const $ = require('jquery');
const _ = require('lodash');
const Vue = require('vue');

const CodeMirror = require('lib/codemirror');
const CodeMirrorMixin = require('bundles/p13n/modules/ui/mixins/code_mirror')
  .default;

const createPluginConfigField = require('./create_plugin_config_field');
const Template = require('./code.html');

const CODE_MIRROR_OPTIONS = {
  fixedGutter: false,
  lineNumbers: false,
  lineWrapping: true,
  matchBrackets: true,
  mode: 'htmlmixed',
  tabSize: 2,
};

const HTMLFieldTypeComponent = createPluginConfigField({
  template: Template,

  data: {
    _codeMirrors: {
      html: null,
    },
  },

  methods: {
    initializeCodeMirrorField(el) {
      this._codeMirrors.html = CodeMirror.fromTextArea(el, CODE_MIRROR_OPTIONS);
      const cmInstance = this._codeMirrors.html;

      // Allow the rest of initialize to finish before binding to change and setting the size.
      Vue.nextTick(() => {
        cmInstance.setSize('auto', $(el.parentElement).height());
        // Initialize change observation for this CodeMirror instance.
        cmInstance.on('change', function(type, cm, change) {  // eslint-disable-line
            this.updateValue(cmInstance.getValue());
          }.bind(this),
        );
      });
      return cmInstance;
    },
  },

  ready() {
    this.initializeCodeMirrorField(this.$$.input);
    this.$on('sidebarResizeEvent', () => {
      this.refreshCodeMirror(this._codeMirrors.html);
    });
  },
});

module.exports = _.merge({}, CodeMirrorMixin, HTMLFieldTypeComponent);

import flux from 'core/flux';

import CurrentProject from 'optly/modules/current_project';
import BundleSplitHelper from 'optly/utils/bundle_split_helper';
import AdminAccount from 'optly/modules/admin_account';
import ProjectPermissions from 'optly/modules/permissions';

import RoutingFns from '../../routing_fns';

let RolloutsSection;

/*
 * Routing function that ensures the Oasis dynamic bundle has been fetched
 */
const EnsureOasisBundle = (ctx, next) => {
  BundleSplitHelper.getOasisBundleModules()
    .then(modules => {
      ({ RolloutsSection } = modules);
    })
    .then(next);
};

/*
 * Routes for the Rollouts section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/projects/:proj_id/rollouts',
    handle: [
      [
        EnsureOasisBundle,
        RoutingFns.loadingMainStart,
        RoutingFns.parseProjectId,
      ],
      (ctx, next) =>
        RolloutsSection.pages.rolloutsDashboard.routingSetup(ctx, next),
      () => {
        // redirect if not a custom project
        const isCustomProject = flux.evaluate(
          CurrentProject.getters.isCustomProject,
        );
        if (!isCustomProject) {
          RoutingFns.redirectToCurrentProjectLayers();
        }

        const canUseFeatureRollouts = flux.evaluateToJS([
          AdminAccount.getters.accountPermissions,
          ProjectPermissions.fns.canUseFeatureRollouts,
        ]);

        // redirect if user does not have permission
        if (!canUseFeatureRollouts) {
          RoutingFns.redirectToCustomProjectHome();
        }

        RoutingFns.renderMainRegion(
          RolloutsSection.pages.rolloutsDashboard.component,
        );
      },
    ],
  },
];

export default routes;

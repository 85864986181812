import { toImmutable } from 'optly/immutable';
import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

/*
 **
 * Getter that filters for audiences in a LayerExperiment.
 */
export const byLayerExperiment = LayerExperiment => [
  baseEntityGetters.entityCache,
  /**
   * @param {Immutable.Map} audienceMap
   * @return {Immutable.List}
   */
  function(audienceMap) {
    if (
      !LayerExperiment ||
      !LayerExperiment.get('audience_ids') ||
      !audienceMap
    ) {
      return toImmutable([]);
    }

    return LayerExperiment.get('audience_ids')
      .map(id => audienceMap.get(id))
      .filter(i => i);
  },
];

export default {
  ...baseEntityGetters,
  byLayerExperiment,
};

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Track } from '@optimizely/segment-js/dist/components';
import { OptimizelyFeature } from '@optimizely/react-sdk';

import { Button, Switch, Table } from 'optimizely-oui';

import Immutable from 'optly/immutable';

import ComponentModuleFns from 'bundles/p13n/sections/oasis_experiment_manager/components/experiment_dialog/component_module/fns';
import SectionModuleFns from 'bundles/p13n/sections/oasis_experiment_manager/section_module/fns';

// components
import VariableValueTable from 'bundles/p13n/components/variable_value_table_legacy';

import VariationRow from './subcomponents/variation_row';

const ExperimentVariations = props => {
  const { hideHeader, disableAddVariation } = props;
  const addVariation = () => {
    const variables = props.feature
      ? props.feature
          .get('variables')
          .filter(variable => !variable.get('archived'))
      : Immutable.List();
    const variationIndex = props.variations.size + 1 || 0;
    const defaultVariationProps = {
      feature_enabled: false,
      api_name: `variation_${variationIndex}`,
    };
    const updatedVariations = ComponentModuleFns.addVariation(
      props.variations,
      variables,
      defaultVariationProps,
    );
    props.updateExperimentProperty('variations', updatedVariations);
  };

  const canDeleteVariation = () => {
    const { canDelete, variations } = props;

    return canDelete && variations.size > 1;
  };

  const deleteVariation = index => {
    const updatedVariations = ComponentModuleFns.deleteVariation(
      index,
      props.variations,
    );
    props.updateExperimentProperty('variations', updatedVariations);
  };

  const updateVariation = (index, variation) => {
    const updatedVariations = props.variations.set(index, variation);
    props.updateExperimentProperty('variations', updatedVariations);
  };

  const updateVariationVariable = (updatedVariable, variationIndex) => {
    let variationToUpdate = props.variations.get(variationIndex);
    variationToUpdate = ComponentModuleFns.updateVariationVariable(
      variationToUpdate,
      updatedVariable,
    );
    updateVariation(variationIndex, variationToUpdate);
  };

  const pauseVariation = index => {
    const updatedVariations = ComponentModuleFns.pauseVariation(
      index,
      props.variations,
    );
    props.updateExperimentProperty('variations', updatedVariations);
  };

  const resumeVariation = (index, variation) => {
    const updatedVariations = ComponentModuleFns.resumeVariation(
      index,
      props.variations,
    );
    props.updateExperimentProperty('variations', updatedVariations);
  };

  const toggleVariationEnabled = (event, index, variation) => {
    const isEnabled = event.target.checked;
    const updatedVariation = variation.set('feature_enabled', isEnabled);
    const updatedVariations = props.variations.set(index, updatedVariation);
    props.updateExperimentProperty('variations', updatedVariations);
  };

  const renderErrorBlock = () => {
    if (props.variations.size === 0) {
      return;
    }

    if (!SectionModuleFns.ensureVariationKeysUnique(props.variations)) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="variation-keys-not-unique-error">
          All variations must have unique keys.
        </div>
      );
    }

    if (!ComponentModuleFns.ensureVariationWeightsTotal100(props.variations)) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="is-total-not-100-error">
          The traffic distribution total must equal 100%.
        </div>
      );
    }

    if (ComponentModuleFns.hasNegativeVariationValues(props.variations)) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="has-negative-value-error">
          Traffic distribution values cannot be negative.
        </div>
      );
    }

    if (props.hasInvalidVariableValues) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="has-invalid-variable-value-error">
          One or more variable values are invalid.
        </div>
      );
    }
  };

  const renderVariationTable = (
    variations,
    tableIndex,
    hideDescription,
    hideActionButtons,
  ) => (
    <Table tableLayoutAlgorithm="auto">
      <Table.THead>
        <Table.TR>
          <Table.TH width={hideDescription ? '68%' : '20%'}>
            Variation Key
          </Table.TH>
          <Table.TH>{!hideDescription && 'Description'}</Table.TH>
          <Table.TH width="22%">Traffic Distribution</Table.TH>
          <Table.TH width="10%" />
        </Table.TR>
      </Table.THead>
      <Table.TBody>
        {// Use the tableIndex if available because it means we are rendering multiple tables with one row
        variations.map((variation, index) => (
          <VariationRow
            canDelete={canDeleteVariation()}
            key={index}
            onDeleteVariation={deleteVariation}
            pauseVariation={pauseVariation}
            resumeVariation={resumeVariation}
            rowIndex={tableIndex || index}
            showInvalidKeyError={
              !ComponentModuleFns.ensureVariationKeyValid(
                variation.get('api_name'),
              )
            }
            updateVariation={updateVariation}
            variation={variation}
            hideDescription={hideDescription}
            hideActionButtons={hideActionButtons}
          />
        ))}
      </Table.TBody>
    </Table>
  );

  const renderVariations = () => {
    const {
      feature,
      variations,
      isFormDirty,
      hideDescription,
      hideFeatureControls,
      hideActionButtons,
    } = props;
    if (variations.size === 0 || !feature) {
      return;
    }

    let variables;
    if (feature) {
      // only use variables that either do not yet have the 'archived' property or have the property set to false
      variables = feature
        .get('variables')
        .filter(
          variable =>
            variable.get('archived') === false ||
            variable.get('archived') === undefined,
        );
    } else {
      variables = Immutable.List();
    }
    const name = feature.get('name');
    const apiName = feature.get('api_name');

    return (
      <div>
        {variations.map((variation, index) => {
          const variationVariables = variables.map(variable => {
            // if there is a value set for the given variation, replace that value
            // in the variable so we can display it in the variable input
            const variationVariableValues = variation.get('variable_values');
            const variableKey = variable.get('api_name');
            if (
              variationVariableValues &&
              variationVariableValues.get(variableKey)
            ) {
              return variable.set(
                'value',
                variationVariableValues.get(variableKey),
              );
            }
            return variable;
          });

          return (
            <div className="push-double--bottom" key={`variation-${index}`}>
              {renderVariationTable(
                Immutable.List([variation]),
                index,
                hideDescription,
                hideActionButtons,
              )}
              <div
                className="border--all"
                data-test-section={`variation-${index}-variables-table`}>
                {!hideFeatureControls && (
                  <Table density="loose" tableLayoutAlgorithm="fixed">
                    <Table.THead>
                      <Table.TR>
                        <Table.TH width="35%">
                          <h6 className="push--top">Feature</h6>
                        </Table.TH>
                        <Table.TH />
                      </Table.TR>
                    </Table.THead>
                    <Table.TBody>
                      <Table.TR>
                        <Table.TD>
                          <OptimizelyFeature feature="user_friendly_names">
                            {isEnabled =>
                              isEnabled ? (
                                <>
                                  <div className="word-break--all">{name}</div>
                                  <div
                                    className="zeta monospace micro muted force-break"
                                    style={{ marginTop: '-1px' }}>
                                    {apiName}
                                  </div>
                                </>
                              ) : (
                                <div className="force-break">{apiName}</div>
                              )
                            }
                          </OptimizelyFeature>
                        </Table.TD>
                        <Table.TD>
                          <div className="push--left">
                            <Track
                              eventName="Variation Toggled"
                              eventType="change"
                              properties={{
                                status: variation.get('feature_enabled'),
                              }}>
                              <Switch
                                checked={variation.get('feature_enabled')}
                                elementId={`toggle-variation-${index}`}
                                onClick={_.partialRight(
                                  toggleVariationEnabled,
                                  index,
                                  variation,
                                )}
                                testSection={`feature-test-variation-toggle-${index}`}
                              />
                            </Track>
                          </div>
                        </Table.TD>
                      </Table.TR>
                    </Table.TBody>
                  </Table>
                )}
                {variables.size > 0 && (
                  <div className="soft-double border--top">
                    <VariableValueTable
                      disableVariables={!variation.get('feature_enabled')}
                      feature={feature}
                      onVariableValueChange={_.partialRight(
                        updateVariationVariable,
                        index,
                      )}
                      isFormDirty={isFormDirty}
                      variables={variationVariables}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <fieldset data-test-section="experiment-variations-container">
      {!hideHeader && (
        <ol className="lego-form-fields">
          <li className="lego-form-field__item">
            <h3>Variations</h3>
            <p
              className="push--bottom"
              data-test-section="variations-explanation-text">
              Create variations (i.e. treatments) you want to include in this
              experiment.
            </p>
          </li>
        </ol>
      )}
      {renderErrorBlock()}
      {renderVariations()}
      {!disableAddVariation && (
        <Button
          onClick={addVariation}
          testSection="feature-experiment-dialog-add-variable"
          isDisabled={!props.feature}
          width="full">
          {props.feature
            ? 'Add variation'
            : 'Add a feature to create variations'}
        </Button>
      )}
    </fieldset>
  );
};

ExperimentVariations.defaultProps = {
  disableAddVariation: false,
  hideHeader: false,
  hideDescription: false,
  hideFeatureControls: false,
  hideActionButtons: false,
};

ExperimentVariations.propTypes = {
  canDelete: PropTypes.bool.isRequired,
  disableAddVariation: PropTypes.bool,
  feature: PropTypes.instanceOf(Immutable.Map),
  hasInvalidVariableValues: PropTypes.bool,
  hideHeader: PropTypes.bool,
  isFormDirty: PropTypes.bool,
  updateExperimentProperty: PropTypes.func.isRequired,
  variations: PropTypes.instanceOf(Immutable.List),
  hideDescription: PropTypes.bool,
  hideFeatureControls: PropTypes.bool,
  hideActionButtons: PropTypes.bool,
};

export default ExperimentVariations;

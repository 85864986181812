/**
 * Services layer pure functions for the oauth authorized clients
 */

export function exampleFn(input) {
  const output = input.slice(1);
  return output;
}

export default {
  exampleFn,
};

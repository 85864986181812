import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const BeforeLeaveContext = React.createContext(() => {});
/*
 * HoC that adds a prop setBeforeLeave which takes care of any hooks that need to be called
 * before leaving a URL route. The beforeLeave fn will be set on the region component.
 * Our core router will then call this function before navigation.
 * You can also use this as a decorator!
 */
export const withBeforeLeave = Component => {
  const ContextWrapper = (props, ref) => (
    <BeforeLeaveContext.Consumer>
      {setBeforeLeave => (
        <Component {...{ ...props, ref }} setBeforeLeave={setBeforeLeave} />
      )}
    </BeforeLeaveContext.Consumer>
  );

  // Helpful display name for debugging
  ContextWrapper.displayName = `withBeforeLeave(${Component.name})`;

  return hoistNonReactStatics(React.forwardRef(ContextWrapper), Component);
};

export default {
  BeforeLeaveContext,
  withBeforeLeave,
};

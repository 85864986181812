import React from 'react';
import PropTypes from 'prop-types';

import { Button, DialogNew, Link, Icon } from 'optimizely-oui';
import { withTrack } from '@optimizely/segment-js/dist/decorators';

import uiDialogMethods from 'core/ui/methods/dialogs';

import { actions as PerformanceTrackingActions } from 'optly/modules/performance_tracking';

import {
  SegmentEvents,
  IntroDialogPerformanceMarkKeys,
  LinkToDocs,
} from '../../../section_module/constants';

@withTrack
class FeaturesDashboardIntroDialog extends React.Component {
  static propTypes = {
    track: PropTypes.func.isRequired,
  };

  componentDidMount() {
    PerformanceTrackingActions.setPerformanceMark(
      IntroDialogPerformanceMarkKeys.DIALOG_MOUNTED_START,
    );
  }

  trackWithDuration = (eventName, timingEnd) => {
    const { track } = this.props;
    const dialogOpenFor =
      timingEnd -
      PerformanceTrackingActions.getPerformanceMark(
        IntroDialogPerformanceMarkKeys.DIALOG_MOUNTED_START,
      );
    track(eventName, { timestamp: dialogOpenFor });
  };

  onDismiss = () => {
    const dialogClosed = PerformanceTrackingActions.setPerformanceMark(
      IntroDialogPerformanceMarkKeys.DIALOG_TIMER_STOP_ON_DISMISS,
    );
    this.trackWithDuration(SegmentEvents.DIALOG_DISMISS, dialogClosed);
    uiDialogMethods.hideDialog();
  };

  linkClickHandler = () => {
    const linkClicked = PerformanceTrackingActions.setPerformanceMark(
      IntroDialogPerformanceMarkKeys.DIALOG_TIMER_STOP_ON_LINK_CLICK,
    );
    this.trackWithDuration(SegmentEvents.DIALOG_CLICK_TO_DOCS, linkClicked);
  };

  render() {
    return (
      <DialogNew
        title="Launch Your First Feature Flag"
        hasCloseButton={false}
        hasOverlay={true}
        testSection="features-dash-intro-dialog"
        footerButtonList={[
          <Button
            style="plain"
            testSection="features-dash-intro-dialog-dismiss-button"
            key={0}
            onClick={this.onDismiss}>
            Dismiss
          </Button>,
          <Link
            testSection="features-dash-intro-dialog-link-to-docs"
            href={LinkToDocs.href}
            newWindow={true}
            key={1}
            onClick={this.linkClickHandler}>
            <Button style="highlight" isLink={true}>
              View Quickstart Guide
              <span className="push--left">
                <Icon name="arrow-up-right-from-square" size="small" />
              </span>
            </Button>
          </Link>,
        ]}>
        <div>
          <ol>
            To get started:
            <li>1&#41; View the quickstart to install the SDK</li>
            <li>2&#41; Implement your first feature flag</li>
            <li>3&#41; Check for your "create password" email</li>
            <br />
          </ol>
        </div>
      </DialogNew>
    );
  }
}

export default FeaturesDashboardIntroDialog;

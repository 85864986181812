/**
 * Third Party Condition Boolean Field Component
 * Responsible for rendering the configuration UI for a third-party boolean condition
 */

const _ = require('lodash');

const baseComponent = require('./base');

module.exports = _.extend({}, baseComponent, {
  template:
    '<input type="hidden" v-model="condition.value" class="lego-text-input" />',

  ready() {
    baseComponent.ready.call(this);

    // Set initial value for condition
    this.condition.value = 'true';
  },
});

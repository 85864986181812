import Vue from 'vue';

import actions from './actions';
import fns from './fns';
import getters from './getters';
import SortableThComponent from './sortable_th_component';
import SortableLiComponent from './sortable_li_component';

Vue.component('sortable-th', SortableThComponent);
Vue.component('sortable-li', SortableLiComponent);

export { actions, fns, getters };

export default {
  actions,
  fns,
  getters,
};

/**
 * Routing functions for the p13n bundle
 *
 * These functions compose modules from the optly namespace to form
 * the implementation layer of the p13n product
 *
 * Routing functions are mainly used for fetching data and setting the proper
 * state necessary to render the app through the router
 */
import $ from 'jquery';
import _ from 'lodash';

import { featureFunction } from '@optimizely/js-sdk-lab/src/decorators';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

// eslint-disable-next-line import/no-unresolved
import { actions as NavActions } from 'NAVBAR';

import flux from 'core/flux';
import parseQueryParams from 'optly/utils/parse_query_params';
import Router from 'core/router';
import ui from 'core/ui';

import UrlHelper2 from 'optly/services/url_helper';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import {
  actions as CurrentLayerActions,
  getters as CurrentLayerGetters,
} from 'bundles/p13n/modules/current_layer';
import {
  actions as CurrentProjectActions,
  getters as CurrentProjectGetters,
} from 'optly/modules/current_project';
import { actions as DcpDatasourceActions } from 'optly/modules/entity/dcp_datasource';
import {
  actions as EventModuleActions,
  getters as EventModuleGetters,
} from 'optly/modules/entity/event';
import {
  actions as FilterableTableActions,
  enums as FilterableTableEnums,
} from 'optly/modules/filterable_table';
import Layer from 'optly/modules/entity/layer';
import { actions as LayerExperimentActions } from 'optly/modules/entity/layer_experiment';
import { actions as LiveCommitTagActions } from 'optly/modules/entity/live_commit_tag';
import NavConstants from 'optly/services/navigation';
import { actions as PageableActions } from 'optly/modules/pageable';
import { fns as PermissionsFns } from 'optly/modules/permissions';
import PermissionsGetters from 'optly/modules/permissions/getters';
import { actions as PluginModuleActions } from 'optly/modules/entity/plugin';
import {
  actions as ProjectActions,
  fns as ProjectFns,
  getters as ProjectGetters,
} from 'optly/modules/entity/project';
import {
  actions as ShareActions,
  getters as ShareGetters,
} from 'optly/modules/entity/share';
import { actions as TagActions } from 'optly/modules/entity/tag';
import { actions as ViewActions } from 'optly/modules/entity/view';
import { actions as MetricActions } from 'optly/modules/entity/metric_template';
import { actions as WebhookActions } from 'optly/modules/entity/webhook';
import { actions as CollaboratorActions } from 'optly/modules/entity/collaborator';
import {
  actions as UserActions,
  getters as UserGetters,
} from 'optly/modules/entity/user';
import { actions as EmailSettingActions } from 'optly/modules/entity/email_setting';
import { actions as IntegrationActions } from 'optly/modules/entity/integration';
import { actions as OAuthBearerTokenActions } from 'optly/modules/entity/oauth_bearer_token';
import { actions as OAuthAuthorizedClientActions } from 'optly/modules/entity/oauth_authorized_client';
import { actions as ProjectIntegrationActions } from 'optly/modules/entity/project_integration';
import { actions as OAuthClientActions } from 'optly/modules/entity/oauth_client';
import { actions as DashboardActions } from 'optly/modules/dashboard';
import { actions as LinkActions } from 'optly/modules/entity/links';
import { actions as BillingInfoActions } from 'optly/modules/entity/billing_info';
import {
  actions as JiraIntegrationActions,
  getters as JiraIntegrationGetters,
} from 'optly/modules/jira_integration';

import VerifyEmailDialog from 'optly/components/dialogs/verify_email';

import { actions as CategoriesActions } from 'bundles/p13n/modules/predefined_categories';
import { actions as CurrentEventActions } from 'bundles/p13n/modules/current_event';
import { actions as P13NUIActions } from 'bundles/p13n/modules/ui'; // eslint-disable-line
import RoutingHelpers from 'bundles/p13n/routing_helpers';

import caching from './caching';

/**
 * Sugar method to do `require(['component'], renderMainRegion)`
 * @param {ReactElement | VueElement} component
 * @param {Object} options
 * @param {Object} options.data
 * @param {Object} options.options
 */
export function renderMainRegion(component, opts = {}) {
  const currentProjectId = flux.evaluate(CurrentProjectGetters.id);
  const componentId = `${component.props?.componentId ||
    component.componentId ||
    component.type.componentId}--${currentProjectId}`;
  const config = {
    component,
    componentId,
  };
  if (opts.data) {
    config.data = opts.data;
  }
  if (opts.options) {
    config.options = opts.options;
  }

  // Safety check to make sure the rootVM has been mounted by now.
  (function waitForRoot() {
    if (ui.getRootVM()) {
      ui.renderMainRegion(config);
      ui.loadingStop('main');
    } else {
      setTimeout(waitForRoot, 5);
    }
  })();
}

/**
 * Loads the necessary data into the frontend stores
 * for the Access tab in account dashboard
 */
export function fetchAccessTabData(ctx, next) {
  const accountId = flux.evaluate(AdminAccountGetters.id);
  const loading = [];

  // See if user has access to OAuthClient feature and load data accordingly
  const accountPermissions = flux.evaluate(
    AdminAccountGetters.accountPermissions,
  );
  if (PermissionsFns.canManageBearerToken(accountPermissions)) {
    loading.push(OAuthBearerTokenActions.fetchAll({ account_id: accountId }));
    loading.push(OAuthAuthorizedClientActions.fetchAll());

    ui.loadingWhen('account_dashboard.bearer_tokens', loading);
  }
  next();
}

/**
 * Loads the necessary data into the frontend stores
 * for the Profile->Alerts tab in account dashboard
 */
export function fetchAlertsTabData(ctx, next) {
  const email = flux.evaluate(AdminAccountGetters.email);
  EmailSettingActions.fetchAll({ email }).then(next);
}

export function fetchCollaboratorsTabData(ctx, next) {
  const byProject = {
    project_id: ctx.currentProject.id,
  };

  const loading = CollaboratorActions.fetchAll(byProject);
  ui.loadingWhen('project-settings-dashboard', loading);
  next();
}

export function fetchDeveloperTabData(ctx, next) {
  // See if user has access to OAuthClient feature and load data accordingly
  const accountPermissions = flux.evaluate(
    AdminAccountGetters.accountPermissions,
  );
  if (PermissionsFns.canViewOAuthClient(accountPermissions)) {
    const byAccount = {
      account_id: flux.evaluate(AdminAccountGetters.id),
    };

    const loading = OAuthClientActions.fetchAll(byAccount);
    ui.loadingWhen('account-dashboard.developer', loading);
  }
  next();
}

export function fetchIntegrationsTabData(ctx, next) {
  const isWebProject = flux.evaluate([
    CurrentProjectGetters.project,
    ProjectFns.isWebProject,
  ]);

  if (isWebProject) {
    Layer.actions.fetchAllByStatus({
      projectId: ctx.currentProject.id,
      archived: false,
    });
  }

  const byProject = {
    project_id: ctx.currentProject.id,
  };
  Promise.all([
    IntegrationActions.fetchAll(),
    ProjectIntegrationActions.fetchAll(byProject),
  ]).then(next);
}

/**
 * Loads the necessary data into the frontend stores
 * for the Profile->Info tab in account dashboard
 */
export function fetchUserInfo(ctx, next) {
  const email = flux.evaluate(AdminAccountGetters.email);
  UserActions.fetch(email).then(next);
}

/**
 * Parses filters from the query params for the collaborators search filter
 */
export function parseCollaboratorFilters(ctx, next) {
  const params = parseQueryParams(ctx.canonicalPath);
  if (params.search) {
    const searchString = decodeURIComponent(params.search);
    DashboardActions.filterCollaboratorsByString(searchString);
  }
  next();
}

/**
 * Parses filters from the query params for the integrations search filter
 */
export function parseIntegrationFilters(ctx, next) {
  const params = parseQueryParams(ctx.canonicalPath);
  if (params.search) {
    const searchString = decodeURIComponent(params.search);
    DashboardActions.filterIntegrationsByString(searchString);
  }
  next();
}

/**
 * Loads the verify Change Email dialog if we have a verification token in the query params.
 * Also checks that currentUser.email_change_requested contains a string (it's null if
 * there is no pending email change).
 */
export function verifyChangeEmail(ctx, next) {
  const params = parseQueryParams(ctx.canonicalPath);
  const currentUser = flux.evaluateToJS(UserGetters.currentUser);
  if (params.verification_token && !!currentUser.email_change_requested) {
    ui.showReactDialog(
      VerifyEmailDialog,
      {
        props: {
          verificationToken: params.verification_token,
          newEmail: currentUser.email_change_requested,
        },
      },
      {
        fullScreen: false,
      },
    );
  }

  next();
}

/**
 * Loads the Verify Email dialog if we have a flag should_verify_email in the query params
 * and current user's email is not verified.
 */
export function verifyCurrentUserEmailVerified(ctx, next) {
  const currentUser = flux.evaluate(UserGetters.currentUser);
  if (currentUser.get('email_verified')) {
    next();
    return;
  }

  const params = parseQueryParams(ctx.canonicalPath);
  if (params.should_verify_email) {
    ui.confirm({
      title: tr('Verify Email Address'),
      message: tr(
        'You will need to verify your email address before continuing. Please verify and try again.',
      ),
      confirmText: tr('Verify'),
      cancelText: tr('Cancel'),
    })
      .then(UserActions.sendVerificationEmail)
      .done(() => {
        ui.showNotification({
          type: 'success',
          message: tr(
            'An email verification link has been sent to you. Please click that link to verify your email.',
          ),
        });
      });
  }
  next();
}

/**
 * Sets current project as specified in  `params.proj_id`
 * Also issues a non-blocking fetchAll for all projects for the project_switcher
 * Sets up localEntity caching for audience associated with the project
 *
 */
export async function parseProjectId(ctx, next) {
  // try to find the project by id specified in URL
  let projId = Number(ctx.params.proj_id);
  if (!projId) {
    // Try to use the already set current project's id if none supplied in url param
    projId = flux.evaluate(CurrentProjectGetters.id);
  }
  let project = flux.evaluateToJS(ProjectGetters.byId(projId));
  if (!project) {
    project = await ProjectActions.fetch(projId);
  }
  if (!project) {
    Router.windowNavigate('/v2/');
    return;
  }
  CurrentProjectActions.setCurrentProjectId(projId);
  caching.setupProjectLocalEntityCache(projId);
  ctx.currentProject = project;

  // Only try to set the last project if this is not anonymous access via share token.
  if (!flux.evaluate(CurrentLayerGetters.shareToken)) {
    CurrentProjectActions.setLastViewedProjectId(project.id);
  }
  const isPersonalizationEnabledInClient = flux.evaluate(
    CurrentProjectGetters.isPersonalizationEnabledInClient,
  );

  const isCustomProject = flux.evaluate(CurrentProjectGetters.isCustomProject);
  const isWelcomePage = ctx.path && ctx.path.includes(UrlHelper2.welcomePage());
  if (!isWelcomePage) {
    if (!isCustomProject) {
      // only warn for web projects as custom projects don't use the snippet
      P13NUIActions.warnIfPersonalizationIsOff(
        project.id,
        isPersonalizationEnabledInClient,
      );
    }
    P13NUIActions.warnIfInArchivedProject(project.project_status);
  }
  next();
}

export function parseShareToken(ctx, next) {
  const params = parseQueryParams(ctx.canonicalPath);

  if (!params.share_token) {
    next();
    return;
  }

  const shareToken = params.share_token ? params.share_token : null;

  CurrentLayerActions.setCurrentLayerShareToken(shareToken);
  next();
}

/**
 * Parses filters from the query params for the layer search filter
 * @param {String} tableId specifying which filtertable is being filtered
 * @param {String|undefined} defaultStatus optional string indicating the default status
 * @param {Array|undefined} validStatusOptions optional list of possible valid status strings
 */
export function parseQueryFilters(tableId, defaultStatus, validStatusOptions) {
  return function(ctx, next) {
    FilterableTableActions.setFilterDefault(tableId, {
      status: defaultStatus || FilterableTableEnums.status.ACTIVE,
      string: '',
    });

    const params = parseQueryParams(ctx.canonicalPath);
    if (params.search) {
      const searchString = decodeURIComponent(params.search);
      FilterableTableActions.setFilter(tableId, {
        string: searchString,
      });
    }

    const validStatuses =
      validStatusOptions || _.values(FilterableTableEnums.status);
    if (params.status) {
      const status = params.status.toUpperCase();
      // TODO (asa): Support multiple 'status' query params?
      if (validStatuses.indexOf(status) !== -1) {
        FilterableTableActions.setFilter(tableId, {
          status,
        });
      }
    }
    next();
  };
}

/**
 * Redirects the user to the default project
 */
export function redirectToCurrentProjectLayers() {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  Router.redirect(UrlHelper2.xWebHome(projectId));
}

/**
 * Redirects the user to the Custom project and checks if home should be experiments or features dashboard
 */
export function redirectToCustomProjectHome() {
  const isFeaturesDashboardDefaultEnabled = isFeatureEnabled(
    'features_dashboard_as_default_landing',
  );
  const accountPermissions = flux.evaluate(
    AdminAccountGetters.accountPermissions,
  );
  const currentProject = flux.evaluate(CurrentProjectGetters.project);
  const projectId = currentProject.get('id');
  const projectPlatform = currentProject.getIn(['project_platforms', 0]);
  const isFlagsEnabled = currentProject.get('is_flags_enabled');

  const xProjectHomeHref = UrlHelper2.xProjectHome(
    projectId,
    projectPlatform,
    isFlagsEnabled,
  );

  if (isFlagsEnabled) {
    // For a Flags project, broadcast the URL change to the Flags SCS
    NavActions.replaceHref(xProjectHomeHref);
    return;
  }

  if (
    isFeaturesDashboardDefaultEnabled ||
    !PermissionsFns.canUseFullStackExperiments(accountPermissions)
  ) {
    Router.redirect(UrlHelper2.featuresHome(projectId));
    return;
  }

  Router.redirect(xProjectHomeHref);
}

/**
 * Sets the currently active nav item
 */
export function setActiveNav(activeNav) {
  return function(ctx, next) {
    NavActions.setActiveNavItem(activeNav);
    next();
  };
}

/**
 * Sets the nav width to the constant provided when shouldHideNav is false
 */
export function setNavWidth(navWidth) {
  return function(ctx, next) {
    const shouldHideNav = !!ctx.queryParams.share_token;
    // If a share token exists, override any setNavWidth call and keep the nav hidden
    NavActions.setNavWidth(
      shouldHideNav ? NavConstants.NavWidth.HIDDEN : navWidth,
    );
    next();
  };
}

export function loadingMainStart(ctx, next) {
  ui.loadingStart('main');
  next();
}

function fetchLayerAndSetAsCurrentAndFetchAssociatedEntities(layerId, next) {
  RoutingHelpers.fetchLayerAndSetAsCurrentAndFetchAssociatedEntities(layerId)
    .then(next)
    .fail(() => Router.redirect('/v2'));
}
/**
 * Parses the router ctx object for an `item_id`, fetches the corresponding layer
 * and sets the currentLayerId. If no item_id, get layer_id from experiment_id.
 */
export function fetchLayer(ctx, next) {
  let layerId;

  if (ctx.params.item_id) {
    layerId = Number(ctx.params.item_id);
  }

  if (!layerId) {
    const experimentId = Number(ctx.params.experiment_id);
    LayerExperimentActions.fetch(experimentId).then(experiment => {
      fetchLayerAndSetAsCurrentAndFetchAssociatedEntities(
        experiment.layer_id,
        next,
      );
    });
  } else {
    fetchLayerAndSetAsCurrentAndFetchAssociatedEntities(layerId, next);
  }
}

/**
 * Fetches all views for the CurrentProject context
 */
export function fetchViewsForCurrentProject(ctx, next) {
  ViewActions.fetchAll({
    project_id: flux.evaluate(CurrentProjectGetters.id),
  }).then(next);
}

/**
 * Fetches all datasources for the CurrentProject context
 */
export function fetchDatasourcesForCurrentProject(ctx, next) {
  const project = flux.evaluate(CurrentProjectGetters.project);
  if (project.get('dcp_service_id')) {
    DcpDatasourceActions.fetchAll({
      dcp_service_id: project.get('dcp_service_id'),
    }).then(next);
  } else {
    next();
  }
}

/*
 * Fetch data related to the Jira integration:
 * project integration settings, jira links, jira user connection state,
 * recently viewed jira issues
 *
 * @param {String} sourceType
 */
export const fetchJiraLinkedIssuesData = function(sourceType) {
  const project = flux.evaluate(CurrentProjectGetters.project);

  if (
    project.getIn(['jira_integration', 'enabled']) &&
    !!project.getIn(['jira_integration', 'resource_id'])
  ) {
    const linkFetchParams = {
      project_id: project.get('id'),
      target_type: 'jira_issue',
      limit: 1000,
    };
    if (sourceType) {
      linkFetchParams.source_type = sourceType;
    }

    ui.loadingWhen('jira-links', LinkActions.fetchAll(linkFetchParams));

    const jiraIntegrationFetch = JiraIntegrationActions.getProjectUserConnectionState().then(
      () => {
        const canUpdateJiraLinks = flux.evaluate([
          CurrentProjectGetters.project,
          PermissionsFns.canUpdateJiraLinks,
        ]);
        const isConnectedToJira = flux.evaluate(
          JiraIntegrationGetters.isUserConnected,
        );
        if (canUpdateJiraLinks && isConnectedToJira) {
          return JiraIntegrationActions.getAllRecentIssues();
        }
      },
    );
    ui.loadingWhen('jira-integration-fetch', jiraIntegrationFetch);
  }
};

/**
 * Attempts to batch fetch all essential data for the given project
 * TODO (asa): Batch some of these requests together as one request
 * when batch request API is available
 * TODO(jordan): this appears to be only needed for change history now
 */
export function fetchDataForChangeHistory(ctx, next) {
  const byProject = {
    project_id: flux.evaluate(CurrentProjectGetters.id),
  };

  const promises = [];
  promises.push(Layer.actions.fetchAll(byProject));
  if (!flux.evaluate(CurrentLayerGetters.shareToken)) {
    promises.push(TagActions.fetchAll(byProject));
  }

  // this fetches views, events and audiences
  promises.push(RoutingHelpers.fetchCreateLayerData());
  promises.push(LiveCommitTagActions.fetchAll(byProject));

  Promise.all(promises).then(next);
}

/**
 * Fetch the share token for the relevant layer
 */
export function fetchShareToken(ctx, next) {
  const layerId = flux.evaluate(CurrentLayerGetters.id);
  const byLayer = {
    layer_id: layerId,
  };

  const canViewShareTokens = flux.evaluate([
    CurrentProjectGetters.project,
    PermissionsFns.canViewShareTokens,
  ]);

  if (
    !flux.evaluate(ShareGetters.shareTokenByLayerId(layerId)) &&
    !flux.evaluate(CurrentLayerGetters.shareToken) &&
    canViewShareTokens
  ) {
    ShareActions.fetchAll(byLayer);
  }
  next();
}

/**
 * Fetch all essential data to display the events page for the current project
 * @param  {Object}   ctx
 * @param  {Function} next
 */
export function fetchEventImplementationData(ctx, next) {
  const isCustomProject = flux.evaluate(CurrentProjectGetters.isCustomProject);
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const byProject = {
    project_id: projectId,
  };
  const deferreds = [];
  deferreds.push(EventModuleActions.fetchAll(byProject));

  // these fetches are required for web projects only, not custom projects
  if (!isCustomProject) {
    deferreds.push(CategoriesActions.fetchAll());
    deferreds.push(ViewActions.fetchAll(byProject));

    const canUsePlugins = flux.evaluate(PermissionsGetters.canUsePlugins);
    if (canUsePlugins) {
      deferreds.push(PluginModuleActions.fetchActiveEventPlugins(projectId));
    }
  }

  Promise.all(deferreds).then(next);
}

/**
 * Fetches tags for the current project
 */
export function fetchTagsForCurrentProject(ctx, next) {
  TagActions.fetchAll({
    project_id: flux.evaluate(CurrentProjectGetters.id),
  });
  next();
}

/**
 * Populate the event entity cache with all events attached to the current project
 *
 */
export function fetchEventsForCurrentProject(ctx, next) {
  const byProject = {
    project_id: flux.evaluate(CurrentProjectGetters.id),
  };
  EventModuleActions.fetchAll(byProject).then(next);
}

/**
 * Populate the metrics entity cache with all metrics attached to the current account
 *
 */
export function fetchMetricsForAccount(ctx, next) {
  MetricActions.fetchAllForAccount().then(next);
}

/**
 * Returns routing function to set the active tab in the nav
 * @param {String} category
 * @param {String} tabId
 */
export function setActiveTab(category, tabId) {
  return function(ctx, next) {
    NavActions.setActiveTab(category, tabId);
    next();
  };
}

/**
 * Authenticates whether a user can use the plugin editor
 * Requires that this.parseProjectId run first, as to reliably have project permissions
 */
export function authenticatePlugins(ctx, next) {
  const canUsePlugins = flux.evaluate(PermissionsGetters.canUsePlugins);
  if (!canUsePlugins) {
    Router.redirect(
      UrlHelper2.xWebHome(flux.evaluate(CurrentProjectGetters.id)),
    );
    return;
  }
  next();
}

/**
 * Set the current event id. Valid values are undefined or an event id associated with the project
 *
 *
 */
export function setCurrentEvent(ctx, next) {
  // Assume this route is for the create page
  if (_.isUndefined(ctx.params.event_id)) {
    CurrentEventActions.setCurrentEventId(ctx.params.event_id);
    next();
    return;
  }
  const eventId = Number(ctx.params.event_id);

  // If the event id is not a number, don't try to fetch events
  if (_.isNaN(eventId)) {
    Router.redirect('/v2');
    return;
  }

  // If we are setting an event id, block until we are sure its valid
  const byProject = {
    project_id: flux.evaluate(CurrentProjectGetters.id),
  };

  // Get all events and verify that the specified id is in the list
  EventModuleActions.fetchAll(byProject).then(() => {
    if (flux.evaluate(EventModuleGetters.byId(eventId))) {
      CurrentEventActions.setCurrentEventId(eventId);
      next();
    } else {
      Router.redirect('/v2');
    }
  });
}

/**
 * Fetch project plugins.  Currently used in the editor sidebar
 * TODO(jordan): refactor when editor is a page
 * TODO(jordan): Deprecate and put in routing fns
 */
export function fetchProjectPlugins(ctx, next) {
  const canUsePlugins = flux.evaluate(PermissionsGetters.canUsePlugins);
  if (!canUsePlugins) {
    // don't try to fetch resource user doesnt have permission for
    next();
    return;
  }
  const byProject = {
    project_id: flux.evaluate(CurrentProjectGetters.id),
  };
  PluginModuleActions.fetchAll(byProject).then(next);
}

/**
 * Grab predefined category options from the categories endpoint
 * @param  {Object}   ctx
 * @param  {Function} next
 * @return {Promise}
 */
export function fetchCategories(ctx, next) {
  CategoriesActions.fetchAll().then(next);
}

export function fetchWebhooksTabData(ctx, next) {
  const isWebProject = flux.evaluate([
    CurrentProjectGetters.project,
    ProjectFns.isWebProject,
  ]);
  const byProject = {
    project_id: flux.evaluate(CurrentProjectGetters.id),
  };
  const adminAccountId = flux.evaluate(AdminAccountGetters.id);

  if (isWebProject) {
    WebhookActions.fetchAll(byProject).then(next);
  } else {
    Promise.all([
      WebhookActions.fetchAll(byProject),
      BillingInfoActions.fetchAll({ account_id: adminAccountId }, true),
    ]).then(next);
  }
}

export function resetPageable(pageableId) {
  return function(ctx, next) {
    PageableActions.resetCurrentPage(pageableId);
    next();
  };
}

export function determineResultsUrl(projectId, layerId, opts = {}) {
  const layer = flux.evaluateToJS(Layer.getters.byId(layerId));
  if (!Layer.fns.isPersonalizationLayer(layer)) {
    const experiments = flux.evaluate(
      Layer.getters.experimentsByLayerId(layerId),
    );
    if (experiments && experiments.size > 0) {
      const experimentId = experiments.first().get('id');
      const url = UrlHelper2.layerExperimentResults(
        projectId,
        layerId,
        experimentId,
        opts,
      );
      return $.Deferred().resolve(url);
    }
    // Temporary hack to get single experiment id associated with layer for AB layers
    // will remove when unified results API is ready
    return LayerExperimentActions.fetchAll({
      project_id: projectId,
      layer_id: layerId,
    }).then(exps => {
      const experimentId = exps[0].id;
      return UrlHelper2.layerExperimentResults(
        projectId,
        layerId,
        experimentId,
        opts,
      );
    });
  }
  return $.Deferred().resolve(
    UrlHelper2.layerResults(projectId, layerId, opts),
  );
}

/**
 * Evaluates current project type and redirects Custom projects to Oasis home. For use with web-only routes.
 * NOTE: this must be placed after parseProjectId in the list of handlers.
 * @param  {Object}   ctx
 * @param  {Function} next
 */
export function disallowCustomProjects(ctx, next) {
  const isCustomProject = flux.evaluate(CurrentProjectGetters.isCustomProject);
  if (isCustomProject) {
    redirectToCustomProjectHome();
    return;
  }
  next();
}

/**
 * Evaluates if an account can access the Advanced Settings tab and redirects to the Implementation tab if no access.
 * @param  {Object}   ctx
 * @param  {Function} next
 */
export function disallowAdvancedSettings(ctx, next) {
  const canAccountUseFullStackAdvancedSettings = flux.evaluate(
    PermissionsGetters.canAccountUseFullStackAdvancedSettings,
  );
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const isWebProject = flux.evaluate([
    CurrentProjectGetters.project,
    ProjectFns.isWebProject,
  ]);
  if (!canAccountUseFullStackAdvancedSettings && !isWebProject) {
    Router.redirect(UrlHelper2.projectSettingsImplementation(projectId));
    return;
  }
  next();
}

/**
 * Evaluates if a project can access the Migration Settings tab and redirects to the Implementation tab if no access.
 * @param  {Object}   ctx
 * @param  {Function} next
 */
export function disallowMigrationSettings(ctx, next) {
  const isCustomProject = flux.evaluate([
    CurrentProjectGetters.project,
    ProjectFns.isCustomProject,
  ]);

  const projectId = flux.evaluate(CurrentProjectGetters.id);
  if (!isCustomProject) {
    Router.redirect(UrlHelper2.projectSettingsImplementation(projectId));
    return;
  }
  next();
}

/**
 * Evaluates if an account can access the Stat Sig Notifications Feature and redirects to the default Profile tab if no access.
 * @param  {Object}   ctx
 * @param  {Function} next
 */
export function disallowStatSigNotifications(ctx, next) {
  const canAccountUseStatSigNotificationPreferences = flux.evaluate(
    PermissionsGetters.canAccountUseStatSigNotificationPreferences,
  );

  if (canAccountUseStatSigNotificationPreferences) {
    next();
  } else {
    Router.redirect('/v2/profile');
  }
}

/**
 * Handles basic NavBar settings which first load the mainStart, then based one 3 passed in arguments, will trigger
 * corresponding actions with its payloads.
 * @param navWidth    if present, will trigger setNavWidth action to handle NavBar
 * @param activeNav   if present, will trigger setActiveNav action to handle ActiveNav
 * @param activeTab   if present along with activeNav, will trigger setActiveTab action to set ActiveTabItem
 * @returns {*[]}     array of functions [loadingMainStart, setNavWidth, setActiveNav, setActiveTab]
 */
export function standardNavHandlers(navWidth, activeNav, activeTab) {
  const handlers = [this.loadingMainStart];
  if (navWidth) {
    handlers.push(this.setNavWidth(navWidth));
  }

  if (activeNav) {
    handlers.push(this.setActiveNav(activeNav));
  }

  if (activeNav && activeTab) {
    handlers.push(this.setActiveTab(activeNav, activeTab));
  }

  return handlers;
}

export function renderEditor(component, variationId, experimentOrSectionId) {
  const editorOptions = {
    variationId: Number(variationId) || variationId,
  };
  if (experimentOrSectionId) {
    editorOptions.experimentOrSectionId = Number(experimentOrSectionId);
  }

  renderMainRegion(component, { options: editorOptions });
}

/**
 * Checks if current user/account has access to a specific route via permission getters. Otherwise, reroute to home page.
 *
 * @param  {Object} ctx
 * @param  {Function} next
 * @param  {Array} permissionGetter
 */
export function ensureAccessToFeatureOrRedirect(ctx, next, permissionGetter) {
  const canUseFeature = flux.evaluate(permissionGetter);
  if (!canUseFeature) {
    Router.redirect('/v2/');
    return;
  }

  next();
}

/*
 * Checks if a feature flag is turned on for a specific route. Otherwise, reroute to home page.
 * @param  {Object}   ctx
 * @param  {Function} next
 * @param  {String} featureId
 */
export function ensureFeatureFlagOrRedirect(featureId) {
  return featureFunction(featureId)(function(ctx, next) {
    if (!this[featureId]) {
      Router.redirect('/v2/');
      return;
    }

    next();
  });
}

export function ensureNoAccessToFS(ctx, next) {
  const accountPermissions = flux.evaluate(
    AdminAccountGetters.accountPermissions,
  );
  if (PermissionsFns.canUseFullStackSDKs(accountPermissions)) {
    Router.redirect('/v2/');
    return;
  }

  next();
}

export function redirectIfNotAdmin(ctx, next) {
  const isUserAdmin = flux.evaluate(AdminAccountGetters.isUserAdmin);
  if (!isUserAdmin) {
    Router.redirect('/v2/');
    return;
  }

  next();
}

/*
 * Constructs and sets the page title for the given route based on primaryName, category, and name
 * @param  {String} category          if passed, will appear between primaryName and name
 * @param  {String} name              if passed, will appear last in the title before the " - Optimizely" suffix
 * @param  {Function} next            if passed, this function is executed first before title is set
 * @param  {String} primaryName       if passed, will appear first in the title instead of projectName
 * @param  {Boolean} showProjectName  if false, will prevent the projectName from being added to the title, defaults to true
 */
export function setPageTitle({
  category,
  name,
  next,
  primaryName,
  showProjectName = true,
}) {
  if (next) {
    next();
  }
  let currentProjectName;
  if (showProjectName) {
    const currentProject = flux.evaluate(CurrentProjectGetters.project);
    currentProjectName = currentProject && currentProject.get('project_name');
  }
  const names = [primaryName || currentProjectName, category, name].filter(
    x => x,
  );
  document.title = names.length
    ? `${names.join(': ')} - Optimizely`
    : 'Optimizely';
}

export default {
  renderMainRegion,
  fetchAccessTabData,
  fetchAlertsTabData,
  fetchCollaboratorsTabData,
  fetchDeveloperTabData,
  fetchIntegrationsTabData,
  fetchUserInfo,
  parseCollaboratorFilters,
  parseIntegrationFilters,
  verifyChangeEmail,
  verifyCurrentUserEmailVerified,
  parseProjectId,
  parseShareToken,
  parseQueryFilters,
  redirectToCurrentProjectLayers,
  redirectToCustomProjectHome,
  setActiveNav,
  setNavWidth,
  loadingMainStart,
  fetchLayer,
  fetchViewsForCurrentProject,
  fetchDatasourcesForCurrentProject,
  fetchDataForChangeHistory,
  fetchShareToken,
  fetchEventImplementationData,
  fetchTagsForCurrentProject,
  fetchEventsForCurrentProject,
  fetchMetricsForAccount,
  setActiveTab,
  authenticatePlugins,
  setCurrentEvent,
  fetchProjectPlugins,
  fetchCategories,
  fetchWebhooksTabData,
  resetPageable,
  determineResultsUrl,
  disallowCustomProjects,
  disallowAdvancedSettings,
  disallowMigrationSettings,
  disallowStatSigNotifications,
  standardNavHandlers,
  renderEditor,
  ensureAccessToFeatureOrRedirect,
  ensureFeatureFlagOrRedirect,
  fetchJiraLinkedIssuesData,
  ensureNoAccessToFS,
  redirectIfNotAdmin,
  setPageTitle,
};

const $ = require('jquery');
const _ = require('lodash');

const cloneDeep = require('optly/clone_deep').default;
const flux = require('core/flux');
const ui = require('core/ui');

const Vue = require('vue');

const ColorPickerConstants = require('bundles/p13n/components/color_picker/constants');
const Editor = require('bundles/p13n/modules/editor');
const ImageManagerConstants = require('bundles/p13n/components/image_input_manager/constants');
const ChangeEditorSidebarMixin = require('bundles/p13n/modules/ui/mixins/change_editor_sidebar')
  .default;

const rgbaUtils = require('optly/utils/rgba');
const ColorPicker = require('bundles/p13n/components/color_picker');
const ImageInputManager = require('bundles/p13n/components/image_input_manager')
  .default;

const Template = require('./template.html');

// Disabling the following eslint rule which is firing a false positive error in this file
/* eslint-disable react/no-this-in-sfc */

const backgroundComponent = {
  replace: true,

  template: Template,

  components: {
    'color-picker': ColorPicker,
  },

  data: {
    backgroundImageEditingEnabled: false,
    parentImage: cloneDeep(Editor.constants.IMAGE_PROPERTIES),
  },

  methods: {
    /**
     * Logic that fires when the color picker container notifies the parent that a new color has been selected.
     * Update the currentlyEditingChange and reapply the changes.
     * @param {string} color - The newly selected color in the color picker.
     */
    backgroundColorChanged(color) {
      const currentChangeColor = flux.evaluate(
        Editor.getters.cssValueFromCurrentlyEditingChange('background-color'),
      );
      const elementColor = flux.evaluate(
        Editor.getters.cssValueFromElement('background-color'),
      );

      if (
        color &&
        color !== currentChangeColor &&
        color !== rgbaUtils.convertZeroAlphaForRgba(elementColor)
      ) {
        Editor.actions.setChangeCSSProperty(
          Editor.constants.CSSPropertyNames.BACKGROUND_COLOR,
          color,
        );
        Editor.actions.applyCurrentlyEditingChange();

        // Treat empty string or default value as a revert
      } else if (color === '' || color === elementColor) {
        Editor.actions.removeChangeCSSProperty(
          Editor.constants.CSSPropertyNames.BACKGROUND_COLOR,
        );

        // We only get null as a value from the color picker when it's destroyed, no need to apply changes.
        if (color !== null) {
          Editor.actions.applyCurrentlyEditingChange();
        }
      }
    },

    /**
     * Logic that fires when initializing the component or changing the currentlyEditingChange.
     * Notifies the color picker component about the state of the background color for the selected
     * element.
     */
    calculateBackgroundColor() {
      Vue.nextTick(() => {
        const currentChangeColor = flux.evaluate(
          Editor.getters.cssValueFromCurrentlyEditingChange('background-color'),
        );
        const elementColor = flux.evaluate(
          Editor.getters.cssValueFromElement('background-color'),
        );

        const backgroundColor =
          currentChangeColor || rgbaUtils.convertZeroAlphaForRgba(elementColor);
        this.$.backgroundColorPicker && this.$.backgroundColorPicker.$emit(ColorPickerConstants.EVENTS.SET_COLOR, backgroundColor);  // eslint-disable-line
      });
    },

    /**
     * When the currently edited change gets modified in some way, check to see if
     * the image(s) should change and if so recalculate all computed properties
     *
     */
    calculateImageProperties() {
      if (!this.hasParentBackgroundImage()) {
        // Reset everything for parent image
        this.parentImage = cloneDeep(Editor.constants.IMAGE_PROPERTIES);
      } else {
        const previousParentImageDisplayValue = this.parentImage.displayValue;
        this.parentImage.displayValue = this.getParentBackgroundImageValue();

        if (previousParentImageDisplayValue !== this.parentImage.displayValue) {
          Editor.actions.setImageProperties(this.parentImage);
        }
      }
      this.backgroundImageEditingEnabled = !this.selectedElementNodeNameMatches(
        'IMG',
      );
      this.$emit(
        ImageManagerConstants.EventTypes.CALCULATE_IMAGE_PORPERTIES,
        this.getImageValue(),
      );
    },

    /**
     * Get background image from parent element if it has one
     *
     * @returns (string|null)
     */
    getBackgroundImageFromParentElement() {
      if (
        this.currentlyEditingChange.elementInfo &&
        this.currentlyEditingChange.elementCount === 1 &&
        this.currentlyEditingChange.elementInfo[0].parentBackground &&
        this.currentlyEditingChange.elementInfo[0].parentBackground
          .backgroundImage
      ) {
        return Editor.fns.getUrlFromStyleValue(
          this.currentlyEditingChange.elementInfo[0].parentBackground
            .backgroundImage,
        );
      }
      return null;
    },

    /**
     * Retrieve the background-image URL for display purposes
     * @returns {string|null}
     */
    getImageValue() {
      const [changeUrl, elementUrl] = [
        Editor.getters.cssValueFromCurrentlyEditingChange('background-image'),
        Editor.getters.cssValueFromElement('background-image'),
      ]
        .map(flux.evaluate)
        .map(Editor.fns.convertProxyUrlToRelativeUrl) // not necessary but not unsafe to pass both through this
        .map(Editor.fns.getUrlFromStyleValue);

      return changeUrl !== null ? changeUrl : elementUrl;
    },

    /**
     * Retrieve the image value (url) of the parent element that has a background image for display purposes
     *
     * @returns {string|null}
     */
    getParentBackgroundImageValue() {
      // Check if the parent element has a change associated with it
      const parentChange = flux.evaluate(
        Editor.getters.getChangeBySelector(
          this.currentlyEditingChangeParentBackground.selector,
        ),
      );

      if (parentChange) {
        return Editor.fns.getUrlFromStyleValue(
          parentChange.getIn(['css', 'background-image']),
        );
      }

      return Editor.fns.convertProxyUrlToRelativeUrl(
        this.getBackgroundImageFromParentElement(),
      );
    },

    /**
     * Check to see if the currently selected change has a parent background image.
     *
     * @returns {boolean}
     */
    hasParentBackgroundImage() {
      return !!this.getBackgroundImageFromParentElement();
    },

    /**
     * Handle image input changed
     */
    imageChanged(url) {
      // We only get null as a value from the color picker when it's destroyed, no need to apply changes.
      if (url === null) {
        return;
      }

      const [storedUrl, elementUrl] = [
        Editor.getters.storedCSSValue('background-image'),
        Editor.getters.cssValueFromElement('background-image'),
      ]
        .map(flux.evaluate)
        .map(Editor.fns.getUrlFromStyleValue);

      // Treat a return to stored value (if one exists) or to the elementInfo value
      // as a revert
      if (
        (storedUrl !== null && url === storedUrl) ||
        (storedUrl === null && url === elementUrl)
      ) {
        Editor.actions.revertCSSProperty('background-image');
      } else {
        Editor.actions.setChangeCSSProperty('background-image', `url(${url})`);
      }

      // Refresh image picker and iframe contents
      this.calculateImageProperties();
      Editor.actions.applyCurrentlyEditingChange();
    },

    /**
     * Explicitly set the value of the change type input fields. Prevent change if the field is focused
     * as that usually indicates that a user is actively making changes to that field. If we were to
     * overwrite these on element change, we'd be constantly modifying the input as they type
     */
    initializeInputFields() {
      if (!$(this.$$.imageInputManager).is(':focus')) {
        const value = flux.evaluate(
          Editor.getters.cssValueFromChangeOrElement('background-image'),
        );
        const url = Editor.fns.getUrlFromStyleValue(value) || '';
        this.$emit(ImageManagerConstants.EventTypes.SET_IMAGE_URL, url);
      }
      if (!$(this.$$.parentImage).is(':focus')) {
        this.$$.parentImage.value = this.parentImage.displayValue;
      }
    },

    revertBackgroundColor() {
      this.revertChangeAttribute('css', 'background-color');
      this.calculateBackgroundColor();
    },

    revertBackgroundImage() {
      Editor.actions.revertCSSProperty('background-image');
      this.calculateImageProperties();
    },

    /**
     * Setup the change editor sidebar image settings and input fields.
     */
    setupChange() {
      if (this.currentlyEditingChange) {
        this.initializeInputFields();
        this.calculateImageProperties();
      }
    },

    /**
     * Passthrough method to call the parent instead of trying to move all of this logic into
     * a mixin.
     * @param change
     */
    switchToParentChange(change) {
      this.$parent.switchToChange(change);
    },
  },

  created() {
    flux.bindVueValues(this, {
      activeFrameId: Editor.getters.activeFrameId,
      changeStatusMap: Editor.getters.changeStatusMap(),
      currentlyEditingChange: Editor.getters.currentlyEditingChange,
      currentlyEditingChangeParentBackground:
        Editor.getters.currentlyEditingChangeParentBackground,
      editingEnabled: Editor.getters.changeEditorEditingEnabled,
      isEditorReadOnly: Editor.getters.isEditorReadOnly,
    });
  },

  ready() {
    ui.renderReactComponent(this, {
      component: ImageInputManager,
      el: this.$$.imageInputManager,
      dataBindings: {
        isEditorReadOnly: Editor.getters.isEditorReadOnly,
        isEditingSelector: Editor.getters.changeEditorIsEditingSelector,
      },
      props: {
        setupChange: this.setupChange.bind(this),
        onImageChange: this.imageChanged.bind(this),
        imageEditingEnabled: !this.isEditingSelector,
      },
    });
    this.calculateBackgroundColor();
    // Observe the currentlyEditingChange and update the change input fields when it changes. This is
    // preferred to using v-value because we can explicitly not update the field that is currently being typed in.
    this.__unwatchCurrentlyEditingChange = flux.observe(
      Editor.getters.currentlyEditingChange,
      this.setupChange.bind(this),
    );
    this.__unwatchCurrentlyEditingChangeId = flux.observe(
      Editor.getters.currentlyEditingChangeId,
      this.calculateBackgroundColor.bind(this),
    );
    this.$.backgroundColorPicker.$on(
      ColorPickerConstants.EVENTS.COLOR_UPDATED,
      this.backgroundColorChanged.bind(this),
    );
  },

  beforeDestroy() {
    if (this.__unwatchCurrentlyEditingChange) {
      this.__unwatchCurrentlyEditingChange();
    }
    if (this.__unwatchCurrentlyEditingChangeId) {
      this.__unwatchCurrentlyEditingChangeId();
    }
  },
};

module.exports = _.merge({}, ChangeEditorSidebarMixin, backgroundComponent);

import React from 'react';
import PropTypes from 'prop-types';

import getSidebar from 'bundles/p13n/components/campaign_manager/sidebar';

import { connect } from 'core/ui/decorators';

import { enums as LayerEnums } from 'optly/modules/entity/layer';

import { getters as SectionModuleGetters } from '../../section_module';

const Sidebar = getSidebar(
  'campaign-overview',
  LayerEnums.campaignTypes.P13N_CAMPAIGN,
);

@connect({
  items: SectionModuleGetters.sideBarItems,
  experiencesHaveUnpublishedChanges:
    SectionModuleGetters.unarchivedExperimentsHaveUnpublishedChanges,
})
class CampaignSidebar extends React.Component {
  static componentId = 'p13n-editor-nav-sidebar';

  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    items: PropTypes.object.isRequired,
    experiencesHaveUnpublishedChanges: PropTypes.bool.isRequired,
  };

  render() {
    return <Sidebar {...this.props} name="Campaign" />;
  }
}

export default CampaignSidebar;

import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'optimizely-oui';

const PageInformation = props => {
  const { pageName, onUpdate, error } = props;

  const onChangePageName = e => {
    onUpdate('name', e.target.value);
  };

  return (
    <div className="soft-double--bottom">
      <Input
        id="name"
        type="text"
        name="name"
        note={error}
        label="Name"
        isRequired={true}
        value={pageName || ''}
        displayError={!!error}
        placeholder="Page name"
        onChange={onChangePageName}
        testSection="configure-view-smart-textfield-input-name"
      />
    </div>
  );
};

PageInformation.propTypes = {
  error: PropTypes.string,
  pageName: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
};

export default PageInformation;

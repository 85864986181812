import React from 'react';
import PropTypes from 'prop-types';
import { Link as ExternalLink, Icon } from 'optimizely-oui';

import { brandPurpleDark } from '@optimizely/design-tokens/dist/json/colors.json';

export const RECOMMENDATIONS_DEFAULT_HELP_LINK =
  'https://help.optimizely.com/Get_Started/Get_started_with_Optimizely_X_Web_Recommendations';

export const RecommendationsActionsText = {
  AlgorithmChange: {
    title: 'Confirm Algorithm Change?',
    message:
      'If you change the algorithm, your events for this recommender will no longer be valid and will be removed.',
    confirmText: 'Change algorithm',
  },
  CatalogChange: {
    title: 'Confirm Catalog Change?',
    message:
      'If you change the catalog, your events and filters for this recommender will no longer be valid and will be removed.',
    confirmText: 'Change catalog',
  },
};

export const RecommendationsCSVDownloadUnavailable = ({ testSection }) => (
  <div
    className="flex push-half--top"
    data-test-section={`recommendations-csv-unavailable-${testSection}`}>
    <div className="push-half--top">
      <Icon color={brandPurpleDark} size="small" name="circle-exclamation" />
    </div>
    <p className="push-half--left flush--bottom micro color--base">
      The CSV for this report hasn't been generated yet. It may take up to 24
      hours for a CSV to be available for download.
    </p>
  </div>
);

RecommendationsCSVDownloadUnavailable.propTypes = {
  testSection: PropTypes.string,
};

RecommendationsCSVDownloadUnavailable.defaultProps = {
  testSection: '',
};

/**
 * Reference link to KB article about recommendations.
 *
 */
export const RecommendationsHelpLink = ({
  helpLink,
  helpText,
  testSection,
}) => (
  <ExternalLink
    href={helpLink}
    newWindow={true}
    testSection={`recommendations-help-link-${testSection}`}>
    Learn more{`${helpText}`}.
  </ExternalLink>
);

RecommendationsHelpLink.propTypes = {
  helpLink: PropTypes.string,
  helpText: PropTypes.string,
  testSection: PropTypes.string,
};

RecommendationsHelpLink.defaultProps = {
  helpLink: RECOMMENDATIONS_DEFAULT_HELP_LINK,
  helpText: '',
  testSection: '',
};

export default {
  RecommendationsActionsText,
  RecommendationsHelpLink,
  RecommendationsCSVDownloadUnavailable,
};

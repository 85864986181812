/**
 * The character to use between <id> and <filter>.
 * @type {string}
 */
export const NAMESPACE_DELIMITER = '__';

export const STORE_KEY = 'loading';

export default {
  NAMESPACE_DELIMITER,
  STORE_KEY,
};

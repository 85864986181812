/**
 * Library to help with manipulating Vue modules
 *
 * @author Sam Jackson (sam@optimizely.com)
 */
const $ = require('jquery');
const _ = require('lodash');

/**
 * Extend Vue Component definitions
 *
 * @param   originalComponent    {Object} Original component definition
 * @param   instantiationOptions {Object} Object with which to augment the parent component
 * @returns {Object}
 */
function extendComponent(originalComponent, instantiationOptions) {
  let component = originalComponent || {};

  // When calling Vue.extend({...}); the original object supplied is modified, and
  // data is mapped to defaultData. For consistencies sake, reset it if we see this has
  // happened so that we can supply the "data" parameter as expected
  if (component.defaultData) {
    component = _.cloneDeep(component);
    component.data = component.defaultData;
    delete component.defaultData;
  }
  return $.extend(true, {}, component, instantiationOptions);
}

/**
 * Recursively removes '$index' property.
 * Sometimes Vue will add an '$index' property which is unneeded and
 * is not a property our JSON validator accepts.
 *
 * @param {object} objectToSanitize
 * @return {object} sanitizedObject
 */
function sanitizeFromVueSideEffects(objectToSanitize) {
  const clonedObjectToSanitize = _.cloneDeep(objectToSanitize);

  const _remove$IndexKey = object => {
    _.each(object, (value, key) => {
      if (key === '$index') {
        delete object[key];
      } else if (typeof object[key] === 'object' && object[key] !== null) {
        _remove$IndexKey(object[key]);
      }
    });
    return object;
  };

  return _remove$IndexKey(clonedObjectToSanitize);
}

module.exports = {
  extendComponent,
  sanitizeFromVueSideEffects,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonRow, ButtonIcon } from 'optimizely-oui';

const TooltipBody = React.forwardRef((props, ref) => (
  <div
    className="soft-double--ends"
    ref={ref}
    style={{
      backgroundColor: 'white',
      borderRadius: '5px',
      width: '500px',
    }}>
    {props.children}
  </div>
));

const TooltipContent = React.forwardRef((props, ref) => (
  <div className="muted soft-double--sides" ref={ref}>
    {props.children}
  </div>
));

const TooltipTitle = React.forwardRef((props, ref) => (
  <div className="beta push--bottom soft-double--sides" ref={ref}>
    {props.children}
  </div>
));

const TooltipFooter = React.forwardRef((props, ref) => (
  <div className="border--top soft--top push-double--top" ref={ref}>
    <div className="soft-double--sides">{props.children}</div>
  </div>
));

export default class ToolTip extends React.Component {
  static propTypes = {
    backProps: PropTypes.object.isRequired,
    closeProps: PropTypes.object.isRequired,
    continuous: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    isLastStep: PropTypes.bool.isRequired,
    primaryProps: PropTypes.object.isRequired,
    size: PropTypes.number.isRequired,
    step: PropTypes.object.isRequired,
    skipProps: PropTypes.object.isRequired,
    tooltipProps: PropTypes.object.isRequired,
  };

  render() {
    const {
      backProps,
      closeProps,
      continuous,
      index,
      isLastStep,
      primaryProps,
      skipProps,
      size,
      step,
      tooltipProps,
    } = this.props;

    const buttons = [];

    if (index > 0) {
      buttons.push(
        <Button {...backProps} key="back" style="plain">
          Back
        </Button>,
      );
    }

    if (continuous && !isLastStep) {
      buttons.push(
        <Button {...primaryProps} key="next" style="highlight">
          Next
        </Button>,
      );
    }

    if (!continuous || isLastStep) {
      buttons.push(
        <Button {...closeProps} key="done" style="highlight">
          Done
        </Button>,
      );
    }

    return (
      <>
        <div
          style={{
            position: 'absolute',
            right: '0px',
            padding: '5px',
          }}>
          <ButtonIcon
            {...skipProps}
            iconName="xmark"
            size="small"
            style="plain"
          />
        </div>
        <TooltipBody {...tooltipProps}>
          {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
          <TooltipContent>{step.content}</TooltipContent>
          <TooltipFooter>
            <ButtonRow
              leftGroup={[
                <div
                  className="weight--bold push-half--top"
                  key="step-indicator">
                  {index + 1} of {size}
                </div>,
              ]}
              rightGroup={buttons}
            />
          </TooltipFooter>
        </TooltipBody>
      </>
    );
  }
}

/**
 * Simple wrapper around our preferred object deep cloning library.  Currently this is
 * the `rfdc` library, which has the benefits of being very fast as well as it does not
 * trigger the "iterable.length has been deprecated" console warning when the cloned
 * object tree contains Immutable collection objects.  Prefer using this over lodash's
 * cloneDeep function. Note that this cloneDeep does not actually create functioning
 * copies of immutable objects, which means you'll need to manually copy these objects
 * after the clone operation completes. This is a good thing, as it will make you more
 * intentional and careful when cloning an object tree known to contain immutables.
 */
import rfdc from 'rfdc';

const cloneDeep = rfdc();

export default cloneDeep;

import flux from 'core/flux';
import { isImmutable } from 'optly/immutable';
import LayerExperimentFns from 'optly/modules/entity/layer_experiment/fns';
import LayerExperimentActions from 'optly/modules/entity/layer_experiment/actions';
import ui from 'core/ui';

import { stopCreatingVariation } from './dispatch_fns';

import getters from '../getters';

/**
 * If newName is a valid name for a new variation in the current experiment,
 * save it and show a loading spinner while saving. Otherwise show a
 * notification with the validation error message.
 * @param {String} newName
 * @param {Immutable.Map} experiment
 * @return {Deferred} The save deferred, or a rejected deferred if the name is
 * invalid
 */
const trySavingNewVariation = (newName, experiment) => {
  if (!experiment) {
    experiment = flux.evaluateToJS(getters.singleExperiment);
  }
  if (isImmutable(experiment)) {
    experiment = experiment.toJS();
  }
  const validation = LayerExperimentFns.validateVariationName(
    experiment,
    newName,
  );
  if (!validation.valid) {
    ui.showNotification({
      message: validation.message,
      type: 'error',
    });
    return $.Deferred().reject({
      message: validation.message,
      type: 'name error',
    });
  }
  const saveDef = LayerExperimentActions.createNewVariationWithRedistributedTraffic(
    newName,
    experiment,
  ).always(() => {
    stopCreatingVariation();
  });
  ui.loadingWhen('variations-panel', saveDef);
  return saveDef;
};

export default trySavingNewVariation;

/**
 * Services layer pure functions for the Plugins
 */
import Immutable, { toImmutable } from 'optly/immutable';

import enums from './enums';

/**
 * Fills in the project_id and any form_schema / options defaults based on the plugin_type
 * @param {Object} plugin
 * @param {Number} projectId
 * @return {Object}
 */
export function fillDefaults(plugin, projectId, snippetGlobalName) {
  switch (plugin.plugin_type) {
    case enums.plugin_type.WIDGET:
      return getDefaultWidgetWithIdReferences(
        plugin,
        projectId,
        snippetGlobalName,
      );
    case enums.plugin_type.EVENT:
      return getDefaultEventExtension(plugin, projectId);
    case enums.plugin_type.ANALYTICS_INTEGRATION:
      return getDefaultAnalyticsExtension(plugin, projectId);
    default:
      throw new Error(`Invalid plugin_type="${plugin.plugin_type}"`);
  }
}

function getDefaultWidgetWithIdReferences(
  plugin,
  projectId,
  snippetGlobalName,
) {
  const pluginName = plugin.name || '';
  const attributeSafeWidgetName = encodeURIComponent(
    pluginName.replace(/\s/g, '-').toLowerCase(),
  );
  let applyJS = enums.applyJsBySnippetGlobal[snippetGlobalName];
  applyJS = applyJS === undefined ? enums.defaultCode.APPLY_JS : applyJS;

  return {
    ...plugin,
    project_id: projectId,
    form_schema: [
      createSchemaField({
        name: 'text',
        label: 'A text field',
        default_value: 'My Butterbar',
      }),
    ],
    options: {
      html: `<div id="optimizely-extension-{{ extension.$instance }}" class="${attributeSafeWidgetName}">\n  {{ extension.text }}\n</div>`,
      css: `.${attributeSafeWidgetName} {
  background-color: #fff575;
  border-bottom: 1px solid #e0d769;
  color: #555;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}`,
      apply_js: applyJS,
      undo_js: enums.defaultCode.UNDO_JS,
    },
  };
}

function getDefaultEventExtension(plugin, projectId) {
  return {
    ...plugin,
    project_id: projectId,
  };
}

function getDefaultAnalyticsExtension(plugin, projectId) {
  return {
    ...plugin,
    project_id: projectId,
    options: {
      track_layer_decision: enums.defaultCode.TRACK_LAYER_DECISION,
    },
  };
}

export function createSchemaField(config) {
  const DEFAULTS = {
    field_type: enums.fieldType.TEXT,
    name: '',
    label: '',
    default_value: '',
    options: null,
  };

  return {
    ...DEFAULTS,
    ...config,
  };
}

export function createBlankSchemaField() {
  // TODO(jordan): invariant
  return createSchemaField({});
}

export function createBlankWidget() {
  return Immutable.Map({
    plugin_id: '',
    plugin_type: enums.plugin_type.WIDGET,
    name: '',
    description: '',
    is_enabled_in_client: false,
    // TODO(jordan): remove when data model is real
    form_schema: [],
    options: {
      html: '',
      css: '',
      apply_js: '',
      undo_js: '',
    },
  });
}

export function createBlankEventExtension() {
  return Immutable.Map({
    plugin_id: '',
    plugin_type: enums.plugin_type.EVENT,
    name: '',
    description: '',
    is_enabled_in_client: false,
    // TODO(jordan): remove when data model is real
    form_schema: [],
    options: {
      attach_js: '',
      detach_js: '',
    },
  });
}

export function createBlankAnalyticsExtension() {
  return Immutable.Map({
    plugin_id: '',
    plugin_type: enums.plugin_type.ANALYTICS_INTEGRATION,
    name: '',
    description: '',
    is_enabled_in_client: false,
    form_schema: [],
    options: {
      track_layer_decision: '',
    },
  });
}

// TODO(ryan): Should remove this status computation when it is returned via the api.
export function getDisplayStatus(plugin) {
  const immutablePlugin = toImmutable(plugin);
  if (immutablePlugin.get('is_enabled_in_client')) {
    return enums.displayStatuses.ENABLED;
  }
  if (immutablePlugin.get('archived')) {
    return enums.displayStatuses.ARCHIVED;
  }
  return enums.displayStatuses.DRAFT;
}

export default {
  createBlankAnalyticsExtension,
  createBlankEventExtension,
  createBlankSchemaField,
  createBlankWidget,
  createSchemaField,
  fillDefaults,
  getDisplayStatus,
};

import { actions as ExperimentSectionActions } from 'optly/modules/entity/experiment_section';
import { actions as LayerActions } from 'optly/modules/entity/layer';
/**
 * @param {Array} sectionsToSave
 * @param {Immutable.Map} currentLayer
 * @returns {Promise}
 */
function createNewSections(sectionsToSave, currentLayer) {
  return Promise.all(
    sectionsToSave.map(sectionToSave =>
      ExperimentSectionActions.save(sectionToSave),
    ),
  ).then(
    sections =>
      new Promise(resolve => {
        const sectionIds = currentLayer
          .get('section_ids')
          .push(...sections.map(section => section.id))
          .toJS();
        return LayerActions.save({
          id: currentLayer.get('id'),
          section_ids: sectionIds,
        }).then(() => {
          resolve(sections);
        });
      }),
  );
}

export default createNewSections;

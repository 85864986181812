import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

const INITIAL_STATE = {
  selector: null,
  viewId: null,
  eventId: null,
  name: '',
  enabled: false,
};

/**
 * trackClicksStore
 */
export default Nuclear.Store({
  getInitialState() {
    return toImmutable(INITIAL_STATE);
  },
  initialize() {
    this.on(actionTypes.TRACK_CLICKS_SET_EVENT, setClickEvent);
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.name - User provided event name
 * @param {String} payload.category - Event category
 * @param {String} payload.selector
 * @param {String} payload.viewId
 * @param {Boolean} payload.enabled
 */
function setClickEvent(state, payload) {
  return state.merge(payload);
}

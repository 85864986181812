import actions from './actions';
import constants from './constants';
import getters from './getters';

export { actions, constants, getters };

export default {
  actions,
  constants,
  getters,
};

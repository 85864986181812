export const SIMPLE_MATCH_TYPE = 'simple';
export const EXACT_MATCH_TYPE = 'exact';
export const SUBSTRING_MATCH_TYPE = 'substring';
export const REGEX_MATCH_TYPE = 'regex';

export const URL_MATCH_TYPES = [
  {
    label: 'Simple Match',
    value: SIMPLE_MATCH_TYPE,
    description: 'Useful for testing a single page',
  },
  {
    label: 'Exact Match',
    value: EXACT_MATCH_TYPE,
    description: 'Use only to load the exact URL',
  },
  {
    label: 'Substring Match',
    value: SUBSTRING_MATCH_TYPE,
    description: 'Use to match specific strings of texts within a URL',
  },
  {
    label: 'Regular Expression Match',
    value: REGEX_MATCH_TYPE,
    description: 'Use to target complicated URL structures',
  },
];

export const SINGLE_URL_MATCH_TYPES = [
  {
    label: 'Simple Match',
    value: SIMPLE_MATCH_TYPE,
    description: 'Useful for testing a single page',
  },
  {
    label: 'Exact Match',
    value: EXACT_MATCH_TYPE,
    description: 'Use only to load the exact URL',
  },
];

export const DEFAULT_URL_CONDITION = {
  type: 'url',
  value: '',
  match_type: 'simple',
};

export default {
  SIMPLE_MATCH_TYPE,
  EXACT_MATCH_TYPE,
  SUBSTRING_MATCH_TYPE,
  REGEX_MATCH_TYPE,
  URL_MATCH_TYPES,
  SINGLE_URL_MATCH_TYPES,
  DEFAULT_URL_CONDITION,
};

/**
 * @author Derek Hammond (derek@optimizely.com)
 */
import keyMirror from 'optly/utils/key_mirror';

/**
 * Support Portal Views
 */
export const steps = keyMirror({
  HELP_CENTER_HOME: null,
  CHOOSE_CATEGORY: null,
  CHOOSE_CASE_TYPE: null,
  REQUEST_FORM: null,
  TICKET_STATUS: null,
});

/**
 * Feedback Component Views
 */
export const feedbackView = keyMirror({
  FEEDBACK_INPUT: null,
  CONFIRMATION: null,
});

/**
 * Zendesk ticket types
 */
export const ticketType = keyMirror({
  PRODUCT_FEEDBACK: null,
  SUPPORT: null,
  ONBOARDING_FEEDBACK: null,
});

export const CaseTypeCategory = keyMirror({
  BILLING: null,
  ACCOUNT: null,
  DEVELOPER: null,
  OASIS: null,
  WEB: null,
  FREE_ROLLOUTS: null,
});

export const fields = keyMirror({
  ENTITY_ID: null,
  SDK_LANGUAGE: null,
  SDK_VERSION: null,
  PRIORITY: null,
});

/**
 * Optimizely Product Zendesk Form Value/Text Mapping
 */
export const categories = {
  BILLING: {
    value: 'BILLING',
    text: 'Billing',
    CASE_TYPE_CATEGORY: CaseTypeCategory.BILLING,
  },
  ACCOUNT: {
    value: 'ACCOUNT',
    text: 'Account and Privacy',
    CASE_TYPE_CATEGORY: CaseTypeCategory.ACCOUNT,
  },
  DEVELOPER: {
    value: 'DEVELOPER',
    text: 'Developer Support (REST API, etc.)',
    CASE_TYPE_CATEGORY: CaseTypeCategory.DEVELOPER,
  },
  FREE_ROLLOUTS: {
    value: 'FREE_ROLLOUTS',
    text: 'Optimizely Rollouts',
    CASE_TYPE_CATEGORY: CaseTypeCategory.FREE_ROLLOUTS,
  },
  X_FULL_STACK: {
    value: 'X_FULL_STACK',
    text: 'Optimizely X Full Stack',
    CASE_TYPE_CATEGORY: CaseTypeCategory.OASIS,
  },
  X_MOBILE: {
    value: 'X_MOBILE',
    text: 'Optimizely X Mobile (iOS/Android)',
    CASE_TYPE_CATEGORY: CaseTypeCategory.OASIS,
  },
  X_OTT: {
    value: 'X_OTT',
    text: 'Optimizely X OTT',
    CASE_TYPE_CATEGORY: CaseTypeCategory.OASIS,
  },
  X_WEB_EDGE: {
    value: 'X_WEB_EDGE',
    text: 'Optimizely X Web Edge',
    CASE_TYPE_CATEGORY: CaseTypeCategory.WEB,
  },
  X_WEB_EXPERIMENTATION: {
    value: 'X_WEB_EXPERIMENTATION',
    text: 'Optimizely X Web Experimentation',
    CASE_TYPE_CATEGORY: CaseTypeCategory.WEB,
  },
  X_WEB_PERSONALIZATION: {
    value: 'X_WEB_PERSONALIZATION',
    text: 'Optimizely X Web Personalization',
    CASE_TYPE_CATEGORY: CaseTypeCategory.WEB,
  },
  X_WEB_RECOMMENDATIONS: {
    value: 'X_WEB_RECOMMENDATIONS',
    text: 'Optimizely X Web Recommendations',
    CASE_TYPE_CATEGORY: CaseTypeCategory.WEB,
  },
  PROGRAM_MANAGEMENT: {
    value: 'PROGRAM_MANAGEMENT',
    text: 'Program Management',
    CASE_TYPE_CATEGORY: CaseTypeCategory.PROGRAM_MANAGEMENT,
  },
};

/**
 * For Zendesk Case Type Custom Field, presented in CHOOSE_CASE_TYPE step
 */
export const caseType = keyMirror({
  // developer specific case types
  CODE_SAMPLES: null,
  DOCUMENTATION_ERRORS: null,
  REST_API: null,

  // oasis specific case types
  SDK_INSTALLATION: null,
  SDK_TROUBLESHOOTING: null,

  // web specific case types
  EDITOR_LOADING: null,

  // general case types used by multiple products
  ACCOUNT_SETTINGS: null,
  EXPERIMENT_QA: null,
  EXPERIMENT_SETUP: null,
  GOALS: null,
  INTEGRATIONS: null,
  OTHER: null,
  RESULTS: null,
  SALES_BILLING: null,
});

/**
 * Detects the managing team based off
 * of a provided contintent abbreviation
 */
export const detectManagingTeamByContinent = {
  NA: 'managing_team_us',
  SA: 'managing_team_us',
  EU: 'managing_team_emea',
  AF: 'managing_team_emea',
  AS: 'managing_team_apac',
  OC: 'managing_team_apac',
};

export const DROPZONE = {
  MAX_CHECKS_ALLOWED: 10,
  MAX_FILE_SIZE_ERROR_SUBSTRING: 'File is too big',
  FILE_TOO_BIG_ERROR: 'Max file size: 10 MB',
  MAX_FILE_SIZE_ALLOWED: 10, // In MB
  FILE_TOO_LARGE_HTTP_STATUS_CODE: 413,
};

export const ORGANIZATION_INFO = {
  NONE_AVAILABLE: {
    name: 'No organizations available',
    id: 'no_organization_available',
  },
  DEFAULT: {
    name: '-',
    id: '-', // string with one blank character since passing an empty string will cause the html select element to have a value of '-'
  },
};

export const SDK_LANGUAGES = {
  FULL_STACK: [
    {
      label: '-',
      value: '-', // string with one blank character since passing an empty string will cause the html select element to have a value of '-'
    },
    {
      label: 'C#',
      value: 'c_sharp_sdk',
    },
    {
      label: 'Java',
      value: 'java_sdk',
    },
    {
      label: 'JavaScript',
      value: 'javascript_sdk',
    },
    {
      label: 'Node',
      value: 'node_sdk',
    },
    {
      label: 'PHP',
      value: 'php_sdk',
    },
    {
      label: 'Python',
      value: 'python_sdk',
    },
    {
      label: 'Ruby',
      value: 'ruby_sdk',
    },
  ],
  MOBILE: [
    {
      label: '-',
      value: '-', // string with one blank character since passing an empty string will cause the html select element to have a value of '-'
    },
    {
      label: 'Android',
      value: 'android_sdk',
    },
    {
      label: 'iOS (Objective-C)',
      value: 'objective_c_sdk',
    },
    {
      label: 'iOS (Swift)',
      value: 'swift_sdk',
    },
  ],
  OTT: [
    {
      label: '-',
      value: '-', // string with one blank character since passing an empty string will cause the html select element to have a value of '-'
    },
    {
      label: 'Android TV',
      value: 'android_tv_sdk',
    },
    {
      label: 'tvOS (Objective-C)',
      value: 'tv_os_objective_c_sdk',
    },
    {
      label: 'tvOS (Swift)',
      value: 'tv_os_swift_sdk',
    },
  ],
};

export const PRIORITIES = [
  {
    label:
      '1 - Critical: Your website or application is unusable or unresponsive',
    value: 'urgent',
  },
  {
    label: '2 - Urgent: Key features of the Optimizely Platform are unusable',
    value: 'high',
  },
  {
    label: '3 - High: Normal usage of the Optimizely Platform is affected',
    value: 'normal',
  },
  {
    label: '4 - Medium: Low-impact issue/request for information',
    value: 'low',
  },
];

/**
 * Enums for Support.actions.createZendeskTicket(), used in ticket_status.html
 */
export const requestStatus = keyMirror({
  SUCCESS: null,
  DEFERRED: null,
});

export const PrioritySupportLink =
  'https://outlook.office365.com/owa/calendar/ExperimentationSupport1@episerver99.onmicrosoft.com/bookings/';

export default {
  CaseTypeCategory,
  categories,
  caseType,
  detectManagingTeamByContinent,
  DROPZONE,
  feedbackView,
  fields,
  ORGANIZATION_INFO,
  PRIORITIES,
  PrioritySupportLink,
  requestStatus,
  SDK_LANGUAGES,
  steps,
  ticketType,
};

/**
 * module analagous to crud_acions but with entirely stubbed data
 * Used for testing
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
import $ from 'jquery';
import _ from 'lodash';
import sprintf from 'sprintf';

import cloneDeep from 'optly/clone_deep';
import flux from 'core/flux';
import { toImmutable } from 'optly/immutable';

import actionTypes from './action_types';
import fns from './fns';
import constants from './constants';

export function fetch(entityDef, id) {
  const entityData = valididateAndDeserialize(
    getTestData(entityDef),
    entityDef,
  );

  const results = _.isObject(id)
    ? _.find(entityData, id)
    : _.find(entityData, { id });

  if (!results) {
    return reject();
  }
  const timeout = getApiTimeout(results, constants.apiActions.FETCH);
  return resolve({ data: results, responseHeaders: {} }, timeout);
}

export function fetchAll(entityDef, filters) {
  const entityData = valididateAndDeserialize(
    getTestData(entityDef),
    entityDef,
  );

  // coerce to map
  const entityMap = toImmutable({}).withMutations(entityMap => {
    entityData.forEach((item, id) => {
      entityMap.set(id, toImmutable(item));
    });
  });

  const results = fns.getAll(entityMap, entityDef, filters).toJS();
  const timeout = getApiTimeout(results, constants.apiActions.FETCH_ALL);
  return resolve({ data: results, responseHeaders: {} }, timeout);
}

export function fetchPage(entityDef, filters) {
  const entityData = valididateAndDeserialize(
    getTestData(entityDef),
    entityDef,
  );

  // coerce to map
  const entityMap = toImmutable({}).withMutations(entityMap => {
    entityData.forEach((item, id) => {
      entityMap.set(id, toImmutable(item));
    });
  });

  const results = fns.getPage(entityMap, entityDef, filters).toJS();
  const timeout = getApiTimeout(results, constants.apiActions.FETCH_PAGE);
  return resolve({ data: results, responseHeaders: {} }, timeout);
}

export function save(entityDef, instance, options) {
  const entityData = getTestData(entityDef);

  if (!entityData) {
    throw new Error(
      sprintf("API is stubbed, no entries for entity: '%s'", entityDef.entity),
    );
  }

  let res = cloneDeep(
    entityDef.isSubresource
      ? entityData.find(e => e.id === (instance.id || options.id))
      : instance,
  );
  if (entityDef.isSubresource) {
    // Simulate a subresource key update by finding the key within the
    // root entity and updating it with the new instance provided.
    const { parent, match } = _.reduce(
      entityDef.parents,
      (current, parentConfig) => {
        if (parentConfig.entity === entityDef.entity) {
          // If this is the root entity, just continue. We already have it.
          return current;
        }
        const parentKeyId =
          instance[parentConfig.key] || options[parentConfig.key];
        if (!parentKeyId) {
          throw new Error(
            `${parentConfig.key} must be supplied in instance or options.`,
          );
        }
        current.parent =
          current.match[parentConfig.entityName || parentConfig.entity];
        current.match = Array.isArray(current.parent)
          ? current.parent.find(e => e[parentConfig.key] === parentKeyId)
          : current.parent[parentKeyId];
        return current;
      },
      { parent: null, match: res },
    );

    if (!parent) {
      throw new Error(
        'crud_stubs.save couldnt find a parent for the child update.',
      );
    } else if (Array.isArray(parent)) {
      if (!match) {
        parent.push(instance);
      } else {
        parent[parent.indexOf(match)] = instance;
      }
    } else {
      Object.assign(match, instance);
    }
    res.last_modified = new Date().toString();
  } else if (!instance.id) {
    // Look for an entity with a value of null, to see if new entity responses should be stubbed
    const newEntityStub = entityData.find(entity => entity.id === null);
    if (newEntityStub) {
      console.log('Found stubbed entity with "id: null"');
      // Overwrite the null id and generate a random number like our API / backend would
      res = {
        ...res,
        ...newEntityStub,
      };
    }
    // Do a PATCH style operation and assign properties to the "new" entity
    res = {
      ...res,
      created: new Date().toString(),
      id: Math.floor(Math.random() * 1000000 + 1),
      last_modified: new Date().toString(),
    };
  } else {
    // Saving by id, simulate a PATCH
    const existingEntityStub = entityData.find(
      entity => entity.id === instance.id,
    );
    if (!existingEntityStub) {
      throw new Error(
        `Cannot find existing entity by id to do PUT, id = ${instance.id}`,
      );
    }

    // Do a PATCH style operation and assign properties on the existing object
    res = {
      ...existingEntityStub,
      ...res,
      last_modified: new Date().toString(),
    };
  }
  setTestDataEntity(entityDef, res);
  const timeout = getApiTimeout(res, constants.apiActions.SAVE) || 0;
  return resolve(res, timeout);
}

function deleteEntity(entityDef, instance) {
  const timeout = getApiTimeout(instance, constants.apiActions.DELETE) || 0;
  return resolve(null, timeout);
}

function getTestData(entityDef) {
  return flux.evaluateToJS([
    ['apiStubs', 'entityData', entityDef.entity],
    function(entityMap) {
      if (!entityMap) {
        return [];
      }
      return entityMap.toList();
    },
  ]);
}

function setTestDataEntity(entityDef, instance) {
  flux.dispatch(actionTypes.API_STUB_UPDATE_ENTITY, {
    entity: entityDef.entity,
    instance,
  });
}

/**
 * Validate the entityDef and deserialize it if applicable.
 * @param {object} entityData
 * @param {object} entityDef
 * @return {object}
 */
function valididateAndDeserialize(entityData, entityDef) {
  if (!entityData) {
    throw new Error(
      sprintf('API is stubbed, no entries for entity: "%s"', entityDef.entity),
    );
  } else if (_.has(entityDef, 'deserialize')) {
    return entityData.map(entry => entityDef.deserialize(cloneDeep(entry)));
  }
  return entityData;
}

/**
 * Returns a deferred that will resolve with the passed in data
 */
function resolve(data, timeout) {
  const def = $.Deferred();
  if (timeout === undefined) {
    timeout = getApiTimeout(data);
  }

  if (timeout !== null) {
    setTimeout(() => {
      def.resolve(data);
    }, timeout);
  } else {
    def.resolve(data);
  }
  return def;
}

/**
 * Returns a deferred that will reject with the passed in data
 */
function reject(data, timeout) {
  const def = $.Deferred();
  if (timeout === undefined) {
    timeout = getApiTimeout(data);
  }

  if (timeout !== null) {
    setTimeout(() => {
      def.reject(data);
    }, timeout);
  } else {
    def.reject(data);
  }
  return def;
}

/*
 * Returns the timeout set for a given API CRUD action.
 * If the timeout is a function based on the results, then
 * evaluate the function and return the timeout.
 * @param {array|object} data
 * @param {string} method optional string indicating what timeout to use
 */
function getApiTimeout(data, method) {
  const timeoutKey = method || 'default';
  const timeouts = flux.evaluateToJS(['apiStubs', 'timeouts']);

  const timeout = timeouts[timeoutKey];
  if (_.isFinite(timeout)) {
    return timeout;
  }

  if (_.isFunction(timeout)) {
    return timeout(data);
  }

  return null;
}

export { deleteEntity as delete };

export default {
  delete: deleteEntity,
  fetch,
  fetchAll,
  fetchPage,
  save,
};

import keyMirror from 'optly/utils/key_mirror';

export const matchType = keyMirror({
  CONTAINS: null,
  EQUALS: null,
});

export const tableIds = keyMirror({
  P13N_LAYERS: null,
  P13N_DATA_LAYER_ATTRIBUTES: null,
  P13N_DATA_LAYER_EVENTS: null,
  P13N_DATA_LAYER_VIEWS: null,
  P13N_DATA_LAYER_PLUGINS: null,
  P13N_DATA_LAYER_AUDIENCES: null,
  P13N_DATA_LAYER_PAGE_EVENTS: null,
  P13N_DATA_LAYER_METRICS: null,
  P13N_PREVIEW_UI_FEED: null,
  P13N_CAMPAIGN_OVERVIEW_DASH: null,
});

export const status = keyMirror({
  ACTIVE: null,
  ARCHIVED: null,
});

export const projectType = {
  ALL: 'All',
  WEB: 'Web',
  FEATURE_EXPERIMENTATION: 'Feature Experimentation',
  EDGE: 'Edge',
  FULL_STACK: 'Full Stack',
};

export const PROJECT_OPTIONS = [
  { label: 'All', value: projectType.ALL },
  { label: 'Web', value: projectType.WEB },
  {
    label: 'Feature Experimentation',
    value: projectType.FEATURE_EXPERIMENTATION,
  },
  { label: 'Edge', value: projectType.EDGE },
];

export default {
  matchType,
  tableIds,
  PROJECT_OPTIONS,
  projectType,
  status,
};

import _ from 'lodash';

import cloneDeep from 'optly/clone_deep';
import Immutable, { isImmutable, toImmutable, toJS } from 'optly/immutable';
import tr from 'optly/translate';

import enums from './enums';

const { ALL_AUDIENCES, ANY_AUDIENCES } = enums.audienceMatchTypes;

// Functions to extract various parameters of the behavioral audience rule
// schema specified here:
// https://confluence.sso.episerver.net/display/EXPENG/Behavioral+Targeting+Condition+Schema

/**
 * Gets parameters for the action clause of the new schema.
 * @param {Object} whereClause The where clause of the rule.
 * @returns {Object} The action parameters.
 * @private
 */
function getActionParameters(whereClause) {
  if (!whereClause) {
    return null;
  }

  // First entry must be 'and'.
  if (
    !_.isArray(whereClause) ||
    whereClause.length < 2 ||
    whereClause[0] !== 'and'
  ) {
    throw new Error(
      `Unexpected "where" clause format ${JSON.stringify(whereClause)}`,
    );
  }

  let action = null;

  whereClause.slice(1).forEach(item => {
    // First entry must be 'or'.
    if (!_.isArray(item) || item.length < 2 || item[0] !== 'or') {
      throw new Error(
        `Unexpected "where" clause entry ${JSON.stringify(item)}`,
      );
    }

    const desc = item[1];
    const label = desc.label_;
    if (label === 'eventType' || label === 'event' || label === 'goal') {
      action = action || {};
    }

    if (label === 'eventType') {
      action.type = desc.args[1].value;
    } else if (label === 'event' || label === 'goal') {
      action.value = desc.args[1].value;
    }
  });

  return action;
}

/**
 * Gets parameters for the time filter clause of the new schema.
 * @param {Object} whereClause The where clause of the rule.
 * @returns {Object} The time filter parameters.
 * @private
 */
function getTimeParameters(whereClause) {
  if (!whereClause) {
    return null;
  }

  // First entry must be 'and'.
  if (
    !_.isArray(whereClause) ||
    whereClause.length < 2 ||
    whereClause[0] !== 'and'
  ) {
    throw new Error(
      `Unexpected "where" clause format ${JSON.stringify(whereClause)}`,
    );
  }

  let time = null;

  whereClause.slice(1).forEach(item => {
    // First entry must be 'or'.
    if (!_.isArray(item) || item.length < 2 || item[0] !== 'or') {
      throw new Error(
        `Unexpected "where" clause entry ${JSON.stringify(item)}`,
      );
    }

    const desc = item[1];
    if (desc.label_ === 'time') {
      time = time || {};
      if (desc.op === 'between') {
        time.type = 'range';
        time.start = desc.args[1].value;
        time.stop = desc.args[2].value;
      } else if (desc.op === 'lt') {
        time.type = 'last_days';
        time.days = desc.period_;
      } else {
        throw new Error(`Unexpected "time" operator: ${desc.op}`);
      }
    }
  });

  return time;
}

/**
 * Gets parameters for the time filter clause of the new schema.
 * @param {Object} havingClause The having clause of the rule.
 * @returns {Object} The count filter parameters.
 * @private
 */
function getCountParameters(havingClause) {
  if (!havingClause) {
    return null;
  }

  // First entry must be 'and'.
  if (
    !_.isArray(havingClause) ||
    havingClause.length < 2 ||
    havingClause[0] !== 'and'
  ) {
    throw new Error(
      `Unexpected "having" clause format ${JSON.stringify(havingClause)}`,
    );
  }

  let count = null;

  havingClause.slice(1).forEach(item => {
    // First entry must be 'or'.
    if (!_.isArray(item) || item.length < 2 || item[0] !== 'or') {
      throw new Error(
        `Unexpected "having" clause entry ${JSON.stringify(item)}`,
      );
    }

    const desc = item[1];
    if (desc.label_ === 'count') {
      count = count || {};
      count.comparator = desc.op;
      count.value = desc.args[1].value;
    }
  });

  return count;
}

/**
 * Gets parameters for the tag filters clause of the new schema.
 * @param {Object} whereClause The where clause of the rule.
 * @returns {Object} The tag filter parameters.
 * @private
 */
function getFilters(whereClause) {
  if (!whereClause) {
    return null;
  }

  // First entry must be 'and'.
  if (
    !_.isArray(whereClause) ||
    whereClause.length < 2 ||
    whereClause[0] !== 'and'
  ) {
    throw new Error(
      `Unexpected "where" clause format ${JSON.stringify(whereClause)}`,
    );
  }

  let filters = null;

  whereClause.slice(1).forEach(item => {
    // First entry must be 'or'.
    if (!_.isArray(item) || item.length < 2 || item[0] !== 'or') {
      throw new Error(
        `Unexpected "where" clause entry ${JSON.stringify(item)}`,
      );
    }

    const desc = item[1];
    if (desc.label_ === 'tag') {
      filters = filters || [];
      filters.push({
        name: desc.field_,
        datatype: desc.tagType_ || 'string',
        comparator: desc.op,
        value: desc.value_,
      });
    }
  });

  return filters;
}

/**
 * Services layer pure functions for the audiences
 */

/**
 * API Audiences have no conditions
 * @param {Immutable.Map} audience
 * @return {Set} conditionTypes
 */
export function allAudienceConditions(audience) {
  const appendMoreConditionTypes = function(conditionsList, conditionTypesSet) {
    conditionsList.forEach(condition => {
      /* a condition can either be:
       *  - a string ("and" or "or"),
       *  - a object with "type" (the condition itself),
       *  - or a List (a new list of conditions)
       */

      if (_.isString(condition)) {
        return conditionTypesSet;
      }

      const conditionType = condition.get('type');

      if (conditionType) {
        conditionTypesSet = conditionTypesSet.add(conditionType);
      } else {
        conditionTypesSet = appendMoreConditionTypes(
          condition,
          conditionTypesSet,
        );
      }
    });

    return conditionTypesSet;
  };

  let conditions = audience.get('conditions');

  if (conditions === undefined) {
    return Immutable.Set();
  }

  if (_.isString(conditions)) {
    conditions = toImmutable(JSON.parse(conditions));
  }

  return appendMoreConditionTypes(conditions, Immutable.Set());
}

/**
 * Takes custom audience code and constructs it into a string that is plain readable English
 * @param {Array} customCode
 * @param {Object} currentProjectAudiencesMap
 * @return {String} audienceLabel
 */
export function constructCustomAudienceLabel(
  customCode,
  currentProjectAudiencesMap,
) {
  const _constructNestedCustomCode = function(conditions, map) {
    if (_.isArray(conditions)) {
      const operator = conditions.shift();
      let string;
      if (operator === 'not') {
        string = tr('not ') + _constructNestedCustomCode(conditions[0], map);
      } else {
        string = _.reduce(conditions, (partialString, newItem) => {
          partialString = _constructNestedCustomCode(partialString, map);
          newItem = _constructNestedCustomCode(newItem, map);
          return partialString.concat(` ${tr(operator)} ${newItem}`);
        });
      }
      return `(${_constructNestedCustomCode(string, map)})`;
    }
    if (_.isString(conditions)) {
      return conditions;
    } else { // eslint-disable-line
      const audienceName = conditions
        ? map[conditions.audience_id] || tr(`ID:${conditions.audience_id}`)
        : tr('Everyone');

      // account for audience names with operators in it (ex. 'Chrome and Firefox users')
      const hasOperator = _.filter(
        audienceName.split(' '),
        partialString =>
          partialString.toLowerCase() === tr('and') ||
          partialString.toLowerCase() === tr('or'),
      );
      return hasOperator.length > 0 ? `(${audienceName})` : audienceName;
    }
  };

  const audienceLabel = _constructNestedCustomCode(
    customCode,
    currentProjectAudiencesMap,
  );
  return audienceLabel.slice(1, audienceLabel.length - 1);
}

/**
 * Return true if given audiences contains at least one adaptive audience that has `unavailable` adaptive_audience_status
 * @param {Immutable.List} audiences
 * @return {Boolean}
 */
export function hasAnyProcessingAdaptiveAudienceConditions(audiences) {
  return !!audiences.find(audience =>
    hasProcessingAdaptiveAudienceStatus(audience),
  );
}

/**
 * Return true if given audience is adaptive audience and has 'unavailable' adaptive_audience_status
 * @param {Immutable.Map} audience
 * @return {Boolean}
 */
export function hasProcessingAdaptiveAudienceStatus(audience) {
  return (
    audience.get('adaptive_audience_status') ===
    enums.AdaptiveAudienceStatuses.UNAVAILABLE
  );
}

/**
 * API Audiences have no conditions
 * @param {Model} audience
 * @return {Boolean}
 */
export function isAPIAudience(audience) {
  // TODO: resolve circular dependency here that the inline require band-aids
  const conditionService = require('optly/services/condition'); // eslint-disable-line global-require
  if (!audience.id) {
    return false;
  }
  return conditionService.isEmpty(audience.conditions);
}

/**
 * Parses custom code to validate whether or not it's acceptable custom code
 * @param {String} customCode
 * @return {Array} audienceConditions
 */
export function validateCustomCode(customCode) {
  let audienceConditions;

  if (customCode === '') {
    audienceConditions = null;
  } else {
    audienceConditions = JSON.parse(customCode);
  }

  return audienceConditions;
}

/**
 * Constructs audience conditions based on 'all' or 'any' audience match type, and optional stringifies them
 * @param {Array|Immutable.List} audienceIds
 * @param {String} matchType - of type enums.audienceMatchTypes.ANY_AUDIENCES or enums.audienceMatchTypes.ALL_AUDIENCES
 * @param {Object} options
 * @param {Boolean} options.stringify
 * @return {Array|String} audienceConditions
 */
export function constructAnyAllAudienceConditions(audienceIds, matchType) {
  if (!audienceIds) {
    return null;
  }

  let isEmpty = audienceIds.size === 0;
  if (!isImmutable(audienceIds)) {
    isEmpty = audienceIds.length === 0;
  }

  if (![ALL_AUDIENCES, ANY_AUDIENCES].includes(matchType) || isEmpty) {
    return null;
  }

  let audienceConditions = [];

  if (matchType === enums.audienceMatchTypes.ALL_AUDIENCES) {
    audienceConditions = ['and'];
  } else if (matchType === enums.audienceMatchTypes.ANY_AUDIENCES) {
    audienceConditions = ['or'];
  }

  audienceIds.forEach(audienceId =>
    audienceConditions.push({ audience_id: audienceId }),
  );

  return audienceConditions;
}

/**
 * Creates an empty audience entity object with the supplied data
 */
export function createAudienceEntity(data) {
  const DEFAULTS = {
    id: null,
    project_id: null,
    name: null,
    description: null,
    last_modified: null,
    conditions: [],
    segmentation: false,
  };

  return _.extend({}, DEFAULTS, data);
}

/**
 * Get the initial state of the visitor behavior targeting rule.
 *
 * @param {Object} currentEvent
 */
export function getStartingBehavioralData(currentEvent) {
  return {
    version: '0.1',
    source: 'events',
    action: {
      type: currentEvent.event_type,
      value: currentEvent.api_name,
    },
    time: {
      type: 'last_days',
      days: 30,
    },
    count: {
      comparator: 'gte',
      value: 1,
    },
  };
}

/**
 * Get the initial state of the visitor attribute targeting rule.
 *
 * @param {Object} currentDatasource
 * @param {Object} currentAttribute
 */
export function getStartingAttributeData(currentDatasource, currentAttribute) {
  const fieldArray = [];
  if (!currentDatasource.is_optimizely) {
    fieldArray.push(currentDatasource.id);
  }
  fieldArray.push(currentAttribute.id);

  const whereClause = {
    where: [
      'and',
      [
        'or',
        {
          op: 'eq',
          args: [{ field: fieldArray }, { value: '' }],
        },
      ],
    ],
  };

  if (!currentDatasource.is_optimizely) {
    return {
      rule: whereClause,
    };
  }

  return whereClause;
}

/**
 * Migrates behavioral rule into UI friendly schema described here:
 * https://confluence.sso.episerver.net/display/EXPENG/Behavioral+Targeting+Condition+Schema
 * @param {Object} rule
 * @return {Object} Rule expressed in new schema.
 */
export function migrateBehavioralRuleToNewSchema(rule) {
  const newSchema = {
    version: '0.1',
    source: 'events',
  };

  const whereClause = rule.where;
  const action = getActionParameters(whereClause);
  if (action) {
    newSchema.action = action;
  }
  const time = getTimeParameters(whereClause);
  if (time) {
    newSchema.time = time;
  }
  const count = getCountParameters(rule.having);
  if (count) {
    newSchema.count = count;
  }
  const filters = getFilters(whereClause);
  if (filters) {
    newSchema.filters = filters;
  }

  return newSchema;
}

/**
 * Determines whether or not an audience with conditions
 * needs a third party modification for it to work.
 *
 * @param {object} Audience conditions
 * @return {bool}
 */
export function thirdPartyNeedsModification(conditions) {
  let needsModification = false;
  const thirdPartyConditionsNeedingModification = ['google_adwords'];
  const checkForThirdPartyModificationNecessary = function(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] instanceof Array) {
        checkForThirdPartyModificationNecessary(arr[i]);
      } else if (arr[i] instanceof Object) {
        for (
          let j = 0;
          j < thirdPartyConditionsNeedingModification.length;
          j++
        ) {
          if (
            arr[i].name &&
            arr[i].name.indexOf(thirdPartyConditionsNeedingModification[j]) > -1
          ) {
            needsModification = true;
            return;
          }
        }
      }
    }
  };

  checkForThirdPartyModificationNecessary(conditions);
  return needsModification;
}

/**
 * Construct audience label from a list of audiences and return it in an immutable array format
 *
 * @param {String} experimentAudienceConditions
 * @param {Immutable.List} experimentAudiences
 * @return {Immutable.List} splitCustomAudienceLabel
 */
export function constructAudienceLabelFromList(
  experimentAudienceConditions,
  experimentAudiences,
) {
  let splitCustomAudienceLabel;

  if (!experimentAudiences || !experimentAudiences.size) {
    return toImmutable([tr('Everyone')]);
  }

  const currentProjectAudiencesMap = experimentAudiences.reduce(
    (map, value) => {
      if (value) return map.set(value.get('id'), value.get('name'));
      return map;
    },
    toImmutable({}),
  );

  if (experimentAudienceConditions) {
    splitCustomAudienceLabel = constructCustomAudienceLabel(
      JSON.parse(experimentAudienceConditions),
      toJS(currentProjectAudiencesMap),
    ).split(' ');
  } else {
    // Covers the case for 'older' experiments that have audience_match_type equal to null (default is 'any' audience match type) and no audience_conditions
    // Need to join and split to account for audience names with operators in it (ex. 'SF and LA Users')
    splitCustomAudienceLabel = experimentAudiences
      .map(audience => audience.get('name'))
      .interpose(tr('or'))
      .join(' ')
      .split(' ');
  }

  return toImmutable(splitCustomAudienceLabel);
}

/**
 * Helper fn to signal when to add styling while iterating over audience conditions
 * @param {String} stringValue
 * @return {Boolean}
 */
export function shouldStylizeAudienceConditionOperator(stringValue) {
  return _.includes(
    [tr('and'), tr('or'), tr('not')],
    stringValue.toLowerCase(),
  );
}

/**
 * Helper fn to indicate if an audience is comopatible with Optimizely X
 * @param {Model} audience
 * @return {Boolean}
 */
export function compatibleWithOptimizelyX(audience) {
  let compatibleWith = audience.compatible_with;

  if (isImmutable(audience)) {
    compatibleWith = audience.get('compatible_with');
  }
  return (
    compatibleWith === enums.audienceCompatility.OPTIMIZELY_X ||
    compatibleWith === enums.audienceCompatility.ALL
  );
}

/**
 * Helper fn to indicate if an audience is comopatible with Optimizely Classic
 * @param {Model} audience
 * @return {Boolean}
 */
export function compatibleWithOptimizelyClassic(audience) {
  return (
    audience.compatible_with === enums.audienceCompatility.OPTIMIZELY_CLASSIC ||
    audience.compatible_with === enums.audienceCompatility.ALL
  );
}

/**
 * Helper fn to construct placeholderName String from selected audiences based on audienceConditions
 * @param {String|Immutable.List} audienceConditions
 * @param {Immutable.List} currentProjectAudiences
 * @return {String}
 */
export function getPlaceHolderNameFromAudiences(
  audienceConditions,
  currentProjectAudiences,
) {
  let placeholderName;
  let parsedAudienceConditions;
  // TODO(APPX-34) Update "audienceConditions" to NOT be parsed when that field is deserialized with rich JSON for all LayerExperiments
  try {
    parsedAudienceConditions =
      typeof audienceConditions === 'string'
        ? JSON.parse(audienceConditions)
        : toJS(audienceConditions);
  } catch (error) {
    return '';
  }

  if (!parsedAudienceConditions || parsedAudienceConditions.length <= 1) {
    placeholderName = 'Everyone';
  } else {
    const clonedAudienceConditions = cloneDeep(parsedAudienceConditions);
    const currentProjectAudiencesMap = {};

    currentProjectAudiences.forEach(audience => {
      currentProjectAudiencesMap[audience.get('id')] = audience.get('name');
    });

    placeholderName = constructCustomAudienceLabel(
      clonedAudienceConditions,
      currentProjectAudiencesMap,
    );
  }
  return placeholderName;
}

export default {
  allAudienceConditions,
  constructCustomAudienceLabel,
  constructAnyAllAudienceConditions,
  constructAudienceLabelFromList,
  compatibleWithOptimizelyClassic,
  compatibleWithOptimizelyX,
  createAudienceEntity,
  getPlaceHolderNameFromAudiences,
  getStartingBehavioralData,
  getStartingAttributeData,
  hasAnyProcessingAdaptiveAudienceConditions,
  hasProcessingAdaptiveAudienceStatus,
  isAPIAudience,
  migrateBehavioralRuleToNewSchema,
  shouldStylizeAudienceConditionOperator,
  thirdPartyNeedsModification,
  validateCustomCode,
};

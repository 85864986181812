export default {
  USER_TYPE: {
    guest: 'guest',
    enterprise: 'enterprise',
    selfServe: 'selfServe',
    saturatedSelfServe: 'saturatedSelfServe',
  },
  ENTERPRISE_SUBSTRING: 'enterprise',
  ENTERPRISE_C_SUBSTRING: 'c',
  PP2020_FS_PLUS_ROLLOUT: 'pp2020_fs_plus_rollout'
};

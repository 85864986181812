import PropTypes from 'prop-types';
import React from 'react';
import { OptimizelyFeature, useFeature } from '@optimizely/react-sdk';
import { Attention, Input, HelpPopover, Link, Textarea } from 'optimizely-oui';
import { FEATURE_DETAILS } from 'optly/utils/constants';

import { connect } from 'core/ui/decorators';
import { actions as CurrentProjectActions } from 'optly/modules/current_project';
import LoadingGetters from 'core/modules/loading/getters';
import userFriendlyNameToKey from 'optly/utils/userFriendlyNameToKey';

// components
import { fieldPropType } from 'react_components/form';

const FeatureDetails = ({
  apiNameField,
  descriptionField,
  isDisabled,
  isEditing,
  isValidatingFeatureKey,
  nameField,
}) => {
  const [isUserFriendlyNamesEnabled] = useFeature('user_friendly_names');

  const renderApiNameErrorBlock = () => {
    if (apiNameField.getErrors().details.isValid) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="feature-dialog-invalid-key-error">
          Please enter a valid key. Valid keys contain alphanumeric characters,
          hyphens, and underscores, and are limited to 64 characters.
        </div>
      );
    }

    if (apiNameField.getErrors().details.isUnique) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="feature-dialog-unique-key-error">
          {isUserFriendlyNamesEnabled
            ? 'This key is already in use. Keys must be unique.'
            : 'Key is already in use by another feature in this project. Please choose a unique key.'}
        </div>
      );
    }
  };
  const renderApiNameNoteBlock = () =>
    isValidatingFeatureKey ? (
      <div
        className="lego-form-note"
        data-test-section="feature-dialog-validating-key-note">
        Validating...
      </div>
    ) : null;
  const renderNameErrorBlock = () => {
    if (nameField.getErrors().details.isValid) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="feature-dialog-invalid-name-error">
          Please enter a name.
        </div>
      );
    }
  };

  const handleNameChange = event => {
    const previousName = nameField.getValue();
    const previousApiName = apiNameField.getValue();
    const inputValue = event.target.value;

    nameField.setValue(inputValue);
    // If the api_name is empty or matches the formatted friendly name, continue with auto-generation ONLY for feature creation dialog
    if (
      !isEditing &&
      (!previousApiName ||
        previousApiName ===
          userFriendlyNameToKey((previousName || '').toLowerCase()))
    ) {
      apiNameField.setValue(
        userFriendlyNameToKey((inputValue || '').toLowerCase()),
      );
    }
  };

  const handleApiNameChange = event => {
    apiNameField.setValue(
      isUserFriendlyNamesEnabled
        ? userFriendlyNameToKey(event.target.value)
        : event.target.value,
    );
  };

  const featuresLink = CurrentProjectActions.getHelpCopy(
    'features_link',
    'https://docs.developers.optimizely.com/full-stack/docs/use-feature-flags',
  );

  const renderFeatureKeyLabelWithHelp = () => {
    return (
      <span>
        <span>Feature Key</span>
        <HelpPopover horizontalAttachment="left" verticalAttachment="top">
          <p>
            Use alphanumeric characters, hyphens, and underscores in this unique
            key. No spaces.
          </p>
        </HelpPopover>
      </span>
    );
  };

  // TODO(APPX-1554): Remove user_friendly_names feature flag once rolled out to 100%
  return (
    <div>
      <div className="push-quad--bottom">
        <div
          className="beta push--bottom"
          data-test-section="feature-dialog-title">
          {isEditing ? 'Edit Feature' : 'New Feature'}
        </div>
        <p>
          Feature flags enable you to control the availability of a feature in
          your application without redeploying code.&nbsp;
          <Link href={featuresLink} newWindow={true}>
            Learn more.
          </Link>
        </p>
      </div>
      <div className="push-quad--bottom">
        <Attention
          alignment="center"
          type="brand"
          testSection="sdk-compatibility-warning">
          {`${FEATURE_DETAILS.sdkWarningText} `}
          <Link newWindow={true} href={FEATURE_DETAILS.sdkWarningKbLink}>
            Learn more
          </Link>
        </Attention>
      </div>
      <OptimizelyFeature feature="user_friendly_names">
        {isEnabled =>
          isEnabled ? (
            <div className="lego-grid">
              <div className="soft-double--left width--1-1 push-quad--bottom">
                <Input
                  id="feature-name"
                  label="Feature Name"
                  isRequired={true}
                  isDisabled={isDisabled}
                  type="text"
                  testSection="feature-dialog-name"
                  value={nameField.getValue()}
                  onChange={handleNameChange}
                  placeholder="Feature Name"
                />
                {renderNameErrorBlock()}
              </div>
            </div>
          ) : null
        }
      </OptimizelyFeature>
      <div className="lego-grid">
        <div className="soft-double--left width--1-1 push-quad--bottom">
          <Input
            id="feature-api-name"
            label={renderFeatureKeyLabelWithHelp()}
            note="Use this to uniquely identify the feature"
            isRequired={true}
            isDisabled={isDisabled}
            type="text"
            testSection="feature-dialog-key"
            value={apiNameField.getValue()}
            onChange={handleApiNameChange}
            placeholder="feature_key"
          />
          {renderApiNameNoteBlock() || renderApiNameErrorBlock()}
        </div>
      </div>
      <div className="lego-grid">
        <div className="soft-double--left width--1-1">
          <Textarea
            id="feature-description"
            label="Description"
            testSection="feature-dialog-description"
            value={descriptionField.getValue()}
            isDisabled={isDisabled}
            onChange={e => descriptionField.setValue(e.target.value)}
            placeholder="Click to add"
          />
        </div>
      </div>
    </div>
  );
};

FeatureDetails.propTypes = {
  apiNameField: fieldPropType.isRequired,
  descriptionField: fieldPropType.isRequired,
  isDisabled: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  isValidatingFeatureKey: PropTypes.bool.isRequired,
  nameField: fieldPropType.isRequired,
};

FeatureDetails.defaultProps = {
  isDisabled: false,
};

export default connect(() => ({
  isValidatingFeatureKey: LoadingGetters.isLoading('feature_key_validation'),
}))(FeatureDetails);

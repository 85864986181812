import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Store({
  getInitialState() {
    return toImmutable({});
  },

  initialize() {
    this.on(actionTypes.PRICING_INFO_LOADED, storePricingInfo);
  },
});

function storePricingInfo(state, payload) {
  return state
    .set('countries', payload.pricingInfo.countries)
    .set(
      'countries_requiring_vat_number',
      payload.pricingInfo.countries_requiring_vat_number,
    );
}

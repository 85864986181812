import PropTypes from 'prop-types';
import React from 'react';

import { PaginationControls } from 'optimizely-oui';

import UpgradeConstants from 'optly/modules/upgrade/constants';
import { useUpgradeInfo } from 'optly/hooks/upgrade';

const Footer = ({
  currentPageNo,
  handleFetchPageByNumber,
  isLoadingNewPage,
  pageSize,
  queryTotalCount,
}) => {
  const { hasLimit, UpgradeComponent } = useUpgradeInfo(
    UpgradeConstants.FeatureLimitTypes.CHANGE_HISTORY_DAYS,
    UpgradeConstants.FeatureLimitUIs.CHANGE_HISTORY_TABLE_FOOTER,
  );

  const totalPages = Math.ceil(queryTotalCount / pageSize);

  return (
    <div className="soft-double flex" data-test-section="change-history-footer">
      {totalPages > 1 && (
        <div className="flex--1 flex flex-justified--center">
          <PaginationControls
            currentPage={currentPageNo}
            totalPages={totalPages}
            goToPage={handleFetchPageByNumber}
            isLoading={isLoadingNewPage}
            testSection="change-history-pagination-controls"
          />
        </div>
      )}
      {hasLimit && <UpgradeComponent />}
    </div>
  );
};

Footer.propTypes = {
  currentPageNo: PropTypes.number.isRequired,
  handleFetchPageByNumber: PropTypes.func.isRequired,
  isLoadingNewPage: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  queryTotalCount: PropTypes.number.isRequired,
};

export default Footer;

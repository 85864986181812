import { api as SegmentJSAPI } from '@optimizely/segment-js';

import { toImmutable } from 'optly/immutable';
import flux from 'core/flux';
import ui from 'core/ui';

import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import {
  actions as EnvironmentsActions,
  getters as EnvironmentsGetters,
} from 'optly/modules/entity/environment';
import {
  actions as FeatureActions,
  fns as FeatureFns,
} from 'optly/modules/entity/feature_flag';
import FeatureForm from 'bundles/p13n/components/feature_form';
import {
  getters as PermissionsGetters,
  fns as PermissionsFns,
} from 'optly/modules/permissions';
import CreateNewFeatureDialog from 'bundles/p13n/sections/manager_feature/components/create_new_feature_dialog';

/**
 * Open the show feature dialog. If feature is provided, will edit feature,
 * otherwise will create a new feature.
 *
 * Returns a Promise that resolves with an Immutable Map containing the updated
 * feature data after the feature has been successfully created or edited via
 * the dialog.
 *
 * Showing and hiding the dialog is handled by this function using
 * ui.showReactDialog and ui.hideDialog.
 *
 * @param {Immutable.Map|undefined} feature
 * @return {Promise}
 */
const showFeatureDialogOld = (feature, onHideDialog) =>
  new Promise(resolve => {
    EnvironmentsActions.fetchAll({
      project_id: flux.evaluate(CurrentProjectGetters.id),
    }).then(() => {
      const currentProjectId = flux.evaluate(CurrentProjectGetters.id);
      const environments = flux.evaluate(
        EnvironmentsGetters.unarchivedEnvironmentsSortedByPriority,
      );
      let featureWithEnvConfigs;
      if (feature) {
        // Ensure there is a default environment config for every unarchived
        // environment (they could be missing if the feature was created in the
        // old "Feature Flags" UI)
        featureWithEnvConfigs = FeatureFns.ensureEnvironmentConfigs(
          feature,
          environments,
        );
      } else {
        featureWithEnvConfigs = FeatureFns.createNewFeatureWithEnvironmentConfigs(
          environments,
          currentProjectId,
        );
      }

      // used to check for feature key uniqueness within the project
      const currentProjectFeatures = flux.evaluate(
        CurrentProjectGetters.features,
      );

      const saveFeature = featureToSave =>
        FeatureActions.save(featureToSave.toJS())
          .then(savedFeature => FeatureActions.fetch(savedFeature.id, true))
          .then(fetchedFeature => {
            resolve(toImmutable(fetchedFeature));
            ui.hideDialog();
          });

      const cancel = () => {
        ui.hideDialog();
      };

      const canManageFeatureFlags = flux.evaluate([
        CurrentProjectGetters.project,
        PermissionsFns.canManageFeatureFlags,
      ]);
      const isMobileOnly = flux.evaluate([
        AdminAccountGetters.accountPermissions,
        PermissionsFns.isMobileOnlyAccount,
      ]);

      ui.showReactDialog(
        FeatureForm,
        {
          props: {
            currentProjectFeatures,
            feature: featureWithEnvConfigs,
            save: saveFeature,
            cancel,
            canManageFeatureFlags,
            isMobileOnly,
          },
        },
        {
          noPadding: true,
          fullScreen: true,
          dismissOnBack: true,
          onHideDialog: () => {
            if (onHideDialog) {
              onHideDialog();
            }
          },
        },
      );

      SegmentJSAPI.virtualPageview('Features', 'Feature Flag Builder', '', {
        builderMode: feature ? 'edit' : 'create',
      });
    });
  });

const showCreateFeatureDialog = () =>
  new Promise(resolve => {
    ui.showReactDialog(
      CreateNewFeatureDialog,
      {
        props: {
          onSave: savedFeature => {
            resolve(savedFeature);
            ui.hideDialog();
          },
          onCancel: ui.hideDialog,
        },
      },
      {
        fullScreen: true,
        dismissOnBack: true,
        isOuiDialog: true,
      },
    );
  });

export const showFeatureDialog = (feature, onHideDialog) =>
  flux.evaluate(PermissionsGetters.canCurrentProjectUseTargetedRollouts)
    ? showCreateFeatureDialog(feature)
    : showFeatureDialogOld(feature, onHideDialog);

export default {
  showFeatureDialog,
};

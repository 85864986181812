const _ = require('lodash');

/**
 * mergeAndDiff merges two objects while making a diff of them
 * Arrays of objects can be merged by specifying a unique key to look for to identify
 * Arrays of objects are merged by looking at every single object contained (the order doesn't matter).
 * Objects are identified by an id (a specific key in each object).
 * The list of keys used to identify objects' id can be specified in 'idKeys'.
 * The function modifies the fist object passed.
 * Diffs are represented as an array like [valueFromObj1, valueFromObj2]
 *
 * @param  {Object} obj1   The first object (it will be modified)
 * @param  {Object} obj2   The object to merge into the first one
 * @param  {Array} idKeys  *optional A list of strings representing the keys used as IDs to identify objects in array
 * @param  {Array} diffKeys  *optional The list of keys the the function should diff. If none is specified, the function will diff every string or number not used as ID
 * @param  {Boolean} ignoreUnchangedDiffs *optional If true, all diff where before and after are the same will be ignored
 * @param  {String} parentKey *private The parent's key. Used to identify IDs or diff keys
 * @return {Object}        The result of merge and diff
 */
const mergeAndDiff = function(
  obj1,
  obj2,
  idKeys,
  diffKeys,
  ignoreUnchangedDiffs,
  parentKey,
) {
  const self = mergeAndDiff;
  let merge;

  if (_.isPlainObject(obj1) || _.isPlainObject(obj2)) {
    // Object
    // Get all keys
    const keys1 = _.keys(obj1);
    const keys2 = _.keys(obj2);
    const allKeys = _.uniq(keys1.concat(keys2));

    merge = {};
    obj1 = obj1 || {};
    obj2 = obj2 || {};

    _.forEach(allKeys, id => {
      if (_.includes(idKeys, id)) {
        // The objects should be either the same or one is null, use any not null one
        merge[id] = obj1[id] || obj2[id];
      } else {
        // call self to iterate on all the children
        merge[id] = self(
          obj1[id],
          obj2[id],
          idKeys,
          diffKeys,
          ignoreUnchangedDiffs,
          id,
        );
      }
    });
  } else if (_.isArray(obj1) || _.isArray(obj2)) {
    // Array
    if (diffKeys && _.includes(diffKeys, parentKey)) {
      // The array requires to be diffed
      merge = [obj1, obj2];
    } else {
      // The array is not a simple diff, so let's check for IDs and other stuff...

      // An id must exist in every dictionary of the array, so we can just check the first one
      const allChildrenKeys = obj1 ? _.keys(obj1[0]) : _.keys(obj2[0]);
      const potentialIdKeys = _.intersection(allChildrenKeys, idKeys);

      const ids1 = [];
      const ids2 = [];
      let resultingArray = [];

      if (potentialIdKeys.length >= 1) {
        // We got the key!
        const idKey = potentialIdKeys[0];

        if (potentialIdKeys.length > 1 && __DEV__) {
          console.warn('More than one id key was found while diffing the object. The first key was used as ID. Here is the list of keys:', potentialIdKeys); //eslint-disable-line
        }

        // Find all the ids
        _.forEach(obj1, child => {
          ids1.push(child[idKey]);
        });
        _.forEach(obj2, child => {
          ids2.push(child[idKey]);
        });
        const allIds = _.uniq(ids1.concat(ids2));

        _.forEach(allIds, id => {
          const condition = {};
          condition[idKey] = id;
          const childIn1 = _.find(obj1, condition);
          const childIn2 = _.find(obj2, condition);
          resultingArray.push(
            self(
              childIn1,
              childIn2,
              idKeys,
              diffKeys,
              ignoreUnchangedDiffs,
              id,
            ),
          );
        });
      } else {
        // No ID key found
        obj1 = obj1 || [];
        obj2 = obj2 || [];

        const tmpConcat = [].concat(obj1, obj2);
        const childrenTypes = _.uniqBy(tmpConcat, el => typeof el);

        if (
          childrenTypes.length === 1 &&
          (_.isString(tmpConcat[0]) || _.isNumber(tmpConcat[0]))
        ) {
          // All array children are strings or numbers
          resultingArray = _.uniq(tmpConcat);
        } else {
          // Not all children have the same typeof or they are objects or arrays
          // => simply concatenate the arrays
          _.forEach(
            obj1,
            function(obj, id) {
              this.push(
                self(obj, null, idKeys, diffKeys, ignoreUnchangedDiffs, id),
              );
            },
            resultingArray,
          );
          _.forEach(
            obj2,
            function(obj, id) {
              this.push(
                self(null, obj, idKeys, diffKeys, ignoreUnchangedDiffs, id),
              );
            },
            resultingArray,
          );
        }
      }
      merge = resultingArray;
    }
  } else {
    // Number, string, boolean...
    if (
      _.includes(idKeys, parentKey) ||
      // The key is not an ID
      (diffKeys && !_.includes(diffKeys, parentKey))
    ) {
      // If diffKeys is specified, only diff matching objects
      // The objects should be either the same or one is null, use any not null
      merge = obj1 || obj2;
    } else if (ignoreUnchangedDiffs && obj1 === obj2) {
      // If ignoreUnchangedDiffs and the two objects are the same, then ignore the diff
      merge = null;
    } else {
      // Convert undefined to null
      obj1 = _.isUndefined(obj1) ? null : obj1;
      obj2 = _.isUndefined(obj2) ? null : obj2;
      merge = [obj1, obj2];
    }
  }
  return merge;
};

/**
 * Makes a merge of two objects (similarly to what _.extend does) and creates a diff of the specified keys
 * For more details look the documentation of `mergeAndDiff` private variable in this file
 */
exports.mergeAndDiff = function(
  obj1,
  obj2,
  idKeys,
  diffKeys,
  ignoreUnchangedDiffs,
) {
  return mergeAndDiff(obj1, obj2, idKeys, diffKeys, ignoreUnchangedDiffs);
};

import renderReactComponent from 'core/ui/methods/render_react_component';

export default {
  replace: true,

  template: '<div></div>',

  ready() {
    renderReactComponent(this, {
      component: this.$options.component,
      dataBindings: this.$options.dataBindings || {},
      props: this.$options.props || {},
      el: this.$el,
    });
  },
};

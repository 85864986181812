/**
 * Event tracking service
 *
 * @author Jon Noronha (jon.noronha@optimizely.com)
 */
let pageName = 'optimizely';

/**
 * Tracks an event in various trackers
 * @param {string} category
 * @param {string=} action
 * @param {string=} label
 * @param {number=} value
 */
function track(category, action, label, value) {
  const eventString = `/${category}${action ? `/${action}` : ''}${
    label ? `/${label}` : ''
  }`;
  console.log(`No Op call to services/events.track for: ${eventString}`);
}

/**
 * Track event in Marketo
 *
 * @param {string} category
 * @param {string=} action
 * @param {string=} label
 * @param {number=} value
 * @param {dict=} params
 */
function trackMarketoEvent(category, action, label, value, params) {
  console.log('No Op call to services/events.trackMarketoEvent');
}

/**
 * Track lead event in Marketo
 *
 * @param {String} mktoFunctionName
 * @param {Object} leadInfo
 * @param {String} token
 */
function trackMarketoLead(mktoFunctionName, leadInfo, token) {
  console.log('No Op call to services/events.trackMarketoLead');
}

/**
 * Setter for global page name
 *
 * @param string page Name of the page user is on
 */
function setPageName(page) {
  pageName = page;
}

/**
 * Getter for global page name
 *
 * @return Name of the global page
 */
function getPageName() {
  return pageName;
}

module.exports = {
  getPageName,
  setPageName,
  track,
  trackMarketoEvent,
  trackMarketoLead,
};

// Core
import { difference, isEmpty, isObject } from 'lodash';

import flux from 'core/flux';
import Immutable, { toJS } from 'optly/immutable';

// Modules
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import {
  actions as SupportInfoActions,
  getters as SupportInfoGetters,
} from 'optly/modules/entity/support_info';
import {
  actions as UserActions,
  getters as UserGetters,
} from 'optly/modules/entity/user';

/**
 * THIS IS A DEPENDENCY for the evaluateUserInfo method. Modify carefully!!
 *
 * The doAllValuesMatch method recursively compares two given arguments and allows for OR matches
 * when an Array is provided for the evaluateValue and compared against a non-Array currentValue
 *
 * String & Integer example:
 * - doAllValuesMatch('Optimizely', 'Optimizely')                     // Match
 * - doAllValuesMatch('Optimizely', 'Carrotsticks')                   // No Match
 * - doAllValuesMatch(123, 123)                                       // Match
 * - doAllValuesMatch(123, 456)                                       // No Match
 *
 * Object example:
 * - doAllValuesMatch({                                               //
 *  companyName: 'Optimizely'                                         //
 *  location: ['Earth', 'Mars']                                       //
 * }, {                                                               //
 *  companyName: 'Optimizely'                                         //
 *  location: 'Earth',                                                //
 *  phone: 1234567890,                                                //
 * })                                                                 // Match
 * - doAllValuesMatch({                                               //
 *  companyName: 'Optimizely'                                         //
 *  location: ['Earth', 'Mars']                                       //
 * }, {                                                               //
 *  companyName: 'Optimizely'                                         //
 *  location: 'Venus',                                                //
 *  phone: 1234567890,                                                //
 * })                                                                 // No Match
 *
 * Array evaluateValue vs. non-Array currentValue example:
 * - doAllValuesMatch(['Optimizely', 'Carrotsticks'], 'Optimizely')   // Match
 * - doAllValuesMatch(['Google', 'Carrotsticks'], 'Optimizely')       // No Match
 *
 * Array evaluateValue vs. Array currentValue example:
 * - doAllValuesMatch(['Optimizely', 'Carrotsticks'],                 //
 *                    ['Optimizely', 'Carrotsticks', 'Google'])       // Match
 * - doAllValuesMatch(['Optimizely', 'Carrotsticks'],                 //
 *                    ['Optimizely', 'Carrotsticks'])                 // Match
 * - doAllValuesMatch(['Optimizely', 'Carrotsticks', 'Google'],       //
 *                     ['Optimizely', 'Carrotsticks'])                // No Match
 *
 * @param evaluateValue {Array|Object|String|Integer} value(s) expected to be in currentValue
 * @param currentValue {Array|Object|String|Integer} the source of truth that should be used to evaluate matches
 * @returns {boolean} - Returns true if all values in evaluateValue are present in currentValue
 */
const doAllValuesMatch = (evaluateValue, currentValue) => {
  // If both values are arrays, ensure items to evaluate are provided
  // and ensure the evaluate items are present in the currentValue
  if (Array.isArray(evaluateValue) && Array.isArray(currentValue)) {
    if (evaluateValue.length === 0) {
      return false;
    }
    return !difference(evaluateValue, currentValue).length;
  }
  if (Array.isArray(evaluateValue) && !Array.isArray(currentValue)) {
    // If the evaluateValue is an array but currentValue isn't the evaluate values
    // will be treated as ORs, returning true if any of them match via the recursively called doAllValuesMatch
    // If the currentValue is falsey, just check if that value is included in the array
    if (!currentValue) {
      return evaluateValue.includes(currentValue);
    }
    return !!evaluateValue.find(evaluateArrayValue =>
      doAllValuesMatch(evaluateArrayValue, currentValue),
    );
  }
  if (isObject(evaluateValue) && isObject(currentValue)) {
    // If both values are objects, ensure the provided keys inside are present in the currentValue
    // by recursively calling doAllValuesMatch to see if each key value pair has a match.
    // Return early with false if the object is empty
    if (isEmpty(evaluateValue)) {
      return false;
    }
    let objectKeysMatch = true;
    Object.keys(evaluateValue).forEach(key => {
      if (!doAllValuesMatch(evaluateValue[key], currentValue[key])) {
        objectKeysMatch = false;
      }
    });
    return objectKeysMatch;
  }
  // For strings or integers, just check if the provided values are equal
  return currentValue === evaluateValue;
};

/**
 * Returns information about the currently logged in Optimizely user and session
 * also contains a complete key, which is a Promise that will resolve when all asynchronous user info has been fetched
 *
 * @return {Object}
 */
export function getUser() {
  const accountId = flux.evaluate(AdminAccountGetters.id);
  const currentSupportInfo =
    flux.evaluate(SupportInfoGetters.currentSupportInfo) || Immutable.Map();
  const currentUser = flux.evaluate(UserGetters.currentUser) || Immutable.Map();
  const email = flux.evaluate(AdminAccountGetters.email);

  const deferreds = [];
  if (!!accountId && !currentSupportInfo.size) {
    deferreds.push(SupportInfoActions.fetchAll({ account_id: accountId }));
  }

  if (!!email && !currentUser.size) {
    deferreds.push(UserActions.fetch(email));
  }

  return {
    accountExecutive: toJS(currentSupportInfo.get('account_executive')),
    accountId,
    accountPermissions: flux.evaluateToJS(
      AdminAccountGetters.accountPermissions,
    ),
    communicationPreference: currentUser.get('communication_preference'),
    currentCollaboratorRole: flux.evaluate(AdminAccountGetters.currentRole),
    customerSuccessManager: toJS(
      currentSupportInfo.get('technical_account_manager'),
    ),
    email,
    emailVerified: currentUser.get('email_verified'),
    firstName: currentUser.get('first_name'),
    hasPrioritySupport: currentSupportInfo.get('has_priority_support'),
    isGAEAdmin: flux.evaluateToJS(AdminAccountGetters.isAdmin),
    jobDepartment: currentUser.get('job_department'),
    jobRole: currentUser.get('job_role'),
    lastName: currentUser.get('last_name'),
    planId: flux.evaluate(AdminAccountGetters.planId),
    projectId: flux.evaluate(CurrentProjectGetters.id),
    projectPlatform: flux.evaluate(CurrentProjectGetters.platform),
    twoFactorEnrolled: currentUser.get('two_factor_enrolled'),
    uniqueUserId: flux.evaluate(AdminAccountGetters.uniqueUserId),
    userLocale: flux.evaluate(AdminAccountGetters.userLocale),

    // Return a promise that will resolve when all asynchronous user info has been fetched
    complete: Promise.all(deferreds),
  };
}

/**
 * Returns either an object or Promise that resolves with:
 *   a) Number of how many of the passed key value pairs matched the current user info
 *   b) Current user info
 *   b) Whether all the passed key value pairs match current user info
 *
 * For example, in "third party" javascript like Optimizely Web, this can be used within a custom JS audience, conditional activation, and extensions
 * (hint: take a look at accountPermissions, you can pass several and mix and match the audiences you'll customize for).
 *
 * @param userInfoToEvaluate {Object} - key value pairs to evaluate for current userInfo equality
 *  If accountPermissions is passed within userInfoToEvaluate, equality is concluded if all
 *  provided Array indexes are present in the current userInfo accountPermissions.
 *  If a key is passed with an Array value other than accountPermissions, each array index will be evaluated - treating the evaluatoin as an OR
 * @param options {Object} Each key value pair passed
 * @param options.async {Boolean} If false, all user info may not be available unless initialized before
 * @returns If options.async === false
 *   {Object} results - The returned object that will resolve with ({matchesFound, userInfo, allValuesMatch})
 *   {Integer} matchesFound - Number of keys that matched the current userInfo
 *   {Object} userInfo - Information about the currently logged in Optimizely user and session
 *   {Boolean} allValuesMatch - Will be true if values provided match the corresponding keys for the current userInfo
 * @returns If options.async === true
 *   {Promise.resolve({Object})} results - The returned promise that will resolve with ({matchesFound, userInfo, allValuesMatch})
 *   {Integer} matchesFound - Number of keys that matched the current userInfo
 *   {Object} userInfo - Information about the currently logged in Optimizely user and session
 *   {Boolean} allValuesMatch - Will be true if values provided match the corresponding keys for the current userInfo
 */
export function evaluateUserInfo(userInfoToEvaluate = {}, options = {}) {
  if (
    !userInfoToEvaluate ||
    (userInfoToEvaluate && !Object.keys(userInfoToEvaluate).length)
  ) {
    console.warn('WARNING - evaluateUserInfo must be passed the userInfoToEvaluate object. Find out more at go/optlyAppApi.'); // eslint-disable-line
    return;
  }
  const accountId = flux.evaluate(AdminAccountGetters.id);
  const email = flux.evaluate(AdminAccountGetters.email);

  // Use closure data for args, accountId, and email
  const userInfoEvaluator = () => {
    // Use flux.evaluate on currentSupportInfo and currentUser here since this needs to runs within the promise for async
    const currentSupportInfo =
      flux.evaluate(SupportInfoGetters.currentSupportInfo) || Immutable.Map();
    const currentUser =
      flux.evaluate(UserGetters.currentUser) || Immutable.Map();

    if (!options.async && !!accountId && !currentSupportInfo.size) {
      SupportInfoActions.fetchAll({ account_id: accountId });
    }

    if (!options.async && !!email && !currentUser.size) {
      UserActions.fetch(email);
    }

    // Build the userInfo object with what info we have available to us
    // TODO: Make this DRYer (object built in getUser() is virtually identical. This code shouldn't be repeated! Abstract into one spot!)
    const userInfo = {
      accountExecutive: toJS(currentSupportInfo.get('account_executive')),
      accountId,
      accountPermissions: flux.evaluateToJS(
        AdminAccountGetters.accountPermissions,
      ),
      communicationPreference: currentUser.get('communication_preference'),
      currentCollaboratorRole: flux.evaluate(AdminAccountGetters.currentRole),
      customerSuccessManager: toJS(
        currentSupportInfo.get('technical_account_manager'),
      ),
      email,
      emailVerified: currentUser.get('email_verified'),
      firstName: currentUser.get('first_name'),
      hasPrioritySupport: currentSupportInfo.get('has_priority_support'),
      isGAEAdmin: flux.evaluateToJS(AdminAccountGetters.isAdmin),
      jobDepartment: currentUser.get('job_department'),
      jobRole: currentUser.get('job_role'),
      lastName: currentUser.get('last_name'),
      planId: flux.evaluate(AdminAccountGetters.planId),
      projectId: flux.evaluate(CurrentProjectGetters.id),
      projectPlatform: flux.evaluate(CurrentProjectGetters.platform),
      twoFactorEnrolled: currentUser.get('two_factor_enrolled'),
      uniqueUserId: flux.evaluate(AdminAccountGetters.uniqueUserId),
      userLocale: flux.evaluate(AdminAccountGetters.userLocale),
    };

    // Make an array of the keys from userInfoToEvaluate
    const keys = Object.keys(userInfoToEvaluate);
    // Use reduce to start at 0 and add 1 for every key value match between actual userInfo and userInfoToEvaluate
    const matchesFound = keys.reduce((matchTotal, key) => {
      const evaluateValue = userInfoToEvaluate[key];
      const currentValue = userInfo[key];
      // Call doAllValuesMatch defined above
      const allValuesMatchForKey = doAllValuesMatch(
        evaluateValue,
        currentValue,
      );
      return allValuesMatchForKey ? matchTotal + 1 : matchTotal;
    }, 0);
    // Ensure that the number of keys passed matches what we found, or return false for allValuesMatch
    const allValuesMatch = keys.length === matchesFound;
    return { matchesFound, userInfo, allValuesMatch };
  };

  // If synchronous, evaluate the existing info, kick off fetches if needed, and return results
  if (!options.async) {
    return userInfoEvaluator();
  }

  const deferreds = [];

  // Only kick off the support info and current user fetches if the required info is available
  if (accountId) {
    deferreds.push(SupportInfoActions.fetchAll({ account_id: accountId }));
  }
  if (email) {
    deferreds.push(UserActions.fetch(email));
  }

  // If async, return a promise and resolve with the results
  return Promise.all(deferreds).then(() => userInfoEvaluator());
}

/**
 * Returns true if current account has the provided feature enabled on their account (see models/feature.py for features)
 *
 * @param {String} feature
 * @return {Boolean}
 */
export function hasPermission(feature) {
  return flux.evaluate([
    AdminAccountGetters.accountPermissions,
    CurrentProjectGetters.permissions,
    function(accountPerms, projectPerms) {
      // extra safegaurds around permission checking
      try {
        return accountPerms.contains(feature) || projectPerms.contains(feature);
      } catch (e) {
        return false;
      }
    },
  ]);
}

export default {
  evaluateUserInfo,
  getUser,
  hasPermission,
};

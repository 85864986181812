import CurrentProjectGetters from 'optly/modules/current_project/getters';
import EventModuleEnums from 'optly/modules/entity/event/enums';
import FilterableTableFns from 'optly/modules/filterable_table/fns';
import FilterableTableGetters from 'optly/modules/filterable_table/getters';
import SortableTableGetters from 'optly/modules/sortable_table/getters';

export const currentProjectEvents = [
  CurrentProjectGetters.events,
  events => {
    return events.filter(
      _event => _event.get('event_type') === EventModuleEnums.eventTypes.CUSTOM,
    );
  },
];

export const filteredAndSortedEvents = function(tableId) {
  return [
    currentProjectEvents,
    FilterableTableGetters.fieldFilter(tableId, 'status'),
    FilterableTableGetters.stringFilter(tableId),
    SortableTableGetters.sortFn(tableId),
    (events, statusFilter, filter, sortFn) => {
      events = FilterableTableFns.filterByArchivedStatus(events, statusFilter);
      events = FilterableTableFns.filterFieldsByString(events, filter, [
        'name',
        'description',
        'id',
      ]);
      if (sortFn) {
        events = events.sort(sortFn);
      }
      return events;
    },
  ];
};

export default {
  currentProjectEvents,
  filteredAndSortedEvents,
};

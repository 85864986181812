/**
 * Contains common ui actions that are shared across components
 *
 * @author Mike Ng (mike.ng@optimizely.com)
 */
import { featureFunction } from '@optimizely/js-sdk-lab/src/decorators';
import htmlSanitizer from 'sanitizer';

import ui from 'core/ui';
import flux from 'core/flux';
import { toImmutable } from 'optly/immutable';

import V1Router from 'optly/services/url_helper_legacy';
import UrlHelper from 'optly/services/url_helper';
// eslint-disable-next-line import/no-cycle
import BundleSplitHelper from 'optly/utils/bundle_split_helper';

import PerformanceTrackingActions from 'optly/modules/performance_tracking/actions';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { enums as ProjectEnums } from 'optly/modules/entity/project';
import { actions as TargetingConditionActions } from 'optly/modules/entity/targeting_condition';
import { actions as TagGroupActions } from 'optly/modules/entity/tag_group';
import { getters as V2MigrationGetters } from 'optly/modules/v2_migration';
import {
  actions as EventModuleActions,
  enums as EventModuleEnums,
  fns as EventModuleFns,
} from 'optly/modules/entity/event';

import EventConfigDialog from 'bundles/p13n/sections/oasis_implementation/pages/events_dashboard/components/event_config';
import MultiarmedBanditNotification from 'optly/components/dialogs/multi_armed_bandit_notification';

/**
 ********************************************************************************
 *   Confirm Action Dialogs                                                     *
 ********************************************************************************
 */

/**
 * Confirm that the user wants to archive the entity and then archive it
 * Note: If the entity is an event, use confirmArchiveEvent
 *
 * @param {Object} options
 * @param {String} options.entityName
 * @param {String} options.instanceName - the name property of the entity we are archiving
 * @param {Object} options.entityInstance - instance of the entity to pass to the archive action
 * @param {Function} options.archiveAction - method to archive the specified entity
 * @returns {Deferred}
 */
export function confirmArchiveEntity(options) {

  return ui
    .confirm({
      title: tr('Archive {0}', options.entityName),
      message: tr(
        'Are you sure you want to archive the {0} <b>{1}</b>? This will affect running campaigns in Custom Snippets that use this {0}.',
        options.entityName,
        htmlSanitizer.escape(options.instanceName),
      ),
      isWarning: true,
      confirmText: tr('Archive'),
    })
    .then(() => options.archiveAction(options.entityInstance));
}

/**
 * Confirm that the user wants to archive the event and then archive it
 * Note: Use this only for events, all other entities should use confirmArchiveEntity
 *
 * @param {Object} event
 * @returns {Deferred}
 */
export function confirmArchiveEvent(event) {
  return ui
    .confirm({
      title: tr('Archive Event'),
      message: tr(
        'Are you sure you want to archive the click event <b>{0}</b>? This event will no longer be tracked. If this event is a tracked metric on a running experiment, its results may become inaccurate.',
        htmlSanitizer.escape(event.name),
      ),
      isWarning: true,
      confirmText: tr('Archive Event'),
    })
    .then(() => EventModuleActions.archive(event));
}

/**
 * Confirm that the user wants to delete the entity and then delete it
 *
 * @param {Object} options
 * @param {Object} options.entityInstance - instance of the entity to pass to the delete action
 * @param {Function} options.deleteAction - method to delete the specified entity
 * @param {String} options.entityType - string to identify the entity being deleted
 * @returns {Deferred}
 */
export function confirmDeleteEntity(options) {
  return ui
    .confirm({
      title: options.title || tr('Delete {0}', options.entityType),
      message:
        options.message ||
        tr('Are you sure you want to delete this {0}?', options.entityType),
      confirmText: options.confirmText || tr('Yes'),
      cancelText: options.cancelText || tr('No'),
      isWarning: true,
      publishWarning: options.publishWarning || null,
    })
    .then(() => options.deleteAction(options.entityInstance));
}

/**
 * Confirm dirty settings before navigating away from the component.
 */
export function confirmNavigation(shouldConfirm, entityName, navigationAction) {
  if (shouldConfirm) {
    return ui
      .confirm({
        title: tr('Discard unsaved changes?'),
        message: tr(
          'You have unsaved changes which will be discarded if you continue without saving first.',
        ),
        confirmText: tr('Discard & Continue'),
        isWarning: true,
      })
      .then(navigationAction);
  }
  return Promise.resolve().then(navigationAction);
}

export function confirmRevert(callback) {
  ui.confirm({
    title: tr('Revert Changes'),
    message: tr(
      'Are you sure you want to return to your last saved changes? Reverting will lose your current changes.',
    ),
    confirmText: tr('Revert Changes'),
    isWarning: true,
  }).then(callback);
}

/**
 ********************************************************************************
 *   Campaign Management Dialogs                                                *
 ********************************************************************************
 */

const fetchTargetingConditionsAndTagGroups = function(dataToFetch) {
  const getTargetingConditions = TargetingConditionActions.fetchAll(null, {
    skipEvaluatingCachedData: true,
  });

  const getDefaultTagGroup = TagGroupActions.fetchDefaultTagGroup(
    flux.evaluate(CurrentProjectGetters.id),
  );
  return dataToFetch.concat([getTargetingConditions, getDefaultTagGroup]);
};

/**
 * Shows the audience editor dialog for creating or editing an audience
 *
 * @param {object} audience
 * @param {Deferred} onDone
 */
export const showAudienceEditorDialog = featureFunction(
  'reduce_data_fetching_audience_builder',
)(function(audience, onDone, onHide) {
  PerformanceTrackingActions.clearPerformanceMark('load_audience_editor_begin');
  PerformanceTrackingActions.setPerformanceMark('load_audience_editor_begin');
  const isWebProject = flux.evaluate(CurrentProjectGetters.isWebProject);
  let dataToFetch = [];
  const getAudiencesBundles = BundleSplitHelper.getAudiencesBundleModules();
  dataToFetch.push(getAudiencesBundles);

  if (this.reduce_data_fetching_audience_builder) {
    // Maintain status quo for web audience builder
    if (isWebProject) {
      dataToFetch = fetchTargetingConditionsAndTagGroups(dataToFetch);
    } else {
      // To improve:
      const getTargetingConditions = TargetingConditionActions.fetchAll();
      dataToFetch.push(getTargetingConditions);
    }
  } else {
    dataToFetch = fetchTargetingConditionsAndTagGroups(dataToFetch);
  }

  return Promise.all(dataToFetch).then(([{ AudienceEditor }]) => {
    /* Debugging for FLAKY-193 */
    __TEST__ && console.warn('[showAudienceEditorDialog] Conditions and AudienceEditor fetched. Showing Dialog.'); // eslint-disable-line
    ui.showDialog({
      component: AudienceEditor,
      noPadding: true,
      // Ensure the dialog container is fullscreen
      fullScreen: true,
      dismissOnBack: true,
      onHideDialog: onHide,
      data: {
        audience,
        // Ensure the audience editor content should take up the full container space
        fullScreen: true,
        _onSave: onDone,
      },
    });
  });
});

/**
 ********************************************************************************
 *   Fullstack Dialogs                                                          *
 ********************************************************************************
 */

/**
 * Opens the event config dialog
 * @param {String}    initialName
 * @param {Function}  onSave
 */
export function showFullstackEventConfigDialog(initialName, onSave) {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const primarySDKLanguage = flux.evaluate(
    CurrentProjectGetters.primarySDKLanguage,
  );
  const eventToEdit = EventModuleFns.createEventEntity({
    event_type: EventModuleEnums.eventTypes.CUSTOM,
    api_name: initialName,
    name: initialName,
    project_id: projectId,
  });

  ui.showReactDialog(
    EventConfigDialog,
    {
      props: {
        onSaveEvent: onSave,
        event: toImmutable(eventToEdit),
        language: primarySDKLanguage,
      },
    },
    {
      noPadding: true,
      isOuiDialog: true,
      fullScreen: true,
      dismissOnBack: true,
    },
  );
}

/**
 ********************************************************************************
 *   Region navigation specific to the Campaign Editor                          *
 ********************************************************************************
 */

/**
 * Render the change list sidebar.
 * @param {Object} component
 * @param {Object} config
 */
export function showChangeListSidebar(config) {
  ui.renderRegion('p13n-editor-sidebar', {
    // TODO: unravel circular dependency here preventing ES6 importing this component
    component: require('bundles/p13n/components/editor/sidebar/change_list_sidebar').default, // eslint-disable-line
    parent: config && config.parent,
    data: config && config.data,
  });
}

/**
 * Render the change list sidebar.
 */
export function showChangeSelectorSidebar() {
  ui.renderRegion('p13n-editor-sidebar', {
    // TODO: unravel circular dependency preventing ES6 import of the ChangeSelectorSidebar
    component: require('bundles/p13n/components/editor/sidebar/change_selector_sidebar').default, // eslint-disable-line
  });
}

/**
 * Render the change editor sidebar.
 * param {boolean} isNewEmptyChange - set to true to indicate that the change was created from the change selector
 *                                    sidebar, this will cause the component to return to the change-selector-sidebar
 *                                    when the back button is clicked
 */
export function showChangeEditorSidebar(isNewEmptyChange) {
  const data = {};
  if (isNewEmptyChange) {
    data.isNewChange = true;
  }
  ui.renderRegion('p13n-editor-sidebar', {
    // TODO: eliminate circular dependency preventing us from ES6 importing the component
    component: require('bundles/p13n/components/editor/sidebar/change_editor_sidebar').default, // eslint-disable-line
    data,
  });
}

/**
 * Render the insert html sidebar.
 *
 * param {Object} component - insert HTML sdiebar component
 * param {boolean} isNewChange - set to true to indicate that the change was created from the change selector
 *                               sidebar, this will cause the component to return to the change-selector-sidebar
 *                               when the back button is clicked
 */
export function showInsertHTMLSidebar(isNewChange) {
  const data = {};
  if (isNewChange) {
    data.isNewChange = true;
  }
  ui.renderRegion('p13n-editor-sidebar', {
    // TODO: eliminate circular dependency preventing us from ES6 importing the component
    component: require('bundles/p13n/components/editor/sidebar/insert_html_sidebar').default, // eslint-disable-line
    data,
  });
}

/**
 * Render the insert image sidebar.
 *
 * param {Object} component - insert HTML sdiebar component
 * param {boolean} isNewChange - set to true to indicate that the change was created from the change selector
 *                               sidebar, this will cause the component to return to the change-selector-sidebar
 *                               when the back button is clicked
 */
export function showInsertImageSidebar(isNewChange) {
  const data = {};
  if (isNewChange) {
    data.isNewChange = true;
  }
  ui.renderRegion('p13n-editor-sidebar', {
    // TODO: eliminate circular dependency preventing us from ES6 importing the component
    component: require('bundles/p13n/components/editor/sidebar/insert_image_sidebar').default, // eslint-disable-line
    data,
  });
}

/**
 * Render the redirect editor sidebar.
 */
export function showRedirectEditorSidebar(isNewChange) {
  const data = {};
  if (isNewChange) {
    data.isNewChange = true;
  }
  ui.renderRegion('p13n-editor-sidebar', {
    // TODO: eliminate circular dependency preventing us from ES6 importing the component
    component: require('bundles/p13n/components/editor/sidebar/redirect_editor_sidebar'), // eslint-disable-line
    data,
  });
}

/**
 ********************************************************************************
 *   Project level persistent notifications                                     *
 ********************************************************************************
 */

/**
 *  Persistent notification message copy for when new client is disabled for a project
 *  @private
 *  @param{String} projectId
 */
function getNewClientDisabledWarning(projectId) {
  if (!projectId) {
    return;
  }
  const v2PlatformText = flux.evaluate(V2MigrationGetters.v2PlatformText);
  const settingsUrl = UrlHelper.projectSettingsImplementation(projectId);
  const hrefLink = `<a href="${settingsUrl}" v-push-state> enable ${v2PlatformText}</a>`;
  return tr(
    'Welcome to {0}! To publish or preview campaigns on the new platform, <strong>please {1} in your snippet.</strong>',
    v2PlatformText,
    hrefLink,
  );
}

/**
 *  Persistent notification message copy for when navigating in archived project
 *  @private
 */
function getNavigatingInArchivedProjectWarning() {
  const manageProjectsURL = UrlHelper.manageProjectsPage(true);
  return tr(
    'This project is currently archived. Please ​<a href={0}>unarchive</a> it to make changes.',
    manageProjectsURL,
  );
}

/**
 *  Persistent notification message copy for when v1 client is disabled for a project
 *  @private
 *  @param{String} projectId
 */
function getV1DisabledWarning(
  projectId,
  canChangeSnippetBundlingConfiguration,
  canUsePersonalizationOnly,
) {
  if (!projectId) {
    return;
  }
  const v1PlatformText = flux.evaluate(V2MigrationGetters.v1PlatformText);
  const v2PlatformText = flux.evaluate(V2MigrationGetters.v2PlatformText);
  const settingsUrl = V1Router.dashboardTab(projectId, 'implementation');
  const hrefLink = `<a href="${settingsUrl}" v-push-state>change your snippet settings</a>`;
  if (canChangeSnippetBundlingConfiguration) {
    return tr(
      '{0} is turned off. To re-enable {0}, {1}.',
      v1PlatformText,
      hrefLink,
    );
  }
  const v2Url = UrlHelper.xWebHome(projectId);
  if (canUsePersonalizationOnly) {
    const p13nHrefLink = `<a href="${v2Url}">click here</a>`;
    return tr(
      '{0} is turned off. To use {1}, {2}.',
      v1PlatformText,
      v2PlatformText,
      p13nHrefLink,
    );
  }
  const newOptimizelyHrefLink = `<a href="${v2Url}" v-push-state>${v2PlatformText}</a>`;
  return tr(
    '{0} is turned off. To create a new experiment, use {1}.',
    v1PlatformText,
    newOptimizelyHrefLink,
  );
}

/**
 * show persistent notification if project being navigated in is archived
 * @param {String} projectStatus
 */
export function warnIfInArchivedProject(projectStatus) {
  if (projectStatus === ProjectEnums.project_status.ARCHIVED) {
    const notificationMessage = getNavigatingInArchivedProjectWarning();
    ui.showPersistentNotification({
      message: notificationMessage,
      type: 'warning',
      id: 'archived-project-warning-notification',
      safeHTML: true,
    });
  } else {
    ui.clearPersistentNotification('archived-project-warning-notification');
  }
}

/**
 * show persistent notification in case of project with personalization or ab2.0
 * does not have the new client enabled
 * @param {String} projectId
 * @param {Boolean} isPersonalizationEnabledInClient
 */
export function warnIfPersonalizationIsOff(
  projectId,
  isPersonalizationEnabledInClient,
) {
  if (!isPersonalizationEnabledInClient) {
    const notificationMessage = getNewClientDisabledWarning(projectId);
    ui.showPersistentNotification({
      message: notificationMessage,
      type: 'warning',
      id: 'personalization-project-disabled-warning-notification',
      safeHTML: true,
    });
  } else {
    ui.clearPersistentNotification(
      'personalization-project-disabled-warning-notification',
    );
  }
}

/**
 * show persistent notification in case a project has V1 disabled
 * @param {String} projectId
 * @param {String} clientType
 */
export function warnIfV1SnippetIsDisabled(
  projectId,
  clientType,
  canChangeSnippetBundlingConfig,
  canUsePersonalizationOnly,
) {
  if (clientType === ProjectEnums.ClientBuildSettings.STANDALONE) {
    const notificationMessage = getV1DisabledWarning(
      projectId,
      canChangeSnippetBundlingConfig,
      canUsePersonalizationOnly,
    );
    ui.showPersistentNotification({
      message: notificationMessage,
      type: 'warning',
      id: 'v1-snippet-disabled-warning-notification',
      safeHTML: true,
    });
  } else {
    ui.clearPersistentNotification('v1-snippet-disabled-warning-notification');
  }
}

export function showMABNotificationDialog(onCreate) {
  ui.showReactDialog(
    MultiarmedBanditNotification,
    {
      props: {
        onCreateClick: onCreate,
      },
    },
    {
      dismissOnBack: true,
      isOuiDialog: true,
      shouldTrack: true,
    },
  );
}

export default {
  confirmArchiveEntity,
  confirmArchiveEvent,
  confirmDeleteEntity,
  confirmNavigation,
  confirmRevert,

  // Campaign Management Dialogs
  showAudienceEditorDialog,
  showMABNotificationDialog,

  // Fullstack Dialogs
  showFullstackEventConfigDialog,

  // Campaign Editor Navigation
  showChangeEditorSidebar,
  showChangeListSidebar,
  showChangeSelectorSidebar,
  showInsertHTMLSidebar,
  showInsertImageSidebar,
  showRedirectEditorSidebar,

  // persistent notifications
  warnIfInArchivedProject,
  warnIfPersonalizationIsOff,
  warnIfV1SnippetIsDisabled,
};

/**
 * Entity Definition for list attribute
 */

export const entity = 'list_attributes';

export const parent = {
  entity: 'projects',
  key: 'project_id',
};

export default {
  entity,
  parent,
};

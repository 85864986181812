const _ = require('lodash');
const Immutable = require('optly/immutable').default;
const PropTypes = require('prop-types');
const React = require('react');

// components
const { Input, Textarea, Label } = require('optimizely-oui');

const FeatureDetails = props => {
  const handleInputChange = (event, property) => {
    const inputValue = event.target.value;
    props.updateFeatureProperty(property, inputValue);
  };

  const renderErrorBlock = () => {
    if (props.feature.get('api_name')) {
      if (!props.isFeatureKeyUnique) {
        return (
          <div
            className="lego-form-note lego-form-note--bad-news"
            data-test-section="feature-dialog-unique-key-error">
            Key is already in use by another feature in this project. Please
            choose a unique key.
          </div>
        );
      }

      if (!props.isFeatureKeyValid(props.feature)) {
        return (
          <div
            className="lego-form-note lego-form-note--bad-news"
            data-test-section="feature-dialog-invalid-key-error">
            Please enter a valid key.
          </div>
        );
      }
    }

    return '';
  };

  return (
    <div>
      <div className="push-quad--bottom">
        <div className="beta push--bottom">
          {props.isEditing ? 'Edit Feature Flag' : 'New Feature Flag'}
        </div>
        <p>Create a feature flag you&apos;d like to experiment on.</p>
      </div>
      <form>
        <fieldset>
          <ol className="lego-form-fields">
            <li className="lego-form-field__item">
              <h3>Feature Flag Key</h3>
              <p>
                A unique identifier for your feature flag that can be referenced
                in your code.
              </p>
              <Input
                type="text"
                testSection="feature-dialog-key"
                value={props.feature.get('api_name')}
                onBlur={() =>
                  props.onBlurTrigger(props.feature.get('api_name'))
                }
                onChange={_.partialRight(handleInputChange, 'api_name')}
              />
              {renderErrorBlock()}
            </li>
            <li className="lego-form-fields__item">
              <h3>
                <Label isOptional={true}>
                  <span>Description</span>
                </Label>
              </h3>
              <Textarea
                testSection="feature-dialog-description"
                defaultValue={props.feature.get('description')}
                onChange={_.partialRight(handleInputChange, 'description')}
              />
            </li>
          </ol>
        </fieldset>
      </form>
    </div>
  );
};

FeatureDetails.propTypes = {
  feature: PropTypes.instanceOf(Immutable.Map).isRequired,
  isEditing: PropTypes.bool.isRequired,
  isFeatureKeyUnique: PropTypes.bool.isRequired,
  isFeatureKeyValid: PropTypes.func.isRequired,
  onBlurTrigger: PropTypes.func.isRequired,
  updateFeatureProperty: PropTypes.func.isRequired,
};

module.exports = FeatureDetails;

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import flux from 'core/flux';
import { loadingWhenFetchAllPages } from 'core/modules/loading/actions';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import FilterableTableEnums from 'optly/modules/filterable_table/enums';
import LayerActions from 'optly/modules/entity/layer/actions';
import AudienceActions from 'optly/modules/entity/audience/actions';
import LayerSummaryActions from 'optly/modules/entity/layer_summary/actions';
import LiveCommitTagActions from 'optly/modules/entity/live_commit_tag/actions';
import TargetingConditionActions from 'optly/modules/entity/targeting_condition/actions';
import ChampagneEnums from 'optly/modules/optimizely_champagne/enums';
import SentryActions from 'optly/modules/sentry/actions';
import { actions as ProjectIntegrationActions } from 'optly/modules/entity/project_integration';

import CategoriesActions from 'bundles/p13n/modules/predefined_categories/actions';
import { fetchJiraLinkedIssuesData } from 'bundles/p13n/routing_fns';
import RoutingHelpers from 'bundles/p13n/routing_helpers';

import component from './page_component';

const routingSetup = (ctx, next) => {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const commonFetchOptions = { skipEvaluatingCachedData: true };

  const preloadLegacyDashboardData = !isFeatureEnabled(
    ChampagneEnums.FEATURES.use_search_api_for_sonic_search_boxes.FEATURE_KEY,
  );
  const fetchAllAudiences = !isFeatureEnabled(
    ChampagneEnums.FEATURES.audience_combo_reskin.FEATURE_KEY,
  );

  // Fetch layer_summaries in the background as they are needed to start a layer in the '...' dropdown
  LayerSummaryActions.fetchNonArchivedLayerSummaries(
    projectId,
    commonFetchOptions,
  ).fail(jqXHR => {
    /* eslint-disable no-param-reassign */
    jqXHR.optlyErrorHandled = true;
    const {
      responseText,
      originalRequestOptions,
      status: httpStatusCode,
    } = jqXHR;

    const httpRequestMethod =
      originalRequestOptions && originalRequestOptions.type;
    const errorToSend = new Error(responseText);
    SentryActions.withScope(scope => {
      scope.setTag('is_api_error', true);
      scope.setTag('api_response_status_code', httpStatusCode);
      scope.setTag('api_request_method', httpRequestMethod);
      scope.setFingerprint([httpStatusCode, errorToSend.name]);

      SentryActions.captureException(errorToSend);
    });
  });

  const liveTagPromise = LiveCommitTagActions.fetchAll(
    { project_id: projectId },
    commonFetchOptions,
  );

  fetchJiraLinkedIssuesData();

  let preloadPromise = Promise.resolve();
  if (preloadLegacyDashboardData) {
    const layersFetchAllPages = LayerActions.fetchAllByStatus({
      projectId,
      byPage: true,
      archived: false,
    });

    loadingWhenFetchAllPages(
      FilterableTableEnums.tableIds.P13N_LAYERS,
      layersFetchAllPages,
    );

    preloadPromise = Promise.all([
      layersFetchAllPages.firstPage,
      liveTagPromise,
      CategoriesActions.fetchAll({}, commonFetchOptions),
      TargetingConditionActions.fetchAll({}, commonFetchOptions),
    ]);
  }

  return preloadPromise.then(() => {
    next();
    // fetch audiences (if necessary), events and views in the background as they are needed for the create modal
    const byProject = {
      project_id: projectId,
    };

    if (fetchAllAudiences) {
      AudienceActions.fetchAll(byProject, {
        skipEvaluatingCachedData: true,
      });
    }
    ProjectIntegrationActions.fetchAll(byProject);
    RoutingHelpers.fetchCreateLayerData();
  });
};

export { component, routingSetup };

export default {
  component,
  routingSetup,
};

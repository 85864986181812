import flux from 'core/flux';

import actionTypes from './action_types';

export function setCurrentlyEditingExperimentId(experimentId) {
  flux.dispatch(actionTypes.OASIS_SET_CURRENTLY_EDITING_EXPERIMENT_ID, {
    experimentId,
  });
}

export function setCurrentlyEditingExperimentTab(tab) {
  flux.dispatch(actionTypes.OASIS_SET_CURRENTLY_EDITING_EXPERIMENT_TAB, {
    tab,
  });
}

export default {
  setCurrentlyEditingExperimentId,
  setCurrentlyEditingExperimentTab,
};

import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Nuclear.Store({
  getInitialState() {
    return toImmutable({
      integrationId: null,
      customIntegrationIsSelected: false,
    });
  },

  initialize() {
    this.on(actionTypes.SELECT_INTEGRATION_ID, setSelectedIntegrationId);
    this.on(
      actionTypes.SET_CUSTOM_INTEGRATION_SELECTED,
      setCustomIntegrationIsSelected,
    );
  },
});

function setSelectedIntegrationId(state, payload) {
  return state.set('integrationId', payload.id);
}

function setCustomIntegrationIsSelected(state, payload) {
  return state.set('customIntegrationIsSelected', payload);
}

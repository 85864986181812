import { fns as MetricFns } from 'optly/modules/entity/metric';

export function getRatioMetricDescription(
  winningDirection,
  subMetrics,
  eventsList,
) {
  const [numerator, denominator] = subMetrics;
  const numeratorEventName = eventsList[numerator?.event_id]?.name;
  const denominatorEventName = eventsList[denominator?.event_id]?.name;

  const winningDirectionMap = {
    decreasing: 'Decrease',
    increasing: 'Increase',
  };

  return `${winningDirectionMap[winningDirection]} in ratio for ${numeratorEventName} over ${denominatorEventName}`;
}

export const getFullMetricDescription = metricWrapper => {
  if (!metricWrapper) {
    return '';
  }

  const metric = metricWrapper.get('metric');
  const metricDescription = metricWrapper.get('description');

  if (MetricFns.isMetricOverallRevenue(metric)) {
    return metricDescription;
  }

  const metricEvent = metricWrapper.get('eventName', metric.get('eventName'));
  return tr('{0} for {1} event', metricDescription, metricEvent);
};

export default {
  getFullMetricDescription,
};

/**
 * Util for sort functions
 *
 * @author Jordan Garcia
 */

// CONSTANTS
const ASC = 'asc';
const DESC = 'desc';

const sortFunctions = {
  string(a, b) {
    a = a && a.toLowerCase ? a.toLowerCase() : a;
    b = b && b.toLowerCase ? b.toLowerCase() : b;
    if (a < b) {
      return 1;
    }
    if (a > b) {
      return -1;
    }
    return 0;
  },
  number(a, b) {
    return b - a;
  },
  date(a, b) {
    return new Date(b) - new Date(a);
  },
  length(a, b) {
    return b.length - a.length;
  },
  /**
   * Sort function based on truthiness
   * If a is truthy and b is falsy than a
   * comes before b in desc order
   */
  boolean(a, b) {
    if (a === b) {
      return 0;
    }
    if (a) {
      return -1;
    }
    if (b) {
      return 1;
    }
  },
};

/**
 * Returns a new function that sorts by some object property
 * @param {function} fn
 * @param {string} field
 * @param {string} getFn
 * @return {function}
 */
function sortByField(fn, field, getFn) {
  if (!getFn) {
    return function(a, b) {
      return fn(a[field], b[field]);
    };
  }
  return function(a, b) {
    return fn(getFn(a, field), getFn(b, field));
  };
}

/**
 * Reverse the sort result of a compare function
 * @param {function} fn
 * @return {number}
 */
function reverse(fn) {
  return function(a, b) {
    return -fn(a, b);
  };
}

/**
 * Combines an array of functions into a sort function
 * that handles ties by calling the next function
 * @param {array.<function>} fns
 * @return {function}
 */
function combineComparators(fns) {
  const len = fns.length;
  return function(a, b) {
    let i = 0;
    let result = 0;
    while (i < len && result === 0) {
      result = fns[i](a, b);
      i += 1;
    }
    return result;
  };
}

/**
 * Usage:
 * sortObjectsBy([
 *   { field: 'goal_type', type: 'number', dir: sort.ASC },
 *   { field: 'goal_ids', type: 'number', dir: sort.ASC }
 * ])
 * opts.sortFns - custom mapping of type => sort function
 * opts.fieldGetFn - getter function
 *
 * @param {array<object>|object} sortBy sort by definition shown above
 * @param {array<function>} sortFns (optional) array of field type => sort function -- default order must be desc
 *
 * @return {function}
 */
function sortObjects(sortBy, opts) {
  opts = opts || {};

  if (!Array.isArray(sortBy)) {
    // allow passing a single object as sortBy rules
    sortBy = [sortBy];
  }

  // fallback to default sort functions
  const fieldTypeSortFns = opts.sortFns || sortFunctions;

  const sortFns = sortBy.map(entry => {
    if (!entry.type || !entry.field || !entry.dir) {
      throw new Error(
        'Must supply type, field and dir when generating object sort function',
      );
    }

    let comparator = fieldTypeSortFns[entry.type];
    if (!comparator) {
      console.warn(
        `No sort comparator for type=${entry.type} - defaulting to string`,
      );
      comparator = sortFunctions.string;
    }
    // generate a sort function that sorts by the proper type
    // and looks at the proper field
    let sortFn = sortByField(comparator, entry.field, opts.fieldGetFn);
    if (entry.dir === ASC) {
      sortFn = reverse(sortFn);
    }
    return sortFn;
  });

  return combineComparators(sortFns);
}

function generateObjectSortFn(sortBy, customSortFns) {
  return sortObjects(sortBy, {
    sortFns: customSortFns,
  });
}

function generateImmutableObjectSortFn(sortBy, customSortFns) {
  return sortObjects(sortBy, {
    sortFns: customSortFns,
    fieldGetFn(obj, field) {
      if (field.indexOf('.') > -1) {
        const fields = field.split('.');
        return obj.getIn(fields);
      }
      return obj.get(field);
    },
  });
}

module.exports = {
  functions: sortFunctions,
  field: sortByField,
  reverse,
  combineComparators,
  generateObjectSortFn,
  generateImmutableObjectSortFn,
  ASC,
  DESC,
};

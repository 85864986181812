export const refreshLock = ['publicApiConsumer', 'refreshLock'];
export const initializationLock = ['publicApiConsumer', 'initializationLock'];
export const scheduledRefreshTimer = [
  'publicApiConsumer',
  'scheduledRefreshTimer',
];
export const lastRefreshTime = ['publicApiConsumer', 'lastRefreshTime'];
export const lastRefreshSucceeded = [
  'publicApiConsumer',
  'lastRefreshSucceeded',
];

export default {
  refreshLock,
  scheduledRefreshTimer,
  initializationLock,
  lastRefreshTime,
  lastRefreshSucceeded,
};

import Fieldset from './Fieldset';
import Footer from './Footer';
import Title from './Title';
import Wrapper from './Wrapper';

export { Fieldset, Footer, Title, Wrapper };

export default {
  Fieldset,
  Footer,
  Title,
  Wrapper,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Row,
  SearchPicker,
  Spinner,
  Icon,
} from 'optimizely-oui';

import noop from 'lodash/noop';

import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import ui from 'core/ui';
import ErrorBoundary from 'core/ui/components/error_boundary';

import AudienceActions from 'optly/modules/entity/audience/actions';
import { showSupportDialog } from 'optly/modules/support/actions';
import PublicApiConsumerActions from 'optly/modules/public_api_consumer/actions';
import truncate from 'optly/filters/truncate';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

import { connect } from 'core/ui/decorators';

/**
 * searchAudiencesByProjectId
 * @description Search audience data for a given projectId
 * @params {Number} projectId - the current project id
 * @returns {Function} - Search function
 * @function
 * @private
 */
const searchAudiencesByProjectId = projectId => ({ query }) => {
  return PublicApiConsumerActions.fetchPage('search', {
    queryParams: {
      type: 'audience',
      page: 1,
      per_page: 25,
      archived: false,
      projectId,
      query,
      ...(query === '' ? { sort: 'created' } : {}),
    },
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      ui.showNotification({
        message:
          'There was an error loading Audiences. Please refresh the page or visit our Help Center if the issue persists.',
        type: 'error',
      });
    })
    .catch(error => {
      ui.showNotification({
        message: error.message,
        type: 'error',
      });
    });
};

/**
 * AudienceSearchPicker
 * @description Render a search-input picker for rollout-rule audiences
 * @kind component
 * @example
 *    <AudienceSearchPicker
 *       addAudience={() => {}}
 *       openAudienceCombinationDialog={() => {}}
 *       openCreateAudienceDialog={() => {}}
 *       openEditAudienceDialog={() => {}}
 *       projectId={CurrentProjectGetters.id}
 *       selectedEntityIds={audienceIds}
 *    />
 */
@connect({
  isFullStackProject: CurrentProjectGetters.isFullStackProject,
  isFlagsProject: CurrentProjectGetters.isFlagsProject,
})
class AudienceSearchPicker extends Component {
  /* eslint-disable */
  static propTypes = {
    addAudience: PropTypes.func.isRequired,
    hasAudienceCombinationOption: PropTypes.bool,
    openAudienceCombinationDialog: PropTypes.func,
    openCreateAudienceDialog: PropTypes.func.isRequired,
    openEditAudienceDialog: PropTypes.func.isRequired,
    minDropdownWidth: PropTypes.number,
    placeholder: PropTypes.string,
    projectId: PropTypes.number.isRequired,
    selectedEntityIds: PropTypes.array.isRequired,
    isFullStackProject: PropTypes.bool,
    isFlagsProject: PropTypes.bool,
    /**
     * Whether or not the component should wait until the audience has been successfully fetched before calling addAudience
     * Should be set to false only if no other elements on the page rely on the newly-added audience data being immediately available in the data store.
     * Defaults to true as it is most likely that surrounding UI will expect the freshly-selected audience to be in memory.
     */
    shouldWaitForAudienceFetch: PropTypes.bool,
  };

  static defaultProps = {
    hasAudienceCombinationOption: false,
    openAudienceCombinationDialog: noop,
    placeholder: 'Search and add audiences',
    shouldWaitForAudienceFetch: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      isFetchingAudience: false,
      createdAudience: false,
      refreshKey: false,
      isDropdownOpenAndSearchInputFocused: false,
    };
  }
  /* eslint-enable */

  shouldRenderCreateAudienceBtn() {
    const { isFullStackProject, isFlagsProject } = this.props;
    return !(
      isFeatureEnabled('disable_creating_full_stack_entities') &&
      isFullStackProject &&
      !isFlagsProject
    );
  }

  handleAddAudience(audience) {
    const { addAudience, shouldWaitForAudienceFetch } = this.props;

    // fetch the newly-added audience entity (in case we don't have it already)
    if (shouldWaitForAudienceFetch) {
      this.setState({ isFetchingAudience: true });
      AudienceActions.fetch(audience.id).then(() => {
        this.setState({ isFetchingAudience: false });
        addAudience(audience);
      });
    } else {
      addAudience(audience);
      AudienceActions.fetch(audience.id);
    }
  }

  render() {
    const {
      hasAudienceCombinationOption,
      minDropdownWidth,
      openAudienceCombinationDialog,
      openCreateAudienceDialog,
      openEditAudienceDialog,
      placeholder,
      projectId,
      selectedEntityIds,
    } = this.props;
    const {
      isFetchingAudience,
      createdAudience,
      refreshKey,
      isDropdownOpenAndSearchInputFocused,
    } = this.state;
    const additionalItems = hasAudienceCombinationOption ? 2 : 1;

    const refreshOnCreate = () => {
      this.setState(() => ({ isDropdownOpenAndSearchInputFocused: true }));
      if (createdAudience) {
        this.setState(() => ({
          refreshKey: !refreshKey,
          createdAudience: false,
        }));
      }
    };
    return (
      <ErrorBoundary
        alternateContent={
          <div
            title="Something went wrong"
            data-test-section="audiences-error-boundary">
            <h3>Audiences</h3>
            There was an error loading Audiences. Please refresh the page or
            visit our Help Center if the issue persists.
            <div className="display--block push--top">
              <Button style="highlight" onClick={showSupportDialog}>
                Optimizely Help Center
              </Button>
            </div>
          </div>
        }>
        <SearchPicker
          additionalItems={additionalItems}
          key={`audience-search-${refreshKey}`}
          onItemSelected={(index, options, searchQuery) => {
            if (index === 0) {
              openCreateAudienceDialog(searchQuery);
              return;
            }
            if (hasAudienceCombinationOption && index === 1) {
              openAudienceCombinationDialog();
              return;
            }
            this.handleAddAudience(options[index - additionalItems]);
          }}
          searchFunction={searchAudiencesByProjectId(projectId)}
          selectedEntityIds={selectedEntityIds}
          supportedTypes={['audience']}>
          {({
            availableEntities,
            currentFauxFocusIndex,
            makeSearchRequest,
            isLoading,
            renderInput,
            resultsText,
            searchQuery,
          }) => (
            <Dropdown
              fullWidth={true}
              isOpen={isDropdownOpenAndSearchInputFocused}
              onClick={refreshOnCreate}
              renderActivator={activatorProps =>
                renderInput({
                  ...activatorProps,
                  placeholder,
                  testSection: 'audience-search-picker-input',
                  onFocus: () => makeSearchRequest(searchQuery),
                })
              }
              testSection="audience-search-picker-dropdown"
              isLoading={isLoading || isFetchingAudience}>
              <Dropdown.Contents minWidth={minDropdownWidth} direction="right">
                {this.shouldRenderCreateAudienceBtn() && (
                  <Dropdown.ListItem>
                    <Dropdown.BlockLink
                      hasFauxFocus={currentFauxFocusIndex === 0}
                      hideFocusCheckIcon={true}
                      onClick={async () => {
                        await openCreateAudienceDialog(searchQuery);
                        this.setState(
                          { createdAudience: true },
                          () => refreshOnCreate,
                        );
                      }}
                      testSection="audience-search-picker-create-audience-button">
                      <div className="flex flex-align--center">
                        <Icon color={brandBlueDark} name="plus" size="small" />
                        <span className="push--left">Create new audience</span>
                      </div>
                    </Dropdown.BlockLink>
                  </Dropdown.ListItem>
                )}
                {hasAudienceCombinationOption && (
                  <Dropdown.ListItem>
                    <Dropdown.BlockLink
                      hasFauxFocus={currentFauxFocusIndex === 1}
                      onClick={openAudienceCombinationDialog}
                      testSection="audience-search-picker-create-audience-combination-button">
                      <div className="flex flex-align--center">
                        <Icon color={brandBlueDark} name="plus" size="small" />
                        <span className="push--left">
                          Create new audience combination
                        </span>
                      </div>
                    </Dropdown.BlockLink>
                  </Dropdown.ListItem>
                )}
                <Dropdown.ListItem role="separator" ignoreToggle={true}>
                  <span data-test-section="audience-search-picker-list-divider">
                    {resultsText.summary}
                  </span>
                </Dropdown.ListItem>
                {isLoading && (
                  <div className="push flex flex-justified--center">
                    <Spinner size="small" />
                  </div>
                )}
                {!isLoading &&
                  availableEntities.map((audience, index) => (
                    <Dropdown.ListItem key={audience.id}>
                      <Container pullRowPadding={true}>
                        <Row verticalAlignment="center">
                          <Col small="fillSpace">
                            <Dropdown.BlockLink
                              hasFauxFocus={
                                currentFauxFocusIndex ===
                                index + additionalItems
                              }
                              onClick={() => this.handleAddAudience(audience)}
                              testSection="audience-search-picker-audience-add-button">
                              <div className="flex flex-align--center">
                                <div className="flex">
                                  <Icon
                                    color={brandBlueDark}
                                    name="plus"
                                    size="small"
                                  />
                                </div>
                                <span className="push--left word-break--word">
                                  <Dropdown.BlockLinkText
                                    text={truncate(audience.name, 68)}
                                  />
                                  {audience.description && (
                                    <Dropdown.BlockLinkSecondaryText
                                      secondaryText={audience.description}
                                      testSection="audience-search-picker-audience-description"
                                    />
                                  )}
                                </span>
                              </div>
                            </Dropdown.BlockLink>
                          </Col>
                          <Col small="fitContent">
                            <Button
                              style="outline"
                              size="small"
                              onClick={() => openEditAudienceDialog(audience)}
                              testSection="audience-search-pick-audience-view-button">
                              View
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Dropdown.ListItem>
                  ))}
                {isFetchingAudience && <Spinner hasOverlay={true} />}
              </Dropdown.Contents>
            </Dropdown>
          )}
        </SearchPicker>
      </ErrorBoundary>
    );
  }
}

export default AudienceSearchPicker;

import { partial, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Label } from 'optimizely-oui';

import { enums as ViewEnums } from 'optly/modules/entity/view';

import ConditionGroup from 'optly/models/condition_group';

import ReactCodeMirror from 'react_components/codemirror';

import ConditionCardHeader from '../card_header';
/*
 * Component to display a custom_css condition for a view
 * This component expects that props.condition.conditions
 * and props.errors have lengths of exactly 1
 */
const JavascriptCondition = ({
  condition,
  errors,
  updateErrors,
  onConditionChange,
}) => {
  const onChangeJSCode = newCode => {
    const newErrors = cloneDeep(errors);
    newErrors[0] = null;
    updateErrors(newErrors);

    condition.conditions[0].value = newCode;
    onConditionChange();
  };

  const error = errors[0];

  const esOptions = { esversion: 6 };

  return (
    <div
      className="border--all"
      data-test-section="condition-card-javascript-condition">
      <ConditionCardHeader
        showMultipleURLsMessage={false}
        onClose={partial(onConditionChange, condition)}
        title="JavaScript Condition"
      />
      <div className="soft">
        <Label isRequired={true}>Custom Code</Label>
        <ReactCodeMirror
          onChange={onChangeJSCode}
          testSection="javascript-condition-card-conditions"
          value={condition.conditions[0].value}
          options={{ lint: esOptions }}
        />
        {error && (
          <div
            className="oui-form-note color--bad-news"
            data-test-section="js-condition-error">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

JavascriptCondition.propTypes = {
  condition: PropTypes.instanceOf(ConditionGroup).isRequired,
  onConditionChange: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
};

JavascriptCondition.defaultProps = {
  errors: [],
};

JavascriptCondition.matchType = ViewEnums.conditionMatchTypes.CUSTOM_CODE;

export default JavascriptCondition;

import keyMirror from 'optly/utils/key_mirror';

export default keyMirror({
  // Create duplicate references to the rest api action types
  // since this module shouldn't know about the RestApi store
  API_ENTITY_PERSIST_START: null,
  API_ENTITY_PERSIST_FAIL: null,
  API_ENTITY_PERSIST_SUCCESS: null,
  LOADING_START: null,
  LOADING_FINISH: null,

  // LOADING
  ENTITY_LOADING_START: null,
  ENTITY_LOADING_FINISH: null,
  CLEAR_ALL_ENTITY_LOADING: null,
});

import React from 'react';

import hoistNonReactStatics from 'hoist-non-react-statics';

import ui from './index';

import ErrorBoundary from './components/error_boundary';

/**
 * Convenience function to hoist non React statics and forward refs.
 * @param {React.Component} Target
 * @param {React.Component} Source
 * @param {String} displayName
 * @returns {React.Component}
 */
const hoistStaticsAndForwardRefs = (Target, Source, displayName) => {
  // Make sure to hoist statics and forward any refs through from Source to Target
  // From the React docs:
  //   https://reactjs.org/docs/higher-order-components.html#static-methods-must-be-copied-over
  //   https://reactjs.org/docs/forwarding-refs.html#forwarding-refs-in-higher-order-components
  const forwardRef = (props, ref) => <Target {...props} forwardedRef={ref} />;
  forwardRef.displayName = `${displayName}(${Source.displayName ||
    Source.name})`;
  return hoistNonReactStatics(React.forwardRef(forwardRef), Source);
};

/**
 * Simple class decorator that wraps a React class
 * with a FluxConnector component using ui.connectGetters.
 * @param {function(*=)} mapStateToProps
 * @returns {function(*=)} Function which returns a Flux connected ReactComponentClass
 */
export const connect = mapStateToProps => ReactComponentClass =>
  ui.connectGetters(ReactComponentClass, mapStateToProps);

export const errorBoundary = (errorBoundaryProps = {}) => WrappedComponent => {
  class ErrorBoundaryWrapper extends React.Component {
    render() {
      const { forwardedRef, ...wrapperProps } = this.props;
      return (
        <ErrorBoundary {...errorBoundaryProps}>
          <WrappedComponent {...wrapperProps} ref={forwardedRef} />
        </ErrorBoundary>
      );
    }
  }

  return hoistStaticsAndForwardRefs(
    ErrorBoundaryWrapper,
    WrappedComponent,
    'errorBoundary',
  );
};

export default {
  connect,
  errorBoundary,
};

import React, { useEffect, useMemo, useState } from 'react';

import AudienceActions from 'optly/modules/entity/audience/actions';

import {
  Audience,
  AudienceCondition,
  AudienceConditionCombination,
  ConditionGroup,
} from './types';
import AudienceSearchDropdown from './audience_search_dropdown';
import AudienceConditionPicker from './audience_condition_picker';
import AudienceConditionTile from './audience_condition_tile';

const DEFAULT_AUDIENCE_CONDITIONS = null;

type AudienceMap = Record<number, string>;

function getAudienceName(audienceId: number, audiencesMap: AudienceMap) {
  return audiencesMap[audienceId] || '[Invalid Audience]';
}

function extractConditionGroup(state: AudienceConditionCombination) {
  if (state === null) {
    return null;
  }
  const condition = state[0] as ConditionGroup;
  return Object.values(ConditionGroup).includes(condition) ? condition : null;
}

function extractAudienceIds(state: AudienceConditionCombination): number[] {
  if (state === null) {
    return [];
  }
  const audienceIds: number[] = [];

  state.forEach(item => {
    if (typeof item !== 'string') {
      audienceIds.push(item.audienceId);
    }
  });

  return audienceIds;
}

function buildAudienceCombination(
  condition: ConditionGroup,
  audienceIds: number[],
): AudienceCondition[] {
  if (audienceIds.length === 0) {
    return [];
  }

  const combination: AudienceCondition[] = [condition];
  audienceIds.forEach(id => {
    combination.push({ audienceId: id });
  });

  return combination;
}

type AudienceCombinationsBuilderV3Props = {
  projectId: number;
  audienceConditions: AudienceConditionCombination;
  onChange: (audienceCondition: AudienceConditionCombination) => void;
  audiencesMap: AudienceMap;
};

export const AudienceCombinationsBuilderV3 = (
  props: AudienceCombinationsBuilderV3Props,
) => {
  const {
    projectId,
    audienceConditions: initialAudienceConditions = DEFAULT_AUDIENCE_CONDITIONS,
    onChange,
    audiencesMap,
  } = props;

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [audienceCombination, setAudienceCombination] = useState<
    AudienceConditionCombination
  >(initialAudienceConditions);

  // Computed values from state
  const {
    selectedCondition,
    selectedAudienceIds,
    isCombinationEveryone,
    isCombinationNotSet,
  } = useMemo(() => {
    return {
      selectedCondition: extractConditionGroup(audienceCombination),
      selectedAudienceIds: extractAudienceIds(audienceCombination),
      isCombinationEveryone:
        Array.isArray(audienceCombination) && audienceCombination.length === 0,
      isCombinationNotSet: audienceCombination === null,
    };
  }, [audienceCombination]);

  /**
   * Use this function to update the audience combination state. It also propagates that change to the parent element
   * so that it can use it.
   * @param newCombination
   */
  const updateAudienceCombination = (
    newCombination: AudienceConditionCombination,
  ) => {
    setShowDropdown(newCombination === null);
    setAudienceCombination(newCombination);
    onChange(newCombination);
  };

  const handleAddAudience = (audience: Audience) => {
    const newState: AudienceCondition[] = buildAudienceCombination(
      selectedCondition || ConditionGroup.OR,
      [...selectedAudienceIds, audience.id],
    );
    updateAudienceCombination(newState);
  };

  const handleRemoveAudience = (audienceId: number) => {
    let newState = null;
    if (selectedAudienceIds.length > 1) {
      newState = buildAudienceCombination(
        selectedCondition || ConditionGroup.OR,
        selectedAudienceIds.filter(id => id !== audienceId),
      );
    }
    updateAudienceCombination(newState);
  };

  const handleConditionChange = (condition: ConditionGroup) => {
    const newState = buildAudienceCombination(condition, selectedAudienceIds);
    updateAudienceCombination(newState);
  };

  return (
    <div>
      {selectedAudienceIds.length > 1 && selectedCondition && (
        <AudienceConditionPicker
          currentCondition={selectedCondition}
          onChange={handleConditionChange}
        />
      )}

      <div data-test-section="audience-condition-list">
        {isCombinationEveryone ? (
          <AudienceConditionTile
            key="everyone"
            name="Everyone"
            canAdd={true}
            handleAddNewCondition={() => {
              setShowDropdown(true);
            }}
            onDelete={() => {
              updateAudienceCombination(null);
            }}
          />
        ) : (
          selectedAudienceIds.map((audienceId, index) => {
            const isLastAudience = index === selectedAudienceIds.length - 1;

            return (
              <AudienceConditionTile
                key={audienceId}
                name={getAudienceName(audienceId, audiencesMap)}
                description={`ID:${audienceId}`}
                canAdd={isLastAudience}
                handleAddNewCondition={() => {
                  setShowDropdown(true);
                }}
                onDelete={() => handleRemoveAudience(audienceId)}
              />
            );
          })
        )}
      </div>

      {(isCombinationNotSet || showDropdown) && (
        <AudienceSearchDropdown
          everyoneSelected={isCombinationEveryone}
          projectId={projectId}
          selectedAudienceIds={selectedAudienceIds}
          onEveryoneClicked={() => {
            updateAudienceCombination([]);
          }}
          onAudienceClicked={handleAddAudience}
        />
      )}
    </div>
  );
};

type AudienceCombinationsBuilderV3WithDataProps = {
  projectId: number;
  audienceConditions: AudienceConditionCombination;
  onChange: (audienceCondition: AudienceConditionCombination) => void;
};

const AudienceCombinationsBuilderV3WithData = (
  props: AudienceCombinationsBuilderV3WithDataProps,
) => {
  const { projectId, audienceConditions, onChange } = props;
  const [audiencesMap, setAudiencesMap] = useState<AudienceMap>({});

  useEffect(() => {
    const audiencesDeferred = AudienceActions.fetchSavedAudiences(projectId);

    audiencesDeferred.then((audienceObjects: Audience[]) => {
      const newAudienceMap: AudienceMap = {};
      audienceObjects.forEach((audience: Audience) => {
        newAudienceMap[audience.id] = audience.name;
      });
      setAudiencesMap(newAudienceMap);
    });
  }, [projectId]);

  return (
    <AudienceCombinationsBuilderV3
      projectId={projectId}
      audienceConditions={audienceConditions}
      onChange={onChange}
      audiencesMap={audiencesMap}
    />
  );
};

export default AudienceCombinationsBuilderV3WithData;

import PropTypes from 'prop-types';
import React from 'react';
import { withTrack } from '@optimizely/segment-js/dist/decorators';

import ui from 'core/ui';

import { Button, DialogNew, Link } from 'optimizely-oui';

import createMABImage from '/static/img/mab.svg';

@withTrack
class MultiArmedBanditNotification extends React.Component {
  static componentId = 'MultiArmedBanditNotification';

  static propTypes = {
    onCreateClick: PropTypes.func.isRequired,
    track: PropTypes.func,
  };

  static defaultProps = {
    track: () => {},
  };

  componentDidMount() {
    const { track } = this.props;

    track('MAB Tutorial Dialog Viewed');
  }

  onCreateClick = () => {
    const { onCreateClick, track } = this.props;

    ui.hideDialog();
    onCreateClick();
    track('MAB Tutorial Dialogue Create Clicked');
  };

  onCloseClick = () => {
    const { track } = this.props;

    ui.hideDialog();
    track('MAB Tutorial Dialog Closed');
  };

  render() {
    const { track } = this.props;

    return (
      <DialogNew
        footerButtonList={[
          <Button key="cancel" onClick={this.onCloseClick} style="plain">
            Cancel
          </Button>,
          <Button key="create" onClick={this.onCreateClick} style="highlight">
            Create
          </Button>,
        ]}
        hasCloseButton={true}
        onClose={this.onCloseClick}
        title="Make an Impact with Multi-Armed Bandits">
        <div className="flex flex--row">
          <img
            alt=""
            className="no-filter push--right"
            height="164px"
            src={createMABImage}
          />
          <div className="flex flex--column">
            <p>
              Multi-armed bandits boost performance across your variations by
              dynamically allocating traffic to the variation where your primary
              metric is doing best. They ignore statistical significance in
              favor of maximizing your goal.
            </p>
            <p>
              <Link
                href="https://help.optimizely.com/Build_Campaigns_and_Experiments/When_to_experiment_and_when_to_optimize"
                newWindow={true}
                onClick={() => {
                  track('MAB Tutorial Dialogue KB Article Clicked');
                }}>
                Learn more
              </Link>{' '}
              about the differences between A/B experiments and multi-armed
              bandit optimizations.
            </p>
          </div>
        </div>
      </DialogNew>
    );
  }
}

export default MultiArmedBanditNotification;

import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'optly/immutable';
import Card from 'react_components/card';
import { HelpPopover, Table } from 'optimizely-oui';
import moment from 'moment';

import ScheduleFns from 'optly/modules/entity/schedule/fns';

const SummaryStatsSection = ({ recommender, summaryStats }) => {
  const totalRecommendedItems = Number(summaryStats.get('total_num_targets'));
  const fullRecommendedItems = Number(
    summaryStats.get('num_targets_with_full_recommendations'),
  );
  const halfRecommendedItems = Number(
    summaryStats.get('num_targets_with_half_recommendations'),
  );

  /**
   * Return the string that indicates the last time that the job status is updated successfully.
   *
   * @returns {String}
   */
  const renderUpdatedTime = () => {
    const lastRun = recommender.getIn(['job_status_summary', 'last_run']);
    const date = moment(lastRun).format('LL');
    const time = moment(lastRun).format('LT');
    const timeZone = ScheduleFns.getCurrentTimezoneName().replace('_', ' ');

    return (
      <div
        className="muted micro zeta"
        data-test-section="recommender-previewer-last-generated-time">
        Last generated: {`${date} at ${time} (${timeZone})`}
      </div>
    );
  };

  return (
    <div className="push-quad--ends">
      <div className="flex">
        <h3 className="flex--1" data-test-section="summary-stats-section-title">
          Summary for the last 7 days
        </h3>
        {renderUpdatedTime()}
      </div>
      <Card testSection="recommender-summary-card">
        <Card.Body>
          <Table style="wall">
            <Table.TBody>
              <Table.TR>
                <Table.TD>
                  <div className="text--left">
                    <div className="flex">
                      <div className="micro muted push-half--bottom">
                        Total Recommended Items
                      </div>
                      <HelpPopover
                        behavior="hover"
                        horizontalAttachment="center"
                        testSection="total-recommended-items-pop-tip"
                        verticalAttachment="bottom">
                        The number of rows of recommendations calculated since
                        the last time the algorithm was run.
                      </HelpPopover>
                    </div>
                    <div
                      className="beta"
                      data-test-section="total-recommended-items">
                      {totalRecommendedItems.toLocaleString()}
                    </div>
                  </div>
                </Table.TD>
                <Table.TD>
                  <div className="text--left">
                    <div className="flex">
                      <div className="micro muted push-half--bottom">
                        Full Recommendations
                      </div>
                      <HelpPopover
                        behavior="hover"
                        horizontalAttachment="center"
                        testSection="full-recommended-items-pop-tip"
                        verticalAttachment="bottom">
                        Items for which there is a complete set of 20
                        recommendations per item.
                      </HelpPopover>
                    </div>
                    <div
                      className="beta"
                      data-test-section="full-recommended-items">
                      {fullRecommendedItems.toLocaleString()}
                    </div>
                  </div>
                </Table.TD>
                <Table.TD>
                  <div className="text--left">
                    <div className="flex">
                      <div className="micro muted push-half--bottom">
                        Partial Recommendations
                      </div>
                      <HelpPopover
                        behavior="hover"
                        horizontalAttachment="center"
                        testSection="half-recommended-items-pop-tip"
                        verticalAttachment="bottom">
                        Items for which there are fewer than 10 recommendations.
                        This is usually due to missing data about these items.
                      </HelpPopover>
                    </div>
                    <div
                      className="beta"
                      data-test-section="half-recommended-items">
                      {halfRecommendedItems.toLocaleString()}
                    </div>
                  </div>
                </Table.TD>
              </Table.TR>
            </Table.TBody>
          </Table>
          <hr className="lego-rule" />
        </Card.Body>
      </Card>
    </div>
  );
};

SummaryStatsSection.propTypes = {
  /**
   * Current Recommender
   */
  recommender: PropTypes.instanceOf(Immutable.Map).isRequired,
  /**
   * an Immutable Map containing summary about number of recommended items for the given recommender
   *
   *    total_num_targets
   *    num_targets_with_full_recommendations
   *    num_targets_with_half_recommendations
   */
  summaryStats: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default SummaryStatsSection;

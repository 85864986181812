import utilities from './utilities';

/**
 * optlyApp API
 *
 * IMPORTANT:
 * Read through the documentation before making any changes.
 * Learn more about this module at https://github.com/optimizely/optimizely/blob/devel/src/www/frontend/src/js/optly/optly_app_api/README.md
 *
 * @author Derek Hammond
 */
export default {
  getUtilities: utilities,
};

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

export const currentSupportInfo = [
  entityCache,
  supportInfos => {
    if (supportInfos.count() > 1) {
      throw new Error('There should be at most one support info in store.');
    }
    return supportInfos.first();
  },
];

export default {
  ...baseEntityGetters,
  currentSupportInfo,
};

import { toImmutable } from 'optly/immutable';
import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';
import enums from './enums';

const baseEntityGetters = createEntityGetters(definition);

/**
 * Getter that returns default email settings.
 */
export const emailSettings = [
  baseEntityGetters.entityCache,
  emails => {
    if (emails.isEmpty()) {
      return;
    }

    // Since there is only one EmailSetting per user, we can safely
    // just retrieve the first (and only) item in the list.
    const emailSetting = emails.first();

    // used to capture the original settings from the email_settings payload
    // and compared to UI state changes for enabling save/cancel buttons
    let defaultEmailSettings = toImmutable({
      over_limit: true,
      projected_over_limit: true,
      account_unfrozen: true,
      impression_usage: true,
    }).set('id', emailSetting.get('id'));

    Object.entries(enums.CATEGORY_MAP).forEach(([key, val]) => {
      if (
        emailSetting.get('opt_out_category_ids', toImmutable([])).includes(val)
      ) {
        defaultEmailSettings = defaultEmailSettings.set(key, false);
      }
    });

    return defaultEmailSettings;
  },
];

export default {
  ...baseEntityGetters,
  emailSettings,
};

/**
 * Codemirror behaves oddly within a test environment and causes Karma to
 * freeze when there is a placeholder value to render. Emptying the
 * placeholder string solves this and allows tests to run properly.
 *
 * Unfortunately, updating the placeholder text in the test file
 * after the component is mounted does not work reliably.
 */

export const CUSTOM_CSS_PLACEHOLDER = !__TEST__
  ? tr(`/*
 *  Shared CSS is available to all experiments in the campaign. You
 *  can modify existing styles or create brand new classes. Click
 *  the help icon for more information.
 */`)
  : '';

export const CUSTOM_JAVASCRIPT_PLACEHOLDER = !__TEST__
  ? tr(`/*
 *  Shared JavaScript is available to all experiments in the
 *  campaign and executes immediately when a visitor is assigned to
 *  a variation. You can adjust this timing using Optimizely
 *  utilities or JQuery. Click the help icon for more information.
 */`)
  : '';

export default {
  CUSTOM_CSS_PLACEHOLDER,
  CUSTOM_JAVASCRIPT_PLACEHOLDER,
};

/**
 * Higher Order Component: filterable
 *
 * Wraps a given component in a pass-through component that performs
 * data filtering and provides an interface for the wrapper component to update
 * filters
 *
 * Example Usage:
 * Base component:  <DataTable data={someListOfData} /> <- renders some list of data
 *
 * To wrap in filtering functionality
 * const FilterableDataTable = filterable(DataTable)
 *
 * Required props:
 *  - `tableId` a unique identifier for the filterable instance
 *  - `filterItemByString` when a string filter is present used to filter items
 *  - `filterItemByStatus` when a status filter is present used to filter items
 *
 * Optional Props
 *  - `defaultFilters` (ex: { string: '', status: 'RUNNING' })
 *
 * Decorates the wrapped component with the following props:
 *  - `filters` object (ex: { string: '', status: 'ACTIVE' })
 *  - `setFilter` function - pass an object to set the filter.  ex: setFilter({ string: 'foo' })
 *  - `resetFilters` function - reset filters back to defaultValues
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Immutable, { toJS } from 'optly/immutable';

import flux from 'core/flux';
// TODO(jordan): this should probably live in core/modules and optly/utils/filter -> utils/filter
import {
  actions as FilterableTableActions,
  getters as FilterableTableGetters,
} from 'optly/modules/filterable_table';

function filterable(Component) {
  return class extends React.Component {
    static displayName = 'FilterableContainer';

    static propTypes = {
      tableId: PropTypes.string.isRequired,
      data: PropTypes.instanceOf(Immutable.List),
      defaultFilters: PropTypes.object,
      filterItemByString: PropTypes.func,
      filterItemByStatus: PropTypes.func,
    };

    static defaultProps = {
      filterItemByString() {
        // TODO(jordan): should we throw an error here
        return true;
      },
      filterItemByStatus() {
        return true;
      },
    };

    state = {
      filters: {},
    };

    shouldComponentUpdate(nextProps, nextState) {
      return !(nextProps === this.props && _.isEqual(nextState, this.state));
    }

    UNSAFE_componentWillMount() {
      // use a bit of hack here to subscribe to flux data after we make the initial default sort by call
      const { tableId, defaultFilters } = this.props;

      // check if existing filters are present are explicitly set for this table
      const computedFiltersGetter = FilterableTableGetters.computedFilters(
        tableId,
        defaultFilters,
      );

      this.setState({
        filters: flux.evaluateToJS(computedFiltersGetter),
      });

      this.__unwatchFilters = flux.observe(computedFiltersGetter, filters => {
        this.setState({
          filters: toJS(filters),
        });
      });

      // if defaultFilters are passed in, sync with flux store
      if (defaultFilters) {
        setTimeout(() => {
          // sync the current filter state with other filterable components
          FilterableTableActions.setFilterDefault(tableId, defaultFilters);
        }, 0);
      }
    }

    componentWillUnmount() {
      this.__unwatchFilters();
    }

    setFilter = filter => {
      // TODO(jordan): is it better to magically provide these functions via props or
      // should the components just know about this module and the tableId
      FilterableTableActions.setFilter(this.props.tableId, filter);
    };

    resetFilters = () => {
      FilterableTableActions.resetFilter(this.props.tableId);
    };

    __filterItem = (item, filters) => {
      const { string, status } = filters;
      let isValid = true;

      if (typeof string === 'string') {
        isValid = this.props.filterItemByString(item, string);
        if (__DEV__) {
          if (isValid !== false && isValid !== true) {
            throw new Error('filterItemByString must return `true` or `false');
          }
        }
      }

      if (isValid && status) {
        isValid = this.props.filterItemByStatus(item, status);
        if (__DEV__) {
          if (isValid !== false && isValid !== true) {
            throw new Error('filterItemByStatus must return `true` or `false');
          }
        }
      }

      return isValid;
    };

    render() {
      const { data } = this.props;
      const { filters } = this.state;
      const propsToPass = _.omit(this.props, 'data');
      if (data) {
        // make data optional here to use filterable to hook up to filter controls
        propsToPass.data = data.filter(item =>
          this.__filterItem(item, filters),
        );
      }
      return (
        <Component
          setFilter={this.setFilter}
          resetFilters={this.resetFilters}
          filters={filters}
          {...propsToPass}
        />
      );
    }
  };
}

export default filterable;

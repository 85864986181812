import RestApi from 'optly/modules/rest_api';

import { toImmutable } from 'optly/immutable';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

export const {
  save,
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deleteTagGroup = baseEntityActions.delete;

export { deleteTagGroup as delete };

/**
 * Convenience method to fetch the default TagGroup for a given project
 * Note that were force fetching because there is only 1 default TagGroup
 * for the entire project, and as such, its much more important to make
 * sure this default TagGroup is up to date.
 *
 * @param {Number} projectId
 */
export function fetchDefaultTagGroup(projectId) {
  return this.fetchAll(
    {
      project_id: projectId,
      is_default: true,
    },
    { force: true },
  );
}

/**
 * Convenience method to create a default TagGroup.
 *
 * @param {Number} projectId
 * @param {String} type
 */
export function createDefaultTagGroup(projectId, type) {
  return this.save({
    project_id: projectId,
    is_default: true,
    type,
  }).then(data => toImmutable(data));
}

export default {
  ...baseEntityActions,
  createDefaultTagGroup,
  fetchDefaultTagGroup,
};

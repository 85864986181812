export function exampleFn(input) {
  return input.slice(1);
}

/**
 * Services layer pure functions for EmailSetting
 */
export default {
  exampleFn,
};

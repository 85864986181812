/**
 * Filter to format a date string into a human readable date
 * with the time.
 *
 * @author Daniel O'Connor (daniel@optimizely.com)
 */
import formatDate from 'optly/filters/format_date';
import formatTime from 'optly/filters/format_time';

export default dateString =>
  tr('{0} at {1}', formatDate(dateString), formatTime(dateString));

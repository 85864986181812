import _ from 'lodash';

import React from 'react';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import flux from 'core/flux';
import Router from 'core/router';
import UrlHelper from 'optly/services/url_helper';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import CurrentLayerActions from 'bundles/p13n/modules/current_layer/actions';
import FilterableTableEnums from 'optly/modules/filterable_table/enums';
import LayerEnums from 'optly/modules/entity/layer/enums';
import PerformanceTrackingActions from 'optly/modules/performance_tracking/actions';
import CampaignOverviewConstants from 'bundles/p13n/sections/campaign_overview/section_module/constants';
import NavConstants from 'optly/services/navigation';

import PermissionsGetters from 'optly/modules/permissions/getters';
import { Features } from 'optly/utils/enums';

import RoutingFns from '../../routing_fns';
import LayersHomeSection from './index';
import { dashboardTabs } from './pages/layers_dashboard/page_module/constants';

const overview = 'Overview';
const experiments = 'Experiments';
const personalizations = 'Personalizations';

const shouldHandleWebGroups = () =>
  Promise.resolve(
    flux.evaluate(CurrentProjectGetters.isWebProject) &&
      flux.evaluate(PermissionsGetters.canUseMutex),
  );

const routes = [
  {
    match: '/v2/projects/:proj_id',
    metadata: { category: overview },
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PERSONALIZATION,
          NavConstants.LayersTabs.LAYERS,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      LayersHomeSection.pages.layersDashboard.routingSetup,
      RoutingFns.parseQueryFilters(
        FilterableTableEnums.tableIds.P13N_LAYERS,
        LayerEnums.status.ACTIVE,
        _.values(LayerEnums.status),
      ),
      RoutingFns.resetPageable(
        CampaignOverviewConstants.CAMPAIGN_OVERVIEW_PAGEABLE_ID,
      ),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category: overview,
          next,
        }),
      ctx => {
        const projectId = Number(ctx.params.proj_id);

        if (
          !flux.evaluateToJS(PermissionsGetters.canUseExperiments) &&
          flux.evaluateToJS(PermissionsGetters.canUsePersonalization)
        ) {
          Router.redirect(UrlHelper.personalizations(projectId));
        } else {
          PerformanceTrackingActions.setFrontendRenderStartTime();
          RoutingFns.renderMainRegion(
            <LayersHomeSection.pages.layersDashboard.component
              activeTab={dashboardTabs.OVERVIEW}
              componentId="p13n-layers-home"
            />,
          );
          CurrentLayerActions.setCurrentLayerId(null);
        }
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/experiment_list',
    metadata: { category: experiments },
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PERSONALIZATION,
          NavConstants.LayersTabs.LAYERS,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      LayersHomeSection.pages.layersDashboard.routingSetup,
      RoutingFns.parseQueryFilters(
        FilterableTableEnums.tableIds.P13N_LAYERS,
        LayerEnums.status.ACTIVE,
        _.values(LayerEnums.status),
      ),
      RoutingFns.resetPageable(
        CampaignOverviewConstants.CAMPAIGN_OVERVIEW_PAGEABLE_ID,
      ),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category: experiments,
          next,
        }),
      ctx => {
        const projectId = Number(ctx.params.proj_id);
        // redirect if user does not have permission
        if (
          !isFeatureEnabled(Features.M1_P13N) ||
          !(
            flux.evaluateToJS(PermissionsGetters.canUseExperiments) &&
            flux.evaluateToJS(PermissionsGetters.canUsePersonalization)
          )
        ) {
          Router.redirect(UrlHelper.xWebHome(projectId));
        } else {
          PerformanceTrackingActions.setFrontendRenderStartTime();
          RoutingFns.renderMainRegion(
            <LayersHomeSection.pages.layersDashboard.component
              activeTab={dashboardTabs.EXPERIMENTS}
              componentId="p13n-experiments-list"
            />,
          );
          CurrentLayerActions.setCurrentLayerId(null);
        }
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/personalizations',
    metadata: { category: personalizations },
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PERSONALIZATION,
          NavConstants.LayersTabs.LAYERS,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      LayersHomeSection.pages.layersDashboard.routingSetup,
      RoutingFns.parseQueryFilters(
        FilterableTableEnums.tableIds.P13N_LAYERS,
        LayerEnums.status.ACTIVE,
        _.values(LayerEnums.status),
      ),
      RoutingFns.resetPageable(
        CampaignOverviewConstants.CAMPAIGN_OVERVIEW_PAGEABLE_ID,
      ),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category: personalizations,
          next,
        }),
      ctx => {
        const projectId = Number(ctx.params.proj_id);
        // redirect if user does not have permission
        if (
          !isFeatureEnabled(Features.M1_P13N) ||
          !flux.evaluateToJS(PermissionsGetters.canUsePersonalization)
        ) {
          Router.redirect(UrlHelper.xWebHome(projectId));
        } else {
          PerformanceTrackingActions.setFrontendRenderStartTime();
          RoutingFns.renderMainRegion(
            <LayersHomeSection.pages.layersDashboard.component
              activeTab={dashboardTabs.PERSONALIZATIONS}
              componentId="p13n-personalizations-list"
            />,
          );
          CurrentLayerActions.setCurrentLayerId(null);
        }
      },
    ],
  },

  /*
   * Temporary redirect to support old url
   */
  {
    match: '/v2/projects/:proj_id/campaigns',
    handle: [
      ctx => {
        const projectId = Number(ctx.params.proj_id);
        Router.redirect(UrlHelper.xWebHome(projectId));
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/groups',
    metadata: { name: 'Exclusion Groups', category: experiments },
    shouldHandle: shouldHandleWebGroups,
    handle: [
      [...RoutingFns.standardNavHandlers(), RoutingFns.parseProjectId],
      LayersHomeSection.pages.groupsDashboard.routingSetup,
      (ctx, next) =>
        RoutingFns.setPageTitle({
          name: 'Exclusion Groups',
          next,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <LayersHomeSection.pages.groupsDashboard.component />,
        ),
    ],
  },
];

export default routes;

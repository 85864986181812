/**
 * Getters for optimizelyChampagne
 */
import { actions as jsSDKLabActions } from '@optimizely/js-sdk-lab';

const CHAMPAGNE_STORE_KEY = 'optimizelyChampagne';

// For a given feature, retrieve the userId weve stored for it.
export const userIdForFeature = feature => [
  CHAMPAGNE_STORE_KEY,
  'featureToUserId',
  feature,
];

const ReactSDKUserState = [CHAMPAGNE_STORE_KEY, 'reactSDKUserState'];

// Getter to proxy isFeatureEnabled based on state changes to the ReactSDK's userInfo
export const isFeatureEnabled = (...args) => [
  ReactSDKUserState,
  () => jsSDKLabActions.isFeatureEnabled(...args),
];

// Getter to proxy getFeatureVariable based on state changes to the ReactSDK's userInfo
export const getFeatureVariable = (...args) => [
  ReactSDKUserState,
  () => jsSDKLabActions.getFeatureVariable(...args),
];

export default {
  isFeatureEnabled,
  getFeatureVariable,
  userIdForFeature,
};

import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Label } from 'optimizely-oui';

import ui from 'core/ui';

import CurrentProjectGetters from 'optly/modules/current_project/getters';

// components
import Immutable from 'optly/immutable';
import FeaturePicker from 'bundles/p13n/components/feature_picker';

const FeatureSelector = props => {
  const {
    experiment,
    features,
    handleFeatureSelected,
    handleFeatureUnselected,
    showFeatureValidationError,
    showNoFeatureSelectedError,
  } = props;

  const onHandleSelectFeature = feature => {
    if (experiment.get('feature_flag_id') === feature.get('id')) {
      return;
    }

    handleFeatureSelected(feature);
  };

  const onHandleUnselectFeature = () => {
    handleFeatureUnselected();
  };

  const experimentFeatureId = experiment.get('feature_flag_id');
  // Include an archived feature in the selectable features only if it is already attached
  // to this experiment.
  const filteredFeatures = features.filter(
    feature =>
      !feature.get('archived') ||
      (isNumber(experimentFeatureId) &&
        feature.get('id') === experimentFeatureId),
  );

  return (
    <div data-test-section="feature-experiment-dialog-feature-flag-selector">
      <Label isRequired={true}>Feature</Label>
      <FeaturePicker
        features={filteredFeatures}
        onSelectFeature={onHandleSelectFeature}
        onUnselectFeature={onHandleUnselectFeature}
        selectedFeatureId={experiment.get('feature_flag_id')}
      />
      {showFeatureValidationError && (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="feature-selector-invalid-error">
          Feature is already in use by another running experiment in this
          project.
        </div>
      )}
      {showNoFeatureSelectedError && (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="no-feature-selected-error">
          Must select a feature for this experiment.
        </div>
      )}
      <span className="lego-form-note push--top">
        By selecting a feature, this experiment will be activated when the
        feature is accessed in the SDK.
      </span>
    </div>
  );
};

FeatureSelector.propTypes = {
  experiment: PropTypes.instanceOf(Immutable.Map),
  features: PropTypes.instanceOf(Immutable.List).isRequired,
  handleFeatureSelected: PropTypes.func.isRequired,
  handleFeatureUnselected: PropTypes.func.isRequired,
  showFeatureValidationError: PropTypes.bool,
  showNoFeatureSelectedError: PropTypes.bool,
};

export default ui.connectGetters(FeatureSelector, {
  features: CurrentProjectGetters.features,
});

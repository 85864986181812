import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import { some, cloneDeep, flatten } from 'lodash';

import { feature } from '@optimizely/js-sdk-lab/src/decorators';
import { Button, Sheet } from 'optimizely-oui';

import Immutable from 'optly/immutable';
import flux from 'core/flux';

import ui from 'core/ui';
import View from 'optly/modules/entity/view';
import Events from 'optly/services/events';

import LoadingOverlay from 'react_components/loading_overlay';

import Header from './subcomponents/header';
import PageName from './subcomponents/page_name';
import SmartPageConfig from '../targeting/smart_page_config';
import Footer from './subcomponents/footer';

const LOADING_KEY = 'createStandaloneOrSaveWebViewEvent';

@feature('metrics_usability')
class ConfigureViewSmart extends React.Component {
  constructor(props) {
    super(props);

    this.originalView =
      flux.evaluate(View.getters.byId(props.viewConfiguration.get('id'))) ||
      props.viewConfiguration;
  }

  static propTypes = {
    viewConfiguration: PropTypes.object,
    isStandAlone: PropTypes.bool,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
  };

  state = {
    viewConfiguration: this.props.viewConfiguration,
    errors: {
      name: null,
      edit_url: null,
      api_name: null,
      description: null,
      conditions: [],
    },
    isSavingViewEvent: false,
  };

  saveViewConfiguration = () => {
    const { viewConfiguration } = this.state;
    const { isStandAlone } = this.props;

    const errors = View.fns.validateSmartView(viewConfiguration, true);

    const flatErrors = cloneDeep(errors);
    flatErrors.conditions = flatten(flatErrors.conditions).join('');
    if (some(flatErrors)) {
      this.setState({
        errors,
      });
      return Promise.reject();
    }

    const { onSave } = this.props;

    this.setState({
      isSavingViewEvent: true,
    });
    const viewConfigurationJS = viewConfiguration.toJS();
    if (!this.metrics_usability) {
      viewConfigurationJS.conditions = View.fns.splitMultilineConditions(
        viewConfiguration,
      );
    }
    const saveViewEvent = View.actions
      .save(viewConfigurationJS)
      .then(view => {
        if (onSave) {
          onSave(view);
        }
        ui.showNotification({
          message: tr('Page saved'),
          type: 'success',
        });
        this.setState({
          isSavingViewEvent: false,
        });
        Events.trackMarketoEvent('x_component', 'setup', 'view_conditions');
      })
      .catch(() =>
        this.setState({
          isSavingViewEvent: false,
        }),
      );
    // Set the loading region to the entire Web event creation component
    // if the View is new and not in standalone mode (i.e. no View id)
    if (!viewConfiguration.get('id') && !isStandAlone) {
      ui.loadingWhen('createWebEventFromDashboard', saveViewEvent);
      return saveViewEvent;
    }
    // Otherwise set the loading region to the just the View config component
    // if the View exists or it was created in standalone mode
    ui.loadingWhen(LOADING_KEY, saveViewEvent);
    return saveViewEvent;
  };

  destroy = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    ui.hideDialog();
  };

  // component methods
  saveAndCloseDialog = () => {
    this.saveViewConfiguration().then(ui.hideDialog, () => {
      /* swallow error */
    });
  };

  cancelAndCloseDialog = () => {
    this.destroy();
  };

  updateViewConfiguration = (fieldName, value, callback) => {
    this.setState((prevState, props) => {
      const newState = {
        viewConfiguration: prevState.viewConfiguration.set(fieldName, value),
        errors: prevState.errors,
      };

      // Whenever a field is updated, clear out any errors associated with the field
      // as they should only be calculated on save
      // Except for conditions - the condition_builder clears out it's own errors using updateConditionErrors
      if (
        Object.prototype.hasOwnProperty.call(prevState.errors, fieldName) &&
        fieldName !== 'conditions'
      ) {
        newState.errors[fieldName] = null;
      }

      return newState;
    }, callback);
  };

  updateConditionErrors = conditionErrors => {
    this.setState({
      errors: {
        conditions: conditionErrors,
      },
    });
  };

  hasUnsavedChanges = () =>
    !Immutable.is(this.originalView, this.state.viewConfiguration);

  render = () => {
    const { errors, viewConfiguration, isSavingViewEvent } = this.state;

    const { isStandAlone = true } = this.props;

    const isNewPage = !viewConfiguration.get('id');

    // determines if we set a max width, or let the "caller" set the max width
    const classes = classnames({
      'soft-quad--bottom': true,
      'reading-column': isStandAlone,
    });

    return this.metrics_usability ? (
      <Sheet
        testSection="configure-view-smart"
        title={isStandAlone && `${isNewPage ? 'New' : 'Edit'} Page`}
        onClose={this.cancelAndCloseDialog}
        footerButtonList={[
          <Button
            style="plain"
            key="cancel"
            onClick={this.cancelAndCloseDialog}
            testSection="configure-view-smart-cancel">
            Cancel
          </Button>,
          <Button
            style="highlight"
            key="save"
            isDisabled={!this.hasUnsavedChanges() || isSavingViewEvent}
            onClick={this.saveAndCloseDialog}
            testSection="configure-view-smart-save">
            {`${isNewPage ? 'Create' : 'Save'} Page`}
          </Button>,
        ]}>
        <LoadingOverlay loadingId={LOADING_KEY}>
          <div className="flex--1" data-test-section="configure-view-smart">
            <div className={classes}>
              <PageName
                pageName={viewConfiguration.get('name')}
                onUpdate={this.updateViewConfiguration}
                error={errors.name}
              />
              <SmartPageConfig
                viewConfiguration={viewConfiguration}
                onUpdate={this.updateViewConfiguration}
                updateConditionErrors={this.updateConditionErrors}
                errors={errors}
              />
            </div>
          </div>
        </LoadingOverlay>
      </Sheet>
    ) : (
      <Sheet
        testSection="configure-view-smart"
        title={isStandAlone && `${isNewPage ? 'New' : 'Edit'} Page`}
        onClose={this.cancelAndCloseDialog}
        footerButtonList={[
          <Button
            style="plain"
            key="cancel"
            onClick={this.cancelAndCloseDialog}
            testSection="configure-view-smart-cancel">
            Cancel
          </Button>,
          <Button
            style="highlight"
            key="save"
            isDisabled={!this.hasUnsavedChanges() || isSavingViewEvent}
            onClick={this.saveAndCloseDialog}
            testSection="configure-view-smart-save">
            {`${isNewPage ? 'Create' : 'Save'} Page`}
          </Button>,
        ]}>
        <LoadingOverlay loadingId={LOADING_KEY}>
          <div>
            <PageName
              pageName={viewConfiguration.get('name')}
              onUpdate={this.updateViewConfiguration}
              error={errors.name}
            />
            <SmartPageConfig
              viewConfiguration={viewConfiguration}
              onUpdate={this.updateViewConfiguration}
              updateConditionErrors={this.updateConditionErrors}
              errors={errors}
            />
          </div>
        </LoadingOverlay>
      </Sheet>
    );
  };
}

export { ConfigureViewSmart };
export default ConfigureViewSmart;

import constants from 'optly/modules/rest_api/constants';
import errorService from 'optly/services/error';
import { logout } from 'optly/modules/auth/logout';
import SentryActions from 'optly/modules/sentry/actions';
import { setLocation } from 'optly/location';

import { showNotification } from 'core/ui/methods/notifications';
import showError from 'core/ui/methods/show_error';

/**
 * Handles API errors
 */
export function handleAPIRequestFailure(jqXHR, textStatus, entityName) {
  // Extract error details from the jqXHR object
  const {
    responseText,
    originalRequestOptions,
    status: httpStatusCode,
  } = jqXHR;
  const httpRequestMethod =
    originalRequestOptions && originalRequestOptions.type;
  const endpointUrl = originalRequestOptions && originalRequestOptions.url;
  const errorData = errorService.getAjaxErrorData(jqXHR, textStatus);
  const didRequestComplete = httpStatusCode !== 0; // HTTP 0 indicates connection failure

  // If the user is unauthorized, logout and redirect to the auth page
  if (httpStatusCode === 401) {
    logout();
    return;
  }

  // Attempt to parse the API response body as JSON and extract error details
  let errorDetails;
  let errorCodes;
  let jsonParserError;
  try {
    const responseParsed = JSON.parse(responseText);

    if (responseParsed.error_details) {
      errorDetails = responseParsed.error_details;
      errorCodes = responseParsed.error_details.map(
        detail => detail.error_code,
      );
    }
  } catch (e) {
    e.name = 'APIResponseParserError';
    jsonParserError = e;
  }

  /**
   * If the user's CSRF session has expired, redirect to the auth page
   * Error codes defined in:
   * https://github.com/optimizely/optimizely/blob/devel/src/www/services/exceptions/error_codes.py
   */
  if (
    errorCodes &&
    errorCodes.includes(constants.apiErrorCodes.INVALID_CSRF_TOKEN)
  ) {
    const message = tr('This session has expired. Redirecting to signin page.');
    showNotification({
      message,
    });
    setLocation('/signin');
    return;
  }

  /**
   * Use a set timeout so that the caller's .fail is called first
   * This allows callers to provide custom error handling other than
   * the default error handling below.
   */
  setTimeout(() => {
    const isLiveCommitTagNotFoundError =
      httpStatusCode === 404 && entityName === 'live';
    // return immediately if error already handled or live commit not found error
    if (jqXHR.optlyErrorHandled || isLiveCommitTagNotFoundError) {
      return;
    }

    if (!didRequestComplete) {
      // Show a notification if the user is experiencing connection issues
      showNotification({
        message: tr(
          `The request seems to be taking a while. Please refresh your browser
          or check your Internet connection.`,
        ),
        type: 'error',
        errorData,
        identifier: 'disconnection_error',
        doNotShowDuplicates: true,
      });
    } else {
      // Otherwise, show error dialog with the API error message
      showError(errorData);
    }

    /**
     * PLEASE NOTE: Direct use of the Sentry SDK is discouraged except in
     * specific cases. To dispatch events to Sentry, please use the Sentry SDK
     * wrapper at optly/modules/sentry.
     * -----------------------------------------------------------------------
     * Log the exception to Sentry with API-specific context information
     *
     * Fingerprint (used to group API errors in Sentry dashboard):
     * - HTTP status code
     * - Exception Name
     *
     * Tags (indexed, general information that can be used to group errors):
     * - is_api_error: did the error occur during an API call?
     * - api_response_status_code: which HTTP status code was received?
     * - api_request_method: which HTTP method was used?
     * - api_request_entity: which entity was being requested when error was received?
     * - api_error_id: Unique ID for error
     *
     * Extra (non-indexed, specific to an instance of an error):
     * - api_request_endpoint: which endpoint was hit when the error occurred?
     * - api_response_message: what was the primary message of the error?
     * - api_error_details: what were the details/secondary messages of the error?
     */
    SentryActions.withScope(scope => {
      scope.setTag('is_api_error', true);
      scope.setTag('api_response_status_code', httpStatusCode);
      scope.setTag('api_request_method', httpRequestMethod);
      scope.setTag('api_request_entity', entityName);
      scope.setTag('api_error_id', errorData.errorId);

      scope.setExtra('api_request_endpoint', endpointUrl);

      let errorToSend;
      if (errorDetails) {
        scope.setExtra('api_error_details', errorDetails);
      }
      if (jsonParserError) {
        // If API response body could not be parsed, send APIResponseParserError
        errorToSend = jsonParserError;
        scope.setExtra('api_response_body', `"${responseText}"`);
      } else {
        // Otherwise, send APIRequestError/APIConnectionError
        const errorMessage = errorData.message || responseText;
        errorToSend = new Error(errorMessage);
        errorToSend.name = didRequestComplete
          ? `APIRequestError (HTTP ${httpStatusCode})`
          : 'APIConnectionError';
        if (errorMessage) {
          scope.setExtra('api_response_message', errorMessage);
        }
      }

      scope.setFingerprint([httpStatusCode, errorToSend.name]);

      SentryActions.captureException(errorToSend);
    });
  }, 25); // 25 millisecond timeout here required for jquery v3 compatibility
}

export default {
  handleAPIRequestFailure,
};

import React from 'react';
import PropTypes from 'prop-types';
import { feature } from '@optimizely/js-sdk-lab/src/decorators';
import _ from 'lodash';
import {
  Button,
  EmptyDashboard,
  Input,
  PaginationControls,
  SelectDropdown,
  Table,
} from 'optimizely-oui';

import LoadingOverlay from 'react_components/loading_overlay';

import Immutable from 'optly/immutable';
import { showSupportDialog } from 'optly/modules/support/actions';

import EntitySearchConstants from 'bundles/p13n/components/entity_search/component_module/constants';
import EmptyResults from 'bundles/p13n/components/entity_dashboard/empty_results';

import { enums as LayerEnums } from 'optly/modules/entity/layer';
import { actions as LayerExperimentActions } from 'optly/modules/entity/layer_experiment';

const AUTOMATIC_SEARCH_DEBOUNCE_TIMEOUT_MS = 750;

@feature('user_friendly_names')
class EntitySearchTable extends React.Component {
  static displayName = 'EntitySearchTable';

  static propTypes = {
    changeFilters: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    changeQuery: PropTypes.func.isRequired,
    changeSort: PropTypes.func.isRequired,
    changeTypeFilter: PropTypes.func.isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentSearchOptions: PropTypes.shape({}).isRequired,
    data: PropTypes.instanceOf(Immutable.List).isRequired,
    defaultFilterValue: PropTypes.any.isRequired,
    defaultTypeFilterValue: PropTypes.string.isRequired,
    entityPlural: PropTypes.string.isRequired,
    filterOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any,
      }),
    ).isRequired,
    filterToArchivedState: PropTypes.shape({}).isRequired,
    filterToStatuses: PropTypes.shape({}).isRequired,
    filterToTypes: PropTypes.shape({}).isRequired,
    initialFilterValue: PropTypes.any.isRequired,
    initialTypeFilterValue: PropTypes.string.isRequired,
    isTableLoading: PropTypes.bool.isRequired,
    onFirstPageLoad: PropTypes.func,
    renderCreateDropdown: PropTypes.func.isRequired,
    renderEmptyDashboard: PropTypes.func.isRequired,
    renderTableHeader: PropTypes.func.isRequired,
    renderTableRow: PropTypes.func.isRequired,
    submitQuery: PropTypes.func.isRequired,
    tableInfo: PropTypes.node,
    totalPages: PropTypes.number.isRequired,
    typeFilterOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any,
      }),
    ).isRequired,
    useCustomTable: PropTypes.bool,
  };

  static defaultProps = {
    onFirstPageLoad: () => {},
    tableInfo: null,
    useCustomTable: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchInputValue: props.currentSearchOptions.query,
      searchStatusFilterValue:
        props.initialFilterValue || props.defaultFilterValue,
      typeFilterValue:
        props.initialTypeFilterValue || props.defaultTypeFilterValue,
      didError: false,
      didInitialize: false,
    };
  }

  shouldFetchPersonalizationLayerExperiments = true;

  tableScrollContainerRef = React.createRef();

  componentDidMount() {
    const { onFirstPageLoad } = this.props;
    this.submitQueryWithErrorHandling().then(() => {
      this.setState({ didInitialize: true });
      return onFirstPageLoad();
    });
  }

  componentDidUpdate = ({ data: previousData }) => {
    const { data, isTableLoading, useCustomTable } = this.props;

    if (
      useCustomTable &&
      this.shouldFetchPersonalizationLayerExperiments &&
      !isTableLoading &&
      data !== previousData &&
      data.size > 0
    ) {
      const personalizationLayers = data.filter(
        dashboardLayer =>
          dashboardLayer.get('layer') &&
          dashboardLayer.get('type') === LayerEnums.type.PERSONALIZATION,
      );

      personalizationLayers.forEach(dashboardLayer =>
        LayerExperimentActions.intelligentFetchAll(
          dashboardLayer.get('layer'),
          false,
        ).then(() => {
          this.shouldFetchPersonalizationLayerExperiments = false;
          this.forceUpdate();
        }),
      );
    }
  };

  submitQueryWithErrorHandling = (...args) => {
    const { submitQuery } = this.props;
    return submitQuery(...args).catch(this.handleSearchError);
  };

  changeQueryWithErrorHandling = (...args) => {
    const { changeQuery } = this.props;
    return changeQuery(...args).catch(this.handleSearchError);
  };

  debouncedChangeQuery = _.debounce(
    this.changeQueryWithErrorHandling,
    AUTOMATIC_SEARCH_DEBOUNCE_TIMEOUT_MS,
  );

  handleSearchError = () => {
    this.setState({
      didError: true,
    });
  };

  onSearchInputChange = e => {
    const searchInputValue = e.target.value;
    this.setState(
      {
        searchInputValue,
      },
      () => {
        this.debouncedChangeQuery(searchInputValue);
      },
    );
  };

  onSearchInputKeyDown = e => {
    if (e.key === 'Enter') {
      this.onSearchInputChange(e);
    }
  };

  onStatusFilterChange = searchStatusFilterValue => {
    const {
      changeFilters,
      filterToStatuses,
      filterToArchivedState,
    } = this.props;
    const status = filterToStatuses[searchStatusFilterValue];
    const archived = filterToArchivedState[searchStatusFilterValue];
    this.setState(
      {
        searchStatusFilterValue,
      },
      () => {
        changeFilters(status, archived).catch(this.handleSearchError);
      },
    );
  };

  onTypeFilterChange = typeFilterValue => {
    const { changeTypeFilter, filterToTypes } = this.props;
    const type = filterToTypes[typeFilterValue];
    this.setState(
      {
        typeFilterValue,
      },
      () => {
        changeTypeFilter(type).catch(this.handleSearchError);
      },
    );
  };

  onSearchPageChange = newPage => {
    const { changePage } = this.props;
    changePage(newPage)
      .then(results => {
        if (
          this.tableScrollContainerRef &&
          this.tableScrollContainerRef.current
        ) {
          this.tableScrollContainerRef.current.scrollTo(0, 0);
        }
        return results;
      })
      .catch(this.handleSearchError);
  };

  toggleSortForColumn = columnName => {
    const { currentSearchOptions, changeSort } = this.props;

    // If switching columns, use the default sort order for the column
    let nextSortOrder =
      EntitySearchConstants.searchDefaultSortOrder[columnName] ||
      EntitySearchConstants.searchSortOrders.ASC;
    const sortToggleMap = {
      [EntitySearchConstants.searchSortOrders.ASC]:
        EntitySearchConstants.searchSortOrders.DESC,
      [EntitySearchConstants.searchSortOrders.DESC]:
        EntitySearchConstants.searchSortOrders.ASC,
    };
    // If the user isn't switching sort columns, toggle the sort direction
    if (currentSearchOptions.sort === columnName) {
      nextSortOrder = sortToggleMap[currentSearchOptions.order];
    }
    changeSort(columnName, nextSortOrder).catch(this.handleSearchError);
  };

  resetFilters = () => {
    const { defaultFilterValue, defaultTypeFilterValue } = this.props;
    this.setState(
      {
        searchInputValue: '',
        searchStatusFilterValue: defaultFilterValue,
        typeFilterValue: defaultTypeFilterValue,
      },
      () => {
        this.submitQueryWithErrorHandling();
      },
    );
  };

  renderStatusFilterDropdown = () => {
    const { filterOptions } = this.props;
    const { searchStatusFilterValue, didInitialize } = this.state;

    const selectedOption = filterOptions.find(
      option => option.value === searchStatusFilterValue,
    );
    return (
      <SelectDropdown
        buttonContent={{
          label: 'Status',
          content: selectedOption.label,
        }}
        items={filterOptions}
        value={searchStatusFilterValue}
        buttonStyle="plain"
        onChange={this.onStatusFilterChange}
        isDisabled={!didInitialize}
        testSection="filter-table-dropdown"
      />
    );
  };

  renderTypeFilterDropdown = () => {
    const { typeFilterOptions } = this.props;
    const { typeFilterValue, didInitialize } = this.state;

    // Only has one option in addition to 'All'
    if (!typeFilterOptions || typeFilterOptions.length <= 2) {
      return;
    }

    const selectedOption = typeFilterOptions.find(
      option => option.value === typeFilterValue,
    );
    return (
      <SelectDropdown
        buttonContent={{
          label: 'Type',
          content: selectedOption.label,
        }}
        items={typeFilterOptions}
        value={typeFilterValue}
        buttonStyle="plain"
        onChange={this.onTypeFilterChange}
        isDisabled={!didInitialize}
        testSection="type-filter-table-dropdown"
      />
    );
  };

  renderPaginationControls = () => {
    const { totalPages, currentSearchOptions } = this.props;

    if (totalPages < 2) {
      return;
    }

    return (
      <div className="soft-double--top soft--bottom border--top">
        <PaginationControls
          currentPage={currentSearchOptions.page || 1}
          totalPages={totalPages}
          goToPage={this.onSearchPageChange}
        />
      </div>
    );
  };

  renderErrorMessage = () => (
    <EmptyDashboard
      button={
        <Button onClick={() => showSupportDialog()} style="highlight">
          Optimizely Help Center
        </Button>
      }
      description={
        <div className="reading-column flush--top">
          Don't worry, most issues are minor. Please refresh the page or visit
          our Help Center if the issue persists.
        </div>
      }
      headline="Something went wrong"
      showButtonBelow={true}
      testSection="entity-search-table-error"
    />
  );

  renderTableHeader = () => {
    const { currentSearchOptions, renderTableHeader } = this.props;
    return renderTableHeader(
      currentSearchOptions.sort,
      currentSearchOptions.order,
      this.toggleSortForColumn,
    );
  };

  renderTableRow = rowData => {
    const { renderTableRow } = this.props;
    return renderTableRow(rowData);
  };

  render() {
    const {
      data,
      currentSearchOptions,
      isTableLoading,
      renderCreateDropdown,
      renderEmptyDashboard,
      defaultFilterValue,
      entityPlural,
      tableInfo,
      defaultTypeFilterValue,
    } = this.props;
    const {
      searchStatusFilterValue,
      searchInputValue,
      typeFilterValue,
      didError,
      didInitialize,
    } = this.state;

    if (didError) {
      return this.renderErrorMessage();
    }

    const shouldRenderEmptyDashboard =
      data.size === 0 &&
      currentSearchOptions.query === '' &&
      Number(currentSearchOptions.page) === 1 &&
      !isTableLoading &&
      searchStatusFilterValue === defaultFilterValue &&
      typeFilterValue === defaultTypeFilterValue;

    return (
      <>
        <div
          className={
            shouldRenderEmptyDashboard ? 'flex flex--1 flex--column' : 'd-none'
          }>
          {renderEmptyDashboard({
            onStatusFilterChange: this.onStatusFilterChange.bind(this),
            shouldRenderEmptyDashboard,
          })}
        </div>
        {!shouldRenderEmptyDashboard ? (
          <div className="flex flex--1 flex--column">
            <div className="flex push-double--ends push-quad--sides">
              <div className="flex flex--1">
                <div className="width--300 push--right">
                  <Input
                    defaultValue={searchInputValue}
                    onChange={this.onSearchInputChange}
                    onKeyDown={this.onSearchInputKeyDown}
                    isFilter={true}
                    isDisabled={!didInitialize}
                    value={searchInputValue}
                    type="text"
                    placeholder={
                      this.user_friendly_names
                        ? 'Filter by name, key, or description'
                        : 'Filter by key or description'
                    }
                    testSection="entity-search-box"
                  />
                </div>
                {this.renderStatusFilterDropdown()}
                {this.renderTypeFilterDropdown()}
              </div>
              {renderCreateDropdown()}
            </div>
            <div className="position--relative flex flex--1 flex--column soft-double--sides soft-double--bottom overflow-y--auto">
              <div
                className="flex--1 overflow-x--auto overflow-y--auto"
                ref={this.tableScrollContainerRef}>
                <LoadingOverlay isLoading={isTableLoading}>
                  {data.size === 0 && !isTableLoading ? (
                    <EmptyResults
                      entityPlural={entityPlural}
                      testSection="empty-search-results"
                      resetFilters={this.resetFilters}
                    />
                  ) : (
                    <>
                      {tableInfo}
                      <Table
                        tableLayoutAlgorithm="auto"
                        density="loose"
                        style="rule-no-bottom-border">
                        <Table.THead>{this.renderTableHeader()}</Table.THead>
                        <Table.TBody>
                          {data.map(this.renderTableRow)}
                        </Table.TBody>
                      </Table>
                    </>
                  )}
                </LoadingOverlay>
              </div>
              {this.renderPaginationControls()}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default EntitySearchTable;

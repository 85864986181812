/**
 * Services layer pure functions for the events
 */

import _ from 'lodash';
import keyMirror from 'optly/utils/key_mirror';
import { toImmutable } from 'optly/immutable';
import LayerExperimentFns from 'optly/modules/entity/layer_experiment/fns';
import errorService from 'optly/services/error';
import mergeAndDiff from 'optly/utils/merge_and_diff';

export const ATTRIBUTE_PROPERTIES_KEYS = keyMirror({
  class: null,
  hide: null,
  href: null,
  html: null,
  remove: null,
  src: null,
  style: null,
  text: null,
});

function addLayerExperimentKeys(revisions) {
  if (revisions.layer_experiment) {
    return LayerExperimentFns.getFieldNamesFromWidgetChanges(
      revisions.layer_experiment,
    );
  }
  return [];
}

function extractExtraDiffKeys(before, after) {
  const extraKeys = addLayerExperimentKeys(before.revisions).concat(
    addLayerExperimentKeys(after.revisions),
  );
  return _.uniq(extraKeys);
}

export function diff(before, after) {
  const idKeys = ['audience_id', 'variation_id', 'view_id', 'id'];
  let diffKeys = [
    'value', // Used by custom_code and custom_css
    'view_ids',
  ];
  // Add all possible attribute properties keys
  diffKeys = diffKeys.concat(_.keys(ATTRIBUTE_PROPERTIES_KEYS));
  // Add any dynamic diff keys, derived from the commit data
  diffKeys = diffKeys.concat(extractExtraDiffKeys(before, after));
  try {
    return mergeAndDiff.mergeAndDiff(before, after, idKeys, diffKeys, true);
  } catch (e) {
    errorService.logToServer('MergeAndDiffError', {
      data: {
        before,
        after,
        idKeys,
        diffKeys,
      },
      errorLocation: 'merge_and_diff',
      errorMessage: e,
      stackTrace: e.stack,
    });
    // Set as the default for the diffs
    return null;
  }
}

/**
 * Helper function to extract the experiment objects from the commit
 * @param {Immutable.Map}liveCommit
 * @returns {Object}
 */
export const getLiveCommitLayerExperimentsMap = liveCommit => {
  const liveCommitLayerExperiments =
    liveCommit.getIn(['revisions', 'layer_experiment']) || toImmutable([]);
  // We need to figure out live/changed status for each experiment. We'll use
  // this, which maps live commit experiment IDs to full live experiment objects
  return liveCommitLayerExperiments.reduce(
    (byExperimentMap, liveExperiment) => {
      byExperimentMap[liveExperiment.get('id')] = liveExperiment;
      return byExperimentMap;
    },
    {},
  );
};

export default {
  getLiveCommitLayerExperimentsMap,
  diff,
};

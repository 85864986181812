import React from 'react';

import flux from 'core/flux';
import Router from 'core/router';
import BundleSplitHelper from 'optly/utils/bundle_split_helper';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import LayerExperimentGetters from 'optly/modules/entity/layer_experiment/getters';
import NavConstants from 'optly/services/navigation';

import RoutingFns from 'bundles/p13n/routing_fns';
import UrlHelper from 'optly/services/url_helper';

import { withSidebarAndManagerChangeHistory } from 'bundles/p13n/components/change_history';

import {
  fetchRequiredDataForFullStackManager,
  fetchRequiredDataForManagerChangeHistory,
} from './routing_fns';

import Sidebar from './components/sidebar';

let ManagerFullstackSection;
let ManagerFullstackModule;

/*
 * Routing function that ensures the Oasis dynamic bundle has been fetched
 */
const EnsureOasisBundle = (ctx, next) => {
  BundleSplitHelper.getOasisBundleModules()
    .then(modules => {
      ({ ManagerFullstackSection, ManagerFullstackModule } = modules);
    })
    .then(next);
};

const shouldHandleFullstack = () =>
  Promise.resolve(flux.evaluate(CurrentProjectGetters.isFullStackProject));

function setPageTitle({ next, name, params }) {
  next();
  let primaryName = '';
  if (params.experiment_id) {
    const currentExperiment = flux.evaluate(
      LayerExperimentGetters.byId(Number(params.experiment_id)),
    );
    primaryName = !!currentExperiment && currentExperiment.get('name');
  }
  RoutingFns.setPageTitle({
    name,
    primaryName,
  });
}

/*
 * Routes for the Oasis Exp Manager section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id',
    shouldHandle: shouldHandleFullstack,
    handle: [
      ctx =>
        Router.redirect(
          UrlHelper.fullStackManagerVariations(
            ctx.params.proj_id,
            ctx.params.experiment_id,
          ),
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/variations',
    shouldHandle: shouldHandleFullstack,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.OASIS,
        ),
        RoutingFns.parseProjectId,
        EnsureOasisBundle,
      ],
      (ctx, next) =>
        ManagerFullstackSection.pages.variations.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Variations',
          params,
        }),
      () => {
        ManagerFullstackModule.actions.setCurrentlyEditingExperimentTab(
          ManagerFullstackModule.constants.Tabs.VARIATIONS,
        );
        RoutingFns.renderMainRegion(
          <ManagerFullstackSection.pages.variations.component />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/audiences',
    shouldHandle: shouldHandleFullstack,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.OASIS,
        ),
        RoutingFns.parseProjectId,
        EnsureOasisBundle,
      ],
      (ctx, next) =>
        ManagerFullstackSection.pages.audiences.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Audiences',
          params,
        }),
      () => {
        ManagerFullstackModule.actions.setCurrentlyEditingExperimentTab(
          ManagerFullstackModule.constants.Tabs.AUDIENCES,
        );
        RoutingFns.renderMainRegion(
          <ManagerFullstackSection.pages.audiences.component />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/metrics',
    shouldHandle: shouldHandleFullstack,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.OASIS,
        ),
        RoutingFns.parseProjectId,
        EnsureOasisBundle,
      ],
      (ctx, next) =>
        ManagerFullstackSection.pages.metrics.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Metrics',
          params,
        }),
      () => {
        ManagerFullstackModule.actions.setCurrentlyEditingExperimentTab(
          ManagerFullstackModule.constants.Tabs.METRICS,
        );
        RoutingFns.renderMainRegion(
          <ManagerFullstackSection.pages.metrics.component />,
        );
      },
    ],
  },

  {
    match:
      '/v2/projects/:proj_id/experiments/:experiment_id/traffic_allocation',
    shouldHandle: shouldHandleFullstack,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.OASIS,
        ),
        RoutingFns.parseProjectId,
        EnsureOasisBundle,
      ],
      (ctx, next) =>
        ManagerFullstackSection.pages.trafficAllocation.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Traffic Allocation',
          params,
        }),
      () => {
        ManagerFullstackModule.actions.setCurrentlyEditingExperimentTab(
          ManagerFullstackModule.constants.Tabs.TRAFFIC_ALLOCATION,
        );
        RoutingFns.renderMainRegion(
          <ManagerFullstackSection.pages.trafficAllocation.component />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/whitelist',
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.OASIS,
        ),
        RoutingFns.parseProjectId,
        EnsureOasisBundle,
      ],
      (ctx, next) =>
        ManagerFullstackSection.pages.whitelist.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Whitelist',
          params,
        }),
      () => {
        ManagerFullstackModule.actions.setCurrentlyEditingExperimentTab(
          ManagerFullstackModule.constants.Tabs.WHITELIST,
        );
        RoutingFns.renderMainRegion(
          <ManagerFullstackSection.pages.whitelist.component />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/api_names',
    shouldHandle: shouldHandleFullstack,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.OASIS,
        ),
        RoutingFns.parseProjectId,
        EnsureOasisBundle,
      ],
      (ctx, next) =>
        ManagerFullstackSection.pages.apiNames.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'API Names',
          params,
        }),
      () => {
        ManagerFullstackModule.actions.setCurrentlyEditingExperimentTab(
          ManagerFullstackModule.constants.Tabs.API_NAMES,
        );
        RoutingFns.renderMainRegion(
          <ManagerFullstackSection.pages.apiNames.component />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/history',
    shouldHandle: shouldHandleFullstack,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.OASIS,
        ),
        RoutingFns.parseProjectId,
        EnsureOasisBundle,
      ],
      (ctx, next) => {
        const experimentId = parseInt(ctx.params.experiment_id, 10);
        fetchRequiredDataForFullStackManager(experimentId).then(next);
      },
      (ctx, next) => fetchRequiredDataForManagerChangeHistory(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'History',
          params,
        }),
      // ctx.fetchedData is set within fetchRequiredDataForManagerChangeHistory
      ({ fetchedData }) => {
        ManagerFullstackModule.actions.setCurrentlyEditingExperimentTab(
          ManagerFullstackModule.constants.Tabs.HISTORY,
        );
        // Define within route since the componentId needs to change for renderMainRegion to remount the component
        const ManagerChangeHistory = withSidebarAndManagerChangeHistory(
          Sidebar,
        );
        RoutingFns.renderMainRegion(
          <ManagerChangeHistory fetchedEntities={fetchedData} />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/settings',
    shouldHandle: shouldHandleFullstack,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.OASIS,
        ),
        RoutingFns.parseProjectId,
        EnsureOasisBundle,
      ],
      (ctx, next) =>
        ManagerFullstackSection.pages.settings.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Settings',
          params,
        }),
      () => {
        ManagerFullstackModule.actions.setCurrentlyEditingExperimentTab(
          ManagerFullstackModule.constants.Tabs.SETTINGS,
        );
        RoutingFns.renderMainRegion(
          <ManagerFullstackSection.pages.settings.component />,
        );
      },
    ],
  },
];

export default routes;

module.exports = {
  DEFAULT_INITIAL_COLOR: 'rgb(0, 0, 0, 1)',

  EVENTS: {
    COLOR_UPDATED: 'colorUpdated',
    SET_COLOR: 'setColor',
  },

  REPLACER_CLASS_NAME: 'optly-color-picker__trigger picker-dimension-override',

  SPECTRUM_EVENTS: {
    PROPERTIES_CHANGED: 'spectrum:propertiesChanged',
    UPDATE: 'spectrum:update',
  },
};

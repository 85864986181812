import layersDashboard from './pages/layers_dashboard';
import groupsDashboard from './pages/groups_dashboard';

const pages = {
  groupsDashboard,
  layersDashboard,
};

export { pages };

export default {
  pages,
};

/* eslint-disable class-methods-use-this */
import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';

import { feature } from '@optimizely/js-sdk-lab/src/decorators';

import flux from 'core/flux';
import tr from 'optly/translate';
import ui from 'core/ui';

import { connect } from 'core/ui/decorators';
import Immutable, { toImmutable } from 'optly/immutable';

import { deepEqual } from 'core/utils/react';

import {
  fns as CurrentLayerFns,
  getters as CurrentLayerGetters,
} from 'bundles/p13n/modules/current_layer';
import {
  actions as LayerActions,
  getters as LayerGetters,
  fns as LayerFns,
} from 'optly/modules/entity/layer';
import { actions as P13NDashboardActions } from 'bundles/p13n/modules/dashboard';

import EntitySelector from 'react_components/entity_selector';
import ExperimentUsage from 'bundles/p13n/components/dialogs/experiment_usage';
import ConfigureViewSmartDialog from 'bundles/p13n/components/configure_view_smart';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

const EXPERIMENTS_USAGE_INDEX = 2;
@connect({
  layers: LayerGetters.entityCache,
  currentProjectId: CurrentProjectGetters.id,
})
class PageSelector extends React.Component {
  shouldComponentUpdate = deepEqual();

  static propTypes = {
    currentProjectId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      experimentsUsageAvailable: false,
    };
  }

  componentDidMount() {
    const { currentProjectId } = this.props;

    LayerActions.fetchAllByStatus({
      projectId: currentProjectId,
      byPage: false,
      archived: false,
    }).then(() => {
      this.setState({ experimentsUsageAvailable: true });
    });
  }

  onSelectView = (clickEvt, view) => {
    this.props.onChange(
      this.props.selectedViewIds.add(view.get('id')),
      this.props.addedViews.add(view),
    );
  };

  onUnselectView = (clickEvt, view) => {
    this.unselectView(view);
  };

  onClickSecondaryView = (clickEvt, view) => {
    this.onEditView(view);
  };

  unselectView = view => {
    // Check if we have any changes associated with the view and warn the user if changes exist
    const changesForView = flux.evaluate(
      CurrentLayerGetters.currentLayerChangesForView(view.get('id')),
    );
    const changeCounts = CurrentLayerFns.generateChangeCountsMessage(
      changesForView,
    );
    if (changesForView.size) {
      ui.confirm({
        title: tr('Removing this page will discard all variation changes'),
        message: `${tr(
          'The following changes will be discarded:',
        )}<div class="color--bad-news weight--bold push--bottom">${changeCounts}</div><div class="lego-media color--bad-news push--bottom" data-test-section="publish-warning"> <svg class="lego-icon lego-media__img"> <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#exclamation-16"></use> </svg> <div class="lego-media__body"> <p>${tr(
          'Once discarded, changes cannot be recovered.',
        )}</p> </div> </div>${tr(
          "Are you sure you'd like to remove the page?",
        )}`,
        doSanitizeHTML: false,
        confirmText: tr('Remove Page and Discard Changes'),
        isWarning: true,
      }).then(() => {
        this.props.onChange(
          this.props.selectedViewIds.delete(view.get('id')),
          this.props.addedViews.delete(view),
        );
      });
    } else {
      this.props.onChange(
        this.props.selectedViewIds.delete(view.get('id')),
        this.props.addedViews.delete(view),
      );
    }
  };

  onEditView = view => {
    ui.showReactDialog(
      ConfigureViewSmartDialog,
      {
        props: {
          isInDialog: true,
          isStandAlone: true,
          viewConfiguration: toImmutable(view),
        },
      },
      {
        fullScreen: true,
        dismissOnBack: true,
      },
    );
  };

  onExperimentUsageView = view => {
    ui.showReactDialog(
      ExperimentUsage,
      {
        props: {
          selectedViewId: view.get('id'),
        },
      },
      {
        isOuiDialog: true,
        fullScreen: false,
        dismissOnBack: true,
      },
    );
  };

  onCreateView = () => {
    ui.showReactDialog(
      ConfigureViewSmartDialog,
      {
        props: {
          onSave: newView => {
            this.props.onChange(
              this.props.selectedViewIds.add(newView.id),
              this.props.addedViews.add(toImmutable(newView)),
            );
          },
          isInDialog: true,
          isStandAlone: true,
          viewConfiguration: toImmutable(P13NDashboardActions.createView()),
        },
      },
      {
        fullScreen: true,
        dismissOnBack: true,
      },
    );
  };

  getExperimentsUsage = selectedEntity => {
    const { layers } = this.props;
    const { experimentsUsageAvailable } = this.state;

    if (!experimentsUsageAvailable) {
      return '';
    }

    const experimentUsage = layers.filter(
      layer =>
        layer.get('view_ids').includes(selectedEntity) &&
        !layer.get('archived') &&
        !LayerFns.hasLayerConcluded(layer),
    );
    return `Used in ${experimentUsage.size} experiments`;
  };

  render() {
    const { shouldUseEditorPageAsUrlTarget } = this.props;
    const byStatus = this.props.views.groupBy(view =>
      this.props.selectedViewIds.has(view.get('id')),
    );
    const selectedViews = byStatus.get(true, toImmutable([]));
    const unselectedViews = byStatus
      .get(false, toImmutable([]))
      .filterNot(view => view.get('archived') || view.get('single_use'))
      .sort((viewA, viewB) => {
        const createdA = viewA.get('created');
        const createdB = viewB.get('created');
        if (moment(createdA).isBefore(createdB)) {
          return 1;
        }
        if (moment(createdB).isBefore(createdA)) {
          return -1;
        }
        return 0;
      });
    const unselectedBlocks = toImmutable([
      {
        key: 'recently_created',
        label: tr('Recently Created Pages'),
        items: unselectedViews,
      },
    ]);

    let selectedSecondaryText = tr('View');
    let selectedSecondaryActions;
    if (this.props.canUseUrlTargeting && this.props.usePageAsUrlTarget) {
      selectedSecondaryActions = [
        {
          text: 'Edit',
          onClick: this.onEditView,
          testSection: 'open-page-view',
          trackId: '2.0-Campaign-Targeting.SavedViews.View',
        },
        {
          text: shouldUseEditorPageAsUrlTarget
            ? 'Use Editor URL as URL Target'
            : 'Use Page as URL Target',
          onClick: this.props.usePageAsUrlTarget,
          testSection: 'use-page-as-url-target',
          trackId: '2.0-Campaign-Targeting.SavedViews.UseAsUrlTarget',
        },
        {
          text: 'Remove',
          onClick: this.unselectView,
          trackId: '2.0-Campaign-Targeting.SavedViews.Remove',
        },
      ];

      selectedSecondaryActions.splice(EXPERIMENTS_USAGE_INDEX, 0, {
        text: 'View Experiment Usage',
        testSection: 'view-experiment-usage',
        onClick: this.onExperimentUsageView,
        trackId: '2.0-Campaign-Targeting.SavedViews.ViewExperimentUsage',
      });

      selectedSecondaryText = tr('Actions');
    }

    return (
      <div>
        <EntitySelector
          selectedItems={selectedViews}
          unselectedItems={unselectedBlocks}
          onEntitySelected={this.onSelectView}
          onEntityUnselected={this.onUnselectView}
          selectedEntitySecondaryText={this.getExperimentsUsage}
          selectedSecondaryActions={selectedSecondaryActions}
          onSelectedSecondaryClick={this.onClickSecondaryView}
          onUnselectedSecondaryClick={this.onClickSecondaryView}
          selectedSecondaryText={selectedSecondaryText}
          unselectedSecondaryText={tr('Edit')}
          primaryActionText={tr('Create New Page')}
          primaryActionOnClick={this.onCreateView}
          inputPlaceholder={tr('Browse for Pages')}
          testSection="pages"
        />
        {this.props.error && (
          <div
            className="lego-form-note lego-form-note--bad-news"
            data-test-section="views-for-layer-error">
            {this.props.error}
          </div>
        )}
      </div>
    );
  }
}

PageSelector.propTypes = {
  addedViews: PropTypes.instanceOf(Immutable.Set).isRequired,
  canUseUrlTargeting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  layers: PropTypes.instanceOf(Immutable.Map),
  onChange: PropTypes.instanceOf(Function),
  /**
   * Callback function for post save
   */
  onSave: PropTypes.instanceOf(Function),
  selectedViewIds: PropTypes.instanceOf(Immutable.Set).isRequired,
  shouldUseEditorPageAsUrlTarget: PropTypes.bool,
  usePageAsUrlTarget: PropTypes.func,
  /**
   * This list contains all views available for selection
   */
  views: PropTypes.instanceOf(Immutable.List).isRequired,
};

PageSelector.defaultProps = {
  shouldUseEditorPageAsUrlTarget: false,
};

export default PageSelector;

/**
 * A data table Table Header <li>
 * EXAMPLE:
 * <table data-sortable-table-id="[[ sortableAttr ]]">
 *   <thead>
 *     <tr>
 *       <th v-component="sortable-th" field="name">Name</th>
 *       <th v-component="sortable-th" field="status">Status</th>
 *     </tr>
 *   </thead>
 *   <tbody>
 *     <tr v-repeat="thingToBeSorted: things">...</tr>
 *   </tbody>
 * </table>
 */

const vueHelpers = require('optly/utils/vue_helpers');

module.exports = vueHelpers.extendComponent(
  require('./sortable_table_header_component.js'),
  {
    template: require('./sortable_th_component.html'),
  },
);

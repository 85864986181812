import _ from 'lodash';

/**
 * Field name mappings used to transform old experiment integration object into new one with the updated schema
 */
const FORM_FIELD_NAME_MAP = {
  adobe_analytics: {
    eVar: 'site_catalyst_eVar',
    prop: 'site_catalyst_prop',
  },

  crazyegg: {
    expiresAt: 'crazyegg_expires_at',
    maxVisits: 'crazyegg_max_visits',
    startsAt: 'crazyegg_starts_at',
  },

  google_classic_analytics: {
    slot: 'google_analytics_slot',
    tracker: 'google_analytics_tracker',
  },

  google_universal_analytics: {
    slot: 'universal_analytics_slot',
    tracker: 'universal_analytics_tracker',
  },

  google_universal_analytics_ios: {
    slot: 'universal_analytics_slot',
  },

  google_universal_analytics_mobile: {
    slot: 'universal_analytics_slot',
  },
};

/*
 * Populate reverse mappings in FORM_FIELD_NAME_MAP
 * E.g. after populating reverse mappings, the value of the adobe_analytics property becomes
 * {
 *   eVar: 'site_catalyst_eVar',
 *   prop: 'site_catalyst_prop',
 *   site_catalyst_eVar: 'eVar',
 *   site_catalyst_prop: 'prop'
 * }
 */
Object.entries(FORM_FIELD_NAME_MAP).forEach(([key, map]) => {
  const invertedMap = _.invert(map);
  Object.assign(map, invertedMap);
});

/**
 * Services layer pure functions for the projectintegrations
 */

/**
 * A helper function to handle non-standard integration settings
 *
 * @param integrationId ID ot the integration to check for if custom settings shall be added
 * @param settings the settings object of the integration to which custom settings will be added
 */
export function applyCustomExperimentLevelSettings(integrationId, settings) {
  if (integrationId === 'crazyegg') {
    const expiresAt = new Date();
    expiresAt.setMonth(expiresAt.getMonth() + 6); // Set expires at to 6 months in the future.
    const maxVisits = 5000;
    const startsAt = new Date();

    settings.expiresAt =
      settings.expiresAt || this.convertDateToUnix(expiresAt);
    settings.maxVisits = settings.maxVisits || maxVisits;
    settings.startsAt = settings.startsAt || this.convertDateToUnix(startsAt);
  } else if (
    integrationId === 'google_classic_analytics' ||
    integrationId === 'adobe_analytics'
  ) {
    settings.mode = 'custom_variables';
  }
}

/**
 * Convert date to UNIX format
 * The implementation is copied from src/www/js/bundle/edit/option/heatmap_intergration.js
 *
 * @param dateTime a string representing a Date / Time to be converted
 * @returns {Number | String} a number if conversion succeeds or an empty string otherwise
 * @private
 */
export function convertDateToUnix(dateTime) {
  if (dateTime) {
    return Math.round(new Date(dateTime).getTime() / 1000);
  }
  return '';
}

/**
 * Transform an integration settings object from legacy to new schema and vice versa
 *
 * @param integrationId ID of the integration whose settings object is going to be transformed
 * @param settings the source settings object to be transformed
 * @returns {Object} a transformed settings object
 */
export function transformExperimentLevelSettings(integrationId, settings) {
  const transformedSettings = {};
  const fieldNameMap = FORM_FIELD_NAME_MAP[integrationId];

  Object.entries(settings).forEach(([key, value]) => {
    if (key === 'mode') {
      transformedSettings.enabled = value !== 'disabled';
    } else if (key === 'enabled') {
      transformedSettings.mode = value ? 'enabled' : 'disabled';
    } else if (fieldNameMap && fieldNameMap[key]) {
      transformedSettings[fieldNameMap[key]] = value;
    }
  });
  return transformedSettings;
}

export default {
  applyCustomExperimentLevelSettings,
  convertDateToUnix,
  transformExperimentLevelSettings,
};

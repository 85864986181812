import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

/**
 * TODO(FE-817): Check whether all state.hasIn(...) guard code can be removed. (except addProjectRecord)
 *
 * verifier store
 * Responsible for the following state management:
 *
 * projectRecords:   Maintains a map of project ids --> object containing revision information about a project
 *
 * A projectRecord will have the following properties:
 *
 *    maxVerifiedRevision: The maximum verified revision
 *
 *    isFetching: A boolean checking whether a CDN request to the snippet ID is in progress
 *
 *    versionRequests: a mapping of snippet versions to an object containing:
 *
 *      deferred: a Deferred object that:
 *        - resolves when the CDN has fetched a valid version
 *        - rejects when the CDN fails to fetch a valid version N number of times
 */
export default Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      projectRecords: {},
    });
  },

  initialize() {
    this.on(actionTypes.VERIFIER_ADD_PROJECT_RECORD, addProjectRecord);
    this.on(actionTypes.VERIFIER_FETCH_START, fetchStart);
    this.on(actionTypes.VERIFIER_FETCH_ERROR, fetchError);
    this.on(actionTypes.VERIFIER_FETCH_SUCCESS, fetchSuccess);
    this.on(actionTypes.VERIFIER_ADD_VERSION_REQUEST, addVersionRequest);
    this.on(actionTypes.VERIFIER_REMOVE_VERSION_REQUEST, removeVersionRequest);
  },
});

/**
 * Create a new project ID record that keeps track of the latest verified revisions
 * as well as state for tracking revision fetching
 *
 * @param {Number} payload.projectId the project id we want to add a project record for
 */
function addProjectRecord(state, payload) {
  if (!state.hasIn(['projectRecords', payload.projectId])) {
    return state.setIn(
      ['projectRecords', payload.projectId],
      toImmutable({
        maxVerifiedRevision: 0,
        isFetching: false,
        versionRequests: {},
      }),
    );
  }
  return state;
}

/**
 * Add a deferred to signify a request for a specific version of a project ID
 *
 * @param {Number} payload.projectId the project id we want to add a project record for
 * @param {Number} payload.version the version number we want to verify
 * @param {Object} payload.deferred the deferred we want to be resolved when the version number has been verified
 */
function addVersionRequest(state, payload) {
  if (state.hasIn(['projectRecords', payload.projectId])) {
    return state.setIn(
      ['projectRecords', payload.projectId, 'versionRequests', payload.version],
      {
        deferred: payload.deferred,
      },
    );
  }
  return state;
}

/**
 * Remove a deferred from the record. This is done when version number has been verified or
 * we've hit the retry limit
 *
 * @param {Number} payload.projectId the project id we want to add a project record for
 * @param {Number} payload.version the version number we want to verify
 */
function removeVersionRequest(state, payload) {
  if (state.hasIn(['projectRecords', payload.projectId])) {
    return state.deleteIn([
      'projectRecords',
      payload.projectId,
      'versionRequests',
      payload.version,
    ]);
  }
  return state;
}

/**
 * Set the isFetching property to true so that other request know not to kick off a fetch request
 *
 * @param {Number} payload.projectId the project id we want to set the isFetching property for
 */
function fetchStart(state, payload) {
  if (state.hasIn(['projectRecords', payload.projectId])) {
    return state.updateIn(['projectRecords', payload.projectId], project =>
      project.merge({
        isFetching: true,
      }),
    );
  }
  return state;
}

/**
 * Set the isFetching property to false after error fetching so that we can retry in the future
 *
 * @param {Number} payload.projectId the project id we want to set the isFetching property for
 */
function fetchError(state, payload) {
  if (state.hasIn(['projectRecords', payload.projectId])) {
    return state.setIn(
      ['projectRecords', payload.projectId, 'isFetching'],
      false,
    );
  }
  return state;
}

/**
 * Set the isFetching property to false after success and set the maxVerifiedRevision
 * to the revision we just fetched
 *
 * @param {Number} payload.projectId the project id we want to set the isFetching property for
 * @param {Number} payload.maxVerifiedRevision the maxRevisionNumber we want to save
 */
function fetchSuccess(state, payload) {
  if (state.hasIn(['projectRecords', payload.projectId])) {
    return state.updateIn(['projectRecords', payload.projectId], project =>
      project.merge({
        maxVerifiedRevision: payload.maxVerifiedRevision,
        isFetching: false,
      }),
    );
  }
  return state;
}

const _ = require('lodash');
const classNames = require('classnames');
const Immutable = require('optly/immutable').default;
const PropTypes = require('prop-types');
const React = require('react');
const sprintf = require('sprintf');

// components
const {
  Button,
  ButtonRow,
  Code,
  Input,
  Textarea,
  Label,
} = require('optimizely-oui');
const Select = require('react_components/select').default;

// modules
const capitalize = require('optly/filters/capitalize');
const enums = require('bundles/p13n/sections/live_variables/section_module/enums');
const LiveVariableActions = require('optly/modules/entity/live_variable/actions')
  .default;
const LiveVariableEnums = require('optly/modules/entity/live_variable/enums');
const regexUtils = require('optly/utils/regex');
const ui = require('core/ui').default;

class LiveVariableDialog extends React.Component {
  static propTypes = {
    canCreateVariable: PropTypes.bool.isRequired,
    currentProjectVariables: PropTypes.instanceOf(Immutable.List).isRequired,
    language: PropTypes.string.isRequired,
    syntaxHighlightingLanguage: PropTypes.string.isRequired,
    variable: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  state = {
    isSaving: false,
    variable: this.props.variable,
  };

  canSave = () =>
    !this.state.isSaving &&
    this.isKeyUnique() &&
    this.isKeyValid() &&
    this.state.variable.get('api_name') &&
    this.state.variable.get('default_value') !== '';

  isKeyUnique = () => {
    const editingKey = this.state.variable.get('api_name');
    return (
      !editingKey ||
      !this.props.currentProjectVariables.some(
        variable =>
          variable.get('api_name') === editingKey &&
          variable.get('id') !== this.state.variable.get('id'),
      )
    );
  };

  isKeyValid = () =>
    regexUtils.apiName.test(this.state.variable.get('api_name'));

  setVariablePropertyFromInput = (event, property) => {
    const valueToSet = event.target.value;
    this.setState(prevState => ({
      variable: prevState.variable.set(property, valueToSet),
    }));
  };

  setVariablePropertyFromSelect = (property, valueToSet) => {
    this.setState(prevState => ({
      variable: prevState.variable.set(property, valueToSet),
    }));
  };

  setVariableType = newType => {
    const defaultValue = enums.LIVE_VARIABLE_VALUE_DEFAULTS[newType];
    this.setState(prevState => ({
      variable: prevState.variable.merge({
        type: newType,
        default_value: defaultValue,
      }),
    }));
  };

  save = () => {
    const { variable } = this.state;
    this.setState({
      isSaving: true,
    });

    const saveDef = LiveVariableActions.save(variable.toJS())
      .done(() => {
        ui.hideDialog();
      })
      .always(() => {
        this.setState({
          isSaving: false,
        });
      });

    ui.loadingWhen('save-oasis-variable', saveDef);
  };

  renderCodeBlock = () => {
    const varType = this.state.variable.get('type');
    const apiName = this.state.variable.get('api_name') || '';
    return sprintf(
      enums.LIVE_VARIABLE_CODE_BLOCKS[this.props.language][varType],
      apiName,
      apiName,
    );
  };

  renderVariableInput = () => {
    switch (this.state.variable.get('type')) {
      case LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean:
        return (
          <Select
            isActivatorFullWidth={true}
            onChange={_.partial(
              this.setVariablePropertyFromSelect,
              'default_value',
            )}
            testSection="live-variable-dialog-default-value-dropdown"
            value={this.state.variable.get('default_value')}>
            <Select.Option
              label="True"
              value="true"
              key="true"
              testSection="live-variable-dialog-true-option"
            />
            <Select.Option
              label="False"
              value="false"
              key="false"
              testSection="live-variable-dialog-false-option"
            />
          </Select>
        );
      case LiveVariableEnums.LIVE_VARIABLE_TYPES.double:
      case LiveVariableEnums.LIVE_VARIABLE_TYPES.integer:
        return (
          <Input
            onChange={_.partialRight(
              this.setVariablePropertyFromInput,
              'default_value',
            )}
            type="number"
            testSection="live-variable-dialog-default-value"
            value={this.state.variable.get('default_value')}
          />
        );
      case LiveVariableEnums.LIVE_VARIABLE_TYPES.string:
      default:
        return (
          <Textarea
            onChange={_.partialRight(
              this.setVariablePropertyFromInput,
              'default_value',
            )}
            type="text"
            testSection="live-variable-dialog-default-value"
            value={this.state.variable.get('default_value')}
          />
        );
    }
  };

  render() {
    const title = !this.state.variable.get('id')
      ? tr('Create New Variable')
      : tr('Edit Variable');

    return (
      <div data-test-section="live-variable-dialog">
        <div className="beta push-quad--bottom">{title}</div>
        <p>Parameterize your app with variables you want to optimize.</p>
        <form>
          <fieldset>
            <ol className="lego-form-fields">
              <li
                className={classNames({
                  'lego-form-field__item': true,
                  'lego-form-bad-news':
                    !this.isKeyUnique() || !this.isKeyValid(),
                })}>
                <Label>Variable</Label>
                <Input
                  type="text"
                  testSection="live-variable-dialog-key"
                  value={this.state.variable.get('api_name')}
                  onChange={_.partialRight(
                    this.setVariablePropertyFromInput,
                    'api_name',
                  )}
                />
                {!this.isKeyValid() && (
                  <div className="lego-form-note lego-form-note--bad-news">
                    Please enter a valid key.
                  </div>
                )}
                {!this.isKeyUnique() && (
                  <div className="lego-form-note lego-form-note--bad-news">
                    Please enter a unique key.
                  </div>
                )}
              </li>
              <li className="lego-form-field__item">
                <Label isOptional={true}>Description</Label>
                <Input
                  type="text"
                  testSection="live-variable-dialog-description"
                  value={this.state.variable.get('description')}
                  onChange={_.partialRight(
                    this.setVariablePropertyFromInput,
                    'description',
                  )}
                />
              </li>
              <li className="lego-form-field__item">
                <div className="lego-grid">
                  <div className="lego-grid__cell">
                    <Label>Type</Label>
                    <Select
                      value={this.state.variable.get('type')}
                      onChange={this.setVariableType}
                      isActivatorFullWidth={true}
                      testSection="live-variable-dialog-type">
                      {_.map(
                        LiveVariableEnums.LIVE_VARIABLE_TYPES,
                        (value, key) => (
                          <Select.Option
                            label={capitalize(value)}
                            value={value}
                            key={value}
                            testSection={`live-variable-dialog-${value}-option`}
                          />
                        ),
                      )}
                    </Select>
                  </div>
                  <div className="lego-grid__cell">
                    <Label>Default Value</Label>
                    {this.renderVariableInput()}
                  </div>
                </div>
              </li>
              <li className="lego-form-field__item">
                <Label>App Code</Label>
                <Code
                  isHighlighted={true}
                  hasCopyButton={true}
                  language={this.props.syntaxHighlightingLanguage}
                  type="block"
                  testSection="live-variable-code-block">
                  {this.renderCodeBlock()}
                </Code>
              </li>
            </ol>
          </fieldset>
        </form>
        <div className="lego-form__footer">
          <ButtonRow
            rightGroup={[
              <Button
                key="btn-cancel"
                style="plain"
                testSection="live-variable-dialog-cancel"
                onClick={ui.hideDialog}>
                Cancel
              </Button>,
              <Button
                key="btn-save"
                style="highlight"
                testSection="live-variable-dialog-save"
                isDisabled={!this.canSave()}
                onClick={this.save}>
                Save
              </Button>,
            ]}
          />
        </div>
      </div>
    );
  }
}

module.exports = LiveVariableDialog;

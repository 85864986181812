/*
 *
 *
 *
 *
 *
 * DEPRECATED as of Jan 2019 - use utils/request instead
 * migration tracked in FE-972
 *
 *
 *
 *
 *
 */
const $ = require('jquery');

const cloneDeep = require('optly/clone_deep').default;
const flux = require('core/flux');
const AuthGetters = require('optly/modules/auth/getters');

const config = require('atomic-config');

const UrlUtil = require('optly/utils/url');

/**
 * Accepts an opts object containing url, and adds options to make a cross site request to v1
 * This is needed when making an api request NOT through the requester module
 * @param {Object} opts
 * @param {String} opts.url
 * @param {type} opts.
 * @return {Deferred}
 */
exports.makeV1AjaxRequest = function(opts) {
  if (arguments.length !== 1 || !opts.url) {
    throw new Error(
      'Must supply a single options object containing url, type, etc',
    );
  }

  const updatedOpts = cloneDeep(opts);
  updatedOpts.url = UrlUtil.optimizelyHRDUrl(opts.url);
  // send cookies along
  updatedOpts.xhrFields = {
    withCredentials: true,
  };
  // Try retrieving csrf from Auth before falling back to the config. If we loaded the page from an
  // un-authed flow, then config will be empty. handleCSRFResponse will have need to have been called
  // before, and that stores the csrf token in the Auth module.
  updatedOpts.headers = opts.headers || {};
  updatedOpts.headers['X-csrf-token'] =
    flux.evaluate(AuthGetters.csrfToken) || config.get('csrf', '');
  return $.ajax(updatedOpts); // eslint-disable-line fetch/no-jquery
};

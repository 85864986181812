export const LABEL = tr('Email Address');

export const LINKS = {
  CHANGE: tr('Change email address\u2026'),
  VERIFY: tr('Verify email address\u2026'),
};

export const STATUSES = {
  UNVERIFIED: tr('unverified'),
  VERIFIED: tr('verified'),
};

export default {
  LABEL,
  LINKS,
  STATUSES,
};

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  ButtonIcon,
  Link as InternalLink,
  Table,
  Dropdown,
  BlockList,
} from 'optimizely-oui';

import Immutable from 'optly/immutable';
import location from 'optly/location';

import SectionModuleFns from 'bundles/p13n/sections/implementation/section_module/fns';
import { RecommendationsCSVDownloadUnavailable } from 'bundles/p13n/components/messaging/recommendations';

const RecommenderTableRow = ({
  canManageRecommendations,
  handleArchiveRecommender,
  handleEditRecommender,
  handleShowRecommenderPreviewer,
  handleUnarchiveRecommender,
  recommender,
  track,
}) => {
  const isArchived = recommender.get('archived');
  const catalogID = recommender.get('recommender_service_id');
  const catalogName = recommender.get('recommender_service_name');
  const recommenderID = recommender.get('id');
  const recommenderName = recommender.get('name');
  const recommenderDescription = recommender.get('description');
  const lastRun = recommender.getIn(['job_status_summary', 'last_run']);
  const lastRunFormatted = lastRun
    ? moment.utc(lastRun).format('LL')
    : 'Unavailable';
  // CSVs are labeled with UTC so we need to format the proper time using UTC instead of local time.
  const lastRunCSVFormatted =
    lastRun && moment.utc(lastRun).format('YYYY-MM-DD');

  const onArchiveRecommenderClick = () => {
    handleArchiveRecommender(recommender);
  };

  const onDownloadRecommenderOutputCSVClick = () => {
    location.setLocation(
      SectionModuleFns.buildURLForDownloadCSV(
        catalogID,
        'recommenders',
        lastRunCSVFormatted,
        recommenderID,
      ),
    );

    track('Recommendations Recommender Output CSV Downloaded', {
      catalogID,
      recommenderID,
    });
  };

  const onEditRecommenderClick = () => {
    handleEditRecommender(recommender);
  };

  const onShowRecommenderPreviewerClick = () => {
    handleShowRecommenderPreviewer(recommender);
  };

  const onUnarchiveRecommenderClick = () => {
    handleUnarchiveRecommender(recommender);
  };

  const renderRecommenderName = () => (
    <React.Fragment>
      {isArchived ? (
        <span
          className="word-break--word"
          data-test-section={`recommender-${recommenderID}-name`}>
          {recommenderName}
        </span>
      ) : (
        <div className="word-break--word">
          <InternalLink
            onClick={onEditRecommenderClick}
            testSection={`recommender-${recommenderID}-name`}>
            {recommenderName}
          </InternalLink>
        </div>
      )}
      <div
        className="micro muted word-break--word"
        data-test-section={`recommender-${recommenderID}-description`}>
        {recommenderDescription}
      </div>
    </React.Fragment>
  );

  const renderDropdown = () => {
    const dropdownItems = [
      {
        isVisible: !isArchived && canManageRecommendations,
        key: 'archive',
        onClick: onArchiveRecommenderClick,
        testSection: `archive-recommender-${recommenderID}`,
        text: 'Archive',
      },
      {
        isVisible: isArchived && canManageRecommendations,
        key: 'unarchive',
        onClick: onUnarchiveRecommenderClick,
        testSection: `unarchive-recommender-${recommenderID}`,
        text: 'Unarchive',
      },
      {
        description: 'Downloaded CSV will use UTC instead of local time.',
        header: 'Download CSV',
        isDisabled: !lastRun,
        isVisible: true,
        onClick: onDownloadRecommenderOutputCSVClick,
        testSection: `recommender-output-csv-btn-${recommenderID}`,
        text: 'Recommender Output',
      },
    ];

    return (
      <Dropdown
        testSection={`recommender-dropdown-${recommenderID}`}
        width={!lastRun ? '300px' : '200px'}
        activator={
          <ButtonIcon
            iconFill="default"
            iconName="ellipsis-solid"
            size="small"
            style="plain"
            testSection="recommender-actions-dropdown-menu"
          />
        }
        arrowIcon="none">
        <Dropdown.Contents>
          <BlockList>
            {dropdownItems
              .filter(item => item.isVisible)
              .map(item => (
                <BlockList.Category header={item.header} key={item.testSection}>
                  <BlockList.Item
                    onClick={item.onClick}
                    testSection={item.testSection}
                    isDisabled={item.isDisabled}>
                    <div className="flex flex-align--center">
                      <div className="flex--1">
                        <div>{item.text}</div>
                        {item.isDisabled && (
                          <RecommendationsCSVDownloadUnavailable
                            testSection={item.testSection}
                          />
                        )}
                        <div className="muted micro">{item.description}</div>
                      </div>
                    </div>
                  </BlockList.Item>
                </BlockList.Category>
              ))}
          </BlockList>
        </Dropdown.Contents>
      </Dropdown>
    );
  };

  return (
    <Table.TR borderStyle="ends" backgroundColor="faint" key={recommenderID}>
      <Table.TD width="60%">{renderRecommenderName()}</Table.TD>
      <Table.TD testSection="catalog-name" width="15%">
        <span className="word-break--word">{catalogName}</span>
      </Table.TD>
      <Table.TD testSection="last-update">
        <span className="nowrap">{lastRunFormatted}</span>
      </Table.TD>
      <Table.TD testSection="recommender-id">
        <span className="nowrap">{recommenderID}</span>
      </Table.TD>
      <Table.TD>
        <Button
          key={`previewer-${recommenderID}`}
          size="small"
          style="outline"
          width="default"
          testSection={`show-recommender-previewer-${recommenderID}-btn`}
          onClick={onShowRecommenderPreviewerClick}>
          Previewer
        </Button>
      </Table.TD>
      <Table.TD isNumerical={true}>{renderDropdown()}</Table.TD>
    </Table.TR>
  );
};

RecommenderTableRow.propTypes = {
  canManageRecommendations: PropTypes.bool.isRequired,
  /**
   * Function handler to archive the current recommender.
   */
  handleArchiveRecommender: PropTypes.func.isRequired,
  /**
   * Function handler to edit the current recommender.
   */
  handleEditRecommender: PropTypes.func.isRequired,
  /**
   * Function handler to show the Recommender Previewer of the current recommender.
   */
  handleShowRecommenderPreviewer: PropTypes.func.isRequired,
  /**
   * Function handler to unarchive the current recommender.
   */
  handleUnarchiveRecommender: PropTypes.func.isRequired,
  /**
   * Current recommender.
   */
  recommender: PropTypes.instanceOf(Immutable.Map).isRequired,
  /**
   * Segment Tracking function handler
   */
  track: PropTypes.func.isRequired,
};

export default RecommenderTableRow;

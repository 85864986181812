import MessageChannel from './message_channel';
import actions from './actions';
import constants from './constants';
import enums from './enums';
import fns from './fns';
import getters from './getters';

export { actions, constants, enums, fns, getters, MessageChannel };

export default {
  actions,
  constants,
  enums,
  fns,
  getters,
  MessageChannel,
};

import keyMirror from 'optly/utils/key_mirror';

export const mainPanelState = keyMirror({
  CONFIGURE: null,
  IFRAME: null,
});

export default {
  mainPanelState,
};

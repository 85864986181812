import keyMirror from 'optly/utils/key_mirror';

export default keyMirror({
  RESULTS_API_ADD_XHR: null,
  RESULTS_API_CLEAR_XHRS: null,
  RESULTS_API_SET_DATE_NOW: null,
  RESULTS_API_SET_TRACE_ID: null,
  RESULTS_API_STUB_DATE_NOW: null,
  RESULTS_API_REQUEST_SUCCESS: null,
});

import keyMirror from 'optly/utils/key_mirror';
import tr from 'optly/translate';

export const ErrorFlag = keyMirror({
  FULL_STACK_RUNNING_EXPERIMENT_UPDATE: null,
});

export const MAX_RUNNING_FEATURES_POPTIP_TEXT = tr(
  'Two or more Experiments are running and using the same Feature within the same environment. To remove, ensure this Experiment is running a maximum of once per environment.',
);

export const GroupExplanation = {
  AB_ONLY: tr(
    "Experiments can be added to exclusion groups. Changes to group membership take effect when you save the group, but saving won't start or unpause the experiments in the group.",
  ),
  P13N_ONLY: tr(
    "Campaigns can be added to exclusion groups. Changes to group membership take effect when you save the group, but saving won't start or unpause the campaigns in the group.",
  ),
  AB_AND_P13N: tr(
    "Both experiments and campaigns can be added to exclusion groups. Changes to group membership take effect when you save the group, but saving won't start or unpause the experiments or campaigns in the group.",
  ),
  FULL_STACK: tr(
    "Experiments can be added to exclusion groups. Changes to group membership take effect when you save the group, but saving won't start or unpause the experiments in the group.",
  ),
};

export default {
  ErrorFlag,
  MAX_RUNNING_FEATURES_POPTIP_TEXT,
  GroupExplanation,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Link } from 'optimizely-oui';

import CurrentProjectActions from 'optly/modules/current_project/actions';

const AdvancedFeaturesTrimming = ({
  label,
  description,
  overridePageTrimming,
  onUpdate,
}) => {
  const AUTOMATIC_PAGE_TRIMMING_LINK = CurrentProjectActions.getHelpCopy(
    'full_stack_targeting_automatic_page_trimming',
  );
  const onChangeOverridePageTrimming = () => {
    onUpdate('override_page_trimming', !overridePageTrimming);
  };

  return (
    <ul className="push-half oui-input-list">
      <li>
        <Checkbox
          description={description}
          onChange={onChangeOverridePageTrimming}
          checked={overridePageTrimming}
          label={label}
          data-test-section="configure-view-smart-override_page_trimming"
        />
        <Link
          className="push-triple--left"
          href={AUTOMATIC_PAGE_TRIMMING_LINK}
          newWindow={true}>
          Learn more
        </Link>
      </li>
    </ul>
  );
};

AdvancedFeaturesTrimming.defaultProps = {
  overridePageTrimming: false,
};

AdvancedFeaturesTrimming.propTypes = {
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  overridePageTrimming: PropTypes.bool,
};

export default AdvancedFeaturesTrimming;

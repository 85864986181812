import React from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown } from 'optimizely-oui';

import flux from 'core/flux';
import { MISSING_WARNING_TEXTS } from 'bundles/p13n/sections/implementation/section_module/constants';
import SectionModuleGetters from 'bundles/p13n/sections/implementation/section_module/getters';

const TagPicker = ({
  choices,
  currentlySelectedTag,
  isValueRequired,
  onChange,
  propertyName,
  testSection,
}) => {
  const isSelectedTagDeleted = flux.evaluateToJS([
    SectionModuleGetters.currentlyEditingCatalog,
    fetchedCatalog => {
      const previouslySelectedTag = fetchedCatalog.get(propertyName);
      const tagList = choices.map(tag => tag.get('value'));
      return !tagList.includes(previouslySelectedTag) && !currentlySelectedTag;
    },
  ]);

  return (
    <React.Fragment>
      <SelectDropdown
        displayError={isSelectedTagDeleted}
        items={choices.toJS()}
        maxWidth={200}
        onChange={val => onChange(propertyName, val)}
        testSection={testSection}
        value={currentlySelectedTag}
        width={200}
      />
      {isSelectedTagDeleted && !currentlySelectedTag && (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section={`${testSection}-deleted-tag-error`}>
          {MISSING_WARNING_TEXTS.TAG.previouslyDeletedTag}
        </div>
      )}
      {isValueRequired && !currentlySelectedTag && (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="catalog-no-primary-id-tag-error">
          {MISSING_WARNING_TEXTS.TAG.primaryIDTag}
        </div>
      )}
    </React.Fragment>
  );
};

TagPicker.propTypes = {
  choices: PropTypes.object.isRequired,
  currentlySelectedTag: PropTypes.string,
  isValueRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  propertyName: PropTypes.string.isRequired,
  testSection: PropTypes.string.isRequired,
};

export default TagPicker;

import _ from 'lodash';

import sprintf from 'sprintf';

import { isImmutable, toImmutable } from 'optly/immutable';

import enums from 'optly/utils/enums';
import stringUtils from 'optly/utils/str';

// TODO(nuclear): move these to project module
import IOS_SNIPPET_TEMPLATE from 'optly/services/project_code_ios.txt';
import IOS_ENABLE_EDITOR_SNIPPET_TEMPLATE from 'optly/services/project_code_ios_enable_editor.txt';
import ANDROID_SNIPPET_TEMPLATE from 'optly/services/project_code_android.txt';
import ANDROID_ENABLE_EDITOR_SNIPPET_TEMPLATE from 'optly/services/project_code_android_enable_editor.txt';

import projectEnums from './enums';
import constants from './constants';

const CUSTOM_PROJECT_SNIPPET_TEMPLATE =
  'https://cdn.optimizely.com/json/%s.json';
const EDGE_WORKER_URL_TEMPLATE =
  'https://optimizely-edge.com/edge-client/v1/%s/%s';
// @TODO(mng): Move this logic to app backend to build URL there itself based on datafile version
const V3_DATAFILE_TEMPLATE =
  'https://cdn.optimizely.com/public/%s/datafile_v3.json';
const WEB_SNIPPET_TEMPLATE =
  '<script src="https://cdn%s.optimizely.com/js/%s.js"></script>';
const EDGE_SUBDOMAIN_SNIPPET_TEMPLATE =
  '<script src="https://%s/edge-client/v1/%s/%s" referrerpolicy="no-referrer-when-downgrade"></script>';

/**
 * Returns a human readable project type for the given project
 * @param {Immutable.Map} project
 * @param {Boolean} canUseFullStackExperiments
 * @param {Boolean} canUseFullStack
 *
 * @return {String} projectType
 */
export const getPlatformName = function(
  project,
  canAccountUseFullStackExperiments = false,
  canUseFullStack = false,
  hasPP2020RolloutsPlusSubscription = false,
) {
  if (isFullStackProject(project)) {
    // Project should be labeled with readable third party platform name if present
    // Otherwise Project should be labeled as Full Stack if
    // 1. The account has access to Full Stack and can create FS experiments
    // 2. The account does not have access to Full Stack - signifying an account
    //    that churned off FS but has existing projects
    // Otherwise the Project is labeled as Rollouts
    //
    // Note: The logic for determining what the project IS (not how it should be presented
    //       through the pill in the primary nav) should reside in the backend.
    //
    const thirdPartyPlatform = isImmutable(project)
      ? project.get('third_party_platform')
      : project.third_party_platform;
    if (thirdPartyPlatform === projectEnums.thirdPartyPlatforms.SALESFORCE) {
      return projectEnums.thirdPartyPlatformsReadable.SALESFORCE_OPX;
    }
    if (
      thirdPartyPlatform ===
      projectEnums.thirdPartyPlatforms.MICROSOFT_DYNAMICS_365_COMMERCE
    ) {
      return projectEnums.thirdPartyPlatformsReadable
        .MICROSOFT_DYNAMICS_365_COMMERCE;
    }
    if (canAccountUseFullStackExperiments || !canUseFullStack) {
      return projectEnums.projectPlatformsHumanReadable.FULL_STACK;
    }
    if (
      !canAccountUseFullStackExperiments &&
      hasPP2020RolloutsPlusSubscription
    ) {
      return isFlagsProject(project)
        ? projectEnums.projectTypesHumanReadable.FULL_STACK_FLAGS
        : projectEnums.projectPlatformsHumanReadable.FULL_STACK;
    }
    return projectEnums.projectPlatformsHumanReadable.ROLLOUTS;
  }
  if (isMobileOrOTTProject(project) || isFullStackProject(project)) {
    return (
      projectEnums.sdksHumanReadable[getPrimarySDKLanguage(project)] ||
      stringUtils.capitalize(projectEnums.project_platforms.CUSTOM)
    );
  }
  if (isiOSProject(project)) {
    return projectEnums.sdksHumanReadable[projectEnums.sdks.IOS];
  }
  if (isWebProject(project)) {
    const deliveryMode = isImmutable(project)
      ? project.get('delivery_mode')
      : project.delivery_mode;
    if (deliveryMode) {
      return stringUtils.capitalize(deliveryMode);
    }
    return stringUtils.capitalize(
      projectEnums.projectPlatformsHumanReadable.WEB,
    );
  }
  return stringUtils.capitalize(
    isImmutable(project)
      ? project.getIn(['project_platforms', 0])
      : project.project_platforms[0],
  );
};

export const getPlatformNameUppercase = function(
  project,
  canAccountUseFullStackExperiments = false,
  canUseFullStack = false,
  hasPP2020RolloutsPlusSubscription = false,
) {
  const platformName = getPlatformName(
    project,
    canAccountUseFullStackExperiments,
    canUseFullStack,
    hasPP2020RolloutsPlusSubscription,
  );
  return platformName === 'iOS' ? platformName : platformName.toUpperCase();
};

/**
 * Returns a human readable project type for the given project
 * appended with the platform the project is on, X or Classic
 * @param {Immutable.Map} project
 * @param {Boolean} canAccountUseFullStackExperiments
 * @param {Boolean} hasPP2020RolloutsPlusSubscription
 * @param {Boolean} canUseFullStack
 *
 * @return {String} projectType
 */
export const getHumanReadableProjectType = function(
  project,
  canAccountUseFullStackExperiments = false,
  canUseFullStack = false,
  hasPP2020RolloutsPlusSubscription = false,
) {
  let projectType = getPlatformName(
    project,
    canAccountUseFullStackExperiments,
    canUseFullStack,
    hasPP2020RolloutsPlusSubscription,
  );

  if (isFlagsProject(project)) {
    projectType = tr('Feature Experimentation');
    return projectType;
  }

  if (
    !isFlagsProject(project) &&
    !isMobileOrOTTProject(project) &&
    (canAccountUseFullStackExperiments || !canUseFullStack) &&
    isXCustomProject(project)
  ) {
    projectType += tr(' (Legacy)');
    return projectType;
  }

  if (
    // X Custom Project or X Web Project
    isXCustomProject(project) ||
    isUsingStandaloneSnippet(project)
  ) {
    return projectType;
  }

  if (
    // Pure Classic Projects
    isClassicCustomProject(project) ||
    isiOSProject(project) ||
    isAndroidProject(project) ||
    isUsingLegacySnippet(project)
  ) {
    projectType += tr(' (Optimizely Classic)');
    return projectType;
  }

  if (
    // Bundled X and Classic Project
    isUsingBundledSnippet(project)
  ) {
    projectType += tr(' (Both Classic and X)');
    return projectType;
  }
};

/**
 * Returns the project type, which is one of
 * web, custom, node, php, java, javascript, ruby, ios, python
 * @param {Immutable.Map} project
 * @return {String} projectType
 */
export function getProjectType(project) {
  const platform = project.getIn(['project_platforms', 0]);
  const sdk = project.getIn(['sdks', 0]);
  if (
    platform === projectEnums.project_platforms.WEB ||
    (platform === projectEnums.project_platforms.CUSTOM && sdk === 'node') ||
    (platform === projectEnums.project_platforms.CUSTOM && sdk === 'javascript')
  ) {
    return platform;
  }

  return sdk || platform;
}

/**
 * Returns the project delivery mode. If the project has a defined delivery_mode,
 * return that. Otherwise we'll return the project type.
 * It'll be one of
 * edge, web, custom, node, php, java, javascript, ruby, ios, python
 * @param project
 * @returns {String} deliveryMode
 */
export function getDeliveryModeOrProjectType(project) {
  if (
    project &&
    Object.values(projectEnums.delivery_modes).includes(
      project.get('delivery_mode'),
    )
  ) {
    return project.get('delivery_mode');
  }
  return project && getProjectType(project);
}

/**
 * For the given project, return the name of the javascript global.
 * @param {Immutable.Map} project
 * @return {String}
 */
export function getSnippetGlobalName(project) {
  return projectEnums.snippetGlobalByTypeOrDeliveryMode[
    getDeliveryModeOrProjectType(project)
  ];
}

/**
 * Services layer pure functions for the projects
 */

/**
 * Returns true if this is a Custom project
 * @param {Object|Immutable.Map} project
 */
export function isCustomProject(project) {
  if (!project) {
    return false;
  }

  if (isImmutable(project)) {
    return project
      .get('project_platforms', toImmutable([]))
      .contains(enums.ProjectPlatforms.CUSTOM);
  }

  return (
    !!project &&
    _.includes(project.project_platforms, enums.ProjectPlatforms.CUSTOM)
  );
}

/**
 * Returns true if this is a Custom project created in Optimizely Classic
 * @param {Object} project
 */
export function isClassicCustomProject(project) {
  if (isImmutable(project)) {
    return (
      project
        .get('project_platforms', toImmutable([]))
        .contains(enums.ProjectPlatforms.CUSTOM) &&
      project.get('manifest_version') !== projectEnums.manifestVersions.V2
    );
  }
  return (
    !!project &&
    _.includes(project.project_platforms, enums.ProjectPlatforms.CUSTOM) &&
    project.manifest_version !== projectEnums.manifestVersions.V2
  );
}

/**
 * Returns true if this is a Custom project created in Optimizely X
 * @param {Object|Immutable.Map} project
 * @return {Boolean}
 */
export function isXCustomProject(project) {
  if (!project) {
    return false;
  }
  if (isImmutable(project)) {
    return (
      project
        .get('project_platforms')
        .contains(enums.ProjectPlatforms.CUSTOM) &&
      project.get('manifest_version') === projectEnums.manifestVersions.V2
    );
  }
  return (
    _.includes(project.project_platforms, enums.ProjectPlatforms.CUSTOM) &&
    project.manifest_version === projectEnums.manifestVersions.V2
  );
}

/**
 * Generates the snippet code for a custom project
 * @param {Object} project
 */
function customProjectCode(project) {
  if (isXMobileProject(project) || isOTTProject(project)) {
    return sprintf(V3_DATAFILE_TEMPLATE, project.id).trim();
  }
  return sprintf(CUSTOM_PROJECT_SNIPPET_TEMPLATE, project.id).trim();
}

/**
 * Generates the snippet code for a web project
 * @param {Integer} projectId
 * @param {Boolean=} isPciEnabled
 */
export function webProjectCode(projectId, isPciEnabled = false) {
  return sprintf(
    WEB_SNIPPET_TEMPLATE,
    isPciEnabled ? '-pci' : '',
    projectId,
  ).trim();
}

/**
 * Helper function to get project data
 */
function getProjectIds(project) {
  return {
    accountId: isImmutable(project)
      ? project.get('account_id')
      : project.account_id,
    projectId: isImmutable(project) ? project.get('id') : project.id,
  };
}

/**
 * Generates the URL for an Edge project
 * @param {Immutable.Map} project
 */
export function getProjectEdgeUrl(project) {
  const ids = getProjectIds(project);
  return sprintf(EDGE_WORKER_URL_TEMPLATE, ids.accountId, ids.projectId).trim();
}

/**
 * Generates the snippet code for an Edge project implemented with Edge Subdomain
 * @param {Immutable.Map} project
 * @param {String} subdomain           The subdomain the customer is implementing Edge with
 */
export function getProjectEdgeSubdomainCode(project, subdomain) {
  const ids = getProjectIds(project);
  return sprintf(
    EDGE_SUBDOMAIN_SNIPPET_TEMPLATE,
    subdomain,
    ids.accountId,
    ids.projectId,
  ).trim();
}

/**
 * Generates the install code for an iOS project
 * @param {String} socketToken
 */
export function iOSProjectCode(socketToken) {
  return sprintf(IOS_SNIPPET_TEMPLATE, socketToken).trim();
}

/**
 * Generates the enable editor snippet for an iOS project
 * @param {String} socketToken
 */
export function iOSEnableEditorProjectCode(socketToken) {
  return sprintf(IOS_ENABLE_EDITOR_SNIPPET_TEMPLATE, socketToken).trim();
}

/**
 * Generates the install code for an Android project
 * @param {String} socketToken
 */
export function androidProjectCode(socketToken) {
  return sprintf(ANDROID_SNIPPET_TEMPLATE, socketToken).trim();
}

/**
 * Generates the example code for enabling the editor for an Android project
 * @param {String} socketToken
 */
export function androidEnableEditorProjectCode(socketToken) {
  return sprintf(ANDROID_ENABLE_EDITOR_SNIPPET_TEMPLATE, socketToken).trim();
}

/**
 * Returns true if this is an iOS project
 * @param {Object|Immutable.Map} project
 */
export function isiOSProject(project) {
  if (isImmutable(project)) {
    return project
      .get('project_platforms', toImmutable([]))
      .includes(enums.ProjectPlatforms.IOS);
  }
  return _.includes(project.project_platforms, enums.ProjectPlatforms.IOS);
}

/**
 * Returns true if this is an Android project
 * @param {Object|Immutable.Map} project
 */
export function isAndroidProject(project) {
  if (isImmutable(project)) {
    return project
      .get('project_platforms', toImmutable([]))
      .includes(enums.ProjectPlatforms.ANDROID);
  }
  return _.includes(project.project_platforms, enums.ProjectPlatforms.ANDROID);
}

/**
 * Returns true if the project's installation_verified flag has been set.
 *
 * Note: This currently is the same flag for mobile and web. The caller should
 *       be aware of the project's type when using this flag.
 *       Once a project can contain mobile and web experiments/layers, an additional
 *       field will be needed on the account model to accurately track installations.
 *
 * @param project
 * @returns {boolean}
 */
export function installationVerified(project) {
  return !!project.installation_verified;
}

/**
 * Creates an empty object with default project fields set
 *
 */
export function createEntity(data) {
  const DEFAULTS = {
    id: null,
    account_id: null,
  };
  return {
    ...DEFAULTS,
    ...data,
  };
}

/**
 * Returns true if this project is using the standalone snippet.
 * @param project
 * @returns {boolean}
 */
export function isUsingStandaloneSnippet(project) {
  return (
    project &&
    project.getIn(['client_build_settings', 'client_type']) ===
      projectEnums.ClientBuildSettings.STANDALONE
  );
}

/**
 * Returns true if the project is using the bundled snippet
 * @param project
 * @returns {boolean}
 */
export function isUsingBundledSnippet(project) {
  return (
    project &&
    project.getIn(['client_build_settings', 'client_type']) ===
      projectEnums.ClientBuildSettings.BUNDLED
  );
}

/**
 * Returns true if the product is using the legacy snippet
 * @param project
 * @returns {boolean}
 */
export function isUsingLegacySnippet(project) {
  return (
    project &&
    project.getIn(['client_build_settings', 'client_type']) ===
      projectEnums.ClientBuildSettings.LEGACY
  );
}

/**
 * Returns true if this is a tvOS project
 * @param {Object|Immutable.Map} project
 * @returns {boolean}
 */
function isTvOSProject(project) {
  if (isImmutable(project)) {
    return project
      .get('sdks', toImmutable([]))
      .includes(projectEnums.sdks.TV_OS);
  }
  return _.includes(project.sdks, projectEnums.sdks.TV_OS);
}

/**
 * Returns true if this is a tvOS project
 * @param {Object|Immutable.Map} project
 * @returns {boolean}
 */
function isAndroidTVProject(project) {
  if (isImmutable(project)) {
    return project
      .get('sdks', toImmutable([]))
      .includes(projectEnums.sdks.ANDROID_TV);
  }
  return _.includes(project.sdks, projectEnums.sdks.ANDROID_TV);
}

/**
 * Returns true if this is an OTT (Android TV or tvOS) project
 * @param {Object|Immutable.Map} project
 * @returns {boolean}
 */
export function isOTTProject(project) {
  return isTvOSProject(project) || isAndroidTVProject(project);
}

/**
 * Returns true if this is an Optimizely X Full Stack (Python, Ruby, Java, Node, PHP, C#, JavaScript) project
 * @param {Object|Immutable.Map} project
 * @returns {boolean}
 */
export function isFullStackProject(project) {
  if (!isXCustomProject(project)) {
    return false;
  }
  let sdks;
  let numSdks;
  if (isImmutable(project)) {
    sdks = project.get('sdks');
    numSdks = sdks.size;
  } else {
    ({ sdks } = project);
    numSdks = sdks.length;
  }
  return (
    numSdks === 0 ||
    sdks.includes(projectEnums.sdks.PYTHON) ||
    sdks.includes(projectEnums.sdks.RUBY) ||
    sdks.includes(projectEnums.sdks.JAVA) ||
    sdks.includes(projectEnums.sdks.NODE) ||
    sdks.includes(projectEnums.sdks.JAVASCRIPT) ||
    sdks.includes(projectEnums.sdks.CSHARP) ||
    sdks.includes(projectEnums.sdks.PHP)
  );
}

/**
 * Returns true if this is a Flags Project
 * @param {Object|Immutable.Map} project
 * @returns {boolean}
 */
export function isFlagsProject(project) {
  let isFlagsEnabled = false;
  if (isImmutable(project)) {
    isFlagsEnabled = project.get('is_flags_enabled');
  } else {
    ({ is_flags_enabled: isFlagsEnabled } = project);
  }
  return !!isFlagsEnabled;
}

/**
 * Returns true if this is an Optimizley X (Oasis) mobile project
 * @param {Object|Immutable.Map} project
 * @returns {boolean}
 */
export function isXMobileProject(project) {
  if (isImmutable(project)) {
    return (
      project.get('sdks', toImmutable([])).includes(projectEnums.sdks.IOS) ||
      project.get('sdks', toImmutable([])).includes(projectEnums.sdks.ANDROID)
    );
  }
  return (
    _.includes(project.sdks, projectEnums.sdks.IOS) ||
    _.includes(project.sdks, projectEnums.sdks.ANDROID)
  );
}

/**
 * Returns true if this is an Optimizely X mobile or OTT project
 * @param {Object|Immutable.Map} project
 * @returns {boolean}
 */
export function isMobileOrOTTProject(project) {
  return isXMobileProject(project) || isOTTProject(project);
}

/**
 * Formats a custom project's name with parenthetical SDK name
 * @param {Object} project
 * @param {String} primarySDKLanguage
 * @returns {boolean}
 */
export function formatCustomProjectName(project, primarySDKLanguage) {
  if (primarySDKLanguage) {
    return `${project.project_name} (${projectEnums.sdksHumanReadable[primarySDKLanguage]})`;
  }
  return project.project_name;
}

/**
 * @name filterIntegrationByProject
 * @description Filter function used to check integrations.channels for the project type / delivery_mode
 *   Powered by: https://github.com/optimizely/optimizely-integrations/blob/552d9a77f3b69a480d7b3c3d52709d67ab65b542/optimizely_integrations/jira/integration.yaml#L7-L10.
 *
 * @param {Immutable.Map} project
 * @param {Immutable.Map} integration
 * @returns {Boolean}
 */
export function filterIntegrationByProject(project, integration) {
  const deliveryModeOrProjectType = getDeliveryModeOrProjectType(project);
  return integration.get('channels').contains(deliveryModeOrProjectType);
}

/**
 * Formats a project's name (Web or Full Stack) with parenthetical SDK name
 * @param {Object} project
 * @param {String} primarySDKLanguage
 * @returns {boolean}
 */
export function formatAnyProjectName(project, primarySDKLanguage) {
  const projectName = isImmutable(project)
    ? project.get('project_name')
    : project.project_name;
  if (!isWebProject(project) && primarySDKLanguage) {
    return `${projectName} (${projectEnums.sdksHumanReadable[primarySDKLanguage]})`;
  }
  return projectName;
}

/**
 * Retrieve the primary sdk language for the given project
 * For now it is just the first sdk listed in the array
 * @param  {Immutable.Map|Object} project
 * @return {string}
 */
export function getPrimarySDKLanguage(project) {
  if (isImmutable(project)) {
    if (project && project.has('sdks') && project.get('sdks').size) {
      return project.get('sdks').first();
    }
    return '';
  }

  if (project && project.sdks && project.sdks.length) {
    return project.sdks[0];
  }
  return '';
}

/**
 * Generates the project code for a project, depending on platform
 * @param {Object} project
 * @param {String} platform
 * @param {String} snippet (One of enums.ProjectSnippets) optional
 * @param {Boolean=} pciEnabled
 * @return {String} Code for the passed in platform or error string
 */
export function projectCode(project, platform, snippet, isPciEnabled = false) {
  const snippetOrStartSnippet = snippet || enums.ProjectSnippets.START;
  let code = '';
  switch (platform) {
    case enums.ProjectPlatformType.WEB:
      code = webProjectCode(project.id, isPciEnabled);
      break;
    case enums.ProjectPlatformType.IOS:
      switch (snippetOrStartSnippet) {
        case enums.ProjectSnippets.START:
          code = iOSProjectCode(project.socket_token);
          break;
        case enums.ProjectSnippets.START_WITH_ENABLE_EDITOR:
          code = iOSEnableEditorProjectCode(project.socket_token);
          break;
        default:
          code = tr('Snippet not found');
      }
      break;
    case enums.ProjectPlatformType.ANDROID:
      switch (snippetOrStartSnippet) {
        case enums.ProjectSnippets.START:
          code = androidProjectCode(project.socket_token);
          break;
        case enums.ProjectSnippets.START_WITH_ENABLE_EDITOR:
          code = androidEnableEditorProjectCode(project.socket_token);
          break;
        default:
          code = tr('Snippet not found');
      }
      break;
    case enums.ProjectPlatformType.CUSTOM:
      code = customProjectCode(project);
      break;
    default:
      code = tr('Unsupported Platform');
  }
  return code;
}

/**
 * Given the primary SDK language, select appropriate syntax highlighting language for event code blocks
 * For all language names and aliases see:
 * https://highlightjs.readthedocs.io/en/latest/css-classes-reference.html#language-names-and-aliases
 * @param  {string} sdkLanguage
 * @return {string} Language that OUI's syntax highlighting library highlight.js uses to generate syntax highlighting
 */
export function getSyntaxHighlightingLanguage(sdkLanguage) {
  let highlightingLanguage;
  if (
    sdkLanguage === projectEnums.sdks.ANDROID ||
    sdkLanguage === projectEnums.sdks.ANDROID_TV
  ) {
    highlightingLanguage = projectEnums.sdkLanguages.JAVA;
  } else if (
    sdkLanguage === projectEnums.sdks.IOS ||
    sdkLanguage === projectEnums.sdks.TV_OS
  ) {
    highlightingLanguage = projectEnums.sdkLanguages.OBJECTIVE_C;
  } else if (sdkLanguage === projectEnums.sdks.CSHARP) {
    highlightingLanguage = projectEnums.sdkLanguages.CSHARP;
  } else if (sdkLanguage === projectEnums.sdks.NODE) {
    highlightingLanguage = projectEnums.sdkLanguages.JAVASCRIPT;
  } else {
    highlightingLanguage = sdkLanguage;
  }
  return highlightingLanguage;
}

/**
 * Returns true if this is a web project
 * @param {Object|Immutable.Map} project
 */
export function isWebProject(project) {
  if (!project) {
    return false;
  }

  if (isImmutable(project)) {
    return project
      .get('project_platforms', toImmutable([]))
      .contains(enums.ProjectPlatforms.WEB);
  }

  return (
    !!project &&
    _.includes(project.project_platforms, enums.ProjectPlatforms.WEB)
  );
}

export function isWebEdgeProject(project) {
  if (!project) {
    return false;
  }

  const immutableProject = isImmutable(project)
    ? project
    : toImmutable(project);

  return (
    immutableProject
      .get('project_platforms', toImmutable([]))
      .contains(enums.ProjectPlatforms.WEB) &&
    immutableProject.get('delivery_mode') === projectEnums.delivery_modes.EDGE
  );
}

/**
 * Returns true if this is a mobile project
 * @param {Object} project
 */
export function isMobileProject(project) {
  return isiOSProject(project) || isAndroidProject(project);
}

/**
 * Returns true if this project is cross-platform
 */
export function isCrossPlatformProject(project) {
  return (
    !!project.project_platforms && _.uniq(project.project_platforms).length > 1
  );
}

/**
 * Returns true if this project uses V2 in its snippet.
 * NOTE: arrow function used to make evaluation of this permission runtime dynamic and stub-able
 * @param project
 * @returns {boolean}
 */
export const isUsingV2Snippet = project =>
  project.getIn(['client_build_settings', 'client_type']) ===
    projectEnums.ClientBuildSettings.BUNDLED ||
  project.getIn(['client_build_settings', 'client_type']) ===
    projectEnums.ClientBuildSettings.STANDALONE;

/**
 * Returns the limit to a project's number of custom attributes or null if there is none.
 *
 * @param project
 * @returns {Integer|null}
 */
export function customAttributeLimit(project) {
  let limit = null;
  const unlimited = !!(
    project.project_permissions &&
    _.includes(
      project.project_permissions,
      enums.Features.UNLIMITED_CUSTOM_ATTRIBUTES,
    )
  );

  if (unlimited) {
    limit = null;
  } else if (isCustomProject(project)) {
    limit = constants.MAX_CUSTOM_ATTRIBUTES_PER_CUSTOM_PROJECT;
  } else {
    limit = constants.MAX_CUSTOM_ATTRIBUTES;
  }

  return limit;
}

/**
 * Returns the editor_iframe_protocol_preference property of the project
 * entity, or EditorIframeProtocolPreferences.PROXY_AND_DIRECT as a default if
 * the project doesn't have that property.
 * @param {Immutable.Map} project
 * @return {String} One of Project.enums.EditorIframeProtocolPreferences
 */
export function getEditorIframeProtocolPreference(project) {
  return project.get(
    'editor_iframe_protocol_preference',
    projectEnums.EditorIframeProtocolPreferences.PROXY_AND_DIRECT,
  );
}

/**
 * Returns a new project Map with the human-readable project type
 * @param {Immutable.Map} project
 * @param {String} projectType
 * @return {Immutable.Map} project Map with project type
 */
const setProjectType = (project, projectType) => {
  return project.set('project_type', projectType);
};

/**
 * Returns the mapped project Map with the human-readable project type
 * @param {Object} project
 * @return {Immutable.Map} project Map
 */
export const mapProjectWithProjectType = ({
  project,
  canAccountUseFullStackExperiments,
  canAccountUseFullStackSDKs,
  hasPP2020RolloutsPlusSubscription,
}) => {
  const projectType = getHumanReadableProjectType(
    project,
    canAccountUseFullStackExperiments,
    canAccountUseFullStackSDKs,
    hasPP2020RolloutsPlusSubscription,
  );

  return setProjectType(project, projectType);
};

export default {
  androidEnableEditorProjectCode,
  androidProjectCode,
  customAttributeLimit,
  createEntity,
  getProjectEdgeUrl,
  filterIntegrationByProject,
  formatAnyProjectName,
  formatCustomProjectName,
  getEditorIframeProtocolPreference,
  getHumanReadableProjectType,
  getDeliveryModeOrProjectType,
  getPlatformName,
  getPlatformNameUppercase,
  getPrimarySDKLanguage,
  getProjectType,
  getProjectEdgeSubdomainCode,
  getSnippetGlobalName,
  getSyntaxHighlightingLanguage,
  installationVerified,
  isAndroidProject,
  isCrossPlatformProject,
  isCustomProject,
  isClassicCustomProject,
  isFullStackProject,
  isFlagsProject,
  iOSProjectCode,
  iOSEnableEditorProjectCode,
  isiOSProject,
  isMobileProject,
  isMobileOrOTTProject,
  isOTTProject,
  isUsingV2Snippet,
  isUsingStandaloneSnippet,
  isUsingBundledSnippet,
  isUsingLegacySnippet,
  isWebEdgeProject,
  isWebProject,
  isXCustomProject,
  isXMobileProject,
  mapProjectWithProjectType,
  projectCode,
  webProjectCode,
};

import Immutable from 'optly/immutable';
import React from 'react';
import PropTypes from 'prop-types';
import truncate from 'optly/filters/truncate';
import ui from 'core/ui';
import { useFeature } from '@optimizely/react-sdk';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import {
  constants as ExperimentationGroupConstants,
  fns as ExperimentationGroupFns,
} from 'optly/modules/entity/experimentation_group';
import { fns as FeatureManagerFns } from 'bundles/p13n/modules/feature_manager';

import ExperimentStatus from 'bundles/p13n/components/layer_experiment_status';
import { Input, Pill, Poptip, Table } from 'optimizely-oui';

import ConfigureGroupComponentModule from '../component_module';

const EntityRow = props => {
  const [isUserFriendlyNamesEnabled] = useFeature('user_friendly_names');
  const {
    canEditGroup,
    currentProjectFullStackExperiments,
    entity,
    environmentsAuthorizedAndSupported,
    group,
    handleRemovedExperiment,
    isCustomProject,
  } = props;

  const trafficAllocation = String(
    entity.get('weight') /
      ExperimentationGroupConstants.TRAFFIC_ALLOCATION_DENOMINATOR,
  );
  const enumeratedStatus = entity.get('status');
  const statusDetails = ExperimentationGroupFns.getStatusDetails(
    enumeratedStatus,
    isCustomProject,
  );
  const flagForWarningOnChange = ConfigureGroupComponentModule.fns.shouldFlagForWarningOnTrafficChange(
    entity,
    isCustomProject,
  );

  const updateCurrentlyEditingGroup = updateGroupEntities => {
    const matchedGroupIndex = group
      .get('entities')
      .findIndex(groupEntity => groupEntity.get('id') === entity.get('id'));
    if (matchedGroupIndex > -1) {
      const updatedGroupEntities = updateGroupEntities(matchedGroupIndex);
      const updatedAvailableTraffic = ConfigureGroupComponentModule.fns.calculateAvailableTraffic(
        updatedGroupEntities,
      );
      ConfigureGroupComponentModule.actions.setGroup(
        group
          .set('entities', updatedGroupEntities)
          .set('available_traffic', updatedAvailableTraffic),
      );
    }
  };

  const handleEntityRemove = () => {
    handleRemovedExperiment(entity);
    updateCurrentlyEditingGroup(matchedGroupIndex =>
      group.get('entities').delete(matchedGroupIndex),
    );
    if (flagForWarningOnChange) {
      ConfigureGroupComponentModule.actions.flagError(
        ConfigureGroupComponentModule.constants.ErrorFlag
          .FULL_STACK_RUNNING_EXPERIMENT_UPDATE,
        true,
      );
    }
  };

  const handleEntityTrafficAllocationChange = event => {
    if (flagForWarningOnChange) {
      ConfigureGroupComponentModule.actions.flagError(
        ConfigureGroupComponentModule.constants.ErrorFlag
          .FULL_STACK_RUNNING_EXPERIMENT_UPDATE,
        true,
      );
    }

    updateCurrentlyEditingGroup(matchedGroupIndex => {
      const updatedEntityWeight =
        (parseInt(event.target.value, 10) || 0) *
        ExperimentationGroupConstants.TRAFFIC_ALLOCATION_DENOMINATOR;
      return group
        .get('entities')
        .update(matchedGroupIndex, groupEntity =>
          groupEntity.set('weight', updatedEntityWeight),
        );
    });
  };

  const renderStatus = () => {
    if (isCustomProject) {
      // Since the ExperimentStatus uses "actual_status", we should mount the component with an instance of the group
      // entity that sets an "actual_status" key with the value of "status". This is compatible because the backend
      // provided "status" key (via the groups api endpoint) is actually using the layer experiment "actual_status"
      const entityWithActualStatus = entity.set(
        'actual_status',
        entity.get('status'),
      );
      return (
        <div
          data-test-section={`group-dialog-entity-${entity.get(
            'id',
          )}-environment-statuses`}>
          <ExperimentStatus experiment={entityWithActualStatus} />
        </div>
      );
    }
    return (
      <div
        data-test-section={`group-dialog-entity-${entity.get(
          'id',
        )}-readable-status`}
        className={statusDetails.get('class')}>
        {statusDetails.get('readable')}
      </div>
    );
  };

  const renderToken = () => {
    let featureAssignmentInvalid;
    if (isCustomProject) {
      // To determine if the entity would have a valid feature assignment if removed from the group, we need to set the NONE_GROUP_ID
      const entityWithNoneGroupId = entity.set(
        'group_id',
        ExperimentationGroupConstants.NONE_GROUP_ID,
      );
      // For Full Stack projects, check if the feature assignment would be valid with the updated entity
      featureAssignmentInvalid = !FeatureManagerFns.isFeatureAssignmentValid(
        entityWithNoneGroupId,
        currentProjectFullStackExperiments,
        environmentsAuthorizedAndSupported,
      );
    }

    // If the user can edit the group and the feature assignment is valid (or undefined), disable token dismissal
    const isTokenDismissable = canEditGroup && !featureAssignmentInvalid;

    const token = (
      <Pill
        backgroundColor="primary"
        description={
          (isUserFriendlyNamesEnabled && entity.get('api_name')) || ''
        }
        name={truncate(entity.get('name'), 48)}
        isDismissible={isTokenDismissable}
        onDismiss={handleEntityRemove}
        testSection={`group-dialog-entity-${entity.get('id')}-name`}
      />
    );

    // If the feature assignment is invalid, wrap the token in a poptip explaining why
    return !featureAssignmentInvalid ? (
      token
    ) : (
      <div data-test-section={`group-dialog-entity-${entity.get('id')}-poptip`}>
        <Poptip
          content={
            ConfigureGroupComponentModule.constants
              .MAX_RUNNING_FEATURES_POPTIP_TEXT
          }>
          {token}
        </Poptip>
      </div>
    );
  };

  return (
    <Table.TBody>
      <Table.TR>
        <Table.TD verticalAlign="middle">
          <div className="display--inline-block" title={entity.get('name')}>
            {renderToken()}
          </div>
        </Table.TD>
        <Table.TD isNumerical={true} verticalAlign="middle">
          <div className="flex flex-align--center flex-justified--end">
            <div className="width--75">
              <Input
                isReadOnly={!canEditGroup}
                min={0}
                max={
                  group.get('available_traffic') <= 0
                    ? parseInt(trafficAllocation, 10)
                    : ExperimentationGroupConstants.MAX_TRAFFIC_ALLOCATION
                }
                value={trafficAllocation}
                type="number"
                onChange={handleEntityTrafficAllocationChange}
                testSection={`group-dialog-entity-${entity.get(
                  'id',
                )}-traffic-allocation`}
              />
            </div>
            <span className="push-half--left push--right">%</span>
          </div>
        </Table.TD>
        <Table.TD verticalAlign="middle" width="100px">
          {renderStatus()}
        </Table.TD>
      </Table.TR>
    </Table.TBody>
  );
};

EntityRow.propTypes = {
  canEditGroup: PropTypes.bool.isRequired,
  currentProjectFullStackExperiments: PropTypes.instanceOf(Immutable.List)
    .isRequired,
  entity: PropTypes.instanceOf(Immutable.Map).isRequired,
  environmentsAuthorizedAndSupported: PropTypes.bool.isRequired,
  group: PropTypes.instanceOf(Immutable.Map).isRequired,
  isCustomProject: PropTypes.bool.isRequired,
};

export default ui.connectGetters(EntityRow, {
  currentProjectFullStackExperiments:
    CurrentProjectGetters.fullStackLayerExperimentsWrapper,
  environmentsAuthorizedAndSupported:
    CurrentProjectGetters.environmentsAuthorizedAndSupported,
});

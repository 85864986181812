import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'optimizely-oui';

import Immutable from 'optly/immutable';
import { formPropType } from 'react_components/form';
import { constants as FeatureConstants } from 'optly/modules/entity/feature_flag';

import VariablesActionsCell from '../variable_actions_cell';
import VariablesKeyCell from '../variable_key_cell';
import VariablesTypeCell from '../variable_type_cell';
import VariablesValueCell from '../variable_value_cell';

/**
 * VariablesEditTable
 * @description This table is for editing features variable. It will currently be used on the feature-variables page
 *  and the create-new-feature modal for targeted-rollouts
 * @kind component
 * @example
 *  <VariablesEditTable
 *    variablesField={form.repeatedField('variables')}
 *    readOnly={false}
 *  />
 */
const VariablesEditTableLegacy = ({ isDisabled, variablesField, readOnly }) => {
  useEffect(() => {
    const invalidErrorMessage =
      'Please enter a valid key. Valid keys contain alphanumeric characters, hyphens, and underscores, and are limited to 64 characters.';

    variablesField.setupRepeatedValidators({
      api_name: {
        isRequired: (value, formState, index) => {
          const variableField = formState.getIn(['variables', index]);
          return !variableField.get('archived') &&
            !Immutable.is(variableField, FeatureConstants.BASE_VARIABLE) &&
            !value
            ? invalidErrorMessage
            : '';
        },
      },
    });
  }, []);

  const renderRow = (variableField, index, activeVariablesField) => {
    if (variableField.getValue().get('archived')) {
      return;
    }
    const variableId = variableField.getValue().get('id');
    const indexTestSection = variableId || `index-${index}`;

    const isDefault = Immutable.is(
      variableField.getValue(),
      FeatureConstants.BASE_VARIABLE,
    );
    const activeVariablesCount = activeVariablesField.size;
    const hasAtLeastThreeVariablesAndIsLastVariable =
      activeVariablesCount >= 3 && activeVariablesCount - 1 === index;
    const showAddButton = activeVariablesCount - 1 === index && !readOnly;
    const showRemoveButton =
      (activeVariablesCount > 1 || !isDefault) && !readOnly;

    return (
      <Table.TR
        key={`${variableId}-field-${index}`}
        testSection="variables-edit-table-row">
        <Table.TD colSpan={2}>
          <VariablesKeyCell
            isDisabled={isDisabled}
            readOnly={readOnly}
            variableField={variableField}
            testSection={`variable-table-key-cell-${indexTestSection}`}
          />
        </Table.TD>
        <Table.TD>
          <VariablesTypeCell
            readOnly={readOnly}
            isDisabled={!!variableField.getValue().get('id') || isDisabled}
            isLastVariable={hasAtLeastThreeVariablesAndIsLastVariable}
            defaultValueField={variableField.field('default_value')}
            typeField={variableField.field('type')}
            testSection={`variable-table-type-cell-${indexTestSection}`}
          />
        </Table.TD>
        <Table.TD colSpan={2}>
          <VariablesValueCell
            isDisabled={isDisabled}
            readOnly={readOnly}
            typeField={variableField.field('type')}
            valueField={variableField.field('default_value')}
            testSection={`variable-table-value-cell-${indexTestSection}`}
          />
        </Table.TD>
        <Table.TD>
          <VariablesActionsCell
            defaultVariable={FeatureConstants.BASE_VARIABLE}
            index={index}
            isDisabled={isDisabled}
            showAddButton={showAddButton}
            showRemoveButton={showRemoveButton}
            variableField={variableField}
            variablesField={variablesField}
            testSection={`variable-table-actions-cell-${indexTestSection}`}
          />
        </Table.TD>
      </Table.TR>
    );
  };

  const activeVariablesField = variablesField.filterChildren(
    variableField => !variableField.getValue().get('archived'),
  );

  return (
    <Table>
      <Table.THead>
        <Table.TR>
          <Table.TH colSpan={2}>Variable Key</Table.TH>
          <Table.TH>Type</Table.TH>
          <Table.TH colSpan={2}>Default Value</Table.TH>
          <Table.TH />
        </Table.TR>
      </Table.THead>
      <Table.TBody>
        {activeVariablesField.map((variableField, index) =>
          renderRow(variableField, index, activeVariablesField),
        )}
      </Table.TBody>
    </Table>
  );
};

VariablesEditTableLegacy.defaultProps = {
  isDisabled: false,
  readOnly: false,
};

VariablesEditTableLegacy.propTypes = {
  isDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  variablesField: formPropType.isRequired,
};

export default VariablesEditTableLegacy;

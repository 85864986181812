import React from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';

import { formPropType } from 'react_components/form';

import Immutable from 'optly/immutable';

import FeatureManagerFns from 'bundles/p13n/modules/feature_manager/fns';
import SectionModuleFns from 'bundles/p13n/sections/manager_fullstack/section_module/fns';

import FeatureSelector from './subcomponents/feature_selector';

class FeatureSelectorFormInput extends React.Component {
  static propTypes = {
    currentProjectExperiments: PropTypes.instanceOf(Immutable.List).isRequired,
    currentProjectFeatures: PropTypes.instanceOf(Immutable.List).isRequired,
    environmentsAuthorizedAndSupported: PropTypes.bool.isRequired,
    form: formPropType.isRequired,
  };

  constructor(props) {
    super(props);

    props.form.field('feature_flag_id').validators({
      hasFeatureValidationError: this.hasFeatureValidationError,
      hasNoFeatureSelectedError: this.hasNoFeatureSelectedError,
    });
  }

  updateExperimentProperty = (property, value) => {
    const { form } = this.props;

    form.field(property).setValue(value);
  };

  handleFeatureSelected = selectedFeature => {
    const { form } = this.props;

    // update the experiment with the selected feature flag
    this.updateExperimentProperty('feature_flag_id', selectedFeature.get('id'));

    // Need the form to keep track of variations field because we are updating each variation after choosing a feature
    form.setValue(
      SectionModuleFns.attachFeatureVariablesToExperiment(
        selectedFeature,
        form.getValue(),
      ),
    );

    // if the experiment key hasn't been manually defined, then pre-populate it
    if (!form.field('key').getValue()) {
      let featureName = selectedFeature.get('api_name');

      if (featureName.length < 60) {
        featureName = `${featureName}_test`;
      }

      this.updateExperimentProperty('key', featureName);
    }
  };

  handleFeatureUnselected = () => {
    this.updateExperimentProperty('feature_flag_id', null);
  };

  hasFeatureValidationError = () => {
    const {
      currentProjectExperiments,
      environmentsAuthorizedAndSupported,
      form,
    } = this.props;

    const selectedFeatureFlagId = form.field('feature_flag_id').getValue();

    return (
      isNumber(selectedFeatureFlagId) &&
      !FeatureManagerFns.isFeatureAssignmentValid(
        form.getValue(),
        currentProjectExperiments,
        environmentsAuthorizedAndSupported,
      )
    );
  };

  hasNoFeatureSelectedError = () => {
    const { form } = this.props;

    const selectedFeatureFlagId = form.field('feature_flag_id').getValue();

    return !selectedFeatureFlagId || !isNumber(selectedFeatureFlagId);
  };

  render() {
    const { currentProjectFeatures, form } = this.props;

    const errors = form.field('feature_flag_id').getErrors();

    return (
      <FeatureSelector
        experiment={form.getValue()}
        features={currentProjectFeatures}
        handleFeatureSelected={this.handleFeatureSelected}
        handleFeatureUnselected={this.handleFeatureUnselected}
        showFeatureValidationError={errors.details.hasFeatureValidationError}
        showNoFeatureSelectedError={errors.details.hasNoFeatureSelectedError}
      />
    );
  }
}

export default FeatureSelectorFormInput;

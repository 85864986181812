import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  Link,
  Dropdown,
  ButtonIcon,
  Icon,
} from '@optimizely/axiom';

import stringUtils from 'optly/utils/str';

import { createUniqueAccessor } from '../../utils';
import { CUSTOM_PROPERTY_TYPES, MAX_EVENT_PROPERTIES } from '../../constants';

const CustomProperties = ({
  eventProperties,
  eventPropertiesError,
  isEventUsedInExperiment,
  isFx,
  updateEventProperties,
}) => {
  const customProperties = useMemo(
    () =>
      eventProperties.filter(({ propertyType }) => propertyType === 'custom'),
    [eventProperties],
  );

  const onAddCustomProperty = useCallback(() => {
    const accessor = createUniqueAccessor({
      prefix: 'custom-event-property',
      accessors: eventProperties.map(property => property.accessor),
    });
    const newEventProperty = {
      accessor,
      value: '',
      type: null,
      propertyType: 'custom',
    };

    updateEventProperties([...eventProperties, newEventProperty]);
  }, [eventProperties]);

  const onRemoveCustomProperty = useCallback(
    accessorKey => {
      updateEventProperties(
        eventProperties.filter(({ accessor }) => accessor !== accessorKey),
      );
    },
    [eventProperties],
  );

  const onCustomPropertyChange = useCallback(
    ({ dataType, accessor, value }) => {
      const updatedProperties = eventProperties.map(property => {
        if (property.accessor === accessor) {
          return {
            ...property,
            [dataType]: value,
          };
        }
        return property;
      });

      updateEventProperties(updatedProperties);
    },
    [eventProperties],
  );

  return (
    <div className="push-double--ends">
      <h6 className="subhead">Custom Properties</h6>
      <p>
        Create your own properties to send with this event.{' '}
        <Link
          href="https://docs.developers.optimizely.com/web-experimentation/docs/event-objects"
          newWindow={true}>
          Learn more
        </Link>
      </p>
      <CustomPropertiesList
        isEventUsedInExperiment={isEventUsedInExperiment}
        isFx={isFx}
        customProperties={customProperties}
        canAdd={eventProperties.length < MAX_EVENT_PROPERTIES}
        eventPropertiesError={eventPropertiesError}
        onAdd={onAddCustomProperty}
        onRemove={onRemoveCustomProperty}
        onChange={onCustomPropertyChange}
      />
    </div>
  );
};

CustomProperties.propTypes = {
  eventProperties: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired,
      persisted: PropTypes.bool,
      propertyType: PropTypes.oneOf(['custom', 'default']).isRequired,
      type: PropTypes.oneOf(['string', 'number', 'boolean']),
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  eventPropertiesError: PropTypes.string,
  isEventUsedInExperiment: PropTypes.bool,
  isFx: PropTypes.bool,
  updateEventProperties: PropTypes.func.isRequired,
};
CustomProperties.defaultProps = {
  eventPropertiesError: '',
};

const CustomPropertiesList = ({
  customProperties,
  canAdd,
  eventPropertiesError,
  isEventUsedInExperiment,
  isFx,
  onAdd,
  onRemove,
  onChange,
}) =>
  customProperties.length ? (
    <ul>
      {customProperties.map((property, i) => (
        <CustomPropertiesListItem
          isEventUsedInExperiment={isEventUsedInExperiment}
          isFx={isFx}
          key={property.accessor}
          property={property}
          onAdd={onAdd}
          onRemove={onRemove}
          onChange={onChange}
          eventPropertiesError={eventPropertiesError}
          canAdd={canAdd && customProperties.length === i + 1}
        />
      ))}
    </ul>
  ) : (
    <Button
      onClick={onAdd}
      className="flex flex-align--center"
      testSection="add-first-custom-property-button"
      disabled={!canAdd}>
      <Icon name="plus" className="push--right" size="small" />
      <span>Add custom property</span>
    </Button>
  );

CustomPropertiesList.propTypes = {
  canAdd: PropTypes.bool.isRequired,
  customProperties: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired,
      persisted: PropTypes.bool,
      propertyType: PropTypes.oneOf(['custom']).isRequired,
      type: PropTypes.oneOf(['string', 'number', 'boolean']),
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  eventPropertiesError: PropTypes.string,
  isEventUsedInExperiment: PropTypes.bool,
  isFx: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
CustomPropertiesList.defaultProps = {
  eventPropertiesError: '',
};

const CustomPropertiesListItem = ({
  eventPropertiesError,
  isEventUsedInExperiment,
  isFx,
  property,
  onAdd,
  onRemove,
  onChange,
  ...props
}) => {
  const canAdd = useMemo(
    () => property.value && property.type && props.canAdd,
    [property.value, property.type, props.canAdd],
  );

  const canRemove = useMemo(
    () =>
      !(
        (isFx && property.persisted) ||
        (isEventUsedInExperiment && property.persisted)
      ),
    [property.persisted, isFx, isEventUsedInExperiment],
  );

  const hasEmptyValueError = useMemo(
    () => !!eventPropertiesError && !property.value,
    [eventPropertiesError, property.value],
  );

  const hasEmptyTypeError = useMemo(
    () => !!eventPropertiesError && !property.type,
    [eventPropertiesError, property.type],
  );

  return (
    <li className="flex width--3-4 push--ends">
      <div className="flex flex--1">
        <Input
          placeholder="Enter property name"
          onChange={e => {
            const { value } = e.target;
            onChange({
              dataType: 'value',
              accessor: property.accessor,
              value,
            });
          }}
          value={property.value}
          className="flex--1"
          displayError={hasEmptyValueError}
          disabled={
            (isEventUsedInExperiment && property.persisted) ||
            (isFx && property.persisted)
          }
        />
        <Dropdown
          arrowIcon="down"
          buttonContent={
            property.type ? stringUtils.capitalize(property.type) : 'Select...'
          }
          displayError={hasEmptyTypeError}
          isDisabled={
            (isEventUsedInExperiment && property.persisted) ||
            (isFx && property.persisted)
          }
          className="push-double--sides flex--1 flex"
          fullWidth={true}>
          <Dropdown.Contents className="flex--1">
            {CUSTOM_PROPERTY_TYPES.map(type => (
              <Dropdown.ListItem key={`property-type--${type}`}>
                <Dropdown.BlockLink
                  onClick={() => {
                    onChange({
                      dataType: 'type',
                      accessor: property.accessor,
                      value: type,
                    });
                  }}>
                  <Dropdown.BlockLinkText
                    isItemSelected={property.type === type}
                    text={stringUtils.capitalize(type)}
                  />
                </Dropdown.BlockLink>
              </Dropdown.ListItem>
            ))}
          </Dropdown.Contents>
        </Dropdown>
      </div>
      <div
        className="flex flex-align--center flex-justified--between"
        style={{ minWidth: 60 }}>
        {canRemove && (
          <ButtonIcon
            testSection="remove-custom-property-button"
            iconFill="default"
            iconName="trash-can"
            onClick={() => onRemove(property.accessor)}
            size="small"
            style="plain"
            title="Delete custom property"
          />
        )}
        {canAdd && (
          <ButtonIcon
            testSection="add-custom-property-button"
            iconFill="default"
            iconName="plus"
            onClick={onAdd}
            size="small"
            style="plain"
            title="Add custom property"
          />
        )}
      </div>
    </li>
  );
};

CustomPropertiesListItem.propTypes = {
  canAdd: PropTypes.bool.isRequired,
  eventPropertiesError: PropTypes.string,
  isEventUsedInExperiment: PropTypes.bool,
  isFx: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  property: PropTypes.shape({
    accessor: PropTypes.string.isRequired,
    persisted: PropTypes.bool,
    propertyType: PropTypes.oneOf(['custom']).isRequired,
    type: PropTypes.oneOf(['string', 'number', 'boolean']),
    value: PropTypes.string.isRequired,
  }).isRequired,
};
CustomPropertiesListItem.defaultProps = {
  eventPropertiesError: '',
};

export default CustomProperties;

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

// Eventually we'll do some sort of filtering/sorting on this, just not for MVP
export const currentProjectFeatures = CurrentProjectGetters.features;
export const currentProjectRunningExperiments =
  CurrentProjectGetters.currentProjectRunningExperiments;

export default {
  currentProjectFeatures,
  currentProjectRunningExperiments,
};

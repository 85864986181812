/**
 * Takes as input string and passes it through tr.variable() function - so value will appear translated.
 * Using this function doesn't add that string into translation queue - make sure that all the possible strings that
 * gets translated using this filter - are marked with "tr()" function calls.
 * @return {String} string to translate
 * @return {String} translated string, or the original string param if there is no translation
 */
module.exports = function(string) {
  return tr.variable(string);
};

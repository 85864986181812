import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { OptimizelyFeature, useFeature } from '@optimizely/react-sdk';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';
import { Col, Container, Fieldset, Input, Row, Textarea } from 'optimizely-oui';

import ui from 'core/ui';
import Immutable from 'optly/immutable';
import { connect } from 'core/ui/decorators';
import { formPropType } from 'react_components/form';
import userFriendlyNameToKey from 'optly/utils/userFriendlyNameToKey';
import {
  getters as FeatureGetters,
  actions as FeatureActions,
  constants as FeatureConstants,
} from 'optly/modules/entity/feature_flag';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import LoadingGetters from 'core/modules/loading/getters';

/**
 * @name setupForm
 * @description Initial form fields and field-validators
 * @param {Form} form - instance from From HOC
 * @private
 */
const setupForm = ({ form, currentProject, feature }) => {
  const invalidNameErrorMessage = 'Please enter a name.';
  const invalidKeyErrorMessage =
    'Please enter a valid key. Valid keys contain alphanumeric characters, hyphens, and underscores, and are limited to 64 characters.';
  const uniqueErrorMessage =
    'Key is already in use by another feature in this project. Please choose a unique key.';

  if (isFeatureEnabled('user_friendly_names')) {
    form.field('name').validators({
      isRequired: value => (!value ? invalidNameErrorMessage : ''),
    });
  }
  form.field('api_name').validators(
    {
      isRequired: value => (!value ? invalidKeyErrorMessage : ''),
      isUnique: value => {
        const skipCheck =
          !value ||
          (!!feature && feature.get('id') && feature.get('api_name') === value);

        if (skipCheck) {
          return Promise.resolve(null);
        }
        const keyValidationFetch = FeatureActions.fetchByApiName(
          currentProject.get('id'),
          value,
        ).then(result => {
          return result && result.length > 0 ? uniqueErrorMessage : '';
        });
        if (isFeatureEnabled('user_friendly_names')) {
          ui.loadingWhen('feature_key_validation', keyValidationFetch);
        }
        return keyValidationFetch;
      },
    },
    {
      validateOnChange: isFeatureEnabled('user_friendly_names'),
    },
  );
  form.field('description');
};

/**
 * @name FeatureDetailsFields
 * @description Renders form fields for feature manager
 * @kind component
 * @example
 *  <FeatureDetailsFields
 *    form={form}
 *    featureId={123}
 *    readOnly={false}
 *  />
 */
const FeatureDetailsFields = ({
  currentProject,
  feature,
  featureId,
  form,
  isValidatingFeatureKey,
  readOnly,
}) => {
  useEffect(() => setupForm({ form, currentProject, feature }), []);
  const [isUserFriendlyNamesEnabled] = useFeature('user_friendly_names');

  const handleNameChange = event => {
    const previousName = form.field('name').getValue();
    const previousApiName = form.field('api_name').getValue();
    const inputValue = event.target.value;

    form.field('name').setValue(inputValue);
    // If the api_name is empty or matches the formatted friendly name, continue with auto-generation ONLY for feature creation dialog
    if (
      !featureId &&
      (!previousApiName ||
        previousApiName ===
          userFriendlyNameToKey((previousName || '').toLowerCase()))
    ) {
      form
        .field('api_name')
        .setValue(userFriendlyNameToKey((inputValue || '').toLowerCase()));
    }
  };

  const getKeyNote = () => {
    if (!isUserFriendlyNamesEnabled) {
      return form.field('api_name').getErrors().message;
    }
    return (
      (isValidatingFeatureKey && 'Validating...') ||
      form.field('api_name').getErrors().message
    );
  };

  return (
    <Fieldset>
      <Container pullRowPadding={true} pushRowsTop={true}>
        <OptimizelyFeature feature="user_friendly_names">
          {isEnabled =>
            isEnabled ? (
              <Row>
                <Col small="fillSpace">
                  <Input
                    label="Feature Name"
                    onChange={handleNameChange}
                    placeholder="Add a name for your feature."
                    type="text"
                    isDisabled={readOnly}
                    isRequired={true}
                    displayError={form.field('name').getErrors().hasError}
                    note={form.field('name').getErrors().message}
                    value={form.field('name').getValue()}
                    testSection="features-details-fields-name"
                  />
                </Col>
              </Row>
            ) : null
          }
        </OptimizelyFeature>
        <Row>
          <Col small="fillSpace">
            <Input
              label="Feature Key"
              placeholder="Enter a unique key for this feature."
              type="text"
              onChange={e =>
                form
                  .field('api_name')
                  .setValue(userFriendlyNameToKey(e.target.value))
              }
              isDisabled={readOnly}
              isRequired={true}
              hasSpellCheck={false}
              maxLength={FeatureConstants.MAX_API_NAME_LENGTH}
              displayError={form.field('api_name').getErrors().hasError}
              note={getKeyNote()}
              value={form.field('api_name').getValue()}
              testSection="features-details-fields-key"
            />
          </Col>
        </Row>
        <Row>
          <Col small="fillSpace">
            <Textarea
              isDisabled={readOnly}
              label="Description"
              placeholder="Describe this feature"
              type="text"
              onChange={e => form.field('description').setValue(e.target.value)}
              value={form.field('description').getValue()}
              testSection="features-details-fields-description"
            />
          </Col>
        </Row>
      </Container>
    </Fieldset>
  );
};

FeatureDetailsFields.defaultProps = {
  readOnly: false,
};

FeatureDetailsFields.propTypes = {
  /**
   * Current project
   * @access nuclear
   */
  currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
  /**
   * Current feature data
   * @access nuclear
   */
  feature: PropTypes.instanceOf(Immutable.Map),
  /**
   * Current feature flag id
   * @public
   */
  featureId: PropTypes.number,
  /**
   * Instance of the Form HOC
   * @public
   */
  form: formPropType.isRequired,
  /**
   * Current feature data
   * @access nuclear
   */
  isValidatingFeatureKey: PropTypes.bool.isRequired,
  /**
   * Determines if the set of fields is read-only
   * @public
   */
  readOnly: PropTypes.bool,
};

export default connect(({ featureId }) => ({
  currentProject: CurrentProjectGetters.project,
  feature: FeatureGetters.byId(featureId),
  isValidatingFeatureKey: LoadingGetters.isLoading('feature_key_validation'),
}))(FeatureDetailsFields);

/**
 * Higher Order Component: pageable
 *
 * Wraps a given component in a pass-through component that performs
 * pagination
 *
 * Example Usage:
 * Base component:  <DataTable data={someListOfData} /> <- renders some list of data
 *
 * To wrap in filtering functionality
 * const PageableDataTable = pageable(DataTable)
 *
 * props:
 *  - `data` Immutable.List of data to be paginated
 *  - `pageSize` number of items per page
 *  - `currentPage` (optional) where to start
 *
 * Decorates the wrapped component with the following props:
 *   - totalPages
 *   - data
 *   - renderControls
 */
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonRow, Button } from 'optimizely-oui';

import Immutable from 'optly/immutable';
import { connect } from 'core/ui/decorators';

import LoadingOverlay from 'react_components/loading_overlay';

import PageableActions from 'optly/modules/pageable/actions';
import PageableGetters from 'optly/modules/pageable/getters';

const PaginationControls = props => {
  const {
    canGoBack,
    canGoForward,
    currentPage,
    gotoNextPage,
    gotoPrevPage,
    gotoFirstPage,
    gotoLastPage,
    isLoading,
    totalPages,
  } = props;

  return (
    <LoadingOverlay isLoading={isLoading} size="small" className=" ">
      <ButtonRow
        centerGroup={[
          <Button
            key="1"
            style="outline"
            width="default"
            onClick={gotoFirstPage}
            isDisabled={!canGoBack}>
            First
          </Button>,
          <Button
            key="2"
            style="outline"
            width="default"
            onClick={gotoPrevPage}
            isDisabled={!canGoBack}>
            Back
          </Button>,
          <span key="pagenums" className="soft--sides">
            &nbsp;Page {currentPage} of {totalPages}&nbsp;
          </span>,
          <Button
            key="3"
            style="outline"
            width="default"
            onClick={gotoNextPage}
            isDisabled={!canGoForward}>
            Next
          </Button>,
          <Button
            key="4"
            style="outline"
            width="default"
            onClick={gotoLastPage}
            isDisabled={!canGoForward}>
            Last
          </Button>,
        ]}
      />
    </LoadingOverlay>
  );
};

PaginationControls.propTypes = {
  canGoBack: PropTypes.bool.isRequired,
  canGoForward: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  gotoFirstPage: PropTypes.func.isRequired,
  gotoLastPage: PropTypes.func.isRequired,
  gotoNextPage: PropTypes.func.isRequired,
  gotoPrevPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  totalPages: PropTypes.number.isRequired,
};

PaginationControls.defaultProps = {
  isLoading: false,
};

export default function(pageableId) {
  return function pageable(Component) {
    return connect(props => ({
      currentPage: PageableGetters.currentPage(pageableId),
    }))(
      class PageableContainer extends React.Component {
        static displayName = 'PageableContainer';

        static propTypes = {
          data: PropTypes.instanceOf(Immutable.List).isRequired,
          currentPage: PropTypes.number.isRequired,
          pageSize: PropTypes.number,
          pageableUrlHelper: PropTypes.func,
          onPageChange: PropTypes.func,
        };

        static defaultProps = {
          pageSize: 50,
        };

        computeData = () => {
          const { currentPage, pageSize, data } = this.props;
          const offset = this.computeOffset(currentPage);
          const canGoBack = currentPage > 1;

          const endingOffset = offset + pageSize;
          const canGoForward = endingOffset < data.size;

          const pageData = data.slice(offset, endingOffset);

          return {
            pageData,
            canGoBack,
            canGoForward,
          };
        };

        prevPage = () => {
          this.gotoPage(this.props.currentPage - 1);
        };

        nextPage = () => {
          this.gotoPage(this.props.currentPage + 1);
        };

        firstPage = () => {
          this.gotoPage(1);
        };

        lastPage = () => {
          this.gotoPage(this.computeTotalPages());
        };

        computeOffset = currentPage => (currentPage - 1) * this.props.pageSize;

        computeTotalPages = () => {
          const total = this.props.data.size;
          return Math.ceil(total / this.props.pageSize);
        };

        gotoPage = pageNumber => {
          if (this.isOutOfBounds(pageNumber, this.props.data.size)) {
            pageNumber = 1;
          }
          PageableActions.saveCurrentPage({
            id: pageableId,
            currentPage: pageNumber,
          });
          if (this.props.pageableUrlHelper) {
            PageableActions.replaceStateWithPageNumber(
              pageableId,
              this.props.pageableUrlHelper(),
            );
          }
          if (this.props.onPageChange) {
            this.props.onPageChange(pageNumber);
          }
        };

        isOutOfBounds = (currentPage, total) =>
          this.computeOffset(currentPage) >= total;

        getRenderControlsMethod = (canGoBack, canGoForward) => isLoading => (
          <PaginationControls
            canGoBack={canGoBack}
            canGoForward={canGoForward}
            currentPage={this.props.currentPage}
            gotoNextPage={this.nextPage}
            gotoPrevPage={this.prevPage}
            gotoFirstPage={this.firstPage}
            gotoLastPage={this.lastPage}
            isLoading={isLoading}
            totalPages={this.computeTotalPages()}
          />
        );

        render() {
          const { pageData, canGoBack, canGoForward } = this.computeData();

          const propsToPass = _.omit(this.props, [
            'onPageChange',
            'currentPage',
            'pageSize',
            'data',
          ]);

          return (
            <Component
              totalPages={this.computeTotalPages()}
              data={pageData}
              renderControls={this.getRenderControlsMethod(
                canGoBack,
                canGoForward,
              )}
              {...propsToPass}
            />
          );
        }
      },
    );
  };
}

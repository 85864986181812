import PropTypes from 'prop-types';
import React from 'react';

import { CodeDiff, Table, Icon } from 'optimizely-oui';
import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';

import ComponentModuleFns from '../component_module/fns';
import { FormatWarnings } from '../component_module/constants';

const ChangeEnumToReadableSourceMap = {
  ui: 'Optimizely App',
  api: 'Optimizely REST API',
};

const ChangesTableRowDetails = ({
  change,
  prettyDiffLineCap,
  shouldPrettifyDiff,
}) => {
  const [
    beforeApiBody,
    afterApiBody,
    formatWarningEnum,
  ] = ComponentModuleFns.computeApiBodyDiff(change.changes, {
    entityType: change.entity.type,
    prettyDiffLineCap,
    shouldPrettifyDiff,
  });

  const hasFormatWarning = !!formatWarningEnum;

  return (
    <Table.TR>
      <Table.TD
        testSection={`change-history-details-row-${change.id}`}
        colSpan={5}>
        <div className="push-half--left soft-double--left">
          <ul>
            {change.changes.map(subChange => {
              // TODO(APPX-913) Harden FE to always work reliably with human readable description pattern
              const defaultDescriptionRegex = new RegExp(
                `\\s${subChange.property}$`,
              );
              if (
                subChange.description &&
                subChange.description.match(defaultDescriptionRegex)
              ) {
                const splitDescription = subChange.description.split(
                  defaultDescriptionRegex,
                );
                const [actionDescription] = splitDescription;
                return (
                  <li
                    data-test-section={`change-history-default-detail-change-${subChange.property}`}
                    key={`change-default-detail-${subChange.id}-${subChange.property}`}>
                    {actionDescription}{' '}
                    <span className="monospace">{subChange.property}</span>
                  </li>
                );
              }
              return (
                <li
                  data-test-section={`change-history-custom-detail-change-${subChange.property}`}
                  key={`change-custom-detail-${subChange.property}`}>
                  {subChange.description}
                </li>
              );
            })}
          </ul>
          <div className="push--top">
            <CodeDiff
              disableWordDiff={hasFormatWarning}
              header={
                <div className="milli flex">
                  <div className="flex--1">
                    {hasFormatWarning ? (
                      <div className="flex">
                        <div className="flex--dead-center push-half--right">
                          <Icon
                            color={brandBlueDark}
                            name="circle-exclamation"
                            size="small"
                          />
                        </div>
                        {FormatWarnings[formatWarningEnum].readable}
                      </div>
                    ) : null}
                  </div>
                  <div
                    data-test-section={`change-readable-source-${change.id}`}>
                    Change made via{' '}
                    {ChangeEnumToReadableSourceMap[change.source] ||
                      ChangeEnumToReadableSourceMap.ui}
                  </div>
                </div>
              }
              hideLineNumbers={false}
              newValue={afterApiBody}
              oldValue={beforeApiBody}
              testSection="change-history-detail-change-diff"
            />
          </div>
        </div>
      </Table.TD>
    </Table.TR>
  );
};

ChangesTableRowDetails.propTypes = {
  change: PropTypes.object.isRequired,
  prettyDiffLineCap: PropTypes.number.isRequired,
  shouldPrettifyDiff: PropTypes.bool.isRequired,
};

export default ChangesTableRowDetails;

import keyMirror from 'optly/utils/key_mirror';
import { constants as FeatureConstants } from 'optly/modules/entity/feature_flag';
import ProjectEnums from 'optly/modules/entity/project/enums';

const FEATURE_CODE_BLOCK_AGENT = `curl -X POST \\
  '{HOST_URL}/v1/activate?featureKey=%s' \\
  -H 'Content-Type: application/json' \\
  -H 'X-Optimizely-SDK-Key: "YOUR-SDK-KEY"' \\
  -d '{
    "userId":"USER_ID",
    "attributes": {}
  }'
`;

export const tabs = keyMirror({
  RULES: null,
  VARIABLES: null,
  EXPERIMENT_USAGE: null,
  SETTINGS: null,
  HISTORY: null,
  SDK_SETUP: null,
});

export const variableCodeBlocks = {
  [ProjectEnums.sdkLanguages.PYTHON]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      "  %s = optimizely_client.get_feature_variable('%s', '%s', user_id, attributes)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      "  %s = optimizely_client.get_feature_variable('%s', '%s', user_id, attributes)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      "  %s = optimizely_client.get_feature_variable('%s', '%s', user_id, attributes)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      "  %s = optimizely_client.get_feature_variable('%s', '%s', user_id, attributes)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      "  %s = optimizely_client.get_feature_variable_json('%s', '%s', user_id, attributes)",
  },
  [ProjectEnums.sdkLanguages.RUBY]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      "  %s = optimizely_client.get_feature_variable('%s', '%s', user_id, attributes)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      "  %s = optimizely_client.get_feature_variable('%s', '%s', user_id, attributes)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      "  %s = optimizely_client.get_feature_variable('%s', '%s', user_id, attributes)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      "  %s = optimizely_client.get_feature_variable('%s', '%s', user_id, attributes)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      "  %s = optimizely_client.get_feature_variable_json('%s', '%s', user_id, attributes)",
  },
  [ProjectEnums.sdkLanguages.JAVASCRIPT]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      "var %s = optimizelyClientInstance.getFeatureVariable('%s', '%s', userId, attributes);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      "var %s = optimizelyClientInstance.getFeatureVariable('%s', '%s', userId, attributes);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      "var %s = optimizelyClientInstance.getFeatureVariable('%s', '%s', userId, attributes);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      "var %s = optimizelyClientInstance.getFeatureVariable('%s', '%s', userId, attributes);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      "var %s = optimizelyClientInstance.getFeatureVariableJSON('%s', '%s', userId, attributes);",
  },
  [ProjectEnums.sdkLanguages.JAVA]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      '  Boolean %s = optimizelyClient.getFeatureVariableBoolean("%s", "%s", userId, attributes);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      '  Double %s = optimizelyClient.getFeatureVariableDouble("%s", "%s", userId, attributes);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      '  Integer %s = optimizelyClient.getFeatureVariableInteger("%s", "%s", userId, attributes);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      '  String %s = optimizelyClient.getFeatureVariableString("%s", "%s", userId, attributes);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      '  OptimizelyJSON %s = optimizelyClient.getFeatureVariableJSON("%s", "%s", userId, attributes);',
  },
  [ProjectEnums.sdkLanguages.PHP]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      "  $%s = $optimizelyClient->getFeatureVariableBoolean('%s', '%s', $userId, $attributes);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      "  $%s = $optimizelyClient->getFeatureVariableDouble('%s', '%s', $userId, $attributes);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      "  $%s = $optimizelyClient->getFeatureVariableInteger('%s', '%s', $userId, $attributes);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      "  $%s = $optimizelyClient->getFeatureVariableString('%s', '%s', $userId, $attributes);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      "  $%s = $optimizelyClient->getFeatureVariableJSON('%s', '%s', $userId, $attributes);",
  },
  [ProjectEnums.sdkLanguages.CSHARP]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      '  var %s = optimizelyClientInstance.GetFeatureVariableBoolean("%s", "%s", userId, attributes);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      '  var %s = optimizelyClientInstance.GetFeatureVariableDouble("%s", "%s", userId, attributes);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      '  var %s = optimizelyClientInstance.GetFeatureVariableInteger("%s", "%s", userId, attributes);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      '  var %s = optimizelyClientInstance.GetFeatureVariableString("%s", "%s", userId, attributes);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      '  var %s = optimizelyClientInstance.GetFeatureVariableJSON("%s", "%s", userId, attributes);',
  },
  [ProjectEnums.sdkLanguages.OBJECTIVE_C]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      'NSNumber *%s = [optimizelyClient getFeatureVariableBooleanWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      'NSNumber *%s = [optimizelyClient getFeatureVariableDoubleWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      'NSNumber *%s = [optimizelyClient getFeatureVariableIntegerWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      'NSString *%s = [optimizelyClient getFeatureVariableStringWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      'OptimizelyJSON *%s = [optimizelyClient getFeatureVariableJSONWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
  },
  [ProjectEnums.sdkLanguages.SWIFT]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      '      let %s = try optimizelyClient?.getFeatureVariableBoolean(featureKey: "%s", variableKey: "%s", userId: userId, attributes: attributes)',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      '      let %s = try optimizelyClient?.getFeatureVariableDouble(featureKey: "%s", variableKey: "%s", userId: userId, attributes: attributes)',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      '      let %s = try optimizelyClient?.getFeatureVariableInteger(featureKey: "%s", variableKey: "%s", userId: userId, attributes: attributes)',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      '      let %s = try optimizelyClient?.getFeatureVariableString(featureKey: "%s", variableKey: "%s", userId: userId, attributes: attributes)',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      '      let %s = try optimizelyClient?.getFeatureVariableJSON(featureKey: "%s", variableKey: "%s", userId: userId, attributes: attributes)',
  },
  [ProjectEnums.sdkLanguages.GO]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      '\t%s, err := optimizelyClient.GetFeatureVariableBoolean("%s", "%s", userContext)',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      '\t%s, err := optimizelyClient.GetFeatureVariableDouble("%s", "%s", userContext)',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      '\t%s, err := optimizelyClient.GetFeatureVariableInteger("%s", "%s", userContext)',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      '\t%s, err := optimizelyClient.GetFeatureVariableString("%s", "%s", userContext)',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      '\t%s, err := optimizelyClient.GetFeatureVariableJSON("%s", "%s", userContext)',
  },
  [ProjectEnums.sdkLanguages.REACT]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]: [
      "            const %1$s = variables['%3$s'];",
    ],
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]: [
      "            const %1$s = variables['%3$s'];",
    ],
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]: [
      "            const %1$s = variables['%3$s'];",
    ],
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]: [
      "            const %1$s = variables['%3$s'];",
    ],
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]: [
      "            const %1$s = variables['%3$s'];",
    ],
  },
  [ProjectEnums.sdkLanguages.AGENT]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]: '',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]: '',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]: '',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]: '',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]: '',
  },
};

export const featureEnabledCodeBlocks = {
  [ProjectEnums.sdks.PYTHON]: [
    '# Check if feature is enabled for user',
    'attributes = {',
    '    # Optional example attributes',
    "    'location': 'california',",
    "    'isAdmin': True",
    '}',
    '',
    "is_enabled = optimizely_client.is_feature_enabled('%s', user_id, attributes)",
  ],
  [ProjectEnums.sdks.RUBY]: [
    '# Check if feature is enabled for user',
    'attributes = {',
    '    # Optional example attributes',
    "    'location' => 'california',",
    "    'isAdmin' => true",
    '}',
    '',
    "is_enabled = optimizely_client.is_feature_enabled('%s', user_id, attributes)",
  ],
  [ProjectEnums.sdks.JAVASCRIPT]: [
    '// Check if feature is enabled for user',
    'var attributes = {',
    '  // Optional example attributes',
    '  location: "california",',
    '  isAdmin: true,',
    '};',
    '',
    "var isEnabled = optimizelyClientInstance.isFeatureEnabled('%s', userId, attributes);",
  ],
  [ProjectEnums.sdks.NODE]: [
    '// Check if feature is enabled for user',
    "var isEnabled = optimizelyClient.isFeatureEnabled('%s', userId);",
  ],
  [ProjectEnums.sdks.JAVA]: [
    '// Check if feature is enabled for user',
    'Map<String, ?> attributes = new HashMap<String, Object>() {',
    '    {',
    '        // Optional example attributes',
    '        put("location", "california");',
    '        put("isAdmin", true);',
    '    }',
    '};',
    '',
    'Boolean isEnabled = optimizelyClient.isFeatureEnabled("%s", userId, attributes);',
  ],
  [ProjectEnums.sdks.PHP]: [
    '// Check if feature is enabled for user',
    '$attributes = [',
    '    "location" => "california",',
    '    "isAdmin" => true',
    '];',
    '',
    "$isEnabled = $optimizelyClient->isFeatureEnabled('%s', $userId, $attributes);",
  ],
  [ProjectEnums.sdks.CSHARP]: [
    'var attributes = new UserAttributes',
    '{',
    '    {"location", "California" },',
    '    {"isAdmin", true }',
    '};',
    '',
    'var isEnabled = optimizelyClientInstance.IsFeatureEnabled("%s", userId, attributes);',
  ],
  [ProjectEnums.sdkLanguages.OBJECTIVE_C]: [
    '// Check if feature is enabled for user',
    'BOOL enabled = [optimizelyClient isFeatureEnabledWithFeatureKey:@"%s" userId: userId attributes: nil];',
  ],
  [ProjectEnums.sdkLanguages.SWIFT]: [
    '// Check if feature is enabled for user',
    'let attributes: OptimizelyAttributes = [',
    '    // Optional example attributes',
    '    "location": "california",',
    '    "isAdmin": true',
    ']',
    '',
    'if let isEnabled = optimizelyClient?.isFeatureEnabled(featureKey: "%s", userId: userId, attributes: attributes), isEnabled {',
  ],
  [ProjectEnums.sdks.GO]: [
    'attributes := map[string]interface{}{',
    '\t// Optional example attributes',
    '\t"location": "california",',
    '\t"isAdmin":  true,',
    '}',
    'userContext := entities.UserContext{',
    '\tID:         userID,',
    '\tAttributes: attributes,',
    '}',
    '',
    'isEnabled, err := optimizelyClientInstance.IsFeatureEnabled("%s", userContext)',
  ],
  [ProjectEnums.sdkLanguages.REACT]: [
    'const attributes = {',
    '  // Optional example attributes',
    '  location: "california",',
    '  isAdmin: true,',
    '};',
    '',
    'class App extends React.Component {',
    '  render() {',
    '    return (',
    '      <OptimizelyProvider',
    '        optimizely={optimizelyClientInstance}',
    '        timeout={500}',
    '        user={{ id: userId, attributes }}',
    '      >',
    '        <OptimizelyFeature feature="%s">',
    '          {(isEnabled, variables) => {',
  ],
  [ProjectEnums.sdkLanguages.AGENT]: FEATURE_CODE_BLOCK_AGENT,
};

export const showFeatureCodeBlocks = {
  [ProjectEnums.sdks.PYTHON]: [
    '',
    'if is_enabled:',
    '%s',
    '    # Implement your feature',
  ],
  [ProjectEnums.sdks.RUBY]: [
    '',
    'if is_enabled',
    '%s',
    '    # Implement your feature',
    'end',
  ],
  [ProjectEnums.sdks.JAVASCRIPT]: [
    '',
    'if (isEnabled) {',
    '%s',
    '  // Implement your feature',
    '}',
  ],
  [ProjectEnums.sdks.NODE]: [
    '\n',
    'if (isEnabled) {',
    '%s',
    '  // Show your feature',
    '}',
  ],
  [ProjectEnums.sdks.JAVA]: [
    '',
    'if (isEnabled) {',
    '%s',
    '    // Implement your feature',
    '}',
  ],
  [ProjectEnums.sdks.PHP]: [
    '',
    'if($isEnabled) {',
    '%s',
    '    // Implement your feature',
    '}',
  ],
  [ProjectEnums.sdks.CSHARP]: [
    '',
    'if (isEnabled)',
    '%s',
    '    // Implement your feature',
    '}',
  ],
  [ProjectEnums.sdkLanguages.OBJECTIVE_C]: [
    '',
    'if (enabled) {',
    '%s',
    '  // Show your feature',
    '}',
  ],
  [ProjectEnums.sdkLanguages.SWIFT]: [
    '    do {',
    '%s',
    '    } catch {',
    '        print(error.localizedDescription)',
    '    }',
    '',
    '    // Implement your feature',
    '}',
  ],
  [ProjectEnums.sdks.GO]: [
    '',
    'if isEnabled {',
    '%s',
    '\t// Implement your feature',
    '}',
  ],
  [ProjectEnums.sdkLanguages.REACT]: [
    '            if (isEnabled) {',
    '%s',
    '              // Implement your feature',
    '              return <p>Feature enabled! Variable values: {JSON.stringify(variables)}</p>;',
    '            }',
    '          }}',
    '        </OptimizelyFeature>',
    '      </OptimizelyProvider>',
    '    );',
    '  }',
    '}',
  ],
};

export const CAMEL_CASE_LANGS = [
  ProjectEnums.sdkLanguages.CSHARP,
  ProjectEnums.sdkLanguages.GO,
  ProjectEnums.sdkLanguages.JAVA,
  ProjectEnums.sdkLanguages.JAVASCRIPT,
  ProjectEnums.sdkLanguages.OBJECTIVE_C,
  ProjectEnums.sdkLanguages.PHP,
  ProjectEnums.sdkLanguages.REACT,
  ProjectEnums.sdkLanguages.SWIFT,
];

export default {
  CAMEL_CASE_LANGS,
  featureEnabledCodeBlocks,
  showFeatureCodeBlocks,
  tabs,
  variableCodeBlocks,
};

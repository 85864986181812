import flux from 'core/flux';

import HistoryStores from 'core/history/stores';
import LoadingStores from 'core/modules/loading/stores';
import UiStores from 'core/ui/stores';

export const stores = {
  ...HistoryStores,
  ...LoadingStores,
  ...UiStores,
};

export default function initalize() {
  flux.registerStores(stores);
}

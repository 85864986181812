import _ from 'lodash';

import cloneDeep from 'optly/clone_deep';
import Immutable, { toImmutable, isImmutable } from 'optly/immutable';
import guid from 'optly/utils/guid';
import { fns as AudienceFns } from 'optly/modules/entity/audience';
import * as LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';

import constants from './constants';
/**
 * Create actions for a new variation for each view.
 * @param {Immutable.Map} variation
 * @param {Immutable.List} selectedViewIds
 * @return {Immutable.Map} updated variation
 */
export function createActionsForNewVariation(variation, selectedViewIds) {
  return variation.set(
    'actions',
    selectedViewIds.map(viewId =>
      toImmutable({
        view_id: viewId,
        changes: [],
      }),
    ),
  );
}

/**
 * Delete actions if they don't correspond with a selected View Id
 * @param {Immutable.List} actions
 * @param {Immutable.List} selectedViewIds
 * @returns {Immutable.List} updated actions
 */
export function deleteActionsForVariationIfNoViewId(actions, selectedViewIds) {
  const existingViewIdsInActions = actions.map(action => action.get('view_id'));
  const viewIdsToDelete = existingViewIdsInActions
    .toSet()
    .subtract(selectedViewIds);
  return actions.filter(action => !viewIdsToDelete.has(action.get('view_id')));
}

/**
 * Returns updated variations with correct properties for the API to consume.
 * @param {Immutable.List} selectedViewIds
 * @param {Immutable.List<LayerVariation>} experimentVariations
 * @return {Immutable.List} updatedVariations
 */
export function updateVariationsForSave(selectedViewIds, experimentVariations) {
  return experimentVariations.map(variation => {
    // Convert the percentage into a weight field.
    if (variation.has('percentage')) {
      variation = variation.set(
        'weight',
        Number((variation.get('percentage') * 100).toFixed()),
      );
    }
    // Delete the guid, which the API does not want.
    variation = variation.delete('guid');

    // If there are no actions, then create an actions list for the variations.
    if (!variation.has('actions')) {
      variation = createActionsForNewVariation(variation, selectedViewIds);
    }

    // Finally, update the actions list with view_ids.
    variation = variation.update('actions', actions => {
      const actionsWithRemovedViews = deleteActionsForVariationIfNoViewId(
        actions,
        selectedViewIds,
      );
      const existingViewIds = actionsWithRemovedViews.map(action =>
        action.get('view_id'),
      );
      const viewIdsToAdd = selectedViewIds.toSet().subtract(existingViewIds);

      return actionsWithRemovedViews.withMutations(mutableActions => {
        viewIdsToAdd.forEach(viewId => {
          mutableActions.push(
            toImmutable({
              view_id: viewId,
              changes: [],
            }),
          );
        });
      });
    });
    return variation;
  });
}

/**
 * Outputs an array of view_ids based on the actions inside the variations
 * @param {Immutable.List} variations
 * @returns {Array}
 */
export function getViewIdsFromVariationActions(variations) {
  const viewIds = Immutable.Set().withMutations(mutableViewIds => {
    variations.forEach(variation => {
      variation.get('actions').forEach(action => {
        mutableViewIds.add(action.get('view_id'));
      });
    });
  });
  return viewIds.toList().toJS();
}

/**
 * Given a single entity, add a guid property
 * @param {Object} entity
 * @returns {Object}
 */
export function guidifySingle(entity) {
  const updatedEntity = cloneDeep(entity);
  updatedEntity.guid = guid();
  return updatedEntity;
}

/**
 * Given a single entity, remove its guid property
 * @param {Object} entity
 * @returns {Object}
 */
export function deguidifySingle(entity) {
  delete entity.guid;
  return entity;
}

/**
 * Given a list of entities, gives each item in the list a guid.
 * @param {Array} entityList
 * @returns {Array}
 */
export function guidifyList(entityList) {
  return entityList.map(entity => this.guidifySingle(entity));
}

/**
 * Given a list of entities, removes all guids from the entities.
 * @param {Array} entityList
 * @returns {Array}
 */
export function deguidifyList(entityList) {
  return entityList.map(entity => this.deguidifySingle(entity));
}

/**
 * Creates a new section, complete with guids and a name calculated based on the number
 * of sections
 * @param {Number} sectionsSize
 * @returns {Immutable.Map} updatedSection
 */
export function createNewSection(sectionsSize) {
  const newSection = cloneDeep(constants.Sections.DEFAULT_SECTION);
  newSection.guid = guid();
  // adds guids to the variations
  newSection.variations = this.guidifyList(newSection.variations);
  if (sectionsSize) {
    newSection.name = `Section #${sectionsSize + 1}`;
  }
  return toImmutable(newSection);
}

/**
 * Gets the number of combinations that the sections will be combined into
 * @param {Immutable.List} sections
 * @returns {number} combination count
 */
export function getCombinationCount(sections) {
  return sections.size
    ? sections.reduce(
        (total, section) => total * section.get('variations').size,
        1,
      )
    : 0;
}

/**
 * Serializes some audiences + an audienceMatchType to prettified JSON
 * @param {Array} audienceIds
 * @param {Audience.enums.audienceMatchTypes} audienceMatchType
 * @returns {string}
 */
export function matcherToJson(audienceIds, audienceMatchType) {
  const matcherObj = AudienceFns.constructAnyAllAudienceConditions(
    audienceIds,
    audienceMatchType,
  );
  return matcherObj === null ? '' : JSON.stringify(matcherObj, null, 2);
}

/**
 * Adds total traffic and percentage values to each variation
 * @param {Immutable.List} variations
 * @param {Number} layerHoldback
 * @returns {Immutable.List}
 */
export function addTrafficDistributionInfoToVariations(
  variations,
  layerHoldback,
) {
  return variations.map(variation => {
    const availableTraffic = 10000 - layerHoldback;
    // The total traffic for a variation is its share of the available traffic. Since the holdback and weight inputs are
    // filtered before being saved, it is expected that 0 <= totalTraffic < 1000.
    const totalTraffic = (availableTraffic / 10000) * variation.get('weight');
    return variation
      .set('percentage', Math.round(variation.get('weight')) / 100)
      .set('totalTraffic', Math.round(totalTraffic) / 100);
  });
}

export function stripVariableValuesFromVariations(variations) {
  return variations.map(variation => variation.delete('variable_values'));
}

export function addVariableValuesToVariations(variations, usages) {
  return variations.map(variation => {
    const variableValues = Immutable.Map().withMutations(map => {
      usages.forEach(usage => {
        const targetInstance = usage
          .get('usage_instances')
          .find(
            instance =>
              instance.get('variation_id') === variation.get('variation_id'),
          );
        map.set(usage.get('live_variable_id'), targetInstance.get('value'));
      });
    });

    return variation.set('variable_values', variableValues);
  });
}

/**
 * Returns true if the Experiment in primary environment is either "running" or "paused".
 * @param  {Object|Immutable.Map} experiment
 * @return {boolean}
 */
export function isExperimentLive(experiment) {
  const actualStatus = isImmutable(experiment)
    ? experiment.get('actual_status')
    : experiment.actual_status;

  return (
    actualStatus === LayerExperimentEnums.ActualStatus.RUNNING ||
    actualStatus === LayerExperimentEnums.ActualStatus.PAUSED
  );
}

/**
 * Returns true if the Experiment in primary environment is "running"
 * @param  {Object|Immutable.Map} experiment
 * @return {boolean}
 */
export function isExperimentRunning(experiment) {
  const actualStatus = isImmutable(experiment)
    ? experiment.get('actual_status')
    : experiment.actual_status;

  return actualStatus === LayerExperimentEnums.ActualStatus.RUNNING;
}

/**
 * Returns true if the Experiment in primary environment is "paused".
 * @param  {Object|Immutable.Map} experiment
 * @return {boolean}
 */
export function isExperimentPaused(experiment) {
  const actualStatus = isImmutable(experiment)
    ? experiment.get('actual_status')
    : experiment.actual_status;

  return actualStatus === LayerExperimentEnums.ActualStatus.PAUSED;
}

/**
 * Returns true if the Experiment in any environment is either "running" or "paused".
 * @param  {Object|Immutable.Map} experiment
 * @return {boolean}
 */
export function isExperimentStarted(experiment) {
  const environments = isImmutable(experiment)
    ? experiment.get('environments')
    : experiment.environments;

  const hasStartedStatus = Object.values(environments).some(environment =>
    [
      LayerExperimentEnums.ActualStatus.RUNNING,
      LayerExperimentEnums.ActualStatus.PAUSED,
    ].includes(environment.status),
  );
  return hasStartedStatus;
}

export default {
  createActionsForNewVariation,
  deleteActionsForVariationIfNoViewId,
  updateVariationsForSave,
  getViewIdsFromVariationActions,
  guidifySingle,
  deguidifySingle,
  guidifyList,
  deguidifyList,
  createNewSection,
  getCombinationCount,
  matcherToJson,
  addTrafficDistributionInfoToVariations,
  stripVariableValuesFromVariations,
  addVariableValuesToVariations,
  isExperimentLive,
  isExperimentRunning,
  isExperimentPaused,
  isExperimentStarted,
};

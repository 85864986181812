import React from 'react';

import { Link } from 'optimizely-oui';

const COMMUNITY_LINK = 'https://community.optimizely.com/';
const DEVELOPERS_LINK = 'https://developers.optimizely.com/';

const WelcomeSectionFooter = () => (
  <div
    className="background--white border--top"
    data-test-section="welcome-section-footer">
    <div className="max-width--large soft-quad--ends anchor--middle">
      <div className="push-double--sides">
        <h2 className="push--bottom">Developer Support</h2>
        <p>
          If you have any questions or need help, be sure to visit{' '}
          <Link href={DEVELOPERS_LINK} newWindow={true}>
            developers.optimizely.com
          </Link>{' '}
          or contact Optimizely&apos;s developer support team using the help
          menu in any project you create. You can also post in our Optiverse{' '}
          <Link href={COMMUNITY_LINK} newWindow={true}>
            developer discussion group
          </Link>
          . We&apos;re always happy and eager to hear your feedback.
        </p>
      </div>
    </div>
  </div>
);

export default WelcomeSectionFooter;

import enums from './enums';
import * as getters from './getters';

const actions = require('./actions');
const fns = require('./fns');

// named exports
export { actions, enums, fns, getters };

// default export
export default {
  actions,
  enums,
  fns,
  getters,
};

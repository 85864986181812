/**
 * Getters for p13n dashboard.
 */
const CurrentProject = require('optly/modules/current_project');

const activeAudiences = [
  CurrentProject.getters.audiences,
  audiences =>
    audiences.filter(
      audience => !audience.get('archived') && audience.get('user_touched'),
    ),
];

const activeAndNonSingleUseViews = [
  CurrentProject.getters.views,
  views =>
    views.filter(view => !view.get('archived') && !view.get('single_use')),
];

module.exports = {
  activeAudiences,
  activeAndNonSingleUseViews,
};

import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';
import constants from '../constants';

export default Store({
  getInitialState() {
    return toImmutable({
      experimentId: null,
      activeTab: constants.Tabs.VARIATIONS,
    });
  },

  initialize() {
    this.on(actionTypes.OASIS_SET_CURRENTLY_EDITING_EXPERIMENT_ID, setId);
    this.on(actionTypes.OASIS_SET_CURRENTLY_EDITING_EXPERIMENT_TAB, setTab);
  },
});

function setId(state, { experimentId }) {
  return state.set('experimentId', experimentId);
}

function setTab(state, { tab }) {
  return state.set('tab', tab);
}

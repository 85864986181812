import EnvironmentActions from './actions';
import EnvironmentEntityDef from './entity_definition';
import EnvironmentFns from './fns';
import EnvironmentGetters from './getters';

export const actions = EnvironmentActions;
export const entityDef = EnvironmentEntityDef;
export const fns = EnvironmentFns;
export const getters = EnvironmentGetters;

export default {
  actions,
  entityDef,
  fns,
  getters,
};

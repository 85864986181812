import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Icon } from '@optimizely/axiom';

import dropdownService from 'optly/services/dropdown';

export class Dropdown extends React.Component {
  static propTypes = {
    activator: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    testSection: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    zIndex: PropTypes.number,
  };

  static childContextTypes = {
    toggleDropdown: PropTypes.func,
    hideDropdown: PropTypes.func,
  };

  static defaultProps = {
    testSection: '',
    width: null,
    zIndex: null,
  };

  getChildContext() {
    return {
      toggleDropdown: () => {
        dropdownService.toggle.call(dropdownService, this.dropdown);
      },
      hideDropdown: () => {
        dropdownService.hide.call(dropdownService, this.dropdown);
      },
    };
  }

  setDropdownRef = el => {
    this.dropdown = el;
  };

  render() {
    const { width, zIndex, testSection, activator, children } = this.props;
    return (
      <div
        data-ui-component={true}
        ref={this.setDropdownRef}
        className="lego-dropdown-group"
        style={{ width, zIndex }}
        data-test-section={testSection}>
        <DropdownActivator>{activator}</DropdownActivator>
        {children}
      </div>
    );
  }
}

class DropdownActivator extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    // loop through children and add props to them
    // we need this so that we can pass the context props to the children
    // passed into the activator
    const { children } = this.props;
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        // trigger the dropdown if the child element is clicked on but don't lose the existing onClick if it exists
        onClick: event => {
          const { toggleDropdown } = this.context;
          if (child && child.props && child.props.onClick) {
            child.props.onClick(event);
          }
          toggleDropdown();
        },
      }),
    );
    return <div>{childrenWithProps}</div>;
  }
}

DropdownActivator.contextTypes = {
  toggleDropdown: PropTypes.func,
};

export class DropdownContents extends React.Component {
  static propTypes = {
    canScroll: PropTypes.bool,

    children: PropTypes.node,

    customStyles: PropTypes.object,

    direction: PropTypes.oneOf(['left', 'right', 'up']),

    isNoWrap: PropTypes.bool,

    minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    canScroll: true,
    children: null,
    customStyles: {},
    direction: 'left',
    isNoWrap: false,
    minHeight: '',
    minWidth: '200px',
  };

  render() {
    const {
      minWidth,
      minHeight,
      canScroll,
      isNoWrap,
      direction,
      customStyles,
      children,
    } = this.props;

    const styleProps = {
      ...(minWidth && { minWidth }),
      ...(minHeight && { minHeight }),
      ...(canScroll && { overflowY: 'auto', maxHeight: 'auto' }),
      ...(!canScroll && !minHeight && { minHeight: 'fit-content' }),
    };

    const classes = classNames({
      nowrap: isNoWrap,
      'lego-dropdown': true,
      'lego-dropdown--right': direction === 'left',
      'lego-dropdown--left': direction === 'right',
      'lego-dropdown--up': direction === 'up',
    });

    const styles = { ...styleProps, ...customStyles };

    return (
      <ul className={classes} style={styles}>
        {children}
      </ul>
    );
  }
}

export class DropdownListItem extends React.Component {
  static defaultProps = {
    children: null,
    hardSides: false,
    hardTop: false,
    hideOnClick: false,
  };

  static propTypes = {
    children: PropTypes.node,
    hardSides: PropTypes.bool,
    hardTop: PropTypes.bool,
    hideOnClick: PropTypes.bool,
  };

  static contextTypes = {
    hideDropdown: PropTypes.func,
  };

  onClick = () => {
    const { hideOnClick } = this.props;
    const { hideDropdown } = this.context;
    if (hideOnClick) {
      hideDropdown();
    }
  };

  render() {
    const { hardSides, hardTop, children } = this.props;
    const classes = classNames({
      'hard--sides': hardSides,
      'hard--top': hardTop,
      'lego-dropdown__item': true,
    });

    return (
      <li // eslint-disable-line
        className={classes}
        onClick={this.onClick}>
        {children}
      </li>
    );
  }
}

export class DropdownBlockLink extends React.Component {
  static defaultProps = {
    hideOnClick: false,
    /* eslint-disable react/default-props-match-prop-types */
    isLink: true,
    /* eslint-enable react/default-props-match-prop-types */
    isIndented: false,
    isDisabled: false,
    isDestructive: false,
    minWidth: '200px',
    testSection: null,
    trackId: null,
  };

  static propTypes = {
    /** Content to be shown in the menu option */
    children: PropTypes.node.isRequired,
    /** should hide when clicking a link */
    hideOnClick: PropTypes.bool,
    /** Should destructive styles be applied to link (for example, 'Delete' actions) */
    isDestructive: PropTypes.bool,
    /** Should disabled styles be applied to link */
    isDisabled: PropTypes.bool,
    /** Indent dropdown item for nesting */
    isIndented: PropTypes.bool,
    /** should this be a link or nonclickable text */
    /* eslint-disable react/require-default-props */
    isLink: PropTypes.bool.isRequired,
    /* eslint-enable react/require-default-props */
    /** Minimum width of the list item, useful if you need to have a block of description text */
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Click handler for the menu option */
    onClick: PropTypes.func.isRequired,
    /** Used for data-test-section attribute on the link */
    testSection: PropTypes.string,
    /** Used for data-track-id attribute on the link */
    trackId: PropTypes.string,
  };

  static contextTypes = {
    hideDropdown: PropTypes.func,
  };

  onClick = () => {
    const { hideOnClick, onClick } = this.props;
    const { hideDropdown } = this.context;
    onClick();
    if (hideOnClick) {
      hideDropdown();
    }
  };

  render() {
    const {
      minWidth,
      isLink,
      isIndented,
      isDisabled,
      isDestructive,
      testSection,
      trackId,
      children,
    } = this.props;
    const styleProps = {};
    if (minWidth) {
      styleProps.minWidth = minWidth;
    }
    return (
      <div
        role="link"
        tabIndex="0"
        className={classNames({
          link: isLink,
          isSelected: !isLink,
          'lego-dropdown__block-link': isLink,
          'lego-dropdown__block-link--indented': isIndented,
          'lego-dropdown_block-link--disabled': isDisabled,
          'lego-dropdown_block-link--destructive': isDestructive,
        })}
        style={styleProps}
        data-test-section={testSection}
        data-track-id={trackId}
        onClick={this.onClick}
        onKeyDown={this.onClick}>
        {children}
      </div>
    );
  }
}

export class DropdownBlockLinkText extends React.Component {
  static defaultProps = {
    testSection: null,
    text: null,
  };

  static propTypes = {
    /** test section from parent */
    testSection: PropTypes.string,
    /** text, if provided */
    text: PropTypes.string,
  };

  render() {
    const { testSection, text } = this.props;
    return (
      <span data-test-section={`block-link-text-${testSection}`}>{text}</span>
    );
  }
}

export class DropdownBlockLinkSecondaryText extends React.Component {
  static defaultProps = {
    isWarning: false,
    secondaryText: null,
    testSection: null,
  };

  static propTypes = {
    /** should show info icon */
    isWarning: PropTypes.bool,
    /** description text, if provided */
    secondaryText: PropTypes.string,
    /** test section from parent */
    testSection: PropTypes.string,
  };

  render() {
    const { isWarning, testSection, secondaryText } = this.props;
    return (
      <div className="lego-dropdown--descriptive__content flex">
        {isWarning && (
          <Icon
            name="circle-exclamation"
            size="small"
            className="oui-icon push--right flex--none"
          />
        )}
        <div data-test-section={`block-link-description-${testSection}`}>
          {secondaryText}
        </div>
      </div>
    );
  }
}

export default {
  Dropdown,
  DropdownContents,
  DropdownListItem,
  DropdownBlockLink,
  DropdownBlockLinkText,
  DropdownBlockLinkSecondaryText,
};

import Immutable from 'immutable';

import { createEntityGetters } from 'optly/modules/rest_api';

import definition from './entity_definition';

const getters = createEntityGetters(definition);

/**
 * @description entity the first item from an entityList
 * @returns {Immutable.Map}
 * @returns {array} - flux getter pipeline
 * @function
 */
const first = [
  getters.entityCache,
  entities => entities.first() || Immutable.Map({}),
];

/**
 * @member actions
 * @memberof entity/terms_of_service
 */
export default {
  ...getters,
  first,
};

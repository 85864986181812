import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

// alternate entity definition for account level events
const accountLevelActions = RestApi.createEntityActions({
  entity: definition.entity,
});

export const {
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
  save,
} = baseEntityActions;

/**
 * Alternate fetch all that works at the account level for shared projects
 * @param {Object?} filters
 * @param {Boolean?} force
 * @return {Deferred}
 */
export function fetchAllForAccount(filters, force) {
  return accountLevelActions.fetchAll(filters, force);
}

/**
 * Archives a metric
 * @param {MetricTemplate} metric
 * @return {Deferred}
 */
export function archive(metric) {
  return this.save({
    id: metric.id,
    archived: true,
  });
}

/**
 * Unarchives a metric
 * @param {MetricTemplate} metric
 * @return {Deferred}
 */
export function unarchive(metric) {
  return this.save({
    id: metric.id,
    archived: false,
  });
}

export default {
  ...baseEntityActions,
  fetchAllForAccount,
  archive,
  unarchive,
};

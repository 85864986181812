import PropTypes from 'prop-types';
import React from 'react';

import ReactCodeMirror from 'react_components/codemirror';

function RedirectFnInput({ destFnValue, onValueUpdated }) {
  const esOptions = { esversion: 6 };
  return (
    <div>
      <p className="push--bottom">Use this function to return the URL.</p>
      <ReactCodeMirror
        onChange={onValueUpdated}
        options={{
          lineWrapping: false,
          lint: esOptions,
        }}
        testSection="redirect-editor-dest-input"
        value={destFnValue}
      />
    </div>
  );
}

RedirectFnInput.propTypes = {
  destFnValue: PropTypes.string,
  onValueUpdated: PropTypes.func.isRequired,
};

RedirectFnInput.defaultProps = {
  destFnValue: '',
};

export default RedirectFnInput;

const _ = require('lodash');
const $ = require('jquery');

const LABEL_TEMPLATE =
  '<div class="lego-form-note lego-form-note--bad-news"></div>';
const INVALID_CLASS = 'lego-form-bad-news';

const BASE_PLUGIN_FIELD_COMPONENT = {
  replace: true,

  data: {
    validationResponse: {},
  },

  directives: {
    'plugin-validate': {
      _updateValidation() {
        if (!this.vm) {
          return;
        }
        const isValid = this.vm.$get('validationResponse.isValid');
        const msg = this.vm.$get('validationResponse.message');
        const $field = $(this.el);

        if (isValid) {
          $field.removeClass(INVALID_CLASS);
          this.$formNote.hide();
        } else {
          $field.addClass(INVALID_CLASS);
          this.$formNote.text(msg).show();
        }
      },

      bind() {
        const formNote = $(LABEL_TEMPLATE);
        this.$formNote = $(formNote).appendTo(this.el);
        this.vm.$watch('validationResponse', this._updateValidation.bind(this));
      },
    },
  },

  methods: {
    onChange(event) {
      this.updateValue(event.target.value);
    },

    updateValue(value) {
      this.value = value;
      this.$dispatch('pluginUpdateFormValue', {
        name: this.inputConfig.name,
        value,
        field_type: this.inputConfig.field_type,
      });
    },

    getValue() {
      // first check if `v-el="input"` exists otherwise just get first input
      try {
        let input;
        if (this.$$ && this.$$.input) {
          input = this.$$.input;
        }
        if (!input) {
          input = $(this.$el)
            .find('input')
            .get(0);
        }
        return input.value;
      } catch (e) {
        throw new Error('Invalid `getValue` implementation');
      }
    },

    doValidate() {
      const value = this.getValue();
      const validationResponse = this.validate(value);
      // TODO(jordan) invariant of ValidationResponse

      this.validationResponse = validationResponse;
      return validationResponse;
    },

    /**
     * @param {*} value
     */
    validate(value) {
      return {
        isValid: true,
        message: '',
      };
    },
  },

  ready() {
    if (!this.inputConfig) {
      throw new Error('input must be passed inputConfig');
    }
    // TODO add validation options, ie keyup

    this.doValidate();

    this.$watch('value', this.doValidate);
    /**
     * inputConfig.name
     * inputConfig.label
     * inputConfig.fieldType
     * inputConfig.options
     */
  },
};

/**
 * @param {Object} config
 * @param {Object} config.template
 * @param {Object} config.methods
 * @return {Object} conig for a Vue component
 */
module.exports = function(config) {
  // TODO invariant assertSchema
  if (__INVARIANT__) {
    const schema = require('js-schema');
    assertType(
      schema({
        template: String,
      }),
      config,
    );
  }

  return _.merge({}, BASE_PLUGIN_FIELD_COMPONENT, config);
};

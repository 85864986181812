import React, { useEffect, useState } from 'react';

import { useGetters } from 'core/ui/methods/connect_getters';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import PermissionsModuleGetters from 'optly/modules/permissions/getters';

import ComponentModuleActions from '../component_module/actions';
import { Metric } from '../component_module/types';

import EventsAndMetricsBuilder, {
  EventsAndMetricsBuilderProps,
} from '../events_and_metrics_builder';

export interface EventsAndMetricsBuilderWithDataProps
  extends Omit<
    EventsAndMetricsBuilderProps,
    'canUseCrossProjectMetrics' | 'projectIds' | 'selectedMetrics'
  > {
  selectedMetrics?: Metric[];
  disablePrimaryMetricEdit?: boolean;
}

const EventsAndMetricsBuilderWithData = ({
  disablePrimaryMetricEdit = false,
  isDisabled = false,
  onChange,
  selectedMetrics = [],
}: EventsAndMetricsBuilderWithDataProps) => {
  const {
    canUseCrossProjectMetrics,
    currentProjectStatus,
    currentProjectId,
  } = useGetters({
    currentProjectId: CurrentProjectGetters.id,
    currentProjectStatus: CurrentProjectGetters.status,
    canUseCrossProjectMetrics:
      PermissionsModuleGetters.canUseCrossProjectMetrics,
  }) as {
    currentProjectId: number;
    currentProjectStatus: string;
    canUseCrossProjectMetrics: boolean;
  };

  const [projectIds, setProjectIds] = useState([currentProjectId]);
  const [projects, setProjects] = useState<any>([]);
  useEffect(() => {
    if (canUseCrossProjectMetrics) {
      ComponentModuleActions.fetchProjectsAndSetProvidedProjectAsDeduplicatedFirst().then(
        projects => {
          setProjectIds([
            /**
             *  Ensures the provided current project ID is the first one (and removes any duplicates)
             * @example fetchProjectsAndSetProvidedProjectAsDeduplicatedFirst(123).then((projectIds)) => [123, 456, 789]
             */

            ...new Set([currentProjectId, ...projects.map(({ id }) => id)]),
          ]);
          setProjects(projects);
        },
      );
      return;
    }
    setProjectIds([currentProjectId]);
  }, [canUseCrossProjectMetrics, currentProjectId]);

  return (
    <EventsAndMetricsBuilder
      canUseCrossProjectMetrics={canUseCrossProjectMetrics}
      currentProjectId={currentProjectId}
      currentProjectStatus={currentProjectStatus}
      disablePrimaryMetricEdit={disablePrimaryMetricEdit}
      isDisabled={isDisabled}
      onChange={onChange}
      projectIds={projectIds}
      projects={projects}
      selectedMetrics={Object.freeze(selectedMetrics)}
    />
  );
};

export default EventsAndMetricsBuilderWithData;

import React from 'react';
import * as LayerEnums from 'optly/modules/entity/layer/enums';
import keyMirror from 'optly/utils/key_mirror';
import tr from 'optly/translate';

export const WORKING_METRIC_ALIAS = 'WORKING';

export const metricLevel = keyMirror({
  GLOBAL: null,
  EVENT: null,
});

export const event_type = {
  CUSTOM: 'custom',
  CLICK: 'click',
  PAGEVIEW: 'pageview',
};

export const aggregator = {
  UNIQUE: 'unique',
  COUNT: 'count',
  SUM: 'sum',
  BOUNCE: 'bounce',
  EXIT: 'exit',
  RATIO: 'ratio',
};

export const scope = {
  VISITOR: 'visitor',
  SESSION: 'session',
  EVENT: 'event',
};

export const field = {
  REVENUE: 'revenue',
  VALUE: 'value',
  SESSION_DURATION: 'session_duration',
};

export const winning_direction = {
  DECREASING: 'decreasing',
  INCREASING: 'increasing',
};

export const liftStatus = {
  BETTER: 'better',
  WORSE: 'worse',
};

export const measureTypeHumanReadable = {
  RATE: 'rate',
  PER: 'per',
};

export const aggregatorHumanReadable = {
  [aggregator.COUNT]: 'total',
  [aggregator.SUM]: 'total',
  [aggregator.UNIQUE]: 'unique',
  [aggregator.BOUNCE]: 'total',
  [aggregator.EXIT]: 'total',
  [aggregator.RATIO]: 'ratio',
};

export const scopeHumanReadable = {
  [scope.EVENT]: 'conversion',
  [scope.SESSION]: 'session',
  [scope.VISITOR]: 'visitor',
};

export const abandonmentMetricScopeHumanReadable = {
  QUALIFIED_SESSION: 'Qualified Session',
};

export const fieldHumanReadable = {
  [field.REVENUE]: 'revenue',
  [field.SESSION_DURATION]: 'session duration',
  [field.VALUE]: 'value',
};

export const globalMetricsByField = {
  [field.REVENUE]: 'Overall Revenue',
  [field.SESSION_DURATION]: 'Session Duration',
};

// Aggregation Options
// These options are now separate from the model, since they are actually
// many parameters being set by a single option

// These are the options represented in the drop down list
export const aggregationOptions = {
  UNIQUE_CONVERSIONS: 'unique_conversions',
  TOTAL_CONVERSIONS: 'total_conversions',
  TOTAL_REVENUE: 'total_revenue',
  TOTAL_VALUE: 'total_value',
  BOUNCE_RATE: 'bounce_rate',
  EXIT_RATE: 'exit_rate',
  RATIO: 'ratio',
};

export const aggregationTypes = {
  CONVERSION: 'conversion',
  NUMERIC_SUM: 'numeric_sum',
  ABANDONMENT: 'abandonment',
};

// This is the dictionary object for the above enums
export const aggregationOperations = {
  [aggregationOptions.UNIQUE_CONVERSIONS]: {
    label: 'unique conversions',
    value: aggregationOptions.UNIQUE_CONVERSIONS,
    aggregator: aggregator.UNIQUE,
    field: null,
    type: aggregationTypes.CONVERSION,
  },
  [aggregationOptions.TOTAL_CONVERSIONS]: {
    label: 'total conversions',
    value: aggregationOptions.TOTAL_CONVERSIONS,
    aggregator: aggregator.COUNT,
    field: null,
    type: aggregationTypes.CONVERSION,
  },
  [aggregationOptions.TOTAL_REVENUE]: {
    label: 'total revenue',
    value: aggregationOptions.TOTAL_REVENUE,
    aggregator: aggregator.SUM,
    field: field.REVENUE,
    type: aggregationTypes.NUMERIC_SUM,
  },
  [aggregationOptions.TOTAL_VALUE]: {
    label: 'total value',
    value: aggregationOptions.TOTAL_VALUE,
    aggregator: aggregator.SUM,
    field: field.VALUE,
    type: aggregationTypes.NUMERIC_SUM,
  },
  [aggregationOptions.BOUNCE_RATE]: {
    label: 'bounce rate',
    value: aggregationOptions.BOUNCE_RATE,
    aggregator: aggregator.BOUNCE,
    field: null,
    type: aggregationTypes.ABANDONMENT,
  },
  [aggregationOptions.EXIT_RATE]: {
    label: 'exit rate',
    value: aggregationOptions.EXIT_RATE,
    aggregator: aggregator.EXIT,
    field: null,
    type: aggregationTypes.ABANDONMENT,
  },
  [aggregationOptions.RATIO]: {
    aggregator: aggregator.RATIO,
    field: null,
  },
};

export const overallRevenueAggregatorOptions = [
  {
    label: 'total revenue',
    value: aggregator.SUM,
  },
];

export const layerBasedScopeOptions = {
  [LayerEnums.policy.RANDOM]: [
    {
      label: scopeHumanReadable[scope.VISITOR],
      value: scope.VISITOR,
    },
  ],
  [LayerEnums.policy.SINGLE_EXPERIMENT]: [
    {
      label: scopeHumanReadable[scope.VISITOR],
      value: scope.VISITOR,
    },
  ],
  [LayerEnums.policy.MULTIVARIATE]: [
    {
      label: scopeHumanReadable[scope.VISITOR],
      value: scope.VISITOR,
    },
  ],
  [LayerEnums.policy.ORDERED]: [
    {
      label: scopeHumanReadable[scope.SESSION],
      value: scope.SESSION,
    },
  ],
  [LayerEnums.policy.EQUAL_PRIORITY]: [
    {
      label: scopeHumanReadable[scope.SESSION],
      value: scope.SESSION,
    },
  ],
};

export const revenueScopeOptions = [
  {
    label: scopeHumanReadable[scope.EVENT],
    value: scope.EVENT,
  },
];

export const eventScopeOptions = [
  {
    label: scope.EVENT,
    value: scope.EVENT,
  },
];

export const winningDirectionOptions = [
  {
    label: 'Increase',
    value: winning_direction.INCREASING,
  },
  {
    label: 'Decrease',
    value: winning_direction.DECREASING,
  },
];

export const metricCopyProperties = keyMirror({
  IMPACT: null,
  IMPACT_KEY: null,
  IMPACT_POPOVER: null,
  IMPACT_SVG: null,
  IMPROVEMENT: null,
  IMPROVEMENT_ABSOLUTE_POPOVER: null,
  IMPROVEMENT_ABSOLUTE_SVG: null,
  IMPROVEMENT_KEY: null,
  IMPROVEMENT_POPOVER: null,
  IMPROVEMENT_SVG: null,
  IMPROVEMENT_UNIT: null,
  MEASURE_RATE: null,
  MEASURE_RATE_POPOVER: null,
  MEASURE_TYPE: null,
  MEASURE_TYPE_POPOVER: null,
  STATISTICAL_SIGNIFICANCE: null,
  STATISTICAL_SIGNIFICANCE_POPOVER: null,
});

export const scopeDescriptions = {
  EVENT: tr(
    'The total number of conversions for all visitors who were \
    exposed to this variation and triggered this event.',
  ),
  SESSION: (
    <div>
      <p>
        {tr(
          'The number of sessions where a visitor was exposed to this variation.',
        )}
      </p>
      <p>
        {tr(
          'A session is the period of time a visitor is actively engaged with your site. \
          The session times out after the user is inactive for 30 minutes or more.',
        )}
      </p>
    </div>
  ),
  BOUNCE_QUALIFIED_SESSION: tr(
    'The total number of sessions where visitors exposed to \
    this variation started the session on this Page.',
  ),
  EXIT_QUALIFIED_SESSION: tr(
    'The total number of sessions where visitors exposed to \
    this variation viewed this Page.',
  ),
  VISITOR: tr('The number of unique visitors exposed to this variation.'),
  SAMPLES: tr('The number of unique samples (e.g: visitors, sessions) exposed to this variation.'),
};

export const generateRatioDescription = (numeratorMetric, denominatorMetric) => {
  const numeratorAggregator = aggregatorHumanReadable[numeratorMetric.get('aggregator')] || 'aggregated';
  const numeratorField = fieldHumanReadable[numeratorMetric.get('field')] || 'conversions';

  const denominatorAggregator = aggregatorHumanReadable[denominatorMetric.get('aggregator')] || 'aggregated';
  const denominatorField = scopeHumanReadable[denominatorMetric.get('field')] || 'conversions';

  // Add "of" after "ratio" if the aggregator is "ratio"
  const formattedNumeratorAggregator = numeratorAggregator === 'ratio' ? `${numeratorAggregator} of` : numeratorAggregator;
  const formattedDenominatorAggregator = denominatorAggregator === 'ratio' ? `${denominatorAggregator} of` : denominatorAggregator;

  return {
    // e.g., "The [total|unique|ratio of] [conversions|revenue|session duration|value] for the numerator event"
    top: `The ${formattedNumeratorAggregator} ${numeratorField} for the numerator event.`,
    // e.g., "The [total|unique|ratio of] conversions for the denominator event"
    // "field" will always be "conversions" for the denominator
    bottom: `The ${formattedDenominatorAggregator} ${denominatorField} for the denominator event.`,
  };
};


// Keep this last as it depends on other enums
export const metricsCardHeaders = {
  [aggregator.RATIO]: {
    [scope.VISITOR]: {
      [metricCopyProperties.IMPACT]: tr('Impact'),
      [metricCopyProperties.IMPACT_KEY]: 'Ratio = Numerator / Denominator',
      [metricCopyProperties.IMPACT_POPOVER]: tr(
        'The net change in the ratio from the variation(s), compared to the baseline.',
      ),
      [metricCopyProperties.IMPACT_SVG]: 'impact-ratio-event.svg',
      [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
        'The relative difference in the ratio for this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]: 'improvement-ratio-abs.svg',
      [metricCopyProperties.IMPROVEMENT_KEY]: 'Ratio = Numerator / Denominator',
      [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
        'The relative difference in ratio for this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-ratio-rel.svg',
      [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in Ratio'),
      [metricCopyProperties.MEASURE_RATE]: tr('Rate'),
      [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
        'The ratio of the numerator (e.g.: total revenue) to the denominator (e.g.: total conversions), calculated for this variation.',
      ),
      [metricCopyProperties.MEASURE_TYPE]: tr('Value'),
      [metricCopyProperties.MEASURE_TYPE_POPOVER]: metric => {        
        const numeratorMetric = metric.get('metrics').get(0);
        const denominatorMetric = metric.get('metrics').get(1);
      
        const { top, bottom } = generateRatioDescription(numeratorMetric, denominatorMetric);
        
        return {
          top: tr(top),
          bottom: tr(bottom),
        };
      },      
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr('Statistical Significance'),
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
        <div>
          {tr(
            'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
          )}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
            {tr('Learn More')}
          </a>
        </div>
      ),
    },
    [scope.SESSION]: {
      [metricCopyProperties.IMPACT]: tr('Impact'),
      [metricCopyProperties.IMPACT_KEY]: 'Ratio = Numerator / Denominator',
      [metricCopyProperties.IMPACT_POPOVER]: tr(
        'The net change in the ratio from the variation(s), compared to the baseline.',
      ),
      [metricCopyProperties.IMPACT_SVG]: 'impact-ratio-event.svg',
      [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
        'The relative difference in the ratio for this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]: 'improvement-ratio-abs.svg',
      [metricCopyProperties.IMPROVEMENT_KEY]: 'Ratio = Numerator / Denominator',
      [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
        'The relative difference in the ratio for this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-ratio-rel.svg',
      [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in Ratio'),
      [metricCopyProperties.MEASURE_RATE]: tr('Rate'),
      [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
        'The ratio of the numerator (e.g., total revenue) to the denominator (e.g., total conversions) calculated for this variation.',
      ),
      [metricCopyProperties.MEASURE_TYPE]: tr('Value'),
      [metricCopyProperties.MEASURE_TYPE_POPOVER]: metric => {        
        const numeratorMetric = metric.get('metrics').get(0);
        const denominatorMetric = metric.get('metrics').get(1);
      
        const { top, bottom } = generateRatioDescription(numeratorMetric, denominatorMetric);
        
        return {
          top: tr(top),
          bottom: tr(bottom),
        };
      },      
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr('Statistical Significance'),
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
        <div>
          {tr(
            'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
          )}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
            {tr('Learn More')}
          </a>
        </div>
      ),
    },
  },
  [aggregator.UNIQUE]: {
    [scope.VISITOR]: {
      [metricCopyProperties.IMPACT]: tr('Impact'),
      [metricCopyProperties.IMPACT_KEY]: 'CVR = Conversion Rate',
      [metricCopyProperties.IMPACT_POPOVER]: tr(
        'The net change in conversions from the variation(s), \
        compared to the baseline.',
      ),
      [metricCopyProperties.IMPACT_SVG]: 'impact-conversion-event.svg',
      [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
        'The relative difference in conversion rate for this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
        'improvement-cvr-abs.svg',
      [metricCopyProperties.IMPROVEMENT_KEY]: 'CVR = Conversion Rate',
      [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
        'The relative difference in conversion rate for this \
        variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-cvr-rel.svg',
      [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in CVR'),
      [metricCopyProperties.MEASURE_RATE]: tr('Conversion Rate'),
      [metricCopyProperties.MEASURE_RATE_POPOVER]: (
        <div>
          {tr(
            'The percentage of unique actions among visitors who were both exposed to a particular variation and triggered an event at least one time. ',
          )}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.optimizely.com/hc/en-us/articles/4410289519373-How-Optimizely-counts-conversions">
            {tr('Learn More')}
          </a>
        </div>
      ),
      [metricCopyProperties.MEASURE_TYPE]: tr('Unique Conversions'),
      [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
        top: tr(
          'The count of distinct actions for all visitors who were both exposed to this variation and triggered this event.',
        ),
        bottom: scopeDescriptions.VISITOR,
      },
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
        'Statistical Significance',
      ),
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
        <div>
          {tr(
            'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
          )}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
            {tr('Learn More')}
          </a>
        </div>
      ),
    },
    [scope.SESSION]: {
      [metricCopyProperties.IMPACT]: tr('Impact'),
      [metricCopyProperties.IMPACT_KEY]: 'CVR = Conversion Rate',
      [metricCopyProperties.IMPACT_POPOVER]: tr(
        'The net change in conversions from the variation(s), \
        compared to the baseline.',
      ),
      [metricCopyProperties.IMPACT_SVG]: 'impact-conversion-event.svg',
      [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
        'The relative difference in conversion rate for this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
        'improvement-cvr-abs.svg',
      [metricCopyProperties.IMPROVEMENT_KEY]: 'CVR = Conversion Rate',
      [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
        'The relative difference in conversion rate for this \
        variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-cvr-rel.svg',
      [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in CVR'),
      [metricCopyProperties.MEASURE_RATE]: tr('Conversion Rate'),
      [metricCopyProperties.MEASURE_RATE_POPOVER]: (
        <div>
          {tr(
            'The percentage of unique actions among visitors who were both exposed to a particular variation and triggered an event at least one time. ',
          )}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.optimizely.com/hc/en-us/articles/4410289519373-How-Optimizely-counts-conversions">
            {tr('Learn More')}
          </a>
        </div>
      ),
      [metricCopyProperties.MEASURE_TYPE]: tr('Unique Conversions'),
      [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
        top: tr(
          'The count of distinct actions for all visitors who were both exposed to this variation and triggered this event.',
        ),
        bottom: scopeDescriptions.SESSION,
      },
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
        'Statistical Significance',
      ),
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
        <div>
          {tr(
            'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
          )}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
            {tr('Learn More')}
          </a>
        </div>
      ),
    },
  },
  [aggregator.COUNT]: {
    [scope.VISITOR]: {
      [metricCopyProperties.IMPACT]: tr('Impact'),
      [metricCopyProperties.IMPACT_KEY]: 'CPV = Conversions Per Visitor',
      [metricCopyProperties.IMPACT_POPOVER]: tr(
        'The net change in conversions from the variation(s), \
        compared to the baseline.',
      ),
      [metricCopyProperties.IMPACT_SVG]: 'impact-conversion-event.svg',
      [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
        'The relative difference in conversion rate for this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
        'improvement-cpv-abs.svg',
      [metricCopyProperties.IMPROVEMENT_KEY]: 'CPV = Conversions Per Visitor',
      [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
        'The relative difference in conversions per visitor for \
        this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-cpv-rel.svg',
      [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in CPV'),
      [metricCopyProperties.MEASURE_RATE]: tr('Conversions per Visitor'),
      [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
        'The average number of conversions for visitors who \
        were exposed to this variation and triggered this event.',
      ),
      [metricCopyProperties.MEASURE_TYPE]: tr('Total Conversions'),
      [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
        top: tr(
          'The total number of conversions for all visitors who were exposed to this variation and \
          triggered this event.',
        ),
        bottom: scopeDescriptions.VISITOR,
      },
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
        'Statistical Significance',
      ),
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
        <div>
          {tr(
            'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
          )}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
            {tr('Learn More')}
          </a>
        </div>
      ),
    },
    [scope.SESSION]: {
      [metricCopyProperties.IMPACT]: tr('Impact'),
      [metricCopyProperties.IMPACT_KEY]: 'CPS = Conversions Per Session',
      [metricCopyProperties.IMPACT_POPOVER]: tr(
        'The net change in conversions from the variation(s), \
        compared to the baseline.',
      ),
      [metricCopyProperties.IMPACT_SVG]: 'impact-conversion-event.svg',
      [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
        'The relative difference in conversion rate for this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
        'improvement-cps-abs.svg',
      [metricCopyProperties.IMPROVEMENT_KEY]: 'CPS = Conversions Per Session',
      [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
        'The relative difference in conversions per session \
        for this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-cps-rel.svg',
      [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in CPS'),
      [metricCopyProperties.MEASURE_RATE]: tr('Conversions per Session'),
      [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
        'The average number of conversions for sessions in \
        which a visitor was exposed to this variation and triggered this event.',
      ),
      [metricCopyProperties.MEASURE_TYPE]: tr('Total Conversions'),
      [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
        top: tr(
          'The total number of conversions for all visitors who were exposed to this variation and \
          triggered this event.',
        ),
        bottom: scopeDescriptions.SESSION,
      },
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
        'Statistical Significance',
      ),
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
        <div>
          {tr(
            'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
          )}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
            {tr('Learn More')}
          </a>
        </div>
      ),
    },
  },
  [aggregator.SUM]: {
    [scope.VISITOR]: {
      [field.REVENUE]: {
        [metricLevel.GLOBAL]: {
          [metricCopyProperties.IMPACT]: tr('Impact'),
          [metricCopyProperties.IMPACT_KEY]: 'RPV = Revenue per Visitor',
          [metricCopyProperties.IMPACT_POPOVER]: tr(
            'The net change in revenue from the variation(s), \
            compared to the baseline.',
          ),
          [metricCopyProperties.IMPACT_SVG]: 'impact-revenue-event.svg',
          [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
            'The relative difference in conversion rate for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
            'improvement-rpv-abs.svg',
          [metricCopyProperties.IMPROVEMENT_KEY]: 'RPV = Revenue per Visitor',
          [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
            'The relative difference in revenue per \
            visitor for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-rpv-rel.svg',
          [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in RPV'),
          [metricCopyProperties.MEASURE_RATE]: tr('Revenue per Visitor'),
          [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
            'The average revenue generated by visitors who were \
            exposed to this variation.',
          ),
          [metricCopyProperties.MEASURE_TYPE]: tr('Total Revenue'),
          [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
            top: tr(
              'The total revenue generated from visitors who were exposed to this variation.',
            ),
            bottom: scopeDescriptions.VISITOR,
          },
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
            'Statistical Significance',
          ),
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
            <div>
              {tr(
                'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
                {tr('Learn More')}
              </a>
            </div>
          ),
        },
        [metricLevel.EVENT]: {
          [metricCopyProperties.IMPACT]: tr('Impact'),
          [metricCopyProperties.IMPACT_KEY]: 'RPV = Revenue per Visitor',
          [metricCopyProperties.IMPACT_POPOVER]: tr(
            'The net change in revenue from the variation(s), \
            compared to the baseline.',
          ),
          [metricCopyProperties.IMPACT_SVG]: 'impact-revenue-event.svg',
          [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
            'The relative difference in conversion rate for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
            'improvement-rpv-abs.svg',
          [metricCopyProperties.IMPROVEMENT_KEY]: 'RPV = Revenue per Visitor',
          [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
            'The relative difference in revenue per \
            visitor for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-rpv-rel.svg',
          [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in RPV'),
          [metricCopyProperties.MEASURE_RATE]: tr('Revenue per Visitor'),
          [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
            'The average revenue generated by visitors who were \
            exposed to this variation and triggered this event.',
          ),
          [metricCopyProperties.MEASURE_TYPE]: tr('Total Revenue'),
          [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
            top: tr(
              'The total revenue generated from visitors who were exposed to this variation.',
            ),
            bottom: scopeDescriptions.VISITOR,
          },
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
            'Statistical Significance',
          ),
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
            <div>
              {tr(
                'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
                {tr('Learn More')}
              </a>
            </div>
          ),
        },
      },
      [field.VALUE]: {
        [metricLevel.EVENT]: {
          [metricCopyProperties.IMPACT]: tr('Impact'),
          [metricCopyProperties.IMPACT_KEY]: 'VPV = Value per Visitor',
          [metricCopyProperties.IMPACT_POPOVER]: tr(
            'The net change in value from the variation(s), \
            compared to the baseline.',
          ),
          [metricCopyProperties.IMPACT_SVG]: 'impact-vpv.svg',
          [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
            'The relative difference in conversion rate for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
            'improvement-vpv-abs.svg',
          [metricCopyProperties.IMPROVEMENT_KEY]: 'VPV = Value per Visitor',
          [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
            'The relative difference in value per \
            visitor for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-vpv-rel.svg',
          [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in VPV'),
          [metricCopyProperties.MEASURE_RATE]: tr('Value per Visitor'),
          [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
            'The average value generated by each \
            visitor who was exposed to this variation and triggered this event.',
          ),
          [metricCopyProperties.MEASURE_TYPE]: tr('Total Value'),
          [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
            top: tr(
              'The total value generated from visitors who were exposed to this variation.',
            ),
            bottom: scopeDescriptions.VISITOR,
          },
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
            'Statistical Significance',
          ),
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
            <div>
              {tr(
                'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
                {tr('Learn More')}
              </a>
            </div>
          ),
        },
      },
    },
    [scope.SESSION]: {
      [field.REVENUE]: {
        [metricLevel.GLOBAL]: {
          [metricCopyProperties.IMPACT]: tr('Impact'),
          [metricCopyProperties.IMPACT_KEY]: 'RPS = Revenue per Session',
          [metricCopyProperties.IMPACT_POPOVER]: tr(
            'The net change in revenue from the variation(s), \
            compared to the baseline.',
          ),
          [metricCopyProperties.IMPACT_SVG]: 'impact-revenue-event.svg',
          [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
            'The relative difference in conversion rate for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
            'improvement-rps-abs.svg',
          [metricCopyProperties.IMPROVEMENT_KEY]: 'RPS = Revenue per Session',
          [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
            'The relative difference in revenue per session for \
            this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-rps-rel.svg',
          [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in RPS'),
          [metricCopyProperties.MEASURE_RATE]: tr('Revenue per Session'),
          [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
            'The average revenue generated by sessions where a \
            visitor was exposed to this variation.',
          ),
          [metricCopyProperties.MEASURE_TYPE]: tr('Total Revenue'),
          [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
            top: tr(
              'The total revenue generated from visitors who were exposed to this variation.',
            ),
            bottom: scopeDescriptions.SESSION,
          },
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
            'Statistical Significance',
          ),
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
            <div>
              {tr(
                'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
                {tr('Learn More')}
              </a>
            </div>
          ),
        },
        [metricLevel.EVENT]: {
          [metricCopyProperties.IMPACT]: tr('Impact'),
          [metricCopyProperties.IMPACT_KEY]: 'RPS = Revenue per Session',
          [metricCopyProperties.IMPACT_POPOVER]: tr(
            'The net change in revenue from the variation(s), \
            compared to the baseline.',
          ),
          [metricCopyProperties.IMPACT_SVG]: 'impact-revenue-event.svg',
          [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
            'The relative difference in conversion rate for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
            'improvement-rps-abs.svg',
          [metricCopyProperties.IMPROVEMENT_KEY]: 'RPS = Revenue per Session',
          [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
            'The relative difference in revenue per session for \
            this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-rps-rel.svg',
          [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in RPS'),
          [metricCopyProperties.MEASURE_RATE]: tr('Revenue per Session'),
          [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
            'The average revenue generated by sessions where a \
            visitor was exposed to this variation and triggered this event.',
          ),
          [metricCopyProperties.MEASURE_TYPE]: tr('Total Revenue'),
          [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
            top: tr(
              'The total revenue generated from visitors who were exposed to this variation.',
            ),
            bottom: scopeDescriptions.SESSION,
          },
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
            'Statistical Significance',
          ),
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
            <div>
              {tr(
                'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
                {tr('Learn More')}
              </a>
            </div>
          ),
        },
      },
      [field.VALUE]: {
        [metricLevel.EVENT]: {
          [metricCopyProperties.IMPACT]: tr('Impact'),
          [metricCopyProperties.IMPACT_KEY]: 'VPS = Value per Session',
          [metricCopyProperties.IMPACT_POPOVER]: tr(
            'The net change in value from the variation(s), \
            compared to the baseline.',
          ),
          [metricCopyProperties.IMPACT_SVG]: 'impact-vps.svg',
          [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
            'The relative difference in conversion rate for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
            'improvement-vps-abs.svg',
          [metricCopyProperties.IMPROVEMENT_KEY]: 'VPS = Value per Session',
          [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
            'The relative difference in value per \
            session for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-vps-rel.svg',
          [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in VPS'),
          [metricCopyProperties.MEASURE_RATE]: tr('Value per Session'),
          [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
            'The average value generated by sessions \
            where a visitor was exposed to this variation and triggered this event.',
          ),
          [metricCopyProperties.MEASURE_TYPE]: tr('Total Value'),
          [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
            top: tr(
              'The total value generated from visitors who were exposed to this variation.',
            ),
            bottom: scopeDescriptions.SESSION,
          },
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
            'Statistical Significance',
          ),
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
            <div>
              {tr(
                'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
                {tr('Learn More')}
              </a>
            </div>
          ),
        },
      },
    },
    [scope.EVENT]: {
      [field.REVENUE]: {
        [metricLevel.EVENT]: {
          [metricCopyProperties.IMPACT]: tr('Impact'),
          [metricCopyProperties.IMPACT_KEY]: 'RPC = Revenue per Conversion',
          [metricCopyProperties.IMPACT_POPOVER]: tr(
            'The net change in revenue from the variation(s), \
            compared to the baseline.',
          ),
          [metricCopyProperties.IMPACT_SVG]: 'impact-revenue-event.svg',
          [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
            'The relative difference in conversion rate for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
            'improvement-rpc-abs.svg',
          [metricCopyProperties.IMPROVEMENT_KEY]:
            'RPC = Revenue per Conversion',
          [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
            'The relative difference in revenue per conversion \
            for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-rpc-rel.svg',
          [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in RPC'),
          [metricCopyProperties.MEASURE_RATE]: tr('Revenue per Conversion'),
          [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
            'The average revenue generated by conversions \
            of this event for this variation.',
          ),
          [metricCopyProperties.MEASURE_TYPE]: tr('Total Revenue'),
          [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
            top: tr(
              'The total revenue generated from visitors who were exposed to this variation.',
            ),
            bottom: scopeDescriptions.EVENT,
          },
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
            'Statistical Significance',
          ),
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
            <div>
              {tr(
                'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
                {tr('Learn More')}
              </a>
            </div>
          ),
        },
        [metricLevel.GLOBAL]: {
          [metricCopyProperties.IMPACT]: tr('Impact'),
          [metricCopyProperties.IMPACT_KEY]: 'RPC = Revenue per Conversion',
          [metricCopyProperties.IMPACT_POPOVER]: tr(
            'The net change in revenue from the variation(s), \
            compared to the baseline.',
          ),
          [metricCopyProperties.IMPACT_SVG]: 'impact-revenue-event.svg',
          [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
            'The relative difference in conversion rate for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
            'improvement-rpc-abs.svg',
          [metricCopyProperties.IMPROVEMENT_KEY]:
            'RPC = Revenue per Conversion',
          [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
            'The relative difference in revenue per conversion \
            for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-rpc-rel.svg',
          [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in RPC'),
          [metricCopyProperties.MEASURE_RATE]: tr('Revenue per Conversion'),
          [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
            'The average revenue generated by conversions of \
            this event for this variation.',
          ),
          [metricCopyProperties.MEASURE_TYPE]: tr('Total Revenue'),
          [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
            top: tr(
              'The total revenue generated from visitors who were exposed to this variation.',
            ),
            bottom: scopeDescriptions.EVENT,
          },
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
            'Statistical Significance',
          ),
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
            <div>
              {tr(
                'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
                {tr('Learn More')}
              </a>
            </div>
          ),
        },
      },
      [field.VALUE]: {
        [metricLevel.EVENT]: {
          [metricCopyProperties.IMPACT]: tr('Impact'),
          [metricCopyProperties.IMPACT_KEY]: 'VPC = Value per Conversion',
          [metricCopyProperties.IMPACT_POPOVER]: tr(
            'The net change in value from the variation(s), \
            compared to the baseline.',
          ),
          [metricCopyProperties.IMPACT_SVG]: 'impact-vpc.svg',
          [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
            'The relative difference in conversion rate for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
            'improvement-vpc-abs.svg',
          [metricCopyProperties.IMPROVEMENT_KEY]: 'VPC = Value per Conversion',
          [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
            'The relative difference in value per \
            conversion for this variation, compared to the baseline.',
          ),
          [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-vpc-rel.svg',
          [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in VPC'),
          [metricCopyProperties.MEASURE_RATE]: tr('Value per Conversion'),
          [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
            'The average value generated by conversions \
            of this event for this variation.',
          ),
          [metricCopyProperties.MEASURE_TYPE]: tr('Total Value'),
          [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
            top: tr(
              'The total value generated from visitors who were exposed to this variation.',
            ),
            bottom: scopeDescriptions.EVENT,
          },
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
            'Statistical Significance',
          ),
          [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
            <div>
              {tr(
                'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
                {tr('Learn More')}
              </a>
            </div>
          ),
        },
      },
    },
  },
  [aggregator.BOUNCE]: {
    [scope.EVENT]: {
      [metricCopyProperties.IMPACT]: tr('Impact'),
      [metricCopyProperties.IMPACT_KEY]: 'BR = Bounce Rate',
      [metricCopyProperties.IMPACT_POPOVER]: tr(
        'The net change in bounces from the variation(s), \
        compared to the baseline.',
      ),
      [metricCopyProperties.IMPACT_SVG]: 'impact-bounce.svg',
      [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
        'The relative difference in conversion rate for this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
        'improvement-bounce-abs.svg',
      [metricCopyProperties.IMPROVEMENT_KEY]: 'BR = Bounce Rate',
      [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
        'The relative difference in bounce rate for \
      this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-bounce-rel.svg',
      [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in BR'),
      [metricCopyProperties.MEASURE_RATE]: tr('Bounce Rate'),
      [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
        'The percentage of qualified sessions where \
        visitors exposed to this variation viewed only this Page.',
      ),
      [metricCopyProperties.MEASURE_TYPE]: tr('Total Bounces'),
      [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
        top: tr(
          'The total number of sessions where visitors exposed to this variation viewed only this Page.',
        ),
        bottom: scopeDescriptions.BOUNCE_QUALIFIED_SESSION,
      },
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
        'Statistical Significance',
      ),
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
        <div>
          {tr(
            'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
          )}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
            {tr('Learn More')}
          </a>
        </div>
      ),
    },
  },
  [aggregator.EXIT]: {
    [scope.EVENT]: {
      [metricCopyProperties.IMPACT]: tr('Impact'),
      [metricCopyProperties.IMPACT_KEY]: 'ER = Exit Rate',
      [metricCopyProperties.IMPACT_POPOVER]: tr(
        'The net change in exits from the variation(s), \
        compared to the baseline.',
      ),
      [metricCopyProperties.IMPACT_SVG]: 'impact-exit.svg',
      [metricCopyProperties.IMPROVEMENT]: tr('Improvement'),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_POPOVER]: tr(
        'The relative difference in conversion rate for this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_ABSOLUTE_SVG]:
        'improvement-exit-abs.svg',
      [metricCopyProperties.IMPROVEMENT_KEY]: 'ER = Exit Rate',
      [metricCopyProperties.IMPROVEMENT_POPOVER]: tr(
        'The relative difference in exit rate for \
        this variation, compared to the baseline.',
      ),
      [metricCopyProperties.IMPROVEMENT_SVG]: 'improvement-exit-rel.svg',
      [metricCopyProperties.IMPROVEMENT_UNIT]: tr('Change in ER'),
      [metricCopyProperties.MEASURE_RATE]: tr('Exit Rate'),
      [metricCopyProperties.MEASURE_RATE_POPOVER]: tr(
        'The percentage of qualified sessions \
        where visitors exposed to this variation ended the session on this Page.',
      ),
      [metricCopyProperties.MEASURE_TYPE]: tr('Total Exits'),
      [metricCopyProperties.MEASURE_TYPE_POPOVER]: {
        top: tr(
          'The total number of sessions where visitors exposed to this variation ended \
          the session on this Page.',
        ),
        bottom: scopeDescriptions.EXIT_QUALIFIED_SESSION,
      },
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE]: tr(
        'Statistical Significance',
      ),
      [metricCopyProperties.STATISTICAL_SIGNIFICANCE_POPOVER]: (
        <div>
          {tr(
            'Significance is a conditional probability of seeing evidence as strong or stronger in favor of the test variation(s), calculated assuming there is no difference between the control and variant(s). ',
          )}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.optimizely.com/hc/en-us/articles/4410284003341-Statistical-significance-in-Optimizely">
            {tr('Learn More')}
          </a>
        </div>
      ),
    },
  },
};

export default {
  WORKING_METRIC_ALIAS,
  metricLevel,
  event_type,
  aggregator,
  scope,
  field,
  winning_direction,
  liftStatus,
  measureTypeHumanReadable,
  aggregatorHumanReadable,
  scopeHumanReadable,
  abandonmentMetricScopeHumanReadable,
  fieldHumanReadable,
  globalMetricsByField,
  aggregationOptions,
  aggregationTypes,
  aggregationOperations,
  overallRevenueAggregatorOptions,
  layerBasedScopeOptions,
  revenueScopeOptions,
  eventScopeOptions,
  winningDirectionOptions,
  metricCopyProperties,
  scopeDescriptions,
  metricsCardHeaders,
};

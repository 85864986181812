/**
 * Holds the configuration for the different field types that can be attached to a plugins schema configuration
 */
const _ = require('lodash');
const PluginModuleEnums = require('optly/modules/entity/plugin/enums');

const fieldTypes = {};

const validateChoicesShape = function(fieldConfig) {
  const options = fieldConfig.options;

  if (!options || !options.choices) {
    return {
      isValid: false,
      message: 'This field requires a "choices" array.',
    };
  }

  if (options.choices.length < 2) {
    return {
      isValid: false,
      message: 'This field requires at least two choices.',
    };
  }

  const hasInvalidChoice = _.some(
    options.choices,
    choice =>
      !_.isObject(choice) ||
      !_.isString(choice.value) ||
      !_.isString(choice.label) ||
      _.keys(choice).length !== 2,
  );
  if (hasInvalidChoice) {
    return {
      isValid: false,
      message: tr(
        'choices must be an array of objects with value & label string properties',
      ),
    };
  }

  return {
    isValid: true,
  };
};

const validateDefaultIsOneOfChoices = function(fieldConfig) {
  const options = fieldConfig.options;
  const hasDefaultChoice = _.some(options.choices, choice =>
    _.isEqual(choice.value, fieldConfig.default_value),
  );
  if (fieldConfig.default_value && !hasDefaultChoice) {
    return {
      isValid: false,
      message: 'The default must be one of these choices.',
    };
  }

  return {
    isValid: true,
    message: '',
  };
};

fieldTypes[PluginModuleEnums.fieldType.HTML] = {
  name: 'HTML',
  description: '',
  singleton: false,
};

fieldTypes[PluginModuleEnums.fieldType.SELECTOR] = {
  name: 'Selector',
  description: '',
  singleton: false,
};

fieldTypes[PluginModuleEnums.fieldType.CSS] = {
  name: 'CSS',
  description: '',
  singleton: false,
};

fieldTypes[PluginModuleEnums.fieldType.TEXT] = {
  name: 'Text (one line)',
  description: '',
  singleton: false,
};

fieldTypes[PluginModuleEnums.fieldType.MULTI_TEXT] = {
  name: 'Text (multiple lines)',
  description: '',
  singleton: false,
};

fieldTypes[PluginModuleEnums.fieldType.RICH_TEXT] = {
  name: 'Rich Text',
  description: '',
  singleton: false,
};

fieldTypes[PluginModuleEnums.fieldType.NUMBER] = {
  name: 'Number',
  description: '',
  singleton: false,
};

fieldTypes[PluginModuleEnums.fieldType.DROPDOWN] = {
  name: 'Dropdown',
  description: '',
  singleton: false,
  defaultOptions: {
    choices: [
      { value: 'a', label: 'Option A' },
      { value: 'b', label: 'Option B' },
    ],
  },
  validateDefaultOptions: validateChoicesShape,
  validateDefaultValue: validateDefaultIsOneOfChoices,
  getDefaultValue(options) {
    return options.choices[0].value;
  },
};

fieldTypes[PluginModuleEnums.fieldType.MULTI_SELECT] = {
  name: 'Multiple Select',
  description: '',
  singleton: false,
  defaultOptions: {
    choices: [
      { value: 'a', label: 'Option A' },
      { value: 'b', label: 'Option B' },
    ],
  },
  validateDefaultOptions: validateChoicesShape,
  validateDefaultValue(config) {
    if (config.default_value.length === 0) {
      return {
        isValid: true,
      };
    }

    const options = config.options;
    const choiceValues = _.reduce(
      options.choices,
      (memo, choice) => {
        memo[choice.value] = true;
        return memo;
      },
      {},
    );
    const hasNonChoiceDefault = _.some(
      config.default_value,
      defaultSelection => !choiceValues[defaultSelection],
    );
    if (hasNonChoiceDefault) {
      return {
        isValid: false,
        message: tr('The default value contains invalid selections'),
      };
    }

    return {
      isValid: true,
    };
  },
  getDefaultValue(options) {
    return [options.choices[0].value];
  },
};

fieldTypes[PluginModuleEnums.fieldType.TOGGLE] = {
  name: 'Toggle',
  description: '',
  singleton: false,
  defaultOptions: {
    choices: [
      { value: 'a', label: 'Option A' },
      { value: 'b', label: 'Option B' },
    ],
  },
  validateDefaultOptions: validateChoicesShape,
  validateDefaultValue: validateDefaultIsOneOfChoices,
  getDefaultValue(options) {
    return options.choices[0].value;
  },
};

fieldTypes[PluginModuleEnums.fieldType.COLOR] = {
  name: 'Color Picker',
  description: '',
  singleton: false,
  defaultOptions: {
    mode: 'rgba',
  },
  validateDefaultOptions(fieldConfig) {
    const options = fieldConfig.options;
    const allowedModes = ['rgba', 'hex'];
    if (!options || !options.mode) {
      return {
        isValid: false,
        message: 'Color fields require a "mode" attribute.',
      };
    }
    if (!_.includes(allowedModes, options.mode)) {
      return {
        isValid: false,
        message: `Mode must be one of ${allowedModes}`,
      };
    }
    return {
      isValid: true,
      message: '',
    };
  },
  getDefaultValue(options) {
    return options.mode;
  },
};

fieldTypes[PluginModuleEnums.fieldType.IMAGE] = {
  name: 'Image',
  description: '',
  singleton: false,
};

module.exports = fieldTypes;

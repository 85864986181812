/**
 * Geotargeting Service
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const $ = require('jquery');
const _ = require('lodash');
const UrlUtil = require('optly/utils/url');

/**
 * Object that batches locations to fetch and makes a single AJAX request
 * @constructor
 */
function LocationFetcher(geotargetingService) {
  this.MAXIMUM_QUEUE = 15;
  /**
   * State of all queue'd location keys to fetch and their corresponding Deferreds
   * @param {Array.<{ location: string, deferred: Deferred }>}
   */
  this.locationsToFetch = [];

  /**
   * @type {GeotargetingService}
   */
  this.geotargetingService = geotargetingService;
}

/**
 * Queues a location to fetch
 * @param {String} location key
 * @param {Deferred} deferred
 */
LocationFetcher.prototype.queue = function(location, deferred) {
  this.locationsToFetch.push({
    location,
    deferred,
  });
};

/**
 * Returns whether the is full on queued on locations to fetch
 * @return {Boolean}
 */
LocationFetcher.prototype.isFull = function() {
  return this.locationsToFetch.length >= this.MAXIMUM_QUEUE;
};

/**
 * Fetches the labels for all the location keys queued on the object
 * and resolves each deferred.  Also caches and removes the locationFetcher
 * from the parent GeotargetingService stack
 *
 * @return {Deferred}
 */
LocationFetcher.prototype.fetch = function() {
  // extract the location keys from the map
  const keysToFetch = this.locationsToFetch.map(item => item.location);

  return this.doFetch(keysToFetch).then(rslt => {
    // Resolve all deferreds with their label values
    this.locationsToFetch.forEach(item => {
      if (rslt[item.location]) {
        item.deferred.resolve(rslt[item.location]);
      } else {
        item.deferred.reject();
      }
    });

    this.geotargetingService.cacheLocationsToLabels(rslt);
    // reset
    this.locationsToFetch = [];
    // remove itself from the locationFetchers stack
    const index = this.geotargetingService.locationFetchers.indexOf(this);
    this.geotargetingService.locationFetchers.splice(index, 1);
  });
};

/**
 * Make an XHR request to get a label for a location string
 * @private
 * @param {Array.<String>} location (ex: City|State|Country)
 * @return {Deferred}
 */
LocationFetcher.prototype.doFetch = function(locations) {
  let url = '/api/location_labels.json';

  const queryString = locations.map(loc => `keys=${loc}`, '').join('&');

  url += `?${queryString}`;

  // TODO: convert this ajax call to fetch
  // eslint-disable-next-line fetch/no-jquery
  return $.ajax({
    url: UrlUtil.optimizelyHRDUrl(url),
    type: 'GET',
  });
};

/**
 * @constructor
 */
function GeotargetingService() {
  /**
   * Cached mapping of locations -> labels
   * @param {Object}
   */
  this.locationsToLabels = {};

  /**
   * Reference to setTimeout for fetching location labels
   */
  this.timeoutRef = null;

  /**
   * @type {Array.<LocationFetcher>}
   */
  this.locationFetchers = [];
}

/**
 * Creates a LocationFetcher and pushes it on the LocationFetcher's stack
 * @private
 */
GeotargetingService.prototype.createLocationFetcher = function() {
  this.locationFetchers.push(new LocationFetcher(this));
};

/**
 * Extends the locationsToLabels cache with the passed map
 * @param {Object} results - a JSON response from the autocomplete endpoint
 */
GeotargetingService.prototype.cacheLocationsToLabels = function(
  locationsToLabels,
) {
  $.extend(this.locationsToLabels, locationsToLabels);
};

/**
 * Takes a location string and returns the readable label
 * If the label isn't cached it will batch the requested labels and make a single
 * XHR request to get all the labels at once and resolve all the deferreds
 *
 * @param {String} location (ex: City|State|Country)
 * @return {Deferred}
 */
GeotargetingService.prototype.getLabel = function(location) {
  const deferred = $.Deferred();
  if (this.locationsToLabels[location]) {
    deferred.resolve(this.locationsToLabels[location]);
  } else {
    // if executed in the same context cancel the timeout
    // add a key to fetch and requeue deferred fetch
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }

    // create a LocationFetcher object
    if (!_.last(this.locationFetchers)) {
      this.createLocationFetcher();
    }

    // if the last LocationFetcher is full, call fetch and create a new one
    if (_.last(this.locationFetchers).isFull()) {
      // if the current LocationFetcher is full queue up the fetch
      _.last(this.locationFetchers).fetch();
      this.createLocationFetcher();
    }

    const lastLocationFetcher = _.last(this.locationFetchers);

    // queue up the location to fetch
    lastLocationFetcher.queue(location, deferred);

    // defer the fetch request
    this.timeoutRef = setTimeout(() => {
      lastLocationFetcher.fetch().then(function() {
        // clear the timeout ref
        this.timeoutRef = null;
      });
    }, 0);
  }

  return deferred;
};

module.exports = new GeotargetingService();

import React from 'react';
import PropTypes from 'prop-types';

import { Input, Button, ButtonRow, Icon } from 'optimizely-oui';

import { QRCodeSVG } from 'qrcode.react';

import Copier from 'react_components/copier';

const ShareLinkReactComponent = props => {
  return (
    <div data-test-section="share-link-dialog" className="lego-dialog--narrow">
      <div className="lego-dialog__header flush--bottom soft--bottom">
        <div className="lego-dialog__title">Share Link</div>
      </div>
      <fieldset className="lego-dialog__body flush--bottom soft-double--bottom">
        <p className="push--bottom">
          Share this URL to let others preview this variation.
        </p>
        <div className="anchor--middle width--3-5">
          <Input
            type="text"
            testSection="share-link-input"
            isReadOnly={true}
            value={props.shareLinkUrl}
          />
          <div className="push--top">
            <ButtonRow
              centerGroup={[
                <Copier textToCopy={props.shareLinkUrl} key={1}>
                  <Button style="outline" testSection="share-link-copy-btn">
                    <Icon
                      name="clipboard"
                      size="small"
                      className="oui-icon vertical-align--middle"
                      color="color--brand"
                    />
                    &nbsp; Copy Link
                  </Button>
                </Copier>,
                <a
                  key={2}
                  className="oui-button oui-button--outline"
                  href={props.shareLinkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-test-section="share-link-open-btn">
                  <Icon
                    name="arrow-up-right-from-square"
                    size="small"
                    className="oui-icon vertical-align--middle"
                    color="color--brand"
                  />
                  &nbsp; Open Link
                </a>,
              ]}
            />
          </div>
        </div>
        <div className="push-double--top">
          <p>
            <strong>Mobile preview:</strong> You can also scan the QR code with
            your preferred QR code reader on a mobile device.
          </p>
        </div>
        <div className="qr-code push--ends">
          <QRCodeSVG
            className="anchor--middle width--200 display--block"
            data-test-section="qr-code-path"
            value={props.shareLinkUrl}
          />
        </div>
      </fieldset>
    </div>
  );
};

ShareLinkReactComponent.propTypes = {
  shareLinkUrl: PropTypes.string.isRequired,
};

export default ShareLinkReactComponent;

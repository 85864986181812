import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Input, SelectDropdown } from 'optimizely-oui';

import filterable from 'react_components/filterable';

import { connect } from 'core/ui/decorators';

import { getters as LoadingGetters } from 'core/modules/loading';
import { ZIndex } from 'optly/utils/enums';

export const TableFilterControls = filterable(
  @connect(props => ({
    isLoading: LoadingGetters.isLoading(props.tableId),
  }))
  class TableFilterControls extends React.Component {
    static propTypes = {
      tableId: PropTypes.string.isRequired,
      isLoading: PropTypes.bool.isRequired,
      // statuses; required if showStatusFilter === true
      statusOptions: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),

      filters: PropTypes.shape({
        string: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }).isRequired,

      // customFilters; required if showCustomFilters === true
      customFilters: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string.isRequired,
              value: PropTypes.string.isRequired,
            }),
          ),
          onChange: PropTypes.func.isRequired,
        }),
      ),

      setFilter: PropTypes.func.isRequired,

      resetFilters: PropTypes.func.isRequired,

      onFiltersChange: PropTypes.func,

      onStatusChange: PropTypes.func,

      // Add text to edit the placeholder text within the search bar
      inputPlaceholder: PropTypes.string,
      // Add a width class to override length of search bar if needed
      inputWidth: PropTypes.string,

      dropdownContentDirection: PropTypes.oneOf(['left', 'right']),

      showCustomFilters: PropTypes.bool,
      showStringFilter: PropTypes.bool,
      showStatusFilter: PropTypes.bool,
    };

    static defaultProps = {
      inputPlaceholder: 'Browse by Name',
      inputWidth: 'width--200',
      showCustomFilters: false,
      showStatusFilter: true,
      showStringFilter: true,
    };

    setStatusFilter = status => {
      this.props.setFilter({
        status,
      });
      if (this.props.onStatusChange) {
        this.props.onStatusChange(status);
      }
    };

    handleStringFilterChange = event => {
      // TODO(jordan): fix focus issue
      this.props.setFilter({
        string: event.target.value,
      });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        this.props.onFiltersChange &&
        !_.isEqual(nextProps.filters, this.props.filters)
      ) {
        this.props.onFiltersChange(nextProps.filters);
      }
    }

    getStatusText = () => {
      const value = this.props.filters.status;
      const entry = this.props.statusOptions[
        _.findKey(this.props.statusOptions, { value })
      ];
      if (!entry) {
        if (__DEV__) {
          throw new Error(`No label found for status=${value}`);
        }
        return '';
      }
      return entry.label;
    };

    renderStatusFilterDropdown = () => {
      const { statusOptions, filters, isLoading } = this.props;

      // Status options are required if showStatusFilters === true
      if (!statusOptions) {
        if (__DEV__) {
          throw new Error(
            `Status options not provided. Status Options are required when showStatusFilter is true`,
          );
        }
        return null;
      }

      return (
        <SelectDropdown
          buttonContent={{
            label: 'Status',
            content: this.getStatusText(),
          }}
          items={statusOptions}
          value={filters.status}
          buttonStyle="plain"
          onChange={value => this.setStatusFilter(value)}
          isDisabled={isLoading}
          testSection="filter-table-dropdown"
        />
      );
    };

    getLabelForCustomFilter = (filter, value) => {
      const option = _.find(filter.options, { value });
      return option.label;
    };

    renderCustomFilterDropdowns = () => {
      const { customFilters, isLoading } = this.props;
      // Status options are required if showStatusFilters === true
      if (!customFilters) {
        if (__DEV__) {
          throw new Error(
            `Custom filters not provided. Custom filters are required when showCustomFilters is true`,
          );
        }
        return null;
      }

      return customFilters.map((filter, index) => {
        return (
          <SelectDropdown
            key={index}
            className="push--left"
            buttonContent={{
              label: filter.label,
              content: this.getLabelForCustomFilter(filter, filter.value),
            }}
            items={filter.options}
            value={filter.value}
            buttonStyle="plain"
            onChange={value => filter.onChange(value)}
            isDisabled={isLoading}
            testSection={`filter-table-custom-dropdown-${index}`}
          />
        );
      });
    };

    render() {
      const {
        filters,
        inputPlaceholder,
        inputWidth,
        isLoading,
        showCustomFilters,
        showStringFilter,
        showStatusFilter,
      } = this.props;
      // Set z-index for this so status dropdown filter is on top of the EntityTable LoadingOverlay (if used)
      return (
        <div
          className="lego-button-group flex--1"
          style={{ zIndex: ZIndex.FILTER_STATUS_DROPDOWN }}>
          {showStringFilter && (
            <div className={`${inputWidth} push--right`}>
              <Input
                isDisabled={isLoading}
                isFilter={true}
                onChange={this.handleStringFilterChange}
                placeholder={isLoading ? 'Loading...' : inputPlaceholder}
                type="text"
                value={filters.string}
                testSection="table-filter-input"
              />
            </div>
          )}
          {showStatusFilter && this.renderStatusFilterDropdown()}
          {showCustomFilters && this.renderCustomFilterDropdowns()}
        </div>
      );
    }
  },
);

export default TableFilterControls;

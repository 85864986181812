const createPluginConfigField = require('./create_plugin_config_field');

module.exports = createPluginConfigField({
  template: require('./number.html'),

  methods: {
    onChange(event) {
      this.updateValue(parseInt(event.target.value, 10));
    },
  },
});

/**
 * Filter to format file size.
 *
 * @author Tim Scanlin (tim.scanlin@optimizely.com)
 */

const tr = require('optly/translate');

module.exports = function(numberInBytes) {
  if (numberInBytes === null || numberInBytes === undefined) {
    return '';
  }

  if (numberInBytes === 0) {
    return tr('0 bytes');
  }

  const unit = Math.floor(Math.log(numberInBytes) / Math.log(1024));
  const number = numberInBytes / Math.pow(1024, unit);

  switch (unit) {
    case 0:
      return tr('{0} bytes', tr.number(number, 0));
    case 1:
      return tr('{0} KB', tr.number(number, 2));
    case 2:
      return tr('{0} MB', tr.number(number, 2));
    case 3:
      return tr('{0} GB', tr.number(number, 2));
    default:
      return '';
  }
};

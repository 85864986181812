import React from 'react';
import ui from 'core/ui';
import PropTypes from 'prop-types';
import { Button, ButtonRow, Dialog } from 'optimizely-oui';

const { Wrapper, Title, Footer, Fieldset } = Dialog;

export default class EmptyCTADialog extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
  };

  componentWillUnmount() {
    this.cancel();
  }

  static defaultProps = {
    onSubmit: () => null,
    onCancel: () => null,
  };

  submit = () => {
    const { onSubmit } = this.props;
    onSubmit();
    ui.hideDialog();
  };

  cancel = () => {
    const { onCancel } = this.props;
    onCancel();
    ui.hideDialog();
  };

  render() {
    const { title, body } = this.props;
    return (
      <Wrapper testSection="empty-cta-dialog">
        {title && (
          <div data-test-section="empty-cta-dialog-title">
            <Title>{title}</Title>
          </div>
        )}
        <Fieldset testSection="empty-cta-dialog-body">
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </Fieldset>
        <Footer>
          <ButtonRow
            rightGroup={[
              <Button style="plain" key="cancel" onClick={this.cancel}>
                Cancel
              </Button>,
              <Button
                style="highlight"
                testSection="empty-cta-dialog-submit"
                key="submit"
                onClick={this.submit}>
                Submit
              </Button>,
            ]}
          />
        </Footer>
      </Wrapper>
    );
  }
}

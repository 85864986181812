import flux from 'core/flux';

import { getters as ViewGetters } from 'optly/modules/entity/view';
import LayerExperimentHumanReadable from 'optly/modules/entity/layer_experiment/human_readable';
import Editor from 'bundles/p13n/modules/editor';

import actions from '../actions';

const EditorToolbarMixin = {
  methods: {
    /**
     * Handle view change via the editor toolbar. For example, when:
     * a) The user clicks on the view item row in the toolbar (it should switch to the view that was clicked on),
     * b) The user adds a new view (it should switch to the newly added view),
     * c) The user updates an existing view (it should switch to the updated view).
     * @param viewId
     *
     * NB: You need to bind `this` to the correct component!
     */
    handleViewChangeClick(viewId) {
      const switchToView = flux.evaluateToJS(ViewGetters.byId(viewId));
      const switchToVariation = flux.evaluateToJS(
        Editor.getters.selectedVariationId,
      );
      const selectedExperimentOrSectionId = flux.evaluateToJS(
        Editor.getters.selectedExperimentOrSectionId,
      );
      actions.confirmNavigation(
        this.currentlyEditingChangeIsDirty,
        LayerExperimentHumanReadable.CHANGE,
        () => {
          Editor.actions.unsetCurrentlyEditingChange();
          Editor.actions.unselectAction();

          const data = {
            view: switchToView,
            experimentOrSectionId: selectedExperimentOrSectionId,
          };
          if (
            switchToVariation ===
            Editor.constants.EVERYONE_ELSE_AUDIENCE.variation_id
          ) {
            data.isReadOnlyMode = true;
          } else {
            data.variationId = switchToVariation;
          }
          this.$options.onActionSelect(data);
        },
      );
    },
  },
};

export default EditorToolbarMixin;

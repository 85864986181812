import React from 'react';
import PropTypes from 'prop-types';
import { Immutable } from 'nuclear-js';
import Card from 'react_components/card';
import { Table } from 'optimizely-oui';
import moment from 'moment';

import ScheduleFns from 'optly/modules/entity/schedule/fns';

const SummaryStatsSection = ({ catalog, summaryStats }) => {
  const pageViewEventsOccurred = Number(
    summaryStats.get('total_num_catalog_events'),
  ).toLocaleString();
  const userEventsOccurred = Number(
    summaryStats.get('total_num_user_events'),
  ).toLocaleString();
  const totalCatalogItems = Number(
    summaryStats.get('total_num_items'),
  ).toLocaleString();
  const totalVisitors = Number(
    summaryStats.get('total_num_visitors'),
  ).toLocaleString();

  /**
   * Return the string that indicates the last time that the job status is updated successfully.
   *
   * @returns {String}
   */
  const renderUpdatedTime = () => {
    const lastRun = catalog.getIn(['job_status_summary', 'last_run']);
    const date = moment(lastRun).format('LL');
    const time = moment(lastRun).format('LT');
    const timeZone = ScheduleFns.getCurrentTimezoneName().replace('_', ' ');

    return (
      <div
        className="muted micro zeta"
        data-test-section="catalog-profiler-last-generated-time">
        Last generated: {`${date} at ${time} (${timeZone})`}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="flex">
        <h3 className="flex--1" data-test-section="summary-stats-section-title">
          Summary for the last 7 days
        </h3>
        {renderUpdatedTime()}
      </div>
      <Card testSection="catalog-summary-card">
        <Card.Body>
          <Table style="wall">
            <Table.TBody>
              <Table.TR>
                <Table.TD>
                  <div className="text--left">
                    <div className="micro muted push-half--bottom">
                      Page View Events Occurred
                    </div>
                    <div
                      className="beta"
                      data-test-section="page-view-events-occurred">
                      {pageViewEventsOccurred}
                    </div>
                  </div>
                </Table.TD>
                <Table.TD>
                  <div className="text--left">
                    <div className="micro muted push-half--bottom">
                      User Events Occurred
                    </div>
                    <div
                      className="beta"
                      data-test-section="user-events-occurred">
                      {userEventsOccurred}
                    </div>
                  </div>
                </Table.TD>
                <Table.TD>
                  <div className="text--left">
                    <div className="micro muted push-half--bottom">
                      Total Catalog Items
                    </div>
                    <div
                      className="beta"
                      data-test-section="total-catalog-items">
                      {totalCatalogItems}
                    </div>
                  </div>
                </Table.TD>
                <Table.TD>
                  <div className="text--left">
                    <div className="micro muted push-half--bottom">
                      Total Visitors
                    </div>
                    <div className="beta" data-test-section="total-visitors">
                      {totalVisitors}
                    </div>
                  </div>
                </Table.TD>
              </Table.TR>
            </Table.TBody>
          </Table>
          <hr className="lego-rule" />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

SummaryStatsSection.propTypes = {
  catalog: PropTypes.instanceOf(Immutable.Map).isRequired,
  /**
   * an Immutable Map containing details about the summary of events, items, and visitors for the given catalog
   *
   *    num_events_average_item
   *    num_events_average_visitor
   *    num_events_median_item
   *    num_events_median_visitor
   *    total_num_catalog_events
   *    total_num_failed_items
   *    total_num_items
   *    total_num_user_events
   *    total_num_visitors
   */
  summaryStats: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default SummaryStatsSection;

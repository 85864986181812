import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Link } from 'optimizely-oui';

function ShowArchivedItemsCtaIfAvailable({
  fetchAnyProjectEntityAndReturnTrueIfPresent,
  handleFetchArchivedItems,
}) {
  const [status, setStatus] = useState('idle');

  const loadArchivedItems = () => {
    let isMounted = true;

    if (status === 'idle') {
      setStatus('loading');
      fetchAnyProjectEntityAndReturnTrueIfPresent().then(data => {
        if (isMounted) {
          setStatus(data ? 'success' : 'failed');
        }
      });
    }

    return () => {
      isMounted = false;
    };
  };

  useEffect(loadArchivedItems, []);

  return status === 'success' ? (
    <p className="push--top flex flex--dead-center">
      <span className="push-half--right">Or</span>
      <Button
        ariaLabel="Show archived items"
        onClick={handleFetchArchivedItems}
        style="unstyled"
        testSection="show-archived-items-button">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link>show archived items</Link>.
      </Button>
    </p>
  ) : null;
}

ShowArchivedItemsCtaIfAvailable.propTypes = {
  fetchAnyProjectEntityAndReturnTrueIfPresent: PropTypes.func.isRequired,
  handleFetchArchivedItems: PropTypes.func.isRequired,
};

export default ShowArchivedItemsCtaIfAvailable;

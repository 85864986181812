import _ from 'lodash';
import sort from 'optly/utils/sort';

/**
 * Module specific pure functions
 */

export const sortingFuncs = _.extend({}, sort.functions, {
  // it so happens that experiment status can be sorted in alphabetical order
  experimentStatus: sort.reverse(sort.functions.string),
});

export default {
  ...sortingFuncs,
};

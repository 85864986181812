import _ from 'lodash';

import flux from 'core/flux';
import ui from 'core/ui';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import EditorActions from 'bundles/p13n/modules/editor/actions';
import EditorConstants from 'bundles/p13n/modules/editor/constants';
import EditorGetters from 'bundles/p13n/modules/editor/getters';
import EditorIframe from 'bundles/p13n/modules/editor_iframe';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
import PermissionsModuleGetters from 'optly/modules/permissions/getters';
import PredefinedCategoriesGetters from 'bundles/p13n/modules/predefined_categories/getters';
import ChangeEditorSidebarMixin from 'bundles/p13n/modules/ui/mixins/change_editor_sidebar';
import TrackClicksChangeGetters from 'bundles/p13n/modules/track_clicks_change/getters';
import DiscloseDirective from 'optly/directives/disclose';

import BackgroundComponent from './background';
import BaseComponent from './base';
import BordersComponent from './borders';
import DimensionsComponent from './dimensions';
import ElementCodeComponent from './element_code';
import LayoutComponent from './layout';
import SidebarHeaderComponent from '../sidebar_header';
import TimingComponent from './timing';
import TrackClicksComponent from './track_clicks';
import TypographyComponent from './typography';
import ComponentHtmlTemplate from './template.html';

const changeEditorSidebarComponent = {
  replace: true,

  template: ComponentHtmlTemplate,

  componentId: 'p13n-change-editor-sidebar',

  components: {
    background: BackgroundComponent,
    base: BaseComponent,
    borders: BordersComponent,
    'element-code': ElementCodeComponent,
    layout: LayoutComponent,
    'sidebar-header': SidebarHeaderComponent,
    timing: TimingComponent,
    typography: TypographyComponent,
  },

  directives: {
    disclose: DiscloseDirective,
  },

  data: {
    changeStatuses: LayerExperimentEnums.ChangeStatuses,
    elementChangeSections: EditorConstants.ElementChangeSections,
    revertDrawerShown: false,
    shouldShowLayoutSectionExpanded: false,
    expandedSectionsList: {},
  },

  computed: {
    /**
     * Get the text of the revert button
     * @returns {string}
     */
    revertButtonText() {
      return this.revertDrawerShown ? tr('Cancel') : tr('Revert to...');
    },
  },

  methods: {
    /**
     * Manually blur all input fields. Clicking inside an iframe doesn't cause
     * a focused input box to blur, so use this method to do that manually.
     */
    blurInputFields() {
      this.$broadcast('blurInput');
    },

    /**
     * Delete the change if there are no changes and unselect it.
     */
    discardChange() {
      if (__DEV__) {
        console.debug(`[EDITOR_SIDEBAR] Discarded change: ${this.currentlyEditingChange.id}`); // eslint-disable-line
      }
      // TODO(wfw): this should reset to elementInfo or last saved change value
    },

    /**
     * Broadcast a message so that child code mirror components can render.
     */
    displayCodeMirrorComponents() {
      this.$broadcast('displayCodeMirrorComponents');

      // set the Styles expanded section
      this.setExpandedSection('STYLES');
    },

    /**
     * Based on the element clicked on, select or create a change.
     *
     * Because this component is not re-instantiated for the new change, we must manually
     * do a lot of setting to update the values of the input fields based on the new element.
     * @param {object} payload
     * @param {string} payload.selector
     * @param {Array} payload.selectorInfo
     */
    handleIframeClick(payload) {
      if (!this.editingEnabled) {
        return;
      }
      EditorActions.discardChangesAndSetChangeBasedOnSelector(
        payload,
        this.handleSelectedChangeUpdate.bind(this),
      );
    },

    /**
     * Container function to reinitialize the editor sidebar after a new change is selected
     */
    handleSelectedChangeUpdate() {
      this.blurInputFields();
    },

    /**
     * Setup the change editor sidebar image settings and input fields.
     */
    setupChange() {
      if (this.currentlyEditingChange) {
        this.shouldShowLayoutSectionExpanded =
          !this.selectedElementNodeNameMatches('IMG') &&
          !this.selectedElementNodeNameMatches('A');

        this.expandedSectionsList = {
          LAYOUT: this.shouldShowLayoutSectionExpanded,
        };
      }
    },

    /**
     * Determines visibility of given section based on the currently selected element type
     *
     * @param {string} section - The enum representing the section to check for
     * @returns {boolean}
     */
    shouldShowSection(section) {
      switch (section) {
        case this.elementChangeSections.BACKGROUND:
        case this.elementChangeSections.TYPOGRAPHY:
          return !this.selectedElementNodeNameMatches('IMG');
        case this.elementChangeSections.DIMENSIONS:
          return !this.selectedElementNodeNameMatches('A');
        case this.elementChangeSections.TIMING:
          return this.canUseAsyncChanges || this.shouldShowDependencyManagement;
        case this.elementChangeSections.TRACK_CLICKS:
          return this.canCreateUserEvent;
        default:
          return true;
      }
    },

    /**
     * Try to navigate to an existing change based on selector, otherwise create a new change
     * and switch to that.
     * @param {string} selector - The unique selector generated by selectorator for the element
     */
    switchToChange(selector) {
      if (this.currentlyEditingChangeIsDirty) {
        const message = tr(
          'Selecting the containing link element will create a new change for that element and discard your unsaved changes for this element. Are you sure you want to switch?',
        );
        this.confirmDiscardChanges(message).then(() => {
          EditorIframe.actions
            .applyChangesAndFetchElementInfo(
              this.activeFrameId,
              flux.evaluateToJS(
                EditorGetters.currentlyEditingActionFormatted(true),
              ),
              selector,
            )
            .then(_.partial(EditorActions.setChangeBasedOnSelector, selector));
        });
        this.handleSelectedChangeUpdate();
      } else {
        EditorIframe.actions
          .fetchElementInfo(this.activeFrameId, selector)
          .then(_.partial(EditorActions.setChangeBasedOnSelector, selector));
        this.handleSelectedChangeUpdate();
      }
    },

    /**
     * Toggle state of revert drawer
     */
    toggleRevertDrawerShown() {
      this.revertDrawerShown = !this.revertDrawerShown;
    },

    setExpandedSection(section) {
      this.expandedSectionsList[section] = !this.expandedSectionsList[section];
    },
  },

  created() {
    flux.bindVueValues(this, {
      activeFrameId: EditorGetters.activeFrameId,
      canCreateUserEvent: [
        CurrentProjectGetters.project,
        PermissionsModuleFns.canCreateUserEvent,
      ],
      canUseAsyncChanges: PermissionsModuleGetters.canUseAsyncChanges,
      changeListSidebarComponentConfig:
        EditorGetters.changeListSidebarComponentConfig,
      currentlyEditingChange: EditorGetters.currentlyEditingChange,
      currentlyEditingChangeIsDirty:
        EditorGetters.currentlyEditingChangeIsDirty,
      currentSelectorType: EditorGetters.currentSelectorType,
      editingEnabled: EditorGetters.changeEditorEditingEnabled,
      isEditorReadOnly: EditorGetters.isEditorReadOnly,
      shouldShowDependencyManagement: EditorGetters.showDependencyManagement,
    });
  },

  ready() {
    this.setupChange();

    this.$iframeComponent = flux.evaluateToJS(
      EditorIframe.getters.iframeComponent(this.activeFrameId),
    );
    this.$iframeClickCallback = this.handleIframeClick.bind(this);

    if (this.$iframeComponent) {
      this.$iframeComponent.$on(
        EditorIframe.enums.IFrameMessageTypes.CLICK,
        this.$iframeClickCallback,
      );
    }

    ui.renderReactComponent(this, {
      component: DimensionsComponent,
      el: this.$$.dimensions,

      props: {},

      dataBindings: {
        changeStatusMap: EditorGetters.changeStatusMap(),
        heightValue: EditorGetters.cssValueFromChangeOrElement('height'),
        widthValue: EditorGetters.cssValueFromChangeOrElement('width'),
      },
    });

    ui.renderReactComponent(this, {
      component: TrackClicksComponent,
      el: this.$$.trackClicks,
      dataBindings: {
        changeStatus: TrackClicksChangeGetters.getTrackClicksChangeStatus,
        nameValue: TrackClicksChangeGetters.trackClicksChangeName,
        enabled: TrackClicksChangeGetters.trackClicksChangeIsEnabled,
        eventCategories: PredefinedCategoriesGetters.getUngroupedList(
          'user_events',
        ),
        showCampaignSpecificEventText:
          TrackClicksChangeGetters.showCampaignSpecificEventText,
      },
    });

    // Observe the currentlyEditingChange and update the change input fields when it changes. This is
    // preferred to using v-value because we can explicitly not update the field that is currently being typed in.
    this.__unwatchCurrentlyEditingChange = flux.observe(
      EditorGetters.currentlyEditingChange,
      this.setupChange.bind(this),
    );
  },

  beforeDestroy() {
    if (this.__unwatchCurrentlyEditingChange) {
      this.__unwatchCurrentlyEditingChange();
    }
    if (this.$iframeComponent) {
      this.$iframeComponent.$off(
        EditorIframe.enums.IFrameMessageTypes.CLICK,
        this.$iframeClickCallback,
      );
    }
  },
};

// add editable selector feature to this component
// via the editableSelectorMixin
export default _.merge(
  {},
  ChangeEditorSidebarMixin,
  changeEditorSidebarComponent,
);

import TimezoneJson from '../../../../../../shared_configs/timezones.json';

const { timezones: TIMEZONES } = TimezoneJson;

// 'require' loads -0 as 0 when loading timezones.json, which would cause WEB-978 to resurface
TIMEZONES.push({ offset: -0, title: 'GMT+00:00' });

export { TIMEZONES };

export default {
  TIMEZONES,
};

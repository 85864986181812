import config from 'atomic-config';
import flux from 'core/flux';

import actionTypes from './action_types';
import enums from './enums';

export default {
  setActiveVersion(version) {
    if (
      version !== enums.versions.CLASSIC &&
      version !== enums.versions.OPTIMIZELY_X
    ) {
      throw new Error(`Cannot set unknown UI version: ${version}`);
    }

    flux.dispatch(actionTypes.SET_ACTIVE_UI_VERSION, {
      version,
    });
    const deployedVersionId = config.get('env.VERSION_NUMBER') || 'local';
    flux.dispatch(actionTypes.SET_DEPLOYED_VERSION_ID, {
      deployedVersionId,
    });
  },
};

import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import { actionTypes } from '../action_types';

/**
 * tabsStore
 * Responsible for the following state management:
 * TODO: fill in
 */
export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      activeTab: null,
      activeSubTab: null,
      activeCanvasIntegration: null,
    });
  },

  initialize() {
    this.on(actionTypes.DASHBOARD_SWITCH_MAIN_TAB, switchMainTab);
    this.on(actionTypes.DASHBOARD_SWITCH_SUB_TAB, switchSubTab);
    this.on(
      actionTypes.DASHBOARD_SWITCH_CANVAS_INTEGRATION,
      switchCanvasIntegration,
    );
  },
});

/**
 * Switches the main tab state
 * @param {object} payload
 */
function switchMainTab(state, payload) {
  if (state.get('activeTab') !== payload.tabId) {
    return state.withMutations(currentState => {
      currentState.set('activeTab', payload.tabId);
      currentState.set('activeSubTab', null);
    });
  }
  return state;
}

/**
 * Switches the Settings tab's subtab state
 * @param {object} payload
 */
function switchSubTab(state, payload) {
  if (state.get('activeSubTab') !== payload.tabId) {
    return state.set('activeSubTab', payload.tabId);
  }
  return state;
}

/**
 * Switches the active Canvas integration state
 * @param {object} payload
 */
function switchCanvasIntegration(state, payload) {
  return state.set('activeCanvasIntegration', payload.integrationId);
}

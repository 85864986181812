/**
 * Helper utils for calculating the limit and offset for
 * a page of results
 *
 * @author Asa Schachar (asa@optimizely.com)
 */
const cloneDeep = require('optly/clone_deep').default;

const getRelativePageFilters = function(filters, direction) {
  const newFilters = cloneDeep(filters);
  if (!newFilters.$limit) {
    throw new Error(
      'getPageFilter functions must supply a filter that contains a $limit value',
    );
  }

  const offset = newFilters.$offset || 0;
  newFilters.$offset = offset + newFilters.$limit * direction;
  return newFilters;
};

function getNextPageFilters(filters) {
  return getRelativePageFilters(filters, 1);
}

function getPreviousPageFilters(filters) {
  return getRelativePageFilters(filters, -1);
}

function getSpecificPageFilters(filters, pageNum) {
  const newFilters = cloneDeep(filters);
  if (!newFilters.$limit) {
    throw new Error(
      'getPageFilter functions must supply a filter that contains a $limit value',
    );
  }

  newFilters.$offset = pageNum * newFilters.$limit;
  return newFilters;
}

module.exports = {
  getNextPageFilters,
  getPreviousPageFilters,
  getSpecificPageFilters,
};

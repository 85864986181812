import React from 'react';

import { SelectDropdown } from '@optimizely/axiom';

import { ConditionGroup } from '../types';

const DROPDOWN_ITEMS = [
  {
    label: 'Any',
    value: ConditionGroup.OR,
  },
  {
    label: 'All',
    value: ConditionGroup.AND,
  },
];

type AudienceConditionPickerProps = {
  onChange: (condition: ConditionGroup) => void;
  currentCondition: ConditionGroup;
};

const AudienceConditionPicker = (props: AudienceConditionPickerProps) => {
  const { onChange, currentCondition } = props;

  return (
    <div className="flex flex-align--center push--bottom">
      <div>
        <SelectDropdown
          buttonStyle="underline"
          isDisabled={false}
          items={DROPDOWN_ITEMS}
          onChange={onChange}
          value={currentCondition}
        />
      </div>
      <div>of the following audiences:</div>
    </div>
  );
};

export default AudienceConditionPicker;

import _ from 'lodash';

import flux from 'core/flux';
import ui from 'core/ui';
import { toImmutable } from 'optly/immutable';

import CampaignManagerActionsFns from 'bundles/p13n/modules/campaign_manager/actions_fns';
import SidebarHeaderMixin from 'bundles/p13n/modules/ui/mixins/sidebar_header';
import { ChangeEditorSidebar as ChangeEditorSidebarMixin } from 'bundles/p13n/modules/ui/mixins';
import EditorGetters from 'bundles/p13n/modules/editor/getters';
import SectionModuleActionsFns from 'bundles/p13n/sections/manager_mvt/section_module/actions_fns';

import Template from './new_variation_dialog.html';

const CreateVariationDialog = {
  replace: true,

  template: Template,

  data: {
    newVariationName: '',
    variation: {},
    hasErrors: false,
    isSaving: false,
  },

  methods: {
    getNewVariation() {
      if (this.isMultivariate) {
        return SectionModuleActionsFns.saveNewVariationInMVTSection(
          toImmutable(this.experiment),
          this.newVariationName,
        );
      }

      return CampaignManagerActionsFns.trySavingNewVariation(
        this.newVariationName,
        this.experiment,
      );
    },

    handleErrors(error) {
      if (error.type === 'name error') {
        this.hasErrors = true;
        return;
      }

      this.deleteVariation(this.variation.variation_id);

      this.showFeedback({
        type: 'bad-news',
        message: 'Something went wrong, try again!',
      });
      ui.hideDialog();
    },

    saveAndRedirectNewVariation(redirect) {
      this.hasErrors = false;
      this.isSaving = true;

      const newVariation = this.getNewVariation();

      newVariation
        .then(experiment => {
          this.variation = experiment.variations.find(
            variation => variation.name === this.newVariationName,
          );

          return this.saveChange(this.variation.variation_id);
        })
        .then(item => {
          ui.hideDialog();

          if (!redirect) {
            this.revertChangeAttribute('html');
            this.showFeedback({
              type: 'good-news',
              message: 'New Variation has been created',
              variationId: this.variation.variation_id,
            });
          }

          if (redirect) {
            this.redirectToNewVariation(this.variation.variation_id);
          }
        })
        .catch(error => {
          this.isSaving = false;
          this.handleErrors(error);
        });
    },
  },

  afterDestroy() {
    this.toggleIsCreatingVariation();
  },

  created() {
    flux.bindVueValues(this, {
      currentlyEditingChange: EditorGetters.currentlyEditingChange,
      currentlyEditingChangeIsDirty:
        EditorGetters.currentlyEditingChangeIsDirty,
    });
  },

  ready() {
    this.newVariationName = this.suggestedNewVariationName;
  },
};

export default _.merge(
  {},
  ChangeEditorSidebarMixin,
  SidebarHeaderMixin,
  CreateVariationDialog,
);

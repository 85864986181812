export const currentlyEditingMetricWrapper = [
  'metricsManager',
  'currentlyEditingMetricWrapper',
];
export const outlierFilter = ['metricsManager', 'outlierFilter'];
export const workingMetricWrappers = [
  'metricsManager',
  'workingMetricWrappers',
];

export const primaryWorkingMetric = [
  workingMetricWrappers,
  _workingMetricWrappers => {
    if (!_workingMetricWrappers.size) {
      return null;
    }

    return _workingMetricWrappers.first().get('metric');
  },
];

export const workingMetrics = [
  workingMetricWrappers,
  _workingMetricWrappers =>
    _workingMetricWrappers.map(metricWrapper => metricWrapper.get('metric')),
];

export default {
  currentlyEditingMetricWrapper,
  outlierFilter,
  primaryWorkingMetric,
  workingMetrics,
  workingMetricWrappers,
};

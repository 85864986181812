import React from 'react';

import PropTypes from 'prop-types';
import {
  Button,
  DialogNew,
  Fieldset,
  Dropdown,
  Label,
  Textarea,
} from '@optimizely/axiom';
import { api as SegmentJSAPI } from '@optimizely/segment-js';

import Immutable, { toJS } from 'optly/immutable';

import { concludeAndPauseLayer, concludeLayer } from './actions';
import { CONCLUSION_MAX_LEN } from 'optly/utils/constants';

const title = 'Conclude Experiment';
const subTitle =
  "This changes the experiment status, so it appears as 'Concluded' in the experiment list. Upon concluding, you can no longer re-publish your experiment.";

const titleP13N = 'Conclude Campaign';
const subTitleP13N =
  "This changes the campaign status, so it appears as 'Concluded'. Upon concluding, you can no longer re-publish your campaign.";

const POSITIVE_RESULT_OPTION = {
  id: 'positive',
  value: 'Positive',
};

const NEGATIVE_RESULT_OPTION = {
  id: 'negative',
  value: 'Negative',
};

const INCONCLUSIVE_RESULT_OPTION = {
  id: 'inconclusive',
  value: 'Inconclusive',
};

const resultsOptions = [
  POSITIVE_RESULT_OPTION,
  NEGATIVE_RESULT_OPTION,
  INCONCLUSIVE_RESULT_OPTION,
];

export default class ConcludeExperimentDialog extends React.PureComponent {
  static propTypes = {
    isLayerActive: PropTypes.bool.isRequired,
    layer: PropTypes.instanceOf(Immutable.Map).isRequired,
    liveCommit: PropTypes.instanceOf(Immutable.Map).isRequired,
    onCloseHandler: PropTypes.func.isRequired,
    onConcludeHandler: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
  };

  state = {
    result: POSITIVE_RESULT_OPTION.id,
    conclusions: '',
    isConcluding: false,
  };

  updateResultOutcome = result => () => this.setState({ result });

  onConclusionsChangeHandler = ({ target }) => {
    this.setState({ conclusions: target.value });
  };

  onConcludeClick = () => {
    const {
      isLayerActive,
      layer,
      liveCommit,
      projectId,
      onConcludeHandler,
    } = this.props;
    const { result, conclusions } = this.state;

    SegmentJSAPI.track('Clicks on final conclude CTA');

    this.setState({ isConcluding: true });

    const plainLayer = toJS(layer);

    if (liveCommit && isLayerActive) {
      concludeAndPauseLayer(
        plainLayer,
        result,
        conclusions,
        projectId,
        liveCommit,
        onConcludeHandler,
      );
    } else {
      concludeLayer(plainLayer, result, conclusions, onConcludeHandler);
    }
  };

  render() {
    const { onCloseHandler, layer } = this.props;
    const plainLayer = toJS(layer);
    const { result, conclusions, isConcluding } = this.state;

    const dialogContent = (
      <>
        <Fieldset>
          <Label>Results Outcome</Label>
          <Dropdown
            buttonContent={resultsOptions.find(({ id }) => id === result).value}
            arrowIcon="down"
            placement="bottom-start"
            fullWidth={true}>
            <Dropdown.Contents className="test">
              {resultsOptions.map(({ id, value }) => (
                <Dropdown.ListItem key={id}>
                  <Dropdown.BlockLink
                    onClick={this.updateResultOutcome(id)}
                    testSection={`conclude-experiment-dialog-result-option-${id}`}>
                    <Dropdown.BlockLinkText text={value} />
                  </Dropdown.BlockLink>
                </Dropdown.ListItem>
              ))}
            </Dropdown.Contents>
          </Dropdown>
        </Fieldset>
        <Fieldset>
          <Textarea
            value={conclusions}
            label="Conclusions"
            onChange={this.onConclusionsChangeHandler}
            placeholder="Briefly summarize the learnings from this experiment."
            testSection="conclude-experiment-dialog-conclusions"
            isRequired={true}
            maxLength={CONCLUSION_MAX_LEN}
          />
        </Fieldset>
      </>
    );

    return (
      <DialogNew
        title={plainLayer.type != "personalization" ? title : titleP13N}
        subtitle={plainLayer.type != "personalization" ? subTitle : subTitleP13N}
        className="conclude-experiment-dialog"
        testSection="conclude-experiment-dialog"
        onClose={onCloseHandler}
        hasCloseButton={true}
        footerButtonList={[
          <Button
            key="close"
            onClick={onCloseHandler}
            style="plain"
            testSection="conclude-experiment-dialog-cancel-button">
            Cancel
          </Button>,
          <Button
            key="conclude"
            onClick={this.onConcludeClick}
            style="highlight"
            isDisabled={!conclusions || isConcluding}
            testSection="conclude-experiment-dialog-conclude-button">
            Conclude
          </Button>,
        ]}>
        {dialogContent}
      </DialogNew>
    );
  }
}

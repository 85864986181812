import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button, DateRangePicker, Dropdown, Icon } from 'optimizely-oui';

import { UiFilterType } from '../component_module/constants';
import { getReadableDateRange } from '../component_module/fns';

const DateFilter = ({
  currentProjectCreatedDate,
  endTime,
  earliestAllowedFilterDate,
  handleFiltersAction,
  isDisabled,
  startTime,
}) => {
  let earliestSelectableDate = moment(earliestAllowedFilterDate);
  if (
    currentProjectCreatedDate &&
    moment(currentProjectCreatedDate).isAfter(earliestSelectableDate)
  ) {
    earliestSelectableDate = moment(currentProjectCreatedDate);
  }
  const readableDateRange = getReadableDateRange(
    startTime,
    endTime,
    earliestSelectableDate,
  );

  // React hook to handle isDetailExpanded state
  const [dateFilter, setDateFilter] = useState({
    selectedStartTime: startTime,
    selectedEndTime: endTime,
  });
  const { selectedStartTime, selectedEndTime } = dateFilter;

  const [
    dateRangeStartTimeFilterName,
    dateRangeEndTimeFilterName,
  ] = UiFilterType.DATE_RANGE.filterName;
  return (
    <div className="push--right">
      <Dropdown
        renderActivator={({ onClick, buttonRef, onBlur }) => (
          <Button
            buttonRef={buttonRef}
            size="narrow"
            style="plain"
            onClick={onClick}
            testSection="change-history-entity-type-button">
            <div className="flex">
              <Icon
                className="push--right push--top"
                name="calendar"
                size="small"
              />
              <div className="line--tight text--left push--right">
                <div className="micro muted">
                  {UiFilterType.DATE_RANGE.label}
                </div>
                <div data-test-section="selected-date-range">
                  {readableDateRange}
                </div>
              </div>
              <div>
                <Icon name="angle-down" size="small" />
              </div>
            </div>
          </Button>
        )}
        isDisabled={isDisabled}
        placement="bottom-start"
        shouldHideChildrenOnClick={false}
        style="plain"
        testSection="change-history-date-range-filter"
        width="auto">
        {({ handleHideChildren }) => (
          <div className="soft-double--ends soft-quad--sides background--white">
            <DateRangePicker
              endDateInputId="change-history-end-date"
              endDateInputLabel=""
              endTimeInputId="change-history-end-time"
              endTimeInputLabel=""
              focusedInput="startDate"
              hasTimeInputs={true}
              initialEndDate={selectedEndTime ? moment(selectedEndTime) : null}
              initialStartDate={
                selectedStartTime ? moment(selectedStartTime) : null
              }
              initialVisibleMonth={() =>
                selectedEndTime
                  ? moment(selectedEndTime).subtract(1, 'M')
                  : moment().subtract(1, 'M')
              }
              isBorderless={true}
              isFutureDateSelectable={false}
              isOutsideRange={day => {
                const isFutureDate =
                  day.isAfter(moment()) && !day.isSame(moment(), 'day');
                const isOldDate = day.isBefore(earliestSelectableDate, 'day');
                return isOldDate || isFutureDate;
              }}
              isPastDateSelectable={true}
              keepOpenAlways={true}
              onDatesChange={({ endDate, startDate }) =>
                setDateFilter({
                  selectedStartTime: startDate,
                  selectedEndTime: endDate,
                })
              }
              panelButtons={({ onDatesChange }) => [
                <Button
                  isDisabled={[selectedStartTime, selectedEndTime].every(
                    i => i === null,
                  )}
                  key="clear"
                  onClick={() => {
                    onDatesChange({ startDate: null, endDate: null });
                    handleFiltersAction({
                      [dateRangeEndTimeFilterName]: null,
                      [dateRangeStartTimeFilterName]: null,
                    });
                    handleHideChildren();
                  }}
                  style="plain"
                  testSection="change-history-date-range-clear-button">
                  Clear
                </Button>,
                <Button
                  isDisabled={[selectedStartTime, selectedEndTime].every(
                    i => i === null,
                  )}
                  key="apply"
                  onClick={() => {
                    onDatesChange({
                      endDate: selectedEndTime,
                      startDate:
                        selectedStartTime ||
                        (selectedEndTime && earliestSelectableDate) ||
                        null,
                    });
                    handleFiltersAction({
                      [dateRangeEndTimeFilterName]:
                        (selectedEndTime &&
                          moment(selectedEndTime).toISOString()) ||
                        (selectedStartTime &&
                          selectedStartTime.toISOString()) ||
                        null,
                      [dateRangeStartTimeFilterName]:
                        (selectedStartTime &&
                          moment(selectedStartTime).toISOString()) ||
                        (selectedEndTime &&
                          earliestSelectableDate.toISOString()) ||
                        null,
                    });
                    handleHideChildren();
                  }}
                  style="highlight"
                  testSection="change-history-date-range-apply-button">
                  Apply
                </Button>,
              ]}
              presetPanelOptions={[
                {
                  endDate: moment().endOf('day'),
                  label: 'Last 7 days',
                  startDate: moment()
                    .subtract(7, 'days')
                    .startOf('day'),
                },
                {
                  endDate: moment().endOf('day'),
                  label: 'Last 30 days',
                  startDate: moment()
                    .subtract(30, 'days')
                    .startOf('day'),
                },
                {
                  endDate: moment().endOf('day'),
                  label: 'Last 60 days',
                  startDate: moment()
                    .subtract(60, 'days')
                    .startOf('day'),
                },
                {
                  endDate: moment().endOf('month'),
                  label: 'This Month',
                  startDate: moment().startOf('month'),
                },
              ]}
              startDateInputId="change-history-start-date"
              startDateInputLabel=""
              startTimeInputId="change-history-start-time"
              startTimeInputLabel=""
            />
          </div>
        )}
      </Dropdown>
    </div>
  );
};

DateFilter.propTypes = {
  currentProjectCreatedDate: PropTypes.string.isRequired,
  earliestAllowedFilterDate: PropTypes.string.isRequired,
  endTime: PropTypes.string,
  handleFiltersAction: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  startTime: PropTypes.string,
};

DateFilter.defaultProps = {
  endTime: null,
  isDisabled: false,
  startTime: null,
};

export default DateFilter;

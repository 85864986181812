// DEPRECATED: This table is only used in deprecated components.
const PropTypes = require('prop-types');
const React = require('react');

// components
const { Table } = require('optimizely-oui');

const {
  FEATURE_VARIABLE_TYPE_HUMAN_READABLES,
} = require('optly/modules/entity/feature_flag/constants');

const Immutable = require('optly/immutable').default;

const VariableValueInput = require('./variable_value_input');

const VariableValueTable = props => {
  const handleVariableValueChange = updatedVariable => {
    props.onVariableValueChange(updatedVariable);
  };

  const renderVariableRows = () => {
    const { isFormDirty } = props;
    if (props.variables) {
      return props.variables.map(variable => (
        <Table.TR
          testSection={`feature-variable-row-${variable.get('api_name')}`}
          key={`${props.feature.get('api_name')}-${variable.get('api_name')}`}>
          <Table.TD>
            <div className="weight--bold">{variable.get('api_name')}</div>
            <div className="muted">
              {FEATURE_VARIABLE_TYPE_HUMAN_READABLES[variable.get('type')]}
            </div>
          </Table.TD>
          <Table.TD>
            <div>
              <VariableValueInput
                disableVariable={props.disableVariables}
                onUpdateVariable={handleVariableValueChange}
                propertyToUpdate="value"
                variable={variable}
                isFormDirty={isFormDirty}
              />
            </div>
          </Table.TD>
        </Table.TR>
      ));
    }
    return '';
  };

  return (
    <Table
      density="tight"
      tableLayoutAlgorithm="auto"
      testSection="variable-value-table">
      <Table.THead>
        <Table.TR>
          <Table.TH width="35%">Variable Key</Table.TH>
          <Table.TH>Value</Table.TH>
        </Table.TR>
      </Table.THead>
      <Table.TBody>{renderVariableRows()}</Table.TBody>
    </Table>
  );
};

VariableValueTable.propTypes = {
  disableVariables: PropTypes.bool.isRequired,
  feature: PropTypes.instanceOf(Immutable.Map).isRequired,
  isFormDirty: PropTypes.bool,
  variables: PropTypes.instanceOf(Immutable.List).isRequired,
  onVariableValueChange: PropTypes.func,
};

module.exports = VariableValueTable;

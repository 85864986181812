import React, { useRef } from 'react';
import classNames from 'classnames';
import { ButtonIcon, Checkbox, Link, Typography } from 'optimizely-oui';

import ApplyCustomizationDialog from 'bundles/p13n/components/dialogs/apply_customization';
import { useToggle } from 'optly/hooks/use_toggle';
import useOnClickOutside from 'optly/hooks/useOnClickOutside';

const selectDeselectCheckboxes = (action: boolean) => {
  const checkboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
    '.customization-drawer .customization-drawer__elements input[type="checkbox"]',
  );

  for (let i = 0; i < checkboxes.length; i++) {
    checkboxes[i].checked = action;
  }
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  columns: [];
  onSubmit: (values: Record<string, boolean>) => void;
}

const CustomizationDrawer = ({
  isOpen = false,
  onClose,
  columns,
  onSubmit,
}: IProps) => {
  const [isInformationDialogOpen, toggleInformationDialog] = useToggle();
  const customizationDrawerRef = useRef<HTMLFormElement>(null);
  useOnClickOutside(
    customizationDrawerRef,
    () => isOpen && !isInformationDialogOpen && onClose(),
  );

  const handleSubmit = () => {
    const formData = new FormData(
      customizationDrawerRef.current as HTMLFormElement,
    );
    const checkedValues: Record<string, boolean> = {};

    formData.forEach((value, name) => {
      if (value) {
        checkedValues[name] = true;
      }
    });

    onSubmit(checkedValues);
  };

  const selectAllHandler = () => {
    selectDeselectCheckboxes(true);
    handleSubmit();
  };

  const deselectAllHandler = () => {
    selectDeselectCheckboxes(false);
    onSubmit({});
  };

  return (
    <form
      className={classNames(
        'customization-drawer soft-double--top soft-double--left soft-double--right',
        {
          'is-open': isOpen,
        },
      )}
      ref={customizationDrawerRef}>
      {isInformationDialogOpen ? (
        <ApplyCustomizationDialog onClose={toggleInformationDialog} />
      ) : null}
      <div className="flex justify-content-end">
        <ButtonIcon iconName="xmark" style="plain" onClick={onClose} />
      </div>
      <Typography tag="h3" type="header3">
        Show/Hide Columns
      </Typography>{' '}
      <Typography
        tag="p"
        type="body"
        className="customization-drawer__description">
        Select which columns to show or hide by clicking the checkboxes below.
        You can also{' '}
        <Link href="#" onClick={toggleInformationDialog}>
          bookmark and share
        </Link>{' '}
        this view.
      </Typography>
      <div className="push-double--top">
        <Link href="#" onClick={selectAllHandler}>
          Select all
        </Link>
        <span className="customization-drawer__divider">|</span>
        <Link href="#" onClick={deselectAllHandler}>
          Deselect all
        </Link>{' '}
      </div>
      <div className="push-double--top customization-drawer__elements">
        {columns.map(column => {
          return (
            <div key={column.id} className="push--bottom">
              <Checkbox
                name={column.id}
                label={column.header}
                defaultChecked={column.isVisible}
                onChange={handleSubmit}
              />
            </div>
          );
        })}
      </div>
    </form>
  );
};

export default CustomizationDrawer;

/**
 * Converts an array list of items to a human readable list:
 *  Example:
 *    Input : [ 1, 2, 3 ]
 *    Output: 1, 2, and 3
 *
 * @param {Array} list
 * @return {String}
 */
module.exports = function(list) {
  let string = '';
  const num_items = list.length;
  if (num_items > 0) {
    list = list.map((element, index) => {
      const isLastElement = index === num_items - 1;

      // Append commas to the end of every element except for the last
      if (num_items > 2 && !isLastElement) {
        element += ',';

        // Prepend 'and ' to the last element in the array
      } else if (num_items >= 2 && isLastElement) {
        element = `and ${element}`;
      }

      return element;
    });

    string = list.join(' ');
  }

  return string;
};

/*
 * Modules defined by electron and used outside the editor
 *
 * These modules are required and provided on the window.optlyDesktop
 * variable by the preload script of the optimizely desktop application
 *
 * https://github.com/optimizely/optimizely-desktop/blob/master/app/p13n/preload.js
 */
export default {
  clipboard: window.optlyDesktop && window.optlyDesktop.clipboard,
  electron: window.optlyDesktop && window.optlyDesktop.electron,
};

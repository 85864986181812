import { loadingWhenFetchAllPages } from 'core/modules/loading/actions';
import CatalogActions from 'optly/modules/entity/catalog/actions';
import EventModuleActions from 'optly/modules/entity/event/actions';
import RecommenderActions from 'optly/modules/entity/recommender/actions';
import TagActions from 'optly/modules/entity/tag/actions';
import ViewActions from 'optly/modules/entity/view/actions';

import { TABLE_IDS } from 'bundles/p13n/sections/implementation/section_module/constants';

import RecommenderDashboard from './main_content';

export const component = RecommenderDashboard;

/**
 * Fetch all active Recommenders that are associated with all catalogs in the current account.
 *
 * @param {Object} ctx
 * @param {Function} next
 */
export const routingSetup = (ctx, next) => {
  const projectID = ctx.currentProject.id;

  const active = {
    archived: false,
  };

  const activeByProjectID = {
    archived: false,
    project_id: projectID,
  };

  const recommendersFetchAllPages = RecommenderActions.fetchAllPagesForAccount(
    active,
  );

  /* Fetch (in the background) catalogs, events, tags, and views that are active in the current project. */
  CatalogActions.fetchAllPages(active);
  EventModuleActions.fetchAllPages(activeByProjectID);
  TagActions.fetchAll(activeByProjectID);
  ViewActions.fetchAllPages(activeByProjectID);

  loadingWhenFetchAllPages(TABLE_IDS.RECOMMENDER, recommendersFetchAllPages);

  recommendersFetchAllPages.firstPage.then(next);
};

export default {
  component,
  routingSetup,
};

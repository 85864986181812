import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

// alternate entity definition for account level events
const accountLevelActions = RestApi.createEntityActions({
  entity: definition.entity,
});

export default {
  ...baseEntityActions,
  /**
   * Alternate fetch all that works at the account level for shared projects
   * @param {Object?} filters
   * @param {Boolean?} force
   * @return {Deferred}
   */
  fetchAllForAccount(filters, force) {
    return accountLevelActions.fetchAll(filters, force);
  },

  /**
   * Overwrite the default delete and do a soft delete instead
   * @param {Event} event
   * @return {Deferred}
   */
  delete(event) {
    return this.archive(event);
  },

  /**
   * Archives an event
   * @param {Event} event
   * @return {Deferred}
   */
  archive(event) {
    return this.save({
      id: event.id,
      archived: true,
    });
  },

  /**
   * Unarchives an event
   * @param {Event} event
   * @return {Deferred}
   */
  unarchive(event) {
    return this.save({
      id: event.id,
      archived: false,
    });
  },

  /**
   * @description Fetch event via key. Used for validation in the creation dialog
   *
   * @param {number} projectId
   * @param {string} name
   * @return {Deferred}
   */
  fetchByName(projectId, name) {
    const fetchFilters = {
      project_id: projectId,
      name,
    };

    return this.fetchAll(fetchFilters, {
      force: true,
    });
  },
};

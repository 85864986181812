import config from 'atomic-config';

import flux from 'core/flux';
import { getters as authGetters } from 'optly/modules/auth';

import actionTypes from './action_types';

/**
 * @param {number} id
 * @return {Promise}
 */
const subscribeToEntityById = async id => {
  const url = `/api/v1/entity/${id}/visitors`;
  const isImpersonating = config.get('is_impersonating');
  const deferred = [fetch(url)];
  if (!isImpersonating) {
    deferred.push(
      fetch(url, {
        method: 'POST',
        headers: {
          'x-csrf-token':
            flux.evaluate(authGetters.csrfToken) || config.get('csrf', ''),
        },
      }),
    );
  }

  const responses = await Promise.all(deferred);
  responses.forEach(response => {
    if (!response.ok) {
      throw Error("Visitors' endpoint requests did not succeed.");
    }
  });
  const [visitorsResponse] = responses;
  const visitors = await visitorsResponse.json();
  return flux.dispatch(actionTypes.SET_ENTITY_VISITORS_BY_ID, {
    id,
    visitors,
  });
};

export default {
  subscribeToEntityById,
};

import PropTypes from 'prop-types';
import React from 'react';

import AudienceDetailPrimaryMetricImage from '/static/img/p13n/audience-detail-primary-metric.svg';

const ChartEmptyState = props => {
  const { message } = props;

  return (
    <div className="results__timeseries-chart flex--dead-center">
      <div className="text--center">
        <img
          alt="Chart unavailable"
          className="anchor--middle"
          src={AudienceDetailPrimaryMetricImage}
          width="200"
        />
        <h3 className="push-half--bottom">{message}</h3>
      </div>
    </div>
  );
};

ChartEmptyState.propTypes = {
  message: PropTypes.string,
};

ChartEmptyState.defaultProps = {
  message: 'Not enough data yet.',
};

export default ChartEmptyState;

import { Store } from 'nuclear-js';

import Immutable from 'optly/immutable';

import actionTypes from '../action_types';

export default Store({
  getInitialState() {
    return Immutable.Map({
      entitiesWithCacheHits: Immutable.Set(),
      isTrackingCacheHits: false,
    });
  },

  initialize() {
    this.on(actionTypes.TRACK_CACHE_HIT, trackCacheHit);
    this.on(actionTypes.START_TRACKING_CACHE_HITS, startTrackingCacheHits);
    this.on(actionTypes.STOP_TRACKING_CACHE_HITS, stopTrackingCacheHits);
  },
});

function trackCacheHit(state, payload) {
  return state.set(
    'entitiesWithCacheHits',
    state.get('entitiesWithCacheHits').add(payload.entity),
  );
}

function startTrackingCacheHits(state) {
  return state
    .set('entitiesWithCacheHits', Immutable.Set())
    .set('isTrackingCacheHits', true);
}

function stopTrackingCacheHits(state) {
  return state.set('isTrackingCacheHits', false);
}

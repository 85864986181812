import React from 'react';
import PropTypes from 'prop-types';

import { Input, Textarea } from 'optimizely-oui';

const EntityDetails = ({
  entity,
  entityInfo,
  renderErrorMessage,
  showValidationError,
  updateEntityProperty,
}) => (
  <React.Fragment>
    <div className="push-triple--bottom">
      <Input
        displayError={showValidationError}
        isRequired={true}
        label="Name"
        maxLength={1500}
        onChange={event => updateEntityProperty('name', event.target.value)}
        placeholder={entityInfo.namePlaceholder}
        testSection={`${entityInfo.entityType}-name-text`}
        type="text"
        value={entity.get('name')}
      />
      {showValidationError &&
        renderErrorMessage(
          'Please provide a name.',
          `${entityInfo.entityType}-name-error-message`,
        )}
    </div>
    <div className="push-triple--bottom">
      <Textarea
        displayError={false}
        label="Description"
        maxLength={1500}
        onChange={event =>
          updateEntityProperty('description', event.target.value)
        }
        placeholder={entityInfo.descriptionPlaceholder}
        testSection={`${entityInfo.entityType}-description-text`}
        type="text"
        value={entity.get('description')}
      />
    </div>
  </React.Fragment>
);

EntityDetails.propTypes = {
  entity: PropTypes.object.isRequired,
  entityInfo: PropTypes.object.isRequired,
  renderErrorMessage: PropTypes.func.isRequired,
  showValidationError: PropTypes.bool.isRequired,
  updateEntityProperty: PropTypes.func.isRequired,
};

export default EntityDetails;

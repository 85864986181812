import Immutable from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'core/ui/decorators';
import ui from 'core/ui';
import { TrackingProvider } from '@optimizely/segment-js/dist/components';

import {
  actions as LinksActions,
  getters as LinksGetters,
} from 'optly/modules/entity/links';
import {
  fns as JiraIntegrationFns,
  getters as JiraIntegrationGetters,
} from 'optly/modules/jira_integration';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { fns as PermissionsFns } from 'optly/modules/permissions';
import { getters as LoadingGetters } from 'core/modules/loading';
import ProjectEnums from 'optly/modules/entity/project/enums';

import { Jira } from '@optimizely/optly-components';
import { ProgressDots } from 'optimizely-oui';
import ErrorBoundary from 'core/ui/components/error_boundary';

@connect(props => {
  const { source, sourceType } = props;
  const isFullstackExperiment =
    sourceType === 'experiment' &&
    source.get('platform') === ProjectEnums.project_types.FULL_STACK;

  return {
    isLoadingLinks: LoadingGetters.isLoading('jira-links'),
    isUserConnectedToJira: JiraIntegrationGetters.isUserConnected,
    linkedIssues: LinksGetters.linksForSourceId(
      source.get('id'),
      isFullstackExperiment,
    ),
    canUpdateJiraLinks: [
      CurrentProjectGetters.project,
      PermissionsFns.canUpdateJiraLinks,
    ],
  };
})
class JiraIssuesTableCell extends React.Component {
  static propTypes = {
    linkedIssues: PropTypes.instanceOf(Immutable.List).isRequired,
    isLoadingLinks: PropTypes.bool,
    isUserConnectedToJira: PropTypes.bool.isRequired,
    source: PropTypes.instanceOf(Immutable.Map).isRequired,
    sourceType: PropTypes.oneOf(['feature', 'experiment', 'campaign'])
      .isRequired,
    canUpdateJiraLinks: PropTypes.bool,
    tenantUrl: PropTypes.string,
  };

  static defaultProps = {
    isLoadingLinks: false,
    canUpdateJiraLinks: false,
    tenantUrl: null,
  };

  unlinkJiraIssue = issue => {
    const { source, sourceType } = this.props;
    const issueKey = issue.getIn(['target', 'extra_data', 'issue_key']);
    ui.confirm({
      isWarning: true,
      title: 'Unlink Jira Issue',
      message: `Are you sure you want to unlink ${issueKey} from the ${sourceType} ${source.get(
        'api_name',
      )}?`,
      confirmText: 'Unlink Jira Issue',
      cancelText: 'Cancel',
    }).then(() => {
      LinksActions.delete({
        id: issue.get('id'),
      });
    });
  };

  render() {
    const {
      isLoadingLinks,
      isUserConnectedToJira,
      linkedIssues,
      canUpdateJiraLinks,
      sourceType,
      source,
      tenantUrl,
    } = this.props;

    return (
      <ErrorBoundary alternateContent={<div>--</div>}>
        {isLoadingLinks ? (
          <ProgressDots />
        ) : (
          <TrackingProvider
            properties={{
              sourceType,
              sourceId: source.get('id'),
              experimentType: null, // this will be relevant when we support linking more than features,
              jiraTenantUrl: tenantUrl,
            }}>
            <Jira.Issues
              issues={linkedIssues}
              tenantUrl={tenantUrl}
              onUnlink={
                canUpdateJiraLinks && isUserConnectedToJira
                  ? this.unlinkJiraIssue
                  : null
              }
            />
          </TrackingProvider>
        )}
      </ErrorBoundary>
    );
  }
}

export default JiraIssuesTableCell;

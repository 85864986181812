import regex from 'optly/utils/regex';
import UrlHelper from 'optly/services/url_helper';

import FilterableTableEnums from 'optly/modules/filterable_table/enums';
import FilterableTableFns from 'optly/modules/filterable_table/fns';
import FilterableTableGetters from 'optly/modules/filterable_table/getters';

import CurrentProjectFns from 'optly/modules/current_project/fns';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import CurrentlyEditingExperimentFns from 'bundles/p13n/modules/currently_editing_experiment/fns';
import CurrentlyEditingExperimentGetters from 'bundles/p13n/modules/currently_editing_experiment/getters';
import LayerExperimentGetters from 'optly/modules/entity/layer_experiment/getters';
import LiveCommitTagGetters from 'optly/modules/entity/live_commit_tag/getters';
import LiveVariableGetters from 'optly/modules/entity/live_variable/getters';
import LiveVariableUsageGetters from 'optly/modules/entity/live_variable_usage/getters';
import NavConstants from 'optly/services/navigation';
import ProjectFns from 'optly/modules/entity/project/fns';
import PermissionsFns from 'optly/modules/permissions/fns';
import AdminAccountGetters from 'optly/modules/admin_account/getters';

import fns from './fns';

/**
 * Returns true if experiment is live
 * @param {Number} experimentId
 * @return {Boolean}
 */
export function isExperimentLive(experimentId) {
  return [
    LayerExperimentGetters.entityCache,
    LiveCommitTagGetters.entityCache,
    (layerExperimentEntityCache, liveCommitTagEntityCache) => {
      const experiment = layerExperimentEntityCache.get(experimentId);
      return CurrentlyEditingExperimentFns.isExperimentLive(
        experiment,
        liveCommitTagEntityCache,
      );
    },
  ];
}

export const currentProjectActiveGroups = [
  CurrentProjectGetters.experimentationGroups,
  groups => {
    groups = FilterableTableFns.filterByArchivedStatus(
      groups,
      FilterableTableEnums.status.ACTIVE,
    );
    return groups;
  },
];

export const currentProjectFeatures = CurrentProjectGetters.features;

export function filteredAndSortedGroups(tableId) {
  return [
    CurrentProjectGetters.experimentationGroups,
    FilterableTableGetters.fieldFilter(tableId, 'status'),
    FilterableTableGetters.stringFilter(tableId),
    (groups, statusFilter, stringFilter) => {
      // @TODO(mng): implement sorting, reliant on OUI table to enable sorting
      groups = FilterableTableFns.filterByArchivedStatus(groups, statusFilter);
      groups = FilterableTableFns.filterFieldsByString(groups, stringFilter, [
        'name',
        'description',
      ]);
      return groups;
    },
  ];
}

export const allVariationKeysUnique = [
  CurrentlyEditingExperimentGetters.variations,
  fns.ensureVariationKeysUnique,
];

/**
 * Getter to determine if all variations have valid keys
 */
export const allVariationKeysValid = [
  CurrentlyEditingExperimentGetters.variations,
  variations =>
    variations.every(
      variation =>
        variation.get('api_name') &&
        regex.apiName.test(variation.get('api_name')),
    ),
];

/**
 * Getter to clean up variations for actions.createExperiment to consume
 */
export const variationsToSave = [
  CurrentlyEditingExperimentGetters.variationsWithVariables,
  variations =>
    variations.map(variation => {
      if (variation.has('percentage')) {
        variation = variation
          .set('weight', Number((variation.get('percentage') * 100).toFixed()))
          .delete('percentage');
      }
      return variation
        .set('name', variation.get('api_name'))
        .delete('guid')
        .delete('$index');
    }),
];

export const currentProjectVariableUsages = [
  LiveVariableUsageGetters.entityCache,
  CurrentProjectGetters.id,
  CurrentProjectFns.filterByProjectId,
];

export const currentProjectVariables = [
  LiveVariableGetters.entityCache,
  CurrentProjectGetters.id,
  CurrentProjectFns.filterByProjectId,
];

export const currentProjectActiveVariables = [
  currentProjectVariables,
  variables =>
    FilterableTableFns.filterByArchivedStatus(
      variables,
      FilterableTableEnums.status.ACTIVE,
    ),
];

export const topbarItems = [
  CurrentProjectGetters.id,
  [AdminAccountGetters.accountPermissions, PermissionsFns.canUseMutex],
  CurrentProjectGetters.currentProjectType,
  (projectId, canUseMutex, currentProjectType) => [
    {
      title: 'Overview',
      url: UrlHelper.xFullStackHome(projectId),
      id: NavConstants.OasisTabs.EXPERIMENTS,
      testSection: 'oasis-experiments-overview-tab',
    },
    {
      title: 'Exclusion Groups',
      url: UrlHelper.groups(projectId),
      id: NavConstants.OasisTabs.GROUPS,
      testSection: 'oasis-experiments-groups-tab',
      isVisible: canUseMutex,
    },
  ],
];

export const customProjectName = [
  CurrentProjectGetters.project,
  CurrentProjectGetters.primarySDKLanguage,
  (currentProject, primarySDKLanguage) =>
    ProjectFns.formatCustomProjectName(
      currentProject.toJS(),
      primarySDKLanguage,
    ),
];

export default {
  currentProjectActiveGroups,
  isExperimentLive,
  currentProjectFeatures,
  filteredAndSortedGroups,
  allVariationKeysUnique,
  allVariationKeysValid,
  variationsToSave,
  currentProjectVariableUsages,
  currentProjectVariables,
  currentProjectActiveVariables,
  topbarItems,
  customProjectName,
};

/**
 * Entity Definition for OAuth Bearer Token
 */

export const entity = 'oauth_bearer_tokens';

export const parent = {
  entity: 'accounts',
  key: 'account_id',
};

export default {
  entity,
  parent,
};

import getters from './getters';
import actions from './actions';
import fns from './fns';

const exported = {
  getters,
  actions,
  fns,
};

export default exported;
export { getters, actions, fns };

import actions from './actions';
import entityDef from './entity_definition';
import getters from './getters';

export { actions, entityDef, getters };

export default {
  actions,
  entityDef,
  getters,
};

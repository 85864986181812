/**
 * Hook for rendering UI, if needed, if there's a limit on a given feature.
 */
import React from 'react';
import PropTypes from 'prop-types';

import { Button, Link } from 'optimizely-oui';

import { useGetters } from 'core/ui/methods/connect_getters';
import PermissionsConstants from 'optly/modules/permissions/constants';
import PermissionsGetters from 'optly/modules/permissions/getters';
import {
  actions as UpgradeActions,
  constants as UpgradeConstants,
  fns as UpgradeFns,
} from 'optly/modules/upgrade';

/**
 * @param {String} featureLimitType - String representing feature
 * @param {String} uiLocation - Where in the UI the component will render
 * @param {Number} limit - What the limit is. -1 if unlimited.
 * @returns {React.Component} If there's a limit, a component.
 */
const UpgradeComponent = ({ featureLimitType, uiLocation, limit }) => {
  if (limit === -1) {
    return null;
  }
  switch (featureLimitType) {
    case UpgradeConstants.FeatureLimitTypes.CHANGE_HISTORY_DAYS:
      switch (uiLocation) {
        case UpgradeConstants.FeatureLimitUIs.CHANGE_HISTORY_DATE_PICKER:
          return (
            <Button
              size="narrow"
              style="plain"
              testSection="change-history-days-upgrade-button"
              onClick={() =>
                UpgradeActions.showUpgradeDialog(
                  UpgradeConstants.FeatureLimitTypes.CHANGE_HISTORY_DAYS,
                )
              }>
              <div className="flex">
                <div className="line--tight text--left micro push--right">
                  <div className="muted">Date</div>
                  <div data-test-section="selected-date-range">
                    Last {limit} days
                  </div>
                </div>
              </div>
            </Button>
          );
        case UpgradeConstants.FeatureLimitUIs.CHANGE_HISTORY_TABLE_FOOTER:
          return (
            <div
              className="flex flex-align--center"
              data-test-section="change-history-upsell-text">
              For access to an unlimited log of changes,&nbsp;
              <Link href={UpgradeFns.getUpgradeLink()} newWindow={true}>
                upgrade your plan
              </Link>
              .
            </div>
          );
        default:
          return null;
      }
    default:
      return null;
  }
};

UpgradeComponent.propTypes = {
  featureLimitType: PropTypes.oneOf(
    Object.keys(UpgradeConstants.FeatureLimitTypes),
  ).isRequired,
  limit: PropTypes.number.isRequired,
  uiLocation: PropTypes.oneOf(Object.keys(UpgradeConstants.FeatureLimitUIs))
    .isRequired,
};

const getLimit = featureLimitType => {
  if (
    featureLimitType === UpgradeConstants.FeatureLimitTypes.CHANGE_HISTORY_DAYS
  ) {
    return useGetters({
      limit: PermissionsGetters.changeHistoryLimit,
    });
  }
  return {
    limit: PermissionsConstants.UNLIMITED_VALUE, // unlimited by default
  };
};

export default function useUpgradeInfo(featureLimitType, uiLocation) {
  const { limit } = getLimit(featureLimitType);
  const hasLimit =
    limit !== undefined && limit !== PermissionsConstants.UNLIMITED_VALUE;
  return {
    hasLimit,
    UpgradeComponent: hasLimit
      ? () => {
          return (
            <UpgradeComponent
              featureLimitType={featureLimitType}
              uiLocation={uiLocation}
              limit={limit}
            />
          );
        }
      : null,
  };
}

const $ = require('jquery');

// taken from closure_legacy/bundle/deparam
/* eslint-disable */
exports.deparam = function(query, coerce) {
  var params = {},
  coerceTypes = {'true': true, 'false': false, 'undefined': undefined, 'null': null};
  if (query && query[0] === "?") {
    // remove the leading ? if present
    query = query.slice(1);
  }
  $.each(query.split("&"), function(index, param) {
    if (param) {
      var keyValue = param.split("="),
      key = decodeURIComponent(keyValue[0]),
      value = true;
      if (keyValue.length === 2) {
        // param has a value
        value = decodeURIComponent(keyValue[1]);
        if (coerce) {
          value = !isNaN(value) ? +value 	// number
            : value in coerceTypes ? coerceTypes[value] // boolean/null values
              : value;	// other
        }
      }
      if (key in params) {
        if (params[key].push) {
          // value is array-like
          params[key].push(value);
        } else {
          // convert value to array
          params[key] = [params[key], value];
        }
      } else {
        params[key] = value;
      }
    }
  });
  return params;
};
/* eslint-enable */

/**
 * @param {Boolean?} coerce
 * @return {Object}
 */
exports.querystring = function(coerce) {
  return exports.deparam(exports.getLocationSearch(), coerce);
};

/**
 * Stubbable method to get location.search
 */
exports.getLocationSearch = function() {
  return location.search;
};

import keyMirror from 'optly/utils/key_mirror';

export default keyMirror({
  RECOMMENDATIONS_INIT: null,
  RECOMMENDATIONS_INIT_CURRENTLY_EDITING_CATALOG: null,
  RECOMMENDATIONS_LOAD_DATA: null,
  RECOMMENDATIONS_UPDATE_PROPERTY: null,
  RECOMMENDATIONS_UPDATE_EVENT: null,
  RECOMMENDATIONS_REMOVE_EVENT: null,
  RECOMMENDATIONS_SET_CATALOG: null,
  RECOMMENDATIONS_SET_CURRENTLY_EDITING_CATALOG: null,
  RECOMMENDATIONS_RESET_CURRENTLY_EDTING_CATALOG: null,

  // currently editing event
  RECOMMENDATIONS_SET_EDITING_EVENT: null,
  RECOMMENDATIONS_ADD_TAG_TO_CURRENTLY_EDITING_EVENT: null,
  RECOMMENDATIONS_REMOVE_TAG_FROM_CURRENTLY_EDITING_EVENT: null,
  RECOMMENDATIONS_UPDATE_FOR_CATALOG: null,
  RECOMMENDATIONS_SELECT_EVENT_PAGE: null,

  RECOMMENDATIONS_SET_EDITING_RECOMMENDER: null,
  RECOMMENDATIONS_UPDATE_EDITING_RECOMMENDER_PROPERTY: null,
  RECOMMENDATIONS_UPDATE_EDITING_RECOMMENDER_SPECIFICS_PROPERTY: null,
  RECOMMENDATIONS_UPSERT_RECOMMENDER: null,
});

import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'optimizely-oui';

import UrlUtil from 'optly/utils/url';
import Uri from 'optly/utils/uri';
import { Fieldset } from 'react_components/dialog';
// eslint-disable-next-line import/no-cycle
import {
  enums as SupportEnums,
  fns as SupportFns,
} from 'optly/modules/support';

import ButtonGrid from '../button_grid';

const helpModalParams = `?utm_source=${UrlUtil.generateUtmSourceValue()}&utm_medium=help_modal&utm_content=resource_link&utm_campaign=support`;
const addHelpModalParams = url => `${url}${helpModalParams}`;

class SupportDialogHome extends React.Component {
  static propTypes = {
    canAccountUseOnlineTickets: PropTypes.bool.isRequired,
    currentProjectId: PropTypes.string.isRequired,
    isEmailVerified: PropTypes.bool.isRequired,
    isEntitledToPrioritySupport: PropTypes.bool.isRequired,
    switchStepHandler: PropTypes.func.isRequired,
  };

  state = { searchValue: '' };

  goToLogASupportTicket = () => {
    const { switchStepHandler } = this.props;
    switchStepHandler(SupportEnums.steps.CHOOSE_CATEGORY);
  };

  updateSearchValue = e => {
    this.setState({ searchValue: e.target.value });
  };

  searchOptiverse = e => {
    e.preventDefault();
    const { searchValue } = this.state;
    const url = Uri.create('https://support.optimizely.com/hc/en-us/search');
    url.param('category', '4410287901197');
    url.param('query', searchValue);
    url.param('utm_source', UrlUtil.generateUtmSourceValue());
    url.param('utm_medium', 'help_modal');
    url.param('utm_content', 'search');
    url.param('utm_campaign', 'support');
    window.open(url.toString(), '_blank');
    this.setState({ searchValue: '' });
  };

  newTicketButtons = isEntitledToPrioritySupport => {
    return [
      {
        label: 'Create a Ticket',
        href: this.newTicketEpiZendeskUrl(),
        target: '_blank',
        testSection: 'help-center-home-create-request-epi-zendesk',
      },
      {
        label: 'View Tickets',
        href: SupportFns.viewRequestsEpiZendesk(),
        testSection: 'help-center-home-my-requests',
      },
      {
        label: 'Schedule a Call',
        href: SupportEnums.PrioritySupportLink,
        isVisible: isEntitledToPrioritySupport,
        target: '_blank',
        testSection: 'help-center-home-schedule-phone-call',
      },
    ];
  };

  newTicketEpiZendeskUrl = () => {
    const { currentProjectId } = this.props;
    return `${SupportFns.supportEnvironment()}/hc/en-us/requests/new?ticket_form_id=360001979712&tf_360045258091=${currentProjectId}`;
  };

  render() {
    const {
      canAccountUseOnlineTickets,
      isEmailVerified,
      isEntitledToPrioritySupport,
    } = this.props;
    const { searchValue } = this.state;
    return (
      <React.Fragment>
        <Fieldset testSection="help-center-home">
          <p className="weight--bold">How can we help?</p>
          <form
            onSubmit={this.searchOptiverse}
            data-test-section="help-center-modal-support-doc-search-form"
            className="push-double--bottom">
            <Input
              isFilter={true}
              testSection="help-center-modal-support-doc-search-input"
              type="text"
              value={searchValue}
              onChange={this.updateSearchValue}
              placeholder="Search Optiverse"
            />
          </form>
          <ButtonGrid
            title="Find support articles and interactive courses"
            buttons={[
              {
                label: 'Knowledge Base',
                href: addHelpModalParams('https://help.optimizely.com/'),
              },
              {
                label: 'Developer Docs',
                href: addHelpModalParams(
                  'https://docs.developers.optimizely.com/full-stack/docs/welcome',
                ),
              },
              {
                label: 'Academy',
                href: addHelpModalParams('https://www.optimizely.com/academy/'),
              },
            ]}
          />
          {isEmailVerified && canAccountUseOnlineTickets && (
            <ButtonGrid
              title="Contact our support team."
              buttons={this.newTicketButtons(isEntitledToPrioritySupport)}
            />
          )}
          {!isEmailVerified && canAccountUseOnlineTickets && (
            <ButtonGrid
              title="Need to contact our support team? Please verify your email address."
              buttons={[
                {
                  label: 'Verify Email Address',
                  href: '/v2/profile/preferences?should_verify_email=true',
                  testSection: 'help-center-home-verify-email',
                },
              ]}
            />
          )}
          <ButtonGrid
            title="Additional resources"
            buttons={[
              {
                label: 'Optimizely Blog',
                href: addHelpModalParams('http://blog.optimizely.com'),
              },
              {
                label: 'Content Library',
                href: addHelpModalParams(
                  'https://app.optimizely.com/resources',
                ),
              },
              {
                label: 'Sample Size Calculator',
                href: addHelpModalParams(
                  'https://app.optimizely.com/resources/sample-size-calculator',
                ),
              },
              {
                label: 'Solutions Partners',
                href: addHelpModalParams(
                  'https://app.optimizely.com/partners/solutions/',
                ),
              },
              {
                label: 'Optimizely Community',
                href: addHelpModalParams(
                  'https://www.optimizely.com/slack-community/',
                ),
              },
            ]}
          />
        </Fieldset>
      </React.Fragment>
    );
  }
}

export default SupportDialogHome;

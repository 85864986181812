import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { toImmutable } from 'nuclear-js';
import { Button, ButtonRow, Checkbox, HelpPopover } from 'optimizely-oui';
import { Track } from '@optimizely/segment-js/dist/components';

import SectionModuleConstants from 'bundles/p13n/sections/implementation/section_module/constants';

import RuleCard from './subcomponents/rule_card';

export default class FilteringRulesBuilder extends React.Component {
  static propTypes = {
    currentProjectTags: PropTypes.instanceOf(Immutable.List).isRequired,
    defaultPolicy: PropTypes.string,
    handleUpdateFilteringRules: PropTypes.func.isRequired,
    rules: PropTypes.instanceOf(Immutable.List),
    switchFilteringRulesMode: PropTypes.func.isRequired,
  };

  static defaultProps = {
    defaultPolicy: SectionModuleConstants.DefaultFilteringRuleValues.POLICY,
    rules: toImmutable([SectionModuleConstants.DefaultRule]),
  };

  getCurrentFilteringRules = () => {
    const { defaultPolicy, rules } = this.props;
    return toImmutable({ default_policy: defaultPolicy, rules });
  };

  handleUpdateDefaultPolicy = () => {
    const { defaultPolicy, handleUpdateFilteringRules } = this.props;
    const newPolicy =
      defaultPolicy ===
      SectionModuleConstants.FilteringRulesValues.POLICIES.EXCLUDE
        ? SectionModuleConstants.FilteringRulesValues.POLICIES.INCLUDE
        : SectionModuleConstants.FilteringRulesValues.POLICIES.EXCLUDE;
    const filteringRules = this.getCurrentFilteringRules();
    const updatedFilteringRules = filteringRules.set(
      'default_policy',
      newPolicy,
    );
    handleUpdateFilteringRules(updatedFilteringRules);
  };

  handleAddRule = () => {
    const { handleUpdateFilteringRules } = this.props;
    const filteringRules = this.getCurrentFilteringRules();
    const updatedFilteringRules = filteringRules.update('rules', rules =>
      rules.push(toImmutable(SectionModuleConstants.DefaultRule)),
    );
    handleUpdateFilteringRules(updatedFilteringRules);
  };

  handleUpdateRule = (value, ruleIndex) => {
    const { handleUpdateFilteringRules } = this.props;
    const filteringRules = this.getCurrentFilteringRules();
    const updatedFilteringRules = filteringRules.setIn(
      ['rules', ruleIndex],
      value,
    );
    handleUpdateFilteringRules(updatedFilteringRules);
  };

  handleRemoveRule = ruleIndex => {
    const { handleUpdateFilteringRules } = this.props;
    const filteringRules = this.getCurrentFilteringRules();
    const updatedFilteringRules = filteringRules.update('rules', rules =>
      rules.splice(ruleIndex, 1),
    );
    handleUpdateFilteringRules(updatedFilteringRules);
  };

  handleRulesReset = () => {
    const { handleUpdateFilteringRules } = this.props;
    // Show the default state with the dropdowns but clean the slate on Recommender.
    this.setState(
      { rules: toImmutable([SectionModuleConstants.DefaultRule]) },
      () => {
        handleUpdateFilteringRules();
      },
    );
  };

  renderRules = () => {
    const { currentProjectTags, rules } = this.props;
    const numRules = rules.size;
    return rules.map((rule, index) => {
      let conditions;
      let conjunction;
      const multipleConditions = rule.getIn(['condition', 'conditions']);
      if (multipleConditions) {
        conditions = multipleConditions;
        conjunction = rule.getIn(['condition', 'op']);
      } else {
        conditions = toImmutable([rule.get('condition')]);
        conjunction =
          SectionModuleConstants.DefaultFilteringRuleValues.CONJUNCTION;
      }
      const policy = rule.get('policy');
      return (
        <RuleCard
          conditions={conditions}
          conjunction={conjunction}
          currentProjectTags={currentProjectTags}
          handleRemoveRule={this.handleRemoveRule}
          handleUpdateRule={this.handleUpdateRule}
          index={index}
          isOnlyRule={numRules === 1}
          key={index}
          policy={policy}
        />
      );
    });
  };

  render() {
    const { defaultPolicy, switchFilteringRulesMode } = this.props;
    return (
      <div>
        <div
          className="flex flex-align--end flex-justified--between"
          data-test-section="filters-builder">
          <div className="flex">
            <Track eventName="Recommendations Filter Scope Selected">
              <Checkbox
                checked={
                  defaultPolicy ===
                  SectionModuleConstants.FilteringRulesValues.POLICIES.EXCLUDE
                }
                label="Exclude all recommendations by default"
                data-test-section="filters-default-policy"
                onChange={this.handleUpdateDefaultPolicy}
              />
            </Track>
            <HelpPopover
              horizontalAttachment="left"
              verticalAttachment="middle"
              popoverTitle="Global Exclusion">
              Use this option to exclude all recommendations by default. Then
              use the filters below to override this setting for specific
              conditions.
            </HelpPopover>
          </div>
          <div>
            <ButtonRow
              rightGroup={[
                <Track eventName="Recommendations All Filters Removed">
                  <Button
                    key="remove-0"
                    style="danger-outline"
                    size="small"
                    testSection="filters-reset-rules"
                    onClick={this.handleRulesReset}>
                    Remove all filters
                  </Button>
                </Track>,
                <Track eventName="Recommendations Code Editor Opened">
                  <Button
                    key="advanced-0"
                    style="outline"
                    size="small"
                    testSection="filters-switch-to-code-mode"
                    onClick={switchFilteringRulesMode}>
                    Advanced code editor
                  </Button>
                </Track>,
              ]}
            />
          </div>
        </div>
        <div>
          {this.renderRules()}
          <div className="push-double--ends">
            <Track eventName="Recommendations Condition Group Added">
              <Button
                style="outline"
                size="small"
                width="default"
                testSection="filters-add-rule"
                onClick={this.handleAddRule}>
                Add condition group
              </Button>
            </Track>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Service for simple accordion directive
 *
 * @author Cheston Lee
 */
const $ = require('jquery');

const ACTIVE_ACCORDION_CLASS = 'is-active';

function activate(el, target) {
  const $el = $(el);
  const $target = $(target);

  const contentPane = $target.parent('.accordion__item');
  const currentActive = $el.find(`.${ACTIVE_ACCORDION_CLASS}`);
  const contentHeight = currentActive.outerHeight();

  contentPane.animate(
    {
      height: contentHeight,
    },
    {
      duration: 200,
      queue: false,
    },
  );

  currentActive.animate(
    {
      height: $target.outerHeight(),
    },
    {
      duration: 200,
      queue: false,
      complete() {
        contentPane
          .parent()
          .children()
          .css('height', '');
      },
    },
  );

  if (currentActive.length > 0) {
    currentActive.removeClass(ACTIVE_ACCORDION_CLASS);
  }

  $target.parent().addClass(ACTIVE_ACCORDION_CLASS);
}

module.exports = {
  activate,
};

/**
 * Handles ajax error in .fail() handler
 * and prevents the default error handling
 * at page level
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
/**
 * Parses out the error message from a failed jqXHR object
 * @param {jqXHR} jqXHR
 * @return {String}
 */
function parseErrorResponse(jqXHR) {
  // Special case when failed promise returns not a jqXHR object.
  if (!(jqXHR instanceof Object)) {
    return jqXHR;
  }
  const response = JSON.parse(jqXHR.responseText);
  let msg;
  if (response.error) {
    msg = response.error;
  } else if (response.errors) {
    msg = response.errors.join('; ');
  } else if (response.message) {
    msg = response.message;
  } else if (response.msg) {
    msg = response.msg;
  } else {
    msg = tr('Server error occurred');
    // Include error ID for server errors
    if (response.id) {
      msg += ` (ID: ${response.id})`;
    }
  }

  return msg;
}

/**
 * Function that is passed to an ajax .fail() or .then(fn, handleAajxError(...))
 * When this function is invoked it prevents the default error handling from happening
 * at the page level
 *
 * This is useful for components that would like like to handle non-200 responses
 * in their own way and prevent the default error dialog from appearing.
 *
 * @param {Function} errorHandler
 */
module.exports = function handleAjaxError(errorHandler) {
  // The fail handler passed to .fail()
  // for some reason jQuery 1.7 calls apply with args = [jqXHR, textStatus, error]
  return function onFail(jqXHR) {
    // destructure args
    let msg;
    let handlerReturn;

    try {
      msg = parseErrorResponse(jqXHR);
      // pass the handler the parsed error message and jqXHR object
      handlerReturn = errorHandler.call(this, msg, jqXHR);
    } catch (e) {
      if (jqXHR instanceof Object && jqXHR.statusText) {
        handlerReturn = errorHandler.call(this, jqXHR.statusText, jqXHR);
      } else {
        handlerReturn = errorHandler.call(this, jqXHR);
      }
    }

    // if the errorHandler function explicitly returns false, don't suppress error
    jqXHR.optlyErrorHandled = handlerReturn !== false;
  };
};

/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import htmlSanitizer from 'sanitizer';

import { Button, Sheet } from 'optimizely-oui';
import { OptimizelyFeature } from '@optimizely/react-sdk';

import { withTrack } from '@optimizely/segment-js/dist/decorators';

import Immutable from 'optly/immutable';
import { connect } from 'core/ui/decorators';
import Router from 'core/router';
import ui from 'core/ui';

import UrlHelper from 'optly/services/url_helper';

import LoadingOverlay from 'react_components/loading_overlay/index';

import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import ExperienceManagerGetters from 'bundles/p13n/components/experience_manager/component_module/getters';
import HoldbackSelectorGetters from 'bundles/p13n/modules/holdback_selector/getters';
import LayerActions from 'optly/modules/entity/layer/actions';
import LayerConstants from 'optly/modules/entity/layer/constants';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerSettingsModuleGetters from 'bundles/p13n/modules/layer_settings/getters';
import PermissionsFns from 'optly/modules/permissions/fns';
import PermissionsGetters from 'optly/modules/permissions/getters';
import MetricsManagerGetters from 'bundles/p13n/modules/metrics_manager/getters';
import SentryActions from 'optly/modules/sentry/actions';
import ViewActions from 'optly/modules/entity/view/actions';

import ExperienceManagerNew from 'bundles/p13n/components/experience_manager';
import ExperienceManagerLegacy from 'bundles/p13n/components/experience_manager/index_legacy';
// Explicit import of the /index file here because there is an existing holdback_selector.js file that takes precedence
import HoldbackSelector from 'bundles/p13n/components/holdback_selector/index';
import LayerSettings from 'bundles/p13n/components/layer_settings';

import TargetingComponent from './subcomponents/targeting';

import {
  actions as ComponentModuleActions,
  getters as ComponentModuleGetters,
} from './component_module';

const LOADING_ID = 'layer-detail';

@withTrack
@connect({
  canUpdateLayer: [
    CurrentProjectGetters.project,
    PermissionsFns.canUpdateLayer,
  ],
  canUseUrlTargeting: PermissionsGetters.canUseUrlTargeting,
  currentLayer: CurrentLayerGetters.layer,
  currentlyEditingMetricWrapper:
    MetricsManagerGetters.currentlyEditingMetricWrapper,
  currentProjectId: CurrentProjectGetters.id,
  description: LayerSettingsModuleGetters.description,
  errorsByField: ComponentModuleGetters.errors,
  holdback: HoldbackSelectorGetters.holdback,
  name: LayerSettingsModuleGetters.name,
  orderedAudienceIds: ExperienceManagerGetters.orderedAudienceIds,
  selectedViewIds: ComponentModuleGetters.selectedViewIds,
  targetingType: ComponentModuleGetters.targetingType,
  urlTargetingApiNameError: ComponentModuleGetters.urlTargetingApiNameError,
  urlTargetingConditionsError:
    ComponentModuleGetters.urlTargetingConditionsError,
  urlTargetingConfig: ComponentModuleGetters.urlTargetingConfig,
  urlTargetingUrlError: ComponentModuleGetters.urlTargetingUrlError,
  views: ComponentModuleGetters.allSavedViewsWithoutSingleUse,
  viewsError: ComponentModuleGetters.viewsError,
  workingMetrics: MetricsManagerGetters.workingMetrics,
})
class LayerDialog extends React.Component {
  static propTypes = {
    canUpdateLayer: PropTypes.bool.isRequired,
    currentProjectId: PropTypes.number.isRequired,
    currentlyEditingMetricWrapper: PropTypes.instanceOf(Immutable.Map)
      .isRequired,
    description: PropTypes.string,
    errorsByField: PropTypes.instanceOf(Immutable.Map).isRequired,
    holdback: PropTypes.number.isRequired,
    name: PropTypes.string,
    orderedAudienceIds: PropTypes.instanceOf(Immutable.List).isRequired,
    selectedViewIds: PropTypes.instanceOf(Immutable.List).isRequired,
    targetingType: PropTypes.string.isRequired,
    track: PropTypes.func.isRequired,
    urlTargetingConfig: PropTypes.instanceOf(Immutable.Map).isRequired,
    workingMetrics: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  static defaultProps = {
    description: '',
    name: '',
  };

  constructor(props) {
    super(props);

    this.policy = LayerEnums.policy.ORDERED;
  }

  componentWillUnmount() {
    ComponentModuleActions.reset();
  }

  clearNameError = () => {
    ComponentModuleActions.setErrors({
      name: '',
    });
  };

  createLayer = () => {
    const {
      currentProjectId,
      description,
      holdback,
      name,
      orderedAudienceIds,
      selectedViewIds,
      targetingType,
      urlTargetingConfig,
      workingMetrics,
    } = this.props;

    const validationErrors = Object.values(ComponentModuleActions.validate());

    // Do not attempt to create experiment if there are any errors
    if (validationErrors.filter(error => error).length) {
      validationErrors.forEach(errorMessage => {
        if (errorMessage) {
          ui.showNotification({
            message: errorMessage,
            type: 'error',
          });
        }
      });
      return;
    }

    const layerData = {
      description,
      holdback,
      metrics: workingMetrics.toJS(),
      name,
      policy: LayerEnums.policy.ORDERED,
      project_id: currentProjectId,
      type: LayerEnums.type.PERSONALIZATION,
    };

    if (targetingType === LayerConstants.TargetingTypes.URL) {
      layerData.url_targeting = urlTargetingConfig.toJS();
    } else {
      layerData.view_ids = selectedViewIds.toJS();
    }

    const onLayerSave = layerId => {
      ui.showNotification({
        message: `The campaign <b>${htmlSanitizer.escape(
          name,
        )}</b> has been saved.`,
      });

      const url = UrlHelper.campaignHome(currentProjectId, layerId);
      ui.hideDialog().then(() => {
        Router.go(url);
      });
    };

    try {
      const def = LayerActions.createPersonalizationLayer(
        layerData,
        orderedAudienceIds.toJS(),
      ).then(layerDef => {
        let fetchDeferred;

        if (targetingType === LayerConstants.TargetingTypes.URL) {
          fetchDeferred = ViewActions.fetch(layerDef.url_targeting.view_id);
        } else {
          fetchDeferred = $.Deferred().resolve();
        }
        fetchDeferred.then(() => {
          onLayerSave(layerDef.id);
        });
      });

      ui.loadingWhen('layer-detail', def);
    } catch (e) {
      ui.showNotification({
        type: 'error',
        message:
          'There was an error creating a new Personalization Campaign. Please refresh the page and try again.',
      });

      SentryActions.withScope(scope => {
        scope.setExtra('layerData', layerData);
        SentryActions.captureException(e);
      });
    }
  };

  render() {
    const {
      canUpdateLayer,
      currentlyEditingMetricWrapper,
      errorsByField,
    } = this.props;

    // TODO(MGMT-2940): Remove the audience_combo_reskin feature flag once rolled out to 100%
    return (
      <Sheet
        testSection="layer-detail"
        title="New Campaign"
        hasRequiredFieldsIndicator={true}
        onClose={ui.hideDialog}
        footerButtonList={[
          <Button
            data-test-section="cancel-button"
            key="cancel"
            onClick={ui.hideDialog}
            style="plain">
            Cancel
          </Button>,
          <Button
            data-track-id="2.0-Campaign-CreateNewCampaign.CreateCampaign"
            isDisabled={!canUpdateLayer || currentlyEditingMetricWrapper}
            key="create"
            onClick={this.createLayer}
            style="highlight"
            testSection="save-button">
            Create Campaign
          </Button>,
        ]}>
        <LoadingOverlay loadingId={LOADING_ID}>
          <LayerSettings
            campaignType={LayerEnums.campaignTypes.P13N_CAMPAIGN}
            nameError={errorsByField.get('name')}
            onNameChange={this.clearNameError}
          />
          <div className="push-quad--bottom">
            <TargetingComponent />
          </div>
          <div className="push-quad--bottom">
            <HoldbackSelector />
          </div>
        </LoadingOverlay>
      </Sheet>
    );
  }
}

export default LayerDialog;

import React from 'react';

import PropTypes from 'prop-types';
import htmlSanitizer from 'sanitizer';

import { Immutable } from 'nuclear-js';
import { withTrack } from '@optimizely/segment-js/dist/decorators';

/* Components */
import { Button, Table } from 'optimizely-oui';

import { connect } from 'core/ui/decorators';
import ui from 'core/ui';
import { SortableTableHeader } from 'react_components/sortable_table';
import LoadingOverlay from 'react_components/loading_overlay';

/* Modules */
import { loadingWhenFetchAllPages } from 'core/modules/loading/actions';
import { isPageLoading } from 'core/modules/loading/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import FilterableTableEnums from 'optly/modules/filterable_table/enums';
import FilterableTableFns from 'optly/modules/filterable_table/fns';
import NavConstants from 'optly/services/navigation';
import CatalogActions from 'optly/modules/entity/catalog/actions';
import CatalogStatsActions from 'optly/modules/entity/catalog_stats/actions';
import PermissionsGetters from 'optly/modules/permissions/getters';
import RestApiActions from 'optly/modules/rest_api/actions';

import DataLayerTopbar from 'bundles/p13n/components/data_layer_topbar';
import { pageableEntityTable } from 'bundles/p13n/components/entity_dashboard/entity_table';
import DashboardTableFilterControls from 'bundles/p13n/components/entity_dashboard/table_filter_controls';
import { RecommendationsHelpLink } from 'bundles/p13n/components/messaging/recommendations';
import CatalogDialog from 'bundles/p13n/sections/implementation/components/recommendations/dialogs/catalog_dialog';
import CatalogProfilerDialog from 'bundles/p13n/sections/implementation/components/recommendations/dialogs/catalog_profiler_dialog';

import SectionModuleActions from 'bundles/p13n/sections/implementation/section_module/actions';
import SectionModuleFns from 'bundles/p13n/sections/implementation/section_module/fns';
import { TABLE_IDS } from 'bundles/p13n/sections/implementation/section_module/constants';

import CatalogTableRow from './subcomponents/catalog_table_row/index';

const CATALOG_TABLE_ID = TABLE_IDS.CATALOG;

const EntityTable = pageableEntityTable(CATALOG_TABLE_ID);

@connect({
  canManageRecommendations: PermissionsGetters.canManageRecommendations,
  catalogs: CurrentProjectGetters.catalogs,
  isFirstPageLoading: isPageLoading(CATALOG_TABLE_ID, 1),
})
@withTrack
class CatalogDashboard extends React.Component {
  static componentId = CATALOG_TABLE_ID;

  static displayName = CATALOG_TABLE_ID;

  static propTypes = {
    canManageRecommendations: PropTypes.bool.isRequired,
    /**
     * All catalogs (recommender services) in the current project.
     */
    catalogs: PropTypes.instanceOf(Immutable.Map).isRequired,
    editCatalog: PropTypes.PropTypes.func.isRequired,
    isFirstPageLoading: PropTypes.bool.isRequired,
    /**
     * Segment Tracking function handler
     */
    track: PropTypes.func,
  };

  static defaultProps = {
    track: () => {},
  };

  state = {
    isLoading: false,
  };

  /* Filter items in filter and search bar */
  filterItemByString = (item, search) =>
    FilterableTableFns.matchesFields(item, ['name'], search);

  filterItemByStatus = (item, status) =>
    FilterableTableFns.matchesArchivedStatus(item, status);

  onStatusChange = status => {
    const { track } = this.props;

    loadingWhenFetchAllPages(
      CATALOG_TABLE_ID,
      CatalogActions.fetchAllPages({
        archived: status === FilterableTableEnums.status.ARCHIVED,
      }),
    );

    track('Recommendations Catalog Dashboard Filter Status Applied', {
      status,
    });
  };

  createCatalog = () => {
    SectionModuleActions.initializeCurrentlyEditingCatalog();
    ui.showReactDialog(CatalogDialog, null, {
      fullScreen: true,
      isOuiDialog: true,
    });
  };

  editCatalog = catalog => {
    SectionModuleActions.setCurrentlyEditingCatalog(catalog);
    ui.showReactDialog(CatalogDialog, null, {
      fullScreen: true,
      isOuiDialog: true,
    });
  };

  archiveCatalog = catalog => {
    ui.confirm({
      title: 'Confirm Archive Catalog',
      message:
        'Are you sure you want to archive this catalog and its associated recommenders?',
      confirmText: 'Archive catalog',
    })
      .then(() => {
        this.setState({ isLoading: true });
        return CatalogActions.save({
          id: catalog.get('id'),
          archived: true,
        })
          .then(response => {
            /**
             * Upon successfully archiving a catalog, all of its associated recommenders are archived in the backend.
             * The frontend, however, doesn't know which recommenders were archived already.
             *
             * We need to invalidate the Recommenders apiRequestCache so that if users go to the Recommender Dashboard,
             * it will trigger a fresh fetch for all recommenders so that the dashboard would reflect correct information.
             *
             * Catalogs don't know how many recommenders they have, so we need to flush all apiRequestCaches for all recommenders.
             */
            RestApiActions.inValidateCacheDataByEntity({
              entity: 'recommenders',
            });
            return response;
          })
          .fail(() => {
            ui.showNotification({
              message: `Unable to archive the catalog: <b>${htmlSanitizer.escape(
                catalog.get('name')
              )}</b>`,
              type: 'error',
            });
          });
      })
      .always(() => this.setState({ isLoading: false }));
  };

  unarchiveCatalog = catalog => {
    ui.confirm({
      title: 'Confirm Unarchive Catalog',
      message: 'Are you sure you want to unarchive this catalog?',
      confirmText: 'Unarchive catalog',
    })
      .then(() => {
        this.setState({ isLoading: true });
        return CatalogActions.save({
          id: catalog.get('id'),
          archived: false,
        }).fail(() => {
          ui.showNotification({
            message: `Unable to unarchive the catalog: <b>${htmlSanitizer.escape(
              catalog.get('name')
            )}</b>`,
            type: 'error',
          });
        });
      })
      .always(() => this.setState({ isLoading: false }));
  };

  showCatalogProfiler = catalog => {
    this.setState({ isLoading: true });

    CatalogStatsActions.fetchCatalogStatsById(catalog.get('id'), {
      skipEvaludatingCachedData: true,
    }).then(() => {
      ui.showReactDialog(
        CatalogProfilerDialog,
        {
          props: {
            catalog,
          },
        },
        {
          fullScreen: true,
          isOuiDialog: true,
        },
      );

      this.setState({ isLoading: false });
    });
  };

  hasCatalogs = () => {
    const { catalogs } = this.props;

    return catalogs && !catalogs.isEmpty();
  };

  renderTableHeader = () => (
    <Table.TR>
      <SortableTableHeader field="name" type="string" width="60%">
        Name
      </SortableTableHeader>
      <Table.TH width="12%">
        <span className="nowrap">Total Items</span>
      </Table.TH>
      <Table.TH>
        <span className="nowrap">Last Generated</span>
      </Table.TH>
      <SortableTableHeader field="id" type="string">
        ID
      </SortableTableHeader>
      {this.hasCatalogs() && <Table.TH />}
      {this.hasCatalogs() && <Table.TH />}
    </Table.TR>
  );

  renderTableRow = catalog => {
    const { canManageRecommendations, track } = this.props;

    return (
      <CatalogTableRow
        archiveCatalogClick={this.archiveCatalog}
        canManageRecommendations={canManageRecommendations}
        catalog={catalog}
        editCatalogClick={this.editCatalog}
        showCatalogProfilerClick={this.showCatalogProfiler}
        track={track}
        unarchiveCatalogClick={this.unarchiveCatalog}
      />
    );
  };

  renderEntityTable = () => {
    const { catalogs, isFirstPageLoading } = this.props;
    return (
      <EntityTable
        tableId={CATALOG_TABLE_ID}
        data={catalogs}
        entityPlural="catalogs"
        filterItemByString={this.filterItemByString}
        filterItemByStatus={this.filterItemByStatus}
        renderTableRow={this.renderTableRow}
        renderTableHeader={this.renderTableHeader}
        isLoading={isFirstPageLoading}
        defaultSortBy={{
          field: 'name',
          type: 'string',
        }}
        defaultFilters={{ status: FilterableTableEnums.status.ACTIVE }}
      />
    );
  };

  renderEntityTableEmptyState = () => (
    <div className="push-quad--sides flex flex--1 flex--column">
      <Table style="rule">
        <Table.THead>{this.renderTableHeader()}</Table.THead>
        <Table.TBody />
      </Table>
    </div>
  );

  render() {
    const { canManageRecommendations } = this.props;
    const { isLoading } = this.state;

    return (
      <LoadingOverlay
        className="flex flex--1 height--1-1"
        isLoading={isLoading}>
        <div
          className="flex flex--column width--1-1"
          data-test-section="catalog-dashboard-main-content">
          <DataLayerTopbar
            activeTab={NavConstants.DataLayerTabs.CATALOGS_DASHBOARD_TAB}
          />
          <div
            className="flex flex--column flex--1"
            data-test-section="catalog-table">
            <div className="flex flex--none push-quad--sides push-double--top">
              Catalogs are collections of your items, and related events, from
              which recommendations are served.&nbsp;
              <RecommendationsHelpLink
                helpLink={SectionModuleFns.getHelpCopy('setup_catalog_link')}
                testSection="catalog-dashboard-subtitle"
              />
            </div>
            <div
              className="flex flex--none push-quad--sides push-double--ends"
              data-test-section="filter-ac">
              <DashboardTableFilterControls
                tableId={CATALOG_TABLE_ID}
                statusOptions={[
                  {
                    label: 'Active',
                    value: FilterableTableEnums.status.ACTIVE,
                  },
                  {
                    label: 'Archived',
                    value: FilterableTableEnums.status.ARCHIVED,
                  },
                ]}
                onStatusChange={this.onStatusChange}
              />
              <Button
                isDisabled={!canManageRecommendations}
                style="highlight"
                onClick={this.createCatalog}
                testSection="create-catalog-btn">
                Create New Catalog&hellip;
              </Button>
            </div>
            {this.hasCatalogs()
              ? this.renderEntityTable()
              : this.renderEntityTableEmptyState()}
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default CatalogDashboard;

import React from 'react';
import { Attention, Link as ExternalLink } from 'optimizely-oui';

import { constants as InterestGroupConstants } from 'optly/modules/entity/interest_group';

/**
 * Reference link to KB article about adaptive audience conditions.
 */
export const AdaptiveAudienceConditionsLink = () => (
  <ExternalLink
    href={InterestGroupConstants.AdaptiveAudienceReferenceLinks.CONDITIONS}
    newWindow={true}>
    {InterestGroupConstants.AdaptiveAudienceHelpTexts.CONDITIONS}
  </ExternalLink>
);

/**
 * Attention block when the given experiment has at least one processing adaptive audience condition.
 * @param props: { subject: String } in which subject can be experiment, campaign, etc.
 */
export const AdaptiveAudienceProcessingAttention = props => {
  const { subject } = props;

  return (
    <Attention
      alignment="center"
      isDismissible={false}
      testSection="processing-adaptive-audience-attention">
      This {subject}{' '}
      {InterestGroupConstants.AdaptiveAudienceHelpTexts.PROCESSING}
      <AdaptiveAudienceConditionsLink />
    </Attention>
  );
};

export default {
  AdaptiveAudienceConditionsLink,
  AdaptiveAudienceProcessingAttention,
};

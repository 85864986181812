/*
 *
 * ListDropdown for making dropdown activators in table rows for entities
 *
 * Usage:
 * Non nested
 * <ListDropdown items={[
 *   { text: 'Archive', isVisible: experiment.get('canArchive'), onClick: this.archive, },
 *   { text: 'Pause', isVisible: experiment.get('canPause'), onClick: this.pause },
 * ]} />
 *
 * Nested
 * <ListDropdown isNested={ true } items={[
 *   [
 *     { text: 'Archive', isVisible: !experiment.get('canArchive'), onClick: this.archive },
 *     { text: 'Pause', isVisible: !experiment.get('canPause'), onClick: this.pause },
 *   ],
 *   [
 *     { text: 'Duplicate', isVisible: !experiment.get('canDuplicate'), onClick: this.duplicate },
 *   ],
 * ]} />
 *
 * Note:
 * Each item may be passed, isVisible.  If set, then it's respected, if undefined the item is rendered
 */
import PropTypes from 'prop-types';
import React from 'react';

import {
  Dropdown,
  DropdownContents,
  DropdownListItem,
  DropdownBlockLink,
  DropdownBlockLinkText,
  DropdownBlockLinkSecondaryText,
} from 'react_components/dropdown';

/**
 * Return true if undefined
 */
function filterByIsVisible(item) {
  if (item.isVisible === undefined) {
    return true;
  }
  return !!item.isVisible;
}

const ListDropdown = props => {
  let items;
  if (props.isNested) {
    items = props.items
      .map(group => group.filter(filterByIsVisible))
      .filter(group => group.length > 0)
      .map((group, index) => (
        <DropdownListItem key={index}>
          {group.map((item, index) => (
            <DropdownBlockLink
              key={index}
              onClick={item.onClick}
              isLink={item.isLink}
              isIndented={item.isIndented}
              isDisabled={item.isDisabled}
              isDestructive={item.isDestructive}
              minWidth={item.minWidth}
              testSection={item.testSection}>
              <DropdownBlockLinkText
                testSection={item.testSection}
                text={item.text}
              />
              {item.secondaryText && (
                <DropdownBlockLinkSecondaryText
                  isWarning={item.isWarning}
                  testSection={item.testSection}
                  descriptionText={item.secondaryText}
                />
              )}
            </DropdownBlockLink>
          ))}
        </DropdownListItem>
      ));
  } else {
    items = props.items.filter(filterByIsVisible).map((item, index) => (
      <DropdownListItem hideOnClick={true} key={index}>
        <DropdownBlockLink
          key={index}
          onClick={item.onClick}
          isLink={item.isLink}
          isIndented={item.isIndented}
          isDisabled={item.isDisabled}
          isDestructive={item.isDestructive}
          minWidth={item.minWidth}
          testSection={item.testSection}>
          <DropdownBlockLinkText
            testSection={item.testSection}
            text={item.text}
          />
          {item.secondaryText && (
            <DropdownBlockLinkSecondaryText
              isWarning={item.isWarning}
              testSection={item.testSection}
              secondaryText={item.secondaryText}
            />
          )}
        </DropdownBlockLink>
      </DropdownListItem>
    ));
  }

  return (
    <Dropdown activator={props.activator} width={props.width}>
      <DropdownContents
        minWidth={props.minWidth}
        isNoWrap={props.isNoWrap}
        direction={props.direction}>
        {items}
      </DropdownContents>
    </Dropdown>
  );
};

ListDropdown.propTypes = {
  activator: PropTypes.node.isRequired,

  direction: PropTypes.oneOf(['left', 'right']),

  isNested: PropTypes.bool,

  minWidth: PropTypes.number,

  isNoWrap: PropTypes.bool,

  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.array),
  ]),

  testSection: PropTypes.string,
};

ListDropdown.defaultProps = {
  minWidth: 200,
};

export default ListDropdown;

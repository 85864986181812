import _ from 'lodash';

import Immutable from 'optly/immutable';
import { Store } from 'nuclear-js';

import actions from '../actions';
import actionTypes from '../action_types';
import fns from '../fns';

export default Store({
  initialize() {
    this.on(actionTypes.API_ENTITY_FETCH_SUCCESS, cacheFetchSuccess);
    this.on(actionTypes.API_CACHE_REQUESTS, cacheRequestObjects);
    this.on(actionTypes.FLUSH_ENTITY_STORE, resetByEntity);
    this.on(actionTypes.FLUSH_SINGLE_API_ENTITY, resetByEntity);
  },
});

/**
 * Removes all entries where requestInfo key entity matches payload.entity
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.entity
 * @return {Immutable.Map}
 */
function resetByEntity(state, payload) {
  return state.filter(
    (value, requestInfo) => requestInfo.get('entity') !== payload.entity,
  );
}

/**
 * payload.requestInfo {Immutable.Map}
 */
function cacheFetchSuccess(state, payload) {
  const fetchTimestamp =
    payload.fetchTimestamp || actions.getCurrentDateTimeAsISOString();
  const valueToSet = Immutable.fromJS({
    fetchTimestamp,
    responseHeaders: payload.responseHeaders,
  });
  return state.withMutations(stateObj => {
    if (_.isArray(payload.data)) {
      payload.data.forEach(result => {
        // Set single fetch result as cached for every entity received
        const singleFetchRequestInfo = fns.createSingleFetchRequestInfo({
          id: result.id,
          entity: payload.requestInfo.get('entity'),
        });
        stateObj.set(singleFetchRequestInfo, valueToSet);
      });
    }
    stateObj.set(payload.requestInfo, valueToSet);
  });
}

/**
 * Given a list of requestInfo objects, set them as cached in the store
 * payload.requestToCache {Array<Immutable.Map>}
 */
function cacheRequestObjects(state, payload) {
  return state.withMutations(stateObj => {
    const fetchTimestamp =
      payload.fetchTimestamp || actions.getCurrentDateTimeAsISOString();
    const valueToSet = Immutable.fromJS({
      fetchTimestamp,
      responseHeaders: payload.responseHeaders,
    });
    if (_.isArray(payload.requestsToCache)) {
      payload.requestsToCache.forEach(request => {
        stateObj.set(request, valueToSet);
      });
    }
  });
}

/**
 * Enumerable field values for layers
 */
import keyMirror from 'optly/utils/key_mirror';

// NOTE: these aren't proper enums, status should match layer.status in the backend
// this is really an enum for frontend filtering (which shouldnt be named Layer.enums.status)
export const status = keyMirror({
  ACTIVE: null,
  RUNNING: null,
  PAUSED: null,
  DRAFT: null,
  ARCHIVED: null,
  CONCLUDED: null,
});

// this is the real `layer.status` enum
export const entityStatus = {
  NOT_STARTED: 'not_started',
  RUNNING: 'running',
  PAUSED: 'paused',
  ARCHIVED: 'archived',
  CONCLUDED: 'concluded',
};

export const policy = {
  // single ab test
  SINGLE_EXPERIMENT: 'single_experiment',
  // list of mutually exclusive ab tests
  RANDOM: 'random',
  // ordered audience personalization
  ORDERED: 'ordered',
  // equal priority means '1 or more ordered priority level groups, with random distribution within each group'
  EQUAL_PRIORITY: 'equal_priority',
  MULTIVARIATE: 'multivariate',
};

export const tag = {
  LIVE: 'live',
};

export const metricKinds = {
  EVENT: 'event',
  VIEW: 'view',
  REVENUE: 'revenue',
};

export const campaignTypes = keyMirror({
  P13N_CAMPAIGN: null,
  SINGLE_EXPERIMENT: null,
  MULTIARMED_BANDIT: null,
  MULTIVARIATE: null,
  FEATURE: null,
});

export const type = {
  AB: 'a/b',
  FEATURE: 'feature',
  MULTIARMED_BANDIT: 'multiarmed_bandit',
  MULTIVARIATE: 'multivariate',
  PERSONALIZATION: 'personalization',
  ROLLOUT: 'rollout',
};

export const typeToEntityName = {
  [type.AB]: 'experiment',
  [type.FEATURE]: 'experiment',
  [type.MULTIARMED_BANDIT]: 'optimization',
  [type.MULTIVARIATE]: 'experiment',
  [type.PERSONALIZATION]: 'campaign',
  [type.ROLLOUT]: 'experiment',
};

export const URL_TARGETING_FIELDS = [
  'activation_code',
  'activation_type',
  'api_name',
  'conditions',
  'deactivation_enabled',
  'edit_url',
  'undo_on_deactivation',
  'view_id',
  'test_urls',
];

export const resultsOutcome = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  INCONCLUSIVE: 'inconclusive',
};

export default {
  campaignTypes,
  entityStatus,
  metricKinds,
  policy,
  status,
  tag,
  type,
  typeToEntityName,
  URL_TARGETING_FIELDS,
  resultsOutcome,
};

import _ from 'lodash';

import regex from 'optly/utils/regex';

const CATEGORY_OTHER = 'other';
/**
 * Services layer pure functions for the events
 */
export default {
  /**
   * Creates an empty event entity object with the supplied data
   */
  createEventEntity(data) {
    const DEFAULTS = {
      id: null,
      archived: false,
      name: null,
      category: CATEGORY_OTHER,
      // api_name: null, discard api_name because null value will throw an error in the api
      description: null,
      event_filter: null,
      event_type: null,
      config: {},
      event_properties: [],
    };

    return {
      ...DEFAULTS,
      ...data,
    };
  },

  /**
   * Verifies that the supplied event has valid properties
   * @param {object} event Click event object
   * @returns {object}
   */
  validateClickEvent(event) {
    const errorObject = {};
    if (!event.name) {
      errorObject.name = tr('This field is required');
    }

    // Checking for event.id is a proxy for whether or not the entity already exists in the backend.
    // If a click event doesn't have an id yet, then the click event doesn't have an api_name yet either.
    if (event.id && !event.api_name) {
      errorObject.api_name = tr('This field is required');
    }

    if (!event.event_filter.selector) {
      errorObject.selector = tr('This field is required');
    }

    return errorObject;
  },

  /**
   * Verifies that the supplied event has valid properties
   * @param {object} event Custom event object
   * @param {Array[]} events All events associated with project
   * @returns {object}
   */
  validateCustomEvent(event, events) {
    const errorObject = {};

    if (!event.name || !event.name.trim()) {
      errorObject.name = tr('This field is required.');
    } else {
      const duplicateEvent = events.find(
        currentEvent =>
          currentEvent.id !== event.id && currentEvent.name === event.name,
      );
      if (duplicateEvent) {
        errorObject.name = tr(
          `This name ${event.name} is already in use by another UserEvent (id: ${duplicateEvent.id}). Please provide a unique value.`,
        );
      }
    }

    if (!event.api_name || !event.api_name.trim()) {
      errorObject.api_name = tr('This field is required.');
    } else if (
      _.some(
        events,
        currentEvent =>
          currentEvent.id !== event.id &&
          currentEvent.api_name === event.api_name,
      )
    ) {
      errorObject.api_name = tr(
        'This API name already is in use by another event.',
      );
    } else if (!regex.eventAPIName.test(event.api_name)) {
      errorObject.api_name = tr(
        'API name may only contain letters, numbers, hyphens, underscores, spaces and periods, and must be shorter than 64 characters.',
      );
    }

    return errorObject;
  },

  /**
   * Creates a filter function that ensures the event is not archived or variation_specific
   * (aka specific to one campaign) and the parent view is valid and not archived
   * @param {Immutable.Map} viewEntityCache
   * @returns {Function}
   */
  createActiveProjectEventFilterFn(viewEntityCache) {
    return function(event) {
      if (event.get('archived') || event.get('variation_specific')) {
        return false;
      }

      const parentViewId = event.get('view_id');
      if (!parentViewId) {
        return true;
      }
      const parentView = viewEntityCache.get(parentViewId);
      return parentView && !parentView.get('archived');
    };
  },
};

const $ = require('jquery');
const _ = require('lodash');
const config = require('atomic-config');

const { toImmutable } = require('optly/immutable');

const beautifyHtml = require('js-beautify').html;

const flux = require('core/flux');
const guid = require('optly/utils/guid');
const urlUtil = require('optly/utils/url');
const regionGetters = require('core/ui/region/getters').default;
const showError = require('core/ui/methods/show_error').default;
const ui = require('core/ui').default;

const Concurrency = require('bundles/p13n/modules/concurrency_legacy').default;
const CurrentLayerGetters = require('bundles/p13n/modules/current_layer/getters');
const CurrentProjectGetters = require('optly/modules/current_project/getters');
const CustomCodeActions = require('bundles/p13n/modules/custom_code/actions');
const EditorIframe = require('bundles/p13n/modules/editor_iframe');

const authGetters = require('optly/modules/auth/getters');

const HighlighterEnums = require('optly/modules/highlighter/enums');
const ExperimentSectionActions = require('optly/modules/entity/experiment_section/actions')
  .default;
const LayerActions = require('optly/modules/entity/layer/actions').default;
const LayerExperimentActions = require('optly/modules/entity/layer_experiment/actions')
  .default;
const LayerFns = require('optly/modules/entity/layer/fns').default;
const LayerExperiment = require('optly/modules/entity/layer_experiment');
const TrackClicksChange = require('bundles/p13n/modules/track_clicks_change')
  .default;
const ViewGetters = require('optly/modules/entity/view/getters');
const uploadUtil = require('optly/utils/upload');
const UIActions = require('bundles/p13n/modules/ui/actions').default;

const getters = require('./getters');
const fns = require('./fns').default;
const constants = require('./constants').default;
const actionTypes = require('./action_types').default;

const JSSDKLabActions = require('@optimizely/js-sdk-lab/src/actions').default;

/**
 * This function takes care of saving a LayerExperiment or Section action, depending on the
 * layer's policy.
 * @param {Immutable.Map} experiment or section
 * @param {Immutable.Map} currentLayer
 * @param {Number} variationId
 * @param {Number} viewId
 * @return {Promise}
 */
exports.saveLayerExperimentOrSectionAction = (
  experimentOrSection,
  currentLayer,
  variationId,
  viewId,
) => {
  const variationIndex = experimentOrSection
    .get('variations')
    .findIndex(variation => variation.get('variation_id') === variationId);
  const actionToSave = experimentOrSection
    .getIn(['variations', variationIndex, 'actions'])
    .find(action => action.get('view_id') === viewId);
  const isMultivariateTestLayer = LayerFns.isMultivariateTestLayer(
    currentLayer,
  );
  const saveAction = isMultivariateTestLayer
    ? ExperimentSectionActions.cleanAndSaveAction
    : LayerExperimentActions.cleanAndSaveAction;

  return saveAction(actionToSave.toJS(), {
    id: experimentOrSection.get('id'),
    variation_id: variationId,
    view_id: viewId,
  }).then(() => {
    flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_RESET_IS_DIRTY);
  });
};

/**
 * Happens after the user unselects an action (view / variation) combination
 * Unbinds the iframe listeners, unsets the currently editing change and selected view/variation
 *
 * Note: must always be called when an action is already selected, ie selected view/variation/iframe
 */
exports.unselectAction = function() {
  const activeFrame = flux.evaluate(getters.activeFrame);
  const currentlyEditingChangeId = flux.evaluate(
    getters.currentlyEditingChangeId,
  );

  // YOLO: bind this to state
  if (activeFrame) {
    activeFrame.get('component').shown = false;
    activeFrame
      .get('component')
      .$off(EditorIframe.enums.IFrameMessageTypes.CLICK);
    EditorIframe.actions.setMode(
      activeFrame.get('id'),
      EditorIframe.enums.IFrameModeTypes.STANDARD,
    );
  }

  // TODO: unhighlighting and dispatch can be put in to one method
  if (currentlyEditingChangeId) {
    exports.unsetCurrentlyEditingChange();
  }

  // this feels a bit weird here since the other parts of this method dont rely on the selected view / variation context
  exports.unselectView();
  exports.unselectVariation();
  exports.unselectExperimentOrSectionId();
  CustomCodeActions.showCustomCodePanel(false); // close & destroy the custom code panel
};

/**
 * Setup the action specified by the view and Everyone Else Audience
 *
 * The Everyone Else audience is a special case, so we don't use the same
 * path as below in setupEditorState.
 *
 * @param {Vue} iframe EditorIframe component instance
 * @param {Object} view
 * @param {String} ID of experiment whose variations one should toggle between
 */
exports.setupEveryoneElseAction = function(
  iframe,
  view,
  experimentOrSectionId,
) {
  exports.selectView(view);
  exports.selectVariation(constants.EVERYONE_ELSE_AUDIENCE.variation_id);
  exports.selectExperimentOrSectionId(experimentOrSectionId);
  const activeFrame = flux.evaluate(getters.activeFrame);
  this.setInteractiveModeOnIFrame(activeFrame);
};

/**
 * Setup the given Iframe mode to be in Interactive mode on Ready state
 *
 * @param {Vue} activeFrame EditorIframe component instance
 */
exports.setInteractiveModeOnIFrame = function(activeFrame) {
  if (
    activeFrame.get('loadStatus') ===
    EditorIframe.enums.IFrameLoadStatuses.READY
  ) {
    EditorIframe.actions.setMode(
      activeFrame.get('id'),
      EditorIframe.enums.IFrameModeTypes.INTERACTIVE,
    );
  } else {
    activeFrame
      .get('component')
      .$on(
        EditorIframe.enums.IFrameMessageTypes.READY,
        _.partial(
          EditorIframe.actions.setMode,
          activeFrame.get('id'),
          EditorIframe.enums.IFrameModeTypes.INTERACTIVE,
        ),
      );
  }
};

/**
 * Reload the url for the currently selected iFrame.
 */
exports.reloadCurrentIframe = function() {
  const activeFrameId = flux.evaluate(getters.activeFrameId);
  EditorIframe.actions.reloadCurrentUrl(activeFrameId);
};

exports.editorCustomCodeOnSave = () => {
  const activeFrameId = flux.evaluateToJS(getters.activeFrameId);
  if (
    flux.evaluateToJS(
      EditorIframe.getters.iframeLoadingStatus(activeFrameId),
    ) === EditorIframe.enums.IFrameLoadStatuses.READY
  ) {
    exports.reloadCurrentIframe();
  }
};

/**
 * Calculate the URL to display and load it as necessary
 * Refresh the url for the currently selected iFrame.
 */
exports.refreshCurrentIframe = function() {
  const activeFrameId = flux.evaluate(getters.activeFrameId);
  const redirectChange = flux.evaluate(getters.currentActionRedirectChange);
  const selectedView = flux.evaluateToJS(getters.selectedView);

  // don't refresh for dest_fn changes
  if (redirectChange && redirectChange.get('dest_fn', null)) {
    return;
  }

  const url = redirectChange
    ? redirectChange.get('dest')
    : selectedView.edit_url;
  const protocols = redirectChange
    ? [EditorIframe.enums.ProtocolTypes.PROXY]
    : _.keys(EditorIframe.enums.ProtocolTypes);

  EditorIframe.actions.setCurrentUrl(activeFrameId, url, protocols);
};

/**
 * Creates a new redirect object and sets it as the currently editing change
 */
exports.createAndSelectNewRedirect = function() {
  exports.unsetCurrentlyEditingChange();
  const newRedirect = {
    id: guid(),
    type: LayerExperiment.enums.ChangeTypes.REDIRECT,
    status: LayerExperiment.enums.ChangeStatuses.INITIAL,
    preserveParameters: true,
    allowAdditionalRedirect: false,
    dest: '',
  };
  exports.setCurrentlyEditingRedirectChange(newRedirect);
};

/**
 * sets the currently editing change
 * @param {Change} change
 */
exports.setCurrentlyEditingChange = function(change) {
  // Setup track clicks change action
  const selectedViewId = flux.evaluate(getters.selectedViewId);
  TrackClicksChange.actions.initializeCurrentlyEditingTrackClickEvent(
    change.selector,
    selectedViewId,
  );

  flux.dispatch(actionTypes.P13N_EDITOR_SET_CURRENTLY_EDITING_CHANGE, {
    change,
  });

  const activeFrameId = flux.evaluate(getters.activeFrameId);
  EditorIframe.actions.unhighlightAllElements(activeFrameId);
  exports.highlightChange(change);
};

/**
 * Applies the currently editing change to the iframe
 */
exports.applyCurrentlyEditingChange = function() {
  const currentlyEditingChange = flux.evaluateToJS(
    getters.currentlyEditingChange,
  );
  const formattedChange = flux.evaluateToJS(
    getters.currentlyEditingActionFormatted(true, currentlyEditingChange),
  );
  const activeFrameId = flux.evaluateToJS(getters.activeFrameId);

  if (__DEV__) {
    console.debug(
      `[EDITOR_SIDEBAR] Applying change: ${currentlyEditingChange.id}`,
    ); // eslint-disable-line
  }

  EditorIframe.actions.applyChanges(activeFrameId, formattedChange);
};

/**
 * sets the currently editing redirect change
 * @param {Change} change
 */
exports.setCurrentlyEditingRedirectChange = function(change) {
  const formattedChange = Object.assign({}, change);

  formattedChange.isRedirectUrl = Object.prototype.hasOwnProperty.call(
    change,
    'isRedirectUrl',
  )
    ? change.isRedirectUrl
    : change.dest_fn === undefined;
  flux.dispatch(actionTypes.P13N_EDITOR_SET_CURRENTLY_EDITING_REDIRECT_CHANGE, {
    change: formattedChange,
  });
};

/**
 * sets the currently editing insert html change
 * @param {Change} change
 */
exports.setCurrentlyEditingInsertHTMLChange = function(change) {
  flux.dispatch(
    actionTypes.P13N_EDITOR_SET_CURRENTLY_EDITING_INSERT_HTML_CHANGE,
    {
      change,
    },
  );

  const activeFrameId = flux.evaluate(getters.activeFrameId);
  EditorIframe.actions.unhighlightAllElements(activeFrameId);

  // Insert HTML changes should always show the insert selector highlighted, not the inserted content.
  exports.highlightChange(change);
};

/**
 * sets the currently editing insert image change
 * @param {Change} change
 */
exports.setCurrentlyEditingInsertImageChange = function(change) {
  flux.dispatch(
    actionTypes.P13N_EDITOR_SET_CURRENTLY_EDITING_INSERT_IMAGE_CHANGE,
    {
      change,
    },
  );

  const activeFrameId = flux.evaluate(getters.activeFrameId);
  EditorIframe.actions.unhighlightAllElements(activeFrameId);

  EditorIframe.actions.highlightElement({
    id: activeFrameId,
    selector: change.selector,
    type: HighlighterEnums.IFrameHighlightTypes.SELECTED,
  });
};

/**
 * Creates a new change object and sets it as the currently editing change
 * @param {string} selector
 */
exports.createAndSelectNewChange = function(selector) {
  exports.unsetCurrentlyEditingChange();

  const newChange = {
    id: guid(),
    selector,
    dependencies: [],
  };
  exports.setCurrentlyEditingChange(newChange);
};

/**
 * Creates a new insert HTML change object and sets it as the currently editing change
 */
exports.createAndSelectInsertHTMLChange = function() {
  exports.unsetCurrentlyEditingChange();

  const newChange = {
    id: guid(),
    selector: '',
    dependencies: [],
  };
  exports.setCurrentlyEditingInsertHTMLChange(newChange);
};

/**
 * Creates a new insert image change object and sets it as the currently editing change
 */
exports.createAndSelectInsertImageChange = function() {
  exports.unsetCurrentlyEditingChange();

  const newChange = {
    id: guid(),
    selector: '',
    dependencies: [],
  };
  exports.setCurrentlyEditingInsertImageChange(newChange);
};

/**
 * Selects the change based on an existing change, fetching the elementInfo first.
 * @param {Object} change
 */
exports.setChangeBasedOnExistingChange = function(change) {
  const activeFrameId = flux.evaluate(getters.activeFrameId);
  return (
    EditorIframe.actions
      .fetchElementInfo(activeFrameId, change.selector, change.id)
      .then(
        fetchedSelectorInfo => {
          exports.setCurrentlyEditingChange(change);
          exports.setElementInfoForChange(fetchedSelectorInfo);
          return fetchedSelectorInfo;
        },
        error => {
          console.warn(
            `Unable to fetch elementInfo for frameId ${activeFrameId}`,
            error,
          ); // eslint-disable-line
          // Set the currentlyEditingChange even if we dont have elementInfo so it can still be edited or deleted.
          exports.setCurrentlyEditingChange(change);
        },
      )
      // Set the currentlyEditingChange even if we dont have elementInfo so it can still be edited.
      .fail(_.partial(exports.setCurrentlyEditingChange, change))
  );
};

/**
 * Selects the change based on the selector.
 * @private
 * @param {String} selector
 * @param {Object?} selectorInfo
 * @param {Array} selectorInfo.elementInfo
 * @param {Number} selectorInfo.elementCount
 */
exports.setChangeBasedOnSelector = (selector, selectorInfo) => {
  const activeFrameId = flux.evaluate(getters.activeFrameId);
  const selectors = _.map(
    flux.evaluateToJS(getters.currentActionAttributeChanges),
    'selector',
  );
  const simpleSelectorMatch = flux.evaluateToJS(
    getters.getAttributeChangeBySelector(selector),
  );

  // if the selector is identical to one stored in an existing change, immediately load that change
  if (simpleSelectorMatch) {
    const def = $.Deferred();
    // Since we found an existing change, we need to fetch the elementInfo with the right change subset applied.
    EditorIframe.actions
      .fetchElementInfo(activeFrameId, selector, simpleSelectorMatch.id)
      .then(fetchedSelectorInfo => {
        exports.unsetCurrentlyEditingChange();
        exports.setCurrentlyEditingChange(simpleSelectorMatch);
        exports.setElementInfoForChange(fetchedSelectorInfo);
        def.resolve();
      });
    return def;
  }

  // if there are no changes, immediately create a new one
  if (!selectors.length) {
    exports.createAndSelectNewChange(selector);
    exports.setElementInfoForChange(selectorInfo);
    exports.setInitialSelector(selector || '');
    return $.Deferred().resolve();
  }

  // If there isn't a simple match, we need to check to see if any of our changes have equivalent selectors.
  // It is important to do this as 1) selectorator may change over time and 2) based on page timing, we may
  // generate slightly different selectors for the same element(s)
  return EditorIframe.actions
    .testSelectorEquality(
      flux.evaluate(getters.activeFrameId),
      selector,
      selectors,
    )
    .then(matchingSelectors => {
      if (!matchingSelectors.length) {
        exports.createAndSelectNewChange(selector);
        exports.setElementInfoForChange(selectorInfo);
        exports.setInitialSelector(selector || '');
        return;
      }
      const matchingSelector = matchingSelectors.pop();
      const matchingChange = flux.evaluateToJS(
        getters.getChangeBySelector(matchingSelector),
      );
      // Since we found an existing change, we need to fetch the elementInfo with the right change subset applied.
      EditorIframe.actions
        .fetchElementInfo(activeFrameId, matchingSelector, matchingChange.id)
        .then(fetchedSelectorInfo => {
          exports.unsetCurrentlyEditingChange();
          exports.setCurrentlyEditingChange(matchingChange);
          exports.setElementInfoForChange(fetchedSelectorInfo);
        });
    });
};

/**
 * Apply the currentlyEditingActionFormatted if there are dirty changes to ensure
 * that the dirty changes are undone in the iframe.
 */
exports.undoDirtyChange = function() {
  if (flux.evaluateToJS(getters.currentlyEditingChangeIsDirty)) {
    const changes = flux.evaluateToJS(
      getters.currentlyEditingActionFormatted(true),
    );
    const frameId = flux.evaluateToJS(getters.activeFrameId);
    EditorIframe.actions.applyChanges(frameId, changes);
  }
};

/**
 * Unsets the currently editing change
 */
exports.unsetCurrentlyEditingChange = function() {
  TrackClicksChange.actions.revertChanges();
  const changeToUnset = flux.evaluate(getters.currentlyEditingChange);
  if (changeToUnset.get('id')) {
    exports.unhighlightChange(changeToUnset.toJS());
  }
  flux.dispatch(actionTypes.P13N_EDITOR_UNSET_CURRENTLY_EDITING_CHANGE);
};

/**
 * Sets the currently editing change's dependencies.
 * A change can depend on a previous change or not depend on a previous.
 * This action sets the dependencies to reflect whether or not the currently
 * editing change should depend on the previous change or not.
 *
 * @param {Boolean} dependsOnPrevious
 */
exports.setCurrentlyEditingChangeDependencies = dependsOnPrevious => {
  const changesList = flux.evaluate(
    getters.currentActionAttributeChangesWithStatus,
  );
  const currentlyEditingChange = flux.evaluate(getters.currentlyEditingChange);
  let updatedDependencies = currentlyEditingChange.get('dependencies');
  const currentlyEditingChangeIndex = changesList.findIndex(
    change => change.get('id') === currentlyEditingChange.get('id'),
  );

  // No need to update dependencies if it's the first change in the list.
  if (currentlyEditingChangeIndex === 0) {
    return;
  }

  const previousChangeId =
    currentlyEditingChangeIndex < 0
      ? changesList.last().get('id') // when currentlyEditingChangeIndex === -1 and the currently editing change is new
      : changesList.getIn([currentlyEditingChangeIndex - 1, 'id']); // when the currently editing change already exists

  // If the currently editing change is dependent on the previous change
  if (dependsOnPrevious) {
    const previousIdAlreadyInDependencies = updatedDependencies.some(
      dependencyId => dependencyId === previousChangeId,
    );
    if (!previousIdAlreadyInDependencies) {
      updatedDependencies = updatedDependencies.push(previousChangeId);
    }
    // If the currently editing change is NOT dependent on the previous change
  } else {
    updatedDependencies = updatedDependencies.filter(
      dependencyId => dependencyId !== previousChangeId,
    );
  }

  flux.dispatch(
    actionTypes.P13N_EDITOR_SET_CURRENTLY_EDITING_CHANGE_DEPENDENCIES,
    {
      dependencies: updatedDependencies,
    },
  );
};

const getRequestError = () => ({
  error: true,
  errorMessage:
    'Sorry, we were not able to generate new suggestions for this content.',
});

exports.getContentSuggestions = async options => {
  try {
    const contentSuggestionsResponse = await fetch(
      '/opti-ai/content-suggestions',
      {
        method: 'POST',
        headers: {
          'x-csrf-token':
            flux.evaluate(authGetters.csrfToken) || config.get('csrf', ''),
        },
        body: JSON.stringify(options),
      },
    );
    const contentSuggestions = await contentSuggestionsResponse.json();
    if (!contentSuggestions.succeeded || !contentSuggestions.variations) {
      return getRequestError();
    }

    return contentSuggestions;
  } catch (error) {
    console.error('error', error);
    return getRequestError();
  }
};

/** *************************************************************
 ******************  PERSISTING CHANGES  ***********************
 ************************************************************** */

/**
 * Deletes a change by id
 * @param {String} changeId
 */
exports.deleteChange = function(changeId) {
  const currentLayerExperimentOrSection = flux.evaluate(
    getters.currentLayerExperimentOrSection,
  );
  let changeList = flux.evaluate(getters.currentActionChanges);

  const viewId = flux.evaluate(getters.selectedViewId);
  // Get the current variation ID; for personalization, there is only 1 variation per layerExperiment
  const variationId = flux.evaluate(getters.selectedVariationId);

  const changeIndex = changeList.findIndex(
    change => change.get('id') === changeId,
  );
  let updatedChanges;

  // If the deleted change is a dependency for any another change in the list, update the dependencies list for that dependent change.
  changeList = changeList.map(change => {
    if (change.get('dependencies').contains(changeId)) {
      const updatedDependencies = change
        .get('dependencies')
        .filter(dependencyId => dependencyId !== changeId);
      return change.set(['dependencies'], updatedDependencies);
    }
    return change;
  });

  if (changeIndex >= 0) {
    updatedChanges = changeList.delete(changeIndex);
    updatedChanges = fns.orderChangeList(updatedChanges);
    const updatedLayerExperimentOrSection = LayerExperiment.fns.addAction(
      viewId,
      variationId,
      updatedChanges,
      currentLayerExperimentOrSection,
      showError,
    );
    const currentLayer = flux.evaluate(CurrentLayerGetters.layer);
    return exports
      .saveLayerExperimentOrSectionAction(
        updatedLayerExperimentOrSection,
        currentLayer,
        variationId,
        viewId,
      )
      .then(() => {
        if (
          flux.evaluate(TrackClicksChange.getters.campaignEventWithMatchingId)
        ) {
          TrackClicksChange.actions.setTrackClickEventEnabled(false);
          return TrackClicksChange.actions.saveTrackClicksChangeEvent();
        }
      });
  }

  console.warn(
    '[EDITOR] Could not find change instance with matching id to delete',
  ); //eslint-disable-line
  return $.Deferred().reject();
};

/**
 * lint code
 * @param  {Object} customCodeChange
 * @return {$.Deferred}
 */
const lintCode = customCodeChange => {
  if (customCodeChange.type === LayerExperiment.enums.ChangeTypes.CUSTOM_CODE) {
    const lintResult = EditorIframe.fns.lintCode(customCodeChange.value);
    if (lintResult.hasLintErrors) {
      return $.Deferred().reject(lintResult);
    }
  }
};

/**
 * save custom-code experience(layerExperiment) javascript/css to LayerExperiment/Variation
 * @param  {Object} customCodeChange
 * @return {$.Deferred}
 */
exports.saveVariationCustomCodeChange = function(customCodeChange) {
  const currentLayerExperimentOrSection = flux.evaluate(
    getters.currentLayerExperimentOrSection,
  );
  const variationId = flux.evaluate(getters.selectedVariationId);
  const selectedViewId = flux.evaluate(getters.selectedViewId);

  const changeList = flux.evaluate(getters.currentActionChanges);
  let updatedChanges = fns.updateChangeListWithFormattedChange(
    changeList,
    toImmutable(customCodeChange),
  );
  updatedChanges = fns.orderChangeList(updatedChanges);
  const updatedLayerExperimentOrSection = LayerExperiment.fns.addAction(
    selectedViewId,
    variationId,
    updatedChanges,
    currentLayerExperimentOrSection,
    showError,
  );

  const currentLayer = flux.evaluate(CurrentLayerGetters.layer);

  return exports.saveLayerExperimentOrSectionAction(
    updatedLayerExperimentOrSection,
    currentLayer,
    variationId,
    selectedViewId,
  );
};

/**
 * save custom-code campaign(layer) javascript/css to Layer
 * @param  {Object} customCodeChange
 * @return {$.Deferred}
 */
exports.saveLayerCustomCodeChange = function(customCodeChange) {
  const currentLayer = flux.evaluate(CurrentLayerGetters.layer);
  const layerChanges = flux.evaluate(CurrentLayerGetters.layerChanges);
  const updatedChangeList = fns.updateChangeListWithFormattedChange(
    layerChanges,
    toImmutable(customCodeChange),
  );

  return LayerActions.save({
    id: currentLayer.get('id'),
    changes: updatedChangeList.toJS(),
  });
};

/**
 * Saves a WidgetChange on the currently editing layer experiment
 * @param {WidgetChange} change.
 * @return {Deferred}
 */
exports.saveWidgetChange = function(change) {
  if (__INVARIANT__) {
    assertType(
      require('optly/modules/entity/layer_experiment/types').WidgetChange, // eslint-disable-line
      change,
    );
  }

  const currentLayerExperimentOrSection = flux.evaluate(
    getters.currentLayerExperimentOrSection,
  );
  const variationId = flux.evaluate(getters.selectedVariationId);
  const selectedViewId = flux.evaluate(getters.selectedViewId);

  const changeList = flux.evaluate(getters.currentActionChanges);
  let updatedChanges = fns.updateChangeListWithFormattedChange(
    changeList,
    toImmutable(change),
  );
  updatedChanges = fns.orderChangeList(updatedChanges);
  const updatedLayerExperimentOrSection = LayerExperiment.fns.addAction(
    selectedViewId,
    variationId,
    updatedChanges,
    currentLayerExperimentOrSection,
    showError,
  );

  const currentLayer = flux.evaluate(CurrentLayerGetters.layer);
  return exports.saveLayerExperimentOrSectionAction(
    updatedLayerExperimentOrSection,
    currentLayer,
    variationId,
    selectedViewId,
  );
};

/**
 * save the currently editing change
 */
exports.saveEditingChange = function(variationIdToSave) {
  const immutableCurrentlyEditingChange = flux.evaluate(
    getters.currentlyEditingChange,
  );

  if (
    !LayerExperiment.fns.validateLayerExperimentChange(
      immutableCurrentlyEditingChange,
    )
  ) {
    return $.Deferred().reject();
  }
  // get the currently editing change and format it to change-applier form as a JS object.
  const currentlyEditingChange = immutableCurrentlyEditingChange.toJS();

  // format the change for saving
  let formattedChange = {
    id: currentlyEditingChange.id,
    status: currentlyEditingChange.status,
    type: currentlyEditingChange.type,
    dependencies: currentlyEditingChange.dependencies || [],
  };

  switch (currentlyEditingChange.type) {
    case LayerExperiment.enums.ChangeTypes.ATTRIBUTE:
      formattedChange.selector = currentlyEditingChange.selector;
      formattedChange.async = currentlyEditingChange.async;
      formattedChange.attributes = {};
      formattedChange.rearrange = {
        insertSelector: currentlyEditingChange.rearrange.insertSelector,
        operator: currentlyEditingChange.rearrange.operator,
      };
      formattedChange.css = currentlyEditingChange.css;
      break;
    case LayerExperiment.enums.ChangeTypes.INSERT_HTML:
    case LayerExperiment.enums.ChangeTypes.INSERT_IMAGE:
      formattedChange.selector = currentlyEditingChange.selector;
      formattedChange.async = currentlyEditingChange.async;
      formattedChange.value = currentlyEditingChange.value;
      formattedChange.operator = currentlyEditingChange.operator;
      break;
    case LayerExperiment.enums.ChangeTypes.REDIRECT:
      if (currentlyEditingChange.isRedirectUrl) {
        formattedChange.dest = currentlyEditingChange.dest;
        formattedChange.preserveParameters =
          currentlyEditingChange.preserveParameters;
      } else {
        // trim right surrounding newlines
        formattedChange.dest_fn = currentlyEditingChange.dest_fn;
        formattedChange.preserveParameters = false;
      }
      formattedChange.allowAdditionalRedirect =
        currentlyEditingChange.allowAdditionalRedirect;
      break;
    default:
      window.console.warn("Editing change doesn't have a valid type");
  }

  formattedChange = toImmutable(formattedChange);

  // Explicitly allow name to be set to an empty string
  if (
    !_.isNull(currentlyEditingChange.name) &&
    !_.isUndefined(currentlyEditingChange.name)
  ) {
    formattedChange = formattedChange.set('name', currentlyEditingChange.name);
  }

  _.each(currentlyEditingChange.attributes, (changeValue, type) => {
    if (currentlyEditingChange.shouldSaveFlags[type]) {
      formattedChange = formattedChange.setIn(
        ['attributes', type],
        changeValue,
      );
    }
  });

  // get the Immutable current layer experiment and current action
  const currentLayerExperimentOrSection = flux.evaluate(
    getters.currentLayerExperimentOrSection,
  );
  const changeList = flux.evaluate(getters.currentActionChanges);

  const viewId = flux.evaluate(getters.selectedViewId);
  // get the current variation ID; for personalization, there is only 1 variation per layerExperiment
  const variationId =
    variationIdToSave ?? flux.evaluate(getters.selectedVariationId);
  let updatedChanges = fns.updateChangeListWithFormattedChange(
    changeList,
    formattedChange,
  );
  updatedChanges = fns.orderChangeList(updatedChanges);

  const updatedLayerExperimentOrSection = LayerExperiment.fns.addAction(
    viewId,
    variationId,
    updatedChanges,
    currentLayerExperimentOrSection,
    showError,
  );

  const currentLayer = flux.evaluate(CurrentLayerGetters.layer);

  return exports
    .saveLayerExperimentOrSectionAction(
      updatedLayerExperimentOrSection,
      currentLayer,
      variationId,
      viewId,
    )
    .then(() => TrackClicksChange.actions.saveTrackClicksChangeEvent());
};

exports.resetCurrentlyEditingDirtyChange = function() {
  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_RESET_IS_DIRTY);
};

/**
 * Set the provided change attribute and value for the currently editing change.
 *
 * Note that this is only setting the specified attribute value in the currentlyEditingChange
 * store and not persisting anything to the entity store. This is important when determining
 * what we consider the "Original" value of the change in the event that the users actions
 * should result in no change to the value and therefore no dirty state.
 *
 * @param type
 * @param newValue
 */
exports.setChangeAttributesProperty = function(type, newValue) {
  let valueToSet = newValue;
  let shouldSaveFlag = true;

  const currentlyEditingChange = flux.evaluate(getters.currentlyEditingChange);
  const currentSavedChange = flux.evaluate(
    getters.getChangeById(currentlyEditingChange.get('id')),
  );
  const currentSavedValue = currentSavedChange
    ? currentSavedChange.getIn(['attributes', type], null)
    : null;

  // Note about elementInfo:
  //   - elementInfo is fetched when there are no changes are applied to the page, meaning
  //     it does not contain values from saved changes but rather the "original" element values
  //   - elementInfo is only updated when a change is selected OR when its selector changes
  //   - elementInfo is NOT updated as changes are typed in the editor
  const currentlyEditingChangeElementInfo = currentlyEditingChange
    .get('elementInfo')
    .first();

  const originalValue = fns.getOriginalValueForChange(
    currentSavedValue,
    currentlyEditingChangeElementInfo,
    type,
  );

  // If the attribute value to set is the same as the original value, we haven't made any change.
  if (newValue === originalValue) {
    // This means were trying to set this attribute in the currentlyEditingChange store to the same value
    // that it had when we selected the change. In this case, we haven't made a change to the value, so
    // we'll set this attribute back to what it was originally.
    valueToSet = currentSavedValue;

    // Were setting the original value in the store, so make sure the shouldSaveFlag is false
    // because this attribute's value its not dirty, meaning it no longer needs to be saved.
    shouldSaveFlag = false;

    // If the new value is empty and we dont have any elementInfo, we want to set the value back to null.
  } else if (
    !currentlyEditingChangeElementInfo &&
    _.isEmpty(newValue) &&
    !_.isBoolean(newValue)
  ) {
    valueToSet = null;
    if (currentSavedValue === null) {
      shouldSaveFlag = false;
    }
  }

  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_SET_ATTRIBUTES_PROPERTY, {
    type,
    value: valueToSet,
    shouldSaveFlag,
  });
};

/**
 * Revert the provided change attribute type value from its current dirty value to saved value in elementInfo.
 * @param type  - change attribute type
 */
exports.revertChangeAttribute = function(type) {
  const currentlyEditingChange = flux.evaluate(getters.currentlyEditingChange);
  const currentSavedChange = flux.evaluate(
    getters.getChangeById(currentlyEditingChange.get('id')),
  );
  const currentSavedValue = currentSavedChange
    ? currentSavedChange.getIn(['attributes', type], null)
    : null;

  // Note about elementInfo:
  //   - elementInfo is fetched when there are no changes are applied to the page, meaning
  //     it does not contain values from saved changes but rather the "original" element values
  //   - elementInfo is only updated when a change is selected OR when its selector changes
  //   - elementInfo is NOT updated as changes are typed in the editor
  const currentlyEditingChangeElementInfo = currentlyEditingChange
    .get('elementInfo')
    .first();

  const originalValue = fns.getOriginalValueForChange(
    currentSavedValue,
    currentlyEditingChangeElementInfo,
    type,
  );

  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_SET_ATTRIBUTES_PROPERTY, {
    type,
    value: originalValue,
    shouldSaveFlag: false,
  });
};

/**
 * Revert the currentlyEditingChange's selector property.
 */
exports.revertChangeSelector = () => {
  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_SET_SELECTOR, {
    selector: flux.evaluate(getters.initialSelector),
    isDirty: false,
  });
};

exports.revertCSSProperty = property => {
  const storedValue = flux.evaluate(getters.storedCSSValue(property));

  if (storedValue !== null) {
    flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_SET_CSS_PROPERTY, {
      property,
      value: storedValue,
    });
  } else {
    flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_REMOVE_CSS_PROPERTY, {
      property,
    });
  }
};

/**
 * Revert the currentlyEditingChange's rearrange property.
 */
exports.revertChangeRearrange = () => {
  const defaultProperties = {
    operator: 'before',
    insertSelector: '',
  };
  const currentlyEditingChange = flux.evaluate(getters.currentlyEditingChange);
  const currentSavedChange = flux.evaluate(
    getters.getChangeById(currentlyEditingChange.get('id')),
  );
  const currentSavedValue = currentSavedChange
    ? currentSavedChange.get('rearrange').toJS()
    : defaultProperties;

  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_SET_REARRANGE_PROPERTY, {
    data: currentSavedValue,
  });
};

/**
 * Revert the currentlyEditingInsertHTMLChange's HTML value.
 */
exports.revertInsertHTMLValue = () => {
  const currentlyEditingChange = flux.evaluate(getters.currentlyEditingChange);
  const currentSavedChange = flux.evaluate(
    getters.getChangeById(currentlyEditingChange.get('id')),
  );
  const currentSavedValue = currentSavedChange
    ? currentSavedChange.get('value')
    : '';

  flux.dispatch(actionTypes.P13N_INSERT_HTML_SET_HTML_VALUE, {
    html: currentSavedValue,
  });
};

/**
 * Revert the timing property for the currentlyEditingChange.
 */
exports.revertChangeTiming = () => {
  const currentlyEditingChange = flux.evaluate(getters.currentlyEditingChange);
  const currentSavedChange = flux.evaluate(
    getters.getChangeById(currentlyEditingChange.get('id')),
  );
  const currentSavedValue =
    currentSavedChange && currentSavedChange.has('async')
      ? currentSavedChange.get('async')
      : false;

  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_SET_ASYNC_PROPERTY, {
    async: currentSavedValue,
  });
};

/**
 * Saves the current selector. This is stored so we know what selector
 * to revert back to if a revert is needed.
 * @param currentSelector
 */
exports.setInitialSelector = currentSelector => {
  flux.dispatch(actionTypes.P13N_CHANGE_EDITOR_SET_INITIAL_SELECTOR, {
    selector: currentSelector,
  });
};

/**
 * Set the provided selector for the currently editing change.
 * @param newSelector
 */
exports.setChangeSelector = function(newSelector) {
  let currentlyEditingChange = flux.evaluateToJS(
    getters.currentlyEditingChange,
  );

  if (currentlyEditingChange.selector !== newSelector) {
    exports.unhighlightChange(currentlyEditingChange);
  }

  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_SET_SELECTOR, {
    selector: newSelector,
    isDirty: newSelector !== flux.evaluate(getters.initialSelector),
  });

  currentlyEditingChange = flux.evaluateToJS(getters.currentlyEditingChange);
  exports.highlightChange(currentlyEditingChange);
};

/**
 * Set the provided rearrange property and value for the currently editing change.
 * @param {string} key
 * @param {string} newValue
 */
exports.setChangeRearrangeProperty = (key, newValue) => {
  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_SET_REARRANGE_PROPERTY, {
    data: {
      [key]: newValue,
    },
  });

  const currentlyEditingChange = flux.evaluateToJS(
    getters.currentlyEditingChange,
  );
  exports.unhighlightChange(currentlyEditingChange);
  exports.highlightChange(currentlyEditingChange);
};

/**
 * Set the provided timing Value for the currently editing change.
 * @param {Boolean} isAsync
 */
exports.setChangeTiming = isAsync => {
  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_SET_ASYNC_PROPERTY, {
    async: isAsync,
  });
};

/**
 * Unhighlight the specified change in the editor.
 * @param change
 */
exports.unhighlightChange = function(change) {
  const activeFrameId = flux.evaluate(getters.activeFrameId);
  const unhighlightOptions = {
    id: activeFrameId,
    dataOptlyId: change.id,
    selector: change.selector,
    type: HighlighterEnums.IFrameHighlightTypes.SELECTED,
  };

  EditorIframe.actions.unhighlightElement(unhighlightOptions);
};

/**
 * Highlight the specified change in the editor.
 * @param change
 */
exports.highlightChange = function(change) {
  const activeFrameId = flux.evaluate(getters.activeFrameId);
  const highlightOptions = _.extend(
    {
      id: activeFrameId,
      type: HighlighterEnums.IFrameHighlightTypes.SELECTED,
    },
    fns.getHighlighterOptionsForElementChange(change),
  );

  EditorIframe.actions.highlightElement(highlightOptions);
};

/**
 * Set the provided name for the currently editing change.
 * @param selector
 */
exports.setChangeName = function(name) {
  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_SET_NAME, {
    name,
  });
};

/**
 * Uploads image to S3 and returns url (or error message) in $.Deferred resolve/reject
 * @param {File} file
 * @returns {$.Deferred}
 */
exports.uploadImage = function(file) {
  return uploadUtil.upload(
    file,
    null,
    null,
    flux.evaluate(CurrentProjectGetters.id),
  );
};

/**
 * Destroys pre-fetched editor iframes belonging to current layer variations.
 * We destroy all iframes corresponding to all views in a given layer,
 * when campaign level custom code(js/css) is saved
 */
exports.destroyVariationIFrames = function() {
  const currentLayerVariations = flux.evaluate(CurrentLayerGetters.variations);
  const currentLayerViews = flux.evaluateToJS(CurrentLayerGetters.views);
  const viewIds = _.map(currentLayerViews, 'id');
  currentLayerVariations.forEach(variation => {
    const variationId = variation.get('variation_id');
    _.map(viewIds, viewId => {
      const iFrameId = fns.getIframeId(viewId, variationId);
      EditorIframe.actions.destroyIFrame(iFrameId);
    });
  });
};

/**
 * Binds a ready handler for an iframe to apply all the changes for a particular action
 * @param {Vue} iframe
 * @param {Object} view
 * @param {String} variationId
 * @param {Function} actionChangesGetter
 */
exports.applyChangesOnReady = function(
  iframe,
  view,
  variationId,
  actionChangesGetter,
) {
  iframe.$on(EditorIframe.enums.IFrameMessageTypes.READY, () => {
    const iframeId = fns.getIframeId(view.id, variationId);
    // get layer level changes
    const layerChanges = flux.evaluate(CurrentLayerGetters.layerChanges);
    // get Array<Change> for the view / audience
    const actionChanges = flux.evaluate(
      actionChangesGetter(view.id, variationId),
    );
    // concat both layer level changes  and action changes for view
    const allChanges = layerChanges.concat(actionChanges);
    const formattedChanges = allChanges.map(fns.formatChangeForApply);
    EditorIframe.actions.applyChanges(iframeId, formattedChanges.toJS());
    EditorIframe.actions.syncState(
      iframeId,
      EditorIframe.enums.EditorTypes.EXPERIMENT_EDITOR,
    );
  });
};

/**
 * Selects page for the experience that is currently being edited
 * @private
 * @param {Object} view
 */
exports.selectView = function(view) {
  flux.dispatch(actionTypes.P13N_EDITOR_SELECT_VIEW, {
    id: view.id,
  });
};

/**
 * unselects the current page that is selected
 * @private
 */
exports.unselectView = function() {
  flux.dispatch(actionTypes.P13N_EDITOR_UNSELECT_VIEW);
};

/**
 * Unselects the current variation that is selected
 * @private
 */
exports.unselectVariation = () => {
  exports.unsubscribeFromCurrentExperiment();
  flux.dispatch(actionTypes.P13N_EDITOR_UNSELECT_VARIATION);
};

/**
 * Selects variation for the experience that is currently being edited
 * @private
 * @param {Object} variation
 */
exports.selectVariation = variationId => {
  flux.dispatch(actionTypes.P13N_EDITOR_SELECT_VARIATION, {
    id: variationId,
  });
  exports.subscribeToCurrentExperiment();
};

/**
 * Make a call to the Concurrency service to subscribe to the
 * currently selected LayerExperiment. Also ensure that if the user
 * comes back online that subscribe is called again.
 */
exports.subscribeToCurrentExperiment = () => {
  const experiment = flux.evaluate(getters.currentLayerExperimentOrSection);
  if (experiment) {
    Concurrency.actions.subscribeToEntity(
      LayerExperiment.entityDef.entity,
      experiment.get('id'),
    );
    // If the user loses their internet connection and comes back, make sure
    // we invoke this method again to resubscribe them.
    $(window).one('online', exports.subscribeToCurrentExperiment);
  }
};

/**
 * Unsubscribe from the current LayerExperiment and make sure that subscribe
 * is not called if the user comes back online.
 */
exports.unsubscribeFromCurrentExperiment = () => {
  const experiment = flux.evaluate(getters.currentLayerExperimentOrSection);
  if (experiment) {
    Concurrency.actions.unsubscribeFromEntity(
      LayerExperiment.entityDef.entity,
      experiment.get('id'),
    );
  }
  // Remove any handlers that would resubscribe a user when they come back online.
  $(window).off('online', exports.subscribeToCurrentExperiment);
};

/**
 * @private
 * @param {Object} fetchedSelectorInfo
 * @param {Array} fetchedSelectorInfo.elementInfo
 * @param {Number} fetchedSelectorInfo.elementCount
 */
exports.setElementInfoForChange = function(selectorInfo) {
  const { elementInfo } = selectorInfo;
  const isBeautifyHtmlEnabled = JSSDKLabActions.isFeatureEnabled(
    'beautify_html_enabled',
  );
  const prettyElementInfo = _.each(elementInfo, info => {
    // Restore CSSStyleDeclaration method that was lost in the stringification for postMessaging
    // Only IDL-style properties survive JSON stringification, so recreate the API to access those
    info.computedStyle.getPropertyValue = function(cssProperty) {
      return this[cssProperty] || this[fns.cssToIDL(cssProperty)];
    };

    return _.extend(info, {
      html: isBeautifyHtmlEnabled
        ? beautifyHtml(info.html.trim(), {
            indent_size: 2,
            max_preserve_newlines: 1,
          })
        : info.html.trim(),
    });
  });
  flux.dispatch(actionTypes.P13N_EDITOR_SET_CURRENT_CHANGE_ELEMENT_INFO, {
    elementInfo: prettyElementInfo,
    elementCount: selectorInfo.elementCount,
  });
};

/**
 * Resets ElementInfo store
 * @private
 */
exports.resetElementInfo = function() {
  flux.dispatch(actionTypes.P13N_EDITOR_RESET_ELEMENT_INFO);
};

/**
 * Set the selector we are currently editing
 *
 * @param {string} selector
 */
exports.setChangeEditorCurrentSelector = function(selector) {
  flux.dispatch(actionTypes.P13N_CHANGE_EDITOR_SET_CURRENT_SELECTOR, {
    selector,
  });
};

/**
 * Set the selector type we are currently editing, as the change editor sidebar
 * has multiple selector input fields, but only one can be edited at a time.
 *
 * @param {string} type
 */
exports.setChangeEditorCurrentSelectorType = function(type) {
  flux.dispatch(actionTypes.P13N_CHANGE_EDITOR_SET_CURRENT_SELECTOR_TYPE, {
    type,
  });
};

/**
 * @param {boolean} enabled
 */
exports.setChangeEditorElementSelectorEnabled = function(enabled) {
  flux.dispatch(
    actionTypes.P13N_EDITOR_SET_CHANGE_EDITOR_ELEMENT_SELECTOR_ENABLED,
    {
      enabled,
    },
  );
};

/**
 * @param {boolean} isEditing
 */
exports.setChangeEditorIsEditingSelector = function(isEditing) {
  flux.dispatch(actionTypes.P13N_EDITOR_SET_CHANGE_EDITOR_IS_EDITING_SELECTOR, {
    isEditing,
  });
};

/**
 * Returns an existing change if one already exists for the provided selector
 * or if a change has a selector that is functionally equivalent to the
 * one provided.
 *
 * @param {string} selector
 * @returns {object | null}
 */
exports.getChangeMatchingSelector = function(selector) {
  const selectors = _.map(
    flux.evaluateToJS(getters.currentActionChanges),
    'selector',
  );
  const simpleSelectorMatch = flux.evaluateToJS(
    getters.getChangeBySelector(selector),
  );

  if (simpleSelectorMatch) {
    return simpleSelectorMatch;
  }

  if (selectors.length) {
    const matchingSelectors = EditorIframe.actions.testSelectorEquality(
      flux.evaluate(getters.activeFrameId),
      selector,
      selectors,
    );

    if (matchingSelectors.length) {
      return flux.evaluateToJS(
        getters.getChangeBySelector(matchingSelectors.pop()),
      );
    }
  }

  return null;
};

/**
 * Retrieve the representative url for the currently selected view and the protocol
 *
 * @returns {object}
 */
exports.getSelectedViewUrlAndProtocol = function() {
  const urlAndProtocol = {
    url: null,
    protocol: null,
  };
  const selectedView = flux.evaluateToJS(getters.selectedView);

  if (selectedView) {
    urlAndProtocol.url = selectedView.edit_url;
    urlAndProtocol.protocol = flux.evaluateToJS(
      EditorIframe.getters.urlProtocolMapping(selectedView.edit_url),
    );
  }
  return urlAndProtocol;
};

/**
 * Given an image url, determine the absolute path of the specified image. In most cases,
 * this url will already be an absolute url, however in the case that we load a page through the
 * proxy and the customer's site uses their site domain as their asset server, all their urls will be
 * going through http://www.optimizelyedit.com. We convert these to relative urls for display purposes,
 * however we need to get the absolute url so that we can load the image in the editor.
 *
 * @returns {string}
 */
exports.getAbsoluteUrlForImage = function(url) {
  if (url && url.match(/^\/[^/]+/)) {
    const urlAndProtocol = exports.getSelectedViewUrlAndProtocol();
    if (urlAndProtocol.url && urlAndProtocol.protocol) {
      // retrieve url object so we can grab hostname
      const urlObject = urlUtil.create(
        urlUtil.generateHttpUrl(urlAndProtocol.url),
      );
      switch (urlAndProtocol.protocol) {
        case EditorIframe.enums.ProtocolTypes.PROXY:
          return urlUtil.generateProxyUrl(
            urlUtil.generateHttpUrl(urlObject.hostname + url),
          );
        case EditorIframe.enums.ProtocolTypes.HTTPS:
          return urlUtil.generateHttpsUrl(urlObject.hostname + url);
        default:
          return urlUtil.generateHttpUrl(urlObject.hostname + url);
      }
    }
  }
  return url;
};

exports.setChangeCSSProperty = (property, value) => {
  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_SET_CSS_PROPERTY, {
    property,
    value,
  });
};

exports.removeChangeCSSProperty = property => {
  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_REMOVE_CSS_PROPERTY, {
    property,
  });
};

/**
 * Given an image, set the relevant properties on it.
 * @param image
 */
exports.setImageProperties = image => {
  const getImageDimensions = _.debounce(img => {
    fns.getImageDimensions(img);
  }, constants.IMAGE_DEBOUNCE_INTERVAL);

  const getImageSize = _.debounce(img => {
    fns.getImageSize(img);
  }, constants.IMAGE_DEBOUNCE_INTERVAL);

  image.absoluteUrl = exports.getAbsoluteUrlForImage(image.displayValue);
  image.filename = fns.getImageFileName(image.displayValue);
  getImageDimensions(image);
  getImageSize(image);
};

/**
 * Given an image, set the relevant properties on it.
 * @param image
 */
exports.setImagePropertiesReact = async image => {
  image.absoluteUrl = exports.getAbsoluteUrlForImage(image.displayValue);
  image.filename = fns.getImageFileName(image.displayValue);
  await fns.getImageSize(image);
};

/**
 * Resets all the editor state when unloading the editor component
 */
exports.resetEditorUiState = function() {
  const activeFrameId = flux.evaluate(getters.activeFrameId);
  EditorIframe.actions.unhighlightAllElements(activeFrameId);
  exports.unselectView();
  exports.unselectVariation();
  exports.unselectExperimentOrSectionId();
  exports.unsetCurrentlyEditingChange();
  exports.resetElementInfo();
  EditorIframe.actions.reset();
};

exports.setCurrentlyEditingInsertHTMLChangeValue = function(html) {
  flux.dispatch(actionTypes.P13N_INSERT_HTML_SET_HTML_VALUE, {
    html,
  });
};

exports.setInsertHTMLOperator = function(operator) {
  flux.dispatch(actionTypes.P13N_INSERT_HTML_SET_OPERATOR, {
    operator,
  });
};

/**
 * Set the provided redirect property and value for the currently editing change.
 * @param {string} key
 * @param {string} newValue
 */
exports.setChangeRedirectProperty = (key, newValue) => {
  flux.dispatch(actionTypes.P13N_CURRENT_CHANGE_SET_REDIRECT_PROPERTY, {
    data: {
      [key]: newValue,
    },
  });
};

/**
 * unselects the current variation that is selected
 */
exports.unselectExperimentOrSectionId = function() {
  flux.dispatch(actionTypes.P13N_EDITOR_UNSELECT_EXPERIMENT_ID);
};

/**
 * Selects variation for the experience that is currently being edited
 * @param {string} experimentOrSectionId
 */
exports.selectExperimentOrSectionId = function(experimentOrSectionId) {
  flux.dispatch(actionTypes.P13N_EDITOR_SELECT_EXPERIMENT_ID, {
    selectedExperimentOrSectionId: experimentOrSectionId,
  });
};

/**
 * Selects variation for the experience that is currently being edited
 * @param {Object} data
 * @returns {Object}
 */
exports.setupEditorState = function(data) {
  exports.unselectAction();
  exports.selectVariation(data.variationId);
  if (data.experimentOrSectionId) {
    exports.selectExperimentOrSectionId(data.experimentOrSectionId);
  }
  if (!data.view) {
    const currentLayerViews = flux.evaluateToJS(CurrentLayerGetters.views);
    data.view = currentLayerViews[0];
  }
  exports.selectView(data.view);
  return data;
};

/**
 * Ensure that the change_list_sidebar is notified if we switch to a new action
 * when its already mounted, as it needs to listen to a different iframe.
 */
exports.initializeChangeListSidebar = () => {
  const currentLayerExperimentOrSection = flux.evaluate(
    getters.currentLayerExperimentOrSection,
  );
  if (
    currentLayerExperimentOrSection &&
    currentLayerExperimentOrSection.get('status') ===
      LayerExperiment.enums.status.ARCHIVED
  ) {
    const activeFrame = flux.evaluate(getters.activeFrame);
    exports.setInteractiveModeOnIFrame(activeFrame);
  }
  // Checking to see if the change-list-sidebar is already mounted, if it is we need to
  // re-initialize the iframe bindings so that clicking on the iframe correctly interacts with the sidebar
  const mountedComponentId = flux.evaluate(
    regionGetters.mountedComponentId('p13n-editor-sidebar'),
  );
  if (mountedComponentId === 'p13n-change-list-sidebar') {
    const mountedComponent = flux.evaluate(
      regionGetters.mountedComponent('p13n-editor-sidebar'),
    );
    mountedComponent.$emit(constants.EmittedEventTypes.INITIALIZE_CHANGE_LIST);
  }
};

/**
 * Given a currentlyEditingChange, highlight the appropriate selector in the EditorIframe
 */
exports.highlightCurrentSelector = () => {
  const activeFrameId = flux.evaluate(getters.activeFrameId);
  const currentlyEditingChange = flux.evaluateToJS(
    getters.currentlyEditingChange,
  );
  const changeHighlighterOptions = fns.getHighlighterOptionsForElementChange(
    currentlyEditingChange,
  );
  EditorIframe.actions.unhighlightAllElements(activeFrameId);
  EditorIframe.actions.highlightElement({
    id: activeFrameId,
    dataOptlyId: changeHighlighterOptions.dataOptlyId,
    selector: changeHighlighterOptions.selector,
    type: HighlighterEnums.IFrameHighlightTypes.SELECTED,
  });
};

/**
 * If we are above the maximum number of editor iframe components (defined by
 * IFRAME_MAX in getters.js), destroy the oldest inactive component.
 */
exports.cleanupIframes = function() {
  const toDestroy = flux.evaluate(getters.oldestInactiveFrame);
  if (toDestroy) {
    toDestroy.get('component').$destroy();
  }
};

/**
 * Function called when a Selector Input value is chosen from a text update or search
 * @param {String} newSelector
 * @param {enums.selectorInputType} selectorInputType - String identifier for a specific Selector Input component type
 */
exports.onChangeSelectorInput = function(newSelector, selectorInputType) {
  let currentSelector;
  const activeFrameId = flux.evaluate(getters.activeFrameId);
  let currentlyEditingChange = flux.evaluate(getters.currentlyEditingChange);

  // Sets the selector for the currentlyEditing change, using a different action for each Selector Input type
  switch (selectorInputType) {
    case constants.SelectorInputTypes.ELEMENT_SELECTOR:
      currentSelector = currentlyEditingChange.get('selector') || '';
      // Set the selector for the currentlyEditing change
      exports.setChangeSelector(newSelector);
      // Update the selector used for the Track Clicks option in the Change Element plugin's base component
      TrackClicksChange.actions.updateTrackClickEventSelector(newSelector);
      break;
    case constants.SelectorInputTypes.REARRANGE_SELECTOR:
      currentSelector =
        currentlyEditingChange.getIn(['rearrange', 'insertSelector']) || '';
      // Set the selector for the currentlyEditingChange's insertSelector value
      exports.setChangeRearrangeProperty('insertSelector', newSelector);
      break;
    case constants.SelectorInputTypes.INSERT_HTML_SELECTOR:
    case constants.SelectorInputTypes.INSERT_IMAGE_SELECTOR:
      currentSelector = currentlyEditingChange.get('selector') || '';
      // Set the selector for the currentlyEditing change
      exports.setChangeSelector(newSelector);
      break;
    default:
      // SHOULD NEVER REACH THIS
      console.warn(
        'No current selector type has been set - unable to set new selector for change',
      ); // eslint-disable-line
      return;
  }

  // Only apply the change and set the element info to the iframe if the selector has changed
  if (newSelector === currentSelector.trim()) {
    return;
  }

  // Update these variables to use the updated currentlyEditingChange info
  currentlyEditingChange = flux.evaluate(getters.currentlyEditingChange);

  // Apply the working change to the editor iframe
  if (__DEV__) {
    console.debug(
      `[EDITOR_SIDEBAR] Applying change: ${currentlyEditingChange.get('id')}`,
    ); // eslint-disable-line
  }
  const changes = flux.evaluateToJS(
    getters.currentlyEditingActionFormatted(true, currentlyEditingChange),
  );

  EditorIframe.actions
    .applyChangesAndFetchElementInfo(
      activeFrameId,
      changes,
      currentlyEditingChange.get('selector', null), // This should be the change's main "selector", not necessarily the one just updated
      currentlyEditingChange.get('id', null),
    )
    .then(exports.setElementInfoForChange);
};

/**
 * Enable/disable the element selector
 * @param {Boolean} enabled - whether the SelectorInputType should be enabled or disabled
 * @param {String} instanceIdentifier - one of Editor.constants.SelectorInputTypes
 */
exports.toggleElementSelection = function(enabled, instanceIdentifier) {
  exports.setChangeEditorIsEditingSelector(enabled);
  exports.setChangeEditorCurrentSelectorType(
    enabled ? instanceIdentifier : null,
  );
};

/**
 * Based on the element clicked on, select or create a change,
 * but first confirm before if the currently editing change is dirty
 *
 * @param {Object} payload
 * @param {String} payload.selector
 * @param {Array} payload.selectorInfo
 * @param {Function} callbackFn
 */
exports.discardChangesAndSetChangeBasedOnSelector = function(
  payload,
  callbackFn = () => {},
) {
  const afterConfirmAction = () => {
    // NOTE: In the future, we may want to allow for a custom callback instead of just P13NUI.actions.showChangeEditorSidebar
    // If we do, we need to be sure to modify setChangeBasedOnSelector to always favor a new change and not an existing one
    exports
      .setChangeBasedOnSelector(payload.selector, payload.selectorInfo)
      .then(() => {
        UIActions.showChangeEditorSidebar();
        callbackFn();
      });
  };
  const currentlyEditingChangeIsDirty = flux.evaluate(
    getters.currentlyEditingChangeIsDirty,
  );
  if (currentlyEditingChangeIsDirty) {
    const activeFrameId = flux.evaluate(getters.activeFrameId);
    ui.confirm({
      title: tr('Are you sure you want to discard changes?'),
      message: tr(
        'Changing elements will discard your unsaved changes. Are you sure you want to continue?',
      ),
      confirmText: tr('Discard Changes and Select New Element'),
      isWarning: true,
    }).then(() => {
      const changes = flux.evaluateToJS(
        getters.currentlyEditingActionFormatted(true),
      );
      EditorIframe.actions.applyChanges(activeFrameId, changes);
      afterConfirmAction();
    });
  } else {
    afterConfirmAction();
  }
};

exports.setChangeListSidebarComponentConfig = function(payload) {
  flux.dispatch(
    actionTypes.P13N_EDITOR_SET_CHANGE_LIST_SIDEBAR_COMPONENT_CONFIG,
    payload,
  );
};

import EventEnums from 'optly/modules/entity/event/enums';

export const DEFAULT_MAX_VALIDATION_RATE = 5000;

export const ENTITY_HUMAN_READABLES = {
  CATALOG: 'catalogs',
  EVENT: {
    [EventEnums.eventTypes.CLICK]: 'Click',
    [EventEnums.eventTypes.CUSTOM]: 'Custom',
    VIEW: 'Page View',
  },
  RECOMMENDER: 'recommender',
};

export const TABLE_IDS = {
  CATALOG: 'CatalogDashboard',
  RECOMMENDER: 'RecommenderDashboard',
};

export const EDIT_WARNING_TEXTS = {
  CATALOG: {
    message: "You don't have permissions to edit this catalog.",
    testSection: 'catalog-dialog-edit-warning-attention',
  },
  EVENT: {
    message: "You don't have permissions to edit this event's tags.",
    testSection: 'edit-event-tags-dialog-edit-warning-attention',
  },
  RECOMMENDER: {
    message: "You don't have permissions to edit this recommender.",
    testSection: 'recommender-dialog-edit-warning-attention',
  },
};

export const MISSING_WARNING_TEXTS = {
  TAG: {
    hasDeletedTag:
      'This event has a tag that was deleted and is no longer being used.',
    hasNoTags:
      'Add at least one tag to this event by clicking on <i>Edit event tags</i>.',
    hasNoPrimaryIDTag: 'This event does not have the required Primary ID tag.',
    isDeletedTag: 'This tag was deleted and is no longer being used.',
    previouslyDeletedTag:
      'The previously selected tag was deleted and is no longer being used.',
    primaryIDTag:
      'Primary ID is required. Available tags are those from selected events above.',
  },
};

export const FilteringRulesLabels = {
  SELECT: {
    OP: 'Select operator...',
    VALUE: 'Select tag/value...',
  },
  CONJUNCTION: {
    ALL: 'all',
    ANY: 'any',
  },
  CONTEXT: {
    KEY: 'Key Item',
    RECOMMENDATION: 'Recommended Items',
  },
  CUSTOM: 'custom',
  OPERATOR: {
    CONTAINS: 'contains',
    EQUAL: '= (equals)',
    GREATER_THAN: '> (greater than)',
    GREATER_THAN_OR_EQUAL: '>= (greater than or equals)',
    LESS_THAN: '< (less than)',
    LESS_THAN_OR_EQUAL: '<= (less than or equals)',
    NOT_EQUAL: "!= (doesn't equal)",
    REGEX: 'regex',
  },
  POLICIES: {
    EXCLUDE: 'Exclude',
    INCLUDE: 'Include',
  },
};

export const FilteringRulesValues = {
  SELECT: {
    OP: 'select-operator',
    VALUE: 'select-value',
  },
  CONJUNCTION: {
    ALL: 'and',
    ANY: 'or',
  },
  CONTEXT: {
    KEY: 'target',
    RECOMMENDATION: 'reco',
  },
  CUSTOM: 'custom',
  OPERATOR: {
    CONTAINS: 'contains',
    EQUAL: '=',
    GREATER_THAN: '>',
    GREATER_THAN_OR_EQUAL: '>=',
    LESS_THAN: '<',
    LESS_THAN_OR_EQUAL: '<=',
    NOT_EQUAL: '!=',
    REGEX: 'regex',
  },
  POLICIES: {
    EXCLUDE: 'exclude',
    INCLUDE: 'include',
  },
};

export const DefaultFilteringRuleLabels = {
  CONJUNCTION: FilteringRulesLabels.CONJUNCTION.ALL,
  CONTEXT: FilteringRulesLabels.CONTEXT.KEY,
  CUSTOM: FilteringRulesLabels.CUSTOM,
  OP: FilteringRulesLabels.SELECT.OP,
  POLICY: FilteringRulesLabels.POLICIES.INCLUDE,
  VALUE: FilteringRulesLabels.SELECT.VALUE,
};

export const DefaultFilteringRuleValues = {
  CONJUNCTION: FilteringRulesValues.CONJUNCTION.ALL,
  CONTEXT: FilteringRulesValues.CONTEXT.KEY,
  CUSTOM: FilteringRulesValues.CUSTOM,
  OP: FilteringRulesValues.SELECT.OP,
  POLICY: FilteringRulesValues.POLICIES.INCLUDE,
  VALUE: FilteringRulesValues.SELECT.VALUE,
};

export const DefaultCondition = {
  op: DefaultFilteringRuleValues.OP,
  lhs: {
    context: DefaultFilteringRuleValues.CONTEXT,
    field: [DefaultFilteringRuleValues.VALUE],
  },
  rhs: {
    context: DefaultFilteringRuleValues.CONTEXT,
    field: [DefaultFilteringRuleValues.VALUE],
  },
};

export const DefaultRule = {
  policy: DefaultFilteringRuleValues.POLICY,
  condition: {
    conditions: [DefaultCondition],
    op: DefaultFilteringRuleValues.CONJUNCTION,
  },
};

export const ConjunctionOptions = [
  {
    label: FilteringRulesLabels.CONJUNCTION.ALL,
    value: FilteringRulesValues.CONJUNCTION.ALL,
  },
  {
    label: FilteringRulesLabels.CONJUNCTION.ANY,
    value: FilteringRulesValues.CONJUNCTION.ANY,
  },
];

export const ContextOptions = [
  {
    label: FilteringRulesLabels.CONTEXT.KEY,
    description:
      'Evaluate the item this recommender references to generate recommendations.',
    value: FilteringRulesValues.CONTEXT.KEY,
  },
  {
    label: FilteringRulesLabels.CONTEXT.RECOMMENDATION,
    description: 'Evaluate the recommendations generated from the key item.',
    value: FilteringRulesValues.CONTEXT.RECOMMENDATION,
  },
];

export const EntityInfo = {
  CATALOG: {
    entityType: 'catalog',
    namePlaceholder: 'Enter a unique name for this catalog.',
    descriptionPlaceholder: 'Describe the contents of this catalog.',
  },
  RECOMMENDER: {
    entityType: 'recommender',
    namePlaceholder: 'Enter a unique name for this recommender.',
    descriptionPlaceholder: 'Describe this recommender.',
  },
};

export const EventDistributionInfo = {
  HIGHEST: {
    label: 'Catalog Items with Highest Number of Events',
    description:
      'Catalog items with a disproportionate number of events OVER the average might indicate an issue with your configuration.',
    testSection: 'highest-event-distribution',
  },
  LOWEST: {
    label: 'Catalog Items with Lowest Number of Events',
    description:
      'Catalog items with a disproportionate number of events UNDER the average might indicate an issue with your configuration.',
    testSection: 'lowest-event-distribution',
  },
};

export const EventKind = {
  VIEW: 'View',
  EVENT: 'UserEvent',
};

export const OperatorOptions = [
  {
    label: FilteringRulesLabels.SELECT.OP,
    value: FilteringRulesValues.SELECT.OP,
  },
  {
    label: FilteringRulesLabels.OPERATOR.EQUAL,
    activatorLabel: FilteringRulesValues.OPERATOR.EQUAL,
    value: FilteringRulesValues.OPERATOR.EQUAL,
  },
  {
    label: FilteringRulesLabels.OPERATOR.NOT_EQUAL,
    activatorLabel: FilteringRulesValues.OPERATOR.NOT_EQUAL,
    value: FilteringRulesValues.OPERATOR.NOT_EQUAL,
  },
  {
    label: FilteringRulesLabels.OPERATOR.LESS_THAN,
    activatorLabel: FilteringRulesValues.OPERATOR.LESS_THAN,
    value: FilteringRulesValues.OPERATOR.LESS_THAN,
  },
  {
    label: FilteringRulesLabels.OPERATOR.GREATER_THAN,
    activatorLabel: FilteringRulesValues.OPERATOR.GREATER_THAN,
    value: FilteringRulesValues.OPERATOR.GREATER_THAN,
  },
  {
    label: FilteringRulesLabels.OPERATOR.LESS_THAN_OR_EQUAL,
    activatorLabel: FilteringRulesValues.OPERATOR.LESS_THAN_OR_EQUAL,
    value: FilteringRulesValues.OPERATOR.LESS_THAN_OR_EQUAL,
  },
  {
    label: FilteringRulesLabels.OPERATOR.GREATER_THAN_OR_EQUAL,
    activatorLabel: FilteringRulesValues.OPERATOR.GREATER_THAN_OR_EQUAL,
    value: FilteringRulesValues.OPERATOR.GREATER_THAN_OR_EQUAL,
  },
  {
    label: FilteringRulesLabels.OPERATOR.CONTAINS,
    value: FilteringRulesValues.OPERATOR.CONTAINS,
  },
  {
    label: FilteringRulesLabels.OPERATOR.REGEX,
    value: FilteringRulesValues.OPERATOR.REGEX,
  },
];

export const PolicyOptions = [
  {
    label: FilteringRulesLabels.POLICIES.INCLUDE,
    value: FilteringRulesValues.POLICIES.INCLUDE,
  },
  {
    label: FilteringRulesLabels.POLICIES.EXCLUDE,
    value: FilteringRulesValues.POLICIES.EXCLUDE,
  },
];

export const ValueOptions = [
  {
    label: FilteringRulesLabels.SELECT.VALUE,
    value: FilteringRulesValues.SELECT.VALUE,
  },
  {
    label: FilteringRulesLabels.CUSTOM,
    value: FilteringRulesValues.CUSTOM,
  },
];

export default {
  DEFAULT_MAX_VALIDATION_RATE,
  ENTITY_HUMAN_READABLES,
  EDIT_WARNING_TEXTS,
  MISSING_WARNING_TEXTS,
  TABLE_IDS,
  ConjunctionOptions,
  ContextOptions,
  DefaultCondition,
  DefaultRule,
  DefaultFilteringRuleLabels,
  DefaultFilteringRuleValues,
  EntityInfo,
  EventDistributionInfo,
  EventKind,
  FilteringRulesLabels,
  FilteringRulesValues,
  OperatorOptions,
  PolicyOptions,
  ValueOptions,
};

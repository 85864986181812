import config from 'atomic-config';

import jsCookie from 'js-cookie';

import Immutable from 'optly/immutable';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import DesktopAppFns from 'optly/modules/desktop_app/fns';
import UIVersionGetters from 'optly/modules/ui/version/getters';

const useLocalBundleCookie = jsCookie.get('use_local_bundle');

let sentryGetters;

export function defaultAttributes(customAttributes = {}) {
  return [
    CurrentProjectGetters.project,
    UIVersionGetters.activeVersion,
    function(currentProject, activeVersion) {
      const project = currentProject || Immutable.Map();

      const attributes = {
        use_local_bundle: useLocalBundleCookie,
        host: config.get('env.HOST'),
        project_id: project.get('id'),
        is_impersonating: config.get('is_impersonating'),
        app_version: activeVersion,

        /* Desktop Application info */
        is_desktop_app: DesktopAppFns.isDesktopApp(),
        desktop_app_version: DesktopAppFns.getAppVersion(),
        desktop_app_platform: DesktopAppFns.getAppPlatform(),
      };

      return attributes;
    },
  ];
}

export default sentryGetters = {
  defaultAttributes,
};

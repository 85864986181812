import React from 'react';
import router from 'core/router';
import PropTypes from 'prop-types';
import { Input, Attention, Button, Link, DialogNew } from 'optimizely-oui';

import ui from 'core/ui';
import LoadingOverlay from 'react_components/loading_overlay';
import { enums as UIVersionEnums } from 'optly/modules/ui/version';
import { actions as AccountActions } from 'optly/modules/entity/account';

export default class VerifyEmailDialog extends React.Component {
  static propTypes = {
    hideVerifyEmailDialog: PropTypes.func.isRequired,
    newEmail: PropTypes.string.isRequired,
    verificationToken: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { newEmail } = this.props;
    this.state = {
      newEmail,
      error: null,
      password: null,
      isSaving: false,
      emailVerified: false,
      notYetInputSettings: true,
    };
  }

  isValidPassword() {
    const { notYetInputSettings, password } = this.state;
    return notYetInputSettings || !!password;
  }

  errorList() {
    let errors = [];
    const { error } = this.state;
    if (error) {
      errors = [error];
    }
    return errors;
  }

  showErrorList() {
    return this.errorList().map(error => <p>{error}</p>);
  }

  reloadWindow = () => {
    router.windowNavigate(UIVersionEnums.projectRoot.OPTIMIZELY_X);
  };

  onSubmitFail = err => {
    this.setState({
      error:
        err && Object.prototype.hasOwnProperty.call(err, 'responseText')
          ? JSON.parse(err.responseText).error
          : tr('There was a problem in verifying your new email.'),
      isSaving: false,
    });
    ui.loadingStop('verifyEmailLoader');
  };

  onSubmitSuccess = () => {
    this.setState({
      error: null,
      emailVerified: true,
      isSaving: false,
    });
    ui.loadingStop('verifyEmailLoader');
  };

  verifyEmail = () => {
    const { verificationToken } = this.props;
    const { newEmail, password } = this.state;
    this.setState({ isSaving: false });
    this.setState({ notYetInputSettings: false }, function() {
      if (!this.isValidPassword()) {
        return;
      }
      ui.loadingStart('verifyEmailLoader');
      const response = AccountActions.verifyChangedEmail(
        newEmail,
        password,
        verificationToken,
      );

      // Verify succeeded
      response.done(this.onSubmitSuccess);

      // Verify failed
      response.fail(this.onSubmitFail);
    });
  };

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  };

  renderButtonsInFooter() {
    const { emailVerified, isSaving } = this.state;
    const { hideVerifyEmailDialog } = this.props;
    return emailVerified
      ? [
          <Button style="highlight" key="ok" onClick={this.reloadWindow}>
            OK
          </Button>,
        ]
      : [
          <Button
            style="plain"
            key="cancel"
            isDisabled={isSaving}
            onClick={hideVerifyEmailDialog}>
            Cancel
          </Button>,
          <Button
            style="highlight"
            key="verify"
            isDisabled={isSaving}
            onClick={this.verifyEmail}>
            Verify email address
          </Button>,
        ];
  }

  render() {
    const { newEmail, password, emailVerified } = this.state;
    const { hideVerifyEmailDialog } = this.props;
    return (
      <DialogNew
        onClose={hideVerifyEmailDialog}
        title="Verify Email Address"
        footerButtonList={this.renderButtonsInFooter()}>
        <LoadingOverlay loadingId="verifyEmailLoader">
          <Input
            label="New Email Address"
            type="email"
            isRequired={true}
            isDisabled={true}
            value={newEmail}
          />
          <div className="push--top" />
          <Input
            label="Current Password"
            type="password"
            isRequired={true}
            value={password}
            onChange={event => this.handlePasswordChange(event)}
            isDisabled={emailVerified}
            testSection="new-email-current-password"
          />
          {!this.isValidPassword() && (
            <div className="oui-form-note oui-form-note--bad-news">
              This field is required.
            </div>
          )}
          {this.errorList().length > 0 && (
            <div className="push--top">
              <Attention type="bad-news">{this.showErrorList()}</Attention>
            </div>
          )}
          <p className="push-double--top">
            <Link href="/privacy" newWindow={true}>
              Optimizely Privacy Policy
            </Link>
          </p>
          {emailVerified && (
            <p className="float--left lego-block-list">
              Email address successfully changed. Please log in again.
            </p>
          )}
        </LoadingOverlay>
      </DialogNew>
    );
  }
}

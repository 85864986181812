/**
 * Module specific pure functions
 */
const _ = require('lodash');
const PluginModuleEnums = require('optly/modules/entity/plugin/enums');
const tr = require('optly/translate');
const { toImmutable } = require('optly/immutable');

const fieldTypesConfig = require('./config');

const defaultJsonEditableFields = {
  plugin_type: pluginType =>
    _.includes(_.values(PluginModuleEnums.plugin_type), pluginType),
  name: _.isString,
  edit_page_url: _.isString,
  form_schema: _.isArray,
  description: _.isString,
  options: _.isObject,
};

const JSON_EDITABLE_FIELDS = {
  [PluginModuleEnums.plugin_type.WIDGET]: defaultJsonEditableFields,
  [PluginModuleEnums.plugin_type.EDITOR_PANEL]: defaultJsonEditableFields,
  [PluginModuleEnums.plugin_type.EVENT]: defaultJsonEditableFields,
  [PluginModuleEnums.plugin_type.ANALYTICS_INTEGRATION]: _.pickBy(
    defaultJsonEditableFields,
    (fn, field) => field !== 'edit_page_url',
  ),
};

/**
 * @typedef {Object} Validation
 * @property {Boolean} isValid
 * @property {String?} message
 */

/**
 * @typedef {Function} ValidatorFn
 * @param {Object} newConfig
 * @return {Validation}
 */

const validateFieldType = function(fieldType) {
  if (_.includes(_.values(PluginModuleEnums.fieldType), fieldType)) {
    return {
      isValid: true,
    };
  }
  return {
    isValid: false,
    message: tr('Invalid field_type: {0}', fieldType),
  };
};

const validateMultiSelectDefault = function(def) {
  if (!_.isArray(def)) {
    return {
      isValid: false,
      message: tr('Default value for multi-select must be an array'),
    };
  }
  return {
    isValid: true,
  };
};

const validateDefault = function(def, config) {
  if (config.field_type === PluginModuleEnums.fieldType.MULTI_SELECT) {
    return validateMultiSelectDefault(def);
  }
  if (_.isUndefined(def)) {
    return {
      isValid: false,
      message: tr(
        'default_value must be set for schema field named: {0}',
        config.name,
      ),
    };
  }
  return {
    isValid: true,
  };
};

const validateOptions = function(options) {
  if (_.isObject(options) || _.isNull(options)) {
    return {
      isValid: true,
    };
  }
  return {
    isValid: false,
    message: tr('Invalid options: {0}', options),
  };
};

exports.validateLabel = function(label) {
  if (_.isString(label) && label.length > 0) {
    return {
      isValid: true,
    };
  }
  return {
    isValid: false,
    message: tr('Invalid label: {0}', label),
  };
};

exports.validateFieldName = function(name) {
  const validation = {
    isValid: true,
    message: '',
  };

  if (!name || !_.isString(name)) {
    validation.isValid = false;
    validation.message = tr('API Name is required.');
  }

  if (!/^\w+$/.test(name)) {
    validation.isValid = false;
    validation.message = tr(
      'API Name may only contain characters, numbers and underscores.',
    );
  }

  return validation;
};

/**
 * Returns a validator function for a given schema and index. If editingIndex is
 * a number, the validator checks if a field config can replace the field at
 * that index. Otherwise, the validator checks if a new field config can be
 * added to the schema.
 * @param {Array} schema
 * @param {Number|undefined} editingIndex
 * @return {ValidatorFn}
 */
exports.getFieldNameValidator = function(schema, editingIndex) {
  return function(newConfig) {
    const validation = exports.validateFieldName(newConfig.name);
    if (!validation.isValid) {
      return validation;
    }

    const isInvalid = _.some(
      schema,
      (existingConfig, index) =>
        existingConfig.name === newConfig.name && index !== editingIndex,
    );
    if (isInvalid) {
      return {
        isValid: false,
        message: tr('A field named {0} already exists', newConfig.name),
      };
    }
    return {
      isValid: true,
    };
  };
};

const SCHEMA_FIELD_CHECKERS = {
  field_type: validateFieldType,
  name: exports.validateFieldName,
  label: exports.validateLabel,
  default_value: validateDefault,
  options: validateOptions,
};

const validateFormField = function(fieldConfig) {
  if (!_.isObject(fieldConfig)) {
    return {
      isValid: false,
      message: tr('The form_schema array must contain only objects'),
    };
  }

  let fieldPropertyValidation;
  _.some(SCHEMA_FIELD_CHECKERS, (validate, propName) => {
    fieldPropertyValidation = validate(fieldConfig[propName], fieldConfig);
    return !fieldPropertyValidation.isValid;
  });
  if (fieldPropertyValidation && !fieldPropertyValidation.isValid) {
    return fieldPropertyValidation;
  }

  if (_.size(fieldConfig) !== _.size(SCHEMA_FIELD_CHECKERS)) {
    return {
      isValid: false,
      message: tr(
        'form_schema objects should have only these properties: {0}',
        _.keys(SCHEMA_FIELD_CHECKERS).join(', '),
      ),
    };
  }

  // Validate the default options based on the type specific validateDefaultOptions function
  const typeSpecificOptionsValidator =
    fieldTypesConfig[fieldConfig.field_type].validateDefaultOptions;
  let typeSpecificOptionsValidation;
  if (typeSpecificOptionsValidator) {
    typeSpecificOptionsValidation = typeSpecificOptionsValidator(fieldConfig);
  }
  if (typeSpecificOptionsValidation && !typeSpecificOptionsValidation.isValid) {
    return typeSpecificOptionsValidation;
  }

  // Validate the default value based on the type specific validateDefaultValue function
  const typeSpecificValueValidator =
    fieldTypesConfig[fieldConfig.field_type].validateDefaultValue;
  let typeSpecificValueValidation;
  if (typeSpecificValueValidator) {
    typeSpecificValueValidation = typeSpecificValueValidator(fieldConfig);
  }
  if (typeSpecificValueValidation && !typeSpecificValueValidation.isValid) {
    return typeSpecificValueValidation;
  }

  return {
    isValid: true,
  };
};

/**
 * Given a plugin object, returns a subset of the same object, containing only
 * the properties that should be editable in the 'Edit as JSON' dialog
 * @param {Object} plugin
 * @returns {Object}
 */
exports.getEditableAsJSONSubset = function(plugin) {
  return _.pick(plugin, _.keys(JSON_EDITABLE_FIELDS[plugin.plugin_type]));
};

/**
 * Given an object that came from parsing user input in the 'Edit as JSON'
 * dialog, verify that it can be used to update the plugin and return an object
 * representing its validity
 */
exports.validateEditedPlugin = function(plugin) {
  const invalidField = _.findKey(
    JSON_EDITABLE_FIELDS[plugin.plugin_type],
    (typeChecker, fieldName) =>
      !typeChecker.call(_, plugin[fieldName], plugin.plugin_type),
  );
  if (invalidField) {
    return {
      isValid: false,
      message: tr(
        'Property "{0}" is missing or has an invalid value',
        invalidField,
      ),
    };
  }
  // We know it has at least the required properties. If its size is different,
  // it must have extra properties
  if (_.size(plugin) !== _.size(JSON_EDITABLE_FIELDS[plugin.plugin_type])) {
    return {
      isValid: false,
      message: tr(
        'Plugin config should have only these properties: {0}',
        _.keys(JSON_EDITABLE_FIELDS[plugin.plugin_type]).join(', '),
      ),
    };
  }

  const optionProperties =
    PluginModuleEnums.propertyOptions[plugin.plugin_type];
  if (optionProperties) {
    const invalidOption = _.find(
      optionProperties,
      option => !_.isString(plugin.options[option]),
    );
    if (invalidOption) {
      return {
        isValid: false,
        message: tr(
          'Option "{0}" is missing or has an invalid value',
          invalidOption,
        ),
      };
    }
    if (_.size(plugin.options) !== optionProperties.length) {
      return {
        isValid: false,
        message: tr(
          'Options object should have only these properties: {0}',
          optionProperties.join(', '),
        ),
      };
    }
  }

  let fieldValidation;
  _.some(plugin.form_schema, fieldConfig => {
    fieldValidation = validateFormField(fieldConfig);
    return !fieldValidation.isValid;
  });
  if (fieldValidation && !fieldValidation.isValid) {
    return fieldValidation;
  }
  // Check for duplicated form field names
  _.forEach(plugin.form_schema, (fieldConfig, index) => {
    fieldValidation = exports.getFieldNameValidator(
      plugin.form_schema,
      index,
    )(fieldConfig);
    return fieldValidation.isValid;
  });
  if (fieldValidation && !fieldValidation.isValid) {
    return fieldValidation;
  }

  return {
    isValid: true,
  };
};

/**
 * Reduce form schema defaults to an Immutable map
 * of names and corresponding default values
 * @param {Immutable} formSchema
 * @returns {Immutable}
 */
exports.mapFormSchemaDefaults = function(formSchema) {
  return formSchema.reduce(
    (memo, field) => memo.set(field.get('name'), field.get('default_value')),
    toImmutable({}),
  );
};

/**
 * Reduce form schema options to an Immutable map
 * of names and corresponding options objects
 * @param {Immutable} formSchema
 * @returns {Immutable}
 */
exports.mapFormSchemaOptions = function(formSchema) {
  return formSchema.reduce(
    (memo, field) => memo.set(field.get('name'), field.get('options')),
    toImmutable({}),
  );
};

/**
 * Standardized method to convert a value to a JSON string
 * @param {Object} value
 * @returns {JSON}
 */
exports.JsonStringify = function(value) {
  return JSON.stringify(value, null, 2);
};

import { Input, Button, Table } from 'optimizely-oui';

import PropTypes from 'prop-types';
import React from 'react';

import Immutable from 'optly/immutable';

import RowControls from './components/row_controls';
import VariationDropdown from './components/variation_dropdown';

class ForcedVariationsTable extends React.Component {
  static propTypes = {
    addForcedVariationRow: PropTypes.func.isRequired,
    currentForcedVariations: PropTypes.instanceOf(Immutable.List).isRequired,
    handleUserIdChange: PropTypes.func.isRequired,
    handleVariationIdChange: PropTypes.func.isRequired,
    isOnFullstackManager: PropTypes.bool,
    removeForcedVariationRow: PropTypes.func.isRequired,
    savedForcedVariations: PropTypes.instanceOf(Immutable.List).isRequired,
    variations: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  maxForcedVariations = 50;

  render() {
    const {
      addForcedVariationRow,
      currentForcedVariations,
      handleUserIdChange,
      handleVariationIdChange,
      isOnFullstackManager,
      removeForcedVariationRow,
      savedForcedVariations,
      variations,
    } = this.props;

    const tableRows = currentForcedVariations.map((forcedVariation, index) => {
      const userId = forcedVariation.get('user_id') || '';

      return (
        <Table.TR key={`forced-variation-row-${index}`}>
          <Table.TD className="soft-double--ends">
            <Input
              onChange={event => {
                handleUserIdChange(index, event.target.value);
              }}
              placeholder="User ID"
              testSection="oasis-forced-variation-user-id-input"
              type="text"
              value={userId}
            />
          </Table.TD>
          <Table.TD className="soft-double--ends">
            <VariationDropdown
              forcedVariation={forcedVariation}
              index={index}
              onVariationIdChange={handleVariationIdChange}
              variations={variations}
            />
          </Table.TD>
          <Table.TD className="soft-double--ends">
            {!(
              savedForcedVariations.size === 0 &&
              currentForcedVariations.size === 1
            ) && !isOnFullstackManager ? (
              <RowControls
                index={index}
                onRemoveRow={removeForcedVariationRow}
              />
            ) : (
              ''
            )}
            {isOnFullstackManager && (
              <RowControls
                index={index}
                onRemoveRow={removeForcedVariationRow}
              />
            )}
          </Table.TD>
        </Table.TR>
      );
    });

    return (
      <div>
        {!!tableRows.size && (
          <Table tableLayoutAlgorithm="auto">
            <Table.THead>
              <Table.TR>
                <Table.TH>
                  User ID
                  <span className="oui-label--required" />
                </Table.TH>
                <Table.TH>Variation</Table.TH>
                <Table.TH />
              </Table.TR>
            </Table.THead>
            <Table.TBody>{tableRows}</Table.TBody>
          </Table>
        )}
        <div className="push-double--top">
          {currentForcedVariations.size < this.maxForcedVariations ? (
            <Button
              onClick={addForcedVariationRow}
              testSection="oasis-forced-variation-add-row-button"
              width="full">
              Add User ID...
            </Button>
          ) : (
            <p
              className="push-half--top"
              data-test-section="oasis-forced-variation-limit-text">
              Optimizely allows you to&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://help.optimizely.com/QA_Campaigns_and_Experiments/QA%3A_Whitelist_users_in_an_SDK_experiment">
                whitelist up to {this.maxForcedVariations} users
              </a>{' '}
              per experiment.
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default ForcedVariationsTable;

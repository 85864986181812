import keyMirror from 'optly/utils/key_mirror';
import LiveVariableEnums from 'optly/modules/entity/live_variable/enums';
import ProjectEnums from 'optly/modules/entity/project/enums';

export const TABLE_ID = 'FeaturesDashboard';

export const NAV_TABS = keyMirror({
  FEATURESOLD: null,
});

export const VARIABLE_VALUE_DEFAULTS = {
  [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]: 'true',
  [LiveVariableEnums.LIVE_VARIABLE_TYPES.float]: 0.0,
  [LiveVariableEnums.LIVE_VARIABLE_TYPES.integer]: 0,
  [LiveVariableEnums.LIVE_VARIABLE_TYPES.string]: '',
};

export const VARIABLE_CODE_BLOCKS_BY_LANG = {
  [ProjectEnums.sdkLanguages.PYTHON]: {
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      "%s = optimizely.get_feature_variable_boolean('%s', '%s', user_id)",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.double]:
      "%s = optimizely.get_feature_variable_double('%s', '%s', user_id)",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.integer]:
      "%s = optimizely.get_feature_variable_integer('%s', '%s', user_id)",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.string]:
      "%s = optimizely.get_feature_variable_string('%s', '%s', user_id)",
  },
  [ProjectEnums.sdkLanguages.RUBY]: {
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      "%s = optimizely.get_feature_variable_boolean('%s', '%s', user_id)",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.double]:
      "%s = optimizely.get_feature_variable_double('%s', '%s', user_id)",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.integer]:
      "%s = optimizely.get_feature_variable_integer('%s', '%s', user_id)",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.string]:
      "%s = optimizely.get_feature_variable_string('%s', '%s', user_id)",
  },
  [ProjectEnums.sdkLanguages.JAVASCRIPT]: {
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      "var %s = optimizely.getFeatureVariableBoolean('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.double]:
      "var %s = optimizely.getFeatureVariableDouble('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.integer]:
      "var %s = optimizely.getFeatureVariableInteger('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.string]:
      "var %s = optimizely.getFeatureVariableString('%s', '%s', userId);",
  },
  [ProjectEnums.sdkLanguages.JAVA]: {
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      "Boolean %s = optimizely.getFeatureVariableBoolean('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.double]:
      "Double %s = optimizely.getFeatureVariableDouble('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.integer]:
      "Integer %s = optimizely.getFeatureVariableInteger('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.string]:
      "String %s = optimizely.getFeatureVariableString('%s', '%s', userId);",
  },
  [ProjectEnums.sdkLanguages.PHP]: {
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      "$%s = optimizely.getFeatureVariableBoolean('%s', '%s', $userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.double]:
      "$%s = optimizely.getFeatureVariableDouble('%s', '%s', $userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.integer]:
      "$%s = optimizely.getFeatureVariableInteger('%s', '%s', $userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.string]:
      "$%s = optimizely.getFeatureVariableString('%s', '%s', $userId);",
  },
  [ProjectEnums.sdkLanguages.CSHARP]: {
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      "Boolean %s = optimizely.getFeatureVariableBoolean('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.double]:
      "Double %s = optimizely.getFeatureVariableDouble('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.integer]:
      "Integer %s = optimizely.getFeatureVariableInteger('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.string]:
      "String %s = optimizely.getFeatureVariableString('%s', '%s', userId);",
  },
  [ProjectEnums.sdkLanguages.IOS]: {
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      'bool %s = [optimizely getFeatureVariableBoolean:@"%s" userId: userId];',
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.double]:
      'double %s = [optimizely getFeatureVariableDouble:@"%s" userId: userId];',
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      'int %s = [optimizely getFeatureVariableInteger:@"%s" userId: userId];',
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      'NSString *%s = [optimizely getFeatureVariableString:@"%s" userId: userId];',
  },
  [ProjectEnums.sdkLanguages.TV_OS]: {
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      'BOOL %s = [optimizely getFeatureVariableBoolean:@"%s" userId: userId];',
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.double]:
      'double %s = [optimizely getFeatureVariableDouble:@"%s" userId: userId];',
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      'NSInteger %s = [optimizely getFeatureVariableInteger:@"%s" userId: userId];',
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      'NSString *%s = [optimizely getFeatureVariableString:@"%s" userId: userId];',
  },
  [ProjectEnums.sdkLanguages.ANDROID]: {
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      "Boolean %s = optimizely.getFeatureVariableBoolean('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.double]:
      "Double %s = optimizely.getFeatureVariableDouble('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.integer]:
      "Integer %s = optimizely.getFeatureVariableInteger('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.string]:
      "String %s = optimizely.getFeatureVariableString('%s', '%s', userId);",
  },
  [ProjectEnums.sdkLanguages.ANDROID_TV]: {
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      "Boolean %s = optimizely.getFeatureVariableBoolean('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.double]:
      "Double %s = optimizely.getFeatureVariableDouble('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.integer]:
      "Integer %s = optimizely.getFeatureVariableInteger('%s', '%s', userId);",
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.string]:
      "String %s = optimizely.getFeatureVariableString('%s', '%s', userId);",
  },
};

export const VARIABLE_CODE_BLOCKS = {
  [ProjectEnums.sdks.PYTHON]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.PYTHON],
  [ProjectEnums.sdks.RUBY]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.RUBY],
  [ProjectEnums.sdks.NODE]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.JAVASCRIPT],
  [ProjectEnums.sdks.JAVASCRIPT]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.JAVASCRIPT],
  [ProjectEnums.sdks.JAVA]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.JAVA],
  [ProjectEnums.sdks.PHP]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.PHP],
  [ProjectEnums.sdks.CSHARP]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.CSHARP],
  [ProjectEnums.sdks.IOS]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.IOS],
  [ProjectEnums.sdks.TV_OS]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.TV_OS],
  [ProjectEnums.sdks.ANDROID]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.ANDROID],
  [ProjectEnums.sdks.ANDROID_TV]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.ANDROID_TV],
};

export const FEATURE_CODE_BLOCKS = {
  [ProjectEnums.sdks.PYTHON]:
    "enabled = optimizely.is_feature_enabled('%s', user_id)",
  [ProjectEnums.sdks.RUBY]:
    "enabled = optimizely.is_feature_enabled('%s', user_id)",
  [ProjectEnums.sdks.JAVASCRIPT]:
    "var enabled = optimizely.isFeatureEnabled('%s', userId);",
  [ProjectEnums.sdks.NODE]:
    "var enabled = optimizely.isFeatureEnabled('%s', userId);",
  [ProjectEnums.sdks.JAVA]:
    "Boolean enabled = optimizely.isFeatureEnabled('%s', userId);",
  [ProjectEnums.sdks.PHP]:
    "$enabled = optimizely.isFeatureEnabled('%s', $userId);",
  [ProjectEnums.sdks.CSHARP]:
    "Boolean enabled = optimizely.isFeatureEnabled('%s', userId);",
  [ProjectEnums.sdks.IOS]:
    'BOOL enabled = [optimizely isFeatureEnabled:@"%s" userId: userId];',
  [ProjectEnums.sdks.TV_OS]:
    'BOOL enabled = [optimizely isFeatureEnabled:@"%s" userId: userId];',
  [ProjectEnums.sdks.ANDROID]:
    "Boolean enabled = optimizely.isFeatureEnabled('%s', userId);",
  [ProjectEnums.sdks.ANDROID_TV]:
    "Boolean enabled = optimizely.isFeatureEnabled('%s', userId);",
};

export default {
  TABLE_ID,
  NAV_TABS,
  VARIABLE_VALUE_DEFAULTS,
  VARIABLE_CODE_BLOCKS_BY_LANG,
  VARIABLE_CODE_BLOCKS,
  FEATURE_CODE_BLOCKS,
};

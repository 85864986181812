import ElementPresentCard from './element_present';
import JavascriptConditionCard from './javascript_condition';
import URLMatchCard from './url_match';

const conditionTypeToCardMap = {
  [ElementPresentCard.matchType]: ElementPresentCard,
  [JavascriptConditionCard.matchType]: JavascriptConditionCard,
  [URLMatchCard.matchType]: URLMatchCard,
};

/**
 * Helper function to return the condition card requested based on its type.
 * @param type - The ConditionGroup.type property
 * @returns {*} A condition card.
 */
export default function getCardByConditionType(type) {
  return conditionTypeToCardMap[type];
}

import React from 'react';
import PropTypes from 'prop-types';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import Immutable from 'optly/immutable';

import { formPropType } from 'react_components/form';

import ExperimentDetails from 'bundles/p13n/sections/manager_fullstack/components/experiment_details_form_input/components/experiment_details';

import SectionModuleConstants from 'bundles/p13n/sections/manager_fullstack/section_module/constants';
import SectionModuleFns from 'bundles/p13n/sections/manager_fullstack/section_module/fns';

class ExperimentDetailsFormInput extends React.Component {
  static propTypes = {
    currentProjectExperiments: PropTypes.instanceOf(Immutable.List).isRequired,
    experimentType: PropTypes.string,
    form: formPropType.isRequired,
    handleOnSubmit: PropTypes.func,
  };

  static defaultProps = {
    experimentType: SectionModuleConstants.ExperimentType.AB_TEST,
  };

  constructor(props) {
    super(props);

    const { currentProjectExperiments, form } = props;

    props.form.field('key').validators(
      {
        hasEmptyKeyError: () => !form.field('key').getValue(),
        hasInvalidKeyError: value =>
          !SectionModuleFns.ensureExperimentKeyValid(value),
        hasUniqueKeyError: () =>
          !SectionModuleFns.ensureExperimentKeyUnique(
            form.getValue(),
            currentProjectExperiments,
          ),
      },
      {
        validateOnChange: isFeatureEnabled('user_friendly_names'),
      },
    );

    if (isFeatureEnabled('user_friendly_names')) {
      props.form.field('name').validators({
        hasEmptyNameError: () => !form.field('name').getValue(),
      });
    }
  }

  render() {
    const { experimentType, form, handleOnSubmit } = this.props;

    const keyErrors = form.field('key').getErrors();
    let hasEmptyNameError = false;
    if (isFeatureEnabled('user_friendly_names')) {
      const nameErrors = form.field('name').getErrors();
      hasEmptyNameError = nameErrors.details.hasEmptyNameError;
    }

    return (
      <ExperimentDetails
        experiment={form.getValue()}
        experimentType={experimentType}
        handleOnSubmit={handleOnSubmit}
        showEmptyKeyError={keyErrors.details.hasEmptyKeyError}
        showEmptyNameError={hasEmptyNameError}
        showInvalidKeyError={keyErrors.details.hasInvalidKeyError}
        showUniqueKeyError={keyErrors.details.hasUniqueKeyError}
        updateExperimentProperty={(property, value) =>
          form.field(property).setValue(value)
        }
      />
    );
  }
}

export default ExperimentDetailsFormInput;

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';
import { toImmutable } from 'optly/immutable';

import entityDef from './entity_definition';

const baseEntityGetters = createEntityGetters(entityDef);
export const { byId, entityCache } = baseEntityGetters;

export const statusUpdatesByRecommenderId = recommenderId => [
  entityCache,
  jobStatuses => {
    const jobStatusById = jobStatuses.find(
      jobStatus => jobStatus.get('source_id') === recommenderId,
    );
    return jobStatusById && jobStatusById.getIn(['status_updates', 0]);
  },
];

export const summaryStatsByRecommenderId = recommenderId => [
  statusUpdatesByRecommenderId(recommenderId),
  updatesById =>
    updatesById
      ? updatesById.getIn(['message_args', 'summary'])
      : toImmutable({}),
];

export const updatedTimeByRecommenderId = recommenderId => [
  entityCache,
  jobStatuses => {
    const jobStatusById = jobStatuses.find(
      jobStatus => jobStatus.get('source_id') === recommenderId,
    );
    return jobStatusById && jobStatusById.get('last_modified');
  },
];

export default {
  ...baseEntityGetters,
  statusUpdatesByRecommenderId,
  summaryStatsByRecommenderId,
  updatedTimeByRecommenderId,
};

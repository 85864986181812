import flux from 'core/flux';

import AdminAccountStores from 'optly/modules/admin_account/stores';
import AuthStores from 'optly/modules/auth/stores';
import CurrentProjectStores from 'optly/modules/current_project/stores';
import DashboardStores from 'optly/modules/dashboard/stores';
import FilterableTableStores from 'optly/modules/filterable_table/stores';
import FluxLinkStores from 'optly/modules/flux_link/stores';
import JiraIntegrationStores from 'optly/modules/jira_integration/stores';
import LocalStorageMetaStores from 'optly/modules/local_storage_meta/stores';
import OptimizelyChampagneStores from 'optly/modules/optimizely_champagne/stores';
import PageableStores from 'optly/modules/pageable/stores';
import PerformanceTrackingStores from 'optly/modules/performance_tracking/stores';
import PricingInfoStores from 'optly/modules/pricing_info/stores';
import ProfileDetailsStores from 'optly/modules/profile_details/stores';
import RestApiStores from 'optly/modules/rest_api/stores';
import SortableTableStores from 'optly/modules/sortable_table/stores';
import SupportStores from 'optly/modules/support/stores';
import TimezonesStores from 'optly/modules/timezones/stores';
import UiVersionStores from 'optly/modules/ui/version/stores';
import VerifierStores from 'optly/modules/verifier/stores';
import ExperimentEntityStores from 'optly/modules/entity/experiment/stores';
import PublicApiConsumerStores from 'optly/modules/public_api_consumer/stores';
import CacheHitTrackingStores from 'optly/modules/cache_hit_tracking/stores';
import NotificationPreferencesStore from 'optly/modules/notification_preferences/stores';

export const stores = {
  ...AdminAccountStores,
  ...AuthStores,
  ...CurrentProjectStores,
  ...DashboardStores,
  ...FilterableTableStores,
  ...FluxLinkStores,
  ...JiraIntegrationStores,
  ...LocalStorageMetaStores,
  ...OptimizelyChampagneStores,
  ...PageableStores,
  ...PerformanceTrackingStores,
  ...PricingInfoStores,
  ...ProfileDetailsStores,
  ...RestApiStores,
  ...SortableTableStores,
  ...SupportStores,
  ...TimezonesStores,
  ...UiVersionStores,
  ...VerifierStores,
  ...ExperimentEntityStores,
  ...PublicApiConsumerStores,
  ...CacheHitTrackingStores,
  ...NotificationPreferencesStore,
};

export default function initialize() {
  flux.registerStores(stores);
}

/**
 * @author Derek Hammond (derek@optimizely.com)
 */
import keyMirror from 'optly/utils/key_mirror';

export const actionType = keyMirror({
  SUPPORT_SET_PORTAL_VERSION: null,
  SUPPORT_SET_CALL_SCHEDULER_VISIBILITY: null,
});

export default {
  ...actionType,
};

import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

const INITIAL_STATE = {
  orderedAudienceIds: [],
};

const addAudienceId = (state, payload) =>
  state.set(
    'orderedAudienceIds',
    state.get('orderedAudienceIds').push(payload.audienceId),
  );

const removeAudienceId = (state, payload) =>
  state.set(
    'orderedAudienceIds',
    state
      .get('orderedAudienceIds')
      .filter(audienceId => audienceId !== payload.audienceId),
  );

const setOrderedAudienceIds = (state, payload) =>
  state.set('orderedAudienceIds', payload.orderedAudienceIds);

export default Store({
  getInitialState() {
    return toImmutable(INITIAL_STATE);
  },

  initialize() {
    this.on(actionTypes.EXPERIENCE_MANAGER_ADD_AUDIENCE_ID, addAudienceId);
    this.on(
      actionTypes.EXPERIENCE_MANAGER_REMOVE_AUDIENCE_ID,
      removeAudienceId,
    );
    this.on(
      actionTypes.EXPERIENCE_MANAGER_SET_ORDERED_AUDIENCE_IDS,
      setOrderedAudienceIds,
    );
  },
});

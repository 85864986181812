const PluginModuleEnums = require('optly/modules/entity/plugin/enums');

/**
 * Exports a mapping of fieldType => component
 * @return {Object}
 */
exports.getFieldTypeToComponents = function() {
  const fieldTypesToComponents = {};

  fieldTypesToComponents[
    PluginModuleEnums.fieldType.SELECTOR
  ] = require('./selector');

  fieldTypesToComponents[PluginModuleEnums.fieldType.HTML] = require('./html');

  fieldTypesToComponents[PluginModuleEnums.fieldType.CSS] = require('./css');

  fieldTypesToComponents[PluginModuleEnums.fieldType.TEXT] = require('./text');

  fieldTypesToComponents[
    PluginModuleEnums.fieldType.MULTI_TEXT
  ] = require('./multi_text');

  fieldTypesToComponents[
    PluginModuleEnums.fieldType.RICH_TEXT
  ] = require('./rich_text');

  fieldTypesToComponents[
    PluginModuleEnums.fieldType.NUMBER
  ] = require('./number');

  fieldTypesToComponents[
    PluginModuleEnums.fieldType.DROPDOWN
  ] = require('./dropdown');

  fieldTypesToComponents[
    PluginModuleEnums.fieldType.MULTI_SELECT
  ] = require('./multi_select');

  fieldTypesToComponents[
    PluginModuleEnums.fieldType.TOGGLE
  ] = require('./toggle');

  fieldTypesToComponents[
    PluginModuleEnums.fieldType.COLOR
  ] = require('./color');

  fieldTypesToComponents[
    PluginModuleEnums.fieldType.IMAGE
  ] = require('./image');

  return fieldTypesToComponents;
};

const _ = require('lodash');

/**
 * Module specific pure functions
 */
module.exports = {
  /**
   * Given the gae value corresponding to a category, return its readable name
   *
   * @param {string} topLevelKey
   * @param {string} value GAE category key
   * @returns {string}
   */
  resolveReadableName(categories, value) {
    const groupEntry = _.find(categories, { value });
    return groupEntry ? groupEntry.text : '';
  },
};

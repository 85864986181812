import React from 'react';
import { Dropdown, SearchPicker, Spinner } from '@optimizely/axiom';

import PublicApiConsumerActions from 'optly/modules/public_api_consumer/actions';
import { showNotification } from 'core/ui';

import { Audience, SearchPickerRenderProps } from '../types';

const searchAudiencesByProjectId = (projectId: number) => async ({
  query,
}: {
  query: string;
}): Promise<Audience[] | undefined> => {
  try {
    const response = await PublicApiConsumerActions.fetchPage('search', {
      queryParams: {
        type: 'audience',
        page: 1,
        per_page: 25,
        archived: false,
        projectId,
        query,
        sort: 'created',
      },
    });

    if (response.ok) {
      return response.json();
    }
    showNotification({
      message:
        'There was an error loading Audiences. Please refresh the page or visit our Help Center if the issue persists.',
      type: 'error',
    });
  } catch (error) {
    showNotification({
      message: error.message,
      type: 'error',
    });
  }
};

type AudienceSearchDropdownProps = {
  everyoneSelected: boolean;
  projectId: number;
  onEveryoneClicked: () => void;
  onAudienceClicked: (audience: Audience) => void;
  selectedAudienceIds: number[];
};

const AudienceSearchDropdown = (props: AudienceSearchDropdownProps) => {
  const {
    everyoneSelected,
    projectId,
    onEveryoneClicked,
    onAudienceClicked,
    selectedAudienceIds,
  } = props;

  return (
    <Dropdown
      arrowIcon="down"
      buttonContent="Choose an audience..."
      fullWidth={true}
      shouldHideChildrenOnClick={false}
      className="width--11-12">
      <Dropdown.Contents direction="right">
        <SearchPicker
          searchFunction={searchAudiencesByProjectId(projectId)}
          supportedTypes={['audience']}>
          {({
            availableEntities,
            renderInput,
            currentFauxFocusIndex,
            isLoading,
            searchQuery,
          }: SearchPickerRenderProps) => (
            <>
              <Dropdown.ListItem>{renderInput()}</Dropdown.ListItem>
              {isLoading ? (
                <Dropdown.ListItem className="soft flex flex-justified--center">
                  <Spinner size="small" hasOverlay={false} />
                </Dropdown.ListItem>
              ) : (
                <>
                  {/* Everyone option */}
                  {!everyoneSelected && (
                    <Dropdown.ListItem key="everyone_fixed">
                      <Dropdown.BlockLink
                        onClick={onEveryoneClicked}
                        testSection="audience-everyone">
                        <Dropdown.BlockLinkText text="Everyone" />
                      </Dropdown.BlockLink>
                    </Dropdown.ListItem>
                  )}

                  {/* Search result options */}
                  {availableEntities
                    .filter(
                      audience => !selectedAudienceIds.includes(audience.id),
                    )
                    .map((audience, _index) => (
                      <Dropdown.ListItem key={audience.id}>
                        <Dropdown.BlockLink
                          hasFauxFocus={currentFauxFocusIndex === _index + 1}
                          onClick={() => onAudienceClicked(audience)}
                          testSection={`audience-${audience.id}`}>
                          <Dropdown.BlockLinkText text={audience.name} />
                        </Dropdown.BlockLink>
                      </Dropdown.ListItem>
                    ))}
                </>
              )}

              {!isLoading && availableEntities.length === 0 && (
                <Dropdown.ListItem ignoreToggle={true}>
                  <span className="micro muted soft--sides">
                    {searchQuery
                      ? `No audiences found matching "${searchQuery}"`
                      : 'No audiences found'}
                  </span>
                </Dropdown.ListItem>
              )}
            </>
          )}
        </SearchPicker>
      </Dropdown.Contents>
    </Dropdown>
  );
};

export default AudienceSearchDropdown;

/**
 * A filter to convert a variation point value to a percentage
 * used to display traffic allocation points on a variation
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const basis = require('optly/utils/basis');
const percentage = require('optly/filters/percentage');

module.exports = function(val) {
  return percentage(basis.toFloat(val), 2);
};

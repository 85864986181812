const _ = require('lodash');

const createEntityGetters = require('optly/modules/rest_api/create_entity_getters')
  .default;

const definition = require('./entity_definition');

const baseEntityGetters = createEntityGetters(definition);

module.exports = _.extend(baseEntityGetters, {
  // implement getters here
});

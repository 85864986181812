import keyMirror from 'optly/utils/key_mirror';

export const actionType = keyMirror({
  LINK_REGISTER: null,
  LINK_SET: null,
  LINK_DELETE: null,
});

export default {
  ...actionType,
};

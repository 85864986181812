/**
 * Layer Summary Entity Module
 */
import actions from './actions';
import entityDef from './entity_definition';
import getters from './getters';
import fns from './fns';

// name exports
export { actions, entityDef, getters, fns };

// default exports
export default {
  actions,
  entityDef,
  getters,
  fns,
};

import ChangeEditorSidebarMixin from './change_editor_sidebar';
import CodeMirrorMixin from './code_mirror';
import CustomCodeMixin from './custom_code';
import EditableSelectorMixin from './editable_selector';
import EditorToolbarMixin from './editor_toolbar';
import FilterableMixin from './filterable';
import SidebarHeaderMixin from './sidebar_header';

export const ChangeEditorSidebar = ChangeEditorSidebarMixin;
export const CodeMirror = CodeMirrorMixin;
export const CustomCode = CustomCodeMixin;
export const EditableSelector = EditableSelectorMixin;
export const EditorToolbar = EditorToolbarMixin;
export const Filterable = FilterableMixin;
export const SidebarHeader = SidebarHeaderMixin;

export default {
  ChangeEditorSidebar,
  CodeMirror,
  CustomCode,
  EditableSelector,
  EditorToolbar,
  Filterable,
  SidebarHeader,
};

/**
 * Defines data fetching and state setup functions used
 * for routing.  Functions should go here if they need to be
 * used cross section only.
 *
 * If a function is only used in routingSetup of pages within a
 * single section then it should go in the SectionModule.actions
 *
 * The difference between routingHelpers are routingFns are
 * routingFns are actual route functions passed (ctx, next)
 * where routing_helpers are generic javascript functions
 *
 * This should generally not include the following:
 * - RoutingFns
 * - Any section or SectionModule
 * - components
 */
import $ from 'jquery';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import ui from 'core/ui';
import flux from 'core/flux';
import Router from 'core/router';

import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { actions as AudienceActions } from 'optly/modules/entity/audience';
import { actions as CategoriesActions } from 'bundles/p13n/modules/predefined_categories';
import {
  actions as CommitActions,
  getters as CommitGetters,
} from 'optly/modules/entity/commit';
import {
  actions as CurrentLayerActions,
  getters as CurrentLayerGetters,
} from 'bundles/p13n/modules/current_layer';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { actions as EventModuleActions } from 'optly/modules/entity/event';
import { actions as ExperimentationGroupActions } from 'optly/modules/entity/experimentation_group';
import handleAjaxError from 'optly/utils/handle_ajax_error';
import HistoryUtil from 'optly/utils/history';
import {
  actions as LayerActions,
  getters as LayerGetters,
} from 'optly/modules/entity/layer';
import LayerExperimentActions from 'optly/modules/entity/layer_experiment/actions';
import {
  actions as LiveCommitTagActions,
  getters as LiveCommitTagGetters,
} from 'optly/modules/entity/live_commit_tag';
import { fns as PermissionsModuleFns } from 'optly/modules/permissions';
import PermissionsGetters from 'optly/modules/permissions/getters';
import { actions as PluginModuleActions } from 'optly/modules/entity/plugin';
import ProjectActions from 'optly/modules/entity/project/actions';
import ProjectFns from 'optly/modules/entity/project/fns';
import ProjectGetters from 'optly/modules/entity/project/getters';
import SentryActions from 'optly/modules/sentry/actions';
import { actions as TargetingConditionActions } from 'optly/modules/entity/targeting_condition';
import { actions as ViewActions } from 'optly/modules/entity/view';

/**
 * Configure event handlers to listen to push state events from
 * the NAVBAR SCS and handle them with our router.
 */
export function initialize() {
  Router.initialize();
  const unsubscribePushState = ui.$on('NAVBAR__URL_CHANGED', ({ href }) => {
    // This event is a signal that the NAVBAR has changed the URL (via pushState).
    // We can treat this similarly to a popstate event - i.e. something outside
    // our application changed the URL and we need to make sure our app reflects
    // that change (using mode='pop' will run the handlers without altering history)
    Router.go(href, 'pop');
  });

  return () => {
    unsubscribePushState();
    Router.reset();
  };
}

/**
 * Method to invoke History.back without the Router listening
 * for a popstate event. Only used with the DialogManager.
 */
export function historyBackSilent() {
  return Router.instance
    .executeWithoutPopstateListener(
      () =>
        new Promise((resolve, reject) => {
          // Add a timeout in case the event is never received
          const popstateEventTimeout = setTimeout(() => {
            window.removeEventListener('popstate', handlePopstateEvent);
            reject(new Error('popstate event never received'));
          }, 1000);

          // Add event listener to resolve when HistoryUtil.back emits popstate event
          function handlePopstateEvent() {
            clearTimeout(popstateEventTimeout);
            window.removeEventListener('popstate', handlePopstateEvent);
            resolve();
          }
          window.addEventListener('popstate', handlePopstateEvent);

          // Navigate back to hide the dialog
          HistoryUtil.back();
        }),
    )
    .catch(error => {
      // If an error occurs due to a timeout, alert Sentry
      SentryActions.withScope(scope => {
        scope.setFingerprint([
          'prevent_router_reevaluating_when_closing_dialog',
          'popstate',
        ]);
        SentryActions.captureException(error);
      });
    });
}

/**
 * Fetch the layer with the given id and set it as the current layer, without fetching
 * all associated entities as fetchLayerAndSetAsCurrentAndFetchAssociatedEntities does
 *
 * @param {Number} layerId
 *
 * @returns {jQuery.deferred}
 */
export function fetchLayerAndSetAsCurrent(layerId) {
  const cachedLayer = flux.evaluateToJS(LayerGetters.byId(layerId));

  // Only fetch the layer if we don't have it yet
  const layerDeferred = cachedLayer
    ? $.Deferred().resolve(cachedLayer)
    : LayerActions.fetch(layerId);

  return layerDeferred.then(layer => {
    if (!layer) {
      return $.Deferred().reject();
    }

    CurrentLayerActions.setCurrentLayerId(layerId);
  });
}

/**
 * Fetch all layer data associated with a layer to open the layer create/edit dialog
 * @param {Boolean} isFullStack - Notifies the function if the fetched layer is a full stack layer to avoid fetching
 *  unnecessary entities
 * @return {Deferred}
 */
export function fetchCreateLayerData(isFullStack = false) {
  const deferreds = [];
  const byProject = {
    project_id: flux.evaluate(CurrentProjectGetters.id),
  };
  const currentLayer = flux.evaluate(CurrentLayerGetters.layer);
  const layerId = currentLayer?.get('id');
  const isEventsBatchFetchEnabled = isFeatureEnabled('events_batch_fetch');

  const audiencesFilters = {
    ...byProject,
  };

  if (layerId) {
    audiencesFilters.layer_id = layerId;
  }

  const eventsFilters = {
    ...byProject,
  };

  if (isEventsBatchFetchEnabled && layerId) {
    eventsFilters.layer_id = layerId;
  }

  AudienceActions.fetchAll(audiencesFilters, {
    skipEvaluatingCachedData: true,
  });

  deferreds.push(
    EventModuleActions.fetchAll(eventsFilters, {
      skipEvaluatingCachedData: true,
    }),
  );

  if (!isFullStack) {
    // prefetch targeting conditions for the audience editor
    TargetingConditionActions.fetchAll(
      {},
      {
        skipEvaluatingCachedData: true,
      },
    );

    const isViewsAsyncEnabled = isFeatureEnabled('async_views_call');
    if (isViewsAsyncEnabled) {
      ViewActions.fetchAll(byProject, {
        skipEvaluatingCachedData: true,
      });
    } else {
      deferreds.push(
        ViewActions.fetchAll(byProject, {
          skipEvaluatingCachedData: true,
        }),
      );
    }

    CategoriesActions.fetchAll(
      {},
      {
        skipEvaluatingCachedData: true,
      },
    );
  }

  return $.when(...deferreds);
}

/**
 * Fetch the layer with the given id and set it as the current layer
 * Also fetch all associated entities for the layer
 *   - views
 *   - audiences
 *   - events
 *   - LayerExperiments
 *
 * @param {Number} layerId
 * @param {Object} options
 * @param {Boolean} options.fetchLiveCommitTag - If true, fetches LiveCommitTag of the argument
 * layer id. Defaults to true.
 * @returns {jQuery.deferred}
 */
export function fetchLayerAndSetAsCurrentAndFetchAssociatedEntities(
  layerId,
  { fetchLiveCommitTag = true, experimentFilters = {} } = {},
) {
  const cachedLayer = flux.evaluateToJS(LayerGetters.byId(layerId));

  // Only fetch the layer if we don't have it yet
  const layerDeferred = cachedLayer
    ? $.Deferred().resolve(cachedLayer)
    : LayerActions.fetch(layerId);

  return layerDeferred.then(layer => {
    if (!layer) {
      return $.Deferred().reject();
    }

    CurrentLayerActions.setCurrentLayerId(layerId);

    const cachedProject = flux.evaluateToJS(
      ProjectGetters.byId(layer.project_id),
    );

    const projectDeferred = cachedProject
      ? $.Deferred().resolve(cachedProject)
      : ProjectActions.fetch(layer.project_id);

    return projectDeferred.then(project => {
      if (fetchLiveCommitTag && !ProjectFns.isFullStackProject(project)) {
        const liveTag = flux.evaluateToJS(
          LiveCommitTagGetters.liveCommitTagByLayerId(layerId),
        );
        // Only fetch the live tag if we don't have it yet
        const liveTagDeferred = liveTag
          ? $.Deferred().resolve(liveTag)
          : LiveCommitTagActions.fetch({ layer_id: layer.id }, true);

        liveTagDeferred
          .fail(
            handleAjaxError(() => {
              // Ignore errors when the live tag does not exist
              // This is a valid state for layers that have not been published yet
            }),
          )
          .done(fetchedLiveTag => {
            if (fetchedLiveTag && fetchedLiveTag.commit_id) {
              // Also get the relevant commit if we don't already have it to show experience status on results page
              const commit = flux.evaluate(
                CommitGetters.byId(fetchedLiveTag.commit_id),
              );

              if (!commit) {
                CommitActions.fetch(fetchedLiveTag.commit_id);
              }
            }
          });
      }

      return $.when(
        LayerExperimentActions.intelligentFetchAll(
          layer,
          false,
          experimentFilters,
        ),
        this.fetchCreateLayerData(ProjectFns.isFullStackProject(project)),
      );
    });
  });
}

export function fetchProjectPlugins() {
  const canUsePlugins = flux.evaluate(PermissionsGetters.canUsePlugins);
  if (!canUsePlugins) {
    // dont try to fetch resource user doesnt have permission for
    return $.Deferred().resolve();
  }
  const byProject = {
    project_id: flux.evaluate(CurrentProjectGetters.id),
  };
  PluginModuleActions.fetchAll(byProject);
}

export function fetchCommit(layerId) {
  const commitDef = $.Deferred();
  LiveCommitTagActions.fetch({
    layer_id: layerId,
  })
    .done(tag => {
      if (tag && tag.commit_id) {
        CommitActions.fetch(tag.commit_id).then(commit => {
          commitDef.resolve(commit);
        });
      } else {
        commitDef.resolve();
      }
    })
    .fail(
      handleAjaxError(() => {
        // Ignore errors when the live tag does not exist
        // This is a valid state for layers that have not been published yet
        commitDef.resolve();
      }),
    );

  return commitDef;
}

export function fetchGroupsData() {
  const canUseMutex = flux.evaluate([
    AdminAccountGetters.accountPermissions,
    PermissionsModuleFns.canUseMutex,
  ]);
  if (!canUseMutex) {
    // Don't try to fetch resource user doesn't have permission for
    return $.Deferred().resolve();
  }
  const byProject = {
    project_id: flux.evaluate(CurrentProjectGetters.id),
  };
  return ExperimentationGroupActions.fetchAll(byProject);
}

export default {
  fetchLayerAndSetAsCurrent,
  fetchCreateLayerData,
  fetchLayerAndSetAsCurrentAndFetchAssociatedEntities,
  fetchProjectPlugins,
  fetchCommit,
  fetchGroupsData,
  historyBackSilent,
  initialize,
};

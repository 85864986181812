import htmlSanitizer from 'sanitizer';

import { toJS } from 'optly/immutable';
import basis from 'optly/utils/basis';

import { enums as LayerExperimentEnums } from 'optly/modules/entity/layer_experiment';

/**
 * Given a list of variations, return true if any of them have a negative percentage value.
 * @param {Immutable.List} variations
 * @returns {Boolean}
 */
export const hasNegativePercentage = variations =>
  variations.some(variation => Number(variation.get('weight') < 0));

/**
 * Given a list of variations, return the total percent
 * @param {Immutable.List} variations with a percentage property
 * @returns {Number} total percent
 */
export const calculateTotalPercent = variations => {
  const totalPercent = variations.reduce(
    (sum, variation) => sum + Number(variation.get('percentage')),
    0,
  );

  const formattedTotalPercent =
    Math.round(totalPercent * basis.MULTIPLIER) / basis.MULTIPLIER;
  return formattedTotalPercent;
};

/**
 * Given a list of variations with traffic settings for a partial factorial MVT,
 * format it for consumption by the REST API
 * @param {Immutable.List} mvtCombinations
 * @returns {Array}
 */
export const buildVariationTrafficDataForPartialFactorial = mvtCombinations =>
  toJS(mvtCombinations).map(combination => ({
    actions: combination.variation.actions,
    api_name: combination.variation.api_name,
    archived: combination.variation.archived,
    description: combination.variation.description,
    name: combination.variation.name,
    status: combination.variation.status,
    variable_values: combination.variation.variable_values,
    variation_id: combination.variation.variation_id,
    weight: basis.getWeightFromPercentage(combination.percentage),
  }));

/**
 * Simple check to see if the MVT is in partial factorial mode. This is useful for our creation/deletion warning dialogs.
 * @param {String} policy
 * @returns Boolean
 */
export const isPartialFactorial = policy =>
  policy === LayerExperimentEnums.multivariateTrafficPolicies.PARTIAL_FACTORIAL;

/**
 * Generates the warning dialog message and confirm text for creating sections within MVT. Should only show
 * if the MVT is in partial factorial mode or if it has been started.
 * @param {Boolean} hasLayerStarted
 * @param {Boolean} isPartialFactorial
 * @returns {Object}
 */
export const generateCreateSectionWarning = (
  hasLayerStarted,
  isPartialFactorial,
) => {
  let message;
  let confirmText;

  if (hasLayerStarted && isPartialFactorial) {
    message = tr(`Are you sure you want to add a section? Adding a section will generate
a whole new set of combinations for this multivariate test and remove any existing
combinations. This will reset your results the next time you publish. Since your test
is currently in <strong>partial factorial mode</strong>, your traffic allocation will be reset
to an even distribution between all combinations.`);
    confirmText = tr('Create Section and Reset Results and Traffic');
  } else if (hasLayerStarted) {
    message = tr(`Are you sure you want to add a section? Adding a section will generate
a whole new set of combinations for this multivariate test and remove any existing
combinations. This will reset your results the next time you publish.`);
    confirmText = tr('Create Section and Reset Results');
  } else if (isPartialFactorial) {
    message = tr(`Are you sure you want to add a section? Since your test
is currently in <strong>partial factorial mode</strong>, your traffic allocation will be reset
to an even distribution between all combinations.`);
    confirmText = tr('Create Section and Reset Traffic');
  }

  return {
    message,
    confirmText,
  };
};

/**
 * Generates the warning dialog message and confirm text for deleting a section within MVT. Should only show
 * if the MVT is in partial factorial mode or if it has been started.
 * @param {String} sectionName
 * @param {Boolean} hasLayerStarted
 * @param {Boolean} isPartialFactorial
 * @returns {Object}
 */
export const generateDeleteSectionWarning = (
  sectionName,
  hasLayerStarted,
  isPartialFactorial,
) => {
  const escapedSectionName = htmlSanitizer.escape(sectionName);
  let message = tr(
    'Are you sure you would like to delete the section <strong>{0}</strong> in the current experiment?',
    escapedSectionName,
  );
  let confirmText = tr('Delete Section');

  if (hasLayerStarted && isPartialFactorial) {
    message = tr(
      `Are you sure you would like to delete the section <strong>{0}</strong> in the current experiment?
Deleting a section will generate a whole new set of combinations for this multivariate test and
remove any existing combinations. This will reset your results the next time you publish.
Since your test is currently in <strong>partial factorial mode</strong>, your traffic allocation will be reset
to an even distribution between all combinations.`,
      escapedSectionName,
    );
    confirmText = tr('Delete Section and Reset Results and Traffic');
  } else if (hasLayerStarted) {
    message = tr(
      `Are you sure you would like to delete the section <strong>{0}</strong> in the current experiment?
Deleting a section will generate a whole new set of combinations for this multivariate test and
remove any existing combinations. This will reset your results the next time you publish.`,
      escapedSectionName,
    );
    confirmText = tr('Delete Section and Reset Results');
  } else if (isPartialFactorial) {
    message = tr(
      `Are you sure you would like to delete the section <strong>{0}</strong> in the current experiment?
Since your test is currently in <strong>partial factorial mode</strong>, your traffic allocation will be reset
to an even distribution between all combinations.`,
      escapedSectionName,
    );
    confirmText = tr('Delete Section and Reset Traffic');
  }

  return {
    message,
    confirmText,
  };
};

export default {
  hasNegativePercentage,
  calculateTotalPercent,
  buildVariationTrafficDataForPartialFactorial,
  isPartialFactorial,
  generateCreateSectionWarning,
  generateDeleteSectionWarning,
};

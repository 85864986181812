import _ from 'lodash';

import cloneDeep from 'optly/clone_deep';

import requester from './api/requester';

/**
 * Functions for models with only one parent
 *
 * Config required:
 *
 * config.entity: string
 * config.parent: object (optional)
 * config.parent.entity: string
 * config.parent.key: string
 */
const entityApiFunctions = {
  /**
   * Persists entity using rest API
   *
   * @param {object} config model definition
   * @param {Model} instance
   * @return {Deferred}
   */
  save(config, instance, apiOptions) {
    apiOptions = apiOptions || {};
    if (instance.id && !apiOptions.forcePost) {
      // do PUT save
      return requester(apiOptions)
        .one(config.entity, encodeURIComponent(instance.id))
        .put(instance);
    }
    // no id is set, do a POST
    const endpoint = requester(apiOptions);
    if (config.parent) {
      endpoint.one(config.parent.entity, instance[config.parent.key]);
    }

    // use post http verb unless entity definition overrides
    const httpVerb = config.createEntityVerb || 'post';

    return endpoint.all(config.entity)[httpVerb](instance);
  },

  /**
   * Fetch and return an entity
   * @param {object} config model definition
   * @param entityId Id of Entity to fetch
   * @returns {Deferred} Resolves to fetched Model instance
   */
  fetch(config, entityId, apiOptions) {
    return requester(apiOptions)
      .one(config.entity, encodeURIComponent(entityId))
      .get();
  },

  /**
   * Fetches a page of entities that match the supplied filters
   * as well as the offset and limit defining the size of the page.
   * If the model has a parent association then the parent.key must be
   * supplied.
   * @param {object} config model definition
   * @param {Object|undefined} filters (optional)
   * @return {Deferred}
   */
  fetchPage(config, filters, apiOptions) {
    const filtersParam = filters || {};
    const { $idsOnly, $order, $offset = 0, $limit } = filtersParam;
    const filtersToApply = _.omit(filtersParam, [
      '$idsOnly',
      '$order',
      '$limit',
      '$offset',
    ]);
    const endpoint = requester(apiOptions);

    if (config.parent && !filtersToApply[config.parent.key]) {
      throw new Error(
        'fetchPage: must supply the parent.key as a filter to fetch entities',
      );
    }

    if (config.parent) {
      endpoint.one(config.parent.entity, filtersToApply[config.parent.key]);
      // since the filtering is happening in the endpoint url we dont need filters
      delete filtersToApply[config.parent.key];
    }

    let fetchQuery = endpoint
      .all(config.entity)
      .filter(filtersToApply)
      .order($order)
      .limit($limit)
      .offset($offset);

    if (apiOptions && apiOptions.excludeFields) {
      fetchQuery = fetchQuery.param('exclude', apiOptions.excludeFields);
    }

    if ($idsOnly === true) {
      fetchQuery = fetchQuery.idsOnly();
    }

    return fetchQuery.get();
  },

  /**
   * Fetches all the entities that match the supplied filters
   * If the model has a parent association then the parent.key must be
   * supplied.
   * @param {object} config model definition
   * @param {Object|undefined} filters (optional)
   * @return {Deferred}
   */
  fetchAll(config, filters, apiOptions) {
    const filtersParam = filters || {};
    const { $idsOnly, $order } = filtersParam;
    const filtersToApply = _.omit(filtersParam, ['$idsOnly', '$order']);
    const endpoint = requester(apiOptions);

    if (config.parent && !filtersToApply[config.parent.key]) {
      throw new Error(
        'fetchAll: must supply the parent.key as a filter to fetch all entities',
      );
    }

    if (config.parent) {
      endpoint.one(
        config.parent.entity,
        encodeURIComponent(filtersToApply[config.parent.key]),
      );
      // since the filtering is happening in the endpoint url we dont need filters
      delete filtersToApply[config.parent.key];
    }

    let fetchQuery = endpoint
      .all(config.entity)
      .filter(filtersToApply)
      .order($order);

    if ($idsOnly === true) {
      fetchQuery = fetchQuery.idsOnly();
    }

    if (apiOptions && apiOptions.excludeFields) {
      fetchQuery = fetchQuery.param('exclude', apiOptions.excludeFields);
    }

    return fetchQuery.get();
  },

  /**
   * Makes an API request to delete the instance by id
   * @param {object} config model definition
   * @param {Model} instance
   */
  delete(config, instance, apiOptions) {
    if (!instance.id) {
      throw new Error('delete(): `id` must be defined');
    }

    return requester(apiOptions)
      .one(config.entity, encodeURIComponent(instance.id))
      .delete();
  },
};

/**
 * Functions for models with two parents
 *
 * Example: project_integrations has integration and project as parents
 *
 * Sample Config:
 *
 * config options:
 * 'entity' {String} name of entity in the API, ex: 'projectintegrations' (unique)
 * 'parents' {[{ entity: String, key: String }]} the required parents association for fetches, note here that
 *                                               the order of parent configs in this property matters, e.g. for
 *                                               [{entity: 'projects', key: ...}, {entity: 'integrations', key: ...}],
 *                                               the fetchAll operation will use an API endpoint like below:
 *                                               /projects/<project_id>/integrations, reverse the parent configs
 *                                               the endpoint will then become /integrations/<integration_id>/projects
 * 'fields' {Object} (optional) hash of default values of the entity when using Model.create()
 *
 *
 * Config required:
 *
 * config.entity: string
 * config.parents: array
 */
const relationshipApiFunctions = {
  /**
   * Persists a relationship entity using rest API
   *
   * @param {Model} instance
   * @return {Deferred}
   */
  save(config, instance, apiOptions) {
    const endpoint = requester(apiOptions);

    _.each(config.parents, parentConfig => {
      const parentKeyId =
        instance[parentConfig.key] || apiOptions[parentConfig.key];
      if (!parentKeyId) {
        throw new Error(
          `${parentConfig.key} must be supplied in instance or options.`,
        );
      }
      endpoint.one(parentConfig.entity, encodeURIComponent(parentKeyId));
    });

    // PUT endpoint will perform upsert operation for relationship entities
    return endpoint.put(instance);
  },

  /**
   * Fetch and return a relationship entity
   * @param {Object} parentIdMap Ids of parent entities which together identify the relationship entity to fetch
   * @returns {Deferred} Resolves to fetched Model instance
   */
  fetch(config, parentIdMap, apiOptions) {
    const endpoint = requester(apiOptions);

    _.each(config.parents, parentConfig => {
      if (!parentIdMap[parentConfig.key]) {
        throw new Error(`${parentConfig.key} must be supplied in parentIdMap.`);
      }
      endpoint.one(
        parentConfig.entity,
        encodeURIComponent(parentIdMap[parentConfig.key]),
      );
    });

    return endpoint.get();
  },

  /**
   * Fetches a page of entities that match the supplied filters
   * as well as the offset and limit defining the size of the page.
   * @param {object} config model definition
   * @param {Object|undefined} filters (optional)
   * @return {Deferred}
   */
  fetchPage(config, filters, apiOptions) {
    const filtersParam = filters || {};
    const { $idsOnly, $order, $offset = 0, $limit } = filtersParam;
    const filtersToApply = _.omit(filtersParam, [
      '$idsOnly',
      '$order',
      '$limit',
      '$offset',
    ]);
    const endpoint = requester(apiOptions);
    let missingParentEntity;

    _.each(config.parents, parentConfig => {
      if (filtersToApply[parentConfig.key]) {
        endpoint.one(
          parentConfig.entity,
          encodeURIComponent(filtersToApply[parentConfig.key]),
        );
        delete filtersToApply[parentConfig.key];
      } else if (missingParentEntity) {
        throw new Error(
          `fetchAll: miss too many parent IDs in filters: ${[
            missingParentEntity.key,
            parentConfig.key,
          ].join(', ')}`,
        );
      } else {
        missingParentEntity = parentConfig;
      }
    });

    if (!missingParentEntity) {
      throw new Error(
        "fetchPage: exactly one of the parents' keys must not be supplied in filters.",
      );
    }

    let fetchQuery = endpoint
      .all(missingParentEntity.entity)
      .filter(filtersToApply)
      .order($order)
      .limit($limit)
      .offset($offset);

    if ($idsOnly === true) {
      fetchQuery = fetchQuery.idsOnly();
    }

    if (apiOptions && apiOptions.excludeFields) {
      fetchQuery = fetchQuery.param('exclude', apiOptions.excludeFields);
    }

    return fetchQuery.get();
  },

  /**
   * Fetches all the entities that match the supplied filters
   * Exactly one of the parents' keys must not be supplied in filters.
   * @param {Object|undefined} filters (optional)
   * @return {Deferred}
   */
  fetchAll(config, filters, apiOptions) {
    filters = _.clone(filters || {});
    const endpoint = requester(apiOptions);
    let missingParentEntity;

    _.each(config.parents, parentConfig => {
      if (filters[parentConfig.key]) {
        endpoint.one(
          parentConfig.entity,
          encodeURIComponent(filters[parentConfig.key]),
        );
        delete filters[parentConfig.key];
      } else if (missingParentEntity) {
        throw new Error(
          `fetchAll: miss too many parent IDs in filters: ${[
            missingParentEntity.key,
            parentConfig.key,
          ].join(', ')}`,
        );
      } else {
        missingParentEntity = parentConfig;
      }
    });

    if (!missingParentEntity) {
      throw new Error(
        "fetchAll: exactly one of the parents' keys must not be supplied in filters.",
      );
    }

    return endpoint
      .all(missingParentEntity.entity)
      .filter(filters)
      .get();
  },

  /**
   * Makes an API request to delete a relationship entity by its parent ids
   * @param {Model} instance
   */
  delete(config, instance, apiOptions) {
    const endpoint = requester(apiOptions);

    _.each(config.parents, parentConfig => {
      if (!instance[parentConfig.key]) {
        throw new Error(`${parentConfig.key} must be supplied in instance.`);
      }
      endpoint.one(
        parentConfig.entity,
        encodeURIComponent(instance[parentConfig.key]),
      );
    });

    return endpoint.delete();
  },
};

/**
 * Helper function to handle a jQuery ajax response and return a consistent form,
 * parsing the response headers into an object and optionally processing the return data.
 *
 * @param {Function=} processFn - Function to process the data response through before returning.
 * @return {
 *   @param {Function(responseData, textStatus, jqXHR)}
 *   @return {Object}       response
 *   @return {Object|Array} response.data - The data returned by the fetch.
 *   @return {Object}       response.responseHeaders - The response headers of the fetch.
 * }
 */
const normalizeFetch = processFn => (responseData, textStatus, jqXHR) => {
  const responseHeadersString = jqXHR && jqXHR.getAllResponseHeaders();
  const responseHeadersList = responseHeadersString
    ? responseHeadersString.split('\n')
    : [];
  // Parse the response headers string into a map of key/value pairs.
  // See https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/getAllResponseHeaders
  const responseHeaders = _.fromPairs(
    responseHeadersList.map(h => {
      const [k] = h.split(':');
      return [k, jqXHR.getResponseHeader(k)];
    }),
  );
  let data = responseData;
  if (processFn) {
    data = Array.isArray(responseData)
      ? responseData.map(processFn)
      : processFn(responseData);
  }
  return { data, responseHeaders };
};

/**
 * Persists an entity through REST API.  Does a PATCH if the `id` isn't set
 *
 * @param {object} modelDef
 * @param {object} instance
 * @param {object} options
 * @return {Promise}
 */
export function save(modelDef, instance, options) {
  if (modelDef.serialize) {
    instance = modelDef.serialize(cloneDeep(instance));
  }

  let def;
  if (modelDef.isRelationshipEntity) {
    def = relationshipApiFunctions.save(modelDef, instance, options);
  } else {
    def = entityApiFunctions.save(modelDef, instance, options);
  }
  // Notification for subscribers outside the current scs that have no access to local flux store
  def.then(updatedInstance => window.dispatchEvent(
    new CustomEvent('entitySave', {detail: {modelDef: modelDef, instance: updatedInstance}}))
  );
  if (modelDef.deserialize) {
    return def.then(modelDef.deserialize);
  }
  return def;
}

/**
 * Fetches an entity by id or parent keys (relationship entity)
 *
 * @param {object} modelDef
 * @param {number|object} filter
 * @return {Promise}
 */
export function fetch(modelDef, ...args) {
  let def;
  if (modelDef.isRelationshipEntity) {
    def = relationshipApiFunctions.fetch(modelDef, ...args);
  } else {
    def = entityApiFunctions.fetch(modelDef, ...args);
  }
  if (modelDef.deserialize) {
    return def.then(normalizeFetch(modelDef.deserialize));
  }
  return def.then(normalizeFetch());
}

/**
 * Fetches a page of an entity must supply $limit and $offset as filters
 *
 * @param {object} modelDef
 * @param {object} filter
 * @return {Promise}
 */
export function fetchPage(modelDef, ...args) {
  let def;
  if (modelDef.isRelationshipEntity) {
    def = relationshipApiFunctions.fetchPage(modelDef, ...args);
  } else {
    def = entityApiFunctions.fetchPage(modelDef, ...args);
  }
  if (modelDef.deserialize) {
    return def.then(normalizeFetch(modelDef.deserialize));
  }
  return def.then(normalizeFetch());
}

/**
 * Fetches all entries for an entity
 *
 * @param {object} modelDef
 * @param {object} filter
 * @return {Promise}
 */
export function fetchAll(modelDef, ...args) {
  let def;
  if (modelDef.isRelationshipEntity) {
    def = relationshipApiFunctions.fetchAll(modelDef, ...args);
  } else {
    def = entityApiFunctions.fetchAll(modelDef, ...args);
  }
  if (modelDef.deserialize) {
    return def.then(normalizeFetch(modelDef.deserialize));
  }
  return def.then(normalizeFetch());
}

/**
 * Deletes an entity through the rest API
 * @param {object} modelDef
 * @param {instance}
 * @return {Promise}
 */
function deleteEntity(modelDef, ...args) {
  if (modelDef.isRelationshipEntity) {
    return relationshipApiFunctions.delete(modelDef, ...args);
  }
  return entityApiFunctions.delete(modelDef, ...args);
}

export { deleteEntity as delete };

export default {
  delete: deleteEntity,
  fetch,
  fetchAll,
  fetchPage,
  save,
};

import React from 'react';
import PropTypes from 'prop-types';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import {
  virtualPageview,
  withTrack,
} from '@optimizely/segment-js/dist/decorators';

import {
  Attention,
  Button,
  Code,
  Sheet,
  Container,
  Spinner,
  Switch,
  Icon,
} from 'optimizely-oui';

import ui from 'core/ui';

import FeaturesSelectLanguageDropdown from 'bundles/p13n/sections/features/pages/features_dashboard/components/features_quickstart/components/features_select_language_dropdown';
import CodeStepHelpPopover from 'bundles/p13n/sections/features/pages/features_dashboard/components/features_quickstart/components/code_step_help_popover';
import FeaturesQuickstartConfirmLeave from 'bundles/p13n/sections/features/pages/features_dashboard/components/features_quickstart/components/features_quickstart_confirm_leave';

import {
  LANGUAGE_OPTIONS,
  LANGUAGES,
  SNIPPETS,
  LANGUAGE_OPTIONS_NOT_AVAILABLE,
} from 'bundles/p13n/sections/features/pages/features_dashboard/components/features_quickstart/component_module/constants';

@withTrack
@virtualPageview('Features', 'Quickstart Guide')
class FeaturesQuickstart extends React.Component {
  static componentId = 'FeaturesQuickstart';

  static propTypes = {
    flagKey: PropTypes.string.isRequired,
    sdkKey: PropTypes.string.isRequired,
    featureFlag: PropTypes.object.isRequired,
    toggleFlag: PropTypes.func.isRequired,
    isFlagOn: PropTypes.func.isRequired,
    track: PropTypes.func,
  };

  static defaultProps = {
    track: () => {},
  };

  state = {
    isFlagOn: this.props.isFlagOn(this.props.featureFlag),
    featureToggleCount: 0,
    hasConfirmed: false,
    updatedFeatureFlag: this.props.featureFlag,
    isUpdatingFeature: false,
    selectedLanguage: LANGUAGES.REACT,
  };

  onToggle = () => {
    const { track, environment } = this.props;
    const { featureToggleCount, updatedFeatureFlag } = this.state;

    this.setState({
      isUpdatingFeature: true,
    });

    this.props.toggleFlag(updatedFeatureFlag).then(updatedFlag => {
      const nextFeatureToggleCount = featureToggleCount + 1;
      this.setState(prevState => ({
        featureToggleCount: nextFeatureToggleCount,
        updatedFeatureFlag: updatedFlag,
        isFlagOn: this.props.isFlagOn(updatedFlag),
        isUpdatingFeature: false,
      }));
      // The first toggle is when the user sees the success message.
      if (nextFeatureToggleCount === 1) {
        track('First Feature Flag Toggle Clicked');
      }
    });
  };

  handleLanguageChange = nextSelectedLanguage => {
    const { selectedLanguage } = this.state;
    if (nextSelectedLanguage !== selectedLanguage) {
      this.setState({ selectedLanguage: nextSelectedLanguage });
    }
  };

  handleConfirm = () => {
    const { track } = this.props;
    const { selectedLanguage } = this.state;
    this.setState({ hasConfirmed: true });
    // Track the language the user confirmed with, indicating which guide was used.
    track('First Feature Flag Confirm Done Clicked', { selectedLanguage });
  };

  handleClose = () => {
    const { featureToggleCount } = this.state;
    // Show confirmation dialog to users who haven't interacted with switch
    if (featureToggleCount < 1) {
      ui.showReactDialog(
        FeaturesQuickstartConfirmLeave,
        {},
        {
          isOuiDialog: true,
        },
      );
      return;
    }
    ui.hideDialog();
  };

  renderLanguageSelector = stepNum => {
    const { selectedLanguage } = this.state;

    return (
      <React.Fragment key={stepNum}>
        <p data-test-section="quickstart-language-selector">
          <strong>{stepNum}. Select a language</strong>
        </p>
        <FeaturesSelectLanguageDropdown
          languageOptions={LANGUAGE_OPTIONS}
          languageOptionsNotAvailable={LANGUAGE_OPTIONS_NOT_AVAILABLE}
          value={selectedLanguage}
          onChange={this.handleLanguageChange}
        />
        <br />
      </React.Fragment>
    );
  };

  renderFeatureToggleStep = stepNum => {
    const {
      featureToggleCount,
      hasConfirmed,
      isUpdatingFeature,
      isFlagOn,
    } = this.state;

    return (
      <React.Fragment key={stepNum}>
        <p data-test-section="quickstart-feature-toggle">
          <strong>{stepNum}. Confirm your feature flag</strong>
        </p>
        {!hasConfirmed ? (
          <div className="push-triple--bottom">
            <p>After completing the above steps, click "Done."</p>
            <Button
              onClick={this.handleConfirm}
              style="outline"
              testSection="quickstart-confirm"
              width="default">
              Done
            </Button>
          </div>
        ) : (
          <div className="push-triple--bottom">
            <p>
              The flag is off by default. Switch it on to see the feature in
              your app.
            </p>
            <div className="flex flex-align--center">
              <Switch
                checked={isFlagOn}
                elementId="quickstart-feature-toggle"
                isDisabled={isUpdatingFeature}
                onClick={this.onToggle.bind(this)}
                testSection="quickstart-feature-toggle-switch"
              />
              <div className="push--left">
                {isUpdatingFeature ? (
                  <div className="flex flex-align--center">
                    <Spinner size="small" /> Updating datafile...
                  </div>
                ) : (
                  // Show the success message only the first time feature is switched to running.
                  featureToggleCount === 1 && (
                    <Attention
                      type="good-news"
                      testSection="quickstart-success">
                      <div className="flex">
                        <div
                          className="push-half--right"
                          style={{ position: 'relative', top: '2px' }}>
                          <Icon name="check-circle-solid" size="small" />
                        </div>
                        Congrats! Your feature has been turned on.
                      </div>
                    </Attention>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  renderCodeStep = ([
    instruction,
    snippet,
    language,
    helpIconLinks,
  ]) => stepNum => {
    return (
      <React.Fragment key={stepNum}>
        <div
          data-test-section={`quickstart-code-step-${stepNum}`}
          className="push--bottom">
          <strong>{`${stepNum}. ${instruction}`}</strong>
          <OptimizelyFeature feature="first_feature_flag_guide">
            {(isEnabled, variables) => {
              // Render HelpIcon only if feature is enabled and there are links to display
              const mappedLinks = helpIconLinks.map(helpLink => {
                return Object.assign(helpLink, {
                  href: variables[helpLink.helpLinkFeatureVariable],
                });
              });
              return isEnabled && helpIconLinks.length ? (
                <CodeStepHelpPopover links={mappedLinks} />
              ) : null;
            }}
          </OptimizelyFeature>
        </div>
        <div className="code-div">
          <Code
            copyButtonStyle="outline"
            copyButtonUsesTextLabel={true}
            hasCopyButton={true}
            isHighlighted={true}
            language={language}
            type="block">
            {snippet}
          </Code>
        </div>
        <br />
      </React.Fragment>
    );
  };

  renderSteps = () => {
    const { selectedLanguage } = this.state;
    const { sdkKey, flagKey } = this.props;
    const codeSteps = SNIPPETS[selectedLanguage](sdkKey, flagKey);
    const steps = [
      this.renderLanguageSelector,
      ...codeSteps.map(this.renderCodeStep),
      this.renderFeatureToggleStep,
    ];

    return steps.map((renderer, index) => renderer(index + 1)); // Start step count from 1
  };

  render() {
    const { flagKey } = this.props;

    return (
      <Sheet
        title="Quickstart Guide"
        centerHeader={true}
        hasCloseButton={true}
        hasOverlay={true}
        footerButtonList={[
          <Button
            key={0}
            onClick={this.handleClose}
            style="highlight"
            testSection="close-quickstart-button">
            Close Quickstart Guide
          </Button>,
        ]}
        onClose={this.handleClose}>
        <h3 className="flex--dead-center">Implement your first feature flag</h3>
        <br />
        <h2 className="background--light soft flex--dead-center">{flagKey} </h2>
        <Container>{this.renderSteps()}</Container>
      </Sheet>
    );
  }
}

export default FeaturesQuickstart;

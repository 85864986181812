import RestApi from 'optly/modules/rest_api';

import { enums as LayerEnums } from 'optly/modules/entity/layer';

import entityDef from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(entityDef);

/**
 * Fetch all non-archived layers summaries associated with the given project.
 *
 * Example:
 * layer_summaries: {
 *   ...
 *   <layer_id>: {
 *     adaptive_audience_status: 'available' | 'none' | 'unavailable',
 *     id: <layer_id>,
 *   },
 *   ...
 * }
 *
 * @param {Number} projectId
 * @param {Object} fetchOptions
 * @param {String | Array<String>} policies
 * @returns {Promise<any | never>} API JSON formatted response promise
 */
const fetchNonArchivedLayerSummaries = (projectId, fetchOptions, policies) => {
  const fetchFilters = {
    project_id: projectId,
    status: [
      LayerEnums.entityStatus.NOT_STARTED,
      LayerEnums.entityStatus.PAUSED,
      LayerEnums.entityStatus.RUNNING,
    ],
  };

  if (policies) {
    fetchFilters.policy = policies;
  }

  return baseEntityActions.fetchAll(fetchFilters, fetchOptions);
};

export default {
  ...baseEntityActions,
  fetchNonArchivedLayerSummaries,
};

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';
import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

/**
 * Get the id of the share token currently loaded in the entity cache
 */
export const activeShareTokenId = [
  entityCache,
  CurrentLayerGetters.id,
  (entityCache, currentLayerId) => {
    const foo = entityCache.size
      ? entityCache.find(
          shareToken => shareToken.get('layer_id') === currentLayerId,
        )
      : null;
    return foo && foo.get('id');
  },
];

export const shareTokenByLayerId = layerId => [
  entityCache,
  entityCache =>
    entityCache.find(shareToken => shareToken.get('layer_id') === layerId),
];

export default {
  ...baseEntityGetters,
  activeShareTokenId,
  shareTokenByLayerId,
};

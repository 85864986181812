import _ from 'lodash';

import flux from 'core/flux';
import Editor from 'bundles/p13n/modules/editor';
import P13NUIActions from 'bundles/p13n/modules/ui/actions';
import ui from 'core/ui';
import CustomCodeActions from 'bundles/p13n/modules/custom_code/actions';
import CustomCodeGetters from 'bundles/p13n/modules/custom_code/getters';
import EditorIframe from 'bundles/p13n/modules/editor_iframe';
import HighlighterEnums from 'optly/modules/highlighter/enums';
import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import LayerExperimentHumanReadable from 'optly/modules/entity/layer_experiment/human_readable';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import TrackClicksChangeGetters from 'bundles/p13n/modules/track_clicks_change/getters';
import TrackClicksChangeActions from 'bundles/p13n/modules/track_clicks_change/actions';

import mixins from 'bundles/p13n/components/layers/mixins';

import guid from 'optly/utils/guid';

import ChangeListSidebarTableRowComponent from './change_list_sidebar_table_row';
import HeaderComponent from './header';
import Template from './template.html';
import WidgetConfigSidebar from '../widget_config_sidebar';

export const ChangeListSidebar = {
  replace: true,

  components: {
    'change-list-sidebar-table-row': ChangeListSidebarTableRowComponent,
  },

  componentId: 'p13n-change-list-sidebar',

  template: Template,

  data: {
    changeStatuses: LayerExperimentEnums.ChangeStatuses,
    changeTypes: LayerExperimentEnums.ChangeTypes,
    customCodeTypes: LayerExperimentEnums.CustomCodeTypes,
    editorTypes: EditorIframe.enums.EditorTypes,
    TrackClickChangeId: null,
  },

  computed: {
    isEmpty() {
      return this.currentActionAttributeChangesWithStatus.length === 0;
    },

    linesAddedStatusText() {
      const added = this.currentActionCustomCodeLinesChanged.added || 0;
      return tr.pluralize('1 line added.', '{0} lines added', added);
    },

    linesRemovedStatusText() {
      const removed = this.currentActionCustomCodeLinesChanged.removed || 0;
      return tr.pluralize('1 line removed', '{0} lines removed', removed);
    },

    cssLinesAddedStatusText() {
      const added = this.currentActionCustomCssLinesChanged.added || 0;
      return tr.pluralize('1 line added.', '{0} lines added', added);
    },

    cssLinesRemovedStatusText() {
      const removed = this.currentActionCustomCssLinesChanged.removed || 0;
      return tr.pluralize('1 line removed', '{0} lines removed', removed);
    },

    isABTestLayer: mixins.isABTest,

    isPersonalizationLayer: mixins.isPersonalizationCampaign,

    isEditorLoadingFinal() {
      return (
        this.activeFrameLoadStatus ===
          EditorIframe.enums.IFrameLoadStatuses.READY ||
        this.activeFrameLoadStatus ===
          EditorIframe.enums.IFrameLoadStatuses.CANCELED ||
        this.activeFrameLoadStatus ===
          EditorIframe.enums.IFrameLoadStatuses.FAILED
      );
    },

    isTrackClickPresent() {
      return !this.campaignTrackClick
        ? false
        : this.currentActionAttributeChangesWithStatus.findIndex(
            attribute =>
              attribute.selector ===
              this.campaignTrackClick.event_filter.selector,
          ) === -1;
    },

    canAddNewChange() {
      return this.isEditorLoadingFinal && !this.currentActionRedirectChange;
    },
  },

  methods: {
    handleIframeClick(payload) {
      P13NUIActions.confirmNavigation(
        this.hasDirtyCustomCode,
        LayerExperimentHumanReadable.CHANGE_TYPES.custom_code,
        () => {
          CustomCodeActions.showCustomCodePanel(false);
          Editor.actions.setChangeBasedOnSelector(
            payload.selector,
            payload.selectorInfo,
          );
          this.showChangeEditorSidebar();
        },
      );
    },

    isLiveChange(change) {
      return change.status === LayerExperimentEnums.ChangeStatuses.LIVE;
    },

    isDraftChange(change) {
      return (
        change.status === LayerExperimentEnums.ChangeStatuses.MODIFIED ||
        change.status === LayerExperimentEnums.ChangeStatuses.NEW
      );
    },

    isDraft(attribute) {
      return false; // TODO(wfw): attribute-level status diffing
    },

    isLive(attribute) {
      return false; // TODO(wfw): attribute-level status diffing
    },

    isEventInChangeList(event) {
      const { selector } = event.event_filter;
      return !selector
        ? true
        : this.currentActionAttributeChangesWithStatus.find(
            change => change.selector === selector,
          );
    },

    getTrackClickChangeText(event) {
      const { view_id } = event;
      const { selector } = event.event_filter;
      return selector
        ? TrackClicksChangeActions.getMatchingEventChangeText(selector, view_id)
        : '';
    },

    openCustomCode(tab) {
      CustomCodeActions.showCustomCodePanel(true); // open panel
      CustomCodeActions.setCustomCodePanelTab(tab); // set panel correct tab (css or js)
      ui.$broadcast('editorShowCustomCodePanel'); // broadcast tab selection event to force the codemirror to rebuild properly
    },

    createDefaultChangeForEvent(event) {
      P13NUIActions.confirmNavigation(
        this.hasDirtyCustomCode,
        LayerExperimentHumanReadable.CHANGE_TYPES.custom_code,
        () => {
          const { selector } = event.event_filter;
          CustomCodeActions.showCustomCodePanel(false);
          Editor.actions.createAndSelectNewChange(selector);
          CustomCodeActions.resetCustomCodeTabsDirty();
          P13NUIActions.showChangeEditorSidebar(true);
        },
      );
    },
    /**
     * Selects the change
     * @param {Object} change
     */
    selectChange(change) {
      if (change.status !== this.changeStatuses.DELETED) {
        P13NUIActions.confirmNavigation(
          this.hasDirtyCustomCode,
          LayerExperimentHumanReadable.CHANGE_TYPES.custom_code,
          () => {
            CustomCodeActions.showCustomCodePanel(false);

            switch (change.type) {
              case this.changeTypes.INSERT_HTML:
                Editor.actions.setCurrentlyEditingInsertHTMLChange(change);
                this.showInsertHTMLSidebar();
                break;
              case this.changeTypes.INSERT_IMAGE:
                Editor.actions.setCurrentlyEditingInsertImageChange(change);
                this.showInsertImageSidebar();
                break;
              case this.changeTypes.REDIRECT:
                Editor.actions.setCurrentlyEditingRedirectChange(change);
                this.showRedirectEditorSidebar();
                break;
              default:
                Editor.actions
                  .setChangeBasedOnExistingChange(change)
                  .then(this.showChangeEditorSidebar(false));
            }
          },
        );
      }
    },

    /**
     * Show the change editor sidebar component via renderRegion.
     */
    showChangeEditorSidebar: P13NUIActions.showChangeEditorSidebar,
    showInsertHTMLSidebar: P13NUIActions.showInsertHTMLSidebar,
    showInsertImageSidebar: P13NUIActions.showInsertImageSidebar,
    showRedirectEditorSidebar: P13NUIActions.showRedirectEditorSidebar,

    /**
     * Highlight/unhighlight a specified change. Used when hovering over the change list in the sidebar.
     * @param {Change} change
     * @param {boolean} shouldHighlight Whether to highlight/unhighlight element
     */
    updateChangeHighlight(change, shouldHighlight) {
      if (shouldHighlight) {
        const changeHighlighterOptions = Editor.fns.getHighlighterOptionsForElementChange(
          change,
        );
        EditorIframe.actions.highlightElement({
          id: this.activeFrameId,
          dataOptlyId: changeHighlighterOptions.dataOptlyId,
          selector: changeHighlighterOptions.selector,
          type: HighlighterEnums.IFrameHighlightTypes.HOVERED,
        });
      } else {
        EditorIframe.actions.unhighlightElement({
          id: this.activeFrameId,
          dataOptlyId: change.id,
          selector: change.selector,
          type: HighlighterEnums.IFrameHighlightTypes.HOVERED,
        });
      }
    },

    createChange() {
      if (this.canAddNewChange) {
        ui.renderRegion('p13n-editor-sidebar', {
          // TODO: unravel circular dependency preventing ES6 import of this component
          component: require('../change_selector_sidebar').default, // eslint-disable-line
        });
      }
    },

    /**
     * @param {WidgetChange}
     */
    editWidgetChange(change) {
      // TODO(jordan): invariant for WidgetChange
      const widget = this.widgets[
        _.findKey(this.widgets, {
          plugin_id: change.widget_id,
        })
      ];

      if (!widget) {
        ui.showNotification({
          message: tr(
            "Error loading extension with id '{0}'. It could not be found.",
            change.widget_id,
          ),
          type: 'error',
        });
        return;
      }

      ui.renderRegion('p13n-editor-sidebar', {
        component: WidgetConfigSidebar,
        data: {
          _widget: widget,
          _editorIframeId: this.activeFrameId,
          _change: change,
        },
      });
    },

    handleChangeClick(change) {
      if (this.canEditChange(change)) {
        switch (change.type) {
          case this.changeTypes.ATTRIBUTE:
          case this.changeTypes.INSERT_HTML:
          case this.changeTypes.INSERT_IMAGE:
          case this.changeTypes.REDIRECT:
            this.selectChange(change);
            break;
          case this.changeTypes.WIDGET:
            this.editWidgetChange(change);
            break;
          default:
            break;
        }
      }
    },

    handleMouseEnter(change) {
      switch (change.type) {
        case this.changeTypes.ATTRIBUTE:
        case this.changeTypes.INSERT_HTML:
        case this.changeTypes.INSERT_IMAGE:
          this.updateChangeHighlight(change, true);
          break;
        default:
          break;
      }
    },

    handleMouseLeave(change) {
      switch (change.type) {
        case this.changeTypes.ATTRIBUTE:
        case this.changeTypes.INSERT_HTML:
        case this.changeTypes.INSERT_IMAGE:
          this.updateChangeHighlight(change, false);
          break;
        default:
          break;
      }
    },

    handleMouseEnterForTrackClick(event) {
      const { selector } = event.event_filter;
      const change = {
        id: guid(),
        selector,
      };
      // save id to recreate change for mouse leave event
      this.TrackClickChangeId = change.id;
      this.updateChangeHighlight(change, true);
    },

    handleMouseLeaveForTrackClick(event) {
      const { selector } = event.event_filter;
      const change = {
        id: this.TrackClickChangeId,
        selector,
      };
      this.updateChangeHighlight(change, false);
    },

    initializeIframe() {
      if (this.$iframeComponent && this.$iframeClickCallback) {
        this.$iframeComponent.$off(
          EditorIframe.enums.IFrameMessageTypes.CLICK,
          this.$iframeClickCallback,
        );
      }
      this.$iframeComponent = flux.evaluateToJS(
        EditorIframe.getters.iframeComponent(this.activeFrameId),
      );
      this.$iframeClickCallback = this.handleIframeClick.bind(this);
      if (this.$iframeComponent) {
        this.$iframeComponent.$on(
          EditorIframe.enums.IFrameMessageTypes.CLICK,
          this.$iframeClickCallback,
        );
      }
    },

    /**
     * This function will always return true if the current change is a redirect. If not, we’ll wait until
     * the editor is finished loading, then check if any changes for this variation are redirect changes.
     * If there are, this change should not be editable, because the changes would be applied to the url
     * that matches url targeting, but if the end user is redirected from that url, the changes wont apply.
     */
    canEditChange(change) {
      if (change.type === this.changeTypes.REDIRECT) {
        return true;
      }
      return this.isEditorLoadingFinal && !this.currentActionRedirectChange;
    },
  },

  created() {
    flux.bindVueValues(this, {
      activeFrameId: Editor.getters.activeFrameId,
      activeFrameLoadStatus: Editor.getters.activeFrameLoadStatus,
      currentViewHasClickEvents: TrackClicksChangeGetters.viewHasClickEvents,
      currentViewClickEvents: TrackClicksChangeGetters.campaignEventsForView,
      currentActionAttributeChangesWithStatus:
        Editor.getters.currentActionAttributeChangesWithStatus,
      currentActionCustomCode: Editor.getters.currentActionCustomCode,
      currentActionCustomCodeLinesChanged:
        Editor.getters.currentActionCustomCodeLinesChanged,
      currentActionCustomCss: Editor.getters.currentActionCustomCss,
      currentActionCustomCssLinesChanged:
        Editor.getters.currentActionCustomCssLinesChanged,
      currentActionWidgetChanges: Editor.getters.currentActionWidgetChanges,
      currentLayer: CurrentLayerGetters.layer,
      currentLayerExperimentOrSection:
        Editor.getters.currentLayerExperimentOrSection,
      editorType: Editor.getters.activeFrameEditorType,
      currentActionRedirectChange: Editor.getters.currentActionRedirectChange,
      hasDirtyCustomCode: CustomCodeGetters.hasDirtyCustomCode,
      widgets: Editor.getters.availableWidgets,
    });
  },

  ready() {
    const noOpFunction = () => {};
    this.initializeIframe();
    this.$on(
      Editor.constants.EmittedEventTypes.INITIALIZE_CHANGE_LIST,
      this.initializeIframe.bind(this),
    );
    ui.renderReactComponent(this, {
      component: HeaderComponent,
      el: this.$$.editorSidebarHeader,
      props: {
        // default to noOpFunction since onActionSelect is a required prop
        onActionSelect: this.$data.onActionSelect || noOpFunction,
      },
    });
  },

  beforeDestroy() {
    if (this.$iframeComponent) {
      this.$iframeComponent.$off(
        EditorIframe.enums.IFrameMessageTypes.CLICK,
        this.$iframeClickCallback,
      );
    }
  },
};

export default ChangeListSidebar;

import {
  enums as ViewEnums,
  constants as ViewConstants,
} from 'optly/modules/entity/view';
import { toImmutable } from 'optly/immutable';
import * as LayerConstants from 'optly/modules/entity/layer/constants';

const constants = {};

constants.targetingTypeItems = [
  {
    label: 'URL',
    value: LayerConstants.TargetingTypes.URL,
  },
  {
    label: 'Saved Pages',
    value: LayerConstants.TargetingTypes.SAVED_PAGES,
  },
];

constants.DEFAULT_URL_TARGETING_CONFIG = toImmutable({
  activation_type: ViewEnums.activationModes.IMMEDIATE,
  edit_url: '',
  includeConditions: toImmutable([ViewConstants.DEFAULT_URL_CONDITION]),
  excludeConditions: toImmutable([ViewConstants.DEFAULT_URL_CONDITION]),
  activation_code: null,
  api_name: '',
});

constants.DEFAULT_URL_TARGETING_CONFIG_SMART = toImmutable({
  activation_type: ViewEnums.activationModes.IMMEDIATE,
  edit_url: '',
  conditions: '["and",["or",{"value":"","match_type":"simple","type":"url"}]]',
  activation_code: null,
  api_name: '',
  deactivation_enabled: false,
  undo_on_deactivation: false,
  is_url_targeting: true,
});

constants.helpText = {
  edit_url:
    'The editor URL will be used if the page is loaded in the visual editor.',
  api_name: `Please provide a unique name so you can activate this page with an API call.
      We recommend creating the name using alphanumeric characters and underscores between words.`,
};

export default constants;

/**
 * Trim outer quotes for param expression - 'test' -> test, "test" -> test
 * @param str {String} Input value
 * @returns {String} String with outer quotes removed
 */
const trimParamQuotes = function(str) {
  if (typeof str !== 'string') {
    return str;
  }
  return str.replace(/^(['"]?)(.*)\1$/, '$2');
};
const tr = require('l10n-tr');

module.exports = function(value, singular, plural) {
  return tr.pluralize(
    trimParamQuotes(singular),
    trimParamQuotes(plural),
    value,
  );
};

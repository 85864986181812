import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import apiPage from 'optly/utils/api_page';

import { actionTypes } from '../action_types';

const ACTIVITY_PAGE_LIMIT = 20;
const DEFAULT_FILTERS = {
  $offset: 0,
  $order: 'created:desc',
  $limit: ACTIVITY_PAGE_LIMIT,
  category: ['experiment', 'project_visible', 'variation', 'goal'],
};

/**
 * activityFiltersStore
 * Responsible for the following state management:
 * TODO: fill in
 */
export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      resultsLength: null,
      filters: DEFAULT_FILTERS,
    });
  },

  initialize() {
    this.on(
      actionTypes.DASHBOARD_SET_NEXT_PAGE_ACTIVITY_FILTER,
      setNextPageFilter,
    );
    this.on(
      actionTypes.DASHBOARD_SET_ACTIVITY_PAGE_RESULTS_LENGTH,
      setPageResultsLength,
    );
    this.on(actionTypes.DASHBOARD_SELECT_ACTIVITY_FILTER, selectActivityFilter);
    this.on(actionTypes.CHANGE_CURRENT_PROJECT_ID, resetFilters);
    this.on(actionTypes.DASHBOARD_SET_TEST_PARAMETERS, setTestParameters);
  },
});

/**
 * Add a filter specified by the key value pair
 * Changing the filter changes the api results, which means
 * we should reset the offset.
 * @param {String} key
 * @param {String|Number} value
 */
function addFilter(state, key, value) {
  return state
    .setIn(['filters', key], value)
    .setIn(['filters', '$offset'], 0)
    .set('resultsLength', null);
}

/**
 * Remove a filter from the filter object specified by key
 * Changing the filter changes the api results, which means
 * we should reset the offset.
 * @param {String} key
 */
function removeFilter(state, key) {
  return state.withMutations(currentState => {
    currentState.deleteIn(['filters', key]);
    currentState.setIn(['filters', '$offset'], 0);
    currentState.set('resultsLength', null);
  });
}

/**
 * Set the filters to grab the next page of results
 * If no offset is provided, the next page is calculated
 * based on the current $offset and $limit
 * @param {object} payload
 */
function setNextPageFilter(state, payload) {
  if (payload.offset) {
    return state.setIn(['filters', '$offset'], payload.offset);
  }
  return state.set(
    'filters',
    toImmutable(apiPage.getNextPageFilters(state.get('filters').toJS())),
  );
}

/**
 * Set the size of the previous fetched page
 * @param {object} payload
 */
function setPageResultsLength(state, payload) {
  return state.set('resultsLength', payload.length);
}

/**
 * Set the state of the users selected filter
 * @param {object} payload
 */
function selectActivityFilter(state, payload) {
  if (payload.filterValue) {
    return addFilter(state, payload.filterKey, payload.filterValue);
  }
  return removeFilter(state, payload.filterKey);
}

/**
 * Reset the filters when switching projects
 * @param {object} payload
 */
function resetFilters(state, payload) {
  if (payload.id) {
    state = state.set('filters', toImmutable(DEFAULT_FILTERS));
    return addFilter(state, 'project_id', payload.id);
  }
  return state;
}

/**
 * Override some parameters for testing purposes.
 * @param {object} payload
 */
function setTestParameters(state, payload) {
  return state.setIn(['filters', '$limit'], payload.limit);
}

import { toImmutable } from 'optly/immutable';
import { getters as FeatureFlagGetters } from 'optly/modules/entity/feature_flag';
import { fns as CurrentProjectFns } from 'optly/modules/current_project';
import { getters as LayerExperimentGetters } from 'optly/modules/entity/layer_experiment';
import { getters as ExperimentationGroupGetters } from 'optly/modules/entity/experimentation_group';
import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';

export const currentlyEditingExperiment = [
  LayerExperimentGetters.entityCache,
  ['oasis/currentlyEditingExperiment', 'experimentId'],
  (entityCache, experimentId) => entityCache.get(experimentId),
];

export const currentlyEditingExperimentWrapper = [
  currentlyEditingExperiment,
  ExperimentationGroupGetters.entityToGroupMap,
  (experiment, entityToGroupMap) => {
    const res = CurrentProjectFns.wrapFullStackLayerExperiments(
      toImmutable([experiment]),
      entityToGroupMap,
    );
    return res.get(0);
  },
];

export const currentlyEditingExperimentFeature = [
  currentlyEditingExperiment,
  FeatureFlagGetters.entityCache,
  (experiment, entityCache) =>
    entityCache.get(experiment.get('feature_flag_id')),
];

export const activeTab = ['oasis/currentlyEditingExperiment', 'tab'];

export const orderedVariations = [
  CurrentLayerGetters.currentSingleExperimentFromAllExperimentsPointingToLayer,
  experiment => {
    if (experiment) {
      return experiment.get('variations');
    }
    return toImmutable([]);
  },
];

export default {
  currentlyEditingExperiment,
  currentlyEditingExperimentWrapper,
  currentlyEditingExperimentFeature,
  activeTab,
  orderedVariations,
};

/**
 * Provides functionality for serializing/unserializing a time and day interval
 * Also used for testing whether a day is in an interval
 */

/**
 * @private
 * @type {string}
 */
const DELIM = '_';
/**
 * @private
 * @type {string}
 */
const DAYS_DELIM = ',';
/**
 * @private
 * @type {string}
 */
const TIME_DELIM = ':';

/**
 * Serializes a time interval
 *
 * start_time and end_time must be in 24 hour 'HH:MM' format
 * @param data { { start_time: string, end_time: string, days: array.<string> } }
 * @return {string} ex: '10:00_15:00_monday,tuesday,wednesday'
 */
exports.serialize = function(data) {
  return (
    data.start_time + DELIM + data.end_time + DELIM + data.days.join(DAYS_DELIM)
  );
};

/**
 * Deserializes a time interval
 *
 * @param serialized {string} ex: '10:00_15:00_monday,tuesday,wednesday'
 * @return { { start_time: string, end_time: string, days: Array } }
 */
exports.deserialize = function(serialized) {
  const parts = serialized.split(DELIM);
  if (parts.length !== 3) {
    throw new Error(`Invalid time and day string ${serialized}`);
  }
  const days = parts[2].split(DAYS_DELIM);

  return {
    start_time: parts[0],
    end_time: parts[1],
    days,
  };
};

/**
 * Takes a 24h time string 'HH:MM' and converts it to minutes
 *
 * @param {string} timeStr
 * @return {number}
 */
exports.timeStringToMinutes = function(timeStr) {
  const parts = timeStr.split(TIME_DELIM);
  if (parts.length !== 2) {
    throw new Error(
      `timeAndDayInterval.timeStringToMinutes: Invalid time string ${timeStr}`,
    );
  }

  return parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
};

/**
 * Tests whether the supplied date falls within the given interval
 * Uses local time
 *
 * Note: does not take into account seconds, so 1:00:30pm is equivalent
 * to 1:00pm in the comparison.
 *
 * @param intervalStr {string} serialized interval string
 * @param currentDate {Date}
 * @return {boolean}
 */
exports.test = function(intervalStr, currentDate) {
  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  const interval = exports.deserialize(intervalStr);
  const startTime = exports.timeStringToMinutes(interval.start_time);
  const endTime = exports.timeStringToMinutes(interval.end_time);
  const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
  const currentDay = days[currentDate.getDay()];

  return (
    currentTime >= startTime &&
    currentTime <= endTime &&
    interval.days.includes(currentDay)
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import { Input, Textarea } from '@optimizely/axiom';

import Immutable from 'optly/immutable';

import { connect } from 'core/ui/decorators';

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { enums as LayerEnums } from 'optly/modules/entity/layer';

import {
  actions as LayerSettingsModuleActions,
  getters as LayerSettingsModuleGetters,
} from '../../modules/layer_settings';

const DESCRIPTION_TEXT = {
  [LayerEnums.campaignTypes.SINGLE_EXPERIMENT]: {
    NOUN: 'experiment',
    VERB: 'testing',
  },
  [LayerEnums.campaignTypes.MULTIVARIATE]: {
    NOUN: 'experiment',
    VERB: 'testing',
  },
  [LayerEnums.campaignTypes.P13N_CAMPAIGN]: {
    NOUN: 'campaign',
    VERB: 'personalizing',
  },
  [LayerEnums.campaignTypes.MULTIARMED_BANDIT]: {
    NOUN: 'optimization',
    VERB: 'optimizing',
  },
};

@connect({
  currentLayer: CurrentLayerGetters.layer,
  description: LayerSettingsModuleGetters.description,
  name: LayerSettingsModuleGetters.name,
})
class LayerSettings extends React.Component {
  static propTypes = {
    campaignType: PropTypes.oneOf(Object.keys(LayerEnums.campaignTypes)),
    currentLayer: PropTypes.instanceOf(Immutable.Map),
    description: PropTypes.string,
    name: PropTypes.string,
    nameError: PropTypes.string,
    onNameChange: PropTypes.func,
  };

  static defaultProps = {
    campaignType: LayerEnums.campaignTypes.SINGLE_EXPERIMENT,
    currentLayer: null,
    description: '',
    name: '',
    nameError: null,
    onNameChange: () => {},
  };

  constructor(props) {
    super(props);

    const { campaignType, currentLayer } = props;

    const layerType = currentLayer && currentLayer.get('type');

    let computedType = campaignType;

    if (layerType) {
      switch (layerType) {
        case LayerEnums.type.PERSONALIZATION:
          computedType = LayerEnums.campaignTypes.P13N_CAMPAIGN;
          break;
        case LayerEnums.type.MULTIVARIATE:
          computedType = LayerEnums.campaignTypes.MULTIVARIATE;
          break;
        case LayerEnums.type.MULTIARMED_BANDIT:
          computedType = LayerEnums.campaignTypes.MULTIARMED_BANDIT;
          break;
        default:
          computedType = LayerEnums.campaignTypes.SINGLE_EXPERIMENT;
          break;
      }
    }

    this.editMode = !!currentLayer;
    this.descriptionWords = DESCRIPTION_TEXT[computedType];
    this.nameInputRef = React.createRef();

    LayerSettingsModuleActions.setLayerName(
      (currentLayer && currentLayer.get('name')) || '',
    );
    LayerSettingsModuleActions.setLayerDescription(
      (currentLayer && currentLayer.get('description')) || '',
    );
  }

  componentDidMount() {
    this.nameInputRef.current.focus();
  }

  componentWillUnmount() {
    LayerSettingsModuleActions.setLayerName(null);
    LayerSettingsModuleActions.setLayerDescription(null);
  }

  updateLayerDescription = event => {
    LayerSettingsModuleActions.setLayerDescription(event.target.value);
  };

  updateLayerName = event => {
    const { onNameChange } = this.props;

    const name = event.target.value;

    onNameChange(name);
    LayerSettingsModuleActions.setLayerName(name);
  };

  renderDescriptionLabel = () => {
    return <span>Description</span>;
  };

  render() {
    const { description, name, nameError } = this.props;

    return (
      <div>
        <fieldset>
          <ol className="lego-form-fields">
            <li className="lego-form-field__item">
              <Input
                className="lego-text-input width--1-2"
                onChange={this.updateLayerName}
                label="Name"
                isRequired={true}
                placeholder="Add a name"
                ref={this.nameInputRef}
                testSection="layer-name"
                type="text"
                value={name || ''}
              />
              {nameError && (
                <div
                  className="lego-form-note lego-form-note--bad-news"
                  data-test-section="error-campaign-name">
                  {nameError}
                </div>
              )}
            </li>
          </ol>
        </fieldset>
        <fieldset>
          <ol className="lego-form-fields">
            <li className="lego-form-field__item">
              <Textarea
                className="lego-textarea"
                label={this.renderDescriptionLabel()}
                data-test-section="campaign-description"
                name="experiment_description"
                onChange={this.updateLayerDescription}
                placeholder="Add a description"
                value={description || ''}
              />
            </li>
          </ol>
        </fieldset>
      </div>
    );
  }
}

export default LayerSettings;

import _ from 'lodash';
import $ from 'jquery';

import cloneDeep from 'optly/clone_deep';
import handleError from 'optly/modules/rest_api/api/handle_error';

/**
 * Config Options
 * config.headers {Object}
 * config.baseUrl {String}
 *
 * @constructor
 * @param {Object} config
 */
export function Api(config) {
  // config
  this._config = config || {};

  // stack of [('one' | 'all'), <noun>, <id?>]
  // ex [['all', 'experiments'] or ['one', 'projects', 4001]]
  this._stack = [];

  /**
   * Array of filters to apply to URL
   * ex: /api/v1/projects/4001/experiments?filter=status:Started&filter=project_id:55
   * @var Array.<{field: string, value: string}>
   */
  this._filter = [];

  /**
   * Array of orders to apply to URL
   * ex: /api/v1/projects/4001/experiments?order=created:desc
   * @var Array.<{field: string, value: string}>
   */
  this._order = [];

  /**
   * Limit to apply to URL
   * ex: /api/v1/projects/4001/experiments?limit=100
   * @var Number the number of limit records for limit/offset pagination
   */
  this._limit = null;

  /**
   * Offset to apply to URL
   * ex: /api/v1/projects/4001/experiments?offset=100
   * @var Number the number of offset records for limit/offset pagination
   */
  this._offset = null;

  /**
   * Ids_only setting to apply to URL
   * ex: /api/v1/projects/4001/experiments?ids_only=true
   * @var string should be null or 'true'
   */
  this._ids_only = null;

  /**
   * Array of additional HTTP parameters to apply to URL
   * ex: /api/v1/projects/4001/experiments?param1=value1&param2=value2
   * @var Array.<{field: string, value: string}>
   */
  this._extra_params = [];
}

/**
 * Class level method to make an ajax request
 * Exists at class level for ease of testability
 *
 * Opts:
 * 'data' {Object}
 * 'type' {String} 'GET', 'PUT', 'POST', 'DELETE'
 * 'url' {String}
 * 'entityName' {String}
 *
 * @param {Object} opts
 * @param {Object=} headers
 * @return {Deferred}
 */
Api.request = function(opts, headers) {
  if (!opts.type || !opts.url) {
    throw new Error(
      'Must supply `opts.type` and `opts.url` to Api.request(opts)',
    );
  }

  const ajaxOpts = {
    type: opts.type,
    url: opts.url,
    contentType: 'application/json',
    // Provide no default ajax error handler
    // Instead, use the one defined below in .fail() handler below
    error: null,
    xhrFields: {
      withCredentials: true,
    },
  };

  if (headers) {
    ajaxOpts.headers = headers;
  }

  if (opts.data) {
    ajaxOpts.data = JSON.stringify(opts.data);
    ajaxOpts.dataType = 'json';
  }

  // Use this to preserve the original request URL from any jqXHR objects
  $.ajaxPrefilter((options, originalOptions, jqXHR) => {
    jqXHR.originalRequestOptions = originalOptions;
  });

  return $.ajax(ajaxOpts) // eslint-disable-line fetch/no-jquery
    .fail((jqXHR, textStatus) => {
      handleError.handleAPIRequestFailure(jqXHR, textStatus, opts.entityName);
    });
};

/**
 * Appends '/{noun}/{id}' to the endpoint
 * @param {string} noun
 * @param {number} id
 * @return {Api}
 */
Api.prototype.one = function(noun, id) {
  this._stack.push(['one', noun, id]);
  return this;
};

/**
 * Appends '/{noun}' to the endpoint
 * @param {string} noun
 * @return {Api}
 */
Api.prototype.all = function(noun) {
  this._stack.push(['all', noun]);
  return this;
};

/**
 * Adds property to filter
 *
 * @param {String|Object} keyOrObject single key (to associate with val) or object of key/value pairs
 * @param {String=} val Value to match against
 * @return {Api}
 */
Api.prototype.filter = function(keyOrObject, val) {
  if (this._getMode() !== 'all') {
    throw new Error("ApiService Error: .filter() must be called in 'all' mode");
  }

  let filters = keyOrObject;
  if (typeof keyOrObject === 'string') {
    filters = {};
    // use 'true' if no value is provided
    filters[keyOrObject] = val;
  }

  _.forOwn(filters, (value, key) => {
    if (Array.isArray(value)) {
      value.forEach(el => {
        this._filter.push([key, el]);
      });
    } else {
      this._filter.push([key, value]);
    }
  });

  return this;
};

/**
 * Adds property to HTTP params
 *
 * @param {String|Object} keyOrObject single key (to associate with val) or object of key/value pairs
 * @param {String=} val Value to match against
 * @return {Api}
 */
Api.prototype.param = function(keyOrObject, val) {
  let params = keyOrObject;
  if (typeof keyOrObject === 'string') {
    params = {};
    // use 'true' if no value is provided
    params[keyOrObject] = val;
  }

  _.forOwn(params, (value, key) => {
    if (Array.isArray(value)) {
      value.forEach(el => {
        this._extra_params.push([key, el]);
      });
    } else {
      this._extra_params.push([key, value]);
    }
  });

  return this;
};

/**
 * Adds property to order
 *
 * @param {Array|String} order string or array of key/value pair strings
 * @return {Api}
 */
Api.prototype.order = function(order) {
  if (this._getMode() !== 'all') {
    throw new Error("ApiService Error: .order() must be called in 'all' mode");
  }

  if (order) {
    // If a string is passed in, coerce to an array
    if (typeof order === 'string') {
      order = [order];
    }

    order.forEach(val => {
      this._order.push(val);
    });
  }

  return this;
};

/**
 * Adds property to offset
 *
 * @param {Object} offset integer specifying page offset
 * @return {Api}
 */
Api.prototype.offset = function(offset) {
  if (this._getMode() !== 'all') {
    throw new Error("ApiService Error: .offset() must be called in 'all' mode");
  }

  this._offset = offset;
  return this;
};

/**
 * Adds property to filter
 *
 * @param {Object} Object single key (to associate with val) or object of key/value pairs
 * @return {Api}
 */
Api.prototype.limit = function(limit) {
  if (this._getMode() !== 'all') {
    throw new Error("ApiService Error: .limit() must be called in 'all' mode");
  }

  this._limit = limit;
  return this;
};

/**
 * Adds property ids_only which instructs the API to only include object IDs rather than all attributes
 *
 * @return {Api}
 */
Api.prototype.idsOnly = function() {
  this._ids_only = 'true';
  return this;
};

/**
 * Make POST request to current endpoint
 * @param {Object} data
 * @return {Deferred}
 */
Api.prototype.post = function(data) {
  if (this._getMode() !== 'all') {
    throw new Error("ApiService Error: .post() must be called in 'all' mode");
  }

  const opts = {
    type: 'POST',
    data,
    url: this._getUrl(),
    entityName: this._getEntity(),
  };

  return Api.request(opts, this._config.headers);
};

/**
 * Update with data
 * @param {Object} data
 * @return {Deferred}
 */
Api.prototype.put = function(data) {
  const opts = {
    type: 'PUT',
    data,
    url: this._getUrl(),
    entityName: this._getEntity(),
  };

  return Api.request(opts, this._config.headers);
};

/**
 * Performs a GET request to the current endpoint the instance
 * is set to.
 *
 * @return {Deferred}
 */
Api.prototype.get = function() {
  const opts = {
    type: 'GET',
    url: this._getUrl(),
    entityName: this._getEntity(),
  };

  return Api.request(opts, this._config.headers);
};

/**
 * Performs a DELETE request to the current endpoint
 *
 * @return {Deferred}
 */
Api.prototype.delete = function() {
  // TODO(jordan): should .delete() be callable after .all()
  if (this._getMode() !== 'one') {
    throw new Error("ApiService Error: .delete() must be called in 'one' mode");
  }

  const opts = {
    type: 'DELETE',
    url: this._getUrl(),
    entityName: this._getEntity(),
  };

  return Api.request(opts, this._config.headers);
};

/**
 * Builds the url from this._stack
 * @private
 */
Api.prototype._getUrl = function() {
  let url = this._config.baseUrl || '';
  const params = [];

  url = this._stack.reduce((memo, item) => {
    const mode = item[0]; // 'one' or 'all'
    memo += `/${item[1]}`; // noun
    if (mode === 'one') {
      memo += `/${item[2]}`; // id
    }
    return memo;
  }, url);

  if (this._filter.length > 0) {
    this._filter.forEach(tuple => {
      params.push(`filter=${tuple[0]}:${tuple[1]}`);
    });
  }

  if (this._order.length > 0) {
    this._order.forEach(order => {
      params.push(`order=${order}`);
    });
  }

  if (this._limit) {
    params.push(`limit=${this._limit}`);
  }

  if (this._ids_only) {
    params.push(`ids_only=${this._ids_only}`);
  }

  if (this._offset) {
    params.push(`offset=${this._offset}`);
  }

  if (this._extra_params.length > 0) {
    this._extra_params.forEach(tuple => {
      params.push(`${tuple[0]}=${tuple[1]}`);
    });
  }

  if (params.length > 0) {
    url += '?';
    url += params.join('&');
  }

  return url;
};

/**
 * Gets the entity of the url
 * @private
 */
Api.prototype._getEntity = function() {
  return this._stack[this._stack.length - 1][1];
};

/**
 * Gets the mode of the request ('one' | 'all')
 * @private
 */
Api.prototype._getMode = function() {
  return this._stack[this._stack.length - 1][0];
};

/**
 * @param {{
 *    headers: object,
 *    baseUrl: string,
 * }} config
 */
export function create(config) {
  return additionalConfig =>
    new Api(_.merge(cloneDeep(config), additionalConfig));
}

export default {
  // expose the Api constructor
  Api,
  // the create function used to make an api instance
  create,
};

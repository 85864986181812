import flux from 'core/flux';

import actionTypes from './action_types';

/**
 * Flag an error for a group
 * @param {String} group
 * @param {Boolean} error
 */
export function flagError(type, error) {
  flux.dispatch(actionTypes.FLAG_ERROR, {
    type,
    error,
  });
}

/**
 * Reset all error flags to their initial state
 * @param {String} group
 * @param {Boolean} error
 */
export function resetErrorFlags() {
  flux.dispatch(actionTypes.RESET_ERROR_FLAGS);
}

/**
 * Sets the entire exclusion group being created or edited
 * @param {Immutable.Map} group
 */
export function setGroup(group) {
  flux.dispatch(actionTypes.SET_GROUP, {
    group,
  });
}

export default {
  flagError,
  resetErrorFlags,
  setGroup,
};

import _ from 'lodash';
import Vue from 'vue';
import { api as SegmentJSAPI } from '@optimizely/segment-js';

import flux from 'core/flux';
import ui from 'core/ui';

import Editor from 'bundles/p13n/modules/editor';
import { getters as EditorIframeGetters } from 'bundles/p13n/modules/editor_iframe';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import { ChangeEditorSidebar as ChangeEditorSidebarMixin } from 'bundles/p13n/modules/ui/mixins';
import InsertOperatorDropdown from 'bundles/p13n/components/insert_operator_dropdown';
import SelectorInput from 'bundles/p13n/components/selector_input';

import PositionSelect from './position_select';
import ComponentHtmlTemplate from './template.html';

const layoutComponent = {
  replace: true,

  template: ComponentHtmlTemplate,

  data: {
    changeStatuses: LayerExperimentEnums.ChangeStatuses,
    selectorInputTypes: Editor.constants.SelectorInputTypes,
  },

  computed: {
    /**
     * Whether the element in the editor is removed
     * @returns {boolean}
     */
    isElementRemoved() {
      return !!this.currentlyEditingChange.attributes.remove;
    },

    /**
     * Whether the element in the editor is hidden
     * @returns {boolean}
     */
    isElementHidden() {
      return !!this.currentlyEditingChange.attributes.hide;
    },

    /**
     * Whether the element in the editor is visible
     * @returns {boolean}
     */
    isElementVisible() {
      this.isElementHidden; // eslint-disable-line
      this.isElementRemoved; // eslint-disable-line
      return !this.isElementHidden && !this.isElementRemoved;
    },

    /**
     * Whether or not the rearrange selector input should be in read-only mode.
     * If any other selector input component is active, this one should be read-only.
     * @returns {boolean}
     */
    isRearrangeSelectorInputReadOnly() {
      return (
        this.isEditorReadOnly ||
        this.currentSelectorType ===
          Editor.constants.SelectorInputTypes.ELEMENT_SELECTOR
      );
    },

    /**
     * Compute a map of all attribute changes and the appropriate classes to use for this section
     * for their current status (dirty, draft, live).
     * @returns {{rearrange: *, visibility: *}}
     */
    statusClassForAttributes() {
      return {
        rearrange: this.getStatusClassForAttribute([
          this.changeStatusMap.rearrange,
        ]),
        visibility: this.getStatusClassForAttribute([
          this.changeStatusMap.attributes.hide,
          this.changeStatusMap.attributes.remove,
        ]),
        position: this.getStatusClassForAttribute([
          this.changeStatusMap.css.position,
        ]),
      };
    },
  },

  methods: {
    handleOperatorUpdate(operator) {
      Editor.actions.setChangeRearrangeProperty('operator', operator);
    },

    /**
     * Sets the rearrange operator in the currently editing change store and then applies changes.
     * @param {string} operator
     */
    handleRearrangeOperatorChanged(operator) {
      Editor.actions.setChangeRearrangeProperty('operator', operator);
      Editor.actions.applyCurrentlyEditingChange();
    },

    /**
     * Hide the currently selected element from the DOM
     */
    hideElement() {
      SegmentJSAPI.track('[Element Change] Layout Hidden');
      this.setAttribute('hide', true);
      this.setAttribute('remove', null);
    },

    // Set rearrange operator value
    initializeInputFields() {
      // Allow a cycle to render before updating the selector components so that the v-with property can update.
      Vue.nextTick(() => {
        // Because this fires on nextTick, make sure the component exists before emitting on it.
        this.$.rearrangeInsertOperatorDropdown && this.$.rearrangeInsertOperatorDropdown.handleOperatorUpdate();  // eslint-disable-line
      });
    },

    /**
     * Remove the currently selected element from the DOM
     */
    removeElement() {
      SegmentJSAPI.track('[Element Change] Layout Removed');
      this.setAttribute('hide', null);
      this.setAttribute('remove', true);
    },

    /**
     * Show the currently selected element from the DOM
     */
    showElement() {
      SegmentJSAPI.track('[Element Change] Layout Visible');
      this.setAttribute('hide', null);
      this.setAttribute('remove', null);
    },
  },

  created() {
    flux.bindVueValues(this, {
      activeFrameId: Editor.getters.activeFrameId,
      changeStatusMap: Editor.getters.changeStatusMap(),
      currentlyEditingChange: Editor.getters.currentlyEditingChange,
      currentSelectorType: Editor.getters.currentSelectorType,
      elementSelectorEnabled: Editor.getters.changeEditorElementSelectorEnabled,
      isEditingSelector: Editor.getters.changeEditorIsEditingSelector,
      isEditorReadOnly: Editor.getters.isEditorReadOnly,
      rearrangeSelector: Editor.getters.currentRearrangeSelector,
    });
  },

  ready() {
    // Observe the currentlyEditingChange and update the change input fields when it changes. This is
    // preferred to using v-value because we can explicitly not update the field that is currently being typed in.
    this.__unwatchCurrentlyEditingChange = flux.observe(
      Editor.getters.currentlyEditingChange,
      this.initializeInputFields.bind(this),
    );

    ui.renderReactComponent(this, {
      component: InsertOperatorDropdown,
      el: this.$$.insertOperatorDropdown,
      props: {
        onOperatorChangeCallback: this.handleRearrangeOperatorChanged.bind(
          this,
        ),
      },
      dataBindings: {
        initialOperator: Editor.getters.currentlyEditingChangeRearrangeOperator,
      },
    });

    ui.renderReactComponent(this, {
      component: PositionSelect,
      el: this.$$['position-select'],
      props: {
        onChange: newValue => {
          Editor.actions.setChangeCSSProperty('position', newValue);
          Editor.actions.applyCurrentlyEditingChange();
        },
        testSection: 'position-select',
      },
      dataBindings: {
        value: Editor.getters.cssValueFromChangeOrElement('position'),
      },
    });

    // Mount the SelectorInput React component for the Change Element's SelectorInputTypes.REARRANGE_SELECTOR
    ui.renderReactComponent(this, {
      component: SelectorInput,
      el: this.$$.rearrangeSelectorInput,
      dataBindings: {
        currentSelectorHighlightOptions:
          Editor.getters.currentlyEditingChangeHighlighterOptions,
        iframe: EditorIframeGetters.iframeComponent(this.activeFrameId),
        isDisabled: Editor.getters.selectorInputIsDisabled(
          Editor.constants.SelectorInputTypes.REARRANGE_SELECTOR,
        ),
        value: Editor.getters.currentlyEditingChangeRearrangeSelector,
      },
      props: {
        onChange: Editor.actions.onChangeSelectorInput,
        onEditStart: _.partial(
          Editor.actions.toggleElementSelection,
          true,
          Editor.constants.SelectorInputTypes.REARRANGE_SELECTOR,
        ),
        onEditStop: _.partial(
          Editor.actions.toggleElementSelection,
          false,
          Editor.constants.SelectorInputTypes.REARRANGE_SELECTOR,
        ),
        selectorInputType:
          Editor.constants.SelectorInputTypes.REARRANGE_SELECTOR,
      },
    });
  },

  beforeDestroy() {
    if (this.__unwatchCurrentlyEditingChange) {
      this.__unwatchCurrentlyEditingChange();
    }
  },
};

export default _.merge({}, ChangeEditorSidebarMixin, layoutComponent);

import Immutable from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';

import { fns as FeatureFns } from 'optly/modules/entity/feature_flag';

import EnvironmentsStatusOverview from 'bundles/p13n/components/environments/environments_status_overview';

function FeatureStatusWithEnvironments({ feature, featureEnvironmentsInfo }) {
  return (
    <EnvironmentsStatusOverview
      isArchived={feature.get('archived')}
      environmentsInfo={featureEnvironmentsInfo}
      getStatusDescription={environment =>
        `${FeatureFns.formatPercentageIncluded(
          environment.get('percentage_included'),
        )}%`
      }
    />
  );
}

FeatureStatusWithEnvironments.propTypes = {
  /* Feature API entity */
  feature: PropTypes.instanceOf(Immutable.Map).isRequired,
  /*
   *  List of Maps representing the status of this feature in each
   *  environment, sorted by environment priority.
   */
  featureEnvironmentsInfo: PropTypes.instanceOf(Immutable.List).isRequired,
};

export default FeatureStatusWithEnvironments;

import Nuclear from 'nuclear-js';

import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

/**
 * componentDataStore
 */
export default Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({});
  },

  initialize() {
    this.on(actionTypes.LEGACY_HOLDBACK_SELECTOR, setLayerHoldback);
    this.on(actionTypes.LEGACY_CLEAR_HOLDBACK_SELECTOR, clearLayerHoldback);
    this.on(actionTypes.LEGACY_LAYER_NAME, setLayerName);
    this.on(actionTypes.LEGACY_LAYER_DESCRIPTION, setLayerDescription);
  },
});

/**
 * Handler for actionTypes.LEGACY_LAYER_NAME
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.name
 */
function setLayerName(state, payload) {
  return state.set('name', payload.name);
}

/**
 * Handler for actionTypes.LEGACY_LAYER_DESCRIPTION
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.description
 */
function setLayerDescription(state, payload) {
  return state.set('description', payload.description);
}

/**
 * Handler for actionTypes.LEGACY_HOLDBACK_SELECTOR
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.holdback
 */
function setLayerHoldback(state, payload) {
  return state.set('holdback', payload.holdback);
}

function clearLayerHoldback(state) {
  return state.set('holdback', null);
}

export {
  clearLayerHoldback,
  setLayerName,
  setLayerHoldback,
  setLayerDescription,
};

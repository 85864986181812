export const routesCompleted = ['performanceTracking', 'routesCompleted'];

export const frontendRenderStartTime = [
  'performanceTracking',
  'frontendRenderStartTime',
];

export const routeStartTime = ['performanceTracking', 'routeStartTime'];

export const visualRoutesChanged = [
  'performanceTracking',
  'visualRoutesChanged',
];

export default {
  routesCompleted,
  frontendRenderStartTime,
  routeStartTime,
  visualRoutesChanged,
};

export const entries = ['localStorageMeta', 'entries'];
export const lruKeyList = ['localStorageMeta', 'lruKeyList'];

/**
 * Calculate total sum of size of entries in store
 */
export const sizeOfAllEntries = [
  entries,
  lsEntries => {
    let totalSize = 0;
    if (lsEntries) {
      lsEntries.forEach(entry => {
        totalSize += entry.get('size');
      });
    }
    return totalSize;
  },
];

/**
 * given a local storage meta store key , find its computed size
 * @param {String} entry key in meta store
 */
export function entrySize(keyToFind) {
  return [
    entries,
    lsEntries => {
      if (lsEntries && lsEntries.has(keyToFind)) {
        return lsEntries.getIn([keyToFind, 'size']);
      }
      return 0;
    },
  ];
}

/**
 * Return true/false whether a key is managed by the meta store
 * @param keyToFind
 * @returns {*[]}
 */
export function isManagedKey(keyToFind) {
  return [entries, lsEntries => lsEntries && lsEntries.has(keyToFind)];
}

/**
 * given a local storage meta store key , find its corresponding dateModified timestamp
 * @param {String} entry key in meta store
 */
export function entryDateModified(keyToFind) {
  return [
    entries,
    lsEntries => {
      if (lsEntries && lsEntries.has(keyToFind)) {
        return lsEntries.getIn([keyToFind, 'dateModified']);
      }
    },
  ];
}

/**
 * Return a json serialized form of the meta store
 * @returns {String} json stringified version of meta store
 */
export const serializedMetaStore = [
  entries,
  lruKeyList,
  (lsEntries, lsLruKeyList) => {
    const currentStoreState = {
      entries: lsEntries && lsEntries.toJS(),
      lruKeyList: entries && lsLruKeyList.toJS(),
    };
    return JSON.stringify(currentStoreState);
  },
];

export default {
  entries,
  entryDateModified,
  entrySize,
  isManagedKey,
  lruKeyList,
  serializedMetaStore,
  sizeOfAllEntries,
};

const keyMirror = require('optly/utils/key_mirror');

module.exports = keyMirror({
  START_PAUSING: null,
  START_PUBLISHING: null,
  START_STARTING: null,
  FINISH_PAUSING: null,
  FINISH_PUBLISHING: null,
  FINISH_STARTING: null,
});

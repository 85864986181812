import Nuclear from 'nuclear-js';

import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';
import constants from '../constants';

const items = [
  { title: 'Welcome', id: 'welcome' },
  { title: 'Using this tutorial', id: 'context' },
  { title: 'Set up tutorial', id: 'setup' },
  { title: 'Feature flags', id: 'rollout' },
  { title: 'Feature tests', id: 'experiment' },
  { title: 'Metrics', id: 'results' },
  { title: 'Thank You', id: 'thanks', isHidden: true },
];

const onboardingStore = Nuclear.Store({
  getInitialState() {
    // holds a map of experimentId => reach stats
    return toImmutable({
      steps: items,
      currentStep: 0,
      currentLanguage: 'node',
      lastCompletedStep: -1,
      finishedSteps: [],
      datafileUrl: '',
      datafileUploaded: false,
      feedbackSubmitted: false,
      projectType: constants.PROJECT_TYPE_FLAGS,
    });
  },

  initialize() {
    this.on(actionTypes.ONBOARDING_GOTO_STEP, gotoStep);
    this.on(actionTypes.ONBOARDING_NEXT_STEP, nextStep);
    this.on(actionTypes.ONBOARDING_SET_LANGUAGE, setLanguage);
    this.on(actionTypes.ONBOARDING_SET_PROJECT_TYPE, setProjectType);
    this.on(actionTypes.ONBOARDING_SET_DATAFILE_URL, setDatafileUrl);
    this.on(actionTypes.ONBOARDING_LOAD_PROGRESS, loadProgress);
    this.on(actionTypes.ONBOARDING_FEEDBACK_SUBMITTED, feedbackSubmitted);
  },
});

function feedbackSubmitted(state, action) {
  return state.set('feedbackSubmitted', true);
}

function loadProgress(
  state,
  { datafileUrl, datafileUploaded, finishedSteps, feedbackSubmitted },
) {
  return state
    .set('datafileUrl', datafileUrl)
    .set('datafileUploaded', datafileUploaded)
    .set('feedbackSubmitted', feedbackSubmitted || false)
    .set('finishedSteps', toImmutable(finishedSteps));
}

function setDatafileUrl(state, { url }) {
  return state.set('datafileUrl', url).set('datafileUploaded', true);
}

function gotoStep(state, { step }) {
  if (Number.isNaN(step) || step >= items.length || step < 0) {
    return state;
  }
  return state.set('currentStep', step);
}

function setLanguage(state, { language }) {
  if (constants.AVAILABLE_LANGUAGE_NAMES.indexOf(language) === -1) {
    return state;
  }
  return state.set('currentLanguage', language);
}

function setProjectType(state, projectType) {
  return state.set('projectType', projectType);
}

function nextStep(state) {
  const currentStep = state.get('currentStep');
  const newCurrentStep = currentStep + 1;
  const stepId = `${state.get('projectType')}-${state.get(
    'currentLanguage',
  )}-${currentStep}`;
  return state
    .set('currentStep', newCurrentStep)
    .update('finishedSteps', steps =>
      steps.indexOf(stepId) > -1 ? steps : steps.push(stepId),
    );
}

export default onboardingStore;

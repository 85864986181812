import PropTypes from 'prop-types';
import React from 'react';

import api from './api';

export default class ErrorBoundary extends React.Component {
  static displayName = 'ErrorBoundary';

  static propTypes = {
    alternateContent: PropTypes.node,
    children: PropTypes.node.isRequired,
    onCatch: PropTypes.func,
  };

  static defaultProps = {
    alternateContent: null,
    onCatch: null,
  };

  state = {
    hasError: false,
  };

  /**
   * @method componentDidCatch
   * @param {Object} error - Javascript error thrown
   * @param {Object} errorInfo - React-specific information about the error
   * @description Lifecycle method that catches errors thrown by the component's children
   */
  componentDidCatch(error, errorInfo) {
    const { onCatch } = this.props;

    this.setState(() => ({
      hasError: true,
    }));

    if (onCatch) {
      onCatch();
    }

    /**
     * PLEASE NOTE: Direct use of the Sentry SDK is discouraged except in
     * specific cases. To dispatch events to Sentry, please use the Sentry SDK
     * wrapper at optly/modules/sentry.
     */
    api.withScope(scope => {
      // Add a fingerprint containing the React component stacktrace
      scope.setFingerprint([
        this.condenseComponentStack(errorInfo.componentStack),
        error.message,
      ]);

      scope.setExtra('react_component_stack', errorInfo.componentStack);

      api.captureException(error, errorInfo);
    });
  }

  /**
   * @method condenseComponentStack
   * @param {String} componentStack - component stack string provided by React
   * @returns {String} dot-separated list of component names in hierarchy
   * @description Condenses a multiline component stacktrace string into a dot-separated string
   *
   * Converts this:
   * in BuggyComponent
   *   in ErrorBoundary
   *   in Page
   *
   * Into this:
   * Page.ErrorBoundary.BuggyComponent
   */
  condenseComponentStack = componentStack =>
    componentStack
      .trim()
      .split('\n')
      .map(stackLine => stackLine.trim().replace('in ', ''))
      .reverse() // Reverses component order so that root component is left-most
      .join('.');

  /**
   * @method render
   * @returns {React.Component} Either child components or alternative content
   * @description Lifecycle method that renders child components until an error
   *              is thrown, then renders alternative content
   */
  render() {
    const { alternateContent, children } = this.props;
    const { hasError } = this.state;

    const fallbackMessage = 'There was an error loading this component.';

    return (
      <React.Fragment>
        {hasError &&
          (alternateContent || (
            <div title={fallbackMessage} data-test-section="fallback-message">
              {fallbackMessage}
            </div>
          ))}

        {!hasError && children}
      </React.Fragment>
    );
  }
}

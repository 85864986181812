import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

/**
 * browserHistoryStore
 * Responsible for the following state management:
 * Push State in our SPAs
 */
export default Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   */
  getInitialState() {
    return toImmutable({
      historyStack: [],
    });
  },

  initialize() {
    this.on(actionTypes.BROWSER_HISTORY_PUSH_STATE, pushState);
    this.on(actionTypes.BROWSER_HISTORY_POP_PREVIOUS_STATE, popPreviousState);
    this.on(actionTypes.BROWSER_HISTORY_REPLACE_STATE, replaceState);
  },
});

/**
 * Push the given url into the stack
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {string} payload.url
 */
function pushState(state, payload) {
  const historyStack = state.get('historyStack').push(
    toImmutable({
      url: payload.url,
    }),
  );
  return state.set('historyStack', historyStack);
}

/**
 * Remove the last entry in the stack
 * @param {Immutable.Map} state
 */
function popPreviousState(state) {
  let historyStack = state.get('historyStack');
  if (historyStack.size > 0) {
    historyStack = historyStack.pop();
  }
  return state.set('historyStack', historyStack);
}

/**
 * Replace the last entry in the stack with the given url
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {string} payload.url
 */
function replaceState(state, payload) {
  const historyStack = state.get('historyStack').butLast();
  return state.set(
    'historyStack',
    historyStack.push(
      toImmutable({
        url: payload.url,
      }),
    ),
  );
}

/**
 * Higher Order Component: sortable
 *
 * Wraps a given component in a pass-through component that provides sortData
 * and passes through a sorted data list
 *
 * How the sorted data list looks like depends on whether the data prop or
 * the sections prop is used.
 *
 * Rows of data can be passed in as an Immutable list using the data prop.
 * Otherwise, that data can be separated out in sections. Each section is
 * a list of rows (each row containing its own data). This is passed in
 * as an Immutable List (filled with Immutable lists of rows) in the sections
 * prop.
 *
 * Each section will be sorted internally and then displayed in the order
 * the sections are passed into the component.
 *
 * The sections and data prop should NOT be used in conjunction. The component
 * defaults to the sections prop in these cases.
 *
 * A limit and sectionLimits prop is provided to limit the data that is returned (and
 * subsequently displayed). Limit refers to the total limit of the number of
 * entities to display across ALL sections. In this case, we start at the first
 * section and go through all the sections until we hit the limit. All data
 * before the limit is displayed.
 *
 * sectionLimits is an array of numbers referring to the limit on the numbers of
 * entites to display PER section (e.g. [5, 5, null, 5]).
 * Do NOT use limit and sectionLimits together.
 *
 * Provides the following props to the wrapped Component
 * - sortData - Immutable.Map
 * - sortBy - function
 * - toggleField - function
 */
import _ from 'lodash';
import { Icon, Table } from 'optimizely-oui';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';

import Immutable, { toImmutable } from 'optly/immutable';

import flux from 'core/flux';
// TODO(jordan): this should be moved to core/modules and utils/sort
import sort from 'optly/utils/sort';
import SortableTableModule from 'optly/modules/sortable_table';

// TODO(FE-635) Deprecate flux.evaluate and flux.observe in favor of @connect decorator for flux bindings so we make this component stateless
export function sortable(Component) {
  return class extends React.Component {
    static displayName = 'SortableContainer';

    static propTypes = {
      // tableId required to setup flux observation of sort data
      tableId: PropTypes.string.isRequired,
      // the data set to be sorted
      data: PropTypes.instanceOf(Immutable.List),
      // a list of lists of data to be sorted
      // See Sortable Table for more information
      sections: PropTypes.instanceOf(Immutable.List),
      // optional default sortBy that will be applied before the component renders
      defaultSortBy: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      // a mapping of 'type' to sort function, example 'experiment_status': sortByStatus
      // this will be applied when calling sortBy({ field: 'status', type: 'experiment_status' })
      customSortFns: PropTypes.objectOf(PropTypes.func),
      limit: PropTypes.number,
      sectionLimits: PropTypes.array,
    };

    static defaultProps = {
      customSortFns: {},
    };

    state = {
      initialized: false,
      sortData: Immutable.List(),
    };

    shouldComponentUpdate(nextProps, nextState) {
      const { initialized, sortData } = this.state;

      return (
        nextProps !== this.props ||
        initialized !== nextState.initialized ||
        !Immutable.is(nextState.sortData, sortData)
      );
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
      // check if defaultSortBy is set
      const { tableId } = this.props;
      let { defaultSortBy } = this.props;
      let hasExistingSortData = false;
      const sortDataGetter = SortableTableModule.getters.sortData(tableId);
      this.__unwatchSortData = flux.observe(sortDataGetter, sortData => {
        this.setState({
          sortData,
        });
      });

      const sortData = flux.evaluate(sortDataGetter);

      if (sortData && sortData.size > 0) {
        this.setState({
          sortData,
        });
        hasExistingSortData = true;
      }

      if (!defaultSortBy || hasExistingSortData) {
        this.setState({
          initialized: true,
        });
        return;
      }

      if (!_.isArray(defaultSortBy)) {
        defaultSortBy = [defaultSortBy];
      }

      defaultSortBy = defaultSortBy.map(entry => {
        if (!entry.dir) {
          return _.extend({}, entry, {
            dir: SortableTableModule.fns.getDefaultSortDir(entry.type),
          });
        }

        return entry;
      });

      this.setState({
        sortData: toImmutable(defaultSortBy),
      });

      // use a bit of hack here to subscribe to flux data after we make the initial default sort by call
      setTimeout(() => {
        // set initialized after setTimeout to allow syncing of sortBy to the store so that toggleField works properly
        this.setState(
          {
            initialized: true,
          },
          () => {
            const isSorted = flux.evaluate(
              SortableTableModule.getters.isSorted(tableId),
            );
            if (defaultSortBy && !isSorted) {
              this.sortBy(defaultSortBy);
            }
          },
        );
      });
    }

    componentWillUnmount() {
      if (this.__unwatchSortData) {
        this.__unwatchSortData();
      }
    }

    toggleField = ({ field, type }) => {
      const { initialized } = this.state;
      const { tableId } = this.props;

      if (!initialized) {
        throw new Error(
          'Cannot call toggleField until SortableTable flux is initialized',
        );
      }
      SortableTableModule.actions.toggleField({
        field,
        type,
        tableId,
      });
    };

    sortBy = config => {
      const { initialized } = this.state;
      const { tableId } = this.props;

      if (!initialized) {
        throw new Error(
          'Cannot call sortBy until SortableTable flux is initialized',
        );
      }
      flux.batch(() => {
        // batch here and reset, since sortTable does an append of the sorting instead of override
        SortableTableModule.actions.resetTable(tableId);
        SortableTableModule.actions.sortTable({
          sortBy: _.isPlainObject(config) ? [config] : config,
          tableId,
        });
      });
    };

    __getSortFn = () => {
      const { sortData } = this.state;
      const { customSortFns } = this.props;

      // TODO(jordan) cache this state
      const sortFns = _.extend({}, sort.functions, customSortFns);
      const sortBy = sortData.toJS();
      return sort.generateImmutableObjectSortFn(sortBy, sortFns);
    };

    /**
     * Returns an Immutable list of sections that have been limited by the value
     * in the limit prop. For example, if there are 3 sections with 4 pieces
     * of data each and the limit is 7, we return 2 sections with the first
     * containing 4 pieces of data and the second containing 3 pieces of data.
     *
     * @param  {Immutable.List} sections
     * @return {Immutable.List}
     */
    __limitAllSections = sections => {
      const { limit } = this.props;
      let totalSize = 0;
      let amountToAdd;

      return sections.reduce((newSections, section) => {
        if (totalSize < limit) {
          if (section.size + totalSize > limit) {
            amountToAdd = limit - totalSize;
            totalSize += amountToAdd;
            return newSections.push(section.take(amountToAdd));
          }
          totalSize += section.size;
          return newSections.push(section);
        }
      }, Immutable.List());
    };

    /**
     * Returns an Immutable list of sections internally sorted. The order of
     * the sections remains the same, but within each section, the data is
     * sorted.
     *
     * If there is a section limits prop passed in, each section is limited as
     * instructed by the sections limits array where each index value
     * corresponds to a section.
     *
     * @param  {Immutable.List} sections
     * @return {Immutable.List} sorted + limited sections
     */
    __sortSections = sections => {
      const { sectionLimits } = this.props;
      let sortedSection;

      return sections.map((section, index) => {
        sortedSection = section.sort(this.__getSortFn());

        if (sectionLimits && typeof sectionLimits[index] === 'number') {
          return sortedSection.take(sectionLimits[index]);
        }

        return sortedSection;
      });
    };

    render() {
      const { sortData } = this.state;
      let { sections } = this.props;
      const { data, limit, sectionLimits } = this.props;
      const propsToPass = _.omit(this.props, ['data', 'sections']);

      if (!sections && !data) {
        throw new Error('Must supply either sections or data prop');
      }

      if (limit && sectionLimits) {
        throw new Error('Cannot use limit and sectionLimits together');
      }

      if (!sections) {
        // Convert to sections format if only data is defined
        sections = toImmutable([data]);
      }

      let sortedSections = this.__sortSections(sections);

      if (limit) {
        sortedSections = this.__limitAllSections(sortedSections);
      }

      return (
        <Component
          sortData={sortData}
          sortBy={this.sortBy}
          toggleField={this.toggleField}
          data={sortedSections.flatten(true)}
          {...propsToPass}
        />
      );
    }
  };
}

/**
 * A table that implements sort functionalities in its columns.
 * Note: This is exported on its own so that consumers can insert other HOCs
 * in between the sortable HOC and this table component. For example, pageable
 * functionality needs to be done after sorting.
 */
export class TableComponent extends React.Component {
  static propTypes = {
    // TODO(jordan): support regular lists
    data: PropTypes.instanceOf(Immutable.List),
    tableId: PropTypes.string.isRequired,
    renderTableHeader: PropTypes.func.isRequired,
    renderTableRow: PropTypes.func.isRequired,
    // all proptypes for table
    density: PropTypes.string,
    style: PropTypes.string,
    tableLayoutAlgorithm: PropTypes.string,
    testSection: PropTypes.string,
    // provided by sortable
    sortData: PropTypes.instanceOf(Immutable.List).isRequired,
    shouldAddHover: PropTypes.bool,
  };

  static childContextTypes = {
    tableId: PropTypes.string.isRequired,
    sortData: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  getChildContext() {
    const { tableId, sortData } = this.props;

    return {
      tableId,
      sortData,
    };
  }

  __renderRows = () => {
    const { data, renderTableRow } = this.props;
    return data.map((item, key) => renderTableRow(item, key));
  };

  render() {
    const { renderTableHeader } = this.props;

    return (
      <Table {...this.props}>
        <Table.THead>{renderTableHeader()}</Table.THead>
        <Table.TBody>{this.__renderRows()}</Table.TBody>
      </Table>
    );
  }
}

export const SortableTable = sortable(TableComponent);

export const SortDirIndicator = ({ dir }) =>
  dir && (
    <Icon
      name={dir === sort.ASC ? 'caret-up-solid' : 'caret-down-solid'}
      size="small"
    />
  );

SortDirIndicator.propTypes = {
  dir: PropTypes.oneOf([sort.ASC, sort.DESC]),
};

export class SortableTableHeader extends React.Component {
  static propTypes = {
    additionalContent: PropTypes.node,
    field: PropTypes.string.isRequired,
    isNumerical: PropTypes.bool,
    joyrideId: PropTypes.string,
    onClick: PropTypes.func,
    sortMax: PropTypes.number,
    type: PropTypes.string,
    children: PropTypes.node,
  };

  static contextTypes = {
    tableId: PropTypes.string.isRequired,
    sortData: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  static defaultProps = {
    isNumerical: false,
    joyrideId: null,
    onClick: () => {},
    sortMax: 1,
    type: 'string',
  };

  sort = () => {
    const { field, onClick, sortMax, type } = this.props;
    const { tableId } = this.context;

    SortableTableModule.actions.toggleField({
      field,
      sortMax,
      type,
      tableId,
    });
    onClick(field, this.__getFieldDir());
  };

  __getFieldDir = () => {
    const { sortData } = this.context;
    const { field } = this.props;

    if (!sortData) {
      return;
    }
    const entry = sortData.find(item => item.get('field') === field);
    if (!entry) {
      return;
    }
    return entry.get('dir');
  };

  render() {
    const {
      field,
      type,
      isNumerical,
      joyrideId,
      children,
      additionalContent,
    } = this.props;
    const fieldDir = this.__getFieldDir();
    const classes = classNames({
      flex: true,
      'flex--row': true,
      'flex-align--center': true,
      'cursor--pointer': true,
      'flex-justified--end': isNumerical,
    });
    const defaultOrder = SortableTableModule.fns.getDefaultSortDir(type);
    return (
      <Table.TH {...this.props}>
        <span className={classes} data-joyride-step={joyrideId}>
          <span
            onClick={this.sort}
            onKeyDown={this.sort}
            role="button"
            tabIndex="0"
            className={classNames(
              'table-header__column-name flex flex-align--center cursor--pointer',
              {
                'table-header__column-name--has-focus': field && fieldDir,
              },
            )}
            data-test-section="toggle-field">
            {children}&nbsp;
            {additionalContent && (
              <span className="flex">{additionalContent}</span>
            )}
            <SortDirIndicator dir={fieldDir || defaultOrder} />
          </span>
        </span>
      </Table.TH>
    );
  }
}

export default {
  SortDirIndicator,
  SortableTable,
  SortableTableHeader,
  sortable,
  TableComponent,
};

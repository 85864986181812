import Immutable, { toImmutable } from 'optly/immutable';

import LayerEnums from 'optly/modules/entity/layer/enums';

import constants from './constants';

export const addProjectNameToEntities = (entities, projectsMap) =>
  entities
    .map(entity => {
      const project = projectsMap.get(entity.get('project_id'));
      if (!project) {
        // This should probably throw an error or notify someone, but for now gracefully degrade.
        return entity;
      }
      return entity.set('project_name', project.get('project_name'));
    })
    .toList();

export const createMapOfQueryableEvents = (events, views, projectsMap) => {
  const parsedEvents = events.map(event =>
    event.set('kind', LayerEnums.metricKinds.EVENT),
  );

  const parsedViews = views.map(view =>
    toImmutable({
      // api_name, category and id are all needed to make API requests
      id: view.get('id'),
      // use view as api_name and category as view_activated for backwards compat with querying druid
      api_name: String(view.get('id')),
      category: constants.VIEW_ACTIVATED_EVENT_TYPE,
      name: tr('Visit Page: {0}', view.get('name')),
      project_id: view.get('project_id'),
      project_name: view.get('project_name'),
      description: view.get('description'),
      kind: LayerEnums.metricKinds.VIEW,
      event_type: constants.eventTypes.PAGE_VIEW,
    }),
  );

  const allEvents = parsedViews.concat(parsedEvents);
  return Immutable.OrderedMap({}).withMutations(map => {
    allEvents.forEach(event => {
      map.set(event.get('id'), event);
    });
  });
};

export default {
  addProjectNameToEntities,
  createMapOfQueryableEvents,
};

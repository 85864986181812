import moduleActions from './actions';
import moduleFns from './fns';

export const actions = moduleActions;
export const fns = moduleFns;

export default {
  actions,
  fns,
};

import keyMirror from 'optly/utils/key_mirror';

export const policy = {
  DEFAULT: tr('default'),
};

export const type = keyMirror({
  interest_group: null,
});

export default {
  policy,
  type,
};

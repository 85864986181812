const { values } = require('lodash');
const PropTypes = require('prop-types');
const React = require('react');

const { GREETINGS } = require('optly/modules/profile_details/constants');

/**
 * Simple component for rendering the profile details dialog header.
 * @param {Object} props - Properties passed to component
 * @returns {ReactElement}
 */
function Header({ title }) {
  // Render header
  return (
    <div className="welcome-header header-welcome">
      <div
        className="reading-column soft-quad--top flush--top"
        data-test-section="profile-details-dialog-header">
        <div className="lego-layout--single-column max-width--reading text--left position--relative height--1-1">
          <h1
            className="flush--bottom kilo weight--light color--brand"
            data-test-section="profile-details-dialog-header-greeting">
            {title}
          </h1>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  /** Sets the greeting text for the user */
  title: PropTypes.oneOf(values(GREETINGS)).isRequired,
};

module.exports = Header;

// Core
import _ from 'lodash';
import eventsService from 'optly/services/events';
import flux from 'core/flux';

// Modules
import AdminAccount from 'optly/modules/admin_account';
import CurrentProject from 'optly/modules/current_project';

/**
 * Sends a tracking call to Marketo
 *
 * @param {String} mktoFunctionName
 * @param {Object} leadInfo
 * @param {String} token
 */
export function trackMarketo(mktoFunctionName, leadInfo, token) {
  const info = _.extend(
    {
      email: flux.evaluate(AdminAccount.getters.email),
      accountId: flux.evaluate(AdminAccount.getters.id),
      projectId: flux.evaluate(CurrentProject.getters.id),
      planId: flux.evaluate(AdminAccount.getters.planId),
      userLocale: flux.evaluate(AdminAccount.getters.userLocale),
    },
    {
      url: window.location.pathname,
    },
    leadInfo,
  );
  eventsService.trackMarketoLead(mktoFunctionName, info, token);
}

export default {
  trackMarketo,
};

/**
 * Disabled directive
 * Shortcut to add the disabled attribute and add the `disabled` class
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
import $ from 'jquery';

export default function(disabled) {
  const $el = $(this.el);

  $el.attr('disabled', disabled).toggleClass('disabled', disabled);
}

/**
 * Geotargeting Label Directive
 *
 * Translates a location key (CITY|STATE|COUNTRY) into a readable location
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const $ = require('jquery');
const geotargetingService = require('optly/services/geotargeting');

module.exports = {
  update(val) {
    if (!val) {
      return '';
    }
    geotargetingService.getLabel(val).then(this._updateText.bind(this));
  },

  _updateText(value) {
    const $el = $(this.el);
    if ($el.is('input')) {
      $el.val(value);
    } else {
      $el.text(value);
    }
  },
};

import flux from 'core/flux';
import RestApi from 'optly/modules/rest_api';
import uploadUtil from 'optly/utils/upload';
import AjaxUtil from 'optly/utils/ajax';
import UIVersionGetters from 'optly/modules/ui/version/getters';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

export const {
  save,
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deleteUser = baseEntityActions.delete;

export { deleteUser as delete };

/**
 * Sends a new email verification token to the current user
 * @return {Deferred}
 */
export function sendVerificationEmail() {
  const data = {
    ui_version: flux.evaluate(UIVersionGetters.activeVersion),
  };
  return AjaxUtil.makeV1AjaxRequest({
    type: 'POST',
    url: '/account/send_verification_email',
    data,
  });
}

export function uploadProfileImage(file, filename) {
  return uploadUtil.upload(
    file,
    filename,
    '/account/upload_user_profile_image',
  );
}

export default {
  ...baseEntityActions,
  sendVerificationEmail,
  uploadProfileImage,
};

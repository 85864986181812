import flux from 'core/flux';

import { getters as ProjectGetters } from 'optly/modules/entity/project';

import { AppEnvironment as APP_ENVIRONMENT } from 'optly/utils/enums';

import config from 'atomic-config';

import Deferred from 'core/async/deferred';

import actionTypes from './action_types';
import constants from './constants';
import fns from './fns';
import getters from './getters';

class UnsatisfiedRequestsError extends Error {}

function consoleLog(...args) {
  if (!__TEST__) {
    console.log(...args); // eslint-disable-line no-console
  }
}

/**
 * Resolve verifier deferred and log to console
 */
function formatVerification(projectId, cdnRevision) {
  const displayEnv = fns.getEnvironment(config.get('env')).toUpperCase();
  consoleLog(
    `[VERIFIER] ${displayEnv} up to date for projectId ${projectId} at revision ${cdnRevision}`,
  );

  return {
    projectId,
    cdn_revision: cdnRevision,
  };
}

/**
 * set the maxVerifiedRevision for a projectId. Should only be used for **TESTING**
 */
export function setProjectRecordAsVerified(projectId, maxVerifiedRevision) {
  flux.dispatch(actionTypes.VERIFIER_ADD_PROJECT_RECORD, {
    projectId,
  });
  flux.dispatch(actionTypes.VERIFIER_FETCH_SUCCESS, {
    projectId,
    maxVerifiedRevision,
  });
}

/**
 * Verify that a given project revision is uploaded to the CDN.
 * @param {number} projectId - The id of the project to verify the cdn revision for.
 * @param {number?} id - The project revision to verify, such as what is returned by a publish.
 *                       If none is provided, the project's code_revision will be used instead,
 *                       simply checking if the most recent project upload succeeded.
 * @return {Promise}
 */
export function verify({ projectId, revisionToVerify }) {
  if (!projectId) {
    return Promise.reject(new Error('No project ID to verify'));
  }
  const displayEnv = fns.getEnvironment(config.get('env')).toUpperCase();
  consoleLog(
    `[VERIFIER] Verifying ${displayEnv} revision for projectId ${projectId}`,
  );

  // if we aren't passed a revision to verify against, use the project's code_revision
  if (!revisionToVerify) {
    revisionToVerify = flux
      .evaluate(ProjectGetters.byId(projectId))
      .get('code_revision');
  }
  // create a projectRecord if it doesn't exist already
  flux.dispatch(actionTypes.VERIFIER_ADD_PROJECT_RECORD, { projectId });

  // check to see if the revision has already been verified
  const maxVerifiedRevision = flux.evaluate(
    getters.maxVerifiedRevision(projectId),
  );
  if (maxVerifiedRevision >= revisionToVerify) {
    return Promise.resolve(formatVerification(projectId, maxVerifiedRevision));
  }
  return retrieveVerifiedRevision(projectId, revisionToVerify);
}

/**
 * Responsible for handling the verification fetching. It:
 *  - fires off xhr requests (through fetchRevisionData)
 *  - waits for an already inflight request if there is one
 */
function retrieveVerifiedRevision(projectId, id) {
  // try to fetch the version request and, if it exists, return the associated deferred
  const versionRequest = flux
    .evaluate(getters.versionRequests(projectId))
    .get(id);
  if (versionRequest) {
    return versionRequest.deferred.promise();
  }
  // otherwise, create our own deferred
  const versionRequestDeferred = new Deferred();
  flux.dispatch(actionTypes.VERIFIER_ADD_VERSION_REQUEST, {
    projectId,
    version: id,
    deferred: versionRequestDeferred,
  });

  // if there isn't an in-flight request, kick one off
  if (!flux.evaluate(getters.isRequestInflight(projectId))) {
    fetchRevisionData(projectId);
  }

  return versionRequestDeferred.promise();
}

/**
 * Repeatedly try to fetch the latest revision number, retrying until it succeeds
 */
function fetchRevisionData(projectId, attemptCount = 1) {
  const hasRequests = !!flux.evaluate(getters.versionRequests(projectId));
  if (!hasRequests) {
    // Bail if this request was cancelled while we were away.
    return;
  }
  const env = config.get('env');
  const ENVIRONMENT = fns.getEnvironment(env);
  const POLL_SETTINGS = constants.POLL_SETTINGS[ENVIRONMENT];
  const cdnURL =
    ENVIRONMENT === APP_ENVIRONMENT.PRODUCTION
      ? `https://cdn.optimizely.com/js/${projectId}.js`
      : `https://${fns.getEnvS3Bucket(
          env,
        )}.s3.amazonaws.com/js/${projectId}.js`;

  flux.dispatch(actionTypes.VERIFIER_FETCH_START, { projectId });

  fetch(cdnURL, {
    cache: 'no-cache',
    method: 'HEAD',
  })
    .then((response, status, xhr) => {
      if (response.ok) {
        return response.headers.get('x-amz-meta-revision');
      }
      throw new Error('Network response was not ok.');
    })
    .then(revision => {
      flux.dispatch(actionTypes.VERIFIER_FETCH_SUCCESS, {
        projectId,
        maxVerifiedRevision: revision,
      });

      // start resolving waiting deferreds with our version number
      let allSatisfied = true;
      const versionRequests = flux.evaluate(getters.versionRequests(projectId));
      versionRequests.forEach((versionRequest, id) => {
        if (id <= revision) {
          versionRequest.deferred.resolve(
            formatVerification(projectId, revision),
          );
          flux.dispatch(actionTypes.VERIFIER_REMOVE_VERSION_REQUEST, {
            projectId,
            version: id,
          });
        } else {
          consoleLog(
            `[VERIFIER] Failed ${ENVIRONMENT.toUpperCase()} revision verification for projectId ${projectId} (attempt ${attemptCount}): ${revision} < ${id}`,
          );
          allSatisfied = false;
        }
      });
      if (!allSatisfied) {
        throw new UnsatisfiedRequestsError(
          'not all version requests were satisfied',
        );
      }
    })
    .catch(err => {
      if (!(err instanceof UnsatisfiedRequestsError)) {
        consoleLog(
          `[VERIFIER] Error fetching revision info for projectId ${projectId}`,
        );
      }
      if (attemptCount >= POLL_SETTINGS.MAX_ATTEMPTS) {
        consoleLog(
          `[VERIFIER] Verifier timed out for projectId ${projectId} after ${attemptCount} attempts.`,
        );

        const versionRequests = flux.evaluate(
          getters.versionRequests(projectId),
        );
        // reject all deferred requests
        versionRequests.forEach((versionRequest, id) => {
          versionRequest.deferred.reject();
          flux.dispatch(actionTypes.VERIFIER_REMOVE_VERSION_REQUEST, {
            projectId,
            version: id,
          });
        });
        flux.dispatch(actionTypes.VERIFIER_FETCH_ERROR, { projectId });
      } else {
        // otherwise, try again at another interval
        setTimeout(
          fetchRevisionData.bind(null, projectId, attemptCount + 1),
          POLL_SETTINGS.POLLING_INTERVAL,
        );
      }
    });
}

export default {
  setProjectRecordAsVerified,
  verify,
};

import entityDefs from './entity_definitions';
import { InvitationStatus } from './constants';

/**
 * Services layer pure functions for the collaborators
 */
export function create(data) {
  const DEFAULTS = entityDefs.v2.fields || {};
  return { ...DEFAULTS, data };
}

/*
 * Given an email and an email to collaborator map
 * Return the username for the email.
 * @param {String} email of the collaborator to look up
 * @param {Immutable.Map} collaboratorMap which has a key of email pointing
 *   and value of Immutable.Map of the collaborator
 */
export function convertEmailToUserName(email, collaboratorMap) {
  let firstName;
  let lastName;
  if (email) {
    const collaborator = collaboratorMap.get(email);
    if (collaborator) {
      firstName = collaborator.get('first_name');
      lastName = collaborator.get('last_name');
    }
  }
  return formatName(email, firstName, lastName);
}

export function isInvitePendingOrExpired(collaborator) {
  return (
    collaborator.invitation_status &&
    collaborator.invitation_status !== InvitationStatus.ACCEPTED
  );
}

export function isCollaboratorAdministrator(collaborator) {
  return collaborator.role_name === 'Administrator';
}

/*
 * Given an email and first and last names
 * Return the best logical name
 * @param {String} email of the user
 * @param {String} firstName of the user
 * @param {String} lastName of the user
 * @return {String} Best translated string to represent
 *    the user's name
 */
export function formatName(email, firstName, lastName) {
  email = email || '';
  firstName = firstName || '';
  lastName = lastName || '';
  return firstName || lastName
    ? tr('{0} {1}', firstName, lastName).trim()
    : email;
}

export default {
  create,
  convertEmailToUserName,
  isCollaboratorAdministrator,
  isInvitePendingOrExpired,
  formatName,
};

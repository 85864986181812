const $ = require('jquery');

const createPluginConfigField = require('./create_plugin_config_field');

module.exports = createPluginConfigField({
  template: require('./multi_select.html'),

  methods: {
    onChange(event) {
      this.updateValue($(event.target).val());
    },

    updateSelection() {
      $(this.$$.input).val(this.value);
    },
  },

  ready() {
    this.updateSelection();
    this.$watch('value', this.updateSelection.bind(this));
  },

  beforeDestroy() {
    this.$unwatch('value');
  },
});

/**
 * Helper utility to build uri's programmatically
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const $ = require('jquery');

const locationHelper = require('optly/location');
/**
 * @constructor
 *
 * @param {string} base uri
 */
function UriLocation(base) {
  this._base = base;
  this._params = {};
}

/**
 * Adds key/value or hash of params to the UriLocation object
 * @param {string|object} key - or hash of params
 * @param {val=} val - or undefined if a hash is provided as first argument
 */
UriLocation.prototype.param = function(key, val) {
  if (typeof key === 'object') {
    $.each(key, (k, v) => {
      this._params[k] = v;
    });
  } else {
    this._params[key] = val;
  }
};

/**
 * Constructs and returns the uri string from the base and params
 * @return {string}
 */
UriLocation.prototype.toString = function(useEncoding = true) {
  let uri = this._base;
  let paramString;

  if (useEncoding) {
    // Standard case: encodes URL
    paramString = $.param(this._params);
  } else {
    /*
    Uses share link: skips encoding the already encoded URL
    Note: uses locationHelper wrapper instead of
    window.location.search directly for testability
    */
    const query = locationHelper.getSearch();
    paramString = query.substring(1);
  }

  if (paramString) {
    uri += uri.indexOf('?') !== -1 ? '&' : '?';
    uri += paramString;
  }
  return uri;
};

module.exports = {
  /**
   * Returns a UriLocation object for uri creation
   */
  create(base) {
    return new UriLocation(base);
  },
};

/**
 * Image input manager
 */
const keyMirror = require('optly/utils/key_mirror');

module.exports = {
  THUMBNAIL_MAX_DIMENSION: 60,
  EventTypes: keyMirror({
    CALCULATE_IMAGE_PORPERTIES: null,
    IMAGE_CHANGED: null,
    SET_IMAGE_URL: null,
  }),
};

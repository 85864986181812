/**
 * Dropdown directive
 * This directive is a port of optly.Dropdown
 * optly.Dropdown is deprecated and should be removed once all instances are refactored
 *
 * This is an empty directive, meaning it is attached via:
 * `<div v-dropdown>`
 *
 * No value is needed.
 *
 * Adding the `data-use-overlay` directive adds an overlay below the dropdown
 * that will prevent the existing contents below the dropdown from intercept
 * click events and prevent the dropdown from closing when clicking outside.
 *
 * Tagging a child element with `data-show-dropdown`, `data-hide-dropdown`, or
 * `data-toggle-dropdown` will show, hide, or toggle the dropdown when that
 * element is clicked.
 *
 * Ex markup:
 * <div class="dropdown" v-dropdown>
 *  <a class="dropdown-activator" data-show-dropdown>Show dropdown</a>
 *  <ul class="dropdown-body">
 *    <li>item 1</li>
 *    <li>item 2</li>
 *    <li data-hide-dropdown>I hide the dropdown when clicked</li>
 *  </ul>
 * </div>
 */
import $ from 'jquery';

import dropdownService from 'optly/services/dropdown';

const ACTIVATOR_SELECTOR = '[data-show-dropdown]';
const TOGGLE_SELECTOR = '[data-toggle-dropdown]';
const HIDE_SELECTOR = '[data-hide-dropdown]';

const exported = {
  isEmpty: true,

  bind() {
    const $el = $(this.el);
    $el.on(
      'click',
      ACTIVATOR_SELECTOR,
      dropdownService.show.bind(dropdownService, this.el),
    );
    $el.on(
      'click',
      TOGGLE_SELECTOR,
      dropdownService.toggle.bind(dropdownService, this.el),
    );
    $el.on(
      'click',
      HIDE_SELECTOR,
      dropdownService.hide.bind(dropdownService, this.el),
    );
  },

  unbind() {
    const $el = $(this.el);
    $el.off('click', ACTIVATOR_SELECTOR, dropdownService.show);
    $el.off('click', TOGGLE_SELECTOR, dropdownService.toggle);
    $el.off('click', HIDE_SELECTOR, dropdownService.hide);
  },
};

export default exported;

export const { isEmpty, bind, unbind } = exported;

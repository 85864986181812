import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'optimizely-oui';
import classnames from 'classnames';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import localStorageWrapper from 'optly/utils/local_storage_wrapper';

import CodeSampleWrapper from './code_sample_wrapper/index';

const LOCAL_STORAGE_SELECTED_SDK_KEY = 'code_sample_picker_selected_sdk';

const SDKPlatform = {
  Android: 'android',
  CSharp: 'csharp',
  Flutter: 'flutter',
  Go: 'go',
  IOS: 'ios',
  Java: 'java',
  Javascript: 'javascript',
  Php: 'php',
  Python: 'python',
  React: 'react',
  Ruby: 'ruby',
  TVOS: 'tv_os',
  Agent: 'agent',
};

const sdkDropdownOptionsAll = {
  [SDKPlatform.Android]: {
    title: 'Android SDK',
    value: SDKPlatform.Android,
  },
  [SDKPlatform.CSharp]: {
    title: 'C# SDK',
    value: SDKPlatform.CSharp,
  },
  [SDKPlatform.Flutter]: {
    title: 'Flutter SDK',
    value: SDKPlatform.Flutter,
  },
  [SDKPlatform.Go]: {
    title: 'Go SDK',
    value: SDKPlatform.Go,
  },
  [SDKPlatform.IOS]: {
    title: 'iOS SDK',
    value: SDKPlatform.IOS,
  },
  [SDKPlatform.Java]: {
    title: 'Java SDK',
    value: SDKPlatform.Java,
  },
  [SDKPlatform.Javascript]: {
    title: 'Javascript SDK',
    value: SDKPlatform.Javascript,
  },
  [SDKPlatform.Php]: {
    title: 'PHP SDK',
    value: SDKPlatform.Php,
  },
  [SDKPlatform.Python]: {
    title: 'Python SDK',
    value: SDKPlatform.Python,
  },
  [SDKPlatform.React]: {
    title: 'React SDK',
    value: SDKPlatform.React,
  },
  [SDKPlatform.Ruby]: {
    title: 'Ruby SDK',
    value: SDKPlatform.Ruby,
  },
  [SDKPlatform.TVOS]: {
    title: 'tvOS SDK',
    value: SDKPlatform.TVOS,
  },
  [SDKPlatform.Agent]: {
    title: 'Agent',
    value: SDKPlatform.Agent,
  },
};

class CodeSamplePicker extends React.Component {
  static propTypes = {
    description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    getCodeSample: PropTypes.func.isRequired,
    isHero: PropTypes.bool,
    showMobileOnly: PropTypes.bool.isRequired,
    title: PropTypes.string,
  };

  static defaultProps = {
    isHero: false,
  };

  constructor(props) {
    super(props);

    let storedSelectedSDK;

    try {
      storedSelectedSDK = localStorageWrapper.getItem(
        LOCAL_STORAGE_SELECTED_SDK_KEY,
      );
    } catch (e) {
      // If local storage can't be read, ignore
      console.error(e);
    }
    const defaultSelectedSDK = props.showMobileOnly ? 'android' : 'javascript';

    const isFlutterSDKLive = isFeatureEnabled('flutter_sdk_live');
    const sdkDropdownOptions = { ...sdkDropdownOptionsAll };
    if (!isFlutterSDKLive) {
      delete sdkDropdownOptions[SDKPlatform.Flutter];
    }

    // If Flutter SDK was selected and then the flag is disabled, it should not be shown as selected anymore.
    if (
      !!storedSelectedSDK &&
      storedSelectedSDK === SDKPlatform.Flutter &&
      !isFlutterSDKLive
    ) {
      storedSelectedSDK = null;
    }

    this.state = {
      selectedSDK: storedSelectedSDK || defaultSelectedSDK,
      sdkDropdownOptions,
    };
  }

  shouldShowCode = SDK =>
    !this.props.showMobileOnly || SDK === 'android' || SDK === 'ios';

  renderSDKCode = SDK => {
    if (this.shouldShowCode(SDK)) {
      return (
        <CodeSampleWrapper
          getCodeSample={this.props.getCodeSample}
          sdk={this.state.selectedSDK}
          showMobileOnly={this.props.showMobileOnly}
        />
      );
    }
    return (
      <div
        className="soft--sides push--top"
        data-test-section="code-sample-picker-upgrade-message">
        Upgrade your license to include Full Stack and take advantage of other
        platforms.
      </div>
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { selectedSDK } = this.state;
    if (selectedSDK !== prevState.selectedSDK) {
      try {
        localStorageWrapper.setItem(
          LOCAL_STORAGE_SELECTED_SDK_KEY,
          selectedSDK,
        );
      } catch (e) {
        // If local storage is full and throws an error, ignore
        console.error(e);
      }
    }
  }

  render() {
    const { description, isHero, title } = this.props;

    const { selectedSDK, sdkDropdownOptions } = this.state;

    return (
      <div className="lego-grid" data-test-section="code-sample-picker">
        <div className="soft-double--left width--1-1 push--top">
          <div
            className={classnames({
              'push--bottom': !isHero,
              'push-triple--bottom': isHero,
            })}>
            <div
              className="push--bottom"
              data-test-section="code-sample-picker-title">
              {isHero ? <h2>{title}</h2> : <h3>{title}</h3>}
            </div>
            <p data-test-section="code-sample-picker-description">
              {description}
            </p>
          </div>
          <Dropdown
            arrowIcon="down"
            buttonContent={sdkDropdownOptions[selectedSDK].title}
            style="outline"
            testSection="code-samples-dropdown"
            width={200}>
            <Dropdown.Contents>
              {Object.values(sdkDropdownOptions).map(sdk => {
                const onSDKChange = () =>
                  this.setState({ selectedSDK: sdk.value });

                return (
                  <Dropdown.ListItem key={sdk.value}>
                    <Dropdown.BlockLink
                      onClick={onSDKChange}
                      testSection={`code-sample-option-${sdk.value}`}>
                      <Dropdown.BlockLinkText
                        isItemSelected={selectedSDK === sdk.value}
                        text={sdk.title}
                      />
                    </Dropdown.BlockLink>
                  </Dropdown.ListItem>
                );
              })}
            </Dropdown.Contents>
          </Dropdown>
          {this.renderSDKCode(selectedSDK)}
        </div>
      </div>
    );
  }
}

export default CodeSamplePicker;

import PropTypes from 'prop-types';
import React from 'react';

import Immutable from 'optly/immutable';

import Card from 'react_components/card';

import CustomEventsTable from './table';

const EVENTS_TO_SHOW = 3;

class CustomEventsCard extends React.Component {
  static propTypes = {
    canArchiveUserEvent: PropTypes.bool.isRequired,
    editEvent: PropTypes.func.isRequired,
    events: PropTypes.instanceOf(Immutable.List).isRequired,
    experimentsUsageAvailable: PropTypes.bool.isRequired,
    tableId: PropTypes.string.isRequired,
  };

  state = {
    shouldShowAllEvents: false,
  };

  toggleShowingAllEvents = () => {
    this.setState(prevState => ({
      shouldShowAllEvents: !prevState.shouldShowAllEvents,
    }));
  };

  shouldShowEvent = (eventObj, index) =>
    this.state.shouldShowAllEvents || index < EVENTS_TO_SHOW; // eslint-disable-line react/destructuring-assignment, max-len

  render() {
    let eventsToggler;
    const {
      canArchiveUserEvent,
      editEvent,
      events,
      experimentsUsageAvailable,
      tableId,
    } = this.props;
    const { shouldShowAllEvents } = this.state;

    if (events.size > EVENTS_TO_SHOW) {
      if (!shouldShowAllEvents) {
        eventsToggler = (
          <a
            onClick={this.toggleShowingAllEvents}
            data-test-section="custom-events-toggle-open">
            <span>
              Show all events <span className="lego-arrow-inline--down" />
            </span>
          </a>
        );
      } else {
        eventsToggler = (
          <a
            onClick={this.toggleShowingAllEvents}
            data-test-section="custom-events-toggle-closed">
            <span>
              Show fewer events <span className="lego-arrow-inline--up" />
            </span>
          </a>
        );
      }
    }

    return (
      <div
        className="push-double position--relative"
        data-test-section="custom-events-section">
        <Card>
          <Card.Row>
            <Card.Label
              supertitle="Custom"
              subtitle={`Events: ${events.size}`}
              title="Custom Events">
              {eventsToggler}
            </Card.Label>

            <Card.Body>
              <CustomEventsTable
                canArchiveUserEvent={canArchiveUserEvent}
                editEvent={editEvent}
                events={events}
                experimentsUsageAvailable={experimentsUsageAvailable}
                shouldShowAllEvents={shouldShowAllEvents}
                shouldShowEvent={this.shouldShowEvent}
                tableId={tableId}
              />
            </Card.Body>
          </Card.Row>
        </Card>
      </div>
    );
  }
}

export default CustomEventsCard;

import { partial, cloneDeep } from 'lodash';
import { Link } from 'optimizely-oui';
import PropTypes from 'prop-types';
import React from 'react';

import { toImmutable } from 'optly/immutable';
import {
  constants as ViewConstants,
  enums as ViewEnums,
} from 'optly/modules/entity/view';

import ConditionGroup from 'optly/models/condition_group';

import UrlCondition from 'bundles/p13n/components/targeting/url_config/url_condition';
import SelectDropdown from 'react_components/select_dropdown';

import ConditionCardHeader from '../card_header';

const CONDITION_NEGATE_OPTIONS = [
  {
    label: tr('matches'),
    value: false,
  },
  {
    label: tr('does not match'),
    value: true,
  },
];

const UrlMatch = props => {
  /**
   * Add an empty (default) row to the specified condition list at the given index
   *
   * @param {Number} index
   */
  const addConditionRow = index => {
    const newErrors = cloneDeep(props.errors);
    newErrors.splice(index + 1, 0, null);
    props.updateErrors(newErrors);

    props.condition.conditions.splice(
      index + 1,
      0,
      cloneDeep(ViewConstants.DEFAULT_URL_CONDITION),
    );
    props.onConditionChange();
  };

  /**
   * Remove a row from the specified condition list at the given index
   *
   * @param {Number} index
   */
  const removeConditionRow = index => {
    const newErrors = cloneDeep(props.errors);
    newErrors.splice(index, 1);
    props.updateErrors(newErrors);

    props.condition.conditions.splice(index, 1);
    props.onConditionChange();
  };

  /**
   * Update the value of a condition at a given index
   *
   * @param {Number} index
   * @param {Object} event
   */
  const onChangeConditionValue = (index, event) => {
    const newErrors = cloneDeep(props.errors);
    newErrors[index] = null;
    props.updateErrors(newErrors);

    props.condition.conditions[index].value = event.target.value;
    props.onConditionChange();
  };

  /**
   * Update the value of a condition's match type at a given index
   *
   * @param {Number} index
   * @param {String} newMatchTypeValue
   */
  const onChangeMatchType = (index, newMatchTypeValue) => {
    props.condition.conditions[index].match_type = newMatchTypeValue;
    props.onConditionChange();
  };

  /*
   * Toggle between url 'does not match' and url 'does match' the succeeding
   * conditions
   *
   * @param {Boolean} shouldNegate
   */
  const onChangeConditionNegation = shouldNegate => {
    props.condition.negate = shouldNegate;
    props.onConditionChange();
  };

  const showEdgeHashWarning = index => {
    return (
      props.isWebEdgeProject &&
      props.condition.conditions[index].match_type !==
        ViewConstants.SIMPLE_MATCH_TYPE &&
      props.condition.conditions[index].value.includes('#')
    );
  };

  return (
    <div className="border--all" data-test-section="condition-card-url-match">
      <ConditionCardHeader
        showMultipleURLsMessage={true}
        onClose={partial(props.onConditionChange, props.condition)}
        title="URL Match"
      />
      <div className="soft">
        <div className="flex flex-align--center push--bottom">
          <div className="push--right">URL</div>
          <SelectDropdown
            items={CONDITION_NEGATE_OPTIONS}
            value={props.condition.negate}
            onChange={onChangeConditionNegation}
            testSection="condition-match-type"
            width="auto"
            minDropdownWidth={150}
          />
          <div className="push--left">
            these <span className="weight--bold">URL(s)</span>:
            <span className="oui-label--required" />
          </div>
        </div>
        <table
          className="lego-table lego-table--add-row"
          data-test-section="url-match-card-conditions">
          <tbody>
            {props.condition.conditions.map((condition, index) => (
              <UrlCondition
                condition={toImmutable(condition)}
                index={index}
                length={props.condition.conditions.length}
                addConditionRow={addConditionRow}
                removeConditionRow={removeConditionRow}
                onChangeCondition={onChangeConditionValue}
                onChangeMatchType={onChangeMatchType}
                key={index}
                showMatchType={true}
                displayError={!!props.errors[index]}>
                {props.errors[index] && (
                  <div
                    className="color--bad-news micro push-half--top"
                    data-test-section="url-match-error">
                    {props.errors[index]}
                  </div>
                )}
                {showEdgeHashWarning(index) && (
                  <div
                    className="color--bad-news micro push-half--top"
                    data-test-section="url-match-edge-hash-warning">
                    Note that browsers do not send characters after the "#" to
                    the Edge Decider.&nbsp;
                    <Link
                      href={props.edgeLearnMoreLinks.hashWarningLink}
                      newWindow={true}
                      variant="bad-news"
                      testSection="edge-hash-warning-learn-more-link">
                      Learn more
                    </Link>
                    .
                  </div>
                )}
              </UrlCondition>
            ))}
          </tbody>
        </table>
        <p className="push-half--top flush--bottom">
          Learn more about{' '}
          <Link
            href="https://support.optimizely.com/hc/en-us/articles/4410283798541-URL-targeting-in-Optimizely-Choose-where-your-experiment-runs/#URL_match_types"
            newWindow={true}>
            URL match types
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

UrlMatch.propTypes = {
  condition: PropTypes.instanceOf(ConditionGroup).isRequired,
  edgeLearnMoreLinks: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string),
  isWebEdgeProject: PropTypes.bool,
  onConditionChange: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired,
};

UrlMatch.defaultProps = {
  edgeLearnMoreLinks: {},
  errors: [],
  isWebEdgeProject: false,
};

UrlMatch.matchType = ViewEnums.conditionMatchTypes.URL;

export default UrlMatch;

import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Link } from 'optimizely-oui';
import { api as SegmentJSAPI } from '@optimizely/segment-js';

import CurrentProjectActions from 'optly/modules/current_project/actions';

const AdvancedFeaturesDeactivationAndUndo = ({
  deactivationDescription,
  deactivationEnabled,
  deactivationLabel,
  onUpdate,
  undoDescription,
  undoLabel,
  undoOnDeactivation,
}) => {
  const DEACTIVATE_PAGE_LINK = CurrentProjectActions.getHelpCopy(
    'full_stack_targeting_deactivate_page',
  );
  const UNDO_CHANGES_LINK = CurrentProjectActions.getHelpCopy(
    'full_stack_targeting_undo_changes',
  );
  const onChangeDeactivationEnabled = () => {
    SegmentJSAPI.track('Click on "Enable deactivation for new Pages" checkbox');
    onUpdate('deactivation_enabled', !deactivationEnabled);
  };
  const onChangeUndoOnDeactivation = () => {
    SegmentJSAPI.track(
      'Click on "Undo changes when Pages deactivate" checkbox',
    );
    onUpdate('undo_on_deactivation', !undoOnDeactivation);
  };

  return (
    <ul className="push-half oui-input-list">
      <li>
        <Checkbox
          checked={deactivationEnabled}
          data-test-section="configure-view-smart-deactivation_enabled"
          description={deactivationDescription}
          label={deactivationLabel}
          onChange={onChangeDeactivationEnabled}
        />
        <Link
          className="push-triple--left"
          href={DEACTIVATE_PAGE_LINK}
          newWindow={true}>
          Learn more
        </Link>
      </li>
      <li className="push-double--left">
        <Checkbox
          checked={undoOnDeactivation}
          data-test-section="configure-view-smart-undo_on_deactivation"
          description={undoDescription}
          isDisabled={!deactivationEnabled}
          label={undoLabel}
          onChange={onChangeUndoOnDeactivation}
        />
        <Link
          className="push-triple--left"
          href={UNDO_CHANGES_LINK}
          newWindow={true}>
          Learn more
        </Link>
      </li>
    </ul>
  );
};

AdvancedFeaturesDeactivationAndUndo.defaultProps = {
  deactivationEnabled: false,
  undoOnDeactivation: false,
};

AdvancedFeaturesDeactivationAndUndo.propTypes = {
  deactivationDescription: PropTypes.string.isRequired,
  deactivationEnabled: PropTypes.bool,
  deactivationLabel: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  undoDescription: PropTypes.string.isRequired,
  undoLabel: PropTypes.string.isRequired,
  undoOnDeactivation: PropTypes.bool,
};

export default AdvancedFeaturesDeactivationAndUndo;

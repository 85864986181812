import PropTypes from 'prop-types';
import React from 'react';
import { OptimizelyFeature, useFeature } from '@optimizely/react-sdk';
import { Input, Label, Textarea } from 'optimizely-oui';

import Immutable from 'optly/immutable';
import capitalize from 'optly/filters/capitalize';
import userFriendlyNameToKey from 'optly/utils/userFriendlyNameToKey';
import ManagerFullStackSectionModuleConstants from 'bundles/p13n/sections/manager_fullstack/section_module/constants';

const ExperimentDetails = props => {
  const [isUserFriendlyNamesEnabled] = useFeature('user_friendly_names');

  const entityName =
    ManagerFullStackSectionModuleConstants.ExperimentTypeToEntityName[
      props.experimentType
    ];

  const handleNameInputChange = event => {
    const { experiment, updateExperimentProperty } = props;
    const isNewExperiment = !experiment.get('id');
    const previousName = experiment.get('name');
    const previousKey = experiment.get('key');
    const inputValue = event.target.value;
    updateExperimentProperty('name', inputValue);
    // If the key is empty or matches the formatted friendly name, continue with auto-generation ONLY for experiment creation dialog
    if (
      isNewExperiment &&
      (!previousKey ||
        previousKey ===
          userFriendlyNameToKey((previousName || '').toLowerCase()))
    ) {
      updateExperimentProperty(
        'key',
        userFriendlyNameToKey((inputValue || '').toLowerCase()),
      );
    }
  };

  const handleKeyInputChange = event => {
    const { updateExperimentProperty } = props;
    updateExperimentProperty(
      'key',
      isUserFriendlyNamesEnabled
        ? userFriendlyNameToKey(event.target.value)
        : event.target.value,
    );
  };

  const handleDescriptionInputChange = event => {
    const { updateExperimentProperty } = props;
    updateExperimentProperty('description', event.target.value);
  };

  const renderNameErrorBlock = () => {
    if (props.showEmptyNameError) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="custom-experiment-name-invalid-error">
          Please enter a name.
        </div>
      );
    }
  };

  const renderKeyErrorBlock = () => {
    if (props.showEmptyKeyError || props.showInvalidKeyError) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="custom-experiment-key-invalid-error">
          Please enter a valid key. Valid keys contain alphanumeric characters,
          hyphens, and underscores, and are limited to 64 characters.
        </div>
      );
    }

    if (props.showUniqueKeyError) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="custom-experiment-key-blank-error">
          {isUserFriendlyNamesEnabled
            ? 'This key is already in use. Keys must be unique.'
            : 'Key is already in use by another feature in this project. Please choose a unique key.'}
        </div>
      );
    }
  };

  return (
    <div>
      <form onSubmit={props.handleOnSubmit}>
        <fieldset>
          <ol className="lego-form-fields">
            <OptimizelyFeature feature="user_friendly_names">
              {isEnabled =>
                isEnabled ? (
                  <li className="push-quad--bottom lego-form-field__item">
                    <Input
                      type="text"
                      label={`${capitalize(entityName)} Name`}
                      placeholder={`Add a name for your ${entityName}.`}
                      isRequired={true}
                      testSection="custom-experiment-name"
                      value={props.experiment.get('name')}
                      onChange={handleNameInputChange}
                    />
                    {renderNameErrorBlock()}
                  </li>
                ) : null
              }
            </OptimizelyFeature>
            <li className="push-quad--bottom lego-form-field__item">
              <Input
                type="text"
                label={`${capitalize(entityName)} Key`}
                placeholder={`Use this to uniquely identify the ${entityName}.`}
                isRequired={true}
                testSection="custom-experiment-key"
                value={props.experiment.get('key')}
                onChange={handleKeyInputChange}
              />
              {renderKeyErrorBlock()}
            </li>
            <li className="lego-form-fields__item">
              <Textarea
                label="Description"
                placeholder={`What is your ${entityName}? State your hypothesis and notes here.`}
                testSection="custom-experiment-description"
                value={props.experiment.get('description')}
                onChange={handleDescriptionInputChange}
              />
            </li>
          </ol>
        </fieldset>
      </form>
    </div>
  );
};

ExperimentDetails.propTypes = {
  experiment: PropTypes.instanceOf(Immutable.Map).isRequired,
  experimentType: PropTypes.string,
  handleOnSubmit: PropTypes.func,
  showEmptyKeyError: PropTypes.bool,
  showEmptyNameError: PropTypes.bool,
  showInvalidKeyError: PropTypes.bool,
  showUniqueKeyError: PropTypes.bool,
  updateExperimentProperty: PropTypes.func.isRequired,
};

export default ExperimentDetails;

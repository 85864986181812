import keyMirror from 'optly/utils/key_mirror';

export default keyMirror({
  P13N_CHANGE_EDITOR_SET_INITIAL_SELECTOR: null,
  P13N_CHANGE_EDITOR_SET_CURRENT_SELECTOR: null,
  P13N_CHANGE_EDITOR_SET_CURRENT_SELECTOR_TYPE: null,
  P13N_CURRENT_CHANGE_RESET_IS_DIRTY: null,
  P13N_CURRENT_CHANGE_REMOVE_CSS_PROPERTY: null,
  P13N_CURRENT_CHANGE_SET_ATTRIBUTES_PROPERTY: null,
  P13N_CURRENT_CHANGE_SET_CSS_PROPERTY: null,
  P13N_CURRENT_CHANGE_SET_REARRANGE_PROPERTY: null,
  P13N_CURRENT_CHANGE_SET_REDIRECT_PROPERTY: null,
  P13N_CURRENT_CHANGE_SET_ASYNC_PROPERTY: null,
  P13N_CURRENT_CHANGE_SET_NAME: null,
  P13N_CURRENT_CHANGE_SET_SELECTOR: null,
  P13N_EDITOR_RESET_CUSTOM_CODE_TABS_DIRTY: null,
  P13N_EDITOR_RESET_ELEMENT_INFO: null,
  P13N_EDITOR_SELECT_VARIATION: null,
  P13N_EDITOR_SELECT_VIEW: null,
  P13N_EDITOR_SET_CHANGE_EDITOR_ELEMENT_SELECTOR_ENABLED: null,
  P13N_EDITOR_SET_CHANGE_EDITOR_IS_EDITING_SELECTOR: null,
  P13N_EDITOR_SET_CURRENT_CHANGE_ELEMENT_INFO: null,
  P13N_EDITOR_SET_CURRENTLY_EDITING_CHANGE: null,
  P13N_EDITOR_SET_CURRENTLY_EDITING_INSERT_HTML_CHANGE: null,
  P13N_EDITOR_SET_CURRENTLY_EDITING_INSERT_IMAGE_CHANGE: null,
  P13N_EDITOR_SET_CURRENTLY_EDITING_REDIRECT_CHANGE: null,
  P13N_EDITOR_SET_CUSTOM_CODE_PANEL_VISIBILITY: null,
  P13N_EDITOR_SET_CUSTOM_CODE_PANEL_TAB: null,
  P13N_EDITOR_SET_CUSTOM_CODE_TAB_DIRTY: null,
  P13N_EDITOR_SET_CURRENTLY_EDITING_CHANGE_DEPENDENCIES: null,
  P13N_EDITOR_UNSELECT_VARIATION: null,
  P13N_EDITOR_UNSELECT_VIEW: null,
  P13N_EDITOR_UNSET_CURRENTLY_EDITING_CHANGE: null,
  P13N_INSERT_HTML_SET_HTML_VALUE: null,
  P13N_INSERT_HTML_SET_OPERATOR: null,
  P13N_EDITOR_SELECT_EXPERIMENT_ID: null,
  P13N_EDITOR_UNSELECT_EXPERIMENT_ID: null,
  P13N_EDITOR_SET_CHANGE_LIST_SIDEBAR_COMPONENT_CONFIG: null,
});

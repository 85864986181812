import React from 'react';
import { useGetters } from 'core/ui/methods/connect_getters';
import DashboardHeader from 'react_components/dashboard_header';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import ProjectFns from 'optly/modules/entity/project/fns';
import UrlHelper from 'optly/services/url_helper';
import NavConstants from 'optly/services/navigation';

const MetricsTopbar = ({ activeTab }) => {
  const { currentProject, currentProjectId } = useGetters({
    currentProject: CurrentProjectGetters.project,
    currentProjectId: CurrentProjectGetters.id,
  });

  const tabs = [
    {
      id: NavConstants.MetricsHubTabs.METRICS_TAB,
      isVisible: true,
      testSection: 'metrics-tab',
      title: 'Metrics',
      url: UrlHelper.metricsHubMetricsHome(currentProjectId),
    },
    {
      id: 'EVENTS_TAB',
      isVisible: true,
      testSection: 'events-tab',
      title: 'Events',
      url: UrlHelper.metricsHubEventsHome(currentProjectId),
    },
  ];

  return (
    <DashboardHeader
      activeTab={activeTab}
      projectName={ProjectFns.formatAnyProjectName(currentProject)}
      tabs={tabs}
      testSection="metrics-topbar-root"
      title="Metrics"
    />
  );
};

export default MetricsTopbar;

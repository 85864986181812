/**
 * Returns true if the argument string is not a valid traffic percentage string
 * according to the validation rules for the rollout traffic input.
 * @param {String} rolloutTrafficStr
 * @returns {Boolean}
 */
export function rolloutTrafficAllocationIsInvalid(rolloutTrafficStr) {
  const trafficAllocationNum = parseFloat(rolloutTrafficStr);
  if (Number.isNaN(trafficAllocationNum)) {
    // Must be parseable as a number
    return true;
  }

  if (parseFloat(trafficAllocationNum.toFixed(2)) !== trafficAllocationNum) {
    // Must not have more than 2 nonzero digits after the decimal/ point (if
    // there is a decimal point)
    return true;
  }

  // Must be between 0 and 100 inclusive
  return trafficAllocationNum < 0 || trafficAllocationNum > 100;
}

export default {
  rolloutTrafficAllocationIsInvalid,
};

const ui = require('core/ui');
const EditorGetters = require('bundles/p13n/modules/editor').getters;
const ImageInputManager = require('bundles/p13n/components/image_input_manager')
  .default;
const imageInputManagerConstants = require('bundles/p13n/components/image_input_manager/constants');

const template = require('./image.html');
const createPluginConfigField = require('./create_plugin_config_field');

module.exports = createPluginConfigField({
  template,

  data: {
    imageEditingEnabled: true,
    isEditorReadOnly: false,
    editingEnabled: true,
  },

  methods: {
    setupChange() {
      this.$emit(
        imageInputManagerConstants.EventTypes.CALCULATE_IMAGE_PORPERTIES,
        this.value,
      );
    },

    imageChanged(url) {
      this.$emit(
        imageInputManagerConstants.EventTypes.CALCULATE_IMAGE_PORPERTIES,
        url,
      );
      this.updateValue(url);
    },
  },

  ready() {
    ui.renderReactComponent(this, {
      component: ImageInputManager,
      el: this.$$.imageInputManager,
      dataBindings: {
        isEditorReadOnly: EditorGetters.isEditorReadOnly,
        isEditingSelector: EditorGetters.changeEditorIsEditingSelector,
      },
      props: {
        setupChange: this.setupChange.bind(this),
        onImageChange: this.imageChanged.bind(this),
        imageEditingEnabled: !this.isEditingSelector,
      },
    });

    // This is to update the imageInputManager component when the url changes
    // programatically (when the revert button is clicked)
    this.$watch('value', url => {
      this.$emit(
        imageInputManagerConstants.EventTypes.CALCULATE_IMAGE_PORPERTIES,
        url,
      );
    });
  },

  beforeDestroy() {
    this.$unwatch('value');
  },
});

/**
 * Creates a deterministic hash of a JS object
 *
 * @author Jordan Garcia
 */
const _ = require('lodash');

/**
 * Creates a deterministic stringified JSON of a JS
 * object by ensuring deterministic key ordering
 *
 * @param {object} obj
 * @return {string}
 */
function stringifyObject(obj) {
  function flatten(obj) {
    if (!_.isObject(obj)) {
      return obj;
    }
    return _.chain(obj)
      .toPairs()
      .map(p => [p[0], flatten(p[1])])
      .sortBy(p => p[0])
      .valueOf();
  }

  return JSON.stringify(flatten(obj));
}

/**
 * Basic hash function
 * @param {string} str
 * @return {number}
 */
function hash(str) {
  let res = 0;
  let i;
  let chr;
  let len;
  if (!str) {
    return res;
  }
  for (i = 0, len = str.length; i < len; i++) {
    chr = str.charCodeAt(i);
    // TODO: refactor to eliminate these bitwise operators
    // eslint-disable-next-line no-bitwise
    res = (res << 5) - res + chr;
    // eslint-disable-next-line no-bitwise
    res |= 0; // Convert to 32bit integer
  }
  return res;
}

/**
 * Object hashing function
 * @param {object} obj
 * @return {string}
 */
function hashObject(obj) {
  if (!_.isObject(obj)) {
    throw new Error('Cannot hash non-object');
  }

  return String(hash(stringifyObject(obj)));
}

module.exports = {
  hash: hashObject,
};

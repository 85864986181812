const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const actionTypes = require('../action_types');

module.exports = Nuclear.Store({
  getInitialState() {
    // holds a map of experimentId => reach stats
    return toImmutable({});
  },

  initialize() {
    this.on(actionTypes.CAMPAIGN_OVERVIEW_LOAD_REACH_RESULTS, load);
  },
});

function load(state, { results }) {
  return state.withMutations(map => {
    results.forEach(entry => {
      map.set(entry.id, entry.reach);
    });
  });
}

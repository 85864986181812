import _ from 'lodash';
import { Store as NuclearStore } from 'nuclear-js';

import cloneDeep from 'optly/clone_deep';
import { toImmutable } from 'optly/immutable';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';

import actionTypes from '../action_types';
import constants from '../constants';

const CURRENTLY_EDITING_CHANGE_KEY = 'currentlyEditingChange';
const INITIAL_STATE = {
  changeListSidebarComponentConfig: {
    parent: null,
    data: {},
  },
  currentlyEditingChange: {
    id: null,
    name: null,
    nameIsDirty: false,
    status: LayerExperimentEnums.ChangeStatuses.INITIAL,
    type: LayerExperimentEnums.ChangeTypes.ATTRIBUTE,
    async: false,
    selector: null,
    elementCount: 0,
    elementInfo: [],
    selectorIsDirty: false,
    rearrange: {
      insertSelector: '',
      operator: null,
    },
    attributes: constants.SupportedAttributeChanges,
    shouldSaveFlags: {
      class: false,
      href: false,
      html: false,
      src: false,
      style: false,
      text: false,
      remove: false,
      hide: false,
    },
    dependencies: [],
    css: {},
  },
  currentlyEditingInsertHTMLChange: {
    id: null,
    status: LayerExperimentEnums.ChangeStatuses.INITIAL,
    type: LayerExperimentEnums.ChangeTypes.INSERT_HTML,
    async: false,
    operator: '',
    selector: '',
    elementCount: 0,
    elementInfo: [],
    value: '',
    dependencies: [],
  },
  currentlyEditingInsertImageChange: {
    id: null,
    status: LayerExperimentEnums.ChangeStatuses.INITIAL,
    type: LayerExperimentEnums.ChangeTypes.INSERT_IMAGE,
    async: false,
    operator: '',
    selector: '',
    elementCount: 0,
    elementInfo: [],
    value: '',
    dependencies: [],
  },
  currentlyEditingRedirectChange: {
    id: null,
    status: LayerExperimentEnums.ChangeStatuses.INITIAL,
    type: LayerExperimentEnums.ChangeTypes.REDIRECT,
    dest: null,
    dest_fn: null,
    isRedirectUrl: true,
    preserveParameters: null,
    allowAdditionalRedirect: null,
  },
  currentSelector: null,
  currentSelectorInputType: null,
  editorState: {
    elementSelectorEnabled: false,
    isEditingSelector: false,
  },
  selectedViewId: null,
  selectedVariationId: null,
  selectedExperimentOrSectionId: null, // this is only needed when the selectedVariationId belongs
  // to a pseudo variation, i.e. the holdback
};

/**
 * currentlyEditingChangeStore
 * Responsible for the following state management:
 * tracking the state of the p13n editor's currently editing change
 */
export default NuclearStore({
  getInitialState() {
    return toImmutable(INITIAL_STATE);
  },

  initialize() {
    this.on(actionTypes.P13N_CURRENT_CHANGE_RESET_IS_DIRTY, resetIsDirty);
    this.on(
      actionTypes.P13N_CURRENT_CHANGE_REMOVE_CSS_PROPERTY,
      removeCSSProperty,
    );
    this.on(
      actionTypes.P13N_CURRENT_CHANGE_SET_ATTRIBUTES_PROPERTY,
      setChangeAttributesProperty,
    );
    this.on(
      actionTypes.P13N_CURRENT_CHANGE_SET_REARRANGE_PROPERTY,
      setChangeRearrangeProperty,
    );
    this.on(
      actionTypes.P13N_CURRENT_CHANGE_SET_REDIRECT_PROPERTY,
      setChangeRedirectProperty,
    );
    this.on(
      actionTypes.P13N_CURRENT_CHANGE_SET_ASYNC_PROPERTY,
      setChangeAsyncProperty,
    );
    this.on(actionTypes.P13N_CURRENT_CHANGE_SET_CSS_PROPERTY, setCSSProperty);
    this.on(actionTypes.P13N_CURRENT_CHANGE_SET_NAME, setChangeName);
    this.on(actionTypes.P13N_CURRENT_CHANGE_SET_SELECTOR, setChangeSelector);
    this.on(
      actionTypes.P13N_EDITOR_SET_CHANGE_EDITOR_ELEMENT_SELECTOR_ENABLED,
      setChangeEditorElementSelectorEnabled,
    );
    this.on(
      actionTypes.P13N_EDITOR_SET_CHANGE_EDITOR_IS_EDITING_SELECTOR,
      setChangeEditorIsEditingSelector,
    );
    this.on(
      actionTypes.P13N_CHANGE_EDITOR_SET_INITIAL_SELECTOR,
      setInitialSelector,
    );
    this.on(
      actionTypes.P13N_CHANGE_EDITOR_SET_CURRENT_SELECTOR,
      setCurrentSelector,
    );
    this.on(
      actionTypes.P13N_CHANGE_EDITOR_SET_CURRENT_SELECTOR_TYPE,
      setCurrentSelectorType,
    );
    this.on(
      actionTypes.P13N_EDITOR_SET_CURRENTLY_EDITING_CHANGE,
      setCurrentlyEditingChange,
    );
    this.on(
      actionTypes.P13N_EDITOR_SET_CURRENTLY_EDITING_INSERT_HTML_CHANGE,
      setCurrentlyEditingInsertHTMLChange,
    );
    this.on(
      actionTypes.P13N_EDITOR_SET_CURRENTLY_EDITING_INSERT_IMAGE_CHANGE,
      setCurrentlyEditingInsertImageChange,
    );
    this.on(
      actionTypes.P13N_EDITOR_SET_CURRENTLY_EDITING_REDIRECT_CHANGE,
      setCurrentlyEditingRedirectChange,
    );
    this.on(
      actionTypes.P13N_EDITOR_SET_CURRENT_CHANGE_ELEMENT_INFO,
      setElementInfo,
    );
    this.on(
      actionTypes.P13N_EDITOR_UNSET_CURRENTLY_EDITING_CHANGE,
      resetInitialState,
    );
    this.on(actionTypes.P13N_EDITOR_SELECT_VIEW, selectView);
    this.on(actionTypes.P13N_EDITOR_UNSELECT_VIEW, unselectView);
    this.on(actionTypes.P13N_EDITOR_SELECT_VARIATION, selectVariation);
    this.on(actionTypes.P13N_EDITOR_UNSELECT_VARIATION, unselectVariation);
    this.on(actionTypes.P13N_INSERT_HTML_SET_HTML_VALUE, setInsertHTMLValue);
    this.on(actionTypes.P13N_INSERT_HTML_SET_OPERATOR, setInsertHTMLOperator);
    this.on(
      actionTypes.P13N_EDITOR_SELECT_EXPERIMENT_ID,
      selectExperimentOrSectionId,
    );
    this.on(
      actionTypes.P13N_EDITOR_UNSELECT_EXPERIMENT_ID,
      unselectExperimentOrSectionId,
    );
    this.on(
      actionTypes.P13N_EDITOR_SET_CURRENTLY_EDITING_CHANGE_DEPENDENCIES,
      setCurrentlyEditingChangeDependencies,
    );
    this.on(
      actionTypes.P13N_EDITOR_SET_CHANGE_LIST_SIDEBAR_COMPONENT_CONFIG,
      setChangeListSidebarComponentConfig,
    );
  },
});

/**
 * Resets the currently editing change to initial state.
 */
function resetInitialState(state) {
  return state
    .setIn(
      [CURRENTLY_EDITING_CHANGE_KEY],
      toImmutable(cloneDeep(INITIAL_STATE[CURRENTLY_EDITING_CHANGE_KEY])),
    )
    .setIn(['currentSelector'], INITIAL_STATE.currentSelector)
    .setIn(['editorState'], toImmutable(INITIAL_STATE.editorState));
}

function setCurrentlyEditingChangeDependencies(state, payload) {
  return state.setIn(
    [CURRENTLY_EDITING_CHANGE_KEY, 'dependencies'],
    payload.dependencies,
  );
}

function setChangeAttributesProperty(state, payload) {
  return state
    .setIn(
      [CURRENTLY_EDITING_CHANGE_KEY, 'attributes', payload.type],
      payload.value,
    )
    .setIn(
      [CURRENTLY_EDITING_CHANGE_KEY, 'shouldSaveFlags', payload.type],
      payload.shouldSaveFlag,
    );
}

function setChangeRearrangeProperty(state, payload) {
  return state.mergeIn(
    [CURRENTLY_EDITING_CHANGE_KEY, 'rearrange'],
    payload.data,
  );
}

function setChangeAsyncProperty(state, payload) {
  return state.mergeIn([CURRENTLY_EDITING_CHANGE_KEY, 'async'], payload.async);
}

function setChangeRedirectProperty(state, payload) {
  return state.mergeIn([CURRENTLY_EDITING_CHANGE_KEY], payload.data);
}

function setChangeSelector(state, payload) {
  if (
    state.getIn([CURRENTLY_EDITING_CHANGE_KEY, 'type']) ===
    LayerExperimentEnums.ChangeTypes.INSERT_HTML
  ) {
    return state.setIn(
      [CURRENTLY_EDITING_CHANGE_KEY, 'selector'],
      payload.selector,
    );
  }
  return state
    .setIn([CURRENTLY_EDITING_CHANGE_KEY, 'selector'], payload.selector)
    .setIn([CURRENTLY_EDITING_CHANGE_KEY, 'selectorIsDirty'], payload.isDirty);
}

/**
 * set the change name
 *
 * @param {object} state
 * @param {object} payload
 * @param {object} payload.name
 */
function setChangeName(state, payload) {
  return state
    .setIn([CURRENTLY_EDITING_CHANGE_KEY, 'name'], payload.name)
    .setIn([CURRENTLY_EDITING_CHANGE_KEY, 'nameIsDirty'], true);
}

/**
 * reset the dirtiness of the currently editing change (attributes and name)
 *
 * @param {object} state
 */
function resetIsDirty(state) {
  return state
    .setIn([CURRENTLY_EDITING_CHANGE_KEY, 'nameIsDirty'], false)
    .setIn([CURRENTLY_EDITING_CHANGE_KEY, 'selectorIsDirty'], false)
    .setIn(
      [CURRENTLY_EDITING_CHANGE_KEY, 'shouldSaveFlags'],
      toImmutable(INITIAL_STATE.currentlyEditingChange.shouldSaveFlags),
    );
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {object} payload.change
 * @param {object} payload.type
 */
function setCurrentlyEditingChange(state, payload) {
  const immutableChange = toImmutable(payload.change);
  const newBaseState = toImmutable(INITIAL_STATE.currentlyEditingChange);
  return state.set(
    CURRENTLY_EDITING_CHANGE_KEY,
    newBaseState.mergeDeep(immutableChange),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {object} payload.change
 * @param {object} payload.type
 */
function setCurrentlyEditingInsertHTMLChange(state, payload) {
  const immutableChange = toImmutable(payload.change);
  const newBaseState = toImmutable(
    INITIAL_STATE.currentlyEditingInsertHTMLChange,
  );
  return state.set(
    CURRENTLY_EDITING_CHANGE_KEY,
    newBaseState.mergeDeep(immutableChange),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {object} payload.change
 * @param {object} payload.type
 */
function setCurrentlyEditingInsertImageChange(state, payload) {
  const immutableChange = toImmutable(payload.change);
  const newBaseState = toImmutable(
    INITIAL_STATE.currentlyEditingInsertImageChange,
  );
  return state.set(
    CURRENTLY_EDITING_CHANGE_KEY,
    newBaseState.mergeDeep(immutableChange),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {object} payload.change
 * @param {object} payload.type
 */
function setCurrentlyEditingRedirectChange(state, payload) {
  const immutableChange = toImmutable(payload.change);
  const newBaseState = toImmutable(
    INITIAL_STATE.currentlyEditingRedirectChange,
  );
  return state.set(
    CURRENTLY_EDITING_CHANGE_KEY,
    newBaseState.mergeDeep(immutableChange),
  );
}

/**
 * Initial Selector is used to remember the previous selector value when we
 * change our selector. This allows the revert functionality on the selector
 * to work.
 *
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {string} payload.selector
 */
function setInitialSelector(state, payload) {
  return state.set('initialSelector', payload.selector);
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {string} payload.selector
 */
function setCurrentSelector(state, payload) {
  return state.set('currentSelector', payload.selector);
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {string} payload.type
 */
function setCurrentSelectorType(state, payload) {
  return state.set('currentSelectorInputType', payload.type);
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 */
function setElementInfo(state, payload) {
  return state
    .setIn(
      [CURRENTLY_EDITING_CHANGE_KEY, 'elementCount'],
      toImmutable(payload.elementCount),
    )
    .setIn(
      [CURRENTLY_EDITING_CHANGE_KEY, 'elementInfo'],
      toImmutable(payload.elementInfo),
    );
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {boolean} payload.enabled
 */
function setChangeEditorElementSelectorEnabled(state, payload) {
  return state.setIn(
    ['editorState', 'elementSelectorEnabled'],
    payload.enabled,
  );
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {boolean} payload.isEditing
 */
function setChangeEditorIsEditingSelector(state, payload) {
  return state.setIn(['editorState', 'isEditingSelector'], payload.isEditing);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 */
function selectView(state, payload) {
  return state.set('selectedViewId', payload.id);
}

/**
 * @param {Immutable.Map} state
 */
function unselectView(state) {
  return state.set('selectedViewId', null);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 */
function selectVariation(state, payload) {
  return state.set('selectedVariationId', payload.id);
}

/**
 * @param {Immutable.Map} state
 */
function unselectVariation(state) {
  return state.set('selectedVariationId', null);
}

function setInsertHTMLValue(state, payload) {
  return state.setIn([CURRENTLY_EDITING_CHANGE_KEY, 'value'], payload.html);
}

function setInsertHTMLOperator(state, payload) {
  return state.setIn(
    [CURRENTLY_EDITING_CHANGE_KEY, 'operator'],
    payload.operator,
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.property - CSS property name
 * @param {String} payload.value - Value for CSS property
 */
function setCSSProperty(state, payload) {
  return state.setIn(
    [CURRENTLY_EDITING_CHANGE_KEY, 'css', payload.property],
    payload.value,
  );
}

function removeCSSProperty(state, payload) {
  return state.removeIn([
    CURRENTLY_EDITING_CHANGE_KEY,
    'css',
    payload.property,
  ]);
}

function selectExperimentOrSectionId(state, payload) {
  return state.set(
    'selectedExperimentOrSectionId',
    payload.selectedExperimentOrSectionId,
  );
}

/**
 * @param {Immutable.Map} state
 */
function unselectExperimentOrSectionId(state) {
  return state.set('selectedExperimentOrSectionId', null);
}

function setChangeListSidebarComponentConfig(state, payload) {
  return state.set('changeListSidebarComponentConfig', payload);
}

/**
 * Holds the state of what API endpoints are stubbed for testing
 * or mocking
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
import { Store as NuclearStore } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';
import enums from '../constants';

const initialApiTimeouts = {};
initialApiTimeouts.default = null;
initialApiTimeouts[enums.DELETE] = null;
initialApiTimeouts[enums.FETCH] = null;
initialApiTimeouts[enums.FETCH_ALL] = null;
initialApiTimeouts[enums.FETCH_PAGE] = null;
initialApiTimeouts[enums.SAVE] = null;

export default NuclearStore({
  getInitialState() {
    return toImmutable({
      entityData: {},
      stubAll: false,
      timeouts: initialApiTimeouts,
    });
  },

  initialize() {
    this.on(actionTypes.API_STUB_UPDATE_ENTITY, apiStubUpdateEntity);
    this.on(actionTypes.API_STUB_ENTITIES, stubEntities);
    this.on(actionTypes.API_STUB_ALL, stubAll);
    this.on(actionTypes.SET_API_STUB_TIMEOUT, setStubTimeout);
    this.on(actionTypes.API_RESTORE_ENTITY_STUB, restoreEntityStub);
    this.on(actionTypes.API_RESTORE_ALL_ENTITY_STUBS, restoreAllEntityStubs);
  },
});

/**
 * payload {Object} entity object
 */
function apiStubUpdateEntity(state, payload) {
  return state.setIn(
    ['entityData', payload.entity, payload.instance.id],
    toImmutable(payload.instance),
  );
}

/**
 * payload {Object} mapping of entity => array of entity objects
 */
function stubEntities(state, payload) {
  const updatedEntityData = state
    .get('entityData')
    .withMutations(entityData => {
      Object.keys(payload).forEach(entityName => {
        const objects = payload[entityName];
        if (entityData.has(entityName)) {
        console.warn(`Stubbing entity ${entityName} which is already stubbed`); //eslint-disable-line
        } else {
          entityData.set(entityName, toImmutable({}));
        }
        objects.forEach(instance => {
          entityData.setIn([entityName, instance.id], toImmutable(instance));
        });
      });
    });

  return state.set('entityData', updatedEntityData);
}

function stubAll(state, payload) {
  return state.set('stubAll', true);
}

/**
 * payload {Object} indicating what timeouts to apply
 */
function setStubTimeout(state, payload) {
  return state.set('timeouts', payload.timeouts);
}

/**
 * Unstubs an entity (or multiple entities)
 */
function restoreEntityStub(state, payload) {
  if (payload.entity) {
    return state.deleteIn(['entityData', payload.entity]);
  }
  if (payload.entities) {
    let chainedState = state;
    payload.entities.forEach(entity => {
      chainedState = chainedState.deleteIn(['entityData', entity]);
    });
    return chainedState;
  }
}

/**
 * Unstubs all entity stubs
 */
function restoreAllEntityStubs(state, payload) {
  return state.set('entityData', toImmutable({}));
}

/**
 * Entity Definition for commits
 */
export default {
  entity: 'commits',

  parent: {
    entity: 'layers',
    key: 'layer_id',
  },
};

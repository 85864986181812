/**
 * Entity Definition for Webhook
 */
export const entity = 'webhooks';

export const parent = {
  entity: 'projects',
  key: 'project_id',
};

export const fields = {
  id: null,
  project_id: null,
  url: null,
  active: null,
  validated: null,
  events: [],
  secret: null,
};

export default {
  entity,
  fields,
  parent,
};

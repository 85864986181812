import sort from 'optly/utils/sort';

const { ASC } = sort;
const { DESC } = sort;

const DEFAULT_SORT_DIR = ASC;

/**
 * Sort direction to use if one isn't specified the first time a field
 * is toggled
 */
const defaultSortDirs = {
  string: ASC,
  number: DESC,
  length: DESC,
  date: DESC,
  boolean: DESC,
};

export const getDefaultSortDir = fieldType =>
  defaultSortDirs[fieldType] || DEFAULT_SORT_DIR;

export default {
  getDefaultSortDir,
};

import Immutable from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';

import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import { EXPERIMENT_ENVIRONMENT_STATUS } from 'optly/modules/entity/layer_experiment/human_readable';

import EnvironmentsStatusOverview from 'bundles/p13n/components/environments/environments_status_overview';

function ExperimentStatusWithEnvironments({
  experiment,
  experimentEnvironmentsInfo,
}) {
  return (
    <EnvironmentsStatusOverview
      isArchived={
        experiment.get('actual_status') ===
        LayerExperimentEnums.ActualStatus.ARCHIVED
      }
      environmentsInfo={experimentEnvironmentsInfo}
      getStatusDescription={environment =>
        EXPERIMENT_ENVIRONMENT_STATUS[environment.get('status')]
      }
    />
  );
}

ExperimentStatusWithEnvironments.propTypes = {
  /* LayerExperiment API entity */
  experiment: PropTypes.instanceOf(Immutable.Map).isRequired,
  /*
   *  List of Maps representing the status of this experiment in each
   *  environment, sorted by environment priority.
   */
  experimentEnvironmentsInfo: PropTypes.instanceOf(Immutable.List).isRequired,
};

export default ExperimentStatusWithEnvironments;

import React from 'react';
import PropTypes from 'prop-types';
import sprintf from 'sprintf';
import { Attention, Button, ButtonRow, Code, Link } from 'optimizely-oui';

import OptimizelySdkActions from '@optimizely/js-sdk-lab/src/actions';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import { feature } from '@optimizely/js-sdk-lab/src/decorators';

import LoadingOverlay from 'react_components/loading_overlay';
import { Form, formPropType } from 'react_components/form';

import flux from 'core/flux';
import ui from 'core/ui';
import { connect } from 'core/ui/decorators';
import Immutable, { toImmutable } from 'optly/immutable';
import regexUtils from 'optly/utils/regex';

// modules
import AudienceEnums from 'optly/modules/entity/audience/enums';
import EnvironmentFns from 'optly/modules/entity/environment/fns';
import EnvironmentGetters from 'optly/modules/entity/environment/getters';
import FeatureFlagActions from 'optly/modules/entity/feature_flag/actions';
import FeatureFlagConstants from 'optly/modules/entity/feature_flag/constants';
import FeatureFlagFns from 'optly/modules/entity/feature_flag/fns';
import CurrentProjectActions from 'optly/modules/current_project/actions';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import LayerExperimentFns from 'optly/modules/entity/layer_experiment/fns';
import LayerExperimentGetters from 'optly/modules/entity/layer_experiment/getters';
import PermissionsFns from 'optly/modules/permissions/fns';
import PermissionsGetters from 'optly/modules/permissions/getters';
import { sdkSyntaxLanguage } from 'optly/modules/entity/project/enums';

// components
import AudienceCombinationsBuilderLegacy from 'bundles/p13n/components/audience_combinations_builder_legacy'; // eslint-disable-line import/no-cycle
import AudienceCombinationsBuilderNew from 'bundles/p13n/components/audience_combinations_builder'; // eslint-disable-line import/no-cycle
import CodeSamplePicker from 'bundles/p13n/components/code_sample_picker';
import FeatureSectionModuleEnums from 'bundles/p13n/sections/features/section_module/enums';
import { variableBeginsWithNumber } from 'bundles/p13n/sections/oasis_experiment_manager/section_module/fns';

import ComponentModuleFns from './component_module/fns';
import EnvironmentsDropdown from './subcomponents/environments_dropdown';
import ExperimentsTable from './components/experiments_table';
import FeatureDetails from './subcomponents/feature_details';
import FeatureVariables from './subcomponents/variables';
import Rollout from './subcomponents/rollout';

const { ANY_AUDIENCES } = AudienceEnums.audienceMatchTypes;

@connect(props => ({
  canModifyRunningExperiment: [
    CurrentProjectGetters.project,
    PermissionsFns.canModifyRunningExperiment,
  ],
  canUseFeatureVariables: PermissionsGetters.canAccountUseFeatureVariables,
  currentProject: CurrentProjectGetters.project,
  environmentsByKey: EnvironmentGetters.byKey,
  experimentsUsingFeature: [
    LayerExperimentGetters.entityCache,
    function(layerExperiments) {
      return layerExperiments
        .filter(
          experiment =>
            experiment.get('feature_flag_id') &&
            experiment.get('feature_flag_id') === props.feature.get('id'),
        )
        .toList();
    },
  ],
  environmentsSortedByPriority:
    EnvironmentGetters.unarchivedEnvironmentsSortedByPriority,
}))
@Form({
  mapPropsToFormData: ({ feature: featureFromProp }) =>
    toImmutable({
      feature: featureFromProp,
      rolloutTraffic: featureFromProp.get('environments').map(envInfo => {
        const percentageIncluded = envInfo.getIn([
          'rollout_rules',
          0,
          'percentage_included',
        ]);
        return FeatureFlagFns.formatPercentageIncluded(percentageIncluded);
      }),
    }),
  debounceTimeout: __TEST__ ? 0 : 100,
})
@feature('user_friendly_names')
class FeatureDialogComponent extends React.Component {
  static propTypes = {
    canManageFeatureFlags: PropTypes.bool.isRequired,
    canModifyRunningExperiment: PropTypes.bool.isRequired,
    canUseFeatureVariables: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    environmentsByKey: PropTypes.instanceOf(Immutable.Map).isRequired,
    environmentsSortedByPriority: PropTypes.instanceOf(Immutable.List)
      .isRequired,
    experimentsUsingFeature: PropTypes.instanceOf(Immutable.List).isRequired,
    feature: PropTypes.instanceOf(Immutable.Map).isRequired,
    form: formPropType.isRequired,
    isMobileOnly: PropTypes.bool.isRequired,
    save: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const lowestPriorityEnvKey = props.environmentsSortedByPriority
      .last()
      .get('key');

    this.state = {
      isEditing: !!props.feature.get('id'),
      isSaving: false,
      isValidAudienceConfig: true,
      selectedEnvironmentKey: lowestPriorityEnvKey,
    };

    const { currentProject, form } = props;
    const featureField = form.field('feature');

    featureField.field('name').validators(
      {
        isValid: name => {
          if (this.user_friendly_names && !name) {
            return 'error';
          }
        },
      },
      {
        debounceTimeout: __TEST__ ? 0 : 1000,
      },
    );

    featureField.field('api_name').validators(
      {
        isValid: apiName => {
          if (!regexUtils.apiName.test(apiName)) {
            return 'error';
          }
        },
        isUnique: apiName => {
          const { feature: initialFeature } = props;
          if (
            initialFeature.get('id') &&
            initialFeature.get('api_name') === apiName
          ) {
            return Promise.resolve();
          }

          const keyValidationFetch = FeatureFlagActions.fetchByApiName(
            currentProject.get('id'),
            apiName,
          ).then(result => {
            if (result.length > 0) {
              return 'error';
            }

            return Promise.resolve();
          });
          if (this.user_friendly_names) {
            ui.loadingWhen('feature_key_validation', keyValidationFetch);
          }
          return keyValidationFetch;
        },
      },
      {
        debounceTimeout: __TEST__ ? 0 : 1000,
        validateOnChange: this.user_friendly_names,
      },
    );

    featureField.repeatedField('variables').setupRepeatedValidators({
      api_name: {
        isUnique: (apiName, formState) => {
          if (!apiName) {
            return;
          }
          if (
            formState
              .getIn(['feature', 'variables'])
              .filter(v => !v.get('archived')) // ignore other archived variables
              .count(v => v.get('api_name') === apiName) > 1
          ) {
            return 'error';
          }
        },
        isValid: apiName => {
          if (!regexUtils.variableName.test(apiName)) {
            return 'error';
          }
        },
      },
      '*': {
        isDefaultValueValid: variable => {
          if (
            !FeatureFlagFns.isVariableValueInputValid(
              variable.get('default_value'),
              variable.get('type'),
            )
          ) {
            return 'error';
          }
        },
      },
    });

    form.field('rolloutTraffic').validators(
      {
        isValid: rolloutTraffic => {
          if (
            rolloutTraffic.some(
              ComponentModuleFns.rolloutTrafficAllocationIsInvalid,
            )
          ) {
            return 'error';
          }
        },
      },
      { validateOnChange: true },
    );
  }

  /**
   * Given original and changed versions of a feature, returns true if a warning
   * dialog should be shown before saving the changed version.
   * @returns {Boolean}
   */
  shouldConfirmFeatureEdit = () => {
    const { form, feature: initialFeature } = this.props;
    const featureField = form.field('feature');
    const apiNameField = featureField.field('api_name');
    const variablesField = featureField.field('variables');
    return (
      apiNameField.getValue() !== initialFeature.get('api_name') ||
      !Immutable.is(variablesField.getValue(), initialFeature.get('variables'))
    );
  };

  shouldConfirmFeatureTestEdit = () => {
    const { experimentsUsingFeature } = this.props;
    return (
      this.shouldConfirmFeatureEdit() &&
      experimentsUsingFeature.some(
        LayerExperimentFns.isRunningInAnyEnvironment.bind(LayerExperimentFns),
      )
    );
  };

  canSave = () => {
    const { canManageFeatureFlags, form } = this.props;
    const { isValidAudienceConfig, isSaving } = this.state;

    if (!canManageFeatureFlags) {
      return false;
    }

    if (!isValidAudienceConfig) {
      return false;
    }

    // make sure there is only ever 1 save in progress
    if (isSaving) {
      return false;
    }

    return form.isFormValid();
  };

  createTargetingRules = () => {
    this.setState(prevState => ({
      feature: prevState.feature.setIn(['targeting_rules'], toImmutable([])),
    }));
  };

  handleSaveClick = () => {
    const { isEditing } = this.state;
    const warningDialogConfig = {
      title: tr('Confirm Change'),
      message:
        "Incorrectly modifying this feature's settings may cause unintended behavior in your application. Confirm you want to make this change.",
      confirmText: 'Confirm',
      cancelText: 'Cancel',
      doSanitizeHTML: false,
    };
    if (isEditing && this.shouldConfirmFeatureTestEdit()) {
      warningDialogConfig.message = `This feature is currently being used in a running experiment. ${warningDialogConfig.message}`;
      ui.confirm(warningDialogConfig).then(() => {
        this.saveFeature();
      });
    } else if (isEditing && this.shouldConfirmFeatureEdit()) {
      ui.confirm(warningDialogConfig).then(() => {
        this.saveFeature();
      });
    } else {
      this.saveFeature();
    }
  };

  saveFeature = () => {
    const { form, save } = this.props;
    const { isEditing } = this.state;

    const featureField = form.field('feature');
    const rolloutTrafficField = form.field('rolloutTraffic');

    this.setState({
      isSaving: true,
    });

    form
      .validate()
      .then(() => {
        const updatedEnvironments = featureField
          .field('environments')
          .getValue()
          .map((env, envName) => {
            const rolloutTrafficInEnv = FeatureFlagFns.parseTraffic(
              rolloutTrafficField.getValue().get(envName),
            );
            return env.setIn(
              ['rollout_rules', 0, 'percentage_included'],
              rolloutTrafficInEnv,
            );
          });

        const updatedVariables = featureField
          .field('variables')
          .getValue()
          .map(variable => {
            if (
              variable.get('type') ===
              FeatureFlagConstants.FEATURE_VARIABLE_TYPES.json
            ) {
              return variable.set(
                'default_value',
                JSON.stringify(JSON.parse(variable.get('default_value'))),
              );
            }
            return variable;
          });

        const featureToSave = featureField
          .getValue()
          .set('project_id', flux.evaluate(CurrentProjectGetters.id))
          .set('environments', updatedEnvironments)
          .set('variables', updatedVariables);

        return save(featureToSave);
      })
      .then(() => {
        if (!isEditing) {
          OptimizelySdkActions.track('fs_feature_created');
        }
      })
      .finally(() => this.setState({ isSaving: false }));
  };

  /**
   * Function that will be called with the following args when AudienceCombinationsBuilder is updated
   *
   * @param {Object} config
   * @param {Immutable.List} config.audienceConditions
   * @param {Boolean} config.isValidAudienceConfig
   */
  handleAudienceSelectionChange = ({
    audienceConditions,
    isValidAudienceConfig,
  }) => {
    const { form } = this.props;
    const environmentsField = form.field('feature').field('environments');
    environmentsField.setValue(
      environmentsField
        .getValue()
        .map(prevEnvInfo =>
          prevEnvInfo.setIn(
            ['rollout_rules', 0, 'audience_conditions_json'],
            audienceConditions,
          ),
        ),
    );

    // TODO(APPX-30) Only set audience for selectedEnvironment when environments are allowed different Audience configs
    // TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
    this.setState({
      isValidAudienceConfig,
    });
  };

  /**
   * Update status on the first targeting rule in the currently selected environment
   * @param {String} status
   */
  onRolloutStatusChange = status => {
    const { form } = this.props;
    const { selectedEnvironmentKey } = this.state;
    const environmentsField = form.field('feature.environments');
    environmentsField.setValue(
      environmentsField
        .getValue()
        .setIn([selectedEnvironmentKey, 'rollout_rules', 0, 'status'], status),
    );
  };

  /**
   * Update currently selected environment
   * @param {String} newSelectedEnvKey
   */
  onSelectedEnvironmentKeyChange = newSelectedEnvKey => {
    this.setState({
      selectedEnvironmentKey: newSelectedEnvKey,
    });
  };

  renderCodeBlock = () => {
    const featuresLink = CurrentProjectActions.getHelpCopy(
      'features_link',
      'https://docs.developers.optimizely.com/full-stack/docs/use-feature-flags',
    );
    return (
      <div className="push-triple--top">
        <CodeSamplePicker
          description={
            <span>
              The Is Feature Enabled method returns a boolean. Use this boolean
              to conditionally change, show, or hide a feature.&nbsp;
              <Link href={featuresLink} newWindow={true}>
                Learn more.
              </Link>
            </span>
          }
          getCodeSample={this.renderFeatureCode}
          showMobileOnly={this.props.isMobileOnly}
          title={tr('Example Code')}
        />
      </div>
    );
  };

  renderFeatureCode = codeSampleLanguage => {
    const { form } = this.props;
    const featureKey = form
      .field('feature')
      .field('api_name')
      .getValue();
    const variables =
      form
        .field('feature')
        .field('variables')
        .getValue() || Immutable.List();

    let codeBlock = sprintf(
      FeatureSectionModuleEnums.FEATURE_CODE_BLOCKS[codeSampleLanguage],
      featureKey,
    );
    if (featureKey === '') {
      codeBlock =
        FeatureSectionModuleEnums.FEATURE_CODE_BLOCKS_DEFAULT[
          codeSampleLanguage
        ];
    } else if (variables.size) {
      variables.forEach(variable => {
        if (variable.get('archived') !== true) {
          const variableName = variable.get('api_name');
          // make adjustments for hyphens and keys that begin with numbers
          let variableKey = variableName.replace(/-/gi, '_');
          if (variableBeginsWithNumber(variableKey)) {
            variableKey = `variable_${variableKey}`;
          }
          const variableType = variable.get('type');
          codeBlock += `\n${sprintf(
            FeatureSectionModuleEnums.VARIABLE_CODE_BLOCKS[codeSampleLanguage][
              variableType
            ],
            variableKey,
            featureKey,
            variableName,
          )}`;
        }
      });
    }

    if (featureKey !== '') {
      codeBlock +=
        FeatureSectionModuleEnums.FEATURE_CODE_BLOCKS_POSTFIX[
          codeSampleLanguage
        ] || '';
    }

    return (
      <Code
        isHighlighted={true}
        hasCopyButton={true}
        language={sdkSyntaxLanguage[codeSampleLanguage] || codeSampleLanguage}
        type="block"
        testSection="feature-code-sample">
        {codeBlock}
      </Code>
    );
  };

  renderPermissionsAttention = canEditNonRolloutFields => {
    const { canManageFeatureFlags } = this.props;
    const { isEditing } = this.state;

    if (isEditing && canManageFeatureFlags && !canEditNonRolloutFields) {
      return (
        <div className="push-double--bottom">
          <Attention
            alignment="center"
            testSection="feature-edit-running-restricted-environment-warning"
            type="warning">
            To edit all fields, this Feature can&apos;t be running in a
            restricted Environment.
          </Attention>
        </div>
      );
    }
    return null;
  };

  render() {
    const { isEditing, isSaving, selectedEnvironmentKey } = this.state;
    const {
      cancel,
      canManageFeatureFlags,
      canModifyRunningExperiment,
      canUseFeatureVariables,
      currentProject,
      environmentsByKey,
      environmentsSortedByPriority,
      experimentsUsingFeature,
      form,
    } = this.props;

    const featureField = form.field('feature');
    const rolloutTrafficField = form.field('rolloutTraffic');
    const nameField = featureField.field('name');
    const apiNameField = featureField.field('api_name');
    const descriptionField = featureField.field('description');
    const variablesField = featureField.repeatedField('variables');
    const environmentsField = featureField.field('environments');

    const rolloutRuleOfSelectedEnv = form
      .field('feature')
      .getValue()
      .getIn(['environments', selectedEnvironmentKey, 'rollout_rules', 0]);
    const trafficInSelectedEnv = rolloutTrafficField
      .getValue()
      .get(selectedEnvironmentKey);

    const environmentsDropdown = (
      <EnvironmentsDropdown
        environments={environmentsSortedByPriority}
        onSelectedEnvironmentKeyChange={this.onSelectedEnvironmentKeyChange}
        selectedEnvironmentKey={selectedEnvironmentKey}
        environmentsField={environmentsField}
        rolloutTrafficField={rolloutTrafficField}
      />
    );

    const currentEnvironment = environmentsByKey.get(selectedEnvironmentKey);

    // See https://confluence.sso.episerver.net/display/EXPENG/Collaborator+Permissions+for+Feature+Rollouts+and+Tests for more info on Full Stack Feature permissions
    // Collaborators with the "Editor" role should not be able to edit Feature Details, Variables, or Audiences
    const canEditNonRolloutFields =
      canModifyRunningExperiment ||
      (canManageFeatureFlags &&
        !FeatureFlagFns.isFeatureRunningInRestrictedEnvironment(
          form.field('feature').getValue(),
          environmentsByKey,
        ));

    const hasDetailsDisabled = !canEditNonRolloutFields;
    const hasVariablesDisabled = !canEditNonRolloutFields;
    const isRolloutDisabled = !EnvironmentFns.canStartAndPause(
      currentProject,
      currentEnvironment,
    );
    const hasAudiencesDisabled = !canEditNonRolloutFields;
    const audiencesLink = CurrentProjectActions.getHelpCopy(
      'audiences_link',
      'https://docs.developers.optimizely.com/full-stack/docs/define-audiences-and-attributes',
    );

    // TODO(MGMT-2940): Remove the audience_combo_reskin feature flag once rolled out to 100%
    const audienceComboProps = {
      audienceConditions: rolloutRuleOfSelectedEnv.get(
        'audience_conditions_json',
      ),
      canEditAudience: !hasAudiencesDisabled,
      defaultAudienceMatchType: ANY_AUDIENCES,
      onSelectionChange: this.handleAudienceSelectionChange,
      inForm: true,
      poptipContent: (
        <p>
          Make this feature available only to certain users.&nbsp;
          <Link href={audiencesLink} newWindow={true}>
            Learn more.
          </Link>
        </p>
      ),
    };
    return (
      <div
        className="reading-column position--relative"
        data-test-section="feature-dialog">
        <LoadingOverlay isLoading={isSaving}>
          {this.renderPermissionsAttention(canEditNonRolloutFields)}
          <FeatureDetails
            apiNameField={apiNameField}
            descriptionField={descriptionField}
            isDisabled={hasDetailsDisabled}
            isEditing={isEditing}
            nameField={nameField}
          />
          {canUseFeatureVariables && (
            <FeatureVariables
              isDisabled={hasVariablesDisabled}
              variablesField={variablesField}
            />
          )}
          {isEditing && (
            <ExperimentsTable
              experimentsUsingFeature={experimentsUsingFeature}
            />
          )}
          <Rollout
            disableInputs={isRolloutDisabled}
            environmentsDropdown={environmentsDropdown}
            key={selectedEnvironmentKey}
            onStatusChange={this.onRolloutStatusChange}
            onTrafficChange={traffic =>
              rolloutTrafficField
                .field(selectedEnvironmentKey)
                .setValue(traffic)
            }
            showError={ComponentModuleFns.rolloutTrafficAllocationIsInvalid(
              trafficInSelectedEnv,
            )}
            status={rolloutRuleOfSelectedEnv.get('status')}
            traffic={trafficInSelectedEnv}
          />
          <div className="push-quad--top">
            <OptimizelyFeature feature="audience_combo_reskin">
              {isEnabled =>
                isEnabled ? (
                  <AudienceCombinationsBuilderNew {...audienceComboProps} />
                ) : (
                  <LoadingOverlay loadingId="fetchAudiences">
                    <AudienceCombinationsBuilderLegacy
                      {...audienceComboProps}
                    />
                  </LoadingOverlay>
                )
              }
            </OptimizelyFeature>
          </div>
          {this.renderCodeBlock(form.field('feature').getValue())}
          <div className="lego-form__footer">
            <div className="flex flex-align--center">
              <div className="oui-sheet__required-indicator cursor--default color--red">
                <span>* Required field</span>
              </div>
              <div className="flex--1">
                <ButtonRow
                  rightGroup={[
                    <Button
                      key="btn-cancel"
                      style="plain"
                      testSection="feature-dialog-cancel"
                      onClick={cancel}>
                      Cancel
                    </Button>,
                    <Button
                      key="btn-save"
                      style="highlight"
                      testSection="feature-dialog-save"
                      isDisabled={!this.canSave()}
                      onClick={this.handleSaveClick}>
                      {isEditing ? 'Save' : 'Create Feature'}
                    </Button>,
                  ]}
                />
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default FeatureDialogComponent;

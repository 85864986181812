const Nav = require('NAVBAR').default;

const flux = require('core/flux');

const CurrentProject = require('optly/modules/current_project');
const FeatureFlag = require('optly/modules/entity/feature_flag').default;
const LayerEnums = require('optly/modules/entity/layer/enums');
const LayerExperiment = require('optly/modules/entity/layer_experiment');
const NavConstants = require('optly/services/navigation').default;
const SectionModuleEnums = require('bundles/p13n/sections/features_old/section_module/enums');

exports.routingSetup = function(ctx, next) {
  const byProject = {
    project_id: flux.evaluate(CurrentProject.getters.id),
  };

  Nav.actions.setActiveNavItem(NavConstants.NavItems.FEATURESOLD);
  Nav.actions.setActiveTab(
    NavConstants.NavItems.FEATURESOLD,
    SectionModuleEnums.NAV_TABS.FEATURESOLD,
  );

  // This is used in the feature dialog so we can defer and not wait for it
  LayerExperiment.actions.fetchAll(
    {
      project_id: byProject.project_id,
      // Only want single experiment layer experiments, not layer experiments that
      // are targeting rules inside a rollout
      layer_policy: LayerEnums.policy.SINGLE_EXPERIMENT,
    },
    { skipEvaluatingCachedData: true },
  );

  FeatureFlag.actions.fetchAll(byProject).then(next);
};

exports.component = require('./page_component');

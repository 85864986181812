import React from 'react';

import flux from 'core/flux';
import BundleSplitHelper from 'optly/utils/bundle_split_helper';
import NavConstants from 'optly/services/navigation';
import FilterableTableEnums from 'optly/modules/filterable_table/enums';
import { actions as PerformanceTrackingActions } from 'optly/modules/performance_tracking';
import urls from 'optly/services/url_helper_legacy';
import PermissionsGetters from 'optly/modules/permissions/getters';
import Router from 'core/router';

import RoutingFns from '../../routing_fns';

const category = 'Audiences';

let AudiencesSection;
let AudiencesSectionModule;

/*
 * Routing function that ensures the Audiences dynamic bundle has been fetched
 */
const EnsureAudiencesBundle = (ctx, next) => {
  BundleSplitHelper.getAudiencesBundleModules()
    .then(modules => {
      ({ AudiencesSection, AudiencesSectionModule } = modules);
    })
    .then(next);
};

/*
 * Routes for the audiences section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/projects/:proj_id/audiences/attributes',
    metadata: { name: 'Attributes', category },
    shouldHandle: () =>
      Promise.resolve(flux.evaluate(PermissionsGetters.canUseCustomAttributes)),
    handle: [
      [
        EnsureAudiencesBundle,
        ...RoutingFns.standardNavHandlers(
          null,
          NavConstants.NavItems.AUDIENCES,
        ),
        RoutingFns.parseProjectId,
      ],
      (ctx, next) => {
        RoutingFns.parseQueryFilters(
          AudiencesSectionModule.constants.tableIds.CUSTOM_ATTRIBUTES,
        )(ctx, next);
      },
      (ctx, next) =>
        AudiencesSection.pages.attributes_dashboard.routingSetup(ctx, next),
      RoutingFns.fetchDatasourcesForCurrentProject,
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Attributes',
          next,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <AudiencesSection.pages.attributes_dashboard.component />,
        ),
    ],
  },
  {
    match: '/v2/projects/:proj_id/audiences/:audience_id?',
    metadata: { category },
    handle: [
      [
        EnsureAudiencesBundle,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.AUDIENCES,
        ),
        RoutingFns.parseProjectId,
        RoutingFns.parseQueryFilters(
          FilterableTableEnums.tableIds.P13N_DATA_LAYER_AUDIENCES,
        ),
      ],
      (ctx, next) =>
        AudiencesSection.pages.audiences_dashboard.routingSetup(ctx, next),
      (ctx, next) => {
        const audienceId = Number(ctx.params.audience_id);
        PerformanceTrackingActions.setFrontendRenderStartTime();
        RoutingFns.renderMainRegion(
          <AudiencesSection.pages.audiences_dashboard.component
            audienceId={audienceId}
          />,
        );
        next();
      },
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          next,
        }),
    ],
  },
  {
    /* Handle bookmarked DCP attributes page */
    match: '/v2/projects/:proj_id/implementation/attributes',
    handle: [
      ctx => Router.go(urls.p13nAttributesDashboard(ctx.params.proj_id)),
    ],
  },
];

export default routes;

import React from 'react';

import parseQueryParams from 'optly/utils/parse_query_params';

import NavConstants from 'optly/services/navigation';

import RoutingFns from 'bundles/p13n/routing_fns';

import WelcomeSection from '.';

export default [
  {
    match: '/v2/welcome',
    handle: [
      [
        ...RoutingFns.standardNavHandlers(NavConstants.NavWidth.HIDDEN),
        RoutingFns.parseProjectId,
      ],
      ctx => {
        // pull out the project_type if the user has already selected this previously in the flow
        const params = parseQueryParams(ctx.canonicalPath || '');
        RoutingFns.renderMainRegion(
          <WelcomeSection.pages.createProject.component
            projectType={params.project_type}
          />,
        );
      },
    ],
  },
];

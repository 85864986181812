import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

/**
 * Getter to return all catalogs (recommender services) in the current account.
 * @returns {Immutable.List}
 */
export const catalogsAsList = () => [
  baseEntityGetters.entityCache,
  catalogs => catalogs.toList(),
];

export default {
  ...baseEntityGetters,
  catalogsAsList,
};

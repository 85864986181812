/**
 * Api service configured to work with the Experiment API
 *
 * Largely influenced by Restangular
 *
 * Usage:
 * ====================================================================
 * var api = require('optly/services/api');
 * var project = api().one('projects', 4001)
 * project.get().then(function(data) {
 *   data.name = 'new name';
 *   // updates the project entity by performing a PUT request
 *   project.put(data);
 * });
 *
 * var experiments = project.all('experiments');
 * experiments.get(function(experiments) {
 *   // experiments is an Array.<Object> of experiment data
 * });
 *
 * ========== or =============
 *
 * var experiments = api().one('projects', 4001').all('experiments')
 * experiments.get().then(function(experiments) {
 *   // experiments is an Array.<Object> of experiment data
 * });
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
import config from 'atomic-config';

import requesterFactory from './requester_factory';

export default requesterFactory.create({
  baseUrl: config.get('api.baseUrl', '/api/v1'),
  headers: {
    'X-csrf-token': config.get('csrf', ''),
  },
});

import flux from 'core/flux';
import HistoryUtil from 'optly/utils/history';

import actionTypes from './action_types';
import getters from './getters';

/**
 * For the current page specified by the pageable id,
 * return the url provided with a page=<pageNumber> query param
 * appended to it, as long as the pageNumber is not 1.
 * @param {string} id
 * @param {string} url
 * @returns {string}
 */
const getPagedUrl = (id, url) => {
  const pageNumber = flux.evaluate(getters.currentPage(id));
  if (pageNumber && pageNumber > 1) {
    url += `?page=${pageNumber}`;
  }
  return url;
};

/**
 * @param {Object} options
 * @param {string} options.id - The pageable id
 * @param {number} options.currentPage - The current page number.
 */
export const saveCurrentPage = ({ id, currentPage }) => {
  flux.dispatch(actionTypes.PAGEABLE_SAVE_CURRENT_PAGE, {
    id,
    currentPage,
  });
};

/**
 * Reset the current page by pageable id
 * @param {string} id - The pageable id
 */
export const resetCurrentPage = id => {
  flux.dispatch(actionTypes.PAGEABLE_RESET_CURRENT_PAGE, {
    id,
  });
};

/**
 * Given a url path, set the page number for the pageable id
 * provided if the url contains a page=<number> query param.
 * @param {string} id - The pageable id
 * @param {Object} params - Query parameter key/value pairs
 */
export const setPageFromPath = (id, params = {}) => {
  if (params.page && Number(params.page)) {
    saveCurrentPage({
      id,
      currentPage: Number(params.page),
    });
  }
};

/**
 * Update the url via replaceState with the appropriate page number
 * (if any) for the specified pageable
 * @param {string} id - The pageable id
 * @param {string} baseUrl - The baseUrl to append the page number to.
 */
export const replaceStateWithPageNumber = (id, baseUrl) => {
  HistoryUtil.replaceState(getPagedUrl(id, baseUrl));
};

/**
 * Update the url via replaceState with the appropriate page number
 * (if any) for the specified pageable
 * @param {string} id - The pageable id
 * @param {string} baseUrl - The baseUrl to append the page number to.
 */
export const pushStateWithPageNumber = (id, baseUrl) => {
  HistoryUtil.pushState(getPagedUrl(id, baseUrl));
};

export default {
  pushStateWithPageNumber,
  resetCurrentPage,
  replaceStateWithPageNumber,
  saveCurrentPage,
  setPageFromPath,
};

import _ from 'lodash';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';
import ChampagneEnums from 'optly/modules/optimizely_champagne/enums';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export default _.extend(baseEntityGetters, {
  // implement getters here
  linksForSourceId: (sourceId, checkResourceFirst) => [
    baseEntityGetters.entityCache,
    function(links) {
      return links
        .filter(link => {
          let resourceId = '';

          // NOTE: source.extra_data.layer_id was added to support web campaign experiments.
          // We specifically needed to use layer_id instead of resource_id for campaigns.
          // But unfortunately this prevent jira-issues from displaying in the feature
          // experiments dashboard. https://optimizely.atlassian.net/browse/MGMT-2951

          if (checkResourceFirst) {
            resourceId =
              link.getIn(['source', 'resource_id']) ||
              link.getIn(['source', 'extra_data', 'layer_id']) ||
              '';
          } else {
            resourceId =
              link.getIn(['source', 'extra_data', 'layer_id']) ||
              link.getIn(['source', 'resource_id']) ||
              '';
          }

          return resourceId.toString() === sourceId.toString();
        })
        .toList();
    },
  ],
});

import PropTypes from 'prop-types';
import React from 'react';

import { Button, Dropdown, Input, Icon, Textarea } from 'optimizely-oui';

import ui from 'core/ui';

import { canUseSelectContainer } from 'optly/modules/permissions/getters';

import keyboardEnums from 'optly/utils/keyboard_enums';

import SelectContainer from './select_container';

const DEFAULT_SELECT_CONTAINER_WIDTH = 260;

export default ui.connectGetters(
  class InnerInput extends React.Component {
    static propTypes = {
      /**
       * The Vue iframe component ID to interact with
       */
      activeFrameId: PropTypes.string.isRequired,
      /**
       * Boolean signaling whether the input AND buttons should be disabled
       */
      allDisabled: PropTypes.bool.isRequired,
      /**
       * Boolean signaling whether the input alone should be disabled
       */
      inputDisabled: PropTypes.bool.isRequired,
      /**
       * Function to apply new selector state to the component consuming selector input
       */
      applyNewSelector: PropTypes.func.isRequired,
      /**
       * Boolean signaling whether user is editing selector through
       * text input
       */
      isEditingText: PropTypes.bool.isRequired,
      /**
       * Boolean signaling whether user is editing selector through
       * using the search functionality
       */
      isSearching: PropTypes.bool.isRequired,
      /**
       * Function to occur when user toggles search button off
       */
      onSearchCancel: PropTypes.func,
      /**
       * Function to occur when user toggles search button on
       */
      onSearchStart: PropTypes.func,
      /**
       * Function to occur on each keyup from text input
       */
      onTextEdit: PropTypes.func,
      /**
       * Function to occur when user clicks confirm button
       */
      onTextEditConfirm: PropTypes.func,
      /**
       * Function to occur when user clicks revert button
       */
      onTextEditRevert: PropTypes.func,
      /**
       * Function to occur when user focuses on text input
       */
      onTextEditStart: PropTypes.func,
      /**
       * The placeholder value for the input field
       */
      placeholder: PropTypes.string,
      /**
       * Optional mode to use textarea instead of input element
       */
      textareaMode: PropTypes.bool,
      /**
       * The stored value of the selector
       */
      value: PropTypes.string.isRequired,
      /**
       * The working value of the selector
       */
      workingSelector: PropTypes.string,
      /**
       * Whether the account has the feature
       */
      canUseSelectContainer: PropTypes.bool.isRequired,
    };

    static defaultProps = {
      placeholder: '',
      workingSelector: '',
      textareaMode: false,
    };

    inputRef = React.createRef();

    onClickSearch = () => {
      if (this.props.isSearching) {
        this.props.onSearchCancel();
      } else {
        this.props.onSearchStart();
      }
    };

    onKeyDown = event => {
      if (event.keyCode === keyboardEnums.ESCAPE_KEY) {
        this.inputRef.current.blur();
        this.props.onTextEditRevert();
        return;
      }
      if (event.keyCode === keyboardEnums.ENTER_KEY) {
        this.inputRef.current.blur();
        this.onTextEditConfirm();
      }
    };

    onTextEdit = event => {
      this.props.onTextEdit(event.target.value);
    };

    onTextEditConfirm = () => {
      this.props.onTextEditConfirm(this.props.value);
    };

    storeSelectorInputGroup = selectorInputGroup => {
      this.selectorInputGroup = selectorInputGroup;
    };

    renderSelectContainer = () => {
      const {
        activeFrameId,
        allDisabled,
        applyNewSelector,
        value,
        textareaMode,
      } = this.props;
      return (
        <Dropdown
          buttonContent={<Icon className="oui-icon" name="bars" size="small" />}
          isDisabled={allDisabled}
          placement="bottom-end"
          style={textareaMode ? 'outsite' : 'plain'}
          testSection="selector-input-select-container"
          width={
            (this.selectorInputGroup && this.selectorInputGroup.offsetWidth) ||
            DEFAULT_SELECT_CONTAINER_WIDTH
          }>
          <SelectContainer
            activeFrameId={activeFrameId}
            applyNewSelector={applyNewSelector}
            value={value}
          />
        </Dropdown>
      );
    };

    render() {
      const {
        allDisabled,
        canUseSelectContainer,
        inputDisabled,
        isEditingText,
        isSearching,
        onTextEditRevert,
        onTextEditStart,
        placeholder,
        value,
        workingSelector,
        textareaMode,
      } = this.props;

      /*
       * Render Select Container feature, except when any of these are true:
       * - the feature is not enabled
       * - searching for an element selector
       * - typing in a selector
       * - the selector input is empty
       */
      const shouldRenderSelectContainer =
        canUseSelectContainer &&
        !isSearching &&
        !isEditingText &&
        !!workingSelector;

      let customInput;

      if (textareaMode) {
        customInput = (
          <Textarea
            ref={this.inputRef}
            testSection="selector-input"
            placeholder={placeholder}
            value={value}
            onFocus={onTextEditStart}
            onChange={this.onTextEdit}
            onKeyDown={this.onKeyDown}
            isDisabled={inputDisabled || allDisabled}
          />
        );
      } else {
        customInput = (
          <Input
            ref={this.inputRef}
            testSection="selector-input"
            placeholder={placeholder}
            value={value}
            onFocus={onTextEditStart}
            onChange={this.onTextEdit}
            onKeyDown={this.onKeyDown}
            isDisabled={inputDisabled || allDisabled}
            type="text"
            RightContainer={
              shouldRenderSelectContainer && this.renderSelectContainer
            }
          />
        );
      }

      return (
        <div
          className="lego-button-group oui-button--selector-input"
          ref={this.storeSelectorInputGroup}>
          {customInput}
          {isEditingText ? (
            <div className="lego-button-group">
              <Button
                testSection="selector-input-revert"
                size="narrow"
                onClick={onTextEditRevert}
                isDisabled={allDisabled}>
                <Icon className="oui-icon" name="xmark" size="small" />
              </Button>
              <Button
                testSection="selector-input-confirm"
                size="narrow"
                onClick={this.onTextEditConfirm}
                isDisabled={allDisabled}>
                <Icon className="oui-icon" name="check" size="small" />
              </Button>
            </div>
          ) : (
            <div className="flex flex-column">
              <div className="lego-button-group">
                <Button
                  testSection="selector-input-search"
                  size="narrow"
                  isActive={isSearching}
                  onClick={this.onClickSearch}
                  isDisabled={allDisabled}>
                  <Icon className="oui-icon" name="search" size="small" />
                </Button>
              </div>
              {textareaMode &&
                shouldRenderSelectContainer &&
                this.renderSelectContainer()}
            </div>
          )}
        </div>
      );
    }
  },
  {
    canUseSelectContainer,
  },
);

import keyMirror from 'optly/utils/key_mirror';

export const LIVE_VARIABLE_TYPES = keyMirror({
  boolean: null,
  double: null,
  integer: null,
  string: null,
});

export const LIVE_VARIABLE_TYPE_HUMAN_READABLES = {
  boolean: tr('Boolean'),
  double: tr('Double'),
  integer: tr('Integer'),
  string: tr('String'),
};

export default {
  LIVE_VARIABLE_TYPES,
  LIVE_VARIABLE_TYPE_HUMAN_READABLES,
};

import React from 'react';

import flux from 'core/flux';

import WrapperForVueComponentInRegion from 'react_components/vue_wrapper_for_region';

import actionTypes from '../action_types';
import fns from './fns';
import getters from './getters';

let actions;

/**
 * @param {String} regionId
 * @param {ReactElement} navigationController
 */
export function registerRegion(regionId, navigationController) {
  flux.dispatch(actionTypes.REGISTER_REGION, {
    regionId,
    navigationController,
  });
}

/**
 * @param {String} regionId
 */
export function unregisterRegion(regionId) {
  flux.dispatch(actionTypes.UNREGISTER_REGION, { regionId });
}

/**
 * @param {String} regionId
 * @param {Object<{component: ReactElement | VueElement, key: String}} component
 * @param {String} componentId
 */
export function registerComponent(regionId, componentId, component) {
  flux.dispatch(actionTypes.REGISTER_COMPONENT, {
    regionId,
    componentId,
    component,
  });
}

/**
 * @param {String} regionId
 */
export function unregisterComponent(regionId) {
  flux.dispatch(actionTypes.UNREGISTER_COMPONENT, { regionId });
}

/**
 * @param {String} regionId
 * @param {ReactElement | VueElement} component
 * @param {String} componentId
 */
export function queueComponent(regionId, component, componentId) {
  flux.dispatch(actionTypes.QUEUE_REGION_COMPONENT, {
    regionId,
    component,
    componentId,
  });
}

/**
 * @param {Object} config
 * @param {ReactElement | VueElement} config.component
 * @param {Object?} config.data
 * @param {Object?} config.options
 */
export function renderRegion(
  regionId,
  { component, parent, componentId, options, data },
) {
  if (!regionId) {
    throw new Error('regionId required for renderRegion');
  }

  componentId =
    componentId ||
    component.componentId ||
    (component.type && component.type.componentId);

  if (!componentId) {
    throw new Error('componentId required for renderRegion');
  }

  let ReactComponent = component;

  if (fns.isVueComponent(component)) {
    const VueWrapperForRegion = WrapperForVueComponentInRegion.wrap(regionId, {
      component,
      componentId,
      options,
      data,
      parent,
    });
    ReactComponent = <VueWrapperForRegion />;
  }
  const navigationController = flux.evaluate(
    getters.navigationController(regionId),
  );

  if (!navigationController) {
    actions.queueComponent(regionId, ReactComponent, componentId);
    return;
  }

  navigationController.pushView(ReactComponent, {
    componentId,
  });
}

/**
 * @param {String} regionId
 */
export function mountInQueue(regionId) {
  const queuedComponent = flux.evaluateToJS(getters.queuedComponent(regionId));
  if (queuedComponent) {
    actions.renderRegion(regionId, {
      component: queuedComponent.component,
      componentId: queuedComponent.componentId,
    });
    flux.dispatch(actionTypes.UNQUEUE_REGION_COMPONENT, {
      regionId,
    });
  }
}

export default actions = {
  registerRegion,
  unregisterRegion,
  renderRegion,
  mountInQueue,
  registerComponent,
  unregisterComponent,
  queueComponent,
};

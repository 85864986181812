import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Input, ProgressDots } from 'optimizely-oui';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import Immutable from 'optly/immutable';

import {
  Dropdown,
  DropdownBlockLink,
  DropdownContents,
  DropdownListItem,
} from 'react_components/dropdown';

class DropdownEntityPicker extends React.Component {
  constructor(props) {
    super(props);

    const { getEntityDisplayConfig } = props;

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterBlur = this.handleFilterBlur.bind(this);
    this.handleOnSelectEntity = this.handleOnSelectEntity.bind(this);
    this.onCreateEntity = this.onCreateEntity.bind(this);
    this.onListMouseEnter = this.onListMouseEnter.bind(this);
    this.onListMouseLeave = this.onListMouseLeave.bind(this);

    const foundEntity = this.props.entities.find(
      entity => entity.get('id') === this.props.selectedEntityId,
    );
    this.state = {
      inputContent:
        (foundEntity && getEntityDisplayConfig(foundEntity).inputText) || '',
      isMouseOverList: false,
    };
  }

  /**
   * This is to support the revert functionality in the FullStack Manager UI which uses the form abstraction.
   * Because this DropdownEntityPicker uses state to manage the inputContent, hence we need to implement
   * componentDidUpdate() to ensure the revert property resets the inputContent correctly.
   *
   * The two following cases support the Revert functionality of the form abstraction. When the field here changes,
   * the form will pass down different value of the props (selectedEntityId) which is used to determine which type of
   * actions we are trying to perform.
   *
   * removeEntityCase: This case indicates we are removing an entity from the dropdown, or if we don't want to select it.
   * changeEntityCase: This case indicates we are selecting another entity from the dropdown.
   *
   * @param prevProps to verify when to update the state.
   */
  componentDidUpdate(prevProps) {
    const {
      entities,
      getEntityDisplayConfig,
      selectedEntityId: currentId,
    } = this.props;

    const { selectedEntityId: previousId } = prevProps;

    const removeEntityCase = currentId && !previousId;
    const changeEntityCase = currentId && currentId !== previousId;

    if (removeEntityCase || changeEntityCase) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        inputContent: getEntityDisplayConfig(
          entities.find(entity => entity.get('id') === currentId),
        ).inputText,
      });
    }
  }

  handleFilterChange(event) {
    const { value } = event.target;
    this.setState({
      inputContent: value,
    });
  }

  handleFilterBlur(event) {
    const { getEntityDisplayConfig } = this.props;
    if (this.state.isMouseOverList) {
      // Blur caused by clicking on element in list, so don't need to reset
      // input or call this.props.onSelectEntity
      return;
    }

    // reset the input if the user didn't select any entity from the dropdown
    const { value } = event.target;
    const foundEntity = this.props.entities.find(entity => {
      const { inputText, primaryText, secondaryText } = getEntityDisplayConfig(
        entity,
      );
      return (
        value &&
        [
          (inputText || '').toLowerCase(),
          (primaryText || '').toLowerCase(),
          (secondaryText || '').toLowerCase(),
        ].includes(value.toLowerCase())
      );
    });
    if (!foundEntity) {
      this.setState({
        inputContent: '',
      });
      this.props.onUnselectEntity();
      return;
    }
    this.props.onSelectEntity(foundEntity);
    this.setState({
      inputContent: getEntityDisplayConfig(foundEntity).inputText,
    });
  }

  handleOnSelectEntity(entity) {
    const { getEntityDisplayConfig, onSelectEntity } = this.props;
    onSelectEntity(entity);
    this.setState({
      inputContent: getEntityDisplayConfig(entity).inputText,
    });
  }

  onCreateEntity() {
    this.props.onCreateEntity(newEntity => {
      this.handleOnSelectEntity(newEntity);
    });
  }

  onListMouseEnter() {
    this.setState({
      isMouseOverList: true,
    });
  }

  onListMouseLeave() {
    this.setState({
      isMouseOverList: false,
    });
  }

  render() {
    const {
      loadingEntities,
      inputPlaceholder,
      getEntityDisplayConfig,
      createNewText,
      entities,
    } = this.props;

    const { inputContent } = this.state;
    return (
      <div className="width--300">
        <Dropdown
          activator={
            <div data-test-section="dropdown-entity-picker-activator">
              <Input
                isFilter={true}
                onChange={this.handleFilterChange}
                onBlur={this.handleFilterBlur}
                placeholder={inputPlaceholder}
                type="text"
                value={inputContent}
              />
            </div>
          }
          width="100%">
          <div
            onMouseEnter={this.onListMouseEnter}
            onMouseLeave={this.onListMouseLeave}
            className="width--1-1">
            <DropdownContents minWidth={300} direction="right">
              <DropdownListItem>
                {!isFeatureEnabled('disable_creating_full_stack_entities') && (
                  <DropdownBlockLink
                    isLink={true}
                    onClick={this.onCreateEntity}
                    hideOnClick={true}
                    testSection="dropdown-entity-picker-create-new">
                    {createNewText}
                  </DropdownBlockLink>
                )}
              </DropdownListItem>
              {loadingEntities ? (
                <DropdownListItem>
                  <DropdownBlockLink isLink={false} onClick={_.noop}>
                    <div className="flex--dead-center">
                      <ProgressDots />
                    </div>
                  </DropdownBlockLink>
                </DropdownListItem>
              ) : (
                entities
                  .filter(entity => {
                    const { inputText } = getEntityDisplayConfig(entity);
                    return (inputText || '')
                      .toLowerCase()
                      .includes(inputContent.toLowerCase());
                  })
                  .map(entity => {
                    const {
                      description,
                      primaryText,
                      secondaryText,
                    } = getEntityDisplayConfig(entity);
                    const apiName = entity.get('api_name');

                    return (
                      <DropdownListItem key={entity.get('id')}>
                        <DropdownBlockLink
                          hideOnClick={true}
                          isLink={true}
                          onClick={() => this.handleOnSelectEntity(entity)}
                          testSection={`dropdown-entity-picker-item-${apiName}`}>
                          <div className="lego-dropdown--descriptive__header">
                            {primaryText}
                          </div>
                          {secondaryText && (
                            <div
                              className="zeta monospace micro muted word-break--all"
                              style={{ marginTop: '-1px' }}>
                              {secondaryText}
                            </div>
                          )}
                          <div className="lego-dropdown--descriptive__content muted">
                            {description}
                          </div>
                        </DropdownBlockLink>
                      </DropdownListItem>
                    );
                  })
              )}
            </DropdownContents>
          </div>
        </Dropdown>
      </div>
    );
  }
}

DropdownEntityPicker.propTypes = {
  createNewText: PropTypes.node.isRequired,
  entities: PropTypes.instanceOf(Immutable.List).isRequired,
  getEntityDisplayConfig: PropTypes.func.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  onCreateEntity: PropTypes.func.isRequired,
  onSelectEntity: PropTypes.func.isRequired,
  onUnselectEntity: PropTypes.func.isRequired,
  selectedEntityId: PropTypes.number,
};

DropdownEntityPicker.defaultProps = {
  loadingEntities: false,
};

export default DropdownEntityPicker;

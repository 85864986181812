import React from 'react';
import PropTypes from 'prop-types';
import { Attention } from '@optimizely/axiom';
import { useFeature } from '@optimizely/react-sdk';

import { DefaultProperties, CustomProperties } from './subcomponents';
import { MAX_EVENT_PROPERTIES } from './constants';

const EventProperties = ({
  eventProperties,
  eventPropertiesError,
  isEventUsedInExperiment,
  isFx,
  updateEventProperties,
  sectionClassNames,
}) => {
  const [isEventPropertiesFeatureEnabled] = useFeature('event_properties');

  return isEventPropertiesFeatureEnabled ? (
    <div className={sectionClassNames}>
      <h5 className="weight--normal">Event Properties</h5>
      <p>
        Select additional properties to send with this event. These will not be
        editable after the event is used in an experiment. An event can have a
        maximum of 15 properties.
      </p>
      <br />
      <DefaultProperties
        eventProperties={eventProperties}
        isEventUsedInExperiment={isEventUsedInExperiment}
        isFx={isFx}
        updateEventProperties={updateEventProperties}
      />
      <CustomProperties
        eventProperties={eventProperties}
        eventPropertiesError={eventPropertiesError}
        isEventUsedInExperiment={isEventUsedInExperiment}
        isFx={isFx}
        updateEventProperties={updateEventProperties}
      />
      {eventProperties.length >= MAX_EVENT_PROPERTIES && (
        <Attention
          alignment="left"
          isDismissible={false}
          type="warning"
          className="push--ends">
          You have reached the maximum number of allowed properties (15).
        </Attention>
      )}
      {eventPropertiesError && (
        <Attention
          alignment="left"
          isDismissible={false}
          type="bad-news"
          className="push--ends">
          {eventPropertiesError}
        </Attention>
      )}
      {isEventUsedInExperiment && (
        <Attention
          alignment="left"
          isDismissible={false}
          type="warning"
          className="push--ends">
          This event is currently used in one or more experiments. Existing
          event properties cannot be changed or deleted.
        </Attention>
      )}
    </div>
  ) : null;
};

EventProperties.propTypes = {
  eventProperties: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired,
      persisted: PropTypes.bool,
      propertyType: PropTypes.oneOf(['custom', 'default']).isRequired,
      type: PropTypes.oneOf(['string', 'number', 'boolean']),
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  eventPropertiesError: PropTypes.string,
  isEventUsedInExperiment: PropTypes.bool,
  isFx: PropTypes.bool,
  updateEventProperties: PropTypes.func.isRequired,
};
EventProperties.defaultProps = {
  eventPropertiesError: '',
  sectionClassNames: '',
};

export default EventProperties;

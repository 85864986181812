export const DEFAULT_PROPERTIES = [
  {
    accessor: 'category',
    value: 'Category',
    type: 'string',
  },
  {
    accessor: 'subcategory',
    value: 'Subcategory',
    type: 'string',
  },
  {
    accessor: 'text',
    value: 'Text',
    type: 'string',
  },
  {
    accessor: 'url',
    value: 'URL',
    type: 'string',
  },
  {
    accessor: 'sku',
    value: 'SKU',
    type: 'string',
  },
];
export const CUSTOM_PROPERTY_TYPES = ['string', 'number', 'boolean'];
export const MAX_EVENT_PROPERTIES = 15;

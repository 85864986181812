// Core
import ui from 'core/ui';

// Modules
import { actions as SupportActions } from 'optly/modules/support';
import EmptyCTADialog from 'optly/components/dialogs/empty_cta';

/**
 * Renders a CTA dialog with a submit and cancel button.
 *
 * @param {Object} opts
 * @param {String} opts.body
 * @param {String?} opts.title
 * @param {String?} opts.submitText
 * @param {String?} opts.cancelText
 * @param {Function} opts.onSubmit
 * @param {Function?} opts.onCancel
 * @param {String?} opts.size 'large' or 'small' defaults to 'normal'
 */
export function showCTADialog(opts) {
  ui.showReactDialog(
    EmptyCTADialog,
    {
      props: {
        title: opts.title,
        body: opts.body,
        onSubmit: opts.onSubmit,
        onCancel: opts.onCancel,
      },
    },
    {
      noEscape: false,
    },
  );
}

/**
 * Renders a standard dialog with no buttons
 *
 * @param {Object} opts
 * @param {String} opts.template
 * @param {Function?} opts.ready
 */
export function showDialog(opts) {
  if (!ui.getRootVM()) {
    console.warn('[optlyTesting] cannot show dialog until page has loaded'); // eslint-disable-line
  }
  if (!opts.template) {
    console.warn('show dialog must be supplied a `template` property'); // eslint-disable-line
  }

  const componentConfig = {
    template: opts.template,
  };

  if (opts.ready) {
    componentConfig.ready = opts.ready;
  }

  ui.showDialog({
    component: componentConfig,
  });
}

/**
 * Opens the Optimizely Support dialog.
 *
 * @param {String} [props.errorId] optional errorId string to be sent to Zendesk
 */
export function showSupportDialog(props = {}) {
  SupportActions.showSupportDialog({ errorId: props.errorId || '' });
}

export default {
  showCTADialog,
  showDialog,
  showSupportDialog,
};

import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import {
  Link,
  Label,
  Input,
  SelectDropdown,
  Textarea,
  Button,
  ButtonRow,
} from 'optimizely-oui';

import { connect } from 'core/ui/decorators';
import Immutable from 'optly/immutable';

import geo from 'optly/utils/geo';
import handleAjaxError from 'optly/utils/handle_ajax_error';
import { Fieldset, Footer } from 'react_components/dialog';
// eslint-disable-next-line import/no-cycle
import {
  enums as SupportEnums,
  fns as SupportFns,
  actions as SupportActions,
} from 'optly/modules/support';
import PermissionsFns from 'optly/modules/permissions/fns';
import PermissionsGetters from 'optly/modules/permissions/getters';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';

// eslint-disable-next-line import/no-cycle
import Attachments from './attachments';

const PRIORITY_LOW = 'low';
const Separator = () => <div className="push-double--top" />;

const getAdditionalFieldsForCategory = categoryId => {
  const {
    ENTITY_ID,
    SDK_LANGUAGE,
    SDK_VERSION,
    PRIORITY,
  } = SupportEnums.fields;
  const {
    X_FULL_STACK,
    X_MOBILE,
    X_OTT,
    X_WEB_EDGE,
    X_WEB_EXPERIMENTATION,
    X_WEB_PERSONALIZATION,
    X_WEB_RECOMMENDATIONS,
    FREE_ROLLOUTS,
  } = SupportEnums.categories;
  switch (categoryId) {
    case X_WEB_EDGE.value:
    case X_WEB_EXPERIMENTATION.value:
    case X_WEB_PERSONALIZATION.value:
    case X_WEB_RECOMMENDATIONS.value:
      return [ENTITY_ID, PRIORITY];
    case X_FULL_STACK.value:
    case X_MOBILE.value:
    case X_OTT.value:
      return [ENTITY_ID, SDK_LANGUAGE, SDK_VERSION, PRIORITY];
    case FREE_ROLLOUTS.value:
      return [SDK_LANGUAGE, SDK_VERSION, PRIORITY];
    default:
      return [];
  }
};

const getEntityIdFromUrl = () => {
  const entityIdMatch = window.location.href.match(
    /(experiment_id=|experiments\/|campaigns\/)([0-9]+)/,
  );
  return entityIdMatch && entityIdMatch[2];
};

@connect({
  canAccountUseEdgeProjects: PermissionsGetters.canAccountUseEdgeProjects,
  canUseFullStackSDKs: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseFullStackSDKs,
  ],
  canUseFullStackExperiments: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseFullStackExperiments,
  ],
  canUseMobileSDKs: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseMobileSDKs,
  ],
  canUseOverTheTopSDKs: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseOverTheTopSDKs,
  ],
  canUseXWeb: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseXWeb,
  ],
  canUsePersonalization: PermissionsGetters.canUsePersonalization,
  canUseTeamsWorkflow: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseTeamsWorkflow,
  ],
})
class RequestForm extends React.Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    canAccountUseEdgeProjects: PropTypes.bool.isRequired,
    canUseFullStackExperiments: PropTypes.bool.isRequired,
    canUseFullStackSDKs: PropTypes.bool.isRequired,
    canUseMobileSDKs: PropTypes.bool.isRequired,
    canUseOverTheTopSDKs: PropTypes.bool.isRequired,
    canUsePersonalization: PropTypes.bool.isRequired,
    canUseTeamsWorkflow: PropTypes.bool.isRequired,
    canUseXWeb: PropTypes.bool.isRequired,
    caseType: PropTypes.string.isRequired,
    categoryId: PropTypes.string.isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    errorId: PropTypes.string,
    isOptimizelyX: PropTypes.bool.isRequired,
    setCategoryId: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    setRequestStatus: PropTypes.func.isRequired,
    setZendeskTicketId: PropTypes.func.isRequired,
    switchStepHandler: PropTypes.func.isRequired,
    userLocale: PropTypes.string.isRequired,
    userPlanId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    errorId: '',
  };

  constructor(props) {
    super(props);

    const { caseType, currentProject } = props;

    this.state = {
      caseType,
      isFileUploading: false,
      attachments: [],
      description: '',
      subject: '',
      organizations: [SupportEnums.ORGANIZATION_INFO.NONE_AVAILABLE],
      selectedOrganizationId: SupportEnums.ORGANIZATION_INFO.NONE_AVAILABLE.id,
      selectedSDK: '-',
      sdkVersionNumber:
        currentProject.get('sdk_version') === '0.0'
          ? ''
          : currentProject.get('sdk_version'),
      entityId: getEntityIdFromUrl() || '',
      additionalEmails: '',
      selectedPriority: PRIORITY_LOW,
      locationData: {
        continent: '',
        country: '',
        managingTeam: '',
      },
      validationErrors: {
        organization: false,
        subject: false,
        description: false,
        sdk: false,
        sdkVersionNumber: false,
        entityId: false,
      },
    };
    this.getZendeskOrganizations();
    this.getLocationData();
  }

  getZendeskOrganizations = () => {
    /**
     * Makes an request to our app that looks up Zendesk organization info
     * This then relays the request to Zendesk via the API
     *
     * If a Zendesk organization is found, the list is prepended with "-" and stored this.organizations
     * If no organization is found, the organization drop down won't show on the REQUEST_FORM page
     */
    SupportActions.getZendeskOrganizations()
      .then(response => {
        if (response.organizations && response.organizations.length > 0) {
          this.setState({
            selectedOrganizationId: SupportEnums.ORGANIZATION_INFO.DEFAULT.id,
            organizations: [
              SupportEnums.ORGANIZATION_INFO.DEFAULT,
              ...response.organizations,
            ],
          });
          // when only one organization is returned, select it - otherwise select the default, blank org
          if (response.organizations.length === 1) {
            this.setState({
              selectedOrganizationId: response.organizations[0].id,
            });
          }
        }
      })
      .fail(
        handleAjaxError(() => {
          // Reset organizations to default state
          // Selects the default no_organization_available in case no organizations are found
          this.setState({
            organizations: [SupportEnums.ORGANIZATION_INFO.NONE_AVAILABLE],
            selectedOrganizationId:
              SupportEnums.ORGANIZATION_INFO.NONE_AVAILABLE.id,
          });
        }),
      );
  };

  getLocationData() {
    geo.getLocationData().always(locationData => {
      this.setState({
        locationData: {
          continent: locationData.continent,
          country: locationData.country,
          managingTeam:
            SupportEnums.detectManagingTeamByContinent[
              locationData.continent
            ] || locationData.continent,
        },
      });
    });
  }

  goToPreviousStep = () => {
    const { categoryId, switchStepHandler } = this.props;
    const {
      BILLING,
      ACCOUNT,
      DEVELOPER,
      PROGRAM_MANAGEMENT,
    } = SupportEnums.categories;
    const { CHOOSE_CATEGORY, CHOOSE_CASE_TYPE } = SupportEnums.steps;
    if (
      [BILLING.value, ACCOUNT.value, PROGRAM_MANAGEMENT.value].includes(
        categoryId,
      )
    ) {
      switchStepHandler(CHOOSE_CATEGORY);
    } else {
      switchStepHandler(CHOOSE_CASE_TYPE);
    }
  };

  getCategoryItems = () => {
    const {
      canAccountUseEdgeProjects,
      canUseFullStackExperiments,
      canUseMobileSDKs,
      canUseOverTheTopSDKs,
      canUseFullStackSDKs,
      canUseXWeb,
      canUsePersonalization,
      canUseTeamsWorkflow,
    } = this.props;

    const {
      X_WEB_EDGE,
      X_WEB_EXPERIMENTATION,
      X_WEB_PERSONALIZATION,
      PROGRAM_MANAGEMENT,
      X_FULL_STACK,
      X_MOBILE,
      X_OTT,
      FREE_ROLLOUTS,
      BILLING,
      ACCOUNT,
      DEVELOPER,
    } = SupportEnums.categories;

    const itemCategories = [BILLING, ACCOUNT, DEVELOPER];

    if (canUseXWeb) {
      itemCategories.push(X_WEB_EXPERIMENTATION);
      if (canAccountUseEdgeProjects) {
        itemCategories.push(X_WEB_EDGE);
      }
      if (canUsePersonalization) {
        itemCategories.push(X_WEB_PERSONALIZATION);
      }
    }

    if (canUseFullStackSDKs) {
      if (canUseFullStackExperiments) {
        itemCategories.push(X_FULL_STACK);

        if (canUseMobileSDKs) {
          itemCategories.push(X_MOBILE);
        }

        if (canUseOverTheTopSDKs) {
          itemCategories.push(X_OTT);
        }
      } else {
        itemCategories.push(FREE_ROLLOUTS);
      }
    }

    if (canUseTeamsWorkflow) {
      itemCategories.push(PROGRAM_MANAGEMENT);
    }

    return itemCategories.map(item => ({
      label: item.text,
      value: item.value,
    }));
  };

  getEntityIdLabel() {
    const { categoryId } = this.props;
    const {
      X_WEB_PERSONALIZATION,
      X_FULL_STACK,
      X_MOBILE,
      X_OTT,
    } = SupportEnums.categories;
    switch (categoryId) {
      case X_FULL_STACK.value:
      case X_MOBILE.value:
      case X_OTT.value:
        return 'Experiment Key';
      case X_WEB_PERSONALIZATION.value:
        return 'Campaign ID';
      default:
        return 'Experiment ID';
    }
  }

  getEntityIdNote() {
    const { categoryId } = this.props;
    const { X_FULL_STACK, X_MOBILE, X_OTT } = SupportEnums.categories;
    switch (categoryId) {
      case X_FULL_STACK.value:
      case X_MOBILE.value:
      case X_OTT.value:
        return 'This key can be found on your Project or Experiment page.';
      default:
        return 'This ID can be found in the address bar when viewing your experiment.';
    }
  }

  getSDKVersionNote() {
    const { categoryId } = this.props;
    const { X_MOBILE } = SupportEnums.categories;
    if (categoryId === X_MOBILE.value) {
      const IOSLoggingLink = () => (
        <Link
          href="http://developers.optimizely.com/ios/reference/index.html#verbose-logging"
          newWindow={true}>
          iOS
        </Link>
      );
      const AndroidLoggingLink = () => (
        <Link
          href="http://developers.optimizely.com/android/reference/index.html#verbose-logging"
          newWindow={true}>
          Android
        </Link>
      );
      return (
        <React.Fragment>
          This can be found by enabling verbose logging in your{' '}
          <IOSLoggingLink /> or <AndroidLoggingLink /> development environment.
        </React.Fragment>
      );
    }
    return '';
  }

  getSDKType() {
    const { categoryId } = this.props;
    const {
      X_FULL_STACK,
      X_MOBILE,
      X_OTT,
      FREE_ROLLOUTS,
    } = SupportEnums.categories;
    switch (categoryId) {
      case X_MOBILE.value:
        return 'Mobile';
      case X_OTT.value:
        return 'OTT';
      case X_FULL_STACK.value:
        return 'Full Stack';
      case FREE_ROLLOUTS.value:
        return 'Full Stack';
      default:
        return '';
    }
  }

  getSDKLanguages() {
    const { categoryId } = this.props;
    const {
      X_FULL_STACK,
      X_MOBILE,
      X_OTT,
      FREE_ROLLOUTS,
    } = SupportEnums.categories;
    switch (categoryId) {
      case X_MOBILE.value:
        return SupportEnums.SDK_LANGUAGES.MOBILE;
      case X_FULL_STACK.value:
        return SupportEnums.SDK_LANGUAGES.FULL_STACK;
      case FREE_ROLLOUTS.value:
        return SupportEnums.SDK_LANGUAGES.FULL_STACK;
      case X_OTT.value:
        return SupportEnums.SDK_LANGUAGES.OTT;
      default:
        return [];
    }
  }

  onCategoryChange = categoryId => {
    const { setCategoryId } = this.props;
    this.setState({
      selectedSDK: '-',
      caseType: null,
    });
    setCategoryId(categoryId);
  };

  onOrganizationChange = organizationId => {
    this.setState({ selectedOrganizationId: organizationId });
  };

  onSDKChange = sdk => {
    this.setState({ selectedSDK: sdk });
  };

  onSubjectChange = event => {
    this.setState({ subject: event.target.value });
  };

  onDescriptionChange = event => {
    this.setState({ description: event.target.value });
  };

  onSDKVersionNumberChange = event => {
    this.setState({ sdkVersionNumber: event.target.value });
  };

  onEntityIdChange = event => {
    this.setState({ entityId: event.target.value });
  };

  onAdditionalEmailsChange = event => {
    this.setState({ additionalEmails: event.target.value });
  };

  onPriorityChange = priority => {
    this.setState({ selectedPriority: priority });
  };

  addUploadToken = token => {
    this.setState(prevState => ({
      attachments: [...prevState.attachments, token],
    }));
  };

  removeUploadToken = token => {
    this.setState(prevState => ({
      attachments: prevState.attachments.filter(item => item !== token),
    }));
  };

  uploadStart = () => {
    this.setState({ isFileUploading: true });
  };

  uploadFinished = () => {
    this.setState({ isFileUploading: false });
  };

  submitTicket = () => {
    const {
      accountId,
      userLocale,
      userPlanId,
      categoryId,
      currentProject,
      errorId,
      isOptimizelyX,
      setRequestStatus,
      setZendeskTicketId,
      switchStepHandler,
      setIsLoading,
    } = this.props;
    setIsLoading(true);
    const {
      caseType,
      attachments,
      additionalEmails,
      locationData,
      description,
      entityId,
      selectedOrganizationId,
      selectedPriority,
      subject,
      selectedSDK,
      sdkVersionNumber,
    } = this.state;
    const { ENTITY_ID, SDK_LANGUAGE, SDK_VERSION } = SupportEnums.fields;
    const ticketInfo = {
      attachments,
      description,
      subject,
      error_id: errorId,
      account_id: accountId,
      case_type: caseType,
      collaborators: additionalEmails.split(/[ ,]+/),
      continent: locationData.continent,
      country: locationData.country,
      form_id: categoryId,
      project_id: currentProject.get('id'),
      user_plan_id: userPlanId,
      user_locale: userLocale,
      language: window.navigator.language,
      managing_team: locationData.managingTeam,
      optimizely_x: isOptimizelyX,
      organization: selectedOrganizationId,
      priority: selectedPriority,
      ticket_type: SupportEnums.ticketType.SUPPORT,
      timezone: -Math.round(new Date().getTimezoneOffset() / 60),
      user_environment_info: SupportFns.userEnvironmentInfo(),
    };
    if (this.shouldShowField(ENTITY_ID)) {
      ticketInfo.entity_id = entityId;
    }
    if (this.shouldShowField(SDK_LANGUAGE)) {
      ticketInfo.sdk_language = selectedSDK;
    }
    if (this.shouldShowField(SDK_VERSION)) {
      ticketInfo.sdk_version = sdkVersionNumber;
    }
    SupportActions.createZendeskTicket(ticketInfo)
      .done(response => {
        setZendeskTicketId(response.ticket.id);
        setRequestStatus(SupportEnums.requestStatus.SUCCESS);
      })
      .fail(
        handleAjaxError(() => {
          setRequestStatus(SupportEnums.requestStatus.DEFERRED);
        }),
      )
      .always(() => {
        setIsLoading(false);
        switchStepHandler(SupportEnums.steps.TICKET_STATUS);
      });
  };

  validateForm = () => {
    const { ENTITY_ID, SDK_LANGUAGE, SDK_VERSION } = SupportEnums.fields;
    this.setState(
      prevState => ({
        validationErrors: {
          organization:
            prevState.organizations.length > 1 &&
            prevState.selectedOrganizationId === '-',
          subject: prevState.subject === '',
          description: prevState.description === '',
          sdk:
            this.shouldShowField(SDK_LANGUAGE) && prevState.selectedSDK === '-',
          sdkVersionNumber:
            this.shouldShowField(SDK_VERSION) &&
            prevState.sdkVersionNumber === '',
          entityId:
            this.shouldShowField(ENTITY_ID) && prevState.entityId === '',
        },
      }),
      () => {
        if (!Object.values(this.state.validationErrors).includes(true)) {
          this.submitTicket();
        }
      },
    );
  };

  shouldShowField = field =>
    getAdditionalFieldsForCategory(this.props.categoryId).includes(field);

  render() {
    const { categoryId } = this.props;
    const {
      organizations,
      selectedOrganizationId,
      selectedSDK,
      subject,
      description,
      sdkVersionNumber,
      entityId,
      additionalEmails,
      selectedPriority,
      validationErrors,
      isFileUploading,
    } = this.state;
    const {
      ENTITY_ID,
      SDK_LANGUAGE,
      SDK_VERSION,
      PRIORITY,
    } = SupportEnums.fields;
    return (
      <React.Fragment>
        <div className="push-double--bottom">
          <h3>Create a new request</h3>
          <p>
            File a ticket or{' '}
            <Link href={SupportFns.myRequestsLink()} newWindow={true}>
              follow up to an existing one
            </Link>
            .
          </p>
        </div>
        <Fieldset>
          <Label>Product or Category</Label>
          <SelectDropdown
            minDropdownWidth="660px"
            width="660px"
            value={categoryId}
            items={this.getCategoryItems()}
            onChange={this.onCategoryChange}
            testSection="product-or-category"
          />
          {organizations.length > 1 && (
            <div
              className={classNames({
                'oui-form-bad-news': validationErrors.organization,
              })}>
              <Separator />
              <Label>Organization</Label>
              <SelectDropdown
                minDropdownWidth="660px"
                width="660px"
                displayError={validationErrors.organization}
                value={selectedOrganizationId}
                items={organizations.map(item => ({
                  label: item.name,
                  value: item.id,
                }))}
                onChange={this.onOrganizationChange}
              />
            </div>
          )}
          <Separator />
          <Input
            type="text"
            label="Subject"
            value={subject}
            displayError={validationErrors.subject}
            testSection="request-form-subject"
            onChange={this.onSubjectChange}
            note="Let us know a short, high-level description of your request."
          />
          <Separator />
          <Textarea
            label="Description"
            value={description}
            displayError={validationErrors.description}
            onChange={this.onDescriptionChange}
            testSection="request-form-description"
            note="Enter the details of your request. A member of our support staff will respond as soon as possible."
          />
          {this.shouldShowField(SDK_LANGUAGE) && (
            <div
              className={classNames({
                'oui-form-bad-news': validationErrors.sdk,
              })}>
              <Separator />
              <Label>SDK Language</Label>
              <SelectDropdown
                minDropdownWidth="660px"
                width="660px"
                value={selectedSDK}
                displayError={validationErrors.sdk}
                items={this.getSDKLanguages()}
                onChange={this.onSDKChange}
                testSection="request-form-sdk-dropdown"
              />
              <div className="oui-form-note">
                Select the {this.getSDKType()} SDK this request is for.
              </div>
            </div>
          )}
          {this.shouldShowField(SDK_VERSION) && (
            <React.Fragment>
              <Separator />
              <Input
                type="text"
                value={sdkVersionNumber}
                onChange={this.onSDKVersionNumberChange}
                displayError={validationErrors.sdkVersionNumber}
                label="SDK Version Number"
                note={this.getSDKVersionNote()}
                testSection="request-form-sdk-version"
              />
            </React.Fragment>
          )}
          {this.shouldShowField(ENTITY_ID) && (
            <React.Fragment>
              <Separator />
              <Input
                type="text"
                label={this.getEntityIdLabel()}
                value={entityId}
                onChange={this.onEntityIdChange}
                displayError={validationErrors.entityId}
                testSection="request-form-entity-id"
                note={
                  <React.Fragment>
                    {this.getEntityIdNote()}{' '}
                    <Link href="/v2" newWindow={true}>
                      Click here
                    </Link>{' '}
                    to open a new tab and find this.
                  </React.Fragment>
                }
              />
            </React.Fragment>
          )}
          <Separator />
          <Input
            type="text"
            value={additionalEmails}
            onChange={this.onAdditionalEmailsChange}
            label="Additional Emails to CC on Responses"
            note="Multiple emails can be added, using commas as separators (e.g. jane@company.com, john@company.com)."
          />
          {this.shouldShowField(PRIORITY) && (
            <React.Fragment>
              <Separator />
              <Label>Priority</Label>
              <SelectDropdown
                buttonStyle="outline-reverse"
                minDropdownWidth="660px"
                width="660px"
                value={selectedPriority}
                items={SupportEnums.PRIORITIES}
                onChange={this.onPriorityChange}
                testSection="request-form-priority"
              />
              <div className="oui-form-note">
                Select the most accurate description of how this request affects
                your team.
              </div>
            </React.Fragment>
          )}
          <Attachments
            addUploadToken={this.addUploadToken}
            removeUploadToken={this.removeUploadToken}
            uploadStart={this.uploadStart}
            uploadFinished={this.uploadFinished}
          />
        </Fieldset>
        <Footer>
          <ButtonRow
            rightGroup={[
              <Button
                key="back"
                style="outline-reverse"
                onClick={this.goToPreviousStep}>
                Back
              </Button>,
              <Button
                key="next"
                style="highlight"
                testSection="request-form-submit-button"
                isDisabled={isFileUploading}
                onClick={this.validateForm}>
                Next
              </Button>,
            ]}
          />
        </Footer>
      </React.Fragment>
    );
  }
}

export default RequestForm;

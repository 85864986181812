import enums from './enums';

export function isOptimizelyX(version) {
  return version === enums.versions.OPTIMIZELY_X;
}

export function isClassic(version) {
  return version === enums.versions.CLASSIC;
}

export default {
  isOptimizelyX,
  isClassic,
};

import React from 'react';
import PropTypes from 'prop-types';
import ConditionGroupModel from 'optly/models/condition_group';
import { actions as InterestGroupActions } from 'optly/modules/entity/interest_group';

import {
  serializeKeywords,
  deserializeKeywords,
  cleanKeywordWrapperList,
} from './utils';

import PredictedIntentCondition from './subcomponents/predicted_intent_condition';

/**
 * @typedef {Object} KeywordWrapper
 * @property {String} name
 * @property {String="primary", "secondary", "error"} style
 *            Indicates the style to use when displaying the word as a <Token>
 */

/**
 * Component whose sole job is to manipulate 2 properties of its
 * props.conditionGroup.conditions[0] (the predicted_intent condition object):
 *
 *   value: represents a percentage using basis points from 0-10000
 *   name: an underscore concatenated string of keywords (see example below)
 *
 * Note that we are intentionally manipulating the referenced ConditionGroup
 * model instance passed in. Since the audience builder does not currently track
 * dirty state, we don't need to notify the parent that we've changed it, but
 * we certainly could do so in the future.
 */
export default class PredictedIntentConditionCard extends React.Component {
  static propTypes = {
    /**
     * A single instance of ConditionGroup whose conditions property
     * is a list of exactly one object (POJO) representing a predicted
     * intent audience condition in the form of:
     *   {
     *     name: "money_savings",
     *     value: 4510,
     *     type: "predicted_intent",
     *     match_type: "gt"
     *   }
     * @type {ConditionGroupModel}
     */
    conditionGroup: PropTypes.instanceOf(ConditionGroupModel).isRequired,

    currentProjectId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    const { conditionGroup } = props;
    const conditionPojo = conditionGroup.conditions[0];
    let currentlySelectedInterestGroup = null;
    const percentVisitors = String(Number(conditionPojo.value) / 100);
    let keywords = [];

    if (conditionPojo.name) {
      keywords = deserializeKeywords(conditionPojo.name);
      // In case our deserialization resulted in updates to the keywords.
      this.setConditionKeywords(keywords, conditionGroup);
    } else {
      currentlySelectedInterestGroup = false;
    }

    this.state = {
      /**
       * Store the reference to the conditionGroup prop in state.
       * Although it's just a reference to the same instance of ConditionGroup as this.props.conditionGroup,
       * manipulating it via setState coerces our component to re-render anything that depends on it.
       *
       * @type {ConditionGroupModel}
       */
      conditionGroup,

      /**
       * The InterestGroup represented by the conditionGroup's name field
       * (an underscored concatenation of keywords).
       * It will always have one of the following 3 values:
       *   null - keywords were just updated and we're searching for an InterestGroup
       *   false - We searched and found an InterestGroup for the current keywords
       *   InterestGroup - We searched and found and InterestGroup for the current keywords
       *
       * @type {Object<InterestGroup>}
       */
      currentlySelectedInterestGroup,

      /**
       * The keywords for this conditionGroup deserialized into a list of KeywordWrappers
       *
       * @type {Array.<KeywordWrapper>}
       */
      keywords,

      /**
       * Store raw input value from the input field or the slider, then pass it down to the Input or Histogram to display
       */
      percentVisitors,
    };
  }

  componentDidMount() {
    this.setCurrentlySelectedInterestGroup();
    this.validateKeywords();
  }

  /**
   * Send the current list of keywords to control tower for validation and
   * update the KeywordWrapper list with the response.
   */
  validateKeywords = () => {
    const { keywords } = this.state;
    InterestGroupActions.validateKeywords(keywords.map(k => k.name)).then(
      result => {
        const res = keywords.map(k => {
          const match = result.find(item => item.word === k.name);
          return {
            name: k.name,
            style: match && match.isValid ? 'primary' : 'error',
          };
        });

        this.setState({ keywords: res });
      },
    );
  };

  /**
   * Given a the current list of keywords, see if an InterestGroup exists for them already.
   */
  setCurrentlySelectedInterestGroup() {
    const { currentProjectId } = this.props;
    const { keywords } = this.state;
    const setInterestGroup = currentlySelectedInterestGroup => {
      this.setState({ currentlySelectedInterestGroup });
    };
    this.setState({ currentlySelectedInterestGroup: null }, () => {
      if (keywords.length === 0) {
        // False here means we know there's no InterestGroup for these keywords.
        setInterestGroup(false);
      } else {
        InterestGroupActions.search(
          currentProjectId,
          serializeKeywords(keywords),
        )
          .then(
            results => (results.size ? results.get(0) : false),
            () => false,
          )
          .then(setInterestGroup);
      }
    });
  }

  /**
   * Helper method to set the name field of the condition object
   * with cleaned keywords and return the updated keywords.
   * @param keywords
   * @param conditionGroup
   * @returns {KeywordWrapper<>}
   */
  setConditionKeywords = (keywords, conditionGroup) => {
    const cleanKeywords = cleanKeywordWrapperList(keywords);
    const conditionPojo = conditionGroup.conditions[0];
    conditionPojo.name = serializeKeywords(
      cleanKeywordWrapperList(cleanKeywords),
    );
    return cleanKeywords;
  };

  /**
   * When the user changes the keywords, we must do a few things:
   *   - clean the keywords for them
   *   - set them in state
   *   - update the condition name field with them
   *   - validate them with the corpus
   *   - update the currentlySelectedInterestGroup to reflect the new
   *     InterestGroup for these keywords.
   *
   * @param {KeywordWrapper<>} updatedKeywords
   */
  onKeywordsChange = updatedKeywords => {
    this.setState(
      prevState => {
        const { conditionGroup } = prevState;
        const keywords = this.setConditionKeywords(
          updatedKeywords,
          conditionGroup,
        );

        return { conditionGroup, keywords };
      },
      () => {
        this.validateKeywords();
        this.setCurrentlySelectedInterestGroup();
      },
    );
  };

  /**
   * When the user changes the target reach percentage, store the exact
   * input value in state and update the condition value with the percentage
   * converted to basis points from 0 to 10000.
   * @param {Number} percentVisitors
   */
  onPercentVisitorsChange = percentVisitors => {
    this.setState(prevState => {
      const { conditionGroup } = prevState;
      const conditionPojo = conditionGroup.conditions[0];
      conditionPojo.value = Math.round(Number(percentVisitors) * 100);

      return {
        conditionGroup,
        percentVisitors,
      };
    });
  };

  render() {
    const {
      currentlySelectedInterestGroup,
      percentVisitors,
      keywords,
    } = this.state;
    return (
      <div
        className="condition__body push--bottom"
        data-test-section="predicted-intent-condition-card-body">
        <PredictedIntentCondition
          interestGroup={currentlySelectedInterestGroup}
          keywords={keywords}
          percentVisitors={percentVisitors}
          onKeywordsChange={this.onKeywordsChange}
          onPercentVisitorsChange={this.onPercentVisitorsChange}
        />
      </div>
    );
  }
}

import React, { ReactPortal, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { DialogNew, Button } from 'optimizely-oui';

interface ApplyCustomizationDialogProps {
  onClose: () => void;
}

const ApplyCustomizationDialog = ({
  onClose,
}: ApplyCustomizationDialogProps): ReactPortal => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  if (!dialogRef.current) {
    dialogRef.current = document.createElement('div');
  }
  useEffect(() => {
    const dialogRoot = document.body;
    dialogRoot.appendChild(dialogRef.current as HTMLDivElement);
    return () => {
      dialogRoot.removeChild(dialogRef.current as HTMLDivElement);
    };
  }, []);
  return createPortal(
    <DialogNew
      footerButtonList={[
        <Button
          style="highlight"
          key="close"
          onClick={onClose}
          testSection="apply-filters-customization-close">
          Close
        </Button>,
      ]}
      hasCloseButton={true}
      hasOverlay={true}
      onClose={onClose}
      testSection="apply-filters-customization-dialog"
      title="Bookmarking and sharing views">
      <p>
        When you turn on and off column visibility and click “Apply”, your
        choices are added to the URL of this page. This allows you to create a
        bookmark of this view in your browser or share this view by copying the
        URL and sending it via email or instant message.
      </p>
    </DialogNew>,
    dialogRef.current,
  );
};

export default ApplyCustomizationDialog;

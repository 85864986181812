import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

const DEFAULT_ACCOUNT_ORIGINS = {
  accountAllowedOrigins: [],
  accountBlockedOrigins: [],
  useAutoOrigins: false,
};

/**
 * account origins dirty store
 * Responsible for the following state management:
 * Keeping track of dirty account origins data. The clean data can be found on the adminAccount
 */
const AccountOriginsStore = Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable(DEFAULT_ACCOUNT_ORIGINS);
  },

  initialize() {
    this.on(actionTypes.SET_DIRTY_ACCOUNT_ORIGINS, setDirtyOrigins);
    this.on(
      actionTypes.SET_DIRTY_ACCOUNT_ALLOWED_ORIGINS,
      setDirtyAllowedOrigins,
    );
    this.on(
      actionTypes.SET_DIRTY_ACCOUNT_BLOCKED_ORIGINS,
      setDirtyBlockedOrigins,
    );
    this.on(
      actionTypes.SET_DIRTY_ACCOUNT_USE_AUTO_ORIGINS,
      setDirtyUseAutoOrigins,
    );
  },
});

/**
 * sets the list of dirty allowed origins
 */
function setDirtyAllowedOrigins(state, payload) {
  return state.set('accountAllowedOrigins', payload.accountAllowedOrigins);
}

/**
 * sets the list of dirty blocked origins
 */
function setDirtyBlockedOrigins(state, payload) {
  return state.set('accountBlockedOrigins', payload.accountBlockedOrigins);
}

/**
 * sets the auto origins boolean
 */
function setDirtyUseAutoOrigins(state, payload) {
  return state.set('useAutoOrigins', payload.useAutoOrigins);
}

function setDirtyOrigins(state, payload) {
  return state
    .set('useAutoOrigins', payload.useAutoOrigins)
    .set('accountAllowedOrigins', payload.accountAllowedOrigins)
    .set('accountBlockedOrigins', payload.accountBlockedOrigins);
}

export default AccountOriginsStore;

import React from 'react';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import Router from 'core/router';

import BundleSplitHelper from 'optly/utils/bundle_split_helper';
import NavConstants from 'optly/services/navigation';
import { enums as FilterableTableEnums } from 'optly/modules/filterable_table';
import { actions as PerformanceTrackingActions } from 'optly/modules/performance_tracking';

import RoutingFns from '../../routing_fns';
import { isFullStackProject } from 'optly/modules/entity/project/fns';

const category = 'Implementation';

/*
 * Routing function that ensures the Views Section dynamic bundle has been fetched
 * We store the returned dynamic bundle module on the function object itself
 */
function MetricsSection(ctx, next) {
  import(
    'bundles/p13n/sections/metrics' // eslint-disable-line
  )
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(MetricsSection, module);
    })
    .then(next);
}

/*
 * Routes for the views section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/projects/:proj_id/implementation/metrics',
    metadata: { name: 'Metrics', category },
    handle: [
      [
        MetricsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PAGES,
        ),
        RoutingFns.parseProjectId,
        (ctx, next) =>
          isFeatureEnabled('metrics_usability') &&
          !isFullStackProject(ctx.currentProject)
            ? next()
            : Router.redirect('/v2/'),
        RoutingFns.parseQueryFilters(
          FilterableTableEnums.tableIds.P13N_DATA_LAYER_METRICS,
        ),
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      [RoutingFns.fetchMetricsForAccount],
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Metrics',
          category,
        }),
      () => {
        PerformanceTrackingActions.setFrontendRenderStartTime();
        RoutingFns.renderMainRegion(
          <MetricsSection.pages.dashboard.component />,
        );
      },
    ],
  },
];

export default routes;

import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';
import constants from '../constants';

const setLayerHoldback = (state, payload) =>
  state.set('holdback', payload.holdback);

const setLayerHoldbackInvalid = (state, payload) =>
  state.set('invalid', payload.invalid);

export default Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      holdback: constants.DEFAULT_HOLDBACK,
      invalid: false,
    });
  },

  initialize() {
    this.on(actionTypes.P13N_SET_LAYER_HOLDBACK, setLayerHoldback);
    this.on(
      actionTypes.P13N_SET_LAYER_HOLDBACK_INVALID,
      setLayerHoldbackInvalid,
    );
  },
});

import PropTypes from 'prop-types';

import React from 'react';

import { SelectDropdown, Icon } from 'optimizely-oui';

import { redBase } from '@optimizely/design-tokens/dist/json/colors.json';

import Immutable from 'optly/immutable';

import { enums as LayerExperimentEnums } from 'optly/modules/entity/layer_experiment';

import { fieldPropType } from 'react_components/form';

import { fns as ComponentModuleFns } from '../../component_module';

function EnvironmentsDropdown(props) {
  const {
    environments,
    onSelectedEnvironmentKeyChange,
    rolloutTrafficField,
    selectedEnvironmentKey,
    environmentsField,
  } = props;

  const dropdownItems = [];
  environments.reverse().forEach(environment => {
    const environmentName = environment.get('name');
    const environmentKey = environment.get('key');
    const trafficInEnv = rolloutTrafficField.getValue().get(environmentKey);
    const statusInEnv = environmentsField
      .getValue()
      .getIn([environmentKey, 'rollout_rules', 0, 'status']);
    let statusIndicator;
    if (ComponentModuleFns.rolloutTrafficAllocationIsInvalid(trafficInEnv)) {
      statusIndicator = (
        <div className="color--bad-news">
          <Icon color={redBase} size="small" name="circle-exclamation" />
        </div>
      );
    } else if (statusInEnv === LayerExperimentEnums.EnvironmentStatus.RUNNING) {
      statusIndicator = `${trafficInEnv}%`;
    } else {
      statusIndicator = 'OFF';
    }
    const activatorLabel = (
      <div className="truncate max-width--200">{environmentName}</div>
    );
    const label = (
      <div className="flex flex-align--center">
        <div className="truncate width--200">{environmentName}</div>
        <div className="muted">{statusIndicator}</div>
      </div>
    );
    dropdownItems.push({
      activatorLabel,
      label,
      value: environmentKey,
    });
  });
  return (
    <SelectDropdown
      testSection="rollout-field-env-select-menu"
      items={dropdownItems}
      value={selectedEnvironmentKey}
      onChange={onSelectedEnvironmentKeyChange}
      displayError={rolloutTrafficField.getErrors().hasError}
    />
  );
}

EnvironmentsDropdown.propTypes = {
  environments: PropTypes.instanceOf(Immutable.List).isRequired,
  environmentsField: fieldPropType.isRequired,
  onSelectedEnvironmentKeyChange: PropTypes.func.isRequired,
  rolloutTrafficField: fieldPropType.isRequired,
  selectedEnvironmentKey: PropTypes.string.isRequired,
};

export default EnvironmentsDropdown;

import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

const setLayerDescription = (state, payload) =>
  state.set('description', payload.description);

const setLayerName = (state, payload) => state.set('name', payload.name);

export default Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      description: '',
      name: '',
    });
  },

  initialize() {
    this.on(actionTypes.P13N_SET_LAYER_DESCRIPTION, setLayerDescription);
    this.on(actionTypes.P13N_SET_LAYER_NAME, setLayerName);
  },
});

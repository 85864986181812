/**
 * Third Party Condition Keyvalue Field Component
 * Responsible for rendering the configuration UI for a third-party keyvalue condition
 */
const AudienceBuilderFns = require('optly/modules/ui/audience_builder/fns');

const _ = require('lodash');

const baseComponent = require('./base');
const template = require('./keyvalue.html');

module.exports = _.extend({}, baseComponent, {
  template,

  data: {
    config: {},
    key: '',
  },

  computed: {
    hasError() {
      return !this.validateKey();
    },

    keyWrapper: {
      $get() {
        return this.key;
      },
      $set(value) {
        this.key = value;
        // updating a key/value condition mutates the audience condition name
        // example: name='demandbase.watch_list' in a key/value condition type
        // becomes name='demandbase.watch_list.<key>'
        this.condition.name = `${this.config.name}.${value}`;
      },
    },
  },

  methods: {
    validateKey() {
      return this.key.indexOf('.') === -1;
    },
  },

  ready() {
    baseComponent.ready.call(this);

    // Set initial value for condition
    this.keyWrapper = AudienceBuilderFns.getKeyFromConditionName(
      this.condition.name,
    );

    this.$on('validateConditions', () => {
      this.$dispatch('validateConditionsResponse', {
        valid: this.validateKey(),
      });
    });
  },
});

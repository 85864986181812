import { toImmutable } from 'optly/immutable';
import localStorageWrapper from 'optly/utils/local_storage_wrapper';

import { LOCAL_STORAGE_DASHBOARD_CUSTOMIZATION_PREFERENCES } from './constants';

/**
 * @param {Immutable.Map} activity
 * @param {array} keyArray
 * @param {function} appliedFunction
 * @returns {object} containing the before and after values of the getter value
 */
export const retrieveChangedObject = function(
  activity,
  keyArray,
  appliedFunction,
) {
  if (appliedFunction === undefined) {
    // appliedFunction was not passed in
    appliedFunction = value => value;
  }
  const beforeArray = keyArray.concat(['before']);
  const afterArray = keyArray.concat(['after']);
  let before = activity.getIn(beforeArray, null);
  let after = activity.getIn(afterArray, null);
  if (before === null && after === null) {
    after = activity.getIn(keyArray, null);
  }
  before = before !== null ? appliedFunction(before) : null;
  after = after !== null ? appliedFunction(after) : null;
  return {
    before,
    after,
  };
};

export const getActivityUserName = function(activity) {
  const name = null;

  if (activity) {
    if (activity.gae_admin_email) {
      return tr('Optimizely Support');
    }

    if (!activity.gae_admin_email && !activity.email) {
      return tr('Optimizely scheduler');
    }

    if (!activity.gae_admin_email && activity.email) {
      return activity.email;
    }
  }

  return name;
};

export const getSearchParamValue = param => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};

export const setSearchParams = (params = {}) => {
  const entries = Object.entries(params);
  if (!entries.length) {
    return;
  }
  const url = new URL(window.location.href);
  entries.forEach(([key, value]) => {
    if (value === null) {
      url.searchParams.delete(key);
    } else {
      url.searchParams.set(key, value);
    }
  });
  window.history.pushState({}, '', url);
};

export const getFieldsParams = function(tab) {
  const fieldsParams = getSearchParamValue(`fields_${tab}`);

  if (fieldsParams) {
    try {
      const fields = fieldsParams.split(',');
      return toImmutable(
        fields.reduce((acc, field) => {
          acc[field] = true;
          return acc;
        }, {}),
      );
    } catch (error) {
      console.error('Error parsing fields Search Param', error);
    }
  }
};

export const persitCustomization = function(state) {
  localStorageWrapper.setItem(
    LOCAL_STORAGE_DASHBOARD_CUSTOMIZATION_PREFERENCES,
    JSON.stringify(state.toJS()),
  );
};

/**
 * Module specific pure functions
 */
export default {
  retrieveChangedObject,
  getActivityUserName,
  getFieldsParams,
  setSearchParams,
  getSearchParamValue,
  persitCustomization,
};

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Immutable from 'optly/immutable';
import ui from 'core/ui';

import EmailAddressField from 'react_components/email_address_field';
import PasswordField from 'react_components/password_field';
import ProfileDetailFns from 'optly/modules/profile_details/fns';
import ValidatedSelectField from 'react_components/validated_select_field';
import ValidatedTextField from 'react_components/validated_text_field';
import { actions as UserActions } from 'optly/modules/entity/user';

import {
  COMMUNICATION_VALUES,
  COMMUNICATION_PREFS,
  COUNTRY_COMMUNICATION_PREFS,
  COUNTRY_COMMUNICATION_DEFAULT_PREFS,
  HIDDEN_COMMUNICATION_PREFS,
  DEPARTMENTS,
  FORM_FIELDS,
  LABELS,
  PLACEHOLDERS,
  ROLES,
  TITLES,
  UNSELECTED,
  VALIDATION_ERRORS,
  SUPPORTED_COUNTRIES,
  LEGACY_COMMUNICATION_PREFS,
} from 'optly/modules/profile_details/constants';

/**
 * Decouples display values seen by user from actual form field values
 */
const DEPARTMENT_DISPLAY_VALUES = _.map(_.values(DEPARTMENTS), department =>
  ProfileDetailFns.transformDepartmentField(department),
).concat(UNSELECTED);
const ROLES_DISPLAY_VALUES = _.values(ROLES).concat(UNSELECTED);

const sendVerificationEmail = () => {
  UserActions.sendVerificationEmail().done(() => {
    ui.showNotification({
      type: 'success',
      message: tr(
        'An email verification link has been sent to you. Please click that link to verify your email.',
      ),
    });
  });
};

/**
 * Form component for editing profile details.
 * @param {Object} props - Properties passed to component
 * @returns {ReactElement}
 */
function ProfileDetailsForm({
  description,
  errors,
  fields,
  formData,
  isSsoEnabled,
  onChange,
  showChangeEmailDialog,
  showChangePasswordDialog,
  title,
  isManagedByAdminCenter,
}) {
  useEffect(() => {
    const currentCommunicationPref = formData.get('communication_preference');
    const currentCountry = formData.get('country');
    if (
      currentCommunicationPref &&
      LEGACY_COMMUNICATION_PREFS.includes(currentCommunicationPref)
    ) {
      const preferenceByCountry =
        COUNTRY_COMMUNICATION_PREFS[currentCountry] ||
        COUNTRY_COMMUNICATION_DEFAULT_PREFS[currentCountry] ||
        COUNTRY_COMMUNICATION_DEFAULT_PREFS.DEFAULT;
      onChange({
        currentCountry,
        communication_preference: preferenceByCountry,
      });
    }
  }, []);

  const onDepartmentChange = departmentEvent => {
    // Transforms department name before invoking onChange handler
    onChange({
      job_department: ProfileDetailFns.transformDepartmentField(
        departmentEvent.job_department,
        false,
      ),
    });
  };

  /**
   * @member onCountryChange
   * @memberof ProfileDetailsForm
   * @description We hide the communication dropdown for `soft-opt-in` and `implict-opt-in` countries.
   * This handler ensures the correct communication preference is set for those countries. We refer to
   * countries with hidden preferences as "implied" countries.
   * @param {Object} countryEvent - Event object when a user changes country dropdown value
   * @param {String} countryEvent.country - country code (i.e. DE is the code for Germany)
   * @returns {any}
   * @private
   */
  const onCountryChange = countryEvent => {
    const { country } = countryEvent;
    const currentCommunicationPref = formData.get('communication_preference');
    const countryPref =
      COUNTRY_COMMUNICATION_PREFS[country] ||
      COUNTRY_COMMUNICATION_DEFAULT_PREFS[country] ||
      COUNTRY_COMMUNICATION_DEFAULT_PREFS.DEFAULT;
    const isImplied =
      countryPref === COMMUNICATION_VALUES.SOFT_OPT_IN ||
      countryPref === COMMUNICATION_VALUES.IMPLICIT_OPT_IN;
    const hasImpliedValue =
      currentCommunicationPref === COMMUNICATION_VALUES.SOFT_OPT_IN ||
      currentCommunicationPref === COMMUNICATION_VALUES.IMPLICIT_OPT_IN;
    return isImplied
      ? onChange({ country, communication_preference: countryPref })
      : onChange({
          country,
          communication_preference: hasImpliedValue
            ? ''
            : currentCommunicationPref,
        });
  };

  // Conditionally render form title and description
  const formHeader = !!title && (
    <div className="lego-layout--single-column max-width--reading push-quad--bottom">
      <h2 className="weight--light" data-test-section="profile-details-title">
        {title}
      </h2>
      <p>{description}</p>
    </div>
  );

  // Use the user's country preferences to determine if the "Marketing Communication Preference" dropdown should render.
  // For countries with hidden preferences we hide the dropdown and manually set the communication preferences.
  // We do this for marketing and legal purposes; to comply with GDPR.
  const countryPref =
    COUNTRY_COMMUNICATION_PREFS[formData.get('country')] ||
    COUNTRY_COMMUNICATION_DEFAULT_PREFS[formData.get('country')] ||
    COUNTRY_COMMUNICATION_DEFAULT_PREFS.DEFAULT;
  const hideCommunicationPerf =
    !formData.get('country') ||
    countryPref === COMMUNICATION_VALUES.SOFT_OPT_IN ||
    countryPref === COMMUNICATION_VALUES.IMPLICIT_OPT_IN;

  // Render form
  return (
    <div className="flex--1" data-test-section="profile-details">
      {formHeader}
      <form>
        <fieldset className="flush--bottom">
          <div className="lego-grid">
            <div className="soft-double--left width--1-2 push-double--bottom">
              <ValidatedTextField
                error={
                  errors.firstNameError ? VALIDATION_ERRORS.FIRST_NAME : ''
                }
                label={LABELS.FIRST_NAME}
                name="first_name"
                onChange={onChange}
                placeholder={PLACEHOLDERS.FIRST_NAME}
                testSection="profile-details-first-name-field"
                value={formData.get('first_name')}
                disabled={isManagedByAdminCenter}
              />
            </div>
            <div className="soft-double--left width--1-2 push-double--bottom">
              <ValidatedTextField
                error={errors.lastNameError ? VALIDATION_ERRORS.LAST_NAME : ''}
                label={LABELS.LAST_NAME}
                name="last_name"
                onChange={onChange}
                placeholder={PLACEHOLDERS.LAST_NAME}
                testSection="profile-details-last-name-field"
                value={formData.get('last_name')}
                disabled={isManagedByAdminCenter}
              />
            </div>
            <div className="soft-double--left width--1-2 push-double--bottom">
              <ValidatedSelectField
                error={
                  errors.jobDepartmentError
                    ? VALIDATION_ERRORS.JOB_DEPARTMENT
                    : ''
                }
                label={
                  <span className="oui-label--required">
                    {LABELS.DEPARTMENT}
                  </span>
                }
                name="job_department"
                onChange={onDepartmentChange}
                options={DEPARTMENT_DISPLAY_VALUES}
                testSection="profile-details-department-field"
                value={
                  ProfileDetailFns.transformDepartmentField(
                    formData.get('job_department'),
                  ) || UNSELECTED
                }
              />
            </div>
            <div className="soft-double--left width--1-2 push-double--bottom">
              <ValidatedSelectField
                error={errors.jobRoleError ? VALIDATION_ERRORS.JOB_ROLE : ''}
                label={
                  <span className="oui-label--required">{LABELS.ROLE}</span>
                }
                name="job_role"
                onChange={onChange}
                options={ROLES_DISPLAY_VALUES}
                testSection="profile-details-role-field"
                value={formData.get('job_role') || UNSELECTED}
              />
            </div>
            <div className="soft-double--left width--1-2 push-double--bottom">
              <ValidatedSelectField
                error={errors.countryError ? VALIDATION_ERRORS.COUNTRY : ''}
                label={
                  <span className="oui-label--required">{LABELS.COUNTRY}</span>
                }
                name="country"
                onChange={onCountryChange}
                options={_.values(SUPPORTED_COUNTRIES)}
                testSection="profile-details-country-field"
                value={
                  formData.get('country') || SUPPORTED_COUNTRIES.EMPTY.value
                }
              />
            </div>
            <div className="soft-double--left width--1-2 push-double--bottom">
              <ValidatedTextField
                error={
                  errors.phoneNumberError ? VALIDATION_ERRORS.PHONE_NUMBER : ''
                }
                isOptional={true}
                label={LABELS.PHONE_NUMBER}
                name="phone_number"
                onChange={onChange}
                placeholder={PLACEHOLDERS.PHONE_NUMBER}
                testSection="profile-details-phone-number-field"
                value={formData.get('phone_number')}
              />
            </div>
            <div className="soft-double--left width--1-2 push-double--bottom">
              <EmailAddressField
                label={LABELS.EMAIL_ADDRESS}
                isVerified={formData.get('email_verified')}
                isSsoEnabled={isSsoEnabled}
                onChangeLinkClick={showChangeEmailDialog}
                onVerifyLinkClick={sendVerificationEmail}
                value={formData.get('id')}
                visible={_.isEqual(fields, FORM_FIELDS.PROFILE_PAGE)}
                managedByAdminCenter={isManagedByAdminCenter}
              />
            </div>

            <div className="soft-double--left width--1-2 push-double--bottom">
              {!isManagedByAdminCenter && (
                <PasswordField
                  isSsoEnabled={isSsoEnabled}
                  onChangeLinkClick={showChangePasswordDialog}
                  visible={_.isEqual(fields, FORM_FIELDS.PROFILE_PAGE)}
                />
              )}
            </div>
            <div className="soft-double--left width--1-2 push-double--bottom">
              <ValidatedSelectField
                label={
                  <span className="oui-label--required">
                    {LABELS.COMMUNICATION_PREF}
                  </span>
                }
                error={
                  errors.communicationPreferenceError
                    ? VALIDATION_ERRORS.COMMUNICATION_PREF
                    : ''
                }
                name="communication_preference"
                isHidden={hideCommunicationPerf}
                onChange={onChange}
                options={_.values(
                  hideCommunicationPerf
                    ? HIDDEN_COMMUNICATION_PREFS
                    : COMMUNICATION_PREFS,
                )}
                testSection="profile-details-communication-pref-field"
                value={
                  hideCommunicationPerf
                    ? countryPref
                    : formData.get('communication_preference') || countryPref
                }
              />
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

ProfileDetailsForm.propTypes = {
  /** Sets the description copy text for the form */
  description: PropTypes.string,
  /** Sets validation error text for applicable fields */
  errors: PropTypes.shape({
    communicationPreferenceError: PropTypes.bool,
    countryError: PropTypes.bool,
    firstNameError: PropTypes.bool,
    jobDepartmentError: PropTypes.bool,
    jobRoleError: PropTypes.bool,
    lastNameError: PropTypes.bool,
    phoneNumberError: PropTypes.bool,
  }),
  /** Sets which fields are modified by the form */
  fields: PropTypes.array.isRequired,
  /** Sets the value of each field in the form */
  formData: PropTypes.instanceOf(Immutable.Map).isRequired,
  /** Sets whether the user is signed in via single sign on */
  isSsoEnabled: PropTypes.bool,
  /** Sets the event handler for user input events */
  onChange: PropTypes.func,
  /** Sets the handler for 'Change Email Address' link clicks */
  showChangeEmailDialog: PropTypes.func,
  /** Sets the handler for 'Change Password' link clicks */
  showChangePasswordDialog: PropTypes.func,
  /** Sets the title copy text for the form */
  title: PropTypes.oneOf(_.values(TITLES)),
  /** Sets the property user is managed by admin center */
  isManagedByAdminCenter: PropTypes.bool,
};

ProfileDetailsForm.defaultProps = {
  description: null,
  errors: {},
  isSsoEnabled: false,
  title: TITLES.DEFAULT,
  isManagedByAdminCenter: false,
};

export default ProfileDetailsForm;

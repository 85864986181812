const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const actionTypes = require('../action_types');

const INITIAL_STATE = {
  currentSelector: null,
  currentSelectorInfo: {},
  editorState: {
    isEditingSelector: false,
  },
};

module.exports = Nuclear.Store({
  getInitialState() {
    return toImmutable(INITIAL_STATE);
  },

  initialize() {
    this.on(
      actionTypes.P13N_TAG_EDITOR_SET_CURRENT_SELECTOR,
      setTagEditorSelector,
    );
    this.on(
      actionTypes.P13N_TAG_EDITOR_SET_CURRENT_SELECTOR_INFO,
      setTagEditorSelectorInfo,
    );
    this.on(
      actionTypes.P13N_TAG_EDITOR_SET_IS_EDITING_SELECTOR,
      setTagEditorIsEditingSelector,
    );
  },
});

function setTagEditorSelector(state, payload) {
  return state.set('currentSelector', payload.selector);
}

function setTagEditorSelectorInfo(state, payload) {
  return state.set('currentSelectorInfo', toImmutable(payload.selectorInfo));
}

function setTagEditorIsEditingSelector(state, payload) {
  return state.setIn(['editorState', 'isEditingSelector'], payload.isEditing);
}

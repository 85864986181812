import $ from 'jquery';
import Vue from 'vue';

const CustomCodeMixin = {
  /** ***********************************************************************************
   * This mixin adds in functionality for reuse within custom code changes at
   * experience and layer level (custom js/css) code mirror components
   *
   * Components using this mixin should define the following properties in their config:
   * {
   *   data: {
   *     currentType : one of types defined in LayerExperiment.enums.CustomCodeTypes
   *     this.$.codeMirror  - respective js/css codemirror component
   *     topPaddingOffset(optional) - offset to correctly set height various nested elements inside the root codemirror instance
   *     isDirty - boolean flag to indicate dirty state of codemirror component
   *   }
   * }
   *
   *************************************************************************************
   */
  methods: {
    initializeCodeMirror(
      codeMirrorInstance,
      initialValue,
      type,
      tab_container_selector,
    ) {
      if (!codeMirrorInstance) {
        return;
      }
      codeMirrorInstance.rebuildEditor();
      this.setCode(codeMirrorInstance, type, initialValue); // from CustomCode mixin
      const topPaddingOffset = this.topPaddingOffset || 0;

      // This is necessary for codeMirror to correctly set the height of various nested
      // elements inside the root container (ie., we can't do this with CSS).
      // The result is the inner codeMirror textarea always being clickable and appearing
      // as the same height as its parent element (whose height is determined by CSS).
      // Todo(Tom): Update this hardcoded number with 32 offset when a cleaner CSS wrapper is added.
      codeMirrorInstance.setSize(
        'auto',
        $(codeMirrorInstance.$el)
          .closest(tab_container_selector)
          .height() - topPaddingOffset,
      );
    },

    /**
     * Handle the message from the code mirror component that the code changed.
     *
     */
    handleCodeChanged() {
      if (this.currentType === this.customCodeTypes.CSS) {
        this.lintCode(this.$.cssCodeMirror);
        this.updateDirtyState(
          this.$.cssCodeMirror,
          this.customCodeTypes.CSS,
          this.currentCustomCss,
        );
      } else {
        this.lintCode(this.$.javascriptCodeMirror);
        this.updateDirtyState(
          this.$.javascriptCodeMirror,
          this.customCodeTypes.JAVASCRIPT,
          this.currentCustomJs,
        );
      }
    },

    /**
     * Handle code revert in code mirror component
     *
     */
    handleRevertClick(
      codeMirrorInstance,
      customCodeType,
      customCode,
      tabContainerSelector,
    ) {
      this.setCode(
        codeMirrorInstance,
        customCodeType,
        customCode,
        tabContainerSelector,
      );
      this.updateDirtyState(codeMirrorInstance, customCodeType, customCode);
      this.rebuildCodeMirror(codeMirrorInstance, tabContainerSelector);
    },

    /**
     * Call CodeMirror's lintCode function.
     * @param {CodeMirror} codeMirrorInstance - The CodeMirror instance to set the code in.
     */
    lintCode(codeMirrorInstance) {
      if (codeMirrorInstance) {
        codeMirrorInstance.lintCode();
      }
    },

    rebuildCodeMirror(codeMirrorInstance, tab_container_selector) {
      if (!codeMirrorInstance) {
        return;
      }
      codeMirrorInstance.rebuildEditor();
      const topPaddingOffset = this.topPaddingOffset || 0;

      codeMirrorInstance.setSize(
        'auto',
        $(codeMirrorInstance.$el)
          .closest(tab_container_selector)
          .height() - topPaddingOffset,
      );
    },

    /**
     * Sets the code value on the code editor
     * @param {CodeMirror} codeMirrorInstance - The CodeMirror instance to set the code in.
     * @param {LayerExperiment.enums.CustomCodeTypes} type - The mode type for the cm instance.
     * @param {string} code - The JS/CSS code to set on the code editor.
     * @param {string} tab_container_selector - the codemirror tab container selector.
     */
    setCode(codeMirrorInstance, type, code, tab_container_selector) {
      if (!codeMirrorInstance) {
        return;
      }
      const editorOptions = {
        mode: type.toLowerCase(),
      };
      code = code || '';
      codeMirrorInstance.setCode(code, editorOptions);
      this.rebuildCodeMirror(codeMirrorInstance, tab_container_selector);
    },

    /**
     * Sets the type of custom code being currently edited (visible).
     * @param {LayerExperiment.enums.CustomCodeTypes} type
     * @param {selector} tab_container_selector
     */
    setType(type, tab_container_selector) {
      this.currentType = type;

      // Allow the CodeMirror instance to be visible in the dom before rebuilding.
      if (this.currentType === this.customCodeTypes.JAVASCRIPT) {
        Vue.nextTick(
          this.rebuildCodeMirror.bind(
            this,
            this.$.javascriptCodeMirror,
            tab_container_selector,
          ),
        );
      } else if (this.currentType === this.customCodeTypes.CSS) {
        Vue.nextTick(
          this.rebuildCodeMirror.bind(
            this,
            this.$.cssCodeMirror,
            tab_container_selector,
          ),
        );
      }
    },

    /**
     * Sets the dirty state for the given custom code type by setting the CodeMirror instance's dirty
     * flag as well as the editor stores flag for dirty custom code tabs.
     * @param {CodeMirror} codeMirrorInstance - The CodeMirror instance to set the code in.
     * @param {LayerExperiment.enums.CustomCodeTypes} type
     * @param {string} savedValue - The currently saved value in the datastore for the type indicated.
     */
    updateDirtyState(codeMirrorInstance, type, savedValue) {
      if (!codeMirrorInstance) {
        return;
      }
      this.isDirty = false;
      if (savedValue && savedValue !== codeMirrorInstance.editorCode) {
        // We have a saved value and its not the same as the CodeMirror value.
        this.isDirty = true;
      } else if (!savedValue && codeMirrorInstance.editorCode.length > 0) {
        // We dont have a saved value (or its an empty string) and CodeMirror has a value.
        this.isDirty = true;
      }
      codeMirrorInstance.setDirtyFlag(this.isDirty);
    },
  },
};

export default CustomCodeMixin;

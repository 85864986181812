import Nuclear from 'nuclear-js';
import Immutable, { toImmutable } from 'optly/immutable';

import * as LayerConstants from 'optly/modules/entity/layer/constants';

import actionTypes from '../action_types';

const initialState = toImmutable({
  selectedViewIds: Immutable.Set(),
  urlTargetingConfig: LayerConstants.DEFAULT_URL_TARGETING_CONFIG,
  targetingType: null,
  errors: {},
});

export default Nuclear.Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.LAYER_DIALOG_SET_VIEW_IDS, setViewIds);
    this.on(
      actionTypes.LAYER_DIALOG_SET_URL_TARGETING_CONFIG,
      setUrlTargetingConfig,
    );
    this.on(actionTypes.LAYER_DIALOG_SET_TARGETING_TYPE, setTargetingType);
    this.on(actionTypes.LAYER_DIALOG_SET_ERRORS, setErrors);
    this.on(actionTypes.LAYER_DIALOG_RESET, reset);
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Array} payload.viewIds
 */
function setViewIds(state, payload) {
  return state.set('selectedViewIds', payload.viewIds);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Object} payload.urlTargetingConfig
 */
function setUrlTargetingConfig(state, payload) {
  return state.set('urlTargetingConfig', payload.urlTargetingConfig);
}

function setTargetingType(state, payload) {
  return state.set('targetingType', payload.targetingType);
}

function setErrors(state, payload) {
  return state.mergeIn(['errors'], toImmutable(payload));
}

function reset(state) {
  return initialState;
}

/**
 * Entity Definition for event
 */
export default {
  entity: 'events',

  parent: {
    entity: 'projects',
    key: 'project_id',
  },
};

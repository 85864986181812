import React from 'react';
import PropTypes from 'prop-types';

import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';

import { Icon } from '@optimizely/axiom';

import SelectDropdown from 'react_components/select_dropdown';

import Immutable from 'optly/immutable';

const CatalogPicker = ({
  canChangeCatalog,
  catalogs,
  currentlySelectedCatalogID,
  defaultCatalog,
  handleCatalogChange,
}) => {
  let catalogWrappers = catalogs
    .map(catalog => ({
      description: catalog.get('description'),
      label: catalog.get('name'),
      value: catalog.get('id'),
    }))
    .toList();

  catalogWrappers = catalogWrappers.unshift({
    label: 'Select a catalog...',
    value: defaultCatalog.get('id'),
  });

  return (
    <div
      className="push-triple--bottom"
      data-test-section="recommender-catalog-picker">
      <h4>Catalog</h4>
      {canChangeCatalog ? (
        <React.Fragment>
          <div
            className="push--bottom"
            data-test-section="select-catalog-help-text">
            In selecting the catalog, you are determining which events this
            recommender uses.
          </div>
          <SelectDropdown
            inputPlaceholder="Select a catalog..."
            isFilterable={true}
            items={catalogWrappers}
            minDropdownWidth={300}
            onChange={handleCatalogChange}
            testSection="catalog-picker-dropdown"
            value={currentlySelectedCatalogID}
            width={200}
          />
        </React.Fragment>
      ) : (
        <span
          className="zeta word-break--word"
          data-test-section="parent-catalog-name">
          {catalogs.getIn([currentlySelectedCatalogID, 'name'])}
        </span>
      )}
      <div className="flex flex-align--center push--top">
        <Icon color={brandBlueDark} size="small" name="circle-exclamation" />
        <div
          className="flex--1 push-half--left"
          data-test-section="change-catalog-warning-text">
          Once this recommender has been saved, you can't change the catalog
          it's assigned to.
        </div>
      </div>
    </div>
  );
};

CatalogPicker.propTypes = {
  canChangeCatalog: PropTypes.bool.isRequired,
  catalogs: PropTypes.instanceOf(Immutable.Map).isRequired,
  currentlySelectedCatalogID: PropTypes.number.isRequired,
  defaultCatalog: PropTypes.instanceOf(Immutable.Map).isRequired,
  handleCatalogChange: PropTypes.func.isRequired,
};

export default CatalogPicker;

import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'optimizely-oui';

import ChangesTableRow from './changes_table_row';

const ChangesTable = ({
  allDetailExpandedCount,
  canUserSeeRevisionsWithoutChanges,
  changes,
  currentProjectId,
  handleShowHideAll,
  isAllDetailExpanded,
  prettyDiffLineCap,
  shouldPrettifyDiff,
}) => (
  <Table
    density="loose"
    key="changes-table"
    style="rule"
    tableLayoutAlgorithm="fixed"
    testSection="change-history-changes-table">
    <ChangeHistoryTableHeader />
    <Table.TBody>
      {changes.map(change => (
        <ChangesTableRow
          authorInfo={change.user}
          change={change}
          currentProjectId={currentProjectId}
          handleShowHideAll={handleShowHideAll}
          isAllDetailExpanded={isAllDetailExpanded}
          key={`${change.id}_${allDetailExpandedCount}`}
          prettyDiffLineCap={prettyDiffLineCap}
          shouldPrettifyDiff={shouldPrettifyDiff}
          canUserSeeRevisionsWithoutChanges={canUserSeeRevisionsWithoutChanges}
        />
      ))}
    </Table.TBody>
  </Table>
);

ChangesTable.propTypes = {
  /** Number that will be increased and used via the key prop to force a new instance of the table row component */
  allDetailExpandedCount: PropTypes.number.isRequired,
  canUserSeeRevisionsWithoutChanges: PropTypes.bool,
  changes: PropTypes.array.isRequired,
  currentProjectId: PropTypes.number.isRequired,
  handleShowHideAll: PropTypes.func.isRequired,
  isAllDetailExpanded: PropTypes.bool.isRequired,
  prettyDiffLineCap: PropTypes.number.isRequired,
  shouldPrettifyDiff: PropTypes.bool.isRequired,
};

ChangesTable.defaultProps = {
  canUserSeeRevisionsWithoutChanges: false,
};

export default ChangesTable;

function ChangeHistoryTableHeader() {
  return (
    <Table.THead>
      <Table.TR>
        <Table.TH width="50%">
          <span data-test-section="change-history-summary-column-header">
            Change Summary
          </span>
        </Table.TH>
        <Table.TH width="150px">
          <span data-test-section="change-history-by-column-header">By</span>
        </Table.TH>
        <Table.TH width="168px">
          <span data-test-section="change-history-time-column-header">
            Time
          </span>
        </Table.TH>
        <Table.TH width="140px" />
        <Table.TH width="50px" />
      </Table.TR>
    </Table.THead>
  );
}

export default {
  MAX_COMBINATION_COUNT: 64,
  ErrorMessages: {
    EXCEEDS_MAX_COMBINATION_COUNT: tr(
      "You've exceeded the maximum number of combinations. Please remove sections or variations to save this experiment.",
    ),
    SECTION_NAMES_MISSING: tr(
      'Please provide section names for all your sections.',
    ),
    SECTION_NAME_MISSING: tr('Please provide a section name for your section'),
    VARIATION_NAMES_MISSING: tr(
      'Please provide variation names for all your variations.',
    ),
    VARIATION_ALLOCATION_NOT_100: tr(
      'Please ensure that variation traffic adds up to 100% for each section.',
    ),
  },
  REQUIRED_TOTAL_VARIATION_PERCENTAGE_PER_SECTION: 100,
};

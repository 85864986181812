import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Disclose } from 'optimizely-oui';

import { fieldPropType } from 'react_components/form';
import InternalLink from 'react_components/link';

import FeatureFns from 'optly/modules/entity/feature_flag/fns';
import FeatureConstants from 'optly/modules/entity/feature_flag/constants';
import UrlHelper from 'optly/services/url_helper';

import VariableValueInput from 'bundles/p13n/components/variable_value_input';

/**
 * VariablesValueCell
 * @description Renders a table-cell that contains a variable-input. This is should be used for feature-variables tables only.
 * @kind component
 * @example
 *   <VariablesValueCell
 *     readOnly={true}
 *     typeField={form.field('variables').field('type')}
 *     valueField={form.field('variables').field('default_value')}
 *   />
 */
const VariablesValueCell = ({
  featureId,
  isDisabled,
  isOpen,
  projectId,
  readOnly,
  showLabel,
  testSection,
  typeField,
  valueField,
}) => {
  useEffect(() => {
    valueField.validators({
      isDefaultValueValid: defaultValue => {
        if (
          !FeatureFns.isVariableValueInputValid(
            // Ensure we never pass an undefined value to the check-validitiy function.
            // defaultValue can be undefined if the formField is not found.
            // https://optimizely.atlassian.net/browse/MGMT-3091
            defaultValue === undefined ? '' : defaultValue,
            typeField.getValue(),
          )
        ) {
          return FeatureConstants.FEATURE_VARIABLE_ERRORS[typeField.getValue()]
            .valueError;
        }
      },
    });
  }, []);

  const VariableValue = () => {
    if (!readOnly) {
      return (
        <VariableValueInput
          disableVariable={isDisabled}
          field={valueField}
          type={typeField.getValue()}
          testSectionPrefix={testSection}
        />
      );
    }

    if (typeField.getValue() === FeatureConstants.FEATURE_VARIABLE_TYPES.json) {
      return (
        <InternalLink
          ariaLabel="Link To Variable Keys & Types Page"
          href={UrlHelper.featureManagerVariables(projectId, featureId)}
          isDisabled={isDisabled}
          testSection={`${testSection}-view-link`}>
          View
        </InternalLink>
      );
    }

    return (
      <span
        className="word-break--word"
        data-test-section={`${testSection}-read-only`}>
        {valueField.getValue()}
      </span>
    );
  };

  const renderVariableValue = () => {
    if (typeField.getValue() === FeatureConstants.FEATURE_VARIABLE_TYPES.json) {
      return (
        <>
          {showLabel ? (
            <Disclose
              headerStyle="header"
              indented={false}
              isOpen={isOpen}
              title={<h6 className="muted">Default Value</h6>}>
              {VariableValue()}
            </Disclose>
          ) : (
            VariableValue()
          )}
        </>
      );
    }

    return (
      <>
        {showLabel && (
          <h6 className="muted push-half--bottom">Default Value</h6>
        )}
        {VariableValue()}
      </>
    );
  };

  return (
    <div>
      {renderVariableValue()}
      {valueField.getErrors().hasError && (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section={`${testSection}-error`}>
          {valueField.getErrors().message}
        </div>
      )}
    </div>
  );
};

VariablesValueCell.defaultProps = {
  isDisabled: false,
  isOpen: true,
  readOnly: false,
  showLabel: false,
  testSection: 'variables-value-cell',
};

VariablesValueCell.propTypes = {
  featureId: PropTypes.number,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  projectId: PropTypes.number,
  readOnly: PropTypes.bool,
  showLabel: PropTypes.bool,
  testSection: PropTypes.string,
  typeField: fieldPropType.isRequired,
  valueField: fieldPropType.isRequired,
};

export default VariablesValueCell;

import $ from 'jquery';
import Router from 'core/router';

/**
 * Push state directive
 *
 * @author Andrew Delikat (andrew@optimizely.com)
 */
const exported = {
  bind() {
    function shouldUseDefault(e) {
      const MIDDLE_BUTTON = 2;
      return e.which === MIDDLE_BUTTON || e.ctrlKey || e.shiftKey || e.metaKey;
    }
    $(this.el).on('click', e => {
      const path = $(this.el).attr('href');
      // Allow clicks with key modifiers to follow default browser behavior
      if (shouldUseDefault(e)) {
        return true;
      }
      e.preventDefault();
      Router.go(path);
      return false;
    });
  },
};

export default exported;

export const { bind } = exported;

export const TrafficAllocationPolicyTypes = {
  MANUAL: 'manual',
  MAXIMIZE_CONVERSIONS: 'min-regret', // MAB
  MINIMIZE_TIME: 'min-time',
  CMAB: 'contextual-multi-arm-bandit',
};

export const Variations = {
  VARIATION_ORIGINAL: {
    name: tr('Original'),
    weight: 5000,
  },
  VARIATION_1_NO_VARIABLE_VALUES: {
    name: tr('Variation #1'),
    weight: 5000,
  },
  VARIATION_1: {
    name: tr('Variation #1'),
    weight: 5000,
    variable_values: {},
  },
  SINGLE_VARIATION: {
    name: tr('Variation #1'),
    weight: 10000,
    variable_values: {},
  },
};

export const ExploitationRate = {
  MIN: 5000,
  MAX: 9500,
};

export const Sections = {
  DEFAULT_SECTION: {
    name: tr('Section #1'),
    variations: [
      Variations.VARIATION_ORIGINAL,
      Variations.VARIATION_1_NO_VARIABLE_VALUES,
    ],
  },
};

export const ErrorMessages = {
  NAME_MISSING: tr('Please name your experiment.'),
  VIEW_MISSING: tr('Please select at least one page.'),
  URL_TARGETING_MISSING: tr('Please provide a URL for targeting.'),
  URL_TARGETING_API_NAME_MISSING: tr(
    'Please provide an api name for manual targeting.',
  ),
  VARIATION_NAMES_MISSING: tr(
    'Please provide variation names for all your variations.',
  ),
};

export default {
  Variations,
  Sections,
  ErrorMessages,
  ExploitationRate,
};

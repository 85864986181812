import { toImmutable } from 'optly/immutable';
import capitalizeFn from 'optly/filters/capitalize';

import formatFns from 'optly/utils/display_format_fns';
import hasher from 'optly/utils/hasher';

import constants from './constants';

// Need this to reference exported functions in other exported functions so
// that the former can be stubbed in tests
let metricFns;

/**
 * @param {Immutable.Map} metric
 * @param {Immutable.Map} eventsMap
 * @param {Immutable.Map} viewsMap
 * @return {String}
 */
const getEventFromMetric = (metric, eventsMap, viewsMap) => {
  const eventId = metric.get('event_id');
  const eventType = metric.get('event_type');
  if (!eventId) {
    return;
  }

  return eventType === constants.event_type.PAGEVIEW
    ? viewsMap.get(eventId)
    : eventsMap.get(eventId);
};

const getMetricEventApiName = (metric, eventsMap, viewsMap) => {
  const event = getEventFromMetric(metric, eventsMap, viewsMap);

  if (!event) {
    return null;
  }

  return event.get('api_name');
};

const isPageViewMetric = metric =>
  metric.get('event_type') === constants.event_type.PAGEVIEW;

export const isRevenueMetric = metric =>
  metric.get('field') === constants.field.REVENUE;

export const isValueMetric = metric =>
  metric.get('field') === constants.field.VALUE;

export const isAbandonmentMetric = metric => {
  const aggregator = metric.get('aggregator');
  return (
    aggregator === constants.aggregator.BOUNCE ||
    aggregator === constants.aggregator.EXIT
  );
};

/**
 * @param {Immutable.Map} metric
 * @return {Boolean}
 */
export const isMetricOverallRevenue = metric =>
  isRevenueMetric(metric) && !metric.get('event_id');

/**
 * Returns the event name for the event a given metric is associated with
 * (e.g. Visit Page: MyHomepage, cartClicked). Returns empty string if metric
 * isn't associated with an event and "Unnamed event" when the metric's event
 * ID isn't tied to a known event.
 *
 * @param {Immutable.Map} metric
 * @param {Immutable.Map} eventsMap
 * @param {Immutable.Map} viewsMap
 * @return {String}
 */
export const getMetricEventName = (metric, eventsMap, viewsMap) => {
  const eventId = metric.get('event_id');
  const eventType = metric.get('event_type');
  if (eventId) {
    const event =
      eventType === constants.event_type.PAGEVIEW
        ? viewsMap.get(eventId)
        : eventsMap.get(eventId);
    // If event is not found, like in the case with share links, return display title or 'Unnamed event'
    if (!event) {
      const displayTitle = metric.get('display_title');
      return displayTitle || 'Unnamed event';
    }

    return (
      (eventType === constants.event_type.PAGEVIEW ? 'Visit Page: ' : '') +
      event.get('name')
    );
  }
  return '';
};

/**
 * Returns the global name for a given metric or "Unknown" if not a global metric.
 * @param {Immutable.Map} metric
 * @return {String}
 */
export const getMetricGlobalName = metric => {
  const field = metric.get('field');

  switch (field) {
    case constants.field.REVENUE:
    case constants.field.SESSION_DURATION:
      return constants.globalMetricsByField[field];
    default:
      return 'Unknown';
  }
};

/**
 * Get the name for a metric. If one isn't already set one in display_title,
 * return the event name the metric is associated with. If the metric is a
 * global metric, then return it's global name. For bad metrics that don't
 * fit any of these categories, "Unknown" is returned so we can display
 * something.
 *
 * @param {Immutable.Map} metric
 * @param {Immutable.Map} eventsMap
 * @param {Immutable.Map} viewsMap
 * @return {String}
 */
export const getMetricName = (metric, eventsMap, viewsMap) => {
  const metricTitle = metric.get('display_title');
  if (metricTitle) {
    return metricTitle;
  }

  const name = metricFns.getMetricEventName(metric, eventsMap, viewsMap);
  if (name) {
    return name;
  }

  return metricFns.getMetricGlobalName(metric);
};

const _formatStringForDisplay = (
  string,
  capitalize = false,
  pluralize = false,
) => {
  if (string && capitalize) {
    string = capitalizeFn(string);
  }

  if (string && pluralize) {
    string = `${string}s`;
  }

  return string;
};

const getAggregatorForDisplay = aggregator =>
  constants.aggregatorHumanReadable[aggregator] || '';

const getFieldForDisplay = field => constants.fieldHumanReadable[field] || '';

export const getMetricValueTypeForDisplay = (
  metric,
  capitalize = false,
  pluralize = true,
) => {
  const aggregator = metric.get('aggregator');
  const field = metric.get('field');
  let metricValueType;

  switch (aggregator) {
    case constants.aggregator.BOUNCE:
    case constants.aggregator.EXIT:
    case constants.aggregator.RATIO:
      // CASE OF ABANDONMENT
      metricValueType = aggregator;
      break;
    case constants.aggregator.SUM:
      // CASE OF NUMERIC SUM
      metricValueType = getFieldForDisplay(field);
      pluralize = false; // We never pluralize this value
      break;
    case constants.aggregator.UNIQUE:
    case constants.aggregator.COUNT:
      // CASE OF CONVERSION METRIC
      metricValueType = constants.aggregationTypes.CONVERSION;
      break;
    default:
      // SHOULD NEVER REACH THIS
      throw new Error(
        'Unable to get metric value type due to invalid aggregator',
      );
  }

  return _formatStringForDisplay(metricValueType, capitalize, pluralize);
};

export const getScopeForDisplay = (
  metric,
  capitalize = false,
  pluralize = false,
) => {
  const scope = metric.get('scope');
  const aggregator = metric.get('aggregator');
  let displayScope;

  if (aggregator === 'ratio') {
    displayScope = 'Sample';
  } else if (isAbandonmentMetric(metric)) {
    displayScope =
      constants.abandonmentMetricScopeHumanReadable.QUALIFIED_SESSION;
    displayScope = !capitalize ? displayScope.toLowerCase() : displayScope;
  } else {
    displayScope = constants.scopeHumanReadable[scope] || '';
  }

  return _formatStringForDisplay(displayScope, capitalize, pluralize);
};

export const getScopeDescriptionsForDisplay = metric => {
  const scope = metric.get('scope');

  // Abandonment metrics have unique scope descriptions
  if (isAbandonmentMetric(metric)) {
    if (metric.get('aggregator') === constants.aggregator.BOUNCE) {
      return constants.scopeDescriptions.BOUNCE_QUALIFIED_SESSION;
    }
    return constants.scopeDescriptions.EXIT_QUALIFIED_SESSION;
  }

  return constants.scopeDescriptions[scope.toUpperCase()] || '';
};

export const getAverageLabelsForDisplay = (metric, capitalize = false) => {
  const aggregator = metric.get('aggregator');
  let metricValueType;
  let measureTypeStr;

  switch (aggregator) {
    case constants.aggregator.BOUNCE:
    case constants.aggregator.EXIT:
    case constants.aggregator.UNIQUE:
      metricValueType = getMetricValueTypeForDisplay(metric, capitalize, false);
      measureTypeStr = capitalize
        ? capitalizeFn(constants.measureTypeHumanReadable.RATE)
        : constants.measureTypeHumanReadable.RATE;
      return `${metricValueType} ${measureTypeStr}`;

    case constants.aggregator.SUM:
    case constants.aggregator.COUNT: // eslint-disable-line no-case-declarations
      metricValueType = getMetricValueTypeForDisplay(metric, capitalize, true);
      const displayScope = getScopeForDisplay(metric, capitalize, false);
      measureTypeStr = capitalize
        ? capitalizeFn(constants.measureTypeHumanReadable.PER)
        : constants.measureTypeHumanReadable.PER;
      return `${metricValueType} ${measureTypeStr} ${displayScope}`;

    case constants.aggregator.RATIO:
      return constants.aggregator.RATIO;
    default:
      // SHOULD NEVER REACH THIS
      throw new Error('Unable to get average label due to invalid aggregator');
  }
};

/**
 * Given the field (AKA type) and policy of a metric, this returns the scope
 * options for that metric.
 *
 * Note: If the createSingleMetricWrapper function is ever refactored, this
 * function should probably be refactored as well as it contains some
 * potentially brittle logic.
 *
 * @param {field} string
 * @param {policy} string
 * @return {Immutable.List}
 */
export const getScopeOptions = (aggregator, policy) => {
  let scopeOptions = [];

  switch (aggregator) {
    case constants.aggregator.BOUNCE:
    case constants.aggregator.EXIT:
      // CASE OF ABANDONMENT
      // ADD EVENT
      scopeOptions = scopeOptions.concat(constants.eventScopeOptions);
      break;
    case constants.aggregator.SUM:
      // CASE OF NUMERIC SUM
      // ADD VISTOR/SESSION + CONVERSION
      scopeOptions = scopeOptions.concat(
        constants.layerBasedScopeOptions[policy],
      );
      scopeOptions = scopeOptions.concat(constants.revenueScopeOptions);
      break;
    case constants.aggregator.UNIQUE:
    case constants.aggregator.COUNT:
      // CASE OF CONVERSION METRIC
      // ADD VISITOR/SESSION
      scopeOptions = scopeOptions.concat(
        constants.layerBasedScopeOptions[policy],
      );
      break;
    case constants.aggregator.RATIO:
      scopeOptions = scopeOptions.concat(
        constants.layerBasedScopeOptions[policy],
      );
      break;
    default:
      // SHOULD NEVER REACH THIS
      throw new Error('Unable to get scope options due to invalid aggregator');
  }

  return toImmutable(scopeOptions);
};

/**
 * @param {Immutable.Map} metric
 * @return {String}
 */
const getMetricDescription = metric => {
  const aggregator = metric.get('aggregator');
  const field = metric.get('field');
  const scope = metric.get('scope');
  const winningDirection = metric.get('winning_direction');

  // TODO(jordan): perhaps port this to metric data model
  let winningDirectionText = '';
  switch (winningDirection) {
    case constants.winning_direction.DECREASING:
      winningDirectionText = 'Decrease in';
      break;
    default:
      // If no winning direction assume increase (this will be null until we do the backfill)
      winningDirectionText = 'Increase in';
  }

  let aggrText = getAggregatorForDisplay(aggregator);

  let typeText = '';
  switch (field) {
    case constants.field.VALUE:
    case constants.field.REVENUE:
    case constants.field.SESSION_DURATION:
      typeText = getFieldForDisplay(field);
      break;
    default:
      typeText = 'conversions';
      break;
  }

  let scopeText = '';
  switch (scope) {
    case constants.scope.VISITOR:
      scopeText = 'per visitor';
      break;
    case constants.scope.SESSION:
      scopeText = 'per session';
      break;
    case constants.scope.EVENT:
      scopeText = 'per conversion';
      break;
    default:
      break;
  }

  let globalText = '';
  if (!metric.get('event_id') && field) {
    globalText = 'summed across all events';
  }

  // text overrides go here
  // ABANDONMENT METRICS
  //   - remove 'per whatever' from a sum of bounce or exit
  //   - set type text to aggregator + rate
  if (isAbandonmentMetric(metric)) {
    aggrText = '';
    typeText = `${aggregator} ${constants.measureTypeHumanReadable.RATE}`;
    scopeText = '';
  }

  const descriptionString = [aggrText, typeText, scopeText, globalText]
    .filter(String)
    .join(' ')
    .trim();

  return descriptionString.charAt(0).toUpperCase() + descriptionString.slice(1);
};

export const getMetricHash = metric => {
  // hash without display_title because that shouldn't make the metric unique
  const metricAliasProps = metric.set('display_title', null);
  return hasher.hash(metricAliasProps.toJS());
};

/**
 * Aggregation Options
 * This method gets the available aggregator options for a given metric.
 *
 * Global metrics are limited to the revenue option while only pageview events
 * are allowed to have bounce/exit metrics.
 *
 * @param {Immutable.Map} metric
 * @returns {Array}
 */
export const getAggregatorOptions = metric => {
  if (isMetricOverallRevenue(metric)) {
    return constants.overallRevenueAggregatorOptions;
  }

  const eventAggregatorOptions = [
    constants.aggregationOperations[
      constants.aggregationOptions.UNIQUE_CONVERSIONS
    ],
    constants.aggregationOperations[
      constants.aggregationOptions.TOTAL_CONVERSIONS
    ],
    constants.aggregationOperations[constants.aggregationOptions.TOTAL_REVENUE],
    constants.aggregationOperations[constants.aggregationOptions.TOTAL_VALUE],
  ];

  if (isPageViewMetric(metric)) {
    eventAggregatorOptions.push(
      constants.aggregationOperations[constants.aggregationOptions.BOUNCE_RATE],
    );
    eventAggregatorOptions.push(
      constants.aggregationOperations[constants.aggregationOptions.EXIT_RATE],
    );
  }

  return eventAggregatorOptions;
};

/**
 * Aggregation Option Selected
 * This method pulls out the field and aggregator and returns the correct
 * slice of enum for the drop down option to select.
 *
 * @param {Object} metric
 * @returns {String}
 */
export const getAggregatorSelected = metric => {
  const aggregator = metric.get('aggregator');
  const field = metric.get('field');

  switch (aggregator) {
    case constants.aggregator.UNIQUE:
      return constants.aggregationOptions.UNIQUE_CONVERSIONS;
    case constants.aggregator.COUNT:
      return constants.aggregationOptions.TOTAL_CONVERSIONS;
    case constants.aggregator.BOUNCE:
      return constants.aggregationOptions.BOUNCE_RATE;
    case constants.aggregator.EXIT:
      return constants.aggregationOptions.EXIT_RATE;
    case constants.aggregator.SUM:
      switch (field) {
        case constants.field.VALUE:
          return constants.aggregationOptions.TOTAL_VALUE;
        case constants.field.REVENUE:
        default:
          return constants.aggregationOptions.TOTAL_REVENUE;
      }
    case constants.aggregator.RATIO:
      return constants.aggregationOptions.RATIO;
    default:
      throw new Error('Unable to select aggregator');
  }
};

/**
 * Wraps a single metric with additional properties such as name and description.
 *
 * The index parameter is the index of the metric in the metrics list. It is used
 * for checking if the metric is the primary metric. If this function is being
 * used outside of createMetricWrappers, pass in null for the index.
 *
 * Note that BEFORE SAVING THE DATA TO THE FLUX STORE, createMetricWrappers
 * SHOULD BE CALLED so the primary metric value can be set correctly.
 * In cases where this function is used to get the scope options (e.g. in the
 * metric picker), the scope will be also incorrect so the returned metric
 * description here is also incorrect. THEREFORE, RUN createMetricWrappers
 * BEFORE SAVING.
 *
 * @param {Immutable.Map} metric
 * @param {Integer} index
 * @param {Immutable.Map} layer
 * @param {Immutable.Map} viewsMap
 * @param {Immutable.Map} eventsMap
 * @returns {Immutable.Map}
 */
export const createSingleMetricWrapper = (
  metric,
  index,
  layer,
  viewsMap,
  eventsMap,
) => {
  const alias = metric.get('alias') || metricFns.getMetricHash(metric);
  const name = getMetricName(metric, eventsMap, viewsMap);
  const eventName = getMetricEventName(metric, eventsMap, viewsMap);
  const eventApiName = getMetricEventApiName(metric, eventsMap, viewsMap);
  const isPrimary = index === 0;
  let absoluteLiftFormatFn = null;
  let conversionFormatFn = null;
  let increaseFormatFn = null;
  let relativeLiftFormatFn = null;
  let rateFormatFn = null;

  // This scope options function contains some potentially brittle logic
  // If this function ever get refactored, note that this scope options will
  // probably need to be as well
  const scopeOptions = getScopeOptions(
    metric.get('aggregator'),
    layer.get('policy'),
  );

  const aggregatorOptions = getAggregatorOptions(metric);

  if (isRevenueMetric(metric)) {
    absoluteLiftFormatFn = formatFns.formatPriceLift;
    conversionFormatFn = formatFns.formatPriceForDisplay;
    increaseFormatFn = formatFns.formatPriceForDisplay;
    relativeLiftFormatFn = formatFns.formatPercentLift;
    rateFormatFn = formatFns.formatPriceForDisplay;
  } else if (isValueMetric(metric)) {
    absoluteLiftFormatFn = formatFns.formatIncrease;
    conversionFormatFn = formatFns.formatFloatAsFloatOrWholeForDisplay;
    increaseFormatFn = formatFns.formatIncrease;
    relativeLiftFormatFn = formatFns.formatLift;
    rateFormatFn = formatFns.formatFloatForDisplay;
  } else {
    // This is a unique/total conversions or bounce/exit metric
    absoluteLiftFormatFn =
      metric.get('aggregator') === constants.aggregator.COUNT
        ? formatFns.formatIncrease
        : formatFns.formatPercentagePoint;
    conversionFormatFn = formatFns.formatWholeNumber;
    increaseFormatFn = formatFns.formatIncrease;
    relativeLiftFormatFn = formatFns.formatLift;
    rateFormatFn =
      metric.get('aggregator') === constants.aggregator.COUNT
        ? formatFns.formatNumberForDisplay
        : formatFns.formatPercentForDisplay;
  }

  return toImmutable({
    metric,
    alias,
    eventApiName,
    name,
    description: getMetricDescription(metric),
    eventName,
    isPrimary,
    absoluteLiftFormatFn,
    conversionFormatFn,
    increaseFormatFn,
    relativeLiftFormatFn,
    rateFormatFn,
    aggregatorOptions,
    scopeOptions,
  });
};

/**
 * Wraps metrics with additional properties such as name and description.
 *
 * @param {Immutable.List} metrics
 * @param {Immutable.Map} layer
 * @param {Immutable.Map} viewsMap
 * @param {Immutable.Map} eventsMap
 * @returns {Immutable.List}
 */
export const createMetricWrappers = (metrics, layer, viewsMap, eventsMap) =>
  metrics.map((metric, index) =>
    metricFns.createSingleMetricWrapper(
      metric,
      index,
      layer,
      viewsMap,
      eventsMap,
    ),
  );

export default metricFns = {
  isRevenueMetric,
  isValueMetric,
  isAbandonmentMetric,
  isMetricOverallRevenue,
  getMetricDescription,
  getMetricEventName,
  getMetricGlobalName,
  getMetricName,
  getMetricValueTypeForDisplay,
  getScopeForDisplay,
  getScopeDescriptionsForDisplay,
  getAverageLabelsForDisplay,
  getScopeOptions,
  getMetricHash,
  getAggregatorOptions,
  getAggregatorSelected,
  createSingleMetricWrapper,
  createMetricWrappers,
};

import $ from 'jquery';
import PropTypes from 'prop-types';

import React from 'react';

import {
  BlockList,
  FilterPicker,
  Input,
  OverlayWrapper,
  Popover,
  Icon,
} from 'optimizely-oui';

import Immutable from 'optly/immutable';

import { connect } from 'core/ui/decorators';
import { deepEqual } from 'core/utils/react';

import { fns as AudienceFns } from 'optly/modules/entity/audience';

import { actions as P13NDashboardActions } from 'bundles/p13n/modules/dashboard';
import { actions as P13NUIActions } from 'bundles/p13n/modules/ui';

import ExperienceOrderManager from './subcomponents/experience_order_manager_legacy';
import {
  actions as ComponentModuleActions,
  getters as ComponentModuleGetters,
} from './component_module';

@connect({
  orderedAudienceIds: ComponentModuleGetters.orderedAudienceIds,
  validAudiences: ComponentModuleGetters.validAudiences,
})
class ExperienceManager extends React.Component {
  static propTypes = {
    orderedAudienceIds: PropTypes.instanceOf(Immutable.List).isRequired,
    validAudiences: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  shouldComponentUpdate = deepEqual();

  componentWillUnmount() {
    ComponentModuleActions.clearOrderedAudienceIds();
  }

  createAudience = () => {
    const audienceDeferred = $.Deferred();
    audienceDeferred.then(_audience => {
      ComponentModuleActions.addAudienceId(_audience.id);
    });

    P13NDashboardActions.fetchOrCreateAudience(null).then(audience => {
      P13NUIActions.showAudienceEditorDialog(audience, audienceDeferred);
    });
  };

  render() {
    const { orderedAudienceIds, validAudiences } = this.props;

    return (
      <FilterPicker
        allEntities={validAudiences}
        keysToSearch={['name', 'description']}
        selectedEntityIds={orderedAudienceIds}>
        {({ availableEntities, handleFilterInput }) => (
          <div>
            <h3>Experiences</h3>
            <p className="push-double--bottom">
              Choose which audiences to personalize for. Each audience will see
              a separate experience. To create experiences with more complex
              conditions or variations, you can skip this step and add them
              later.
            </p>
            <ExperienceOrderManager />
            <div className="oui-filter-picker-list">
              <Input
                isFilter={true}
                onInput={handleFilterInput}
                placeholder="Browse for Audiences"
                type="search"
              />
              <div
                className="border--bottom"
                style={{
                  maxHeight: 350,
                  overflowY: 'scroll',
                }}>
                <BlockList hasBorder={true}>
                  <BlockList.Category>
                    <FilterPicker.ListItem
                      name="Create New Audience"
                      onClick={this.createAudience}
                      testSection="create-audience-link"
                    />
                  </BlockList.Category>
                  <BlockList.Category header="Audiences">
                    {availableEntities.map(item => {
                      const addAudienceFn = () => {
                        ComponentModuleActions.addAudienceId(item.get('id'));
                      };

                      const editAudienceFn = () => {
                        ComponentModuleActions.editAudience(item.get('id'));
                      };

                      if (AudienceFns.compatibleWithOptimizelyX(item)) {
                        return (
                          <FilterPicker.ListItem
                            key={item.get('id')}
                            id={item.get('id')}
                            name={item.get('name')}
                            description={item.get('description')}
                            onClick={addAudienceFn}
                            buttonText="View"
                            onButtonClick={editAudienceFn}
                            testSection="selectable-audience-row"
                          />
                        );
                      }
                      return (
                        <BlockList.Item key={item.get('id')}>
                          <div
                            className="muted"
                            data-test-section="read-only-audience-row">
                            <OverlayWrapper
                              overlay={
                                <Popover>
                                  This audience is not compatible with
                                  Optimizely X. You can edit this audience or
                                  target with it only in Optimizely Classic.
                                </Popover>
                              }>
                              <div className="push--right">
                                <Icon name="circle-exclamation" size="small" />
                              </div>
                            </OverlayWrapper>
                            <div
                              className="drop-filter-list__text display--inline"
                              data-test-section="unselected-audience-title">
                              {item.get('name')}
                              <div className="drop-filter-list__description">
                                {item.get('description')}
                              </div>
                            </div>
                          </div>
                        </BlockList.Item>
                      );
                    })}
                  </BlockList.Category>
                </BlockList>
              </div>
            </div>
          </div>
        )}
      </FilterPicker>
    );
  }
}

export default ExperienceManager;

import React from 'react';
import ui from 'core/ui';
import Immutable, { toImmutable } from 'optly/immutable';
import PropTypes from 'prop-types';

import SectionModuleConstants from 'bundles/p13n/sections/oasis_experiment_manager/section_module/constants';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import LoadingOverlay from 'react_components/loading_overlay';

import { Attention, Button, ButtonRow } from 'optimizely-oui';
import { Title, Footer, Wrapper, Fieldset } from 'react_components/dialog';

import ForcedVariationsTable from './components/forced_variations_table';

const getNewForcedVariationRow = experiment => {
  const defaultVariationId = experiment
    .get('variations')
    .filter(
      variation =>
        variation.get('status') === LayerExperimentEnums.VariationStatus.ACTIVE,
    )
    .first()
    .get('variation_id');
  return {
    user_id: null,
    variation_id: defaultVariationId,
    match_type: SectionModuleConstants.FORCED_VARIATION_MATCH_TYPES.EXACT,
  };
};

export default class WhitelistDialog extends React.Component {
  static componentId = 'oasis-experiment-whitelist-dialog';

  static propTypes = {
    experiment: PropTypes.instanceOf(Immutable.Map).isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const savedForcedVariations = props.experiment.get('forced_variations');
    const currentForcedVariations =
      savedForcedVariations && savedForcedVariations.size === 0
        ? toImmutable([getNewForcedVariationRow(props.experiment)])
        : savedForcedVariations;

    this.state = {
      isSaving: false,
      errors: toImmutable([]),
      savedForcedVariations,
      currentForcedVariations,
    };
  }

  addForcedVariationRow = () => {
    const { experiment } = this.props;
    this.setState(prevState => ({
      currentForcedVariations: prevState.currentForcedVariations.push(
        toImmutable(getNewForcedVariationRow(experiment)),
      ),
    }));
  };

  handleUserIdChange = (index, value) => {
    this.setState(prevState => ({
      currentForcedVariations: prevState.currentForcedVariations.update(
        index,
        forcedVariation => forcedVariation.set('user_id', value),
      ),
    }));
  };

  handleVariationIdChange = (index, value) => {
    this.setState(prevState => ({
      currentForcedVariations: prevState.currentForcedVariations.update(
        index,
        forcedVariation => forcedVariation.set('variation_id', value),
      ),
    }));
  };

  onSave = () => {
    if (this.validate()) {
      this.setState({ isSaving: true });
      const { currentForcedVariations } = this.state;
      const { experiment, onSave: onSaveProp } = this.props;
      const experimentToSave = {
        forced_variations: currentForcedVariations.toJS(),
        id: experiment.get('id'),
      };
      onSaveProp(experimentToSave)
        .then(() => {
          ui.hideDialog();
        })
        .always(() => {
          this.setState({ isSaving: false });
        });
    }
  };

  removeForcedVariationRow = index => {
    this.setState(prevState => ({
      currentForcedVariations: prevState.currentForcedVariations.delete(index),
    }));
  };

  validate = () => {
    const { currentForcedVariations } = this.state;
    let errors = toImmutable([]);
    const invalidUserId = currentForcedVariations.find(
      forcedVariation => !forcedVariation.get('user_id'),
    );

    if (invalidUserId) {
      errors = errors.push('Missing user ID for one or more entries.');
    }
    this.setState({
      errors,
    });

    return errors.size === 0;
  };

  render() {
    const { experiment } = this.props;
    const {
      savedForcedVariations,
      currentForcedVariations,
      errors,
      isSaving,
    } = this.state;
    const variations = experiment.get('variations');
    return (
      <LoadingOverlay
        className="overflow-y--auto flex flex--1"
        isLoading={isSaving}>
        <Wrapper testSection="oasis-whitelist-dialog">
          <Title>Whitelist for {experiment.get('key')}</Title>
          <p>Force users into running variations using the whitelist below.</p>
          <Fieldset>
            <ForcedVariationsTable
              savedForcedVariations={savedForcedVariations}
              addForcedVariationRow={this.addForcedVariationRow}
              currentForcedVariations={currentForcedVariations}
              handleUserIdChange={this.handleUserIdChange}
              handleVariationIdChange={this.handleVariationIdChange}
              removeForcedVariationRow={this.removeForcedVariationRow}
              variations={variations}
            />
            {errors.map(error => (
              <Attention
                alignment="left"
                testSection="oasis-whitelisting-dialog-error"
                type="bad-news">
                {error}
              </Attention>
            ))}
          </Fieldset>
          <Footer>
            <ButtonRow
              rightGroup={[
                <Button
                  key="btn-cancel"
                  style="plain"
                  testSection="oasis-config-whitelist-cancel"
                  onClick={ui.hideDialog}>
                  Cancel
                </Button>,
                <Button
                  key="btn-save"
                  style="highlight"
                  testSection="oasis-config-whitelist-save"
                  onClick={this.onSave}>
                  Save
                </Button>,
              ]}
            />
          </Footer>
        </Wrapper>
      </LoadingOverlay>
    );
  }
}

/*
 * Deferred based on native esnext Promise.
 * Where possible, API parity with jquery Deferreds is provided.
 */

class Deferred {
  constructor() {
    let resolver;
    let rejecter;

    const p = makeQueryablePromise(
      new Promise((resolve, reject) => {
        resolver = resolve;
        rejecter = reject;
      }),
    );
    return Object.assign(p, {
      resolve(value) {
        resolver(value);
        return p;
      },
      reject(value) {
        rejecter(value);
        return p;
      },
      promise() {
        return p;
      },
    });
  }

  // using the same statuses used in jquery Deferreds to simplify code migrations
  static status = {
    PENDING: 'pending',
    REJECTED: 'rejected',
    RESOLVED: 'resolved',
  };
}

/**
 * This function allow you to modify a JS Promise by adding some status properties.
 * Based on accepted answer to: http://stackoverflow.com/questions/21485545
 */
function makeQueryablePromise(promise) {
  // Don't modify any promise that has been already modified.
  if (promise.state) {
    return promise;
  }

  // Set initial state
  let state = Deferred.status.PENDING;

  // Observe the promise, saving the fulfillment in a closure scope.
  const observedPromise = promise.then(
    resolvedValue => {
      state = Deferred.status.RESOLVED;
      return resolvedValue;
    },
    rejectReason => {
      state = Deferred.status.REJECTED;
      throw rejectReason;
    },
  );

  // provide access to promise state via `.state()` function (for jquery Deferred API parity)
  observedPromise.state = () => state;
  return observedPromise;
}

export default Deferred;

import _ from 'lodash';
import Vue from 'vue';

import error from 'optly/services/error';

import tmpl from './notification_manager.html';
/**
 * Notification Manager component
 *
 * Responsible for listening for notification events
 *
 * Usage:
 * Showing a notification message over the page
 * ==================================================================================================
 *
 * For non persistent notifications:
 * ```js
 * app.$broadcast('notify', {
 *  message: tr("The project <b>{0}</b> has been archived.", project.project_name),
 *  type: 'success'
 * });
 * ```
 * The type field is optional, and will default to 'default'.
 *
 *
 * For persistent notifications:
 * ```js
 * ui.showPersistentNotification({
 *  message: tr('Please _unarchive_ your project to make changes'),
 *  type: 'warning'
 *  id: 'archived-project-warning-notification',  // required
 *  safeHTML: true  // in case there are href tags in your message and you dont want to sanitize the message explicitly
 * });
 * ```
 * The type field is optional, and will default to 'default'.
 * The safeHTML field is optional, but will default to false (i.e. href links might be swallowed due to sanitizeHTML vue filter)
 * Be sure that your `html` in message is cross-script-scripting proof to be 'safe when specifying safeHTML:true
 *
 * @author Jon Noronha (jon.noronha@optimizely.com)
 */
const LIFETIME = 6000;
const FADE_TIME = 300;

export default {
  replace: true,

  template: tmpl,

  data: {
    notificationTypes: {
      default: 'lego-attention--brand',
      success: 'lego-attention--good-news',
      warning: 'lego-attention--warning',
      error: 'lego-attention--bad-news',
    },
    notifications: [],
    persistentNotifications: [],
  },

  methods: {
    /*
     * Shows a notification on the page, then hides it after timeout.
     * Pass in an object with .message and .type (the class to be used).
     */
    showNotification(config) {
      if (
        config.doNotShowDuplicates &&
        _.some(this.notifications, { identifier: config.identifier })
      ) {
        return;
      }

      if (!config.type) {
        config.type = 'default';
      }
      config.timestamp = Date.now();
      config.notificationClass = this.notificationTypes[config.type];
      this.notifications.push(config);

      // Log to server
      if (config.logToServer) {
        error.logToServer(
          'JSError',
          _.extend({}, config.errorData.errorDetails, {
            errorId: config.errorData.errorId,
            errorUrl: config.errorData.errorUrl,
            errorMessage: config.errorData.errorMessage,
            timestamp: config.timestamp,
            type: config.errorData.type,
            response: config.errorData.response,
            data: config.errorData.data,
          }),
        );
      }
      // Fade in notification
      Vue.nextTick(() => {
        config.shown = true;
      });

      // Fade out notification
      window.setTimeout(() => {
        config.shown = false;
      }, LIFETIME);

      // Remove it when done fading
      window.setTimeout(() => {
        this.notifications = _.dropWhile(this.notifications, {
          timestamp: config.timestamp,
        });
      }, LIFETIME + FADE_TIME);
    },

    showPersistentNotification(config) {
      if (!config) {
        return;
      }
      if (!config.id) {
        throw new Error(
          'Must supply config.id to show and hide persistent notifications',
        );
      }
      if (!config.type) {
        config.type = 'default';
      }
      if (_.isUndefined(config.showCloseButton)) {
        config.showCloseButton = true;
      }
      // clear out any existing persistent notifications with same id
      this.clearPersistentNotification(config.id);

      config.notificationClass = this.notificationTypes[config.type];
      this.persistentNotifications.push(config);
    },

    clearPersistentNotification(id) {
      if (!id) {
        throw new Error('Must supply id to hide persistent notifications');
      }
      this.persistentNotifications = _.filter(
        this.persistentNotifications,
        notification => notification.id !== id,
      );
    },
  },
};

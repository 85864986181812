import { values as _values } from 'lodash';
import PropTypes from 'prop-types';

export const BASE_URL = 'https://p13n-results-api.optimizely.com';

export const BASE_LOCAL_URL = 'http://localhost:8080';

export const EDP_URLS = {
  UAT: 'https://inte.p13n-results-api.optimizely.com',
  PROD: 'https://p13n-results-api.optimizely.com',
};

export const API_URLS = {
  LOCAL: {
    BASE: BASE_LOCAL_URL,
    SEGMENT: `${BASE_LOCAL_URL}/segments`,
  },
  PROD: {
    BASE: BASE_URL,
    SEGMENT: `${BASE_URL}/segments`,
  },
};

export const ACCEPT_HEADER = 'application/vnd.optimizely.v3+json';

export const API_SOURCE_QUERY_PARAM = 'api_source';

export const CONFIDENCE_INTERVAL_SHAPE = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
);

export const CONFIDENCE_LEVEL_DIVISOR = 10000;

export const DIFFERENCE_TYPES = {
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative',
};

export const DIFFERENCE_TYPE_PROP = PropTypes.oneOf(_values(DIFFERENCE_TYPES));

export const LIFT_SHAPE = PropTypes.shape({
  confidenceInterval: CONFIDENCE_INTERVAL_SHAPE.isRequired,
  confidenceIntervalScaled: CONFIDENCE_INTERVAL_SHAPE,
  isSignificant: PropTypes.bool.isRequired,
  significance: PropTypes.number.isRequired,
  status: PropTypes.string,
  value: PropTypes.number.isRequired,
  valueScaled: PropTypes.number,
  visitorsRemaining: PropTypes.number.isRequired,
});

export const MAX_CONFIDENCE_LEVEL = 0.9999999999;

export const SEGMENT_CATEGORY_API_ROUTE =
  '/api/projects/%s/segments.json?default_segments=true';

export const TRACE_ID_VERSION = 1;

export const RESTRICTION_NUM_YEARS = 2;

export default {
  BASE_URL,
  BASE_LOCAL_URL,
  API_URLS,
  ACCEPT_HEADER,
  API_SOURCE_QUERY_PARAM,
  CONFIDENCE_INTERVAL_SHAPE,
  CONFIDENCE_LEVEL_DIVISOR,
  DIFFERENCE_TYPES,
  DIFFERENCE_TYPE_PROP,
  EDP_URLS,
  LIFT_SHAPE,
  MAX_CONFIDENCE_LEVEL,
  RESTRICTION_NUM_YEARS,
  SEGMENT_CATEGORY_API_ROUTE,
  TRACE_ID_VERSION,
};

import keyMirror from 'optly/utils/key_mirror';

export const TwoFactorAuthenticationStatus = keyMirror({
  ENABLED: null,
  DISABLED: null,
});

export const AuthenticationEvents = keyMirror({
  VERIFY_PASSWORD: null,
  PASSWORD_VERIFIED: null,
});

/**
 * States in the authentication state machine. See Auth.actions.advanceAuthFlow.
 */
export const States = keyMirror({
  // Client just successfully gave their email/password (e.g., POST /signin -> 200 OK)
  EMAIL_PASSWORD_VERIFIED: null,

  // A session was just granted to the client (e.g., via Set-Cookie in /signin response)
  HAS_SESSION: null,

  // Client is ready to be ajax'd into the app
  READY: null,
});

export const LoginOptions = {
  LEGACY_PASSWORD: 'legacy_password',
  LEGACY_SSO: 'legacy_sso',
  OPTI_ID: 'optiid',
};

export default {
  TwoFactorAuthenticationStatus,
  AuthenticationEvents,
  States,
  LoginOptions,
};

/**
 * A directive that will hide an element if a certain loadingId is true
 *
 * Usage:
 *
 * <div v-loading-hide="dashboard.audiences.reportableCount">
 *
 * Where `dashboard.audiences.reportableCount` corresponds to key managed by the loading store
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
import $ from 'jquery';

import flux from 'core/flux';

import { getters as LoadingGetters } from 'core/modules/loading';

const exported = {
  isLiteral: true,

  bind() {
    const isLoadingGetter = LoadingGetters.isLoading(this.key);
    this.toggleLoading(flux.evaluate(isLoadingGetter));
    this.__unwatch = flux.observe(
      isLoadingGetter,
      this.toggleLoading.bind(this),
    );
  },

  toggleLoading(isLoading) {
    $(this.el).toggle(!isLoading);
  },

  unbind() {
    this.__unwatch();
  },
};

export default exported;

export const { isLiteral, bind, toggleLoading, unbind } = exported;

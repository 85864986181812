const PropTypes = require('prop-types');
const React = require('react');

const { Checkbox } = require('optimizely-oui');

class DependencyManagement extends React.Component {
  static propTypes = {
    /** Whether or not the currently editing change depends on the previous change */
    dependsOnPrevious: PropTypes.bool.isRequired,
    /** Function that handles dependency management */
    handleChangeDependencyClick: PropTypes.func.isRequired,
  };

  handleChangeDependencyClick = event => {
    this.props.handleChangeDependencyClick(event.target.checked);
  };

  render() {
    return (
      <div>
        <ol className="lego-form-fields push--bottom">
          <li className="flush--bottom lego-form-field__item">
            <Checkbox
              checked={this.props.dependsOnPrevious}
              label="Wait for previous change"
              onChange={this.handleChangeDependencyClick}
              data-test-section="dependency-management"
            />
          </li>
          <li className="lego-form-field__item lego-form-note">
            This change will wait until the previous change is applied before
            applying.
          </li>
        </ol>
      </div>
    );
  }
}

module.exports = DependencyManagement;

import _ from 'lodash';

import enums from './enums';

/**
 * Services layer pure functions for the events
 */

/**
 * Creates an empty event entity object with the supplied data
 */
export function createDcpDatasourceEntity(data) {
  const DEFAULTS = {
    archived: false,
    attributes: [],
    id: null,
    name: null,
    description: null,
    aws_access_key: null,
    aws_secret_key: null,
    s3_path: null,
  };

  return {
    ...DEFAULTS,
    ...data,
  };
}

export function isKeyFieldVisible(datasource) {
  return (
    datasource &&
    !!datasource.keyfield_locator_type &&
    datasource.keyfield_locator_type !== enums.keyFieldLocations.UID
  );
}

/**
 * Add s3 prefix to s3 path.
 * @param {string} s3path
 */
export function addS3prefix(s3path) {
  return `/optimizely-import/${s3path}`;
}

export function headerCsv(datasource) {
  const attributeNames = _.map(
    datasource.attributes || [],
    attribute => `"${(attribute.name || '').replace(/"/g, '""')}"`,
  );

  // Every data source needs a customerId field, even if it's not defined
  return ['"customerId"'].concat(attributeNames).join(',');
}

export default {
  addS3prefix,
  createDcpDatasourceEntity,
  headerCsv,
  isKeyFieldVisible,
};

import { toImmutable } from 'optly/immutable';

/**
 * Module specific pure functions
 */
import TargetingConditionEnums from 'optly/modules/entity/targeting_condition/enums';

/**
 * Extracts the base key for condition types where key is something like 'demandbase.watch_list.123'
 * to 'demandbase.watch_list'
 * @param {String} conditionName
 * @return {String}
 */
export function getThirdPartyIntegrationName(conditionName) {
  const vals = conditionName.split('.');
  return `${vals[0]}.${vals[1]}`;
}

/**
 * Get the key from the conditionName which is of the format providerId.field.key
 *
 * @param {String} value of a condition using the 'keyvalue' input type. eg: demandbase.watch_list.company
 * @returns {String}
 */
export function getKeyFromConditionName(conditionName) {
  const vals = conditionName.split('.');
  return vals[2] ? vals[2] : '';
}

export function enabledVersions(
  isCustomProject,
  isClassicTestingEnabledInClient,
  isPersonalizationEnabledInClient,
) {
  let versions = [];

  if (isCustomProject) {
    /* Custom projects do not respect the client build setting and is Optimizely X only */
    versions = [TargetingConditionEnums.audienceVersions.OPTIMIZELY_X];
  } else {
    if (isClassicTestingEnabledInClient) {
      versions.push(
        TargetingConditionEnums.audienceVersions.OPTIMIZELY_CLASSIC,
      );
    }

    if (isPersonalizationEnabledInClient) {
      versions.push(TargetingConditionEnums.audienceVersions.OPTIMIZELY_X);
    }
  }

  return versions;
}

/**
 * This function returns condition config objects for each audience integration.
 * Each object will be rendered as a section in the sidebar of the audience condition builder,
 * comprised of:
 * 1) A heading showing the Master label (e.g. "Krux")
 * 2) Rows showing the conditions that were defined for that integration
 * @param {Immutable.List} integrations
 * @param {Immutable.List}  thirdPartyAudienceConditions
 * @returns {Immutable.List}
 */
export function buildOneClickIntegrationConditionsConfigObject(
  integrations,
  thirdPartyAudienceConditions,
) {
  return integrations.map(integration => {
    const integrationConditions = thirdPartyAudienceConditions.find(
      entry => entry.get('integration_id') === integration.get('id'),
    );

    // if the DynamicCondition hasn't been fetched default to empty array
    const conditions = integrationConditions
      ? integrationConditions.get('conditions')
      : toImmutable([]);

    // append useful information about the parent, eg masterLabel and integrationId so that
    // we can pass a single conditionConfig object to the child components
    const conditionsWithMetadata = conditions.map(cond =>
      cond
        .set(
          'integration_master_label',
          integrationConditions.get('master_label'),
        )
        .set('integration_id', integrationConditions.get('integration_id')),
    );
    return toImmutable({
      id: integration.get('id'),
      masterLabel: integration.get('masterLabel'),
      conditions: conditionsWithMetadata,
    });
  });
}

/**
 * This is adapted from the buildOneClickIntegrationConditionsConfigObject function above and serves
 * the same purpose, except that it's built from custom audience integrations.
 * 'master_label': Conditions returned from the /attribute_definitions endpoint
 *                 come with a "master_label" field, so we will pull from there.
 * "api_name": Must be unique among all CAIs in the project. It becomes the namespace within the
 *             client-side optimizely.visitor object ‘enhanced’ by custom 'provide_visitor_attributes' code.
 *             More info in the design doc here: http://link.optimizely.com/customaudienceintegrations
 * @param {Immutable.List} enabledCustomAudienceIntegrations
 * @param {Immutable.List}  customAudienceAttributeDefinitions
 * @returns {Immutable.List}
 */
export function buildCustomAudienceIntegrationConditionsConfigObject(
  enabledCustomAudienceIntegrations,
  customAudienceAttributeDefinitions,
) {
  return enabledCustomAudienceIntegrations.map(integration => {
    const integrationConditions = customAudienceAttributeDefinitions.find(
      entry => entry.get('integration_id') === integration.get('api_name'),
    );

    // if the AttributeDefinition hasn't been fetched default to empty array
    const conditions = integrationConditions
      ? integrationConditions.get('conditions')
      : toImmutable([]);

    // append useful information about the parent, eg masterLabel and integrationId so that
    // we can pass a single conditionConfig object to the child components
    const conditionsWithMetadata = conditions.map(cond =>
      cond
        .set(
          'integration_master_label',
          integrationConditions && integrationConditions.get('master_label'),
        )
        .set(
          'integration_id',
          integrationConditions && integrationConditions.get('integration_id'),
        ),
    );
    return toImmutable({
      id: integration.get('id'),
      masterLabel:
        integrationConditions && integrationConditions.get('master_label'),
      conditions: conditionsWithMetadata,
    });
  });
}

export default {
  enabledVersions,
  buildOneClickIntegrationConditionsConfigObject,
  buildCustomAudienceIntegrationConditionsConfigObject,
  getKeyFromConditionName,
  getThirdPartyIntegrationName,
};

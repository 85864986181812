import CurrentProjectGetters from 'optly/modules/current_project/getters';
import CurrentLayerFns from 'bundles/p13n/modules/current_layer/fns';
import PermissionsModuleGetters from 'optly/modules/permissions/getters';

export const selectedViewIds = ['layersDialog', 'selectedViewIds'];

export const urlTargetingConfig = ['layersDialog', 'urlTargetingConfig'];

export const targetingType = [
  ['layersDialog', 'targetingType'],
  PermissionsModuleGetters.canUseUrlTargeting,
  function(layersDialogTargetingType, canUseUrlTargeting) {
    if (!layersDialogTargetingType) {
      return CurrentLayerFns.getDefaultTargetingType(canUseUrlTargeting);
    }
    return layersDialogTargetingType;
  },
];

export const errors = ['layersDialog', 'errors'];

export const allSavedViewsWithoutSingleUse = [
  CurrentProjectGetters.views,
  views => views.filter(view => !view.get('single_use')),
];

export const urlTargetingConditionsError = [
  ['layersDialog', 'errors'],
  layersDialogErrors => layersDialogErrors.get('urlTargetingConditions'),
];

export const urlTargetingApiNameError = [
  ['layersDialog', 'errors'],
  layersDialogErrors => layersDialogErrors.get('urlTargetingApiName'),
];

export const urlTargetingUrlError = [
  ['layersDialog', 'errors'],
  layersDialogErrors => layersDialogErrors.get('urlTargetingUrl'),
];

export const viewsError = [
  ['layersDialog', 'errors'],
  layersDialogErrors => layersDialogErrors.get('views'),
];

export default {
  selectedViewIds,
  urlTargetingConfig,
  targetingType,
  errors,
  urlTargetingConditionsError,
  urlTargetingApiNameError,
  urlTargetingUrlError,
  viewsError,
  allSavedViewsWithoutSingleUse,
};

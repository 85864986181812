import $ from 'jquery';

import LiveCommitTagActions from 'optly/modules/entity/live_commit_tag/actions';
import fns from 'optly/modules/entity/commit/fns';
import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

function getCommitsAndDiff(commitBefore, commitAfter) {
  const resultDeferred = $.Deferred();

  $.when(
    baseEntityActions.fetch(commitBefore),
    baseEntityActions.fetch(commitAfter),
  ).done((before, after) => {
    // The following checks are needed because before and after are arrays when they are first fetched from the server
    // but they are objects when they are retrieved from the cache.
    // This is because of a discrepancy between jquery deferreds (from the server) and the deferreds returned by our cache
    if (Array.isArray(before)) {
      before = before[0];
    }
    if (Array.isArray(after)) {
      after = after[0];
    }

    const commitDiff = fns.diff(before, after);
    resultDeferred.resolve(commitDiff);
  });
  return resultDeferred;
}

/**
 * Publish a layer commit by pointing the live tag to the commit_id
 *
 * @param {Object} layerId
 * @param {Number} commitId the commit id that the commit_tag should point to
 * @return {Deferred}
 */
function publishCommit(layerId, commitId) {
  return LiveCommitTagActions.save({
    layer_id: layerId,
    commit_id: commitId,
  });
}

export default {
  ...baseEntityActions,
  getCommitsAndDiff,
  publishCommit,
};

/* eslint-disable optimizely/restrict-entire-module-imports */
const PropTypes = require('prop-types');
const React = require('react');

const { Button, Icon } = require('optimizely-oui');

const Editor = require('bundles/p13n/modules/editor');

const revertButton = props => (
  <div className="anchor--right push-half--bottom">
    <Button
      style="unstyled"
      onClick={function() {
        props.action(props.property);
        Editor.actions.applyCurrentlyEditingChange();
      }}
      testSection={`revert-${props.property}-button`}>
      <Icon name="arrow-rotate-left" size="small" className="lego-icon" />
    </Button>
  </div>
);

revertButton.propTypes = {
  /**
   * Function that should be called (with property as its first argument)
   * when the revert button is clicked.
   */
  action: PropTypes.func,
  property: PropTypes.string.isRequired,
};

revertButton.defaultProps = {
  action: Editor.actions.revertCSSProperty,
};

module.exports = revertButton;

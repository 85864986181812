import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayWrapper, Popover } from 'optimizely-oui';

import flux from 'core/flux';
import { useGetters } from 'core/ui/methods/connect_getters';
import Immutable from 'optly/immutable';
import PermissionsGetters from 'optly/modules/permissions/getters';

import tr from 'optly/translate';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';

import EnvironmentStatus, {
  iconTypes,
} from 'bundles/p13n/components/environments/environment_status';

const ENVIRONMENT_EXPERIMENT_STATUS = LayerExperimentEnums.EnvironmentStatus;

function getIconTypeFromStatus(status) {
  if (status === ENVIRONMENT_EXPERIMENT_STATUS.RUNNING) {
    return iconTypes.GREEN_CHECK;
  }
  return iconTypes.GREY_EMPTY;
}

/*
 * This component displays the status of an entity (Experiment or Feature) for a set of
 * environments. It displays an list of statuses, with icons for the status next to
 * the environment names. If there are more than 2 environments, it shows "Other environments"
 * with a summary icon. On hover, it shows all environments statuses
 */

function EnvironmentsStatusOverview({
  isArchived,
  environmentsInfo,
  getStatusDescription,
}) {
  const { canCurrentProjectUseTargetedRollouts } = useGetters({
    canCurrentProjectUseTargetedRollouts:
      PermissionsGetters.canCurrentProjectUseTargetedRollouts,
  });

  if (isArchived) {
    return <div className="muted">Archived</div>;
  }

  const firstEnvironment = environmentsInfo.get(0);
  if (!firstEnvironment) {
    return null;
  }

  function renderEnvironments() {
    if (environmentsInfo.size <= 2) {
      return (
        <div data-test-section="two-minus-environments-status-overview">
          {environmentsInfo
            .map(environment => (
              <EnvironmentStatus
                key={String(environment.get('id'))}
                name={environment.get('name')}
                iconType={getIconTypeFromStatus(environment.get('status'))}
              />
            ))
            .reverse()}
        </div>
      );
    }

    const otherEnvironmentStatuses = environmentsInfo
      .slice(1)
      .map(environment => environment.get('status'))
      .toSet();
    const allOthersRunning =
      otherEnvironmentStatuses.size === 1 &&
      otherEnvironmentStatuses.has(ENVIRONMENT_EXPERIMENT_STATUS.RUNNING);
    const someOthersRunning =
      otherEnvironmentStatuses.size > 1 &&
      otherEnvironmentStatuses.has(ENVIRONMENT_EXPERIMENT_STATUS.RUNNING);
    let summaryStatusIcon;
    if (allOthersRunning) {
      summaryStatusIcon = iconTypes.GREEN_CHECK;
    } else if (someOthersRunning) {
      summaryStatusIcon = iconTypes.HALF_GREEN;
    } else {
      summaryStatusIcon = iconTypes.GREY_EMPTY;
    }

    return (
      <div data-test-section="three-plus-environments-status-overview">
        <EnvironmentStatus
          key="secondEnvironment"
          name={tr('Other Environments ({0})', environmentsInfo.size - 1)}
          iconType={summaryStatusIcon}
        />
        <EnvironmentStatus
          key="firstEnvironment"
          name={firstEnvironment.get('name')}
          iconType={getIconTypeFromStatus(firstEnvironment.get('status'))}
        />
      </div>
    );
  }

  return (
    <div>
      <OverlayWrapper
        behavior="hover"
        horizontalAttachment="left"
        verticalAttachment="middle"
        overlay={
          !canCurrentProjectUseTargetedRollouts && (
            <Popover
              testSection="environment-status-list"
              title={tr('Environments')}>
              <ul className="min-width--150">
                {environmentsInfo
                  .map(environment => (
                    <li key={String(environment.get('id'))}>
                      <div className="flex">
                        <div className="flex--1 push--right">
                          <EnvironmentStatus
                            key={String(environment.get('id'))}
                            name={environment.get('name')}
                            iconType={getIconTypeFromStatus(
                              environment.get('status'),
                            )}
                          />
                        </div>
                        <div
                          className="push--left"
                          data-test-section="environment-status-description">
                          {getStatusDescription(environment)}
                        </div>
                      </div>
                    </li>
                  ))
                  .reverse()}
              </ul>
            </Popover>
          )
        }>
        {renderEnvironments()}
      </OverlayWrapper>
    </div>
  );
}

EnvironmentsStatusOverview.propTypes = {
  isArchived: PropTypes.bool,
  /*
   *  List of Maps representing the status of this experiment/feature in each
   *  environment, sorted by environment priority.
   */
  environmentsInfo: PropTypes.instanceOf(Immutable.List),
  /*
   * Function to display the description for the environment status in the popover
   */
  getStatusDescription: PropTypes.func,
};

EnvironmentsStatusOverview.defaultProps = {
  isArchived: false,
  environmentsInfo: Immutable.List(),
  getStatusDescription: () => {},
};

export default EnvironmentsStatusOverview;

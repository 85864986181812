/**
 * Getters for the Concurrency module.
 */
import Immutable from 'optly/immutable';

import fns from './fns';

export const connectionId = ['p13n/concurrency_legacy', 'connectionId'];

export const entities = ['p13n/concurrency_legacy', 'entities'];

export const refsByKeypath = ['p13n/concurrency_legacy', 'refsByKeypath'];

/**
 * @return {Getter}
 */
export function concurrencyStateByEntityId(entityType, entityId) {
  return [entities, e => e.getIn([entityType, entityId], Immutable.Map())];
}

/**
 * For a given entity_id, return a list of user_ids (email addresses)
 * who are currently subscribed to the entity.
 * @param {string} entityType
 * @param {string} entityId
 * @returns {Immutable.List}
 */
export function usersListByEntityId(entityType, entityId) {
  return [
    concurrencyStateByEntityId(entityType, entityId),
    fns.userListFromEntity,
  ];
}

export function firebaseRefByKeypath(keyPath) {
  return [refsByKeypath, refs => refs.get(keyPath)];
}

export default {
  concurrencyStateByEntityId,
  connectionId,
  entities,
  refsByKeypath,
  firebaseRefByKeypath,
  usersListByEntityId,
};

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TabNav } from '@optimizely/axiom';

import pushStateHandler from 'optly/utils/push_state';

function filterByIsVisible(item) {
  if (item.isVisible === undefined) {
    return true;
  }
  return !!item.isVisible;
}

const DashboardHeader = props => {
  const { activeTab, tabs, dashboardSize, title, testSection } = props;
  const visibleTabs = tabs.filter(tab => filterByIsVisible(tab));
  const [activeDashBoardTab, setActiveDashBoardTab] = useState(activeTab);

  const titlePadding = classNames({
    'soft-double--top': true,
    'soft-double--sides': dashboardSize === 'small',

    // default value if no dashboardSize is defined
    'soft-quad--sides': !dashboardSize,
  });

  const tabParentClasses = classNames({
    'lego-tabs': true,
    'dashboard-tabs': true,
    'dashboard-tabs--half': dashboardSize === 'small',
  });

  const handleTabClick = (event, tab) => {
    setActiveDashBoardTab(tab.id);
    pushStateHandler(tab.url, event);
  };

  return (
    <div
      data-ui-component={true}
      className={titlePadding}
      data-test-section={testSection}>
      <h1 data-test-section="dashboard-header-title">{title}</h1>

      {!!visibleTabs.length && (
        <div className={tabParentClasses}>
          <TabNav activeTab={activeDashBoardTab}>
            {visibleTabs.map(tab => {
              return (
                <TabNav.Tab
                  testSection={tab.testSection}
                  onClick={event => handleTabClick(event, tab)}
                  tabId={tab.id}
                  key={tab.id}>
                  {tab.title}
                </TabNav.Tab>
              );
            })}
          </TabNav>
        </div>
      )}
    </div>
  );
};

DashboardHeader.propTypes = {
  activeTab: PropTypes.string,
  /* Optional dashboardSize attribute to determine header classes
   * Potential values: small
   */
  dashboardSize: PropTypes.string,

  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isVisible: PropTypes.bool,
      testSection: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  testSection: PropTypes.string,
  title: PropTypes.string.isRequired,
};

DashboardHeader.defaultProps = {
  activeTab: '',
  dashboardSize: '',
  tabs: [],
  testSection: '',
};

export default DashboardHeader;

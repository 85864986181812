import { Store } from 'nuclear-js';

import actionTypes from '../action_types';

/**
 * Holds the state of the current project
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
export default Store({
  getInitialState() {
    return null;
  },

  initialize() {
    this.on(actionTypes.CHANGE_CURRENT_PROJECT_ID, setId);
  },
});

/**
 * Handler for actionTypes.CHANGE_CURRENT_PROJECT_ID
 *
 * @param {Number} state
 * @param {Object} payload
 * return {number} payload.id
 */
function setId(state, payload) {
  return payload.id;
}

export const entity = 'tag_groups';

export const parent = {
  entity: 'projects',
  key: 'project_id',
};

export default {
  entity,
  parent,
};

import _ from 'lodash';

import flux from 'core/flux';
import {
  actions as EditorActions,
  getters as EditorGetters,
} from 'bundles/p13n/modules/editor';
import SidebarHeaderMixin from 'bundles/p13n/modules/ui/mixins/sidebar_header';

import Template from './template.html';

const SidebarHeaderCopyVariations = {
  replace: true,

  template: Template,

  computed: {
    backButtonText() {
      const backButtonTextValues = {
        'p13n-change-content-suggestions': 'Element Change',
      };
      return backButtonTextValues[this.$parent.$options.componentId]
        ? backButtonTextValues[this.$parent.$options.componentId]
        : tr('Changes');
    },

    sidebarTitle() {
      const sidebarTextValues = {
        'p13n-change-content-suggestions': 'Copy Generator',
      };

      return sidebarTextValues[this.$parent.$options.componentId]
        ? sidebarTextValues[this.$parent.$options.componentId]
        : '';
    },
  },

  methods: {
    handleBackButtonClick() {
      switch (this.$parent.$options.componentId) {
        case 'p13n-change-content-suggestions':
          this.showCorrectSidebar(this.changeListSidebarComponentConfig);
          break;
        default:
          this.showChangeListSidebar(this.changeListSidebarComponentConfig);
      }
    },
  },

  created() {
    flux.bindVueValues(this, {
      changeListSidebarComponentConfig:
        EditorGetters.changeListSidebarComponentConfig,
    });
  },
};

export default _.merge({}, SidebarHeaderMixin, SidebarHeaderCopyVariations);

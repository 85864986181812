/**
 * A directive that will add classes based on scrolling position
 * Can optionally pass data to the directive simply so the directive knows to
 * recalculate the scrolling based on the data change
 *
 * Usage:
 *
 * <div v-scroll-classes
 *      data-scroll-direction="horizontal"
 *      data-class-left="object--scroll-left"
 *      data-class-right="object--scroll-right"
 *      data-class-both="object--scroll-both">
 * </div>
 *
 * @author Kelly Walker (kelly@optimizely.com)
 */
import $ from 'jquery';

import _ from 'lodash';
import Vue from 'vue';

const SCROLL_HORIZONTAL = 'horizontal';
const SCROLL_VERTICAL = 'vertical';

const DEFAULT_OPTIONS = {
  // Possible values are 'horizontal' or 'vertical'
  scrollDirection: SCROLL_HORIZONTAL,
  classLeft: null,
  classRight: null,
  classTop: null,
  classBottom: null,
  classBoth: null,
};

const exported = {
  _setUpHorizontalScroll() {
    this.config = _.defaults(
      {
        scrollDirection: SCROLL_HORIZONTAL,
        classLeft: this.el.getAttribute('data-class-left') || undefined,
        classRight: this.el.getAttribute('data-class-right') || undefined,
        classBoth: this.el.getAttribute('data-class-both') || undefined,
      },
      DEFAULT_OPTIONS,
    );
    this._applyHorizontalClasses();
    $(this.el).on(
      'scroll.scrollClasses',
      this._applyHorizontalClasses.bind(this),
    );
  },

  _setUpVerticalScroll() {
    this.config = _.defaults(
      {
        scrollDirection: SCROLL_VERTICAL,
        classTop: this.el.getAttribute('data-class-top') || undefined,
        classBottom: this.el.getAttribute('data-class-bottom') || undefined,
        classBoth: this.el.getAttribute('data-class-both') || undefined,
      },
      DEFAULT_OPTIONS,
    );
    this._applyVerticalClasses();
    $(this.el).on(
      'scroll.scrollClasses',
      this._applyVerticalClasses.bind(this),
    );
  },

  _setUpScrolls() {
    const scrollDirection = this.el.getAttribute('data-scroll-direction');
    if (scrollDirection === SCROLL_HORIZONTAL) {
      this._setUpHorizontalScroll();
    } else if (scrollDirection === SCROLL_VERTICAL) {
      this._setUpVerticalScroll();
    }
  },

  _applyHorizontalClasses() {
    const overflowed = this.el.offsetHeight < this.el.scrollWidth;
    if (!overflowed) {
      return;
    }
    const scrolledAwayFromLeft = this.el.scrollLeft !== 0;
    const atEndOfScroll =
      this.el.offsetWidth + this.el.scrollLeft >= this.el.scrollWidth;

    // add left class when scroll away from left side
    if (scrolledAwayFromLeft) {
      $(this.el).addClass(this.config.classLeft);
    }

    // add right class when scroll away from right side
    if (!atEndOfScroll) {
      $(this.el).addClass(this.config.classRight);
    }

    // add both class when scroll away from both side
    if (!atEndOfScroll && scrolledAwayFromLeft) {
      $(this.el).addClass(this.config.classBoth);
    }

    // remove left and both class when scrolled to left side
    if (!scrolledAwayFromLeft) {
      $(this.el).removeClass(this.config.classLeft);
      $(this.el).removeClass(this.config.classBoth);
    }

    // remove right and both class when scrolled to right side
    if (atEndOfScroll) {
      $(this.el).removeClass(this.config.classRight);
      $(this.el).removeClass(this.config.classBoth);
    }
  },

  _applyVerticalClasses() {
    const overflowed = this.el.offsetHeight < this.el.scrollHeight;
    if (!overflowed) {
      return;
    }
    const scrolledAwayFromTop = this.el.scrollTop !== 0;
    const atEndOfScroll =
      this.el.offsetHeight + this.el.scrollTop >= this.el.scrollHeight;

    // add top class when scroll away from top side
    if (scrolledAwayFromTop) {
      $(this.el).addClass(this.config.classTop);
    }

    // add bottom class when scroll away from bottom side
    if (!atEndOfScroll) {
      $(this.el).addClass(this.config.classBottom);
    }

    // add both class when scroll away from both side
    if (!atEndOfScroll && scrolledAwayFromTop) {
      $(this.el).addClass(this.config.classBoth);
    }

    // remove top and both class when scrolled to top side
    if (!scrolledAwayFromTop) {
      $(this.el).removeClass(this.config.classTop);
      $(this.el).removeClass(this.config.classBoth);
    }

    // remove bottom and both class when scrolled to bottom side
    if (atEndOfScroll) {
      $(this.el).removeClass(this.config.classBottom);
      $(this.el).removeClass(this.config.classBoth);
    }
  },

  bind() {
    this._setUpScrolls();
  },

  update(value) {
    if (value) {
      Vue.nextTick(() => {
        this._setUpScrolls();
      });
    }
  },

  unbind() {
    $(this.el).off('.scrollClasses');
  },
};

export default exported;

export const {
  _setUpHorizontalScroll,
  _setUpVerticalScroll,
  _setUpScrolls,
  _applyHorizontalClasses,
  _applyVerticalClasses,
  bind,
  update,
  unbind,
} = exported;

/**
 * Entity Definition for Catalog Stats
 *
 * /recommender_services/{recommender_service_id}/last_run/
 */

export const entity = 'recommender_services_stats';

export const isRelationshipEntity = true;

export const parents = [
  {
    entity: 'recommender_services',
    key: 'recommender_service_id',
  },
  {
    entity: 'last_run',
  },
];

export default {
  entity,
  isRelationshipEntity: true,
  parents,
};

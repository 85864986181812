import sprintf from 'sprintf';
import _ from 'lodash';

import cloneDeep from 'optly/clone_deep';
import Immutable, { toImmutable } from 'optly/immutable';
import { getResourceSize } from 'optly/utils/resource_size';
import {
  enums as LayerExperimentEnums,
  fns as LayerExperimentFns,
} from 'optly/modules/entity/layer_experiment';
import { WidgetChange } from 'optly/modules/entity/layer_experiment/types';

import constants from './constants';

const CDN_DOMAIN = 'cdn.optimizely.com';
// eslint-disable-next-line no-useless-escape
const PROXY_URL_REGEX = 'https?://.*?.?optimizelyedit.(com|test)(/.*)';
const S3_DOMAIN = 'optimizely.s3.amazonaws.com';
const THUMBNAIL_MAX_DIMENSION = 60;

const fns = {};

/**
 * Remove changes with null values from a change.
 * This expects a change from the GAE data model.
 * @param {Immutable.Map<Change>} change
 * @return {Immutable.Map<Change>}
 */
fns.formatChangeForApply = function(change) {
  if (change.get('type') !== LayerExperimentEnums.ChangeTypes.ATTRIBUTE) {
    return change;
  }

  const formattedChange = {
    id: change.get('id'),
    status: change.get('status'),
    type: change.get('type'),
    selector: change.get('selector'),
    dependencies: change.get('dependencies').toJS() || [],
    attributes: {},
    css: change.get('css') || {},
  };

  // Not all saved changes will have rearrange.
  if (change.get('rearrange')) {
    formattedChange.rearrange = change.get('rearrange');
  }

  change.get('attributes').map((changeValue, type) => {
    if (changeValue !== null) {
      formattedChange.attributes[type] = changeValue;
    }
  });

  if (change.get('name')) {
    formattedChange.name = change.get('name');
  }

  return toImmutable(formattedChange);
};

/**
 * Formats code into a custom code change for applying and saving.
 * It saves an async status with the custom code change.
 *
 * @param  {string} id
 * @param  {string} code
 * @param  {boolean} isAsync
 * @return {Change} formatted custom code change
 */
fns.formatCustomCodeChange = (id, code, isAsync) => ({
  id,
  type: LayerExperimentEnums.ChangeTypes.CUSTOM_CODE,
  async: isAsync,
  value: code,
  dependencies: [],
});

/**
 * Formats code into a custom code change for applying and saving.
 * It saves an async status with the custom code change.
 *
 * @param  {string} id
 * @param  {string} code
 * @param  {boolean} isAsync
 * @return {Change} formatted custom code change
 */
fns.formatCustomCssChange = (id, code, isAsync) => ({
  id,
  type: LayerExperimentEnums.ChangeTypes.CUSTOM_CSS,
  async: isAsync,
  selector: 'head',
  value: code,
  dependencies: [],
});

/**
 * Formats iframe id based on viewId and variationId
 * @param {string} viewId
 * @param {string} variationId
 * @returns {string}
 */
fns.getIframeId = (viewId, variationId) => `${viewId}:${variationId}`;

/**
 * Deconstructs the iframe id and returns the viewId
 * @param {String} iframeId
 * @return {Number} viewId
 */
fns.getViewIdFromIframeId = function(iframeId) {
  const viewId = iframeId.split(':')[0];
  if (!viewId) {
    throw new Error('Could not parse viewId from iframeId');
  }
  return Number(viewId);
};

/**
 * Deconstructs the iframe id and returns the variationId
 * @param {String} iframeId
 * @return {Number} variationId
 */
fns.getVariationIdFromIframeId = function(iframeId) {
  const ids = iframeId.split(':');
  if (ids.length < 2) {
    throw new Error('Could not parse variationId from iframeId');
  }
  return Number(ids[1]);
};

/**
 * Return updated formatted ChangeList with new/existing formatted change
 * @param  {Immutable.List<Change>} changeList
 * @param  {Immutable.Map} formattedChange
 * @return {Immutable.List<Change>} updated ChangeList
 */
fns.updateChangeListWithFormattedChange = (changeList, formattedChange) => {
  let updatedChanges;
  const changeIndex =
    changeList &&
    changeList.findIndex(
      change => change.get('id') === formattedChange.get('id'),
    );

  if (changeIndex >= 0) {
    updatedChanges = changeList.update(changeIndex, change => {
      let updatedChange;
      // If change type widge, don't do deep merge
      // because that messes with values for multiple selection type of widget
      // Since most of the widget types are only one level, just a single level merge should be sufficient
      if (
        formattedChange.get('type') === LayerExperimentEnums.ChangeTypes.WIDGET
      ) {
        updatedChange = change.merge(formattedChange);
      } else {
        // For all other types of changes, deep merge
        // so that the attributes that can be complex lists get merged properly
        updatedChange = change.mergeDeep(formattedChange);
      }
      // Make sure that any updated dependencies get properly set on change.
      updatedChange = updatedChange.set(
        'dependencies',
        formattedChange.get('dependencies'),
      );
      return updatedChange;
    });
  } else if (
    formattedChange.get('type') ===
      LayerExperimentEnums.ChangeTypes.CUSTOM_CODE ||
    formattedChange.get('type') === LayerExperimentEnums.ChangeTypes.CUSTOM_CSS
  ) {
    // Custom Code/Custom CSS changes should be at the top of the list.
    updatedChanges = changeList.unshift(formattedChange);
  } else {
    updatedChanges = changeList.push(formattedChange);
  }

  // Handle attribute changes by filtering out null values from the currentlyEditingChange store.
  if (
    formattedChange.get('type') === LayerExperimentEnums.ChangeTypes.ATTRIBUTE
  ) {
    updatedChanges = updatedChanges.updateIn(
      [changeIndex, 'attributes'],
      attributes => attributes.filter(value => value !== null),
    );
  }

  // Handle dest/dest_fn exclusivity when saving a redirect change
  if (
    formattedChange.get('type') === LayerExperimentEnums.ChangeTypes.REDIRECT
  ) {
    const removedProperty = formattedChange.get('dest', null)
      ? 'dest_fn'
      : 'dest';
    updatedChanges = updatedChanges.removeIn([changeIndex, removedProperty]);
  }

  // Remove any custom code/css changes which are only whitespace.
  return updatedChanges.filterNot(
    change =>
      _.includes(
        [
          LayerExperimentEnums.ChangeTypes.CUSTOM_CODE,
          LayerExperimentEnums.ChangeTypes.CUSTOM_CSS,
        ],
        change.get('type'),
      ) && _.isEmpty(change.get('value').trim()),
  );
};

/**
 * Sort function to ensure that custom code/custom css changes are at the top of a change list.
 * @param {Immutable.Map} change1
 * @param {Immutable.Map} change2
 * @returns {number}
 */
fns.changeListSortFn = (change1, change2) => {
  const changePriority = change => {
    const changeIndex = _.indexOf(
      LayerExperimentEnums.ChangeTypePriority,
      change.get('type'),
    );
    return changeIndex === -1
      ? LayerExperimentEnums.ChangeTypePriority.length
      : changeIndex;
  };
  return changePriority(change1) - changePriority(change2);
};

/**
 * Sort the change list to ensure custom_code/custom_css/redirect are at the top per the ChangeTypePriority list.
 * @param {Immutable.List} changeList
 * @returns {Immutable.List}
 */
fns.orderChangeList = function(changeList) {
  return changeList.sort(fns.changeListSortFn);
};

/**
 * Simple helper function to strip out the filename from a full URL for display purposes
 *
 * @param {string} url
 * @returns {string}
 */
fns.getImageFileName = url =>
  url ? url.substring(url.lastIndexOf('/') + 1) : '';

/**
 * Calculates the dimensions of an image described by the imageObject as well as the
 * dimensions of the thumbnail used to display the image.
 *
 * @param {object} imageObject - an object that stores information about the image it represents
 */
fns.getImageDimensions = function(imageObject) {
  if (!imageObject.displayValue) {
    resetImageDimensions(imageObject);
    return;
  }
  const image = new Image();
  const src = imageObject.absoluteUrl;

  image.onload = function() {
    // We want to make sure we are dealing with the same image we were when we made the request,
    // else we might be overwriting valid dimensions with out of date ones
    if (src === imageObject.absoluteUrl) {
      imageObject.height = image.height;
      imageObject.width = image.width;

      // Scale down to thumbnail size while keeping proportions
      if (imageObject.height > imageObject.width) {
        imageObject.thumbnailHeight = THUMBNAIL_MAX_DIMENSION;
        imageObject.thumbnailWidth =
          (imageObject.thumbnailHeight / imageObject.height) *
          imageObject.width;
      } else {
        imageObject.thumbnailWidth = THUMBNAIL_MAX_DIMENSION;
        imageObject.thumbnailHeight =
          (imageObject.thumbnailWidth / imageObject.width) * imageObject.height;
      }
    }
  };

  image.onerror = function() {
    // if we hit this it's probably because this is an invalid url
    if (src === imageObject.absoluteUrl) {
      resetImageDimensions(imageObject);
    }
  };

  // set the source to kick-off the image request
  image.src = src;
};

/**
 * Best effort to fetch the size in kb of an image. The request is impacted
 * by the sameorigin policy as well as unsafe headers, so it rarely works.
 *
 * @param {object} imageObject - an object that stores information about the image it represents
 */
fns.getImageSize = async function(imageObject) {
  const src = imageObject.absoluteUrl;
  if (!src) {
    imageObject.size = null;
    return;
  }

  try {
    const size = await getResourceSize(src.replace(CDN_DOMAIN, S3_DOMAIN));
    if (size) {
      // Make sure the current image we're pointing at is the same image we made this request for
      if (imageObject.absoluteUrl === src) {
        imageObject.size = size;
      }
    }
  } catch (e) {
    if (imageObject.absoluteUrl === src) {
      imageObject.size = null;
    }
  }
};

/**
 * Try to grab url from background-image or background styles. We will only consider
 * styles that specify 1 url() to be valid. Specifying inline data() or multiple urls
 * will return null.
 * @returns {string|null}
 */
fns.getUrlFromStyleValue = function(input) {
  // Invalid if doesn't start with "url("
  if (typeof input !== 'string' || !input.startsWith('url(')) {
    return null;
  }

  // Disallow background images with multiple images specified
  if (input.indexOf(', url(') !== -1) {
    return null;
  }

  // Disallow data uris
  if (input.match(/^url\(['"]?data/)) {
    return null;
  }
  // Remove "url("
  input = input.slice(4, -1);

  // Remove quotes (if they exist)
  if (input.match(/^['"]/)) {
    input = input.slice(1, -1);
  }
  return input;
};

/**
 * If there is an image url coming through the proxy, we should convert it to a relative url
 * in the display (as that is how it's actually specified on a customers site)
 *
 * @param {string} url
 * @returns {string}
 */
fns.convertProxyUrlToRelativeUrl = function(url) {
  if (url === null) {
    return null;
  }

  const match = url.match(PROXY_URL_REGEX);
  if (match) {
    return match[2];
  }
  return url;
};

/**
 * Takes a javascript expression and wraps it in an immediately invoked function closing on jquery
 * @param {String} code
 * @return {String}
 */
fns.wrapEvalCodeWithjQuery = code =>
  sprintf(
    '(function() {\nvar $ = window.optimizely.get("jquery");\n%s\n})()',
    code,
  );

/**
 * @param {Object} input
 * @returns {{id: guid, type: string, async: boolean, dependencies: Array, name: *, widget_id: (*|string|string|string|string), config: *}}
 */
fns.createWidgetChange = function(input) {
  const change = {
    id: LayerExperimentFns.generateGuid(),
    type: LayerExperimentEnums.ChangeTypes.WIDGET,
    async: false,
    dependencies: [],
    name: input.name,
    widget_id: input.widget_id,
    config: cloneDeep(input.config),
  };
  if (__INVARIANT__) {
    assertType(WidgetChange, change);
  }
  return change;
};

/**
 * Given a saved value and the known elementInfo, return the value that should be used
 * as the original value for the given attribute type.
 *
 * The "Original" value is always defined as what we have currently saved for this attribute.
 * If nothing is saved, we must then consider what value we have in the elementInfo.
 *
 * @param currentSavedValue
 * @param currentlyEditingChangeElementInfo
 * @param type
 * @returns {string|null}
 */
fns.getOriginalValueForChange = function(
  currentSavedValue,
  currentlyEditingChangeElementInfo,
  type,
) {
  if (type === 'remove' || type === 'hide') {
    // Hide/Remove dont have elementInfo values, so just used the currently saved value, if any.
    return currentSavedValue;
  }
  // Style information is stored in the cssText key of the style attribute.
  const attributeKey = type === 'style' ? ['style', 'cssText'] : [type];
  const attributeElementInfoValue = currentlyEditingChangeElementInfo
    ? currentlyEditingChangeElementInfo.getIn(attributeKey)
    : null;
  // If we have a current value saved in the datastore, that is the original.
  // Otherwise (for a new attribute change), grab the value from elementInfo.
  return currentSavedValue || attributeElementInfoValue;
};

/**
 * For an attribute change, return a map of attribute:status for each attribute as well as rearrange and selector.
 * @param currentlyEditingChange
 * @param currentlyEditingChangeRearrangeIsDirty
 * @param currentlyEditingChangeSelectorIsDirty
 * @param currentDraftChange
 * @param currentLiveChange
 * @param changeId
 * @returns {Immutable.Map}
 */
fns.getAttributeChangeStatuses = function(
  currentlyEditingChange,
  currentlyEditingChangeRearrangeIsDirty,
  currentlyEditingChangeSelectorIsDirty,
  currentDraftChange,
  currentLiveChange,
  changeId,
) {
  // Use the currentlyEditingChange to loop through all possible change attributes and map each to a status.
  const changeStatusMap = toImmutable({});
  const attributeMap = toImmutable(constants.SupportedAttributeChanges).map(
    (value, attribute) => {
      if (currentlyEditingChange.get('id') === changeId) {
        // The currentlyEditingChange indicates dirty attributes with the should save flag, so check that.
        if (currentlyEditingChange.getIn(['shouldSaveFlags', attribute])) {
          return LayerExperimentEnums.ChangeStatuses.DIRTY;
        }
      }

      // We have a live change with this attribute
      // - AND -
      // We either dont have a draft change OR that draft change value is equal to the live one.
      const hasLiveChangeWithAttribute =
        currentLiveChange && currentLiveChange.hasIn(['attributes', attribute]);
      const hasMissingOrEqualDraftChange =
        !currentDraftChange ||
        (currentLiveChange &&
          currentDraftChange.getIn(['attributes', attribute]) ===
            currentLiveChange.getIn(['attributes', attribute]));
      if (hasLiveChangeWithAttribute && hasMissingOrEqualDraftChange) {
        return LayerExperimentEnums.ChangeStatuses.LIVE;
      }

      // We have a draft change
      // - AND -
      // that draft change has this attribute OR there is a live change with this attribute.
      if (
        currentDraftChange &&
        (currentDraftChange.hasIn(['attributes', attribute]) ||
          hasLiveChangeWithAttribute)
      ) {
        return LayerExperimentEnums.ChangeStatuses.DRAFT;
      }

      return null;
    },
  );

  // Handle rearrange, which is in use if the key `rearrange.insertSelector` is defined and has non-zero length.
  let rearrangeStatus = null;
  if (
    currentlyEditingChange.get('id') === changeId &&
    currentlyEditingChangeRearrangeIsDirty
  ) {
    rearrangeStatus = LayerExperimentEnums.ChangeStatuses.DIRTY;
  } else if (
    currentLiveChange &&
    !_.isEmpty(currentLiveChange.getIn(['rearrange', 'insertSelector'])) &&
    (!currentDraftChange ||
      Immutable.is(
        currentDraftChange.get('rearrange'),
        currentLiveChange.get('rearrange'),
      ))
  ) {
    rearrangeStatus = LayerExperimentEnums.ChangeStatuses.LIVE;
  } else if (
    currentDraftChange &&
    !_.isEmpty(currentDraftChange.getIn(['rearrange', 'insertSelector']))
  ) {
    rearrangeStatus = LayerExperimentEnums.ChangeStatuses.DRAFT;
  }

  const cssMap = toImmutable(constants.SupportedCSSProperties).map(
    (value, property) => {
      const values = {};
      values.draft =
        currentDraftChange && currentDraftChange.getIn(['css', property]);
      values.live =
        currentLiveChange && currentLiveChange.getIn(['css', property]);
      values.currentChange = currentlyEditingChange.getIn(['css', property]);

      if (currentlyEditingChange.get('id') === changeId) {
      if (values.currentChange != values.draft && values.currentChange != values.live) {  // eslint-disable-line
          return LayerExperimentEnums.ChangeStatuses.DIRTY;
        }
      }

    if (values.draft && values.live && values.draft != values.live) {  // eslint-disable-line
        return LayerExperimentEnums.ChangeStatuses.DRAFT;
      }

      if (values.live) {
        return LayerExperimentEnums.ChangeStatuses.LIVE;
      }

      if (values.draft) {
        return LayerExperimentEnums.ChangeStatuses.DRAFT;
      }

      return null;
    },
  );

  return changeStatusMap
    .set(
      'async',
      fns.getStatusOfAsyncProperty(
        currentlyEditingChange,
        currentDraftChange,
        currentLiveChange,
        changeId,
      ),
    )
    .set('attributes', attributeMap)
    .set('css', cssMap)
    .set('rearrange', rearrangeStatus)
    .set(
      'selector',
      fns.getStatusOfChangeProperty(
        'selector',
        currentlyEditingChange,
        currentlyEditingChangeSelectorIsDirty,
        currentDraftChange,
        currentLiveChange,
        changeId,
      ),
    );
};

/**
 * For a given change, return the status of a certain property for that change.

 * Note: This is only guarenteed to work with the 'selector' and 'operator' properties.
 * It is not clear whether the same logic (defined below) works with other properties, but it is very possible.
 *
 * If the change is being currently edited, the property is dirty.

 * Else if the change in live and there is either no draft change or the draft change property
 * value matches the live value, then the property is live.
 *
 * Else if there exists a draft change, the property is in draft mode.
 *
 * @param {String} propertyName
 * @param {Immutable.Map} currentlyEditingChange
 * @param {Boolean} currentlyEditingChangePropertyIsDirty  Whether or not this property is dirty in currentlyEditingChange
 * @param {Immutable.Map} currentDraftChange
 * @param {Immutable.Map} currentLiveChange
 * @param {String} changeId
 * @returns {String}
 */
fns.getStatusOfChangeProperty = (
  propertyName,
  currentlyEditingChange,
  currentlyEditingChangePropertyIsDirty,
  currentDraftChange,
  currentLiveChange,
  changeId,
) => {
  if (
    currentlyEditingChange.get('id') === changeId &&
    currentlyEditingChangePropertyIsDirty
  ) {
    return LayerExperimentEnums.ChangeStatuses.DIRTY;
  }
  if (
    currentLiveChange &&
    (!currentDraftChange ||
      Immutable.is(
        currentDraftChange.get(propertyName),
        currentLiveChange.get(propertyName),
      ))
  ) {
    return LayerExperimentEnums.ChangeStatuses.LIVE;
  }
  if (currentDraftChange) {
    return LayerExperimentEnums.ChangeStatuses.DRAFT;
  }
};

/**
 * For a given change, return the status of the 'async' property for that change.
 *
 * The default value for an async change is 'false', so if a change is missing the key 'async',
 * it should only be considered dirty by comparison if the comparing value is 'true'.
 *
 * @param currentlyEditingChange
 * @param currentDraftChange
 * @param currentLiveChange
 * @param changeId
 * @returns {Immutable.Map}
 */
fns.getStatusOfAsyncProperty = (
  currentlyEditingChange,
  currentDraftChange,
  currentLiveChange,
  changeId,
) => {
  if (currentlyEditingChange.get('id') === changeId) {
    // There is no draft or live change and the currentlyEditingChange has async set to true.
    // -OR-
    // There is a draft change and its value is not the same as the currentlyEditingChange.
    // -OR-
    // There is a live change and its value is not the same as the currentlyEditingChange.
    let currentSavedValue;
    if (currentDraftChange && currentDraftChange.has('async')) {
      currentSavedValue = currentDraftChange.get('async');
    } else if (currentLiveChange && currentLiveChange.has('async')) {
      currentSavedValue = currentLiveChange.get('async');
    }
    if (
      (currentlyEditingChange.get('async') &&
        _.isUndefined(currentSavedValue)) ||
      (!_.isUndefined(currentSavedValue) &&
        currentSavedValue !== currentlyEditingChange.get('async'))
    ) {
      return LayerExperimentEnums.ChangeStatuses.DIRTY;
    }
  }

  if (
    currentLiveChange &&
    currentLiveChange.has('async') &&
    (!currentDraftChange ||
      currentDraftChange.get('async') === currentLiveChange.get('async'))
  ) {
    return LayerExperimentEnums.ChangeStatuses.LIVE;
  }

  if (
    currentDraftChange &&
    currentDraftChange.has('async') &&
    (!currentLiveChange ||
      currentDraftChange.get('async') !== currentLiveChange.get('async'))
  ) {
    return LayerExperimentEnums.ChangeStatuses.DRAFT;
  }
};

fns.getWidgetChangeStatuses = function(currentDraftChange, currentLiveChange) {
  if (!currentDraftChange) {
    return currentLiveChange
      .get('config')
      .map(() => LayerExperimentEnums.ChangeStatuses.LIVE);
  }
  if (!currentLiveChange) {
    return currentDraftChange
      .get('config')
      .map(() => LayerExperimentEnums.ChangeStatuses.DRAFT);
  }
  const draftConfig = currentDraftChange.get('config');
  const liveConfig = currentLiveChange.get('config');
  return draftConfig.map((draftFieldValue, fieldName) => {
    if (Immutable.is(draftFieldValue, liveConfig.get(fieldName))) {
      return LayerExperimentEnums.ChangeStatuses.LIVE;
    }
    return LayerExperimentEnums.ChangeStatuses.DRAFT;
  });
};

fns.getInsertHTMLChangeStatus = function(
  currentlyEditingChange,
  currentlyEditingChangeSelectorIsDirty,
  currentlyEditingInsertHTMLOperatorIsDirty,
  currentDraftChange,
  currentLiveChange,
  changeId,
) {
  const statusMap = toImmutable({ html: null });
  const currentDraftChangeValue =
    currentDraftChange && currentDraftChange.get('value');
  const currentLiveChangeValue =
    currentLiveChange && currentLiveChange.get('value');

  return statusMap
    .map(() => {
      if (currentlyEditingChange.get('id') === changeId) {
        if (
          (currentlyEditingChange.get('value') &&
            !currentDraftChange &&
            !currentLiveChange) ||
          (currentDraftChange &&
            currentDraftChangeValue !== currentlyEditingChange.get('value')) ||
          (currentLiveChange &&
            currentLiveChangeValue !== currentlyEditingChange.get('value'))
        ) {
          return LayerExperimentEnums.ChangeStatuses.DIRTY;
        }
      }

      if (
        currentLiveChange &&
        currentLiveChange.get('value') &&
        (!currentDraftChange ||
          currentDraftChangeValue === currentLiveChange.get('value'))
      ) {
        return LayerExperimentEnums.ChangeStatuses.LIVE;
      }

      if (currentDraftChange && currentDraftChange.get('value')) {
        return LayerExperimentEnums.ChangeStatuses.DRAFT;
      }
    })
    .set(
      'selector',
      fns.getStatusOfChangeProperty(
        'selector',
        currentlyEditingChange,
        currentlyEditingChangeSelectorIsDirty,
        currentDraftChange,
        currentLiveChange,
        changeId,
      ),
    )
    .set(
      'operator',
      fns.getStatusOfChangeProperty(
        'operator',
        currentlyEditingChange,
        currentlyEditingInsertHTMLOperatorIsDirty,
        currentDraftChange,
        currentLiveChange,
        changeId,
      ),
    )
    .set(
      'async',
      fns.getStatusOfAsyncProperty(
        currentlyEditingChange,
        currentDraftChange,
        currentLiveChange,
        changeId,
      ),
    );
};

fns.getRedirectChangeStatuses = function(
  currentlyEditingChange,
  currentDraftChange,
  currentLiveChange,
) {
  const getStatusForKey = function(changeId, defaults, key) {
    // A change can only be dirty if the currentlyEditingChangeId matches.
    if (
      currentlyEditingChange &&
      currentlyEditingChange.get('id') === changeId
    ) {
      // get our base comparison change. Prioritize draft change then live change
      const baseChange = currentDraftChange || currentLiveChange;

      // if we have a base change to compare it to, check for differences in the keys for dirtiness
      // Otherwise, its dirty if it does not have the default value
      if (baseChange) {
        if (currentlyEditingChange.get(key) !== baseChange.get(key)) {
          return LayerExperimentEnums.ChangeStatuses.DIRTY;
        }
      } else if (currentlyEditingChange.get(key) !== defaults[key]) {
        return LayerExperimentEnums.ChangeStatuses.DIRTY;
      }
    }

    // We have a live change with this attribute
    // - AND -
    // We either don't have a draft change OR that draft change value is equal to the live one.
    if (
      currentLiveChange &&
      currentLiveChange.has(key) &&
      (!currentDraftChange ||
        currentDraftChange.get(key) === currentLiveChange.get(key))
    ) {
      return LayerExperimentEnums.ChangeStatuses.LIVE;
    }

    // We have a draft change with this attribute
    if (currentDraftChange && currentDraftChange.has(key)) {
      return LayerExperimentEnums.ChangeStatuses.DRAFT;
    }

    return null;
  };

  if (
    currentLiveChange &&
    currentLiveChange.get('type') !== LayerExperimentEnums.ChangeTypes.REDIRECT
  ) {
    currentLiveChange = undefined;
  }
  if (
    currentDraftChange &&
    currentDraftChange.get('type') !== LayerExperimentEnums.ChangeTypes.REDIRECT
  ) {
    currentDraftChange = undefined;
  }
  if (
    currentlyEditingChange &&
    currentlyEditingChange.get('type') !==
      LayerExperimentEnums.ChangeTypes.REDIRECT
  ) {
    currentlyEditingChange = undefined;
  }

  const cannonicalChange =
    currentDraftChange || currentLiveChange || currentlyEditingChange;
  const id = cannonicalChange ? cannonicalChange.get('id') : undefined;
  // since the default for dest_fn is undefined, it's not set in this default map
  const DEFAULTS = {
    allowAdditionalRedirect: false,
    dest: '',
    preserveParameters: true,
  };
  const statusForKey = _.partial(getStatusForKey, id, DEFAULTS);
  let output = Immutable.Map();

  if (cannonicalChange) {
    output = output
      .set('allowAdditionalRedirect', statusForKey('allowAdditionalRedirect'))
      .set('dest', statusForKey('dest'))
      .set('dest_fn', statusForKey('dest_fn'))
      .set('preserveParameters', statusForKey('preserveParameters'));
  }

  return output;
};

/**
 * Helper function to assist with providing the right data for highlighting an element change.
 * @param change
 * @returns {{dataOptlyId: *, selector: *}}
 */
fns.getHighlighterOptionsForElementChange = function(change) {
  // If we have a rearrange change present for an element change or this is an insertHTML
  // change, use the change id for highlighting instead of selector.
  let dataOptlyId = null;
  let selector = null;
  // An attribute change must have a rearrange and rearrange.insertSelector property to warrant
  // using the id for highlighting, while other changes must simply have just an operator (html, image).
  if (
    (change.rearrange && change.rearrange.insertSelector) ||
    change.operator
  ) {
    dataOptlyId = change.id;
  } else {
    ({ selector } = change);
  }
  return {
    dataOptlyId,
    selector,
  };
};

fns.isCSSObjectDirty = function(changeStatusMap) {
  if (changeStatusMap.css) {
    return _.find(
      changeStatusMap.css,
      (value, property) => value === LayerExperimentEnums.ChangeStatuses.DIRTY,
    );
  }

  return false;
};

/**
 * Transform a CSS property name from CSS convention to IDL convention.
 * Required because the change editor receives a computed style object that has
 * passed through JSON.stringify() and as a result is stripped of its inherited
 * CSSStyleDeclaration properties. That object only has properties following
 * the IDL convention (e.g., 'fontSize' is present, 'font-size' is undefined).
 *
 * See https://drafts.csswg.org/cssom/#css-property-to-idl-attribute
 *
 * @param cssProperty
 * @returns {string} CSS property name, but following IDL convention
 * @private
 */
fns.cssToIDL = function(cssProperty) {
  let result = '';

  // Replace all occurrences of '-a' with 'A', for an abitrary letter a
  for (let i = 0; i < cssProperty.length; ++i) {
    const c = cssProperty[i];
    result += c === '-' ? cssProperty[++i].toUpperCase() : c;
  }

  return result;
};

/**
 * Helper method to just reset all the image and thumbnail sizes
 *
 * @param {object} imageObject - an object that stores information about the image it represents
 */
function resetImageDimensions(imageObject) {
  imageObject.width = 0;
  imageObject.height = 0;
  imageObject.thumbnailWidth = 0;
  imageObject.thumbnailHeight = 0;
}

fns.getStatusClassForView = function(draftChanges, liveChanges) {
  if (draftChanges && draftChanges.length) {
    return 'background--draft';
  }

  if (liveChanges && liveChanges.length) {
    return 'background--live';
  }
  return '';
};

/**
 * Formats redirect URL based on regex. If it resembles a hostname but doesn't have a protocol, then add
 * an 'http://' protocol.
 * @param {string} url
 * @returns {string} url
 */
fns.formatRedirectUrl = function(url) {
  if (url.match(/^([a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)+.*)$/)) {
    url = `http://${url}`;
  }
  return url;
};

/**
 * Compute the status class that should be used for the given list of statuses.
 * TODO: This duplicates ui/mixins.ChangeEditorSidebar#getStatusClassForAttribute
 *       Refactor to replace usages of that mixin method with this one.
 * @param statuses {...string}
 */
fns.coalesceStatusesIntoClass = function(...statuses) {
  if (_.includes(statuses, 'dirty')) {
    return 'lego-form-field__item--dirty';
  }
  if (_.includes(statuses, 'draft')) {
    return 'lego-form-field__item--draft';
  }
  if (_.includes(statuses, 'live')) {
    return 'lego-form-field__item--live';
  }
  return '';
};

/**
 * Returns a map representing change status for extension fields, used to
 * display dirty status indicators while editing an instance of an extension
 * change
 * @param {Immutable.Map} draftChange - Saved version of the extension change
 * @param {Immutable.Map} liveChange - Live version of the extension change
 * @param {Immutable.Map} formValues - Map of field name to value, representing
 * the current form values for each field
 * @return {Immutable.Map} A map from field name to change status
 */
fns.getStatusesForExtensionFields = function(
  draftChange,
  liveChange,
  formValues,
) {
  if (!draftChange && !liveChange) {
    return formValues.map(
      (formValue, fieldName) => LayerExperimentEnums.ChangeStatuses.DRAFT,
    );
  }
  return formValues.map((formValue, fieldName) => {
    if (!draftChange) {
      return Immutable.is(liveChange.getIn(['config', fieldName]), formValue)
        ? LayerExperimentEnums.ChangeStatuses.LIVE
        : LayerExperimentEnums.ChangeStatuses.DIRTY;
    }
    if (!liveChange) {
      return Immutable.is(draftChange.getIn(['config', fieldName]), formValue)
        ? LayerExperimentEnums.ChangeStatuses.DRAFT
        : LayerExperimentEnums.ChangeStatuses.DIRTY;
    }
    const liveValue = liveChange.getIn(['config', fieldName]);
    const draftValue = draftChange.getIn(['config', fieldName]);
    const formValueMatchesStatus = Immutable.is(liveValue, draftValue)
      ? LayerExperimentEnums.ChangeStatuses.LIVE
      : LayerExperimentEnums.ChangeStatuses.DRAFT;
    return Immutable.is(formValue, draftValue)
      ? formValueMatchesStatus
      : LayerExperimentEnums.ChangeStatuses.DIRTY;
  });
};

/**
 * Determines if a change depends on its previous change in the list of changes.
 *
 * @param {Immutable.List} changesList
 * @param {Immutable.Map} givenChange
 * @return {Boolean}
 */
fns.changeDependsOnPrevious = (changesList, givenChange) => {
  if (!givenChange) {
    return false;
  }

  const changeId = givenChange.get('id');
  const currChangeIndex = changesList.findIndex(
    change => change.get('id') === changeId,
  );

  // If currChange is the first change in the list, it won't depend on any previous change OR
  // if changeList is empty, immediately return false.
  if (currChangeIndex === 0 || changesList.isEmpty()) {
    return false;
  }

  const prevChange =
    currChangeIndex < 0
      ? changesList.last() // If currChange is getting created and does not exist yet
      : changesList.get(currChangeIndex - 1); // If currChange exists and is not the first change in the list

  return (
    givenChange.get('dependencies') &&
    givenChange
      .get('dependencies')
      .some(dependencyChangeId => dependencyChangeId === prevChange.get('id'))
  );
};

export default fns;

import { Store } from 'nuclear-js';
import Immutable, { toImmutable } from 'optly/immutable';

import { SET_FILTER, TOGGLE_COLLABORATOR_SELECTION } from '../action_types';

export default Store({
  getInitialState() {
    return toImmutable({
      filter: '',
      selectedCollaboratorIDs: Immutable.Set(),
    });
  },

  initialize() {
    this.on(SET_FILTER, setFilter);
    this.on(TOGGLE_COLLABORATOR_SELECTION, toggleCollaboratorSelection);
  },
});

function setFilter(state, payload) {
  return state.set('filter', payload.filter);
}

function toggleCollaboratorSelection(state, payload) {
  const { collaboratorID, selectMultiple } = payload;
  let selectedCollaboratorIDs = state.get('selectedCollaboratorIDs');

  if (!selectMultiple) {
    selectedCollaboratorIDs = Immutable.Set([collaboratorID]);
  } else if (selectedCollaboratorIDs.has(collaboratorID)) {
    selectedCollaboratorIDs = selectedCollaboratorIDs.delete(collaboratorID);
  } else {
    selectedCollaboratorIDs = selectedCollaboratorIDs.add(collaboratorID);
  }

  return state.set('selectedCollaboratorIDs', selectedCollaboratorIDs);
}

const LiveVariableEnums = require('optly/modules/entity/live_variable/enums');
const ProjectEnums = require('optly/modules/entity/project/enums');
const keyMirror = require('optly/utils/key_mirror');

exports.TABLE_ID = 'VariablesDashboard';

exports.NAV_TABS = keyMirror({
  VARIABLES: null,
});

exports.LIVE_VARIABLE_VALUE_DEFAULTS = {
  [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]: 'true',
  [LiveVariableEnums.LIVE_VARIABLE_TYPES.float]: 0.0,
  [LiveVariableEnums.LIVE_VARIABLE_TYPES.integer]: 0,
  [LiveVariableEnums.LIVE_VARIABLE_TYPES.string]: '',
};

exports.LIVE_VARIABLE_CODE_BLOCKS_BY_LANG = {
  [ProjectEnums.sdkLanguages.JAVA]: {
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      'Boolean %s = getVariableBoolean("%s", userId, true);',
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.double]:
      'Double %s = getVariableDouble("%s", userId, true);',
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.integer]:
      'Integer %s = getVariableInteger("%s", userId, true);',
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.string]:
      'String %s = getVariableString("%s", userId, true);',
  },
  [ProjectEnums.sdkLanguages.OBJECTIVE_C]: {
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.boolean]:
      'BOOL %s = [optimizely variableBoolean:@"%s" userId:userId activateExperiment:TRUE];',
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.double]:
      'double %s = [optimizely variableDouble:@"%s" userId:userId activateExperiment:TRUE];',
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.integer]:
      'NSInteger %s = [optimizely variableInteger:@"%s" userId:userId activateExperiment:TRUE];',
    [LiveVariableEnums.LIVE_VARIABLE_TYPES.string]:
      'NSString *%s = [optimizely variableString:@"%s" userId:userId activateExperiment:TRUE];',
  },
};

exports.LIVE_VARIABLE_CODE_BLOCKS = {
  [ProjectEnums.sdks.ANDROID]:
    exports.LIVE_VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.JAVA],
  [ProjectEnums.sdks.ANDROID_TV]:
    exports.LIVE_VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.JAVA],
  [ProjectEnums.sdks.IOS]:
    exports.LIVE_VARIABLE_CODE_BLOCKS_BY_LANG[
      ProjectEnums.sdkLanguages.OBJECTIVE_C
    ],
  [ProjectEnums.sdks.TV_OS]:
    exports.LIVE_VARIABLE_CODE_BLOCKS_BY_LANG[
      ProjectEnums.sdkLanguages.OBJECTIVE_C
    ],
};

import $ from 'jquery';
import _ from 'lodash';

import flux from 'core/flux';
import AjaxUtil from 'optly/utils/ajax';
import RestApi from 'optly/modules/rest_api';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import UIVersion from 'optly/modules/ui/version';

import userRolesActions from 'optly/modules/entity/user_roles/actions';

import entityDefs from './entity_definitions';
import { isInvitePendingOrExpired } from './fns';
import getters from './getters';

const v1Actions = RestApi.createEntityActions(entityDefs.v1);
const v2Actions = RestApi.createEntityActions(entityDefs.v2);
const ccActions = RestApi.createEntityActions(entityDefs.crossCompatible);

function generateCollaboratorSavePayload(collaborator, projectIds) {
  return (projectIds || []).map(projectId => ({
    ...collaborator,
    project_id: projectId,
  }));
}

function baseCollaboratorSavePayload(collaborator) {
  const accountId = flux.evaluate(AdminAccountGetters.id);
  const currentOptlyVersion = UIVersion.isClassic()
    ? UIVersion.enums.versions.CLASSIC
    : UIVersion.enums.versions.OPTIMIZELY_X;

  const omitAttributes = [];
  if (isInvitePendingOrExpired(collaborator)) {
    omitAttributes.push('enum_role_name', 'user_id'); // v2 disallowed attributes
  } else {
    omitAttributes.push('invitation_status'); // v1 disallowed attribute
  }

  const collaboratorPayload = {
    ..._.omit(collaborator, omitAttributes),
    account_id: accountId,
    optimizely_version: currentOptlyVersion,
    target_user_email: collaborator.user_id || collaborator.target_user_email,
    first_name: collaborator.target_user_first_name,
    last_name: collaborator.target_user_last_name,
  };
  return collaboratorPayload;
}

export function flush() {
  // flush both the v1 collaborator and v2 collaborator stores
  v1Actions.flush();
  v2Actions.flush();
}

/**
 * Fetches collaboraor invites and approved collaborators (via separate v1 and v2 collaborator APIs).
 * @param {object} filters
 * @return {jQuery.Deferred}
 */
export function fetchAll(filters, force) {
  const deferred = $.Deferred();
  const promises = [
    v1Actions.fetchAll(filters, force),
    v2Actions.fetchAll(filters, force),
  ];

  Promise.all(promises).then(deferred.resolve);

  return deferred;
}

/**
 * Creates a new collab. We flush the datastore since this collaborator may be
 * duplicated locally as a User/Project Creator.
 * @param {object} data
 * @param {object} currentProject
 * @return {Deferred}
 */
export function createAndSave(data, currentProject) {
  return this.save(data).then(result => {
    this.refreshCollaborators(currentProject.id);
    return result;
  });
}

/**
 * Does a patch of a collaborator with id and changes
 * @param {object} changes
 * @return {Deferred}
 */
export function save(changes) {
  const changesPayload = changes.map(change =>
    baseCollaboratorSavePayload(change),
  );
  return ccActions.save(changesPayload).then(results => {
    (results || []).forEach((result, index) => {
      const change = changesPayload[index];
      if (result.id !== change.id) {
        const collab = flux.evaluate(getters.byId(change.id));
        const isV2 = !!(collab && collab.get('is_v2'));
        const entityCacheToPrune = isV2 ? 'v2' : 'v1';
        // saving collabs in the API will delete the entity
        // and return a new one, emulate that behavior in the frontend
        const payload = {
          entity: entityDefs[entityCacheToPrune].entity,
          id: change.id,
        };
        flux.dispatch(RestApi.actionTypes.API_ENTITY_DELETE_SUCCESS, payload);
      }
    });
    return results;
  });
}

/**
 * Deletes a collaborator. We flush the datastore since this collaborator may be
 * recreated on the server as a User/Project Creator.
 * @param {object} instance
 * @param {object} currentProject
 * @return {Deferred}
 */
export function deleteFromProject(instance, currentProject) {
  const deferred = ccActions.delete(instance);
  return deferred.then(result => {
    this.refreshCollaborators(currentProject.id);
    return result;
  });
}

/**
 * Special action to remove collaborator from all projects
 * Flush the store after since other projects will also be affected
 * @param {Object} instance
 * @param {Object} currentProject
 * @return {Deferred}
 */
export function deleteFromAccount(instance, currentProject) {
  const accountId = flux.evaluate(AdminAccountGetters.id);
  return AjaxUtil.makeV1AjaxRequest({
    url: `/api/v1/accounts/${accountId}/user_roles/${instance.user_id}`,
    type: 'DELETE',
  }).then(result => {
    if (currentProject !== null) {
      this.refreshCollaborators(currentProject.id);
    } else {
      this.refreshUserRoles(accountId);
    }
    return result;
  });
}

// export function deleteMultipleFromAccount

/**
 * Flushes the collaborators store and fetches a fresh set
 * @param {number} projectid
 * @return {Deferred}
 */
export function refreshCollaborators(projectId) {
  this.flush();

  // force fetchAll
  return this.fetchAll(
    {
      project_id: projectId,
    },
    true,
  );
}

export function refreshUserRoles(adminAccountId) {
  userRolesActions.flush();

  // force fetchAllPagesOfUsers
  return userRolesActions.fetchAllPagesOfUsers(adminAccountId);
}

/**
 * @param {Object} collaborator
 * @param {Array} projectIds
 * @param {String} currentOptlyVersion
 * @returns {Deferred}
 */
export function saveCollaboratorToProjects(collaborator, projectIds) {
  if (projectIds.length === 0) {
    return $.Deferred().reject('Must specify at least one project');
  }
  const payload = generateCollaboratorSavePayload(collaborator, projectIds);
  return this.save(payload);
}

/**
 * @param {Object} user
 * @param {Array} projects
 * @returns {Deferred}
 */
export function saveUserToProjects(user, projects) {
  if (projects.length === 0) {
    return $.Deferred().reject('Must specify at least one project');
  }
  const payload = generateUserSavePayload(user, projects);

  return this.save(payload);
}

function generateUserSavePayload(user, projects) {
  if (user.role_name === 'Administrator') {
    return [
      {
        ...user,
        project_id: projects[0].target_project_id,
      },
    ];
  }
  return (projects || []).map(project => ({
    ...user,
    project_id: project.target_project_id,
    role_name: project.role_name,
  }));
}

export default {
  flush,
  fetchAll,
  createAndSave,
  save,
  deleteFromAccount,
  deleteFromProject,
  refreshCollaborators,
  refreshUserRoles,
  saveCollaboratorToProjects,
  saveUserToProjects,
};

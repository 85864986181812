import { toImmutable } from 'optly/immutable';
import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

export const asList = [entityCache, views => views.toList()];

/**
 * Getter that filters for active views on the account level.
 */
export const activeAccountViews = [
  entityCache,
  accountViews => accountViews.filter(view => !view.get('archived')),
];

/**
 * Getter that filters for page views on the account level.
 */
export const activeAccountPageViews = [
  entityCache,
  accountViews =>
    accountViews.filter(
      view => !view.get('archived') && !view.get('single_use'),
    ),
];

/**
 * Getter that filters for page views in a Layer.
 */
export const byLayer = layer => [
  entityCache,
  /**
   * @param {Immutable.Map} viewMap
   * @return {Immutable.List}
   */
  function(viewMap) {
    if (!layer || !layer.get('view_ids') || !viewMap) {
      return toImmutable([]);
    }

    return layer
      .get('view_ids')
      .map(id => viewMap.get(id))
      .filter(i => i);
  },
];

export default {
  ...baseEntityGetters,
  activeAccountViews,
  activeAccountPageViews,
  byLayer,
  asList,
};

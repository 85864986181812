import {
  Button,
  ButtonRow,
  Checkbox,
  Icon,
  Link,
  Textarea,
  TextField,
} from 'optimizely-oui';

import PropTypes from 'prop-types';

import React from 'react';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import { connect } from 'core/ui/decorators';
import AdminAccountGetters from 'optly/modules/admin_account/getters';
import CurrentProjectActions from 'optly/modules/current_project/actions';
import LoadingGetters from 'core/modules/loading/getters';
import PermissionsFns from 'optly/modules/permissions/fns';
import { fns as ProjectEntityFns } from 'optly/modules/entity/project';
import PermissionsGetters from 'optly/modules/permissions/getters';
import keyMirror from 'optly/utils/key_mirror';
import LoadingOverlay from 'react_components/loading_overlay';
import tr from 'optly/translate';
import { Wrapper } from 'react_components/dialog';
import ui from 'core/ui';
import enums from 'optly/modules/entity/project/enums';

import Immutable from 'optly/immutable';

import { enums as OptimizelyChampagneEnums } from 'optly/modules/optimizely_champagne';

import ImageGrid from './image_grid';

import createEdgeProjectLogo from '/static/img/x/x-edge-create-project.svg';

import createWebProjectLogo from '/static/img/x/x-web-create-project.svg';

export const productCategories = keyMirror({
  EDGE: null,
  FEATURE_EXPERIMENTATION: null,
  FULL_STACK: null,
  MOBILE: null,
  MOBILE_OTT: null,
  WEB: null,
  OTT: null,
});

const projectTypeToProductCategories = {
  edge: productCategories.EDGE,
  web: productCategories.WEB,
  fullstack: productCategories.FULL_STACK,
};

const humanReadableProjectCategories = {
  EDGE: tr('Edge'),
  FEATURE_EXPERIMENTATION: tr('Feature Experimentation'),
  FULL_STACK: tr('Full Stack'),
  MOBILE: tr('Mobile'),
  MOBILE_OTT: tr('Mobile / OTT'),
  WEB: tr('Web'),
  OTT: tr('OTT'),
};

function showUpsellDialog(productName) {
  ui.showUpsell({
    message: tr(
      'Your account does not allow creating new {0} projects. To gain access, please contact your Optimizely Customer Success Manager or Optimizely Sales.',
      productName,
    ),
    title: tr('Would you like access to {0}?', productName),
  });
}

const renderImageContainerFromPath = imagePath => (
  <div className="push-quad--right">
    <img src={imagePath} className="width--150" alt="logo" />
  </div>
);

const lock = (
  <span
    key="lock"
    className="push-half--left"
    data-test-section="platform-card-lock">
    <Icon name="lock" size="small" />
  </span>
);

const PlatformCard = props => {
  const {
    image,
    title,
    body,
    buttonContent,
    locked,
    onSelect,
    testSection,
  } = props;
  return (
    <div
      className="flex shadow-box push-quad--bottom"
      data-test-section={testSection}>
      {image}
      <div className="flex--1">
        <h3>{title}</h3>
        <p className="push-double--bottom">{body}</p>
        <Button
          style="outline"
          onClick={onSelect}
          testSection="select-platform">
          {buttonContent}
          {locked && lock}
        </Button>
      </div>
    </div>
  );
};

PlatformCard.propTypes = {
  body: PropTypes.node.isRequired,
  buttonContent: PropTypes.node.isRequired,
  image: PropTypes.node.isRequired,
  locked: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  testSection: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
};

PlatformCard.defaultProps = {
  locked: false,
};

const MAX_PROJECT_NAME_LENGTH = 50;

const MAX_PROJECT_DESCRIPTION_LENGTH = 200;

const INITIAL_STATE = {
  description: '',
  isLoading: false,
  name: '',
  selectedPlatform: null,
};

const getActiveWebProjects = () =>
  Immutable.List(window.optlyProjectsMap.values()).filter(
    project =>
      !project.get('archived') &&
      project.get('project_status') !== enums.project_status.ARCHIVED &&
      ProjectEntityFns.isWebProject(project),
  );

const getActiveFXProjects = () =>
  Immutable.List(window.optlyProjectsMap.values()).filter(
    project =>
      !project.get('archived') &&
      project.get('project_status') !== enums.project_status.ARCHIVED &&
      ProjectEntityFns.isFullStackProject(project),
  );

@connect({
  canUseDatafileAuthentication: PermissionsGetters.canUseDatafileAuthentication,
  canAccountUseEdgeProjects: PermissionsGetters.canAccountUseEdgeProjects,
  canUsePersonalizationOnly: PermissionsGetters.canUsePersonalizationOnly,
  canUseFullStackSDKs: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseFullStackSDKs,
  ],
  canUseMobileSDKs: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseMobileSDKs,
  ],
  canUseXWeb: PermissionsGetters.canUseXWeb,
  canUseOverTheTopSDKs: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseOverTheTopSDKs,
  ],
  canCreateXWebProjects: [
    PermissionsGetters.canUseXWeb,
    LoadingGetters.isLoading('activeProjects'),
    [
      AdminAccountGetters.accountFeatureConfigs,
      PermissionsFns.getNumberOfWebProjects,
    ],
    function(canUseXWeb, loadingActiveProjects, numberOfWebProjects) {
      // First check if account has permission to create Web project at all
      if (!canUseXWeb) {
        return false;
      }

      // If account can't create more than one Web project,
      // wait until active projects have loaded to validate
      // that they have not yet created a Web project
      if (loadingActiveProjects) {
        return false;
      }

      const activeWebProjects = getActiveWebProjects();

      // Once active projects have loaded, check to see
      // if there are 0 Web project
      return activeWebProjects.size < numberOfWebProjects;
    },
  ],
  canCreateFullStackProjects: [
    [
      AdminAccountGetters.accountPermissions,
      PermissionsFns.canUseFullStackSDKs,
    ],
    LoadingGetters.isLoading('activeProjects'),
    [
      AdminAccountGetters.accountFeatureConfigs,
      PermissionsFns.getNumberOfFXProjects,
    ],
    function(canUseFullStackSDKs, loadingActiveProjects, numberOfFXProjects) {
      // First check if account has access to fullstack at all
      if (!canUseFullStackSDKs) {
        return false;
      }

      // If account can't create more than one FS project,
      // wait until active projects have loaded to validate
      // that they have not yet created a FS project
      if (loadingActiveProjects) {
        return false;
      }

      const activeFXProjects = getActiveFXProjects();

      // Once active projects have loaded, check to see
      // if there are 0 FS projects
      return activeFXProjects.size < numberOfFXProjects;
    },
  ],
})
class CreateProject extends React.Component {
  static propTypes = {
    canAccountUseEdgeProjects: PropTypes.bool.isRequired,
    canCreateFullStackProjects: PropTypes.bool,
    canCreateXWebProjects: PropTypes.bool.isRequired,
    canUseDatafileAuthentication: PropTypes.bool.isRequired,
    canUseFullStackSDKs: PropTypes.bool.isRequired,
    canUseMobileSDKs: PropTypes.bool.isRequired,
    canUseOverTheTopSDKs: PropTypes.bool.isRequired,
    canUsePersonalizationOnly: PropTypes.bool.isRequired,
    isFirstProject: PropTypes.bool,
    onCreate: PropTypes.func.isRequired,
    // value of query param "project_type"
    projectType: PropTypes.string,
  };

  static defaultProps = {
    isFirstProject: false,
    projectType: null,
    canCreateFullStackProjects: false,
  };

  constructor(props) {
    super(props);

    const { isFirstProject } = this.props;

    let selectedPlatform = null;
    const platform = projectTypeToProductCategories[props.projectType];
    if (platform) {
      selectedPlatform = platform;
    } else if (isFirstProject) {
      const unlockedOptions = this.getUnlockedProjectOptions();
      if (unlockedOptions.length === 1) {
        selectedPlatform = unlockedOptions.at(0).productCategory;
      }
    }
    // eslint-disable-next-line react/state-in-constructor

    this.state = {
      ...INITIAL_STATE,
      selectedPlatform,
      useFullStackLegacyExperience: false,
    };
  }

  getProjectCards = ({ productCategory, locked }) => {
    switch (productCategory) {
      case productCategories.WEB:
        return (
          <PlatformCard
            key="web-platform-card"
            image={renderImageContainerFromPath(createWebProjectLogo)}
            title={humanReadableProjectCategories[productCategories.WEB]}
            body={tr(
              "Optimize your website using Optimizely's visual editor or code editor. If you're unsure, choose this project type to get started.",
            )}
            buttonContent={tr('Create Web Project')}
            locked={locked}
            onSelect={this.onWebSelect}
            testSection="platform-card-web"
          />
        );
      case productCategories.EDGE:
        return (
          <PlatformCard
            key="edge-platform-card"
            image={renderImageContainerFromPath(createEdgeProjectLogo)}
            title={humanReadableProjectCategories[productCategories.EDGE]}
            body={tr(
              "Run A/B tests on your website using Optimizely's visual editor or code editor with extremely fast page load performance.",
            )}
            buttonContent={tr('Create Edge Project')}
            onSelect={this.onEdgeSelect}
            testSection="platform-card-edge"
          />
        );
      case productCategories.FEATURE_EXPERIMENTATION:
        return null;
      case productCategories.FULL_STACK:
        return (
          <PlatformCard
            key="full-stack-platform-card"
            image={<ImageGrid />}
            title={
              humanReadableProjectCategories[
                productCategories.FEATURE_EXPERIMENTATION
              ]
            }
            body={tr(
              'Run experiments anywhere in your technology stack. Supported platforms and languages include C#, Flutter, Go, Java, JavaScript, React, Node, PHP, Python, Ruby, Android, iOS, Android TV, and tvOS.',
            )}
            buttonContent={tr('Create Feature Experimentation Project')}
            locked={locked}
            testSection="platform-card-full-stack"
            onSelect={this.onFullStackSelect}
          />
        );
      case productCategories.MOBILE:
        return (
          <PlatformCard
            key="full-stack-platform-card"
            image={<ImageGrid isMobileOrOTTOnlyAccount={true} />}
            title={humanReadableProjectCategories[productCategories.MOBILE]}
            body={tr(
              'Run mobile experiments on Android and Apple iOS devices. Upgrade your license to include Feature Experimentation and take advantage of other platforms.',
            )}
            buttonContent={tr('Create Mobile Project')}
            locked={false}
            testSection="platform-card-mobile"
            onSelect={this.onMobileOrOTTSelect}
          />
        );
      case productCategories.OTT:
        return (
          <PlatformCard
            key="full-stack-platform-card"
            image={<ImageGrid isMobileOrOTTOnlyAccount={true} />}
            title={humanReadableProjectCategories[productCategories.OTT]}
            body={tr(
              'Run OTT experiments on Android and Apple iOS devices. Upgrade your license to include Feature Experimentation and take advantage of other platforms.',
            )}
            buttonContent={tr('Create OTT Project')}
            locked={false}
            testSection="platform-card-ott"
            onSelect={this.onMobileOrOTTSelect}
          />
        );
      case productCategories.MOBILE_OTT:
        return (
          <PlatformCard
            key="full-stack-platform-card"
            image={<ImageGrid isMobileOrOTTOnlyAccount={true} />}
            title={humanReadableProjectCategories[productCategories.MOBILE_OTT]}
            body={tr(
              'Run experiments on Android and Apple iOS devices. Upgrade your license to include Feature Experimentation and take advantage of other platforms.',
            )}
            buttonContent={tr('Create Mobile / OTT Project')}
            locked={false}
            testSection="platform-card-mobile-ott"
            onSelect={this.onMobileOrOTTSelect}
          />
        );
    }
  };

  onEdgeSelect = () => {
    const { canAccountUseEdgeProjects } = this.props;
    if (canAccountUseEdgeProjects) {
      this.setState({
        selectedPlatform: productCategories.EDGE,
      });
    } else {
      showUpsellDialog(humanReadableProjectCategories[productCategories.EDGE]);
    }
  };

  onWebSelect = () => {
    const { canCreateXWebProjects } = this.props;
    if (canCreateXWebProjects) {
      this.setState({
        selectedPlatform: productCategories.WEB,
      });
    } else {
      showUpsellDialog(humanReadableProjectCategories[productCategories.WEB]);
    }
  };

  onFullStackSelect = () => {
    const { canCreateFullStackProjects } = this.props;
    if (canCreateFullStackProjects) {
      this.setState({
        selectedPlatform: productCategories.FULL_STACK,
      });
    } else {
      showUpsellDialog(
        humanReadableProjectCategories[
          productCategories.FEATURE_EXPERIMENTATION
        ],
      );
    }
  };

  onMobileOrOTTSelect = () => {
    /*
     * Note: Even though this platform card displays 'Mobile', the
     * underlying Project data that we would create is the same regardless
     * of whether the card shows 'Mobile' or 'Full Stack'. It's only a
     * cosmetic difference based on the product features available to the
     * customer account.
     */
    this.setState({
      selectedPlatform: productCategories.FULL_STACK,
    });
  };

  onNameInput = evt => {
    this.setState({
      name: evt.target.value.slice(0, MAX_PROJECT_NAME_LENGTH),
    });
  };

  onDescriptionInput = evt => {
    this.setState({
      description: evt.target.value.slice(0, MAX_PROJECT_DESCRIPTION_LENGTH),
    });
  };

  onBack = () => {
    this.setState(INITIAL_STATE);
  };

  onCreate = () => {
    const {
      description,
      name,
      selectedPlatform,
      hasPrivateDefaultDatafiles,
      useFullStackLegacyExperience,
    } = this.state;
    const { onCreate } = this.props;
    this.setState({
      isLoading: true,
    });
    onCreate({
      description,
      name,
      selectedPlatform,
      hasPrivateDefaultDatafiles,
      ...(selectedPlatform === projectTypeToProductCategories.fullstack
        ? { useFullStackLegacyExperience }
        : {}),
    }).fail(() => {
      this.setState({
        isLoading: false,
      });
    });
  };

  updatePrivateDefaultDatafiles = () => {
    this.setState(prevState => ({
      hasPrivateDefaultDatafiles: !prevState.hasPrivateDefaultDatafiles,
    }));
  };

  updateUseFullStackLegacyExperience = () => {
    this.setState(prevState => ({
      useFullStackLegacyExperience: !prevState.useFullStackLegacyExperience,
    }));
  };

  getFullStackOption = () => {
    const {
      canCreateFullStackProjects,
      canUseFullStackSDKs,
      canUseMobileSDKs,
      canUseOverTheTopSDKs,
    } = this.props;

    const shouldShowMobileCard =
      !canUseFullStackSDKs && canUseMobileSDKs && !canUseOverTheTopSDKs;
    const shouldShowOTTCard =
      !canUseFullStackSDKs && !canUseMobileSDKs && canUseOverTheTopSDKs;
    const shouldShowMobileAndOTTCard =
      !canUseFullStackSDKs && canUseMobileSDKs && canUseOverTheTopSDKs;

    if (shouldShowMobileCard) {
      return { productCategory: productCategories.MOBILE, locked: false };
    }
    if (shouldShowOTTCard) {
      return { productCategory: productCategories.OTT, locked: false };
    }
    if (shouldShowMobileAndOTTCard) {
      return { productCategory: productCategories.MOBILE_OTT, locked: false };
    }
    return {
      productCategory: productCategories.FULL_STACK,
      locked: !canCreateFullStackProjects,
    };
  };

  getProjectOptions = () => {
    const { canAccountUseEdgeProjects, canCreateXWebProjects } = this.props;
    return [
      {
        productCategory: productCategories.WEB,
        locked: !canCreateXWebProjects,
      },
      ...(canAccountUseEdgeProjects
        ? [{ productCategory: productCategories.EDGE, locked: false }]
        : []),
      this.getFullStackOption(),
    ];
  };

  getUnlockedProjectOptions = () => {
    return this.getProjectOptions().filter(option => !option.locked);
  };

  getProjectOptionPlatformCards = () => {
    return this.getProjectOptions().map(option => {
      return this.getProjectCards(option);
    });
  };

  isP13NOnlyAccount = () => {
    const {
      canAccountUseEdgeProjects,
      canCreateFullStackProjects,
      canUsePersonalizationOnly,
    } = this.props;
    return (
      canUsePersonalizationOnly &&
      !canAccountUseEdgeProjects &&
      !canCreateFullStackProjects
    );
  };

  createProjectScreen = () => {
    const {
      canUseDatafileAuthentication,
      canUseFullStackSDKs,
      isFirstProject,
    } = this.props;
    const { selectedPlatform } = this.state;

    const shouldShowSecureEnvironmentsOption =
      canUseDatafileAuthentication &&
      canUseFullStackSDKs &&
      selectedPlatform === productCategories.FULL_STACK;
    const shouldShowLegacyFullStackProjectOption =
      canUseFullStackSDKs && selectedPlatform === productCategories.FULL_STACK;
    const {
      name,
      description,
      hasPrivateDefaultDatafiles,
      useFullStackLegacyExperience,
    } = this.state;

    const legacyFullStackText = CurrentProjectActions.getHelpCopy(
      'create_project_legacy_fullstack_text',
      'If you want to take advantage of our new Feature Experimentation experience and features, leave this option unchecked. If you need legacy Full Stack to use Advanced Audience Combinations, or Jira ticket linking today, select this option.',
    );
    const legacyFullStackLearnMoreLink = CurrentProjectActions.getHelpCopy(
      'create_project_legacy_fullstack_learn_more_link',
      'https://docs.developers.optimizely.com/full-stack/v2.1/docs',
    );
    const isCreateButtonDisabled = name.length === 0;

    const instructions = this.isP13NOnlyAccount() ? (
      <>
        Add a name and description for this project. Projects let you subdivide
        your Optimizely Personalization account for different sections of a
        website, multiple sites, or different products. For more information,
        see{' '}
        <a
          href="https://support.optimizely.com/hc/en-us/articles/4410284291213-Manage-projects"
          target="_blank"
          rel="noopener noreferrer">
          Managing Projects
        </a>
        .
      </>
    ) : (
      <>
        Add a name and description for this project. You can change these at any
        time in the Project Settings.
      </>
    );

    return (
      <div>
        <p className="push--bottom">{instructions}</p>
        <div className="push-double--bottom">
          <TextField
            isRequired={true}
            label="Name"
            placeholder="Enter name"
            testSection="project-creator-name"
            type="text"
            value={name}
            onInput={this.onNameInput}
          />
        </div>
        <Textarea
          label="Description"
          placeholder="Enter description"
          testSection="project-creator-description"
          value={description}
          onInput={this.onDescriptionInput}
        />
        <fieldset>
          {shouldShowSecureEnvironmentsOption && (
            <div className="push-double--top">
              <Checkbox
                label="Secure environments"
                data-test-section="secure-environments-checkbox"
                onChange={this.updatePrivateDefaultDatafiles}
                checked={hasPrivateDefaultDatafiles}
              />
              <div className="push-triple--left">
                <p>
                  The default environments for this project will be secure
                  environments and will not be publicly accessible without
                  access tokens.{' '}
                  <Link
                    href="https://docs.developers.optimizely.com/feature-experimentation/docs/manage-environments#secure-environments"
                    newWindow={true}>
                    Learn more.
                  </Link>
                </p>
              </div>
            </div>
          )}
          {!isFeatureEnabled(
            OptimizelyChampagneEnums.FEATURES.disable_legacy_full_stack_creation
              .FEATURE_KEY,
          ) &&
            shouldShowLegacyFullStackProjectOption && (
              <div className="push-double--top">
                <Checkbox
                  label="Use Legacy Experiences"
                  data-test-section="legacy-experience-checkbox"
                  onChange={this.updateUseFullStackLegacyExperience}
                  checked={useFullStackLegacyExperience}
                />
                <div className="push-triple--left">
                  <p>
                    {legacyFullStackText}{' '}
                    <Link href={legacyFullStackLearnMoreLink} newWindow={true}>
                      Learn more.
                    </Link>
                  </p>
                </div>
              </div>
            )}
        </fieldset>
        <div className="border--top soft-double--top">
          <div className="flex flex-align--center">
            <div className="oui-sheet__required-indicator cursor--default color--red">
              <span>* Required field</span>
            </div>
            <div className="flex--1">
              <ButtonRow
                rightGroup={[
                  ...(!isFirstProject ||
                  this.getUnlockedProjectOptions().length > 1
                    ? [
                        <Button
                          key="back"
                          style="plain"
                          onClick={this.onBack}
                          testSection="project-creator-back">
                          Back
                        </Button>,
                      ]
                    : []),
                  <Button
                    key="create"
                    isDisabled={isCreateButtonDisabled}
                    style="highlight"
                    testSection="project-creator-create"
                    onClick={this.onCreate}>
                    Create Project
                  </Button>,
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { selectedPlatform, isLoading } = this.state;

    return (
      <LoadingOverlay isLoading={isLoading}>
        <Wrapper testSection="project-creator">
          <h2 className="weight--light">Create Project</h2>
          {!this.isP13NOnlyAccount() && (
            <p className="push-quad--bottom">
              Use Optimizely to optimize experiences on a variety of platforms.
              Choose the platform which best suits your type of project. For
              more information, see{' '}
              <a
                href="https://help.optimizely.com/Set_Up_Optimizely/Manage_projects_in_Optimizely_X"
                target="_blank"
                rel="noopener noreferrer">
                Managing Projects
              </a>
              .
            </p>
          )}
          {selectedPlatform === null
            ? this.getProjectOptionPlatformCards()
            : this.createProjectScreen()}
        </Wrapper>
      </LoadingOverlay>
    );
  }
}

export default CreateProject;

import keyMirror from 'optly/utils/key_mirror';

const CODE_BLOCK_REACT_WITH_PROPERTIES = `import { withOptimizely } from '@optimizely/react-sdk';
import React from 'react';

class TheComponent extends React.Component {
  onEvent = () => {
    const properties = { 
      %s 
    };
    const tags = { 
      "$opt_event_properties": properties 
    };
    optimizely.track('%s', tags);
  }

  render() {
    return (
      <button onClick={this.onEvent}>Track Event</button>
    );
  }
}

const WrappedComponent = withOptimizely(TheComponent);
export default WrappedComponent;
`;

const CODE_BLOCK_REACT_WITHOUT_PROPERTIES = `import { withOptimizely } from '@optimizely/react-sdk';
import React from 'react';

class TheComponent extends React.Component {
  onEvent = () => {
    optimizely.track('%s');
  }

  render() {
    return (
      <button onClick={this.onEvent}>Track Event</button>
    );
  }
}

const WrappedComponent = withOptimizely(TheComponent);
export default WrappedComponent;
`;

const CODE_BLOCK_AGENT_WITH_PROPERTIES = `curl -X POST \\
  '{HOST_URL}/v1/track?eventKey=%s' \\
  -H 'Content-Type: application/json' \\
  -H 'X-Optimizely-SDK-Key: "YOUR-SDK-KEY"' \\
  -d '{
    "userId": "USER_ID",
    "eventTags": {
      "$opt_event_properties": {
      %s
      }
    }
  }'
`;

const CODE_BLOCK_AGENT_WITHOUT_PROPERTIES = `curl -X POST \\
  '{HOST_URL}/v1/track?eventKey=%s' \\
  -H 'Content-Type: application/json' \\
  -H 'X-Optimizely-SDK-Key: "YOUR-SDK-KEY"' \\
  -d '{
    "userId": "USER_ID"
  }'
`;


const PYTHON_CODE_BLOCK_WITH_PROPERTIES = `properties = { 
%s 
}
tags = { 
  "$opt_event_properties": properties 
}
user = optimizely_client.create_user_context(user_id, attributes)
user.track_event("%s", tags)
`;

const PYTHON_CODE_BLOCK_WITHOUT_PROPERTIES = `user = optimizely_client.create_user_context(user_id, attributes)
user.track_event("%s")
`;

const JAVA_CODE_BLOCK_WITH_PROPERTIES = `OptimizelyUserContext user = optimizelyClient.createUserContext(userId, attributes);

Map<String, Object> properties = new HashMap<>();
%s

Map<String, Object> tags = new HashMap<>();
tags.put("$opt_event_properties", properties);

user.trackEvent("%s", tags);
`;

const JAVA_CODE_BLOCK_WITHOUT_PROPERTIES = `OptimizelyUserContext user = optimizelyClient.createUserContext(userId, attributes);

user.trackEvent("%s");
`;

const JAVASCRIPT_CODE_BLOCK_WITH_PROPERTIES = `const user = optimizelyClientInstance.createUserContext(userId, attributes);
const properties = { 
%s 
};
const tags = { 
  "$opt_event_properties": properties 
};
user.trackEvent('%s', tags);
`;

const JAVASCRIPT_CODE_BLOCK_WITHOUT_PROPERTIES = `const user = optimizelyClientInstance.createUserContext(userId, attributes);
user.trackEvent('%s');
`;

const CSHARP_CODE_BLOCK_WITH_PROPERTIES = `OptimizelyUserContext user = optimizelyClientInstance.CreateUserContext(userId, attributes);

var properties = new Dictionary<string, object>()
{
  %s
};

var tags = new EventTags
{
  { "$opt_event_properties", properties }
};

user.TrackEvent("%s", tags);
`;


const CSHARP_CODE_BLOCK_WITHOUT_PROPERTIES = `OptimizelyUserContext user = optimizelyClientInstance.CreateUserContext(userId, attributes);

user.TrackEvent("%s");
`;

const SWIFT_CODE_BLOCK_WITH_PROPERTIES = `let user = optimizelyClient.createUserContext(userId: userId, attributes: attributes)
let properties: [String: Any] = [
%s
]
let tags: [String: Any] = [
  "$opt_event_properties": properties
]
try? user.trackEvent(eventKey: "%s", tags: tags)
`;

const SWIFT_CODE_BLOCK_WITHOUT_PROPERTIES = `let user = optimizelyClient.createUserContext(userId: userId, attributes: attributes)

try? user.trackEvent(eventKey: "%s")
`;

const PHP_CODE_BLOCK_WITH_PROPERTIES = `<?php
$user = $optimizelyClient->createUserContext($userId, $attributes);
$properties = [
%s
];
$tags = [
  "$opt_event_properties" => $properties
];
$user->trackEvent('%s', $tags);
?>
`;

const PHP_CODE_BLOCK_WITHOUT_PROPERTIES = `<?php
$user = $optimizelyClient->createUserContext($userId, $attributes);
$user->trackEvent('%s');
?>
`;

const RUBY_CODE_BLOCK_WITH_PROPERTIES = `properties = { 
%s 
}
tags = { 
  "$opt_event_properties" => properties 
}
user = optimizely_client.create_user_context(user_id, attributes)
user.track_event("%s", tags)
`;

const RUBY_CODE_BLOCK_WITHOUT_PROPERTIES = `user = optimizely_client.create_user_context(user_id, attributes)
user.track_event("%s")
`;

const GO_CODE_BLOCK_WITH_PROPERTIES = `user := optimizelyClientInstance.CreateUserContext(userId, attributes)

properties := map[string]interface{}{
%s
}

tags := map[string]interface{}{
    "$opt_event_properties": properties,
}

user.TrackEvent("%s", tags)
`;

const GO_CODE_BLOCK_WITHOUT_PROPERTIES = `user := optimizelyClientInstance.CreateUserContext(userId, attributes)

user.TrackEvent("%s")
`;

const FLUTTER_CODE_BLOCK_WITH_PROPERTIES = `Map<String, dynamic> properties = {
%s
};
Map<String, dynamic> tags = {
  "\$opt_event_properties": properties
};
var user = await optimizelyClientInstance.createUserContext(userId, attributes);
user.trackEvent("%s", tags: tags);
`;

const FLUTTER_CODE_BLOCK_WITHOUT_PROPERTIES = `var user = await optimizelyClientInstance.createUserContext(userId, attributes);
user.trackEvent("%s");
`;

const CODE_BLOCK_OBJECTIVEC_WITH_PROPERTIES = `OptimizelyUserContext *user = [optimizelyClient createUserContextWithUserId:userId attributes:attributes];
NSDictionary *properties = @{
%s
};
NSDictionary *tags = @{
  @"$opt_event_properties": properties
};
[user trackEvent:@"%s" tags:tags];
`;

const CODE_BLOCK_OBJECTIVEC_WITHOUT_PROPERTIES = `OptimizelyUserContext *user = [optimizelyClient createUserContextWithUserId:userId attributes:attributes];
[user trackEvent:@"%s"];
`;

export const CODE_BLOCKS_WITH_PROPERTIES = {
  android: JAVA_CODE_BLOCK_WITH_PROPERTIES,
  android_tv: JAVA_CODE_BLOCK_WITH_PROPERTIES,
  csharp: CSHARP_CODE_BLOCK_WITH_PROPERTIES,
  flutter: FLUTTER_CODE_BLOCK_WITH_PROPERTIES,
  go: GO_CODE_BLOCK_WITH_PROPERTIES,
  html: CODE_BLOCK_AGENT_WITH_PROPERTIES,
  java: JAVA_CODE_BLOCK_WITH_PROPERTIES,
  javascript: JAVASCRIPT_CODE_BLOCK_WITH_PROPERTIES,
  jsx: CODE_BLOCK_REACT_WITH_PROPERTIES,
  node: JAVASCRIPT_CODE_BLOCK_WITH_PROPERTIES,
  objectivec: CODE_BLOCK_OBJECTIVEC_WITH_PROPERTIES,
  php: PHP_CODE_BLOCK_WITH_PROPERTIES,
  python: PYTHON_CODE_BLOCK_WITH_PROPERTIES,
  ruby: RUBY_CODE_BLOCK_WITH_PROPERTIES,
  swift: SWIFT_CODE_BLOCK_WITH_PROPERTIES,
  tv_os: CODE_BLOCK_OBJECTIVEC_WITH_PROPERTIES,
};

export const CODE_BLOCKS_WITHOUT_PROPERTIES = {
  android: JAVA_CODE_BLOCK_WITHOUT_PROPERTIES,
  android_tv: JAVA_CODE_BLOCK_WITHOUT_PROPERTIES,
  csharp: CSHARP_CODE_BLOCK_WITHOUT_PROPERTIES,
  flutter: FLUTTER_CODE_BLOCK_WITHOUT_PROPERTIES,
  go: GO_CODE_BLOCK_WITHOUT_PROPERTIES,
  html: CODE_BLOCK_AGENT_WITHOUT_PROPERTIES,
  java: JAVA_CODE_BLOCK_WITHOUT_PROPERTIES,
  javascript: JAVASCRIPT_CODE_BLOCK_WITHOUT_PROPERTIES,
  jsx: CODE_BLOCK_REACT_WITHOUT_PROPERTIES,
  node: JAVASCRIPT_CODE_BLOCK_WITHOUT_PROPERTIES,
  objectivec: CODE_BLOCK_OBJECTIVEC_WITHOUT_PROPERTIES,
  php: PHP_CODE_BLOCK_WITHOUT_PROPERTIES,
  python: PYTHON_CODE_BLOCK_WITHOUT_PROPERTIES,
  ruby: RUBY_CODE_BLOCK_WITHOUT_PROPERTIES,
  swift: SWIFT_CODE_BLOCK_WITHOUT_PROPERTIES,
  tv_os: CODE_BLOCK_OBJECTIVEC_WITHOUT_PROPERTIES,
};

export const TABLE_IDS = keyMirror({
  EVENTS: null,
});

export const TABS = keyMirror({
  EVENTS: null,
});

export default {
  TABLE_IDS,
  TABS,
  CODE_BLOCKS_WITH_PROPERTIES,
  CODE_BLOCKS_WITHOUT_PROPERTIES,
};

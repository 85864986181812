import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Store({
  /**
   * This store captures the state of localstorage data we manage for p13n/AB v2 entities from entity cache
   * entries is an Immutable.Map of cached entities that we store in localStorage for p13n
   * lruKeyList : is an immutable list of localstorage keys that we manipulate to phase out lru localstorage entities
   */
  getInitialState() {
    return toImmutable({
      entries: {},
      lruKeyList: [],
    });
  },

  initialize() {
    this.on(
      actionTypes.LOCAL_STORAGE_META_LOAD_STORE,
      loadLocalStorageMetaItem,
    );
    this.on(actionTypes.LOCAL_STORAGE_META_ADD_ITEM, addLocalStorageMetaItem);
    this.on(
      actionTypes.LOCAL_STORAGE_META_REMOVE_ITEM,
      removeLocalStorageMetaItem,
    );
    this.on(
      actionTypes.LOCAL_STORAGE_META_UPDATE_ITEM,
      updateLocalStorageMetaItem,
    );
    this.on(
      actionTypes.LOCAL_STORAGE_META_CLEAR_STORE,
      clearLocalStorageMetaStore,
    );
  },
});

/**
 * payload.key
 * payload.size
 * payload.dateModified
 * @param {Immutable.Map} state
 * @param {object} payload
 */
function addLocalStorageMetaItem(state, payload) {
  const { key } = payload;
  const { size } = payload;
  const { dateModified } = payload;
  if (!key || size === undefined || !dateModified) {
    throw Error('Parameter missing to set item in local storage meta store');
  }
  const data = {
    dateModified,
    size,
  };
  return state
    .setIn(['entries', key], toImmutable(data))
    .update('lruKeyList', keyList =>
      keyList.filter(lruKey => lruKey !== key).push(key),
    );
}

/**
 * payload.key
 * @param {Immutable.Map} state
 * @param {object} payload
 */
function removeLocalStorageMetaItem(state, payload) {
  const { key } = payload;
  if (!key) {
    throw Error('Undefined key to remove from LocalStorage');
  }
  return state
    .removeIn(['entries', key])
    .update('lruKeyList', keyList => keyList.filter(lruKey => lruKey !== key));
}

/**
 * Update store when managed item is read from localstorage
 * @param state
 * @param payload.key
 * @returns {*}
 */
function updateLocalStorageMetaItem(state, payload) {
  const { key } = payload;
  const { dateModified } = payload;
  if (!key || !dateModified) {
    throw Error('Parameter missing to update local storage meta store');
  }
  return state
    .setIn(['entries', key, 'dateModified'], payload.dateModified)
    .update('lruKeyList', keyList =>
      keyList.filter(lruKey => lruKey !== key).push(key),
    );
}
/**
 * Clear the state of the meta store
 * @param state
 * @returns {*}
 */
function clearLocalStorageMetaStore(state) {
  return state
    .set('entries', toImmutable({}))
    .set('lruKeyList', toImmutable([]));
}
/**
 * load the prior meta store from localstorage into the flux store
 * @param state
 * @param payload
 */
function loadLocalStorageMetaItem(state, payload) {
  const entries = payload.entries || {};
  const lruKeyList = payload.lruKeyList || [];
  return state
    .set('entries', toImmutable(entries))
    .set('lruKeyList', toImmutable(lruKeyList));
}

// TODO(jordan): move this module into p13n/sections/views/
import actions from './actions';
import fns from './fns';
import getters from './getters';

export { actions, fns, getters };

export default {
  actions,
  fns,
  getters,
};

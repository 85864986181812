import { partial, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from 'optimizely-oui';

import { enums as ViewEnums } from 'optly/modules/entity/view';

import ConditionGroup from 'optly/models/condition_group';

import ConditionCardHeader from '../card_header';

/*
 * Component to display a element_present condition for a view
 * This component expects that props.condition.conditions
 * and props.errors have lengths of exactly 1
 */
const ElementPresent = props => {
  const onChangeCSSSelector = event => {
    const newErrors = cloneDeep(props.errors);
    newErrors[0] = null;
    props.updateErrors(newErrors);

    props.condition.conditions[0].value = event.target.value;
    props.onConditionChange();
  };

  const error = props.errors[0];

  return (
    <div
      className="border--all"
      data-test-section="condition-card-element-present">
      <ConditionCardHeader
        showMultipleURLsMessage={false}
        onClose={partial(props.onConditionChange, props.condition)}
        title="Element is present on page"
      />
      <div className="soft" data-test-section="element-present-card-conditions">
        <TextField
          name="edit_url"
          label="CSS Selector"
          isRequired={true}
          placeholder="Enter CSS selector here"
          value={props.condition.conditions[0].value}
          type="text"
          displayError={!!error}
          onChange={onChangeCSSSelector}
          testSection="element-present-css-selector"
        />
        {error ? (
          <div
            className="color--bad-news micro push-half--top"
            data-test-section="element-present-error">
            {error}
          </div>
        ) : (
          <div className="micro muted push-half--top">
            Use multiple selectors by separating each with a comma.
          </div>
        )}
      </div>
    </div>
  );
};

ElementPresent.propTypes = {
  condition: PropTypes.instanceOf(ConditionGroup).isRequired,
  onConditionChange: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
};

ElementPresent.defaultProps = {
  errors: [],
};

ElementPresent.matchType = ViewEnums.conditionMatchTypes.ELEMENT_PRESENT;

export default ElementPresent;

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Immutable, { isImmutable } from 'optly/immutable';

/**
 * @typedef {Object} FormInterface
 * @property {function} getValue() - Returns Immutable.Map() value of the form editing state
 * @property {function} setValue(Immutable.Map) - Sets Immutable.Map() value of the form editing state
 * @property {function} getErrors() - Gets object containing error information for the form
 * @property {function} revert() - Resets the form editing state to the form clean state
 * @property {function} validators(Object) - Attach validators at the form level
 * @property {function} validate() - Perform validation on the entire form
 * @property {function} field(String) - Get a standalone field interface for the provided keypath
 * @property {function} repeatedField(String) - Get a repeated field interface for the provided keypath
 * @property {function} isFormValid() - Returns whether validation is failing or passing
 * @property {function} isFormDirty() - Returns whether the editing and clean states are matching
 */

/**
 * @typedef {Object} StandaloneFieldInterface
 * @property {function} getValue() - Returns value of the field at the field interface keypath
 * @property {function} setValue(Any) - Sets the value of the field at the field interface keypath
 * @property {function} getErrors() - Gets the error object for the field at the field interface keypath
 * @property {function} validate() - Gets the error object for the field at the field interface keypath
 * @property {function} validators(Object) - Attaches validators to the field at the field interface keypath
 * @property {function} field(String) - Returns a standalone field wrapper as a child of the field interface keypath
 * @property {function} repeatedField(String) - Returns a repeated field wrapper as a child of the field interface keypath
 */

/**
 * @typedef {Object} RepeatedParentFieldInterface
 * @property {function} getValue() - Returns value of the field at the field interface keypath
 * @property {function} setValue(Any) - Sets the value of the field at the field interface keypath
 * @property {function} getErrors() - Gets the error object for the field at the field interface keypath
 * @property {function} validate() - Gets the error object for the field at the field interface keypath
 * @property {function} validators(Object) - Attaches validators to the field at the field interface keypath
 * @property {function} field(String) - Returns a standalone field wrapper as a child of the field interface keypath
 * @property {function} repeatedField(String) - Returns a repeated field wrapper as a child of the field interface keypath
 * @property {function} setupRepeatedValidators(String, Object) - Attaches validators to all children containing the relative keypath
 * @property {function} getChildField(Number) - Returns the child field interface of the child field at the specified index
 * @property {function} mapChildren(function) - Maps over all children of the repeated parent field, returns mapped result
 * @property {function} pushChild(Any) - Pushes an item of any type to the repeated parent field, triggers revalidation of the parent
 * @property {function} removeChild(Number) - Removes an item at the specified index from the repeated parent field
 * @property {function} swapChildValues(Number,Number) - Swaps the values of the repeated child fields at the specified indexes
 */

/**
 * @typedef {Object} RepeatedChildFieldInterface
 * @property {function} getValue() - Returns value of the field at the field interface keypath
 * @property {function} setValue(Any) - Sets the value of the field at the field interface keypath
 * @property {function} getErrors() - Gets the error object for the field at the field interface keypath
 * @property {function} validate() - Gets the error object for the field at the field interface keypath
 * @property {function} field(String) - Returns a standalone field wrapper as a child of the field interface keypath
 * @property {function} repeatedField(String) - Returns a repeated field wrapper as a child of the field interface keypath
 * @property {function} remove() - Removes the repeated child field from its repeated parent field
 */

/**
 * @private
 * @method
 * @name makeCancelablePromise
 * @description Wraps a promise such that it can be cancelled when the form
 *              component is unmounted. Used to cancel pending validation
 *              promises to prevent post-unmount updates, thereby preventing
 *              React from throwing a JS error in the console.
 *
 * @param {Promise} promise - promise to wrap
 *
 * @returns {Object} - cancelable promise and cancellation method
 */
const makeCancelablePromise = promise => {
  let hasCanceled = false;
  const cancellationError = new Error(
    'Form validation completed after form component unmounted.',
  );

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled ? reject(cancellationError) : resolve(val)),
      error => (hasCanceled ? reject(cancellationError) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};

/**
 * @public
 * @function
 * @name Form
 * @description Creates a decorator function for wrapping components in a Form HOC
 *
 * @param {Object} options - options to configure the returned HOC
 * @param {Boolean} options.validateOnChange - optional, whether to validate each time a field is updated
 * @param {Number} options.debounceTimeout - optional, time (in ms) to debounce validation calls
 * @param {Function} options.mapPropsToFormData - function that accepts props, returns Immutable.Map of form state
 *
 * @returns {Function} - Decorator which wraps the passed component with a form HOC
 */
export function Form({
  validateOnChange = true,
  debounceTimeout = 300,
  mapPropsToFormData,
  // TODO(jordan): add _.map or _.omit functionality here (jess says whitelist is better)
}) {
  // Throw error if required option "mapPropsToFormData" is not supplied
  if (!mapPropsToFormData) {
    throw new Error('mapPropsToFormData is required in @Form() decorator.');
  }

  // Return a decorator function which wraps a passed component in a form HOC
  return function(Component) {
    /**
     * @namespace {React.Component} FormComponent
     */
    class FormComponent extends React.Component {
      static propTypes = {
        clean: PropTypes.instanceOf(Immutable.Map),
      };

      static defaultProps = {
        clean: Immutable.Map(),
      };

      constructor(props) {
        super(props);

        // Generate the clean state using the provided mapper function
        const initialCleanState = mapPropsToFormData(props);

        /**
         * @protected
         * @member {Object}
         * @name formState
         * @description Synchronously-accessible, internally-managed form state
         * @memberof FormComponent
         * @instance
         */
        this.formState = {
          editing: initialCleanState,
          clean: initialCleanState,
          isDirty: false,
          errors: Immutable.Map(),
          isFormValid: true,
        };

        this.formOptions = {
          validateOnChange,
          debounceTimeout,
          mapPropsToFormData,
        };

        /**
         * @protected
         * @member {Object}
         * @name state
         * @description Asynchronously-accessible, React-managed form state
         * @memberof FormComponent
         * @instance
         */
        this.state = this.formState;

        /**
         * @protected
         * @member {Immutable.Map}
         * @name fieldWrappers
         * @description Store of field interface wrappers, keyed with flattened keypaths
         * @memberof FormComponent
         * @instance
         */
        this.fieldWrappers = Immutable.Map();

        /**
         * @protected
         * @member {Immutable.Map}
         * @name debounceValidationFns
         * @description Store of debounced, keypath-bound hooks for `validateKeyPath`, keyed with flattened keypaths
         * @memberof FormComponent
         * @instance
         */
        this.debounceValidationFns = Immutable.Map();

        /**
         * @protected
         * @member {Immutable.Map}
         * @name validationConfig
         * @description Store of validation config objects, keyed with flattened keypaths
         * @memberof FormComponent
         * @instance
         */
        this.validationConfig = Immutable.Map();

        /**
         * @protected
         * @member {Object}
         * @name cancelableValidationDefs
         * @description Store of references to pending cancelable promises, keyed by flattened keypaths
         * @memberof FormComponent
         * @instance
         */
        this.cancelableValidationDefs = {};

        /**
         * @protected
         * @member {FormInterface}
         * @name form
         * @description Top-level interface provided to the wrapped child component
         * @memberof FormComponent
         * @instance
         */
        this.form = {
          getValue: this.getFormValue,
          setValue: this.setValue,
          setOptions: this.setFormOptions,
          getErrors: this.getErrors.bind(this, '*'),
          revert: this.revert,
          validators: this.attachValidatorsToStandaloneField.bind(this, '*'),
          validate: this.validateForm,
          field: this.createOrGetStandaloneFieldWrapper,
          repeatedField: this.createOrGetRepeatedParentFieldWrapper,
          isFormValid: this.isFormValid,
          isFormDirty: this.isFormDirty,
        };
      }

      /**
       * @protected
       * @method
       * @name flattenKeyPath
       * @description Converts a keypath array into a string
       * @memberof FormComponent
       *
       * @param {Array} keyPath - array of key path segments
       *
       * @returns {String} - dot-separated string representing keypath
       */
      flattenKeyPath = keyPath => {
        // For non-empty keypaths, return a dot-separated keypath string
        if (keyPath.length > 0) {
          return keyPath.join('.');
        }

        // For empty (root) keypaths, return a star
        return '*';
      };

      /**
       * @protected
       * @method
       * @name unflattenKeyPath
       * @description Converts a string into a keypath array
       * @memberof FormComponent
       *
       * @param {String} keyPathString - dot-separated string represending keypath
       *
       * @returns {String} - array of key path segments
       */
      unflattenKeyPath = keyPathString => {
        // If the keypath string corresponds to the root node, return root keypath array
        if (keyPathString === '*') {
          return [];
        }

        // If the keypath is a numeric index, do not attempt to split
        if (Number.isInteger(keyPathString)) {
          return keyPathString;
        }

        // If the keypath corresponds to a non-root node, return an array split by dot
        return keyPathString.split('.');
      };

      /**
       * @protected
       * @method
       * @name setFormState
       * @description Merges changes into form (sync) and component (async) state
       * @memberof FormComponent
       *
       * @param {Object} updatedState - form state properties and values to update
       *
       * @returns {Promise} Resolves after new state is set
       */
      setFormState = updatedState => {
        this.formState = Object.assign({}, this.formState, updatedState);
        return new Promise(resolve => {
          this.setState(this.formState, () => resolve());
        });
      };

      /**
       * @protected
       * @method
       * @name revert
       * @description Resets the editing state to the current clean state
       * @memberof FormComponent
       *
       * @returns {Promise} Resolves after new state is set
       */
      revert = () =>
        this.setFormState({
          editing: this.formState.clean,
          isDirty: false,
          errors: Immutable.Map(),
          isFormValid: true,
        });

      /**
       * @protected
       * @method
       * @name isFormValid
       * @description Determines the validity of the form editing state.
       *              Used by the form interface in the component constructor.
       * @memberof FormComponent
       *
       * @returns {Boolean} - whether the form is valid
       */
      isFormValid = () => this.formState.isFormValid;

      /**
       * @protected
       * @method
       * @name isFormDirty
       * @description Determines the dirtiness of the form editing state.
       *              Used by the form interface in the component constructor.
       * @memberof FormComponent
       *
       * @returns {Boolean} - whether the form state is dirty
       */
      isFormDirty = () => this.formState.isDirty;

      /**
       * @protected
       * @method
       * @name getFormValue
       * @description Gets the editing value of the form from the form state.
       *              Used by the form interface in the component constructor.
       * @memberof FormComponent
       *
       * @returns {Immutable.Map} - form editing state
       */
      getFormValue = () => this.formState.editing;

      /**
       * @protected
       * @method
       * @name setFormOptions
       * @description Updates the form options initially provided through the
       *              Form decorator options argument by merging in new options.
       * @memberof FormComponent
       *
       * @param {Object} - object of options to update to merge into current options
       */
      setFormOptions = nextOptions => {
        const prevOptions = this.formOptions;

        this.formOptions = {
          ...this.formOptions,
          ...nextOptions,
        };

        // If the debounce timeout is changed, cached debounced validators need to be recreated
        if (
          nextOptions.debounceTimeout &&
          nextOptions.debounceTimeout !== prevOptions.debounceTimeout
        ) {
          this.purgeDebouncedValidationFnCache();
        }
      };

      /**
       * @protected
       * @method
       * @name createBaseFieldWrapper
       * @description Creates a basic field wrapper which provides the basic
       *              field wrapper methods for the specified keypath string.
       *              This function is used by other field wrapper generator
       *              methods to extend the basic field interface.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - key path for field
       *
       * @returns {StandaloneFieldInterface} - basic field interface object
       */
      createBaseFieldWrapper = keyPathString => {
        const keyPath = this.unflattenKeyPath(keyPathString);
        return {
          getValue: this.getFieldValue.bind(this, keyPathString),
          setValue: val => {
            this.update(keyPathString, val);
            return this.createOrGetStandaloneFieldWrapper(keyPathString);
          },
          getErrors: this.getErrors.bind(this, keyPathString),
          validate: () => this.validateKeyPath(keyPathString),
          field: relativeKeyPathString => {
            const relativeKeyPath = this.unflattenKeyPath(
              relativeKeyPathString,
            );
            return this.createOrGetStandaloneFieldWrapper(
              this.flattenKeyPath([...keyPath, ...relativeKeyPath]),
            );
          },
          repeatedField: relativeKeyPathString => {
            const relativeKeyPath = this.unflattenKeyPath(
              relativeKeyPathString,
            );
            return this.createOrGetRepeatedParentFieldWrapper(
              this.flattenKeyPath([...keyPath, ...relativeKeyPath]),
            );
          },
          isDirty: this.getIsFieldDirty.bind(this, keyPathString),
        };
      };

      /**
       * @protected
       * @method
       * @name createOrGetStandaloneFieldWrapper
       * @description Gets or creates/stores a basic field wrapper which
       *              provides the basic field wrapper methods for the
       *              specified keypath string.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath to the field
       *
       * @returns {StandaloneFieldInterface} - helper functions to use with the specified field
       */
      createOrGetStandaloneFieldWrapper = keyPathString => {
        if (!this.fieldWrappers.hasIn(keyPathString)) {
          const wrapper = this.createBaseFieldWrapper(keyPathString);
          wrapper.validators = (validators, validationOptions) => {
            this.attachValidatorsToStandaloneField(
              keyPathString,
              validators,
              validationOptions,
            );
            return wrapper;
          };
          this.fieldWrappers = this.fieldWrappers.set(keyPathString, wrapper);
        }

        return this.fieldWrappers.get(keyPathString);
      };

      /**
       * @protected
       * @method
       * @name createOrGetRepeatedChildFieldWrapper
       * @description Gets or creates/stores a repeated child field wrapper
       *              which provides the same methods as the basic field
       *              wrapper as well as a function for removing the field at
       *              the specified keypath string from its parent. This field
       *              wrapper also blocks standalone validators from being
       *              registered to a direct child of a repeated parent.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath to the field
       *
       * @returns {RepeatedChildFieldInterface} - helper functions to use with the specified field
       */
      createOrGetRepeatedChildFieldWrapper = keyPathString => {
        if (!this.fieldWrappers.hasIn(keyPathString)) {
          let wrapper = this.createBaseFieldWrapper(keyPathString);

          // Extend the base wrapper: add a "remove" function
          wrapper = _.assign(wrapper, {
            remove: () => {
              const keyPath = this.unflattenKeyPath(keyPathString);
              const parentKeyPath = keyPath.slice(0, -1);
              const childKeyPath = Number(keyPath[keyPath.length - 1]);
              this.removeFieldFromRepeatedParent(parentKeyPath, childKeyPath);
            },
            validators: (validators, validationOptions) => {
              this.attachValidatorsToStandaloneField(
                keyPathString,
                validators,
                validationOptions,
              );
              return wrapper;
            },
          });

          // Extend the base wrapper: delete the "validators" function
          wrapper = _.omit(wrapper, 'validators');

          this.fieldWrappers = this.fieldWrappers.set(keyPathString, wrapper);
        }

        return this.fieldWrappers.get(keyPathString);
      };

      /**
       * @protected
       * @method
       * @name createOrGetRepeatedParentFieldWrapper
       * @description Gets or creates/stores a repeated parent field wrapper
       *              which provides the same methods as the basic field
       *              wrapper as well as functions for validating, mapping
       *              over, and managing descendant fields.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath to the field
       *
       * @returns {RepeatedParentFieldInterface} - helper functions to use with the specified field
       */
      createOrGetRepeatedParentFieldWrapper = keyPathString => {
        const keyPath = this.unflattenKeyPath(keyPathString);

        if (!this.fieldWrappers.hasIn(keyPathString)) {
          let wrapper = this.createBaseFieldWrapper(keyPathString);

          // Extend the base field wrapper: add new functions
          wrapper = _.assign(wrapper, {
            getChildField: this.getChildWrapperInRepeatedField.bind(
              this,
              keyPathString,
            ),
            mapChildren: this.mapItemsInRepeatedField.bind(this, keyPathString),
            filterChildren: this.filterItemsInRepeatedField.bind(
              this,
              keyPathString,
            ),
            pushChild: this.addItemToRepeatedField.bind(this, keyPathString),
            removeChild: this.removeFieldFromRepeatedParent.bind(this, keyPath),
            swapChildValues: this.swapItemsInRepeatedField.bind(
              this,
              keyPathString,
            ),
            setupRepeatedValidators: this.setupRepeatedValidators.bind(
              this,
              keyPathString,
            ),
            validators: (validators, validationOptions) => {
              this.attachValidatorsToStandaloneField(
                keyPathString,
                validators,
                validationOptions,
              );
              return wrapper;
            },
          });

          this.fieldWrappers = this.fieldWrappers.set(keyPathString, wrapper);
        }

        return this.fieldWrappers.get(keyPathString);
      };

      /**
       * @protected
       * @method
       * @name getDebounceTimeoutForField
       * @description Gets the debounce timeout configured for a field.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath to a field
       *
       * @returns {Number} amount of milliseconds to wait during debounce
       */
      getDebounceTimeoutForField = keyPathString => {
        if (
          this.validationConfig.hasIn([
            keyPathString,
            'standaloneValidationOptions',
            'debounceTimeout',
          ])
        ) {
          return this.validationConfig.getIn([
            keyPathString,
            'standaloneValidationOptions',
            'debounceTimeout',
          ]);
        }
        return this.formOptions.debounceTimeout;
      };

      /**
       * @protected
       * @method
       * @name getDebouncedValidationFn
       * @description Gets or creates/stores a debounce wrapper for the
       *              validation function call for the specified keypath
       *              string. Used when validators are executed on-update.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath to the field
       */
      getDebouncedValidationFn = keyPathString => {
        if (!this.debounceValidationFns.has(keyPathString)) {
          const debounceTimeoutForField = this.getDebounceTimeoutForField(
            keyPathString,
          );
          this.debounceValidationFns = this.debounceValidationFns.set(
            keyPathString,
            _.debounce(() => {
              this.validateKeyPath(keyPathString);
            }, debounceTimeoutForField),
          );
        }

        return this.debounceValidationFns.get(keyPathString);
      };

      /**
       * @protected
       * @method
       * @name purgeDebouncedValidationFnCache
       * @description Purges the cache of debounced validation functions.
       * @memberof FormComponent
       */
      purgeDebouncedValidationFnCache = () => {
        this.debounceValidationFns = Immutable.Map();
      };

      /**
       * @protected
       * @method
       * @name getFieldValue
       * @description Gets the value of the field at the specified keypath
       *              string. Used by the basic field wrapper.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath for the field
       *
       * @returns {Any|Immutable.Map} - value of the field
       */
      getFieldValue = keyPathString => {
        const keyPath = this.unflattenKeyPath(keyPathString);
        return this.formState.editing.getIn(keyPath);
      };

      /**
       * @protected
       * @method
       * @name getIsFieldDirty
       * @description Determines if the field is dirty
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath for the field
       *
       * @returns {Boolean} - whether or not the field is dirty
       */
      getIsFieldDirty = keyPathString => {
        const keyPath = this.unflattenKeyPath(keyPathString);
        const cleanValue = this.formState.clean.getIn(keyPath);
        const dirtyValue = this.getFieldValue(keyPathString);

        return !Immutable.is(cleanValue, dirtyValue);
      };

      /**
       * @protected
       * @method
       * @name getErrors
       * @description Gets the error object for the field at the specified
       *              keypath string. Used by the basic field wrapper.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath for the field
       *
       * @returns {Object} - error object for a field
       */
      getErrors = keyPathString => {
        /**
         * Gets error details from all descendant fields of the field at the
         * specified keypath and shallowly convert to a POJO. Defaults to {}.
         *
         * Please Note: .toJS() cannot be used here. Validators can return
         * immutable results which cannot be converted to POJOs. Therefore,
         * in order to only convert the top-level child errors object to a
         * POJO, .toObject() is used instead.
         */
        const childErrors = this.formState.errors
          .filter((errorObj, errorKey) => {
            const isDescendant =
              (errorKey.startsWith(keyPathString) || keyPathString === '*') &&
              errorKey !== keyPathString;
            return isDescendant && errorObj.size > 0;
          })
          .map(value => value.toObject())
          .toObject();

        /**
         * Gets error details from the field at the specified keypath and
         * shallowly convert to a POJO. Defaults to {}.
         *
         * Please Note: .toJS() cannot be used here. Validators can return
         * immutable results which cannot be converted to POJOs. Therefore,
         * in order to only convert the top-level child errors object to a
         * POJO, .toObject() is used instead.
         */
        const details = this.formState.errors
          .get(keyPathString, Immutable.Map())
          .toObject();

        // Determines whether any errors exist on the current or descendant fields
        const hasError =
          Object.keys(childErrors).length > 0 ||
          Object.keys(details).length > 0;

        // Gets the bottom-most string-typed validator result for the message
        const message = this.formState.errors
          .get(keyPathString, Immutable.Map())
          .reduce((currentMessage, currentError) => {
            if (_.isString(currentError)) {
              return currentError;
            }
            return currentMessage;
          }, '');

        return {
          hasError,
          message,
          details,
          childErrors,
        };
      };

      /**
       * @protected
       * @method
       * @name attachValidatorsToStandaloneField
       * @description Registers validator functions to be applied to a single
       *              field at the specified keypath string. Also registers
       *              dependent validation configuration. Used by the basic
       *              field wrapper interface.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath string for the field to validate
       * @param {Object} newValidationFns - map of error keys to validator functions for field
       * @param {Object} options - optional, map of validation options
       * @param {Array} options.dependsOnFields - list of fields which, when updated, should revalidate this field
       * @param {boolean} options.validateOnChange - whether to validate this field on change (overrides global option)
       * @param {boolean} options.debounceTimeout - how long to debounce input validation (overrides global option)
       */
      attachValidatorsToStandaloneField = (
        keyPathString,
        newValidationFns,
        options = {},
      ) => {
        const {
          dependsOnFields,
          validateOnChange: validateKeyPathOnChange,
          debounceTimeout: debounceTimeoutForField,
        } = options;

        this.validationConfig = this.validationConfig.mergeIn(
          [keyPathString, 'standalone'],
          newValidationFns,
        );

        if (validateKeyPathOnChange) {
          this.validationConfig = this.validationConfig.setIn(
            [keyPathString, 'standaloneValidationOptions', 'validateOnChange'],
            validateKeyPathOnChange,
          );
        }

        if (debounceTimeoutForField) {
          this.validationConfig = this.validationConfig.setIn(
            [keyPathString, 'standaloneValidationOptions', 'debounceTimeout'],
            debounceTimeoutForField,
          );
        }

        if (dependsOnFields) {
          dependsOnFields.forEach(dependsOnKeyPathString => {
            this.validationConfig = this.validationConfig.setIn(
              [dependsOnKeyPathString, 'validationDependencies'],
              this.validationConfig
                .getIn(
                  [dependsOnKeyPathString, 'validationDependencies'],
                  Immutable.Set(),
                )
                .add(keyPathString),
            );
          });
        }
      };

      /**
       * @protected
       * @method
       * @name addItemToRepeatedField
       * @description Adds an item with the specified value to the end of a
       *              list managed by the repeated parent field corresponding
       *              to the sepcified keypath string. Used by the repeated
       *              field wrapper.
       * @memberof FormComponent
       *
       * @param {Array} keyPath - keypath string for the field to receive the new item
       * @param {Any} itemValue - item to add to the repeated field
       *
       * @returns {Object} - repeated child field wrapper for new item
       */
      addItemToRepeatedField = (keyPathString, itemValue) => {
        const keyPath = this.unflattenKeyPath(keyPathString);
        const updatedValue = this.formState.editing
          .getIn(keyPath, Immutable.List())
          .push(itemValue);
        this.update(keyPathString, updatedValue, true);
      };

      /**
       * @protected
       * @method
       * @name removeFieldFromRepeatedParent
       * @description Removes the value of the item at the specified index
       *              within the repeated parent field at the specified
       *              keypath string. Used by the repeated parent field wrapper.
       * @memberof FormComponent
       *
       * @param {Array} keyPath - keypath of the field from which to remove an item
       * @param {Number} itemIndex - index of item in field to remove
       */
      removeFieldFromRepeatedParent = (parentKeyPath, itemIndex) => {
        const itemKeyPath = [...parentKeyPath, itemIndex];
        const itemKeyPathString = this.flattenKeyPath(itemKeyPath);

        // Remove all field wrappers and validators of child field and descendants
        this.fieldWrappers = this.fieldWrappers.filter(
          (wrapper, wrapperKey) => !wrapperKey.startsWith(itemKeyPathString),
        );

        this.validationConfig = this.validationConfig.filter(
          (config, configKey) => !configKey.startsWith(itemKeyPathString),
        );

        let nextErrors = this.formState.errors.filter(
          (error, errorKey) => !errorKey.startsWith(itemKeyPathString),
        );

        // Left-shift other item wrappers, config, and errors after removed item in the parent
        const itemsInParent = this.formState.editing.getIn(parentKeyPath);
        for (let i = itemIndex + 1; i < itemsInParent.size; i++) {
          const prevKeyPathString = this.flattenKeyPath([...parentKeyPath, i]);
          const nextKeyPathString = this.flattenKeyPath([
            ...parentKeyPath,
            i - 1,
          ]);

          this.validationConfig = this.validationConfig.mapKeys(configKey => {
            if (
              configKey.startsWith(prevKeyPathString) &&
              prevKeyPathString.length > 0
            ) {
              const relativeKeyPathString = configKey.slice(
                prevKeyPathString.length + 1,
              );
              return `${nextKeyPathString}.${relativeKeyPathString}`;
            }
            return configKey;
          });

          this.fieldWrappers = this.fieldWrappers.mapKeys(configKey => {
            if (
              configKey.startsWith(prevKeyPathString) &&
              prevKeyPathString.length > 0
            ) {
              const relativeKeyPathString = configKey.slice(
                prevKeyPathString.length + 1,
              );
              return `${nextKeyPathString}.${relativeKeyPathString}`;
            }
            return configKey;
          });

          nextErrors = nextErrors.mapKeys(configKey => {
            if (
              configKey.startsWith(prevKeyPathString) &&
              prevKeyPathString.length > 0
            ) {
              const relativeKeyPathString = configKey.slice(
                prevKeyPathString.length + 1,
              );
              return `${nextKeyPathString}.${relativeKeyPathString}`;
            }
            return configKey;
          });
        }

        // Update shifted error state
        this.setFormState({
          errors: nextErrors,
          isFormValid: !nextErrors.some(errorObj => errorObj.size > 0),
        });

        // Remove item from parent and update form state
        const parentKeyPathString = this.flattenKeyPath(parentKeyPath);
        this.update(
          parentKeyPathString,
          this.formState.editing
            .getIn(parentKeyPath, Immutable.List())
            .remove(itemIndex),
          true,
        );
      };

      /**
       * @protected
       * @method
       * @name swapItemsInRepeatedField
       * @description Swaps the values of two repeated child fields within
       *              the repeated parent field at the specified keypath
       *              string. Used by the repeated parent field wrapper.
       * @memberof FormComponent
       *
       * @param {String} baseKeyPathString - keypath of the field in which to swap items
       * @param {Number} indexA - index of first swapped item
       * @param {Number} indexB - index of second swapped item
       */
      swapItemsInRepeatedField = (baseKeyPathString, indexA, indexB) => {
        const baseKeyPath = this.unflattenKeyPath(baseKeyPathString);
        const keyPathA = [...baseKeyPath, indexA];
        const keyPathB = [...baseKeyPath, indexB];

        // Swap values in editing state
        const valueA = this.formState.editing.getIn(keyPathA);
        const valueB = this.formState.editing.getIn(keyPathB);
        const originalParentValue = this.formState.editing.getIn(baseKeyPath);

        this.update(
          baseKeyPathString,
          originalParentValue.set(indexA, valueB).set(indexB, valueA),
        );
      };

      /**
       * @protected
       * @method
       * @name mapItemsInRepeatedField
       * @description Allows a mapper function to transform the list of child
       *              field wrappers within the repeated parent field at the
       *              specified keypath string. Used by the repeated parent
       *              field wrapper.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath of the field to map over
       * @param {Function} mapperFn - function that receives (value, index, itemField)
       *
       * @returns {Immutable.List} - result of mapping over repeated field
       */
      mapItemsInRepeatedField = (keyPathString, mapperFn) => {
        const keyPath = this.unflattenKeyPath(keyPathString);
        const fieldValue =
          this.getFieldValue(keyPathString) || Immutable.List();
        return fieldValue.map((value, index) => {
          const itemKeyPathString = this.flattenKeyPath([...keyPath, index]);
          const itemField = this.createOrGetRepeatedChildFieldWrapper(
            itemKeyPathString,
          );
          return mapperFn(itemField, index);
        });
      };

      /**
       * @protected
       * @method
       * @name filterItemsInRepeatedField
       * @description Allows a filter function to transform the list of child
       *              field wrappers within the repeated parent field at the
       *              specified keypath string. Used by the repeated parent
       *              field wrapper.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath of the field to map over
       * @param {Function} filterFn - function that receives (value, index, itemField)
       *
       * @returns {Immutable.List} - result of filtering repeated field
       */
      filterItemsInRepeatedField = (keyPathString, filterFn) => {
        const keyPath = this.unflattenKeyPath(keyPathString);
        const fieldValue =
          this.getFieldValue(keyPathString) || Immutable.List();
        return fieldValue
          .map((value, index) => {
            const itemKeyPathString = this.flattenKeyPath([...keyPath, index]);
            return this.createOrGetRepeatedChildFieldWrapper(itemKeyPathString);
          })
          .filter(filterFn);
      };

      /**
       * @protected
       * @method
       * @name getChildWrapperInRepeatedField
       * @description Using the repeated parent corresponding to the specified
       *              keypath string, this function finds and returns the
       *              field wrapper of the child field at the specified index.
       *              Used by the repeated parent field wrapper
       * @memberof FormComponent
       *
       * @param {Array} keyPath - keypath to get item field wrapper from
       * @param {Number} index - index of item in repeated field
       *
       * @returns {Object} - item field wrapper for item at index
       */
      getChildWrapperInRepeatedField = (keyPathString, index) => {
        const keyPath = this.unflattenKeyPath(keyPathString);
        const childKeyPathString = this.flattenKeyPath([...keyPath, index]);
        return this.createOrGetRepeatedChildFieldWrapper(childKeyPathString);
      };

      /**
       * @protected
       * @method
       * @name setupRepeatedValidators
       * @description Registers repeated validators to be applied to
       *              descendant fields of a repeated parent field. Validators
       *              are registered on the specified repeated parent field,
       *              and are keyed by the relative keypath on which to apply
       *              validation. Used by the repeated parent field wrapper
       * @memberof FormComponent
       *
       * @param {String} parentKeyPathString - keypath of the repeated parent field
       * @param {Object} validatorFnsForDescendants - keyed validator functions to apply to descendants
       * @param {Object} validationOptionsForDescendants - validator options to apply to descendants
       */
      setupRepeatedValidators = (
        parentKeyPathString,
        validatorFnsForDescendants,
        validationOptionsForDescendants,
      ) => {
        _.each(
          validatorFnsForDescendants,
          (validatorFns, relativeKeyPathString) => {
            this.validationConfig = this.validationConfig.mergeIn(
              [parentKeyPathString, 'repeated', relativeKeyPathString],
              validatorFns,
            );

            if (
              validationOptionsForDescendants &&
              validationOptionsForDescendants[relativeKeyPathString]
            ) {
              this.validationConfig = this.validationConfig.mergeIn(
                [
                  parentKeyPathString,
                  'repeatedValidationOptions',
                  relativeKeyPathString,
                ],
                validationOptionsForDescendants[relativeKeyPathString],
              );
            }
          },
        );
      };

      /**
       * @protected
       * @method
       * @name getRepeatedValidationOptionsFromAncestors
       * @description Gets all repeated validation options registered on ancestor
       *              fields to be applied to the field corresponding to the
       *              specified keypath. This is used by .update()
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath to find matching repeated validators from ancestor fields
       *
       * @returns {Immutable.Map} - map of matching validation options
       */
      getRepeatedValidationOptionsFromAncestors = keyPathString => {
        const keyPath = this.unflattenKeyPath(keyPathString);
        let matchingValidationOptions = Immutable.Map();

        // Get repeated validation options from non-parent ancestors
        for (let i = 0; i < keyPath.length - 1; i++) {
          // Compute the ancestor key path
          const ancestorKeyPath = keyPath.slice(0, i);
          const ancestorKeyPathString = this.flattenKeyPath(ancestorKeyPath);

          // Compute the current key path relative to the ancestor key path
          const relativeKeyPath = keyPath.slice(i + 1);
          const relativeKeyPathString = this.flattenKeyPath(relativeKeyPath);

          // Get any matching validation options from the ancestor
          const matchingValidatorsFromAncestor = this.validationConfig.getIn(
            [
              ancestorKeyPathString,
              'repeatedValidationOptions',
              relativeKeyPathString,
            ],
            Immutable.Map(),
          );

          // Merge repeated validation options from this ancestor into matching validation options map
          matchingValidationOptions = matchingValidationOptions.merge(
            matchingValidatorsFromAncestor,
          );
        }

        // Get repeated validation options from direct parent
        const parentKeyPath = keyPath.slice(0, -1);
        const parentKeyPathString = this.flattenKeyPath(parentKeyPath);
        matchingValidationOptions = matchingValidationOptions.merge(
          this.validationConfig.getIn(
            [parentKeyPathString, 'repeatedValidationOptions', '*'],
            Immutable.Map(),
          ),
        );

        return matchingValidationOptions;
      };

      /**
       * @protected
       * @method
       * @name getRepeatedValidatorsFromAncestors
       * @description Gets all repeated validators registered on ancestor
       *              fields to be applied to the field corresponding to the
       *              specified keypath. This is used by .validateKeyPath()
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath to find matching repeated validators from ancestor fields
       *
       * @returns {Immutable.Map} - map of matching validators, keyed by validation function name
       */
      getRepeatedValidatorsFromAncestors = keyPathString => {
        const keyPath = this.unflattenKeyPath(keyPathString);
        let matchingValidators = Immutable.Map();

        // Get repeated validators from non-parent ancestors
        for (let i = 0; i < keyPath.length - 1; i++) {
          // Compute the ancestor key path
          const ancestorKeyPath = keyPath.slice(0, i);
          const ancestorKeyPathString = this.flattenKeyPath(ancestorKeyPath);

          // Compute the current key path relative to the ancestor key path
          const relativeKeyPath = keyPath.slice(i + 1);
          const relativeKeyPathString = this.flattenKeyPath(relativeKeyPath);

          // Get any matching validators from the ancestor
          const matchingValidatorsFromAncestor = this.validationConfig.getIn(
            [ancestorKeyPathString, 'repeated', relativeKeyPathString],
            Immutable.Map(),
          );

          // Merge repeated validators from this ancestor into matching validator map
          matchingValidators = matchingValidators.merge(
            matchingValidatorsFromAncestor,
          );
        }

        // Get repeated validators from direct parent parent
        const parentKeyPath = keyPath.slice(0, -1);
        const parentKeyPathString = this.flattenKeyPath(parentKeyPath);
        matchingValidators = matchingValidators.merge(
          this.validationConfig.getIn(
            [parentKeyPathString, 'repeated', '*'],
            Immutable.Map(),
          ),
        );

        return matchingValidators;
      };

      /**
       * @protected
       * @method
       * @name validateKeyPath
       * @description Deeply validates the field at the key path
       * @memberof FormComponent
       *
       * @param {String} rootKeyPathString - keypath to validate
       *
       * @returns {Promise} - resolves when validation is finished
       */
      validateKeyPath = rootKeyPathString => {
        const rootKeyPath = this.unflattenKeyPath(rootKeyPathString);

        /**
         * First, create a place to store pending validation promises, which
         * resolve with the validation result. This list is then used to
         * wait for all validation to finish executing.
         */
        const defs = [];

        /**
         * Second, check to see if the field at the specified root keypath
         * inherits any validators from an ancestor repeated parent field.
         * If so, apply these validators to the current field.
         */
        this.getRepeatedValidatorsFromAncestors(rootKeyPathString).forEach(
          (validatorFn, validatorKey) => {
            const validatorResult = validatorFn(
              this.formState.editing.getIn(rootKeyPath),
              this.formState.editing,
            );
            const validatorDef = Promise.resolve(validatorResult).then(
              result => ({
                validatorResult: result,
                validatorKey,
                nodeKeyPath: rootKeyPath,
                nodeKeyPathString: rootKeyPathString,
              }),
            );
            defs.push(validatorDef);
          },
        );

        /**
         * Third, find the validation config objects for the field at the
         * specified keypath as well as all descendants of that field and
         * execute the corresponding validators.
         */
        this.validationConfig
          .filter(
            (config, key) =>
              key.startsWith(rootKeyPathString) || rootKeyPathString === '*',
          )
          .forEach((config, currentKeyPathString) => {
            const currentKeyPath = this.unflattenKeyPath(currentKeyPathString);

            // Apply standalone validators to current node
            config
              .getIn(['standalone'], Immutable.Map())
              .forEach((validatorFn, validatorKey) => {
                const validatorResult = validatorFn(
                  this.formState.editing.getIn(currentKeyPath),
                  this.formState.editing,
                );
                const validatorDef = Promise.resolve(validatorResult).then(
                  result => ({
                    validatorResult: result,
                    validatorKey,
                    nodeKeyPath: currentKeyPath,
                    nodeKeyPathString: currentKeyPathString,
                  }),
                );
                defs.push(validatorDef);
              });

            // Apply repeated parent validators to descentant keypaths
            config
              .getIn(['repeated'], Immutable.Map())
              .forEach((validatorFns, relativeKeyPathString) => {
                const relativeKeyPath = this.unflattenKeyPath(
                  relativeKeyPathString,
                );
                this.formState.editing
                  .getIn(currentKeyPath)
                  .forEach((childValue, index) => {
                    validatorFns.forEach((validatorFn, validatorKey) => {
                      const descentantKeyPath = [
                        ...currentKeyPath,
                        index,
                        ...relativeKeyPath,
                      ];
                      const descentantKeyPathString = this.flattenKeyPath(
                        descentantKeyPath,
                      );
                      const validatorResult = validatorFn(
                        this.formState.editing.getIn(descentantKeyPath),
                        this.formState.editing,
                        index,
                      );
                      const validatorDef = Promise.resolve(
                        validatorResult,
                      ).then(result => ({
                        validatorResult: result,
                        validatorKey,
                        nodeKeyPathString: descentantKeyPathString,
                      }));
                      defs.push(validatorDef);
                    });
                  });
              });
          });

        /**
         * Fourth, create a cancellable promise that resolves when all
         * validators have resolved. Store this promise in the cancellable
         * promise store so that the form can cancel pending validation when
         * it unmounts.
         */
        const validationDefKey = _.uniqueId();
        this.cancelableValidationDefs[validationDefKey] = makeCancelablePromise(
          Promise.all(defs),
        );

        /**
         * Fifth, use all of the results resolved by the validator functions
         * to update the form error state.
         */
        return this.cancelableValidationDefs[validationDefKey].promise.then(
          results => {
            // Copy the current form error state, remove old errors from the current field
            let updatedErrors = this.formState.errors.filter(
              (errorObj, keyPathString) =>
                !(
                  keyPathString.startsWith(rootKeyPathString) ||
                  rootKeyPathString === '*'
                ),
            );

            // For each validator result from the current field and its children, update the form state
            _.each(
              results,
              ({ validatorResult, validatorKey, nodeKeyPathString }) => {
                // Update the field error object corresponding to the current validator result
                let updatedErrorObject = updatedErrors.get(
                  nodeKeyPathString,
                  Immutable.Map(),
                );

                if (validatorResult) {
                  updatedErrorObject = updatedErrorObject.set(
                    validatorKey,
                    validatorResult,
                  );
                } else if (updatedErrorObject.has(validatorKey)) {
                  updatedErrorObject = updatedErrorObject.remove(validatorKey);
                }

                if (updatedErrorObject.size > 0) {
                  updatedErrors = updatedErrors.set(
                    nodeKeyPathString,
                    updatedErrorObject,
                  );
                } else {
                  updatedErrors = updatedErrors.remove(nodeKeyPathString);
                }
              },
            );

            // Check if any validation errors exist in the form
            const isFormValid = !updatedErrors.some(
              errorObj => errorObj.size > 0,
            );

            // Propagate change to the form states
            this.setFormState({
              errors: updatedErrors,
              isFormValid,
            });

            // Remove def from cancellation list once state change is executed
            delete this.cancelableValidationDefs[validationDefKey];
          },
        );
      };

      /**
       * @protected
       * @method
       * @name validateForm
       * @description Validates all fields in the form. Used by the form
       *              method interface in the component constructor.
       * @memberof FormComponent
       *
       * @returns {Promise} - resolves when validation succeeds,
       *                      rejects with error details if validation fails
       */
      validateForm = () =>
        this.validateKeyPath('*').then(() => {
          if (!this.formState.isFormValid) {
            return Promise.reject(this.getErrors('*'));
          }

          return Promise.resolve(this.getFormValue());
        });

      /**
       * @protected
       * @method
       * @name setValue
       * @description Assigns the specified value to the form editing state
       *              and initiates a re-render cycle. Used by the form
       *              method interface in the component constructor.
       * @memberof FormComponent
       *
       * @param {Immutable.Map} formValues - map field key paths to new field values
       *
       * @returns {Promise} Resolves after new state is set
       */
      setValue = formValues => {
        const editing = formValues;
        const isDirty = !Immutable.is(editing, this.formState.clean);
        return this.setFormState({
          editing,
          isDirty,
        });
      };

      /**
       * @protected
       * @method
       * @name getTopmostAutovalidatableAncestorKeyPathString
       * @description Gets the keypath of the topmost ancestor field configured
       *              with on-change validation above the specified field,
       *              if such an ancestor exists.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath for the field
       * @returns {String|void} Auto-validatable ancestor node keypath string
       */
      getTopmostAutovalidatableAncestorKeyPathString = keyPathString => {
        const keyPath = this.unflattenKeyPath(keyPathString);
        for (let i = 0; i <= keyPath.length; i++) {
          // Compute the ancestor key path
          const ancestorKeyPath = keyPath.slice(0, i);
          const ancestorKeyPathString = this.flattenKeyPath(ancestorKeyPath);

          // Get any matching validation config from the ancestor
          const matchingValidationOptionsFromAncestor = this.validationConfig.getIn(
            [ancestorKeyPathString, 'standaloneValidationOptions'],
            Immutable.Map(),
          );

          if (
            matchingValidationOptionsFromAncestor.get('validateOnChange') ===
            true
          ) {
            return ancestorKeyPathString;
          }
        }
      };

      /**
       * @protected
       * @method
       * @name update
       * @description Assigns the specified value to the field corresponding
       *              to the specified keypath string and, if necessary,
       *              revalidates the modified field and dependent fields.
       *              Finally, it initiates a re-render cycle.
       * @memberof FormComponent
       *
       * @param {String} keyPathString - keypath for field to be updated
       * @param {Any} value - new value of the specified field
       */
      update = (keyPathString, value, skipValidation = false) => {
        const keyPath = this.unflattenKeyPath(keyPathString);
        const editing = this.getFormValue().setIn(keyPath, value);
        const isDirty = !Immutable.is(editing, this.formState.clean);
        this.setFormState({
          editing,
          isDirty,
        });

        // Determine if a repeated validation option is configured to validate on-change
        const validateRepeatedFieldOnChange = this.getRepeatedValidationOptionsFromAncestors(
          keyPathString,
        ).get('validateOnChange', false);

        // Determine if an ancestor of this node (or this node itself) is configured to validate on-change
        const topmostAutovalidatableAncestorKeyPathString = this.getTopmostAutovalidatableAncestorKeyPathString(
          keyPathString,
        );

        // Validate the highest auto-validatable field after updating this field's value
        if (!skipValidation) {
          let validationKeyPathString;
          if (topmostAutovalidatableAncestorKeyPathString) {
            validationKeyPathString = topmostAutovalidatableAncestorKeyPathString;
          } else if (
            validateRepeatedFieldOnChange ||
            this.formOptions.validateOnChange
          ) {
            validationKeyPathString = keyPathString;
          }

          if (validationKeyPathString) {
            const debounceTimeoutForField = this.getDebounceTimeoutForField(
              validationKeyPathString,
            );
            if (debounceTimeoutForField === 0) {
              this.validateKeyPath(validationKeyPathString);
            } else {
              this.getDebouncedValidationFn(validationKeyPathString)();
            }
          }
        }

        // Validate any dependent fields after updating this field's value
        if (
          this.validationConfig.hasIn([keyPathString, 'validationDependencies'])
        ) {
          this.validationConfig
            .getIn([keyPathString, 'validationDependencies'])
            .forEach(dependentKeyPathString => {
              const debounceTimeoutForField = this.getDebounceTimeoutForField(
                dependentKeyPathString,
              );
              if (debounceTimeoutForField === 0) {
                this.validateKeyPath(dependentKeyPathString);
              } else {
                this.getDebouncedValidationFn(dependentKeyPathString)();
              }
            });
        }

        // Validate any dependent fields of repeated parent (if applicable)
        if (keyPath.length > 0) {
          const parentKeyPath = keyPath.slice(0, -1);
          const parentKeyPathString = this.flattenKeyPath(parentKeyPath);
          if (
            this.validationConfig.hasIn([
              parentKeyPathString,
              'validationDependencies',
            ])
          ) {
            this.validationConfig
              .getIn([parentKeyPathString, 'validationDependencies'])
              .forEach(dependentKeyPathString => {
                const dependentKeyPath = this.unflattenKeyPath(
                  dependentKeyPathString,
                );
                const debounceTimeoutForField = this.getDebounceTimeoutForField(
                  dependentKeyPath,
                );
                if (debounceTimeoutForField === 0) {
                  this.validateKeyPath(dependentKeyPath);
                } else {
                  this.getDebouncedValidationFn(dependentKeyPathString)();
                }
              });
          }
        }
      };

      /**
       * @protected
       * @method
       * @name componentDidUpdate
       * @description React lifecycle method, updates clean and editing state when new props are provided
       * @memberof FormComponent
       */
      componentDidUpdate() {
        // Update clean state with mapPropsToFormData if it is declared
        const nextClean = this.formOptions.mapPropsToFormData(this.props);
        let nextDirty = nextClean;

        // Resolve new dirty state based on updated clean state
        if (!Immutable.is(this.formState.clean, nextClean)) {
          // Depth-first search traversal: deeply find keypaths that have dirty values
          const stack = [[]];
          const visited = {};

          while (stack.length !== 0) {
            const currentKeyPath = stack.pop();
            const currentKeyPathString = this.flattenKeyPath(currentKeyPath);
            if (visited[currentKeyPathString] === true) {
              continue; // eslint-disable-line no-continue
            }
            visited[currentKeyPathString] = true;

            const editingValue = this.formState.editing.getIn(currentKeyPath);

            // Remove any keypaths that are still clean
            if (
              !isImmutable(this.formState.clean.getIn(currentKeyPath)) &&
              this.formState.clean.getIn(currentKeyPath) !==
                this.formState.editing.getIn(currentKeyPath)
            ) {
              nextDirty = nextDirty.setIn(
                currentKeyPath,
                this.formState.editing.getIn(currentKeyPath),
              );
            }

            // If current keypath has children, add child keypaths to DFS stack
            if (isImmutable(editingValue)) {
              editingValue.forEach((value, key) => {
                stack.push([...currentKeyPath, key]);
              });
            }
          }

          this.setFormState({
            editing: nextDirty,
            clean: nextClean,
            isDirty: !Immutable.is(nextClean, nextDirty),
          });
        }
      }

      /**
       * @protected
       * @method
       * @name componentWillUnmount
       * @description React lifecycle method, cancels pending validation when component unmounts
       * @memberof FormComponent
       */
      componentWillUnmount() {
        // Cancel pending validation promises before unmounting
        if (this.cancelableValidationDefs.length !== 0) {
          _.forEach(this.cancelableValidationDefs, ({ cancel }) => cancel());
          this.cancelableValidationDefs = [];
        }
      }

      /**
       * @protected
       * @method
       * @name render
       * @description React lifecycle method, passes form state to decorated component
       * @memberof FormComponent
       */
      render() {
        return (
          <Component {...this.props} form={this.form} formState={this.state} />
        );
      }
    }

    FormComponent.componentId = Component.componentId;

    return FormComponent;
  };
}

/**
 * @public
 * @name fieldPropType
 * @description React prop type definition for field interfaces
 */
export const fieldPropType = PropTypes.shape({
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  getErrors: PropTypes.func,
  validate: PropTypes.func,
  validators: PropTypes.func,
});

/**
 * @public
 * @name formPropType
 * @description React prop type definition for form interfaces
 */
export const formPropType = PropTypes.shape({
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  getErrors: PropTypes.func,
  revert: PropTypes.func,
  validators: PropTypes.func,
  validate: PropTypes.func,
  field: PropTypes.func,
  isFormValid: PropTypes.func,
  isFormDirty: PropTypes.func,
});

/**
 * Handles the logic for catching errors thrown after validating the form
 * @param  {Object} errorObject
 * @return {null || Object}
 */
export function handleFormValidationError(errorObject) {
  // Only silently catch the form validation error object
  if (errorObject && errorObject.hasError) {
    return;
  }
  throw errorObject;
}

export default Form;

import $ from 'jquery';
import _ from 'lodash';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import flux from 'core/flux';
import EditorFns from 'bundles/p13n/modules/editor/fns';
import EditorGetters from 'bundles/p13n/modules/editor/getters';
import LocationFilter from 'optly/filters/insert_location';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import LayerExperimentHumanReadable from 'optly/modules/entity/layer_experiment/human_readable';
import TrackClicksChangeActions from 'bundles/p13n/modules/track_clicks_change/actions';

import { Features } from 'optly/utils/enums';

import Template from './change_list_sidebar_table_row.html';

// Coax webpack into properly compiling LocationFilter by assigning it to a local variable.
// TODO (FEI-3336) Figure out whats going on here.
const InsertLocationFilter = LocationFilter;

export const ChangeListSidebarTableRow = {
  replace: true,

  template: Template,

  data: {
    changeStatuses: LayerExperimentEnums.ChangeStatuses,
    changeTypes: LayerExperimentEnums.ChangeTypes,
  },

  computed: {
    /**
     * Return a list of all attributes that have changes,
     * in addition to rearrange (if present).
     * @returns {Array}
     */
    changeProperties() {
      const propertiesList = _.keys(this.change.attributes);
      if (
        this.change.rearrange &&
        !_.isEmpty(this.change.rearrange.insertSelector)
      ) {
        propertiesList.push('rearrange');
      }
      return propertiesList;
    },

    /**
     * @returns {Array<string>} list of CSS properties defined in the current change
     */
    changeCSSProperties() {
      return this.change.css ? _.keys(this.change.css) : [];
    },
  },

  filters: {
    'insert-location': InsertLocationFilter,
  },

  methods: {
    /**
     * This method also lives in the parent (change_list_sidebar index file); ideally should be moved to getter.
     * This function will always return true if the current change is a redirect. If not, we’ll wait until
     * the editor is finished loading, then check if any changes for this variation are redirect changes.
     * If there are, this change should not be editable, because the changes would be applied to the url
     * that matches url targeting, but if the end user is redirected from that url, the changes wont apply.
     */
    canEditChange() {
      if (this.change.type === this.changeTypes.REDIRECT) {
        return true;
      }
      return this.isEditorLoadingFinal && !this.currentActionRedirectChange;
    },

    getAddWidgetStatusClass() {
      if (this.change.type !== this.changeTypes.WIDGET) {
        return '';
      }
      if (this.change.status === this.changeStatuses.NEW) {
        return 'sidenav__change-list--draft';
      }
      return 'sidenav__change-list--live';
    },

    /**
     * Compute the status class that should be used for the given attribute change.
     */
    getStatusClassForProperty(property) {
      if (property && this.changeStatusMap[property]) {
        return `sidenav__change-list--${this.changeStatusMap[property]}`;
      }
      if (property && this.changeStatusMap.attributes[property]) {
        return `sidenav__change-list--${this.changeStatusMap.attributes[property]}`;
      }
      return '';
    },

    getStatusClassForCSS(property) {
      return `sidenav__change-list--${this.changeStatusMap.css[property]}`;
    },

    getStatusClassForInsertHTML() {
      return this.changeStatusMap.html
        ? `sidenav__change-list--${this.changeStatusMap.html}`
        : '';
    },

    getMatchingEventChangeText(selector) {
      return TrackClicksChangeActions.getMatchingEventChangeText(
        selector,
        this.selectedViewId,
      );
    },

    /**
     * Computes the change title for a given change
     * @param  {object} change
     * @return {string} nodename
     */
    getChangeTitle(change) {
      let suffix = '';
      if (change.status === this.changeStatuses.DELETED) {
        suffix = tr(' (Deleted)');
      }

      if (change.type === LayerExperimentEnums.ChangeTypes.INSERT_HTML) {
        return `${tr('Inserted HTML ') +
          InsertLocationFilter(change.operator)} ${change.selector}${suffix}`;
      }

      if (change.type === LayerExperimentEnums.ChangeTypes.INSERT_IMAGE) {
        return (
          tr('Inserted image') +
          InsertLocationFilter(change.operator) +
          change.selector +
          suffix
        );
      }

      if (change.type === this.changeTypes.REDIRECT) {
        return LayerExperimentHumanReadable.REDIRECT;
      }
      if (change.name) {
        return change.name + suffix;
      }
      if (change.elementCount === 1) {
        return change.elementInfo[0].nodeName + suffix || '';
      }
      return change.selector + suffix || '';
    },

    /**
     * Computes the attribute display value for a given change and attribute
     *
     * @param {String} attribute (html, class, src, style, etc.)
     */
    getChangeAttributeValue(attribute) {
      const value = this.change.attributes[attribute];
      const prefix = `Changed ${attribute}`;

      if (attribute === 'hide') {
        return tr('Changed visibility to hidden');
      }
      if (attribute === 'remove') {
        return tr('Changed visibility to removed');
      }
      if (attribute === 'rearrange') {
        return tr(
          'Rearranged {0} {1}',
          InsertLocationFilter(this.change.rearrange.operator),
          this.change.rearrange.insertSelector,
        );
      }
      if (typeof value === 'string') {
        return tr('{0} to "{1}"', prefix, value);
      }
      if (typeof value === 'boolean') {
        return tr('{0} to "{1}"', prefix, value ? 'True' : 'False');
      }
      // anything else defaults to "Changed _attribute_."
      return prefix;
    },

    /**
     * @param {string} property - CSS property
     * @returns {string} change list bullet point text for a CSS property change
     */
    getCSSChangeDescription(property) {
      return tr(
        'Changed {0} to {1}',
        property.replace('-', ' '),
        this.change.css[property],
      );
    },

    getImageNameFromHTML(html) {
      const imageElement = $(html);
      const url = imageElement.length
        ? imageElement[0].getAttribute('src')
        : '';
      return EditorFns.getImageFileName(url) || tr('image');
    },

    isDisabled(change) {
      return change.status === this.changeStatuses.DELETED;
    },

    isTemplatesEnabled() {
      return (
        isFeatureEnabled(Features.USE_OPTIMIZELY_TEMPLATES) ||
        isFeatureEnabled(Features.USE_MERGED_TEMPLATES)
      );
    },
  },

  created() {
    flux.bindVueValues(this, {
      changeStatusMap: EditorGetters.changeStatusMap(this.change.id),
      selectedViewId: EditorGetters.selectedViewId,
      changeDependsOnPrevious: EditorGetters.givenChangeDependsOnPrevious(
        this.change,
      ),
    });
  },
};

export default ChangeListSidebarTableRow;

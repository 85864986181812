import PropTypes from 'prop-types';
import React from 'react';

import { Button, ButtonRow } from 'optimizely-oui';

export default class RowControls extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    onRemoveRow: PropTypes.func,
  };

  handleRemoveRow = () => {
    this.props.onRemoveRow(this.props.index);
  };

  render() {
    return (
      <ButtonRow
        rightGroup={[
          <Button
            key="remove-row"
            style="danger-outline"
            onClick={this.handleRemoveRow}
            testSection="oasis-forced-variation-remove-row-button">
            Delete
          </Button>,
        ]}
      />
    );
  }
}

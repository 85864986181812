import flux from 'core/flux';
import Router from 'core/router';
import ui from 'core/ui';

import Events from 'optly/services/events';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import {
  actions as ProjectActions,
  enums as ProjectEnums,
  fns as ProjectFns,
} from 'optly/modules/entity/project';
import { fns as PermissionsFns } from 'optly/modules/permissions';
import UrlHelper from 'optly/services/url_helper';

import CreateProject, { productCategories } from '../component';

/**
 * Saves project with provided details
 *
 * @param {Object} options
 * @param {String} options.name
 * @param {String} options.description
 * @param {String} options.selectedPlatform
 * @param {Boolean} options.hasPrivateDefaultDatafiles
 * @param {Boolean} options.useFullStackLegacyExperience
 */
// TODO(OASIS-3148) Once only new create_project component is used, clean up v1 references
export function saveProject({
  name,
  description,
  selectedPlatform,
  hasPrivateDefaultDatafiles,
  useFullStackLegacyExperience,
}) {
  const adminAccountId = flux.evaluate(AdminAccountGetters.id);
  const canUseOptimizelyX = flux.evaluate([
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseOptimizelyX,
  ]);

  const projectData = {
    account_id: adminAccountId,
    project_name: name,
    description,
    manifest_version: canUseOptimizelyX
      ? ProjectEnums.manifestVersions.V2
      : ProjectEnums.manifestVersions.V1,
  };

  if (canUseOptimizelyX) {
    projectData.library = 'none';
  }

  // If this is the first project (identified by an empty project name), update project.id to be the admin account ID
  // so a PUT is performed on the main project instead of a POST to create a new one
  const currentProjectName = flux.evaluate(CurrentProjectGetters.name);
  if (!currentProjectName) {
    projectData.id = adminAccountId;
  }

  const project = ProjectFns.createEntity(projectData);

  if (selectedPlatform === productCategories.EDGE) {
    project.delivery_mode = ProjectEnums.delivery_modes.EDGE;
    project.project_platforms = [ProjectEnums.project_platforms.WEB];
    project.client_build_settings = {
      client_type: ProjectEnums.ClientBuildSettings.STANDALONE,
    };
  } else if (selectedPlatform === productCategories.FULL_STACK) {
    project.project_platforms = [ProjectEnums.project_platforms.CUSTOM];
    project.sdks = [];
    if (hasPrivateDefaultDatafiles) {
      project.private_default_datafiles = true;
    }
    if (!useFullStackLegacyExperience) {
      project.is_flags_enabled = true;
    }
  } else if (selectedPlatform === productCategories.WEB) {
    project.project_platforms = [ProjectEnums.project_platforms.WEB];
    project.client_build_settings = {
      client_type: canUseOptimizelyX
        ? ProjectEnums.ClientBuildSettings.STANDALONE
        : ProjectEnums.ClientBuildSettings.LEGACY,
    };
  } else {
    throw new Error(
      `Can't create project: unrecognized platform ${selectedPlatform}`,
    );
  }

  return ProjectActions.save(project).then(savedProject => {
    const params = {
      name: savedProject.project_name,
      platform: savedProject.project_platforms,
    };
    Events.trackMarketoEvent(
      'x_component',
      'create',
      'project',
      savedProject.id,
      params,
    );
    return savedProject;
  });
}

/**
 * Given a v2 (Optimizely X) project, redirect to that project based on the ID and platform
 * NOTE: This will only work when used in Optimizely X for a v2 project, since Router.go only works within a single router
 *
 * @param {Object} project
 */
export function routeToProject(project) {
  const projectUrl = UrlHelper.xProjectHome(
    project.id,
    project.project_platforms[0],
    project.is_flags_enabled,
  );
  // Use Router.go since we can guarantee this action and routing will happen from X and only for X projects
  Router.go(projectUrl);
}

export const showCreateProjectDialog = (options = {}) =>
  new Promise(resolve => {
    ui.showReactDialog(
      CreateProject,
      {
        props: {
          onCreate(projectInputData) {
            return saveProject(projectInputData).then(savedProject => {
              ui.hideDialog();
              /**
               * when showing dialog as a full screen modal we
               * need to hide the dialog first because it does
               * some route changes, and then we can resolve
               * the promise. Otherwise if the promise has a redirect,
               * it'll not work
               */
              setTimeout(() => {
                resolve(savedProject);
              }, 100);
            });
          },
        },
      },
      {
        fullScreen: true,
        dismissOnBack: true,
        ...options,
      },
    );
  });

export default {
  routeToProject,
  saveProject,
  showCreateProjectDialog,
};

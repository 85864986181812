import flux from 'core/flux';

/**
 * Retrieves the root Vue VM from the flux store
 * @return {Vue}
 */
export function getRootVM() {
  return flux.evaluate(['uiComponents', 'rootVM']);
}

export default {
  getRootVM,
};

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { fns as PermissionsModuleFns } from 'optly/modules/permissions';
import LayerFns from 'optly/modules/entity/layer/fns';

const canPublishLayer = [
  CurrentProjectGetters.project,
  PermissionsModuleFns.canPublishLayer.bind(PermissionsModuleFns),
];

export const canStartLayer = [
  CurrentProjectGetters.isUsingV2Snippet,
  canPublishLayer,
  CurrentLayerGetters.layer,
  (isUsingV2Snippet, isLayerPublishable, currentLayer) =>
    isUsingV2Snippet &&
    isLayerPublishable &&
    !(currentLayer && currentLayer.get('archived')) &&
    !(currentLayer && LayerFns.hasLayerConcluded(currentLayer)),
];

export default {
  canStartLayer,
};

const _ = require('lodash');

const { default: Immutable, toImmutable, toJS } = require('optly/immutable');

const AdminAccountGetters = require('optly/modules/admin_account/getters');
const AudienceFns = require('optly/modules/entity/audience/fns');
const AudienceGetters = require('optly/modules/entity/audience/getters')
  .default;
const Concurrency = require('bundles/p13n/modules/concurrency_legacy').default;
const CurrentLayerFns = require('bundles/p13n/modules/current_layer/fns');
const CurrentLayerGetters = require('bundles/p13n/modules/current_layer/getters');
const CurrentProjectFns = require('optly/modules/current_project/fns');
const CurrentProjectGetters = require('optly/modules/current_project/getters');
const CustomCodeGetters = require('bundles/p13n/modules/custom_code/getters');
const EditorIframe = require('bundles/p13n/modules/editor_iframe');
const InsertOperatorConstants = require('bundles/p13n/components/insert_operator_dropdown/constants');
const LayerFns = require('optly/modules/entity/layer/fns').default;
const LayerExperimentEntityDef = require('optly/modules/entity/layer_experiment/entity_definition')
  .default;
const LayerExperimentEnums = require('optly/modules/entity/layer_experiment/enums')
  .default;
const PermissionsGetters = require('optly/modules/permissions/getters');
const PluginModuleEnums = require('optly/modules/entity/plugin/enums');
const PluginModuleGetters = require('optly/modules/entity/plugin/getters');
const RegionGetters = require('core/ui/region/getters').default;
const TemplateModuleGetters = require('optly/modules/entity/template/getters');
const TrackClicksChange = require('bundles/p13n/modules/track_clicks_change')
  .default;
const ViewGetters = require('optly/modules/entity/view/getters');

const constants = require('./constants').default;
const fns = require('./fns').default;

exports.selectedVariationId = ['p13n/editor', 'selectedVariationId'];
exports.selectedViewId = ['p13n/editor', 'selectedViewId'];
exports.changeEditorElementSelectorEnabled = [
  'p13n/editor',
  'editorState',
  'elementSelectorEnabled',
];
exports.changeEditorIsEditingSelector = [
  'p13n/editor',
  'editorState',
  'isEditingSelector',
];
exports.selectedExperimentOrSectionId = [
  'p13n/editor',
  'selectedExperimentOrSectionId',
];
exports.changeListSidebarComponentConfig = [
  'p13n/editor',
  'changeListSidebarComponentConfig',
];

exports.sidebarHandleIsVisible = [
  RegionGetters.mountedComponentId('p13n-editor-sidebar'),
  editorSidebarMountedComponent => {
    if (editorSidebarMountedComponent) {
      return _.includes(
        [
          'p13n-change-editor-sidebar',
          'p13n-insert-html-sidebar',
          'p13n-redirect-editor-sidebar',
          'p13n-widget-config-sidebar',
        ],
        editorSidebarMountedComponent,
      );
    }
    return false;
  },
];

exports.selectedView = [
  exports.selectedViewId,
  ViewGetters.entityCache,
  /*
   * @param {Number} id
   * @param {Immutable.map} viewMap
   */
  (id, viewMap) => viewMap.get(id),
];

/**
 * Retrieves the current layer experiment based on either:
 * 1) The variation ID of the variation that's loaded in the editor
 * 2) The selected experiment ID as set when the holdback is selected in the UI
 *    since the holdback isn't actually a variation and therefore has a pseudo-ID
 */
exports.currentLayerExperimentOrSection = [
  CurrentLayerGetters.allExperimentsOrSectionsPointingToLayer,
  exports.selectedVariationId,
  exports.selectedExperimentOrSectionId,
  /**
   * @param {Immutable.Map} currentLayer
   * @param {Immutable.Map} layerExperimentOrSectionMap
   * @param {Number} selectedVariationId
   * @param {Number} selectedExperimentOrSectionId
   * @return {Immutable.List}
   */
  (
    layerExperimentOrSectionMap,
    selectedVariationId,
    selectedExperimentOrSectionId,
  ) => {
    if (
      selectedVariationId === constants.EVERYONE_ELSE_AUDIENCE.variation_id &&
      selectedExperimentOrSectionId
    ) {
      return layerExperimentOrSectionMap.find(
        experiment => experiment.get('id') === selectedExperimentOrSectionId,
      );
    }
    return layerExperimentOrSectionMap.find(experiment =>
      experiment
        .get('variations')
        .find(
          variation => variation.get('variation_id') === selectedVariationId,
        ),
    );
  },
];

/**
 * The id of the current Layer experiment if it exists.
 * @type {*[]}
 */
exports.currentLayerExperimentOrSectionId = [
  exports.currentLayerExperimentOrSection,
  experiment => experiment && experiment.get('id'),
];

/**
 * Returns a list of users who are currently editing the current layer experiment.
 * @type {Array}
 */
exports.currentlyEditingCollaboratorsForExperiment = [
  exports.currentLayerExperimentOrSectionId,
  Concurrency.getters.entities,
  AdminAccountGetters.email,
  (experimentId, concurrencyEntities, currentUserEmail) => {
    const concurrencyEntity = concurrencyEntities.getIn([
      LayerExperimentEntityDef.entity,
      experimentId,
    ]);
    if (!concurrencyEntity) {
      return toImmutable([]);
    }

    // Filter out the current user and remove duplicate entries.
    // It contains duplicate entries for the users who have the experiment open in multiple tabs or windows.
    return Concurrency.fns
      .userListFromEntity(concurrencyEntity)
      .filter(email => email !== currentUserEmail)
      .toSet()
      .toList();
  },
];

exports.isLayerExperimentArchived = [
  exports.currentLayerExperimentOrSection,
  experiment => {
    if (experiment) {
      return experiment.get('status') === LayerExperimentEnums.status.ARCHIVED;
    }
    return false;
  },
];

/**
 * Indicates if editor needs to be in read only mode
 * @return {Boolean}
 */
exports.isEditorReadOnly = [
  exports.isLayerExperimentArchived,
  CurrentLayerGetters.layer,
  (isExperimentArchived, currentLayer) =>
    isExperimentArchived ||
    (currentLayer && LayerFns.hasLayerConcluded(currentLayer)),
];

const previewUrlGenerationArgs = [
  exports.selectedVariationId,
  exports.selectedView,
  exports.currentLayerExperimentOrSection,
  CurrentProjectGetters.id,
  CurrentLayerGetters.id,
  CurrentProjectGetters.previewJSFileName,
  (
    selectedVariationId,
    selectedView,
    currentLayerExperiment,
    currentProjectId,
    currentLayerId,
    token,
  ) => {
    if (selectedVariationId && selectedView && currentLayerExperiment) {
      const audienceIds = currentLayerExperiment.get('audience_ids');
      const editUrl = selectedView.get('edit_url');
      const protocol =
        editUrl.indexOf('https:') === 0
          ? EditorIframe.enums.ProtocolTypes.HTTPS
          : EditorIframe.enums.ProtocolTypes.HTTP;

      const options = {
        variationId: selectedVariationId,
        audienceIds: audienceIds ? audienceIds.toJS() : [],
        previewLayerIds: [currentLayerId],
        projectId: currentProjectId,
      };

      return { editUrl, protocol, token, options };
    }

    return {};
  },
];

/**
 * Retrieve preview link for the currently selected action, showing the preview tool.
 */
exports.previewUrlForAction = [
  previewUrlGenerationArgs,
  ({ editUrl, protocol, token, options }) => {
    if (!token) {
      return 'One of the following is undefined: selectedVariationId, selectedView, currentLayerExperiment';
    }

    options = _.extend({}, options, {
      previewMode: {
        type: 'CAMPAIGN',
        id: options.previewLayerIds[0],
      },
    });

    return EditorIframe.fns.generatePreviewUrl(
      editUrl,
      protocol,
      token,
      options,
    );
  },
];

/**
 * Retrieves preview link for the currently selected action, with NO preview tool
 */
exports.previewUrlForActionWithoutTool = [
  previewUrlGenerationArgs,
  ({ editUrl, protocol, token, options }) => {
    if (!token) {
      return 'One of the following is undefined: selectedVariationId, selectedView, currentLayerExperiment';
    }

    return EditorIframe.fns.generatePreviewUrl(
      editUrl,
      protocol,
      token,
      options,
    );
  },
];

/** ******************************************************************
 ***************************** VARIATION ****************************
 ******************************************************************* */

exports.selectedVariation = [
  exports.selectedVariationId,
  CurrentLayerGetters.allExperimentsOrSectionsPointingToLayer,
  CurrentLayerFns.getVariationById,
];

function actionChangesForView(viewId) {
  return [
    CurrentLayerGetters.allExperimentsOrSectionsPointingToLayer,
    exports.selectedVariationId,
    function(experiments, variationId) {
      return CurrentLayerFns.changesForAction(experiments, viewId, variationId);
    },
  ];
}

/**
 * gets a list of all changes that are currently live for the current variation for a specified viewId
 *
 * @param {Number} viewId
 */
exports.liveChangesForView = function(viewId) {
  return [
    CurrentLayerGetters.liveCommit,
    exports.selectedVariationId,
    (liveCommit, variationId) =>
      CurrentLayerFns.liveChangesForAction(liveCommit, viewId, variationId),
  ];
};

/**
 * gets a list of all draft that are currently unpublished for the current variation for a specified viewId
 *
 * @param {Number} viewId
 */
exports.draftChangesForView = function(viewId) {
  return [
    actionChangesForView(viewId),
    exports.liveChangesForView(viewId),
    (actionChanges, liveChanges) => {
      const newChanges = CurrentLayerFns.newChangesForChangeList(
        actionChanges,
        liveChanges,
      );
      const modifiedChanges = CurrentLayerFns.modifiedChangesForChangeList(
        actionChanges,
        liveChanges,
      );
      const deletedChanges = CurrentLayerFns.deletedChangesForChangeList(
        actionChanges,
        liveChanges,
      );
      return newChanges.concat(modifiedChanges).concat(deletedChanges);
    },
  ];
};

/**
 * Retrieve the display name for a variation. In personalization campaigns, we want to show the
 * audience name as the name of the variation. In a/b tests, and in 'variations in p13n', we want
 * to show the actual variation name.
 * @returns (string)
 */
function getVariationDisplayName(
  variationId,
  variation,
  currentExperiment,
  audiences,
  currentLayer,
  canViewVariationsInP13N,
) {
  if (currentExperiment && variation && currentLayer) {
    const audience_ids = currentExperiment.get('audience_ids');
    if (
      LayerFns.isPersonalizationLayer(currentLayer) &&
      !canViewVariationsInP13N
    ) {
      return audiences
        .find(audience => audience_ids.get(0) === audience.get('id'))
        .get('name');
    }
    return variation.get('name');
  }
  if (variationId === constants.EVERYONE_ELSE_AUDIENCE.variation_id) {
    return constants.EVERYONE_ELSE_AUDIENCE.name;
  }
  return '';
}

exports.variationDisplayName = function(id) {
  return [
    CurrentLayerGetters.variationById(id),
    exports.currentLayerExperimentOrSection,
    AudienceGetters.entityCache,
    _.partial(getVariationDisplayName, id),
  ];
};

exports.selectedVariationDisplayName = [
  exports.selectedVariationId,
  exports.selectedVariation,
  exports.currentLayerExperimentOrSection,
  AudienceGetters.entityCache,
  CurrentLayerGetters.layer,
  CurrentLayerGetters.canViewVariationsInP13N,
  getVariationDisplayName,
];

/**
 * Get variations ONLY in the current layer experiment
 * @return {Immutable.List}
 */
exports.variationsInCurrentLayerExperimentOrSection = [
  CurrentLayerGetters.layer,
  exports.currentLayerExperimentOrSection,
  (currentLayer, experiment) => {
    if (experiment) {
      const isABTestLayer = LayerFns.isABTestLayer(currentLayer);
      const isMultivariateTestLayer = LayerFns.isMultivariateTestLayer(
        currentLayer,
      );
      if (isABTestLayer || isMultivariateTestLayer) {
        return experiment.get('variations');
      }
      return experiment
        .get('variations')
        .unshift(toImmutable(constants.EVERYONE_ELSE_AUDIENCE));
    }

    return toImmutable([]);
  },
];

/** ******************************************************************
 ***************************** CHANGE *******************************
 ******************************************************************* */

/**
 * Given a selector, retrieve the corresponding change from the currently editing Action, if any.
 * @param selector
 * @returns <Change>
 */
exports.getChangeBySelector = function(selector) {
  return [
    exports.currentActionChanges,
    action => action.findLast(change => change.get('selector') === selector),
  ];
};

/**
 * Given a selector, retrieve the corresponding ATTRIBUTE change from
 * the currently editing Action, if any.
 * @param selector
 * @returns <Change>
 */
exports.getAttributeChangeBySelector = function(selector) {
  return [
    exports.currentActionAttributeChangesWithStatus,
    action =>
      action.findLast(
        change =>
          change.get('selector') === selector &&
          change.get('type') === LayerExperimentEnums.ChangeTypes.ATTRIBUTE,
      ),
  ];
};

/**
 * Given a changeId, retrieve the corresponding change from the currently editing Action, if any.
 * @param id
 * @returns <Change>
 */
exports.getChangeById = function(id) {
  return [
    exports.currentActionChanges,
    action => action.find(change => change.get('id') === id),
  ];
};

exports.currentlyEditingChange = [
  ['p13n/editor', 'currentlyEditingChange'],
  /**
   * Final composition of the currentlyEditingChange stores information with the elementInfo
   * to form a proper EditorChange in the immutable form.
   * @param {Immutable.Map} change
   * @param {Immutable.Map<String, ElementInfo>} elementInfoMap
   * @return {Change} immutable version of change
   */
  change => {
    switch (change.get('type')) {
      case LayerExperimentEnums.ChangeTypes.INSERT_HTML:
      case LayerExperimentEnums.ChangeTypes.INSERT_IMAGE: {
        const insertOperator =
          change.get('operator') ||
          InsertOperatorConstants.DOMInsertionType.BEFORE;
        return change.set('operator', insertOperator);
      }
      case LayerExperimentEnums.ChangeTypes.ATTRIBUTE: {
        const rearrangeOperator =
          change.getIn(['rearrange', 'operator']) ||
          InsertOperatorConstants.DOMInsertionType.BEFORE;
        return change.setIn(['rearrange', 'operator'], rearrangeOperator);
      }
      default:
        return change;
    }
  },
];

exports.elementCountFromCurrentlyEditingChange = [
  exports.currentlyEditingChange,
  /**
   * @param {EditorChange} editorChange (immutable version)
   * @return {Integer} Count of selectors matching currentlyEditingChange selector
   */
  currentlyEditingChange => currentlyEditingChange.get('elementCount', 0),
];

exports.currentlyEditingChangeHighlighterOptions = [
  exports.currentlyEditingChange,
  currentlyEditingChange =>
    fns.getHighlighterOptionsForElementChange(toJS(currentlyEditingChange)),
];

exports.childrenWithExistingChanges = [
  exports.currentlyEditingChange,
  /**
   * @param {EditorChange} editorChange (immutable version)
   * @return {Immutable.List} List of selectors representing children with Optimizely changes
   */
  currentlyEditingChange =>
    currentlyEditingChange
      .get('elementInfo')
      .flatMap(elementInfo => elementInfo.get('selectorsWithChangeData')),
];

exports.currentlyEditingChangeId = [
  exports.currentlyEditingChange,
  /**
   * @param {EditorChange} editorChange (immutable version)
   * @return {String?}
   */
  editorChange => {
    if (!editorChange) {
      return;
    }
    return editorChange.get('id');
  },
];

exports.currentlyEditingChangeParentAnchor = [
  exports.currentlyEditingChange,
  /**
   * @param {EditorChange} editorChange (immutable version)
   * @return {Object}
   */
  editorChange => {
    if (
      editorChange &&
      editorChange.get('elementInfo') &&
      editorChange.get('elementInfo').size === 1
    ) {
      return editorChange
        .get('elementInfo')
        .first()
        .get('parentAnchor');
    }
    return toImmutable({});
  },
];

exports.currentlyEditingChangeParentBackground = [
  exports.currentlyEditingChange,
  /**
   * @param {EditorChange} editorChange (immutable version)
   * @return {Object}
   */
  editorChange => {
    if (
      editorChange &&
      editorChange.get('elementInfo') &&
      editorChange.get('elementInfo').size === 1
    ) {
      return editorChange
        .get('elementInfo')
        .first()
        .get('parentBackground');
    }
    return toImmutable({});
  },
];

exports.currentlyEditingChangeTitle = [
  exports.currentlyEditingChange,
  currentlyEditingChange => {
    if (currentlyEditingChange.get('name')) {
      return currentlyEditingChange.get('name');
    }
    if (
      currentlyEditingChange.get('elementInfo') &&
      currentlyEditingChange.get('elementInfo').size === 1
    ) {
      return (
        currentlyEditingChange
          .get('elementInfo')
          .first()
          .get('nodeName') || ''
      );
    }
    return currentlyEditingChange.get('selector') || '';
  },
];

/**
 * Get a map of all live attribute changes for the current change.
 * The attribute is the key and the value is a boolean.
 * @param {String} changeId - The id of the change to use.
 * @return {Getter}
 */
exports.getChangeLiveAttributes = function(changeId) {
  return [
    exports.currentActionLiveChanges,
    liveChanges => {
      const currentLiveChange = liveChanges.find(
        change => change.get('id') === changeId,
      );
      if (currentLiveChange) {
        return currentLiveChange.get('attributes');
      }
      return toImmutable({});
    },
  ];
};

/**
 * Get a map of all draft attribute changes for the changeId provided.
 * The attribute is the key and the value is a boolean.
 * @param {String} changeId - The id of the change to use.
 * @return {Getter}
 */
exports.getChangeDraftAttributes = function(changeId) {
  return [
    exports.currentActionDraftChanges,
    exports.currentActionLiveChanges,
    (draftChanges, liveChanges) => {
      let currentDraftChange = draftChanges.find(
        change => change.get('id') === changeId,
      );
      if (!currentDraftChange) {
        return toImmutable({});
      }
      const currentLiveChange = liveChanges.find(
        change => change.get('id') === changeId,
      );
      if (currentLiveChange) {
        currentDraftChange = currentDraftChange.set(
          'attributes',
          currentDraftChange
            .get('attributes')
            .filterNot(
              (value, attribute) =>
                currentLiveChange.hasIn(['attributes', attribute]) &&
                currentLiveChange.getIn(['attributes', attribute]) === value,
            ),
        );
      }
      return currentDraftChange.get('attributes');
    },
  ];
};

/**
 * Get a map of the status of all attribute changes for the changeId provided.
 * The attribute is the key and the value is an LayerExperimentEnums.ChangeStatuses value.
 * @param {String?} id - The id of the change to use.
 *                       If none is provided, the currentlyEditingChangeId is assumed.
 * @return {Getter}
 */
exports.changeStatusMap = function(id) {
  return [
    exports.currentActionDraftChanges,
    exports.currentActionLiveChanges,
    exports.currentlyEditingChange,
    exports.currentlyEditingChangeRearrangeIsDirty,
    exports.currentlyEditingChangeSelectorIsDirty,
    exports.currentlyEditingInsertHTMLOperatorIsDirty,
    (
      draftChanges,
      liveChanges,
      currentlyEditingChange,
      currentlyEditingChangeRearrangeIsDirty,
      currentlyEditingChangeSelectorIsDirty,
      currentlyEditingInsertHTMLOperatorIsDirty,
    ) => {
      const changeId = id || currentlyEditingChange.get('id');
      const currentDraftChange = draftChanges.find(
        change => change.get('id') === changeId,
      );
      const currentLiveChange = liveChanges.find(
        change => change.get('id') === changeId,
      );

      switch (
        (currentDraftChange || currentLiveChange || currentlyEditingChange).get(
          'type',
        )
      ) {
        case LayerExperimentEnums.ChangeTypes.ATTRIBUTE:
          return fns.getAttributeChangeStatuses(
            currentlyEditingChange,
            currentlyEditingChangeRearrangeIsDirty,
            currentlyEditingChangeSelectorIsDirty,
            currentDraftChange,
            currentLiveChange,
            changeId,
          );
        case LayerExperimentEnums.ChangeTypes.WIDGET:
          return fns.getWidgetChangeStatuses(
            currentDraftChange,
            currentLiveChange,
          );
        case LayerExperimentEnums.ChangeTypes.INSERT_HTML:
        case LayerExperimentEnums.ChangeTypes.INSERT_IMAGE:
          return fns.getInsertHTMLChangeStatus(
            currentlyEditingChange,
            currentlyEditingChangeSelectorIsDirty,
            currentlyEditingInsertHTMLOperatorIsDirty,
            currentDraftChange,
            currentLiveChange,
            changeId,
          );
        case LayerExperimentEnums.ChangeTypes.REDIRECT:
          return fns.getRedirectChangeStatuses(
            currentlyEditingChange,
            currentDraftChange,
            currentLiveChange,
          );
        default:
          return toImmutable({});
      }
    },
  ];
};

/** ******************************************************************
 ***************************** IFRAME *******************************
 ******************************************************************* */

/**
 * @param {string} viewId
 * @param {string} variationId
 * @return {Getter}
 */
exports.iframeByIds = function(viewId, variationId) {
  return EditorIframe.getters.iframe(fns.getIframeId(viewId, variationId));
};

/**
 * @return {Getter}
 */
exports.activeFrame = [
  exports.selectedViewId,
  exports.selectedVariationId,
  EditorIframe.getters.iframes,
  (viewId, variationId, iframes) =>
    iframes.get(fns.getIframeId(viewId, variationId), null),
];

exports.activeFrameId = [
  exports.selectedViewId,
  exports.selectedVariationId,
  (viewId, variationId) => {
    if (!viewId || !variationId) {
      return;
    }
    return fns.getIframeId(viewId, variationId);
  },
];

exports.customCodeIsReadOnly = [
  exports.isEditorReadOnly,
  EditorIframe.getters.iframes,
  exports.activeFrameId,
  (isEditorReadOnly, iframes, activeFrameId) => {
    const activeFrameLoadStatus = iframes.getIn([activeFrameId, 'loadStatus']);
    const editingDisabled =
      activeFrameLoadStatus === EditorIframe.enums.IFrameLoadStatuses.LOADING;
    return isEditorReadOnly || editingDisabled;
  },
];

exports.activeFrameLoadStatus = [
  exports.activeFrameId,
  EditorIframe.getters.iframes,
  (activeFrameId, allIframes) =>
    allIframes.getIn([activeFrameId, 'loadStatus'], null),
];

exports.activeFrameType = [
  exports.activeFrameId,
  EditorIframe.getters.iframes,
  (activeFrameId, allIframes) =>
    allIframes.getIn([activeFrameId, 'frameType'], null),
];

exports.activeFrameEditorType = [
  exports.activeFrameId,
  EditorIframe.getters.iframes,
  (activeFrameId, allIframes) =>
    allIframes.getIn([activeFrameId, 'editorType'], null),
];

/**
 * @return {Getter}
 */
exports.activeFrameLoadingId = [
  exports.activeFrame,
  iframe => iframe && iframe.get('component').loadingId,
];

// If, after creating a new iframe, there are more iframes than IFRAME_MAX, we
// destroy the least recently created inactive iframe.
// The oldestInactiveFrame getter returns the iframe that should be destroyed,
// or a falsy value if no iframe should be destroyed.
// See: cleanupIframes in actions.js
const IFRAME_MAX = 3;

exports.oldestInactiveFrame = [
  exports.activeFrameId,
  EditorIframe.getters.iframes,
  (activeFrameId, allIframes) => {
    let frameToCleanup = null;

    if (allIframes.size > IFRAME_MAX) {
      const inactiveIframes = allIframes.filter(
        iframe => iframe.get('id') !== activeFrameId,
      );
      frameToCleanup = inactiveIframes.minBy(iframe => iframe.get('created'));
    }

    return frameToCleanup;
  },
];

/** *************************************************************
 ****************** ACTION/DIRTY STATES ************************
 ************************************************************** */

/**
 * Return the action (ie. the full changes list) currently being edited based on the selected view/variation ids.
 * @return {Action}
 */
exports.currentActionChanges = [
  CurrentLayerGetters.allExperimentsOrSectionsPointingToLayer,
  exports.selectedViewId,
  exports.selectedVariationId,
  CurrentLayerFns.changesForAction,
];

/**
 * Return the changeId of the last change currently in the list of changes for the current action.s
 * @type {String}
 */
exports.currentActionLastChangeId = [
  exports.currentActionChanges,
  changes => changes.getIn([-1, 'id']),
];

/**
 * Return the action (ie. the full changes list) currently being edited based on the selected view/variation ids.
 * @return {Action}
 */
exports.currentActionAttributeChanges = [
  exports.currentActionChanges,
  action =>
    action
      .filter(
        change =>
          change.get('type') === LayerExperimentEnums.ChangeTypes.ATTRIBUTE,
      )
      .toList(),
];

/**
 * Retrieve the singular custom javascript code change from the currently editing Action, if it exists.
 */
exports.currentActionCustomCode = [
  exports.currentActionChanges,
  action =>
    action.find(
      change =>
        change.get('type') === LayerExperimentEnums.ChangeTypes.CUSTOM_CODE,
    ),
];

/**
 * Retrieve the timing of singular custom javascript code change
 * from the currently editing Action, if it exists. Otherwise, return false
 * for the timing.
 */
exports.currentActionCustomCodeTiming = [
  exports.currentActionCustomCode,
  change => {
    if (change && change.has('async')) {
      return change.get('async');
    }
    return false;
  },
];

exports.currentActionWidgetChanges = [
  exports.currentActionChanges,
  action =>
    action
      .filter(
        change =>
          change.get('type') === LayerExperimentEnums.ChangeTypes.WIDGET,
      )
      .toList(),
];

/**
 * Retrieve the singular custom css change from the currently editing Action, if it exists.
 */
exports.currentActionCustomCss = [
  exports.currentActionChanges,
  action =>
    action.find(
      change =>
        change.get('type') === LayerExperimentEnums.ChangeTypes.CUSTOM_CSS,
    ),
];

/**
 * Retrieve the timing of singular custom css code change from the currently editing Action, if it exists.
 *
 * Otherwise, return false for the timing.
 */
exports.currentActionCustomCssTiming = [
  exports.currentActionCustomCss,
  change => {
    if (change && change.has('async')) {
      return change.get('async');
    }
    return false;
  },
];

exports.currentActionLiveChanges = [
  CurrentLayerGetters.liveCommit,
  exports.selectedViewId,
  exports.selectedVariationId,
  CurrentLayerFns.liveChangesForAction,
];

exports.currentActionLiveCustomCode = [
  exports.currentActionLiveChanges,
  liveChanges =>
    liveChanges.find(
      change =>
        change.get('type') === LayerExperimentEnums.ChangeTypes.CUSTOM_CODE,
    ),
];

exports.currentActionLiveCustomCss = [
  exports.currentActionLiveChanges,
  liveChanges =>
    liveChanges.find(
      change =>
        change.get('type') === LayerExperimentEnums.ChangeTypes.CUSTOM_CSS,
    ),
];

exports.currentActionCustomCodeLinesChanged = [
  exports.currentActionLiveCustomCode,
  exports.currentActionCustomCode,
  CurrentLayerFns.customCodeLinesChanged,
];

exports.currentActionCustomCssLinesChanged = [
  exports.currentActionLiveCustomCss,
  exports.currentActionCustomCss,
  CurrentLayerFns.customCodeLinesChanged,
];

/**
 * All new (working-copy-only) attribute changes for the current action
 */
exports.currentActionNewChanges = [
  exports.currentActionChanges,
  exports.currentActionLiveChanges,
  CurrentLayerFns.newChangesForChangeList,
];

/**
 * All modified (workingCopy and liveCommit changes with same ID do not match) attribute changes for the current action
 */
exports.currentActionModifiedChanges = [
  exports.currentActionChanges,
  exports.currentActionLiveChanges,
  CurrentLayerFns.modifiedChangesForChangeList,
];

/**
 * All deleted (liveCommit-only) attribute changes for the current action
 */
exports.currentActionDeletedChanges = [
  exports.currentActionChanges,
  exports.currentActionLiveChanges,
  CurrentLayerFns.deletedChangesForChangeList,
];

/**
 * All changes for this action, which is a combination of the changes in the working copy plus any changes
 * in the liveCommit which are no longer in the working copy (because we deleted them) ordered in the same
 * way they were before the deleted changes were deleted.
 * @type {*[]}
 */
exports.currentActionOrderedDraftAndLiveChanges = [
  exports.currentActionChanges,
  exports.currentActionLiveChanges,
  exports.currentActionDeletedChanges,
  (draftChanges, liveChanges, deletedChanges) => {
    deletedChanges.map(deletedChange => {
      const liveIndex = liveChanges.find(
        liveChange => liveChange.get('id') === deletedChange.get('id'),
      );
      draftChanges = draftChanges.splice(liveIndex, 0, deletedChange);
    });
    return draftChanges;
  },
];

/**
 * All draft attribute changes for the current action.
 */
exports.currentActionDraftChanges = [
  exports.currentActionNewChanges,
  exports.currentActionModifiedChanges,
  exports.currentActionDeletedChanges,
  (newChanges, modifiedChanges, deletedChanges) =>
    newChanges.concat(modifiedChanges).concat(deletedChanges),
];

exports.currentNewAndModifiedChanges = [
  exports.currentActionChanges,
  exports.currentActionLiveChanges,
  (workingCopyChanges, liveChanges) => {
    const newAndModifiedChanges = workingCopyChanges.map(change => {
      const changeId = change.get('id');
      const correspondingLiveChange = liveChanges.find(
        liveChange => liveChange.get('id') === changeId,
      );
      let status;
      if (!correspondingLiveChange) {
        status = LayerExperimentEnums.ChangeStatuses.NEW;
      } else {
        status = Immutable.is(change, correspondingLiveChange)
          ? LayerExperimentEnums.ChangeStatuses.LIVE
          : LayerExperimentEnums.ChangeStatuses.MODIFIED;
      }
      return change.set('status', status);
    });
    return newAndModifiedChanges;
  },
];

exports.currentDeletedChanges = [
  exports.currentActionChanges,
  exports.currentActionLiveChanges,
  (workingCopyChanges, liveChanges) =>
    liveChanges
      .filter(liveChange => {
        const liveChangeId = liveChange.get('id');
        const workingCopyChange = workingCopyChanges.find(
          change => change.get('id') === liveChangeId,
        );
        return !workingCopyChange;
      })
      .map(change =>
        change.set('status', LayerExperimentEnums.ChangeStatuses.DELETED),
      ),
];

/**
 * Return the action changes list (undo-able changes i.e css/attribute) currently being edited
 * based on the selected view/audience ids.
 *
 * For all changes removed, their dependencies must be removed from other changes as well.
 * @return {Action}
 */
exports.currentActionUndoableChanges = [
  exports.currentActionChanges,
  currentActionChanges => {
    // Find the ids for changes of custom code type so they can be removed from dependencies.
    const changeIdsToRemove = currentActionChanges
      .filter(
        change =>
          change.get('type') === LayerExperimentEnums.ChangeTypes.CUSTOM_CODE,
      )
      .map(x => x.get('id'))
      .toJS();
    // Find all the undoable changes in the currentActionList and filter out any dependencies of changeIds which were removed.
    return currentActionChanges
      .filterNot(change => _.includes(changeIdsToRemove, change.get('id')))
      .map(change =>
        change.set(
          'dependencies',
          toImmutable(
            _.difference(change.get('dependencies').toJS(), changeIdsToRemove),
          ),
        ),
      );
  },
];

/**
 * Returns a redirect change in the current action changes.
 *
 * @return {Change} immutable version of change or null if none exists
 */
exports.currentActionRedirectChange = [
  exports.currentActionChanges,
  currentActionChanges => {
    const redirectChange = currentActionChanges.find(
      change =>
        change.get('type') === LayerExperimentEnums.ChangeTypes.REDIRECT,
    );

    return redirectChange || null;
  },
];

exports.currentlyEditingInsertHTMLOperatorIsDirty = [
  exports.currentActionChanges,
  exports.currentlyEditingChange,
  (currentActionChanges, currentlyEditingChange) => {
    if (currentlyEditingChange.get('selector')) {
      const workingCopyChange = currentActionChanges.find(
        change => change.get('id') === currentlyEditingChange.get('id'),
      );
      if (workingCopyChange) {
        return (
          workingCopyChange.get('operator') !==
          currentlyEditingChange.get('operator')
        );
      }
      return !_.isEmpty(currentlyEditingChange.get('operator'));
    }
    return false;
  },
];

exports.currentlyEditingInsertHTMLChangeIsDirty = [
  exports.currentActionChanges,
  exports.currentlyEditingChange,
  (currentActionChanges, currentlyEditingChange) => {
    const workingCopyChange = currentActionChanges.find(
      change => change.get('id') === currentlyEditingChange.get('id'),
    );

    if (workingCopyChange) {
      return (
        workingCopyChange.get('selector') !==
          currentlyEditingChange.get('selector') ||
        workingCopyChange.get('value') !==
          currentlyEditingChange.get('value') ||
        workingCopyChange.get('operator') !==
          currentlyEditingChange.get('operator')
      );
    }

    // Don't really care whether the dropdown has been modified if there is no selector or value change
    return (
      !!currentlyEditingChange.get('selector') ||
      !!currentlyEditingChange.get('value')
    );
  },
];

/**
 * Returns a boolean to indicate if the currentlyEditingChange has a dirty setting for a redirect change.
 *
 * @returns {Boolean}
 */
exports.currentlyEditingRedirectChangeIsDirty = [
  exports.currentActionChanges,
  exports.currentlyEditingChange,
  (currentActionChanges, currentlyEditingChange) => {
    // if we don't have a currentlyEditingChange or the currentlyEditingChange isn't a redirect, default to false
    if (
      !currentlyEditingChange ||
      currentlyEditingChange.get('type') !==
        LayerExperimentEnums.ChangeTypes.REDIRECT
    ) {
      return false;
    }

    const workingCopyChange = currentActionChanges.find(
      change => change.get('id') === currentlyEditingChange.get('id'),
    );

    const hasDirtyProperty = propertyNames => {
      const dirtyProperty = _.find(
        propertyNames,
        propertyName =>
          currentlyEditingChange.get(propertyName) !==
          workingCopyChange.get(propertyName),
      );

      return dirtyProperty !== undefined;
    };

    if (
      workingCopyChange &&
      workingCopyChange.get('type') ===
        LayerExperimentEnums.ChangeTypes.REDIRECT
    ) {
      if (currentlyEditingChange.get('isRedirectUrl')) {
        return hasDirtyProperty([
          'dest',
          'preserveParameters',
          'allowAdditionalRedirect',
        ]);
      }
      return hasDirtyProperty(['dest_fn', 'allowAdditionalRedirect']);
    }

    // TODO: define defaults in one spot
    // no working copy change comparisons. Performs different checks for url vs code modes
    if (!currentlyEditingChange.get('isRedirectUrl')) {
      // for code mode, we only care about dest_fn and allowAdditionalRedirect since preserveParameters is false by default
      return (
        // treat null or undefined value as an empty string
        (currentlyEditingChange.get('dest_fn') || '') !== '' ||
        currentlyEditingChange.get('allowAdditionalRedirect')
      );
    }
    return (
      // treat null or undefined value as an empty string
      (currentlyEditingChange.get('dest') || '') !== '' ||
      !currentlyEditingChange.get('preserveParameters') ||
      currentlyEditingChange.get('allowAdditionalRedirect')
    );
  },
];

/**
 * Returns a boolean to indicate if the currentlyEditingChange has a dirty setting for rearrange.
 *
 * If there is no working copy change, or the working copy change is legacy and has no 'rearrange'
 * key, then the selector must have a non-zero length to consider rearrange dirty.
 *
 * Otherwise, if there is a working copy change with rearrange, then just compare that to the
 * currentlyEditingChange 'rearrange' key.
 *
 * @returns {Boolean}
 */
exports.currentlyEditingChangeRearrangeIsDirty = [
  exports.currentActionChanges,
  exports.currentlyEditingChange,
  (currentActionChanges, currentlyEditingChange) => {
    const workingCopyChange = currentActionChanges.find(
      change => change.get('id') === currentlyEditingChange.get('id'),
    );

    const currentlyEditingChangeHasInsertSelector = !_.isEmpty(
      currentlyEditingChange.getIn(['rearrange', 'insertSelector']),
    );
    const workingCopyHasNoRearrange =
      !workingCopyChange || !workingCopyChange.get('rearrange');
    const workingCopyHasDifferentRearrange =
      workingCopyChange &&
      workingCopyChange.get('rearrange') &&
      !Immutable.is(
        workingCopyChange.get('rearrange'),
        currentlyEditingChange.get('rearrange'),
      );
    return (
      (workingCopyHasNoRearrange && currentlyEditingChangeHasInsertSelector) ||
      workingCopyHasDifferentRearrange
    );
  },
];

/**
 * Returns a boolean to indicate if the currentlyEditingChange has a dirty setting for async.
 *
 * @returns {Boolean}
 */
exports.currentlyEditingChangeAsyncIsDirty = [
  exports.currentActionChanges,
  exports.currentlyEditingChange,
  (currentActionChanges, currentlyEditingChange) => {
    const workingCopyChange = currentActionChanges.find(
      change => change.get('id') === currentlyEditingChange.get('id'),
    );

    if (workingCopyChange && workingCopyChange.has('async')) {
      return (
        workingCopyChange.get('async') !== currentlyEditingChange.get('async')
      );
    }
    // Default is false, so its only dirty at this point if its true.
    return currentlyEditingChange.get('async');
  },
];

exports.currentlyEditingChangeCSSIsDirty = [
  exports.currentActionChanges,
  exports.currentlyEditingChange,
  (currentActionChanges, currentlyEditingChange) => {
    const workingCopyChange = currentActionChanges.find(
      change => change.get('id') === currentlyEditingChange.get('id'),
    );

    let workingCopyCSS =
      (workingCopyChange && workingCopyChange.get('css')) || toImmutable({});
    let currentChangeCSS = currentlyEditingChange.get('css') || toImmutable({});

    workingCopyCSS = workingCopyCSS.filter((value, property) => value !== null);
    currentChangeCSS = currentChangeCSS.filter(
      (value, property) => value !== null,
    );

    return !Immutable.is(workingCopyCSS, currentChangeCSS);
  },
];

/**
 * Returns a boolean to indicate if the currentlyEditingChange has a dirty setting for change dependencies.
 *
 * @returns {Boolean}
 */
exports.currentlyEditingDependenciesAreDirty = [
  exports.currentActionChanges,
  exports.currentlyEditingChange,
  (currentActionChanges, currentlyEditingChange) => {
    const workingCopyChange = currentActionChanges.find(
      change => change.get('id') === currentlyEditingChange.get('id'),
    );
    const workingCopyDependencies =
      (workingCopyChange && workingCopyChange.get('dependencies')) ||
      toImmutable([]);
    const currentlyEditingDependencies =
      (currentlyEditingChange && currentlyEditingChange.get('dependencies')) ||
      toImmutable([]);
    return !Immutable.is(workingCopyDependencies, currentlyEditingDependencies);
  },
];

exports.currentlyEditingChangeIsDirty = [
  exports.currentlyEditingChange,
  exports.currentlyEditingChangeRearrangeIsDirty,
  exports.currentlyEditingChangeAsyncIsDirty,
  exports.currentlyEditingInsertHTMLChangeIsDirty,
  exports.currentlyEditingRedirectChangeIsDirty,
  exports.currentlyEditingChangeCSSIsDirty,
  TrackClicksChange.getters.trackClicksChangeIsDirty,
  exports.currentlyEditingDependenciesAreDirty,
  /**
   * @param {Change} change (immutable version)
   * @param {Boolean} rearrangeIsDirty
   * @param {Boolean} insertHTMLIsDirty
   * @param {Boolean} redirectIsDirty
   * @param {Boolean} cssIsDirty
   * @param {Boolean} trackClicksIsDirty
   * @param {Boolean} dependenciesAreDirty
   * @return {Boolean}
   */
  (
    change,
    rearrangeIsDirty,
    asyncIsDirty,
    insertHTMLIsDirty,
    redirectIsDirty,
    cssIsDirty,
    trackClicksIsDirty,
    dependenciesAreDirty,
  ) => {
    switch (change.get('type')) {
      case LayerExperimentEnums.ChangeTypes.REDIRECT:
        return redirectIsDirty;
      case LayerExperimentEnums.ChangeTypes.INSERT_HTML:
      case LayerExperimentEnums.ChangeTypes.INSERT_IMAGE:
        return insertHTMLIsDirty || asyncIsDirty || dependenciesAreDirty;
      case LayerExperimentEnums.ChangeTypes.ATTRIBUTE:
        return (
          change.get('nameIsDirty') ||
          change.get('selectorIsDirty') ||
          rearrangeIsDirty ||
          asyncIsDirty ||
          cssIsDirty ||
          trackClicksIsDirty ||
          dependenciesAreDirty ||
          change.get('shouldSaveFlags').reduce(
            (last, next) => last || next, // if nothing's been dirty yet, or if next is dirty (shouldSave === true)
            false,
          )
        );
      default:
        return false;
    }
  },
];

/**
 * Get all attribute changes for currently editing action with injected statuses.
 * The correct change order is prescribed by the changeset saved in GAE, exports.currentActionChanges.
 */
exports.currentActionAttributeChangesWithStatus = [
  exports.currentActionNewChanges,
  exports.currentActionLiveChanges,
  exports.currentActionModifiedChanges,
  exports.currentActionDeletedChanges,
  exports.currentActionOrderedDraftAndLiveChanges,
  CurrentLayerFns.attributeChangesWithStatusForAction,
];

/**
 * Getter that determines if a given change depends on its previous change in the list of changes.
 */
exports.givenChangeDependsOnPrevious = change => [
  exports.currentActionAttributeChangesWithStatus,
  changesList => fns.changeDependsOnPrevious(changesList, toImmutable(change)),
];

/**
 * Getter that determines whether or not the currently editing change depends on the previous change.
 */
exports.currentlyEditingChangeDependsOnPrevious = [
  exports.currentActionAttributeChangesWithStatus,
  exports.currentlyEditingChange,
  (changesList, change) => fns.changeDependsOnPrevious(changesList, change),
];

/**
 * Return the action currently editing action formatted for applying.
 *
 * If an individual change is passed in with the same ID as an existing change,
 * it should be used instead of the existing one (it is currently being edited).
 *
 * Otherwise, that individual change should be appended to the action list
 * because it doesn't yet exist in the current action list.
 *
 * Any layer level CSS change should be applied first and hence is prefixed to the action list
 *
 * @param {Boolean} undoableChangeOnly - Used to only include changes that have
 *                                       type != LayerExperimentEnums.ChangeTypes.CUSTOM_CODE
 *                                       Helpful for excluding changes that shouldn't be executed
 *                                       each time changes are applied in the editor.
 * @param {Change=} editingChange
 * @returns <Action>
 */
exports.currentlyEditingActionFormatted = function(
  undoableChangeOnly,
  editingChange,
) {
  return [
    exports.currentActionChanges,
    CurrentLayerGetters.layerCustomCSSChange,
    exports.currentActionUndoableChanges,
    function(action, layerCssChange, actionUndoableChangesOnly) {
      // Components will pass editing change as POJO, but our formatting
      // functions require an Immutable.List for the action.
      editingChange = toImmutable(editingChange || {});
      const actionToUse = undoableChangeOnly
        ? actionUndoableChangesOnly
        : action;
      const editingChangeIndex = actionToUse.findIndex(
        change => change.get('id') === editingChange.get('id'),
      );
      let formattedAction = actionToUse.map(change => {
        if (editingChange.get('id') === change.get('id')) {
          // The editingChange is an existing change in the action, meaning it is being edited.
          // Use it's data instead, but ensure that the dependencies set in actionToUse are set.
          return fns.formatChangeForApply(
            editingChange.set('dependencies', change.get('dependencies')),
          );
        }
        return fns.formatChangeForApply(change);
      });

      if (editingChange.get('id') && editingChangeIndex === -1) {
        // This editingChange is a new change that is not in the current action.
        formattedAction = formattedAction.push(
          fns.formatChangeForApply(editingChange),
        );
      }
      if (layerCssChange) {
        formattedAction = formattedAction.unshift(layerCssChange); // prefix layer css change to formattedAction
      }
      // We've tampered with our change list, so make sure it is ordered
      // with the right dependencies before returning it.
      return fns.orderChangeList(formattedAction);
    },
  ];
};

/** *************************************************************
 ******************** SELECTOR/ENABLED  ************************
 ************************************************************** */

/**
 * Getter to get initial selector. This value is used when we are trying to
 * revert the selector to the initial value.
 *
 * It checks if there is a saved value selector value first. If not, it uses
 * the initial selector store value for attribute changes and an empty string
 * for all other types of changes (e.g. insert HTML, insert image).
 *
 * @type {Getter}
 */
exports.initialSelector = [
  ['p13n/editor', 'initialSelector'],
  exports.currentlyEditingChange,
  exports.currentActionChanges,
  (initialSelector, currentlyEditingChange, currentActionChanges) => {
    const currentSavedChange = currentActionChanges.find(
      change => change.get('id') === currentlyEditingChange.get('id'),
    );
    if (currentSavedChange && currentSavedChange.has('selector')) {
      return currentSavedChange.get('selector');
    }
    if (
      currentlyEditingChange.get('type') ===
      LayerExperimentEnums.ChangeTypes.ATTRIBUTE
    ) {
      return initialSelector;
    }
    return '';
  },
];

/**
 * Getter to get value telling if the selector is dirty or not. Note that
 * this refers to the selector in the store, NOT currentSelector.
 * @type {Getter}
 */
exports.currentlyEditingChangeSelectorIsDirty = [
  exports.currentlyEditingChange,
  exports.initialSelector,
  (currentlyEditingChange, initialSelector) => {
    if (
      currentlyEditingChange.get('type') ===
      LayerExperimentEnums.ChangeTypes.ATTRIBUTE
    ) {
      return currentlyEditingChange.get('selectorIsDirty') || false;
    }
    return _.isUndefined(initialSelector)
      ? false
      : initialSelector !== currentlyEditingChange.get('selector');
  },
];

/**
 * Getter to indicate which selector input, if any, is the one currently being edited.
 * @type {Getter}
 */
exports.currentSelectorType = ['p13n/editor', 'currentSelectorInputType'];

/**
 * Getter that indicates whether or not the editor iframe element should be shown. It should be shown if:
 *   - We have an action selected
 *                 -AND-
 *   - The editor is not in popout mode while the custom code panel is open.
 * @return {Getter}
 */
exports.shouldShowEditor = [
  exports.activeFrameType,
  CustomCodeGetters.customCodePanelShown,
  exports.activeFrameId,
  (selectedFrameType, customCodePanelShown, activeFrameId) => {
    // We have an activeFrameId (meaning we have an action selected) AND were not in popout mode with the editcode panel in use.
    const inPopoutModeWithCustomCode =
      selectedFrameType === EditorIframe.enums.FrameTypes.POPOUT &&
      customCodePanelShown;
    return activeFrameId && !inPopoutModeWithCustomCode;
  },
];

exports.changeEditorEditingEnabled = [
  exports.changeEditorElementSelectorEnabled,
  exports.changeEditorIsEditingSelector,
  (elementSelectorEnabled, isEditingSelector) =>
    elementSelectorEnabled === false && isEditingSelector === false,
];

// TODO(derek@optimizely.com) Remove this when all Editor plugin Selector Input's are updated
exports.currentSelector = [
  ['p13n/editor', 'currentSelector'],
  exports.currentSelectorType,
  exports.currentlyEditingChange,
  exports.changeEditorEditingEnabled,
  (currentSelector, currentSelectorType, change, editingEnabled) => {
    // !editingEnabled meaning we can edit changes because
    // we aren't changing selectors
    if (
      !editingEnabled &&
      currentSelectorType === constants.SelectorInputTypes.ELEMENT_SELECTOR
    ) {
      return currentSelector;
    }
    return change.get('selector');
  },
];

// TODO(derek@optimizely.com) Remove this when all Editor plugin Selector Input's are updated
exports.currentRearrangeSelector = [
  ['p13n/editor', 'currentSelector'],
  exports.currentSelectorType,
  exports.currentlyEditingChange,
  exports.changeEditorEditingEnabled,
  (currentSelector, currentSelectorType, change, editingEnabled) => {
    // !editingEnabled meaning we can edit changes because
    // we aren't changing selectors
    if (
      !editingEnabled &&
      currentSelectorType === constants.SelectorInputTypes.REARRANGE_SELECTOR
    ) {
      return currentSelector;
    }
    return change.getIn(['rearrange', 'insertSelector']);
  },
];

exports.currentlyEditingChangeSelector = [
  exports.currentlyEditingChange,
  change => change.get('selector'),
];

exports.currentlyEditingChangeRearrangeSelector = [
  exports.currentlyEditingChange,
  change => change.getIn(['rearrange', 'insertSelector']),
];

exports.currentlyEditingChangeRearrangeOperator = [
  exports.currentlyEditingChange,
  change => change.getIn(['rearrange', 'operator']),
];

/**
 * Getter to determine if Selector Input should be disabled due to read only mode or other active Selector Input instances
 * @param {String} selectorTypeInstance - the specific instance's Editor.constants.SelectorInputTypes
 * @returns {Boolean} returns true if the input should be disabled
 */
exports.selectorInputIsDisabled = function(selectorTypeInstance) {
  return [
    exports.isEditorReadOnly,
    exports.currentSelectorType,
    (isEditorReadOnly, currentSelectorType) =>
      isEditorReadOnly ||
      (!!currentSelectorType && currentSelectorType !== selectorTypeInstance),
  ];
};

/** *************************************************************
 *********************** Plugins *******************************
 ************************************************************** */

/**
 * Getter to show available plugins
 * @returns {Immutable.List}
 */
exports.availablePlugins = [
  PluginModuleGetters.entityCache,
  CurrentProjectGetters.id,
  CurrentProjectFns.filterByProjectId,
];

exports.availableWidgets = [
  exports.availablePlugins,
  plugins =>
    plugins.filter(
      plugin =>
        plugin.get('plugin_type') === PluginModuleEnums.plugin_type.WIDGET,
    ),
];

exports.enabledWidgets = [
  exports.availableWidgets,
  widgets => widgets.filter(widget => widget.get('is_enabled_in_client')),
];

/** *************************************************************
 ************************* CSS *********************************
 ************************************************************** */

exports.storedCSSValue = function(property) {
  return [
    exports.currentlyEditingChangeId,
    exports.currentActionChanges,
    (changeId, currentActionChanges) => {
      const workingCopyChange = currentActionChanges.find(
        change => change.get('id') === changeId,
      );

      if (workingCopyChange && workingCopyChange.get('css')) {
        return workingCopyChange.get('css').get(property) || null;
      }

      return null;
    },
  ];
};

/**
 * Get the value of a CSS property in the currently editing change
 * @returns {string|null}
 */
exports.cssValueFromCurrentlyEditingChange = function(property) {
  return [
    exports.currentlyEditingChange,
    change => {
      const value = change.getIn(['css', property]);
      return _.isUndefined(value) ? null : value;
    },
  ];
};

/**
 * Get value of CSS property from the currently editing element
 *
 * @param {string} property - CSS property to get the computed value for
 * @returns {string|number|null}
 */
exports.cssValueFromElement = function(property) {
  return [
    exports.currentlyEditingChange,
    change => {
      change = change.toJS();

      // Check to see that the element meets base criteria
      const { elementInfo } = change;

      if (elementInfo && change.elementCount === 1) {
        return elementInfo[0].computedStyle.getPropertyValue(property) || null;
      }

      return null;
    },
  ];
};

exports.cssValueFromChangeOrElement = function(property) {
  return [
    exports.cssValueFromCurrentlyEditingChange(property),
    exports.cssValueFromElement(property),
    (changeValue, elementValue) =>
      changeValue !== null ? changeValue : elementValue,
  ];
};

exports.currentExperimentOrSectionDisplayName = [
  exports.currentLayerExperimentOrSection,
  AudienceGetters.entityCache,
  (currentLayerExperimentOrSection, audiencesMap) => {
    if (!currentLayerExperimentOrSection) {
      return '';
    }
    if (currentLayerExperimentOrSection.get('name')) {
      return currentLayerExperimentOrSection.get('name');
    }
    if (!currentLayerExperimentOrSection.get('audience_ids')) {
      return '';
    }
    const experimentAudiences = currentLayerExperimentOrSection
      .get('audience_ids')
      .map(audienceId => audiencesMap.get(audienceId));
    const audienceLabelStr = AudienceFns.constructAudienceLabelFromList(
      currentLayerExperimentOrSection.get('audience_conditions'),
      experimentAudiences,
    ).join(' ');
    return audienceLabelStr;
  },
];

/**
 * Given a change Id and a getter for extension field form values, returns a
 * getter for a map of field name to change status. The statuses are based on
 * comparing saved values to form values.
 * @param {String} changeId
 * @param {Array} formValuesGetter
 * @return {Array}
 */
exports.extensionChangeFieldStatuses = function(changeId, formValuesGetter) {
  return [
    exports.currentActionDraftChanges,
    exports.currentActionLiveChanges,
    formValuesGetter,
    (draftChanges, liveChanges, formValues) => {
      const extensionDraftChange = draftChanges.find(
        change => change.get('id') === changeId,
      );
      const extensionLiveChange = liveChanges.find(
        change => change.get('id') === changeId,
      );
      return fns.getStatusesForExtensionFields(
        extensionDraftChange,
        extensionLiveChange,
        formValues,
      );
    },
  ];
};

/**
 * Whether or not to show the dependency management section.
 */
exports.showDependencyManagement = [
  PermissionsGetters.canUseChangeDependencies,
  exports.currentlyEditingChangeId,
  exports.currentActionChanges,
  (canUseChangeDependencies, currentlyEditingChangeId, changeList) => {
    // if canUseChangeDependencies is false OR change is null OR if change is new and will be the first change in the list
    if (
      !canUseChangeDependencies ||
      !currentlyEditingChangeId ||
      changeList.isEmpty()
    ) {
      return false;
    }

    // When there are existing changes in the changes list
    const changeIndex = changeList.findIndex(
      change => change.get('id') === currentlyEditingChangeId,
    );
    const prevChangeType =
      changeIndex < 0
        ? changeList.last().get('type')
        : changeList.getIn([changeIndex - 1, 'type']);
    const changeIsFirstInList =
      changeList.getIn([0, 'id']) === currentlyEditingChangeId;

    return (
      !changeIsFirstInList && // if change is not first in the list
      prevChangeType !== LayerExperimentEnums.ChangeTypes.CUSTOM_CODE && // if the previous change is not custom code
      prevChangeType !== LayerExperimentEnums.ChangeTypes.CUSTOM_CSS
    ); // if the previous change is not custom css
  },
];

const keyMirror = require('optly/utils/key_mirror');

// snippetStatuses represents the set of pills that can be shown in the top
// right area of the experiment cards in this section.
exports.snippetStatuses = keyMirror({
  // NOT_STARTED means the experiment has never been published and is not
  // currently paused
  NOT_STARTED: null,
  // RUNNING means the experiment has been published in the past and is not
  // currently paused
  RUNNING: null,
  // PAUSED means the experiment is currently paused
  PAUSED: null,
  // CAMPAIGN_PAUSED means the campaign containing this experiment is paused
  CAMPAIGN_PAUSED: null,
});

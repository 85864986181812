import { cloneDeep, extend, isString } from 'lodash';

import Immutable, { toImmutable } from 'optly/immutable';
import { enums as LayerExperimentEnums } from 'optly/modules/entity/layer_experiment';

import { FEATURE_VARIABLE_TYPES } from './constants';

let defaultExportObj = {}; // eslint-disable-line import/no-mutable-exports

/**
 * TODO(APPX-34) Move to entity_definition.serialize for "audience_conditions" when all FeatureFlag Audiences builders can use rich JSON
 *
 * Given a feature flag, delete computed audience_conditions_json key from the primary environment
 *
 * Fields removed:
 *   'audience_conditions_json': Since the new Audience Combinations Builder
 *             component sends the rich audience_conditions_json, and because
 *             audience_conditions takes precedence over audience_ids in the
 *             backend, this removes the (computed in the frontend only) audience_conditions_json and sets
 *             audience_conditions (stringified JSON) and audience_ids (Array|Null)
 *
 * NOTE: Currently, the backend and UI use the primary environment's rollout_rule for all environments. In the future,
 * when we support multiple unique rollout_rules for different environments, this will continue to work since every
 * environment's audience config is already being computed
 *
 * @param {Object} featureFlag
 * @return {Object} new state for the layerExperiment
 */
export function cleanAudiencesJson(featureFlag) {
  const clonedFeatureFlag = cloneDeep(featureFlag);
  if (!clonedFeatureFlag.environments) {
    return clonedFeatureFlag;
  }

  /**
   * Given an environment reference from clonedFeatureFlag, this will mutate that reference and update audience_ids and
   * audience_conditions from audience_conditions_json, then delete audience_conditions_json
   *
   * @param {Object} featureEnvironment
   */
  const deriveAudienceConfigfromAudiencesJson = featureEnvironment => {
    (featureEnvironment.rollout_rules || []).forEach(rolloutRule => {
      const { audience_conditions_json } = rolloutRule;
      if (!audience_conditions_json) {
        return;
      }
      delete rolloutRule.audience_conditions_json;
      rolloutRule.audience_conditions = JSON.stringify(
        audience_conditions_json,
      );
      rolloutRule.audience_ids = null;

      // audience_ids should be an empty array if audience_conditions_json is available but configured to everyone
      // ("everyone" means audience_conditions_json either has just a single operator or is empty)
      if (
        audience_conditions_json &&
        (!audience_conditions_json.length ||
          audience_conditions_json.every(isString))
      ) {
        rolloutRule.audience_conditions = null;
        rolloutRule.audience_ids = [];
      }
    });
  };

  Object.values(clonedFeatureFlag.environments).forEach(
    deriveAudienceConfigfromAudiencesJson,
  );

  return clonedFeatureFlag;
}

/**
 * Creates an empty feature entity object extended with the supplied data
 * @param {Object} data
 * @return {Object}
 */
export function createFeatureEntity(data) {
  const DEFAULTS = {
    archived: false,
    api_name: '',
    description: '',
    variables: [],
  };

  return extend({}, DEFAULTS, data);
}

/**
 * Returns the default variable value for the given type
 * @param  {string} type
 * @return {mixed} Return the default value. Can be boolean, integer, float, string.
 */
export function getDefaultValueForType(type) {
  if (type === FEATURE_VARIABLE_TYPES.boolean) {
    return 'false';
  }
  if (type === FEATURE_VARIABLE_TYPES.integer) {
    return '0';
  }
  if (type === FEATURE_VARIABLE_TYPES.double) {
    return '0.0';
  }
  if (type === FEATURE_VARIABLE_TYPES.string) {
    return '';
  }
  if (type === FEATURE_VARIABLE_TYPES.json) {
    return '{}';
  }

  return '';
}

/**
 * Gets rollout information from the primary environment of a feature
 * @param {Immutable.Map} feature
 * @return {Immutable.Map}
 */
export function getPrimaryEnvironmentRolloutInfoOfFeature(feature) {
  const environments = feature.get('environments');
  return environments.find(environment => environment.get('is_primary'));
}

/**
 * Return whether a string value from an input for a variable of the given type
 * is valid
 * @param {String} value
 * @param {String} variableType
 * @return {Boolean}
 */
export function isVariableValueInputValid(value, variableType) {
  if (typeof value !== 'string') {
    return false;
  }

  switch (variableType) {
    case FEATURE_VARIABLE_TYPES.boolean:
      return value === 'true' || value === 'false';

    case FEATURE_VARIABLE_TYPES.double:
      return value.length > 0 && !/[^0-9\-.]/.test(value);

    case FEATURE_VARIABLE_TYPES.integer:
      return value.length > 0 && !/[^0-9-]/.test(value);

    case FEATURE_VARIABLE_TYPES.json: {
      try {
        const parsedJsonValue = JSON.parse(value);
        if (
          Array.isArray(parsedJsonValue) ||
          typeof parsedJsonValue === 'boolean' ||
          typeof parsedJsonValue === 'number' ||
          typeof parsedJsonValue === 'string'
        )
          return false;
      } catch (e) {
        return false;
      }

      return true;
    }

    default:
      return true;
  }
}

/**
 * Returns a default environment config for the given environment (0%, toggled
 * off).
 * @param {Immutable.Map} environment
 * @returns {Immutable.Map}
 */
function createDefaultEnvironmentConfig(environment) {
  return toImmutable({
    id: environment.get('id'),
    is_primary: environment.get('is_primary'),
    rollout_rules: [
      {
        audience_conditions: null,
        audience_ids: [],
        percentage_included: 0,
        status: LayerExperimentEnums.EnvironmentStatus.NOT_STARTED,
      },
    ],
  });
}

/**
 * Returns a new Feature entity with a default config for the argument primary
 * environment, and project_id equal to the argument projectId
 * @param {Immutable.Map} primaryEnvironment
 * @param {Number} projectId
 * @returns {Immutable.Map}
 */
export function createNewFeatureWithEnvironmentConfig(
  primaryEnvironment,
  projectId,
) {
  return toImmutable(
    defaultExportObj.createFeatureEntity({
      api_name: '',
      project_id: projectId,
      environments: {
        [primaryEnvironment.get('name')]: createDefaultEnvironmentConfig(
          primaryEnvironment,
        ),
      },
    }),
  );
}

/**
 * Returns an updated version of the argument feature. If the feature is missing
 * an environment config for the argument environment, we fill in a default one.
 * @param {Immutable.Map} feature
 * @param {Immutable.Map} environment
 * @returns {Immutable.Map}
 */
export function ensureDefaultEnvironmentConfig(feature, environment) {
  return feature.updateIn(
    ['environments', environment.get('key')],
    envConfig => envConfig || createDefaultEnvironmentConfig(environment),
  );
}

/**
 * Given a feature API entity, returns an object representing the feature
 * rollout in the primary environment
 * @param {Immutable.Map} feature
 * @returns {Object} Has environmentKey, environmentName, and environmentInfo properties.
 * environmentKey is the key of the primary environment
 * @deprecated environmentName is the key of the primary environment
 * environmentInfo is an Immutable.Map containing info about the feature rollout
 * in the primary environment, such as rollout rules.
 */
export function getPrimaryEnvironmentEntry(feature) {
  const [environmentKey, environmentInfo] = feature
    .get('environments', Immutable.Map({}))
    .findEntry(environment => environment.get('is_primary'));
  return {
    environmentKey,
    environmentName: environmentKey, // @deprecated, use `entity/environment` for env. names
    environmentInfo,
  };
}

/**
 * Update a propery on the primary environment rollout
 * @param {Immutable.Map} feature
 * @param {String} key
 * @param {*} value
 * @returns {Immutable.Map} feature with updated rollout property
 */
export function updateRollout(feature, key, value) {
  const { environmentName, environmentInfo } = getPrimaryEnvironmentEntry(
    feature,
  );
  const newEnvironmentData = environmentInfo.setIn(
    ['rollout_rules', 0, key],
    value,
  );
  return feature.set(
    'environments',
    toImmutable({
      [environmentName]: newEnvironmentData,
    }),
  );
}

/**
 * Update the rollout rule for a given environment
 * @param {Immutable.Map} feature
 * @param {Immutable.Map} environment
 * @param {object} values
 * @returns {Immutable.Map}
 */
export function updateRolloutForEnvironment(feature, environment, values) {
  const keyPath = ['environments', environment.get('key'), 'rollout_rules', 0];
  const updatedRollout = feature.getIn(keyPath).merge(values);
  return feature.setIn(keyPath, updatedRollout);
}

/**
 * Update a propery on the primary environment rollout
 * @param {Immutable.Map} feature
 * @param {String} key
 * @returns {*}
 */
export function getRolloutPropertyValue(feature, key) {
  const { environmentInfo } = getPrimaryEnvironmentEntry(feature);
  return environmentInfo.getIn(['rollout_rules', 0, key]);
}

/**
 * TODO(jordan): replace with fns
 * Given a string value taken from the rollout traffic input, return the
 * corresponding value of the percentage_included property of the rollout rule
 * in the feature API entity.
 * The traffic input shows 0 to 100 inclusive.
 * percentage_included in the API is an integer from 0 to 10000 inclusive.
 * @param {String} traffic
 * @returns {Number}
 */
export function parseTraffic(traffic) {
  // Using Math.round because multiplying by 100 yields a slightly-off result
  // for some numbers
  return Math.round(parseFloat(traffic) * 100);
}

/**
 * Given a percentage_included value from a feature API entity's rollout rule,
 * return a string that can be displayed to the user in the traffic input.
 * percentage_included in the API is an integer from 0 to 10000 inclusive.
 * The traffic input shows 0 to 100 inclusive.
 * @param {Number} percentageIncluded
 * @returns {String}
 */
export function formatInitialPercentageIncluded(percentageIncluded) {
  return (percentageIncluded / 100).toString();
}

/**
 * Ensure the presence of an environment config for each environment in
 * environments (the second argument) inside feature (the first argument)
 * @param {Immutable.Map} feature
 * @param {Immutable.Map|Immutable.List} environments
 * @returns {Immutable.Map}
 */
export function ensureEnvironmentConfigs(feature, environments) {
  return environments.reduce(
    (updatedFeature, environment) =>
      defaultExportObj.ensureDefaultEnvironmentConfig(
        updatedFeature,
        environment,
      ),
    feature,
  );
}

/**
 * Create and return a new feature entity with environment configs for each
 * environment in the argument environments
 * @param {Immutable.Map|Immutable.List} environments
 * @param {Number} projectId
 * @returns {Immutable.Map}
 */
export function createNewFeatureWithEnvironmentConfigs(
  environments,
  projectId,
) {
  const newFeature = toImmutable(
    defaultExportObj.createFeatureEntity({
      api_name: '',
      project_id: projectId,
      environments: toImmutable({}),
    }),
  );
  return defaultExportObj.ensureEnvironmentConfigs(newFeature, environments);
}

/**
 * Given a percentage_included value from a feature API entity's rollout rule,
 * return a string that can be displayed to the user.
 * percentage_included in the API is an integer from 0 to 10000 inclusive.
 * @param {Number} percentageIncluded
 * @returns {String}
 */
export function formatPercentageIncluded(percentageIncluded) {
  return (percentageIncluded / 100).toString();
}

/**
 * Given a feature, return true if running in at least one restricted environment.
 * @param feature {Immutable.Map}
 * @param environmentsByKey {Immutable.List}
 * @returns {Boolean}
 */
export function isFeatureRunningInRestrictedEnvironment(
  feature,
  environmentsByKey,
) {
  if (!feature || !environmentsByKey) {
    return false;
  }

  return environmentsByKey
    .toList()
    .filter(env => env.get('has_restricted_permissions'))
    .map(env => env.get('key'))
    .some(envKey =>
      feature
        .get('environments')
        .getIn([envKey, 'rollout_rules'])
        .some(
          rolloutRule =>
            rolloutRule.get('status') ===
            LayerExperimentEnums.EnvironmentStatus.RUNNING,
        ),
    );
}

export function getIsFlagOnForEnvironment(environment) {
  const env = toImmutable(environment);

  return featureFlag => {
    const flag = toImmutable(featureFlag);

    const isFeatureRunning =
      flag.getIn([
        'environments',
        env.get('key'),
        'rollout_rules',
        0,
        'status',
      ]) === 'running';

    return !!isFeatureRunning;
  };
}

defaultExportObj = {
  cleanAudiencesJson,
  createFeatureEntity,
  getDefaultValueForType,
  getPrimaryEnvironmentRolloutInfoOfFeature,
  isFeatureRunningInRestrictedEnvironment,
  isVariableValueInputValid,
  createNewFeatureWithEnvironmentConfig,
  ensureDefaultEnvironmentConfig,
  getPrimaryEnvironmentEntry,
  getIsFlagOnForEnvironment,
  parseTraffic,
  formatInitialPercentageIncluded,
  updateRollout,
  getRolloutPropertyValue,
  ensureEnvironmentConfigs,
  createNewFeatureWithEnvironmentConfigs,
  formatPercentageIncluded,
  updateRolloutForEnvironment,
};
export default defaultExportObj;

const flux = require('core/flux');
const ui = require('core/ui').default;

const CurrentProjectGetters = require('optly/modules/current_project/getters');
const PermissionsFns = require('optly/modules/permissions/fns').default;

const TopbarComponent = require('bundles/p13n/sections/features_old/components/topbar');

const template = require('./template.html');
const FeaturesDashboardComponent = require('./main_content');

module.exports = {
  componentId: 'features-old-dashboard',

  replace: true,

  template,

  components: {
    topbar: TopbarComponent,
  },

  ready() {
    const canManageFeatureFlags = flux.evaluate([
      CurrentProjectGetters.project,
      PermissionsFns.canManageFeatureFlags,
    ]);

    ui.renderReactComponent(this, {
      component: FeaturesDashboardComponent,
      el: this.$$.reactContainer,
      props: {
        canManageFeatureFlags,
      },
    });
  },
};

import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

/**
 * Concurrent Editing Store
 */
export default Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   */
  getInitialState() {
    return toImmutable({
      // The unique identifier for this specific browser tab (set in sessionStorage)
      connectionId: null,

      // The concurrency entities of interest for the logged in user (ones they are subscribed to).
      entities: {},

      // Instances of firebase.database().ref by keypath
      refsByKeypath: {},
    });
  },

  initialize() {
    this.on(
      actionTypes.SET_CURRENT_STATE_FOR_ENTITY,
      handleSetEntityCurrentState,
    );
    this.on(actionTypes.SET_CONNECTION_ID, handleSetConnectionId);
    this.on(actionTypes.SET_FIREBASE_DB_REF, handleStoreRef);
    this.on(actionTypes.REMOVE_FIREBASE_DB_REF, handleRemoveRef);
  },
});

/**
 * Handler for actionTypes.SET_CURRENT_STATE_FOR_ENTITY
 * Sets the current state of the entityId specified.
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 * @param {number} payload.type
 * @param {Object} payload.currentState
 */
function handleSetEntityCurrentState(state, payload) {
  return state.setIn(
    ['entities', payload.type, payload.id],
    toImmutable(payload.currentState),
  );
}

/**
 * Handler for actionTypes.SET_CONNECTION_ID
 * Sets the current connectionId as specified.
 *
 * @param {Immutable.Map} state
 * @param {Object} payload - The connectionId to set.
 */
function handleSetConnectionId(state, payload) {
  return state.set('connectionId', payload);
}

/**
 * Handler for actionTypes.SET_FIREBASE_DB_REF
 * Sets the ref for the keypath specified.
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.keypath
 * @param {Object} payload.ref
 */
function handleStoreRef(state, payload) {
  return state.setIn(['refsByKeypath', payload.keypath], payload.ref);
}

/**
 * Handler for actionTypes.REMOVE_FIREBASE_DB_REF
 * Removes the ref for the keypath specified.
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.keypath
 */
function handleRemoveRef(state, payload) {
  return state.deleteIn(['refsByKeypath', payload.keypath]);
}

import $ from 'jquery';
import querySelector from 'optly/utils/query_selector';

const MUTATION_OBSERVER_CONFIG = {
  attributes: true,
  childList: true,
  subtree: true,
  characterData: true,
};

/**
 * Wait until given condition is true
 * @param {Function} predicate  A boolean-valued function that returns true once the condition is met
 * @param {Number}   maxAttempts How many times to poll
 * @param {Number}   interval    How long between each poll
 * @return {Promise}
 */
export function waitUntil(predicate, maxAttempts = 3, interval = 500) {
  const promise = $.Deferred();
  let attempts = 0;
  const poll = window.setInterval(() => {
    if (attempts > maxAttempts) {
      window.clearInterval(poll);
    }

    if (predicate()) {
      window.clearInterval(poll);
      promise.resolve();
    }
    attempts++;
  }, interval);

  return promise;
}

/**
 * Method to detect when a given selector appears in the DOM
 * using a MutationObserver for optimal performance.
 * @param selector
 * @returns {Promise}
 */
export function waitForSelector(selector) {
  const getElement = () => querySelector.querySelector(selector);
  return new Promise(resolve => {
    if (getElement()) {
      resolve(getElement());
    } else if (window.MutationObserver) {
      const observer = new MutationObserver(function() {
        if (getElement()) {
          this.disconnect();
          resolve(getElement());
        }
      });
      observer.observe(window.document, MUTATION_OBSERVER_CONFIG);
    } else {
      waitUntil(getElement, 100, 25).then(() => resolve(getElement()));
    }
  });
}

export default {
  waitForSelector,
  waitUntil,
};

import { toImmutable } from 'optly/immutable';

export const CREATE_SECTION_FORM_INITIAL_STATE = toImmutable({
  name: '',
  variations: [
    {
      name: tr('Original'),
      weight: 5000,
      actions: [],
    },
    {
      name: tr('Variation #1'),
      weight: 5000,
      actions: [],
    },
  ],
});

export default {
  CREATE_SECTION_FORM_INITIAL_STATE,
};

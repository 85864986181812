/**
 * Condition Group Component
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const _ = require('lodash');
const flux = require('core/flux');
const PermissionsGetters = require('optly/modules/permissions/getters');

const ConditionTemplate = require('./conditions/condition_template');

const HIDE_ADD_BUTTON_DIMENSIONS = [
  'code',
  'location',
  'behavior',
  'default_behavior',
  'dynamic_customer_profile',
  'visitor',
];

const HIDE_VALUE_BUTTONS_DIMENSIONS = [
  'has_ppid',
  'first_session',
  'ios_is_phone',
  'ios_is_retina',
  'ios_is_tablet',
];

module.exports = {
  // Base component for conditions. Template comes from services/targeting_conditions.
  components: {
    'condition-template': ConditionTemplate,
  },

  data: {
    /**
     * @var {ConditionGroup}
     */
    conditionGroup: null,
    /**
     * @var {String}
     */
    conditionType: null,
    /**
     * For custom dimensions the name is meaningful
     * @var {String}
     */
    customDimensionName: null,
    /**
     * For custom attributes the name is meaningful
     * @var {String}
     */
    customAttributeName: null,
    /**
     * For list attributes the name is meaningful
     * @var {String}
     */
    listAttributeName: null,
    /**
     * NOTE: This property is overwritten by Third Party Dimensions. Please do not remove.
     * @var {Object}
     */
    conditionConfig: {},
  },

  computed: {
    /**
     * Use computed negate so 'true' and 'false' can be converted to their boolean values
     */
    negate: {
      $get() {
        return this.conditionGroup.negate ? 'true' : 'false';
      },
      $set(val) {
        this.conditionGroup.negate = val !== 'false';
      },
    },

    /**
     * Only show add button if its not one of these condition types.
     */
    showAddButton() {
      if (this.canUseListAttributes && this.conditionType === 'list') {
        return false;
      }

      return HIDE_ADD_BUTTON_DIMENSIONS.indexOf(this.conditionType) === -1;
    },

    /**
     * Only show value block and buttons if its not one of these condition types
     */
    showValueButtons() {
      if (this.canUseListAttributes && this.conditionType === 'list') {
        return false;
      }

      return HIDE_VALUE_BUTTONS_DIMENSIONS.indexOf(this.conditionType) === -1;
    },
  },

  methods: {
    /**
     * Check if the condition has a matchType in the list, if so, set the value to undefined
     * @param {Condition} condition
     * @param {Array} matchTypes
     */
    clearValueForMatchTypes(condition, matchTypes) {
      if (_.includes(matchTypes, condition.match_type)) {
        condition.value = undefined;
      }
    },
    /**
     * Check if a field is required in the dimensions configuration
     * @param {String} field
     */
    isRequired(field) {
      return this.dimension.fields[field].required;
    },
    /**
     * Return true if any condition has the inputted match_type
     * @param {String} matchType
     * @return {Boolean}
     */
    hasMatchType(matchType) {
      return _.some(
        this.conditionGroup.conditions,
        condition => condition.match_type === matchType,
      );
    },
    /**
     * Return the placeholder text for the specific match type
     * @param {String} matchType
     * @return {String}
     */
    placeholderText(valueString, matchType) {
      let text = '';
      switch (matchType) {
        case 'exact':
          text = valueString;
          break;
        case 'substring':
          text = `${valueString}(substring)`;
          break;
        case 'regex':
          text = `${valueString}(regular expression)`;
          break;
      }
      return text;
    },
    /**
     * Helper method to get the current index of a condition
     *
     * Cannot use the $index property of the loop because it does not get
     * updated when conditions are removed
     *
     * @param {ConditionGroup|Condition} condition
     * @return {number}
     */
    conditionIndex(condition) {
      return this.conditionGroup.conditions.indexOf(condition);
    },
    /**
     * Determine if a condition is the last one in the array.
     * @param {Condition} condition
     * @return {Boolean}
     */
    isLastSubCondition(condition) {
      return (
        this.conditionIndex(condition) ===
        this.conditionGroup.conditions.length - 1
      );
    },
    /**
     * Add an empty condition to the conditionGroup
     */
    addEmptyCondition() {
      // TODO get this information from the injected conditionConfig when the targeting_condition service
      // associates a conditionType => component
      const condition = {
        type: this.conditionType,
      };
      if (this.customDimensionName) {
        condition.name = this.customDimensionName;
      } else if (this.customAttributeName) {
        condition.name = this.customAttributeName;
      } else if (this.listAttributeName) {
        condition.name = this.listAttributeName;
      }
      this.conditionGroup.conditions.push(condition);
    },
    /**
     * Removes condition from the group
     * @param {ConditionGroup|Condition} condition
     */
    removeCondition(condition) {
      this.conditionGroup.removeCondition(condition);

      // trigger to ancestor VMs that the last condition was removed
      if (this.conditionGroup.conditions.length === 0) {
        this.removeConditionGroup();
      }
    },
    removeConditionGroup() {
      this.$dispatch('unwarnAboutCondition', {
        type: this.conditionGroup.conditions[0].type,
        name: this.conditionGroup.conditions[0].warnName,
      });
      this.conditionGroup.conditions = [];
      this.$dispatch('conditionRemoved');
    },
  },

  ready() {
    if (this.conditionType === 'custom_dimension') {
      // extract the name from the first condition object in the conditionGroup
      // For custom dimensions all names are the same
      this.customDimensionName = this.conditionGroup.conditions[0].name;
    }
    if (this.conditionType === 'custom_attribute') {
      // extract the name from the first condition object in the conditionGroup
      // For custom attributes all names are the same
      this.customAttributeName = this.conditionGroup.conditions[0].name;
    }
    if (this.conditionType === 'list') {
      // extract the name from the first condition object in the conditionGroup
      // For list attributes all names are the same
      this.listAttributeName = this.conditionGroup.conditions[0].name;
    }
  },

  created() {
    flux.bindVueValues(this, {
      canUseListAttributes: PermissionsGetters.canUseListAttributes,
    });
  },
};

export const keyFieldLocations = {
  COOKIE: 'cookie',
  JS_VARIABLE: 'js_variable',
  QUERY_PARAM: 'query_param',
  UID: 'uid',
};

export const dcpSteps = {
  FLOW_INTRO: 'flow-intro',
  FLOW_CONFIGURE_DATASOURCE: 'flow-configure-datasource',
  FLOW_EDIT_ATTRIBUTES: 'flow-edit-attributes',
  FLOW_DATASOURCE_UPLOAD: 'flow-datasource-upload',
};

export const ALLOWED_FILE_EXTENSIONS = ['csv', 'tsv'];
export const ALLOWED_FILE_TYPES = ['text/csv', 'text/tab-separated-values'];
export const FILE_SIZE_LIMIT_IN_BYTES = 5000000; /* 5 MB */

export default {
  ALLOWED_FILE_TYPES,
  ALLOWED_FILE_EXTENSIONS,
  dcpSteps,
  FILE_SIZE_LIMIT_IN_BYTES,
  keyFieldLocations,
};

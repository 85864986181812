import PropTypes from 'prop-types';
import React from 'react';
import Vue from 'vue';

/*
 * Higher order component to wrap a Vue component in a React component
 * Makes it possible to render Vue components within React components
 * @param {Object} vueComponentConfig
 * @param {VueComponent} parent
 *
 * @returns {Function}
 */
function getReactWrapperForVueComponent(
  vueComponentConfig,
  parent,
  options,
  data,
) {
  const VueComponent = Vue.extend(Object.assign({}, vueComponentConfig));

  class VueWrapper extends React.Component {
    constructor(props) {
      super(props);
      /* bind non-standard methods, see: https://github.com/goatslacker/alt/issues/283 */
      this.createRef = this.createRef.bind(this);
    }

    shouldComponentUpdate() {
      return false;
    }

    componentDidMount() {
      const config = Object.assign({}, options, { parent, data });
      this.vueComponent = new VueComponent(config);
      this.vueComponent.$appendTo(this.el);

      if (this.props.vueComponentRef) {
        this.props.vueComponentRef(this.vueComponent);
      }
    }

    componentWillUnmount() {
      if (this.vueComponent) {
        this.vueComponent.$destroy();
      }

      if (this.props.vueComponentRef) {
        this.props.vueComponentRef(null);
      }
    }

    createRef(el) {
      this.el = el;
    }

    render() {
      return (
        <div className={this.props.className || ''} ref={this.createRef} />
      );
    }
  }

  VueWrapper.propTypes = {
    className: PropTypes.string,
    vueComponentRef: PropTypes.func,
  };

  return VueWrapper;
}

export default getReactWrapperForVueComponent;

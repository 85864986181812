import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';

import LayerEnums from '../layer/enums';

/**
 * Human readable translations of enums and other values
 */
export const CHANGE_TYPES = {
  custom_code: tr('Custom Code'),
  custom_js: tr('Custom Javascript'),
  custom_css: tr('Custom CSS'),
};

export const CHANGE = tr('Change');
export const REDIRECT = tr('Redirect');

export const STATUS_BY_ACTUAL_STATUS = {
  [LayerExperimentEnums.ActualStatus.ARCHIVED]: tr('Archived'),
  [LayerExperimentEnums.ActualStatus.RUNNING]: tr('Running'),
  [LayerExperimentEnums.ActualStatus.PAUSED]: tr('Paused'),
  [LayerExperimentEnums.ActualStatus.NOT_STARTED]: tr('Draft'), // Draft is used for layer experiments in Fullstack
  [LayerExperimentEnums.ActualStatus.CAMPAIGN_PAUSED]: tr('Paused'),
};

export const EXPERIMENT_ENVIRONMENT_STATUS = {
  [LayerExperimentEnums.EnvironmentStatus.ARCHIVED]: tr('Archived'),
  [LayerExperimentEnums.EnvironmentStatus.RUNNING]: tr('Running'),
  [LayerExperimentEnums.EnvironmentStatus.PAUSED]: tr('Paused'),
  [LayerExperimentEnums.EnvironmentStatus.NOT_STARTED]: tr('Draft'),
};

export const EXPERIMENT_ENVIRONMENT_STATUS_LOWER = {
  [LayerExperimentEnums.EnvironmentStatus.RUNNING]: tr('running'),
  [LayerExperimentEnums.EnvironmentStatus.PAUSED]: tr('paused'),
  [LayerExperimentEnums.EnvironmentStatus.NOT_STARTED]: tr('draft'),
};

export const MULTIVARIATE_TRAFFIC_POLICIES = {
  [LayerExperimentEnums.multivariateTrafficPolicies.FULL_FACTORIAL]: tr(
    'Full Factorial',
  ),
  [LayerExperimentEnums.multivariateTrafficPolicies.PARTIAL_FACTORIAL]: tr(
    'Partial Factorial',
  ),
};

export const FULLSTACK_EXPERIMENT_TYPES = {
  [LayerEnums.policy.MULTIVARIATE]: tr('Multivariate Test'),
  [LayerEnums.policy.SINGLE_EXPERIMENT]: tr('A/B Test'),
  feature: tr('Feature Test'),
  multiArmedBandit: tr('Multi-Armed Bandit'),
};

export default {
  CHANGE_TYPES,
  CHANGE,
  REDIRECT,
  STATUS_BY_ACTUAL_STATUS,
  EXPERIMENT_ENVIRONMENT_STATUS,
  EXPERIMENT_ENVIRONMENT_STATUS_LOWER,
  MULTIVARIATE_TRAFFIC_POLICIES,
  FULLSTACK_EXPERIMENT_TYPES,
};

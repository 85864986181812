import flux from 'core/flux';

import CacheHitTrackingGetters from './getters';
import CacheHitTrackingActionTypes from './action_types';

/**
 * When an API request hits either entity cache or local storage cache in the
 * REST API module, this action is called to track it.
 *
 * @param {String} entity The name of the entity that is cached
 */
export const trackCacheHit = entity => {
  const isTrackingCacheHits = flux.evaluate(
    CacheHitTrackingGetters.isTrackingCacheHits,
  );

  if (!isTrackingCacheHits) {
    return;
  }

  const entitiesWithCacheHits = flux.evaluate(
    CacheHitTrackingGetters.entitiesWithCacheHits,
  );
  if (!entitiesWithCacheHits.includes(entity)) {
    flux.dispatch(CacheHitTrackingActionTypes.TRACK_CACHE_HIT, { entity });
  }
};

/**
 * When a new route transition starts, this action is called by the router to
 * flush old tracking data out of the store + new relic SDK.
 */
export const trackRouteStart = () => {
  flux.dispatch(CacheHitTrackingActionTypes.START_TRACKING_CACHE_HITS);
};

/**
 * When a route transition completes, this action is called by the router to
 * stop tracking cache hits.
 */
export const trackRouteComplete = () => {
  flux.dispatch(CacheHitTrackingActionTypes.STOP_TRACKING_CACHE_HITS);
};

export default {
  trackCacheHit,
  trackRouteStart,
  trackRouteComplete,
};

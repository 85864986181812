/**
 * @author Derek Hammond (derek@optimizely.com)
 */
import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      directToExternalSupportPortal: false,
      showSchedulePhoneCall: false,
    });
  },

  initialize() {
    this.on(
      actionTypes.SUPPORT_SET_PORTAL_VERSION,
      setDirectToExternalSupportPortal,
    );
    this.on(
      actionTypes.SUPPORT_SET_CALL_SCHEDULER_VISIBILITY,
      setShowSchedulePhoneCall,
    );
  },
});

/**
 * Handler for actionTypes.SUPPORT_SET_PORTAL_VERSION
 *
 * @param {Immutable.Map} state
 */
function setDirectToExternalSupportPortal(
  state,
  { directToExternalSupportPortal },
) {
  return state.set(
    'directToExternalSupportPortal',
    directToExternalSupportPortal,
  );
}

/**
 * Handler for actionTypes.SUPPORT_SET_CALL_SCHEDULER_VISIBILITY
 *
 * @param {Immutable.Map} state
 */
function setShowSchedulePhoneCall(state, showSchedulePhoneCall) {
  return state.set('showSchedulePhoneCall', { showSchedulePhoneCall });
}

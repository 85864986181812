/**
 * Service for working with errors
 *
 * @author Tyler Brandt (tyler@optimizely.com)
 */
const $ = require('jquery');
const config = require('atomic-config');

const guid = require('optly/utils/guid');
const {
  withScope: withSentryScope,
  captureMessage: captureSentryMessage,
} = require('optly/modules/sentry/actions');

/**
 * Error handler for handing javascript ajax errors.
 * Shows an error dialog and logs the error to /util/log_bundle_error
 *
 * @param {Object} jqXHR
 * @param {String} textStatus
 *
 * @return {Object}
 */
exports.getAjaxErrorData = function(jqXHR, textStatus) {
  // Don't need to worry about failed AJAX requests if page is unloading.
  // Don't alert user about request that was programmatically aborted.
  // jqXHR.optlyErrorHandled is true when another function has handled the error
  // TODO add unloading logic for when the page is unloading
  if (textStatus === 'abort' || jqXHR.optlyErrorHandled) {
    return;
  }

  let errorMessage = '';
  let errorId;

  if (jqXHR && jqXHR.responseText) {
    try {
      const responseJSON = $.parseJSON(jqXHR.responseText);
      if (responseJSON.errors) {
        // Field validation error(s)
        errorMessage = responseJSON.errors.join('; ');
      } else if (responseJSON.message) {
        // REST API, any other error
        errorMessage = responseJSON.message;
      } else if (responseJSON.error) {
        // old API, app.py handlers
        errorMessage = responseJSON.error;
      }
      // REST API
      errorId = responseJSON.uuid;
      if (!errorId) {
        // old API, app.py handlers
        errorId = responseJSON.id;
      }
    } catch (e) {
      // handle
    }
  }

  // create options object to log to server
  const options = {};
  if (jqXHR && jqXHR.options) {
    if (jqXHR.options.optimizelyIgnoreErrors) {
      return;
    }
    $.each(
      [
        'contentType',
        'dataType',
        'optimizelyRetryLimit',
        'optimizelyRetryCount',
        'type',
        'url',
        'contents',
      ],
      (index, value) => {
        options[value] = jqXHR.options[value];
      },
    );
  }

  const accountId = config.get('account_info.account_id');
  const errorInfo = {
    options,
    status: textStatus,
    statusCode: jqXHR && jqXHR.status,
    accountId,
  };

  return {
    errorId: errorId || guid(),
    message: errorMessage,
    errorDetails: errorInfo,
    errorUrl:
      jqXHR && jqXHR.originalRequestOptions && jqXHR.originalRequestOptions.url,
    type:
      jqXHR &&
      jqXHR.originalRequestOptions &&
      jqXHR.originalRequestOptions.type,
    data:
      jqXHR &&
      jqXHR.originalRequestOptions &&
      jqXHR.originalRequestOptions.data,
    response:
      jqXHR && jqXHR.responseText && jqXHR.responseText.substring(0, 100),
  };
};

/**
 * This method logs to Sentry
 *
 * @param {string} source
 * @param {Object} jsonData
 */
exports.logToServer = function(source, jsonData) {
  let env = 'unknown';
  if (window.optlyConfig && window.optlyConfig.env) {
    if (!window.optlyConfig.env.USE_SENTRY) {
      console.error('An error occurred:', source, jsonData);
      return;
    }

    if (window.optlyConfig.env.USE_SENTRY) {
      env = window.optlyConfig.env.ENVIRONMENT;
    }
  }

  /**
   * PLEASE NOTE: Direct use of the Sentry SDK is discouraged except in
   * specific cases. To dispatch events to Sentry, please use the Sentry SDK
   * wrapper at optly/modules/sentry.
   */
  withSentryScope(scope => {
    scope.setTag('error_dialog', jsonData.wasErrorDialogShown ? 'yes' : 'no');
    scope.setTag('origin', jsonData.isServerOriginated ? 'server' : 'client');
    scope.setTag('env', env);
    scope.setTag('error_id', jsonData.errorId);
    scope.setTag('error_location', jsonData.errorLocation);

    scope.setExtra('type', jsonData.type);
    scope.setExtra('data', jsonData.data);
    scope.setExtra('response', jsonData.response);
    scope.setExtra('accountId', jsonData.accountId);
    scope.setExtra('errorId', jsonData.errorId);
    scope.setExtra('errorMessage', jsonData.errorMessage);
    scope.setExtra('url', jsonData.errorUrl);
    scope.setExtra('stackTrace', jsonData.stackTrace);
    scope.setExtra('status', jsonData.status);
    scope.setExtra('statusCode', jsonData.statusCode);
    scope.setExtra('timestamp', jsonData.timestamp);

    captureSentryMessage(jsonData.errorMessage);
  });
};

import constants from './constants';
import fns from './fns';
import getters from './getters';

export { constants, fns, getters };

export default {
  constants,
  fns,
  getters,
};

import $ from 'jquery';
import _ from 'lodash';

import flux from 'core/flux';
import ui from 'core/ui';

import { ChangeEditorSidebar as ChangeEditorSidebarMixin } from 'bundles/p13n/modules/ui/mixins';
import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import DependencyManagement from 'bundles/p13n/components/editor/sidebar/dependency_management';
import Editor from 'bundles/p13n/modules/editor';
import {
  enums as EditorIframeEnums,
  getters as EditorIframeGetters,
} from 'bundles/p13n/modules/editor_iframe';
import ImageManagerConstants from 'bundles/p13n/components/image_input_manager/constants';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import { fns as PermissionsModuleFns } from 'optly/modules/permissions';

import InsertOperatorDropdown from 'bundles/p13n/components/insert_operator_dropdown';
import ImageInputManager from 'bundles/p13n/components/image_input_manager';
import SelectorInput from 'bundles/p13n/components/selector_input';

import SidebarHeaderComponent from '../sidebar_header';
import ComponentHtmlTemplate from './template.html';

const InsertImageSidebarComponent = {
  replace: true,

  componentId: 'p13n-insert-image-sidebar',

  components: {
    'insert-operator-dropdown': InsertOperatorDropdown,
    'sidebar-header': SidebarHeaderComponent,
  },

  computed: {
    statusClassForAttributes() {
      return {
        html: this.getStatusClassForAttribute(this.changeStatusMap.html),
        selectorAndOperator: this.getStatusClassForAttribute([
          this.changeStatusMap.selector,
          this.changeStatusMap.operator,
        ]),
      };
    },
  },

  data: {
    changeStatuses: LayerExperimentEnums.ChangeStatuses,
  },

  template: ComponentHtmlTemplate,

  methods: {
    /**
     * Based on the element clicked on, select or create a change.
     *
     * Because this component is not re-instantiated for the new change, we must manually
     * do a lot of setting to update the values of the input fields based on the new element.
     * @param {object} payload
     * @param {string} payload.selector
     * @param {Array} payload.selectorInfo
     */
    handleIframeClick(payload) {
      if (this.isEditingSelector) {
        return;
      }
      Editor.actions.discardChangesAndSetChangeBasedOnSelector(payload);
    },

    getImageURL() {
      if (this.currentlyEditingChange.value) {
        const imageElement = $(this.currentlyEditingChange.value);
        return imageElement.length ? imageElement[0].getAttribute('src') : '';
      }

      return '';
    },

    handleInsertOperatorChanged(operator) {
      Editor.actions.setInsertHTMLOperator(operator);

      if (this.currentlyEditingChangeSelector) {
        Editor.actions.applyCurrentlyEditingChange();
      }
    },

    revertImageURL() {
      Editor.actions.revertInsertHTMLValue();
      Editor.actions.applyCurrentlyEditingChange();
    },

    /**
     * Wrapper to validate the selector before saving.
     */
    saveChangeAndShowChangeListSidebar() {
      ChangeEditorSidebarMixin.methods.saveChangeAndShowChangeListSidebar.call(
        this,
        this.changeListSidebarComponentConfig,
      );
    },

    setImageURL(url) {
      const html = `<img src='${url}' />`;
      Editor.actions.setCurrentlyEditingInsertHTMLChangeValue(html);
      // Don't try to apply changes when there is no selector
      if (this.currentlyEditingChangeSelector) {
        Editor.actions.applyCurrentlyEditingChange();
      }
    },

    /**
     * Setup the insert image sidebar input fields.
     */
    setupChange() {
      this.$emit(
        ImageManagerConstants.EventTypes.CALCULATE_IMAGE_PORPERTIES,
        this.getImageURL(),
      );
    },
  },

  created() {
    flux.bindVueValues(this, {
      activeFrameId: Editor.getters.activeFrameId,
      changeListSidebarComponentConfig:
        Editor.getters.changeListSidebarComponentConfig,
      changeStatusMap: Editor.getters.changeStatusMap(),
      currentLayer: CurrentLayerGetters.layer,
      currentlyEditingChange: Editor.getters.currentlyEditingChange,
      currentlyEditingChangeIsDirty:
        Editor.getters.currentlyEditingChangeIsDirty,
      currentlyEditingChangeSelector:
        Editor.getters.currentlyEditingChangeSelector,
      currentlyEditingChangeTitle: Editor.getters.currentlyEditingChangeTitle,
      isEditingSelector: Editor.getters.changeEditorIsEditingSelector,
      isEditorReadOnly: Editor.getters.isEditorReadOnly,
      canUpdateLayerExperiment: [
        CurrentProjectGetters.project,
        PermissionsModuleFns.canUpdateLayerExperiment,
      ],
      shouldShowDependencyManagement: Editor.getters.showDependencyManagement,
    });
  },

  ready() {
    this.$iframeComponent = flux.evaluateToJS(
      EditorIframeGetters.iframeComponent(this.activeFrameId),
    );
    this.$iframeClickCallback = this.handleIframeClick.bind(this);

    if (this.$iframeComponent) {
      this.$iframeComponent.$on(
        EditorIframeEnums.IFrameMessageTypes.CLICK,
        this.$iframeClickCallback,
      );
    }

    this.__unwatchCurrentlyEditingChange = flux.observe(
      Editor.getters.currentlyEditingChange,
      this.setupChange.bind(this),
    );

    ui.renderReactComponent(this, {
      component: ImageInputManager,
      el: this.$$.imageInputManager,
      dataBindings: {
        isEditorReadOnly: Editor.getters.isEditorReadOnly,
        isEditingSelector: Editor.getters.changeEditorIsEditingSelector,
      },
      props: {
        setupChange: this.setupChange.bind(this),
        onImageChange: this.setImageURL.bind(this),
        imageEditingEnabled: !this.isEditingSelector,
      },
    });

    ui.renderReactComponent(this, {
      component: InsertOperatorDropdown,
      el: this.$$.insertOperatorDropdown,
      props: {
        onOperatorChangeCallback: this.handleInsertOperatorChanged.bind(this),
        initialOperator: this.currentlyEditingChange.operator,
      },
    });

    ui.renderReactComponent(this, {
      component: DependencyManagement,
      el: this.$$.dependencyManagement,
      dataBindings: {
        dependsOnPrevious:
          Editor.getters.currentlyEditingChangeDependsOnPrevious,
      },
      props: {
        // Sets the change dependencies property for the currently editing change.
        handleChangeDependencyClick:
          Editor.actions.setCurrentlyEditingChangeDependencies,
      },
    });

    // Mount the SelectorInput React component for the Insert Image's SelectorInputTypes.INSERT_IMAGE_SELECTOR
    ui.renderReactComponent(this, {
      component: SelectorInput,
      el: this.$$.insertImageSelectorInput,
      dataBindings: {
        currentSelectorHighlightOptions:
          Editor.getters.currentlyEditingChangeHighlighterOptions,
        iframe: EditorIframeGetters.iframeComponent(this.activeFrameId),
        isDisabled: Editor.getters.selectorInputIsDisabled(
          Editor.constants.SelectorInputTypes.INSERT_IMAGE_SELECTOR,
        ),
        mountInSearchMode: [
          Editor.getters.currentlyEditingChangeSelector,
          currentlyEditingChangeSelector => !currentlyEditingChangeSelector,
        ],
        value: Editor.getters.currentlyEditingChangeSelector,
      },
      props: {
        onChange: Editor.actions.onChangeSelectorInput,
        onEditStart: _.partial(
          Editor.actions.toggleElementSelection,
          true,
          Editor.constants.SelectorInputTypes.INSERT_IMAGE_SELECTOR,
        ),
        onEditStop: _.partial(
          Editor.actions.toggleElementSelection,
          false,
          Editor.constants.SelectorInputTypes.INSERT_IMAGE_SELECTOR,
        ),
        selectorInputType:
          Editor.constants.SelectorInputTypes.INSERT_IMAGE_SELECTOR,
      },
    });
  },

  beforeDestroy() {
    if (this.__unwatchCurrentlyEditingChange) {
      this.__unwatchCurrentlyEditingChange();
    }

    if (this.$iframeComponent) {
      this.$iframeComponent.$off(
        EditorIframeEnums.IFrameMessageTypes.CLICK,
        this.$iframeClickCallback,
      );
    }
  },
};

export default _.merge(
  {},
  ChangeEditorSidebarMixin,
  InsertImageSidebarComponent,
);

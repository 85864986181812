/**
 * @author Derek Hammond (derek@optimizely.com)
 */
import AjaxUtil from 'optly/utils/ajax';
import flux from 'core/flux';
import ui from 'core/ui';

// eslint-disable-next-line import/no-cycle
import SupportDialog from 'optly/components/support_dialog';

import actionTypes from './action_types';

/**
 * Sets whether customers user the external (Zendesk hosted) or internal support form
 * @param {Boolean} directToExternalSupportPortal
 */
export const setDirectToExternalSupportPortal = directToExternalSupportPortal => {
  flux.dispatch(actionTypes.SUPPORT_SET_PORTAL_VERSION, {
    directToExternalSupportPortal,
  });
};

/**
 * Sets whether customers see the phone call scheduling options
 * @param {Boolean} showSchedulePhoneCall
 */
export const setShowSchedulePhoneCall = showSchedulePhoneCall => {
  flux.dispatch(actionTypes.SUPPORT_SET_CALL_SCHEDULER_VISIBILITY, {
    showSchedulePhoneCall,
  });
};

/**
 * makes a request to abstracted Zendesk service to create ticket
 *
 * @param ticketInfo
 * @returns {Deferred}
 */
export const createZendeskTicket = ticketInfo =>
  AjaxUtil.makeV1AjaxRequest({
    url: '/support/submit',
    type: 'POST',
    data: ticketInfo,
  });

/**
 * fetches Zendesk organization summary for logged in user
 *
 * @returns {Deferred}
 */
export const getZendeskOrganizations = () =>
  AjaxUtil.makeV1AjaxRequest({
    url: '/support/organizations',
    type: 'GET',
  });

/**
 * Used in showSupportDialog. Exported only for testing purposes
 * @param {Function} [callbackFn] (optional)
 * @returns {Function}
 */
export const _getOnClose = callbackFn => () => {
  ui.hideDialog();
  if (callbackFn) {
    callbackFn();
  }
};

/**
 * Shows the support_dialog component
 * @param {Object} [props] (optional)
 * @param {Object} [config] (optional)
 */
export function showSupportDialog(props = {}, config = {}) {
  ui.showReactDialog(
    SupportDialog,
    {
      props: {
        ...props,
        onClose: _getOnClose(config.onHideDialog),
      },
    },
    {
      fullScreen: true,
      ...config,
    },
  );
}

export default {
  _getOnClose,
  createZendeskTicket,
  getZendeskOrganizations,
  setDirectToExternalSupportPortal,
  setShowSchedulePhoneCall,
  showSupportDialog,
};

import { parse as parseLinkHeader } from 'http-link-header';

import Clipboard from 'optly/utils/clipboard';
import HistoryUtils from 'optly/utils/history';
import parseQueryParams from 'optly/utils/parse_query_params';

import ui from 'core/ui';
import UrlHelper from 'optly/services/url_helper';

import PublicApiConsumerActions from 'optly/modules/public_api_consumer/actions';

import ComponentModuleFns from './fns';

/**
 * @name fetchChanges
 * @description Fetch changes from Frontdoor via the PublicApiConsumer
 *
 * @param {Object|String} filtersOrUrl
 * @param {Date|Null} filtersOrUrl.start_time
 * @param {Date|Null} filtersOrUrl.end_time
 * @param {Number} filtersOrUrl.per_page
 * @param {Number} filtersOrUrl.project_id
 * @param {Array.<Number>} filtersOrUrl.entity
 * @param {Array.<String>} filtersOrUrl.entity_type
 *
 * @returns {Promise}
 *     Returns a promise that resolves with an Object.
 */
export function fetchChanges(filtersOrUrl) {
  let filtersToFetch = filtersOrUrl;
  if (typeof filtersOrUrl === 'string') {
    filtersToFetch = parseQueryParams(filtersOrUrl);
  }

  // Removes unset filters, strip null values, converts ui filters to api filters
  const cleanedFilters = ComponentModuleFns.cleanFiltersForApi(filtersToFetch);

  return PublicApiConsumerActions.fetchPage('changes', {
    queryParams: {
      ...cleanedFilters,
    },
  })
    .then(response =>
      Promise.all([Promise.resolve(response.headers), response.json()]),
    )
    .then(([headers, changes]) => {
      const link = parseLinkHeader(headers.get('Link') ?? '') || {};
      return {
        changes,
        nextPageUrl: link.rel('next')[0]?.uri || null,
        queryTotalCount: Number(headers.get('X-Record-Total')),
      };
    });
}

/**
 * @name getCopyShareLinkAndNotify
 * @description generate a function that can be used to copy a single log share link
 *
 * @param currentLocationPathname {String}
 *  e.g. /v2/projects/3652058995/changes?id=10896719
 *       /v2/projects/3652058995/history?id=10896719
 *       /v2/projects/3652058995/experiments/3663633246/history?id=10896719
 * @param logId {Number}
 *
 * @returns {Function}
 *   Returns a function that uses the passed pathname and logId values to compute and return a log deep link.
 */
export function getCopyShareLinkAndNotify(currentLocationPathname, logId) {
  return () => {
    const logDeepLink = UrlHelper.changeHistoryLog(
      currentLocationPathname,
      logId,
    );
    Clipboard.copyToClipboard(logDeepLink);
    ui.showNotification({
      message: 'Share link copied',
    });
    return logDeepLink;
  };
}

/**
 * @name setRouteAndFetchChanges
 * @description
 *  Returns a Promise that removes unset filters, strips null values,
 *  computes safe filters and updates the route, and fetches the changes
 *  This is used to fetch the first page of changes for a new query. The fetchChanges()
 *  method can be used directly with the next page url for subsequent pages.
 *
 * @param filters {Object}
 *
 * @returns {Promise}
 */
export function setRouteAndFetchChanges(filters) {
  return new Promise((resolve, reject) => {
    // Computes safe filters and update the route with filters as params
    const [
      allowedFiltersFromState,
      filtersToRemove,
    ] = ComponentModuleFns.computeAllowedUrlFiltersFromState(filters);

    const urlSafeFilters = {
      ...ComponentModuleFns.computeAllowedUrlFiltersFromUrl(),
      ...allowedFiltersFromState,
    };

    HistoryUtils.pushStateAndAddAndRemoveQueryParams(
      urlSafeFilters,
      filtersToRemove,
    );

    this.fetchChanges(filters).then(resolve, reject);
  });
}

export default {
  fetchChanges,
  getCopyShareLinkAndNotify,
  setRouteAndFetchChanges,
};

import keyMirror from 'optly/utils/key_mirror';

export const SegmentEvents = {
  DIALOG_DISMISS: 'Features Intro Dialog: closed',
  DIALOG_CLICK_TO_DOCS: 'Features Intro Dialog: clicked link to docs',
};

export const IntroDialogPerformanceMarkKeys = keyMirror({
  DIALOG_MOUNTED_START: null,
  DIALOG_TIMER_STOP_ON_DISMISS: null,
  DIALOG_TIMER_STOP_ON_LINK_CLICK: null,
});

export const LinkToDocs = {
  href: 'https://docs.developers.optimizely.com/rollouts/docs/quickstart',
};

export default { SegmentEvents, IntroDialogPerformanceMarkKeys, LinkToDocs };

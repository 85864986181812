import React from 'react';
import PropTypes from 'prop-types';

import {
  greenBase,
  greyLight,
} from '@optimizely/design-tokens/dist/json/colors.json';

import { Icon } from '@optimizely/axiom';

import keyMirror from 'optly/utils/key_mirror';

export const iconTypes = keyMirror({
  GREY_EMPTY: null,
  GREEN_CHECK: null,
  HALF_GREEN: null,
});

export function EnvironmentStatus(props) {
  let iconType;
  let color = greenBase;

  if (props.iconType === iconTypes.GREY_EMPTY) {
    iconType = 'circle-solid';
    color = greyLight;
  } else if (props.iconType === iconTypes.HALF_GREEN) {
    iconType = 'circle-half-stroke-solid';
  } else {
    iconType = 'check-circle-solid';
  }

  return (
    <span className="flex flex--1 push-half--bottom flex-align--center">
      <span className="flex flex-shrink--none">
        <Icon color={color} name={iconType} size="small" />
      </span>
      <span
        data-test-section="environment-name"
        className="push-half--left truncate max-width--200">
        {props.name}
      </span>
    </span>
  );
}

EnvironmentStatus.propTypes = {
  name: PropTypes.string.isRequired,
  iconType: PropTypes.oneOf(Object.keys(iconTypes)).isRequired,
};

export default EnvironmentStatus;

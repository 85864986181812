import config from 'atomic-config';
import locationHelper from 'optly/location';
import url from 'url';

/**
 * Triggers a standard logout
 *
 * This will redirect via window.location to /account/signout and specifies landing_page to be {env.HOST_URL}/login.
 */
export function logout(opts) {
  opts = opts || {};
  const query = {
    landing_page: config.get('env.HOST_URL'),
  };
  if (opts.idleLogout) {
    query.idleLogout = true;
  }

  const signoutUrl = url.format({
    pathname: '/account/signout',
    query,
  });

  locationHelper.setLocation(signoutUrl);
}

export default logout;

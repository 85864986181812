const PluginModuleEnums = require('optly/modules/entity/plugin/enums');

const omittedFields = {};

omittedFields[PluginModuleEnums.plugin_type.ANALYTICS_INTEGRATION] = [
  PluginModuleEnums.fieldType.SELECTOR,
  PluginModuleEnums.fieldType.MULTI_TEXT,
  PluginModuleEnums.fieldType.RICH_TEXT,
  PluginModuleEnums.fieldType.NUMBER,
  PluginModuleEnums.fieldType.HTML,
  PluginModuleEnums.fieldType.CSS,
  PluginModuleEnums.fieldType.TOGGLE,
  PluginModuleEnums.fieldType.MULTI_SELECT,
  PluginModuleEnums.fieldType.IMAGE,
  PluginModuleEnums.fieldType.COLOR,
];

exports.omittedFields = omittedFields;

/**
 * Getters for anything related directly to the migration from
 * the 1.0 stack to the 2.0 stack
 */
import AdminAccountGetters from 'optly/modules/admin_account/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
import PermissionsModuleGetters from 'optly/modules/permissions/getters';

import fns from './fns';

const canUsePersonalization = PermissionsModuleGetters.canUsePersonalization;

const canUseABTestingV1 = [
  AdminAccountGetters.accountPermissions,
  PermissionsModuleFns.canUseABTesting,
];

const canUseABTestingV2 = [
  AdminAccountGetters.accountPermissions,
  PermissionsModuleFns.canUseABTestingV2,
];

export const v2PlatformText = [
  canUsePersonalization,
  canUseABTestingV2,
  fns.getV2PlatformText,
];

export const v1PlatformText = [
  canUsePersonalization,
  canUseABTestingV2,
  fns.getV1PlatformText,
];

export const v2FirstRunText = [
  canUsePersonalization,
  canUseABTestingV2,
  CurrentProjectGetters.project,
  fns.getV2FirstRunText,
];

export const v2FirstRunImage = [
  canUsePersonalization,
  canUseABTestingV2,
  CurrentProjectGetters.project,
  fns.getV2FirstRunImage,
];

export const bundledSnippetText = [
  canUsePersonalization,
  canUseABTestingV2,
  fns.getBundledSnippetText,
];

export const standaloneSnippetText = [
  canUsePersonalization,
  canUseABTestingV2,
  fns.getStandaloneSnippetText,
];

/**
 * Getter indiciating whether the current project should be able
 * to show the 'Optimizely Classic' link in the nav bar to navigate
 * back to Optimizely Classic Testing.
 */
export const hasClassicEnabled = [
  canUseABTestingV1,
  CurrentProjectGetters.isClassicTestingEnabledInClient,
  CurrentProjectGetters.isCustomProject,
  (hasV1Permission, isClassicEnabledInClient, isCustomProject) =>
    (hasV1Permission || isClassicEnabledInClient) && !isCustomProject,
];

export default {
  v2PlatformText,
  v1PlatformText,
  v2FirstRunText,
  v2FirstRunImage,
  bundledSnippetText,
  standaloneSnippetText,
  hasClassicEnabled,
};

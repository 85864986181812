/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';

import { Attention, Button, Link } from 'optimizely-oui';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import Immutable, { toImmutable } from 'optly/immutable';
import ui from 'core/ui';

import { Form, formPropType } from 'react_components/form';
import LoadingOverlay from 'react_components/loading_overlay';

import ExperimentDetailsFormInput from 'bundles/p13n/sections/manager_fullstack/components/experiment_details_form_input';
import FeatureSelectorFormInput from 'bundles/p13n/sections/manager_fullstack/components/feature_selector_form_input';

import UpgradeFns from 'optly/modules/upgrade/fns';
import {
  constants as ManagerFullStackSectionModuleConstants,
  fns as ManagerFullStackSectionModuleFns,
} from 'bundles/p13n/sections/manager_fullstack/section_module';

@Form({
  mapPropsToFormData: ({ experiment }) => {
    const experimentFormData = {
      description: experiment.get('description'),
      feature_flag_id: experiment.get('feature_flag_id'),
      id: experiment.get('id'),
      key: experiment.get('key'),
      layer_policy: experiment.get('layer_policy'),
      // Need the form to keep track of variations field because we are updating each variation after choosing a feature
      variations: experiment.get('variations'),
    };

    if (isFeatureEnabled('user_friendly_names')) {
      experimentFormData.name = experiment.get('name');
    }
    return toImmutable(experimentFormData);
  },
  debounceTimeout: 0,
})
class CreateExperimentDialog extends React.Component {
  static propTypes = {
    canCreateLayerExperiment: PropTypes.bool,
    canUseFeatureManagement: PropTypes.bool,
    currentProjectExperiments: PropTypes.instanceOf(Immutable.List).isRequired,
    currentProjectFeatures: PropTypes.instanceOf(Immutable.List),
    environmentsAuthorizedAndSupported: PropTypes.bool.isRequired,
    experiment: PropTypes.instanceOf(Immutable.Map).isRequired,
    experimentType: PropTypes.oneOf(
      Object.values(ManagerFullStackSectionModuleConstants.ExperimentType),
    ).isRequired,
    form: formPropType.isRequired,
    isLoadingFeatures: PropTypes.bool,
    isRolloutsProject: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    runningExperimentLimit: PropTypes.number.isRequired,
  };

  static defaultProps = {
    canUseMultiArmedBandits: false,
    isRolloutsProject: false,
    isLoadingFeatures: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
    };
  }

  onCancelClick = () => {
    const { onCancel } = this.props;

    onCancel();
  };

  canSave = () => {
    const {
      canUseFeatureManagement,
      canCreateLayerExperiment,
      form,
      experimentType,
    } = this.props;

    const { isSaving } = this.state;

    const isFeatureTest =
      experimentType ===
      ManagerFullStackSectionModuleConstants.ExperimentType.FEATURE_TEST;
    const canCreateABTest = canCreateLayerExperiment;
    const canCreateFeatureTest = canUseFeatureManagement && isFeatureTest;

    /**
     * Only enable the Create button if:
     * 1. Users are creating AB tests
     * 2. OR Users are creating Feature tests
     * 3. AND the form is dirty, valid, and we're not saving an experiment.
     */
    return (
      (canCreateFeatureTest || canCreateABTest) &&
      form.isFormDirty() &&
      form.isFormValid() &&
      !isSaving
    );
  };

  onCreateClick = event => {
    event.preventDefault();
    const { experiment, form, onSave } = this.props;

    this.setState({
      isSaving: true,
    });
    const newExperimentFields = {
      description: form.field('description').getValue(),
      feature_flag_id: form.field('feature_flag_id').getValue(),
      id: form.field('id').getValue(),
      key: form.field('key').getValue(),
      layer_policy: form.field('layer_policy').getValue(),
      variations: form.field('variations').getValue(),
    };

    if (isFeatureEnabled('user_friendly_names')) {
      newExperimentFields.name = form.field('name').getValue();
    }

    const updatedExperiment = experiment.merge(newExperimentFields);

    form
      .validate()
      .then(() => onSave(updatedExperiment.toJS()))
      .catch(() => {
        // If there's an error, notify users
        ui.showNotification({
          message: 'Please provide valid experiment details',
          type: 'error',
        });
      })
      .finally(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  getExperimentLabel = () => {
    const { experimentType } = this.props;

    return ManagerFullStackSectionModuleFns.getExperimentTypeToLabel(
      experimentType,
    );
  };

  renderRolloutsLimitNotification = () => {
    const { runningExperimentLimit } = this.props;
    const upgradeDialogButtonLink = UpgradeFns.getUpgradeLink();
    return (
      <Attention
        alignment="left"
        type="brand"
        testSection="running-experiment-limit-notification">
        You can create unlimited feature tests, but only run{' '}
        {runningExperimentLimit > 1 ? (
          <span data-test-section="running-experiment-limit-number">
            {`${runningExperimentLimit}`}
          </span>
        ) : (
          <span data-test-section="running-experiment-limit-number">one</span>
        )}{' '}
        at a time.{' '}
        <Link newWindow={true} href={upgradeDialogButtonLink}>
          Upgrade to Optimizely Feature Experimentation
        </Link>{' '}
        to run unlimited feature tests.
      </Attention>
    );
  };

  renderFeatureSelector = () => {
    const {
      currentProjectExperiments,
      currentProjectFeatures,
      environmentsAuthorizedAndSupported,
      experimentType,
      form,
    } = this.props;

    /**
     * If we're not creating an AB_TEST or a Multi-Armed Bandit, we should show the Feature Selector because in both
     * Feature Test and MVT, users are able to select a feature for the test.
     *
     * We also don't need to check for canUseFeatureManagement permission because if users see the new dialog,
     * that means they are already allowed to use FeatureManagement, which is gated behind the feature flag.
     */
    if (
      experimentType !==
        ManagerFullStackSectionModuleConstants.ExperimentType.AB_TEST &&
      experimentType !==
        ManagerFullStackSectionModuleConstants.ExperimentType.MULTIARMED_BANDIT
    ) {
      return (
        <div className="push-quad--bottom soft-double--top">
          <FeatureSelectorFormInput
            currentProjectExperiments={currentProjectExperiments}
            currentProjectFeatures={currentProjectFeatures}
            environmentsAuthorizedAndSupported={
              environmentsAuthorizedAndSupported
            }
            form={form}
          />
        </div>
      );
    }
  };

  render() {
    const {
      currentProjectExperiments,
      experimentType,
      form,
      isRolloutsProject,
      isLoadingFeatures,
    } = this.props;

    const { isSaving } = this.state;

    return (
      <div
        className="reading-column"
        data-test-section="create-experiment-dialog">
        <LoadingOverlay isLoading={isSaving || isLoadingFeatures}>
          <div
            className="beta push-double--bottom"
            data-test-section="create-experiment-label">
            {`New ${this.getExperimentLabel()}`}
          </div>
          {isRolloutsProject && this.renderRolloutsLimitNotification()}
          {this.renderFeatureSelector()}
          {experimentType ===
            ManagerFullStackSectionModuleConstants.ExperimentType
              .MULTIARMED_BANDIT && (
            <div className="push-double--bottom width--1-1">
              <Attention alignment="left" type="brand">
                This type of optimization will not include statistical
                significance calculations.
              </Attention>
            </div>
          )}
          <div className="push-quad--bottom">
            <ExperimentDetailsFormInput
              currentProjectExperiments={currentProjectExperiments}
              experimentType={experimentType}
              form={form}
              handleOnSubmit={this.onCreateClick}
              updateExperimentProperty={(property, value) =>
                form.field(property).setValue(value)
              }
            />
          </div>
          <div className="lego-form__footer lego-button-row lego-button-row--right push-quad--bottom">
            <div className="flex flex-align--center">
              <div className="oui-sheet__required-indicator cursor--default color--red">
                <span>* Required field</span>
              </div>
              <div className="flex--1">
                <Button
                  onClick={this.onCancelClick}
                  testSection="cancel-button">
                  Cancel
                </Button>
                <Button
                  isDisabled={!this.canSave()}
                  onClick={this.onCreateClick}
                  style="highlight"
                  testSection="create-experiment-button">
                  {`Create ${this.getExperimentLabel()}`}
                </Button>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default CreateExperimentDialog;

/**
 * Router module provides a thin abstraction around the Nuclear Router
 *
 * @author Jordan Garcia
 */
import $ from 'jquery';

import _ from 'lodash';

import NuclearRouter from '@optimizely/nuclear-router';

import historyUtil from 'optly/utils/history';
import { tracking } from 'optly/modules/segment';

import $window from 'optly/window';

import PerformanceTrackingActions from 'optly/modules/performance_tracking/actions';
import OptimizelyChampagneActions from 'optly/modules/optimizely_champagne/actions';
import CacheHitTrackingActions from 'optly/modules/cache_hit_tracking/actions';
import stripIdsFromRoute from 'optly/utils/strip_ids_from_route';

import flux from 'core/flux';
import { getters as RegionGetters } from 'core/ui/region';

import getComponent from 'core/ui/methods/get_component';
import { getRootVM } from 'core/ui/methods/root_vm';
import { $broadcast } from 'core/ui/methods/events';
import getMainRegionVM from 'core/ui/methods/get_main_region_vm';
import { loadingStart } from 'core/modules/loading/actions';
import HistoryModule from '../history';

const router = new NuclearRouter({
  // Ignore SingleSpa's extraneous popstate events
  // More info here: https://github.com/single-spa/single-spa/pull/532
  filterPopstateEvent: event => !event.singleSpa,

  onRouteStart() {
    tracking.onRouteStart();

    PerformanceTrackingActions.setRouteStartTime();

    CacheHitTrackingActions.trackRouteStart();

    // set the page title to 'Optimizely' as a fallback to prevent erroneously lingering page titles
    document.title = 'Optimizely';
  },

  /**
   * @param {object} data
   * @param {string} data.toPath
   * @param {string} data.fromPath
   * @param {number} data.duration
   */
  onRouteComplete(data) {
    PerformanceTrackingActions.trackRouteComplete(data);

    CacheHitTrackingActions.trackRouteComplete();

    tracking.trackRouteComplete(data);

    OptimizelyChampagneActions.track('route_complete', {
      attributes: {
        to_path: stripIdsFromRoute(data.toPath),
        from_path: stripIdsFromRoute(data.fromPath),
      },
      eventTags: {
        value: data.duration,
      },
    });
  },
});

const exported = {
  /**
   * Expose the router's initialize method for convenience.
   */
  initialize: router.initialize.bind(router),

  /**
   * Expose the instance for legacy routing
   */
  instance: router,

  /**
   * Takes a URL string and does a pushState navigation, resulting in the
   * evaluation of the routing handlers
   * @param {string} url
   * @param {string?<push|replace|pop>} mode
   */
  go(url, mode = 'push') {
    if (__DEV__) {
      console.log(`Router go: ${url}`);
    }

    const vm = getMainRegionVM();
    const navController = flux.evaluate(
      RegionGetters.navigationController('main'),
    );

    const executeRoute = function() {
      if (navController && navController.beforeLeaveFns.length) {
        navController.beforeLeaveFns = [];
      }

      loadingStart('main');
      router.go(url, mode);

      if (mode === 'push') {
        HistoryModule.pushBrowserHistoryState(url);
      } else if (mode === 'replace') {
        HistoryModule.replaceBrowserHistoryState(url);
      }
    };

    if (vm && vm.$options && vm.$options.beforeLeave) {
      vm.$options.beforeLeave.call(vm, executeRoute);
    } else if (navController && navController.beforeLeaveFns.length) {
      const beforeLeavePromises = navController.beforeLeaveFns.map(fn => fn());
      Promise.all(beforeLeavePromises).then(executeRoute, message =>
        console.warn('Router navigation was aborted: ', message),
      );
    } else {
      executeRoute();
    }
  },

  getPathname() {
    return window.location.pathname;
  },

  /**
   * Gets the current window location + search
   * @return {string}
   */
  getLocation() {
    return this.getPathname() + this.windowLocationSearch();
  },

  /**
   * Navigate to the previous state
   */
  back() {
    historyUtil.back();
    HistoryModule.popPreviousBrowserHistoryState();
  },

  /**
   * Redirect to a URL
   *
   * Note: the redirect happens in a setTimeout
   * @param {string} url
   */
  redirect(url) {
    setTimeout(() => {
      router.replace(url);
      HistoryModule.replaceBrowserHistoryState(url);
    }, 0);
  },

  /**
   * Starts the router, loads routes and parses the current URI to evaluate
   * the route handlers
   * @param {Array.<Route>} routes
   */
  loadRoutes(routes) {
    if (!routes || !_.isArray(routes)) {
      throw new Error(
        'Must supply an Array of routes when calling router.start',
      );
    }
    router.registerRoutes(routes);
  },

  /**
   * Mounts a component on some dom node.  If the same component is already mounted
   * do nothing and return
   *
   * If another component is mounted, $destroy and mount the intended one
   *
   * Note: mountComponent doesnt call next because it is intended to be the last
   * step in the routing resolution
   * @param {HTMLElement|string} el to $appendTo
   * @param {string} componentId to mount
   * @return {function} a nuclear router function
   */
  mountComponent(el, componentId) {
    return function(ctx) {
      if (_.isString(el)) {
        el = $(el).get(0);
        if (!el) {
          throw new Error('Cannot match find element to mount component');
        }
      }
      const mountedComponent = $(el).data('mountedComponent');
      if (mountedComponent) {
        if (mountedComponent.id === componentId) {
          // the component is already mounted, no-op
          return;
        }
        $(el).removeData('mountedComponent');
        // we want to mount a different component
        mountedComponent.instance.$destroy();
      }

      const Component = getComponent(componentId);

      const instance = new Component({
        parent: getRootVM(),
      });
      instance.$appendTo(el);

      // save reference to the component at the dom element to be able to check if the component
      // is mounted already
      $(el).data('mountedComponent', {
        instance,
        id: componentId,
      });

      // call renderMainRegion here to simulate the same event hook as `renderMainRegion`
      $broadcast('renderMainRegion');
    };
  },

  reset() {
    router.reset();
  },

  /**
   * Opens the given url in a new tab
   * @param {string} url
   */
  windowOpenInNewTab(url) {
    $window.open(url, '_blank');
  },

  /**
   * @param {string} loc url or on domain relative path to redirect to.
   * @return {string} current url/location
   */
  windowNavigate(loc) {
    $window.location = loc;
    return $window.location;
  },

  /**
   * @return {string} query string for the browser
   */
  windowLocationSearch() {
    return $window.location.search;
  },

  windowLocationReload() {
    return $window.location.reload();
  },
};

export default exported;
export { router as instance };

export const {
  initialize,
  go,
  getPathname,
  getLocation,
  back,
  redirect,
  loadRoutes,
  mountComponent,
  reset,
  windowOpenInNewTab,
  windowNavigate,
  windowLocationSearch,
  windowLocationReload,
} = exported;

import { toImmutable } from 'optly/immutable';
import flux from 'core/flux';

import CatalogActions from 'optly/modules/entity/catalog/actions';
import ProjectActions from 'optly/modules/entity/project/actions';
import CurrentProjectGetters from 'optly/modules/current_project/getters';

import actionTypes from './action_types';
import getters from './getters';

export function setCatalog(catalog) {
  flux.dispatch(actionTypes.RECOMMENDATIONS_SET_CATALOG, {
    catalog,
  });
}

export function setCurrentlyEditingCatalog(catalog) {
  flux.dispatch(actionTypes.RECOMMENDATIONS_SET_CURRENTLY_EDITING_CATALOG, {
    catalog,
  });
}

export function initialize() {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  flux.dispatch(actionTypes.RECOMMENDATIONS_INIT, {
    projectId,
  });
}

export function initializeCurrentlyEditingCatalog() {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  flux.dispatch(actionTypes.RECOMMENDATIONS_INIT_CURRENTLY_EDITING_CATALOG, {
    projectId,
  });
}

export function saveSettings(associateWithProject = false) {
  const catalog = flux.evaluate(getters.catalogForSave);
  const def1 = CatalogActions.save(catalog);

  if (associateWithProject) {
    const currentProject = flux.evaluateToJS(CurrentProjectGetters.project);
    const catalogIDs = currentProject.recommender_service_ids || [];

    return def1.then(savedCatalog => {
      catalogIDs.push(savedCatalog.id);
      ProjectActions.save({
        id: currentProject.id,
        recommender_service_ids: catalogIDs,
      });
      return savedCatalog;
    });
  }

  return def1;
}

/**
 * Updates a property on the Catalog being edited
 * @param {String} property
 * @param {String} value
 */
export function updateProperty(property, value) {
  flux.dispatch(actionTypes.RECOMMENDATIONS_UPDATE_PROPERTY, {
    property,
    value,
  });
}

/**
 * @param {Immutable.Map} event
 */
export function setCurrentlyEditingEvent(event) {
  flux.dispatch(actionTypes.RECOMMENDATIONS_SET_EDITING_EVENT, {
    event,
  });
  const availableViews = flux.evaluate(getters.eventPages);
  selectEventPage(availableViews.first().get('id'));
}

/**
 * @param {Immutable.Map} recommender
 */
export function setCurrentlyEditingRecommender(recommender) {
  flux.dispatch(actionTypes.RECOMMENDATIONS_SET_EDITING_RECOMMENDER, {
    recommender,
  });
}

/**
 * @param {Immutable.Map} event
 */
export function removeEvent(event) {
  flux.dispatch(actionTypes.RECOMMENDATIONS_REMOVE_EVENT, {
    event,
  });
}

/**
 * @param {Immutable.Map} tag
 */
export function addTagToCurrentlyEditingEvent(tag) {
  flux.dispatch(
    actionTypes.RECOMMENDATIONS_ADD_TAG_TO_CURRENTLY_EDITING_EVENT,
    {
      tag,
    },
  );
}

/**
 * @param {Immutable.Map} tag
 */
export function removeTagToCurrentlyEditingEvent(tag) {
  flux.dispatch(
    actionTypes.RECOMMENDATIONS_REMOVE_TAG_FROM_CURRENTLY_EDITING_EVENT,
    {
      tag,
    },
  );
}

/**
 * Action of creating a new recommender and editing it
 */
export function updateForCatalog(value) {
  flux.dispatch(actionTypes.RECOMMENDATIONS_UPDATE_FOR_CATALOG, {
    value,
  });
}

/**
 * Selects a page to show available tags when attaching an event to the catalog
 * @param {Number} viewId
 */
export function selectEventPage(viewId) {
  flux.dispatch(actionTypes.RECOMMENDATIONS_SELECT_EVENT_PAGE, {
    viewId,
  });
}

/**
 * Updates an entry in the ProjectSpecifics.events
 * @param {Immutable.Map} event
 */
export function updateEvent(event) {
  flux.dispatch(actionTypes.RECOMMENDATIONS_UPDATE_EVENT, {
    event,
  });
}

/**
 * Updates a recommender top level property
 * @param {String} property
 * @param {String} value
 */
export function updateCurrentlyEditingRecommenderProperty(property, value) {
  flux.dispatch(
    actionTypes.RECOMMENDATIONS_UPDATE_EDITING_RECOMMENDER_PROPERTY,
    {
      property,
      value,
    },
  );
}

/**
 * Update the recommender specifics json blob add an event from a property
 * @param {String} property
 * @param {Immutable.Map} event
 */
export function updateCurrentlyEditingRecommenderSpecificsAddEvent(
  property,
  event,
) {
  const currentValue = flux.evaluate(
    getters.currentlyEditingRecommenderSpecificsField(property),
  );
  const newValue = currentValue.push(
    toImmutable({
      id: event.get('id'),
      kind: event.get('kind'),
    }),
  );

  flux.dispatch(
    actionTypes.RECOMMENDATIONS_UPDATE_EDITING_RECOMMENDER_SPECIFICS_PROPERTY,
    {
      property,
      value: newValue,
    },
  );
}

/**
 * Update the recommender specifics json blob remove an event from a property
 * @param {String} property
 * @param {Immutable.Map} event
 */
export function updateCurrentlyEditingRecommenderSpecificsRemoveEvent(
  property,
  event,
) {
  const currentValue = flux.evaluate(
    getters.currentlyEditingRecommenderSpecificsField(property),
  );
  const newValue = currentValue.filter(
    item =>
      item.get('id') !== event.get('id') ||
      item.get('kind') !== event.get('kind'),
  );

  flux.dispatch(
    actionTypes.RECOMMENDATIONS_UPDATE_EDITING_RECOMMENDER_SPECIFICS_PROPERTY,
    {
      property,
      value: newValue,
    },
  );
}

export default {
  addTagToCurrentlyEditingEvent,
  initialize,
  initializeCurrentlyEditingCatalog,
  removeEvent,
  removeTagToCurrentlyEditingEvent,
  saveSettings,
  selectEventPage,
  setCatalog,
  setCurrentlyEditingCatalog,
  setCurrentlyEditingEvent,
  setCurrentlyEditingRecommender,
  updateCurrentlyEditingRecommenderProperty,
  updateCurrentlyEditingRecommenderSpecificsAddEvent,
  updateCurrentlyEditingRecommenderSpecificsRemoveEvent,
  updateEvent,
  updateForCatalog,
  updateProperty,
};

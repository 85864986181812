/**
 * Mutual Exclusion Group Traffic Allocation component.
 *
 * @author Jessica Chong (jessica.chong@optimizely.com)
 */

import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import { Attention } from 'optimizely-oui';

import Immutable from 'optly/immutable';

import ExperimentationGroupConstants from 'optly/modules/entity/experimentation_group/constants';
import ExperimentationGroupFns from 'optly/modules/entity/experimentation_group/fns';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerExperimentFns from 'optly/modules/entity/layer_experiment/fns';

import { fieldPropType } from 'react_components/form';

import TrafficInput from './components/traffic_input';
import ExclusionGroupDropdown from './components/exclusion_group_dropdown';

class GroupTrafficAllocation extends React.Component {
  static displayName = 'GroupTrafficAllocation';

  static propTypes = {
    activeGroups: PropTypes.instanceOf(Immutable.List).isRequired,
    // 'entity' is a layer entity with additional properties for group_id and percentage_included
    entity: PropTypes.object.isRequired,
    hidePercentageIncludedForNoneGroup: PropTypes.bool,
    isExperimentRunning: PropTypes.bool,
    isFullstackExperiment: PropTypes.bool,
    isWebExperimentLive: PropTypes.bool,
    percentageIncludedField: fieldPropType.isRequired,
    selectedGroupField: fieldPropType.isRequired,
    showHeader: PropTypes.bool,
  };

  static defaultProps = {
    hidePercentageIncludedForNoneGroup: false,
    isWebExperimentLive: false,
    isFullstackExperiment: false,
    showHeader: true,
    isExperimentRunning: false,
  };

  constructor(props) {
    super(props);

    const { percentageIncludedField, selectedGroupField } = props;

    percentageIncludedField.validators(
      {
        isWithinGroupAllocationRange: value => {
          if (
            selectedGroupField.getValue() !==
            ExperimentationGroupConstants.NONE_GROUP_ID
          ) {
            return value > this.getTotalAvailableTraffic();
          }
          return false;
        },
        isWithinExperimentAllocationRange: value => {
          if (!value) {
            return false;
          }
          return (
            value < 0 ||
            value > ExperimentationGroupConstants.MAX_TRAFFIC_ALLOCATION
          );
        },
      },
      {
        dependsOnFields: ['selectedGroup'],
      },
    );
  }

  getTotalAvailableTraffic = () => {
    const { activeGroups, selectedGroupField, entity } = this.props;
    const selectedGroupId = selectedGroupField.getValue();
    const selectedGroup =
      activeGroups.find(group => group.get('id') === selectedGroupId) ||
      ExperimentationGroupConstants.NONE_GROUP;
    return ExperimentationGroupFns.calculateAvailableTrafficForGroup(
      selectedGroup,
      entity.get('id'),
    );
  };

  renderTrafficInput = () => {
    const {
      hidePercentageIncludedForNoneGroup,
      percentageIncludedField,
      selectedGroupField,
    } = this.props;

    const isGroupSelected =
      selectedGroupField.getValue() !==
      ExperimentationGroupConstants.NONE_GROUP_ID;
    const shouldRenderInput = !(
      hidePercentageIncludedForNoneGroup && !isGroupSelected
    );

    return (
      shouldRenderInput && (
        <TrafficInput
          entityName={this.getEntityName()}
          isSelectedGroupNone={!isGroupSelected}
          field={percentageIncludedField}
          value={percentageIncludedField.getValue()}
        />
      )
    );
  };

  renderTrafficRemaining = () => {
    const { percentageIncludedField, selectedGroupField } = this.props;

    const percentageIncluded = percentageIncludedField.getValue();
    const totalAvailableTraffic = this.getTotalAvailableTraffic();
    const adjustedRemainingTraffic =
      ExperimentationGroupFns.calculateRemainingTraffic(
        totalAvailableTraffic,
        percentageIncluded,
      ) / 100;
    const shouldRender =
      selectedGroupField.getValue() !==
      ExperimentationGroupConstants.NONE_GROUP_ID;

    return (
      shouldRender && (
        <div
          className="lego-form-note"
          data-test-section="available-traffic-remaining-note">
          Available traffic remaining:&nbsp;
          <span
            className={classNames({
              'color--bad-news': adjustedRemainingTraffic < 0,
            })}>
            {adjustedRemainingTraffic}%
          </span>
        </div>
      )
    );
  };

  renderTrafficChangeWarning = () => {
    const {
      entity,
      selectedGroupField,
      percentageIncludedField,
      isWebExperimentLive,
      isFullstackExperiment,
      isExperimentRunning,
    } = this.props;

    const isFullStackExperimentLive =
      isFullstackExperiment &&
      LayerExperimentFns.isRunningInAnyEnvironment(entity);
    const isExperimentLive = isFullStackExperimentLive || isWebExperimentLive;
    const isCreatingNewInstance = entity.get('id') === undefined;
    const isGroupSelected =
      selectedGroupField.getValue() !==
      ExperimentationGroupConstants.NONE_GROUP_ID;
    const isPercentageIncludedDirty =
      entity.get('percentage_included') !== percentageIncludedField.getValue();
    const isGroupSelectedDirty =
      entity.get('group_id') !== selectedGroupField.getValue();
    const isLiveExperimentGroupInfoChanged =
      !isCreatingNewInstance &&
      (isExperimentRunning || isFullStackExperimentLive) &&
      isExperimentLive &&
      (isGroupSelectedDirty || (isGroupSelected && isPercentageIncludedDirty));
    const isLiveExperimentTotalAllocationChanged =
      (isExperimentRunning || isFullStackExperimentLive) &&
      isExperimentLive &&
      isPercentageIncludedDirty;

    let warningMessage = '';

    if (isLiveExperimentGroupInfoChanged) {
      warningMessage = `Changing traffic allocation or group assignment for a running
      experiment will impact user bucketing, causing users to be bucketed
      in or unbucketed from this experiment.`;
    } else if (isLiveExperimentTotalAllocationChanged) {
      warningMessage = `Changing traffic allocation for a running experiment could impact user bucketing,
      causing users to be bucketed in or removed from this experiment.`;
    }

    return (
      warningMessage && (
        <div className="push--top">
          <Attention
            type="warning"
            testSection="traffic-or-group-change-warning">
            {warningMessage}
          </Attention>
        </div>
      )
    );
  };

  renderGroupAllocationError = () => {
    const { percentageIncludedField, selectedGroupField } = this.props;

    let groupAllocationError;
    let experimentAllocationError;
    if (percentageIncludedField.getErrors().hasError) {
      const fieldErrorDetails = percentageIncludedField.getErrors().details;
      groupAllocationError = fieldErrorDetails.isWithinGroupAllocationRange;
      experimentAllocationError =
        fieldErrorDetails.isWithinExperimentAllocationRange;
    }

    const isGroupSelected =
      selectedGroupField.getValue() !==
      ExperimentationGroupConstants.NONE_GROUP_ID;
    const shouldRenderError =
      isGroupSelected && groupAllocationError && !experimentAllocationError;
    const totalAvailableTraffic = this.getTotalAvailableTraffic();

    return (
      shouldRenderError && (
        <li
          className="lego-attention lego-attention--bad-news text--center"
          data-test-section="group-allocation-error">
          <p>
            The <strong>experiment traffic allocation</strong> must fit within
            the available traffic of the exclusion group. The selected exclusion
            group has {totalAvailableTraffic / 100}% available.
          </p>
        </li>
      )
    );
  };

  renderExperimentAllocationError = () => {
    const { percentageIncludedField } = this.props;

    let experimentAllocationError;
    if (percentageIncludedField.getErrors().hasError) {
      const fieldErrorDetails = percentageIncludedField.getErrors().details;
      experimentAllocationError =
        fieldErrorDetails.isWithinExperimentAllocationRange;
    }

    return (
      experimentAllocationError && (
        <li
          className="lego-attention lego-attention--bad-news text--center push--bottom"
          data-test-section="custom-experiment-allocation-error">
          <p>
            The <strong>experiment traffic allocation</strong> must be between
            0% and {this.getTotalAvailableTraffic() / 100}%.
          </p>
        </li>
      )
    );
  };

  getEntityName = () => {
    const { entity } = this.props;

    return LayerEnums.typeToEntityName[entity.get('type')] || 'experiment';
  };

  render() {
    const {
      selectedGroupField,
      showHeader,
      percentageIncludedField,
      activeGroups,
    } = this.props;
    const doActiveGroupsExist = activeGroups.count() > 0;

    return (
      <div data-test-section="group-traffic-allocation-component">
        {showHeader && <h1>Traffic Allocation</h1>}
        <ol className="lego-form-fields">
          {doActiveGroupsExist && (
            <li className="lego-form-field__item soft--top">
              <p>
                {`Add this ${this.getEntityName()} to the following exclusion group:`}
              </p>
              <div className="display--inline-block">
                <ExclusionGroupDropdown
                  activeGroups={activeGroups}
                  field={selectedGroupField}
                  selectedGroupField={selectedGroupField}
                  percentageIncludedField={percentageIncludedField}
                />
              </div>
              {this.renderTrafficRemaining()}
            </li>
          )}
          {this.renderGroupAllocationError()}
          {this.renderTrafficInput()}
          {this.renderExperimentAllocationError()}
        </ol>
        {this.renderTrafficChangeWarning()}
      </div>
    );
  }
}

export default GroupTrafficAllocation;

/**
 * Getters for audienceBuilder
 */
import { toImmutable } from 'optly/immutable';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import DimensionEnums from 'optly/modules/entity/dimension/enums';
import DimensionGetters from 'optly/modules/entity/dimension/getters';
import ProjectFns from 'optly/modules/entity/project/fns';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import fns from './fns';

export const oneClickIntegrationConditionConfigs = [
  CurrentProjectGetters.enabledAudienceIntegrations,
  CurrentProjectGetters.dynamicConditions,
  fns.buildOneClickIntegrationConditionsConfigObject,
];

export const customAudienceIntegrationConditionConfigs = [
  CurrentProjectGetters.enabledCustomAudienceIntegrations, // Custom Audience integrations
  CurrentProjectGetters.customAudienceAttributeDefinitions, // Attribute definitions evaluated from endpoint
  fns.buildCustomAudienceIntegrationConditionsConfigObject,
];

/**
 * Groups of provider => conditionConfigs
 * @param oneClickIntegrationConditionConfigs {Immutable.List}
 * @param customAudienceIntegrationConditionConfigs {Immutable.List}
 * @return {ImmutableList}
 * These also include attribute definitions fetched from the custom_integrations endpoint
 */
export const thirdPartyConditionConfigs = [
  oneClickIntegrationConditionConfigs,
  customAudienceIntegrationConditionConfigs,
  (
    oneClickIntegrationConditionConfigs,
    customAudienceIntegrationConditionConfigs,
  ) =>
    oneClickIntegrationConditionConfigs.concat(
      customAudienceIntegrationConditionConfigs,
    ),
];

/**
 * Mapping of condition.name => condition
 */
const thirdPartyConditionsNameMap = [
  thirdPartyConditionConfigs,
  conditionConfigs =>
    toImmutable({}).withMutations(conditionsMap => {
      // iterate through all conditions and add to flat conditionsMap
      conditionConfigs.forEach(config => {
        config.get('conditions').forEach(condition => {
          conditionsMap.set(condition.get('name'), condition);
        });
      });
    }),
];

/**
 * Expose a function to lookup in mapping of <condition.name> => condition
 */
export function thirdPartyConditionsByName(name) {
  return [
    thirdPartyConditionsNameMap,
    function(conditionsNameMap) {
      const conditionName = fns.getThirdPartyIntegrationName(name);
      // Vue consumers will need to reliably have an object as to not error when looking at properties
      return conditionsNameMap.get(conditionName, toImmutable({}));
    },
  ];
}

const availableDimensions = [
  CurrentProjectGetters.project,
  DimensionGetters.defaultDimensions,
  (currentProject, defaultDimensions) =>
    defaultDimensions.filter(dimension => {
      if (dimension.get('platform_configuration')) {
        const projectTypeOrDeliveryMode = ProjectFns.getDeliveryModeOrProjectType(
          currentProject,
        );
        return (
          dimension
            .get('platform_configuration')
            .get(projectTypeOrDeliveryMode) === true &&
          dimension.get('used_by').indexOf('audiences') !== -1 &&
          dimension.get('hidden') !== true &&
          (dimension.get('has_permission') === true ||
            dimension.get('upsell') === true) &&
          DimensionEnums.NON_STANDARD_DIMENSIONS.indexOf(
            dimension.get('id'),
          ) === -1
        );
      }
      return false;
    }),
];

export default {
  oneClickIntegrationConditionConfigs,
  customAudienceIntegrationConditionConfigs,
  thirdPartyConditionConfigs,
  thirdPartyConditionsByName,
  thirdPartyConditionsNameMap,
  availableDimensions,
};

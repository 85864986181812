const LayerExperimentEnums = require('optly/modules/entity/layer_experiment/enums')
  .default;

export const experimentFilterStatuses = {
  ALL: 'all',
  ACTIVE: LayerExperimentEnums.status.ACTIVE,
  ARCHIVED: LayerExperimentEnums.status.ARCHIVED,
  PAUSED: LayerExperimentEnums.status.PAUSED,
};

export const BucketingOptions = {
  LIFETIME: 'lifetime',
  IMPRESSION: 'impression',
};

export default {
  experimentFilterStatuses,
  BucketingOptions,
};

import _ from 'lodash';

import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

// alternate entity definition for account level events
const accountLevelActions = RestApi.createEntityActions({
  entity: definition.entity,
});

export const {
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

/**
 * Alternate fetch all that works at the account level for shared projects
 * @param {Object?} filters
 * @param {Boolean?} force
 * @return {Deferred}
 */
export function fetchAllForAccount(filters, force) {
  return accountLevelActions.fetchAll(filters, force);
}

/**
 * Overwrite the default delete and do a soft delete instead
 * @param {View} view
 * @return {Deferred}
 */
function deleteView(view) {
  return this.archive(view);
}

/**
 * Archives a view
 * @param {View} view
 * @return {Deferred}
 */
export function archive(view) {
  return this.save({
    id: view.id,
    archived: true,
  });
}

/**
 * Unarchives a view
 * @param {View} view
 * @return {Deferred}
 */
export function unarchive(view) {
  return this.save({
    id: view.id,
    archived: false,
  });
}

/**
 * Handles saving a view
 *
 * @param {View} view
 */
export function save(view) {
  if (!view.api_name) {
    // if no api name, we shouldn't send it at all, otherwise the server will complain about it
    delete view.api_name;
  }

  // call "parent" save
  return baseEntityActions.save.call(this, view).then(response => {
    /**
     * Modifying and saving pages will potentially update page related events, so we need to invalidate cache.
     */
    RestApi.actions.inValidateCacheDataByEntity({
      entity: 'events',
    });
    return response;
  });
}

/**
 *  Fetch all views in a list
 */
export function fetchViewsById(viewIds) {
  const deferred = $.Deferred();

  const experimentViewDeferreds = _.map(viewIds, viewId =>
    baseEntityActions.fetch(viewId),
  );

  $.when(...experimentViewDeferreds).then(function() {
    const views = [].slice.call(arguments); // eslint-disable-line prefer-rest-params
    deferred.resolve(views);
  }, deferred.reject);

  return deferred;
}

export { deleteView as delete };

export default {
  ...baseEntityActions,
  fetchViewsById,
  fetchAllForAccount,
  delete: deleteView,
  archive,
  unarchive,
  save,
};

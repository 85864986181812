/**
 * Experiment Section Entity Module
 */
import actions from './actions';
import constants from './constants';
import getters from './getters';
import entityDef from './entity_definition';
import fns from './fns';

// named exports
export { actions, constants, getters, entityDef, fns };

// default export
export default {
  actions,
  constants,
  getters,
  entityDef,
  fns,
};

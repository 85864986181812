const React = require('react');

const HelpPopover = require('optimizely-oui').HelpPopover;

const PagesPopover = () => (
  <HelpPopover
    horizontalAttachment="left"
    popoverTitle="Pages"
    verticalAttachment="top">
    <p>
      Where will you run this experiment and track visitor actions? The
      homepage? Product pages?
    </p>
    <p>
      Pages are similar to URL Targeting in Optimizely Classic, but now they’re
      re-usable across experiments.
    </p>
    <p>
      Add an existing Page to your experiment, or create a new page by adding a
      URL (or pattern of URLs).
    </p>
  </HelpPopover>
);

module.exports = PagesPopover;

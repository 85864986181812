import _ from 'lodash';

const CATEGORY_OTHER = 'other';
/**
 * Services layer pure functions for the tags
 */

/**
 * Creates an empty tag object with the supplied data
 * @param {object} data Overridden properties
 * @returns {object}
 */
export function createTagEntity(data) {
  const DEFAULTS = {
    id: null,
    // api_name: null, discard api_name because null value will throw an error in the api
    category: CATEGORY_OTHER,
    description: null,
    platform: null,
    locator: null,
    locator_type: null,
    value_type: null,
    format_string: null,
    view_id: null,
  };
  return {
    ...DEFAULTS,
    ...data,
  };
}

/**
 * @param {String} categoryName
 * @param {View} view
 */
export function generateName(categoryName, view) {
  return `${categoryName} (${view.name})`;
}

/**
 * Validate the tag has the expected fields. Build this out when more tag types/functionality is supported
 * @param {object} tag
 * @param {string} tagValue
 * @param {Immutable.Map} otherViewTags the other view tags to validate the category against
 * @returns {object}
 */
export function validateTag(tag, tagValue, otherViewTags) {
  const errorObject = {};
  const fieldRequiredErrorMessage = tr('This field is required');

  if (!tag.locator) {
    errorObject.locator = fieldRequiredErrorMessage;
  }
  if (!tag.name || (_.isString(tag.name) && tag.name.trim() === '')) {
    errorObject.name = fieldRequiredErrorMessage;
  }
  if (tagValue.length > 500) {
    errorObject.tagValue = tr(
      'This tag value is longer than the allowed limit of 500 characters',
    );
  }
  return errorObject;
}

export default {
  createTagEntity,
  generateName,
  validateTag,
};

import { Store } from 'nuclear-js';

import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';
import { States } from '../enums';

export default Store({
  getInitialState() {
    return toImmutable({
      totpCode: null,
      password: null,
      email: null,
      lastSuccessfulAuth: null,
      nextState: States.EMAIL_PASSWORD_VERIFIED,
    });
  },

  initialize() {
    this.on(actionTypes.SET_TOTP_CODE, setTOTPCode);
    this.on(actionTypes.SET_PASSWORD, setPassword);
    this.on(actionTypes.SET_EMAIL, setEmail);
    this.on(actionTypes.SET_CSRF_TOKEN, setCSRFToken);
    this.on(actionTypes.SET_LAST_SUCCESSFUL_AUTH, setLastSuccessfulAuth);
    this.on(actionTypes.SET_TEMP_2FA_SECRET, setTemp2faSecret);
    this.on(actionTypes.SET_NEXT_STATE, setNextState);
  },
});

/**
 * Handler for actionTypes.SET_TOTP_CODE
 *
 * @param {Object} state
 * @param {Object} payload
 */
function setTOTPCode(state, payload) {
  return state.set('totpCode', payload.totpCode);
}

/**
 * Handler for actionTypes.SET_PASSWORD
 *
 * @param {Object} state
 * @param {Object} payload
 */
function setPassword(state, payload) {
  return state.set('password', payload.password);
}

/**
 * Handler for actionTypes.SET_EMAIL
 *
 * @param {Object} state
 * @param {Object} payload
 */
function setEmail(state, payload) {
  return state.set('email', payload.email);
}

/**
 * Handler for actionTypes.SET_CSRF_TOKEN
 *
 * @param {Object} state
 * @param {Object} payload
 */
function setCSRFToken(state, payload) {
  return state.get('lastSuccessfulAuth')
    ? state.setIn(['lastSuccessfulAuth', 'csrf_token'], payload.csrfToken)
    : // in the unlikely event that lastSuccessfulAuth is empty, we'll create a new object
      // with just the csrf_token key.
      toImmutable({
        lastSuccessfulAuth: {
          csrf_token: payload.csrfToken,
        },
      });
}

/**
 * Handler for actionTypes.LAST_SUCCESSFUL_AUTH
 *
 * @param {Object} state
 * @param {Object} payload
 */
function setLastSuccessfulAuth(state, payload) {
  return state.set('lastSuccessfulAuth', payload.data);
}

/**
 * Handler for actionTypes.SET_TEMP_2FA_SECRET
 *
 * @param {Object} state
 * @param {Object} payload
 */
function setTemp2faSecret(state, payload) {
  return state.setIn(['lastSuccessfulAuth', 'two_factor_token'], payload.data);
}

/**
 * Handler for actionTypes.SET_NEXT_STATE
 *
 * @param {Object} state
 * @param {Object} payload
 */
function setNextState(state, payload) {
  return state.set('nextState', payload.nextState);
}

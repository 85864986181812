import React from 'react';
import PropTypes from 'prop-types';

import some from 'lodash/some';
import { Col, Container, Row } from 'optimizely-oui';

import { OptimizelyFeature } from '@optimizely/react-sdk';

import Router from 'core/router';
import { connect } from 'core/ui/decorators';
import Immutable from 'optly/immutable';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import EnvironmentGetters from 'optly/modules/entity/environment/getters';
import FeatureGetters from 'optly/modules/entity/feature_flag/getters';
import FeatureFlagFns from 'optly/modules/entity/feature_flag/fns';
import PermissionsGetters from 'optly/modules/permissions/getters';
import shortdate from 'optly/filters/shortdate';
import UrlHelper from 'optly/services/url_helper';
import {
  Sidebar,
  SidebarDetailsList,
  SidebarHeader,
  SidebarNavListBody,
  SidebarNavList,
  SidebarNavListItem,
} from 'react_components/sidebar';

import * as SectionModuleConstants from '../../section_module/constants';

@connect(({ featureId }) => ({
  canUseFeatureVariables: PermissionsGetters.canAccountUseFeatureVariables,
  currentProject: CurrentProjectGetters.project,
  feature: FeatureGetters.byId(featureId),
  prioritizedEnvironments:
    EnvironmentGetters.unarchivedEnvironmentsSortedByPriority,
}))
class FeatureSidebar extends React.Component {
  static componentId = 'manager-feature-sidebar';

  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    canUseFeatureVariables: PropTypes.bool.isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    feature: PropTypes.instanceOf(Immutable.Map).isRequired,
    featureId: PropTypes.number.isRequired,
    prioritizedEnvironments: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  navigateBack = () => {
    const { currentProject } = this.props;

    Router.go(UrlHelper.featuresHome(currentProject.get('id')));
  };

  getSidebarItems() {
    const { canUseFeatureVariables, currentProject, featureId } = this.props;

    const projectId = currentProject.get('id');

    return [
      {
        name: SectionModuleConstants.tabs.RULES,
        displayName: 'Rules',
        testSection: 'feature-sidebar-rules-tab',
        url: UrlHelper.featureManagerRules(projectId, featureId),
      },
      ...(canUseFeatureVariables
        ? [
            {
              name: SectionModuleConstants.tabs.VARIABLES,
              displayName: 'Variable Keys & Types',
              testSection: 'feature-sidebar-variables-tab',
              url: UrlHelper.featureManagerVariables(projectId, featureId),
            },
          ]
        : []),
      {
        name: SectionModuleConstants.tabs.EXPERIMENT_USAGE,
        displayName: 'Experiment Usage',
        testSection: 'feature-sidebar-experiment-usage-tab',
        url: UrlHelper.featureManagerExperimentUsage(projectId, featureId),
      },
      {
        name: SectionModuleConstants.tabs.HISTORY,
        displayName: 'History',
        testSection: 'feature-sidebar-history-tab',
        url: UrlHelper.featureManagerHistory(projectId, featureId),
      },
      {
        name: SectionModuleConstants.tabs.SETTINGS,
        displayName: 'Settings',
        testSection: 'feature-sidebar-settings-tab',
        url: UrlHelper.featureManagerSettings(projectId, featureId),
      },
      {
        name: SectionModuleConstants.tabs.SDK_SETUP,
        displayName: 'SDK Setup',
        testSection: 'feature-sidebar-sdk-setup-tab',
        url: UrlHelper.featureManagerSDKSetup(projectId, featureId),
      },
    ];
  }

  render() {
    const {
      activeTab,
      currentProject,
      feature,
      prioritizedEnvironments,
    } = this.props;

    const primaryEnvironment = prioritizedEnvironments.find(
      env => env.get('is_primary'),
      null,
      prioritizedEnvironments.first(), // if there's somehow no primary, use highest priority
    );

    const primaryEnvironmentRolloutRules = FeatureFlagFns.getPrimaryEnvironmentEntry(
      feature,
    ).environmentInfo.get('rollout_rules');
    const isAnyEnvironmentRuleEnabled = some(
      primaryEnvironmentRolloutRules.toJS(),
      rule =>
        rule.percentage_included && parseFloat(rule.percentage_included) > 0,
    );
    const primaryEnvironmentStatus = isAnyEnvironmentRuleEnabled
      ? 'Enabled'
      : 'Disabled';

    return (
      <Sidebar testSection="manager-feature-sidebar">
        <OptimizelyFeature feature="user_friendly_names">
          {isEnabled =>
            isEnabled && feature.get('name') ? (
              <SidebarHeader
                backLinkText="Features"
                backLinkOnClick={this.navigateBack}
                isFullHeight={false}
                projectName={currentProject.get('project_name')}
                isFullStackProject={false}
                scrollHeader={false}
                showBackLink={true}
                spaceBelow={true}
                title={feature.get('name')}>
                <div
                  className="zeta monospace micro muted force-break"
                  data-test-section="header-subtitle"
                  style={{ marginTop: '-1px' }}>
                  {feature.get('api_name')}
                </div>
              </SidebarHeader>
            ) : (
              <SidebarHeader
                backLinkText="Features"
                backLinkOnClick={this.navigateBack}
                isFullHeight={false}
                projectName={currentProject.get('project_name')}
                isFullStackProject={true}
                scrollHeader={false}
                showBackLink={true}
                spaceBelow={true}
                title={feature.get('api_name')}
              />
            )
          }
        </OptimizelyFeature>
        <SidebarNavListBody>
          <SidebarDetailsList
            items={[
              {
                label: 'Created',
                value: shortdate(feature.get('created')),
              },
              {
                label: 'Primary Environment',
                value:
                  primaryEnvironment.get('name') ||
                  primaryEnvironment.get('key'),
              },
              {
                label: 'Primary Environment Status',
                value: primaryEnvironmentStatus,
              },
            ]}
            spaceBelow={true}
          />
          <SidebarNavList>
            {this.getSidebarItems()
              .filter(({ isHidden }) => !isHidden)
              .map(item => (
                <SidebarNavListItem
                  isActive={activeTab === item.name}
                  href={item.url}
                  key={item.name}
                  testSection={item.testSection}>
                  <Container fluid={true} pullRowPadding={true}>
                    <Row verticalAlignment="center">
                      <Col small="fillSpace">{item.displayName}</Col>
                      {item.badge && (
                        <Col
                          small="fitContent"
                          data-test-section="feature-manager-sidebar-badge">
                          {item.badge}
                        </Col>
                      )}
                    </Row>
                  </Container>
                </SidebarNavListItem>
              ))}
          </SidebarNavList>
        </SidebarNavListBody>
      </Sidebar>
    );
  }
}

export default FeatureSidebar;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'optimizely-oui';

// eslint-disable-next-line import/no-cycle
import {
  enums as SupportEnums,
  fns as SupportFns,
} from 'optly/modules/support';

const renderPaddedLink = (href, text) => (
  <Link newWindow={true} href={href}>
    <span className="soft-half--sides">{text}</span>
  </Link>
);

export default class TicketStatus extends React.Component {
  static propTypes = {
    requestStatus: PropTypes.string.isRequired,
    zendeskTicketId: PropTypes.number.isRequired,
  };

  render() {
    const { onClose, requestStatus, zendeskTicketId } = this.props;
    return (
      <React.Fragment>
        <div data-test-section="ticket-status" className="soft-double--bottom">
          {requestStatus === SupportEnums.requestStatus.SUCCESS && (
            <section data-test-section="ticket-success">
              <div className="weight--bold">We've received your request!</div>
              <p className="push--top">
                Request
                {renderPaddedLink(
                  SupportFns.newlyCreatedRequestLink(zendeskTicketId),
                  `#${zendeskTicketId}`,
                )}
                has been submitted and you'll hear from us soon. You can
                {renderPaddedLink(
                  SupportFns.newlyCreatedRequestLink(zendeskTicketId),
                  'click here',
                )}
                to view this request. See all of your Optimizely Support
                requests
                {renderPaddedLink(SupportFns.myRequestsLink(), 'here.')}
              </p>
            </section>
          )}
          {requestStatus === SupportEnums.requestStatus.DEFERRED && (
            <section data-test-section="ticket-deferred">
              <div className="weight--bold">We've received your request!</div>
              <p className="push--top">
                Your request is being processed and you'll hear from us soon.
                You can view all of your Optimizely Support requests
                {renderPaddedLink(SupportFns.myRequestsLink(), 'here.')}
              </p>
            </section>
          )}
        </div>
      </React.Fragment>
    );
  }
}

// IMPORTANT: Keep these enums in sync with client-js/src/core/tags/enums
export const valueType = {
  STRING: 'string',
  NUMBER: 'number',
  CURRENCY: 'currency',
};
export const value_type = valueType;

export const locatorType = {
  CSS_SELECTOR: 'css_selector',
  JAVASCRIPT: 'javascript',
  URL_REGEX: 'url_regex',
};
export const locator_type = locatorType;

// this list comes from the values in the yaml file pulled in by this module --> optly/modules/p13n/predefined_categories
export const categories = {
  CATEGORY: 'category',
  SUBCATEGORY: 'subcategory',
  TITLE: 'title',
  PRICE: 'price',
  OTHER: 'other',
};

export default {
  categories,
  locatorType,
  locator_type,
  valueType,
  value_type,
};

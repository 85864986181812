import PermissionFns from 'optly/modules/permissions/fns';
import tr from 'optly/translate';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import Immutable from 'optly/immutable';

const EXPERIMENT_ENVIRONMENT_STATUS = LayerExperimentEnums.EnvironmentStatus;
let fns;

/**
 * Create array at errors[property] if it doesn't exist, and then add
 * errorMessage to errors[property]
 *
 * This mutates errors
 *
 * @param {Object} errors
 * @param {String} property
 * @param {String} errorMessage
 */
function addErrorForProperty(errors, property, errorMessage) {
  if (!errors[property]) {
    errors[property] = [];
  }
  errors[property].push(errorMessage);
}

/**
 * Validate environment and return an error messages object
 *
 * Property names in the object are are 'name' and/or 'key'
 *
 * Property values are arrays of strings (error messages)
 *
 * When the returned object is empty, the environment is valid
 *
 * @param {Object} environment
 * @param {Immutable.Map} projectEnvironments
 * @return {Object}
 */
export function validateEnvironment(environment, projectEnvironments) {
  const errors = {};

  const { key, name, id } = environment;

  if (!key) {
    addErrorForProperty(errors, 'key', tr('Key is required.'));
  }

  if (!name) {
    addErrorForProperty(errors, 'name', tr('Name is required.'));
  }

  if (!/^[a-zA-Z0-9_]+$/.test(key)) {
    addErrorForProperty(
      errors,
      'key',
      tr('Key must contain only numbers, letters, and underscores.'),
    );
  }

  const isDuplicateName = projectEnvironments.some(
    projectEnv =>
      projectEnv.get('name') === name && projectEnv.get('id') !== id,
  );
  if (isDuplicateName) {
    addErrorForProperty(
      errors,
      'name',
      tr(
        'That name is already used by another environment in this project. Please choose another.',
      ),
    );
  }

  const isDuplicateKey = projectEnvironments.some(
    projectEnv => projectEnv.get('key') === key && projectEnv.get('id') !== id,
  );
  if (isDuplicateKey) {
    addErrorForProperty(
      errors,
      'key',
      tr(
        'That key is already used by another environment in this project. Please choose another.',
      ),
    );
  }

  return errors;
}

/**
 * Returns true if the permissions of the argument project allow starting and
 * pausing experiments in the argument environment
 * @param {Immutable.Map} project
 * @param {Immutable.Map} environment
 * @return {Boolean}
 */
export function canStartAndPause(project, environment) {
  if (!project) {
    return false;
  }

  if (environment.get('has_restricted_permissions')) {
    // Ensures "Restricted Publisher" role or higher can start/pause in a restricted Environment
    return PermissionFns.canModifyRunningExperiment(project);
  }
  // "Restricted Editor" roles and above can start/pause an unrestricted Environment
  return PermissionFns.canUpdateLayerExperimentWithExtension(project);
}

function sortEnvironments(environments, currentProjectId) {
  return environments
    .filter(
      environment =>
        !environment.get('archived') &&
        environment.get('project_id') === currentProjectId,
    )
    .toList()
    .sortBy(environment => environment.get('priority'));
}

/**
 * Returns a Map containing some environment information, sorted by environment
 * priority, for each layer experiment in the current project, and each
 * environment in the current project. The Map keys are layer experiment ids.
 * The Map values are Lists of Maps, which have these properties:
 * - id: environment id
 * - status: Status of the experiment in this environment
 * - name: Name of this environment
 * - canStartAndPause: True if the current project permissions allow starting
 *   and pausing this experiment in this environment
 *
 * If environmentsAuthorizedAndSupported is false, returns an empty map.
 *
 * If a layer experiment lacks an 'environments' property, this map will contain
 * an empty list entry for its ID.
 *
 * @param {Boolean} environmentsAuthorizedAndSupported
 * @param {Immutable.Map} layerExperiments
 * @param {Immutable.Map} environments
 * @param {Immutable.Map} currentProject
 * @return {Immutable.Map} Environment info for each experiment as described
 * above
 */
export function getEnvironmentStatusesByExperimentId(
  environmentsAuthorizedAndSupported,
  layerExperiments,
  environments,
  currentProject,
) {
  if (!environmentsAuthorizedAndSupported) {
    return Immutable.Map();
  }

  const currentProjectId = currentProject ? currentProject.get('id') : null;

  const sortedEnvironments = sortEnvironments(
    environments,
    currentProject.get('id'),
  );

  const currentProjectExperiments = layerExperiments.filter(exp => {
    const layerPolicy = exp.get('layer_policy');
    return (
      exp.get('project_id') === currentProjectId &&
      // Only want single experiment and multivariate layer experiments, not layer experiments that
      // are targeting rules inside a rollout
      (layerPolicy === LayerEnums.policy.SINGLE_EXPERIMENT ||
        layerPolicy === LayerEnums.policy.MULTIVARIATE)
    );
  });

  return currentProjectExperiments.map(layerExperiment => {
    const experimentEnvironmentsInfo = layerExperiment.get('environments');
    if (!experimentEnvironmentsInfo) {
      return Immutable.List();
    }
    return sortedEnvironments.map(environment => {
      const experimentEnvironmentInfo = experimentEnvironmentsInfo.get(
        environment.get('key'),
      );

      let status;
      if (experimentEnvironmentInfo) {
        status = experimentEnvironmentInfo.get('status');
      } else {
        status = EXPERIMENT_ENVIRONMENT_STATUS.NOT_STARTED;
      }
      return Immutable.Map({
        status,
        name: environment.get('name'),
        id: environment.get('id'),
        canStartAndPause: fns.canStartAndPause(currentProject, environment),
        key: environment.get('key'),
      });
    });
  });
}

/**
 * Returns a Map containing some environment information, sorted by environment
 * priority, for each feature in the current project, and each
 * environment in the current project. The Map keys are feature ids.
 * The Map values are Lists of Maps, which have these properties:
 * - id: environment id
 * - status: Status of the experiment in this environment
 * - name: Name of this environment
 * - canStartAndPause: True if the current project permissions allow starting
 *   and pausing this experiment in this environment
 *
 * If environmentsAuthorizedAndSupported is false, returns an empty map.
 *
 * If a feature lacks an 'environments' property, this map will contain
 * an empty list entry for its ID.
 *
 * @param {Boolean} environmentsAuthorizedAndSupported
 * @param {Immutable.Map} features
 * @param {Immutable.Map} environments
 * @param {Immutable.Map} currentProject
 * @param {Boolean} canUseTargetedRollouts
 * @return {Immutable.Map} Environment info for each experiment as described
 * above
 */
export function getEnvironmentStatusesByFeatureId(
  environmentsAuthorizedAndSupported,
  features,
  environments,
  currentProject,
  canUseTargetedRollouts,
) {
  if (!environmentsAuthorizedAndSupported) {
    return Immutable.Map();
  }

  const currentProjectId = currentProject ? currentProject.get('id') : null;

  const sortedEnvironments = sortEnvironments(
    environments,
    currentProject.get('id'),
  );

  const currentProjectFeatures = features.filter(
    feat => feat.get('project_id') === currentProjectId,
  );

  return currentProjectFeatures.map(feature => {
    const featureEnvironmentsInfo = feature.get('environments');
    if (!featureEnvironmentsInfo) {
      return Immutable.List();
    }
    return sortedEnvironments.map(environment => {
      let status;
      if (!canUseTargetedRollouts) {
        status =
          featureEnvironmentsInfo.getIn([
            environment.get('key'),
            'rollout_rules',
            0,
            'status',
          ]) || EXPERIMENT_ENVIRONMENT_STATUS.NOT_STARTED;
      } else {
        const rolloutRules = featureEnvironmentsInfo.getIn(
          [environment.get('key'), 'rollout_rules'],
          Immutable.List(),
        );
        status = EXPERIMENT_ENVIRONMENT_STATUS.NOT_STARTED;
        if (rolloutRules.size > 0) {
          const rolloutRulePercentages = rolloutRules.map(rule =>
            rule.get('percentage_included'),
          );
          status = rolloutRulePercentages.every(percentage => percentage === 0)
            ? EXPERIMENT_ENVIRONMENT_STATUS.PAUSED
            : EXPERIMENT_ENVIRONMENT_STATUS.RUNNING;
        }
      }

      const percentIncluded =
        featureEnvironmentsInfo.getIn([
          environment.get('key'),
          'rollout_rules',
          0,
          'percentage_included',
        ]) || 0;

      return Immutable.Map({
        status,
        name: environment.get('name'),
        id: environment.get('id'),
        canStartAndPause: fns.canStartAndPause(currentProject, environment),
        key: environment.get('key'),
        percentage_included: percentIncluded,
      });
    });
  });
}

export default fns = {
  canStartAndPause,
  getEnvironmentStatusesByExperimentId,
  getEnvironmentStatusesByFeatureId,
  validateEnvironment,
};

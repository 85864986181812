import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';

import { HelpPopover } from 'optimizely-oui';

import interpolate from 'optly/utils/interpolate';

const formatBasedOnDate = d => moment(d).format('MMM Do');

const ReachEstimate = props => {
  const { similarityScore, scoreDistribution } = props;
  const buckets = scoreDistribution.get('buckets');
  const computedBucket = interpolate(
    'similarity_score',
    similarityScore,
    buckets,
  );
  const firstDate = new Date(scoreDistribution.get('first_date'));
  const lastDate = new Date(scoreDistribution.get('last_date'));
  const duration = moment.duration(+lastDate - +firstDate);
  const reachVisitorsPerDay = numeral(
    computedBucket.get('reach_visitor_count') / duration.asDays(),
  ).format('0.0a');
  const reachVisitorsPerWeek = numeral(
    (computedBucket.get('reach_visitor_count') / duration.asDays()) * 7,
  ).format('0.0a');

  return (
    <div className="push--sides audience-stats text--left">
      <div className="flex push-half--bottom shadow-box__title">
        <HelpPopover
          behavior="hover"
          popoverTitle="Eligible Visitors"
          testSection="eligible-visitors-poptip"
          verticalAttachment="bottom">
          The maximum number of people eligible for this audience condition. The
          actual number of visitors that match this condition may be less than
          what is displayed depending on other audience conditions and page
          targeting.
        </HelpPopover>
        <span
          className="push-half--left"
          data-test-section="eligible-visitors-text">
          Eligible Visitors
        </span>
      </div>
      <p>
        <strong data-test-section="visitors-per-week">
          {reachVisitorsPerWeek}
        </strong>{' '}
        visitors per week
        <br />
        <strong data-test-section="visitors-per-day">
          {reachVisitorsPerDay}
        </strong>{' '}
        visitors per day
      </p>
      <div
        className="sidenav__section__title flush--bottom"
        data-test-section="total-eligible-visitors-text">
        <h6>Total Eligible Visitors</h6>
      </div>
      <p>
        <strong data-test-section="reach-display-percentage">
          {(computedBucket.get('reach_percentage') * 100).toFixed(2)}%
        </strong>
        &nbsp; of traffic
      </p>
      <div className="sidenav__section__title flush--bottom">
        <h6>Based on</h6>
      </div>
      <p data-test-section="reach-display-duration">
        Visitors seen in the last {duration.humanize()} (
        {formatBasedOnDate(firstDate)} - {formatBasedOnDate(lastDate)})
      </p>
    </div>
  );
};

ReachEstimate.propTypes = {
  scoreDistribution: PropTypes.object.isRequired,
  similarityScore: PropTypes.number.isRequired,
};

export default ReachEstimate;

import actions from './actions';
import constants from './constants';
import fns from './fns';
import getters from './getters';

export { actions, constants, fns, getters };

export default {
  actions,
  constants,
  fns,
  getters,
};

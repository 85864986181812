import fieldTypes from 'optly/modules/rest_api/field_types';
import _ from 'lodash';

/**
 * Entity Definition for audience
 */
export default {
  entity: 'audiences',

  parent: {
    entity: 'projects',
    key: 'project_id',
  },

  fieldTypes: {
    id: fieldTypes.NUMBER,
    project_id: fieldTypes.NUMBER,
    name: fieldTypes.STRING,
    description: fieldTypes.STRING,
    last_modified: fieldTypes.DATE,
    conditions: fieldTypes.ARRAY,
    segmentation: fieldTypes.BOOLEAN,
  },

  serialize(data) {
    if (data.conditions) {
      data.conditions = JSON.stringify(data.conditions);
    }
    return data;
  },

  deserialize(data) {
    if (_.isString(data.conditions)) {
      data.conditions = JSON.parse(data.conditions);
    }
    return data;
  },
};

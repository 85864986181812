/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import SegmentTracking from 'optly/modules/segment';

import { DragAndDrop, Tile, Attention, Dropdown } from 'optimizely-oui';

import truncate from 'optly/filters/truncate';

import { DefaultMetrics } from '../../component_module/constants';
import {
  getEventNameFromCache,
  getMetricDescription,
  getRatioMetricDescription,
} from '../../component_module/fns';
import ComponentModuleActions from '../../component_module/actions';

import { EventsAndMetricsBuilderProps } from '../../events_and_metrics_builder';
import { Metric } from '../../component_module/types';
import { useFeature } from '@optimizely/react-sdk';

export interface ConfigureMetricsProps extends EventsAndMetricsBuilderProps {
  eventEntityCache: Immutable.Map<string, any>;
  viewEntityCache: Immutable.Map<string, any>;
}

export const ConfigureMetrics = ({
  currentProjectId,
  disablePrimaryMetricEdit,
  eventEntityCache,
  viewEntityCache,
  isDisabled,
  onChange,
  selectedMetrics,
}: ConfigureMetricsProps) => {
  const [isCompoundMetricsFeatureEnabled] = useFeature('compound_metrics');

  const trackedMetrics = React.useRef(new Set());
  const draggableMetrics = selectedMetrics.map((metric, index) => {
    const {
      aggregator,
      event_id,
      field,
      scope,
      winning_direction,
      metrics,
    } = metric;

    const eventNameToDisplay = event_id
      ? getEventNameFromCache(event_id, eventEntityCache, viewEntityCache)
      : DefaultMetrics.OVERALL_REVENUE.display_title;

    let { description } = getMetricDescription(
      winning_direction,
      aggregator,
      field,
      scope,
      eventNameToDisplay,
    );

    if (metrics?.length) {
      description = getRatioMetricDescription(
        metric,
        eventEntityCache,
        viewEntityCache,
      );
    }

    return {
      description,
      id: `${metric.display_title}:${metric.event_id}:${index}`,
      metric,
    };
  });

  const trackMetricOnce = (primaryMetric: Metric) => {
    const { aggregator, metrics } = primaryMetric;
  
    if (aggregator === 'ratio' && metrics?.length === 2) {
      const numeratorEventId = metrics[0].event_id;
      const denominatorEventId = metrics[1].event_id;
  
      const eventIdPair = `${numeratorEventId}-${denominatorEventId}`;
  
      // Prevent duplicate segment tracking calls for the same metric
      if (!trackedMetrics.current.has(eventIdPair)) {
        trackedMetrics.current.add(eventIdPair);
        SegmentTracking.tracking.trackEvent('Compound Metric Used As Primary Metric', primaryMetric);
      }
    }
  };
  
  const getActionsDropdownItems = (
    index: number,
    selectedMetrics: Metric[],
    metric: any,
  ) => {
    const handleEditMetric = () => {
      const showEditMetricComponent = isCompoundMetricsFeatureEnabled
        ? ComponentModuleActions.showEditMetricsModal
        : ComponentModuleActions.showEditMetricSheet;

      showEditMetricComponent({
        currentProjectId,
        metric,
        selectedMetrics,
      }).then(updatedMetric => {
        const updatedSelectedMetrics = [...selectedMetrics];
        updatedSelectedMetrics.splice(index, 1, updatedMetric);
        onChange(updatedSelectedMetrics);
      });
    };

    return [
      <>
        <Dropdown.ListItem key={`${index}-edit-action`}>
          <Dropdown.BlockLink
            onClick={handleEditMetric}
            testSection="tile-edit">
            <Dropdown.BlockLinkText text="Edit" />
          </Dropdown.BlockLink>
        </Dropdown.ListItem>
        <Dropdown.ListItem key={`${index}-delete-action`}>
          <Dropdown.BlockLink
            onClick={() => {
              const updatedSelectedMetrics = [...selectedMetrics];
              updatedSelectedMetrics.splice(index, 1);
              onChange(updatedSelectedMetrics);
            }}
            testSection="tile-delete">
            <Dropdown.BlockLinkText isDestructive={true} text="Delete" />
          </Dropdown.BlockLink>
        </Dropdown.ListItem>
      </>,
    ];
  };

  return (
    <DragAndDrop
      idForDroppableRegion="metrics-drag-and-drop"
      items={draggableMetrics}
      onDragEnd={(updatedSelectedMetrics: { metric: Metric }[]) => {
        const reorderedMetrics = updatedSelectedMetrics.map(({ metric }) => metric);
      
        onChange(reorderedMetrics);      
        if (reorderedMetrics.length > 0 && reorderedMetrics[0].aggregator === 'ratio') {
          const primaryMetric = reorderedMetrics[0];      
          trackMetricOnce(primaryMetric);
        }
      }}
      
      renderItem={items => {
        const { index, item } = items;
        const { description, metric } = item;
        const { display_title, event_id } = metric;
        const eventNameToDisplay = event_id
          ? eventEntityCache.getIn([event_id, 'api_name'])
          : DefaultMetrics.OVERALL_REVENUE.display_title;

        const isPrimaryMetricDisabledEdit =
          !!disablePrimaryMetricEdit &&
          selectedMetrics.length > 0 &&
          index === 0;

        const isEnabled = isPrimaryMetricDisabledEdit ? false : !isDisabled;
        const renderName = () => (
          <span style={{ display: 'inline-grid' }}>
            <span
              className="truncate"
              data-test-section="tile-name"
              title={display_title || eventNameToDisplay}>
              {truncate(display_title || eventNameToDisplay, 64)}
            </span>
          </span>
        );

        const renderDescription = () => (
          <span style={{ display: 'inline-grid' }}>
            <span
              className="word-break--word"
              data-test-section="tile-description"
              title={description}>
              {description}
            </span>
          </span>
        );

        return (
          <div className="width--1-1">
            <Tile
              description={renderDescription()}
              {...(isEnabled
                ? {
                    dropdownItems: getActionsDropdownItems(
                      index,
                      selectedMetrics,
                      metric,
                    ),
                  }
                : {})}
              isDraggable={
                !isDisabled &&
                selectedMetrics.length > 1 &&
                !!!disablePrimaryMetricEdit
              }
              key={event_id}
              name={renderName()}
              status={
                selectedMetrics.length > 1 && index === 0 ? 'Primary' : ''
              }
              onTileClick={() => {}}
              testSection={`metric-tile-${event_id || 'overall-revenue'}`}
            />
            {isPrimaryMetricDisabledEdit && (
              <div className="push--top">
                <Attention
                  alignment="left"
                  testSection="attention-metrics"
                  type="brand">
                  <p>
                    Multi-armed bandits only use the primary metric to determine
                    traffic allocation. You can’t edit or remove the primary
                    metric after the optimization has started.
                  </p>
                </Attention>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default ConfigureMetrics;

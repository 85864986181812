import ouiIcons from 'oui-icons';
import React from 'react';

import { OptimizelyProvider } from '@optimizely/react-sdk';

// eslint-disable-next-line import/no-unresolved
import { setBeforeLeave as NavBarBeforeLeave } from 'NAVBAR';

import IconSprite from 'react_components/icon_sprite';

import LoadingOverlay from 'react_components/loading_overlay';
import { RegionComponent } from 'react_components/region';
import getReactWrapperForVueComponent from 'react_components/vue_wrapper';
import CoreUIActionTypes from 'core/ui/action_types';
import DialogManager from 'core/ui/components/dialog_manager';
import flux from 'core/flux';
import NotifcationManager from 'core/ui/components/notification_manager';

import OptimizelyChampagneActions from 'optly/modules/optimizely_champagne/actions';

import { historyBackSilent } from './routing_helpers';

const DialogManagerVueWrapper = getReactWrapperForVueComponent(
  DialogManager,
  null,
  {
    handleHistoryBack: historyBackSilent,
  },
);
const NotificationManagerVueWrapper = getReactWrapperForVueComponent(
  NotifcationManager,
);

const regionMap = {
  layer: {
    1: 'p13n-layers-home',
    2: ['p13n-layer-detail', 'p13n-editor'],
    3: ['p13n-results'],
  },
  pages: {
    1: 'p13n-pages-home',
  },
  audiences: {
    1: 'p13n-audiences-home',
    2: 'p13n-audience-detail',
  },
  extensions: {
    1: 'p13n-data-layer',
    2: 'plugins-root',
  },
  settings: {
    1: 'project-settings-integrations',
    2: 'plugins-root',
  },
};

class OptimizelyApp extends React.Component {
  static displayName = 'OptimizelyApp';

  componentDidMount() {
    // register the dialog manager in flux
    flux.dispatch(CoreUIActionTypes.REGISTER_GLOBAL_COMPONENT, {
      id: 'dialogManager',
      instance: this.dialogManager,
    });
    // register the notification manager in flux
    flux.dispatch(CoreUIActionTypes.REGISTER_GLOBAL_COMPONENT, {
      id: 'notificationManager',
      instance: this.notificationManager,
    });
    // register the oui icons in flux
    flux.dispatch(CoreUIActionTypes.REGISTER_GLOBAL_COMPONENT, {
      id: 'ouiIcons',
      instance: this.ouiIcons,
    });
  }

  optimizelyClient = OptimizelyChampagneActions.getClientInstance();

  registerComponentRef = (id, instance) => {
    this[id] = instance;
  };

  render() {
    return (
      <OptimizelyProvider optimizely={this.optimizelyClient}>
        <div
          id="ouiIconSprite"
          className="display--none"
          dangerouslySetInnerHTML={{ __html: ouiIcons }}
          ref={this.registerComponentRef.bind(this, 'ouiIcons')}
        />
        <IconSprite />
        <LoadingOverlay className="wrap" loadingId="page">
          <div className="root-panel">
            <RegionComponent
              regionMap={regionMap}
              regionId="main"
              navBarBeforeLeave={NavBarBeforeLeave}
            />
          </div>
        </LoadingOverlay>
        <DialogManagerVueWrapper
          vueComponentRef={this.registerComponentRef.bind(
            this,
            'dialogManager',
          )}
        />
        <NotificationManagerVueWrapper
          vueComponentRef={this.registerComponentRef.bind(
            this,
            'notificationManager',
          )}
        />
      </OptimizelyProvider>
    );
  }
}

export default OptimizelyApp;

import React from 'react';
import { useGetters } from 'core/ui/methods/connect_getters';

import ProjectGetters from 'optly/modules/entity/project/getters';

function ProjectName({ projectId }: { projectId: number}) {
    const { project } = useGetters({
      project: ProjectGetters.byId(projectId)
    }, [projectId]) as { project: any }
    return <span>{project ? project.get('project_name') : ''}</span>;
}

export default React.memo(ProjectName);
import React from 'react';
import { ButtonIcon, Dropdown, Tile } from '@optimizely/axiom';

type AudienceConditionTileProps = {
  canAdd: boolean;
  description?: React.ReactNode;
  handleAddNewCondition: () => void;
  name: React.ReactNode;
  onDelete: () => void;
};

const AudienceConditionTile = (props: AudienceConditionTileProps) => {
  const { canAdd, description, handleAddNewCondition, name, onDelete } = props;

  return (
    <div className="flex flex-align--center push-half--bottom">
      <div className="width--11-12">
        <div className="flex flex--column">
          {' '}
          <Tile
            className="push flush"
            description={description}
            isDraggable={false}
            dropdownItems={[
              <Dropdown.ListItem key="1">
                <Dropdown.BlockLink
                  onClick={onDelete}
                  testSection="delete-audience-btn">
                  <Dropdown.BlockLinkText isDestructive={true} text="Delete" />
                </Dropdown.BlockLink>
              </Dropdown.ListItem>,
            ]}
            name={name}
          />
        </div>
      </div>
      <div className="width--1-12 push-half--left">
        {canAdd && (
          <ButtonIcon
            iconFill="default"
            iconName="plus"
            onClick={handleAddNewCondition}
            size="small"
            style="plain"
            title="Add another audience condition"
            testSection="add-another-condition-btn"
          />
        )}
      </div>
    </div>
  );
};

AudienceConditionTile.defaultProps = {
  description: undefined,
};

export default AudienceConditionTile;

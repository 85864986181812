/**
 * Getters for loading
 */
import { NAMESPACE_DELIMITER, STORE_KEY } from './constants';

/**
 * Checks if a certain type of entity is loading
 * Can check generically for a entity type
 * or entity type and id combination
 *
 * @param {string} entity
 * @param {number} id
 * @return {boolean}
 */
export function isEntityLoading(entity, id) {
  return [
    [STORE_KEY, 'entitiesLoading', entity],
    entityState => {
      if (!entityState) {
        return false;
      }

      if (!id) {
        return entityState.filter(value => !!value).size > 0;
      }

      return !!entityState.get(id);
    },
  ];
}

/**
 * Checks whether some key is currently loading
 * @param {String} key - The filter key to check.
 * @param {String} filter - The filter or "subkey" to append.
 *                          This just allows easy grouping of related keys.
 */
export function isLoading(key, filter) {
  const entry = filter ? `${key}${NAMESPACE_DELIMITER}${filter}` : key;
  return [[STORE_KEY, 'loading', entry], value => !!value];
}

/**
 * Checks whether some key is currently loading
 * @param {String} key - The filter key to check.
 * @param {String} page - The page number.
 */
export function isPageLoading(key, page) {
  return isLoading(key, page);
}

export default {
  isEntityLoading,
  isLoading,
  isPageLoading,
};

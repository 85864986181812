import DraggableList from 'react-draggable-list';
import Immutable, { toImmutable, toJS } from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Pill } from 'optimizely-oui';

import { connect } from 'core/ui/decorators';

import {
  actions as ComponentModuleActions,
  getters as ComponentModuleGetters,
} from '../../component_module';

class TokenTemplate extends React.Component {
  static propTypes = {
    anySelected: PropTypes.number.isRequired,
    dragHandle: PropTypes.func.isRequired,
    item: PropTypes.instanceOf(Object).isRequired,
  };

  editAudience = () => {
    const { item } = this.props;

    ComponentModuleActions.editAudience(item.id);
  };

  removeAudience = () => {
    const { item } = this.props;

    ComponentModuleActions.removeAudienceId(item.id);
  };

  shouldComponentUpdate(nextProps) {
    const { anySelected } = this.props;
    // Update component only when nothing is selected. This will avoid updating the
    // component while dragging is happening.
    return !anySelected || !nextProps.anySelected;
  }

  render() {
    const { dragHandle, item } = this.props;

    const experienceToken = dragHandle(
      <Pill
        isDraggable={true}
        isDismissible={true}
        name={
          <span>
            Experience for <span className="weight--bold">{item.name}</span>
          </span>
        }
        onDismiss={this.removeAudience}
        order={item.index + 1}
      />,
    );

    return (
      <div className="flex border--top soft-half--top">
        {experienceToken}
        <div className="flex flex--1 flex-justified--end flex-align--center">
          <Button onClick={this.editAudience} size="tiny" style="outline">
            View
          </Button>
        </div>
      </div>
    );
  }
}

@connect({
  orderedAudiences: ComponentModuleGetters.orderedAudiences,
})
class ExperienceOrderManager extends React.Component {
  static propTypes = {
    orderedAudiences: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  handleAudienceRearrange = orderedAudiences => {
    ComponentModuleActions.setOrderedAudienceIds(
      toImmutable(orderedAudiences.map(({ id }) => id)),
    );
  };

  render() {
    const { orderedAudiences } = this.props;

    return (
      <div data-test-section="selected-audience-list">
        <DraggableList
          itemKey="id"
          list={toJS(orderedAudiences)}
          onMoveEnd={this.handleAudienceRearrange}
          template={TokenTemplate}
        />
      </div>
    );
  }
}

export default ExperienceOrderManager;

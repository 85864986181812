import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import {
  getters as EventModuleGetters,
  fns as EventModuleFns,
} from 'optly/modules/entity/event';
import {
  getters as ProjectGetters,
  fns as ProjectFns,
} from 'optly/modules/entity/project';
import LayerEnums from 'optly/modules/entity/layer/enums';
import MetricConstants from 'optly/modules/entity/metric/constants';
import { getters as ViewGetters } from 'optly/modules/entity/view';

import fns from './fns';
import constants from './constants';

export const shouldUseCurrentProject = [
  'metricsPickerSelectedItems',
  'useCurrentProject',
];

const activeViewsMap = [
  ViewGetters.entityCache,
  views => views.filter(view => !view.get('archived')),
];

/**
 * Combines both views and event gae entities into a single format such that eventTypeNamePair generation
 * can happen seamlessly
 */
export const availableViews = [
  activeViewsMap,
  ProjectGetters.entityCache,
  CurrentLayerGetters.layer,
  (views, projectsMap, currentLayer) => {
    views = views.filter(view => {
      let shouldNotFilterView = !view.get('single_use');
      if (
        currentLayer &&
        currentLayer.get('url_targeting') &&
        view.get('single_use')
      ) {
        shouldNotFilterView =
          view.get('id') === currentLayer.getIn(['url_targeting', 'view_id']);
      }
      return shouldNotFilterView;
    });
    return fns.addProjectNameToEntities(views, projectsMap);
  },
];

/*
 * Only events that are not archived or variation_specific, with added project name field
 * @returns {Immutable.List}
 */
const activeProjectEvents = [
  EventModuleGetters.entityCache,
  ViewGetters.entityCache,
  ProjectGetters.entityCache,
  (events, views, projectsMap) => {
    const filterFn = EventModuleFns.createActiveProjectEventFilterFn(views);
    events = events.filter(filterFn);
    return fns.addProjectNameToEntities(events, projectsMap);
  },
];

/**
 * Combines views and event gae entities into a single format to enable eventTypeNamePair generation
 * This gets only events that are NOT archived OR variation_specific
 */
export const queryableActiveProjectEventsMap = [
  activeProjectEvents,
  availableViews,
  ProjectGetters.entityCache,
  fns.createMapOfQueryableEvents,
];

export const projectFilteredQueryableEventsMap = [
  queryableActiveProjectEventsMap,
  CurrentProjectGetters.project,
  shouldUseCurrentProject,
  ProjectGetters.entityCache,
  (eventsMap, currentProject, filterCurrentProject) => {
    let filteredEvents = eventsMap;
    if (filterCurrentProject) {
      filteredEvents = eventsMap.filter(
        event =>
          event.get('project_id') === currentProject.get('id') ||
          event.get('kind') === LayerEnums.metricKinds.REVENUE,
      );
    }
    return filteredEvents;
  },
];

export const customEventsGetterV2 = [
  projectFilteredQueryableEventsMap,
  events =>
    events
      .filter(event => event.get('event_type') === constants.eventTypes.CUSTOM)
      .toList(),
];

export const allPageViewEvents = [
  projectFilteredQueryableEventsMap,
  eventsMap =>
    eventsMap.filter(
      event => event.get('kind') === LayerEnums.metricKinds.VIEW,
    ),
];

export const allPageClickEvents = [
  projectFilteredQueryableEventsMap,
  eventsMap =>
    eventsMap.filter(
      event =>
        event.get('kind') === LayerEnums.metricKinds.EVENT &&
        event.get('event_type') === MetricConstants.event_type.CLICK,
    ),
];

export default {
  queryableActiveProjectEventsMap,
  projectFilteredQueryableEventsMap,
  allPageClickEvents,
  allPageViewEvents,
  availableViews,
  customEventsGetterV2,
  shouldUseCurrentProject,
};

const LayerFns = require('optly/modules/entity/layer/fns').default;

/*
 * Mixins which are used across layer components that share identical functionality.
 */

// Methods

/**
 * Returns a boolean value describing whether the current layer is an AB test.
 */
exports.isABTest = function() {
  this.currentLayer; // eslint-disable-line
  this.policy; // eslint-disable-line
  return LayerFns.isABTestLayer(
    this.currentLayer ? this.currentLayer : { policy: this.policy },
  );
};

/**
 * Returns a boolean value describing whether the current layer is a personalization campaign.
 */
exports.isPersonalizationCampaign = function() {
  this.currentLayer; // eslint-disable-line
  this.policy; // eslint-disable-line
  return LayerFns.isPersonalizationLayer(
    this.currentLayer ? this.currentLayer : { policy: this.policy },
  );
};

import { getRootVM } from 'core/ui/methods/root_vm';

/**
 * Gets a component registered on the root VM
 * Note: it is not encouraged to globally register all components
 * on the root vm, this approach means that every component is bundled
 * with every bundle, not allowing AMD hot loading of components on demand
 * @param {String} id
 * @return {Function} component constructor
 */
export default function getComponent(id) {
  const rootVM = getRootVM();
  if (!rootVM) {
    throw new Error('Cannot call getComponent with root ViewModel mounted');
  }
  const component = rootVM.$options.components[id];
  if (!component) {
    throw new Error(`No component found for: ${id}`);
  }

  return component;
}

/**
 * Function to generate a config object for highcharts with the dataset provided.
 *
 * @param {Object} data
 * @param {String} duration
 * @param {Function} redraw
 * @param {String} selectedColor
 * @return {Object} Highcharts config object.
 *   See https://www.highcharts.com/docs/getting-started/how-to-set-options
 */
export default function(data, duration, redraw, selectedColor) {
  return {
    chart: {
      className: 'audience-condition-chart',
      height: 250,
      type: 'areaspline',
      events: {
        redraw,
      },
      style: {
        fontFamily: 'Inter, Arial, Helvetica',
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      style: {
        padding: 0,
      },
      /**
       * Custom HTML to display the interest and visitor count.
       * TODO - Make this appear above the <Slider>.
       * @return {string} An HTML string for Highcharts to render.
       */
      formatter() {
        return `<span>
             Interest: <b>${Math.round(this.x * 100) / 100}</b>
             <br>Visitors: <b>${this.y}</b>
           </span>`;
      },
    },
    xAxis: {
      min: 0,
      max: 1,
      title: {
        text: 'Interest Score',
      },
      plotLines: [
        {
          width: 16,
          value: 0.5,
          zIndex: 5,
        },
      ],
    },
    yAxis: {
      title: {
        text: `Visitors (Last ${duration})`,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Visitors',
        data,
        zoneAxis: 'x',
        color: selectedColor,
        animation: {
          duration: 0,
        },
      },
    ],
  };
}

import keyMirror from 'optly/utils/key_mirror';
import Immutable from 'optly/immutable';

export const FEATURE_VARIABLE_TYPES = keyMirror({
  boolean: null,
  double: null,
  integer: null,
  string: null,
  json: null,
});

export const FEATURE_VARIABLE_TYPE_HUMAN_READABLES = {
  [FEATURE_VARIABLE_TYPES.boolean]: 'Boolean',
  [FEATURE_VARIABLE_TYPES.double]: 'Double',
  [FEATURE_VARIABLE_TYPES.integer]: 'Integer',
  [FEATURE_VARIABLE_TYPES.string]: 'String',
  [FEATURE_VARIABLE_TYPES.json]: 'JSON',
};

export const FEATURE_VARIABLE_ERRORS = {
  [FEATURE_VARIABLE_TYPES.boolean]: {
    valueError: 'Please enter a valid Boolean.',
  },
  [FEATURE_VARIABLE_TYPES.double]: {
    valueError: 'Please enter a valid Double.',
  },
  [FEATURE_VARIABLE_TYPES.integer]: {
    valueError: 'Please enter a valid Integer.',
  },
  [FEATURE_VARIABLE_TYPES.string]: {
    valueError: 'Please enter a valid String.',
  },
  [FEATURE_VARIABLE_TYPES.json]: {
    valueError: 'Please enter a valid JSON object.',
  },
};

export const LOADING_FEATURE_FLAGS = 'feature-flags-loading';

export const entityStatus = {
  NOT_STARTED: 'not_started',
  RUNNING: 'running',
  PAUSED: 'paused',
};

export const BASE_VARIABLE = Immutable.Map({
  api_name: '',
  default_value: '',
  type: FEATURE_VARIABLE_TYPES.string,
});

export const DEFAULT_VARIABLE = Immutable.Map({
  api_name: '',
  default_value: '',
  type: undefined,
});

export const MAX_API_NAME_LENGTH = 64;

export const FEATURE_VARIABLE_TEXTS = [
  {
    label: 'Boolean',
    value: FEATURE_VARIABLE_TYPES.boolean,
  },
  {
    label: 'Double',
    value: FEATURE_VARIABLE_TYPES.double,
  },
  {
    label: 'Integer',
    value: FEATURE_VARIABLE_TYPES.integer,
  },
  {
    label: 'String',
    value: FEATURE_VARIABLE_TYPES.string,
  },
  {
    label: 'JSON',
    value: FEATURE_VARIABLE_TYPES.json,
  },
];

export default {
  entityStatus,
  FEATURE_VARIABLE_TYPES,
  FEATURE_VARIABLE_TYPE_HUMAN_READABLES,
  FEATURE_VARIABLE_ERRORS,
  LOADING_FEATURE_FLAGS,
  BASE_VARIABLE,
  DEFAULT_VARIABLE,
  MAX_API_NAME_LENGTH,
  FEATURE_VARIABLE_TEXTS,
};

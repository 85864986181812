/**
 * Component to show an upsell dialog
 * Intended to be used for upselling features.
 *
 * @author Tim Scanlin (tim.scanlin@optimizely.com)
 */

import { hideDialog } from 'core/ui/methods/dialogs';

import { track } from 'optly/services/events';

import htmlTemplate from './upsell.html';

const exported = {
  template: htmlTemplate,

  data: {
    title: tr('Would you like access to this feature?'),
    label: 'upsell-default', // For event tracking purposes (don't translate).
    featureText: '',
    message: tr(`This feature is not enabled on your account. 
To gain access to this feature, please contact your Optimizely Customer 
Success Manager or Optimizely Sales 
<a href="https://www.optimizely.com/pricing?modal=contact-sales" target="_blank">here</a>.`),
    confirmText: tr('Contact Sales'),
    cancelText: tr('Cancel'),
    contactURL: 'https://www.optimizely.com/pricing?modal=contact-sales',
    buttonColor: 'lego-button--highlight',
    showImage: false,
    imageUrl: '',
    _onClose: null,
  },

  methods: {
    confirm(event) {
      track('upsell', 'confirm', this.label);
      if (this._onClose) {
        this._onClose.resolve();
      }
      hideDialog();
    },
  },

  ready() {
    track('upsell', 'dialog-open', this.label);
  },

  afterDestroy() {
    if (this._onClose) {
      // Reject if dialog is closed without confirming.
      this._onClose.reject();
    }
  },
};

export default exported;

export const { template, data, methods, ready, afterDestroy } = exported;

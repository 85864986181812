import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

const accountEntityActions = RestApi.createEntityActions(
  Object.assign({}, definition, { parent: null }),
);

export const {
  save,
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deleteRecommender = baseEntityActions.delete;

/**
 * Action to fetch all recommenders across the account which uses the accountEntityActions.fetchAllPages().
 * URL: api/v1/recommenders/.
 *
 * @param args
 * @returns {*|{allPages: *, firstPage: *}}
 */
export const fetchAllPagesForAccount = (...args) =>
  accountEntityActions.fetchAllPages(...args);

export { deleteRecommender as delete };

export default {
  ...baseEntityActions,
  fetchAllPagesForAccount,
};

/**
 * Getters for the Concurrency module.
 */

import AdminAccountGetters from 'optly/modules/admin_account/getters';

import fns from './fns';

/**
 * @param {string} id
 * @return {Getter}
 */
export const concurrencyStateByEntityId = id => [
  'p13n/concurrency',
  'entities',
  id,
];

/**
 * For a given entity_id, return a list of user_ids (email addresses)
 * who are currently subscribed to the entity.
 * @param {string} entityId
 * @returns {Immutable.List}
 */
export function usersListByEntityId(entityId) {
  return [
    concurrencyStateByEntityId(entityId),
    AdminAccountGetters.email,
    (entity, currentUserEmail) => {
      const rawUserEmails = fns.userListFromEntity(entity);
      const emails = fns.excludeUser(rawUserEmails, currentUserEmail);
      const sortedEmails = fns.sortUsers(emails);
      return sortedEmails;
    },
  ];
}

export default {
  concurrencyStateByEntityId,
  usersListByEntityId,
};

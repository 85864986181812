import _ from 'lodash';

import sort from 'optly/utils/sort';
import sanitizeHTML from 'optly/utils/sanitize_html';
import tr from 'optly/translate';

import constants from './constants';

const { DefaultGoals } = constants;
const { TypeCodes } = constants;
const { TypeNames } = constants;
const { MatchTypeCodes } = constants;

const InvertedTypeCodes = _.invert(TypeCodes);

/**
 * Get the customer-readable type of a goal
 */
export function goalTypeToString(code) {
  const type = InvertedTypeCodes[code];
  return TypeNames[type];
}

/**
 * Generate a description for a goal
 * @param {Model} goal
 * @return {String}
 */
export function generateGoalDescription(goal) {
  switch (goal.goal_type) {
    case TypeCodes.CLICK:
      return tr('The percentage of visitors who clicked on a tracked element.');
    case TypeCodes.CUSTOM_EVENT:
      return tr(
        'The percentage of visitors who triggered {0} (custom event).',
        sanitizeHTML(goal.event),
      );
    case TypeCodes.ENGAGEMENT:
      return tr(
        'The percentage of visitors who clicked on any part of the experiment page.',
      );
    case TypeCodes.REVENUE:
      return `${tr(
        'The revenue earned per visitor after visiting an experiment page.',
      )}
        <a class='help-button' href='https://help.optimizely.com/hc/en-us/articles/200039865' target='_blank'></a>`;
    case TypeCodes.PAGEVIEW:
      // very rarely (it's an error condition), there are pageview goals with no urls
      if (goal.urls === undefined) {
        return tr(
          'This goal is not set up to track views on any page. Please modify the goal or take a look at <a href="https://help.optimizely.com/hc/en-us/articles/200090069">Setting Up Goals</a> if you need help.',
        );
      }

      const urls = [];
      for (let i = 0; i < goal.urls.length; i++) {
        let type = '';
        switch (goal.url_match_types[i]) {
          case MatchTypeCodes.EXACT:
            type = tr('(exact match)');
            break;
          case MatchTypeCodes.REGEX:
            type = tr('(regular expression match)');
            break;
          case MatchTypeCodes.SIMPLE:
            type = tr('(simple match)');
            break;
          case MatchTypeCodes.SUBSTRING:
            type = tr('(substring match)');
            break;
        }
        urls.push(`${sanitizeHTML(goal.urls[i])} ${type}`);
      }
      return tr(
        'The percentage of visitors who visited {0}.',
        tr.addCommaToArray(urls, { or: true, quotes: false }),
      );
    case TypeCodes.MOBILE_TAP_EVENT_TYPE:
      return tr(
        'The percentage of visitors who tapped a specific element in the app.',
      );
    case TypeCodes.MOBILE_VIEW_EVENT_TYPE:
      return tr('The percentage of visitors who visited a view in the app.');
    case TypeCodes.MOBILE_SESSION_LENGTH_GOAL:
      return tr(
        'This goal measures the average amount of time that a user spends using the app within a single session. A session is measured as the time the app is in the foreground.',
      );
    case TypeCodes.MOBILE_NUM_SESSION_GOAL:
      return tr(
        'This goal tracks the average number of sessions made by an app user during a single week.',
      );
    default:
      return '';
  }
}

/**
 * Generate warning for the given goal
 * @param {Model} goal
 * @return {String}
 */
export function generateGoalWarning(goal) {
  switch (goal.goal_type) {
    case TypeCodes.MOBILE_SESSION_GOAL:
    case TypeCodes.MOBILE_SESSION_LENGTH_GOAL:
    case TypeCodes.MOBILE_NUM_SESSION_GOAL:
      return tr('Requires SDK version 1.3+');
    default:
      return '';
  }
}

/**
 * Generate the ordered list of goals for an experiment
 * @param {Object} experiment
 * @param {Object} goals belonging to the experiment above
 * @return {String}
 */
export function generateOrderedGoals(experiment, goals) {
  // order the goals according to the initially supplied goal order
  // if it exists
  const orderList = experiment.display_goal_order_lst
    ? experiment.display_goal_order_lst
    : experiment.display_goal_order;
  if (orderList) {
    // If a goal isn't in the order list, take it out and add it to the end.
    // Otherwise it gets sorted to the top of the list because its index is -1
    const newGoals = [];
    goals.forEach(goal => {
      if (orderList.indexOf(goal.id) < 0) {
        goals.splice(goals.indexOf(goal), 1);
        newGoals.push(goal);
      }
    });
    goals = _.clone(goals).sort(
      (a, b) => orderList.indexOf(a.id) - orderList.indexOf(b.id),
    );
    newGoals.forEach(goal => {
      goals.push(goal);
    });
  }

  // Ensure that the primary goal is at the top of the list by removing it and adding it to
  // the front of the list
  if (experiment.primary_goal_id) {
    const primaryGoal = _.remove(
      goals,
      goal => goal.id === experiment.primary_goal_id,
    )[0];

    if (primaryGoal) {
      goals.unshift(primaryGoal);
    }
  }
  return goals;
}

/**
 * Filter out duplicate default goals.
 * In case of duplicates, choose the default goal with the lowest id.
 * The default goal with the lowest id is used by client.
 * @param {Object} projectGoals goals belonging to the current project
 * @return {Object}
 */
export function filterDuplicateDefaultGoals(projectGoals) {
  // sort the goals first by goal type then by id
  const comparisonFunction = sort.generateObjectSortFn([
    { field: 'goal_type', type: 'number', dir: sort.ASC },
    { field: 'id', type: 'number', dir: sort.ASC },
  ]);

  let previousGoalType;
  return _.clone(projectGoals)
    .sort(comparisonFunction)
    .filter(goal => {
      const isDefaultGoal = DefaultGoals.indexOf(goal.goal_type) !== -1;
      const isFirstDefaultGoal =
        isDefaultGoal && previousGoalType !== goal.goal_type;

      // Add all non-default goals
      // Add the first default goal (ie. the one with the smallest id)
      const include = !isDefaultGoal || isFirstDefaultGoal;
      previousGoalType = goal.goal_type;
      return include;
    });
}

/**
 * For goals that don't have a last_modified property (older goals) return
 * the created date instead of null
 * If the goal doesn't have either, it is brand new, so return current date
 * @param {Object} goal
 * @return {String}
 */
export function getGoalLastModifiedDate(goal) {
  if (goal.last_modified) {
    return goal.last_modified;
  }
  if (goal.created) {
    return goal.created;
  }
  return new Date(Date.now()).toISOString();
}

export function isCustomEventGoal(goal) {
  /**
   * Returns whether a goal is Custom Event goal type
   */
  return goal.goal_type === TypeCodes.CUSTOM_EVENT;
}

/**
 * Services layer pure functions for the goals
 */
export default {
  goalTypeToString,
  generateGoalDescription,
  generateGoalWarning,
  generateOrderedGoals,
  filterDuplicateDefaultGoals,
  getGoalLastModifiedDate,
  isCustomEventGoal,
};

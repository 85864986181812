const { Input, Textarea } = require('optimizely-oui');

const Immutable = require('optly/immutable').default;

const PropTypes = require('prop-types');
const React = require('react');

// components
const Select = require('react_components/select').default;

const {
  FEATURE_VARIABLE_TYPES,
} = require('optly/modules/entity/feature_flag/constants');

const VariableValueInput = props => {
  const type = props.variable.get('type');

  const updateValueFromSelect = value => {
    const updatedVariable = props.variable.set(props.propertyToUpdate, value);
    props.onUpdateVariable(updatedVariable);
  };

  const updateValueFromInput = inputEvent => {
    const value = inputEvent.target.value;
    const updatedVariable = props.variable.set(props.propertyToUpdate, value);
    props.onUpdateVariable(updatedVariable);
    // variable string error handling
    if (props.handleVariableValueClick) {
      props.handleVariableValueClick(true);
    }
  };

  const variableValue = props.variable.get(props.propertyToUpdate);
  const isFormDirty = props.isFormDirty;
  const testSectionPrefix = props.testSectionPrefix
    ? `${props.testSectionPrefix}-`
    : '';
  const variableType = props.variable.get('type');
  switch (variableType) {
    case FEATURE_VARIABLE_TYPES.boolean:
      return (
        <Select
          isDisabled={props.disableVariable}
          isActivatorFullWidth={true}
          onChange={updateValueFromSelect}
          testSection={`${testSectionPrefix}variable-value-boolean`}
          value={variableValue}>
          <Select.Option
            label="True"
            value="true"
            key="true"
            testSection={`${testSectionPrefix}variable-value-boolean-true-option`}
          />
          <Select.Option
            label="False"
            value="false"
            key="false"
            testSection={`${testSectionPrefix}variable-value-boolean-false-option`}
          />
        </Select>
      );
    case FEATURE_VARIABLE_TYPES.double:
    case FEATURE_VARIABLE_TYPES.integer:
      return (
        <Input
          isDisabled={props.disableVariable}
          onChange={updateValueFromInput}
          type="number"
          testSection={
            variableType === FEATURE_VARIABLE_TYPES.double
              ? `${testSectionPrefix}variable-value-double`
              : `${testSectionPrefix}variable-value-integer`
          }
          value={variableValue}
        />
      );
    case FEATURE_VARIABLE_TYPES.json:
      return (
        <Textarea
          isDisabled={props.disableVariable}
          numRows={5}
          onChange={updateValueFromInput}
          testSection={`${testSectionPrefix}variable-value-json`}
          value={
            !isFormDirty
              ? JSON.stringify(JSON.parse(variableValue), undefined, 2)
              : variableValue
          }
        />
      );
    case FEATURE_VARIABLE_TYPES.string:
    default:
      return (
        <Textarea
          isDisabled={props.disableVariable}
          onChange={updateValueFromInput}
          type="text"
          testSection={`${testSectionPrefix}variable-value-string`}
          value={variableValue}
        />
      );
  }
};

VariableValueInput.propTypes = {
  disableVariable: PropTypes.bool,
  handleVariableValueClick: PropTypes.func,
  isFormDirty: PropTypes.bool,
  onUpdateVariable: PropTypes.func.isRequired,
  propertyToUpdate: PropTypes.string.isRequired,
  testSectionPrefix: PropTypes.string,
  variable: PropTypes.instanceOf(Immutable.Map).isRequired,
};

VariableValueInput.defaultProps = {
  disableVariable: false,
};

module.exports = VariableValueInput;

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon, ButtonRow } from 'optimizely-oui';

import { fieldPropType } from 'react_components/form';

/**
 * VariablesActionCell
 * @description Renders a table-cell that container create and actions buttons for the features-variables table
 * @kind component
 * @example
 *  <VariablesActionCell
 *    index={123}
 *    isDisabled={false}
 *    showAddButton={false}
 *    showRemoveButton={false}
 *    variableField={form.repeatedField('variables').field(n)}
 *    variablesField={form.repeatedField('variables')}
 *  />
 */
const VariablesActionCell = ({
  defaultVariable,
  index,
  isDisabled,
  showAddButton,
  showRemoveButton,
  variableField,
  variablesField,
  testSection,
}) => {
  const handleOnRemove = () => {
    const hasId = typeof variableField.getValue().get('id') === 'number';
    if (hasId) {
      variableField.field('archived').setValue(true);
    } else {
      variablesField.removeChild(index);
    }

    // We always want to render at least one field
    const activeVariableFields = variablesField.filterChildren(
      field => !field.getValue().get('archived'),
    );

    if (activeVariableFields.size === 0) {
      variablesField.pushChild(defaultVariable);
    }
  };

  const addRow = () => {
    variablesField.pushChild(defaultVariable);
  };

  return (
    <ButtonRow
      rightGroup={[
        ...(showRemoveButton
          ? [
              <ButtonIcon
                iconName="trash-can"
                isDisabled={isDisabled}
                onClick={handleOnRemove}
                size="small"
                style="outline"
                title="Remove row"
                testSection={`${testSection}-remove-button`}
                key="variables-action-cell-remove-button"
              />,
            ]
          : []),
        ...(showAddButton
          ? [
              <ButtonIcon
                iconName="plus"
                isDisabled={isDisabled}
                onClick={addRow}
                size="small"
                style="outline"
                title="Add new variable row"
                testSection={`${testSection}-add-button`}
                key="variables-action-cell-add-button"
              />,
            ]
          : []),
      ]}
    />
  );
};

VariablesActionCell.defaultProps = {
  isDisabled: false,
  showAddButton: false,
  showRemoveButton: false,
  testSection: 'variables-action-cell',
};

VariablesActionCell.propTypes = {
  defaultVariable: fieldPropType.isRequired,
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  showAddButton: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
  testSection: PropTypes.string,
  variableField: fieldPropType.isRequired,
  variablesField: fieldPropType.isRequired,
};

export default VariablesActionCell;

import _ from 'lodash';
import Nuclear from 'nuclear-js';

import Immutable, { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';
import enums from '../enums';

export default Nuclear.Store({
  getInitialState() {
    return Immutable.Map({});
  },

  initialize() {
    this.on(actionTypes.EXPERIMENT_RESULTS_FETCH_SUCCESS, saveResults);
    this.on(actionTypes.EXPERIMENT_VISITOR_COUNT_FETCH_SUCCESS, saveVisitors);
    this.on(
      actionTypes.EXPERIMENT_VISITOR_COUNT_FETCH_SUCCESS_BATCH,
      saveVisitorsBatch,
    );
  },
});

/**
 * Stores the result of the experiment visitor counts from backend API
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Array} payload.results [{cache_time: <Date>, id: <number>, visitor_count: <number>}, ...]
 * @param {String} payload.timestamp
 *
 * @return {Immutable.Map} state
 */
function saveVisitors(currentState, payload) {
  // data looks like:
  // [{"cache_time":"2014-11-18T22:31:14Z","by_variation":{"1019930313":5005,"1019930312":5006},"id":999820904,"visitor_count":10011}, ...]

  return currentState.withMutations(state => {
    payload.results.forEach(res => {
      const expId = res.id;
      if (!state.get(expId)) {
        state.set(
          expId,
          toImmutable({
            id: expId,
            variations: [],
          }),
        );
      }

      state
        .setIn([expId, 'retrieval_timestamp'], payload.timestamp)
        .setIn([expId, 'cache_time'], res.cache_time)
        .setIn([expId, 'visitors'], res.visitor_count);
    });
  });
}

/**
 * Stores the result of the experiment visitor counts from backend API
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Array} payload.results [{cache_time: <Date>, id: <number>, visitor_count: <number>}, ...]
 * @param {String} payload.timestamp
 *
 * @return {Immutable.Map} state
 */
function saveVisitorsBatch(currentState, payload) {
  // data looks like:
  //
  // {
  //   "request1": {
  //     "body": "[{\"variation_id\":\"2611300881\",\"visitors\":952,\"confidence\":0,\"conversions\":242,\"status\":\"winner\", ...}]",
  //   },
  //   "request2": {
  //     "body": "[{\"variation_id\":\"2611300882\",\"visitors\":950,\"confidence\":0,\"conversions\":233,\"status\":\"loser\", ...}]",
  //   }
  // }
  //

  return currentState.withMutations(state => {
    Object.entries(payload.results).forEach(
      ([experimentId, experimentRequest]) => {
        const experiment = JSON.parse(experimentRequest.body);
        const visitorMap = {};
        const expId = parseInt(experimentId, 10);

        Object.values(experiment).forEach(variationGoalPair => {
          visitorMap[variationGoalPair.variation_id] =
            variationGoalPair.visitors;
        });

        const visitorTotal = _.reduce(
          Object.values(visitorMap),
          (sum, num) => sum + num,
        );

        if (!state.get(expId)) {
          state.set(
            expId,
            toImmutable({
              id: expId,
              variations: [],
            }),
          );
        }

        const variationsByGoal = _.groupBy(experiment, 'goal_id');

        state
          .setIn([expId, 'cache_time'], experimentRequest.headers.date)
          .setIn([expId, 'conclusions'], calculateConclusions(variationsByGoal))
          .setIn([expId, 'retrieval_timestamp'], experimentRequest.headers.date)
          .setIn([expId, 'visitors'], visitorTotal);
      },
    );
  });
}

/**
 * Stores the result of the experiment visitor counts from backend API
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Number} payload.experiment_id
 * @param {String} payload.timestamp
 * @param {Array} payload.results
 *
 * @return {Immutable.Map} state
 */
function saveResults(state, payload) {
  // payload.results is a list of Variation Results which has an entry for every goal and variation on the experiment
  // to find the number of visitors filter by any goal id and reduce the number of visitors for each VariationResult
  const goalId = _.head(payload.results).goal_id;
  const visitors = payload.results
    .filter(res => res.goal_id === goalId)
    .reduce((total, variation) => total + variation.visitors, 0);

  return state.set(
    payload.experiment_id,
    toImmutable({
      id: payload.experiment_id,
      visitors,
      variations: payload.results,
      retrieval_timestamp: payload.timestamp,
    }),
  );
}

/**
 * Calculate the winners/losers/inconclusives for each Goal.
 * @param {Object} variationsByGoal - Object storing all variations by goalId.
 */
function calculateConclusions(variationsByGoal) {
  return _.mapValues(variationsByGoal, variations => {
    // Default to inconclusive
    const conclusion = {
      status: enums.goal_status_type.INCONCLUSIVE,
      text: enums.goal_status_type_text.INCONCLUSIVE,
      name: variations[0].goal_name,
    };

    // Find the most confident variation (matching logic from renderConclusion in detail_viewer.js)
    const nonBaselineVariations = _.filter(
      variations,
      v => v.status !== enums.goal_status_type.BASELINE,
    );

    if (nonBaselineVariations.length) {
      // Sort by confidence, then pick the most confident
      // Sorts ascending so most confident is last
      const sortedVariations = _.sortBy(nonBaselineVariations, [
        'confidence',
        'improvement',
      ]);
      const bestVariation = sortedVariations.pop();

      // We have a winner
      if (bestVariation.status === enums.goal_status_type.WINNER) {
        conclusion.status = enums.goal_status_type.WINNER;
        conclusion.text = enums.goal_status_type_text.WINNER;
        conclusion.variationName = bestVariation.variation_name;
        conclusion.improvement = bestVariation.improvement;
      }

      // All the variations are losing
      if (bestVariation.status === enums.goal_status_type.LOSER) {
        conclusion.status = enums.goal_status_type.LOSER;
        conclusion.text = enums.goal_status_type_text.LOSER;
        conclusion.variationName = bestVariation.variation_name;
        conclusion.improvement = bestVariation.improvement;
      }
    }
    return conclusion;
  });
}

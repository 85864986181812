import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';
import { Features } from 'optly/utils/enums';

import { actionTypes } from '../action_types';

/**
 * labsDataStore
 */
export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    /**
     * Schema for labs data
     *
     * id: <integer>, // an id for reference when selected in the UI
     * name: <string>,
     * category: <string>,
     * sidebarComponent: <string>, // the component must be registered on the labs tab
     * permissionRequire: <Feature Enum>, // feature enum required to see the labs entry
     */
    return toImmutable([
      {
        id: 2,
        name: tr('Executive Summary'),
        category: tr('Analytics'),
        sidebarComponent: 'executive-summary-sidebar',
        permissionRequired: Features.EXECUTIVE_SUMMARY,
        allowedInFullStack: false,
      },
      {
        id: 4,
        name: tr('Resave Project'),
        category: tr('Administration'),
        sidebarComponent: 'resave-project-sidebar',
        requiresAdmin: true,
        allowedInFullStack: true,
      },
      {
        id: 5,
        name: tr('Content Security Policy'),
        category: tr('Administration'),
        sidebarComponent: 'csp-sidebar',
        permissionRequired: Features.CSP_SUPPORT,
        allowedInFullStack: false,
      },
      {
        id: 6,
        name: tr('Optimizely Apps'),
        category: tr('Productivity'),
        sidebarComponent: 'apps-sidebar',
        permissionRequired: Features.APPS_TAB,
        allowedInFullStack: false,
      },
      {
        id: 7,
        name: tr('Archived Goals Setting'),
        category: tr('Administration'),
        sidebarComponent: 'exclude-inactive-goals-sidebar',
        allowedInFullStack: false,
      },
      {
        id: 8,
        name: tr('Recommendation Settings'),
        category: tr('Machine Learning'),
        sidebarComponent: 'recommender-settings-sidebar',
        permissionRequired: Features.RECOMMENDER,
        requiresAdmin: false,
        allowedInFullStack: false,
      },
      {
        id: 9,
        name: tr('Editor Protocol Preferences'),
        category: tr('Administration'),
        sidebarComponent: 'editor-proxy-settings-sidebar',
        requiresAdmin: true,
        allowedInFullStack: false,
      },
    ]);
  },

  initialize() {
    this.on(actionTypes.DASHBOARD_SET_LABS_DATA, setLabsData);
  },
});

/**
 * Overrides the current labs data
 * Currently only used for testing
 */
function setLabsData(state, payload) {
  return toImmutable(payload.data);
}

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

/**
 * Grab a specific live commit tag by its layer_id
 * @param {layerId} integer specifying the id of the layer of interest
 * @return {Getter}
 */
export function liveCommitTagByLayerId(layerId) {
  return [
    entityCache,
    commitTags =>
      commitTags.find(commitTag => commitTag.get('layer_id') === layerId) ||
      null,
  ];
}

export default {
  ...baseEntityGetters,
  liveCommitTagByLayerId,
};

import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

const initialState = toImmutable({
  now: {},
  timerange: {},
});

export default Nuclear.Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.RESULTS_API_STUB_DATE_NOW, stubDateNow);
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Number} payload.now
 */
function stubDateNow(state, payload) {
  return state.set('now', payload.now);
}

import Immutable, { toImmutable } from 'optly/immutable';
import { some, reduce } from 'lodash';

import constants from './constants';

export default {
  /**
   * Given a section, returns the sum of the variation percentages.
   * @param {Immutable.Map} section
   * @returns {number}
   */
  getTotalPercentageForSection(section) {
    return (
      section
        .get('variations')
        .reduce((sum, variation) => sum + Number(variation.get('weight')), 0) /
      100
    );
  },

  /**
   * Gets the number of combinations that the sections will be combined into
   * @param {Immutable.List<ExperimentSection>} sections
   * @returns {number} combination count
   */
  getCombinationCount(sections) {
    return sections && sections.size
      ? sections.reduce(
          (total, section) => total * section.get('variations').size,
          1,
        )
      : 0;
  },

  /**
   * Gets the number of remaining combinations that the sections will be combined into after deleting a section or a variation
   * @param {Immutable.List<ExperimentSection>} sections list of all sections in layer_experiment
   * @param {ExperimentSection} currentSection section that is to be deleted or has a variation to be deleted
   * @param {Boolean} isDeletingVariation true if deleting a variation in currentSection, false if deleting currentSection
   * @returns {Number} remaining combinations count after deletion
   */
  getRemainingCombinationCount(sections, currentSection, isDeletingVariation) {
    return sections && sections.size
      ? sections.reduce((total, section) => {
          let remainingSectionVariations = section.get('variations').size;
          if (section.get('id') === currentSection.get('id')) {
            remainingSectionVariations =
              isDeletingVariation && remainingSectionVariations > 1
                ? remainingSectionVariations - 1
                : 1;
          }
          return total * remainingSectionVariations;
        }, 1)
      : 0;
  },

  /**
   * Generates a default section for an MVT experiment
   * @param {Immutable.Map<FeatureVariable>} variable
   * @param {Immutable.Map<LayerExperiment>} experiment
   * @returns {Immutable.Map} the generated MVT section
   */
  generateDefaultMvtSection(variable, experiment) {
    const defaultVariation = {
      name: `${experiment.get('key')} - ${variable.get('api_name')}`,
      api_name: 'variation-1',
      variable_value: variable.get('default_value'),
      weight: 10000,
    };
    return toImmutable({
      name: `${experiment.get('key')} - ${variable.get('api_name')}`,
      layer_experiment_id: experiment.get('id'), // null for new experiments and that is fine :)
      variable_id: variable.get('id'),
      variations: [defaultVariation],
    });
  },

  /**
   * Generates default sections for an MVT experiment
   * @param {Immutable.Map<FeatureFlag>} feature
   * @param {Immutable.Map<LayerExperiment>} experiment
   * @returns {Immutable.List} the generated MVT sections
   */
  generateDefaultMvtSections(feature, experiment) {
    const defaultSections = [];
    (feature.get('variables') || []).forEach(variable => {
      defaultSections.push(
        this.generateDefaultMvtSection(variable, experiment),
      );
    });
    return Immutable.List(defaultSections);
  },

  /**
   * Updates sections for an MVT experiment based on changes to the feature
   * @param {Immutable.List} mvtSections
   * @param {Immutable.Map<FeatureFlag>} feature
   * @param {Immutable.Map<LayerExperiment>} experiment
   * @returns {Immutable.List} the generated MVT sections
   */
  updateMvtSections(mvtSections, feature, experiment) {
    const updatedMvtSections = [];
    (feature.get('variables') || []).forEach(variable => {
      const existingMvtSection = mvtSections.find(
        section => section.get('variable_id') === variable.get('id'),
      );
      if (existingMvtSection) {
        updatedMvtSections.push(existingMvtSection);
      } else {
        updatedMvtSections.push(
          this.generateDefaultMvtSection(variable, experiment),
        );
      }
    });
    return Immutable.List(updatedMvtSections);
  },

  /**
   * Validates a section
   * @param {Immutable.Map<ExperimentSection>} section
   * @returns {Immutable.Map} errors
   */
  validateSection(section) {
    let errors = toImmutable({});
    if (!section.get('name', '').trim()) {
      const nameErrorMessage = constants.ErrorMessages.SECTION_NAME_MISSING;
      errors = errors.set('name', nameErrorMessage);
    }

    const variations = section.get('variations');
    const variationsAreMissingNames = variations.some(
      variation =>
        variation.has('weight') &&
        (!variation.get('name') || variation.get('name').trim().length === 0),
    );

    if (variationsAreMissingNames) {
      const variationNameErrorMessage =
        constants.ErrorMessages.VARIATION_NAMES_MISSING;
      errors = errors.set('variations', variationNameErrorMessage);
    }

    return errors;
  },

  /**
   * Validates a list of sections
   * @param {Array<ExperimentSection>} section
   * @returns {Object} errors
   */
  validateSections(sections, combinationCount) {
    const errors = {
      mvtVariationNames: '',
      mvtVariationAllocation: '',
      mvtSectionNames: '',
      mvtCombinationLimit: '',
    };

    const mvtNeedsVariationNames = some(sections, section =>
      some(
        section.variations,
        variation => !variation.name || variation.name.trim().length === 0,
      ),
    );
    if (mvtNeedsVariationNames) {
      errors.mvtVariationNames =
        constants.ErrorMessages.VARIATION_NAMES_MISSING;
    }

    const mvtHasVariationAllocationError = some(
      sections,
      section =>
        reduce(
          section.variations,
          (sum, variation) => sum + variation.weight,
          0,
        ) !== 10000,
    );
    if (mvtHasVariationAllocationError) {
      errors.mvtVariationAllocation =
        constants.ErrorMessages.VARIATION_ALLOCATION_NOT_100;
    }

    const mvtNeedsSectionNames = some(
      sections,
      section => !section.name || section.name.trim().length === 0,
    );
    if (mvtNeedsSectionNames) {
      errors.mvtSectionNames = constants.ErrorMessages.SECTION_NAMES_MISSING;
    }

    if (combinationCount > constants.MAX_COMBINATION_COUNT) {
      errors.mvtCombinationLimit =
        constants.ErrorMessages.EXCEEDS_MAX_COMBINATION_COUNT;
    }

    return errors;
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown } from 'optimizely-oui';

import { useFeature } from '@optimizely/react-sdk';

import { fieldPropType } from 'react_components/form';

import FeatureConstants from 'optly/modules/entity/feature_flag/constants';
import FeatureFns from 'optly/modules/entity/feature_flag/fns';

/**
 * VariablesTypeCell
 * @description Renders a table-cell that contains a variable-type dropdown for the features-variables table
 * @kind component
 * @example
 *  <VariablesTypeCell
 *    readOnly={true}
 *    typeField={form.field('type')}
 *    defaultValueField={form.field('default_value')}
 *    isDisabled={false}
 */
const VariablesTypeCell = ({
  defaultValueField,
  isDisabled,
  isLastVariable,
  onChange,
  readOnly,
  showLabel,
  testSection,
  typeField,
}) => {
  const [isFeatureVariableJSONEnabled] = useFeature('feature_variable_json');

  const handleOnSelect = type => {
    if (type !== typeField.getValue()) {
      defaultValueField.setValue(FeatureFns.getDefaultValueForType(type));
    }
    typeField.setValue(type);
    onChange(type);
  };

  return (
    <div>
      {showLabel && <h6 className="muted push-half--bottom">Variable Type</h6>}
      {!readOnly ? (
        <SelectDropdown
          placement={isLastVariable ? 'top-start' : 'bottom-start'}
          dropdownDirection={isLastVariable ? 'up' : 'right'}
          width={isFeatureVariableJSONEnabled ? 165 : '100%'}
          onChange={handleOnSelect}
          isDisabled={isDisabled}
          initialPlaceholder="Select Variable Type"
          testSection={`${testSection}-dropdown`}
          value={typeField.getValue()}
          items={FeatureConstants.FEATURE_VARIABLE_TEXTS.filter(item => {
            if (isFeatureVariableJSONEnabled) {
              return true;
            }
            return item.value !== FeatureConstants.FEATURE_VARIABLE_TYPES.json;
          })}
        />
      ) : (
        <span data-test-section="variables-type-cell-read-only">
          {
            FeatureConstants.FEATURE_VARIABLE_TYPE_HUMAN_READABLES[
              typeField.getValue()
            ]
          }
        </span>
      )}
    </div>
  );
};

VariablesTypeCell.defaultProps = {
  isDisabled: false,
  isLastVariable: false,
  onChange: () => {},
  readOnly: false,
  showLabel: false,
  testSection: 'variables-type-cell',
};

VariablesTypeCell.propTypes = {
  defaultValueField: fieldPropType.isRequired,
  isDisabled: PropTypes.bool,
  isLastVariable: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  showLabel: PropTypes.bool,
  testSection: PropTypes.string,
  typeField: fieldPropType.isRequired,
};

export default VariablesTypeCell;

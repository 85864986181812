import { Store } from 'nuclear-js';

import Immutable, { toImmutable } from 'optly/immutable';

import { actionTypes } from '../action_types';

/**
 * Customization Data Store
 */
export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({});
  },

  initialize() {
    this.on(actionTypes.DASHBOARD_SET_COLUMNS_VISIBILITY, setColumnsVisibility);
    this.on(actionTypes.DASHBOARD_SET_ACTIVE_TAB, setActiveTab);
  },
});

/**
 * Overrides the current visibility state
 */
function setColumnsVisibility(state, payload) {
  const { projectId, visibleColumns, tab } = payload.data;
  const columnsProperty = `columns${tab ? `-${tab}` : ''}`;
  return state.updateIn([projectId], Immutable.Map(), project =>
    project.set(
      columnsProperty,
      Immutable.Map(
        visibleColumns.reduce((acc, columnID) => {
          acc[columnID] = true;
          return acc;
        }, {}),
      ),
    ),
  );
}

function setActiveTab(state, payload) {
  const { projectId, tab } = payload.data;
  return state.updateIn([projectId], Immutable.Map(), project =>
    project.set('active-tab', tab),
  );
}

import AjaxUtil from '../../utils/ajax';

export const getSupportLevels = () =>
  AjaxUtil.makeV1AjaxRequest({
    url: '/subscriber_info',
    type: 'GET',
  });

export default {
  getSupportLevels,
};

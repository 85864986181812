/**
 * NOTE(nate): event tracking in this module does not send data to marketo, per marketing team
 */

import RestApi from 'optly/modules/rest_api';
import events from 'optly/services/events';

import definition from './entity_definition';

let actions;

const baseEntityActions = RestApi.createEntityActions(definition);

/**
 * Archives a goal
 * @param {goal}
 * @return {Deferred}
 */
export function archive(goal) {
  events.track('dashboard2', 'archive-goal');
  return actions.save({
    id: goal.id,
    archived: true,
  });
}

/**
 * Unarchives a goal
 * @param {goal}
 * @return {Deferred}
 */
export function unarchive(goal) {
  events.track('dashboard2', 'unarcive-goal');
  return actions.save({
    id: goal.id,
    archived: false,
  });
}

export default actions = {
  ...baseEntityActions,
  archive,
  unarchive,
};

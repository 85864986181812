import React from 'react';
import PropTypes from 'prop-types';

import getSidebar from 'bundles/p13n/components/campaign_manager/sidebar';

import { connect } from 'core/ui/decorators';

import { getters as SectionModuleGetters } from 'bundles/p13n/sections/manager_mvt/section_module';
import { enums as LayerEnums } from 'optly/modules/entity/layer';

const Sidebar = getSidebar(
  'manager-mvt',
  LayerEnums.campaignTypes.SINGLE_EXPERIMENT,
);

@connect({
  sideBarItems: SectionModuleGetters.sideBarItems,
})
class MVTSidebar extends React.Component {
  static componentId = 'manager-mvt-sidebar';

  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    sideBarItems: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Sidebar
        activeTab={this.props.activeTab}
        items={this.props.sideBarItems}
        name="Multivariate Test"
        showVariationChangedBadge={true}
      />
    );
  }
}

export default MVTSidebar;

/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';

import { Button, Label, Link, Sheet, Table } from 'optimizely-oui';

import classNames from 'classnames';

import ui from 'core/ui';
import { connect } from 'core/ui/decorators';
import Immutable from 'optly/immutable';

import {
  getters as LayerGetters,
  enums as LayerEnums,
  humanReadable as LayerHumanReadable,
  fns as LayerFns,
} from 'optly/modules/entity/layer';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { getters as ProjectGetters } from 'optly/modules/entity/project';

import { SortableTableHeader } from 'react_components/sortable_table';
import SelectDropdown from 'react_components/select_dropdown';
import EventModuleEnums from 'optly/modules/entity/event/enums';
import UrlHelperV2 from 'optly/services/url_helper';

import DashboardEntityTable from 'bundles/p13n/components/entity_dashboard/entity_table';

const TABLE_ID = 'ExperimentsUsageDashboard';

@connect({
  layers: LayerGetters.entityCache,
  projects: ProjectGetters.entityCache,
  views: CurrentProjectGetters.multiUseViews,
  events: CurrentProjectGetters.events,
})
class EventsExperimentUsage extends React.Component {
  static propTypes = {
    events: PropTypes.instanceOf(Immutable.List).isRequired,
    layers: PropTypes.instanceOf(Immutable.Map).isRequired,
    projects: PropTypes.instanceOf(Immutable.Map).isRequired,
    selectedEventId: PropTypes.number.isRequired,
    views: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentEventId: props.selectedEventId,
    };

    this.collectEvents();
  }

  getListItemforEvent = (id, name, type, experimentUsage) => {
    const suffixS = experimentUsage > 1 ? 's' : '';
    const description = `${type} - Used in ${experimentUsage} experiment${suffixS}`;
    return {
      label: name,
      value: id,
      type,
      description,
    };
  };

  collectEvents = () => {
    const { events, views } = this.props;

    const customEvents = events
      .filter(
        event => event.get('event_type') === EventModuleEnums.eventTypes.CUSTOM,
      )
      .map(event =>
        this.getListItemforEvent(
          event.get('id'),
          event.get('name'),
          event.get('event_type'),
          event.get('experiment_count'),
        ),
      )
      .toJS();

    const pageviewEvents = views
      .filter(view => !view.get('archived'))
      .map(view =>
        this.getListItemforEvent(
          view.get('id'),
          `Visited ${view.get('name')} page`,
          EventModuleEnums.eventTypes.PAGE_VIEW,
          view.get('experiment_count_pageview'),
        ),
      )
      .toJS();

    const pageviewIds = views.map(view => view.get('id')).toJS();
    const clickEvents = events
      .filter(
        event =>
          !event.get('archived') &&
          !event.get('variation_specific') &&
          pageviewIds.includes(event.get('view_id')),
      )
      .map(event =>
        this.getListItemforEvent(
          event.get('id'),
          event.get('name'),
          event.get('event_type'),
          event.get('experiment_count'),
        ),
      )
      .toJS();

    this.events = customEvents.concat(pageviewEvents).concat(clickEvents);
  };

  getEventType = eventId => {
    const events = this.events.filter(event => event.value === eventId);
    return events.length === 1 ? events[0].type : '';
  };

  getExperimentsUsage = (eventId, eventType) => {
    const { events, layers } = this.props;
    if (!eventType) {
      eventType = this.getEventType(eventId);
    }
    const experimentUsage = layers.filter(
      layer =>
        !layer.get('archived') &&
        !LayerFns.hasLayerConcluded(layer) &&
        this.getLayerMetricCountforEvent(layer, eventId, eventType) > 0,
    );
    return experimentUsage.size;
  };

  isRunning = status => {
    return status === LayerEnums.status.RUNNING.toLowerCase();
  };

  getProjectName = id => {
    const { projects } = this.props;
    const project = projects.get(id, null);
    return project ? project.get('project_name') : '';
  };

  getStatusClassName = status =>
    classNames({
      'color--good-news': this.isRunning(status),
    });

  renderButtonsInFooter = () => [
    <Button
      testSection="close-button"
      style="highlight"
      key="Done"
      onClick={ui.hideDialog}>
      Done
    </Button>,
  ];

  renderTableHeader = () => {
    return (
      <tr>
        <SortableTableHeader field="name" type="string">
          Experiment Name
        </SortableTableHeader>
        <SortableTableHeader field="project" type="string">
          Project
        </SortableTableHeader>
        <SortableTableHeader field="type" type="string">
          Type
        </SortableTableHeader>
        <SortableTableHeader field="status" type="string">
          Status
        </SortableTableHeader>
        <Table.TH />
      </tr>
    );
  };

  getLayerMetricCountforEvent = (layer, eventId, eventType) => {
    const metricsWithEvent = layer
      .get('metrics')
      .filter(
        metric =>
          metric.get('event_type') === eventType &&
          metric.get('event_id') === eventId,
      );
    return metricsWithEvent.size;
  };

  renderTableRow = (layer, index) => {
    const { currentEventId } = this.state;
    const currentEventType = this.getEventType(currentEventId);
    const metricCount = this.getLayerMetricCountforEvent(
      layer,
      currentEventId,
      currentEventType,
    );
    const layerUrl = UrlHelperV2.campaignHome(
      layer.get('project_id'),
      layer.get('id'),
    );
    const isLayerArchivedOrConcluded =
      layer.get('archived') || LayerFns.hasLayerConcluded(layer);
    return metricCount > 0 && !isLayerArchivedOrConcluded ? (
      <Table.TR>
        <Table.TD testSection={`event-experiment-usage-${index}-description`}>
          <div>
            <Link
              testSection={`event-experiment-usage-${index}-name`}
              href={layerUrl}>
              {layer.get('name')}
            </Link>
            <div className="micro muted">{layer.get('description')}</div>
          </div>
        </Table.TD>
        <Table.TD testSection={`event-experiment-usage-${index}-id`}>
          {this.getProjectName(layer.get('project_id'))}
        </Table.TD>
        <Table.TD testSection={`event-experiment-usage-${index}-type`}>
          {LayerHumanReadable.TEXT_BY_LAYER_TYPE[layer.get('type')].name}
        </Table.TD>
        <Table.TD testSection={`event-experiment-usage-${index}-status`}>
          <span className={this.getStatusClassName(layer.get('status'))}>
            {LayerHumanReadable.STATUS_BY_ACTUAL_STATUS[layer.get('status')]}
          </span>
        </Table.TD>
      </Table.TR>
    ) : (
      ''
    );
  };

  render() {
    const { layers } = this.props;
    const { currentEventId } = this.state;
    return (
      <Sheet
        onClose={ui.hideDialog}
        title="Experiment Usage"
        footerButtonList={this.renderButtonsInFooter()}>
        <p>Event Name</p>
        <SelectDropdown
          isFilterable={true}
          testSection="experiment-usage-events-select"
          items={this.events}
          onChange={event => {
            this.setState({ currentEventId: event });
          }}
          value={currentEventId}
          width={320}
          minDropdownWidth={320}
        />
        <div className="soft-triple--top">
          {this.getExperimentsUsage(currentEventId) ? (
            <DashboardEntityTable
              tableId={TABLE_ID}
              testSection="events-experiment-usage-table"
              data={layers}
              renderTableRow={this.renderTableRow}
              renderTableHeader={this.renderTableHeader}
              defaultSortBy={{ field: 'status', type: 'string', dir: 'desc' }}
            />
          ) : (
            <Label testSection="no-experiment-usage-message">
              Experiments for this event may be archived. Try another event.
            </Label>
          )}
        </div>
      </Sheet>
    );
  }
}

export default EventsExperimentUsage;

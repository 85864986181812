import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Nuclear.Store({
  getInitialState() {
    return toImmutable({
      currentlySelectedTrafficAllocationPolicy: 'manual',
    });
  },

  initialize() {
    this.on(
      actionTypes.UPDATE_CURRENTLY_SELECTED_TRAFFIC_ALLOCATION_POLICY,
      updateCurrentlySelectedTrafficAllocationPolicy,
    );
  },
});

function updateCurrentlySelectedTrafficAllocationPolicy(state, payload) {
  return state.set('currentlySelectedTrafficAllocationPolicy', payload);
}

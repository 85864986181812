import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'core/ui/decorators';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import NavConstants from 'optly/services/navigation';
import { actions as VerifierActions } from 'optly/modules/verifier';
import Topbar from 'bundles/p13n/sections/layers/components/topbar';

import MainContent from './main_content';
import { generateTabProps } from './constants';

@connect({
  currentProjectId: CurrentProjectGetters.id,
})
class PageComponent extends React.Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    currentProjectId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    const { activeTab, currentProjectId } = props;
    this.tabProps = generateTabProps(activeTab);

    VerifierActions.verify({
      projectId: currentProjectId,
    }).catch(() => {}); // don't error on rejection
  }

  render() {
    return (
      <div className="stage__item__content--column">
        <Topbar activeTab={this.tabProps.layerTab} />
        <div
          className="flex flex--1"
          data-test-section={this.tabProps.dataTestSection}>
          <MainContent {...this.tabProps} />
        </div>
      </div>
    );
  }
}

export default PageComponent;

import enums from './enums';

const humanReadable = {
  keyFieldLocations: {
    [enums.keyFieldLocations.COOKIE]: tr('Cookie'),
    [enums.keyFieldLocations.JS_VARIABLE]: tr('JS Variable'),
    [enums.keyFieldLocations.QUERY_PARAM]: tr('Query Param'),
    [enums.keyFieldLocations.UID]: tr('Optimizely End User ID Cookie'),
  },
};

export default humanReadable;

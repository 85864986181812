/**
 * Targeting Conditions service
 *
 * Creates a registry of components for each targeting condition type
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const Vue = require('vue');
const _ = require('lodash');
const $ = require('jquery');
const flux = require('core/flux');
const TargetingCondition = require('optly/modules/entity/targeting_condition');

const PredictedIntentConditionGroup = require('optly/components/audience_editor/targeting_predicted_intent')
  .default;
const GeotargetingConditionGroup = require('optly/components/geotargeting/geotargeting_condition_group');
const ThirdPartyTargetingCondtionGroup = require('optly/components/third_party_targeting/third_party_targeting_condition_group');

/**
 * The base component to extend for every condition group
 */
const baseComponent = require('optly/components/audience_editor/condition_group');
/**
 * A mapping of condition_types => baseComponents
 * if the condition_type should not use the standard baseComponent
 */
const baseComponentExceptions = {
  location: GeotargetingConditionGroup,
  third_party_dimension: ThirdPartyTargetingCondtionGroup,
  predicted_intent: PredictedIntentConditionGroup,
};

// Registry of sub components
const conditionComponents = {};

// Mapping of condition types to component ids
const componentMap = {};

// Condition template filename overrides for dimensions
let componentFileNameOverrideMap = {};

/**
 * Gets the field definition for a dimensionType/field
 * @param {String} dimensionType
 * @param {String} field
 * @return { {
 *     required: <Boolean>
 *     values: <Array.<{ value: <String>, text: <String> }>>
 * }}
 */
function getFieldDefinition(dimensionType, field) {
  const dimensions = flux.evaluateToJS(TargetingCondition.getters.entityCache);
  if (!dimensions[dimensionType]) {
    throw new Error(`Invalid dimension type: ${dimensionType}`);
  }
  if (!dimensions[dimensionType].fields[field]) {
    throw new Error(`Invalid field: ${field}`);
  }

  return dimensions[dimensionType].fields[field];
}

/**
 * Sets componentFileNameOverrideMap and builds component map with potential overrides
 *
 * @param {Object} overrideMap - key/value map of original dimension name and updated condition template name (if applicable)
 */
function initializeComponentMap(overrideMap = {}) {
  componentFileNameOverrideMap = overrideMap;
  buildComponentMap();
}

/**
 * Checks if a particular value is a valid value for a condition type's field
 *
 * Ex: dimensionType='visitor' and field='value' and value='hey' => invalid
 *
 * @param {String} dimensionType
 * @param {String} field
 * @param {String} value
 * @return {Boolean}
 */
function isValidFieldValue(dimensionType, field, value) {
  let fieldDef;
  try {
    fieldDef = getFieldDefinition(dimensionType, field);
  } catch (e) {
    return false;
  }

  if (fieldDef.required && !value) {
    // if the field is required and value is fasly
    return false;
  }
  if (!fieldDef.required) {
    return true;
  }

  if (fieldDef.values) {
    const acceptableValues = fieldDef.values.map(def => def.value);

    if (acceptableValues.indexOf(value) === -1) {
      return false;
    }
  }
  return true;
}

/**
 * Grab targeting conditions from the store and build out the compnent map
 * @return {[type]} [description]
 */
function buildComponentMap() {
  const dimensions = flux.evaluateToJS(TargetingCondition.getters.entityCache);
  /**
   * Iterate over the 'dimensions' data from the RequireJS config for this module and create two things:
   *
   * 1.  A mapping of components only defined on the ViewModel for each supported
   * condition type.  All of these components extend the base `condition-group` component
   * but have their own templates
   *
   * 2.  A mapping of conditionTypes (ex: 'ad_campaign', 'browser') to their component id
   */
  _.forEach(dimensions, (dimension, type) => {
    // Ensure this dimension is an allowed dimension for audiences
    if (_.includes(dimension.used_by, 'audiences')) {
      const componentName = `conditions/${type}`;

      const componentToExtend = baseComponentExceptions[type]
        ? baseComponentExceptions[type]
        : baseComponent;

      const templateFileName = componentFileNameOverrideMap[type] || type;

      conditionComponents[componentName] = Vue.component(
        componentName,
        $.extend(true, {}, componentToExtend, {
          // TODO: implement without using dynamic path requires eslint-disable-next-line global-require
          // eslint-disable-next-line global-require
          template: require(`optly/components/audience_editor/conditions/${templateFileName}.html`),
          data: {
            // TODO(audexp): dimension *should* contain the conditionType
            conditionType: type,
            dimension,
          },
        }),
      );

      componentMap[type] = componentName;
    }
  });
}

function getComponentId(conditionType) {
  if (!componentMap[conditionType]) {
    buildComponentMap();
  }
  return componentMap[conditionType];
}

module.exports = {
  getComponentId,
  initializeComponentMap,
  isValidFieldValue,
};

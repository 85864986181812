import { enums as LayerEnums } from 'optly/modules/entity/layer';
import UrlHelper from 'optly/services/url_helper';

function getLayerUrl({ layer, projectId, unique_id, type }): string {
  if (layer) {
    return UrlHelper.campaignHome(projectId, layer.get('id'));
  }

  if (type === LayerEnums.type.PERSONALIZATION) {
    return UrlHelper.campaignHome(projectId, unique_id);
  }

  if (type === LayerEnums.type.MULTIVARIATE) {
    return UrlHelper.mvtHome(projectId, unique_id);
  }

  return UrlHelper.experimentHome(projectId, unique_id);
}

export default getLayerUrl;

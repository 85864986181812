import React from 'react';

import classNames from 'classnames';

import { Button, Poptip, Icon } from 'optimizely-oui';

import PropTypes from 'prop-types';

import SegmentTracking from 'optly/modules/segment';

import ui from 'core/ui';
import flux from 'core/flux';
import Router from 'core/router';
import UrlHelper from 'optly/services/url_helper';
import Immutable, { toJS } from 'optly/immutable';
import { connect } from 'core/ui/decorators';

import { isWinnerRolloutFeatureEnabled } from 'optly/utils/features';
import Events from 'optly/services/events';
import LayerActions from 'optly/modules/entity/layer/actions';
import LayerFns from 'optly/modules/entity/layer/fns';
import CurrentLayerActions from 'bundles/p13n/modules/current_layer/actions';
import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';

import PublishStatusActions from 'bundles/p13n/modules/publish_status/actions';
import PublishStatusGetters from 'bundles/p13n/modules/publish_status/getters';
import PublishStatusFns from 'bundles/p13n/modules/publish_status/fns';
import VerifierActions from 'optly/modules/verifier/actions';
import LiveCommitTagActions from 'optly/modules/entity/live_commit_tag/actions';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
import PermissionsGetters from 'optly/modules/permissions/getters';
import CampaignManagerGetters from 'bundles/p13n/modules/campaign_manager/getters';
import ResultsSectionConstants from 'bundles/p13n/sections/results/section_module/constants';

import { getters } from './component_module';

const waitForReindex = () => {
  return new Promise(resolve => setTimeout(resolve, 1000));
};

@connect(({ currentLayer }) => ({
  canArchiveLayer: [
    CurrentProjectGetters.project,
    PermissionsModuleFns.canDeleteLayer,
  ],
  canDeactivateLayer: [
    CurrentProjectGetters.project,
    PermissionsModuleFns.canDeactivateLayer.bind(PermissionsModuleFns),
  ],
  canUsePreview: PermissionsGetters.canUsePreview,
  currentLayer: CurrentLayerGetters.layer,
  currentProjectId: CurrentProjectGetters.id,
  disableStartButton: getters.disableStartButton,
  hasAnyProcessingAdaptiveAudienceConditions:
    CurrentLayerGetters.hasAnyProcessingAdaptiveAudienceConditions,
  isUsingV2Snippet: CurrentProjectGetters.isUsingV2Snippet,
  qaUrl: CampaignManagerGetters.campaignQAUrl,
  isActive: PublishStatusGetters.isActive(currentLayer.get('id')),
  liveTag: PublishStatusGetters.liveTag(currentLayer.get('id')),
  showPlayButton: PublishStatusGetters.showPlayButton(currentLayer.get('id')),
}))
class LayerActionsButtons extends React.Component {
  static propTypes = {
    canArchiveLayer: PropTypes.bool.isRequired,
    canDeactivateLayer: PropTypes.bool.isRequired,
    canUsePreview: PropTypes.bool.isRequired,
    currentLayer: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentProjectId: PropTypes.number.isRequired,
    disableStartButton: PropTypes.bool.isRequired,
    hasAnyProcessingAdaptiveAudienceConditions: PropTypes.bool.isRequired,
    hideResultsButton: PropTypes.bool,
    isActive: PropTypes.bool.isRequired,
    isUsingV2Snippet: PropTypes.bool.isRequired,
    liveTag: PropTypes.instanceOf(Immutable.Map).isRequired,
    qaUrl: PropTypes.string.isRequired,
    showPlayButton: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    hideResultsButton: false,
  };

  constructor(props) {
    super(props);
    const { currentLayer, hideResultsButton } = this.props;

    const campaignType = LayerFns.getCampaignTypeOfLayer(toJS(currentLayer));
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      text: PublishStatusFns.getText(campaignType, currentLayer.get('name')),
      showResultsButton: !hideResultsButton,
    };
  }

  layerIsArchived() {
    const { currentLayer } = this.props;
    return currentLayer && currentLayer.get('archived');
  }

  /**
   * Activate the live tag
   * @param {LiveCommitTag} liveTag
   * @param {Layer} currentLayer
   */
  activateTag = () => {
    const {
      currentLayer,
      hasAnyProcessingAdaptiveAudienceConditions,
      liveTag,
    } = this.props;
    const { text } = this.state;

    if (liveTag) {
      ui.confirm({
        title: hasAnyProcessingAdaptiveAudienceConditions
          ? text.adaptive.title
          : text.start.title,
        message: hasAnyProcessingAdaptiveAudienceConditions
          ? text.adaptive.message
          : text.start.message,
        confirmText: text.start.confirmText,
        doSanitizeHTML: false,
      }).then(() => {
        this.startCampaign(currentLayer);
      });
    }
  };

  startCampaign(layer) {
    const { text } = this.state;
    const layerId = layer.get('id');
    PublishStatusActions.startStarting(layerId);
    CurrentLayerActions.startCampaign(toJS(layer))
      .then(() => {
        ui.showNotification({
          message: text.start.confirmationText,
        });
        Events.trackMarketoEvent(
          'x_campaign_nav',
          'start',
          'experiment_for_layer',
          layerId,
        );

        /* This core API event is implemented in the frontend so that the context from which it was invoked can be inferred. */
        SegmentTracking.tracking.trackEvent('Experiment Started');
      })
      .finally(() => {
        PublishStatusActions.finishStarting(layerId);
      });
  }

  /**
   * Dectivate the live tag
   * @param {LiveCommitTag} liveTag
   * @param {Layer} currentLayer
   */
  deactivateTag = () => {
    const { currentLayer, liveTag } = this.props;
    const { text } = this.state;
    const layerId = currentLayer.get('id');
    if (liveTag) {
      ui.confirm({
        title: text.pause.title,
        message: text.pause.message,
        isWarning: true,
        confirmText: text.pause.confirmText,
      }).then(() => {
        // TODO (asa): Move pausing state out of component module and into current layer module?
        PublishStatusActions.startPausing(layerId);
        CurrentLayerActions.pauseCampaign(toJS(currentLayer))
          .then(() => {
            ui.showNotification({
              message: text.pause.confirmationText,
            });
          })
          .finally(() => {
            PublishStatusActions.finishPausing(layerId);

            /* This core API event is implemented in the frontend so that the context from which it was invoked can be inferred. */
            SegmentTracking.tracking.trackEvent('Experiment Paused');
          });
      });
    }
  };

  navigateToResults = () => {
    const { currentLayer, currentProjectId } = this.props;
    const layerId = currentLayer.get('id');
    const campaignExperiments = flux.evaluate(
      CurrentLayerGetters.campaignExperimentsByLayerId(layerId),
    );
    const currentExperiment = campaignExperiments.get(0);
    const isMultivariateTestLayer = LayerFns.isMultivariateTestLayer(
      currentLayer,
    );
    const isABTestLayer = LayerFns.isABTestLayer(currentLayer);
    const isPersonalizationLayer = LayerFns.isPersonalizationLayer(
      currentLayer,
    );
    let currentView;
    let url = null;

    if (isMultivariateTestLayer) {
      currentView = ResultsSectionConstants.previousViews.MULTIVARIATE_SECTIONS;
    } else if (isABTestLayer) {
      currentView = ResultsSectionConstants.previousViews.VARIATIONS;
    } else if (isPersonalizationLayer) {
      currentView = ResultsSectionConstants.previousViews.EXPERIENCES;
    }

    if (currentProjectId && layerId && currentView) {
      if (isPersonalizationLayer) {
        url = UrlHelper.layerResults(currentProjectId, layerId, {
          previousView: currentView,
        });
      } else if (
        (isMultivariateTestLayer || isABTestLayer) &&
        currentExperiment.get('id')
      ) {
        url = UrlHelper.layerExperimentResults(
          currentProjectId,
          layerId,
          currentExperiment.get('id'),
          {
            previousView: currentView,
          },
        );
      }
      Router.go(url);
    }
  };

  previewCampaign = () => {
    const { qaUrl, currentLayer } = this.props;
    Router.windowOpenInNewTab(qaUrl);
    Events.trackMarketoEvent(
      'x_campaign_nav_sidebar',
      'preview',
      'experiment_for_layer',
      currentLayer.get('id'),
    );
    SegmentTracking.tracking.trackEvent('Experiment Previewed');
  };

  /**
   * Archive the layer. If the layer is currently active,
   * confirm that the layer should be paused before archiving
   * @param {Layer} layer
   */
  archiveLayer = () => {
    const { currentLayer, currentProjectId, isActive, liveTag } = this.props;
    const { text } = this.state;
    const layerId = currentLayer.get('id');
    if (currentLayer.get('archived')) {
      return LayerActions.unarchive({
        id: layerId,
      }).then(() => {
        ui.showNotification({
          message: text.unarchive.message,
        });
      });
    }
    if (liveTag && isActive) {
      ui.confirm({
        title: text.archiveAndPause.title,
        message: text.archiveAndPause.message,
        isWarning: true,
        confirmText: text.archiveAndPause.confirmText,
      }).then(() => {
        PublishStatusActions.startPausing(layerId);

        LayerActions.archive({
          id: layerId,
        })
          .then(() => {
            LiveCommitTagActions.deactivateTag({
              layer_id: liveTag.get('layer_id'),
              // Purposefully don't pipe the result of the verifier as it takes
              // too long for the snippet to update
            })
              .then(updatedLiveCommit => {
                VerifierActions.verify({
                  projectId: currentProjectId,
                  revisionToVerify: updatedLiveCommit.project_code_revision,
                }); // don't error on rejection
              })
              .then(() => waitForReindex())
              .always(() => {
                PublishStatusActions.finishPausing(layerId);
              });
          })
          .then(() => waitForReindex())
          .then(() => {
            ui.showNotification({
              message: text.archiveAndPause.confirmationText,
            });

            /* This core API event is implemented in the frontend so that the context from which it was invoked can be inferred. */
            SegmentTracking.tracking.trackEvent('Experiment Archived');

            const campaignOverview = UrlHelper.xWebHome(currentProjectId);
            Router.go(campaignOverview);
          });
      });
    } else {
      ui.confirm({
        title: text.archive.title,
        message: text.archive.message,
        isWarning: true,
        confirmText: text.archive.confirmText,
      }).then(() => {
        LayerActions.archive({
          id: layerId,
        })
          .then(() => waitForReindex())
          .then(() => {
            ui.showNotification({
              message: text.archive.confirmationText,
            });

            /* This core API event is implemented in the frontend so that the context from which it was invoked can be inferred. */
            SegmentTracking.tracking.trackEvent('Experiment Archived');

            const campaignOverview = UrlHelper.xWebHome(currentProjectId);
            Router.go(campaignOverview);
          });
      });
    }
  };

  render() {
    const { showResultsButton } = this.state;
    const {
      canArchiveLayer,
      canDeactivateLayer,
      canUsePreview,
      currentLayer,
      disableStartButton,
      isUsingV2Snippet,
      liveTag,
      showPlayButton,
    } = this.props;

    const isWinnerRolloutEnabled = isWinnerRolloutFeatureEnabled();

    const resultsButton = showResultsButton && (
      <div className="lego-grid__cell flex flex-justified--center soft--left">
        <Poptip
          trigger="mouseenter"
          position="top"
          disable={!liveTag || !currentLayer.get('metrics').size}
          content="Results">
          <Button
            testSection="campaign-overview-sidebar-results"
            style={isWinnerRolloutEnabled ? 'icon' : 'unstyled'}
            size={isWinnerRolloutEnabled ? 'narrow' : undefined}
            isDisabled={!liveTag || !currentLayer.get('metrics').size}
            key="results"
            onClick={this.navigateToResults}>
            <Icon
              name="chart-column"
              className={classNames({
                'oui-icon oui-icon--medium': true,
                muted: !liveTag || !currentLayer.get('metrics').size,
              })}
              size="small"
            />
          </Button>
        </Poptip>
      </div>
    );

    const showPreviewButton =
      canUsePreview && (!isWinnerRolloutEnabled || showResultsButton);

    const shouldDisableButton =
      !isUsingV2Snippet ||
      this.layerIsArchived() ||
      LayerFns.hasLayerConcluded(currentLayer);

    return (
      <div className="lego-grid push--top">
        {!isWinnerRolloutEnabled && (
          <div className="flex-justified--center lego-grid__cell flex">
            {!showPlayButton ? (
              <Poptip
                trigger="mouseenter"
                position="top"
                disable={!liveTag || !canDeactivateLayer}
                content="Pause">
                <Button
                  testSection="campaign-overview-sidebar-pause"
                  style="unstyled"
                  isDisabled={!liveTag || !canDeactivateLayer}
                  key="pause"
                  onClick={this.deactivateTag}>
                  <Icon
                    className={classNames({
                      'oui-icon oui-icon--medium': true,
                      muted: !liveTag || !canDeactivateLayer,
                    })}
                    name="pause"
                    size="small"
                  />
                </Button>
              </Poptip>
            ) : (
              <Poptip
                trigger="mouseenter"
                position="top"
                disable={disableStartButton}
                content="Start">
                <Button
                  testSection="campaign-overview-sidebar-play"
                  style="unstyled"
                  isDisabled={disableStartButton}
                  key="play"
                  onClick={this.activateTag}>
                  <Icon
                    className={classNames({
                      'oui-icon oui-icon--medium': true,
                      muted: disableStartButton,
                    })}
                    name="play"
                    size="small"
                  />
                </Button>
              </Poptip>
            )}
          </div>
        )}
        {!isWinnerRolloutEnabled && resultsButton}
        {showPreviewButton && (
          <div
            className={classNames({
              'flex flex-justified--center lego-grid__cell': true,
              'soft--left': showResultsButton,
            })}>
            <Poptip
              trigger="mouseenter"
              position="top"
              disable={shouldDisableButton}
              content="Preview">
              <Button
                testSection="campaign-overview-sidebar-preview"
                style={isWinnerRolloutEnabled ? 'icon' : 'unstyled'}
                size={isWinnerRolloutEnabled ? 'narrow' : undefined}
                isDisabled={shouldDisableButton}
                key="next"
                onClick={this.previewCampaign}>
                <Icon
                  name="eye"
                  size="small"
                  className={classNames({
                    'oui-icon oui-icon--medium': true,
                    muted: shouldDisableButton,
                  })}
                />
              </Button>
            </Poptip>
          </div>
        )}
        {isWinnerRolloutEnabled && resultsButton}
        {!isWinnerRolloutEnabled && (
          <div
            className={classNames({
              'flex flex-justified--center lego-grid__cell': true,
              'soft--left': showResultsButton,
            })}>
            <Poptip
              trigger="mouseenter"
              position="top"
              disable={!canArchiveLayer}
              content={this.layerIsArchived() ? 'Unarchive' : 'Archive'}>
              <Button
                testSection="campaign-overview-sidebar-archive"
                style="unstyled"
                isDisabled={!canArchiveLayer}
                key="next"
                onClick={this.archiveLayer}>
                <Icon
                  className={classNames({
                    'oui-icon oui-icon--medium': true,
                    muted: !canArchiveLayer,
                  })}
                  name="box-archive"
                  size="small"
                />
              </Button>
            </Poptip>
          </div>
        )}
      </div>
    );
  }
}

export default LayerActionsButtons;

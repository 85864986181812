/**
 * Translates enum location to human readable form
 * @param {String} value
 * @return {String}
 */
module.exports = function(value) {
  const insertLocationMap = {
    after: tr('after'),
    append: tr('at the end of'),
    before: tr('before'),
    prepend: tr('at the beginning of'),
  };

  return insertLocationMap[value] || tr('before');
};

const createPluginConfigField = require('./create_plugin_config_field');

module.exports = createPluginConfigField({
  template: require('./toggle.html'),

  methods: {
    selectOption(option) {
      this.updateValue(option);
    },

    getValue() {
      return this.value;
    },
  },
});

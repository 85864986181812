import Immutable, { toImmutable } from 'optly/immutable';
import AdminAccountGetters from 'optly/modules/admin_account/getters';
import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import PermissionsGetters from 'optly/modules/permissions/getters';
import * as LayerConstants from 'optly/modules/entity/layer/constants';
import ViewFns from 'optly/modules/entity/view/fns';

import constants from './constants';

const getters = {};

getters.urlTargetingConfig = [
  CurrentLayerGetters.layer,
  currentLayer => {
    if (!currentLayer) {
      return Immutable.Map();
    }
    let urlTargetingConfig = currentLayer.get('url_targeting');
    if (!urlTargetingConfig) {
      return constants.DEFAULT_URL_TARGETING_CONFIG;
    }

    const {
      includeConditions,
      excludeConditions,
    } = ViewFns.deserializeConditions(urlTargetingConfig.get('conditions'));
    urlTargetingConfig = urlTargetingConfig
      .set('includeConditions', toImmutable(includeConditions))
      .set('excludeConditions', toImmutable(excludeConditions))
      .delete('conditions');

    return urlTargetingConfig.toMap();
  },
];

getters.targetingType = [
  CurrentLayerGetters.layer,
  PermissionsGetters.canUseUrlTargeting,
  (currentLayer, canUseUrlTargeting) => {
    if (!currentLayer || !canUseUrlTargeting) {
      return LayerConstants.TargetingTypes.SAVED_PAGES;
    }

    const urlTargetingConfig = currentLayer.get('url_targeting');
    if (urlTargetingConfig) {
      return LayerConstants.TargetingTypes.URL;
    }

    return LayerConstants.TargetingTypes.SAVED_PAGES;
  },
];

/*
 * Return all saved views that are not url targeting views
 * (url targeting views are distinguished by the field single_use=True)
 */
getters.allSavedViews = [
  CurrentProjectGetters.views,
  views => views.filter(view => !view.get('single_use')),
];

getters.savedViewIds = [
  CurrentLayerGetters.layer,
  getters.allSavedViews,
  (currentLayer, allSavedViews) => {
    if (!currentLayer || !currentLayer.get('view_ids')) {
      return Immutable.Set([]);
    }
    return currentLayer
      .get('view_ids')
      .filter(id => allSavedViews.some(view => view.get('id') === id))
      .toSet();
  },
];

getters.urlTargetingConfigSmart = [
  CurrentLayerGetters.layer,
  AdminAccountGetters.accountPermissions,
  (currentLayer, account) => {
    if (!currentLayer) {
      return Immutable.Map();
    }
    let urlTargetingConfig = currentLayer.get('url_targeting');
    if (!urlTargetingConfig) {
      return constants.DEFAULT_URL_TARGETING_CONFIG_SMART;
    }

    urlTargetingConfig = urlTargetingConfig.set('is_url_targeting', true);

    return urlTargetingConfig;
  },
];

export default getters;

import React from 'react';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import flux from 'core/flux';
import Router from 'core/router';
import UrlHelper from 'optly/services/url_helper';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import FeatureGetters from 'optly/modules/entity/feature_flag/getters';
import PermissionsGetters from 'optly/modules/permissions/getters';

import NavConstants from 'optly/services/navigation';

import { withSidebarAndManagerChangeHistory } from 'bundles/p13n/components/change_history';

import {
  fetchRequiredDataForFeatureManager,
  fetchRequiredDataForManagerChangeHistory,
} from './routing_fns';
import RoutingFns from '../../routing_fns';
import SectionModuleConstants from './section_module/constants';

import Sidebar from './components/sidebar';

/*
 * Routing function that ensures the Feature Manager dynamic bundle has been fetched
 */
function FeatureManagerSection(ctx, next) {
  import(
    /* webpackChunkName: "feature-manager-bundle" */
    'bundles/p13n/sections/manager_feature' // eslint-disable-line
  )
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(FeatureManagerSection, module);
    })
    .then(next);
}

const shouldHandleFullstackFeatures = () =>
  Promise.resolve(
    flux.evaluate(CurrentProjectGetters.isFullStackProject) &&
      flux.evaluate(PermissionsGetters.canCurrentProjectUseTargetedRollouts),
  );

function setPageTitle({ next, name, params }) {
  next();
  let primaryName = '';

  if (params.feature_id) {
    const currentFeature = flux.evaluate(
      FeatureGetters.byId(Number(params.feature_id)),
    );
    primaryName = isFeatureEnabled('user_friendly_names')
      ? !!currentFeature &&
        (currentFeature.get('name') || currentFeature.get('api_name'))
      : !!currentFeature && currentFeature.get('api_name');
  }
  RoutingFns.setPageTitle({
    name,
    primaryName,
  });
}

/*
 * Routes for the Feature Manager section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/projects/:proj_id/features/:feature_id',
    shouldHandle: () =>
      flux.evaluate(PermissionsGetters.canCurrentProjectUseTargetedRollouts)
        ? Promise.resolve()
        : Promise.reject(),
    handle: [
      ctx => {
        if (Number.isNaN(Number(ctx.params.feature_id))) {
          Router.redirect(UrlHelper.featuresHome(ctx.params.proj_id));
          return;
        }
        Router.redirect(
          UrlHelper.featureManagerRules(
            ctx.params.proj_id,
            ctx.params.feature_id,
          ),
        );
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/features/:feature_id/rules',
    shouldHandle: shouldHandleFullstackFeatures,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.FEATURES,
        ),
        FeatureManagerSection,
        RoutingFns.parseProjectId,
      ],
      (ctx, next) => FeatureManagerSection.pages.rules.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Rules',
          params,
        }),
      ({ params }) => {
        RoutingFns.renderMainRegion(
          <FeatureManagerSection.pages.rules.pageComponent
            featureId={Number(params.feature_id)}
          />,
        );
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/features/:feature_id/variables',
    shouldHandle: shouldHandleFullstackFeatures,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.FEATURES,
        ),
        FeatureManagerSection,
        RoutingFns.loadingMainStart,
        RoutingFns.parseProjectId,
      ],
      (ctx, next) =>
        FeatureManagerSection.pages.variables.routingSetup(ctx, next),
      ({ params }) => {
        RoutingFns.renderMainRegion(
          <FeatureManagerSection.pages.variables.pageComponent
            featureId={Number(params.feature_id)}
          />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/features/:feature_id/sdk-setup',
    shouldHandle: shouldHandleFullstackFeatures,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.FEATURES,
        ),
        FeatureManagerSection,
        RoutingFns.parseProjectId,
      ],
      (ctx, next) =>
        FeatureManagerSection.pages.sdkSetup.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'SDK Setup',
          params,
        }),
      ({ params }) => {
        RoutingFns.renderMainRegion(
          <FeatureManagerSection.pages.sdkSetup.pageComponent
            featureId={Number(params.feature_id)}
          />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/features/:feature_id/experiment-usage',
    shouldHandle: shouldHandleFullstackFeatures,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.FEATURES,
        ),
        FeatureManagerSection,
        RoutingFns.parseProjectId,
      ],
      (ctx, next) =>
        FeatureManagerSection.pages.experimentUsage.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Experiment Usage',
          params,
        }),
      ({ params }) => {
        RoutingFns.renderMainRegion(
          <FeatureManagerSection.pages.experimentUsage.pageComponent
            featureId={Number(params.feature_id)}
          />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/features/:feature_id/history',
    shouldHandle: shouldHandleFullstackFeatures,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.FEATURES,
        ),
        FeatureManagerSection,
        RoutingFns.parseProjectId,
      ],
      (ctx, next) => {
        const featureId = parseInt(ctx.params.feature_id, 10);
        fetchRequiredDataForFeatureManager(featureId).then(next);
      },
      (ctx, next) => fetchRequiredDataForManagerChangeHistory(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'History',
          params,
        }),
      // ctx.fetchedData is set within fetchRequiredDataForManagerChangeHistory
      ({ fetchedData, params }) => {
        const featureId = parseInt(params.feature_id, 10);
        // Define within route since the componentId needs to change for renderMainRegion to remount the component
        const ManagerChangeHistory = withSidebarAndManagerChangeHistory(
          Sidebar,
        );
        RoutingFns.renderMainRegion(
          <ManagerChangeHistory
            fetchedEntities={fetchedData}
            sidebarProps={{
              activeTab: SectionModuleConstants.tabs.HISTORY,
              featureId,
            }}
          />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/features/:feature_id/settings',
    shouldHandle: shouldHandleFullstackFeatures,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.FEATURES,
        ),
        FeatureManagerSection,
        RoutingFns.parseProjectId,
      ],
      (ctx, next) =>
        FeatureManagerSection.pages.settings.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Settings',
          params,
        }),
      ({ params }) => {
        RoutingFns.renderMainRegion(
          <FeatureManagerSection.pages.settings.pageComponent
            featureId={Number(params.feature_id)}
          />,
        );
      },
    ],
  },
];

export default routes;

import { toImmutable } from 'optly/immutable';

export const OUTLIER_FILTER_DEFAULT_STATE = toImmutable({
  enabled: true,
});

// Used for layers created prior to introduction of outlier filtering
// (which we enable by default).This constants allow layers to be reverted
// back to their original state when there was no outlier filtering.
export const OUTLIER_FILTER_PRIOR_LAYERS_DEFAULT_STATE = OUTLIER_FILTER_DEFAULT_STATE.set(
  'enabled',
  false,
);

export default {
  OUTLIER_FILTER_DEFAULT_STATE,
  OUTLIER_FILTER_PRIOR_LAYERS_DEFAULT_STATE,
};

/**
 * IMPORTANT!
 *
 * SELECT COMPONENT WILL BE DEPRECATED!
 * USE THE SELECT_DROPDOWN COMPONENT INSTEAD.
 *
 */

import PropTypes from 'prop-types';
import React from 'react';

import dropdownService from 'optly/services/dropdown';

const Option = props => (
  <li className="lego-dropdown__item">
    <a
      className="lego-dropdown__block-link"
      data-test-section={props.testSection}
      onClick={function(event) {
        dropdownService.hide(event.currentTarget);
        props.parentCallback(props.value);
      }}>
      {props.label}
    </a>
  </li>
);

Option.propTypes = {
  /**
   * The string or <span> to link as the main option text
   */
  label: PropTypes.node,

  /**
   * On click, this is called with props.value as its first argument
   */
  parentCallback: PropTypes.func,

  testSection: PropTypes.string,

  /**
   * Value of the Option
   */
  value: PropTypes.any,
};

export default Option;

import _ from 'lodash';

/**
 * Entity Definition for template
 *
 * @type {Template}
 * @property {Number} id id for the particular template within a project
 * @property {Number} template_id id for the particular template within a project
 * @property {String} name
 * @property {String} description
 * @property {String} template_type the type of the template, e.g. plugin or panel
 * @property {Array} categories
 * @property {String} created
 * @property {String} last_modified
 * @property {Boolean} is_enabled_in_client
 * @property {String} edit_page_url url for the page that should be loaded in the template builder
 * @property {Object} options object of all options for the template
 * @property {Array} form_schema holds the schema for the form inputs available in the editor
 * @property {Object} template_type_settings object of all options for the template
 * @property {Boolean} optimizely_template
 * @property {Number} project_id
 */

export const entity = 'templates';

export const parent = {
  entity: 'projects',
  key: 'project_id',
};

export const deserialize = data => {
  // TODO(Matt): This is necessary until we run a data migration to transfer
  // 'default', a string property, into 'default_values', a JSON property
  data.form_schema = _.map(data.form_schema, field => {
    if (field.default_value === undefined || field.default_value === null) {
      field.default_value = field.default;
    }
    delete field.default;
    return field;
  });
  return data;
};

export default {
  entity,
  parent,
  deserialize,
};

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { OverlayWrapper, Popover, Table, Icon } from 'optimizely-oui';
import {
  yellowBase,
  greenDark,
} from '@optimizely/design-tokens/dist/json/colors.json';

export default class EventDistributionsSection extends React.Component {
  static propTypes = {
    /**
     * info about the current event distribution sample
     */
    eventDistributionInfo: PropTypes.shape({
      description: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      testSection: PropTypes.string.isRequired,
    }).isRequired,
    /**
     * the currently selected distribution sample for the currently selected percentage of distribution
     */
    selectedDistributionSample: PropTypes.shape({
      active: PropTypes.bool,
      item_id: PropTypes.number,
      num_events: PropTypes.number,
      recency: PropTypes.string,
    }).isRequired,
  };

  /**
   * Renders the distribution samples table for each distribution percentage which consists of some sample items along
   * with some other information regarding whether or not it's in the catalog, has any related events, and when was
   * the last time that an event was triggered for the corresponding item.
   */
  renderDistributionSamples = () => {
    const { eventDistributionInfo } = this.props;

    return (
      <div data-test-section={eventDistributionInfo.testSection}>
        <h3 className="push--bottom">{eventDistributionInfo.label}</h3>
        <div
          className="milli push--bottom"
          data-test-section={`${eventDistributionInfo.testSection}-description`}>
          {eventDistributionInfo.description}
        </div>
        <div className="push--sides">
          <Table>
            <Table.THead>
              <Table.TR>
                <Table.TH colSpan={2}>ItemID</Table.TH>
                <Table.TH isNumerical={true}>In Catalog</Table.TH>
                <Table.TH isNumerical={true}>Event Total</Table.TH>
                <Table.TH isNumerical={true}>Last Event Received</Table.TH>
              </Table.TR>
            </Table.THead>
            <Table.TBody>{this.renderDistributionSampleRows()}</Table.TBody>
          </Table>
        </div>
      </div>
    );
  };

  /**
   * Renders a single row of distribution sample for a given sample item with some indications if the item is in the
   * catalog or not.
   */
  renderDistributionSampleRows = () => {
    const { eventDistributionInfo, selectedDistributionSample } = this.props;

    return selectedDistributionSample.map(sample => {
      const itemID = sample.get('item_id');
      let inCatalogLabel = (
        <Icon color={greenDark} name="circle-check" size="medium" />
      );
      if (!sample.get('active')) {
        inCatalogLabel = (
          <OverlayWrapper
            behavior="hover"
            horizontalAttachment="left"
            horizontalTargetAttachment="right"
            overlay={
              <Popover>
                This item was removed from your catalog because we couldn't
                verify its URL. You should verify whether or not this was
                intended.
              </Popover>
            }
            verticalAttachment="middle"
            verticalTargetAttachment="middle">
            <Icon color={yellowBase} size="small" name="circle-exclamation" />
          </OverlayWrapper>
        );
      }
      const numEvents = Number(sample.get('num_events')).toLocaleString();
      const lastReceivedDate = moment(sample.get('recency')).format(
        'MM/DD/YYYY',
      );

      return (
        <React.Fragment key={itemID}>
          <Table.TR
            backgroundColor="faint"
            borderStyle="ends"
            testSection={`${eventDistributionInfo.testSection}-row-${itemID}`}>
            <Table.TD colSpan={2}>
              <span className="word-break--word">{itemID}</span>
            </Table.TD>
            <Table.TD isNumerical={true}>
              <span className="push-double--right">{inCatalogLabel}</span>
            </Table.TD>
            <Table.TD isNumerical={true}>{numEvents}</Table.TD>
            <Table.TD isNumerical={true}>{lastReceivedDate}</Table.TD>
          </Table.TR>
        </React.Fragment>
      );
    });
  };

  render() {
    return (
      <div className="push-quad--bottom">
        {this.renderDistributionSamples()}
      </div>
    );
  }
}

import { Store } from 'nuclear-js';

import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

const initialState = {
  preferences: {},
};

export default Store({
  getInitialState() {
    return toImmutable(initialState);
  },

  initialize() {
    this.on(
      actionTypes.FETCH_NOTIFICATION_PREFERENCES,
      fetchNotificationPreference,
    );
    this.on(
      actionTypes.SAVE_NOTIFICATION_PREFERENCES,
      saveNotificationPreference,
    );
  },
});

/**
 * Handler for actionTypes.FETCH_NOTIFICATION_PREFERENCES
 * @param {Object} state
 * @param {Object} payload
 */
function fetchNotificationPreference(state, payload) {
  return state.set('preferences', payload.preferences);
}

/**
 * Handler for actionTypes.SAVE_NOTIFICATION_PREFERENCES
 *
 * @param {Object} state
 * @param {Object} payload
 */
function saveNotificationPreference(state, payload) {
  return state;
}

import $ from 'jquery';
import flux from 'core/flux';
import ui from 'core/ui';

import {
  getters as CurrentLayerGetters,
  fns as CurrentLayerFns,
} from 'bundles/p13n/modules/current_layer';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import * as LayerConstants from 'optly/modules/entity/layer/constants';
import {
  fns as ViewFns,
  getters as ViewGetters,
} from 'optly/modules/entity/view';

import fns from './fns';

const actions = {};

/*
 * @param {Immutable.Map} urlTargetingConfig
 * @returns {Object} view
 */
actions.convertURLTargetingConfigToView = function(urlTargetingConfig) {
  const conditions = ViewFns.serializeConditions(
    urlTargetingConfig.get('includeConditions'),
    urlTargetingConfig.get('excludeConditions'),
  );
  const urlTargetingView = urlTargetingConfig
    .set('conditions', conditions)
    .delete('includeConditions')
    .delete('excludeConditions');

  const viewFromStore = flux.evaluate(
    ViewGetters.byId(urlTargetingConfig.get('view_id')),
  );
  const currentProjectId = flux.evaluate(CurrentProjectGetters.id);

  return fns.addFieldsToTargetingConfigForViewConversion(
    urlTargetingView,
    viewFromStore,
    currentProjectId,
  );
};

/*
 * @param {Immutable.Map} urlTargetingConfig
 * @returns {Object} view
 */
actions.convertURLTargetingConfigToSmartView = function(urlTargetingConfig) {
  const viewFromStore = flux.evaluate(
    ViewGetters.byId(urlTargetingConfig.get('view_id')),
  );
  const currentProjectId = flux.evaluate(CurrentProjectGetters.id);

  return fns.addFieldsToTargetingConfigForViewConversion(
    urlTargetingConfig,
    viewFromStore,
    currentProjectId,
  );
};

/*
 * If user is switching a layer from using url targeting to
 * saved views, check to see if there are any changes associated with
 * the URL Targeting view. If there are, show a warning dialog.
 * @param {String} targetingType
 * @param {Number} viewId
 * @returns {Deferred}
 */
actions.confirmSwitchFromURLTargetingToSavedViews = function(
  targetingType,
  viewId,
) {
  let changeCounts;
  if (targetingType === LayerConstants.TargetingTypes.URL) {
    const changesForView = flux.evaluate(
      CurrentLayerGetters.currentLayerChangesForView(viewId),
    );
    changeCounts = CurrentLayerFns.generateChangeCountsMessage(changesForView);
  }
  let confirmDef = $.Deferred().resolve();
  if (changeCounts) {
    confirmDef = ui.confirm({
      title: tr('Switching to Saved Pages will discard changes'),
      message: [
        tr('The following changes will be discarded:'),
        '<div class="color--bad-news weight--bold push--bottom">',
        changeCounts,
        '</div><div class="lego-media color--bad-news push--bottom" data-test-section="publish-warning"> <svg class="lego-icon lego-media__img"> <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#exclamation-16"></use> </svg> <div class="lego-media__body"> <p>',
        tr('Once discarded, changes cannot be recovered.'),
        '</p> </div> </div>',
        tr("Are you sure you'd like to switch to Saved Pages?"),
      ].join(''),
      doSanitizeHTML: false,
      confirmText: tr('Switch to Saved Pages and Discard Changes'),
      isWarning: true,
    });
  }
  return confirmDef;
};

/*
 * If user is switching a layer from using saved views to
 * url targeting, check to see if there are any changes associated with
 * any of the saved views. If there are, show a warning dialog.
 * @param {String} targetingType
 * @param {Immutable.Set} savedViewIds
 * @param {Immutable.List} allSavedViews
 * @returns {Deferred}
 */
actions.confirmSwitchFromSavedViewsToURLTargeting = function(
  targetingType,
  savedViewIds,
  allSavedViews,
) {
  const lostChangeMessages = [];
  if (targetingType === LayerConstants.TargetingTypes.SAVED_PAGES) {
    savedViewIds.forEach(viewId => {
      const changesForView = flux.evaluate(
        CurrentLayerGetters.currentLayerChangesForView(viewId),
      );
      const changeCounts = CurrentLayerFns.generateChangeCountsMessage(
        changesForView,
      );
      if (changesForView.size) {
        const viewName = allSavedViews
          .find(view => view.get('id') === viewId)
          .get('name');

        const message = [
          '<div class="color--bad-news weight--bold push--bottom">',
          'Page: ',
          viewName,
          '<div class="style--italic">',
          changeCounts,
          '</div></div>',
        ].join('');

        lostChangeMessages.push(message);
      }
    });
  }

  let confirmDef = $.Deferred().resolve();
  if (lostChangeMessages.length) {
    confirmDef = ui.confirm({
      title: tr(
        'Switching to URL Targeting will discard all changes on all pages',
      ),
      message: [
        tr('The following changes will be discarded:'),
        lostChangeMessages.join(''),
        '<div class="lego-media color--bad-news push--bottom" data-test-section="publish-warning"> <svg class="lego-icon lego-media__img"> <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#exclamation-16"></use> </svg> <div class="lego-media__body"> <p>',
        tr('Once discarded, changes cannot be recovered.'),
        '</p> </div> </div>',
        tr("Are you sure you'd like to switch to URL Targeting?"),
      ].join(''),
      doSanitizeHTML: false,
      confirmText: tr('Switch to URL Targeting and Discard Changes'),
      isWarning: true,
    });
  }
  return confirmDef;
};

export default actions;

import Immutable from 'optly/immutable';

/**
 * Creates boilerplate getters for an entity module
 */
export default function(entityDef) {
  const entityCacheGetter = [
    ['entityCache', entityDef.entity],
    /**
     * @return {Immutable.Map}
     */
    entityMap => {
      // protect the entityMap here from being undefined, there are cases
      // where an entity type isn't loaded yet, so we need to always to
      // return an Immutable.Map for getters downstream
      if (!entityMap) {
        return Immutable.Map();
      }
      return entityMap;
    },
  ];

  return {
    entityCache: entityCacheGetter,

    /**
     * Returns getter that pulls an entity by id
     * @return {Array}
     */
    byId(id) {
      return [entityCacheGetter, entityMap => entityMap.get(id)];
    },
  };
}

const _ = require('lodash');

const RestApi = require('optly/modules/rest_api');
const { toImmutable } = require('optly/immutable');

const definition = require('./entity_definition');

_.extend(exports, RestApi.createEntityGetters(definition));

exports.getUngroupedList = function(topLevelKey) {
  return [
    exports.entityCache,
    categories => {
      if (categories.has(topLevelKey)) {
        return categories.getIn([topLevelKey, 'options']);
      }

      return toImmutable([]);
    },
  ];
};

exports.getList = function(topLevelKey) {
  return [
    exports.entityCache,
    categories =>
      categories
        .getIn([topLevelKey, 'options'])
        .map(groupEntry => groupEntry.get('value')),
  ];
};

import _ from 'lodash';
import sprintf from 'sprintf';

import sort from 'optly/utils/sort';
import Immutable, { toImmutable } from 'optly/immutable';

import fieldTypes from './field_types';
import constants from './constants';

/**
 * @typedef APIRequestQueueItem
 * @property {Function} fn - The partially applied API call function to invoke.
 * @property {Deferred} def - The promise to resolve/reject when the API call completes.
 */

/**
 * Given a queue of API requests, execute them all without exceeding the
 * number of concurrent pending requests specified by CONCURRENT_QUEUE_LIMIT
 * @param {Array<APIRequestQueueItem>} queue
 * @param {Number} concurrentLimit - The max number of requests to process in parallel.
 * @returns {Promise => Array<Object>} - Resolves when all requests have completed.
 *                                       Rejects when any request fails.
 */
export function processRequestQueue(queue, concurrentLimit) {
  let processingCount = 0;
  function process() {
    if (queue.length === 0) {
      return;
    }
    if (
      processingCount < (concurrentLimit || constants.CONCURRENT_QUEUE_LIMIT)
    ) {
      const next = queue.shift();
      processingCount += 1;
      next
        .fn()
        .then(next.def.resolve, next.def.reject)
        .always(() => {
          processingCount -= 1;
          process();
        });
      process();
    }
  }
  setTimeout(process);
  return Promise.all(queue.map(i => i.def));
}

/**
 * Creates a sorting function based on the model.fieldTypes
 * and order string/array
 *
 * order is of the format 'created:desc' or ['created:desc', 'id:asc']
 * @param {Object} entityFieldTypes
 * @param {Array|String} order
 * @return {Function}
 */
export function createSortFn(order, entityFieldTypes) {
  entityFieldTypes = entityFieldTypes || {};
  // If a string is passed in, coerce to an array
  if (typeof order === 'string') {
    order = [order];
  }

  // transform ['created:desc', 'id:asc'] => [
  //   { field: 'created', dir: 'desc', type: 'date' },
  //   { field: 'id', dir: 'asc', type: 'number' },
  // ]
  const sortBy = order.map(orderString => {
    const orderParts = orderString.split(':');
    const field = orderParts[0];
    const direction =
      orderParts.length > 1 && orderParts[1] === sort.DESC
        ? sort.DESC
        : sort.ASC;
    let type = entityFieldTypes[field];

    // default to string or number if id
    if (!type && field === 'id') {
      type = field === 'id' ? fieldTypes.NUMBER : fieldTypes.STRING;
    }

    return {
      field,
      dir: direction,
      type,
    };
  });

  return sort.generateImmutableObjectSortFn(sortBy);
}

/**
 * Creates and returns a single function that returns true/false
 * whether an item meets all of the filters
 *
 * Supports doing a _.contains for ARRAY types
 *
 * @param {Object} entityFieldTypes
 * @param {Object?} filters
 */
export function createFilterFn(filters, entityFieldTypes) {
  entityFieldTypes = entityFieldTypes || {};

  // Filter keys starting with $ are only used by fetchAll & fetchPage (pagination).
  const filterKeysToOmit = Object.keys(filters).filter(key => key[0] === '$');
  const filtersToUse = _.omit(filters, filterKeysToOmit);
  // map the current filters to filterFns
  const filterFns = _.map(filtersToUse, (val, field) => {
    if (entityFieldTypes[field] === fieldTypes.ARRAY) {
      return item => _.includes(item.get(field), val);
    }

    if (Array.isArray(val)) {
      return item => {
        if (Array.isArray(item.toJS()[field])) {
          return item.get(field).get(0);
        }

        return _.includes(val, item.get(field));
      };
    }

    return item => Array.isArray(item.toJS()[field]) || item.get(field) === val;
  });

  return item => filterFns.every(fn => fn(item));
}

/**
 * Gets a cached paged result from entity store
 * @param {Immutable.Map} entityMap supplied by the modelCache store
 * @param {object} modelDef
 * @param {object|undefined} filters
 * @return {array.<Immutable.Map>}
 */
export function getPage(map, modelDef, filters = {}) {
  const data = getAll(map, modelDef, filters);

  if (filters.$limit || filters.$offset) {
    const { $limit } = filters;
    const $offset = filters.$offset ? filters.$offset : 0;

    return data.skip($offset).take($limit);
  }
  return data;
}

/**
 * Gets a cached paged result from entity store
 *
 * Get all data in the store, can supply map of filters
 * @param {Immutable.Map} entityMap supplied by the modelCache store
 * @param {object} modelDef
 * @param {object|undefined} filters
 * @return {array.<Immutable.Map>}
 */
export function getAll(entityMap, modelDef, filters) {
  const filtersCopy = filters || {};
  if (!entityMap) {
    return Immutable.List([]);
  }
  let data = entityMap.toList();

  const { $order } = filtersCopy;
  const keysForRemoval = ['$order', '$limit', '$offset'];
  const $filters = _.omitBy(
    filtersCopy,
    filter =>
      keysForRemoval.includes(filter) ||
      // [FE-822] Strip out filters that are empty arrays for API/stubbed API parity
      (Array.isArray(filter) && !filter.length),
  );

  if (!_.isEmpty($filters)) {
    data = data.filter(createFilterFn($filters, modelDef));
  }

  if ($order) {
    data = data.sort(createSortFn($order, modelDef.fieldTypes));
  }

  return data;
}

/**
 * Given an immutable requestInfo object to query by the ancestor or parent
 * Return the corresponding requestInfo object to fetch the single result
 *
 * @param {Object} result single entity instance returned by result of hitting the API
 * @param {Object} entityDef object defining the entity
 * @param {Immutable.Map} requestInfo map defining the request info to query by multiple entities
 * @return {Immutable.Map} map defining the request info to query by a single entity
 */
export function createSingleFetchRequestInfo(entityData) {
  return toImmutable({
    entity: entityData.entity,
    method: constants.apiActions.FETCH,
    requestArgs: {
      id: entityData.id,
    },
  });
}

/**
 * Given an entity model defintion and the method to invoke on it,
 * check whether we are invoking a fetchAll
 *
 * This returns false if we invoke fetchAll with some filters - which are just manipulating the cached entity for read purposes,
 * while it will return true when we invoke fetchAll with only the argsToOmit below
 * This allows us to be sure that api state is consistent with flux store state when fetchAll is invoked on an entity
 *
 * Omit args that are not modifying the entity (like $order)
 *
 * @param {Object} entityDef
 * @param {string} method
 * @param {number|object} args the single argument passed to fetch*
 *
 *
 * @return {Boolean}
 */
export function isTrueFetchAll(entityDef, method, args) {
  const argsToOmit = ['$order', '$limit', '$offset'];
  if (method === 'fetchAll') {
    if (entityDef.parent) {
      argsToOmit.push(entityDef.parent.key);
    }
    const prunedArgs = _.omit(args, argsToOmit);
    return _.size(prunedArgs) === 0;
  }
  return false;
}

/**
 * Construct localStorage Key based on parent_id/key name and entity name
 *
 * @param {string} parent key (name)
 * @param {string|Number} parentId / parent
 * @param {string} entity
 *
 * @return {string}
 */
export function getLocalStorageKey(parentKey, parentId, entity) {
  return sprintf('entity_%s_%s_%s', entity, parentKey, parentId);
}

export default {
  createFilterFn,
  createSingleFetchRequestInfo,
  createSortFn,
  getPage,
  getAll,
  getLocalStorageKey,
  isTrueFetchAll,
  processRequestQueue,
};

import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import LayerEnums from 'optly/modules/entity/layer/enums';

export const isCurrentLayerInDraftStatus = [
  CurrentLayerGetters.layer,
  layer => {
    if (!layer) {
      return true;
    }
    return layer.get('status') === LayerEnums.entityStatus.NOT_STARTED;
  },
];

export default {
  isCurrentLayerInDraftStatus,
};

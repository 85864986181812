import LocalStorageWrapper from 'optly/utils/local_storage_wrapper';

import constants from './constants';

export const hasJoyrideBeenSeen = joyrideId => {
  const joyrides = LocalStorageWrapper.getItem(
    constants.JOYRIDE_LOCAL_STORAGE_KEY,
  );

  if (!joyrides) {
    return false;
  }

  try {
    const joyridesObject = JSON.parse(joyrides);
    return !!joyridesObject[joyrideId];
  } catch (e) {
    return false;
  }
};

export const hasJoyrideExceededSkips = (joyrideId, maxSkips) => {
  const joyrideSkips = LocalStorageWrapper.getItem(
    constants.SKIP_JOYRIDE_LOCAL_STORAGE_KEY,
  );

  if (!joyrideSkips) {
    return false;
  }

  try {
    const joyridesObject = JSON.parse(joyrideSkips);
    return joyridesObject[joyrideId] >= maxSkips;
  } catch (e) {
    return false;
  }
};

export const markJoyrideAsSeen = joyrideId => {
  let currentState = LocalStorageWrapper.getItem(
    constants.JOYRIDE_LOCAL_STORAGE_KEY,
  );

  if (!currentState) {
    currentState = {
      [joyrideId]: Date.now(),
    };
  } else {
    currentState = JSON.parse(currentState);
    currentState[joyrideId] = Date.now();
  }
  LocalStorageWrapper.setItem(
    constants.JOYRIDE_LOCAL_STORAGE_KEY,
    JSON.stringify(currentState),
  );
};

export const markJoyrideAsSkipped = joyrideId => {
  let currentState = LocalStorageWrapper.getItem(
    constants.SKIP_JOYRIDE_LOCAL_STORAGE_KEY,
  );

  if (!currentState) {
    currentState = {
      [joyrideId]: 1,
    };
  } else {
    currentState = JSON.parse(currentState);
    currentState[joyrideId] = parseInt(currentState[joyrideId], 10) + 1;
  }
  LocalStorageWrapper.setItem(
    constants.SKIP_JOYRIDE_LOCAL_STORAGE_KEY,
    JSON.stringify(currentState),
  );
};

export default {
  hasJoyrideBeenSeen,
  hasJoyrideExceededSkips,
  markJoyrideAsSeen,
  markJoyrideAsSkipped,
};

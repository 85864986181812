import _ from 'lodash';
import { Label } from 'optimizely-oui';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

/**
 * Simple component for rendering a select field and validation errors.
 * @param {Object} props - Properties passed to component
 * @returns {ReactElement}
 */
function ValidatedSelectField({
  error,
  isHidden,
  label,
  name,
  onChange,
  options,
  testSection,
  value,
}) {
  // Create change handler
  const changeHandler = e => {
    onChange({ [name]: e.target.value });
  };

  // Conditionally render error message
  const errorElement = !!error && (
    <div
      className="lego-form-note lego-form-note--bad-news push--bottom"
      data-test-section="validation-error">
      {error}
    </div>
  );

  // Render dropdown options
  const optionElements = options.map(option => {
    let optionText;
    let optionValue;
    if (_.isPlainObject(option)) {
      optionText = option.displayText;
      optionValue = option.value;
    } else {
      optionText = option;
      optionValue = option;
    }

    return (
      <option key={`${name}-${optionValue}`} value={optionValue}>
        {optionText}
      </option>
    );
  });

  // Dynamic classes
  const classes = classNames({
    'display--none': isHidden,
  });

  // Return field and error
  return (
    <div
      className={classes}
      data-ui-component={true}
      data-test-section="validated-select-field">
      <Label>{label}</Label>
      <select
        className="lego-select width--1-1"
        value={value}
        data-test-section={testSection}
        onChange={changeHandler}>
        {optionElements}
      </select>
      {errorElement}
    </div>
  );
}

ValidatedSelectField.propTypes = {
  /** Sets validation error text for the field */
  error: PropTypes.string,
  /** Determines if the select field is hiden */
  isHidden: PropTypes.bool,
  /** Sets the label text above the field */
  label: PropTypes.string,
  /** Sets name for the field */
  name: PropTypes.string.isRequired,
  /** Sets event handler for user input events */
  onChange: PropTypes.func,
  /** Sets dropdown choices for select input */
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        displayText: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  ]).isRequired,
  /** Sets test section for the field */
  testSection: PropTypes.string,
  /** Sets value of the field */
  value: PropTypes.string,
};

ValidatedSelectField.defaultProps = {
  error: null,
  isHidden: false,
  label: null,
  onChange: () => {},
  testSection: null,
  value: '',
};

export default ValidatedSelectField;

import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'optimizely-oui';

const AdvancedFeaturesDeactivationTitle = ({ getHelpLinkProps }) => {
  const helpLinkProps = getHelpLinkProps();

  if (helpLinkProps && helpLinkProps.linkURL && helpLinkProps.linkText) {
    return (
      <div className="micro">
        <Link testSection="sfdw-help-text" href={helpLinkProps.linkURL}>
          {helpLinkProps.linkText}
        </Link>
      </div>
    );
  }

  return null;
};

AdvancedFeaturesDeactivationTitle.propTypes = {
  getHelpLinkProps: PropTypes.func,
};

AdvancedFeaturesDeactivationTitle.defaultProps = {
  getHelpLinkProps: () => {},
};

export default AdvancedFeaturesDeactivationTitle;

import React from 'react';
import PropTypes from 'prop-types';

import { Attention, Icon } from 'optimizely-oui';

import { fns as LayerExperimentFns } from 'optly/modules/entity/layer_experiment';
import Immutable from 'optly/immutable';

const getFormattedDate = date => tr.date.utc(date).format('lll');

class SchedulerInfo extends React.Component {
  determineIfStartScheduled = experiment => {
    if (experiment.getIn(['schedule', 'timezone_name'])) {
      return experiment.getIn(['schedule', 'start_time']);
    }
    return LayerExperimentFns.hasFutureStartScheduled(experiment);
  };

  determineIfStopScheduled = experiment => {
    if (experiment.getIn(['schedule', 'timezone_name'])) {
      return experiment.getIn(['schedule', 'stop_time']);
    }
    return LayerExperimentFns.hasFutureStopScheduled(experiment);
  };

  renderContents = (hasStartSchedule, hasStopSchedule) => {
    const {
      experiment,
      hasUpdatesToPublish,
      isSingleExperimentLayer,
    } = this.props;

    let experimentTimezone;
    if (hasStartSchedule || hasStopSchedule) {
      experimentTimezone = experiment.getIn(['schedule', 'time_zone']);
      if (experiment.getIn(['schedule', 'timezone_name'])) {
        experimentTimezone = experiment
          .getIn(['schedule', 'timezone_name'])
          .replace('_', ' ');
      }
    }

    const experimentTerm = isSingleExperimentLayer
      ? 'experiment'
      : 'experience';

    return (
      <React.Fragment>
        {hasStartSchedule && (
          <div
            className="micro muted push--ends"
            data-test-section="campaign-overview-sidebar-campaign-start">
            Scheduled to start on{' '}
            {getFormattedDate(experiment.get('schedule').get('start_time'))} (
            {experimentTimezone})
          </div>
        )}
        {hasStopSchedule && (
          <div
            className="micro muted push--ends"
            data-test-section="campaign-overview-sidebar-campaign-stop">
            Scheduled to stop on{' '}
            {getFormattedDate(experiment.get('schedule').get('stop_time'))} (
            {experimentTimezone})
          </div>
        )}
        {hasStartSchedule && hasUpdatesToPublish && (
          <Attention
            type="warning"
            alignment="left"
            testSection="campaign-overview-sidebar-draft-changes-with-schedule-warning">
            <div className="flex">
              <div className="push--right color--brand">
                <Icon name="circle-exclamation" size="small" />
              </div>
              <div className="micro flex--1">
                Publish your {experimentTerm} if you want your draft changes to
                go live on the scheduled date.
              </div>
            </div>
          </Attention>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { isSingleExperimentLayer, experiment } = this.props;

    const hasStartSchedule = this.determineIfStartScheduled(experiment);
    const hasStopSchedule = this.determineIfStopScheduled(experiment);

    if (!hasStartSchedule && !hasStopSchedule) {
      return null;
    }

    if (isSingleExperimentLayer) {
      return this.renderContents(hasStartSchedule, hasStopSchedule);
    }

    return (
      <div
        className="push-quad--right width--250"
        data-test-section="experience-schedule">
        <h6 className="muted">Schedule</h6>
        {this.renderContents(hasStartSchedule, hasStopSchedule)}
      </div>
    );
  }
}

SchedulerInfo.propTypes = {
  experiment: PropTypes.instanceOf(Immutable.Map).isRequired,
  hasUpdatesToPublish: PropTypes.bool.isRequired,
  isSingleExperimentLayer: PropTypes.bool.isRequired,
};

export default SchedulerInfo;

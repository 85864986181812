import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { enums as LayerExperimentEnums } from 'optly/modules/entity/layer_experiment';
import { fns as PermissionsModuleFns } from 'optly/modules/permissions';
import LayerFns from 'optly/modules/entity/layer/fns';

export const activeChangeCount = [
  CurrentLayerGetters.currentCampaignLiveChangesByExperimentStatus(
    LayerExperimentEnums.status.ACTIVE,
  ),
  changes => changes.size,
];

export const draftChangeCount = [
  CurrentLayerGetters.draftChangesFromAllExperimentsOrSections,
  changes => changes.size,
];

export const canPublishLayer = [
  CurrentProjectGetters.project,
  PermissionsModuleFns.canPublishLayer.bind(PermissionsModuleFns),
];

export const disableStartButton = [
  CurrentProjectGetters.isUsingV2Snippet,
  canPublishLayer,
  CurrentLayerGetters.layer,
  (isUsingV2Snippet, canPublishLayer, currentLayer) =>
    !isUsingV2Snippet ||
    !canPublishLayer ||
    (currentLayer && currentLayer.get('archived')) ||
    (currentLayer && LayerFns.hasLayerConcluded(currentLayer)),
];

export default {
  activeChangeCount,
  draftChangeCount,
  canPublishLayer,
  disableStartButton,
};

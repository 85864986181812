import { Input } from 'optimizely-oui';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Simple component for rendering a text field and validation errors.
 * @param {Object} props - Properties passed to component
 * @returns {ReactElement}
 */
function ValidatedTextField({
  castToType,
  error,
  isOptional,
  label,
  name,
  onChange,
  placeholder,
  testSection,
  value,
  disabled,
}) {
  // Create change handler
  const changeHandler = e => {
    // Apply typecast if necessary
    let formattedValue = e.target.value;
    if (castToType === Number) {
      // Nullify value to prevent null numeric conversion quirks
      if (formattedValue.length > 0) {
        formattedValue = Number(formattedValue);
      } else {
        formattedValue = null;
      }
    } else if (castToType) {
      formattedValue = castToType(formattedValue);
    }

    // Emit event with formatted value
    onChange({ [name]: formattedValue });
  };

  // Conditionally render error message
  const errorElement = !!error && (
    <div
      className="lego-form-note lego-form-note--bad-news push--bottom"
      data-test-section="validation-error">
      {error}
    </div>
  );

  // Return field and error
  return (
    <div data-ui-component={true} data-test-section="validated-text-field">
      <Input
        value={value}
        isRequired={!isOptional}
        label={label}
        onChange={changeHandler}
        placeholder={placeholder}
        testSection={testSection}
        type="text"
        disabled={disabled}
      />
      {errorElement}
    </div>
  );
}

ValidatedTextField.propTypes = {
  /** Sets the typecast for this field */
  castToType: PropTypes.oneOf([Number, String]),
  /** Sets validation error text for the field */
  error: PropTypes.string,
  /** Sets whether the field is optional or required */
  isOptional: PropTypes.bool,
  /** Sets the label text above the field */
  label: PropTypes.string,
  /** Sets name for the field */
  name: PropTypes.string.isRequired,
  /** Sets event handler for user input events */
  onChange: PropTypes.func,
  /** Sets the placeholdter text in blank inputs */
  placeholder: PropTypes.string,
  /** Sets test section for the field */
  testSection: PropTypes.string,
  /** Sets value of the field */
  value: PropTypes.string,
  /** Sets disabled mode for the field */
  disabled: PropTypes.bool,
};

ValidatedTextField.defaultProps = {
  castToType: null,
  error: null,
  isOptional: false,
  label: null,
  onChange: () => {},
  placeholder: null,
  testSection: null,
  value: '',
  disabled: false,
};

export default ValidatedTextField;

/**
 * Enumerable field values for Experiment
 */
export const status_type = {
  ARCHIVED: 'Archived',
  DRAFT: 'Draft',
  PAUSED: 'Paused',
  RUNNING: 'Running',
};

export const goal_status_type = {
  BASELINE: 'baseline',
  INCONCLUSIVE: 'inconclusive',
  LOSER: 'loser',
  WINNER: 'winner',
};

// These values are used for localization extraction purposes. Do not delete.

export const goal_status_type_text = {
  BASELINE: tr('baseline'),
  INCONCLUSIVE: tr('inconclusive'),
  LOSER: tr('loser'),
  WINNER: tr('winner'),
};

export default {
  status_type,
  goal_status_type,
  goal_status_type_text,
};

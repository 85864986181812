/**
 * Third Party Condition Range Field Component
 * Responsible for rendering the configuration UI for a third-party range condition
 */
const _ = require('lodash');

const baseComponent = require('./base');
const tmpl = require('./range.html');

/**
 * Get condition value for the `range` input type based on values from `from` and `to` input elements.
 *
 * @param from the beginning value of the range
 * @param to the ending value of the range
 * @returns {string}
 * @private
 */
const _getRangeConditionValue = function(from, to) {
  if (!(from && to)) {
    return '';
  }

  return `${from}:${to}`;
};

/**
 * Get the upper and lower limits from a range field's condition value.
 *
 * @param conditionValue value of a condition using the `range` input type
 * @returns {*}
 * @private
 */
const _getRangeLimitsFromConditionValue = function(conditionValue) {
  if (!conditionValue) {
    return {};
  }

  const limits = conditionValue.split(':');

  return {
    from: limits[0],
    to: limits[1],
  };
};

module.exports = _.extend({}, baseComponent, {
  template: tmpl,

  data: {
    config: {},
    rangeFrom: '',
    rangeTo: '',
  },

  computed: {
    hasError() {
      return !this.validateInputValues();
    },

    rangeFromWrapper: {
      $get() {
        return this.rangeFrom;
      },
      $set(value) {
        this.rangeFrom = value;
        this.condition.value = _getRangeConditionValue(
          this.rangeFrom,
          this.rangeTo,
        );
      },
    },

    rangeToWrapper: {
      $get() {
        return this.rangeTo;
      },
      $set(value) {
        this.rangeTo = value;
        this.condition.value = _getRangeConditionValue(
          this.rangeFrom,
          this.rangeTo,
        );
      },
    },
  },

  methods: {
    validateInputValues() {
      if (_.isEmpty(this.rangeFrom) && _.isEmpty(this.rangeTo)) {
        // no need to show the error message when nothing is supplied
        return true;
      }

      const rangeFrom = parseFloat(this.rangeFrom);
      const rangeTo = parseFloat(this.rangeTo);

      // Range input values are considered valid iff all of the following conditions are met:
      // * rangeFrom is a number.
      // * rangeTo is a number.
      // * rangeFrom is less than rangeTo.
      return !isNaN(rangeFrom) && !isNaN(rangeTo) && rangeFrom < rangeTo;
    },
  },

  ready() {
    baseComponent.ready.call(this);

    // Set initial value for condition
    const limits = _getRangeLimitsFromConditionValue(this.condition.value);
    this.rangeFromWrapper = limits.from;
    this.rangeToWrapper = limits.to;
    this.condition.match_type = this.config.match_type.values[0].value;

    this.$on('validateConditions', () => {
      this.$dispatch('validateConditionsResponse', {
        valid: this.validateInputValues(),
        // no need to supply a message here since the range component has validation in UI
      });
    });
  },
});

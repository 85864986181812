import React, { useEffect, useMemo, useState } from 'react';
import { isMatch, omit, sortBy } from 'lodash';
import { useFeature } from '@optimizely/react-sdk';

import {
  Button,
  Code,
  Input,
  Label,
  Link,
  Sheet,
  Spinner,
  Tile,
} from '@optimizely/axiom';

import { useGetters } from 'core/ui/methods/connect_getters';

import { Form, handleFormValidationError } from 'react_components/form';
import Immutable, { toImmutable, toJS } from 'optly/immutable';

import EventActions from 'optly/modules/entity/event/actions';
import EventGetters from 'optly/modules/entity/event/getters';
import ViewGetters from 'optly/modules/entity/view/getters';

import CodeSamplePicker from 'bundles/p13n/components/code_sample_picker';

import {
  sdkSyntaxLanguage,
  sdkLanguages,
} from 'optly/modules/entity/project/enums';
import SegmentTracking from 'optly/modules/segment';

import { EventPropertiesFilter } from 'bundles/p13n/components/metrics/metrics_modal/event_properties';

import {
  formatConditions,
  getEventPropertiesPayload,
  validateConditions,
} from 'bundles/p13n/components/metrics/metrics_modal/utils';

import {
  MetricsModal,
  MFEMetricsModalWrapper,
} from 'bundles/p13n/components/metrics/metrics_modal';

import ComponentModuleActions from '../../component_module/actions';
import {
  getCodeBlock,
  getMetricForm,
  getRatioMetricForm,
  getScopeOptions,
} from '../../component_module/fns';
import { DefaultMetrics } from '../../component_module/constants';
import {
  Metric,
  SheetMetricTypeEnum,
  MetricFieldEnum,
  EventTypeEnum,
} from '../../component_module/types';

import MetricMeasureConfig from './MetricMeasureConfig';
import { scope as SCOPE_VALUES } from 'optly/modules/entity/metric/constants';

export interface EditMetricSheetCoreProps {
  currentProjectId: number;
  /** Controls whether the form says "Add" or "Update" */
  isNewMetric?: boolean;
  metric: Metric;
  selectedMetrics: Metric[];
}

export interface EditMetricSheetProps extends EditMetricSheetCoreProps {
  event: Immutable.Map<string, any>;
  /** @access form-hoc - Manages form state and validation */
  form: any;
  isLoading: boolean;
  metricDetailsMap: {
    eventName: string;
    description: string;
    id: number;
    readableApiName: string;
  };
  metricType: SheetMetricTypeEnum;
  onCancel: () => void;
  onSave: (updatedMetric: Metric) => void;
}

const DEFAULT_ADVANCED_METRIC_HREF =
  'https://docs.developers.optimizely.com/full-stack/docs/choose-metrics';
const DEFAULT_EDIT_METRIC_HREF =
  'https://support.optimizely.com/hc/en-us/articles/4410289407885-Overview-of-metrics';
const DEFAULT_METRIC_TAGS_HREF =
  'https://docs.developers.optimizely.com/full-stack/docs/include-event-tags';
const UNIQUE_METRIC_ERROR_MESSAGE =
  'A metric like this already exists. Please choose a unique configuration.';

const EditMetricSheet = ({
  currentProjectId,
  event,
  form,
  isLoading,
  isNewMetric = false,
  metricType,
  selectedMetrics,
  onCancel,
  onSave,
}: EditMetricSheetProps) => {
  const {
    full_stack_advanced_metric_link,
    full_stack_edit_metric_link,
    full_stack_metric_tags_link,
  } = useFeature('help_copy')[1] || {};

  const [isSaving, setIsSaving] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [isEventPropertiesFeatureEnabled] = useFeature('event_properties');

  const aggregatorFormField = form.field('aggregator');
  const displayTitleFormField = form.field('display_title');
  const fieldFormField = form.field('field');
  const scopeFormField = form.field('scope');
  const winningDirectionFormField = form.field('winning_direction');
  const eventFormField = form.field('event');
  const conditionsFormField = form.field('conditions');
  const combineOperatorFormField = form.field('combineOperator');
  const filterByPropertiesFormField = form.field('filterByProperties');

  const modalForm = useMemo(
    () => ({
      event: toJS(eventFormField.getValue()),
      conditions: toJS(conditionsFormField.getValue()),
      combineOperator: toJS(combineOperatorFormField.getValue()),
      filterByProperties: toJS(filterByPropertiesFormField.getValue()),
    }),
    [
      eventFormField,
      conditionsFormField,
      combineOperatorFormField,
      filterByPropertiesFormField,
    ],
  );

  const handleModalFormChange = (key: string, value: object) => {
    const fieldMap: any = {
      event: eventFormField,
      conditions: conditionsFormField,
      combineOperator: combineOperatorFormField,
      filterByProperties: filterByPropertiesFormField,
    };

    const selected = fieldMap[key];
    if (selected) {
      selected.setValue(value);
    }
  };

  let metricDetailsMap = {
    eventName: '',
    description: '',
    id: NaN,
    readableApiName: '',
  };

  switch (metricType) {
    case SheetMetricTypeEnum.Custom:
      metricDetailsMap = {
        eventName: event.get('api_name'),
        description: `ID: ${event.get('id')}`,
        id: event.get('id'),
        readableApiName: event.get('api_name'),
      };
      break;
    case SheetMetricTypeEnum.Revenue:
      metricDetailsMap = {
        eventName: DefaultMetrics.OVERALL_REVENUE.display_title,
        description: 'Global Metric',
        id: NaN,
        readableApiName: 'revenue',
      };
      break;
  }

  useEffect(() => {
    form.validators(
      {
        isUniquelyConfiguredMetric: (metricFromForm: any) => {
          const omittedFields = [
            'display_title',
            'conditions',
            'combineOperator',
            'event',
            'filterByProperties',
          ];
          const originalMetric = toJS(metricFromForm);
          const newMetric = omit(originalMetric, omittedFields);

          const matchingMetrics = selectedMetrics.filter(existingMetric => {
            let isDuplicate = isMatch(
              omit(existingMetric, ['display_title', 'event_properties']),
              newMetric,
            );

            if (originalMetric.filterByProperties) {
              const eventProperties = existingMetric.event_properties;
              const conditions = Object.values(originalMetric.conditions).map(
                (condition: any) => {
                  const cleanCondition = {
                    ...condition,
                  };
                  delete cleanCondition.id;
                  return cleanCondition;
                },
              );
              isDuplicate =
                isDuplicate &&
                originalMetric.combineOperator ===
                  eventProperties?.filter.combine_operator &&
                isMatch(
                  sortBy(conditions, (c: any) => c?.name),
                  sortBy(
                    eventProperties?.filter.conditions,
                    (c: any) => c?.name,
                  ),
                );
            }

            return isDuplicate;
          });

          if (
            (isNewMetric && matchingMetrics.length === 1) ||
            (!isNewMetric && matchingMetrics.length > 1)
          ) {
            return UNIQUE_METRIC_ERROR_MESSAGE;
          }
        },
        hasAllConditionsFilled: (metricFromForm: any) =>
          validateConditions(toJS(metricFromForm.get('conditions'))),
      },
      {
        // TODO [FEI-3521] submitCount is used as workaround for form.validators() not respecting form.setOptions({ validateOnChange: true })
        validateOnChange: !!submitCount,
      },
    );
  }, [submitCount]);

  const eventTagsHelpLink = (
    <>
      For more info on sending a metric with revenue or value tags,{' '}
      <Link
        href={String(full_stack_metric_tags_link) || DEFAULT_METRIC_TAGS_HREF}
        newWindow={true}>
        check out our developer documentation
      </Link>
      .
    </>
  );

  const shouldHideCodeSamplePicker =
    metricType === SheetMetricTypeEnum.Revenue || fieldFormField.getValue();

  return (
    <Sheet
      footerButtonList={[
        <Button
          key="cancel"
          style="plain"
          onClick={onCancel}
          testSection="edit-metric-modal-cancel-button">
          Cancel
        </Button>,
        <Button
          isDisabled={!isNewMetric && !form.isFormDirty()}
          key="submit"
          style="highlight"
          onClick={() => {
            setIsSaving(true);
            setSubmitCount(count => count + 1);
            form
              .validate()
              .then((formValues: any) => {
                const jsValues = toJS(formValues);
                const {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  event: _,
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  filterByProperties,
                  conditions,
                  combineOperator,
                  ...values
                } = jsValues;

                const event_properties = filterByProperties
                  ? getEventPropertiesPayload(combineOperator, conditions)
                  : null;

                const updatedValues = { event_properties, ...values };
                const updatedValuesWithoutNullValues = Object.entries(
                  updatedValues,
                ).reduce(
                  (acc, [key, value]) =>
                    value === null ? { ...acc } : { ...acc, [key]: value },
                  {},
                ) as Metric;
                const hasValidConditions = Object.values(conditions).some(
                  (condition: any) =>
                    condition.value && condition.name && condition.operator,
                );
                if (filterByProperties && hasValidConditions) {
                  SegmentTracking.tracking.trackEvent(
                    'Metric Created Using Filtering on Event Properties',
                    {
                      metricName: updatedValuesWithoutNullValues.display_title,
                      eventName: eventFormField.getValue().toJS().name,
                      numberOfConditions: Object.keys(conditions).length,
                    },
                  );
                }
                onSave(updatedValuesWithoutNullValues);
              })
              .catch(handleFormValidationError)
              .finally(() => setIsSaving(false));
          }}
          isLoading={isSaving}
          testSection="edit-metric-modal-save-button">
          {isNewMetric ? 'Add Metric' : 'Done'}
        </Button>,
      ]}
      onClose={onCancel}
      title={`${isNewMetric ? 'Add' : 'Edit'} Metric`}
      subtitle={
        <span>
          Custom tracking events allow you to capture and report on visitor
          actions or events.{' '}
          <Link
            href={
              String(full_stack_edit_metric_link) || DEFAULT_EDIT_METRIC_HREF // eslint-disable-line camelcase
            }
            newWindow={true}>
            Learn more
          </Link>
        </span>
      }>
      {isLoading ? (
        <div className="height--300">
          <Spinner hasOverlay={true} />
        </div>
      ) : (
        <>
          <div className="push-double--bottom">
            <Label>Event</Label>
            <Tile
              description={metricDetailsMap.description}
              name={metricDetailsMap.eventName || 'Loading...'}
              testSection={`event-tile-${metricDetailsMap.readableApiName}`}
              {...(metricDetailsMap.id && {
                onEdit: () => {
                  ComponentModuleActions.showCreateOrEditEventSheet({
                    currentProjectId,
                    id: metricDetailsMap.id,
                  });
                },
              })}
            />
          </div>
          <div className="push-double--bottom">
            <Input
              displayError={displayTitleFormField.getErrors().hasError}
              id="metric-name"
              label="Name"
              note={displayTitleFormField.getErrors().message}
              onChange={({ target }) =>
                displayTitleFormField.setValue(target.value)
              }
              placeholder={metricDetailsMap.eventName}
              testSection="metric-display-title"
              type="text"
              value={displayTitleFormField.getValue()}
            />
          </div>
          <div className="push-double--bottom">
            <MetricMeasureConfig
              advancedMetricLink={
                String(full_stack_advanced_metric_link) ||
                DEFAULT_ADVANCED_METRIC_HREF // eslint-disable-line camelcase
              }
              aggregatorFormField={aggregatorFormField}
              displayError={
                !!form.getErrors().details.isUniquelyConfiguredMetric
              }
              fieldFormField={fieldFormField}
              metricType={metricType}
              note={form.getErrors().details.isUniquelyConfiguredMetric}
              scopeFormField={scopeFormField}
              winningDirectionFormField={winningDirectionFormField}
            />
          </div>
          {isEventPropertiesFeatureEnabled && (
            <EventPropertiesFilter
              formValues={modalForm}
              error={form.getErrors().details.hasAllConditionsFilled}
              onChange={handleModalFormChange}
            />
          )}
          <fieldset>
            {shouldHideCodeSamplePicker ? (
              eventTagsHelpLink
            ) : (
              <CodeSamplePicker
                description={
                  <span>
                    Choose your preferred SDK to view sample code. Copy and
                    paste the code to track the event in your application.{' '}
                    {eventTagsHelpLink}
                  </span>
                }
                getCodeSample={(codeSampleLanguage: string) => {
                  const language = codeSampleLanguage || sdkLanguages.PYTHON;
                  return (
                    <Code
                      isHighlighted={true}
                      hasCopyButton={true}
                      language={sdkSyntaxLanguage[language] || language}
                      type="block"
                      testSection="metric-code-sample">
                      {getCodeBlock(language, metricDetailsMap.readableApiName)}
                    </Code>
                  );
                }}
                showMobileOnly={false}
                title="Tracking Code"
              />
            )}
          </fieldset>
        </>
      )}
    </Sheet>
  );
};

const EditMetricSheetWithForm = Form({
  mapPropsToFormData: ({ event, metric }: { event: any; metric: Metric }) => {
    const {
      aggregator,
      display_title,
      event_id,
      event_type,
      field,
      scope,
      winning_direction,
      event_properties = {},
    } = metric;
    const { conditions, combineOperator } = formatConditions(event_properties);
    return toImmutable({
      aggregator,
      display_title,
      event_id,
      event_type,
      field,
      scope,
      winning_direction,
      event,
      conditions,
      combineOperator,
      filterByProperties: !!Object.values(conditions).length,
    });
  },
  debounceTimeout: __TEST__ ? 0 : 300,
  validateOnChange: false,
})(EditMetricSheet);

/* This renders the edit metric sheet */
const EditMetricSheetWithData = (props: EditMetricSheetCoreProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { metric } = props;
  const eventId = metric.event_id;
  const { event } = useGetters({ event: EventGetters.byId(eventId) }, [
    eventId,
  ]) as { event: Immutable.Map<string, any> };

  let metricType: string;
  if (
    typeof metric.event_type === 'undefined' &&
    metric.field === MetricFieldEnum.Revenue
  ) {
    metricType = SheetMetricTypeEnum.Revenue;
  } else {
    metricType = SheetMetricTypeEnum.Custom;
  }

  useEffect(() => {
    if (!event && metricType === SheetMetricTypeEnum.Custom) {
      // Use isLoading state to defer loading until after fetch entity cache update which avoids flux simultaneous dispatch error
      setIsLoading(true);
      EventActions.fetch(eventId).always(() => setIsLoading(false));
    }
  }, [eventId]);

  return (
    <EditMetricSheetWithForm
      {...props}
      event={event || Immutable.Map()}
      isLoading={isLoading && metricType === SheetMetricTypeEnum.Custom}
      metricType={metricType}
    />
  );
};

function getEventData(eventType: string | null, eventId: number | null) {
  const getter =
    eventType === EventTypeEnum.Pageview ? ViewGetters.byId : EventGetters.byId;
  const { event } = useGetters({ event: getter(eventId) }, [eventId]) as {
    event: Immutable.Map<string, any>;
  };

  if (eventType === EventTypeEnum.Pageview) {
    return event.set('event_type', 'pageview');
  }

  return event;
}

export function EditMetricModalWithData(props: EditMetricSheetProps) {
  const { metric, isNewMetric, onCancel, onSave } = props;
  const [isMetricsHubEnabled] = useFeature('metrics_hub');

  const MetricsModalComponent = useMemo(
    () => (isMetricsHubEnabled ? MFEMetricsModalWrapper : MetricsModal),
    [isMetricsHubEnabled],
  );

  let metricFunction;
  if (!isNewMetric && metric) {
    if (metric.metrics) {
      const [numerator, denominator] = metric.metrics;

      const numeratorEvent = getEventData(
        numerator.event_type,
        numerator.event_id,
      );
      const denominatorEvent = getEventData(
        denominator.event_type,
        denominator.event_id,
      );

      metricFunction = () =>
        getRatioMetricForm(metric, numeratorEvent, denominatorEvent);
    } else {
      const event = getEventData(metric?.event_type, metric?.event_id);
      metricFunction = () => getMetricForm(metric, event);
    }
  }

  const title = isNewMetric ? 'Add Metric' : 'Edit Metric';

  return (
    <MetricsModalComponent
      getMetricFormToEdit={metricFunction}
      getScopeOptions={getScopeOptions}
      initialScopeValue={metric?.scope || SCOPE_VALUES.VISITOR}
      onClose={onCancel}
      onSave={onSave}
      title={title}
    />
  );
}

export default EditMetricSheetWithData;

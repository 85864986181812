import PropTypes from 'prop-types';
import React from 'react';
import { withTrack } from '@optimizely/segment-js/dist/decorators';
import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';
import { Button, Dropdown, Icon } from 'optimizely-oui';

import ui from 'core/ui';
import { connect } from 'core/ui/decorators';

import { actions as CurrentLayerActions } from 'bundles/p13n/modules/current_layer';
import LayerEnums from 'optly/modules/entity/layer/enums';
import { actions as P13NUIActions } from 'bundles/p13n/modules/ui';
import { getters as PermissionsModuleGetters } from 'optly/modules/permissions';
import { Features } from 'optly/utils/enums';

// MVT test only, remove after test is done
import { actions as PageModuleActions } from '../../page_module';

@connect({
  canCreateExperiment: PermissionsModuleGetters.canCreateExperiment,
  canCreateLayer: PermissionsModuleGetters.canCreateLayer,
  canMultivariateTestInX: PermissionsModuleGetters.canMultivariateTestInX,
  canUseABTestingV2: PermissionsModuleGetters.canUseABTestingV2,
  canUseMultiArmedBandits: PermissionsModuleGetters.canUseMultiArmedBandits,
  canUsePersonalization: PermissionsModuleGetters.canUsePersonalization,
  shouldUpsellPersonalization:
    PermissionsModuleGetters.shouldUpsellPersonalization,
})
@withTrack
class CreateDropdown extends React.Component {
  static propTypes = {
    addDataTrackId: PropTypes.bool,
    allowedLayerTypes: PropTypes.arrayOf(PropTypes.string),
    canCreateExperiment: PropTypes.bool.isRequired,
    canCreateLayer: PropTypes.bool.isRequired,
    canMultivariateTestInX: PropTypes.bool.isRequired,
    canUseABTestingV2: PropTypes.bool.isRequired,
    canUseMultiArmedBandits: PropTypes.bool.isRequired,
    canUsePersonalization: PropTypes.bool.isRequired,
    direction: PropTypes.oneOf(['left', 'right']),
    shouldUpsellPersonalization: PropTypes.bool.isRequired,
    track: PropTypes.func,
  };

  static defaultProps = {
    addDataTrackId: false,
    allowedLayerTypes: [],
    direction: 'left',
    track: () => {},
  };

  onCreateExperimentClick = () => {
    const { canUseABTestingV2, track } = this.props;

    CurrentLayerActions.setCurrentLayerId(null);
    if (canUseABTestingV2) {
      const def = PageModuleActions.showABExperimentCreationDialogReact();
      ui.loadingWhen('main', def);
    } else {
      ui.showUpsell({
        dataTestSection: 'p13n-upsell-dialog',
      });
    }

    track('Experiment Builder Viewed', { experimentType: LayerEnums.type.AB });
  };

  onCreateMVTClick = () => {
    const { canMultivariateTestInX, track } = this.props;

    CurrentLayerActions.setCurrentLayerId(null);
    if (canMultivariateTestInX) {
      const def = PageModuleActions.showMVTExperimentCreationDialogReact();
      ui.loadingWhen('main', def);
    }

    track('Experiment Builder Viewed', {
      experimentType: LayerEnums.type.MULTIVARIATE,
    });
  };

  onCreateCampaignClick = () => {
    const { canUsePersonalization, track } = this.props;

    CurrentLayerActions.setCurrentLayerId(null);
    if (canUsePersonalization) {
      const def = PageModuleActions.showLayerDetailDialogReact();
      ui.loadingWhen('main', def);
    } else {
      ui.showUpsell({
        dataTestSection: 'p13n-upsell-dialog',
      });
    }

    track('Experiment Builder Viewed', {
      experimentType: LayerEnums.type.PERSONALIZATION,
    });
  };

  onCreateMABClick = () => {
    const { canUseMultiArmedBandits, track } = this.props;

    CurrentLayerActions.setCurrentLayerId(null);
    if (canUseMultiArmedBandits) {
      if (ui.hasDialogBeenShown('MultiArmedBanditNotification')) {
        const def = PageModuleActions.showMABCreationDialog();
        ui.loadingWhen('main', def);
      } else {
        P13NUIActions.showMABNotificationDialog(
          PageModuleActions.showMABCreationDialog,
        );
      }
    } else {
      ui.showUpsell({
        dataTestSection: 'mab-upsell-dialog',
      });
    }

    track('Experiment Builder Viewed', {
      experimentType: LayerEnums.type.MULTIARMED_BANDIT,
    });
  };

  render() {
    const {
      addDataTrackId,
      allowedLayerTypes,
      canCreateExperiment,
      canCreateLayer,
      canMultivariateTestInX,
      canUseMultiArmedBandits,
      canUsePersonalization,
      direction,
      shouldUpsellPersonalization,
      track,
    } = this.props;

    const buttonDetails = {
      [LayerEnums.type.AB]: {
        shouldDisplay:
          !isFeatureEnabled(Features.M1_P13N) ||
          allowedLayerTypes?.includes(LayerEnums.type.AB),
        displayName: 'A/B Test',
        subText:
          'Test multiple variations against each other to find the best experience.',
        icon: 'vials-thin',
        linkProps: {
          onClick: this.onCreateExperimentClick,
          testSection: 'layers-create-new-experiment',
        },
        shouldLock: !canCreateExperiment,
      },

      [LayerEnums.type.MULTIVARIATE]: {
        shouldDisplay:
          (!isFeatureEnabled(Features.M1_P13N) ||
            allowedLayerTypes?.includes(LayerEnums.type.MULTIVARIATE)) &&
          canMultivariateTestInX,
        displayName: 'Multivariate Test',
        subText: 'Test multiple sections of a page at once.',
        icon: 'ball-pile-thin',
        linkProps: {
          onClick: this.onCreateMVTClick,
          testSection: 'layers-create-new-mvt',
        },
        shouldLock: false,
      },
      [LayerEnums.type.PERSONALIZATION]: {
        shouldDisplay:
          (!isFeatureEnabled(Features.M1_P13N) ||
            allowedLayerTypes?.includes(LayerEnums.type.PERSONALIZATION)) &&
          shouldUpsellPersonalization,
        displayName: 'Personalization Campaign',
        subText:
          'Target multiple audiences with different personalized experiences.',
        icon: 'megaphone-thin',
        linkProps: {
          onClick: this.onCreateCampaignClick,
          testSection: 'layers-create-new-campaign',
        },
        shouldLock: !canUsePersonalization,
      },
      [LayerEnums.type.MULTIARMED_BANDIT]: {
        shouldDisplay:
          (!isFeatureEnabled(Features.M1_P13N) ||
            allowedLayerTypes?.includes(LayerEnums.type.MULTIARMED_BANDIT)) &&
          canUseMultiArmedBandits,
        displayName: 'Multi-Armed Bandit',
        subText:
          'Use machine learning to dynamically allocate traffic to the best-performing variation.',
        icon: 'user-robot-thin',
        linkProps: {
          onClick: this.onCreateMABClick,
          testSection: 'layers-create-new-mab',
        },
        shouldLock: false,
      },
    };

    function dropdownListItem(props) {
      return (
        <>
          {props.shouldDisplay && (
            <Dropdown.ListItem>
              <Dropdown.BlockLink {...props.linkProps}>
                <div className="flex flex-align--center">
                  <div className="flex-shrink--none push--left push-double--right flex--dead-center">
                    <Icon
                      color={brandBlueDark}
                      name={props.icon}
                      size="medium"
                    />
                  </div>
                  <div>
                    <div className="lego-dropdown--descriptive__header">
                      {props.displayName}
                    </div>
                    <div className="lego-dropdown--descriptive__content">
                      {props.subText}
                    </div>
                  </div>
                  {props.shouldLock && (
                    <div className="anchor--right push--sides flex--dead-center">
                      <Icon name="lock" size="small" />
                    </div>
                  )}
                </div>
              </Dropdown.BlockLink>
            </Dropdown.ListItem>
          )}
        </>
      );
    }

    const dropdownActivator = (
      <Button
        style="highlight"
        isDisabled={!canCreateLayer}
        onClick={() => {
          track('Create New Experiment Clicked');
        }}
        testSection="layers-create-new-dropdown">
        Create
        {isFeatureEnabled(Features.M1_P13N) && canUsePersonalization
          ? ''
          : ' New Experiment'}
        &hellip;
        <span className="lego-arrow-inline--down push-double--left" />
      </Button>
    );

    if (addDataTrackId) {
      buttonDetails[LayerEnums.type.AB].linkProps.trackId =
        '2.0-Campaign-Overview-CreateNew.single_experiment';
      buttonDetails[LayerEnums.type.PERSONALIZATION].linkProps.trackId =
        '2.0-Campaign-Overview-CreateNew.personalization';
    }

    if (isFeatureEnabled(Features.M1_P13N) && allowedLayerTypes.length === 1) {
      return (
        <Button
          ariaHasPopup={true}
          ariaLabel="Button"
          loadingText="Loading..."
          style="highlight"
          data-test-section="layers-create-button"
          {...buttonDetails[allowedLayerTypes[0]].linkProps}>
          {`Create ${buttonDetails[allowedLayerTypes[0]].displayName}`}
        </Button>
      );
    }

    return (
      <Dropdown activator={dropdownActivator}>
        <Dropdown.Contents
          canScroll={true}
          minWidth={300}
          direction={direction}>
          {[
            LayerEnums.type.AB,
            LayerEnums.type.MULTIVARIATE,
            LayerEnums.type.PERSONALIZATION,
            LayerEnums.type.MULTIARMED_BANDIT,
          ].map(type => dropdownListItem(buttonDetails[type]))}
        </Dropdown.Contents>
      </Dropdown>
    );
  }
}

export default CreateDropdown;

import $ from 'jquery';

import ui from 'core/ui';

import LayerExperimentHumanReadable from 'optly/modules/entity/layer_experiment/human_readable';
import { actions as EditorActions } from 'bundles/p13n/modules/editor';

import actions from '../actions';

const SidebarHeaderMixin = {
  methods: {
    /**
     * Save the changes made by the user in the currentChange store.
     * If the changes array is empty, delete the empty change. TODO: do we want to do this?
     */
    saveChange(variationId) {
      if (__DEV__) {
        console.debug(
          `[EDITOR_SIDEBAR] Saved change: ${this.currentlyEditingChange.id}`,
        ); // eslint-disable-line
      }
      if (this.currentlyEditingChangeIsDirty) {
        const saveDef = EditorActions.saveEditingChange(variationId).then(
          editingChange => {
            ui.showNotification({
              message: tr('Your changes have been saved.'),
              type: 'success',
            });
            return editingChange;
          },
        );
        ui.loadingWhen('change-editor-sidebar', saveDef);
        return saveDef;
      }
      return $.Deferred().resolve();
    },

    saveChangeAndShowChangeListSidebar(config) {
      return this.saveChange().then(() => this.showChangeListSidebar(config));
    },

    saveChangeAndShowChangeListSidebarAndRefreshCurrentIFrame(config) {
      return this.saveChangeAndShowChangeListSidebar(config).then(
        EditorActions.refreshCurrentIframe,
      );
    },

    /**
     * Go back to the change list sidebar
     */
    showChangeListSidebar(config) {
      actions.confirmNavigation(
        this.currentlyEditingChangeIsDirty,
        LayerExperimentHumanReadable.CHANGE,
        () => {
          EditorActions.undoDirtyChange();
          EditorActions.unsetCurrentlyEditingChange();
          actions.showChangeListSidebar(config);
        },
      );
    },

    /**
     * Go back to the change selector sidebar
     */
    showChangeSelectorSidebar() {
      actions.confirmNavigation(
        this.currentlyEditingChangeIsDirty,
        LayerExperimentHumanReadable.CHANGE,
        () => {
          EditorActions.undoDirtyChange();
          EditorActions.unsetCurrentlyEditingChange();
          actions.showChangeSelectorSidebar();
        },
      );
    },

    /**
     * Go back to the change selector sidebar
     */
    showElementChangeSidebar() {
      actions.showChangeEditorSidebar();
    },

    /**
     * Go back to the correct sidebar based on how this change was accessed (change list sidebar or change selector sidebar)
     * Typically used for the back button, so we want to confirm dirty changes here.
     */
    showCorrectSidebar(config) {
      if (this.parentSidebar === 'p13n-change_editor_sidebar') {
        this.showElementChangeSidebar();
        return;
      }
      if (this.isNewChange) {
        this.showChangeSelectorSidebar();
      } else {
        this.showChangeListSidebar(config);
      }
    },
  },
};

export default SidebarHeaderMixin;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import _ from 'lodash';

import flux from 'core/flux';

// TODO(FEI-3496) Fix connectGetters bug preventing dynamic props updates by using useGetters under the hood
/**
 * Decorate a React or function component to inject flux dataGetters as props
 * @param {ReactComponent|Function} Component
 * @param {Object} dataBindings
 * @return {Function}
 */
export default function connectGetters(Component, dataBindings) {
  class FluxConnector extends React.Component {
    static propTypes = {
      // Getters passed in an object like {propName:getter}
      connectGetters: PropTypes.object,
      forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    };

    static defaultProps = {
      connectGetters: {},
    };

    constructor(props, context) {
      super(props, context);
      this.unwatchByKey = this.unwatchByKey.bind(this);

      this.__unwatchFns = {};
      this.__unwatchFlux = () => {
        _.each(_.keys(this.__unwatchFns), this.unwatchByKey);
      };

      let dataBindingsToConnect = dataBindings;

      // @connect will pass a function so it can receive props
      // from the parent when instantiating its databindings.
      if (typeof dataBindings === 'function') {
        // Update the dataBindingsToConnect variable as to not mutate the dataBindings reference
        dataBindingsToConnect = dataBindings(props);
      }

      // Merge any data bindings passed via the connectGetters prop, where connectGetters
      // explicitly takes precedence as a runtime, not static, databinding set
      this.state = this.connectGetters({
        ...dataBindingsToConnect,
        ...props.connectGetters,
      });

      if (context && context.vueEvents) {
        // only try to bind to region unmount if the component is in a Vue context
        context.vueEvents.$on('unwatchFluxBindVueValues', this.__unwatchFlux);
      }
    }

    /**
     * Bind a mapping of getters to this component's state using flux.observe.
     * When this component's state is updated, the values will be threaded
     * through to the child component via props.
     * @param bindings - Map of propName to getter
     * @returns {Object} The initial state of the bindings.
     */
    connectGetters = bindings => {
      const initialState = {};
      _.each(bindings, (getter, key) => {
        // Ensure we unwatch the previous getter if one was bound with this key.
        this.unwatchByKey(key);

        initialState[key] = flux.evaluate(getter);

        this.__unwatchFns[key] = flux.observe(getter, val => {
          this.setState({
            [key]: val,
          });
        });
      });

      return initialState;
    };

    /**
     * Unwatch and delete a given unwatchFn by its key.
     * @param key
     */
    unwatchByKey(key) {
      if (this.__unwatchFns[key]) {
        this.__unwatchFns[key]();
        delete this.__unwatchFns[key];
      }
    }

    componentWillUnmount() {
      if (this.context.vueEvents) {
        this.context.vueEvents.$off(
          'unwatchFluxBindVueValues',
          this.__unwatchFlux,
        );
      }
      this.__unwatchFlux();
    }

    render() {
      const { forwardedRef, ...actualProps } = this.props;
      // Props override state here, as it means they came from a subsequent
      // call to connectGetters, which should (by design) overwrite existing keys.
      const mergedProps = Object.assign({}, this.state, actualProps);

      return <Component {...mergedProps} ref={forwardedRef} />;
    }
  }

  FluxConnector.contextTypes = {
    vueEvents: PropTypes.shape({
      $emit: PropTypes.func,
      $on: PropTypes.func,
      $off: PropTypes.func,
      $dispatch: PropTypes.func,
    }),
  };

  // Make sure to forward any refs through to Component
  // https://reactjs.org/docs/forwarding-refs.html#forwarding-refs-in-higher-order-components
  const forwardRef = (props, ref) => (
    <FluxConnector {...props} forwardedRef={ref} />
  );
  forwardRef.displayName = `withFluxConnector(${Component.displayName ||
    Component.name})`;
  return hoistNonReactStatics(React.forwardRef(forwardRef), Component);
}

/**
 * A React Hook to observe the value of getters (effectively providing
 * connectGetters to functional components).
 *
 * @param {Object.<{key: getter}>} getters - The flux bindings to observe as kv pairs.
 * @param {Array.<any>} dependencies (optional) - If provided, getters will be re-bound when changed.
 * @returns @param {currentValues: Object} - The current state of the binding keys.
 */
export const useGetters = (getters, dependencies = []) => {
  const isFirstRun = useRef(true);

  const getValuesFromStore = useCallback(() => {
    return Object.entries(getters).reduce((acc, [key, getter]) => {
      return Object.assign(acc, { [key]: flux.evaluate(getter) });
    }, {});
  }, dependencies);

  const [currentValues, setCurrentValues] = useState(getValuesFromStore());

  useEffect(() => {
    if (!isFirstRun.current) {
      setCurrentValues(getValuesFromStore());
    }

    isFirstRun.current = false;

    const unwatchFns = Object.entries(getters).map(([key, getter]) => {
      return flux.observe(getter, val => {
        setCurrentValues(prevState => ({ ...prevState, ...{ [key]: val } }));
      });
    });

    return () => unwatchFns.forEach(fn => fn());
  }, dependencies);

  return currentValues;
};

import keyMirror from 'optly/utils/key_mirror';

export default keyMirror({
  CREATE_EXP_COMPONENT_ADD_AUDIENCE: null,
  CREATE_EXP_COMPONENT_ADD_VARIABLE: null,
  CREATE_EXP_COMPONENT_ADD_VARIATION: null,
  CREATE_EXP_COMPONENT_INIT: null,
  CREATE_EXP_COMPONENT_PAUSE_VARIATION: null,
  CREATE_EXP_COMPONENT_REMOVE_AUDIENCE: null,
  CREATE_EXP_COMPONENT_REMOVE_IDS_FROM_VARIATIONS_FOR_DUPLICATE_EXPERIMENTS: null,
  CREATE_EXP_COMPONENT_REMOVE_VARIABLE: null,
  CREATE_EXP_COMPONENT_REMOVE_VARIATION: null,
  CREATE_EXP_COMPONENT_RESET_TO_UNIFORM_TRAFFIC: null,
  CREATE_EXP_COMPONENT_RESUME_VARIATION: null,
  CREATE_EXP_COMPONENT_TEARDOWN: null,
  CREATE_EXP_COMPONENT_UPDATE_AUDIENCE_CONDITIONS: null,
  CREATE_EXP_COMPONENT_UPDATE_AUDIENCE_CONDITIONS_JSON: null,
  CREATE_EXP_COMPONENT_UPDATE_AUDIENCE_IDS: null,
  CREATE_EXP_COMPONENT_UPDATE_AUDIENCE_MATCH_TYPE: null,
  CREATE_EXP_COMPONENT_UPDATE_BUCKETING_STRATEGY: null,
  CREATE_EXP_COMPONENT_UPDATE_DESCRIPTION: null,
  CREATE_EXP_COMPONENT_UPDATE_LAYER_HOLDBACK: null,
  CREATE_EXP_COMPONENT_UPDATE_NAME: null,
  CREATE_EXP_COMPONENT_UPDATE_CURRENTLY_SELECTED_TRAFFIC_ALLOCATION_POLICY: null,
  CREATE_EXP_COMPONENT_UPDATE_CURRENTLY_SELECTED_DISTRIBUTION_GOAL: null,
  CREATE_EXP_COMPONENT_UPDATE_IS_AUTOMATIC_DISTRIBUTION_GOAL: null,
  CREATE_EXP_COMPONENT_UPDATE_EXPLOITATION_RATE: null,
  CREATE_EXP_COMPONENT_UPDATE_VARIABLE_VALUE: null,
  CREATE_EXP_COMPONENT_UPDATE_VARIATION: null,
  CREATE_EXP_COMPONENT_UPDATE_VARIATION_PROPERTY: null,
  CREATE_EXP_SET_CURRENT_EXPERIMENT_ID_TO_NULL: null,
  CREATE_EXP_COMPONENT_ADD_SECTION: null,
  CREATE_EXP_COMPONENT_REMOVE_SECTION: null,
  CREATE_EXP_COMPONENT_ADD_VARIATION_TO_SECTION: null,
  CREATE_EXP_COMPONENT_REMOVE_VARIATION_FROM_SECTION: null,
  CREATE_EXP_COMPONENT_UPDATE_VARIATION_IN_SECTION: null,
  CREATE_EXP_COMPONENT_UPDATE_SECTION_NAME: null,
  CREATE_EXP_COMPONENT_SET_VIEW_IDS: null,
  CREATE_EXP_COMPONENT_SET_URL_TARGETING_CONFIG: null,
  CREATE_EXP_COMPONENT_SET_TARGETING_TYPE: null,
  CREATE_EXP_COMPONENT_SET_ERRORS: null,
  CREATE_EXP_COMPONENT_UPDATE_USER_ATTRIBUTES: null,
});

import React from 'react';
import PropTypes from 'prop-types';
import ui from 'core/ui';

import TargetingFullSmart from './subcomponents/targeting_full_smart';
import TargetingCreation from './subcomponents/targeting_creation';

const Targeting = props => {
  const { isCreating = false } = props;
  return (
    <div>
      {isCreating ? (
        <TargetingCreation {...props} /> // eslint-disable-line
      ) : (
        <TargetingFullSmart {...props} />
      )}
    </div>
  );
};

Targeting.propTypes = {
  isCreating: PropTypes.bool,
};

/*
 * Convenience static function to trigger display of this component
 */
Targeting.show = onDone => {
  ui.showReactDialog(
    Targeting,
    {
      props: {
        onSave: onDone,
        isInDialog: true,
      },
    },
    {
      fullScreen: true,
      dismissOnBack: true,
    },
  );
};

export default Targeting;

/**
 * Return a string to use in a tooltip for the number of new, modified, and deleted change counts.
 *
 * @param  {Number} newCount
 * @param  {Number} modifiedCount
 * @param  {Number} deletedCount
 * @return {string}
 */
export const draftTooltipText = function(
  newCount,
  modifiedCount,
  deletedCount,
) {
  return tr(
    '{0} New - {1} Modified - {2} Deleted',
    newCount,
    modifiedCount,
    deletedCount,
  );
};

export default {
  draftTooltipText,
};

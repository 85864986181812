/**
 * Ported from optly.common.phone_numbers
 * @author Jordan Garcia
 */
const regex = require('optly/utils/regex');
/**
 * @param {number|string} text
 *
 * Extracts and returns the first phone number identified in the text.
 */
const extractPhoneNumber = function(text) {
  const match = text.match(regex.phoneNumber);
  if (match && match.length > 0) {
    return match[0];
  }
  return null;
};

const DIGITS = '0123456789';

/**
 * @param {number|string} text
 *
 * Strips extraneous characters out of a phone number
 */
const stripPhoneNumber = function(text) {
  const numText = extractPhoneNumber(text);
  if (numText) {
    let finalNumText = '';
    for (let i = 0; i < numText.length; i++) {
      const c = numText[i];
      if (DIGITS.indexOf(c) >= 0) {
        finalNumText += c;
      }
    }
    return finalNumText;
  }
  return null;
};

module.exports = {
  extractPhoneNumber,
  stripPhoneNumber,
};

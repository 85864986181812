/**
 * Filter for formatting phone number (across countries not implemented, just one form).
 * The input is a string containing raw phone number as entered by a user.
 * Default format is N-NNN-NXX-XXXX
 */
module.exports = function(rawPhoneNumber) {
  if (!rawPhoneNumber || rawPhoneNumber.length === 0) {
    return '';
  }
  if (/\D/.test(rawPhoneNumber)) {
    return rawPhoneNumber;
  }

  try {
    const phoneNumberPattern = /(\d*)(\d{3})(\d{3})(\d{4})$/;
    const matches = phoneNumberPattern.exec(rawPhoneNumber);
    return matches.slice(matches[1].length === 0 ? 2 : 1).join('-');
  } catch (error) {
    return rawPhoneNumber;
  }
};

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { toImmutable } from 'nuclear-js';
import { ButtonIcon, SelectDropdown } from 'optimizely-oui';

import { withTrack } from '@optimizely/segment-js/dist/decorators';

import SectionModuleConstants from 'bundles/p13n/sections/implementation/section_module/constants';

import ConditionLine from './condition_line';

@withTrack
class RuleCard extends React.Component {
  static propTypes = {
    conditions: PropTypes.instanceOf(Immutable.List),
    conjunction: PropTypes.string,
    currentProjectTags: PropTypes.instanceOf(Immutable.List),
    handleRemoveRule: PropTypes.func.isRequired,
    handleUpdateRule: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isOnlyRule: PropTypes.bool.isRequired,
    policy: PropTypes.string,
    track: PropTypes.func,
  };

  static defaultProps = {
    conditions: toImmutable([]),
    conjunction: SectionModuleConstants.DefaultFilteringRuleValues.CONJUNCTION,
    currentProjectTags: toImmutable([]),
    policy: SectionModuleConstants.DefaultFilteringRuleValues.POLICY,
    track: () => {},
  };

  getCurrentRule = () => {
    const { conditions, conjunction, policy } = this.props;
    return toImmutable({
      policy,
      condition: { conditions, op: conjunction },
    });
  };

  handleConjunctionUpdate = value => {
    const { handleUpdateRule, index, track } = this.props;
    const rule = this.getCurrentRule();
    const updatedRule = rule.setIn(['condition', 'op'], value);
    track('Recommendations Policy Scope Selected');
    handleUpdateRule(updatedRule, index);
  };

  handlePolicyUpdate = value => {
    const { handleUpdateRule, index, track } = this.props;
    const rule = this.getCurrentRule();
    const updatedRule = rule.set('policy', value);
    track('Recommendations Policy Selected', {
      policy: value,
    });
    handleUpdateRule(updatedRule, index);
  };

  handleAddCondition = () => {
    const { handleUpdateRule, index, track } = this.props;
    const rule = this.getCurrentRule();
    const updatedRule = rule.updateIn(['condition', 'conditions'], conditions =>
      conditions.push(toImmutable(SectionModuleConstants.DefaultCondition)),
    );
    track('Recommendations Condition Added');
    handleUpdateRule(updatedRule, index);
  };

  handleRemoveCondition = conditionIndex => {
    const { handleUpdateRule, index } = this.props;
    const rule = this.getCurrentRule();
    const updatedRule = rule.updateIn(['condition', 'conditions'], conditions =>
      conditions.splice(conditionIndex, 1),
    );
    handleUpdateRule(updatedRule, index);
  };

  handleUpdateCondition = (value, conditionIndex) => {
    const { handleUpdateRule, index } = this.props;
    const rule = this.getCurrentRule();
    const updatedRule = rule.setIn(
      ['condition', 'conditions', conditionIndex],
      value,
    );
    handleUpdateRule(updatedRule, index);
  };

  renderConditions = () => {
    const { conditions, currentProjectTags } = this.props;
    const numConditions = conditions.size;
    return conditions.map((condition, index) => {
      const operator = condition.get('op');
      const lhsContext = condition.getIn(['lhs', 'context']);
      const lhsField =
        condition.getIn(['lhs', 'field']) &&
        condition.getIn(['lhs', 'field']).size > 0 &&
        condition.getIn(['lhs', 'field', 0]);
      const lhsValue = condition.getIn(['lhs', 'value']);
      const rhsContext = condition.getIn(['rhs', 'context']);
      const rhsField =
        condition.getIn(['rhs', 'field']) &&
        condition.getIn(['rhs', 'field']).size > 0 &&
        condition.getIn(['rhs', 'field', 0]);
      const rhsValue = condition.getIn(['rhs', 'value']);

      return (
        <ConditionLine
          currentProjectTags={currentProjectTags}
          handleAddCondition={this.handleAddCondition}
          handleRemoveCondition={this.handleRemoveCondition}
          handleUpdateCondition={this.handleUpdateCondition}
          index={index}
          isOnlyCondition={numConditions === 1}
          isLastCondition={index === numConditions - 1}
          key={index}
          lhsContext={lhsContext}
          lhsField={lhsField}
          lhsValue={lhsValue}
          operator={operator}
          rhsContext={rhsContext}
          rhsField={rhsField}
          rhsValue={rhsValue}
        />
      );
    });
  };

  renderPolicyAndConjunction = () => {
    const { conjunction, index, policy } = this.props;
    return (
      <div className="flex flex-align--center">
        <SelectDropdown
          items={SectionModuleConstants.PolicyOptions}
          value={policy}
          onChange={this.handlePolicyUpdate}
          buttonStyle="underline"
          testSection={`filters-policy-rule-${index}`}
        />
        <span className="push--sides">items that match</span>
        <SelectDropdown
          items={SectionModuleConstants.ConjunctionOptions}
          value={conjunction}
          onChange={this.handleConjunctionUpdate}
          buttonStyle="underline"
          testSection={`filters-conjunction-rule-${index}`}
        />
        <span className="push--left">of the following conditions:</span>
      </div>
    );
  };

  render() {
    const { index, isOnlyRule, handleRemoveRule } = this.props;
    return (
      <div
        className="border--all soft-double push-double--top push--bottom"
        data-test-section={`filters-rule-${index}`}>
        {!isOnlyRule && (
          <div className="float--right">
            <ButtonIcon
              style="outline"
              size="small"
              testSection={`filters-remove-rule-${index}`}
              onClick={() => handleRemoveRule(index)}
              iconName="trash-can"
              title="Remove Condition Group"
            />
          </div>
        )}
        {this.renderPolicyAndConjunction()}
        <hr className="oui-rule push-double--ends" />
        {this.renderConditions()}
      </div>
    );
  }
}

export default RuleCard;

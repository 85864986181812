import React from 'react';
import PropTypes from 'prop-types';
import { Immutable } from 'nuclear-js';
import moment from 'moment';

/* Components */
import {
  Button,
  ButtonIcon,
  Table,
  Link as InternalLink,
  Dropdown,
  BlockList,
} from 'optimizely-oui';

import location from 'optly/location';

import SectionModuleFns from 'bundles/p13n/sections/implementation/section_module/fns';
import { RecommendationsCSVDownloadUnavailable } from 'bundles/p13n/components/messaging/recommendations';

const CatalogTableRow = ({
  archiveCatalogClick,
  canManageRecommendations,
  catalog,
  editCatalogClick,
  showCatalogProfilerClick,
  track,
  unarchiveCatalogClick,
}) => {
  const catalogID = catalog.get('id');
  const catalogName = catalog.get('name');
  const catalogDescription = catalog.get('description');
  const catalogTotalItems = catalog.getIn(
    ['job_status_summary', 'total_num_items'],
    0,
  );
  const isArchived = catalog.get('archived');
  const lastRun = catalog.getIn(['job_status_summary', 'last_run']);
  const lastRunFormatted = lastRun
    ? moment.utc(lastRun).format('LL')
    : 'Unavailable';
  // CSVs are labeled with UTC so we need to format the proper time using UTC instead of local time.
  const lastRunCSVFormatted =
    lastRun && moment.utc(lastRun).format('YYYY-MM-DD');

  const editCatalogOnClick = () => {
    editCatalogClick(catalog);
  };

  const archiveCatalogOnClick = () => {
    archiveCatalogClick(catalog);
  };

  const unarchiveCatalogOnClick = () => {
    unarchiveCatalogClick(catalog);
  };

  const showCatalogProfilerOnClick = () => {
    showCatalogProfilerClick(catalog);
  };

  const downloadCatalogInventoryCSVOnClick = () => {
    location.setLocation(
      SectionModuleFns.buildURLForDownloadCSV(
        catalogID,
        'catalog',
        lastRunCSVFormatted,
      ),
    );

    track('Recommendations Catalog Inventory CSV Downloaded', { catalogID });
  };

  const downloadOverviewStatisticsCSVOnClick = () => {
    location.setLocation(
      SectionModuleFns.buildURLForDownloadCSV(
        catalogID,
        'stats',
        lastRunCSVFormatted,
      ),
    );

    track('Recommendations Overview Statistics CSV Downloaded', { catalogID });
  };

  const renderName = () => (
    <React.Fragment>
      {isArchived ? (
        <span
          className="word-break--word"
          data-test-section={`catalog-no-edit-btn-${catalogID}`}>
          {catalogName}
        </span>
      ) : (
        <div className="word-break--word">
          <InternalLink
            onClick={editCatalogOnClick}
            testSection={`catalog-edit-btn-${catalogID}`}>
            {catalogName}
          </InternalLink>
        </div>
      )}
      <div
        className="micro muted word-break--word"
        data-test-section={`catalog-description-${catalogID}`}>
        {catalogDescription}
      </div>
    </React.Fragment>
  );

  const renderDropdown = () => {
    const items = [
      {
        isDisabled: false,
        isVisible: !isArchived && canManageRecommendations,
        onClick: archiveCatalogOnClick,
        testSection: `archive-btn-${catalogID}`,
        text: 'Archive',
      },
      {
        isDisabled: false,
        isVisible: isArchived && canManageRecommendations,
        onClick: unarchiveCatalogOnClick,
        testSection: `unarchive-btn-${catalogID}`,
        text: 'Unarchive',
      },
      {
        description: 'Downloaded CSV will use UTC instead of local time.',
        header: 'Download CSV',
        isDisabled: !lastRun,
        isVisible: true,
        onClick: downloadCatalogInventoryCSVOnClick,
        testSection: `catalog-inventory-csv-btn-${catalogID}`,
        text: 'Catalog Inventory',
      },
      {
        description: 'Downloaded CSV will use UTC instead of local time.',
        isDisabled: !lastRun,
        isVisible: true,
        onClick: downloadOverviewStatisticsCSVOnClick,
        testSection: `overview-statistics-csv-btn-${catalogID}`,
        text: 'Overview Statistics',
      },
    ];

    return (
      <Dropdown
        testSection={`catalog-dropdown-${catalogID}`}
        width={!lastRun ? '300px' : '200px'}
        activator={
          <ButtonIcon
            iconFill="default"
            iconName="ellipsis-solid"
            size="small"
            style="plain"
            testSection={`dropdown-menu-${catalogID}`}
          />
        }
        arrowIcon="down">
        <Dropdown.Contents>
          <BlockList>
            {items
              .filter(item => item.isVisible)
              .map(item => (
                <BlockList.Category header={item.header} key={item.testSection}>
                  <BlockList.Item
                    onClick={item.onClick}
                    testSection={item.testSection}
                    isDisabled={item.isDisabled}>
                    <div className="flex flex-align--center">
                      <div className="flex--1">
                        <div>{item.text}</div>
                        {item.isDisabled && (
                          <RecommendationsCSVDownloadUnavailable
                            testSection={item.testSection}
                          />
                        )}
                        <div className="muted micro">{item.description}</div>
                      </div>
                    </div>
                  </BlockList.Item>
                </BlockList.Category>
              ))}
          </BlockList>
        </Dropdown.Contents>
      </Dropdown>
    );
  };

  return (
    <Table.TR borderStyle="ends" backgroundColor="faint" key={catalog}>
      <Table.TD width="60%">{renderName()}</Table.TD>
      <Table.TD width="12%">{catalogTotalItems}</Table.TD>
      <Table.TD>
        <span className="nowrap">{lastRunFormatted}</span>
      </Table.TD>
      <Table.TD>
        <span className="nowrap">{catalogID}</span>
      </Table.TD>
      <Table.TD>
        <Button
          key={`profiler-${catalogID}`}
          size="small"
          style="outline"
          width="default"
          testSection={`show-catalog-profiler-${catalogID}-btn`}
          onClick={showCatalogProfilerOnClick}>
          Profiler
        </Button>
      </Table.TD>
      <Table.TD isNumerical={true}>{renderDropdown()}</Table.TD>
    </Table.TR>
  );
};

CatalogTableRow.propTypes = {
  archiveCatalogClick: PropTypes.func.isRequired,
  canManageRecommendations: PropTypes.bool.isRequired,
  catalog: PropTypes.instanceOf(Immutable.Map).isRequired,
  editCatalogClick: PropTypes.func.isRequired,
  showCatalogProfilerClick: PropTypes.func.isRequired,
  track: PropTypes.func.isRequired,
  unarchiveCatalogClick: PropTypes.func.isRequired,
};

export default CatalogTableRow;

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import { toImmutable } from 'optly/immutable';

import definition from './entity_definition';
import enums from './enums';

const baseEntityGetters = createEntityGetters(definition);

export const { entityCache } = baseEntityGetters;

/**
 * Getter for the experiment results map
 */
const experimentResultsMap = [
  ['experimentResults'],
  resultsMap => resultsMap || toImmutable({}),
];

export const startedExperiments = [
  baseEntityGetters.entityCache,
  experiments =>
    experiments.filter(
      experiment =>
        experiment.get('status') === enums.status_type.RUNNING ||
        experiment.get('status') === enums.status_type.PAUSED,
    ),
];

export const combinedExperimentsAndResults = [
  experimentResultsMap,
  baseEntityGetters.entityCache,
  (resultsMap, experiments) =>
    experiments
      .map(exp => {
        const result = resultsMap.get(exp.get('id'));
        if (result) {
          exp = exp.set('results', result);
        }
        return exp;
      })
      .toList(),
];

/**
 * Returns a getter for the visitor count of an experiment
 * @param {Number} experimentId
 * @return {Getter}
 */
export function results(experimentId) {
  return ['experimentResults', experimentId];
}

export default {
  ...baseEntityGetters,
  combinedExperimentsAndResults,
  results,
  startedExperiments,
};

/**
 * Entity Definition for EmailSetting
 */
import FieldTypes from 'optly/modules/rest_api/field_types';

export const entity = 'email_settings';
export const parent = {
  entity: 'users',
  key: 'email',
};
export const fieldTypes = {
  opt_out_category_ids: FieldTypes.ARRAY,
  email: FieldTypes.STRING,
};

export default {
  entity,
  fieldTypes,
  parent,
};

import Router from 'core/router';

/**
 * Helper function to handle a click event which we want to treat as a router navigation.
 * @param href - The route to execute.
 * @param e - The click event.
 */
export default function(href, e) {
  function shouldUseDefault() {
    const MIDDLE_BUTTON = 1;
    return e.button === MIDDLE_BUTTON || e.ctrlKey || e.shiftKey || e.metaKey;
  }
  // Allow clicks with key modifiers to follow default browser behavior
  if (shouldUseDefault()) {
    return;
  }
  e.preventDefault();
  Router.go(href);
}

/* eslint-disable */
/**
 * Bundle Split Helpers - This module is intended to encapsulate shared bundle splitting
 * logic.  All shared code using require.ensure or dynamic imports to trigger webpack
 * dynamic bundle loading should live in this module.  We disable eslint for this file
 * as the dynamic imports cannot be parsed appropriately by eslint (as of 3/2018).
 */
import lintingInitializer from 'core/utils/linting_initializer';

export function getAccountDashboardBundleModule() {
  return import(
    /* webpackChunkName: "account-dashboard-bundle" */
    'bundles/p13n/sections/account_dashboard'
  );
}

export function getAudiencesBundleModules() {
  return Promise.all([
    import(/* webpackChunkName: "audiences-bundle" */ 'bundles/p13n/sections/audiences'),
    import(/* webpackChunkName: "audiences-bundle" */ 'bundles/p13n/sections/audiences/section_module'),
    import(/* webpackChunkName: "audiences-bundle" */ 'optly/components/audience_editor/audience_editor')
  ])
  .then(([AudiencesSection, AudiencesSectionModule, AudienceEditor]) => {
    console.warn('[BUNDLE SPLIT HELPER] Fetched 3 "getAudiencesBundleModules" OK');
    return (
      { AudiencesSection, AudiencesSectionModule, AudienceEditor: AudienceEditor.default }
    )
  });
}

export function getCodeLintingBundleModules() {
  return Promise.all([
    import(/* webpackChunkName: "code-linting-bundle" */ 'jshint'),
    import(/* webpackChunkName: "code-linting-bundle" */ 'csslint'),
  ])
  .then(lintingInitializer);
}

export function getFirebaseBundleModules() {
  return Promise.all([
    import(/* webpackChunkName: "firebase-bundle" */ 'firebase/compat/app'),
    import(/* webpackChunkName: "firebase-bundle" */ 'firebase/compat/database'),
  ])
  .then(([firebaseApp, firebaseDatabase]) => (
    {
      firebaseApp: firebaseApp.default, // not entirely sure why these .default suffixes are needed here??
      firebaseDatabase: firebaseDatabase.default
    }
  ));
}

export function getOasisBundleModules() {
  return Promise.all([
    import(/* webpackChunkName: "full-stack-bundle" */ 'bundles/p13n/sections/oasis_experiment_manager'),
    import(/* webpackChunkName: "full-stack-bundle" */ 'bundles/p13n/sections/oasis_experiment_manager/section_module'),
    import(/* webpackChunkName: "full-stack-bundle" */ 'bundles/p13n/sections/oasis_implementation'),
    import(/* webpackChunkName: "full-stack-bundle" */ 'bundles/p13n/sections/full_stack_onboarding'),
    import(/* webpackChunkName: "full-stack-bundle" */ 'bundles/p13n/sections/live_variables'),
    import(/* webpackChunkName: "full-stack-bundle" */ 'bundles/p13n/sections/rollouts'),
    import(/* webpackChunkName: "full-stack-bundle" */ 'bundles/p13n/sections/manager_fullstack'),
    import(/* webpackChunkName: "full-stack-bundle" */ 'bundles/p13n/sections/manager_fullstack/section_module'),
  ])
  .then(
    ([
      OasisExperimentManagerSection,
      OasisExperimentManagerModule,
      OasisImplementationSection,
      FullStackOnboardingSection,
      LiveVariablesSection,
      RolloutsSection,
      ManagerFullstackSection,
      ManagerFullstackModule
    ]) => ({
      OasisExperimentManagerSection,
      OasisExperimentManagerModule,
      OasisImplementationSection,
      FullStackOnboardingSection,
      LiveVariablesSection,
      RolloutsSection,
      ManagerFullstackSection,
      ManagerFullstackModule
    })
  );
}

export default {
  getAccountDashboardBundleModule,
  getAudiencesBundleModules,
  getCodeLintingBundleModules,
  getFirebaseBundleModules,
  getOasisBundleModules,
}

/**
 * Resizable directive
 *
 * @author Sam Jackson (sam@optimizely.com)
 */
const $ = require('jquery');
const _ = require('lodash');

const DEFAULT_OPTIONS = {
  handles: {},
  maxHeight: 0,
  minHeight: 0,
};

module.exports = {
  _options: {},

  /**
   * Add resize start + stop handlers so we can set up our own events
   *
   * @private
   */
  _addResizeHandlers() {
    $(this.el)
      .on('resize', this._resizeHandler.bind(this))
      .on('resizestart', this._resizeStartHandler.bind(this))
      .on('resizestop', this._resizeStopHandler.bind(this));
  },

  /**
   * Extend the options object with an arbitrary set of objects
   *
   */
  _extendOptions() {
    const args = Array.prototype.slice.call(arguments);
    args.splice(0, 0, this._options);
    _.extend.apply(null, args);
  },

  /**
   * Called on resize event -- http://api.jqueryui.com/resizable/#event-resize
   *
   * @private
   */
  _resizeHandler(event, ui) {
    $(this.el).trigger('optlyresize', ui);
  },

  /**
   * Called on resizestart event -- http://api.jqueryui.com/resizable/#event-start
   *
   * @private
   */
  _resizeStartHandler(event, ui) {
    $(this.el).trigger('optlyresizestart', ui);
  },

  /**
   * Called on resizestop event -- http://api.jqueryui.com/resizable/#event-stop
   *
   * @private
   */
  _resizeStopHandler(event, ui) {
    $(this.el).trigger('optlyresizestop', ui);
  },

  /**
   * Initialize the resizable element
   *
   * @param value
   */
  bind(value) {
    this._addResizeHandlers();
    this._extendOptions(DEFAULT_OPTIONS, value);
    $(this.el).resizable(this._options);
  },

  /**
   * Destroy the jQuery UI component
   *
   */
  unbind() {
    $(this.el).resizable('destroy');
  },

  /**
   * If the options change, pass the new set along to the jQuery UI component
   *
   * @param value
   */
  update(value) {
    this._extendOptions(value);
    // sometimes we need to complete reset the resizable plugin because it needs to be lazy initialized (wait for DOM
    // element to actually be displayed) and the way we lazy initialize it is by updating the value and telling it to reset
    if (!value.reset) {
      $(this.el).resizable('option', this._options);
    } else {
      $(this.el)
        .resizable('destroy')
        .resizable(this._options);
    }
  },
};

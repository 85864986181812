/* eslint-disable class-methods-use-this */
import _ from 'lodash';
import $ from 'jquery';

import PropTypes from 'prop-types';

import React from 'react';

import { withTrack } from '@optimizely/segment-js/dist/decorators';
import { OptimizelyFeature } from '@optimizely/react-sdk';

import {
  Attention,
  Button,
  ButtonRow,
  Checkbox,
  Input,
  Icon,
} from 'optimizely-oui';

import flux from 'core/flux';

import capitalize from 'optly/filters/capitalize';
import Immutable from 'optly/immutable';
import { connect } from 'core/ui/decorators';

import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import EventGetters from 'optly/modules/entity/event/getters';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerFns from 'optly/modules/entity/layer/fns';
import MetricConstants from 'optly/modules/entity/metric/constants';
import MetricFns from 'optly/modules/entity/metric/fns';
import MetricTemplateActions from 'optly/modules/entity/metric_template/actions';
import ViewGetters from 'optly/modules/entity/view/getters';

// FOR OUTLIER FILTERING WHITELIST
import AdminAccountGetters from 'optly/modules/admin_account/getters';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
// END OUTLIER FILTERING WHITELIST

import OutlierFilterPopover from 'bundles/p13n/components/help_popovers/outlier_filter';
import MetricsManagerModuleActions from 'bundles/p13n/modules/metrics_manager/actions';
import MetricsManagerModuleGetters from 'bundles/p13n/modules/metrics_manager/getters';

import SelectDropdown from 'react_components/select_dropdown';

@withTrack
class ActionButtons extends React.Component {
  static propTypes = {
    confirmText: PropTypes.string.isRequired,
    currentProjectId: PropTypes.number.isRequired,
    getMetricProperty: PropTypes.func,
    metricName: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    primaryDisabled: PropTypes.bool.isRequired,
    track: PropTypes.func,
  };

  static defaultProps = {
    track: () => {},
    getMetricProperty: () => {},
  };

  state = {
    copyMetricToDashboard: false,
  };

  handleConfirmClick = () => {
    const {
      onConfirm,
      track,
      currentProjectId,
      getMetricProperty,
      metricName,
    } = this.props;
    const { copyMetricToDashboard } = this.state;

    if (copyMetricToDashboard) {
      const metricTemplate = {
        aggregator: getMetricProperty('aggregator'),
        description: getMetricProperty('display_title'),
        display_title: getMetricProperty('display_title'),
        event_id: getMetricProperty('event_id'),
        event_type: getMetricProperty('event_type'),
        winning_direction: getMetricProperty('winning_direction'),
        name: getMetricProperty('display_title')
          ? getMetricProperty('display_title')
          : metricName,
        project_id: currentProjectId,
        scope: getMetricProperty('scope'),
      };

      MetricTemplateActions.save(metricTemplate);
      track('Metric Template Saved');
    }

    onConfirm();
    track('Metric Edits Saved');
  };

  render() {
    const { confirmText, onCancel, primaryDisabled } = this.props;
    const { copyMetricToDashboard } = this.state;

    return (
      <ButtonRow
        leftGroup={[
          <OptimizelyFeature feature="metrics_usability">
            {isEnabled =>
              isEnabled && (
                <Checkbox
                  checked={copyMetricToDashboard}
                  label="Push a copy of this metric to the Metrics Dashboard"
                  onChange={() =>
                    this.setState({
                      copyMetricToDashboard: !copyMetricToDashboard,
                    })
                  }
                  data-test-section="metric-copy-to-dashboard-checkbox"
                />
              )
            }
          </OptimizelyFeature>,
        ]}
        rightGroup={[
          <Button
            key="cancel"
            onClick={onCancel}
            style="plain"
            testSection="metric-editor-cancel-button">
            Cancel
          </Button>,
          <Button
            key="apply"
            isDisabled={primaryDisabled}
            onClick={this.handleConfirmClick}
            style="highlight"
            testSection="metric-editor-apply-button">
            {confirmText}
          </Button>,
        ]}
      />
    );
  }
}

@connect({
  canUseOutlierFiltering: [
    AdminAccountGetters.accountPermissions,
    CurrentLayerGetters.layer,
    PermissionsModuleFns.canUseOutlierFiltering,
  ],
  currentLayer: CurrentLayerGetters.layer,
  outlierFilter: MetricsManagerModuleGetters.outlierFilter,
  currentProjectId: CurrentProjectGetters.id,
})
class MetricEditor extends React.Component {
  static propTypes = {
    canUseOutlierFiltering: PropTypes.bool,
    currentLayer: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentProjectId: PropTypes.number.isRequired,
    dragHandle: PropTypes.func,
    indexValue: PropTypes.number,
    isDialog: PropTypes.bool,
    layer: PropTypes.instanceOf(Immutable.Map).isRequired,
    metricWrapper: PropTypes.instanceOf(Immutable.Map).isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onUpdate: PropTypes.func,
    outlierFilter: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  static defaultProps = {
    isDialog: false,
    // Only need onUpdate if changes in the editor should impact things outside of the editor
    onUpdate: () => {},
  };

  constructor(props) {
    super(props);
    const { metricWrapper } = props;

    this.state = {
      eventMap: flux.evaluate(EventGetters.entityCache),
      viewMap: flux.evaluate(ViewGetters.entityCache),
      workingMetricWrapper: metricWrapper,
    };

    MetricsManagerModuleActions.setCurrentlyEditingMetricWrapper(metricWrapper);
  }

  titleInputRef = React.createRef();

  componentWillUnmount() {
    MetricsManagerModuleActions.setCurrentlyEditingMetricWrapper(null);
  }

  getMetricProperty = property => {
    const { workingMetricWrapper } = this.state;

    return workingMetricWrapper.getIn(['metric', property]);
  };

  handleTitleInputFocus = event => {
    event.target.select();
  };

  handleTitleKeyUp = event => {
    switch (event.keyCode) {
      case 27: // Escape
      case 13: // Return
        this.titleInputRef.current.blur();
        break;
      default:
        break;
    }
  };

  isDirty = () => {
    const { metricWrapper } = this.props;
    const { workingMetricWrapper } = this.state;

    if (
      workingMetricWrapper.get('alias') === MetricConstants.WORKING_METRIC_ALIAS
    ) {
      return true;
    }

    return !Immutable.is(metricWrapper, workingMetricWrapper);
  };

  setAggregator = aggregatorFunction => {
    const { metricWrapper } = this.props;
    const aggOpts = MetricConstants.aggregationOperations[aggregatorFunction];

    this.updateMetricWrapperProperty('aggregator', aggOpts.aggregator).then(
      () => {
        if (metricWrapper.get('metric').get('event_id')) {
          this.updateMetricWrapperProperty('field', aggOpts.field).then(() => {
            this.setScopeOptionsAndUpdateScope();
          });
        }
      },
    );
  };

  setScope = scope => {
    this.updateMetricWrapperProperty('scope', scope);
  };

  setScopeOptionsAndUpdateScope = () => {
    const { layer } = this.props;
    const { eventMap, viewMap, workingMetricWrapper } = this.state;

    // Creating the metric wrapper will set the correct scope options
    // Since the scope hasn't been updated, other fields are incorrect.
    // Thus, we only take the scopeOptions from the generated metric wrapper.
    const newMetricWrapper = MetricFns.createSingleMetricWrapper(
      workingMetricWrapper.get('metric'),
      null,
      layer,
      viewMap,
      eventMap,
    );

    const scopeOptions = newMetricWrapper.get('scopeOptions');

    this.setState(
      previousState => ({
        workingMetricWrapper: previousState.workingMetricWrapper.set(
          'scopeOptions',
          scopeOptions,
        ),
      }),
      this.updateScope,
    );
  };

  setTitle = event => {
    this.updateMetricWrapperProperty(
      'display_title',
      event.target.value.trim(),
    );
  };

  setWinningDirection = winningDirection => {
    this.updateMetricWrapperProperty('winning_direction', winningDirection);
  };

  updateMetricWrapperProperty = (property, value) => {
    const deferred = $.Deferred();
    const { onUpdate } = this.props;
    const { workingMetricWrapper } = this.state;

    this.setState(
      {
        workingMetricWrapper: workingMetricWrapper.setIn(
          ['metric', property],
          value,
        ),
      },
      () => {
        onUpdate(workingMetricWrapper);
        deferred.resolve();
      },
    );

    return deferred;
  };

  updateScope = () => {
    const { workingMetricWrapper } = this.state;
    const scopeOptions = workingMetricWrapper.get('scopeOptions');
    const currentScope = workingMetricWrapper.getIn(['metric', 'scope']);
    const curScopeInOptions = scopeOptions.find(
      option => option.get('value') === currentScope,
    );

    // If current scope is not in the new scope options, update it to the first default scope
    if (!curScopeInOptions) {
      this.setScope(scopeOptions.first().get('value'));
    }
  };

  render() {
    const {
      canUseOutlierFiltering,
      currentLayer,
      currentProjectId,
      dragHandle,
      indexValue,
      isDialog,
      layer,
      metricWrapper,
      onCancel,
      onConfirm,
      outlierFilter,
    } = this.props;

    const { eventMap, viewMap, workingMetricWrapper } = this.state;

    const entityText = capitalize(
      LayerEnums.typeToEntityName[layer.get('type') || LayerEnums.type.AB],
    );

    const onConfirmFn = _.partial(onConfirm, workingMetricWrapper);
    const confirmText = metricWrapper.get('alias')
      ? `Save to ${entityText}`
      : `Add to ${entityText}`;
    const metric = workingMetricWrapper.get('metric');

    const actionButtons = (
      <ActionButtons
        confirmText={confirmText}
        onCancel={onCancel}
        onConfirm={onConfirmFn}
        primaryDisabled={!this.isDirty() || LayerFns.isLayerReadonly(layer)}
        getMetricProperty={this.getMetricProperty}
        currentProjectId={currentProjectId}
        metricName={MetricFns.getMetricName(metric, eventMap, viewMap)}
      />
    );

    const selectedAggregatorField = MetricFns.getAggregatorSelected(metric);
    const isAbandonmentMetric = MetricFns.isAbandonmentMetric(metric);

    const showOutlierFilterEnabledText =
      canUseOutlierFiltering &&
      MetricFns.isRevenueMetric(workingMetricWrapper.get('metric')) &&
      !isDialog;
    const outlierFilterEnabledText = outlierFilter.get('enabled')
      ? tr('enabled')
      : tr('disabled');

    return (
      <div data-test-section="metric-editor">
        {isDialog && (
          <div className="beta push-quad--top push-double--bottom">
            Edit Metric
          </div>
        )}
        <div className="border--bottom background--white">
          <div className="flex">
            {dragHandle &&
              dragHandle(
                <div className="flex border--left border--ends muted cursor--move soft-half--top">
                  <span className="soft--left soft-half--right">
                    {indexValue}
                  </span>
                  <Icon
                    name="ellipsis-solid"
                    size="small"
                    className="lego-icon lego-token__move push-half--top"
                  />
                </div>,
              )}
            <div className="flex--1" onKeyUp={this.handleTitleKeyUp}>
              <Input
                defaultValue={this.getMetricProperty('display_title')}
                onFocus={this.handleTitleInputFocus}
                onInput={this.setTitle}
                placeholder={MetricFns.getMetricName(metric, eventMap, viewMap)}
                ref={this.titleInputRef}
                type="text"
                testSection="metric-editor-input"
              />
            </div>
          </div>
          <div className="soft-double--ends soft-quad--sides border--sides flex flex-wrap">
            <SelectDropdown
              buttonStyle="underline"
              items={MetricConstants.winningDirectionOptions}
              minDropdownWidth={100}
              onChange={this.setWinningDirection}
              testSection="winning-direction-dropdown"
              value={
                this.getMetricProperty('winning_direction') ||
                MetricConstants.winning_direction.INCREASING
              }
              width="auto"
            />
            <div className="muted soft-half--top push-half--sides"> in </div>
            {workingMetricWrapper.get('aggregatorOptions').size > 1 && (
              <SelectDropdown
                buttonStyle="underline"
                items={workingMetricWrapper.get('aggregatorOptions').toJS()}
                minDropdownWidth={175}
                onChange={this.setAggregator}
                testSection="aggregator-dropdown"
                value={selectedAggregatorField}
                width="auto"
              />
            )}
            {workingMetricWrapper.get('aggregatorOptions').size === 1 && (
              <div className="muted soft-half--top">
                {workingMetricWrapper
                  .get('aggregatorOptions')
                  .first()
                  .get('label')}
              </div>
            )}
            {!isAbandonmentMetric && (
              <div className="muted soft-half--top push-half--sides"> per </div>
            )}
            {workingMetricWrapper.get('scopeOptions').size > 1 && (
              <SelectDropdown
                buttonStyle="underline"
                items={workingMetricWrapper.get('scopeOptions').toJS()}
                minDropdownWidth={100}
                onChange={this.setScope}
                testSection="scope-dropdown"
                value={this.getMetricProperty('scope')}
                width="auto"
              />
            )}
            {workingMetricWrapper.get('scopeOptions').size === 1 &&
              !isAbandonmentMetric && (
                <div className="muted soft-half--top">
                  {' '}
                  {this.getMetricProperty('scope')}{' '}
                </div>
              )}
            {!MetricFns.isMetricOverallRevenue(
              workingMetricWrapper.get('metric'),
            ) && (
              <div className="flex flex-wrap">
                <div className="muted soft-half--top push-half--sides">
                  {' '}
                  for{' '}
                </div>
                <div className="weight--bold muted soft-half--top">
                  {this.getMetricProperty('name') ||
                    MetricFns.getMetricEventName(metric, eventMap, viewMap)}
                </div>
                <div className="muted soft-half--top push-half--sides">
                  {' '}
                  event.
                </div>
              </div>
            )}
          </div>
          {showOutlierFilterEnabledText && (
            <div className="soft--bottom soft-quad--sides border--sides">
              <span data-test-section="outlier-filtering-state-text">
                Outlier smoothing is {outlierFilterEnabledText}.
              </span>
              <OutlierFilterPopover />
            </div>
          )}
          {!isDialog && (
            <div className="soft background--faint border--top border--sides">
              {actionButtons}
            </div>
          )}
        </div>
        {isDialog && (
          <div className="push-quad--top">
            {!LayerFns.isMultiArmedBandit(currentLayer) && (
              <Attention
                alignment="center"
                testSection="metric-editor-warning"
                type="warning">
                <span>
                  Changing this metric could affect statistical significance for
                  other metrics in this campaign.{' '}
                </span>
                <a
                  href="https://support.optimizely.com/hc/en-us/articles/4410283825421-Edit-a-metric"
                  rel="noopener noreferrer"
                  target="_blank">
                  Learn More
                </a>
                .
              </Attention>
            )}
            <div className="push-quad--top soft border--top">
              {actionButtons}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MetricEditor;

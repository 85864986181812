const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const actionTypes = require('../action_types');

const INITIAL_STATE = {
  currentSelector: '',
  editorState: {
    elementSelectorEnabled: false,
    isEditingSelector: false,
  },
};

module.exports = Nuclear.Store({
  getInitialState() {
    return toImmutable(INITIAL_STATE);
  },

  initialize() {
    this.on(
      actionTypes.P13N_EVENT_EDITOR_SET_CURRENT_SELECTOR,
      setEventEditorSelector,
    );
    this.on(
      actionTypes.P13N_EVENT_EDITOR_SET_ELEMENT_SELECTOR_ENABLED,
      setEventEditorElementSelectorEnabled,
    );
    this.on(
      actionTypes.P13N_EVENT_EDITOR_SET_IS_EDITING_SELECTOR,
      setEventEditorIsEditingSelector,
    );
  },
});

function setEventEditorSelector(state, payload) {
  return state.set('currentSelector', payload.selector);
}

function setEventEditorElementSelectorEnabled(state, payload) {
  return state.setIn(
    ['editorState', 'elementSelectorEnabled'],
    payload.enabled,
  );
}

function setEventEditorIsEditingSelector(state, payload) {
  return state.setIn(['editorState', 'isEditingSelector'], payload.isEditing);
}

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

export const orderedAudienceIds = ['experienceManager', 'orderedAudienceIds'];

export const validAudiences = [
  CurrentProjectGetters.audiences,
  audiences => audiences.sortBy(audience => audience.get('name')),
];

export const orderedAudiences = [
  validAudiences,
  orderedAudienceIds,
  (_audiences, _orderedAudienceIds) =>
    _orderedAudienceIds.map((audienceId, index) =>
      _audiences
        .find(audience => audience.get('id') === audienceId)
        .set('index', index),
    ),
];

export default {
  orderedAudiences,
  orderedAudienceIds,
  validAudiences,
};

import flux from 'core/flux';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { actions as LayerActions } from 'optly/modules/entity/layer';
import {
  actions as AudienceLayersActions,
  getters as AudienceLayersGetters,
} from 'optly/modules/entity/audience_layers';

const fetchLayersForAudience = (projectId, audienceId) => {
  return new Promise((resolve, reject) => {
    AudienceLayersActions.fetchAll(
      {
        project_id: projectId,
        audience_id: audienceId,
      },
      {
        force: true,
      },
    ).then(() => {
      const audienceLayers = flux.evaluateToJS(
        AudienceLayersGetters.byId(audienceId),
      );
      const layerIds = audienceLayers.layer_ids;
      const loadedLayers = flux
        .evaluate(CurrentProjectGetters.layers)
        .map(layer => layer.get('id'))
        .toJS();
      const notpresentLayers = layerIds.filter(
        layerId => !loadedLayers.includes(layerId),
      );
      if (notpresentLayers.length > 0) {
        LayerActions.fetchAll({
          project_id: projectId,
          id: notpresentLayers,
        }).then(() => {
          resolve(layerIds);
        }, reject);
      } else {
        resolve(layerIds);
      }
    }, reject);
  });
};

export default {
  fetchLayersForAudience,
};

import React from 'react';
import PropTypes from 'prop-types';

import { ButtonRow, Button } from 'optimizely-oui';

import { Footer, Fieldset } from 'react_components/dialog';
import SupportEnums from 'optly/modules/support/enums';

import ButtonGrid from '../button_grid';

export default class ChooseCaseType extends React.Component {
  static propTypes = {
    categoryId: PropTypes.string.isRequired,
    setCaseType: PropTypes.func.isRequired,
    switchStepHandler: PropTypes.func.isRequired,
  };

  showCampaignOrExperimentText = () =>
    SupportEnums.categories.X_WEB_PERSONALIZATION.value ===
    this.props.categoryId
      ? 'Campaign'
      : 'Experiment';

  showDeveloperCaseTypes = () =>
    SupportEnums.categories[this.props.categoryId].CASE_TYPE_CATEGORY ===
    SupportEnums.CaseTypeCategory.DEVELOPER;

  showOasisCaseTypes = () =>
    SupportEnums.categories[this.props.categoryId].CASE_TYPE_CATEGORY ===
    SupportEnums.CaseTypeCategory.OASIS;

  showRolloutsCaseTypes = () =>
    SupportEnums.categories[this.props.categoryId].CASE_TYPE_CATEGORY ===
    SupportEnums.CaseTypeCategory.FREE_ROLLOUTS;

  showWebCaseTypes = () =>
    !this.showDeveloperCaseTypes() &&
    !this.showOasisCaseTypes() &&
    !this.showRolloutsCaseTypes();

  getNextStepHandler = caseType => () => {
    const { setCaseType, switchStepHandler } = this.props;
    setCaseType(caseType);
    switchStepHandler(SupportEnums.steps.REQUEST_FORM);
  };

  goToPreviousStep = () => {
    const { switchStepHandler } = this.props;
    switchStepHandler(SupportEnums.steps.CHOOSE_CATEGORY);
  };

  render() {
    const {
      EXPERIMENT_SETUP,
      EDITOR_LOADING,
      GOALS,
      ACCOUNT_SETTINGS,
      EXPERIMENT_QA,
      INTEGRATIONS,
      RESULTS,
      OTHER,
      REST_API,
      CODE_SAMPLES,
      DOCUMENTATION_ERRORS,
      SDK_INSTALLATION,
      SDK_TROUBLESHOOTING,
    } = SupportEnums.caseType;
    return (
      <React.Fragment>
        <Fieldset testSection="choose-case-type">
          <p className="weight--bold">
            Which of these best describe your issue?
          </p>
          {this.showWebCaseTypes() && (
            <ButtonGrid
              buttons={[
                {
                  label: (
                    <React.Fragment>
                      {this.showCampaignOrExperimentText()}
                      <br />
                      Setup
                    </React.Fragment>
                  ),
                  testSection: 'web-experiment-setup-case-type',
                  onClick: this.getNextStepHandler(EXPERIMENT_SETUP),
                },
                {
                  label: 'Editor Loading',
                  onClick: this.getNextStepHandler(EDITOR_LOADING),
                },
                {
                  label: 'Metrics',
                  onClick: this.getNextStepHandler(GOALS),
                },
                {
                  label: 'Account Settings',
                  onClick: this.getNextStepHandler(ACCOUNT_SETTINGS),
                },
                {
                  label: `${this.showCampaignOrExperimentText()} QA`,
                  onClick: this.getNextStepHandler(EXPERIMENT_QA),
                  testSection: 'rest-api-case-type',
                  addExtraLineBreak: true,
                },
                {
                  label: 'Integrations',
                  onClick: this.getNextStepHandler(INTEGRATIONS),
                },
                {
                  label: 'Results',
                  onClick: this.getNextStepHandler(RESULTS),
                },
                {
                  label: 'Other',
                  onClick: this.getNextStepHandler(OTHER),
                },
              ]}
            />
          )}
          {this.showDeveloperCaseTypes() && (
            <ButtonGrid
              buttons={[
                {
                  label: 'Rest API',
                  onClick: this.getNextStepHandler(REST_API),
                  testSection: 'developer-rest-api-case-type',
                },
                {
                  label: 'Integrations',
                  onClick: this.getNextStepHandler(INTEGRATIONS),
                },
                {
                  label: 'Code Samples',
                  onClick: this.getNextStepHandler(CODE_SAMPLES),
                },
                {
                  label: 'Documentation Errors',
                  onClick: this.getNextStepHandler(DOCUMENTATION_ERRORS),
                },
                {
                  label: 'Other',
                  onClick: this.getNextStepHandler(OTHER),
                },
              ]}
            />
          )}
          {this.showOasisCaseTypes() && (
            <ButtonGrid
              buttons={[
                {
                  label: (
                    <React.Fragment>
                      Experiment
                      <br />
                      Setup
                    </React.Fragment>
                  ),
                  onClick: this.getNextStepHandler(EXPERIMENT_SETUP),
                  testSection: 'oasis-experiment-setup-case-type',
                },
                {
                  label: 'SDK Installation',
                  onClick: this.getNextStepHandler(SDK_INSTALLATION),
                },
                {
                  label: 'Experiment QA',
                  onClick: this.getNextStepHandler(EXPERIMENT_QA),
                },
                {
                  label: 'Results',
                  onClick: this.getNextStepHandler(RESULTS),
                },
                {
                  label: 'SDK Troubleshooting',
                  onClick: this.getNextStepHandler(SDK_TROUBLESHOOTING),
                },
                {
                  label: 'Other',
                  onClick: this.getNextStepHandler(OTHER),
                },
              ]}
            />
          )}
          {this.showRolloutsCaseTypes() && (
            <ButtonGrid
              buttons={[
                {
                  label: 'SDK Installation',
                  onClick: this.getNextStepHandler(SDK_INSTALLATION),
                },
                {
                  label: 'SDK Troubleshooting',
                  onClick: this.getNextStepHandler(SDK_TROUBLESHOOTING),
                },
                {
                  label: 'Other',
                  onClick: this.getNextStepHandler(OTHER),
                },
              ]}
            />
          )}
        </Fieldset>
        <Footer>
          <ButtonRow
            rightGroup={[
              <Button
                key="back"
                style="outline-reverse"
                testSection="choose-case-type-back-button"
                onClick={this.goToPreviousStep}>
                Back
              </Button>,
            ]}
          />
        </Footer>
      </React.Fragment>
    );
  }
}

// Adaptive Audience Statuses
// https://github.com/optimizely/optimizely/blob/23f58a0c2370c415dd471dbc8363ba9ac7b36a22/src/www/models/audiences.py#L76
export const AdaptiveAudienceStatuses = {
  AVAILABLE: 'available', // ADA condition(s) exist on Audience and all of these have corresponding Interest Groups that are available.
  NONE: 'none', // No ADA conditions exist on Audience
  UNAVAILABLE: 'unavailable', // ADA condition(s) exist on Audience and at least one of these has a corresponding Interest Group that is unavailable.
};

// Different audience match types.
export const audienceMatchTypes = {
  ANY_AUDIENCES: 'any_audiences',
  ALL_AUDIENCES: 'all_audiences',
  CUSTOM_AUDIENCES: 'custom_audiences',
};

// Audience compatibility types.
export const audienceCompatility = {
  ALL: 'all',
  OPTIMIZELY_CLASSIC: 'v1',
  OPTIMIZELY_X: 'v2',
};

// Types of operators that can be used in audience_condition code
export const ConditionGroupTypes = {
  OR: 'or',
  AND: 'and',
  NOT: 'not',
};

// These are event properties for events stored in a users local event store (localstorage events).
export const eventProperties = {
  NAME: 'n',
  TYPE: 'y',
  CATEGORY: 'c',
  METADATA: 'o',
  REVENUE: 'r',
  TIMESTAMP: 't',
};

// Different datatypes supported by tags.
export const metadataTagTypes = {
  CUSTOM: 'custom',
  INTEGER: 'integer', // this is deprecated.
  NUMBER: 'number',
  STRING: 'string',
};

// Different aggregation types for complex behavioral rules.
export const aggregationTypes = {
  MOST_FREQUENT: 'most_frequent',
  MOST_RECENT: 'most_recent',
  SUM: 'sum',
  AVG: 'avg',
};

// Optimizely Tracking Template
export const optimizelyTrackingTemplate =
  '{lpurl}?opti_ca={campaignid}&opti_ag={adgroupid}&opti_ad={creative}&opti_key={targetid}';

export const status = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export default {
  AdaptiveAudienceStatuses,
  audienceMatchTypes,
  audienceCompatility,
  ConditionGroupTypes,
  eventProperties,
  metadataTagTypes,
  aggregationTypes,
  optimizelyTrackingTemplate,
  status,
};

import PropTypes from 'prop-types';
import React from 'react';

import { DropdownListItem, DropdownBlockLink } from 'react_components/dropdown';

class StatusOption extends React.Component {
  static propTypes = {
    actionId: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onStatusClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  onClick = () => {
    const { onStatusClick, actionId } = this.props;

    onStatusClick(actionId);
  };

  render() {
    const { value, isSelected, actionId } = this.props;

    return (
      <DropdownListItem hideOnClick={true}>
        <DropdownBlockLink
          onClick={this.onClick}
          testSection={`status-${actionId}-option`}>
          <span className={isSelected ? 'color--base' : ''}>{value}</span>
        </DropdownBlockLink>
      </DropdownListItem>
    );
  }
}

export default StatusOption;

/**
 * [config]
 * [config.forceValue] {boolean} force the input value to be a valid time format
 * @param {Element|jQueryObject} inputEl
 * @param {Object=} config
 * @param {Function} callback for after the value is set
 * @constructor
 */
const $ = require('jquery');

const TimeInput = function(inputEl, config, callback) {
  config = config || {};
  const forceValue = config.forceValue || false;
  const ENTER_KEY = 13;

  this.input = $(inputEl);
  this.input.on('blur', this.parseTime.bind(this, forceValue, callback));
  this.input.on('keypress', event => {
    if (event.keyCode === ENTER_KEY) {
      this.parseTime(forceValue, callback);
    }
  });
};

/**
 * Parses the current value and normalizes into time string
 *
 * @param {boolean} forceValue - clears the value if its not a valid time string
 * @param {Function} callback - callback called with the new value
 */
TimeInput.prototype.parseTime = function(forceValue, callback) {
  const val = this.input.val() && this.input.val().trim();
  const timeObj = tr.date(val, 'LT');
  let newValue;

  if (forceValue && !timeObj.isValid()) {
    // clears the value if its not a valid time string
    newValue = '';
  } else {
    newValue = timeObj.format('LT');
  }

  this.input.val(newValue);
  if (callback) {
    callback(newValue);
  }
};

module.exports = TimeInput;

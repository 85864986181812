/**
 * Automatic conversion of user-friendly names to keys
 * e.g. "User Friendly Name Conversion" turns into "user_friendly_name_conversion"
 *
 * @author Yeeland Chen (yeeland.chen@optimizely.com)
 */

import regexUtils from 'optly/utils/regex';

function userFriendlyNameToKey(userFriendlyName) {
  return userFriendlyName.replace(regexUtils.variableNameDisallowedChars, '_');
}

export default userFriendlyNameToKey;

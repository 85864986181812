import enums from './enums';

/**
 * Human readable translations of enums
 */
export const POLICY_TYPES = {
  [enums.POLICY_TYPES.OVERLAPPING]: tr('Overlapping'),
  [enums.POLICY_TYPES.RANDOM]: tr('Mutually Exclusive'),
};

export default {
  POLICY_TYPES,
};

import keyMirror from 'optly/utils/key_mirror';

export const LANGUAGES = keyMirror({
  ANDROID: null,
  BROWSER: null,
  C: null,
  GO: null,
  JAVA: null,
  NODE: null,
  PHP: null,
  PYTHON: null,
  REACT: null,
  RUBY: null,
  SWIFT: null,
});

export const LANGUAGE_OPTIONS = [
  { value: LANGUAGES.REACT, label: 'JavaScript (React)' },
  { value: LANGUAGES.NODE, label: 'JavaScript (Node)' },
  { value: LANGUAGES.BROWSER, label: 'JavaScript (Browser)' },
];

export const LANGUAGE_OPTIONS_NOT_AVAILABLE = [
  {
    value: LANGUAGES.ANDROID,
    label: 'Android',
    link: 'https://docs.developers.optimizely.com/rollouts/docs/android',
  },
  {
    value: LANGUAGES.C,
    label: 'C#',
    link: 'https://docs.developers.optimizely.com/rollouts/docs/c',
  },
  {
    value: LANGUAGES.GO,
    label: 'Go',
    link: 'https://docs.developers.optimizely.com/rollouts/docs/go',
  },
  {
    value: LANGUAGES.JAVA,
    label: 'Java',
    link: 'https://docs.developers.optimizely.com/rollouts/docs/java',
  },
  {
    value: LANGUAGES.PHP,
    label: 'PHP',
    link: 'https://docs.developers.optimizely.com/rollouts/docs/php',
  },
  {
    value: LANGUAGES.PYTHON,
    label: 'Python',
    link: 'https://docs.developers.optimizely.com/rollouts/docs/python',
  },
  {
    value: LANGUAGES.RUBY,
    label: 'Ruby',
    link: 'https://docs.developers.optimizely.com/rollouts/docs/ruby',
  },
  {
    value: LANGUAGES.SWIFT,
    label: 'Swift',
    link: 'https://docs.developers.optimizely.com/rollouts/docs/swift',
  },
];

/**
 * Each item of the array is a separate step w/ in the format:
 * [ <Instruction>, <Code Snippet>, <Syntax Highlighting Language>, [<Help Link>{ text, helpLinkFeatureVariable }] ]
 * which is consumed in the Features Quickstart's `renderCodeStep` method.
 */
export const SNIPPETS = {
  [LANGUAGES.REACT]: (sdkKey, featureKey) => [
    [
      `Install the SDK`,
      `npm install --save @optimizely/react-sdk`,
      null,
      [
        {
          text: 'Install React SDK',
          helpLinkFeatureVariable: 'help_install_react',
        },
      ],
    ],
    [
      `Import and Initialize the SDK`,
      `import React from 'react';
import {
  createInstance,
  OptimizelyFeature,
  OptimizelyProvider,
} from '@optimizely/react-sdk';

const optimizely = createInstance({
  sdkKey: '${sdkKey}'
});`,
      'jsx',
      [
        {
          text: 'Initalize React SDK',
          helpLinkFeatureVariable: 'help_init_react',
        },
      ],
    ],
    [
      `Wrap your application with OptimizelyProvider and use the OptimizelyFeature component anywhere you want to use a feature flag`,
      `class App extends React.Component {
  render() {
    return (
      <OptimizelyProvider
        optimizely={optimizely}
        user={{
          id: 'user123',
        }}
      >
        <OptimizelyFeature feature="${featureKey}">
          {(enabled) => (
            enabled ? 'Feature is ON!' : 'Feature is off.'
          )}
        </OptimizelyFeature>
      </OptimizelyProvider>
    );
  }
}`,
      'jsx',
      [
        {
          text: 'OptimizelyProvider',
          helpLinkFeatureVariable: 'help_optimizely_provider_react',
        },
        {
          text: 'OptimizelyFeature',
          helpLinkFeatureVariable: 'help_optimizely_feature_react',
        },
      ],
    ],
  ],
  [LANGUAGES.NODE]: (sdkKey, featureKey) => [
    [
      `Install the SDK`,
      `npm install --save @optimizely/optimizely-sdk`,
      null,
      [
        {
          text: 'Install Node SDK',
          helpLinkFeatureVariable: 'help_install_node',
        },
      ],
    ],
    [
      `Import and Initialize the SDK`,
      `var optimizelySdk = require('@optimizely/optimizely-sdk');
var optimizelyClientInstance = optimizelySdk.createInstance({
  sdkKey: '${sdkKey}'
});`,
      'js',
      [
        {
          text: 'Initalize Node SDK',
          helpLinkFeatureVariable: 'help_init_node',
        },
      ],
    ],
    [
      `Implement a Feature Flag`,
      `optimizelyClientInstance.onReady().then(() => {
  var enabled = optimizelyClientInstance.isFeatureEnabled('${featureKey}', 'user123');
  if (enabled) {
    console.log('Feature is ON!');
  } else {
    console.log('Feature is off.');
  }
});`,
      'js',
      [
        {
          text: 'Is Feature Enabled Node',
          helpLinkFeatureVariable: 'help_feature_enabled_node',
        },
      ],
    ],
  ],
  [LANGUAGES.BROWSER]: (sdkKey, featureKey) => [
    [
      'Install the SDK & Datafile',
      `<script src="https://unpkg.com/@optimizely/optimizely-sdk@3.5/dist/optimizely.browser.umd.min.js"></script>
<script src="https://cdn.optimizely.com/datafiles/${sdkKey}.json/tag.js"></script>`,
      'html',
      [
        {
          text: 'Install JavaScript SDK',
          helpLinkFeatureVariable: 'help_install_browser',
        },
      ],
    ],
    [
      'Initialize the SDK',
      `var optimizelyClientInstance = window.optimizelySdk.createInstance({
  datafile: window.optimizelyDatafile,
});`,
      'js',
      [
        {
          text: 'Initialize JavaScript SDK',
          helpLinkFeatureVariable: 'help_init_browser',
        },
      ],
    ],
    [
      'Implement a Feature Flag',
      `var enabled = optimizelyClientInstance.isFeatureEnabled('${featureKey}', 'user123');
if (enabled) {
  console.log('Feature is ON!');
} else {
  console.log('Feature is off.');
}`,
      'js',
      [
        {
          text: 'Is Feature Enabled JavaScript',
          helpLinkFeatureVariable: 'help_feature_enabled_browser',
        },
      ],
    ],
  ],
};

export default {
  LANGUAGES,
  LANGUAGE_OPTIONS,
  LANGUAGE_OPTIONS_NOT_AVAILABLE,
  SNIPPETS,
};

/**
 * Third Party Targeting Condition Group Component
 * This component extends components/audience/targeting/condition_group to provide specific logic to render a third-party condition
 *
 * @author Peng-Wen Chen (peng-wen@optimizely.com)
 */
const $ = require('jquery');

const flux = require('core/flux');
const AudienceBuilder = require('optly/modules/ui/audience_builder'); // eslint-disable-line
const baseComponent = require('optly/components/audience_editor/condition_group');

const Boolean = require('./condition_fields/boolean');
const KeyValue = require('./condition_fields/keyvalue');
const Range = require('./condition_fields/range');
const SearchableSelect = require('./searchable_select');
const Select = require('./condition_fields/select').default;
const SelectDetail = require('./condition_fields/select_with_detail').default;
const SelectCampaign = require('./condition_fields/select_campaign');
const SelectAdGroup = require('./condition_fields/select_ad_group');
const Text = require('./condition_fields/text');

module.exports = $.extend(true, {}, baseComponent, {
  /**
   * Condition field input components with names equal to the corresponding condition input type
   */
  components: {
    boolean: Boolean,
    keyvalue: KeyValue,
    range: Range,
    'searchable-select': SearchableSelect,
    select: Select,
    select_with_detail: SelectDetail,
    select_ad_campaign: SelectCampaign,
    select_ad_group: SelectAdGroup,
    text: Text,
  },

  data: {
    conditionConfig: {},
    conditionGroup: {},
    conditionName: '',
  },

  computed: {
    /**
     * @override
     * Instead of just checking the conditionType in condition_group component, override
     * to check the conditionConfig.input_type
     * @return {Boolean}
     */
    showValueButtons() {
      return this.conditionConfig.input_type !== 'boolean';
    },
  },

  methods: {
    /**
     * Add an empty third-party condition to the conditionGroup
     */
    addEmptyCondition() {
      this.conditionGroup.conditions.push({
        type: this.conditionType,
        name: this.conditionName,
      });
    },

    /**
     * Get the field input type used by this third party condition group
     */
    getConditionFieldInputType() {
      return this.conditionConfig.input_type || null;
    },
  },

  ready() {
    // ensure we are getting the properly formatted name in the case of key/value types
    // where the key is something like 'demandbase.watch_list.123' and we want 'demandbase.watch_list'
    this.conditionName = AudienceBuilder.fns.getThirdPartyIntegrationName(
      this.conditionGroup.conditions[0].name,
    );

    flux.bindVueValues(this, {
      conditionConfig: AudienceBuilder.getters.thirdPartyConditionsByName(
        this.conditionName,
      ),
    });
  },
});

/**
 * Rest API CRUD actions that utilize Flux as a frontend datastore
 */
import $ from 'jquery';
import _ from 'lodash';

import { getFeatureVariable } from '@optimizely/js-sdk-lab/src/actions';

import { isImmutable } from 'optly/immutable';
import flux from 'core/flux';

import ChampagneEnums from 'optly/modules/optimizely_champagne/enums';
import CacheHitTrackingActions from 'optly/modules/cache_hit_tracking/actions';

import constants from './constants';
import createRequestInfo from './api/create_request_info';
import crudActions from './crud_actions';
import fns from './fns';
import actions from './actions';
import apiActionTypes from './action_types';
import crudStubs from './crud_stubs';

/**
 * Returns an object closing over the context of the entityDef
 * Use in creation of entity module actions
 * @param {Object} entityDef
 * @returns {{ delete: *, fetch: *, fetchAll: *, fetchAllPages: *, fetchPage: *, save: * }}
 */
export default function(entityDef) {
  return {
    /**
     * Makes an apiActionTypes request to delete entity and sends the proper result
     * event into the flux system
     * @param {object} instance
     */
    delete(instance) {
      const onSuccess = onDeleteSuccess.bind(
        null,
        entityDef.entity,
        instance.id,
      );
      const onFail = onDeleteFail.bind(null, entityDef.entity, instance.id);
      if (isEntityStubbed(entityDef)) {
        return crudStubs
          .delete(entityDef, instance)
          .done(onSuccess)
          .fail(onFail);
      }
      return crudActions
        .delete(entityDef, instance)
        .done(onSuccess)
        .fail(onFail);
    },

    /**
     * Persists an entity to the database
     * @param {object} data
     * @param {object=} options - additional options
     */
    save(data, options) {
      const pojoData = isImmutable(data) ? data.toJS() : data;
      const onSuccess = onPersistSuccess.bind(this, entityDef.entity);
      const onFail = onPersistFail.bind(this, entityDef.entity, pojoData);

      flux.dispatch(apiActionTypes.API_ENTITY_PERSIST_START, {
        entity: entityDef.entity,
        data: pojoData,
      });

      if (isEntityStubbed(entityDef)) {
        return crudStubs
          .save(entityDef, pojoData, options)
          .done(onSuccess)
          .fail(onFail);
      }
      return crudActions
        .save(entityDef, pojoData, options)
        .done(onSuccess)
        .fail(onFail);
    },

    /**
     * Does a Model.fetch() generically and wraps with request caching
     * and flux-specific deferred resolve/reject functionality
     * @param {number|object} fetchParams
     * @param {boolean} force a model fetch instead of using cache
     */
    fetch(fetchParams, force) {
      return executeModelFetch(entityDef, 'fetch', fetchParams, {
        force,
        skipEvaluatingCachedData: false,
      }).then(handleApiResponse());
    },

    /**
     * Does a Model.fetchPage() generically and wraps with request caching
     * and flux-specific deferred resolve/reject functionality
     * @param {object} filters
     * @param {boolean} force a model fetch instead of using cache
     */
    fetchPage(filters, options, dataOnly = true) {
      if (!filters.$limit) {
        throw new Error(
          'fetchPage: must take a limit. Otherwise use fetchAll instead.',
        );
      }
      return executeModelFetch(
        entityDef,
        'fetchPage',
        filters,
        Object.assign({}, options, {
          skipEvaluatingCachedData: false,
        }),
      ).then(handleApiResponse(dataOnly));
    },

    /**
     * Abstraction of executing multiple fetchPage calls to retrieve all available pages.
     *
     * Does 1 or more Model.fetchPage() invocations to retreive all available pages, using
     * filters.$offset/$limit to paginate through the API based on the total record count.
     *
     * entityDef.fetchAllPagesConfig is expected to provide a request and response header
     * definition which is used to retreive the record count from the API response.
     *
     * @param {Object} filters
     * @param {string} filters.$limit
     * @param {Object=} options - Any additional options like force, skipEvaluatingCachedData, headers, etc...
     * @returns {{
     *            firstPage: <Promise>, - A Promise resolved with the first page of entities.
     *            allPages: <Promise> - A Promise resovled with all of the entities, including the first page.
     *          }}
     */
    fetchAllPages(filters, options) {
      const {
        FEATURE_KEY,
        VARIABLES,
      } = ChampagneEnums.FEATURES.fetch_all_paginated;

      let initialPageSize;
      if (filters.project_status && filters.project_platforms) {
        initialPageSize = 100;
      }
      if (!filters.$limit) {
        filters.$limit =
          getFeatureVariable(FEATURE_KEY, VARIABLES.page_size) ||
          constants.DEFAULT_PAGE_SIZE;
      }

      const pageSize = initialPageSize || filters.$limit;
      if (!initialPageSize) {
        initialPageSize =
          getFeatureVariable(FEATURE_KEY, VARIABLES.initial_page_size) ||
          constants.INITIAL_PAGE_SIZE;
      }
      const initialPageDelta = pageSize - initialPageSize;
      filters.$offset = 0;
      options = Object.assign(options || {}, {
        headers: {
          [constants.fetchAllPagesConfig.requestHeader]: true,
          ...(filters.project_status && { 'Cache-Control': 'no-cache' }),
        },
      });
      const initialFilters = Object.assign({}, filters, {
        $limit: initialPageSize,
      });

      const allPages = $.Deferred();
      const firstPage = this.fetchPage(initialFilters, options, false).then(
        ({ data, responseHeaders }) => {
          const recordCount =
            responseHeaders[
              constants.fetchAllPagesConfig.responseHeader.toLowerCase()
            ];
          const remainingPages = Math.ceil(
            Number(recordCount - initialPageSize) / pageSize,
          );
          const remainingPagesQueue = _.times(remainingPages, index => ({
            def: $.Deferred(),
            fn: this.fetchPage.bind(
              this,
              Object.assign({}, filters, {
                $offset: pageSize * (index + 1) - initialPageDelta,
              }),
              { skipTrackingCacheHit: true },
            ),
          }));
          const parallelRequestLimit = getFeatureVariable(
            FEATURE_KEY,
            VARIABLES.concurrent_request_limit,
          );
          fns
            .processRequestQueue(remainingPagesQueue, parallelRequestLimit)
            .then(
              // Unfortunately, /support_info returns a single entity from fetchAll, so check for data to be a list.
              res =>
                allPages.resolve(
                  Array.isArray(data) ? data.concat(...res) : data,
                ),
              allPages.reject,
            );
          return data;
        },
      );

      return { firstPage, allPages };
    },

    /**
     * Generic fetchAll function, wraps Model.fetchAll with request caching
     * and flux-specific deferred resolve/reject functionality
     * @param {object|undefined} filters
     * @param {Object|boolean|undefined} forceOrOptions Either an object with force and
     * skipEvaluatingCachedData boolean properties, or a boolean. If an object
     * is provided, those properties are used for the value of the force and
     * skipEvaluatingCachedData options. Otherwice, this argument itself is used
     * as the value of the force option, and skipEvaluatingCachedData is set to
     * false.
     *
     * When the force option is true, a model fetch is always performed and the
     * cache is ignored.
     * When the skipEvaluatingCachedData option is true, the returned deferred
     * will be resolved with null  (instead of the requested data) for cached
     * requests.
     *
     * NOTE: Passing a boolean as the second argument should be considered
     * deprecated.
     */
    fetchAll(filters, forceOrOptions) {
      let force;
      let skipEvaluatingCachedData;
      let excludeFields;
      if (_.isObject(forceOrOptions)) {
        ({ force } = forceOrOptions);
        skipEvaluatingCachedData = !!forceOrOptions.skipEvaluatingCachedData;
        excludeFields = forceOrOptions.excludeFields;
      } else {
        force = forceOrOptions;
        skipEvaluatingCachedData = false;
        excludeFields = null;
      }
      return executeModelFetch(entityDef, 'fetchAll', filters, {
        force,
        skipEvaluatingCachedData,
        excludeFields,
      }).then(handleApiResponse());
    },

    /**
     * Flush everything from the given entity cache
     *
     */
    flush() {
      return flux.dispatch(apiActionTypes.FLUSH_ENTITY_STORE, {
        entity: entityDef.entity,
      });
    },
  };
}

/**
 * Helper function to handle a response from the api.
 * @param dataOnly
 * @returns {function(...[*])}
 */
function handleApiResponse(dataOnly = true) {
  return response => (dataOnly ? response.data : response);
}

/**
 * Dispatches action when an API fetch request starts
 * @private
 *
 * @param {Immutable.Map} requestInfo with `method` and `requestArgs`
 * @param {Deferred} deferred
 */
function onFetchStart(requestInfo, deferred) {
  flux.dispatch(apiActionTypes.API_ENTITY_FETCH_START, {
    entity: requestInfo.get('entity'),
    requestInfo,
    deferred,
  });
}

/**
 * Handle a successful API response.
 *
 * Dispatch an API_ENTITY_FETCH_SUCCESS event to the system including
 * the request response data and the requestInfo object
 *
 * Return the response data and headers.
 * @private
 *
 * @param {Immutable.Map} requestInfo with `method` and `requestArgs`
 * @param {object} response
 * @param {object|array} response.data
 * @param {object} response.responseHeaders
 * @return {object} response
 */
function onFetchSuccess(requestInfo, response) {
  flux.dispatch(apiActionTypes.API_ENTITY_FETCH_SUCCESS, {
    ...response,
    entity: requestInfo.get('entity'),
    requestInfo,
    fetchTimestamp: actions.getCurrentDateTimeAsISOString(),
  });
  return response;
}

/**
 * Dispatch an API_ENTITY_FETCH_FAIL event to the system
 * @private
 *
 * @param {Immutable.Map} requestInfo with `method` and `requestArgs`
 * @param {string} reason
 */
function onFetchFail(requestInfo, reason) {
  flux.dispatch(apiActionTypes.API_ENTITY_FETCH_FAIL, {
    entity: requestInfo.get('entity'),
    reason,
    requestInfo,
  });
  return reason;
}

/**
 * Dispatch an API_ENTITY_PERSIST_SUCCESS event to the system
 * @private
 *
 * @param {string} entity
 * @param {object|array} instance
 */
function onPersistSuccess(entity, instance) {
  /* @TODO(will): Remove the null check workaround once the custom_attributes API stops sending null instead of the resulting object */
  if (instance) {
    flux.dispatch(apiActionTypes.API_ENTITY_PERSIST_SUCCESS, {
      entity,
      data: instance,
    });
  }
  return instance;
}

/**
 * Dispatch an API_ENTITY_PERSIST_FAIL event to the system
 * @private
 *
 * @param {string} entity
 * @param {object} data of the instance
 * @param {string} reason
 */
function onPersistFail(entity, data, reason) {
  flux.dispatch(apiActionTypes.API_ENTITY_PERSIST_FAIL, {
    entity,
    reason,
    data,
  });
  return reason;
}

/**
 * Dispatch an API_ENTITY_DELETE_SUCCESS event to the system
 * @private
 *
 * @param {string} entity
 * @param {number} id
 */
function onDeleteSuccess(entity, id) {
  flux.dispatch(apiActionTypes.API_ENTITY_DELETE_SUCCESS, {
    entity,
    id,
  });
}

/**
 * Sees if a request is already in progress and returns the
 * in progress deferred
 * @param {Immutable.Map} args
 * @return {Deferred|undefined}
 */
function getRequestInProgress(requestInfo) {
  return flux.evaluate(['apiRequestInProgress', requestInfo]);
}

/**
 * Calls to the flux entityCache instead of making an ajax request to the REST API
 * The result of this is indistinguishable between making a real API call given that the
 * data hasn't changed server-side
 * @param {Object} entityDef
 * @param {number} id
 * @return {Object|undefined}
 */
function simulateApiFetch(entityDef, id) {
  return flux.evaluateToJS([
    ['entityCache', entityDef.entity],
    entityMap => entityMap.get(id),
  ]);
}

/**
 * Calls to the flux entityCache instead of making an ajax request to the REST API
 * The result of this is indistinguishable between making a real API call given that the
 * data hasn't changed server-side
 * @param {Object} entityDef
 * @param {Object} filters
 * @return {Array}
 */
function simulateApiFetchPage(entityDef, filters) {
  return flux.evaluateToJS([
    ['entityCache', entityDef.entity],
    entityMap => fns.getPage(entityMap, entityDef, filters),
  ]);
}

/**
 * Calls to the flux entityCache instead of making an ajax request to the REST API
 * The result of this is indistinguishable between making a real API call given that the
 * data hasn't changed server-side
 * @param {Object} entityDef
 * @param {Object?} filters
 * @return {Array}
 */
function simulateApiFetchAll(entityDef, filters) {
  return flux.evaluateToJS([
    ['entityCache', entityDef.entity],
    entityMap => fns.getAll(entityMap, entityDef, filters),
  ]);
}

/**
 * Helper Function that calls all model fetch* functions based on when fetchAll is explicitly called
 * with a cache wrapper
 * @param {Model} entityDef
 * @param {string} method
 * @param {number|object} idOrFilters - The identifying information for the fetch* API calls.
 *                                      in the case of fetch it should be just a number (id)
 *                                      in the case of fetchPage/fetchAll, it should be a map of filters
 * @param {Object} requestInfo - requestInfo as typically supplied to executeModelFetch
 * @param {Object} requestOptions - Additional options to pass to the Api requester, like headers, params, etc...
 *
 * @return {Deferred}
 */
function doFetch(entityDef, method, idOrFilters, requestInfo, requestOptions) {
  let deferred = $.Deferred();

  const onSuccess = onFetchSuccess.bind(null, requestInfo);
  const onFail = onFetchFail.bind(null, requestInfo);

  // if it isn't cached just do a model fetch*
  if (isEntityStubbed(entityDef)) {
    // TODO test the stubbed codepath
    const stubDef = $.Deferred();
    onFetchStart.call(null, requestInfo, stubDef);
    deferred = crudStubs[method](entityDef, idOrFilters, requestOptions)
      .done(onSuccess)
      .fail(onFail)
      .done(stubDef.resolve)
      .fail(stubDef.reject);
  } else {
    deferred = crudActions[method](entityDef, idOrFilters, requestOptions)
      .done(onSuccess)
      .fail(onFail);
    onFetchStart.call(null, requestInfo, deferred);
  }

  return deferred;
}
/**
 * Function that calls all model fetch* functions
 * with a cache wrapper
 * @param {Model} model
 * @param {string} method
 * @param {number|object} idOrFilters - The identifying information for the fetch* API calls.
 *                                      in the case of fetch it should be just a number (id)
 *                                      in the case of fetchPage/fetchAll, it should be a map of filters
 * @param {Object} options
 * @param {boolean} options.force - bypass of the cache layer and always fetch a result from apiActionTypes
 * @param {boolean} options.skipEvaluatingCachedData - If true, returned deferred will not be resolved with
 * data when the response is cached (instead it will be resolved with null)
 * @param {Object} options.headers - a map of additional headers to pass to the API requester.
 * @param {Object} options.params - a map of additional query parameters to pass to the API requester.
 *
 * @return {Deferred}
 */
function executeModelFetch(entityDef, method, idOrFilters, options) {
  const { force, skipEvaluatingCachedData, excludeFields } = options;
  const { headers } = options;
  const { entity } = entityDef;
  // mapping of model.fetch* methods to their corresponding store methods
  const fetchMethodMap = {
    fetch() {
      return simulateApiFetch(entityDef, idOrFilters);
    },
    fetchPage() {
      return simulateApiFetchPage(entityDef, idOrFilters);
    },
    fetchAll() {
      return simulateApiFetchAll(entityDef, idOrFilters);
    },
  };
  // check whether we need to refresh a locally cached entity - do it explicitly if we are doing a fetchAll
  const shouldRefreshLocalCache =
    actions.isCachedLocally(entity) &&
    fns.isTrueFetchAll(entityDef, method, idOrFilters);

  const requestInfo = createRequestInfo(
    entity,
    method,
    idOrFilters,
    shouldRefreshLocalCache,
  );

  const requestOptions = { headers, excludeFields };

  const storeMethod = fetchMethodMap[method];
  if (!storeMethod) {
    throw new Error(`Invalid fetch method ${method}`);
  }

  const requestInProgress = getRequestInProgress(requestInfo);
  if (requestInProgress) {
    return requestInProgress;
  }

  let def;
  let fetchMethod;

  const {
    isCached,
    responseHeaders: cachedResponseHeaders,
  } = actions.requestCacheData(requestInfo);
  const fetchResult = {
    responseHeaders: cachedResponseHeaders ? cachedResponseHeaders.toJS() : {},
  };

  // if the request is cached in flux, dont make again
  if (!force && isCached) {
    if (!options.skipTrackingCacheHit) {
      CacheHitTrackingActions.trackCacheHit(entity);
    }
    // TODO: should we just stick with jQuery deferred here for consistency?
    def = $.Deferred();
    fetchMethod = fetchMethodMap[method];
    if (skipEvaluatingCachedData) {
      def.resolve(fetchResult);
    } else {
      def.resolve({ ...fetchResult, data: fetchMethod() });
    }
    return def;
  }

  // TODO(FE-944) Only accept actions.isCachedLocally(entity) value and persist to storage when there are no filters
  // if the request is cached in localStore, return that immediately but proceed fetching in the background
  if (!force && actions.isCachedLocally(entity)) {
    if (!options.skipTrackingCacheHit) {
      CacheHitTrackingActions.trackCacheHit(entity);
    }
    def = $.Deferred();
    fetchMethod = fetchMethodMap[method];
    if (skipEvaluatingCachedData) {
      def.resolve(fetchResult);
    } else {
      def.resolve({ ...fetchResult, data: fetchMethod() });
    }
    doFetch(entityDef, method, idOrFilters, requestInfo, requestOptions);
    return def;
  }

  return doFetch(entityDef, method, idOrFilters, requestInfo, requestOptions);
}

/**
 * Dispatch an API_ENTITY_DELETE_FAIL event to the system
 * @private
 *
 * @param {string} entity
 * @param {number} id
 * @param {string} reason
 */
function onDeleteFail(entity, id, reason) {
  flux.dispatch(apiActionTypes.API_ENTITY_DELETE_FAIL, {
    entity,
    id,
    reason,
  });
}

/**
 * returns whether the entity is stubbed or not
 */
function isEntityStubbed(entityDef) {
  return (
    flux.evaluate(['apiStubs', 'stubAll']) ||
    flux.evaluate(['apiStubs', 'entityData', entityDef.entity])
  );
}

import React, { useEffect, useMemo } from 'react';
import { useGetters } from 'core/ui/methods/connect_getters';

import EventGetters from 'optly/modules/entity/event/getters';
import ViewGetters from 'optly/modules/entity/view/getters';

import { fetchEventsForSelectedMetrics } from '../../component_module/actions';

import { EventsAndMetricsBuilderProps } from '../../events_and_metrics_builder';

import ConfigureMetrics from './ConfigureMetrics';

export interface ConfigureMetricsWithDataProps
  extends EventsAndMetricsBuilderProps {}

const ConfigureMetricsWithData = ({
  currentProjectId,
  disablePrimaryMetricEdit,
  isDisabled,
  onChange,
  selectedMetrics,
}: ConfigureMetricsWithDataProps) => {
  const { eventEntityCache } = useGetters({
    eventEntityCache: EventGetters.entityCache,
  }) as { eventEntityCache: Immutable.Map<string, any> };

  const { viewEntityCache } = useGetters({
    viewEntityCache: ViewGetters.entityCache,
  }) as { viewEntityCache: Immutable.Map<string, any> };

  useEffect(() => fetchEventsForSelectedMetrics(selectedMetrics), [
    // Only fetch when metrics are added or removed
    selectedMetrics.map(({ event_id }) => event_id).join(),
  ]);

  return (
    <div className="soft-half--top">
      <ConfigureMetrics
        currentProjectId={currentProjectId}
        disablePrimaryMetricEdit={disablePrimaryMetricEdit}
        eventEntityCache={eventEntityCache}
        viewEntityCache={viewEntityCache}
        isDisabled={isDisabled}
        onChange={onChange}
        selectedMetrics={selectedMetrics}
      />
    </div>
  );
};

export default ConfigureMetricsWithData;

import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'optly/immutable';

import ExperimentationGroupConstants from 'optly/modules/entity/experimentation_group/constants';

import { ProgressBar, Table } from 'optimizely-oui';

import EntityRow from './entity_row';
import ExperimentPicker from './experiment_picker';

const ExclusionGroupSettings = props => {
  const hasEntities = !!props.group.get('entities').size;

  return (
    <fieldset data-test-section="exclusion-group-settings">
      <ol className="lego-form-fields">
        {hasEntities && (
          <li className="lego-form-field__item">
            <Table
              testSection="mutex-entity-table"
              density="tight"
              style="rule"
              tableLayoutAlgorithm="auto">
              <Table.THead>
                <Table.TR>
                  <Table.TH>{tr('Name')}</Table.TH>
                  <Table.TH isNumerical={true}>
                    {tr('Traffic Allocation')}
                  </Table.TH>
                  <Table.TH>{tr('Status')}</Table.TH>
                </Table.TR>
              </Table.THead>
              {props.group.get('entities').map((entity, index) => (
                <EntityRow
                  canEditGroup={props.canEditGroup}
                  entity={entity}
                  group={props.group}
                  handleRemovedExperiment={props.handleRemovedExperiment}
                  isCustomProject={props.isCustomProject}
                  key={`group-dialog-entity-row-${index}`}
                />
              ))}
            </Table>
          </li>
        )}
        <ExperimentPicker
          canEditGroup={props.canEditGroup}
          group={props.group}
          isCustomProject={props.isCustomProject}
        />
        <li className="lego-form-field__item">
          {hasEntities && (
            <ProgressBar
              topLabel={tr('Group Traffic Allocation')}
              leftLabel={tr('Allocated traffic')}
              min={ExperimentationGroupConstants.ZERO_TRAFFIC_ALLOCATION}
              max={ExperimentationGroupConstants.MAX_TRAFFIC_ALLOCATION}
              progress={
                ExperimentationGroupConstants.MAX_TRAFFIC_ALLOCATION -
                props.group.get(
                  'available_traffic',
                  ExperimentationGroupConstants.MAX_TRAFFIC_ALLOCATION,
                )
              }
              rightLabel={tr('Available traffic')}
            />
          )}
        </li>
      </ol>
    </fieldset>
  );
};

ExclusionGroupSettings.propTypes = {
  canEditGroup: PropTypes.bool.isRequired,
  group: PropTypes.instanceOf(Immutable.Map).isRequired,
  isCustomProject: PropTypes.bool.isRequired,
};

export default ExclusionGroupSettings;

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';
import LayerExperimentGetters from 'optly/modules/entity/layer_experiment/getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

export const liveVariableUsagesWithStatuses = [
  baseEntityGetters.entityCache,
  LayerExperimentGetters.entityCache,
  (variablesUsages, layerExperiments) =>
    variablesUsages
      .map(_variableUsage => {
        let experimentStatus = null;
        if (_variableUsage.get('experiment_id')) {
          const layerExperiment = layerExperiments
            .filter(
              experiment =>
                experiment.get('id') === _variableUsage.get('experiment_id'),
            )
            .first();
          if (layerExperiment) {
            experimentStatus = layerExperiment.get('status');
          }
        }
        return _variableUsage.set('experiment_status', experimentStatus);
      })
      .toList(),
];

export default {
  ...baseEntityGetters,
  liveVariableUsagesWithStatuses,
};

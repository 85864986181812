import parseQueryParams from 'optly/utils/parse_query_params';
import windowEnv from 'optly/window';

/**
 *
 * @param {Function} handler - The handler to call when popstate is fired.
 * @returns {Function} unlisten function
 */
export function addPopStateListener(handler) {
  const fn = e => {
    if (e && e.singleSpa) {
      // Ignore SingleSpa's extraneous popstate events
      // More info here: https://github.com/single-spa/single-spa/pull/532
      return;
    }
    return handler(e);
  };
  windowEnv.addEventListener('popstate', fn);
  return () => windowEnv.removeEventListener('popstate', fn);
}

/**
 * @name replaceState
 * @description
 *  Helper function to use our conventions of replace state
 *  API mirrors https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState
 *
 * @param {String} path (uri)
 * @param {String} title (optional) Defaults to document.title
 * @param {String} url (optional) Defaults to the path provided
 */
export function replaceState(path, title = document.title, url) {
  window.history.replaceState({ path }, title, url || path);
}

/**
 * @name pushState
 * @description
 *  Helper function to use our conventions of push state
 *  API mirrors https://developer.mozilla.org/en-US/docs/Web/API/History/pushState
 *
 * @param {String} path (uri)
 * @param {String} title (optional) Defaults to document.title
 * @param {String} url (optional) Defaults to the path provided
 */
export function pushState(path, title = document.title, url) {
  window.history.pushState({ path }, title, url || path);
}

/**
 * @name back
 * @description
 *  Calls window.history.back() method
 */
export function back() {
  window.history.back();
}

/**
 * @name pushStateAndAddAndRemoveQueryParams
 * @description
 *  Calls push state after adding and removing specified params
 * @param {Object} paramsToAdd
 * @param {Array} paramKeysToRemove
 */
export function pushStateAndAddAndRemoveQueryParams(
  paramsToAdd,
  paramKeysToRemove,
) {
  const newUrl = this.__addAndRemoveQueryParams(paramsToAdd, paramKeysToRemove);
  this.pushState(newUrl);
}

/**
 * @name pushStateAndAddQueryParams
 * @description
 *  Calls push state with the same path but adds some query params
 * @param {Object} params
 * @return {String}
 */
export function pushStateAndAddQueryParams(params) {
  const newUrl = this.__addQueryParams(params);
  this.pushState(newUrl);
}

/**
 * @name pushStateAndRemoveQueryParams
 * @description
 *  Calls push state with the same path but removes some query params
 * @param {Array} toRemove
 */
export function pushStateAndRemoveQueryParams(toRemove) {
  const newUrl = this.__removeQueryParams(toRemove);
  this.pushState(newUrl);
}

/**
 * @name replaceStateAndAddQueryParams
 * @description
 *  Calls replace state with the same path but adds some query params
 * @param {Object} params
 * @return {String}
 */
export function replaceStateAndAddQueryParams(params) {
  const newUrl = this.__addQueryParams(params);
  this.replaceState(newUrl);
}

/**
 * @name replaceStateAndRemoveQueryParams
 * @description
 *  Calls replace state with the same path but removes some query params
 * @param {Array} toRemove
 */
export function replaceStateAndRemoveQueryParams(toRemove) {
  const newUrl = this.__removeQueryParams(toRemove);
  this.replaceState(newUrl);
}

/**
 * @name __removeQueryParams
 * @description
 *  Private function but exported for testing. Given a window.location and an array of query params, return a new path string
 * @param {Array} toRemove
 * @param {Object} windowLocation (optional) Defaults to window.location
 */
export function __removeQueryParams(
  toRemove,
  windowLocation = window.location,
) {
  const { pathname, href } = windowLocation;
  const queryParams = parseQueryParams(href);

  toRemove.forEach(param => {
    delete queryParams[param];
  });

  const newSearch = this.buildSearch({ ...queryParams });

  return pathname + newSearch;
}

/**
 * @name __addQueryParams
 * @description
 *  Private function but exported for testing. Given a window.location and an array of query params, return a new path string
 * @param {Object} params
 * @param {Object} windowLocation (optional) Defaults to window.location
 */
export function __addQueryParams(params, windowLocation = window.location) {
  const { pathname, href } = windowLocation;
  const queryParams = parseQueryParams(href);
  const newSearch = this.buildSearch({
    ...queryParams,
    ...params,
  });
  return pathname + newSearch;
}

/**
 * @name __addAndRemoveQueryParams
 * @description
 *  Private function but exported for testing. Given params to be added, an array of query param keys to remove,
 *  and a window.location, return a new path string
 * @param {Object} paramsToAdd
 * @param {Array} paramKeysToRemove
 * @param {Object} windowLocation (optional) Defaults to window.location
 */
export function __addAndRemoveQueryParams(
  paramsToAdd,
  paramKeysToRemove,
  windowLocation = window.location,
) {
  const { pathname, href } = windowLocation;
  const paramsFromCurrentUrl = parseQueryParams(href);
  const paramsToAddClone = { ...paramsToAdd };

  (paramKeysToRemove || []).forEach(param => {
    delete paramsFromCurrentUrl[param];
    delete paramsToAddClone[param];
  });

  const newSearch = this.buildSearch({
    ...paramsFromCurrentUrl,
    ...paramsToAddClone,
  });

  return pathname + newSearch;
}

/**
 * @name buildSearch
 * @description
 *  Provided an object, query params will be turned into a string able to be used in window.location.search
 * @example
 *  buildSearch({
 *     id: [1, 2, 3, 4],
 *     type: 'experiment',
 *     limit: 20,
 *     show_all: true,
 *     before: null,
 *  }) => '?id=1&id=2&id=3&id=4&type=experiment&limit=20&show_all=true&before=null';
 *
 * @param {Object} paramsObj
 *
 * @returns {String}
 */
export function buildSearch(paramsObj) {
  if (!Object.keys(paramsObj).length) {
    return '';
  }

  const searchParamsList = Object.entries(paramsObj).reduce(
    (acc, [paramKey, paramValue]) => {
      if (Array.isArray(paramValue) && !paramValue.length) {
        return acc;
      }
      if (Array.isArray(paramValue)) {
        return [
          ...acc,
          paramValue
            .map(
              paramSubValue =>
                `${paramKey}=${encodeURIComponent(paramSubValue)}`,
            )
            .join('&'),
        ];
      }
      return [...acc, `${paramKey}=${encodeURIComponent(paramValue)}`];
    },
    [],
  );

  return searchParamsList.length ? `?${searchParamsList.join('&')}` : '';
}

export default {
  __addQueryParams,
  __removeQueryParams,
  __addAndRemoveQueryParams,
  addPopStateListener,
  back,
  buildSearch,
  pushState,
  pushStateAndAddAndRemoveQueryParams,
  pushStateAndAddQueryParams,
  pushStateAndRemoveQueryParams,
  replaceState,
  replaceStateAndAddQueryParams,
  replaceStateAndRemoveQueryParams,
};

import Nuclear from 'nuclear-js';
import Immutable from 'optly/immutable';

import actionTypes from '../action_types';

export default Nuclear.Store({
  getInitialState() {
    return Immutable.Map({
      selectedTimezone: null,
      gmtTimezone: false,
    });
  },

  initialize() {
    this.on(actionTypes.SET_SELECTED_TIMEZONE, setSelectedTimezone);
    this.on(actionTypes.SET_GMT_TIMEZONE, setGMTTimezone);
  },
});

/**
 *
 * @param {Number} state
 * @param {Object} payload
 * @param {String} payload.timezone
 */
function setSelectedTimezone(state, payload) {
  return state.set('selectedTimezone', payload.timezone);
}

/**
 *
 * @param {Number} state
 * @param {Object} payload
 * @param {String} payload.gmtTimezone
 */
function setGMTTimezone(state, payload) {
  return state.set('gmtTimezone', payload.gmtTimezone);
}

import Immutable from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'core/ui/decorators';
import { TrackingProvider } from '@optimizely/segment-js/dist/components';
import { virtualPageview } from '@optimizely/segment-js/dist/decorators';

import ui from 'core/ui';

import {
  actions as LinksActions,
  getters as LinksGetters,
} from 'optly/modules/entity/links';
import JiraIntegrationActions from 'optly/modules/jira_integration/actions';
import JiraIntegrationGetters from 'optly/modules/jira_integration/getters';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { getters as LoadingGetters } from 'core/modules/loading';
import ProjectEnums from 'optly/modules/entity/project/enums';

import { Jira } from '@optimizely/optly-components';
import LoadingOverlay from 'react_components/loading_overlay';

const LINKS_LOADING_ID = 'jiraLinksLoading';

/*
 * A Link (optly/modules/entity/link) represents the relationship between a Jira
 * entity and an Optimizely entity. This component is for creating or deleting
 * Links between Optimizely entities and Jira issues.
 */
@virtualPageview('Features', 'Link Jira Issue')
class JiraIssuesPicker extends React.Component {
  static propTypes = {
    currentProjectId: PropTypes.number.isRequired,
    jiraIntegrationSettings: PropTypes.instanceOf(Immutable.Map).isRequired,
    links: PropTypes.instanceOf(Immutable.List).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    source: PropTypes.instanceOf(Immutable.Map).isRequired,
    sourceType: PropTypes.oneOf(['feature', 'experiment', 'campaign'])
      .isRequired,
  };

  onSave = (linksToCreate, linkIdsToDelete) => {
    const { currentProjectId, source, sourceType } = this.props;

    const linkSaves = linksToCreate.map(linkToCreate =>
      LinksActions.save(
        Object.assign(linkToCreate, {
          owner_type: 'project',
          owner_id: currentProjectId,
          project_id: currentProjectId,
          source: {
            type: sourceType,
            resource_id: source.get('id'),
          },
        }),
      ),
    );

    const linkDeletes = linkIdsToDelete.map(id => LinksActions.delete({ id }));

    const linksUpdatedPromise = Promise.all(linkSaves.concat(linkDeletes)).then(
      () => {
        const { onSave } = this.props;
        onSave();
      },
    );
    ui.loadingWhen(LINKS_LOADING_ID, linksUpdatedPromise);
    return linksUpdatedPromise;
  };

  render() {
    const { jiraIntegrationSettings } = this.props;

    const jiraLinkProps = {
      ...this.props,
      onSave: this.onSave,
      searchFunctions: {
        byKey: JiraIntegrationActions.searchByKey,
        byProject: JiraIntegrationActions.searchByProject,
        byText: JiraIntegrationActions.searchByText,
      },
      tenantId: jiraIntegrationSettings.get('resource_id'),
      tenantUrl: jiraIntegrationSettings.get('tenant_url'),
    };

    return <Jira.Link {...jiraLinkProps} />;
  }
}

@connect(({ source, sourceType }) => {
  const isFullstackExperiment =
    sourceType === 'experiment' &&
    source.get('platform') === ProjectEnums.project_types.FULL_STACK;

  return {
    currentProjectId: CurrentProjectGetters.id,
    links: LinksGetters.linksForSourceId(
      source.get('id'),
      isFullstackExperiment,
    ),
    recentIssues: JiraIntegrationGetters.recentIssues,
    loadingRecentIssues: LoadingGetters.isLoading('jira-integration-fetch'),
    jiraIntegrationSettings: CurrentProjectGetters.jiraIntegrationSettings,
  };
})
class JiraIssuesPickerConnected extends React.Component {
  static propTypes = {
    currentProjectId: PropTypes.number.isRequired,
    jiraIntegrationSettings: PropTypes.instanceOf(Immutable.Map).isRequired,
    loadingRecentIssues: PropTypes.bool,
    source: PropTypes.instanceOf(Immutable.Map).isRequired,
    sourceType: PropTypes.oneOf(['feature', 'experiment', 'campaign'])
      .isRequired,
  };

  static defaultProps = {
    loadingRecentIssues: false,
  };

  render() {
    const { jiraIntegrationSettings, sourceType, source } = this.props;

    return (
      <LoadingOverlay loadingId={LINKS_LOADING_ID}>
        <TrackingProvider
          properties={{
            sourceType,
            sourceId: source.get('id'),
            experimentType: null, // this will be relevant when we support linking more than features,
            jiraTenantUrl: jiraIntegrationSettings.get('tenant_url'),
          }}>
          <JiraIssuesPicker {...this.props} />
        </TrackingProvider>
      </LoadingOverlay>
    );
  }
}

export default JiraIssuesPickerConnected;

import Immutable from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';
import sprintf from 'sprintf';

import featureEnums from 'bundles/p13n/sections/features_old/section_module/enums';

import { fns as ProjectFns } from 'optly/modules/entity/project';

// components
import { Code } from 'optimizely-oui';

const FeatureCodeBlock = props => {
  const renderCodeBlock = () => {
    const featureKey = props.feature.get('api_name');
    const variables = props.feature.get('variables');
    let codeBlock = sprintf(
      featureEnums.FEATURE_CODE_BLOCKS[props.language],
      featureKey,
    );

    if (variables.size) {
      variables.forEach((variable, index) => {
        const variableKey = variable.get('api_name');
        const type = variable.get('type');
        codeBlock += `\n${sprintf(
          featureEnums.VARIABLE_CODE_BLOCKS[props.language][type],
          variableKey,
          featureKey,
          variableKey,
        )}`;
      });
    }
    return codeBlock;
  };

  const syntaxHighlightingLanguage = ProjectFns.getSyntaxHighlightingLanguage(
    props.language,
  );
  return (
    <div>
      <div className="push-quad--bottom">
        <div className="push--bottom">
          <h3>Feature Flag Code</h3>
        </div>
        <p>
          Use the code below to determine whether this feature flag is enabled
          and access variables in the SDK.
        </p>
      </div>
      <div>
        <Code
          isHighlighted={true}
          hasCopyButton={true}
          language={syntaxHighlightingLanguage}
          type="block"
          testSection="feature-code-block">
          {renderCodeBlock()}
        </Code>
      </div>
    </div>
  );
};

FeatureCodeBlock.propTypes = {
  feature: PropTypes.instanceOf(Immutable.Map).isRequired,
  language: PropTypes.string.isRequired,
};

export default FeatureCodeBlock;

import flux from 'core/flux';

import actionTypes from './action_types';

const PAGE_LOAD = 'PAGE LOAD';
const V2_REGEX = /v2(\/?)(\?|$)/;

/**
 * Gets performance mark if window.performance API exists and mark is set
 *  - See MDN docs for more info: https://developer.mozilla.org/en-US/docs/Web/API/Performance/mark
 *
 * @param {String} name
 *
 * @return {Number|null} start time of performance mark, or null if not set or window.performance API does not exist
 */
export function getPerformanceMark(name) {
  if (!window.performance) {
    return null;
  }

  const performanceMark = window.performance.getEntriesByName(name)[0];
  if (performanceMark) {
    return performanceMark.startTime;
  }

  return null;
}

/**
 * Returns the startTime for the latest performance mark
 * if window.performance API exists and mark is set
 *  - See MDN docs for more info: https://developer.mozilla.org/en-US/docs/Web/API/Performance/mark
 *
 * @param {String} name
 *
 * @return {Number|null} start time of performance mark, or null if not set or window.performance API does not exist
 */
export function getLatestPerformanceMark(name) {
  if (!window.performance) {
    return null;
  }

  // Return the last performance mark
  const performanceMark = window.performance
    .getEntriesByName(name)
    .slice(-1)[0];
  if (performanceMark) {
    return performanceMark.startTime;
  }

  return null;
}

export function setFrontendRenderStartTime() {
  flux.dispatch(
    actionTypes.PERFORMANCE_TRACKING_SET_FRONTEND_RENDER_START_TIME,
  );
}

/**
 * Sets performance mark if window.performance API exists
 *  - See MDN docs for more info: https://developer.mozilla.org/en-US/docs/Web/API/Performance/mark
 *
 * @param {String} name
 *
 * @return {Number|null} start time of performance mark or null if window.performance API does not exist
 */
export function setPerformanceMark(name) {
  if (!window.performance) {
    return null;
  }

  const performanceMark = window.performance.mark(name);

  if (performanceMark) {
    return performanceMark.startTime;
  }

  return null;
}

/**
 * Clear performance mark for given name if window.performance API exists
 *  - See MDN docs for more info: https://developer.mozilla.org/en-US/docs/Web/API/Performance/clearMarks
 *
 * @param {String} name
 *
 * @return {null}
 */
export function clearPerformanceMark(name) {
  if (!window.performance) {
    return null;
  }

  window.performance.clearMarks(name);

  return null;
}

/**
 * Records the time when route change was initiated in a Flux store and as a performance mark
 *
 * @return {null}
 * */
export function setRouteStartTime() {
  flux.dispatch(actionTypes.PERFORMANCE_TRACKING_SET_ROUTE_START_TIME);
  // In addition to storing, also set a performance mark for the latest route change
  this.setPerformanceMark('route_start');
}

/**
 * Records the time when route change was initiated in a Flux store and as a performance mark
 * Additionally records user visible page changes using visual_routes_changed
 * If routed to /v2 on initial page load, no visual changes happen
 * so do not consider as visual route changes
 * @param {string} fromPath path from which this route was targeted
 * @param {string} toPath path to which this route was targeted
 *
 * @return {null}
 * */
export function trackRouteComplete({ fromPath, toPath }) {
  flux.dispatch(actionTypes.PERFORMANCE_TRACKING_ROUTE_COMPLETE);
  this.setPerformanceMark('route_complete');
  if (!(fromPath === PAGE_LOAD && toPath.match(V2_REGEX))) {
    flux.dispatch(actionTypes.PERFORMANCE_TRACKING_VISUAL_ROUTES_CHANGED);
  }
}

export default {
  getPerformanceMark,
  getLatestPerformanceMark,
  setFrontendRenderStartTime,
  setPerformanceMark,
  clearPerformanceMark,
  setRouteStartTime,
  trackRouteComplete,
};

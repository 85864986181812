const flux = require('core/flux');
const ui = require('core/ui').default;

const CurrentlyEditingPlugin = require('bundles/p13n/sections/plugin_builder/modules/currently_editing_plugin');
const EditorIframe = require('bundles/p13n/modules/editor_iframe');
const SelectorInput = require('bundles/p13n/components/selector_input').default;

const createPluginConfigField = require('./create_plugin_config_field');

const template = require('./selector.html');

module.exports = createPluginConfigField({
  template,

  methods: {
    onChange(newValue) {
      this.updateValue(newValue);
    },
    onEditStart() {
      CurrentlyEditingPlugin.actions.startEditingSelector(
        this.inputConfig.name,
      );
      // only change mode if iframe is set and not in variation editor
      if (this.iframe && this.iframe.id && !this.inVariationEditor) {
        EditorIframe.actions.setMode(
          this.iframe.id,
          EditorIframe.enums.IFrameModeTypes.STANDARD,
        );
      }
    },
    onEditStop() {
      CurrentlyEditingPlugin.actions.stopEditingSelector();
      // only change mode if not in variation editor
      if (this.iframe && this.iframe.id && !this.inVariationEditor) {
        EditorIframe.actions.setMode(
          this.iframe.id,
          EditorIframe.enums.IFrameModeTypes.NO_HOVER,
        );
      }
    },
  },

  ready() {
    flux.bindVueValues(this, {
      iframe: EditorIframe.getters.iframeComponent(this.activeFrameId),
    });

    ui.renderReactComponent(this, {
      component: SelectorInput,
      el: this.$$.selectorInput,
      dataBindings: {
        iframe: EditorIframe.getters.iframeComponent(this.activeFrameId),
        isDisabled: CurrentlyEditingPlugin.getters.isSelectorDisabled(
          this.inputConfig.name,
        ),
        value: CurrentlyEditingPlugin.getters.formInputValue(
          this.inputConfig.name,
        ),
      },
      props: {
        // Extension selectors should only be highlighted when hovering, so override the highlighter options with null values
        currentSelectorHighlightOptions: { selector: null, dataOptlyId: null },
        onChange: this.onChange.bind(this),
        onEditStart: this.onEditStart.bind(this),
        onEditStop: this.onEditStop.bind(this),
      },
    });
  },
});

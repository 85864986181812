import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);
export const currentBillingInfo = [
  baseEntityGetters.entityCache,
  billingInfos => {
    if (billingInfos.count() > 1) {
      throw new Error('There should be at most one billing info in store.');
    }
    return billingInfos.first();
  },
];

/**
 * Retrieves the currently fetched Billing Info object in the entity cache.
 */
export default Object.assign(baseEntityGetters, {
  currentBillingInfo,
});

/**
 * The component displays a banner with an extract of the list of users viewing this experiment.
 * @component
 */

const Link = require('optimizely-oui').Link;
const Typography = require('optimizely-oui').Typography;
const Attention = require('optimizely-oui').Attention;

const Immutable = require('optly/immutable').default;

const PropTypes = require('prop-types');
const React = require('react');
const ConcurrentEditingDialog = require('./concurrent_editing_dialog');

class ConcurrentEditingBanner extends React.Component {
  static propTypes = {
    /** The list of users currently editing this experiment. */
    users: PropTypes.instanceOf(Immutable.List),
  };

  static defaultProps = {
    users: Immutable.List(),
  };

  state = {
    /** Whether the dialog is visible or not. */
    showDialog: false,
  };

  toggleDialog = () =>
    this.setState(prev => ({
      showDialog: !prev.showDialog,
    }));

  render() {
    const { showDialog } = this.state;
    const { users } = this.props;

    const component = (
      <Attention
        type="brand"
        className="push-double--bottom push--top"
        data-test-section="concurrent-editing-banner">
        <div>
          {showDialog ? (
            <ConcurrentEditingDialog
              users={users}
              onClose={this.toggleDialog}
            />
          ) : null}
          <div className="flex--1 soft--right soft--left">
            <Typography type="body">
              Other users are currently editing this experiment.{' '}
              <Link
                href="#"
                data-test-section="concurrent-editing-banner__more-information"
                onClick={this.toggleDialog}>
                More info
              </Link>
            </Typography>
          </div>
        </div>
      </Attention>
    );
    return users.size ? component : null;
  }
}

module.exports = ConcurrentEditingBanner;

/**
 * Poptip directive
 * http://optimizely.github.io/lego-docs/#poptips-39
 *
 * WARNING: this file is currently being used from the p13n_inner bundle.
 * Currently, this means we SHOULD NOT use any ES7+ syntax/functions and need to be very careful
 * about the dependencies referenced in this module.
 *
 * @author Cheston Lee
 */

import $ from 'jquery';
import poptipUtil from 'optly/utils/poptip';
import tr from 'optly/translate';

const ARROW_CLASS_TEMPLATE = 'lego-pop--tip--arrow-';

const DEFAULT_OPTIONS = {
  container: 'body',
  content: null,
  dir: 'top-center',
  template: '<div class="lego-pop lego-pop--tip"></div>',
};

const exported = {
  _showPoptip() {
    const arrowLocationClassName = poptipUtil.getArrowDirection(
      this.config.dir,
    );

    // Remove any existing poptips if they weren't removed, for example if the mouse events weren't recorded properly.
    this._hidePoptip.bind(this);

    // Update the content since it may load dynamically.
    this.config.content = tr.variable(this.el.getAttribute('data-content'));

    // Only attach the poptip to the page if it is not-empty.
    if (this.config.content) {
      this.$poptip = $(this.config.template);
      this.$poptipContainer = $(this.config.container);
      this.$poptip.addClass(ARROW_CLASS_TEMPLATE + arrowLocationClassName);

      // We add the "data-poptip" attribute here to ensure that every poptip has the attribute, so that we can later
      // detach all poptips on mouseleave/click. This is an extra defense to ensure that poptips get removed if mouse
      // events aren't properly recorded, which was happening on certain SVGs in IE11.
      this.$poptip.attr('data-poptip', '');

      // Add poptip to the DOM
      this.$poptipContainer.append(this.$poptip);

      this.$poptip.html(this.config.content);

      this.offset = poptipUtil.getPoptipOffset(
        this.$el,
        this.$poptip,
        this.$poptipContainer,
        this.config.dir,
      );

      this.$poptip.css({
        left: this.offset.left,
        top: this.offset.top,
        opacity: 1,
      });
    }
  },

  _hidePoptip() {
    // Remove all poptips from the DOM.
    $('*[data-poptip]').detach();
  },

  bind() {
    this.$el = $(this.el);

    this.config = {
      container:
        this.el.getAttribute('data-container') || DEFAULT_OPTIONS.container,
      content: this.el.getAttribute('data-content') || DEFAULT_OPTIONS.content,
      dir: this.el.getAttribute('data-dir') || DEFAULT_OPTIONS.dir,
      template:
        this.el.getAttribute('data-template') || DEFAULT_OPTIONS.template,
    };

    this.$el.on('mouseenter', this._showPoptip.bind(this));
    this.$el.on('mouseleave click', this._hidePoptip.bind(this));
  },

  unbind() {
    // Remove event handlers.
    $(this.el).off('mouseenter mouseleave click');
    // Remove existing poptips if they remained on the DOM for some reason.
    this._hidePoptip.bind(this);
  },
};

export default exported;

export const { _showPoptip, _hidePoptip, bind, unbind } = exported;

/**
 * Pure functions related to Live Variables
 */

import {
  LIVE_VARIABLE_TYPES,
  LIVE_VARIABLE_TYPE_HUMAN_READABLES,
} from './enums';

const DEFAULTS = {
  id: null,
  archived: false,
  api_name: null,
  default_value: 'true',
  description: null,
  project_id: null,
  type: LIVE_VARIABLE_TYPES.boolean,
};

/**
 * Creates an empty live variable entity object extended with the supplied data
 * @param {Object} data
 * @return {Object}
 */
export const createLiveVariableEntity = data => ({
  ...DEFAULTS,
  ...data,
});

/**
 * Return whether a string value from an input for a variable of the given type
 * is valid
 * @param {String} value
 * @param {String} variableType
 * @return {Boolean}
 */
export const isVariableValueInputValid = (value, variableType) => {
  if (typeof value !== 'string') {
    return false;
  }

  switch (variableType) {
    case LIVE_VARIABLE_TYPES.boolean:
      return value === 'true' || value === 'false';

    case LIVE_VARIABLE_TYPES.double:
      return value.length > 0 && !/[^0-9\-.]/.test(value);

    case LIVE_VARIABLE_TYPES.integer:
      return value.length > 0 && !/[^0-9-]/.test(value);

    case LIVE_VARIABLE_TYPES.string:
      return value !== '';

    default:
      return true;
  }
};

/**
 * Returns the default variable value for the given type
 * @param  {string} type
 * @return {mixed} Return the default value. Can be boolean, integer, float, string.
 */
export const getDefaultValueForType = type => {
  if (type === LIVE_VARIABLE_TYPES.boolean) {
    return 'false';
  }
  if (type === LIVE_VARIABLE_TYPES.integer) {
    return '0';
  }
  if (type === LIVE_VARIABLE_TYPES.double) {
    return '0.0';
  }

  return '';
};

export const getLiveVariablesHumanReadable = type =>
  LIVE_VARIABLE_TYPE_HUMAN_READABLES[type];

export default {
  createLiveVariableEntity,
  getLiveVariablesHumanReadable,
  getDefaultValueForType,
  isVariableValueInputValid,
};

import React from 'react';

import NavConstants from 'optly/services/navigation';

import RoutingFns from '../../routing_fns';
import TryRolloutsPage from './pages/try_rollouts';

/*
 * Routes for the try_rollouts section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/try-rollouts',
    handle: [
      ...RoutingFns.standardNavHandlers(NavConstants.NavWidth.HIDDEN),
      RoutingFns.parseProjectId,
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Try Rollouts',
          showProjectName: false,
        }),
      () => {
        RoutingFns.renderMainRegion(<TryRolloutsPage />);
      },
    ],
  },
];

export default routes;

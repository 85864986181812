import actions from './actions';
import actionTypes from './action_types';
import getters from './getters';
import fns from './fns';

export { actions, actionTypes, getters, fns };

export default {
  actions,
  actionTypes,
  fns,
  getters,
};

/**
 * @author Daniel O'Connor (daniel@optimizely.com)
 */
const $ = require('jquery');

const ARROW_WIDTH = 12;
const ARROW_HEIGHT = 6;
const DEFAULT_MARGIN = 20;

/**
 * Parse the readable name and translate it into the appropriate LEGO classname.
 *
 * @param {String} direction data-dir from the tip directive
 * @return {String} The appropriate LEGO class name to apply to the poptip
 */
const getArrowDirection = function(direction) {
  if (!direction) {
    return 'bottom-center';
  }

  const directionClassMap = {
    'top-left': 'bottom-left',
    'top-center': 'bottom-center',
    'top-right': 'bottom-right',
    'bottom-left': 'top-left',
    'bottom-center': 'top-center',
    'bottom-right': 'top-right',
    'left-bottom': 'left-bottom',
    'left-center': 'left-center',
    'left-top': 'left-top',
    'right-top': 'right-top',
    'right-center': 'right-center',
    'right-bottom': 'right-bottom',
    right: 'left-center',
    left: 'right-center',
    top: 'bottom-center',
    bottom: 'top-center',
  };

  return directionClassMap[direction];
};

/**
 * Get the offset for the tooltip's absolute position
 *
 * @param {Object} jQuery object the poptip is attached to (i.e. the button that opens the poptip)
 * @param {Object} jQuery object of the poptip content
 * @param {Object} jQuery ojbect of the poptip DOM container (the element that the poptip should positioned absolutely to)
 * @param {String} Direction that the poptip should be placed
 * @param {Bool} preventOffScreen Prevent the poptip going off screen
 * @return {Object} The poptip's offset
 */
const getPoptipOffset = function(
  activator,
  poptip,
  container,
  direction,
  preventOffScreen,
  margin,
) {
  let offset = {
    left: 0,
    top: 0,
  };
  let containerOffset = {
    left: 0,
    top: 0,
  };
  margin = margin || DEFAULT_MARGIN;

  if (container && activator.offset()) {
    containerOffset = container.offset();
    offset = activator.offset();

    offset.left -= containerOffset.left;
    offset.top -= containerOffset.top;
  }

  const arrowWidth = ARROW_WIDTH;
  const arrowHeight = ARROW_HEIGHT;

  let left = offset.left;
  let top = offset.top;

  switch (direction) {
    case 'top-left':
      top -= poptip.outerHeight(true) + arrowHeight;
      break;
    case 'top-center':
      left += activator.outerWidth(false) / 2 - poptip.innerWidth() / 2;
      top -= poptip.outerHeight(true) + arrowHeight;
      break;
    case 'top-right':
      left -= poptip.outerWidth(true) - activator.outerWidth(false);
      top -= poptip.outerHeight(true) + arrowHeight;
      break;
    case 'bottom-left':
      top += activator.outerHeight(false) + arrowHeight;
      break;
    case 'bottom-center':
      left += activator.outerWidth(false) / 2 - poptip.innerWidth() / 2;
      top += activator.outerHeight(false) + arrowHeight;
      break;
    case 'bottom-right':
      left -= poptip.outerWidth(true) - activator.outerWidth(false);
      top += activator.outerHeight(false) + arrowHeight;
      break;
    case 'left-bottom':
      // Unique to popover
      left += activator.outerWidth(false) + arrowWidth;
      top -= poptip.outerHeight(true) - activator.outerHeight(false);
      break;
    case 'left-center':
      // Unique to popover, equal to poptip's `right`
      left += activator.outerWidth(false) + arrowWidth;
      top += activator.outerHeight(false) / 2 - poptip.innerHeight() / 2;
      break;
    case 'left-top':
      // Unique to popover
      left += activator.outerWidth(false) + arrowWidth;
      break;
    case 'right-top':
      // Unique to popover
      left -= poptip.outerWidth(true) + arrowWidth;
      break;
    case 'right-center':
      // Unique to popover, equal to poptip's `left`
      left -= poptip.outerWidth(true) + arrowWidth;
      top += activator.outerHeight(false) / 2 - poptip.innerHeight() / 2;
      break;
    case 'right-bottom':
      // Unique to popover
      left -= poptip.outerWidth(true) + arrowWidth;
      top -= poptip.outerHeight(true) - activator.outerHeight(false);
      break;
    case 'right':
      // Unique to poptip, equal to popover's `left`
      left += activator.outerWidth(false) + arrowWidth;
      top += activator.outerHeight(false) / 2 - poptip.innerHeight() / 2;
      break;
    case 'left':
      // Unique to poptip, equal to popover's `right`
      left -= poptip.outerWidth(true) + arrowWidth;
      top += activator.outerHeight(false) / 2 - poptip.innerHeight() / 2;
      break;
    case 'top':
      // Unique to poptip
      left += activator.outerWidth(false) / 2 - poptip.innerWidth() / 2;
      top -= poptip.outerHeight(true) + arrowHeight;
      break;
    case 'bottom':
      // Unique to poptip
      left += activator.outerWidth(false) / 2 - poptip.innerWidth() / 2;
      top += activator.outerHeight(false) + arrowHeight;
      break;
  }

  if (preventOffScreen) {
    const docH = $(document).height();
    const docW = $(document).width();
    const h = poptip.outerHeight(true);
    const w = poptip.outerWidth(true);

    top = top < margin ? containerOffset.top + margin : top;
    left = left < margin ? containerOffset.left + margin : left;
    top =
      top + h > docH - margin ? docH - h - containerOffset.top - margin : top;
    left =
      left + w > docW - margin
        ? docW - w - containerOffset.left - margin
        : left;
  }

  return {
    left,
    top,
  };
};

module.exports = {
  getArrowDirection,
  getPoptipOffset,
};

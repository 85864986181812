const ENTERPRISE = 'enterprise';
const FREE_ENTERPRISE_TRIAL_X = 'free_enterprise_trial_x';

/**
 * Convert the billingInfo.plan_id into a category of plan types.
 * @param billingInfo {Object} a billing_info REST API entity
 * @returns {*} 'legacy_self_serve' if the plan is a variation of bronze, silver, or gold
 *                   'enterprise' is the plan is a variation of platinum or new enterprise
 *                   undefined if the billingInfo.plan_id is not set
 */
export function getOveragePlanType(billingInfo) {
  // Kick out if we have not loaded the billing_info entity yet
  if (!billingInfo || !billingInfo.plan_id) {
    return;
  }

  // Accounts with a trial plan see a general overage message.
  if (billingInfo.in_trial) {
    return 'trial';
  }

  // Now, we know the plan_id for the Account, so let's classify it.
  //
  // If at least one of the legacy plan prefixes is a member of the Account's plan_id, it is a legacy self serve.
  const isLegacySelfServe = ['bronze', 'silver', 'gold'].some(
    planId => billingInfo.plan_id.indexOf(planId) === 0,
  );

  // The plan is an old or new enterprise plan: enterprise-monthly or enterprise_a, for example.
  let isEnterprise = billingInfo.plan_id.indexOf(ENTERPRISE) === 0;
  // The plan is one of our custom tiers.
  isEnterprise =
    isEnterprise ||
    /c(\d)_d(\d)_i(\d)_m(\d)_s[\d]_t(\d)_.+/.exec(billingInfo.plan_id);
  isEnterprise = isEnterprise && billingInfo.overage_cents_per_visitor > 0;

  if (isLegacySelfServe) {
    return 'legacy_self_serve';
  }
  if (isEnterprise) {
    return ENTERPRISE;
  }
}

/**
 * Returns true if the plan is free enterprise trial x
 * @param {Object|Immutable.Map} billingInfo
 * @returns {boolean}
 */
export function isFreeEnterpriseTrialX(billingInfo) {
  return (
    billingInfo &&
    billingInfo.plan_id &&
    billingInfo.plan_id === FREE_ENTERPRISE_TRIAL_X
  );
}

export function hasOverageCharge(billingInfo) {
  if (!billingInfo) {
    return false;
  }

  // Double-checking that an account that has unlimited usage doesn't see a charge---ever.
  if (billingInfo.has_unlimited_usage) {
    return false;
  }

  // Edge case for free_light plans: the raw_charge_amount does not get set, so instead, we must see if the
  // usage_total (what was used) is greater than their allocation (usage_allowed).
  if (billingInfo.plan_id === 'free_light') {
    return billingInfo.usage_total > billingInfo.usage_allowed;
  }

  // This leaves us with Accounts with limits, so if the raw_charge_amount (what we're going to add as an overage)
  // is greater than 0, we should display the overage subsection.
  return billingInfo.raw_charge_amount > 0;
}

export default {
  getOveragePlanType,
  isFreeEnterpriseTrialX,
  hasOverageCharge,
};

/**
 * @author Roman Onufryk (roman@optimizely.com)
 */

import actions from './actions';

export { actions };

export default {
  actions,
};

import layerSettingsActions from './actions';
import layerSettingsActionTypes from './action_types';
import layerSettingsGetters from './getters';

export const actions = layerSettingsActions;
export const actionTypes = layerSettingsActionTypes;
export const getters = layerSettingsGetters;

export default {
  actions,
  actionTypes,
  getters,
};

import flux from 'core/flux';

import actionTypes from '../action_types';

export const stopCreatingVariation = function() {
  flux.dispatch(actionTypes.P13N_STOP_CREATING_VARIATION);
};

export default {
  stopCreatingVariation,
};

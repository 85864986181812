const $ = require('jquery');
const _ = require('lodash');
const flux = require('core/flux');

const CodeMirrorMixin = require('bundles/p13n/modules/ui/mixins/code_mirror')
  .default;
const ChangeEditorSidebarMixin = require('bundles/p13n/modules/ui/mixins/change_editor_sidebar')
  .default;
const EditorGetters = require('bundles/p13n/modules/editor/getters');
const LayerExperimentEnums = require('optly/modules/entity/layer_experiment/enums')
  .default;
const ResizableDirective = require('optly/directives/resizable');

const Template = require('./template.html');

const elementCodeComponent = {
  replace: true,

  template: Template,

  data: {
    changeStatuses: LayerExperimentEnums.ChangeStatuses,
    codeMirrorInitialized: false,
    _codeMirrors: {
      style: null,
    },
    shouldObserveCodeMirror: true,
  },

  directives: {
    resizable: ResizableDirective,
  },

  computed: {
    /**
     * Compute a map of all attribute changes and the appropriate classes to use
     * for their current status (dirty, draft, live).
     * @returns {{class: *, style: *, text: *}}
     */
    statusClassForAttributes() {
      return {
        class: this.getStatusClassForAttribute([
          this.changeStatusMap.attributes.class,
        ]),
        style: this.getStatusClassForAttribute([
          this.changeStatusMap.attributes.style,
        ]),
        text: this.getStatusClassForAttribute([
          this.changeStatusMap.attributes.text,
        ]),
      };
    },

    /**
     * Should we show text editing, a deprecated attribute change.
     * Only allow text editing if there is already a text change present.
     * @returns {boolean}
     */
    textEditingEnabled() {
      return this.currentlyEditingChange.attributes.text !== null;
    },
  },

  methods: {
    /**
     * Manually blur all input fields. Clicking inside an iframe doesn't cause
     * a focused input box to blur, so use this method to do that manually.
     * Make sure codemirror elements are initialized before trying to blur them.
     */
    blurInputFields() {
      $(this.$$.class).blur();
      $(this.$$.text).blur();

      if (this.codeMirrorInitialized) {
        this._codeMirrors.style.getInputField().blur();
      }
    },

    /**
     * Explicitly set the value of the change type input fields. Prevent change if the field is focused
     * as that usually indicates that a user is actively making changes to that field. If we were to
     * overwrite these on element change, we'd be constantly modifying the input as they type
     */
    initializeInputFields() {
      if (!$(this.$$.class).is(':focus')) {
        this.$$.class.value = this.getAttribute('class');
      }
      if (
        this.codeMirrorInitialized &&
        this._codeMirrors.style &&
        !this._codeMirrors.style.hasFocus()
      ) {
        this._codeMirrors.style.setValue(this.getAttribute('style'));
      }
      if (!$(this.$$.text).is(':focus')) {
        this.$$.text.value = this.getAttribute('text');
      }
    },
  },

  created() {
    flux.bindVueValues(this, {
      activeFrameId: EditorGetters.activeFrameId,
      changeStatusMap: EditorGetters.changeStatusMap(),
      currentlyEditingChange: EditorGetters.currentlyEditingChange,
      editingEnabled: EditorGetters.changeEditorEditingEnabled,
      isEditorReadOnly: EditorGetters.isEditorReadOnly,
    });

    this._resizableOptions = {
      handles: 'se',
      minHeight: 200,
      reset: true,
      // jQuery UI Resizable insists on setting the width inline upon resize, so override that here
      resize: this.resizeCodeMirror.bind(this),
    };
  },

  ready() {
    this.initializeInputFields();

    this.$on('displayCodeMirrorComponents', () => {
      if (!this.codeMirrorInitialized) {
        this._codeMirrors.style = this.initializeCodeMirrorField(
          this.$$.style,
          'style',
          'css',
          this.setAttribute.bind(this),
        );
        this.codeMirrorInitialized = true;
        if (this.isEditorReadOnly) {
          this.toggleCodeMirrorComponentsDisabled(true);
        }
      }
      this.initializeInputFields();
    });

    this.$on('blurInput', this.blurInputFields.bind(this));

    // Observe the currentlyEditingChange and update the change input fields when it changes. This is
    // preferred to using v-value because we can explicitly not update the field that is currently being typed in.
    this.__unwatchCurrentlyEditingChange = flux.observe(
      EditorGetters.currentlyEditingChange,
      this.initializeInputFields.bind(this),
    );

    this.$on('sidebarResizeEvent', () => {
      this.refreshCodeMirror(this._codeMirrors.style);
    });
  },

  beforeDestroy() {
    if (this.__unwatchCurrentlyEditingChange) {
      this.__unwatchCurrentlyEditingChange();
    }
  },
};

module.exports = _.merge(
  {},
  CodeMirrorMixin,
  ChangeEditorSidebarMixin,
  elementCodeComponent,
);

/**
 * Abstract controller for the event flow managing which tab is showing in a tab panel
 *
 * @author Cheston Lee
 */

const $ = require('jquery');

const service = {};

service.ACTIVE_CLASS = 'is-active';
service.TAB_RELATION_ATTR = 'data-tab-related';

/**
 * Take in a navigation element & tab content element
 * and make them active while disabling the previous active tab
 *
 * @param nav {HTMLElement} The <li> element representing the newly active tab
 * @param tab {HTMLElement} The <div> element representing the newly active tab content
 */
service.activate = function(nav, tab) {
  const $nav = $(nav);
  const $tab = $(tab);

  if ($nav.hasClass(this.ACTIVE_CLASS)) {
    return;
  }

  const currActiveNav = $nav.siblings(`.${this.ACTIVE_CLASS}`);
  const currActiveTab = $tab.siblings(`.${this.ACTIVE_CLASS}`);

  if (currActiveNav.length === 1 && currActiveTab.length === 1) {
    currActiveNav.removeClass(this.ACTIVE_CLASS);
    currActiveTab.removeClass(this.ACTIVE_CLASS);
  }

  $nav.addClass(this.ACTIVE_CLASS);
  $tab.addClass(this.ACTIVE_CLASS);
};

module.exports = service;

import { Store } from 'nuclear-js';

import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Store({
  getInitialState() {
    return toImmutable({});
  },

  initialize() {
    this.on(actionTypes.SUMMARY_LOADED, storeSummary);
  },
});

function storeSummary(state, payload) {
  return state
    .set('allowance', payload.summary.allowance)
    .set('end_date', payload.summary.end_date)
    .set('last_update_date', payload.summary.last_update_date)
    .set('plan_id', payload.summary.plan_id)
    .set('rolling_usage', payload.summary.rolling_usage_percentage)
    .set('start_date', payload.summary.start_date)
    .set('usage', payload.summary.usage)
    .set('usage_percentage', payload.summary.usage_percentage);
}

import React from 'react';
import PropTypes from 'prop-types';
import Immutable, { toImmutable } from 'optly/immutable';
import { connect } from 'core/ui/decorators';
import { BlockList, FilterPicker, Input, Pill } from 'optimizely-oui';

import SectionModuleActions from 'bundles/p13n/sections/implementation/section_module/actions';
import SectionModuleFns from 'bundles/p13n/sections/implementation/section_module/fns';
import SectionModuleGetters from 'bundles/p13n/sections/implementation/section_module/getters';
import { RecommendationsHelpLink } from 'bundles/p13n/components/messaging/recommendations';

@connect(props => ({
  selectedItemIds: SectionModuleGetters.selectedRecommenderSpecificEventIds(
    props.id,
  ),
  allItems: SectionModuleGetters.selectedEventsForSavedCatalog,
}))
class EventPicker extends React.Component {
  static propTypes = {
    allItems: PropTypes.instanceOf(Immutable.List).isRequired,
    areEventsValid: PropTypes.bool,
    description: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    renderErrorMessage: PropTypes.func.isRequired,
    selectedItemIds: PropTypes.instanceOf(Immutable.List).isRequired,
    testSection: PropTypes.string.isRequired,
  };

  addEvent = event => {
    const { id } = this.props;
    SectionModuleActions.updateCurrentlyEditingRecommenderSpecificsAddEvent(
      id,
      toImmutable(event),
    );
  };

  removeEvent = event => {
    const { id } = this.props;
    SectionModuleActions.updateCurrentlyEditingRecommenderSpecificsRemoveEvent(
      id,
      toImmutable(event),
    );
  };

  render() {
    const {
      allItems,
      areEventsValid,
      description,
      label,
      placeholder,
      renderErrorMessage,
      selectedItemIds,
      testSection,
    } = this.props;

    return (
      <div className="push-double--bottom" data-test-section={testSection}>
        <h5>{label}</h5>
        <div
          className="push--bottom"
          data-test-section={`${testSection}-description`}>
          {description}
          <RecommendationsHelpLink
            helpLink={SectionModuleFns.getHelpCopy('setup_recommender_link')}
            testSection={testSection}
          />
        </div>
        <FilterPicker
          allEntities={allItems}
          selectedEntityIds={selectedItemIds}
          keysToSearch={['name']}
          testSection={`${testSection}-filter-picker`}>
          {({ availableEntities, handleFilterInput, selectedEntities }) => (
            <React.Fragment>
              {!!selectedEntities.size && (
                <div className="push-half--top push-double--bottom">
                  {selectedEntities.map(item => (
                    <div
                      className="flex flex-align--center"
                      key={item.get('id')}>
                      <Pill
                        description={item.get('description')}
                        name={item.get('name')}
                        isDismissible={true}
                        isFullWidth={true}
                        onDismiss={() => this.removeEvent(item)}
                        showWell={false}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className="oui-filter-picker-list">
                <Input
                  isFilter={true}
                  onInput={handleFilterInput}
                  placeholder={placeholder}
                  type="search"
                />
                <BlockList hasBorder={true}>
                  <BlockList.Category>
                    {availableEntities.map(item => (
                      <FilterPicker.ListItem
                        description={item.get('description')}
                        key={item.get('id')}
                        id={item.get('id')}
                        name={item.get('name')}
                        onClick={() => this.addEvent(item)}
                        testSection={`${testSection}-event-${item.get('id')}`}
                      />
                    ))}
                  </BlockList.Category>
                </BlockList>
              </div>
            </React.Fragment>
          )}
        </FilterPicker>
        {!areEventsValid &&
          renderErrorMessage(
            'At least one event must be selected',
            'recommender-events-error-message',
          )}
      </div>
    );
  }
}

export default EventPicker;

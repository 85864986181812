import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import ui from 'core/ui';
// TODO(jordan): perhaps move this to core
import DesktopApp from 'optly/modules/desktop_app';
import CopierUtil from 'optly/utils/copier';

/**
 * Copier React Component
 * Usage:
 *
 * <Copier textToCopy='foo'>
 *   <Button>Copy Me!</Button>
 * </Copier>
 */
export default class Copier extends React.Component {
  static propTypes = {
    textToCopy: PropTypes.string.isRequired,
    onAfterCopy: PropTypes.func,
    showNotification: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    showNotification: true,
  };

  onAfterCopy = event => {
    if (this.props.showNotification) {
      ui.showNotification({
        message: tr('Copied to clipboard.'),
      });
    }
    if (this.props.onAfterCopy) {
      this.props.onAfterCopy(event);
    }
  };

  componentDidMount() {
    const el = ReactDOM.findDOMNode(this); //eslint-disable-line

    if (__DESKTOP__) {
      // If the user is on the desktop app, use Electron's clipboard module,
      // which is in exposed in window object.
      $(el).on('click', event => {
        const { textToCopy } = this.props;
        DesktopApp.modules.clipboard.writeText(textToCopy);
        this.onAfterCopy(event);
      });
    } else {
      $(el).on('click', event => {
        const { textToCopy } = this.props;
        if (CopierUtil.copyText(textToCopy)) {
          this.onAfterCopy(event);
        }
      });
    }
  }

  render() {
    return React.Children.only(this.props.children);
  }
}

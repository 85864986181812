/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';

import { Button, Label, Link, Sheet, Table } from 'optimizely-oui';

import classNames from 'classnames';

import ui from 'core/ui';
import { connect } from 'core/ui/decorators';
import Immutable from 'optly/immutable';
import UrlHelperV2 from 'optly/services/url_helper';

import {
  actions as LayerActions,
  getters as LayerGetters,
  enums as LayerEnums,
  humanReadable as LayerHumanReadable,
  fns as LayerFns,
} from 'optly/modules/entity/layer';
import { generateObjectSortFn, ASC } from 'optly/utils/sort';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { getters as DataLayerGetters } from 'bundles/p13n/modules/data_layer';

import { SortableTableHeader } from 'react_components/sortable_table';
import SelectDropdown from 'react_components/select_dropdown';

import LoadingOverlay from 'react_components/loading_overlay';
import DashboardEntityTable from 'bundles/p13n/components/entity_dashboard/entity_table';

const TABLE_ID = 'ExperimentsUsageDashboard';

@connect({
  layers: LayerGetters.entityCache,
  views: DataLayerGetters.views,
  currentProjectId: CurrentProjectGetters.id,
})
class ExperimentUsage extends React.Component {
  static propTypes = {
    currentProjectId: PropTypes.number.isRequired,
    layers: PropTypes.instanceOf(Immutable.Map).isRequired,
    selectedViewId: PropTypes.number.isRequired,
    views: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentViewId: props.selectedViewId,
      experimentsUsageAvailable: false,
    };

    LayerActions.fetchAllByStatus({
      projectId: props.currentProjectId,
      byPage: false,
      archived: false,
    }).then(() => {
      this.setState({ experimentsUsageAvailable: true });
    });
  }

  renderButtonsInFooter = () => [
    <Button
      testSection="close-button"
      style="highlight"
      key="Done"
      onClick={ui.hideDialog}>
      Done
    </Button>,
  ];

  isRunning = status => {
    return status === LayerEnums.status.RUNNING.toLowerCase();
  };

  getStatusClassName = status =>
    classNames({
      'color--good-news': this.isRunning(status),
    });

  renderViews = () => {
    const { views } = this.props;

    return views
      .toJS()
      .sort(generateObjectSortFn([{ field: 'name', type: 'string', dir: ASC }]))
      .filter(view => !view.archived)
      .map(view => {
        const experimentUsage = view.experiment_count;
        return {
          label: view.name,
          value: view.id,
          description: `Used in ${experimentUsage} experiment${
            experimentUsage > 1 ? 's' : ''
          }`,
        };
      });
  };

  renderTableRow = (layer, index) => {
    const { currentViewId } = this.state;
    const layerUrl = UrlHelperV2.campaignHome(
      layer.get('project_id'),
      layer.get('id'),
    );

    return layer.get('view_ids').includes(currentViewId) &&
      !layer.get('archived') ? (
      <Table.TR>
        <Table.TD>
          <div>
            <Link testSection={`usage-experiment${index}-name`} href={layerUrl}>
              {layer.get('name')}
            </Link>
            <div className="micro muted">{layer.get('description')}</div>
          </div>
        </Table.TD>
        <Table.TD testSection={`usage-experiment${index}-type`}>
          {LayerHumanReadable.TEXT_BY_LAYER_TYPE[layer.get('type')].name}
        </Table.TD>
        <Table.TD testSection={`usage-experiment${index}-status`}>
          <span className={this.getStatusClassName(layer.get('status'))}>
            {LayerHumanReadable.STATUS_BY_ACTUAL_STATUS[layer.get('status')]}
          </span>
        </Table.TD>
      </Table.TR>
    ) : (
      ''
    );
  };

  getExperimentsUsage = viewId => {
    const { layers } = this.props;
    const experimentUsage = layers.filter(
      layer =>
        layer.get('view_ids').includes(viewId) &&
        !layer.get('archived') &&
        !LayerFns.hasLayerConcluded(layer),
    );
    return experimentUsage.size;
  };

  renderTableHeader = () => {
    return (
      <tr>
        <SortableTableHeader field="name" type="string">
          Experiment Name
        </SortableTableHeader>
        <SortableTableHeader field="type" type="string">
          Type
        </SortableTableHeader>
        <SortableTableHeader field="status" type="string">
          Status
        </SortableTableHeader>
        <Table.TH />
      </tr>
    );
  };

  render() {
    const { layers } = this.props;
    const { currentViewId, experimentsUsageAvailable } = this.state;
    return (
      <Sheet
        onClose={ui.hideDialog}
        title="Experiment Usage"
        footerButtonList={this.renderButtonsInFooter()}>
        <LoadingOverlay isLoading={!experimentsUsageAvailable}>
          <SelectDropdown
            isFilterable={true}
            testSection="experiment-usage-view-select"
            items={this.renderViews()}
            onChange={view => {
              this.setState({ currentViewId: view });
            }}
            value={currentViewId}
            width={320}
            minDropdownWidth={320}
          />
          <div className="soft-triple--top">
            {this.getExperimentsUsage(currentViewId) ? (
              <DashboardEntityTable
                tableId={TABLE_ID}
                testSection="experiment-usage-table"
                data={layers}
                renderTableRow={this.renderTableRow}
                renderTableHeader={this.renderTableHeader}
                defaultSortBy={{ field: 'status', type: 'string', dir: 'desc' }}
              />
            ) : (
              <Label testSection="no-experiment-usage-message">
                Experiments for this page may be archived. Try another page.
              </Label>
            )}
          </div>
        </LoadingOverlay>
      </Sheet>
    );
  }
}

export default ExperimentUsage;

// DONT PUT GETTERS IN HERE
// this is because metrics live on Layers, this module shouldnt know about currentX
// it's just model specific functions and enums
import MetricConstants from './constants';
import MetricFns from './fns';

// Allows other files to import individual files from this module
export const constants = MetricConstants;
export const fns = MetricFns;

export default {
  constants,
  fns,
};

import AjaxUtil from 'optly/utils/ajax';
import enums from 'optly/utils/enums';
import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);
const MOBILE_BASE_ENDPOINT = '/mobile';

export const {
  save,
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deleteProject = baseEntityActions.delete;

export { deleteProject as delete };

/**
 * Archives a project
 * @param {Project} project
 */
export function archive(project) {
  return this.save({
    id: project.id,
    project_status: enums.ProjectStatusType.ARCHIVED,
  });
}

/**
 * Unarchives a project
 * @param {Project} project
 */
export function unarchive(project) {
  return this.save({
    id: project.id,
    project_status: enums.ProjectStatusType.ACTIVE,
  });
}

/**
 * Fetches a project via the legacy API.
 * @param {Integer} projectId
 */
export function fetchProject(projectId) {
  return AjaxUtil.makeV1AjaxRequest({
    url: `/api/projects/${projectId}/`,
    type: 'GET',
  }).then(response => response.project);
}

/**
 * Triggers a handler to email project code to a developer
 * Only works with web projects due to Marketo implementation
 * @param {String} email
 * @param {Object} project
 */
export function sendProjectCode(email, project) {
  AjaxUtil.makeV1AjaxRequest({
    url: '/project/send_code',
    type: 'POST',
    data: {
      email,
      project_id: project.id,
    },
  });
}

/**
 * Save a project name to a mobile project
 * @param {String} name The name of the project
 * @param {String} platform `ios` or `android`
 * @param {String} nextStep `install` or `email`
 * @returns {Object} A jQuery deferred object
 */
export function addProjectName(name, platform, nextStep) {
  return AjaxUtil.makeV1AjaxRequest({
    url: `${MOBILE_BASE_ENDPOINT}/add-project-name`,
    type: 'POST',
    data: {
      project_name: name,
      project_platform: platform,
      next_step: nextStep,
    },
  });
}

/**
 * Send the sdk installation instructions for the current project to the given emails
 * @param {Integer} project id that we should send the install instructions for
 * @param {Array} emails List of emails to send the instructions to
 * @param {String} string representing the custom message from sender
 * @param {String} string representing the type of email to send (ex. update or install)
 */
export function emailSDKInstructions(
  projectId,
  emails,
  customMessage,
  emailAction,
) {
  return AjaxUtil.makeV1AjaxRequest({
    url: `${MOBILE_BASE_ENDPOINT}/send-email`,
    type: 'POST',
    data: {
      project_id: projectId,
      emails,
      message_body: customMessage,
      email_action: emailAction,
    },
  });
}

export function fetchExecutiveSummary(projectId) {
  return AjaxUtil.makeV1AjaxRequest({
    url: `/executive-summary/latest_report?account_id=${projectId}`,
  });
}

/**
 * See a project has experiments that are not archived.
 * @param {Integer} projectId
 */
export function hasExperiments(projectId) {
  return AjaxUtil.makeV1AjaxRequest('/api/experiments.json', {
    data: {
      project_ids: projectId,
      status: `${enums.ExperimentStatusType.RUNNING},${enums.ExperimentStatusType.PAUSED},${enums.ExperimentStatusType.NOT_STARTED}`,
    },
    optimizelyRetryLimit: 3,
  });
}

export function saveClientBuildSettings(projectId, settings) {
  const projectPatch = {
    id: projectId,
    client_build_settings: {
      client_type: settings.client_type,
      build_server: settings.build_server || null,
      client_version: settings.client_version || null,
    },
  };
  return this.save(projectPatch);
}

export function saveCSP(projectId, enabled) {
  const projectPatch = {
    id: projectId,
    client_options: {
      'csp-support': enabled,
    },
  };

  return this.save(projectPatch);
}

export function saveExcludeInactiveGoals(projectId, enabled) {
  const projectPatch = {
    id: projectId,
    exclude_inactive_goals: enabled,
  };

  return this.save(projectPatch);
}

/**
 * Hit the (admin-only) resave_project endpoint to trigger an update of a particular project to CDN.
 * @param {string|number} projectId
 * @returns {$.Deferred}
 */
export function resave(projectId) {
  return AjaxUtil.makeV1AjaxRequest({
    url: '/tool/resave_project',
    type: 'POST',
    data: {
      account_id: projectId,
    },
  });
}

export function saveAppsBetaOptInStatus(projectId, optingIn) {
  const projectPatch = {
    id: projectId,
    opted_into_apps_beta: optingIn,
  };

  return this.save(projectPatch);
}

/**
 * Link / share project endpoint that triggers an asynchronous task to share the project and entities for the provided projectId.
 * @param {string|number} projectId
 * @returns {$.Deferred}
 */
export function share(projectId) {
  return AjaxUtil.makeV1AjaxRequest({
    url: `api/v1/projects/${projectId}/share`,
    type: 'POST',
  });
}

export default {
  ...baseEntityActions,
  addProjectName,
  archive,
  emailSDKInstructions,
  fetchExecutiveSummary,
  fetchProject,
  hasExperiments,
  unarchive,
  resave,
  saveAppsBetaOptInStatus,
  saveClientBuildSettings,
  saveCSP,
  saveExcludeInactiveGoals,
  sendProjectCode,
  share,
};

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const entityCache = baseEntityGetters.entityCache;

export const byId = baseEntityGetters.byId;

export default Object.assign(baseEntityGetters, {
  // implement getters here
});

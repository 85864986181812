/**
 * Getters for verifier
 */
export function maxVerifiedRevision(projectId) {
  return ['verifier', 'projectRecords', projectId, 'maxVerifiedRevision'];
}

export function isRequestInflight(projectId) {
  return ['verifier', 'projectRecords', projectId, 'isFetching'];
}

export function mostRecentVerificationAttempt(projectId) {
  return [
    'verifier',
    'projectRecords',
    projectId,
    'mostRecentVerificationAttempt',
  ];
}

export function versionRequests(projectId) {
  return ['verifier', 'projectRecords', projectId, 'versionRequests'];
}

export default {
  maxVerifiedRevision,
  isRequestInflight,
  mostRecentVerificationAttempt,
  versionRequests,
};

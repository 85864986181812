const PropTypes = require('prop-types');
const React = require('react');

const EmptyResults = props => (
  <div className="soft-quad--sides">
    No {props.entityPlural} match your filter. Try&nbsp;
    <a onClick={props.resetFilters} data-test-section={props.testSection}>
      resetting your filters
    </a>
    .
  </div>
);

EmptyResults.propTypes = {
  resetFilters: PropTypes.func.isRequired,
  entityPlural: PropTypes.string.isRequired,
  testSection: PropTypes.string,
};

module.exports = EmptyResults;

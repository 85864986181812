/**
 * Module specific pure functions
 *
 * @author Cheston Lee
 */
import _ from 'lodash';
import AuthFns from 'optly/modules/auth/fns';
import regex from 'optly/utils/regex';

export default {
  /**
   * Validate Account info
   * @param  {Object} accountData
   * @param  {Array} validateOnly List of parameters to validate. Use this to restrict the validation to only a subset of parameters
   */
  validateAccountInfo(accountData, validateOnly) {
    let dataValidationRules = {
      first_name: {
        msg: tr('First name is required'),
        fn(firstName) {
          return firstName.length > 0;
        },
      },
      last_name: {
        msg: tr('Last name is required'),
        fn(lastName) {
          return lastName.length > 0;
        },
      },
      email: {
        msg: tr('Invalid Email Address'),
        fn(email) {
          return regex.email.test(email);
        },
      },
      'terms-of-service': {
        msg: tr('Please accept our Terms of Service'),
        fn(val) {
          return val === 'on';
        },
      },
    };

    if (validateOnly) {
      dataValidationRules = _.pick(dataValidationRules, validateOnly);
    }

    return this.validateInfo(dataValidationRules, accountData);
  },

  /**
   * Validate user's first created password
   * @param {Object} data Contains { email, password, confirm password, create password token }
   * @return {Object} ret Contains { valid: bool, msg: string }
   */
  validateCreatePasswordInfo(data) {
    const validationRules = {
      email: {
        msg: tr('Invalid Email Address'),
        fn(email) {
          return regex.email.test(email);
        },
      },
      password: {
        msg: tr('Invalid Password'),
        fn(pass) {
          return AuthFns.checkComplexPassword(pass).passed;
        },
      },
      confirm_password: {
        msg: tr('Invalid Password'),
        fn(pass) {
          return AuthFns.checkComplexPassword(pass).passed;
        },
      },
      create_password_token: {
        msg: tr('Invalid Token'),
        fn(token) {
          return regex.token.test(token);
        },
      },
    };

    return this.validateInfo(validationRules, data);
  },

  /**
   * Validate information for resetting a user's password
   * @param {Object} data Contains { email, token, new_password1, new_password2 }
   * @return {Object} ret Contains { valid: bool, msg: string }
   */
  validateSetPasswordInfo(data) {
    const validationRules = {
      email: {
        msg: tr('Invalid Email Address'),
        fn(email) {
          return regex.email.test(email);
        },
      },
      new_password1: {
        msg: tr('Invalid Password'),
        fn(pass) {
          return AuthFns.checkComplexPassword(pass).passed;
        },
      },
      new_password2: {
        msg: tr('Invalid Password'),
        fn(pass) {
          return AuthFns.checkComplexPassword(pass).passed;
        },
      },
      token: {
        msg: tr('Invalid Token'),
        fn(token) {
          return /^[a-z0-9]+$/i.test(token);
        },
      },
    };

    return this.validateInfo(validationRules, data);
  },

  /**
   * Validate data based upon a set of rules/fns and set a rejection message if an
   * element does not pass.
   *
   * @param {Object} rules Contains { key: func, ... }
   * @param {Object} data Contains { email, token, new_password1, new_password2 }
   * @return {Object} ret Contains { valid: bool, msg: string }
   */
  validateInfo(rules, data) {
    const retVal = {
      valid: true,
      msg: '',
    };
    const keys = Object.keys(rules);

    // Ensure that we at least have the required properties for creation and call the validation method
    for (let i = 0; i < keys.length; i++) {
      if (!data.hasOwnProperty(keys[i]) || !rules[keys[i]].fn(data[keys[i]])) {
        // eslint-disable-line no-prototype-builtins
        retVal.valid = false;
        retVal.msg = rules[keys[i]].msg;
        break;
      }
    }

    return retVal;
  },
};

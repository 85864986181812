import config from 'atomic-config';

import Router from 'optly/services/url_helper_legacy';
import UrlUtil from 'optly/utils/url';

/**
 * Gets the current temporary two factor token.
 * This is the temporary session token stored on the backend.
 * @return {string}
 */
const temporaryTwoFactorToken = [
  'auth',
  'lastSuccessfulAuth',
  'two_factor_token',
];

/**
 * Gets the current TOTP code.
 * This is the six-digit code generated by the user's auth app.
 * @return {string}
 */
const totpCode = ['auth', 'totpCode'];

/**
 * Gets the password (before signin) in case user needs to signin with or configure 2 factor. Will be consumed on use.
 * @return {string}
 */
const password = ['auth', 'password'];

/**
 * Stores the email here for an intermediary state when the user hasn't fully signed in and
 * needs to supply an additional code for 2factor signin
 * @return {string}
 */
const email = ['auth', 'email'];

/**
 * Stores recoverEmail (fetched from server on /recover and /claim attempts)
 * @type {string}
 */
const recoverEmail = [() => config.get('recover_email')];

/**
 * Next auth state to enter.
 * @return {string}
 */
const nextState = ['auth', 'nextState'];

/**
 * The payload in the response from the backend upon successful
 * email/password submission.
 * @return {Object}
 */
const lastSuccessfulAuth = ['auth', 'lastSuccessfulAuth'];

/**
 * True if and only if account-level password expiration is enforced and
 * the user needs to change their password because it has expired.
 * @return {boolean}
 */
const passwordExpired = [
  'auth',
  'lastSuccessfulAuth',
  'account_enforced_password_is_expired',
];

/**
 * @return {string} The CSRF token included with in the successful email/password response
 */
const csrfToken = ['auth', 'lastSuccessfulAuth', 'csrf_token'];

/**
 * @return {string} Path to the app based on the last used version of the product, to
 *                  which an ajax request will be made the end of sign-in.
 *
 *                  Note: This likely does not need to live within a flux/NuclearJS/whatever
 *                  getter, because the value this getter produces doesn't depend on the state
 *                  of the store, since we no longer care about last_viewed_optly_version with
 *                  the deprecation of classic (AST-790).
 * @example '/v2'
 */
const postSignInPath = [[], () => UrlUtil.v2Url(Router.layersHome())];

/**
 * @return {Boolean} Whether the user is signing in for the first time
 */
const isFirstSignin = ['auth', 'lastSuccessfulAuth', 'is_first_signin'];

export {
  temporaryTwoFactorToken,
  totpCode,
  password,
  email,
  recoverEmail,
  lastSuccessfulAuth,
  nextState,
  passwordExpired,
  csrfToken,
  postSignInPath,
  isFirstSignin,
};

export default {
  temporaryTwoFactorToken,
  totpCode,
  password,
  email,
  recoverEmail,
  lastSuccessfulAuth,
  nextState,
  passwordExpired,
  csrfToken,
  postSignInPath,
  isFirstSignin,
};

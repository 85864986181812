/**
 * This copier util file allows copying of any text to the clipboard
 * It also ensures a browser is capable of copying before attempting a clipboard copy
 */

/**
 * Function for determining whether or not the user's browser is capable of copying text to clipboard
 * by the document copy command
 * @returns {boolean}
 */
exports.supportsCopyAPI = function() {
  return (
    document &&
    typeof document.queryCommandSupported === 'function' &&
    document.queryCommandSupported('copy')
  );
};

/**
 * Attempts to copy given text to clipboard.
 * Since text is required to be in the DOM to be copied, we add it and then remove it afterwards.
 * Returns true or false depending on whether copying to clipboard succeeded or not.
 * @param {string} text
 * @returns {boolean}
 */
exports.copyText = function(text) {
  if (this.supportsCopyAPI) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    const copied = document.execCommand('copy');
    document.body.removeChild(textArea);

    return copied;
  }
  return false;
};

import ui from 'core/ui';
import flux from 'core/flux';

import AdminAccountGetters from 'optly/modules/admin_account/getters';

import UpgradeDialog from 'bundles/p13n/components/dialogs/upgrade_dialog';

import fns from './fns';

/**
 * Open the reusable Upgrade Dialog component.
 * @param {String} featureLimitType - the type of feature limit, for example "NUMBER_OF_SEATS_LIMIT"
 * @param {Number|String} featureLimit - the value for the feature limit. Sometimes rendered inline in a message.
 */
export const showUpgradeDialog = (featureLimitType, featureLimit) => {
  const isFreeRolloutsPlan = flux.evaluate(
    AdminAccountGetters.isFreeRolloutsPlan,
  );
  const { message, header, buttonLink, buttonText } = fns.getUpgradeDialogCopy(
    featureLimitType,
    featureLimit,
    isFreeRolloutsPlan,
  );
  ui.showReactDialog(
    UpgradeDialog,
    {
      props: {
        header,
        message,
        buttonLink,
        buttonText,
      },
    },
    {
      isOuiDialog: true,
    },
  );
};

export default {
  showUpgradeDialog,
};

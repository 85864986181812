import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

import fns from './fns';

/**
 * The Audience Match Type UI options will show if is a Web or Full Stack Project:
 *
 * @returns {Array}
 *  Returns a getter that will evaluate to a Boolean
 */
export function shouldShowAudienceCombinationOptions() {
  return [
    CurrentProjectGetters.isWebProject,
    CurrentProjectGetters.isFullStackProject,
    fns.getShouldShowAudienceCombinationOptions,
  ];
}

export default {
  shouldShowAudienceCombinationOptions,
};

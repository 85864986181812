import {
  enums as FilterableTableEnums,
  fns as FilterableTableFns,
  getters as FilterableTableGetters,
} from 'optly/modules/filterable_table';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

export const currentProjectActiveGroups = [
  CurrentProjectGetters.experimentationGroups,
  groups =>
    FilterableTableFns.filterByArchivedStatus(
      groups,
      FilterableTableEnums.status.ACTIVE,
    ),
];

export const filteredAndSortedGroups = tableId => {
  return [
    CurrentProjectGetters.experimentationGroups,
    FilterableTableGetters.fieldFilter(tableId, 'status'),
    FilterableTableGetters.stringFilter(tableId),
    (groups, statusFilter, stringFilter) => {
      // @TODO(mng): implement sorting, reliant on OUI table to enable sorting
      const _groups = FilterableTableFns.filterByArchivedStatus(
        groups,
        statusFilter,
      );
      return FilterableTableFns.filterFieldsByString(_groups, stringFilter, [
        'name',
        'description',
      ]);
    },
  ];
};

export default {
  currentProjectActiveGroups,
  filteredAndSortedGroups,
};

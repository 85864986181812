/*
 * Takes as input the platform name of the project, and returns translated string with optional prefix added.
 * @param {string} platform Platform string
 * @param {string} useForPrefix If "true" (string because passed as Vue parameter) - then add an "for" prefix
 * @return {string} The translated string
 */
module.exports = function(platform, useForPrefix) {
  let ProjectPlatformStrings = null;
  if (useForPrefix === 'true') {
    ProjectPlatformStrings = {
      web: tr('for web'),
      ios: tr('for ios'),
      android: tr('for android'),
    };
  } else {
    ProjectPlatformStrings = {
      web: tr('web'),
      ios: tr('ios'),
      android: tr('android'),
    };
  }

  if (!ProjectPlatformStrings[platform]) {
    return tr.variable(platform);
  }

  return ProjectPlatformStrings[platform];
};

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Sheet, Icon } from 'optimizely-oui';
import {
  brandBlueDark,
  redDark,
} from '@optimizely/design-tokens/dist/json/colors.json';

import EntitySelector from 'react_components/entity_selector';

import flux from 'core/flux';
import ui from 'core/ui';
import { connect } from 'core/ui/decorators';
import Immutable from 'optly/immutable';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import EventEnums from 'optly/modules/entity/event/enums';
import PermissionsGetters from 'optly/modules/permissions/getters';

import { RecommendationsHelpLink } from 'bundles/p13n/components/messaging/recommendations';

import SectionModuleActions from 'bundles/p13n/sections/implementation/section_module/actions';
import SectionModuleConstants from 'bundles/p13n/sections/implementation/section_module/constants';
import SectionModuleFns from 'bundles/p13n/sections/implementation/section_module/fns';
import SectionModuleGetters from 'bundles/p13n/sections/implementation/section_module/getters';

const addTag = (event, item) => {
  SectionModuleActions.addTagToCurrentlyEditingEvent(item);
};

const removeTag = (event, item) => {
  SectionModuleActions.removeTagToCurrentlyEditingEvent(item);
};

const renderInfoItem = (label, value, testSection, mutedValue) => (
  <li className="oui-form-field__item flex--1" data-test-section={testSection}>
    <div className="weight--bold">{`${label}:`}</div>
    <div>
      {value}
      {mutedValue && <span className="muted push--left">{mutedValue}</span>}
    </div>
  </li>
);

@connect({
  canManageRecommendations: PermissionsGetters.canManageRecommendations,
  event: SectionModuleGetters.currentlyEditingEvent,
  eventPageName: SectionModuleGetters.currentlyEditingEventPageName,
  projectName: CurrentProjectGetters.name,
  selectedEventPageId: SectionModuleGetters.selectedEventPageId,
  selectedTags: SectionModuleGetters.selectedTags,
  unselectedTags: SectionModuleGetters.unselectedTags,
})
class EditEventTagsDialog extends React.Component {
  static propTypes = {
    canManageRecommendations: PropTypes.bool.isRequired,
    event: PropTypes.instanceOf(Immutable.Map).isRequired,
    eventPageName: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    selectedEventPageId: PropTypes.number.isRequired,
    selectedTags: PropTypes.instanceOf(Immutable.List).isRequired,
    unselectedTags: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  isCustomEvent = () => {
    const { event } = this.props;
    return (
      event.getIn(['entity', 'event_type']) === EventEnums.eventTypes.CUSTOM
    );
  };

  saveAction = () => {
    SectionModuleActions.updateForCatalog(true);
    const eventFromFlux = flux.evaluate(
      SectionModuleGetters.currentlyEditingEvent,
    );
    SectionModuleActions.updateEvent(eventFromFlux);
    ui.hideDialog();
  };

  renderCustomEventsWarning = () => (
    <div className="flex flex-align--center push-double--bottom">
      <Icon color={brandBlueDark} size="small" name="circle-exclamation" />
      <div
        className="flex--1 push--left"
        data-test-section="custom-events-warning-text">
        To use custom events, their tags must match existing tag API names for
        for an existing click or page view event.
      </div>
    </div>
  );

  renderNoTagsWarning = () => (
    <div className="flex">
      <div className="push-half--top">
        <Icon color={redDark} size="small" name="circle-exclamation" />
      </div>
      <div className="push--left" data-test-section="no-tags-warning-text">
        You currently have no tags. To add tags to your pages, go to
        Implementation > Pages, then return here to include them with your
        catalog events.
      </div>
    </div>
  );

  renderTagsForEvents = () => {
    const { selectedTags, unselectedTags } = this.props;

    const hasNoTags =
      selectedTags.size === 0 && unselectedTags.getIn([0, 'items']).size === 0;

    return (
      <>
        <h4>Include Tags</h4>
        <div
          className="push-half--top push--bottom"
          data-test-section="include-tags-description">
          Assign tags to include in this catalog event.
        </div>
        {this.isCustomEvent() && this.renderCustomEventsWarning()}
        {hasNoTags ? (
          this.renderNoTagsWarning()
        ) : (
          <EntitySelector
            inputPlaceholder="Search and add tags to this event"
            isFullWidth={true}
            selectedItems={selectedTags}
            unselectedItems={unselectedTags}
            onEntitySelected={addTag}
            onEntityUnselected={removeTag}
          />
        )}
      </>
    );
  };

  render() {
    const {
      canManageRecommendations,
      event,
      eventPageName,
      projectName,
    } = this.props;

    const formattedEventId = `(ID: ${event.get('id')})`;

    return (
      <Sheet
        footerButtonList={[
          <Button
            key="cancel"
            style="plain"
            onClick={ui.hideDialog}
            testSection="edit-event-tags-dialog-cancel-button">
            Cancel
          </Button>,
          <Button
            isDisabled={!canManageRecommendations}
            key="save"
            style="highlight"
            testSection="edit-event-tags-dialog-save-button"
            onClick={this.saveAction}>
            Save tags
          </Button>,
        ]}
        onClose={ui.hideDialog}
        subtitle={
          <React.Fragment>
            Include the tags you want to be associated with this catalog
            event.&nbsp;
            <RecommendationsHelpLink
              helpLink={SectionModuleFns.getHelpCopy('edit_events_tags_link')}
              testSection="edit-event-tags-dialog-subtitle"
            />
          </React.Fragment>
        }
        title="Edit Event Tags"
        testSection="edit-event-dialog"
        warningContent={
          !canManageRecommendations &&
          SectionModuleConstants.EDIT_WARNING_TEXTS.EVENT.message
        }
        warningTestSection={
          SectionModuleConstants.EDIT_WARNING_TEXTS.EVENT.testSection
        }>
        <ol className="flex push-quad--bottom">
          {renderInfoItem('Project', projectName, 'project-name-label')}
          {renderInfoItem('Page', eventPageName, 'event-page-name-label')}
          {renderInfoItem(
            'Event',
            event.getIn(['entity', 'name']),
            'event-name-id-label',
            formattedEventId,
          )}
        </ol>
        {this.renderTagsForEvents()}
      </Sheet>
    );
  }
}

export default EditEventTagsDialog;

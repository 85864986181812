import Immutable from 'optly/immutable';

let memoizeMap = new WeakMap();

/**
 * TEST FUNCTION ONLY - Resets memoization WeakMap for consistent test results.
 * @private
 */
export const __TESTresetMemoizeMap = () => {
  memoizeMap = new WeakMap();
};

/**
 * Memoized sort based on a Cache map with a nested key structure:
 *
 * E.g.:
 *
 *    WeakMap {
 *      datasetA<Array>: {
 *        key1: <datasetA sorted by key1>,
 *        key2: <datasetA sorted by key2>,
 *        ...
 *      },
 *      datasetB<Array>: {
 *        key1: <datasetB sorted by key1>,
 *        key3: <datasetB sorted by key3>,
 *        ...
 *      },
 *      ...
 *    }
 * @param {String} sortKey
 * @param {Immutable.List} dataset
 * @return {Immutable.List}
 */
const memoizedSort = (sortKey, dataset) => {
  if (!memoizeMap.get(dataset)) {
    memoizeMap.set(dataset, {});
  }
  const datasetMap = memoizeMap.get(dataset);

  if (!datasetMap[sortKey]) {
    datasetMap[sortKey] = dataset.sort(
      (a, b) => a.get(sortKey) - b.get(sortKey),
    );
  }

  return datasetMap[sortKey];
};

/**
 * Linear interpolation function for a list of objects.
 * The key provided specifies which field in the dataset the input searchValue applies to.
 *
 * E.g.:
 *
 *  Given the dataset:
 *  [
 *    { x: 1, y: 1, z: 1 },
 *    { x: 2, y: 3, z: 5 },
 *    { x: 3, y: 5, z: 1 },
 *  ]
 *
 * Given the key is x, a search value of 1.4 is 40% of the distance between dataset[0] and dataset[1]
 * As such, the returned object will have field values that are 40%  of the distance between dataset[0] and dataset[1]
 *
 *   {
 *     x: 1.4    // 40% between x=1 and x=3 (same as the searchValue)
 *     y: 1.8    // 40% between y=1 and y=3
 *     z: 2.6    // 40% between z=1 and z=5
 *   }
 *
 * @param {String} key - The object key to
 * @param {String} searchValue - The value of the key field used to interpolate
 * @param {Immutable.List<Immutable.Map>} dataset
 * @return {Immutable.Map} A linearly interpolated object of the same form as the dataset.
 */
export default function(key, searchValue, dataset) {
  const sortedDataset = memoizedSort(key, dataset);

  const bucket = sortedDataset.find(b => b.get(key) >= searchValue);
  if (!bucket) return sortedDataset.last();

  const idx = sortedDataset.findIndex(d => Immutable.is(d, bucket));
  if (idx === 0) return bucket;

  // Were in-between the first and last element of our dataset.
  // Time to interpolate the result!

  const prevBucket = sortedDataset.get(idx - 1);
  const prevVal = prevBucket.get(key);
  const currentVal = bucket.get(key);
  const distanceBetween = currentVal - prevVal;
  const multiplier = 1 - (searchValue - prevVal) / distanceBetween;
  const multiplierToUse = Number.isNaN(multiplier) ? 0 : multiplier;

  return bucket.mapEntries(([property, val]) => {
    const propertyDistance = val - prevBucket.get(property);
    return [property, val - multiplierToUse * propertyDistance];
  });
}

import React from 'react';

import { connect } from 'core/ui/decorators';

import PermissionsGetters from 'optly/modules/permissions/getters';
import TargetingComponent from 'bundles/p13n/components/targeting/layer_targeting_config';

import {
  actions as ComponentModuleActions,
  getters as ComponentModuleGetters,
} from '../../component_module';

@connect({
  canUseUrlTargeting: PermissionsGetters.canUseUrlTargeting,
  selectedViewIds: ComponentModuleGetters.selectedViewIds,
  targetingType: ComponentModuleGetters.targetingType,
  urlTargetingApiNameError: ComponentModuleGetters.urlTargetingApiNameError,
  urlTargetingConditionsError:
    ComponentModuleGetters.urlTargetingConditionsError,
  urlTargetingConfig: ComponentModuleGetters.urlTargetingConfig,
  urlTargetingUrlError: ComponentModuleGetters.urlTargetingUrlError,
  views: ComponentModuleGetters.allSavedViewsWithoutSingleUse,
  viewsError: ComponentModuleGetters.viewsError,
})
class BoundTargetingComponent extends React.Component {
  render() {
    return (
      <TargetingComponent
        isABExperiment={false}
        isCreating={true}
        onChangeSelectedViewIds={ComponentModuleActions.setSelectedViewIds}
        onChangeUrlTargeting={ComponentModuleActions.setUrlTargetingConfig}
        setTargetingType={ComponentModuleActions.setTargetingType}
        {...this.props}
      />
    );
  }
}

export default BoundTargetingComponent;

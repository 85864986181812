import { OverlayWrapper, Popover, Icon } from 'optimizely-oui';
import React from 'react';
import PropTypes from 'prop-types';

import Immutable from 'optly/immutable';

import { RecommendationsHelpLink } from 'bundles/p13n/components/messaging/recommendations';
import TagPicker from 'bundles/p13n/sections/implementation/components/recommendations/pickers/tag_picker';

import SectionModuleFns from 'bundles/p13n/sections/implementation/section_module/fns';

const EventTagsInfo = {
  PrimaryID: {
    title: 'Primary ID *',
    description:
      'Assign a tag as the unique identifier. This is also the key used to generate recommendations. ',
    isRequired: true,
    propertyName: 'id_tag_name',
    testSection: 'catalog-primary-id-tag-picker',
  },
  SourceURL: {
    title: 'Source URL',
    description:
      'Optionally, assign a tag for the page URL. During catalog generation, this URL will be checked to see if it is valid. ',
    isRequired: false,
    propertyName: 'url_tag_name',
    testSection: 'catalog-source-url-tag-picker',
  },
};

const AssignEventTagsPicker = ({
  selectedIdTagName,
  selectedUrlTagName,
  tagChoices,
  updateCatalogProperty,
}) => {
  const renderSingleEventTagsPicker = (eventTagsInfo, value) => (
    <div className="flex--1">
      <div className="flex flex-align--center">
        <h5 className="push-half--bottom">{eventTagsInfo.title}</h5>
        <OverlayWrapper
          horizontalAttachment="left"
          verticalAttachment="middle"
          overlay={
            <Popover testSection={`${eventTagsInfo.testSection}-description`}>
              {eventTagsInfo.description}
              <RecommendationsHelpLink
                helpLink={SectionModuleFns.getHelpCopy('setup_catalog_link')}
                testSection={eventTagsInfo.testSection}
              />
            </Popover>
          }>
          <div className="push--left">
            <Icon name="circle-question" size="small" />
          </div>
        </OverlayWrapper>
      </div>
      <TagPicker
        choices={tagChoices}
        currentlySelectedTag={value}
        isValueRequired={eventTagsInfo.isRequired}
        onChange={updateCatalogProperty}
        propertyName={eventTagsInfo.propertyName}
        testSection={eventTagsInfo.testSection}
      />
    </div>
  );

  return (
    <div
      className="push-triple--bottom"
      data-test-section="catalog-assign-event-tags">
      <h4>Assign Event Tags</h4>
      <div
        className="push-double--bottom"
        data-test-section="catalog-assign-event-tags-description">
        Choose from the event tags you added above to define a primary ID and
        source URL.
      </div>
      <div className="flex">
        {renderSingleEventTagsPicker(
          EventTagsInfo.PrimaryID,
          selectedIdTagName,
        )}
        {renderSingleEventTagsPicker(
          EventTagsInfo.SourceURL,
          selectedUrlTagName,
        )}
      </div>
    </div>
  );
};

AssignEventTagsPicker.propTypes = {
  selectedIdTagName: PropTypes.string,
  selectedUrlTagName: PropTypes.string.isRequired,
  tagChoices: PropTypes.instanceOf(Immutable.List).isRequired,
  updateCatalogProperty: PropTypes.func.isRequired,
};

export default AssignEventTagsPicker;

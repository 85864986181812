/**
 * Event helper functions
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const $ = require('jquery');

/**
 * @param {HTMLElement} element
 * @return {String|undefined} transitionEnd event name
 */
exports.getTransitionEndEvent = function(element) {
  const transitions = {
    WebkitTransition: 'webkitTransitionEnd',
    MozTransition: 'transitionend',
    OTransition: 'oTransitionEnd',
    transition: 'transitionend',
  };

  for (const t in transitions) {
    if (element.style[t] !== undefined) {
      return transitions[t];
    }
  }
};

/**
 * Create a jquery `one` listener for a cross browser compatible transitionend event
 * @param {HTMLElement} el
 * @param {Function} handler
 * @return {Function} unwatch fn
 */
exports.transitionEndOnce = function(el, handler) {
  const $el = $(el);
  const transitionEndEvent = exports.getTransitionEndEvent($el.get(0));

  // When running in phantom or older safari, certain transition functions are not
  // respected (ie cubic-bezier with bounce effects). If the transition function
  // is invalid, the whole transition is ignored, meaning the transition end event
  // will never fire. We handle this here by checking to see that a transition property
  // is actually specified ('all' is the default). If it's not, we can surmise that the
  // transition property was invalid. Only checking webkit prefix as its just an old
  // safari/phantom issue.

  // Check the duration of the transition
  const styles = getComputedStyle(el);
  const transitionDuration = styles
    .getPropertyValue('transition-duration')
    .split(',')[0];
  if (
    !transitionEndEvent ||
    !transitionDuration ||
    transitionDuration === '0s' ||
    $el.css('-webkit-transition-property') === 'all'
  ) {
    console.warn(
      '[transitionEndOnce] No transition expected, so invoking handler immediately.',
      { transitionEndEvent, transitionDuration, $el },
    );
    handler({
      target: $el.get(0),
    });

    // return no-op since no event listener was created
    return function() {};
  }

  const eventName = `${transitionEndEvent}.transitionEnd`;

  $el.one(eventName, handler);

  return function() {
    $el.off('.transitionEnd');
  };
};

import Nuclear, { toImmutable } from 'nuclear-js';

import actionTypes from '../action_types';

export default Nuclear.Store({
  getInitialState() {
    let clearInitializationLock;
    const initializationLock = new Promise(resolve => {
      clearInitializationLock = resolve;
    });
    return toImmutable({
      clearInitializationLock,
      initializationLock,
    });
  },

  initialize() {
    this.on(actionTypes.PUBLIC_API_CONSUMER_SET_REFRESH_LOCK, setRefreshLock);
    this.on(
      actionTypes.PUBLIC_API_CONSUMER_SET_REFRESH_TIMEOUT,
      setRefreshTimer,
    );
    this.on(
      actionTypes.PUBLIC_API_CONSUMER_CLEAR_REFRESH_LOCK,
      clearRefreshLock,
    );
    this.on(
      actionTypes.PUBLIC_API_CONSUMER_CLEAR_REFRESH_TIMEOUT,
      clearRefreshTimer,
    );
    this.on(
      actionTypes.PUBLIC_API_CONSUMER_CLEAR_INITIALIZATION_LOCK,
      clearInitializationLock,
    );
  },
});

function setRefreshLock(state, lock) {
  return state.set('refreshLock', lock);
}

function setRefreshTimer(state, timeout) {
  return state.set('scheduledRefreshTimer', timeout);
}

function clearRefreshLock(state, didRefreshSucceed) {
  return state
    .delete('refreshLock')
    .set('lastRefreshTime', new Date().toISOString())
    .set('lastRefreshSucceeded', didRefreshSucceed);
}

function clearRefreshTimer(state) {
  return state.delete('scheduledRefreshTimer');
}

function clearInitializationLock(state, resolveValue) {
  state.get('clearInitializationLock')(resolveValue);
  return state;
}

import ReactDOM from 'react-dom';
import React from 'react';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import PropTypes from 'prop-types';
import _ from 'lodash';

import VueDirective from 'vue/src/directive';

import OptimizelyChampagneActions from 'optly/modules/optimizely_champagne/actions';
import connectGetters from 'core/ui/methods/connect_getters';

/**
 * Render a React component using provided options.
 * @param {Vue} vm
 * @param {Object} config
 * @param {Object} config.dataBindings
 * @param {Object} config.props
 * @param {Object} config.vmBindings
 * @param {Object} config.component
 * @param {HTMLElement} config.el
 * @param {Function} callback
 */
export default function renderReactComponent(vm, config, callback) {
  const reffedComponent = {};

  /**
   * A simple Vue directive to set state on a React component
   * on $update. Intended to mimic v-with as a 1-way data binding.
   * See:
   *   Directive constructor: /node_modules/vue/src/directive.js
   *   Native directive v-with config: /node_modules/vue/src/directives/with.js
   */
  class ReactStateDirective extends VueDirective {
    /**
     *
     * @param ctx - The React component instance.
     * @param key - The vm key to bind to.
     * @param propName - The propName to set on the React component on update.
     * @param compiler - The compiler instance for the vm.
     * @constructor
     */
    constructor(ctx, key, propName, compiler) {
      super('ReactStateDirective', { key }, {}, compiler, null);
      this.ctx = ctx;
      this.propName = propName;
    }

    update(val) {
      this.ctx.setState({
        [this.propName]: val,
      });
    }
  }

  class VueConnector extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.optimizelyClient = OptimizelyChampagneActions.getClientInstance();
      this.Component = config.component;
      if (config && config.dataBindings) {
        this.Component = connectGetters(config.component, config.dataBindings);
      }
    }

    UNSAFE_componentWillMount() {
      _.each(config.vmBindings, (componentProperty, propName) => {
        vm.$compiler.bindDirective(
          new ReactStateDirective(
            this,
            componentProperty,
            propName,
            vm.$compiler,
          ),
        );
      });
    }

    getChildContext() {
      return {
        vueEvents: {
          $emit(event, payload) {
            vm.$emit(event, payload);
          },

          $dispatch(event, payload) {
            vm.$dispatch(event, payload);
          },

          $on(event, handler) {
            vm.$on(event, handler);
          },

          $off(event, handler) {
            vm.$off(event, handler);
          },
        },
      };
    }

    render() {
      const { Component } = this;
      const mergedProps = {
        ref(c) {
          reffedComponent.instance = c;
        },
      };

      Object.assign(
        mergedProps, // Our manually defined ref
        this.props, // Static props passed from the parent Vue component (config.props)
        this.state, // Dynamic props from the parent Vue component (config.vmBindings)
      );

      return (
        <OptimizelyProvider optimizely={this.optimizelyClient}>
          <Component {...mergedProps} />
        </OptimizelyProvider>
      );
    }
  }

  // These validations are necessary for context to be passed between
  // parent and child. See https://reactjs.org/docs/context.html for
  // more explanation
  config.component.contextTypes = {
    vueEvents: PropTypes.shape({
      $emit: PropTypes.func,
      $on: PropTypes.func,
      $off: PropTypes.func,
      $dispatch: PropTypes.func,
    }),
  };

  VueConnector.childContextTypes = {
    vueEvents: PropTypes.shape({
      $emit: PropTypes.func,
      $on: PropTypes.func,
      $off: PropTypes.func,
      $dispatch: PropTypes.func,
    }),
  };

  vm.$on('hook:beforeDestroy', () => {
    ReactDOM.unmountComponentAtNode(config.el);
  });

  ReactDOM.render(
    React.createElement(VueConnector, config.props),
    config.el,
    () => {
      if (callback) {
        callback(reffedComponent);
      }
    },
  );
}

import flux from 'core/flux';

import { actionTypes } from './action_types';

/**
 * options.tableId - unique table identifier (ex: 'dashboard.experiments')
 * options.sortMax - (optional default=2) the maximum number of fields to fallback on sorting
 * options.sortBy - array of { field, type, dir }
 */
export function sortTable(options) {
  if (!options.tableId || !options.sortBy) {
    throw new Error('Must supply tableId and sortBy when sorting');
  }

  flux.dispatch(actionTypes.SORT_TABLE, options);
}

/**
 * Toggles sorting by a certain field
 *
 * options.tableId - unique table identifier (ex: 'dashboard.experiments')
 * options.field - name of field in object
 * options.dir - sort.ASC or sort.DESC
 * options.sortMax - the maximum number of fields to fallback on sorting
 */
export function toggleField(options) {
  if (!options.tableId || !options.field) {
    throw new Error('Must supply tableId and field when sorting');
  }

  flux.dispatch(actionTypes.TOGGLE_SORT_TABLE_FIELD, options);
}

/**
 * Destroys the sorting entry for a particular table
 * @param {string} tableId
 */
export function resetTable(tableId) {
  flux.dispatch(actionTypes.RESET_TABLE_SORTING, {
    tableId,
  });
}

export default {
  resetTable,
  sortTable,
  toggleField,
};

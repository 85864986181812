import React from 'react';
import PropTypes from 'prop-types';
import { isFunction, some } from 'lodash';

import { connect } from 'core/ui/decorators';

import { fns as ViewFns, enums as ViewEnums } from 'optly/modules/entity/view';
import PermissionsGetters from 'optly/modules/permissions/getters';
import ProjectFns from 'optly/modules/entity/project/fns';
import {
  getters as CurrentProjectGetters,
  actions as CurrentProjectActions,
} from 'optly/modules/current_project';

import UrlHelper from 'optly/services/url_helper';

import PageInformation from './subcomponents/page_information';
import ConditionBuilder from './subcomponents/condition_builder';
import AddCondition from './subcomponents/add_condition';
import AdvancedFeatures from './subcomponents/advanced_features';
import TriggerSettings from './subcomponents/trigger_settings';
import UrlValidator from './subcomponents/url_validator';

const DEFAULT_CONDITIONS =
  ViewEnums.defaultConditionConfigs[ViewEnums.conditionMatchTypes.URL];

const DEFAULT_TEST_URL = '';

@connect({
  canUseAdvancedPageSettings: PermissionsGetters.canUseAdvancedPageSettings,
  canUseViewActivationModeCallback:
    PermissionsGetters.canUseViewActivationModeCallback,
  canUseViewActivationModeDomChanged:
    PermissionsGetters.canUseViewActivationModeDomChanged,
  canUseViewActivationModeManual:
    PermissionsGetters.canUseViewActivationModeManual,
  canUseViewActivationModePolling:
    PermissionsGetters.canUseViewActivationModePolling,
  canUseViewActivationModeUrlChanged:
    PermissionsGetters.canUseViewActivationModeUrlChanged,
  canUseViewConditionCustomCode:
    PermissionsGetters.canUseViewConditionCustomCode,
  canUseViewConditionElementPresent:
    PermissionsGetters.canUseViewConditionElementPresent,
  currentProject: CurrentProjectGetters.project,
  currentProjectId: CurrentProjectGetters.id,
  isWebEdgeProject: [
    CurrentProjectGetters.project,
    ProjectFns.isWebEdgeProject,
  ],
  isSupportForDynamicWebsitesEnabled: [
    CurrentProjectGetters.project,
    project => {
      return project.getIn([
        'client_build_settings',

        'dynamic_website_support',
      ]);
    },
  ],
  snippetGlobalName: [
    CurrentProjectGetters.project,
    ProjectFns.getSnippetGlobalName,
  ],
})
class SmartPageConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deserializedConditionGroup: this.getDeserializedConditions(
        props.viewConfiguration,
      ),
      testUrls: this.getTestUrlsList(props.viewConfiguration),
      edgeLearnMoreLinks: props.isWebEdgeProject
        ? {
            hashWarningLink: CurrentProjectActions.getHelpCopy(
              'edge_hash_warning_learn_more_link',
              'https://docs.developers.optimizely.com/performance-edge/docs/create-experiment#section-1-set-up-url-targeting',
            ),
          }
        : {},
    };

    if (isFunction(props.setRevertHandler)) {
      props.setRevertHandler(this.resetState);
    }
  }

  static propTypes = {
    canUseAdvancedPageSettings: PropTypes.bool.isRequired,
    canUseViewActivationModeCallback: PropTypes.bool.isRequired,
    canUseViewActivationModeDomChanged: PropTypes.bool.isRequired,
    canUseViewActivationModeManual: PropTypes.bool.isRequired,
    canUseViewActivationModePolling: PropTypes.bool.isRequired,
    canUseViewActivationModeUrlChanged: PropTypes.bool.isRequired,
    canUseViewConditionCustomCode: PropTypes.bool.isRequired,
    canUseViewConditionElementPresent: PropTypes.bool.isRequired,
    errors: PropTypes.shape({
      activation_code: PropTypes.string,
      api_name: PropTypes.string,
      conditions: PropTypes.array,
      edit_url: PropTypes.string,
    }),
    isSupportForDynamicWebsitesEnabled: PropTypes.bool,
    isWebEdgeProject: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
    setRevertHandler: PropTypes.func,
    snippetGlobalName: PropTypes.string.isRequired,
    updateConditionErrors: PropTypes.func.isRequired,
    viewConfiguration: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isWebEdgeProject: false,
    isSupportForDynamicWebsitesEnabled: false,
  };

  resetState = () => {
    this.setState({
      deserializedConditionGroup: this.getDeserializedConditions(
        this.props.viewConfiguration,
      ),
      testUrls: this.getTestUrlsList(this.props.viewConfiguration),
    });
  };

  getDeserializedConditions = viewConfiguration => {
    const conditions = viewConfiguration.get('conditions');
    let initialConditionGroup;
    if (!conditions) {
      // If there is no id, we can assume that we are creating a new view
      // in which case we want to prepopulate the conditions with default url conditions
      initialConditionGroup = ViewFns.createConditionGroup(
        null,
        ViewEnums.conditionGroupTypes.AND,
      );
      if (!viewConfiguration.get('id')) {
        initialConditionGroup.conditions.push(
          ViewFns.createConditionGroup(DEFAULT_CONDITIONS),
        );
      }
    } else {
      initialConditionGroup = ViewFns.smartDeserializeConditions(conditions);
    }

    return initialConditionGroup;
  };

  getTestUrlsList = viewConfiguration => {
    const testUrls = viewConfiguration.get('test_urls');
    let initialTestUrls = [DEFAULT_TEST_URL];
    if (testUrls && testUrls.size) {
      initialTestUrls = [...testUrls];
    }
    return initialTestUrls;
  };

  /**
   * Handle a child component's mutation of this.state.deserializedConditionGroup
   * outside of the standard state management method (setState) by calling forceUpdate.
   * This is necessary because deserializedConditionGroup is not just a plain old
   * object - its an instance of a ConditionGroup.
   *    See https://reactjs.org/docs/integrating-with-other-libraries.html#integrating-with-model-layers
   */
  onConditionGroupDidMutate = () => {
    const { onUpdate } = this.props;
    const { deserializedConditionGroup } = this.state;
    this.forceUpdate();

    let updatedConditions = null;
    // If there are no conditions, we should not try to serialize the conditionGroup
    if (deserializedConditionGroup.conditions.length) {
      updatedConditions = ViewFns.smartSerializeConditions(
        deserializedConditionGroup,
      );
    }
    onUpdate('conditions', updatedConditions);
  };

  onTestUrlsChange = updatedUrls => {
    const { onUpdate } = this.props;
    onUpdate('test_urls', updatedUrls);
  };

  showUrlValidator = () =>
    some(
      this.state.deserializedConditionGroup.conditions,
      condition =>
        condition.conditions[0].type === ViewEnums.conditionMatchTypes.URL,
    );

  getHelpLinkProps = noun => {
    return this.props.isWebEdgeProject &&
      !this.props.isSupportForDynamicWebsitesEnabled
      ? {
          linkText: `This ${noun} requires Support for Dynamic Websites.`,
          linkURL: UrlHelper.projectSettingsImplementation(
            this.props.currentProjectId,
          ),
          linkNewWindow: true,
        }
      : null;
  };

  render = () => {
    const {
      canUseAdvancedPageSettings,
      canUseViewActivationModeCallback,
      canUseViewActivationModeDomChanged,
      canUseViewActivationModeManual,
      canUseViewActivationModePolling,
      canUseViewActivationModeUrlChanged,
      canUseViewConditionCustomCode,
      canUseViewConditionElementPresent,
      currentProject,
      currentProjectId,
      errors,
      onUpdate,
      updateConditionErrors,
      viewConfiguration,
      isWebEdgeProject,
      isSupportForDynamicWebsitesEnabled,
      snippetGlobalName,
    } = this.props;

    const {
      deserializedConditionGroup,
      edgeLearnMoreLinks,
      testUrls,
    } = this.state;

    return (
      <div>
        <PageInformation
          viewId={viewConfiguration.get('id')}
          pageName={viewConfiguration.get('name')}
          description={viewConfiguration.get('description')}
          editorUrl={viewConfiguration.get('edit_url')}
          apiName={viewConfiguration.get('api_name')}
          isNewPage={!!viewConfiguration.get('id')}
          activationType={viewConfiguration.get('activation_type')}
          canUseViewActivationModeManual={canUseViewActivationModeManual}
          onUpdate={onUpdate}
          errors={{
            edit_url: errors.edit_url,
            api_name: errors.api_name,
          }}
        />
        <h3 className="push-double--bottom">Page Settings</h3>
        <TriggerSettings
          apiName={viewConfiguration.get('api_name')}
          activationType={viewConfiguration.get('activation_type')}
          activationCode={viewConfiguration.get('activation_code')}
          canUseViewActivationModeCallback={canUseViewActivationModeCallback}
          canUseViewActivationModeDomChanged={
            canUseViewActivationModeDomChanged
          }
          canUseViewActivationModeManual={canUseViewActivationModeManual}
          canUseViewActivationModePolling={canUseViewActivationModePolling}
          canUseViewActivationModeUrlChanged={
            canUseViewActivationModeUrlChanged
          }
          conditionGroupDidMutate={this.onConditionGroupDidMutate}
          conditionGroup={deserializedConditionGroup}
          error={errors.activation_code}
          onUpdate={onUpdate}
          currentProjectId={currentProjectId}
          isSupportForDynamicWebsitesEnabled={
            isSupportForDynamicWebsitesEnabled
          }
          isWebEdgeProject={isWebEdgeProject}
          getHelpLinkProps={this.getHelpLinkProps.bind(this, 'trigger')}
          snippetGlobalName={snippetGlobalName}
        />
        <ConditionBuilder
          conditionGroup={deserializedConditionGroup}
          conditionGroupDidMutate={this.onConditionGroupDidMutate}
          errors={errors.conditions}
          isWebEdgeProject={isWebEdgeProject}
          edgeLearnMoreLinks={edgeLearnMoreLinks}
          updateConditionErrors={updateConditionErrors}
        />
        <AddCondition
          canUseViewConditionCustomCode={canUseViewConditionCustomCode}
          canUseViewConditionElementPresent={canUseViewConditionElementPresent}
          conditionGroup={deserializedConditionGroup}
          conditionGroupDidMutate={this.onConditionGroupDidMutate}
          getHelpLinkProps={this.getHelpLinkProps.bind(this, 'condition type')}
        />
        {canUseAdvancedPageSettings && (
          <AdvancedFeatures>
            <AdvancedFeatures.HelpLink
              getHelpLinkProps={this.getHelpLinkProps.bind(this, 'setting')}
            />
            <AdvancedFeatures.Deactivation
              onUpdate={onUpdate}
              deactivationEnabled={
                viewConfiguration.get('deactivation_enabled') ??
                currentProject.get('deactivation_enabled')
              }
              deactivationLabel="Deactivation and Undo"
              deactivationDescription="Automatically deactivate this page when the trigger fires and conditions
                are false. Even if this setting is disabled, the page can be manually
                activated and deactivated via the JS API."
              undoLabel="Undo changes when this page deactivates"
              undoDescription="Actively undo changes when this page deactivates. Note that not
                all types of changes can be undone."
              undoOnDeactivation={
                viewConfiguration.get('undo_on_deactivation') ??
                currentProject.get('undo_on_deactivation')
              }
            />
            {!isWebEdgeProject && !viewConfiguration.get('is_url_targeting') && (
              <AdvancedFeatures.Trimming
                onUpdate={onUpdate}
                label="Override Page Trimming"
                description="Always include this page in your snippet(s). If you've enabled Automatic
                  Page Trimming in Snippet Settings, pages are only included if they are
                  used for Targeting or Pageview Metrics. Select this option if you need to
                  include this snippet for other uses like behavioral targeting or data
                  export."
                overridePageTrimming={viewConfiguration.get(
                  'override_page_trimming',
                )}
              />
            )}
          </AdvancedFeatures>
        )}
        {this.showUrlValidator() && (
          <UrlValidator
            conditionGroup={deserializedConditionGroup}
            isWebEdgeProject={isWebEdgeProject}
            edgeLearnMoreLinks={edgeLearnMoreLinks}
            testUrls={testUrls}
            testUrlsChange={this.onTestUrlsChange}
          />
        )}
      </div>
    );
  };
}

export default SmartPageConfig;

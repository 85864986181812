/**
 * Entity Definition for Feature Flag
 */

import { cloneDeep } from 'lodash';

import fieldTypes from 'optly/modules/rest_api/field_types';

import AudienceEnums from '../audience/enums';

/**
 * TODO(APPX-34) Update to JSON.parse and replace "audience_conditions" when all FeatureFlag Audience builders can use rich JSON
 *
 * The AudienceCombinationsBuilder works with rich JSON. For every environment's rollout_rules, add
 * computed audience_conditions_json key from audience_conditions or audience_ids, This value will be removed before
 * save in feature_flag/actions.save.
 *
 * NOTE: Currently, the backend and UI use the primary environment's rollout_rule for all environments. In the future,
 * when we support multiple unique rollout_rules for different environments, this will continue to work since every
 * environment's audience config is already being computed
 *
 * @param {Object} featureFlag
 * @returns {Object}
 */
function computeFeatureFlagWithAudienceConditionsJson(featureFlag) {
  const clonedFeatureFlag = cloneDeep(featureFlag);
  if (!clonedFeatureFlag.environments) {
    return clonedFeatureFlag;
  }

  const computeAudienceConditionFromRolloutRule = rolloutRule => {
    if (!rolloutRule) {
      return;
    }
    const { audience_conditions, audience_ids } = rolloutRule;

    if (typeof audience_conditions === 'string') {
      rolloutRule.audience_conditions_json = JSON.parse(audience_conditions);
    } else {
      const audienceIds = audience_ids || [];
      rolloutRule.audience_conditions_json = [
        AudienceEnums.ConditionGroupTypes.OR,
      ].concat(audienceIds.map(id => ({ audience_id: id })));
    }
  };

  // For each of feature.environments.[key].rollout_rules.[i], mutate the rule
  // to add audience_conditions_json
  Object.values(clonedFeatureFlag.environments).forEach(featureEnvironment => {
    featureEnvironment.rollout_rules.forEach(
      computeAudienceConditionFromRolloutRule,
    );
  });

  return clonedFeatureFlag;
}

export default {
  entity: 'feature_flags',

  parent: {
    entity: 'projects',
    key: 'project_id',
  },

  fieldTypes: {
    id: fieldTypes.NUMBER,
    project_id: fieldTypes.NUMBER,
    api_name: fieldTypes.STRING,
    archived: fieldTypes.BOOLEAN,
    created: fieldTypes.DATE,
    description: fieldTypes.STRING,
    last_modified: fieldTypes.DATE,
    variables: fieldTypes.ARRAY,
  },

  deserialize(featureFlag) {
    // TODO(APPX-34) Update to JSON.parse and replace "audience_conditions" when all FeatureFlag Audience builders can use rich JSON
    return computeFeatureFlagWithAudienceConditionsJson(featureFlag);
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import { debounce, isEmpty } from 'lodash';
import update from 'immutability-helper';
import { Button, Input, OverlayWrapper, Popover, Icon } from 'optimizely-oui';

import { withTrack } from '@optimizely/segment-js/dist/decorators';

import LoadingOverlay from 'react_components/loading_overlay';

import Immutable from 'optly/immutable';
import RecommenderConstants from 'optly/modules/entity/recommender/constants';
import CatalogStatsActions from 'optly/modules/entity/catalog_stats/actions';
import RecommenderStatsActions from 'optly/modules/entity/recommender_stats/actions';
import URL from 'optly/utils/url';

import PreviewerTable from '../previewer_table';

const $INITIAL_STATE = {
  currentItemRecommendations: { $set: [] },
  currentlySelectedItemID: { $set: '' },
  currentlySelectedItemInfo: { $set: {} },
  isLoading: { $set: false },
};

const URL_REGEX = /\.(jpeg|jpg|gif|png|svg)/;

@withTrack
class ItemPreviewer extends React.Component {
  static propTypes = {
    /**
     * Current catalog (recommender service)
     */
    catalog: PropTypes.instanceOf(Immutable.Map).isRequired,
    /**
     * Current recommender
     */
    recommender: PropTypes.instanceOf(Immutable.Map).isRequired,
    /**
     * Segment Tracking function handler.
     */
    track: PropTypes.func,
  };

  static defaultProps = {
    track: () => {},
  };

  state = update({}, $INITIAL_STATE);

  isCoBrowseOrCoBuyAlgorithm = () => {
    const { recommender } = this.props;
    const algorithm = recommender.get('algorithm');
    return (
      algorithm === RecommenderConstants.Algorithm.CO_BROWSE ||
      algorithm === RecommenderConstants.Algorithm.CO_BUY
    );
  };

  isPopularAlgorithm = () => {
    const { recommender } = this.props;

    return (
      recommender.get('algorithm') === RecommenderConstants.Algorithm.POPULAR
    );
  };

  /**
   * Validate the given URL to ensure it's an image URL.
   * This is a heuristic way to infer if a URL points to an image.
   * If we cannot find a URL that could point to an image, we'll indicate users that there's No Image Available.
   *
   * @param {String} url to be validated URL.
   * @return {Boolean} whether or not the given URL is an image URL.
   */
  isValidImageURL = url => {
    const formattedURL = url && url.toString().toLowerCase();

    return (
      formattedURL &&
      URL.isValidUrl(formattedURL) &&
      (!!formattedURL.match(URL_REGEX) || formattedURL.indexOf('image') !== -1)
    );
  };

  setLoadingState = currentInputValue => {
    const loadingState = update(this.state, {
      currentlySelectedItemID: { $set: currentInputValue },
      isLoading: { $set: true },
    });
    this.setState(loadingState);
  };

  /**
   * This looks into all entries of selectedItemInfo and find if there's an entry which contains the string 'price'.
   * If such entry is available, set add an entry of { price: value } to the selectedItemRowData.
   *
   * @param selectedItemRowData
   * @param selectedItemInfo
   */
  setValueForPriceFieldIfAvailable = (
    selectedItemRowData,
    selectedItemInfo,
  ) => {
    const PRICE = 'price';

    /**
     * Sort those tag names alphabetically, so if we find any occurance of the substring first, that's would be
     * one of the valid tag name that contains the search string, then we don't need to find another similar tag name anymore.
     */
    Object.entries(selectedItemInfo)
      .sort()
      .find(tagInfo => {
        const tagName = tagInfo[0].toLowerCase();
        const tagValue = tagInfo[1];

        if (tagName.includes(PRICE)) {
          selectedItemRowData.price = tagValue;
          return tagValue;
        }
      });
  };

  /**
   * This looks into all entries of selectedItemInfo and find if there's an entry which contains the string 'name' or 'title'.
   * If such entry is available, set add an entry of { title: value } to the selectedItemRowData.
   *
   * @param selectedItemRowData
   * @param selectedItemInfo
   */
  setValueForTitleFieldIfAvailable = (
    selectedItemRowData,
    selectedItemInfo,
  ) => {
    const NAME = 'name';
    const TITLE = 'title';

    /**
     * Sort those tag names alphabetically, so if we find any occurance of the substring first, that's would be
     * one of the valid tag name that contains the search string, then we don't need to find another similar tag name anymore.
     */
    Object.entries(selectedItemInfo)
      .sort()
      .find(tagInfo => {
        const tagName = tagInfo[0].toLowerCase();
        const tagValue = tagInfo[1];

        if (tagName.includes(NAME) || tagName.includes(TITLE)) {
          selectedItemRowData.title = tagValue;
          return tagValue;
        }
      });
  };

  /**
   * Whenever user inputs an ItemID, a VisitorID or click on the View Results button, this will fetch
   * recommendations for the given ItemID (Co-Browse and Co-Buy), VisitorID (Collaborative-Filtering), or Popular algorithm.
   *
   * @param {String} inputValue the updated itemID (or 'popular') to be fetched for recommendations.
   */
  fetchRecommendationsForChosenAlgorithm = inputValue => {
    const { catalog, recommender, track } = this.props;

    const idTagName = catalog.get('id_tag_name');
    const currentlySelectedItemID = this.isPopularAlgorithm()
      ? 'popular'
      : inputValue;

    if (!currentlySelectedItemID) {
      this.setState(update({}, $INITIAL_STATE));
      return;
    }

    this.setLoadingState(currentlySelectedItemID);

    if (this.isCoBrowseOrCoBuyAlgorithm()) {
      /**
       * Fetch the itemInfo for the currentlySelectedItemID
       * This is to show what's the current item we're looking at.
       */
      CatalogStatsActions.fetchItemMetadataById(
        catalog,
        currentlySelectedItemID,
      ).then(selectedItemInfo => {
        let selectedItemRowData = {
          id: currentlySelectedItemID,
          strength: 1,
        };

        if (selectedItemInfo) {
          selectedItemRowData.imageURL = Object.values(
            selectedItemInfo,
          ).find(value => this.isValidImageURL(value));
          this.setValueForPriceFieldIfAvailable(
            selectedItemRowData,
            selectedItemInfo,
          );
          this.setValueForTitleFieldIfAvailable(
            selectedItemRowData,
            selectedItemInfo,
          );
        } else {
          selectedItemRowData = null;
        }

        const newState = update(this.state, {
          currentlySelectedItemID: { $set: currentlySelectedItemID },
          currentlySelectedItemInfo: { $set: selectedItemRowData },
        });

        this.setState(newState);
      });
    } else {
      const newState = update(this.state, {
        currentlySelectedItemID: { $set: currentlySelectedItemID },
      });

      this.setState(newState);
    }

    /**
     * Fetch the recommendations for the currentlySelectedItemID based on the recommender.
     */
    RecommenderStatsActions.fetchRecommendationsById(
      recommender,
      currentlySelectedItemID,
    )
      .then(recommendations => {
        if (!recommendations) {
          return [];
        }

        /**
         * Must fetch the recommendations first in order to get all of the recommended itemIDs
         * Then once we have all of the itemIDs, we need to initiate between 1-20 fetch calls simultaneously to fetch
         * all of the itemInfos for each of the recommended itemID.
         */
        return Promise.all(
          recommendations.map(recommendation => {
            /**
             * idTagName can be varied across all users, so we need recommendation to be an immutable structure in order
             * to get it, hence, can't use a pure POJO.
             */
            const itemID = recommendation.get(idTagName);
            const itemStrength =
              Math.round(Number(recommendation.get('_score')) * 100) / 100;

            /**
             * Fetch the itemInfo for each of the recommended item.
             */
            return CatalogStatsActions.fetchItemMetadataById(
              catalog,
              itemID,
            ).then(catalogItemInfo => {
              const recommendationRowData = {
                id: itemID,
                strength: itemStrength,
              };

              if (catalogItemInfo) {
                recommendationRowData.imageURL = Object.values(
                  catalogItemInfo,
                ).find(value => this.isValidImageURL(value));
                this.setValueForPriceFieldIfAvailable(
                  recommendationRowData,
                  catalogItemInfo,
                );
                this.setValueForTitleFieldIfAvailable(
                  recommendationRowData,
                  catalogItemInfo,
                );
              }

              return recommendationRowData;
            });
          }),
        );
      })
      .then(detailedRecommendations => {
        const newState = update(this.state, {
          currentItemRecommendations: { $set: detailedRecommendations },
          isLoading: { $set: false },
        });

        this.setState(newState);
      });

    // Segment Tracking
    track('Recommendations Recommender Item Previewer Searched Keywords', {
      keyword: currentlySelectedItemID,
    });
  };

  debounceFetchRecommendationsForChosenAlgorithm = debounce(
    this.fetchRecommendationsForChosenAlgorithm,
    __TEST__ ? 0 : 500,
  );

  renderItemRecommendations = () => {
    const { recommender } = this.props;

    const {
      currentItemRecommendations,
      currentlySelectedItemID,
      currentlySelectedItemInfo,
      isLoading,
    } = this.state;

    const algorithm = recommender.get('algorithm');
    const responsePrefix = this.isPopularAlgorithm()
      ? 'There are'
      : `"${currentlySelectedItemID}" has`;
    const responsePostfix =
      RecommenderConstants.AlgorithmPreviewerTexts[algorithm].response;
    const emptyStateResponse =
      RecommenderConstants.AlgorithmPreviewerTexts[algorithm].emptyResponse;

    if (
      !!currentlySelectedItemID &&
      isEmpty(currentlySelectedItemInfo) &&
      !currentItemRecommendations.length &&
      !this.isPopularAlgorithm() &&
      !isLoading
    ) {
      return (
        <div data-test-section="empty-state-catalog-item-details">
          {emptyStateResponse}
        </div>
      );
    }

    return (
      <div className="push--top" style={{ position: 'relative' }}>
        <LoadingOverlay isLoading={isLoading}>
          {!isLoading && (
            <>
              {this.isCoBrowseOrCoBuyAlgorithm() && (
                <PreviewerTable
                  currentItemRecommendations={[currentlySelectedItemInfo]}
                  currentlySelectedItemID={currentlySelectedItemID}
                  tableLabel={`Results for "${currentlySelectedItemID}"`}
                  testSection="target-items-table"
                />
              )}
              <PreviewerTable
                currentItemRecommendations={currentItemRecommendations}
                currentlySelectedItemID={currentlySelectedItemID}
                tableLabel={`${responsePrefix} ${currentItemRecommendations.length} ${responsePostfix}`}
                testSection="recommended-items-table"
              />
            </>
          )}
        </LoadingOverlay>
      </div>
    );
  };

  renderButtonForPopularAlgorithm = () => {
    const { recommender } = this.props;

    const algorithm = recommender.get('algorithm');
    const previewerPlaceholder =
      RecommenderConstants.AlgorithmPreviewerTexts[algorithm].placeholder;

    return (
      <div className="push-triple--bottom" data-test-section="item-previewer">
        <span data-test-section="item-previewer-label">
          View recommendations based on the <b>Popular</b> algorithm.
        </span>
        <div className="push--top">
          <Button
            key="view-results"
            onClick={this.debounceFetchRecommendationsForChosenAlgorithm}
            style="outline"
            testSection="view-results-popular-algorithm-button">
            {previewerPlaceholder}
          </Button>
        </div>
      </div>
    );
  };

  renderInputForOtherAlgorithms = () => {
    const { recommender } = this.props;

    const algorithm = recommender.get('algorithm');
    const previewerLabel =
      RecommenderConstants.AlgorithmPreviewerTexts[algorithm].label;
    const previewerPlaceholder =
      RecommenderConstants.AlgorithmPreviewerTexts[algorithm].placeholder;

    return (
      <div className="push-triple--bottom" data-test-section="item-previewer">
        <span data-test-section="item-previewer-label">{previewerLabel}</span>
        <div className="flex flex-align--center push--top">
          <div className="width--1-2">
            <Input
              displayError={false}
              id="recommender-item-previewer"
              isFilter={true}
              maxLength={150}
              onChange={event =>
                this.debounceFetchRecommendationsForChosenAlgorithm(
                  event.target.value,
                )
              }
              placeholder={previewerPlaceholder}
              testSection="item-previewer-input"
              type="text"
            />
          </div>
          <OverlayWrapper
            behavior="hover"
            horizontalAttachment="left"
            verticalAttachment="middle"
            overlay={
              <Popover testSection="item-previewer-input-description">
                Depending on the algorithm used for this recommender, enter
                either an <b>ItemID</b> (when using Co-browse/Co-buy) or a{' '}
                <b>VisitorID</b> (when using Collaborative Filtering).
              </Popover>
            }>
            <div className="push--left push-half--top">
              <Icon name="circle-question" size="small" />
            </div>
          </OverlayWrapper>
        </div>
      </div>
    );
  };

  render() {
    const { currentlySelectedItemID } = this.state;
    return (
      <React.Fragment>
        <h3>Preview Item Recommendations</h3>
        {this.isPopularAlgorithm()
          ? this.renderButtonForPopularAlgorithm()
          : this.renderInputForOtherAlgorithms()}
        {!!currentlySelectedItemID && this.renderItemRecommendations()}
      </React.Fragment>
    );
  }
}

export default ItemPreviewer;

import React from 'react';
import PropTypes from 'prop-types';

import {
  actions as ProjectCreationActions,
  Component as CreateProject,
} from 'optly/components/create_project';

import WelcomeWrapper from '../../components/welcome_wrapper';

class CreateProjectWelcome extends React.Component {
  static componentId = 'p13n-welcome-create-project-page';

  static propTypes = {
    projectType: PropTypes.string,
  };

  handleCreateProject = projectInputData =>
    ProjectCreationActions.saveProject(projectInputData).then(project =>
      ProjectCreationActions.routeToProject(project),
    );

  render() {
    const { projectType } = this.props;

    return (
      <WelcomeWrapper>
        <div className="soft--bottom">
          <CreateProject
            onCreate={this.handleCreateProject}
            projectType={projectType}
            isFirstProject={true}
          />
        </div>
      </WelcomeWrapper>
    );
  }
}

export const component = CreateProjectWelcome;

export default {
  component,
};

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Input, Table } from 'optimizely-oui';

class MVTSectionVariationRow extends React.Component {
  constructor(props) {
    super(props);

    this.onUpdateVariationPercentage = this.onUpdateVariationPercentage.bind(
      this,
    );
    this.onUpdateVariationName = this.onUpdateVariationName.bind(this);
    this.onRemoveVariationFromSection = this.onRemoveVariationFromSection.bind(
      this,
    );
  }

  onUpdateVariationPercentage(guid, e) {
    const variation = {
      guid,
      weight: e.target.value * 100,
    };
    this.props.onUpdateVariationInSection(variation);
  }

  onUpdateVariationName(guid, e) {
    const variation = {
      guid,
      name: e.target.value,
    };
    this.props.onUpdateVariationInSection(variation);
  }

  onRemoveVariationFromSection(guid) {
    this.props.onRemoveVariationFromSection(guid);
  }

  render() {
    const {
      name,
      sectionIndex,
      variationIndex,
      percentage,
      guid,
      variationCount,
    } = this.props;

    return (
      <Table.TR>
        <Table.TD>
          <Input
            testSection={`mvt-section-${sectionIndex}-variation-name-${variationIndex}`}
            isRequired={true}
            type="text"
            value={name}
            onChange={_.partial(this.onUpdateVariationName, guid)}
          />
        </Table.TD>
        <Table.TD>
          <span className="display--inline-block width--75">
            <Input
              testSection={`mvt-section-${sectionIndex}-variation-percentage-${variationIndex}`}
              isRequired={true}
              type="number"
              max={100}
              min={0}
              value={Number(percentage)}
              onChange={_.partial(this.onUpdateVariationPercentage, guid)}
            />
          </span>{' '}
          %
          {variationCount !== 1 && (
            <a
              className="push--left"
              onClick={_.partial(this.onRemoveVariationFromSection, guid)}
              data-test-section={`mvt-section-${sectionIndex}-delete-variation-${variationIndex}`}>
              Delete
            </a>
          )}
        </Table.TD>
      </Table.TR>
    );
  }
}

MVTSectionVariationRow.propTypes = {
  guid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  variationIndex: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  onRemoveVariationFromSection: PropTypes.func.isRequired,
  onUpdateVariationInSection: PropTypes.func.isRequired,
  variationCount: PropTypes.number.isRequired,
};

export default MVTSectionVariationRow;

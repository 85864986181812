/**
 * Utility functions for use with Select2 jQuery plugin.
 */
module.exports = {
  /**
   * Convert an array of options to an array of objects
   * that are compatible for use with Select2.
   * Use the index as the `id` value.
   *
   * @param data array of options for dropdown
   * @param propertyName of text value
   */
  formatDataByIndex(data, propertyName) {
    return data.map((value, index) => ({
      id: index,
      text: value[propertyName],
      subtext: value.subtext ? value.subtext : null,
      title: value.title ? value.title : null,
    }));
  },

  /**
   * Convert an array of options to an array of objects
   * that are compatible for use with Select2.
   * Use the `text` value also as the `id` value.
   *
   * @param data array of options for dropdown
   * @param propertyName of text value
   */
  formatDataByText(data, propertyName) {
    return data.map((value, index) => ({
      id: value[propertyName],
      text: value[propertyName],
      subtext: value.subtext ? value.subtext : null,
      title: value.title ? value.title : null,
    }));
  },
};

import { getFeatureVariableString } from '@optimizely/js-sdk-lab/src/actions';
import { Recommendations } from 'optly/modules/optimizely_champagne/enums';

export const buildURLForDownloadCSV = (
  catalogID,
  csvType,
  csvDate,
  recommenderID,
) => {
  const urlPrefix = `https://api.app.optimizely.com/v2/recommendations/catalogs/${catalogID}/${csvType}`;

  if (recommenderID) {
    return `${urlPrefix}/${recommenderID}/${csvDate}`;
  }

  return `${urlPrefix}/${csvDate}`;
};

/**
 * Get help copy or link value based on OoO feature variable value for Adaptive Recommendations.
 * @param {String} variable
 * @param {String|Undefined} [defaultVal] - can be undefined, resulting in a falsy return value if variable is not found
 *
 * @returns {String} undefined will only be returned if variable does not exist and defaultVal is missing or undefined
 */
export const getHelpCopy = (variable, defaultVal) => {
  return (
    getFeatureVariableString(
      Recommendations.FEATURE_IDS.RECS_HELP_COPY,
      variable,
    ) || defaultVal
  );
};

export default {
  buildURLForDownloadCSV,
  getHelpCopy,
};

import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Button, Input } from 'optimizely-oui';

import filter from 'optly/utils/filter';
import {
  Dropdown,
  DropdownContents,
  DropdownListItem,
  DropdownBlockLink,
} from 'react_components/dropdown';

class SelectDropdown extends React.Component {
  static propTypes = {
    /**
     * Boolean that determines whether or not the selector has the filter feature.
     */
    isFilterable: PropTypes.bool,
    /**
     * Placeholder text for the filter input.
     */
    inputPlaceholder: PropTypes.string,
    /**
     * Dropdown items that can be selected from the select dropdown.
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.number.isRequired,
          PropTypes.bool,
        ]).isRequired,
      }),
    ).isRequired,
    /**
     * Value of currently selected item.
     */
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
    /**
     * Function that is called when user selects another item from dropdown list.
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Width of the activator container.
     */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * The select is greyed out if it is disabled.
     */
    isDisabled: PropTypes.bool,
    /**
     * The minimum width of the dropdown list; any valid CSS width value.
     */
    minDropdownWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Dropdown direction.
     */
    dropdownDirection: PropTypes.oneOf(['right', 'left']),
    /**
     * Identifier used to create data-test-section attributes for testing.
     */
    testSection: PropTypes.string,
    /**
     * Identifier used to create data-track-id attributes for Heap testing.
     */
    trackId: PropTypes.string,
    /**
     * Style value that is passed to the OUI button that controls the dropdown.
     */
    buttonStyle: PropTypes.string,
    /**
     * zIndex of dropdown group
     */
    zIndex: PropTypes.number,
    /**
     * Function to fire when the dropdown button is clicked
     */
    onButtonClick: PropTypes.func,
  };

  static defaultProps = {
    buttonStyle: 'outline',
    isFilterable: false,
    dropdownDirection: 'right',
    width: '100%',
    trackId: '',
    testSection: '',
    onButtonClick: () => {},
  };

  state = {
    searchTerm: '',
  };

  search = event => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  __filterTermValue = value =>
    filter.isFilterTermInItem(this.state.searchTerm, value);

  renderContents = () => {
    const {
      items,
      value,
      minDropdownWidth,
      dropdownDirection,
      isFilterable,
      inputPlaceholder,
    } = this.props;
    const itemsToDisplay = items.filter(item =>
      this.__filterTermValue(item.label),
    );

    return (
      <DropdownContents
        minWidth={minDropdownWidth}
        direction={dropdownDirection}>
        {isFilterable && (
          <DropdownListItem>
            <form className="soft-half--ends lego-search">
              <Input
                type="text"
                isFilter={true}
                value={this.state.searchTerm}
                placeholder={inputPlaceholder}
                onChange={this.search}
              />
            </form>
          </DropdownListItem>
        )}
        {itemsToDisplay.map((entry, index) => (
          <SelectOption
            key={index}
            onChange={this.props.onChange}
            value={entry.value}
            label={entry.label}
            description={entry.description}
            isSelected={entry.value === value}
          />
        ))}
      </DropdownContents>
    );
  };

  render() {
    const { buttonStyle, value, width, zIndex } = this.props;
    let selectedItem;
    this.props.items.forEach(item => {
      if (item.value === value) {
        selectedItem = item;
      }
    });

    return (
      <Dropdown
        width={width}
        zIndex={zIndex}
        activator={
          <Button
            isDisabled={this.props.isDisabled}
            onClick={this.props.onButtonClick}
            style={buttonStyle}
            testSection={this.props.testSection}
            width="full">
            <div
              className="flex flex-align--center"
              data-track-id={this.props.trackId}>
              <span
                style={{ overflow: 'hidden' }}
                className="flex flex--1"
                data-test-section="select-dropdown-displayed-text">
                {selectedItem.label}
              </span>
              <Icon className="push--left" name="angle-down" size="small" />
            </div>
          </Button>
        }>
        {this.renderContents()}
      </Dropdown>
    );
  }
}

class SelectOption extends React.Component {
  static propTypes = {
    /**
     * Description of select item.
     */
    description: PropTypes.string,
    /**
     * Value of select item.
     */
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
    /**
     * Label of select item.
     */
    label: PropTypes.string.isRequired,
    /**
     * Whether or not item has been selected or not.
     */
    isSelected: PropTypes.bool.isRequired,
    /**
     * Function that is called when user selects another item.
     */
    onChange: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.onChange(this.props.value);
  };

  render() {
    const { isSelected, label, description, value } = this.props;
    return (
      <DropdownListItem hideOnClick={true}>
        <DropdownBlockLink
          isLink={!isSelected}
          onClick={this.onClick}
          testSection={`dropdown-block-link-${value}`}>
          {label}
          {description && <div className="micro muted">{description}</div>}
        </DropdownBlockLink>
      </DropdownListItem>
    );
  }
}

export default SelectDropdown;

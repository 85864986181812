import _ from 'lodash';
import React from 'react';

import { Button } from 'optimizely-oui';

import PropTypes from 'prop-types';

import Immutable from 'optly/immutable';
import tr from 'optly/translate';

import { EnvironmentStatus as EnvironmentStatusEnums } from 'optly/modules/entity/layer_experiment/enums';
import LoadingOverlay from 'react_components/loading_overlay';

import EnvironmentStatus, { iconTypes } from '../environment_status';

function EnvironmentStatusControls(props) {
  const {
    className,
    hasNoLayerMetrics,
    isLoading,
    onStatusChange,
    statuses,
    testSection,
  } = props;

  const cx = !_.isUndefined(className) ? className : 'width--200';

  // Statuses should be shown in decending priority order;
  const statusesReversed = statuses.reverse();

  return (
    <div
      className={cx}
      data-test-section={testSection}
      style={{ position: 'relative' }}>
      Environments
      <LoadingOverlay isLoading={!!isLoading} size="small">
        {statusesReversed.map(item => {
          const onClick = event => {
            onStatusChange(
              item.get('id'),
              item.get('key'),
              item.get('status'),
              event,
            );
          };

          let iconType;
          if (
            item.get('status') === EnvironmentStatusEnums.NOT_STARTED ||
            item.get('status') === EnvironmentStatusEnums.PAUSED
          ) {
            iconType = iconTypes.GREY_EMPTY;
          } else {
            iconType = iconTypes.GREEN_CHECK;
          }

          const shouldBeDisabled =
            hasNoLayerMetrics || !item.get('canStartAndPause');

          return (
            <div key={item.get('key')} className="flex">
              <EnvironmentStatus name={item.get('name')} iconType={iconType} />
              <span
                className="width--50"
                data-test-section="environment-status-button-container"
                data-test-row-id={item.get('key')}>
                <Button
                  width="full"
                  style="outline"
                  size="tiny"
                  testSection="environment-status-button"
                  onClick={onClick}
                  isDisabled={shouldBeDisabled}>
                  {item.get('status') === 'running' ? tr('Pause') : tr('Run')}
                </Button>
              </span>
            </div>
          );
        })}
      </LoadingOverlay>
    </div>
  );
}

EnvironmentStatusControls.propTypes = {
  className: PropTypes.string,
  hasNoLayerMetrics: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isLoading: PropTypes.bool.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  statuses: PropTypes.instanceOf(Immutable.List).isRequired,
  testSection: PropTypes.string,
};
export default EnvironmentStatusControls;

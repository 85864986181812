import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { enums as LayerEnums } from 'optly/modules/entity/layer';
import { toImmutable } from 'optly/immutable';

import { RESTRICTION_NUM_YEARS } from './constants';

export const dateNow = ['p13n/resultsApi', 'now'];

export const stubDateNow = ['p13n/resultsApiStub', 'now'];

export const stubTimerange = ['p13n/resultsApiStub', 'timerange'];

export const traceId = ['p13n/resultsApi', 'traceId'];

export const campaignTimerange = [
  CurrentLayerGetters.layer,
  stubDateNow,
  dateNow,
  (layer, _stubDateNow, _dateNow) => {
    let end = __TEST__ ? _stubDateNow : _dateNow;
    if (!end) {
      end = Date.now();
    }
    // Calculates a restricted begin date starting 2 years from current date
    const today = new Date(end);
    let restrictedBegin = today.setFullYear(
      today.getFullYear() - RESTRICTION_NUM_YEARS,
    );

    // If for some reason layer isn't set, send a one second date range so that we don't
    // get into an infinite loop of requests.
    if (!layer) {
      return {
        begin: Date.now(),
        end: Date.now() + 1000,
        restrictedBegin: Date.now(),
      };
    }

    // For any experiment that is not active check if latest date was ever set (Paused)
    if (layer.get('status') !== LayerEnums.entityStatus.RUNNING) {
      end = layer.get('latest') || end;
    }

    // Layer earliest doesn't get populated properly until the API request to start the campaign completes
    // and the datastore updates. Since this only happens on create and shouldn't take too long we can safely
    // fall back to when the layer was created, either way there shouldn't be any data.
    const begin = layer.get('earliest') || layer.get('created');

    // For experiments less than 2 years old, or paused more than 2 years ago
    if (
      new Date(restrictedBegin).valueOf() < new Date(begin).valueOf() ||
      new Date(restrictedBegin).valueOf() > new Date(end).valueOf()
    ) {
      restrictedBegin = begin;
    }
    // After we reset results the end date and begin date become the same and we look directly at this
    // getter. For that special case just add a second to the end date so that the results api doesn't freak out.
    if (new Date(begin).valueOf() >= new Date(end).valueOf()) {
      return {
        begin: new Date(begin).valueOf(),
        end: new Date(begin).valueOf() + 1000,
        restrictedBegin: new Date(begin).valueOf(),
      };
    }

    return {
      begin: new Date(begin).valueOf(),
      end: new Date(end).valueOf(),
      restrictedBegin: new Date(restrictedBegin).valueOf(),
    };
  },
];

export const defaultLoadTimerange = [
  CurrentLayerGetters.layer,
  campaignTimerange,
  (layer, _campaignTimerange) => {
    // If for some reason the layer is not set just return the campaignTimerange, which already handles
    // this case and sets a one second timerange with the current time.
    if (!layer) {
      return _campaignTimerange;
    }

    const { restrictedBegin } = _campaignTimerange;
    // Default to created if earliest isn't set (works for brand new experiments)
    const startDate = layer.get('earliest') || layer.get('created');
    let endDate = _campaignTimerange.end;

    // endDate can be before beginDate if we cache dateNow and then create a layer.
    // Add a second to make sure endDate is after beginDate.
    if (new Date(endDate) < new Date(startDate)) {
      endDate = new Date(startDate).valueOf() + 1000;
    }

    return toImmutable({
      begin: new Date(startDate).valueOf(),
      end: new Date(endDate).valueOf(),
      restrictedBegin: new Date(restrictedBegin).valueOf(),
    });
  },
];

export default {
  campaignTimerange,
  dateNow,
  defaultLoadTimerange,
  stubDateNow,
  stubTimerange,
  traceId,
};

import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';

import { connect } from 'core/ui/decorators';
import LoadingGetters from 'core/modules/loading/getters';
import JoyrideActions from 'bundles/p13n/modules/joyride/actions';
import JoyrideToolTip from 'react_components/joyride_tooltip';

// react-joyride calls the skip action twice. Remove this when
// calling skip twice is fixed
const SKIP_PADDING = 2;

/**
 * @name TourGuideComponent
 * @description renders a tour-guide experience
 * @kind component
 * @param {object} props
 * @param {object} props.config - react-joyride config
 * @param {string} props.guideId - tour guide namespace that's used to control when a user sees the tour
 * @param {boolean} props.isPageLoading - determines if the main page container is still loading.
 *                                         We need DOM to run the tour guide
 * @param {number} props.maxSkips - max number of times a user can skip the tour
 * @example
 *  <TourGuide
 *    config={{
 *      steps: [...]
 *    }}
 *    guideId="MY_APP_TOUR_GUIDE"
 *    maxSkips={3}
 *  />
 */
@connect({
  isPageLoading: LoadingGetters.isLoading('main'),
})
class TourGuideComponent extends React.Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    guideId: PropTypes.string.isRequired,
    isPageLoading: PropTypes.bool.isRequired,
    maxSkips: PropTypes.number,
  };

  static defaultProps = {
    maxSkips: Infinity,
  };

  handleJoyrideStateChange = joyrideState => {
    const { guideId } = this.props;
    if (joyrideState.action === 'close') {
      return JoyrideActions.markJoyrideAsSeen(guideId);
    }

    if (joyrideState.action === 'skip') {
      return JoyrideActions.markJoyrideAsSkipped(guideId);
    }
  };

  render() {
    const { config, guideId, isPageLoading, maxSkips } = this.props;
    const { steps } = config || {};
    const shouldShowJoyride =
      !isPageLoading &&
      typeof config === 'object' &&
      !JoyrideActions.hasJoyrideExceededSkips(
        guideId,
        maxSkips * SKIP_PADDING,
      ) &&
      !JoyrideActions.hasJoyrideBeenSeen(guideId);
    return (
      shouldShowJoyride && (
        <Joyride
          callback={this.handleJoyrideStateChange}
          continuous={true}
          floaterProps={{
            disableAnimation: true,
          }}
          run={true}
          steps={steps}
          styles={{
            options: {
              zIndex: 100000, // Have to do this to overlay our sidebar properly
            },
          }}
          tooltipComponent={JoyrideToolTip}
        />
      )
    );
  }
}

export const TourGuide = TourGuideComponent;
export default { TourGuide };

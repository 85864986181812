import _ from 'lodash';
import sprintf from 'sprintf';

import flux from 'core/flux';

import LocalStorageWrapper from 'optly/utils/local_storage_wrapper';
import { toImmutable, toJS } from 'optly/immutable';

import actionTypes from './action_types';
import createRequestInfo from './api/create_request_info';
import constants from './constants';
import fns from './fns';

const MS_PER_MINUTE = 60 * 1000;
const ENTITY_STALE_PERIOD_IN_MS = 5 * MS_PER_MINUTE; // 5 Minutes

/**
 * Setup local Entity Cache
 * @param {String} parentIdKey  parent key for entities to be cached
 * @param {Number} parentIdKey  parent key value for entities to be cached
 * @param {Array} entities
 */
export function setupLocalEntityCache(parentIdKey, parentIdValue, entities) {
  if (!_.isArray(entities)) {
    throw new Error('setupEntityCaching must be passed an array');
  }
  // tear down tracking for existing entities if it exists
  this.unwatchExistingLocalCacheEntity(parentIdKey, parentIdValue, entities);
  // setup tracking of entities
  const entityEntries = entities.map(entity => {
    const localStorageKey = fns.getLocalStorageKey(
      parentIdKey,
      parentIdValue,
      entity,
    );
    const oldEntity = flux.evaluate(['apiLocalCache', entity]);
    if (oldEntity && oldEntity.get('localStorageKey') === localStorageKey) {
      // no need to setup tracking if entity already being tracked
      return;
    }
    const getter = [
      ['entityCache', entity],
      function(entityMap) {
        if (!entityMap) {
          return toImmutable([]);
        }
        return entityMap
          .filter(entry => entry.get(parentIdKey) === parentIdValue)
          .toList();
      },
    ];

    const unwatchFn = flux.observe(
      getter,
      _.debounce(
        entityData => {
          try {
            const data = JSON.stringify(toJS(entityData));
            LocalStorageWrapper.setItem(localStorageKey, data);
          } catch (e) {
        console.warn(sprintf("Setting the value of %s exceeded localstorage quota", localStorageKey)); //eslint-disable-line
          }
        },
        __TEST__ ? 0 : 50,
      ),
    );

    return {
      entity,
      unwatchFn,
      localStorageKey,
    };
  });

  // load all items in cache

  entityEntries.forEach(entry => {
    if (!entry) {
      // in case no tracking was set up
      return;
    }
    flux.batch(() => {
      const { entity, localStorageKey } = entry;
      try {
        let hasData = true;
        const data = JSON.parse(LocalStorageWrapper.getItem(localStorageKey));
        if (!_.isArray(data)) {
          hasData = false;
          // invalid data => cleanup
          LocalStorageWrapper.removeItem(localStorageKey);
        }

        flux.dispatch(actionTypes.API_ADD_LOCAL_CACHE_ENTITY, {
          entity,
          localStorageKey,
          unwatchFn: entry.unwatchFn,
          hasData,
        });
        if (hasData) {
          flux.batch(() => {
            flux.dispatch(actionTypes.API_INJECT_ENTITY, {
              entity,
              data,
            });
          });
        }
      } catch (e) {
        console.warn(`Cannot load data for entity=${entity}`); //eslint-disable-line
      }
    });
  });
}

/**
 * Check if an entity has been loaded from localStorage but not fully synced with server
 * @param {String} entity
 */
export function isCachedLocally(entity) {
  const entry = flux.evaluate(['apiLocalCache', entity]);
  return entry && entry.get('hasData');
}

/**
 * Invalidate and flush the cache given entityData
 *
 * @param {Object} entityData
 * @param {String} entityData.entity
 * @param {Number} entityData.id
 */
export const inValidateCacheDataByEntity = entityData => {
  flux.dispatch(actionTypes.FLUSH_SINGLE_API_ENTITY, entityData);
};

/**
 * Checks if the request indicated by requestInfo was previously made
 * and if so checks if the requestInfo is 'stale', meaning it was
 * made too long ago to be considered a valid cache.
 *
 * Consider the request 'stale' only if the current time is not
 * within the stale period of the stored timestamp.
 *
 * If not stale, also return the responseHeaders which were cached.
 *
 * @param {Immutable.Map} requestInfo
 * @return {{
 *   {Boolean} isCached
 *   {Object|null} responseHeaders
 * }}
 */
export function requestCacheData(requestInfo) {
  const storedValue = flux.evaluate(['apiRequestCache', requestInfo]);
  if (!storedValue) {
    // Request is not cached.
    return {
      isCached: false,
      responseHeaders: null,
    };
  }
  const fetchDate = new Date(storedValue.get('fetchTimestamp'));
  const requestAgeInMinutes =
    (new Date(this.getCurrentDateTimeAsISOString()) - fetchDate) /
    MS_PER_MINUTE;
  const earliestFreshDate = new Date(
    new Date(this.getCurrentDateTimeAsISOString()) - ENTITY_STALE_PERIOD_IN_MS,
  );
  const ttl = fetchDate - earliestFreshDate;

  const ttlInMinutes = (ttl / MS_PER_MINUTE).toFixed(1);
  const messageSuffix =
    ttl > 0
      ? `(fresh for ${ttlInMinutes} min longer)`
      : `(${Math.abs(ttlInMinutes)} min stale)`;
  if (!__TEST__) {
    window.console.debug(
      `[API REQUEST CACHE][${requestInfo.get('entity')}.${requestInfo.get(
        'method',
      )}]` +
        `Request is ${requestAgeInMinutes.toFixed(
          1,
        )} min old. ${messageSuffix}`,
    );
  }

  const isCached = ttl > 0;

  return {
    isCached,
    responseHeaders: isCached ? storedValue.get('responseHeaders') : null,
  };
}

/**
 * Returns the current time as an ISO timestring.
 */
export function getCurrentDateTimeAsISOString() {
  return new Date().toISOString();
}

/**
 * Given a fetch all success when querying by ancestor (parent's parent) ensure
 * that all strict subset queries are marked as cached by dispatching an API_CACHE_REQUESTS
 * for all subquery request infos
 *
 * The subquery request info is generated by taking the api filters
 * and replacing the ancestor filter with direct parent filter:
 *  Ex:  Ancestor: Project
 *       Parent: View
 *       Entity: Tag = {
 *         id: 5,
 *         project_id: 1,
 *         view_id: 3,
 *       }
 *
 *   Ancestor Filter for Tags by ancestor project:
 *       { project_id: 1 }
 *
 *   Parent Filter for Tags by ancestor view:
 *       { view_id: 3 }
 *
 * @param {Object} ancestorFilters filters that were used for the ancestor query
 * @param {Object} ancestorDef definition of the ancestor filter
 * @param {Object} entityDef definition of the entity
 * @param {Array} resp response from the api for the ancestor query
 */
export function onAncestorFetchAllSuccess(
  ancestorFilters,
  ancestorDef,
  entityDef,
  resp,
) {
  if (_.isArray(resp)) {
    const requestsToCache = resp.map(result => {
      if (!ancestorDef.parent || !ancestorDef.parent.key) {
        throw new Error(
          'ancestorDef: must be an object with a parent key defined',
        );
      }

      const ancestorFilter = ancestorDef.parent.key;

      const parentFilter = {};
      const parentId = result[entityDef.parent.key];
      parentFilter[entityDef.parent.key] = parentId;

      const filtersByParent = _(ancestorFilters)
        .omit(ancestorFilter)
        .assign(parentFilter)
        .value();

      return createRequestInfo(
        entityDef.entity,
        constants.apiActions.FETCH_ALL,
        filtersByParent,
      );
    });

    flux.dispatch(actionTypes.API_CACHE_REQUESTS, {
      requestsToCache,
      fetchTimestamp: this.getCurrentDateTimeAsISOString(),
    });
  }
}

/**
 * unwatch and clear the local entity cache value if different from current parent entity
 * @param {String} parentIdKey
 * @param {Number|String} parentIdValue
 * @param {Array} entities
 */
export function unwatchExistingLocalCacheEntity(
  parentIdKey,
  parentIdValue,
  entities,
) {
  flux.batch(() => {
    entities.forEach(entity => {
      const localStorageKey = fns.getLocalStorageKey(
        parentIdKey,
        parentIdValue,
        entity,
      );
      const existingCacheEntity = flux.evaluate(['apiLocalCache', entity]);
      if (
        existingCacheEntity &&
        existingCacheEntity.get('localStorageKey') !== localStorageKey
      ) {
        existingCacheEntity.get('unwatchFn')();
        flux.dispatch(actionTypes.API_REMOVE_LOCAL_CACHE_ENTITY, {
          entity: existingCacheEntity.get('entity'),
        });
      }
    });
  });
}

export default {
  getCurrentDateTimeAsISOString,
  inValidateCacheDataByEntity,
  isCachedLocally,
  onAncestorFetchAllSuccess,
  requestCacheData,
  setupLocalEntityCache,
  unwatchExistingLocalCacheEntity,
};

import _ from 'lodash';

import constants from './constants';

/**
 * Transforms the department field name from display name to data name or vice versa where applicable
 * @param {String} departmentName
 */
export function transformDepartmentField(departmentName, forDisplay = true) {
  if (forDisplay) {
    return (
      constants.DEPARTMENT_DATA_TO_DISPLAY_MAP[departmentName] || departmentName
    ); // data -> display
  }
  return (
    _.findKey(
      constants.DEPARTMENT_DATA_TO_DISPLAY_MAP,
      value => value === departmentName,
    ) || departmentName
  ); // display -> data
}

export default {
  transformDepartmentField,
};

/**
 * InterestGroup Entity Module
 */
import actions from './actions';
import constants from './constants';
import entityDef from './entity_definition';
import getters from './getters';

export { actions, constants, entityDef, getters };

export default {
  actions,
  constants,
  entityDef,
  getters,
};

import CatalogActions from 'optly/modules/entity/catalog/actions';
import EventModuleActions from 'optly/modules/entity/event/actions';
import TagActions from 'optly/modules/entity/tag/actions';
import ViewActions from 'optly/modules/entity/view/actions';

import { loadingWhenFetchAllPages } from 'core/modules/loading/actions';
import { TABLE_IDS } from 'bundles/p13n/sections/implementation/section_module/constants';

import CatalogDashboard from './main_content';

const CATALOG_TABLE_ID = TABLE_IDS.CATALOG;

export const component = CatalogDashboard;

/**
 * Fetch all active Catalogs that are in the current account.
 * Fetch (in the background) events, tags, and views that are active in the current project.
 *
 * @param {Object} ctx
 * @param {Function} next
 */
export const routingSetup = (ctx, next) => {
  const projectID = ctx.currentProject.id;

  const active = {
    archived: false,
  };

  const activeByProjectID = {
    archived: false,
    project_id: projectID,
  };

  const activeCatalogsFetchAllPages = CatalogActions.fetchAllPages(active);

  /* Fetch (in the background) events, tags, and views that are active in the current project. */
  EventModuleActions.fetchAllPages(activeByProjectID);
  TagActions.fetchAll(activeByProjectID);
  ViewActions.fetchAllPages(activeByProjectID);

  /* Add CATALOG_TABLE_ID to the loading store until all pages fetch resolved to tell the PageableEntityTable when it's still loading. */
  loadingWhenFetchAllPages(CATALOG_TABLE_ID, activeCatalogsFetchAllPages);

  /* Only block loading of the Catalog Dashboard on the firstPage promise, other pages will be fetched in background. */
  activeCatalogsFetchAllPages.firstPage.then(next);
};

export default {
  component,
  routingSetup,
};

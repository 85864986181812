/**
 * Component wrapper around legacy optly.conditions.TimeAndDay
 *
 * This component is a bridge between the old jQuery style component
 * and integrating it into the and-or-targeting component
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const $ = require('jquery');

const LegacyTimeAndDay = require('./legacy_time_and_day');

module.exports = {
  data: {
    // holds reference to outside condition
    serialized: null,
  },

  methods: {
    /**
     * Handler for custom event triggered by the timeAndDay jQuery
     * component
     *
     * @param {Event} event
     * @param {String} data - serialized time/day data
     */
    _handleChange(event, data) {
      this.serialized = data;
    },
  },

  ready() {
    const timeAndDayComponent = new LegacyTimeAndDay(this.$el);
    // if passed serialized data, load into time/day picker
    if (this.serialized) {
      timeAndDayComponent.loadData(this.serialized);
    }

    // subscribe to jQuery component change event
    $(this.$el).on(
      LegacyTimeAndDay.CHANGE_EVENT,
      this._handleChange.bind(this),
    );

    this.$on('validateConditions', () => {
      this.$dispatch('validateConditionsResponse', {
        valid: timeAndDayComponent.validate(),
        // no need to supply a message here since the timeAndDayComponent has validation in UI
      });
    });

    // Convert input[type='date'] to use jquery substitute when its not supported.
    const $dateInputs = $(this.$el).find('input[type="date"]');
    if ($dateInputs.get(0) && $dateInputs.get(0).type === 'text') {
      $dateInputs.datepicker();
    }
  },
};

import { enums as ViewEnums } from 'optly/modules/entity/view';
import { cloneDeep } from 'lodash';
import keyMirror from 'optly/utils/key_mirror';

import UrlHelper from 'optly/services/url_helper';

import { DEFAULT_ACTIVATION_TYPE_OPTIONS } from './constants';

const getProjectSettingsUrl = projectId =>
  UrlHelper.projectSettingsImplementation(projectId);

const MODES_THAT_NEED_HELP_TEXT_EDGE = keyMirror({
  [ViewEnums.activationModes.MANUAL]: true,
  [ViewEnums.activationModes.DOM_CHANGED]: true,
  [ViewEnums.activationModes.URL_CHANGED]: true,
});

/**
 * Returns list of available activation modes for Edge project
 * @param {boolean} canUseViewActivationModeManual
 * @param {boolean} canUseViewActivationModeUrlChanged
 * @param {boolean} canUseViewActivationModeDomChanged
 * @returns {Array}
 */
export const getAvailableActivationModesForEdgeProject = (
  canUseViewActivationModeManual,
  canUseViewActivationModeUrlChanged,
  canUseViewActivationModeDomChanged,
) => {
  const ACTIVATION_TYPES_AVAILABLE_FOR_EDGE_PROJECT = {
    [ViewEnums.activationModes.IMMEDIATE]: true,
    [ViewEnums.activationModes.MANUAL]: canUseViewActivationModeManual,
    [ViewEnums.activationModes.URL_CHANGED]: canUseViewActivationModeUrlChanged,
    [ViewEnums.activationModes.DOM_CHANGED]: canUseViewActivationModeDomChanged,
  };

  // First, derive a shortlist of activation modes for which the account actually has permission.
  return Object.keys(ACTIVATION_TYPES_AVAILABLE_FOR_EDGE_PROJECT)
    .filter(key => {
      return ACTIVATION_TYPES_AVAILABLE_FOR_EDGE_PROJECT[key];
    })
    .map(activationModeId => {
      return DEFAULT_ACTIVATION_TYPE_OPTIONS[activationModeId];
    });
};

/**
 * Adds help text to activation modes for Edge projects (if necessary)
 * @param {Array} activationModes
 * @param {Function} getHelpLinkProps
 * @returns {Array} Activation modes decorated with link text, if applicable
 */
export const addHelpTextToActivationModesForEdgeProject = (
  activationModes,
  getHelpLinkProps,
) => {
  return activationModes.map(mode => {
    const modeToReturn = { ...mode };
    // reset all to null. This is required to override the default help text for Web view triggers.
    modeToReturn.linkText = null;
    modeToReturn.linkNewWindow = null;
    modeToReturn.linkURL = null;
    if (MODES_THAT_NEED_HELP_TEXT_EDGE[modeToReturn.value]) {
      return { ...modeToReturn, ...getHelpLinkProps() };
    }
    return modeToReturn;
  });
};

export default {
  getAvailableActivationModesForEdgeProject,
  addHelpTextToActivationModesForEdgeProject,
};

import $ from 'jquery';
import _ from 'lodash';
import Vue from 'vue';

import cloneDeep from 'optly/clone_deep';
import flux from 'core/flux';
import ui from 'core/ui';

import Editor from 'bundles/p13n/modules/editor';
import { actions as EditorIframeActions } from 'bundles/p13n/modules/editor_iframe';
import CodeMirror from 'lib/codemirror';

import EditableSelector from './editable_selector';
import actions from '../actions';

// disabling the following eslint rule which is firing a false positive error in this file
/* eslint-disable react/no-this-in-sfc */

const DEFAULT_CODE_MIRROR_OPTIONS = {
  // Custom written option to show errors in gutters
  // annotations: true,
  // Add Ctrl-Enter to the keymap so it will fire the keyHandled event when pressed
  extraKeys: {
    'Ctrl-Enter': () => {},
    'Ctrl-Space': 'autocomplete',
  },
  fixedGutter: false,
  // Required for annotations to work
  // gutters: ['CodeMirror-lint-markers'],
  lineNumbers: false,
  lineWrapping: true,
  matchBrackets: true,
  mode: 'css',
  tabSize: 2,
};

const ChangeEditorSidebarMixin = {
  /** ***********************************************************************************
   * This mixin adds in functionality for handling operations common to the various
   * change types edited in the editor sidebar.
   *
   * ASSUMPTIONS - Components that use this mixin should define the following properties in their config:
   *
   * {
   *  data: {
   *    activeFrameId: {string}
   *  },
   *  created() {
   *    flux.bindVueValues(this, {
   *      activeFrameId: Editor.getters.activeFrameId,
   *      changeListSidebarComponentConfig: Editor.getters.changeListSidebarComponentConfig, (if deleteChange is used)
   *      changeStatusMap: Editor.getters.changeStatusMap(),
   *      currentlyEditingChange: Editor.getters.currentlyEditingChange,
   *    });
   *  },
   * },
   *
   * All code mirror instances are expected to be stored in an object keyed by name:
   *
   *   this._codeMirrors
   *
   *   i.e.
   *      _codeMirrors: {
   *        style: null,
   *        html: null,
   *      },
   *
   * @author James Fox (james@optimizely.com)
   *************************************************************************************
   */
  methods: {
    /**
     * Apply the currentlyEditingChange to the iframe by applying all changes except custom code
     * and also fetch the elementInfo for the page.
     */
    applyChangesAndFetchElementInfo() {
      if (__DEV__) {
        console.debug(`[EDITOR_SIDEBAR] Applying change: ${this.currentlyEditingChange.id}`); // eslint-disable-line
      }
      const changes = flux.evaluateToJS(
        Editor.getters.currentlyEditingActionFormatted(
          true,
          this.currentlyEditingChange,
        ),
      );
      EditorIframeActions.applyChangesAndFetchElementInfo(
        this.activeFrameId,
        changes,
        this.currentlyEditingChange.selector,
        this.currentlyEditingChange.id,
      ).then(Editor.actions.setElementInfoForChange);
    },

    /**
     * Wrapper for confirmation dialog to confirm whether or not to discard current changes.
     *
     * @param {string} message (optional)
     * @return {Promise}
     */
    confirmDiscardChanges(message) {
      const defaultMessage = tr(
        'Leaving will discard your unsaved changes. Are you sure you want to go back?',
      );

      return ui.confirm({
        title: tr('Are you sure you want to discard changes?'),
        message: message || defaultMessage,
        confirmText: tr('Discard Changes'),
        isWarning: true,
      });
    },

    /**
     * Delete this change from the current change store.
     */
    deleteChange(event) {
      ui.confirm({
        title: tr('Are you sure you want to delete these changes?'),
        message: tr(
          'The deletion will not take effect until the next publish.',
        ),
        confirmText: tr('Delete Changes'),
        isWarning: true,
      }).then(() => {
        if (__DEV__) {
          console.debug(`[EDITOR_SIDEBAR] Deleted change: ${this.currentlyEditingChange.id}`); // eslint-disable-line
        }
        const changeName =
          this.currentlyEditingChange.name ||
          this.currentlyEditingChange.selector ||
          '';
        const saveDef = Editor.actions
          .deleteChange(this.currentlyEditingChange.id)
          .then(() => {
            ui.showNotification({
              message: tr(
                'Your changes on the element <b>{0}</b> have been deleted.',
                changeName,
              ),
              type: 'warning',
            });
            const changes = flux.evaluateToJS(
              Editor.getters.currentlyEditingActionFormatted(true),
            );
            EditorIframeActions.applyChanges(this.activeFrameId, changes);
            Editor.actions.unsetCurrentlyEditingChange();
            actions.showChangeListSidebar(
              this.changeListSidebarComponentConfig,
            );
          });
        ui.loadingWhen('change-editor-sidebar', saveDef);
      });
    },

    /**
     * Helper function to compute the display text for various attributes
     *
     * @param {string} attributeName Attribute to read from elementInfo
     *
     * @returns {string}
     */
    getAttribute(attributeName) {
      // If the change attribute is null, the user has not overridden the original value
      if (this.currentlyEditingChange.attributes[attributeName] !== null) {
        return this.currentlyEditingChange.attributes[attributeName];
      }
      if (this.currentlyEditingChange.elementCount === 1) {
        // Style changes are stored in elementInfo.style.cssText
        if (attributeName === 'style') {
          return this.currentlyEditingChange.elementInfo[0].style.cssText || '';
        }
        return this.currentlyEditingChange.elementInfo[0][attributeName] || '';
      }
      return '';
    },

    /**
     * Get background image from parent element if it has one
     *
     * @returns (string|null)
     */
    getBackgroundImageFromParentElement() {
      if (
        this.currentlyEditingChange.elementInfo &&
        this.currentlyEditingChange.elementCount === 1 &&
        this.currentlyEditingChange.elementInfo[0].parentBackground &&
        this.currentlyEditingChange.elementInfo[0].parentBackground
          .backgroundImage
      ) {
        return Editor.fns.getUrlFromStyleValue(
          this.currentlyEditingChange.elementInfo[0].parentBackground
            .backgroundImage,
        );
      }

      return null;
    },

    /**
     * Compute the status class that should be used for the given list of statuses.
     */
    getStatusClassForAttribute(statuses) {
      if (_.includes(statuses, 'dirty')) {
        return 'lego-form-field__item--dirty';
      }
      if (_.includes(statuses, 'draft')) {
        return 'lego-form-field__item--draft';
      }
      if (_.includes(statuses, 'live')) {
        return 'lego-form-field__item--live';
      }
      return '';
    },

    /**
     * Handle selector editing via the input textbox
     *
     * @param {object} payload
     */
    handleSelectorUpdateEvent(payload) {
      const { selector } = payload;
      if (this.currentlyEditingChange.selector === selector) {
        return;
      }

      if (!this.isEditingSelector) {
        this.toggleCodeMirrorComponentsDisabled(true);
      }
      EditableSelector.methods.handleSelectorUpdate.call(
        this,
        payload.event,
        selector,
      );
      Editor.actions.setChangeEditorCurrentSelectorType(
        payload.instanceIdentifier,
      );
    },

    handleToggleSelectorEditing(payload) {
      Editor.actions.setChangeEditorCurrentSelectorType(
        payload.instanceIdentifier,
      );
      Editor.actions.setChangeEditorIsEditingSelector(payload.isEditing);
    },

    /**
     * Create a CodeMirror instance with the specified mode from the specified element.
     * @param {HTML Element} el The element to create the CodeMirror instance from.
     * @param {string} changeType - The change type of the field being instantiated.
     * @param {string} mode The mode option to pass codeMirror.
     * @param {function} onChange - The function to call when the cm instance changes.
     * @return {CodeMirror}
     */
    initializeCodeMirrorField(el, changeType, mode, onChange) {
      const cmInstance = CodeMirror.fromTextArea(
        el,
        _.extend(cloneDeep(DEFAULT_CODE_MIRROR_OPTIONS), {
          mode,
        }),
      );

      // Allow the rest of initialize to finish before binding to change and setting the size.
      Vue.nextTick(() => {
        cmInstance.setSize(
          'auto',
          parseInt($(el.parentElement).css('min-height'), 10),
        );
        // Initialize change observation for this CodeMirror instance.
        cmInstance.on('change', function(type, cm, change) {  // eslint-disable-line
            // Only update the currentlyEditing change if the value is different and the change was initiated by the user.
            // Programatically setting the CodeMirror text is indicated with an origin value of 'setValue', and
            // shouldObserveCodeMirror is toggled when we call CodeMirror.autoFormatRange.
            if (
              !_.isEqual(change.text, change.removed) &&
              change.origin !== undefined &&
              change.origin !== 'setValue' &&
              this.shouldObserveCodeMirror
            ) {
              onChange(type, cm.getValue ? cm.getValue() : '');
            }
          }.bind(this, changeType),
        );

        // Prevent codemirror from scrolling the entire window when it adjusts itself
        // See 'scrollCursorIntoView' documentation here: https://codemirror.net/doc/manual.html#config
        cmInstance.on('scrollCursorIntoView', (instance, event) => {
          event.preventDefault();
        });
      });
      return cmInstance;
    },

    /**
     * Called when the resizable directive on the element surrounding CodeMirror fires a resize event.
     * @param event
     * @param uielement
     */
    resizeCodeMirror(event, uielement) {
      uielement.element.css('width', 'auto');
      const cmInstanceName = $(uielement.originalElement.context).data(
        'codemirror-ref',
      );
      this._codeMirrors[cmInstanceName].setSize(
        'auto',
        $(uielement.originalElement.context).height(),
      );
    },

    /**
     * NOTICE: Deprecated! This should be used for the Vue Selector Input only, use Editor module actions for other implementations
     *
     * Restore the selector highlighting
     */
    restoreSelectorState() {
      this.toggleCodeMirrorComponentsDisabled(false);
      Editor.actions.setChangeEditorCurrentSelectorType(null);
      const changeHighlighterOptions = Editor.fns.getHighlighterOptionsForElementChange(
        this.currentlyEditingChange,
      );
      EditableSelector.methods.restoreSelectorState.call(
        this,
        changeHighlighterOptions.selector,
        changeHighlighterOptions.dataOptlyId,
      );
    },

    /**
     * Revert attributes property to the saved value (dirty -> saved)
     * then apply and highlight the reverted change
     */
    revertChangeAttribute(type, property) {
      if (type === 'rearrange') {
        Editor.actions.revertChangeRearrange();
      } else if (type === 'selector') {
        Editor.actions.revertChangeSelector();
      } else if (type === 'css') {
        if (!property) {
          return;
        }
        Editor.actions.revertCSSProperty(property);
      } else {
        Editor.actions.revertChangeAttribute(type);
      }
      Editor.actions.applyCurrentlyEditingChange();
      Editor.actions.highlightCurrentSelector();
    },

    /**
     * Verifies that the currently DOM element is of a given type
     *
     * @param {string} nodeType
     * @returns {boolean}
     */
    selectedElementNodeNameMatches(nodeType) {
      return (
        this.currentlyEditingChange.elementCount > 0 &&
        this.currentlyEditingChange.elementInfo.length > 0 &&
        this.currentlyEditingChange.elementInfo[0].nodeName === nodeType
      );
    },

    /**
     * Sets the change attribute in the currently editing change store and applies it
     * setAttribute with no debounce
     * @param {string} propertyName
     * @param {string} value
     */
    setAttribute(propertyName, value) {
      Editor.actions.setChangeAttributesProperty(propertyName, value);
      Editor.actions.applyCurrentlyEditingChange();
    },

    /**
     * Debounce setAttribute so that rapid keystrokes don't cause issues with the input
     * box showing what was typed (becomes an issue with slower browsers and longer change lists).
     * @param {string} propertyName
     * @param {string} value
     */
    setAttributeDebounced: _.debounce(function(propertyName, value) {
      this.setAttribute(propertyName, value);
    }, 100),

    statusClassForCSSProperty(property) {
      return this.getStatusClassForAttribute([
        this.changeStatusMap.css[property],
      ]);
    },

    /**
     * Enable/disable the code mirror components
     *
     * @param {boolean} disabled
     */
    toggleCodeMirrorComponentsDisabled(disabled) {
      // have to manually add the disabled class to the code mirror components
      Object.values(this._codeMirrors).forEach(codeMirrorComponent => {
        codeMirrorComponent.setOption('readOnly', !!disabled);
        if (
          codeMirrorComponent.display &&
          codeMirrorComponent.display.wrapper
        ) {
          $(codeMirrorComponent.display.wrapper).toggleClass(
            'lego-input--disabled faded background--faint',
            disabled,
          );
        }
      });
    },
  },
};

export default ChangeEditorSidebarMixin;

import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';
import constants from '../constants';

const INITIAL_STATE = {
  currentlyEditingMetricWrapper: null,
  outlierFilter: constants.OUTLIER_FILTER_DEFAULT_STATE,
  workingMetricWrappers: [],
};

export default Store({
  getInitialState() {
    return toImmutable(INITIAL_STATE);
  },

  initialize() {
    this.on(
      actionTypes.METRIC_EDITOR_SET_CURRENTLY_EDITING_METRIC_WRAPPER,
      setCurrentlyEditingMetricWrapper,
    );
    this.on(
      actionTypes.METRICS_MANAGER_UPDATE_OUTLIER_FILTER,
      updateOutlierFilter,
    );
    this.on(
      actionTypes.METRICS_MANAGER_UPDATE_WORKING_METRIC_WRAPPERS,
      updateWorkingMetricWrappers,
    );
    this.on(
      actionTypes.METRICS_MANAGER_RESET_WORKING_METRIC_WRAPPERS,
      resetWorkingMetricWrappers,
    );
  },
});

function setCurrentlyEditingMetricWrapper(state, payload) {
  return state.set('currentlyEditingMetricWrapper', payload.metricWrapper);
}

function updateOutlierFilter(state, payload) {
  return state.set('outlierFilter', payload.outlierFilter);
}

function updateWorkingMetricWrappers(state, payload) {
  return state.set('workingMetricWrappers', payload.metricWrappers);
}

function resetWorkingMetricWrappers(state) {
  return state.set('workingMetricWrappers', toImmutable([]));
}

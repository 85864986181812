import sort from 'optly/utils/sort';
import { toImmutable } from 'optly/immutable';
import { featureFunction } from '@optimizely/js-sdk-lab/src/decorators';

import ExperimentationGroupConstants from 'optly/modules/entity/experimentation_group/constants';
import * as LayerEnums from 'optly/modules/entity/layer/enums';
import { enums as LayerExperimentEnums } from 'optly/modules/entity/layer_experiment';

import constants from './constants';

// Define sort function for filterForAvailableEntities
const sortByCreatedDateFn = sort.generateImmutableObjectSortFn([
  { field: 'created', type: 'date', dir: sort.DESC },
]);

/**
 * Returns explanation text guiding users as to how they can add entities to groups.
 * @param {Boolean} canUseABTestingV2
 * @param {Boolean} canUsePersonalization
 * @param {Boolean} isCustomProject
 * @returns {String}
 */
export function getGroupsExplanationText(
  canUseABTestingV2,
  canUsePersonalization,
  isCustomProject,
) {
  if (isCustomProject) {
    return constants.GroupExplanation.FULL_STACK;
  }
  if (canUseABTestingV2 && canUsePersonalization) {
    return constants.GroupExplanation.AB_AND_P13N;
  }
  if (canUseABTestingV2) {
    return constants.GroupExplanation.AB_ONLY;
  }
  if (canUsePersonalization) {
    return constants.GroupExplanation.P13N_ONLY;
  }
}

/**
 * Calculate and return available traffic in group from entities
 * @param {Immutable.List} entities
 * @returns {Number}
 */
export function calculateAvailableTraffic(entities) {
  let availableTraffic = ExperimentationGroupConstants.MAX_TRAFFIC_ALLOCATION;
  toImmutable(entities).forEach(entity => {
    availableTraffic -= entity.get('weight');
  });
  return availableTraffic;
}

/**
 * Filter entities that should be shown in the configure group dialog
 * this excludes entities (experiments or campaigns) that are in other groups or are archived
 * @param {Immutable.Map} currentlyEditingGroup
 * @param {Immutable.List} experimentationGroups
 * @param {Immutable.List} currentProjectLayers
 * @param {Immutable.List} currentProjectLayerExperiments
 * @param {Boolean} isCustomProject
 * @returns {Immutable.List}
 */
export const filterForAvailableEntities = featureFunction(
  'user_friendly_names',
)(function(
  currentlyEditingGroup,
  experimentationGroups,
  currentProjectLayers,
  currentProjectLayerExperiments,
  isCustomProject,
) {
  const entityIdsInGroups = experimentationGroups
    .filter(group => group.get('id') !== currentlyEditingGroup.get('id'))
    .map(group => group.get('entities').map(entity => entity.get('id')))
    .concat(
      currentlyEditingGroup.get('entities').map(entity => entity.get('id')),
    )
    .flatten()
    .toSet();

  // Only LayerExperiments with 'single_experiment' and 'multivariate' layer policies should be included
  const filteredLayerExperiments = currentProjectLayerExperiments.filter(
    layerExperiment =>
      [
        LayerEnums.policy.SINGLE_EXPERIMENT,
        LayerEnums.policy.MULTIVARIATE,
      ].includes(layerExperiment.get('layer_policy')),
  );

  // Only allow layers that have at least one experiment if they are single experiment (fix for CJS-4348)
  const filteredLayers = currentProjectLayers.filter(
    layer =>
      layer.get('policy') !== LayerEnums.policy.SINGLE_EXPERIMENT ||
      layer.get('experiment_ids').size,
  );

  const entitiesToFilter = isCustomProject
    ? filteredLayerExperiments
    : filteredLayers;

  return entitiesToFilter
    .filter(entity => entity.get('id', null))
    .filterNot(entity => {
      // Filter out entities that exist in this or other groups
      // Filter out entities that are archived
      const entityId = entity.get('id');
      const entityStatus = isCustomProject
        ? entity.get('actual_status')
        : entity.get('status');
      return (
        !!entityIdsInGroups.get(entityId) ||
        entityStatus === LayerEnums.entityStatus.ARCHIVED ||
        entityStatus === LayerEnums.entityStatus.CONCLUDED
      );
    })
    .map(entity => {
      // Create the entity Map for each experiment or campaign
      const entityName = entity.get('name');
      const entityKey = entity.get('key');
      const entityStatus = isCustomProject
        ? entity.get('actual_status')
        : entity.get('status');
      const entityData = {
        created: entity.get('created'),
        id: entity.get('id'),
        name: isCustomProject ? entityKey : entityName,
        status: entityStatus,
      };
      if (isCustomProject) {
        entityData.environments = entity.get('environments');
        if (this.user_friendly_names) {
          entityData.name = entityName;
          entityData.key = entityKey;
        }
      }
      return toImmutable(entityData);
    })
    .sort(sortByCreatedDateFn);
});

/**
 * Returns true if changing the argument entity's traffic should set the warning
 * flag, so that a warning popup will appear when the user saves the group.
 * @param {Immutable.Map} entity
 * @param {Boolean} isCustomProject
 * @returns {Boolean}
 */
export function shouldFlagForWarningOnTrafficChange(entity, isCustomProject) {
  return (
    isCustomProject &&
    (entity.get('environments') || toImmutable({})).some(
      envInfo =>
        envInfo.get('status') ===
        LayerExperimentEnums.EnvironmentStatus.RUNNING,
    )
  );
}

export default {
  getGroupsExplanationText,
  calculateAvailableTraffic,
  filterForAvailableEntities,
  shouldFlagForWarningOnTrafficChange,
};

/**
 * Entity Definition for metric_template
 */
export const entity = 'metrics';

export const parent = {
  entity: 'projects',
  key: 'project_id',
};

export default {
  entity,
  parent,
};

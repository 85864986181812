/**
 * Audience Combinations Builder Simple:
 * This audience combinations builder only renders the simple view, ie does not allow a user to edit complex audience combinations.
 * It is currently only used in the Flags UI.
 *
 */
import React, { useEffect, useRef} from 'react';

import { toImmutable } from 'optly/immutable';

import { fns as ComponentModuleFns } from '../component_module';
import { AudienceCombinationsBuilder } from '../audience_combinations_builder';
import {
  AudienceCondition,
} from '../component_module/types';



export interface FlagsAudienceCombinationsBuilderProps {
  audienceConditions: AudienceCondition[];
  canEditAudience: boolean;
  onChange: ({
    audienceConditions,
  }: {
    audienceConditions: AudienceCondition[];
  }) => void;
}

interface FlagsAudienceCombinationsBuilderPropsWithInjectedData
  extends FlagsAudienceCombinationsBuilderProps {
  audiences: [{ [key: string]: any }];
  projectId: number;
}

const FlagsAudienceCombinationsBuilder = ({
  audiences,
  audienceConditions = [],
  canEditAudience = true,
  onChange,
  projectId,
}: FlagsAudienceCombinationsBuilderPropsWithInjectedData) => {
  const audienceCombinationsBuilderRef = useRef(null);
  useEffect(() => {
    if (audienceCombinationsBuilderRef.current) {
      // @ts-ignore
      audienceCombinationsBuilderRef.current.reinitializeComponent();
    }
  }, [audiences]);

  const audienceNamesMap = ComponentModuleFns.computeSelectedAudienceNamesMapPOJO(
    audienceConditions,
    audiences,
  );

  return <AudienceCombinationsBuilder
    audienceNamesMap={audienceNamesMap}
    audiences={toImmutable(audiences)}
    isFlagProject={true}
    isWebProject={false}
    isFullStackProject={false}
    inForm={false}
    projectId={projectId}
    ref={audienceCombinationsBuilderRef}
    shouldShowAudienceCombinationOptions={true}
    shouldShowCodeModeButton={false}
    audienceConditions={toImmutable(audienceConditions)}
    canEditAudience={canEditAudience}
    shouldShowAudienceWithHelper={false}
    onSelectionChange={({ audienceConditions, isValidAudienceConfig}: any) => {
      if (audienceConditions.size === 1) {
        audienceConditions = toImmutable([]);
      }
      onChange({
        audienceConditions: Object.freeze(audienceConditions.toJS()),
      });
    }}
  />
};

export default FlagsAudienceCombinationsBuilder;

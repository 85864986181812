/*
 * Service to upload assets to S3
 *
 * @module
 */

const $ = require('jquery');
const config = require('atomic-config');
const UrlUtil = require('optly/utils/url');

/**
 * Upload the given file to S3. Must be logged in to do so.
 * @param {File} file
 * @param {String} filename filename to use
 * @param {String} url URL where to upload file
 * @param {String=} projectId The projectId associated with this upload.
 * @return $.Deferred
 */
exports.upload = function(file, filename, url, projectId) {
  const deferred = $.Deferred();
  const defaultUrl = UrlUtil.optimizelyHRDUrl('/tool/upload');
  filename = filename || file.name;
  url = url || defaultUrl;
  if (!filename) {
    deferred.reject(tr('Could not upload a file without filename'));
    return deferred;
  }
  if (filename && filename.indexOf('&') !== -1) {
    rejectWithError(
      deferred,
      filename,
      tr("there cannot be a '&' in the filename"),
    );
    return deferred;
  }

  let postUrl = `${url}?name=${filename}`;
  if (projectId) {
    postUrl += `&project_id=${projectId}`;
  }

  // TODO: refactor this to use fetch instead of XMLHttpRequest
  const request = new XMLHttpRequest(); // eslint-disable-line fetch/no-xhr
  request.onreadystatechange = handleReadyStateChange.bind(
    null,
    request,
    deferred,
    filename,
  );
  request.onerror = rejectWithError.bind(
    null,
    deferred,
    filename,
    tr('an error occurred'),
  );
  request.open('POST', postUrl, true);
  request.setRequestHeader('Content-Type', 'application/octet-stream');
  const csrfTokenValue = config.get('csrf', '');
  if (csrfTokenValue) {
    request.setRequestHeader('X-csrf-token', csrfTokenValue);
  }
  request.withCredentials = true;
  request.send(file);
  return deferred;
};

/**
 * Handle a 200 response from the server by resolving/rejecting deferred with response url
 * @param {$.Deferred} deferred
 * @param {string} fileName
 * @param {string} responseText
 */
function handleSuccess(deferred, fileName, responseText) {
  try {
    const response = JSON.parse(responseText);
    if (response.url) {
      deferred.resolve(response.url);
      return;
    }
  } catch (e) {
    rejectWithError(deferred, fileName, tr('an error occurred'));
  }

  rejectWithError(deferred, fileName, tr('an error occurred'));
}

/**
 * Handle a 200 response from the server by resolving/rejecting deferred with response url
 * @param {$.Deferred} deferred
 * @param {string} fileName
 * @param {string} fileName
 */
function handleReadyStateChange(request, deferred, fileName) {
  if (request.readyState === 4) {
    let error;

    switch (request.status) {
      case 200:
        handleSuccess(deferred, fileName, request.responseText);
        return;
      case 400:
        // 400 errors strings are localized on the server
        const response = JSON.parse(request.responseText);
        error = response.error;
        break;
      case 413:
        error = tr('the file is too large');
        break;
      default:
        error = tr('an error occurred');
    }
    rejectWithError(deferred, fileName, error);
  }
}

/**
 * Helper function to resolve with a given error message
 * @param {$.Deferred} deferred
 * @param {string} fileName
 * @param {string} error
 */
function rejectWithError(deferred, fileName, error) {
  deferred.reject(tr('Could not upload {0} because {1}.', fileName, error));
}

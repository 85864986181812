import React from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { parse as parseLinkHeader } from 'http-link-header';
import { feature, variable } from '@optimizely/js-sdk-lab/src/decorators';

import ui from 'core/ui';
import { isLoading } from 'core/modules/loading/getters';
import { connect } from 'core/ui/decorators';

import ChampagneEnums from 'optly/modules/optimizely_champagne/enums';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import PublicApiConsumerActions from 'optly/modules/public_api_consumer/actions';
import {
  getSearchParamValue,
  setSearchParams,
} from 'optly/modules/dashboard/fns';

import ComponentModuleConstants from './component_module/constants';

const hoistStaticsAndForwardRefs = (Target, Source, displayName) => {
  const forwardRef = (props, ref) => (
    <Target forwardedProps={props} forwardedRef={ref} />
  );
  forwardRef.displayName = `${displayName}(${Source.displayName ||
    Source.name ||
    'Component'})`;
  return hoistNonReactStatics(React.forwardRef(forwardRef), Source);
};

export const withEntitySearchPropTypes = {
  currentSearchOptions: PropTypes.shape({
    archived: PropTypes.bool,
    expand: PropTypes.arrayOf(
      Object.values(ComponentModuleConstants.searchExpandFields),
    ),
    layerType: PropTypes.oneOfType([
      PropTypes.oneOf(
        Object.values(ComponentModuleConstants.searchFilterableLayerTypes),
      ),
      PropTypes.arrayOf(
        PropTypes.oneOf(
          Object.values(ComponentModuleConstants.searchFilterableLayerTypes),
        ),
      ),
    ]),
    order: PropTypes.oneOf(
      Object.values(ComponentModuleConstants.searchSortOrders),
    ),
    page: PropTypes.number,
    perPage: PropTypes.number,
    projectId: PropTypes.number,
    query: PropTypes.string.isRequired,
    sort: PropTypes.oneOf(
      Object.values(ComponentModuleConstants.searchSortableKeys),
    ),
    status: PropTypes.oneOfType([
      PropTypes.oneOf(
        Object.values(ComponentModuleConstants.searchFilterableStatuses),
      ),
      PropTypes.arrayOf(
        Object.values(ComponentModuleConstants.searchFilterableStatuses),
      ),
    ]),
    type: PropTypes.oneOfType([
      PropTypes.oneOf(
        Object.values(ComponentModuleConstants.searchEntityTypes),
      ),
      PropTypes.arrayOf(
        PropTypes.oneOf(
          Object.values(ComponentModuleConstants.searchEntityTypes),
        ),
      ),
    ]),
  }).isRequired,
  totalSearchPages: PropTypes.number.isRequired,
  currentSearchResults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeSearchPage: PropTypes.func.isRequired,
  changeSearchFilters: PropTypes.func.isRequired,
  changeTypeFilter: PropTypes.func.isRequired,
  changeSearchSort: PropTypes.func.isRequired,
  changeSearchQuery: PropTypes.func.isRequired,
  reloadSearchPage: PropTypes.func.isRequired,
  submitSearchQuery: PropTypes.func.isRequired,
  waitForReindex: PropTypes.func.isRequired,
  isSearchLoading: PropTypes.bool.isRequired,
  searchApiStatus: PropTypes.shape({
    canListEntities: PropTypes.bool.isRequired,
    canQueryEntities: PropTypes.bool.isRequired,
  }).isRequired,
  syncWithSearchParams: PropTypes.bool,
};

export default function withEntitySearch(
  defaultSearchOptions = {},
  featureKeys = {},
) {
  const canQueryFeatureKey =
    featureKeys.canQueryFeatureKey ||
    // https://app.optimizely.com/v2/projects/8351122416/features/17132780494#modal
    ChampagneEnums.FEATURES.use_search_api_for_sonic_search_boxes.FEATURE_KEY;
  const canListFeatureKey =
    featureKeys.canListFeatureKey ||
    // https://app.optimizely.com/v2/projects/8351122416/features/17128570349#modal
    ChampagneEnums.FEATURES.use_search_api_for_sonic_tables.FEATURE_KEY;

  return WrappedComponent => {
    @connect({
      currentProjectId: CurrentProjectGetters.id,
      isSearchLoading: isLoading(ComponentModuleConstants.LOADING_ID),
    })
    @feature(canListFeatureKey)
    @feature(canQueryFeatureKey)
    class ComponentWithEntitySearch extends React.Component {
      static propTypes = {
        currentProjectId: PropTypes.number.isRequired,
        forwardedProps: PropTypes.shape({}),
        isSearchLoading: PropTypes.bool.isRequired,
      };

      static defaultProps = {
        forwardedProps: {},
      };

      state = {
        currentSearchOptions: (() => {
          const searchOptions = {
            query: '',
            page: 1,
            perPage: ComponentModuleConstants.MAX_PAGE_SIZE,
            type: [],
            sort: null,
            order: null,
            status: null,
            archived: null,
            projectId: null,
          };
          if (defaultSearchOptions.syncWithSearchParams) {
            searchOptions.page =
              parseInt(getSearchParamValue('page'), 10) || searchOptions.page;
            searchOptions.query =
              getSearchParamValue('query') || searchOptions.query;
            searchOptions.archived = getSearchParamValue('archived') === 'true';
            searchOptions.status =
              getSearchParamValue('status') || searchOptions.status;
          }
          return searchOptions;
        })(),
        totalPages: 1,
        results: [],
      };

      // Make sure to add this variable to feature passed above.
      @variable('entity_update_reindex_delay_ms')
      entityUpdateReindexDelayMS = 1000;

      isFirstQuery = true;

      canQueryEntitiesUsingSearchAPI = () => !!this[canQueryFeatureKey];

      canListEntitiesUsingSearchAPI = () => !!this[canListFeatureKey];

      waitForReindex = () => {
        return new Promise(resolve =>
          setTimeout(resolve, this.entityUpdateReindexDelayMS),
        );
      };

      changeSearchPage = page => {
        const { currentSearchOptions } = this.state;
        if (defaultSearchOptions.syncWithSearchParams) {
          setSearchParams({
            page,
          });
        }
        return this.submitSearchQuery({
          ...currentSearchOptions,
          page,
        });
      };

      changeSearchSort = (sort, order) => {
        const { currentSearchOptions } = this.state;
        if (defaultSearchOptions.syncWithSearchParams) {
          setSearchParams({
            sort,
          });
        }
        return this.submitSearchQuery({
          ...currentSearchOptions,
          sort,
          order,
        });
      };

      changeSearchFilters = (status, archived) => {
        const { currentSearchOptions } = this.state;
        if (defaultSearchOptions.syncWithSearchParams) {
          setSearchParams({
            status,
            archived,
          });
        }
        return this.submitSearchQuery({
          ...currentSearchOptions,
          status,
          archived,
        });
      };

      changeTypeFilter = layerType => {
        const { currentSearchOptions } = this.state;
        if (defaultSearchOptions.syncWithSearchParams) {
          setSearchParams({
            layerType,
          });
        }
        return this.submitSearchQuery({
          ...currentSearchOptions,
          layerType,
        });
      };

      changeSearchQuery = query => {
        const { currentSearchOptions } = this.state;
        if (defaultSearchOptions.syncWithSearchParams) {
          setSearchParams({
            query,
          });
        }
        return this.submitSearchQuery({
          ...currentSearchOptions,
          query,
        });
      };

      reloadSearchPage = () => {
        const { currentSearchOptions } = this.state;
        return this.submitSearchQuery(currentSearchOptions);
      };

      submitSearchQuery = (passedSearchOptions = {}) => {
        const { currentProjectId, forwardedProps } = this.props;

        // Create new search options object based on passed and default options
        const currentSearchOptions = {
          ...defaultSearchOptions,
          ...passedSearchOptions,
          projectId: currentProjectId,
          type: forwardedProps.entityTypes || defaultSearchOptions.type,
        };

        // Ensure the right conditions before executing the query
        if (
          currentSearchOptions.perPage > ComponentModuleConstants.MAX_PAGE_SIZE
        ) {
          return Promise.reject(
            new Error(
              `Cannot request ${currentSearchOptions.perPage} results per page. Max page size = ${ComponentModuleConstants.MAX_PAGE_SIZE}.`,
            ),
          );
        }

        if (
          !this.canQueryEntitiesUsingSearchAPI() &&
          currentSearchOptions.query &&
          currentSearchOptions.query !== ''
        ) {
          return Promise.reject(
            new Error('Text-based queries cannot be performed at this time.'),
          );
        }

        const {
          currentSearchOptions: prevSearchOptions,
          totalPages: prevTotalPages,
        } = this.state;

        const shouldResetPagination =
          currentSearchOptions.query !== prevSearchOptions.query ||
          currentSearchOptions.status !== prevSearchOptions.status ||
          currentSearchOptions.archived !== prevSearchOptions.archived ||
          currentSearchOptions.projectId !== prevSearchOptions.projectId ||
          currentSearchOptions.layerType !== prevSearchOptions.layerType;

        if (shouldResetPagination) {
          currentSearchOptions.page = 1;
        }

        if (defaultSearchOptions.syncWithSearchParams && this.isFirstQuery) {
          currentSearchOptions.page =
            parseInt(getSearchParamValue('page'), 10) ||
            currentSearchOptions.page;
          currentSearchOptions.query =
            getSearchParamValue('query') || currentSearchOptions.query;
          currentSearchOptions.archived =
            getSearchParamValue('archived') === 'true';
          currentSearchOptions.status =
            getSearchParamValue('status') || currentSearchOptions.status;
          currentSearchOptions.layerType =
            getSearchParamValue('layerType') || currentSearchOptions.layerType;
        }

        // Generate query parameter object based on current search options
        const queryParams = {
          perPage:
            currentSearchOptions.perPage ||
            ComponentModuleConstants.MAX_PAGE_SIZE,
          page: currentSearchOptions.page || 1,
          projectId: currentSearchOptions.projectId,
          query:
            (currentSearchOptions.query &&
              encodeURIComponent(currentSearchOptions.query)) ||
            '',
        };

        if (currentSearchOptions.type) {
          queryParams.type = currentSearchOptions.type;
        }

        if (currentSearchOptions.sort) {
          queryParams.sort = currentSearchOptions.sort;
        }

        if (currentSearchOptions.order) {
          queryParams.order = currentSearchOptions.order;
        }

        if (currentSearchOptions.status) {
          queryParams.status = currentSearchOptions.status;
        }

        if (currentSearchOptions.layerType) {
          queryParams.type_expand = currentSearchOptions.layerType;
        }

        if (typeof currentSearchOptions.archived === 'boolean') {
          queryParams.archived = currentSearchOptions.archived;
        }

        if (currentSearchOptions.expand) {
          queryParams.expand = currentSearchOptions.expand;
        }

        // Determine whether to use the Search or Experiment Summaries API
        const useSearchAPIForRequest =
          (this.canQueryEntitiesUsingSearchAPI() &&
            currentSearchOptions.query !== '') ||
          (this.canListEntitiesUsingSearchAPI() &&
            currentSearchOptions.query === '');
        const endpoint = useSearchAPIForRequest
          ? ComponentModuleConstants.SEARCH_API_ENDPOINT
          : ComponentModuleConstants.EXPERIMENT_SUMMARIES_API_ENDPOINT;

        // Execute query
        this.isFirstQuery = false;
        const searchFetchPromise = PublicApiConsumerActions.fetchPage(
          endpoint,
          { queryParams },
        )
          .then(response => {
            if (!response.ok) {
              throw new Error('Could not execute query.');
            }

            // Parse the pagination headers and pass it to the next .then()
            let totalSearchPages = Promise.resolve(
              shouldResetPagination ? 1 : prevTotalPages,
            );
            if (response.headers.has('Link')) {
              const link = parseLinkHeader(response.headers.get('Link'));
              if (link.rel('last')[0]) {
                const url = new URL(link.rel('last')[0].uri);
                const lastPage = url.searchParams.get('page');
                // If the current page doesn't have a last page, there is only 1 page
                totalSearchPages = Number(lastPage) || 1;
              }
            }

            return Promise.all([totalSearchPages, response.json()]);
          })
          .then(([totalPages, results]) => {
            this.setState({
              currentSearchOptions,
              totalPages,
              results,
            });

            return Promise.resolve(results);
          });

        ui.loadingWhen(ComponentModuleConstants.LOADING_ID, searchFetchPromise);

        return searchFetchPromise;
      };

      render() {
        const { isSearchLoading, forwardedProps } = this.props;
        const { currentSearchOptions, totalPages, results } = this.state;

        return (
          <WrappedComponent
            currentSearchOptions={currentSearchOptions}
            totalSearchPages={totalPages}
            currentSearchResults={results}
            changeSearchPage={this.changeSearchPage}
            changeSearchFilters={this.changeSearchFilters}
            changeTypeFilter={this.changeTypeFilter}
            changeSearchSort={this.changeSearchSort}
            changeSearchQuery={this.changeSearchQuery}
            reloadSearchPage={this.reloadSearchPage}
            submitSearchQuery={this.submitSearchQuery}
            waitForReindex={this.waitForReindex}
            isSearchLoading={isSearchLoading}
            searchApiStatus={{
              canQueryEntities: this.canQueryEntitiesUsingSearchAPI(),
              canListEntities: this.canListEntitiesUsingSearchAPI(),
            }}
            {...forwardedProps}
          />
        );
      }
    }

    return hoistStaticsAndForwardRefs(
      ComponentWithEntitySearch,
      WrappedComponent,
      'withEntitySearch',
    );
  };
}

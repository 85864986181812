import React from 'react';
import { OptimizelyFeature } from '@optimizely/react-sdk';

import { SortableTableHeader } from 'react_components/sortable_table';

export default function EventCardTableHeader() {
  return (
    <tr>
      <SortableTableHeader field="name" type="string" width="30%">
        Name
      </SortableTableHeader>
      <SortableTableHeader field="api_name" type="string" width="28%">
        API Name
      </SortableTableHeader>
      <OptimizelyFeature feature="usage_inspector_events">
        {isEnabled =>
          isEnabled && (
            <SortableTableHeader
              field="experiment_count"
              type="number"
              width="10%">
              Experiments
            </SortableTableHeader>
          )
        }
      </OptimizelyFeature>
      <SortableTableHeader field="id" type="number" width="15%">
        ID
      </SortableTableHeader>
      <SortableTableHeader field="event_type" type="string" width="10%">
        <span className="nowrap">Type</span>
      </SortableTableHeader>
      <SortableTableHeader field="dropdown" type="string" width="7%" />
    </tr>
  );
}

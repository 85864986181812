import React from 'react';

import MissingSVG from '/static/img/p13n/interest-group-unavailable.svg';

import ProcessingSVG from '/static/img/p13n/audience-detail-primary-metric.svg';

import { AdaptiveAudienceConditionsLink } from 'bundles/p13n/components/messaging/adaptive_audience';

/**
 * Empty state when an InterestGroup does not exist for the current keywords.
 */
const Missing = () => (
  <div
    className="flex--dead-center"
    data-test-section="interest-group-card-empty-state-missing">
    <div className="text--center">
      <img className="soft-double" alt="" src={MissingSVG} />
      <h5 className="soft--bottom push-half--left">
        Eligible Visitors Unavailable
      </h5>
      <p className="width--300">
        After saving this audience, we&apos;ll begin processing these eligible
        visitors. This typically takes up to 24 hours. Once processed,
        you&apos;ll be able to refine this condition, and this audience will
        begin targeting visitors.&nbsp;
        <AdaptiveAudienceConditionsLink />
      </p>
    </div>
  </div>
);

/**
 * Empty state when the InterestGroup for the current keywords is not yet available (it's processing).
 */
const Processing = () => (
  <div
    className="flex--dead-center"
    data-test-section="interest-group-card-empty-state-processing">
    <div className="text--center">
      <img className="soft-double" alt="" src={ProcessingSVG} />
      <h5 className="soft--bottom push-half--left">
        We&apos;re processing these eligible visitors.
      </h5>
      <p className="micro width--300">
        Hang tight. This typically takes up to 24 hours. Once processed,
        you&apos;ll be able to refine this condition, and this audience will
        begin targeting visitors.&nbsp;
        <AdaptiveAudienceConditionsLink />
      </p>
    </div>
  </div>
);

export default {
  Missing,
  Processing,
};

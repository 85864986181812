/**
 * Module containing methods representing routes in the application
 * NOTE: From now on Url helper functions for p13n go into `p13n/url_helper`,
 *       this file is DEPRECATED
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const $ = require('jquery');
const _ = require('lodash');

const uri = require('optly/utils/uri');
const UrlUtil = require('optly/utils/url');
const NavConstants = require('optly/services/navigation').default;

const sprintf = require('sprintf');

const ProjectEnums = require('optly/modules/entity/project/enums');

const router = {};

/**
 * Return a url for the p13n results page for all audiences (default)
 * @return {String}
 */
router.p13nAllAudiences = function(projectId, layerId) {
  return sprintf('/v2/projects/%s/results/%s', projectId, layerId);
};

/**
 * Returns a url for the editor. If an experimentId is provided, we just direct to /edit
 * for that experimentId. Else, we append the necessary information for /edit to create the experiment.
 *
 * @param {object} editorParams
 * @return {string}
 */
router.experimentEdit = function(editorParams) {
  const experimentId = editorParams.experimentId;
  const loc = uri.create('/edit');

  if (experimentId) {
    loc.param('experiment_id', experimentId);
    if (editorParams.platform) {
      loc.param('platform', editorParams.platform);
    }
    return loc.toString();
  }
  // This is the exact logic from optly.common.url.generateEditorUrl
  const name = editorParams.name ? $.trim(editorParams.name) : '';
  const url = editorParams.url ? $.trim(editorParams.url) : '';
  const projectId = editorParams.projectId
    ? $.trim(editorParams.projectId)
    : '';

  if (!url) {
    return '';
  }

  loc.param('url', url);

  if (name) {
    loc.param('name', name);
  }

  if (projectId) {
    loc.param('project_id', projectId);
  }
  return loc.toString();
};

/**
 * Returns a url for the experiment in the dashboard
 *
 * @param {object} editorParams
 * @return {string}
 */
router.customProjectExperimentEdit = function(editorParams) {
  const projectId = editorParams.projectId;
  const experimentId = editorParams.experimentId;
  let urlBase;
  let url;
  if (projectId) {
    if (experimentId) {
      urlBase = sprintf('/projects/%s/experiments/%s', projectId, experimentId);
      url = uri.create(urlBase);
    } else {
      urlBase = sprintf('/projects/%s', projectId);
      url = uri.create(urlBase);
    }
  } else {
    url = uri.create('/projects');
  }
  return url;
};

/**
 * Returns a url for the classic Audiences home (projects) with specified audience selected
 *
 * @param {number} projectId
 * @param {number} audienceId
 * @return {string}
 */
router.classicAudiencesHome = function(projectId, audienceId) {
  return sprintf('/projects/%s/audiences/%s', projectId, audienceId);
};

/**
 * Returns a url for the classic audiences attributes dashboard with specified dimension selected
 * @param {number} projectId
 * @param {number} dimensionId
 * @return {string}
 */
router.classicDimensionsDashboard = function(projectId, dimensionId) {
  return sprintf('/projects/%s/dimensions/%s', projectId, dimensionId);
};
/**
 * Returns a url for the Audiences home (projects).
 *
 * @param {number} projectId
 * @return {string}
 */
router.p13nAudiencesHome = function(projectId) {
  return sprintf('/v2/projects/%s/audiences', projectId);
};

/**
 * Returns a url for the p13n audiences attributes dashboard
 * @param {number} projectId
 * @return {string}
 */
router.p13nAttributesDashboard = function(projectId) {
  return sprintf('/v2/projects/%s/audiences/attributes', projectId);
};

/**
 * Returns a url for the audience editor (create).
 *
 * @param {number} projectId
 * @return {string}
 */
router.p13nAudienceCreate = function(projectId) {
  const parts = [projectId, 'audiences', 'create'];
  const loc = uri.create(`/v2/projects/${parts.join('/')}`);

  return loc.toString();
};

/**
 * Returns a url for the layer editor.
 *
 * @param {number} projectId
 * @param {number} layerId
 * @return {string}
 */
router.layerEdit = function(projectId, layerId) {
  return sprintf('/v2/projects/%s/campaigns/%s', projectId, layerId);
};

/**
 * Returns a url for an A/B layer results.
 * @param {number} projectId
 * @param {number} layerId
 * @param {object} opts optional query parameters
 * @return {string}
 */
router.aBLayerResults = function(projectId, layerId, opts) {
  const urlBase = sprintf('/results2?layer_id=%s', layerId);
  const url = uri.create(urlBase);
  if (opts) {
    url.param(opts);
  }
  return UrlUtil.optimizelyHRDUrl(url.toString());
};

/**
 * Returns a url for the layer manager.
 *
 * @param {number} projectId
 * @param {number} layerId
 * @return {string}
 */
router.layerDetail = function(projectId, layerId) {
  return sprintf('/v2/projects/%s/layers/%s/detail', projectId, layerId);
};

/**
 * @param {number} experimentId
 * @return {string}
 */
router.experimentResults = function(experimentId) {
  const loc = uri.create('/results');
  loc.param('experiment_id', experimentId);
  return loc.toString();
};

/**
 * @param {number=} projectId
 * @param {string=} view
 * @return {string}
 */
router.dashboard = function(projectId, view) {
  // @TODO(daniel): Depricate this function and use the
  // more robust dashboard routing function.
  return router.dashboardTab(projectId, view);
};

/**
 * Root path for project.
 * @param {number} projectId
 * @param {string} projectPlatform
 * @return {string}
 */
router.projectHome = function(projectId, projectPlatform) {
  if (projectPlatform === ProjectEnums.project_platforms.CUSTOM) {
    return router.oasisHome(projectId);
  }
  return router.layersHome(projectId);
};

/**
 * Root path for personalization.
 * @return {string}
 */
router.layersHome = function(projectId) {
  if (!projectId) {
    return '/v2';
  }
  return sprintf('/v2/projects/%s', projectId);
};

/**
 * @return {Number} projectId
 * @return {String}
 */
router.layersCreate = function(projectId) {
  return sprintf('/v2/projects/%s/campaigns/create', projectId);
};

/**
 * Creates a dashboard link based the current project id, the subtab (ex: 'experiments')
 * the selected item id (can be null) and an object of query params (for filtering)
 *
 * @param {number} projectId
 * @param {string?} subTab defaults to 'experiments' if not provided
 * @param {number?} itemId the selected item id
 * @param {object?} params query params
 * @return {string}
 */
router.dashboardTab = function(projectId, subTab, itemId, params) {
  if (!projectId) {
    // if no arguments than just goto dashboard
    return '/projects';
  }

  subTab = subTab || 'experiments';
  const parts = ['/projects', projectId, subTab];
  const queryParams = [];
  if (itemId) {
    parts.push(itemId);
  }
  let url = parts.join('/');

  _.each(params, (val, key) => {
    queryParams.push(`${key}=${encodeURIComponent(val)}`);
  });

  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }

  return url;
};

/**
 * Creates an account dashboard link based on the tab (ex: 'apps')
 *
 * @param {string?} tab defaults to 'apps' if not provided
 * @param {string?} a sub tab like 'plan' or 'overview'
 * @return {string}
 */
router.accountDashboardTab = function(tab, subtab) {
  tab = tab || 'apps';
  const parts = ['/accountsettings', tab];
  if (subtab) {
    parts.push(subtab);
  }

  return parts.join('/');
};

/**
 * Creates a link to the the email NUX SDK instructions page
 * for a project.
 *
 * @param {number} projectId
 * @param {string} projectPlatform
 * @param {string} emailAction What they're emailing (ex. update vs install)
 * @return {string}
 */
router.sdkEmailInstructions = function(
  projectId,
  projectPlatform,
  emailAction,
) {
  const loc = uri.create(`/mobile/${projectPlatform}/email`);
  loc.param('project_id', projectId);
  loc.param('email_action', emailAction);
  return loc.toString();
};

/**
 * Creates a link to the the NUX SDK install instructions
 * page for a project.
 *
 * @param {number} projectId
 * @param {string} projectPlatform
 * @return {string}
 */
router.sdkInstallInstructions = function(projectId, projectPlatform) {
  const loc = uri.create(`/mobile/${projectPlatform}/install`);
  loc.param('project_id', projectId);
  return loc.toString();
};

/**
 * Creates a link to the the NUX SDK Update instructions
 * page for a project.
 *
 * @param {number} projectId
 * @param {string} projectPlatform
 * @return {string}
 */
router.sdkUpdateInstructions = function(projectId, projectPlatform) {
  const loc = uri.create(`/mobile/${projectPlatform}/update`);
  loc.param('project_id', projectId);
  return loc.toString();
};

/**
 * Constructs URL for the create view page
 * @returns {string}
 */
router.viewsCreate = function(projectId) {
  return sprintf('/v2/projects/%s/implementation/pages/create', projectId);
};

/**
 * Constructs URL for the edit view page
 * @param {string} viewId
 * @returns {string}
 */
router.viewsEdit = function(projectId, viewId) {
  return sprintf('/v2/projects/%s/implementation/pages/%s', projectId, viewId);
};

/**
 * Creates links to navigate between the tabs of the data layer dashboard
 * in the p13n product
 *
 * @param {string} tabName
 * @return {string}
 */
router.dataLayerTab = function(projectId, tabName) {
  let tabRoute = 'pages';
  if (tabName === NavConstants.DataLayerTabs.EVENT_TAB) {
    tabRoute = 'events';
  } else if (tabName === NavConstants.DataLayerTabs.PLUGIN_TAB) {
    tabRoute = 'extensions';
  }
  return sprintf('/v2/projects/%s/implementation/%s', projectId, tabRoute);
};

/**
 * Returns the url for event editing
 *
 * @param {number} projectId
 * @param {number} eventId
 * @param {number} viewId
 * @param {string} eventType
 * @return {string}
 */
router.eventsEdit = function(projectId, eventId, viewId) {
  return sprintf(
    '/v2/projects/%s/implementation/pages/%s/events/%s',
    projectId,
    viewId,
    eventId,
  );
};

/**
 * Returns the url for click event creation
 *
 * @param {number} projectId
 * @param {number} viewId
 * @return {string}
 */
router.createClickEvent = function(projectId, viewId) {
  return sprintf(
    '/v2/projects/%s/implementation/pages/%s/events/create',
    projectId,
    viewId,
  );
};

router.manageProjects = function(useFullyQualifiedUrl) {
  const manageProjectsUri = '/projects/manage';
  if (!useFullyQualifiedUrl) {
    return manageProjectsUri;
  }
  return UrlUtil.optimizelyHRDUrl(manageProjectsUri);
};

router.plansAndPricing = function() {
  const plansAndPricingUri = '/plans';
  return UrlUtil.optimizelyHRDUrl(plansAndPricingUri);
};

/**
 * Root path for Custom Project Experiments.
 * @param {string} projectId
 * @return {string}
 */
router.oasisHome = function(projectId) {
  return sprintf('/v2/projects/%s/experiments', projectId);
};

/**
 * Oasis Implementation home url
 * @param  {string} projectId
 * @return {string}
 */
router.oasisImplementationTab = function(projectId) {
  const tabRoute = 'events';
  return sprintf('/v2/projects/%s/implementation/%s', projectId, tabRoute);
};

module.exports = router;

const _ = require('lodash');
const { toImmutable } = require('optly/immutable');

const CurrentLayerGetters = require('bundles/p13n/modules/current_layer/getters');
const CurrentProjectGetters = require('optly/modules/current_project/getters');
const EditorIframeEnums = require('bundles/p13n/modules/editor_iframe/enums');
const EditorIframeFns = require('bundles/p13n/modules/editor_iframe/fns');
const LayerExperimentGetters = require('optly/modules/entity/layer_experiment/getters');
const LayerExperimentFns = require('optly/modules/entity/layer_experiment/fns');
const PermissionsModuleFns = require('optly/modules/permissions/fns');

exports.selectedTab = ['p13n/campaignManager', 'selectedTab'];
exports.selectedSettingsTab = ['p13n/campaignManager', 'selectedSettingsTab'];
exports.dirtySettingsTabs = ['p13n/campaignManager', 'dirtySettingsTabs'];
exports.orderedAudienceIds = ['link', 'audienceSelector', 'orderedAudienceIds'];
exports.layerName = ['LegacyLayerSettingsStore', 'name'];
exports.layerDescription = ['LegacyLayerSettingsStore', 'description'];
exports.holdback = ['LegacyLayerSettingsStore', 'holdback'];

/**
 * Whether user is currently creating a new variation
 */
exports.isCreatingNewVariation = [
  'p13n/campaignManager',
  'creatingNewVariation',
];

/**
 * ID of variation currently being renamed, or null if no variation is being
 * renamed
 */
exports.currentlyRenamingVariationId = [
  'p13n/campaignManager',
  'currentlyRenamingVariationId',
];

exports.formatLayerHoldback = [
  exports.holdback,
  layerHoldback =>
    Number(!_.isNull(layerHoldback) ? layerHoldback / 100 || 0 : null),
];

/**
 * Generate a URL to QA this campaign using the QA button.
 */
exports.campaignQAUrl = [
  CurrentProjectGetters.project,
  CurrentProjectGetters.previewJSFileName,
  CurrentLayerGetters.id,
  CurrentLayerGetters.views,
  (currentProject, previewJSFileName, currentLayerId, currentLayerViews) => {
    const firstPageUrl = currentLayerViews.getIn([0, 'edit_url']);

    if (!firstPageUrl) {
      return null;
    }

    const protocol =
      firstPageUrl.indexOf('https:') === 0
        ? EditorIframeEnums.ProtocolTypes.HTTPS
        : EditorIframeEnums.ProtocolTypes.HTTP;

    return EditorIframeFns.generatePreviewUrl(
      firstPageUrl,
      protocol,
      previewJSFileName,
      {
        projectId: currentProject.get('id'),
        previewLayerIds: [currentLayerId], // The layers preview_client should fetch working copy (draft) data for.
        previewMode: {
          // The parameters preview_ui should use for initializing the preview module.
          type: 'CAMPAIGN',
          id: currentLayerId,
        },
      },
    );
  },
];

exports.selectedViewIds = [
  ['link', 'viewSelector', 'selectedViews'],
  function(selectedViews) {
    if (selectedViews) {
      return selectedViews.map(view => view.get('id'));
    }
  },
];

exports.hasDirtyHoldback = [
  CurrentLayerGetters.layer,
  exports.holdback,
  function(currentLayer, holdback) {
    if (currentLayer && holdback !== undefined) {
      return currentLayer.get('holdback') !== holdback;
    }
    return false;
  },
];

exports.hasDirtyLayerName = [
  CurrentLayerGetters.layer,
  exports.layerName,
  function(currentLayer, layerName) {
    if (currentLayer && layerName) {
      return currentLayer.get('name') !== layerName;
    }
    return false;
  },
];

exports.hasDirtyLayerDescription = [
  CurrentLayerGetters.layer,
  exports.layerDescription,
  function(currentLayer, layerDescription) {
    if (currentLayer && layerDescription) {
      return currentLayer.get('description') !== layerDescription;
    }
    return false;
  },
];

exports.hasDirtyAudienceIds = [
  CurrentLayerGetters.orderedAudienceIds,
  exports.orderedAudienceIds,
  function(currentLayerAudienceIds, orderedAudienceIds) {
    if (currentLayerAudienceIds && orderedAudienceIds) {
      return !currentLayerAudienceIds.equals(orderedAudienceIds);
    }
    return false;
  },
];

exports.hasDirtySelectedViews = [
  CurrentLayerGetters.layer,
  exports.selectedViewIds,
  function(currentLayer, selectedViewIds) {
    if (currentLayer && selectedViewIds) {
      return !currentLayer.get('view_ids').equals(selectedViewIds);
    }
    return false;
  },
];

/**
 * Getter to indicate if any of the settings tab settings or audience IDs are dirty.
 * @returns {Boolean}
 */
exports.hasDirtySettingsTab = [
  exports.hasDirtyLayerName,
  exports.hasDirtyLayerDescription,
  exports.hasDirtyHoldback,
  exports.hasDirtyAudienceIds,
  function(
    hasDirtyLayerName,
    hasDirtyLayerDescription,
    hasDirtyHoldback,
    hasDirtyAudienceIds,
  ) {
    return (
      hasDirtyHoldback ||
      hasDirtyLayerDescription ||
      hasDirtyLayerName ||
      hasDirtyAudienceIds
    );
  },
];

/**
 * LayerExperiment entity being displayed on the single experiment overview
 * page. The current layer is policy SINGLE_EXPERIMENT, and only one
 * LayerExperiment has this layer as its layer_id
 */
exports.singleExperiment = [
  CurrentLayerGetters.id,
  LayerExperimentGetters.entityCache,
  (currentLayerId, experimentsCache) => {
    if (!_.isNumber(currentLayerId)) {
      return null;
    }
    return experimentsCache.find(
      experiment => experiment.get('layer_id') === currentLayerId,
    );
  },
];

exports.singleExperimentId = [
  exports.singleExperiment,
  experiment => {
    if (!experiment) {
      return null;
    }
    return experiment.get('id');
  },
];

/**
 * Returns variations with data needed by the single experiment overview page,
 * including modification info (canRename, canDuplicate, etc...) and percentage
 * of traffic
 */
exports.variationsForSingleExperiment = [
  exports.singleExperiment,
  [CurrentProjectGetters.project, PermissionsModuleFns.canUpdateLayer],
  CurrentLayerGetters.liveCommit,
  CurrentLayerGetters.isMultivariateTestLayer,
  CurrentLayerGetters.isPersonalizationLayer,
  (
    experiment,
    canUpdateLayer,
    liveCommit,
    isMultivariateTestLayer,
    isPersonalizationLayer,
  ) => {
    if (!experiment) {
      return toImmutable([]);
    }
    const experimentId = experiment.get('id');
    const liveExperiment =
      liveCommit &&
      liveCommit
        .getIn(['revisions', 'layer_experiment'], toImmutable([]))
        .find(
          liveCommitExperiment =>
            experimentId === liveCommitExperiment.get('id'),
        );
    const withModificationInfo = LayerExperimentFns.addVariationModificationInfo(
      experiment.get('variations'),
      canUpdateLayer,
      liveExperiment,
      isMultivariateTestLayer,
      isPersonalizationLayer,
      experiment.get('allocation_policy'),
    );
    return LayerExperimentFns.addPercentageToVariations(withModificationInfo);
  },
];

exports.suggestedNewVariationName = [
  exports.singleExperiment,
  experiment => {
    if (!experiment) {
      return '';
    }
    return LayerExperimentFns.suggestedNewVariationName(
      experiment.get('variations'),
    );
  },
];

import React from 'react';
import PropTypes from 'prop-types';
import { BlockList, Dropdown, Button } from 'optimizely-oui';

import Immutable from 'optly/immutable';

import { constants as ExperimentationGroupConstants } from 'optly/modules/entity/experimentation_group';

import { fieldPropType } from 'react_components/form';

class ExclusionGroupDropdown extends React.Component {
  static propTypes = {
    selectedGroupField: fieldPropType.isRequired,
    percentageIncludedField: fieldPropType.isRequired,
    activeGroups: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  handleGroupChange = groupId => {
    const { selectedGroupField, percentageIncludedField } = this.props;
    if (groupId !== selectedGroupField.getValue()) {
      selectedGroupField.setValue(groupId);

      if (groupId === ExperimentationGroupConstants.NONE_GROUP_ID) {
        percentageIncludedField.setValue(10000);
      } else {
        percentageIncludedField.setValue(0);
      }
    }
  };

  render() {
    const { activeGroups, selectedGroupField } = this.props;

    // Get selected group name
    const activeGroupsWithDefault = activeGroups.unshift(
      ExperimentationGroupConstants.NONE_GROUP,
    );
    const selectedGroup = activeGroupsWithDefault.find(
      group => group.get('id') === selectedGroupField.getValue(),
    );
    const selectedGroupName = selectedGroup && selectedGroup.get('name');

    // Define the dropdown items
    const dropdownItems = activeGroupsWithDefault.map(group => (
      <BlockList.Category key={group.get('id')}>
        <BlockList.Item>
          <Button
            style="unstyled"
            width="full"
            onClick={() => this.handleGroupChange(group.get('id'))}
            testSection={`dropdown-block-link-${group.get('id')}`}>
            <div className="text--left color--brand">{group.get('name')}</div>
          </Button>
        </BlockList.Item>
      </BlockList.Category>
    ));

    return (
      <Dropdown
        arrowIcon="down"
        buttonContent={selectedGroupName}
        style="outline"
        width={300}
        testSection="group-select">
        <BlockList>{dropdownItems}</BlockList>
      </Dropdown>
    );
  }
}

export default ExclusionGroupDropdown;

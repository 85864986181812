export const Algorithm = {
  CO_BROWSE: 'co-browse',
  CO_BUY: 'co-buy',
  COLLABORATIVE_FILTERING: 'collaborative-filtering',
  POPULAR: 'popular',
  CUSTOM: 'custom',
};

export const AlgorithmDescriptions = {
  [Algorithm.CO_BROWSE]:
    'Serve recommendations based on connection strength defined by order of pages viewed and actions performed on those pages.',
  [Algorithm.CO_BUY]:
    'Serve recommendations based on Add to Cart and Buy events occurring together in any order.',
  [Algorithm.COLLABORATIVE_FILTERING]:
    'Serve recommendations based on the browse history of both the user and similar users.',
  [Algorithm.POPULAR]:
    'Serve recommendations based on the most frequent interactions (as defined by your events).',
};

export const AlgorithmEventInfo = {
  [Algorithm.CO_BROWSE]: {
    description:
      'Choose the catalog events you want to use for this recommender. ',
    id: 'item_view_events',
    label: 'Catalog Events',
    placeholder: 'Search and add events to this catalog',
    testSection: 'co-browse-catalog-events-picker',
  },
  [Algorithm.CO_BUY]: {
    description:
      'Choose the catalog events you want to use for this recommender. ',
    id: 'buy_events',
    label: 'Catalog Events',
    placeholder: 'Search and add events to this catalog',
    testSection: 'co-buy-catalog-events-picker',
  },
  [Algorithm.POPULAR]: {
    description:
      'Choose the catalog events you want to use for this recommender. ',
    id: 'events',
    label: 'Catalog Events',
    placeholder: 'Search and add events to this catalog',
    testSection: 'popular-catalog-events-picker',
  },
  [Algorithm.COLLABORATIVE_FILTERING]: {
    description:
      'Choose the catalog events you want to use for this recommender. ',
    id: 'item_view_events',
    label: 'Catalog Events',
    placeholder: 'Search and add events to this catalog',
    testSection: 'collaborative-filtering-catalog-events-picker',
  },
  BOOST: {
    description:
      'This algorithm supports boost events. Choose the boost events you want to use for this recommender. ',
    id: 'boost_events',
    label: 'Boost Events',
    placeholder: 'Search and add events to this catalog',
    testSection: 'boost-events-picker',
  },
};

export const AlgorithmHumanReadables = {
  CO_BROWSE: 'Co-Browse',
  CO_BUY: 'Co-Buy',
  COLLABORATIVE_FILTERING: 'Collaborative Filtering',
  POPULAR: 'Popular',
  CUSTOM: 'Custom',
};

export const AlgorithmPreviewerTexts = {
  [Algorithm.CO_BROWSE]: {
    emptyResponse: 'No catalog item found matching that ItemID.',
    humanReadable: AlgorithmHumanReadables.CO_BROWSE,
    label: 'Enter an ItemID to see sample recommendations for that item.',
    placeholder: 'Search by ItemID',
    response: 'recommendations generated using the Co-Browse algorithm.',
  },
  [Algorithm.CO_BUY]: {
    emptyResponse: 'No catalog item found matching that ItemID.',
    humanReadable: AlgorithmHumanReadables.CO_BUY,
    label: 'Enter an ItemID to see sample recommendations for that item.',
    placeholder: 'Search by ItemID',
    response: 'recommendations generated using the Co-Buy algorithm.',
  },
  [Algorithm.COLLABORATIVE_FILTERING]: {
    emptyResponse: 'No visitor found matching that VisitorID.',
    humanReadable: AlgorithmHumanReadables.COLLABORATIVE_FILTERING,
    label: 'Enter a VisitorID to see sample recommendations for that visitor.',
    placeholder: 'Search by VisitorID',
    response:
      'recommendations generated using the Collaborative Filtering algorithm.',
  },
  [Algorithm.POPULAR]: {
    humanReadable: AlgorithmHumanReadables.POPULAR,
    placeholder: 'View Results',
    response: 'recommendations generated using the Popular algorithm.',
  },
};

export const AlgorithmSelectorTexts = [
  {
    label: 'Select an algorithm...',
    value: undefined,
  },
  {
    description: AlgorithmDescriptions[Algorithm.CO_BROWSE],
    label: AlgorithmHumanReadables.CO_BROWSE,
    value: Algorithm.CO_BROWSE,
  },
  {
    description: AlgorithmDescriptions[Algorithm.CO_BUY],
    label: AlgorithmHumanReadables.CO_BUY,
    value: Algorithm.CO_BUY,
  },
  {
    description: AlgorithmDescriptions[Algorithm.COLLABORATIVE_FILTERING],
    label: AlgorithmHumanReadables.COLLABORATIVE_FILTERING,
    value: Algorithm.COLLABORATIVE_FILTERING,
  },
  {
    description: AlgorithmDescriptions[Algorithm.POPULAR],
    label: AlgorithmHumanReadables.POPULAR,
    value: Algorithm.POPULAR,
  },
];

export default {
  Algorithm,
  AlgorithmDescriptions,
  AlgorithmEventInfo,
  AlgorithmHumanReadables,
  AlgorithmPreviewerTexts,
  AlgorithmSelectorTexts,
};

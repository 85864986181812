import { toImmutable } from 'optly/immutable';
import sort from 'optly/utils/sort';
import _ from 'lodash';

import sortFns from './sort_fns';

/**
 * Returns all sort data about a table
 * @param {string} tableId
 */
export function sortData(tableId) {
  return [
    ['sortableTable', tableId],
    tableData => {
      if (tableData) {
        return tableData.get('sortBy');
      }
      return toImmutable([]);
    },
  ];
}

/**
 * returns sort.ASC, sort.DESC or undefined
 * @param {string} tableId
 * @param {string} field
 * @return {string|undefined}
 */
export function fieldSortDir(tableId, field) {
  return [
    ['sortableTable', tableId],
    function(sortData) {
      if (sortData) {
        const entry = _.find(sortData.get('sortBy').toJS(), { field });
        if (entry) {
          return entry.dir;
        }
      }
    },
  ];
}

/**
 * Checks if a table is currently being sorted on
 * @param {ImmutableMap} state
 * @param {string} tableId
 * @return {boolean}
 */
export function isSorted(tableId) {
  return [
    ['sortableTable', tableId, 'sortBy'],
    sortBy => !!(sortBy && sortBy.size > 0),
  ];
}

/**
 * Returns a single generated function that represents all the sort
 * rules for a specific table.  Returns undefined if there is no sorting needed.
 *
 * @param {string|number} tableId
 * @return {function|undefined}
 */
export function sortFn(tableId) {
  return [
    ['sortableTable', tableId],
    function(sortData) {
      if (sortData) {
        return sort.generateImmutableObjectSortFn(
          sortData.get('sortBy').toJS(),
          sortFns,
        );
      }
    },
  ];
}

/**
 * Getters for sortableTable
 */
export default {
  fieldSortDir,
  isSorted,
  sortFn,
  sortData,
};

/**
 * Directive for simple accordion
 *
 * @author Cheston Lee
 */
import $ from 'jquery';

import accordionService from 'optly/services/accordion';

const exported = {
  __activate(event) {
    event.preventDefault();
    accordionService.activate(this.el, event.target);
  },

  bind() {
    const $el = $(this.el);
    $el.find('> li > a').on('click', this.__activate.bind(this));
  },

  unbind() {
    const $el = $(this.el);
    $el.find('> li > a').off('click', this.__activate);
  },
};

export default exported;

export const { __activate, bind, unbind } = exported;

import Immutable from 'optly/immutable';
import { memoize } from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import { Track } from '@optimizely/segment-js/dist/components';

import ReactHighchartsWrapper from 'react_components/highcharts_wrapper';

import interpolate from 'optly/utils/interpolate';

import chartConfig from './highcharts_config';
import ReachEstimate from './reach_estimate';
import Slider from './slider';

const selectedColor = '#4069ff';
const notSelectedColor = '#dcdcdc';

/**
 * Responsible for displaying a histogram of the score distribution data provided
 * with a vertical slider overlayed to show the current area captured by the percent visitors.
 */
export default class EstimatedReachHistogram extends React.Component {
  static propTypes = {
    onPercentVisitorsChange: PropTypes.func.isRequired,
    percentVisitors: PropTypes.number.isRequired,
    scoreDistribution: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  state = {
    /**
     * Absolute positioning information for the slider overlay
     * calculated using the ref to the rendered chart.
     */
    plotWidth: undefined,
    plotHeight: undefined,
    plotLeft: undefined,
    plotTop: undefined,
  };

  /**
   * Ref to the rendered chart instance of Highcharts.
   */
  chartRef = React.createRef();

  componentDidMount() {
    this.setZoneData();
  }

  /**
   * Whenever the component updates, ensure the chart zones are
   * updated to reflect any changes in the percent visitors.
   */
  componentDidUpdate(prevProps) {
    const { percentVisitors } = this.props;
    if (prevProps.percentVisitors !== percentVisitors) {
      this.setZoneData();
    }
  }

  setSliderStyling = () => {
    /**
     * Get the exact position and size information of the rendered
     * Highchart to pass down to the <Slider> so it can be positioned correctly.
     */
    const {
      plotWidth,
      plotHeight,
      plotLeft,
      plotTop,
    } = this.chartRef.current.getChart();
    this.setState({
      plotWidth,
      plotHeight,
      plotLeft,
      plotTop,
    });
  };

  setZoneData() {
    const [chartSeries] = this.chartRef.current.chart.series;
    const { percentVisitors } = this.props;
    chartSeries.update({
      zones: [
        {
          value: this.percentageAsSimilarityScore(percentVisitors),
          color: notSelectedColor,
        },
        {
          color: selectedColor,
        },
      ],
    });
  }

  /**
   * Return the chart config for the current score distribution dataset.
   * Since re-rendering the chart is expensive, we can memoize the chartConfig
   * object using the scoreDistribution dataset as the key.
   */
  getChartConfig = memoize(scoreDistribution => {
    const buckets = scoreDistribution.get('buckets');
    const data = buckets
      .map(bucket => [
        bucket.get('similarity_score'),
        bucket.get('num_visitors'),
      ])
      .toJS();
    const duration = moment.duration(
      +new Date(scoreDistribution.get('last_date')) -
        +new Date(scoreDistribution.get('first_date')),
    );
    return chartConfig(
      data,
      duration.humanize(),
      this.setSliderStyling,
      selectedColor,
    );
  });

  /**
   * Handles similarity_score changes from the overlay slider.
   * Convert the score back to a percentage with 1 decimal precision.
   * @param {Number} score
   */
  onSimilarityScoreChange = score => {
    const { onPercentVisitorsChange } = this.props;
    onPercentVisitorsChange(
      Number(this.similarityScoreAsPercentage(score).toFixed(1)),
    );
  };

  /**
   * Given a similarity_score, compute it's estimated reach percentage
   * using the linear interpolation utility and our buckets dataset.
   * @param {Number} score
   * @return {Number} The interpolated percent reach.
   */
  similarityScoreAsPercentage = score => {
    const { scoreDistribution } = this.props;
    const buckets = scoreDistribution.get('buckets');
    return (
      interpolate('similarity_score', score, buckets).get('reach_percentage') *
      100
    );
  };

  /**
   * Given an estimated reach percentage, compute it's similarity_score
   * using the linear interpolation utility and our buckets dataset.
   * @param {Number} percent
   * @return {Number} The interpolated similarity_score
   */
  percentageAsSimilarityScore = percent => {
    const { scoreDistribution } = this.props;
    const buckets = scoreDistribution.get('buckets');
    return interpolate('reach_percentage', percent / 100, buckets).get(
      'similarity_score',
    );
  };

  render() {
    const { percentVisitors, scoreDistribution } = this.props;
    const similarityScore = this.percentageAsSimilarityScore(
      Number(percentVisitors),
    );
    const { plotWidth, plotHeight, plotLeft, plotTop } = this.state;
    return (
      <div className="width--1-1">
        <div className="flex flex--1 flex-wrap">
          <div className="flex flex--1 position--relative min-width--300 height--300">
            <div className="width--1-1">
              <ReactHighchartsWrapper
                config={this.getChartConfig(scoreDistribution)}
                ref={this.chartRef}
                isPureConfig={true}
              />
            </div>
            <Track eventName="Adaptive Audience Slider Clicked">
              <Slider
                value={similarityScore}
                onChange={this.onSimilarityScoreChange}
                plotHeight={plotHeight}
                plotWidth={plotWidth}
                plotLeft={plotLeft}
                plotTop={plotTop}
              />
            </Track>
          </div>
          <div className="flex width--200">
            <ReachEstimate
              scoreDistribution={scoreDistribution}
              similarityScore={similarityScore}
            />
          </div>
        </div>
      </div>
    );
  }
}

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

export const asList = [entityCache, events => events.toList()];

export const activeEvents = [
  entityCache,
  events => events.filter(event => !event.get('archived')),
];

export default {
  ...baseEntityGetters,
  activeEvents,
  asList,
};

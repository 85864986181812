const capitalize = require('optly/filters/capitalize');
const { Label } = require('optimizely-oui');
const PropTypes = require('prop-types');
const React = require('react');

const EditorFns = require('bundles/p13n/modules/editor/fns').default;
const {
  ChangeStatuses,
} = require('optly/modules/entity/layer_experiment/enums').default;

const RevertButton = require('./revert_button');

const FieldLabel = props => {
  const statusClass = EditorFns.coalesceStatusesIntoClass(props.changeStatus);
  return (
    <div className={`flex flex-align--center ${statusClass}`}>
      <Label>
        {props.label ? capitalize(props.label) : capitalize(props.property)}
      </Label>
      {props.changeStatus === ChangeStatuses.DIRTY ? (
        <RevertButton property={props.property} action={props.revertAction} />
      ) : null}
    </div>
  );
};

FieldLabel.propTypes = {
  /** String to signify if should show revert button and status class */
  changeStatus: PropTypes.string,
  /** Title to display in UI */
  label: PropTypes.string,
  /** Property of change */
  property: PropTypes.string,
  /** Function that should be called  when the revert button is clicked. */
  revertAction: PropTypes.func,
};

module.exports = FieldLabel;

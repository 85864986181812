import keyMirror from 'optly/utils/key_mirror';

export const xAxisLabelFormat = 'MMM D';

export const productIds = {
  AB_TESTING: 'ab_testing',
  AB_TESTING_V2: 'ab_testing_v2',
  ALL_PRODUCTS: 'all_products',
  PERSONALIZATION: 'personalization',
  MOBILE: 'mobile',
  FREE_ROLLOUTS: 'dev_tier_0',
  FULL_STACK: 'full_stack',
  OVER_THE_TOP: 'over_the_top',
  RECOMMENDATIONS: 'recommendations',
  USAGE: 'usage',
};

export const productNames = {
  CLASSIC_AB_TESTING: 'Classic A/B Testing',
  ALL_PRODUCTS: 'Testing / Web Experimentation',
  PERSONALIZATION: 'Personalization',
  MOBILE: 'Mobile',
  FEATURE_EXPERIMENTATION: 'Feature Experimentation',
  FULL_STACK: 'Full Stack',
  OVER_THE_TOP: 'OTT',
  RECOMMENDATIONS: 'Recommendations',
  USAGE: 'Impressions',
  FREE_ROLLOUTS: 'Optimizely Rollouts',
};

export const subscriptionTerm = {
  MONTHLY: 'Monthly',
  SEMI_ANNUAL: 'Semi-annual',
  ANNUAL: 'Annual',
  BI_ANNUAL: 'Bi-annual',
};

export const visitorsTestedType = {
  IMPRESSION_DELIVERED: 'Impressions Delivered',
  UNIQUE_VISITORS_TESTED: 'Unique Visitors Tested',
  MONTHLY_IMPRESSION_DELIVERED: 'Monthly Impressions Delivered',
  MONTHLY_UNIQUE_VISITOR_TESTED: 'Monthly Unique Visitors Tested',
};

export const visitorsLimit = {
  IMPRESSION_LIMIT: 'Impressions Limit',
  UNIQUE_VISITORS_LIMIT: 'Unique Visitors Limit',
  MONTHLY_IMPRESSION_LIMIT: 'Monthly Impressions Limit',
  MONTHLY_UNIQUE_VISITOR_LIMIT: 'Monthly Unique Visitors Limit',
};

export const planIds = {
  FREE_ROLLOUTS: 'free_rollouts',
  PP_2017: 'pp_2017',
  PP_2020: 'pp_2020',
  PP_2024: 'pp_2024',
};

export const pricingPackagingPDFs = {
  COMBINED_IMPRESSIONS_AND_MAU_MODEL:
    'https://files.marketing.cmp.optimizely.com/download/df464e649da311ee96032e42370f90ff', // pp_2017 and pp_2020
  IMPRESSIONS_MODEL:
    'https://files.marketing.cmp.optimizely.com/download/bed67ca2653811ed8e21da0865cfefd9', // pp_2017
  MAU_MODEL:
    'https://files.marketing.cmp.optimizely.com/download/3ec49dd040ea11eea6d9521dbd5dc5dc', // pp_2020
  MODEL_2024:
    'https://files.marketing.cmp.optimizely.com/download/48baf25843b911efa8ddd66b96c6b4bb', // pp_2024
};

export default {
  xAxisLabelFormat,
  productIds,
  productNames,
  subscriptionTerm,
  visitorsTestedType,
  visitorsLimit,
  planIds,
  pricingPackagingPDFs,
};

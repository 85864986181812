import { getFeatureVariableInteger } from '@optimizely/js-sdk-lab/src/actions';

import EnvironmentActions from 'optly/modules/entity/environment/actions';
import FeatureFlagActions from 'optly/modules/entity/feature_flag/actions';
import LayerExperimentActions from 'optly/modules/entity/layer_experiment/actions';

import {
  DEFAULT_MAX_API_FILTERS,
  EntityType,
} from 'bundles/p13n/components/change_history';

/**
 * This function contains all the required fetches for the pages in the Feature Manager.
 * It fetches:
 * 1) feature
 * 2) primary environment (for sidebar)
 * @param {Number} experimentId
 * @param {Boolean} forceFetchLayerExperiment
 */
export function fetchRequiredDataForFeatureManager(featureId) {
  const toPromise = deferred =>
    new Promise((resolve, reject) => {
      deferred.then(resolve, reject);
    });

  return toPromise(FeatureFlagActions.fetch(Number(featureId))).then(
    feature => {
      const featureEnvironments = Object.values(feature.environments);
      const primaryEnvironment = featureEnvironments.find(
        env => env.is_primary,
      );
      return Promise.all([
        feature,
        toPromise(EnvironmentActions.fetch(primaryEnvironment.id)),
      ]);
    },
  );
}

export function fetchRequiredDataForManagerChangeHistory(ctx, next) {
  const featureId = parseInt(ctx.params.feature_id, 10);
  const projectId = parseInt(ctx.params.proj_id, 10);

  // Change History will truncates fitlers to this number if exceeded so no need to fetch more
  const fetchLimit =
    getFeatureVariableInteger(
      'change_history_improvements',
      'max_api_filters',
    ) || DEFAULT_MAX_API_FILTERS;

  Promise.all([
    FeatureFlagActions.fetch(featureId),
    LayerExperimentActions.fetchPage({
      $limit: fetchLimit,
      feature_flag_id: featureId,
      project_id: projectId,
    }),
  ]).then(([feature, layerExperiment]) => {
    // set ctx.fetchedData for downstream route use (where component is rendered)
    ctx.fetchedData = {
      [EntityType.feature.entityType]: [].concat(feature),
      [EntityType.experiment.entityType]: [].concat(layerExperiment),
    };
    next();
  });
}

export default {
  fetchRequiredDataForFeatureManager,
  fetchRequiredDataForManagerChangeHistory,
};

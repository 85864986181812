import Immutable from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';

class VariationDropdown extends React.Component {
  static propTypes = {
    forcedVariation: PropTypes.instanceOf(Immutable.Map),
    index: PropTypes.number,
    onVariationIdChange: PropTypes.func,
    variations: PropTypes.instanceOf(Immutable.List),
  };

  handleVariationIdChange = event => {
    const value = parseInt(event.target.value, 10);
    this.props.onVariationIdChange(this.props.index, value);
  };

  render() {
    const selectedVariationId = this.props.forcedVariation.get('variation_id');
    const userId = this.props.forcedVariation.get('user_id');
    return (
      <select
        value={selectedVariationId}
        onChange={this.handleVariationIdChange}
        className="lego-select width--1-1"
        data-test-section="oasis-forced-variation-dropdown">
        {/* filter out variations:
         * 1.  if it is an existing forced variation:  show only active variations in dropdown
         *     unless the variation is the selected variation.
         * 2.  add new variation only shows active variations.
         */
        this.props.variations
          .filter(variation => {
            let shouldShowVariation;
            // if there is a userid then it is an existing forced varition
            if (userId !== null && userId !== undefined) {
              // if the variation is for an existing forced variation and
              // the variation is not active and not selected dont show variation
              // in the dropdown
              if (
                selectedVariationId !== variation.get('variation_id') &&
                variation.get('status') !==
                  LayerExperimentEnums.VariationStatus.ACTIVE
              ) {
                shouldShowVariation = false;
              } else {
                // otherwise, if it is selected, show it.
                shouldShowVariation = true;
              }
            } else {
              // if there is no user id then it is a new entry and definitely, don't show
              // variation that are not active (paused or archived).
              shouldShowVariation =
                variation.get('status') ===
                LayerExperimentEnums.VariationStatus.ACTIVE;
            }
            return shouldShowVariation;
          })
          .map(variation => (
            <option
              key={`option-${variation.get('variation_id')}`}
              value={variation.get('variation_id')}>
              {variation.get('api_name')}
            </option>
          ))}
      </select>
    );
  }
}

export default VariationDropdown;

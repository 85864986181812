const flux = require('core/flux');
const ui = require('core/ui').default;
const _ = require('lodash');

const ColorPickerConstants = require('bundles/p13n/components/color_picker/constants');

const Editor = require('bundles/p13n/modules/editor');
const ColorPicker = require('bundles/p13n/components/color_picker');

const template = require('./template.html');
const StyleAndWidthInputs = require('./styleAndWidthInputs');

module.exports = {
  template,

  replace: true,

  components: {
    'color-picker': ColorPicker,
  },

  computed: {
    statusClasses() {
      return {
        'border-color': Editor.fns.coalesceStatusesIntoClass(
          this.changeStatusMap.css['border-color'],
        ),
      };
    },
  },

  methods: {
    initializeColorPicker() {
      const initialColor = flux.evaluateToJS(
        Editor.getters.cssValueFromChangeOrElement('border-color'),
      );

      if (
        this.$.borderColorPicker &&
        initialColor !== this.$.borderColorPicker.getValue()
      ) {
        this.$.borderColorPicker.$emit(
          ColorPickerConstants.EVENTS.SET_COLOR,
          initialColor,
        );
      }
    },

    handleChangedColor(newColor) {
      const currentColor = flux.evaluate(
        Editor.getters.cssValueFromChangeOrElement('border-color'),
      );

      if (!newColor || newColor === currentColor) {
        return;
      }

      const elementColor = flux.evaluate(
        Editor.getters.cssValueFromElement('border-color'),
      );
      const storedColor = flux.evaluate(
        Editor.getters.storedCSSValue('border-color'),
      );

      if (
        (storedColor !== null && newColor === storedColor) ||
        (storedColor === null && newColor === elementColor)
      ) {
        Editor.actions.revertCSSProperty('border-color');
      } else {
        Editor.actions.setChangeCSSProperty('border-color', newColor);
      }

      Editor.actions.applyCurrentlyEditingChange();
    },

    revertCSSProperty: Editor.actions.revertCSSProperty,
  },

  created() {
    flux.bindVueValues(this, {
      // Required by the ChangeEditorSidebar mixin
      activeFrameId: Editor.getters.activeFrameId,
      changeStatusMap: Editor.getters.changeStatusMap(),
      currentlyEditingChange: Editor.getters.currentlyEditingChange,
    });
  },

  ready() {
    this.initializeColorPicker();

    // Reinitialize color picker when a new element(s) is selected
    // Have to observe the change rather than changeId in order to get elementInfo
    this.__unwatchCurrentlyEditingChange = flux.observe(
      Editor.getters.currentlyEditingChange,
      () => {
        setTimeout(this.initializeColorPicker.bind(this), 0);
      },
    );

    this.$.borderColorPicker.$on(
      ColorPickerConstants.EVENTS.COLOR_UPDATED,
      _.debounce(this.handleChangedColor, 20),
    );

    ui.renderReactComponent(this, {
      component: StyleAndWidthInputs,
      el: this.$$['style-and-width-inputs'],

      props: {
        onStyleChange: newStyle => {
          Editor.actions.setChangeCSSProperty('border-style', newStyle);
          Editor.actions.applyCurrentlyEditingChange();
        },
        onWidthChange: newWidth => {
          const storedValue = flux.evaluate(
            Editor.getters.storedCSSValue('border-width'),
          );
          const elementValue = flux.evaluate(
            Editor.getters.cssValueFromElement('border-width'),
          );

          // Treat a return to stored value (if one exists) or to the elementInfo value
          // as a revert
          if (
            (storedValue !== null && newWidth === storedValue) ||
            (storedValue === null && newWidth === elementValue)
          ) {
            Editor.actions.revertCSSProperty('border-width');
            Editor.actions.revertCSSProperty('border-style');
            return;
          }

          Editor.actions.setChangeCSSProperty('border-width', newWidth);

          const currentChangeStyle = flux.evaluate(
            Editor.getters.cssValueFromCurrentlyEditingChange('border-style'),
          );
          // If width is changing null -> something non-0px, default width is solid
          if (currentChangeStyle === null && newWidth !== '0px') {
            Editor.actions.setChangeCSSProperty('border-style', 'solid');
          }
          Editor.actions.applyCurrentlyEditingChange();
        },
      },

      dataBindings: {
        changeStatusMap: Editor.getters.changeStatusMap(),
        styleValue: Editor.getters.cssValueFromChangeOrElement('border-style'),
        widthValue: Editor.getters.cssValueFromChangeOrElement('border-width'),
      },
    });
  },

  beforeDestroy() {
    if (this.__unwatchCurrentlyEditingChange) {
      this.__unwatchCurrentlyEditingChange();
    }
  },
};

import PropTypes from 'prop-types';
import React from 'react';

import Immutable from 'optly/immutable';

import DashboardEntityTable from 'bundles/p13n/components/entity_dashboard/entity_table';

import EventCardTableHeader from '../event_card_table_header';
import CustomEventTableRow from './table_row';

class CustomEventsTable extends React.Component {
  static propTypes = {
    canArchiveUserEvent: PropTypes.bool.isRequired,
    editEvent: PropTypes.func.isRequired,
    events: PropTypes.instanceOf(Immutable.List).isRequired,
    experimentsUsageAvailable: PropTypes.bool.isRequired,
    shouldShowEvent: PropTypes.func,
    tableId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    shouldShowEvent: true,
  };

  renderTableRow = (event, index) => {
    const {
      canArchiveUserEvent,
      editEvent,
      experimentsUsageAvailable,
      shouldShowEvent,
    } = this.props;
    if (shouldShowEvent(event, index)) {
      return (
        <CustomEventTableRow
          canArchiveUserEvent={canArchiveUserEvent}
          editEvent={editEvent}
          event={event}
          experimentsUsageAvailable={experimentsUsageAvailable}
          key={event.get('id')}
        />
      );
    }
  };

  render() {
    const { events, tableId } = this.props;

    return (
      <DashboardEntityTable
        tableId={tableId}
        data={events}
        disableScrolling={true}
        entityPlural="events"
        renderTableRow={this.renderTableRow}
        renderTableHeader={EventCardTableHeader}
        defaultSortBy={{ field: 'name', type: 'string' }}
        testSection="custom-events-table"
      />
    );
  }
}

export default CustomEventsTable;

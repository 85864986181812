/**
 * Spectrum color picker directive. Expects to bind to an object of the format:
 *
 * {r: 255, g: 255, b: 255, a: .1}
 *
 * @author Sam Jackson (sam@optimizely.com)
 */
const $ = require('jquery');

const SPECTRUM_PREFIX = 'spectrum:';
const ESC_KEYCODE = 27;
const _ = require('lodash');

// Default options -- these can be overridden by setting a json string in the
// data-options attribute of the element
const DEFAULT_OPTIONS = {
  allowEmpty: false,
  clickoutFiresChange: true,
  containerClassName: 'optly-color-picker popover',
  preferredFormat: 'hex6',
  replacerClassName: 'optly-color-picker__trigger',
  showAlpha: true,
  showButtons: false,
  showInitial: false,
  showInput: true,
};

module.exports = {
  /**
   * This is sent to the parent component along with the update so that the parent
   * knows which property to update with the selected color value
   *
   * @param {String}
   */
  _propertyName: null,

  last: null,

  /**
   * Lets parent component know that the color value has changed and sends the color value along with the update
   *
   * @param {Object} value Spectrum provided parameter
   * @private
   */
  _handleSpectrumUpdate(value) {
    if (this.vm) {
      this.last = this.vm;
    } else {
      this.vm = this.last;
    }
    value = value.toRgb();
    this.vm.$dispatch(`${SPECTRUM_PREFIX}update`, this._propertyName, value);
  },

  /**
   * Hide the component if the parent drawer was clicked
   *
   * @private
   */
  _hide() {
    $(this.el).spectrum('hide');
  },

  /**
   * Sync color values with the color value from the selected element
   *
   * @param {Object} properties
   * @private
   */
  _handlePropertiesChanged(properties) {
    // we are only interested in one property
    $(this.el).spectrum('set', properties[this._propertyName]);
  },

  _handleKeyUp(event) {
    if (event.keyCode === ESC_KEYCODE) {
      this._hide();
    }
  },

  /**
   * Set up directive listeners and bind data model
   *
   * @param {Object} value The color value this directive is being bound to
   */
  bind(value) {
    // for dynamic components where we don't know the property name during compile time, we pass the property name
    // in via the value because Vue doesn't like dynamic argument bindings. Examples:
    // 1) if passed in as an arg the directive should look like this v-spectrum="argName: value" where arg is just a
    //    string and value is an object with the rgba properties
    // 2) if no arg is provided then directive should look like this v-spectrum="value" where value is
    //    { a: 0, r: 0, g: 0, b: 0, propertyName: '' }
    this._propertyName = this.arg || value.propertyName;

    // Grab any instance specfic options from the DOM
    let options = $(this.el).attr('data-options');

    // Coerce options to object
    if (options) {
      options = JSON.parse(options);
    }

    // Extend user supplied options with the defaults
    options = _.extend(
      {
        move: this._handleSpectrumUpdate.bind(this),
        change: this._handleSpectrumUpdate.bind(this),
      },
      DEFAULT_OPTIONS,
      options || {},
    );

    // Instantiate spectrum
    $(this.el).spectrum(options);
    $(this.el).spectrum('set', value);

    // Commented out because of MBL-313 on JIRA.
    // Stored as a variable because we need to pass `this` to it.
    // http://stackoverflow.com/a/22870717/316602
    // this.handleKeyUpBind = this._handleKeyUp.bind(this);
    // document.addEventListener('keyup', this.handleKeyUpBind);

    this.vm.$on(`${SPECTRUM_PREFIX}hide`, this._hide.bind(this));
    this.vm.$on(
      `${SPECTRUM_PREFIX}propertiesChanged`,
      this._handlePropertiesChanged.bind(this),
    );
  },

  /**
   * Destroy the element that spectrum creates.
   */
  unbind() {
    // Commented out and tracked in MBL-784.
    // $(this.el).spectrum('destroy');
    // Commented out because of MBL-313 on JIRA.
    // document.removeEventListener('keyup', this.handleKeyUpBind);
  },
};

import { constants as FeatureConstants } from 'optly/modules/entity/feature_flag';
import ProjectEnums from 'optly/modules/entity/project/enums';

// named exports
export const TABLE_ID = 'FeaturesDashboard';

export const CREATING_FIRST_FEATURE_LOADING_ID =
  'CREATING_FIRST_FEATURE_LOADING_ID';

const FEATURE_CODE_BLOCK_REACT = `// Note that you don't set the userID here.
// Instead, set it on a higher-level OptimizelyProvider component.
function FeatureComponent() {
  return (
    <OptimizelyFeature feature="%s">
      {(isEnabled, variables) => {`;

const FEATURE_CODE_BLOCK_REACT_POSTFIX = `
      }}
    </OptimizelyFeature>
  )
}
`;

const FEATURE_CODE_BLOCK_AGENT = `curl -X POST \\
  '{HOST_URL}/v1/activate?featureKey=%s' \\
  -H 'Content-Type: application/json' \\
  -H 'X-Optimizely-SDK-Key: "YOUR-SDK-KEY"' \\
  -d '{
    "userId":"USER_ID"
  }'
`;

const GET_FEATURE_VARIABLES_REACT = '        let %s = variables.%1$s;';

const FEATURE_VARIABLE_CODE_BLOCK_FLUTTER =
  'var %1$s = decision.variables["%3$s"];';

export const VARIABLE_VALUE_DEFAULTS = {
  [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]: 'true',
  [FeatureConstants.FEATURE_VARIABLE_TYPES.float]: 0.0,
  [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]: 0,
  [FeatureConstants.FEATURE_VARIABLE_TYPES.string]: '',
  [FeatureConstants.FEATURE_VARIABLE_TYPES.json]: {},
};

export const VARIABLE_CODE_BLOCKS_BY_LANG = {
  [ProjectEnums.sdkLanguages.PYTHON]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      "%s = optimizely_client.get_feature_variable_boolean('%s', '%s', user_id)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      "%s = optimizely_client.get_feature_variable_double('%s', '%s', user_id)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      "%s = optimizely_client.get_feature_variable_integer('%s', '%s', user_id)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      "%s = optimizely_client.get_feature_variable_string('%s', '%s', user_id)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      "%s = optimizely_client.get_feature_variable_json('%s', '%s', user_id)",
  },
  [ProjectEnums.sdkLanguages.RUBY]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      "%s = optimizely_client.get_feature_variable_boolean('%s', '%s', user_id)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      "%s = optimizely_client.get_feature_variable_double('%s', '%s', user_id)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      "%s = optimizely_client.get_feature_variable_integer('%s', '%s', user_id)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      "%s = optimizely_client.get_feature_variable_string('%s', '%s', user_id)",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      "%s = optimizely_client.get_feature_variable_json('%s', '%s', user_id)",
  },
  [ProjectEnums.sdkLanguages.JAVASCRIPT]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      "var %s = optimizelyClientInstance.getFeatureVariableBoolean('%s', '%s', userId);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      "var %s = optimizelyClientInstance.getFeatureVariableDouble('%s', '%s', userId);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      "var %s = optimizelyClientInstance.getFeatureVariableInteger('%s', '%s', userId);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      "var %s = optimizelyClientInstance.getFeatureVariableString('%s', '%s', userId);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      "var %s = optimizelyClientInstance.getFeatureVariableJSON('%s', '%s', userId);",
  },
  [ProjectEnums.sdkLanguages.FLUTTER]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES
      .boolean]: FEATURE_VARIABLE_CODE_BLOCK_FLUTTER,
    [FeatureConstants.FEATURE_VARIABLE_TYPES
      .double]: FEATURE_VARIABLE_CODE_BLOCK_FLUTTER,
    [FeatureConstants.FEATURE_VARIABLE_TYPES
      .integer]: FEATURE_VARIABLE_CODE_BLOCK_FLUTTER,
    [FeatureConstants.FEATURE_VARIABLE_TYPES
      .string]: FEATURE_VARIABLE_CODE_BLOCK_FLUTTER,
    [FeatureConstants.FEATURE_VARIABLE_TYPES
      .json]: FEATURE_VARIABLE_CODE_BLOCK_FLUTTER,
  },
  [ProjectEnums.sdkLanguages.JAVA]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      'Boolean %s = optimizelyClient.getFeatureVariableBoolean("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      'Double %s = optimizelyClient.getFeatureVariableDouble("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      'Integer %s = optimizelyClient.getFeatureVariableInteger("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      'String %s = optimizelyClient.getFeatureVariableString("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      'OptimizelyJSON %s = optimizelyClient.getFeatureVariableJSON("%s", "%s", userId);',
  },
  [ProjectEnums.sdkLanguages.PHP]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      "$%s = $optimizelyClient->getFeatureVariableBoolean('%s', '%s', $userId);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      "$%s = $optimizelyClient->getFeatureVariableDouble('%s', '%s', $userId);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      "$%s = $optimizelyClient->getFeatureVariableInteger('%s', '%s', $userId);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      "$%s = $optimizelyClient->getFeatureVariableString('%s', '%s', $userId);",
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      "$%s = $optimizelyClient->getFeatureVariableJSON('%s', '%s', $userId);",
  },
  [ProjectEnums.sdkLanguages.CSHARP]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      'bool? %s = OptimizelyClient.GetFeatureVariableBoolean("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      'double? %s = OptimizelyClient.GetFeatureVariableDouble("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      'int? %s = OptimizelyClient.GetFeatureVariableInteger("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      'string %s = OptimizelyClient.GetFeatureVariableString("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      'OptimizelyJSON %s = OptimizelyClient.GetFeatureVariableJSON("%s", "%s", userId);',
  },
  [ProjectEnums.sdkLanguages.OBJECTIVE_C]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      'NSNumber *%s = [optimizelyClient getFeatureVariableBooleanWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      'NSNumber *%s = [optimizelyClient getFeatureVariableDoubleWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      'NSNumber *%s = [optimizelyClient getFeatureVariableIntegerWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      'NSString *%s = [optimizelyClient getFeatureVariableStringWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      'OptimizelyJSON *%s = [optimizelyClient getFeatureVariableJSONWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
  },
  [ProjectEnums.sdkLanguages.SWIFT]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      'let %s = optimizelyClient?.getFeatureVariableBoolean("%s", variableKey: "%s", userId: userId).boolValue',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      'let %s = optimizelyClient?.getFeatureVariableDouble("%s", variableKey: "%s", userId: userId).doubleValue',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      'let %s = optimizelyClient?.getFeatureVariableInteger("%s", variableKey: "%s", userId: userId).intValue',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      'let %s = optimizelyClient?.getFeatureVariableString("%s", variableKey: "%s", userId: userId)',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      'let %s = optimizelyClient?.getFeatureVariableJSON("%s", variableKey: "%s", userId: userId)',
  },
  [ProjectEnums.sdkLanguages.IOS]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      'NSNumber *%s = [optimizelyClient getFeatureVariableBooleanWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      'NSNumber *%s = [optimizelyClient getFeatureVariableDoubleWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      'NSNumber *%s = [optimizelyClient getFeatureVariableIntegerWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      'NSString *%s = [optimizelyClient getFeatureVariableStringWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      'OptimizelyJSON *%s = [optimizelyClient getFeatureVariableJSONWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
  },
  [ProjectEnums.sdkLanguages.TV_OS]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      'NSNumber *%s = [optimizelyClient getFeatureVariableBooleanWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      'NSNumber *%s = [optimizelyClient getFeatureVariableDoubleWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      'NSNumber *%s = [optimizelyClient getFeatureVariableIntegerWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      'NSString *%s = [optimizelyClient getFeatureVariableStringWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      'OptimizelyJSON *%s = [optimizelyClient getFeatureVariableJSONWithFeatureKey:@"%s" variableKey: @"%s" userId: userId attributes: nil error: nil];',
  },
  [ProjectEnums.sdkLanguages.ANDROID]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      'Boolean %s = optimizelyClient.getFeatureVariableBoolean("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      'Double %s = optimizelyClient.getFeatureVariableDouble("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      'Integer %s = optimizelyClient.getFeatureVariableInteger("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      'String %s = optimizelyClient.getFeatureVariableString("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      'OptimizelyJSON %s = optimizelyClient.getFeatureVariableJSON("%s", "%s", userId);',
  },
  [ProjectEnums.sdkLanguages.ANDROID_TV]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      'Boolean %s = optimizelyClient.getFeatureVariableBoolean("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      'Double %s = optimizelyClient.getFeatureVariableDouble("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      'Integer %s = optimizelyClient.getFeatureVariableInteger("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      'String %s = optimizelyClient.getFeatureVariableString("%s", "%s", userId);',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      'OptimizelyJSON %s = optimizelyClient.getFeatureVariableJSON("%s", "%s", userId);',
  },
  [ProjectEnums.sdkLanguages.GO]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES.boolean]:
      '%s,err := optimizelyClient.GetFeatureVariableBoolean("%s", "%s", entities.UserContext{ID: userId})',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.double]:
      '%s,err := optimizelyClient.GetFeatureVariableDouble("%s", "%s", entities.UserContext{ID: userId})',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.integer]:
      '%s,err := optimizelyClient.GetFeatureVariableInteger("%s", "%s", entities.UserContext{ID: userId})',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.string]:
      '%s,err := optimizelyClient.GetFeatureVariableString("%s", "%s", entities.UserContext{ID: userId})',
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]:
      '%s,err := optimizelyClient.GetFeatureVariableJSON("%s", "%s", entities.UserContext{ID: userId})',
  },
  [ProjectEnums.sdkLanguages.REACT]: {
    [FeatureConstants.FEATURE_VARIABLE_TYPES
      .boolean]: GET_FEATURE_VARIABLES_REACT,
    [FeatureConstants.FEATURE_VARIABLE_TYPES
      .double]: GET_FEATURE_VARIABLES_REACT,
    [FeatureConstants.FEATURE_VARIABLE_TYPES
      .integer]: GET_FEATURE_VARIABLES_REACT,
    [FeatureConstants.FEATURE_VARIABLE_TYPES
      .string]: GET_FEATURE_VARIABLES_REACT,
    [FeatureConstants.FEATURE_VARIABLE_TYPES.json]: GET_FEATURE_VARIABLES_REACT,
  },
};

export const VARIABLE_CODE_BLOCKS = {
  [ProjectEnums.sdks.PYTHON]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.PYTHON],
  [ProjectEnums.sdks.RUBY]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.RUBY],
  [ProjectEnums.sdkLanguages.REACT]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.REACT],
  [ProjectEnums.sdks.NODE]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.JAVASCRIPT],
  [ProjectEnums.sdks.JAVASCRIPT]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.JAVASCRIPT],
  [ProjectEnums.sdks.FLUTTER]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.FLUTTER],
  [ProjectEnums.sdks.JAVA]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.JAVA],
  [ProjectEnums.sdks.PHP]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.PHP],
  [ProjectEnums.sdks.CSHARP]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.CSHARP],
  [ProjectEnums.sdkLanguages.OBJECTIVE_C]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.OBJECTIVE_C],
  [ProjectEnums.sdkLanguages.SWIFT]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.SWIFT],
  [ProjectEnums.sdks.IOS]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.IOS],
  [ProjectEnums.sdks.TV_OS]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.TV_OS],
  [ProjectEnums.sdks.ANDROID]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.ANDROID],
  [ProjectEnums.sdks.ANDROID_TV]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.ANDROID_TV],
  [ProjectEnums.sdks.GO]:
    VARIABLE_CODE_BLOCKS_BY_LANG[ProjectEnums.sdkLanguages.GO],
  [ProjectEnums.sdkLanguages.AGENT]: '',
};

export const FEATURE_CODE_BLOCKS = {
  [ProjectEnums.sdks.PYTHON]:
    "enabled = optimizely_client.is_feature_enabled('%s', user_id)",
  [ProjectEnums.sdks.RUBY]:
    "enabled = optimizely_client.is_feature_enabled('%s', user_id)",
  [ProjectEnums.sdkLanguages.REACT]: FEATURE_CODE_BLOCK_REACT,
  [ProjectEnums.sdks.JAVASCRIPT]:
    "var enabled = optimizelyClientInstance.isFeatureEnabled('%s', userId);",
  [ProjectEnums.sdks.NODE]:
    "var enabled = optimizelyClient.isFeatureEnabled('%s', userId);",
  [ProjectEnums.sdks.JAVA]:
    'Boolean enabled = optimizelyClient.isFeatureEnabled("%s", userId);',
  [ProjectEnums.sdks.PHP]:
    "$enabled = $optimizelyClient->isFeatureEnabled('%s', $userId);",
  [ProjectEnums.sdks.CSHARP]:
    'bool enabled = OptimizelyClient.IsFeatureEnabled("%s", userId);',
  [ProjectEnums.sdkLanguages.OBJECTIVE_C]:
    'bool enabled = [client isFeatureEnabled:@"%s" userId: userId attributes: nil];',
  [ProjectEnums.sdkLanguages.SWIFT]:
    'let enabled = optimizelyClient?.isFeatureEnabled("%s", userId: userId)',
  [ProjectEnums.sdks.IOS]:
    'bool enabled = [client isFeatureEnabled:@"%s" userId: userId];',
  [ProjectEnums.sdks.TV_OS]:
    'bool enabled = [client isFeatureEnabled:@"%s" userId: userId];',
  [ProjectEnums.sdks.ANDROID]:
    'Boolean enabled = optimizelyClient.isFeatureEnabled(c, userId);',
  [ProjectEnums.sdks.ANDROID_TV]:
    'Boolean enabled = optimizelyClient.isFeatureEnabled("%s", userId);',
  [ProjectEnums.sdks.GO]:
    'enabled, err := optimizelyClient.IsFeatureEnabled("%s", entities.UserContext{ID: userId})',
  [ProjectEnums.sdkLanguages.AGENT]: FEATURE_CODE_BLOCK_AGENT,
  [ProjectEnums.sdks
    .FLUTTER]: `var user = await optimizelyClientInstance.createUserContext(userId, attributes);
var decision = await user.decide("%s");
var enabled = decision.enabled;`,
};

export const FEATURE_CODE_BLOCKS_POSTFIX = {
  [ProjectEnums.sdkLanguages.REACT]: FEATURE_CODE_BLOCK_REACT_POSTFIX,
};

export const FEATURE_CODE_BLOCKS_DEFAULT = {
  [ProjectEnums.sdks.PYTHON]: '# add a feature key to generate example code.',
  [ProjectEnums.sdks.RUBY]: '# add a feature key to generate example code.',
  [ProjectEnums.sdks.JAVASCRIPT]:
    '// add a feature key to generate example code.',
  [ProjectEnums.sdks.FLUTTER]: '// add a feature key to generate example code.',
  [ProjectEnums.sdkLanguages.REACT]:
    '// add a feature key to generate example code.',
  [ProjectEnums.sdks.NODE]: '// add a feature key to generate example code.',
  [ProjectEnums.sdks.JAVA]: '// add a feature key to generate example code.',
  [ProjectEnums.sdks.PHP]: '// add a feature key to generate example code.',
  [ProjectEnums.sdks.CSHARP]: '// add a feature key to generate example code.',
  [ProjectEnums.sdkLanguages.OBJECTIVE_C]:
    '// add a feature key to generate example code.',
  [ProjectEnums.sdkLanguages.SWIFT]:
    '// add a feature key to generate example code.',
  [ProjectEnums.sdks.IOS]: '// add a feature key to generate example code.',
  [ProjectEnums.sdks.TV_OS]: '// add a feature key to generate example code.',
  [ProjectEnums.sdks.ANDROID]: '// add a feature key to generate example code.',
  [ProjectEnums.sdks.ANDROID_TV]:
    '// add a feature key to generate example code.',
  [ProjectEnums.sdks.GO]: '// add a feature key to generate example code.',
  [ProjectEnums.sdkLanguages.AGENT]:
    '// add a feature key to generate example code.',
};

// default export
export default {
  VARIABLE_VALUE_DEFAULTS,
  VARIABLE_CODE_BLOCKS_BY_LANG,
  VARIABLE_CODE_BLOCKS,
  FEATURE_CODE_BLOCKS,
  FEATURE_CODE_BLOCKS_DEFAULT,
  FEATURE_CODE_BLOCKS_POSTFIX,
  TABLE_ID,
  CREATING_FIRST_FEATURE_LOADING_ID,
};

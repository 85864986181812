import React from 'react';

import { connect } from 'core/ui/decorators';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { enums as LayerEnums } from 'optly/modules/entity/layer';
import PermissionsGetters from 'optly/modules/permissions/getters';
import TargetingComponent from 'bundles/p13n/components/targeting/layer_targeting_config';

import {
  actions as CurrentlyEditingExperimentActions,
  getters as CurrentlyEditingExperimentGetters,
} from 'bundles/p13n/modules/currently_editing_experiment';

@connect({
  canUseUrlTargeting: PermissionsGetters.canUseUrlTargeting,
  targetingType: CurrentlyEditingExperimentGetters.targetingType(
    LayerEnums.policy.SINGLE_EXPERIMENT,
  ),
  urlTargetingConfig: CurrentlyEditingExperimentGetters.urlTargetingConfig,
  selectedViewIds: CurrentlyEditingExperimentGetters.selectedViewIds,
  views: CurrentProjectGetters.views,
  urlTargetingConditionsError:
    CurrentlyEditingExperimentGetters.urlTargetingConditionsError,
  urlTargetingApiNameError:
    CurrentlyEditingExperimentGetters.urlTargetingApiNameError,
  urlTargetingUrlError: CurrentlyEditingExperimentGetters.urlTargetingUrlError,
  viewsError: CurrentlyEditingExperimentGetters.savedPagesError,
})
class BoundTargetingComponent extends React.Component {
  render() {
    return (
      <TargetingComponent
        isABExperiment={true}
        isCreating={true}
        onChangeSelectedViewIds={
          CurrentlyEditingExperimentActions.setSelectedViewIds
        }
        onChangeUrlTargeting={
          CurrentlyEditingExperimentActions.setUrlTargetingConfig
        }
        setTargetingType={CurrentlyEditingExperimentActions.setTargetingType}
        {...this.props}
      />
    );
  }
}

export default BoundTargetingComponent;

import PropTypes from 'prop-types';
import React from 'react';

import Immutable from 'optly/immutable';

import DashboardEntityTable from 'bundles/p13n/components/entity_dashboard/entity_table';
import FilterableTableEnums from 'optly/modules/filterable_table/enums';

import EventCardTableHeader from '../event_card_table_header';
import PageEventTableRow from './table_row';

const PAGE_TABLE_ID = FilterableTableEnums.tableIds.P13N_DATA_LAYER_PAGE_EVENTS;

class PageEventsTable extends React.Component {
  static propTypes = {
    canArchiveUserEvent: PropTypes.bool.isRequired,
    canArchiveView: PropTypes.bool.isRequired,
    currentProjectId: PropTypes.number.isRequired,
    configureView: PropTypes.func.isRequired,
    events: PropTypes.instanceOf(Immutable.List).isRequired,
    experimentsUsageAvailable: PropTypes.bool.isRequired,
    layers: PropTypes.instanceOf(Immutable.Map).isRequired,
    pluginEventTypeToName: PropTypes.instanceOf(Immutable.Map).isRequired,
    shouldShowEvent: PropTypes.func,
    view: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  static defaultProps = {
    shouldShowEvent: true,
  };

  renderTableRow = (event, index) => {
    const {
      canArchiveUserEvent,
      canArchiveView,
      configureView,
      currentProjectId,
      experimentsUsageAvailable,
      layers,
      pluginEventTypeToName,
      shouldShowEvent,
      view,
    } = this.props;
    if (shouldShowEvent(event, index)) {
      return (
        <PageEventTableRow
          canArchiveUserEvent={canArchiveUserEvent}
          canArchiveView={canArchiveView}
          currentProjectId={currentProjectId}
          configureView={configureView}
          event={event}
          experimentsUsageAvailable={experimentsUsageAvailable}
          key={event.get('id')}
          layers={layers}
          pluginEventTypeToName={pluginEventTypeToName}
          view={view}
        />
      );
    }
  };

  render() {
    const { events } = this.props;

    return (
      <DashboardEntityTable
        tableId={PAGE_TABLE_ID}
        data={events}
        disableScrolling={true}
        entityPlural="events"
        renderTableRow={this.renderTableRow}
        renderTableHeader={EventCardTableHeader}
        defaultSortBy={{ field: 'name', type: 'string' }}
      />
    );
  }
}

export default PageEventsTable;

/* eslint-disable */
/**
 * Checks whether an email is a temporary Optimizely email created for testing
 * using the same logic as temp account data deletion:
 *   https://github.com/optimizely/optimizely/blob/devel/src/www/services/users.py#L647
 *
 * The following are examples of all the email patterns recognized as temporary accounts:
 *   bdd+test1491946867.53.02747@optimizely.com
 *   forgot_password+1491947117.73.47300@optimizely.com
 *   fullstacke2e-test-254515a@optimizely.com
 *   new_user+1491946909.01.36924@optimizely.com
 *   selenium_1491946893.23@optimizely.com
 *   test-f4d137b@optimizely.com
 * @param {String} email
 */
const isTemporaryOptimizelyEmail = function(email) {
  return (
    /^bdd\+test[\d\.]+@optimizely.com$/.test(email) ||
    /^forgot_password\+[\d\.]+@optimizely.com$/.test(email) ||
    /^fullstacke2e\-test\-[\d\.\w]+@optimizely.com$/.test(email) ||
    /^new_user\+[\d\.]+@optimizely.com$/.test(email) ||
    /^selenium_[\d\.]+@optimizely.com$/.test(email) ||
    /^test-[\w]{7}@optimizely.com$/.test(email)
  );
};

exports.isTemporaryOptimizelyEmail = function(email) {
  return isTemporaryOptimizelyEmail(email);
};

/**
 * Services layer pure functions for the schedules
 */
import _ from 'lodash';

import enums from './enums';

/**
 * Generate time string which indicates to the user which GMT offset they are currently in
 * NOTE: This function is not pure and depends on the time of the environment
 * @return {Array.<Object>}
 */
export function generateLocalTimeString() {
  let now = new Date();
  const match = now.toString().match(/\(.*\)$/);
  const timeZoneCode = match ? match[0] : '';

  now = tr.date();
  const formattedNow = now.format('LT');
  const GTMString = this.timezoneOffsetToGMTString(now.utcOffset());
  return `${formattedNow}, ${GTMString} ${timeZoneCode}`;
}

/**
 * Get current users timezone name (ie America/New_York) from Intl api
 */
export function getCurrentTimezoneName() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Create a string to describe the users local time and timezone
 * Example output:
 * 7:58 PM (America/New York (GMT-05:00))
 */
export function generateLocalTimeZoneNameString() {
  const now = tr.date();
  const formattedNow = now.format('LT');
  const GTMString = this.timezoneOffsetToGMTString(now.utcOffset());
  const timezone = this.getCurrentTimezoneName().replace('_', ' ');
  return `${formattedNow} (${timezone} (${GTMString}))`;
}

/**
 * Helper function to translate timezone offset in minutes into string GMT[+-]XX:XX
 * @param offset {Number} Timezone offset in minutes - can be positive or negative
 * @returns {string} GMT[+-]XX:XX string
 */
export function timezoneOffsetToGMTString(offset) {
  offset = parseInt(offset, 10);
  if (_.isNaN(offset)) {
    return null;
  }
  return `GMT${tr
    .date()
    .utcOffset(offset)
    .format('Z')}`;
}

/**
 * Helper function to translate GMT[+-]XX:XX string into timezone offset in minutes
 * @param gmtString {string} GMT[+-]XX:XX string
 * @returns {Number} Timezone offset in minutes - can be positive or negative
 */
export function gmtStringToTimezoneOffset(gmtString) {
  const md = gmtString.match(/^GMT([+-])(\d\d?):(\d\d)$/);
  if (md === null) {
    return null;
  }
  gmtString = `${md[1] + (md[2].length === 1 ? '0' : '') + md[2]}:${md[3]}`;
  return tr
    .date()
    .utcOffset(gmtString)
    .utcOffset();
}

/**
 * Generate offset from timezone
 * @param {String} timezone in GMT format
 * @return {Integer|undefined} offset value representing timezone
 */
export function getOffsetFromTimezone(timezone) {
  const timezoneObj = _.find(enums.TIMEZONES, { title: timezone });
  if (!timezoneObj) {
    return;
  }
  return Number(timezoneObj.offset);
}

/**
 * Generate timezone from offset
 * @param {Integer} offset value for GMT timezone
 * @return {String|undefined} timezone string representing offset
 */
export function getTimezoneFromOffset(offset) {
  const timezoneObj = _.find(enums.TIMEZONES, { offset: Number(offset) });
  if (!timezoneObj) {
    return;
  }
  return timezoneObj.title;
}

/**
 * Returns a boolean if the time is in the future
 * @param {String} dateTime where the year, month, date, and hour are set to what
 *                 you expect, but the proper timezone is indicated below
 * @param {String} timezone of where the datetime should be set to
 * @return {Boolean} indicating whether the time is in the future
 */
export function isTimeInFuture(dateTime, timeZone) {
  const time = tr.date.utc(dateTime);
  const year = time.year();
  const month = time.month();
  const date = time.date();
  const hour = time.hour();
  const minute = time.minute();
  const second = time.second();

  const offset = this.getOffsetFromTimezone(timeZone);
  const dateWithOffset = tr
    .date()
    .utcOffset(offset)
    .set('year', year)
    .set('month', month)
    .set('date', date)
    .set('hour', hour)
    .set('minute', minute)
    .set('second', second);
  const isInFuture = dateWithOffset.isAfter(tr.date());
  return isInFuture;
}

export default {
  generateLocalTimeString,
  getCurrentTimezoneName,
  generateLocalTimeZoneNameString,
  timezoneOffsetToGMTString,
  gmtStringToTimezoneOffset,
  getOffsetFromTimezone,
  getTimezoneFromOffset,
  isTimeInFuture,
};

/**
 * Polyfill for Vue 0.11 v-el directive
 */
const exported = {
  isLiteral: true,

  bind() {
    this.vm.$$ = this.vm.$$ || {};
    this.vm.$$[this.expression] = this.el;
  },

  unbind() {
    delete this.vm.$$[this.expression];
  },
};

export default exported;

export const { isLiteral, bind, unbind } = exported;

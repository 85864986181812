import React from 'react';

import flux from 'core/flux';

import CurrentProjectGetters from 'optly/modules/current_project/getters';

import NavConstants from 'optly/services/navigation';
import RoutingFns from 'bundles/p13n/routing_fns';

import FeaturesDashboardPage from './pages/features_dashboard';

const category = 'Features';

export default [
  {
    match: '/v2/projects/:proj_id/features/:feature_id?',
    metadata: { name: 'Feature Flags', category },
    handle: [
      ...RoutingFns.standardNavHandlers(
        NavConstants.NavWidth.OPEN,
        NavConstants.NavItems.FEATURES,
        NavConstants.FeaturesTabs.FEATURES_DASHBOARD,
      ),
      RoutingFns.parseProjectId,
      FeaturesDashboardPage.routingSetup,
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Feature Flags',
          next,
        }),
      ctx => {
        // redirect if not a fullstack project
        const isFullStackProject = flux.evaluate(
          CurrentProjectGetters.isFullStackProject,
        );
        if (!isFullStackProject) {
          RoutingFns.redirectToCurrentProjectLayers();
          return;
        }

        const featureId = Number(ctx.params.feature_id);

        RoutingFns.renderMainRegion(
          <FeaturesDashboardPage.component featureId={featureId} />,
        );
      },
    ],
  },
];

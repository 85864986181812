/**
 * Module specific pure functions
 */

import { List } from 'immutable';

/**
 * Given an entity return a list of all email addresses
 * which are subscribed to the entity indicated.
 *
 * @param {Immutable.Map} entity
 *   Expected entity structure example:
 *
 *   [
 *     {
 *       account_id: 1
 *       user_id: james@optimizely.com,
 *       last_visited: 2022-05-24T17:30:23.828026Z
 *     },
 *     {
 *       account_id: 2,
 *       user_id: travis@optimizely.com,
 *       last_visited: 2022-05-24T17:31:23.828026Z
 *     }
 *   ]
 *
 * @returns {Immutable.List}
 *   Expected output example:
 *
 *   ['james@optimizely.com', 'travis@optimizely.com']
 */
export function userListFromEntity(visitors = List()) {
  return visitors.map(userInfo => userInfo.get('user_id'));
}

export const excludeUser = (emails, emailToExclude) =>
  emails.filter(user => user !== emailToExclude);

export const sortUsers = emails =>
  emails.sort((a, b) =>
    a.toString().localeCompare(b.toString(), 'en', { numeric: true }),
  );

export default {
  excludeUser,
  sortUsers,
  userListFromEntity,
};

import flux from 'core/flux';

import fns from './fns';
import actionTypes from './action_types';

export const updateOutlierFilter = outlierFilter => {
  flux.dispatch(actionTypes.METRICS_MANAGER_UPDATE_OUTLIER_FILTER, {
    outlierFilter,
  });
};

export const setDefaultOutlierFilter = layer => {
  const defaultOutlierFilter = fns.getDefaultOutlierFilter(layer);
  updateOutlierFilter(defaultOutlierFilter);
};

export const setOriginalOutlierFilter = layer => {
  const originalOutlierFilter = fns.getOriginalOutlierFilter(layer);
  updateOutlierFilter(originalOutlierFilter);
};

export const resetWorkingMetricWrappers = () => {
  flux.dispatch(actionTypes.METRICS_MANAGER_RESET_WORKING_METRIC_WRAPPERS);
};

export const updateWorkingMetricWrappers = metricWrappers => {
  flux.dispatch(actionTypes.METRICS_MANAGER_UPDATE_WORKING_METRIC_WRAPPERS, {
    metricWrappers,
  });
};

export const setCurrentlyEditingMetricWrapper = metricWrapper => {
  flux.dispatch(
    actionTypes.METRIC_EDITOR_SET_CURRENTLY_EDITING_METRIC_WRAPPER,
    { metricWrapper },
  );
};

export default {
  resetWorkingMetricWrappers,
  setCurrentlyEditingMetricWrapper,
  setDefaultOutlierFilter,
  setOriginalOutlierFilter,
  updateOutlierFilter,
  updateWorkingMetricWrappers,
};

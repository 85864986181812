const _ = require('lodash');

const moment = require('moment');
const JSSDKLabActions = require('@optimizely/js-sdk-lab/src/actions').default;

const { default: Immutable, toImmutable } = require('optly/immutable');

const AdminAccountGetters = require('optly/modules/admin_account/getters');
const AudienceGetters = require('optly/modules/entity/audience/getters')
  .default;
const AudienceEnums = require('optly/modules/entity/audience/enums');
const Concurrency = require('bundles/p13n/modules/concurrency_legacy').default;
const CommitGetters = require('optly/modules/entity/commit/getters').default;
const CurrentProjectGetters = require('optly/modules/current_project/getters');
const EventGetters = require('optly/modules/entity/event/getters').default;
const ExperimentSectionGetters = require('optly/modules/entity/experiment_section/getters')
  .default;
const ExperimentationGroupFns = require('optly/modules/entity/experimentation_group/fns');
const ExperimentationGroupGetters = require('optly/modules/entity/experimentation_group/getters')
  .default;
const LayerFns = require('optly/modules/entity/layer/fns');
const LayerGetters = require('optly/modules/entity/layer/getters').default;
const LayerExperiment = require('optly/modules/entity/layer_experiment');
const LiveCommitTagGetters = require('optly/modules/entity/live_commit_tag/getters');
const MetricFns = require('optly/modules/entity/metric/fns');
const PermissionsModuleFns = require('optly/modules/permissions/fns');
const ViewGetters = require('optly/modules/entity/view/getters');

const fns = require('./fns');

exports.id = ['p13n/currentLayer', 'id'];
exports.shareToken = ['p13n/currentLayer', 'share_token'];
exports.outlierFilter = ['p13n/currentLayer', 'outlier_filter'];

/**
 * The currently selected layer in the UI
 */
exports.layer = [
  exports.id,
  LayerGetters.entityCache,
  function(id, entityCache) {
    return entityCache.get(id);
  },
];

exports.isLayerInDraftStatus = [exports.layer, LayerFns.isLayerInDraftStatus];

exports.liveCommitTag = [
  exports.id,
  LiveCommitTagGetters.entityCache,
  function(id, commitTags) {
    if (!id) {
      return;
    }
    return commitTags.find(commitTag => commitTag.get('layer_id') === id);
  },
];

/**
 * The currently live commit id for the selected layer in the UI
 */
exports.liveCommitId = [
  exports.liveCommitTag,
  function(liveCommitTag) {
    if (liveCommitTag) {
      return liveCommitTag.get('commit_id');
    }
  },
];

/**
 * The layer level changes for the selected layer in the UI
 */
exports.layerChanges = [
  exports.layer,
  function(currentLayer) {
    if (currentLayer) {
      return currentLayer.get('changes') || toImmutable([]);
    }
    return toImmutable([]);
  },
];

exports.liveCommit = [
  exports.liveCommitId,
  CommitGetters.entityCache,
  function(id, entityCache) {
    if (!id) {
      return;
    }
    return entityCache.get(id);
  },
];

exports.liveCommitLayer = [
  exports.liveCommit,
  function(liveCommit) {
    if (liveCommit) {
      return liveCommit.getIn(['revisions', 'layer']).first();
    }
  },
];

/**
 * Pull out an ordered list of audience ids based on the experiment_ids
 * property of the current live commit layer.
 *
 * Making the same assumptions here as the layers getter where:
 *    1. All personalization experiments have only one audience_id and
 *       it's unique for the layer
 *    2. AB layers only have one layer experiment which lists all the audience_ids
 * DEPRECATED, use liveCommitAudienceIdsFromExperiments instead
 */
exports.liveCommitLayerAudienceIds = [
  exports.liveCommit,
  exports.liveCommitLayer,
  /**
   * @param {Immutable.Map} liveCommit
   * @param {Immutable.Map} liveCommitLayer
   * @returns {Immutable.List}
   */
  function(liveCommit, liveCommitLayer) {
    let audienceIds = [];
    if (liveCommitLayer) {
      const commitExperimentIds = liveCommitLayer.get('experiment_ids');
      if (!!commitExperimentIds && !!commitExperimentIds.size) {
        commitExperimentIds.map(experimentId => {
          const matchingExperiments = liveCommit
            .getIn(['revisions', 'layer_experiment'])
            .filter(
              layerExperiment => layerExperiment.get('id') === experimentId,
            );

          if (matchingExperiments.size > 0) {
            audienceIds = audienceIds.concat(
              matchingExperiments
                .first()
                .get('audience_ids')
                .toJS(),
            );
          }
        });
      }
    }

    return toImmutable(audienceIds);
  },
];

/**
 * Provides a list of ids of all audiences in the audience_ids property of all
 * layer experiments in the live commit's revisions
 */
exports.liveCommitAudienceIdsFromExperiments = [
  exports.liveCommit,
  liveCommit => {
    if (!liveCommit) {
      return toImmutable([]);
    }
    const commitLayerExperiments = liveCommit.getIn(
      ['revisions', 'layer_experiment'],
      toImmutable([]),
    );
    return commitLayerExperiments
      .reduce(
        (allAudienceIds, experiment) =>
          allAudienceIds.union(experiment.get('audience_ids')),
        Immutable.Set(),
      )
      .toList();
  },
];

exports.liveCommitLayerExperiments = [
  exports.liveCommit,
  function(liveCommit) {
    if (liveCommit) {
      return liveCommit.getIn(['revisions', 'layer_experiment']);
    }
    return toImmutable([]);
  },
];

exports.liveCommitExperimentSections = [
  exports.liveCommit,
  function(liveCommit) {
    if (liveCommit) {
      return liveCommit.getIn(['revisions', 'experiment_section']);
    }
    return toImmutable([]);
  },
];

/**
 * The layer level changes for the live commit layer
 */
exports.liveCommitLayerChanges = [
  exports.liveCommitLayer,
  function(liveCommitLayer) {
    if (liveCommitLayer) {
      return liveCommitLayer.get('changes') || toImmutable([]);
    }
  },
];

/**
 * The variations associated with the current layer
 */

exports.liveCommitLayerVariationIds = [
  exports.liveCommitLayerExperiments,
  function(experiments) {
    let variationIds = [];
    if (experiments && !!experiments.size) {
      experiments.map(experiment => {
        experiment.get('variations').map(variation => {
          variationIds = variationIds.concat(variation.get('variation_id'));
        });
      });
    }
    return toImmutable(variationIds);
  },
];

/**
 * Retrieve the singular custom code(js) change from the currently editing Layer, if it exists.
 */
exports.layerCustomCodeChange = [
  exports.layerChanges,
  function(layerChanges) {
    return (
      layerChanges &&
      layerChanges.find(
        change =>
          change.get('type') === LayerExperiment.enums.ChangeTypes.CUSTOM_CODE,
      )
    );
  },
];

/**
 * Retrieve the timing of singular custom code(js) change from the currently editing Layer, if it exists.
 *
 * Otherwise, return false for the timing.
 */
exports.layerCustomCodeChangeTiming = [
  exports.layerCustomCodeChange,
  function(change) {
    if (change && change.has('async')) {
      return change.get('async');
    }
    return false;
  },
];

/**
 * Retrieve the singular custom css change from the currently editing Layer, if it exists.
 */
exports.layerCustomCSSChange = [
  exports.layerChanges,
  function(layerChanges) {
    return (
      layerChanges &&
      layerChanges.find(
        change =>
          change.get('type') === LayerExperiment.enums.ChangeTypes.CUSTOM_CSS,
      )
    );
  },
];

/**
 * Retrieve the timing of singular custom css code change from the currently editing Layer, if it exists.
 *
 * Otherwise, return false for the timing.
 */
exports.layerCustomCSSChangeTiming = [
  exports.layerCustomCSSChange,
  function(change) {
    if (change && change.has('async')) {
      return change.get('async');
    }
    return false;
  },
];

/**
 * Retrieve the singular custom code (js) change from the live commit layer, if it exists.
 */
exports.liveCommitLayerCustomCodeChange = [
  exports.liveCommitLayerChanges,
  function(liveCommitLayerChanges) {
    return (
      liveCommitLayerChanges &&
      liveCommitLayerChanges.find(
        change =>
          change.get('type') === LayerExperiment.enums.ChangeTypes.CUSTOM_CODE,
      )
    );
  },
];

/**
 * Retrieve the singular custom live css change from the live commit Layer, if it exists.
 */
exports.liveCommitLayerCustomCSSChange = [
  exports.liveCommitLayerChanges,
  function(liveCommitLayerChanges) {
    return (
      liveCommitLayerChanges &&
      liveCommitLayerChanges.find(
        change =>
          change.get('type') === LayerExperiment.enums.ChangeTypes.CUSTOM_CSS,
      )
    );
  },
];

/**
 * Compare the difference in lines of code between liveCommitLayerCustomCodeChange
 * and layerCustomCodeChange and return the number of lines added/removed.
 */
exports.currentLayerCustomCodeLinesChanged = [
  exports.liveCommitLayerCustomCodeChange,
  exports.layerCustomCodeChange,
  fns.customCodeLinesChanged,
];

/**
 * Compare the difference in lines of code between liveCommitLayerCustomCSSChange
 * and layerCustomCSSChange and return the number of lines added/removed.
 */
exports.currentLayerCustomCSSLinesChanged = [
  exports.liveCommitLayerCustomCSSChange,
  exports.layerCustomCSSChange,
  fns.customCodeLinesChanged,
];

exports.campaignCustomCodeLinesChanged = [
  exports.currentLayerCustomCodeLinesChanged,
  exports.currentLayerCustomCSSLinesChanged,
  (cssLinesChanged, jsLinesChanged) => {
    const cssLinesRemoved = cssLinesChanged.removed || 0;
    const jsLinesRemoved = jsLinesChanged.removed || 0;
    const cssLinesAdded = cssLinesChanged.added || 0;
    const jsLinesAdded = jsLinesChanged.added || 0;
    return cssLinesAdded + jsLinesAdded + cssLinesRemoved + jsLinesRemoved;
  },
];

exports.customCodeTooltipText = [
  exports.currentLayerCustomCodeLinesChanged,
  exports.currentLayerCustomCSSLinesChanged,
  (cssLinesChanged, jsLinesChanged) => {
    const cssLinesRemoved = cssLinesChanged.removed || 0;
    const jsLinesRemoved = jsLinesChanged.removed || 0;
    const cssLinesAdded = cssLinesChanged.added || 0;
    const jsLinesAdded = jsLinesChanged.added || 0;

    const removed = cssLinesRemoved + jsLinesRemoved;
    const linesRemoved =
      removed === 1
        ? tr('{0} line removed', removed)
        : tr('{0} lines removed', removed);

    const added = cssLinesAdded + jsLinesAdded;
    const linesAdded =
      added === 1 ? tr('{0} line added', added) : tr('{0} lines added', added);

    return `${linesAdded}<br>${linesRemoved}`;
  },
];

exports.isLiveCommitPushedToCDN = [
  exports.liveCommitTag,
  CurrentProjectGetters.project,
  function(liveCommitTag, currentProject) {
    if (!liveCommitTag) {
      return;
    }
    return (
      liveCommitTag.get('project_code_revision') <=
      currentProject.get('cdn_revision')
    );
  },
];

/**
 * Layer experiments associated with this layer which are currently
 * active (meaning their experimentId is in the layer.experiment_ids list).
 * DEPRECATED, use allExperimentsPointingToLayer instead
 */
exports.experiments = [
  exports.layer,
  LayerExperiment.getters.entityCache,
  /**
   * @param {Immutable.Map} layer
   * @param {Immutable.Map} experimentMap
   * @return {Immutable.List}
   */
  function(layer, experimentMap) {
    if (!layer) {
      return toImmutable([]);
    }
    // This will automatically return the experiments ordered
    if (!!layer.get('experiment_ids') && !!layer.get('experiment_ids').size) {
      return layer
        .get('experiment_ids')
        .map(experimentId => experimentMap.get(experimentId))
        .toList();
    }

    return toImmutable([]);
  },
];

exports.orderedExperimentIds = [
  exports.experiments,
  exports.layer,
  (experiments, layer) => {
    if (!layer) {
      return toImmutable([]);
    }

    const {
      unarchivedGroups,
      archivedGroup,
    } = LayerFns.getPriorityGroupsSplitByStatus(layer, experiments);

    return unarchivedGroups.flatten().concat(archivedGroup);
  },
];

/**
 * The current layer experiment, to be used ONLY with AB tests
 * DEPRECATED, please use currentSingleExperimentFromAllExperimentsPointingToLayer instead.
 * TODO (jessica.chong): DEPRECATE THIS!! https://optimizely.atlassian.net/browse/WEB-1751
 */
exports.currentLayerABExperiment = [
  exports.experiments,
  function(layerExperiments) {
    // TODO (Lauren): update to deal with more than one experiment
    return layerExperiments.get(0);
  },
];

/**
 * Returns a list of users who are currently editing the current AB layer experiment.
 * @type {Array}
 */
exports.currentlyEditingCollaboratorsForABExperiment = [
  exports.currentLayerABExperiment,
  Concurrency.getters.entities,
  AdminAccountGetters.email,
  (experiment, concurrencyEntities, currentUserEmail) => {
    let experimentId;
    if (experiment) {
      experimentId = experiment.get('id');
    }
    const concurrencyEntity = concurrencyEntities.getIn([
      LayerExperiment.entityDef.entity,
      experimentId,
    ]);
    if (!concurrencyEntity) {
      return toImmutable([]);
    }
    // Filter out the current user.
    return Concurrency.fns
      .userListFromEntity(concurrencyEntity)
      .filter(email => email !== currentUserEmail);
  },
];

/**
 * Fetch Experiments with specified status in current layer
 * @param status  -- Layer experiment status to filter by (e.g. Active, Archived)
 * @returns {Immutable.List}
 */
exports.filteredExperimentsByStatus = function(status) {
  return [
    exports.experimentsAndAssociatedAudiences,
    function(allExperiments) {
      return LayerExperiment.fns.getExperimentsByStatus(allExperiments, status);
    },
  ];
};

/**
 * Get the first variation of each experiment currently active in the layer
 * for p13n and all variations for all experiments for AB.
 * DEPRECATED, use variationsFromAllExperimentsPointingToLayer
 * @return {Immutable.List}
 */
exports.variations = [
  exports.experiments,
  function(experiments) {
    let variations = [];
    experiments.map(experiment => {
      variations = variations.concat(experiment.get('variations').toJS());
    });

    return toImmutable(variations);
  },
];

exports.variationsWithPercentages = [
  exports.variations,
  function(variations) {
    return variations.map(variation =>
      variation.set('percentage', variation.get('weight') / 100),
    );
  },
];

/**
 * Gets all the non-archived variations
 * return {Immutable.List}
 */
exports.liveVariations = [
  exports.variationsWithPercentages,
  function(variations) {
    return variations.filter(variation => variation.get('archived') !== true);
  },
];

/**
 * Gets all stopped variations (stopped in UI and published - cannot be restored)
 * return {Immutable.List}
 */
exports.stoppedVariations = [
  exports.variationsWithPercentages,
  exports.currentLayerABExperiment,
  exports.liveCommitLayerExperiments,
  function(variations, layerExpriment, liveCommitLayerExperiments) {
    if (layerExpriment) {
      const experimentId = layerExpriment.toJS().id;
      liveCommitLayerExperiments = liveCommitLayerExperiments
        ? liveCommitLayerExperiments.toJS()
        : [];
      return toImmutable(
        _.filter(
          variations.toJS(),
          variation =>
            variation.archived &&
            !LayerExperiment.fns.isVariationPublishedActive(
              variation,
              experimentId,
              liveCommitLayerExperiments,
            ),
        ),
      );
    }
    return toImmutable([]);
  },
];

/**
 * Gets all stopping variations (stopped in UI but not yet published - can still be restored)
 * @type {Immutable.List}
 */
exports.stoppingVariations = [
  exports.variationsWithPercentages,
  exports.currentLayerABExperiment,
  exports.liveCommitLayerExperiments,
  function(variations, layerExpriment, liveCommitLayerExperiments) {
    if (layerExpriment) {
      const experimentId = layerExpriment.toJS().id;
      liveCommitLayerExperiments = liveCommitLayerExperiments
        ? liveCommitLayerExperiments.toJS()
        : [];
      return toImmutable(
        _.filter(
          variations.toJS(),
          variation =>
            variation.archived &&
            LayerExperiment.fns.isVariationPublishedActive(
              variation,
              experimentId,
              liveCommitLayerExperiments,
            ),
        ),
      );
    }
    return toImmutable([]);
  },
];

/**
 * All layer experiments whose layer_id points to the given layer
 */
exports.allExperimentsPointingToLayer = [
  exports.layer,
  LayerExperiment.getters.entityCache,
  /**
   * @param {Immutable.Map} layer
   * @param {Immutable.Map} experimentMap
   * @return {Immutable.List}
   */
  function(layer, experimentMap) {
    if (!layer) {
      return toImmutable([]);
    }

    const layerId = layer.get('id');
    return experimentMap
      .filter(experiment => experiment.get('layer_id') === layerId)
      .toList();
  },
];

/**
 * Very basic getter to just get a list of experiment ids that are associated with the current layer
 */
exports.allExperimentIdsPointingToLayer = [
  exports.allExperimentsPointingToLayer,
  layerExperiments => {
    if (!layerExperiments) {
      return toImmutable([]);
    }

    return layerExperiments.map(experiment => experiment.get('id'));
  },
];

/**
 * All experiment sections whose layer_id points to the given layer
 */
exports.allSectionsPointingToLayer = [
  exports.layer,
  ExperimentSectionGetters.entityCache,
  /**
   * @param {Immutable.Map} layer
   * @param {Immutable.Map} experimentMap
   * @return {Immutable.List}
   */
  function(layer, sectionMap) {
    if (!layer) {
      return toImmutable([]);
    }

    const layerId = layer.get('id');
    return sectionMap
      .filter(section => section.get('layer_id') === layerId)
      .toList();
  },
];

exports.allActiveSectionsPointingToLayer = [
  exports.allSectionsPointingToLayer,
  _sections => _sections.filter(_section => !_section.get('archived')),
];

/**
 * All layer experiments OR sections whose layer_id points to the given layer. For use in the editor.
 */
exports.allExperimentsOrSectionsPointingToLayer = [
  exports.layer,
  exports.allSectionsPointingToLayer,
  exports.allExperimentsPointingToLayer,
  /**
   * @param {Immutable.Map} layer
   * @param {Immutable.Map} allExperimentsPointingToLayer
   * @param {Immutable.Map} allSectionsPointingToLayer
   * @return {Immutable.List}
   */
  function(layer, allSectionsPointingToLayer, allExperimentsPointingToLayer) {
    if (!layer) {
      return toImmutable([]);
    }

    const isMultivariateTestLayer = LayerFns.isMultivariateTestLayer(layer);
    // Eventually, we may have to update this if we need to have read-only combinations in the editor.
    // In which case, we could?? concatenate the list of sections with the experiment.
    return isMultivariateTestLayer
      ? allSectionsPointingToLayer
      : allExperimentsPointingToLayer;
  },
];

exports.canViewVariationsInP13N = [
  CurrentProjectGetters.project,
  exports.layer,
  PermissionsModuleFns.canViewVariationsInP13N,
];

/**
 * Gets all active audiences in current layer
 * DEPRECATED, use audiencesFromAllExperimentsPointingToLayer instead
 */
exports.audiences = [
  exports.layer,
  LayerExperiment.getters.entityCache,
  AudienceGetters.entityCache,
  exports.orderedExperimentIds,
  /**
   * @param {Immutable.Map} layer
   * @param {Immutable.Map} experiments
   * @param {Immutable.Map} audiences
   * @param {Immutable.List} orderedExperimentIds
   * @return {Immutable.List}
   */
  (layer, experiments, audiences, orderedExperimentIds) => {
    if (!layer) {
      return toImmutable([]);
    }

    let allAudiences = Immutable.OrderedSet();

    // Get all audiences that are included in any of the current layer's experiments
    // An audience included in n experiments will appear n times in allAudiences
    if (!orderedExperimentIds.size) {
      return toImmutable([]);
    }
    orderedExperimentIds.map(experimentId => {
      experiments
        .filter(experiment => experiment.get('id') === experimentId)
        .map(experiment => {
          allAudiences = allAudiences.concat(experiment.get('audience_ids'));
        });
    });

    return allAudiences.map(audienceId => audiences.get(audienceId)).toList();
  },
];

exports.audiencesInExperiments = [
  exports.experiments,
  AudienceGetters.entityCache,
  function(experiments, audiences) {
    if (!audiences || !experiments) {
      return toImmutable([]);
    }

    return experiments.map(experiment =>
      audiences.get(experiment.get('audience_ids').first()),
    );
  },
];

/**
 * Convenience getter to take the ordered audiences and pull out the audience ids
 * DEPRECATED, use audienceIdsFromAllExperimentsPointingToLayer instead
 */
exports.orderedAudienceIds = [
  exports.audiences,
  function(audiences) {
    if (!audiences) {
      return toImmutable([]);
    }

    return audiences.map(audience => audience.get('id'));
  },
];

/**
 * Grab all experiments for the current layer and supplement each with an `audiences` property
 * that contains references to each audience specified in the given experiments `audience_ids` list
 *
 * @returns {Immutable.List}
 */
exports.experimentsAndAssociatedAudiences = [
  exports.layer,
  exports.experiments,
  AudienceGetters.entityCache,
  exports.orderedAudienceIds,
  function(currentLayer, experiments, audiences, orderedAudienceIds) {
    let currentLayerExperimentsMap = toImmutable({});
    currentLayerExperimentsMap = currentLayerExperimentsMap.withMutations(
      mCurrentLayerExperimentsMap => {
        experiments.forEach(experiment => {
          let experimentAudiences = toImmutable([]);
          experiment.get('audience_ids').forEach(id => {
            const matchingAudience = audiences.find(
              audience => audience.get('id') === id,
            );
            if (matchingAudience) {
              experimentAudiences = experimentAudiences.push(matchingAudience);
            }
          });

          if (experimentAudiences.size === 0) {
            return;
          }

          mCurrentLayerExperimentsMap.set(
            experimentAudiences.first().get('id'),
            experiment.set('audiences', experimentAudiences).set('reach', null),
          );
        });
      },
    );

    let layerExperimentsSortedByAudience = toImmutable([]);
    layerExperimentsSortedByAudience = layerExperimentsSortedByAudience.withMutations(
      mLayerExperimentsSortedByAudience => {
        orderedAudienceIds.map(audienceId => {
          if (currentLayerExperimentsMap.has(audienceId)) {
            mLayerExperimentsSortedByAudience.push(
              currentLayerExperimentsMap.get(audienceId),
            );
          }
        });
      },
    );

    return layerExperimentsSortedByAudience;
  },
];

/**
 * All added views (those viewIds that are saved in the layer but not in the live commit) for the current campaign
 * @param  {Layer} layer
 * @param  {LiveCommit} liveCommit
 */
exports.addedViews = [
  exports.layer,
  exports.liveCommitLayer,
  function(layer, liveCommitLayer) {
    if (liveCommitLayer) {
      return layer
        .get('view_ids')
        .filterNot(viewId => liveCommitLayer.get('view_ids').includes(viewId));
    }
    return toImmutable([]);
  },
];

/**
 * All removed views (those viewIds that in the live commit but are not saved in the layer) for the current campaign
 * @param  {Layer} layer
 * @param  {LiveCommit} liveCommit
 */
exports.removedViews = [
  exports.layer,
  exports.liveCommitLayer,
  function(layer, liveCommitLayer) {
    if (liveCommitLayer) {
      return liveCommitLayer
        .get('view_ids')
        .filterNot(viewId => layer.get('view_ids').includes(viewId));
    }
    return toImmutable([]);
  },
];

/**
 * All added audiences (those audienceIds that are not in the live commit but are saved in the layer) for the current campaign
 * @param  {List} orderedAudienceIds
 * @param  {List} liveCommitLayerAudienceIds
 * DEPRECATED, use addedAudiencesFromAllExperimentsPointingToLayer instead
 */
exports.addedAudiences = [
  exports.orderedAudienceIds,
  exports.liveCommitLayerAudienceIds,
  function(orderedAudienceIds, liveCommitLayerAudienceIds) {
    if (liveCommitLayerAudienceIds.size) {
      return orderedAudienceIds
        .filterNot(audienceId =>
          liveCommitLayerAudienceIds.includes(audienceId),
        )
        .toList();
    }
    return toImmutable([]);
  },
];

/**
 * All added experiments of a specific status (those experiments that are saved in the layer but not in the live commit) for the current campaign
 * Currently used for when experiments are archived/unarchived and comparing with live commit layer state
 * @param  {String} status LayerExperiment.enums.status type  (e.g.active, archived)
 * @return  {Immutable.List}  LayerExperiment experiments of specified status type that are saved and not yet in commit
 * DEPRECATED, use experimentsAddedToLayerWithStatus instead
 */
exports.addedExperimentsToLayer = function(status) {
  return [
    exports.experiments,
    exports.liveCommitLayerExperiments,
    function(savedExperiments, liveCommitExperiments) {
      return LayerExperiment.fns.getAddedExperimentsByStatus(
        savedExperiments,
        liveCommitExperiments,
        status,
      );
    },
  ];
};

/**
 * Returns a getter that provides experiments pointing to the current layer, and
 * with the argument status, that are saved, but do not exist in the current
 * live commit
 * @param  {String} status LayerExperiment.enums.status type  (e.g.active, archived)
 * @return {Array}
 */
exports.experimentsAddedToLayerWithStatus = status => [
  exports.allExperimentsPointingToLayer,
  exports.liveCommitLayerExperiments,
  (savedExperiments, liveCommitExperiments) =>
    LayerExperiment.fns.getAddedExperimentsByStatus(
      savedExperiments,
      liveCommitExperiments,
      status,
    ),
];

/**
 * All removed audiences (those audienceIds that in the live commit but are not saved in the layer) for the current campaign
 * @param  {Layer} layer
 * @param  {LiveCommit} liveCommit
 * DEPRECATED, use removedAudiencesFromAllExperimentsPointingToLayer instead
 */
exports.removedAudiences = [
  exports.orderedAudienceIds,
  exports.liveCommitLayerAudienceIds,
  function(orderedAudienceIds, liveCommitLayerAudienceIds) {
    if (liveCommitLayerAudienceIds.size) {
      return liveCommitLayerAudienceIds
        .filterNot(audienceId => orderedAudienceIds.includes(audienceId))
        .toList();
    }
    return toImmutable([]);
  },
];

/**
 * Boolean check to see if the current experiment layer has the same audience conditions
 * set as the live commit
 */
exports.hasChangedAudienceConditions = [
  exports.liveCommitLayerExperiments,
  LayerExperiment.getters.entityCache,
  function(liveCommitExperiments, experimentMap) {
    let changedExperiment;
    if (liveCommitExperiments) {
      changedExperiment = liveCommitExperiments.find(liveExperiment => {
        if (liveExperiment.get('archived')) {
          return false;
        }
        const currentExperiment = experimentMap.get(liveExperiment.get('id'));
        if (
          currentExperiment &&
          fns.experimentHasUnpublishedAudienceChanges(
            currentExperiment,
            liveExperiment,
          )
        ) {
          return true;
        }
      });
    }
    return !!changedExperiment;
  },
];

/**
 * Indicates if the the working copy layer has identical audiences as the live commit, but in a different order.
 * @param {Layer} layer
 * @param {LiveCommit} liveCommit
 * @param {Immutable.List} addedAudiences
 * @param {Immutable.List} removedAudiences
 * DEPRECATED, please don't use this because it depends on Layer experiment_ids
 * - The idea of 'audience order' only makes sense in legacy p13n. In non-legacy
 *   p13n, audiences are many-to-many with experiments.
 * - We are trying to move towards using decision_metadata experiment_priorities
 *   as the sole representation of experiment ordering
 */
exports.hasReorderedAudiences = [
  exports.layer,
  exports.liveCommitLayer,
  exports.addedAudiences,
  exports.removedAudiences,
  function(layer, liveCommitLayer, addedAudiences, removedAudiences) {
    if (liveCommitLayer) {
      return (
        addedAudiences.size === 0 &&
        removedAudiences.size === 0 &&
        !Immutable.is(
          liveCommitLayer.get('experiment_ids'),
          layer.get('experiment_ids'),
        )
      );
    }
    return false;
  },
];

exports.hasDecisionMetadataChanges = [
  exports.layer,
  exports.liveCommitLayer,
  function(layer, liveCommitLayer) {
    if (liveCommitLayer) {
      return !Immutable.is(
        liveCommitLayer.get('decision_metadata'),
        layer.get('decision_metadata'),
      );
    }
    return false;
  },
];

/**
 * Indicates if the the layer holdback has been updated but not published.
 * @param  {Layer} layer
 * @param  {LiveCommit} liveCommit
 */
exports.hasDraftHoldbackSetting = [
  exports.layer,
  exports.liveCommitLayer,
  function(layer, liveCommitLayer) {
    if (liveCommitLayer) {
      return !Immutable.is(
        liveCommitLayer.get('holdback'),
        layer.get('holdback'),
      );
    }
    return false;
  },
];

/**
 * Indicates if the the layer_experiment holdback or variations have been updated but not published.
 * DEPRECATED, use a combination of hasTrafficAllocationToPublish and hasDraftHoldbackSetting instead
 * @returns {Boolean}
 */
exports.hasDraftExperimentOrTrafficAllocationToPublish = [
  exports.experiments,
  exports.liveCommitLayerExperiments,
  exports.hasDraftHoldbackSetting,
  function(experiments, liveCommitExperiments, hasDraftHoldbackSetting) {
    let hasChanged = false;
    let liveCommitExperiment = null;

    experiments.forEach(experiment => {
      if (liveCommitExperiments) {
        liveCommitExperiment = liveCommitExperiments.find(
          liveExperiment => liveExperiment.get('id') === experiment.get('id'),
        );
      }
      if (liveCommitExperiment) {
        const currentVariationWeights =
          experiment
            .get('variations')
            .map(variation => variation.get('weight')) || toImmutable([]);
        const liveVariationWeights =
          liveCommitExperiment
            .get('variations')
            .map(variation => variation.get('weight')) || toImmutable([]);
        // Need to add hasChanged into the OR conditions, in case hasChanged is true. We don't want it to keep
        // overwriting itself.
        hasChanged =
          hasChanged ||
          hasDraftHoldbackSetting ||
          !Immutable.is(liveVariationWeights, currentVariationWeights);
      }
    });
    return hasChanged;
  },
];

/**
 * Provides a boolean representing whether there is a traffic allocation change to publish
 * (i.e. when what's represented in the live commit, traffic-wise, is out of date).
 * Will return true in the following cases:
 * 1. The variation weights of the experiment (or sections, if MVT) differ from those of the
 *    live commit copy. This can be the result of adding/removing a section, adding/removing a section
 *    variation, adding/removing a variation, or changing the traffic among the variations.
 * 2. If MVT, the multivariate_traffic_policy has changed.
 *
 * Note that the source of truth for unpublished traffic allocation changes is usually the LayerExperiment;
 * the exception is for multivariate layers, where we also look at the ExperimentSection.
 */
exports.hasTrafficAllocationToPublish = [
  exports.layer,
  exports.allExperimentsPointingToLayer,
  exports.liveCommitLayerExperiments,
  exports.allSectionsPointingToLayer,
  exports.liveCommitExperimentSections,
  (layer, experiments, liveCommitExperiments, sections, liveCommitSections) => {
    const isMultivariateTestLayer = LayerFns.isMultivariateTestLayer(layer);
    if (isMultivariateTestLayer) {
      const hasMultivariateTrafficPolicyChange =
        !!liveCommitExperiments.size && // make sure there's an exp in the live commit first
        liveCommitExperiments.getIn([0, 'multivariate_traffic_policy']) !==
          experiments.getIn([0, 'multivariate_traffic_policy']);
      if (hasMultivariateTrafficPolicyChange) {
        return true; // short circuit if the multivariate traffic policy has been updated.
      }
    }
    const activeExperiments = experiments.filter(
      experiment => experiment.get('status') !== 'archived',
    );
    const experimentHasTrafficChanges = fns.destinationLayerExperimentHasTrafficChanges(
      activeExperiments,
      liveCommitExperiments,
    );
    const sectionsHaveTrafficChanges = fns.sectionsHaveTrafficChanges(
      sections,
      liveCommitSections,
    );
    return isMultivariateTestLayer
      ? experimentHasTrafficChanges || sectionsHaveTrafficChanges
      : experimentHasTrafficChanges;
  },
];

/**
 * Indicates if the the layer custom code has been updated but not published.
 * @param  {Immutable.List} layerCustomCodeChange
 * @param  {Immutable.List} layerCustomCSSChange
 * @param  {Immutable.List} liveCommitLayerCustomCodeChange
 * @param  {Immutable.List} liveCommitLayerCustomCSSChange
 * @return {Boolean}
 */
exports.hasCampaignCustomCodeChanges = [
  exports.layerCustomCodeChange,
  exports.layerCustomCSSChange,
  exports.liveCommitLayerCustomCodeChange,
  exports.liveCommitLayerCustomCSSChange,
  function(layerJs, layerCss, liveLayerJs, liveLayerCss) {
    return !(
      Immutable.is(layerJs, liveLayerJs) && Immutable.is(layerCss, liveLayerCss)
    );
  },
];

exports.views = [
  exports.layer,
  ViewGetters.entityCache,
  /**
   * @param {Immutable.List} viewIds
   * @param {Immutable.Map} viewMap
   * @return {Immutable.List}
   */
  function(layer, viewMap) {
    if (!layer || !layer.get('view_ids') || !viewMap) {
      return toImmutable([]);
    }

    return layer
      .get('view_ids')
      .map(id => viewMap.get(id))
      .filter(i => i);
  },
];

exports.excludedViews = [
  exports.views,
  CurrentProjectGetters.views,
  /**
   * @param {Immutable.List} views
   * @param {Immutable.Map} viewMap
   */
  function(includedViews, allViews) {
    // take all the current project views and filter out
    // all the views already associated with this experience
    return allViews.filter(
      view => !includedViews.find(v => v.get('id') === view.get('id')),
    );
  },
];

/**
 * Returns the first view from list of current views,
 * which is treated as the holdback view
 */
exports.holdbackView = [
  exports.views,
  function(currentViews) {
    return currentViews.first();
  },
];

/**
 * The first item in metrics list is the primary metric.
 * This object is the complete metric unlike that which is returned by layerPrimaryMetric
 */
exports.layerPrimaryMetricFromList = [
  exports.layer,
  function(layer) {
    if (!layer || !layer.get('metrics') || layer.get('metrics').size === 0) {
      return null;
    }
    return layer.getIn(['metrics', 0]);
  },
];

/**
 * Get a list of unique events/views associated with metrics for the current layer
 */
exports.metricEvents = [
  exports.layer,
  EventGetters.entityCache,
  ViewGetters.entityCache,
  (layer, events, views) =>
    Immutable.OrderedSet(
      layer.get('metrics').map(metric => {
        const eventId = metric.get('event_id');

        if (!eventId && !metric.get('field')) {
          return Immutable.Map();
        }

        if (!eventId && metric.get('field')) {
          return toImmutable({
            id: null,
            api_name: null,
            name: MetricFns.getMetricGlobalName(metric),
          });
        }

        const event = events.get(eventId) || views.get(eventId);

        return toImmutable({
          id: event.get('id'),
          api_name: event.get('api_name'),
          name: MetricFns.getMetricEventName(metric, events, views),
        });
      }),
    ).toList(),
];

/**
 * List of all events that are within the current layer's metrics
 */
exports.layerEvents = [
  exports.layer,
  EventGetters.entityCache,
  (layer, events) => {
    if (!layer) {
      return toImmutable([]);
    }
    return events.filter(
      event =>
        layer.get('metrics') &&
        layer
          .get('metrics')
          .find(metric => metric.get('event_id') === event.get('id')),
    );
  },
];

exports.hasLayerMetrics = [
  exports.layer,
  currentLayer =>
    currentLayer &&
    currentLayer.get('metrics') &&
    currentLayer.get('metrics').size,
];

/**
 * Boolean check to see if the current experiment layer has the same event_ids saved in the metrics array
 */

/**
 * Checks for any changes to unique event_ids that have been saved in Metrics
 * @param  {Layer} layer
 * @param  {LiveCommit} liveCommit
 */
exports.hasChangedEventIDs = [
  exports.layer,
  exports.liveCommitLayer,
  function(layer, liveCommitLayer) {
    let hasChangedEventIDs = false;

    if (liveCommitLayer) {
      const layerUniqueEventIDs =
        layer.get('metrics') &&
        layer
          .get('metrics')
          .map(metric => metric.get('event_id'))
          .toSet()
          .sort();
      const liveCommitLayerUniqueEventIDs =
        liveCommitLayer.get('metrics') &&
        liveCommitLayer
          .get('metrics')
          .map(metric => metric.get('event_id'))
          .toSet()
          .sort();
      hasChangedEventIDs = !Immutable.is(
        layerUniqueEventIDs,
        liveCommitLayerUniqueEventIDs,
      );
    }

    return hasChangedEventIDs;
  },
];

/**
 * All currently saved changes (including those modified or deleted in the working copy) for the current campaign
 * DEPRECATED, use savedChangesFromAllExperiments
 */
exports.currentCampaignSavedChanges = [
  exports.experiments,
  fns.savedChangesForCampaign,
];

exports.savedChangesFromAllExperiments = [
  exports.allExperimentsPointingToLayer,
  fns.savedChangesForCampaign,
];

exports.savedChangesFromAllSections = [
  exports.allSectionsPointingToLayer,
  fns.savedChangesForCampaign,
];

/**
 * All currently live changes (including those modified or deleted in the working copy) for the current campaign
 */
exports.currentCampaignLiveChanges = [
  exports.layer,
  exports.liveCommit,
  fns.liveChangesForCampaign,
];

/**
 * All currently live changes for experiments for the current campaign by experiment status(live commit only)
 */
exports.currentCampaignLiveChangesByExperimentStatus = function(status) {
  return [
    exports.layer,
    exports.liveCommit,
    LayerExperiment.getters.entityCache,
    function(layer, liveCommit, experimentsMap) {
      return fns.liveChangesForCampaign(
        layer,
        liveCommit,
        experimentsMap,
        status,
      );
    },
  ];
};

/**
 * All new (working-copy-only) attribute changes for the current campaign
 * DEPRECATED, use newChangesFromAllExperiments instead
 */
exports.currentCampaignNewChanges = [
  exports.currentCampaignSavedChanges,
  exports.currentCampaignLiveChanges,
  fns.newChangesForChangeList,
];

exports.newChangesFromAllExperiments = [
  exports.savedChangesFromAllExperiments,
  exports.currentCampaignLiveChanges,
  fns.newChangesForChangeList,
];

exports.newChangesFromAllSections = [
  exports.savedChangesFromAllSections,
  exports.currentCampaignLiveChanges,
  fns.newChangesForChangeList,
];

/**
 * All modified (workingCopy and liveCommit changes with same ID do not match) attribute changes for the current campaign
 * DEPRECATED, use modifiedChangesFromAllExperiments instead
 */
exports.currentCampaignModifiedChanges = [
  exports.currentCampaignSavedChanges,
  exports.currentCampaignLiveChanges,
  fns.modifiedChangesForChangeList,
];

exports.modifiedChangesFromAllExperiments = [
  exports.savedChangesFromAllExperiments,
  exports.currentCampaignLiveChanges,
  fns.modifiedChangesForChangeList,
];

exports.modifiedChangesFromAllSections = [
  exports.savedChangesFromAllSections,
  exports.currentCampaignLiveChanges,
  fns.modifiedChangesForChangeList,
];

/**
 * All deleted (liveCommit-only) attribute changes for the current campaign
 * DEPRECATED, use deletedChangesFromAllExperiments instead
 */
exports.currentCampaignDeletedChanges = [
  exports.currentCampaignSavedChanges,
  exports.currentCampaignLiveChanges,
  fns.deletedChangesForChangeList,
];

exports.deletedChangesFromAllExperiments = [
  exports.savedChangesFromAllExperiments,
  exports.currentCampaignLiveChanges,
  fns.deletedChangesForChangeList,
];

exports.deletedChangesFromAllSections = [
  exports.savedChangesFromAllSections,
  exports.currentCampaignLiveChanges,
  fns.deletedChangesForChangeList,
];

/**
 * All new, modified, and deleted changes (referred to in the UI as draft) for the current campaign.
 * DEPRECATED, used draftChangesFromAllExperiments instead
 */
exports.currentCampaignDraftChanges = [
  exports.currentCampaignNewChanges,
  exports.currentCampaignModifiedChanges,
  exports.currentCampaignDeletedChanges,
  function(newChanges, modifiedChanges, deletedChanges) {
    return newChanges.concat(modifiedChanges).concat(deletedChanges);
  },
];

exports.draftChangesFromAllExperiments = [
  exports.newChangesFromAllExperiments,
  exports.modifiedChangesFromAllExperiments,
  exports.deletedChangesFromAllExperiments,
  (newChanges, modifiedChanges, deletedChanges) =>
    newChanges.concat(modifiedChanges, deletedChanges),
];

exports.draftChangesFromAllSections = [
  exports.newChangesFromAllSections,
  exports.modifiedChangesFromAllSections,
  exports.deletedChangesFromAllSections,
  (newChanges, modifiedChanges, deletedChanges) =>
    newChanges.concat(modifiedChanges, deletedChanges),
];

exports.draftChangesFromAllExperimentsOrSections = [
  exports.layer,
  exports.draftChangesFromAllExperiments,
  exports.draftChangesFromAllSections,
  (layer, draftChangesFromAllExperiments, draftChangesFromAllSections) => {
    let draftChanges = draftChangesFromAllExperiments;
    if (LayerFns.isMultivariateTestLayer(layer)) {
      /**
       * When a user is managing their MVT in the Multivariate Test Manager, it's possible to get
       * into a state where the ExperimentSection cache is empty, even though ExperimentSections
       * exist in the datastore.
       *
       * This happens when a user clicks on a deep link to the combinations page for an MVT, because
       * the app does not do a fetch of ExperimentSections.
       *
       * As a result, draftChangesFromAllSections may return an empty list. In this event, we can trust
       * that draftChangesFromAllExperiments will be an accurate representation of whether there are
       * changes, since LayerExperiments are computed from Sections at the API level.
       */
      draftChanges = draftChangesFromAllSections.size
        ? draftChangesFromAllSections
        : draftChangesFromAllExperiments;
    }
    return draftChanges;
  },
];

/**
 * Integration settings which have already been published for the current layer
 */
exports.liveCommitLayerIntegrationSettings = [
  exports.liveCommitLayer,
  function(liveCommitLayer) {
    if (liveCommitLayer) {
      return liveCommitLayer.get('integration_settings') || toImmutable([]);
    }

    return toImmutable([]);
  },
];

/**
 * Integration settings stored on the layer model currently (which may not be the same as published)
 */
exports.currentIntegrationSettings = [
  exports.layer,
  function(currentLayer) {
    if (currentLayer) {
      return currentLayer.get('integration_settings') || toImmutable([]);
    }

    return toImmutable([]);
  },
];

/**
 * Indicates whether or not integration settings have changed since the last publish
 */
exports.haveIntegrationSettingsChanged = [
  exports.liveCommitLayerIntegrationSettings,
  exports.currentIntegrationSettings,
  function(publishedSettings, currentSettings) {
    if (
      JSSDKLabActions.isFeatureEnabled('have_integration_settings_changed') &&
      !JSSDKLabActions.getFeatureVariableBoolean(
        'have_integration_settings_changed',
        'integrations_array_should_include_disabled_ones',
      )
    ) {
      const excludeDisabledIntegration = integrations =>
        integrations.filter(i => i.enabled);
      return !_.isEqual(
        publishedSettings.toJS(),
        excludeDisabledIntegration(currentSettings).toJS(),
      );
    }

    return !_.isEqual(publishedSettings.toJS(), currentSettings.toJS());
  },
];

/**
 * Whether or not there are any draft changes to the current campaign which would require a publish.
 *  I.e. Changes, added/removed audiences, added/removed pages, updated settings.
 *  DEPRECATED, use hasUpdatesToPublish instead
 * @return {Boolean}
 */
exports.currentCampaignHasUpdatesToPublish = [
  exports.hasCampaignCustomCodeChanges,
  exports.haveIntegrationSettingsChanged,
  exports.currentCampaignDraftChanges,
  exports.addedAudiences,
  exports.removedAudiences,
  exports.hasChangedAudienceConditions,
  exports.addedViews,
  exports.removedViews,
  exports.hasDraftHoldbackSetting,
  exports.hasReorderedAudiences,
  exports.hasDecisionMetadataChanges,
  exports.addedExperimentsToLayer(LayerExperiment.enums.status.ARCHIVED),
  exports.addedExperimentsToLayer(LayerExperiment.enums.status.ACTIVE),
  exports.hasDraftExperimentOrTrafficAllocationToPublish,
  function(
    hasDraftCampaignCustomCodeChange,
    haveIntegrationSettingsChanged,
    draftVariationChanges,
    addedAudiences,
    removedAudiences,
    hasChangedAudienceConditions,
    addedViews,
    removedViews,
    hasDraftHoldbackSetting,
    hasReorderedAudiences,
    hasDecisionMetadataChanges,
    addedArchivedExperiments,
    addedActiveExperiments,
    hasDraftExperimentOrTrafficAllocationToPublish,
  ) {
    return (
      hasDraftCampaignCustomCodeChange ||
      haveIntegrationSettingsChanged ||
      !!draftVariationChanges.size ||
      !!addedAudiences.size ||
      !!removedAudiences.size ||
      !!addedViews.size ||
      !!removedViews.size ||
      hasChangedAudienceConditions ||
      hasDraftHoldbackSetting ||
      hasReorderedAudiences ||
      hasDecisionMetadataChanges ||
      !!addedArchivedExperiments.size ||
      !!addedActiveExperiments.size ||
      hasDraftExperimentOrTrafficAllocationToPublish
    );
  },
];

/**
 * Return the action (ie. the full changes list) based on given view/variation ids.
 * @return {Action}
 * DEPRECATED, use p13nActionChanges instead
 */
exports.actionChanges = function(viewId, variationId) {
  return [
    exports.experiments,
    function(experiments) {
      return fns.changesForAction(experiments, viewId, variationId);
    },
  ];
};

/**
 * All currently live changes (including those modified or deleted in the working copy) for the given action
 * @param  {Number} viewId
 * @param  {Number} variationId
 */
exports.liveChanges = function(viewId, variationId) {
  return [
    exports.liveCommit,
    function(liveCommit) {
      return fns.liveChangesForAction(liveCommit, viewId, variationId);
    },
  ];
};

/**
 * All new (working-copy-only) attribute changes for the given action
 * @param  {number} viewId
 * @param  {number} variationId
 * DEPRECATED, use p13nNewChanges instead
 */
exports.newChanges = function(viewId, variationId) {
  return [
    exports.actionChanges(viewId, variationId),
    exports.liveChanges(viewId, variationId),
    fns.newChangesForChangeList,
  ];
};

/**
 * All modified (workingCopy and liveCommit changes with same ID do not match) attribute changes for the given action
 * @param  {number} viewId
 * @param  {number} variationId
 */
exports.modifiedChanges = function(viewId, variationId) {
  return [
    exports.actionChanges(viewId, variationId),
    exports.liveChanges(viewId, variationId),
    fns.modifiedChangesForChangeList,
  ];
};

/**
 * All deleted (liveCommit-only) attribute changes for the given action
 * @param  {number} viewId
 * @param  {number} variationId
 */
exports.deletedChanges = function(viewId, variationId) {
  return [
    exports.actionChanges(viewId, variationId),
    exports.liveChanges(viewId, variationId),
    fns.deletedChangesForChangeList,
  ];
};

/**
 */
exports.draftChanges = function(viewId, variationId) {
  return [
    exports.newChanges(viewId, variationId),
    exports.modifiedChanges(viewId, variationId),
    exports.deletedChanges(viewId, variationId),
    function(newChanges, modifiedChanges, deletedChanges) {
      return newChanges.concat(modifiedChanges).concat(deletedChanges);
    },
  ];
};

/**
 * Return the action (ie. the full changes list) based on given view/variation ids.
 * @return {Action}
 */
exports.p13nActionChanges = function(viewId, variationId) {
  return [
    exports.allExperimentsOrSectionsPointingToLayer,
    function(experiments) {
      return fns.changesForAction(experiments, viewId, variationId);
    },
  ];
};

/**
 * Return the action (ie. the full changes list) based on given view/variation ids.
 * @return {Action}
 */
exports.changesForVariation = function(variationId) {
  return [
    exports.experiments,
    function(experiments) {
      return fns.changesForVariation(experiments, variationId);
    },
  ];
};

/**
 * All currently live changes (including those modified or deleted in the working copy) for the given variation
 * @param {Number} variationId
 */
exports.liveChangesForVariation = function(variationId) {
  return [
    exports.liveCommit,
    function(liveCommit) {
      return fns.liveChangesForVariation(liveCommit, variationId);
    },
  ];
};

/**
 * All new (working-copy-only) attribute changes for the given variation
 * @param {number} variationId
 */
exports.newChangesForVariation = function(variationId) {
  return [
    exports.changesForVariation(variationId),
    exports.liveChangesForVariation(variationId),
    fns.newChangesForChangeList,
  ];
};

/**
 * All modified (workingCopy and liveCommit changes with same ID do not match) attribute changes for the given variation
 * @param {number} variationId
 */
exports.modifiedChangesForVariation = function(variationId) {
  return [
    exports.changesForVariation(variationId),
    exports.liveChangesForVariation(variationId),
    fns.modifiedChangesForChangeList,
  ];
};

/**
 * All deleted (liveCommit-only) attribute changes for the given variation
 * @param {number} variationId
 */
exports.deletedChangesForVariation = function(variationId) {
  return [
    exports.changesForVariation(variationId),
    exports.liveChangesForVariation(variationId),
    fns.deletedChangesForChangeList,
  ];
};

/**
 * All draft changes for the given variation
 * @param {number} variationId
 */
exports.draftChangesForVariation = function(variationId) {
  return [
    exports.newChangesForVariation(variationId),
    exports.modifiedChangesForVariation(variationId),
    exports.deletedChangesForVariation(variationId),
    function(newChanges, modifiedChanges, deletedChanges) {
      return newChanges.concat(modifiedChanges).concat(deletedChanges);
    },
  ];
};

/**
 * Gets all the changes for the given view in the current layer
 * @param {String} viewId
 */
exports.currentLayerChangesForView = viewId => {
  const emptyList = Immutable.List();
  const emptyMap = Immutable.Map();
  return [
    exports.allExperimentsOrSectionsPointingToLayer,
    experimentsOrSections =>
      experimentsOrSections.flatMap(experimentOrSection => {
        if (
          experimentOrSection.get('status') ===
          LayerExperiment.enums.status.ARCHIVED
        ) {
          return emptyList;
        }
        return experimentOrSection.get('variations').flatMap(variation =>
          variation
            .get('actions')
            .find(action => action.get('view_id') === viewId, null, emptyMap)
            .get('changes', emptyList),
        );
      }),
  ];
};

/**
 * Return the action attribute change list based on given view/variation ids.
 * @return {Action}
 */
exports.actionAttributeChanges = function(viewId, variationId) {
  return [
    exports.experiments,
    function(experiments) {
      const actionChanges = fns.changesForAction(
        experiments,
        viewId,
        variationId,
      );
      return actionChanges.filter(
        change =>
          change.get('type') === LayerExperiment.enums.ChangeTypes.ATTRIBUTE,
      );
    },
  ];
};

/**
 * Return the layer experiment currently being edited based on the selected variation id.
 * @return {Action}
 */
exports.layerExperimentForVariation = function(variationId) {
  return [
    exports.experiments,
    function(experiments) {
      return experiments.find(experiment =>
        experiment
          .get('variations')
          .find(variation => variation.get('variation_id') === variationId),
      );
    },
  ];
};

/**
 * @typedef {Immutable.Map} Field
 * @property {String} id
 * @property {String} label
 */

/**
 * @typedef {Immutable.Map} Field
 * @property {String} label
 * @property {String} name
 * @property {String} inputType
 * @property {Immutable.List of Value} values
 * @property {Boolean} required
 */

/**
 * @typedef {Immutable.Map} Integration
 * @property {String} id
 * @property {String} masterLabel
 * @property {Immutable.Map} layerLevelData
 * @property {Immutable.Map} layerLevelData.settings
 * @property {Boolean} layerLevelData.enabled
 * @property {Immutable.List of Field} layerLevelData.fields
 * @property {String} layerLevelData.generalHelp.message
 */

/**
 * Return the integrations which are currently available for use for this layer
 * @returns Immutable.List of Integration
 */
exports.integrations = [
  CurrentProjectGetters.enabledCampaignLevelPersonalizationIntegrations,
  CurrentProjectGetters.enabledAnalyticsIntegrationExtensions,
  exports.layer,
  function(
    enabledCampaignLevelPersonalizationIntegrations,
    enabledAnalyticsIntegrationExtensions,
    currentLayer,
  ) {
    const integrationLayerSettings = currentLayer.get(
      'integration_settings',
      [],
    );
    const integrationIdToLayerSettingsMap = {};

    // Prepare integration settings map for quick lookup
    integrationLayerSettings.forEach(integrationSetting => {
      integrationIdToLayerSettingsMap[
        integrationSetting.get('integration_id')
      ] = integrationSetting;
    });
    enabledCampaignLevelPersonalizationIntegrations = enabledCampaignLevelPersonalizationIntegrations.map(
      integration => {
        if (
          Object.prototype.hasOwnProperty.call(
            integrationIdToLayerSettingsMap,
            integration.get('id'),
          )
        ) {
          integration = integration.setIn(
            ['layerLevelData', 'settings'],
            toImmutable(
              integrationIdToLayerSettingsMap[integration.get('id')].get(
                'settings',
              ),
            ),
          );
          integration = integration.setIn(
            ['layerLevelData', 'enabled'],
            toImmutable(
              integrationIdToLayerSettingsMap[integration.get('id')].get(
                'enabled',
              ),
            ),
          );
        } else {
          integration = integration.setIn(
            ['layerLevelData', 'enabled'],
            toImmutable(false),
          );
        }

        return integration;
      },
    );

    const formattedAnalyticsExtensions = fns.formatAnalyticsExtensionsToIntegrationsUI(
      enabledAnalyticsIntegrationExtensions,
      integrationIdToLayerSettingsMap,
    );

    return enabledCampaignLevelPersonalizationIntegrations.concat(
      formattedAnalyticsExtensions,
    );
  },
];

exports.isPersonalizationLayer = [
  exports.layer,
  layer => LayerFns.isPersonalizationLayer(layer),
];

exports.isMultivariateTestLayer = [
  exports.layer,
  layer => LayerFns.isMultivariateTestLayer(layer),
];

exports.formattedHoldback = [
  exports.layer,
  currentLayer => {
    if (currentLayer) {
      return Math.round(currentLayer.get('holdback')) / 100;
    }
    return 0;
  },
];

exports.resultsAPIToken = [
  exports.layer,
  currentLayer => {
    if (currentLayer) {
      return currentLayer.get('layer_results_api_token');
    }
  },
];

exports.earliestDate = [
  exports.layer,
  currentLayer => {
    if (currentLayer) {
      return currentLayer.get('earliest')
        ? moment(currentLayer.get('earliest')).format()
        : null;
    }

    return null;
  },
];

exports.campaignExperimentsByLayerId = function(layerId) {
  return [
    LayerExperiment.getters.entityCache,
    entityMap =>
      entityMap
        .filter(entity => layerId && entity.get('layer_id') === layerId)
        .toList(),
  ];
};

/**
 * All layer experiments (experiences) on layer (p13n campaign)
 */
exports.currentLayerCampaignExperiments = [
  exports.id,
  LayerExperiment.getters.entityCache,
  (currentLayerId, entityMap) =>
    entityMap
      .filter(
        entity => currentLayerId && entity.get('layer_id') === currentLayerId,
      )
      .toList(),
];

/**
 * The current layer experiment, to be used ONLY with a layer whose policy
 * is of type single experiment.
 * This includes AB Test Layers and Multivariate Test Layers.
 */
exports.currentSingleExperimentFromAllExperimentsPointingToLayer = [
  exports.allExperimentsPointingToLayer,
  allExperimentsPointingToLayer => allExperimentsPointingToLayer.get(0),
];

/**
 * Variations of the current layer AB experiment
 */
exports.currentLayerABExperimentVariations = [
  exports.currentSingleExperimentFromAllExperimentsPointingToLayer,
  currentExperiment => currentExperiment.get('variations'),
];

exports.variationById = function(id) {
  return [
    exports.allExperimentsOrSectionsPointingToLayer,
    _.partial(fns.getVariationById, id),
  ];
};

/**
 * Gets the traffic allocation policy for current layer experiment stored inside the entity cache
 */
exports.currentLayerABExperimentTrafficAllocationPolicy = [
  exports.currentSingleExperimentFromAllExperimentsPointingToLayer,
  LayerExperiment.getters.entityCache,
  (currentExperiment, experimentEntityCache) => {
    if (currentExperiment) {
      const experimentInEntityCache = experimentEntityCache.get(
        currentExperiment.get('id'),
      );
      if (experimentInEntityCache) {
        return experimentInEntityCache.get('allocation_policy');
      }
    }
    return 'manual';
  },
];

/**
 * Checks if all layer experiments on layer uses manual traffic allocation policy
 */
exports.currentLayerUsesManualTrafficAllocationPolicyOnly = [
  exports.currentLayerCampaignExperiments,
  allExperimentsOnLayer =>
    !allExperimentsOnLayer.find(
      experiment =>
        experiment.get('actual_status') !== 'archived' &&
        experiment.get('allocation_policy') !== 'manual',
    ),
];

/**
 * Getter to indicate if any of the campaign settings that are dirty that need a publish
 * Currently just has the holdback setting that requires a publish
 * NB: This getter should only be used with Personalization.
 * @returns {Boolean}
 */
exports.hasDirtySettingsToPublish = [
  exports.hasDraftHoldbackSetting,
  exports.isPersonalizationLayer,
  (holdBackSettingToPublish, isPersonalizationLayer) =>
    isPersonalizationLayer && holdBackSettingToPublish,
];

exports.audiencesFromAllExperimentsPointingToLayer = [
  exports.allExperimentsPointingToLayer,
  AudienceGetters.entityCache,
  /**
   * @param {Immutable.Map} experiments
   * @param {Immutable.Map} audiences
   * @return {Immutable.List}
   */
  (experiments, audiences) => {
    if (!experiments) {
      return Immutable.List([]);
    }

    // Get all audiences that are included in any of the current layer's experiments
    const audienceIds = experiments.reduce(
      (allAudienceIds, experiment) =>
        allAudienceIds.union(experiment.get('audience_ids')),
      Immutable.Set([]),
    );
    return audienceIds
      .flatMap(audienceId => {
        const audience = audiences.get(audienceId);
        if (audience) {
          return [audience];
        }
        return [];
      })
      .toList();
  },
];

/**
 * Return true if current layer has any adaptive audience that has `unavailable` adaptive_audience_status.
 * @param {Immutable.List} audiences - List of all audiences from all experiments pointing to current layer.
 * @return {Boolean}
 */
exports.hasAnyProcessingAdaptiveAudienceConditions = [
  exports.audiencesFromAllExperimentsPointingToLayer,
  audiences =>
    !!audiences.find(
      audience =>
        audience.get('adaptive_audience_status') ===
        AudienceEnums.AdaptiveAudienceStatuses.UNAVAILABLE,
    ),
];

/**
 * Provides a list of ids of all audiences in the audience_ids property of all
 * layer experiments pointing to the current layer
 */
exports.audienceIdsFromAllExperimentsPointingToLayer = [
  exports.audiencesFromAllExperimentsPointingToLayer,
  audiences => {
    if (!audiences) {
      return toImmutable([]);
    }
    return audiences.map(audience => audience.get('id'));
  },
];

/**
 * Provides the following:
 * When there is a live commit:
 * - a list of all ids that are present in the audience_ids of experiments in
 *   the current live commit's revisions, but which are not present in the
 *   audience_ids of any saved layer experiment entity pointing to the current
 *   layer
 * When there is no live commit:
 * - an empty list
 */
exports.removedAudiencesFromAllExperimentsPointingToLayer = [
  exports.audienceIdsFromAllExperimentsPointingToLayer,
  exports.liveCommitAudienceIdsFromExperiments,
  (savedAudienceIds, liveCommitAudienceIds) => {
    if (liveCommitAudienceIds.size) {
      return liveCommitAudienceIds
        .filterNot(audienceId => savedAudienceIds.includes(audienceId))
        .toList();
    }
    return toImmutable([]);
  },
];

/**
 * Provides the following:
 * When there is a live commit:
 * - a list of all ids that are present in the audience_ids of saved layer
 *   experiment entities pointing to the current layer, but which are not
 *   present in the audience_ids of any experiment in the current live commit's
 *   revisions
 * When there is no live commit:
 * - an empty list
 */
exports.addedAudiencesFromAllExperimentsPointingToLayer = [
  exports.audienceIdsFromAllExperimentsPointingToLayer,
  exports.liveCommitAudienceIdsFromExperiments,
  (savedAudienceIds, liveCommitAudienceIds) => {
    if (liveCommitAudienceIds.size) {
      return savedAudienceIds
        .filterNot(audienceId => liveCommitAudienceIds.includes(audienceId))
        .toList();
    }
    return toImmutable([]);
  },
];

/**
 * Provides a list of all variations in all experiments pointing to the current
 * layer
 */
exports.variationsFromAllExperimentsPointingToLayer = [
  exports.allExperimentsPointingToLayer,
  experiments =>
    experiments.flatMap(experiment => experiment.get('variations')),
];

/**
 * CurrentLayer groups
 */

/**
 * Gets the group ID of the group that the current layer is a part of.
 */
exports.layerSelectedGroupId = [
  ExperimentationGroupGetters.entityToGroupMap,
  CurrentProjectGetters.activeGroups,
  exports.id,
  ExperimentationGroupFns.getSelectedGroupIdForEntity,
];

/**
 * Our group_traffic_allocation component accepts an POJO entity, as per how Full Stack passes it in.
 * Full Stack entities in groups are LayerExperiments, and they have a percentage_included property on their model:
 * https://github.com/optimizely/optimizely/blob/8b6d423f318e53602ab35918f35748f3bb62546c/src/www/models/layer_experiments.py#L235
 *
 * We need to calculate an analogous percentage_included property based on the experiment's group membership.
 * Full Stack also extends its LayerExperiments with group_ids in the oasis_experiment_manager section_module:
 * https://github.com/optimizely/optimizely/blob/8b6d423f318e53602ab35918f35748f3bb62546c/src/www/frontend/src/js/bundles/p13n/sections/oasis_experiment_manager/section_module/getters.js#L74,
 * so we will likewise do that here.
 */
exports.layerWithGroupIdAndPercentageIncluded = [
  exports.layerSelectedGroupId,
  ExperimentationGroupGetters.entityCache,
  exports.layer,
  ExperimentationGroupFns.getCurrentSingleExperimentLayerWithGroupIdAndPercentageIncluded,
];

/**
 * Provides a boolean representing whether any experiment pointing to the
 * current layer, or the current layer itself, has changes that require a
 * publish to take effect
 */
exports.hasUpdatesToPublish = [
  exports.hasCampaignCustomCodeChanges,
  exports.haveIntegrationSettingsChanged,
  exports.draftChangesFromAllExperimentsOrSections,
  exports.addedAudiencesFromAllExperimentsPointingToLayer,
  exports.removedAudiencesFromAllExperimentsPointingToLayer,
  exports.hasChangedAudienceConditions,
  CurrentProjectGetters.hasPageTrimming,
  exports.hasChangedEventIDs,
  exports.addedViews,
  exports.removedViews,
  exports.hasDraftHoldbackSetting,
  exports.hasDecisionMetadataChanges,
  exports.experimentsAddedToLayerWithStatus(
    LayerExperiment.enums.status.ARCHIVED,
  ),
  exports.experimentsAddedToLayerWithStatus(
    LayerExperiment.enums.status.ACTIVE,
  ),
  exports.hasTrafficAllocationToPublish,
  (
    hasDraftCampaignCustomCodeChange,
    haveIntegrationSettingsChanged,
    draftVariationChanges,
    addedAudiences,
    removedAudiences,
    hasChangedAudienceConditions,
    hasPageTrimming,
    hasChangedEventIDs,
    addedViews,
    removedViews,
    hasDraftHoldbackSetting,
    hasDecisionMetadataChanges,
    addedArchivedExperiments,
    addedActiveExperiments,
    hasTrafficAllocationToPublish,
  ) =>
    hasDraftCampaignCustomCodeChange ||
    haveIntegrationSettingsChanged ||
    !!draftVariationChanges.size ||
    !!addedAudiences.size ||
    !!removedAudiences.size ||
    !!addedViews.size ||
    !!removedViews.size ||
    hasChangedAudienceConditions ||
    (hasPageTrimming && hasChangedEventIDs) || // If pageTrimming is enabled AND unique event_ids have changes, user may want to republish!
    hasDraftHoldbackSetting ||
    hasDecisionMetadataChanges ||
    !!addedArchivedExperiments.size ||
    !!addedActiveExperiments.size ||
    hasTrafficAllocationToPublish,
];

exports.experimentsMetrics = [
  exports.allExperimentsPointingToLayer,
  layerExperiments => {
    const experiments = layerExperiments.toJS();
    const experimentsMetrics = experiments.flatMap(
      experiment => experiment.metrics || [],
    );

    return toImmutable(experimentsMetrics);
  },
];

exports.currentLayerMetrics = [
  exports.layer,
  exports.experimentsMetrics,
  CurrentProjectGetters.isFlagsProject,
  (layer, experimentsMetrics, isFlagsProject) => {
    if (!layer) {
      return toImmutable([]);
    }

    const isCompoundMetricsEnabled = JSSDKLabActions.isFeatureEnabled(
      'compound_metrics',
    );

    const getFromExperiments =
      isCompoundMetricsEnabled &&
      isFlagsProject &&
      experimentsMetrics.toJS().find(metric => metric.metrics?.length);

    return getFromExperiments
      ? experimentsMetrics
      : layer.get('metrics', toImmutable([]));
  },
];

exports.currentLayerMetricWrappers = [
  exports.currentLayerMetrics,
  exports.layer,
  ViewGetters.entityCache,
  EventGetters.entityCache,
  MetricFns.createMetricWrappers,
];

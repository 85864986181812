import { enums as ViewEnums } from 'optly/modules/entity/view';

/**
 * Dict of activation type options available for Views.
 */
export const DEFAULT_ACTIVATION_TYPE_OPTIONS = {
  [ViewEnums.activationModes.IMMEDIATE]: {
    label: tr('Immediately'),
    description: tr('Check conditions as soon as the page is loaded.'),
    value: ViewEnums.activationModes.IMMEDIATE,
  },
  [ViewEnums.activationModes.URL_CHANGED]: {
    label: tr('When the URL changes'),
    description: tr("Triggered when the URL in the visitor's browser changes."),
    value: ViewEnums.activationModes.URL_CHANGED,
    linkNewWindow: true,
    linkText: 'Requires Dynamic Website support',
    linkURL:
      'https://help.optimizely.com/Build_Campaigns_and_Experiments/Support_for_Dynamic_Websites%3A_Use_Optimizely_on_Single_Page_Applications_(beta)',
  },
  [ViewEnums.activationModes.CALLBACK]: {
    label: tr('When a callback is called'),
    description: tr(
      'A javascript function you can call to activate the page when needed.',
    ),
    value: ViewEnums.activationModes.CALLBACK,
  },
  [ViewEnums.activationModes.DOM_CHANGED]: {
    label: tr('When the DOM changes'),
    description: tr('Triggered when any element on the page changes.'),
    value: ViewEnums.activationModes.DOM_CHANGED,
    linkNewWindow: true,
    linkText: 'Requires Dynamic Website support',
    linkURL:
      'https://help.optimizely.com/Build_Campaigns_and_Experiments/Support_for_Dynamic_Websites%3A_Use_Optimizely_on_Single_Page_Applications_(beta)',
  },
  [ViewEnums.activationModes.MANUAL]: {
    label: tr('Manually'),
    description: tr('Manually trigger a page to check conditions.'),
    value: ViewEnums.activationModes.MANUAL,
  },
  [ViewEnums.activationModes.POLLING]: {
    label: tr('When a javascript condition is true'),
    description: tr(
      'Polling. Wait until a condition is met to check for true conditions, polling every 50ms. Polling functions in the client timeout 2 seconds after DOM ready.',
    ),
    value: ViewEnums.activationModes.POLLING,
  },
};

/**
 * Ordered list of activation types to display in the View UI
 */
export const ORDERED_DEFAULT_ACTIVATION_TYPE_OPTIONS = [
  DEFAULT_ACTIVATION_TYPE_OPTIONS[ViewEnums.activationModes.IMMEDIATE],
  DEFAULT_ACTIVATION_TYPE_OPTIONS[ViewEnums.activationModes.URL_CHANGED],
  DEFAULT_ACTIVATION_TYPE_OPTIONS[ViewEnums.activationModes.DOM_CHANGED],
  DEFAULT_ACTIVATION_TYPE_OPTIONS[ViewEnums.activationModes.CALLBACK],
  DEFAULT_ACTIVATION_TYPE_OPTIONS[ViewEnums.activationModes.MANUAL],
  DEFAULT_ACTIVATION_TYPE_OPTIONS[ViewEnums.activationModes.POLLING],
];

export const DEFAULT_CODE = {
  [ViewEnums.activationModes.CALLBACK]: `/**
 * Sample Activation Function
 * For complete documentation, see https://docs.developers.optimizely.com/web/docs/dynamic-websites#section-callback
 * @param {Function} activate - Call this function when you want to activate the page.
 * @param {Object} options - An object containing Page information.
 */

function callbackFn(activate, options) {
  document.querySelector('button').addEventListener('click', activate);
}`,
  [ViewEnums.activationModes.POLLING]: `/**
 * Sample Polling Function
 * Supply an expression to return a boolean inside a function.
 * For complete documentation, see https://docs.developers.optimizely.com/web/docs/dynamic-websites#section-polling
 */

function pollingFn() {
  return window.globalVarToWaitFor === 'READY';
}`,
};

export const ROOT_TYPE_OPTIONS = [
  {
    label: tr('all'),
    description: tr('All conditions must be true for the page to activate.'),
    value: ViewEnums.conditionGroupTypes.AND,
  },
  {
    label: tr('any'),
    description: tr(
      'Any condition being true will cause the page to activate.',
    ),
    value: ViewEnums.conditionGroupTypes.OR,
  },
];

export default {
  DEFAULT_ACTIVATION_TYPE_OPTIONS,
  ORDERED_DEFAULT_ACTIVATION_TYPE_OPTIONS,
  DEFAULT_CODE,
  ROOT_TYPE_OPTIONS,
};

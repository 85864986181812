import Immutable, { toImmutable } from 'optly/immutable';
import FeatureFns from 'optly/modules/entity/feature_flag/fns';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import LayerExperimentFns from 'optly/modules/entity/layer_experiment/fns';
import guid from 'optly/utils/guid';
import regex from 'optly/utils/regex';

import constants from './constants';

/**
 * Adds a new variation to the given list
 * @param {Immutable.List} variations
 * @param {Immutable.List} variables
 * @param {Object} config - additional properties to assign to the variation
 * @return {Immutable.List}
 */
export function addVariation(
  variations,
  variables = Immutable.List(),
  config = {},
) {
  let variableValuesMap = Immutable.Map();
  variables.forEach(variable => {
    variableValuesMap = variableValuesMap.set(
      variable.get('api_name'),
      variable.get('default_value'),
    );
  });

  const newVariation = toImmutable({
    guid: guid(),
    variable_values: variableValuesMap,
    ...config,
  });

  const variationsToUpdate = variations.push(newVariation);
  return LayerExperimentFns.redistributeWithNewVariationTrafficImmutable(
    variationsToUpdate,
  );
}

/**
 * Deletes the variation at the given index from the list
 * @param  {Integer} index
 * @param  {Immutable.List} variations
 * @return {Immutable.List}
 */
export function deleteVariation(index, variations) {
  const variationsToUpdate = variations.delete(index);
  return LayerExperimentFns.redistributeWithNewVariationTrafficImmutable(
    variationsToUpdate,
  );
}

/**
 * Pauses the variation at the given index and redistributes the traffic
 * @param  {Integer} index
 * @param  {Immutable.List} variations
 * @return {Immutable.List}
 */
export function pauseVariation(index, variations) {
  const variation = variations.get(index);
  const pausedVariationWeight = variation.get('weight');
  const updatedVariation = variation
    .set('status', LayerExperimentEnums.VariationStatus.PAUSED)
    .set('weight', 0);
  const updatedVariations = variations.set(index, updatedVariation);
  return LayerExperimentFns.redistributePausedVariationTraffic(
    updatedVariations,
    pausedVariationWeight,
  );
}

/**
 * Resumes the variation at the given index and redistributes the traffic
 * @param  {Integer} index
 * @param  {Immutable.List} variations
 * @return {Immutable.List}
 */
export function resumeVariation(index, variations) {
  const variation = variations.get(index);
  const updatedVariation = variation.set(
    'status',
    LayerExperimentEnums.VariationStatus.ACTIVE,
  );
  const updatedVariations = variations.set(index, updatedVariation);
  return LayerExperimentFns.redistributeResumedVariationTraffic(
    updatedVariation.get('variation_id'),
    updatedVariations,
  );
}

/**
 * Attach the variables from the feature to the experiment's variations.
 * @param  {Immutable.Map} feature
 * @param  {Immutable.Map} experiment
 * @return {Immutable.Map} The experiment with the variables attached
 */
export function attachFeatureVariablesToExperiment(feature, experiment) {
  // add the variables to each variation in the experiment
  const variationWithVariables = experiment.get('variations').map(variation => {
    let variableValueMap = Immutable.Map();
    feature.get('variables').forEach(variable => {
      const variableIsArchived = variable.get('archived');
      if (!variableIsArchived) {
        variableValueMap = variableValueMap.set(
          variable.get('api_name'),
          variable.get('default_value'),
        );
      }
    });
    return variation.set('variable_values', variableValueMap);
  });

  return experiment.set('variations', variationWithVariables);
}

/**
 * Ensures that the given experiment is unique among the specified experiments
 * @param  {Immutable.Map}  experiment
 * @param  {Immutable.List} projectExperiments
 * @return {Boolean}
 */
export function ensureExperimentKeyUnique(experiment, projectExperiments) {
  return !projectExperiments.some(
    exp =>
      exp.get('id') !== experiment.get('id') &&
      exp.get('key') === experiment.get('key'),
  );
}

export function ensureExperimentKeyValid(experimentKey) {
  return !!experimentKey && !!regex.apiName.test(experimentKey);
}

/**
 * Ensures the variable values inside the experiment's variations are valid.
 * @param  {Immutable.Map} experiment
 * @param features
 * @return {Boolean}
 */
export function ensureVariableValuesValid(experiment, features) {
  const featureId = experiment.get('feature_flag_id');

  // checking for features because sometimes features comes in as undefined
  if (featureId && features) {
    // Iterate through project features to find the feature corresponding experiment's feature ID
    const experimentFeature = features.find(
      feature => featureId === feature.get('id'),
    );
    // create a Map that links variable IDs to their types
    const featureMap = experimentFeature
      .get('variables')
      .filter(variable => !variable.get('archived'))
      .reduce(
        (map, variable) =>
          map.set(variable.get('api_name'), variable.get('type')),
        Immutable.Map(),
      );

    // iterate through every variation and check validity for each variable value within the variation to
    return experiment.get('variations').every(variation => {
      const variables = variation.get('variable_values');
      return variables.every((value, key) =>
        FeatureFns.isVariableValueInputValid(value, featureMap.get(key)),
      );
    });
  }
  return true;
}

/**
 * Given a list of variations that all exist in the same experiment,
 * return a boolean indicating whether each of them has a unique key.
 *
 * @param {Immutable.List} variations
 * @return {Boolean}
 */
export function ensureVariationKeysUnique(variations) {
  const seenNames = {};
  return variations.every(variation => {
    const key = variation.get('api_name');
    if (!key) {
      return true;
    }
    if (seenNames[key]) {
      return false;
    }
    seenNames[key] = true;
    return true;
  });
}

export function ensureVariationKeyValid(variationKey) {
  return !!variationKey && !!regex.apiName.test(variationKey);
}

/**
 * Makes sure that the weights of the given variations total 100
 * @param  {Immutable.List} variationList
 * @return {Boolean}
 */
export function ensureVariationWeightsTotal100(variationList) {
  const totalWeight = variationList.reduce(
    (weight, variation) => weight + variation.get('weight'),
    0,
  );
  return totalWeight === 10000;
}

export function getExperimentType(experiment) {
  if (LayerExperimentFns.isFeatureTest(experiment)) {
    return constants.ExperimentType.FEATURE_TEST;
  }
  if (LayerExperimentFns.isMultivariateTest(experiment)) {
    return constants.ExperimentType.MVT;
  }
  if (LayerExperimentFns.isMultiArmedBandit(experiment)) {
    return constants.ExperimentType.MULTIARMED_BANDIT;
  }
  return constants.ExperimentType.AB_TEST;
}

export function getExperimentTypeToLabel(experimentType) {
  return constants.ExperimentTypeToLabel[experimentType];
}

/**
 * Returns true if any variation in the experiment has negative weights
 * @param  {Immutable.List}  variations
 * @return {Boolean}
 */
export function hasNegativeVariationValues(variations) {
  return variations.some(variation => variation.get('weight') < 0);
}

/**
 * Updates the variation's variable values with the given variable
 * @param  {Immutable.Map} variation
 * @param  {Immutable.Map} variable
 * @return {Immutable.Map}
 */
export function updateVariationVariable(variation, variable) {
  let updatedVariation = variation;
  if (!updatedVariation.get('variable_values')) {
    updatedVariation = updatedVariation.set('variable_values', Immutable.Map());
  }
  updatedVariation = updatedVariation.setIn(
    ['variable_values', variable.get('api_name')],
    variable.get('value'),
  );
  return updatedVariation;
}

export default {
  addVariation,
  deleteVariation,
  pauseVariation,
  resumeVariation,
  attachFeatureVariablesToExperiment,
  ensureExperimentKeyUnique,
  ensureExperimentKeyValid,
  ensureVariableValuesValid,
  ensureVariationKeysUnique,
  ensureVariationKeyValid,
  ensureVariationWeightsTotal100,
  getExperimentType,
  getExperimentTypeToLabel,
  hasNegativeVariationValues,
  updateVariationVariable,
};

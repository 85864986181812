/**
 * Holds the state of the selected table items for the dashboard
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */

import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import _ from 'lodash';

import { actionTypes } from '../action_types';
import constants from '../constants';

/**
 * tableFiltersStore
 */
export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      tableFilters: constants.FILTER_DEFAULTS,
    });
  },

  initialize() {
    this.on(actionTypes.DASHBOARD_SET_TABLE_FILTERS, setFilters);
    this.on(actionTypes.DASHBOARD_RESET_TABLE_FILTERS, resetFilters);
  },
});

/**
 * Reset filter values to defaults for a particular category
 */
function resetFilters(state, payload) {
  const { category } = payload;
  return state.setIn(
    ['tableFilters', category],
    toImmutable(constants.FILTER_DEFAULTS[category]),
  );
}

/**
 * Sets filters for a category, will leave existing filters in
 * if the payload.filters doesnt overwrite
 */
function setFilters(state, payload) {
  const { category } = payload;

  const payloadFilters = validateFilters(category, payload.filters);
  let currentFilters = state.getIn(['tableFilters', category]);
  currentFilters = currentFilters ? currentFilters.toJS() : {};
  const newFilters = _.extend({}, currentFilters, payloadFilters);

  return state.setIn(['tableFilters', category], toImmutable(newFilters));
}

/**
 * Validates the filters based on the filter category
 * Use default filters if the supplied filter is not valid.
 * @private
 */
function validateFilters(category, filters) {
  switch (category) {
    case 'experiments':
      if (filters.statusString) {
        filters.status =
          constants.EXPERIMENT_STATUS_FILTERS[filters.statusString];
        // Check for invalid status strings
        if (!filters.status) {
          filters.statusString = constants.DEFAULT_EXPERIMENT_STATUS_FILTER;
          filters.status =
            constants.EXPERIMENT_STATUS_FILTERS[
              constants.DEFAULT_EXPERIMENT_STATUS_FILTER
            ];
        }
      }

      break;
    default:
      break;
  }
  return filters;
}

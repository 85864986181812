import React from 'react';
import PropTypes from 'prop-types';

import ui from 'core/ui';
import { connect } from 'core/ui/decorators';
import Immutable from 'optly/immutable';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import ExperimentUsageTable from 'bundles/p13n/components/experiment_usage_table';

import {
  actions as ComponentActions,
  fns as ComponentFns,
} from './component_module';

@connect({
  audiences: CurrentProjectGetters.xCompatibleAudiences,
  currentProjectId: CurrentProjectGetters.id,
  experimentCounts: CurrentProjectGetters.audienceExperimentCount,
  layers: CurrentProjectGetters.layers,
})
class AudienceExperimentUsage extends React.Component {
  static propTypes = {
    audiences: PropTypes.instanceOf(Immutable.List).isRequired,
    currentProjectId: PropTypes.number.isRequired,
    experimentCounts: PropTypes.instanceOf(Immutable.List).isRequired,
    layers: PropTypes.instanceOf(Immutable.List).isRequired,
    selectedAudienceId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    const { selectedAudienceId } = props;
    this.state = {
      currentAudienceId: selectedAudienceId,
      experimentsUsageAvailable: false,
    };
  }

  componentDidMount() {
    const { currentAudienceId } = this.state;
    this.fetchLayersForAudience(currentAudienceId);
  }

  fetchLayersForAudience = audienceId => {
    const { currentProjectId } = this.props;
    this.setState({
      experimentsUsageAvailable: false,
      layerIds: [],
      currentAudienceId: audienceId,
    });
    ComponentActions.fetchLayersForAudience(currentProjectId, audienceId).then(
      layerIds => {
        this.setState({ experimentsUsageAvailable: true, layerIds });
      },
      () => {
        this.setState({
          experimentsUsageAvailable: true,
        });
        ui.showNotification({
          message: tr('Unable to get audience experiments'),
          type: 'error',
        });
      },
    );
  };

  render() {
    const {
      currentAudienceId,
      experimentsUsageAvailable,
      layerIds,
    } = this.state;
    const {
      audiences,
      currentProjectId,
      experimentCounts,
      layers,
    } = this.props;
    return (
      <ExperimentUsageTable
        currentEntityId={currentAudienceId}
        currentProjectId={currentProjectId}
        experimentsUsageAvailable={experimentsUsageAvailable}
        entities={ComponentFns.covertAudiences(audiences, experimentCounts)}
        entityType="audience"
        layers={ComponentFns.filterLayers(layers, layerIds)}
        onEntityChange={this.fetchLayersForAudience}
      />
    );
  }
}

export default AudienceExperimentUsage;

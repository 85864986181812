import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox } from '@optimizely/axiom';

import stringUtils from 'optly/utils/str';

import { DEFAULT_PROPERTIES, MAX_EVENT_PROPERTIES } from '../../constants';

const DefaultProperties = ({
  eventProperties,
  isEventUsedInExperiment,
  isFx,
  updateEventProperties,
}) => {
  const defaultProperties = useMemo(
    () =>
      eventProperties.filter(({ propertyType }) => propertyType === 'default'),
    [eventProperties],
  );

  const onDefaultPropertySelection = (event, property) => {
    const { checked } = event.target;
    const updatedProperties = checked
      ? [
          ...eventProperties,
          {
            ...property,
            propertyType: 'default',
          },
        ]
      : eventProperties.filter(
          ({ accessor }) => accessor !== property.accessor,
        );

    updateEventProperties(updatedProperties);
  };

  return (
    <div>
      <h6 className="subhead">Default Properties</h6>
      <p>Select the built-in properties to send with this event</p>
      <Table
        className="push-double--bottom push--top width--7-10"
        testSection="default-event-properties-table">
        <Table.THead className="muted">
          <Table.TR>
            <Table.TH className="soft-quad--left">Name</Table.TH>
            <Table.TH className="soft--left">Data Type</Table.TH>
          </Table.TR>
        </Table.THead>
        <Table.TBody>
          {DEFAULT_PROPERTIES.map(property => {
            const defaultProperty = defaultProperties.find(
              ({ accessor }) => accessor === property.accessor,
            );
            return (
              <Table.TR key={`custom-property--${property.accessor}`}>
                <Table.TD
                  className="soft-quad--left position--relative"
                  testSection="default-property-item">
                  <Checkbox
                    className="position--absolute"
                    disabled={
                      (isEventUsedInExperiment && defaultProperty?.persisted) ||
                      (isFx && defaultProperty?.persisted) ||
                      (eventProperties.length >= MAX_EVENT_PROPERTIES &&
                        !defaultProperty)
                    }
                    onChange={event =>
                      onDefaultPropertySelection(event, property)
                    }
                    checked={
                      !!defaultProperties.find(
                        p => p.accessor === property.accessor,
                      )
                    }
                    style={{
                      left: 5,
                      top: 0,
                      bottom: 0,
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  />
                  <span data-test-section="default-property-item-label">
                    {property.value}
                  </span>
                </Table.TD>
                <Table.TD className="muted">
                  <span>{stringUtils.capitalize(property.type)}</span>
                </Table.TD>
              </Table.TR>
            );
          })}
        </Table.TBody>
      </Table>
    </div>
  );
};

DefaultProperties.propTypes = {
  eventProperties: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired,
      persisted: PropTypes.bool,
      propertyType: PropTypes.oneOf(['custom', 'default']).isRequired,
      type: PropTypes.oneOf(['string', 'number', 'boolean']),
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isEventUsedInExperiment: PropTypes.bool,
  updateEventProperties: PropTypes.func.isRequired,
};

export default DefaultProperties;

import React from 'react';
import PropTypes from 'prop-types';

import { Button, DialogNew, Link } from 'optimizely-oui';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import RequestUtil from 'optly/utils/request';
import Router from 'core/router';
import UrlHelper from 'optly/services/url_helper';
import { connect } from 'core/ui/decorators';
import ui from 'core/ui';

import AdminAccountGetters from 'optly/modules/admin_account/getters';
import ProjectEnums from 'optly/modules/entity/project/enums';
import ProjectActions from 'optly/modules/entity/project/actions';
import PermissionsFns from 'optly/modules/permissions/fns';

@connect({
  accountId: AdminAccountGetters.id,
  canUseFullStackSDKs: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseFullStackSDKs,
  ],
  isUserAdmin: AdminAccountGetters.isUserAdmin,
})
class TryRollouts extends React.Component {
  static componentId = 'try-rollouts';

  static propTypes = {
    accountId: PropTypes.number.isRequired,
    canUseFullStackSDKs: PropTypes.bool.isRequired,
    isUserAdmin: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isAgreeing: false,
    };
  }

  whiteListAccount = () => {
    const { accountId } = this.props;
    const route = isFeatureEnabled('enable-product-trial')
      ? 'create_rollouts_product_trial'
      : 'create_rollouts_project';
    this.setState(
      {
        isAgreeing: true,
      },
      () => {
        RequestUtil.makeOptlyFetchRequest({
          url: `api/v1/${route}/${accountId}`,
          method: 'POST',
        })
          .then(res => {
            const rolloutsProjectId = res.id;
            ProjectActions.fetch(rolloutsProjectId, true).then(() => {
              Router.windowNavigate(
                UrlHelper.xProjectHome(
                  rolloutsProjectId,
                  ProjectEnums.project_platforms.CUSTOM,
                ),
              );
            });
          })
          .catch(() => {
            ui.error({
              message: 'Rollouts could not be added to your account.',
              logToServer: true,
            });
            this.setState({
              isAgreeing: false,
            });
          });
      },
    );
  };

  navigateToProjects = () => {
    Router.windowNavigate('/v2/projects');
  };

  navigateToAdminAccountCollaborators = () => {
    const { accountId } = this.props;
    const collaboratorsLink = UrlHelper.projectSettingsCollaborators(accountId);
    Router.windowNavigate(collaboratorsLink);
  };

  renderAlreadyHasFSDialog = () => (
    <DialogNew
      hasOverlay={false}
      hasCloseButton={false}
      title="You have access to Rollouts"
      footerButtonList={[
        <Button
          style="highlight"
          key="agree"
          testSection="navigate-to-projects"
          onClick={this.navigateToProjects}>
          OK
        </Button>,
      ]}>
      <p>
        Your account already has access to all the functionality of Rollouts!
        Navigate to any Full Stack project to create a feature.
      </p>
    </DialogNew>
  );

  renderNonAdminDialog = () => (
    <DialogNew
      hasOverlay={false}
      hasCloseButton={false}
      title="Contact your account Administrator"
      footerButtonList={[
        <Button
          style="highlight"
          key="agree"
          testSection="navigate-to-collaborators"
          onClick={this.navigateToAdminAccountCollaborators}>
          Find an Administrator
        </Button>,
      ]}>
      <p>
        In order to add Rollouts, you must be an Administrator on this account.
        Find an Administrator or{' '}
        <Link
          href="https://www.optimizely.com/rollouts-signup"
          newWindow={true}>
          create a fresh Rollouts account
        </Link>{' '}
        with a different email.
      </p>
    </DialogNew>
  );

  renderFreeRolloutsDialog = () => {
    const { isAgreeing } = this.state;
    return (
      <DialogNew
        hasOverlay={false}
        hasCloseButton={false}
        title="Get Optimizely Rollouts for Free"
        footerButtonList={[
          <Button
            style="plain"
            key="no-thanks"
            testSection="try-rollouts-cancel"
            onClick={this.navigateToProjects}
            isDisabled={isAgreeing}>
            No, Thanks
          </Button>,
          <Button
            style="highlight"
            key="agree"
            testSection="try-rollouts-agree"
            onClick={this.whiteListAccount}
            isDisabled={isAgreeing}>
            Agree
          </Button>,
        ]}>
        <div data-test-section="try-rollouts-with-experimentation-copy">
          <p>
            <Link href="http://optimizely.com/rollouts" newWindow={true}>
              Optimizely Rollouts
            </Link>{' '}
            is a safer, faster way to release new features. Implement feature
            flags, gradual rollouts, and safe rollbacks in your development
            process using our SDKs. Run an experiment on top of your flag to
            learn if you’re building the right feature.
          </p>
          <p className="micro push-triple--top">
            We’re providing this product completely free. In this free product,
            you’ll also be able to run one experiment at a time to measure and
            prove the value of features.{' '}
            <strong>
              {' '}
              If you have an existing, active impressions or monthly active user
              (MAU) package, your experiment usage in Rollouts will be billed to
              it.
            </strong>
            By proceeding, you agree that your company’s use of Rollouts is
            powered by our online{' '}
            <Link href="https://www.optimizely.com/terms/" newWindow={true}>
              Terms of Service
            </Link>{' '}
            as a Free Access Subscription.
          </p>
          <p className="micro">
            Read our{' '}
            <Link href="https://www.optimizely.com/privacy/" newWindow={true}>
              privacy policy
            </Link>
            .
          </p>
        </div>
      </DialogNew>
    );
  };

  renderDialog = () => {
    const { canUseFullStackSDKs, isUserAdmin } = this.props;
    if (!canUseFullStackSDKs) {
      if (!isUserAdmin) {
        return this.renderNonAdminDialog();
      }

      return this.renderFreeRolloutsDialog();
    }
    return this.renderAlreadyHasFSDialog();
  };

  render() {
    return (
      <div className="marketing-landing" data-test-section="try-rollouts">
        <div className="marketing-landing__nav">
          <div className="flex--1 marketing-landing__column">
            <div className="marketing-landing__logo">
              <a href="/v2/projects">
                <div className="marketing-landing__logo-image" />
              </a>
            </div>
          </div>
        </div>
        {this.renderDialog()}
      </div>
    );
  }
}

export default TryRollouts;

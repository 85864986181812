import { createEntityActions } from 'optly/modules/rest_api';

import termsGetters from './getters';
import definition from './entity_definition';

/**
 * @member actions
 * @memberof entity/terms_of_service
 */
export const actions = createEntityActions(definition);

export const getters = termsGetters;

/**
 * @module entity/terms_of_service
 */
export default {
  actions,
  getters,
};

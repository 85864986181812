import ProjectEnums from 'optly/modules/entity/project/enums';
import keyMirror from 'optly/utils/key_mirror';

export const TABLE_ID = 'ExperimentsDashboard';

const getActivationCodeBlockReact = expKey =>
  `function ExperimentComponent() {
  return (
    <OptimizelyExperiment experiment="${expKey}">`;

const getVariationCodeBlockReact = variationKey =>
  `      <OptimizelyVariation variation="${variationKey}">
        { // execute code or render component for ${variationKey} }
      </OptimizelyVariation>
`;

const EXPERIMENT_CONTROL_CODE_BLOCKS_REACT = `      <OptimizelyVariation default>
        { // execute default code or show default component }
      </OptimizelyVariation>`;

const EXPERIMENT_ACTIVATION_CODE_BLOCKS_REACT_POSTFIX = `
    </OptimizelyExperiment>
  )
}
`;

const EXPERIMENT_ACTIVATION_CODE_BLOCK_AGENT = `curl -X POST \\
  '{HOST_URL}/v1/activate?experimentKey={ experimentKey }' \\
  -H 'Content-Type: application/json' \\
  -H 'X-Optimizely-SDK-Key: "YOUR-SDK-KEY"' \\
  -d '{
    "userId":"USER_ID"
  }'
`;

export const tableIds = keyMirror({
  EXPERIMENTS: null,
  GROUPS: null,
});

export const FORCED_VARIATION_MATCH_TYPES = {
  EXACT: 'exact',
};

export const LAYER_EXPERIMENT_HUMAN_READABLE_STATUS_TYPES = {
  ARCHIVED: 'Archived',
  DRAFT: 'Draft',
  LAUNCHED: 'Launched',
  PAUSED: 'Paused',
  RUNNING: 'Running',
};

export const EXPERIMENT_KEY_PLACEHOLDER = '{ experimentKey }';

export const EXPERIMENT_ACTIVATION_CODE_BLOCKS = {
  [ProjectEnums.sdkLanguages
    .PHP]: `$variation = $optimizelyClient->activate('${EXPERIMENT_KEY_PLACEHOLDER}', $userId);`,
  [ProjectEnums.sdkLanguages
    .PYTHON]: `variation = optimizely_client.activate('${EXPERIMENT_KEY_PLACEHOLDER}', user_id)`,
  [ProjectEnums.sdkLanguages
    .JAVA]: `Variation variation = optimizelyClient.activate("${EXPERIMENT_KEY_PLACEHOLDER}", userId);`,
  [ProjectEnums.sdkLanguages
    .RUBY]: `variation = optimizely_client.activate('${EXPERIMENT_KEY_PLACEHOLDER}', user_id)`,
  [ProjectEnums.sdkLanguages.REACT]: getActivationCodeBlockReact(
    EXPERIMENT_KEY_PLACEHOLDER,
  ),
  [ProjectEnums.sdkLanguages
    .JAVASCRIPT]: `var variation = optimizelyClientInstance.activate('${EXPERIMENT_KEY_PLACEHOLDER}', userId);`,
  [ProjectEnums.sdkLanguages
    .OBJECTIVE_C]: `OPTLYVariation *variation = [client activate:@"${EXPERIMENT_KEY_PLACEHOLDER}" userId:userId];`,
  [ProjectEnums.sdkLanguages
    .SWIFT]: `let variation = optimizelyClient?.activate("${EXPERIMENT_KEY_PLACEHOLDER}", userId:userId)`,
  [ProjectEnums.sdkLanguages
    .CSHARP]: `var variation = OptimizelyClient.Activate("${EXPERIMENT_KEY_PLACEHOLDER}", userId);`,
  [ProjectEnums.sdkLanguages
    .GO]: `variation, err := optimizelyClient.Activate("${EXPERIMENT_KEY_PLACEHOLDER}", entities.UserContext{ID: userId})`,
  [ProjectEnums.sdkLanguages.AGENT]: EXPERIMENT_ACTIVATION_CODE_BLOCK_AGENT,
  [ProjectEnums.sdkLanguages
    .FLUTTER]: `var variation = await optimizelyClientInstance.activate("${EXPERIMENT_KEY_PLACEHOLDER}", userId);`,
};

export const EXPERIMENT_ACTIVATION_CODE_BLOCKS_POSTFIX = {
  [ProjectEnums.sdkLanguages
    .REACT]: EXPERIMENT_ACTIVATION_CODE_BLOCKS_REACT_POSTFIX,
};

export const EXPERIMENT_CONTROL_CODE_BLOCKS = {
  [ProjectEnums.sdkLanguages.PHP]: '\n} else {\n // execute default code',
  [ProjectEnums.sdkLanguages.PYTHON]: '\nelse:\n  # execute default code',
  [ProjectEnums.sdkLanguages.JAVA]:
    '\n  }\n} else {\n  // execute default code',
  [ProjectEnums.sdkLanguages.RUBY]: '\nelse\n  # execute default code',
  [ProjectEnums.sdkLanguages.REACT]: EXPERIMENT_CONTROL_CODE_BLOCKS_REACT,
  [ProjectEnums.sdkLanguages.JAVASCRIPT]:
    '\n} else {\n  // execute default code',
  [ProjectEnums.sdkLanguages.OBJECTIVE_C]:
    '\n} else {\n    // execute default code',
  [ProjectEnums.sdkLanguages.SWIFT]:
    '\n  }\n} else {\n    // execute default code',
  [ProjectEnums.sdkLanguages.GO]:
    '\n  }\n} else {\n    // execute default code',
  [ProjectEnums.sdkLanguages.CSHARP]:
    '\n  }\n} \nelse\n{\n  // execute default code',
  [ProjectEnums.sdkLanguages.AGENT]: '',
  [ProjectEnums.sdkLanguages.FLUTTER]: '\n} else {\n  // execute default code',
};

export const VARIATION_KEY_PLACEHOLDER = '{ variationKey }';

// @NOTE: the weird indentation is intentional because we
// are using template strings to preserve the newlines and indents
export const EXPERIMENT_VARIATION_CODE_BLOCKS = {
  [ProjectEnums.sdkLanguages
    .PHP]: `if ($variation == '${VARIATION_KEY_PLACEHOLDER}') {
  // execute code for ${VARIATION_KEY_PLACEHOLDER}`,
  [ProjectEnums.sdkLanguages
    .PYTHON]: `if variation == '${VARIATION_KEY_PLACEHOLDER}':
  # execute code for ${VARIATION_KEY_PLACEHOLDER}`,
  [ProjectEnums.sdkLanguages
    .JAVA]: `if (variation.is("${VARIATION_KEY_PLACEHOLDER}")) {
    // execute code for ${VARIATION_KEY_PLACEHOLDER}`,
  [ProjectEnums.sdkLanguages
    .RUBY]: `if variation == '${VARIATION_KEY_PLACEHOLDER}'
  # execute code for ${VARIATION_KEY_PLACEHOLDER}`,
  [ProjectEnums.sdkLanguages.REACT]: getVariationCodeBlockReact(
    VARIATION_KEY_PLACEHOLDER,
  ),
  [ProjectEnums.sdkLanguages
    .JAVASCRIPT]: `if (variation === '${VARIATION_KEY_PLACEHOLDER}') {
  // execute code for ${VARIATION_KEY_PLACEHOLDER}`,
  [ProjectEnums.sdkLanguages
    .OBJECTIVE_C]: `if ([variation.variationKey isEqualToString:@"${VARIATION_KEY_PLACEHOLDER}"]) {
    // execute code for ${VARIATION_KEY_PLACEHOLDER}`,
  [ProjectEnums.sdkLanguages
    .SWIFT]: `if variation.variationKey == "${VARIATION_KEY_PLACEHOLDER}" {
      // execute code for ${VARIATION_KEY_PLACEHOLDER}`,
  [ProjectEnums.sdkLanguages
    .CSHARP]: `if (variation.Key == "${VARIATION_KEY_PLACEHOLDER}")\n  {
      // execute code for ${VARIATION_KEY_PLACEHOLDER}`,
  [ProjectEnums.sdkLanguages
    .GO]: `if variation == "${VARIATION_KEY_PLACEHOLDER}" {
      // execute code for ${VARIATION_KEY_PLACEHOLDER}`,
  [ProjectEnums.sdkLanguages.AGENT]: '',
  [ProjectEnums.sdkLanguages
    .FLUTTER]: `if (variation.variationKey == "${VARIATION_KEY_PLACEHOLDER}") {
  // execute code for ${VARIATION_KEY_PLACEHOLDER}`,
};

export default {
  EXPERIMENT_ACTIVATION_CODE_BLOCKS,
  EXPERIMENT_ACTIVATION_CODE_BLOCKS_POSTFIX,
  EXPERIMENT_CONTROL_CODE_BLOCKS,
  EXPERIMENT_KEY_PLACEHOLDER,
  EXPERIMENT_VARIATION_CODE_BLOCKS,
  FORCED_VARIATION_MATCH_TYPES,
  LAYER_EXPERIMENT_HUMAN_READABLE_STATUS_TYPES,
  TABLE_ID,
  tableIds,
  VARIATION_KEY_PLACEHOLDER,
};

import BundleSplitHelper from 'optly/utils/bundle_split_helper';

import NavConstants from 'optly/services/navigation';

import RoutingFns from '../../routing_fns';
import SectionRoutingFns from './routing_fns';

let FullStackOnboardingSection;

/*
 * Routing function that ensures the Oasis dynamic bundle has been fetched
 */
const EnsureOasisBundle = (ctx, next) => {
  BundleSplitHelper.getOasisBundleModules()
    .then(modules => {
      ({ FullStackOnboardingSection } = modules);
    })
    .then(next);
};

/*
 * Routes for the Full Stack Onboarding section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/projects/:proj_id/onboarding',
    metadata: { name: 'Onboarding', category: 'Onboarding' },
    handle: [
      [
        EnsureOasisBundle,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.ONBOARDING,
        ),
        RoutingFns.parseProjectId,
        SectionRoutingFns.loadEntities,
      ],

      (ctx, next) =>
        FullStackOnboardingSection.pages.onboarding.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          name: 'Onboarding',
          next,
        }),
      () => {
        RoutingFns.renderMainRegion(
          FullStackOnboardingSection.pages.onboarding.component,
        );
      },
    ],
  },
];

export default routes;

/**
 * Third Party Condition Select Field Component
 * Responsible for rendering the configuration UI for a third-party select condition
 */
import _ from 'lodash';

import cloneDeep from 'optly/clone_deep';
import ui from 'core/ui';

import baseComponent from './base';
import template from './select.html';

export default _.extend({}, baseComponent, {
  template,

  data: {
    conditionValues: null,
    selectedConditionIndex: null,
  },

  methods: {
    restoreSelectedOptions() {
      const conditionId = this.condition.value.toString();
      this.selectedConditionIndex = _.findIndex(
        this.conditionValues,
        condition => condition.value.toString() === conditionId,
      );
    },

    setDefaultValue() {
      if (this.condition.value) {
        this.restoreSelectedOptions();
      } else {
        this.selectedConditionIndex = -1;
        this.updateConditionValue();
      }
    },

    updateConditionValue() {
      if (this.selectedConditionIndex !== -1) {
        const selectedCondition = this.conditionValues[
          this.selectedConditionIndex
        ];
        this.condition.value = selectedCondition.value.toString();
      } else {
        this.condition.value = '';
      }
    },
  },

  created() {
    this.conditionValues = [];
  },

  ready() {
    baseComponent.ready.call(this);

    // Initializes the condition values with those inherited from base.js
    this.conditionValues = cloneDeep(this.config.values);

    this.setDefaultValue();

    // Notify user if conditions weren't loaded
    if (!this.conditionValues || !this.conditionValues.length) {
      ui.showNotification({
        message: tr(
          'Optimizely is still syncing with your account. Please try again in a few minutes.',
        ),
      });
    }
  },
});

import constants from './constants';

/**
 * Returns the default outlier filter state of a given layer. If the layer
 * does not already have an outlier filter state, then set it to the default
 * one which has outlier filtering enabled.
 * @param layer
 * @returns Immutable.Map
 */
export const getDefaultOutlierFilter = layer => {
  if (layer && layer.get('outlier_filter')) {
    return layer.get('outlier_filter');
  }
  return constants.OUTLIER_FILTER_DEFAULT_STATE;
};

/**
 * Returns the original outlier filter state of a given layer. If the layer
 * does not already have one, set it to the default one with the enabled field
 * set to false since the layer did not originally have outlier filtering
 * enabled. This function is used for resetting a layer to it's original outlier
 * filter state.
 * @param layer
 * @returns Immutable.Map
 */
export const getOriginalOutlierFilter = layer => {
  if (layer && layer.get('outlier_filter')) {
    return layer.get('outlier_filter');
  }
  return constants.OUTLIER_FILTER_PRIOR_LAYERS_DEFAULT_STATE;
};

export default {
  getDefaultOutlierFilter,
  getOriginalOutlierFilter,
};

/**
 * Entity Definition for oauth client
 */

export const entity = 'oauth_clients';

export const parent = {
  entity: 'accounts',
  key: 'account_id',
};

export const fields = {
  id: null,
  client_id: null,
  account_id: null,
  client_type: null,
  client_secret: null,
  master_label: null,
  redirect_uris: [],
};

export default {
  entity,
  parent,
  fields,
};

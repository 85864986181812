/**
 * Getters for the UI regions store
 */

/**
 * Creates getter for reference to currently mounted component
 * @param {String} regionId
 * @return {Getter}
 */
export const mountedComponent = function(regionId) {
  return [['uiRegions', regionId, 'component'], data => data && data.component];
};

/**
 * Creates getter for reference to currently mounted component
 * @param {String} regionId
 * @return {Getter}
 */
export const mountedComponentId = function(regionId) {
  return ['uiRegions', regionId, 'componentId'];
};

export const navigationController = function(regionId) {
  return ['uiRegions', regionId, 'navigationController'];
};

/**
 * Creates getter for the component config to be queued up
 * @param {String} regionId
 * @return {Getter}
 */
export const queuedComponent = function(regionId) {
  return ['uiRegions', 'componentsToMount', regionId];
};

const exported = {
  mountedComponent,
  mountedComponentId,
  navigationController,
  queuedComponent,
};

export default exported;

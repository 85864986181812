export const TypeNames = {
  CLICK: tr('Click'),
  CUSTOM_EVENT: tr('Custom Event'),
  ENGAGEMENT: tr('Built-in'),
  PAGEVIEW: tr('Pageview'),
  REVENUE: tr('Built-in'),
  MOBILE_TAP_EVENT_TYPE: tr('Tap'),
  MOBILE_VIEW_EVENT_TYPE: tr('View'),
  MOBILE_SESSION_GOAL: tr('Built-in'),
  MOBILE_SESSION_LENGTH_GOAL: tr('Built-in'),
  MOBILE_NUM_SESSION_GOAL: tr('Built-in'),
};

export const TypeCodes = {
  CLICK: 0,
  CUSTOM_EVENT: 1,
  ENGAGEMENT: 2,
  PAGEVIEW: 3,
  REVENUE: 4,
  MOBILE_TAP_EVENT_TYPE: 5,
  MOBILE_VIEW_EVENT_TYPE: 6,
  MOBILE_SESSION_GOAL: 7,
  MOBILE_SESSION_LENGTH_GOAL: 8,
  MOBILE_NUM_SESSION_GOAL: 9,
};

export const DefaultGoals = [
  TypeCodes.ENGAGEMENT,
  TypeCodes.REVENUE,
  TypeCodes.MOBILE_SESSION_GOAL,
  TypeCodes.MOBILE_SESSION_LENGTH_GOAL,
  TypeCodes.MOBILE_NUM_SESSION_GOAL,
];

export const MatchTypeCodes = {
  EXACT: 1,
  REGEX: 2,
  SIMPLE: 3,
  SUBSTRING: 4,
};

/**
 * Goal constants
 */
export default {
  TypeNames,
  TypeCodes,
  DefaultGoals,
  MatchTypeCodes,
};

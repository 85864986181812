import React from 'react';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import Router from 'core/router';
import flux from 'core/flux';

import NavConstants from 'optly/services/navigation';
import BundleSplitHelper from 'optly/utils/bundle_split_helper';
import AdminAccountGetters from 'optly/modules/admin_account/getters';
import PermissionsFns from 'optly/modules/permissions/fns';
import BillingInfoEnums from 'optly/modules/entity/billing_info/enums';
import OptimizelyChampaignEnums from 'optly/modules/optimizely_champagne/enums';

import RoutingFns from '../../routing_fns';

const category = 'Account Settings';

/*
 * Routing function that ensures the account dashboard dynamic bundle has been fetched
 * We store the returned dynamic bundle module on the function object itself
 */
function AccountDashboardSection(ctx, next) {
  BundleSplitHelper.getAccountDashboardBundleModule()
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(AccountDashboardSection, module);
    })
    .then(next);
}

/*
 * Routes for the Account Dashboard section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/accountsettings(/account)?',
    handle: [
      () => {
        const canViewProjectsTab = flux.evaluate([
          AdminAccountGetters.accountPermissions,
          PermissionsFns.canUseProjects.bind(PermissionsFns),
        ]);
        if (!canViewProjectsTab) {
          Router.redirect('/v2/accountsettings/security');
        } else {
          Router.redirect('/v2/accountsettings/projects');
        }
      },
    ],
  },

  {
    match: '/v2/accountsettings/profile/(info|alerts)?',
    handle: [
      ctx => Router.redirect(`/v2/profile/preferences?${ctx.queryString}`),
    ],
  },

  {
    match: '/v2/accountsettings/apps(/access)?',
    handle: [() => Router.redirect('/v2/profile/api')],
  },

  {
    match: '/v2/accountsettings/security',
    metadata: { name: 'Security and Privacy Settings', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
        RoutingFns.setActiveTab(
          NavConstants.NavItems.ACCOUNT,
          NavConstants.AccountTabs.SECURITY_TAB,
        ),
        RoutingFns.fetchUserInfo,
      ],
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Security and Privacy Settings',
          next,
          showProjectName: false,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.Security.component />,
        ),
    ],
  },
  {
    match: '/v2/accountsettings/dcp-services',
    metadata: { name: 'DCP Services', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
        RoutingFns.setActiveTab(
          NavConstants.NavItems.ACCOUNT,
          NavConstants.AccountTabs.DCP_SERVICE_TAB,
        ),
      ],
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'DCP Services',
          next,
          showProjectName: false,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.DcpServices.component />,
        ),
    ],
  },

  {
    match: '/v2/accountsettings/account/dcp-services',
    handle: [() => Router.redirect('/v2/accountsettings/dcp-services')],
  },

  {
    match: '/v2/accountsettings/account/plan',
    metadata: { name: 'Plan Information', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
        RoutingFns.setActiveTab(
          NavConstants.NavItems.ACCOUNT,
          NavConstants.AccountTabs.PLAN_TAB,
        ),
      ],
      (ctx, next) => AccountDashboardSection.pages.Plan.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Plan Information',
          next,
          showProjectName: false,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.Plan.component />,
        ),
    ],
  },

  {
    match: '/v2/accountsettings/account/billing',
    metadata: { name: 'Billing', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
        (ctx, next) => {
          const canViewBillingTab = isFeatureEnabled(
            OptimizelyChampaignEnums.FEATURES.zuora_uncoupling.FEATURE_KEY,
          );
          if (canViewBillingTab) {
            return next();
          }
          Router.redirect('/v2/accountsettings');
        },
        RoutingFns.setActiveTab(
          NavConstants.NavItems.ACCOUNT,
          NavConstants.AccountTabs.BILLING_TAB,
        ),
      ],
      (ctx, next) =>
        AccountDashboardSection.pages.Billing.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Billing',
          next,
          showProjectName: false,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.Billing.component />,
        ),
    ],
  },

  {
    match: '/v2/accountsettings/account/plan-billing',
    metadata: { name: 'Plan Billing', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
        (ctx, next) => {
          const canViewBillingTab = isFeatureEnabled(
            OptimizelyChampaignEnums.FEATURES.zuora_uncoupling.FEATURE_KEY,
          );
          if (canViewBillingTab) {
            return next();
          }
          Router.redirect('/v2/accountsettings');
        },
        RoutingFns.setActiveTab(
          NavConstants.NavItems.ACCOUNT,
          NavConstants.AccountTabs.PLAN_BILLING_TAB,
        ),
      ],
      (ctx, next) =>
        AccountDashboardSection.pages.PlanBilling.routingSetup(ctx, next),
      (ctx, next) => {
        const planBillingPageTitle = isFeatureEnabled(
          OptimizelyChampaignEnums.FEATURES.impression_usage_page.FEATURE_KEY,
        )
          ? 'Billing'
          : 'Plan Billing';
        return RoutingFns.setPageTitle({
          category,
          name: planBillingPageTitle,
          next,
          showProjectName: false,
        });
      },
      () =>
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.PlanBilling.component />,
        ),
    ],
  },

  {
    match: '/v2/accountsettings/account/usage',
    metadata: { name: 'Usage', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
        (ctx, next) => {
          const planId = flux.evaluate(AdminAccountGetters.planId);
          const canSeeMAUDashboard =
            ( BillingInfoEnums.planIds.PP_2020 || BillingInfoEnums.planIds.PP_2024 ) &&
            isFeatureEnabled(
              OptimizelyChampaignEnums.FEATURES.mau_visualization.FEATURE_KEY,
            );
          if (
            (planId === BillingInfoEnums.planIds.PP_2017 ||
              canSeeMAUDashboard) &&
            isFeatureEnabled(
              OptimizelyChampaignEnums.FEATURES.impression_usage_page
                .FEATURE_KEY,
            )
          ) {
            return next();
          }
          Router.redirect('/v2/accountsettings');
        },
        RoutingFns.setActiveTab(
          NavConstants.NavItems.ACCOUNT,
          NavConstants.AccountTabs.USAGE_TAB,
        ),
      ],
      (ctx, next) =>
        AccountDashboardSection.pages.Usage.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Usage',
          next,
          showProjectName: false,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.Usage.component />,
        ),
    ],
  },

  {
    match: '/v2/accountsettings/classic',
    metadata: { name: 'Optimizely Classic', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
      ],
      RoutingFns.setActiveTab(
        NavConstants.NavItems.ACCOUNT,
        NavConstants.AccountTabs.OPTIMIZELY_CLASSIC_TAB,
      ),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Optimizely Classic',
          next,
          showProjectName: false,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.OptimizelyClassic />,
        ),
    ],
  },

  {
    match: '/v2/accountsettings/projects',
    metadata: { name: 'Projects', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
        RoutingFns.setActiveTab(
          NavConstants.NavItems.ACCOUNT,
          NavConstants.AccountTabs.PROJECTS_TAB,
        ),
      ],
      (ctx, next) =>
        AccountDashboardSection.pages.Projects.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Projects',
          next,
          showProjectName: false,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.Projects.component />,
        ),
    ],
  },

  {
    match: '/v2/accountsettings/registered-apps',
    metadata: { name: 'Registered Apps', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
        RoutingFns.setActiveTab(
          NavConstants.NavItems.ACCOUNT,
          NavConstants.AccountTabs.REGISTERED_APPS_TAB,
        ),
        RoutingFns.fetchDeveloperTabData,
      ],
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Registered Apps',
          next,
          showProjectName: false,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.RegisteredApps.component />,
        ),
    ],
  },

  {
    match: '/v2/accountsettings/snippets',
    metadata: { name: 'Snippets', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
        RoutingFns.setActiveTab(
          NavConstants.NavItems.ACCOUNT,
          NavConstants.AccountTabs.SNIPPETS_TAB,
        ),
      ],
      (ctx, next) =>
        AccountDashboardSection.pages.CustomSnippets.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Snippets',
          next,
          showProjectName: false,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.CustomSnippets.component />,
        ),
    ],
  },

  {
    match: '/v2/accountsettings/subject-access-requests',
    metadata: { name: 'Subject Access Requests', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
        RoutingFns.setActiveTab(
          NavConstants.NavItems.ACCOUNT,
          NavConstants.AccountTabs.SUBJECT_ACCESS_REQUESTS_TAB,
        ),
      ],
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Subject Access Requests',
          next,
          showProjectName: false,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.SubjectAccessRequests.component />,
        ),
    ],
  },

  {
    match: '/v2/accountsettings/users',
    metadata: { name: 'Users', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
        RoutingFns.setActiveTab(
          NavConstants.NavItems.ACCOUNT,
          NavConstants.AccountTabs.USERS_TAB,
        ),
      ],
      (ctx, next) =>
        AccountDashboardSection.pages.Users.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Users',
          next,
        }),
      () => {
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.Users.component />,
        );
      },
    ],
  },

  {
    match: '/v2/accountsettings/teams',
    metadata: { name: 'Teams', category },
    handle: [
      [
        AccountDashboardSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.ACCOUNT,
        ),
        RoutingFns.setActiveTab(
          NavConstants.NavItems.ACCOUNT,
          NavConstants.AccountTabs.TEAMS_TAB,
        ),
      ],
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Teams',
          next,
        }),
      () => {
        RoutingFns.renderMainRegion(
          <AccountDashboardSection.pages.Teams.component />,
        );
      },
    ],
  },
];

export default routes;

import $ from 'jquery';

import flux from 'core/flux';

import Immutable from 'optly/immutable';
import RequestUtil from 'optly/utils/request';
import ProjectActions from 'optly/modules/entity/project/actions';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import IntegrationActions from 'optly/modules/entity/integration/actions';
import DynamicConditionActions from 'optly/modules/entity/dynamic_condition/actions';
import RestApiCreateEntityActions from 'optly/modules/rest_api/create_entity_actions';

import definition from './entity_definition';

const baseEntityActions = RestApiCreateEntityActions(definition);

export const {
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deleteProjectIntegration = baseEntityActions.delete;

export { deleteProjectIntegration as delete };

/**
 * Fetch integration data for the project, required to render integrations dialog and third party audience options.
 * @param projectId ID of the project to fetch data from
 * @return {Deferred}
 */
export function fetchIntegrationsDataForProject(projectId) {
  ProjectActions.fetch(projectId);
  const integrationDef = IntegrationActions.fetchAll();
  const projectIntegrationDef = this.fetchAll({
    project_id: projectId,
  });

  // dont block on Project fetch as this is workaround on pages where CurrentProject isn't loaded
  return $.when(integrationDef, projectIntegrationDef);
}

/**
 * Gets all integration / project_integration / condition data from rest api needed to populate the AudienceBuilder
 * @param {Number} projectId
 * @return {Deferred}
 */
export function fetchAudienceIntegrationsDataForProject(projectId) {
  return this.fetchIntegrationsDataForProject(projectId).then(() => {
    // call private method for easier stubbing
    const enabledIntegrationIds = this.__getEnabledAudienceIntegrationIds();
    const deferredMap = {};

    enabledIntegrationIds.forEach(integrationId => {
      deferredMap[integrationId] = DynamicConditionActions.fetch({
        integration_id: integrationId,
        project_id: projectId,
      });
    });

    return deferredMap;
  });
}

/**
 * This method really doesnt belong in this module, as apparent with the circular dependency to the CurrentProject module
 * Fetch default-on integrations for new experiments
 * @param projectId ID of the project to fetch data from
 * @returns {Deferred}
 */
export function fetchDefaultEnabledIntegrationsForNewExperiment(projectId) {
  if (!projectId) {
    return $.Deferred().resolve([]);
  }

  const integrationDeferred = IntegrationActions.fetchAll();
  const projectIntegrationDeferred = this.fetchAll({ project_id: projectId });

  return $.when(integrationDeferred, projectIntegrationDeferred).then(() => {
    // Grossly needs to be required inline to avoid circular dependency. BAD BAD BAD
    const integrations = flux.evaluateToJS(CurrentProjectGetters.integrations);
    return integrations.filter(integration => {
      const { settings } = integration.projectLevelData;
      return (
        integration.projectLevelData.enabled &&
        settings &&
        settings.enable_for_new_experiments === 'on'
      );
    });
  });
}

export function save(data) {
  const def = baseEntityActions.save(data);
  // flush dynamic conditions as saving ProjectIntegration data can cause dynamic conditions to be different
  DynamicConditionActions.flush();
  return def;
}

/**
 * Update integration settings for a project
 *
 * @param projectId ID of the project to update integration settings
 * @param integrationId ID of the integration whose settings will be updated
 * @param enabled whether this integration shall be enabled for the project or not
 * @param settings an object all settings that the project integration uses
 * @param modifiedSettings an object containing only the settings modified by the user
 * @returns {Deferred}
 */
export function updateSettingsForProject(
  projectId,
  integrationId,
  enabled,
  settings,
  modifiedSettings,
) {
  return this.save({
    project_id: projectId,
    integration_id: integrationId,
    enabled: !!enabled,
    settings: settings || null,
    modified_settings: modifiedSettings || null,
  });
}

/**
 * Clear any stored auth tokens for the [current user + project + integration]
 * combo. Note this doesn't actually revoke Optimizely's permissions within the
 * third-party platform! Calling this function effectively tells our frontend
 * that the user needs to re-authenticate with that third-party.
 *
 * @param projectId {number} ID of the project where the integration is enabled.
 * @param integrationId {string} ID of the integration these credentials are for.
 *   This is _not_ a numeric ID, see integration.yaml files in the
 *   `optimizely/optimizely-integrations` repo for example values.
 * @returns {Promise}
 */
export function clearOAuthCredentialsForProject(projectId, integrationId) {
  const allIntegrations = flux.evaluate(CurrentProjectGetters.integrations);
  const integration =
    allIntegrations.find(integ => integ.get('id') === integrationId) ||
    Immutable.Map({});

  return RequestUtil.makeOptlyFetchRequest({
    url: `integrations/revoke-oauth-credentials?proj_id=${projectId}&integration_id=${integrationId}`,
    noResponseParse: true, // successful response has an empty body, so JSON parsing will fail
  }).then(() =>
    this.updateSettingsForProject(
      projectId,
      integrationId,
      integration.getIn(['projectLevelData', 'enabled']),
      integration.getIn(['projectLevelData', 'settings']),
      {
        refresh_token: null,
        key_version: null,
        adwords_client_id: null,
      },
    ),
  );
}

/**
 * Stubbable method for getting currently enabled audience integration ids array
 * @return {Array<String>}
 */
export function __getEnabledAudienceIntegrationIds() {
  return flux
    .evaluateToJS(CurrentProjectGetters.enabledAudienceIntegrations)
    .map(integration => integration.id);
}

export default {
  ...baseEntityActions,
  fetchIntegrationsDataForProject,
  fetchAudienceIntegrationsDataForProject,
  fetchDefaultEnabledIntegrationsForNewExperiment,
  save,
  updateSettingsForProject,
  clearOAuthCredentialsForProject,
  __getEnabledAudienceIntegrationIds,
};

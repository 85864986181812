import actions from './actions';
import fns from './fns';
import enums from './enums';
import getters from './getters';

export { actions, fns, enums, getters };

export default {
  actions,
  fns,
  enums,
  getters,
};

import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Textarea, Icon } from 'optimizely-oui';

import ui from 'core/ui';
import {
  actions as EditorActions,
  constants as EditorConstants,
} from 'bundles/p13n/modules/editor';
import LoadingOverlay from 'react_components/loading_overlay';
import FormatFilterSize from 'optly/filters/format_file_size';

import constants from './constants';

export default class ImageInputManager extends React.Component {
  static propTypes = {
    imageEditingEnabled: PropTypes.bool.isRequired,
    isEditingSelector: PropTypes.bool.isRequired,
    isEditorReadOnly: PropTypes.bool.isRequired,
    onImageChange: PropTypes.func.isRequired,
    setupChange: PropTypes.func.isRequired,
  };

  state = {
    url: '',
    image: { ...EditorConstants.IMAGE_PROPERTIES },
  };

  componentDidMount() {
    const { vueEvents } = this.context;
    const { setupChange } = this.props;
    if (vueEvents) {
      vueEvents.$on(
        constants.EventTypes.CALCULATE_IMAGE_PORPERTIES,
        this.handleCalculateImageProperties,
      );
      vueEvents.$on(constants.EventTypes.SET_IMAGE_URL, this.setValue);
      setupChange();
    }
  }

  setValue = url => {
    this.setState({ url });
  };

  /**
   * Emit an event whenever the image changes with the new URL.
   */
  onImageUrlChanged = event => {
    const { onImageChange } = this.props;
    this.setState({
      url: event.target.value,
      image: { ...EditorConstants.IMAGE_PROPERTIES },
    });
    onImageChange(event.target.value);
  };

  /**
   * Initialize the image object based on the provided displayValue.
   * @param displayValue
   */
  handleCalculateImageProperties = displayValue => {
    let image;
    const { image: imageFromState } = this.state;
    const imageObject = new Image();
    const { imageEditingEnabled } = this.props;
    if (!imageEditingEnabled) {
      image = { ...EditorConstants.IMAGE_PROPERTIES }; // Reset everything
    } else {
      image = { ...imageFromState };
    }
    const previousDisplayValue = image.displayValue;
    image.displayValue = displayValue;

    if (previousDisplayValue !== image.displayValue) {
      EditorActions.setImagePropertiesReact(image).finally(() => {
        imageObject.src = image.absoluteUrl;
        imageObject.onload = this.onImageLoad;
        imageObject.onerror = this.onImageError;
        this.setState({
          image,
          url: image.displayValue,
        });
      });
    } else {
      this.setState({
        image,
        url: image.displayValue,
      });
    }
  };

  /**
   * To be called on the change event for an file input element. Uploads the local image
   * supplied by the user to the optimizely CDN and sets the url of the that image in the
   * change store.
   *
   * @param {Event} event Change event
   * @return nothing
   */
  uploadImageAndSetAsSource = event => {
    const { onImageChange } = this.props;
    const { files } = event.target;
    if (files.length) {
      const file = files[0];
      const def = EditorActions.uploadImage(file)
        .done(url => {
          onImageChange(url);
          this.setState({ url });
        })
        .fail(errorMessage => {
          ui.showNotification({
            type: 'error',
            message:
              'There was an error uploading your image. Please try again.',
          });
        });
      ui.loadingWhen('img-upload', def);
    }
  };

  // Scale down to thumbnail size while keeping proportions
  getProportionalHeight = image =>
    image.height > image.width
      ? constants.THUMBNAIL_MAX_DIMENSION
      : (constants.THUMBNAIL_MAX_DIMENSION / image.width) * image.height;

  // Scale down to thumbnail size while keeping proportions
  getProportionalWidth = image =>
    image.height > image.width
      ? (constants.THUMBNAIL_MAX_DIMENSION / image.height) * image.width
      : constants.THUMBNAIL_MAX_DIMENSION;

  onImageLoad = ({ target: imageObject }) => {
    this.setState(prevState => ({
      image: {
        ...prevState.image,
        height: imageObject.height,
        width: imageObject.width,
        thumbnailHeight: this.getProportionalHeight(imageObject),
        thumbnailWidth: this.getProportionalWidth(imageObject),
      },
    }));
  };

  onImageError = ({ target: imageObject }) => {
    // if we hit this it's probably because this is an invalid url
    const { image: imageFromState } = this.state;
    if (imageFromState.src === imageObject.absoluteUrl) {
      this.setState(prevState => ({
        image: {
          ...prevState.image,
          width: 0,
          height: 0,
          thumbnailWidth: 0,
          thumbnailHeight: 0,
        },
      }));
    }
  };

  render() {
    const { image, url } = this.state;
    const {
      isEditingSelector,
      isEditorReadOnly,
      imageEditingEnabled,
    } = this.props;
    const style = {
      backgroundPosition: 'center center',
      backgroundImage: `url( ${image.absoluteUrl} )`,
      backgroundSize: `${image.thumbnailWidth}px ${image.thumbnailHeight}px`,
    };

    return (
      <LoadingOverlay loadingId="img-upload" className="img-upload">
        <div className="img-upload__data">
          <div className="img-upload__img" style={style} />
          <div className="img-upload__stats flex--1 force-break">
            <ul>
              <li data-test-section="image-filename">{image.filename}</li>
              <li className="muted" data-test-section="image-size">
                {FormatFilterSize(image.size)}
              </li>
              <li className="muted" data-test-section="image-dimensions">
                {`${image.width} x ${image.height}`}
              </li>
            </ul>
          </div>
        </div>
        <div className="push-half--bottom">
          <Textarea
            value={url}
            onChange={this.onImageUrlChanged}
            testSection="image-input-textarea"
            isDisabled={isEditingSelector || isEditorReadOnly}
          />
        </div>
        <div>
          <span className="file-input">
            <input
              type="file"
              onChange={this.uploadImageAndSetAsSource}
              disabled={isEditingSelector || !imageEditingEnabled}
            />
            <span
              className={classNames(
                'oui-button',
                'anchor--left',
                'oui-button--small',
                'oui-button--outline',
                {
                  'oui-button--disabled':
                    isEditingSelector || !imageEditingEnabled,
                },
              )}>
              <div className="flex">
                <span className="push-half--right flex--dead-center">
                  <Icon name="upload" size="small" />
                </span>
                {tr('Upload New')}
              </div>
            </span>
          </span>
        </div>
      </LoadingOverlay>
    );
  }
}

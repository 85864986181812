import enums from './enums';

export const plugin_type = {
  [enums.plugin_type.WIDGET]: 'Editor',

  [enums.plugin_type.EVENT]: 'Event',

  [enums.plugin_type.EDITOR_PANEL]: 'Editor Panel',

  [enums.plugin_type.ANALYTICS_INTEGRATION]: 'Analytics Integration',
};

export default {
  plugin_type,
};

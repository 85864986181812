/**
 * Module specific pure functions
 */

/**
 * Given an entity return a list of all email addresses
 * which are subscribed to the entity indicated.
 *
 * @param {Immutable.Map} entity
 *   Expected entity structure example:
 *
 *   {
 *     <user.unique_user_id> : {
 *       <connection_id>: {
 *         email: james@optimizely.com,
 *         last_seen: 2pm
 *       }
 *     },
 *     <user.unique_user_id> : {
 *       <connection_id>: {
 *         email: travis@optimizely.com,
 *         last_seen: 2:01pm
 *       }
 *     }
 *   }
 *
 * @returns {Immutable.List}
 *   Expected output example:
 *
 *   ['james@optimizely.com', 'travis@optimizely.com']
 */
export function userListFromEntity(entity) {
  return entity
    .map(user => user.map(connection => connection.get('email')))
    .flatten()
    .valueSeq()
    .toList();
}

export default {
  userListFromEntity,
};

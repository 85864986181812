/**
 * Entity Definition for LayerExperiment
 */
import { cloneDeep } from 'lodash';
import AudienceEnums from 'optly/modules/entity/audience/enums';
import fieldTypes from 'optly/modules/rest_api/field_types';

/**
 * TODO(APPX-34) Update to JSON.parse and replace "audience_conditions" when all LayerExperiment Audience builders can use rich JSON
 *
 * The AudienceCombinationsBuilder works with rich JSON. For the primary environment's rollout_rules, add computed audience_conditions_json key
 * from audience_conditions or audience_ids. This value will be removed before save in layer_experiment/actions.save
 *
 * @param {Object} layerExperiment
 * @returns {Object}
 */
export function computeLayerExperimentWithAudienceConditionsJson(
  layerExperiment,
) {
  const clonedLayerExperiment = cloneDeep(layerExperiment);
  if (typeof clonedLayerExperiment.audience_conditions === 'string') {
    clonedLayerExperiment.audience_conditions_json = JSON.parse(
      clonedLayerExperiment.audience_conditions,
    );
  } else {
    const audienceIds = clonedLayerExperiment.audience_ids || [];
    clonedLayerExperiment.audience_conditions_json = [
      AudienceEnums.ConditionGroupTypes.OR,
    ].concat(audienceIds.map(id => ({ audience_id: id })));
  }

  return clonedLayerExperiment;
}

export default {
  entity: 'layer_experiments',

  parent: {
    entity: 'projects',
    key: 'project_id',
  },

  fieldTypes: {
    id: fieldTypes.NUMBER,
    layer_id: fieldTypes.NUMBER,
    audience_ids: fieldTypes.ARRAY,
    audience_conditions: fieldTypes.STRING,
    variations: fieldTypes.ARRAY,
    holdback: fieldTypes.NUMBER,
    experiment_changes: fieldTypes.STRING,
    last_modified: fieldTypes.DATE,
    created: fieldTypes.DATE,
    forced_variations: fieldTypes.ARRAY,
  },

  deserialize(data) {
    // TODO(APPX-34) Update to JSON.parse and replace "audience_conditions" when all LayerExperiment Audience builders can use rich JSON
    return computeLayerExperimentWithAudienceConditionsJson(data);
  },
};

const {
  brandBlueDark,
} = require('@optimizely/design-tokens/dist/json/colors.json');

const flux = require('core/flux');
const CurrentlyEditingPluginGetters = require('bundles/p13n/sections/plugin_builder/modules/currently_editing_plugin/getters')
  .default;
const LayerExperimentEnums = require('optly/modules/entity/layer_experiment/enums')
  .default;

const fieldTypes = require('./field_types');

const EditorFns = require('bundles/p13n/modules/editor/fns').default;

const components = fieldTypes.getFieldTypeToComponents();
const Template = require('./template.html');

module.exports = {
  componentId: 'plugin-config-form',

  replace: true,

  template: Template,

  components,

  data: {
    allowSchemaEdit: false,
    inVariationEditor: false,
    ChangeStatuses: LayerExperimentEnums.ChangeStatuses,
    brandBlueDark,
  },

  methods: {
    getStatusClassForField(fieldName) {
      if (this.dirtyStatuses) {
        return EditorFns.coalesceStatusesIntoClass(
          this.dirtyStatuses[fieldName],
        );
      }
      return '';
    },

    /**
     * @param {FieldType} fieldType
     */
    getFieldComponent(fieldType) {
      const component = components[fieldType];
      if (!component) {
        throw new Error(`Invalid fieldType="${fieldType}"`);
      }
      // conventionally the component is named after the `fieldType`
      return fieldType;
    },

    getValue(inputConfig) {
      return this.formValues[inputConfig.name];
    },

    addField() {
      this.$options.addSchemaField();
    },

    /**
     * @param {Number} index of the schema field to edit
     */
    editSchemaField(index) {
      this.$options.editSchemaField(index);
    },

    onResetFormValue(inputConfig) {
      this.$options.onRevert(inputConfig);
    },
  },

  created() {
    if (!this.activeFrameId) {
      this.activeFrameId = null;
    }

    if (!this.$options.onUpdateFormValue) {
      throw new Error('Must provide onUpdateFormValue');
    }

    if (!this.$options.formValuesGetter) {
      throw new Error('Must provide formValuesGetter');
    }

    if (this.allowSchemaEdit && !this.$options.editSchemaField) {
      throw new Error('Must provide editSchemaField');
    }

    if (this.allowSchemaEdit && !this.$options.addSchemaField) {
      throw new Error('Must provide addSchemaField');
    }

    flux.bindVueValues(this, {
      formSchema: CurrentlyEditingPluginGetters.pluginConfigFormSchema,
      formValues: this.$options.formValuesGetter,
    });

    if (this.$options.formValuesDirtyStatusGetter) {
      flux.bindVueValues(this, {
        dirtyStatuses: this.$options.formValuesDirtyStatusGetter,
      });
    }

    this.$on('pluginUpdateFormValue', this.$options.onUpdateFormValue);
  },
};

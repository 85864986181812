import actions from './actions';
import config from './config';
import enums from './enums';
import fns from './fns';
import getters from './getters';

export { actions, config, enums, fns, getters };

export default {
  actions,
  config,
  enums,
  fns,
  getters,
};

import { OptimizelyFeature } from '@optimizely/react-sdk';

import PropTypes from 'prop-types';

import React from 'react';

import { Link, Table } from 'optimizely-oui';

import InternalLink from 'react_components/link/index';

import Immutable from 'optly/immutable';
import MouseEvent from 'optly/utils/mouse_event';

import ui from 'core/ui';
import UrlHelper from 'optly/services/url_helper';

import { getters as LoadingGetters } from 'core/modules/loading';
import {
  fns as PermissionsFns,
  getters as PermissionsGetters,
} from 'optly/modules/permissions';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { getters as EnvironmentGetters } from 'optly/modules/entity/environment';

import shortdate from 'optly/filters/shortdate';
import JiraIssuesTableCell from 'bundles/p13n/components/jira/issues_table_cell';

import RowActions from './feature_table_row_actions';
import FeatureStatusWithEnvironments from './environments_status_overview';

const FeatureTableRow = ({
  activeExperiments,
  archive,
  canManageFeatures,
  canUpdateJiraLinks,
  canUseFeatureTests,
  currentProjectRunningExperiments,
  editFeature,
  environmentStatusesByFeatureId,
  feature,
  isLoadingLinks,
  jiraIntegrationSettings,

  rowIndex,
  unarchive,
}) => {
  const apiName = feature.get('api_name');
  const isArchived = feature.get('archived');
  const featureId = feature.get('id');
  const featureName = feature.get('name');
  const projectId = feature.get('project_id');

  const environmentStatuses = environmentStatusesByFeatureId.get(featureId);

  let tableWidths = {
    environmentStatus: '20%',
    created: '11%',
    modified: '11%',
    actions: '2%',
  };

  const showJiraIssues =
    !!jiraIntegrationSettings.get('resource_id') &&
    jiraIntegrationSettings.get('enabled');

  if (showJiraIssues) {
    tableWidths = {
      jiraIssues: '10%',
      environmentStatus: '18%',
      created: '10%',
      modified: '10%',
      actions: '2%',
    };
  }

  if (canUseFeatureTests) {
    tableWidths.activeExperiments = '22%';
  }

  return (
    <Table.TR key={featureId} testSection={`feature-table-row-${rowIndex}`}>
      <Table.TD testSection={`feature-table-row-details-${rowIndex}`}>
        <OptimizelyFeature feature="user_friendly_names">
          {isEnabled =>
            !!featureName && isEnabled ? (
              <>
                <Link
                  href={UrlHelper.editFeature(projectId, featureId)}
                  isDisabled={isArchived}
                  onClick={MouseEvent.shouldPreventDefaultAndCallFn(() =>
                    editFeature(feature),
                  )}
                  testSection="feature-link">
                  <span data-test-section="feature-name">{featureName}</span>
                </Link>
                <div
                  className="zeta monospace muted micro word-break--all"
                  data-test-section="feature-api-name"
                  style={{ marginTop: '-1px' }}>
                  {apiName}
                </div>
              </>
            ) : (
              <Link
                href={UrlHelper.editFeature(projectId, featureId)}
                isDisabled={isArchived}
                onClick={MouseEvent.shouldPreventDefaultAndCallFn(() =>
                  editFeature(feature),
                )}
                testSection="feature-link">
                <span
                  data-test-section="feature-api-name"
                  className="zeta monospace">
                  {apiName}
                </span>
              </Link>
            )
          }
        </OptimizelyFeature>
        <div
          className="muted micro word-break--all"
          data-test-section="feature-description">
          {feature.get('description')}
        </div>
      </Table.TD>
      {showJiraIssues && (
        <Table.TD width={tableWidths.jiraIssues}>
          <JiraIssuesTableCell
            source={feature}
            sourceType="feature"
            tenantUrl={jiraIntegrationSettings.get('tenant_url')}
          />
        </Table.TD>
      )}
      {canUseFeatureTests && (
        <Table.TD
          testSection={`feature-table-row-active-experiments-${rowIndex}`}
          width={tableWidths.activeExperiments}>
          <div className="word-break--all">
            {activeExperiments.size === 0 && '--'}
            {activeExperiments.size > 0 && (
              <OptimizelyFeature feature="user_friendly_names">
                {isEnabled =>
                  isEnabled
                    ? activeExperiments.map(
                        (
                          { key, name, id, projectId: experimentProjectId },
                          index,
                        ) => (
                          <div
                            className={index ? 'push-half--top' : ''}
                            key={key}>
                            <InternalLink
                              key={id}
                              href={UrlHelper.experimentVariations(
                                experimentProjectId,
                                id,
                              )}
                              testSection={`active-experiment-link-${index}`}>
                              <div
                                data-test-section={`active-experiment-name-${index}`}>
                                {name}
                              </div>
                            </InternalLink>
                            <div
                              className="zeta monospace muted micro"
                              data-test-section={`active-experiment-key-${index}`}
                              style={{ marginTop: '-1px' }}>
                              {key}
                            </div>
                          </div>
                        ),
                      )
                    : activeExperiments.map(({ key }) => key).join(', ')
                }
              </OptimizelyFeature>
            )}
          </div>
        </Table.TD>
      )}
      <Table.TD
        testSection={`feature-table-row-rollout-info-${rowIndex}`}
        width={tableWidths.environmentStatus}>
        <FeatureStatusWithEnvironments
          feature={feature}
          featureEnvironmentsInfo={environmentStatuses}
        />
      </Table.TD>
      <Table.TD width={tableWidths.created}>
        {shortdate(feature.get('created'))}
      </Table.TD>
      <Table.TD width={tableWidths.modified}>
        {shortdate(feature.get('last_modified'))}
      </Table.TD>
      <Table.TD isNumerical={true} width={tableWidths.actions}>
        <RowActions
          archive={archive}
          canManageFeatures={canManageFeatures}
          canUpdateJiraLinks={canUpdateJiraLinks}
          currentProjectRunningExperiments={currentProjectRunningExperiments}
          feature={feature}
          rowIndex={rowIndex}
          unarchive={unarchive}
          jiraIntegrationEnabled={jiraIntegrationSettings.get('enabled')}
          isLoadingLinks={isLoadingLinks}
        />
      </Table.TD>
    </Table.TR>
  );
};

FeatureTableRow.propTypes = {
  activeExperiments: PropTypes.PropTypes.instanceOf(Immutable.List).isRequired,
  archive: PropTypes.func.isRequired,
  canManageFeatures: PropTypes.bool.isRequired,
  canUpdateJiraLinks: PropTypes.bool.isRequired,
  canUseFeatureTests: PropTypes.bool.isRequired,
  currentProjectRunningExperiments: PropTypes.instanceOf(Immutable.List)
    .isRequired,
  editFeature: PropTypes.func.isRequired,
  environmentStatusesByFeatureId: PropTypes.instanceOf(Immutable.Map)
    .isRequired,
  feature: PropTypes.instanceOf(Immutable.Map).isRequired,
  isLoadingLinks: PropTypes.bool,
  jiraIntegrationSettings: PropTypes.instanceOf(Immutable.Map),
  rowIndex: PropTypes.number.isRequired,
  unarchive: PropTypes.func.isRequired,
};

FeatureTableRow.defaultProps = {
  isLoadingLinks: false,
  jiraIntegrationSettings: Immutable.Map({}),
};

export default ui.connectGetters(FeatureTableRow, {
  canUpdateJiraLinks: [
    CurrentProjectGetters.project,
    PermissionsFns.canUpdateJiraLinks,
  ],
  canUseFeatureTests: PermissionsGetters.canUseFeatureTests,
  isLoadingLinks: LoadingGetters.isLoading('jira-links'),
  environmentStatusesByFeatureId:
    EnvironmentGetters.environmentStatusesByFeatureId,
});

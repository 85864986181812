import config from 'atomic-config';

import SentrySDKWrapper from '@optimizely/sentry-sdk-wrapper';

import flux from 'core/flux';
import SentryGetters from 'optly/modules/sentry/getters';
import AdminAccountGetters from 'optly/modules/admin_account/getters';

/**
 * @public
 * @function init
 * @description Initializes the Sentry SDK
 */
export const init = () => {
  // For production releases, grab the release version
  let release;
  if (!config.get('env.IS_LOCAL') && !config.get('env.IS_STAGING')) {
    const versionWithBuildNumber = config.get('env.VERSION_NUMBER');
    if (versionWithBuildNumber) {
      /**
       * env.VERSION_NUMBER is in the format 'master-pci-416.408490621559695461'.
       * We need to remove the build number suffix (in this case '.408490621559695461')
       * for proper sentry error rollups.
       */
      [release] = versionWithBuildNumber.split('.');
    }
  }

  const sentryTags = flux.evaluateToJS(SentryGetters.defaultAttributes({}));

  const sentryEventContext = {
    /**
     * Standard Sentry Tags:
     *
     * This is general information regarding the application version,
     * distribution, and high-level processes (routing and page loading).
     * It is used to determine if a regression-linked Sentry event is specific
     * to particular versions or releases of the Optimizely app. This info is
     * viewable on the Sentry dashboard and can be indexed or used for aggregation.
     *
     * - appVersion: is the event from classic or X?
     * - desktopAppPlatform: if using the desktop app, which OS?
     * - desktopAppVersion: if using the desktop app, which release?
     * - isDesktopApp: did the event originate in the desktop app?
     * - isImpersonating: is this user emulating an account?
     * - mfeName: The name of the host application / microfrontend. ** This tag is required by the SentrySDKWrapper. **
     */
    tags: {
      appVersion: sentryTags.app_version,
      desktopAppPlatform:
        sentryTags.is_desktop_app && sentryTags.desktop_app_platform,
      desktopAppVersion:
        sentryTags.is_desktop_app && sentryTags.desktop_app_version,
      isDesktopApp: sentryTags.is_desktop_app,
      isImpersonating: sentryTags.is_impersonating,
      mfeName: 'OPTLY_X',
    },
    user: {
      id: flux.evaluate(AdminAccountGetters.id),
      uuid: flux.evaluate(AdminAccountGetters.uniqueUserId),
    },
    context: {
      project: {
        projectID: sentryTags.project_id,
      },
    },
  };

  const sentryStackTraceMatchers = [
    /\/dist\/js\/optly_x(?:-\w+)?\.js/,
    /\/dist\/js\/optly_x_vendor(?:-\w+)?\.js/,
  ];

  // Filter out breadcrumbs for XHR/Fetch requests to
  // Segment, New Relic, Datadog
  const sentryBreadCrumbDenylistMatchers = [
    /https:\/\/api\.segment\.io/,
    /https:\/\/esp-eu\.aptrinsic\.com/,
    /https:\/\/bam\.nr-data\.net/,
    /https:\/\/rum\.browser-intake-datadoghq\.com/,
  ];

  SentrySDKWrapper.initHostSentry(
    {
      environment: config.get('env.ENVIRONMENT'),
      debug: __DEV__,
      dsn:
        'https://a84512de41244b67bbd61ef7bcae3605@o10597.ingest.sentry.io/100089',
      maxValueLength: 500,
      release,
    },
    sentryEventContext,
    sentryStackTraceMatchers,
    sentryBreadCrumbDenylistMatchers,
  );
};

/**
 * @protected
 * @function withStandardScope
 * @param {Function} callback - function to execute within the standard scope
 * @description Executes a callback within the standard Sentry scope
 */
export const withStandardScope = callback => {
  SentrySDKWrapper.withScope(scope => {
    // Trigger the callback with the standard scope context information
    callback(scope);
  });
};

/**
 * @public
 * @function captureException
 * @param {Any} exception - The JS exception to log in Sentry
 * @returns {String} - ID of the dispatched Sentry event
 * @description Logs a JS exception in Sentry
 */
export const captureException = exception => {
  withStandardScope(() => SentrySDKWrapper.captureException(exception));
};

/**
 * @public
 * @function captureMessage
 * @param {String} message - The message to log in Sentry
 * @param {String} level - The log level of the message
 * @returns {String} - ID of the dispatched Sentry event
 * @description Logs a message in Sentry
 */
export const captureMessage = (message, level) => {
  withStandardScope(() => SentrySDKWrapper.captureMessage(message, level));
};

/**
 * @public
 * @function withScope
 * @param {function(scope)} callback - Callback for configuring scope and dispatching events
 * @description Extends global Sentry scope to configure and dispatch Sentry events
 */
export const { withScope } = SentrySDKWrapper;

export default {
  init,
  withStandardScope,
  captureException,
  captureMessage,
  withScope,
};

import $ from 'jquery';
import flux from 'core/flux';

import { actions as CommitActions } from 'optly/modules/entity/commit';
import { actions as LayerActions } from 'optly/modules/entity/layer';
import { actions as LayerExperimentActions } from 'optly/modules/entity/layer_experiment';
import {
  getters as LiveCommitTagGetters,
  actions as LiveCommitTagActions,
} from 'optly/modules/entity/live_commit_tag';
import { actions as VerifierActions } from 'optly/modules/verifier';

/**
 * @param  {Number} layerId
 * @param  {Number} commitId
 * @param  {Number} currentProjectId
 * @return {Deferred}
 */
export function publishLayerCommit(layerId, commitId, currentProjectId) {
  // Purposefully don't pipe the result of the verifier as it takes
  // too long for the snippet to update. We only want to wait for the publish to happen
  return CommitActions.publishCommit(layerId, commitId).then(layerTag => {
    VerifierActions.verify({
      projectId: currentProjectId,
      revisionToVerify: layerTag.project_code_revision,
    }).catch(() => {}); // don't error on rejection
  });
}

function refetchLayerAndExperiments(layerId) {
  return LayerActions.fetch(layerId, true).then(layer =>
    LayerExperimentActions.intelligentFetchAll(layer, true),
  );
}

/**
 * Publish changes to a layer
 *
 * Creates a commmit and calls publishLayerCommit which publishes and verifies
 * the commit
 *
 * @param {Number} layerId
 * @param {Number} projectId
 * @return {Deferred}
 */
export function publishLayer(layerId, projectId, experimentIds, baseCommitId) {
  const date = tr.date().format('LLL');
  const commitData = {
    layer_id: layerId,
    name: tr('Campaign published at {0}', date),
    experiment_ids: experimentIds,
  };
  if (baseCommitId) {
    commitData.base_id = baseCommitId;
  }
  return CommitActions.save(commitData).then(commit =>
    this.publishLayerCommit(layerId, commit.id, projectId),
  );
}

/**
 * Publish changes to a layer and refetch the layer and its experiments.
 * Returned deferred is resolved when the publish and refetch are both complete
 *
 * @param {Number} layerId
 * @param {Number} projectId
 * @return {Deferred}
 */
export function publishLayerAndRefetchLayerData(
  layerId,
  projectId,
  experimentIds,
  baseCommitId,
) {
  return this.publishLayer(
    layerId,
    projectId,
    experimentIds,
    baseCommitId,
  ).then(() => refetchLayerAndExperiments(layerId));
}

/**
 * Pauses the layer by either deactivating the live
 * tag or if the live tag does not exist yet (because the campaign
 * has not yet been published), create a tag that is already deactivated
 *
 * @param {Number} layerId integer id of the layer of interest
 * @return {Deferred} deferred resolved when actions are finished
 *
 */
export function pauseLayer(layerId) {
  const commitTag = flux.evaluate(
    LiveCommitTagGetters.liveCommitTagByLayerId(layerId),
  );
  if (commitTag) {
    // If the commit tag already exists, deactivate it
    return LiveCommitTagActions.deactivateTag({
      layer_id: layerId,
    });
  }

  // Otherwise create a new commit and point a deactivated tag
  // to that commit
  const date = tr.date().format('LLL');
  const commitData = {
    layer_id: layerId,
    name: tr('Campaign published at {0}', date),
  };

  return CommitActions.save(commitData).then(commit =>
    LiveCommitTagActions.save({
      layer_id: layerId,
      commit_id: commit.id,
      active: false,
    }),
  );
}

/**
 * Publish ONLY changes to a layer, and not any changes to its child layer
 * experiments.
 *
 * Creates a commmit and calls publishLayerCommit which publishes and verifies
 * the commit.
 *
 * The new commit is based on the commit identified by baseCommitId. All layer
 * experiment state in the new commit comes from this base commit.
 *
 * See: https://optimizely.atlassian.net/browse/WEB-1485
 *
 * @param {Number} layerId
 * @param {Number} projectId
 * @param {Number} baseCommitId
 * @return {Deferred}
 */
export function publishLayerOnly(layerId, projectId, baseCommitId) {
  const date = tr.date().format('LLL');
  const commitData = {
    layer_id: layerId,
    name: tr('Campaign published at {0}', date),
    base_id: baseCommitId,
    layer_only: true,
  };
  return CommitActions.save(commitData).then(commit =>
    this.publishLayerCommit(layerId, commit.id, projectId),
  );
}

/**
 * Publish ONLY changes to a layer, and not any changes to its child layer
 * experiments. Also, refetch the layer and its experiments. Returned deferred
 * is resolved when the publish and refetch are both complete
 *
 * @param {Number} layerId
 * @param {Number} projectId
 * @param {Number} baseCommitId
 * @return {Deferred}
 */
export function publishLayerOnlyAndRefetchLayerData(
  layerId,
  projectId,
  baseCommitId,
) {
  const publishDeferred = this.publishLayerOnly(
    layerId,
    projectId,
    baseCommitId,
  );
  const refetchDeferred = refetchLayerAndExperiments(layerId);
  return $.when(publishDeferred, refetchDeferred);
}

export default {
  publishLayerCommit,
  publishLayer,
  publishLayerAndRefetchLayerData,
  pauseLayer,
  publishLayerOnly,
  publishLayerOnlyAndRefetchLayerData,
};

import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'optly/immutable';
import { connect } from 'core/ui/decorators';
import ui from 'core/ui';

import { Button, DialogNew, Sheet } from 'optimizely-oui';
import { withTrack } from '@optimizely/segment-js/dist/decorators';

import CatalogStatsGetters from 'optly/modules/entity/catalog_stats/getters';
import { RecommendationsHelpLink } from 'bundles/p13n/components/messaging/recommendations';
import { EventDistributionInfo } from 'bundles/p13n/sections/implementation/section_module/constants';

import SectionModuleFns from 'bundles/p13n/sections/implementation/section_module/fns';

import SummaryStatsSection from './subcomponents/summary_stats';
import ItemDetailsSection from './subcomponents/item_details';
import EventDetailsSection from './subcomponents/event_details';
import EventDistributionsSection from './subcomponents/event_distributions';

const DEFAULT_WARNING_THRESHOLD = 100;

@connect(({ catalog }) => ({
  displayDistributionSamples: CatalogStatsGetters.displayDistributionSamplesByCatalogID(
    catalog.get('id'),
  ),
  pageEventsStats: CatalogStatsGetters.pageEventsStatsByCatalogID(
    catalog.get('id'),
  ),
  summaryStats: CatalogStatsGetters.summaryStatsByCatalogID(catalog.get('id')),
}))
@withTrack
class CatalogProfilerDialog extends React.Component {
  static propTypes = {
    catalog: PropTypes.instanceOf(Immutable.Map).isRequired,
    /**
     * an array containing details about the display distribution samples of events and items for the given catalog
     */
    displayDistributionSamples: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        /**
         * active
         * item_id
         * num_events
         * recency
         */
        value: PropTypes.instanceOf(Immutable.List),
      }),
    ).isRequired,
    /**
     * an Immutable Map containing display names for each event in the given catalog
     *
     *    display_name
     *    event_name
     *    failed_items
     *    num_events
     *    num_items
     *    page_name
     *    per_tag_stats
     *    project_name
     */
    pageEventsStats: PropTypes.instanceOf(Immutable.Map).isRequired,
    /**
     * an Immutable Map containing details about the summary of events, items, and visitors for the given catalog
     *
     *    num_events_average_item
     *    num_events_average_visitor
     *    num_events_median_item
     *    num_events_median_visitor
     *    total_num_catalog_events
     *    total_num_failed_items
     *    total_num_items
     *    total_num_user_events
     *    total_num_visitors
     */
    summaryStats: PropTypes.instanceOf(Immutable.Map).isRequired,
    /**
     * Function handler for Segment Tracking
     */
    track: PropTypes.func,
  };

  static defaultProps = {
    track: () => {},
  };

  constructor(props) {
    super(props);

    const { pageEventsStats, track } = props;

    this.state = {
      /**
       * the current warning threshold for missing tags
       */
      currentWarningThreshold: DEFAULT_WARNING_THRESHOLD,
      /**
       * the currently selected page event stats for the currently selected event in the given catalog
       */
      selectedPageEventStats: pageEventsStats.first(),
    };

    track('Recommendations Catalog Profiler Dialog Opened');
  }

  onPageEventChange = value => {
    const { track } = this.props;

    this.setState({
      selectedPageEventStats: value,
    });

    track('Recommendations Catalog Profiler Event Selected', {
      event: value.get('display_name'),
    });
  };

  onWarningThresholdChange = value => {
    this.setState({
      currentWarningThreshold: value,
    });
  };

  /**
   * Renders the empty state when the data for the catalog is not yet available.
   */
  renderEmptyState = () => (
    <DialogNew
      footerButtonList={[
        <Button
          key="catalog-stats-dismiss-button"
          onClick={ui.hideDialog}
          style="highlight">
          Dismiss
        </Button>,
      ]}
      onClose={ui.hideDialog}
      title="Catalog Profiler"
      testSection="catalog-profiler-empty-state-dialog">
      <div className="text--center">
        <img
          alt=""
          className="anchor--middle"
          src="https://app.optimizely.com/dist/static/img/p13n/audience-detail-primary-metric-9552eb9ae5c0c6124634ad8b53db3bfc.svg"
          width="200"
        />
        <h3
          className="push-half--bottom"
          data-test-section="catalog-profiler-empty-state-help-text">
          Not enough data yet.
        </h3>
        <RecommendationsHelpLink
          helpLink={SectionModuleFns.getHelpCopy('profiler_link')}
          helpText=" about recommendations"
          testSection="catalog-profiler-empty-state"
        />
      </div>
    </DialogNew>
  );

  render() {
    const {
      catalog,
      displayDistributionSamples,
      pageEventsStats,
      summaryStats,
    } = this.props;

    if (
      displayDistributionSamples.length === 0 ||
      pageEventsStats.isEmpty() ||
      summaryStats.isEmpty()
    ) {
      return this.renderEmptyState();
    }

    const { currentWarningThreshold, selectedPageEventStats } = this.state;

    const highestDistributionSample = displayDistributionSamples[0].value;
    const lowestDistributionSample =
      displayDistributionSamples[displayDistributionSamples.length - 1].value;

    return (
      <Sheet
        footerButtonList={[
          <Button
            key="catalog-stats-dismiss-button"
            onClick={ui.hideDialog}
            style="highlight">
            Dismiss
          </Button>,
        ]}
        onClose={ui.hideDialog}
        title="Catalog Profiler"
        testSection="catalog-profiler-dialog">
        <SummaryStatsSection catalog={catalog} summaryStats={summaryStats} />
        <EventDistributionsSection
          eventDistributionInfo={EventDistributionInfo.HIGHEST}
          selectedDistributionSample={highestDistributionSample}
        />
        <EventDistributionsSection
          eventDistributionInfo={EventDistributionInfo.LOWEST}
          selectedDistributionSample={lowestDistributionSample}
        />
        <ItemDetailsSection catalog={catalog} />
        <EventDetailsSection
          currentWarningThreshold={String(currentWarningThreshold)}
          onPageEventChange={this.onPageEventChange}
          onWarningThresholdChange={this.onWarningThresholdChange}
          pageEventsStats={pageEventsStats}
          selectedPageEventStats={selectedPageEventStats}
        />
      </Sheet>
    );
  }
}

export default CatalogProfilerDialog;

/**
 * Services layer pure functions for the layer_experiments
 *
 * TODO: Audit the functions in here! If they consume BOTH LayerExperiments and ExperimentSections,
 * we should probably move them to a shared place.
 * JIRA: https://optimizely.atlassian.net/browse/FE-455
 *
 */
import _ from 'lodash';

import cloneDeep from 'optly/clone_deep';
import Immutable, { toImmutable, isImmutable } from 'optly/immutable';

import guid from 'optly/utils/guid';
import basis from 'optly/utils/basis';
import { aggregator, scope } from 'optly/modules/entity/metric/constants';
import {
  TrafficAllocationPolicyItems,
  TrafficAllocationPolicyTypes,
  TrafficAllocationVariationStatuses,
} from 'bundles/p13n/components/traffic_allocation/traffic_allocation_policy/constants';
import ScheduleFns from 'optly/modules/entity/schedule/fns';
import {
  policy as LayerPolicy,
  type as LayerType,
} from 'optly/modules/entity/layer/enums';

import humanReadable from './human_readable';
import enums from './enums';

const MAX_VARIATION_NAME_LENGTH = 500;
const MAXIMIZE_CONVERSION_VARIATION_REQUIREMENT = 2;
const MINIMIZE_TIME_VARIATION_REQUIREMENT = 3;

/**
 * @param {Number} viewId
 * @param {Number} variationId
 * @param {Object} changes
 * @param {Immutable.Map} experiment
 * @param {function} onError
 */
export function addAction(viewId, variationId, changes, experiment, onError) {
  // Ensure the action for this viewId exists and has changes keys.
  const updatedExperiment = this.ensureActionInitialized(
    viewId,
    variationId,
    experiment,
  );
  const variationIndex = updatedExperiment
    .get('variations')
    .findIndex(variation => variation.get('variation_id') === variationId);
  if (variationIndex === -1) {
    if (onError) {
      onError({
        title: 'Unknown variation  id',
        message:
          'Unable to find specified variation_id - [layer_experiment/fns.addAction]',
      });
    }
    return;
  }
  return updatedExperiment.updateIn(
    ['variations', variationIndex, 'actions'],
    actionList =>
      actionList.withMutations(newState => {
        const actionIndex = newState.findIndex(
          change => change.get('view_id') === viewId,
        );
        newState.setIn([actionIndex, 'changes'], toImmutable(changes));
        return newState;
      }),
  );
}

/**
 * Returns all changes corresponding to a view in a variation (in a given experiment)
 * @param {Number} viewId
 * @param {Number} variationId
 * @param {Immutable.Map} experiment
 * @returns {Immutable.List}
 */
export function changesForViewIdAndVariationIdAndExperimentId(
  viewId,
  variationId,
  experiment,
) {
  // Ensure the action for this viewId exists and has changes keys.
  const updatedExperiment = this.ensureActionInitialized(
    viewId,
    variationId,
    experiment,
  );
  const variationIndex = updatedExperiment
    .get('variations')
    .findIndex(variation => variation.get('variation_id') === variationId);
  if (variationIndex === -1) {
    return toImmutable([]);
  }
  const actionForView = updatedExperiment
    .getIn(['variations', variationIndex, 'actions'])
    .filter(action => action.get('view_id') === viewId);

  if (actionForView.count() > 0) {
    // return first change list for matching view
    // TODO : ensure backend api consolidates  multiple changes for same view id
    return actionForView.first().get('changes');
  }
}

/**
 * Ensure that changes array exists for the specified action
 * @param {Number} viewId
 * @param {Number} variationId
 * @param {Immutable.Map} experiment
 */
export function ensureActionInitialized(viewId, variationId, experiment) {
  const variationIndex = experiment
    .get('variations')
    .findIndex(variation => variation.get('variation_id') === variationId);
  return experiment.updateIn(
    ['variations', variationIndex, 'actions'],
    actionList =>
      actionList.withMutations(newState => {
        const actionIndex = newState.findIndex(
          change => change.get('view_id') === viewId,
        );
        if (actionIndex === -1) {
          newState.push(
            toImmutable({
              view_id: viewId,
              changes: [],
            }),
          );
        } else {
          const action = newState.get(actionIndex);
          if (!action.get('changes')) {
            newState.setIn([actionIndex, 'changes'], toImmutable([]));
          }
        }
        return newState;
      }),
  );
}

/**
 * Sets the actions to an empty list for all variations of the experiment
 * @param {Immutable.List} layerExperiment
 * @return {Immutable.Map} new state for the layerExperiment
 */
export function removeActions(layerExperiment) {
  return layerExperiment.update('variations', variations =>
    variations.map(variation =>
      variation.update('actions', () => toImmutable([])),
    ),
  );
}

/**
 * Given a layer experiment, delete all the actions which correspond to
 * any viewId in the provided list of viewIds.
 * @param {Immutable.Map} layerExperiment
 * @param {Array} viewIds
 * @return {Immutable.Map} new state for the layerExperiment
 */
export function removeViews(layerExperiment, viewIds) {
  return layerExperiment.update('variations', variations =>
    variations.map(variation =>
      variation.update('actions', actionList => {
        // Create a new Immutable.List since we cannot
        // use .delete with withMutations
        let newState = Immutable.List(actionList);
        (viewIds || []).forEach(viewId => {
          const changeIndex = newState.findIndex(
            change => change.get('view_id') === viewId,
          );
          if (changeIndex !== -1) {
            newState = newState.delete(changeIndex);
          }
        });
        return newState;
      }),
    ),
  );
}

/**
 * Given a layer experiment, clean all unnecessary fields from
 * the actions JSON.
 *
 * Fields removed:
 *   'status': this field is computed in the frontend, so does not
 *             need to be saved in the backend
 * @param {Object} layerExperiment
 * @return {Object} new state for the layerExperiment
 */
export function cleanActionsJSON(layerExperiment) {
  const layerExperimentClone = cloneDeep(layerExperiment);
  // account for cloneDeep not cloning the variations properly if they are Immutable
  if (isImmutable(layerExperiment.variations)) {
    layerExperimentClone.variations = layerExperiment.variations.toJS();
  }

  if (layerExperimentClone.variations) {
    layerExperimentClone.variations.forEach(variation => {
      if (variation.actions) {
        variation.actions.forEach(action => {
          action.changes.forEach(change => {
            delete change.status;
          });
        });
      }
    });
  }

  return layerExperimentClone;
}

/**
 * TODO(APPX-34) Move to entity_definition.serialize for "audience_conditions" when all LayerExperiment Audiences builders can use rich JSON
 *
 * Given a layer experiment, delete computed audience_conditions_json key
 *
 * Fields removed:
 *   'audience_conditions_json': Since the new Audience Combinations Builder
 *             component sends the rich audience_conditions_json, and because
 *             audience_conditions takes precedence over audience_ids in the
 *             backend, this removes the (computed in the frontend only) audience_conditions_json and sets
 *             audience_conditions (stringified JSON) and audience_ids (Array|Null)
 * @param {Object} layerExperiment
 * @param {Object} options
 * @param {Boolean} options.useAudienceConditionsJSON
 *  If true, the audience_conditions_json field (computed in layer_experiment/entity_definition.js) should be used
 *  to compute audience_conditions and audience_ids. Otherwise, audience_conditions and audience_ids will be used
 * @return {Object} new state for the layerExperiment
 */
export function cleanAudiencesJSON(
  layerExperiment,
  options = { useAudienceConditionsJSON: false },
) {
  const layerExperimentClone = cloneDeep(layerExperiment);

  const audienceConditionsJson = layerExperimentClone.audience_conditions_json;
  if (options.useAudienceConditionsJSON && audienceConditionsJson) {
    layerExperimentClone.audience_conditions = JSON.stringify(
      audienceConditionsJson,
    );
    // TODO(APPX-34) Figure out what to do with audience_ids once migration to rich JSON audience_conditions is complete!
    delete layerExperimentClone.audience_ids;

    // audience_ids should be an empty array if audience_conditions_json is available but configured to everyone
    // ("everyone" means audience_conditions_json either has just a single operator or is empty)
    if (
      audienceConditionsJson &&
      (!audienceConditionsJson.length ||
        audienceConditionsJson.every(_.isString))
    ) {
      layerExperimentClone.audience_conditions = null;
      layerExperimentClone.audience_ids = [];
    }
  }

  delete layerExperimentClone.audience_conditions_json;
  return layerExperimentClone;
}

/**
 * Return list of layer Experiments filtered by experiment status
 * @param {Immutable.List} experiments
 * @param {String} status
 * @return {Array} filtered experiments
 */
export function getExperimentsByStatus(experiments, status) {
  return _.filter(
    experiments.toJS(),
    experiment => experiment.status === status,
  );
}

/**
 * Return list of layer Experiments which have the given status and don't exist in the live commit
 * @param {Immutable.List} savedExperiments - from a given Layer
 * @param {Immutable.List} liveCommitExperiments - for same layer as above
 * @param {String} status  - LayerExperiment status
 * @return {Immutable.list} list of filtered experiments which are in savedExperiments but not in liveCommitExperiments
 */
export function getAddedExperimentsByStatus(
  savedExperiments,
  liveCommitExperiments,
  status,
) {
  if (liveCommitExperiments) {
    const savedLayerExperiments = savedExperiments.filter(
      savedExperiment => savedExperiment.get('status') === status,
    );

    return savedLayerExperiments.filter(
      savedLayerExperiment =>
        !liveCommitExperiments.some(
          liveLayerExperiment =>
            liveLayerExperiment.get('id') === savedLayerExperiment.get('id'),
        ),
    );
  }
  return toImmutable([]);
}

/**
 * Upon attempting to create a new layer experiment, validates layer experiment form inputs first
 * TODO: @jessica.chong Fix this later to take in the Layer and LayerExperiment as args and confine the
 * validation logic to the interior of this function.
 * @param {String} name
 * @param {Array} views
 * @param {Array} variations
 * @param {Boolean} isSingleExperimentLayer
 * @param {Number} holdback
 * @return {Object} error messages
 */
export function validateLayerExperiment(
  name,
  views,
  variations,
  isSingleExperimentLayer,
  holdback,
) {
  const errors = {};

  if (isSingleExperimentLayer && (!name || name.trim().length === 0)) {
    const nameErrorMessage = tr('Please name your experiment.');
    errors.name = nameErrorMessage;
  }

  if (isSingleExperimentLayer && views.length === 0) {
    const viewsErrorMessage = tr('Please select at least one page.');
    errors.views = viewsErrorMessage;
  }

  const missingName = _.some(
    variations,
    variation => variation.weight && variation.name === '',
  );

  if (missingName) {
    const variationsNameErrorMessage = tr(
      'Please provide variation names for all your variations.',
    );
    errors.variations = variationsNameErrorMessage;
  }

  if (holdback && (holdback < 0 || holdback > 99.99)) {
    errors.holdback =
      'The campaign holdback must be a value between 0 and 99.99.';
  }

  return errors;
}

/**
 * Given a layer experiment and a variation name, create a name for the duplicated variation
 * @param {Object} layerExperiment
 * @param {String} variationName
 * @return {String} the new name
 */
export function createDuplicateName(layerExperiment, variationName) {
  let newName;
  let copyNumber = 1;

  do {
    newName = `${variationName} (${copyNumber})`;
    copyNumber++;
  } while (!this.validateVariationName(layerExperiment, newName).valid);

  return newName;
}

/**
 * Given a layer experiment and a variation name, make sure name exists and is unique
 * @param {Object} layerExperiment
 * @param {String} newVariationName
 */
export function validateVariationName(layerExperiment, newVariationName) {
  let valid = true;
  let message = '';
  // Variation must have a name that is not blank
  if (!newVariationName || newVariationName.trim() === '') {
    valid = false;
    message = tr('Please choose a name for your variation');
  } else if (newVariationName.length > MAX_VARIATION_NAME_LENGTH) {
    valid = false;
    message = tr('Variation name must be no longer than 500 characters');
  } else {
    // Name must be unique
    const { variations } = layerExperiment;

    variations.forEach(variation => {
      if (variation.name.trim() === newVariationName.trim()) {
        valid = false;
        message = tr('Please choose a unique name for your variation.');
      }
    });
  }
  return {
    valid,
    message,
  };
}

/**
 * Count all changes in variation for a given experiment
 * @param {Immutable.Map} experiment
 * @param {Number} variationId
 * @returns {Number} consolidatedChangesCount - count of all changes in views in a variation
 */
export function countAllChangesInVariation(experiment, variationId) {
  const viewIdList = this.getViewIdsInVariation(experiment, variationId);
  let consolidatedChangesCount = 0;
  let changesInSourceView;
  // update for each viewId
  viewIdList.map(viewId => {
    changesInSourceView = this.changesForViewIdAndVariationIdAndExperimentId(
      viewId,
      variationId,
      experiment,
    );
    consolidatedChangesCount += changesInSourceView.count();
  });
  return consolidatedChangesCount;
}

/**
 * Get list of all viewIds in variation for a given experiment
 * @param {Immutable.Map} experiment
 * @param {Number} variationId
 * @returns {Immutable.List}
 */
export function getViewIdsInVariation(experiment, variationId) {
  const targetVariation = experiment
    .get('variations')
    .filter(variation => variation.get('variation_id') === variationId);
  if (targetVariation.count() > 0) {
    // return first variation actions if more than one exists (ok for p13n)
    return targetVariation
      .getIn([0, 'actions'])
      .map(action => action.get('view_id'));
  }
  return toImmutable([]);
}

/**
 * wrapper needed to stub guid module for easier testing
 * @returns {guid}
 */
export function generateGuid() {
  return guid();
}

/**
 * Clones an actions object for use elsewhere.
 *
 * @param {array} originalActions
 * @returns {array}
 */
export function cloneActions(originalActions) {
  const actions = cloneDeep(originalActions);
  actions.forEach(action => {
    action.changes = this.cloneChangeList(action.changes);
  });
  return actions;
}

/**
 * Clones a changeList, generating new guids for each changeId and
 * updating dependency ids accordingly.
 *
 * @param {array} originalChanges
 * @returns {array}
 */
export function cloneChangeList(originalChanges) {
  const changes = cloneDeep(originalChanges);
  const oldToNewIdMap = {};
  changes.forEach(change => {
    oldToNewIdMap[change.id] = this.generateGuid();
    change.id = oldToNewIdMap[change.id];
  });
  changes.forEach(change => {
    const newDependencies = [];
    change.dependencies.forEach(changeId =>
      newDependencies.push(oldToNewIdMap[changeId] || changeId),
    );
    change.dependencies = newDependencies;
  });
  return changes;
}

/**
 * Calculates total percent from variations (note that variations do not normally have a percentage field,
 * but one has to be calculated from the weight_distributions field on the layerExperiment
 * @param {Object} variations
 * @returns {Number} totalPercent
 */
export function calculateTotalPercent(variations) {
  let totalPercent = 0;

  _.each(variations, variation => {
    totalPercent += Number(variation.percentage);
  });

  return totalPercent;
}

/**
 * Checks whether a particular experiment is in the liveCommitLayer
 * @param {Number} experimentId
 * @param {Immutable.List} liveCommitLayerExperiments
 * @returns {Boolean} experimentLive
 */
export function isExperimentLive(experimentId, liveCommitLayerExperiments) {
  return liveCommitLayerExperiments
    .map(experiment => experiment.get('id'))
    .contains(experimentId);
}

/**
 * Checks whether a particular experiment variation is in the liveCommitLayer and is not archived
 * @param {Object} variation
 * @param {Number} experimentId
 * @param {Array} liveCommitLayerExperiments
 * @returns {Boolean} experimentLive
 */
export function isVariationPublishedActive(
  variation,
  experimentId,
  liveCommitLayerExperiments,
) {
  let variationLive = false;
  _.each(liveCommitLayerExperiments, experiment => {
    if (experiment.id === experimentId) {
      _.each(experiment.variations, liveVariation => {
        if (
          variation.variation_id === liveVariation.variation_id &&
          !liveVariation.archived
        ) {
          variationLive = true;
        }
      });
    }
  });
  return variationLive;
}

/**
 * Set weight on variations based on
 * @param {Object} variations
 * @return {Object} Updated variations
 */
export function setVariationWeightsBasedOnPercentage(variations) {
  const copyOfVariations = cloneDeep(variations);
  const updatedVariations = _.map(copyOfVariations, variation =>
    _.extend({}, variation, {
      weight: Number((variation.percentage * 100).toFixed()),
    }),
  );
  return updatedVariations;
}

/**
 * As the name suggests this determines if we want to show relevant data on the results page. For now this is supposed to impact
 * only Accelerated Impact experiments as the improvement, confidence intervals, remaining visitors, and statistical significance
 * values do not provide any valuable insights and need to be hidden.
 * @param {Immutable.Map} experiment
 * @returns {Boolean}
 */
export const shouldShowDataOnResultsPage = experiment =>
  !(
    experiment &&
    experiment.get('allocation_policy') ===
      TrafficAllocationPolicyTypes.MAXIMIZE_CONVERSIONS
  );

/**
 * Adds percentage property to variations based on variation weight
 * @param {Immutable.List} variations
 * @returns {Immutable.List} variations
 */
export function addPercentageToVariations(variations) {
  return variations.map(variation =>
    variation.set('percentage', variation.get('weight') / 100),
  );
}

/**
 * Redistribute traffic from a stopped variation by adding to variations proportionally
 * @param {Object} variations
 * @param {Number} weightOfStoppedVariation
 */
export function redistributeStoppedVariationTraffic(
  variations,
  weightOfStoppedVariation,
) {
  const currentTotal = basis.TOTAL_POINTS - weightOfStoppedVariation;
  const multiplier = basis.TOTAL_POINTS / currentTotal;
  let totalAfterRedistribution = 0;
  let nonZeroVariation = null;
  let unarchivedVariation = null;
  variations.forEach(variation => {
    // first give preference to non-zero variations
    delete variation.percentage;

    if (!variation.archived && variation.weight !== 0) {
      variation.weight = Number((variation.weight * multiplier).toFixed());
      totalAfterRedistribution += variation.weight;
      nonZeroVariation = variation;
    } else if (!variation.archived) {
      // mark a live variation (even if it has 0 weight) in case it's the only unarchived variation
      unarchivedVariation = variation;
    }
  });
  // in case there's a leftover
  const leftover = basis.TOTAL_POINTS - totalAfterRedistribution;
  if (nonZeroVariation) {
    nonZeroVariation.weight += leftover;
  } else if (unarchivedVariation) {
    unarchivedVariation.weight += leftover;
  }
  return variations;
}

/**
 * Redistribute traffic from a paused variation by adding to variations proportionally
 * @param {Immutable.List} variations
 * @param {Number} weightOfPausedVariation
 *
 * @returns {Immutable.List}
 */
export function redistributePausedVariationTraffic(
  variations,
  weightOfPausedVariation,
) {
  const currentTotal = basis.TOTAL_POINTS - weightOfPausedVariation;
  const multiplier = basis.TOTAL_POINTS / currentTotal;
  let totalAfterRedistribution = 0;
  let nonZeroVariationIndex;
  variations = variations.map((variation, index) => {
    // first give preference to non-zero variations
    variation = variation.delete('percentage');
    if (
      variation.get('status') !== enums.VariationStatus.PAUSED &&
      variation.get('weight') !== 0
    ) {
      variation = variation.set(
        'weight',
        Number((variation.get('weight') * multiplier).toFixed()),
      );
      totalAfterRedistribution += variation.get('weight');
      nonZeroVariationIndex = index;
    }
    return variation;
  });
  // in case there's a leftover
  const leftover = basis.TOTAL_POINTS - totalAfterRedistribution;
  if (!_.isUndefined(nonZeroVariationIndex)) {
    let nonZeroVariation = variations.get(nonZeroVariationIndex);
    nonZeroVariation = nonZeroVariation.set(
      'weight',
      nonZeroVariation.get('weight') + leftover,
    );
    variations = variations.set(nonZeroVariationIndex, nonZeroVariation);
  }
  return variations;
}

/**
 * Redistribute traffic to a resumed variation by subtracting from other variations proportionally
 * @param {Number} resumedVariationId
 * @param {Immutable.List} variations
 *
 * @returns {Immutable.List}
 */
export function redistributeResumedVariationTraffic(
  resumedVariationId,
  variations,
) {
  const liveVariations = variations.filter(
    variation =>
      (variation.get('status') !== enums.VariationStatus.PAUSED &&
        variation.get('weight') !== 0) ||
      variation.get('variation_id') === resumedVariationId,
  );
  const liveVariationsCount = liveVariations.size;
  const multiplier = (liveVariationsCount - 1) / liveVariationsCount;
  let totalAfterRedistribution = 0;
  variations = variations.map(variation => {
    variation = variation.delete('percentage');
    if (variation.get('variation_id') === resumedVariationId) {
      variation = variation.set(
        'weight',
        Number(((1 / liveVariationsCount) * basis.TOTAL_POINTS).toFixed()),
      );
    } else {
      variation = variation.set(
        'weight',
        Number((variation.get('weight') * multiplier).toFixed()),
      );
    }
    totalAfterRedistribution += variation.get('weight');
    return variation;
  });
  // in case there's a leftover
  const leftover = basis.TOTAL_POINTS - totalAfterRedistribution;
  if (liveVariations.size && leftover) {
    const variationIndexToUpdate = variations.findIndex(
      variation =>
        variation.get('status') !== enums.VariationStatus.PAUSED &&
        variation.get('weight') !== 0,
    );
    variations = variations.setIn(
      [variationIndexToUpdate, 'weight'],
      variations.getIn([variationIndexToUpdate, 'weight']) + leftover,
    );
  }
  return variations;
}

/**
 * Redistribute traffic to an unstopped variation by subtracting from other variations proportionally
 * @param {Number} restoredVariationId
 * @param {Object} variations
 */
export function redistributeRestoredVariationTraffic(
  restoredVariationId,
  variations,
) {
  const liveVariations = _.filter(
    variations,
    variation =>
      (!variation.archived && variation.weight !== 0) ||
      variation.variation_id === restoredVariationId,
  );
  const liveVariationsCount = liveVariations.length;
  const multiplier = (liveVariationsCount - 1) / liveVariationsCount;
  let totalAfterRedistribution = 0;
  liveVariations.forEach(variation => {
    delete variation.percentage;
    if (variation.variation_id === restoredVariationId) {
      variation.weight = Number(
        ((1 / liveVariationsCount) * basis.TOTAL_POINTS).toFixed(),
      );
    } else {
      variation.weight = Number((variation.weight * multiplier).toFixed());
    }
    totalAfterRedistribution += variation.weight;
  });
  // in case there's a leftover
  const leftover = basis.TOTAL_POINTS - totalAfterRedistribution;
  if (liveVariations[0]) {
    liveVariations[0].weight += leftover;
  }
  return variations;
}

/**
 * Redistributes values evenly based on existing values that are 'relatively' even
 * (ie. [33.33, 33.33, 33.34])
 * @param {Array} originalValues
 * @return {Array} redistributedValues
 */
export function redistributeEvenly(originalValues) {
  let newValue = 0;
  let newValuesTotal = 0;
  const redistributedValues = [];
  const VALUES_COUNT = originalValues.length;

  // Math logic that takes old value and produces new value
  _.each(originalValues, (value, index) => {
    if (VALUES_COUNT - 1 === index) {
      newValue = Number((basis.TOTAL_POINTS - newValuesTotal).toFixed());
    } else {
      newValue = Number((basis.TOTAL_POINTS / VALUES_COUNT).toFixed());
    }
    redistributedValues.push(newValue);
    newValuesTotal += newValue;
  });

  return redistributedValues;
}

/**
 * Redistributes values unevenly based on existing values that are uneven
 * @param {Array} originalValues
 * @return {Array} redistributedValues
 */
export function redistributeUnevenly(originalValues) {
  let newValue = 0;
  let newValuesTotal = 0;
  const redistributedValues = [];
  let nonZeroValuesCount = 0;
  let zeroValuesCount = 0;
  const nonZeroValues = [];
  const VALUES_COUNT = originalValues.length;

  _.each(originalValues, value => {
    if (!value) {
      zeroValuesCount++;
    } else {
      nonZeroValuesCount++;
      nonZeroValues.push(value);
    }
  });

  const multiplier = nonZeroValuesCount / VALUES_COUNT;

  // Math logic that takes old value and produces new value
  _.each(originalValues, (value, index) => {
    if (VALUES_COUNT - 1 === index) {
      newValue = Number((basis.TOTAL_POINTS - newValuesTotal).toFixed());
    } else if (value) {
      newValue = Number((value * multiplier).toFixed());
    } else {
      newValue = Number(
        ((basis.TOTAL_POINTS - newValuesTotal) / zeroValuesCount).toFixed(),
      );
    }
    redistributedValues.push(newValue);
    newValuesTotal += newValue;
  });

  return redistributedValues;
}

/**
 * Redistributes traffic to an added variation. This function will assign a
 * new value to the 'weight' property of each variation object in the argument
 * array, unless that variation is stopped (has archived: true).
 * @param {Array} variations with 'weight' and 'archived' properties
 * @return {Array} Returns the same input array of variations, after having
 * assigned new weights to non-stopped variations in that array
 */
export function redistributeWithNewVariationTraffic(variations) {
  // TODO(amy): make redistribution EVEN better by making floating decimals even more distributed. JIRA CM-180.

  // Handle scenario when there's only one variation in the list. In this scenario,
  // the single variation will always end up with a weight of 10000.
  const liveVariations =
    variations.length === 1
      ? variations
      : _.filter(
          variations,
          variation => !variation.archived && variation.weight !== 0,
        );
  let weights = _.map(liveVariations, variation => variation.weight);
  const originalWeights = weights.slice(0, weights.length - 1);
  const SIMILARITY_THRESHOLD_LOW_END = liveVariations[0].weight - 3;
  const SIMILARITY_THRESHOLD_HIGH_END = liveVariations[0].weight + 3;

  // Note: Checks whether each weight value is 'relatively' similar to one another or within a similarity threshold.
  // This accounts for scenarios like 33.33%, 33.33%, 33.34%, where when adding a variation,
  // instead of an output of 25%, 25%, 25.01%, 24.99%, we now get 25%, 25%, 25%, 25%.
  const similarValues = _.every(
    originalWeights,
    weight =>
      SIMILARITY_THRESHOLD_LOW_END <= weight &&
      weight <= SIMILARITY_THRESHOLD_HIGH_END,
  );

  // If original values are 'relatively' similar, redistribute exactly evenly when adding a new variation, else use different logic.
  if (similarValues) {
    weights = this.redistributeEvenly(weights);
  } else {
    weights = this.redistributeUnevenly(weights);
  }

  _.each(liveVariations, (variation, index) => {
    variation.weight = weights[index];
  });

  return variations;
}

/**
 * Wrapper around the original redistributeWithNewVariationTraffic; accepts an Immutable.Map
 * instead of a POJO so we don't need to do the conversion every time we call it.
 * @param {Immutable.List} variations
 * @return {Immutable.List} variations with redistributed traffic
 */
export function redistributeWithNewVariationTrafficImmutable(variations) {
  // Handle scenario when there's only one variation in the list. In this scenario,
  // the single variation will always end up with a weight of 10000.
  // Add an index property to the variation so we can apply the weight changes later, leaving paused
  // variations intact
  let liveVariations =
    variations.size === 1
      ? variations.setIn([0, 'index'], 0)
      : variations
          .map((variation, index) => variation.set('index', index))
          .filter(
            variation =>
              !variation.get('archived') && variation.get('weight') !== 0,
          );
  let weights = liveVariations.map(variation => variation.get('weight'));
  const SIMILARITY_THRESHOLD_LOW_END = liveVariations.getIn([0, 'weight']) - 3;
  const SIMILARITY_THRESHOLD_HIGH_END = liveVariations.getIn([0, 'weight']) + 3;

  // Note: Checks whether each weight value is 'relatively' similar to one another or within a similarity threshold.
  // This accounts for scenarios like 33.33%, 33.33%, 33.34%, where when adding a variation,
  // instead of an output of 25%, 25%, 25.01%, 24.99%, we now get 25%, 25%, 25%, 25%.
  const originalWeights = weights.slice(0, weights.size - 1);
  const areValuesSimilar = originalWeights.every(
    weight =>
      SIMILARITY_THRESHOLD_LOW_END <= weight &&
      weight <= SIMILARITY_THRESHOLD_HIGH_END,
  );

  // If original values are 'relatively' similar, redistribute exactly evenly when adding a new variation, else use different logic.
  if (areValuesSimilar) {
    weights = this.redistributeEvenly(weights.toJS());
  } else {
    weights = this.redistributeUnevenly(weights.toJS());
  }

  liveVariations = liveVariations.map((variation, index) =>
    variation.set('weight', weights[index]),
  );
  return variations.map((variation, index) => {
    const matchingLiveVariation = liveVariations.find(
      liveVariation => liveVariation.get('index') === index,
    );
    variation = variation.delete('index');
    if (matchingLiveVariation) {
      return variation.set('weight', matchingLiveVariation.get('weight'));
    }
    return variation;
  });
}

/**
 * Return the names of all fields in all widget changes in all variations in
 * the argument experiments
 * @param {Array} layerExperiments
 * @return {Array}
 */
export function getFieldNamesFromWidgetChanges(layerExperiments) {
  let fieldNames = [];
  _.each(layerExperiments, layerExperiment => {
    _.each(layerExperiment.variations, variation => {
      _.each(variation.actions, action => {
        _.each(action.changes, change => {
          if (change.type === enums.ChangeTypes.WIDGET) {
            fieldNames = fieldNames.concat(_.keys(change.config));
          }
        });
      });
    });
  });
  return fieldNames;
}

/**
 * Given a list of variations in an experiment, return a suggested name for a
 * new variation in that experiment
 * @param {Immutable.List} variations
 * @return {String}
 */
export function suggestedNewVariationName(variations) {
  let highestVariationNumber = variations
    .filter(
      variation =>
        variation
          .get('name')
          .toUpperCase()
          .includes('VARIATION #') &&
        !Number.isNaN(variation.get('name').substr(11)),
    )
    .map(variation => Number(variation.get('name').substr(11)))
    .max();

  // For cases where they didn't use names following the "Variation #" pattern, just use the number of variations
  if (!highestVariationNumber) {
    highestVariationNumber = variations.size;
  }
  return tr('Variation #{0}', highestVariationNumber + 1);
}

/**
 * Given a list of variations that all exist in the same layer experiment (or multivariate
 * destination layer experiment), return a list of the same variations with additional
 * boolean properties describing what actions can be taken on them. The allowed actions
 * are based on permissions, published experiment state, local experiment state, and layer
 * policy.
 *
 * The properties added are canDuplicate, canRename, canDelete, canStop, and canRestore.
 *
 * NB: For multivariate destination layer experiments, we NEVER allow customers to delete
 * variations.
 *
 * @param {Immutable.List} variations
 * @param {Boolean} canUpdateLayer - Represents whether the current project has
 *                  permission to update the layer argument variations
 * @param {Immutable.Map|null} liveExperiment - The live copy of the
 *                             experiment containing the variations, or undefined
 *                             if the experiment containing the variations is not live
 * @param {Boolean} isMultivariateTestLayer - Represents whether the layer's policy is multivariate.
 * @param {Boolean} isPersonalizationLayer - Represents whether the layer's is personalization.
 * @param trafficAllocationPolicy current traffic allocation policy
 * @return {Immutable.List}
 */
export function addVariationModificationInfo(
  variations,
  canUpdateLayer,
  liveExperiment,
  isMultivariateTestLayer = false,
  isPersonalizationLayer = false,
  trafficAllocationPolicy,
) {
  const multipleLiveVariations =
    variations.count(variation => !variation.get('archived')) > 1;
  let liveVariationsById = toImmutable({});
  if (liveExperiment) {
    liveVariationsById = liveExperiment
      .get('variations')
      .reduce(
        (byIdMap, liveVariation) =>
          byIdMap.set(liveVariation.get('variation_id'), liveVariation),
        liveVariationsById,
      );
  }

  const totalVariations = isPersonalizationLayer
    ? variations.size + 1
    : variations.size;
  const canDeleteVariation = allocationPolicyAndNumberOfVariationsAreValid(
    trafficAllocationPolicy,
    totalVariations - 1,
  );

  return variations.map(variation => {
    const liveCopyVariation = liveVariationsById.get(
      variation.get('variation_id'),
    );
    const isPublished = !_.isUndefined(liveCopyVariation);
    const isPublishedActive = isPublished && !liveCopyVariation.get('archived');

    const canDelete =
      !isMultivariateTestLayer &&
      multipleLiveVariations &&
      !isPublished &&
      canUpdateLayer &&
      canDeleteVariation;
    const canStop =
      multipleLiveVariations &&
      !variation.get('archived') &&
      isPublishedActive &&
      canUpdateLayer;
    const canRestore =
      canUpdateLayer && variation.get('archived') && isPublishedActive;

    return variation.merge({
      canDuplicate: canUpdateLayer,
      canRename: canUpdateLayer,
      canDelete,
      canStop,
      canRestore,
    });
  });
}

/**
 * Basic helper function to take a policy as stored in GAE and get the label that we want to display wherever we want to notify
 * the user about which policy applies to the experiment
 * @param {Immutable.Map} experiment
 * @returns {String}
 */
export const getTrafficAllocationLabelFromExperimentPolicy = experiment => {
  if (!experiment || !experiment.get('allocation_policy')) {
    return 'Manual';
  }

  const foundItem = TrafficAllocationPolicyItems.find(item =>
    item.test.startsWith(experiment.get('allocation_policy')),
  );

  if (foundItem) {
    return foundItem.label;
  }

  return 'Manual';
};

/**
 * Helper function that returns the status of the variation within the context of traffic allocation
 * @param {Immutable.Map} variation
 * @param {Boolean} isPublishedAndActive
 * @return {String}
 */
export function getTrafficAllocationVariationStatus(
  variation,
  isPublishedAndActive,
) {
  let status;
  if (variation.get('archived') && isPublishedAndActive) {
    status = TrafficAllocationVariationStatuses.STOPPING;
  } else if (variation.get('archived') && !isPublishedAndActive) {
    status = TrafficAllocationVariationStatuses.STOPPED;
  } else if (!variation.get('archived')) {
    status = TrafficAllocationVariationStatuses.LIVE;
  }
  return status;
}

/**
 * Returns layer experiments filtered by a given status
 * @param {Immutable.Map} layerExperiments
 * @param {string} status
 * @return {Immutable.Map}
 */
export function filterLayerExperimentsByStatus(layerExperiments, status) {
  let filter;
  switch (status) {
    case enums.statusFilter.ACTIVE:
      filter = layer => layer.get('status') !== enums.status.ARCHIVED;
      break;
    case enums.statusFilter.ARCHIVED:
      filter = layer => layer.get('status') === enums.status.ARCHIVED;
      break;
    case enums.statusFilter.CONCLUDED:
      filter = layer => layer.get('status') === enums.status.CONCLUDED;
      break;
    case enums.statusFilter.PAUSED:
      filter = layer => layer.get('status') === enums.status.PAUSED;
      break;
    case enums.statusFilter.RUNNING:
      filter = layer =>
        layer.get('actual_status') === enums.ActualStatus.RUNNING;
      break;
    case enums.statusFilter.NOT_STARTED:
      filter = layer =>
        layer.get('actual_status') === enums.ActualStatus.NOT_STARTED;
      break;
    default:
      break;
  }
  return filter ? layerExperiments.filter(filter) : layerExperiments;
}

/**
 * This function formats the variations provided from the (AB|MVT) traffic allocation component to have the states
 * required to determine how they can be edited from within that component.
 * @param {Immutable.List} variations
 * @param {Boolean} canUpdateLayer
 * @param {Number} currentExperimentId
 * @param {Immutable.List} liveExperiments
 * @param {Boolean} isMultivariateTestLayer
 * @return {Immutable.List}
 */
export function formatTrafficAllocationVariations(
  variations,
  canUpdateLayer,
  currentExperimentId,
  liveExperiments,
  isMultivariateTestLayer = false,
) {
  const liveExperiment = liveExperiments.find(
    liveCommitExperiment =>
      liveCommitExperiment.get('id') === currentExperimentId,
  );

  return this.addVariationModificationInfo(
    variations,
    canUpdateLayer,
    liveExperiment,
    isMultivariateTestLayer,
  ).map(variation => {
    const isPublishedAndActive = this.isVariationPublishedActive(
      variation.toJS(),
      currentExperimentId,
      liveExperiments.toJS(),
    );
    const isStopped = variation.get('archived') && !isPublishedAndActive;
    const status = this.getTrafficAllocationVariationStatus(
      variation,
      isPublishedAndActive,
    );

    // This is a variation wrapper. When communicating with API please use the variation inside.
    return toImmutable({
      variation,
      percentage: variation.get('weight') / 100,
      canDelete: variation.get('canDelete'),
      canStop: variation.get('canStop'),
      canRestore: variation.get('canRestore'),
      isStopped,
      status,
    });
  });
}

/**
 * For Layer Experiments with a multivariate_traffic_policy of Partial Factorial, we want to let customers
 * stop variations right out of the gate, even if the experiment has never started before.
 * @param {Immutable.List} variations
 * @param {Boolean} canUpdateLayer
 * @return {Immutable.List}
 */
export function formatTrafficAllocationVariationsForUnstartedPartialFactorial(
  variations,
  canUpdateLayer,
) {
  return variations.map(variation =>
    toImmutable({
      variation,
      percentage: variation.get('weight') / 100,
      canDelete: false,
      canStop: canUpdateLayer && !variation.get('archived'),
      canRestore: canUpdateLayer && variation.get('archived'),
      isStopped: false,
    }),
  );
}
/**
 * Returns true if there is a start time set on the experiment for the future
 * according to start_time and time_zone
 * @param {Immutable.Map} experiment - the experiment object in question
 * @return {Boolean} indicating whether there is a start time scheduled in the future
 */
export function hasFutureStartScheduled(experiment) {
  if (experiment) {
    const startTime = experiment.getIn(['schedule', 'start_time']);
    const timeZone = experiment.getIn(['schedule', 'time_zone']);
    if (startTime && timeZone) {
      return ScheduleFns.isTimeInFuture(startTime, timeZone);
    }
  }
  return false;
}
/**
 * Returns true if there is a start time set on the experiment for the future
 * according to start_time, time_zone and timezone_name
 * @param {Immutable.Map} experiment - the experiment object in question
 * @return {Boolean} indicating whether there is a start time scheduled in the future
 */
export function determineIfStartScheduled(experiment) {
  if (experiment.getIn(['schedule', 'timezone_name'])) {
    return experiment.getIn(['schedule', 'start_time']);
  }
  return hasFutureStartScheduled(experiment);
}
/**
 * Returns true if there is a stop time set on the experiment for the future
 * according to stop_time and time_zone
 * @param {Immutable.Map} experiment - the experiment object in question
 * @return {Boolean} indicating whether there is a stop time scheduled in the future
 */
export function hasFutureStopScheduled(experiment) {
  if (experiment) {
    const stopTime = experiment.getIn(['schedule', 'stop_time']);
    const timeZone = experiment.getIn(['schedule', 'time_zone']);
    if (stopTime && timeZone) {
      return ScheduleFns.isTimeInFuture(stopTime, timeZone);
    }
  }
  return false;
}
/**
 * Returns true if there is a stop time set on the experiment for the future
 * according to stop_time, time_zone and timezone_name
 * @param {Immutable.Map} experiment - the experiment object in question
 * @return {Boolean} indicating whether there is a stop time scheduled in the future
 */
export function determineIfStopScheduled(experiment) {
  if (experiment.getIn(['schedule', 'timezone_name'])) {
    return experiment.getIn(['schedule', 'stop_time']);
  }
  return hasFutureStopScheduled(experiment);
}
/**
 * Returns timezone name of scheudle of experiment if experiment is scheduled
 * to start or stop in future
 * @param {Immutable.Map} experiment - the experiment object in question
 * @return {String} timezone name of scheudle of experiment
 */
export function getTimeZoneString(experiment) {
  let experimentTimezone;
  experimentTimezone = experiment.getIn(['schedule', 'time_zone']);
  if (experiment.getIn(['schedule', 'timezone_name'])) {
    experimentTimezone = experiment
      .getIn(['schedule', 'timezone_name'])
      .replace('_', ' ');
  }
  return experimentTimezone;
}
/**
 * Returns true if there is a start or stop time set on the experiment
 * @param {Immutable.Map} experiment - the experiment object in question
 * @return {Boolean}
 */
export function hasSchedule(experiment) {
  if (isImmutable(experiment)) {
    return (
      experiment &&
      (experiment.getIn(['schedule', 'start_time']) ||
        experiment.getIn(['schedule', 'stop_time']))
    );
  }
  return (
    experiment &&
    (experiment.schedule.start_time || experiment.schedule.stop_time)
  );
}

/**
 * Get audience conditions for the argument experiment
 * @param {Immutable.Map} experiment
 * @return {String}
 * TODO: Change the backend computed property to do this
 */
export function getAudienceConditions(experiment) {
  const audienceConditions = experiment.get('audience_conditions');
  if (audienceConditions === null) {
    // null for audience_conditions means 'OR all the audiences in audience_ids'
    // This happens when an experiment is first created as part of campaign
    // creation. audience_conditions is computed on the backend using
    // audience_condition_structure and audience_ids. It seems that
    // for experiences created as part of campaign creation,
    // audience_condition_structure starts off as None, and does not get a value
    // unless you subsequently edit it and save using the experience dialog.
    // To be able to compare audiences between live & working copies of an
    // experiment, we have to normalize the representation of audiences we're
    // using.
    const idConditions = experiment
      .get('audience_ids')
      .map(audienceId => `{"audience_id": ${audienceId}}`)
      .join(', ');
    return `["or", ${idConditions}]`;
  }
  return audienceConditions;
}

/**
 * Recursively compile an array of the Audience IDs used in audienceConditions
 *
 * @param audienceConditions {Immutable.List}
 * @returns {Array}
 *  Array of Audience IDs
 */
export function deriveAudienceIdsFromAudienceConditions(audienceConditions) {
  if (!audienceConditions || !audienceConditions.size) {
    return [];
  }
  const reducerFn = iterable =>
    iterable.reduce((accum, item) => {
      if (item instanceof Immutable.List) {
        return accum.concat(reducerFn(item));
      }
      if (item instanceof Immutable.Map && item.get('audience_id')) {
        return accum.concat(item.get('audience_id'));
      }
      return accum;
    }, []);
  return reducerFn(audienceConditions);
}

/**
 * Recursively compile an array of the Audience IDs used in audienceConditions
 *
 * @param audienceConditions {Array}
 * @returns {Array}
 *  Array of Audience IDs
 */
export function deriveAudienceIdsFromAudienceConditionsPOJO(
  audienceConditions,
) {
  if (!audienceConditions || !audienceConditions.length) {
    return [];
  }
  const reducerFn = iterable =>
    iterable.reduce((accum, item) => {
      if (Array.isArray(item)) {
        return accum.concat(reducerFn(item));
      }
      if (typeof item === 'object' && item !== null && item.audience_id) {
        return accum.concat(item.audience_id);
      }
      return accum;
    }, []);
  return reducerFn(audienceConditions);
}

/**
 * Returns the validity of a LayerExperiment Change.
 * @param {Immutable.Map} layerExperimentChange - the change to validate
 * @return {boolean}
 */
export function validateLayerExperimentChange(layerExperimentChange) {
  // layerExperimentChange must have an ID
  if (!layerExperimentChange.get('id', false)) {
    return false;
  }

  // layerExperimentChange cannot have a selector that is an empty string
  return !(
    layerExperimentChange.has('selector') &&
    layerExperimentChange.get('selector') === ''
  );
}

export function getLayerExperiementsForAudience(layerExperiements, audienceId) {
  return _.filter(layerExperiements, layerExperiment => {
    let containsAudienceId = false;

    if (_.includes(layerExperiment.audience_ids, audienceId)) {
      containsAudienceId = true;
    } else if (layerExperiment.audience_conditions) {
      const audienceConditions = JSON.parse(
        layerExperiment.audience_conditions,
      );
      containsAudienceId = !!_.find(
        _.flattenDeep(audienceConditions),
        condition => condition.audience_id === audienceId,
      );
    }

    return containsAudienceId;
  });
}

/**
 * Returns validity of traffic allocation policy and potential absence of primary metric
 * Invalid if MINIMIZE_TIME policy and no primary metric
 *
 * @param {String} trafficAllocationPolicy
 * @param {Object} primaryMetric
 */
export function allocationPolicyAndAbsenceOfPrimaryMetricAreValid(
  trafficAllocationPolicy,
  primaryMetric,
) {
  return (
    trafficAllocationPolicy !== TrafficAllocationPolicyTypes.MINIMIZE_TIME ||
    !!primaryMetric
  );
}

/**
 * Returns validity of traffic allocation policy and primary metric combination
 * Invalid if MINIMIZE_TIME policy and numeric primary metric for visitor scope
 *
 * @param {String} trafficAllocationPolicy
 * @param {Object} primaryMetric
 */
export function allocationPolicyAndSelectedPrimaryMetricAreValid(
  trafficAllocationPolicy,
  primaryMetric,
) {
  return !(
    trafficAllocationPolicy === TrafficAllocationPolicyTypes.MINIMIZE_TIME &&
    primaryMetric &&
    primaryMetric.get('scope') === scope.VISITOR &&
    (primaryMetric.get('aggregator') === aggregator.COUNT ||
      primaryMetric.get('aggregator') === aggregator.SUM)
  );
}

/**
 * Returns validity of traffic allocation policy and number of variations combination
 * Must have at least the VARIATION_REQUIREMENT for MAXIMIZE_CONVERSION or MINIMIZE_TIME.
 *
 * @param {String} trafficAllocationPolicy
 * @param {Number} numVariationsTotal (including Original)
 */
export function allocationPolicyAndNumberOfVariationsAreValid(
  trafficAllocationPolicy,
  numVariationsTotal,
) {
  if (trafficAllocationPolicy === TrafficAllocationPolicyTypes.MINIMIZE_TIME) {
    return numVariationsTotal >= MINIMIZE_TIME_VARIATION_REQUIREMENT;
  }

  if (
    trafficAllocationPolicy ===
    TrafficAllocationPolicyTypes.MAXIMIZE_CONVERSIONS
  ) {
    return numVariationsTotal >= MAXIMIZE_CONVERSION_VARIATION_REQUIREMENT;
  }

  return true;
}

/**
 * Returns true if the layerExperiment's multivariate_traffic_policy
 * is full_factorial
 * @param {Object|Immutable.Map} layerExperiment
 * @returns {boolean}
 */
export function hasFullFactorialTrafficPolicy(layerExperiment) {
  if (!layerExperiment) {
    return false;
  }
  const trafficPolicy = isImmutable(layerExperiment)
    ? layerExperiment.get('multivariate_traffic_policy')
    : layerExperiment.multivariate_traffic_policy;

  return trafficPolicy === enums.multivariateTrafficPolicies.FULL_FACTORIAL;
}

/**
 * Returns true if the layerExperiment's multivariate_traffic_policy
 * is partial_factorial
 * @param {Object|Immutable.Map} layerExperiment
 * @returns {boolean}
 */
export function hasPartialFactorialTrafficPolicy(layerExperiment) {
  if (!layerExperiment) {
    return false;
  }
  const trafficPolicy = isImmutable(layerExperiment)
    ? layerExperiment.get('multivariate_traffic_policy')
    : layerExperiment.multivariate_traffic_policy;

  return trafficPolicy === enums.multivariateTrafficPolicies.PARTIAL_FACTORIAL;
}

/**
 *  Returns true if the experiment has the argument status in any environment
 * @param {Immutable.Map} experiment
 * @param {String} status
 * @return {Boolean}
 */
export function hasStatusInAnyEnvironment(experiment, status) {
  let expEnvsInfo;
  if (experiment) {
    expEnvsInfo = experiment.get('environments', toImmutable({}));
  } else {
    expEnvsInfo = toImmutable({});
  }
  return expEnvsInfo.some(
    expEnvInfo => expEnvInfo && expEnvInfo.get('status') === status,
  );
}

/**
 *  Returns true if the experiment has the RUNNING status in any environment
 * @param {Immutable.Map} experiment
 * @return {Boolean}
 */
export function isRunningInAnyEnvironment(experiment) {
  return this.hasStatusInAnyEnvironment(
    experiment,
    enums.EnvironmentStatus.RUNNING,
  );
}

/**
 * Returns true if the experiment is a feature test
 * @param {Immutable.Map|Object} experiment
 * @return {Boolean}
 */
export function isFeatureTest(experiment) {
  const policy = isImmutable(experiment)
    ? experiment.get('layer_policy')
    : experiment.layer_policy;

  const featureId = isImmutable(experiment)
    ? experiment.get('feature_flag_id')
    : experiment.feature_flag_id;

  return policy === LayerPolicy.SINGLE_EXPERIMENT && _.isNumber(featureId);
}

/**
 * Returns true if the experiment is a multivariate test
 * @param {Immutable.Map} experiment
 * @return {Boolean}
 */
export function isMultivariateTest(experiment) {
  if (!experiment) {
    return false;
  }
  const policy = isImmutable(experiment)
    ? experiment.get('policy')
    : experiment.policy;

  return policy === LayerPolicy.MULTIVARIATE;
}

/**
 * Returns true if the experiment is a multi-armed bandit
 * @param {Immutable.Map} experiment
 * @return {Boolean}
 */
export function isMultiArmedBandit(experiment) {
  if (!experiment) {
    return false;
  }

  const allocationPolicy = isImmutable(experiment)
    ? experiment.get('allocation_policy')
    : experiment.allocation_policy;

  return allocationPolicy === TrafficAllocationPolicyTypes.MAXIMIZE_CONVERSIONS;
}

/**
 * Returns true if experiment is a stats accelerator experiment (not MAB)
 * @param {Immutable.Map} experiment
 * @return {Boolean}
 */
export function isStatsAcceleratorExperiment(experiment) {
  if (!experiment) {
    return false;
  }

  const allocationPolicy = isImmutable(experiment)
    ? experiment.get('allocation_policy')
    : experiment.allocation_policy;

  return allocationPolicy === TrafficAllocationPolicyTypes.MINIMIZE_TIME;
}

/**
 * Returns human readable experiment type for full stack experiments
 * @param {Immutable.Map} experiment
 * @return {String}
 */
export function getFullStackExperimentTypeHumanReadable(experiment) {
  if (isFeatureTest(experiment)) {
    return humanReadable.FULLSTACK_EXPERIMENT_TYPES.feature;
  }

  if (isMultiArmedBandit(experiment)) {
    return humanReadable.FULLSTACK_EXPERIMENT_TYPES.multiArmedBandit;
  }

  return humanReadable.FULLSTACK_EXPERIMENT_TYPES[
    experiment.get('layer_policy')
  ];
}

export default {
  addAction,
  addPercentageToVariations,
  addVariationModificationInfo,
  allocationPolicyAndNumberOfVariationsAreValid,
  allocationPolicyAndAbsenceOfPrimaryMetricAreValid,
  allocationPolicyAndSelectedPrimaryMetricAreValid,
  changesForViewIdAndVariationIdAndExperimentId,
  calculateTotalPercent,
  cleanActionsJSON,
  cleanAudiencesJSON,
  cloneActions,
  cloneChangeList,
  countAllChangesInVariation,
  createDuplicateName,
  determineIfStartScheduled,
  determineIfStopScheduled,
  deriveAudienceIdsFromAudienceConditions,
  deriveAudienceIdsFromAudienceConditionsPOJO,
  ensureActionInitialized,
  filterLayerExperimentsByStatus,
  formatTrafficAllocationVariations,
  formatTrafficAllocationVariationsForUnstartedPartialFactorial,
  generateGuid,
  getAddedExperimentsByStatus,
  getAudienceConditions,
  getExperimentsByStatus,
  getFieldNamesFromWidgetChanges,
  getFullStackExperimentTypeHumanReadable,
  getLayerExperiementsForAudience,
  getTimeZoneString,
  getTrafficAllocationLabelFromExperimentPolicy,
  getTrafficAllocationVariationStatus,
  getViewIdsInVariation,
  hasFullFactorialTrafficPolicy,
  hasFutureStartScheduled,
  hasFutureStopScheduled,
  hasSchedule,
  hasPartialFactorialTrafficPolicy,
  hasStatusInAnyEnvironment,
  isExperimentLive,
  isFeatureTest,
  isMultiArmedBandit,
  isMultivariateTest,
  isRunningInAnyEnvironment,
  isStatsAcceleratorExperiment,
  isVariationPublishedActive,
  redistributeEvenly,
  redistributePausedVariationTraffic,
  redistributeResumedVariationTraffic,
  redistributeRestoredVariationTraffic,
  redistributeStoppedVariationTraffic,
  redistributeUnevenly,
  redistributeWithNewVariationTraffic,
  redistributeWithNewVariationTrafficImmutable,
  removeActions,
  removeViews,
  setVariationWeightsBasedOnPercentage,
  shouldShowDataOnResultsPage,
  suggestedNewVariationName,
  validateLayerExperiment,
  validateLayerExperimentChange,
  validateVariationName,
};

import Immutable from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';

import { Table } from 'optimizely-oui';

import RowActions from './feature_table_row_actions';

const shortdate = require('optly/filters/shortdate');

const FeatureTableRow = props => {
  const onEditFeature = () => {
    props.editFeature(props.feature);
  };

  return (
    <Table.TR>
      <Table.TD testSection={`feature-table-row-api-name-${props.rowIndex}`}>
        <a
          className="epsilon cursor--pointer"
          data-test-section={`feature-table-row-api-name-button-${props.rowIndex}`}
          onClick={onEditFeature}>
          {props.feature.get('api_name')}
        </a>
        <div className="muted micro">{props.feature.get('description')}</div>
      </Table.TD>
      <Table.TD>{shortdate(props.feature.get('created'))}</Table.TD>
      <Table.TD>{shortdate(props.feature.get('last_modified'))}</Table.TD>
      <Table.TD isNumerical={true}>
        <RowActions
          archive={props.archive}
          canManageFeatureFlags={props.canManageFeatureFlags}
          currentProjectRunningExperiments={
            props.currentProjectRunningExperiments
          }
          feature={props.feature}
          rowIndex={props.rowIndex}
          unarchive={props.unarchive}
        />
      </Table.TD>
    </Table.TR>
  );
};

FeatureTableRow.propTypes = {
  archive: PropTypes.func.isRequired,
  canManageFeatureFlags: PropTypes.bool.isRequired,
  currentProjectRunningExperiments: PropTypes.instanceOf(Immutable.List)
    .isRequired,
  editFeature: PropTypes.func.isRequired,
  feature: PropTypes.instanceOf(Immutable.Map).isRequired,
  rowIndex: PropTypes.number.isRequired,
  unarchive: PropTypes.func.isRequired,
};

export default FeatureTableRow;

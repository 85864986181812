import actions from './actions';
import getters from './getters';

// named exports
export { actions, getters };

// default export
export default {
  actions,
  getters,
};

import ActivityFiltersStore from './activity_filters_store';
import LabsDataStore from './labs_data_store';
import SelectedItemsStore from './selected_items_store';
import TableFiltersStore from './table_filters_store';
import TabsStore from './tabs_store';
import CustomizationStore from './customization_store';

export default {
  activityFilters: ActivityFiltersStore,
  labsData: LabsDataStore,
  selectedItems: SelectedItemsStore,
  tableFilters: TableFiltersStore,
  tabs: TabsStore,
  customization: CustomizationStore,
};

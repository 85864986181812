import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import { toImmutable } from 'optly/immutable';

import definition from './entity_definition';
import enums from './enums';
import fns from './fns';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

export const asList = [entityCache, projects => projects.toList()];

export const activeWebProjects = [
  entityCache,
  projects =>
    projects.filter(
      project =>
        !project.get('archived') &&
        project.get('project_status') !== enums.project_status.ARCHIVED &&
        fns.isWebProject(project),
    ),
];

export const activeFXProjects = [
  entityCache,
  projects =>
    projects.filter(
      project =>
        !project.get('archived') &&
        project.get('project_status') !== enums.project_status.ARCHIVED &&
        fns.isFullStackProject(project),
    ),
];

export const fullstackProjects = [
  entityCache,
  projects => projects.filter(fns.isFullStackProject),
];

export const projectsWithoutDcpService = [
  entityCache,
  entities =>
    toImmutable({}).withMutations(map => {
      entities.forEach(entity => {
        if (
          !entity.get('dcp_service_id') &&
          entity.get('project_status') !== enums.project_status.ARCHIVED
        ) {
          map.set(entity.get('id'), entity.toJS());
        }
      });
    }),
];

export default {
  ...baseEntityGetters,
  activeFXProjects,
  activeWebProjects,
  asList,
  fullstackProjects,
  projectsWithoutDcpService,
};

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import fns from './fns';
import entityDef from './entity_definition';

const baseEntityGetters = createEntityGetters(entityDef);
export const { byId } = baseEntityGetters;

/**
 * Return the current adaptive audience conditions status of the given layer_id.
 *    null:   waiting for information
 *    true:   the layer has at least one adaptive audience that has `unavailable` adaptive_audience_status.
 *    false:  the layer has no adaptive audience that has `unavailable` adaptive_audience_status.
 *
 * @param {Number} layerId
 *
 * @return {null, boolean} indicate the status of adaptive audience conditions
 */
export const hasAnyProcessingAdaptiveAudienceConditions = layerId => [
  byId(layerId),
  fns.summaryHasAnyProcessingAdaptiveAudienceConditions,
];

export default {
  ...baseEntityGetters,
  hasAnyProcessingAdaptiveAudienceConditions,
};

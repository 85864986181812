const RestApi = require('optly/modules/rest_api');

/**
 *  Sets up local entity caching for entities associated with a project
 * @param {Number}projectId
 */
exports.setupProjectLocalEntityCache = function(projectId) {
  // setup caching for desired entities
  const entitiesToCache = ['audiences'];
  RestApi.actions.setupLocalEntityCache(
    'project_id',
    projectId,
    entitiesToCache,
  );
};

/**
 * Sets up local entity caching for entities associated with a admin project
 * @param {Number} accountId  -- admin account id
 */

exports.setupAccountLocalEntityCache = function(accountId) {
  const entitiesToCache = ['projects'];
  RestApi.actions.setupLocalEntityCache(
    'account_id',
    accountId,
    entitiesToCache,
  );
};

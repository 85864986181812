const _ = require('lodash');
const enums = require('optly/utils/enums');
const projectActivityEnums = require('optly/modules/entity/project_activity/enums');

const goalTypeMap = enums.GoalTypeName;
const goalTypeEnum = _.invert(enums.GoalType);

/**
 * Takes as input the variables for an activity and outputs the formatted english string for the activity
 * @return {Object} activity
 * @return {String}
 */
module.exports = function(activity) {
  const more_info = activity.more_info;
  let entityName = activity.category;
  let goalTypeString = '';

  if (
    more_info &&
    more_info.category !== projectActivityEnums.categories.EXPERIMENT
  ) {
    if (more_info.category === projectActivityEnums.categories.SECTION) {
      entityName = projectActivityEnums.categories.SECTION;
    } else if (
      more_info.category === projectActivityEnums.categories.VARIATION
    ) {
      entityName = projectActivityEnums.categories.VARIATION;
    }
  }

  if (entityName === projectActivityEnums.categories.GOAL) {
    if (more_info && more_info.goal_type !== null) {
      let goalType = null;

      if (more_info.goal_type.after !== undefined) {
        goalType = more_info.goal_type.after;
      } else {
        goalType = more_info.goal_type;
      }

      if (goalType !== null) {
        goalTypeString = goalTypeMap[goalTypeEnum[goalType]].toLowerCase();
      }
    }
  }

  const messages = {
    experiment: {
      save: tr('saved experiment'),
      create: tr('created experiment'),
      start: tr('started experiment'),
      pause: tr('paused experiment'),
      delete: tr('deleted experiment'),
      archive: tr('archived experiment'),
      unarchive: tr('unarchived experiment'),
      'new goal': tr('added a new goal to experiment'),
      'add saved goal': tr('added a saved goal to experiment'),
      'remove goal': tr('removed a goal from experiment'),
      'reset results': tr('reset results for experiment'),
      update: tr('edited experiment'),
      'schedule changed': tr('changed the schedule for experiment'),
    },
    goal: {
      'add saved goal': tr('added {0} goal', goalTypeString),
      'delete goal': tr('deleted {0} goal', goalTypeString),
      'new goal': tr('created {0} goal', goalTypeString),
      'remove goal': tr('removed {0} goal', goalTypeString),
      'update goal': tr('edited {0} goal', goalTypeString),
    },
    project_visible: {
      update: tr('updated project'),
      create: tr('created project'),
    },
    section: {
      save: tr('saved section'),
      create: tr('created section'),
      start: tr('started section'),
      pause: tr('paused section'),
      delete: tr('deleted section'),
      archive: tr('archived section'),
      unarchive: tr('unarchived section'),
      'new goal': tr('added a new goal to section'),
      'add saved goal': tr('added a saved goal to section'),
      update: tr('edited section'),
    },
    variation: {
      save: tr('saved variation'),
      create: tr('created variation'),
      start: tr('started variation'),
      pause: tr('paused variation'),
      delete: tr('deleted variation'),
      archive: tr('archived variation'),
      unarchive: tr('unarchived variation'),
      'new goal': tr('added a new goal to variation'),
      'add saved goal': tr('added a saved goal to variation'),
      update: tr('edited variation'),
    },
  };

  let activityString = messages[entityName][activity.event];

  if (!activityString) {
    if (activity.event.indexOf('Scheduled changed') !== -1) {
      activityString = messages[entityName]['schedule changed'];
    } else {
      activityString = activity.event;
    }
  }

  return activityString;
};

import React from 'react';

import ui from 'core/ui';
import Router from 'core/router';

import NavConstants from 'optly/services/navigation';
import BundleSplitHelper from 'optly/utils/bundle_split_helper';
import UrlHelper from 'optly/services/url_helper';

import RoutingFns from '../../routing_fns';

const category = 'Project Settings';

/*
 * Routing functions that ensure required dynamic bundles have been fetched
 * We store the returned dynamic bundle module on the function object itself
 */
function ProjectSettingsSection(ctx, next) {
  import(
    /* webpackChunkName: "project-settings-bundle" */
    'bundles/p13n/sections/project_settings'
  )
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(ProjectSettingsSection, module);
    })
    .then(next); // Until we can just return this promise
}

/*
 * Routes for the project settings section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/projects/:proj_id/settings/implementation',
    metadata: { name: 'Project Implementation Settings', category },
    handle: [
      [
        ProjectSettingsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.SETTINGS,
        ),
        RoutingFns.parseProjectId,
        RoutingFns.setActiveTab(
          NavConstants.NavItems.SETTINGS,
          NavConstants.SettingsTabs.IMPLEMENTATION_TAB,
        ),
      ],
      (ctx, next) =>
        ProjectSettingsSection.pages.implementation.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Implementation',
          next,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ProjectSettingsSection.pages.implementation.component />,
        ),
    ],
  },
  {
    match: '/v2/projects/:proj_id/settings/integrations',
    metadata: { name: 'Integrations', category },
    handle: [
      (ctx, next) => {
        // needs to load before ProjectSettingsSection to ensure window.JSHINT available
        BundleSplitHelper.getCodeLintingBundleModules().then(next);
      },
      [
        ProjectSettingsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.SETTINGS,
        ),
        RoutingFns.parseProjectId,
        RoutingFns.fetchIntegrationsTabData,
      ],

      RoutingFns.parseIntegrationFilters,
      (ctx, next) =>
        ProjectSettingsSection.pages.integrations.routingSetup(ctx, next),
      RoutingFns.setActiveTab(
        NavConstants.NavItems.SETTINGS,
        NavConstants.SettingsTabs.INTEGRATIONS_TAB,
      ),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Integrations',
          next,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ProjectSettingsSection.pages.integrations.component />,
        ),
    ],
  },
  {
    match: '/v2/projects/:proj_id/settings/javascript',
    metadata: { name: 'Project JavaScript', category },
    handle: [
      (ctx, next) => {
        // needs to load before ProjectSettingsSection to ensure window.JSHINT available
        BundleSplitHelper.getCodeLintingBundleModules().then(next);
      },
      [
        ProjectSettingsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.SETTINGS,
        ),
        RoutingFns.parseProjectId,
        RoutingFns.setActiveTab(
          NavConstants.NavItems.SETTINGS,
          NavConstants.SettingsTabs.JAVASCRIPT_TAB,
        ),
      ],
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Project JavaScript',
          next,
        }),
      (ctx, next) =>
        ProjectSettingsSection.pages.javascript.routingSetup(ctx, next),
      () => {
        // Settings page component is a wrapper for all settings tabs
        RoutingFns.renderMainRegion(
          <ProjectSettingsSection.pages.javascript.component />,
        );
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/settings/collaborators',
    metadata: { name: 'Collaborator Details', category },
    handle: [
      [
        ProjectSettingsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.SETTINGS,
        ),
        RoutingFns.parseProjectId,
        RoutingFns.fetchCollaboratorsTabData,
      ],
      RoutingFns.parseCollaboratorFilters,
      RoutingFns.setActiveTab(
        NavConstants.NavItems.SETTINGS,
        NavConstants.SettingsTabs.COLLABORATORS_TAB,
      ),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Collaborator Details',
          next,
        }),
      () => {
        RoutingFns.renderMainRegion(
          <ProjectSettingsSection.pages.collaborators.component />,
        );
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/settings/privacy',
    handle: [
      ctx =>
        Router.redirect(
          UrlHelper.projectSettingsImplementation(ctx.params.proj_id),
        ),
    ],
  },
  {
    match: '/v2/projects/:proj_id/settings/labs',
    metadata: { name: 'Labs', category },
    handle: [
      [
        ProjectSettingsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.SETTINGS,
        ),
        RoutingFns.parseProjectId,
        RoutingFns.setActiveTab(
          NavConstants.NavItems.SETTINGS,
          NavConstants.SettingsTabs.LABS_TAB,
        ),
      ],
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Labs',
          next,
        }),
      () => {
        // Settings page component is a wrapper for all settings tabs
        RoutingFns.renderMainRegion(
          <ProjectSettingsSection.pages.labs.component />,
        );
        ui.clearPersistentNotification(
          'personalization-project-disabled-warning-notification',
        );
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/settings/advanced',
    metadata: { name: 'Advanced Project Settings', category },
    handle: [
      [
        ProjectSettingsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.SETTINGS,
        ),
        RoutingFns.parseProjectId,
        RoutingFns.setActiveTab(
          NavConstants.NavItems.SETTINGS,
          NavConstants.SettingsTabs.ADVANCED_TAB,
        ),
      ],
      RoutingFns.disallowAdvancedSettings,
      (ctx, next) =>
        ProjectSettingsSection.pages.advanced.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Advanced',
          next,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ProjectSettingsSection.pages.advanced.component />,
        ),
    ],
  },
  {
    match: '/v2/projects/:proj_id/settings/webhooks',
    metadata: { name: 'Webhooks', category },
    handle: [
      [
        ProjectSettingsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.SETTINGS,
        ),
        RoutingFns.parseProjectId,
        RoutingFns.fetchWebhooksTabData,
      ],
      RoutingFns.setActiveTab(
        NavConstants.NavItems.SETTINGS,
        NavConstants.SettingsTabs.WEBHOOKS_TAB,
      ),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Webhooks',
          next,
        }),
      () => {
        // Settings page component is a wrapper for all settings tabs
        RoutingFns.renderMainRegion(
          <ProjectSettingsSection.pages.webhooks.component />,
        );
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/settings/migration',
    metadata: { name: 'Migration', category },
    handle: [
      [
        ProjectSettingsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.SETTINGS,
        ),
        RoutingFns.parseProjectId,
        RoutingFns.setActiveTab(
          NavConstants.NavItems.SETTINGS,
          NavConstants.SettingsTabs.MIGRATION_TAB,
        ),
      ],
      RoutingFns.disallowMigrationSettings,
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          name: 'Migration',
          next,
        }),
      () => {
        RoutingFns.renderMainRegion(
          <ProjectSettingsSection.pages.migration.component />,
        );
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/settings',
    handle: [
      ctx =>
        Router.redirect(
          UrlHelper.projectSettingsImplementation(ctx.params.proj_id),
        ),
    ],
  },
];

export default routes;

import { actions as EditorIframeActions } from 'bundles/p13n/modules/editor_iframe';
import { enums as HighlighterEnums } from 'optly/modules/highlighter';
import keyboardEnums from 'optly/utils/keyboard_enums';

const EditableSelector = {
  /** ***********************************************************************************
   *
   * NOTICE: Deprecated! This should be used for the Vue Selector Input only, use Editor module actions for other implementations
   *
   * This mixin adds in functionality for editing the selector either by typing it
   * into the selector input textbox or by selecting it from the iframe.
   *
   * Components that use this mixin should define the following properties in their config:
   * {
   *   data: {
   *    activeFrameId: {string}
   *  },
   *   methods: {
   *    setCurrentSelector: {Function},
   *    setIsEditingSelector: {Function},
   *    setElementSelectorEnabled: {Function},
   *    updateSelector: {Function},
   *   }
   * }
   *
   * @author Mike Ng (mike.ng@optimizely.com)
   *************************************************************************************
   */
  methods: {
    /**
     * Apply the new selector that the user typed into the selector text input
     *
     * @param {string} selector
     */
    applySelectorChange(selector) {
      this.updateSelector(selector);
      this.restoreSelectorState(selector);
    },

    /**
     * Handle selector editing via the input textbox
     *
     * @param {object} event
     * @param {string} selector
     */
    handleSelectorUpdate(event, selector) {
      if (!this.isEditingSelector) {
        this.setIsEditingSelector(true);
        this.setCurrentSelector(selector);
      }

      const { keyCode } = event;
      if (keyCode === keyboardEnums.ENTER_KEY) {
        this.applySelectorChange(selector);
      } else if (keyCode === keyboardEnums.ESCAPE_KEY) {
        this.restoreSelectorState(selector);
      }

      EditorIframeActions.unhighlightAllElements(this.activeFrameId);

      // we want to highlight elements as they match the selector that the user is typing in
      if (selector) {
        EditorIframeActions.highlightElement({
          id: this.activeFrameId,
          selector,
          type: HighlighterEnums.IFrameHighlightTypes.HOVERED,
        });
      }
    },

    /**
     * Restore the selector to its original state
     *
     * @param {string} originalSelector
     * @param {number} dataOptlyId
     */
    restoreSelectorState(originalSelector, dataOptlyId) {
      EditorIframeActions.unhighlightAllElements(this.activeFrameId);
      EditorIframeActions.highlightElement({
        id: this.activeFrameId,
        dataOptlyId,
        selector: originalSelector,
        type: HighlighterEnums.IFrameHighlightTypes.SELECTED,
      });
      this.setIsEditingSelector(false);
      this.setCurrentSelector(originalSelector);
    },

    /**
     * Enable/disable the element selector
     *
     * @param {boolean} enabled
     * @param {string} originalSelector
     * @param {number} dataOptlyId
     */
    toggleElementSelection(enabled, originalSelector, dataOptlyId) {
      this.setElementSelectorEnabled(enabled);

      if (enabled) {
        EditorIframeActions.unhighlightElement({
          id: this.activeFrameId,
          dataOptlyId,
          selector: originalSelector,
          type: HighlighterEnums.IFrameHighlightTypes.SELECTED,
        });
      } else {
        this.restoreSelectorState(originalSelector);
      }
    },
  },
};

export default EditableSelector;

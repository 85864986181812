// The numeric/string values below correspond to constants defined in
// src/www/services/mail/categories.CATEGORY_IDS and persisted as
// the EmailSetting.opt_out_category_ids array for each user
export const CATEGORY_MAP = {
  over_limit: 0,
  projected_over_limit: 2,
  account_unfrozen: 4,
  impression_usage: 5,
};

export default {
  CATEGORY_MAP,
};

/**
 * Holds the state of what entities are cached locally
 */
import { Store } from 'nuclear-js';
import Immutable, { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Store({
  getInitialState() {
    /**
     * Mapping of entity => {
     *   entity: String,
     *   localStorageKey: String,
     *   unwatchFn: Function,
     * }
     */
    return toImmutable({});
  },

  initialize() {
    this.on(actionTypes.API_ADD_LOCAL_CACHE_ENTITY, setLocalCacheEntity);
    this.on(actionTypes.API_REMOVE_LOCAL_CACHE_ENTITY, removeLocalCacheEntity);
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.entity
 * @param {Function} payload.unwatchFn
 * @return {Immutable.Map}
 */
function setLocalCacheEntity(state, payload) {
  const { entity } = payload;
  if (state.get(entity)) {
    throw new Error(`Already caching: ${entity}`);
  }

  return state.set(
    entity,
    Immutable.Map({
      entity,
      unwatchFn: payload.unwatchFn,
      localStorageKey: payload.localStorageKey,
      hasData: payload.hasData,
    }),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function removeLocalCacheEntity(state, payload) {
  return state.delete(payload.entity);
}

import { AppEnvironment } from 'optly/utils/enums';
/**
 * Verifier Enums
 */
export default {
  LEVELS: {
    AMAZON: 'amazon',
    CDN: 'cdn',
  },
  POLL_SETTINGS: {
    [AppEnvironment.PRODUCTION]: {
      MAX_ATTEMPTS: 100,
      POLLING_INTERVAL: 3000,
    },
    [AppEnvironment.STAGING]: {
      MAX_ATTEMPTS: 10,
      POLLING_INTERVAL: 3000,
    },
    [AppEnvironment.DEV]: {
      MAX_ATTEMPTS: 10,
      POLLING_INTERVAL: 600,
    },
  },
};

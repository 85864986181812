import PropTypes from 'prop-types';
import React from 'react';

import { ActualStatus as ACTUAL_STATUS } from 'optly/modules/entity/layer_experiment/enums';
import { STATUS_BY_ACTUAL_STATUS as HR_STATUS_TYPES } from 'optly/modules/entity/layer_experiment/human_readable';

/**
 * Returns the human readable status text for the argument experiment
 * @param {Immutable.Map} experiment
 * @return {String}
 */
function readableStatus(status, isLaunched) {
  // Support legacy layer_experiment.is_launched property
  // TODO: remove once is_launched is no longer supported - OASIS-2464
  if (isLaunched) {
    return tr('Launched');
  }
  return HR_STATUS_TYPES[status];
}

/**
 * Returns the status class name for the argument status, or undefined if there
 * is none
 * @param {String} status - One of HR_STATUS_TYPES
 * @return {String | undefined}
 */
function statusClassName(status, isLaunched) {
  if (isLaunched) {
    return 'color--brand';
  }

  switch (status) {
    case ACTUAL_STATUS.ARCHIVED:
      return 'muted';
    case ACTUAL_STATUS.NOT_STARTED:
      return 'muted';
    case ACTUAL_STATUS.CAMPAIGN_PAUSED:
    case ACTUAL_STATUS.PAUSED:
      return '';
    case ACTUAL_STATUS.RUNNING:
      return 'color--good-news';
    default:
      break;
  }
}

function SimpleStatus({ status, isLaunched }) {
  return (
    <div className={statusClassName(status, isLaunched)}>
      {readableStatus(status, isLaunched)}
    </div>
  );
}

SimpleStatus.propTypes = {
  status: PropTypes.string,
  isLaunched: PropTypes.bool,
};

export default SimpleStatus;

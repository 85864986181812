import connectGetters from 'core/ui/methods/connect_getters';
import PropTypes from 'prop-types';
import React from 'react';

import CurrentProjectGetters from 'optly/modules/current_project/getters';

import DashboardHeader from 'react_components/dashboard_header';

const ChangeHistoryTopbar = ({ currentProjectNameWithSdkIfApplicable }) => (
  <DashboardHeader
    projectName={currentProjectNameWithSdkIfApplicable}
    title="History"
  />
);

ChangeHistoryTopbar.propTypes = {
  currentProjectNameWithSdkIfApplicable: PropTypes.string.isRequired,
};

export default connectGetters(ChangeHistoryTopbar, {
  currentProjectNameWithSdkIfApplicable:
    CurrentProjectGetters.nameWithSdkIfApplicable,
});

import PropTypes from 'prop-types';
import React from 'react';

import Immutable from 'optly/immutable';
import { strLimiter } from 'optly/utils/str';

import Card from 'react_components/card';

import PageEventsTable from './table';

const EVENTS_TO_SHOW = 3;

class PageEventsCard extends React.Component {
  static propTypes = {
    canArchiveUserEvent: PropTypes.bool.isRequired,
    canArchiveView: PropTypes.bool.isRequired,
    currentProjectId: PropTypes.number.isRequired,
    configureView: PropTypes.func.isRequired,
    events: PropTypes.instanceOf(Immutable.List).isRequired,
    experimentsUsageAvailable: PropTypes.bool.isRequired,
    pluginEventTypeToName: PropTypes.instanceOf(Immutable.Map).isRequired,
    view: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  state = {
    shouldShowAllEvents: false,
  };

  toggleShowingAllEvents = () => {
    this.setState(prevState => ({
      shouldShowAllEvents: !prevState.shouldShowAllEvents, // eslint-disable-line react/destructuring-assignment
    }));
  };

  shouldShowEvent = (eventObj, index) =>
    this.state.shouldShowAllEvents || index < EVENTS_TO_SHOW; // eslint-disable-line react/destructuring-assignment, max-len

  render() {
    const {
      canArchiveUserEvent,
      canArchiveView,
      configureView,
      currentProjectId,
      events,
      experimentsUsageAvailable,
      pluginEventTypeToName,
      view,
    } = this.props;
    const { shouldShowAllEvents } = this.state;
    let eventsToggler;

    if (events.size > EVENTS_TO_SHOW) {
      if (!shouldShowAllEvents) {
        eventsToggler = (
          <a onClick={this.toggleShowingAllEvents}>
            <span>
              Show all events <span className="lego-arrow-inline--down" />
            </span>
          </a>
        );
      } else {
        eventsToggler = (
          <a onClick={this.toggleShowingAllEvents}>
            <span>
              Show fewer events <span className="lego-arrow-inline--up" />
            </span>
          </a>
        );
      }
    }
    return (
      <div
        className="push-double position--relative"
        data-test-section="page-events-card">
        <Card>
          <Card.Row>
            <Card.Label
              supertitle="Page"
              subtitle={`Events: ${events.size}`}
              title={view.get('name')}>
              {eventsToggler}
              <div className="muted micro">
                {strLimiter(view.get('description'), 300)}
              </div>
            </Card.Label>

            <Card.Body>
              <PageEventsTable
                canArchiveUserEvent={canArchiveUserEvent}
                canArchiveView={canArchiveView}
                configureView={configureView}
                currentProjectId={currentProjectId}
                events={events}
                experimentsUsageAvailable={experimentsUsageAvailable}
                pluginEventTypeToName={pluginEventTypeToName}
                shouldShowAllEvents={shouldShowAllEvents}
                shouldShowEvent={this.shouldShowEvent}
                view={view}
              />
            </Card.Body>
          </Card.Row>
        </Card>
      </div>
    );
  }
}

export default PageEventsCard;

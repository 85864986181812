const EVENTS = {
  SST_JSON_UPDATED: 'project.datafile_updated',
};
EVENTS.ALL = Object.values(EVENTS).reduce((all, next) => all.concat(next), []);

// Use WEBHOOK_EVENT_TYPES as source of truth for webhook event types permitted by backed
// WEBHOOK_EVENT_ENTITY_MAP used only for rendering webhook event checkboxes
export const WEBHOOK_EVENT_ENTITY_MAP = {
  DATAFILE: {
    name: 'Datafile',
    updated_event: 'project.datafile_updated',
  },
  ATTRIBUTE: {
    name: 'Attribute',
    created_event: 'project.attribute_created',
    updated_event: 'project.attribute_updated',
    deleted_event: 'project.attribute_deleted',
    archived_event: 'project.attribute_archived',
  },
  AUDIENCE: {
    name: 'Audience',
    created_event: 'project.audience_created',
    updated_event: 'project.audience_updated',
    deleted_event: 'project.audience_deleted',
    archived_event: 'project.audience_archived',
  },
  ENVIRONMENT: {
    name: 'Environment',
    created_event: 'project.environment_created',
    updated_event: 'project.environment_updated',
    deleted_event: 'project.environment_deleted',
    archived_event: 'project.environment_archived',
  },
  EVENT: {
    name: 'Event',
    created_event: 'project.event_created',
    updated_event: 'project.event_updated',
    deleted_event: 'project.event_deleted',
    archived_event: 'project.event_archived',
  },
  FLAG: {
    name: 'Flag',
    created_event: 'project.flag_created',
    updated_event: 'project.flag_updated',
    deleted_event: 'project.flag_deleted',
    archived_event: 'project.flag_archived',
  },
  GROUP: {
    name: 'Group',
    created_event: 'project.group_created',
    updated_event: 'project.group_updated',
    deleted_event: 'project.group_deleted',
    archived_event: 'project.group_archived',
  },
  PROJECT: {
    name: 'Project',
    created_event: 'project.project_setting_created',
    updated_event: 'project.project_setting_updated',
    deleted_event: 'project.project_setting_deleted',
    archived_event: 'project.project_setting_archived',
  },
  RULESET: {
    name: 'Ruleset',
    created_event: 'project.ruleset_created',
    updated_event: 'project.ruleset_updated',
    deleted_event: 'project.ruleset_deleted',
    archived_event: 'project.ruleset_archived',
  },
  RULE: {
    name: 'Rule',
    created_event: 'project.rule_created',
    updated_event: 'project.rule_updated',
    deleted_event: 'project.rule_deleted',
    archived_event: 'project.rule_archived',
  },
  VARIABLE: {
    name: 'Variable',
    created_event: 'project.variable_created',
    updated_event: 'project.variable_updated',
    deleted_event: 'project.variable_deleted',
    archived_event: 'project.variable_archived',
  },
  VARIATION: {
    name: 'Variation',
    created_event: 'project.variation_created',
    updated_event: 'project.variation_updated',
    deleted_event: 'project.variation_deleted',
    archived_event: 'project.variation_archived',
  },
};

export const DATAFILE_WEBHOOK_EVENT_TYPES = [
  'project.datafile_updated'
]

export const CHANGE_HISTORY_WEBHOOK_EVENT_TYPES = [
  'project.attribute_created',
  'project.attribute_updated',
  'project.attribute_deleted',
  'project.attribute_archived',
  'project.audience_created',
  'project.audience_updated',
  'project.audience_archived',
  'project.environment_created',
  'project.environment_updated',
  'project.environment_archived',
  'project.event_created',
  'project.event_updated',
  'project.event_deleted',
  'project.event_archived',
  'project.flag_created',
  'project.flag_updated',
  'project.flag_deleted',
  'project.flag_archived',
  'project.group_created',
  'project.group_updated',
  'project.group_deleted',
  'project.group_archived',
  'project.project_setting_created',
  'project.project_setting_updated',
  'project.project_setting_archived',
  'project.ruleset_created',
  'project.ruleset_updated',
  'project.rule_created',
  'project.rule_updated',
  'project.rule_deleted',
  'project.variable_created',
  'project.variable_updated',
  'project.variable_deleted',
  'project.variation_created',
  'project.variation_updated',
  'project.variation_deleted',
  'project.variation_archived',
]

export const WEBHOOK_EVENT_TYPES = [
  ...DATAFILE_WEBHOOK_EVENT_TYPES,
  ...CHANGE_HISTORY_WEBHOOK_EVENT_TYPES
];

export const ALL_EVENTS = EVENTS.ALL;

export { EVENTS };

export default {
  CHANGE_HISTORY_WEBHOOK_EVENT_TYPES,
  DATAFILE_WEBHOOK_EVENT_TYPES,
  EVENTS,
  ALL_EVENTS,
  WEBHOOK_EVENT_ENTITY_MAP,
  WEBHOOK_EVENT_TYPES,
};

import React from 'react';

import getReactWrapperForVueComponent from '../vue_wrapper';

/**
 * Wrap the provided Vue component in a React component that
 * exposes a few method for manually interacting with it during
 * region transitions.
 */
export function wrap(
  regionId,
  { component, componentId, options, data, parent },
) {
  const WrappedVueComponent = getReactWrapperForVueComponent(
    component,
    parent,
    options,
    data,
  );

  class VueComponentInRegion extends React.Component {
    shouldComponentUpdate() {
      return false;
    }

    registerComponent(cb) {
      cb(regionId, componentId, {
        component: this.vueComponentRef,
        key: componentId,
      });
    }

    createVueComponentRef = vueComponentFromRef => {
      this.vueComponentRef = vueComponentFromRef;
    };

    navigationControllerWillHideView = () => {
      if (this.vueComponentRef) {
        // make the current component unsubscribe to all flux updates since we could have two components
        // both on the page with conflicting state
        this.vueComponentRef.$emit('unwatchFluxBindVueValues');
        this.vueComponentRef.$broadcast('unwatchFluxBindVueValues');
      }
    };

    render() {
      return (
        <WrappedVueComponent
          vueComponentRef={this.createVueComponentRef}
          className="stage__item__content--column"
        />
      );
    }
  }

  VueComponentInRegion.componentId = componentId;

  return VueComponentInRegion;
}

export default {
  wrap,
};

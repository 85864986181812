import PropTypes from 'prop-types';
import React from 'react';
import Immutable, { toImmutable } from 'optly/immutable';

import SmartPageConfig from 'bundles/p13n/components/targeting/smart_page_config';

const UrlTargeting = function(props) {
  function onUpdate(fieldName, value, callback) {
    props.onChange(
      toImmutable({
        [fieldName]: value,
      }),
      callback,
    );
  }

  const {
    config,
    saveUrlTargetingAsView,
    errors,
    updateConditionErrors,
    setRevertHandler,
  } = props;

  // SmartPageConfig expects view configurations to have an
  // 'id' property, but url targeting configs on layers have
  // 'view_id' properties instead
  const configWithId = config
    .set('id', config.get('view_id'))
    .delete('view_id');

  return (
    <div className="border--all">
      <div className="background--faint border--bottom flex flex-justified--end  soft-half--ends soft-double--right">
        <a
          onClick={saveUrlTargetingAsView}
          data-test-section="save-as-page"
          data-track-id="2.0-Campaign-Targeting.UrlTargeting.UseAsUrlTarget">
          Save as Page...
        </a>
      </div>
      <div className="soft-double">
        <SmartPageConfig
          viewConfiguration={configWithId}
          onUpdate={onUpdate}
          updateConditionErrors={updateConditionErrors}
          errors={errors}
          setRevertHandler={setRevertHandler}
        />
      </div>
    </div>
  );
};

UrlTargeting.propTypes = {
  config: PropTypes.instanceOf(Immutable.Map).isRequired,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  saveUrlTargetingAsView: PropTypes.func.isRequired,
  updateConditionErrors: PropTypes.func.isRequired,
  setRevertHandler: PropTypes.func.isRequired,
};

export default UrlTargeting;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Immutable, toJS, toImmutable } from 'nuclear-js';
import { Button, Dialog, DialogNew, Sheet } from 'optimizely-oui';

import ui from 'core/ui';
import { connect } from 'core/ui/decorators';

import LayerFns from 'optly/modules/entity/layer/fns';
import LayerActions from 'optly/modules/entity/layer/actions';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
import AdminAccountGetters from 'optly/modules/admin_account/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import DuplicateLayerSuccess from 'bundles/p13n/components/dialogs/duplicate_layer_success';

import LoadingOverlay from 'react_components/loading_overlay';
import SelectDropdown from 'react_components/select_dropdown';

const NO_PROJECT_SELECTED = 'NO_PROJECT_SELECTED';

@connect({
  currentProject: CurrentProjectGetters.project,
  projects: AdminAccountGetters.activeWebProjects,
  canDuplicateAcrossProjects: [
    AdminAccountGetters.accountPermissions,
    PermissionsModuleFns.canDuplicateAcrossProjects,
  ],
})
class DuplicateLayerDialog extends React.Component {
  static propTypes = {
    canDuplicateAcrossProjects: PropTypes.bool.isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    layer: PropTypes.instanceOf(Immutable.Map).isRequired,
    projects: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: null,
      text: LayerFns.getText(toJS(props.layer)).duplicate,
      selectedProject: toImmutable({ project_name: NO_PROJECT_SELECTED }),
    };
  }

  duplicateInstructions() {
    const { text } = this.state;
    const { canDuplicateAcrossProjects } = this.props;
    return canDuplicateAcrossProjects
      ? text.selectProject
      : text.selectSameProject;
  }

  componentWillUnmount() {
    const { currentProject } = this.props;
    const { response, selectedProject, text } = this.state;
    if (response) {
      ui.showReactDialog(
        DuplicateLayerSuccess,
        {
          props: {
            settings: response,
            selectedProjectName: selectedProject.get('project_name'),
            title: text.successTitle,
            successMessage: text.successMessage,
            wasDuplicationCrossProject:
              selectedProject.get('id') === currentProject.get('id'),
          },
        },
        {
          fullScreen: true,
          dismissOnBack: true,
        },
      );
    }
  }

  handleDuplicateClick = () => {
    let error;
    let selectedProject;
    const {
      canDuplicateAcrossProjects,
      currentProject,
      layer,
      projects,
    } = this.props;
    const { selectedProject: selectedProjectFromState, text } = this.state;
    if (
      canDuplicateAcrossProjects &&
      selectedProjectFromState.get('project_name') === NO_PROJECT_SELECTED
    ) {
      this.setState({ error: tr('Please select a project') });
    } else {
      if (
        selectedProjectFromState.get('project_name') === NO_PROJECT_SELECTED
      ) {
        selectedProject = currentProject;
      } else {
        selectedProject = projects
          .filter(
            project =>
              project.get('project_name') ===
              selectedProjectFromState.get('project_name'),
          )
          .get(0);
      }
      ui.loadingWhen(
        'duplicateExperiment',
        LayerActions.duplicateToProject(
          layer.get('id'),
          selectedProject.get('id'),
        )
          .done(response => {
            this.setState(
              {
                error: null,
                response,
                selectedProject,
              },
              () => ui.hideDialog(),
            );
          })
          .fail(response => {
            if (response.status === 403) {
              error = text.permissionsError;
            } else {
              error = tr('Something went wrong');
            }
            this.setState({
              error,
              selectedProject,
            });
          }),
      );
    }
  };

  handleProjectSelectDropdown = project => {
    if (project !== NO_PROJECT_SELECTED) {
      this.setState(prevState => ({
        selectedProject: prevState.selectedProject.set('project_name', project),
        error: null,
      }));
    }
  };

  getDialogBody = () => {
    const { error, text, selectedProject } = this.state;
    const { canDuplicateAcrossProjects, projects } = this.props;
    const items = [
      {
        label: 'Select a Project',
        value: NO_PROJECT_SELECTED,
      },
    ];
    projects.map(project => {
      items.push({
        id: project.get('id'),
        label: project.get('project_name'),
        value: project.get('project_name'),
      });
    });
    return (
      <LoadingOverlay
        className="position--relative"
        loadingId="duplicateExperiment">
        <Dialog.Fieldset>
          <ol className="oui-form-fields">
            <li
              className={classNames({
                'oui-form-field__item': true,
                'oui-form-bad-news': error,
              })}>
              <p data-test-section="duplicate-layer-paragraph-text">
                {this.duplicateInstructions()}
              </p>
              <div data-test-section="duplicate-layer-paragraph-note">
                {text.note}
              </div>
              {canDuplicateAcrossProjects && (
                <div className="push-double--top">
                  <SelectDropdown
                    width={300}
                    items={items}
                    isFilterable={true}
                    inputPlaceholder="Select a Project"
                    testSection="duplicate-layer-select-project"
                    value={selectedProject.get('project_name')}
                    onChange={this.handleProjectSelectDropdown}
                  />
                </div>
              )}
              {error && (
                <div
                  className="oui-form-note oui-form-bad-news"
                  data-test-section="error-selected-project">
                  {error}
                </div>
              )}
            </li>
          </ol>
        </Dialog.Fieldset>
      </LoadingOverlay>
    );
  };

  renderDialogNew = () => {
    const { text } = this.state;
    return (
      <DialogNew
        footerButtonList={[
          <Button
            key="Cancel"
            style="plain"
            onClick={ui.hideDialog}
            testSection="duplicate-cancel">
            Cancel
          </Button>,
          <Button
            key="Duplicate"
            style="highlight"
            onClick={this.handleDuplicateClick}
            testSection="duplicate-layer-button">
            Duplicate
          </Button>,
        ]}
        title={text.title}
        onClose={ui.hideDialog}
        testSection="layer-duplicate">
        {this.getDialogBody()}
      </DialogNew>
    );
  };

  renderSheet = () => {
    const { text } = this.state;
    return (
      <Sheet
        footerButtonList={[
          <Button
            key="Cancel"
            style="plain"
            onClick={ui.hideDialog}
            testSection="duplicate-cancel">
            Cancel
          </Button>,
          <Button
            key="Duplicate"
            style="highlight"
            onClick={this.handleDuplicateClick}
            testSection="duplicate-layer-button">
            Duplicate
          </Button>,
        ]}
        title={text.title}
        onClose={ui.hideDialog}
        testSection="layer-duplicate">
        {this.getDialogBody()}
      </Sheet>
    );
  };

  render() {
    const { canDuplicateAcrossProjects } = this.props;
    return canDuplicateAcrossProjects
      ? this.renderSheet()
      : this.renderDialogNew();
  }
}

export default DuplicateLayerDialog;

import _ from 'lodash';

import tr from 'optly/translate';
import enums from 'optly/utils/enums';
import AdminAccountGetters from 'optly/modules/admin_account/getters';
import Audience from 'optly/modules/entity/audience';
import Collaborator from 'optly/modules/entity/collaborator';
import Dimension from 'optly/modules/entity/dimension';
import Experiment from 'optly/modules/entity/experiment';

import Goal from 'optly/modules/entity/goal';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import ExperimentationGroup from 'optly/modules/entity/experimentation_group';
import ProjectFns from 'optly/modules/entity/project/fns';
import ProjectGetters from 'optly/modules/entity/project/getters';
import SortableTableGetters from 'optly/modules/sortable_table/getters';
import ProjectActivityGetters from 'optly/modules/entity/project_activity/getters';
import { toImmutable } from 'optly/immutable';

import relativeDate from 'optly/filters/relative_date';

import {
  DEFAULT_COLUMNS_VISIBILITY,
  DEFAULT_EXPERIMENTS_COLUMNS_VISIBILITY,
  DEFAULT_PERSONALIZATION_COLUMNS_VISIBILITY,
} from './constants';
import { dashboardTabs } from '../../../bundles/p13n/sections/layers/pages/layers_dashboard/page_module/constants';

export const activeMainTab = ['tabs', 'activeTab'];
export const activeCanvasIntegration = ['tabs', 'activeCanvasIntegration'];
export const activeSubTab = ['tabs', 'activeSubTab'];
export const activityFilters = ['activityFilters', 'filters'];
export const visibilityState = ['customization'];

const goalTypeMap = _.invert(enums.GoalType);
const productTypes = require('optly/modules/entity/integration/enums').products;

const fns = require('./fns');

function toPercentageString(num) {
  return tr.number((num * 1.0) / 100, 2, true);
}

export function tableFilters(category) {
  return [
    ['tableFilters', 'tableFilters', category],
    function(state) {
      if (!state) {
        throw new Error(`No table filters registered for ${category}`);
      }
      return state;
    },
  ];
}

/**
 * Returns an Immutable.List of selected ids for a category
 * @return {Immutable.List} ids
 */
export function selectedIds(category) {
  return [
    ['selectedItems', 'selectedItems', category],
    ids => ids || toImmutable([]),
  ];
}

/**
 * Function getter to take dashboard/tableFilters + category
 * and return all the fields which can be used as filters
 * through the rest api
 *
 * @param {String} category of the filter ('experiments', 'audiences', etc.)
 */
export function apiFilters(category) {
  const CATEGORY_TO_MODEL = {
    audiences: Audience,
    collaborators: Collaborator,
    dimensions: Dimension,
    experiments: Experiment,
    goals: Goal,
  };

  return [
    tableFilters(category),
    CurrentProjectGetters.id,
    function(filters, currentProjectId) {
      const apiFilters = CATEGORY_TO_MODEL[category].entityDef.fieldTypes;
      const byProject = {
        project_id: currentProjectId,
      };
      return _(filters.toJS())
        .pick(_.keys(apiFilters))
        .assign(byProject)
        .value();
    },
  ];
}

/**
 * Gets the selected activity for the sidebar on the dashboard
 */
const selectedActivity = [
  selectedIds('activities'),
  ProjectActivityGetters.entityCache,
  function(selectedActivityIds, activities) {
    if (selectedActivityIds.size === 1) {
      return activities.get(selectedActivityIds.first());
    }
    return null;
  },
];

/**
 * Function getter to take selectedActivity
 * and return the changes in allocations.
 */
export const changedAllocations = [
  selectedActivity,
  function(activity) {
    let allocationObject = null;
    if (activity && !_.includes(activity.get('event'), 'goal')) {
      const changedObject = fns.retrieveChangedObject(
        activity,
        ['more_info', 'percentage_included'],
        toPercentageString,
      );
      if (changedObject.before && changedObject.after) {
        allocationObject = changedObject;
      }
    }
    return toImmutable(allocationObject);
  },
];

/**
 * Function getter to take selectedActivity
 * and return the changes in audiences.
 */
export const changedAudiences = [
  selectedActivity,
  function(activity) {
    let audienceObject = null;
    if (activity && !_.includes(activity.get('event'), 'goal')) {
      const added = activity
        .getIn(['more_info', 'audience_ids', 'added'], toImmutable([]))
        .toJS();
      const removed = activity
        .getIn(['more_info', 'audience_ids', 'removed'], toImmutable([]))
        .toJS();
      if (added.length > 0 || removed.length > 0) {
        audienceObject = {
          added,
          removed,
        };
      }
    }

    return toImmutable(audienceObject);
  },
];

/**
 * Function getter to take selectedActivity
 * and return the changes in one goal.
 */
export const changedGoal = [
  selectedActivity,
  function(activity) {
    let goalObject = null;
    if (activity && _.includes(activity.get('event'), 'goal')) {
      const goalId = activity.get('goal_id', null);

      const descriptionBefore = activity.getIn(
        ['more_info', 'description', 'before'],
        null,
      );
      let descriptionAfter = activity.getIn(
        ['more_info', 'description', 'after'],
        null,
      );
      descriptionAfter =
        descriptionAfter !== null
          ? descriptionAfter
          : activity.getIn(['more_info', 'description'], null);
      const description = {
        after: descriptionAfter,
        before: descriptionBefore,
      };

      const eventBefore = activity.getIn(
        ['more_info', 'event', 'before'],
        null,
      );
      let eventAfter = activity.getIn(['more_info', 'event', 'after'], null);
      eventAfter =
        eventAfter !== null
          ? eventAfter
          : activity.getIn(['more_info', 'event'], null);
      const event = {
        after: eventAfter,
        before: eventBefore,
      };

      let goalTypeBefore = activity.getIn(
        ['more_info', 'goal_type', 'before'],
        null,
      );
      goalTypeBefore =
        goalTypeBefore !== null
          ? goalTypeMap[goalTypeBefore].toLowerCase().replace('_', ' ')
          : goalTypeBefore;
      let goalTypeAfter = activity.getIn(
        ['more_info', 'goal_type', 'after'],
        null,
      );
      goalTypeAfter =
        goalTypeAfter !== null
          ? goalTypeAfter
          : activity.getIn(['more_info', 'goal_type'], null);
      goalTypeAfter =
        goalTypeAfter !== null
          ? goalTypeMap[goalTypeAfter].toLowerCase().replace('_', ' ')
          : goalTypeAfter;
      const goalType = {
        after: goalTypeAfter,
        before: goalTypeBefore,
      };

      const selectorBefore = activity.getIn(
        ['more_info', 'selector', 'before'],
        null,
      );
      let selectorAfter = activity.getIn(
        ['more_info', 'selector', 'after'],
        null,
      );
      selectorAfter =
        selectorAfter !== null
          ? selectorAfter
          : activity.getIn(['more_info', 'selector'], null);
      const selector = {
        after: selectorAfter,
        before: selectorBefore,
      };

      const targetToExperimentBefore = activity.getIn(
        ['more_info', 'target_to_experiments', 'before'],
        null,
      );
      let targetToExperimentAfter = activity.getIn(
        ['more_info', 'target_to_experiments', 'after'],
        null,
      );
      targetToExperimentAfter =
        targetToExperimentAfter !== null
          ? targetToExperimentAfter
          : activity.getIn(['more_info', 'target_to_experiments'], null);
      const targetToExperiment = {
        after: targetToExperimentAfter,
        before: targetToExperimentBefore,
      };

      const titleBefore = activity.getIn(
        ['more_info', 'title', 'before'],
        null,
      );
      let titleAfter = activity.getIn(['more_info', 'title', 'after'], null);
      titleAfter =
        titleAfter !== null
          ? titleAfter
          : activity.getIn(['more_info', 'title'], null);
      const title = {
        after: titleAfter,
        before: titleBefore,
      };

      const urlsBefore = activity.getIn(
        ['more_info', 'targeting_urls', 'before'],
        toImmutable([]),
      );
      let urlsAfter = activity.getIn(
        ['more_info', 'targeting_urls', 'after'],
        toImmutable([]),
      );
      urlsAfter =
        urlsAfter !== undefined
          ? urlsAfter
          : activity.getIn(['more_info', 'targeting_urls'], toImmutable([]));
      const urls = {
        after: (urlsAfter !== undefined ? urlsAfter : toImmutable([])).toJS(),
        before: (urlsBefore !== undefined
          ? urlsBefore
          : toImmutable([])
        ).toJS(),
      };

      // Because we store this data in an Expando on the python side every period is converted to an underscore.
      // Changing them back here for human readability.
      if (urls.before && urls.before.length > 0) {
        urls.before = urls.before.map(urlObj => {
          urlObj.url = urlObj.url.replace(/_/g, '.');
          return urlObj;
        });
      }

      if (urls.after && urls.after.length > 0) {
        urls.after = urls.after.map(urlObj => {
          urlObj.url = urlObj.url.replace(/_/g, '.');
          return urlObj;
        });
      }

      goalObject = {
        description,
        event,
        goalId,
        goalType,
        selector,
        targetToExperiment,
        title,
        urls,
      };
    }
    return toImmutable(goalObject);
  },
];

export const changedProject = [
  selectedActivity,
  function(activity) {
    let projectChangeObject = null;
    if (activity && activity.get('category') === 'project_visible') {
      const changedObject = fns.retrieveChangedObject(
        activity,
        ['more_info', 'experiment_confidence_threshold'],
        toPercentageString,
      );
      if (changedObject.before && changedObject.after) {
        projectChangeObject = {
          experimentConfidenceThreshold: changedObject,
        };
      }
    }
    return toImmutable(projectChangeObject);
  },
];

export const changedSections = [
  selectedActivity,
  function(activity) {
    let sectionObject = null;
    if (activity && !_.includes(activity.get('event'), 'goal')) {
      let experimentType = activity.getIn(
        ['more_info', 'experiment_type', 'after'],
        null,
      );
      if (!experimentType) {
        experimentType = activity.getIn(['more_info', 'experiment_type'], null);
      }
      if (experimentType === 'multivariate') {
        const added = activity
          .getIn(['more_info', 'sections', 'added'], toImmutable([]))
          .toJS();
        const changed = activity
          .getIn(['more_info', 'sections', 'changed'], toImmutable([]))
          .toJS();
        const deleted = activity
          .getIn(['more_info', 'sections', 'deleted'], toImmutable([]))
          .toJS();
        if (added.length > 0 || changed.length > 0 || deleted.length > 0) {
          sectionObject = {
            added,
            changed,
            deleted,
          };
        }
      }
    }

    return toImmutable(sectionObject);
  },
];

/**
 * Function getter to take selectedActivity
 * and return the changes in variations.
 */
export const changedVariations = [
  selectedActivity,
  function(activity) {
    let variationObject = null;
    if (activity && !_.includes(activity.get('event'), 'goal')) {
      let experimentType = activity.getIn(
        ['more_info', 'experiment_type', 'after'],
        null,
      );
      if (!experimentType) {
        // ExperimentType defaults to 'ab' to handle for previous activities that do not have this specific field
        experimentType = activity.getIn(['more_info', 'experiment_type'], 'ab');
      }
      if (experimentType === 'ab') {
        const added = activity
          .getIn(['more_info', 'variations', 'added'], toImmutable([]))
          .toJS();
        const changed = activity
          .getIn(['more_info', 'variations', 'changed'], toImmutable([]))
          .toJS();
        const deleted = activity
          .getIn(['more_info', 'variations', 'deleted'], toImmutable([]))
          .toJS();
        const unchanged = activity
          .getIn(['more_info', 'variations', 'unchanged'], toImmutable([]))
          .toJS();
        if (
          added.length > 0 ||
          changed.length > 0 ||
          deleted.length > 0 ||
          unchanged.length > 0
        ) {
          variationObject = {
            added,
            changed,
            deleted,
            unchanged,
          };
        }
      }
    }

    return toImmutable(variationObject);
  },
];

/**
 * Function getter to take currentProject + labs store
 * and return the labs for which the current project has permission.
 */
export const enabledLabs = [
  CurrentProjectGetters.project,
  ['labsData'],
  AdminAccountGetters.isAdmin,
  function(currentProject, labsData, isAdmin) {
    const permissions = currentProject.get('project_permissions');
    return labsData
      .filter(lab => {
        if (lab.get('requiresAdmin') && !isAdmin) {
          return false;
        }

        // Check for Full Stack and if the labs option should be available
        if (ProjectFns.isCustomProject(currentProject)) {
          return lab.get('allowedInFullStack');
        }

        const permissionRequired = lab.get('permissionRequired');

        if (!permissionRequired) {
          return true;
        }

        // TODO (asa): Add support for and or conditions
        // Currently is an implicit OR of conditions
        if (!_.isString(permissionRequired)) {
          return permissionRequired.some(perm => permissions.contains(perm));
        }

        return permissions.contains(permissionRequired);
      })
      .toList();
  },
];

/**
 * Function getter to take currentProject + experiments store
 * and return all experiments for that project
 */

const filteredDimensions = [
  CurrentProjectGetters.dimensions,
  SortableTableGetters.sortFn('dashboard-dimensions'),
  tableFilters('dimensions'),
  function(dimensions, sortFn, tableFilters) {
    let filterString = tableFilters.get('string');
    if (filterString) {
      filterString = filterString.toLowerCase();
      dimensions = dimensions.filter(dim => {
        const name = dim.get('name') || '';
        const api_name = dim.get('client_api_name') || '';
        const desc = dim.get('description') || '';
        const id = dim.get('id');
        return (
          name.toLowerCase().indexOf(filterString) !== -1 ||
          desc.toLowerCase().indexOf(filterString) !== -1 ||
          api_name.toLowerCase().indexOf(filterString) !== -1 ||
          id.toString().indexOf(filterString) !== -1
        );
      });
    }

    if (sortFn) {
      dimensions = dimensions.sort(sortFn);
    }
    return dimensions;
  },
];

/**
 * Main getter powering the dashboard audience table
 * Takes all current project audiences and filters and sorts
 * based on the UI state of the dashboard
 */
export const filteredAudiences = [
  CurrentProjectGetters.audiences,
  SortableTableGetters.sortFn('dashboard-audiences'),
  CurrentProjectGetters.experiments,
  tableFilters('audiences'),
  function(audiences, sortFn, experiments, filters) {
    // when the archived filter is not defined, show
    // only active audiences
    const archived = !!filters.get('archived');
    let filtered = audiences.filter(
      audience =>
        audience.get('archived') === archived && audience.get('user_touched'),
    );
    let filterString = filters.get('string');

    if (filterString) {
      filterString = filterString.toLowerCase();
      filtered = filtered.filter(audience => {
        const id = audience.get('id');
        const name = audience.get('name');
        const desc = audience.get('description') || '';
        return (
          name.toLowerCase().indexOf(filterString) !== -1 ||
          desc.toLowerCase().indexOf(filterString) !== -1 ||
          id.toString().indexOf(filterString) !== -1
        );
      });
    }

    // map and populate the audience experiments
    filtered = filtered.map(audience =>
      audience.set(
        'experiments',
        experiments.filter(experiment =>
          experiment.get('audience_ids').contains(audience.get('id')),
        ),
      ),
    );

    if (sortFn) {
      filtered = filtered.sort(sortFn);
    }

    return filtered;
  },
];

/**
 * Main getter powering the dashboard user lists table
 * Takes all current project user lists and filters and sorts
 * based on the UI state of the dashboard
 */
export const filteredUserLists = [
  CurrentProjectGetters.userLists,
  SortableTableGetters.sortFn('dashboard-user-lists'),
  CurrentProjectGetters.integrations,
  tableFilters('user-lists'),
  function(userLists, sortFn, integrations, filters) {
    let filtered = userLists;
    let filterString = filters.get('string');

    if (filterString) {
      filterString = filterString.toLowerCase();
      filtered = filtered.filter(list => {
        const id = list.get('id');
        const name = list.get('name');
        const desc = list.get('description') || '';
        return (
          name.toLowerCase().indexOf(filterString) !== -1 ||
          desc.toLowerCase().indexOf(filterString) !== -1 ||
          id.toString().indexOf(filterString) !== -1
        );
      });
    }

    // map and populate the sources
    filtered = filtered.map(userList => {
      const integrationId = userList.get('integration_id');
      const integration = integrations
        .filter(i => integrationId === i.get('id'))
        .get(0);
      const source = integration
        ? integration.get('masterLabel')
        : 'Manual upload';

      return userList.set('source', source);
    });

    if (sortFn) {
      filtered = filtered.sort(sortFn);
    }

    return filtered;
  },
];

/**
 * Main getter powering the dashboard datasources table
 * Takes all current project datasources and filters and sorts
 * based on the UI state of the dashboard
 */
export const filteredDatasources = [
  CurrentProjectGetters.dcpDatasources,
  SortableTableGetters.sortFn('dashboard-datasources'),
  CurrentProjectGetters.integrations,
  tableFilters('datasources'),
  function(datasources, sortFn, integrations, filters) {
    let filtered = datasources;
    const archived = filters.get('archived') || false;
    let filterString = filters.get('string');

    filtered = filtered.filter(ds => ds.get('archived') === archived);

    if (filterString) {
      filterString = filterString.toLowerCase();
      filtered = filtered.filter(item => {
        const id = item.get('id');
        const name = item.get('name');
        const desc = item.get('description') || '';
        return (
          name.toLowerCase().indexOf(filterString) !== -1 ||
          desc.toLowerCase().indexOf(filterString) !== -1 ||
          id.toString().indexOf(filterString) !== -1
        );
      });
    }

    // map and populate the source field
    filtered = filtered.map(datasource =>
      datasource.set('source', 'Manual upload'),
    );

    if (sortFn) {
      filtered = filtered.sort(sortFn);
    }

    return filtered;
  },
];

/**
 * Main getter powering the dashboard experiments table
 * Takes all current project experiments and filters and sorts
 * based on the UI state of the dashboard
 */
export const filteredExperiments = [
  Experiment.getters.combinedExperimentsAndResults,
  SortableTableGetters.sortFn('dashboard-experiments'),
  Goal.getters.experimentToGoalsMap,
  tableFilters('experiments'),
  CurrentProjectGetters.id,
  ExperimentationGroup.getters.entityToGroupMap,

  /**
   * This function composes the current experiments with their results, goals and does any
   * sort of filtering / sorting based on the state of the dashboard UI
   */
  function(
    experiments,
    sortFn,
    goalsMap,
    filters,
    currentProjectId,
    entityToGroupMap,
  ) {
    let filterString = filters.get('string');
    const status = filters.get('status');

    experiments = experiments
      .filter(
        exp => currentProjectId && exp.get('project_id') === currentProjectId,
      )
      .toList();

    if (status.size > 0) {
      experiments = experiments.filter(exp =>
        status.contains(exp.get('status')),
      );
    }

    if (filterString) {
      filterString = filterString.toLowerCase();
      experiments = experiments.filter(exp => {
        const id = exp.get('id').toString();
        const desc = exp.get('details').toLowerCase();
        const title = exp.get('description').toLowerCase();
        return (
          _.includes(desc, filterString) ||
          _.includes(title, filterString) ||
          _.includes(id, filterString)
        );
      });
    }

    experiments = experiments.map(exp =>
      exp.withMutations(exp => {
        let visitorCount;
        let visitorsTooltip;
        const results = exp.get('results');
        exp.set('goals', goalsMap.get(exp.get('id')) || toImmutable([]));

        if (exp.get('status') === enums.ExperimentStatusType.NOT_STARTED) {
          visitorCount = -1;
          visitorsTooltip = tr("This experiment hasn't been started yet.");
        }
        if (!results) {
          visitorCount = -1;
          visitorsTooltip = tr('No results available.');
        } else {
          visitorCount = exp.getIn(['results', 'visitors']);
          const cacheTime = exp.getIn(['results', 'cache_time']);
          if (cacheTime) {
            visitorsTooltip = tr(
              'Last refreshed: {0}',
              relativeDate(new Date(cacheTime)),
            );
          } else {
            visitorsTooltip = tr('Last refreshed: just now');
          }
        }

        const experimentId = exp.get('id').toString();
        // add the group name, that is, the layer this experiment is associated with
        if (entityToGroupMap.has(experimentId)) {
          exp.set('group', entityToGroupMap.get(experimentId).get('name'));
        }

        exp.set('visitors', visitorCount);
        exp.set('visitorsTooltip', visitorsTooltip);

        if (results) {
          const conclusions = results.get('conclusions');
          if (conclusions) {
            exp.set('winners', _.filter(conclusions, { status: 'winner' }));
            exp.set('losers', _.filter(conclusions, { status: 'loser' }));
            exp.set(
              'inconclusives',
              _.filter(conclusions, { status: 'inconclusive' }),
            );
            exp.set(
              'primaryConclusion',
              conclusions[exp.get('primary_goal_id')],
            );
          } else {
            // No conclusions will resort in just the goal count being displayed in the table row.
          }
        }

        return exp;
      }),
    );

    if (sortFn) {
      experiments = experiments.sort(sortFn);
    }
    return experiments.toList();
  },
];

export const filteredExperimentationGroups = [
  CurrentProjectGetters.experimentationGroups,
  groups =>
    groups
      .filter(group => !group.get('archived', false))
      .map(group => {
        const humanReadablePolicyName =
          ExperimentationGroup.humanReadable.POLICY_TYPES[group.get('policy')];
        return group.set('policy', humanReadablePolicyName);
      }),
];

export const filteredGoals = [
  CurrentProjectGetters.goals,
  SortableTableGetters.sortFn('dashboard-goals'),
  tableFilters('goals'),
  function(visibleGoals, sortFn, filters) {
    const archived = filters.get('archived') || false;
    let filterString = filters.get('string');

    let filtered = visibleGoals.filter(
      goal => goal.get('archived', false) === archived,
    );

    if (filterString) {
      filterString = filterString.toLowerCase();
      filtered = filtered.filter(goal => {
        const id = goal.get('id').toString();
        const title = goal.get('title').toLowerCase();
        const desc = goal.get('description').toLowerCase();
        const eventName = goal.get('event')
          ? goal.get('event').toLowerCase()
          : '';
        return (
          _.includes(eventName, filterString) ||
          _.includes(id, filterString) ||
          _.includes(desc, filterString) ||
          _.includes(title, filterString)
        );
      });
    }

    if (sortFn) {
      filtered = filtered.sort(sortFn);
    }

    return filtered;
  },
];

/**
 * Function getter to take currentProject + integrations store
 * and return all integrations for that project
 */
export const filteredIntegrations = [
  CurrentProjectGetters.integrations,
  tableFilters('integrations'),
  activeMainTab,
  function(integrations, filters, currentMainTab) {
    const category = filters.get('category');
    let filterString = filters.get('string');

    const desiredAddonType = currentMainTab === 'apps' ? 'app' : 'integration';
    integrations = integrations.filter(
      integration => integration.get('addonType') === desiredAddonType,
    );

    if (category !== enums.IntegrationCategoryTypes.ALL) {
      integrations = integrations.filter(
        integration => integration.get('categories').indexOf(category) !== -1,
      );
    }

    if (filterString) {
      integrations = integrations.filter(integration => {
        const masterLabel = integration.get('masterLabel').toLowerCase();
        // TODO(ali): Need to concatenate categories, when integration belongs to multiple categories
        const categories = integration.getIn(['categories', 0]).toLowerCase();
        filterString = filterString.toLowerCase();
        return (
          masterLabel.indexOf(filterString) !== -1 ||
          categories.indexOf(filterString) !== -1
        );
      });
    }

    return integrations;
  },
];

/**
 * Function getter to return integrations available for AB Testing product
 */
export const filteredIntegrationsForAB = [
  filteredIntegrations,
  function(integrations) {
    // Filter integrations for AB
    integrations = integrations.filter(
      integration =>
        integration.get('products').indexOf(productTypes.AB_TESTING) !== -1,
    );

    return integrations;
  },
];

/**
 * Function getter to return integrations available for Personalization product
 */
export const filteredIntegrationsForP13N = [
  filteredIntegrations,
  function(integrations) {
    // Filter integrations for Personalization
    integrations = integrations.filter(
      integration =>
        integration.get('products').indexOf(productTypes.PERSONALIZATION) !==
        -1,
    );

    return integrations;
  },
];

export const filteredCollaborators = [
  CurrentProjectGetters.collaborators,
  SortableTableGetters.sortFn('dashboard-collaborators'),
  tableFilters('collaborators'),
  (collaborators, sortFn, filters) => {
    let filterString = filters.get('string');
    let filtered = collaborators;

    if (filterString) {
      filterString = filterString.toLowerCase();
      filtered = collaborators.filter(collab => {
        const email = (
          collab.get('user_id') || collab.get('target_user_email')
        ).toLowerCase();
        const role = collab.get('role_name').toLowerCase();
        const name = `${collab.get('first_name')} ${collab.get(
          'last_name',
        )}`.toLowerCase();
        return (
          _.includes(email, filterString) ||
          _.includes(role, filterString) ||
          _.includes(name, filterString)
        );
      });
    }

    if (sortFn) {
      filtered = filtered.sort(sortFn);
    }

    return filtered.toList();
  },
];

export const selectedCollaborators = [
  selectedIds('collaborators'),
  filteredCollaborators,
  (selectedCollaboratorIds, collaborators) =>
    collaborators.filter(collaborator =>
      selectedCollaboratorIds.contains(collaborator.get('id')),
    ),
];

/**
 * Gets the selected dimensions for the dashboard
 */
export const selectedAudiences = [
  selectedIds('audiences'),
  filteredAudiences,
  (selectedAudienceIds, audiences) =>
    audiences.filter(audience =>
      selectedAudienceIds.contains(audience.get('id')),
    ),
];

/**
 * Gets the selected dimensions for the dashboard
 */
export const selectedDimensions = [
  selectedIds('dimensions'),
  filteredDimensions,
  (selectedDimensionIds, dimensions) =>
    dimensions.filter(dimension =>
      selectedDimensionIds.contains(dimension.get('id')),
    ),
];

export const selectedExperiments = [
  selectedIds('experiments'),
  filteredExperiments,
  (selectedItems, experiments) =>
    experiments.filter(experiment =>
      selectedItems.contains(experiment.get('id')),
    ),
];

export const selectedIntegration = [
  CurrentProjectGetters.integrations,
  selectedIds('integrations'),
  function(integrations, selectedItems) {
    const selectedIntegrationId = selectedItems.get(0);
    return (
      integrations.find(
        integration =>
          integration.get('id') === selectedIntegrationId &&
          integration.get('addonType') === 'integration',
      ) || toImmutable({})
    );
  },
];

export const selectedApp = [
  CurrentProjectGetters.integrations,
  selectedIds('integrations'),
  function(integrations, selectedItems) {
    const selectedIntegrationId = selectedItems.get(0);
    return (
      integrations.find(
        integration =>
          integration.get('id') === selectedIntegrationId &&
          integration.get('addonType') === 'app',
      ) || toImmutable({})
    );
  },
];

const selectedProject = [
  selectedIds('projects'),
  ProjectGetters.entityCache,
  function(selectedItems, projects) {
    if (selectedItems.size === 1) {
      return projects.get(selectedItems.get(0));
    }
  },
];

export const selectedGoals = [
  selectedIds('goals'),
  filteredGoals,
  (selectedItems, goals) =>
    goals.filter(goal => selectedItems.contains(goal.get('id'))),
];

export const selectedExperimentationGroups = [
  selectedIds('groups'),
  filteredExperimentationGroups,
  (selectedItems, groups) =>
    groups.filter(group => selectedItems.contains(group.get('id'))),
];

/**
 * Gets the selected user lists for the dashboard
 */
export const selectedUserLists = [
  selectedIds('user-lists'),
  filteredUserLists,
  (selectedUserListIds, userLists) =>
    userLists.filter(userList =>
      selectedUserListIds.contains(userList.get('id')),
    ),
];

export const sectionDetails = [
  changedSections,
  function(sectionObj) {
    let sectionDetailObject = null;
    if (sectionObj) {
      let parsedSections = _.union(
        sectionObj.get('added').toJS(),
        sectionObj.get('changed').toJS(),
      );
      parsedSections = parsedSections.map(obj => {
        let parsedVariations = obj.variations;
        if (!_.isArray(parsedVariations)) {
          parsedVariations = _.union(
            obj.variations.added,
            obj.variations.changed,
            obj.variations.unchanged,
          );
        }
        parsedVariations = parsedVariations.map(varObj => {
          const before =
            varObj.weight.before || varObj.weight.before === 0
              ? toPercentageString(varObj.weight.before)
              : null;
          let after =
            varObj.weight.after || varObj.weight.after === 0
              ? toPercentageString(varObj.weight.after)
              : null;
          if (after === null) {
            after = toPercentageString(varObj.weight);
          }
          return {
            description: varObj.description,
            weight: {
              before,
              after,
            },
          };
        });
        parsedVariations = _.sortBy(parsedVariations, 'description');
        return {
          title: obj.title,
          variations: parsedVariations,
        };
      });
      sectionDetailObject = _.sortBy(parsedSections, 'title');
    }
    return toImmutable(sectionDetailObject);
  },
];

export const variationDetails = [
  changedVariations,
  function(variationObj) {
    let variationDetailObject = null;
    if (variationObj) {
      const variations = _.union(
        variationObj.get('added').toJS(),
        variationObj.get('changed').toJS(),
        variationObj.get('unchanged').toJS(),
      );
      variationDetailObject = _.sortBy(
        variations.map(obj => {
          const before =
            obj.weight.before || obj.weight.before === 0
              ? toPercentageString(obj.weight.before)
              : null;
          let after =
            obj.weight.after || obj.weight.after === 0
              ? toPercentageString(obj.weight.after)
              : null;
          if (after === null) {
            after = toPercentageString(obj.weight);
          }

          return {
            description: obj.description,
            weight: {
              before,
              after,
            },
          };
        }),
        'description',
      );
    }
    return toImmutable(variationDetailObject);
  },
];

/**
 * Gets the selected data sources for the dashboard
 */
export const selectedDatasources = [
  selectedIds('datasources'),
  filteredDatasources,
  (selectedDatasourceIds, datasources) =>
    datasources.filter(datasource =>
      selectedDatasourceIds.contains(datasource.get('id')),
    ),
];

export function isSelected(category, id) {
  return [
    ['selectedItems', 'selectedItems'],
    function(state) {
      const categoryArray = state.get(category);
      if (categoryArray) {
        return categoryArray.indexOf(id) !== -1;
      }
      return false;
    },
  ];
}

export const projectVisibilityState = [
  CurrentProjectGetters.id,
  visibilityState,
  (projectId, state) =>
    state.getIn([projectId, `columns-${dashboardTabs.OVERVIEW}`]) ||
    DEFAULT_COLUMNS_VISIBILITY,
];

export const experimentationContentColumns = [
  CurrentProjectGetters.id,
  visibilityState,
  (projectId, state) =>
    state.getIn([projectId, `columns-${dashboardTabs.EXPERIMENTS}`]) ||
    DEFAULT_EXPERIMENTS_COLUMNS_VISIBILITY,
];

export const personalizationContentColumns = [
  CurrentProjectGetters.id,
  visibilityState,
  (projectId, state) =>
    state.getIn([projectId, `columns-${dashboardTabs.PERSONALIZATIONS}`]) ||
    DEFAULT_PERSONALIZATION_COLUMNS_VISIBILITY,
];

export const getColumnsVisibility = activeTab => {
  switch (activeTab) {
    case dashboardTabs.EXPERIMENTS:
      return experimentationContentColumns;
    case dashboardTabs.PERSONALIZATIONS:
      return personalizationContentColumns;
    case dashboardTabs.OVERVIEW:
    default:
      return projectVisibilityState;
  }
};

export const getActiveTab = [
  CurrentProjectGetters.id,
  visibilityState,
  (projectId, state) => state.getIn([projectId, 'active-tab']),
];

/**
 * Getters for dashboard
 */
export default {
  apiFilters,
  activeMainTab,
  activeCanvasIntegration,
  activeSubTab,
  activityFilters,
  hasMoreActivities: [
    ['activityFilters'],
    state => state.get('resultsLength') === state.getIn(['filters', '$limit']),
  ],
  hasNoActivities: [
    ['activityFilters'],
    state =>
      state.get('resultsLength') === 0 &&
      state.getIn(['filters', '$offset']) === 0,
  ],
  changedAllocations,
  changedAudiences,
  changedGoal,
  changedProject,
  changedSections,
  changedVariations,
  /**
   * Get the labs that should be enabled for the current project.
   */
  enabledLabs,
  filteredAudiences,
  filteredCollaborators,
  filteredDimensions,
  filteredExperiments,
  filteredExperimentationGroups,
  filteredGoals,
  filteredIntegrationsForAB,
  filteredIntegrationsForP13N,
  filteredUserLists,
  filteredDatasources,
  selectedActivity,
  selectedAudiences,
  selectedCollaborators,
  selectedDimensions,
  selectedExperiments,
  selectedGoals,
  selectedExperimentationGroups,
  selectedIntegration,
  selectedApp,
  selectedProject,
  selectedUserLists,
  selectedDatasources,
  selectedIds,
  /**
   * Get filter values for a specific category
   * @return {object}
   */
  tableFilters,
  sectionDetails,
  variationDetails,
  visibilityState,
  projectVisibilityState,
  experimentationContentColumns,
  personalizationContentColumns,
  getColumnsVisibility,
  isSelected,
  getActiveTab,
};

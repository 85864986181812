import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import Vue from 'vue';

// Monkey patch v-model so that it handles inputs from IMEs
import 'core/ui/methods/monkey_patch_vmodel';
import flux from 'core/flux';
import { getRootVM } from 'core/ui/methods/root_vm';
import getComponent from 'core/ui/methods/get_component';
import {
  showNotification,
  showPersistentNotification,
  clearPersistentNotification,
} from 'core/ui/methods/notifications';
import renderReactComponent from 'core/ui/methods/render_react_component';
import connectGetters from 'core/ui/methods/connect_getters';
import getMainRegionVM from 'core/ui/methods/get_main_region_vm';
import error from 'core/ui/methods/show_error';
import { $broadcast, $on, $off } from 'core/ui/methods/events';
import {
  getDialogManager,
  showDialog,
  hideDialog,
  showReactDialog,
} from 'core/ui/methods/dialogs';
import {
  loadingStart,
  loadingStop,
  loadingWhen,
  loadingEntityWhen,
  loadingEntityFinished,
} from 'core/modules/loading/actions';

// TODO (FEI-3382) - Core should not import from /optly
import historyUtils from 'optly/utils/history';
import LocalStorageWrapper from 'optly/utils/local_storage_wrapper';
import poll from 'optly/utils/poll';

import { actions as RegionActions, getters as RegionGetters } from './region';
import actionTypes from './action_types';
import constants from './constants';

import confirmComponent from './components/confirm';
import customDialogComponent from './components/custom_dialog';
import upsellComponent from './components/upsell';

import dialogManager from './components/dialog_manager';
import notifcationManager from './components/notification_manager';

/**
 * Renders a component to the main region
 * @param {Object} config
 * @param {ReactElement | VueElement} config.component
 * @param {Object?} config.data
 * @param {Object?} config.options
 * @param {Object?} config.metadata
 * @param {Boolean?} config.forceRerender
 */
export function renderMainRegion(config) {
  RegionActions.renderRegion('main', {
    component: config.component,
    componentId: config.componentId,
    options: config.options,
    data: config.data,
  });

  $broadcast('renderMainRegion');
}

/**
 * Mount the component provided as the root React component.
 * @param {React.Component} component
 * @param {string=} selector
 * @return {Promise} Resolved when ReactDOM.render has mounted the rootVM.
 */
export function mountRootVM(component, selector = '#root') {
  if (getRootVM()) {
    throw new Error(
      'A root component is already mounted - only one root component is allowed.',
    );
  }

  const rootVM = React.createElement(component, {
    ref: instance => {
      flux.dispatch(actionTypes.REGISTER_ROOT_VM, {
        vm: instance,
      });
    },
  });
  return new Promise(resolve => {
    poll.waitForSelector(selector).then(el => {
      ReactDOM.render(rootVM, el, resolve);
    });
  });
}

/**
 * Takes the standard Vue options
 * @param {Object} options
 * @param {string|HTMLElement} options.el id selector or HTMLElement as the mount point
 * @param {string} options.template
 * @param {Object} options.components
 * @param {Object} options.directives
 * @param {Object} options.filters
 * @param {string=} selector to mount rootVM to when it exists
 *
 * @return {Vue} root ViewModel
 */
export function mountLegacyVueRootVM(options, selector = null) {
  if (getRootVM()) {
    throw new Error(
      'A root component is already mounted - only one root component is allowed.',
    );
  }

  const rootVM = new Vue(options);

  // If we've been provided a selector, ensure a matching element exists in
  // the DOM, then replace that element with our rootVM $el.
  if (selector) {
    poll
      .waitForSelector(selector)
      .then(el => el.parentNode.replaceChild(rootVM.$el, el));
  }

  const DialogManager = Vue.extend(dialogManager);
  const dialogManagerVM = new DialogManager({
    parent: rootVM,
    handleHistoryBack: () => historyUtils.back(),
  });
  dialogManagerVM.$appendTo(rootVM.$el);

  // register the dialog manager in flux
  flux.dispatch(actionTypes.REGISTER_GLOBAL_COMPONENT, {
    id: 'dialogManager',
    instance: dialogManagerVM,
  });

  const NotificationManager = Vue.extend(notifcationManager);
  const notificationManagerVM = new NotificationManager({
    parent: rootVM,
  });
  notificationManagerVM.$appendTo(rootVM.$el);

  // register the notification manager in flux
  flux.dispatch(actionTypes.REGISTER_GLOBAL_COMPONENT, {
    id: 'notificationManager',
    instance: notificationManagerVM,
  });

  flux.dispatch(actionTypes.REGISTER_ROOT_VM, {
    vm: rootVM,
  });

  // ensure that the dialogManager and notificationManager elements are cleaned up
  const dialogManagerEl = dialogManagerVM.$el;
  const notificationManagerEl = notificationManagerVM.$el;
  rootVM.$on('hook:afterDestroy', () => {
    $(dialogManagerEl).remove();
    $(notificationManagerEl).remove();
    dialogManagerVM.$destroy();
    notificationManagerVM.$destroy();
  });

  return rootVM;
}

/**
 * @param {object} options
 * @param {string} options.title
 * @param {string} options.message
 * @param {string} options.confirmText
 * @param {string} options.cancelText
 * @param {boolean} options.isWarning
 * @return {jQuery.Deferred}
 */
export function confirm(options) {
  options = options || {};
  const def = $.Deferred();

  showDialog({
    component: confirmComponent,
    data: _.extend({}, options, {
      onResolve() {
        def.resolve();
      },
      onReject() {
        def.reject();
      },
    }),
  });
  return def;
}

/**
 * All cleanup necessary to reset the UI
 */
export function reset(keepRootVM, selector = '#root') {
  const rootVM = getRootVM();
  if (rootVM) {
    if (rootVM.$destroy) {
      rootVM.$destroy();
    } else {
      ReactDOM.unmountComponentAtNode(document.querySelector(selector));
    }
    flux.dispatch(actionTypes.RESET_ROOT_VM);
  }
}

/**
 * @param {object} options
 * @param {string} options.title
 * @param {string} options.label
 * @param {string} options.feature
 * @param {string} options.confirmText
 * @param {string} options.cancelText
 *
 * @return {jQuery.Deferred} when the dialog is closed
 */
export function showUpsell(options) {
  options = options || {};
  const onClose = $.Deferred();
  showDialog({
    component: upsellComponent,
    data: _.extend({}, options, {
      _onClose: onClose,
    }),
  });
  return onClose;
}

/**
 * This handler allows you to very quickly create a basic message pop-up dialog.
 *
 * @param {object} options
 * @param {string} options.title
 * @param {string} options.message
 * @param {boolean} options.showSupportLink
 *
 * @return {jQuery.Deferred} when the dialog is closed
 */
export function showCustomDialog(options) {
  options = options || {};
  const onClose = $.Deferred();
  showDialog({
    component: customDialogComponent,
    data: _.extend({}, options, {
      _onClose: onClose,
    }),
  });
  return onClose;
}

/**
 * Check if a component (by componentId) is shown as a dialog
 *
 * @param {String} componentId
 * @return {Boolean}
 */
export function isDialogShown(componentId) {
  return getDialogManager().isDialogShown(componentId);
}

export function hasDialogBeenShown(componentId) {
  const dialogHistory = JSON.parse(
    LocalStorageWrapper.getItem(constants.DIALOG_LOCAL_STORAGE_KEY),
  );
  if (!dialogHistory) {
    return false;
  }

  return !!dialogHistory[componentId];
}

/**
 * Destroy main region component
 */
export function destroyMainRegionComponent() {
  const mainNavigationController = flux.evaluate(
    RegionGetters.navigationController('main'),
  );
  try {
    mainNavigationController.resetState();
    RegionActions.unregisterComponent('main');
  } catch (e) {
    // swallow
  }
}

export const renderRegion = RegionActions.renderRegion;

export {
  getRootVM,
  getComponent,
  showNotification,
  $broadcast,
  $on,
  $off,
  getDialogManager,
  showDialog,
  hideDialog,
  showReactDialog,
  renderReactComponent,
  connectGetters,
  getMainRegionVM,
  loadingStart,
  loadingStop,
  loadingWhen,
  loadingEntityWhen,
  loadingEntityFinished,
  error,
};

/**
 * Public API
 */
const exported = {
  getRootVM,
  mountLegacyVueRootVM,
  mountRootVM,
  getComponent,
  getDialogManager,
  renderReactComponent,
  connectGetters,
  showReactDialog,
  showDialog,
  hideDialog,
  $broadcast,
  $off,
  $on,
  loadingStart,
  loadingStop,
  loadingWhen,
  loadingEntityWhen,
  loadingEntityFinished,
  confirm,
  renderRegion,
  renderMainRegion,
  getMainRegionVM,
  showNotification,
  showPersistentNotification,
  clearPersistentNotification,
  error,
  reset,
  showUpsell,
  showCustomDialog,
  isDialogShown,
  destroyMainRegionComponent,
  hasDialogBeenShown,
};

export default exported;

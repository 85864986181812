import React from 'react';

import { connect } from 'core/ui/decorators';

import NavConstants from 'optly/services/navigation';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { fns as PermissionsFns } from 'optly/modules/permissions';
import {
  constants as SectionModuleConstants,
  getters as SectionModuleGetters,
} from 'bundles/p13n/sections/layers/section_module';

import GroupTableComponent from 'bundles/p13n/components/group_table';
import Topbar from 'bundles/p13n/sections/layers/components/topbar';

const TABLE_ID = SectionModuleConstants.TABLE_IDS.GROUPS;

@connect({
  isEmptyState: [
    CurrentProjectGetters.experimentationGroups,
    groups => groups.size === 0,
  ],
  groups: SectionModuleGetters.filteredAndSortedGroups(TABLE_ID),
  currentProjectId: CurrentProjectGetters.id,
  canCreateGroup: [
    CurrentProjectGetters.project,
    PermissionsFns.canCreateGroup,
  ],
  canArchiveGroup: [
    CurrentProjectGetters.project,
    PermissionsFns.canArchiveGroup,
  ],
})
class GroupsPageComponent extends React.Component {
  static componentId = 'GroupsPageComponent';

  render() {
    return (
      <div
        className="stage__item__content--column"
        data-test-section="web-groups-dashboard">
        <Topbar activeTab={NavConstants.LayersTabs.GROUPS} />
        <div className="stage__item__content--column">
          <div className="overflow-y--auto flex flex--1">
            <GroupTableComponent
              tableId={TABLE_ID}
              helpUrl="https://help.optimizely.com/Build_Campaigns_and_Experiments/Mutually_exclusive_experiments_in_Optimizely_X_Web"
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GroupsPageComponent;

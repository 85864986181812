import { getFeatureVariable } from '@optimizely/js-sdk-lab/src/actions';

import RestApi from 'optly/modules/rest_api';
import { enums as ChampagneEnums } from 'optly/modules/optimizely_champagne';

import definition from './entity_definition';
import * as AjaxUtil from '../../../utils/ajax';

const baseEntityActions = RestApi.createEntityActions(definition);

export const { fetch, fetchAll, fetchPage, fetchAllPages } = baseEntityActions;

const deleteUser = baseEntityActions.delete;

export { deleteUser as delete };

export function fetchAllPagesOfUsers(adminAccountId, force = false) {
  const fetchFilters = {
    account_id: adminAccountId,
    $limit: getPageSize(),
  };
  return this.fetchAllPages(fetchFilters, {
    skipEvaluatingCachedData: true,
    force,
  });
}

export function getPageSize() {
  const {
    FEATURE_KEY,
    VARIABLES,
  } = ChampagneEnums.FEATURES.fetch_all_paginated;
  return (
    getFeatureVariable(FEATURE_KEY, VARIABLES.page_size) ||
    RestApi.constants.DEFAULT_PAGE_SIZE
  );
}

// this exist because fetch on a 404 creates an action that will pop up an error dialog, we do not want that
export function doesUserRoleExist(accountId, email) {
  return AjaxUtil.makeV1AjaxRequest({
    url: `/api/v1/accounts/${accountId}/user_roles/${email}`,
    type: 'GET',
  });
}

export default {
  ...baseEntityActions,
  fetchAllPagesOfUsers,
  getPageSize,
  doesUserRoleExist,
};

import { connect, errorBoundary } from 'core/ui/decorators';
import ui from 'core/ui';
import Router from 'core/router';
import flux from 'core/flux';
import locationHelper from 'optly/location';

import moment from 'moment';
import { debounce, memoize } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { withTrack } from '@optimizely/segment-js/dist/decorators';

import AdminAccountGetters from 'optly/modules/admin_account/getters';
import CurrentProjectActions from 'optly/modules/current_project/actions';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import PermissionsGetters from 'optly/modules/permissions/getters';
import PermissionsConstants from 'optly/modules/permissions/constants';

import {
  getFeatureVariableBoolean,
  getFeatureVariableInteger,
  getFeatureVariableString,
  isFeatureEnabled,
} from '@optimizely/js-sdk-lab/src/actions';

import PerformanceTrackingActions from 'optly/modules/performance_tracking/actions';
import SentryActions from 'optly/modules/sentry/actions';
import { project_types as projectTypes } from 'optly/modules/entity/project/enums';
import { showSupportDialog } from 'optly/modules/support/actions';

import { Button, EmptyDashboard, Link } from 'optimizely-oui';

import LoadingOverlay from 'react_components/loading_overlay';

import ComponentModuleActions from './component_module/actions';
import ComponentModuleFns from './component_module/fns';
import ComponentModuleConstants from './component_module/constants';

import ChangesTable from './changes_table';
import Footer from './footer';
import Header from './header';

import ChangeHistoryEmptyStateImage from '/static/img/p13n/change-history-empty-state.svg';

export const {
  DEFAULT_MAX_API_FILTERS,
  EntityType,
  UiStyles,
} = ComponentModuleConstants;

const memoizedComputeChangeIdToNameMap = memoize(changes => {
  return changes.reduce(
    (acc, change) => ({
      ...acc,
      [change.id]: change.summary,
    }),
    {},
  );
});

@withTrack
@connect({
  currentProjectCreatedDate: CurrentProjectGetters.createdDate,
  changeHistoryLimit: PermissionsGetters.changeHistoryLimit,
  currentProjectId: CurrentProjectGetters.id,
  currentProjectType: CurrentProjectGetters.currentProjectType,
  isFlagsProject: CurrentProjectGetters.isFlagsProject,
  isGaeAdmin: AdminAccountGetters.isAdmin,
})
@errorBoundary({
  alternateContent: (
    <EmptyDashboard
      button={
        <Button onClick={() => showSupportDialog()} style="highlight">
          Optimizely Help Center
        </Button>
      }
      description={
        <div className="reading-column flush--top">
          Don't worry, most issues are minor. Please refresh the page or visit
          our Help Center if the issue persists.
        </div>
      }
      headline="Something went wrong"
      showButtonBelow={true}
    />
  ),
})
class ChangeHistory extends Component {
  static propTypes = {
    changeHistoryLimit: PropTypes.number,
    currentProjectCreatedDate: PropTypes.string,
    currentProjectId: PropTypes.number.isRequired,
    currentProjectType: PropTypes.oneOf([
      projectTypes.WEB,
      projectTypes.FULL_STACK,
      projectTypes.ROLLOUTS,
    ]).isRequired,
    customEntityTypes: PropTypes.arrayOf(PropTypes.string),
    hasAllEntities: PropTypes.bool,
    /** List of entityType:entityId pairs (experiment:id, event:id etc.) for initial filters on mount */
    initialEntityFilter: PropTypes.arrayOf(PropTypes.string),
    /** List of UiEntityTypes ('personalization', 'multivariate_test', 'experiment', 'event' etc.) for initial filters on mount */
    initialTypeFilter: PropTypes.arrayOf(PropTypes.string),
    isFlagsProject: PropTypes.bool,
    isGaeAdmin: PropTypes.bool,
    track: PropTypes.func,
    uiStyle: PropTypes.oneOf([UiStyles.MANAGER, UiStyles.PROJECT]),
  };

  static defaultProps = {
    hasAllEntities: false,
    changeHistoryLimit: PermissionsConstants.UNLIMITED_VALUE,
    currentProjectCreatedDate: '',
    customEntityTypes: [],
    initialEntityFilter: [],
    initialTypeFilter: [],
    isGaeAdmin: false,
    track: () => {},
    uiStyle: UiStyles.PROJECT,
  };

  constructor(props) {
    super(props);

    const {
      hasAllEntities,
      changeHistoryLimit,
      currentProjectId,
      initialEntityFilter,
      initialTypeFilter,
      isGaeAdmin,
      uiStyle,
    } = props;

    const allowedUrlFiltersFromUrl = ComponentModuleFns.computeAllowedUrlFiltersFromUrl();

    const { entity } = allowedUrlFiltersFromUrl;

    this.changeHistoryKbLink = CurrentProjectActions.getHelpCopy(
      'change_history_link',
      'https://help.optimizely.com/Troubleshoot_Problems/View_project_or_experiment_change_history',
    );

    this.changesToFetch =
      getFeatureVariableInteger(
        'change_history_improvements',
        'api_page_size',
      ) || ComponentModuleConstants.DEFAULT_CHANGES_TO_FETCH;

    this.earliestAllowedFilterDate = getFeatureVariableString(
      'change_history_improvements',
      'earliest_allowed_filter_date',
    );

    /**
     * Search is an internal feature for now, so only show the search box if:
     * - the user is a GAE Admin (e.g. via Emulate)
     * - OR there are ?enity params AND we're NOT in the manager change history view
     * - OR the ?search=true param exists
     */
    this.isChangeHistoryIdSearchEnabled =
      isGaeAdmin ||
      (uiStyle !== UiStyles.MANAGER && entity && entity.length) ||
      locationHelper.getSearch().includes('search=true');

    // TODO(FEI-3385) Investigate library or solution on efficient infinite scrolling in React and replace this
    this.maxRowsRenderable = locationHelper
      .getSearch()
      .includes('row_limit=false')
      ? NaN
      : getFeatureVariableInteger(
          'change_history_improvements',
          'max_rows_renderable',
        );

    this.prettyDiffLineCap =
      getFeatureVariableInteger(
        'change_history_improvements',
        'pretty_diff_line_cap',
      ) || ComponentModuleConstants.DEFAULT_PRETTY_DIFF_LINE_CAP;

    const shouldPrettifyDiff = getFeatureVariableBoolean(
      'change_history_improvements',
      'should_prettify_diff',
    );

    this.canUserSeeRevisionsWithoutChanges = isFeatureEnabled(
      'can_user_see_revisions_without_changes',
    );

    this.shouldPrettifyDiff =
      typeof shouldPrettifyDiff === 'boolean'
        ? shouldPrettifyDiff
        : ComponentModuleConstants.DEFAULT_SHOULD_PRETTIFY_DIFF;

    const startTime =
      changeHistoryLimit === PermissionsConstants.UNLIMITED_VALUE
        ? null
        : moment()
            .subtract(changeHistoryLimit, 'days')
            .startOf('day')
            .toISOString();

    this.state = {
      changes: [],
      filters: {
        all_entities: hasAllEntities,
        end_time: null,
        entity: initialEntityFilter,
        id: [],
        page: 1,
        per_page: this.changesToFetch,
        project_id: currentProjectId,
        start_time: startTime, // change history limit days ago
        // The "type" filter is transformred to "entity_type" for the API. See ComponentModuleConstants.UiEntityType for more details
        type: initialTypeFilter,
        ...allowedUrlFiltersFromUrl,
      },
      isAllDetailExpanded: false,
      /**
       * Number that will be increased and used via the key prop to force a new instance of the table row component in a specific use-case where
       * after expanding a few rows, alt+click-ing one of those rows' "Hide details" button fails to work as expected
       */
      allDetailExpandedCount: 0,
      isFilterApplied: false,
      isLoading: true,
      isLoadingNextPage: false,
      nextPageUrl: null,
      queryTotalCount: null,
    };
  }

  componentDidMount() {
    const { filters } = this.state;
    const { track } = this.props;

    PerformanceTrackingActions.setPerformanceMark(
      'change_history_component_mount',
    );
    PerformanceTrackingActions.setPerformanceMark('change_history_fetch_begin');

    ComponentModuleActions.fetchChanges(filters)
      .then(this.onFetchChangesResolve, this.onFetchChangesReject)
      .then(() =>
        PerformanceTrackingActions.setPerformanceMark(
          'change_history_component_ready',
        ),
      );

    track(ComponentModuleConstants.SegmentEvents.PAGE_VIEW, {
      location: locationHelper.getLocation(),
    });

    const currentProject = flux.evaluate(CurrentProjectGetters.project);

    if (currentProject.get('is_flags_enabled') && !Router.instance.__routes) {
      // Required for the History page on Flags
      Router.initialize();
      Router.loadRoutes([
        {
          match: '/v2/projects/:projId/flags/*',
          handle: [
            // catchall no-op
            function() {},
          ],
        },
      ]);
    }
  }

  /**
   * @name handleFetchPageNo
   * @description Class function for fetching the specified page for the current filters, used in Footer component
   */
  handleFetchPageByNumber = pageNumber => {
    const { filters } = this.state;
    const { track } = this.props;

    this.setState({ isLoadingNextPage: true }, () => {
      const updatedFilters = {
        ...filters,
        page: pageNumber,
      };
      ComponentModuleActions.fetchChanges(updatedFilters).then(
        ({ changes, nextPageUrl, queryTotalCount }) => {
          this.setState(
            {
              changes,
              nextPageUrl,
              queryTotalCount,
              filters: updatedFilters,
              isLoadingNextPage: false,
            },
            () => {
              track(ComponentModuleConstants.SegmentEvents.GO_TO_PAGE_CLICKED, {
                pageNumber,
                queryTotalCount,
              });
            },
          );
        },
        this.onFetchChangesReject,
      );
    });
  };

  /**
   * @name handleFiltersAction
   * @description Class function for updating filter state and optionally applying filters
   *  in order to fetch first page. Used in Header component
   *
   * @param {Object} updatedFilters
   */
  handleFiltersAction = (updatedFilters = {}) =>
    this.setState(prevState => {
      const updatedState = {
        ...prevState,
        filters: {
          ...prevState.filters,
          ...updatedFilters,
          page: 1,
        },
      };

      const { track } = this.props;
      const { filters } = updatedState;

      ComponentModuleActions.setRouteAndFetchChanges(filters).then(response => {
        this.onFetchChangesResolve(response);

        const [type] = filters.type;
        track(ComponentModuleConstants.SegmentEvents.FILTER_APPLIED, {
          startTime: filters.start_time,
          endTime: filters.end_time,
          entityType: type || 'All',
          filteredQueryTotal: response.queryTotalCount,
        });
      }, this.onFetchChangesReject);
      return {
        ...updatedState,
        isLoading: true,
      };
    });

  handleShowHideAll = isAllDetailExpanded => {
    const { track } = this.props;
    const { changes } = this.state;
    this.setState(
      prevState => ({
        isAllDetailExpanded,
        allDetailExpandedCount: prevState.allDetailExpandedCount + 1,
      }),
      () => {
        const segmentEvent = isAllDetailExpanded
          ? ComponentModuleConstants.SegmentEvents.SHOW_ALL_DETAILS_CLICKED
          : ComponentModuleConstants.SegmentEvents.HIDE_ALL_DETAILS_CLICKED;
        track(segmentEvent, {
          recordsShown: changes.length,
        });
      },
    );
  };

  /**
   * @name onFetchChangesReject
   * @description
   *  Shows UI notification for fetch failure and sets isLoading state to false,
   *  for use with Promise rejection via ComponentModuleActions.setRouteAndFetchChanges()
   */
  onFetchChangesReject = () => {
    this.setState({
      isLoading: false,
      isLoadingNextPage: false,
    });

    ui.showNotification({
      message: 'There was a problem fetching change history',
      type: 'error',
    });

    SentryActions.captureMessage('Failure fetching changes from public API');
  };

  /**
   * @name onFetchChangesResolve
   * @description
   *  Sets expected changes data as state, for use with
   *  Promise resolution via ComponentModuleActions.setRouteAndFetchChanges()
   *
   * @param {Object} data
   * @param {Array} data.changes
   * @param {String|Undefined} data.nextPageUrl
   * @param {Number} data.queryTotalCount
   */
  onFetchChangesResolve = ({ changes, nextPageUrl, queryTotalCount }) => {
    const { customEntityTypes } = this.props;

    // Need to filter out changes that are not related to customEntityTypes.
    const filteredChanges = changes.filter(
      item =>
        !customEntityTypes.length ||
        customEntityTypes.includes(item.entity.type),
    );

    // Need to subtract the filtered out changes that are not related to customEntityTypes.
    const filteredQueryTotalCount =
      queryTotalCount - (changes.length - filteredChanges.length);

    PerformanceTrackingActions.setPerformanceMark(
      'change_history_fetch_complete',
    );

    // The following is to ensure New Relic tracking happens as close to after component state is updated as possible
    return new Promise(resolve =>
      this.setState(
        ({ allDetailExpandedCount }) => ({
          changes: filteredChanges,
          nextPageUrl,
          queryTotalCount: filteredQueryTotalCount,
          allDetailExpandedCount: allDetailExpandedCount + 1,
          isAllDetailExpanded: false,
          isLoading: false,
        }),
        resolve,
      ),
    );
  };

  render() {
    const {
      currentProjectCreatedDate,
      currentProjectType,
      currentProjectId,
      customEntityTypes,
      isFlagsProject,
      isGaeAdmin,
      uiStyle,
    } = this.props;

    const {
      allDetailExpandedCount,
      changes,
      filters,
      isAllDetailExpanded,
      isLoading,
      isLoadingNextPage,
      queryTotalCount,
    } = this.state;

    const { length: changesLength } = changes;
    const isFilterApplied = ComponentModuleFns.isUiFiltered(filters);

    return (
      <React.Fragment>
        <Header
          changeIdToNameMap={
            // Only compute this map if filter IDs are set and changes are updated
            filters.id.length ? memoizedComputeChangeIdToNameMap(changes) : {}
          }
          currentProjectCreatedDate={currentProjectCreatedDate}
          currentProjectType={currentProjectType}
          customEntityTypes={customEntityTypes}
          earliestAllowedFilterDate={this.earliestAllowedFilterDate}
          filters={filters}
          handleFiltersAction={this.handleFiltersAction}
          isChangeHistoryIdSearchEnabled={this.isChangeHistoryIdSearchEnabled}
          isDisabled={isLoading || isLoadingNextPage}
          isFlagsProject={isFlagsProject}
          isGaeAdmin={isGaeAdmin}
          uiStyle={uiStyle}
        />
        {changesLength === 0 && !isLoading ? (
          <EmptyDashboard
            description={
              <React.Fragment>
                Can't find your changes? It may have occurred before the new
                change history feature.
              </React.Fragment>
            }
            headline={
              isFilterApplied ? 'No changes found' : 'No changes available'
            }
            imagePath={ChangeHistoryEmptyStateImage}
            showButtonBelow={true}
          />
        ) : (
          <LoadingOverlay
            className="height--1-1 soft-double--sides soft-double--bottom overflow-y--auto"
            isLoading={isLoading}>
            {changesLength !== 0 && (
              <React.Fragment>
                <ChangesTable
                  allDetailExpandedCount={allDetailExpandedCount}
                  changes={changes}
                  currentProjectId={currentProjectId}
                  handleShowHideAll={this.handleShowHideAll}
                  isAllDetailExpanded={isAllDetailExpanded}
                  prettyDiffLineCap={this.prettyDiffLineCap}
                  shouldPrettifyDiff={this.shouldPrettifyDiff}
                  canUserSeeRevisionsWithoutChanges={
                    this.canUserSeeRevisionsWithoutChanges
                  }
                />
                <Footer
                  currentPageNo={filters.page}
                  handleFetchPageByNumber={this.handleFetchPageByNumber}
                  isLoadingNewPage={isLoadingNextPage}
                  pageSize={this.changesToFetch}
                  queryTotalCount={queryTotalCount}
                />
              </React.Fragment>
            )}
          </LoadingOverlay>
        )}
      </React.Fragment>
    );
  }
}

const debouncedShowMaxFiltersNotification = debounce(
  maxApiFilters =>
    ui.showNotification({
      message: `Showing results from the maximum ${maxApiFilters} filters. The experiment is too large to show additional results. View all changes in Project History`,
      type: 'warning',
    }),
  100,
);

export const withSidebarAndManagerChangeHistory = SidebarComponent =>
  class extends React.Component {
    // Set with dynamic epoch time since the componentId needs to change for renderMainRegion to remount the component
    static componentId = `manager-change-history-${+new Date()}`;

    static displayName = 'ManagerChangeHistory';

    static propTypes = {
      fetchedEntities: PropTypes.shape({
        [EntityType.campaign.entityType]: PropTypes.array,
        [EntityType.environment.entityType]: PropTypes.array,
        [EntityType.experiment.entityType]: PropTypes.array,
        [EntityType.feature.entityType]: PropTypes.array,
        [EntityType.project.entityType]: PropTypes.array,
      }),
      sidebarProps: PropTypes.object,
    };

    static defaultProps = {
      fetchedEntities: {},
      sidebarProps: {},
    };

    maxApiFilters =
      getFeatureVariableInteger(
        'change_history_improvements',
        'max_api_filters',
      ) || ComponentModuleConstants.DEFAULT_MAX_API_FILTERS;

    render() {
      const { fetchedEntities, sidebarProps } = this.props;
      let initialEntityFilter = ComponentModuleFns.computeEntityFilterFromFetchedEntities(
        fetchedEntities,
      );

      // The browser will reject the request around 150 filters, so we truncate and notify well before that
      if (initialEntityFilter.length > this.maxApiFilters) {
        initialEntityFilter = initialEntityFilter.splice(0, this.maxApiFilters);
        debouncedShowMaxFiltersNotification(this.maxApiFilters);
      }

      return (
        <div
          className="two-col"
          data-test-section="change-history-manager-view">
          <SidebarComponent {...sidebarProps} />
          <div className="two-col__content">
            <div className="flex push-quad flush--bottom">
              <h1>History</h1>
            </div>
            <ChangeHistory
              initialEntityFilter={initialEntityFilter}
              uiStyle={UiStyles.MANAGER}
            />
          </div>
        </div>
      );
    }
  };

export const withTopbarAndProjectChangeHistory = TopbarComponent =>
  class extends React.Component {
    // Set with dynamic epoch time since the componentId needs to change for renderMainRegion to remount the component
    static componentId = `project-change-history-${+new Date()}`;

    static displayName = 'ProjectChangeHistory';

    static propTypes = {
      topbarProps: PropTypes.object,
    };

    static defaultProps = {
      topbarProps: {},
    };

    render() {
      const { topbarProps } = this.props;
      return (
        <div
          className="stage__item__content--column"
          data-test-section="change-history-project-view">
          <TopbarComponent {...topbarProps} />
          <ChangeHistory uiStyle={UiStyles.PROJECT} />
        </div>
      );
    }
  };

export default ChangeHistory;

const React = require('react');
const Select = require('react_components/select').default;

const capitalize = require('optly/filters/capitalize');

const choices = [
  'static',
  'absolute',
  'fixed',
  'relative',
  'initial',
  'inherit',
];

const PositionSelect = props => (
  <Select {...props}>
    {choices.map(choice => (
      <Select.Option
        key={choice}
        value={choice}
        label={capitalize(choice)}
        testSection={`position-option-${choice}`}
      />
    ))}
  </Select>
);

module.exports = PositionSelect;

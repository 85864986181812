export const eventTypes = {
  CLICK: 'click',
  CUSTOM: 'custom',
  PAGE_VIEW: 'pageview',
};

export const filterTypes = {
  TARGET_SELECTOR: 'target_selector',
};

export default {
  eventTypes,
  filterTypes,
};

const DEFAULT_MESSAGE = 'Would you like to buy something?';
const DEFAULT_VARIATION = 'Offer available until midnight. Buy it now?';

const SORT_METHOD_VARIATION_1 = 'Variation 1 shows popular products first!';
const SORT_METHOD_VARIATION_2 = 'Variation 2 shows relevant products first!';

const FLAG_KEY_PURCHASE_OPTION = 'purchase_option';
const FLAG_KEY_PRODUCT_SORT = 'product_sort';

const VARIABLE_KEY_MESSAGE = 'message';
const VARIABLE_KEY_SORT_METHOD = 'sort_method';

const ONBOARDING_LOCAL_STORAGE_KEY = 'onboarding_data';

const PROJECT_TYPE_FLAGS = 'project_type_flags';
const PROJECT_TYPE_LEGACY = 'project_type_legacy';

const LANGUAGES = [
  {
    value: 'node',
    title: 'NodeJS',
    subtitle: 'v10.0.4',
    image: '/static/img/x/logos/node.svg',
    isAvailable: true,
  },
  {
    value: 'android',
    title: 'Android',
    subtitle: 'Java API 19 or greater',
    image: '/static/img/x/logos/android.svg',
    isAvailable: true,
  },
  {
    value: 'csharp',
    title: 'C#',
    subtitle: '.NET Core 2.0 or later',
    image: '/static/img/x/logos/csharp.svg',
    isAvailable: true,
  },
  {
    value: 'go',
    title: 'Go',
    subtitle: '',
    image: '/static/img/x/logos/go.svg',
    isAvailable: true,
  },
  {
    value: 'ios',
    title: 'iOS',
    subtitle: '(tutorial not yet available)',
    image: '/static/img/x/logos/apple.svg',
    isAvailable: false,
  },
  {
    value: 'java',
    title: 'Java',
    subtitle: '',
    image: '/static/img/x/logos/java.svg',
    isAvailable: true,
  },
  {
    value: 'javascript',
    title: 'JavaScript',
    subtitle: 'Modern browsers',
    image: '/static/img/x/logos/javascript.svg',
    isAvailable: true,
  },
  {
    value: 'php',
    title: 'PHP',
    subtitle: '',
    image: '/static/img/x/logos/php.svg',
    isAvailable: true,
  },
  {
    value: 'python',
    title: 'Python',
    subtitle: 'v3',
    image: '/static/img/x/logos/python.svg',
    isAvailable: true,
  },
  {
    value: 'react',
    title: 'React',
    subtitle: 'v16.3.0 or greater',
    image: '/static/img/x/logos/react.svg',
    isAvailable: true,
  },
  {
    value: 'ruby',
    title: 'Ruby',
    subtitle: '',
    image: '/static/img/x/logos/ruby.svg',
    isAvailable: true,
  },
  {
    value: 'swift',
    title: 'Swift',
    subtitle: 'v5.0 or later',
    image: '/static/img/x/logos/swift.svg',
    isAvailable: true,
  },
  {
    value: 'flutter',
    title: 'Flutter',
    subtitle: '',
    image: '/static/img/x/logos/flutter.svg',
    isAvailable: true,
  },
];

const AVAILABLE_LANGUAGE_NAMES = LANGUAGES.filter(
  language => language.isAvailable,
).map(language => language.value);

export {
  DEFAULT_MESSAGE,
  DEFAULT_VARIATION,
  ONBOARDING_LOCAL_STORAGE_KEY,
  LANGUAGES,
  AVAILABLE_LANGUAGE_NAMES,
  SORT_METHOD_VARIATION_1,
  SORT_METHOD_VARIATION_2,
  FLAG_KEY_PURCHASE_OPTION,
  FLAG_KEY_PRODUCT_SORT,
  VARIABLE_KEY_MESSAGE,
  VARIABLE_KEY_SORT_METHOD,
  PROJECT_TYPE_FLAGS,
  PROJECT_TYPE_LEGACY,
};

export default {
  DEFAULT_MESSAGE,
  DEFAULT_VARIATION,
  ONBOARDING_LOCAL_STORAGE_KEY,
  LANGUAGES,
  AVAILABLE_LANGUAGE_NAMES,
  SORT_METHOD_VARIATION_1,
  SORT_METHOD_VARIATION_2,
  FLAG_KEY_PURCHASE_OPTION,
  FLAG_KEY_PRODUCT_SORT,
  VARIABLE_KEY_MESSAGE,
  VARIABLE_KEY_SORT_METHOD,
  PROJECT_TYPE_FLAGS,
  PROJECT_TYPE_LEGACY,
};

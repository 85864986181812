/**
 * ConditionGroup model
 *
 *
 * Condition data structure:
 *   {
 *    name: <string>,
 *    value: <string|number>,
 *    match_type: <value>,
 *    type: <string>
 *   }
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const isPlainObject = require('is-plain-object');
/**
 * White list of condition properties we care about
 */
const CONDITION_PROPS = ['name', 'value', 'match_type', 'type'];
/**
 * @constructor
 *
 * Note: methods cannot go on the prototype since Vue will override it when it attaches
 * the EventEmitter
 *
 * @param {string} type
 */
function ConditionGroup(type) {
  const self = this;

  /**
   * Hack since we can't check `instanceof`
   */
  this.IS_GROUP = true;

  /**
   * @var {string} <'and'|'or'>
   */
  this.type = type;

  /**
   * @var {boolean}
   */
  this.negate = false;

  /**
   * @var {Array.<Condition|ConditionGroup>}
   */
  this.conditions = [];

  /**
   * @param {Condition|ConditionGroup} condition
   */
  this.removeCondition = function(condition) {
    const ind = self.conditions.indexOf(condition);
    if (ind !== -1) {
      self.conditions.splice(ind, 1);
    }
  };

  /**
   * Recursively removes empty child condition groups
   */
  this.deleteEmptyConditions = function() {
    let dirty = false;

    self.conditions.forEach((val, ind) => {
      if (val.IS_GROUP) {
        if (val.conditions.length === 0) {
          self.conditions.splice(ind, 1);
          dirty = true;
        } else {
          const result = val.deleteEmptyConditions();
          if (!dirty) {
            // if the current iteration wasn't dirty,
            // mark as dirty if the a child condition group was marked dirty
            dirty = result;
          }
        }
      }
    });

    // once all the conditions are recursively cleared try to clear outer level
    if (dirty) {
      self.deleteEmptyConditions();
    }

    return dirty;
  };

  /**
   * Serialize to JS object
   *
   * Data structure:
   * [
   *   'and',
   *   [
   *     'or',
   *     [
   *       'or',
   *       {
   *         name: ''
   *         value: 'value1',
   *         match_type: null,
   *         type: 'ad_campaign'
   *       },
   *       {
   *         name: ''
   *         value: 'value2',
   *         match_type: null,
   *         type: 'ad_campaign'
   *       },
   *     ],
   *   ],
   *   [
   *     'not',
   *     [
   *       'or':
   *       {
   *         name: ''
   *         value: 'value2',
   *         match_type: null,
   *         type: 'ad_campaign'
   *       },
   *     ]
   *   ]
   * ]
   *
   * @return {Object}
   */
  this.serialize = function() {
    if (!this.type && this.conditions.length > 0) {
      throw new Error('Type cannot be undefined to call serialize()');
    }
    if (!this.type) {
      return [];
    }

    let serialized = [self.type];

    // conditions is an array of ConditionGroups or condition literals (never a mix)
    self.conditions.forEach(condition => {
      if (condition.IS_GROUP) {
        // condition is a ConditionGroup
        serialized.push(condition.serialize());
      } else {
        // condition is a condition literal,
        const cleanedCondition = {};
        // white list properties so Vue related things like '$index' don't get included
        CONDITION_PROPS.forEach(prop => {
          // Ensure prop is in condition and that it's value is not undefined
          if (
            Object.prototype.hasOwnProperty.call(condition, prop) &&
            typeof condition[prop] !== 'undefined'
          ) {
            cleanedCondition[prop] = condition[prop];
          }
        });
        serialized.push(cleanedCondition);
      }
    });

    if (self.negate) {
      serialized = ['not', serialized];
    }

    return serialized;
  };

  /**
   * Load JS object of same structure as this.serialize()
   *
   * @param {Object} data
   */
  this.load = function(data) {
    const RE_AND_OR = /^and|or$/;
    // remove existing conditions
    self.conditions = [];

    if (data.length === 0) {
      return;
    }

    const current = data;
    // handle the ['not', ['or' ...]] case
    if (current[0] === 'not') {
      self.negate = true;

      if (current.length > 2) {
        throw new Error(
          '`not` is unary operator, cannot have multiple conditions or condition groups',
        );
      }
      const condition = current[1];

      // recursively load the condition data ['not', ['or', ...]] or ['not
      // in the case where current[1] is another condition group: ['not', ['or', { cond1 }]]
      if (Array.isArray(condition)) {
        self.load(condition);
      } else if (isPlainObject(condition)) {
        // current[1] is a condition literal, must transform to ['or', { cond }]
        self.load(['or', condition]);
      } else {
        throw new Error(
          `Expecting Array or Object, got: ${JSON.stringify(condition)}`,
        );
      }
    } else {
      // current[0] now HAS to point the logical type ('and'|'or')
      if (!RE_AND_OR.test(current[0])) {
        throw new Error(`Expecting and|or, got: ${current[0]}`);
      }
      self.type = current[0];

      current.slice(1).forEach(condition => {
        if (Array.isArray(condition)) {
          // create and load a new condition group
          const newConditionGroup = new ConditionGroup();
          newConditionGroup.load(condition);
          self.conditions.push(newConditionGroup);
        } else if (isPlainObject(condition)) {
          // handle condition literal of form:
          // { name: <string>, match_type: <string>, value: <string|number>, type: <string> }
          const conditionToAppend = {};
          CONDITION_PROPS.forEach(key => {
            if (Object.prototype.hasOwnProperty.call(condition, key)) {
              conditionToAppend[key] = condition[key];
            }
          });
          self.conditions.push(conditionToAppend);
        } else {
          throw new Error(
            `Expecting Array or Object, got: ${JSON.stringify(condition)}`,
          );
        }
      });
    }
  };
}

module.exports = ConditionGroup;

/**
 * Spinner directive
 *
 * This is an empty directive, meaning it is attached via:
 * <div v-spinner="watchedVar"
 *      data-spinner-size="small"
 *      data-spinner-overlay></div>
 *
 * @author Cheston Lee
 */
import $ from 'jquery';

import sprintf from 'sprintf';

const OVERLAY_CLASS = 'lego-overlay';
const HIDDEN_CLASS = 'display--none';
const SPINNER_SIZE_PREFIX = 'lego-spinner--';

const exported = {
  bind() {
    this.options = {
      // Accepted sizes are `tiny` or `small`
      size: this.el.hasAttribute('data-spinner-size')
        ? SPINNER_SIZE_PREFIX + this.el.getAttribute('data-spinner-size')
        : null,
      overlay: this.el.hasAttribute('data-spinner-overlay')
        ? OVERLAY_CLASS
        : null,
    };

    this.spinner = sprintf(
      '<div class="%s"><div class="lego-spinner %s"></div></div>',
      this.options.overlay,
      this.options.size,
    );
    this.$spinner = $(this.spinner);
    this.$el = $(this.el);
    this.$el.append(this.$spinner);
  },

  unbind() {
    this.$spinner.remove();
  },

  update(val) {
    if (val) {
      this.$spinner.removeClass(HIDDEN_CLASS);
    } else {
      this.$spinner.addClass(HIDDEN_CLASS);
    }
  },
};

export default exported;

export const { bind, unbind, update } = exported;

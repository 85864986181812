import actions from './actions';
import fns from './fns';
import getters from './getters';
import humanReadable from './human_readable';

export { actions, fns, getters, humanReadable };

export default {
  actions,
  fns,
  getters,
  humanReadable,
};

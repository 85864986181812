const SpectrumDirective = require('optly/directives/spectrum');

const template = require('./color.html');
const createPluginConfigField = require('./create_plugin_config_field');

const SPECTRUM_PREFIX = 'spectrum:';

module.exports = createPluginConfigField({
  template,

  computed: {
    spectrumOptions() {
      const options = {
        showAlpha: this.inputConfig.options.mode === 'rgba',
      };
      return JSON.stringify(options);
    },
  },

  methods: {
    getValue() {
      return this.value;
    },

    _handleSpectrumUpdate(propertyName, value) {
      if (this.inputConfig.options.mode === 'rgba') {
        const rgbaString = `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
        this.updateValue(rgbaString);
      }
      if (this.inputConfig.options.mode === 'hex') {
        const rgb = (value.r << 16) + (value.g << 8) + value.b;
        const hexString = `#${(0x1000000 + rgb).toString(16).slice(1)}`;
        this.updateValue(hexString);
      }
    },
  },

  directives: {
    spectrum: SpectrumDirective,
  },

  ready() {
    this.$on(`${SPECTRUM_PREFIX}update`, this._handleSpectrumUpdate.bind(this));

    // This is to update the spectrum plugin when the color value binding
    // changes programatically (when the revert button is clicked)
    this.$watch('value', color => {
      this.$emit(`${SPECTRUM_PREFIX}propertiesChanged`, {
        color,
      });
    });
  },

  beforeDestroy() {
    this.$unwatch('value');
  },
});

/**
 * Directive that extends the `v-loading` directive and allows
 * the specification of a dynamic entity id
 *
 * Usage:
 *
 * <div v-loading-entity="experiment.id" entity="experiments">
 *
 * Where `experiment.id` is a ViewModel property and thus dynamic
 *
 * This integrates with the API layer of flux which will mark an entity
 * as "loading" whenever it is saved by the key `<entityName>.<entityId>`
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
import _ from 'lodash';

import flux from 'core/flux';

import { getters as LoadingGetters } from 'core/modules/loading';

import loadingDirective from './loading';

export default _.extend({}, loadingDirective, {
  isLiteral: false,

  setupObservation() {
    // extend base class and no-op
  },

  update(value) {
    if (this.__unwatchLoadingStore) {
      this.__unwatchLoadingStore();
    }

    const id = this.vm.$get(this.key);
    const entity = this.el.getAttribute('entity');
    const isLoadingGetter = LoadingGetters.isEntityLoading(entity, id);

    this.toggleSpinner(flux.evaluate(isLoadingGetter));
    this.__unwatchLoadingStore = flux.observe(isLoadingGetter, isLoading => {
      this.toggleSpinner(isLoading);
    });
  },
});

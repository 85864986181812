/**
 * Javascript filtering helpers
 *
 * WARNING: this file is currently being used from the preview_ui bundle.
 * Currently, this means we SHOULD NOT use any ES7+ syntax/functions and need to be very careful
 * about the dependencies referenced in this module.
 *
 * @author Asa Schachar (asa@optimizely.com)
 */

const isPlainObject = require('lodash/isPlainObject');

/**
 * Searches a string for an occurrence of a filtering string (case insensitive)
 * @param {string} stringToFind    The term to find
 * @param {string, Immutable.List, Immutable.Map} contentToSearch  The content to search for the filter term
 * @return {boolean}
 */
exports.isFilterTermInItem = function isFilterTermInItem(
  stringToFind,
  contentToSearch,
) {
  if (!contentToSearch) {
    return false;
  }

  if (typeof contentToSearch === 'string') {
    const filters = stringToFind.match(/\S+/g) || [];

    for (let i = 0; i < filters.length; i++) {
      if (
        contentToSearch
          .toLocaleLowerCase()
          .indexOf(filters[i].toLocaleLowerCase()) === -1
      ) {
        return false;
      }
    }
    return true;
  }

  return isFilterTerminItemHelper(stringToFind, contentToSearch);
};

/**
 * Wrapper function for filtering that allows us to filter through nested lists or objects
 * @param {String} string
 * @param {String, Immutable.List, Immutable.Map} content
 * @returns {boolean}
 */
function isFilterTerminItemHelper(string, content) {
  let filterResults = [];
  content = content.toJS();
  // If the content is an array, loop through each one and filter through its items
  if (Array.isArray(content)) {
    content.forEach(value => {
      filterResults = filterResults.concat(
        exports.isFilterTermInItem(string, value),
      );
    });

    // If the content is an object, loop through the keys and filter through its items
  } else if (isPlainObject(content)) {
    Object.keys(content).forEach(key => {
      if (key) {
        filterResults = filterResults.concat(
          exports.isFilterTermInItem(string, content[key]),
        );
      }
    });
  }
  // If any of the filter results were true, return true
  return filterResults.some(item => item);
}

import { toImmutable } from 'optly/immutable';
import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

/**
 * Get a mapping of value to readable value for a specific targeting condition
 * @param {Number} conditionId
 */
export function valueMap(conditionId) {
  return [
    entityCache,
    targetingConditions => {
      const values = targetingConditions.getIn(
        [conditionId, 'fields', 'value', 'values'],
        toImmutable([]),
      );
      return toImmutable({}).withMutations(valueMap => {
        values.forEach(value => {
          valueMap.set(value.get('value'), value.get('text'));
        });
      });
    },
  ];
}

export function title(conditionId) {
  return [
    entityCache,
    targetingConditions => targetingConditions.getIn([conditionId, 'title']),
  ];
}

export function compatibleTargetingConditions(audienceVersion) {
  return [
    entityCache,
    targetingConditions =>
      targetingConditions.filter(targetingCondition =>
        targetingCondition
          .get('compatible_audiences')
          .contains(audienceVersion),
      ),
  ];
}

export default {
  ...baseEntityGetters,
  title,
  valueMap,
  compatibleTargetingConditions,
};

export const apiActions = {
  DELETE: 'delete',
  FETCH: 'fetch',
  FETCH_ALL: 'fetchAll',
  FETCH_PAGE: 'fetchPage',
  SAVE: 'save',
};

/**
 * Matches error codes in https://github.com/optimizely/optimizely/blob/devel/src/www/services/exceptions/error_codes.py
 */
export const apiErrorCodes = {
  INVALID_CSRF_TOKEN: 'invalid_csrf_token',
};

/**
 * Limit for the number of concurrent API requests to process via fns.processRequestQueue
 * @type {number}
 */
export const CONCURRENT_QUEUE_LIMIT = 10;

/**
 * Default for the size of the first page fetched using fetchAllPages.
 * @type {number}
 */
export const INITIAL_PAGE_SIZE = 50;

/**
 * Default size for pages fetched via fetchPage
 * @type {number}
 */
export const DEFAULT_PAGE_SIZE = 100;

/**
 * Optimizely specific request/response headers for retreiving
 * the total number of records for a paged API request.
 * @type {{requestHeader: string, responseHeader: string}}
 */
export const fetchAllPagesConfig = {
  requestHeader: 'X-Include-Record-Total',
  responseHeader: 'X-Record-Total',
};

export const INTERNAL_TO_SEARCH_API_ENTITY_MAP = {
  layers: 'campaign',
  feature_flags: 'feature',
  audiences: 'audience',
  views: 'page',
  events: 'event',
};

export const SEARCH_API_ERROR_FINGERPRINT = 'SEARCH_API_ERROR_FINGERPRINT';

export const SEARCH_API_REINDEX_FAILURE_FINGERPRINT =
  'SEARCH_API_REINDEX_FAILURE_FINGERPRINT';

export const SEARCH_API_REINDEX_CHECK_DEFAULT_DELAY = 1;

export default {
  apiActions,
  apiErrorCodes,
  CONCURRENT_QUEUE_LIMIT,
  fetchAllPagesConfig,
  INITIAL_PAGE_SIZE,
  INTERNAL_TO_SEARCH_API_ENTITY_MAP,
  SEARCH_API_REINDEX_CHECK_DEFAULT_DELAY,
  SEARCH_API_ERROR_FINGERPRINT,
  SEARCH_API_REINDEX_FAILURE_FINGERPRINT,
};

import PropTypes from 'prop-types';
import React from 'react';

/**
 * A left-hand label for the card
 */
const Label = props => (
  <div className="width--1-4">
    <div className="zeta muted weight--normal">{props.supertitle}</div>
    <div className="subhead force-break">{props.title}</div>
    <div className="micro muted push-half--ends">{props.subtitle}</div>
    {props.children}
  </div>
);

Label.propTypes = {
  subtitle: PropTypes.node,
  supertitle: PropTypes.node,
  title: PropTypes.node,
  children: PropTypes.node,
};

export default Label;

import RestApi from 'optly/modules/rest_api';

import definition from '../entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);
/**
 * Wrapper for baseEntityActions.save() to allow invalidating cache after save.
 *
 * @param {Object} data
 * @param args
 * @returns {Promise}
 */
function save(data, ...args) {
  return baseEntityActions.save(data, ...args).then(response => {
    /**
     * Modifying and saving layer experiment will potentially update layer data, so we need to invalidate layer summary.
     */
    RestApi.actions.inValidateCacheDataByEntity({
      entity: 'layer_summaries',
      id: data.id,
    });
    return response;
  });
}

export default {
  save,
};

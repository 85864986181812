import actions from './actions';
import actionTypes from './action_types';
import fns from './fns';
import getters from './getters';

export { actions, actionTypes, fns, getters };

export default {
  actions,
  actionTypes,
  fns,
  getters,
};

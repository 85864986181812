import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import {
  isFeatureEnabled,
  getFeatureVariableInteger,
} from '@optimizely/js-sdk-lab/src/actions';

import { connect } from 'core/ui/decorators';

import {
  getters as ConcurrencyGetters,
  actions as ConcurrencyActions,
} from 'bundles/p13n/modules/concurrency';

import ConcurrentEditingBanner from './concurrent_editing_panel';

@connect(props => {
  const { entityId } = props;
  return {
    users: ConcurrencyGetters.usersListByEntityId(entityId),
  };
})
class ConcurrentEditingContainer extends React.Component {
  static propTypes = {
    entityId: PropTypes.number.isRequired,
    users: PropTypes.instanceOf(List),
  };

  static defaultProps = {
    users: List(),
  };

  state = {
    timeToNextRequest: Date.now(), // in Milliseconds
    failedTries: 0,
  };

  constructor(props) {
    super(props);
    this.callVisitorsEndpoints = this.callVisitorsEndpoints.bind(this);
    this.canUseConcurrentEditing = isFeatureEnabled('concurrent_editing');
    this.backoff = getFeatureVariableInteger(
      'concurrent_editing',
      'backoff_in_seconds',
    );
    this.requestInterval = getFeatureVariableInteger(
      'concurrent_editing',
      'request_interval_in_seconds',
    );
  }

  callVisitorsEndpoints() {
    const { entityId } = this.props;

    ConcurrencyActions.subscribeToEntityById(entityId)
      .then(() =>
        this.setState({
          failedTries: 0,
          timeToNextRequest: Date.now() + this.requestInterval * 1000,
        }),
      )
      .catch(() =>
        this.setState(prevState => {
          const failedTries = prevState.failedTries + 1;
          return {
            failedTries,
            timeToNextRequest: Date.now() + this.backoff ** failedTries * 1000,
          };
        }),
      );
  }

  componentDidMount() {
    if (this.canUseConcurrentEditing) {
      this.callVisitorsEndpoints();
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.canUseConcurrentEditing) {
      const { timeToNextRequest } = this.state;
      if (prevState.timeToNextRequest < timeToNextRequest) {
        this.nextRequest = setTimeout(
          this.callVisitorsEndpoints,
          timeToNextRequest - Date.now(),
        );
      }
    }
  }

  componentWillUnmount() {
    if (this.canUseConcurrentEditing) {
      clearTimeout(this.nextRequest);
    }
  }

  render() {
    const { users } = this.props;
    if (!this.canUseConcurrentEditing || !users.size) {
      return null;
    }
    return <ConcurrentEditingBanner users={users} />;
  }
}

export default ConcurrentEditingContainer;

/**
 * Entity Definition for event
 */
export default {
  entity: 'dcp_datasources',

  parent: {
    entity: 'dcp_services',
    key: 'dcp_service_id',
  },
};

import React from 'react';
import PropTypes from 'prop-types';

import './slider.css';

/**
 * <input> component with style props to position it on top
 * of the histogram as a vertical bar with a handle.
 * The local CSS for this file is where all the magic happens.
 * @param {Object} props
 */
const Slider = props => {
  const { onChange, value, plotHeight, plotWidth, plotLeft, plotTop } = props;

  function __onChange(evt) {
    onChange(Number(evt.target.value) / 1000);
  }

  return (
    <React.Fragment>
      <input
        value={Math.round(value * 1000)}
        onChange={__onChange}
        className="estimated-reach-slider flex flex--1"
        /* Used to ensure positioning of the slider directly on top of the histogram. */
        style={{
          height: `${plotHeight}px`,
          width: `${plotWidth + 8}px`,
          top: `${plotTop}px`,
          left: `${plotLeft - 4}px`,
        }}
        type="range"
        step="1"
        min="0"
        max="1000"
        data-test-section="histogram-slider"
      />
    </React.Fragment>
  );
};

Slider.propTypes = {
  onChange: PropTypes.func.isRequired,
  plotHeight: PropTypes.number,
  plotWidth: PropTypes.number,
  plotLeft: PropTypes.number,
  plotTop: PropTypes.number,
  value: PropTypes.number.isRequired,
};

Slider.defaultProps = {
  plotHeight: 0,
  plotWidth: 0,
  plotLeft: 0,
  plotTop: 0,
};

export default Slider;

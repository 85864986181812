import flux from 'core/flux';

import actionTypes from './action_types';

export const setLayerDescription = description => {
  flux.dispatch(actionTypes.P13N_SET_LAYER_DESCRIPTION, {
    description,
  });
};

export const setLayerName = name => {
  flux.dispatch(actionTypes.P13N_SET_LAYER_NAME, {
    name,
  });
};

export default {
  setLayerDescription,
  setLayerName,
};

/**
 * Entity Definition for Live Variable
 */

import FieldTypes from 'optly/modules/rest_api/field_types';

export const entity = 'live_variables';

export const parent = {
  entity: 'projects',
  key: 'project_id',
};

export const fieldTypes = {
  id: FieldTypes.NUMBER,
  project_id: FieldTypes.NUMBER,
  api_name: FieldTypes.STRING,
  archived: FieldTypes.BOOLEAN,
  created: FieldTypes.DATE,
  default_value: FieldTypes.STRING,
  description: FieldTypes.STRING,
  last_modified: FieldTypes.DATE,
  type: FieldTypes.STRING,
};

export default {
  entity,
  parent,
  fieldTypes,
};

const sanitizer = require('sanitizer');

const stripHTML = require('./strip_html');
/**
 * Sanitizes a string of HTML.  If called with an array, the function recursively
 * sanitizes all the elements of the array.
 *
 * @param {String} str - The string to sanitize.
 * @param {Boolean} shouldStripHTML - Whether or not HTML markup should be stripped in the return value.
 */
module.exports = function sanitizeHTML(str, shouldStripHTML = false) {
  if (Array.isArray(str)) {
    for (let i = 0; i < str.length; i++) {
      str[i] = sanitizeHTML(str[i], shouldStripHTML);
    }
    return str;
  }

  if (shouldStripHTML) {
    return stripHTML(sanitizer.sanitize(str));
  }
  return sanitizer.sanitize(str);
};

// load Select2 jQuery plugin
const $ = require('jquery');
require('jquery-select2');
const utils = require('optly/utils/select2');

/**
 * Searchable select dropdown
 */
module.exports = {
  methods: {
    _bind() {
      // unbind first if necessary
      const $element = $(this.$el);
      if ($element.data('select2')) {
        $element.select2('destroy');
      }

      // clear existing options and
      // add empty option so placeholder works
      $element.empty().append($('<option />'));

      const selectOptions = {};
      if (this.values && this.values.length > 0) {
        // use `text` property as `text` and index in the array as `id`
        selectOptions.data = utils.formatDataByIndex(this.values, 'text');
      }

      function formatState(state) {
        if (!state.subtext) {
          return state.text;
        }
        const $state = $(
          `<span>${state.text}<br /><small>${state.subtext}</small></span>`,
        );
        return $state;
      }

      selectOptions.templateResult = formatState;

      $element
        .select2(selectOptions)
        .on('select2:select', this._onSelect.bind(this));
      this._set();
    },

    _onSelect(event) {
      // v-on doesn't work here because it binds before Select2
      if (this.onChange && event.params && event.params.data) {
        this.selectedIndex = event.params.data.id;
        this.onChange.call(this.$parent);
      }
    },

    _set() {
      // make default selection by setting the value on the `select`
      // element and triggering `change` event for Select2
      const $element = $(this.$el);
      $element.val(this.selectedIndex).trigger('change');
    },
  },

  beforeDestroy() {
    $(this.$el).select2('destroy');
  },

  ready() {
    this.$watch('values', this._bind.bind(this));
    this.$watch('selectedIndex', this._set.bind(this));
  },
};

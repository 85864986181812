import flux from 'core/flux';

import { core as SegmentJSCore } from '@optimizely/segment-js';

import {
  fns as LayerFns,
  getters as LayerGetters,
} from 'optly/modules/entity/layer';
import { getters as LayerExperimentGetters } from 'optly/modules/entity/layer_experiment';

export const setTrackingData = (ctx, next) => {
  let experimentType = null;
  let layerId =
    ctx && ctx.params && ctx.params.item_id && Number(ctx.params.item_id);
  const experimentId =
    ctx &&
    ctx.params &&
    ctx.params.experiment_id &&
    Number(ctx.params.experiment_id);

  if (experimentId && !layerId) {
    const experiment = flux.evaluate(LayerExperimentGetters.byId(experimentId));
    layerId = experiment ? Number(experiment.get('layer_id')) : null;
  }
  if (layerId) {
    const layer = flux.evaluate(LayerGetters.byId(layerId));
    if (LayerFns.isMultiArmedBandit(layer)) {
      experimentType = 'Multi-Armed Bandit';
    } else if (LayerFns.isABTestLayer(layer)) {
      experimentType = 'A/B Test';
    } else if (LayerFns.isPersonalizationLayer(layer)) {
      experimentType = 'Personalization Campaign';
    } else if (LayerFns.isMultivariateTestLayer(layer)) {
      experimentType = 'Multivariate Test';
    }
  }
  SegmentJSCore.setProperties({
    campaignId: layerId || null,
    experimentId: experimentId || null,
    experimentType,
  });
  next();
};

export default {
  setTrackingData,
};

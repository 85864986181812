/**
 * OAuth Client Entity Module
 */
import actions from './actions';
import entityDef from './entity_definition';
import fns from './fns';
import getters from './getters';

export { actions, entityDef, fns, getters };

export default {
  actions,
  entityDef,
  fns,
  getters,
};

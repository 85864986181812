import RestApi from 'optly/modules/rest_api';
import { toImmutable } from 'optly/immutable';

import entityDef from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(entityDef);

export const {
  save,
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deleteCatalogStats = baseEntityActions.delete;

export { deleteCatalogStats as delete };

/**
 * Given an itemId, fetch the itemInfo from the catalog_serving_url in the catalog.
 *
 * @param {Immutable.Map} catalog the catalog (recommender service) that the item to be fetch could be in.
 * @param {String} itemId id of the item to be fetched for info.
 * @return {Promise<any | never>} Promise that resolves with itemMetadata fetched from the catalog_serving_url endpoint.
 */
const fetchItemMetadataById = (catalog, itemId) => {
  const fetchURL = `${catalog.get('catalog_serving_url')}/${encodeURIComponent(
    itemId,
  )}`;

  return window
    .fetch(fetchURL, { credentials: 'same-origin' })
    .then(response => {
      if (response.ok) {
        return response.json();
      }

      return null;
    })
    .then(jsonPayload => {
      const itemInfo =
        !!jsonPayload &&
        toImmutable(jsonPayload).getIn(['data', 'itemMetadata']);
      return itemInfo && JSON.parse(itemInfo);
    });
};

/**
 * Fetch summary
 *
 * @param {Number} catalogId
 * @param {Object} fetchOptions
 * @returns {Promise<any | never>} API JSON formatted response promise
 */
const fetchCatalogStatsById = (catalogId, fetchOptions) => {
  const fetchFilters = {
    recommender_service_id: catalogId,
  };

  return fetchAll(fetchFilters, fetchOptions);
};

export default {
  ...baseEntityActions,
  fetchCatalogStatsById,
  fetchItemMetadataById,
};

/**
 * Abstraction of window.location for testability
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const $window = require('optly/window');

function pathname() {
  return $window.location.pathname;
}

// Adding this method here to make it possible to stub JS redirects for testing.
function setLocation(newLocation) {
  $window.location = newLocation;
}

// Adding this method here to make it possible to stub for testing.
function getLocation() {
  return $window.location.href;
}

// Adding this method here to make it possible to stub for testing.
function getOrigin() {
  return $window.location.origin;
}

// Adding this method here to make it possible to stub for testing.
function getPath() {
  return $window.location.pathname;
}

// Adding this method here to make it possible to stub for testing.
function getSearch() {
  return $window.location.search;
}

// Adding this method here to make it possible to stub for testing.
function getHash() {
  return $window.location.hash;
}

/**
 * Reloads the browser
 */
function windowLocationReload() {
  return $window.location.reload();
}

/**
 * Replaces the URL in the browser location bar without actually going to it. history.replaceState()
 * updates the most recent entry on the history stack to have the specified data, title, and URL
 * and will not cause the browser to go to the specified URL.
 * @param {String} newLocation
 * @returns {Function}
 */
function replaceLocation(newLocation) {
  return $window.history.replaceState({}, '', newLocation);
}

module.exports = {
  pathname,
  replaceLocation,
  setLocation,
  getLocation,
  getOrigin,
  getPath,
  getSearch,
  getHash,
  windowLocationReload,
};

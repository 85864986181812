/**
 * Filter to HTML-unescape using the util used by results2 page
 * @author Ian Hinsdale
 */
const _ = require('lodash');

module.exports = function(str) {
  return _.unescape(str).replace('&#96;', '`'); // Duplicating the optly.util.main.HTMLUnescape method here
  // rather than refer to it directly, because we probably shouldn't rely on this Vue component having optly.util.main
  // available.
};

import React from 'react';
import PropTypes from 'prop-types';

import { Button, DialogNew, Sheet } from 'optimizely-oui';

import { withTrack } from '@optimizely/segment-js/dist/decorators';

import Immutable from 'optly/immutable';
import { connect } from 'core/ui/decorators';
import ui from 'core/ui';

import { getters as RecommenderStatsGetters } from 'optly/modules/entity/recommender_stats';

import { RecommendationsHelpLink } from 'bundles/p13n/components/messaging/recommendations';

import SectionModuleFns from 'bundles/p13n/sections/implementation/section_module/fns';

import ItemPreviewer from './subcomponents/item_previewer';
import SummaryStatsSection from './subcomponents/summary_stats';

@connect(({ recommender }) => ({
  summaryStats: RecommenderStatsGetters.summaryStatsByRecommenderId(
    recommender.get('id'),
  ),
}))
@withTrack
class RecommenderPreviewerDialog extends React.Component {
  static propTypes = {
    /**
     * Current catalog (recommender service)
     */
    catalog: PropTypes.instanceOf(Immutable.Map).isRequired,
    /**
     * Current recommender
     */
    recommender: PropTypes.instanceOf(Immutable.Map).isRequired,
    /**
     * an Immutable Map containing summary about number of recommended items for the given recommender
     *
     *    total_num_targets
     *    num_targets_with_full_recommendations
     *    num_targets_with_half_recommendations
     */
    summaryStats: PropTypes.instanceOf(Immutable.Map).isRequired,
    /**
     * Segment Tracking function handler.
     */
    track: PropTypes.func,
  };

  static defaultProps = {
    track: () => {},
  };

  componentDidMount() {
    const { track } = this.props;

    // Segment Tracking
    track('Recommendations Recommender Previewer Dialog Opened');
  }

  renderEmptyState = () => (
    <DialogNew
      footerButtonList={[
        <Button
          key="recommender-previewer-dismiss-button"
          onClick={ui.hideDialog}
          style="highlight">
          Dismiss
        </Button>,
      ]}
      onClose={ui.hideDialog}
      title="Recommender Previewer"
      testSection="recommender-previewer-empty-state-dialog">
      <div className="text--center">
        <img
          alt=""
          className="anchor--middle"
          src="https://app.optimizely.com/dist/static/img/p13n/audience-detail-primary-metric-9552eb9ae5c0c6124634ad8b53db3bfc.svg"
          width="200"
        />
        <h3
          className="push-half--bottom"
          data-test-section="recommender-previewer-empty-state-help-text">
          Not enough data yet.
        </h3>
        <RecommendationsHelpLink
          helpLink={SectionModuleFns.getHelpCopy('previewer_link')}
          helpText=" about recommendations"
          testSection="recommender-previewer-empty-state"
        />
      </div>
    </DialogNew>
  );

  render() {
    const { catalog, recommender, summaryStats } = this.props;

    if (!recommender.getIn(['job_status_summary', 'last_run'])) {
      return this.renderEmptyState();
    }

    return (
      <Sheet
        footerButtonList={[
          <Button
            key="recommender-previewer-dismiss-button"
            onClick={ui.hideDialog}
            style="highlight">
            Dismiss
          </Button>,
        ]}
        onClose={ui.hideDialog}
        title="Recommender Previewer"
        testSection="recommender-previewer-dialog">
        <SummaryStatsSection
          recommender={recommender}
          summaryStats={summaryStats}
        />
        <ItemPreviewer catalog={catalog} recommender={recommender} />
      </Sheet>
    );
  }
}

export default RecommenderPreviewerDialog;

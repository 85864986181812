/**
 * A directive that will put an overlay + spinner over an element if the
 * a certain `loadingId` is true in the flux system.
 *
 * Usage:
 *
 * <div v-loading="dashboard.experiments" spinner-size="small">
 *
 * Where `dashboard.experiments` corresponds to key managed by the loading store
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
import $ from 'jquery';

import Vue from 'vue';
import flux from 'core/flux';

import { getters as LoadingGetters } from 'core/modules/loading';

const spinnerWithOverlay =
  '<div class="lego-overlay" style="z-index: 2000;"><div class="lego-spinner"></div></div>';

const MIN_TIME = 300;

/**
 *
 * TODO(webpack) make this a pragma / defined value for testing
var MIN_TIME = 0;
*/

const exported = {
  isLiteral: true,
  showTime: null,

  toggleSpinner(show) {
    if (show && !this.shown) {
      const $el = $(this.el);

      if ($el.is('tr')) {
        // special case table rows since the overlay positioning
        // works differently
        this.spinner.appendTo($el.parents('table'));
        this.showTime = Date.now();
        Vue.nextTick(() => {
          const rowPos = $el.position();
          this.spinner.css({
            position: 'absolute',
            top: rowPos.top,
            left: rowPos.left,
            width: this.el.clientWidth,
            height: this.el.clientHeight,
          });
        });
      } else {
        this.spinner.appendTo(this.el);
        this.showTime = Date.now();
      }
    } else if (!show && this.shown) {
      // execute if we should hide and it is shown
      const diff = Date.now() - this.showTime;
      // if the minimum duration has occurred immediately hide
      if (diff > MIN_TIME) {
        this.spinner.remove();
      } else if (!this.timeout) {
        // otherwise set timeout to the remaining time to hide
        this.timeout = setTimeout(() => {
          this.spinner.remove();
          this.timeout = null;
        }, MIN_TIME - diff);
      }
    }
    this.shown = show;
  },

  bind() {
    this.shown = false;
    const $el = $(this.el);
    const pos = $el.css('position');
    if (!pos || pos === 'static') {
      $(this.el).css('position', 'relative');
    }
    this.spinner = $(spinnerWithOverlay);

    // add a class for a custom size
    const spinnerSize = this.el.getAttribute('spinner-size');
    if (spinnerSize) {
      this.spinner
        .find('.lego-spinner')
        .addClass(`lego-spinner--${spinnerSize}`);
    }

    this.setupObservation();
  },

  setupObservation() {
    const isLoadingGetter = LoadingGetters.isLoading(this.key);
    this.toggleSpinner(flux.evaluate(isLoadingGetter));
    this.__unwatchLoadingStore = flux.observe(isLoadingGetter, isLoading => {
      this.toggleSpinner(isLoading);
    });
  },

  unbind() {
    this.spinner.remove();
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.__unwatchLoadingStore) {
      this.__unwatchLoadingStore();
    }
    delete this.timeout;
    delete this.showTime;
    delete this.spinner;
  },
};

export default exported;

export const {
  isLiteral,
  showTime,
  toggleSpinner,
  bind,
  setupObservation,
  unbind,
} = exported;

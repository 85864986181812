const _ = require('lodash');

/**
 * Turns a number into a letter.
 *
 * – 0 => a
 * - 1 => b
 *
 * @author Daniel O'Connor (daniel@optimizely.com)
 */
module.exports = function(str) {
  const num = parseInt(str, 10);
  const MAX_NUMBER = 26;

  if (!_.isNumber(num) && num >= MAX_NUMBER) {
    console.error(
      `Value passed to number-to-letter must be a number under ${MAX_NUMBER}.`,
    ); // eslint-disable-line no-console
  }

  // 97 is the ASCII value for 'a'
  // http://stackoverflow.com/a/13202079/316602
  return String.fromCharCode(97 + num);
};

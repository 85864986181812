import MetricsHubDashboard from './main_content'

export const component = MetricsHubDashboard;

export const routingSetup = (_ctx, next) => {
  next();
};

export default {
  component,
  routingSetup,
};

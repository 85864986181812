import { isImmutable, toJS } from 'optly/immutable';

import CurrentlyEditingExperimentFns from 'bundles/p13n/modules/currently_editing_experiment/fns';

import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import ProjectEnums from 'optly/modules/entity/project/enums';

import constants from './constants';

const EXPERIMENT_ENVIRONMENT_STATUS = LayerExperimentEnums.EnvironmentStatus;

/**
 * Given a list of variations that all exist in the same experiment,
 * return a boolean indicating whether each of them has a unique key.
 *
 * @param {Immutable.List} variations
 * @return {Boolean}
 */
export function ensureVariationKeysUnique(variations) {
  const seenNames = {};
  return variations.every(variation => {
    const key = variation.get('api_name');
    if (!key) {
      return true;
    }
    if (seenNames[key]) {
      return false;
    }
    seenNames[key] = true;
    return true;
  });
}

/**
 * Get the status of the experiment
 * @param  {Object} layerExperiment
 * @return {string}
 */
export function getExperimentStatus(layerExperiment) {
  const isLive = CurrentlyEditingExperimentFns.isExperimentLive(
    layerExperiment,
  );
  if (layerExperiment.is_launched) {
    return constants.LAYER_EXPERIMENT_HUMAN_READABLE_STATUS_TYPES.LAUNCHED;
  }
  if (isLive) {
    return layerExperiment.status === LayerExperimentEnums.status.PAUSED
      ? constants.LAYER_EXPERIMENT_HUMAN_READABLE_STATUS_TYPES.PAUSED
      : constants.LAYER_EXPERIMENT_HUMAN_READABLE_STATUS_TYPES.RUNNING;
  }
  return constants.LAYER_EXPERIMENT_HUMAN_READABLE_STATUS_TYPES.DRAFT;
}

/**
 * Check whether the layer experiment matches the expected status.
 * Returns true if the layer experiment matches the expected status, else false.
 * @param  {Object} layerExperiment
 * @param  {string} expectedStatus ["active", "running", "paused", "draft", "archived"]
 * @param  {Immutable.Map} liveCommitTagMap
 * @return {boolean}
 */
export function compareExperimentIsOfStatus(
  layerExperiment,
  expectedStatus,
  liveCommitTagMap,
) {
  let equalsExpectedStatus = false;
  if (expectedStatus === LayerExperimentEnums.oasis_status.ACTIVE) {
    equalsExpectedStatus =
      layerExperiment.status === LayerExperimentEnums.oasis_status.ACTIVE ||
      layerExperiment.status === LayerExperimentEnums.oasis_status.PAUSED ||
      !layerExperiment.status;
  } else if (expectedStatus === LayerExperimentEnums.oasis_status.ARCHIVED) {
    equalsExpectedStatus = layerExperiment.status === expectedStatus;
  } else if (expectedStatus === LayerExperimentEnums.oasis_status.RUNNING) {
    equalsExpectedStatus =
      layerExperiment.status === LayerExperimentEnums.oasis_status.ACTIVE &&
      this.getExperimentStatus(layerExperiment, liveCommitTagMap) ===
        constants.LAYER_EXPERIMENT_HUMAN_READABLE_STATUS_TYPES.RUNNING;
  } else if (expectedStatus === LayerExperimentEnums.oasis_status.PAUSED) {
    equalsExpectedStatus = layerExperiment.status === expectedStatus;
  } else if (expectedStatus === LayerExperimentEnums.oasis_status.DRAFT) {
    equalsExpectedStatus =
      layerExperiment.status === LayerExperimentEnums.oasis_status.ACTIVE &&
      this.getExperimentStatus(layerExperiment, liveCommitTagMap) ===
        constants.LAYER_EXPERIMENT_HUMAN_READABLE_STATUS_TYPES.DRAFT;
  }
  return equalsExpectedStatus;
}

/**
 * Retrieve activation codeblock for the given language.
 * Inserts the experiment key into the codeblock placeholder.
 * @param  {string} experimentKey
 * @param  {string} language
 * @return {string}
 */
export function getExperimentActivateCodeBlock(experimentKey, language) {
  experimentKey = experimentKey || 'experimentKey';
  language = language || 'python';
  const activeCodeBlock = constants.EXPERIMENT_ACTIVATION_CODE_BLOCKS[language];
  return activeCodeBlock.replace(
    constants.EXPERIMENT_KEY_PLACEHOLDER,
    experimentKey,
  );
}

export function getExperimentActivateCodeBlockPostfix(experimentKey, language) {
  experimentKey = experimentKey || 'experimentKey';
  language = language || 'python';
  const activeCodeBlockPostfix =
    constants.EXPERIMENT_ACTIVATION_CODE_BLOCKS_POSTFIX[language];
  return (
    (activeCodeBlockPostfix &&
      activeCodeBlockPostfix.replace(
        constants.EXPERIMENT_KEY_PLACEHOLDER,
        experimentKey,
      )) ||
    ''
  );
}

/**
 * Retrieve the variation codeblock for the given language
 * @param  {Array<object>} variations
 * @param  {string} language
 * @return {string}
 */
export function getVariationsCodeBlock(variations, language) {
  language = language || 'python';
  let variationsCodeBlock = '';
  if (variations) {
    variations.forEach((variation, index) => {
      let variationCodeBlock =
        constants.EXPERIMENT_VARIATION_CODE_BLOCKS[language];
      const apiName =
        (isImmutable(variation) && variation.get('api_name')) ||
        variation.api_name ||
        `variation_${index + 1}`;
      variationCodeBlock = variationCodeBlock.replace(
        new RegExp(constants.VARIATION_KEY_PLACEHOLDER, 'g'),
        apiName,
      );
      if (index !== 0) {
        // if not first variation, add in the else clause
        variationCodeBlock =
          getElseKeywordByLanguage(language) + variationCodeBlock;
      }

      if (index === variations.size - 1) {
        // if last variation then add in the control conditional block and
        // closing keyword/char (can be parenthesis or 'end', etc)
        variationCodeBlock +=
          constants.EXPERIMENT_CONTROL_CODE_BLOCKS[language];
        variationCodeBlock += getIfElseClauseEndingByLanguage(language);
      }
      variationsCodeBlock += variationCodeBlock;
    });
  }
  return variationsCodeBlock;
}

/**
 * Get null checking code for the variation returned by activate since the return value of activate for some
 * languages is an object that can be null.
 * @param  {string} language
 */
export function getNullCheckingCodeByLanguage(language) {
  switch (language) {
    case ProjectEnums.sdkLanguages.JAVA:
      return 'if (variation != null) {';
    case ProjectEnums.sdkLanguages.CSHARP:
      return 'if (variation != null) \n{';
    case ProjectEnums.sdkLanguages.SWIFT:
      return 'if let variation = variation {';
    case ProjectEnums.sdkLanguages.GO:
      return 'if err == nil {';
    default:
      return '';
  }
}

/**
 * Return list of usages of specified live variable in other active experiments except the specified experiment.
 *
 * Filters list of usages of live variables and filter usage where variable is the same as specified and
 * experiment is different than specified and status of the experiment is not "archived".
 *
 * @param {List} liveVariableUsagesWithStatuses List of variable usages, each item contains status of the experiment
 * @param {Number} experimentId Current experiment ID to exclude in filtering.
 * @param {Number} liveVariableId Current variable ID to include in filtering.
 */
export function getLiveVariableUsagesInOtherExperiments(
  liveVariableUsagesWithStatuses,
  experimentId,
  liveVariableId,
) {
  if (isImmutable(liveVariableUsagesWithStatuses)) {
    liveVariableUsagesWithStatuses = toJS(liveVariableUsagesWithStatuses);
  }

  return liveVariableUsagesWithStatuses.filter(
    usage =>
      usage.live_variable_id === liveVariableId &&
      usage.experiment_id !== experimentId &&
      usage.experiment_status !== LayerExperimentEnums.status.ARCHIVED,
  );
}

function getElseKeywordByLanguage(language) {
  switch (language) {
    case ProjectEnums.sdkLanguages.PYTHON:
      return '\nel'; // prepend el to if to make elif
    case ProjectEnums.sdkLanguages.RUBY:
      return '\nels'; // prepend els to if to make elsif
    case ProjectEnums.sdkLanguages.JAVA:
    case ProjectEnums.sdkLanguages.SWIFT:
    case ProjectEnums.sdkLanguages.GO:
      return '\n  } else ';
    case ProjectEnums.sdkLanguages.OBJECTIVE_C:
    case ProjectEnums.sdkLanguages.JAVASCRIPT:
    case ProjectEnums.sdkLanguages.FLUTTER:
    case ProjectEnums.sdkLanguages.PHP:
      return '\n} else ';
    case ProjectEnums.sdkLanguages.CSHARP:
      return '\n  }\n  else ';
    case ProjectEnums.sdkLanguages.REACT:
      return '';
    default:
      return '';
  }
}

function getIfElseClauseEndingByLanguage(language) {
  switch (language) {
    case ProjectEnums.sdkLanguages.RUBY:
      return '\nend';
    case ProjectEnums.sdkLanguages.JAVA:
    case ProjectEnums.sdkLanguages.JAVASCRIPT:
    case ProjectEnums.sdkLanguages.FLUTTER:
    case ProjectEnums.sdkLanguages.PHP:
    case ProjectEnums.sdkLanguages.OBJECTIVE_C:
    case ProjectEnums.sdkLanguages.SWIFT:
    case ProjectEnums.sdkLanguages.GO:
    case ProjectEnums.sdkLanguages.CSHARP:
      return '\n}';
    case ProjectEnums.sdkLanguages.REACT:
      return '';
    default:
      return '';
  }
}

/**
 * Returns whether input begins with a number
 * @param {String} variableKey
 * @returns {boolean}
 */
export function variableBeginsWithNumber(variableKey) {
  return /\d/.test(variableKey[0]);
}

/**
 * Determines whether a user can run an additional experiment in the project, based on the project's
 * running experiment limit.
 * @param {Immutable.List} currentProjectRunningExperimentIds - list of IDs for experiments currently running
 * @param {number} currentProjectRunningExperimentLimit - limit, which cannot be exceeded.
 * @returns {Boolean} whether the user can run an additional experiment
 */
export const canRunAdditionalExperiment = (
  currentExperimentId,
  currentProjectRunningExperimentIds,
  currentProjectRunningExperimentLimit,
) => {
  currentProjectRunningExperimentIds = currentProjectRunningExperimentIds.toSet();
  if (!currentProjectRunningExperimentIds.includes(currentExperimentId)) {
    return (
      currentProjectRunningExperimentIds.size <=
      currentProjectRunningExperimentLimit - 1
    );
  }
  return true;
};

export default {
  canRunAdditionalExperiment,
  compareExperimentIsOfStatus,
  ensureVariationKeysUnique,
  getElseKeywordByLanguage,
  getExperimentActivateCodeBlock,
  getExperimentActivateCodeBlockPostfix,
  getExperimentStatus,
  getIfElseClauseEndingByLanguage,
  getLiveVariableUsagesInOtherExperiments,
  getNullCheckingCodeByLanguage,
  getVariationsCodeBlock,
  variableBeginsWithNumber,
};

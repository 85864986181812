import PropTypes from 'prop-types';
import React from 'react';

/**
 * A vertical subdivision of a Card, typically containing as children
 * a Card.Label and Card.Body.
 */
const Part = props => (
  <div className="flex" data-test-section={props.testSection}>
    {props.children}
  </div>
);

Part.propTypes = {
  children: PropTypes.node,
  testSection: PropTypes.string,
};

export default Part;

/**
 * Filter for showing full date in readable form.
 *
 * Used in Access tab under Preferences
 * @author Ali Rizvi
 */
const tr = require('optly/translate');

/**
 * @param value Datetime in ISO format which needs to be formatted
 * @returns {string} Date in human readable format like: Jan 1, 1970, 12:00am
 */
module.exports = function(value) {
  // Return value if it cannot be parsed
  if (Number.isNaN(Date.parse(value))) {
    return value;
  }
  return tr.date(value).format('lll');
};

import flux from 'core/flux';
import { waitUntil } from 'optly/utils/poll';

import UIGetters from '../getters';

/**
 * Helper util to invoke the method provided on the notificationManager as
 * soon as it is available. Necessary because some callers may utilize the
 * manager before it has been mounted in the DOM.
 * @param {string} method - The method to invoke on the instance of the notificationManager
 * @param {array<any>} args
 */
const whenManagerReady = (method, ...args) => {
  const waitForManager = () =>
    waitUntil(getNotificationManager, 3, 5).then(getNotificationManager);
  const manager = getNotificationManager();
  if (manager) {
    manager[method](...args);
  } else {
    waitForManager().then(m => m[method](...args));
  }
};

/**
 * Get the global notification manager.
 */
function getNotificationManager() {
  const currentNotificationManager = flux.evaluate(
    UIGetters.notificationManager,
  );
  if (!currentNotificationManager) {
    console.warn(
      'No NotificationManager component instantiated and registered globally',
    );
  }
  return currentNotificationManager;
}

/**
 * Shows a non persistent notification in the UI via the NotificationManager component
 * @param {Object} options
 * @param {String} options.message
 * @param {String?} [options.type] ('default', 'success', 'warning', 'error')
 */
export function showNotification(options) {
  whenManagerReady('showNotification', options);
}

/**
 * Shows a persistent notification in the UI via the NotificationManager component
 * @param {Object} options
 * @param {String} options.message
 * @param {String} options.id
 * @param {String?} options.type ('default', 'success', 'warning', 'error')
 * @param {Boolean?} options.safeHTML
 */
export function showPersistentNotification(options) {
  whenManagerReady('showPersistentNotification', options);
}

/**
 * clears a specific persistent notification in the UI via the NotificationManager component
 * @param {String} id
 */
export function clearPersistentNotification(id) {
  whenManagerReady('clearPersistentNotification', id);
}

export default {
  showNotification,
  showPersistentNotification,
  clearPersistentNotification,
};

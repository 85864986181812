import CurrentProject from 'optly/modules/current_project';

import fns from './fns';

export const currentlyEditingGroup = [
  'configureGroup',
  'currentlyEditingGroup',
];
export const errorFlags = ['configureGroup', 'errorFlags'];

export const availableEntities = [
  currentlyEditingGroup,
  CurrentProject.getters.experimentationGroups,
  CurrentProject.getters.layers,
  CurrentProject.getters.layerExperiments,
  CurrentProject.getters.isCustomProject,
  fns.filterForAvailableEntities,
];

export default {
  availableEntities,
  currentlyEditingGroup,
  errorFlags,
};

import enums from 'optly/utils/enums';
import ProjectFns from 'optly/modules/entity/project/fns';

import entityDef from './entity_definition';

// Show a warning about editing goals for experiments that
// have results older than 3 days ago
const EDIT_GOALS_WARNING_AGE_MILLISECONDS = 3 * 24 * 60 * 60 * 1000;

/**
 * Services layer pure functions for the experiments
 */

/**
 * Logic to determine if an experiment status
 * @param {Experiment} experiment
 * @return {boolean}
 */
export function canWebPreview(experiment, project) {
  const status = enums.ExperimentStatusType;
  const blacklist = [status.ARCHIVED, status.DELETED, status.ERROR];

  return (
    !blacklist.includes(experiment.status) && ProjectFns.isWebProject(project)
  );
}

/**
 * Return whether the experiment has results older than 3 days
 * @return {boolean}
 */
export function showEditGoalsWarning(experiment) {
  const { earliest } = experiment;
  let age;

  if (earliest) {
    // If the experiment has started, check that results >= 3 days.
    age = new Date() - new Date(earliest);
    return age >= EDIT_GOALS_WARNING_AGE_MILLISECONDS;
  }
  return false;
}

export function create(data) {
  const DEFAULTS = entityDef.fields || {};
  return {
    ...DEFAULTS,
    ...data,
  };
}

export default {
  canWebPreview,
  create,
  showEditGoalsWarning,
};

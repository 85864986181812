import React, { useEffect, useState } from 'react';

import AudienceActions from 'optly/modules/entity/audience/actions';

import { AudienceMap, Audience } from './types';

type AudienceLabelingWrapperProps = {
  projectId: number;
  render: (audiencesMap: AudienceMap) => React.ReactElement;
};

const AudienceLabelingWrapper = (props: AudienceLabelingWrapperProps) => {
  const { projectId, render } = props;
  const [audiencesMap, setAudiencesMap] = useState<AudienceMap>({});

  useEffect(() => {
    const audiencesDeferred = AudienceActions.fetchSavedAudiences(projectId);

    audiencesDeferred.then((audiences: Audience[]) => {
      const newAudiencesMap = {};

      audiences.forEach(audience => {
        newAudiencesMap[audience.id] = audience.name;
      });

      setAudiencesMap(newAudiencesMap);
    });
  }, [projectId]);

  return <>{render(audiencesMap)}</>;
};

export default AudienceLabelingWrapper;

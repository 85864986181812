/**
 * Module specific pure functions
 */
module.exports = {
  /**
   * Return a list of data sources that are not Optimizely
   *
   * @param {Immutable.List} datasources
   * @return {Immutable.List}
   */
  removeOptimizelyDatasource(datasources) {
    return datasources.filter(datasource => !datasource.get('is_optimizely'));
  },
};

import ui from 'core/ui';

import RoutingHelpers from 'bundles/p13n/routing_helpers';

import MultiarmedBanditNotification from 'optly/components/dialogs/multi_armed_bandit_notification';

import ABExperimentDialogReact from '../components/ab_experiment_dialog_react';
import LayerDialogReact from '../components/layer_dialog_react/index';
import MultiArmedBanditDialog from '../components/mab_dialog';
import MVTExperimentDialog from '../components/mvt_experiment_dialog_react';

export const showABExperimentCreationDialogReact = (
  config = {},
  options = {
    fullScreen: true,
    dismissOnBack: true,
    isOuiDialog: true,
  },
) =>
  RoutingHelpers.fetchCreateLayerData().then(() => {
    ui.showReactDialog(ABExperimentDialogReact, config, options);
  });

/**
 * Shows the layer detail dialog for creating or editing a layer
 *
 * @param {Object} config
 * @param {Object} options
 */
export const showLayerDetailDialogReact = (
  config = {},
  options = {
    fullScreen: true,
    dismissOnBack: true,
    isOuiDialog: true,
  },
) =>
  RoutingHelpers.fetchCreateLayerData().then(() => {
    ui.showReactDialog(LayerDialogReact, config, options);
  });

export const showMVTExperimentCreationDialogReact = (
  config = {},
  options = {
    fullScreen: true,
    dismissOnBack: true,
    isOuiDialog: true,
  },
) =>
  RoutingHelpers.fetchCreateLayerData().then(() => {
    ui.showReactDialog(MVTExperimentDialog, config, options);
  });

export const showMABCreationDialog = (
  config = {},
  options = {
    dismissOnBack: true,
    fullScreen: true,
    isOuiDialog: true,
  },
) =>
  RoutingHelpers.fetchCreateLayerData().then(() => {
    ui.showReactDialog(MultiArmedBanditDialog, config, options);
  });

export default {
  showABExperimentCreationDialogReact,
  showLayerDetailDialogReact,
  showMABCreationDialog,
  showMVTExperimentCreationDialogReact,
};

// Hard limit on number of experiments per project to limit snippet size
export const MAX_EXPERIMENTS_PER_PROJECT = __TEST__ ? 3 : 800;
export const MAX_CUSTOM_ATTRIBUTES_PER_CUSTOM_PROJECT = 100;
export const MAX_CUSTOM_ATTRIBUTES = 100;

export default {
  MAX_CUSTOM_ATTRIBUTES,
  MAX_CUSTOM_ATTRIBUTES_PER_CUSTOM_PROJECT,
  MAX_EXPERIMENTS_PER_PROJECT,
};

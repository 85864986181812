const classnames = require('classnames');
const PropTypes = require('prop-types');
const React = require('react');

const Immutable = require('optly/immutable').default;
const SelectDropdown = require('react_components/select_dropdown').default;

const { Button, Textarea, Icon } = require('optimizely-oui');

const ViewConstants = require('optly/modules/entity/view/constants');

class UrlCondition extends React.Component {
  constructor(props) {
    super(props);

    this.addConditionRow = this.addConditionRow.bind(this);
    this.removeConditionRow = this.removeConditionRow.bind(this);
    this.onChangeCondition = this.onChangeCondition.bind(this);
    this.onChangeMatchType = this.onChangeMatchType.bind(this);
  }

  addConditionRow(event) {
    this.props.addConditionRow(this.props.index);
  }

  removeConditionRow(event) {
    this.props.removeConditionRow(this.props.index);
  }

  onChangeCondition(event) {
    this.props.onChangeCondition(this.props.index, event);
  }

  onChangeMatchType(event) {
    this.props.onChangeMatchType(this.props.index, event);
  }

  render() {
    const {
      condition,
      index,
      length,
      showMatchType,
      children,
      displayError,
    } = this.props;

    return (
      <tr data-test-section="include-url-condition" className="no-border--top">
        <td className="width--1-1">
          <Textarea
            type="text"
            numRows={1}
            displayError={displayError}
            value={condition.get('value')}
            onChange={this.onChangeCondition}
            testSection={`condition-url-input-row-${index}`}
          />
          {children}
        </td>
        {showMatchType && (
          <td>
            <SelectDropdown
              items={ViewConstants.URL_MATCH_TYPES}
              value={condition.get('match_type')}
              onChange={this.onChangeMatchType}
              width="200px"
              testSection="match-type-condition"
            />
          </td>
        )}
        <td className="oui-table--add-row__controls">
          <Button
            className={classnames({
              'soft-half--sides': true,
            })}
            testSection={`add-condition-button-row-${index}`}
            style="outline-reverse"
            onClick={this.addConditionRow}>
            <Icon name="plus" size="small" />
          </Button>
          <Button
            className={classnames({
              'push-half--left': true,
              'soft-half--sides': true,
              'visibility--hidden': length <= 1,
            })}
            testSection="remove-condition-button"
            style="outline-reverse"
            size="medium"
            onClick={this.removeConditionRow}>
            <Icon
              className={classnames({
                'optly-hard--sides': true,
              })}
              name="xmark"
              size="small"
            />
          </Button>
        </td>
      </tr>
    );
  }
}

UrlCondition.propTypes = {
  condition: PropTypes.instanceOf(Immutable.Map).isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  addConditionRow: PropTypes.func.isRequired,
  removeConditionRow: PropTypes.func.isRequired,
  onChangeCondition: PropTypes.func.isRequired,
  onChangeMatchType: PropTypes.func,
  showMatchType: PropTypes.bool,
  children: PropTypes.node,
  displayError: PropTypes.bool,
};

UrlCondition.defaultProps = {
  showMatchType: true,
};

module.exports = UrlCondition;

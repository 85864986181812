import enums from './enums';

/**
 * Services layer pure functions for the dimensions
 */
export default {
  create(data) {
    const DEFAULTS = {
      id: null,
      project_id: null,
      name: '',
      description: '',
      // Set new Dimensions to Active by default.
      status: enums.dimension_status.ACTIVE,
      last_modified: null,
    };
    return {
      ...DEFAULTS,
      ...data,
    };
  },
};

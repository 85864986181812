/**
 * Filter for dates to be simplified.
 *
 * Used on the Tokens page
 * @author Cheston Lee
 */
const tr = require('optly/translate');

module.exports = function(value) {
  // Return value if it cannot be parsed
  if (isNaN(Date.parse(value))) {
    return value;
  }
  return tr.date(value).format('ll');
};

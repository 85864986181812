import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';
import enums from './enums';

const baseEntityActions = RestApi.createEntityActions(definition);

export default {
  ...baseEntityActions,
  /**
   * Fetches all custom dimensions for a project
   *
   * @param {Integer} number
   * @return {Deferred}
   */
  fetchDimensionsForProject(projectId) {
    return this.fetchAll({
      project_id: projectId,
    });
  },

  archive(dimension) {
    return this.save({
      ...dimension,
      status: enums.dimension_status.INACTIVE,
    });
  },

  unarchive(dimension) {
    return this.save({
      ...dimension,
      status: enums.dimension_status.ACTIVE,
    });
  },
};

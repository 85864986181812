import PropTypes from 'prop-types';
import React from 'react';

import { connectGetters } from 'core/ui';

import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { fns as PermissionsFns } from 'optly/modules/permissions';

import Header from '../header';
import Footer from '../footer';

const WelcomeWrapper = props => {
  const { children, shouldShowDeveloperSupportFooter } = props;
  return (
    <div
      className="overflow-y--auto flex--1"
      data-test-section="welcome-section-wrapper">
      <Header />
      {children}
      {shouldShowDeveloperSupportFooter && <Footer />}
    </div>
  );
};

WelcomeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  shouldShowDeveloperSupportFooter: PropTypes.bool.isRequired,
};

export default connectGetters(WelcomeWrapper, {
  shouldShowDeveloperSupportFooter: [
    AdminAccountGetters.accountPermissions,
    accountPermissions =>
      PermissionsFns.canUseFullStackSDKs(accountPermissions) ||
      PermissionsFns.canUseMobileSDKs(accountPermissions) ||
      PermissionsFns.canUseOverTheTopSDKs(accountPermissions),
  ],
});

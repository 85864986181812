import RestApi from 'optly/modules/rest_api';
import { toImmutable } from 'optly/immutable';

import entityDef from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(entityDef);

export const {
  save,
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deleteRecommenderStats = baseEntityActions.delete;

export { deleteRecommenderStats as delete };

/**
 * Fetch summary
 *
 * @param {Number} recommenderId
 * @param {Object} fetchOptions
 * @returns {Promise<any | never>} API JSON formatted response promise
 */
const fetchRecommenderStatsByRecommenderId = (recommenderId, fetchOptions) => {
  const fetchFilters = {
    recommender_id: recommenderId,
  };

  return fetchAll(fetchFilters, fetchOptions);
};

/**
 * Given an itemId, fetch the recommendation for the given item from the recommender_serving_url in the recommender.
 *
 * @param {Immutable.Map} recommender the recommender that is used to fetch for recommendations of given itemID
 * @param {String} itemId id of the item to be fetched for recommendations.
 * @return {Promise<any | never>} Promise that resolved with itemRecommendations fetched from the recommender_serving_url.
 */
const fetchRecommendationsById = (recommender, itemId) => {
  const fetchURL = `${recommender.get(
    'recommender_serving_url',
  )}/${encodeURIComponent(itemId)}`;

  return window
    .fetch(fetchURL, { credentials: 'same-origin' })
    .then(response => {
      if (response.ok) {
        return response.json();
      }

      return null;
    })
    .then(jsonPayload => {
      const recommendations =
        !!jsonPayload && toImmutable(jsonPayload).getIn(['data', 'recos']);

      /**
       * Need to convert this list to Immutable structure because one property in recommendation varies by different users.
       */
      return recommendations && toImmutable(JSON.parse(recommendations));
    });
};

export default {
  ...baseEntityActions,
  fetchRecommenderStatsByRecommenderId,
  fetchRecommendationsById,
};

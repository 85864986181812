import { Store as NuclearStore } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default NuclearStore({
  initialize() {
    // loads an entity into store, semantically a non API fetch
    this.on(actionTypes.API_INJECT_ENTITY, loadEntityData);

    this.on(actionTypes.API_ENTITY_FETCH_SUCCESS, loadEntityDataFromFetch);
    this.on(actionTypes.API_ENTITY_FETCH_FAIL, onFetchFail);

    this.on(actionTypes.API_ENTITY_PERSIST_SUCCESS, loadEntityData);
    this.on(actionTypes.API_ENTITY_PERSIST_FAIL, onPersistFail);

    this.on(actionTypes.API_ENTITY_DELETE_SUCCESS, onDeleteSuccess);
    this.on(actionTypes.API_ENTITY_DELETE_FAIL, onDeleteFail);

    this.on(actionTypes.FLUSH_ENTITY_STORE, flushEntityStore);
  },
});

/**
 * payload.entity
 * @param {Immutable.Map} state
 * @param {object} payload
 */
function flushEntityStore(state, payload) {
  return state.delete(payload.entity);
}

/**
 * payload.data
 * payload.entity
 * @param {Immutable.Map} state
 * @param {object} payload
 */
function loadEntityData(state, payload) {
  const entity = payload.entity;
  let data = payload.data;

  if (!Array.isArray(data)) {
    data = [data];
  }

  return state.withMutations(mutableState => {
    data.forEach(entry => {
      mutableState.setIn([entity, entry.id], toImmutable(entry));
    });
  });
}

/**
 * payload.data
 * payload.entity
 * @param {Immutable.Map} state
 * @param {Immutable.Map} payload
 * @param {Immutable.Map} requestInfo
 * @return {Immutable.Map}
 */
function loadEntityDataFromFetch(state, payload) {
  let entityCacheState = state;
  const { entity, requestInfo } = payload;
  let { data } = payload;
  // skip updating entity cache if $idsOnly filter in use
  if (requestInfo && requestInfo.getIn(['requestArgs', '$idsOnly'])) {
    return entityCacheState;
  }
  // prune any deleted values that may have been deleted from entities
  if (requestInfo && requestInfo.get('shouldClearCache')) {
    entityCacheState = entityCacheState.set(entity, toImmutable({}));
  }
  if (!Array.isArray(data)) {
    data = [data];
  }

  return entityCacheState.withMutations(updatedState => {
    data.forEach(entry => {
      updatedState.setIn([entity, entry.id], toImmutable(entry));
    });
  });
}

/**
 * payload.id
 * payload.entity
 * @param {Immutable.Map} state
 * @param {object} payload
 */
function onDeleteSuccess(state, payload) {
  const entity = payload.entity;
  const id = payload.id;

  return state.removeIn([entity, id]);
}

/**
 * payload.data
 * payload.entity
 * @param {Immutable.Map} state
 * @param {object} payload
 */
function onFetchFail(state, payload) {
  // noop right now
  return state;
}

/**
 * payload.data
 * payload.entity
 * @param {Immutable.Map} state
 * @param {object} payload
 */
function onPersistFail(state, payload) {
  // noop right now
  return state;
}

/**
 * payload.data
 * payload.entity
 * @param {Immutable.Map} state
 * @param {object} payload
 */
function onDeleteFail(state, payload) {
  // noop right now
  return state;
}

const $ = require('jquery');

const createPluginConfigField = require('./create_plugin_config_field');

module.exports = createPluginConfigField({
  template: require('./rich_text.html'),

  methods: {
    onChange(event) {
      this.updateValue($(event.target).html());
    },
  },

  ready() {
    $(this.$$.input).html(this.value);
  },
});

import Immutable from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'core/ui/decorators';
import ui from 'core/ui';
import UrlHelper from 'optly/services/url_helper';

import {
  actions as JiraIntegrationActions,
  getters as JiraIntegrationGetters,
} from 'optly/modules/jira_integration';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

import { Jira } from '@optimizely/optly-components';
import ErrorBoundary from 'core/ui/components/error_boundary';

import JiraIssuesPicker from '../issues_picker';

@connect({
  currentProjectId: CurrentProjectGetters.id,
  isUserConnectedToJira: JiraIntegrationGetters.isUserConnected,
  jiraIntegrationSettings: CurrentProjectGetters.jiraIntegrationSettings,
})
class JiraOptionsWrapper extends React.Component {
  static propTypes = {
    currentProjectId: PropTypes.number.isRequired,
    isUserConnectedToJira: PropTypes.bool,
    jiraIntegrationSettings: PropTypes.instanceOf(Immutable.Map).isRequired,
    newWindow: PropTypes.bool,
    source: PropTypes.instanceOf(Immutable.Map).isRequired,
    sourceType: PropTypes.oneOf(['feature', 'experiment', 'campaign'])
      .isRequired,
  };

  static defaultProps = {
    newWindow: false,
    isUserConnectedToJira: false,
  };

  openLinkIssuesDialog = () => {
    const { source, sourceType } = this.props;
    ui.showReactDialog(
      JiraIssuesPicker,
      {
        props: {
          onSave: ui.hideDialog,
          onCancel: ui.hideDialog,
          source,
          sourceType,
        },
      },
      {
        fullScreen: true,
        dismissOnBack: true,
      },
    );
  };

  render() {
    const {
      newWindow,
      currentProjectId,
      isUserConnectedToJira,
      jiraIntegrationSettings,
    } = this.props;

    return (
      <ErrorBoundary>
        <Jira.Options
          newWindow={newWindow}
          isConnected={isUserConnectedToJira}
          urlToConnectUser={JiraIntegrationActions.getUrlToConnectUser()}
          linkIssues={this.openLinkIssuesDialog}
          hasCompletedSetup={!!jiraIntegrationSettings.get('resource_id')}
          urlToCompleteSetup={UrlHelper.projectSettingsIntegrations(
            currentProjectId,
            'jira',
          )}
        />
      </ErrorBoundary>
    );
  }
}

export default JiraOptionsWrapper;

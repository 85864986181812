import actions from './actions';
import enums from './enums';
import getters from './getters';

// named exports
export { actions };
export { enums };
export { getters };

// default export
export default {
  actions,
  enums,
  getters,
};

/**
 * Slices the given array of elements and returns that slice
 * @param {Array} arr
 * @param {Integer} start
 * @param {Integer} end
 * @returns {Array}
 */
module.exports = function(arr, start, end) {
  if (isNaN(start) && this[start] && !isNaN(this[start])) {
    start = this[start];
  }
  if (isNaN(end) && this[end] && !isNaN(this[end])) {
    end = this[end];
  }
  return arr.slice(start, end);
};

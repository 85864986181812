/**
 * Gets experiment count of given audience
 * @param {Immutable.Map} audience
 * @param {Immutable.List} List of experiment count of the audiences
 * @returns {Number} No of experiments given audience appears in
 */
export function getExperimentCountofAudience(
  audience,
  audienceExperimentCount,
) {
  const audienceId = audience.get('id');
  const experimentCount = audienceExperimentCount.filter(
    count => count.get('id') === audienceId,
  );
  return experimentCount.size
    ? experimentCount.get(0).get('experiment_count')
    : 0;
}

export default {
  getExperimentCountofAudience,
};

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

/**
 * Getter that filters for active views on the account level.
 */
export const activeMetrics = [
  entityCache,
  metrics => metrics.filter(metric => !metric.get('archived')),
];

/**
 * Getter that filters for active views on the account level.
 */
export const archivedMetrics = [
  entityCache,
  metrics => metrics.filter(metric => metric.get('archived')),
];

export default {
  ...baseEntityGetters,
  activeMetrics,
};

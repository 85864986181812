/**
 * Wrapper around bundle/backend/api.js
 *
 * @author Jordan Garcia
 */
const config = require('atomic-config');
const flux = require('core/flux');
const AdminAccount = require('optly/modules/admin_account');

/**
 * @param {string?} instance the string identifier of the instance (new backend vs mongo).  This is
 *                           defined at the experiment level by experiment.backend_api_host
 * @return {optly.backend.Api}
 */
module.exports = {
  create(instance) {
    const accountId = flux.evaluate(AdminAccount.getters.id);
    const backendApiToken = flux.evaluate(AdminAccount.getters.backendApiToken);
    const apiPrefix = config.get('env.BACKEND_API_HOST');

    return new optly.backend.Api(
      apiPrefix,
      accountId,
      backendApiToken,
      instance,
    );
  },
};

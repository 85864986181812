/**
 * Functions that help deal with Strings.
 *
 * @author Cheston Lee
 */
import $ from 'jquery';

/**
 * Capitalizes the first character in the given string (assumed to be a word)
 * @param  {String} string
 * @return {String}
 */
export const capitalize = function(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.substring(1);
  }
  return string;
};

/**
 * Capitalizes a string and translates it so it can be ready for display
 * @param  {String} string
 * @return {String}
 */
export const formatForDisplay = function(string) {
  const capitalizedString = capitalize(string);
  return tr('{0}', capitalizedString);
};

/**
 * This abuses jQuery to easily decode a string containing html entities.
 * i.e.
 * original: I'll &quot;walk&quot; the &lt;b&gt;dog&lt;/b&gt; now
 * result: I'll "walk" the <b>dog</b> now
 *
 * @param {string} str String to decode
 * @return {string} completely decoded string
 */
export const htmlDecode = function(str) {
  return $('<div/>')
    .html(str)
    .text();
};

/**
 * Truncate the string value to the max length while slicing the string till the last index of white-space before the maxLength.
 * @param  {String} str String to truncate
 * @param  {Number} maxLength slicing max length of str
 * @return {String}
 */
export const strLimiter = (str, maxLength = 300) => {
  if (!str) {
    return '';
  }
  if (str.length <= maxLength) {
    return str.trimEnd();
  }
  const sliceString = str.slice(0, maxLength - 1);
  const indexToTrimTheWhiteSpace = sliceString.lastIndexOf(' ');
  if (indexToTrimTheWhiteSpace < 0) {
    return `${sliceString.trimEnd()}...`;
  }
  const trimmedStr = sliceString.slice(0, indexToTrimTheWhiteSpace);
  return `${trimmedStr}...`;
};

export default {
  capitalize,
  formatForDisplay,
  htmlDecode,
  strLimiter,
};

import constants from './constants';
import getters from './getters';
import actions from './actions';
import fns from './fns';

export { constants, getters, actions, fns };

export default {
  constants,
  getters,
  actions,
  fns,
};

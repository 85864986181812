import Immutable from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Input, Table } from 'optimizely-oui';
import { Fieldset } from 'react_components/dialog';
import ExperimentSectionConstants from 'optly/modules/entity/experiment_section/constants';

const REQUIRED_TOTAL_PERCENTAGE =
  ExperimentSectionConstants.REQUIRED_TOTAL_VARIATION_PERCENTAGE_PER_SECTION;

class CreateSectionFormInputs extends React.Component {
  constructor(props) {
    super(props);

    this.onRenameSection = this.onRenameSection.bind(this);
    this.onChangeVariation = this.onChangeVariation.bind(this);
    this.onRemoveVariation = this.onRemoveVariation.bind(this);
    this.onAddVariation = this.onAddVariation.bind(this);
  }

  onRenameSection(event) {
    const name = event.target.value;
    this.props.onRenameSection(name);
  }

  onChangeVariation(index, property, event) {
    const value = event.target.value;
    this.props.onChangeVariation(index, property, value);
  }

  onRemoveVariation(index) {
    this.props.onRemoveVariation(index);
  }

  onAddVariation() {
    this.props.onAddVariation();
  }

  render() {
    const { form, errors } = this.props;

    return (
      <div>
        <Fieldset key="name">
          <h3>
            Section Name
            <span className="oui-label display--inline oui-label--required" />
          </h3>
          <p>
            Choose a name that reflects what you&apos;re testing, like
            &quot;Headline Text.&quot;
          </p>
          <Input
            testSection="create-section-section-name"
            isRequired={true}
            onInput={this.onRenameSection}
            placeholder="New Section"
          />
          {errors.get('name') && (
            <div
              className="lego-form-note lego-form-note--bad-news"
              data-test-section="create-section-section-name-error">
              {errors.get('name')}
            </div>
          )}
        </Fieldset>
        <Fieldset key="variations">
          <h3>Variations</h3>
          <p>
            Change the percentage of experiment visitors who see any given
            variation.
          </p>
          <Table tableLayoutAlgorithm="fixed">
            <Table.THead>
              <Table.TR>
                <Table.TH width="70%">
                  Variation Name
                  <span className="oui-label display--inline oui-label--required" />
                </Table.TH>
                <Table.TH width="30%">Traffic Allocation</Table.TH>
              </Table.TR>
            </Table.THead>
            <Table.TBody>
              {form.get('variations').map((variation, index) => {
                const percentage =
                  variation.get('weight') / REQUIRED_TOTAL_PERCENTAGE;
                const variationCount = form.get('variations').size;
                return (
                  <Table.TR
                    testSection="create-section-variation-row"
                    key={index}>
                    <Table.TD>
                      <Input
                        testSection="create-section-variation-name"
                        isRequired={true}
                        type="text"
                        value={variation.get('name')}
                        onChange={_.partial(
                          this.onChangeVariation,
                          index,
                          'name',
                        )}
                      />
                    </Table.TD>
                    <Table.TD>
                      <span className="display--inline-block width--75">
                        <Input
                          testSection="create-section-variation-percentage"
                          isRequired={true}
                          type="number"
                          max={REQUIRED_TOTAL_PERCENTAGE}
                          min={0}
                          value={Number(percentage)}
                          onChange={_.partial(
                            this.onChangeVariation,
                            index,
                            'percentage',
                          )}
                        />
                      </span>{' '}
                      %
                      {variationCount !== 1 && (
                        <a
                          className="push--left"
                          onClick={_.partial(this.onRemoveVariation, index)}
                          data-test-section="create-section-delete-variation">
                          Delete
                        </a>
                      )}
                    </Table.TD>
                  </Table.TR>
                );
              })}
              {errors.get('variations') && (
                <div
                  className="lego-form-note lego-form-note--bad-news"
                  data-test-section="create-section-variations-error">
                  {errors.get('variations')}
                </div>
              )}
              <Table.TR>
                <Table.TD colSpan={2}>
                  <a
                    data-test-section="create-section-add-variation"
                    onClick={_.partial(this.onAddVariation)}>
                    Add Variation...
                  </a>
                </Table.TD>
              </Table.TR>
            </Table.TBody>
          </Table>
        </Fieldset>
      </div>
    );
  }
}

CreateSectionFormInputs.propTypes = {
  form: PropTypes.instanceOf(Immutable.Map).isRequired,
  errors: PropTypes.instanceOf(Immutable.Map).isRequired,
  onRenameSection: PropTypes.func.isRequired,
  onChangeVariation: PropTypes.func.isRequired,
  onRemoveVariation: PropTypes.func.isRequired,
  onAddVariation: PropTypes.func.isRequired,
};

export default CreateSectionFormInputs;

export default {
  isRelationshipEntity: true,

  entity: 'user_roles',
  parents: [
    {
      entity: 'accounts',
      key: 'account_id',
    },
    {
      entity: 'user_roles',
      key: 'user_id',
    },
  ],
};

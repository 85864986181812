/**
 * Constants for p13n.
 */
import keyMirror from 'optly/utils/key_mirror';

export default {
  CampaignSettingsEvents: keyMirror({
    HOLDBACK_SELECTION_CHANGED: null,
    PRIMARY_EVENT_SELECTION_CHANGED: null,
  }),

  PreviousViews: {
    EXTENSIONS: 'extensions',
    INTEGRATIONS: 'integrations',
  },
};

import _ from 'lodash';

import Immutable, { toImmutable } from 'optly/immutable';

import UIVersion from 'optly/modules/ui/version';
import RestApiFns from 'optly/modules/rest_api/fns';
import { ExperimentStatusType } from 'optly/utils/enums';

// NOTE: Please sort it when adding something new.
import AdminAccountGetters from 'optly/modules/admin_account/getters';
import AudienceEnums from 'optly/modules/entity/audience/enums';
import AudienceGetters from 'optly/modules/entity/audience/getters';
import AudienceExperimentCountGetters from 'optly/modules/entity/audience_experiment_count/getters';
import CatalogGetters from 'optly/modules/entity/catalog/getters';
import CollaboratorGetters from 'optly/modules/entity/collaborator/getters';
import CustomAttributeGetters from 'optly/modules/entity/custom_attribute/getters';
import CustomAudienceIntegrationGetters from 'optly/modules/entity/custom_audience_integration/getters';
import CustomAudienceAttributeDefinitionGetters from 'optly/modules/entity/custom_audience_attribute_definitions/getters';
import DimensionGetters from 'optly/modules/entity/dimension/getters';
import DcpDatasourceGetters from 'optly/modules/entity/dcp_datasource/getters';
import DynamicConditionGetters from 'optly/modules/entity/dynamic_condition/getters';
import EventGetters from 'optly/modules/entity/event/getters';
import ExperimentGetters from 'optly/modules/entity/experiment/getters';
import ExperimentationGroupGetters from 'optly/modules/entity/experimentation_group/getters';
import FeatureFlagGetters from 'optly/modules/entity/feature_flag/getters';
import FilterableTableEnums from 'optly/modules/filterable_table/enums';
import FilterableTableFns from 'optly/modules/filterable_table/fns';
import GoalGetters from 'optly/modules/entity/goal/getters';
import IntegrationEnums from 'optly/modules/entity/integration/enums';
import IntegrationFns from 'optly/modules/entity/integration/fns';
import IntegrationGetters from 'optly/modules/entity/integration/getters';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerGetters from 'optly/modules/entity/layer/getters';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import LayerExperimentFns from 'optly/modules/entity/layer_experiment/fns';
import LayerExperimentGetters from 'optly/modules/entity/layer_experiment/getters';
import ListAttributesGetters from 'optly/modules/entity/list_attributes/getters';
import MetricTemplateGetters from 'optly/modules/entity/metric_template/getters';
import PermissionsFns from 'optly/modules/permissions/fns';
import PluginEnums from 'optly/modules/entity/plugin/enums';
import PluginGetters from 'optly/modules/entity/plugin/getters';
import ProjectConstants from 'optly/modules/entity/project/constants';
import ProjectEnums from 'optly/modules/entity/project/enums';
import ProjectFns from 'optly/modules/entity/project/fns';
import ProjectGetters from 'optly/modules/entity/project/getters';
import ProjectActivityGetters from 'optly/modules/entity/project_activity/getters';
import ProjectIntegrationGetters from 'optly/modules/entity/project_integration/getters';
import RecommenderGetters from 'optly/modules/entity/recommender/getters';
import TagGetters from 'optly/modules/entity/tag/getters';
import TargetingListGetters from 'optly/modules/entity/targeting_list/getters';
import TagGroupGetters from 'optly/modules/entity/tag_group/getters';
import ViewGetters from 'optly/modules/entity/view/getters';
import WebhookGetters from 'optly/modules/entity/webhook/getters';

import constants from './constants';
import fns from './fns';

const id = ['currentProjectId'];

const project = [
  ProjectGetters.entityCache,
  id,
  (projects, currentProjectId) => projects.get(currentProjectId, null),
];

const createdDate = [
  project,
  projectModel => (projectModel ? projectModel.get('created') : null),
];

const name = [project, projectModel => projectModel.get('project_name')];

const accountId = [project, projectModel => projectModel.get('account_id')];

const projectResultsAPIToken = [
  project,
  projectModel => projectModel.get('p13n_results_api_token'),
];

const projectToken = [project, projectModel => projectModel.get('token')];

const excludeDisabledExperiments = [
  project,
  projectModel => projectModel.get('exclude_disabled_experiments'),
];

const status = [
  project,
  projectModel => (projectModel ? projectModel.get('project_status') : null),
];

const isClassicTestingEnabledInClient = [
  project,
  projectModel => {
    if (projectModel) {
      if (
        projectModel.getIn(['client_build_settings', 'client_type']) ===
          ProjectEnums.ClientBuildSettings.BUNDLED ||
        projectModel.getIn(['client_build_settings', 'client_type']) ===
          ProjectEnums.ClientBuildSettings.LEGACY
      ) {
        return true;
      }
    }
    return false;
  },
];

const isPersonalizationEnabledInClient = [
  project,
  projectModel => {
    if (projectModel) {
      if (
        projectModel.getIn(['client_build_settings', 'client_type']) ===
          ProjectEnums.ClientBuildSettings.BUNDLED ||
        projectModel.getIn(['client_build_settings', 'client_type']) ===
          ProjectEnums.ClientBuildSettings.STANDALONE
      ) {
        return true;
      }
    }
    return false;
  },
];

const isCustomProject = [
  project,
  currentProject => {
    const projectObject = currentProject ? currentProject.toJS() : {};
    return ProjectFns.isCustomProject(projectObject);
  },
];
const isClassicCustomProject = [
  project,
  currentProject => {
    const projectObject = currentProject ? currentProject.toJS() : {};
    return ProjectFns.isClassicCustomProject(projectObject);
  },
];
const isXCustomProject = [
  project,
  currentProject => {
    const projectObject = currentProject ? currentProject.toJS() : {};
    return ProjectFns.isXCustomProject(projectObject);
  },
];
const isAndroidProject = [
  project,
  currentProject => {
    const projectObject = currentProject ? currentProject.toJS() : {};
    return ProjectFns.isAndroidProject(currentProject);
  },
];
const isiOSProject = [
  project,
  currentProject => {
    const projectObject = currentProject ? currentProject.toJS() : {};
    return ProjectFns.isiOSProject(currentProject);
  },
];
const isMobileProject = [
  project,
  currentProject => {
    const projectObject = currentProject ? currentProject.toJS() : {};
    return ProjectFns.isMobileProject(projectObject);
  },
];
const isXMobileProject = [
  project,
  currentProject => {
    const projectObject = currentProject ? currentProject.toJS() : {};
    return ProjectFns.isXMobileProject(projectObject);
  },
];
const isOTTProject = [
  project,
  currentProject => {
    const projectObject = currentProject ? currentProject.toJS() : {};
    return ProjectFns.isOTTProject(projectObject);
  },
];
const isFlagsProject = [
  project,
  currentProject => {
    const projectObject = currentProject ? currentProject.toJS() : {};
    return (
      ProjectFns.isFullStackProject(projectObject) &&
      !!projectObject.is_flags_enabled
    );
  },
];
const isFullStackProject = [
  project,
  currentProject => {
    const projectObject = currentProject ? currentProject.toJS() : {};
    return ProjectFns.isFullStackProject(projectObject);
  },
];
const isWebProject = [
  project,
  currentProject => ProjectFns.isWebProject(currentProject),
];
// Unfortunately, can't use PermissionsGetters.canAccountUseFullStackExperiments because
// it introduces a circular import.
const isRolloutsProject = [
  project,
  [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseFullStackExperiments,
  ],
  (currentProject, canAccountUseFullStackExperiments) => {
    return (
      ProjectFns.isFullStackProject(currentProject) &&
      !canAccountUseFullStackExperiments
    );
  },
];

// Only free Rollouts projects have running experiment limits
const runningExperimentLimit = [
  project,
  projectModel => projectModel.get('running_experiment_limit'),
];

const isUIVersionX = [UIVersion.getters.activeVersion, UIVersion.isOptimizelyX];
// for mobile projects, we change the name of dimensions to attributes
const dimensionText = [
  isMobileProject,
  isCustomProject,
  isUIVersionX,
  (_isMobileProject, _isCustomProject, _isUIVersionX) => {
    if (_isUIVersionX) {
      return tr('custom attribute');
    }

    return _isMobileProject || _isCustomProject
      ? tr('attribute')
      : tr('dimension');
  },
];

const dimensionTextPlural = [
  isMobileProject,
  isCustomProject,
  isUIVersionX,
  (_isMobileProject, _isCustomProject, _isUIVersionX) => {
    if (_isUIVersionX) {
      return tr('custom attributes');
    }

    return _isMobileProject || _isCustomProject
      ? tr('attributes')
      : tr('dimensions');
  },
];

const previewJSFileName = [
  project,
  currentProject => {
    if (currentProject) {
      return currentProject.get('preview_js_file_name');
    }
    return '';
  },
];

/**
 * Function getter to take currentProject + dimensions store
 * and return all dimensions for that project
 */
const dimensions = [DimensionGetters.entityCache, id, fns.filterByProjectId];

/**
 * Function getter to take currentProject + customAttributes store
 * and return all customAttributes for that project
 */
const customAttributes = [
  CustomAttributeGetters.entityCache,
  id,
  fns.filterByProjectId,
];

const experiments = [ExperimentGetters.entityCache, id, fns.filterByProjectId];

/**
 * Function getter to return all project activities for that project
 */
const projectActivities = [
  id,
  ProjectActivityGetters.entityCache,
  (currentProjectId, activities) =>
    activities
      .filter(
        activity =>
          currentProjectId && activity.get('project_id') === currentProjectId,
      )
      .toList(),
];

/**
 * Gets all goals visible to the current project.
 */
const goals = [GoalGetters.visibleGoals, id, fns.filterByProjectId];

const layerExperiments = [
  LayerExperimentGetters.entityCache,
  id,
  fns.filterByProjectId,
];

const layers = [LayerGetters.entityCache, id, fns.filterByProjectId];

// MVT Experiment only, remove after test
const runningLayers = [
  layers,
  _layers =>
    _layers.filter(
      layer => layer.get('status') === LayerEnums.entityStatus.RUNNING,
    ),
];
// End MVT Test

/**
 * Returns an Immutable list of the current project's layer experiments with a policy of SINGLE_EXPERIMENT
 * or MULTIVARIATE. Ensures the variables are set on their variation and also sets the current group ID or default ID
 * if the experiment is not in a group.
 *
 * NOTE: This getter allows for easier consumption of the Full Stack Experiment,
 * but is not a fully accurate representation of the layer experiment model.
 *
 * TODO(OASIS-2588) Make the core function of this an actual wrapper, and add the real layer_experiment to it alongside the computed data
 */
const fullStackLayerExperimentsWrapper = [
  layerExperiments,
  ExperimentationGroupGetters.entityToGroupMap,
  fns.wrapFullStackLayerExperiments,
];

const integrations = [
  project,
  IntegrationGetters.entityCache,
  ProjectIntegrationGetters.entityCache,
  experiments,
  layers,
  fns.getEnabledAndSortedIntegrationsForProjectType,
];

const integrationById = integrationId => [
  integrations,
  allIntegrations =>
    allIntegrations.find(
      integration => integration.get('id') === integrationId,
    ),
];

const projectIntegrationById = integrationId => [
  ProjectIntegrationGetters.settingsByIntegrationId(integrationId),
  id,
  (allProjectSettings, currentProjectId) =>
    allProjectSettings.find(
      setting => setting.get('project_id') === currentProjectId,
    ) || Immutable.Map({}),
];

const enabledAudienceIntegrations = [
  integrations,
  /**
   * Filters only enabled audience integrations
   * @param {Immutable.List} allIntegrations
   * @return {Immutable.List} allIntegrations
   */
  allIntegrations =>
    allIntegrations
      .filter(integration => {
        // POJO = plain ol javascript object
        const integrationPOJO = integration.toJS();
        return (
          IntegrationFns.isEnabled(integrationPOJO) &&
          IntegrationFns.isAudienceIntegration(integrationPOJO)
        );
      })
      .toList(),
];

/**
 * Function getter to return all integrations that are active for the current project and use the Optimizely Canvas.
 */
const enabledCanvasIntegrations = [
  integrations,
  allIntegrations =>
    allIntegrations.filter(
      integration =>
        integration.get('usesCanvas') &&
        integration.get('projectLevelData').get('enabled'),
    ),
];

/**
 * Function getter to return all personalization integrations that
 * project has permissions for
 */
const personalizationIntegrations = [
  AdminAccountGetters.accountPermissions,
  integrations,
  fns.filterPersonalizationIntegrations,
];

/**
 * Function getter to return all integrations that are active for the current project
 * and are available to configure at campaign level.
 */
const enabledCampaignLevelPersonalizationIntegrations = [
  integrations,
  allIntegrations =>
    allIntegrations.filter(
      integration =>
        integration
          .get('products')
          .indexOf(IntegrationEnums.products.PERSONALIZATION) !== -1 &&
        integration.getIn(['projectLevelData', 'enabled']) &&
        integration.getIn(['layerLevelData', 'onOffable']),
    ),
];

const jiraIntegrationSettings = [
  project,
  currentProject => currentProject.get('jira_integration'),
];

const dynamicConditions = [
  DynamicConditionGetters.entityCache,
  id,
  fns.filterByProjectId,
];

/**
 * Getter for all custom audience attribution definitions
 */
const customAudienceAttributeDefinitions = [
  CustomAudienceAttributeDefinitionGetters.entityCache,
  id,
  fns.filterByProjectId,
];

/**
 * Getter to get all the collaborators on the current project
 */
const collaborators = [
  id,
  AdminAccountGetters.id,
  CollaboratorGetters.entityCache,
  (currentProjectId, currentAccountId, entityCollaborators) => {
    if (currentProjectId) {
      // Include any admins associated with the admin account ID
      // and any admin collaborators associated with the project ID
      const accountUsers = entityCollaborators.filter(
        collaborator =>
          (collaborator.get('project_id') === currentAccountId ||
            collaborator.get('project_id') === currentProjectId) &&
          _.includes(
            constants.ACCOUNT_ROLES_ADMIN,
            collaborator.get('role_name'),
          ),
      );

      // Viewers, editors, and project owners are project-level collaborators.
      const projectUsers = entityCollaborators.filter(
        collaborator =>
          collaborator.get('project_id') === currentProjectId &&
          _.includes(constants.PROJECT_ROLES, collaborator.get('role_name')),
      );

      const projectUserList = projectUsers.toList().toJS();
      const accountUserList = accountUsers.toList().toJS();

      return toImmutable(
        _.uniqBy(_.union(projectUserList, accountUserList), 'user_id'),
      );
    }
    return toImmutable([]);
  },
];

/**
 * Getter to get all experiments in Snippet
 */
const experimentsInSnippet = [
  project,
  experiments,
  function(currentProject, currentExperiments) {
    const validStatuses = currentProject.get('exclude_disabled_experiments')
      ? [ExperimentStatusType.RUNNING]
      : [
          ExperimentStatusType.PAUSED,
          ExperimentStatusType.RUNNING,
          ExperimentStatusType.NOT_STARTED,
        ];
    return currentExperiments.filter(exp =>
      _.includes(validStatuses, exp.get('status')),
    );
  },
];

/**
 * Getter to get all the collaborators NOT on the current project
 */
const otherCollaborators = [
  id,
  AdminAccountGetters.id,
  CollaboratorGetters.entityCache,
  (currentProjectId, currentAccountId, collaborators) => {
    if (currentProjectId) {
      // Get all the users and project creators
      const accountUsers = collaborators.filter(
        collaborator =>
          collaborator.get('project_id') === currentAccountId &&
          _.includes(
            constants.ACCOUNT_ROLES_NON_ADMIN,
            collaborator.get('role_name'),
          ),
      );

      // Find the viewers, editors, and project owners, who may overlap
      const projectUsers = collaborators.filter(
        collaborator =>
          collaborator.get('project_id') === currentProjectId &&
          _.includes(constants.PROJECT_ROLES, collaborator.get('role_name')),
      );

      // Grab all of the user ids that are on the current project
      const projectUserIds = _.map(projectUsers.toJS(), 'user_id');

      // Exclude the users and project creators who are on this project
      const otherProjectUsers = accountUsers.filter(
        user => !_.includes(projectUserIds, user.get('user_id')),
      );
      return otherProjectUsers.toList();
    }
    return toImmutable([]);
  },
];

/**
 * Get possible project targets for entity duplication.
 */
const duplicationTargetProjects = [
  AdminAccountGetters.activeProjects,
  project,
  (activeProjects, currentProject) => {
    const currentPlatform = currentProject.getIn(['project_platforms', 0]);
    return activeProjects.filter(proj => {
      // filter out the current project
      if (proj.get('id') === currentProject.get('id')) {
        return false;
      }
      return proj.get('project_platforms').indexOf(currentPlatform) !== -1;
    });
  },
];

/**
 * Current project audiences
 */
const audiences = [AudienceGetters.entityCache, id, fns.filterByProjectId];

/**
 * An Audience is considered available if all of the following are true:
 * 1. Audience is not archived OR an archived Audience is already added to the LayerExperiment
 * 2. Audience has been touched by user (i.e. not just created by automated migration)
 *
 * @param {Immutable.List} audienceConditions
 * @returns {Array}
 *  Returns a getter that will evaluate to an Immutable List
 */
const availableAudiences = audienceConditions => [
  audiences,
  fns.getAvailableAudiences(audienceConditions),
];

/**
 * Audiences that are compatible with Optimizely X, excludes all Classic audiences.
 *
 * @param {Immutable.List} audiences
 * @retursn {Immutable.List}
 */
const xCompatibleAudiences = [
  audiences,
  _audiences =>
    _audiences.filter(audience => {
      const audienceCompatility = audience.get('compatible_with');
      return (
        audienceCompatility === AudienceEnums.audienceCompatility.ALL ||
        audienceCompatility === AudienceEnums.audienceCompatility.OPTIMIZELY_X
      );
    }),
];

/**
 * Current project audience experiment count
 */
const audienceExperimentCount = [
  AudienceExperimentCountGetters.entityCache,
  id,
  fns.filterByProjectId,
];

/**
 * Current project user lists
 */
const userLists = [TargetingListGetters.entityCache, id, fns.filterByProjectId];

/**
 * Current project list attributes
 */
const listAttributes = [
  ListAttributesGetters.entityCache,
  id,
  fns.filterByProjectId,
];

/**
 * Current project dcp datasources
 */
const dcpDatasources = [
  DcpDatasourceGetters.entityCache,
  project,
  (entityMap, currentProject) => {
    const dcpServiceId = currentProject.get('dcp_service_id');
    return entityMap
      .filter(
        entity =>
          dcpServiceId &&
          entity.get('dcp_service_id') === dcpServiceId &&
          (entity.get('project_id') === currentProject.get('id') ||
            !entity.get('is_optimizely')),
      )
      .toList();
  },
];

/**
 * Datasources which are not archived and which aren't Optimizely internal sources.
 */
const activeDcpDatasources = [
  dcpDatasources,
  datasources =>
    datasources.filter(
      datasource =>
        !datasource.get('archived') && !datasource.get('is_optimizely'),
    ),
];

/**
 * Returns all recommenders that are associated with all catalogs that are associated with the current project.
 *
 * @returns {Array} a getter that will evaluate to an Immutable List.
 */
const recommenders = [
  RecommenderGetters.entityCache,
  project,
  (recommenders, currentProject) => {
    const catalogIDs = currentProject.get('recommender_service_ids');
    return recommenders.filter(recommender =>
      catalogIDs.contains(recommender.get('recommender_service_id')),
    );
  },
];

/**
 * Returns the number of audiences that have segmentation enabled
 */
const reportableAudiencesCount = [
  project,
  audiences,
  (currentProject, audiences) =>
    audiences.filter(
      RestApiFns.createFilterFn({
        project_id: currentProject.get('id'),
        segmentation: true,
      }),
    ).size,
];

/**
 *
 * Returns a boolean determining if number of experiments in current project are under project limit
 */
const isUnderExperimentLimit = [
  experimentsInSnippet,
  experimentEntities =>
    experimentEntities.size <= ProjectConstants.MAX_EXPERIMENTS_PER_PROJECT,
];
/**
 * Logic for the calculation of available reporting slots (total slots(10) - segmentation audiences - dimensions)
 */
const availableReportingSlots = [
  reportableAudiencesCount,
  dimensions,
  isCustomProject,
  (audienceCount, dimensions, isCustomProject) => {
    const totalSlots = isCustomProject
      ? constants.TOTAL_REPORTING_SLOTS_CUSTOM_PROJECT
      : constants.TOTAL_REPORTING_SLOTS;
    return totalSlots - audienceCount - dimensions.size;
  },
];

/**
 * CurrentProject views
 */
const views = [ViewGetters.entityCache, id, fns.filterByProjectId];

/**
 * CurrentProject views that are not single_use
 */
const multiUseViews = [
  views,
  allViews => allViews.filter(view => !view.get('single_use')),
];

/**
 * CurrentProject webhooks
 */
const webhooks = [WebhookGetters.entityCache, id, fns.filterByProjectId];

/**
 * CurrentProject events
 */
const events = [EventGetters.entityCache, id, fns.filterByProjectId];

/**
 * CurrentProject active events
 * Note : An activeEvent should not have its parent view as archived
 */
const activeEvents = [
  events,
  ViewGetters.entityCache,
  (allEvents, allViews) =>
    allEvents.filter(event => {
      if (event.get('archived')) {
        return false;
      }
      const parentViewId = event.get('view_id');
      if (!parentViewId) {
        return true;
      }
      const parentView = allViews.get(parentViewId);
      return parentView && !parentView.get('archived');
    }),
];
/**
 * CurrentProject groups
 */
const experimentationGroups = [
  ExperimentationGroupGetters.entityCache,
  id,
  fns.filterByProjectId,
];

/**
 * Gets the active groups in the current project's groups
 */
const activeGroups = [
  experimentationGroups,
  groups =>
    FilterableTableFns.filterByArchivedStatus(
      groups,
      FilterableTableEnums.status.ACTIVE,
    ),
];

/**
 * CurrentProject TagGroups (collection of Tags for use with Adaptive Audiences)
 */
const tagGroups = [TagGroupGetters.entityCache, id, fns.filterByProjectId];

/**
 * The default TagGroup for this project (if it exists)
 * @type {[null,null]}
 */
const defaultTagGroup = [
  tagGroups,
  groups => groups.filter(g => g.get('is_default')).first(),
];

/**
 * CurrentProject tags
 */
const tags = [TagGetters.entityCache, id, fns.filterByProjectId];

/**
 * CurrentProject plugins
 */
const plugins = [PluginGetters.entityCache, id, fns.filterByProjectId];

/**
 * CurrentProject metric templates
 */
const metricTemplates = [
  MetricTemplateGetters.entityCache,
  id,
  fns.filterByProjectId,
];

const customAnalyticsIntegrations = [
  plugins,
  allPlugins =>
    allPlugins.filter(
      plugin =>
        plugin.get('plugin_type') ===
        PluginEnums.plugin_type.ANALYTICS_INTEGRATION,
    ),
];

const enabledAnalyticsIntegrationExtensions = [
  plugins,
  allPlugins =>
    allPlugins.filter(
      plugin =>
        plugin.get('plugin_type') ===
          PluginEnums.plugin_type.ANALYTICS_INTEGRATION &&
        plugin.get('is_enabled_in_client'),
    ),
];

const customAudienceIntegrations = [
  CustomAudienceIntegrationGetters.entityCache,
  id,
  fns.filterByProjectId,
];

const enabledCustomAudienceIntegrations = [
  customAudienceIntegrations,
  customAudienceIntegrations =>
    customAudienceIntegrations.filter(
      integration => integration.get('is_enabled_in_client') === true,
    ),
];

const permissions = [
  project,
  proj => {
    const emptySet = toImmutable([]);
    if (!proj) {
      return emptySet;
    }
    return proj.get('project_permissions', emptySet);
  },
];

const platform = [
  project,
  currentProject => currentProject.getIn(['project_platforms', 0]),
];

const primarySDKLanguage = [project, ProjectFns.getPrimarySDKLanguage];

const nameWithSdkIfApplicable = [
  project,
  primarySDKLanguage,
  (currentProject, projectSdkLanguage) =>
    ProjectFns.formatAnyProjectName(currentProject, projectSdkLanguage),
];

const isUsingV2Snippet = [
  project,
  currentProject => ProjectFns.isUsingV2Snippet(currentProject),
];

const features = [
  [FeatureFlagGetters.entityCache, id, fns.filterByProjectId],
  currentProjectFeatures =>
    currentProjectFeatures.map(feature => {
      // right now the API does not yet associate variables with features so
      // we assign an empty variables array if not available until the API is patched
      const variables = feature.get('variables') || toImmutable([]);
      return feature.set('variables', variables);
    }),
];

const activeFeatures = [
  features,
  allFeatures => allFeatures.filter(feature => !feature.get('archived')),
];

/**
 * Helper getter to check if user can create projects
 */
const canCreateProject = [
  project,
  AdminAccountGetters.accountInfo,
  AdminAccountGetters.activeProjects,
  PermissionsFns.canCreateProject,
];

/**
 *
 * @param {String} status
 *  A LayerExperiment status from optly/utils/enums.ExperimentStatusType
 * @returns {Array}
 *  Getter that evaluates to a Boolean
 */
const canEditExperiment = status => [
  project,
  currentProject => PermissionsFns.canEditExperiment(currentProject, status),
];

/**
 * Getter for grabbing only a specific set of properties
 */
const filteredProjectSettings = filteredSettings => [
  project,
  currentProject =>
    currentProject.filter((setting, key) => _.includes(filteredSettings, key)),
];

// In addition to the Account permission/feature, the project must have has_access_to_environments
// This is required due to legacy ios/android projects not supporting environments.
const environmentsAuthorizedAndSupported = [
  project,
  [AdminAccountGetters.accountPermissions, PermissionsFns.canUseEnvironments],
  (currentProject, accountCanUseEnvironments) =>
    !!(
      currentProject.get('has_access_to_environments') &&
      accountCanUseEnvironments
    ),
];

const currentProjectActiveEvents = [
  EventGetters.activeEvents,
  id,
  (entityMap, projectId) =>
    entityMap.filter(entity => entity.get('project_id') === projectId),
];

const currentProjectActiveTags = [
  TagGetters.activeTags,
  id,
  (entityMap, projectId) =>
    entityMap.filter(entity => entity.get('project_id') === projectId),
];

const currentProjectActiveViews = [
  ViewGetters.activeAccountViews,
  id,
  (entityMap, projectId) =>
    entityMap.filter(entity => entity.get('project_id') === projectId),
];

/**
 * Getter to represent the number of running experiments with a SINGLE_EXPERIMENT policy.
 * They can be running according to their actual_status, or according to whether
 * they are running in any environment
 */
const currentProjectRunningExperiments = [
  [LayerExperimentGetters.entityCache, id, fns.filterByProjectId],
  currentProjectExperiments =>
    currentProjectExperiments.filter(exp => {
      // Only want single experiment layer experiments, not layer experiments that
      // are targeting rules inside a rollout
      if (exp.get('layer_policy') !== LayerEnums.policy.SINGLE_EXPERIMENT) {
        return false;
      }

      if (
        exp.get('actual_status') === LayerExperimentEnums.ActualStatus.RUNNING
      ) {
        return true;
      }

      return LayerExperimentFns.isRunningInAnyEnvironment(exp);
    }),
];

/**
 * Getter to represent the current project's type, one of 'web', 'fullstack',
 * or 'rollouts'. Created for use with the JS SDK.
 */
const currentProjectType = [
  project,
  [
    AdminAccountGetters.accountPermissions,
    (...args) => PermissionsFns.canUseFullStackExperiments(...args),
  ],
  function(project, canUseFullStackExperiments) {
    if (!project) {
      return null;
    }

    if (ProjectFns.isWebProject(project)) {
      return ProjectEnums.project_types.WEB;
    }

    if (canUseFullStackExperiments) {
      return ProjectEnums.project_types.FULL_STACK;
    }

    return ProjectEnums.project_types.ROLLOUTS;
  },
];

const hasPageTrimming = [
  project,
  currentProject => !!currentProject.get('trim_unused_pages'),
];

/**
 * Returns all catalogs that are associated with the current project.
 */
const catalogs = [
  CatalogGetters.entityCache,
  id,
  (catalogs, currentProjectID) =>
    catalogs.filter(catalog =>
      catalog
        .get('project_specifics')
        .find(
          projectSpecific =>
            projectSpecific.get('project_id') === currentProjectID,
        ),
    ),
];

/**
 * Used to send updated project attributes via Optimizely Champagne
 */
const attributesForChampagne = [
  project,
  currentProjectType,
  (currentProject, projectType) => ({
    current_project_experiment_confidence_threshold:
      currentProject &&
      currentProject.get('experiment_confidence_threshold', null), // {Number} Statistical significance threshold, 0-10000
    current_project_code_revision:
      currentProject && currentProject.get('code_revision', null), // {Number} Update revision of current project
    current_project_created_date: +new Date(
      currentProject && currentProject.get('created'),
    ), // {Number} Date string coerced to epoch time
    current_project_id: currentProject && currentProject.get('id'), // {Number} ID of currently selected project
    current_project_milliseconds_since_code_last_modified:
      +new Date() -
        +new Date(currentProject && currentProject.get('code_last_modified')) ||
      null, // {Number} Date string coerced to time elapsed in milliseconds
    current_project_type: projectType, // {String} from "web", "fullstack", or "rollouts"
  }),
];

const eventsWithUrls = [
  EventGetters.activeEvents,
  ViewGetters.activeAccountPageViews,
  (eventsEntityMap, viewsEntityMap) =>
    eventsEntityMap
      .map(e => {
        if (e.get('event_type') === 'custom') {
          return e;
        }
        const view = viewsEntityMap.find(v => v.get('id') === e.get('view_id'));
        const updatedEvent = e.set(
          'edit_url',
          view ? view.get('edit_url') : null,
        );
        return updatedEvent;
      })
      .toList()
      .concat(viewsEntityMap.map(v => v.set('event_type', 'pageview'))),
];

const isDeactivationEnabled = [
  project,
  currentProject => currentProject.get('deactivation_enabled', false),
];

const undoOnDeactivation = [
  project,
  currentProject => currentProject.get('undo_on_deactivation', false),
];

/**
 * Getters for currentProject
 */
export {
  accountId,
  activeDcpDatasources,
  activeEvents,
  activeGroups,
  attributesForChampagne,
  audiences,
  availableAudiences,
  availableReportingSlots,
  canCreateProject,
  canEditExperiment,
  collaborators,
  createdDate,
  currentProjectActiveEvents,
  currentProjectActiveTags,
  currentProjectActiveViews,
  currentProjectRunningExperiments,
  currentProjectType,
  customAnalyticsIntegrations,
  customAudienceIntegrations,
  customAttributes,
  dcpDatasources,
  dimensions,
  dimensionText,
  dimensionTextPlural,
  duplicationTargetProjects,
  dynamicConditions,
  customAudienceAttributeDefinitions,
  enabledAnalyticsIntegrationExtensions,
  enabledAudienceIntegrations,
  enabledCampaignLevelPersonalizationIntegrations,
  enabledCanvasIntegrations,
  enabledCustomAudienceIntegrations,
  events,
  eventsWithUrls,
  excludeDisabledExperiments,
  experimentationGroups,
  experiments,
  experimentsInSnippet,
  features,
  activeFeatures,
  filteredProjectSettings,
  fullStackLayerExperimentsWrapper,
  goals,
  hasPageTrimming,
  id,
  integrationById,
  integrations,
  isAndroidProject,
  status,
  isClassicCustomProject,
  isClassicTestingEnabledInClient,
  isCustomProject,
  isDeactivationEnabled,
  isRolloutsProject,
  isFlagsProject,
  isFullStackProject,
  isiOSProject,
  isWebProject,
  isMobileProject,
  isOTTProject,
  isPersonalizationEnabledInClient,
  isUnderExperimentLimit,
  isUsingV2Snippet,
  isXCustomProject,
  isXMobileProject,
  jiraIntegrationSettings,
  layerExperiments,
  layers,
  listAttributes,
  metricTemplates,
  name,
  nameWithSdkIfApplicable,
  otherCollaborators,
  permissions,
  personalizationIntegrations,
  platform,
  plugins,
  previewJSFileName,
  primarySDKLanguage,
  project,
  projectActivities,
  catalogs,
  projectIntegrationById,
  projectToken,
  projectResultsAPIToken,
  recommenders,
  reportableAudiencesCount,
  runningExperimentLimit,
  runningLayers, // MVT Test only, remove after test
  tags,
  tagGroups,
  defaultTagGroup,
  undoOnDeactivation,
  userLists,
  views,
  multiUseViews,
  webhooks,
  environmentsAuthorizedAndSupported,
};

export default {
  accountId,
  activeDcpDatasources,
  activeEvents,
  activeGroups,
  attributesForChampagne,
  audiences,
  audienceExperimentCount,
  availableAudiences,
  availableReportingSlots,
  canCreateProject,
  canEditExperiment,
  collaborators,
  createdDate,
  currentProjectActiveEvents,
  currentProjectActiveTags,
  currentProjectActiveViews,
  currentProjectRunningExperiments,
  currentProjectType,
  customAnalyticsIntegrations,
  customAudienceIntegrations,
  customAttributes,
  dcpDatasources,
  dimensions,
  dimensionText,
  dimensionTextPlural,
  duplicationTargetProjects,
  dynamicConditions,
  customAudienceAttributeDefinitions,
  enabledAnalyticsIntegrationExtensions,
  enabledAudienceIntegrations,
  enabledCampaignLevelPersonalizationIntegrations,
  enabledCanvasIntegrations,
  enabledCustomAudienceIntegrations,
  events,
  eventsWithUrls,
  excludeDisabledExperiments,
  experimentationGroups,
  experiments,
  experimentsInSnippet,
  features,
  activeFeatures,
  filteredProjectSettings,
  fullStackLayerExperimentsWrapper,
  goals,
  hasPageTrimming,
  id,
  integrationById,
  integrations,
  isAndroidProject,
  isClassicCustomProject,
  isClassicTestingEnabledInClient,
  isCustomProject,
  isDeactivationEnabled,
  isRolloutsProject,
  isFlagsProject,
  isFullStackProject,
  isiOSProject,
  isWebProject,
  isMobileProject,
  isOTTProject,
  isPersonalizationEnabledInClient,
  isUnderExperimentLimit,
  isUsingV2Snippet,
  isXCustomProject,
  isXMobileProject,
  jiraIntegrationSettings,
  layerExperiments,
  layers,
  listAttributes,
  name,
  nameWithSdkIfApplicable,
  otherCollaborators,
  permissions,
  personalizationIntegrations,
  platform,
  plugins,
  previewJSFileName,
  primarySDKLanguage,
  project,
  projectActivities,
  catalogs,
  projectIntegrationById,
  projectToken,
  projectResultsAPIToken,
  recommenders,
  reportableAudiencesCount,
  runningExperimentLimit,
  runningLayers, // MVT Test only, remove after test
  status,
  tags,
  tagGroups,
  defaultTagGroup,
  undoOnDeactivation,
  userLists,
  views,
  multiUseViews,
  webhooks,
  environmentsAuthorizedAndSupported,
  xCompatibleAudiences,
};

import moduleActions from './actions';
import moduleConstants from './constants';
import moduleFns from './fns';
import moduleGetters from './getters';

export const actions = moduleActions;
export const constants = moduleConstants;
export const fns = moduleFns;
export const getters = moduleGetters;

export default {
  actions,
  constants,
  fns,
  getters,
};

const _ = require('lodash');
const Immutable = require('optly/immutable').default;
const PropTypes = require('prop-types');
const React = require('react');

// modules
const capitalize = require('optly/filters/capitalize');
const LiveVariableEnums = require('optly/modules/entity/live_variable/enums');

// components
const { Button, Input, Table } = require('optimizely-oui');
const Select = require('react_components/select').default;
const VariableValueInput = require('bundles/p13n/components/variable_value_table_legacy/variable_value_input');

const FeatureVariableTableRow = props => {
  const renderErrorBlock = () => {
    const variable = props.variable;
    if (variable.get('api_name') && !props.isVariableKeyValid(variable)) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section={`feature-dialog-variation-key-invalid-error-${props.index}`}>
          Please enter a valid key. Valid keys contain alphanumeric characters,
          hyphens, and underscores, and are limited to 64 characters.
        </div>
      );
    }

    if (variable.get('api_name') && !props.isVariableUnique(variable)) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section={`feature-dialog-variation-unique-key-error-${props.index}`}>
          Key is already in use by another variable in this feature. Please
          choose a unique key.
        </div>
      );
    }

    // display error if variable value is invalid AND the variable value input has been editied
    if (
      !props.isVariableValueValid(variable) &&
      props.hasVariableValueBeenClicked
    ) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section={`feature-dialog-variation-invalid-value-error-${props.index}`}>
          Please specify valid default values for all variables.
        </div>
      );
    }

    return '';
  };

  /**
   * Handle update value from an Input element
   * @param  {Object} event
   * @param  {string} property
   */
  const updateVariableValueFromInput = (event, property) => {
    const valueToSet = event.target.value;
    const variableToUpdate = props.variable.set(property, valueToSet);
    props.updateVariable(props.index, variableToUpdate);
  };

  /**
   * Handle update value from a Select element
   * @param  {string} property
   * @param  {string} valueToSet
   */
  const updateVariableValueFromSelect = (property, valueToSet) => {
    const variableToUpdate = props.variable.set(property, valueToSet);
    props.updateVariable(props.index, variableToUpdate);
  };

  const onUpdateVariable = variable => {
    props.updateVariable(props.index, variable);
  };

  return (
    <Table.TR>
      <Table.TD>
        <Input
          onChange={_.partialRight(updateVariableValueFromInput, 'api_name')}
          testSection={`feature-dialog-variable-key-${props.index}`}
          type="text"
          value={props.variable.get('api_name')}
        />
        {renderErrorBlock()}
      </Table.TD>
      <Table.TD>
        <Select
          isActivatorFullWidth={true}
          isDisabled={
            // Changing variable types is not allowed after it has been created (if it has an ID)
            !!props.variable.get('id')
          }
          onChange={_.partial(updateVariableValueFromSelect, 'type')}
          testSection={`feature-dialog-variable-type-select-${props.index}`}
          value={props.variable.get('type')}>
          {_.map(LiveVariableEnums.LIVE_VARIABLE_TYPES, (value, key) => (
            <Select.Option
              label={capitalize(value)}
              value={value}
              key={value}
              testSection={`feature-dialog-variable-type-${value}-option-${props.index}`}
            />
          ))}
        </Select>
      </Table.TD>
      <Table.TD>
        {
          <VariableValueInput
            onUpdateVariable={onUpdateVariable}
            propertyToUpdate="default_value"
            testSectionPrefix={`feature-dialog-${props.index}`}
            variable={props.variable}
            handleVariableValueClick={props.handleVariableValueClick}
          />
        }
      </Table.TD>
      <Table.TD>
        <Button
          style="plain"
          testSection={`feature-dialog-variable-delete-button-${props.index}`}
          onClick={_.partial(props.removeVariableAtIndex, props.index)}>
          Delete
        </Button>
      </Table.TD>
    </Table.TR>
  );
};

FeatureVariableTableRow.propTypes = {
  handleVariableValueClick: PropTypes.func,
  hasVariableValueBeenClicked: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isVariableKeyValid: PropTypes.func.isRequired,
  isVariableUnique: PropTypes.func.isRequired,
  isVariableValueValid: PropTypes.func.isRequired,
  removeVariableAtIndex: PropTypes.func.isRequired,
  updateVariable: PropTypes.func.isRequired,
  variable: PropTypes.instanceOf(Immutable.Map).isRequired,
};

module.exports = FeatureVariableTableRow;

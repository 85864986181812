import _ from 'lodash';
import sprintf from 'sprintf';

const RESERVED_PROPS = [
  // componentId is not a Vue option, but a reserved option for our components with v-region
  'componentId',
  'components',
  'directives',
  'filters',
  'replace',
  'template',
  'data',
  'computed',
  'methods',
  'created',
  'ready',
  'beforeDestroy',
  'afterDestroy',
];

/**
 * Validates that options passed in when extending Vue components dont override Vue reserved
 * options or our own.
 * @throws
 * @param {Object} options
 */
export default function validateVueOptions(options) {
  const keys = Object.keys(options);
  const invalidKeys = _.intersection(keys, RESERVED_PROPS);

  if (invalidKeys.length > 0) {
    throw new Error(
      sprintf(
        'Invalid component options, %s are reserved Vue options',
        JSON.stringify(keys),
      ),
    );
  }
}

import enums from './enums';
import fns from './fns';
import getters from './getters';

export { enums, fns, getters };

export default {
  enums,
  fns,
  getters,
};

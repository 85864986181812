/**
 * Services layer pure functions for the oauth clients
 */
import _ from 'lodash';

/**
 * Check if the provided value is a valid OAuth application master label
 *
 * @param masterLabel masterLabel to validate
 * @returns {boolean} true if the provided value is valid; false otherwise
 */
export function validateMasterLabel(masterLabel) {
  return _.isString(masterLabel) && masterLabel.length > 0;
}

/**
 * Check if the provided value is a valid OAuth application redirect URI
 *
 * @param redirectUri redirectUri to validate
 * @returns {boolean} true if the provided value is valid; false otherwise
 */
export function validateRedirectUri(redirectUri) {
  if (!_.isString(redirectUri)) {
    return false;
  }

  const hasScheme = redirectUri.indexOf('://') > 0;
  const hasFragment = redirectUri.indexOf('#') !== -1;

  return hasScheme && !hasFragment;
}

export default {
  validateMasterLabel,
  validateRedirectUri,
};

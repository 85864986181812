import React from 'react';
import PropTypes from 'prop-types';

import MetricDropdownItem from './metric_dropdown_item';

const EditMetricAction = ({ onEditClick }) => {
  return (
    <MetricDropdownItem
      clickHandler={onEditClick}
      actionString="Edit"
      testSection="edit"
    />
  );
};

EditMetricAction.propTypes = {
  onEditClick: PropTypes.func.isRequired,
};

const RemoveMetricAction = ({ onRemoveClick }) => {
  return (
    <MetricDropdownItem
      clickHandler={onRemoveClick}
      actionString="Remove"
      testSection="remove"
      isDestructive={true}
    />
  );
};
RemoveMetricAction.propTypes = {
  onRemoveClick: PropTypes.func.isRequired,
};

const MoveToTopAction = ({ pushMetricTopFn }) => {
  return (
    <MetricDropdownItem
      clickHandler={pushMetricTopFn}
      actionString="Move to top"
      testSection="move-top"
    />
  );
};

MoveToTopAction.propTypes = {
  pushMetricTopFn: PropTypes.func.isRequired,
};

const MoveToBottomAction = ({ pushMetricBottomFn }) => {
  return (
    <MetricDropdownItem
      clickHandler={pushMetricBottomFn}
      actionString="Move to bottom"
      testSection="move-bottom"
    />
  );
};

MoveToBottomAction.propTypes = {
  pushMetricBottomFn: PropTypes.func.isRequired,
};

export {
  EditMetricAction,
  RemoveMetricAction,
  MoveToBottomAction,
  MoveToTopAction,
};

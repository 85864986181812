import React, { Suspense, useEffect } from 'react';
import { registerApplication, start, unregisterApplication } from 'single-spa';
import { Spinner } from '@optimizely/axiom';
import pathToRegexp from 'path-to-regexp';

import LoadingOverlay from 'react_components/loading_overlay';
import NavConstants from 'optly/services/navigation';

import MetricsTopbar from '../../components/metrics_topbar';

const MFE_NAME = 'METRICS';
const CONTAINER_ID = 'metrics-hub';

const MetricsHubDashboard = React.forwardRef((props, ref) => {
  useEffect(() => {
    /**
     * Metrics Hub MFE
     * See https://github.com/optimizely/metrics-hub-mfe/blob/main/src/mfe_main.ts
     */
    registerApplication({
      name: MFE_NAME,
      app: () => System.import(MFE_NAME),
      activeWhen: ({ pathname }) => {
        const isActive = pathToRegexp('/v2/projects/:proj_id/metrics').test(
          pathname,
        );
        return isActive;
      },
      customProps: {
        domElementGetter: () => document.getElementById(CONTAINER_ID),
        ...props,
      },
    });

    start();

    return () => {
      unregisterApplication(MFE_NAME);
    };
  }, []);

  return (
    <div
      className="flex flex--1 flex--column"
      data-test-section="metrics-hub-main-content">
      <MetricsTopbar activeTab={NavConstants.MetricsHubTabs.METRICS_TAB} />
      <div
        className="stage__item__content--column position--relative"
        data-test-section="metrics-hub-dashboard">
        <Suspense
          fallback={
            <LoadingOverlay
              isLoading={true}
              data-test-section="loading-metrics-hub"
            />
          }>
          <div
            id={CONTAINER_ID}
            ref={ref}
            style={{
              width: '100%',
              height: '100%',
            }}>
            <Spinner hasOverlay={true} />
          </div>
        </Suspense>
      </div>
    </div>
  );
});

MetricsHubDashboard.displayName = 'MetricsHubDashboard';

export default MetricsHubDashboard;

import PropTypes from 'prop-types';
import React from 'react';

import { Dropdown } from 'optimizely-oui';

import Router from 'core/router';

class FeaturesSelectLanguageDropdown extends React.Component {
  static propTypes = {
    languageOptions: PropTypes.array.isRequired,
    languageOptionsNotAvailable: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  state = {
    languageBeingHovered: null,
  };

  handleUnavailableClick = link => {
    Router.windowOpenInNewTab(link);
  };

  onMouseEnter = language => {
    this.setState({ languageBeingHovered: language.label });
  };

  onMouseLeave = () => {
    this.setState({ languageBeingHovered: null });
  };

  render = () => {
    const {
      languageOptions,
      languageOptionsNotAvailable,
      onChange,
      value,
    } = this.props;
    const { languageBeingHovered } = this.state;
    const selectedLanguage = languageOptions.find(e => e.value === value);
    const buttonText = selectedLanguage.label;
    return (
      <Dropdown
        buttonContent={buttonText}
        arrowIcon="down"
        style="outline"
        testSection="select-language-dropdown-activator">
        <Dropdown.Contents>
          <Dropdown.ListItem role="separator" ignoreToggle={true}>
            In-app quickstarts
          </Dropdown.ListItem>
          {languageOptions.map(language => {
            return (
              <Dropdown.ListItem role="menuitem" key={language.value}>
                <Dropdown.BlockLink
                  testSection="available-languages-item"
                  value={language.value}
                  hasFauxFocus={false}
                  onClick={() => onChange(language.value)}>
                  <Dropdown.BlockLinkText text={language.label} />
                </Dropdown.BlockLink>
              </Dropdown.ListItem>
            );
          })}
          <Dropdown.ListItem
            role="separator"
            ignoreToggle={true}
            key="coming-soon-separator">
            Coming soon...
          </Dropdown.ListItem>
          {languageOptionsNotAvailable.map(language => {
            return (
              <Dropdown.ListItem role="menuitem" key={language.value}>
                <Dropdown.BlockLink
                  testSection="unavailable-languages-item"
                  value={language.value}
                  onClick={() => this.handleUnavailableClick(language.link)}
                  onMouseEnter={() => this.onMouseEnter(language)}
                  onMouseLeave={() => this.onMouseLeave(language)}
                  hasFauxFocus={false}>
                  <Dropdown.BlockLinkText
                    text={
                      languageBeingHovered === language.label
                        ? 'View in Dev Docs'
                        : language.label
                    }
                    hasExternalIcon={true}
                  />
                </Dropdown.BlockLink>
              </Dropdown.ListItem>
            );
          })}
        </Dropdown.Contents>
      </Dropdown>
    );
  };
}

export default FeaturesSelectLanguageDropdown;

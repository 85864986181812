import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

/**
 * loadingStore
 * Responsible for the following state management:
 * TODO: fill in
 */
export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      loading: {},
      entitiesLoading: {},
    });
  },

  initialize() {
    this.on(actionTypes.LOADING_START, startLoading);
    this.on(actionTypes.LOADING_FINISH, stopLoading);
    this.on(actionTypes.ENTITY_LOADING_START, startEntityLoading);
    this.on(actionTypes.ENTITY_LOADING_FINISH, stopEntityLoading);
    this.on(actionTypes.API_ENTITY_PERSIST_START, startEntityLoading);
    this.on(actionTypes.API_ENTITY_PERSIST_SUCCESS, stopEntityLoading);
    this.on(actionTypes.API_ENTITY_PERSIST_FAIL, stopEntityLoading);
    this.on(actionTypes.CLEAR_ALL_ENTITY_LOADING, entityLoadingStopped);
  },
});

/**
 * Clears all loading keys for an entity
 */
function entityLoadingStopped(state, payload) {
  return state.setIn(['entitiesLoading', payload.entity], toImmutable({}));
}

/**
 * Set loading keys for a given entity
 */
function startEntityLoading(state, payload) {
  if (payload.data && payload.data.id) {
    const entity = payload.entity;
    const id = payload.data.id;

    // only handle case of single entity being saved
    // since we must have ID
    if (!state.getIn(['entitiesLoading', entity])) {
      state = state.setIn(['entitiesLoading', entity], toImmutable({}));
    }

    state = state.setIn(['entitiesLoading', entity, id], true);
  }
  return state;
}

/**
 * @param {object} payload
 */
function stopEntityLoading(state, payload) {
  if (payload.data && payload.data.id) {
    const entity = payload.entity;
    const id = payload.data.id;
    state = state.deleteIn(['entitiesLoading', entity, id]);
  }
  return state;
}

/**
 * Makes some section id as loading
 * @param {object} payload
 */
function startLoading(state, payload) {
  return state.setIn(['loading', payload.id], true);
}

/**
 * Makes some section id as done loading
 * @param {object} payload
 */
function stopLoading(state, payload) {
  return state.setIn(['loading', payload.id], false);
}

import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import enums from '../enums';
import actionTypes from '../action_types';

/**
 * View editor store
 * Responsible for state management for view editor UI
 */
export default Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      currentlyEditingView: null,
      iframeOverlayShown: true,
      mainPanelState: enums.mainPanelState.IFRAME,
      selectedElementInfo: [],
    });
  },

  initialize() {
    this.on(actionTypes.P13N_VIEW_EDITOR_SET_EDITING_VIEW, setEditingView);
    this.on(
      actionTypes.P13N_VIEW_EDITOR_SET_IFRAME_OVERLAY_SHOWN,
      setIframeOverlayShown,
    );
  },
});

/**
 * Set the currently editing view
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Object} payload.view the view properties to set for editing
 */
function setEditingView(state, payload) {
  return state.set('currentlyEditingView', toImmutable(payload.view));
}

/**
 * Set visibility of the iframe overlay
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {boolean} payload.shown Do we show the overlay on the iframe
 */
function setIframeOverlayShown(state, payload) {
  return state.set('iframeOverlayShown', payload.shown);
}

import keyMirror from 'optly/utils/key_mirror';

export const actionTypes = keyMirror({
  SORT_TABLE: null,
  TOGGLE_SORT_TABLE_FIELD: null,
  RESET_TABLE_SORTING: null,
});

export default {
  ...actionTypes,
};

/*
 * WARNING: this file is currently being used from the preview_ui bundle.
 * Currently, this means we SHOULD NOT use any ES7+ syntax/functions and need to be very careful
 * about the dependencies referenced in this module.
 */

import flux from 'core/flux';
import isString from 'lodash/isString';

import actionTypes from './action_types';

/**
 * Set a filter for the given tableId
 *
 * @param {string} tableId
 * @param {Object|string} filter This can be a string or an object consisting of more complicated filtering
 */
export function setFilter(tableId, filter) {
  if (isString(filter)) {
    filter = {
      string: filter,
    };
  }
  // Doing a batch here because it's possible that a dispatch which updates a getter that's being observed
  // will call this setFilter function, which will result in simultaneous dispatches, which will throw an error.
  // This makes sure that any current dispatch is done before dispatching again.
  flux.batch(() => {
    flux.dispatch(actionTypes.FILTERABLE_TABLE_SET_FILTER, {
      filter,
      tableId,
    });
  });
}

/**
 * Reset the filter settings for the specified table
 *
 * @param {string} tableId
 */
export function resetFilter(tableId) {
  flux.dispatch(actionTypes.FILTERABLE_TABLE_RESET_FILTER, {
    tableId,
  });
}

/**
 * Reset the filter settings for the specified table
 *
 * @param {string} tableId
 * @param {Object|string} filter
 */
export function setFilterDefault(tableId, filter) {
  if (isString(filter)) {
    filter = {
      string: filter,
    };
  }
  flux.dispatch(actionTypes.FILTERABLE_TABLE_SET_FILTER_DEFAULT, {
    filter,
    tableId,
  });
}

export default {
  setFilter,
  resetFilter,
  setFilterDefault,
};

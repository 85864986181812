const flux = require('core/flux');
const PropTypes = require('prop-types');
const React = require('react');
const Immutable = require('optly/immutable').default;
const { Input } = require('optimizely-oui');
const Select = require('react_components/select').default;

const capitalize = require('optly/filters/capitalize');

const {
  ChangeStatuses,
} = require('optly/modules/entity/layer_experiment/enums').default;

const EditorActions = require('bundles/p13n/modules/editor/actions');
const EditorFns = require('bundles/p13n/modules/editor/fns').default;

const RevertButton = require('../revert_button');

const styleValues = [
  'none',
  'hidden',
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset',
  'initial',
  'inherit',
];

class styleAndWidthInputs extends React.Component {
  static propTypes = {
    changeStatusMap: PropTypes.instanceOf(Immutable.Map),

    onStyleChange: PropTypes.func,
    onWidthChange: PropTypes.func,

    styleChangeStatus: PropTypes.string,
    styleValue: PropTypes.string,

    widthChangeStatus: PropTypes.string,
    widthValue: PropTypes.string,
  };

  callWidthChangeHandler = event => {
    this.props.onWidthChange(event.target.value);
  };

  blurChangeHandler = event => {
    // If losing focus and the width value's empty, treat as a revert
    if (this.props.widthValue === '') {
      this.revertWidth();
    }
  };

  revertWidth = () => {
    EditorActions.revertCSSProperty('border-width');

    // The following crudely duplicates Editor.getters.cssValueFromChangeOrElement.
    // The above action mutates state but Nuclear does not detect that getter caches
    // should be invalidated when a value changes from '' -> null, as happens above
    // TODO(jordan) Look into upgrading the version of Nuclear to fix this bug
    const changeValue = flux.evaluate([
      'p13n/editor',
      'currentlyEditingChange',
      'css',
      'border-width',
    ]);
    const computedStyle = flux
      .evaluate([
        'p13n/editor',
        'currentlyEditingChange',
        'elementInfo',
        0,
        'computedStyle',
      ])
      .toJS();
    const elementValue = computedStyle.getPropertyValue('border-width');
    let changeOrElementValue;
    if (changeValue || changeValue === '') {
      changeOrElementValue = changeValue;
    } else {
      changeOrElementValue = elementValue;
    }

    // If we just reverted to 0px, reset style to None
    if (changeOrElementValue === '0px') {
      EditorActions.revertCSSProperty('border-style');
    }

    EditorActions.applyCurrentlyEditingChange();
  };

  render() {
    if (this.props.widthValue === null) {
      return null;
    }

    const statusClasses = {};
    ['border-style', 'border-color', 'border-width'].forEach(property => {
      const changeStatus = this.props.changeStatusMap.getIn(['css', property]);
      statusClasses[property] = EditorFns.coalesceStatusesIntoClass(
        changeStatus,
      );
    });

    return (
      <div className="flex flex-align--center">
        <div className="flex--1">
          <div
            className={`flex flex-align--center ${statusClasses['border-width']}`}>
            <label className="lego-label">Width</label>
            {this.props.changeStatusMap.getIn(['css', 'border-width']) ===
            ChangeStatuses.DIRTY ? (
              <RevertButton property="border-width" action={this.revertWidth} />
            ) : null}
          </div>
          <Input
            type="text"
            value={this.props.widthValue}
            onChange={this.callWidthChangeHandler}
            onBlur={this.blurChangeHandler}
            testSection="border-width-input"
          />
        </div>
        <div className="flex--1 push-double--left">
          <div
            className={`flex flex-align--center ${statusClasses['border-style']}`}>
            <label className="lego-label">Style</label>
            {this.props.changeStatusMap.getIn(['css', 'border-style']) ===
            ChangeStatuses.DIRTY ? (
              <RevertButton property="border-style" />
            ) : null}
          </div>
          <Select
            value={this.props.styleValue}
            isActivatorFullWidth={true}
            onChange={this.props.onStyleChange}
            isDisabled={this.props.widthValue === '0px'}
            testSection="border-style-select">
            {styleValues.map(value => (
              <Select.Option
                value={value}
                key={value}
                label={capitalize(value)}
                testSection={`border-style-option-${value}`}
              />
            ))}
          </Select>
        </div>
      </div>
    );
  }
}

module.exports = styleAndWidthInputs;

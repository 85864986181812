/**
 * Entity Definition for Layer Summary
 */

export default {
  entity: 'layer_summaries',

  isRelationshipEntity: true,

  parents: [
    {
      entity: 'projects',
      key: 'project_id',
    },
    {
      entity: 'layers/summaries',
    },
  ],
};

/**
 * Entity Definition for Recommender.
 */

export const entity = 'recommenders';

export const parent = {
  entity: 'recommender_services',
  key: 'recommender_service_id',
};

export default {
  entity,
  parent,
};

/**
 * NOTE(nate): event tracking in this module does not send data to marketo, per marketing team
 */
import config from 'atomic-config';

import flux from 'core/flux';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import ExperimentActions from 'optly/modules/entity/experiment/actions';
import router from 'optly/services/url_helper_legacy';

import historyUtil from 'optly/utils/history';
import events from 'optly/services/events';
import { ExperimentStatusType } from 'optly/utils/enums';

import ui from 'core/ui';
import ProjectActivityActions from 'optly/modules/entity/project_activity/actions';

import constants from './constants';
import getters from './getters';
import { actionTypes } from './action_types';

/**
 * Helper method to evaluate the current dashboard url based on the store state
 * and do a history.pushState() with the new url
 */
export function pushUrl(title) {
  const url = this.getDashboardUrl();
  historyUtil.pushState(url, title);
}

/**
 * Helper method to evaluate the current dashboard url based on the store state
 * and do a history.replaceState() with the new url
 */
export function replaceUrl(title) {
  const url = this.getDashboardUrl();
  historyUtil.replaceState(url, title);
}

/**
 * Action to switch the main tab in the dashboard view
 * @param {string} tabId
 */
export function switchMainTab(tabId) {
  flux.dispatch(actionTypes.DASHBOARD_SWITCH_MAIN_TAB, {
    tabId,
  });
  events.track('dashboard2', 'view-tab', tabId);
}

/**
 * Action to switch the subtab in the Settings tab
 * @param {string} tabId
 */
export function switchSubTab(tabId) {
  flux.dispatch(actionTypes.DASHBOARD_SWITCH_SUB_TAB, {
    tabId,
  });
  events.track('dashboard2', 'view-subtab', tabId);
}

/**
 * Logic for toggling an experiment in a list, does select/deselect logic
 * based on whether the item is selected, how many items are selected and
 * if the shift key was used
 * @param {string} entity
 * @param {object} instance
 * @param {boolean} shiftKey
 */
export function clickItem(entity, instance, shiftKey) {
  const isSelected = flux.evaluate(getters.isSelected(entity, instance.id));

  if (isSelected) {
    if (shiftKey) {
      this.deselectItem(entity, instance);
    } else {
      this.selectItem(entity, instance);
    }
  } else if (shiftKey) {
    this.selectItem(entity, instance, {
      multiple: true,
    });
  } else {
    this.selectItem(entity, instance);
  }
}

/**
 * When a user selects an item in the data table
 * @param {string} entity
 * @param {object} instance
 * @param {object} opts
 */
export function selectItem(entity, instance, opts) {
  opts = opts || {};
  flux.dispatch(actionTypes.DASHBOARD_SELECT_TABLE_ITEM, {
    category: entity,
    multiple: opts.multiple,
    itemId: instance.id,
  });
  events.track('dashboard2', 'select-item', entity);
  if (opts.multiple) {
    events.track('dashboard2', 'multiselect-item', entity);
  }
}

/**
 * When a user deselects an item in the data table
 * @param {string} entity
 * @param {object} instance
 */
export function deselectItem(entity, instance) {
  flux.dispatch(actionTypes.DASHBOARD_DESELECT_TABLE_ITEM, {
    category: entity,
    itemId: instance.id,
  });
}

/**
 * Fetch experiments with the latest filter set by the user
 */
export function fetchExperiments(shouldFetchResults) {
  const apiFilters = flux.evaluate(getters.apiFilters('experiments'));
  const def = ExperimentActions.fetchAll(apiFilters);

  if (shouldFetchResults) {
    def.then(experiments => {
      const expsToFetch = experiments.filter(
        exp => exp.status !== ExperimentStatusType.ARCHIVED,
      );

      if (config.get('env.USE_REPER_RESULTS_BATCH')) {
        // Fetch results from reper batch endpoint.
        // This is in place temporarily to roll out the
        // usage of this endpoint via an Optimizely experiment.
        ExperimentActions.fetchResults(expsToFetch);
      } else {
        // Fetch results data via Mongo backend endpoint.
        ExperimentActions.fetchVisitorCounts(expsToFetch);
      }
    });
  }

  return def;
}

/**
 * Sets the filter values for the experiment data table
 * @param {string} status to filter experiment
 */
export function filterExperimentsByStatus(statusString) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TABLE_FILTERS, {
    category: 'experiments',
    filters: {
      statusString,
    },
  });
  events.track('dashboard2', 'filter-experiments-status', statusString);
}

/**
 * Sets the filter values for the experiment data table
 * @param {string} string to filter experiment name/description
 */
export function filterExperimentsByString(string) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TABLE_FILTERS, {
    category: 'experiments',
    filters: {
      string,
    },
  });
  events.track('dashboard2', 'filter-experiments-string');
}

/**
 * Resets all table filters for the experiments tab
 */
export function resetExperimentFilters() {
  flux.dispatch(actionTypes.DASHBOARD_RESET_TABLE_FILTERS, {
    category: 'experiments',
  });
}

/**
 * Sets the filter values for the collaborators data table
 * @param {string} string to filter audience name/description
 */
export function filterCollaboratorsByString(string) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TABLE_FILTERS, {
    category: 'collaborators',
    filters: {
      string,
    },
  });
}

/**
 * Resets all table filters for the collaborators tab
 */
export function resetCollaboratorFilters() {
  flux.dispatch(actionTypes.DASHBOARD_RESET_TABLE_FILTERS, {
    category: 'collaborators',
  });
}

/**
 * Sets the filter values for the audience data table
 * @param {string} string to filter audience name/description
 */
export function filterAudiencesByString(string) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TABLE_FILTERS, {
    category: 'audiences',
    filters: {
      string,
    },
  });
  events.track('dashboard2', 'filter-audiences-string');
}

/**
 * Sets the filter values for the audiences data table
 * @param {string} status to filter audiences
 */
export function filterAudiencesByStatus(statusString) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TABLE_FILTERS, {
    category: 'audiences',
    filters: {
      statusString,
      archived: statusString === 'archived',
    },
  });
  events.track('dashboard2', 'filter-audiences-status', statusString);
}

/**
 * Resets all table filters for the audiences tab
 */
export function resetAudienceFilters() {
  flux.dispatch(actionTypes.DASHBOARD_RESET_TABLE_FILTERS, {
    category: 'audiences',
  });
}

/**
 * Sets the filter values for the goals data table
 * @param {string} status to filter goals
 */
export function filterGoalsByStatus(statusString) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TABLE_FILTERS, {
    category: 'goals',
    filters: {
      statusString,
      archived: statusString === 'archived',
    },
  });
  events.track('dashboard2', 'filter-goals-status', statusString);
}

/**
 * Sets the filter values for the goals data table
 * @param {string} string to filter goal name/description
 */
export function filterGoalsByString(string) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TABLE_FILTERS, {
    category: 'goals',
    filters: {
      string,
    },
  });
  events.track('dashboard2', 'filter-goals-string');
}

/**
 * Resets all table filters for the goals tab
 */
export function resetGoalFilters() {
  flux.dispatch(actionTypes.DASHBOARD_RESET_TABLE_FILTERS, {
    category: 'goals',
  });
}

/**
 * Sets the filter values for the goals data table
 * @param {string} string to filter goal name/description
 */
export function filterUserListsByString(string) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TABLE_FILTERS, {
    category: 'user-lists',
    filters: {
      string,
    },
  });
  events.track('dashboard2', 'filter-user-lists-string');
}

/**
 * Resets all table filters for the user list tab
 */
export function resetUserListFilters() {
  flux.dispatch(actionTypes.DASHBOARD_RESET_TABLE_FILTERS, {
    category: 'user-lists',
  });
}

/**
 * Resets all table filters for the datasources tab
 */
export function resetDatasourceFilters() {
  flux.dispatch(actionTypes.DASHBOARD_RESET_TABLE_FILTERS, {
    category: 'datasources',
  });
}

/**
 * Sets the filter values for the datasources table
 * @param {string} string to filter by
 */
export function filterDatasourcesByString(string) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TABLE_FILTERS, {
    category: 'datasources',
    filters: {
      string,
    },
  });
  events.track('dashboard2', 'filter-datasources-string');
}

/**
 * Resets all table filters for the datasources tab
 */
export function resetDatasourcesFilters() {
  flux.dispatch(actionTypes.DASHBOARD_RESET_TABLE_FILTERS, {
    category: 'datasources',
  });
}

/**
 * Sets the filter values for the audience data table
 * @param {string} string to filter audience name/description
 */
export function filterDimensionsByString(string) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TABLE_FILTERS, {
    category: 'dimensions',
    filters: {
      string,
    },
  });
  events.track('dashboard2', 'filter-dimensions-string');
}

/**
 * Resets all table filters for the dimensions tab
 */
export function resetDimensionFilters() {
  flux.dispatch(actionTypes.DASHBOARD_RESET_TABLE_FILTERS, {
    category: 'dimensions',
  });
}

/**
 * When a user requests to load more activity in the log
 * @param {Object} filters
 */
export function getActivities(filters) {
  const loadingKey = 'dashboard.change-history';
  ui.loadingStart(loadingKey);
  ProjectActivityActions.fetchPage(filters)
    .then(results => {
      flux.dispatch(actionTypes.DASHBOARD_SET_ACTIVITY_PAGE_RESULTS_LENGTH, {
        length: results.length,
      });
    })
    .then(() => {
      ui.loadingStop(loadingKey);
    });
}

/**
 * When a user selects a filter for the dashboard activity list
 * @param {String} key
 * @param {String|Number|null} value
 */
export function selectActivityFilter(key, value) {
  flux.dispatch(actionTypes.DASHBOARD_SELECT_ACTIVITY_FILTER, {
    filterKey: key,
    filterValue: value,
  });
  events.track('dashboard2', 'filter-activities');
}

/**
 * Change the filters for the next page of results
 * @param {String} key
 * @param {String|Number|null} value
 */
export function setActivityNextPageFilters(offset) {
  flux.dispatch(actionTypes.DASHBOARD_SET_NEXT_PAGE_ACTIVITY_FILTER, {
    offset,
  });
}

export function getDashboardUrl() {
  const mainTab = flux.evaluate(getters.activeMainTab);
  const subTab = flux.evaluate(getters.activeSubTab);
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const queryParams = {};
  let selectedItemId;

  // depending on whether the maintab is projects use the mainTab or subTab as the second part
  // in the url.  This is because everything is actually a child of projects, main and sub tabs
  const subPart = mainTab === 'projects' ? subTab : mainTab;

  // add the selected table item id to the uri
  // ex: /projects/123/experiments/456 <- that
  const selectedItemsKey = constants.SUB_PART_TO_ENTITY[subPart];
  if (selectedItemsKey) {
    const ids = flux.evaluate(getters.selectedIds(selectedItemsKey));
    if (ids.size === 1) {
      // add the selected item to the URI
      selectedItemId = ids.get(0);
    }
  }

  let filters;
  let string;
  let statusString;

  switch (subPart) {
    case 'experiments':
      filters = flux.evaluate(getters.tableFilters('experiments'));
      string = filters.get('string');
      statusString = filters.get('statusString');
      if (string) {
        queryParams.search = string;
      }
      if (statusString && statusString !== constants.DEFAULT_STATUS_STRING) {
        queryParams.status = statusString;
      }
      break;
    case 'audiences':
      filters = flux.evaluate(getters.tableFilters('audiences'));
      string = filters.get('string');
      statusString = filters.get('statusString');
      if (string) {
        queryParams.search = string;
      }
      if (statusString && statusString !== constants.DEFAULT_STATUS_STRING) {
        queryParams.status = statusString;
      }
      break;
    case 'dimensions':
      filters = flux.evaluate(getters.tableFilters('dimensions'));
      string = filters.get('string');
      if (string) {
        queryParams.search = string;
      }
      break;
    case 'collaborators':
      filters = flux.evaluate(getters.tableFilters('collaborators'));
      string = filters.get('string');
      if (string) {
        queryParams.search = string;
      }
      break;
    case 'goals':
      filters = flux.evaluate(getters.tableFilters('goals'));
      string = filters.get('string');
      if (string) {
        queryParams.search = string;
      }
      break;
    case 'user-lists':
      filters = flux.evaluate(getters.tableFilters('user-lists'));
      string = filters.get('string');
      if (string) {
        queryParams.search = string;
      }
      break;
    case 'datasources':
      filters = flux.evaluate(getters.tableFilters('datasources'));
      string = filters.get('string');
      if (string) {
        queryParams.search = string;
      }
      break;
    case 'change-history':
      filters = flux.evaluate(getters.activityFilters);
      const experiment_id = filters.get('experiment_id');
      if (experiment_id) {
        queryParams.expId = experiment_id;
      }
      break;
    default:
      break;
  }
  return router.dashboardTab(projectId, subPart, selectedItemId, queryParams);
}

/**
 * Sets the filter values for the integrations data table
 * @param {string} string to filter integration category
 */
export function filterIntegrationsByCategory(string) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TABLE_FILTERS, {
    category: 'integrations',
    filters: {
      category: string,
    },
  });
}

/**
 * Sets the filter values for the integrations data table
 * @param {string} string to filter integration name/category
 */
export function filterIntegrationsByString(string) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TABLE_FILTERS, {
    category: 'integrations',
    filters: {
      string,
    },
  });
}

/**
 * Resets all table filters for the integrations tab
 */
export function resetIntegrationFilters() {
  flux.dispatch(actionTypes.DASHBOARD_RESET_TABLE_FILTERS, {
    category: 'integrations',
  });
}

/**
 * Resets the activity filter defaults
 * Currently only used for testing
 */
export function resetActivityDefaults(id) {
  flux.dispatch(actionTypes.CHANGE_CURRENT_PROJECT_ID, {
    id,
  });
}

/**
 * Sets the activity filter settings.
 * Currently only used for testing
 */
export function setActivityParameters(limit) {
  flux.dispatch(actionTypes.DASHBOARD_SET_TEST_PARAMETERS, {
    limit,
  });
}

/**
 * Sets the labs data entries
 * Currently only used for testing
 */
export function setLabsData(data) {
  flux.dispatch(actionTypes.DASHBOARD_SET_LABS_DATA, {
    data,
  });
}

export function setColumnsVisibility(tab, projectId, visibleColumns) {
  flux.dispatch(actionTypes.DASHBOARD_SET_COLUMNS_VISIBILITY, {
    data: {
      tab,
      projectId,
      visibleColumns,
    },
  });
}

export function setActiveTab(projectId, tab) {
  flux.dispatch(actionTypes.DASHBOARD_SET_ACTIVE_TAB, {
    data: {
      projectId,
      tab,
    },
  });
}

export default {
  clickItem,
  deselectItem,
  fetchExperiments,
  filterAudiencesByStatus,
  filterAudiencesByString,
  filterCollaboratorsByString,
  filterDatasourcesByString,
  filterDimensionsByString,
  filterExperimentsByStatus,
  filterExperimentsByString,
  filterGoalsByStatus,
  filterGoalsByString,
  filterIntegrationsByCategory,
  filterIntegrationsByString,
  filterUserListsByString,
  getActivities,
  getDashboardUrl,
  pushUrl,
  replaceUrl,
  resetActivityDefaults,
  resetAudienceFilters,
  resetCollaboratorFilters,
  resetDatasourceFilters,
  resetDatasourcesFilters,
  resetDimensionFilters,
  resetExperimentFilters,
  resetGoalFilters,
  resetIntegrationFilters,
  resetUserListFilters,
  selectItem,
  selectActivityFilter,
  setActivityNextPageFilters,
  setActivityParameters,
  setColumnsVisibility,
  setLabsData,
  switchMainTab,
  switchSubTab,
  setActiveTab,
};

/* eslint-disable import/no-cycle */
import $ from 'jquery';
import htmlSanitizer from 'sanitizer';
import { track } from '@optimizely/segment-js/dist/api';

import cloneDeep from 'optly/clone_deep';
import flux from 'core/flux';
import { toImmutable } from 'optly/immutable';
import Router from 'core/router';
import ui from 'core/ui';
import vueHelpers from 'optly/utils/vue_helpers';
import guid from 'optly/utils/guid';
import HistoryUtils from 'optly/utils/history';
import capitalize from 'optly/filters/capitalize';
import UrlHelperV2 from 'optly/services/url_helper';

import AdminAccountGetters from 'optly/modules/admin_account/getters';
import AudienceActions from 'optly/modules/entity/audience/actions';
import CurrentlyEditingExperimentActions from 'bundles/p13n/modules/currently_editing_experiment/actions';
import CurrentlyEditingExperimentEnums from 'bundles/p13n/modules/currently_editing_experiment/enums';
import CurrentlyEditingExperimentFns from 'bundles/p13n/modules/currently_editing_experiment/fns';
import CurrentLayerActions from 'bundles/p13n/modules/current_layer/actions';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import EnvironmentActions from 'optly/modules/entity/environment/actions';
import EnvironmentGetters from 'optly/modules/entity/environment/getters';
import ExperimentationGroupActions from 'optly/modules/entity/experimentation_group/actions';
import ExperimentationGroupConstants from 'optly/modules/entity/experimentation_group/constants';
import ExperimentationGroupEnums from 'optly/modules/entity/experimentation_group/enums';
import {
  actions as UpgradeActions,
  constants as UpgradeConstants,
} from 'optly/modules/upgrade';
import {
  actions as EventActions,
  enums as EventModuleEnums,
  fns as EventModuleFns,
} from 'optly/modules/entity/event';
import FilterableTableActions from 'optly/modules/filterable_table/actions';
import LayerActions from 'optly/modules/entity/layer/actions';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerGetters from 'optly/modules/entity/layer/getters';
import LayerExperiment from 'optly/modules/entity/layer_experiment';
import LiveVariableActions from 'optly/modules/entity/live_variable/actions';
import LiveVariableFns from 'optly/modules/entity/live_variable/fns';
import LiveVariableUsageActions from 'optly/modules/entity/live_variable_usage/actions';
import LiveCommitTagActions from 'optly/modules/entity/live_commit_tag/actions';
import LiveCommitTagGetters from 'optly/modules/entity/live_commit_tag/getters';
import { LOADING_FEATURE_FLAGS } from 'optly/modules/entity/feature_flag/constants';
import LoadingGetters from 'core/modules/loading/getters';
import ManagerFullStackSectionModuleConstants from 'bundles/p13n/sections/manager_fullstack/section_module/constants';
import MetricsManagerGetters from 'bundles/p13n/modules/metrics_manager/getters';
import PermissionsFns from 'optly/modules/permissions/fns';
import tr from 'optly/translate';

import CreateExperimentDialog from 'bundles/p13n/sections/manager_fullstack/components/dialogs/create_experiment_dialog';
import { FeatureExperimentDialog } from 'bundles/p13n/sections/oasis_experiment_manager/components/experiment_dialog';
import EventConfigDialog from 'bundles/p13n/sections/oasis_implementation/pages/events_dashboard/components/event_config';
import LiveVariableDialogComponent from 'bundles/p13n/sections/live_variables/components/live_variable_dialog';
import WhitelistDialog from 'bundles/p13n/sections/oasis_experiment_manager/components/whitelist_dialog';

import { TrafficAllocationPolicyTypes } from 'bundles/p13n/components/traffic_allocation/traffic_allocation_policy/constants';

import fns from './fns';
import getters from './getters';
import constants from './constants';

/**
 * Archives the given experiment
 * @param  {Immutable.Map} experiment
 * @return {Promise}
 */
export function archiveExperiment(experiment) {
  const deferred = $.Deferred();
  const environmentsAuthorizedAndSupported = flux.evaluate(
    CurrentProjectGetters.environmentsAuthorizedAndSupported,
  );
  let isExperimentLive;
  if (environmentsAuthorizedAndSupported) {
    isExperimentLive = LayerExperiment.fns.isRunningInAnyEnvironment(
      experiment,
    );
  } else {
    isExperimentLive =
      flux.evaluate(getters.isExperimentLive(experiment.get('id'))) &&
      experiment.get('status') === LayerExperiment.enums.status.ACTIVE;
  }

  const isMAB = LayerExperiment.fns.isMultiArmedBandit(experiment);

  if (isExperimentLive) {
    const experimentName = experiment.get('name') || experiment.get('key');
    const campaignType = isMAB ? 'optimization' : 'experiment';
    ui.confirm({
      title: tr('Archive {0}', capitalize(campaignType)),
      message: tr(
        'The {0} <b>{1}</b> is currently running. Archiving a running {2} will pause it. Are you sure you want to archive it?',
        campaignType,
        experimentName,
        campaignType,
      ),
      isWarning: true,
      confirmText: tr('Archive {0}', capitalize(campaignType)),
    }).then(() =>
      LayerExperiment.actions
        .archive(experiment.toJS())
        .then(savedExperiment => {
          deferred.resolve(savedExperiment);
        }),
    );
    return deferred.promise();
  }

  return LayerExperiment.actions.archive(experiment.toJS());
}

/**
 * Updates the group associated with a given experiment.
 * @param   {Object} experiment
 * @param   {String} groupId
 * @return  {Promise}
 */
export function updateExperimentGroup(experiment, groupId) {
  return ExperimentationGroupActions.updateExperimentGroup(
    experiment.id,
    experiment.percentage_included,
    ExperimentationGroupEnums.EXPERIMENTATION_GROUP_TYPES.LAYER_EXPERIMENT,
    groupId,
  );
}

/**
 * Launches the given experiment
 *
 * 1/17/17: This functionality is only available to customers with LAUNCH_EXPERIMENT feature
 * @param  {Object} experiment
 * @return {Promise}
 */
export function launchExperiment(experiment) {
  return LayerExperiment.actions.launch(experiment);
}

/**
 * Unarchive the given experiment
 * @param  {Object} experiment
 * @return {Promise}
 */
export function unarchiveExperiment(experiment) {
  return LayerExperiment.actions
    .unarchive(experiment)
    .then(unarchivedExpData => {
      const existingLiveTag = flux.evaluate(
        LiveCommitTagGetters.liveCommitTagByLayerId(experiment.layer_id),
      );
      if (existingLiveTag) {
        return LiveCommitTagActions.activateTag({
          layer_id: experiment.layer_id,
        }).then(() => unarchivedExpData);
      }
      return unarchivedExpData;
    });
}

/**
 * Return copy of experiment with status set to "archived".
 * @param  {Object} original experiment
 * @return {Object} unarchived experiment
 */
export function getUnarchivedExperimentCopy(experiment) {
  const unarchivedExperiment = cloneDeep(experiment);
  unarchivedExperiment.status = LayerExperiment.enums.status.PAUSED;
  unarchivedExperiment.is_launched = false;
  return unarchivedExperiment;
}

/**
 * @name createOrUpdateExperimentViaOasisDialog
 * @description Used in Full Stack and Legacy X Mobile projects that use the AB / Feature Test Dialogs for experiment creation / update
 * - Ensures sane defaults for data
 * - Creates or updates Layer
 * - Creates or updates LayerExperiment (with featureFlagId if provided)
 * - Updates group (or sets to none)
 *
 * @param {Object} experiment
 * @param {Object} options (optional)
 * @param {Number} options.groupId
 * @param {Array} options.metrics
 * @param {String} options.trafficAllocationPolicy
 *
 * @return {Promise} resolves with newly created or updated LayerExperiment
 */
export function createOrUpdateExperimentViaOasisDialog(
  experiment,
  {
    groupId = null,
    metrics = [],
    trafficAllocationPolicy = TrafficAllocationPolicyTypes.MANUAL,
  } = {},
) {
  return new Promise((resolve, reject) => {
    const experimentDataToSave = {
      ...experiment,
      allocation_policy: trafficAllocationPolicy,
      audience_conditions: experiment.audience_conditions || null,
      audience_ids: experiment.audience_ids || [],
      feature_flag_id: experiment.feature_flag_id || null,
    };

    // TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
    if (!experimentDataToSave.audience_conditions_json) {
      delete experimentDataToSave.audience_conditions_json;
    }

    let layerData = {
      metrics,
      name: experimentDataToSave.key,
      policy:
        experimentDataToSave.layer_policy ||
        LayerEnums.policy.SINGLE_EXPERIMENT,
      type: experimentDataToSave.layer_type || LayerEnums.type.AB,
    };

    // Update the existing Layer and LayerExperiment if an ID is provided
    if (experimentDataToSave.id) {
      const experimentLayer = flux.evaluateToJS(
        LayerGetters.byId(experimentDataToSave.layer_id),
      );
      layerData = {
        ...layerData,
        earliest: (experimentLayer && experimentLayer.earliest) || null,
        id: experimentDataToSave.layer_id,
      };
    } else {
      layerData = {
        ...layerData,
        experiment_ids: [],
        holdback: 0,
        project_id: experimentDataToSave.project_id,
        status: LayerEnums.status.ACTIVE,
      };
    }

    const accountPermissions = flux.evaluate(
      AdminAccountGetters.accountPermissions,
    );
    const canUseOutlierFiltering = PermissionsFns.canUseOutlierFiltering(
      accountPermissions,
      toImmutable(layerData),
    );

    if (canUseOutlierFiltering) {
      layerData.outlier_filter = flux.evaluateToJS(
        MetricsManagerGetters.outlierFilter,
      );
    }

    return LayerActions.save(layerData)
      .then(layer => {
        const experimentDataWithLayerId = {
          ...experimentDataToSave,
          layer_id: layer.id,
        };

        return LayerExperiment.actions.save(experimentDataWithLayerId, {
          useAudienceConditionsJSON: true,
        });
      })
      .then(layerExperiment =>
        this.updateExperimentGroup(layerExperiment, groupId).then(
          () => resolve(layerExperiment),
          reject,
        ),
      )
      .fail(reject); // This will catch the jQuery Deferred failure if Layer, LayerExperiment, or Group saves fail
  });
}

/**
 * Opens up the Create Experiment dialog to create a new AB experiment
 * with given layerPolicyType to specify which types of experiment to create.
 *
 * @param {String} layerPolicyType 'SINGLE_EXPERIMENT'
 * @param {String} experimentType 'AB_TEST' or 'FEATURE_TEST'
 * @return {Promise} createdExperiment the created experiment returned from the backend API.
 */
export function showCreateExperimentDialog(
  layerPolicyType = LayerEnums.policy.SINGLE_EXPERIMENT,
  experimentType = ManagerFullStackSectionModuleConstants.ExperimentType
    .AB_TEST,
) {
  const mode = CurrentlyEditingExperimentEnums.modes.CREATE;
  const isFeatureTest =
    experimentType ===
    ManagerFullStackSectionModuleConstants.ExperimentType.FEATURE_TEST;
  const isMultiArmedBandit =
    experimentType ===
    ManagerFullStackSectionModuleConstants.ExperimentType.MULTIARMED_BANDIT;
  const allocationType = isMultiArmedBandit
    ? TrafficAllocationPolicyTypes.MAXIMIZE_CONVERSIONS
    : TrafficAllocationPolicyTypes.MANUAL;
  let layerType = LayerEnums.type.AB;

  if (isFeatureTest) {
    layerType = LayerEnums.type.FEATURE;
  }

  if (isMultiArmedBandit) {
    layerType = LayerEnums.type.MULTIARMED_BANDIT;
  }

  const variation1 = {
    api_name: 'variation_1',
    guid: guid(),
    variable_values: {},
    weight: 5000,
  };

  const variation2 = {
    api_name: 'variation_2',
    guid: guid(),
    variable_values: {},
    weight: 5000,
  };

  if (isFeatureTest) {
    variation1.feature_enabled = false;
    variation2.feature_enabled = true;
  }

  const variationData = [variation1, variation2];

  const currentProjectId = flux.evaluate(CurrentProjectGetters.id);
  const immutableExperiment = toImmutable({
    audience_ids: [],
    description: '',
    feature_flag_id: null,
    key: '',
    layer_policy: layerPolicyType,
    layer_type: layerType,
    percentage_included: ExperimentationGroupConstants.MAX_TRAFFIC_ALLOCATION,
    project_id: currentProjectId,
    variations: variationData,
  });

  CurrentlyEditingExperimentActions.init({
    experiment: immutableExperiment,
    mode,
  });

  const handleOnSave = experimentToCreate =>
    // 1 - Take the payload from the dialog and create a new experiment with it
    this.createOrUpdateExperimentViaOasisDialog(experimentToCreate, {
      trafficAllocationPolicy: allocationType,
    })
      // 2 - Take the newly created experiment and:
      //     > Notify the user of success
      //     > Hide the dialog and pass the created experiment along
      .then(createdExperiment => {
        console.warn('New experiment created: ', { createdExperiment });
        const successMessage = `The experiment ${createdExperiment.key} has been created`;
        ui.showNotification({
          message: successMessage,
        });
        return ui.hideDialog().then(() => createdExperiment);
      })
      // 3 - Now that that hideDialog is complete, route the user to their new experiment.
      .then(createdExperiment => {
        console.warn('redirecting to manager for new experiment');

        Router.go(
          UrlHelperV2.fullStackManagerVariations(
            createdExperiment.project_id,
            createdExperiment.id,
          ),
        );
        return createdExperiment;
      })
      // 4 - Fire segment track event (Product Analytics).
      .then(createdExperiment => {
        const segmentParams = {
          type: createdExperiment.type,
          experimentId: createdExperiment.id,
          experimentKey: createdExperiment.key,
          experimentName: createdExperiment.name,
        };
        track('Experiment Created', segmentParams);
      });

  ui.showReactDialog(
    CreateExperimentDialog,
    {
      props: {
        onSave: handleOnSave,
        onCancel: () => ui.hideDialog(),
        experiment: immutableExperiment,
        experimentType,
      },
      dataBindings: {
        canCreateLayerExperiment: [
          CurrentProjectGetters.project,
          PermissionsFns.canCreateLayerExperiment,
        ],
        canUseFeatureManagement: [
          CurrentProjectGetters.project,
          PermissionsFns.canUseFeatureManagement,
        ],
        currentProjectExperiments:
          CurrentProjectGetters.fullStackLayerExperimentsWrapper,
        currentProjectFeatures: CurrentProjectGetters.features,
        environmentsAuthorizedAndSupported:
          CurrentProjectGetters.environmentsAuthorizedAndSupported,
        isRolloutsProject: CurrentProjectGetters.isRolloutsProject,
        isFullStack: CurrentProjectGetters.isFullStackProject,
        isLoadingFeatures: LoadingGetters.isLoading(LOADING_FEATURE_FLAGS),
        runningExperimentLimit: CurrentProjectGetters.runningExperimentLimit,
      },
    },
    {
      fullScreen: true,
      dismissOnBack: true,
      component: {
        template: '<div class="flex flex--1 height--1-1 width--1-1"></div>',
      },
    },
  );
}

/**
 * Fetch all the data necessary for the legacy (mobile only?) experiment editor.
 * This is deprecated and will be going away soon.
 * @return {Promise<any[]>}
 */
function fetchLegacyExperimentDialogData() {
  const currentProjectId = flux.evaluate(CurrentProjectGetters.id);
  const byProject = {
    project_id: currentProjectId,
  };
  const isFullStackProject = flux.evaluate(
    CurrentProjectGetters.isFullStackProject,
  );
  const apiCalls = [
    AudienceActions.fetchAll(byProject),
    EventActions.fetchAll(byProject),
    ExperimentationGroupActions.fetchAll(byProject),
  ];
  if (!isFullStackProject) {
    apiCalls.push(LiveVariableActions.fetchAll(byProject));
    apiCalls.push(LiveVariableUsageActions.fetchAll(byProject));
  }

  return Promise.all(apiCalls);
}

/**
 * Opens up the experiment dialog for the given experiment
 * if no experiment is passed in then create a new experiment
 * @param  {Object} dialogComponent the component to render
 * @param  {String} modeArg 'create' or 'edit'
 * @param  {Object} experimentArg If null then we should create a new
 *                             experiment object to pass to the dialog
 */
export function showLegacyCreateExperimentDialog(
  dialogComponent,
  modeArg = CurrentlyEditingExperimentEnums.modes.EDIT,
  experimentArg,
) {
  let mode = modeArg;
  let experiment = experimentArg;

  if (!experiment) {
    const currentProjectId = flux.evaluate(CurrentProjectGetters.id);
    experiment = {
      description: '',
      key: '',
      percentage_included: ExperimentationGroupConstants.MAX_TRAFFIC_ALLOCATION,
      project_id: currentProjectId,
      layer_policy: LayerEnums.policy.SINGLE_EXPERIMENT,
      audience_ids: [],
    };
    mode = CurrentlyEditingExperimentEnums.modes.CREATE;
  } else {
    CurrentLayerActions.setCurrentLayerId(experiment.layer_id);
  }

  const canEditExperiment = flux.evaluate(
    CurrentProjectGetters.canEditExperiment(experiment.status),
  );
  const data = {
    canEditExperiment,
    experiment,
    mode,
    onSave: () => {
      // after creating a new experiment, we should reset the filters
      // so the new experiment shows up in the dashboard
      FilterableTableActions.setFilter(constants.tableIds.EXPERIMENTS, {
        status:
          experiment.status === LayerExperiment.enums.status.ARCHIVED
            ? experiment.status
            : LayerExperiment.enums.status.ACTIVE,
      });
    },
  };

  fetchLegacyExperimentDialogData().then(() => {
    ui.showDialog({
      component: dialogComponent,
      fullScreen: true,
      dismissOnBack: true,
      onHideDialog: () => {
        HistoryUtils.replaceStateAndRemoveQueryParams(['experiment_id']);
      },
      data,
    });
  });
}

/**
 * Opens up the feature experiment dialog for the given experiment
 * if no experiment is passed in, then create a new feature experiment
 * @param  {Object} experimentArg If null then we should create a new
 *                             experiment object to pass to the dialog
 * @param  {Immutable.Map} experiment If null then we create a new one to pass to the dialog
 */
export function showFeatureExperimentDialog(experimentArg) {
  let experiment = experimentArg;
  const isEditing = !!experiment;

  if (!experiment) {
    const currentProjectId = flux.evaluate(CurrentProjectGetters.id);
    experiment = toImmutable({
      description: '',
      key: '',
      percentage_included: ExperimentationGroupConstants.MAX_TRAFFIC_ALLOCATION,
      project_id: currentProjectId,
      layer_policy: LayerEnums.policy.SINGLE_EXPERIMENT,
      layer_type: LayerEnums.type.FEATURE,
      audience_ids: [],
      variations: [
        {
          feature_enabled: false,
          guid: guid(),
          variable_values: {},
          api_name: 'variation_1',
          weight: 5000,
        },
        {
          feature_enabled: true,
          guid: guid(),
          variable_values: {},
          api_name: 'variation_2',
          weight: 5000,
        },
      ],
    });
  }

  ui.showReactDialog(
    FeatureExperimentDialog,
    {
      props: {
        onSave: (experimentToSave, workingMetrics) => {
          const experimentKey = htmlSanitizer.sanitize(
            experimentToSave.key,
            true,
          );
          const modificationType = isEditing ? tr('updated') : tr('created');
          const successMessage = tr(
            'The experiment <b>{0}</b> has been {1}.',
            experimentKey,
            modificationType,
          );
          return this.createOrUpdateExperimentViaOasisDialog(experimentToSave, {
            metrics: workingMetrics,
            trafficAllocationPolicy: TrafficAllocationPolicyTypes.MANUAL,
          }).then(() => {
            ui.showNotification({
              message: successMessage,
            });
            ui.hideDialog();
          });
        },
        onCancel() {
          ui.hideDialog();
        },
        experiment,
        isEditing,
        isExperimentLive: CurrentlyEditingExperimentFns.isExperimentLive(
          experiment,
        ),
      },
      dataBindings: {
        activeGroups: getters.currentProjectActiveGroups,
        canCreateLayerExperiment: [
          CurrentProjectGetters.project,
          PermissionsFns.canCreateLayerExperiment,
        ],
        canEditExperiment: CurrentProjectGetters.canEditExperiment(
          experiment.get('status'),
        ),
        canUpdateLayerExperiment: [
          CurrentProjectGetters.project,
          PermissionsFns.canUpdateLayerExperiment,
        ],
        currentProjectExperiments:
          CurrentProjectGetters.fullStackLayerExperimentsWrapper,
        currentProjectFeatures: CurrentProjectGetters.features,
        environmentsAuthorizedAndSupported:
          CurrentProjectGetters.environmentsAuthorizedAndSupported,
        isMobileOnly: [
          AdminAccountGetters.accountPermissions,
          PermissionsFns.isMobileOnlyAccount,
        ],
        primarySDKLanguage: CurrentProjectGetters.primarySDKLanguage,
        workingMetrics: MetricsManagerGetters.workingMetrics,
      },
    },
    {
      fullScreen: true,
      dismissOnBack: true,
      onHideDialog: () => {
        HistoryUtils.replaceStateAndRemoveQueryParams(['experiment_id']);
      },
      component: {
        template: '<div class="flex--1 flex width--1-1 height--1-1"></div>',
      },
    },
  );
}

/**
 * Opens the event config dialog
 * @TODO(mng): deprecate once we get rid of the mobile experiment VUE form because
 * this is only needed in the experiment_dialog component module.
 * @param {String}    initialName
 * @param {Function}  onSave
 */
export function showEventConfigDialog(initialName, onSave) {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const primarySDKLanguage = flux.evaluate(
    CurrentProjectGetters.primarySDKLanguage,
  );
  const eventToEdit = EventModuleFns.createEventEntity({
    event_type: EventModuleEnums.eventTypes.CUSTOM,
    api_name: initialName,
    name: initialName,
    project_id: projectId,
  });

  ui.showReactDialog(
    EventConfigDialog,
    {
      props: {
        onSaveEvent: onSave,
        event: toImmutable(eventToEdit),
        language: primarySDKLanguage,
      },
    },
    {
      isOuiDialog: true,
      noPadding: true,
      fullScreen: true,
      dismissOnBack: true,
    },
  );
}

/**
 * Opens up the experiment whitelisting dialog
 * @param {Object} experimentVueObject
 */
export function showWhitelistDialog(experimentVueObject) {
  ui.showReactDialog(
    WhitelistDialog,
    {
      props: {
        onSave: LayerExperiment.actions.save,
        experiment: toImmutable(
          flux.evaluateToJS(
            LayerExperiment.getters.byId(experimentVueObject.id),
          ),
        ),
      },
    },
    {
      fullScreen: true,
      dismissOnBack: true,
    },
  );
}

/**
 * Opens the live variable dialog
 * @param {Immutable.Map} variableArg
 */
export function showVariableDialog(variableArg) {
  let variable = variableArg;
  const language = flux.evaluate(CurrentProjectGetters.primarySDKLanguage);

  if (!variable) {
    const projectId = flux.evaluate(CurrentProjectGetters.id);
    variable = LiveVariableFns.createLiveVariableEntity({
      project_id: projectId,
    });
  }

  ui.showDialog({
    component: vueHelpers.extendComponent(LiveVariableDialogComponent, {
      onSave: variableToSave => LiveVariableActions.save(variableToSave),
      language,
      variable,
    }),
    noPadding: true,
    fullScreen: true,
    dismissOnBack: true,
  });
}

/**
 * Boolean is used to determine whether we let users run additional experiments.
 * If the user can run additional experiments, return true.
 * If the user cannot run additional experiments, show the dialog and return false.
 * @param {Immutable.Map} experiment,
 * @param {String} environmentStatus,
 * @returns {boolean}
 */

function validateCanStartExperimentOrWarn(experiment, environmentStatus) {
  const shouldCheckExperimentLimit =
    flux.evaluate(CurrentProjectGetters.isRolloutsProject) &&
    environmentStatus !== LayerExperiment.enums.EnvironmentStatus.RUNNING;
  if (!shouldCheckExperimentLimit) {
    return true;
  }
  const currentProjectRunningExperimentIds = flux
    .evaluate(CurrentProjectGetters.currentProjectRunningExperiments)
    .map(e => e.get('id'));
  const currentProjectRunningExperimentLimit = flux
    .evaluate(CurrentProjectGetters.project)
    .get('running_experiment_limit');
  const shouldShowFreeRolloutsLimitDialog = !fns.canRunAdditionalExperiment(
    experiment.get('id'),
    currentProjectRunningExperimentIds,
    currentProjectRunningExperimentLimit,
  );
  if (!shouldShowFreeRolloutsLimitDialog) {
    return true;
  }
  UpgradeActions.showUpgradeDialog(
    UpgradeConstants.FeatureLimitTypes.RUNNING_EXPERIMENT_LIMIT,
    currentProjectRunningExperimentLimit,
  );
  return false;
}

/**
 * Change the status of an experiment tied to an environment.
 * If the experiment is not started and the environmentId corresponds to the
 * primary environment, show a confirmation dialog before changing the status.
 * @param {Immutable.Map} experiment,
 * @param {Number} environmentId
 * @param {String} environmentKey
 * @param {String} environmentStatus
 * @return {$.Deferred}
 */

export function updateExperimentStatusWithEnvironment(
  experiment,
  environmentId,
  environmentKey,
  environmentStatus,
) {
  if (!validateCanStartExperimentOrWarn(experiment, environmentStatus)) {
    // Not allowed to start.
    // Need to return a resolved deferred to resolve the loading spinner.
    return $.Deferred().resolve();
  }
  // proceed to start
  return EnvironmentActions.fetch(environmentId)
    .then(environment => {
      if (
        environment.is_primary &&
        environmentStatus ===
          LayerExperiment.enums.EnvironmentStatus.NOT_STARTED
      ) {
        const entityName = LayerExperiment.fns.isMultiArmedBandit(experiment)
          ? 'optimization'
          : 'experiment';
        return ui.confirm({
          title: `Run ${capitalize(entityName)}`,
          message: `Your ${entityName} is about to run in your primary environment. Are you sure you want to run this ${entityName}?`,
          confirmText: `Run ${capitalize(entityName)}`,
        });
      }
      // Intentionally not returning anything here. The environment is not
      // primary or it's been started before, so there's no need to show a
      // confirm dialog. We can go ahead and do the API update immediately.
    })
    .then(() => {
      if (
        environmentStatus === LayerExperiment.enums.EnvironmentStatus.RUNNING
      ) {
        return LayerExperiment.actions.pauseExperimentWithEnvironment(
          experiment,
          environmentKey,
        );
      }
      return LayerExperiment.actions.runExperimentWithEnvironment(
        experiment,
        environmentKey,
      );
    })
    .then(updatedExperiment => {
      const newStatus =
        updatedExperiment &&
        updatedExperiment.environments &&
        updatedExperiment.environments[environmentKey] &&
        updatedExperiment.environments[environmentKey].status;
      const readableNewStatus =
        LayerExperiment.humanReadable.EXPERIMENT_ENVIRONMENT_STATUS_LOWER[
          newStatus
        ];
      if (readableNewStatus) {
        const environment = flux.evaluate(
          EnvironmentGetters.byId(environmentId),
        );
        const trArgs = [
          experiment.get('key'),
          readableNewStatus,
          (environment && environment.get('name')) || '',
        ].map(s => htmlSanitizer.sanitize(s, true));
        const message = tr('Success: <b>{0}</b> is now {1} in {2}', ...trArgs);
        ui.showNotification({
          message,
        });
      }
    });
}

export default {
  archiveExperiment,
  createOrUpdateExperimentViaOasisDialog,
  getUnarchivedExperimentCopy,
  launchExperiment,
  showCreateExperimentDialog,
  showEventConfigDialog,
  showLegacyCreateExperimentDialog,
  showFeatureExperimentDialog,
  showWhitelistDialog,
  showVariableDialog,
  unarchiveExperiment,
  updateExperimentGroup,
  updateExperimentStatusWithEnvironment,
};

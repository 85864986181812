/**
 * Directive to ensure that links open in the same window
 * for the desktop app by setting the target attribute
 * to _self instead of _blank
 *
 * Usage:
 * <a href="..." v-desktop-same-window>Link Name</a>
 */
import { fns as DesktopAppFns } from 'optly/modules/desktop_app';

const exported = {
  isLiteral: true,

  bind() {
    if (DesktopAppFns.isDesktopApp()) {
      this.el.setAttribute('target', '_self');
    }
  },
};

export default exported;

export const { isLiteral, bind } = exported;

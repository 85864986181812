/* eslint-disable import/no-cycle */
/**
 * Audience Combinations Builder:
 * - This component is meant for use throughout Web and Full Stack and is meant to replace all
 *   other Audience Picker / Selector / Configuration components
 * - Inspired by the React docs, this component allows for parent refresh via a React ref
 *   Learn more at https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#alternative-2-reset-uncontrolled-component-with-an-instance-method
 * - While deferring state management to the parent doesn't feel perfect, it allows us to much more effectively manage
 *   updating this component for cases of dirty state checking and reverting
 * - While the backend provides and expects stringified JSON for audience_conditions, this component expects and saves
 *   audienceConditions in an Immutable.List format. The deserialization happens in layer_experiment/entity_definition.js
 *   and the cleanup happens in layer_experiment/actions.js' save() method
 * - If audienceConditions is undefined (e.g. for new Feature Experiments), the defaultAudienceMatchType will be used to build the Audience structure
 * - When onSelectionChange is called, audienceConditions {Immutable.List} and isValidAudienceConfig {Boolean} will be passed.
 *   audienceConditions should be converted via the Immutable toJS() method before being saved and
 *   isValidAudienceConfig should be used to prevent save when false. This is especially important because an invalid
 *   audienceConfig will actually pass audienceConditions as an empty Immutable.List, which could cause some confusing outcomes
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import {
  Attention,
  BlockList,
  Button,
  Checkbox,
  Dropdown,
  FilterPicker,
  HelpPopover,
  Input,
  Label,
  Link,
  Pill,
  Poptip,
} from 'optimizely-oui';

import { OptimizelyFeature } from '@optimizely/react-sdk';

import Immutable, { toImmutable } from 'optly/immutable';
import { connect } from 'core/ui/decorators';

import {
  Dropdown as DropDownV2,
  DropdownContents,
  DropdownListItem,
  DropdownBlockLink,
} from 'react_components/dropdown';
import AudienceExperimentUsage from 'bundles/p13n/components/dialogs/audience_experiment_usage';
import ui from 'core/ui';

import {
  enums as AudienceEnums,
  fns as AudienceFns,
} from 'optly/modules/entity/audience';
import { fns as LayerExperimentFns } from 'optly/modules/entity/layer_experiment';
import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import LayerFns from 'optly/modules/entity/layer/fns';
import { actions as P13NDashboardActions } from 'bundles/p13n/modules/dashboard';
import { actions as P13NUIActions } from 'bundles/p13n/modules/ui'; // eslint-disable-line import/no-cycle
import { showSupportDialog } from 'optly/modules/support/actions';

import ErrorBoundary from 'core/ui/components/error_boundary';
import LoadingOverlay from 'react_components/loading_overlay';
import ReactCodeMirror from 'react_components/codemirror';

import {
  fns as ComponentModuleFns,
  getters as ComponentModuleGetters,
} from './component_module';

const { AND, OR } = AudienceEnums.ConditionGroupTypes;
const {
  ALL_AUDIENCES,
  ANY_AUDIENCES,
  CUSTOM_AUDIENCES,
} = AudienceEnums.audienceMatchTypes;
const AudienceAction = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};
const AUDIENCE_CONDITONS_DEV_DOC_LINK =
  'https://docs.developers.optimizely.com/full-stack/docs/set-audiences-for-experiments-and-rollouts#section-step-3-define-the-conditions';
const FULL_STACK_AUDIENCES_KB_DEFAULT_LINK =
  'https://docs.developers.optimizely.com/full-stack/docs/set-audiences-for-experiments-and-rollouts';
const CODE_MODE_TOGGLE_DISABLED_POPTIP_TEXT =
  'Due to the structure or syntax of your conditions, you can only edit this Audience combination in Code Mode';

@connect(({ audienceConditions }) => ({
  availableAudiences: CurrentProjectGetters.availableAudiences(
    audienceConditions,
  ),
  currentLayer: CurrentLayerGetters.layer,
  isWebProject: CurrentProjectGetters.isWebProject,
  projectId: CurrentProjectGetters.id,
  shouldShowAudienceCombinationOptions: ComponentModuleGetters.shouldShowAudienceCombinationOptions(),
  isFullStackProject: CurrentProjectGetters.isFullStackProject,
  isFlagsProject: CurrentProjectGetters.isFlagsProject,
}))
class AudienceCombinationsBuilder extends Component {
  static displayName = 'AudienceCombinationsBuilder';

  /* eslint-disable react/sort-prop-types */
  static propTypes = {
    /**
     * Provided by @connect - See above getters for more info
     */
    availableAudiences: PropTypes.instanceOf(Immutable.List).isRequired,
    currentLayer: PropTypes.instanceOf(Immutable.Map),
    isWebProject: PropTypes.bool.isRequired,
    inForm: PropTypes.bool,
    projectId: PropTypes.number.isRequired,
    shouldShowAudienceCombinationOptions: PropTypes.bool.isRequired,

    /**
     * Passed Props
     */
    /** Audience Conditions JSON provided as an Immutable.List */
    audienceConditions: PropTypes.instanceOf(Immutable.List),
    /** Whether or not Audience can be edited */
    canEditAudience: PropTypes.bool,
    /** Default Audience Match Type of type ANY_AUDIENCES or ALL_AUDIENCES, chosen for resets and simplified dropdown */
    defaultAudienceMatchType: PropTypes.oneOf([ALL_AUDIENCES, ANY_AUDIENCES]),
    /**
     * Function that will be called with the following object when this component is updated:
     *
     * @param {Object} config
     * @param {Immutable.List} config.audienceConditions
     * @param {Boolean} config.isValidAudienceConfig
     */
    onSelectionChange: PropTypes.func,
    poptipContent: PropTypes.node,
    isFullStackProject: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    isFlagsProject: PropTypes.bool,
  };
  /* eslint-enable react/sort-prop-types */

  static defaultProps = {
    audienceConditions: toImmutable([]),
    canEditAudience: true,
    currentLayer: Immutable.Map(),
    defaultAudienceMatchType: ANY_AUDIENCES,
    inForm: false,
    onSelectionChange: () => {},
    poptipContent: <p>Who do you want to be included in this experience?</p>,
  };

  dropdownOptions = [
    {
      renderTitle: (
        <span>
          Match <strong>any</strong> audience
        </span>
      ), // eslint-disable-line react/display-name
      description:
        'A visitor will be in this experiment if they match one or more of the audiences specified.',
      type: ANY_AUDIENCES,
    },
    {
      renderTitle: (
        <span>
          Match <strong>all</strong> audiences
        </span>
      ), // eslint-disable-line react/display-name
      description:
        'A visitor will be in this experiment if they match all of the audiences specified.',
      type: ALL_AUDIENCES,
    },
  ];

  /* eslint-disable react/state-in-constructor */
  constructor(props) {
    super(props);

    this.state = {
      isFetchingAudienceDialogInfo: false,
      isCodeMode: false,
      ...this.deriveComponentStateFromAudienceConditionsProp(),
    };
  }
  /* eslint-enable react/state-in-constructor */

  /**
   * Using this.props.audienceConditions, derive state in case updates are needed for audienceConditionsString, audienceMatchType, or isCodeMode
   *
   * @returns {Object}
   */
  deriveComponentStateFromAudienceConditionsProp = () => {
    const { defaultAudienceMatchType } = this.props;

    let { audienceConditions } = this.props;

    if (!audienceConditions || !audienceConditions.size) {
      audienceConditions = toImmutable([
        defaultAudienceMatchType === ANY_AUDIENCES ? OR : AND,
      ]);
    }
    const audienceMatchType = ComponentModuleFns.deriveAudienceMatchTypeFromConditions(
      audienceConditions,
    );
    return {
      ...(audienceMatchType === CUSTOM_AUDIENCES ? { isCodeMode: true } : {}),
      audienceConditionsString: ComponentModuleFns.derivePrettyStringFromImmutableList(
        audienceConditions,
      ),
      audienceConditions,
      audienceMatchType,
    };
  };

  /**
   * This method should be called from the parent via React.createRef if a revert is needed.
   * Example:
   *  audienceCombinationsBuilderRef = React.createRef()
   *  handleRevert() => {
   *    this.audienceCombinationsBuilderRef.current.reinitializeComponent();
   *  }
   *  <AudienceCombinationsBuilder ...props ref={this.audienceCombinationsBuilderRef} />
   *  <Button onClick={ this.handleRevert }>Revert</Button>
   */
  reinitializeComponent = () => {
    this.setState(this.deriveComponentStateFromAudienceConditionsProp());
  };

  /**
   * Handles calling onSelectionChange and updating component state
   *
   * @param {String} audienceMatchType
   *  Set in component state
   * @param {Immutable.List} audienceCondition
   *  Sent in onSelectionChange alongside isValidAudienceConfig
   * @param {String} audienceConditionsString
   *  Set in component state
   */
  setStateAndUpdateParent = ({
    audienceMatchType,
    audienceConditions,
    audienceConditionsString,
  }) => {
    const { canEditAudience, onSelectionChange } = this.props;

    // No-op if no edit permissions
    if (!canEditAudience) {
      return;
    }
    const audienceIds = LayerExperimentFns.deriveAudienceIdsFromAudienceConditions(
      audienceConditions,
    );
    this.setState(
      { audienceConditions, audienceMatchType, audienceConditionsString },
      () => {
        onSelectionChange({
          audienceConditions,
          isValidAudienceConfig: this.validateAudienceConfig(
            audienceConditionsString,
            audienceIds,
          ).isValid,
        });
      },
    );
  };

  /**
   * Passes provided arguments to each function in the ComponentModuleFns.validators. If there are validation errors, it sets
   * the error and separately provides a Boolean key/value on whether the Audience config is valid.
   *
   * @param {String} audienceConditionsString
   * @param {Array} audienceIds
   * @returns {Object} { isValid: {Boolean}, validations, {Object} }
   *  Returns true if any validator returned true
   */
  validateAudienceConfig = (audienceConditionsString, audienceIds) => {
    const { availableAudiences } = this.props;

    const validations = {};

    Object.values(ComponentModuleFns.validators).forEach(fn => {
      const validation = fn({
        audienceConditionsString,
        audienceIds,
        availableAudiences,
      });
      if (validation) {
        validations[fn.name] = validation;
      }
    });

    return {
      isValid: !Object.keys(validations).length,
      validations,
    };
  };

  /**
   * Component Handlers
   */

  /**
   * Adds or removes the audience specified by audienceId in UI Mode
   *
   * @param {Number} audienceId
   *  Audience ID to ADD or REMOVE
   * @param {AudienceAction} action
   *  Of type AudienceAction, determines what action is applied along with the Audience ID
   */
  handleAudienceAddOrRemove = (audienceId, action) => {
    const { defaultAudienceMatchType } = this.props;
    const { audienceConditions } = this.state;
    let { audienceMatchType } = this.state;

    let updatedAudienceConditions = audienceConditions;
    switch (action) {
      case AudienceAction.ADD:
        // If audience_ids nor audience_conditions have never been saved for a layerExperiment, this list will be empty
        if (
          !audienceConditions.filter(item => item instanceof Immutable.Map).size
        ) {
          audienceMatchType = defaultAudienceMatchType;
          updatedAudienceConditions = toImmutable([
            defaultAudienceMatchType === ANY_AUDIENCES ? OR : AND,
          ]);
        }
        updatedAudienceConditions = updatedAudienceConditions.push(
          toImmutable({ audience_id: audienceId }),
        );
        break;
      case AudienceAction.REMOVE:
        updatedAudienceConditions = audienceConditions.filterNot(
          item =>
            item instanceof Immutable.Map &&
            item.get('audience_id') === audienceId,
        );

        // If there are fewer than two audience_ids, we need to override this to be the defaultAudienceMatchType, which
        // will update audience_match_type and audience_conditions. This will ensure that LayerExperiments with only one
        // Audience combination leaf will be compatible with older SDKs
        if (
          ((defaultAudienceMatchType === ANY_AUDIENCES &&
            audienceMatchType === ALL_AUDIENCES) ||
            (defaultAudienceMatchType === ALL_AUDIENCES &&
              audienceMatchType === ANY_AUDIENCES)) &&
          // Detect if there are fewer than 2 condition leafs
          updatedAudienceConditions.filter(i => i instanceof Immutable.Map)
            .size <= 1
        ) {
          audienceMatchType = defaultAudienceMatchType;
          updatedAudienceConditions = updatedAudienceConditions.set(
            0,
            audienceMatchType === ANY_AUDIENCES ? OR : AND,
          );
        }
        break;
      default:
        return;
    }

    this.setStateAndUpdateParent({
      audienceConditions: updatedAudienceConditions,
      audienceConditionsString: ComponentModuleFns.derivePrettyStringFromImmutableList(
        updatedAudienceConditions,
      ),
      audienceMatchType,
    });
  };

  /**
   * When Audience Conditions are updated via Code Mode, convert to Immutable.List if valid and attempt to prettify to stringified JSON
   *
   * @param {String} updatedAudienceConditionsString
   */
  handleAudienceConditionsStringUpdate = updatedAudienceConditionsString => {
    const updatedAudienceConditions = ComponentModuleFns.deriveAudienceConditionsJsonFromString(
      updatedAudienceConditionsString,
    );
    const audienceConditionsString = ComponentModuleFns.derivePrettyStringFromImmutableList(
      updatedAudienceConditions,
    );
    this.setStateAndUpdateParent({
      audienceConditions: updatedAudienceConditions || toImmutable([]),
      audienceConditionsString:
        audienceConditionsString || updatedAudienceConditionsString,
      audienceMatchType: ComponentModuleFns.deriveAudienceMatchTypeFromConditions(
        updatedAudienceConditions,
      ),
    });
  };

  /**
   * If shouldShowAudienceCombinationOptions is true, the Audience Match Type Dropdown menu will be visible when in UI Mode
   *
   * @param {String} audienceMatchType
   */
  handleAudienceMatchTypeUpdate = audienceMatchType => {
    const { audienceConditions } = this.state;

    const updatedAudienceConditions = audienceConditions.set(
      0,
      audienceMatchType === ANY_AUDIENCES ? OR : AND,
    );
    const audienceConditionsString = ComponentModuleFns.derivePrettyStringFromImmutableList(
      updatedAudienceConditions,
    );
    this.setStateAndUpdateParent({
      audienceConditions: updatedAudienceConditions,
      audienceConditionsString,
      audienceMatchType,
    });
  };

  /**
   * For creating or viewing Audiences
   *
   * @param {Object} config
   * @param {Number} config.id
   *  If provided, the Audience for this ID will be fetched
   * @param {String} config.name
   *  If provided when options.id is falsy, the Audience builder will be loaded with this value as the name
   */
  handleCreateOrEditAudience = ({ id = null, name = '' }) => {
    const handleDeferred = $.Deferred();

    if (!id) {
      // Only add into Selected Audiences if Create New Audience is clicked
      handleDeferred.then(audience => {
        this.handleAudienceAddOrRemove(audience.id, AudienceAction.ADD);
      });
    }
    const { isFetchingAudienceDialogInfo } = this.state;
    if (isFetchingAudienceDialogInfo) {
      // Don't open it again if it's already open.
      return;
    }
    this.setState({
      isFetchingAudienceDialogInfo: true,
    });
    P13NDashboardActions.fetchOrCreateAudience(id, name).then(audience => {
      P13NUIActions.showAudienceEditorDialog(audience, handleDeferred).then(
        () => {
          this.setState({
            isFetchingAudienceDialogInfo: false,
          });
        },
      );
    });
  };

  handleViewExperimentUsage = audience_id => {
    ui.showReactDialog(
      AudienceExperimentUsage,
      {
        props: {
          selectedAudienceId: audience_id,
        },
      },
      {
        isOuiDialog: true,
        fullScreen: false,
        dismissOnBack: true,
      },
    );
  };

  /**
   * Toggles UI and Code Mode when audienceMatchType is not CUSTOM_AUDIENCES
   */
  handleToggleCodeMode = () => {
    const { audienceMatchType } = this.state;

    if (audienceMatchType === CUSTOM_AUDIENCES) {
      return;
    }

    this.setState(prevState => ({ isCodeMode: !prevState.isCodeMode }));
  };

  shouldHideCreateAudienceBtn() {
    const { isFullStackProject, isFlagsProject } = this.props;
    return (
      isFeatureEnabled('disable_creating_full_stack_entities') &&
      isFullStackProject &&
      !isFlagsProject
    );
  }

  render() {
    const {
      availableAudiences,
      currentLayer,
      poptipContent,
      canEditAudience,
      isWebProject,
      shouldShowAudienceCombinationOptions,
      inForm,
      isFullStackProject,
    } = this.props;
    const {
      audienceConditions,
      audienceConditionsString,
      audienceMatchType,
      isFetchingAudienceDialogInfo,
      isCodeMode,
    } = this.state;

    const audienceIds = LayerExperimentFns.deriveAudienceIdsFromAudienceConditions(
      audienceConditions,
    );
    const audienceConfigValidations = this.validateAudienceConfig(
      audienceConditionsString,
      audienceIds,
    );
    const smallPropValue = isWebProject ? 'fillSpace' : '10';
    const isUsageInspectorEnabled = isFeatureEnabled(
      'usage_inspector_audiences',
    );
    return (
      <div className="position--relative">
        <LoadingOverlay isLoading={isFetchingAudienceDialogInfo}>
          <ErrorBoundary
            alternateContent={
              <div
                title="Something went wrong"
                data-test-section="audiences-error-boundary">
                <h3>Audiences</h3>
                There was an error loading Audiences. Please refresh the page or
                visit our Help Center if the issue persists.
                <div className="display--block push--top">
                  <Button style="highlight" onClick={() => showSupportDialog()}>
                    Optimizely Help Center
                  </Button>
                </div>
              </div>
            }>
            <FilterPicker
              allEntities={availableAudiences}
              selectedEntityIds={audienceIds}
              keysToSearch={['name', 'description']}
              testSection="audience-combinations-builder">
              {({
                availableEntities,
                filterQuery,
                handleFilterInput,
                selectedEntities,
              }) => (
                <>
                  <div className="flex">
                    <div className="flex--1">
                      <Label>
                        {inForm ? (
                          <h3 className="display--inline-block flush">
                            Audiences
                          </h3>
                        ) : (
                          <h2 className="display--inline-block flush">
                            Audiences
                          </h2>
                        )}
                        <HelpPopover
                          horizontalAttachment="left"
                          popoverTitle="Audiences"
                          verticalAttachment="top"
                          testSection="audiences-help-popover">
                          <>
                            {poptipContent}
                            {shouldShowAudienceCombinationOptions && (
                              <span data-test-section="audiences-help-popover-optional-combination-note">
                                When multiple Audiences are selected, you can
                                choose a combination of <em>any</em> or{' '}
                                <em>all</em> of the selected Audiences, or even
                                define a custom combination in{' '}
                                <em>Code Mode</em>.
                              </span>
                            )}
                          </>
                        </HelpPopover>
                      </Label>
                    </div>
                    {shouldShowAudienceCombinationOptions && (
                      <Poptip
                        content={CODE_MODE_TOGGLE_DISABLED_POPTIP_TEXT}
                        disable={
                          audienceConfigValidations.isValid &&
                          audienceMatchType !== CUSTOM_AUDIENCES
                        }
                        isAnimated={false}>
                        <div className="soft-half--top">
                          <Checkbox
                            isDisabled={
                              !audienceConfigValidations.isValid ||
                              audienceMatchType === CUSTOM_AUDIENCES
                            }
                            label="Code Mode"
                            onChange={this.handleToggleCodeMode}
                            data-test-section="code-mode-toggle"
                            checked={isCodeMode}
                          />
                        </div>
                      </Poptip>
                    )}
                  </div>
                  <div className="push--ends">
                    {`Choose the audiences who should see this ${
                      LayerFns.isMultiArmedBandit(currentLayer)
                        ? 'multi-armed bandit'
                        : 'experience'
                    }`}
                  </div>
                  {shouldShowAudienceCombinationOptions &&
                    audienceIds.length > 1 &&
                    audienceMatchType !== CUSTOM_AUDIENCES &&
                    !isCodeMode && (
                      <div className="push--ends">
                        <Dropdown
                          arrowIcon="down"
                          buttonContent={
                            this.dropdownOptions.find(
                              option => option.type === audienceMatchType,
                            ).renderTitle
                          }
                          isDisabled={!canEditAudience}
                          style="outline"
                          value={audienceMatchType}
                          width={300}
                          testSection="audience-combination-dropdown">
                          <BlockList>
                            {this.dropdownOptions.map(item => {
                              const { description, type } = item;
                              return (
                                <BlockList.Category key={type}>
                                  <BlockList.Item
                                    onClick={() =>
                                      this.handleAudienceMatchTypeUpdate(type)
                                    }
                                    testSection={`dropdown-option-${type}`}>
                                    <div className="flex flex-align--center">
                                      <div className="flex--1">
                                        <div>
                                          {
                                            this.dropdownOptions.find(
                                              option => option.type === type,
                                            ).renderTitle
                                          }
                                        </div>
                                        <div className="muted micro">
                                          {description}
                                        </div>
                                      </div>
                                    </div>
                                  </BlockList.Item>
                                </BlockList.Category>
                              );
                            })}
                          </BlockList>
                        </Dropdown>
                      </div>
                    )}
                  {!isWebProject &&
                    audienceIds.length !== 0 &&
                    audienceConfigValidations.isValid &&
                    audienceMatchType !== ANY_AUDIENCES && (
                      <div
                        className={classNames({
                          'push--bottom':
                            audienceMatchType === CUSTOM_AUDIENCES,
                          'push-half--bottom':
                            audienceMatchType !== CUSTOM_AUDIENCES,
                        })}>
                        <Attention
                          alignment="center"
                          type="brand"
                          testSection="sdk-compatibility-warning">
                          Using this Audience combination requires Optimizely
                          SDK version 3.0 or greater.{' '}
                          <Link
                            newWindow={true}
                            href={FULL_STACK_AUDIENCES_KB_DEFAULT_LINK}>
                            Learn More
                          </Link>
                          .
                        </Attention>
                      </div>
                    )}
                  {!isCodeMode && (
                    <div data-test-section="selected-audiences">
                      {!selectedEntities.size && (
                        <div className="push-double--ends">
                          <div className="display--inline-block">
                            <Pill
                              name="Everyone"
                              testSection="selected-audience-everyone"
                            />
                          </div>
                        </div>
                      )}
                      {!!selectedEntities.size && (
                        <>
                          <div className="muted">
                            To pass Audience evaluation, a visitor must match
                          </div>
                          <div className="push-half--top push-double--bottom">
                            {selectedEntities.map((entity, index) => (
                              <React.Fragment key={entity.get('id')}>
                                <div
                                  className="flex flex-align--center"
                                  data-test-section="selected-audience">
                                  <div className="flex--1">
                                    <div className="display--inline-block">
                                      <Pill
                                        isDismissible={canEditAudience}
                                        name={entity.get('name')}
                                        onDismiss={() =>
                                          this.handleAudienceAddOrRemove(
                                            entity.get('id'),
                                            AudienceAction.REMOVE,
                                          )
                                        }
                                        testSection={`selected-audience-${entity.get(
                                          'id',
                                        )}`}
                                      />
                                      {isWebProject &&
                                        isUsageInspectorEnabled && (
                                          <span className="micro muted soft-half--left">
                                            Used in{' '}
                                            {entity.get('experiment_count')}{' '}
                                            experiments
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                  <DropDownV2
                                    activator={
                                      <Button
                                        testSection="audience-combinations-builder-actions"
                                        size="small"
                                        style="outline">
                                        <div className="flex flex-align--center">
                                          <span className="flex flex--1">
                                            Actions
                                          </span>
                                          <span className="push--left oui-arrow-inline--down" />
                                        </div>
                                      </Button>
                                    }>
                                    <DropdownContents
                                      direction="left"
                                      minWidth="200px">
                                      <DropdownListItem hideOnClick={true}>
                                        <DropdownBlockLink
                                          isLink={true}
                                          onClick={() =>
                                            this.handleCreateOrEditAudience({
                                              id: entity.get('id'),
                                            })
                                          }
                                          testSection={`view-selected-audience-${entity.get(
                                            'id',
                                          )}`}>
                                          Edit
                                        </DropdownBlockLink>
                                        {!isFullStackProject && (
                                          <OptimizelyFeature feature="usage_inspector_audiences">
                                            {isEnabled =>
                                              isEnabled && (
                                                <DropdownBlockLink
                                                  isLink={true}
                                                  onClick={() =>
                                                    this.handleViewExperimentUsage(
                                                      entity.get('id'),
                                                    )
                                                  }
                                                  testSection={`view-experiment-usage-audience-${entity.get(
                                                    'id',
                                                  )}`}>
                                                  View Experiment Usage
                                                </DropdownBlockLink>
                                              )
                                            }
                                          </OptimizelyFeature>
                                        )}
                                      </DropdownListItem>
                                    </DropdownContents>
                                  </DropDownV2>
                                </div>
                                {selectedEntities.size !== index + 1 && (
                                  <div
                                    className="push-half--sides push-half--ends muted"
                                    data-test-section="combination-operator">
                                    {audienceMatchType === ANY_AUDIENCES &&
                                      'Or'}
                                    {audienceMatchType === ALL_AUDIENCES &&
                                      'And must also match'}
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {isCodeMode && (
                    <>
                      <ReactCodeMirror
                        isReadOnly={!canEditAudience}
                        maxHeight={900}
                        onChange={this.handleAudienceConditionsStringUpdate}
                        options={{ lint: false }}
                        testSection="audience-conditions-code-mirror"
                        value={audienceConditionsString}
                      />
                      <div className="oui-form-note">
                        For help writing condition code, see our{' '}
                        <Link
                          href={AUDIENCE_CONDITONS_DEV_DOC_LINK}
                          newWindow={true}>
                          API documentation
                        </Link>
                        .
                      </div>
                      <div className="push--top push-double--bottom">
                        <Label>
                          <span className="weight--bold">
                            Evaluated Audiences
                          </span>
                        </Label>
                        <div
                          className={classNames({
                            'color--bad-news': !audienceConfigValidations.isValid,
                          })}
                          data-test-section="evaluated-audiences">
                          {!audienceConfigValidations.isValid
                            ? Object.values(
                                audienceConfigValidations.validations,
                              ).join(' ')
                            : AudienceFns.getPlaceHolderNameFromAudiences(
                                audienceConditionsString,
                                availableAudiences,
                              ) || 'Everyone'}
                        </div>
                      </div>
                    </>
                  )}
                  {canEditAudience && audienceConfigValidations.isValid && (
                    <div
                      className="oui-filter-picker-list"
                      data-test-section="audience-picker">
                      <Input
                        isFilter={true}
                        onInput={handleFilterInput}
                        placeholder="Browse for Audiences"
                        testSection="filter-audiences-input"
                        type="search"
                      />
                      <BlockList hasBorder={true}>
                        {!this.shouldHideCreateAudienceBtn() && (
                          <BlockList.Category>
                            <FilterPicker.ListItem
                              name={`Create New Audience${
                                filterQuery ? ` "${filterQuery}"` : ''
                              }...`}
                              onClick={() =>
                                this.handleCreateOrEditAudience({
                                  name: filterQuery,
                                })
                              }
                              testSection="create-new-audience"
                            />
                          </BlockList.Category>
                        )}
                        <div className="max-scroll--medium">
                          <BlockList.Category
                            header={
                              !!availableEntities.size &&
                              'Recently Created Audiences'
                            }>
                            {availableEntities.map(entity => (
                              <FilterPicker.ListItem
                                key={entity.get('id')}
                                id={entity.get('id')}
                                name={entity.get('name')}
                                description={entity.get('description')}
                                onClick={(event, audienceId) =>
                                  this.handleAudienceAddOrRemove(
                                    audienceId,
                                    AudienceAction.ADD,
                                  )
                                }
                                buttonText="View"
                                onButtonClick={() =>
                                  this.handleCreateOrEditAudience({
                                    id: entity.get('id'),
                                  })
                                }
                                testSection={`available-audience-${entity.get(
                                  'id',
                                )}`}
                              />
                            ))}
                          </BlockList.Category>
                        </div>
                      </BlockList>
                    </div>
                  )}
                </>
              )}
            </FilterPicker>
          </ErrorBoundary>
        </LoadingOverlay>
      </div>
    );
  }
}

export default AudienceCombinationsBuilder;

import LayerEnums from 'optly/modules/entity/layer/enums';
import EntitySearchConstants from 'bundles/p13n/components/entity_search/component_module/constants';

const filterableActiveStatuses = [
  EntitySearchConstants.searchFilterableStatuses.RUNNING,
  EntitySearchConstants.searchFilterableStatuses.PAUSED,
  EntitySearchConstants.searchFilterableStatuses.NOT_STARTED,
];

export const allFilterableActiveStatuses = filterableActiveStatuses.join(',');

export const filterToStatuses = {
  [LayerEnums.status.ACTIVE]: allFilterableActiveStatuses,
  [LayerEnums.status.RUNNING]:
    EntitySearchConstants.searchFilterableStatuses.RUNNING,
  [LayerEnums.status.PAUSED]:
    EntitySearchConstants.searchFilterableStatuses.PAUSED,
  [LayerEnums.status.DRAFT]:
    EntitySearchConstants.searchFilterableStatuses.NOT_STARTED,
  [LayerEnums.status.CONCLUDED]:
    EntitySearchConstants.searchFilterableStatuses.CONCLUDED,
  [LayerEnums.status.ARCHIVED]: null,
};

export const filterToArchivedState = {
  [LayerEnums.status.ACTIVE]: false,
  [LayerEnums.status.RUNNING]: false,
  [LayerEnums.status.PAUSED]: false,
  [LayerEnums.status.DRAFT]: false,
  [LayerEnums.status.CONCLUDED]: false,
  [LayerEnums.status.ARCHIVED]: true,
};

export const filterToTypes = {
  [LayerEnums.type.AB]: EntitySearchConstants.searchFilterableLayerTypes.AB,
  [LayerEnums.type.MULTIARMED_BANDIT]:
    EntitySearchConstants.searchFilterableLayerTypes.MULTIARMED_BANDIT,
  [LayerEnums.type.MULTIVARIATE]:
    EntitySearchConstants.searchFilterableLayerTypes.MULTIVARIATE,
  [LayerEnums.type.PERSONALIZATION]:
    EntitySearchConstants.searchFilterableLayerTypes.PERSONALIZATION,
};

export const dashboardTabs = {
  OVERVIEW: 'overview',
  EXPERIMENTS: 'experiments',
  PERSONALIZATIONS: 'personalizations',
};

export default {
  allFilterableActiveStatuses,
  filterToStatuses,
  filterToArchivedState,
  filterToTypes,
  dashboardTabs,
};

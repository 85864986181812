import { getFeatureVariableInteger } from '@optimizely/js-sdk-lab/src/actions';

import Router from 'core/router';

import CurrentLayerActions from 'bundles/p13n/modules/current_layer/actions';
import ExperimentationGroupActions from 'optly/modules/entity/experimentation_group/actions';
import LayerActions from 'optly/modules/entity/layer/actions';
import LayerExperimentActions from 'optly/modules/entity/layer_experiment/actions';

import {
  DEFAULT_MAX_API_FILTERS,
  EntityType,
} from 'bundles/p13n/components/change_history';

/**
 * @description Fetch and resolve with the found Layer if one exists for the provided ID, otherwise reject the promise
 * @param {Number} layerId
 * @returns {Promise} Resolves with Layer for ID if found
 */
function fetchLayerIfValid(layerId) {
  return new Promise((resolve, reject) => {
    LayerActions.fetch(layerId)
      .then(layer => {
        if (!layer) {
          reject(new Error('No Layer found for ID'));
          return;
        }

        resolve(layer);
      })
      .fail(() => reject(new Error('No Layer found for ID')));
  });
}

/**
 * @description Used with Layer ID from route
 *   - If a Layer can be found for layerId, set the "id" key as the current Layer ID.
 *   - Does NOT fetch all associated entities like the centralized RoutingFns.fetchLayerAndSetAsCurrentAndFetchAssociatedEntities does
 *
 * @param {Number} layerId - id to fetch, ensure is valid, and use to set as current layer
 * @param {Function} next - method to call when routing fn is complete
 */
export function fetchLayerAndSetAsCurrent(layerId, next) {
  const parsedLayerId = parseInt(layerId, 10);
  fetchLayerIfValid(parsedLayerId)
    .then(({ id }) => {
      CurrentLayerActions.setCurrentLayerId(id);
      next();
    })
    .catch(() => Router.redirect('/v2'));
}

/**
 * @decription Fetch Web P13N manager entities computed in Change History's withSidebarAndManagerChangeHistory HOF
 * @param {Object} ctx - object that contains context from route (e.g. params) which we also use to set fetchedData
 * @param {Function} next - method from Nuclear route that is called when routing function is complete
 */
export function fetchRequiredDataForWebP13nManagerChangeHistory(ctx, next) {
  const projectId = parseInt(ctx.params.proj_id, 10);
  const parsedCampaignId = parseInt(ctx.params.layer_id, 10);

  // Change History will truncates fitlers to this number if exceeded so no need to fetch more
  const fetchLimit =
    getFeatureVariableInteger(
      'change_history_improvements',
      'max_api_filters',
    ) || DEFAULT_MAX_API_FILTERS;

  Promise.all([
    ExperimentationGroupActions.fetchPage({
      $limit: fetchLimit,
      project_id: projectId,
    }),
    LayerActions.fetch(parsedCampaignId),
    LayerExperimentActions.fetchPage({
      $limit: fetchLimit,
      layer_id: parsedCampaignId,
      project_id: projectId,
    }),
  ])
    .then(([groups, layer, layerExperiments]) => {
      // set ctx.fetchedData for downstream route use (where component is rendered)
      ctx.fetchedData = {
        [EntityType.group.entityType]: [].concat(groups),
        [EntityType.campaign.entityType]: [].concat(layer),
        [EntityType.experiment.entityType]: [].concat(layerExperiments),
      };
      next();
    })
    .catch(() => Router.redirect('/v2'));
}

export default {
  fetchLayerAndSetAsCurrent,
  fetchRequiredDataForWebP13nManagerChangeHistory,
};

import React from 'react';
import PropTypes from 'prop-types';

import { Label, Link, Icon } from 'optimizely-oui';
import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';

import { enums as ViewEnums } from 'optly/modules/entity/view';

import ReactCodeMirror from 'react_components/codemirror';

const TriggerSettingsCard = props => {
  const {
    activationType,
    activationCode,
    apiName,
    onUpdate,
    error,
    snippetGlobalName,
  } = props;

  const onChangeActivationCode = newCode => {
    onUpdate('activation_code', newCode);
  };

  const esOptions = { esversion: 6 };

  return (
    <div>
      {activationType === ViewEnums.activationModes.POLLING && (
        <div
          className="push-double--top"
          data-test-section="polling-activation">
          <p className="weight--bold push-half--bottom">Polling JavaScript</p>
          <div className="soft-half--bottom">
            Supply an expression to return a boolean inside a function.
          </div>
          <ReactCodeMirror
            onChange={onChangeActivationCode}
            testSection="codemirror-polling"
            value={activationCode || ''}
            options={{ lint: esOptions }}
          />
          <div className="flex flex-align--center">
            <span className="flex push-half--right">
              <Icon
                color={brandBlueDark}
                name="circle-exclamation"
                size="small"
              />
            </span>
            Note: This expression will be executed every time the snippet
            loads.&nbsp;
            <Link
              href="https://docs.developers.optimizely.com/web/docs/dynamic-websites#section-polling"
              newWindow={true}>
              Learn more.
            </Link>
          </div>
        </div>
      )}
      {activationType === ViewEnums.activationModes.CALLBACK && (
        <div
          className="push-double--top"
          data-test-section="callback-activation">
          <Label>Callback JavaScript</Label>
          <ReactCodeMirror
            onChange={onChangeActivationCode}
            testSection="codemirror-callback"
            value={activationCode || ''}
            options={{ lint: esOptions }}
          />
        </div>
      )}
      {activationType === ViewEnums.activationModes.MANUAL && (
        <div className="push-double--top" data-test-section="manual-activation">
          <div className="push--top push-half--bottom">
            Embed the code below into your page at the time you want the page to
            activate.&nbsp;
            <a
              href="https://help.optimizely.com/Build_Experiments/Activation_Mode%3A_Activating_an_experiment_dynamically_after_a_page_has_loaded"
              target="_blank"
              rel="noopener noreferrer">
              Learn more.
            </a>
          </div>
          <pre className="lego-attention lego-attention--brand monospace milli">
            {`window['${snippetGlobalName}'] = window['${snippetGlobalName}'] || [];
window['${snippetGlobalName}'].push({
  type: "page",
  pageName: "${apiName}"
});`}
          </pre>
        </div>
      )}
      {error && (
        <div
          className="color--bad-news micro push-half--top"
          data-test-section="trigger-settings-error">
          {error}
        </div>
      )}
    </div>
  );
};

TriggerSettingsCard.propTypes = {
  activationType: PropTypes.string,
  activationCode: PropTypes.string,
  apiName: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  error: PropTypes.string,
  snippetGlobalName: PropTypes.string.isRequired,
};

export default TriggerSettingsCard;

import React from 'react';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import Router from 'core/router';
import NavConstants from 'optly/services/navigation';
import { fns as PermissionsFns } from 'optly/modules/permissions';

import RoutingFns from '../../routing_fns';

const category = 'Profile Settings';

/*
 * Routing functions that ensure required dynamic bundles have been fetched
 * We store the returned dynamic bundle module on the function object itself
 */
function ProfileSection(ctx, next) {
  import(
    /* webpackChunkName: "profile-bundle" */
    'bundles/p13n/sections/profile' // eslint-disable-line
  )
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(ProfileSection, module);
    })
    .then(next);
}

/*
 * Routes for the profile section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/profile',
    handle: [() => Router.redirect('/v2/profile/preferences')],
  },

  {
    // NOTE: DO NOT CHANGE THIS ROUTE. Server-generated emails regarding users changing their email addresses
    // point to this route. See www/models/user.py for more.
    match: '/v2/profile/preferences',
    metadata: { name: 'Profile Preferences', category },
    handle: [
      [
        ProfileSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PROFILE,
        ),
        RoutingFns.fetchUserInfo,
      ],
      RoutingFns.verifyCurrentUserEmailVerified,
      RoutingFns.fetchAlertsTabData,
      (ctx, next) => ProfileSection.pages.preferences.routingSetup(ctx, next),
      RoutingFns.setActiveTab(
        NavConstants.NavItems.PROFILE,
        NavConstants.ProfileTabs.PREFERENCES_TAB,
      ),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          name: 'Profile Preferences',
          next,
        }),
      ctx =>
        RoutingFns.renderMainRegion(
          <ProfileSection.pages.preferences.component
            canonicalPath={ctx.canonicalPath}
          />, // eslint-disable-line
        ),
    ],
  },
  {
    match: '/v2/profile/notifications',
    metadata: { name: 'Profile Notifications', category },
    handle: [
      [
        ProfileSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PROFILE,
        ),
        RoutingFns.setActiveTab(
          NavConstants.NavItems.PROFILE,
          NavConstants.ProfileTabs.NOTIFICATIONS_TAB,
        ),
        RoutingFns.fetchAccessTabData,
      ],
      RoutingFns.fetchAlertsTabData,
      (ctx, next) => ProfileSection.pages.preferences.routingSetup(ctx, next),
      (ctx, next) => ProfileSection.pages.notifications.routingSetup(ctx, next),
      RoutingFns.disallowStatSigNotifications,
      (ctx, next) =>
        RoutingFns.setPageTitle({
          name: 'Profile Notifications',
          next,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ProfileSection.pages.notifications.component />,
        ), // eslint-disable-line
    ],
  },
  {
    match: '/v2/profile/api',
    metadata: { name: 'Profile API Access', category },
    handle: [
      [
        ProfileSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PROFILE,
        ),
        RoutingFns.setActiveTab(
          NavConstants.NavItems.PROFILE,
          NavConstants.ProfileTabs.API_ACCESS_TAB,
        ),
        RoutingFns.fetchAccessTabData,
      ],
      (ctx, next) => ProfileSection.pages.apiAccess.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          name: 'Profile API Access',
          next,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ProfileSection.pages.apiAccess.component />,
        ), // eslint-disable-line
    ],
  },
  {
    match: '/v2/profile/api_tokens_v1',
    metadata: { name: 'Profile API Tokens V1', category },
    handle: [
      [
        ProfileSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PROFILE,
        ),
        RoutingFns.setActiveTab(
          NavConstants.NavItems.PROFILE,
          NavConstants.ProfileTabs.API_TOKENS_V1_TAB,
        ),
        RoutingFns.fetchAccessTabData,
      ],
      (ctx, next) => ProfileSection.pages.apiTokensV1.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          name: 'Profile API Tokens V1',
          next,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ProfileSection.pages.apiTokensV1.component />,
        ), // eslint-disable-line
    ],
  },
];

export default routes;

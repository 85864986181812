// eslint disable below required due to legacy code
import PropTypes from 'prop-types';

import React from 'react';

import { Attention, Button, ButtonRow } from 'optimizely-oui';

import Immutable, { toImmutable } from 'optly/immutable';

import {
  constants as ExperimentSectionConstants,
  fns as ExperimentSectionFns,
} from 'optly/modules/entity/experiment_section';
import { fns as LayerExperimentFns } from 'optly/modules/entity/layer_experiment';
import SectionModuleConstants from 'bundles/p13n/sections/manager_mvt/section_module/constants';
import SectionModuleActionsFns from 'bundles/p13n/sections/manager_mvt/section_module/actions_fns';
import SectionModuleFns from 'bundles/p13n/sections/manager_mvt/section_module/fns';

import { Footer, Title, Wrapper } from 'react_components/dialog';

import CreateSectionForm from './subcomponents/create_section_form';

const REQUIRED_TOTAL_PERCENTAGE =
  ExperimentSectionConstants.REQUIRED_TOTAL_VARIATION_PERCENTAGE_PER_SECTION;

class CreateSection extends React.Component {
  constructor(props) {
    super(props);

    this.onCancelClick = this.onCancelClick.bind(this);
    this.onCreateSectionClick = this.onCreateSectionClick.bind(this);
    this.onFormUpdate = this.onFormUpdate.bind(this);
    this.onRenameSection = this.onRenameSection.bind(this);
    this.onChangeVariation = this.onChangeVariation.bind(this);
    this.onRemoveVariation = this.onRemoveVariation.bind(this);
    this.onAddVariation = this.onAddVariation.bind(this);

    this.state = {
      form: SectionModuleConstants.CREATE_SECTION_FORM_INITIAL_STATE,
      errors: toImmutable({}),
    };
  }

  canSave() {
    return (
      !this.state.isSaving &&
      this.state.errors.isEmpty() &&
      this.getTotalPercentage() === REQUIRED_TOTAL_PERCENTAGE &&
      !this.hasNegativePercentage()
    );
  }

  onRenameSection(name) {
    this.onFormUpdate('name', name);
  }

  onChangeVariation(variationIndex, property, value) {
    let variations = this.state.form.get('variations');
    if (property === 'percentage') {
      property = 'weight';
      value = Number(value) * 100;
    }
    variations = variations.setIn([variationIndex, property], value);
    this.onFormUpdate('variations', variations);
  }

  onRemoveVariation(variationIndex) {
    let variations = this.state.form.get('variations');
    variations = variations.delete(variationIndex);
    variations = LayerExperimentFns.redistributeWithNewVariationTrafficImmutable(
      variations,
    );
    this.onFormUpdate('variations', variations);
  }

  onAddVariation() {
    let variations = this.state.form.get('variations');
    const newVariation = toImmutable({
      name: LayerExperimentFns.suggestedNewVariationName(variations),
      weight: '',
      actions: [],
    });
    variations = variations.push(newVariation);
    variations = LayerExperimentFns.redistributeWithNewVariationTrafficImmutable(
      variations,
    );
    this.onFormUpdate('variations', variations);
  }

  onFormUpdate(property, value) {
    const newInfo = toImmutable({}).set(property, value);

    this.setState(prevState => {
      const updatedInfo = prevState.form.merge(newInfo);
      const errors = ExperimentSectionFns.validateSection(updatedInfo);

      return {
        form: updatedInfo,
        errors,
      };
    });
  }

  onCancelClick() {
    this.props.onCancelClick();
  }

  onCreateSectionClick() {
    const errors = ExperimentSectionFns.validateSection(this.state.form);
    if (!errors.isEmpty()) {
      this.setState({
        errors,
      });
      return;
    }

    this.setState({
      isSaving: true,
    });

    const { currentLayer, destinationLayerExperimentId } = this.props;
    const variations = this.state.form.get('variations');
    const sectionToSave = {
      name: this.state.form.get('name'),
      layer_experiment_id: destinationLayerExperimentId,
      variations: variations.toJS(),
    };

    return SectionModuleActionsFns.createNewSections(
      [sectionToSave],
      currentLayer,
    ).then(() => {
      this.onCancelClick();
      this.setState({
        isSaving: false,
      });
    });
  }

  getTotalPercentage() {
    return ExperimentSectionFns.getTotalPercentageForSection(this.state.form);
  }

  hasNegativePercentage() {
    const variations = this.state.form.get('variations');
    return SectionModuleFns.hasNegativePercentage(variations);
  }

  render() {
    return (
      <Wrapper>
        <Title>Create Section</Title>
        <div>
          <CreateSectionForm
            form={this.state.form}
            errors={this.state.errors}
            onRenameSection={this.onRenameSection}
            onChangeVariation={this.onChangeVariation}
            onRemoveVariation={this.onRemoveVariation}
            onAddVariation={this.onAddVariation}
          />
        </div>
        <div>
          {(this.getTotalPercentage() !== REQUIRED_TOTAL_PERCENTAGE ||
            this.hasNegativePercentage()) && (
            <Attention alignment="left" type="bad-news">
              {this.getTotalPercentage() !== REQUIRED_TOTAL_PERCENTAGE && (
                <div data-test-section="create-section-error-not-100">
                  The traffic distribution total must equal{' '}
                  {REQUIRED_TOTAL_PERCENTAGE}%. Current total is{' '}
                  {this.getTotalPercentage()}%.
                </div>
              )}
              {this.hasNegativePercentage() && (
                <div data-test-section="create-section-error-negative-value">
                  Traffic distribution values cannot be negative.
                </div>
              )}
            </Attention>
          )}
        </div>
        <Footer>
          <div className="flex flex-align--center">
            <div className="oui-sheet__required-indicator cursor--default color--red">
              <span>* Required field</span>
            </div>
            <div className="flex--1">
              <ButtonRow
                rightGroup={[
                  <Button
                    onClick={this.onCancelClick}
                    testSection="create-section-form-cancel"
                    key="cancel">
                    Cancel
                  </Button>,
                  <Button
                    isDisabled={!this.canSave()}
                    style="highlight"
                    onClick={this.onCreateSectionClick}
                    testSection="create-section-form-create"
                    key="create">
                    Create Section
                  </Button>,
                ]}
              />
            </div>
          </div>
        </Footer>
      </Wrapper>
    );
  }
}

CreateSection.propTypes = {
  currentLayer: PropTypes.instanceOf(Immutable.Map).isRequired,
  destinationLayerExperimentId: PropTypes.number.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

export default CreateSection;

import { getters as AdminAccountGetters } from 'optly/modules/admin_account';

import flux from 'core/flux';

import guid from 'optly/utils/guid';
import BundleSplitHelper from 'optly/utils/bundle_split_helper';

import { fns as PermissionsFns } from 'optly/modules/permissions';

import getters from './getters';
import constants from './constants';
import actionTypes from './action_types';

const CONNECTION_ID_SESSION_STORAGE_KEY = 'optimizelyConnectionId';

/**
 * -- FIREBASE STRUCTURE FOR CONCURRENT EDITING SUBSCRIPTIONS --
 * -- https://console.firebase.google.com/u/0/project/optimizely-b353b/database/data --
 *
 *  Subscriptions to an entity are stored on a per userId/connectionId pair:
 *    /<entity_type>/<entity_id>/users/<user_id>/<connection_id>
 *
 *  This allows a single user with multiple tabs to correctly stay subscribed
 *  to an experiment until all tabs for it are closed.
 *
 *  {
 *    <entity_name | field_name>: {
 *      <id associated with name>: {
 *        users: {
 *           <unique connection id for browser tab> : {
 *             email: <users email>,
 *             last_seen: <last subscribed time>
 *           }
 *        },
 *        ...
 *      }
 *    }
 *    <layer_experiments>: {
 *      <layer_experiment_id> : {
 *        users: {
 *          <user.unique_user_id> : {
 *            <connection_id>: {
 *              email: james@optimizely.com,
 *              last_seen: 2pm
 *            }
 *          },
 *          <user.unique_user_id> : {
 *            <connection_id>: {
 *              email: travis@optimizely.com,
 *              last_seen: 2:01pm
 *            }
 *          }
 *        }
 *      }
 *    },
 *    <variations>: {
 *      <variation_id>: {
 *        <user.unique_user_id> : {
 *          <connection_id>: {
 *            email: travis@optimizely.com,
 *            last_seen: 2:01pm
 *          }
 *        }
 *      }
 *    },
 *    <project>: {
 *      <project_id>: {
 *        <user.unique_user_id> : {
 *          <connection_id>: {
 *            email: travis@optimizely.com,
 *            last_seen: 2:01pm
 *          }
 *        }
 *      }
 *    }
 *  }
 */

/**
 * Helper function to determine whether or not subscribing/unsubscribing should be enabled.
 * @returns {Boolean}
 */
function concurrentEditingIsEnabled() {
  return (
    __TEST__ ||
    flux.evaluate([
      AdminAccountGetters.accountPermissions,
      PermissionsFns.canUseConcurrentEditing,
    ])
  );
}

function consoleLog() {
  if (!__TEST__) {
    console.log(...arguments); // eslint-disable-line
  }
}

/**
 * Subscribe to the entity specified by setting our subscription in the firebase store:
 *   /<entity_type>/<entity_id>/users/<user_id>/<connection_id>
 *
 * @param {String} id - The id of the entity to subscribe to.
 * @param {String} type - The type of the entity to subscribe to (Audience, LayerExperiment, Layer, etc...).
 */
export function subscribeToEntity(type, id) {
  if (!concurrentEditingIsEnabled()) {
    // Bail if not currently enabled.
    return null;
  }

  consoleLog(`[CONCURRENCY] subscribeToEntity: ${type} ${id}`); // eslint-disable-line

  return BundleSplitHelper.getFirebaseBundleModules().then(
    ({ firebaseApp: firebase }) => {
      // Ensure firebase has been initialized.
      if (firebase.apps.length === 0) {
        this.initialize(firebase);
      }

      const entityPath = `/${type}/${id}`;
      const userId = flux.evaluate(AdminAccountGetters.uniqueUserId);
      const email = flux.evaluate(AdminAccountGetters.email);
      const connectionId = flux.evaluate(getters.connectionId);
      const subscriptionData = { email, last_seen: Date.now() };

      // The path used to subscribe to the given entity.
      const subscriptionPath = `${entityPath}/users/${userId}/${connectionId}`;
      let subscriptionRef = flux.evaluate(
        getters.firebaseRefByKeypath(subscriptionPath),
      );
      if (!subscriptionRef) {
        subscriptionRef = firebase.database().ref(subscriptionPath);
        // Store this reference so unsubscribe can use it.
        flux.dispatch(actionTypes.SET_FIREBASE_DB_REF, {
          keypath: subscriptionPath,
          ref: subscriptionRef,
        });

        // If our connection drops, unsubscribe
        subscriptionRef.onDisconnect().remove();

        // If we connect (or reconnect), ensure were subscribed
        subscriptionRef.on('value', snapshot => {
        consoleLog(`[CONCURRENCY] Subscription update: ${subscriptionPath}`); // eslint-disable-line
          // Ensure if were reconnecting that our subscription is re-set
          // and were again handling disconnect
          if (!snapshot.val()) {
          consoleLog(`[CONCURRENCY] Resubscribing to: ${subscriptionPath}`); // eslint-disable-line
            // If our connection drops, unsubscribe
            subscriptionRef.onDisconnect().remove();
            subscriptionRef.set(subscriptionData);
          }
        });
      }

    consoleLog(`[CONCURRENCY] Subscribing to: ${subscriptionPath}`); // eslint-disable-line
      subscriptionRef.set(subscriptionData);

      // The path to the object storing all subscribed users to this entity.
      const subscribedUsersPath = `${entityPath}/users`;
      let subscribedUsersRef = flux.evaluate(
        getters.firebaseRefByKeypath(subscribedUsersPath),
      );
      if (!subscribedUsersRef) {
        subscribedUsersRef = firebase.database().ref(subscribedUsersPath);

        // Listen for changes to the subscribed users and update our store accordingly.
        subscribedUsersRef.on('value', snapshot => {
        consoleLog(`[CONCURRENCY] Users update: ${subscribedUsersPath}`); // eslint-disable-line
          flux.dispatch(actionTypes.SET_CURRENT_STATE_FOR_ENTITY, {
            id,
            type,
            currentState: snapshot.val(),
          });
        });

        // Store this reference so unsubscribe can use it.
        flux.dispatch(actionTypes.SET_FIREBASE_DB_REF, {
          keypath: subscribedUsersPath,
          ref: subscribedUsersRef,
        });
      consoleLog(`[CONCURRENCY] Users observe: ${subscribedUsersPath}`); // eslint-disable-line
      }
    },
  );
}

/**
 * Unsubscribe from the entity specified by doing 2 things:
 *   - Remove ourselves from the users list for the entity
 *   - Stop listening to updates for this entity
 *
 * @param {String} id - The id of the entity to unsubscribe from.
 * @param {String} type - The type of the entity to unsubscribe from (Audience, LayerExperiment, Layer, etc...).
 */
export function unsubscribeFromEntity(type, id) {
  if (!concurrentEditingIsEnabled()) {
    // Bail if not currently enabled.
    return null;
  }

  consoleLog(`[CONCURRENCY] unsubscribeFromEntity: ${type} ${id}`); // eslint-disable-line

  return BundleSplitHelper.getFirebaseBundleModules().then(
    ({ firebaseApp: firebase }) => {
      // Ensure firebase has been initialized.
      if (firebase.apps.length === 0) {
        this.initialize(firebase);
      }

      const entityPath = `/${type}/${id}`;
      const userId = flux.evaluate(AdminAccountGetters.uniqueUserId);
      const connectionId = flux.evaluate(getters.connectionId);

      // Stop listening to updates for users at this path
      const subscribedUsersPath = `${entityPath}/users`;
      const subscribedUsersRef = flux.evaluate(
        getters.firebaseRefByKeypath(subscribedUsersPath),
      );
      if (subscribedUsersRef) {
      consoleLog(`[CONCURRENCY] Users unobserve: ${subscribedUsersPath}`); // eslint-disable-line
        subscribedUsersRef.off();
        // Delete the reference to the list of users for this path.
        flux.dispatch(actionTypes.REMOVE_FIREBASE_DB_REF, {
          keypath: subscribedUsersPath,
        });
      }

      // Remove ourselves from the path
      const subscriptionPath = `${entityPath}/users/${userId}/${connectionId}`;
      const subscriptionRef = flux.evaluate(
        getters.firebaseRefByKeypath(subscriptionPath),
      );
      if (subscriptionRef) {
        // Stop checking for connect/reconnect for this subscription
        subscriptionRef.off();

      consoleLog(`[CONCURRENCY] Unsubscribing from: ${subscriptionPath}`); // eslint-disable-line

        // Remove our subscription
        subscriptionRef.remove();

        // Delete the reference to our subscription
        flux.dispatch(actionTypes.REMOVE_FIREBASE_DB_REF, {
          keypath: subscriptionPath,
        });
      }
    },
  );
}

/**
 * Initialize the concurrency module by setting the current
 * connectionId and initializing the firebase app.
 */
export function initialize(firebase) {
  let connectionId = guid();
  const existingConnectionId = this.__getItemFromSessionStorage(
    CONNECTION_ID_SESSION_STORAGE_KEY,
  );
  if (existingConnectionId) {
    connectionId = existingConnectionId;
  } else {
    this.__setItemInSessionStorage(
      CONNECTION_ID_SESSION_STORAGE_KEY,
      connectionId,
    );
  }
  flux.dispatch(actionTypes.SET_CONNECTION_ID, connectionId);

  firebase.initializeApp(constants.firebaseConfig);
}

/**
 * Write item to sessionStorage
 * @param {String} key
 * @param {String} data
 */
export function __setItemInSessionStorage(key, data) {
  window.sessionStorage.setItem(key, data);
}

/**
 * Get item from sessionStorage
 * @param {String} key
 */
export function __getItemFromSessionStorage(key) {
  return window.sessionStorage.getItem(key);
}

export default {
  __getItemFromSessionStorage,
  __setItemInSessionStorage,
  initialize,
  subscribeToEntity,
  unsubscribeFromEntity,
};

const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const actionTypes = require('../action_types');

/**
 * navStore
 * Responsible for the following state management:
 * tracking navigation state
 */
module.exports = Nuclear.Store({
  getInitialState() {
    return toImmutable({
      activeIDETab: null,
      previousView: null,
      iframeLoadComplete: false,
      showCodeAssistant: false,
    });
  },

  initialize() {
    this.on(actionTypes.PLUGINS_UI_SET_IDE_ACTIVE_TAB, setActiveIDETab);
    this.on(actionTypes.PLUGINS_UI_SET_PREVIOUS_VIEW, setPreviousView);
    this.on(
      actionTypes.PLUGINS_UI_SET_IFRAME_LOAD_COMPLETE,
      setIframeLoadComplete,
    );
    this.on(actionTypes.PLUGINS_UI_SHOW_CODE_ASSISTANT, showCodeAssistant);
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Boolean} payload.tabId
 */
function setActiveIDETab(state, payload) {
  return state.set('activeIDETab', payload.tabId);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Boolean} payload.previousView
 */

function setPreviousView(state, { previousView }) {
  return state.set('previousView', previousView);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Boolean} payload.iframeLoadComplete
 */

function setIframeLoadComplete(state, { iframeLoadComplete }) {
  return state.set('iframeLoadComplete', iframeLoadComplete);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Boolean} payload.shouldShowCodeAssistant
 */

function showCodeAssistant(state, { shouldShowCodeAssistant }) {
  return state.set('showCodeAssistant', shouldShowCodeAssistant);
}

import flux from 'core/flux';
import RequestUtil from 'optly/utils/request';
import { optimizelyHRDUrl } from 'optly/utils/url';
import locationHelper from 'optly/location';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

import actionTypes from './action_types';

export const setUserConnected = function(isConnected) {
  flux.dispatch(actionTypes.SET_USER_CONNECTED, isConnected);
};

// TODO: Follow up on the difference between user connected state and project connected state
export const getProjectUserConnectionState = function() {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const url = `api/v1/projects/${projectId}/integrations/jira/connection`;
  return RequestUtil.makeOptlyFetchRequest({
    url,
    type: 'GET',
  }).then(response => {
    setUserConnected(response.authorized);
  });
};

export const getUrlToConnectUser = function(opts = { redirect: true }) {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const currentPath = locationHelper.getPath();
  let url = `integrations/start-flow?proj_id=${projectId}&integration_id=jira`;
  if (opts.redirect) {
    url += `&redirect_uri=${currentPath}`;
  }
  return optimizelyHRDUrl(url);
};

export const getUserDetails = function() {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const url = `api/v1/projects/${projectId}/integrations/jira/api/myself`;
  return RequestUtil.makeOptlyFetchRequest({ url });
};

export const getAllRecentIssues = function() {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const url = `api/v1/projects/${projectId}/integrations/jira/api/issue/picker`;
  return RequestUtil.makeOptlyFetchRequest({
    url,
    type: 'GET',
    headers: {
      Accept: 'application/json',
    },
  }).then(response => {
    if (
      !(
        response.sections &&
        response.sections[0] &&
        response.sections[0].issues
      )
    ) {
      return;
    }

    const issuesToGetIds = response.sections[0].issues.slice(0, 10);
    const issueKeys = issuesToGetIds.map(issue => issue.key);
    return this.getIssueIdsByKeys(issueKeys).then(issueResponse => {
      flux.dispatch(actionTypes.SET_RECENT_ISSUES, issueResponse.issues);
    });
  });
};

function makeJQLRequest(jql, maxResults, pageOffset) {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const url = `api/v1/projects/${projectId}/integrations/jira/api/search?maxResults=${maxResults}&startAt=${pageOffset ||
    0}&jql=${jql}`;

  return RequestUtil.makeOptlyFetchRequest({
    url,
    type: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
}

export const searchByKey = function(query, maxResults, pageOffset) {
  const jql = `(key="${query}")`;
  return makeJQLRequest(jql, maxResults, pageOffset);
};

export const searchByProject = function(query, maxResults, pageOffset) {
  const jql = `(project="${query}")`;
  return makeJQLRequest(jql, maxResults, pageOffset);
};

export const searchByText = function(query, maxResults, pageOffset) {
  const jql = `(summary~"${query}"%20OR%20description~"${query}"%20OR%20comment~"${query}")`;
  return makeJQLRequest(jql, maxResults, pageOffset);
};

export const getIssueIdsByKeys = function(issueKeys) {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const url = `api/v1/projects/${projectId}/integrations/jira/api/search?jql=key+in+(${issueKeys.join(
    ',',
  )})`;
  return RequestUtil.makeOptlyFetchRequest({
    url,
    type: 'GET',
  });
};

export const getIssueByKey = issueKey => {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  return RequestUtil.makeOptlyFetchRequest({
    url: `api/v1/projects/${projectId}/integrations/jira/api/issue/${issueKey}`,
    type: 'GET',
  });
};

export default {
  getProjectUserConnectionState,
  searchByText,
  searchByKey,
  searchByProject,
  getAllRecentIssues,
  getIssueIdsByKeys,
  getUrlToConnectUser,
  setUserConnected,
  getUserDetails,
  getIssueByKey,
};

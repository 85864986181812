/**
 * Filter for formatting time from a date string
 *
 * @author Asa Schachar (asa@optimizely.com)
 */
const tr = require('optly/translate');

module.exports = function(dateString) {
  return tr.date(dateString).format('LTS');
};

import { getFeatureVariableInteger } from '@optimizely/js-sdk-lab/src/actions';

import Router from 'core/router';

import CurrentLayerActions from 'bundles/p13n/modules/current_layer/actions';
import ExperimentationGroupActions from 'optly/modules/entity/experimentation_group/actions';
import LayerActions from 'optly/modules/entity/layer/actions';
import LayerExperimentActions from 'optly/modules/entity/layer_experiment/actions';

import {
  DEFAULT_MAX_API_FILTERS,
  EntityType,
} from 'bundles/p13n/components/change_history';

/**
 * @description Fetch and resolve with the found LayerExperiment if one exists for the provided ID, otherwise reject the promise
 * @param {Number} layerExperimentId
 * @returns {Promise} Resolves with Layer for ID if found
 */
function fetchLayerExperimentIfValid(layerExperimentId) {
  return new Promise((resolve, reject) => {
    LayerExperimentActions.fetch(layerExperimentId)
      .then(layerExperiment => {
        if (!layerExperiment) {
          reject(new Error('No LayerExperiment found for ID'));
          return;
        }

        resolve(layerExperiment);
      })
      .fail(() => reject(new Error('No LayerExperiment found for ID')));
  });
}

/**
 * @description Used with LayerExperiment ID from route
 *   - If a LayerExperiment can be found for layerExperimentId, set the "layer_id" key as the current Layer ID.
 *   - Fetch the Layer via the LayerExperiment's "layer_id" key
 *   - Does NOT fetch all associated entities like the centralized RoutingFns.fetchLayerAndSetAsCurrentAndFetchAssociatedEntities does
 *
 * @param {Number} layerExperimentId - id to fetch, ensure is valid, and use to set as current layer
 * @param {Function} next - method to call when routing fn is complete
 */
export function fetchLayerExperimentIfValidAndSetLayerIdAsCurrent(
  layerExperimentId,
  next,
) {
  const parsedLayerExperimentId = parseInt(layerExperimentId, 10);
  fetchLayerExperimentIfValid(parsedLayerExperimentId)
    // eslint-disable-next-line camelcase
    .then(({ layer_id }) => {
      CurrentLayerActions.setCurrentLayerId(layer_id);
      return LayerActions.fetch(layer_id).then(next);
    })
    .catch(() => Router.redirect('/v2'));
}

/**
 * @decription Fetch Web AB manager entities computed in Change History's withSidebarAndManagerChangeHistory HOF
 * @param {Object} ctx - object that contains context from route (e.g. params) which we also use to set fetchedData
 * @param {Function} next - method from Nuclear route that is called when routing function is complete
 */
export function fetchRequiredDataForWebAbManagerChangeHistory(ctx, next) {
  const projectId = parseInt(ctx.params.proj_id, 10);
  const parsedExperimentId = parseInt(ctx.params.experiment_id, 10);

  // Change History will truncates fitlers to this number if exceeded so no need to fetch more
  const fetchLimit =
    getFeatureVariableInteger(
      'change_history_improvements',
      'max_api_filters',
    ) || DEFAULT_MAX_API_FILTERS;

  Promise.all([
    LayerExperimentActions.fetch(parsedExperimentId),
    ExperimentationGroupActions.fetchPage({
      $limit: fetchLimit,
      project_id: projectId,
    }),
  ])
    .then(([layerExperiment, groups]) => {
      // set ctx.fetchedData for downstream route use (where component is rendered)
      ctx.fetchedData = {
        [EntityType.group.entityType]: [].concat(groups),
        [EntityType.experiment.entityType]: [].concat(layerExperiment),
      };
      next();
    })
    .catch(() => Router.redirect('/v2'));
}

export default {
  fetchLayerExperimentIfValidAndSetLayerIdAsCurrent,
  fetchRequiredDataForWebAbManagerChangeHistory,
};

import { isNumber } from 'lodash';
import Immutable, { toImmutable, toJS } from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';
import sprintf from 'sprintf';
import ui from 'core/ui';

import { actions as OptimizelySdkActions } from '@optimizely/js-sdk-lab';
import { audienceMatchTypes } from 'optly/modules/entity/audience/enums';
import FeatureEnums from 'bundles/p13n/sections/features/section_module/enums';
import ComponentModuleFns from 'bundles/p13n/sections/oasis_experiment_manager/components/experiment_dialog/component_module/fns';
import FeatureManagerFns from 'bundles/p13n/modules/feature_manager/fns';
import SectionModuleFns from 'bundles/p13n/sections/oasis_experiment_manager/section_module/fns';
import ExperimentationGroupConstants from 'optly/modules/entity/experimentation_group/constants';

// components
import { Button, ButtonRow, Code } from 'optimizely-oui';
import { Footer, Title, Wrapper } from 'react_components/dialog';
import AudienceCombinationsBuilder from 'bundles/p13n/components/audience_combinations_builder';
import CodeSamplePicker from 'bundles/p13n/components/code_sample_picker';
import GroupTrafficAllocation from 'bundles/p13n/components/traffic_allocation/group_traffic_allocation';
import LoadingOverlay from 'react_components/loading_overlay';
import { Form, formPropType } from 'react_components/form';

import MetricsPickerWrapper from '../../components/metrics_picker_wrapper';
import ExperimentVariations from './subcomponents/experiment_variations';
import ExperimentDetails from './subcomponents/experiment_details';

@Form({
  mapPropsToFormData: ({ experiment }) =>
    toImmutable({
      // TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
      audience_conditions_json: experiment.get('audience_conditions_json'),
      selectedGroup: experiment.get(
        'group_id',
        ExperimentationGroupConstants.NONE_GROUP_ID,
      ),
      percentageIncluded: experiment.get('percentage_included', 0),
    }),
  debounceTimeout: __TEST__ ? 0 : 300,
})
class FeatureExperimentDialog extends React.Component {
  static componentId = 'feature-experiment-dialog';

  static propTypes = {
    activeGroups: PropTypes.instanceOf(Immutable.List).isRequired,
    canCreateLayerExperiment: PropTypes.bool,
    canEditExperiment: PropTypes.bool,
    canUpdateLayerExperiment: PropTypes.bool,
    currentProjectExperiments: PropTypes.instanceOf(Immutable.List).isRequired,
    currentProjectFeatures: PropTypes.instanceOf(Immutable.List),
    environmentsAuthorizedAndSupported: PropTypes.bool.isRequired,
    experiment: PropTypes.instanceOf(Immutable.Map).isRequired,
    isEditing: PropTypes.bool,
    isExperimentLive: PropTypes.bool,
    isMobileOnly: PropTypes.bool,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    workingMetrics: PropTypes.instanceOf(Immutable.List),
    form: formPropType.isRequired,
  };

  static defaultProps = {
    canEditExperiment: true,
  };

  constructor(props) {
    super(props);
    const { experiment } = props;

    this.state = {
      experimentKeySetManually: false,
      isSaving: false,
      isValidAudienceConfig: true,
      experiment,
    };
  }

  allVariationKeysValid = () =>
    this.state.experiment
      .get('variations')
      .every(variation =>
        ComponentModuleFns.ensureVariationKeyValid(variation.get('api_name')),
      );

  canDeleteVariation = () => {
    const { isExperimentLive } = this.props;

    return !isExperimentLive;
  };

  canSave = () => {
    // make sure (1) we can only have 1 save in progress and (2) user has permission to save/create
    const { isSaving } = this.state;

    const {
      canCreateLayerExperiment,
      canUpdateLayerExperiment,
      isEditing,
    } = this.props;

    if (
      isSaving ||
      (isEditing && !canUpdateLayerExperiment) ||
      (!isEditing && !canCreateLayerExperiment)
    ) {
      return false;
    }

    const validations = this.getValidations();
    return validations.every(result => result);
  };

  getValidations = () => [
    !!this.state.experiment.get('feature_flag_id'),
    !ComponentModuleFns.hasNegativeVariationValues(
      this.state.experiment.get('variations'),
    ),
    !this.props.form.field('percentageIncluded').getErrors().hasError,
    ComponentModuleFns.ensureExperimentKeyValid(
      this.state.experiment.get('key'),
    ),
    ComponentModuleFns.ensureVariableValuesValid(
      this.state.experiment,
      this.props.currentProjectFeatures,
    ),
    ComponentModuleFns.ensureVariationWeightsTotal100(
      this.state.experiment.get('variations'),
    ),
    SectionModuleFns.ensureVariationKeysUnique(
      this.state.experiment.get('variations'),
    ),
    this.allVariationKeysValid(),
    this.isExperimentKeyUnique(),
    this.isFeatureAssignmentValid(this.state.experiment.get('feature_flag_id')),
    this.props.workingMetrics.size,
    this.state.isValidAudienceConfig,
  ];

  /**
   * Function that will be called with the following args when AudienceCombinationsBuilder is updated
   *
   * @param {Object} config
   * @param {Immutable.List} config.audienceConditions
   * @param {Boolean} config.isValidAudienceConfig
   */
  handleAudienceSelectionChange = ({
    audienceConditions,
    isValidAudienceConfig,
  }) => {
    const { form } = this.props;

    // TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
    form.field('audience_conditions_json').setValue(audienceConditions);

    this.setState({
      isValidAudienceConfig,
    });
  };

  handleFeatureSelected = selectedFeature => {
    const { experiment, experimentKeySetManually } = this.state;

    // update the experiment state with the selected feature flag
    let updatedExperiment = experiment.set(
      'feature_flag_id',
      selectedFeature.get('id'),
    );
    updatedExperiment = ComponentModuleFns.attachFeatureVariablesToExperiment(
      selectedFeature,
      updatedExperiment,
    );

    // if the experiment key hasn't been manually defined, then pre-populate it
    if (!experimentKeySetManually) {
      const featureName = selectedFeature.get('api_name');
      if (featureName.length > 59) {
        updatedExperiment = updatedExperiment.set('key', featureName);
      } else {
        updatedExperiment = updatedExperiment.set('key', `${featureName}_test`);
      }
    }
    this.setState({ experiment: updatedExperiment });
  };

  isExperimentKeyUnique = () => {
    // suspend this validation while saving is in progress
    const { currentProjectExperiments } = this.props;

    const { experiment, isSaving } = this.state;

    if (isSaving) {
      return true;
    }

    return ComponentModuleFns.ensureExperimentKeyUnique(
      experiment,
      currentProjectExperiments,
    );
  };

  isFeatureAssignmentValid = featureId => {
    // suspend this validation while saving is in progress
    const {
      currentProjectExperiments,
      environmentsAuthorizedAndSupported,
    } = this.props;

    const { experiment, isSaving } = this.state;

    if (isSaving) {
      return true;
    }

    if (!isNumber(featureId)) {
      // Must select a feature before saving
      return false;
    }

    return FeatureManagerFns.isFeatureAssignmentValid(
      experiment,
      currentProjectExperiments,
      environmentsAuthorizedAndSupported,
    );
  };

  onCancelClick = () => {
    const { onCancel } = this.props;

    onCancel();
  };

  onSave = () => {
    const { onSave, workingMetrics, form, isEditing } = this.props;

    const { experiment } = this.state;

    const updatedExperiment = experiment.merge({
      // TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
      audience_conditions_json: form
        .field('audience_conditions_json')
        .getValue(),
      percentage_included: form.field('percentageIncluded').getValue(),
      group_id: form.field('selectedGroup').getValue(),
    });

    this.setState({
      isSaving: true,
    });

    const saveExperimentPromise = onSave(
      toJS(updatedExperiment),
      workingMetrics.toJS(),
    )
      .then(() => {
        if (!isEditing) {
          OptimizelySdkActions.track('fs_experiment_created');
          OptimizelySdkActions.track('fs_feature_experiment_created');
        }
      })
      .always(() => {
        this.setState({
          isSaving: false,
        });
      });

    ui.loadingWhen('save-feature-experiment', saveExperimentPromise);
  };

  updateExperimentProperty = (property, value) => {
    this.setState(prevState => ({
      experiment: prevState.experiment.set(property, value),
      experimentKeySetManually:
        !prevState.experimentKeySetManually && property === 'key',
    }));
  };

  renderCodeBlock = () => {
    const { isMobileOnly } = this.props;

    return (
      <CodeSamplePicker
        description={tr(
          'Choose your preferred SDK to view sample code. Copy and paste the following code into your application.',
        )}
        getCodeSample={this.renderFeatureCode}
        showMobileOnly={isMobileOnly}
        title={tr('Example Code')}
      />
    );
  };

  renderFeatureCode = codeSampleLanguage => {
    const { currentProjectFeatures } = this.props;

    const { experiment } = this.state;

    const selectedFeatureFlagId = experiment.get('feature_flag_id');
    const selectedFeature = currentProjectFeatures.find(
      aFeature => aFeature.get('id') === selectedFeatureFlagId,
    );
    const featureKey = selectedFeature.get('api_name');
    const variables = selectedFeature.get('variables');

    let codeBlock = sprintf(
      FeatureEnums.FEATURE_CODE_BLOCKS[codeSampleLanguage],
      featureKey,
    );
    if (featureKey === '') {
      codeBlock = FeatureEnums.FEATURE_CODE_BLOCKS_DEFAULT[codeSampleLanguage];
    } else if (variables.size) {
      variables.forEach(variable => {
        if (variable.get('archived') !== true) {
          const variableName = variable.get('api_name');
          // make adjustments for hyphens and keys that begin with numbers
          let variableKey = variableName.replace(/-/gi, '_');
          if (SectionModuleFns.variableBeginsWithNumber(variableKey)) {
            variableKey = `variable_${variableKey}`;
          }
          const variableType = variable.get('type');
          codeBlock += `\n${sprintf(
            FeatureEnums.VARIABLE_CODE_BLOCKS[codeSampleLanguage][variableType],
            variableKey,
            featureKey,
            variableName,
          )}`;
        }
      });
    }

    return (
      <Code
        isHighlighted={true}
        hasCopyButton={true}
        language={codeSampleLanguage}
        type="block"
        testSection="feature-code-sample">
        {codeBlock}
      </Code>
    );
  };

  render() {
    const {
      activeGroups,
      canEditExperiment,
      currentProjectExperiments,
      currentProjectFeatures,
      environmentsAuthorizedAndSupported,
      isEditing,
      form,
    } = this.props;

    const { experiment } = this.state;

    let selectedFeature = null;
    const selectedFeatureFlagId = experiment.get('feature_flag_id');
    if (selectedFeatureFlagId) {
      selectedFeature = currentProjectFeatures.find(
        aFeature => aFeature.get('id') === selectedFeatureFlagId,
      );
    }

    // Don't show feature validation error unless a feature is selected.
    // The error says 'Feature is already in use by another running experiment
    // in this project.', which doesn't make sense if no feature is selected.
    const showFeatureValidationError =
      isNumber(selectedFeatureFlagId) &&
      !FeatureManagerFns.isFeatureAssignmentValid(
        experiment,
        currentProjectExperiments,
        environmentsAuthorizedAndSupported,
      );

    const selectedGroupField = form.field('selectedGroup');
    const percentageIncludedField = form.field('percentageIncluded');

    // TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
    return (
      <Wrapper>
        <LoadingOverlay loadingId="save-feature-experiment">
          <Title>
            {isEditing ? tr('Edit Feature Test') : tr('New Feature Test')}
          </Title>
          <div data-test-section="feature-experiment-dialog">
            <div className="push-quad--bottom">
              <ExperimentDetails
                experiment={experiment}
                handleFeatureSelected={this.handleFeatureSelected}
                isEditing={isEditing}
                updateExperimentProperty={this.updateExperimentProperty}
                showFeatureValidationError={showFeatureValidationError}
                showUniqueKeyError={!this.isExperimentKeyUnique()}
              />
            </div>
            <div className="push-quad--bottom">
              <GroupTrafficAllocation
                activeGroups={activeGroups}
                entity={experiment}
                selectedGroupField={selectedGroupField}
                percentageIncludedField={percentageIncludedField}
                isFullstackExperiment={true}
              />
            </div>
            <div className="push-quad--bottom">
              <ExperimentVariations
                allVariationKeysUnique={SectionModuleFns.ensureVariationKeysUnique(
                  experiment.get('variations'),
                )}
                canDelete={this.canDeleteVariation()}
                feature={selectedFeature}
                hasInvalidVariableValues={
                  !ComponentModuleFns.ensureVariableValuesValid(
                    experiment,
                    currentProjectFeatures,
                  )
                }
                updateExperimentProperty={this.updateExperimentProperty}
                variations={experiment.get('variations')}
              />
            </div>
            <div className="push-quad--bottom">
              <AudienceCombinationsBuilder
                audienceConditions={form
                  .field('audience_conditions_json')
                  .getValue()}
                canEditAudience={canEditExperiment}
                defaultAudienceMatchType={audienceMatchTypes.ANY_AUDIENCES}
                onSelectionChange={this.handleAudienceSelectionChange}
              />
            </div>
            <MetricsPickerWrapper />
            {!!selectedFeature && this.renderCodeBlock(selectedFeature)}
          </div>
          <Footer>
            <ButtonRow
              rightGroup={[
                <Button
                  onClick={this.onCancelClick}
                  testSection="feature-experiment-form-cancel"
                  key="cancel">
                  Cancel
                </Button>,
                <Button
                  isDisabled={!this.canSave()}
                  style="highlight"
                  onClick={this.onSave}
                  testSection="feature-experiment-form-save"
                  key="save">
                  {isEditing ? 'Save Experiment' : 'Create Experiment'}
                </Button>,
              ]}
            />
          </Footer>
        </LoadingOverlay>
      </Wrapper>
    );
  }
}

export default FeatureExperimentDialog;

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon } from '@optimizely/axiom';

import UrlHelper from 'optly/services/url_helper';

const ManageMetricsButton = ({ projectId }) => {
  return (
    <a
      href={UrlHelper.metricsHubMetricsHome(projectId)}
      data-test-section="manage-metrics-button"
      className="flex items-center"
      style={{ fontSize: '13px' }}>
      Manage Metrics
      <ButtonIcon
        iconName="arrow-up-right-from-square"
        size="small"
        style="unstyled"
        className="ml-1"
      />
    </a>
  );
};

ManageMetricsButton.propTypes = {
  projectId: PropTypes.number.isRequired,
};

export default ManageMetricsButton;

/**
 * Returns whether or not the user is signed in for the first time.
 */
export const isFirstSignin = ['p13n/profileDetails', 'isFirstSignin'];
/**
 * Returns whether or not the ProfileDetailsDialog is in the foreground.
 */
export const isDialogVisible = ['p13n/profileDetails', 'isDialogVisible'];
/**
 * Returns whether or not the user has provided necessary information.
 */
export const requiredInfoWasSupplied = [
  'p13n/profileDetails',
  'requiredInfoWasSupplied',
];

export default {
  isFirstSignin,
  isDialogVisible,
  requiredInfoWasSupplied,
};

/**
 * Truncates string and adds ellipses. Must be used in v-html for &hellip; to be escaped.
 * @param {String} value
 * @param {Integer} length
 * @return {String}
 */
module.exports = function(value, length) {
  if (value && value.length > length) {
    return `${value.substring(0, length)}...`;
  }
  return value;
};

import flux from 'core/flux';

import {
  actions as EnvironmentsActions,
  getters as EnvironmentsGetters,
} from 'optly/modules/entity/environment';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

export function loadEntities(ctx, next) {
  EnvironmentsActions.fetchAll({
    project_id: flux.evaluate(CurrentProjectGetters.id),
  }).then(() => next());
}

export default {
  loadEntities,
};

const $ = require('jquery');
const keyboardEnums = require('optly/utils/keyboard_enums');

/**
 * Module that is instantiated on a text input
 * Provides <select> element like behavior, eg when a user focuses it will
 * show a dropdown of values to select
 *
 * Note: "suggestive" is used with the "tending to suggest an idea." denotation
 */

/**
 * @param inputEl {Element|jQueryObject}
 * @param suggestions {object.<(string|number), (string|number)>}
 * @constructor
 */
const SuggestiveTextInput = function(inputEl, suggestions) {
  this.textInput = $(inputEl);
  this.suggestions = suggestions || {};
  this.suggestionsList = null;

  this.textInput.on('click', event => {
    this.showSuggestionsList();
  });

  // handle showing the suggestions list when tabbing to input
  // also handle up/down selected item movement
  this.textInput.on('keyup', event => {
    if (event.keyCode === keyboardEnums.TAB) {
      this.showSuggestionsList();
    } else if (event.keyCode === keyboardEnums.UP) {
      this.moveSelectedItem('up');
    } else if (event.keyCode === keyboardEnums.DOWN) {
      this.moveSelectedItem('down');
    }
  });

  // prevent enter from submitting the form when suggestionlist is open
  this.textInput.on('keypress', event => {
    if (event.keyCode === keyboardEnums.ENTER) {
      let retValue = true;
      if (this.suggestionsList) {
        retValue = false;
      }
      this.chooseSelectedItem();
      return retValue;
    }
  });

  // hide the suggestions list when tabbing out of field
  this.textInput.on('keydown', event => {
    if (event.keyCode === keyboardEnums.TAB) {
      this.hideSuggestionsList();
    }
  });

  // handle hiding the suggestions list when clicking outside
  $(document).on('click', event => {
    if (
      !this.isSuggestionsListShown() ||
      this.suggestionsList.get(0).contains(event.target) ||
      this.textInput.get(0).contains(event.target)
    ) {
      // ignore clicks inside the suggestions list and text input
      return;
    }

    this.hideSuggestionsList();
  });
};

/**
 * @private
 * @const
 * @type {string}
 */
const SUGGESTION_LIST_CLASS = 'suggestion-list';

/**
 * @private
 * @const
 * @type {string}
 */
const SUGGESTION_LIST_ITEM_CLASS = 'suggestion-list-item';

/**
 * @private
 * @const
 * @type {string}
 */
const SELECTED_ITEM_CLASS = 'selected';

/**
 * @private
 * @const
 * @type {string}
 */
const VALUE_DATA_KEY = 'suggestion-list-value';

/**
 * @param {Object.<string, string>} suggestions
 */
SuggestiveTextInput.prototype.setSuggestions = function(suggestions) {
  this.suggestions = suggestions;
};

/**
 * Creates and shows a suggestionsList if one is not present
 */
SuggestiveTextInput.prototype.showSuggestionsList = function() {
  if (this.isSuggestionsListShown() || $.isEmptyObject(this.suggestions)) {
    // return if already showing or there are no suggestions
    return;
  }

  let selectedItem = null;
  const offsetPos = this.textInput.offset();
  const listWidth = this.textInput.get(0).offsetWidth;
  const offsetTop = this.textInput.get(0).offsetHeight + offsetPos.top;

  const $wrapper = $('<ul></ul>')
    .addClass(SUGGESTION_LIST_CLASS)
    .css('width', listWidth)
    .css('position', 'absolute')
    .css('left', offsetPos.left)
    .css('top', offsetTop)
    // bind event delegation for list item clicks
    .on('click', `.${SUGGESTION_LIST_ITEM_CLASS}`, event => {
      // set the text input value to the clicked value
      this.chooseItem(event.target);
    })
    // remove the selected item class when hovering over other elements
    .on('hover', `.${SUGGESTION_LIST_ITEM_CLASS}`, event => {
      this.addSelectedItemClass($(event.target));
    })
    .appendTo(document.body);

  // add the list items to list
  $.each(this.suggestions, (label, value) => {
    const $li = $(`<li>${label}</li>`)
      .data(VALUE_DATA_KEY, value)
      .addClass(SUGGESTION_LIST_ITEM_CLASS)
      .appendTo($wrapper);

    if (value == this.textInput.val()) { //eslint-disable-line
      // the list item is currently selected
      selectedItem = $li;
    }
  });

  this.suggestionsList = $wrapper;

  if (selectedItem) {
    this.addSelectedItemClass(selectedItem);
    // scroll so that the selected item is in view
    this.scrollToSelectedItem(selectedItem);
  }
};

/**
 * Takes a selectedItem in the suggestionsList and scrolls the list to ensure that the
 * selected item is properly in view
 *
 * @private
 * @param {jQueryObject} selectedItem
 */
SuggestiveTextInput.prototype.scrollToSelectedItem = function(selectedItem) {
  if (!this.isSuggestionsListShown()) {
    return;
  }

  const selectedItemHeight = selectedItem.get(0).offsetHeight;
  const suggestionsListHeight = this.suggestionsList.get(0).offsetHeight;
  // the selected items offset (relative to document) - the suggestionsList offset (relative to document)
  const posTop = selectedItem.position().top;
  const posBottom = selectedItem.position().top + selectedItemHeight;
  // the true distance between the beginning of the suggestionsList and the top of the selected items bounding box
  const offsetTop = selectedItem.get(0).offsetTop;

  const topThreshold = 0;
  const bottomThreshold = suggestionsListHeight;
  const offsetToMiddle = 0.4 * suggestionsListHeight;

  if (posBottom > bottomThreshold || posTop < topThreshold) {
    // scroll to make sure the newly selected item is in view
    this.suggestionsList.scrollTop(offsetTop - offsetToMiddle);
  }
};

SuggestiveTextInput.prototype.hideSuggestionsList = function() {
  if (this.isSuggestionsListShown()) {
    this.suggestionsList.remove();
    this.suggestionsList = null;
  }
};

/**
 * Moves the currently selected li item up or down
 * @private
 * @param {string} dir 'up' or 'down
 */
SuggestiveTextInput.prototype.moveSelectedItem = function(dir) {
  if (!this.isSuggestionsListShown()) {
    return;
  }

  let index;
  const $items = this.suggestionsList.find(`.${SUGGESTION_LIST_ITEM_CLASS}`);
  const $selected = $items.filter(`.${SELECTED_ITEM_CLASS}`);

  if ($selected.length === 0) {
    // no item selected, if down choose the first item
    // if up choose the last item in list
    index = dir === 'down' ? -1 : $items.length;
  } else {
    index = $items.index($selected);
  }

  if (dir === 'down') {
    // if the current item is at the end of list set index to first item position
    index = index === $items.length - 1 ? 0 : index + 1;
  } else {
    // if the current item is at the beginning of list set index to last item position
    index = index === 0 ? $items.length - 1 : index - 1;
  }

  // always show the selected item in scroll viewport
  const selectedItem = $items.eq(index);
  this.addSelectedItemClass(selectedItem);
  this.scrollToSelectedItem(selectedItem);
};

SuggestiveTextInput.prototype.addSelectedItemClass = function(selectedItem) {
  const listItems = this.suggestionsList.find(`.${SUGGESTION_LIST_ITEM_CLASS}`);
  listItems.removeClass(SELECTED_ITEM_CLASS);
  selectedItem.addClass(SELECTED_ITEM_CLASS);
};

/**
 * Chooses the element with the SELECTED_ITEM_CLASS
 * @private
 */
SuggestiveTextInput.prototype.chooseSelectedItem = function() {
  if (!this.suggestionsList) {
    return;
  }
  const $selected = this.suggestionsList.find(`.${SELECTED_ITEM_CLASS}`);
  if ($selected.length > 0) {
    this.chooseItem($selected);
  }
};

/**
 * Reads value from an suggestionsList element and sets text input value
 * @private
 * @param {Element|jQueryObject} element
 */
SuggestiveTextInput.prototype.chooseItem = function(element) {
  const $el = $(element);
  if ($el.length === 0) {
    return;
  }

  this.textInput.val(String($el.data(VALUE_DATA_KEY)));
  this.textInput.trigger('change');
  this.hideSuggestionsList();
};

/**
 * @private
 * @return {boolean}
 */
SuggestiveTextInput.prototype.isSuggestionsListShown = function() {
  return !!this.suggestionsList;
};

module.exports = SuggestiveTextInput;

import actions from './actions';
import enums from './enums';
import getters from './getters';

export { actions, enums, getters };

export default {
  actions,
  enums,
  getters,
};

const _ = require('lodash');

const baseComponent = require('./base');

const ui = require('core/ui').default;

const template = require('./select_campaign.html');

/**
 * Third Party Condition Campaign Field Component
 * Responsible for rendering the configuration UI for a third-party
 * campaign condition
 */
module.exports = _.extend({}, baseComponent, {
  template,

  data: {
    campaignValues: null,
    selectedCampaignIndex: null,
  },

  methods: {
    /**
     * Handles a campaign change
     */
    onSelectedCampaignChange() {
      this.updateConditionValue();
    },

    /**
     * Restores initially selected options.
     * Expects the form: [campaignValue]
     */
    restoreSelectedOptions() {
      const campaignId = this.condition.value.toString();

      this.selectedCampaignIndex = _.findIndex(
        this.config.values,
        campaign => campaign.value.toString() === campaignId,
      );
    },

    /**
     * Set values for campaign dropdown
     */
    setCampaignValues() {
      this.campaignValues = this.config.values;
    },

    /**
     * Set default / initial values for the component
     */
    setDefaultValue() {
      if (this.condition.value) {
        this.restoreSelectedOptions();
      } else {
        this.selectedCampaignIndex = -1;
        this.updateConditionValue();
      }
    },

    /**
     * Updates the condition value used by the component / code mode
     */
    updateConditionValue() {
      if (this.selectedCampaignIndex !== -1) {
        const selectedCampaign = this.config.values[this.selectedCampaignIndex];
        this.condition.value = selectedCampaign.value.toString();
      } else {
        this.condition.value = '';
      }
    },
  },

  created() {
    this.campaignValues = [];
  },

  ready() {
    baseComponent.ready.call(this);
    this.setCampaignValues();
    this.setDefaultValue();

    // Notify user if conditions weren't loaded
    if (!this.config.values || !this.config.values.length) {
      ui.showNotification({
        message: tr(
          'Optimizely is unable to access any data from your AdWords account. Please connect an account that contains ad campaigns/groups.',
        ),
      });
    }
  },
});

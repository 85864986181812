import Immutable from 'optly/immutable';

import {
  getFeatureVariable,
  isFeatureEnabled,
} from 'optly/modules/optimizely_champagne/getters';

import AudienceGetters from 'optly/modules/entity/audience/getters';
import EditorGetters from 'bundles/p13n/modules/editor/getters';
import FilterableTable from 'optly/modules/filterable_table';
import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import LayerExperimentGetters from 'optly/modules/entity/layer_experiment/getters';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
import ExperimentationGroup from 'optly/modules/entity/experimentation_group';
import UrlHelper from 'optly/services/url_helper';
import CampaignManagerEnums from 'bundles/p13n/modules/campaign_manager/enums';

import fns from './fns';

const TABLE_ID = FilterableTable.enums.tableIds.P13N_CAMPAIGN_OVERVIEW_DASH;

const experimentReachMap = ['sections/campaign_overview/experiment_reach'];

export const canUpdateLayer = [
  CurrentProjectGetters.project,
  PermissionsModuleFns.canUpdateLayer,
];

// Exported for testing
export const __allExperiments = [
  LayerExperimentGetters.entityCache,
  AudienceGetters.entityCache,
  CurrentLayerGetters.liveCommit,
  CurrentLayerGetters.layer,
  canUpdateLayer,
  CurrentLayerGetters.liveCommitTag,
  fns.getExperimentCardData,
];

const filteredExperiments = [
  CurrentLayerGetters.layer,
  __allExperiments,
  LayerExperimentGetters.entityCache,
  FilterableTable.getters.stringFilter(TABLE_ID),
  FilterableTable.getters.fieldFilter(TABLE_ID, 'status'),
  (layer, experiments, experimentsCache, stringFilter, statusFilter) =>
    fns.filterExperiments(
      experiments,
      experimentsCache,
      stringFilter,
      statusFilter,
    ),
];

export const experiments = [
  filteredExperiments,
  experimentReachMap,
  (experimentsToMap, reachMap) =>
    experimentsToMap.map(exp => {
      const reach = reachMap.get(exp.get('id'));
      if (reach) {
        return exp.set('reach', reach);
      }
      return exp;
    }),
];

export const showEmptyExperimentState = [
  __allExperiments,
  allExperiments => allExperiments.size === 0,
];

export const showEditor = [EditorGetters.activeFrameId, id => !!id];

export const unarchivedExperimentsHaveUnpublishedChanges = [
  __allExperiments,
  fns.unarchivedExperimentsHaveUnpublishedChanges,
];

export const experimentPriorityDialogData = [
  CurrentLayerGetters.layer,
  CurrentLayerGetters.liveCommit,
  LayerExperimentGetters.entityCache,
  AudienceGetters.entityCache,
  fns.getExperimentPriorityDialogData,
];

export const savedViewIds = [
  CurrentLayerGetters.layer,
  currentLayer => {
    if (!currentLayer) {
      return Immutable.Set([]);
    }
    return currentLayer.get('view_ids').toSet();
  },
];

/**
 * Gets the active groups in the current project's groups
 */
export const currentProjectActiveGroups = [
  CurrentProjectGetters.experimentationGroups,
  groups =>
    FilterableTable.fns.filterByArchivedStatus(
      groups,
      FilterableTable.enums.status.ACTIVE,
    ),
];

/**
 * Gets the group ID of the group that the current layer is a part of.
 */
export const currentLayerSelectedGroupId = [
  ExperimentationGroup.getters.entityToGroupMap,
  currentProjectActiveGroups,
  CurrentLayerGetters.id,
  ExperimentationGroup.fns.getSelectedGroupIdForEntity,
];

/**
 * Our group_traffic_allocation component accepts an POJO entity, as per how Full Stack passes it in.
 * Full Stack entities in groups are LayerExperiments, and they have a percentage_included property on their model:
 * https://github.com/optimizely/optimizely/blob/8b6d423f318e53602ab35918f35748f3bb62546c/src/www/models/layer_experiments.py#L235
 *
 * We need to calculate an analogous percentage_included property based on the experiment's group membership.
 * Full Stack also extends its LayerExperiments with group_ids in the oasis_experiment_manager section_module:
 * https://github.com/optimizely/optimizely/blob/8b6d423f318e53602ab35918f35748f3bb62546c/src/www/frontend/src/js/bundles/p13n/sections/oasis_experiment_manager/section_module/getters.js#L74,
 * so we will likewise do that here.
 */
export const currentLayerWithGroupIdAndPercentageIncluded = [
  currentLayerSelectedGroupId,
  ExperimentationGroup.getters.entityCache,
  CurrentLayerGetters.layer,
  (selectedGroupId, groupsEntityCache, currentLayer) => {
    let percentageIncluded =
      ExperimentationGroup.constants.MAX_TRAFFIC_ALLOCATION;
    // If the Layer is NOT part of a group, then the percentageIncluded is 10000. The percentageIncluded
    // input field won't even appear in the UI unless they put the Layer into a group.
    if (
      selectedGroupId &&
      selectedGroupId !== ExperimentationGroup.constants.NONE_GROUP_ID
    ) {
      // If the Layer IS part of a group, then calculate the percentageIncluded based on the groups.weight_distributions
      percentageIncluded = ExperimentationGroup.fns.getPercentageIncludedForEntity(
        groupsEntityCache.get(selectedGroupId),
        currentLayer.get('id'),
      );
    }
    return currentLayer
      .set('percentage_included', percentageIncluded)
      .set('group_id', selectedGroupId);
  },
];

export const orderedVariations = [
  __allExperiments,
  LayerExperimentGetters.entityCache,
  (allExperiments, experimentsCache) =>
    allExperiments
      // Don't need to preload variations in archived experiments
      .filterNot(
        experiment =>
          experiment.get('status') === LayerExperimentEnums.status.ARCHIVED,
      )
      // Return plain variations data without extra properties added by getExperimentCardData
      .flatMap(experiment =>
        experimentsCache.getIn([experiment.get('id'), 'variations']),
      ),
];

/**
 * Getter for the map of sidebar items necessary for this section.
 * @type {Getter}
 */
export const sideBarItems = [
  CurrentProjectGetters.id,
  CurrentLayerGetters.id,
  (currentProjectId, currentLayerId) => ({
    experiences: {
      name: CampaignManagerEnums.tabs.EXPERIENCES,
      url: UrlHelper.campaignHome(currentProjectId, currentLayerId),
    },
    integrations: {
      name: CampaignManagerEnums.tabs.INTEGRATIONS,
      url: UrlHelper.campaignIntegrations(currentProjectId, currentLayerId),
    },
    metrics: {
      name: CampaignManagerEnums.tabs.METRICS,
      url: UrlHelper.campaignMetrics(currentProjectId, currentLayerId),
    },
    pages: {
      name: CampaignManagerEnums.tabs.PAGES,
      url: UrlHelper.campaignPages(currentProjectId, currentLayerId),
    },
    custom_code: {
      name: CampaignManagerEnums.tabs.CUSTOM_CODE,
      url: UrlHelper.campaignCustomCode(currentProjectId, currentLayerId),
    },
    api_names: {
      name: CampaignManagerEnums.tabs.API_NAMES,
      url: UrlHelper.campaignApiNames(currentProjectId, currentLayerId),
    },
    history: {
      name: CampaignManagerEnums.tabs.HISTORY,
      url: UrlHelper.campaignHistory(currentProjectId, currentLayerId),
    },
    settings: {
      name: CampaignManagerEnums.tabs.SETTINGS,
      url: UrlHelper.campaignSettings(currentProjectId, currentLayerId),
    },
  }),
];

export default {
  __allExperiments, // Exported for testing
  canUpdateLayer,
  filteredExperiments,
  experiments,
  showEmptyExperimentState,
  showEditor,
  unarchivedExperimentsHaveUnpublishedChanges,
  experimentPriorityDialogData,
  savedViewIds,
  currentProjectActiveGroups,
  currentLayerSelectedGroupId,
  currentLayerWithGroupIdAndPercentageIncluded,
  orderedVariations,
  sideBarItems,
};

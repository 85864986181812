import {
  isFeatureEnabled,
  getFeatureVariableString,
} from '@optimizely/js-sdk-lab/src/actions';

import { canMigrateToFlags } from 'optly/modules/permissions/fns';

const DEFAULT_ATTENTION_TEXT =
  'Full Stack is now sunset. You can no longer create Full Stack experiments, feature rollouts, audiences, events or receive technical support. Learn more and immediately migrate to Feature Experimentation before Full Stack is deprecated';
const attentionText =
  getFeatureVariableString('flags_migration', 'attention_text') ||
  DEFAULT_ATTENTION_TEXT;
const attentionLink = projectId => {
  return `/v2/projects/${projectId}/settings/migration`;
};
const DEFAULT_ROLLOUT_ATTENTION_TEXT_PART_1 = `Full Stack is now sunset. You can no longer create Full Stack experiments, feature rollouts, audiences, events or receive technical support.
<br /><strong>Your Free Rollouts account will be auto-migrated if eligible beginning August 19th.</strong> If you are using the Java or Android SDKs, please ensure you are on Java version 3.6 or higher and Android version 3.7 or higher and if your project is blocked, please read our documentation `;

const DEFAULT_ROLLOUT_ATTENTION_TEXT_PART_2 =
  ' to unblock and migrate or your project will be soon auto-archived. Full Stack projects inactive for 1+ year will also be auto-archived.';

const freeRolloutPlanIds = [
  'free_rollouts',
  'free_trial_optimizely_x',
  'free_enterprise_trial',
  'free_p13n_trial',
  'free_developer',
  'free',
  'free_enterprise_trial_x',
  'free_light',
  'free_nonprofit',
];

const isFlagsMigrationEnabled = props => {
  const flagsMigration = isFeatureEnabled('flags_migration');
  const {
    currentProject,
    isWebProject,
    isFlagsProject,
    isFullStackProject,
    isFullStack,
  } = props;
  if (isFlagsProject || isWebProject) {
    return false;
  }

  return (
    (isFullStackProject || isFullStack) &&
    flagsMigration &&
    canMigrateToFlags(currentProject)
  );
};
export default {
  isFlagsMigrationEnabled,
  attentionText,
  attentionLink,
  DEFAULT_ROLLOUT_ATTENTION_TEXT_PART_1,
  DEFAULT_ROLLOUT_ATTENTION_TEXT_PART_2,
  freeRolloutPlanIds,
};

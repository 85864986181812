import { toImmutable } from 'optly/immutable';
import {
  ExperimentStatusType as statusType,
  IntegrationCategoryTypes as integrationCategoryTypes,
} from 'optly/utils/enums';

export const DEFAULT_EXPERIMENT_STATUS_FILTER = 'active';

export const EXPERIMENT_STATUS_FILTERS = {
  active: [statusType.RUNNING, statusType.PAUSED, statusType.NOT_STARTED],
  archived: [statusType.ARCHIVED],
  draft: [statusType.NOT_STARTED],
  paused: [statusType.PAUSED],
  running: [statusType.RUNNING],
};

export const FILTER_DEFAULTS = {
  experiments: {
    statusString: DEFAULT_EXPERIMENT_STATUS_FILTER,
    status: EXPERIMENT_STATUS_FILTERS[DEFAULT_EXPERIMENT_STATUS_FILTER],
    string: null,
  },
  audiences: {
    string: null,
  },
  collaborators: {
    string: null,
  },
  goals: {
    string: null,
  },
  groups: {
    string: null,
  },
  dimensions: {
    string: null,
  },
  integrations: {
    category: integrationCategoryTypes.ALL,
    string: null,
  },
  'user-lists': {
    string: null,
  },
  datasources: {
    string: null,
  },
};

export const LOCAL_STORAGE_DASHBOARD_CUSTOMIZATION_PREFERENCES =
  'dashboard_customization_preferences';

export const DEFAULT_COLUMNS_VISIBILITY = toImmutable({
  name: true,
  type: true,
  jira: true,
  status: true,
  creator: false,
  last_modified: true,
  first_published: false,
  last_paused: false,
  last_published: false,
  primary_metric: false,
  days_running: false,
  variations: false,
  pages: false,
  audiences: false,
  targeting_method: false,
  unique_id: false,
  traffic_allocation: false,
  distribution_mode: false,
  results: true,
  concluded_results_outcome: false,
});

export const DEFAULT_EXPERIMENTS_COLUMNS_VISIBILITY = toImmutable({
  name: true,
  type: true,
  status: true,
  creator: false,
  last_modified: false,
  first_published: false,
  last_paused: false,
  last_published: false,
  primary_metric: true,
  days_running: false,
  variations: true,
  pages: false,
  targeting_method: false,
  unique_id: false,
  traffic_allocation: false,
  distribution_mode: false,
  results: true,
  concluded_results_outcome: false,
});

export const DEFAULT_PERSONALIZATION_COLUMNS_VISIBILITY = toImmutable({
  name: true,
  status: true,
  creator: false,
  last_modified: false,
  first_published: false,
  last_paused: false,
  last_published: false,
  primary_metric: true,
  days_running: false,
  pages: false,
  audiences: true,
  targeting_method: false,
  unique_id: false,
  traffic_allocation: false,
  distribution_mode: false,
  results: true,
  concluded_results_outcome: false,
});

// mapping of the second part of the url /projects/123/{secondPart}
// to the category in the selected items store
// if a key isn't listed than that tab doesn't support selecting items
// eg: settings
export const SUB_PART_TO_ENTITY = {
  experiments: 'experiments',
  audiences: 'audiences',
  'change-history': 'activities',
  collaborators: 'collaborators',
  goals: 'goals',
  dimensions: 'dimensions',
  integrations: 'integrations',
  'user-lists': 'targeting_list',
  datasources: 'dcp_datasource',
};

export default {
  DEFAULT_EXPERIMENT_STATUS_FILTER,
  DEFAULT_STATUS_STRING: 'active',
  DEFAULT_COLUMNS_VISIBILITY,
  EXPERIMENT_STATUS_FILTERS,
  FILTER_DEFAULTS,
  LOCAL_STORAGE_DASHBOARD_CUSTOMIZATION_PREFERENCES,
  SUB_PART_TO_ENTITY,
};

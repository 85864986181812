export const AdaptiveAudienceHelpTexts = {
  CONDITIONS: tr('Learn more about adaptive audience conditions.'),
  INTEREST_TAGS: tr('Learn how to add interest tags.'),
  PROCESSING: tr(
    'contains an adaptive audience condition that may still be processing. ',
  ),
  TITLE: tr('Adaptive Audience Condition'),
};

export const AdaptiveAudienceReferenceLinks = {
  CONDITIONS:
    'https://help.optimizely.com/Target_Your_Visitors/Build_interest-based_audiences_with_adaptive_audiences',
};

export const Subjects = {
  EXPERIENCE: tr('experience'),
  EXPERIMENT: tr('experiment'),
  MVT_SECTION: tr('section'),
  P13N: tr('campaign has an experience that'),
};

export default {
  AdaptiveAudienceHelpTexts,
  AdaptiveAudienceReferenceLinks,
  Subjects,
};

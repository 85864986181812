import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { toImmutable } from 'nuclear-js';

import { ButtonIcon, ButtonRow, Input, SelectDropdown } from 'optimizely-oui';
import { withTrack } from '@optimizely/segment-js/dist/decorators';

import SectionModuleConstants from 'bundles/p13n/sections/implementation/section_module/constants';

@withTrack
class ConditionLine extends React.Component {
  static propTypes = {
    currentProjectTags: PropTypes.instanceOf(Immutable.List).isRequired,
    handleAddCondition: PropTypes.func.isRequired,
    handleRemoveCondition: PropTypes.func.isRequired,
    handleUpdateCondition: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isLastCondition: PropTypes.bool.isRequired,
    isOnlyCondition: PropTypes.bool.isRequired,
    lhsContext: PropTypes.string,
    lhsField: PropTypes.string,
    lhsValue: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
    operator: PropTypes.string,
    rhsContext: PropTypes.string,
    rhsField: PropTypes.string,
    rhsValue: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
    track: PropTypes.func,
  };

  static defaultProps = {
    lhsContext: SectionModuleConstants.DefaultFilteringRuleValues.CONTEXT,
    lhsField: SectionModuleConstants.DefaultFilteringRuleValues.CUSTOM,
    operator: SectionModuleConstants.DefaultFilteringRuleValues.OP,
    rhsContext: SectionModuleConstants.DefaultFilteringRuleValues.CONTEXT,
    rhsField: SectionModuleConstants.DefaultFilteringRuleValues.CUSTOM,
    track: () => {},
  };

  constructor(props) {
    super(props);
    const { lhsValue, rhsValue } = this.props;
    this.state = { lhsValue, rhsValue };
  }

  getConditionSide = (field, context, value) => {
    if (field === SectionModuleConstants.DefaultFilteringRuleValues.CUSTOM) {
      return { value };
    }
    return { context, field: [field] };
  };

  getCurrentCondition = () => {
    const {
      lhsContext,
      lhsField,
      lhsValue,
      operator,
      rhsContext,
      rhsField,
      rhsValue,
    } = this.props;
    const lhs = this.getConditionSide(lhsField, lhsContext, lhsValue);
    const rhs = this.getConditionSide(rhsField, rhsContext, rhsValue);
    return toImmutable({ op: operator, lhs, rhs });
  };

  parseString = stringValue => {
    if (stringValue.toLowerCase() === 'true') return true;
    if (stringValue.toLowerCase() === 'false') return false;
    const numVal = Number(stringValue);
    return Number.isNaN(numVal) ? stringValue : numVal;
  };

  updateField = (side, newField) => {
    const {
      handleUpdateCondition,
      index,
      lhsContext,
      lhsValue,
      rhsContext,
      rhsValue,
      track,
    } = this.props;
    const condition = this.getCurrentCondition();
    const newConditionSide =
      side === 'lhs'
        ? this.getConditionSide(newField, lhsContext, lhsValue)
        : this.getConditionSide(newField, rhsContext, rhsValue);
    const updatedCondition = condition.set(side, toImmutable(newConditionSide));
    track('Recommendations Tag or Value Selected');
    handleUpdateCondition(updatedCondition, index);
  };

  updateValue = (side, newValue) => {
    const { handleUpdateCondition, index } = this.props;
    const condition = this.getCurrentCondition();
    const updatedCondition = condition.setIn(
      [side, 'value'],
      this.parseString(newValue),
    );
    handleUpdateCondition(updatedCondition, index);
  };

  updateContext = (side, newContext) => {
    const { handleUpdateCondition, index } = this.props;
    const condition = this.getCurrentCondition();
    const updatedCondition = condition.setIn([side, 'context'], newContext);
    handleUpdateCondition(updatedCondition, index);
  };

  updateOperator = newOp => {
    const { handleUpdateCondition, index, track } = this.props;
    const condition = this.getCurrentCondition();
    const updatedCondition = condition.set('op', newOp);
    track('Recommendations Operator Selected', {
      operator: newOp,
    });
    handleUpdateCondition(updatedCondition, index);
  };

  renderAddRemoveButtons = () => {
    const {
      handleAddCondition,
      handleRemoveCondition,
      index,
      isLastCondition,
      isOnlyCondition,
    } = this.props;
    if (isOnlyCondition) {
      return (
        <ButtonIcon
          iconName="plus"
          onClick={handleAddCondition}
          size="small"
          style="outline"
          testSection={`filters-add-condition-${index}`}
          title="Add Condition"
        />
      );
    }
    if (isLastCondition) {
      return (
        <ButtonRow
          rightGroup={[
            <ButtonIcon
              iconName="plus"
              key="add-1"
              size="small"
              style="outline"
              onClick={handleAddCondition}
              testSection={`filters-add-condition-${index}`}
              title="Add Condition"
            />,
            <ButtonIcon
              iconName="trash-can"
              key="minus-0"
              onClick={() => handleRemoveCondition(index)}
              size="small"
              style="outline"
              testSection={`filters-remove-condition-${index}`}
              title="Remove Condition"
            />,
          ]}
        />
      );
    }
    return (
      <ButtonIcon
        iconName="trash-can"
        onClick={() => handleRemoveCondition(index)}
        size="small"
        style="outline"
        testSection={`filters-remove-condition-${index}`}
        title="Remove Condition"
      />
    );
  };

  render() {
    const {
      currentProjectTags,
      index,
      lhsContext,
      lhsField,
      operator,
      rhsContext,
      rhsField,
    } = this.props;
    const { lhsValue, rhsValue } = this.state;
    const fieldOptions = currentProjectTags.map(tag => ({
      label: tag.get('api_name'),
      value: tag.get('api_name'),
    }));
    const fieldAndValueOptions = SectionModuleConstants.ValueOptions.concat(
      fieldOptions.toJS(),
    );

    return (
      <div
        className="flex flex-align--center flex-justified--between push-double--bottom"
        data-test-section={`filters-condition-${index}`}>
        <div className="flex">
          <div className="push-half--right">
            <SelectDropdown
              initialPlaceholder={
                SectionModuleConstants.DefaultFilteringRuleLabels.VALUE
              }
              items={fieldAndValueOptions}
              maxWidth="90px"
              onChange={value => this.updateField('lhs', value)}
              testSection={`filters-lhs-field-condition-${index}`}
              value={lhsField}
            />
          </div>
          {lhsField ===
            SectionModuleConstants.DefaultFilteringRuleValues.CUSTOM && (
            <div className="push-half--right">
              <Input
                id="lhs-input"
                isFilter={false}
                isOptional={false}
                maxLength={250}
                onChange={event =>
                  this.setState({ lhsValue: event.target.value })
                }
                onBlur={event => this.updateValue('lhs', event.target.value)}
                placeholder="Enter value"
                testSection={`filters-lhs-value-input-condition-${index}`}
                type="text"
                value={lhsValue}
              />
            </div>
          )}
          {lhsField !==
            SectionModuleConstants.DefaultFilteringRuleValues.CUSTOM &&
            lhsField !==
              SectionModuleConstants.DefaultFilteringRuleValues.VALUE && (
              <div className="push-half--right">
                <SelectDropdown
                  items={SectionModuleConstants.ContextOptions}
                  maxWidth="145px"
                  onChange={value => this.updateContext('lhs', value)}
                  testSection={`filters-lhs-context-condition-${index}`}
                  value={lhsContext}
                />
              </div>
            )}
          <div className="push-half--right">
            <SelectDropdown
              initialPlaceholder={
                SectionModuleConstants.DefaultFilteringRuleLabels.OP
              }
              items={SectionModuleConstants.OperatorOptions}
              maxWidth="150px"
              minDropdownWidth="240px"
              onChange={this.updateOperator}
              testSection={`filters-operator-condition-${index}`}
              value={operator}
            />
          </div>
          <div className="push-half--right">
            <SelectDropdown
              initialPlaceholder={
                SectionModuleConstants.DefaultFilteringRuleLabels.VALUE
              }
              items={fieldAndValueOptions}
              maxWidth="90px"
              onChange={value => this.updateField('rhs', value)}
              testSection={`filters-rhs-field-condition-${index}`}
              value={rhsField}
            />
          </div>
          {rhsField ===
            SectionModuleConstants.DefaultFilteringRuleValues.CUSTOM && (
            <div className="push-half--right">
              <Input
                id="rhs-input"
                isFilter={false}
                isOptional={false}
                maxLength={250}
                onBlur={event => this.updateValue('rhs', event.target.value)}
                onChange={event =>
                  this.setState({ rhsValue: event.target.value })
                }
                placeholder="Enter value"
                testSection={`filters-rhs-value-input-condition-${index}`}
                type="text"
                value={rhsValue}
              />
            </div>
          )}
          {rhsField !==
            SectionModuleConstants.DefaultFilteringRuleValues.CUSTOM &&
            rhsField !==
              SectionModuleConstants.DefaultFilteringRuleValues.VALUE && (
              <SelectDropdown
                items={SectionModuleConstants.ContextOptions}
                maxWidth="145px"
                onChange={value => this.updateContext('rhs', value)}
                testSection={`filters-rhs-context-condition-${index}`}
                value={rhsContext}
              />
            )}
        </div>
        <div className="push--left">{this.renderAddRemoveButtons()}</div>
      </div>
    );
  }
}

export default ConditionLine;

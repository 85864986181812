import React, { useEffect, useRef } from 'react';
import ouiIconsSVG from 'oui-icons';
import ReactDOM from 'react-dom';

import { subscribe, Subscriptions } from 'NAVBAR';

import { OptimizelyProvider } from '@optimizely/react-sdk';

import getReactWrapperForVueComponent from 'react_components/vue_wrapper';

import CoreUIActionTypes from 'core/ui/action_types';
import DialogManager from 'core/ui/components/dialog_manager';
import flux from 'core/flux';
import NotifcationManager from 'core/ui/components/notification_manager';
import connect from 'core/ui/methods/connect_getters';
import UIGetters from 'core/ui/getters';

import AdminAccountGetters from 'optly/modules/admin_account/getters';
import OptimizelyChampagneActions from 'optly/modules/optimizely_champagne/actions';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import CurrentProjectActions from 'optly/modules/current_project/actions';

import IconSprite from 'react_components/icon_sprite'

import lifecycle from './lifecycle';
import { historyBackSilent } from './routing_helpers';

/**
 * HOC that causes the passed component to be rendered in a React portal at the
 * root of the <body> DOM tree. This is useful for instantiating dialog/
 * notification managers in makeExternallyConsumable HOC, which can be
 * instantiated deep within a component hierarchy and within DOM parents
 * with styling that would affect proper rendering.
 *
 * @param Component component to be rendered in a portal
 */
const makePortalRenderedComponent = (Component: React.ComponentType) => (
  props: any,
) => {
  const portalElement = useRef(document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(portalElement.current);

    return () => {
      document.body.removeChild(portalElement.current);
    };
  }, []);

  return ReactDOM.createPortal(<Component {...props} />, portalElement.current);
};

const DialogManagerReactWrapper = makePortalRenderedComponent(
  getReactWrapperForVueComponent(DialogManager, null, {
    handleHistoryBack: historyBackSilent,
  }),
);

const NotificationManagerReactWrapper = makePortalRenderedComponent(
  getReactWrapperForVueComponent(NotifcationManager),
);

interface ConnectedGetterProps {
  adminAccountId: number;
  currentProjectId: number;
  dialogManager: Object;
  notificationManager: Object;
  ouiIcons: Object;
}

/**
 * Makes the provided component consumable outside the context of the OPTLY_X root_vm.
 * This consists of ensuring:
 *  - The OPTLY_X bootstrap process has happened.
 *  - The component is wrapped in an <OptimizelyProvider> using the OPTLY_X ReactSDK instance.
 *  - The component has access to an instance of the Dialog & Notification managers
 *
 * @param Component - The React component to wrap.
 */
export const makeExternallyConsumable = (Component: React.ComponentType) =>
  connect(
    (propsAndConnectedGetters: ConnectedGetterProps) => {
      const {
        adminAccountId,
        currentProjectId,
        dialogManager,
        notificationManager,
        ouiIcons,
        ...props
      } = propsAndConnectedGetters;
      const thisDialogManager = useRef();
      const thisNotificationManager = useRef();
      const thisOUIIcons = useRef();

      useEffect(() => {
        if (!adminAccountId) {
          lifecycle.bootstrap();
        }
        return () => {
          // Do we need to cleanup our manager registrations in flux? Not sure if it matters
        };
      }, []);

      /**
       * We can't rely on the OPTLY_X router to synchronize the flux store with
       * the current project ID in the URL, so we need to subscribe to changes
       * in the current project to get the current project and current projectID.
       */
      useEffect(() => {
        return subscribe(Subscriptions.CURRENT_PROJECT, currentProject => {
          if (currentProject.get('id') !== currentProjectId) {
            CurrentProjectActions.setCurrentProject(currentProject.toJS());
          }
        });
      }, []);

      const optimizely = OptimizelyChampagneActions.getClientInstance();
      // Render our own instance of the dialogManager/notificationManager if
      // a different one is not currently registered in flux.
      const shouldRenderDialogManager =
        (!dialogManager || dialogManager === thisDialogManager.current) &&
        adminAccountId;
      const shouldRenderNotificationManager =
        (!notificationManager ||
          notificationManager === thisNotificationManager.current) &&
        adminAccountId;
      const shouldRenderOUIIcons =
        (!ouiIcons || ouiIcons === thisOUIIcons.current) && adminAccountId;

      /**
       * Dialog manager registration callback/effect
       */
      const registerDialogManager = (node: any) =>
        (thisDialogManager.current = node);
      useEffect(() => {
        if (
          thisDialogManager.current &&
          // React useEffect executes async, check store for latest value
          !flux.evaluate(UIGetters.dialogManager)
        ) {
          flux.dispatch((CoreUIActionTypes as any).REGISTER_GLOBAL_COMPONENT, {
            id: 'dialogManager',
            instance: thisDialogManager.current,
          });

          return () => {
            flux.dispatch(
              (CoreUIActionTypes as any).REGISTER_GLOBAL_COMPONENT,
              {
                id: 'dialogManager',
                instance: undefined,
              },
            );
          };
        }
      }, [thisDialogManager.current, shouldRenderDialogManager]);

      /**
       * Notification manager registration callback/effect.
       */
      const registerNotificationManager = (node: any) =>
        (thisNotificationManager.current = node);
      useEffect(() => {
        if (
          thisNotificationManager.current &&
          // React useEffect executes async, check store for latest value
          !flux.evaluate(UIGetters.notificationManager)
        ) {
          flux.dispatch((CoreUIActionTypes as any).REGISTER_GLOBAL_COMPONENT, {
            id: 'notificationManager',
            instance: thisNotificationManager.current,
          });

          return () => {
            flux.dispatch(
              (CoreUIActionTypes as any).REGISTER_GLOBAL_COMPONENT,
              {
                id: 'notificationManager',
                instance: undefined,
              },
            );
          };
        }
      }, [!!thisNotificationManager.current, shouldRenderNotificationManager]);

      /**
       * OUI icons registration callback/effect.
       */
      const registerOUIIcons = (node: any) => (thisOUIIcons.current = node);
      useEffect(() => {
        if (
          thisOUIIcons.current &&
          // React useEffect executes async, check store for latest value
          !flux.evaluate(UIGetters.ouiIcons)
        ) {
          flux.dispatch((CoreUIActionTypes as any).REGISTER_GLOBAL_COMPONENT, {
            id: 'ouiIcons',
            instance: thisOUIIcons.current,
          });

          return () => {
            flux.dispatch(
              (CoreUIActionTypes as any).REGISTER_GLOBAL_COMPONENT,
              {
                id: 'ouiIcons',
                instance: undefined,
              },
            );
          };
        }
      }, [!!thisOUIIcons.current, shouldRenderOUIIcons]);

      return (
        <OptimizelyProvider optimizely={optimizely}>
          {shouldRenderOUIIcons && (
            <>
              <div
                id="ouiIconSprite"
                className="display--none"
                dangerouslySetInnerHTML={{ __html: ouiIconsSVG }}
                ref={registerOUIIcons}
              />
              <IconSprite />
            </>
          )}
          {shouldRenderDialogManager && (
            <DialogManagerReactWrapper
              data-test-section="dialog-manager-react-wrapper"
              vueComponentRef={registerDialogManager}
            />
          )}
          {shouldRenderNotificationManager && (
            <NotificationManagerReactWrapper
              data-test-section="notification-manager-react-wrapper"
              vueComponentRef={registerNotificationManager}
            />
          )}
          {adminAccountId && <Component {...props} />}
        </OptimizelyProvider>
      );
    },
    {
      adminAccountId: AdminAccountGetters.id,
      currentProjectId: CurrentProjectGetters.id,
      dialogManager: UIGetters.dialogManager,
      notificationManager: UIGetters.notificationManager,
      ouiIcons: UIGetters.ouiIcons,
    },
  );

export default {
  makeExternallyConsumable,
};

import { featureFunction } from '@optimizely/js-sdk-lab/src/decorators';
import { time } from '@optimizely/js-sdk-lab/src/actions'; // eslint-disable-line optimizely/restrict-deep-imports
import flux from 'core/flux';

import {
  actions as ChampagneActions,
  enums as ChampagneEnums,
  getters as ChampagneGetters,
} from 'optly/modules/optimizely_champagne';

import guid from 'optly/utils/guid';

import actions from './actions';
import actionTypes from './action_types';
import createEntityActionsCore from './create_entity_actions';
import createEntityGetters from './create_entity_getters';
import constants from './constants';
import fieldTypes from './field_types';
import fns from './fns';
import requester from './api/requester';

/**
 * Extend createEntityActions with our Feature controlled paginated version
 * of fetchAll, which toggles between fetchAll and fetchAllPages.
 * @param entityDef
 * @returns {{ delete: *, fetch: *, fetchAll: *, fetchAllPages: *, fetchPage: *, save: * }}
 */
export const createEntityActions = function(entityDef) {
  const {
    FEATURE_KEY,
    VARIABLES,
  } = ChampagneEnums.FEATURES.fetch_all_paginated;
  const userIdKey = `${FEATURE_KEY}_${entityDef.entity}`;
  const featureOptions = {
    attributes: {
      [ChampagneEnums.ATTRIBUTES.fe_api_entity_type]: entityDef.entity,
    },
    userId: () =>
      /**
       * Each API call should use a new guid as the userId.
       * If one exists for this featureFunction call already,
       * use that. Otherwise, create a new one and clear it
       * after the time call.
       */
      flux.evaluate(ChampagneGetters.userIdForFeature(userIdKey)) ||
      ChampagneActions.setUserIdForFeature(userIdKey, guid()),
  };

  const entityActions = createEntityActionsCore(entityDef);
  const fetchAll = featureFunction(
    FEATURE_KEY,
    VARIABLES.page_size,
    featureOptions,
  )(
    /**
     * fetchAll function which is either implemented via the traditional fetchAll
     * or using fetchAllPages to fetch all entities in paged groups.
     * @param args
     * @returns {Promise}
     */
    function(...args) {
      const [filters, options] = args;
      let executeFetch = entityActions.fetchAll.bind(this, ...args);
      const modifiedOptions =
        filters && filters.project_status
          ? Object.assign(options || {}, {
              headers: { ...options?.headers, 'Cache-Control': 'no-cache' },
            })
          : options;
      if (this[FEATURE_KEY]) {
        executeFetch = () =>
          entityActions.fetchAllPages(
            Object.assign(filters || {}, {
              $limit: this[VARIABLES.page_size] || constants.DEFAULT_PAGE_SIZE,
            }),
            modifiedOptions,
          ).allPages;
      }
      return (
        time(
          executeFetch,
          ChampagneEnums.EVENTS.fetch_all_paginated_execution_time,
          featureOptions,
        )
          // Clear the userId for this feature in preparation for the next API call.
          .always(() => ChampagneActions.setUserIdForFeature(userIdKey, null))
      );
    },
  );
  return {
    ...entityActions,
    fetchAll,
  };
};

// named exports
export {
  actions,
  actionTypes,
  constants,
  createEntityGetters,
  fns,
  fieldTypes,
  requester,
};

// default export
export default {
  actions,
  actionTypes,
  constants,
  createEntityActions,
  createEntityGetters,
  fns,
  fieldTypes,
  requester,
};

import PropTypes from 'prop-types';
import React from 'react';

import { Track } from '@optimizely/segment-js/dist/components';

import {
  Attention,
  Input,
  Label,
  Switch,
  RangeSlider,
  Link,
  Icon,
} from 'optimizely-oui';

import { actions as CurrentProjectActions } from 'optly/modules/current_project';

import { enums as LayerExperimentEnums } from 'optly/modules/entity/layer_experiment';

class Rollout extends React.Component {
  static propTypes = {
    disableInputs: PropTypes.bool,
    environmentsDropdown: PropTypes.node,
    onStatusChange: PropTypes.func.isRequired,
    onTrafficChange: PropTypes.func.isRequired,
    rangeDisabled: PropTypes.bool,
    traffic: PropTypes.string.isRequired,
    status: PropTypes.oneOf([
      LayerExperimentEnums.EnvironmentStatus.RUNNING,
      LayerExperimentEnums.EnvironmentStatus.PAUSED,
      LayerExperimentEnums.EnvironmentStatus.NOT_STARTED,
    ]).isRequired,
    showError: PropTypes.bool,
    showLabel: PropTypes.bool,
  };

  static defaultProps = {
    disableInputs: false,
    environmentsDropdown: null,
    rangeDisabled: false,
    showError: false,
    showLabel: true,
  };

  state = {
    initialStatus: this.props.status,
  };

  render() {
    const {
      disableInputs,
      environmentsDropdown,
      onStatusChange,
      onTrafficChange,
      rangeDisabled,
      showError,
      showLabel,
      status,
      traffic,
    } = this.props;

    const trafficInputsDisabled =
      status !== LayerExperimentEnums.EnvironmentStatus.RUNNING ||
      disableInputs ||
      rangeDisabled;
    const statusSwitchDisabled = disableInputs;

    let rangeSliderValue = parseFloat(traffic);
    if (Number.isNaN(rangeSliderValue)) {
      // The current traffic input value is invalid. Set the slider value to 0.
      rangeSliderValue = 0;
    }

    const onChangeTraffic = evt => {
      if (trafficInputsDisabled) {
        return;
      }

      // only allow changing of traffic when the rollout is turned on
      if (status === LayerExperimentEnums.EnvironmentStatus.RUNNING) {
        onTrafficChange(evt.target.value);
      }
    };

    const onChangeStatus = evt => {
      if (statusSwitchDisabled) {
        return;
      }

      let newStatus;

      /*
       * The toggle controls the value of status. Status is one of NOT_STARTED,
       * RUNNING, or PAUSED. The status should initially be NOT_STARTED for
       * newly created features.
       * When the feature is saved with the toggle enabled, the status should
       * be set to RUNNING. If the rollout amount has not been set (ie it has
       * a value of 0), set the rollout to 100%
       * Any time after it is saved as RUNNING, when the toggle is disabled, the
       * status should be set to PAUSED.
       * This is consistent with the meaning of the status field inside the
       * environments property of layer experiments.
       */
      if (evt.target.checked) {
        newStatus = LayerExperimentEnums.EnvironmentStatus.RUNNING;
        if (rangeSliderValue === 0) {
          onTrafficChange('100');
        }
      } else if (
        this.state.initialStatus ===
        LayerExperimentEnums.EnvironmentStatus.NOT_STARTED
      ) {
        newStatus = LayerExperimentEnums.EnvironmentStatus.NOT_STARTED;
      } else {
        newStatus = LayerExperimentEnums.EnvironmentStatus.PAUSED;
      }

      onStatusChange(newStatus);
    };

    const featuresLink = CurrentProjectActions.getHelpCopy(
      'features_link',
      'https://docs.developers.optimizely.com/full-stack/docs/use-feature-flags',
    );

    return (
      <div className="width--1-1 push-quad--top">
        {showLabel && (
          <h3>
            <Label isOptional={false}>
              <span>Controls</span>
            </Label>
          </h3>
        )}
        {environmentsDropdown && (
          <div data-test-section="rollout-environments-dropdown-area">
            <div className="push-double--bottom">
              <p>
                The toggle turns the flag on in this environment. To roll your
                feature out gradually, use the slider to show it to a percentage
                of users.{' '}
                <Link href={featuresLink} newWindow={true}>
                  Learn more.
                </Link>
              </p>
            </div>
            <Label>Environment</Label>
            <div className="push-double--bottom push-half--top flex">
              {environmentsDropdown}
              {disableInputs && (
                <div className="muted push-double--left push--right flex milli">
                  <div className="push--right flex flex-align--center">
                    <Icon name="circle-exclamation" size="small" />
                  </div>
                  <div className="flex flex-align--center">
                    You don&apos;t have permission to modify the rollout in this
                    environment
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {showError && (
          <div className="push--bottom">
            <Attention type="bad-news" testSection="rollout-error-message">
              The rollout traffic allocation must be a number between 0 and 100,
              containing up to 2 decimal digits.
            </Attention>
          </div>
        )}
        <div key="traffic" className="flex">
          <div className="push--right">
            <Label>Feature</Label>
            <Track
              eventName="Rollout Toggled"
              eventType="change"
              properties={{
                status:
                  status === LayerExperimentEnums.EnvironmentStatus.RUNNING,
              }}>
              <Switch
                elementId="rollout-switch"
                testSection="rollout-switch"
                checked={
                  status === LayerExperimentEnums.EnvironmentStatus.RUNNING
                }
                onClick={onChangeStatus}
                isDisabled={statusSwitchDisabled}
              />
            </Track>
          </div>
          <div className="flex--1 push--sides">
            <Track eventName="Rollout Slider Clicked">
              <RangeSlider
                isDisabled={trafficInputsDisabled}
                value={rangeSliderValue}
                onChange={onChangeTraffic}
                testSection="rollout-form-traffic-input-range"
              />
            </Track>
          </div>
          <div className="width-100 push--left">
            <Label>Traffic</Label>
            <span className="flex flex-align--center">
              <Track eventName="Rollout Input Clicked">
                <Input
                  min={0}
                  max={100}
                  type="number"
                  value={traffic}
                  onChange={onChangeTraffic}
                  testSection="rollout-form-traffic-input-number"
                  textAlign="right"
                  isDisabled={trafficInputsDisabled}
                />
              </Track>
              <span className="push--left">%</span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Rollout;

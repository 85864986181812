const keyMirror = require('optly/utils/key_mirror');

const actionTypes = keyMirror({
  ONBOARDING_GOTO_STEP: null,
  ONBOARDING_NEXT_STEP: null,
  ONBOARDING_SET_LANGUAGE: null,
  ONBOARDING_SET_PROJECT_TYPE: null,
  ONBOARDING_SET_DATAFILE_URL: null,
  ONBOARDING_LOAD_PROGRESS: null,
  ONBOARDING_FEEDBACK_SUBMITTED: null,
});

export default actionTypes;

const $ = require('jquery');

const SuggestiveTextInput = require('./suggestive_text_input');
const timeAndDayInterval = require('./time_and_day_interval');
const TimeInput = require('./time_input');

/**
 * @constructor
 * @param {Element|jQueryObject} el
 */
const TimeAndDay = function(el) {
  const DEFAULT_TIMES = TimeAndDay.generateTimes();
  const DAY_START_TIME = tr
    .date()
    .startOf('day')
    .format('LT');
  const DAY_END_TIME = tr
    .date()
    .endOf('day')
    .format('LT');

  this.wrapper = $(el);
  const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
  const weekends = ['saturday', 'sunday'];
  const wrapInputs = function(arr) {
    if (!(arr instanceof Array)) {
      arr = [arr];
    }
    return arr.map(name => `input[name="${name}"]`).join(',');
  };
  this.selectors = {
    startTimeInput: wrapInputs('start_time'),
    endTimeInput: wrapInputs('end_time'),
    allDayCheckbox: wrapInputs('all_day'),
    timeInputs: 'input.timepicker',
    daysOfWeek: wrapInputs(weekdays.concat(weekends)),
    weekdays: wrapInputs(weekdays),
    weekends: wrapInputs(weekends),
    weekdaysCheckbox: wrapInputs('weekdays'),
    weekendsCheckbox: wrapInputs('weekends'),
    timeOfDayError: '.time-of-day-error',
    daysOfWeekError: '.days-of-week-error',
  };

  this.startTimeInput = this.select('startTimeInput');
  this.endTimeInput = this.select('endTimeInput');
  this.allDayCheckbox = this.select('allDayCheckbox');
  this.weekdays = this.select('weekdays');
  this.weekends = this.select('weekends');
  this.weekendsCheckbox = this.select('weekendsCheckbox');
  this.weekdaysCheckbox = this.select('weekdaysCheckbox');

  // initialize suggestive text inputs
  this.startTimeSuggestiveInput = new SuggestiveTextInput(
    this.startTimeInput,
    DEFAULT_TIMES,
  );
  this.endTimeSuggestiveInput = new SuggestiveTextInput(
    this.endTimeInput,
    DEFAULT_TIMES,
  );

  // initialize time inputs (eslint disable required until TimeInput is refactored)
  // eslint-disable-next-line no-new
  new TimeInput(this.startTimeInput, { forceValue: true });
  // eslint-disable-next-line no-new
  new TimeInput(this.endTimeInput, { forceValue: true });

  // update the end time suggestions to be valid with respect to start time
  this.startTimeInput.on('change', event => {
    this.endTimeSuggestiveInput.setSuggestions(
      TimeAndDay.generateTimes(this.startTimeInput.val()),
    );
    this.triggerChange();
  });

  // update time inputs when selecting all day
  this.allDayCheckbox.on('change', event => {
    if (this.allDayCheckbox.prop('checked')) {
      this.startTimeInput.val(DAY_START_TIME);
      this.endTimeInput.val(DAY_END_TIME);
      // reset the suggestions
      this.startTimeSuggestiveInput.setSuggestions(DEFAULT_TIMES);
      this.endTimeSuggestiveInput.setSuggestions(DEFAULT_TIMES);
    }

    this.triggerChange();
  });

  // check if the times are 'all day' and update checkbox
  this.select('timeInputs').on('change', event => {
    const isAllDay =
      this.startTimeInput.val() === DAY_START_TIME &&
      this.endTimeInput.val() === DAY_END_TIME;
    this.allDayCheckbox.prop('checked', isAllDay);

    this.triggerChange();
  });

  // check the corresponding days for weekend / weekday checkboxes
  this.weekdaysCheckbox.on('change', event => {
    this.select('weekdays').prop(
      'checked',
      this.weekdaysCheckbox.prop('checked'),
    );

    this.triggerChange();
  });

  this.weekendsCheckbox.on('change', event => {
    this.select('weekends').prop(
      'checked',
      this.weekendsCheckbox.prop('checked'),
    );

    this.triggerChange();
  });

  // keep the weekends/weekday checkboxes in sync
  this.weekdays.on('change', event => {
    const allChecked =
      this.weekdays.filter(':checked').length === this.weekdays.length;
    this.weekdaysCheckbox.prop('checked', allChecked);

    this.triggerChange();
  });

  this.weekends.on('change', event => {
    const allChecked =
      this.weekends.filter(':checked').length === this.weekends.length;
    this.weekendsCheckbox.prop('checked', allChecked);

    this.triggerChange();
  });
};

/** @constant */
TimeAndDay.MODULE_ID = 'optly.conditions.TimeAndDay';

/** @constant */
TimeAndDay.CHANGE_EVENT = 'optlyTimeAndDayChange';

/**
 * Triggers a DOM event denoting the value has changed
 */
TimeAndDay.prototype.triggerChange = function() {
  this.wrapper.trigger(TimeAndDay.CHANGE_EVENT, [this.serialize()]);
};

/**
 * This function takes the return value of form input
 * @private
 * @nosideeffects
 * @param {String} startDateStr
 * @return Array
 */
TimeAndDay.generateTimes = function(startDateStr) {
  const times = {};
  let timeObj = tr.date(startDateStr, 'LT', true);
  if (typeof startDateStr !== 'undefined' && timeObj.isValid()) {
    timeObj.add(30, 'minutes');
  } else {
    timeObj = tr.date().startOf('day');
  }
  const today = tr.date();
  while (timeObj.isSame(today, 'day')) {
    const value = timeObj.format('LT');
    times[value] = value;
    timeObj.add(30, 'minutes');
  }
  return times;
};

/**
 * Validation function that updates the field errors and returns whether input is valid
 *
 * @return {boolean}
 */
TimeAndDay.prototype.validate = function() {
  const INVALID_TIME_OF_DAY_ERROR = tr('You must enter a valid time interval');
  const INVALID_DAYS_OF_WEEK_ERROR = tr(
    'You must select at least one day of the week',
  );

  // clear errors first
  this.select('timeOfDayError').text('');
  this.select('daysOfWeekError').text('');

  if (
    !tr.date(this.startTimeInput.val(), 'LT', true).isValid() ||
    !tr.date(this.endTimeInput.val(), 'LT', true).isValid()
  ) {
    this.select('timeOfDayError').text(INVALID_TIME_OF_DAY_ERROR);
    return false;
  }

  // validate days of week
  if (this.select('daysOfWeek').filter(':checked').length === 0) {
    this.select('daysOfWeekError').text(INVALID_DAYS_OF_WEEK_ERROR);
    return false;
  }
  return true;
};

/**
 * @return {string}
 */
TimeAndDay.prototype.serialize = function() {
  const data = {
    start_time: tr.date(this.startTimeInput.val(), 'LT', true).format('HH:mm'),
    end_time: tr.date(this.endTimeInput.val(), 'LT', true).format('HH:mm'),
    days: [],
  };

  this.select('daysOfWeek').each((ind, el) => {
    const $el = $(el);
    if ($el.prop('checked')) {
      data.days.push($el.attr('name'));
    }
  });

  return timeAndDayInterval.serialize(data);
};

/**
 * Loads data into UI
 * @param {string} serialized
 */
TimeAndDay.prototype.loadData = function(serialized) {
  const data = timeAndDayInterval.deserialize(serialized);

  // dates always come in 34-hour format
  const startTime = tr.date(data.start_time, 'HH:mm', true);
  const endTime = tr.date(data.end_time, 'HH:mm', true);

  this.startTimeInput.val(startTime.format('LT')).trigger('change');

  this.endTimeInput.val(endTime.format('LT')).trigger('change');

  const daysOfWeek = this.select('daysOfWeek');
  data.days.forEach(day => {
    daysOfWeek
      .filter(`[name="${day}"]`)
      .prop('checked', true)
      // change event to update weekdays/weekend checkboxes
      .trigger('change');
  });

  this.triggerChange();
};

/**
 * @private
 * @param {string} key
 * @return {jQueryObject}
 * @constructor
 */
TimeAndDay.prototype.select = function(key) {
  const selector = this.selectors[key];
  if (!selector) {
    throw new Error(`Selector for key = ${key} doesn't exist`);
  }
  return this.wrapper.find(selector);
};

module.exports = TimeAndDay;

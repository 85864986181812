import { toImmutable } from 'optly/immutable';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import EventModuleEnums from 'optly/modules/entity/event/enums';
import FilterableTable, {
  enums as FilterableTableEnums,
} from 'optly/modules/filterable_table';
import PluginModuleGetters from 'optly/modules/entity/plugin/getters';
import PluginModuleEnums from 'optly/modules/entity/plugin/enums';
import PredefinedCategoriesGetters from 'bundles/p13n/modules/predefined_categories/getters';
import PredefinedCategoriesFns from 'bundles/p13n/modules/predefined_categories/fns';
import SortableTableGetters from 'optly/modules/sortable_table/getters';
import DataLayerGetters from 'bundles/p13n/modules/data_layer/getters';

const EVENTS_TABLE_ID = FilterableTable.enums.tableIds.P13N_DATA_LAYER_EVENTS;

/**
 * Return true if there are any page events for the given table id after applying the string filter
 *
 * @param {string} tableId
 * @return {boolean}
 */
export function hasFilteredPageEvents(tableId) {
  return [
    FilterableTable.getters.stringFilter(tableId),
    CurrentProjectGetters.views,
    CurrentProjectGetters.events,
    function(stringFilter, views, events) {
      const filteredViews = FilterableTable.fns.filterFieldsByString(
        views,
        stringFilter,
        ['name', 'id', 'api_name'],
      );
      const filteredEvents = FilterableTable.fns
        .filterFieldsByString(events, stringFilter, ['name', 'id', 'api_name'])
        .filter(filteredEvent => filteredEvent.get('event_type') !== 'custom');

      // for pageview events we add some additional display text to it, so we want to check if the
      // filter term includes those words
      let hasPageViewMatch = false;
      if (stringFilter) {
        const pageViewName = tr('Visited page');
        hasPageViewMatch =
          pageViewName.toLowerCase().indexOf(stringFilter.toLowerCase()) !== -1;
      }

      return (
        hasPageViewMatch || filteredViews.size > 0 || filteredEvents.size > 0
      );
    },
  ];
}

export function filteredAndSortedCustomEvents(tableId) {
  return [
    DataLayerGetters.customEvents,
    FilterableTable.getters.fieldFilter(tableId, 'status'),
    FilterableTable.getters.stringFilter(tableId),
    SortableTableGetters.sortFn(tableId),
    (events, statusFilter, filter, sortFn) => {
      const archivedFilter =
        statusFilter === FilterableTableEnums.status.ARCHIVED;
      const fieldsToFilter = ['name', 'id', 'api_name'];

      let filteredEvents = events.filter(
        event =>
          event.get('archived') === archivedFilter &&
          filterItem(event, filter, fieldsToFilter),
      );

      if (sortFn) {
        filteredEvents = filteredEvents.sort(sortFn);
      }
      return filteredEvents;
    },
  ];
}

function filterItem(item, searchText, fieldsToFilter) {
  const searchTextLowerCase = searchText.toLowerCase();
  return fieldsToFilter.some(field => {
    const fieldValue = (item.get(field) || '').toString().toLowerCase();
    return fieldValue.indexOf(searchTextLowerCase) !== -1;
  });
}

/**
 * Parsed and formated custom, click and pageview events for the events dashboard
 * This getter grabs the pages and the page events associated with the user.
 * It then filters, sorts, and formats them in a digestible way for the events dashboard.
 */
export const pageEventsForDashboard = [
  CurrentProjectGetters.views,
  CurrentProjectGetters.events,
  FilterableTable.getters.fieldFilter(EVENTS_TABLE_ID, 'status'),
  FilterableTable.getters.stringFilter(EVENTS_TABLE_ID),
  SortableTableGetters.sortFn(EVENTS_TABLE_ID),
  PredefinedCategoriesGetters.getUngroupedList('user_events'),
  (views, events, statusFilter, stringFilter, sortFn, userEventsCategories) => {
    // Hide pages with single_use=True, these are pages used only for url targeting on
    // a specific campaign

    const archivedFilter =
      statusFilter === FilterableTableEnums.status.ARCHIVED;
    const userEventsCategoriesJS = userEventsCategories.toJS();
    const fieldsToFilter = ['name', 'id', 'api_name'];

    // Places all page events in a map with the associated view as the key
    const viewEventsMap = toImmutable({}).withMutations(m => {
      // each filtered view needs to have a pageview event

      events.forEach(event => {
        const viewId = event.get('view_id');
        if (
          viewId &&
          !event.get('variation_specific') &&
          event.get('archived') === archivedFilter &&
          filterItem(event, stringFilter, fieldsToFilter)
        ) {
          // Our current event dashboard filters out variation specific events
          if (m.has(viewId)) {
            m.update(viewId, item =>
              item.set(
                'events',
                item
                  .get('events')
                  .push(
                    event.set(
                      'readable_category',
                      PredefinedCategoriesFns.resolveReadableName(
                        userEventsCategoriesJS,
                        event.get('category'),
                      ),
                    ),
                  ),
              ),
            );
          } else {
            m.set(
              viewId,
              toImmutable({
                view: null,
                events: [
                  event.set(
                    'readable_category',
                    PredefinedCategoriesFns.resolveReadableName(
                      userEventsCategoriesJS,
                      event.get('category'),
                    ),
                  ),
                ],
              }),
            );
          }
        }
      });

      views.forEach(view => {
        const viewId = view.get('id');

        if (
          !view.get('single_use') &&
          view.get('archived') === archivedFilter &&
          filterItem(view, stringFilter, fieldsToFilter)
        ) {
          const defaultPageViewEvent = toImmutable({
            name: `Visited ${view.get('name')} page`,
            readable_category: view.get('category'),
            experiment_count: view.get('experiment_count_pageview', 0),
            event_type: EventModuleEnums.eventTypes.PAGE_VIEW,
            api_name: view.get('api_name'),
            id: view.get('id'),
          });

          if (m.has(viewId)) {
            m.update(viewId, item =>
              item
                .set('view', view)
                .set('events', item.get('events').push(defaultPageViewEvent)),
            );
          } else {
            m.set(
              viewId,
              toImmutable({
                view,
                events: [defaultPageViewEvent],
              }),
            );
          }
        } else if (m.has(viewId)) {
          m.update(viewId, item => item.set('view', view));
        }
      });
    });

    return Array.from(viewEventsMap.values());
  },
];

export const pluginEventTypeToName = [
  PluginModuleGetters.entityCache,
  CurrentProjectGetters.id,
  (plugins, projectId) =>
    toImmutable({}).withMutations(map => {
      plugins
        .toList()
        .filter(
          plugin =>
            plugin.get('plugin_type') === PluginModuleEnums.plugin_type.EVENT &&
            plugin.get('project_id') === projectId,
        )
        .forEach(plugin => {
          map.set(plugin.get('plugin_id'), plugin.get('name'));
        });
    }),
];

export default {
  filteredAndSortedCustomEvents,
  hasFilteredPageEvents,
  pageEventsForDashboard,
  pluginEventTypeToName,
};

import { patchCssLintVerify } from 'lib/codemirror/monkey_patches';
/**
 * Helper method to initialize CSSLint and JSHINT for global consumption.
 * @param jsHintModule
 * @param cssLint
 * @returns {{jsHint: (*|JSHINT), cssLint: *}}
 */
export default function([jsHintModule, cssLint]) {
  const jsHint = jsHintModule.JSHINT;
  if (!window.JSHINT) {
    window.JSHINT = jsHint; // set JSHINT global (required by codemirror)
    patchCssLintVerify(cssLint);
  }
  return { jsHint, cssLint };
}

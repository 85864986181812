import $ from 'jquery';

import flux from 'core/flux';

import actionTypes from './action_types';
import { NAMESPACE_DELIMITER } from './constants';

/**
 * is the passed thenable parameter a jQuery Deferred (vs. esnext Promise)
 * @param {jQuery.Deferred|Promise} thenable
 */
function isJqueryDeferred(thenable) {
  return typeof thenable.always === 'function';
}

/**
 * Returns a jQuery deferred or promise that is resolved when either a single
 * thenable is resolved or array of thenables
 *
 * @private
 * @param {jQuery.Deferred|array<jQuery.Deferred>|Promise|array<Promise>} thenable
 */
export function when(thenable) {
  if (Array.isArray(thenable)) {
    const thenableArray = thenable;
    return isJqueryDeferred(thenableArray[0])
      ? $.when(...thenableArray)
      : Promise.all(thenableArray);
  }
  return !thenable || isJqueryDeferred(thenable) ? $.when(thenable) : thenable;
}

/**
 * Returns a thenable that is resolved when either a single
 * jQuery deferred is resolved or array of jQuery deferreds
 *
 * @private
 * @param {jQuery.Deferred|Promise} thenable
 */
function always(thenable, alwaysCallback) {
  if (thenable.always) {
    thenable.always(alwaysCallback);
  } else if (thenable.finally) {
    thenable.finally(alwaysCallback); // ES7 Promise.finally ensured via polyfill
  } else {
    console.log( // eslint-disable-line
      `WARN: core/ui - expected thenable of type jQuery.Deferred or Promise, but received ${typeof thenable}`,
    );
  }
}

/**
 * Denotes loading starting for a specific loadingId
 * @param {string|number} id
 */
export function loadingStart(id) {
  // For listeners outside the current scs
  const loadingStartEvent = new CustomEvent('loading', {
    detail: {
      type: id,
      isLoading: true,
    },
  });
  window.dispatchEvent(loadingStartEvent);

  // For listeners within the scs
  flux.dispatch(actionTypes.LOADING_START, {
    id,
  });
}

/**
 * Denotes loading finishing for a specific loadingId
 * @param {string|number} id
 */
export function loadingStop(id) {
  // For listeners outside the current scs
  const loadingStopEvent = new CustomEvent('loading', {
    detail: {
      type: id,
      isLoading: false,
    },
  });
  window.dispatchEvent(loadingStopEvent);

  // For listeners within the scs
  flux.dispatch(actionTypes.LOADING_FINISH, {
    id,
  });
}

/**
 * Watches a deferred or promise and stops loading when resolved
 *
 * @param {string} id
 * @param {string=} filter - An optional suffix for namespacing.
 * @param {jQuery.Deferred|Array.<jQuery.Deferred>|Promise|Array.<Promise>} def
 */
export function loadingWhen(id, def, filter) {
  const entry = filter ? `${id}${NAMESPACE_DELIMITER}${filter}` : id;
  loadingStart(entry);
  always(when(def), () => {
    loadingStop(entry);
  });
}

/**
 * Helper function to set 2 loading entries using the 2 promises returned
 * by the rest api module's fetchAllPages
 * @param {string} id
 * @param {Object} promises
 * @param {Promise} promises.firstPage
 * @param {Promise} promises.allPages
 */
export function loadingWhenFetchAllPages(id, { firstPage, allPages }) {
  // Store the first page Promise with a filter for page 1.
  loadingWhen(id, firstPage, 1);
  loadingWhen(id, allPages);
}

/**
 * Helper function to declare an entity is being saved/updated
 *
 * If passed a Deferred as a third argument it will stop the loading
 * after the deferred is resolved.
 *
 * @param {string} entity
 * @param {object} instance
 * @param {jQuery.Deferred|array<jQuery.Deferred>|Promise|array<Promise>} thenable
 */
export function loadingEntityWhen(entity, instance, thenable) {
  flux.dispatch(actionTypes.ENTITY_LOADING_START, {
    entity,
    data: instance,
  });

  always(when(thenable), () => {
    flux.dispatch(actionTypes.ENTITY_LOADING_FINISH, {
      entity,
      data: instance,
    });
  });
}

/**
 * Brute force mark all entities of a certain type as being finished
 * loading
 * @param {string} entity
 */
export function loadingEntityFinished(entity) {
  flux.dispatch(actionTypes.CLEAR_ALL_ENTITY_LOADING, {
    entity,
  });
}

export default {
  loadingStart,
  loadingStop,
  loadingWhen,
  loadingWhenFetchAllPages,
  loadingEntityWhen,
  loadingEntityFinished,
};

export const delivery_modes = {
  EDGE: 'edge',
};

/**
 * Enumerable field values for Project
 */
export const project_status = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
};

export const ClientBuildSettings = {
  LEGACY: 'legacy',
  BUNDLED: 'bundled',
  STANDALONE: 'standalone',
};

export const project_platforms = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
  CUSTOM: 'custom',
};

/**
 * These enums lie in between platforms (which match the actual property
 * returned from the backend), and human readable text. These enums should be
 * used to broadly identify a project as a user might, but should not be seen
 * as a reflection of the datastore
 */
export const project_types = {
  FULL_STACK: 'fullstack',
  WEB: 'web',
  ROLLOUTS: 'rollouts',
};

export const projectTypesHumanReadable = {
  WEB: 'Web',
  WEB_CLASSIC: 'Web (Optimizely Classic)',
  WEB_CLASSIC_AND_X: 'Web (Both Classic and X)',
  FULL_STACK_LEGACY: 'Full Stack (Legacy)',
  FULL_STACK_FLAGS: 'Feature Experimentation',
  ROLLOUTS: 'Rollouts',
  IOS: 'iOS',
  IOS_CLASSIC: 'iOS (Optimizely Classic)',
  ANDROID_TV: 'Android TV',
  ANDROID_CLASSIC: 'Android (Optimizely Classic)',
  CUSTOM_CLASSIC: 'Custom (Optimizely Classic)',
};

export const projectPlatformsHumanReadable = {
  FULL_STACK: 'Full Stack',
  WEB: 'Web',
  ROLLOUTS: 'Rollouts',
  ROLLOUTS_PLUS: 'Rollouts Plus',
};

export const snippetGlobalName = {
  OPTIMIZELY: 'optimizely',
  OPTIMIZELY_EDGE: 'optimizelyEdge',
};

export const snippetGlobalByTypeOrDeliveryMode = {
  [delivery_modes.EDGE]: snippetGlobalName.OPTIMIZELY_EDGE,
  [project_types.WEB]: snippetGlobalName.OPTIMIZELY,
};

/**
 * SDKs available for custom project types
 */
export const sdks = {
  ANDROID: 'android',
  ANDROID_TV: 'android_tv',
  CSHARP: 'csharp',
  GO: 'go',
  IOS: 'ios',
  JAVA: 'java',
  JAVASCRIPT: 'javascript',
  NODE: 'node',
  PHP: 'php',
  PYTHON: 'python',
  RUBY: 'ruby',
  REACT: 'react',
  TV_OS: 'tv_os',
  FLUTTER: 'flutter',
  AGENT: 'agent',
};

export const sdksHumanReadable = {
  [sdks.ANDROID]: 'Android',
  [sdks.ANDROID_TV]: 'Android TV',
  [sdks.CSHARP]: 'C#',
  [sdks.GO]: 'Go',
  [sdks.IOS]: 'iOS',
  [sdks.JAVA]: 'Java',
  [sdks.JAVASCRIPT]: 'JavaScript',
  [sdks.NODE]: 'Node',
  [sdks.PHP]: 'PHP',
  [sdks.PYTHON]: 'Python',
  [sdks.RUBY]: 'Ruby',
  [sdks.REACT]: 'React',
  [sdks.TV_OS]: 'tvOS',
  [sdks.FLUTTER]: 'Flutter',
  [sdks.AGENT]: 'Agent',
};

export const sdkSyntaxLanguage = {
  [sdks.CSHARP]: 'cs',
  [sdks.FLUTTER]: 'javascript',
};

export const sdkLanguages = {
  CSHARP: 'csharp',
  FLUTTER: 'flutter',
  GO: 'go',
  JAVA: 'java',
  JAVASCRIPT: 'javascript',
  OBJECTIVE_C: 'objectivec',
  PHP: 'php',
  PYTHON: 'python',
  RUBY: 'ruby',
  REACT: 'jsx',
  SWIFT: 'swift',
  AGENT: 'html',
};

export const sdkDocsURL = {
  [sdks.ANDROID]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=android&platform=mobile',
  [sdks.ANDROID_TV]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=android&platform=ott',
  [sdks.CSHARP]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=csharp',
  [sdks.GO]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=go',
  [sdks.IOS]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=objectivec&platform=mobile',
  [sdks.JAVA]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=java',
  [sdks.JAVASCRIPT]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=javascript',
  [sdks.NODE]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=node',
  [sdks.PHP]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=php',
  [sdks.PYTHON]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=python',
  [sdks.RUBY]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=ruby',
  [sdks.REACT]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=react',
  [sdks.TV_OS]:
    'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html?language=objectivec&platform=ott',
};

export const sdkDocsGettingStartedUrl =
  'https://developers.optimizely.com/x/solutions/sdks/getting-started/index.html';

export const sdkInstallURL = {
  [sdks.ANDROID]:
    'https://developers.optimizely.com/server/reference/index.html?language=android&platform=mobile#installation',
  [sdks.ANDROID_TV]:
    'https://developers.optimizely.com/x/solutions/sdks/reference/index.html?language=android&platform=ott#installation',
  [sdks.CSHARP]:
    'https://developers.optimizely.com/server/reference/index.html?language=csharp#installation',
  [sdks.GO]:
    'https://developers.optimizely.com/server/reference/index.html?language=go#installation',
  [sdks.IOS]:
    'https://developers.optimizely.com/x/solutions/sdks/reference/index.html?language=objectivec&platform=mobile#installation',
  [sdks.JAVA]:
    'https://developers.optimizely.com/server/reference/index.html?language=java#installation',
  [sdks.JAVASCRIPT]:
    'https://developers.optimizely.com/server/reference/index.html?language=javascript#installation',
  [sdks.NODE]:
    'https://developers.optimizely.com/server/reference/index.html?language=node#installation',
  [sdks.PHP]:
    'https://developers.optimizely.com/server/reference/index.html?language=php#installation',
  [sdks.PYTHON]:
    'https://developers.optimizely.com/server/reference/index.html?language=python#installation',
  [sdks.RUBY]:
    'https://developers.optimizely.com/server/reference/index.html?language=ruby#installation',
  [sdks.REACT]:
    'https://developers.optimizely.com/server/reference/index.html?language=react#installation',
  [sdks.TV_OS]:
    'https://developers.optimizely.com/x/solutions/sdks/reference/index.html?language=objectivec&platform=ott#installation',
};

export const mobileAndOTTSDKs = [
  sdks.ANDROID,
  sdks.ANDROID_TV,
  sdks.IOS,
  sdks.TV_OS,
];

/**
 * Optimizely versions
 */
export const manifestVersions = {
  V1: 1,
  V2: 2,
};

// Options for the editor iframe protocol preferences labs setting
// See backend definition: EditorIframeProtocolPreferences in src/www/helpers/enums.py
export const EditorIframeProtocolPreferences = {
  PROXY_AND_DIRECT: 'PROXY_AND_DIRECT',
  DIRECT_ONLY: 'DIRECT_ONLY',
  PROXY_ONLY: 'PROXY_ONLY',
};

export const thirdPartyPlatforms = {
  SALESFORCE: 'salesforce',
  MICROSOFT_DYNAMICS_365_COMMERCE: 'microsoft_dynamics_365_commerce',
};

export const thirdPartyPlatformsReadable = {
  SALESFORCE_OPX: 'Salesforce OpX',
  MICROSOFT_DYNAMICS_365_COMMERCE: 'MS Dynamics 365 Commerce',
};

export default {
  ClientBuildSettings,
  delivery_modes,
  EditorIframeProtocolPreferences,
  manifestVersions,
  mobileAndOTTSDKs,
  projectPlatformsHumanReadable,
  project_platforms,
  project_status,
  project_types,
  projectTypesHumanReadable,
  sdks,
  sdkDocsGettingStartedUrl,
  sdkDocsURL,
  sdksHumanReadable,
  sdkInstallURL,
  sdkLanguages,
  sdkSyntaxLanguage,
  snippetGlobalName,
  snippetGlobalByTypeOrDeliveryMode,
  thirdPartyPlatforms,
  thirdPartyPlatformsReadable,
};

import { toImmutable } from 'optly/immutable';

import FeatureActions from 'optly/modules/entity/feature_flag/actions';

export function unarchiveFeature(feature) {
  return FeatureActions.unarchive(feature.get('id'));
}

/**
 * Saves a feature, then invokes a callback function.
 * @param {object} featureToSave
 * @param {function} onSave
 * @return {Deferred}
 */
export function saveFeature(featureToSave, onSave) {
  return FeatureActions.save(featureToSave.toJS()).then(savedFeature => {
    const invokeOnSaveCallback = feature => {
      if (onSave && typeof onSave === 'function') {
        onSave(toImmutable(feature));
      }
    };
    // detect and handle eventual consistency issue with feature flag saving (APP-1596)
    if (
      featureToSave.get('variables') &&
      featureToSave.get('variables').size !== savedFeature.variables.length
    ) {
      // force fetch the feature to push out the bad data from the entity cache
      FeatureActions.fetch(savedFeature.id, true).then(fetchedFeature => {
        invokeOnSaveCallback(fetchedFeature);
      });
    } else {
      invokeOnSaveCallback(savedFeature);
    }
  });
}

// default export
export default {
  saveFeature,
  unarchiveFeature,
};

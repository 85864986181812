import constants from './constants';
import fns from './fns';
import * as getters from './getters';

const actions = require('./actions');

// named exports
export { actions, constants, fns, getters };

// default export
export default {
  actions,
  constants,
  fns,
  getters,
};

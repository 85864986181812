/**
 * Collaborator Entity Module
 */
import actions from './actions';
import constants from './constants';
import entityDefs from './entity_definitions';
import fns from './fns';
import getters from './getters';

const entityDef = entityDefs.v2; // set default entityDef to v2 API

export { actions, constants, entityDef, entityDefs, fns, getters };

export default {
  actions,
  constants,
  entityDef,
  entityDefs,
  fns,
  getters,
};

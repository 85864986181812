export const JOYRIDE_IDS = {
  MAB_RESULTS: 'mabResults',
  SA_RESULTS: 'saResults',
  TARGETED_ROLLOUTS_RULES_PAGE: 'trRules',
  TARGETED_ROLLOUTS_LIST_PAGE: 'trList',
};

export const JOYRIDE_LOCAL_STORAGE_KEY = 'optimizelyJoyrides';
export const SKIP_JOYRIDE_LOCAL_STORAGE_KEY = 'optimizelySkipJoyrides';

export default {
  JOYRIDE_IDS,
  JOYRIDE_LOCAL_STORAGE_KEY,
  SKIP_JOYRIDE_LOCAL_STORAGE_KEY,
};

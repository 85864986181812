import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { actions as RegionActions, fns as RegionFns } from 'core/ui/region';

import events from 'optly/utils/events';
import LoadingOverlay from 'react_components/loading_overlay';
import { BeforeLeaveContext } from 'react_components/before_leave_context';

const transitionClassEnum = {
  FORWARD: 'stage--forward',
  BACKWARD: 'stage--back',
  FADE: 'stage--fade',
};

export class RegionBase extends React.Component {
  render() {
    return (
      <div className="height--1-1 flex flex--column flex-justified--center flex-align--center width--1-1" />
    );
  }
}

export class RegionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.viewRefs = [];
    this.beforeLeaveFns = [];
  }

  getInitialState = () => ({
    mountedViews: [null, { component: <RegionBase />, key: 'region-base' }],
    isTransitioning: false,
    currentlyMountedComponentId: null,
  });

  state = this.getInitialState();

  resetState = () => {
    const initialState = this.getInitialState();
    this.setState(initialState);
  };

  UNSAFE_componentWillMount() {
    this.regionMap = RegionFns.graphifyRegionMap(this.props.regionMap);
    RegionActions.registerRegion(this.props.regionId, this);
  }

  componentDidMount() {
    RegionActions.mountInQueue(this.props.regionId);
  }

  componentWillUnmount() {
    RegionActions.unregisterRegion(this.props.regionId);
  }

  /**
   * Called once a view animation has completed
   */
  completeViewsTransition = (componentId, view) => {
    let prev = this.state.mountedViews.length - 2;
    let next = this.state.mountedViews.length - 1;
    if (!this.shouldAppend) {
      // prev and next indices are swapped
      prev = this.state.mountedViews.length - 1;
      next = this.state.mountedViews.length - 2;
    }

    this.setState(
      state => {
        let mountedViews = state.mountedViews.map((mountedView, index) => {
          if (index !== next) {
            return null;
          }
          return mountedView;
        });

        if (!this.shouldAppend) {
          mountedViews = mountedViews.slice(0, prev);
        }
        return {
          mountedViews,
          isTransitioning: false,
        };
      },
      () => {
        const nextView = this.viewRefs[next];
        if (nextView && nextView.registerComponent) {
          nextView.registerComponent(RegionActions.registerComponent);
        } else {
          RegionActions.registerComponent(
            this.props.regionId,
            componentId,
            view,
          );
        }
        RegionActions.mountInQueue(this.props.regionId);
      },
    );
  };

  /**
   * @param {Object} options
   * @param {Function} options.onComplete
   * @param {String} transitionClass
   */
  transitionViews = (options, transitionClass, view) => {
    const { componentId, onComplete } = options;

    const prev = this.shouldAppend
      ? this.state.mountedViews.length - 2
      : this.state.mountedViews.length - 1;
    const prevView = this.viewRefs[prev];
    if (prevView && prevView.navigationControllerWillHideView) {
      prevView.navigationControllerWillHideView();
    }

    if (transitionClass) {
      this.setState(
        {
          transitionClass,
        },
        () => {
          const unbind = events.transitionEndOnce(this.outerRef, e => {
            this.setState(
              {
                transitionClass: null,
              },
              () => {
                this.completeViewsTransition(componentId, view);

                if (onComplete) {
                  onComplete();
                }
                if (unbind) {
                  unbind();
                }
              },
            );
          });
        },
      );
    } else {
      this.completeViewsTransition(componentId, view);
      if (onComplete) {
        onComplete();
      }
    }
  };

  /**
   * Push a new view onto the stack
   *
   * @param {ReactElement} comp - The view to push onto the stack
   * @param {Object} options
   * @param {String} options.componentId
   * @param {Function?} options.onComplete
   */
  pushView = (component, options) => {
    const { regionId } = this.props;
    const { currentlyMountedComponentId, isTransitioning } = this.state;
    const { componentId } = options;

    if (isTransitioning) {
      RegionActions.queueComponent(regionId, component, componentId);
      return;
    }

    if (componentId === currentlyMountedComponentId) {
      return;
    }

    const view = { component, key: componentId };

    const currentComponentMapConfig = this.regionMap[
      currentlyMountedComponentId
    ];
    let positionInRegionMap = 0;
    this.shouldAppend = true;

    let transitionClass;
    if (currentComponentMapConfig && currentComponentMapConfig[componentId]) {
      positionInRegionMap = currentComponentMapConfig[componentId];

      if (positionInRegionMap > 0) {
        transitionClass = transitionClassEnum.FORWARD;
      } else if (positionInRegionMap < 0) {
        transitionClass = transitionClassEnum.BACKWARD;
        this.shouldAppend = false;
      } else {
        transitionClass = transitionClassEnum.FADE;
      }
    }

    this.setState(
      state => {
        const { mountedViews } = state;
        if (this.shouldAppend) {
          mountedViews.push(view);
        } else {
          mountedViews.splice(mountedViews.length - 2, 1, view);
        }
        return {
          mountedViews,
          isTransitioning: true,
          currentlyMountedComponentId: componentId,
        };
      },
      () => {
        this.transitionViews(options, transitionClass, view);
      },
    );
  };

  createOuterRef = el => {
    this.outerRef = el;
  };

  setBeforeLeave = fn => {
    const { navBarBeforeLeave } = this.props;
    this.beforeLeaveFns.push(fn);
    if (navBarBeforeLeave) {
      navBarBeforeLeave(() => {
        return new Promise((resolve, reject) => {
          if (!this.beforeLeaveFns || !this.beforeLeaveFns.length) {
            return resolve();
          }
          Promise.all(this.beforeLeaveFns.map(fni => fni()))
            .then(() => {
              this.beforeLeaveFns = [];
              setTimeout(resolve, 0); // Avoiding race condition in routing
            })
            .catch(reject);
        });
      });
    }
  };

  render() {
    const { className, regionId } = this.props;
    const { mountedViews, transitionClass } = this.state;
    const classNames = classnames('stage', className, transitionClass);
    return (
      <LoadingOverlay
        loadingId={regionId}
        className="stage__item__content--column">
        <BeforeLeaveContext.Provider value={this.setBeforeLeave}>
          <div className={classNames} ref={this.createOuterRef}>
            {mountedViews.map((view, index) => {
              if (!view) {
                return null;
              }

              const createViewRef = el => {
                this.viewRefs[index] = el;
              };
              return (
                <div className="stage__item" key={view.key || index}>
                  {React.cloneElement(view.component, {
                    ref: createViewRef,
                  })}
                </div>
              );
            })}
          </div>
        </BeforeLeaveContext.Provider>
      </LoadingOverlay>
    );
  }
}

RegionComponent.propTypes = {
  /**
   * Map of region relationships, ex:
   * const regionMap = {
   *   layer: {
   *     1: 'p13n-layers-home',
   *     2: [
   *       'p13n-layer-detail', 'p13n-editor',
   *     ],
   *     3: [
   *       'p13n-results',
   *     ],
   *   },
   *   pages: {
   *     1: 'p13n-pages-home',
   *   },
   *   audiences: {
   *     1: 'p13n-audiences-home',
   *     2: 'p13n-audience-detail',
   *   },
   *   extensions: {
   *     1: 'p13n-data-layer',
   *     2: 'plugins-root',
   *   },
   *   settings: {
   *     1: 'p13n-settings',
   *     2: 'plugins-root',
   *   },
   * };
   */
  regionMap: PropTypes.object.isRequired,
  regionId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default RegionComponent;

/**
 * History module keeps track of the history of routes/urls we
 * we have visited in our SPA
 */
import $ from 'jquery';

import flux from 'core/flux';

import actionTypes from './action_types';
import getters from './getters';

$(window).on('popstate.global', () => {
  popPreviousBrowserHistoryState();
});

/**
 * Push the given url into the history stack
 * @param {string} url
 */
function pushBrowserHistoryState(url) {
  flux.dispatch(actionTypes.BROWSER_HISTORY_PUSH_STATE, {
    url,
  });
}

/**
 * Replace the latest entry in the history stack with the given url
 * @param {string} url
 */
function replaceBrowserHistoryState(url) {
  flux.dispatch(actionTypes.BROWSER_HISTORY_REPLACE_STATE, {
    url,
  });
}

/**
 * Remove the last entry in the browser history stack
 */
function popPreviousBrowserHistoryState() {
  flux.dispatch(actionTypes.BROWSER_HISTORY_POP_PREVIOUS_STATE);
}

/**
 * Return the current state
 * @return {string}
 */
function getCurrentBrowserHistoryState() {
  return flux.evaluateToJS(getters.currentState);
}

/**
 * Return the previous page's state
 * @return {string}
 */
function getPreviousBrowserHistoryState() {
  return flux.evaluateToJS(getters.previousState);
}

export {
  getCurrentBrowserHistoryState,
  getPreviousBrowserHistoryState,
  popPreviousBrowserHistoryState,
  pushBrowserHistoryState,
  replaceBrowserHistoryState,
};

export default {
  getCurrentBrowserHistoryState,
  getPreviousBrowserHistoryState,
  popPreviousBrowserHistoryState,
  pushBrowserHistoryState,
  replaceBrowserHistoryState,
};

import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

const projectParentDefinition = {
  ...definition,
  parent: {
    entity: 'projects',
    key: 'project_id',
  },
};
const entityActionsByProject = RestApi.createEntityActions(
  projectParentDefinition,
);

export const {
  save,
  fetch,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deleteTags = baseEntityActions.delete;

export { deleteTags as delete };

/**
 * Fetch all tags by ancestor project rather than direct view parent
 */
function fetchAllByProject(filters, force) {
  entityActionsByProject.fetchAll(filters, force).then(resp => {
    RestApi.actions.onAncestorFetchAllSuccess(
      filters,
      projectParentDefinition,
      definition,
      resp,
    );
  });
}

/**
 * Overwrite the base fetchAll so that we can query by either
 * a parent view or a parent project
 * @param {object|undefined} filters
 * @param {boolean} force a model fetch instead of using cache
 */
export function fetchAll(filters, force) {
  const fetchAllFunc = filters.project_id
    ? fetchAllByProject
    : baseEntityActions.fetchAll;

  return fetchAllFunc(filters, force);
}

export default {
  ...baseEntityActions,
  fetchAll,
};

import React from 'react';

import flux from 'core/flux';

import BundleSplitHelper from 'optly/utils/bundle_split_helper';
import NavConstants from 'optly/services/navigation';

import CampaignManagerEnums from 'bundles/p13n/modules/campaign_manager/enums';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import PermissionsGetters from 'optly/modules/permissions/getters';
import LayerExperimentGetters from 'optly/modules/entity/layer_experiment/getters';

import { withSidebarAndManagerChangeHistory } from 'bundles/p13n/components/change_history';

import AbManagerRoutingFns from './routing_fns';

import RoutingFns from '../../routing_fns';

import Sidebar from './components/sidebar';

const category = 'Manage A/B Test';

/*
 * Routing function that ensures the AB Experiment Editor dynamic bundle has been fetched
 * We store the returned dynamic bundle module on the function object itself
 */
function ABExperimentEditorSection(ctx, next) {
  import(
    /* webpackChunkName: "ab-exp-editor-bundle" */
    'bundles/p13n/sections/ab_experiment_editor' // eslint-disable-line
  )
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(ABExperimentEditorSection, module);
    })
    .then(next);
}

function shouldHandleWeb() {
  return Promise.resolve(flux.evaluate(CurrentProjectGetters.isWebProject));
}

function setPageTitle({ next, name, params }) {
  next();
  let primaryName = '';
  if (params.experiment_id) {
    const currentExperiment = flux.evaluate(
      LayerExperimentGetters.byId(Number(params.experiment_id)),
    );
    primaryName = !!currentExperiment && currentExperiment.get('name');
  }
  RoutingFns.setPageTitle({
    category: !primaryName ? category : '',
    name,
    primaryName,
  });
}

/*
 * Routes for the AB Experiment Overview section in nuclear-router format
 */
const routes = [
  /*
   * AB Test Experiment Overview Routes
   */
  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id',
    metadata: { name: 'Variations', category },
    shouldHandle: shouldHandleWeb,
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        ABExperimentEditorSection.pages.variations.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Variations',
          params,
        }),
      ctx => {
        const experimentId = Number(ctx.params.experiment_id);
        RoutingFns.renderMainRegion(
          <ABExperimentEditorSection.pages.variations.component
            experimentId={experimentId}
          />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/variations',
    metadata: { name: 'Variations', category },
    shouldHandle: shouldHandleWeb,
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        ABExperimentEditorSection.pages.variations.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Variations',
          params,
        }),
      ctx => {
        const experimentId = Number(ctx.params.experiment_id);
        RoutingFns.renderMainRegion(
          <ABExperimentEditorSection.pages.variations.component
            experimentId={experimentId}
          />,
        );
      },
    ],
  },

  {
    match:
      '/v2/projects/:proj_id/experiments/:experiment_id/variations/:variation_id',
    metadata: { name: 'Variation Editor', category },
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        ABExperimentEditorSection.pages.variations.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Variation Editor',
          params,
        }),
      RoutingFns.fetchViewsForCurrentProject,
      ctx => {
        const variationId = Number(ctx.params.variation_id);
        const experimentId = Number(ctx.params.experiment_id);
        RoutingFns.renderEditor(
          <ABExperimentEditorSection.pages.variations.component
            experimentId={experimentId}
            variationId={variationId}
          />,
          variationId,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/api_names',
    metadata: { name: 'API Names', category },
    shouldHandle: shouldHandleWeb,
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        ABExperimentEditorSection.pages.api_names.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'API Names',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ABExperimentEditorSection.pages.api_names.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/audiences',
    metadata: { name: 'Audiences', category },
    shouldHandle: shouldHandleWeb,
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        ABExperimentEditorSection.pages.audiences.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Audiences',
          params,
        }),
      () => {
        RoutingFns.renderMainRegion(
          <ABExperimentEditorSection.pages.audiences.component />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/integrations',
    metadata: { name: 'Integrations', category },
    shouldHandle: () =>
      shouldHandleWeb &&
      Promise.resolve(
        flux.evaluate(PermissionsGetters.canUseLayerIntegrations),
      ),
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      [
        RoutingFns.fetchIntegrationsTabData,
        (ctx, next) =>
          ABExperimentEditorSection.pages.integrations.routingSetup(ctx, next),
      ],
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Integrations',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ABExperimentEditorSection.pages.integrations.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/metrics',
    metadata: { name: 'Metrics', category },
    shouldHandle: shouldHandleWeb,
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      (ctx, next) =>
        ABExperimentEditorSection.pages.metrics.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Metrics',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ABExperimentEditorSection.pages.metrics.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/pages',
    metadata: { name: 'Targeting', category },
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        ABExperimentEditorSection.pages.pages.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Targeting',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ABExperimentEditorSection.pages.pages.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/schedule',
    metadata: { name: 'Schedule', category },
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        ABExperimentEditorSection.pages.schedule.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Schedule',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ABExperimentEditorSection.pages.schedule.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/settings',
    metadata: { name: 'Settings', category },
    shouldHandle: shouldHandleWeb,
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      (ctx, next) =>
        ABExperimentEditorSection.pages.settings.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Settings',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ABExperimentEditorSection.pages.settings.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/shared_code',
    metadata: { name: 'Shared Code', category },
    shouldHandle: shouldHandleWeb,
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        ABExperimentEditorSection.pages.shared_code.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Shared Code',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ABExperimentEditorSection.pages.shared_code.component />,
        ),
    ],
  },

  {
    match:
      '/v2/projects/:proj_id/experiments/:experiment_id/traffic_allocation',
    metadata: { name: 'Traffic Allocation', category },
    shouldHandle: shouldHandleWeb,
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        ABExperimentEditorSection.pages.traffic_allocation.routingSetup(
          ctx,
          next,
        ),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Traffic Allocation',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ABExperimentEditorSection.pages.traffic_allocation.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/experiments/:experiment_id/history',
    metadata: { name: 'History', category },
    shouldHandle: shouldHandleWeb,
    handle: [
      [
        ABExperimentEditorSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      ({ params }, next) =>
        AbManagerRoutingFns.fetchLayerExperimentIfValidAndSetLayerIdAsCurrent(
          params.experiment_id,
          next,
        ),
      AbManagerRoutingFns.fetchRequiredDataForWebAbManagerChangeHistory,
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'History',
          params,
        }),
      // ctx.fetchedData is set within RoutingFns.fetchRequiredDataForWebManagerChangeHistory
      ({ fetchedData }) => {
        // Define within route since the componentId needs to change for renderMainRegion to remount the component
        const ManagerChangeHistory = withSidebarAndManagerChangeHistory(
          Sidebar,
        );
        RoutingFns.renderMainRegion(
          <ManagerChangeHistory
            fetchedEntities={fetchedData}
            sidebarProps={{
              activeTab: CampaignManagerEnums.tabs.HISTORY,
            }}
          />,
        );
      },
    ],
  },
];

export default routes;

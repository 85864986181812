import FieldTypes from 'optly/modules/rest_api/field_types';

/**
 * Entity Definition for targetingList
 */

export const entity = 'targeting_lists';

export const parent = {
  entity: 'projects',
  key: 'project_id',
};

/**
 * Optional definitions
 */
// fieldTypes are used for client side sorting / filtering
export const fieldTypes = {
  id: FieldTypes.NUMBER,
  account_id: FieldTypes.NUMBER,
  project_id: FieldTypes.NUMBER,
  name: FieldTypes.STRING,
  file_size_in_bytes: FieldTypes.NUMBER,
  format: FieldTypes.STRING,
  key_fields: FieldTypes.STRING,
  created: FieldTypes.DATE,
  last_modified: FieldTypes.DATE,
};

export default {
  entity,
  parent,
  fieldTypes,
};

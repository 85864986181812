/**
 * Characters which should be considered as indicators to split the keyword input on.
 * @type {string[]}
 */
export const KEYWORD_INPUT_SEPARATORS = [
  ' ',
  '_',
  ';',
  ',',
  '.',
  '|',
  '/',
  '\\',
  '\t',
  '\n',
];
export const TAGS_LIMIT = 12;
export const TAGS_LIMIT_VISIBLE_THRESHOLD = 0.75;

export default {
  KEYWORD_INPUT_SEPARATORS,
  TAGS_LIMIT,
  TAGS_LIMIT_VISIBLE_THRESHOLD,
};

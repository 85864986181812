/**
 * Module specific pure functions for Optimizely on Optimizely.
 */
export function hasOptimizelyEmail(email) {
  if (email.includes('@optimizely.com')) {
    return 'yes';
  }
  return 'no';
}

/**
 * Creates and returns an ID based on the user information and whether impersonation is being used.
 * Backend equivalent: https://github.com/optimizely/optimizely/blob/78afd80cff99da239592cdc0816017028093e6dd/src/www/services/segment_tracking.py#L108
 *
 * @param {Object} accountInfo
 * @param {Boolean} isImpersonating
 *
 * @returns {String|null} string with the admin account id, "impersonating" string
 *                        (if impersonating) and the user's unique_id.
 *                        E.G.
 *                          "76342_impersonating_90b4346b8ea411e5ab800106f27f94bd"
 *                          "983748_562f9440e9b711e4ad3a0bcf736d4c65"
 */
export function generateUserId(accountInfo, isImpersonating) {
  if (!accountInfo.unique_user_id) {
    return null;
  }
  const prefix = isImpersonating ? '_impersonating' : '';
  return `${accountInfo.account_id + prefix}_${accountInfo.unique_user_id}`;
}

export default {
  hasOptimizelyEmail,
  generateUserId,
};

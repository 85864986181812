import React from 'react';
import PropTypes from 'prop-types';

import truncate from 'optly/filters/truncate';

const MetricDescription = ({ description }) => (
  <span style={{ display: 'inline-grid' }}>
    <span
      className="word-break--word soft-half--bottom"
      data-test-section="tile-description"
      title={description}>
      {description}
    </span>
  </span>
);

MetricDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

const MetricName = ({ name }) => (
  <span style={{ display: 'inline-grid' }}>
    <span
      className="truncate soft-half--bottom"
      data-test-section="tile-name"
      title={name}>
      {truncate(name)}
    </span>
  </span>
);

MetricName.propTypes = {
  name: PropTypes.string.isRequired,
};

export { MetricName, MetricDescription };

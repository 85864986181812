/**
 * The component displays a Dialog with the list of users that are viewing this entity.
 * @component
 */

const DialogNew = require('optimizely-oui').DialogNew;
const Button = require('optimizely-oui').Button;
const Typography = require('optimizely-oui').Typography;

const Immutable = require('optly/immutable').default;

const PropTypes = require('prop-types');
const React = require('react');

const messages = {
  title: 'Multiple editors',
  subtitle:
    'Other users are currently editing this experiment and may overwrite each others change. The users currently editing are:',
};

function ConcurrentEditingDialog({ onClose, users }) {
  return (
    <DialogNew
      title={messages.title}
      subtitle={messages.subtitle}
      data-test-section="concurrent-editing-dialog"
      footerButtonList={[
        <Button
          style="highlight"
          key={0}
          data-test-section="concurrent-editing-dialog__dismiss"
          onClick={onClose}>
          Close
        </Button>,
      ]}
      onClose={onClose}>
      <div className="border--all border-radius soft max-h-10 overflow-y-auto">
        <ul className="push--left list-disc">
          {users.map(user => (
            <li key={user} data-test-section="concurrent-editing-dialog__user">
              <Typography type="body">{user}</Typography>
            </li>
          ))}
        </ul>
      </div>
    </DialogNew>
  );
}

ConcurrentEditingDialog.propTypes = {
  /** Function top be executed once the users close the dialog. */
  onClose: PropTypes.func,
  /** The list of users currently editing this experiment. */
  users: PropTypes.instanceOf(Immutable.List),
};

ConcurrentEditingDialog.defaultProps = {
  onClose: undefined,
  users: Immutable.List(),
};

module.exports = ConcurrentEditingDialog;

import htmlSanitizer from 'sanitizer';

import React from 'react';
import PropTypes from 'prop-types';

import { ButtonIcon } from 'optimizely-oui';

import Immutable from 'optly/immutable';

import ListDropdown from 'react_components/list_dropdown';
import ui from 'core/ui';

function FeatureTableRowActions({
  archive,
  canManageFeatureFlags,
  currentProjectRunningExperiments,
  feature,
  rowIndex,
  unarchive,
}) {
  const sanitizedFeatureFlagName = htmlSanitizer.sanitize(
    feature.get('api_name'),
  );
  const onArchive = () => {
    if (!canManageFeatureFlags) {
      ui.showNotification({
        message: tr('You do not have permission to perform this action.'),
      });
      return;
    }

    const successMessage = tr(
      'The feature flag <b>{0}</b> has been archived.',
      sanitizedFeatureFlagName,
    );
    archive(feature).then(() => {
      ui.showNotification({
        message: successMessage,
      });
    });
  };

  const onUnarchive = () => {
    if (!canManageFeatureFlags) {
      ui.showNotification({
        message: tr('You do not have permission to perform this action.'),
      });
      return;
    }

    const sanitizedName = htmlSanitizer.sanitize(feature.get('api_name'), true);
    const successMessage = `The feature flag <b>${sanitizedName}</b> has been unarchived.`;

    unarchive(feature).then(() => {
      ui.showNotification({
        message: successMessage,
      });
    });
  };

  const foundExperiment = currentProjectRunningExperiments.some(
    exp => exp.get('feature_flag_id') === feature.get('id'),
  );
  let archiveLink;
  if (foundExperiment) {
    archiveLink = {
      isLink: false,
      text: 'Archive',
      secondaryText: tr(
        'The feature flag {0} is currently used in running experiments. To archive, please pause any running experiments currently using this feature flag.',
        sanitizedFeatureFlagName,
      ),
      isWarning: true,
      minWidth: 150,
      onClick: () => {},
      testSection: `feature-flag-archive-button-disabled-${feature.get('id')}`,
    };
  } else {
    archiveLink = {
      text: 'Archive',
      onClick: onArchive,
      testSection: `feature-flag-archive-button-${feature.get('id')}`,
    };
  }

  const dropdownItems = feature.get('archived')
    ? [
        {
          text: 'Unarchive',
          isHidden: false,
          onClick: onUnarchive,
          testSection: `feature-flag-unarchive-button-${feature.get('id')}`,
        },
      ]
    : [archiveLink];

  return (
    <ListDropdown
      key={feature.get('id')}
      activator={
        <ButtonIcon
          iconFill="default"
          iconName="ellipsis-solid"
          size="small"
          style="plain"
          testSection={`feature-table-row-actions-${rowIndex}`}
        />
      }
      items={dropdownItems}
    />
  );
}

FeatureTableRowActions.propTypes = {
  archive: PropTypes.func.isRequired,
  canManageFeatureFlags: PropTypes.bool.isRequired,
  currentProjectRunningExperiments: PropTypes.instanceOf(Immutable.List)
    .isRequired,
  feature: PropTypes.instanceOf(Immutable.Map).isRequired,
  rowIndex: PropTypes.number.isRequired,
  unarchive: PropTypes.func.isRequired,
};

export default FeatureTableRowActions;

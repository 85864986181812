import actions from './actions';
import actionTypes from './action_types';
import constants from './constants';
import fns from './fns';
import getters from './getters';

export { actions, actionTypes, constants, fns, getters };

export default {
  actions,
  actionTypes,
  constants,
  fns,
  getters,
};

const flux = require('core/flux');

const actionTypes = require('./action_types');

exports.startPausing = layerId => {
  flux.dispatch(actionTypes.START_PAUSING, {
    layerId,
  });
};

exports.startPublishing = (layerId, isFirstPublish = false) => {
  flux.dispatch(actionTypes.START_PUBLISHING, {
    layerId,
    isFirstPublish,
  });
};

exports.startStarting = layerId => {
  flux.dispatch(actionTypes.START_STARTING, {
    layerId,
  });
};

exports.finishPausing = layerId => {
  flux.dispatch(actionTypes.FINISH_PAUSING, {
    layerId,
  });
};

exports.finishPublishing = layerId => {
  flux.dispatch(actionTypes.FINISH_PUBLISHING, {
    layerId,
  });
};

exports.finishStarting = layerId => {
  flux.dispatch(actionTypes.FINISH_STARTING, {
    layerId,
  });
};

import { toImmutable } from 'optly/immutable';
import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import constants from './constants';
import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const visibleGoals = [
  baseEntityGetters.entityCache,
  goals =>
    goals
      .filter(
        goal =>
          goal.get('goal_type') !== constants.TypeCodes.MOBILE_SESSION_GOAL,
      )
      .map(goal => {
        // map over each goal and ensure experiment_ids is unique XFLOW-232
        const dedupedIds = goal
          .get('experiment_ids', toImmutable([]))
          .toSet()
          .toList();
        return goal.set('experiment_ids', dedupedIds);
      })
      .toList(),
];

export const experimentToGoalsMap = [
  visibleGoals,
  /**
   * Returns an Immutable.Map of exp => list of goals
   */
  goals =>
    toImmutable({}).withMutations(goalsMap => {
      goals.forEach(goal => {
        goal.get('experiment_ids').forEach(expId => {
          if (!goalsMap.has(expId)) {
            goalsMap.set(expId, toImmutable([goal]));
          } else {
            goalsMap.update(expId, list => list.push(goal));
          }
        });
      });
    }),
];

export default {
  ...baseEntityGetters,
  visibleGoals,
  experimentToGoalsMap,
};

import React from 'react';
import PropTypes from 'prop-types';
import htmlSanitizer from 'sanitizer';

import { Button, Link as ExternalLink, Table } from 'optimizely-oui';
import { withTrack } from '@optimizely/segment-js/dist/decorators';

import { loadingWhenFetchAllPages } from 'core/modules/loading/actions';
import ui from 'core/ui';
import { connect } from 'core/ui/decorators';
import { isPageLoading } from 'core/modules/loading/getters';
import Immutable from 'optly/immutable';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import FilterableTableEnums from 'optly/modules/filterable_table/enums';
import FilterableTableFns from 'optly/modules/filterable_table/fns';
import NavConstants from 'optly/services/navigation';
import RecommenderActions from 'optly/modules/entity/recommender/actions';
import RecommenderStatsActions from 'optly/modules/entity/recommender_stats/actions';
import PermissionsGetters from 'optly/modules/permissions/getters';

import LoadingOverlay from 'react_components/loading_overlay';
import { SortableTableHeader } from 'react_components/sortable_table';

import DataLayerTopbar from 'bundles/p13n/components/data_layer_topbar';
import { pageableEntityTable } from 'bundles/p13n/components/entity_dashboard/entity_table';
import DashboardTableFilterControls from 'bundles/p13n/components/entity_dashboard/table_filter_controls';

import CatalogDialog from 'bundles/p13n/sections/implementation/components/recommendations/dialogs/catalog_dialog';
import RecommenderPreviewerDialog from 'bundles/p13n/sections/implementation/components/recommendations/dialogs/recommender_previewer_dialog';
import RecommenderDialog from 'bundles/p13n/sections/implementation/components/recommendations/dialogs/recommender_dialog';

import SectionModuleActions from 'bundles/p13n/sections/implementation/section_module/actions';
import SectionModuleConstants from 'bundles/p13n/sections/implementation/section_module/constants';
import SectionModuleFns from 'bundles/p13n/sections/implementation/section_module/fns';
import { RecommendationsHelpLink } from 'bundles/p13n/components/messaging/recommendations';

import RecommenderTableRow from './subcomponents/recommender_table_row';

const RECOMMENDER_ENTITY_PLURAL =
  SectionModuleConstants.ENTITY_HUMAN_READABLES.RECOMMENDER;
const RECOMMENDER_TABLE_ID = SectionModuleConstants.TABLE_IDS.RECOMMENDER;
const EntityTable = pageableEntityTable(RECOMMENDER_TABLE_ID);

@connect({
  canManageRecommendations: PermissionsGetters.canManageRecommendations,
  catalogs: CurrentProjectGetters.catalogs,
  isFirstPageLoading: isPageLoading(RECOMMENDER_TABLE_ID, 1),
  recommenders: CurrentProjectGetters.recommenders,
})
@withTrack
class RecommenderDashboard extends React.Component {
  static componentId = RECOMMENDER_TABLE_ID;

  static displayName = RECOMMENDER_TABLE_ID;

  static propTypes = {
    canManageRecommendations: PropTypes.bool.isRequired,
    /**
     * All catalogs (recommender services) in the current project.
     */
    catalogs: PropTypes.instanceOf(Immutable.Map).isRequired,
    isFirstPageLoading: PropTypes.bool.isRequired,
    /**
     * All recommenders that belong to all catalogs (recommender services) in the current project.
     */
    recommenders: PropTypes.instanceOf(Immutable.Map).isRequired,
    /**
     * Segment Tracking function handler
     */
    track: PropTypes.func,
  };

  static defaultProps = {
    track: () => {},
  };

  state = {
    isLoading: false,
  };

  /**
   * To archive the given recommender.
   *
   * @param {Immutable.Map} recommender
   */
  archiveRecommender = recommender => {
    ui.confirm({
      title: 'Confirm Archive Recommender?',
      message: 'Are you sure you want to archive this recommender?',
      confirmText: 'Archive recommender',
    })
      .then(() => {
        this.setState({ isLoading: true });
        return RecommenderActions.save({
          id: recommender.get('id'),
          archived: true,
        }).fail(() => {
          ui.showNotification({
            message: `Unable to archive the recommender: <b>${htmlSanitizer.escape(
              recommender.get('name')
            )}</b>`,
            type: 'error',
          });
        });
      })
      .always(() => this.setState({ isLoading: false }));
  };

  unarchiveRecommender = recommender => {
    ui.confirm({
      title: 'Confirm Unarchive Recommender?',
      message: 'Are you sure you want to unarchive this recommender?',
      confirmText: 'Unarchive recommender',
    })
      .then(() => {
        this.setState({ isLoading: true });
        return RecommenderActions.save({
          id: recommender.get('id'),
          archived: false,
        }).fail(() => {
          ui.showNotification({
            message: `Unable to unarchive the recommender: <b>${htmlSanitizer.escape(
              recommender.get('name')
            )}</b>`,
            type: 'error',
          });
        });
      })
      .always(() => this.setState({ isLoading: false }));
  };

  /**
   * To create a new recommender.
   */
  createRecommender = () => {
    SectionModuleActions.setCurrentlyEditingRecommender(Immutable.Map());
    ui.showReactDialog(RecommenderDialog, null, {
      fullScreen: true,
      isOuiDialog: true,
    });
  };

  /**
   * To edit the given recommender.
   *
   * When editing an existing recommender, the parent catalog is the current catalog that the recommender belongs to.
   *
   * @param {Immutable.Map} recommender
   */
  editRecommender = recommender => {
    const parentCatalog = this.getParentCatalog(recommender);

    SectionModuleActions.setCatalog(parentCatalog);
    SectionModuleActions.setCurrentlyEditingRecommender(recommender);

    ui.showReactDialog(RecommenderDialog, null, {
      fullScreen: true,
      isOuiDialog: true,
    });
  };

  /**
   * Filtering function to filter item by its name.
   *
   * @param item
   * @param search
   */
  filterItemByString = (item, search) =>
    FilterableTableFns.matchesFields(
      item,
      ['name', 'recommender_service_name'],
      search,
    );

  /**
   * Filtering function to filter item by its status (archived or active).
   *
   * @param item
   * @param status
   */
  filterItemByStatus = (item, status) =>
    FilterableTableFns.matchesArchivedStatus(item, status);

  fetchDataForStatus = status => {
    const { track } = this.props;

    loadingWhenFetchAllPages(
      RECOMMENDER_TABLE_ID,
      RecommenderActions.fetchAllPagesForAccount({
        archived: status === FilterableTableEnums.status.ARCHIVED,
      }),
    );

    track('Recommendations Recommender Dashboard Filter Status Applied', {
      status,
    });
  };

  /**
   * Find the parent catalog of the given recommender.
   *
   * @param {Immutable.Map} recommender
   * @returns {Immutable.Map} parent catalog of the given recommender.
   */
  getParentCatalog = recommender => {
    const { catalogs } = this.props;

    return catalogs.get(recommender.get('recommender_service_id'));
  };

  hasAnyCatalogs = () => {
    const { catalogs } = this.props;

    return !catalogs.isEmpty();
  };

  hasAnyRecommenders = () => {
    const { recommenders } = this.props;

    return !recommenders.isEmpty();
  };

  /**
   * To create a new catalog.
   */
  createCatalog = () => {
    SectionModuleActions.initializeCurrentlyEditingCatalog();
    ui.showReactDialog(CatalogDialog, null, {
      fullScreen: true,
      isOuiDialog: true,
    });
  };

  /**
   * Renders the Recommender Table Header
   */
  renderTableHeader = () => (
    <Table.TR>
      <SortableTableHeader field="name" type="string" width="60%">
        Name
      </SortableTableHeader>
      <SortableTableHeader
        field="recommender_service_name"
        type="string"
        width="15%">
        <span className="nowrap">Parent Catalog</span>
      </SortableTableHeader>
      <Table.TH>
        <span className="nowrap">Last Generated</span>
      </Table.TH>
      <SortableTableHeader field="id" type="number">
        ID
      </SortableTableHeader>
      {this.hasAnyRecommenders() && <Table.TH />}
      {this.hasAnyRecommenders() && <Table.TH />}
    </Table.TR>
  );

  renderRecommendersTableEmptyState = () => {
    const { canManageRecommendations } = this.props;

    return (
      <div
        className="push-quad--sides flex flex--1 flex--column"
        data-test-section="no-recommender-empty-state">
        <Table style="rule">
          <Table.THead>{this.renderTableHeader()}</Table.THead>
          <Table.TBody />
        </Table>
        {!this.hasAnyCatalogs() && (
          <div
            className="text--center overflow-y--auto flex flex--1 flex-column flex-justified--center"
            data-test-section="no-catalog-empty-state">
            <p className="alpha">Create a catalog first.</p>
            <div>
              Recommenders provide recommendations based on a catalog, so&nbsp;
              <ExternalLink
                isDisabled={!canManageRecommendations}
                onClick={
                  canManageRecommendations ? this.createCatalog : () => {}
                }
                testSection="create-catalog-action">
                create a catalog
              </ExternalLink>
              &nbsp;first.
            </div>
          </div>
        )}
      </div>
    );
  };

  /**
   * Renders the Recommender Table inside the Recommender Dashboard.
   */
  renderRecommendersTable = () => {
    const { isFirstPageLoading, recommenders } = this.props;

    return (
      <EntityTable
        data={recommenders.toList()}
        defaultFilters={{ status: FilterableTableEnums.status.ACTIVE }}
        defaultSortBy={{ field: 'name', type: 'string' }}
        entityPlural={RECOMMENDER_ENTITY_PLURAL}
        filterItemByStatus={this.filterItemByStatus}
        filterItemByString={this.filterItemByString}
        isLoading={isFirstPageLoading}
        renderTableHeader={this.renderTableHeader}
        renderTableRow={this.renderTableRow}
        tableId={RECOMMENDER_TABLE_ID}
        testSection="recommenders-dashboard-table"
      />
    );
  };

  /**
   * Renders the recommender dashboard header that includes
   *    - Help texts about recommenders.
   *    - TableFilterControls
   *    - Create New Recommender button.
   */
  renderDashboardHeader = () => {
    const { canManageRecommendations } = this.props;

    return (
      <React.Fragment>
        <div className="push-quad--sides push-double--top">
          Recommenders display recommendations from your catalog based on its
          algorithm, events, and filters.&nbsp;
          <RecommendationsHelpLink
            helpLink={SectionModuleFns.getHelpCopy('setup_recommender_link')}
            testSection="recommender-dashboard-subtitle"
          />
        </div>
        <div className="flex flex--none push-quad--sides push-double--ends">
          <DashboardTableFilterControls
            inputPlaceholder="Filter by recommender or catalog name..."
            inputWidth="width--1-3"
            onStatusChange={this.fetchDataForStatus}
            tableId={RECOMMENDER_TABLE_ID}
            statusOptions={[
              { label: 'Active', value: FilterableTableEnums.status.ACTIVE },
              {
                label: 'Archived',
                value: FilterableTableEnums.status.ARCHIVED,
              },
            ]}
          />
          {this.hasAnyCatalogs() && (
            <Button
              isDisabled={!canManageRecommendations}
              style="highlight"
              onClick={this.createRecommender}
              testSection="create-new-recommender-btn">
              Create New Recommender&hellip;
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  };

  /**
   * Render a single Recommender Table Row for the given recommender.
   *
   * @param {Immutable.Map} recommender
   */
  renderTableRow = recommender => {
    const { canManageRecommendations, track } = this.props;

    return (
      <RecommenderTableRow
        canManageRecommendations={canManageRecommendations}
        handleArchiveRecommender={this.archiveRecommender}
        handleEditRecommender={this.editRecommender}
        handleShowRecommenderPreviewer={this.showRecommenderPreviewer}
        handleUnarchiveRecommender={this.unarchiveRecommender}
        recommender={recommender}
        track={track}
      />
    );
  };

  /**
   * Render the Recommender Previewer Dialog for the given recommender.
   *
   * @param {Immutable.Map} recommender
   */
  showRecommenderPreviewer = recommender => {
    const parentCatalog = this.getParentCatalog(recommender);

    this.setState({ isLoading: true });

    RecommenderStatsActions.fetchRecommenderStatsByRecommenderId(
      recommender.get('id'),
      { skipEvaluatingCachedData: true },
    ).then(() => {
      ui.showReactDialog(
        RecommenderPreviewerDialog,
        {
          props: {
            catalog: parentCatalog,
            recommender,
          },
        },
        {
          fullScreen: true,
          isOuiDialog: true,
        },
      );

      this.setState({ isLoading: false });
    });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <LoadingOverlay
        className="flex flex--1 height--1-1"
        isLoading={isLoading}>
        <div
          className="stage__item__content--column"
          data-test-section="recommender-dashboard-main-content">
          <DataLayerTopbar
            activeTab={NavConstants.DataLayerTabs.RECOMMENDERS_DASHBOARD_TAB}
          />
          <div
            className="flex flex--column flex--1"
            data-test-section="recommenders-table">
            {this.renderDashboardHeader()}
            {this.hasAnyRecommenders()
              ? this.renderRecommendersTable()
              : this.renderRecommendersTableEmptyState()}
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default RecommenderDashboard;

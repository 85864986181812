import moment from 'moment';

export const OUTLIER_FILTERING_CUTOFF_DATE = moment('2018-05-22');

export const UNLIMITED_VALUE = Number.MAX_SAFE_INTEGER; // 2^53 - 1

export default {
  OUTLIER_FILTERING_CUTOFF_DATE,
  UNLIMITED_VALUE,
};

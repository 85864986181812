const CodeMirrorMixin = {
  // TODO consider moving CodeMirror methods from this file to this object
  /** ***********************************************************************************
   *  This mixin adds in functionality for handling code mirror operations
   *
   * @author Derek Hammond (derek@optimizely.com)
   *************************************************************************************
   */
  methods: {
    /**
     * Refresh provided CodeMirror instance
     *
     * @param cmInstance (e.g. this._codeMirrors.html)
     */
    refreshCodeMirror(cmInstance) {
      if (cmInstance) {
        cmInstance.refresh();
      }
    },
  },
};

export default CodeMirrorMixin;

exports.customCodePanelShown = ['custom_code', 'customCodePanelShown'];
exports.tabType = ['custom_code', 'tabType'];
exports.dirtyCustomCodeTabs = ['custom_code', 'dirtyCustomCodeTabs'];
/**
 * Getter to indicate if any of the custom code tabs are dirty.
 * @returns {Boolean}
 */
exports.hasDirtyCustomCode = [
  exports.dirtyCustomCodeTabs,
  function(customCodeTabs) {
    return customCodeTabs.filter(tab => tab).size !== 0;
  },
];

import { Store } from 'nuclear-js';

import { toImmutable } from 'optly/immutable';

import dateUtils from 'optly/utils/date';

import actionTypes from '../action_types';

export default Store({
  getInitialState() {
    return toImmutable({
      routesCompleted: 0,
      visualRoutesChanged: 0,
    });
  },

  initialize() {
    this.on(
      actionTypes.PERFORMANCE_TRACKING_ROUTE_COMPLETE,
      trackRouteComplete,
    );
    this.on(
      actionTypes.PERFORMANCE_TRACKING_SET_FRONTEND_RENDER_START_TIME,
      setFrontendRenderStartTime,
    );
    this.on(
      actionTypes.PERFORMANCE_TRACKING_SET_ROUTE_START_TIME,
      setRouteStartTime,
    );
    this.on(
      actionTypes.PERFORMANCE_TRACKING_VISUAL_ROUTES_CHANGED,
      trackVisualRoutesChanged,
    );
  },
});

function trackRouteComplete(state) {
  return state.set('routesCompleted', state.get('routesCompleted') + 1);
}

function setFrontendRenderStartTime(state) {
  return state.set('frontendRenderStartTime', dateUtils.getNow());
}

function setRouteStartTime(state) {
  return state.set('routeStartTime', dateUtils.getNow());
}

function trackVisualRoutesChanged(state) {
  return state.set('visualRoutesChanged', state.get('visualRoutesChanged') + 1);
}

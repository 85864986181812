/* Using eslint-disable flag due to dependency cycle error in legacy code */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';

import { Button, Sheet } from 'optimizely-oui';

import Immutable from 'optly/immutable';
import ui from 'core/ui';
import { connect } from 'core/ui/decorators';

import { enums as SupportEnums } from 'optly/modules/support';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { getters as UserGetters } from 'optly/modules/entity/user';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { getters as PermissionsGetters } from 'optly/modules/permissions';
import {
  getters as UIVersionGetters,
  enums as UIVersionEnums,
} from 'optly/modules/ui/version';
import { Wrapper } from 'react_components/dialog';
import LoadingOverlay from 'react_components/loading_overlay';
import { actions as SupportLevelsActions } from 'optly/modules/support_levels';

import Home from './home';
import ChooseCategory from './choose_category';
import ChooseCaseType from './choose_case_type';
import RequestForm from './request_form';
import TicketStatus from './ticket_status';
import handleAjaxError from '../../utils/handle_ajax_error';

const PLAN_NON_SUBSCRIBED_LEAD = 'Non_subscribed lead';

@connect({
  account: AdminAccountGetters.accountInfo,
  currentUser: UserGetters.currentUser,
  currentProject: CurrentProjectGetters.project,
  activeVersion: UIVersionGetters.activeVersion,
  canAccountUseOnlineTickets: PermissionsGetters.canAccountUseOnlineTickets,
})
class SupportDialog extends React.Component {
  static propTypes = {
    account: PropTypes.instanceOf(Immutable.Map).isRequired,
    activeVersion: PropTypes.string.isRequired,
    canAccountUseOnlineTickets: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
    errorId: PropTypes.string,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    errorId: '',
    onClose: ui.hideDialog,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentStep: SupportEnums.steps.HELP_CENTER_HOME,
      categoryId: '',
      caseType: null,
      requestStatus: null,
      zendeskTicketId: null,
      isEntitledToPrioritySupport: false,
    };
  }

  componentDidMount() {
    this.getSupportLevels();
  }

  getSupportLevels = () => {
    this.setIsLoading(true);
    SupportLevelsActions.getSupportLevels()
      .then(response => {
        this.setIsLoading(false);
        this.setState({
          isEntitledToPrioritySupport: response.is_entitled_to_priority_support,
        });
      })
      .fail(
        handleAjaxError(() => {
          this.setIsLoading(false);
          this.setState({
            isEntitledToPrioritySupport: false,
          });
        }),
      );
  };

  // Looks up plan display name and returns it, also noting if it's inactive.
  planDisplayName = () => {
    const { account } = this.props;
    if (!account) {
      return '';
    }
    if (account.get('planDisplayName') === PLAN_NON_SUBSCRIBED_LEAD) {
      return ' Optimizely'; // leading space left intentionally
    }
    if (account.get('isSubscribed')) {
      return ` ${account.get('planDisplayName')}`; // leading space left intentionally
    }
    return ` ${account.get('planDisplayName')} (inactive)`; // leading space left intentionally
  };

  switchStepHandler = newStep => {
    this.setState({ currentStep: newStep });
  };

  setCategoryId = categoryId => {
    this.setState({ categoryId });
  };

  setCaseType = caseType => {
    this.setState({ caseType });
  };

  setRequestStatus = requestStatus => {
    this.setState({ requestStatus });
  };

  setZendeskTicketId = ticketId => {
    this.setState({ zendeskTicketId: ticketId });
  };

  setIsLoading = isLoading => {
    this.setState({ isLoading });
  };

  render() {
    const {
      account,
      activeVersion,
      canAccountUseOnlineTickets,
      currentProject,
      currentUser,
      errorId,
      onClose,
    } = this.props;
    const {
      currentStep,
      categoryId,
      caseType,
      requestStatus,
      zendeskTicketId,
      isLoading,
      isEntitledToPrioritySupport,
    } = this.state;
    const {
      HELP_CENTER_HOME,
      CHOOSE_CATEGORY,
      CHOOSE_CASE_TYPE,
      REQUEST_FORM,
      TICKET_STATUS,
    } = SupportEnums.steps;

    return (
      <Sheet
        testSection="support-dialog"
        title="Optimizely Help Center"
        onClose={onClose}
        footerButtonList={[
          <Button
            key="cancel"
            onClick={onClose}
            style="plain"
            testSection="cancel-button">
            Cancel
          </Button>,
        ]}>
        <LoadingOverlay
          isLoading={isLoading}
          className="overflow-y--auto flex flex--1">
          <Wrapper>
            {currentStep === HELP_CENTER_HOME && (
              <Home
                canAccountUseOnlineTickets={canAccountUseOnlineTickets}
                isEntitledToPrioritySupport={isEntitledToPrioritySupport}
                isEmailVerified={currentUser.get('email_verified')}
                switchStepHandler={this.switchStepHandler}
              />
            )}
            {currentStep === CHOOSE_CATEGORY && (
              <ChooseCategory
                setCaseType={this.setCaseType}
                setCategoryId={this.setCategoryId}
                switchStepHandler={this.switchStepHandler}
              />
            )}
            {currentStep === CHOOSE_CASE_TYPE && (
              <ChooseCaseType
                categoryId={categoryId}
                setCaseType={this.setCaseType}
                switchStepHandler={this.switchStepHandler}
              />
            )}
            {currentStep === REQUEST_FORM && (
              <RequestForm
                accountId={account.get('accountId')}
                categoryId={categoryId}
                caseType={caseType}
                currentProject={currentProject}
                errorId={errorId}
                isOptimizelyX={
                  activeVersion === UIVersionEnums.versions.OPTIMIZELY_X
                }
                setCategoryId={this.setCategoryId}
                setIsLoading={this.setIsLoading}
                setRequestStatus={this.setRequestStatus}
                setZendeskTicketId={this.setZendeskTicketId}
                switchStepHandler={this.switchStepHandler}
                userPlanId={account.get('planId')}
                userLocale={account.get('userLocale')}
              />
            )}
            {currentStep === TICKET_STATUS && (
              <TicketStatus
                requestStatus={requestStatus}
                zendeskTicketId={zendeskTicketId}
              />
            )}
          </Wrapper>
        </LoadingOverlay>
      </Sheet>
    );
  }
}

export default SupportDialog;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CodeMirror from '@optimizely/react-codemirror';

// Enables syntax highlighting for javascript
import 'codemirror/mode/javascript/javascript';
// Enables linting for javascript
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';

/**
 * NOTE: The below options can be overidden or extended via the options prop. Here is a list of some popular options:
 * - placeholder {String}
 * - learn about others at https://codemirror.net/doc/manual.html
 */

export const DEFAULT_CODEMIRROR_OPTIONS = {
  // Custom written option to show errors in gutters
  annotations: true,
  // Add Ctrl-Enter to the keymap so it will fire the keyHandled event when pressed
  extraKeys: {
    'Ctrl-Enter': () => {},
    'Ctrl-Space': 'autocomplete',
  },
  fixedGutter: true,
  // Required for annotations to work
  gutters: ['CodeMirror-lint-markers'],
  lineNumbers: true,
  lineWrapping: true,
  matchBrackets: true,
  mode: 'javascript',
  tabSize: 2,
  lint: true,
};

/**
 * NOTE: This component can be used in controlled OR uncontrolled mode.
 * Controlled: https://reactjs.org/docs/forms.html#controlled-components
 * Uncontrolled: https://reactjs.org/docs/uncontrolled-components.html
 */

class ReactCodeMirror extends React.Component {
  static propTypes = {
    /**
     * Value of height in string.
     * If null, no max-height will be set.
     */
    height: PropTypes.string,
    /**
     * Defaults to 200px. If another number is provided, max-height will be set to that.
     * If null, no max-height will be set.
     */
    maxHeight: PropTypes.number,
    /** If true, the CodeMirror will be faded and uneditable */
    isReadOnly: PropTypes.bool,
    /** Function called on CodeMirror keypress, will be called with value and info arguments  */
    onChange: PropTypes.func.isRequired,
    /** Object of options that will extend DEFAULT_CODEMIRROR_OPTIONS. Override the defaults by providing a new value */
    options: PropTypes.object,
    /** name of data-test-section attribute */
    testSection: PropTypes.string,
    /** String value of CodeMirror content (for controlled mode) */
    value: PropTypes.string,
    /** Initial string value of CodeMirror content (for uncontrolled mode) */
    defaultValue: PropTypes.string,
  };

  static defaultProps = {
    isReadOnly: false,
    maxHeight: 200,
    options: {},
    testSection: '',
    height: null,
    value: null,
    defaultValue: null,
  };

  constructor(props) {
    super(props);

    /* bind non-standard methods, see: https://github.com/goatslacker/alt/issues/283 */
    this.assignRef = this.assignRef.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (this._codeMirror) {
        // not sure why this forced refresh is needed, but is necessary for proper rendering within modals
        this._codeMirror.getCodeMirror().refresh();
      }
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const { value, defaultValue } = this.props;
    // Only update the value this way if the component is controlled (using value instead of defaultValue)
    if (defaultValue === null && prevProps.value !== value) {
      const CM = this._codeMirror.getCodeMirror();
      const cursor = CM.getCursor();
      CM.setValue(value);
      // the cursor value sometimes changes after directly setting the value
      CM.setCursor(cursor);
    }
  }

  assignRef(c) {
    this._codeMirror = c;
  }

  render() {
    const {
      maxHeight,
      isReadOnly,
      onChange,
      options,
      testSection,
      value,
      height,
      defaultValue,
    } = this.props;
    const styles = {
      ...(Number.isFinite(maxHeight) ? { maxHeight } : {}),
      height,
    };
    const updatedOptions = Object.assign(
      {},
      DEFAULT_CODEMIRROR_OPTIONS,
      options,
      { readOnly: isReadOnly },
    );
    return (
      <div
        className={classNames(
          'border--all width--1-1 min-height--200 overflow-y--auto',
          {
            faded: isReadOnly,
          },
        )}
        style={styles}
        data-test-section={testSection}>
        <CodeMirror
          data-ui-component={true}
          ref={this.assignRef}
          onChange={onChange}
          options={updatedOptions}
          value={value}
          defaultValue={defaultValue}
        />
      </div>
    );
  }
}

export default ReactCodeMirror;

import React from 'react';
import PropTypes from 'prop-types';

import { OverlayWrapper, Popover, Link, Icon } from 'optimizely-oui';

const CodeStepHelpPopover = ({ links }) => (
  <OverlayWrapper
    behavior="click"
    horizontalAttachment="left"
    verticalAttachment="middle"
    overlay={
      <Popover>
        <span className="micro">
          <strong>Help</strong>
        </span>
        <br />
        {links.map(link => (
          <React.Fragment key={`code-step-link-${link.text}`}>
            <Link href={link.href} key={link.text} newWindow={true}>
              {link.text}
            </Link>
            <br />
          </React.Fragment>
        ))}
      </Popover>
    }
    shouldHideOnClick={true}>
    <span
      className="push--left"
      data-test-section="code-step-help-popover-icon">
      <Icon name="circle-question" size="medium" />
    </span>
  </OverlayWrapper>
);

CodeStepHelpPopover.propTypes = {
  links: PropTypes.instanceOf(Array),
};

CodeStepHelpPopover.defaultProps = {
  links: [],
};

CodeStepHelpPopover.componentId = 'CodeStepHelpPopover';

export default CodeStepHelpPopover;

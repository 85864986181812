/**
 * Slices the given array of elements and returns that slice
 * @param {string} prop Name of a property available on every element in the array
 * @param {string} value Acceptable value for prop
 */
module.exports = function(array, prop, value) {
  return array.filter(item => {
    if (value === 'true' || value === 'false') {
      value = JSON.parse(value);
    }
    return item[prop] === value;
  });
};

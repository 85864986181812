export const EXPERIMENTATION_GROUP_TYPES = {
  EXPERIMENT: 'Experiment',
  LAYER_EXPERIMENT: 'LayerExperiment',
  LAYER: 'Layer',
};
export const POLICY_TYPES = {
  OVERLAPPING: 'overlapping',
  RANDOM: 'random',
};

export default {
  EXPERIMENTATION_GROUP_TYPES,
  POLICY_TYPES,
};

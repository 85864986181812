/**
 * @author Tyler Brandt (tyler@optimizely.com)
 */
const Ratio = function(numerator, denominator) {
  this.numerator = numerator;
  this.denominator = denominator;
};

/** Finds the greatest common divisor of two integers
 *  using Euclid's algorithm: http://en.wikipedia.org/wiki/Euclidean_algorithm
 *
 *  @param {Number} a
 *  @param {Number} b
 */
const gcd = function(a, b) {
  return b === 0 ? a : gcd(b, a % b);
};

/**
 * Add two fractions together
 * @param {Ratio} b
 * @returns {Ratio}
 */
Ratio.prototype.add = function(b) {
  const a = this.clone();
  const lcm =
    (a.denominator / gcd(a.denominator, b.denominator)) * b.denominator;
  a.numerator =
    a.numerator * (lcm / a.denominator) + b.numerator * (lcm / b.denominator);
  a.denominator = lcm;
  return a.simplify();
};

Ratio.prototype.clone = function() {
  return new Ratio(this.numerator, this.denominator);
};

Ratio.prototype.equal = function(other) {
  const simplified = this.simplify();
  const otherSimplified = other.simplify();
  return (
    simplified.numerator === otherSimplified.numerator &&
    simplified.denominator === otherSimplified.denominator
  );
};

/**
 * Invert a fraction (swap numerator/denominator)
 * @returns {Ratio}
 */
Ratio.prototype.invert = function() {
  const a = this.clone();
  a.numerator = this.denominator;
  a.denominator = this.numerator;
  return a;
};

/**
 * Multiply two fractions
 * @param {Ratio} b
 * @returns {Ratio}
 */
Ratio.prototype.mult = function(b) {
  const a = this.clone();
  a.numerator *= b.numerator;
  a.denominator *= b.denominator;
  return a.simplify();
};

/**
 * Simplify a fraction
 * @returns {Ratio}
 */
Ratio.prototype.simplify = function() {
  const a = this.clone();
  if (a.numerator === 0 || a.denominator === 0) {
    return a;
  }
  const frac_gcd = gcd(a.denominator, a.numerator);
  a.numerator /= frac_gcd;
  a.denominator /= frac_gcd;
  return a;
};

module.exports = Ratio;

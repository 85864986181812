import PropTypes from 'prop-types';
import React from 'react';

// modules
import { Button, Input, SelectDropdown, Table } from 'optimizely-oui';
import { useFeature } from '@optimizely/react-sdk';

import FeatureConstants from 'optly/modules/entity/feature_flag/constants';
import FeatureFns from 'optly/modules/entity/feature_flag/fns';

// components

import VariableValueInput from 'bundles/p13n/components/variable_value_input';
import { fieldPropType } from 'react_components/form';

const FeatureVariableTableRow = ({ isDisabled, variableField, index }) => {
  const apiNameField = variableField.field('api_name');
  const typeField = variableField.field('type');
  const defaultValueField = variableField.field('default_value');

  const testSectionDisambiguator = apiNameField.getValue() || index;
  const [isFeatureVariableJSONEnabled] = useFeature('feature_variable_json');

  const renderErrorBlock = () => {
    if (apiNameField.getErrors().details.isValid) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section={`feature-dialog-variation-key-invalid-error-${testSectionDisambiguator}`}>
          Please enter a valid key. Valid keys contain alphanumeric characters,
          hyphens, and underscores, and are limited to 64 characters.
        </div>
      );
    }

    if (apiNameField.getErrors().details.isUnique) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section={`feature-dialog-variation-unique-key-error-${testSectionDisambiguator}`}>
          Key is already in use by another variable in this feature. Please
          choose a unique key.
        </div>
      );
    }

    // display error if variable value is invalid AND the variable value input has been editied
    if (variableField.getErrors().details.isDefaultValueValid) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section={`feature-dialog-variation-invalid-value-error-${testSectionDisambiguator}`}>
          {
            FeatureConstants.FEATURE_VARIABLE_ERRORS[typeField.getValue()]
              .valueError
          }
        </div>
      );
    }
  };

  const handleOnSelect = type => {
    if (type !== typeField.getValue()) {
      defaultValueField.setValue(FeatureFns.getDefaultValueForType(type));
    }
    typeField.setValue(type);
  };

  const handleOnRemove = () => {
    if (variableField.getValue().get('id')) {
      variableField.field('archived').setValue(true);
    } else {
      variableField.remove();
    }
  };

  return (
    <Table.TR>
      <Table.TD>
        <Input
          isDisabled={isDisabled}
          onChange={e => apiNameField.setValue(e.target.value)}
          testSection={`feature-dialog-variable-key-${testSectionDisambiguator}`}
          type="text"
          value={apiNameField.getValue()}
        />
        {renderErrorBlock()}
      </Table.TD>
      <Table.TD>
        <SelectDropdown
          isDisabled={
            // Changing variable types is not allowed after it has been created (if it has an ID)
            !!variableField.getValue().get('id') || isDisabled
          }
          fullWidth={true}
          minDropdownWidth={200}
          onChange={handleOnSelect}
          testSection={`feature-dialog-variable-type-select-${testSectionDisambiguator}`}
          value={typeField.getValue()}
          items={FeatureConstants.FEATURE_VARIABLE_TEXTS.filter(item => {
            if (isFeatureVariableJSONEnabled) {
              return true;
            }
            return item.value !== FeatureConstants.FEATURE_VARIABLE_TYPES.json;
          })}
        />
      </Table.TD>
      <Table.TD>
        <VariableValueInput
          field={defaultValueField}
          type={typeField.getValue()}
          disableVariable={isDisabled}
          testSectionPrefix={`feature-dialog-${testSectionDisambiguator}`}
        />
      </Table.TD>
      <Table.TD>
        <Button
          isDisabled={isDisabled}
          onClick={handleOnRemove}
          style="plain"
          testSection={`feature-dialog-variable-delete-button-${testSectionDisambiguator}`}>
          Delete
        </Button>
      </Table.TD>
    </Table.TR>
  );
};

FeatureVariableTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  variableField: fieldPropType.isRequired,
};

FeatureVariableTableRow.defaultProps = {
  isDisabled: false,
};

export default FeatureVariableTableRow;

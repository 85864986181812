import Immutable, { isImmutable } from 'optly/immutable';
import _ from 'lodash';

const WARNING_THRESHOLD_MS = 10;

/**
 * Utility function to use as a shouldComponentUpdate comparision
 * when a deep props/state equality check is needed.
 *
 * NOTE: If you dont need a deep equality check, just use React.PureComponent
 *
 * @param {Array} ignoreProps - A list of prop keys to ignore comparisons of.
 * @param {Array} ignoreState - A list of state keys to ignore comparisons of.
 * @return {function(nextProps, nextState): Boolean} - A function that can be used as a React Component's shouldComponentUpdate
 */
export const deepEqual = (ignoreProps = [], ignoreState = []) =>
  function(nextProps, nextState) {
    const t1 = window.performance.now();
    const propsChanged = _.some(this.props, (prop, key) => {
      if (ignoreProps.includes(key)) {
        return false;
      }
      const nextProp = nextProps[key];
      if (isImmutable(prop)) {
        return !Immutable.is(prop, nextProp);
      }
      return !_.isEqual(prop, nextProp);
    });

    const stateChanged = _.some(this.state, (state, key) => {
      if (ignoreState.includes(key)) {
        return false;
      }
      const nextStateVal = nextState[key];
      if (isImmutable(state)) {
        return !Immutable.is(state, nextStateVal);
      }
      return !_.isEqual(state, nextStateVal);
    });
    const elapsed = window.performance.now() - t1;
    if (elapsed > WARNING_THRESHOLD_MS) {
      // TODO - Should we send this to Sentry?
    console.warn(`[shouldComponentUpdate deepEqual] Comparison took ${elapsed}ms`); //eslint-disable-line
    }
    return propsChanged || stateChanged;
  };

export default {
  deepEqual,
};

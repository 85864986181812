export const TOKEN_NAME_PREFIX = 'public_api.access_token';
export const TOKEN_MESSAGE_DATA_EVENT_TYPE = 'OPTLY_IMPLICIT_AUTH_DATA';
export const TOKEN_MESSAGE_ERROR_EVENT_TYPE = 'OPTLY_IMPLICIT_AUTH_ERROR';
export const TOKEN_MESSAGE_SESSION_EXPIRED_EVENT_TYPE =
  'OPTLY_IMPLICIT_AUTH_SESSION_EXPIRED';
export const TOKEN_FETCH_TIMEOUT_SECONDS = 10;
export const TOKEN_FETCH_MAX_ATTEMPTS = 6;
export const TOKEN_FETCH_TIMEOUT_RETRY_INCREMENT = 4;
export const TOKEN_FETCH_LOGOUT_SENTRY_FINGERPRINT =
  'TOKEN_FETCH_LOGOUT_SENTRY_FINGERPRINT';
export const TOKEN_FETCH_TIMEOUT_SENTRY_FINGERPRINT =
  'TOKEN_FETCH_TIMEOUT_SENTRY_FINGERPRINT';
export const PUBLIC_API_INIT_FAILURE_SENTRY_FINGERPRINT =
  'PUBLIC_API_INIT_FAILURE_SENTRY_FINGERPRINT';
export const PUBLIC_API_REQUEST_FAILURE_SENTRY_FINGERPRINT =
  'PUBLIC_API_REQUEST_FAILURE_SENTRY_FINGERPRINT';
export const BASE_ERROR_FINGERPRINT = 'PUBLIC_API_CONSUMER_ERROR';
export const errorTypes = {
  csrfMismatch: {
    MESSAGE:
      'Public API token fetch failed due to an invalid response CSRF token.',
    FINGERPRINT: 'PUBLIC_API_TOKEN_FETCH_CSRF_MISMATCH_ERROR',
  },
  timeout: {
    MESSAGE: 'Public API token fetch request timed out.',
    FINGERPRINT: 'PUBLIC_API_TOKEN_FETCH_TIMEOUT_ERROR',
  },
  retryExhaustion: {
    MESSAGE: 'Public API token fetch request timed out. Giving up.',
    FINGERPRINT: 'PUBLIC_API_TOKEN_FETCH_RETRY_EXHAUSTION_ERROR',
    DEV_MESSSAGE:
      ' **ACTION REQUIRED**, your local datastore needs to be seeded with a registered Oauth client!  See: https://github.com/optimizely/optimizely/blob/devel/src/www/docs/getting_started.md#seed-the-local-datastore-with-a-registered-application',
  },
  incompatibleEnvironment: {
    MESSAGE: 'Cannot make public API requests in this environment.',
    FINGERPRINT: 'PUBLIC_API_TOKEN_FETCH_ENVIRONMENT_ERROR',
  },
  expiredSession: {
    MESSAGE: 'Public API token fetch failed due to an expired session.',
    FINGERPRINT: 'PUBLIC_API_TOKEN_FETCH_EXPIRED_SESSION_ERROR',
  },
  iframeFailure: {
    MESSAGE: 'Public API token fetch failed due to an error.',
    FINGERPRINT: 'PUBLIC_API_TOKEN_FETCH_IFRAME_FAILURE_ERROR',
  },
  unauthorized: {
    MESSAGE: 'Public API request was unauthorized.',
    FINGERPRINT: 'PUBLIC_API_TOKEN_FETCH_UNAUTHORIZED_ERROR',
  },
  forbidden: {
    MESSAGE: 'Public API request was forbidden.',
    FINGERPRINT: 'PUBLIC_API_TOKEN_FETCH_FORBIDDEN_ERROR',
  },
  serviceException: {
    MESSAGE: 'Public API request failed due to service error.',
    FINGERPRINT: 'PUBLIC_API_TOKEN_FETCH_SERVICE_EXCEPTION_ERROR',
  },
  notFound: {
    MESSAGE: 'Public API request could not find resource.',
    FINGERPRINT: 'PUBLIC_API_TOKEN_FETCH_NOT_FOUND_ERROR',
  },
  generalHttpFailure: {
    MESSAGE: 'Public API request failed.',
    FINGERPRINT: 'PUBLIC_API_TOKEN_FETCH_GENERAL_HTTP_ERROR',
  },
};

export default {
  TOKEN_NAME_PREFIX,
  TOKEN_MESSAGE_DATA_EVENT_TYPE,
  TOKEN_MESSAGE_ERROR_EVENT_TYPE,
  TOKEN_MESSAGE_SESSION_EXPIRED_EVENT_TYPE,
  TOKEN_FETCH_TIMEOUT_SECONDS,
  TOKEN_FETCH_MAX_ATTEMPTS,
  TOKEN_FETCH_TIMEOUT_RETRY_INCREMENT,
  TOKEN_FETCH_LOGOUT_SENTRY_FINGERPRINT,
  TOKEN_FETCH_TIMEOUT_SENTRY_FINGERPRINT,
  PUBLIC_API_INIT_FAILURE_SENTRY_FINGERPRINT,
  PUBLIC_API_REQUEST_FAILURE_SENTRY_FINGERPRINT,
  BASE_ERROR_FINGERPRINT,
  errorTypes,
};

/**
 * Module specific pure functions
 *
 * WARNING: this file is currently being used from the preview_ui bundle.
 * Currently, this means we SHOULD NOT use any ES7+ syntax/functions and need to be very careful
 * about the dependencies referenced in this module.
 */
import filter from 'optly/utils/filter';

import enums from './enums';

/**
 * Generic function for filtering based solely on string matches
 * @param {Immutable} thingsToFilter
 * @param {String} filter String to filter on
 * @param {Array<String>} fieldsToFilter Array of the fields we want to filter on
 * @param {String} matchType Type that we want to match on
 */
export function filterFieldsByString(
  thingsToFilter,
  filter,
  fieldsToFilter,
  matchType,
) {
  const filterString = filter ? filter.toString().toLowerCase() : '';
  if (!filterString) {
    return thingsToFilter;
  }

  return thingsToFilter.filter(item =>
    fieldsToFilter.some(field => {
      const fieldValue = (item.get(field) || '').toString().toLowerCase();
      switch (matchType) {
        case enums.matchType.EQUALS:
          return equals(filterString, fieldValue);
        default:
          return contains(filterString, fieldValue);
      }
    }),
  );
}

function equals(filter, value) {
  return filter === value;
}

function contains(filter, value) {
  return value.indexOf(filter) !== -1;
}

/**
 * Generic function for filtering based on archived field
 *    ex: to filter to just archived events, call the function as follows
 *        filterByArchivedStatus(event, enums.status.ARCHIVED);
 * @param {Immutable.List} thingsToFilter
 * @param {String} status filter to archive by (either 'archived' or 'active')
 * @param {Function(Immutable.Map)} function to determine if the entity is archived
 * @return {Immutable.List} status filter to archive by (either 'archived' or 'active')
 */
export function filterByArchivedStatusWithCustomArchive(
  thingsToFilter,
  status,
  isItemArchived,
) {
  return thingsToFilter.filter(item => {
    const archivedFilter = status === enums.status.ARCHIVED;
    return isItemArchived(item) === archivedFilter;
  });
}

/**
 * Generic function for filtering based on archived field
 *    ex: to filter to just archived events, call the function as follows
 *        filterByArchivedStatus(event, enums.status.ARCHIVED);
 * @param {Immutable.List} thingsToFilter
 * @param {String} status filter to archive by (either 'archived' or 'active')
 * @return {Immutable.List} status filter to archive by (either 'archived' or 'active')
 */
export function filterByArchivedStatus(thingsToFilter, status) {
  return this.filterByArchivedStatusWithCustomArchive(
    thingsToFilter,
    status,
    item => item.get('archived'),
  );
}

/**
 * Generic function for filtering based on a list of accepted field values
 *    ex: to filter for any items that contain active or draft in the status field, call as follows
 *        filterFromListOfValues(events, toImmutable(['active', 'draft']), 'status');
 * @param {Immutable.List} thingsToFilter
 * @param {Immutable.List} filterConditions
 * @param {String} fieldToFilterOn
 * @returns {Immutable.List}
 */
export function filterByListOfValues(
  thingsToFilter,
  filterConditions,
  fieldToFilterOn,
) {
  return thingsToFilter.filter(item =>
    filterConditions.includes(item.get(fieldToFilterOn)),
  );
}

/**
 * Generic function for get access into immutable or non immutable object
 */
function getFn(item, field) {
  return item.get ? item.get(field) : item[field];
}

export function matchesFields(item, fields, string) {
  if (!Array.isArray(fields)) {
    fields = [fields];
  }

  return fields
    .map(field => {
      const fieldValue = getFn(item, field);
      // Stringify number field values so they can be searched like strings
      return typeof fieldValue === 'number' ? String(fieldValue) : fieldValue;
    })
    .some(content => filter.isFilterTermInItem(string, content));
}

export function matchesArchivedStatus(item, status) {
  if (status === enums.status.ARCHIVED) {
    return !!item.get('archived');
  }

  return !item.get('archived');
}

export default {
  filterByArchivedStatus,
  filterByArchivedStatusWithCustomArchive,
  filterByListOfValues,
  filterFieldsByString,
  getFn,
  matchesArchivedStatus,
  matchesFields,
};

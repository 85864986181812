import classNames from 'classnames';
import { Input, Link } from 'optimizely-oui';
import PropTypes from 'prop-types';
import React from 'react';

import { LABEL, LINKS, STATUSES } from './constants';

/**
 * Simple component for rendering Email Address field with links.
 * @param {Object} props - Properties passed to component
 * @returns {ReactElement}
 */
function EmailAddressField({
  isSsoEnabled,
  isVerified,
  onChangeLinkClick,
  onVerifyLinkClick,
  value,
  visible,
  managedByAdminCenter,
}) {
  // Create classes for fieldset
  const labelClasses = 'oui-label';
  const labelVerifiedClasses = classNames({
    'color--bad-news': !isVerified,
    'color--good-news': isVerified,
  });

  // Determine verified label text
  const labelText = isVerified ? STATUSES.VERIFIED : STATUSES.UNVERIFIED;

  // Conditionally render email verification link
  const verificationLink = !isVerified && (
    <Link testSection="verify-email-link" onClick={onVerifyLinkClick}>
      {LINKS.VERIFY}
    </Link>
  );

  // Return field and error
  return (
    visible && (
      <div data-ui-component={true}>
        <div className={labelClasses} data-test-section="email-address-label">
          {LABEL} <span className={labelVerifiedClasses}>({labelText})</span>
        </div>
        <Input
          isDisabled={true}
          isRequired={true}
          testSection="email-address-field"
          type="text"
          value={value}
        />
        <div className="push--top">{verificationLink}</div>
        {!isSsoEnabled && !managedByAdminCenter && (
          <Link testSection="change-email-link" onClick={onChangeLinkClick}>
            {LINKS.CHANGE}
          </Link>
        )}
      </div>
    )
  );
}

EmailAddressField.propTypes = {
  /** Sets whether the user is signed in via single sign on */
  isSsoEnabled: PropTypes.bool,
  /** Sets whether the user's email address has been verified */
  isVerified: PropTypes.bool,
  /** Sets event handler for 'Change Email Address' link clicks */
  onChangeLinkClick: PropTypes.func,
  /** Sets event handler for 'Verify Email Address' link clicks */
  onVerifyLinkClick: PropTypes.func,
  /** Sets value of the field */
  value: PropTypes.string,
  /** Sets whether the field is visible in the current form instance */
  visible: PropTypes.bool,
  /** Sets whether the user is managed by admin center */
  managedByAdminCenter: PropTypes.bool,
};

EmailAddressField.defaultProps = {
  isSsoEnabled: false,
  isVerified: false,
  onChangeLinkClick: () => {},
  onVerifyLinkClick: () => {},
  value: '',
  visible: false,
  managedByAdminCenter: false,
};

export default EmailAddressField;

/**
 * Contains constants used by models.
 */

const tr = require('optly/translate');

// local storage key for most recently viewed project id
exports.LAST_VIEWED_PROJECT_ID = 'lastViewedProjectId';

// default locale to use
exports.DEFAULT_LOCALE = 'en_US';

// list of all available locales
exports.LOCALES = [
  { locale: 'de_DE', title: 'Deutsch', translatedTitle: tr('German') },
  {
    locale: 'en_US',
    title: 'English (US)',
    translatedTitle: tr('English (US)'),
  },
  {
    locale: 'es_ES',
    title: 'Español (España)',
    translatedTitle: tr('Spanish (Spain)'),
  },
  { locale: 'fr_FR', title: 'Français', translatedTitle: tr('French') },
  { locale: 'ja_JP', title: '日本語', translatedTitle: tr('Japanese') },
];

exports.FEATURE_DETAILS = {
  sdkWarningText:
    'Improvements are coming! Please ensure your SDK meets the minimum requirements to use targeted rollouts.',
  sdkWarningKbLink:
    'https://docs.developers.optimizely.com/rollouts/docs/roll-out-and-roll-back-features',
};

exports.PREFIX_REGEX = /^[a-zA-Z]+([-_][a-zA-Z0-9]+)*[-_]*$/;

exports.PREFIX_DEFAULT_VALUE = 'data-';

exports.CONCLUSION_MAX_LEN = 350;

import keyMirror from 'optly/utils/key_mirror';

export const actionTypes = keyMirror({
  // DASHBOARD ACTIONS
  DASHBOARD_SWITCH_MAIN_TAB: null,
  DASHBOARD_SWITCH_SUB_TAB: null,
  DASHBOARD_SWITCH_CANVAS_INTEGRATION: null,

  DASHBOARD_SET_TABLE_FILTERS: null,
  DASHBOARD_RESET_TABLE_FILTERS: null,

  DASHBOARD_SELECT_TABLE_ITEM: null,
  DASHBOARD_DESELECT_TABLE_ITEM: null,

  // DASHBOARD ACTIVITY ACTIONS
  DASHBOARD_SELECT_ACTIVITY_FILTER: null,
  DASHBOARD_SET_NEXT_PAGE_ACTIVITY_FILTER: null,
  DASHBOARD_SET_ACTIVITY_PAGE_RESULTS_LENGTH: null,

  DASHBOARD_SELECT_INTEGRATION: null,

  DASHBOARD_SET_LABS_DATA: null,

  DASHBOARD_SET_TEST_PARAMETERS: null,

  // DASHBOARD CUSTOMIZATION
  DASHBOARD_SET_COLUMNS_VISIBILITY: null,
  DASHBOARD_SET_ACTIVE_TAB: null,
});

export default {
  ...actionTypes,
};

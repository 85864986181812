import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input, TextField, Textarea } from 'optimizely-oui';

import { enums as ViewEnums } from 'optly/modules/entity/view';

import APIName from 'bundles/p13n/components/targeting/url_config/api_name';

const PageInformation = props => {
  const {
    errors,
    viewId,
    apiName,
    onUpdate,
    editorUrl,
    description,
    activationType,
    canUseViewActivationModeManual,
  } = props;

  const textFieldChanged = (event, fieldName) => {
    onUpdate(fieldName, event.target.value);
  };

  const isNewPage = !viewId;
  const showAPIName =
    !isNewPage || activationType === ViewEnums.activationModes.MANUAL;

  return (
    <fieldset
      className="push-quad--bottom"
      data-test-section="configure-view-smart-page_information">
      <Input
        type="text"
        id="edit_url"
        name="edit_url"
        placeholder="URL"
        isRequired={true}
        label="Editor URL"
        note={errors.edit_url}
        value={editorUrl || ''}
        displayError={!!errors.edit_url}
        onChange={event => textFieldChanged(event, 'edit_url')}
        testSection="configure-view-smart-textfield-input-edit_url"
        helpText="The editor URL will be used if the page is loaded in the visual editor."
      />
      <div className="push-double--top">
        <Textarea
          label="Description"
          value={description || ''}
          placeholder="Add a description"
          onChange={event => textFieldChanged(event, 'description')}
          testSection="page-description"
        />
      </div>
      {showAPIName && (
        <div
          className="soft-double--top flex"
          data-test-section="page-information-api-name-id">
          <div className="push-double--right">
            <APIName
              error={errors.api_name}
              apiName={apiName || ''}
              onChange={event => textFieldChanged(event, 'api_name')}
              canUseViewActivationModeManual={canUseViewActivationModeManual}
            />
          </div>
          {!isNewPage && (
            <div data-test-section="page-information-id">
              <Label>ID</Label>
              <div>{viewId}</div>
            </div>
          )}
        </div>
      )}
    </fieldset>
  );
};

PageInformation.propTypes = {
  errors: PropTypes.shape({
    edit_url: PropTypes.string,
    api_name: PropTypes.string,
  }),
  editorUrl: PropTypes.string,
  activationType: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  canUseViewActivationModeManual: PropTypes.bool.isRequired,
  viewId: PropTypes.number,
  apiName: PropTypes.string,
};

PageInformation.defaultProps = {
  apiName: '',
  viewId: null,
};

export default PageInformation;

import keyMirror from 'optly/utils/key_mirror';

export const Tabs = keyMirror({
  VARIATIONS: null,
  AUDIENCES: null,
  METRICS: null,
  SCHEDULE: null,
  TRAFFIC_ALLOCATION: null,
  WHITELIST: null,
  API_NAMES: null,
  HISTORY: null,
  SETTINGS: null,
});

export const ExperimentType = keyMirror({
  AB_TEST: null,
  FEATURE_TEST: null,
  MULTIARMED_BANDIT: null,
  MVT: null,
});

export const ExperimentTypeToLabel = {
  AB_TEST: tr('A/B Test'),
  FEATURE_TEST: tr('Feature Test'),
  MULTIARMED_BANDIT: tr('Multi-Armed Bandit'),
  MVT: tr('Multivariate Test'),
};

export const ExperimentTypeToEntityName = {
  AB_TEST: tr('experiment'),
  FEATURE_TEST: tr('experiment'),
  MULTIARMED_BANDIT: tr('optimization'),
  MVT: tr('experiment'),
};

export const EMPTY_METRICS_POPOVER_CONTENT_AB = `You need to add at least one metric to start this experiment.
Metrics will generate experiment results and help you measure performance.`;

export default {
  Tabs,
  ExperimentType,
  ExperimentTypeToEntityName,
  ExperimentTypeToLabel,
  EMPTY_METRICS_POPOVER_CONTENT_AB,
};

import Nuclear from 'nuclear-js';

import Immutable, { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

/**
 * uiRegions store
 * Responsible for the following state management:
 * Storing what components are in a region
 */
export default Nuclear.Store({
  getInitialState() {
    return toImmutable({});
  },

  initialize() {
    this.on(actionTypes.REGISTER_COMPONENT, registerComponent);
    this.on(actionTypes.UNREGISTER_COMPONENT, unregisterComponent);
    this.on(actionTypes.REGISTER_REGION, registerRegion);
    this.on(actionTypes.UNREGISTER_REGION, unregisterRegion);
    this.on(actionTypes.QUEUE_REGION_COMPONENT, queueComponent);
    this.on(actionTypes.UNQUEUE_REGION_COMPONENT, unqueueComponent);
  },
});

/**
 * Stores reference to a region
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {ReactComponent} payload.navigationController
 */
function registerRegion(state, payload) {
  return state.set(
    payload.regionId,
    Immutable.Map({
      navigationController: payload.navigationController,
    }),
  );
}

/**
 * Stores reference to a component in a region
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.regionId
 * @param {String} payload.componentId
 * @param {Object} payload.component
 */
function registerComponent(state, payload) {
  return state.mergeIn(
    [payload.regionId],
    Immutable.Map({
      componentId: payload.componentId,
      component: payload.component,
    }),
  );
}

/**
 * Stores reference to a region
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.regionId
 * @param {Object} payload.directive
 */
function unregisterComponent(state, payload) {
  return state.mergeIn(
    [payload.regionId],
    Immutable.Map({
      componentId: null,
      component: null,
    }),
  );
}

/**
 * Queues a component to be mounted in a region.  Happens when the region
 * isn't available when `ui.renderRegion` is called.
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.regionId
 * @param {Object} payload.component
 * @param {Object} payload.componentId
 */
function queueComponent(state, payload) {
  const queuedComponent = Immutable.Map({
    component: payload.component,
    componentId: payload.componentId,
  });
  return state.setIn(['componentsToMount', payload.regionId], queuedComponent);
}

/**
 * Unqueue a component from unmounted components queue
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.regionId
 */
function unqueueComponent(state, payload) {
  return state.setIn(['componentsToMount', payload.regionId], null);
}

/**
 * Removes a region from the registration
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.regionId
 */
function unregisterRegion(state, payload) {
  return state.remove(payload.regionId);
}

import schema from 'js-schema';

/**
 * @typedef {Object} Variation
 * @property {string} id
 * @property {String|null} name
 * @property {Object.<string, Change[]>} actions Mapping of pageId to changes (see [client-change-applier](https://github.com/optimizely/client-change-applier))
 */
/**
 * Schema for Variation
 */
export const Variation = schema({
  id: String,
  name: [null, String],
  actions: Array.of(Object),
});

export default {
  Variation,
};

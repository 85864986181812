import _ from 'lodash';

import flux from 'core/flux';
import reactDialog from 'core/ui/components/react';

import vueHelpers from 'optly/utils/vue_helpers';
import { waitUntil } from 'optly/utils/poll';

import UIGetters from '../getters';

/**
 * Helper util to invoke the method provided on the dialogManager as
 * soon as it is available. Necessary because some callers may utilize the
 * manager before it has been mounted in the DOM.
 * @param {string} method - The method to invoke on the instance of the dialogManager
 * @param {array<any>} args
 */
const whenManagerReady = (method, ...args) => {
  const waitForManager = () =>
    waitUntil(getDialogManager, 3, 5).then(getDialogManager);
  const manager = getDialogManager();
  if (manager) {
    return manager[method](...args);
  }
  return waitForManager().then(m => m[method](...args));
};

/**
 * Returns the instance of the dialog manager component
 * @return {Vue}
 */
export function getDialogManager() {
  const currentDialogManager = flux.evaluate(UIGetters.dialogManager);
  if (!currentDialogManager) {
    console.warn(
      'No DialogManager component instantiated and registered globally',
    );
  }
  return currentDialogManager;
}

/**
 * Shows a dialog in a specific dialog frame
 *
 * @param {Object} options
 * @param {string} options.component
 * @param {object?} options.data
 * @param {boolean} options.noEscape
 *
 * @return {Vue} component instance
 */
export function showDialog(options) {
  return whenManagerReady('showDialog', options);
}

/**
 * Hides the top most visible dialog
 */
export function hideDialog() {
  return whenManagerReady('hideDialog');
}

/**
 * Shows a react dialog in a specific dialog frame
 *
 * @param {React.Component<any>|React.FC<any>} reactComponent
 * @param {Object} [config]
 * @param {Object} [config.dataBindings]
 * @param {Object} [config.props]
 * @param {Object} [options]
 *
 * @return {Vue} component instance
 */
export function showReactDialog(reactComponent, config, options) {
  return showDialog(
    _.merge(
      {
        component: vueHelpers.extendComponent(reactDialog, {
          component: reactComponent,
          dataBindings: (config && config.dataBindings) || {},
          props: (config && config.props) || {},
        }),
      },
      options || {},
    ),
  );
}

export default {
  getDialogManager,
  showDialog,
  hideDialog,
  showReactDialog,
};

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

export const currentUser = [
  baseEntityGetters.entityCache,
  function(users) {
    if (users.count() > 1) {
      throw new Error('There should be at most one user in store.');
    }
    return users.toList().get(0);
  },
];

export default {
  ...baseEntityGetters,
  currentUser,
};

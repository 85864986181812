import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import pushStateHandler from 'optly/utils/push_state';

export const RouterLink = ({
  ariaLabel,
  children,
  className,
  href,
  isDisabled,
  testSection,
}) => {
  const classes = classNames(className, {
    'link--disabled': isDisabled,
    'pointer-events--none': isDisabled,
  });

  return (
    <a
      aria-label={ariaLabel}
      data-ui-component={true}
      href={href}
      className={classes}
      data-test-section={testSection}
      data-track-id={testSection}
      onClick={event => pushStateHandler(href, event)}
      disabled={isDisabled}>
      {children}
    </a>
  );
};

RouterLink.propTypes = {
  /** Label to use for screen readers */
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  testSection: PropTypes.string,
};

RouterLink.defaultProps = {
  ariaLabel: '',
  className: '',
  isDisabled: false,
  testSection: '',
};

export default RouterLink;

/**
 * Helper functions to create a canonical representation of
 * an API request
 *
 * Including the method: fetch, fetchAll, fetchPage
 * model.entity
 * model.parent (entity, parent)
 * requestParams: <object>
 *
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
import _ from 'lodash';
import Immutable from 'optly/immutable';

/**
 * Creates a canonical requestInfo object that holds
 * information such as entity, method and args
 * @param {string} entity
 * @param {string} method
 * @param {object} args
 * @param {Boolean} shouldClearCache  -- indicates whether the entity has stale data from localStorage and needs a fetchAll to refresh
 * @return {object}
 */
export default function(entity, method, args, shouldClearCache) {
  if (_.isNumber(args)) {
    args = {
      id: args,
    };
  }

  return Immutable.fromJS({
    entity,
    method,
    requestArgs: args,
    shouldClearCache: !!shouldClearCache,
  });
}

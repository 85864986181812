import PropTypes from 'prop-types';
import React from 'react';
import ui from 'core/ui';
import { Link } from 'optimizely-oui';
import Editor from 'bundles/p13n/modules/editor';

import RedirectUrlInput from './subcomponents/redirect_url_input';
import RedirectFnInput from './subcomponents/redirect_fn_input';

export function RedirectInputToggler({ toggleHandler, urlTypeSelected }) {
  if (urlTypeSelected) {
    return (
      <span>
        <span className="weight--bold">URL</span> |{' '}
        <Link
          testSection="toggle-redirect-mode"
          variant="default"
          onClick={toggleHandler}>
          {' '}
          Code{' '}
        </Link>
      </span>
    );
  }
  return (
    <span>
      <Link
        testSection="toggle-redirect-mode"
        variant="default"
        onClick={toggleHandler}>
        {' '}
        URL{' '}
      </Link>{' '}
      | <span className="weight--bold"> Code </span>
    </span>
  );
}

RedirectInputToggler.propTypes = {
  toggleHandler: PropTypes.func.isRequired,
  urlTypeSelected: PropTypes.bool.isRequired,
};

export class RedirectInput extends React.Component {
  toggleUI = () => {
    Editor.actions.setChangeRedirectProperty(
      'isRedirectUrl',
      !this.props.currentlyEditingChange.get('isRedirectUrl'),
    );
  };

  setRedirectUrl = dest => {
    // Update the dest as shown in the UI so that the user isn't surprised if http:// is added.
    dest = Editor.fns.formatRedirectUrl(dest);
    Editor.actions.setChangeRedirectProperty('dest', dest);
  };

  setRedirectFunction = destFn => {
    Editor.actions.setChangeRedirectProperty('dest_fn', destFn);
  };

  render() {
    const { currentlyEditingChange } = this.props;
    const isRedirectUrl = currentlyEditingChange.get('isRedirectUrl', false);

    return (
      <div>
        <RedirectInputToggler
          urlTypeSelected={isRedirectUrl}
          toggleHandler={this.toggleUI}
        />

        {isRedirectUrl && (
          // guard against an undefined
          <RedirectUrlInput
            destValue={currentlyEditingChange.get('dest') || ''}
            onValueUpdated={this.setRedirectUrl}
          />
        )}
        {!isRedirectUrl && (
          <RedirectFnInput
            destFnValue={
              currentlyEditingChange.get('dest_fn') ||
              Editor.constants.DEFAULT_REDIRECT_DESTINATION_FUNCTION
            }
            onValueUpdated={this.setRedirectFunction}
          />
        )}
      </div>
    );
  }
}

RedirectInput.propTypes = {
  currentlyEditingChange: PropTypes.object.isRequired,
};

export default ui.connectGetters(RedirectInput, {
  currentlyEditingChange: Editor.getters.currentlyEditingChange,
});

const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const _ = require('lodash');

const enums = require('bundles/p13n/modules/editor_iframe/enums');

const editorMocksActionTypes = require('../action_types');
const editorActionTypes = require('../../editor_iframe/action_types');

/**
 * store to hold mock state for the EditorIframeMock component
 */
module.exports = Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      // mapping of viewId => ElementInfo[]
      viewElementInfo: {},
      iframes: {},
    });
  },

  initialize() {
    this.on(editorMocksActionTypes.P13N_EDITOR_IFRAME_MOCKS_ADD, addMock);

    this.on(
      editorMocksActionTypes.P13N_EDITOR_IFRAME_MOCKS_SET_VIEW_ELEMENT_INFO,
      setElementInfo,
    );

    this.on(
      editorMocksActionTypes.P13N_EDITOR_IFRAME_MOCKS_APPLY_CHANGES,
      setChanges,
    );

    this.on(
      editorMocksActionTypes.P13N_EDITOR_IFRAME_MOCKS_LOG_ACTION,
      logAction,
    );

    // original action handlers
    this.on(editorActionTypes.P13N_EDITOR_IFRAME_REMOVE_IFRAME, removeIFrame);

    this.on(
      editorActionTypes.P13N_EDITOR_IFRAME_HIGHLIGHT_ELEMENT,
      highlightElement,
    );

    this.on(
      editorActionTypes.P13N_EDITOR_IFRAME_UNHIGHLIGHT_ELEMENT,
      unhighlightElement,
    );

    this.on(
      editorActionTypes.P13N_EDITOR_IFRAME_UNHIGHLIGHT_ALL_ELEMENTS,
      unhighlightAllElements,
    );
  },
});

const DEFAULT_ENTRY = toImmutable({
  id: null,
  actionLog: [],
  // mapping of selector => elementInfo
  elementInfo: {},
  loadedUrl: null,
  changes: [],
  highlightedElements: [],
  width: '100%',
  selectedDevice: enums.Sizes.full,
  isLandscapeMode: false,
});

/**
 * @param {Immutable.Map} state
 * @param {Object<viewId, ElementInfo>} payload
 */
function setElementInfo(state, payload) {
  return state.withMutations(mutableState => {
    _.each(payload, (elementInfo, viewId) => {
      mutableState.setIn(
        ['viewElementInfo', Number(viewId)],
        toImmutable(elementInfo).toList(),
      );
    });
  });
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.iframeId
 * @param {Object} payload.changes
 */
function setChanges(state, payload) {
  const changes = toImmutable(payload.changes);
  return state.setIn(['iframes', payload.iframeId, 'changes'], changes);
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {string} payload.iframeId id to index this under
 */
function addMock(state, payload) {
  return state.setIn(['iframes', payload.iframeId], DEFAULT_ENTRY);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.iframeId
 * @param {Object} payload.action
 * @param {String} payload.action.type
 * @param {Array} payload.action.args
 */
function logAction(state, payload) {
  return state.updateIn(['iframes', payload.iframeId, 'actionLog'], actionLog =>
    actionLog.push(toImmutable(payload.action)),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {string} payload.id
 */
function removeIFrame(state, payload) {
  return state.deleteIn(['iframes', payload.id]);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 * @param {number} payload.selector
 * @param {number} payload.type
 */
function highlightElement(state, payload) {
  if (!isMockedIframe(state, payload.id)) {
    return state;
  }
  return state.updateIn(
    ['iframes', payload.id, 'highlightedElements'],
    highlighted => {
      const index = highlighted.findIndex(
        element =>
          element.selector === payload.selector &&
          element.type === payload.type,
      );
      if (index > -1) {
        return highlighted.push(
          toImmutable({
            selector: payload.selector,
            type: payload.type,
          }),
        );
      }
      return highlighted;
    },
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 * @param {number} payload.selector
 * @param {number} payload.type
 */
function unhighlightElement(state, payload) {
  if (!isMockedIframe(state, payload.id)) {
    return state;
  }
  return state.updateIn(
    ['iframes', payload.id, 'highlightedElements'],
    highlighted => {
      const index = highlighted.findIndex(
        element =>
          element.selector === payload.selector &&
          element.type === payload.type,
      );
      if (index > -1) {
        return highlighted.delete(index);
      }
      return highlighted;
    },
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 */
function unhighlightAllElements(state, payload) {
  if (!isMockedIframe(state, payload.id)) {
    return state;
  }
  return state.setIn(
    ['iframes', payload.id, 'highlightedElements'],
    toImmutable([]),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {String} iframeId
 */
function isMockedIframe(state, iframeId) {
  return state.hasIn(['iframes', iframeId]);
}

/**
 * Widget change sidebar component (referred to in the product as an "Extension")
 * ===
 * Edits a change corresponding to showing a widget in the editor
 *
 * This component must be instantiated imperatively and passed certain data properties
 *
 * Example Usage:
 *
 * ui.renderRegion('p13n-editor-sidebar', {
 *   component: require('bundles/p13n/components/editor/sidebar/widget_config_sidebar'),
 *   data: {
 *     _widget: widget,
 *     _editorIframeId: this.activeFrameId,
 *     _change: change,
 *   },
 * });
 *
 * Required data params
 * `_widget` - The widget definition
 * `_change` - A WidgetChange object, optional, if supplied it's implied you are editing that change
 *
 */
const _ = require('lodash');
const Vue = require('vue');
const htmlSanitizer = require('sanitizer');

const { isFeatureEnabled } = require('@optimizely/js-sdk-lab/src/actions');

const cloneDeep = require('optly/clone_deep').default;
const flux = require('core/flux');
const ui = require('core/ui').default;

const CurrentlyEditingPluginActions = require('bundles/p13n/sections/plugin_builder/modules/currently_editing_plugin/actions');
const CurrentlyEditingPluginGetters = require('bundles/p13n/sections/plugin_builder/modules/currently_editing_plugin/getters');
const Editor = require('bundles/p13n/modules/editor');
const EditorIframeActions = require('bundles/p13n/modules/editor_iframe/actions');
const P13NUIActions = require('bundles/p13n/modules/ui/actions').default;
const PermissionsModuleGetters = require('optly/modules/permissions/getters');
const PluginConfigFormModule = require('bundles/p13n/sections/plugin_builder/components/plugin_config_form');

const { Features } = require('optly/utils/enums');

const Template = require('./template.html');
const ChangeTimingDropdown = require('../change_timing_dropdown');
const SidebarHeader = require('../sidebar_header').default;

const PluginConfigForm = Vue.extend(PluginConfigFormModule);

module.exports = {
  replace: true,

  componentId: 'p13n-widget-config-sidebar',

  components: {
    'change-timing-dropdown': ChangeTimingDropdown,
    'sidebar-header': SidebarHeader,
  },

  template: Template,

  data: {
    includeTimingLabel: false,
    isEditing: false,
    isDestroyed: false,
    timingDropdownTypes: Editor.constants.TimingDropdownTypes,
  },

  computed: {
    isDirty() {
      const { formValues } = this;
      const { isEditing } = this;

      if (!isEditing) {
        // in the case of creation change is always dirty
        return true;
      }

      const existingAsyncIsDirty =
        !_.isUndefined(this._originalChange.async) &&
        this._originalChange.async !== this.isAsync;
      const newAsyncIsDirty =
        _.isUndefined(this._originalChange.async) && this.isAsync;
      return (
        !_.isEqual(formValues, this._originalChange.config) ||
        existingAsyncIsDirty ||
        newAsyncIsDirty
      );
    },

    /**
     * The current Change given the form values
     */
    editingChange() {
      return _.extend({}, this._change, {
        config: this.formValues,
        async: this.isAsync,
      });
    },

    isTemplatesEnabled() {
      return (
        isFeatureEnabled(Features.USE_OPTIMIZELY_TEMPLATES) ||
        isFeatureEnabled(Features.USE_MERGED_TEMPLATES)
      );
    },
  },

  methods: {
    /**
     * Applies all existing changes plus the updates made from this component
     */
    applyEditingChanges() {
      const changes = flux.evaluateToJS(
        Editor.getters.currentlyEditingActionFormatted(
          true,
          this.editingChange,
        ),
      );
      EditorIframeActions.applyChanges(this._editorIframeId, changes);
    },

    /**
     * Applies the change state before any modifications by this component
     */
    applyExistingChanges() {
      const changes = flux.evaluateToJS(
        Editor.getters.currentlyEditingActionFormatted(true),
      );
      EditorIframeActions.applyChanges(this._editorIframeId, changes);
    },

    /**
     * Delete this change
     */
    deleteChange() {
      ui.confirm({
        title: tr('Are you sure you want to delete these changes?'),
        message: tr(
          'The deletion will not take effect until the next publish.',
        ),
        confirmText: tr('Delete Change'),
        isWarning: true,
      }).then(() => {
        const changeName = this._change.name || this.widget.name;
        const saveDef = Editor.actions
          .deleteChange(this._change.id)
          .then(() => {
            ui.showNotification({
              message: tr(
                'Your changes on the extension <b>{0}</b> have been deleted.',
                htmlSanitizer.escape(changeName),
              ),
              type: 'warning',
            });
            this.applyExistingChanges();
            P13NUIActions.showChangeListSidebar(
              this.changeListSidebarComponentConfig,
            );
          });
        ui.loadingWhen('widget-editor-sidebar', saveDef);
      });
    },

    /**
     * Sets the async property based on an update from the component. Update
     * includes isAsync property and instanceIdentifer property.
     * @param isAsync
     */
    handleChangeTimingChanged(payload) {
      this.isAsync = payload.isAsync;
    },
  },

  created() {
    if (!this._widget || !this._editorIframeId) {
      throw new Error('Must supply `_widget` and `_editorIframeId`');
    }

    this.widget = cloneDeep(this._widget);
    CurrentlyEditingPluginActions.loadPlugin(this.widget);

    // if passed this._change denotes that we are editing an existing widget change
    if (this._change) {
      this.isEditing = true;
      this._change = cloneDeep(this._change);
      this._originalChange = cloneDeep(this._change);
      this.widget.form_schema.forEach(field => {
        // eslint-disable-next-line no-prototype-builtins
        if (!this._change.config.hasOwnProperty(field.name)) {
          this._change.config[field.name] = field.default_value;
        }
      });
      // load formValues from the saved change
      CurrentlyEditingPluginActions.setFormValues(this._change.config);
    }

    flux.bindVueValues(this, {
      changeListSidebarComponentConfig:
        Editor.getters.changeListSidebarComponentConfig,
      // bind the form values from the PluginConfigForm
      formValues: CurrentlyEditingPluginGetters.formValues,
      shouldShowAsyncChanges: PermissionsModuleGetters.canUseAsyncChanges,
    });

    flux.observe(
      CurrentlyEditingPluginGetters.formValues,
      _.debounce(() => {
        if (this.isDestroyed) {
          return;
        }
        this.applyEditingChanges();
      }, 250),
    );

    if (!this.isEditing) {
      this._change = Editor.fns.createWidgetChange({
        name: this.widget.name,
        widget_id: this.widget.plugin_id,
        config: this.formValues,
      });
    }
    this.isAsync = this._change.async;
  },

  ready() {
    const formOptions = {
      parent: this,
      el: this.$$.form,
      data: {
        allowSchemaEdit: false,
        activeFrameId: this._editorIframeId,
        inVariationEditor: true,
      },
      onUpdateFormValue(data) {
        CurrentlyEditingPluginActions.setFormValue(data.name, data.value);
      },
      formValuesGetter: CurrentlyEditingPluginGetters.formValues,
    };
    if (this.isEditing) {
      formOptions.formValuesDirtyStatusGetter = Editor.getters.extensionChangeFieldStatuses(
        this._change.id,
        CurrentlyEditingPluginGetters.formValues,
      );
      formOptions.onRevert = fieldConfig => {
        CurrentlyEditingPluginActions.setFormValue(
          fieldConfig.name,
          this._change.config[fieldConfig.name],
        );
        CurrentlyEditingPluginActions.stopEditingSelector();
      };
      formOptions.data.changeId = this._change.id;
    }
    // mount the form, MUST use new here due to how Vue initializes
    new PluginConfigForm(formOptions); // eslint-disable-line no-new

    if (!this.isEditing) {
      this.applyEditingChanges();
    }
  },

  beforeDestroy() {
    CurrentlyEditingPluginActions.reset();
    this.isDestroyed = true;
  },
};

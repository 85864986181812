import React from 'react';
import { Button, Table } from 'optimizely-oui';
import PropTypes from 'prop-types';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import Immutable from 'optly/immutable';

// components
import EmptyState from 'bundles/p13n/components/entity_dashboard/empty_state';
import DashboardEntityTable from 'bundles/p13n/components/entity_dashboard/entity_table';
import { SortableTableHeader } from 'react_components/sortable_table';
import DashboardTableFilterControls from 'bundles/p13n/components/entity_dashboard/table_filter_controls';
// eslint-disable-next-line import/no-cycle
import GroupDetailDialog from 'bundles/p13n/components/dialogs/configure_group';

// modules
import {
  enums as FilterableTableEnums,
  fns as FilterableTableFns,
} from 'optly/modules/filterable_table';
import {
  actions as GroupModuleActions,
  fns as GroupModuleFns,
} from 'optly/modules/entity/experimentation_group';

import { Features } from 'optly/utils/enums';

import ExtensionsEmptyStateImage from '/static/img/p13n/extensions-empty-state.svg';

// eslint-disable-next-line import/no-cycle
import { connect } from 'core/ui/decorators';
import PermissionsGetters from 'optly/modules/permissions/getters';

import GroupTableRow from './group_table_row';

@connect({
  canUsePersonalization: PermissionsGetters.canUsePersonalization,
})
class GroupTable extends React.Component {
  state = {
    isGroupDetailDialogLoading: false,
  };

  constructor(props) {
    super(props);

    this.createGroup = this.createGroup.bind(this);
    this.renderEmptyState = this.renderEmptyState.bind(this);
    this.renderTableRow = this.renderTableRow.bind(this);
    this.renderTableHeader = this.renderTableHeader.bind(this);
  }

  filterItemByStatus = (item, status) =>
    FilterableTableFns.matchesArchivedStatus(item, status);

  filterItemByString = (item, string) =>
    FilterableTableFns.matchesFields(item, ['name', 'description'], string);

  renderTableHeader = () => (
    <tr>
      <SortableTableHeader field="name" type="string">
        Exclusion Group
      </SortableTableHeader>
      <Table.TH>Experiments</Table.TH>
      <Table.TH>Available Traffic</Table.TH>
      <SortableTableHeader field="created" type="date">
        Created
      </SortableTableHeader>
      <SortableTableHeader field="last_modified" type="date">
        Modified
      </SortableTableHeader>
      <Table.TH />
    </tr>
  );

  createGroup() {
    // disable 'Create New Exclusion Group' button to prevent double clicks.
    // These double clicks tend to load dialog twice.
    this.setState({
      isGroupDetailDialogLoading: true,
    });

    const newGroup = GroupModuleFns.createExperimentationGroupEntity({
      project_id: this.props.currentProjectId,
    });

    const data = {
      group: newGroup,
    };

    GroupDetailDialog.show(
      groupToSave => GroupModuleActions.save(groupToSave),
      data,
    );

    // We need to enable 'Create New Exclusion Group' button after dialog is shown.
    // As GroupDetailDialog.show is not returning a promise, we are bound to enable
    // button after some fixed delay.
    setTimeout(
      () =>
        this.setState({
          isGroupDetailDialogLoading: false,
        }),
      500,
    );
  }

  renderEmptyState() {
    const { canUsePersonalization } = this.props;
    return (
      <EmptyState
        createButton={{
          onClick: this.createGroup,
          testSection: 'empty-state-create-group-button',
          isDisabled: !this.props.canCreateGroup,
          label: <span>Create New Exclusion Group&hellip;</span>,
        }}
        headline="Create mutually exclusive experiments"
        description={
          <div>
            Exclusion groups can be used to keep your
            {isFeatureEnabled(Features.M1_P13N) && canUsePersonalization
              ? ' optimizations'
              : ' experiments'}{' '}
            mutually exclusive.{' '}
            <a
              href={this.props.helpUrl}
              target="_blank"
              rel="noopener noreferrer">
              Learn more.
            </a>
          </div>
        }
        imagePath={ExtensionsEmptyStateImage}
      />
    );
  }

  renderTableRow(group) {
    return (
      <GroupTableRow
        key={group.get('id')}
        group={group}
        canArchiveGroup={this.props.canArchiveGroup}
      />
    );
  }

  render() {
    const { isGroupDetailDialogLoading } = this.state;
    if (this.props.isEmptyState) {
      return this.renderEmptyState();
    }

    return (
      <div className="flex flex--1 flex--column">
        <div className="flex flex--none push-quad--sides push-double--ends">
          <DashboardTableFilterControls
            tableId={this.props.tableId}
            inputPlaceholder="Filter by name or description"
            inputWidth="width--300"
            statusOptions={[
              { label: 'Active', value: FilterableTableEnums.status.ACTIVE },
              {
                label: 'Archived',
                value: FilterableTableEnums.status.ARCHIVED,
              },
            ]}
          />
          <Button
            isDisabled={
              !this.props.canCreateGroup || isGroupDetailDialogLoading
            }
            style="highlight"
            onClick={this.createGroup}
            testSection="create-group-button">
            Create New Exclusion Group...
          </Button>
        </div>
        <DashboardEntityTable
          tableId={this.props.tableId}
          data={this.props.groups}
          entityPlural="exclusion groups"
          filterItemByString={this.filterItemByString}
          filterItemByStatus={this.filterItemByStatus}
          renderTableRow={this.renderTableRow}
          renderTableHeader={this.renderTableHeader}
          defaultSortBy={{ field: 'name', type: 'string' }}
          defaultFilters={{ status: FilterableTableEnums.status.ACTIVE }}
        />
      </div>
    );
  }
}

GroupTable.propTypes = {
  canArchiveGroup: PropTypes.bool.isRequired,
  canCreateGroup: PropTypes.bool.isRequired,
  currentProjectId: PropTypes.number.isRequired,
  groups: PropTypes.instanceOf(Immutable.List).isRequired,
  helpUrl: PropTypes.string.isRequired,
  isEmptyState: PropTypes.bool.isRequired,
  tableId: PropTypes.string.isRequired,
};

export default GroupTable;

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

import definition from './entity_definition';
import fns from './fns';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

const currentProjectPluginLayerCounts = [
  baseEntityGetters.entityCache,
  CurrentProjectGetters.id,
  fns.getLayerCountsofProject,
];

export default {
  ...baseEntityGetters,
  currentProjectPluginLayerCounts,
};

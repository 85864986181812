import EventActions from 'optly/modules/entity/event/actions';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerExperimentActions from 'optly/modules/entity/layer_experiment/actions';

import EventInspectorDashboard from './main_content';

export const component = EventInspectorDashboard;

/**
 * Fetch all unarchived experiments and variations in the current project.
 *
 * @param {Object} ctx
 * @param {Function} next
 */
export const routingSetup = (ctx, next) => {
  const currentProjectId = ctx.currentProject.id;

  const fetchPolicies = [
    LayerEnums.policy.SINGLE_EXPERIMENT,
    LayerEnums.policy.MULTIVARIATE,
  ];

  // Fetch all unarchived events and layer experiments in the current project in the background.
  EventActions.fetchAllPages({
    archived: false,
    project_id: currentProjectId,
  });
  LayerExperimentActions.fetchAllByStatus({
    archived: false,
    byPage: true,
    policies: fetchPolicies,
    projectId: currentProjectId,
  });

  return next();
};

export default {
  component,
  routingSetup,
};

import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'optimizely-oui';

import flux from 'core/flux';
import Immutable from 'optly/immutable';

import Editor from 'bundles/p13n/modules/editor';
import LayerExperimentHumanReadable from 'optly/modules/entity/layer_experiment/human_readable';
import P13NUIActions from 'bundles/p13n/modules/ui/actions';

import {
  Dropdown,
  DropdownContents,
  DropdownListItem,
  DropdownBlockLink,
} from 'react_components/dropdown';

class VariationSwitcher extends React.Component {
  static propTypes = {
    currentLayerExperimentOrSection: PropTypes.instanceOf(Immutable.Map)
      .isRequired,
    onActionSelect: PropTypes.func.isRequired,
    selectedVariationId: PropTypes.number.isRequired,
    selectedView: PropTypes.instanceOf(Immutable.Map).isRequired,
    variationsInCurrentLayerExperimentOrSection: PropTypes.instanceOf(
      Immutable.List,
    ).isRequired,
  };

  handleVariationSwitcherClick = variation => {
    const switchToView = this.props.selectedView.toJS();
    const experimentOrSectionId = this.props.currentLayerExperimentOrSection.get(
      'id',
    );
    const currentlyEditingChangeIsDirty = flux.evaluate(
      Editor.getters.currentlyEditingChangeIsDirty,
    );
    P13NUIActions.confirmNavigation(
      currentlyEditingChangeIsDirty,
      LayerExperimentHumanReadable.CHANGE,
      () => {
        Editor.actions.unsetCurrentlyEditingChange();
        Editor.actions.unselectAction();
        const data = {
          view: switchToView,
          experimentOrSectionId,
        };
        if (
          variation.get('variation_id') ===
          Editor.constants.EVERYONE_ELSE_AUDIENCE.variation_id
        ) {
          data.isReadOnlyMode = true;
        } else {
          data.variationId = variation.get('variation_id');
        }
        this.props.onActionSelect(data);
      },
    );
  };

  render() {
    const { variationsInCurrentLayerExperimentOrSection } = this.props;
    return (
      <div
        className="sidebar-variation-switcher push--bottom push-double--top"
        data-test-section="variation-switcher">
        <Dropdown
          width="100%"
          activator={
            <button
              type="button"
              className="oui-button oui-button--full soft--left text--left"
              data-test-section="variation-switcher-dropdown">
              <div className="flex" data-test-section="selected-variation-name">
                <div className="flex--1 truncate">
                  {flux.evaluate(Editor.getters.selectedVariationDisplayName)}
                </div>
                <div className="text--right">
                  <span>
                    <Icon name="angle-down" size="small" />
                  </span>
                </div>
              </div>
            </button>
          }>
          <DropdownContents>
            {variationsInCurrentLayerExperimentOrSection.map(variation => (
              <VariationOption
                key={variation.get('variation_id')}
                variation={variation}
                isSelected={
                  variation.get('variation_id') ===
                  this.props.selectedVariationId
                }
                onVariationClick={this.handleVariationSwitcherClick}
              />
            ))}
          </DropdownContents>
        </Dropdown>
      </div>
    );
  }
}

class VariationOption extends React.Component {
  static propTypes = {
    isSelected: PropTypes.bool.isRequired,
    onVariationClick: PropTypes.func.isRequired,
    variation: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  onClick = () => {
    this.props.onVariationClick(this.props.variation);
  };

  render() {
    return (
      <DropdownListItem hideOnClick={true}>
        <DropdownBlockLink
          onClick={this.onClick}
          testSection="variation-switcher-option">
          <span className={this.props.isSelected ? 'color--base' : ''}>
            {this.props.variation.get('name')}
          </span>
        </DropdownBlockLink>
      </DropdownListItem>
    );
  }
}

export { VariationSwitcher };

export default VariationSwitcher;

import keyMirror from 'optly/utils/key_mirror';

export const GENERIC_PLANS_PAGE_LINK = 'https://www.optimizely.com/plans';
export const PP2020_PLANS_PAGE_LINK =
  'https://files.marketing.cmp.optimizely.com/download/48baf25843b911efa8ddd66b96c6b4bb';
export const FeatureLimitTypes = keyMirror({
  CHANGE_HISTORY_DAYS: null,
  RUNNING_EXPERIMENT_LIMIT: null,
  NUMBER_OF_SEATS_LIMIT: null,
});

export const FeatureLimitUIs = keyMirror({
  CHANGE_HISTORY_DATE_PICKER: null,
  CHANGE_HISTORY_TABLE_FOOTER: null,
  INVITE_COLLABORATORS_FOOTER: null,
});

export const UpgradeVariables = {
  UPGRADE_LINK: GENERIC_PLANS_PAGE_LINK,
  PP2020_UPGRADE_LINK: PP2020_PLANS_PAGE_LINK,
  GLOBAL_NAV_BUTTON_TEXT: 'See Upgrade Options',
  GLOBAL_NAV_SUBHEADING_TEXT: 'Expand your Rollouts feature set',
  UPSELL_DIALOG_BUTTON_TEXT: 'View upgrade options',
};
export const UpgradeDialogHeaders = {
  UPGRADE_TO_ESSENTIALS: 'Do more with Essentials',
  GENERIC_UPGRADE: 'Upgrade for more',
};

export const UpgradeDialogMessages = featureLimit => {
  return {
    [FeatureLimitTypes.CHANGE_HISTORY_DAYS]:
      'For access to an unlimited log of changes, upgrade your plan.',
    [FeatureLimitTypes.RUNNING_EXPERIMENT_LIMIT]: `You can create unlimited feature tests, but only run ${featureLimit} at a time. To start this feature test, pause a running test or upgrade your account.`,
    [FeatureLimitTypes.NUMBER_OF_SEATS_LIMIT]: `You have reached your limit of ${featureLimit} users. To add more, upgrade your plan.`,
  };
};

export default {
  GENERIC_PLANS_PAGE_LINK,
  FeatureLimitTypes,
  FeatureLimitUIs,
  UpgradeVariables,
  UpgradeDialogHeaders,
  UpgradeDialogMessages,
};

import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

/**
 * optimizelyOnOptimizelyStore
 * Responsible for state management of Optimizely on Optimizely testing module.
 */
export default Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      featureToUserId: {},

      /**
       * Stores a hash for the current user state of the ReactSDK
       * simply for the purpose of observing changes to it.
       */
      reactSDKUserState: null,
    });
  },

  initialize() {
    this.on(actionTypes.SET_REACT_SDK_USER_STATE, setJSSDKLabState);
    this.on(actionTypes.SET_USER_ID_FOR_FEATURE, setUserIdForFeature);
    this.on(actionTypes.REMOVE_USER_ID_FOR_FEATURE, removeUserIdForFeature);
  },
});

/**
 * Set the given ReactSDK userState in our store.
 * @param {Immutable.Map} state
 * @param {Immutable.Map} payload
 * @return {Immutable.Map} - New state
 */
function setJSSDKLabState(state, payload) {
  return state.setIn(['reactSDKUserState'], payload);
}

/**
 * Set the userId for the given feature.
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Object} payload.feature
 * @param {Object} payload.userId
 */
function setUserIdForFeature(state, { feature, userId }) {
  return state.setIn(['featureToUserId', feature], userId);
}

/**
 * Unset the userId for the given feature.
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Object} payload.feature
 */
function removeUserIdForFeature(state, { feature }) {
  return state.deleteIn(['featureToUserId', feature]);
}

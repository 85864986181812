/**
 * Module specific enums/constants for Optimizely on Optimizely.
 *   https://app.optimizely.com/v2/projects/8351122416
 */

import keyMirror from 'optly/utils/key_mirror';

/**
 * List of attribuets available in the Optimizely on Optimizely Project:
 *   https://app.optimizely.com/v2/projects/8351122416/audiences/attributes
 */
export const ATTRIBUTES = keyMirror({
  fe_api_entity_type: null,
});

/**
 * List of events available in the Optimizely on Optimizely Project:
 *   https://app.optimizely.com/v2/projects/8351122416/implementation/events
 */
export const EVENTS = keyMirror({
  fetch_all_paginated_execution_time: null,
  share_link_copy: null,
  share_link_email: null,
  click_calendar_open: null,
});

/**
 * List of features available in the Optimizely on Optimizely Project:
 *   https://app.optimizely.com/v2/projects/8351122416/features
 */
export const FEATURES = {
  /**
   * Feature which controls all Opti ID frontend and backend functionality.
   */
  opti_id_switch: {
    FEATURE_KEY: 'opti_id_switch',
  },
  /**
   * Feature which controls the MAU dashboard UI.
   */
  zuora_uncoupling: {
    FEATURE_KEY: 'zuora_uncoupling',
  },
  /**
   * Feature which controls the MAU dashboard UI.
   */
  mau_visualization: {
    FEATURE_KEY: 'mau_visualization',
  },
  /**
   * Feature which controls the datafile authentication UI.
   */
  datafile_authentication: {
    FEATURE_KEY: 'datafile_authentication',
  },
  /**
   * Feature which controls whether or not a user can convert
   * a regular Environment to a Secure one.
   */
  enable_datafile_authentication: {
    FEATURE_KEY: 'datafile-authentication-enable-button',
  },

  /**
   * Feature which controls the Event Inspector UI (OASIS-7000)
   */
  event_inspector: {
    FEATURE_KEY: 'event_inspector',
  },

  /**
   * Feature which controls the sending flag decisions UI (OASIS-7265)
   */
  send_flag_decisions: {
    FEATURE_KEY: 'send_flag_decisions',
  },

  /**
   * Feature which controls whether the fetchAll or fetchAllPages method
   * is used to implement a fetchAll.
   */
  fetch_all_paginated: {
    FEATURE_KEY: 'fetch_all_paginated',
    VARIABLES: keyMirror({
      // How many concurrent requests to make.
      concurrent_request_limit: null,
      // The size used for the first page of a fetchAllPages call.
      initial_page_size: null,
      // The size used for a fetchAllPages call.
      page_size: null,
    }),
  },

  /**
   * Feature which enables/disables text-based queries against the search API
   */
  use_search_api_for_sonic_search_boxes: {
    FEATURE_KEY: 'use_search_api_for_sonic_search_boxes',
  },

  /**
   * Feature which enables/disables separate email and password during login
   */
  use_separate_email_password_for_login: {
    FEATURE_KEY: 'use_separate_email_password_for_login',
  },

  /**
   * Feature which controls the data source for the search-based tables
   */
  use_search_api_for_sonic_tables: {
    FEATURE_KEY: 'use_search_api_for_sonic_tables',
    VARIABLES: keyMirror({
      // Integer: Time to wait before reloading search results after an entity update
      entity_update_reindex_delay_ms: null,
    }),
  },

  /**
   * Feature to display the Impression Usage page
   * - Updates Account Settings `Plan & Billing` Tab text to `Billing`
   * - Adds new `Usage` Tab
   * - Removes `UsageDetails` from `Plan & Billing` page
   */
  impression_usage_page: {
    FEATURE_KEY: 'impression_usage_page',
  },

  /**
   * Temporary feature flag to use while converting audiences dashboard table to sonic
   */
  use_sonic_for_audience_table: {
    FEATURE_KEY: 'use_sonic_for_audience_table',
  },

  /**
   * Temporary feature flag to use while converting pages dashboard table to search API
   */
  use_search_api_for_pages: {
    FEATURE_KEY: 'use_search_api_for_pages',
  },

  /**
   * Feature which controls the visibility of the redesigned AudienceCombinationsBuilder
   */
  audience_combo_reskin: {
    FEATURE_KEY: 'audience_combo_reskin',
  },

  /**
   * Feature which controls the Optibot Chassis (EC3-627)
   */
  optibot_chassis: {
    FEATURE_KEY: 'optibot_chassis',
  },

  /**
   * Feature which disables creating new Full Stack projects with Legacy Experience
   */
  disable_legacy_full_stack_creation: {
    FEATURE_KEY: 'disable_legacy_full_stack',
  },

  /**
   * Feature that controls the visibility of the FX Change History Webhooks UI (APPX-6991)
   */
  fx_change_history_webhooks: {
    FEATURE_KEY: 'fx_change_history_webhooks',
  },

  /**
   * Feature to enable the graunular permission control
   */
  granular_permissions: {
    FEATURE_KEY: 'granular_permissions',
  },
};

/**
 * Query string parameter prefix that setForcedVariationsFromUrlParamsIfAuthorized looks for
 * @type {string}
 */
export const FORCE_VARIATION_PARAM_PREFIX = 'optimizely_x_force_';

export const NavProjectSwitcher = {
  Events: {
    SWITCH_PROJECT_CLICK: 'switch_project_click',
    CREATE_PROJECT_CLICK: 'create_project_click',
  },
};

export const Recommendations = {
  FEATURE_IDS: {
    RECS_HELP_COPY: 'recs_help_copy',
  },
};

export default {
  ATTRIBUTES,

  EVENTS,

  FEATURES,

  JS_SDK_BUCKETING_ID_ATTRIBUTES_KEY: '$opt_bucketing_id', // https://developers.optimizely.com/x/solutions/sdks/reference/index.html?language=javascript#bucketing-id

  /**
   * TODO - Migrate these to the appropriate top level entities above: ATTRIBUTES, EVENTS, FEATURES, etc.
   */

  TestUrlTargetingSetup: {
    EXPERIMENT_KEY: 'test_url_targeting_setup',
    Variations: {
      ORIGINAL: 'original',
      SIMPLIFIED_CREATION_FLOW: 'simplified_creation_flow',
    },
    Events: {
      EXPERIMENT_CREATED: 'experiment_created',
      EXPERIMENT_STARTED: 'experiment_started',
      TIME_TO_PUBLISH: 'time_to_publish',
      CREATE_EXPERIMENT_DIALOG_BOUNCE: 'create_experiment_dialog_bounce',
      COUNT_METRICS_ON_PUBLISH: 'count_metrics_on_publish',
    },
  },

  NavProjectSwitcher,

  FORCE_VARIATION_PARAM_PREFIX,

  Recommendations,
};

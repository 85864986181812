const cloneDeep = require('optly/clone_deep').default;
const flux = require('core/flux');
const tr = require('optly/translate');
const PluginModuleGetters = require('optly/modules/entity/plugin/getters')
  .default;
const PluginModuleEnums = require('optly/modules/entity/plugin/enums').default;
const PluginTypes = require('optly/modules/entity/plugin/types');

const fns = require('./fns');
const actionTypes = require('./action_types');

exports.loadPlugin = function(plugin) {
  if (__INVARIANT__) {
    assertType(PluginTypes.Plugin, plugin);
  }

  if (plugin.options.on_layer_decision) {
    plugin.options.track_layer_decision = plugin.options.on_layer_decision;
  }

  flux.dispatch(actionTypes.LOAD_CURRENTLY_EDITING_PLUGIN, {
    pluginConfig: plugin,
  });
};

/**
 * Sets a top level config value such as schema or html
 * @param {Object} config object to be merged with the currently editing plugin
 */
exports.updatePluginConfig = function(config) {
  flux.dispatch(actionTypes.UPDATE_CURRENTLY_EDITING_PLUGIN_CONFIG, config);
};

/**
 * Sets the formValues for the currently editing plugin
 * Used to track the values for a plugin within the variation editor
 * @param {Object} formValues
 */
exports.setFormValues = function(formValues) {
  flux.dispatch(actionTypes.SET_FORM_VALUES_CURRENTLY_EDITING_PLUGIN, {
    formValues,
  });
};

/**
 * Sets a form value for the currently editing live form
 * Used to track the values for a plugin within the variation editor
 */
exports.setFormValue = function(name, value) {
  flux.dispatch(actionTypes.SET_VALUE_CURRENTLY_EDITING_PLUGIN, {
    name,
    value,
  });
};

/**
 * Unsets the currently editing plugin
 */
exports.reset = function() {
  flux.dispatch(actionTypes.UNSET_CURRENTLY_EDITING_PLUGIN);
};

exports.revertChanges = function(pluginId) {
  const originalPlugin = flux.evaluateToJS(PluginModuleGetters.byId(pluginId));
  exports.updatePluginConfig(originalPlugin);
};

/**
 * @typedef {Object} EditResult
 * @property {Boolean} didEdit
 * @property {String?} errorMessage If an edit didn't happen, this message
 * explains why
 */

/**
 * Given a string of user input from the 'Edit as JSON' dialog, try to parse it
 * and update the currently editing plugin. Returns an EditResult object
 * indicating whether the update happened
 * @param {String} newConfigStr
 * @returns {EditResult}
 */
exports.updateFromJSON = function(newConfigStr) {
  let configObj;
  try {
    configObj = JSON.parse(newConfigStr);
  } catch (e) {
    return {
      didEdit: false,
      errorMessage: tr("Plugin configuration isn't valid JSON"),
    };
  }
  // default plugin_type for backwards compatibility
  if (!configObj.plugin_type) {
    configObj.plugin_type = PluginModuleEnums.plugin_type.WIDGET;
  }
  const validation = fns.validateEditedPlugin(configObj);
  if (!validation.isValid) {
    return {
      didEdit: false,
      errorMessage: validation.message,
    };
  }
  exports.updatePluginConfig(configObj);
  return {
    didEdit: true,
    updatedPlugin: cloneDeep(configObj),
  };
};

/**
 * Updates the default value for a field in the form schema
 * @param {String} fieldName
 * @param {*} newDefault
 */
exports.updateDefaultFieldValue = function(fieldName, newDefault) {
  flux.dispatch(actionTypes.UPDATE_PLUGIN_DEFAULT_FIELD_VALUE, {
    fieldName,
    newDefault,
  });
};

/**
 * Updates the default value for a field in the form schema
 * @param {String} selectorFieldName
 */
exports.startEditingSelector = function(selectorFieldName) {
  flux.dispatch(actionTypes.CURRENTLY_EDITING_PLUGIN_SET_EDITING_SELECTOR, {
    selectorFieldName,
  });
};

/**
 * Sets the value of the currently editing selector to null
 * to signify that a selector is no longer being edited
 */
exports.stopEditingSelector = function() {
  flux.dispatch(actionTypes.CURRENTLY_EDITING_PLUGIN_SET_EDITING_SELECTOR, {
    selectorFieldName: null,
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import { Attention, Button, Link, Sheet } from 'optimizely-oui';

import { OptimizelyFeature } from '@optimizely/react-sdk';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import flux from 'core/flux';
import { connect } from 'core/ui/decorators';
import FeatureActions from 'optly/modules/entity/feature_flag/actions';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import EnvironmentGetters from 'optly/modules/entity/environment/getters';
import FeatureConstants from 'optly/modules/entity/feature_flag/constants';
import PermissionsGetters from 'optly/modules/permissions/getters';
import { FEATURE_DETAILS } from 'optly/utils/constants';
import { Form, formPropType } from 'react_components/form';
import Immutable, { toImmutable } from 'optly/immutable';

import VariablesEditTable from 'bundles/p13n/sections/manager_feature/components/variables_tables/variables_edit_table';
import VariablesEditTableLegacy from 'bundles/p13n/sections/manager_feature/components/variables_tables/variables_edit_table_legacy';
import FeatureDetailsFields from 'bundles/p13n/sections/manager_feature/components/feature_details_fields';

@Form({
  mapPropsToFormData: () => {
    const isFeatureVariableJSONEnabled = isFeatureEnabled(
      'feature_variable_json',
    );

    return toImmutable({
      // The VariablesEditTable component has logic to always add an empty field.
      // When the field is added is tells the form that it's "dirty". We don't want
      // the form to thinks it's dirty when a user first opens the page, so adding
      // an empty variables row tricks the form into thinking it's clean when a user
      // first opens the modal
      variables: [
        isFeatureVariableJSONEnabled
          ? FeatureConstants.DEFAULT_VARIABLE
          : FeatureConstants.BASE_VARIABLE,
      ],
    });
  },
  debounceTimeout: __TEST__ ? 0 : 300,
  validateOnChange: false,
})
@connect(() => ({
  canUseFeatureVariables: PermissionsGetters.canAccountUseFeatureVariables,
}))
class CreateNewFeatureDialog extends React.Component {
  static propTypes = {
    canUseFeatureVariables: PropTypes.bool.isRequired,
    form: formPropType.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
  };

  createNewFeature = () => {
    const { form, onSave } = this.props;
    this.setState({ isLoading: true });
    return form
      .validate()
      .then(() => {
        const environments = flux.evaluate(
          EnvironmentGetters.unarchivedEnvironmentsSortedByPriority,
        );

        // Hackiness to get the current features API to accept the new feature. It requires the environments
        // to be a Map (not a List). This code converts each enviroment to a Map where the key is
        // the environment key and the value is the enviroment data.
        // TODO: We plan on letting the backend handle this transoform in the future
        // https://optimizely.atlassian.net/browse/MGMT-2882
        const formattedEnvironments = environments.reduce(
          (environmentMap, env) => {
            return environmentMap.set(
              env.get('key'),
              toImmutable({
                id: env.get('id'),
                is_primary: env.get('is_primary'),
                rollout_rules: [
                  {
                    audience_conditions: null,
                    audience_ids: [],
                    percentage_included: 0,
                    status: 'running',
                  },
                ],
              }),
            );
          },
          Immutable.Map({}),
        );

        const validVariables = form
          .field('variables')
          .getValue()
          .filter(variable => variable.get('api_name') !== '')
          .map(variable => {
            if (
              variable.get('type') ===
              FeatureConstants.FEATURE_VARIABLE_TYPES.json
            ) {
              return variable.set(
                'default_value',
                JSON.stringify(JSON.parse(variable.get('default_value'))),
              );
            }
            return variable;
          });

        const featureToSave = form
          .getValue()
          .set('project_id', flux.evaluate(CurrentProjectGetters.id))
          .set('environments', formattedEnvironments)
          .set('variables', validVariables);
        return new Promise((resolve, reject) => {
          FeatureActions.save(featureToSave).then(
            savedFeature =>
              resolve(featureToSave.mergeDeep(toImmutable(savedFeature))),
            reject,
          );
        });
      })
      .then(onSave)
      .catch(() => form.setOptions({ validateOnChange: true }))
      .finally(() => this.setState({ isLoading: false }));
  };

  render() {
    const { canUseFeatureVariables, form, onCancel } = this.props;
    const { isLoading } = this.state;
    return (
      <Sheet
        footerButtonList={[
          <Button
            key="cancel"
            style="plain"
            onClick={onCancel}
            testSection="create-new-feature-modal-cancel-button">
            Cancel
          </Button>,
          <Button
            key="submit"
            style="highlight"
            onClick={this.createNewFeature}
            isDisabled={!form.isFormDirty()}
            isLoading={isLoading}
            testSection="create-new-feature-modal-create-button">
            Create Feature
          </Button>,
        ]}
        onClose={onCancel}
        title="New Feature"
        subtitle={
          <span>
            Create a feature and customize its settings.{' '}
            <Link
              href="https://docs.developers.optimizely.com/full-stack/docs/use-feature-flags"
              newWindow={true}>
              Learn more
            </Link>
          </span>
        }>
        <>
          <div className="push-quad--bottom">
            <Attention
              alignment="center"
              type="brand"
              testSection="sdk-compatibility-warning">
              {`${FEATURE_DETAILS.sdkWarningText} `}
              <Link newWindow={true} href={FEATURE_DETAILS.sdkWarningKbLink}>
                Learn more
              </Link>
            </Attention>
          </div>
          <FeatureDetailsFields form={form} />
          {canUseFeatureVariables && (
            <>
              <h3>Variable Keys & Types</h3>
              <p>
                Define your variable keys, types, and default values to use with
                your environment and audience rules.
              </p>
              <OptimizelyFeature feature="feature_variable_json">
                {isEnabled =>
                  isEnabled ? (
                    <VariablesEditTable
                      variablesField={form.repeatedField('variables')}
                    />
                  ) : (
                    <VariablesEditTableLegacy
                      variablesField={form.repeatedField('variables')}
                    />
                  )
                }
              </OptimizelyFeature>
            </>
          )}
        </>
      </Sheet>
    );
  }
}

export default CreateNewFeatureDialog;

import keyMirror from 'optly/utils/key_mirror';

import ViewEnums from 'optly/modules/entity/view/enums';
import { toImmutable } from 'optly/immutable';

export const DefaultHoldbackValues = {
  P13N_CAMPAIGN: 5,
  SINGLE_EXPERIMENT: 0,
};

export const TargetingTypes = keyMirror({
  URL: null,
  SAVED_PAGES: null,
});

export const DEFAULT_URL_TARGETING_CONFIG = toImmutable({
  activation_type: ViewEnums.activationModes.IMMEDIATE,
  edit_url: '',
  conditions: '["and",["or",{"value":"","match_type":"simple","type":"url"}]]',
  activation_code: null,
});

export default {
  DEFAULT_URL_TARGETING_CONFIG,
  DefaultHoldbackValues,
  TargetingTypes,
};

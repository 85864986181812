import { AppEnvironment as APP_ENVIRONMENT } from 'optly/utils/enums';

/**
 * Module specific pure functions
 */
export function getEnvironment(env = {}) {
  // try to get the environment, but default to production
  return env.ENVIRONMENT || APP_ENVIRONMENT.PRODUCTION;
}

export function getEnvS3Bucket(env = {}) {
  return env.S3_BUCKET || 'optimizely';
}

export default {
  getEnvironment,
  getEnvS3Bucket,
};

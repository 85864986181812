import flux from 'core/flux';

import { constants as LayerConstants } from 'optly/modules/entity/layer';
import { enums as ViewEnums } from 'optly/modules/entity/view';

import { getters as HoldbackSelectorGetters } from 'bundles/p13n/modules/holdback_selector';
import { getters as LayerSettingsGetters } from 'bundles/p13n/modules/layer_settings';

import getters from './getters';
import actionTypes from './action_types';

/**
 * @param {Object} errors
 */
export const setErrors = function(errors) {
  flux.dispatch(actionTypes.LAYER_DIALOG_SET_ERRORS, errors);
};

/**
 * @param {Immutable.Set} viewIds
 */
export const setSelectedViewIds = function(viewIds) {
  setErrors({
    views: null,
  });
  flux.dispatch(actionTypes.LAYER_DIALOG_SET_VIEW_IDS, {
    viewIds,
  });
};

/**
 * @param {Immutable.Map} urlTargetingConfig
 */
export const setUrlTargetingConfig = function(urlTargetingConfig) {
  setErrors({
    urlTargetingConditions: null,
    urlTargetingApiName: null,
    urlTargetingUrl: null,
  });
  flux.dispatch(actionTypes.LAYER_DIALOG_SET_URL_TARGETING_CONFIG, {
    urlTargetingConfig,
  });
};

/**
 * @param {String} targetingType
 */
export const setTargetingType = function(targetingType) {
  setErrors({
    urlTargetingConditions: null,
    urlTargetingApiName: null,
    urlTargetingUrl: null,
    views: null,
  });
  flux.dispatch(actionTypes.LAYER_DIALOG_SET_TARGETING_TYPE, {
    targetingType,
  });
};

/**
 * Validate the layer data before creation
 * @param {String} name
 */
export const validate = function(name) {
  const errors = {
    name: '',
    views: '',
    urlTargetingConditions: '',
    urlTargetingApiName: '',
    urlTargetingUrl: '',
    metrics: '',
    holdback: '',
  };

  const _name = name || flux.evaluate(LayerSettingsGetters.name) || '';

  if (_name.trim().length === 0) {
    errors.name = tr('Please name your campaign.');
  }

  const holdbackInvalid = flux.evaluate(
    HoldbackSelectorGetters.isLayerHoldbackInvalid,
  );

  if (holdbackInvalid) {
    errors.holdback = tr('Please provide a valid holdback');
  }

  const targetingType = flux.evaluate(getters.targetingType);
  const selectedViewIds = flux.evaluate(getters.selectedViewIds);
  const urlTargetingConfig = flux.evaluate(getters.urlTargetingConfig);

  if (
    targetingType === LayerConstants.TargetingTypes.SAVED_PAGES &&
    selectedViewIds.size === 0
  ) {
    errors.views = tr('Please select at least one page.');
  }

  if (targetingType && targetingType === LayerConstants.TargetingTypes.URL) {
    const editUrl = urlTargetingConfig.get('edit_url');
    if (!editUrl) {
      errors.urlTargetingConditions = tr('Please provide a URL for targeting.');
    }

    if (
      urlTargetingConfig.get('activation_type') ===
        ViewEnums.activationModes.MANUAL &&
      !urlTargetingConfig.get('api_name')
    ) {
      errors.urlTargetingApiName = tr(
        'Please provide an api name for manual targeting.',
      );
    }
  }

  errors.metrics = null;

  setErrors(errors);
  return errors;
};

export const reset = function() {
  flux.dispatch(actionTypes.LAYER_DIALOG_RESET);
};

export default {
  setSelectedViewIds,
  setUrlTargetingConfig,
  setTargetingType,
  setErrors,
  validate,
  reset,
};

import flux from 'core/flux';

import AjaxUtil from 'optly/utils/ajax';
import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

export const {
  save,
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deleteWebhook = baseEntityActions.delete;

export { deleteWebhook as delete };

export function regenerateWebhook(webhook) {
  const options = {
    url: `/api/v1/webhooks/${webhook.id}/regenerate`,
    type: 'POST',
  };

  flux.dispatch(RestApi.actionTypes.API_ENTITY_PERSIST_START, {
    entity: definition.entity,
    data: webhook,
  });

  return AjaxUtil.makeV1AjaxRequest(options)
    .then(updatedWebhook => {
      flux.dispatch(RestApi.actionTypes.API_ENTITY_PERSIST_SUCCESS, {
        entity: definition.entity,
        data: updatedWebhook,
      });
    })
    .fail(reason => {
      flux.dispatch(RestApi.actionTypes.API_ENTITY_PERSIST_FAIL, {
        entity: definition.entity,
        data: webhook,
        reason,
      });
    });
}

export default {
  ...baseEntityActions,
  regenerateWebhook,
};

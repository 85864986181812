import React from 'react';
import PropTypes from 'prop-types';
import htmlSanitizer from 'sanitizer';

import Immutable, { toImmutable, toJS } from 'optly/immutable';
import { Button, Dialog, Sheet, Input, Textarea } from 'optimizely-oui';

import ui from 'core/ui';
import flux from 'core/flux';
import { connect } from 'core/ui/decorators';

import {
  actions as CurrentProjectActions,
  getters as CurrentProjectGetters,
} from 'optly/modules/current_project';
import {
  fns as PermissionsModuleFns,
  getters as PermissionsModuleGetters,
} from 'optly/modules/permissions';
import UrlHelperV2 from 'optly/services/url_helper';
import pushStateHandler from 'optly/utils/push_state';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { actions as LayerExperimentActions } from 'optly/modules/entity/layer_experiment';

import LoadingOverlay from 'react_components/loading_overlay';

import ConfigureGroupComponentModule from './component_module';
// eslint-disable-next-line import/no-cycle
import ExclusionGroupSettings from './subcomponents/exclusion_group_settings';

@connect({
  currentProjectId: CurrentProjectGetters.id,
})
class ConfigureGroup extends React.Component {
  static componentId = 'mutex-experiment-group-dialog';

  componentDidMount() {
    // Fetch new layers or layer experiments and hide the loading spinner on success or fail
    this.fetchCurrentProjectEntities().done(() => {
      this.setIsLoadingToFalse();
    });
  }

  static propTypes = {
    canEditGroup: PropTypes.bool.isRequired,
    canUseABTestingV2: PropTypes.bool.isRequired,
    canUsePersonalization: PropTypes.bool.isRequired,
    group: PropTypes.instanceOf(Immutable.Map).isRequired,
    isCustomProject: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  state = {
    removedExclusionExperiment: Immutable.Map({}),
    isLoadingOrSaving: true,
  };

  setIsLoadingToFalse = () => {
    this.setState({
      isLoadingOrSaving: false,
    });
  };

  handleRemovedExperiment = experiment => {
    this.setState({ removedExclusionExperiment: experiment });
  };

  fetchCurrentProjectEntities = (filters = {}, options = {}) => {
    const { isCustomProject } = this.props;
    if (isCustomProject) {
      return CurrentProjectActions.fetchLayerExperimentsWithSingleAndMvtPolicies(
        filters,
        options,
      );
    }
    return CurrentProjectActions.fetchCurrentProjectLayers(filters, options);
  };

  handleGroupNameChange = event => {
    const { group } = this.props;
    ConfigureGroupComponentModule.actions.setGroup(
      group.set('name', event.target.value),
    );
  };

  handleGroupDescriptionChange = event => {
    const { group } = this.props;
    ConfigureGroupComponentModule.actions.setGroup(
      group.set('description', event.target.value),
    );
  };

  validate = () => {
    const { group } = this.props;
    let hasErrors = false;
    const name = group.get('name').trim();
    const availableTraffic = group.get('available_traffic');

    if (!name) {
      hasErrors = true;
      ui.showNotification({
        message: 'Please provide a name.',
        type: 'error',
      });
    }
    if (availableTraffic < 0) {
      hasErrors = true;
      ui.showNotification({
        message: 'Group Traffic Allocation cannot exceed 100%.',
        type: 'error',
      });
    }
    return !hasErrors;
  };

  onSave = () => {
    if (this.validate()) {
      this.setState({
        isLoadingOrSaving: true,
      });

      const { currentProjectId, group, isCustomProject, onSave } = this.props;

      const saveGroup = () => {
        onSave(group.toJS()).then(() => {
          this.setIsLoadingToFalse();
          const groupEntities = group.get('entities');

          // For Full Stack Layer Experiments, the percentage included should match the
          // Entity's weight in the group. This will also update the entityCache.
          if (isCustomProject) {
            groupEntities.forEach(entity => {
              LayerExperimentActions.save({
                id: entity.get('id'),
                percentage_included: entity.get('weight'),
              });
            });
          }

          // For groups with at least 1 entity in Web, force fetch all current project
          // Layers and update the entityCache since the holdback is set to 0 on the backend.
          if (!isCustomProject && groupEntities.size) {
            const groupEntityIds = toJS(
              groupEntities.map(entity => entity.get('id')),
            );
            this.fetchCurrentProjectEntities(
              { id: groupEntityIds },
              { force: true },
            );
          }

          const { removedExclusionExperiment } = this.state;

          ui.hideDialog();

          if (removedExclusionExperiment.size > 0) {
            const experimentUrl = UrlHelperV2.campaignHome(
              currentProjectId,
              removedExclusionExperiment.get('id'),
            );

            ui.showPersistentNotification({
              message: `Traffic allocation for the experiment <b>${removedExclusionExperiment.get(
                'name',
              )}</b> has been reset to 100%. Go to <a href="${experimentUrl}" v-push-state> experiment details.</a>`,
              type: 'warning',
              id: 'removed-experiment-notification',
              safeHTML: true,
            });
          }

          ui.showNotification({
            message: `The group <b>${htmlSanitizer.escape(group.get('name'))}</b> has been saved.`,
          });
        });
      };

      const fullStackExperimentUpdateErrorFlag = flux
        .evaluate(ConfigureGroupComponentModule.getters.errorFlags)
        .get(
          ConfigureGroupComponentModule.constants.ErrorFlag
            .FULL_STACK_RUNNING_EXPERIMENT_UPDATE,
        );

      if (isCustomProject && fullStackExperimentUpdateErrorFlag) {
        ui.confirm({
          isWarning: true,
          title: 'Are you sure you want to save this exclusion group?',
          message:
            'Changing traffic allocation or group assignment for a running experiment will impact user bucketing, causing users to be bucketed in or unbucketed from this experiment. Are you sure you want to proceed with this change?',
          confirmText: 'Save Group',
          cancelText: 'Cancel',
        })
          .then(() => {
            // Validate again to be safe
            if (this.validate()) {
              saveGroup();
            }
          })
          .fail(this.setIsLoadingToFalse);
      } else {
        saveGroup();
      }
    }
  };

  render() {
    const {
      canEditGroup,
      canUseABTestingV2,
      canUsePersonalization,
      isCustomProject,
      group,
    } = this.props;
    const { isLoadingOrSaving, removedExclusionExperiment } = this.state;

    const isNewGroup = !group.get('id');
    const updateGroupText = ConfigureGroupComponentModule.fns.getGroupsExplanationText(
      canUseABTestingV2,
      canUsePersonalization,
      isCustomProject,
    );

    return (
      <Sheet
        testSection="mutex-group-dialog"
        title={isNewGroup ? 'Create Exclusion Group' : 'Edit Exclusion Group'}
        hasRequiredFieldsIndicator={true}
        onClose={ui.hideDialog}
        footerButtonList={[
          <Button
            key="btn-cancel"
            style="plain"
            testSection="mutex-group-dialog-cancel"
            onClick={ui.hideDialog}>
            Cancel
          </Button>,
          <Button
            key="btn-save"
            style="highlight"
            isDisabled={isLoadingOrSaving || !canEditGroup}
            testSection="mutex-group-dialog-save"
            onClick={this.onSave}>
            {isNewGroup ? 'Create Exclusion Group' : 'Save'}
          </Button>,
        ]}>
        <LoadingOverlay isLoading={isLoadingOrSaving}>
          <Dialog.Fieldset>
            <ol className="oui-form-fields">
              <li className="oui-form-field__item">
                <p>
                  Experiments assigned to this exclusion group are mutually
                  exclusive, which means that visitors to your site will only
                  see one experiment from this exclusion group.
                </p>
              </li>
            </ol>
          </Dialog.Fieldset>
          <Dialog.Fieldset>
            <ol className="oui-form-fields">
              <li className="oui-form-field__item">
                <Input
                  isReadOnly={!canEditGroup}
                  label="Exclusion Group Name"
                  isRequired={true}
                  onChange={this.handleGroupNameChange}
                  testSection="mutex-group-dialog-name"
                  type="text"
                  value={group.get('name')}
                />
              </li>
              <li className="oui-form-field__item">
                <Textarea
                  label="Description"
                  isReadOnly={!canEditGroup}
                  onChange={this.handleGroupDescriptionChange}
                  testSection="mutex-group-dialog-description"
                  value={group.get('description')}
                />
              </li>
            </ol>
          </Dialog.Fieldset>
          <Dialog.Fieldset>
            <ol className="oui-form-fields">
              <li className="oui-form-field__item">
                <h3>Experiments</h3>
                <p data-test-section="group-explanation">{updateGroupText}</p>
              </li>
            </ol>
          </Dialog.Fieldset>
          <ExclusionGroupSettings
            canEditGroup={canEditGroup}
            group={group}
            handleRemovedExperiment={this.handleRemovedExperiment}
            isCustomProject={isCustomProject}
          />
        </LoadingOverlay>
      </Sheet>
    );
  }
}

export const ConfigureGroupDialog = ui.connectGetters(ConfigureGroup, {
  group: ConfigureGroupComponentModule.getters.currentlyEditingGroup,
});

/*
 * Convenience static function to trigger display of this dialog
 */
export function show(onDone, data) {
  // Store selected group in component module store and reset error flags
  ConfigureGroupComponentModule.actions.setGroup(toImmutable(data.group));
  ConfigureGroupComponentModule.actions.resetErrorFlags();

  ui.showReactDialog(
    ConfigureGroupDialog,
    {
      props: {
        onSave: onDone,
        isCustomProject: flux.evaluate(CurrentProjectGetters.isCustomProject),
        canEditGroup: PermissionsModuleFns.canEditGroup(
          flux.evaluate(CurrentProjectGetters.project),
        ),
        canUseABTestingV2: PermissionsModuleFns.canUseABTestingV2(
          flux.evaluate(AdminAccountGetters.accountPermissions),
        ),
        canUsePersonalization: flux.evaluate(
          PermissionsModuleGetters.canUsePersonalization,
        ),
      },
    },
    {
      fullScreen: true,
      dismissOnBack: true,
      isOuiDialog: true,
    },
  );
}

export default {
  show,
  ConfigureGroupDialog,
};

const keyMirror = require('optly/utils/key_mirror');

module.exports = keyMirror({
  LOAD_CURRENTLY_EDITING_PLUGIN: null,
  UPDATE_CURRENTLY_EDITING_PLUGIN_CONFIG: null,
  UNSET_CURRENTLY_EDITING_PLUGIN: null,
  SET_VALUE_CURRENTLY_EDITING_PLUGIN: null,
  SET_FORM_VALUES_CURRENTLY_EDITING_PLUGIN: null,
  UPDATE_PLUGIN_DEFAULT_FIELD_VALUE: null,
  CURRENTLY_EDITING_PLUGIN_SET_EDITING_SELECTOR: null,
});

import RestApi, { requester } from 'optly/modules/rest_api';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

export const { save, fetch, fetchPage, fetchAll, flush } = baseEntityActions;

export const deleteEnvironment = baseEntityActions.delete;

let defaultExportObj;

/**
 * Toggle archived status on the argument environment
 * @param {Immutable.Map} environment
 * @return {$.Deferred}
 */
export function toggleArchived(environment) {
  const id = environment.get('id');
  if (environment.get('archived')) {
    return defaultExportObj.save({
      id,
      archived: false,
    });
  }
  return defaultExportObj.delete({
    id,
  });
}

export function removePublicDatafile(EnvironmentId) {
  return requester()
    .one('environments', EnvironmentId)
    .all('datafile')
    .all('remove_from_cdn')
    .post();
}
export function toggleSecure(environmentId) {
  return requester()
    .one('environments', environmentId)
    .all('secure')
    .post();
}

export default defaultExportObj = Object.assign({}, baseEntityActions, {
  toggleArchived,
  removePublicDatafile,
  toggleSecure,
});

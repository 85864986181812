const { toImmutable } = require('optly/immutable');

const enums = require('./enums');

exports.iframes = ['p13n/editorIframe'];

/**
 * @param {number} id
 * @return {Getter}
 */
exports.iframe = function(id) {
  return ['p13n/editorIframe', id];
};

/**
 * @param {number} id
 * @return {Getter}
 */
exports.iframeComponent = function(id) {
  return ['p13n/editorIframe', id, 'component'];
};

/**
 * @param {number} id
 * @return {Getter}
 */
exports.iframeLoadingStatus = function(id) {
  return ['p13n/editorIframe', id, 'loadStatus'];
};

/**
 * @param {String} id
 * @return {Getter}
 */
exports.width = function(id) {
  return ['p13n/editorIframe', id, 'width'];
};

/**
 * @param {String} id
 * @return {Getter}
 */
exports.selectedDevice = function(id) {
  return ['p13n/editorIframe', id, 'selectedDevice'];
};

/**
 * @param {String} id
 * @return {Getter}
 */
exports.isLandscapeMode = function(id) {
  return ['p13n/editorIframe', id, 'isLandscapeMode'];
};

/**
 * @param {number} id
 * @return {Getter}
 */
exports.editorType = function(id) {
  return ['p13n/editorIframe', id, 'editorType'];
};

/**
 * @param {number} id
 * @return {Getter}
 */
exports.frameMode = function(id) {
  return ['p13n/editorIframe', id, 'mode'];
};

/**
 * @param {number} id
 * @return {Getter}
 */
exports.frameType = function(id) {
  return ['p13n/editorIframe', id, 'frameType'];
};

exports.urlProtocolMap = ['p13n/urlProtocolMap'];

/**
 * @return {Getter}
 */
exports.iframeLoggingEnabled = ['p13n/editorIframeLogging', 'loggingEnabled'];

/**
 * @param {String} id iframe id
 * @return {Getter}
 */
exports.iframeLog = function(id) {
  return [
    ['p13n/editorIframe', id, 'log'],
    /**
     * @param {Immutable.List|undefined} log
     * @return {Immutable.List}
     */
    function(log) {
      return log || toImmutable([]);
    },
  ];
};

exports.urlProtocolMapping = function(url) {
  return ['p13n/urlProtocolMap', url];
};

exports.shouldShortcutProxyTimeoutForUrl = function(url) {
  return [
    exports.urlProtocolMap,
    function(urlProtocolMap) {
      return (
        urlProtocolMap.has(url) &&
        urlProtocolMap.get(url) === enums.ProtocolTypes.PROXY
      );
    },
  ];
};

exports.iframeCustomCodeAlertsShown = function(id) {
  return ['p13n/editorIframe', id, 'customCodeAlertsShown'];
};

exports.iframeEvalError = function(id) {
  return ['p13n/editorIframe', id, 'evalError'];
};

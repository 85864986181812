/**
 * Abstracts the DOM behavior for showing/hiding a dropdown
 * so it can be used in the dropdown directive or directly
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const $ = require('jquery');

const SHOWN_CLASS = 'is-active';
const ESCAPE_KEY = 27;
const MENU_OVERLAY_CLASS = 'menu-overlay';

const service = {};

/**
 * Shows a dropdown
 * @param {HTMLElement} el the dropdown container
 */
service.show = function(el) {
  // If the element is disabled, then the dropdown won't show
  if ($(el).prop('disabled')) {
    return;
  }
  const eventNS = `.dropdown-${new Date().valueOf()}`;
  const useOverlay = el.hasAttribute('data-use-overlay');

  $(el)
    .addClass(SHOWN_CLASS)
    .data('eventNS', eventNS);

  if (useOverlay) {
    this._overlay = $('<div/>')
      .prependTo(document.body)
      .addClass(MENU_OVERLAY_CLASS);
  }

  $(document).on(`click${eventNS}`, event => {
    if ($(el).has(event.target).length === 0) {
      this.hide(el);
    }
  });

  $(document).on(`keyup${eventNS}`, event => {
    if (event.keyCode === ESCAPE_KEY) {
      this.hide(el);
    }
  });
};

/**
 * Hides a dropdown
 * @param {HTMLElement} el the dropdown container
 */
service.hide = function(el) {
  const eventNS = $(el).data('eventNS');

  if (eventNS) {
    $(document).off(eventNS);
    $(el).removeData('eventNS');
  }
  $(el).removeClass(SHOWN_CLASS);

  if (this._overlay) {
    this._overlay.remove();
    this._overlay = null;
  }
};

/**
 * Toggles the dropdown
 * @param {HTMLElement} el the dropdown container
 */
service.toggle = function(el) {
  if ($(el).hasClass(SHOWN_CLASS)) {
    this.hide(el);
  } else {
    this.show(el);
  }
};

module.exports = service;

import _ from 'lodash';
import $ from 'jquery';

import { getFeatureVariableString } from '@optimizely/js-sdk-lab/src/actions';
import { api as SegmentJSAPI } from '@optimizely/segment-js';

import flux from 'core/flux';
import ui from 'core/ui';

import { canUseAIWebCopyVariations } from 'optly/modules/permissions/getters';

import Editor from 'bundles/p13n/modules/editor';
import {
  actions as EditorIframeActions,
  getters as EditorIframeGetters,
} from 'bundles/p13n/modules/editor_iframe';
import ImageManagerConstants from 'bundles/p13n/components/image_input_manager/constants';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import LayerExperimentHumanReadable from 'optly/modules/entity/layer_experiment/human_readable';
import { actions as P13NUIActions } from 'bundles/p13n/modules/ui';

import ImageInputManager from 'bundles/p13n/components/image_input_manager';
import SelectorInput from 'bundles/p13n/components/selector_input';
import {
  CodeMirror as CodeMirrorMixin,
  ChangeEditorSidebar as ChangeEditorSidebarMixin,
} from 'bundles/p13n/modules/ui/mixins';

import ResizableDirective from 'optly/directives/resizable';

import ComponentHtmlTemplate from './template.html';

// Disabling the following eslint rule which is firing a false positive error in this file
/* eslint-disable react/no-this-in-sfc */

const changeEditorSidebarBaseComponent = {
  replace: true,

  template: ComponentHtmlTemplate,

  data: {
    changeStatuses: LayerExperimentEnums.ChangeStatuses,
    codeMirrorInitialized: false,
    _codeMirrors: {
      html: null,
    },
    imageEditingEnabled: false,
    shouldObserveCodeMirror: true,
    isDisableCopyVariationsButton: true,
    isAiWebCopyVariationsFeatureEnabled: flux.evaluate(
      canUseAIWebCopyVariations,
    ),
    generateSuggestionsButtonText: getFeatureVariableString(
      'ai_web_copy_variations',
      'button_label',
    ),
    shouldShowHtmlExpanded: true,
  },

  directives: {
    resizable: ResizableDirective,
  },

  computed: {
    /**
     * Should we show href editing
     * @returns {boolean}
     */
    hrefEditingEnabled() {
      this.currentlyEditingChange; // eslint-disable-line
      return this.selectedElementNodeNameMatches('A');
    },

    /**
     * Should we enable html editing
     * @returns {boolean}
     */
    htmlEditingEnabled() {
      this.isEditingSelector; // eslint-disable-line
      this.isEditorReadOnly; // eslint-disable-line
      this.childrenWithExistingChanges; // eslint-disable-line
      return (
        !this.isEditingSelector &&
        !this.isEditorReadOnly &&
        !this.childrenWithExistingChanges.length
      );
    },

    /**
     * Return the url that a parent container points to if the currently selected element
     * is nested inside of an anchor tag.
     *
     * @returns {string | null}
     */
    hrefForParentAnchor() {
      if (this.currentlyEditingChangeParentAnchor) {
        const { selector } = this.currentlyEditingChangeParentAnchor;
        const href = this.currentlyEditingChangeParentAnchor.href || null;
        const parentChange = Editor.actions.getChangeMatchingSelector(selector);
        return parentChange &&
          parentChange.attributes &&
          parentChange.attributes.href
          ? parentChange.attributes.href
          : href;
      }
      return null;
    },

    /**
     * Compute a map of all attribute changes and the appropriate classes to use
     * for their current status (dirty, draft, live).
     * @returns {{href: *, image: *, selector: *}}
     */
    statusClassForAttributes() {
      return {
        href: this.getStatusClassForAttribute([
          this.changeStatusMap.attributes.href,
        ]),
        html: this.getStatusClassForAttribute([
          this.changeStatusMap.attributes.html,
        ]),
        image: this.getStatusClassForAttribute([
          this.changeStatusMap.attributes.src,
        ]),
        selector: this.getStatusClassForAttribute([
          this.changeStatusMap.selector,
        ]),
      };
    },
  },

  methods: {
    /**
     * Manually blur all input fields. Clicking inside an iframe doesn't cause
     * a focused input box to blur, so use this method to do that manually.
     */
    blurInputFields() {
      $(this.$$.href).blur();
      $(this.$$.name).blur();

      if (this.codeMirrorInitialized) {
        this._codeMirrors.html.getInputField().blur();
      }
    },

    /**
     * When the currently edited change gets modified in some way, check to see if
     * the image(s) should change and if so recalculate all computed properties
     *
     */
    calculateImageProperties() {
      this.imageEditingEnabled = this.selectedElementNodeNameMatches('IMG');
      this.$emit(
        ImageManagerConstants.EventTypes.CALCULATE_IMAGE_PORPERTIES,
        this.getImageValue(),
      );
    },

    copyVariationsList() {
      SegmentJSAPI.track('Clicks on "Generate Copy" CTA');
      ui.renderRegion('p13n-editor-sidebar', {
        component: require('../../change_content_suggestions_sidebar').default, // eslint-disable-line
        data: {
          isNewChange: true,
          initialVariationValue: this.getAttribute('html'),
        },
      });
    },

    /**
     * Retrieve the image value (url) for display purposes
     * @returns {string|null}
     */
    getImageValue() {
      // Case that the selected element has an image tag
      if (this.selectedElementNodeNameMatches('IMG')) {
        // Return any changes that the user made rather than the original value
        if (this.currentlyEditingChange.attributes.src !== null) {
          return this.currentlyEditingChange.attributes.src;
        }

        // If there is only one element selected, return the src of that image
        if (this.currentlyEditingChange.elementCount === 1) {
          return (
            Editor.fns.convertProxyUrlToRelativeUrl(
              this.currentlyEditingChange.elementInfo[0].src,
            ) || null
          );
        }
      }
      return null;
    },

    /**
     * Create Element Change with provided selector and show Change Editor Sidebar
     */
    createEditorSidebarChange(sidebarChangeAction, selector = '') {
      P13NUIActions.confirmNavigation(
        this.currentlyEditingChangeIsDirty,
        LayerExperimentHumanReadable.CHANGE,
        () => {
          Editor.actions.setChangeBasedOnSelector(selector);
          const changes = flux.evaluateToJS(
            Editor.getters.currentlyEditingActionFormatted(true),
          );
          EditorIframeActions.applyChanges(this.activeFrameId, changes);
          sidebarChangeAction();
        },
      );
    },

    /**
     * Create Element Change with provided selector and show Change Editor Sidebar
     */
    handleShowChangeEditorSidebarForSelector(selector) {
      this.createEditorSidebarChange(
        P13NUIActions.showChangeEditorSidebar,
        selector,
      );
    },

    /**
     * Create default Element Change and show Change Editor Sidebar
     */
    handleCreateDefaultChange() {
      this.createEditorSidebarChange(P13NUIActions.showChangeEditorSidebar);
    },

    /**
     * Create default Insert HTML Change and show Insert HTML Sidebar
     */
    handleCreateInsertHTMLChange() {
      this.createEditorSidebarChange(P13NUIActions.showInsertHTMLSidebar);
    },

    /**
     * Create default Insert HTML Change and show Insert Image Sidebar
     */
    handleCreateInsertImageChange() {
      this.createEditorSidebarChange(P13NUIActions.showInsertImageSidebar);
    },

    /**
     * Handle image input changed
     */
    imageChanged(url) {
      // Todo(James): Rerender the highlighter in case the image size changed
      if (this.selectedElementNodeNameMatches('IMG')) {
        this.setAttribute('src', url);
      }
    },

    /**
     * Explicitly set the value of the change type input fields. Prevent change if the field is focused
     * as that usually indicates that a user is actively making changes to that field. If we were to
     * overwrite these on element change, we'd be constantly modifying the input as they type
     */
    initializeInputFields() {
      if (!$(this.$$.href).is(':focus')) {
        this.$$.href.value = this.getAttribute('href');
      }

      if (this._codeMirrors.html && !this._codeMirrors.html.hasFocus()) {
        this.shouldObserveCodeMirror = false;
        this._codeMirrors.html.setValue(this.getAttribute('html'));
        this.shouldObserveCodeMirror = true;
        this.isDisableCopyVariationsButton = !this.getAttribute('html').length;
      }
    },

    /**
     * Setup the change editor sidebar image settings and input fields.
     */
    setupChange() {
      if (this.currentlyEditingChange) {
        this.calculateImageProperties();
        this.initializeInputFields();
        this.safelyEnsureHTMLCodeMirrorDisableState();
        this.shouldShowLayoutSectionExpanded =
          !this.selectedElementNodeNameMatches('IMG') &&
          !this.selectedElementNodeNameMatches('A');
      }
    },

    /**
     * This safely wraps the mixin's toggleCodeMirrorComponentsDisabled function and disables or enables the HTML CodeMirror Component
     */
    safelyEnsureHTMLCodeMirrorDisableState() {
      if (!this.codeMirrorInitialized) {
        return;
      }
      const isDisabled = !this.htmlEditingEnabled;
      this.toggleCodeMirrorComponentsDisabled(isDisabled);
    },

    toggleHtmlExpandedSection() {
      if (!this.shouldShowHtmlExpanded) {
        setTimeout(() => {
          this.refreshCodeMirror(this._codeMirrors.html);
        }, 10);
      }
      this.shouldShowHtmlExpanded = !this.shouldShowHtmlExpanded;
    },
  },

  created() {
    flux.bindVueValues(this, {
      activeFrameId: Editor.getters.activeFrameId,
      changeStatusMap: Editor.getters.changeStatusMap(),
      childrenWithExistingChanges: Editor.getters.childrenWithExistingChanges,
      currentlyEditingChange: Editor.getters.currentlyEditingChange,
      currentlyEditingChangeIsDirty:
        Editor.getters.currentlyEditingChangeIsDirty,
      currentlyEditingChangeParentAnchor:
        Editor.getters.currentlyEditingChangeParentAnchor,
      isEditorReadOnly: Editor.getters.isEditorReadOnly,
      isEditingSelector: Editor.getters.changeEditorIsEditingSelector,
    });

    this._resizableOptions = {
      handles: 'se',
      minHeight: 200,
      reset: true,
      // jQuery UI Resizable insists on setting the width inline upon resize, so override that here
      resize: this.resizeCodeMirror.bind(this),
    };
  },

  ready() {
    ui.renderReactComponent(this, {
      component: ImageInputManager,
      el: this.$$.imageInputManager,
      dataBindings: {
        isEditorReadOnly: Editor.getters.isEditorReadOnly,
        isEditingSelector: Editor.getters.changeEditorIsEditingSelector,
      },
      props: {
        setupChange: this.setupChange.bind(this),
        onImageChange: this.imageChanged.bind(this),
        imageEditingEnabled: !this.isEditingSelector,
      },
    });

    if (!this.codeMirrorInitialized) {
      this._codeMirrors.html = this.initializeCodeMirrorField(
        this.$$.html,
        'html',
        'htmlmixed',
        this.setAttribute.bind(this),
      );
      this.codeMirrorInitialized = true;
      this.safelyEnsureHTMLCodeMirrorDisableState();
    }
    this.initializeInputFields();

    this.$on('blurInput', this.blurInputFields.bind(this));

    // Observe the currentlyEditingChange and update the change input fields when it changes. This is
    // preferred to using v-value because we can explicitly not update the field that is currently being typed in.
    this.__unwatchCurrentlyEditingChange = flux.observe(
      Editor.getters.currentlyEditingChange,
      this.setupChange.bind(this),
    );

    this.$on('sidebarResizeEvent', () => {
      this.refreshCodeMirror(this._codeMirrors.html);
    });

    // Mount the SelectorInput React component for the Change Element's SelectorInputTypes.ELEMENT_SELECTOR
    ui.renderReactComponent(this, {
      component: SelectorInput,
      el: this.$$.elementSelectorInput,
      dataBindings: {
        currentSelectorHighlightOptions:
          Editor.getters.currentlyEditingChangeHighlighterOptions,
        elementCount: Editor.getters.elementCountFromCurrentlyEditingChange,
        iframe: EditorIframeGetters.iframeComponent(this.activeFrameId),
        isDisabled: Editor.getters.selectorInputIsDisabled(
          Editor.constants.SelectorInputTypes.ELEMENT_SELECTOR,
        ),
        value: Editor.getters.currentlyEditingChangeSelector,
      },
      props: {
        onChange: Editor.actions.onChangeSelectorInput,
        onEditStart: _.partial(
          Editor.actions.toggleElementSelection,
          true,
          Editor.constants.SelectorInputTypes.ELEMENT_SELECTOR,
        ),
        onEditStop: _.partial(
          Editor.actions.toggleElementSelection,
          false,
          Editor.constants.SelectorInputTypes.ELEMENT_SELECTOR,
        ),
        selectorInputType: Editor.constants.SelectorInputTypes.ELEMENT_SELECTOR,
      },
    });

    // When a Selector Input is being used, we should update the HTML Code Mirror with the appropriate disable state
    flux.observe(
      Editor.getters.changeEditorIsEditingSelector,
      this.safelyEnsureHTMLCodeMirrorDisableState.bind(this),
    );
  },

  beforeDestroy() {
    if (this.__unwatchCurrentlyEditingChange) {
      this.__unwatchCurrentlyEditingChange();
    }
  },
};

export default _.merge(
  {},
  CodeMirrorMixin,
  ChangeEditorSidebarMixin,
  changeEditorSidebarBaseComponent,
);

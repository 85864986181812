import fieldTypes from 'optly/modules/rest_api/field_types';

export default {
  entity: 'groups',

  parent: {
    entity: 'projects',
    key: 'project_id',
  },

  fieldTypes: {
    id: fieldTypes.NUMBER,
    project_id: fieldTypes.NUMBER,
    entities: fieldTypes.ARRAY,
    name: fieldTypes.STRING,
  },
};

import PropTypes from 'prop-types';
import React from 'react';

import Immutable from 'optly/immutable';
import { connect } from 'core/ui/decorators';
import { feature } from '@optimizely/js-sdk-lab/src/decorators';

import DropdownEntityPicker from 'bundles/p13n/components/dropdown_entity_picker';
import { actions as FeatureManagerActions } from 'bundles/p13n/modules/feature_manager';
import tr from 'optly/translate';
import { getters as LoadingGetters } from 'core/modules/loading';
import { LOADING_FEATURE_FLAGS } from 'optly/modules/entity/feature_flag/constants';

function onCreateFeature(onCreated) {
  FeatureManagerActions.showFeatureDialog().then(savedFeature => {
    onCreated(savedFeature);
  });
}

@connect({
  isLoadingFeatures: LoadingGetters.isLoading(LOADING_FEATURE_FLAGS),
})
@feature('user_friendly_names')
class FeaturePicker extends React.Component {
  static propTypes = {
    features: PropTypes.instanceOf(Immutable.List).isRequired,
    isLoadingFeatures: PropTypes.bool.isRequired,
    onSelectFeature: PropTypes.func.isRequired,
    onUnselectFeature: PropTypes.func,
    selectedFeatureId: PropTypes.number,
  };

  render() {
    const {
      features,
      selectedFeatureId,
      onSelectFeature,
      onUnselectFeature,
      isLoadingFeatures,
    } = this.props;

    const getSelectedFeatureDisplayConfig = feature => {
      const apiName = feature.get('api_name');
      const description = feature.get('description');
      const name = feature.get('name');
      if (name && this.user_friendly_names) {
        return {
          description,
          inputText: `${name} (${apiName})`,
          primaryText: name,
          secondaryText: apiName,
        };
      }
      return {
        description,
        inputText: apiName,
        primaryText: apiName,
      };
    };

    return (
      <DropdownEntityPicker
        entities={features}
        selectedEntityId={selectedFeatureId}
        onSelectEntity={onSelectFeature}
        onUnselectEntity={onUnselectFeature}
        onCreateEntity={onCreateFeature}
        getEntityDisplayConfig={getSelectedFeatureDisplayConfig}
        inputPlaceholder={
          this.user_friendly_names
            ? tr('Search by name or key')
            : tr('Browse Features...')
        }
        createNewText={<b>Create New Feature...</b>}
        loadingEntities={isLoadingFeatures}
        key={`is_user_friendly_names_enabled=${this.user_friendly_names}`}
      />
    );
  }
}

export default FeaturePicker;

module.exports = {
  asi: true,
  boss: true,
  debug: true,
  elision: true,
  eqnull: true,
  expr: true,
  funcscope: true,
  iterator: true,
  laxbreak: true,
  laxcomma: true,
  loopfunc: true,
  multistr: true,
  proto: true,
  scripturl: true,
  shadow: true,
  sub: true,
  supernew: true,
  withstmt: true,
  '-W001': true,
  '-W002': true,
  '-W003': true,
  '-W004': true,
  '-W005': true,
  '-W006': true,
  '-W007': true,
  '-W008': true,
  '-W009': true,
  '-W010': true,
  '-W011': true,
  '-W012': true,
  '-W013': true,
  '-W014': true,
  '-W015': true,
  '-W016': true,
  '-W017': true,
  '-W018': true,
  '-W019': true,
  '-W020': true,
  '-W021': true,
  '-W022': true,
  '-W023': true,
  '-W024': true,
  '-W025': true,
  '-W026': true,
  '-W027': true,
  '-W028': true,
  '-W030': true,
  '-W031': true,
  '-W032': true,
  '-W033': true,
  '-W034': true,
  '-W035': true,
  '-W036': true,
  '-W037': true,
  '-W038': true,
  '-W039': true,
  '-W040': true,
  '-W041': true,
  '-W042': true,
  '-W043': true,
  '-W044': true,
  '-W045': true,
  '-W046': true,
  '-W047': true,
  '-W048': true,
  '-W049': true,
  '-W050': true,
  '-W051': true,
  '-W052': true,
  '-W053': true,
  '-W054': true,
  '-W055': true,
  '-W056': true,
  '-W057': true,
  '-W058': true,
  '-W059': true,
  '-W060': true,
  '-W061': true,
  '-W062': true,
  '-W063': true,
  '-W064': true,
  '-W065': true,
  '-W066': true,
  '-W067': true,
  '-W068': true,
  '-W069': true,
  '-W070': true,
  '-W071': true,
  '-W072': true,
  '-W073': true,
  '-W074': true,
  '-W075': true,
  '-W076': true,
  '-W077': true,
  '-W078': true,
  '-W079': true,
  '-W080': true,
  '-W081': true,
  '-W082': true,
  '-W083': true,
  '-W084': true,
  '-W085': true,
  '-W086': true,
  '-W087': true,
  '-W088': true,
  '-W089': true,
  '-W090': true,
  '-W091': true,
  '-W093': true,
  '-W094': true,
  '-W095': true,
  '-W096': true,
  '-W097': true,
  '-W098': true,
  '-W099': true,
  '-W100': true,
  '-W101': true,
  '-W102': true,
  '-W103': true,
  '-W104': true,
  '-W105': true,
  '-W106': true,
  '-W107': true,
  '-W108': true,
  '-W109': true,
  '-W110': true,
  '-W112': true,
  '-W113': true,
  '-W114': true,
  '-W115': true,
  '-W116': true,
  '-W117': true,
  '-W118': true,
  '-W119': true,
  '-W120': true,
  '-W121': true,
  '-W122': true,
  '-W123': true,
  '-W124': true,
  '-W125': true,
  '-W126': true,
  '-W127': true,
  '-W128': true,
  '-W129': true,
  '-W130': true,
  '-W131': true,
  '-W132': true,
  '-W133': true,
  '-W134': true,
  '-W135': true,
  '-W136': true,
  '-W137': true,
  '-W138': true,
  '-W139': true,
};

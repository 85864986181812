import { actions as NavActions } from 'NAVBAR';

import flux from 'core/flux';

import {
  actions as CurrentProjectActions,
  getters as CurrentProjectGetters,
} from 'optly/modules/current_project';
import { actions as ExperimentationGroupActions } from 'optly/modules/entity/experimentation_group';
import {
  actions as FilterableTableActions,
  enums as FilterableTableEnums,
} from 'optly/modules/filterable_table';
import { actions as LiveCommitTagActions } from 'optly/modules/entity/live_commit_tag';
import NavConstants from 'optly/services/navigation';

import { constants as SectionModuleConstants } from 'bundles/p13n/sections/layers/section_module';

import component from './page_component';

const routingSetup = (ctx, next) => {
  const byProject = {
    project_id: flux.evaluate(CurrentProjectGetters.id),
  };

  CurrentProjectActions.fetchCurrentProjectLayers();
  Promise.all([
    LiveCommitTagActions.fetchAll(byProject),
    ExperimentationGroupActions.fetchAll(byProject),
  ]).then(() => {
    NavActions.setActiveNavItem(NavConstants.NavItems.PERSONALIZATION);
    NavActions.setActiveTab(
      NavConstants.NavItems.PERSONALIZATION,
      NavConstants.LayersTabs.GROUPS,
    );
    FilterableTableActions.setFilterDefault(
      SectionModuleConstants.TABLE_IDS.GROUPS,
      {
        status: FilterableTableEnums.status.ACTIVE,
        string: '',
      },
    );
    next();
  });
};

export { component, routingSetup };

export default {
  component,
  routingSetup,
};

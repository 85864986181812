const _ = require('lodash');
const flux = require('core/flux');
const guid = require('optly/utils/guid');
const ui = require('core/ui').default;

const CurrentProjectGetters = require('optly/modules/current_project/getters');

const EditorActions = require('bundles/p13n/modules/editor/actions');
const EditorGetters = require('bundles/p13n/modules/editor/getters');
const LayerExperimentEnums = require('optly/modules/entity/layer_experiment/enums')
  .default;
const P13NUIActions = require('bundles/p13n/modules/ui/actions').default;
const ChangeEditorSidebarMixin = require('bundles/p13n/modules/ui/mixins/change_editor_sidebar')
  .default;
const PermissionsModuleFns = require('optly/modules/permissions/fns');

const RedirectInput = require('./subcomponents/redirect_input').default;
const SidebarHeader = require('../sidebar_header').default;
const Template = require('./template.html');

const COMPONENT_ID = 'p13n-redirect-editor-sidebar';
const IGNORE_EXISTING_CHANGE_WARNING_TYPES = [
  LayerExperimentEnums.ChangeTypes.REDIRECT,
  LayerExperimentEnums.ChangeTypes.CUSTOM_CODE,
  LayerExperimentEnums.ChangeTypes.CUSTOM_CSS,
];

const statusClassForAttribute = function(attributeName) {
  if (
    this.changeStatusMap.attributes &&
    this.changeStatusMap.attributes[attributeName]
  ) {
    return this.getStatusClassForAttribute(
      this.changeStatusMap.attributes[attributeName],
    );
  }

  return this.getStatusClassForAttribute(this.changeStatusMap[attributeName]);
};

const inputIdForAttribute = function(attributeName, id) {
  return [COMPONENT_ID, attributeName, id].join('--');
};

const redirectEditorSidebarComponent = {
  replace: true,

  template: Template,

  componentId: COMPONENT_ID,

  components: {
    'sidebar-header': SidebarHeader,
  },

  computed: {
    statusClassForAllowAdditionalRedirect: _.partial(
      statusClassForAttribute,
      'allowAdditionalRedirect',
    ),
    statusClassForDest: _.partial(statusClassForAttribute, 'dest'),
    statusClassForPreserveParameters: _.partial(
      statusClassForAttribute,
      'preserveParameters',
    ),

    showExistingChangeWarning() {
      return !!_.find(
        this.currentActionChanges,
        change =>
          !_.includes(IGNORE_EXISTING_CHANGE_WARNING_TYPES, change.type),
      );
    },

    showPreserveParameters() {
      return this.currentlyEditingChange.isRedirectUrl;
    },
  },

  data: {
    changeStatuses: LayerExperimentEnums.ChangeStatuses,
  },

  methods: {
    setAllowAdditionalRedirect(e) {
      EditorActions.setChangeRedirectProperty(
        'allowAdditionalRedirect',
        e.target.checked,
      );
    },

    setRedirectPreserveParameters(e) {
      EditorActions.setChangeRedirectProperty(
        'preserveParameters',
        e.target.checked,
      );
    },

    /**
     * Delete the redirect change from the current change store.
     */
    deleteChange(event, message) {
      ui.confirm({
        title: tr('Are you sure you want to delete this redirect?'),
        message: tr(
          'The deletion will not take effect until the next publish.',
        ),
        confirmText: tr('Delete Redirect'),
        isWarning: true,
      }).then(() => {
        if (__DEV__) {
          console.debug('[EDITOR_SIDEBAR] Deleted change: ' + this.currentlyEditingChange.id); // eslint-disable-line
        }
        const saveDef = EditorActions.deleteChange(
          this.currentlyEditingChange.id,
        ).then(() => {
          ui.showNotification({
            message: tr('This variation will no longer redirect.'),
            type: 'warning',
          });
          EditorActions.unsetCurrentlyEditingChange();
          EditorActions.refreshCurrentIframe();
          P13NUIActions.showChangeListSidebar(
            this.changeListSidebarComponentConfig,
          );
        });
        ui.loadingWhen('change-editor-sidebar', saveDef);
      });
    },
  },

  created() {
    const instanceGuid = guid();

    flux.bindVueValues(this, {
      activeFrameId: EditorGetters.activeFrameId,
      changeListSidebarComponentConfig:
        EditorGetters.changeListSidebarComponentConfig,
      changeStatusMap: EditorGetters.changeStatusMap(),
      currentActionChanges: EditorGetters.currentActionChanges,
      currentlyEditingChange: EditorGetters.currentlyEditingChange,
      currentlyEditingChangeIsDirty:
        EditorGetters.currentlyEditingChangeIsDirty,
      isEditorReadOnly: EditorGetters.isEditorReadOnly,
      canUpdateLayerExperiment: [
        CurrentProjectGetters.project,
        PermissionsModuleFns.canUpdateLayerExperiment,
      ],
    });

    this.inputIdForDest = inputIdForAttribute('dest', instanceGuid);
    this.inputIdForPreserveParameters = inputIdForAttribute(
      'preserveParameters',
      instanceGuid,
    );
    this.inputIdForAllowAdditionalRedirect = inputIdForAttribute(
      'allowAdditionalRedirect',
      instanceGuid,
    );
  },

  ready() {
    ui.renderReactComponent(
      this,
      {
        component: RedirectInput,
        el: this.$$.redirectInput,
      },
      __TEST__ &&
        (componentElement => {
          this.redirectInputComponent = componentElement.instance;
        }),
    );
  },
};

module.exports = _.merge(
  {},
  ChangeEditorSidebarMixin,
  redirectEditorSidebarComponent,
);

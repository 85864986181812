import _ from 'lodash';
import sprintf from 'sprintf';

import cloneDeep from 'optly/clone_deep';
import flux from 'core/flux';

import actionTypes from './action_types';
import fns from './fns';
import getters from './getters';
import constants from './constants';

/**
 * Write items to localStorage after updating localStorageMeta Store
 * @param {String} key
 * @param {String} data
 * @param {Date} dateModified - timestamp when entry was requested to be written
 */
export function writeToLocalStorage(key, data, dateModified) {
  if (!_.isString(data)) {
    console.warn(sprintf("Cant write non-stringy data for key: %s to localstorage", key)); //eslint-disable-line
    return;
  }

  const lruKeyList = cloneDeep(flux.evaluateToJS(getters.lruKeyList));
  const newItemSize = fns.calculateItemSizeInBytes(key, data);
  if (newItemSize > constants.LOCAL_STORAGE_BYTE_LIMIT) {
    console.warn(sprintf("Setting the value of %s exceeded localstorage quota", key)); //eslint-disable-line
    return;
  }

  // calculate size of items in localstorage that are not managed in meta store
  const nonMetaStoreManagedItemSize = this.getNonLruKeysTotalSize(lruKeyList);

  // figure out size of localStorage after this new item is inserted , considering key may already exist
  const localStorageSizeWithNewItem = this.calculateLocalStorageSizeWithNewItem(
    key,
    newItemSize,
    nonMetaStoreManagedItemSize,
  );

  // if incoming item has key that is already present in meta store
  let keysToRemove = [];

  if (localStorageSizeWithNewItem > constants.LOCAL_STORAGE_BYTE_LIMIT) {
    // new item has to be accommodated by removing some existing keys
    keysToRemove = this.getKeysToRemove(key, newItemSize);
    const newLocalStorageSize = this.calculateNewLocalStorageSizeWithRemovedKeys(
      newItemSize,
      keysToRemove,
      localStorageSizeWithNewItem,
    );
    if (newLocalStorageSize > constants.LOCAL_STORAGE_BYTE_LIMIT) {
      // we don't control enough of local storage to remove keys and fit item in
      console.warn(sprintf("Setting the value of %s exceeded available localstorage space", key)); //eslint-disable-line
      return;
    }
  } else {
    keysToRemove = _.includes(lruKeyList, key) ? [key] : [];
  }

  // free up storage to accommodate item
  flux.batch(() => {
    _.each(keysToRemove, keyToRemove => {
      flux.dispatch(actionTypes.LOCAL_STORAGE_META_REMOVE_ITEM, {
        key: keyToRemove,
      });
      this.removeItemFromLocalStorage(keyToRemove);
    });

    // set item in meta store
    flux.dispatch(actionTypes.LOCAL_STORAGE_META_ADD_ITEM, {
      key,
      size: newItemSize,
      dateModified,
    });
  });
  this.setItemInLocalStorage(key, data);
  this.persistMetaStoreEntriesToLocalStorage();
}

/**
 * Remove item from localStorage after updating localStorageMeta Store
 * and persist meta store entries to localstorage as well
 * @param {String} key
 */
export function removeFromLocalStorage(keyToRemove) {
  const lruKeyList = flux.evaluate(getters.lruKeyList);
  if (lruKeyList.contains(keyToRemove)) {
    flux.dispatch(actionTypes.LOCAL_STORAGE_META_REMOVE_ITEM, {
      key: keyToRemove,
    });
    // update meta store entries local storage key
    this.persistMetaStoreEntriesToLocalStorage();
  }
  this.removeItemFromLocalStorage(keyToRemove);
}

/**
 * Retrieve item from localstorage and update meta store if applicable
 * @param {String} key in localstorage to be accessed
 */
export function readFromLocalStorage(key) {
  const value = this.getItemFromLocalStorage(key);
  if (value) {
    const isManagedKey = flux.evaluate(getters.isManagedKey(key));
    if (isManagedKey) {
      flux.dispatch(actionTypes.LOCAL_STORAGE_META_UPDATE_ITEM, {
        key,
        dateModified: _.now(),
      });
      // update meta store entries state in local storage
      this.persistMetaStoreEntriesToLocalStorage();
    }
  }
  return value;
}

/**
 * Initialize the meta store with data from local storage (if it exists)
 */
export function initialize() {
  // get existing meta store from localstorage
  const metaStoreEntriesInLocalStorage = this.getItemFromLocalStorage(
    constants.META_STORE_LOCAL_STORAGE_KEY,
  );
  let metaStoreEntries = {};
  try {
    if (!_.isEmpty(metaStoreEntriesInLocalStorage)) {
      metaStoreEntries = JSON.parse(metaStoreEntriesInLocalStorage);
    } else {
      // invalid data => cleanup
      this.removeItemFromLocalStorage(constants.META_STORE_LOCAL_STORAGE_KEY);
      console.warn('Removing invalid data for meta store in localstorage'); //eslint-disable-line
    }
  } catch (e) {
    console.warn('Could not load meta store from local storage entry'); //eslint-disable-line
  }

  if (!_.isEmpty(metaStoreEntries)) {
    // load meta store
    flux.dispatch(actionTypes.LOCAL_STORAGE_META_LOAD_STORE, {
      entries: metaStoreEntries.entries,
      lruKeyList: metaStoreEntries.lruKeyList,
    });
  }
}

/**
 * Get total size in localStorage for non LRU keys in bytes
 * @param {Array} keysInLru
 * @return {Number} Bytes currently occupied in localstorage
 */
export function getNonLruKeysTotalSize(keysInLru) {
  // size estimate in Bytes assuming each char is 2 bytes
  let total = 0;
  for (let index = 0; index < window.localStorage.length; index++) {
    const key = window.localStorage.key(index);
    const isManagedKey = flux.evaluate(getters.isManagedKey(key));
    if (isManagedKey) {
      const value = window.localStorage.getItem(key);
      if (_.isString(value)) {
        total += 2 * (value.length + key.length);
      }
    }
  }
  return total;
}

/**
 * clear all entries from local storage meta store
 */
export function clearLocalStorageMetaStore() {
  flux.dispatch(actionTypes.LOCAL_STORAGE_META_CLEAR_STORE);
}

/**
 * Calculate new size of localstorage with new incoming item to be written to localstorage
 * The incoming item's key  may already exist in localStorage, so we make the existing item space available as well
 *
 * @param key {String} key to write into localstorage
 * @param newItemSize {Number} item size in bytes of entry expecting be written to localstorage
 * @param nonMetaStoreManagedItemSize {Number} total item size in bytes of keys in localstorage that are not managed in meta store
 * @private
 */
export function calculateLocalStorageSizeWithNewItem(
  key,
  newItemSize,
  nonMetaStoreManagedItemSize,
) {
  const existingLocalStorageSize =
    flux.evaluate(getters.sizeOfAllEntries) + nonMetaStoreManagedItemSize;
  let newLocalStorageSize = existingLocalStorageSize;
  // check if incoming key is already in store
  const isManagedKey = flux.evaluate(getters.isManagedKey(key));
  if (isManagedKey) {
    const existingKeySize = flux.evaluate(getters.entrySize(key));
    newLocalStorageSize -= existingKeySize;
  }
  newLocalStorageSize += newItemSize;
  return newLocalStorageSize;
}

/**
 * Return list of keys from localstorage whose removal would free up space enough for proposed key with newItemSize
 * @param {String} key   The key to set in localstorage
 * @param {Number} newItemSize
 * @private
 */
export function getKeysToRemove(key, newItemSize) {
  const isManagedKey = flux.evaluate(getters.isManagedKey(key));
  const keysToRemove = isManagedKey ? [key] : [];
  let bytesAvailable = isManagedKey ? flux.evaluate(getters.entrySize(key)) : 0;
  // pull managed key from lruKeyList if that's the key being updated
  const lruKeyList = _.pull(flux.evaluateToJS(getters.lruKeyList), key);

  if (bytesAvailable < newItemSize) {
    _.each(lruKeyList, lruKey => {
      const oldestKeySize = flux.evaluate(getters.entrySize(lruKey));
      bytesAvailable += oldestKeySize;
      keysToRemove.push(lruKey);
      if (bytesAvailable >= newItemSize) {
        return false;
      }
    });
  }
  return keysToRemove;
}

/**
 * Return new local storage size after removing size of removed keys that are managed in store and including the new item to be inserted
 * @param {Array} keysToRemove  list of keys from meta store that can be removed to accommodate new key
 * @param {Number} localStorageSizeWithNewItem total size of localstorage after new item is inserted/replaced
 * @private
 */
export function calculateNewLocalStorageSizeWithRemovedKeys(
  keysToRemove,
  localStorageSizeWithNewItem,
) {
  let newLocalStorageSize = localStorageSizeWithNewItem;
  _.each(keysToRemove, key => {
    newLocalStorageSize -= flux.evaluate(getters.entrySize(key));
  });
  return newLocalStorageSize;
}

/**
 * Persist meta store entries to local storage
 * @private
 */
export function persistMetaStoreEntriesToLocalStorage() {
  const serializedMetaStore = flux.evaluate(getters.serializedMetaStore);
  this.setItemInLocalStorage(
    constants.META_STORE_LOCAL_STORAGE_KEY,
    serializedMetaStore,
  );
}

/**
 * Write item to localStorage
 * @param {String} key
 * @param {String} data
 */
export function setItemInLocalStorage(key, data) {
  window.localStorage.setItem(key, data);
}

/**
 * Remove item from localStorage
 * @param {String} key
 */
export function removeItemFromLocalStorage(key) {
  window.localStorage.removeItem(key);
}

/**
 * Get item from localStorage
 * @param {String} key
 */
export function getItemFromLocalStorage(key) {
  return window.localStorage.getItem(key);
}

export default {
  calculateLocalStorageSizeWithNewItem,
  calculateNewLocalStorageSizeWithRemovedKeys,
  clearLocalStorageMetaStore,
  getItemFromLocalStorage,
  getKeysToRemove,
  getNonLruKeysTotalSize,
  initialize,
  persistMetaStoreEntriesToLocalStorage,
  readFromLocalStorage,
  removeFromLocalStorage,
  removeItemFromLocalStorage,
  setItemInLocalStorage,
  writeToLocalStorage,
};

/**
 * Entity Definition for BillingInfo.
 *
 * Used primarily by plan_tab (Billing Settings page) to gather
 * usage related information about the Account.
 */
export default {
  entity: 'billing_info',

  parent: {
    entity: 'accounts',
    key: 'account_id',
  },
};

const _ = require('lodash');
const classnames = require('classnames');
const PropTypes = require('prop-types');
const React = require('react');
const Immutable = require('optly/immutable').default;
const { Input } = require('optimizely-oui');
const SelectDropdown = require('react_components/select_dropdown').default;
const ViewConstants = require('optly/modules/entity/view/constants');

class SingleUrl extends React.Component {
  constructor(props) {
    super(props);

    this.onChangeCondition = this.onChangeCondition.bind(this);
    this.onChangeMatchType = this.onChangeMatchType.bind(this);
  }

  onChangeCondition(event) {
    const newConditionValue = event.target.value;
    const updatedConditions = this.props.includeUrlConditions.setIn(
      [0, 'value'],
      newConditionValue,
    );
    this.props.onChange(updatedConditions);
  }

  onChangeMatchType(newMatchTypeValue) {
    const updatedConditions = this.props.includeUrlConditions.setIn(
      [0, 'match_type'],
      newMatchTypeValue,
    );
    this.props.onChange(updatedConditions);
  }

  render() {
    const { error, includeUrlConditions, showMatchType } = this.props;
    const singleUrlCondition = includeUrlConditions.get(0);

    return (
      <table className="lego-table push--bottom">
        <thead>
          <tr>
            <th>
              URL
              <span className="oui-label display--inline oui-label--required" />
            </th>
            {showMatchType && <th>Match Type</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              className={classnames({
                'lego-form-bad-news': error,
                'width--1-1': true,
              })}>
              <Input
                value={singleUrlCondition.get('value')}
                onChange={this.onChangeCondition}
                type="text"
                placeholder="Enter a URL"
                testSection="condition-url-input-row-0"
              />
              {error && (
                <div
                  className="lego-form-note lego-form-note--bad-news"
                  data-test-section="error-url-condition">
                  {error}
                </div>
              )}
            </td>
            {showMatchType && (
              <td>
                {_.some(
                  ViewConstants.SINGLE_URL_MATCH_TYPES,
                  matchTypes =>
                    singleUrlCondition.get('match_type') === matchTypes.value,
                ) && (
                  <SelectDropdown
                    items={ViewConstants.SINGLE_URL_MATCH_TYPES}
                    value={singleUrlCondition.get('match_type')}
                    onChange={this.onChangeMatchType}
                    width="200px"
                  />
                )}
              </td>
            )}
          </tr>
        </tbody>
      </table>
    );
  }
}

SingleUrl.propTypes = {
  error: PropTypes.string,
  includeUrlConditions: PropTypes.instanceOf(Immutable.List).isRequired,
  onChange: PropTypes.func.isRequired,
  showMatchType: PropTypes.bool,
};

SingleUrl.defaultProps = {
  showMatchType: true,
};

module.exports = SingleUrl;

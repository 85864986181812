import fieldTypes from 'optly/modules/rest_api/field_types';

const defaultFields = {
  project_id: null,
  user_id: null,
  role_name: null,
  title: null,
  first_name: null,
  last_name: null,
  invitation_status: null,
};
const v2Fields = {
  ...defaultFields,
  target_user_email: null,
};
const defaultFieldTypes = {
  project_id: fieldTypes.NUMBER,
  user_id: fieldTypes.NUMBER,
  role_name: fieldTypes.STRING,
  title: fieldTypes.STRING,
  first_name: fieldTypes.STRING,
  last_name: fieldTypes.STRING,
  invitation_status: fieldTypes.STRING,
};
const v2FieldTypes = {
  ...defaultFieldTypes,
  target_user_email: fieldTypes.STRING,
};

/**
 * Entity Definitions for collaborator.  There are multiple entity definitions for collaborators due to the
 * need to invoke a mixture of new and old APIs with inconsistent API paths.  Invited (/collaborators_v2) collaborators
 * are retrieved via a separate GET endpoint from approved collaborators (/collaborators), but both versions can
 * be edited via a single create/update endpoint.
 *
 * See: https://confluence.sso.episerver.net/pages/viewpage.action?pageId=2896867659
 */
export default {
  // Entity definition for the v1 (approved) collaborator API endpoints:
  // - list approved collaborators: GET `https://app.optimizely.test/api/v1/projects/${projectId}/collaborators`
  v1: {
    entity: 'collaborators',

    isRelationshipEntity: true,

    parents: [
      {
        entity: 'projects',
        key: 'project_id',
      },
      {
        entity: 'collaborators',
        key: 'user_id',
      },
    ],
    fields: defaultFields,
    fieldTypes: defaultFieldTypes,
  },
  // Entity definition for the v2 (invited) project-scoped collaborator API endpoints:
  // - list invitations: GET `https://app.optimizely.test/api/v1/projects/${projectId}/collaborators_v2`
  v2: {
    entity: 'collaborators_v2',

    isRelationshipEntity: true,

    parents: [
      {
        entity: 'projects',
        key: 'project_id',
      },
      {
        entity: 'collaborators_v2',
        key: 'target_user_email',
      },
    ],
    fields: v2Fields,
    fieldTypes: v2FieldTypes,
  },
  // Entity definition for the v2 collaborator API endpoints that are v1 entity compatible:
  // - bulk create or update: PUT `https://app.optimizely.test/api/v1/collaborators_v2` (multi-entity)
  // - delete v1 or v2: DELETE `https://app.optimizely.test/api/v1/collaborators_v2/${userId}`
  crossCompatible: {
    entity: 'collaborators_v2',

    isRelationshipEntity: false,

    // this API uses PUT for entity creates, so we override the default 'post' verb here
    createEntityVerb: 'put',

    fields: v2Fields,
    fieldTypes: v2FieldTypes,
  },
};

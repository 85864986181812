import { Attention, Button } from 'optimizely-oui';

import PropTypes from 'prop-types';

import React from 'react';

import { connect } from 'core/ui/decorators';
import Immutable from 'optly/immutable';

import { getters as CurrentlyEditingExperimentGetters } from 'bundles/p13n/modules/currently_editing_experiment';
import ExperimentSectionConstants from 'optly/modules/entity/experiment_section/constants';

import MVTSectionRow from './subcomponents/mvt_section_row';

const MAX_COMBINATION_COUNT = ExperimentSectionConstants.MAX_COMBINATION_COUNT;

@connect(() => ({
  sections: CurrentlyEditingExperimentGetters.sections,
  errors: CurrentlyEditingExperimentGetters.errors,
  combinationCount: CurrentlyEditingExperimentGetters.combinationCount,
}))
class MVTSectionContainer extends React.Component {
  static propTypes = {
    sections: PropTypes.instanceOf(Immutable.List).isRequired,
    errors: PropTypes.instanceOf(Immutable.Map).isRequired,
    combinationCount: PropTypes.number.isRequired,
    onAddSection: PropTypes.func.isRequired,
    onRemoveSection: PropTypes.func.isRequired,
    onUpdateSectionName: PropTypes.func.isRequired,
    onAddVariationToSection: PropTypes.func.isRequired,
    onRemoveVariationFromSection: PropTypes.func.isRequired,
    onUpdateVariationInSection: PropTypes.func.isRequired,
  };

  render() {
    const {
      sections,
      combinationCount,
      errors,
      onAddSection,
      onUpdateSectionName,
      onRemoveSection,
      onAddVariationToSection,
      onRemoveVariationFromSection,
    } = this.props;

    const onUpdateVariationInSection = (sectionGuid, variation) => {
      this.props.onUpdateVariationInSection(sectionGuid, variation);
    };

    const sectionCount = sections.size;

    const mvtErrors = [
      'mvtCombinationLimit',
      'mvtSectionNames',
      'mvtVariationNames',
      'mvtVariationAllocation',
    ];
    const hasValidationError = mvtErrors.some(
      errorType => !!errors.get(errorType),
    ); // coerce empty string to boolean

    return (
      <ol className="lego-form-fields">
        <li className="lego-form-field__item">
          <h3>Sections</h3>
          <p
            className="push-double--bottom"
            data-test-section="variations-explanation-text">
            Multivariate testing is a technique for testing a hypothesis in
            which multiple sections are modified. The goal of multivariate
            testing is to determine which combination of variations performs the
            best out of all the possible combinations.
          </p>
          <p data-test-section="section-combination-metadata">
            Total Combinations: {combinationCount}
            <span className="muted push--left">
              Max: {MAX_COMBINATION_COUNT}
            </span>
          </p>
          {sections.map((section, sectionIndex) => (
            <MVTSectionRow
              key={section.get('guid')}
              sectionIndex={sectionIndex}
              section={section}
              sectionCount={sectionCount}
              onRemoveSection={onRemoveSection}
              onUpdateSectionName={onUpdateSectionName}
              onAddVariationToSection={onAddVariationToSection}
              onRemoveVariationFromSection={onRemoveVariationFromSection}
              onUpdateVariationInSection={onUpdateVariationInSection}
            />
          ))}
          <div className="push-double--top">
            <Button
              onClick={onAddSection}
              testSection="mvt-sections-add-section"
              width="full">
              Add Section
            </Button>
          </div>
          {combinationCount > MAX_COMBINATION_COUNT && (
            <div
              className="push-double--top"
              data-test-section="mvt-sections-creation-warning-combination-limit">
              <Attention alignment="left" type="warning">
                <strong>
                  You&apos;ve exceeded the maximum number of combinations.
                </strong>{' '}
                Multivariate tests support a maximum of{' '}
                <strong>{MAX_COMBINATION_COUNT} combinations</strong>. Either
                remove sections or variations to save this experiment. Current
                combination count is {combinationCount}.
              </Attention>
            </div>
          )}
          {hasValidationError && (
            <div
              className="lego-form-note lego-form-note--bad-news push-double--top"
              data-test-section="mvt-sections-creation-error">
              {mvtErrors.map(errorType => {
                if (errors.get(errorType)) {
                  return (
                    <p
                      data-test-section={`mvt-sections-creation-error-${errorType}`}
                      key={errorType}>
                      {errors.get(errorType)}
                    </p>
                  );
                }
              })}
            </div>
          )}
        </li>
      </ol>
    );
  }
}

export default MVTSectionContainer;

export const categories = {
  ALL: 'All',
  ANALYTICS: 'Analytics',
  AUDIENCES: 'Audiences',
  CALL_TRACKING: 'Call Tracking',
  CONTENT_MANAGEMENT: 'Content Management',
  CUSTOM_ANALYTICS: 'Custom Analytics',
  CUSTOM_AUDIENCES: 'Custom Audiences',
  HEATMAP: 'Heatmap',
  PRODUCTIVITY: 'Productivity',
};

export const products = {
  AB_TESTING: 'ab_testing', // Classic
  PERSONALIZATION: 'personalization', // X
};

export default {
  categories,
  products,
};

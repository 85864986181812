import PropTypes from 'prop-types';
import React from 'react';

import Body from './Body';
import Label from './Label';
import Row from './Row';

const Card = props => {
  // Add bottom margin to all but the last child.
  const children = React.Children.toArray(props.children)
    .filter(Child => Child !== null)
    .map((Child, i, allChildren) => {
      const isLastChild = i === allChildren.length - 1;

      const wrapperProps = {
        className: !isLastChild ? 'push-double--bottom' : null,
      };

      /* Since the children are converted with toArray, we reference their keys off the objects themselves, not their props */
      /* See: https://facebook.github.io/react/docs/react-api.html#react.children.toarray */
      wrapperProps.key = Child.key;

      return <div {...wrapperProps}>{Child}</div>;
    });

  return (
    <div
      data-ui-component={true}
      className="shadow-box"
      data-test-section={props.testSection}>
      {children}
    </div>
  );
};

Card.propTypes = {
  /** Expects one or more `Card.Row` components */
  children: PropTypes.node,

  /** Identifier used to create data-test-section attributes for testing */
  testSection: PropTypes.string,
};

Card.Body = Body;
Card.Label = Label;
Card.Row = Row;

export default Card;

import React from 'react';
import classNames from 'classnames';

import { Attention, Label, Link, SelectDropdown } from 'optimizely-oui';

import ComponentModuleFns from '../../component_module/fns';
import {
  ReadableMetricsFields,
  UNDEFINED,
} from '../../component_module/constants';

import {
  MetricWinningDirectionEnum,
  MetricAggregatorEnum,
  MetricFieldEnum,
  MetricScopeEnum,
  SheetMetricTypeEnum,
} from '../../component_module/types';

interface MetricMeasureConfigProps {
  advancedMetricLink: string;
  aggregatorFormField: any;
  displayError: boolean;
  fieldFormField: any;
  metricType: SheetMetricTypeEnum;
  note?: string;
  scopeFormField: any;
  winningDirectionFormField: any;
}

const MetricMeasureConfig = ({
  advancedMetricLink,
  aggregatorFormField,
  displayError,
  fieldFormField,
  metricType,
  note,
  scopeFormField,
  winningDirectionFormField,
}: MetricMeasureConfigProps) => {
  const {
    description,
    isAdvancedConfigFromApi,
  } = ComponentModuleFns.getMetricDescription(
    winningDirectionFormField.getValue(),
    aggregatorFormField.getValue(),
    fieldFormField.getValue(),
    scopeFormField.getValue(),
  );

  const renderVisitor = () => {
    if (fieldFormField.getValue() === null) {
      // Only set 'visitor' if the current value is not already null
      if (scopeFormField.getValue() !== 'visitor') {
        setTimeout(() => {
          scopeFormField.setValue(MetricScopeEnum.Visitor);
        });
      }
    }
    return MetricScopeEnum.Visitor;
  };

  return (
    <div
      className={classNames({ 'oui-form-bad-news': displayError })}
      data-test-section="metric-measure-config">
      <Label>Measure</Label>
      {isAdvancedConfigFromApi ? (
        <div data-test-section="metric-measure-config-api-configurable">
          {description}
          <div className="push--top">
            <Attention alignment="center" type="brand">
              This metric has an advanced configuration. Please use the API to
              update or remove and create again.{' '}
              <Link href={advancedMetricLink} newWindow={true}>
                View developer documentation
              </Link>
              .
            </Attention>
          </div>
        </div>
      ) : (
        <div
          className="flex flex-align--center"
          data-test-section="metric-measure-config-ui-configurable">
          <SelectDropdown
            items={[
              {
                label: ReadableMetricsFields.winningDirection.increasing,
                value: MetricWinningDirectionEnum.Increasing,
              },
              {
                label: ReadableMetricsFields.winningDirection.decreasing,
                value: MetricWinningDirectionEnum.Decreasing,
              },
            ]}
            onChange={(updatedValue: string) =>
              winningDirectionFormField.setValue(updatedValue)
            }
            testSection="metric-winning-direction-dropdown"
            value={winningDirectionFormField.getValue()}
          />
          <div className="push-half--sides">in</div>
          <div data-test-section="aggregator-and-field">
            {/* If the event ID is UNDEFINED it is the Overall Revenue global metric and the field and aggregator cannot be changed */}
            {metricType === SheetMetricTypeEnum.Revenue ? (
              ReadableMetricsFields.aggregatorAndField.sum.revenue
            ) : (
              <SelectDropdown
                items={[
                  {
                    label:
                      ReadableMetricsFields.aggregatorAndField.unique[
                        UNDEFINED
                      ],
                    value:
                      ReadableMetricsFields.aggregatorAndField.unique[
                        UNDEFINED
                      ],
                  },
                  {
                    label:
                      ReadableMetricsFields.aggregatorAndField.count[UNDEFINED],
                    value:
                      ReadableMetricsFields.aggregatorAndField.count[UNDEFINED],
                  },
                  {
                    label: ReadableMetricsFields.aggregatorAndField.sum.revenue,
                    value: ReadableMetricsFields.aggregatorAndField.sum.revenue,
                  },
                  {
                    label: ReadableMetricsFields.aggregatorAndField.sum.value,
                    value: ReadableMetricsFields.aggregatorAndField.sum.value,
                  },
                ]}
                onChange={(updatedValue: string) => {
                  switch (updatedValue) {
                    case ReadableMetricsFields.aggregatorAndField.unique[
                      UNDEFINED
                    ]:
                      aggregatorFormField.setValue(MetricAggregatorEnum.Unique);
                      fieldFormField.setValue(null);
                      return;
                    case ReadableMetricsFields.aggregatorAndField.count[
                      UNDEFINED
                    ]:
                      aggregatorFormField.setValue(MetricAggregatorEnum.Count);
                      fieldFormField.setValue(null);
                      return;
                    case ReadableMetricsFields.aggregatorAndField.sum.revenue:
                      aggregatorFormField.setValue(MetricAggregatorEnum.Sum);
                      fieldFormField.setValue(MetricFieldEnum.Revenue);
                      return;
                    case ReadableMetricsFields.aggregatorAndField.sum.value:
                      aggregatorFormField.setValue(MetricAggregatorEnum.Sum);
                      fieldFormField.setValue(MetricFieldEnum.Value);
                      return;
                  }
                }}
                testSection="metric-aggregator-and-field-dropdown"
                value={ComponentModuleFns.getAggregatorAndFieldDescription(
                  aggregatorFormField.getValue(),
                  fieldFormField.getValue(),
                )}
              />
            )}
          </div>
          <div className="push-half--sides">per</div>
          {fieldFormField.getValue() ? (
            <>
              <SelectDropdown
                items={[
                  {
                    label: ReadableMetricsFields.scope.visitor,
                    value: MetricScopeEnum.Visitor,
                  },
                  {
                    label: ReadableMetricsFields.scope.event,
                    value: MetricScopeEnum.Event,
                  },
                ]}
                onChange={(updatedValue: string) =>
                  scopeFormField.setValue(updatedValue)
                }
                testSection="metric-scope-dropdown"
                value={scopeFormField.getValue()}
              />{' '}
            </>
          ) : (
            renderVisitor()
          )}
        </div>
      )}
      {note && (
        <div
          className="oui-form-note"
          data-test-section="metric-measure-config-note">
          {note}
        </div>
      )}
    </div>
  );
};

export default MetricMeasureConfig;

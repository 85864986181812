export const EXPERIENCES = tr('Experiences');
export const EXPERIMENTS_OVERVIEW = tr('Experiments Overview');
export const MULTIVARIATE_SECTIONS = tr('Sections');
export const MULTIVARIATE_COMBINATIONS = tr('Combinations');
export const VARIATIONS = tr('Variations');
export const REPORTS = tr('Reports');

export default {
  EXPERIENCES,
  EXPERIMENTS_OVERVIEW,
  MULTIVARIATE_SECTIONS,
  MULTIVARIATE_COMBINATIONS,
  VARIATIONS,
  REPORTS,
};

export const categories = {
  EXPERIMENT: 'experiment',
  GOAL: 'goal',
  SECTION: 'section',
  VARIATION: 'variation',
};

export default {
  categories,
};

import { useCallback, useState } from 'react';

const useToggle = (initialState = false) => {
  const [state, setState] = useState<boolean>(initialState);

  const toggle = useCallback(
    (): void => setState(currentState => !currentState),
    [],
  );

  return [state, toggle] as const;
};

export default useToggle;

import PropTypes from 'prop-types';
import React from 'react';

import { Button, ButtonRow, Link, Icon } from 'optimizely-oui';

import ui from 'core/ui';
import Immutable from 'optly/immutable';

import { actions as LayerExperimentActions } from 'optly/modules/entity/layer_experiment';

import LoadingOverlay from 'react_components/loading_overlay';

const helpPage =
  'https://help.optimizely.com/Analyze_Results/Send_all_traffic_to_a_winning_variation#Show_a_winning_variation_to_all_visitors';
const StopVariationDialog = function(props) {
  const saveAsPartialFactorial = props.saveAsPartialFactorial || false;
  const isOriginal = props.isP13NCampaign
    ? false
    : props.experiment.getIn(['variations', 0, 'variation_id']) ===
      props.variation.get('variation_id');

  const onConfirm = function() {
    const saveDef = LayerExperimentActions.stopVariation(
      props.variation.get('variation_id'),
      props.experiment.toJS(),
      props.experiment.get('variations').toJS(),
      saveAsPartialFactorial,
    ).then(() => {
      if (props.onSave) {
        props.onSave();
      }
      ui.hideDialog();
    });
    ui.loadingWhen('stop-variation-dialog', saveDef);
    if (props.onSaving) {
      props.onSaving(saveDef);
    }
  };

  const computeDialogBodyMessage = function(name, canUseWebStickyBucketing) {
    if (isOriginal) {
      return (
        <p data-test-section="original-message">
          Stopping the Original variation will prevent users from seeing it as
          well as change the baseline against which your results are calculated.
          Visitors previously allocated to the Original variation will be
          reallocated to a different variation the next time they see
          experiment. If the intent is to send all traffic to a winning
          variation, please see this{' '}
          <Link newWindow={true} href={helpPage}>
            article
          </Link>{' '}
          so that the integrity of this this experiment’s results can be
          preserved.
        </p>
      );
    }
    if (canUseWebStickyBucketing) {
      return (
        <p data-test-section="sticky-not-original-message">
          Stopping {name} will exclude returning visitors from the experiment.
          Visitors already allocated to {name} will not see this experiment when
          they return. They will no longer be measured in the Results page.
        </p>
      );
    }
    return (
      <p data-test-section="not-original-message">
        Stopping {name} will reallocate returning visitors to the remaining
        variation(s), and may affect your results.
      </p>
    );
  };

  return (
    <LoadingOverlay loadingId="stop-variation-dialog">
      <div
        className="lego-dialog--narrow"
        data-test-section="stop-variation-dialog">
        <div className="lego-dialog__header">
          <div
            className="lego-dialog__title"
            data-test-section="dialog-title-stop-variation">
            Stop {props.variation.get('name')}
          </div>
        </div>
        <div className="lego-dialog__body">
          {computeDialogBodyMessage(
            props.variation.get('name'),
            props.canUseWebStickyBucketing,
          )}
          <div className="lego-media color--bad-news">
            <Icon
              name="circle-exclamation"
              size="small"
              className="lego-icon lego-media__img"
              color="color--bad-news"
            />
            <div className="lego-media__body">
              <p>
                You cannot undo this action once you publish the experiment.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="lego-dialog__footer">
        <ButtonRow
          rightGroup={[
            <Button
              testSection="cancel-button"
              style="plain"
              onClick={ui.hideDialog}
              key={0}>
              Cancel
            </Button>,
            <Button
              testSection="stop-variation-button"
              className="lego-button lego-button--danger"
              style="danger"
              onClick={onConfirm}
              key={1}>
              Stop Variation
            </Button>,
          ]}
        />
      </div>
    </LoadingOverlay>
  );
};

StopVariationDialog.propTypes = {
  variation: PropTypes.instanceOf(Immutable.Map).isRequired,
  experiment: PropTypes.instanceOf(Immutable.Map).isRequired,
  onSave: PropTypes.func,
  onSaving: PropTypes.func,
  canUseWebStickyBucketing: PropTypes.bool,
  isP13NCampaign: PropTypes.bool.isRequired,
  saveAsPartialFactorial: PropTypes.bool,
};

StopVariationDialog.defaultProps = {
  canUseWebStickyBucketing: true,
};

export default StopVariationDialog;

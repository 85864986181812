/**
 * Third Party Condition Field Base Component
 * Serves as the base component for all condition field components to share common functionality.
 */
const flux = require('core/flux');
const AudienceBuilder = require('optly/modules/ui/audience_builder');

module.exports = {
  replace: true,

  // to be overridden by sub components
  template: '',

  data: {
    config: {},
  },

  ready() {
    // in key/value types the condition.name is '<condition.name>.<key>'
    // ex: 'demandbase.watch_list.123' and we need 'demandbase.watch_list' to do a lookup
    // in `thirdPartyConditionsByName` getter
    const conditionName = AudienceBuilder.fns.getThirdPartyIntegrationName(
      this.condition.name,
    );
    flux.bindVueValues(this, {
      config: AudienceBuilder.getters.thirdPartyConditionsByName(conditionName),
    });
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import { partial, cloneDeep } from 'lodash';
import { Label } from 'optimizely-oui';

import ConditionGroup from 'optly/models/condition_group';

import ConditionCards from './cards';

export default class ConditionBuilder extends React.Component {
  static propTypes = {
    conditionGroup: PropTypes.instanceOf(ConditionGroup).isRequired,
    conditionGroupDidMutate: PropTypes.func.isRequired,
    edgeLearnMoreLinks: PropTypes.object,
    isWebEdgeProject: PropTypes.bool,
    updateConditionErrors: PropTypes.func.isRequired,
    errors: PropTypes.array,
  };

  static defaultProps = {
    edgeLearnMoreLinks: {},
    errors: [],
    isWebEdgeProject: false,
  };

  /*
   * @param {ConditionGroup} conditionToRemove
   */
  onConditionChange = (index, conditionToRemove) => {
    if (conditionToRemove) {
      const newErrors = cloneDeep(this.props.errors);
      newErrors.splice(index, 1);
      this.props.updateConditionErrors(newErrors);
      this.props.conditionGroup.removeCondition(conditionToRemove);
    }
    this.props.conditionGroupDidMutate();
  };

  updateConditionErrorsAtIndex = (index, newConditionError) => {
    const newErrors = cloneDeep(this.props.errors);
    newErrors[index] = newConditionError;
    this.props.updateConditionErrors(newErrors);
  };

  render = () => {
    const { conditions } = this.props.conditionGroup;

    const cardsToRender = conditions
      .map((cond, index) => {
        // We can safely assume that each ConditionGroup.conditions entry has the same type.
        const conditionType = cond.conditions[0].type;
        const ConditionCard = ConditionCards(conditionType);
        return (
          <ConditionCard
            key={index}
            condition={cond}
            onConditionChange={partial(this.onConditionChange, index)}
            errors={this.props.errors[index]}
            updateErrors={partial(this.updateConditionErrorsAtIndex, index)}
            isWebEdgeProject={this.props.isWebEdgeProject}
            edgeLearnMoreLinks={this.props.edgeLearnMoreLinks}
          />
        );
      })
      // Insert a card between each condition for the conjunction (and/or) being used.
      .reduce((acc, current, index) => {
        const conjunctionElement = (
          <div
            key={`type_${index}`}
            className="push-half--ends style--italic"
            data-test-section="condition-builder-conjunction">
            {this.props.conditionGroup.type}
          </div>
        );
        return acc.concat(
          current,
          index < conditions.length - 1 ? conjunctionElement : [],
        );
      }, []);
    return (
      <div className="soft-double--ends">
        <Label>Conditions</Label>
        {/* TODO: Validate that each props.conditionGroup.conditions is the same type
         *       If there is a mix, it has been edited by the API or is corrupt.
         */}
        {conditions.length ? (
          cardsToRender
        ) : (
          <div
            className="muted text--center height--150 flex flex-justified--center flex--column"
            data-test-section="condition-builder-empty-state">
            <h3>No Conditions</h3>
            <p>
              This page will activate{' '}
              <span className="weight--bold">
                as soon as it&#39;s triggered.
              </span>
            </p>
          </div>
        )}
      </div>
    );
  };
}

import PropTypes from 'prop-types';
import React from 'react';
import { Textarea } from 'optimizely-oui';

export default class RedirectUrlInput extends React.Component {
  render() {
    const { destValue, onValueUpdated } = this.props;

    const inputUpdateHandler = function(event) {
      onValueUpdated(event.target.value);
    };

    return (
      <Textarea
        testSection="redirect-editor-dest-input"
        defaultValue={destValue}
        placeholder="Enter URL..."
        onInput={inputUpdateHandler}
      />
    );
  }
}

RedirectUrlInput.propTypes = {
  destValue: PropTypes.string,
  onValueUpdated: PropTypes.func.isRequired,
};

RedirectUrlInput.defaultProps = {
  destValue: '',
};

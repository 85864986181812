/* eslint-disable react/jsx-pascal-case */
import React from 'react';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import BundleSplitHelper from 'optly/utils/bundle_split_helper';
import NavConstants from 'optly/services/navigation';
import { enums as FilterableTableEnums } from 'optly/modules/filterable_table';
import { actions as PerformanceTrackingActions } from 'optly/modules/performance_tracking';
import OptimizelyChampagneEnums from 'optly/modules/optimizely_champagne/enums';
import EventModuleEnums from 'optly/modules/entity/event/enums';
import Router from 'core/router';

import RoutingFns from '../../routing_fns';

const category = 'Implementation';

/*
 * Routing function that ensures the Views Section dynamic bundle has been fetched
 * We store the returned dynamic bundle module on the function object itself
 */
function ViewsSection(ctx, next) {
  import(
    /* webpackChunkName: "views-bundle" */
    'bundles/p13n/sections/views' // eslint-disable-line
  )
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(ViewsSection, module);
    })
    .then(next);
}

const EnsureCodeLintingBundle = (ctx, next) => {
  BundleSplitHelper.getCodeLintingBundleModules().then(next);
};

/*
 * Routes for the views section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/projects/implementation',
    handle: [() => Router.redirect('/p13n/implementation/pages')],
  },
  {
    match: '/v2/projects/:proj_id/implementation/pages',
    metadata: { name: 'Pages', category },
    handle: [
      [
        ViewsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PAGES,
        ),
        RoutingFns.parseProjectId,
        RoutingFns.parseQueryFilters(
          FilterableTableEnums.tableIds.P13N_DATA_LAYER_VIEWS,
        ),
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      (ctx, next) => {
        const useSearchAPIForPages = isFeatureEnabled(
          OptimizelyChampagneEnums.FEATURES.use_search_api_for_pages
            .FEATURE_KEY,
        );
        if (useSearchAPIForPages) {
          next();
          return;
        }
        Promise.all([
          new Promise(resolve => RoutingFns.fetchCategories(ctx, resolve)),
          new Promise(resolve =>
            RoutingFns.fetchViewsForCurrentProject(ctx, resolve),
          ),
          new Promise(resolve =>
            RoutingFns.fetchEventsForCurrentProject(ctx, resolve),
          ),
          new Promise(resolve =>
            RoutingFns.fetchTagsForCurrentProject(ctx, resolve),
          ),
        ]).then(next);
      },
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Pages',
          category,
        }),
      () => {
        PerformanceTrackingActions.setFrontendRenderStartTime();
        RoutingFns.renderMainRegion(<ViewsSection.pages.dashboard.component />);
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/implementation/pages/:view_id',
    metadata: { name: 'Page Builder', category },
    handle: [
      [
        ViewsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PAGES,
        ),
        EnsureCodeLintingBundle,
        RoutingFns.parseProjectId,
      ],
      [RoutingFns.fetchCategories, RoutingFns.fetchEventsForCurrentProject],
      (ctx, next) => ViewsSection.pages.editor.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Page Builder',
          category,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <ViewsSection.pages.editor.PageComponent />,
        ),
    ],
  },
  {
    match: '/v2/projects/:proj_id/implementation/pages/:view_id/events/create',
    metadata: { name: 'Event Builder', category },
    handle: [
      [
        ViewsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PAGES,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.fetchCategories,
      (ctx, next) => ViewsSection.pages.editor.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Event Builder',
          category,
        }),
      ctx => {
        const params = ctx.queryParams;
        const eventType = params.event_type
          ? params.event_type
          : EventModuleEnums.eventTypes.CLICK;

        RoutingFns.renderMainRegion(
          <ViewsSection.pages.editor.PageComponent
            createEventType={eventType}
          />,
        );
      },
    ],
  },
  {
    match:
      '/v2/projects/:proj_id/implementation/pages/:view_id/events/:event_id',
    metadata: { name: 'Event Builder', category },
    handle: [
      [
        ViewsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PAGES,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.fetchCategories,
      RoutingFns.fetchEventsForCurrentProject,
      RoutingFns.setCurrentEvent,
      (ctx, next) => ViewsSection.pages.editor.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Event Builder',
          category,
        }),
      ctx => {
        RoutingFns.renderMainRegion(
          <ViewsSection.pages.editor.PageComponent
            editingEventId={Number(ctx.params.event_id)}
          />,
        );
      },
    ],
  },
];

export default routes;

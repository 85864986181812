import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from '@optimizely/axiom';

const MetricDropdownItem = ({
  actionString,
  clickHandler,
  testSection,
  isDestructive,
}) => {
  return (
    <Dropdown.ListItem key={`metric-${testSection}-action`}>
      <Dropdown.BlockLink
        onClick={clickHandler}
        testSection={`tile-${testSection}`}>
        <Dropdown.BlockLinkText
          isDestructive={isDestructive}
          text={actionString}
        />
      </Dropdown.BlockLink>
    </Dropdown.ListItem>
  );
};

MetricDropdownItem.propTypes = {
  actionString: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  isDestructive: PropTypes.bool,
  testSection: PropTypes.string.isRequired,
};

MetricDropdownItem.defaultProps = {
  isDestructive: false,
};

export default React.memo(MetricDropdownItem);

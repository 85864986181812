import activityString from './activity_string';
import capitalize from './capitalize';
import filterProp from './filter_prop';
import formatCurrency from './format_currency';
import formatDate from './format_date';
import formatDatetime from './format_datetime';
import formatDescription from './format_description';
import formatFileSize from './format_file_size';
import formatNumber from './format_number';
import formatTime from './format_time';
import formatPhoneNumber from './format_phone_number';
import fulldate from './fulldate';
import insertLocation from './insert_location';
import join from './join';
import listToReadableString from './list_to_readable_string';
import numberToLetter from './number_to_letter';
import percentage from './percentage';
import platformString from './platform_string';
import plural from './plural';
import relativeDate from './relative_date';
import results2Unescape from './results2_unescape';
import sanitizeHtml from './sanitize_html';
import shortdate from './shortdate';
import slice from './slice';
import trafficAllocationPercentage from './traffic_allocation_percentage';
import translate from './translate';
import truncate from './truncate';
import wrapTag from './wrap_tag';

export {
  activityString,
  capitalize,
  filterProp,
  formatCurrency,
  formatDate,
  formatDatetime,
  formatDescription,
  formatFileSize,
  formatNumber,
  formatTime,
  formatPhoneNumber,
  fulldate,
  insertLocation,
  join,
  listToReadableString,
  numberToLetter,
  percentage,
  platformString,
  plural,
  relativeDate,
  results2Unescape,
  sanitizeHtml,
  shortdate,
  slice,
  trafficAllocationPercentage,
  translate,
  truncate,
  wrapTag,
};

export default {
  activityString,
  capitalize,
  filterProp,
  formatCurrency,
  formatDate,
  formatDatetime,
  formatDescription,
  formatFileSize,
  formatNumber,
  formatTime,
  formatPhoneNumber,
  fulldate,
  insertLocation,
  join,
  listToReadableString,
  numberToLetter,
  percentage,
  platformString,
  plural,
  relativeDate,
  results2Unescape,
  sanitizeHtml,
  shortdate,
  slice,
  trafficAllocationPercentage,
  translate,
  truncate,
  wrapTag,
};

import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

export const {
  save,
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deleteSupportInfo = baseEntityActions.delete;

export { deleteSupportInfo as delete };

export default {
  ...baseEntityActions,
};

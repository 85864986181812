import SentryActions from 'optly/modules/sentry/actions';

/**
 * Wrapper around Sentry SDK API that allows for test stubbing.
 */

export const { withScope, captureException } = SentryActions;

export default {
  withScope,
  captureException,
};

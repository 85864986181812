import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonRow, Dialog, Table, Icon } from 'optimizely-oui';
import {
  redBase,
  greenBase,
} from '@optimizely/design-tokens/dist/json/colors.json';

import ui from 'core/ui';
import router from 'core/router';

const { TH, TD, TR, THead, TBody } = Table;
const { Footer, Fieldset, Title, Wrapper } = Dialog;

export default class DuplicateLayerSuccess extends React.Component {
  static propTypes = {
    selectedProjectName: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    successMessage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    wasDuplicationCrossProject: PropTypes.bool.isRequired,
  };

  hasExistingEntities() {
    const { settings } = this.props;
    return (
      !!(
        settings.audience_maps &&
        settings.audience_maps.find(audienceMap => !audienceMap.is_new)
      ) ||
      !!(
        settings.event_maps &&
        settings.event_maps.find(eventMap => !eventMap.is_new)
      ) ||
      !!(
        settings.view_maps &&
        settings.view_maps.find(viewMap => !viewMap.is_new)
      )
    );
  }

  hasNewEntities() {
    const { settings } = this.props;
    return (
      !!(
        settings.audience_maps &&
        settings.audience_maps.find(audienceMap => audienceMap.is_new)
      ) ||
      !!(
        settings.event_maps &&
        settings.event_maps.find(eventMap => eventMap.is_new)
      ) ||
      !!(
        settings.view_maps && settings.view_maps.find(viewMap => viewMap.is_new)
      )
    );
  }

  componentWillUnmount() {
    // Do a full page reload in order to ensure that all entities created
    // from duplication can be fetched into the entity cache
    // See: https://optimizely.atlassian.net/browse/WEB-825
    router.windowLocationReload();
  }

  getNewSettings() {
    const { settings } = this.props;
    return (
      <React.Fragment>
        {settings.audience_maps &&
          settings.audience_maps.map(audienceMap => {
            if (audienceMap.is_new) {
              return (
                <TR testSection="duplicate-layer-new-audience">
                  <TD>
                    <div className="text--right width--50">
                      <Icon color={greenBase} name="check" size="small" />
                    </div>
                  </TD>
                  <TD width="80%">{audienceMap.new_name}</TD>
                  <TD>
                    <div className="text--right micro muted">Audience</div>
                  </TD>
                </TR>
              );
            }
          })}
        {settings.event_maps &&
          settings.event_maps.map(eventMap => {
            if (eventMap.is_new) {
              return (
                <TR testSection="duplicate-layer-new-event">
                  <TD>
                    <div className="text--right width--50">
                      <Icon color={greenBase} name="check" size="small" />
                    </div>
                  </TD>
                  <TD width="80%">{eventMap.new_name}</TD>
                  <TD>
                    <div className="text--right micro muted">Event</div>
                  </TD>
                </TR>
              );
            }
          })}
        {settings.view_maps &&
          settings.view_maps.map(viewMap => {
            if (viewMap.is_new) {
              return (
                <TR testSection="duplicate-layer-new-view">
                  <TD>
                    <div className="text--right width--50">
                      <Icon color={greenBase} name="check" size="small" />
                    </div>
                  </TD>
                  <TD width="80%">{viewMap.new_name}</TD>
                  <TD>
                    <div className="text--right micro muted">Page</div>
                  </TD>
                </TR>
              );
            }
          })}
      </React.Fragment>
    );
  }

  getOldSettings() {
    const { settings, selectedProjectName } = this.props;
    return (
      <React.Fragment>
        {settings.audience_maps &&
          settings.audience_maps.map(audienceMap => {
            if (!audienceMap.is_new) {
              return (
                <TR testSection="duplicate-layer-old-audience">
                  <TD>
                    <div className="text--right width--50">
                      <Icon color={redBase} name="xmark" size="small" />
                    </div>
                  </TD>
                  <TD width="80%">
                    {audienceMap.old_name}
                    <p className="micro">
                      {audienceMap.old_name} matches {audienceMap.new_name}, an
                      audience in {selectedProjectName}
                    </p>
                  </TD>
                  <TD>
                    <div className="text--right micro muted">Audience</div>
                  </TD>
                </TR>
              );
            }
          })}
        {settings.event_maps &&
          settings.event_maps.map(eventMap => {
            if (!eventMap.is_new) {
              return (
                <TR testSection="duplicate-layer-old-event">
                  <TD>
                    <div className="text--right width--50">
                      <Icon color={redBase} name="xmark" size="small" />
                    </div>
                  </TD>
                  <TD width="80%">
                    {eventMap.old_name}
                    <p className="micro">
                      {eventMap.old_name} matches {eventMap.new_name}, an event
                      in {selectedProjectName}
                    </p>
                  </TD>
                  <TD>
                    <div className="text--right micro muted">Event</div>
                  </TD>
                </TR>
              );
            }
          })}
        {settings.view_maps &&
          settings.view_maps.map(viewMap => {
            if (!viewMap.is_new) {
              return (
                <TR testSection="duplicate-layer-old-view">
                  <TD>
                    <div className="text--right width--50">
                      <Icon color={redBase} name="xmark" size="small" />
                    </div>
                  </TD>
                  <TD width="80%">
                    {viewMap.old_name}
                    <p className="micro">
                      {viewMap.old_name} matches {viewMap.new_name}, a page in{' '}
                      {selectedProjectName}
                    </p>
                  </TD>
                  <TD>
                    <div className="text--right micro muted">Page</div>
                  </TD>
                </TR>
              );
            }
          })}
      </React.Fragment>
    );
  }

  render() {
    const {
      title,
      successMessage,
      selectedProjectName,
      wasDuplicationCrossProject,
    } = this.props;
    return (
      <div className="flex--1 position--relative">
        <Wrapper testSection="layer-duplicate-success">
          <Title>{title}</Title>
          <Fieldset testSection="duplicate-layer-data-container">
            <div className="delta push--bottom">Summary</div>
            {this.hasNewEntities() && (
              <div className="push-double--bottom">
                <Table
                  style="rule-no-bottom-border"
                  tableLayoutAlgorithm="auto">
                  <THead>
                    <TR borderStyle="top">
                      <TH
                        colSpan={2}
                        testSection="duplicate-layer-destination-project">
                        Duplicated to {selectedProjectName}
                      </TH>
                      <TH />
                    </TR>
                  </THead>
                  <TBody>{this.getNewSettings()}</TBody>
                </Table>
              </div>
            )}
            {wasDuplicationCrossProject && this.hasExistingEntities() && (
              <div className="push-double--bottom">
                <Table
                  style="rule-no-bottom-border"
                  tableLayoutAlgorithm="auto">
                  <THead>
                    <TR borderStyle="top">
                      <TH
                        colSpan={2}
                        testSection="duplicate-layer-already-exists-destination-project">
                        Already existed in {selectedProjectName}
                      </TH>
                      <TH />
                    </TR>
                  </THead>
                  <TBody>{this.getOldSettings()}</TBody>
                </Table>
              </div>
            )}
            <p>{successMessage}</p>
          </Fieldset>
          <Footer>
            <ButtonRow
              rightGroup={[
                <Button
                  key="success"
                  style="highlight"
                  onClick={ui.hideDialog}
                  testSection="duplicate-layer-success-done-button">
                  Done
                </Button>,
              ]}
            />
          </Footer>
        </Wrapper>
      </div>
    );
  }
}

import { getFeatureVariableInteger } from '@optimizely/js-sdk-lab/src/actions';

import Router from 'core/router';
import flux from 'core/flux';

// utilities components
import RoutingHelpers from 'bundles/p13n/routing_helpers';

// actions, constants, enums, fns, getters (single imports)
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import EnvironmentActions from 'optly/modules/entity/environment/actions';
import ExperimentationGroupActions from 'optly/modules/entity/experimentation_group/actions';
import LayerExperimentActions from 'optly/modules/entity/layer_experiment/actions';
import SectionModuleActions from 'bundles/p13n/sections/manager_fullstack/section_module/actions';

import {
  DEFAULT_MAX_API_FILTERS,
  EntityType,
} from 'bundles/p13n/components/change_history';

/**
 * This function
 * @param experimentId
 * @return {Promise<jQuery.deferred[] | never>}
 */
export const fetchRequiredDataForFullStackManager = experimentId => {
  const currentProjectId = flux.evaluate(CurrentProjectGetters.id);
  const byProject = {
    project_id: currentProjectId,
  };

  const promises = [
    LayerExperimentActions.fetch(experimentId).then(experiment => {
      SectionModuleActions.setCurrentlyEditingExperimentId(experiment.id);
      return RoutingHelpers.fetchLayerAndSetAsCurrent(experiment.layer_id).then(
        () => experiment,
      );
    }),
  ];
  const environmentsAuthorizedAndSupported = flux.evaluate(
    CurrentProjectGetters.environmentsAuthorizedAndSupported,
  );
  if (environmentsAuthorizedAndSupported) {
    promises.push(EnvironmentActions.fetchAll(byProject));
  }
  return Promise.all(promises).then(
    ([experiment]) => experiment,
    () => Router.windowNavigate('/v2/'),
  );
};

export function fetchRequiredDataForManagerChangeHistory(ctx, next) {
  const entityId = parseInt(ctx.params.experiment_id, 10);
  const projectId = parseInt(ctx.params.proj_id, 10);

  // Change History will truncates fitlers to this number if exceeded so no need to fetch more
  const fetchLimit =
    getFeatureVariableInteger(
      'change_history_improvements',
      'max_api_filters',
    ) || DEFAULT_MAX_API_FILTERS;

  Promise.all([
    LayerExperimentActions.fetch(entityId),
    ExperimentationGroupActions.fetchPage({
      $limit: fetchLimit,
      project_id: projectId,
    }),
  ]).then(([layerExperiment, groups]) => {
    // set ctx.fetchedData for downstream route use (where component is rendered)
    ctx.fetchedData = {
      [EntityType.experiment.entityType]: [].concat(layerExperiment),
      [EntityType.group.entityType]: [].concat(groups),
    };
    next();
  });
}

export default {
  fetchRequiredDataForFullStackManager,
  fetchRequiredDataForManagerChangeHistory,
};

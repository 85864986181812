/**
 * NOTE(nate): event tracking in this module does not send data to marketo, per marketing team
 */
import $ from 'jquery';
import _ from 'lodash';

import flux from 'core/flux';
import tr from 'optly/translate';

import { actions as AudienceActions } from 'optly/modules/entity/audience';
import { actions as VariationActions } from 'optly/modules/entity/variation';

import { actions as GoalActions } from 'optly/modules/entity/goal';
import { actions as ScheduleActions } from 'optly/modules/entity/schedule';
import RestApi from 'optly/modules/rest_api';

// TODO(nuclear): refactor to modules
import events from 'optly/services/events';
import backendApi from 'optly/services/backend_api';

import definition from './entity_definition';
import enums from './enums';
import actionTypes from './action_types';

const baseEntityActions = RestApi.createEntityActions(definition);

const REPER_BATCH_EXPERIMENT_MAX = 50;

export const { fetchAll } = baseEntityActions;

// TODO: convert all ajax calls to fetch in this file and remove eslint disable
/* eslint-disable fetch/no-jquery */

/**
 * Starts an experiment
 * @param {Experiment} experiment
 * @return {Deferred}
 */
export function start(experiment) {
  // MONEY-1055 - Free trial marketo events
  events.trackMarketoEvent(
    'experiments_tab',
    'start',
    'experiment',
    experiment.id,
  );
  events.track('dashboard2', 'start-experiment');
  return this.save({
    id: experiment.id,
    status: 'Running',
  });
}

/**
 * Pauses an experiment
 * @param {Experiment} experiment
 * @return {Deferred}
 */
export function pause(experiment) {
  events.track('dashboard2', 'pause-experiment');
  return this.save({
    id: experiment.id,
    status: 'Paused',
  });
}

/**
 * Duplicates an experiment to a different project
 * @param {Experiment} experiment
 * @param {number} projectId to duplicate to
 * @return {Deferred}
 */
export function duplicate(experiment, projectId) {
  events.track('dashboard2', 'duplicate-experiment');
  const data = {
    experiment_id: experiment.id,
    new_project_id: projectId,
  };

  return $.ajax('/experiment/duplicate', {
    data,
    type: 'POST',
  }).then(newExperiment => {
    this.fetch(newExperiment.experiment_id);

    // refresh goals to get up-to-date experiment_ids
    GoalActions.fetchAll(
      {
        project_id: projectId,
      },
      true,
    );

    const hasNewAudiences = _.some(
      newExperiment.destination_audiences,
      'is_new',
    );
    if (hasNewAudiences) {
      AudienceActions.fetchAll(
        {
          project_id: projectId,
        },
        true,
      );
    }
    return newExperiment;
  });
}

/**
 * Archives an experiment
 * @param {Experiment} experiment
 * @return {Deferred}
 */
export function archive(experiment) {
  events.track('dashboard2', 'archive-experiment');
  ScheduleActions.flush();

  return this.save({
    id: experiment.id,
    status: 'Archived',
  });
}

/**
 * Unarchives an experiment
 * @param {Experiment} experiment
 * @return {Deferred}
 */
export function unarchive(experiment) {
  const status = experiment.earliest ? 'Paused' : 'Not started';

  return this.save({
    id: experiment.id,
    status,
  });
}

/**
 * Deletes an experiment and flushes associated caches
 * @param {Experiment} experiment
 * @param {Number} projectId - Necessary for the fetchAll call for goals
 * @return {Deferred}
 */

export function deleteExperiment(experiment) {
  return baseEntityActions.delete(experiment).then(() => {
    GoalActions.flush();
    ScheduleActions.flush();
    GoalActions.fetchAll({ project_id: experiment.project_id });
  });
}

/**
 * Updates a variation's weight, it does not automatically
 * sync to the server
 * @param {number} variationId
 * @param {number} weight
 * @return {Deferred}
 */
export function updateVariationWeight(variationId, weight) {
  return VariationActions.save({
    id: variationId,
    weight,
  });
}

/**
 * Fetch the visitor counts directly from the backend API
 * @param {array<Experiment>} experiments
 * @return {Deferred}
 */
export function fetchVisitorCounts(experiments) {
  const experimentStatusesToFetch = [
    enums.status_type.PAUSED,
    enums.status_type.RUNNING,
  ];
  const experimentsToFetch = experiments
    .filter(exp => experimentStatusesToFetch.includes(exp.status))
    .map(exp => ({
      ...exp,
      // the backend api requires a certain format for start times
      earliest: tr.date(exp.earliest).toISOString(),
    }));
  // makeVisitorRequests can make multiple requests to different backends (mongo vs NBE)
  // thus it returns an array of deferreds
  const deferreds = backendApi.create().makeVisitorRequests(experimentsToFetch);
  deferreds.forEach(def => {
    def.then(response => {
      // response.experiments looks like:
      // [{"cache_time":"2014-11-18T22:31:14Z","by_variation":{"1019930313":5005,"1019930312":5006},"id":999820904,"visitor_count":10011}, ...]
      flux.dispatch(actionTypes.EXPERIMENT_VISITOR_COUNT_FETCH_SUCCESS, {
        results: response.experiments,
        // this is not the cached timestamp, but the timestamp of when the API request was made
        timestamp: Date.now(),
      });
    });
  });

  return Promise.all(deferreds);
}

/**
 *
 * Fetches the results for an experiment by making a request
 * to the Reper backend endpoint.
 * @param {array<Experiment>} experiments
 * @return {Deferred}
 */
export function fetchResults(experiments) {
  const baseReperUrl = 'https://reper.dz.optimizely.com';
  const batches = [];
  const experimentStatusesToFetch = [
    enums.status_type.PAUSED,
    enums.status_type.RUNNING,
  ];

  const experimentsToFetch = experiments.filter(exp =>
    experimentStatusesToFetch.includes(exp.status),
  );

  while (experimentsToFetch.length > 0) {
    batches.push(experimentsToFetch.splice(0, REPER_BATCH_EXPERIMENT_MAX));
  }

  function createJSON(experimentsToJSONify) {
    const requestSet = {};
    Object.values(experimentsToJSONify).forEach(experiment => {
      const urlStr = `${baseReperUrl}/v1/experiments/${experiment.id}/results`;
      requestSet[experiment.id] = {
        method: 'GET',
        headers: {
          'X-share-token': experiment.share_token,
        },
        url: urlStr,
      };
    });
    return requestSet;
  }

  batches.forEach(batch => {
    window.console.log(createJSON(batch));
    const data = JSON.stringify(createJSON(batch));
    $.ajax({
      data,
      url: `${baseReperUrl}/batch`,
      type: 'POST',
      contentType: 'application/json',
    }).then(results => {
      window.console.log(results);
      flux.dispatch(actionTypes.EXPERIMENT_VISITOR_COUNT_FETCH_SUCCESS_BATCH, {
        timestamp: Date.now(),
        results,
      });
    });
  });
}

/**
 * Load all Google Universal Analytics slots that are in use by all active
 * experiments.
 * @param  {number} project_id
 * @return {Promise}
 */
export function listUniversalAnalyticsSlotsInUse(project_id) {
  // TODO (HALEY): Replace mobile_slots with _slots (line below) once web and mobile are merged
  // $.ajax("project/list_universal_analytics_slots?project_id=" + project_id)
  return $.ajax(
    `/project/list_universal_analytics_mobile_slots?project_id=${project_id}`,
  ).then(data => data.slots_map);
}

/**
 * Creates a new iOS experiment
 *
 * @param name Name of the experiment
 * @param project_id Id of the experiment's parent project
 * @param type ANDROID or IOS Experiment types
 * @returns {Deferred}
 */
export function createMobileExperiment(name, project_id, type) {
  return $.ajax({
    type: 'POST',
    url: '/experiment/create',
    // Clear error so caller can handle the error instead of relying on the error handler in bundle/page.js
    error() {},
    data: {
      experiment_title: name,
      project_id,
      experiment_type: 'ab',
      percentage_included: 10000,
      platform: type,
      sections: JSON.stringify([
        {
          variations: [
            {
              id: null,
              title: tr('Original'),
              is_paused: false,
              weight: 5000,
              code: '',
              variation_platforms: [
                {
                  id: null,
                  assets: {},
                  views: {},
                  variables: {},
                  type,
                },
              ],
            },
            {
              id: null,
              title: tr('Variation #1'),
              is_paused: false,
              weight: 5000,
              code: '',
              variation_platforms: [
                {
                  id: null,
                  assets: '{}',
                  views: '{}',
                  variables: '{}',
                  type,
                },
              ],
            },
          ],
        },
      ]),
    },
  });
}

export default {
  ...baseEntityActions,
  start,
  pause,
  duplicate,
  archive,
  unarchive,
  deleteExperiment,
  updateVariationWeight,
  fetchVisitorCounts,
  fetchResults,
  listUniversalAnalyticsSlotsInUse,
  createMobileExperiment,
};

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Attention, Input, Table, Icon } from 'optimizely-oui';

import Immutable from 'optly/immutable';

import { fns as ExperimentSectionFns } from 'optly/modules/entity/experiment_section';

import MVTSectionVariationRow from '../mvt_section_variation_row';

class MVTSectionRow extends React.Component {
  constructor(props) {
    super(props);

    this.onUpdateSectionName = this.onUpdateSectionName.bind(this);
  }

  onUpdateSectionName(sectionGuid, e) {
    const name = e.target.value;
    this.props.onUpdateSectionName(sectionGuid, name);
  }

  render() {
    const {
      section,
      sectionIndex,
      sectionCount,
      onRemoveSection,
      onAddVariationToSection,
      onRemoveVariationFromSection,
      onUpdateVariationInSection,
    } = this.props;

    const totalPercentage = ExperimentSectionFns.getTotalPercentageForSection(
      section,
    );

    const hasNegativeVariationWeight = !!section
      .get('variations')
      .find(variation => Number(variation.get('weight')) < 0);

    const variationCount = section.get('variations').size;

    return (
      <div
        className="border--all soft-double push-double--top"
        data-test-section={`mvt-section-row-${sectionIndex}`}>
        <div className="section-header flex flex-justified--between flex-align--center push-double--bottom">
          <Input
            testSection={`mvt-section-name-input-${sectionIndex}`}
            type="text"
            label="Section Name"
            isRequired={true}
            value={section.get('name')}
            onChange={_.partial(this.onUpdateSectionName, section.get('guid'))}
          />
          {sectionCount > 1 && (
            <div
              onClick={_.partial(onRemoveSection, section.get('guid'))}
              data-test-section="mvt-section-remove-section"
              className="flex soft--left">
              <Icon
                name="xmark"
                size="small"
                className="oui-icon icon-target"
              />
            </div>
          )}
        </div>
        <Table tableLayoutAlgorithm="fixed">
          <Table.THead>
            <Table.TR>
              <Table.TH>
                Variation Name
                <span className="oui-label display--inline oui-label--required" />
              </Table.TH>
              <Table.TH width="30%">Traffic Allocation</Table.TH>
            </Table.TR>
          </Table.THead>
          <Table.TBody>
            {section.get('variations').map((variation, variationIndex) => (
              <MVTSectionVariationRow
                sectionIndex={sectionIndex}
                variationIndex={variationIndex}
                key={variation.get('guid')}
                variationCount={variationCount}
                name={variation.get('name')}
                percentage={variation.get('weight') / 100}
                guid={variation.get('guid')}
                onRemoveVariationFromSection={_.partial(
                  onRemoveVariationFromSection,
                  section.get('guid'),
                )}
                onUpdateVariationInSection={_.partial(
                  onUpdateVariationInSection,
                  section.get('guid'),
                )}
              />
            ))}
            <Table.TR>
              <Table.TD colSpan={2}>
                <a
                  onClick={_.partial(
                    onAddVariationToSection,
                    section.get('guid'),
                  )}
                  data-test-section="mvt-section-add-variation">
                  Add Variation...
                </a>
                {(totalPercentage !== 100 || hasNegativeVariationWeight) && (
                  <div className="push-double--top">
                    <Attention alignment="left" type="bad-news">
                      {totalPercentage !== 100 && (
                        <div data-test-section="mvt-section-error-not-100">
                          The traffic distribution total must equal 100%.
                          Current total is {totalPercentage}%.
                        </div>
                      )}
                      {hasNegativeVariationWeight && (
                        <div data-test-section="mvt-section-error-negative-value">
                          Traffic distribution values cannot be negative.
                        </div>
                      )}
                    </Attention>
                  </div>
                )}
              </Table.TD>
            </Table.TR>
          </Table.TBody>
        </Table>
      </div>
    );
  }
}

MVTSectionRow.propTypes = {
  section: PropTypes.instanceOf(Immutable.Map).isRequired,
  sectionCount: PropTypes.number,
  sectionIndex: PropTypes.number.isRequired,
  onRemoveSection: PropTypes.func.isRequired,
  onUpdateSectionName: PropTypes.func.isRequired,
  onAddVariationToSection: PropTypes.func.isRequired,
  onRemoveVariationFromSection: PropTypes.func.isRequired,
  onUpdateVariationInSection: PropTypes.func.isRequired,
};

export default MVTSectionRow;

import $ from 'jquery';
import tabService from 'optly/services/tab';
/**
 * Tab directive to handle finding relationship between tabs & content and
 * activating nav/content elements.
 *
 * Relationships can be made via data attribute('data-tab-related')
 * or by the order of the nav element and it's assoicated tab content block.
 *
 * Example of data attribute relationship
 *
 * <div v-tabs="tabsContainer2">
 *   <ul>
 *     <li class="is-active" data-tab-related="two">Tab Two</li>
 *     <li data-tab-related="one">Tab One</li>
 *     <li data-tab-related="three">Tab Three</li>
 *   </ul>
 * </div>
 *
 *  <div class="lego-tab-content" id="tabsContainer2">
 *    <div class="is-active" data-tab-related="one">One</div>
 *    <div data-tab-related="three">Three</div>
 *    <div data-tab-related="two">Two</div>
 *  </div>
 *
 * Example of order based relationship
 *
 *   <div v-tabs="tabsContainer">
 *    <ul>
 *      <li class="is-active">Tab One</li>
 *      <li>Tab Two</li>
 *      <li>Tab Three</li>
 *    </ul>
 *  </div>
 *
 *  <div id="tabsContainer">
 *    <div class="is-active">One</div>
 *    <div>Two</div>
 *    <div>Three</div>
 *  </div>
 *
 *  if the tabs needs to contain nested li's, we can use a data-tab-selector data selector ("data-tabs-li")
 *  as an attribute on the parent tab elements
 *  Example:
 *   <div v-tabs="tabsContainer" data-tab-selector="data-tab-li">
 *    <ul>
 *      <li class="is-active">
 *          data-tab-li="uniqueName">
 *          <div class= "wrapper">
 *            <div>Tab One </div>
 *            <div>
 *                <ul>
 *                    <li> tab 1 related </li>
 *                </ul>
 *            </div>
 *          </div>
 *      </li>
 *      <li>
 *          data-tab-li="uniqueName">
 *          <div class= "wrapper">
 *            <div>Tab Two </div>
 *            <div>
 *                <ul>
 *                    <li> tab 2 related </li>
 *                </ul>
 *            </div>
 *          </div>
 *      </li>
 *      <li>
 *          data-tab-li="uniqueName">
 *          <div class= "wrapper">
 *            <div>Tab Three </div>
 *            <div>
 *                <ul>
 *                    <li> tab 3 related </li>
 *                </ul>
 *            </div>
 *          </div>
 *      </li>
 *    </ul>
 *  </div>
 *
 *
 *
 * @author Cheston Lee
 */

export default {
  isEmpty: true,
  bind() {
    $(this.el).on('click', e => {
      const $el = $(this.el);

      // If the expression doesn't provide the id, check for the data attribute
      const tabContainer = $(
        `#${this.expression ? this.expression : $el.data('tab-id')}`,
      );
      if (tabContainer.length === 0) {
        return;
      }

      let tab = null;
      const $target = $(e.target);
      let targetElem = null;
      let targetLis = null;
      const dataTabSelector = $el.data('tab-selector');
      const relation = $target.attr(tabService.TAB_RELATION_ATTR);

      if (relation) {
        tab = tabContainer.children(
          `div[${tabService.TAB_RELATION_ATTR}=${relation}]`,
        )[0];
      } else {
        // Determine the target's position in the nav list in order to 'show' the proper tab.
        // if tab selector is specified , choose the parent tab for target element,
        // else choose the tab corresponding to the target
        if (dataTabSelector) {
          targetLis = $el.find(`[${dataTabSelector}]`);
          targetElem = $target.closest(`[${dataTabSelector}]`).get(0);
        } else {
          targetLis = $el.find('li');
          targetElem = e.target;
        }
        const lis = $.makeArray(targetLis);
        const idx = lis.indexOf(targetElem);

        // Bail if we cannot find the list item
        if (idx === -1) {
          return;
        }
        tab = tabContainer.children('div')[idx];
      }
      tabService.activate(targetElem, tab);
    });
  },
};

import track from './track';
import ui from './ui';
import userInfo from './user_info';

/**
 * optlyApp API Utilities
 *
 * IMPORTANT:
 * Read through the documentation before making any changes.
 * Learn more about this module at https://github.com/optimizely/optimizely/blob/devel/src/www/frontend/src/js/optly/optly_app_api/README.md
 *
 * @author Derek Hammond
 */
export default function create() {
  return {
    track,
    ui,
    userInfo,
  };
}

import Cookie from 'js-cookie';

import DesktopAppFns from 'optly/modules/desktop_app/fns';
import UrlUtil from 'optly/utils/url';

const ENVIRONMENT_INFO_HEADERS = {
  BROWSER: 'Browser',
  AGENT: 'Agent',
  LOCATION: 'Location',
  REFERRER: 'Referrer',
};

const SHOW_SUPPORT_FORM_COOKIE = 'qutlh_chenmoh';

const EPI_ZENDESK_URL = {
  DEV: 'https://episerver1624538952.zendesk.com/',
  PROD: 'https://support.optimizely.com',
};

/**
 * generates utm source for tracking
 * @return {string}
 */
export function supportEnvironment() {
  if (__DEV__) {
    return EPI_ZENDESK_URL.DEV;
  }
  return EPI_ZENDESK_URL.PROD;
}

/**
 * returns boolean used to direct users to external support form if the qutlh_chenmoh=false cookie is present
 * AST-791 - delete this when Epi Zendesk migration is complete.
 * @returns {Boolean}
 */
export function directToExternalSupportPortal() {
  return Cookie.get(SHOW_SUPPORT_FORM_COOKIE) === 'false';
}

/**
 * generates the link for My Requests page on the external Support Form
 * with a dynamic utm_source parameter based off the current page
 * @returns {string}
 */
export function myRequestsLink() {
  const myRequestsHelpCenterLink = `${this.supportEnvironment()}/hc/en-us/requests?utm_source=${UrlUtil.generateUtmSourceValue()}&utm_medium=global_nav&utm_content=support_ticket&utm_campaign=education`;
  return DesktopAppFns.isDesktopApp()
    ? myRequestsHelpCenterLink
    : `/sso?service=zendesk&return_to=${encodeURIComponent(
        myRequestsHelpCenterLink,
      )}`;
}

/**
 * Returns URL which directs user to where they can view tickets in Episervers
 * Zendesk instance. ACTUAL URL DETAILS TBD.
 * @returns {string}
 */
export function viewRequestsEpiZendesk() {
  return this.supportEnvironment();
}

/**
 * generates the link for New Requests page on the external Support Form
 * with a dynamic utm_source parameter based off the current page
 * @returns {string}
 */
export function newRequestLink() {
  const newRequestHelpCenterLink = `${this.supportEnvironment()}/hc/en-us/requests/new?utm_source=${UrlUtil.generateUtmSourceValue()}&utm_medium=global_nav&utm_content=support_ticket&utm_campaign=education`;
  return DesktopAppFns.isDesktopApp()
    ? newRequestHelpCenterLink
    : `/sso?service=zendesk&return_to=${encodeURIComponent(
        newRequestHelpCenterLink,
      )}`;
}

/**
 * generates the link for a newly created request's Individual Request page on the external Support Form
 * with a dynamic utm_source parameter based off the current page
 * @param {number} ticket ID of newly created ticket
 * @returns {string}
 */
export function newlyCreatedRequestLink(ticketId) {
  const newlyCreatedRequestHelpCenterLink = `${this.supportEnvironment()}/hc/en-us/requests/${ticketId}?utm_source=${UrlUtil.generateUtmSourceValue()}&utm_medium=global_nav&utm_content=support_ticket&utm_campaign=education`;
  return DesktopAppFns.isDesktopApp()
    ? newlyCreatedRequestHelpCenterLink
    : `/sso?service=zendesk&return_to=${encodeURIComponent(
        newlyCreatedRequestHelpCenterLink,
      )}`;
}

/**
 * returns string of user's browser version, os, and other environment details
 * @returns {String}
 */
export function userEnvironmentInfo() {
  const navigatorUserAgent = navigator.userAgent;
  let fullVersion = `${parseFloat(navigator.appVersion)}`;
  let browserName;
  let ix;

  const stringFinder = substring => navigatorUserAgent.indexOf(substring);

  if (stringFinder('Opera') !== -1) {
    // In Opera, the true version is after "Opera" or after "Version"
    browserName = 'Opera';
    if (stringFinder('Version') !== -1) {
      fullVersion = navigatorUserAgent.substring(stringFinder('Version') + 8);
    } else {
      fullVersion = navigatorUserAgent.substring(stringFinder('Opera') + 6);
    }
  } else if (stringFinder('MSIE') !== -1) {
    // In MSIE, the true version is after "MSIE" in userAgent
    browserName = 'Microsoft Internet Explorer';
    fullVersion = navigatorUserAgent.substring(stringFinder('MSIE') + 5);
  } else if (stringFinder('Chrome') !== -1) {
    // In Chrome, the true version is after "Chrome"
    browserName = 'Chrome';
    fullVersion = navigatorUserAgent.substring(stringFinder('Chrome') + 7);
  } else if (stringFinder('Safari') !== -1) {
    // In Safari, the true version is after "Safari" or after "Version"
    browserName = 'Safari';
    if (stringFinder('Version') !== -1) {
      fullVersion = navigatorUserAgent.substring(stringFinder('Version') + 8);
    } else {
      fullVersion = navigatorUserAgent.substring(stringFinder('Safari') + 7);
    }
  } else if (stringFinder('Firefox') !== -1) {
    // In Firefox, the true version is after "Firefox"
    browserName = 'Firefox';
    fullVersion = navigatorUserAgent.substring(stringFinder('Firefox') + 8);
  } else if (
    navigatorUserAgent.lastIndexOf(' ') + 1 <
    navigatorUserAgent.lastIndexOf('/')
  ) {
    // In most other browsers, "name/version" is at the end of userAgent
    browserName = navigatorUserAgent.substring(
      navigatorUserAgent.lastIndexOf(' ') + 1,
      navigatorUserAgent.lastIndexOf('/'),
    );
    fullVersion = navigatorUserAgent.substring(
      navigatorUserAgent.lastIndexOf('/') + 1,
    );
  }

  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(';')) !== -1) {
    fullVersion = fullVersion.substring(0, ix);
  }

  if ((ix = fullVersion.indexOf(' ')) !== -1) {
    fullVersion = fullVersion.substring(0, ix);
  }

  return (
    `${ENVIRONMENT_INFO_HEADERS.BROWSER}: ${browserName} - ${fullVersion}\n` +
    `${ENVIRONMENT_INFO_HEADERS.AGENT}: ${navigator.userAgent}\n` +
    `${ENVIRONMENT_INFO_HEADERS.LOCATION}: ${window.location.href}\n` +
    `${ENVIRONMENT_INFO_HEADERS.REFERRER}: ${document.referrer || 'direct'}`
  );
}

export default {
  directToExternalSupportPortal,
  myRequestsLink,
  newlyCreatedRequestLink,
  newRequestLink,
  supportEnvironment,
  userEnvironmentInfo,
  viewRequestsEpiZendesk,
};

import ui from 'core/ui';
import flux from 'core/flux';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

import PredictedIntent from './component';

/**
 * Vue Wrapper for the PredictedIntent React component
 */
export default {
  data: {
    /**
     * @var {ConditionGroup}
     */
    conditionGroup: null,
  },

  methods: {
    /**
     * Destroy the codnition group by dispatching an event
     * to parent component
     */
    removeConditionGroup() {
      this.conditionGroup.conditions = [];
      this.$dispatch('conditionRemoved');
    },
  },

  ready() {
    const currentProjectId = flux.evaluate(CurrentProjectGetters.id);
    ui.renderReactComponent(this, {
      component: PredictedIntent,
      el: this.$$.predictedIntentReactComponent,
      props: {
        conditionGroup: this.conditionGroup,
        currentProjectId,
      },
    });
  },
};

/**
 * Fetches user location info from Optimizely Client JS
 * or returns with UNKNOWN_GEO_INFO after MAX_GEO_WAIT
 *
 * Requires 'utils' and 'visitor' from Optimizely Client JS (Snippet)
 * will return with UNKNOWN_GEO_INFO if the snippet is delayed or blocked
 *
 * @returns {jQuery.Deferred} e.g. {city: "LOSANGELES", continent: "NA", country: "US", region: "CA"}
 */
import $ from 'jquery';
import config from 'atomic-config';

import { featureFunction } from '@optimizely/js-sdk-lab/src/decorators';
import { track } from '@optimizely/js-sdk-lab/src/actions';

const EXPECTED_CONTINENTS = ['NA', 'SA', 'EU', 'AF', 'AS', 'OC'];
const GEO_INTERVAL = 50;
const MAX_GEO_WAIT = __TEST__ ? 1000 : 10000;
const UNKNOWN_GEO_INFO = {
  city: 'unknown',
  continent: 'unknown',
  country: 'unknown',
  region: 'unknown',
};
const SDK_USER_ID =
  config.get('account_info.unique_user_id') ||
  config.get('account_info.account_id');
const SDK_OPTIONS = SDK_USER_ID ? { userId: SDK_USER_ID } : {};

// TODO(WEB-2820) Update to use es6 promise and use optly/utils/poll.waitUntil on experiment cleanup
export const getLocationData = featureFunction(
  'geo_location_data_config',
  ['timeout', 'interval'],
  SDK_OPTIONS,
)(function() {
  const utils =
    window.optimizely &&
    window.optimizely.get &&
    window.optimizely.get('utils');
  const geoDef = $.Deferred();
  if (!utils) {
    track('utils_not_available', SDK_OPTIONS);
    geoDef.reject(UNKNOWN_GEO_INFO);
  } else {
    let geoTimeout;
    const cancelPolling = utils.poll(() => {
      const getGeoData =
        window.optimizely.get &&
        window.optimizely.get('visitor') &&
        window.optimizely.get('visitor').location;
      if (getGeoData) {
        cancelPolling();
        clearTimeout(geoTimeout);
        track('geo_available', SDK_OPTIONS);
        if (EXPECTED_CONTINENTS.includes(getGeoData.continent)) {
          track('geo_successful_expected_continent', SDK_OPTIONS);
        }
        geoDef.resolve(getGeoData);
      }
    }, this.interval || GEO_INTERVAL);

    geoTimeout = setTimeout(() => {
      cancelPolling();
      track('geo_timeout', SDK_OPTIONS);
      geoDef.reject(UNKNOWN_GEO_INFO);
    }, this.timeout || MAX_GEO_WAIT);
  }

  return geoDef;
});

export default {
  getLocationData,
};

import fieldTypes from 'optly/modules/rest_api/field_types';

const { STRING, NUMBER, BOOLEAN } = fieldTypes;

export default {
  entity: 'environments',
  parent: {
    entity: 'projects',
    key: 'project_id',
  },
  fieldTypes: {
    name: STRING,
    description: STRING,
    has_restricted_permissions: BOOLEAN,
    priority: NUMBER,
    is_primary: BOOLEAN,
    key: STRING,
    archived: BOOLEAN,
    project_id: NUMBER,
  },
};

import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { actions as EditorIframeActions } from 'bundles/p13n/modules/editor_iframe';

import SelectorLink from './selector_link';

const MAX_HEIGHT_MEDIUM = 200;

export default class SelectContainer extends React.PureComponent {
  static propTypes = {
    /**
     * The iframe component ID to interact with
     */
    activeFrameId: PropTypes.string.isRequired,
    /**
     * Function to apply new selector state to the component consuming selector input
     */
    applyNewSelector: PropTypes.func.isRequired,
    /**
     * The stored value of the selector
     */
    value: PropTypes.string.isRequired,
  };

  static defaultProps = {
    childSelectors: [],
    parentSelectors: [],
  };

  state = {
    childSelectors: [],
    parentSelectors: [],
  };

  applyNewSelector = (event, newSelectorValue) => {
    this.props.applyNewSelector(newSelectorValue);
  };

  fetchRelatedElementSelectors = selector => {
    EditorIframeActions.fetchRelatedElementSelectors(
      this.props.activeFrameId,
      selector,
    ).then(fetchedRelatedElementSelectors => {
      this.setState({
        childSelectors: fetchedRelatedElementSelectors.childSelectors,
        parentSelectors: fetchedRelatedElementSelectors.parentSelectors,
      });
    });
  };

  UNSAFE_componentWillMount() {
    this.fetchRelatedElementSelectors(this.props.value);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If the updated selector value is different than the current, fetch the related element selectors and update the component
    if (!_.isEqual(this.props.value, nextProps.value)) {
      this.fetchRelatedElementSelectors(nextProps.value);
    }
  }

  render() {
    return (
      <div
        className="background--white push-half--top border--all"
        data-test-section="select-container">
        {!!this.state.parentSelectors.length && (
          <div data-test-section="current-and-parent-selectors">
            <div className="border--bottom soft-half background--faint micro border--bottom">
              Parent Elements
            </div>
            <ul
              className="lego-block-list-group no-border scrollbars--visible"
              data-test-section="current-and-parent-selectors-list"
              style={{ maxHeight: MAX_HEIGHT_MEDIUM }}>
              {this.state.parentSelectors.map((selector, index) => (
                <SelectorLink
                  activeFrameId={this.props.activeFrameId}
                  applyNewSelector={this.applyNewSelector}
                  currentlyWorkingSelector={false}
                  key={selector}
                  selector={selector}
                />
              ))}
              <SelectorLink
                activeFrameId={this.props.activeFrameId}
                applyNewSelector={this.applyNewSelector}
                currentlyWorkingSelector={true}
                key={this.props.value}
                selector={this.props.value}
              />
            </ul>
          </div>
        )}
        {!!this.state.childSelectors.length && (
          <div data-test-section="child-selectors">
            <div
              className={classNames({
                'background--faint': true,
                'border--bottom': !this.state.parentSelectors.length,
                'border--ends': this.state.parentSelectors.length,
                micro: true,
                'soft-half': true,
              })}>
              Child Elements
            </div>
            <ul
              className="lego-block-list-group no-border scrollbars--visible"
              data-test-section="child-selectors-list"
              style={{ maxHeight: MAX_HEIGHT_MEDIUM }}>
              {this.state.childSelectors.map((selector, index) => (
                <SelectorLink
                  activeFrameId={this.props.activeFrameId}
                  applyNewSelector={this.applyNewSelector}
                  currentlyWorkingSelector={false}
                  key={selector}
                  selector={selector}
                />
              ))}
            </ul>
          </div>
        )}
        {!this.state.parentSelectors.length &&
          !this.state.childSelectors.length && (
            <div data-test-section="related-info-not-available">
              <div className="border--bottom soft--sides soft-half--ends background--faint micro">
                Select Container
              </div>
              <p className="flush soft--sides soft-half--ends muted">
                Related element info is not available.
              </p>
            </div>
          )}
      </div>
    );
  }
}

/**
 * Desktop App Module Enums
 */
import keyMirror from 'optly/utils/key_mirror';

export default {
  EVENT_TYPES: {
    DID_FAIL_LOAD: 'did-fail-load',
    DID_NAVIGATE: 'did-navigate',
    DID_START_LOADING: 'did-start-loading',
    DOM_READY: 'dom-ready',
    IPC_MESSAGE: 'ipc-message',
  },

  MESSENGER_NAMES: keyMirror({
    DESKTOP_INNIE: null,
    DESKTOP_OUTIE: null,
  }),
};

import React from 'react';
import PropTypes from 'prop-types';
import { Label, Table } from 'optimizely-oui';
import { OptimizelyFeature } from '@optimizely/react-sdk';

import Immutable from 'optly/immutable';
import ExperimentStatus from 'bundles/p13n/components/layer_experiment_status';

export default class ExperimentsTable extends React.Component {
  static propTypes = {
    experimentsUsingFeature: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  render() {
    if (!this.props.experimentsUsingFeature.size) {
      return null;
    }

    return (
      <div className="lego-grid">
        <div className="soft-double--left width--1-1 push-quad--top">
          <h3>
            <Label>
              <span>Experiments</span>
            </Label>
          </h3>
          <p>
            {tr.pluralize(
              'This feature is currently in 1 experiment.',
              'This feature is currently in {0} experiments.',
              this.props.experimentsUsingFeature.size,
            )}
          </p>
          <Table style="rule" tableLayoutAlgorithm="auto">
            <Table.THead>
              <Table.TR>
                <Table.TH>Experiment</Table.TH>
                <Table.TH isCollapsed={true}>Status</Table.TH>
              </Table.TR>
            </Table.THead>
            <Table.TBody>
              {this.props.experimentsUsingFeature.map((experiment, index) => (
                <Table.TR key={experiment.get('id')}>
                  <Table.TD
                    testSection={`experiment-key-${experiment.get('id')}`}>
                    <OptimizelyFeature feature="user_friendly_names">
                      {isEnabled =>
                        isEnabled ? (
                          <>
                            <div
                              className="word-break--all"
                              data-test-section="feature-experiment-name">
                              {experiment.get('name')}
                            </div>
                            <div
                              className="zeta monospace muted micro color--base word-break--all"
                              data-test-section="feature-experiment-key"
                              style={{ marginTop: '-1px' }}>
                              {experiment.get('key')}
                            </div>
                          </>
                        ) : (
                          <code>{experiment.get('key')}</code>
                        )
                      }
                    </OptimizelyFeature>
                  </Table.TD>
                  <Table.TD
                    testSection={`experiment-status-${experiment.get('id')}`}>
                    <ExperimentStatus experiment={experiment} />
                  </Table.TD>
                </Table.TR>
              ))}
            </Table.TBody>
          </Table>
        </div>
      </div>
    );
  }
}

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import capitalize from 'optly/filters/capitalize';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import {
  BlockList,
  Button,
  Dropdown,
  Icon,
  Input,
  Pill,
} from 'optimizely-oui';

import { project_types as projectTypes } from 'optly/modules/entity/project/enums';
import UpgradeHooks from 'optly/hooks/upgrade';
import { constants as UpgradeConstants } from 'optly/modules/upgrade';

import DateFilter from './date_filter';

import {
  UiEntityType,
  UiFilterType,
  UiStyles,
} from '../component_module/constants';
import {
  computeEntityTypeDropdownSelectedValue,
  computeSearchIdsArray,
  computeUpdatedTypeList,
} from '../component_module/fns';

const Header = ({
  changeIdToNameMap,
  currentProjectCreatedDate,
  currentProjectType,
  customEntityTypes,
  earliestAllowedFilterDate,
  filters,
  handleFiltersAction,
  isChangeHistoryIdSearchEnabled,
  isFlagsProject,
  isDisabled,
  uiStyle,
}) => {
  const [entityString, setEntityString] = useState(
    (filters.entity || []).join(' '),
  );

  const { hasLimit, UpgradeComponent } = UpgradeHooks.useUpgradeInfo(
    UpgradeConstants.FeatureLimitTypes.CHANGE_HISTORY_DAYS,
    UpgradeConstants.FeatureLimitUIs.CHANGE_HISTORY_DATE_PICKER,
  );

  const selectedTypes = filters.type;

  const deduplicatedLogIds = [...new Set(filters.id)];
  const isFilteredByLogId = !!deduplicatedLogIds.length;

  // Don't show the log tokens row unless there are log IDs filters
  // and until either there are items in changeIdToNameMap OR the header is enabled
  const shouldShowLogTokens =
    isFilteredByLogId &&
    (!isDisabled || !!Object.keys(changeIdToNameMap).length);
  const shouldShowSearchFilter =
    !isFilteredByLogId &&
    isChangeHistoryIdSearchEnabled &&
    UiFilterType.SEARCH.projectTypes.includes(currentProjectType);
  const shouldShowTypeFilter =
    !isFilteredByLogId &&
    UiFilterType.TYPE.projectTypes.includes(currentProjectType);
  const shouldShowDateFilter =
    !isFilteredByLogId &&
    UiFilterType.DATE_RANGE.projectTypes.includes(currentProjectType);
  const shouldShowSourceFilter =
    !isFilteredByLogId &&
    UiFilterType.SOURCE.projectTypes.includes(currentProjectType);

  const sources = UiFilterType.SOURCE.values;
  const memoizedOnTokenDismiss = useCallback(
    tokenId => () =>
      handleFiltersAction({
        [UiFilterType.LOG_ID.filterName]: deduplicatedLogIds.filter(
          id => tokenId !== id,
        ),
      }),
    [deduplicatedLogIds],
  );

  const filterFlagsDropdown = useCallback(
    item => {
      if (currentProjectType !== projectTypes.FULL_STACK) {
        return true;
      }
      if (isFlagsProject) {
        return !item.isHiddenOnFlags;
      }
      return true;
    },
    [isFlagsProject, currentProjectType],
  );
  return (
    <div
      className={classNames({
        'flex push-double--ends push-triple--sides':
          uiStyle === UiStyles.PROJECT,
        toolbar__content: uiStyle === UiStyles.MANAGER,
        'soft-triple--left': uiStyle === UiStyles.MANAGER,
      })}
      data-test-section="change-history-table-header">
      {shouldShowLogTokens && (
        <div
          className="flex flex-wrap"
          data-test-section="change-history-log-tokens"
          // TODO(UXENG-58) OUI exception to correct left align
          style={{ marginLeft: -3 }}>
          {deduplicatedLogIds.map(logId => (
            <div className="push-half--right" key={logId}>
              <Pill
                description={`Change ID: ${logId}`}
                isDismissible={true}
                name={changeIdToNameMap[logId] || 'Change not found'}
                onDismiss={memoizedOnTokenDismiss(logId)}
                showWell={false}
                testSection={`change-history-log-token-${logId}`}
              />
            </div>
          ))}
        </div>
      )}

      {/* TODO(APPX-850) Remove hidden entity:id search and replace with customer-facing, elastic search */}
      {shouldShowSearchFilter && (
        <div
          className={classNames('width--300', 'push--right')}
          data-test-section="change-history-entity-id-filter-wrapper">
          <Input
            value={entityString}
            isDisabled={isDisabled}
            isFilter={true}
            onChange={event => setEntityString(event.target.value)}
            onKeyDown={event => {
              if (event.keyCode !== 13) {
                return;
              }
              handleFiltersAction({
                [UiFilterType.SEARCH.filterName]: computeSearchIdsArray(
                  event.target.value,
                ),
              });
              setEntityString(
                computeSearchIdsArray(event.target.value).join(' '),
              );
            }}
            placeholder={UiFilterType.SEARCH.placeholder}
            testSection="change-history-entity-id-filter"
            type="text"
          />
        </div>
      )}

      {shouldShowTypeFilter && (
        <div className="push--right">
          <Dropdown
            activator={
              <Button
                size="narrow"
                style="plain"
                testSection="change-history-entity-type-button">
                <div className="flex">
                  <div className="line--tight text--left push--right">
                    <div className="micro muted">{UiFilterType.TYPE.label}</div>
                    <div data-test-section="change-history-entity-type-selected-text">
                      {computeEntityTypeDropdownSelectedValue(selectedTypes)}
                    </div>
                  </div>
                  <div>
                    <Icon name="angle-down" size="small" />
                  </div>
                </div>
              </Button>
            }
            isDisabled={isDisabled}
            placement="bottom-start"
            style="plain"
            testSection="change-history-entity-type-filter"
            value={UiFilterType.TYPE.label}
            width={230}>
            <BlockList>
              {Object.values(UiEntityType)
                // Filter out "Reset" option if no type is selected
                .filter(
                  item =>
                    item.projectTypes.includes(currentProjectType) &&
                    filterFlagsDropdown(item) &&
                    (!customEntityTypes.length ||
                      customEntityTypes.includes(item.type)) &&
                    (!Object.prototype.hasOwnProperty.call(
                      item,
                      'useFeature',
                    ) ||
                      isFeatureEnabled(item.useFeature)),
                )
                .map(({ readableName, type }) => (
                  <BlockList.Category key={type}>
                    <BlockList.Item
                      onClick={({ altKey }) =>
                        handleFiltersAction({
                          [UiFilterType.TYPE
                            .filterName]: computeUpdatedTypeList({
                            selectedTypes,
                            type,
                            shouldAppendNonAnyType: altKey,
                          }),
                        })
                      }
                      testSection={`change-history-entity-type-${type}`}>
                      <span
                        className={classNames({
                          'color--base':
                            selectedTypes.includes(type) ||
                            (typeof selectedTypes[0] === 'undefined' &&
                              type === UiEntityType.any.type),
                        })}>
                        {readableName}
                      </span>
                    </BlockList.Item>
                  </BlockList.Category>
                ))}
            </BlockList>
          </Dropdown>
        </div>
      )}

      {!hasLimit && shouldShowDateFilter && (
        <DateFilter
          currentProjectCreatedDate={currentProjectCreatedDate}
          earliestAllowedFilterDate={earliestAllowedFilterDate}
          endTime={filters.end_time}
          handleFiltersAction={handleFiltersAction}
          isDisabled={isDisabled}
          startTime={filters.start_time}
        />
      )}

      {shouldShowSourceFilter && (
        <div className="push--right">
          <Dropdown
            activator={
              <Button
                size="narrow"
                style="plain"
                testSection="change-history-source-button">
                <div className="flex">
                  <div className="line--tight text--left push--right">
                    <div className="micro muted">
                      {UiFilterType.SOURCE.label}
                    </div>
                    <div data-test-section="change-history-source-selected-text">
                      {capitalize(
                        Object.keys(sources).find(
                          key => sources[key] === filters.source,
                        ) || 'Any',
                      )}
                    </div>
                  </div>
                  <div>
                    <Icon name="angle-down" size="small" />
                  </div>
                </div>
              </Button>
            }
            isDisabled={isDisabled}
            placement="bottom-start"
            style="plain"
            testSection="change-history-source-filter"
            value={UiFilterType.SOURCE.label}
            width={230}>
            <BlockList>
              {Object.keys(sources).map(key => (
                <BlockList.Category key={key}>
                  <BlockList.Item
                    onClick={() =>
                      handleFiltersAction({
                        [UiFilterType.SOURCE.filterName]: sources[key],
                      })
                    }
                    testSection={`change-history-source-type-${key}`}>
                    <span>{capitalize(key)}</span>
                  </BlockList.Item>
                </BlockList.Category>
              ))}
            </BlockList>
          </Dropdown>
        </div>
      )}

      {hasLimit && <UpgradeComponent />}
    </div>
  );
};

Header.propTypes = {
  changeIdToNameMap: PropTypes.object.isRequired,
  currentProjectCreatedDate: PropTypes.string.isRequired,
  currentProjectType: PropTypes.oneOf([
    projectTypes.WEB,
    projectTypes.FULL_STACK,
    projectTypes.ROLLOUTS,
  ]).isRequired,
  customEntityTypes: PropTypes.arrayOf(PropTypes.string),
  earliestAllowedFilterDate: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  handleFiltersAction: PropTypes.func.isRequired,
  isChangeHistoryIdSearchEnabled: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  isFlagsProject: PropTypes.bool,
  uiStyle: PropTypes.oneOf([UiStyles.MANAGER, UiStyles.PROJECT]),
};

Header.defaultProps = {
  customEntityTypes: [],
  isDisabled: false,
  uiStyle: UiStyles.PROJECT,
};

export default Header;

import flux from 'core/flux';

import actionTypes from './action_types';

/**
 * Sets the stub for Date.now()
 * @param {Number} now - Date.now() like timestamp
 */
export const stubDateNow = now => {
  flux.dispatch(actionTypes.RESULTS_API_STUB_DATE_NOW, {
    now,
  });
};

export default {
  stubDateNow,
};

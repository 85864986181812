import tr from 'optly/translate';

import * as enums from './enums';

/**
 * Human readable translations of enums and other values
 */

export const REVENUE_METRIC = {
  name: tr('Overall Revenue'),
  description: tr('Overall Revenue'),
};

export const INVALID_HOLDBACK_ERROR = tr(
  'The campaign holdback must be a value between 0 and 99.99.',
);

export const MIN_HOLDBACK_WARNING = tr(
  'This holdback is too small to measure results. We recommend a 5% holdback.',
);

export const STATUS_BY_ACTUAL_STATUS = {
  [enums.entityStatus.ARCHIVED]: tr('Archived'),
  [enums.entityStatus.RUNNING]: tr('Running'),
  [enums.entityStatus.PAUSED]: tr('Paused'),
  [enums.entityStatus.NOT_STARTED]: tr('Not Started'),
};

// Readable name based off the LayerEnums.type, which is set on the Layer and LayerExperiment
export const TEXT_BY_LAYER_TYPE = {
  [enums.type.AB]: {
    name: tr('A/B Test'),
  },
  [enums.type.FEATURE]: {
    name: tr('Feature Test'),
  },
  [enums.type.MULTIVARIATE]: {
    name: tr('Multivariate Test'),
  },
  [enums.type.MULTIARMED_BANDIT]: {
    name: tr('Multi-Armed Bandit'),
  },
  [enums.type.PERSONALIZATION]: {
    name: tr('Personalization Campaign'),
  },
  [enums.type.ROLLOUT]: {
    name: tr('Rollout'),
  },
};

export const TEXT_BY_CAMPAIGN_TYPE = {
  [enums.campaignTypes.SINGLE_EXPERIMENT]: {
    name: tr('A/B Test'),
    duplicate: {
      title: tr('Duplicate Experiment'),
      selectProject: tr('Select a project to duplicate this experiment to.'),
      selectSameProject: tr(
        'This will create a copy of your experiment in the current project.',
      ),
      inProgress: tr('Duplicating experiment...'),
      note: tr(
        'Note: If you are duplicating a paused experiment with no variation changes, you will need to add some variation code in order to start the duplicate experiment.',
      ),
      permissionsError: tr(
        'You do not have the necessary permissions to duplicate this experiment.',
      ),
      successTitle: tr('Experiment Duplicated'),
      successMessage: tr('Your experiment was duplicated successfully.'),
    },
  },
  [enums.campaignTypes.P13N_CAMPAIGN]: {
    name: tr('Personalization Campaign'),
    duplicate: {
      title: tr('Duplicate Campaign'),
      selectProject: tr('Select a project to duplicate this campaign to.'),
      selectSameProject: tr(
        'This will create a copy of your campaign in the current project.',
      ),
      inProgress: tr('Duplicating campaign...'),
      note: tr(
        'Note: If you are duplicating a paused campaign with no variation changes, you will need to add some variation code in order to start the duplicate campaign.',
      ),
      permissionsError: tr(
        'You do not have the necessary permissions to duplicate this campaign.',
      ),
      successTitle: tr('Campaign Duplicated'),
      successMessage: tr('Your campaign was duplicated successfully.'),
    },
  },
  [enums.campaignTypes.MULTIVARIATE]: {
    name: tr('Multivariate Test'),
    duplicate: {
      title: tr('Duplicate Experiment'),
      selectProject: tr('Select a project to duplicate this experiment to.'),
      selectSameProject: tr(
        'This will create a copy of your experiment in the current project.',
      ),
      inProgress: tr('Duplicating experiment...'),
      note: tr(
        'Note: If you are duplicating a paused experiment with no variation changes, you will need to add some variation code in order to start the duplicate experiment.',
      ),
      permissionsError: tr(
        'You do not have the necessary permissions to duplicate this experiment.',
      ),
      successTitle: tr('Experiment Duplicated'),
      successMessage: tr('Your experiment was duplicated successfully.'),
    },
  },
  [enums.campaignTypes.MULTIARMED_BANDIT]: {
    name: tr('Multi-Armed Bandit'),
    duplicate: {
      title: tr('Duplicate Multi-Armed Bandit'),
      selectProject: tr('Select a project to duplicate this experiment to.'),
      selectSameProject: tr(
        'This will create a copy of your bandit in the current project.',
      ),
      inProgress: tr('Duplicating multi-armed bandit...'),
      note: tr(
        'Note: If you are duplicating a paused bandit with no variations changes, you will need to add some variation code in order to start the duplicate bandit.',
      ),
      permissionsError: tr(
        'You do not have the necessary permissions to duplicate this bandit.',
      ),
      successTitle: tr('Multi-Armed Bandit Duplicated'),
      successMessage: tr('Your bandit was duplicated successfully.'),
    },
  },
};

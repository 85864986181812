import Nuclear from 'nuclear-js';

import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

/**
 * Concurrent Editing Store
 */
export default Nuclear.Store({
  getInitialState() {
    /*
     * A map of entities with the following structure:
     *  {
     *    entity_id: [
     *      {
     *        account_id: 1,
     *        user_id: james@optimizely.com,
     *        last_visited: 2022-05-24T17:30:23.828026Z,
     *       },...
     *    ],...
     *  }
     */
    return toImmutable({
      entities: {},
    });
  },

  initialize() {
    this.on(actionTypes.SET_ENTITY_VISITORS_BY_ID, handleSetEntityVisitorsById);
  },
});

/**
 * Handler for actionTypes.SET_ENTITY_VISITORS_BY_ID
 * Sets the current state of the entityId specified.
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 */
function handleSetEntityVisitorsById(state, payload) {
  return state.setIn(['entities', payload.id], toImmutable(payload.visitors));
}

import classnames from 'classnames';
import { Link, Disclose } from 'optimizely-oui';
import PropTypes from 'prop-types';
import React from 'react';

import { toImmutable } from 'optly/immutable';
import { fns as ViewFns } from 'optly/modules/entity/view';

import UrlCondition from 'bundles/p13n/components/targeting/url_config/url_condition';
import ConditionGroup from 'optly/models/condition_group';

const DEFAULT_TEST_URL = '';

class URLValidator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testUrls: props.testUrls,
    };
  }

  testUrlHasMatch = testUrlValue => {
    let finalTestUrlValue = testUrlValue;
    if (this.props.isWebEdgeProject) {
      // Values after the '#' sign are not sent to the Edge Decider (they are removed by the browser)
      // so let's remove it when testing the URL
      finalTestUrlValue = testUrlValue.split('#')[0];
    }

    return (
      !!testUrlValue &&
      ViewFns.smartValidateUrlForConditions(
        finalTestUrlValue,
        this.props.conditionGroup,
      )
    );
  };

  testUrlHasHash = testUrlValue => {
    return testUrlValue.includes('#');
  };

  updateTestUrl = (index, event) => {
    const { testUrlsChange } = this.props;
    const { testUrls } = this.state;
    const updatedUrls = [...testUrls];
    updatedUrls[index] = event.target.value;
    this.setState({
      testUrls: updatedUrls,
    });
    testUrlsChange(updatedUrls);
  };

  /**
   * add an empty (default) row to the specified test url list at the given index
   *
   * @param {[type]} testUrlList
   * @param {[type]} index
   */
  addTestUrlRow = index => {
    const { testUrlsChange } = this.props;
    const { testUrls } = this.state;
    const updatedUrls = [
      ...testUrls.slice(0, index + 1),
      DEFAULT_TEST_URL,
      ...testUrls.slice(index + 1),
    ];
    this.setState({
      testUrls: updatedUrls,
    });
    testUrlsChange(updatedUrls);
  };

  /**
   * remove a row from the specified test url list at the given index
   *
   * @param {[type]} testUrlList
   * @param {[type]} index
   */
  removeTestUrlRow = index => {
    const { testUrlsChange } = this.props;
    const { testUrls } = this.state;
    const updatedUrls = testUrls.filter((url, i) => i !== index);
    this.setState({
      testUrls: updatedUrls,
    });
    testUrlsChange(updatedUrls);
  };

  componentDidUpdate(prevProps) {
    if (this.props.testUrls !== prevProps.testUrls) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        testUrls: this.props.testUrls,
      });
    }
  }

  render() {
    const { edgeLearnMoreLinks, isWebEdgeProject } = this.props;
    const { testUrls } = this.state;

    return (
      <div>
        <Disclose title="Test URL(s)" testSection="test-urls-toggle">
          <div data-test-section="test-url-container">
            <p>
              Check sample URLs to make sure they match the URL pattern(s) you
              specified.
            </p>

            <table
              className="oui-table oui-table--add-row"
              data-test-section="test-urls-table">
              <tbody>
                {testUrls.map((testUrl, index) => {
                  const testUrlHasMatch = this.testUrlHasMatch(testUrl);
                  const testUrlHasHash = this.testUrlHasHash(testUrl);
                  return (
                    <UrlCondition
                      condition={toImmutable({ value: testUrl })}
                      index={index}
                      length={testUrls.length}
                      addConditionRow={this.addTestUrlRow}
                      removeConditionRow={this.removeTestUrlRow}
                      onChangeCondition={this.updateTestUrl}
                      key={index}
                      showMatchType={false}
                      classes={classnames({
                        'lego-form-good-news': testUrlHasMatch,
                        'lego-form-bad-news': !!testUrl && !testUrlHasMatch,
                      })}>
                      <div>
                        {testUrlHasMatch && (
                          <p
                            className="lego-form-note flush--bottom lego-form-note--good-news"
                            data-test-section={`test-url-valid-row-${index}`}>
                            This page will activate on this URL.
                          </p>
                        )}
                        {!!testUrl && !testUrlHasMatch && (
                          <p
                            className="lego-form-note flush--bottom lego-form-note--bad-news"
                            data-test-section={`test-url-invalid-row-${index}`}>
                            This page will not activate on this URL.
                          </p>
                        )}
                        {isWebEdgeProject && testUrlHasHash && (
                          <div
                            className="color--bad-news micro push-half--top"
                            data-test-section={`url-validator-edge-hash-warning-row-${index}`}>
                            Note that browsers do not send characters after the
                            "#" to the Edge Decider.&nbsp;
                            <Link
                              href={edgeLearnMoreLinks.hashWarningLink}
                              newWindow={true}
                              variant="bad-news"
                              testSection={`edge-hash-warning-learn-more-link-row-${index}`}>
                              Learn more
                            </Link>
                            .
                          </div>
                        )}
                      </div>
                    </UrlCondition>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Disclose>
      </div>
    );
  }
}

URLValidator.propTypes = {
  conditionGroup: PropTypes.instanceOf(ConditionGroup).isRequired,
  edgeLearnMoreLinks: PropTypes.object,
  isWebEdgeProject: PropTypes.bool,
  testUrls: PropTypes.object.isRequired,
  testUrlsChange: PropTypes.func.isRequired,
};

URLValidator.defaultProps = {
  edgeLearnMoreLinks: {},
  isWebEdgeProject: false,
};

export default URLValidator;

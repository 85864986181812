const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const actionTypes = require('../action_types');

/**
 * p13NUiStore
 * Responsible for the following state management:
 * P13n application UI state
 */
module.exports = Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      id: null,
      share_token: null,
      outlier_filter: true,
    });
  },

  initialize() {
    this.on(actionTypes.P13N_SET_CURRENT_LAYER_ID, setCurrentLayerId);
    this.on(
      actionTypes.P13N_SET_CURRENT_LAYER_SHARE_TOKEN,
      setCurrentLayerShareToken,
    );
    this.on(
      actionTypes.P13N_SET_CURRENT_LAYER_OUTLIER_FILTER,
      setOutlierFilter,
    );
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 */
function setCurrentLayerId(state, payload) {
  return state.set('id', payload.id);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.share_token
 */
function setCurrentLayerShareToken(state, payload) {
  return state.set('share_token', payload.share_token);
}

/**
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Object} payload.isOutlierFilteringOn
 */
function setOutlierFilter(state, payload) {
  return state.set('outlier_filter', toImmutable(payload.isOutlierFilteringOn));
}

import _ from 'lodash';
import Vue from 'vue';

import flux from 'core/flux';
import ui from 'core/ui';

import {
  ChangeEditorSidebar as ChangeEditorSidebarMixin,
  CodeMirror as CodeMirrorMixin,
} from 'bundles/p13n/modules/ui/mixins';
import AdminAccountGetters from 'optly/modules/admin_account/getters';
import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import DependencyManagement from 'bundles/p13n/components/editor/sidebar/dependency_management';
import Editor from 'bundles/p13n/modules/editor';
import EditorIframeEnums from 'bundles/p13n/modules/editor_iframe/enums';
import EditorIframeGetters from 'bundles/p13n/modules/editor_iframe/getters';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
import PermissionsModuleGetters from 'optly/modules/permissions/getters';
import InsertOperatorDropdown from 'bundles/p13n/components/insert_operator_dropdown';
import SelectorInput from 'bundles/p13n/components/selector_input';
import ResizableDirective from 'optly/directives/resizable';

import ChangeTimingDropdown from '../change_timing_dropdown';
import SidebarHeaderComponent from '../sidebar_header';
import ComponentHtmlTemplate from './template.html';

const InsertHTMLSidebarComponent = {
  replace: true,

  componentId: 'p13n-insert-html-sidebar',

  components: {
    'change-timing-dropdown': ChangeTimingDropdown,
    'sidebar-header': SidebarHeaderComponent,
  },

  computed: {
    /**
     * Should we enable html editing
     * @returns {boolean}
     */
    htmlEditingEnabled() {
      this.isEditingSelector; // eslint-disable-line
      this.isEditorReadOnly; // eslint-disable-line
      return !this.isEditingSelector && !this.isEditorReadOnly;
    },

    statusClassForAttributes() {
      return {
        async: this.getStatusClassForAttribute(this.changeStatusMap.async),
        html: this.getStatusClassForAttribute(this.changeStatusMap.html),
        selectorAndOperator: this.getStatusClassForAttribute([
          this.changeStatusMap.selector,
          this.changeStatusMap.operator,
        ]),
      };
    },
  },

  data: {
    _codeMirrors: {
      html: null,
    },
    changeStatuses: LayerExperimentEnums.ChangeStatuses,
    codeMirrorInitialized: false,
    includeTimingLabel: false,
    timingDropdownTypes: Editor.constants.TimingDropdownTypes,
  },

  directives: {
    resizable: ResizableDirective,
  },

  template: ComponentHtmlTemplate,

  methods: {
    /**
     * Based on the element clicked on, select or create a change.
     *
     * Because this component is not re-instantiated for the new change, we must manually
     * do a lot of setting to update the values of the input fields based on the new element.
     * @param {object} payload
     * @param {string} payload.selector
     * @param {Array} payload.selectorInfo
     */
    handleIframeClick(payload) {
      if (this.isEditingSelector) {
        return;
      }
      Editor.actions.discardChangesAndSetChangeBasedOnSelector(payload);
    },

    handleInsertOperatorChanged(operator) {
      Editor.actions.setInsertHTMLOperator(operator);
      if (this.currentlyEditingChangeSelector) {
        Editor.actions.applyCurrentlyEditingChange();
      }
    },

    /**
     * Sets the async property based on an update from the component. Update
     * includes isAsync property and instanceIdentifer property.
     * @param payload
     */
    handleChangeTimingChanged(payload) {
      Editor.actions.setChangeTiming(payload.isAsync);
    },

    /**
     * Sets the async property back to what the currentlySavedValue is.
     */
    revertChangeTiming: Editor.actions.revertChangeTiming,

    /**
     * Explicitly set the value of the change type input fields. Prevent change if the field is focused
     * as that usually indicates that a user is actively making changes to that field. If we were to
     * overwrite these on element change, we'd be constantly modifying the input as they type
     */
    initializeInputFields() {
      if (!this._codeMirrors.html.hasFocus()) {
        this.shouldObserveCodeMirror = false;
        this._codeMirrors.html.setValue(
          this.currentlyEditingChange.value || '',
        );
        this.shouldObserveCodeMirror = true;
      }

      // Allow a cycle to render before updating the selector components so that the v-with property can update.
      Vue.nextTick(() => {
        // Because this fires on nextTick, make sure the component exists before emitting on it.
        this.$.changeTimingDropdown && this.$.changeTimingDropdown.handleUpdate();  // eslint-disable-line
      });
    },

    revertInsertHTMLValue() {
      Editor.actions.revertInsertHTMLValue();
      Editor.actions.applyCurrentlyEditingChange();
    },

    /**
     * Wrapper to validate the selector before saving.
     */
    saveChangeAndShowChangeListSidebar() {
      ChangeEditorSidebarMixin.methods.saveChangeAndShowChangeListSidebar.call(
        this,
        this.changeListSidebarComponentConfig,
      );
    },

    setHTML(type, html) {
      // trim the whitespace because if there is whitespace, there will be a lot of highlighted boxes.
      Editor.actions.setCurrentlyEditingInsertHTMLChangeValue(html.trim());

      // Don't try to apply changes when there is no selector
      if (this.currentlyEditingChangeSelector) {
        Editor.actions.applyCurrentlyEditingChange();
      }
    },

    /**
     * Setup the insert html sidebar input fields.
     */
    setupChange() {
      if (!this.currentlyEditingChange) {
        return;
      }
      this.initializeInputFields();
      this.safelyEnsureHTMLCodeMirrorDisableState();
    },

    /**
     * This safely wraps the mixin's toggleCodeMirrorComponentsDisabled function and disables or enables the HTML CodeMirror Component
     */
    safelyEnsureHTMLCodeMirrorDisableState() {
      if (!this.codeMirrorInitialized) {
        return;
      }
      const isDisabled = !this.htmlEditingEnabled;
      this.toggleCodeMirrorComponentsDisabled(isDisabled);
    },
  },

  created() {
    flux.bindVueValues(this, {
      activeFrameId: Editor.getters.activeFrameId,
      changeListSidebarComponentConfig:
        Editor.getters.changeListSidebarComponentConfig,
      changeStatusMap: Editor.getters.changeStatusMap(),
      currentLayer: CurrentLayerGetters.layer,
      currentlyEditingChange: Editor.getters.currentlyEditingChange,
      currentlyEditingChangeIsDirty:
        Editor.getters.currentlyEditingChangeIsDirty,
      currentlyEditingChangeSelector:
        Editor.getters.currentlyEditingChangeSelector,
      currentlyEditingChangeTitle: Editor.getters.currentlyEditingChangeTitle,
      isAdmin: AdminAccountGetters.isAdmin,
      isEditorReadOnly: Editor.getters.isEditorReadOnly,
      isEditingSelector: Editor.getters.changeEditorIsEditingSelector,
      canUpdateLayerExperiment: [
        CurrentProjectGetters.project,
        PermissionsModuleFns.canUpdateLayerExperiment,
      ],
      shouldShowAsyncChanges: PermissionsModuleGetters.canUseAsyncChanges,
      shouldShowDependencyManagement: Editor.getters.showDependencyManagement,
    });

    this._resizableOptions = {
      handles: 'se',
      minHeight: 200,
      reset: true,
      // jQuery UI Resizable insists on setting the width inline upon resize, so override that here
      resize: this.resizeCodeMirror.bind(this),
    };
  },

  ready() {
    if (!this.codeMirrorInitialized) {
      this._codeMirrors.html = this.initializeCodeMirrorField(
        this.$$.html,
        'html',
        'htmlmixed',
        this.setHTML.bind(this),
      );
      this.codeMirrorInitialized = true;
    }

    this.$iframeComponent = flux.evaluateToJS(
      EditorIframeGetters.iframeComponent(this.activeFrameId),
    );
    this.$iframeClickCallback = this.handleIframeClick.bind(this);

    if (this.$iframeComponent) {
      this.$iframeComponent.$on(
        EditorIframeEnums.IFrameMessageTypes.CLICK,
        this.$iframeClickCallback,
      );
    }

    this.__unwatchCurrentlyEditingChange = flux.observe(
      Editor.getters.currentlyEditingChange,
      this.setupChange.bind(this),
    );

    this.initializeInputFields();

    this.$on('sidebarResizeEvent', () => {
      this.refreshCodeMirror(this._codeMirrors.html);
    });

    ui.renderReactComponent(this, {
      component: DependencyManagement,
      el: this.$$.dependencyManagement,
      dataBindings: {
        dependsOnPrevious:
          Editor.getters.currentlyEditingChangeDependsOnPrevious,
      },
      props: {
        // Sets the change dependencies property for the currently editing change.
        handleChangeDependencyClick:
          Editor.actions.setCurrentlyEditingChangeDependencies,
      },
    });

    ui.renderReactComponent(this, {
      component: InsertOperatorDropdown,
      el: this.$$.insertOperatorDropdown,
      props: {
        onOperatorChangeCallback: this.handleInsertOperatorChanged.bind(this),
        initialOperator: this.currentlyEditingChange.operator,
      },
    });

    // Mount the SelectorInput React component for the Insert HTML's SelectorInputTypes.INSERT_HTML_SELECTOR
    ui.renderReactComponent(this, {
      component: SelectorInput,
      el: this.$$.insertHTMLSelectorInput,
      dataBindings: {
        currentSelectorHighlightOptions:
          Editor.getters.currentlyEditingChangeHighlighterOptions,
        iframe: EditorIframeGetters.iframeComponent(this.activeFrameId),
        isDisabled: Editor.getters.selectorInputIsDisabled(
          Editor.constants.SelectorInputTypes.INSERT_HTML_SELECTOR,
        ),
        mountInSearchMode: [
          Editor.getters.currentlyEditingChangeSelector,
          currentlyEditingChangeSelector => !currentlyEditingChangeSelector,
        ],
        value: Editor.getters.currentlyEditingChangeSelector,
      },
      props: {
        onChange: Editor.actions.onChangeSelectorInput,
        onEditStart: _.partial(
          Editor.actions.toggleElementSelection,
          true,
          Editor.constants.SelectorInputTypes.INSERT_HTML_SELECTOR,
        ),
        onEditStop: _.partial(
          Editor.actions.toggleElementSelection,
          false,
          Editor.constants.SelectorInputTypes.INSERT_HTML_SELECTOR,
        ),
        selectorInputType:
          Editor.constants.SelectorInputTypes.INSERT_HTML_SELECTOR,
      },
    });

    // When a Selector Input is being used, we should update the HTML Code Mirror with the appropriate disable state
    flux.observe(
      Editor.getters.changeEditorIsEditingSelector,
      this.safelyEnsureHTMLCodeMirrorDisableState.bind(this),
    );
  },

  beforeDestroy() {
    if (this.__unwatchCurrentlyEditingChange) {
      this.__unwatchCurrentlyEditingChange();
    }

    if (this.$iframeComponent) {
      this.$iframeComponent.$off(
        EditorIframeEnums.IFrameMessageTypes.CLICK,
        this.$iframeClickCallback,
      );
    }
  },
};

export default _.merge(
  {},
  CodeMirrorMixin,
  ChangeEditorSidebarMixin,
  InsertHTMLSidebarComponent,
);

import AudienceEnums from 'optly/modules/entity/audience/enums';

export const summaryHasAnyProcessingAdaptiveAudienceConditions = layerSummary =>
  !layerSummary
    ? null
    : layerSummary.get('adaptive_audience_status') ===
      AudienceEnums.AdaptiveAudienceStatuses.UNAVAILABLE;

export default {
  summaryHasAnyProcessingAdaptiveAudienceConditions,
};

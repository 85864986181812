import _ from 'lodash';
import PropTypes from 'prop-types';

import * as tokens from 'optimizely-oui/src/tokens/forimport/index.es';

import { toImmutable } from 'optly/immutable';
import keyMirror from 'optly/utils/key_mirror';
import tr from 'optly/translate';

const chartYAxisLabelBase = {
  IMPROVEMENT: 'Improvement',
  NET_IMPROVEMENT: 'Improvement',
  STAT_SIG: 'Statistical Significance',
};

const chartYAxisLabelRevenueBase = Object.assign({}, chartYAxisLabelBase, {
  CONVERSIONS: 'Revenue',
});

const chartYAxisLabelValueBase = Object.assign({}, chartYAxisLabelBase, {
  CONVERSIONS: 'Value',
});

const resultShapeObject = {
  isBaseline: PropTypes.bool.isRequired,
  lift: PropTypes.shape({
    confidenceInterval: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    // not required for baseline
    isSignificant: PropTypes.bool.isRequired,
    significance: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    visitorsRemaining: PropTypes.number.isRequired,
    // isMostConclusive: PropTypes.bool.isRequired, // Not using this yet
  }),
  rate: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  variationId: PropTypes.string, // not there for p13n overview where it's 'baseline' / 'variation'

  // totalIncrease: PropTypes.number.isRequired, // Not using this yet
};

const timeseriesShapeObject = _.extend({}, resultShapeObject, {
  timestamp: PropTypes.number,
});

export const AB_CHART_DATE_TIME_FORMAT = '%A, %B %e, %H:%M';

export const allCombinationsSection = {
  id: -1,
  name: tr('All Combinations'),
  value: tr('All Combinations'),
};

export const AND_OR = {
  AND: 'and',
  OR: 'or',
};

export const baseGetterPaths = {
  andOrSegmentCondition: ['p13n/results', 'andOrSegmentCondition'],
  colorOrder: ['p13n/results', 'colorOrder'],
  currencyType: ['p13n/results', 'currencyType'],
  differenceTypeOverride: ['p13n/results', 'differenceTypeOverride'],
  initialSelectedSegments: ['p13n/results', 'initialSelectedSegments'],
  isCurrentProject: ['p13n/results', 'isCurrentProject'],
  resultsQueryPromises: ['p13n/results', 'resultsQueryPromises', 'promises'],
  selectedExperimentId: ['p13n/results', 'selectedExperimentId'],
  selectedExperimentIdsForFilter: [
    'p13n/results',
    'selectedExperimentIdsForFilter',
  ],
  selectedSectionId: ['p13n/results', 'selectedSectionId'],
  selectedSegments: ['p13n/results', 'selectedSegments'],
  statsConfig: ['p13n/results', 'statsConfig'],
};

export const campaignResultStates = {
  INCONCLUSIVE: 'inconclusive',
  LOSER: 'loser',
  WINNER: 'winner',
};

export const chartColors = {
  IS_SIGNIFICANT_NEGATIVE: '#C60C0C',
  IS_SIGNIFICANT_POSITIVE: '#14A54A',
  IS_NOT_SIGNIFICANT: '#B2B2B2',
  TIMESERIES_COLORS: ['#C34687', '#84CFCA', '#F4B852', '#869FAB', '#0081BA'],
  PERSONALIZED: '#F4B852',
  BASELINE: '#7E8C93',
  REBRAND_BASELINE: tokens.yellowBase,
  REBRAND_TIMESERIES: [
    tokens.pinkBase,
    tokens.purpleBase,
    tokens.blueBase,
    tokens.magentaBase,
    tokens.redBase,
    tokens.orangeBase,
    tokens.greenBase,
    tokens.aquaBase,
  ],
};

export const dashStyles = {
  BASELINE_STYLE: 'Solid',
  VARIATION_STYLES: [
    'ShortDash',
    'ShortDot',
    'ShortDashDot',
    'ShortDashDotDot',
    'Dot',
    'Dash',
    'LongDash',
    'DashDot',
    'LongDashDot',
    'LongDashDotDot',
  ],
};

export const chartType = keyMirror({
  CONVERSION_RATE: null,
  CONVERSIONS: null,
  IMPROVEMENT: null,
  IMPROVEMENT_OVER_ORIGINAL: null,
  NET_IMPROVEMENT: null,
  SAMPLES: null,
  STAT_SIG: null,
});

export const chartYAxisLabelBounces = Object.assign({}, chartYAxisLabelBase, {
  CONVERSION_RATE: 'Bounce Rate',
  CONVERSIONS: 'Bounces',
});

export const chartYAxisLabelConversions = Object.assign(
  {},
  chartYAxisLabelBase,
  {
    CONVERSION_RATE: 'Conversion Rate',
    CONVERSIONS: 'Conversions',
  },
);

export const chartYAxisLabelExits = Object.assign({}, chartYAxisLabelBase, {
  CONVERSION_RATE: 'Exit Rate',
  CONVERSIONS: 'Exits',
});

export const chartYAxisLabelSums = {
  revenue: {
    VISITOR: Object.assign({}, chartYAxisLabelRevenueBase, {
      CONVERSION_RATE: 'Revenue per Visitor',
    }),
    SESSION: Object.assign({}, chartYAxisLabelRevenueBase, {
      CONVERSION_RATE: 'Revenue per Session',
    }),
    EVENT: Object.assign({}, chartYAxisLabelRevenueBase, {
      CONVERSION_RATE: 'Revenue per Conversion',
    }),
  },
  value: {
    VISITOR: Object.assign({}, chartYAxisLabelValueBase, {
      CONVERSION_RATE: 'Value per Visitor',
    }),
    SESSION: Object.assign({}, chartYAxisLabelValueBase, {
      CONVERSION_RATE: 'Value per Session',
    }),
    EVENT: Object.assign({}, chartYAxisLabelValueBase, {
      CONVERSION_RATE: 'Value per Conversion',
    }),
  },
};

export const confidenceIntervalChartColors = {
  INCONCLUSIVE_CONFIDENCE_RANGE: 'rgba(136, 136, 136, 0.4)',
  INCONCLUSIVE_IMPROVEMENT: '#888',
  NEGATIVE_CONFIDENCE_RANGE: 'rgba(198, 12, 12, 0.4)',
  NEGATIVE_IMPROVEMENT: '#C60C0C',
  POSITIVE_CONFIDENCE_RANGE: 'rgba(33, 163, 80, 0.4)',
  POSITIVE_IMPROVEMENT: '#21a350',
};

export const currencyTypes = keyMirror({
  DOLLAR: null,
  EURO: null,
  KRONA: null,
  POUND: null,
  WON: null,
  YEN: null,
  YUAN: null,
});

export const currencies = {
  [currencyTypes.DOLLAR]: {
    code: 'USD',
    locale: 'us-US',
    symbol: '$',
  },
  [currencyTypes.EURO]: {
    code: 'EUR',
    locale: 'de-DE',
    symbol: '€',
  },
  [currencyTypes.KRONA]: {
    code: 'SEK',
    locale: 'sv-SV',
    symbol: 'kr',
  },
  [currencyTypes.POUND]: {
    code: 'GBP',
    locale: 'gb-GB',
    symbol: '£',
  },
  [currencyTypes.WON]: {
    code: 'KRW',
    locale: 'kr-KR',
    symbol: '₩',
  },
  [currencyTypes.YEN]: {
    code: 'JPY',
    locale: 'ja-JP',
    symbol: '￥',
  },
  [currencyTypes.YUAN]: {
    code: 'CNY',
    locale: 'cn-CN',
    symbol: 'CN¥',
  },
};

export const nonDecimalCurrencyTypes = [currencyTypes.WON, currencyTypes.YEN];

export const DEFAULT_VARIATIONS_TO_SHOW = __TEST__ ? 3 : 5;

export const BROWSER_SEGMENT_ID = 100;
export const CAMPAIGN_SEGMENT_ID = 200;
export const DEVICE_SEGMENT_ID = 300;
export const REFERRER_SEGMENT_ID = 500;
export const SOURCE_SEGMENT_ID = 600;
export const DEVICE_TYPE_SEGMENT_ID = 700;

export const BROWSER_SEGMENT_NAME = '_browserId_';
export const CAMPAIGN_SEGMENT_NAME = '_campaign_';
export const DEVICE_SEGMENT_NAME = '_device_';
export const REFERRER_SEGMENT_NAME = '_referrer_';
export const SOURCE_TYPE_SEGMENT_NAME = '_source_type_';
export const DEVICE_TYPE_SEGMENT_NAME = '_device_type_';

// Pretty sure these aren't used at all
export const CITY_SEGMENT_NAME = '_city_';
export const REGION_SEGMENT_NAME = '_region_';
export const COUNTRY_SEGMENT_NAME = '_country_';
export const CONTINENT_SEGMENT_NAME = '_continent_';

export const defaultSegmentCategories = {
  [BROWSER_SEGMENT_ID]: tr('Browser'),
  [CAMPAIGN_SEGMENT_ID]: tr('Campaign'),
  [DEVICE_SEGMENT_ID]: tr('Device'),
  [REFERRER_SEGMENT_ID]: tr('Referrer'),
  [SOURCE_SEGMENT_ID]: tr('Source'),
  [DEVICE_TYPE_SEGMENT_ID]: tr('Device Type'),
};

export const defaultSegmentCategoryNames = [
  {
    [BROWSER_SEGMENT_NAME]: tr('Browser'),
    id: BROWSER_SEGMENT_ID,
  },
  {
    [CAMPAIGN_SEGMENT_NAME]: tr('Campaign'),
    id: CAMPAIGN_SEGMENT_ID,
  },
  {
    [DEVICE_SEGMENT_NAME]: tr('Device'),
    id: DEVICE_SEGMENT_ID,
  },
  {
    [REFERRER_SEGMENT_NAME]: tr('Referrer'),
    id: REFERRER_SEGMENT_ID,
  },
  {
    [SOURCE_TYPE_SEGMENT_NAME]: tr('Source'),
    id: SOURCE_SEGMENT_ID,
  },
  {
    [DEVICE_TYPE_SEGMENT_NAME]: tr('Device Type'),
    id: DEVICE_TYPE_SEGMENT_ID,
  },
];
export const deviceSegmentValues = {
  DESKTOP: 'desktop',
  IPAD: 'ipad',
  IPHONE: 'iphone',
  MOBILE: 'mobile',
  TABLET: 'tablet',
};

export const deviceTypeSegmentValues = {
  DESKTOP_LAPTOP: 'desktop_laptop',
  OTHER: 'other',
  PHONE: 'phone',
  TABLET: 'tablet',
};

export const deviceSegmentSortOrder = [
  {
    segmentId: DEVICE_TYPE_SEGMENT_ID,
    value: deviceTypeSegmentValues.DESKTOP_LAPTOP,
  },
  {
    segmentId: DEVICE_TYPE_SEGMENT_ID,
    value: deviceTypeSegmentValues.PHONE,
  },
  {
    segmentId: DEVICE_SEGMENT_ID,
    value: deviceSegmentValues.IPHONE,
  },
  {
    segmentId: DEVICE_SEGMENT_ID,
    value: deviceSegmentValues.MOBILE,
  },
  {
    segmentId: DEVICE_TYPE_SEGMENT_ID,
    value: deviceTypeSegmentValues.TABLET,
  },
  {
    segmentId: DEVICE_SEGMENT_ID,
    value: deviceSegmentValues.IPAD,
  },
  {
    segmentId: DEVICE_SEGMENT_ID,
    value: deviceSegmentValues.TABLET,
  },
  {
    segmentId: DEVICE_TYPE_SEGMENT_ID,
    value: deviceTypeSegmentValues.OTHER,
  },
  {
    segmentId: DEVICE_SEGMENT_ID,
    value: deviceSegmentValues.DESKTOP,
  },
];

export const DIFFERENCE_TYPE_PARAM = 'differenceType';

export const emittedEventTypes = {
  RESET_RESULTS: 'results-reset-results',
};

export const eventType = {
  REVENUE: 'revenue',
};

export const EXPERIMENT_GROUPING = {
  combinations: 'combinations',
  sectionVariations: 'section variations',
  variations: 'variations',
};

export const holdbackProperties = {
  NAME: tr('Holdback'),
  VARIATION_ID: 'holdback',
};

export const holdbackVariation = {
  name: tr('Holdback'),
  variation_id: 'holdback',
};

export const MAX_CACHED_RESULTS_AGE = 1000 * 60 * 10;

export const MAX_EXPERIMENTS_FOR_DETAILED_RESULTS = 8;

export const MAX_EXPERIMENTS_TO_FETCH = 100;

export const MAX_VISITORS_REMAINING = 100000;

export const MIN_VISITORS_REMAINING = 1000;

export const overviewVariations = toImmutable([
  {
    variation_id: 'baseline',
    name: 'Holdback',
  },
  {
    variation_id: 'variation',
    name: 'Variations',
  },
]);

export const P13N_CHART_DATE_TIME_FORMAT = '%A, %B %e';

export const previousViews = keyMirror({
  EXPERIENCES: null,
  EXPERIMENTS: null,
  CAMPAIGNS: null,
  MULTIVARIATE_SECTIONS: null,
  MULTIVARIATE_COMBINATIONS: null,
  VARIATIONS: null,
});

export const queryParams = {
  BASELINE_VARIATION_ID: 'baseline',
  CURRENCY_TYPE: 'currency',
  // Can't use just begin and end because those are used by the results requests
  DATE_RANGE_BEGIN: 'beginDate',
  DATE_RANGE_END: 'endDate',
  METRIC_ALIAS: 'metricAlias',
  SEGMENT_CONDITION: 'segmentCondition',
  // Can't use segmentId and segmentValue because those are used by the results requests
  SEGMENT_ID: 'segId',
  SEGMENT_NAME: 'segName',
  SEGMENT_VALUE: 'segValue',
};

export const RESULTS_CONTROL_KEY = 'baseline';

export const RESULTS_DELAY_NOTIFICATION_ID = 'results-delay-notification';

export const RESULTS_DELAY_TIMEOUT = 30000;

export const RESULTS_FAILURE_NOTIFICATION_ID = 'results-failure-notification';

export const RESULTS_FETCH_DELAY_TIMEOUT = 2000;

export const RESULTS_TREATMENT_KEY = 'variation';

export const SRM_RESET_RESULTS_LINK =
  'https://support.optimizely.com/hc/en-us/articles/27926536539533-Possible-causes-for-traffic-imbalances';

export const resultShape = PropTypes.shape(resultShapeObject);

export const segmentValues = {
  browser: {
    edge: 'Microsoft Edge',
    ff: 'Firefox',
    gc: 'Google Chrome',
    ie: 'Internet Explorer',
    opera: 'Opera',
    safari: 'Safari',
    unknown: tr('Unknown'),
  },
  device: {
    [deviceSegmentValues.DESKTOP]: tr('Desktop (deprecated)'),
    [deviceSegmentValues.IPAD]: 'iPad',
    [deviceSegmentValues.IPHONE]: 'iPhone',
    [deviceSegmentValues.MOBILE]: tr('Other phones'),
    [deviceSegmentValues.TABLET]: tr('Other tablets'),
  },
  'device type': {
    [deviceTypeSegmentValues.DESKTOP_LAPTOP]: tr('Desktop / laptop'),
    [deviceTypeSegmentValues.OTHER]: tr('Other devices'),
    [deviceTypeSegmentValues.PHONE]: tr('All phones'),
    [deviceTypeSegmentValues.TABLET]: tr('All tablets'),
  },
  source: {
    campaign: tr('Campaign'),
    direct: tr('Direct'),
    referral: tr('Referral'),
    search: tr('Search'),
  },
};

export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
};

export const timeseriesNodeState = keyMirror({
  INCONCLUSIVE: null,
  NEGATIVE: null,
  POSITIVE: null,
});

export const timeseriesShape = PropTypes.shape(timeseriesShapeObject);

export const SEGMENT_PROJECT_SETTINGS = {
  ALL_PROJECTS: 'ALL_PROJECTS',
  CURRENT_PROJECT: 'CURRENT_PROJECT',
};

export default {
  AB_CHART_DATE_TIME_FORMAT,
  allCombinationsSection,
  AND_OR,
  baseGetterPaths,
  BROWSER_SEGMENT_ID,
  BROWSER_SEGMENT_NAME,
  campaignResultStates,
  chartColors,
  chartType,
  chartYAxisLabelBounces,
  chartYAxisLabelConversions,
  chartYAxisLabelExits,
  chartYAxisLabelSums,
  confidenceIntervalChartColors,
  currencies,
  currencyTypes,
  dashStyles,
  DEFAULT_VARIATIONS_TO_SHOW,
  defaultSegmentCategories,
  defaultSegmentCategoryNames,
  DEVICE_SEGMENT_ID,
  DEVICE_SEGMENT_NAME,
  DEVICE_TYPE_SEGMENT_ID,
  DEVICE_TYPE_SEGMENT_NAME,
  deviceTypeSegmentValues,
  deviceSegmentSortOrder,
  DIFFERENCE_TYPE_PARAM,
  emittedEventTypes,
  eventType,
  EXPERIMENT_GROUPING,
  holdbackProperties,
  holdbackVariation,
  MAX_CACHED_RESULTS_AGE,
  MAX_EXPERIMENTS_FOR_DETAILED_RESULTS,
  MAX_EXPERIMENTS_TO_FETCH,
  MAX_VISITORS_REMAINING,
  MIN_VISITORS_REMAINING,
  nonDecimalCurrencyTypes,
  overviewVariations,
  P13N_CHART_DATE_TIME_FORMAT,
  previousViews,
  queryParams,
  REFERRER_SEGMENT_ID,
  RESULTS_CONTROL_KEY,
  RESULTS_DELAY_NOTIFICATION_ID,
  RESULTS_DELAY_TIMEOUT,
  RESULTS_FAILURE_NOTIFICATION_ID,
  RESULTS_FETCH_DELAY_TIMEOUT,
  RESULTS_TREATMENT_KEY,
  SRM_RESET_RESULTS_LINK,
  resultShape,
  segmentValues,
  SEGMENT_PROJECT_SETTINGS,
  SORT_DIRECTIONS,
  timeseriesNodeState,
  timeseriesShape,
};

export const MAX_RESULTS_RETRIES = 25;

export const SENTRY_ERROR_FINGERPRINT = 'results_api_max_retries_reached';

import Immutable, { toImmutable } from 'optly/immutable';

import AdminAccountGetters from 'optly/modules/admin_account/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import EventEnums from 'optly/modules/entity/event/enums';
import RecommenderGetters from 'optly/modules/entity/recommender/getters';

import constants from './constants';

const DATA_STORE_ID = 'RecommendationsDataStore';

export const currentlyEditingCatalog = [
  DATA_STORE_ID,
  'currentlyEditingCatalog',
];

export const catalogProperty = function(property) {
  return [
    currentlyEditingCatalog,
    editingCatalog => editingCatalog.get(property),
  ];
};

const projectSpecificEvents = isCurrentlyEditingCatalog => [
  [
    DATA_STORE_ID,
    isCurrentlyEditingCatalog ? 'currentlyEditingCatalog' : 'catalog',
    'project_specifics',
  ],
  projectSpecifics => {
    // current convention is one ProjectSpecifics per catalog
    if (!projectSpecifics || projectSpecifics.size === 0) {
      return toImmutable([]);
    }

    return projectSpecifics.first().get('events');
  },
];

const selectedEventsFilter = [
  CurrentProjectGetters.currentProjectActiveViews,
  CurrentProjectGetters.currentProjectActiveEvents,
  CurrentProjectGetters.currentProjectActiveTags,
  catalogProperty('id_tag_name'),
  (events, viewMap, eventMap, tagMap, primaryIDTagName) =>
    events.map(event => {
      let entityName;
      let entity;
      let humanReadableType;
      let warningContent;
      const id = event.get('id');
      const kind = event.get('kind');
      const tags = event.get('tags');
      const tagsNames = tags.map(tagID => tagMap.getIn([tagID, 'api_name']));
      const eventHasNoTags = !tags.size;
      const eventHasDeletedTags = tags.find(tagID => !tagMap.get(tagID));
      const eventHasNoPrimaryIDTag = !tagsNames.includes(primaryIDTagName);

      if (kind === constants.EventKind.VIEW) {
        entity = viewMap.get(id);
        entityName = viewMap.getIn([id, 'name']);
        humanReadableType = constants.ENTITY_HUMAN_READABLES.EVENT.VIEW;
      } else if (kind === constants.EventKind.EVENT) {
        entity = eventMap.get(id);
        entityName = eventMap.getIn([id, 'name']);
        const eventType = entity.get('event_type');
        humanReadableType = constants.ENTITY_HUMAN_READABLES.EVENT[eventType];
      }

      const name = `${humanReadableType} - ${entityName}`;

      if (eventHasDeletedTags) {
        warningContent = constants.MISSING_WARNING_TEXTS.TAG.hasDeletedTag;
      } else if (eventHasNoTags) {
        warningContent = constants.MISSING_WARNING_TEXTS.TAG.hasNoTags;
      } else if (eventHasNoPrimaryIDTag) {
        warningContent = constants.MISSING_WARNING_TEXTS.TAG.hasNoPrimaryIDTag;
      }

      return toImmutable({
        description: `ID: ${id}`,
        entity,
        for_catalog: event.get('for_catalog'),
        id,
        kind,
        name,
        tags,
        humanReadableType,
        warningContent,
      });
    }),
];

export const selectedEventsForSavedCatalog = [
  projectSpecificEvents(false),
  ...selectedEventsFilter,
];

export const catalog = [DATA_STORE_ID, 'catalog'];

export const activeRecommenders = [
  [DATA_STORE_ID, 'catalog', 'id'],
  RecommenderGetters.entityCache,
  (catalogID, recommenders) =>
    recommenders
      .filter(
        reco =>
          reco.get('recommender_service_id') === catalogID &&
          !reco.get('archived'),
      )
      .toList(),
];

export const archivedRecommenders = [
  [DATA_STORE_ID, 'catalog', 'id'],
  RecommenderGetters.entityCache,
  (catalogID, recommenders) =>
    recommenders
      .filter(
        reco =>
          reco.get('recommender_service_id') === catalogID &&
          reco.get('archived') === true,
      )
      .toList(),
];

export const currentlyEditingEvent = [DATA_STORE_ID, 'currentlyEditingEvent'];

export const currentlyEditingEventPageName = [
  currentlyEditingEvent,
  CurrentProjectGetters.currentProjectActiveViews,
  (event, viewMap) => {
    const kind = event.get('kind');
    if (
      kind === constants.EventKind.EVENT &&
      event.getIn(['entity', 'event_type']) === EventEnums.eventTypes.CUSTOM
    ) {
      return 'N/A';
    }

    const viewId =
      kind === constants.EventKind.EVENT
        ? event.getIn(['entity', 'view_id'])
        : event.getIn(['entity', 'id']);
    return viewMap.getIn([viewId, 'name']);
  },
];

export const selectedEvents = [
  projectSpecificEvents(true),
  ...selectedEventsFilter,
];

export const selectedEventsForCatalog = [
  selectedEvents,
  events => events.filter(event => event.get('for_catalog')),
];

export const unselectedUserEvents = eventType => [
  projectSpecificEvents(true),
  CurrentProjectGetters.currentProjectActiveEvents,
  (events, eventMap) => {
    const selectedEventIds = [];

    events.forEach(event => {
      const kind = event.get('kind');
      if (kind === constants.EventKind.EVENT) {
        selectedEventIds.push(event.get('id'));
      }
    });
    const eventIdsSet = Immutable.Set(selectedEventIds);

    return eventMap
      .toList()
      .filter(
        event =>
          !eventIdsSet.has(event.get('id')) &&
          event.get('event_type') === eventType,
      )
      .map(event => {
        const id = event.get('id');
        const kind = constants.EventKind.EVENT;
        const humanReadableType =
          constants.ENTITY_HUMAN_READABLES.EVENT[event.get('event_type')];

        return toImmutable({
          description: `ID: ${id}`,
          entity: event,
          for_catalog: false,
          id,
          kind,
          name: `${humanReadableType} - ${event.get('name')}`,
          tags: [],
          humanReadableType,
        });
      });
  },
];

export const unselectedViews = [
  projectSpecificEvents(true),
  CurrentProjectGetters.currentProjectActiveViews,
  (events, viewMap) => {
    const selectedViewIds = [];

    events.forEach(event => {
      const kind = event.get('kind');
      if (kind === constants.EventKind.VIEW) {
        selectedViewIds.push(event.get('id'));
      }
    });
    const viewIdsSet = Immutable.Set(selectedViewIds);

    return viewMap
      .toList()
      .filter(view => !viewIdsSet.has(view.get('id')))
      .map(view => {
        const id = view.get('id');
        const kind = constants.EventKind.VIEW;
        const humanReadableType = constants.ENTITY_HUMAN_READABLES.EVENT.VIEW;

        return toImmutable({
          description: `ID: ${id}`,
          entity: view,
          for_catalog: false,
          id,
          kind,
          name: `${humanReadableType} - ${view.get('name')}`,
          tags: [],
          humanReadableType,
        });
      });
  },
];

export const unselectedEvents = [
  unselectedViews,
  unselectedUserEvents(EventEnums.eventTypes.CLICK),
  unselectedUserEvents(EventEnums.eventTypes.CUSTOM),
  (views, clickEvents, customEvents) =>
    toImmutable([
      {
        key: 'pageViews',
        label: 'Page Views',
        items: views,
      },
      {
        key: 'clickEvents',
        label: 'Click Events',
        items: clickEvents,
      },
      {
        key: 'customEvents',
        label: 'Custom Events',
        items: customEvents,
      },
    ]),
];

export const selectedEventPageId = [DATA_STORE_ID, 'selectedEventPageId'];

export const availableTags = [
  CurrentProjectGetters.currentProjectActiveTags,
  projectSpecificEvents(false),
  (tags, events) => {
    if (events.isEmpty()) {
      return Immutable.List();
    }

    const allAvailableTagIds = events
      .map(event => event.get('tags'))
      .reduce((tagIds, event) => tagIds.concat(event.tags))
      .toSet();
    const filteredTags = tags.filter(tag =>
      allAvailableTagIds.has(tag.get('id')),
    );
    return filteredTags.toList();
  },
];

export const selectedTags = [
  currentlyEditingEvent,
  CurrentProjectGetters.currentProjectActiveTags,
  CurrentProjectGetters.currentProjectActiveViews,
  (event, tagMap, viewMap) =>
    event.get('tags').map(id => {
      const isDeleted = !tagMap.get(id);
      const apiName = tagMap.getIn([id, 'api_name'], 'Unavailable');
      const valueType = tagMap.getIn([id, 'value_type']);
      const pageID = tagMap.getIn([id, 'view_id']);
      const pageName = viewMap.getIn([pageID, 'name'], 'N/A');

      return toImmutable({
        description: `ID: ${id}, Page: ${pageName}`,
        id,
        name: apiName,
        type: valueType,
        warningContent:
          isDeleted && constants.MISSING_WARNING_TEXTS.TAG.isDeletedTag,
      });
    }),
];

export const unselectedTags = [
  currentlyEditingEvent,
  CurrentProjectGetters.currentProjectActiveTags,
  CurrentProjectGetters.currentProjectActiveViews,
  selectedEventPageId,
  (event, tagMap, viewMap, pageId) => {
    const isCustomEvent =
      event.getIn(['entity', 'event_type']) === EventEnums.eventTypes.CUSTOM;
    const tagIds = event.get('tags').toSet();
    const filteredUnselectedTags = tagMap
      .filter(
        tag =>
          (isCustomEvent || tag.get('view_id') === pageId) &&
          !tagIds.has(tag.get('id')),
      )
      .map(tag => {
        const id = tag.get('id');
        const apiName = tag.get('api_name');
        const valueType = tag.get('value_type');
        const pageID = tag.get('view_id');
        const pageName = viewMap.getIn([pageID, 'name'], 'N/A');

        return toImmutable({
          description: `ID: ${id}, Page: ${pageName}`,
          id,
          name: apiName,
          type: valueType,
        });
      });

    return toImmutable([
      {
        key: 'tags',
        label: 'Tags',
        items: filteredUnselectedTags.toList(),
      },
    ]);
  },
];

// tags (by name) available to the catalog
export const availableTagChoices = [
  selectedEventsForCatalog,
  CurrentProjectGetters.currentProjectActiveTags,
  (events, tagMap) =>
    Immutable.Set()
      .withMutations(set => {
        events.forEach(event =>
          event
            .get('tags')
            .filter(id => !!tagMap.get(id))
            .map(id => {
              const apiName = tagMap.getIn([id, 'api_name']);
              return set.add(
                toImmutable({
                  label: apiName,
                  value: apiName,
                }),
              );
            }),
        );
      })
      .toList()
      .unshift(
        toImmutable({
          label: 'None',
          value: null,
        }),
      ),
];

export const selectedIdTagName = [
  catalogProperty('id_tag_name'),
  availableTagChoices,
  (selected, choices) => {
    const foundIndex = choices.findIndex(
      choice => choice.get('value') === selected,
    );
    if (foundIndex > -1) {
      return selected;
    }
    return choices.first().get('value');
  },
];

export const selectedUrlTagName = [
  catalogProperty('url_tag_name'),
  availableTagChoices,
  (selected, choices) => {
    const foundIndex = choices.findIndex(
      choice => choice.get('value') === selected,
    );
    if (foundIndex > -1) {
      return selected;
    }
    return choices.first().get('value');
  },
];

// available pages the event can fire on, for click / view events its the view, for custom its any view
export const eventPages = [
  currentlyEditingEvent,
  CurrentProjectGetters.currentProjectActiveViews,
  (event, viewMap) => {
    const kind = event.get('kind');
    if (
      kind === constants.EventKind.EVENT &&
      event.getIn(['entity', 'event_type']) === EventEnums.eventTypes.CUSTOM
    ) {
      return viewMap.toList();
    }

    const viewId =
      kind === constants.EventKind.EVENT
        ? event.getIn(['entity', 'view_id'])
        : event.getIn(['entity', 'id']);

    return toImmutable([viewMap.get(viewId)]);
  },
];

export const catalogForSave = [
  AdminAccountGetters.id,
  currentlyEditingCatalog,
  selectedIdTagName,
  selectedUrlTagName,
  (adminAccountId, editingCatalog, idTagName, urlTagName) => {
    const toSave = {
      account_id: adminAccountId,
      description: editingCatalog.get('description'),
      id_tag_name: idTagName,
      name: editingCatalog.get('name'),
      run_parameters: editingCatalog.get('run_parameters'),
      url_tag_name: urlTagName,
    };

    if (editingCatalog.get('id')) {
      toSave.id = editingCatalog.get('id');
    }
    // map over tags and flatten to ids only
    toSave.project_specifics = editingCatalog
      .get('project_specifics')
      .updateIn([0, 'events'], events =>
        events.map(event =>
          toImmutable({
            id: event.get('id'),
            kind: event.get('kind'),
            tags: event.get('tags'),
            for_catalog: event.get('for_catalog'),
          }),
        ),
      )
      .toJS();

    return toSave;
  },
];

export const currentlyEditingRecommender = [
  DATA_STORE_ID,
  'currentlyEditingRecommender',
];

export const currentlyEditingRecommenderSpecificsField = function(key) {
  return [
    [DATA_STORE_ID, 'currentlyEditingRecommender', 'specifics', key],
    val => {
      if (!val) {
        return toImmutable([]);
      }

      return val;
    },
  ];
};

export const selectedRecommenderSpecificEventIds = function(key) {
  return [
    [DATA_STORE_ID, 'currentlyEditingRecommender', 'specifics', key],
    selectedEventsForSavedCatalog,
    (selected, availableEvents) => {
      if (!selected) {
        return toImmutable([]);
      }
      return selected
        .filter(
          selectedItem =>
            availableEvents.findIndex(
              item => item.get('id') === selectedItem.get('id'),
            ) >= 0,
        )
        .map(item => item.get('id'));
    },
  ];
};

export const recommenderById = function(id) {
  return [
    [DATA_STORE_ID, 'recommenders'],
    recommenders =>
      recommenders.find(recommender => recommender.get('id') === id),
  ];
};

export default {
  activeRecommenders,
  archivedRecommenders,
  availableTags,
  availableTagChoices,
  catalog,
  catalogForSave,
  catalogProperty,
  currentlyEditingCatalog,
  currentlyEditingEvent,
  currentlyEditingEventPageName,
  currentlyEditingRecommender,
  currentlyEditingRecommenderSpecificsField,
  eventPages,
  recommenderById,
  selectedEvents,
  selectedEventsForSavedCatalog,
  selectedEventPageId,
  selectedRecommenderSpecificEventIds,
  selectedIdTagName,
  selectedTags,
  selectedUrlTagName,
  unselectedEvents,
  unselectedTags,
  unselectedUserEvents,
  unselectedViews,
};

/**
 * Condition Template Component
 *
 * @author Tim Scanlin (tim.scanlin@optimizely.com)
 */

const lodash = require('lodash');
const flux = require('core/flux');
const DimensionGetters = require('optly/modules/entity/dimension/getters')
  .default;
const CurrentProjectGetters = require('optly/modules/current_project/getters');
const tr = require('optly/translate');
const stringUtils = require('optly/utils/str');
const PermissionsGetters = require('optly/modules/permissions/getters');

const TimeAndDayPicker = require('../time_and_day_picker');
const template = require('./condition_template.html');

const CUSTOM_DIMENSION_KEY = 'custom_dimension';
const LIST_ATTRIBUTE_KEY = 'list';

module.exports = {
  template,

  // special components that specific condition template components need to use
  components: {
    'time-and-day-picker': TimeAndDayPicker,
  },
  computed: {
    // returns list of weekdays sorted according to locale settings
    weekdaysMin() {
      const date = tr.date();
      const weekdayValuesMap = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ];
      return lodash.times(7, weekday => ({
        label: date.weekday(weekday).format('dd'),
        // isoWeekday is 1-7, when 1 always is monday
        name: weekdayValuesMap[date.isoWeekday() - 1],
      }));
    },
  },

  methods: {
    /**
     * Get dimension title to be displayed in the audience builder
     * @param {object.<Dimension>} dimension The dimension object
     * @param {object} conditionConfig Config representing the third party dimension
     * @return {string} Title representing the dimension
     */
    getDimensionTitle(dimension, conditionConfig) {
      let title = '';
      if (
        !Object.prototype.hasOwnProperty.call(
          conditionConfig,
          'integration_master_label',
        )
      ) {
        if (dimension.id === CUSTOM_DIMENSION_KEY) {
          title = lodash
            .map(this.dimensionText.split(' '), word =>
              stringUtils.capitalize(word),
            )
            .join(' ');
        } else if (
          this.canUseListAttributes &&
          dimension.id === LIST_ATTRIBUTE_KEY
        ) {
          title = tr('List Attribute');
        } else {
          title = tr(dimension.title);
        }
      } else {
        title = tr(conditionConfig.integration_master_label);
      }

      return title;
    },
  },

  created() {
    // TODO(jordan): this is only needed such that `condition_template.html` can do a lookup
    // on `dimensions[conditionType].deprecated`
    //
    // Ideally we'd be passing the same `dimension` to the condition_group base component
    // that is grabbed from `Dimension.getters.defaultDimensions`
    flux.bindVueValues(this, {
      dimensions: DimensionGetters.defaultDimensions,
      dimensionText: CurrentProjectGetters.dimensionText,
      canUseListAttributes: PermissionsGetters.canUseListAttributes,
    });
  },
};

/**
 * Getters for filterableTable
 */

import { toImmutable } from 'optly/immutable';

import enums from './enums';

const defaultFilter = ['filterableTable', 'defaultFilter'];

const DEFAULT_FILTERS = {
  string: '',
  status: enums.status.ACTIVE,
};

/**
 * Retrieves the raw filter object for a givent table id
 *
 * @param {string} tableId Table id that we want to retrieve filters for
 */
const filtersNoDefaults = tableId => ['filterableTable', 'filters', tableId];

/**
 * Retrieves set filters or the default for the specified table
 *
 * @param {string} tableId Table id that we want to retrieve filters for
 */
export const filters = tableId => [
  filtersNoDefaults(tableId),
  defaultFilter,
  (filter, fallback) => filter || fallback,
];

/**
 * Retrieves the field that is being filtered
 *
 * @param {string} tableId Table id that we want to retrieve filters for
 * @param {string} field containing the filter that we are interested in
 */
export const fieldFilter = (tableId, field) => [
  filtersNoDefaults(tableId),
  defaultFilter,
  (filter, fallback) => (filter ? filter.get(field) : fallback.get(field)),
];

/**
 * Retrieves the string that is being filtered (usage is for basic case
 * when only want to filter on a single string -- basically for convenience)
 *
 * @param {string} tableId Table id that we want to retrieve filters for
 */
export const stringFilter = tableId => fieldFilter(tableId, 'string');

export const tableDefaults = tableId => [
  ['filterableTable', 'defaults', tableId],
  defaults => toImmutable({}).merge(toImmutable(DEFAULT_FILTERS), defaults),
];

export const computedFilters = (tableId, localDefaults) => [
  filtersNoDefaults(tableId),
  tableDefaults(tableId),
  (filters, defaults) =>
    toImmutable({}).merge(defaults, toImmutable(localDefaults), filters),
];

export default {
  filters,
  fieldFilter,
  stringFilter,
  filtersNoDefaults,
  computedFilters,
};

import { connect } from 'core/ui/decorators';
import flux from 'core/flux';
import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'optly/immutable';

import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import CustomCodeActions from 'bundles/p13n/modules/custom_code/actions';
import CustomCodeGetters from 'bundles/p13n/modules/custom_code/getters';
import EditorActions from 'bundles/p13n/modules/editor/actions';
import EditorGetters from 'bundles/p13n/modules/editor/getters';
import EditorIframeEnums from 'bundles/p13n/modules/editor_iframe/enums';
import LayerFns from 'optly/modules/entity/layer/fns';
import LayerExperimentHumanReadable from 'optly/modules/entity/layer_experiment/human_readable';
import P13NUIActions from 'bundles/p13n/modules/ui/actions';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
import PermissionsGetters from 'optly/modules/permissions/getters';
import SidebarComponent from 'react_components/sidebar';
import ConcurrentEditingContainer from 'bundles/p13n/components/concurrency/concurrent_editing_container';

import PreviewButton from './subcomponents/preview_button';
import VariationSwitcherComponent from './subcomponents/variation_switcher';

const { SidebarHeader } = SidebarComponent;

@connect({
  canUsePreview: PermissionsGetters.canUsePreview,
  currentLayer: CurrentLayerGetters.layer,
  currentLayerExperimentOrSection:
    EditorGetters.currentLayerExperimentOrSection,
  currentProject: CurrentProjectGetters.project,
  selectedVariationId: EditorGetters.selectedVariationId,
  selectedView: EditorGetters.selectedView,
  variationsInCurrentLayerExperimentOrSection:
    EditorGetters.variationsInCurrentLayerExperimentOrSection,
})
class Header extends React.Component {
  static propTypes = {
    canUsePreview: PropTypes.bool.isRequired,
    currentLayer: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentLayerExperimentOrSection: PropTypes.instanceOf(Immutable.Map)
      .isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    onActionSelect: PropTypes.func.isRequired,
    selectedVariationId: PropTypes.number.isRequired,
    selectedView: PropTypes.instanceOf(Immutable.Map).isRequired,
    variationsInCurrentLayerExperimentOrSection: PropTypes.instanceOf(
      Immutable.List,
    ).isRequired,
  };

  navigateBack = () => {
    const hasDirtyCustomCode = flux.evaluate(
      CustomCodeGetters.hasDirtyCustomCode,
    );
    P13NUIActions.confirmNavigation(
      hasDirtyCustomCode,
      LayerExperimentHumanReadable.CHANGE_TYPES.custom_code,
      () => {
        CustomCodeActions.resetCustomCodeTabsDirty();
        EditorActions.unselectAction();
        this.props.onActionSelect({
          shouldShowCampaignNavSidebar: true,
        });
      },
    );
  };

  showVariationSwitcher = () => {
    const canViewVariationsInP13N = PermissionsModuleFns.canViewVariationsInP13N(
      this.props.currentProject,
      this.props.currentLayer,
    );
    const isPersonalizationLayer = LayerFns.isPersonalizationLayer(
      this.props.currentLayer,
    );
    return canViewVariationsInP13N || !isPersonalizationLayer;
  };

  showPreviewButton = () => {
    const { canUsePreview } = this.props;
    const editorType = flux.evaluate(EditorGetters.activeFrameEditorType);
    const isMultivariateTestLayer = LayerFns.isMultivariateTestLayer(
      this.props.currentLayer,
    );
    return (
      canUsePreview &&
      !isMultivariateTestLayer &&
      editorType !== EditorIframeEnums.EditorTypes.EXPERIMENT_EDITOR_READ_ONLY
    );
  };

  getTitle = () => {
    const isABTestLayer = LayerFns.isABTestLayer(this.props.currentLayer);
    const canViewVariationsInP13N = PermissionsModuleFns.canViewVariationsInP13N(
      this.props.currentProject,
      this.props.currentLayer,
    );
    if (isABTestLayer) {
      return this.props.currentLayer.get('name');
    }
    if (!canViewVariationsInP13N) {
      return flux.evaluateToJS(EditorGetters.selectedVariationDisplayName);
    }
    return flux.evaluateToJS(
      EditorGetters.currentExperimentOrSectionDisplayName,
    );
  };

  getBackLinkText = () => {
    let text;
    const isABTestLayer = LayerFns.isABTestLayer(this.props.currentLayer);
    const isMultivariateTestLayer = LayerFns.isMultivariateTestLayer(
      this.props.currentLayer,
    );
    if (isABTestLayer) {
      text = 'Variations';
    } else if (isMultivariateTestLayer) {
      text = 'Sections';
    } else {
      text = 'Experiences';
    }
    return text;
  };

  render() {
    const { currentLayer, currentProject } = this.props;
    const isPersonalizationLayer = LayerFns.isPersonalizationLayer(
      currentLayer,
    );
    return (
      <SidebarHeader
        projectName={currentProject.get('project_name')}
        backLinkOnClick={this.navigateBack}
        backLinkText={this.getBackLinkText()}
        title={this.getTitle()}>
        {isPersonalizationLayer && (
          <div
            className="micro muted"
            data-test-section="change-list-p13n-layer-name">
            {currentLayer.get('name')}
          </div>
        )}
        {currentLayer ? (
          <ConcurrentEditingContainer entityId={currentLayer.get('id')} />
        ) : null}
        {this.showVariationSwitcher() && (
          <VariationSwitcherComponent {...this.props} />
        )}
        {this.showPreviewButton() && <PreviewButton {...this.props} />}
      </SidebarHeader>
    );
  }
}

export { Header };

export default Header;

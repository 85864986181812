/**
 * This is a wrapper around the React Metrics Picker. We pass it all its required
 * props via ui.connectGetter so we don't have to pollute the experiment form with
 * these data bindings. Ideally the metrics picker component itself should be connecting
 * with the getters.
 */
import Immutable, { toImmutable } from 'optly/immutable';
import flux from 'core/flux';
import PropTypes from 'prop-types';
import React from 'react';
import ui from 'core/ui';

import MetricsPicker from 'bundles/p13n/components/metrics/metrics_manager_react/subcomponents/metrics_picker_react';

// modules
import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import LayerEnums from 'optly/modules/entity/layer/enums';
import MetricsManagerModuleGetters from 'bundles/p13n/modules/metrics_manager/getters';
import ComponentModuleGetters from 'bundles/p13n/sections/oasis_experiment_manager/components/experiment_dialog/component_module/getters';
import P13NUIActions from 'bundles/p13n/modules/ui/actions';

const MetricsPickerWrapper = props => {
  const currentLayer = flux.evaluate(CurrentLayerGetters.layer);
  const layer =
    currentLayer ||
    toImmutable({
      policy: LayerEnums.policy.SINGLE_EXPERIMENT,
      primary_metric: null,
    });
  return (
    <div className="push-quad--bottom">
      <h3>Metrics</h3>
      <p className="push-double--bottom">
        Create one or more metrics for this campaign. Choose a primary metric
        that best determines your campaign’s success. Secondary metrics help
        gather additional insights but may take longer to achieve statistical
        significance.{' '}
        <a
          href="https://help.optimizely.com/Ideate_and_Hypothesize/Set_primary%2C_secondary%2C_and_monitoring_metrics"
          target="_blank"
          rel="noopener noreferrer">
          {' '}
          Learn More{' '}
        </a>
      </p>
      <MetricsPicker
        createEvent={P13NUIActions.showFullstackEventConfigDialog}
        layer={layer}
        {...props}
      />
    </div>
  );
};

MetricsPickerWrapper.propTypes = {
  layer: PropTypes.instanceOf(Immutable.Map),
};

export default ui.connectGetters(MetricsPickerWrapper, {
  canSaveEmptyMetrics: ComponentModuleGetters.isCurrentLayerInDraftStatus,
  metricWrappers: CurrentLayerGetters.currentLayerMetricWrappers,
  workingMetricWrappers: MetricsManagerModuleGetters.workingMetricWrappers,
});

/**
 * Entity Definition for Recommender Stats
 *
 * /api/v1/recommenders/{recommender_id}/last_run/
 */

export const entity = 'recommenders_stats';

export const isRelationshipEntity = true;

export const parents = [
  {
    entity: 'recommenders',
    key: 'recommender_id',
  },
  {
    entity: 'last_run',
  },
];

export default {
  entity,
  isRelationshipEntity,
  parents,
};

import actions from './actions';
import enums from './enums';
import constants from './constants';
import getters from './getters';
import fns from './fns';

/**
 * Helper function which returns the filter object which should be used for
 * LayerExperiment.actions.intelligentFetchAll's experimentFilters option.
 *
 * @returns {*}
 */
const getDefaultLayerExperimentFilters = () => {
  return { status: ['active', 'paused'] };
};

export {
  actions,
  constants,
  enums,
  fns,
  getDefaultLayerExperimentFilters,
  getters,
};

export default {
  actions,
  constants,
  enums,
  fns,
  getDefaultLayerExperimentFilters,
  getters,
};

import keyMirror from 'optly/utils/key_mirror';

export default keyMirror({
  SET_TOTP_CODE: null,
  SET_TWO_FACTOR_TOKEN: null,
  SET_PASSWORD: null,
  SET_EMAIL: null,
  SET_CSRF_TOKEN: null,
  SET_LAST_SUCCESSFUL_AUTH: null,
  SET_TEMP_2FA_SECRET: null,
  SET_NEXT_STATE: null,
});

import React from 'react';
import PropTypes from 'prop-types';

const Header = props => {
  const { isNewPage } = props;

  return (
    <div
      className="beta push-double--bottom"
      data-test-section="edit-view-smart-pagetitle">
      {isNewPage ? 'New' : 'Edit'} Page
    </div>
  );
};

Header.propTypes = {
  isNewPage: PropTypes.bool,
};

export default Header;

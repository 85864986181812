/**
 * Format a description into HTML with links and hashtags activated
 *
 * @author Jon Noronha (jon.noronha@optimizely.com)
 */
const regex = require('optly/utils/regex');

module.exports = function(text) {
  const html = text
    .replace(/\n/g, '<br />')
    .replace(regex.urls, '<a href="$&" target="_blank">$&</a>')
    .replace(regex.hashtags, '<a class="hashtag">$&</a>');
  return html;
};

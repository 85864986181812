import _ from 'lodash';
import { toImmutable } from 'optly/immutable';

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';
import ExperimentSectionGetters from 'optly/modules/entity/experiment_section/getters';
import LayerExperimentGetters from 'optly/modules/entity/layer_experiment/getters';

import * as fns from './fns';
import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

function singleExperimentByLayerId(layerId) {
  return [
    LayerExperimentGetters.entityCache,
    /**
     * @param {Immutable.Map} experimentMap
     * @return {Immutable.Map}
     */
    experimentMap =>
      experimentMap.filter(exp => exp.get('layer_id') === layerId).first(),
  ];
}

function experimentsByLayerId(layerId) {
  return [
    LayerExperimentGetters.entityCache,
    /**
     * @param {Immutable.Map} experimentMap
     * @return {Immutable.List}
     */
    experimentMap =>
      experimentMap.filter(exp => exp.get('layer_id') === layerId).toList(),
  ];
}

function sectionsByLayerId(layerId) {
  return [
    ExperimentSectionGetters.entityCache,
    /**
     * @param {Immutable.Map} experimentMap
     * @return {Immutable.List}
     */
    sectionMap =>
      sectionMap
        .filter(section => section.get('layer_id') === layerId)
        .toList(),
  ];
}

/**
 * Getter designed to get a list of ordered audience_ids for a given layer.
 * If the layer does not have an experiment_ids property simply fetch the
 * audience_ids property and return that.
 *
 * If the layer has experiment_ids, iterate through each experiment and pull
 * out the audience_ids property. Making two big assumptions here:
 *    1. All personalization layers have 1 audience per experiment and they are
 *       all unique.
 *    2. There is one and only one layer experiment for an AB campaign that
 *       contains a list of unique audience_ids.
 *
 * DEPRECATED - uses Layer experiment_ids - DO NOT USE
 *
 * @param layerId
 * @returns {Immutable.List}
 */
function audienceIdsByLayerId(layerId) {
  return [
    this.byId(layerId),
    LayerExperimentGetters.entityCache,
    /**
     * @param {Immutable.Map} layer
     * @param {Immutable.Map} experiments
     * @return {Immutable.List}
     */
    function(layer, experiments) {
      if (!layer) {
        return toImmutable([]);
      }

      let allAudiences = [];

      if (!!layer.get('experiment_ids') && !!layer.get('experiment_ids').size) {
        layer.get('experiment_ids').map(experimentId => {
          experiments
            .filter(experiment => experiment.get('id') === experimentId)
            .map(experiment => {
              // TODO(ricky): Revisit this when personalization can have more than one audience per experiment.
              // Additionally revisit this if we have more than one experiment for an AB layer.
              allAudiences = allAudiences.concat(
                experiment.get('audience_ids').toJS(),
              );
            });
        });
      }

      return toImmutable(allAudiences);
    },
  ];
}

/**
 * Getter to return the specific layer experiment given a layerId and a audienceId.
 * @param {Number} layerId
 * @param {Number/String} audienceId
 * @returns {Immutable.List}
 */
function experimentByLayerIdAndAudienceId(layerId, audienceId) {
  return [
    experimentsByLayerId(layerId),
    /**
     * @param {Immutable.List} experiments
     * @return {Immutable.Map}
     */
    experiments =>
      experiments
        .filter(
          experiment => experiment.get('audience_ids').first() === audienceId,
        )
        .first(),
  ];
}

function experimentIdsOFLayerByStatusFilter(layerId, filterFunc) {
  return [
    baseEntityGetters.entityCache,
    LayerExperimentGetters.entityCache,
    (layers, experiments) =>
      layers
        .getIn([layerId, 'experiment_ids'])
        .filter(experimentId =>
          filterFunc(experiments.getIn([experimentId, 'status'])),
        ),
  ];
}

/**
 * Really cheap way to check if the layer has had a state change after being created
 * @param {Number} layerId
 * @returns {Boolean}
 */
function isLayerInDraftStatus(layerId) {
  return [baseEntityGetters.byId(layerId), fns.isLayerInDraftStatus];
}

export default _.extend(baseEntityGetters, {
  audienceIdsByLayerId,
  experimentsByLayerId,
  experimentByLayerIdAndAudienceId,
  experimentIdsOFLayerByStatusFilter,
  isLayerInDraftStatus,
  sectionsByLayerId,
  singleExperimentByLayerId,
});

/**
 * For use with components like the color picker.
 */

module.exports = {
  /**
   * Default rgba values for elements that don't have backgrounds will return the alpha value as 0.
   * This will cause a color picker to load with alpha set to transparent and changing colors will have no impact.
   * This function strips the 0 and replaces it with a 1 to make the color picker work correctly.
   * @param {string} color - the 0 alpha rgba color to transform, e.g., 'rgba(0, 0, 0, 0)'
   * @returns {string|null}
   */
  convertZeroAlphaForRgba(color) {
    return color ? color.replace(', 0)', ', 1)') : null;
  },
};

import vModel from 'vue/src/directives/model';
/**
 * This monkeypatch overwrites the event listener on `compositionend` to explicitly dispatch an input event
 * when a user has finished typing or exited out of an IME.
 * MDN docs on `compositionend`: https://developer.mozilla.org/en-US/docs/Web/Events/compositionend
 *
 * Without this monkeypatch, when a user types into an input field with an IME, the following occurs in VueJS:
 *    1. `compositionstart` fires and locks the v-model data so you't change it;
 *    2. `input` fires, but the lock prevents the v-model data from updating; and
 *    3. on the last input into the IME:
 *      a) `input` fires, but the v-model is still locked, and
 *      b)`compositionend` fires, unlocking the v-model for data updates (which doesn't matter because all
 *        `input` events have already fired).
 *
 * This monkeypatch emulates the logic in VueJS 2.2.6:
 * https://github.com/vuejs/vue/blob/e5e940307b75cff51208c0d14107bcf53ba8c851/src/platforms/web/runtime/directives/model.js#L112-L125
 * @private
 */
const __vModelBind = vModel.bind;
vModel.bind = function() {
  __vModelBind.call(this);

  // First we remove the original event listener.
  this.el.removeEventListener('compositionend', this.cUnlock);

  const __cUnlock = this.cUnlock;
  this.cUnlock = () => {
    __cUnlock.call(this);
    const event = new Event(this.event);
    this.el.dispatchEvent(event);
  };

  // Then we attach the new event listener.
  this.el.addEventListener('compositionend', this.cUnlock);
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Disclose } from 'optimizely-oui';

import AdvancedFeaturesDeactivationTitle from './AdvancedFeaturesDeactivationTitle';
import AdvancedFeaturesDeactivationAndUndo from './AdvancedFeaturesDeactivationAndUndo';
import AdvancedFeaturesTrimming from './AdvancedFeaturesTrimming';

const AdvancedFeatures = function(props) {
  const { hasDisclose, children } = props;

  const renderWithDisclose = () => {
    return (
      <Disclose title="Advanced" data-test-section="advanced_features-toggle">
        {children}
      </Disclose>
    );
  };

  const renderTest = childrenProp => {
    return childrenProp;
  };

  return (
    <div
      className={classNames({ 'push-quad--top': hasDisclose })}
      data-test-section="configure-view-smart-advanced_features">
      {hasDisclose ? renderWithDisclose() : renderTest(children)}
    </div>
  );
};

AdvancedFeatures.propTypes = {
  children: PropTypes.node.isRequired,
  hasDisclose: PropTypes.bool,
};

AdvancedFeatures.defaultProps = {
  hasDisclose: true,
};

AdvancedFeatures.displayName = 'AdvancedFeatures';
AdvancedFeatures.HelpLink = AdvancedFeaturesDeactivationTitle;
AdvancedFeatures.Deactivation = AdvancedFeaturesDeactivationAndUndo;
AdvancedFeatures.Trimming = AdvancedFeaturesTrimming;

export default AdvancedFeatures;

/**
 * Utility to generate and parse urls and allow the user to access individual query parameters
 *
 * @author Cheston Lee
 */
const urlParser = require('fast-url-parser');
const qs = require('qs');
const validUrl = require('valid-url');

const PROTOCOL_PATTERN = /^([a-z0-9.+-]+:)/i;
const config = require('atomic-config');
// We are overwriting the internal encoder/decoder for the querystring parser to simply return the url instead of using
// decodeURI and encodeURI. This allows developers who use this functionality to input the url exactly from what the
// customer has input, without needing to encode it first, solving https://optimizely.atlassian.net/browse/WEB-1010.
// More details on this solution can be found at https://confluence.sso.episerver.net/display/EXPENG/WEB-1010
urlParser.queryString = {
  parse(query) {
    return qs.parse(query, {
      decoder(url) {
        return url;
      },
    });
  },
  stringify(query) {
    return qs.stringify(query, {
      encoder(url) {
        return url;
      },
    });
  },
};

/**
 * node's url module doesnt like urls without protocols (www.site.com)
 * @param {string} urlString
 * @return {string}
 */
const ensureProtocol = function(urlString) {
  const protocol = PROTOCOL_PATTERN.exec(urlString);
  if (!protocol) {
    return `http://${urlString}`;
  }
  return urlString;
};

/**
 * given a url, return an http-protocol version
 * @param  {string} urlString
 * @return {string}
 */
const generateHttpUrl = function(urlString) {
  const urlObject = urlParser.parse(ensureProtocol(urlString), true);
  urlObject.protocol = 'http';
  urlObject.slashes = true;
  return urlParser.format(urlObject);
};

/**
 * given a url, return an http-protocol version
 * @param  {string} urlString
 * @return {string}
 */
const generateHttpsUrl = function(urlString) {
  const urlObject = urlParser.parse(ensureProtocol(urlString), true);
  urlObject.protocol = 'https';
  urlObject.slashes = true;
  return urlParser.format(urlObject);
};

/**
 * given a url, return an http-protocol version
 * @param  {string} urlString
 * @return {string}
 */
const generateProxyUrl = function(urlString) {
  const urlObject = urlParser.parse(ensureProtocol(urlString), true);
  const ProxyUrlObject = urlParser.parse(config.get('env.EDIT_URL'), true);
  ProxyUrlObject.pathname = encodeURIComponent(urlParser.format(urlObject));
  return urlParser.format(ProxyUrlObject);
};

/**
 * given a url, return preview proxy version
 * @param  {string} urlString
 * @return {string}
 */
const generatePreviewProxyUrl = function(urlString) {
  const urlObject = urlParser.parse(ensureProtocol(urlString), true);
  const urlObjectWithoutQueryString = urlParser.parse(
    ensureProtocol(urlString),
  );
  const ProxyUrlObject = urlParser.parse(config.get('env.PREVIEW_URL'), true);
  urlObjectWithoutQueryString.search = '';
  urlObjectWithoutQueryString.hash = '';
  ProxyUrlObject.pathname = urlParser.format(urlObjectWithoutQueryString);
  ProxyUrlObject.query = urlObject.query;
  ProxyUrlObject.hash = urlObject.hash;
  return urlParser.format(ProxyUrlObject);
};

const isValidUrl = function(urlString) {
  const urlWithProtocol = ensureProtocol(urlString);
  return validUrl.isUri(urlWithProtocol);
};

/**
 * given the current visitor location (url), return utm_source
 * @return {string} examples given below
 * - https://app.optimizely.com/v2/projects/990060029/campaigns will return product_v2_projects
 * - https://app.optimizely.com/projects/990060029 will return product_projects
 *
 */
const generateUtmSourceValue = function() {
  const current_path = window.location.pathname;
  const sourcePageParts = current_path.split('/');
  let underscore;
  let pageName;
  if (current_path.indexOf('v2') !== -1) {
    underscore =
      sourcePageParts.length > 2 && sourcePageParts[2] !== '' ? '_' : '';
    pageName = sourcePageParts.length > 2 ? sourcePageParts[2] : '';
    return `product_v2${underscore}${encodeURIComponent(pageName)}`;
  }
  underscore =
    sourcePageParts.length > 1 && sourcePageParts[1] !== '' ? '_' : '';
  pageName = sourcePageParts.length > 1 ? sourcePageParts[1] : '';
  return `product${underscore}${encodeURIComponent(pageName)}`;
};

/**
 * Build the base properties leveraging the parsing properties of the anchor.
 * @param target
 * @param {object} [overrides] - URL properties to persist
 * @constructor
 */
function Url(target, overrides) {
  const anchor = document.createElement('a');
  anchor.href = target;

  overrides = overrides || {};

  this.hostname = overrides.hostname || anchor.hostname;
  this.protocol = overrides.protocol || anchor.protocol;
  this.pathname = overrides.pathname || anchor.pathname;
  this.search = overrides.search || anchor.search;
  this.href = overrides.href || anchor.href;
  this.fullpath = this.pathname + this.search;
  this.origin = `${anchor.protocol}//${anchor.hostname}`;

  this._params = {};
  this._parseQuery(this.search);
}

/**
 * Populates  the _params object with the key/value pairs in .search
 *
 * @param {String} search The window.location.search
 */
Url.prototype._parseQuery = function(search) {
  if (!search) {
    return;
  }
  // Strip leading ?
  const params = search.substring(1).split('&');

  params.forEach(param => {
    const arr = param.split('=');
    this._params[decodeURIComponent(arr[0])] = decodeURIComponent(arr[1]);
  });
};

/**
 * @param {String} param The query param value to retrieve
 * @return {String|Number} The value for the given query param
 */
Url.prototype.getQueryParam = function(param) {
  return this._params[param];
};

/**
 * Builds url for the editor client (goes through GAE)
 *
 * @param {string} projectId Current projectId
 * @param {string} projectToken Token that is used for the preview url
 * @returns {string}
 */
function generateClientBuildServerEditorUrl(projectId, projectToken) {
  return optimizelyHRDUrl(
    `/js/editor-client.js?project_id=${projectId}&token=${projectToken}`,
  );
}

/**
 * Retrieve endpoint for getting draft data for a given project
 * @param {string} projectId
 * @param {string} projectToken Token that is used for the preview url
 * @returns {string}
 */
function generateClientDraftDataEndpointUrl(projectId, projectToken) {
  const uri = `/api/client/${projectId}/editor_data.js?token=${projectToken}`;
  return optimizelyHRDUrl(uri);
}

/**
 * Creates a fully qualified URL based on the `OPTIMIZELY_HRD_HOST_URL` env variable
 * @param {String} uri
 * @return {String}
 */
function optimizelyHRDUrl(uri) {
  let base = config.get('env.OPTIMIZELY_HRD_HOST_URL', '') || '';
  if (base.substr(base.length - 1) !== '/') {
    base += '/';
  }

  if (uri.charAt(0) === '/') {
    uri = uri.substr(1);
  }

  return base + uri;
}

/**
 * Returns a url fully qualified with the v2 (nova) host
 */
function v2Url(uri) {
  if (!config.get('env.USE_NOVA')) {
    return uri;
  }
  let base = config.get('env.NOVA_HOST_URL', '');
  if (base.substr(base.length - 1) !== '/') {
    base += '/';
  }

  if (uri.charAt(0) === '/') {
    uri = uri.substr(1);
  }

  return base + uri;
}

module.exports = {
  // URL Utilities
  create(target, overrides) {
    if (!target) {
      return null;
    }

    return new Url(target, overrides);
  },
  ensureProtocol,
  urlParser, // Patched version of fast-url-parser

  // Optimizely Specific URL Functions
  generateClientBuildServerEditorUrl,
  generateClientDraftDataEndpointUrl,
  generateHttpUrl,
  generateHttpsUrl,
  generateProxyUrl,
  generatePreviewProxyUrl,
  generateUtmSourceValue,
  optimizelyHRDUrl,
  v2Url,
  isValidUrl,
};

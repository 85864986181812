import $ from 'jquery';
import { extend } from 'lodash';
import flux from 'core/flux';

import { getters as CommitGetters } from 'optly/modules/entity/commit';
import {
  actions as CurrentLayerActions,
  getters as CurrentLayerGetters,
} from 'bundles/p13n/modules/current_layer';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import Event from 'optly/modules/entity/event';

import getters from './getters';
import fns from './fns';
import actionTypes from './action_types';

const actions = {};

/**
 * @param {Object} payload
 * @param {String} payload.name
 * @param {String} payload.enabled
 * @param {String} payload.selector
 * @param {Number} payload.viewId
 * @param {Number} payload.eventId
 */
actions.setTrackClickEvent = payload => {
  flux.dispatch(actionTypes.TRACK_CLICKS_SET_EVENT, payload);
};

/**
 * @param {String} name
 */
actions.setTrackClickEventName = name => {
  actions.setTrackClickEvent({
    name,
  });
};

/**
 * Set up the initial store when user selects an iframe element
 * @param {String} selector
 * @param {Number} viewId
 */
actions.initializeCurrentlyEditingTrackClickEvent = (selector, viewId) => {
  const layerEvents = flux.evaluate(CurrentLayerGetters.layerEvents);
  const campaignEventWithMatchingId = fns.findMatchingEvent(
    layerEvents,
    selector,
    viewId,
  );
  const projectEvents = flux.evaluate(CurrentProjectGetters.events);
  const projectEventWithMatchingId = fns.findMatchingEvent(
    projectEvents,
    selector,
    viewId,
  );
  let eventConfig = {
    name: '',
    selector,
    viewId,
    enabled: false,
    eventId: null,
  };
  if (campaignEventWithMatchingId) {
    eventConfig = extend({}, eventConfig, {
      name: campaignEventWithMatchingId.get('name'),
      eventId: campaignEventWithMatchingId.get('id'),
      enabled: true,
    });
  } else if (projectEventWithMatchingId) {
    eventConfig = extend({}, eventConfig, {
      name: projectEventWithMatchingId.get('name'),
      eventId: projectEventWithMatchingId.get('id'),
    });
  }

  actions.setTrackClickEvent(eventConfig);
};

/**
 * @param {String} selector
 */
actions.updateTrackClickEventSelector = selector => {
  const isCampaignSpecificEvent = flux.evaluate(
    getters.isCampaignSpecificEvent,
  );
  const trackClicksChangeIsEnabled = flux.evaluate(
    getters.trackClicksChangeIsEnabled,
  );
  // Only update existing selector if track clicks change is enabled and is campaign specific
  if (isCampaignSpecificEvent && trackClicksChangeIsEnabled) {
    actions.setTrackClickEvent({
      selector,
    });
    return;
  }
  // If event is project level, we do not want to edit the selector of the
  // event through the change editor, reinitialize the store with a new selector
  const viewId = flux.evaluate(getters.trackClicksChangeViewId);
  actions.initializeCurrentlyEditingTrackClickEvent(selector, viewId);
};

/**
 * @param {Boolean} enabled
 *
 * @returns {Boolean} whether set enabled state was successful or not
 */
actions.setTrackClickEventEnabled = enabled => {
  const layerMetrics = flux.evaluate(CurrentLayerGetters.layer).get('metrics');
  // Prevent the user from disabling the last metric in the campaign
  if (!enabled && layerMetrics.size === 0) {
    return false;
  }
  flux.dispatch(actionTypes.TRACK_CLICKS_SET_EVENT, {
    enabled,
  });
  return true;
};

/**
 * Revert track clicks event store to initialized state
 */
actions.revertChanges = () => {
  const selector = flux.evaluate(getters.trackClicksChangeSelector);
  const viewId = flux.evaluate(getters.trackClicksChangeViewId);
  actions.initializeCurrentlyEditingTrackClickEvent(selector, viewId);
};

/**
 * Save current state of track clicks event store to event and layer entities
 * @returns {Deferred}
 */
actions.saveTrackClicksChangeEvent = function() {
  const trackClicksChangeIsDirty = flux.evaluate(
    getters.trackClicksChangeIsDirty,
  );

  // if there are no dirty changes
  if (!trackClicksChangeIsDirty) {
    // return immediately resolved deferred
    return $.Deferred().resolve();
  }

  let trackClicksChange = flux.evaluate(getters.trackClicksChange);

  if (!trackClicksChange.get('name')) {
    const placeholderName = tr(
      'Click Event for {0}',
      trackClicksChange.get('selector'),
    );
    actions.setTrackClickEventName(placeholderName);
    trackClicksChange = flux.evaluate(getters.trackClicksChange);
  }

  const campaignEventWithMatchingId = flux.evaluate(
    getters.campaignEventWithMatchingId,
  );
  const isTrackClicksEventInCampaign = !!campaignEventWithMatchingId;

  const projectEventWithMatchingId = flux.evaluate(
    getters.projectEventWithMatchingId,
  );
  const isTrackClicksEventInProject = !!projectEventWithMatchingId;
  const eventAddAction =
    CurrentLayerActions.addEventToCurrentLayerMetricsWithMetricsBuilder;

  // if the event is in the campaign, and its not enabled
  if (isTrackClicksEventInCampaign && !trackClicksChange.get('enabled')) {
    // remove it from the campaign
    return CurrentLayerActions.removeEventFromCurrentLayerMetrics(
      campaignEventWithMatchingId.get('id'),
    ).then(() => {
      if (campaignEventWithMatchingId.get('variation_specific')) {
        return Event.actions.archive(campaignEventWithMatchingId.toJS());
      }
    });
  }

  // if the event is not in the campaign, but its a project event, and its enabled
  if (
    !isTrackClicksEventInCampaign &&
    isTrackClicksEventInProject &&
    trackClicksChange.get('enabled')
  ) {
    return eventAddAction(projectEventWithMatchingId.get('id')).then(() => {
      if (
        trackClicksChange.get('name') !== projectEventWithMatchingId.get('name')
      ) {
        return Event.actions.save({
          name: trackClicksChange.get('name'),
          id: projectEventWithMatchingId.get('id'),
          archived: false,
        });
      }
      if (projectEventWithMatchingId.get('archived')) {
        return Event.actions.unarchive(projectEventWithMatchingId.toJS());
      }
    });
  }

  // if the event is not in the campaign, and its not a project event, and its enabled
  if (
    !isTrackClicksEventInCampaign &&
    !isTrackClicksEventInProject &&
    trackClicksChange.get('enabled')
  ) {
    // create a new event and add it to the campaign as a secondary metric
    const event = Event.fns.createEventEntity({
      name: trackClicksChange.get('name'),
      event_type: Event.enums.eventTypes.CLICK,
      event_filter: {
        filter_type: Event.enums.filterTypes.TARGET_SELECTOR,
        selector: trackClicksChange.get('selector'),
      },
      project_id: flux.evaluate(CurrentProjectGetters.id),
      view_id: trackClicksChange.get('viewId'),
      variation_specific: true,
    });
    return Event.actions.save(event).then(newEvent => {
      actions.setTrackClickEvent({
        eventId: newEvent.id,
      });
      return eventAddAction(newEvent.id);
    });
  }

  // if the event is in the campaign and its enabled
  if (isTrackClicksEventInCampaign && trackClicksChange.get('enabled')) {
    // update the existing event name and selector
    return Event.actions.save({
      id: campaignEventWithMatchingId.get('id'),
      name: trackClicksChange.get('name'),
      event_filter: {
        filter_type: Event.enums.filterTypes.TARGET_SELECTOR,
        selector: trackClicksChange.get('selector'),
      },
    });
  }

  return $.Deferred().resolve();
};

/**
 * @param {String} selector
 * @param {Number} viewId
 * @return {String}
 */
actions.getMatchingEventChangeText = function(selector, viewId) {
  const layerEvents = flux.evaluate(CurrentLayerGetters.layerEvents);
  const campaignEventWithMatchingId = fns.findMatchingEvent(
    layerEvents,
    selector,
    viewId,
  );
  if (campaignEventWithMatchingId) {
    return tr('Enabled click tracking');
  }
  // Look for selector in both active and archived events
  const allInProjectEvents = flux.evaluate(CurrentProjectGetters.events);
  const projectEventWithMatchingId = fns.findMatchingEvent(
    allInProjectEvents,
    selector,
    viewId,
  );
  if (!projectEventWithMatchingId) {
    return null;
  }

  // Look through commit history to see if there was ever a matching event in the metrics
  // If there was a matching event in the metrics for this layer, that means the user has
  // disabled the event at some point
  const commits = flux.evaluate(CommitGetters.entityCache);
  const allEvents = flux.evaluate(Event.getters.entityCache);
  const currentLayerId = flux.evaluate(CurrentLayerGetters.id);
  let previousRevisionEvent;
  commits.forEach(commit => {
    commit
      .getIn(['revisions', 'layer'])
      .filter(layer => layer.get('id') === currentLayerId)
      .forEach(layer => {
        const layerMetrics = layer.get('metrics');
        const matchingEvents = allEvents.filter(event =>
          layerMetrics.find(
            metric => metric.get('event_id') === event.get('id'),
          ),
        );
        previousRevisionEvent = fns.findMatchingEvent(
          matchingEvents,
          selector,
          viewId,
        );
      });
  });
  if (previousRevisionEvent) {
    return tr('Disabled click tracking');
  }

  return null;
};

export default actions;

import React from 'react';

const WelcomeSectionHeader = () => (
  <div
    className="welcome-header soft-quad--top reverse background--white width--1-1"
    data-test-section="welcome-section-header">
    <div className="max-width--large text--left height--1-1 anchor--middle">
      <h1 className="flush--bottom kilo weight--light color--brand push-triple--left">
        Welcome to Optimizely.
      </h1>
    </div>
  </div>
);

export default WelcomeSectionHeader;

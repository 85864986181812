export const dialogManager = [
  'uiComponents',
  'globalComponents',
  'dialogManager',
];

export const notificationManager = [
  'uiComponents',
  'globalComponents',
  'notificationManager',
];

export const ouiIcons = ['uiComponents', 'globalComponents', 'ouiIcons'];

export default {
  dialogManager,
  notificationManager,
  ouiIcons,
};

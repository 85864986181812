import { fns as Viewfns, enums as ViewEnums } from 'optly/modules/entity/view';
import { enums as ProjectEnums } from 'optly/modules/entity/project';
import Immutable from 'optly/immutable';

/*
 * Get a map of errors for the current url targeting config
 * @param {Immutable.Map} config
 * @returns {Immutable.Map}
 */
const validateUrlTargetingConfig = function(config) {
  let errorObject = Immutable.Map();

  if (!config.getIn(['includeConditions', 0, 'value'])) {
    errorObject = errorObject.set('url_condition', tr('Please provide a url'));
  }

  if (!config.get('edit_url')) {
    errorObject = errorObject.set(
      'edit_url',
      tr('Please provide an example url for use when editing or previewing'),
    );
  }

  const apiNameError = Viewfns.validateApiName(config);
  if (apiNameError) {
    errorObject = errorObject.set('api_name', apiNameError);
  }

  return errorObject;
};

/*
 * @param {Immutable.Map} urlTargetingView
 * @param {Immutable.Map} viewFromStore
 * @returns {Immutable.Map}
 */
const addFieldsToTargetingConfigForViewConversion = function(
  urlTargetingView,
  viewFromStore,
  currentProjectId,
) {
  let view;
  if (viewFromStore) {
    view = viewFromStore
      .merge(urlTargetingView)
      .set('single_use', false)
      .delete('view_id');
    view = view.toJS();
  } else {
    view = urlTargetingView
      .set('page_type', ViewEnums.pageTypes.URL_SET)
      .set('project_id', currentProjectId)
      .set('platform', ProjectEnums.project_platforms.WEB);
    view = Viewfns.createViewEntity(view.toJS());
  }
  return view;
};

export default {
  validateUrlTargetingConfig,
  addFieldsToTargetingConfigForViewConversion,
};

import React from 'react';
import { Button, DialogNew, Link, Icon } from 'optimizely-oui';

import PropTypes from 'prop-types';

import ui from 'core/ui';

const UpgradeDialog = props => {
  const { header, message, buttonLink, buttonText } = props;
  return (
    <DialogNew
      footerButtonList={[
        <Button
          key="cancel"
          onClick={ui.hideDialog}
          style="plain"
          data-test-section="upgrade-dialog-dismiss-button">
          Cancel
        </Button>,
        <Link
          key="cta-link"
          testSection="upgrade-dialog-button-link"
          newWindow={true}
          href={buttonLink}>
          <Button
            isLink={true}
            style="highlight"
            testSection="upgrade-dialog-button-text">
            {buttonText} <Icon name="arrow-up-right-from-square" size="small" />
          </Button>
        </Link>,
      ]}
      onClose={ui.hideDialog}
      title={header}>
      <p data-test-section="upgrade-button-message">{message}</p>
    </DialogNew>
  );
};

UpgradeDialog.propTypes = {
  message: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default UpgradeDialog;

import ResultsApiActions from './actions';
import ResultsApiConstants from './constants';
import ResultsApiFns from './fns';
import ResultsApiGetters from './getters';
import ResultsApiStubActions from './stub_actions';

export const actions = ResultsApiActions;
export const constants = ResultsApiConstants;
export const fns = ResultsApiFns;
export const getters = ResultsApiGetters;
export const stubActions = ResultsApiStubActions;

export default {
  actions,
  constants,
  fns,
  getters,
  stubActions,
};

window.optly = window.optly || {};

import React from 'react';
import PropTypes from 'prop-types';

import { actions as jsSDKLabActions } from '@optimizely/js-sdk-lab';

import { connect } from 'core/ui/decorators';
import { isPageLoading } from 'core/modules/loading/getters';

import Immutable, { toImmutable } from 'optly/immutable';

import LayerGetters from 'optly/modules/entity/layer/getters';
import {
  getters as DashboardGetters,
  fns as DashboardFns,
  actions as DashboardActions,
} from 'optly/modules/dashboard';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerFns from 'optly/modules/entity/layer/fns';
import LayerActions from 'optly/modules/entity/layer/actions';
import LayerExperimentActions from 'optly/modules/entity/layer_experiment/actions';
import LiveCommitTagGetters from 'optly/modules/entity/live_commit_tag/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import FilterableTableEnums from 'optly/modules/filterable_table/enums';
import { Features } from 'optly/utils/enums';

import withEntitySearch, {
  withEntitySearchPropTypes,
} from 'bundles/p13n/components/entity_search';
import EntitySearchConstants from 'bundles/p13n/components/entity_search/component_module/constants';

import {
  getters as V2MigrationGetters,
  fns as V2MigrationFns,
} from 'optly/modules/v2_migration';

import LegacyExperimentTable from './legacy_experiment_table';
import SearchExperimentTable from './search_experiment_table';
import {
  dashboardTabs,
  allFilterableActiveStatuses,
} from './page_module/constants';

const TABLE_ID = FilterableTableEnums.tableIds.P13N_LAYERS;

@connect(({ activeTab }) => {
  return {
    isLegacyDashboardLoading: isPageLoading(TABLE_ID, 1),
    currentProject: CurrentProjectGetters.project,
    layers: LayerGetters.entityCache,
    liveCommitTagsByLayerId: [
      LiveCommitTagGetters.entityCache,
      liveCommitTags =>
        liveCommitTags.reduce(
          (byLayerIdMap, liveCommitTag) =>
            byLayerIdMap.set(liveCommitTag.get('layer_id'), liveCommitTag),
          Immutable.Map({}),
        ),
    ],
    columnsVisibility: DashboardGetters.getColumnsVisibility(activeTab),
    defaultEmptyStateImage: V2MigrationGetters.v2FirstRunImage,
    defaultEmptyStateText: V2MigrationGetters.v2FirstRunText,
  };
})
@withEntitySearch({
  expand: [EntitySearchConstants.searchExpandFields.EXPERIMENT_TYPE],
  query: '',
  page: 1,
  type: [
    EntitySearchConstants.searchEntityTypes.CAMPAIGN,
    EntitySearchConstants.searchEntityTypes.EXPERIMENT,
  ],
  archived: false,
  status: allFilterableActiveStatuses,
  order: EntitySearchConstants.searchSortOrders.DESC,
  sort: EntitySearchConstants.searchSortableKeys.LAST_MODIFIED,
  syncWithSearchParams: jsSDKLabActions.getFeatureVariableBoolean(
    'experiment_list_m1',
    'use_custom_table',
  ),
})
class LayerTableDataWrapper extends React.Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    columnsVisibility: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    defaultEmptyStateImage: PropTypes.string.isRequired,
    defaultEmptyStateText: PropTypes.shape({
      subtitle: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
    isLegacyDashboardLoading: PropTypes.bool.isRequired,
    layers: PropTypes.instanceOf(Immutable.Map).isRequired,
    liveCommitTagsByLayerId: PropTypes.instanceOf(Immutable.Map).isRequired,
    ...withEntitySearchPropTypes,
  };

  state = {
    hydratedSearchResultIdsToLayerIds: {},
    isReloadingSearchResults: false,
    fieldsParams: DashboardFns.getFieldsParams(this.props.activeTab),
  };

  useCustomTable = jsSDKLabActions.getFeatureVariableBoolean(
    'experiment_list_m1',
    'use_custom_table',
  );

  isM1P13NEnabled = jsSDKLabActions.isFeatureEnabled(Features.M1_P13N);

  componentDidMount() {
    const { fieldsParams } = this.state;
    const { activeTab, columnsVisibility, currentProject } = this.props;
    if (!fieldsParams && this.useCustomTable) {
      this.syncColumnsVisibility(columnsVisibility);
    }
    DashboardActions.setActiveTab(currentProject.get('id'), activeTab);
  }

  componentDidUpdate(prevProps) {
    const { columnsVisibility } = this.props;
    if (
      prevProps.columnsVisibility !== columnsVisibility &&
      this.useCustomTable
    ) {
      this.syncColumnsVisibility(columnsVisibility);
    }
  }

  syncColumnsVisibility(newColumnVisibility) {
    const { activeTab } = this.props;
    const fields = newColumnVisibility
      .filter(value => value)
      .keySeq()
      .toArray();
    this.setState(
      {
        fieldsParams: newColumnVisibility,
      },
      () =>
        DashboardFns.setSearchParams({
          [`fields_${activeTab}`]: fields,
        }),
    );
  }

  shouldRenderLegacyTable = () => {
    const { searchApiStatus } = this.props;
    // If the Search API cannot query entities, fall back to the legacy dashboard
    return !searchApiStatus.canQueryEntities;
  };

  hydrateSearchResults = searchResults => {
    const { currentProject } = this.props;
    const currentProjectId = currentProject.get('id');

    // Seperate experiments/campaigns since each has different hydration logic
    const experimentResults = searchResults.filter(
      entity =>
        entity.type === EntitySearchConstants.searchEntityTypes.EXPERIMENT,
    );
    const campaignResults = searchResults.filter(
      entity =>
        entity.type === EntitySearchConstants.searchEntityTypes.CAMPAIGN,
    );

    let experimentResultIdsToLayerIdsPromise = Promise.resolve({});
    if (experimentResults.length > 0) {
      experimentResultIdsToLayerIdsPromise = LayerExperimentActions.fetchAll({
        id: experimentResults.map(result => result.id),
        project_id: currentProjectId,
      }).then(layerExperiments =>
        layerExperiments.reduce(
          (acc, exp) => ({
            ...acc,
            [exp.id]: exp.layer_id,
          }),
          {},
        ),
      );
    }

    experimentResultIdsToLayerIdsPromise.then(experimentResultIdsToLayerIds => {
      const campaignResultIdsToLayerIds = campaignResults.reduce(
        (acc, result) => ({ ...acc, [result.id]: result.id }),
        {},
      );
      const layerIds = [
        ...Object.values(experimentResultIdsToLayerIds),
        ...campaignResults.map(result => result.id),
      ];
      let layerFetchPromise = Promise.resolve([]);
      if (layerIds.length > 0) {
        layerFetchPromise = LayerActions.fetchAll({
          project_id: currentProjectId,
          id: layerIds,
        }).then(() => {
          // Once layers are loaded, cache their search result associations in state
          this.setState(prevState => ({
            hydratedSearchResultIdsToLayerIds: {
              ...prevState.hydratedSearchResultIdsToLayerIds,
              ...campaignResultIdsToLayerIds,
              ...experimentResultIdsToLayerIds,
            },
          }));
        });
      }
      return layerFetchPromise;
    });
  };

  onEntityUpdate = () => {
    const { waitForReindex, reloadSearchPage } = this.props;

    return waitForReindex().then(() => {
      this.setState({
        isReloadingSearchResults: true,
      });

      return reloadSearchPage().then(results => {
        this.hydrateSearchResults(results);
        this.setState({
          isReloadingSearchResults: false,
        });
      });
    });
  };

  getDashboardLayerForSearchResult = searchResult => {
    const { layers, liveCommitTagsByLayerId } = this.props;
    const { hydratedSearchResultIdsToLayerIds } = this.state;

    let layer;
    let liveTag;
    if (hydratedSearchResultIdsToLayerIds[searchResult.id]) {
      layer = layers.get(hydratedSearchResultIdsToLayerIds[searchResult.id]);
      liveTag = liveCommitTagsByLayerId.get(
        hydratedSearchResultIdsToLayerIds[searchResult.id],
        null,
      );
    }

    // Convert search result status to enum (like LayerFns.getStatus() does for layers)
    const status =
      {
        [LayerEnums.entityStatus.NOT_STARTED]: LayerEnums.status.DRAFT,
        [LayerEnums.entityStatus.RUNNING]: LayerEnums.status.RUNNING,
        [LayerEnums.entityStatus.PAUSED]: LayerEnums.status.PAUSED,
        [LayerEnums.entityStatus.ARCHIVED]: LayerEnums.status.ARCHIVED,
        [LayerEnums.entityStatus.CONCLUDED]: LayerEnums.status.CONCLUDED,
      }[searchResult.status] || LayerEnums.status.DRAFT;

    /**
     * @type DashboardLayer
     * @description Normalized layer data for rendering experiment tables
     * @property {Immutable.Map} Layer optional - layer associated with an experiment
     *                                 table row. If undefined, the layer is still
     *                                 loading.
     * @property {Immutable.Map} LiveTag optional - live tag associated with an
     *                                   experiment table row. If null, the live
     *                                   tag is still loading. If undefined, the
     *                                   live tag doesn't exist.
     * @property {string} name - name of the experiment
     * @property {string} description - description of the experiment
     * @property {string} type - type of the experiment
     * @property {string} last_modified - ISO date string of entity's last modified date
     * @property {string} status - status string of the entity
     * @property {string} unique_id - a unique ID for entity that can be used as a React key
     */
    return Immutable.Map({
      layer,
      liveTag,
      name: searchResult.name,
      description: searchResult.description,
      type: searchResult.experiment_type || LayerEnums.type.PERSONALIZATION,
      last_modified: searchResult.last_modified,
      status,
      unique_id: searchResult.id,
    });
  };

  getDashboardLayerForLayer = layer => {
    const { liveCommitTagsByLayerId } = this.props;
    const liveTag = liveCommitTagsByLayerId.get(layer.get('id'), null);
    const status = LayerFns.getStatus(layer, liveTag);

    /**
     * @type DashboardLayer
     * @description Normalized layer data for rendering experiment tables
     * @property {Immutable.Map} Layer optional - layer associated with an experiment
     *                                 table row. If undefined, the layer is still
     *                                 loading.
     * @property {Immutable.Map} LiveTag optional - live tag associated with an
     *                                   experiment table row. If null, the live
     *                                   tag is still loading. If undefined, the
     *                                   live tag doesn't exist.
     * @property {string} name - name of the experiment
     * @property {string} description - description of the experiment
     * @property {string} type - type of the experiment
     * @property {string} last_modified - ISO date string of entity's last modified date
     * @property {string} status - status string of the entity
     * @property {string} unique_id - a unique ID for entity that can be used as a React key
     */
    return Immutable.Map({
      layer,
      liveTag,
      name: layer.get('name'),
      description: layer.get('description'),
      type: layer.get('type'),
      last_modified: layer.get('last_modified'),
      status,
      unique_id: layer.get('id'),
    });
  };

  getEmptyStateImage = () => {
    const { activeTab, defaultEmptyStateImage } = this.props;

    switch (activeTab) {
      case dashboardTabs.EXPERIMENTS:
        return V2MigrationFns.getV2FirstRunExperimentsImage();
      case dashboardTabs.PERSONALIZATIONS:
        return V2MigrationFns.getV2FirstRunP13nCampaignImage();
      default:
        return defaultEmptyStateImage;
    }
  };

  getEmptyStateText = () => {
    const { activeTab, defaultEmptyStateText } = this.props;

    switch (activeTab) {
      case dashboardTabs.EXPERIMENTS:
        return V2MigrationFns.getV2FirstRunExperimentsText();
      case dashboardTabs.PERSONALIZATIONS:
        return V2MigrationFns.getV2FirstRunP13nCampaignText();
      default:
        return defaultEmptyStateText;
    }
  };

  render() {
    const {
      activeTab,
      isSearchLoading,
      isLegacyDashboardLoading,
      layers,
      currentProject,
      currentSearchResults,
      changeSearchPage,
      currentSearchOptions,
      changeSearchFilters,
      changeTypeFilter,
      changeSearchSort,
      changeSearchQuery,
      entityTypes,
      submitSearchQuery,
      totalSearchPages,
      searchApiStatus,
      columnsVisibility,
      typeFilterOptions,
      defaultColumnsVisibility,
    } = this.props;
    const { isReloadingSearchResults, fieldsParams } = this.state;
    const shouldRenderLegacyTable = this.shouldRenderLegacyTable();

    if (shouldRenderLegacyTable) {
      const dashboardLayersForProject = layers
        .filter(layer => layer.get('project_id') === currentProject.get('id'))
        .toList()
        .map(this.getDashboardLayerForLayer);

      return (
        <LegacyExperimentTable
          dashboardLayers={dashboardLayersForProject}
          isTableLoading={isLegacyDashboardLoading}
          searchApiStatus={searchApiStatus}
          v2FirstRunImage={this.getEmptyStateImage()}
          v2FirstRunText={this.getEmptyStateText()}
          typeFilterAvailableOptions={this.isM1P13NEnabled && typeFilterOptions}
        />
      );
    }

    const columns = this.useCustomTable
      ? fieldsParams || columnsVisibility
      : defaultColumnsVisibility;

    return (
      <SearchExperimentTable
        useCustomTable={this.useCustomTable}
        dashboardLayers={toImmutable(
          currentSearchResults.map(this.getDashboardLayerForSearchResult),
        )}
        columnsVisibility={columns}
        isTableLoading={isSearchLoading && !isReloadingSearchResults}
        currentSearchOptions={currentSearchOptions}
        totalPages={totalSearchPages}
        changePage={(...args) =>
          changeSearchPage(...args).then(this.hydrateSearchResults)
        }
        changeFilters={(...args) =>
          changeSearchFilters(...args).then(this.hydrateSearchResults)
        }
        changeTypeFilter={(...args) =>
          changeTypeFilter(...args).then(this.hydrateSearchResults)
        }
        changeSort={(...args) =>
          changeSearchSort(...args).then(this.hydrateSearchResults)
        }
        changeQuery={(...args) =>
          changeSearchQuery(...args).then(this.hydrateSearchResults)
        }
        entityTypes={entityTypes}
        handleEntityUpdate={this.onEntityUpdate}
        submitQuery={(...args) =>
          submitSearchQuery(...args).then(this.hydrateSearchResults)
        }
        searchApiStatus={searchApiStatus}
        tab={activeTab}
        typeFilterAvailableOptions={this.isM1P13NEnabled && typeFilterOptions}
        v2FirstRunImage={this.getEmptyStateImage()}
        v2FirstRunText={this.getEmptyStateText()}
      />
    );
  }
}

export default LayerTableDataWrapper;

import { Store } from 'nuclear-js';

import { toImmutable } from 'optly/immutable';
import { DEFAULT_LOCALE as defaultLocale } from 'optly/utils/constants';

import actionTypes from '../action_types';

const DEFAULT_ACCOUNT_INFO = {
  accountId: null,
  backendApiToken: null,
  email: null,
  uniqueUserId: null,
  experimentsTotal: 0,
  featureConfigs: toImmutable({}),
  featureLimits: {},
  firstName: null,
  lastName: null,
  maxProjects: -1,
  userLocale: defaultLocale,
  userProfileImage: null,
  isSignedIn: false,
  isAdmin: false,
  permissions: {
    userPermissions: [],
    accountPermissions: [],
  },
  userAccounts: null,
};

/**
 * accountStore
 * Responsible for the following state management:
 * TODO: fill in
 */
export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable(DEFAULT_ACCOUNT_INFO);
  },

  initialize() {
    this.on(actionTypes.ACCOUNT_INFO_LOADED, loadAccountInfo);
    this.on(actionTypes.ACCOUNT_UPDATE_IS_ADMIN, updateIsAdmin);
  },
});

/**
 * Caches the request
 *
 * @param {Immutable.Map} state
 * @param {object} payload
 */
function loadAccountInfo(state, payload) {
  return state.withMutations(currentState => {
    const { subscription } = payload.accountInfo;
    let productIds = [];
    if (subscription && subscription.product_ids) {
      productIds = subscription.product_ids;
    }
    let trialDaysLeft = null;
    let currency = null;
    let isOrWasTrialTrialSubscription = false;
    if (subscription) {
      isOrWasTrialTrialSubscription =
        subscription.in_trial || subscription.status === 'expired_trial';
      // free enterprise trial x does not expire so do not show trial days left
      const isEnterpriseTrialX =
        subscription.plan &&
        subscription.plan.plan_id === 'free_enterprise_trial_x';
      if (subscription.in_trial && !isEnterpriseTrialX) {
        trialDaysLeft = subscription.free_trial_days_remaining;
      } else if (subscription.status === 'expired_trial') {
        trialDaysLeft = 0;
      }
      ({ currency } = subscription);
    }
    let turnstileInstanceId = null;
    if (subscription && subscription.turnstile_instance_id) {
      turnstileInstanceId = subscription.turnstile_instance_id;
    }

    let crmId = null;
    if (subscription && subscription.crm_id) {
      crmId = subscription.crm_id;
    }

    let enableOptiIdUserManagement = false;
    if (subscription && subscription.enable_opti_id_user_management) {
      enableOptiIdUserManagement = subscription.enable_opti_id_user_management;
    }

    return currentState
      .set('accountId', payload.accountInfo.account_id) // Account - Admin Account Id
      .set(
        'accountAdminCenterRoleId',
        payload.accountInfo.account_admin_center_role_id,
      ) // User (current user's account level Admin Center role id)
      .set('allowsSso', payload.accountInfo.allows_sso) // Whether account has SSO feature
      .set('backendApiToken', payload.accountInfo.backend_api_token) // Account level
      .set('companyName', payload.accountInfo.company_name) // Display name of the organization
      .set('email', payload.accountInfo.email) // User (current user's email)
      .set('uniqueUserId', payload.accountInfo.unique_user_id) // User (current user's unique_user_id)
      .set('frozen', payload.accountInfo.frozen) // Account level
      .set('isFreezable', payload.accountInfo.is_freezable) // Account level
      .set('featureConfigs', toImmutable(payload.accountInfo.feature_configs)) // Account level
      .set('featureLimits', payload.accountInfo.feature_limits) // Account level
      .set('firstName', payload.accountInfo.first_name) // User (current user's first name)
      .set('lastName', payload.accountInfo.last_name) // User (current user's last name)
      .set('maxProjects', payload.accountInfo.max_projects) // Account level
      .set('userLocale', payload.accountInfo.user_locale) // User (current user's)
      .set('userProfileImage', payload.accountInfo.user_profile_image) // User (current user's)
      .set('isAdmin', payload.accountInfo.is_admin) // User level (GAE Admin)
      .set('isMasterAccount', payload.accountInfo.is_master_account) // ?
      .set('isUserAdmin', payload.accountInfo.is_user_admin) // Is user an admin on current account
      .set('planId', payload.accountInfo.plan_id) // Account level
      .set('planDisplayName', payload.accountInfo.plan_display_name) // Plan display name
      .set('userAccounts', toImmutable(payload.accountInfo.user_accounts)) // User level (used for account switcher)
      .set('isSignedIn', !!payload.accountInfo.account_id) // User/Session (unnecessary)
      .set('permissions', payload.accountInfo.permissions)
      .set('currency', currency) // Currency code
      .set('isTrialSubscription', isOrWasTrialTrialSubscription) // Is the subscription trial
      .set('isSubscribed', payload.accountInfo.subscribed) // Status is active or trial
      .set('trialDaysLeft', trialDaysLeft) // # of trial days left (null==not trial)
      .set('productIds', productIds) // Product Ids
      .set('crmId', crmId)
      .set('turnstileInstanceId', turnstileInstanceId) // # Turnstile provisioned instance id
      .set('enableOptiIdUserManagement', enableOptiIdUserManagement); // Determines whether or not an account should use OptiID user management
  });
}

function updateIsAdmin(state, payload) {
  return state.set('isAdmin', payload.isAdmin);
}

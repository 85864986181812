import React from 'react';
import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';

import PropTypes from 'prop-types';

import { Button, Input, Sheet, Icon } from 'optimizely-oui';

import { withTrack } from '@optimizely/segment-js/dist/decorators';

import ui from 'core/ui';
import Immutable from 'optly/immutable';
import { connect } from 'core/ui/decorators';

import LoadingOverlay from 'react_components/loading_overlay';
import EntitySelector from 'react_components/entity_selector';

import PermissionsGetters from 'optly/modules/permissions/getters';

import EditEventTagsDialog from 'bundles/p13n/sections/implementation/components/recommendations/dialogs/edit_event_tags_dialog';
import AssignEventTagsPicker from 'bundles/p13n/sections/implementation/components/recommendations/pickers/assign_event_tags_picker';
import CatalogDetails from 'bundles/p13n/sections/implementation/components/recommendations/entity_details';
import { RecommendationsHelpLink } from 'bundles/p13n/components/messaging/recommendations';

import SectionModuleActions from 'bundles/p13n/sections/implementation/section_module/actions';
import SectionModuleConstants from 'bundles/p13n/sections/implementation/section_module/constants';
import SectionModuleFns from 'bundles/p13n/sections/implementation/section_module/fns';
import SectionModuleGetters from 'bundles/p13n/sections/implementation/section_module/getters';

const safeTrim = str => (str || '').trim();

@connect({
  canManageRecommendations: PermissionsGetters.canManageRecommendations,
  catalog: SectionModuleGetters.currentlyEditingCatalog,
  selectedEvents: SectionModuleGetters.selectedEvents,
  selectedIdTagName: SectionModuleGetters.selectedIdTagName,
  selectedUrlTagName: SectionModuleGetters.selectedUrlTagName,
  tagChoices: SectionModuleGetters.availableTagChoices,
  unselectedEvents: SectionModuleGetters.unselectedEvents,
})
@withTrack
class CatalogDialog extends React.Component {
  static componentId = 'CatalogDialog';

  static propTypes = {
    canManageRecommendations: PropTypes.bool.isRequired,
    catalog: PropTypes.instanceOf(Immutable.Map).isRequired,
    selectedEvents: PropTypes.instanceOf(Immutable.List).isRequired,
    selectedIdTagName: PropTypes.string,
    selectedUrlTagName: PropTypes.string.isRequired,
    tagChoices: PropTypes.instanceOf(Immutable.List).isRequired,
    // Function handler for Segment Tracking
    track: PropTypes.func,
    unselectedEvents: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  static defaultProps = {
    track: () => {},
  };

  constructor(props) {
    super(props);
    const { catalog, track } = props;

    let maxValidationRate = catalog.getIn([
      'run_parameters',
      'max_validation_rate',
    ]);
    if (maxValidationRate === null || maxValidationRate === undefined) {
      maxValidationRate = SectionModuleConstants.DEFAULT_MAX_VALIDATION_RATE;
    }

    this.state = {
      isLoading: false,
      isNameValid: true,
      maxValidationRate,
    };

    this.isCreating = !catalog.get('id');

    if (this.isCreating) {
      track('Recommendations Create New Catalog Dialog Opened');
    } else {
      track('Recommendations Edit Existing Catalog Dialog Opened');
    }
  }

  addEvent = (event, item) => {
    const eventUsedForCatalog = item.set('for_catalog', true);
    SectionModuleActions.updateEvent(eventUsedForCatalog);
  };

  editEvent = (event, item) => {
    SectionModuleActions.setCurrentlyEditingEvent(item);
    ui.showReactDialog(EditEventTagsDialog, null, {
      fullScreen: true,
      isOuiDialog: true,
    });
  };

  removeEvent = (event, item) => {
    SectionModuleActions.removeEvent(item);
  };

  /**
   * Coerce the users input to a 0-100 number with single decimal point precision.
   * @param value
   */
  ensureProperMaxValidationRateFormat = value => {
    const { catalog } = this.props;

    let inputValue = value;
    // If input value includes the scientific constant 'e', coerce it to 0.
    if (inputValue.includes('e')) {
      inputValue = 0;
    }

    // Ensure the number is an integer greater than or equal to 0. If it's negative, round up to 0.
    const roundToWholeInteger = Number(inputValue).toFixed();
    const maxValidationRate = Math.max(Number(roundToWholeInteger), 0);

    this.setState({ maxValidationRate });
    const updatedRunParameters = catalog
      .get('run_parameters')
      .set('max_validation_rate', maxValidationRate);
    this.updateCatalogProperty('run_parameters', updatedRunParameters);
  };

  onMaxValidationRateChange = value => {
    this.setState({ maxValidationRate: value });
  };

  /**
   * Function handler to update any property of catalog with new value.
   *
   * @param {String} property any property of catalog to be updated (name, description, etc.)
   * @param {*} inputValue new value to update the property of catalog.
   */
  updateCatalogProperty = (property, inputValue) => {
    if (property === 'name') {
      this.setState({ isNameValid: true });
    }

    SectionModuleActions.updateProperty(property, inputValue);
  };

  /**
   * Returns true if catalog's name is valid, false otherwise.
   *
   * @returns {Boolean} whether or not catalog's name is valid.
   */
  validateName = () => {
    const { catalog } = this.props;
    const nameLength = safeTrim(catalog.get('name')).length;
    const isNameValid = nameLength > 0 && nameLength < 1500;

    this.setState({ isNameValid });

    return isNameValid;
  };

  canSave = () => {
    const { selectedIdTagName } = this.props;

    return this.validateName() && !!selectedIdTagName;
  };

  saveAction = () => {
    if (!this.canSave()) {
      return;
    }

    const { track } = this.props;

    const saveErrorMessage = this.isCreating
      ? 'Unable to create catalog.'
      : 'Unable to save catalog.';
    const saveSuccessMessage = this.isCreating
      ? 'Catalog created successfully.'
      : 'Catalog saved successfully.';

    this.setState({ isLoading: true });

    return SectionModuleActions.saveSettings(this.isCreating)
      .then(savedCatalog => {
        const trackCatalogData = {
          catalogID: savedCatalog.id,
          maximumIndexingRate: savedCatalog.run_parameters.max_validation_rate,
          projectSpecifics: savedCatalog.project_specifics,
        };

        if (this.isCreating) {
          track('Recommendations Catalog Created', trackCatalogData);
        } else {
          track('Recommendations Catalog Edited', trackCatalogData);
        }

        ui.showNotification({
          message: saveSuccessMessage,
          type: 'success',
        });

        ui.hideDialog();
      })
      .fail(() => {
        ui.showNotification({
          message: saveErrorMessage,
          type: 'error',
        });
      })
      .always(() => this.setState({ isLoading: false }));
  };

  renderErrorMessage = (errorMessage, testSection) => (
    <div
      className="lego-form-note lego-form-note--bad-news"
      data-test-section={testSection}>
      {errorMessage}
    </div>
  );

  renderCatalogEventsPicker = () => {
    const { selectedEvents, unselectedEvents } = this.props;

    return (
      <div
        className="push-triple--bottom"
        data-test-section="catalog-events-picker">
        <h4>Catalog Events</h4>
        <div
          className="push-double--bottom"
          data-test-section="catalog-events-picker-description">
          Add the events you want to include with this catalog then add the
          relevant event tags for each event.&nbsp;
          <RecommendationsHelpLink
            helpLink={SectionModuleFns.getHelpCopy('catalog_events_link')}
            testSection="catalog-dialog-catalog-events"
          />
        </div>
        <EntitySelector
          inputPlaceholder="Search and add events to this catalog"
          isFullWidth={true}
          onEntitySelected={this.addEvent}
          onEntityUnselected={this.removeEvent}
          onSelectedSecondaryClick={this.editEvent}
          selectedItems={selectedEvents}
          selectedSecondaryText="Edit event tags"
          unselectedItems={unselectedEvents}
        />
      </div>
    );
  };

  renderIndexingRateSection = () => {
    const { maxValidationRate } = this.state;

    return (
      <div
        className="push-triple--bottom"
        data-test-section="catalog-indexing-rate">
        <h4>Indexing Rate</h4>
        <div
          className="push--bottom"
          data-test-section="catalog-indexing-rate-description">
          {`Set the maximum rate for catalog indexing. The default is ${SectionModuleConstants.DEFAULT_MAX_VALIDATION_RATE.toLocaleString()} times per minute.`}
        </div>
        <div className="flex flex-align--center push-double--bottom">
          <div className="push--right width--75">
            <Input
              min={0}
              onBlur={event =>
                this.ensureProperMaxValidationRateFormat(event.target.value)
              }
              onChange={event =>
                this.onMaxValidationRateChange(event.target.value)
              }
              testSection="catalog-max-indexing-rate-input"
              textAlign="right"
              type="number"
              value={maxValidationRate}
            />
          </div>
          <span data-test-section="catalog-max-indexing-rate-input-description-text">
            times per minute allowed
          </span>
        </div>
        <div className="flex">
          <div className="push-half--top">
            <Icon name="circle-exclamation" size="small" />
          </div>
          <div
            className="flex--1 push--left"
            data-test-section="catalog-max-indexing-rate-explaination-text">
            Catalog items are verified daily to ensure they can be served. If a
            page is missing, errors, or returns a 404 error, it will be removed
            from the catalog and shown as a validation error. It's recommended
            you whitelist the user-agent "Optimizely Recommender".&nbsp;
            <RecommendationsHelpLink
              helpLink={SectionModuleFns.getHelpCopy(
                'max_validation_rate_link',
              )}
              testSection="catalog-dialog-indexing-rate"
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      canManageRecommendations,
      catalog,
      selectedIdTagName,
      selectedUrlTagName,
      tagChoices,
    } = this.props;
    const { isLoading, isNameValid } = this.state;

    const dialogTitle = this.isCreating ? 'New Catalog' : 'Edit Catalog';
    const saveButtonLabel = this.isCreating ? 'Create Catalog' : 'Save Catalog';

    return (
      <Sheet
        footerButtonList={[
          <Button
            key="cancel"
            onClick={ui.hideDialog}
            style="plain"
            testSection="catalog-dialog-cancel-button">
            Cancel
          </Button>,
          <Button
            isDisabled={!canManageRecommendations}
            key="save"
            onClick={this.saveAction}
            style="highlight"
            testSection="catalog-dialog-create-save-button">
            {saveButtonLabel}
          </Button>,
        ]}
        onClose={ui.hideDialog}
        subtitle={
          <React.Fragment>
            Catalogs contain your items and related events. Your recommenders
            generate recommendations from catalog information.&nbsp;
            <RecommendationsHelpLink
              helpLink={SectionModuleFns.getHelpCopy('setup_catalog_link')}
              testSection="catalog-dialog-subtitle"
            />
          </React.Fragment>
        }
        title={dialogTitle}
        warningContent={
          !canManageRecommendations &&
          SectionModuleConstants.EDIT_WARNING_TEXTS.CATALOG.message
        }
        warningTestSection={
          SectionModuleConstants.EDIT_WARNING_TEXTS.CATALOG.testSection
        }>
        <LoadingOverlay isLoading={isLoading}>
          <CatalogDetails
            entity={catalog}
            entityInfo={SectionModuleConstants.EntityInfo.CATALOG}
            renderErrorMessage={this.renderErrorMessage}
            showValidationError={!isNameValid}
            updateEntityProperty={this.updateCatalogProperty}
          />
          {this.renderCatalogEventsPicker()}
          <AssignEventTagsPicker
            selectedIdTagName={selectedIdTagName}
            selectedUrlTagName={selectedUrlTagName}
            tagChoices={tagChoices}
            updateCatalogProperty={this.updateCatalogProperty}
          />
          {this.renderIndexingRateSection()}
        </LoadingOverlay>
      </Sheet>
    );
  }
}

export default CatalogDialog;

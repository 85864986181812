/**
 * Vue store keeps track of all state of Vue rootVM and Vue primitives
 * that can be globally registered (components / directives / filters / partials / ettects)
 */
import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Nuclear.Store({
  getInitialState() {
    return toImmutable({
      rootVM: null,
      globalComponents: {},
    });
  },

  initialize() {
    this.on(actionTypes.REGISTER_GLOBAL_COMPONENT, registerGlobalComponent);
    this.on(actionTypes.REGISTER_ROOT_VM, registerRootVM);
    this.on(actionTypes.RESET_ROOT_VM, resetRootVM);
  },
});

/**
 * Registers reference to a global component instance
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.id
 * @param {Vue} payload.instance
 */
function registerGlobalComponent(state, payload) {
  return state.setIn(['globalComponents', payload.id], payload.instance);
}

/**
 * Holds reference to the root Vue ViewModel
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {string} payload.vm
 */
function registerRootVM(state, payload) {
  return state.set('rootVM', payload.vm);
}

/**
 * @param {Immutable.Map} state
 */
function resetRootVM(state) {
  return state.set('rootVM', null).set('globalComponents', toImmutable({}));
}

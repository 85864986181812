/**
 * @author Tyler Brandt (tyler@optimizely.com)
 */
const tr = require('optly/translate');
/**
 * Format a floating point as a percentage with optional precision specified
 * @param {number} value
 * @param {number=} maxPrecision
 * @returns {string}
 */
module.exports = function(value, maxPrecision) {
  if (maxPrecision !== undefined) {
    maxPrecision = Number(maxPrecision);
  } else {
    maxPrecision = 0;
  }

  // remove trailing spaces
  return `${tr.number(value * 100, maxPrecision, true)}%`;
};

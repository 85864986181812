/**
 * Module specific pure functions
 */
import ProjectFns from 'optly/modules/entity/project/fns';

import enums from './enums';

/*
 * Return the title of the new platform. Depending on what features
 * the user has enabled, return the most appropriate text.
 * @param {Boolean} canUsePersonalization
 * @param {Boolean} canUseABTestingV2
 * @return {String}
 */
export function getV2PlatformText(canUsePersonalization, canUseABTestingV2) {
  if (canUsePersonalization && canUseABTestingV2) {
    return enums.V2_PLATFORM_TEXT__AB_AND_P13N;
  }

  if (canUsePersonalization) {
    return enums.V2_PLATFORM_TEXT__P13N_ONLY;
  }

  if (canUseABTestingV2) {
    return enums.V2_PLATFORM_TEXT__AB_ONLY;
  }

  return enums.V2_PLATFORM_TEXT__AB_AND_P13N;
}

/*
 * Return the title of the old platform. Depending on what features
 * the user has enabled, return the most appropriate text.
 * @param {Boolean} canUsePersonalization
 * @param {Boolean} canUseABTestingV2
 * @return {String}
 */
export function getV1PlatformText(canUsePersonalization, canUseABTestingV2) {
  if (canUsePersonalization && canUseABTestingV2) {
    return enums.V1_PLATFORM_TEXT__AB_AND_P13N;
  }

  if (canUsePersonalization) {
    return enums.V1_PLATFORM_TEXT__P13N_ONLY;
  }

  if (canUseABTestingV2) {
    return enums.V1_PLATFORM_TEXT__AB_ONLY;
  }

  return enums.V1_PLATFORM_TEXT__AB_AND_P13N;
}

/*
 * Return the text for the first run experience. Depending on what features
 * the user has enabled, return the most appropriate text.
 * @param {Boolean} canUsePersonalization
 * @param {Boolean} canUseABTestingV2
 * @return {Object}
 *         { title: String, subtitle: String }
 */
export function getV2FirstRunText(
  canUsePersonalization,
  canUseABTestingV2,
  currentProject,
) {
  if (ProjectFns.isWebEdgeProject(currentProject)) {
    return {
      title: enums.V2_FIRST_RUN_TITLE__EDGE,
      subtitle: enums.V2_FIRST_RUN_SUBTITLE__EDGE,
    };
  }

  if (canUsePersonalization && canUseABTestingV2) {
    return {
      title: enums.V2_FIRST_RUN_TITLE__AB_AND_P13N,
      subtitle: enums.V2_FIRST_RUN_SUBTITLE__AB_AND_P13N,
    };
  }

  if (canUsePersonalization) {
    return {
      title: enums.V2_FIRST_RUN_TITLE__P13N_ONLY,
      subtitle: enums.V2_FIRST_RUN_SUBTITLE__P13N_ONLY,
    };
  }

  if (canUseABTestingV2) {
    return {
      title: enums.V2_FIRST_RUN_TITLE__AB_ONLY,
      subtitle: enums.V2_FIRST_RUN_SUBTITLE__AB_ONLY,
    };
  }

  return {
    title: enums.V2_FIRST_RUN_TITLE__AB_AND_P13N,
    subtitle: enums.V2_FIRST_RUN_SUBTITLE__AB_AND_P13N,
  };
}

/*
 * Return the image for the first run experience. Depending on what features
 * the user has enabled, return the most appropriate image.
 * @param {Boolean} canUsePersonalization
 * @param {Boolean} canUseABTestingV2
 * @return {String}
 */
export function getV2FirstRunImage(
  canUsePersonalization,
  canUseABTestingV2,
  currentProject,
) {
  if (ProjectFns.isWebEdgeProject(currentProject)) {
    return enums.V2_FIRST_RUN_IMAGE__EDGE;
  }

  if (canUsePersonalization && canUseABTestingV2) {
    return enums.V2_FIRST_RUN_IMAGE__AB_AND_P13N;
  }

  if (canUsePersonalization) {
    return enums.V2_FIRST_RUN_IMAGE__P13N_ONLY;
  }

  if (canUseABTestingV2) {
    return enums.V2_FIRST_RUN_IMAGE__AB_ONLY;
  }

  return enums.V2_FIRST_RUN_IMAGE__AB_AND_P13N;
}

/*
 * Return the text for the bundled snippet, as seen in Settings > Implementation (sidebar and tab)
 * @param {Boolean} canUsePersonalization
 * @param {Boolean} canUseABTestingV2
 * @return {Object}
 *         { identifier: String, label: String, description: String }
 */
export function getBundledSnippetText(
  canUsePersonalization,
  canUseABTestingV2,
) {
  if (canUsePersonalization && !canUseABTestingV2) {
    return {
      identifier: enums.BUNDLED_SNIPPET_IDENTIFIER__P13N_ONLY,
      label: enums.BUNDLED_SNIPPET_LABEL__P13N_ONLY,
      description: enums.BUNDLED_SNIPPET_DESCRIPTION__P13N_ONLY,
    };
  }

  return {
    identifier: enums.BUNDLED_SNIPPET_IDENTIFIER__AB_AND_P13N,
    label: enums.BUNDLED_SNIPPET_LABEL__AB_AND_P13N,
    description: enums.BUNDLED_SNIPPET_DESCRIPTION__AB_AND_P13N,
  };
}

/*
 * Return the text for the standalone snippet, as seen in Settings > Implementation (sidebar and tab)
 * @param {Boolean} canUsePersonalization
 * @param {Boolean} canUseABTestingV2
 * @return {Object}
 *         { identifier: String, label: String, description: String }
 */
export function getStandaloneSnippetText(
  canUsePersonalization,
  canUseABTestingV2,
) {
  if (canUsePersonalization && !canUseABTestingV2) {
    return {
      identifier: enums.STANDALONE_SNIPPET_IDENTIFIER__P13N_ONLY,
      label: enums.STANDALONE_SNIPPET_LABEL__P13N_ONLY,
      description: enums.STANDALONE_SNIPPET_DESCRIPTION__P13N_ONLY,
    };
  }

  return {
    identifier: enums.BUNDLED_SNIPPET_IDENTIFIER__AB_AND_P13N,
    label: enums.STANDALONE_SNIPPET_LABEL__AB_AND_P13N,
    description: enums.STANDALONE_SNIPPET_DESCRIPTION__AB_AND_P13N,
  };
}

export function getV2FirstRunExperimentsText() {
  return {
    title: enums.V2_FIRST_RUN_TITLE__EXPERIMENTS,
    subtitle: enums.V2_FIRST_RUN_SUBTITLE__EXPERIMENTS,
  };
}

export function getV2FirstRunExperimentsImage() {
  return enums.V2_FIRST_RUN_IMAGE__EXPERIMENTS;
}

export function getV2FirstRunP13nCampaignText() {
  return {
    title: enums.V2_FIRST_RUN_TITLE__P13N_CAMPAIGNS,
    subtitle: enums.V2_FIRST_RUN_SUBTITLE__P13N_CAMPAIGNS,
  };
}

export function getV2FirstRunP13nCampaignImage() {
  return enums.V2_FIRST_RUN_IMAGE__P13N_CAMPAIGNS;
}

export default {
  getV2PlatformText,
  getV1PlatformText,
  getV2FirstRunText,
  getV2FirstRunImage,
  getBundledSnippetText,
  getStandaloneSnippetText,
  getV2FirstRunExperimentsText,
  getV2FirstRunExperimentsImage,
  getV2FirstRunP13nCampaignText,
  getV2FirstRunP13nCampaignImage,
};

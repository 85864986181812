import _ from 'lodash';
import * as LayerConstants from 'optly/modules/entity/layer/constants';

export const formatLayerHoldbackForInput = layerHoldback =>
  Number(
    !_.isNull(layerHoldback)
      ? layerHoldback / 100 || 0
      : LayerConstants.DefaultHoldbackValues.P13N_CAMPAIGN,
  ).toFixed(2);

export default {
  formatLayerHoldbackForInput,
};

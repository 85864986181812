import { unescape } from 'lodash';
import atomicConfig from 'atomic-config';

import router from 'core/router';
import ui from 'core/ui';
import flux from 'core/flux';
import RestApiActionTypes from 'optly/modules/rest_api/action_types';
import AccountEntityDef from 'optly/modules/entity/account/entity_definition';
import { actions as AdminAccountActions } from 'optly/modules/admin_account';
import { actions as CurrentProjectActions } from 'optly/modules/current_project';
import UserEntityDef from 'optly/modules/entity/user/entity_definition';
import OptlyTesting from 'optly/optly_testing';

if (__INVARIANT__) {
  // eslint-disable-next-line global-require
  window.assertType = require('./assert_type');
}

const FIELDS_TO_UNESCAPE = ['project_name'];

/**
 * Main entry point into the Sandbox
 */
export default {
  /**
   * Loads the initial state of the application into the
   * flux system
   *
   * This is needed to ensure that things like account info and
   * current project into the flux system so subsequent code can be used
   * @param {Object} overrides
   * @param {Object} overrides.accountInfo
   * @param {Object} overrides.adminAccount
   * @param {Object} [overrides.currentUser]
   * @param {Object} overrides.currentProject
   * @return {Deferred}
   */
  loadInitialData(overrides) {
    // NOTE: this isn't this ideal place to expose this optlyTesting interface
    // however its the most DRY place, otherwise it'd have to go in every bundle/*/main.js file
    window.optlyTesting = OptlyTesting;

    const BASE_CONFIG = {
      accountInfo: atomicConfig.get('account_info'),
      adminAccount: atomicConfig.get('admin_account'),
      currentProject: atomicConfig.get('current_project'),
      currentUser: atomicConfig.get('current_user'),
    };

    const config = Object.assign(BASE_CONFIG, overrides);

    if (config.adminAccount) {
      flux.dispatch(RestApiActionTypes.API_INJECT_ENTITY, {
        entity: AccountEntityDef.entity,
        data: config.adminAccount,
      });
    }

    if (config.accountInfo) {
      AdminAccountActions.loadAccountInfo(config.accountInfo);
    }

    if (config.currentProject) {
      FIELDS_TO_UNESCAPE.forEach(field => {
        if (config.currentProject[field]) {
          config.currentProject[field] = unescape(config.currentProject[field]);
        }
      });

      CurrentProjectActions.setCurrentProject(config.currentProject);
    }

    if (config.currentUser) {
      flux.dispatch(RestApiActionTypes.API_INJECT_ENTITY, {
        entity: UserEntityDef.entity,
        data: [config.currentUser],
      });
    }
  },

  /**
   * Function to reset the state of the app
   * Mainly used for testing
   * @param {Object} [opts]
   */
  reset(opts) {
    router.reset();
    ui.reset((opts || {}).keepRootVM);
    flux.reset();
  },
};

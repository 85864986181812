import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { OverlayWrapper, Popover, Icon } from 'optimizely-oui';

import Immutable from 'optly/immutable';
import { formPropType } from 'react_components/form';
import {
  DEFAULT_VARIABLE,
  FEATURE_VARIABLE_TYPES,
} from 'optly/modules/entity/feature_flag/constants';

import VariablesActionsCell from '../variable_actions_cell';
import VariablesKeyCell from '../variable_key_cell';
import VariablesTypeCell from '../variable_type_cell';
import VariablesValueCell from '../variable_value_cell';

/**
 * VariablesEditTable
 * @description This table is for editing features variable. It will currently be used on the feature-variables page
 *  and the create-new-feature modal for targeted-rollouts
 * @kind component
 * @example
 *  <VariablesEditTable
 *    variablesField={form.repeatedField('variables')}
 *    readOnly={false}
 *  />
 */
const VariablesEditTable = ({ isDisabled, variablesField, readOnly }) => {
  useEffect(() => {
    const invalidErrorMessage = (
      <div className="flex flex-align--center color--red">
        <div className="push-half--right soft-half--bottom">
          Please enter a valid key.
        </div>
        <OverlayWrapper
          behavior="hover"
          horizontalAttachment="center"
          verticalAttachment="top"
          overlay={
            <Popover>
              Valid keys contain alphanumeric characters, hyphens, and
              underscores, and are limited to 64 characters.
            </Popover>
          }>
          <Icon name="circle-exclamation" size="small" />
        </OverlayWrapper>
      </div>
    );

    variablesField.setupRepeatedValidators({
      api_name: {
        isRequired: (value, formState, index) => {
          const variableField = formState.getIn(['variables', index]);
          return !variableField.get('archived') &&
            !Immutable.is(variableField, DEFAULT_VARIABLE) &&
            !value
            ? invalidErrorMessage
            : '';
        },
      },
    });
  }, []);

  const renderRow = (variableField, index, activeVariablesField) => {
    if (variableField.getValue().get('archived')) {
      return;
    }
    const variableId = variableField.getValue().get('id');
    const indexTestSection = variableId || `index-${index}`;

    const isDefault = Immutable.is(variableField.getValue(), DEFAULT_VARIABLE);
    const activeVariablesCount = activeVariablesField.size;
    const hasAtLeastThreeVariablesAndIsLastVariable =
      activeVariablesCount >= 3 && activeVariablesCount - 1 === index;
    const showAddButton = activeVariablesCount - 1 === index && !readOnly;
    const showRemoveButton =
      (activeVariablesCount > 1 || !isDefault) && !readOnly;
    const isVariableTypeUndefined =
      variableField.getValue().get('type') === undefined;
    const defaultValue = variableField.getValue().get('default_value');

    const variablesTypeCell = (
      <VariablesTypeCell
        readOnly={readOnly}
        isDisabled={!!variableField.getValue().get('id') || isDisabled}
        isLastVariable={hasAtLeastThreeVariablesAndIsLastVariable}
        defaultValueField={variableField.field('default_value')}
        showLabel={true}
        typeField={variableField.field('type')}
        testSection={`variable-table-type-cell-${indexTestSection}`}
      />
    );

    const variablesKeyCell = (
      <VariablesKeyCell
        isDisabled={isDisabled}
        readOnly={readOnly}
        showLabel={true}
        variableField={variableField}
        testSection={`variable-table-key-cell-${indexTestSection}`}
      />
    );

    const variablesValueCell = (
      <VariablesValueCell
        isDisabled={isDisabled}
        readOnly={readOnly}
        showLabel={true}
        typeField={variableField.field('type')}
        valueField={variableField.field('default_value') || defaultValue}
        testSection={`variable-table-value-cell-${indexTestSection}`}
      />
    );

    const renderVariableRow = () => {
      if (
        variableField.getValue().get('type') === FEATURE_VARIABLE_TYPES.json
      ) {
        return (
          <>
            <div className="flex">
              <div className="push-double--right">{variablesTypeCell}</div>
              {!isVariableTypeUndefined && (
                <div className="flex--1">{variablesKeyCell}</div>
              )}
            </div>
            {!isVariableTypeUndefined && (
              <div className="push--top">{variablesValueCell}</div>
            )}
          </>
        );
      }

      return (
        <>
          <div className="flex">
            <div className="push-double--right">{variablesTypeCell}</div>
            {!isVariableTypeUndefined && (
              <>
                <div>{variablesKeyCell}</div>
                <div className="flex--1 push-double--left">
                  {variablesValueCell}
                </div>
              </>
            )}
          </div>
        </>
      );
    };

    return (
      <div key={`${variableId}-field-${index}`}>
        <div className="flex" data-test-section="variables-edit-table-row">
          <div className="flex--1">{renderVariableRow()}</div>
          {!isVariableTypeUndefined && (
            <div className="width--75 push--left push-triple--top">
              <VariablesActionsCell
                defaultVariable={DEFAULT_VARIABLE}
                index={index}
                isDisabled={isDisabled}
                showAddButton={showAddButton}
                showRemoveButton={showRemoveButton}
                variableField={variableField}
                variablesField={variablesField}
                testSection={`variable-table-actions-cell-${indexTestSection}`}
              />
            </div>
          )}
        </div>
        {index !== activeVariablesField.size - 1 && (
          <hr className="push-double--ends" />
        )}
      </div>
    );
  };

  const activeVariablesField = variablesField.filterChildren(
    variableField => !variableField.getValue().get('archived'),
  );

  return (
    <div className="push-double--top push-quad--bottom">
      {activeVariablesField.map((variableField, index) =>
        renderRow(variableField, index, activeVariablesField),
      )}
    </div>
  );
};

VariablesEditTable.defaultProps = {
  isDisabled: false,
  readOnly: false,
};

VariablesEditTable.propTypes = {
  isDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  variablesField: formPropType.isRequired,
};

export default VariablesEditTable;

const _ = require('lodash');
const Vue = require('vue');

const flux = require('core/flux');
const ui = require('core/ui').default;

const DependencyManagement = require('bundles/p13n/components/editor/sidebar/dependency_management');
const Editor = require('bundles/p13n/modules/editor');
const ChangeEditorSidebarMixin = require('bundles/p13n/modules/ui/mixins/change_editor_sidebar')
  .default;

const ChangeTimingDropdown = require('../../change_timing_dropdown');
const Template = require('./template.html');

const timingComponent = {
  replace: true,

  template: Template,

  components: {
    'change-timing-dropdown': ChangeTimingDropdown,
  },

  data: {
    includeTimingLabel: false,
    timingDropdownTypes: Editor.constants.TimingDropdownTypes,
  },

  computed: {
    /**
     * Compute a map of all attribute changes and the appropriate classes to use
     * for their current status (dirty, draft, live).
     * @returns {{async: *}}
     */
    statusClassForAttributes() {
      return {
        async: this.getStatusClassForAttribute([this.changeStatusMap.async]),
      };
    },
  },

  methods: {
    /**
     * Sets the async property based on an update from the component. Update
     * includes isAsync property and instanceIdentifer property.
     * @param payload
     */
    handleChangeTimingChanged(payload) {
      Editor.actions.setChangeTiming(payload.isAsync);
    },

    /**
     * Explicitly set the value of the change type input fields. Prevent change if the field is focused
     * as that usually indicates that a user is actively making changes to that field. If we were to
     * overwrite these on element change, we'd be constantly modifying the input as they type
     */
    initializeInputFields() {
      // Allow a cycle to render before updating the selector components so that the v-with property can update.
      Vue.nextTick(() => {
        // Because this fires on nextTick, make sure the component exists before emitting on it.
        this.$.changeTimingDropdown && this.$.changeTimingDropdown.handleUpdate();  // eslint-disable-line
      });
    },

    /**
     * Sets the async property back to what the currentlySavedValue is.
     */
    revertChangeTiming: Editor.actions.revertChangeTiming,
  },

  created() {
    flux.bindVueValues(this, {
      changeStatusMap: Editor.getters.changeStatusMap(),
      currentlyEditingChange: Editor.getters.currentlyEditingChange,
      shouldShowDependencyManagement: Editor.getters.showDependencyManagement,
    });
  },

  ready() {
    this.initializeInputFields();
    // Observe the currentlyEditingChange and update the change input fields when it changes. This is
    // preferred to using v-value because we can explicitly not update the field that is currently being typed in.
    this.__unwatchCurrentlyEditingChange = flux.observe(
      Editor.getters.currentlyEditingChange,
      this.initializeInputFields.bind(this),
    );

    ui.renderReactComponent(this, {
      component: DependencyManagement,
      el: this.$$.dependencyManagement,
      dataBindings: {
        dependsOnPrevious:
          Editor.getters.currentlyEditingChangeDependsOnPrevious,
      },
      props: {
        // Sets the change dependencies property for the currently editing change.
        handleChangeDependencyClick:
          Editor.actions.setCurrentlyEditingChangeDependencies,
      },
    });
  },

  beforeDestroy() {
    if (this.__unwatchCurrentlyEditingChange) {
      this.__unwatchCurrentlyEditingChange();
    }
  },
};

module.exports = _.merge({}, ChangeEditorSidebarMixin, timingComponent);

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

export const {
  features: currentProjectFeatures,
  currentProjectRunningExperiments,
} = CurrentProjectGetters;

// default export
export default {
  currentProjectFeatures,
  currentProjectRunningExperiments,
};

import PropTypes from 'prop-types';
import React from 'react';

import cSharpLogo from '/static/img/x/logos/csharp.svg';

import goLogo from '/static/img/x/logos/go.svg';

import nodeLogo from '/static/img/x/logos/node.svg';

import jsLogo from '/static/img/x/logos/javascript.svg';

import javaLogo from '/static/img/x/logos/java.svg';

import phpLogo from '/static/img/x/logos/php.svg';

import reactLogo from '/static/img/x/logos/react.svg';

import rubyLogo from '/static/img/x/logos/ruby.svg';

import pythonLogo from '/static/img/x/logos/python.svg';

import appleLogo from '/static/img/x/logos/apple.svg';

import androidLogo from '/static/img/x/logos/android.svg';

const GRID_IMAGES = [
  { url: cSharpLogo, alignSelf: 'start', justifySelf: 'start', opacity: 0.3 },
  { url: nodeLogo, alignSelf: 'start', justifySelf: 'center', opacity: 0.5 },
  { url: jsLogo, alignSelf: 'start', justifySelf: 'end', opacity: 0.5 },
  { url: javaLogo, alignSelf: 'center', justifySelf: 'start', opacity: 0.7 },
  { url: phpLogo, alignSelf: 'center', justifySelf: 'center', opacity: 0.3 },
  { url: rubyLogo, alignSelf: 'center', justifySelf: 'end', opacity: 0.3 },
  { url: pythonLogo, alignSelf: 'center', justifySelf: 'start', opacity: 0.5 },
  {
    url: appleLogo,
    alignSelf: 'center',
    justifySelf: 'center',
    coloredForMobileOrOTT: true,
  },
  {
    url: androidLogo,
    alignSelf: 'center',
    justifySelf: 'end',
    coloredForMobileOrOTT: true,
  },
  { url: goLogo, alignSelf: 'end', justifySelf: 'start', opacity: 0.3 },
  { url: reactLogo, alignSelf: 'end', justifySelf: 'center', opacity: 0.3 },
];

function getGridCellStyle(
  { alignSelf, justifySelf, coloredForMobileOrOTT, url, opacity },
  isMobileOrOTTOnlyAccount,
) {
  const style = {
    alignSelf,
    justifySelf,
  };
  if (!coloredForMobileOrOTT && isMobileOrOTTOnlyAccount) {
    style.filter = 'grayscale(1)';
    style.opacity = opacity;
  }
  return style;
}

function ImageGrid({ isMobileOrOTTOnlyAccount }) {
  return (
    <div className="push-quad--right width--150">
      <ul
        className="soft-half--ends"
        style={{
          height: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(3,1fr)',
          gridTemplateRows: 'repeat(4,1fr)',
          gridGap: 0,
        }}>
        {GRID_IMAGES.map(image => (
          <li
            key={image.url}
            style={getGridCellStyle(image, isMobileOrOTTOnlyAccount)}>
            <img src={image.url} style={{ width: '40px' }} alt="" />
          </li>
        ))}
      </ul>
    </div>
  );
}

ImageGrid.propTypes = {
  isMobileOrOTTOnlyAccount: PropTypes.bool,
};

export default ImageGrid;

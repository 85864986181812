const { api: SegmentJSAPI } = require('@optimizely/segment-js');

const sanitizeHtml = require('optly/utils/sanitize_html');
const SpectrumDirective = require('optly/directives/spectrum');

const template = require('./template.html');
const constants = require('./constants');

module.exports = {
  template,

  directives: {
    spectrum: SpectrumDirective,
  },

  constants,

  data: {
    spectrumOptions: JSON.stringify({
      replacerClassName: constants.REPLACER_CLASS_NAME,
    }),
    value: null,
  },

  methods: {
    getValue() {
      return this.value;
    },

    handleValueUpdate() {
      SegmentJSAPI.track('[Element Change] Color Hex Input');
      this.$emit(constants.SPECTRUM_EVENTS.PROPERTIES_CHANGED, {
        color: this.value,
      });
      this.$emit(constants.EVENTS.COLOR_UPDATED, this.value);
    },

    onClickContainer() {
      SegmentJSAPI.track('[Element Change] Color Container');
    },

    _handleColorUpdateRequest(value) {
      if (value) {
        value = sanitizeHtml(value, true);
      }

      this.value = value;
      this.handleValueUpdate();
    },

    _handleSpectrumUpdate(_, value) {
      // Only returning rgba values from here because that's what style properties use and that's
      // where the color picker is currently being used, can be updated to return hex values.
      this.value = sanitizeHtml(
        `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`,
        true,
      );
      this.$emit(constants.EVENTS.COLOR_UPDATED, this.value);
    },
  },

  ready() {
    this.value = sanitizeHtml(
      this.initialValue || constants.DEFAULT_INITIAL_COLOR,
      true,
    );
    this.$on(
      constants.SPECTRUM_EVENTS.UPDATE,
      this._handleSpectrumUpdate.bind(this),
    );
    this.$on(
      constants.EVENTS.SET_COLOR,
      this._handleColorUpdateRequest.bind(this),
    );
    this.$emit(constants.SPECTRUM_EVENTS.PROPERTIES_CHANGED, {
      color: this.initialValue,
    });
  },
};

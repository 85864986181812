import React from 'react';

export default function IconSprite() {
  // any new icon/ svg sprite can be added to this file and ID can used in svg use href.
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
      <symbol width="18" height="18" id="angle-down" viewBox="0 0 640 512">
        <path d="M362.7 203.9l-159.1 144c-6.125 5.469-15.31 5.469-21.44 0L21.29 203.9C14.73 197.1 14.2 187.9 20.1 181.3C26.38 174.4 36.5 174.5 42.73 180.1L192 314.5l149.3-134.4c6.594-5.877 16.69-5.361 22.62 1.188C369.8 187.9 369.3 197.1 362.7 203.9z" />
      </symbol>
      <symbol width="16" height="16" id="caret-down" viewBox="0 0 320 512">
        <path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
      </symbol>
      <symbol width="16" height="16" id="ban" viewBox="0 0 512 512">
        <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM86.7 109.3C52.62 148.6 32 199.9 32 256C32 379.7 132.3 480 256 480C312.1 480 363.4 459.4 402.7 425.3L86.7 109.3zM480 256C480 132.3 379.7 32 256 32C199.9 32 148.6 52.62 109.3 86.7L425.3 402.7C459.4 363.4 480 312.1 480 255.1V256z" />
      </symbol>
      <symbol width="16" height="16" id="bars" viewBox="0 0 448 512">
        <path d="M0 80C0 71.16 7.164 64 16 64H432C440.8 64 448 71.16 448 80C448 88.84 440.8 96 432 96H16C7.164 96 0 88.84 0 80zM0 240C0 231.2 7.164 224 16 224H432C440.8 224 448 231.2 448 240C448 248.8 440.8 256 432 256H16C7.164 256 0 248.8 0 240zM432 416H16C7.164 416 0 408.8 0 400C0 391.2 7.164 384 16 384H432C440.8 384 448 391.2 448 400C448 408.8 440.8 416 432 416z" />
      </symbol>
      <symbol width="16" height="16" id="calendar" viewBox="0 0 448 512">
        <path d="M112 0C120.8 0 128 7.164 128 16V64H320V16C320 7.164 327.2 0 336 0C344.8 0 352 7.164 352 16V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H96V16C96 7.164 103.2 0 112 0zM416 192H32V448C32 465.7 46.33 480 64 480H384C401.7 480 416 465.7 416 448V192zM384 96H64C46.33 96 32 110.3 32 128V160H416V128C416 110.3 401.7 96 384 96z" />
      </symbol>
      <symbol width="14" height="14" id="chevron-down" viewBox="0 0 448 512">
        <path d="M4.251 181.1C7.392 177.7 11.69 175.1 16 175.1c3.891 0 7.781 1.406 10.86 4.25l197.1 181.1l197.1-181.1c6.5-6 16.64-5.625 22.61 .9062c6 6.5 5.594 16.59-.8906 22.59l-208 192c-6.156 5.688-15.56 5.688-21.72 0l-208-192C-1.343 197.7-1.749 187.6 4.251 181.1z" />
      </symbol>
      <symbol width="14" height="14" id="chevron-right" viewBox="0 0 320 512">
        <path d="M85.14 475.8c-3.438-3.141-5.156-7.438-5.156-11.75c0-3.891 1.406-7.781 4.25-10.86l181.1-197.1L84.23 58.86c-6-6.5-5.625-16.64 .9062-22.61c6.5-6 16.59-5.594 22.59 .8906l192 208c5.688 6.156 5.688 15.56 0 21.72l-192 208C101.7 481.3 91.64 481.8 85.14 475.8z" />
      </symbol>
      <symbol width="16" height="16" id="circle-check" viewBox="0 0 512 512">
        <path d="M235.3 331.3C229.1 337.6 218.9 337.6 212.7 331.3L148.7 267.3C142.4 261.1 142.4 250.9 148.7 244.7C154.9 238.4 165.1 238.4 171.3 244.7L224 297.4L340.7 180.7C346.9 174.4 357.1 174.4 363.3 180.7C369.6 186.9 369.6 197.1 363.3 203.3L235.3 331.3zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z" />
      </symbol>
      <symbol width="16" height="16" id="circle-xmark" viewBox="0 0 512 512">
        <path d="M180.7 180.7C186.9 174.4 197.1 174.4 203.3 180.7L256 233.4L308.7 180.7C314.9 174.4 325.1 174.4 331.3 180.7C337.6 186.9 337.6 197.1 331.3 203.3L278.6 256L331.3 308.7C337.6 314.9 337.6 325.1 331.3 331.3C325.1 337.6 314.9 337.6 308.7 331.3L256 278.6L203.3 331.3C197.1 337.6 186.9 337.6 180.7 331.3C174.4 325.1 174.4 314.9 180.7 308.7L233.4 256L180.7 203.3C174.4 197.1 174.4 186.9 180.7 180.7zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z" />
      </symbol>
      <symbol width="16" height="16" id="ellipsis" viewBox="0 0 448 512">
        <path d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z" />
      </symbol>
      <symbol width="16" height="16" id="lock" viewBox="0 0 448 512">
        <path d="M96 192V128C96 57.31 153.3 0 224 0C294.7 0 352 57.31 352 128V192H368C412.2 192 448 227.8 448 272V432C448 476.2 412.2 512 368 512H80C35.82 512 0 476.2 0 432V272C0 227.8 35.82 192 80 192H96zM128 192H320V128C320 74.98 277 32 224 32C170.1 32 128 74.98 128 128V192zM32 432C32 458.5 53.49 480 80 480H368C394.5 480 416 458.5 416 432V272C416 245.5 394.5 224 368 224H80C53.49 224 32 245.5 32 272V432z" />
      </symbol>
      <symbol width="16" height="16" id="xmark-16" viewBox="0 0 320 512">
        <path d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" />
      </symbol>
      <symbol width="16" height="16" id="plus" viewBox="0 0 448 512">
        <path
          fill="currentColor"
          d="M432 256C432 264.8 424.8 272 416 272h-176V448c0 8.844-7.156 16.01-16 16.01S208 456.8 208 448V272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h176V64c0-8.844 7.156-15.99 16-15.99S240 55.16 240 64v176H416C424.8 240 432 247.2 432 256z"
        />
      </symbol>
      <symbol
        width="16"
        height="16"
        id="circle-exclamation"
        viewBox="0 0 512 512">
        <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480zM256 304c8.844 0 16-7.156 16-16V128c0-8.844-7.156-16-16-16S240 119.2 240 128v160C240 296.8 247.2 304 256 304zM256 344c-13.25 0-24 10.75-24 24s10.75 24 24 24s24-10.75 24-24S269.3 344 256 344z" />
      </symbol>
      <symbol width="16" height="16" id="circle-question" viewBox="0 0 512 512">
        <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480zM240 344c-13.25 0-24 10.75-24 24s10.75 24 24 24s24-10.75 24-24S253.3 344 240 344zM285.6 128H226.4C189.8 128 160 157.8 160 194.4V204c0 8.844 7.156 16 16 16S192 212.8 192 204V194.4C192 175.4 207.4 160 226.4 160h59.25C304.6 160 320 175.4 320 194.4c0 12.48-6.781 24-17.06 29.72L242.5 254.3C231.1 260.7 224 272.7 224 285.8V304C224 312.8 231.2 320 240 320S256 312.8 256 304V285.8c0-1.453 .7813-2.797 1.438-3.172l60.41-30.22C338.9 240.7 352 218.5 352 194.4C352 157.8 322.2 128 285.6 128z" />
      </symbol>
      <symbol
        width="16"
        height="16"
        id="arrow-rotate-left"
        viewBox="0 0 512 512">
        <path d="M480 256c0 123.5-100.4 223.9-223.9 223.9c-69.41 0-133.9-31.3-176.7-86.05c-5.438-6.938-4.203-17 2.75-22.44c6.984-5.531 17.03-4.25 22.47 2.75C141.3 421.1 196.5 448 256 448c105.9 0 192-86.13 192-192s-86.13-192-192-192C187.1 64 124.5 100.7 90.15 160H176C184.8 160 192 167.2 192 176S184.8 192 176 192h-128C39.16 192 32 184.8 32 176v-128C32 39.16 39.16 32 48 32S64 39.16 64 48v93.56C104.4 73.87 176.6 32.11 256.1 32.11C379.6 32.11 480 132.5 480 256z" />
      </symbol>
      <symbol
        width="16"
        height="16"
        id="arrow-rotate-right"
        viewBox="0 0 512 512">
        <path d="M255.9 32.11c79.47 0 151.8 41.76 192.1 109.4V48C448 39.16 455.2 32 464 32S480 39.16 480 48v128C480 184.8 472.8 192 464 192h-128C327.2 192 320 184.8 320 176S327.2 160 336 160h85.85C387.5 100.7 324.9 64 256 64C150.1 64 64 150.1 64 256s86.13 192 192 192c59.48 0 114.7-26.91 151.3-73.84c5.438-7 15.48-8.281 22.47-2.75c6.953 5.438 8.187 15.5 2.75 22.44c-42.8 54.75-107.3 86.05-176.7 86.05C132.4 479.9 32 379.5 32 256S132.4 32.11 255.9 32.11z" />
      </symbol>
      <symbol width="16" height="16" id="arrow-right" viewBox="0 0 448 512">
        <path d="M443.7 266.8l-165.9 176C274.5 446.3 269.1 448 265.5 448c-3.986 0-7.988-1.375-11.16-4.156c-6.773-5.938-7.275-16.06-1.118-22.59L393.9 272H16.59c-9.171 0-16.59-7.155-16.59-15.1S7.421 240 16.59 240h377.3l-140.7-149.3c-6.157-6.531-5.655-16.66 1.118-22.59c6.789-5.906 17.27-5.469 23.45 1.094l165.9 176C449.4 251.3 449.4 260.7 443.7 266.8z" />
      </symbol>
      <symbol width="16" height="16" id="arrow-left" viewBox="0 0 448 512">
        <path d="M448 256C448 264.8 440.6 272 431.4 272H54.11l140.7 149.3c6.157 6.531 5.655 16.66-1.118 22.59C190.5 446.6 186.5 448 182.5 448c-4.505 0-9.009-1.75-12.28-5.25l-165.9-176c-5.752-6.094-5.752-15.41 0-21.5l165.9-176c6.19-6.562 16.69-7 23.45-1.094c6.773 5.938 7.275 16.06 1.118 22.59L54.11 240h377.3C440.6 240 448 247.2 448 256z" />
      </symbol>
      <symbol
        width="16"
        height="16"
        id="arrow-up-right-from-square"
        viewBox="0 0 512 512">
        <path d="M400 288C391.2 288 384 295.2 384 304V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V160c0-17.67 14.33-32 32-32h112C184.8 128 192 120.8 192 112S184.8 96 176 96L64 96c-35.35 0-64 28.65-64 64V448c0 35.35 28.65 64 64 64h288c35.35 0 64-28.65 64-64V304C416 295.2 408.8 288 400 288zM496 0h-160C327.2 0 320 7.156 320 16S327.2 32 336 32h121.4L180.7 308.7c-6.25 6.25-6.25 16.38 0 22.62C183.8 334.4 187.9 336 192 336s8.188-1.562 11.31-4.688L480 54.63V176C480 184.8 487.2 192 496 192S512 184.8 512 176v-160C512 7.156 504.8 0 496 0z" />
      </symbol>
      <symbol width="16" height="16" id="gear" viewBox="0 0 512 512">
        <path d="M168 255.1C168 207.4 207.4 167.1 256 167.1C304.6 167.1 344 207.4 344 255.1C344 304.6 304.6 344 256 344C207.4 344 168 304.6 168 255.1zM256 199.1C225.1 199.1 200 225.1 200 255.1C200 286.9 225.1 311.1 256 311.1C286.9 311.1 312 286.9 312 255.1C312 225.1 286.9 199.1 256 199.1zM65.67 230.6L25.34 193.8C14.22 183.7 11.66 167.2 19.18 154.2L49.42 101.8C56.94 88.78 72.51 82.75 86.84 87.32L138.8 103.9C152.2 93.56 167 84.96 182.8 78.43L194.5 25.16C197.7 10.47 210.7 0 225.8 0H286.2C301.3 0 314.3 10.47 317.5 25.16L329.2 78.43C344.1 84.96 359.8 93.56 373.2 103.9L425.2 87.32C439.5 82.75 455.1 88.78 462.6 101.8L492.8 154.2C500.3 167.2 497.8 183.7 486.7 193.8L446.3 230.6C447.4 238.9 448 247.4 448 255.1C448 264.6 447.4 273.1 446.3 281.4L486.7 318.2C497.8 328.3 500.3 344.8 492.8 357.8L462.6 410.2C455.1 423.2 439.5 429.2 425.2 424.7L373.2 408.1C359.8 418.4 344.1 427 329.2 433.6L317.5 486.8C314.3 501.5 301.3 512 286.2 512H225.8C210.7 512 197.7 501.5 194.5 486.8L182.8 433.6C167 427 152.2 418.4 138.8 408.1L86.84 424.7C72.51 429.2 56.94 423.2 49.42 410.2L19.18 357.8C11.66 344.8 14.22 328.3 25.34 318.2L65.67 281.4C64.57 273.1 64 264.6 64 255.1C64 247.4 64.57 238.9 65.67 230.6V230.6zM158.4 129.2L145.1 139.5L77.13 117.8L46.89 170.2L99.58 218.2L97.39 234.8C96.47 241.7 96 248.8 96 255.1C96 263.2 96.47 270.3 97.39 277.2L99.58 293.8L46.89 341.8L77.13 394.2L145.1 372.5L158.4 382.8C169.5 391.4 181.9 398.6 195 403.1L210.5 410.4L225.8 480H286.2L301.5 410.4L316.1 403.1C330.1 398.6 342.5 391.4 353.6 382.8L366.9 372.5L434.9 394.2L465.1 341.8L412.4 293.8L414.6 277.2C415.5 270.3 416 263.2 416 256C416 248.8 415.5 241.7 414.6 234.8L412.4 218.2L465.1 170.2L434.9 117.8L366.9 139.5L353.6 129.2C342.5 120.6 330.1 113.4 316.1 108L301.5 101.6L286.2 32H225.8L210.5 101.6L195 108C181.9 113.4 169.5 120.6 158.4 129.2H158.4z" />
      </symbol>
      <symbol width="16" height="16" id="eye" viewBox="0 0 576 512">
        <path d="M416 256C416 326.7 358.7 384 288 384C217.3 384 160 326.7 160 256C160 185.3 217.3 128 288 128C358.7 128 416 185.3 416 256zM288 160C234.1 160 192 202.1 192 256C192 309 234.1 352 288 352C341 352 384 309 384 256C384 202.1 341 160 288 160zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM32 256C45.33 288 74.13 336 117.2 376C160.3 416 217.6 448 288 448C358.4 448 415.7 416 458.8 376C501.9 336 530.7 288 544 256C530.7 223.1 501.9 175.1 458.8 136C415.7 95.99 358.4 64 288 64C217.6 64 160.3 95.99 117.2 136C74.13 175.1 45.33 223.1 32 256V256z" />
      </symbol>
      <symbol width="16" height="16" id="eye-slash" viewBox="0 0 640 512">
        <path d="M633.9 483.4C640.9 488.9 642 498.1 636.6 505.9C631.1 512.9 621 514 614.1 508.6L6.086 28.56C-.8493 23.08-2.033 13.02 3.443 6.086C8.918-.8493 18.98-2.033 25.91 3.443L633.9 483.4zM605.5 268.3C595.3 292.9 577.2 325.3 551.6 357.3L526.5 337.5C550.1 307.1 566.8 278.1 576 256C562.7 223.1 533.9 175.1 490.8 136C447.7 95.99 390.4 64 320 64C280.2 64 244.6 74.21 213.4 90.31L186.1 68.78C224 46.8 268.8 31.1 320 31.1C400.8 31.1 465.5 68.84 512.6 112.6C559.4 156 590.7 207.1 605.5 243.7C608.8 251.6 608.8 260.4 605.5 268.3L605.5 268.3zM88.39 154.7L113.5 174.5C89.86 204 73.23 233.9 63.1 255.1C77.33 288 106.1 336 149.2 376C192.3 416 249.6 448 319.1 448C359.8 448 395.4 437.8 426.6 421.7L453.9 443.2C415.1 465.2 371.2 480 319.1 480C239.2 480 174.5 443.2 127.4 399.4C80.62 355.1 49.34 304 34.46 268.3C31.18 260.4 31.18 251.6 34.46 243.7C44.73 219.1 62.8 186.7 88.39 154.7L88.39 154.7zM191.1 255.1C191.1 249.7 192.5 243.6 193.3 237.5L224.2 261.9C227.2 312.2 268.1 352 319.1 352C325.6 352 331.2 351.5 336.5 350.6L367.4 374.9C352.7 380.8 336.7 384 319.1 384C249.3 384 191.1 326.7 191.1 255.1zM448 255.1C448 262.3 447.5 268.4 446.7 274.5L415.8 250.1C412.8 199.8 371 159.1 319.1 159.1C314.4 159.1 308.8 160.5 303.5 161.4L272.6 137.1C287.3 131.2 303.3 127.1 319.1 127.1C390.7 127.1 448 185.3 448 255.1V255.1z" />
      </symbol>
      <symbol width="16" height="16" id="user-group" viewBox="0 0 640 512">
        <path d="M274.7 304H173.3c-95.73 0-173.3 77.6-173.3 173.3C0 496.5 15.52 512 34.66 512H413.3C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM413.3 480H34.66C33.2 480 32 478.8 32 477.3C32 399.4 95.4 336 173.3 336H274.7C352.6 336 416 399.4 416 477.3C416 478.8 414.8 480 413.3 480zM224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM224 32c52.94 0 96 43.06 96 96c0 52.93-43.06 96-96 96S128 180.9 128 128C128 75.06 171.1 32 224 32zM375.1 241C392.9 250.8 412.3 256 432 256C493.8 256 544 205.8 544 144S493.8 32 432 32c-12.83 0-25.39 2.156-37.34 6.391c-8.328 2.953-12.69 12.09-9.734 20.42c2.953 8.344 12.12 12.66 20.42 9.734C413.9 65.53 422.8 64 432 64C476.1 64 512 99.89 512 144S476.1 224 432 224c-14.08 0-27.91-3.703-39.98-10.69c-7.656-4.453-17.44-1.828-21.86 5.828C365.7 226.8 368.3 236.6 375.1 241zM490.7 320H448c-8.844 0-16 7.156-16 16S439.2 352 448 352h42.67C555.4 352 608 404.6 608 469.3C608 475.2 603.2 480 597.3 480H496c-8.844 0-16 7.156-16 16s7.156 16 16 16h101.3C620.9 512 640 492.9 640 469.3C640 386.1 573 320 490.7 320z" />
      </symbol>
      <symbol width="16" height="16" id="flag" viewBox="0 0 512 512">
        <path d="M498.5 6.232c-19.76-11.99-38.92-3.226-41.61-1.1c-41.75 19.06-76.02 27.94-107.8 27.94c-28.92 0-51.74-7.321-75.9-15.09C247.5 8.844 220.1 .3094 185.2 .3055C159 .3055 121.3 2.641 32 38.84V16.01c0-8.836-7.164-15.1-16-15.1S0 7.172 0 16.01V496C0 504.8 7.164 512 16 512S32 504.8 32 496v-104.9c14.47-6.441 77.75-38.93 148.8-38.93c36.8 0 67.14 7.713 99.25 15.89c30.74 7.82 62.49 15.9 99.31 15.9c35.46 0 72.08-7.553 111.1-23.09c12.28-4.781 20.38-16.6 20.38-29.78L512 32.35C512 22.01 507.4 11.6 498.5 6.232zM479.7 331c-36.11 14.07-68.93 20.91-100.3 20.91c-32.81 0-61.26-7.238-91.39-14.9C255.4 328.7 221.7 320.2 180.8 320.2c-45.89 0-93.61 11.31-145.9 34.58L32 356.1V73.37l28.01-11.35c49.34-19.98 90.29-29.7 125.2-29.7c30.74 0 53.8 7.406 78.2 15.24c25.44 8.172 51.75 16.62 85.69 16.62c69.43 0 130.9-32.17 130.9-32.17L479.7 331z" />
      </symbol>
      <symbol width="16" height="16" id="play" viewBox="0 0 384 512">
        <path d="M24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13V38.13zM56.34 66.35C51.4 63.33 45.22 63.21 40.17 66.04C35.13 68.88 32 74.21 32 80V432C32 437.8 35.13 443.1 40.17 445.1C45.22 448.8 51.41 448.7 56.34 445.7L344.3 269.7C349.1 266.7 352 261.6 352 256C352 250.4 349.1 245.3 344.3 242.3L56.34 66.35z" />
      </symbol>
      <symbol width="24" height="24" id="arrows-rotate" viewBox="0 0 512 512">
        <path d="M463.5 272.1C463.2 272 462.9 272 462.6 272c-4.031 0-7.469 3-7.938 7.094C443.1 379.9 357.7 456 256 456c-85.8 0-161.6-55.56-188.9-136H184C188.4 320 192 316.4 192 312S188.4 304 184 304H48c-4.406 0-8 3.594-8 8v128C40 444.4 43.59 448 48 448s8-3.594 8-8v-104C88.43 416.9 167.2 472 256 472c109.8 0 202.1-82.16 214.6-191.1C471.1 276.5 467.9 272.6 463.5 272.1zM464 64c-4.406 0-8 3.594-8 8v104C423.6 95.09 344.8 40 256 40c-109.8 0-202.1 82.16-214.6 191.1c-.5 4.375 2.625 8.344 7.031 8.844C48.78 239.1 49.09 240 49.38 240c4.031 0 7.469-3 7.938-7.094C68.91 132.1 154.3 56 256 56c85.8 0 161.6 55.56 188.9 136H328C323.6 192 320 195.6 320 200s3.594 8 8 8h136c4.406 0 8-3.594 8-8v-128C472 67.59 468.4 64 464 64z" />
      </symbol>
      <symbol width="24" height="24" id="xmark-24" viewBox="0 0 320 512">
        <path d="M317.7 402.3c3.125 3.125 3.125 8.188 0 11.31c-3.127 3.127-8.186 3.127-11.31 0L160 267.3l-146.3 146.3c-3.127 3.127-8.186 3.127-11.31 0c-3.125-3.125-3.125-8.188 0-11.31L148.7 256L2.344 109.7c-3.125-3.125-3.125-8.188 0-11.31s8.188-3.125 11.31 0L160 244.7l146.3-146.3c3.125-3.125 8.188-3.125 11.31 0s3.125 8.188 0 11.31L171.3 256L317.7 402.3z" />
      </symbol>
      <symbol width="24" height="24" id="terminal" viewBox="0 0 576 512">
        <path d="M34.24 45.55C31.17 42.37 31.27 37.3 34.45 34.24C37.63 31.17 42.7 31.27 45.76 34.45L253.8 250.5C256.7 253.5 256.7 258.5 253.8 261.5L45.76 477.5C42.7 480.7 37.63 480.8 34.45 477.8C31.27 474.7 31.17 469.6 34.24 466.5L236.9 256L34.24 45.55zM568 464C572.4 464 576 467.6 576 472C576 476.4 572.4 480 568 480H232C227.6 480 224 476.4 224 472C224 467.6 227.6 464 232 464H568z" />
      </symbol>
      <symbol width="24" height="24" id="hand-pointer" viewBox="0 0 448 512">
        <path d="M232 424v-112c0-4.418-3.582-8-8-8S216 307.6 216 312v112c0 4.418 3.582 8 8 8S232 428.4 232 424zM393.8 224h-12.88c-9.094-19.38-27.97-32-48.65-32h-15.38c-7.25 0-13.94 1.188-20.06 3.5C286.7 183.2 271.6 176 255.4 176h-8.594C238.9 176 231.1 177.8 224 181.1V56C224 25.12 198.9 0 168 0S112 25.12 112 56v288C112 348.4 115.6 352 120 352S128 348.4 128 344v-288C128 33.94 145.9 16 168 16S208 33.94 208 56v139.1c0 5.328 4.521 8.017 7.996 8.017C223.1 203.1 227.3 192 246.8 192h8.594c13.06 0 25.09 6.656 32.19 17.81c1.515 2.408 4.079 3.713 6.729 3.713c4.993 0 7.861-5.526 22.65-5.526h15.38c15.59 0 30.34 11.06 35.84 26.91c1.078 3.12 4.01 5.417 7.136 5.417c.3179 0 .6378-.0237 .9579-.0728c0 0 2.281-.25 2.312-.25h15.28c21.03 0 38.16 17.12 38.16 38.16v102.8c0 63.44-51.63 115.1-115.1 115.1H224c-42.5 0-85.53-20.72-109.8-53L23.22 329.6c-4.727-6.286-7.218-13.76-7.218-21.49c0-29.37 26.98-36.13 35.94-36.13c24.38 0 26.32 17.61 35.23 17.61c4.6 0 8.028-3.741 8.028-7.993c0-7.458-18.63-25.6-43.21-25.6c-29.14 0-51.99 23.73-51.99 52.11c0 11.19 3.608 22.04 10.57 31.3l91.03 113.4C128.6 488.8 176.6 512 224 512h92.94c72.28 0 131.1-58.78 131.1-131.1V278.2C447.1 248.3 423.7 224 393.8 224zM296 424v-112c0-4.418-3.583-8-8.001-8S280 307.6 280 312v112c0 4.418 3.58 8 7.998 8S296 428.4 296 424zM360 424v-112c0-4.418-3.582-8-8-8s-8 3.582-8 8v112c0 4.418 3.582 8 8 8S360 428.4 360 424z" />
      </symbol>
      <symbol width="24" height="24" id="toolbox" viewBox="0 0 512 512">
        <path d="M502.6 214.6l-77.25-77.25C419.4 131.4 411.2 128 402.7 128H384V72C384 49.94 366.1 32 344 32h-176C145.9 32 128 49.94 128 72V128H109.3C100.8 128 92.63 131.4 86.63 137.4L9.373 214.6C3.371 220.6 0 228.8 0 237.3V416c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64V237.3C512 228.8 508.6 220.6 502.6 214.6zM144 72c0-13.23 10.78-24 24-24h176c13.22 0 24 10.77 24 24V128h-224V72zM496 416c0 26.47-21.53 48-48 48H64c-26.47 0-48-21.53-48-48v-88h136v48c0 4.422 3.594 8 7.1 8S168 380.4 168 376v-48h176v48c0 4.422 3.594 8 8 8s8-3.578 8-8v-48h136V416zM496 312h-136v-48C360 259.6 356.4 256 352 256s-8 3.578-8 8v48h-176v-48C168 259.6 164.4 256 160 256S152 259.6 152 264v48H16V237.3c0-4.273 1.664-8.291 4.688-11.31l77.25-77.25C100.1 145.7 104.1 144 109.3 144h293.5c4.273 0 8.291 1.664 11.31 4.686l77.26 77.26C494.3 228.1 496 232.1 496 237.3V312z" />
      </symbol>
      <symbol width="24" height="24" id="mobile" viewBox="0 0 384 512">
        <path d="M80 512h224c26.5 0 48-21.5 48-48v-416C352 21.5 330.5 0 304 0h-224C53.5 0 32 21.5 32 48v416C32 490.5 53.5 512 80 512zM48 48c0-17.64 14.36-32 32-32h224c17.64 0 32 14.36 32 32v416c0 17.64-14.36 32-32 32h-224c-17.64 0-32-14.36-32-32V48zM144 456h96c4.406 0 8-3.578 8-8s-3.594-8-8-8h-96c-4.406 0-8 3.578-8 8S139.6 456 144 456z" />
      </symbol>
      <symbol width="24" height="24" id="display" viewBox="0 0 576 512">
        <path d="M64 352h448V64H64V352zM80 80h416v256h-416V80zM528 0h-480C21.5 0 0 21.5 0 48v320C0 394.5 21.5 416 48 416h180.9l-26.67 80H136C131.6 496 128 499.6 128 504S131.6 512 136 512h304c4.406 0 8-3.578 8-8s-3.594-8-8-8h-66.21l-26.67-80H528c26.5 0 48-21.5 48-48v-320C576 21.5 554.5 0 528 0zM356.9 496H219.1l26.67-80h84.46L356.9 496zM560 368c0 17.64-14.36 32-32 32h-480c-17.64 0-32-14.36-32-32v-320c0-17.64 14.36-32 32-32h480c17.64 0 32 14.36 32 32V368z" />
      </symbol>
      <symbol width="24" height="24" id="tablet" viewBox="0 0 448 512">
        <path d="M48 512h352c26.5 0 48-21.5 48-48v-416C448 21.5 426.5 0 400 0h-352C21.5 0 0 21.5 0 48v416C0 490.5 21.5 512 48 512zM16 48c0-17.64 14.36-32 32-32h352c17.64 0 32 14.36 32 32v416c0 17.64-14.36 32-32 32h-352c-17.64 0-32-14.36-32-32V48zM176 456h96c4.406 0 8-3.578 8-8s-3.594-8-8-8h-96c-4.406 0-8 3.578-8 8S171.6 456 176 456z" />
      </symbol>
      <symbol
        width="24"
        height="24"
        id="arrows-left-right"
        viewBox="0 0 512 512">
        <path d="M509.7 261.8l-96 103.8c-3.125 3.192-8.188 3.192-11.31 0c-3.125-3.192-3.125-8.363 0-11.55l82.34-89.88H27.31l82.34 89.88c3.125 3.192 3.125 8.362 0 11.55C108.1 367.2 106.1 368 104 368s-4.094-.7979-5.656-2.394l-96-103.8c-3.125-3.192-3.125-8.362 0-11.55l96-103.8c3.125-3.192 8.188-3.192 11.31 0s3.125 8.363 0 11.55L27.31 247.8h457.4l-82.34-89.88c-3.125-3.192-3.125-8.363 0-11.55C403.9 144.8 405.9 144 408 144s4.094 .7979 5.656 2.394l96 103.8C512.8 253.4 512.8 258.6 509.7 261.8z" />
      </symbol>
      <symbol id="toolbar-new-window-24" viewBox="0 0 24 24">
        <path d="M16 20H2.2c-1 0-1.2-.7-1.2-1V5.2C1 4.5 1.5 4 2.2 4H19c.5 0 1 .3 1 1.2V8c0 .3-.2.5-.5.5S19 8.3 19 8V5H2.2c-.1 0-.2.1-.2.2V19h14c.3 0 .5.2.5.5s-.2.5-.5.5z" />
        <path d="M19 8H2c-.3 0-.5-.2-.5-.5S1.7 7 2 7h17c.3 0 .5.2.5.5s-.2.5-.5.5zm3.5 10h-7.3c-.2 0-.4-.1-.5-.3-.1-.2 0-.4.1-.5l1.9-2c-2.3-1.1-4.5-1.3-5.7-.5-.8.5-1.2 1.3-1.2 2.6 0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-2.6.8-4.6 2.5-5.8 2.1-1.6 5.4-1.8 9.3-.6L22.1 9c.1-.2.4-.2.6-.1.2.1.3.2.3.5v8.1c0 .3-.2.5-.5.5zm-6.1-1H22v-6.3l-.8 1c-.1.1-.3.2-.5.2-3.7-1.1-6.8-1-8.7.4-.6.4-1 .9-1.3 1.5 2-1.1 5-.3 7.2.8.1.1.2.2.3.4 0 .2 0 .3-.1.4L16.4 17z" />
      </symbol>
      <symbol id="toolbar-new-window-close-24" viewBox="0 0 24 24">
        <path d="M16 20H2.2c-1 0-1.2-.7-1.2-1V5.2C1 4.5 1.5 4 2.2 4H19c.5 0 1 .3 1 1.2V8c0 .3-.2.5-.5.5S19 8.3 19 8V5H2.2c-.1 0-.2.1-.2.2V19h14c.3 0 .5.2.5.5s-.2.5-.5.5z" />
        <path d="M19 8H2c-.3 0-.5-.2-.5-.5S1.7 7 2 7h17c.3 0 .5.2.5.5s-.2.5-.5.5zM8.8 17.5V9.4c0-.3.1-.4.3-.5.2-.1.5-.1.6.1l1.5 1.9c3.9-1.2 7.2-1 9.3.6 1.7 1.2 2.5 3.2 2.5 5.8 0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-1.3-.4-2.1-1.2-2.6-1.2-.8-3.4-.6-5.7.5l1.9 2c.1.1.2.3.1.5s-.3.3-.5.3H9.3c-.3 0-.5-.2-.5-.5zm4.9-2.1c-.1-.1-.1-.2-.1-.4.1-.2.2-.3.3-.4 2.2-1.1 5.2-1.9 7.2-.8-.3-.6-.7-1.1-1.3-1.5-1.9-1.4-5-1.5-8.7-.4-.2 0-.4-.1-.5-.2l-.8-1V17h5.6l-1.7-1.6z" />
      </symbol>
      <symbol id="orientation-landscape-24" viewBox="0 0 24 24">
        <g opacity=".3">
          <path d="M23.5 13.5a.5.5 0 0 1-.5-.5V2.5A1.5 1.5 0 0 0 21.5 1h-9A1.5 1.5 0 0 0 11 2.5V10a.5.5 0 0 1-1 0V2.5A2.5 2.5 0 0 1 12.5 0h9A2.5 2.5 0 0 1 24 2.5V13a.5.5 0 0 1-.5.5z" />
          <path d="M23 5H11a.5.5 0 0 1 0-1h12a.5.5 0 0 1 0 1zm-6-1.5a1 1 0 1 1 1-1 1 1 0 0 1-1 1zM17 2v.5V2z" />
        </g>
        <path d="M21.5 24h-19A2.5 2.5 0 0 1 0 21.5v-9A2.5 2.5 0 0 1 2.5 10h19a2.5 2.5 0 0 1 2.5 2.5v9a2.5 2.5 0 0 1-2.5 2.5zm-19-13A1.5 1.5 0 0 0 1 12.5v9A1.5 1.5 0 0 0 2.5 23h19a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-19z" />
        <path d="M5.5 23.5A.5.5 0 0 1 5 23V11a.5.5 0 0 1 1 0v12a.5.5 0 0 1-.5.5zm14 0a.5.5 0 0 1-.5-.5V11a.5.5 0 0 1 1 0v12a.5.5 0 0 1-.5.5zm2-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v4a.5.5 0 0 1-.5.5zM3 17.5a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm0-1z" />
      </symbol>
      <symbol id="orientation-portrait-24" viewBox="0 0 24 24">
        <path d="M21.5 24h-9a2.5 2.5 0 0 1-2.5-2.5v-19A2.5 2.5 0 0 1 12.5 0h9A2.5 2.5 0 0 1 24 2.5v19a2.5 2.5 0 0 1-2.5 2.5zm-9-23A1.5 1.5 0 0 0 11 2.5v19a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5v-19A1.5 1.5 0 0 0 21.5 1h-9z" />
        <path d="M23 5H11a.5.5 0 0 1 0-1h12a.5.5 0 0 1 0 1zm0 15H11a.5.5 0 0 1 0-1h12a.5.5 0 0 1 0 1zm-4 2h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1zM17 3.5a1 1 0 1 1 1-1 1 1 0 0 1-1 1zM17 2v.5V2z" />
        <g opacity=".3">
          <path d="M12 24H2.5A2.5 2.5 0 0 1 0 21.5v-9A2.5 2.5 0 0 1 2.5 10H10a.5.5 0 0 1 0 1H2.5A1.5 1.5 0 0 0 1 12.5v9A1.5 1.5 0 0 0 2.5 23H12a.5.5 0 0 1 0 1z" />
          <path d="M5.5 23.5A.5.5 0 0 1 5 23V11a.5.5 0 0 1 1 0v12a.5.5 0 0 1-.5.5zm-2.5-6a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm0-1z" />
        </g>
      </symbol>
      <symbol id="ax-pop-out" width="22" height="16" viewBox="0 0 22 16">
        <path d="M14.2738 4.0633H19.7554L17.1865 1.8585C16.9843 1.67645 16.964 1.37304 17.1258 1.17076C17.3079 0.968485 17.6113 0.948257 17.8136 1.11008L21.3736 4.16444C21.4748 4.2858 21.5557 4.40717 21.5557 4.54876C21.5557 4.69036 21.4748 4.83195 21.3736 4.93309L17.8136 7.98745C17.7327 8.06836 17.6113 8.10881 17.5102 8.10881C17.3686 8.10881 17.227 8.04813 17.1258 7.92676C16.964 7.72449 16.9843 7.42108 17.1865 7.23903L19.7554 5.03422H14.2738C13.1006 5.03422 12.1701 5.96469 12.1701 7.11766V9.52474C12.1701 9.84838 11.9476 10.0507 11.6846 10.0507C11.4014 10.0507 11.1992 9.84838 11.1992 9.5652V7.15812C11.1992 5.459 12.5746 4.0633 14.2738 4.0633Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4294 1.05688C14.4149 1.05656 14.4003 1.0564 14.3858 1.0564H2.43597C1.31568 1.0564 0.444336 1.95886 0.444336 3.04803V13.0062C0.444336 14.1265 1.31568 14.9979 2.43597 14.9979H14.3858C15.475 14.9979 16.3774 14.1265 16.3774 13.0062V9.74697C16.3744 9.47458 16.1526 9.25468 15.8795 9.25468C15.6064 9.25468 15.3847 9.47458 15.3816 9.74697L15.3816 9.75259V13.0062C15.3816 13.5664 14.9148 14.002 14.3858 14.002H2.43597C1.87582 14.002 1.44015 13.5664 1.44015 13.0062V5.03967H9.89934C10.1725 5.03749 10.3932 4.81539 10.3932 4.54176C10.3932 4.26813 10.1725 4.04605 9.89934 4.04387L9.89527 4.04385H5.42343V2.05221H14.3858C14.4022 2.05221 14.4185 2.05266 14.4348 2.05355V2.05219C14.7073 2.04928 14.9273 1.82748 14.9273 1.55431C14.9273 1.27932 14.7043 1.05688 14.4294 1.05688ZM1.44015 3.04803C1.44015 2.519 1.87582 2.05221 2.43597 2.05221H4.42761V4.04385H1.44015V3.04803Z"
        />
      </symbol>
      <symbol id="ax-pop-in" width="21" height="16" viewBox="0 0 21 16">
        <path d="M17.6934 11.8943L12.1887 11.8943L14.7684 14.1084C14.9715 14.2912 14.9918 14.5959 14.8293 14.799C14.6465 15.0021 14.3418 15.0224 14.1387 14.8599L10.5637 11.7927C10.4621 11.6709 10.3809 11.549 10.3809 11.4068C10.3809 11.2646 10.4621 11.1224 10.5637 11.0209L14.1387 7.95368C14.2199 7.87243 14.3418 7.8318 14.4434 7.8318C14.5855 7.8318 14.7277 7.89274 14.8293 8.01462C14.9918 8.21774 14.9715 8.52243 14.7684 8.70524L12.1887 10.9193H17.6934C18.8715 10.9193 19.8059 9.98493 19.8059 8.82712V6.40993C19.8059 6.08493 20.0293 5.8818 20.2934 5.8818C20.5777 5.8818 20.7809 6.08493 20.7809 6.3693V8.78649C20.7809 10.4927 19.3996 11.8943 17.6934 11.8943Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.21875 1H15.2188C16.3125 1 17.2188 1.90625 17.2188 3V7.20937H16.2188V5H2.21875V13C2.21875 13.5625 2.65625 14 3.21875 14H10.0098V15H3.21875C2.09375 15 1.21875 14.125 1.21875 13V3C1.21875 1.90625 2.09375 1 3.21875 1ZM3.21875 2C2.65625 2 2.21875 2.46875 2.21875 3V4H5.21875V2H3.21875ZM6.21875 4H16.2188V3C16.2188 2.46875 15.75 2 15.2188 2H6.21875V4Z"
        />
        <path d="M16.7188 7.7083C16.4426 7.7083 16.2188 7.48444 16.2188 7.2083H17.2188C17.2188 7.48444 16.9949 7.7083 16.7188 7.7083Z" />
        <path d="M10.5035 14.5C10.5035 14.7761 10.2797 15 10.0035 15V14C10.2797 14 10.5035 14.2239 10.5035 14.5Z" />
      </symbol>
      <symbol width="16" height="16" id="grip-vertical" viewBox="0 0 320 512">
        <path d="M40 480C17.91 480 0 462.1 0 440V392C0 369.9 17.91 352 40 352H88C110.1 352 128 369.9 128 392V440C128 462.1 110.1 480 88 480H40zM40 448H88C92.42 448 96 444.4 96 440V392C96 387.6 92.42 384 88 384H40C35.58 384 32 387.6 32 392V440C32 444.4 35.58 448 40 448zM232 480C209.9 480 192 462.1 192 440V392C192 369.9 209.9 352 232 352H280C302.1 352 320 369.9 320 392V440C320 462.1 302.1 480 280 480H232zM232 448H280C284.4 448 288 444.4 288 440V392C288 387.6 284.4 384 280 384H232C227.6 384 224 387.6 224 392V440C224 444.4 227.6 448 232 448zM0 232C0 209.9 17.91 192 40 192H88C110.1 192 128 209.9 128 232V280C128 302.1 110.1 320 88 320H40C17.91 320 0 302.1 0 280V232zM32 232V280C32 284.4 35.58 288 40 288H88C92.42 288 96 284.4 96 280V232C96 227.6 92.42 224 88 224H40C35.58 224 32 227.6 32 232zM232 320C209.9 320 192 302.1 192 280V232C192 209.9 209.9 192 232 192H280C302.1 192 320 209.9 320 232V280C320 302.1 302.1 320 280 320H232zM232 288H280C284.4 288 288 284.4 288 280V232C288 227.6 284.4 224 280 224H232C227.6 224 224 227.6 224 232V280C224 284.4 227.6 288 232 288zM0 72C0 49.91 17.91 32 40 32H88C110.1 32 128 49.91 128 72V120C128 142.1 110.1 160 88 160H40C17.91 160 0 142.1 0 120V72zM32 72V120C32 124.4 35.58 128 40 128H88C92.42 128 96 124.4 96 120V72C96 67.58 92.42 64 88 64H40C35.58 64 32 67.58 32 72zM232 160C209.9 160 192 142.1 192 120V72C192 49.91 209.9 32 232 32H280C302.1 32 320 49.91 320 72V120C320 142.1 302.1 160 280 160H232zM232 128H280C284.4 128 288 124.4 288 120V72C288 67.58 284.4 64 280 64H232C227.6 64 224 67.58 224 72V120C224 124.4 227.6 128 232 128z" />
      </symbol>
      <symbol width="16" height="16" id="sparkles" viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M432 32C440.8 32 448 39.16 448 48V96H496C504.8 96 512 103.2 512 112C512 120.8 504.8 128 496 128H448V176C448 184.8 440.8 192 432 192C423.2 192 416 184.8 416 176V128H368C359.2 128 352 120.8 352 112C352 103.2 359.2 96 368 96H416V48C416 39.16 423.2 32 432 32zM432 320C440.8 320 448 327.2 448 336V384H496C504.8 384 512 391.2 512 400C512 408.8 504.8 416 496 416H448V464C448 472.8 440.8 480 432 480C423.2 480 416 472.8 416 464V416H368C359.2 416 352 408.8 352 400C352 391.2 359.2 384 368 384H416V336C416 327.2 423.2 320 432 320zM123.3 321.8L9.292 269.1C3.627 266.5 0 260.8 0 254.6C0 248.3 3.627 242.6 9.292 240L123.3 187.3L176 73.29C178.6 67.63 184.3 64 190.6 64C196.8 64 202.5 67.63 205.1 73.29L257.8 187.3L371.8 240C377.5 242.6 381.1 248.3 381.1 254.6C381.1 260.8 377.5 266.5 371.8 269.1L257.8 321.8L205.1 435.8C202.5 441.5 196.8 445.1 190.6 445.1C184.3 445.1 178.6 441.5 176 435.8L123.3 321.8zM54.16 254.6L136.8 292.7C143.7 295.9 149.2 301.4 152.4 308.3L190.6 390.9L228.7 308.3C231.9 301.4 237.4 295.9 244.3 292.7L326.9 254.6L244.3 216.4C237.4 213.2 231.9 207.7 228.7 200.8L190.6 118.2L152.4 200.8C149.2 207.7 143.7 213.2 136.8 216.4L54.16 254.6z"
        />
      </symbol>
      <symbol width="16" height="16" id="thumbs-up" viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M88 192h-48C17.94 192 0 209.9 0 232v208C0 462.1 17.94 480 40 480h48C110.1 480 128 462.1 128 440v-208C128 209.9 110.1 192 88 192zM96 440C96 444.4 92.41 448 88 448h-48C35.59 448 32 444.4 32 440v-208C32 227.6 35.59 224 40 224h48C92.41 224 96 227.6 96 232V440zM512 221.5C512 187.6 484.4 160 450.5 160h-102.5c11.98-27.06 18.83-53.48 18.83-67.33C366.9 62.84 343.6 32 304.9 32c-41.22 0-50.7 29.11-59.12 54.81C218.1 171.1 160 184.8 160 208C160 217.1 167.5 224 176 224C180.1 224 184.2 222.4 187.3 219.3c52.68-53.04 67.02-56.11 88.81-122.5C285.3 68.95 288.2 64 304.9 64c20.66 0 29.94 16.77 29.94 28.67c0 10.09-8.891 43.95-26.62 75.48c-1.366 2.432-2.046 5.131-2.046 7.83C306.2 185.5 314 192 322.2 192h128.3C466.8 192 480 205.2 480 221.5c0 15.33-12.08 28.16-27.48 29.2c-8.462 .5813-14.91 7.649-14.91 15.96c0 12.19 12.06 12.86 12.06 30.63c0 14.14-10.11 26.3-24.03 28.89c-5.778 1.082-13.06 6.417-13.06 15.75c0 8.886 6.765 10.72 6.765 23.56c0 31.02-31.51 22.12-31.51 43.05c0 3.526 1.185 5.13 1.185 10.01C389 434.8 375.8 448 359.5 448H303.9c-82.01 0-108.3-64.02-127.9-64.02c-8.873 0-16 7.193-16 15.96C159.1 416.3 224.6 480 303.9 480h55.63c33.91 0 61.5-27.58 61.5-61.47c18.55-10.86 30.33-31 30.33-53.06c0-4.797-.5938-9.594-1.734-14.27c19.31-10.52 32.06-30.97 32.06-53.94c0-7.219-1.281-14.31-3.75-20.98C498.2 266.2 512 245.3 512 221.5z"
        />
      </symbol>
      <symbol width="32" height="32" id="spinner" viewBox="0 0 512 512">
        <path d="M208 48C208 21.49 229.5 0 256 0C282.5 0 304 21.49 304 48C304 74.51 282.5 96 256 96C229.5 96 208 74.51 208 48zM256 64C264.8 64 272 56.84 272 48C272 39.16 264.8 32 256 32C247.2 32 240 39.16 240 48C240 56.84 247.2 64 256 64zM208 464C208 437.5 229.5 416 256 416C282.5 416 304 437.5 304 464C304 490.5 282.5 512 256 512C229.5 512 208 490.5 208 464zM256 480C264.8 480 272 472.8 272 464C272 455.2 264.8 448 256 448C247.2 448 240 455.2 240 464C240 472.8 247.2 480 256 480zM96 256C96 282.5 74.51 304 48 304C21.49 304 0 282.5 0 256C0 229.5 21.49 208 48 208C74.51 208 96 229.5 96 256zM48 240C39.16 240 32 247.2 32 256C32 264.8 39.16 272 48 272C56.84 272 64 264.8 64 256C64 247.2 56.84 240 48 240zM416 256C416 229.5 437.5 208 464 208C490.5 208 512 229.5 512 256C512 282.5 490.5 304 464 304C437.5 304 416 282.5 416 256zM464 272C472.8 272 480 264.8 480 256C480 247.2 472.8 240 464 240C455.2 240 448 247.2 448 256C448 264.8 455.2 272 464 272zM142.9 369.1C161.6 387.9 161.6 418.3 142.9 437C124.1 455.8 93.73 455.8 74.98 437C56.23 418.3 56.23 387.9 74.98 369.1C93.73 350.4 124.1 350.4 142.9 369.1V369.1zM97.61 391.8C91.36 398 91.36 408.1 97.61 414.4C103.9 420.6 113.1 420.6 120.2 414.4C126.5 408.1 126.5 398 120.2 391.8C113.1 385.5 103.9 385.5 97.61 391.8zM74.98 74.98C93.73 56.23 124.1 56.23 142.9 74.98C161.6 93.73 161.6 124.1 142.9 142.9C124.1 161.6 93.73 161.6 74.98 142.9C56.24 124.1 56.24 93.73 74.98 74.98V74.98zM97.61 120.2C103.9 126.5 113.1 126.5 120.2 120.2C126.5 113.1 126.5 103.9 120.2 97.61C113.1 91.36 103.9 91.36 97.61 97.61C91.36 103.9 91.36 113.1 97.61 120.2zM437 437C418.3 455.8 387.9 455.8 369.1 437C350.4 418.3 350.4 387.9 369.1 369.1C387.9 350.4 418.3 350.4 437 369.1C455.8 387.9 455.8 418.3 437 437V437zM414.4 391.8C408.1 385.5 398 385.5 391.8 391.8C385.5 398 385.5 408.1 391.8 414.4C398 420.6 408.1 420.6 414.4 414.4C420.6 408.1 420.6 398 414.4 391.8z" />
      </symbol>
      <symbol id="check" viewBox="0 0 448 512">
        <path
          fill="currentColor"
          d="M443.3 100.7C449.6 106.9 449.6 117.1 443.3 123.3L171.3 395.3C165.1 401.6 154.9 401.6 148.7 395.3L4.686 251.3C-1.562 245.1-1.562 234.9 4.686 228.7C10.93 222.4 21.06 222.4 27.31 228.7L160 361.4L420.7 100.7C426.9 94.44 437.1 94.44 443.3 100.7H443.3z"
        />
      </symbol>
    </svg>
  );
}

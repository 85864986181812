/**
 * Get the current page for the pageable id specified.
 * @param {string} id - The pageable id.
 * @returns {GetterFunction}
 */
export const currentPage = id => [
  ['pageable', id, 'currentPage'],
  pageNum => pageNum || 1,
];

export default {
  currentPage,
};

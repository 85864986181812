import actions from './actions';
import actionTypes from './action_types';
import getters from './getters';

export { actions, actionTypes, getters };

export default {
  actions,
  actionTypes,
  getters,
};

export const versions = {
  UNKNOWN: null,
  CLASSIC: 'v1',
  OPTIMIZELY_X: 'v2',
};

export const projectRoot = {
  CLASSIC: '/projects',
  OPTIMIZELY_X: '/v2/projects',
};

export default {
  versions,
  projectRoot,
};

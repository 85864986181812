import flux from 'core/flux';

import RestApi from 'optly/modules/rest_api';
import requester from 'optly/modules/rest_api/api/requester';

import definition from './entity_definition';

let LiveCommitTagActions;

const baseEntityActions = RestApi.createEntityActions(definition);

const projectParentDefinition = {
  ...definition,
  parent: {
    entity: 'projects',
    key: 'project_id',
  },
};
const entityActionsByProject = RestApi.createEntityActions(
  projectParentDefinition,
);

/**
 * Activate Tag
 * @param {LiveCommitTag} liveTag
 */
export function activateTag(liveTag) {
  return LiveCommitTagActions.save({
    layer_id: liveTag.layer_id,
    active: true,
  });
}

/**
 * Deactivate Tag
 * @param {LiveCommitTag} liveTag
 */
export function deactivateTag(liveTag) {
  return LiveCommitTagActions.save({
    layer_id: liveTag.layer_id,
    active: false,
  });
}

/**
 * Persists an entity to the database
 *
 * Overwrite the standard save method because the live tag is an upsert operation
 * on the non-standard url path '/layer/<layer_id>/live'
 * (notice there is no live tag commit tag id in the path because the name of the tag
 * and the layer id uniquely identify the resource)
 *
 * @param {object} data
 */
export function save(data) {
  const onSuccess = onPersistSuccess.bind(
    LiveCommitTagActions,
    definition.entity,
  );
  const onFail = onPersistFail.bind(
    LiveCommitTagActions,
    definition.entity,
    data,
  );

  flux.dispatch(RestApi.actionTypes.API_ENTITY_PERSIST_START, {
    entity: definition.entity,
    data,
  });

  // Check for the enity being stubbed and if so, call the crud_stubs save method instead.
  // This is necessary because this save is overriding the rest_api module save which would have otherwise handled the stub for us.
  if (isEntityStubbed(definition)) {
    // TODO: resolve this circular dependency
    const crudStubs = require('optly/modules/rest_api/crud_stubs'); // eslint-disable-line
    return crudStubs
      .save(definition, data)
      .done(onSuccess)
      .fail(onFail);
  }
  return requester()
    .one(definition.parent.entity, data.layer_id)
    .one(definition.entity, '')
    .put(data)
    .done(onSuccess)
    .fail(onFail);
}

export function fetch(filters, force) {
  // A specific live tag exists at '/layer/<layer_id>/live' which
  // is normally the path for a fetchAll. In this case we can
  // use a fetchAll for fetching one live commit tag
  return baseEntityActions.fetchAll(filters, force).then(result => {
    // Since we are in the context of a fetch, be sure to return an object rather
    // than a list containing the object
    if (Array.isArray(result)) {
      result = result[0];
    }

    return result;
  });
}

export function fetchPage(filters, force) {
  throw 'The /live endpoint is a non-standard endpoint. To use fetchPage, ' + // eslint-disable-line no-throw-literal
    'implement it and test that its functionality is consistent';
}

export function fetchAll(filters, force) {
  // The only way to fetch multiple live commit tags is to fetchAll by project
  return entityActionsByProject.fetchAll(filters, force).then(resp => {
    RestApi.actions.onAncestorFetchAllSuccess(
      filters,
      projectParentDefinition,
      definition,
      resp,
    );
  });
}

const deleteLiveCommitTag = data => {
  throw 'The /live endpoint is a non-standard endpoint. To use delete, ' + // eslint-disable-line no-throw-literal
    'implement it and test that its functionality is consistent';
};

export { deleteLiveCommitTag as delete };

export default LiveCommitTagActions = {
  ...baseEntityActions,
  activateTag,
  deactivateTag,
  save,
  delete: deleteLiveCommitTag,
  fetch,
  fetchPage,
  fetchAll,
};

/**
 * Dispatch an API_ENTITY_PERSIST_SUCCESS event to the system
 * @private
 *
 * @param {string} entity
 * @param {object|array} instance
 */
function onPersistSuccess(entity, instance) {
  flux.dispatch(RestApi.actionTypes.API_ENTITY_PERSIST_SUCCESS, {
    entity,
    data: instance,
  });
  return instance;
}

/**
 * Dispatch an API_ENTITY_PERSIST_FAIL event to the system
 * @private
 *
 * @param {string} entity
 * @param {object} data of the instance
 * @param {string} reason
 */
function onPersistFail(entity, data, reason) {
  flux.dispatch(RestApi.actionTypes.API_ENTITY_PERSIST_FAIL, {
    entity,
    reason,
    data,
  });
  return reason;
}

/**
 * returns whether the entity is stubbed or not
 */
function isEntityStubbed(entityDef) {
  return (
    flux.evaluate(['apiStubs', 'stubAll']) ||
    flux.evaluate(['apiStubs', 'entityData', entityDef.entity])
  );
}

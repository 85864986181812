import actions from './actions';
import constants from './constants';
import fns from './fns';
import getters from './getters';

export { actions, fns, getters, constants };

export default {
  actions,
  fns,
  getters,
  constants,
};

/**
 * Map of event strings to handler functions.
 *
 * @private
 * @type {Map<string, Set<function>>}
 */
// TODO (James)[OPP-227] - Create a separate global event listener.
const listeners = (window.listeners = window.listeners || new Map());

/**
 * Broadcasts a message to the global event system.
 * @param {string} eventType
 * @param {...any} payload
 */
export function $broadcast(eventType, ...args) {
  if (!listeners.get(eventType)) {
    console.warn(
      `[Event System] No handlers registered for eventType ${eventType}`,
    );
    return false;
  }
  listeners.get(eventType).forEach(h => h(...args));
}

/**
 * Registers a global event handler for the specified eventType.
 * @param {string} eventType
 * @param {function} handler
 * @return {function} An unlisten ($off) function.
 */
export function $on(eventType, handler) {
  if (!eventType || typeof handler !== 'function') {
    console.warn(
      `[Event System] $on expects an eventType<String> and a handler<function>, received: ${eventType} - ${handler}`,
    );
    return;
  }
  if (!listeners.get(eventType)) {
    listeners.set(eventType, new Set());
  }
  listeners.get(eventType).add(handler);
  return () => $off(eventType, handler);
}

/**
 * Unregisters the global event handler for the specified eventType.
 * @param {string} eventType
 * @param {function=} handler
 */
export function $off(eventType, handler) {
  if (!listeners.get(eventType)) {
    console.warn(
      `[Event System] No handlers registered for eventType ${eventType}`,
    );
    return false;
  }
  if (handler !== undefined) {
    listeners.get(eventType).delete(handler);
  } else {
    listeners.get(eventType).clear();
  }
}

export default {
  $broadcast,
  $on,
  $off,
};

import Immutable from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'core/ui/decorators';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';

import { getters as EnvironmentGetters } from 'optly/modules/entity/environment';

import EnvironmentsStatusOverview from './environments_status_overview';
import SimpleStatus from './simple_status';
import SimpleEnvironmentStatus from './simple_environment_status';

@connect({
  environments: EnvironmentGetters.entityCache,
  environmentsAuthorizedAndSupported:
    CurrentProjectGetters.environmentsAuthorizedAndSupported,
  environmentStatusesByExperimentId:
    EnvironmentGetters.environmentStatusesByExperimentId,
})
class ExperimentStatus extends React.Component {
  static propTypes = {
    environments: PropTypes.instanceOf(Immutable.Map).isRequired,
    environmentsAuthorizedAndSupported: PropTypes.bool.isRequired,
    environmentStatusesByExperimentId: PropTypes.instanceOf(Immutable.Map)
      .isRequired,
    experiment: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  render() {
    // Render environment status only if authorized and experiment statuses exist
    if (
      this.props.environmentsAuthorizedAndSupported &&
      this.props.environmentStatusesByExperimentId
    ) {
      const environmentStatuses = this.props.environmentStatusesByExperimentId.get(
        this.props.experiment.get('id'),
      );
      if (environmentStatuses && environmentStatuses.size) {
        return (
          <EnvironmentsStatusOverview
            experiment={this.props.experiment}
            experimentEnvironmentsInfo={environmentStatuses}
          />
        );
      }
    }

    // If not authorized, but the experiment has environments status information,
    // display a simple status using the primary environment's status
    const primaryEnvInfo = (
      this.props.experiment.get('environments') || Immutable.Map()
    ).find(envInfo =>
      this.props.environments.getIn([
        envInfo.get('environment_id'),
        'is_primary',
      ]),
    );
    if (primaryEnvInfo) {
      return (
        <SimpleEnvironmentStatus
          status={primaryEnvInfo.get('status')}
          isLaunched={this.props.experiment.get('is_launched')}
        />
      );
    }

    return (
      <SimpleStatus
        status={this.props.experiment.get('actual_status')}
        isLaunched={this.props.experiment.get('is_launched')}
      />
    );
  }
}

export default ExperimentStatus;

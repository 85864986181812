import { toImmutable } from 'optly/immutable';

import { enums as LayerExperimentEnums } from 'optly/modules/entity/layer_experiment';

const PROD_ENVIRONMENT_ID = 'PRODUCTION';
const { RUNNING: RUNNING_STATUS } = LayerExperimentEnums.ActualStatus;

/**
 * Asserts that the feature assigned to the current experiment has not been assigned to other experiments.
 * Or if it is, then the experiment must be in the same group as this experiment if those other
 * experiments are running.
 *
 * @param  {Immutable.Map} currentlyEditingExperiment
 * @param  {Immutable.List} currentProjectFullStackExperiments - this should be used with the fullStackLayerExperimentsWrapper, which includes experiment group ID
 * @param  {Boolean} environmentsAuthorizedAndSupported
 * @return {Boolean}
 */
export function isFeatureAssignmentValid(
  currentlyEditingExperiment,
  currentProjectFullStackExperiments,
  environmentsAuthorizedAndSupported,
) {
  // Return true for experiments that do not have an id and haven't been created on the backend and couldn't possibly be running
  if (!currentlyEditingExperiment.get('id')) {
    return true;
  }

  // First, attempt to find the existing experiment from the entity cache based on the ID and merge it in with currentlyEditingExperiment,
  // this ensures that all data is available in the case that currentlyEditingExperiment is incomplete. Otherwise, just use the currentlyEditingExperiment.
  const experimentFromEntityCache = currentProjectFullStackExperiments.find(
    experiment => experiment.get('id') === currentlyEditingExperiment.get('id'),
  );
  let experimentToValidate = experimentFromEntityCache
    ? experimentFromEntityCache.mergeDeep(currentlyEditingExperiment)
    : currentlyEditingExperiment;
  const featureId = experimentToValidate.get('feature_flag_id');

  /*
   * If feature_flag_id of the experiment isn't a number, there is no feature
   * assigned to the experiment, so it is valid.
   */
  if (!Number.isFinite(featureId)) {
    return true;
  }

  // Store currentProjectFullStackExperiments in a variable in case it needs to be updated for non-ENVIRONMENT accounts
  let fullStackExperiments = currentProjectFullStackExperiments;

  // For accounts WITHOUT Environments enabled, overwrite the environments Map with a temporary "PRODUCTION" environment alias...
  // This allows us to use the same logic that we do with environments below
  if (!environmentsAuthorizedAndSupported) {
    // TODO(OASIS-2588) do this ahead of time for many to use in the fullStackLayerExperimentsWrapper getter, or just remove when all accounts are environments
    const makeEnvironmentAlias = experiment =>
      toImmutable({
        [PROD_ENVIRONMENT_ID]: {
          environment_id: PROD_ENVIRONMENT_ID,
          status: experiment.get('actual_status'),
        },
      });

    // Set environment alias on the experimentToValidate Map and the Maps in the fullStackExperiments List
    experimentToValidate = experimentToValidate.set(
      'environments',
      makeEnvironmentAlias(experimentToValidate),
    );
    fullStackExperiments = currentProjectFullStackExperiments.map(experiment =>
      experiment.set('environments', makeEnvironmentAlias(experiment)),
    );
  }

  // Return early if the experiment is not running in any environments - it should be allowed to be configured in any way until it's running
  const experimentToValidateIsNotRunningInAnyEnvironment = !experimentToValidate
    .get('environments')
    .some(environment => environment.get('status') === RUNNING_STATUS);
  if (experimentToValidateIsNotRunningInAnyEnvironment) {
    return true;
  }

  // Create an Immutable list of Environment IDs where where the currentlyEditingExperiment is running
  const runningEnvironmentIds = experimentToValidate
    .get('environments')
    .filter(environment => environment.get('status') === RUNNING_STATUS)
    .toList()
    .map(environment => environment.get('environment_id'));

  // Compute the Environments Map into a List of Environments and set it in a new "environments_list" key
  const experimentsWithEnvironmentsToList = fullStackExperiments.map(
    experiment =>
      experiment.set(
        'environments_list',
        experiment.get('environments', toImmutable({})).toList(),
      ),
  );

  // Ensures ALL the following are true for every Experiment when compared to the currentlyEditingExperiment:
  // a) Using the same feature
  // b) Not the same Experiment
  // c) Not running in any same Environment
  // d) If all of the above are true, ensure it's in the same group, which cannot be the NONE_GROUP
  return !experimentsWithEnvironmentsToList.some(
    exp =>
      exp.get('feature_flag_id') === featureId && // a.
      exp.get('id') !== experimentToValidate.get('id') && // b.
      exp
        .get('environments_list')
        .some(
          env =>
            runningEnvironmentIds.includes(env.get('environment_id')) &&
            env.get('status') === RUNNING_STATUS,
        ) && // c.
      (exp.get('group_id') !== experimentToValidate.get('group_id') ||
        [0, null].includes(exp.get('group_id', null))), // d.
  );
}

export default {
  isFeatureAssignmentValid,
};

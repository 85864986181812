const $ = require('jquery');

const flux = require('core/flux').default;

const HighlighterEnums = require('optly/modules/highlighter/enums');

const _ = require('lodash');

const actionTypes = require('./action_types');
const getters = require('./getters');
const enums = require('./enums');
const { setLoggingEnabled, logEmit, logWrite, log } = require('./log_actions');

// const commentOutOptimizelyEdgeRegex = /\/\/\s*(?:const|let|var).+window\.optimizelyEdge|\/\*\s*.*\s*.+(?:const|let|var).+window\.optimizelyEdge/gm;
const commentOutOptimizelyEdgeRegex = /\/\/\s*.*window\.optimizelyEdge|\/\*\s*.*\s*.*window/gm;
const optimizelyEdgeRegex = /(?:const|let|var)*.+window\.optimizelyEdge/gm;

const dispatchSetWidth = _.debounce(
  (id, width) => {
    flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_SET_WIDTH, {
      iframeId: id,
      width,
    });
  },
  __TEST__ ? 0 : 50,
);

/**
 * Sets the width of the EditorIframe component
 *
 * @param {String} id
 * @param {Number|String} width in pixels or 100%
 * @param {Object} component
 */
const setWidth = function(id, width, component) {
  // Enforce a minimum width (applicable during drag resizing).
  if (width < enums.Sizes.mobile.width) {
    return;
  }

  // Debounce dispatch for updating the store with new width since the case of drag resizing can result in this method
  // being called for each pixel change. However, do not debounce the update in the component so that the user can see
  // the drag changes immediately without a lag.
  dispatchSetWidth(id, width);
  component.setWidth(width);
};

/**
 * Sets the selectedDevice of the EditorIframe component
 * @param {String} id
 * @param {Object} selectedDevice
 */
const setSelectedDevice = function(id, selectedDevice) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_SET_SELECTED_DEVICE, {
    iframeId: id,
    selectedDevice,
  });
};

/**
 * Sets isLandscapeMode (to true or false) and sets width and height based on value of setToLandscapeMode
 * @param {String} id
 * @param {Boolean} setToLandscapeMode
 * @param {Object} component
 * @param {Object} selectedDevice
 * @param {Number|String} selectedDevice.height
 * @param {Number|String} selectedDevice.width
 */
const setIsLandscapeMode = function(
  id,
  setToLandscapeMode,
  component,
  selectedDevice,
) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_SET_IS_LANDSCAPE_MODE, {
    iframeId: id,
    isLandscapeMode: setToLandscapeMode,
  });

  if (setToLandscapeMode) {
    component.setWidth(selectedDevice.height);
    component.setHeight(selectedDevice.width);
  } else {
    component.setWidth(selectedDevice.width);
    component.setHeight(selectedDevice.height);
  }
};

function hasTheCodeOptimizelyEdgeText(code) {
  const hasOptimizelyEdgeTextCommentedOut =
    code.match(commentOutOptimizelyEdgeRegex) ?? [];
  const hasOptimizelyEdgeText = code.match(optimizelyEdgeRegex) ?? [];

  return (
    hasOptimizelyEdgeText.length > hasOptimizelyEdgeTextCommentedOut.length
  );
}

/**
 * Write with deferred to the iframe component specified
 * by the id as long as it exists and has not failed to load.
 * Otherwise, return a Deferred rejected with the reason.
 *
 * @param id - The id of the iframe.
 * @param data - The data to write.
 * @returns {Deferred}
 */
exports.writeOrReject = (id, data) => {
  const component = flux.evaluate(getters.iframeComponent(id));
  const status = flux.evaluate(getters.iframeLoadingStatus(id));

  if (!component) {
    return $.Deferred().reject(
      `Cannot write to component that doesnt exist: iframeId=${id}`,
    );
  }
  if (status === enums.IFrameLoadStatuses.FAILED) {
    return $.Deferred().reject(
      `Cannot write to a frame that failed to load: iframeId=${id}`,
    );
  }
  if (status === enums.IFrameLoadStatuses.CANCELED) {
    return $.Deferred().reject(
      `Cannot write to a frame that was canceled: iframeId=${id}`,
    );
  }

  let newData = null;

  if (data.payload.code) {
    newData = {
      ...data,
      isOptimizelyEdgeTextInTargetCode: hasTheCodeOptimizelyEdgeText(
        data.payload.code,
      ),
    };
  }

  return component.writeWithDeferred(newData ?? data);
};

/**
 * Updates the selectedDevice, isLandscapeMode, width, and height, then reloads the page when necessary
 * @param {String} id
 * @param {Number|String} width in pixels or 100%
 * @param {Object} selectedDevice
 * @param {Number|String} selectedDevice.height
 * @param {Number|String} selectedDevice.width
 * @param {String} selectedDevice.userAgent
 * @param {Object} lastSelectedDevice
 */
exports.setSelectedDeviceAndWidth = function(
  id,
  width,
  selectedDevice,
  lastSelectedDevice,
) {
  if (selectedDevice !== lastSelectedDevice) {
    setSelectedDevice(id, selectedDevice);
  }

  const component = flux.evaluate(getters.iframeComponent(id));
  if (component) {
    setWidth(id, width, component);
    if (lastSelectedDevice.userAgent) {
      // Reset the orientation of the device to portrait mode (width and height aren't swapped). This condition is here
      // since there is no need to reset if previously the user was not emulating (lastSelectedDevice.userAgent is null).
      setIsLandscapeMode(id, false, component, selectedDevice);
    }
    if (lastSelectedDevice.userAgent !== selectedDevice.userAgent) {
      component.setHeight(selectedDevice.height);
      exports.reloadCurrentUrl(id);
    }
  }
};

/**
 * Checks whether dimensions have been swapped or not (toggling between portrait and landscape) and sets isLandscapeMode
 * to the opposite of its current value
 * @param {String} id
 * @param {Object} selectedDevice
 */
exports.swapDimensions = function(id, selectedDevice) {
  const component = flux.evaluate(getters.iframeComponent(id));
  if (component) {
    const isLandscapeMode = flux.evaluate(getters.isLandscapeMode(id));
    setIsLandscapeMode(id, !isLandscapeMode, component, selectedDevice);
  }
};

// expose exports from log_actions
exports.setLoggingEnabled = setLoggingEnabled;
exports.logEmit = logEmit;
exports.logWrite = logWrite;
exports.log = log;

/**
 * Adds an iframe component reference to the manager store
 * @param {Vue} instance component instance reference
 */
exports.addIFrame = function(instance) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_ADD_IFRAME, {
    id: instance.id,
    component: instance,
    url: instance.url,
    editorType: instance.editorType,
  });
};

/**
 * removes an iframe component reference to the manager store
 * @param {string} id User supplied id for component
 */
exports.removeIFrame = function(id) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_REMOVE_IFRAME, {
    id,
  });
};

/**
 * register a url to iframe protocol mapping (to enable proxy timeout shortcutting)
 * @param {string} url
 * @param {string} protocol
 */
exports.registerProtocolForUrl = function(url, protocol) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_REGISTER_PROTOCOL_FOR_URL, {
    url,
    protocol,
  });
};

/**
 * unregister a view id to iframe protocol mapping
 * @param {string} url
 */
exports.unregisterProtocolForUrl = function(url) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_UNREGISTER_PROTOCOL_FOR_URL, {
    url,
  });
};

/**
 * Atomic action that will apply a changeset and upon completion of that changeset application,
 * return element info for the supplied selector
 *
 * @param {String} id Id of the iframe to communicate with
 * @param {ChangeSet} changes Changeset to be applied to the iframe
 * @param {String} selector CSS selector for elements we want to retrieve info
 * @param {String} partialUndoChangeId The changeId to use for partial undoing of existing changes.
 * @return {deferred}
 */
exports.applyChangesAndFetchElementInfo = function(
  id,
  changes,
  selector,
  partialUndoChangeId,
) {
  return exports.writeOrReject(id, {
    type: enums.IFrameMessageTypes.APPLY_CHANGES_AND_FETCH_ELEMENT_INFO,
    payload: {
      changes,
      selector,
      partialUndoChangeId,
    },
  });
};

/**
 * Destroys an iframe component if it exists in the store
 * @param {string} iFrameId id for iFrame component
 */
exports.destroyIFrame = function(iFrameId) {
  const component = flux.evaluate(getters.iframeComponent(iFrameId));
  if (component) {
    component.$destroy();
  }
};

/**
 * Fetch the element information for a given iframe and returns a deferred resolved
 * with the ElementInfo
 * @param {String} id Id of the iframe to communicate with
 * @param {String} selector CSS selector for elements we want to retrieve info
 * @param {String} partialUndoChangeId The changeId to use for partial undoing of existing changes.
 * @return {Deferred}
 */
exports.fetchElementInfo = function(id, selector, partialUndoChangeId) {
  return exports.writeOrReject(id, {
    type: enums.IFrameMessageTypes.FETCH_ELEMENT_INFO,
    payload: {
      selector,
      partialUndoChangeId,
    },
  });
};

/**
 * Fetch selectors for the children and parents related to the provided selector for a given iframe
 * returns a deferred resolved with the related selector info
 * @param {String} id - Id of the iframe to communicate with
 * @param {String} selector - CSS selector for elements we want to retrieve info
 * @return {Deferred}
 */
exports.fetchRelatedElementSelectors = function(id, selector) {
  return exports.writeOrReject(id, {
    type: enums.IFrameMessageTypes.FETCH_RELATED_ELEMENT_SELECTORS,
    payload: {
      selector,
    },
  });
};

/**
 * Fetch value for a given tag
 *
 * @param {string} id Id of the iframe to communicate with
 * @param {object} tag
 * @return {Deferred}
 */
exports.fetchTagValue = function(id, tag) {
  return exports.writeOrReject(id, {
    type: enums.IFrameMessageTypes.FETCH_TAG_VALUE,
    payload: {
      tag,
    },
  });
};

/**
 * Apply a changeset to the iframe
 *
 * @param {string} id Id of the iframe to communicate with
 * @param {ChangeSet} changes Changeset to be applied to the iframe
 *
 */
exports.applyChanges = function(id, changes) {
  const component = flux.evaluate(getters.iframeComponent(id));
  if (component) {
    component.write({
      type: enums.IFrameMessageTypes.APPLY_CHANGES,
      payload: {
        changes,
      },
    });
  }
};

/**
 * Highlight elements matching selector in the specified iframe
 * @param {object} options
 * @param {string} options.id Id of the iframe to communicate with
 * @param {string} options.dataOptlyId The id portion of the data-optly-<id> attribute for the element to highlight.
 * @param {string} options.selector CSS selector of elements we want to highlight
 * @param {string} options.type Type of highlighting we want to apply (eg SELECTED, LIVE, ...)
 * @param {string} options.label Text to display for the cursor label
 * @param {string} options.selectorType the type of element we are highlighting (eg TAG, EVENT)
 */
exports.highlightElement = function(options) {
  const component = flux.evaluate(getters.iframeComponent(options.id));
  if (component) {
    flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_HIGHLIGHT_ELEMENT, {
      id: options.id,
      dataOptlyId: options.dataOptlyId,
      selector: options.selector,
      type: options.type,
    });

    const payload = {
      dataOptlyId: options.dataOptlyId,
      selector: options.selector,
      type: options.type,
    };

    if (options.label) {
      payload.label = options.label;
    }

    if (options.selectorType) {
      payload.selectorType = options.selectorType;
    }

    component.write({
      type: enums.IFrameMessageTypes.HIGHLIGHT_ELEMENT,
      payload,
    });
  }
};

/**
 * Unhighlight elements matching selector in the specified iframe
 * @param {object} options
 * @param {string} options.id Id of the iframe to communicate with
 * @param {string} options.dataOptlyId The id property of the data-optly-<id> attribute.
 * @param {string} options.selector CSS selector of elements we want to unhighlight
 * @param {string} options.type Type of highlighting we want to unapply (eg SELECTED, LIVE, ...)
 *
 */
exports.unhighlightElement = function(options) {
  const component = flux.evaluate(getters.iframeComponent(options.id));
  if (component) {
    flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_UNHIGHLIGHT_ELEMENT, {
      id: options.id,
      dataOptlyId: options.dataOptlyId,
      selector: options.selector,
      type: options.type,
    });
    component.write({
      type: enums.IFrameMessageTypes.UNHIGHLIGHT_ELEMENT,
      payload: {
        dataOptlyId: options.dataOptlyId,
        selector: options.selector,
        type: options.type,
      },
    });
  }
};

/**
 * Unhighlight all highlighted elements in the iframe
 *
 * @param {string} id Id of the iframe to communicate with
 *
 */
exports.unhighlightAllElements = function(id) {
  const component = flux.evaluate(getters.iframeComponent(id));
  if (component) {
    flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_UNHIGHLIGHT_ALL_ELEMENTS, {
      id,
    });
    _.each(HighlighterEnums.IFrameHighlightTypes, messageType => {
      component.write({
        type: enums.IFrameMessageTypes.UNHIGHLIGHT_ELEMENT,
        payload: {
          type: messageType,
        },
      });
    });
  }
};

/**
 * Set interactive mode on/off
 *
 * @param {string} id Id of the iframe to communicate with
 * @param {enums.IFrameModeTypes} mode
 *
 */
exports.setMode = function(id, mode) {
  const component = flux.evaluate(getters.iframeComponent(id));
  if (component) {
    flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_SET_MODE, {
      id,
      mode,
    });
    component.write({
      type: enums.IFrameMessageTypes.SET_MODE,
      payload: {
        mode,
      },
    });
  }
};

/**
 * Set the type of editor being used for a particular frame.
 * @param {string} id The id of the editor_iframe component.
 * @param {EditorIframe.enums.EditorTypes} type
 */
exports.setEditorType = function(id, type) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_SET_EDITOR_TYPE, {
    id,
    type,
  });
};

/**
 * Set the ready state
 * @param {string} id The id of the editor_iframe component.
 * @param {EditorIframe.enums.FrameTypes} type
 */
exports.setFrameType = function(id, type) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_SET_FRAME_TYPE, {
    id,
    type,
  });
};

/**
 * Set the ready state
 * @param {string} id The id of the editor_iframe component.
 * @param {Object} options
 * @param {string} options.loadStatus
 * @param {string} options.protocolType
 * @param {number} options.loadDuration
 */
exports.setReadyState = function(id, options) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_SET_READY_STATE, {
    id,
    loadStatus: options.loadStatus,
    protocolType: options.protocolType,
    loadDuration: options.loadDuration,
  });
};

/**
 * Sync the iframe state with what we have stored in this module
 *
 * @param {string} id
 * @param {string} type
 */
exports.syncState = function(id, type) {
  const iframe = flux.evaluateToJS(getters.iframe(id));
  if (iframe) {
    exports.unhighlightAllElements(id);
    _.each(iframe.highlightedElements, highlightedElement => {
      exports.highlightElement({
        id,
        dataOptlyId: highlightedElement.dataOptlyId,
        selector: highlightedElement.selector,
        type: highlightedElement.type,
      });
    });
    exports.setMode(id, flux.evaluateToJS(getters.iframe(id)).mode);
    exports.setEditorType(id, type);
  }
};

/**
 * Reload the currently loaded URL for the iframe component with the given id.
 * @param {string?} id The id of the editor_iframe that should reload its current url.
 */
exports.reloadCurrentUrl = function(id) {
  const component = flux.evaluate(getters.iframeComponent(id));
  if (component) {
    component.reloadCurrentUrl();
  }
};

/**
 * Look up the iframe component with the given id. If its url differs from
 * newUrl, tell it to load newUrl and dispatch an action to update its displayed
 * url
 *
 * @param {String} id - The id of the editor_iframe that should load the new url
 * @param {String} newUrl
 * @param {Array} protocols to try to load, defaults to all protocols
 */
exports.setCurrentUrl = function(id, newUrl, protocols) {
  protocols = protocols || _.keys(enums.ProtocolTypes);
  const component = flux.evaluate(getters.iframeComponent(id));
  const iframe = flux.evaluate(getters.iframe(id));

  // reload the iframe, if its url or protocols differ
  if (
    component &&
    iframe &&
    (iframe.get('url') !== newUrl ||
      !_.includes(protocols, iframe.get('protocolType')))
  ) {
    component.loadUrl(newUrl, protocols);
    flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_SET_URL, {
      id,
      url: newUrl,
    });
  }
};

/**
 * Reset all stores to original state
 *
 */
exports.reset = function() {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_RESET);
};

/**
 * Toggles interactive mode for the editor.
 * @param {EditorIframe.enums.IFrameModeTypes} mode
 * @param {string} id The id of the iframe to set the mode for.
 */
exports.toggleInteractiveMode = function(mode, id) {
  if (mode === enums.IFrameModeTypes.INTERACTIVE) {
    mode = enums.IFrameModeTypes.STANDARD;
  } else {
    mode = enums.IFrameModeTypes.INTERACTIVE;
  }
  exports.setMode(id, mode);
};

/**
 * Makes a request to innie that will compute functional equality of selector and any selectors in
 * selectorArray
 * @param {string} id
 * @param {string} selector
 * @param {string} selectorArray
 * @returns {Deferred}
 */
exports.testSelectorEquality = function(id, selector, selectorArray) {
  return exports.writeOrReject(id, {
    type: enums.IFrameMessageTypes.TEST_SELECTOR_EQUALITY,
    payload: {
      selector,
      selectorsToTest: selectorArray,
    },
  });
};

/**
 * Evals raw JS code in the iframe
 */
exports.evalCode = (id, code) =>
  exports
    .writeOrReject(id, {
      type: enums.IFrameMessageTypes.EVAL,
      payload: {
        code,
      },
    })
    .then(response => {
      flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_EVAL_COMPLETE, {
        id,
        error: response.error,
      });
      return response;
    });

/**
 * Increment the number of times an error alert has been shown for any given iframe
 * @param {string} id - iframe id for the associated error
 */
exports.incrementAlertShownCount = function(id) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_INCREMENT_ALERT_COUNT, {
    id,
  });
};

/**
 * Entity Definition for dynamic condition
 */
export default {
  entity: 'conditions',

  isRelationshipEntity: true,

  parents: [
    {
      entity: 'projects',
      key: 'project_id',
    },
    {
      entity: 'conditions',
      key: 'integration_id',
    },
  ],
};

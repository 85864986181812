import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import _ from 'lodash';

import actionTypes from '../action_types';

/**
 * componentDataStore
 * Responsible for the following state management:
 * TODO: fill in
 */
export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({});
  },

  initialize() {
    this.on(actionTypes.LINK_REGISTER, registerComponent);
    this.on(actionTypes.LINK_SET, setLinkState);
    this.on(actionTypes.LINK_DELETE, deleteComponent);
  },
});

/**
 * Handler for actionTypes.LINK_DELETE
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 */
function deleteComponent(state, payload) {
  return state.delete(payload.id);
}

/**
 * Handler for actionTypes.LINK_REGISTER
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 * @param {Object} payload.initialState
 */
function registerComponent(state, payload) {
  if (!_.isUndefined(state.getIn([payload.id]))) {
    throw new Error(
      'Attempted to register component under an already active flux id',
    );
  }
  return state.set(payload.id, toImmutable(payload.initialState));
}

/**
 * Handler for actionTypes.LINK_SET
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 * @param {*} payload.value
 */
function setLinkState(state, payload) {
  if (!_.isUndefined(state.get(payload.id))) {
    return state.set(payload.id, toImmutable(payload.value));
  }
  throw new Error('Attempted to set non-existent id:', payload.id);
}

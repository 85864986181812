import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';
import { toImmutable } from 'optly/immutable';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const entityToGroupMap = [
  baseEntityGetters.entityCache,
  groupsMap => {
    const experimentMap = {};
    groupsMap.forEach(group => {
      if (group.has('entities')) {
        group.get('entities').forEach(entity => {
          experimentMap[entity.get('id')] = group;
        });
      }
    });

    return toImmutable(experimentMap);
  },
];

export default {
  ...baseEntityGetters,
  entityToGroupMap,
};

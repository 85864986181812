/**
 * Stubbable wrapper around window.localStorage
 */
const _ = require('lodash');
const localStorageMetaWrapper = require('optly/modules/local_storage_meta');
/**
 * @param {String} key
 * @param {String} value
 */
exports.setItem = function(key, value) {
  const dateModified = _.now();
  localStorageMetaWrapper.actions.writeToLocalStorage(key, value, dateModified);
};

/**
 * @param {String} key
 * @return {String|undefined}
 */
exports.getItem = function(key) {
  return localStorageMetaWrapper.actions.readFromLocalStorage(key);
};

/**
 * @param {String} key
 */
exports.removeItem = function(key) {
  localStorageMetaWrapper.actions.removeFromLocalStorage(key);
};

exports.clear = function() {
  window.localStorage.clear();
  localStorageMetaWrapper.actions.clearLocalStorageMetaStore();
};

/**
 * @param {Number} index
 * @return {String|undefined}
 */
exports.key = function(index) {
  return window.localStorage.key(index);
};

/**
 * Entity Definition for live commit tag
 */

export const entity = 'live';

export const parent = {
  entity: 'layers',
  key: 'layer_id',
};

export default {
  entity,
  parent,
};

import React, { useEffect, useMemo, useState } from 'react';
import { Input, Select, Poptip, Label } from '@optimizely/axiom';
import PropTypes from 'prop-types';
import { useFeature } from '@optimizely/react-sdk';

import { constants as MetricConstants } from 'optly/modules/entity/metric';

import {
  METRIC_TYPES,
  METRIC_TYPE_TOOLTIP_CONTENT,
  COMPOUND_METRIC_TYPE,
} from './constants';
import { EventsSelectorWrapper as EventsSelector } from './events_selector';
import { Measure } from './measure';
import { getDefaultAggregatorValue } from './utils';
import { EventPropertiesFilter } from './event_properties';
import { CompoundMetricEvents } from './compound_metric_events';

export function MetricsForm({
  formErrors,
  formValues,
  onChange,
  initialScopeValue,
  getScopeOptions,
  getMetricFormToEdit,
}) {
  const [availableMetricTypes, setAvailableMetricTypes] = useState(
    METRIC_TYPES,
  );
  const [isCompoundMetricsFeatureEnabled] = useFeature('compound_metrics');

  const isCompoundMetric = useMemo(
    () => formValues.type === COMPOUND_METRIC_TYPE,
    [formValues.type],
  );

  useEffect(() => {
    if (isCompoundMetricsFeatureEnabled) {
      setAvailableMetricTypes(metricTypes => ({
        ...metricTypes,
        COMPOUND: COMPOUND_METRIC_TYPE,
      }));
    }
  }, [formValues.type]);

  const clearForm = () => {
    onChange('event', {});
    onChange('winningDirection', MetricConstants.winning_direction.INCREASING);
    onChange('aggregator', '');
    onChange('scope', initialScopeValue);
    onChange('conditions', {});
    onChange('compoundNumerator', null);
    onChange('compoundDenominator', null);
    onChange('combineOperator', 'and');
    onChange('filterByProperties', false);

    if (!formValues.type) {
      onChange('type', METRIC_TYPES.CUSTOM);
    }
  };

  useEffect(() => {
    if (isCompoundMetric && !getMetricFormToEdit) {
      clearForm();
    }
  }, [isCompoundMetric]);

  const onEventChange = event => {
    clearForm();
    onChange('event', event);
    onChange('aggregator', getDefaultAggregatorValue(event));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        minHeight: '500px',
      }}>
      <div>
        <Label>Metric Type</Label>
        <Poptip
          content={METRIC_TYPE_TOOLTIP_CONTENT}
          isInline={true}
          disabled={formValues.isDraft}>
          <Select
            isDisabled={!formValues.isDraft}
            onChange={e => onChange('type', e.target.value)}
            value={formValues.type}
            className="display--inline-block"
            testSection="metric-type-dropdown">
            {Object.values(availableMetricTypes).map(option => {
              return (
                <option value={option} key={`metric-type-option-[${option}]`}>
                  {option}
                </option>
              );
            })}
          </Select>
        </Poptip>
      </div>
      <Input
        placeholder={formValues.event.name || 'Metric name'}
        label="Name"
        value={formValues.name}
        onChange={e => onChange('name', e.target.value)}
        displayError={!!formErrors.name}
        note={formErrors.name}
        testSection="metric-name-input"
      />
      {!isCompoundMetric && (
        <div>
          <Label>Event</Label>
          <EventsSelector
            value={formValues.event.name}
            metricType={formValues.type}
            onChange={onEventChange}
            isDisabled={!formValues.isDraft}
          />
        </div>
      )}
      <Measure
        onChange={onChange}
        formValues={formValues}
        getScopeOptions={getScopeOptions}
      />
      {!isCompoundMetric && (
        <EventPropertiesFilter
          onChange={onChange}
          formValues={formValues}
          error={formErrors.conditions}
        />
      )}
      {isCompoundMetric && (
        <CompoundMetricEvents
          onChange={onChange}
          formValues={formValues}
          isDisabled={!formValues.isDraft}
          error={formErrors.ratio}
        />
      )}
    </div>
  );
}

MetricsForm.propTypes = {
  formErrors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default { MetricsForm };

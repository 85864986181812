import React from 'react';
import PropTypes from 'prop-types';
import { partial } from 'lodash';

import ConditionGroup from 'optly/models/condition_group';
import { enums as ViewEnums, fns as ViewFns } from 'optly/modules/entity/view';
import { Button, SelectDropdown } from 'optimizely-oui';

const AddCondition = props => {
  const {
    canUseViewConditionCustomCode,
    canUseViewConditionElementPresent,
    conditionGroup,
    conditionGroupDidMutate,
    getHelpLinkProps,
  } = props;

  /**
   * Returns a list of Condition Option objects that will be passed directly to the
   * <SelectDropdown>.
   * @returns {Array}
   */
  const getAddConditionOptions = () => {
    return [
      {
        label: tr('URL Match'),
        description: tr('Match the visitors URL.'),
        value: ViewEnums.conditionMatchTypes.URL,
        shouldRender: true,
      },
      {
        label: tr('Element is present'),
        description: tr('Check if an element present on the page.'),
        value: ViewEnums.conditionMatchTypes.ELEMENT_PRESENT,
        shouldRender: canUseViewConditionElementPresent,
        ...getHelpLinkProps(),
      },
      {
        label: tr('JavaScript Condition'),
        description: tr('Write your own conditional logic.'),
        value: ViewEnums.conditionMatchTypes.CUSTOM_CODE,
        shouldRender: canUseViewConditionCustomCode,
        ...getHelpLinkProps(),
      },
    ].filter(option => option.shouldRender);
  };

  const addCondition = conditionMatchType => {
    const initialConditionObject =
      ViewEnums.defaultConditionConfigs[conditionMatchType];
    const newConditionGroup = ViewFns.createConditionGroup(
      initialConditionObject,
    );
    conditionGroup.conditions.push(newConditionGroup);
    conditionGroupDidMutate();
  };

  const availableConditions = getAddConditionOptions();

  return (
    <div className="push-quad--bottom width--1-1">
      {availableConditions.length === 1 && (
        <Button
          width="full"
          onClick={partial(addCondition, availableConditions[0].value)}
          testSection="add-condition">
          Add {availableConditions[0].label} Condition
        </Button>
      )}
      {availableConditions.length > 1 && (
        <SelectDropdown
          buttonStyle="outline-reverse"
          items={availableConditions}
          initialPlaceholder={<div className="text--left">Add Condition</div>}
          value="Add Condition"
          testSection="add-condition"
          onChange={addCondition}
          width={260}
          minDropdownWidth={260}
        />
      )}
    </div>
  );
};

AddCondition.propTypes = {
  canUseViewConditionCustomCode: PropTypes.bool.isRequired,
  canUseViewConditionElementPresent: PropTypes.bool.isRequired,
  conditionGroup: PropTypes.instanceOf(ConditionGroup).isRequired,
  conditionGroupDidMutate: PropTypes.func.isRequired,
  getHelpLinkProps: PropTypes.func,
};

AddCondition.defaultProps = {
  getHelpLinkProps: () => {},
};

export default AddCondition;

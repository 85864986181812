import FieldTypes from 'optly/modules/rest_api/field_types';

export const entity = 'goals';

export const parent = {
  entity: 'projects',
  key: 'project_id',
};

export const fieldTypes = {
  experiment_ids: FieldTypes.ARRAY,
  created: FieldTypes.DATE,
};

/**
 * Entity Definition for goal
 */
export default {
  entity,
  fieldTypes,
  parent,
};

/**
 * OptlyX SCS Interface
 * A SingleSPA compliant interface to mount the main OptimizelyX panel.
 */
// webpack bundle bootstrap MUST be the first module loaded
import 'core/utils/webpack_bundle_bootstrap';
import { bootstrap, mount, unmount } from './lifecycle';

import { makeExternallyConsumable } from './api';
import ChangeHistoryComponent from './components/change_history';
import {
  EventsAndMetricsBuilderWithData as EMBWithData,
  EventsAndMetricsBuilderWithDataProps,
} from './components/flags_events_and_metrics_builder';
import FeaturesEmptyState from './sections/features/pages/features_dashboard/components/features_empty_state';
import FeaturesQuickstart from './sections/features/pages/features_dashboard/components/features_quickstart';
import FlagsAudienceCombinationsBuilder, {
  FlagsAudienceCombinationsBuilderProps,
} from './components/audience_combinations_builder/flags_audience_combinations_builder';
import FlagsAudienceCombinationsName from './components/audience_combinations_builder/flags_audience_combinations_name';
import FlagsAudienceSearchPicker from './components/flags_audience_search_picker';
import ProjectNameComponent from './components/project_name';
import AudienceCombinationsBuilderV3Component from './components/audience_combination_builder_v3';
import AudienceLabelingWrapperComponent from './components/audience_labeling_wrapper';
import { EventsSelectorWrapper } from './components/metrics/metrics_modal/events_selector';

export { bootstrap, mount, unmount };
export const AudienceCombinationsBuilderWithData = makeExternallyConsumable(
  FlagsAudienceCombinationsBuilder,
);
export const EventsSelector = makeExternallyConsumable(EventsSelectorWrapper);
export const AudienceCombinationsName = makeExternallyConsumable(
  FlagsAudienceCombinationsName,
);
export const AudienceSearchPicker = makeExternallyConsumable(
  FlagsAudienceSearchPicker,
);
export const ChangeHistory = makeExternallyConsumable(ChangeHistoryComponent);
export const EventsAndMetricsBuilderWithData = makeExternallyConsumable(
  EMBWithData,
);
export const FeaturesQuickstartDialog = makeExternallyConsumable(
  FeaturesQuickstart,
);
export const FeaturesQuickstartEmptyState = makeExternallyConsumable(
  FeaturesEmptyState,
);
export const AudienceCombinationsBuilderV3 = makeExternallyConsumable(
  AudienceCombinationsBuilderV3Component,
);
export const AudienceLabelingWrapper = makeExternallyConsumable(
  AudienceLabelingWrapperComponent,
);
export const ProjectName = makeExternallyConsumable(ProjectNameComponent);

export default {
  bootstrap,
  mount,
  unmount,

  AudienceCombinationsBuilderV3,
  AudienceCombinationsBuilderWithData,
  EventsSelector,
  AudienceCombinationsBuilderWithDataProps: FlagsAudienceCombinationsBuilderProps,
  AudienceCombinationsName,
  AudienceLabelingWrapper,
  AudienceSearchPicker,
  ChangeHistory,
  EventsAndMetricsBuilderWithData,
  EventsAndMetricsBuilderWithDataProps,
  FeaturesQuickstartDialog,
  FeaturesQuickstartEmptyState,
  ProjectName,
};

import { toImmutable } from 'optly/immutable';
import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definitions from './entity_definitions';

// entityCache getter for /api/v1/project/<proj_id>/collaborators entities (accepted)
const v1EntityGetters = createEntityGetters(definitions.v1);
// entityCache getter for /api/v1/project/<proj_id>/collaborators_v2 entities (invited)
const v2EntityGetters = createEntityGetters(definitions.v2);

/**
 * @constant entityCacheGetter - a custom entityCache getter that pulls and merges data from 2
 * separate entityCaches (/collaborators and /collaborators_v2 APIs) so that the collaborators list
 * view shows both invited (/collaborators_v2) and accepted (/collaborators) collaborators.  This is
 * only required due to a wonky GET request API landscape for collaborators 😭. All getters
 * in this file should use this custom entityCacheGetter as the base getter for consistency.
 */
const entityCacheGetter = [
  v1EntityGetters.entityCache,
  v2EntityGetters.entityCache,
  (v1Collaborators, v2Collaborators) => {
    const v2Modified = (v2Collaborators || []).map(collaborator =>
      // ensure user_id (email) property is always set (v1 and v2 APIs use a different property for email)
      collaborator.merge({
        user_id: collaborator.get('target_user_email'),
        is_v2: true,
      }),
    );
    return v1Collaborators.concat(v2Modified);
  },
];

export default {
  // default entity cache getter that exposes both invited and accepted collaborators
  entityCache: entityCacheGetter,

  byId: id => [entityCacheGetter, entityMap => entityMap.get(id)],

  byUserId: userId => [
    entityCacheGetter,
    collaborators => {
      const matched = collaborators.filter(
        collaborator => collaborator.get('user_id') === userId,
      );
      return matched && matched.size > 0 ? matched.first() : undefined;
    },
  ],

  emailToCollaboratorMap: [
    entityCacheGetter,
    collaborators =>
      toImmutable({}).withMutations(collaboratorsMap => {
        collaborators.forEach(collaborator => {
          collaboratorsMap.set(collaborator.get('user_id'), collaborator);
        });
      }),
  ],
};

import Layer from 'optly/modules/entity/layer';
import {
  getters as LayerExperimentGetters,
  enums as LayerExperimentEnums,
} from 'optly/modules/entity/layer_experiment';
import { getters as LiveCommitTagGetters } from 'optly/modules/entity/live_commit_tag';
import { fns as PermissionsModuleFns } from 'optly/modules/permissions';
import { getters as VerifierGetters } from 'optly/modules/verifier';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { getters as ProjectGetters } from 'optly/modules/entity/project';
import { isWinnerRolloutFeatureEnabled } from 'optly/utils/features';

import humanReadable from './human_readable';

import fns from './fns';

export function publishStatus(layerId) {
  return [
    [
      'p13n/campaignOverview/sidebar/publishStatus',
      layerId,
      'isInProcessOfPausing',
    ],
    [
      'p13n/campaignOverview/sidebar/publishStatus',
      layerId,
      'isInProcessOfPublishing',
    ],
    [
      'p13n/campaignOverview/sidebar/publishStatus',
      layerId,
      'isInProcessOfStarting',
    ],
    [
      'p13n/campaignOverview/sidebar/publishStatus',
      layerId,
      'isInProcessOfFirstPublish',
    ],
    function(
      isInProcessOfPausing,
      isInProcessOfPublishing,
      isInProcessOfStarting,
      isInProcessOfFirstPublish,
    ) {
      return {
        pausing: isInProcessOfPausing || false,
        publishing: isInProcessOfPublishing || false,
        starting: isInProcessOfStarting || false,
        firstPublish: isInProcessOfFirstPublish || false,
      };
    },
  ];
}

export function isChangingStatus(layerId) {
  return [
    this.publishStatus(layerId),
    statuses => statuses.pausing || statuses.publishing || statuses.starting,
  ];
}

export function liveTag(layerId) {
  return [
    LiveCommitTagGetters.liveCommitTagByLayerId(layerId),
    liveTag => liveTag || null,
  ];
}

/**
 * Returns true if the live layer commit tag associated with
 * this layer exists and is active
 */
export function isActive(layerId) {
  return [
    LiveCommitTagGetters.liveCommitTagByLayerId(layerId),
    liveTag => (liveTag && liveTag.get('active') === true) || false,
  ];
}

/**
 * Returns true if the live layer commit tag associated with
 * this layer exists and is not active
 */
export function isPaused(layerId) {
  return [
    LiveCommitTagGetters.liveCommitTagByLayerId(layerId),
    liveTag => (liveTag && liveTag.get('active') === false) || false,
  ];
}

/**
 * Returns true if there is no live commit tag associated with
 * the layer, meaning the campaign is in draft status
 */
export function isDraft(layerId) {
  return [
    LiveCommitTagGetters.liveCommitTagByLayerId(layerId),
    liveTag => !liveTag || liveTag.get('active') === undefined,
  ];
}

// TODO: Possible duplicate logic in deriving status text: https://optimizely.atlassian.net/browse/WEB-1662
// See optimizely/src/www/frontend/src/js/bundles/p13n/sections/layers/pages/layers_dashboard/components/layer_table_row/index.js
export function statusText(layerId) {
  return [
    Layer.getters.byId(layerId),
    this.isActive(layerId),
    this.isPaused(layerId),
    this.isDraft(layerId),
    this.publishStatus(layerId),
    (layer, isActive, isPaused, isDraft, publishStatus) => {
      let status;
      if (publishStatus.pausing) {
        status = humanReadable.statusText.PAUSING;
      } else if (publishStatus.starting) {
        status = humanReadable.statusText.STARTING;
      } else if (publishStatus.firstPublish) {
        status = humanReadable.statusText.STARTING;
      } else if (layer && layer.get('archived')) {
        status = humanReadable.statusText.ARCHIVED;
      } else if (isActive) {
        status = humanReadable.statusText.RUNNING;
      } else if (isPaused) {
        status = humanReadable.statusText.PAUSED;
      } else if (isDraft) {
        status = humanReadable.statusText.NOT_STARTED;
      }
      return status;
    },
  ];
}

/**
 * Temporary getter to use the experiment status until Oasis layers can be synced up to use the layer status above.
 * @param {Number} experimentId
 * @returns {String}
 */
export function statusTextByExperimentId(experimentId) {
  return [
    LayerExperimentGetters.byId(experimentId),
    experiment => {
      const environments = experiment.get('environments');
      if (environments && environments.size) {
        if (
          environments.find(
            environment =>
              environment.get('status') ===
              LayerExperimentEnums.ActualStatus.RUNNING,
          )
        ) {
          return humanReadable.statusText.RUNNING;
        }

        if (
          environments.find(
            environment =>
              environment.get('status') ===
              LayerExperimentEnums.ActualStatus.PAUSED,
          )
        ) {
          return humanReadable.statusText.PAUSED;
        }
      }

      return humanReadable.statusText[
        experiment.get('actual_status').toUpperCase()
      ];
    },
  ];
}

export function showPlayButton(layerId) {
  return [
    this.isPaused(layerId),
    this.publishStatus(layerId),
    (isPaused, publishStatus) =>
      (isPaused || publishStatus.starting) && !publishStatus.pausing,
  ];
}

/**
 * Returns true if the live layer commit tag associated with
 * this layer does not exist
 */
export function isFirstPublish(layerId) {
  return [
    LiveCommitTagGetters.liveCommitTagByLayerId(layerId),
    liveTag => !liveTag,
  ];
}

/**
 * Returns the text of the action of publishing depending on
 * whether it is the first publish or a subsequent publish
 */
export function publishText(
  layerId,
  campaignType = Layer.enums.campaignTypes.P13N_CAMPAIGN,
) {
  return [
    Layer.getters.byId(layerId),
    this.isFirstPublish(layerId),
    (layer, isFirstPublish) => {
      const text = fns.getText(
        campaignType,
        layer.get('name'),
        isWinnerRolloutFeatureEnabled(),
      );
      return isFirstPublish ? text.firstPublish.title : text.publish.title;
    },
  ];
}

/**
 * Returns the text of the status of publishing
 */
export function publishStatusText(
  layerId,
  campaignType = Layer.enums.campaignTypes.P13N_CAMPAIGN,
) {
  return [
    Layer.getters.byId(layerId),
    this.publishStatus(layerId),
    (layer, publishStatus) => {
      const text = fns.getText(campaignType, layer.get('name'));
      return publishStatus.publishing
        ? text.publish.inProgressMessage
        : text.name;
    },
  ];
}

/**
 * Returns true if the status of the campaign should be green
 */
export function showGreenStatus(layerId) {
  return [
    this.isActive(layerId),
    this.publishStatus(layerId),
    (isActive, publishStatus) =>
      (isActive && !publishStatus.starting && !publishStatus.firstPublish) ||
      publishStatus.pausing,
  ];
}

/**
 * Temporary getter to determine the color of the status for the results page while Oasis experiments are out of
 * sync with the layer.
 * @param {Number} experimentId
 * @returns {Boolean}
 */
export function showGreenStatusByExperimentId(experimentId) {
  return [
    this.statusTextByExperimentId(experimentId),
    status => status === humanReadable.statusText.RUNNING,
  ];
}

export function isUploadingToCDN(projectId, layerId) {
  return [
    VerifierGetters.maxVerifiedRevision(projectId),
    this.liveTag(layerId),
    (maxVerifiedRevision, liveTagObj) => {
      if (maxVerifiedRevision && liveTagObj) {
        const campaignRevision = liveTagObj.get('project_code_revision');
        return (
          campaignRevision > maxVerifiedRevision && maxVerifiedRevision !== 0
        );
      }
      return false;
    },
  ];
}

/**
 * Returns true if the current account and argument layer should have the
 * ability to do layer-only publishing.
 * See: https://optimizely.atlassian.net/browse/WEB-1485
 */
export function shouldPublishLayerOnly(layerId) {
  return [
    [
      AdminAccountGetters.accountPermissions,
      PermissionsModuleFns.shouldPublishLayerOnly,
    ],
    this.isFirstPublish(layerId),
    Layer.getters.byId(layerId),
    ProjectGetters.entityCache,
    (hasPermission, isFirstPublish, layer, projects) => {
      const projectOfLayer = projects.get(layer.get('project_id'));
      const canViewVariationsInP13N =
        !!projectOfLayer &&
        PermissionsModuleFns.canViewVariationsInP13N(projectOfLayer, layer);
      return (
        hasPermission &&
        canViewVariationsInP13N &&
        !isFirstPublish &&
        Layer.fns.isPersonalizationLayer(layer)
      );
    },
  ];
}

export default {
  publishStatus,
  isChangingStatus,
  liveTag,
  isActive,
  isPaused,
  isDraft,
  statusText,
  statusTextByExperimentId,
  showPlayButton,
  isFirstPublish,
  publishText,
  publishStatusText,
  showGreenStatus,
  showGreenStatusByExperimentId,
  isUploadingToCDN,
  shouldPublishLayerOnly,
};

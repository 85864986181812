import _ from 'lodash';

import { showDialog } from 'core/ui/methods/dialogs';
import errorComponent from 'core/ui/components/error';

/**
 * @param {Object} options
 * @param {String?} options.title
 * @param {String?} options.message
 * @param {String?} options.errorId
 * @param {Object?} options.errorDetails
 *
 * @return {jQuery.Deferred} when the dialog is closed
 */
export default function error(options) {
  options = options || {};
  const onClose = $.Deferred();
  showDialog({
    component: errorComponent,
    data: _.extend({}, options, {
      _onClose: onClose,
    }),
  });
  return onClose;
}

/**
 * Human readable translations of enums
 */
export const EVENT_TYPES = {
  pageview: tr('Pageview'),
  click: tr('Click'),
};

export default {
  EVENT_TYPES,
};

/**
 * Change Timing Constants
 */

module.exports = {
  /**
   * Timing options.
   */
  TimingTypes: {
    ASYNCHRONOUS: 'asynchronous',
    SYNCHRONOUS: 'synchronous',
  },
};

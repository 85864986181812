/**
 * Editor Iframe Module helper functions
 */
const _ = require('lodash');

const cloneDeep = require('optly/clone_deep').default;
const urlUtil = require('optly/utils/url');

const ProjectEnums = require('optly/modules/entity/project/enums');
const ProjectFns = require('optly/modules/entity/project/fns');

const BundleSplitHelper = require('optly/utils/bundle_split_helper');

const enums = require('./enums');
const jshintConfig = require('./jshint_config');

const P13N_EDITOR_FLAGS = {
  optimizely_p13n: true, // Flag to indicate were in the v2 editor.
  optimizely_editor: true, // Flag to indicate were in any editor.
  optimizely_include_innie: true, // Flag to tell the proxy to include innie
  optimizely_log: 'debug', // Flag to tell editor-client to enable logging.
};

/**
 * given a url, return an http-protocol version
 * @param  {string} url
 * @return {string}
 */
exports.generateBaseUrl = function(urlString, type) {
  switch (type) {
    case enums.ProtocolTypes.HTTP:
      return urlUtil.generateHttpUrl(urlString);
    case enums.ProtocolTypes.HTTPS:
      return urlUtil.generateHttpsUrl(urlString);
    case enums.ProtocolTypes.PROXY:
      return urlUtil.generateProxyUrl(urlString);
    case enums.PreviewProtocolTypes.PREVIEW_PROXY:
      return urlUtil.generatePreviewProxyUrl(urlString);
    default:
      return urlUtil.generateProxyUrl(urlString);
  }
};

/**
 * Generate the url for loading the iframe
 * @param {string} urlString
 * @param {string} type
 * @param {Object} extraParams - Any additional query params to include in the url for proxy loads.
 * @returns {string}
 */
exports.generateLoaderUrl = (urlString, type, extraParams = {}) => {
  let proxyHash = null;
  let baseUrl = exports.generateBaseUrl(urlString, type);

  if (type === enums.ProtocolTypes.PROXY) {
    // Create a URL object with the requested URL string
    const originalUrlObject = urlUtil.urlParser.parse(urlString, true);

    // Make a copy of the requested URL string's query and hash params
    // The proxy load should include a token and projectId to allow proxy authentication.
    extraParams = _.extend(cloneDeep(originalUrlObject.query), extraParams);
    proxyHash = _.clone(originalUrlObject.hash);

    // Set the proxy's URL object search key to null
    originalUrlObject.search = null;
    originalUrlObject.query = {};
    originalUrlObject.hash = null;

    // Create a proxy base URL without the requested URLs params (we'll attach them later).
    baseUrl = exports.generateBaseUrl(
      urlUtil.urlParser.format(originalUrlObject),
      type,
    );
  }

  const urlObject = urlUtil.urlParser.parse(baseUrl, true);

  urlObject.query = _.extend(extraParams, urlObject.query, P13N_EDITOR_FLAGS);
  urlObject.hash = proxyHash || urlObject.hash;
  urlObject.search = null;
  return urlUtil.urlParser.format(urlObject);
};

/**
 * DEPRECATED!!!!!!
 * The backend now provides a `share_link` for every action in a variation.
 * TODO: We should rip out this frontend logic
 * JIRA: https://optimizely.atlassian.net/browse/WEB-2130
 *
 * Generate the url for loading preview
 * @param {string} urlString
 * @param {string} type
 * @param {string} token
 * @param {object} options
 * @returns {string}
 */
exports.generatePreviewUrl = function(urlString, type, token, options) {
  const urlObject = urlUtil.urlParser.parse(
    exports.generateBaseUrl(urlString, type),
    true,
  );
  const queryParameters = {};
  queryParameters.optimizely_token = token;
  _.each(options, (value, key) => {
    switch (key) {
      case 'audienceIds': // The audienceIds that should be force bucketed by client
        if (_.isArray(value)) {
          value = value.join(',');
        }
        queryParameters.optimizely_x_audiences = encodeURIComponent(value);
        break;
      case 'variationId': // The variationIds that should be force bucketed by client
        if (_.isArray(value)) {
          value = value.join(',');
        }
        queryParameters.optimizely_x = encodeURIComponent(value);
        break;
      case 'previewLayerIds': // The layers for which preview_client should fetch working copy (draft) data
        if (_.isArray(value)) {
          value = value.join(',');
        }
        queryParameters.optimizely_preview_layer_ids = encodeURIComponent(
          value,
        ); // Current
        break;
      case 'previewMode': // The mode and id of preview to be used by preview_ui to initialize the preview module
        const paramKey = `optimizely_preview_mode_${value.type}`;
        queryParameters[paramKey] = value.id;
        break;
      case 'projectId': // The projectId of the static preview snippet that should be fetched from s3
        queryParameters.optimizely_snippet = `s3-${value}`;
        break;
      default:
        throw new Error('invalid option supplied to generate preview url');
    }
  });
  // ensure only query field for urlObject is populated, node url module defaults to using the search field, if present
  // see search and query details in  https://nodejs.org/api/url.html#url_url_format_urlobj
  urlObject.query = _.extend({}, urlObject.query, queryParameters);
  urlObject.search = '';
  return urlUtil.urlParser.format(urlObject);
};

/**
 * Given share link url, which now lives on LayerVariations, append the "optimizely_preview_mode_CAMPAIGN"
 * query parameter to show the preview widget.
 * `optimizely_preview_layer_ids` could in the future be a comma-separated list, so we
 * split on the comma and use the first one.
 * https://github.com/optimizely/optimizely/blob/31eda485c1237974517f1a361cb06d7ce664bf60/src/www/services/layer_experiments.py#L981
 * @param shareLinkUrl
 * @returns {string} URL with preview
 */
exports.generatePreviewUrlBasedOnShareLinkUrl = function(shareLinkUrl) {
  const urlObject = urlUtil.urlParser.parse(shareLinkUrl, true);
  const queryParams = Object.assign({}, urlObject.query);
  queryParams.optimizely_preview_mode_CAMPAIGN = queryParams.optimizely_preview_layer_ids.split(
    ',',
  )[0];
  urlObject.search = '';
  urlObject.query = queryParams;
  return urlUtil.urlParser.format(urlObject);
};

/**
 * Lints code, checking for any errors
 * @param {string} code
 * @returns {object}
 */
exports.lintCode = function(code) {
  const lintOutcome = {
    hasLintErrors: false,
    errors: [],
  };

  // Its possible jshint hasnt finished downloading, so make sure its here first.
  const jshint = window.JSHINT;
  if (jshint) {
    // jshint returns true if there are no lint errors, false if any are found
    lintOutcome.hasLintErrors = !jshint(code, jshintConfig);

    if (lintOutcome.hasLintErrors) {
      // jshint.errors is an array of warnings and errors generated
      // by the most recent invocation of jshint
      lintOutcome.errors = jshint.errors;
    }
  } else {
    // jshint not loaded, so let's request that webpack load the jshint bundle
    BundleSplitHelper.getCodeLintingBundleModules();
  }

  return lintOutcome;
};

/**
 * Returns an array of protocol types, representing which iframes should be
 * created, given a url, EditorIframe component frameType, and the current
 * project
 * @param {String} urlToLoad
 * @param {String} frameType
 * @param {Immutable.Map} currentProject
 * @return {Array}
 */
exports.getAllowedProtocolTypes = (urlToLoad, frameType, currentProject) => {
  // If the url includes a protocol, we don't want to create an iframe
  // element for the protocol not equal to url's protocol. This is to
  // reduce the amount of work the browser has to do. If the url doesn't
  // include a protocol, we still create both http and https iframe
  // elements.
  let allowedProtocolTypes;
  if (!urlToLoad) {
    // Allow everything if there is no url, not sure what it's actually loading though.
    allowedProtocolTypes = [
      enums.ProtocolTypes.PROXY,
      enums.ProtocolTypes.HTTP,
      enums.ProtocolTypes.HTTPS,
    ];
  } else {
    const httpsRequested = urlToLoad.indexOf('https:') === 0;
    const httpRequested = urlToLoad.indexOf('http:') === 0;
    const isIframeFrameType = frameType === enums.FrameTypes.IFRAME;

    let protocolForNonIframeFrameType;
    if (httpsRequested) {
      protocolForNonIframeFrameType = enums.ProtocolTypes.HTTPS;
    } else {
      protocolForNonIframeFrameType = enums.ProtocolTypes.HTTP;
    }

    if (!isIframeFrameType) {
      return [protocolForNonIframeFrameType];
    }

    if (httpsRequested) {
      allowedProtocolTypes = [
        enums.ProtocolTypes.PROXY,
        enums.ProtocolTypes.HTTPS,
      ];
    } else if (httpRequested) {
      allowedProtocolTypes = [
        enums.ProtocolTypes.PROXY,
        enums.ProtocolTypes.HTTP,
      ];
    } else {
      allowedProtocolTypes = [
        enums.ProtocolTypes.PROXY,
        enums.ProtocolTypes.HTTP,
        enums.ProtocolTypes.HTTPS,
      ];
    }
  }

  // If the project has 'Direct Only or 'Proxy Only' for the editor iframe
  // protocol preference setting, we honor that by preventing the appropriate
  // iframe or iframes from being created.
  let protocolPreferenceSetting;
  if (currentProject) {
    protocolPreferenceSetting = ProjectFns.getEditorIframeProtocolPreference(
      currentProject,
    );
  }
  if (
    protocolPreferenceSetting ===
    ProjectEnums.EditorIframeProtocolPreferences.DIRECT_ONLY
  ) {
    allowedProtocolTypes = _.without(
      allowedProtocolTypes,
      enums.ProtocolTypes.PROXY,
    );
  } else if (
    protocolPreferenceSetting ===
    ProjectEnums.EditorIframeProtocolPreferences.PROXY_ONLY
  ) {
    allowedProtocolTypes = [enums.ProtocolTypes.PROXY];
  }

  return allowedProtocolTypes;
};

import PropTypes from 'prop-types';
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import HighchartsMore from 'highcharts-extended';

import ErrorBoundary from 'core/ui/components/error_boundary';

import ChartEmptyState from '../chart_empty_state';

// Add this here to support arearange for highcharts
HighchartsMore(ReactHighcharts.Highcharts);

ReactHighcharts.Highcharts.setOptions({
  lang: {
    numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'],
  },
});

const HighchartsWrapper = React.forwardRef((props, ref) => (
  <ErrorBoundary
    alternateContent={<ChartEmptyState message="Unable to generate chart." />}>
    <ReactHighcharts {...props} ref={ref} />
  </ErrorBoundary>
));

HighchartsWrapper.propTypes = {
  config: PropTypes.object.isRequired,
};

HighchartsWrapper.displayName = 'HighchartsWrapper';

export default HighchartsWrapper;

import React from 'react';
import PropTypes from 'prop-types';

import Immutable from 'optly/immutable';

import getSidebar from 'bundles/p13n/components/campaign_manager/sidebar';

import { connect } from 'core/ui/decorators';

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import {
  enums as LayerEnums,
  fns as LayerFns,
} from 'optly/modules/entity/layer';
import PermissionsGetters from 'optly/modules/permissions/getters';

import { getters as SectionModuleGetters } from '../../section_module';

const MABSidebar = getSidebar(
  'ab-dashboard',
  LayerEnums.campaignTypes.MULTIARMED_BANDIT,
);
const Sidebar = getSidebar(
  'ab-dashboard',
  LayerEnums.campaignTypes.SINGLE_EXPERIMENT,
);

@connect({
  canUseMultiArmedBandits: PermissionsGetters.canUseMultiArmedBandits,
  currentLayer: CurrentLayerGetters.layer,
  sideBarItems: SectionModuleGetters.sideBarItems,
})
class ABSidebar extends React.Component {
  static componentId = 'ab-sidebar';

  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    canUseMultiArmedBandits: PropTypes.bool.isRequired,
    currentLayer: PropTypes.instanceOf(Immutable.Map),
    sideBarItems: PropTypes.object.isRequired,
  };

  render() {
    const {
      activeTab,
      canUseMultiArmedBandits,
      currentLayer,
      sideBarItems,
    } = this.props;

    return (
      <React.Fragment>
        {(!LayerFns.isMultiArmedBandit(currentLayer) ||
          !canUseMultiArmedBandits) && (
          <Sidebar
            activeTab={activeTab}
            items={sideBarItems}
            name="Experiment"
          />
        )}
        {LayerFns.isMultiArmedBandit(currentLayer) &&
          canUseMultiArmedBandits && (
            <MABSidebar
              activeTab={activeTab}
              items={sideBarItems}
              name="Bandit"
            />
          )}
      </React.Fragment>
    );
  }
}

export default ABSidebar;

import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'optimizely-oui';
import Immutable, { toImmutable } from 'optly/immutable';

export default function PreviewerTable(props) {
  const {
    currentItemRecommendations,
    currentlySelectedItemID,
    tableLabel,
    testSection,
  } = props;

  const shouldShowPrice = !!currentItemRecommendations.find(
    recommendation => recommendation.price,
  );
  const shouldShowTitle = !!currentItemRecommendations.find(
    recommendation => recommendation.title,
  );

  return (
    <div className="push-quad--bottom">
      {!!currentlySelectedItemID && (
        <p className="push-double--bottom zeta" data-test-section={testSection}>
          {tableLabel}
        </p>
      )}

      {!!currentlySelectedItemID && currentItemRecommendations.length > 0 && (
        <Table>
          <Table.THead>
            <Table.TR key={currentlySelectedItemID}>
              <Table.TH>ID</Table.TH>
              <Table.TH>Strength</Table.TH>
              {shouldShowTitle && <Table.TH width="40%">Title</Table.TH>}
              {shouldShowPrice && <Table.TH width="12%">Price</Table.TH>}
              <Table.TH>Image</Table.TH>
            </Table.TR>
          </Table.THead>
          <Table.TBody>
            {toImmutable(currentItemRecommendations).map(recommendation => (
              <PreviewerTableRow
                key={recommendation.get('id')}
                recommendation={recommendation}
                shouldShowPrice={shouldShowPrice}
                shouldShowTitle={shouldShowTitle}
              />
            ))}
          </Table.TBody>
        </Table>
      )}
    </div>
  );
}

PreviewerTable.propTypes = {
  currentItemRecommendations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageURL: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      strength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
    }),
  ),
  currentlySelectedItemID: PropTypes.string,
  tableLabel: PropTypes.string,
  testSection: PropTypes.string,
};

function PreviewerTableRow(props) {
  const { recommendation, shouldShowPrice, shouldShowTitle } = props;

  const id = recommendation.get('id');
  const imageURL = recommendation.get('imageURL');
  const price = recommendation.get('price') || 'Unavailable';
  const strength = recommendation.get('strength');
  const title = recommendation.get('title') || 'Unavailable';

  return (
    <Table.TR borderStyle="ends" backgroundColor="faint">
      <Table.TD>
        <span className="word-break--word">{id}</span>
      </Table.TD>
      <Table.TD>{strength}</Table.TD>
      {shouldShowTitle && (
        <Table.TD>
          <span className="word-break--word">{title}</span>
        </Table.TD>
      )}
      {shouldShowPrice && <Table.TD>{price}</Table.TD>}
      <Table.TD>
        {imageURL ? (
          <img
            alt=""
            className="anchor--middle"
            height="150"
            src={imageURL}
            width="150"
          />
        ) : (
          'Unavailable'
        )}
      </Table.TD>
    </Table.TR>
  );
}

PreviewerTableRow.propTypes = {
  recommendation: PropTypes.instanceOf(Immutable.Map),
  shouldShowPrice: PropTypes.bool,
  shouldShowTitle: PropTypes.bool,
};

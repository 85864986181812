import React from 'react';
import PropTypes from 'prop-types';

import { Poptip, Icon } from 'optimizely-oui';

const ConditionCardHeader = props => {
  const { showMultipleURLsMessage, title, onClose } = props;

  return (
    <div className="background--faint border--bottom flex flex--row  flex-justified--between soft-half--ends soft-double--right">
      <div className="push--left flex flex--row flex--1 flex-align--center">
        <div
          className="weight--bold"
          data-test-section="condition-card-header-title">
          {title}
        </div>
        {showMultipleURLsMessage && (
          <div
            data-test-section="condition-card-header-multiple-urls"
            className="muted micro push-half--left flex flex--row flex-align--center">
            Add multiple URLs separated by line breaks
            <Poptip
              position="right"
              content="A line break can also be expressed as \n \r \t or <br>">
              <div className="push-half--left push-half--top">
                <Icon name="circle-question" size="small" />
              </div>
            </Poptip>
          </div>
        )}
      </div>
      <div
        className="flex soft--left"
        data-test-section="condition-card-header-close"
        onClick={onClose}
        onKeyDown={onClose}
        role="button"
        tabIndex={0}>
        <Icon className="oui-icon icon-target" name="xmark" size="small" />
      </div>
    </div>
  );
};

ConditionCardHeader.propTypes = {
  showMultipleURLsMessage: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ConditionCardHeader;

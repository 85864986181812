import { searchEntityTypes } from 'bundles/p13n/components/entity_search/component_module/constants';

import LayerEnums from 'optly/modules/entity/layer/enums';

import {
  DEFAULT_COLUMNS_VISIBILITY,
  DEFAULT_EXPERIMENTS_COLUMNS_VISIBILITY,
  DEFAULT_PERSONALIZATION_COLUMNS_VISIBILITY,
} from 'optly/modules/dashboard/constants';

import NavConstants from 'optly/services/navigation';

import { dashboardTabs } from './page_module/constants';

export function generateTabProps(tab) {
  switch (tab) {
    case dashboardTabs.EXPERIMENTS:
      return {
        activeTab: dashboardTabs.EXPERIMENTS,
        dataTestSection: 'experiments-table-container',
        defaultColumnsVisibility: DEFAULT_PERSONALIZATION_COLUMNS_VISIBILITY,
        entityTypes: [searchEntityTypes.EXPERIMENT],
        layerTab: NavConstants.LayersTabs.EXPERIMENTS,
        typeFilterOptions: [
          LayerEnums.type.AB,
          LayerEnums.type.MULTIVARIATE,
          LayerEnums.type.MULTIARMED_BANDIT,
        ],
      };
    case dashboardTabs.PERSONALIZATIONS:
      return {
        activeTab: dashboardTabs.PERSONALIZATIONS,
        dataTestSection: 'personalizations-table-container',
        defaultColumnsVisibility: DEFAULT_EXPERIMENTS_COLUMNS_VISIBILITY,
        entityTypes: [searchEntityTypes.CAMPAIGN],
        layerTab: NavConstants.LayersTabs.PERSONALIZATIONS,
        typeFilterOptions: [LayerEnums.type.PERSONALIZATION],
      };
    case dashboardTabs.OVERVIEW:
    default:
      return {
        activeTab: dashboardTabs.OVERVIEW,
        dataTestSection: 'layers-table-container',
        defaultColumnsVisibility: DEFAULT_COLUMNS_VISIBILITY,
        entityTypes: [searchEntityTypes.EXPERIMENT, searchEntityTypes.CAMPAIGN],
        layerTab: NavConstants.LayersTabs.LAYERS,
        typeFilterOptions: [
          LayerEnums.type.AB,
          LayerEnums.type.MULTIVARIATE,
          LayerEnums.type.MULTIARMED_BANDIT,
          LayerEnums.type.PERSONALIZATION,
        ],
      };
  }
}

export default {
  generateTabProps,
};

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import EventEnums from 'optly/modules/entity/event/enums';
import * as LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';

const getters = {};

getters.trackClicksChange = ['track_clicks_change'];

getters.trackClicksChangeName = ['track_clicks_change', 'name'];

getters.trackClicksChangeIsEnabled = ['track_clicks_change', 'enabled'];

getters.trackClicksChangeSelector = ['track_clicks_change', 'selector'];

getters.trackClicksChangeViewId = ['track_clicks_change', 'viewId'];

getters.trackClicksChangeEventId = ['track_clicks_change', 'eventId'];

getters.selectedViewId = ['p13n/editor', 'selectedViewId'];

/**
 * Event registered on campaign that matches the current track clicks
 * change selector and view id
 * @return {Immutable.Map} event Object
 */
getters.campaignEventWithMatchingId = [
  CurrentLayerGetters.layerEvents,
  getters.trackClicksChangeEventId,
  function(layerEvents, trackClicksChangeEventId) {
    if (!trackClicksChangeEventId) {
      return;
    }
    return layerEvents.find(
      event => event.get('id') === trackClicksChangeEventId,
    );
  },
];

/**
 * Event registered on campaign that matches the current track clicks view id
 * @return {Immutable.Map} event Object
 */
getters.campaignEventsForView = [
  CurrentLayerGetters.layerEvents,
  getters.selectedViewId,
  function(events, viewId) {
    return events.filter(event => {
      const filterType = event.getIn(['event_filter', 'filter_type']);
      if (filterType !== EventEnums.filterTypes.TARGET_SELECTOR) {
        return false;
      }
      return (
        event.get('view_id') === viewId &&
        event.get('variation_specific') &&
        !event.get('archived')
      );
    });
  },
];

/**
 * Informs whether current view has any click events to track
 * @return boolean
 */
getters.viewHasClickEvents = [
  getters.campaignEventsForView,
  function(events) {
    return events.size > 0;
  },
];

/**
 * Event registered on project that matches the current track clicks
 * change selector and view id
 * @return {Immutable.Map} event Object
 */
getters.projectEventWithMatchingId = [
  CurrentProjectGetters.events,
  getters.trackClicksChangeEventId,
  function(activeEvents, trackClicksChangeEventId) {
    if (!trackClicksChangeEventId) {
      return;
    }
    return activeEvents.find(
      event => event.get('id') === trackClicksChangeEventId,
    );
  },
];

/**
 * Whether to show warning text that event is only campaign specific
 * Happens if event is created from the editor
 * @return {Boolean}
 */
getters.showCampaignSpecificEventText = [
  getters.campaignEventWithMatchingId,
  getters.projectEventWithMatchingId,
  function(campaignEventWithMatchingId, projectEventWithMatchingId) {
    // If the event exists globally already
    if (!campaignEventWithMatchingId && projectEventWithMatchingId) {
      if (projectEventWithMatchingId.get('variation_specific')) {
        return true;
      }
      return false;
    }

    // If trackClicksChangeSelector and trackClicksChangeViewId
    // do not match a campaign or global event
    // it will still be campaign specific because
    // it will be created from the editor if it is saved
    if (!campaignEventWithMatchingId && !projectEventWithMatchingId) {
      return true;
    }

    if (campaignEventWithMatchingId.get('variation_specific')) {
      return true;
    }

    return false;
  },
];

/**
 * Whether event is only shown for this campaign
 * Happens if event is created from the editor
 * @return {Boolean}
 */
getters.isCampaignSpecificEvent = [
  getters.campaignEventWithMatchingId,
  function(campaignEventWithMatchingId) {
    if (
      campaignEventWithMatchingId &&
      campaignEventWithMatchingId.get('variation_specific')
    ) {
      return true;
    }

    return false;
  },
];

/**
 * Track Clicks change dirty state
 * @return {Boolean}
 */
getters.trackClicksChangeIsDirty = [
  getters.trackClicksChange,
  getters.campaignEventWithMatchingId,
  function(trackClicksChange, campaignEventWithMatchingId) {
    const trackClicksChangeIsEnabled = trackClicksChange.get('enabled');
    const isTrackClickEventInCampaign = !!campaignEventWithMatchingId;
    // if event is in the campaign, and its not enabled
    if (isTrackClickEventInCampaign && !trackClicksChangeIsEnabled) {
      return true;
    }
    // if event is not in the campaign and its enabled
    if (!isTrackClickEventInCampaign && trackClicksChangeIsEnabled) {
      return true;
    }

    // if event is in the campaign and enabled, but its name or selector are different
    if (isTrackClickEventInCampaign && trackClicksChangeIsEnabled) {
      const nameIsDirty =
        trackClicksChange.get('name') !==
        campaignEventWithMatchingId.get('name');
      const selectorIsDirty =
        trackClicksChange.get('selector') !==
        campaignEventWithMatchingId.getIn(['event_filter', 'selector']);
      return nameIsDirty || selectorIsDirty;
    }

    // event is not in campaign and not enabled
    return false;
  },
];

/**
 * Track Clicks change state
 * @return {String}
 */
getters.getTrackClicksChangeStatus = [
  getters.trackClicksChangeIsDirty,
  getters.trackClicksChangeIsEnabled,
  (trackClicksChangeIsDirty, trackClicksChangeIsEnabled) => {
    if (trackClicksChangeIsDirty) {
      return LayerExperimentEnums.ChangeStatuses.DIRTY;
    }
    if (trackClicksChangeIsEnabled) {
      return LayerExperimentEnums.ChangeStatuses.LIVE;
    }
    return null;
  },
];

export default getters;

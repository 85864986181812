import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

export default {
  // export getters here
  ...baseEntityGetters,
};

import _ from 'lodash';

import enums from './enums';
/**
 * Services layer pure functions for the integrations
 *
 * All of these functions are assuming the Integration data structure returned from CurrentProject.getters.integrations
 */

/**
 * @param {Object} integration
 * @return {Boolean}
 */
export function isEnabled(integration) {
  try {
    return !!integration.projectLevelData.enabled;
  } catch (e) {
    console.warn('Could not read `integration.projectLevelData.enabled`'); // eslint-disable-line
    return false;
  }
}

/**
 * @param {Object} integration
 * @return {Boolean}
 */
export function isAudienceIntegration(integration) {
  try {
    return _.includes(integration.categories, enums.categories.AUDIENCES);
  } catch (e) {
    console.warn('Could not read `integration.categories` as an array'); // eslint-disable-line
    return false;
  }
}

export default {
  isAudienceIntegration,
  isEnabled,
};

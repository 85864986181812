import { actions as LayerActions } from 'optly/modules/entity/layer';

import { actions as PublishStatusActions } from 'bundles/p13n/modules/publish_status';
import LiveCommitTagActions from 'optly/modules/entity/live_commit_tag/actions';
import VerifierActions from 'optly/modules/verifier/actions';

const waitForReindex = () => {
  return new Promise(resolve => setTimeout(resolve, 1000));
};

/**
 * Conclude and Pause the layer
 * @param {Layer} layer
 * @param {string} result
 * @param {string} conclusions
 * @param {number} currentProjectId
 * @param {LiveCommit} liveCommit
 * @param {Function} onSuccessAction - callbackFn
 */
export function concludeAndPauseLayer(
  plainLayer,
  result,
  conclusions,
  currentProjectId,
  liveCommit,
  onSuccessAction,
) {
  PublishStatusActions.startPausing(plainLayer.id);

  LayerActions.conclude({
    ...plainLayer,
    concluded_results_outcome: result,
    concluded_conclusions: conclusions,
  })
    .then(() => {
      LiveCommitTagActions.deactivateTag({
        layer_id: liveCommit.get('layer_id'),
        // Purposefully don't pipe the result of the verifier as it takes
        // too long for the snippet to update
      })
        .then(updatedLiveCommit => {
          VerifierActions.verify({
            projectId: currentProjectId,
            revisionToVerify: updatedLiveCommit.project_code_revision,
          }); // don't error on rejection
        })
        .then(() => waitForReindex())
        .always(() => {
          PublishStatusActions.finishPausing(plainLayer.id);
        });
    })
    .then(() => waitForReindex())
    .then(onSuccessAction);
}

/**
 * Conclude the layer
 * @param {Layer} layer
 * @param {string} result
 * @param {string} conclusions
 * @param {Function} onSuccessAction - callbackFn
 */
export function concludeLayer(
  plainLayer,
  result,
  conclusions,
  onSuccessAction,
) {
  LayerActions.conclude({
    ...plainLayer,
    concluded_results_outcome: result,
    concluded_conclusions: conclusions,
  })
    .then(() => waitForReindex())
    .then(onSuccessAction);
}

export default {
  concludeLayer,
  concludeAndPauseLayer,
};

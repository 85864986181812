/**
 * Module specific pure functions
 */
/**
 * @param {Immutable.Map} accountInfo
 *  @param activeProjectsList
 */

const isUnderProjectLimit = (accountInfo, activeProjectsList) =>
  activeProjectsList.size < accountInfo.get('maxProjects');

export { isUnderProjectLimit };

export default {
  isUnderProjectLimit,
};

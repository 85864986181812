import $ from 'jquery';
import _ from 'lodash';

import url from 'url';

import ajaxUtil from 'optly/utils/ajax';
import AuthGetters from 'optly/modules/auth/getters';
import deparam from 'optly/utils/deparam';
import events from 'optly/services/events';
import flux from 'core/flux';

import RestApi from 'optly/modules/rest_api';
import regex from 'optly/utils/regex';

import fns from './fns';
import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

export default _.extend({}, baseEntityActions, {
  /**
   * Make an AJAX request to create a new account.
   *
   * @param {Object} data
   * @param {Array} validateOnly Specify the data parameters to validate
   * @return {Deferred}
   */
  createAccount(data, validateOnly) {
    /* eslint-disable fetch/no-jquery */
    // Validate this data before we send it off
    const validation = fns.validateAccountInfo(data, validateOnly);
    const queryParameters = $.deparam
      ? $.deparam.querystring()
      : (window && window.deparam && window.deparam(window.location.search)) ||
        {};
    const continueTo =
      'continue_to' in queryParameters
        ? queryParameters.continue_to.toLowerCase()
        : undefined;
    const createAccountUrl = '/account/create_free_trial_account';

    if (validation.valid) {
      return $.ajax({
        type: 'POST',
        url: createAccountUrl,
        // This is an older endpoint and needs a url encoded query string, strip the leading ?
        data: url.format({ query: data }).substring(1, this.length),
        error: null,
      });
    }
    return $.Deferred().reject(validation);
  },

  /**
   * Make an AJAX request to check if create password token is valid
   *
   * @param {Object} data Contains email, create password token
   * @return {Deferred}
   */
  checkCreatePasswordToken(data) {
    /* eslint-disable fetch/no-jquery */
    return $.ajax({
      type: 'POST',
      url: '/account/create_password_token_check',
      data,
    });
  },

  /**
   * Make an AJAX request to create a first password
   *
   * @param {Object} data Contains email, password, confirm password, create password token
   * @return {Deferred}
   */
  createPassword(data) {
    // Validate data before making a post request
    const validation = fns.validateCreatePasswordInfo(data);
    if (validation.valid) {
      return $.ajax({
        // eslint-disable-line fetch/no-jquery
        type: 'POST',
        url: '/account/create_password_complete',
        data,
      });
    }
    return $.Deferred().reject(validation);
  },

  /**
   * Make an AJAX request to create a new free trial account.
   * @param {Object} data
   * @param {Array} validateOnly Specify the data parameters to validate
   * @return {Deferred}
   */
  createTrialAccount(data, validateOnly) {
    // Validate this data before we send it off
    const validation = fns.validateAccountInfo(data, validateOnly);
    if (validation.valid) {
      return $.ajax({
        // eslint-disable-line fetch/no-jquery
        type: 'POST',
        url: '/account/free_trial_create',
        data,
        error: null,
      });
    }
    return $.Deferred().reject(validation);
  },

  /**
   * Fetches an account via the legacy API.
   * @param {String} email
   */
  fetchAccount(email) {
    return $.ajax({
      // eslint-disable-line fetch/no-jquery
      url: `/api/account/${email}.json`,
      type: 'GET',
    }).then(response => response);
  },

  /**
   * Make an AJAX call to send off the 'forgot password' email
   *
   * @param {String} emailAddress Email Address
   * @return {Deferred}
   */
  resetPassword(emailAddress) {
    let ret = false;
    if (regex.email.test(emailAddress)) {
      ret = $.ajax({
        // eslint-disable-line fetch/no-jquery
        type: 'POST',
        url: '/recover/request',
        data: {
          email: emailAddress,
        },
        error: null,
      });
    }
    return ret;
  },

  /**
   * Make an AJAX call to complete either of the following processes:
   *  - Account creation via a claim token
   *  - Account recovery
   *
   * These two hit the same backend handler, used for account recovery
   * (requires token, email, and new password).
   *
   * @param {Object} data Contains { email, token, new_password1, new_password2, new_account }
   * @return {Deferred}
   */
  setPassword(data) {
    const validation = fns.validateSetPasswordInfo(data);
    const postUrl = `${data.new_account ? '/claim' : '/recover'}/complete`;
    let retVal;

    if (validation.valid) {
      retVal = $.ajax({
        // eslint-disable-line fetch/no-jquery
        type: 'POST',
        url: postUrl,
        // This is an older endpoint and needs a url encoded query string, strip the leading ?
        data: url.format({ query: data }).substring(1, this.length),
        error: null,
      });
    } else {
      // The reject object will be a jqXHR object so that, should the validation fail, our
      // handleAjaxError utility method can parse it properly.
      retVal = $.Deferred().reject({
        responseText: JSON.stringify(validation),
      });
    }
    return retVal;
  },

  /**
   * Make an AJAX call to complete either of the following processes:
   *  - In-app password change
   *  - Pre-app (but post-auth) admin-enforced password expiration
   *
   * Endpoint requires session cookie and old and new passwords.
   * @param {Object} data { old_password, new_password1, new_password2 }
   */
  submitAuthenticatedPasswordChange(data) {
    const csrf = flux.evaluate(AuthGetters.csrfToken);

    return $.ajax({
      // eslint-disable-line fetch/no-jquery
      type: 'POST',
      url: '/account/change_password',
      // The manual csrf handling is due to using the csrf token that
      // the email/password verification returned, which was saved in flux.
      beforeSend: xhr => xhr.setRequestHeader('X-csrf-token', csrf),
      data: {
        old_password: data.old_password,
        new_password1: data.new_password1,
        new_password2: data.new_password2,
      },
    });
  },

  /**
   * Called on successful signup
   */
  trackSignup(accountInfo, leadInfo) {
    const queryParameters = deparam.querystring();
    const continueTo =
      'continue_to' in queryParameters ? queryParameters.continue_to : '';

    if (accountInfo && accountInfo.munchkin_token) {
      if (!leadInfo) {
        // Try to detect lead info based on referred/parameters
        leadInfo = {};
        leadInfo.Web_Conversion_URL__c = window.location.href;

        if (
          document.referrer &&
          document.referrer.match(/(academy|community|help)\.optimizely/)
        ) {
          // Optiverse/Edu flow
          leadInfo.LeadSource = 'Website - Direct';
          leadInfo.Lead_Source_Category__c = 'Optiverse';
          leadInfo.Initial_Form_Source__c = 'Create Account - Optiverse';
          leadInfo.Inbound_Lead_Form_Type__c = 'Create Account - Optiverse';
          leadInfo.Conversion_Type__c = 'Create Account';

          // If referrer points to one of educational sites - associate this lead in Marketo to optiverse
          if (document.referrer.indexOf('community.optimizely') >= 0) {
            leadInfo.Lead_Source_Subcategory__c = 'Community';
          } else if (document.referrer.indexOf('help.optimizely') >= 0) {
            leadInfo.Lead_Source_Subcategory__c = 'KB';
          } else if (document.referrer.indexOf('learn.optimizely') >= 0) {
            leadInfo.Lead_Source_Subcategory__c = 'Academy';
          }
        } else {
          // default case
          leadInfo.LeadSource = 'Website - Direct';
          leadInfo.Lead_Source_Category__c = 'Website';
          leadInfo.Lead_Source_Subcategory__c = 'Optimizely';
          // if free trial
          if (accountInfo.subscription && accountInfo.subscription.in_trial) {
            leadInfo.Initial_Form_Source__c =
              'Create Account - Enterprise Free Trial';
            leadInfo.Inbound_Lead_Form_Type__c =
              'Create Account - Enterprise Free Trial';
          } else {
            leadInfo.Initial_Form_Source__c = 'Create Account';
            leadInfo.Inbound_Lead_Form_Type__c = 'Sign Up';
          }
          leadInfo.Conversion_Type__c = 'Create Account';
        }
      }

      const data = {
        FirstName: accountInfo.first_name,
        LastName: accountInfo.last_name,
        PhoneNumber: accountInfo.phone_number,
        Email: accountInfo.email,
        Company: accountInfo.company_name,
        Demandbase_ID__c: accountInfo.db_demandbase_sid,
        Ultimate_Parent_Name__c: accountInfo.db_worldhq_company_name,
        Company_db__c: accountInfo.db_company_name,
        Industry: accountInfo.db_industry,
        Subindustry__c: accountInfo.db_sub_industry,
        revenue_range: accountInfo.db_revenue_range,
        employee_range: accountInfo.db_employee_range,
        Street_db__c: accountInfo.db_street_address,
        City_db__c: accountInfo.db_city,
        State_db__c: accountInfo.db_state,
        Postal_Code_db__c: accountInfo.db_zip,
        Country_Code__c: accountInfo.db_country,
        Company_Phone_db__c: accountInfo.db_phone,
        Country_db__c: accountInfo.db_country_name,
        SIC_Code__c: accountInfo.db_primary_sic,
        Primary_NAICS_Code__c: accountInfo.db_primary_naics,
        AnnualRevenue: accountInfo.db_annual_sales,
        NumberOfEmployees: accountInfo.db_employee_count,
        Fortune_1000__c: accountInfo.db_fortune_1000,
        Forbes_2000__c: accountInfo.db_forbes_2000,
        B2B__c: accountInfo.db_b2b,
        B2C__c: accountInfo.db_b2c,
        Marketing_Alias__c: accountInfo.db_marketing_alias,
        Website_db__c: accountInfo.db_web_site,
        Latitude__c: accountInfo.db_latitude,
        Longitude__c: accountInfo.db_longitude,
        Audience_db__c: accountInfo.db_audience,
        Audience_Segment_db__c: accountInfo.db_audience_segment,
      };

      events.trackMarketoLead(
        'associateLead',
        _.extend(data, leadInfo),
        accountInfo.munchkin_token,
      );
    }

    // Track a successful account creation with various analytics tools (including Optimizely itself).
    events.track('/account/create/success');

    // Track signups coming through the mobile landing page.
    if (continueTo.indexOf('/mobile/first-project') > -1) {
      events.track('/mobile/signup/success');
    }
  },

  /**
   * Triggers a handler to verify changed email for user and send email verification link to new email
   * @param {String} newEmail
   * @param {String} password
   * @param {String} verificationToken
   */
  verifyChangedEmail(newEmail, password, verificationToken) {
    return ajaxUtil.makeV1AjaxRequest({
      url: '/account/verify_change_email',
      type: 'POST',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: {
        new_email: newEmail,
        password,
        verification_token: verificationToken,
      },
    });
  },
});

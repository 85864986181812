import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const NUM_COLUMNS = 4;

export default class ButtonGrid extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        description: PropTypes.string,
        onClick: PropTypes.func,
        href: PropTypes.string,
        isVisible: PropTypes.bool,
        testSection: PropTypes.string,
        addExtraLineBreak: PropTypes.bool,
      }),
    ),
  };

  handleButtonClick = (e, action, href) => {
    if (action) {
      action();
      e.stopPropagation();
    } else {
      e.preventDefault();
      window.open(href, '_blank');
    }
  };

  render() {
    const { title, buttons } = this.props;
    const visibleButtons = buttons.filter(
      button =>
        !Object.prototype.hasOwnProperty.call(button, 'isVisible') ||
        button.isVisible,
    );
    const numRows = Math.ceil(visibleButtons.length / NUM_COLUMNS);
    return (
      <div>
        {title && (
          <p
            data-test-section="button-grid-title"
            className="weight--bold muted flush">
            {title}
          </p>
        )}
        {[...Array(numRows)].map((row, rowIndex) => (
          <div
            key={rowIndex}
            className="lego-grid flush--sides flush--top push--bottom">
            {[...Array(NUM_COLUMNS)].map((item, colIndex) => {
              const buttonIndex = rowIndex * NUM_COLUMNS + colIndex;
              const button = visibleButtons[buttonIndex];
              if (buttonIndex < visibleButtons.length) {
                return (
                  <div
                    key={colIndex}
                    data-test-section={button.testSection}
                    onClick={e =>
                      this.handleButtonClick(e, button.onClick, button.href)
                    }
                    className={classNames({
                      flex: true,
                      'flex-justified--center': true,
                      'flex-align--center': true,
                      soft: true,
                      'lego-selector__item': true,
                      'push--ends': true,
                      'push--right': true,
                      'soft-double--ends': button.addExtraLineBreak,
                    })}>
                    <a
                      target="_blank"
                      data-test-section={`${button.testSection}-link`}
                      className="color--base"
                      href={button.href}>
                      {button.label}
                      {button.description && (
                        <span className="weight--light oui-label flush micro">
                          ({button.description})
                        </span>
                      )}
                    </a>
                  </div>
                );
              }
              return (
                <div
                  key={colIndex}
                  className="soft lego-selector__item visibility--hidden">
                  <br />
                  <br />
                  <br />
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }
}

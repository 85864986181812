import React from 'react';
import PropTypes from 'prop-types';

import { ButtonRow, Button } from 'optimizely-oui';

import { connect } from 'core/ui/decorators';
import { Footer, Fieldset } from 'react_components/dialog';
import SupportEnums from 'optly/modules/support/enums';
import PermissionsFns from 'optly/modules/permissions/fns';
import PermissionsGetters from 'optly/modules/permissions/getters';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';

import ButtonGrid from '../button_grid';

@connect({
  canAccountUseEdgeProjects: PermissionsGetters.canAccountUseEdgeProjects,
  canUseFullStackSDKs: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseFullStackSDKs,
  ],
  canUseFullStackExperiments: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseFullStackExperiments,
  ],
  canUseMobileSDKs: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseMobileSDKs,
  ],
  canUseOverTheTopSDKs: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseOverTheTopSDKs,
  ],
  canUseXWeb: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseXWeb,
  ],
  canUsePersonalization: PermissionsGetters.canUsePersonalization,
  canUseTeamsWorkflow: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.canUseTeamsWorkflow,
  ],
})
class ChooseCategory extends React.Component {
  static propTypes = {
    canAccountUseEdgeProjects: PropTypes.bool.isRequired,
    canUseFullStackExperiments: PropTypes.bool.isRequired,
    canUseFullStackSDKs: PropTypes.bool.isRequired,
    canUseMobileSDKs: PropTypes.bool.isRequired,
    canUseOverTheTopSDKs: PropTypes.bool.isRequired,
    canUsePersonalization: PropTypes.bool.isRequired,
    canUseTeamsWorkflow: PropTypes.bool.isRequired,
    canUseXWeb: PropTypes.bool.isRequired,
    setCaseType: PropTypes.func.isRequired,
    setCategoryId: PropTypes.func.isRequired,
    switchStepHandler: PropTypes.func.isRequired,
  };

  goToSupportHome = () => {
    const { switchStepHandler } = this.props;
    switchStepHandler(SupportEnums.steps.HELP_CENTER_HOME);
  };

  getNextStepHandler = category => () => {
    const { setCategoryId, setCaseType, switchStepHandler } = this.props;
    const { BILLING, ACCOUNT, PROGRAM_MANAGEMENT } = SupportEnums.categories;
    const { SALES_BILLING, ACCOUNT_SETTINGS } = SupportEnums.caseType;
    const { REQUEST_FORM, CHOOSE_CASE_TYPE } = SupportEnums.steps;
    setCategoryId(category.value);
    switch (category) {
      case BILLING:
        setCaseType(SALES_BILLING);
        switchStepHandler(REQUEST_FORM);
        break;
      case ACCOUNT:
        setCaseType(ACCOUNT_SETTINGS);
        switchStepHandler(REQUEST_FORM);
        break;
      case PROGRAM_MANAGEMENT:
        switchStepHandler(REQUEST_FORM);
        break;
      default:
        switchStepHandler(CHOOSE_CASE_TYPE);
    }
  };

  render() {
    const {
      canAccountUseEdgeProjects,
      canUseFullStackExperiments,
      canUseMobileSDKs,
      canUseOverTheTopSDKs,
      canUseFullStackSDKs,
      canUseXWeb,
      canUsePersonalization,
      canUseTeamsWorkflow,
    } = this.props;
    const {
      X_WEB_EDGE,
      X_WEB_EXPERIMENTATION,
      X_WEB_PERSONALIZATION,
      PROGRAM_MANAGEMENT,
      X_FULL_STACK,
      X_MOBILE,
      X_OTT,
      FREE_ROLLOUTS,
      BILLING,
      ACCOUNT,
      DEVELOPER,
    } = SupportEnums.categories;
    return (
      <React.Fragment>
        <Fieldset testSection="choose-category">
          <p className="weight--bold">Which topic can we help you with?</p>
          {canUseXWeb && (
            <ButtonGrid
              title="Optimizely Web"
              buttons={[
                {
                  label: 'Web Experimentation',
                  onClick: this.getNextStepHandler(X_WEB_EXPERIMENTATION),
                  testSection: 'web-experimentation-case-category',
                },
                {
                  label: 'Web Edge',
                  isVisible: canAccountUseEdgeProjects,
                  onClick: this.getNextStepHandler(X_WEB_EDGE),
                  testSection: 'web-edge-case-category',
                },
                {
                  label: 'Web Personalization',
                  isVisible: canUsePersonalization,
                  onClick: this.getNextStepHandler(X_WEB_PERSONALIZATION),
                  testSection: 'web-personalization-case-category',
                },
              ]}
            />
          )}
          {canUseFullStackSDKs && canUseFullStackExperiments && (
            <ButtonGrid
              title="Optimizely SDK's"
              buttons={[
                {
                  label: 'Full Stack',
                  onClick: this.getNextStepHandler(X_FULL_STACK),
                  testSection: 'full-stack-case-category',
                },
                {
                  label: 'X Mobile',
                  description: 'iOS/Android',
                  onClick: this.getNextStepHandler(X_MOBILE),
                  isVisible: canUseMobileSDKs,
                  testSection: 'x-mobile-case-category',
                },
                {
                  label: 'OTT',
                  onClick: this.getNextStepHandler(X_OTT),
                  testSection: 'ott-case-category',
                  isVisible: canUseOverTheTopSDKs,
                },
              ]}
            />
          )}
          {canUseFullStackSDKs && !canUseFullStackExperiments && (
            <ButtonGrid
              title="Optimizely Rollouts"
              buttons={[
                {
                  label: 'Optimizely Rollouts',
                  onClick: this.getNextStepHandler(FREE_ROLLOUTS),
                  testSection: 'free-rollouts-case-category',
                },
              ]}
            />
          )}
          {canUseTeamsWorkflow && (
            <ButtonGrid
              title="Program Management"
              buttons={[
                {
                  label: 'Program Management',
                  onClick: this.getNextStepHandler(PROGRAM_MANAGEMENT),
                  testSection: 'program-management-case-category',
                },
              ]}
            />
          )}
          <ButtonGrid
            title="Account Issues"
            buttons={[
              {
                label: 'Billing',
                onClick: this.getNextStepHandler(BILLING),
                testSection: 'billing-case-category',
              },
              {
                label: 'Account & Privacy',
                onClick: this.getNextStepHandler(ACCOUNT),
                testSection: 'account-and-privacy-case-category',
              },
            ]}
          />
          <ButtonGrid
            title="Developer Platform"
            buttons={[
              {
                label: 'Developer Support',
                description: 'REST API, etc.',
                onClick: this.getNextStepHandler(DEVELOPER),
                testSection: 'developer-case-category',
              },
            ]}
          />
        </Fieldset>
        <Footer>
          <ButtonRow
            rightGroup={[
              <Button
                key="back"
                style="outline-reverse"
                testSection="choose-category-back-button"
                onClick={this.goToSupportHome}>
                Back
              </Button>,
            ]}
          />
        </Footer>
      </React.Fragment>
    );
  }
}

export default ChooseCategory;

import keyMirror from 'optly/utils/key_mirror';

export const actionType = keyMirror({
  VERIFIER_ADD_PROJECT_RECORD: null,
  VERIFIER_FETCH_START: null,
  VERIFIER_FETCH_ERROR: null,
  VERIFIER_FETCH_SUCCESS: null,
  VERIFIER_ADD_VERSION_REQUEST: null,
  VERIFIER_REMOVE_VERSION_REQUEST: null,
});

export default {
  ...actionType,
};

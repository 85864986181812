const keyMirror = require('optly/utils/key_mirror');

module.exports = keyMirror({
  P13N_CAMPAIGN_SELECT_TAB: null,
  P13N_CAMPAIGN_SET_SETTINGS_TAB: null,
  P13N_CAMPAIGN_SET_SETTINGS_DIRTY: null,
  P13N_CAMPAIGN_RESET_SETTINGS_DIRTY: null,
  P13N_SET_CREATING_VARIATION_STATUS: null,
  P13N_START_CREATING_VARIATION: null,
  P13N_STOP_CREATING_VARIATION: null,
  P13N_START_RENAMING_VARIATION: null,
  P13N_STOP_RENAMING_VARIATION: null,
});

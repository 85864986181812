const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const actionTypes = require('../action_types');

module.exports = Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      id: null,
    });
  },

  initialize() {
    this.on(actionTypes.P13N_SET_CURRENT_EVENT_ID, setCurrentEventId);
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 */
function setCurrentEventId(state, payload) {
  return state.set('id', payload.id);
}

import { toImmutable } from 'optly/immutable';
import flux from 'core/flux';
import ui from 'core/ui';
import vueHelpers from 'optly/utils/vue_helpers';

import FeaturesDialogComponent from 'bundles/p13n/sections/features_old/components/feature_dialog';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import FeatureFlagActions from 'optly/modules/entity/feature_flag/actions';
import FeatureFlagFns from 'optly/modules/entity/feature_flag/fns';

import getters from './getters';

export function archiveFeatureFlag(featureFlag) {
  return FeatureFlagActions.save({
    id: featureFlag.get('id'),
    archived: true,
  });
}

/**
 * Saves a feature flag, then invokes a callback function.
 * @param {object} featureToSave
 * @param {function} onSave
 * @return {Deferred}
 */
export function saveFeatureFlag(featureToSave, onSave) {
  return FeatureFlagActions.save(featureToSave.toJS()).then(savedFeature => {
    const invokeOnSaveCallback = feature => {
      if (onSave && typeof onSave === 'function') {
        onSave(toImmutable(feature));
      }
    };
    // detect and handle eventual consistency issue with feature flag saving (APP-1596)
    if (
      featureToSave.get('variables') &&
      featureToSave.get('variables').size !== savedFeature.variables.length
    ) {
      // force fetch the feature flag to push out the bad data from the entity cache
      FeatureFlagActions.fetch(savedFeature.id, true).then(fetchedFeature => {
        invokeOnSaveCallback(fetchedFeature);
      });
    } else {
      invokeOnSaveCallback(savedFeature);
    }
  });
}

export function showFeatureDialog(feature, onSave) {
  if (!feature) {
    const projectId = flux.evaluate(CurrentProjectGetters.id);
    // create empty new feature with a variable
    feature = toImmutable(
      FeatureFlagFns.createFeatureEntity({
        api_name: '',
        project_id: projectId,
      }),
    );
  }

  // used to check for feature key uniqueness within the project
  const currentProjectFeatures = flux.evaluate(getters.currentProjectFeatures);
  ui.showDialog({
    component: vueHelpers.extendComponent(FeaturesDialogComponent, {
      currentProjectFeatures,
      feature,
      save: featureToSave => saveFeatureFlag(featureToSave, onSave),
    }),
    noPadding: true,
    fullScreen: true,
    dismissOnBack: true,
  });
}

export function unarchiveFeatureFlag(featureFlag) {
  return FeatureFlagActions.unarchive(featureFlag.get('id'));
}

export default {
  archiveFeatureFlag,
  saveFeatureFlag,
  showFeatureDialog,
  unarchiveFeatureFlag,
};

/**
 * Pure functions related to Live Variable Usages
 */

/**
 * Creates an empty live variable entity object extended with the supplied data
 * @param {Object} data
 * @return {Object}
 */
export function createLiveVariableUsageEntity(data) {
  const DEFAULTS = {
    id: null,
    experiment_id: null,
    experiment_scope: null,
    group_id: null,
    live_variable_id: null,
    usage_instances: [],
    project_id: null,
  };

  return {
    ...DEFAULTS,
    ...data,
  };
}

export default {
  createLiveVariableUsageEntity,
};

/**
 * Human readable translations of enums
 */
import enums from './enums';

export const EVENT_TYPES = {
  [enums.eventTypes.CLICK]: tr('Click'),
  [enums.eventTypes.CUSTOM]: tr('Custom'),
  [enums.eventTypes.PAGE_VIEW]: tr('Pageview'),
};

export default {
  EVENT_TYPES,
};

/**
 * Enumerable field values for Project
 */
export const dimension_status = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

// Special dimensions are used differently than the standard dimensions so we omit them from the list.
export const NON_STANDARD_DIMENSIONS = [
  'behavior',
  'default_behavior',
  'dynamic_customer_profile',
  'custom_attribute',
  'custom_dimension',
  'predicted_intent',
  'third_party_dimension',
  'list',
];

export default {
  dimension_status,
  NON_STANDARD_DIMENSIONS,
};

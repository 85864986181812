import Nuclear from 'nuclear-js';
import Immutable from 'optly/immutable';

import actionTypes from '../action_types';

export default Nuclear.Store({
  getInitialState() {
    return Immutable.Map({
      timezones: Immutable.List(),
    });
  },

  initialize() {
    this.on(actionTypes.SET_TIMEZONES, setTimezones);
  },
});

/**
 * Handler for actionTypes.SET_USER_CONNECTED
 *
 * @param {Number} state
 * @param {Object} payload
 * @param {Array} payload.timezones
 */
function setTimezones(state, payload) {
  return state.set('timezones', Immutable.fromJS(payload.timezones));
}

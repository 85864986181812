import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'optly/immutable';
import { debounce } from 'lodash';
import ui from 'core/ui';
import { withTrack } from '@optimizely/segment-js/dist/decorators';

import { Input, Table } from 'optimizely-oui';
import LoadingOverlay from 'react_components/loading_overlay';

import CatalogStatsActions from 'optly/modules/entity/catalog_stats/actions';

const $INITIAL_STATE = {
  currentlySelectedItemID: '',
  currentlySelectedItemInfo: [],
  isLoading: false,
};

@withTrack
class ItemDetailsSection extends React.Component {
  static propTypes = {
    /**
     * Current catalog (recommender service)
     */
    catalog: PropTypes.instanceOf(Immutable.Map).isRequired,
    /**
     * Function handler for Segment Tracking
     */
    track: PropTypes.func,
  };

  static defaultProps = {
    track: () => {},
  };

  state = $INITIAL_STATE;

  fetchItemDetails = value => {
    const { catalog, track } = this.props;
    const currentlySelectedItemID = value;

    if (!currentlySelectedItemID) {
      this.setState($INITIAL_STATE);
      return;
    }

    this.setState({
      currentlySelectedItemID,
      isLoading: true,
    });

    /**
     * Fetch the itemInfo for the currentlySelectedItemID
     * This is to show what's the current item we're looking at.
     */
    CatalogStatsActions.fetchItemMetadataById(catalog, currentlySelectedItemID)
      .then(selectedItemInfo => {
        this.setState({
          currentlySelectedItemInfo: Object.entries(
            selectedItemInfo || [],
          ).sort(),
        });
      })
      .catch(() => {
        ui.showNotification({
          message: 'No catalog item found matching that ItemID.',
          type: 'warning',
        });
      })
      .finally(() => this.setState({ isLoading: false }));

    track('Recommendations Catalog Profiler Catalog Item Searched', {
      itemID: currentlySelectedItemID,
    });
  };

  debounceFetchItemDetails = debounce(
    this.fetchItemDetails,
    __TEST__ ? 0 : 500,
  );

  renderItemDetailsTable = () => {
    const {
      currentlySelectedItemID,
      currentlySelectedItemInfo,
      isLoading,
    } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        <LoadingOverlay isLoading={isLoading} size="small">
          {!!currentlySelectedItemID &&
            currentlySelectedItemInfo.length === 0 && (
              <div data-test-section="empty-state-catalog-item-details">
                No catalog item found matching that ItemID.
              </div>
            )}

          {!!currentlySelectedItemID && currentlySelectedItemInfo.length > 0 && (
            <div data-test-section="catalog-item-details-table">
              <Table>
                <Table.THead>
                  <Table.TR>
                    <Table.TH>Tag</Table.TH>
                    <Table.TH>Value</Table.TH>
                  </Table.TR>
                </Table.THead>
                <Table.TBody>
                  {currentlySelectedItemInfo.map(tagInfo => {
                    const tagName = tagInfo[0];
                    const tagValue = tagInfo[1];

                    return (
                      <Table.TR
                        key={tagName}
                        borderStyle="ends"
                        backgroundColor="faint">
                        <Table.TD>
                          <span
                            className="word-break--word"
                            data-test-section={`tag-name-${tagName}`}>
                            {tagName}
                          </span>
                        </Table.TD>
                        <Table.TD>
                          <span
                            className="word-break--word"
                            data-test-section={`tag-value-${tagName}`}>
                            {tagValue}
                          </span>
                        </Table.TD>
                      </Table.TR>
                    );
                  })}
                </Table.TBody>
              </Table>
            </div>
          )}
        </LoadingOverlay>
      </div>
    );
  };

  render() {
    return (
      <div
        className="push-quad--bottom"
        data-test-section="catalog-item-details-section">
        <h3 className="push--bottom">Catalog Item Details</h3>
        <div
          className="push-double--bottom"
          data-test-section="catalog-item-details-description">
          View the tags and metadata association with a catalog item.
        </div>
        <div className="push-quad--bottom">
          <div className="push-double--bottom width--1-2">
            <Input
              id="catalog-item-details"
              isFilter={true}
              onChange={event =>
                this.debounceFetchItemDetails(event.target.value)
              }
              placeholder="Search by ItemID"
              testSection="item-details-input"
              type="text"
            />
          </div>
          {this.renderItemDetailsTable()}
        </div>
      </div>
    );
  }
}

export default ItemDetailsSection;

/**
 * Tag Entity Module
 */
import actions from './actions';
import entityDef from './entity_definition';
import enums from './enums';
import fns from './fns';
import getters from './getters';
import humanReadable from './human_readable';

export { actions, entityDef, enums, fns, getters, humanReadable };

export default {
  actions,
  entityDef,
  enums,
  fns,
  getters,
  humanReadable,
};

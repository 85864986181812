/**
 * Utility to strip any HTML from an input string.
 *
 * Inspired from:
 *   http://stackoverflow.com/questions/822452/strip-html-from-text-javascript
 *
 * @param htmlString
 */
module.exports = function(htmlString) {
  const element = document.createElement('div');
  element.innerHTML = htmlString;
  return element.textContent || element.innerText || '';
};

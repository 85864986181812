const flux = require('core/flux');

const Layer = require('optly/modules/entity/layer');
const LayerExperiment = require('optly/modules/entity/layer_experiment');
const { isCMABGroupedExperienceEnabled } = require('optly/utils/features');

/**
 * Given the reorganized priority groups from the dialog, add the archived
 * experiment ids that were not given to the dialog, and update the layer's
 * experiment priorities
 * @param {Number} layerId
 * @param {Array} experimentPriorities - An array of arrays, with each sub-array
 * @param {Object} experimentWeights - An object containing traffic allocation weights for each experiment
 * representing a priority level, and containing experiment IDs. This should not
 * include archived experiment IDs
 * @return {Deferred}
 */
exports.saveExperimentPriorities = (
  layerId,
  experimentPriorities,
  experimentWeights = {},
) => {
  const layer = flux.evaluate(Layer.getters.byId(layerId));
  const experimentsCache = flux.evaluate(LayerExperiment.getters.entityCache);
  const { archivedGroup } = Layer.fns.getPriorityGroupsSplitByStatus(
    layer,
    experimentsCache,
  );
  let newPriorityGroups = experimentPriorities;
  if (archivedGroup.size) {
    newPriorityGroups = newPriorityGroups.concat([archivedGroup.toJS()]);
    if (isCMABGroupedExperienceEnabled()) {
      // add weights to archived group
      const {
        distributedValue,
        lastValue,
      } = Layer.fns.equallyDistributeTraffic(archivedGroup.size);
      archivedGroup.forEach((id, index) => {
        experimentWeights[id] =
          index === archivedGroup.size - 1 ? lastValue : distributedValue;
      });
    }
  }
  return Layer.actions.updateExperimentPriorities(
    layerId,
    newPriorityGroups,
    experimentWeights,
  );
};

exports.attemptCreatingNewVariation = (variationName, experimentId) => {
  const experiment = flux.evaluateToJS(
    LayerExperiment.getters.byId(experimentId),
  );
  const validation = LayerExperiment.fns.validateVariationName(
    experiment,
    variationName,
  );
  if (!validation.valid) {
    return {
      isNameValid: false,
      errorMessage: validation.message,
    };
  }
  return {
    isNameValid: true,
    saveDef: LayerExperiment.actions.createNewVariationWithRedistributedTraffic(
      variationName,
      experiment,
    ),
  };
};

const EventModule = require('optly/modules/entity/event');

exports.id = ['p13n/currentEvent', 'id'];

/**
 * The currently selected event in the UI
 */
exports.event = [
  exports.id,
  EventModule.getters.entityCache,
  function(id, entityCache) {
    return entityCache.get(id);
  },
];

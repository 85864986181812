import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';
import Immutable from 'optly/immutable';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { getters as EventGetters } from 'optly/modules/entity/event';
import { getters as ViewGetters } from 'optly/modules/entity/view';

import RecommendationsSectionModuleConstants from 'bundles/p13n/sections/implementation/section_module/constants';

import entityDef from './entity_definition';

const baseEntityGetters = createEntityGetters(entityDef);
export const { byId, entityCache } = baseEntityGetters;

const percentageRanges = [
  '90 - 100%',
  '80 - 90%',
  '70 - 80%',
  '60 - 70%',
  '50 - 60%',
  '40 - 50%',
  '30 - 40%',
  '20 - 30%',
  '10 - 20%',
  '00 - 10%',
];

export const statusUpdatesByCatalogID = catalogID => [
  entityCache,
  jobStatuses => {
    const jobStatusById = jobStatuses.find(
      jobStatus => jobStatus.get('source_id') === catalogID,
    );
    return jobStatusById && jobStatusById.getIn(['status_updates', 0]);
  },
];

export const distributionsByCatalogID = catalogID => [
  statusUpdatesByCatalogID(catalogID),
  updatesById =>
    updatesById
      ? updatesById.getIn(['message_args', 'distributions'])
      : Immutable.Map(),
];

export const summaryStatsByCatalogID = catalogID => [
  statusUpdatesByCatalogID(catalogID),
  updatesById =>
    updatesById
      ? updatesById.getIn(['message_args', 'summary'])
      : Immutable.Map(),
];

export const updatedTimeByCatalogID = catalogID => [
  entityCache,
  jobStatuses => {
    const jobStatusById = jobStatuses.find(
      jobStatus => jobStatus.get('source_id') === catalogID,
    );
    return jobStatusById && jobStatusById.get('last_modified');
  },
];

export const itemAndVisitorEventDistributionByCatalogID = catalogID => [
  distributionsByCatalogID(catalogID),
  distributionsById => {
    if (distributionsById.isEmpty()) {
      return [];
    }

    const itemEventDistribution = distributionsById.get(
      'item_event_distribution',
    );
    const visitorEventDistribution = distributionsById.get(
      'visitor_event_distribution',
    );

    return percentageRanges.map((percentage, i) => ({
      numberOfItems: Number(
        Math.round(itemEventDistribution.get(i)),
      ).toLocaleString(),
      numberOfVisitors: Number(
        Math.round(visitorEventDistribution.get(i)),
      ).toLocaleString(),
      percentage,
    }));
  },
];

export const displayDistributionSamplesByCatalogID = catalogID => [
  distributionsByCatalogID(catalogID),
  distributionsById => {
    if (distributionsById.isEmpty()) {
      return [];
    }

    const itemSamples = distributionsById.get('item_samples');
    return percentageRanges.map((percentage, i) => ({
      label: percentage,
      value: itemSamples.get(i),
    }));
  },
];

export const pageEventsStatsByCatalogID = catalogID => [
  statusUpdatesByCatalogID(catalogID),
  CurrentProjectGetters.name,
  EventGetters.activeEvents,
  ViewGetters.activeAccountViews,
  (updatesById, projectName, events, views) => {
    if (!updatesById) {
      return Immutable.Map();
    }

    const perEventStats = updatesById.getIn([
      'message_args',
      'per_event_stats',
    ]);
    return perEventStats.mapEntries(([eventId, eventStats]) => {
      const eventKind = eventStats.get('event_type');
      let event;
      if (eventKind === RecommendationsSectionModuleConstants.EventKind.VIEW) {
        event = views.get(Number(eventId));
        const eventName = (event && event.get('name')) || 'Not Applicable';
        const humanReadableType =
          RecommendationsSectionModuleConstants.ENTITY_HUMAN_READABLES.EVENT
            .VIEW;
        return [
          eventId,
          eventStats.set('display_name', `${humanReadableType} - ${eventName}`),
        ];
      }

      if (eventKind === RecommendationsSectionModuleConstants.EventKind.EVENT) {
        event = events.get(Number(eventId));
        const eventName = (event && event.get('name')) || 'Not Applicable';
        const humanReadableType =
          RecommendationsSectionModuleConstants.ENTITY_HUMAN_READABLES.EVENT[
            event.get('event_type')
          ];
        return [
          eventId,
          eventStats.set('display_name', `${humanReadableType} - ${eventName}`),
        ];
      }

      return [eventId, eventStats];
    });
  },
];

export default {
  ...baseEntityGetters,
  displayDistributionSamplesByCatalogID,
  distributionsByCatalogID,
  itemAndVisitorEventDistributionByCatalogID,
  pageEventsStatsByCatalogID,
  statusUpdatesByCatalogID,
  summaryStatsByCatalogID,
  updatedTimeByCatalogID,
};

const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const actionTypes = require('../action_types');

module.exports = Nuclear.Store({
  getInitialState() {
    return toImmutable({});
  },

  initialize() {
    this.on(actionTypes.START_PAUSING, startPausing);
    this.on(actionTypes.START_PUBLISHING, startPublishing);
    this.on(actionTypes.START_STARTING, startStarting);
    this.on(actionTypes.FINISH_PAUSING, finishPausing);
    this.on(actionTypes.FINISH_PUBLISHING, finishPublishing);
    this.on(actionTypes.FINISH_STARTING, finishStarting);
  },
});

/**
 * Start Pausing Operation
 */
function startPausing(state, payload) {
  return state.setIn([payload.layerId, 'isInProcessOfPausing'], true);
}

/**
 * Start Publishing Operation
 */
function startPublishing(state, payload) {
  return state
    .setIn([payload.layerId, 'isInProcessOfPublishing'], true)
    .setIn(
      [payload.layerId, 'isInProcessOfFirstPublish'],
      payload.isFirstPublish,
    );
}

/**
 * Start Starting Operation
 */
function startStarting(state, payload) {
  return state.setIn([payload.layerId, 'isInProcessOfStarting'], true);
}

/**
 * Finishing Pausing Operation
 */
function finishPausing(state, payload) {
  return state.setIn([payload.layerId, 'isInProcessOfPausing'], false);
}

/**
 * Finishing Publishing Operation
 */
function finishPublishing(state, payload) {
  return state
    .setIn([payload.layerId, 'isInProcessOfPublishing'], false)
    .setIn([payload.layerId, 'isInProcessOfFirstPublish'], false);
}

/**
 * Finishing Starting Operation
 */
function finishStarting(state, payload) {
  return state.setIn([payload.layerId, 'isInProcessOfStarting'], false);
}

import React from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Sheet, Textarea } from 'optimizely-oui';
import { withTrack } from '@optimizely/segment-js/dist/decorators';

import ui from 'core/ui';
import { connect } from 'core/ui/decorators';
import Immutable from 'optly/immutable';
import LoadingOverlay from 'react_components/loading_overlay';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import RecommenderActions from 'optly/modules/entity/recommender/actions';
import RecommenderConstants from 'optly/modules/entity/recommender/constants';
import PermissionsGetters from 'optly/modules/permissions/getters';

import AlgorithmPicker from 'bundles/p13n/sections/implementation/components/recommendations/pickers/algorithm_picker';
import CatalogPicker from 'bundles/p13n/sections/implementation/components/recommendations/pickers/catalog_picker';
import EventPicker from 'bundles/p13n/sections/implementation/components/recommendations/pickers/event_picker';
import FilteringRulesBuilder from 'bundles/p13n/sections/implementation/components/recommendations/filtering_rules_builder';
import RecommenderDetails from 'bundles/p13n/sections/implementation/components/recommendations/entity_details';
import {
  RecommendationsActionsText,
  RecommendationsHelpLink,
} from 'bundles/p13n/components/messaging/recommendations';

import SectionModuleActions from 'bundles/p13n/sections/implementation/section_module/actions';
import SectionModuleConstants from 'bundles/p13n/sections/implementation/section_module/constants';
import SectionModuleFns from 'bundles/p13n/sections/implementation/section_module/fns';
import SectionModuleGetters from 'bundles/p13n/sections/implementation/section_module/getters';

const getFilteringRulesAsString = filteringRules =>
  filteringRules ? JSON.stringify(filteringRules, null, '  ') : '';
const safeTrim = str => (str || '').trim();

const DEFAULT_FILTERING_RULES = null;
const DEFAULT_SPECIFICS = Immutable.Map();
const EMPTY_CATALOG = Immutable.Map();

@withTrack
@connect({
  canManageRecommendations: PermissionsGetters.canManageRecommendations,
  catalogs: CurrentProjectGetters.catalogs,
  currentProjectTags: SectionModuleGetters.availableTags,
  parentCatalog: SectionModuleGetters.catalog,
  recommender: SectionModuleGetters.currentlyEditingRecommender,
})
class RecommenderDialog extends React.Component {
  static componentId = 'RecommenderDialog';

  static propTypes = {
    canManageRecommendations: PropTypes.bool.isRequired,
    catalogs: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentProjectTags: PropTypes.instanceOf(Immutable.List).isRequired,
    parentCatalog: PropTypes.instanceOf(Immutable.Map).isRequired,
    recommender: PropTypes.instanceOf(Immutable.Map).isRequired,
    track: PropTypes.func,
  };

  static defaultProps = {
    track: () => {},
  };

  constructor(props) {
    super(props);

    const { recommender, track } = props;

    this.state = {
      areFilteringRulesValid: true,
      filteringRules: getFilteringRulesAsString(
        recommender.get('filtering_rules'),
      ),
      filteringRulesError: '',
      isLoading: false,
      isNameValid: true,
      useFilteringRulesCodeMode: this.areFilteringRulesNested(),
    };

    this.isCreating = !recommender.get('id');

    if (this.isCreating) {
      track('Recommendations Create New Recommender Dialog Opened');
    } else {
      track('Recommendations Edit Existing Recommender Dialog Opened');
    }
  }

  areFilteringRulesNested = () => {
    const { recommender } = this.props;

    const rules = recommender.getIn(['filtering_rules', 'rules']);

    if (!rules) {
      return false;
    }

    const rootConditionsWithNestedConditions = rules.filter(rule => {
      const conditions = rule.getIn(['condition', 'conditions']);
      if (!conditions) {
        return false;
      }
      const filteredConditions = conditions.filter(condition =>
        condition.get('conditions'),
      );
      return filteredConditions.size > 0;
    });
    return rootConditionsWithNestedConditions.size > 0;
  };

  countConditions = () => {
    const counter = condition => {
      if (condition && condition.get('conditions')) {
        const nestedConditions = condition.get('conditions');
        return nestedConditions
          .map(nestedCondition => counter(nestedCondition))
          .reduce((total, subtotal) => total + subtotal);
      }
      return condition ? 1 : 0;
    };

    const { recommender } = this.props;

    const rules = recommender.getIn(['filtering_rules', 'rules']);

    if (rules) {
      return rules
        .map(rule => {
          const conditions = rule.get('condition');
          return conditions ? counter(conditions) : 0;
        })
        .reduce((total, subtotal) => total + subtotal);
    }
    return 0;
  };

  handleUpdateFilteringRules = filteringRules => {
    // Update component state( which the code mode relies on) in addition to the recommender in the flux store.
    this.updateFilteringRulesComponentState(
      getFilteringRulesAsString(filteringRules),
    );
    this.updateRecommenderProperty('filtering_rules', filteringRules);
  };

  parseFilteringRulesComponentState = () => {
    const { filteringRules } = this.state;

    if (filteringRules === '') {
      this.updateRecommenderProperty(
        'filtering_rules',
        DEFAULT_FILTERING_RULES,
      );
      this.setState({
        areFilteringRulesValid: true,
        filteringRulesError: '',
      });
      return;
    }

    try {
      const filteringRulesJson = JSON.parse(filteringRules);
      this.updateRecommenderProperty('filtering_rules', filteringRulesJson);
      this.setState({
        areFilteringRulesValid: true,
        filteringRulesError: '',
      });
    } catch (e) {
      this.setState({
        areFilteringRulesValid: false,
        filteringRulesError: e.message || 'Failed to parse filtering rules',
      });
    }
  };

  /**
   * Function handler to change an algorithm.
   * When changing algorithm, currently used events will be invalid and removed.
   * Filters are not affected.
   *
   * @param {String} algorithm
   */
  onAlgorithmChange = algorithm => {
    const { recommender, track } = this.props;

    const currentAlgorithm = recommender.get('algorithm');

    if (algorithm !== currentAlgorithm) {
      /**
       * Using a Promise.resolve() here to avoid duplicate code upon switching algorithms.
       * This results in the need of returning a Promise.resolve() in the test to ensure component update
       * and validation happen after the Promise.resolve() to finish in the next tick.
       */
      (currentAlgorithm
        ? ui.confirm(RecommendationsActionsText.AlgorithmChange)
        : Promise.resolve()
      ).then(() => {
        this.updateRecommenderProperty('algorithm', algorithm);
        this.updateRecommenderProperty('specifics', DEFAULT_SPECIFICS);

        if (algorithm) {
          track('Recommendations Recommender Algorithm Selected', {
            algorithm,
          });
        }
      });
    }
  };

  /**
   * Function handler to change a catalog.
   * When changing catalog, currently used events and filters will be invalid and removed.
   *
   * @param {Integer} selectedCatalogID
   */
  onCatalogChange = selectedCatalogID => {
    const { catalogs, recommender, track } = this.props;

    const selectedCatalog = catalogs.get(selectedCatalogID) || EMPTY_CATALOG;
    const currentCatalogID = recommender.get('recommender_service_id');

    if (selectedCatalogID !== currentCatalogID) {
      /**
       * Using a Promise.resolve() here to avoid duplicate code upon switching algorithms.
       * This results in the need of returning a Promise.resolve() in the test to ensure component update
       * and validation happen after the Promise.resolve() to finish in the next tick.
       */
      (currentCatalogID
        ? ui.confirm(RecommendationsActionsText.CatalogChange)
        : Promise.resolve()
      ).then(() => {
        SectionModuleActions.setCatalog(selectedCatalog);
        this.setState({
          areFilteringRulesValid: true,
          filteringRules: getFilteringRulesAsString(DEFAULT_FILTERING_RULES),
          filteringRulesError: '',
        });
        this.updateRecommenderProperty(
          'filtering_rules',
          DEFAULT_FILTERING_RULES,
        );
        this.updateRecommenderProperty(
          'recommender_service_id',
          selectedCatalogID,
        );
        this.updateRecommenderProperty('specifics', DEFAULT_SPECIFICS);

        if (selectedCatalogID) {
          track('Recommendations Recommender Catalog Selected', {
            catalogID: selectedCatalogID,
          });
        }
      });
    }
  };

  updateFilteringRulesComponentState = filteringRulesCandidate => {
    // Assign null for '' so that JSON becomes valid
    const newFilteringRules = safeTrim(filteringRulesCandidate) || null;
    this.setState({
      areFilteringRulesValid: true,
      filteringRules: newFilteringRules,
    });
  };

  /**
   * Function handler to update any property of recommender with new value.
   *
   * @param {String} property any property of recommender to be updated (name, description, algorithm, etc.)
   * @param {*} inputValue new value to update the property of recommender.
   */
  updateRecommenderProperty = (property, inputValue) => {
    if (property === 'name') {
      this.setState({ isNameValid: true });
    }

    if (property === 'filtering_rules') {
      this.setState({ areFilteringRulesValid: true });
    }

    SectionModuleActions.updateCurrentlyEditingRecommenderProperty(
      property,
      inputValue,
    );
  };

  /**
   * Returns true if recommender's name is valid, false otherwise.
   *
   * @returns {Boolean} whether or not recommender's name is valid.
   */
  validateName = () => {
    const { recommender } = this.props;
    const nameLength = safeTrim(recommender.get('name')).length;
    const isNameValid = nameLength > 0 && nameLength < 1500;

    this.setState({ isNameValid });

    return isNameValid;
  };

  /**
   * Returns true if recommender's events are valid, false otherwise.
   *
   * @returns {Boolean} whether or not recommender's events are valid.
   */
  areEventsValid = () => {
    const { recommender } = this.props;

    const algorithm = recommender.get('algorithm');
    const eventID = RecommenderConstants.AlgorithmEventInfo[algorithm].id;
    const events = recommender.getIn(['specifics', eventID]);
    return events && events.size > 0;
  };

  /**
   * Returns true if all properties of recommender are valid and recommender can be created or updated, false otherwise.
   *
   * @returns {Boolean} whether or not recommender can be created or updated.
   */
  canSave = () => {
    const { recommender } = this.props;
    const { areFilteringRulesValid } = this.state;

    const hasACatalog = !!recommender.get('recommender_service_id');
    const hasAnAlgorithm = !!recommender.get('algorithm');

    return (
      this.validateName() &&
      hasACatalog &&
      hasAnAlgorithm &&
      this.areEventsValid() &&
      areFilteringRulesValid
    );
  };

  /**
   * Function handler to create or edit recommender.
   * Notify users if there's any error that needs to be corrected in order to create or edit recommender.
   */
  saveAction = () => {
    if (!this.canSave()) {
      return;
    }

    const { recommender, track } = this.props;

    const saveErrorMessage = this.isCreating
      ? 'Unable to create recommender.'
      : 'Unable to save recommender.';
    const saveSuccessMessage = this.isCreating
      ? 'Recommender created successfully.'
      : 'Recommender saved successfully.';

    const recommenderToSave = {
      algorithm: recommender.get('algorithm'),
      description: recommender.get('description'),
      filtering_rules: recommender.get('filtering_rules'),
      id: recommender.get('id'),
      name: recommender.get('name'),
      recommender_service_id: recommender.get('recommender_service_id'),
      specifics: recommender.get('specifics'),
    };

    this.setState({ isLoading: true });

    return RecommenderActions.save(recommenderToSave)
      .then(savedRecommender => {
        const trackRecommenderData = {
          algorithm: savedRecommender.algorithm,
          catalogID: savedRecommender.recommender_service_id,
          recommenderID: savedRecommender.id,
          specifics: savedRecommender.specifics,
        };

        if (this.isCreating) {
          track('Recommendations Recommender Created', trackRecommenderData);
        } else {
          track('Recommendations Recommender Edited', trackRecommenderData);
        }

        if (recommender.get('filtering_rules')) {
          track('Recommendations Filter Added', {
            numConditions: this.countConditions(),
          });
        }

        ui.showNotification({
          message: saveSuccessMessage,
          type: 'success',
        });

        ui.hideDialog();
      })
      .fail(failedResponse => {
        if (
          failedResponse.responseJSON.error_details.find(
            error => error.field === 'filtering_rules',
          )
        ) {
          this.setState({
            areFilteringRulesValid: false,
            filteringRulesError: 'Invalid Filtering Rules.',
          });
        }

        ui.showNotification({
          message: saveErrorMessage,
          type: 'error',
        });

        // We caught the error already, need to tell the global error handler to not show the error dialog anymore.
        failedResponse.optlyErrorHandled = true;
      })
      .always(() => this.setState({ isLoading: false }));
  };

  switchFilteringRulesMode = () => {
    const { useFilteringRulesCodeMode } = this.state;
    this.setState({ useFilteringRulesCodeMode: !useFilteringRulesCodeMode });
  };

  renderFilteringRulesSection = () => {
    const { recommender } = this.props;

    const hasACatalog = !!recommender.get('recommender_service_id');
    const hasAnAlgorithm = !!recommender.get('algorithm');

    return (
      <div
        className="push-quad--bottom"
        data-test-section="recommender-filtering-rules">
        <h4>Filters</h4>
        <div
          className="push-double--bottom"
          data-test-section="filtering-rules-help-text">
          Add filtering conditions to customize the output of this
          recommender.&nbsp;
          <RecommendationsHelpLink
            helpLink={SectionModuleFns.getHelpCopy('filtering_rules_link')}
            testSection="recommender-dialog-filters"
          />
        </div>
        {hasACatalog && hasAnAlgorithm ? (
          this.renderFilters()
        ) : (
          <span
            className="style--italic"
            data-test-section="filtering-rules-empty-state-help-text">
            Select a catalog and an algorithm to use filters.
          </span>
        )}
      </div>
    );
  };

  renderFilters = () => {
    const { currentProjectTags, recommender } = this.props;

    const {
      areFilteringRulesValid,
      filteringRules,
      filteringRulesError,
      useFilteringRulesCodeMode,
    } = this.state;

    const cannotSwitchModes = this.areFilteringRulesNested();
    const defaultPolicy = recommender.getIn([
      'filtering_rules',
      'default_policy',
    ]);
    const rules = recommender.getIn(['filtering_rules', 'rules']);

    return (
      <React.Fragment>
        {useFilteringRulesCodeMode ? (
          <React.Fragment>
            <div className="push--bottom flex flex-justified--end">
              <Button
                key="advanced-0"
                style="outline"
                size="small"
                onClick={this.switchFilteringRulesMode}
                isDisabled={cannotSwitchModes}
                testSection="filters-switch-to-builder">
                Filtering rules builder
              </Button>
            </div>
            <Textarea
              className="condition-code-editor flex--1 min-height--200 width--1-1 border--all"
              numRows={20}
              onBlur={this.parseFilteringRulesComponentState}
              onChange={event =>
                this.updateFilteringRulesComponentState(event.target.value)
              }
              testSection="edit-filtering-rules-textarea"
              value={filteringRules}
            />
          </React.Fragment>
        ) : (
          <FilteringRulesBuilder
            currentProjectTags={currentProjectTags}
            defaultPolicy={defaultPolicy}
            rules={rules}
            handleUpdateFilteringRules={this.handleUpdateFilteringRules}
            switchFilteringRulesMode={this.switchFilteringRulesMode}
          />
        )}
        {!areFilteringRulesValid &&
          this.renderErrorMessage(
            filteringRulesError,
            'edit-filtering-rules-errors',
          )}
      </React.Fragment>
    );
  };

  renderSingleEventPicker = (eventInfo, areEventsValid) => (
    <EventPicker
      areEventsValid={areEventsValid}
      description={eventInfo.description}
      id={eventInfo.id}
      key={eventInfo.id}
      label={eventInfo.label}
      placeholder={eventInfo.placeholder}
      renderErrorMessage={this.renderErrorMessage}
      testSection={eventInfo.testSection}
    />
  );

  renderEventPickersSections = () => {
    const { recommender } = this.props;

    const algorithm = recommender.get('algorithm');
    const parentCatalog = recommender.get('recommender_service_id');

    if (!algorithm || !parentCatalog) {
      return (
        <div
          className="push-triple--bottom"
          data-test-section="recommender-event-pickers-empty-state">
          <h5>Catalog Events</h5>
          <div
            className="push--bottom"
            data-test-section="recommender-event-pickers-empty-state-description">
            Choose the catalog events you want to use for this
            recommender.&nbsp;
            <RecommendationsHelpLink
              helpLink={SectionModuleFns.getHelpCopy('setup_recommender_link')}
              testSection="recommender-dialog-catalog-events"
            />
          </div>
          <Input
            isDisabled={true}
            isFilter={true}
            placeholder="Search and add events to this catalog"
            testSection="recommender-event-pickers-empty-state-input"
            type="search"
          />
        </div>
      );
    }

    const isCoBrowseOrCollaborative =
      algorithm === RecommenderConstants.Algorithm.CO_BROWSE ||
      algorithm === RecommenderConstants.Algorithm.COLLABORATIVE_FILTERING;

    const mainEventInfo = RecommenderConstants.AlgorithmEventInfo[algorithm];
    const boostEventInfo = RecommenderConstants.AlgorithmEventInfo.BOOST;

    const mainEventPicker = this.renderSingleEventPicker(
      mainEventInfo,
      this.areEventsValid(),
    );
    const boostEventPicker = this.renderSingleEventPicker(boostEventInfo, true);

    return (
      <div
        className="push-triple--bottom"
        data-test-section="recommender-event-pickers">
        {mainEventPicker}
        {isCoBrowseOrCollaborative && boostEventPicker}
      </div>
    );
  };

  renderErrorMessage = (errorMessage, testSection) => (
    <div
      className="lego-form-note lego-form-note--bad-news"
      data-test-section={testSection}>
      {errorMessage}
    </div>
  );

  render() {
    const {
      canManageRecommendations,
      catalogs,
      parentCatalog,
      recommender,
    } = this.props;
    const { isLoading, isNameValid } = this.state;

    const hasACatalog = recommender.get('recommender_service_id');

    const dialogTitle = this.isCreating
      ? 'New Recommender'
      : 'Edit Recommender';
    const saveButtonLabel = this.isCreating
      ? 'Create Recommender'
      : 'Save Recommender';

    return (
      <Sheet
        footerButtonList={[
          <Button
            key="cancel"
            style="plain"
            onClick={ui.hideDialog}
            testSection="recommender-dialog-cancel-button">
            Cancel
          </Button>,
          <Button
            isDisabled={!canManageRecommendations}
            key="save"
            style="highlight"
            testSection="recommender-dialog-create-save-button"
            onClick={this.saveAction}>
            {saveButtonLabel}
          </Button>,
        ]}
        onClose={ui.hideDialog}
        subtitle={
          <React.Fragment>
            Recommenders are used to calculate recommendations from your catalog
            items.&nbsp;
            <RecommendationsHelpLink
              helpLink={SectionModuleFns.getHelpCopy('setup_recommender_link')}
              testSection="recommender-dialog-subtitle"
            />
          </React.Fragment>
        }
        title={dialogTitle}
        warningContent={
          !canManageRecommendations &&
          SectionModuleConstants.EDIT_WARNING_TEXTS.RECOMMENDER.message
        }
        warningTestSection={
          SectionModuleConstants.EDIT_WARNING_TEXTS.RECOMMENDER.testSection
        }>
        <LoadingOverlay isLoading={isLoading}>
          <RecommenderDetails
            entity={recommender}
            entityInfo={SectionModuleConstants.EntityInfo.RECOMMENDER}
            renderErrorMessage={this.renderErrorMessage}
            showValidationError={!isNameValid}
            updateEntityProperty={this.updateRecommenderProperty}
          />
          <CatalogPicker
            canChangeCatalog={this.isCreating}
            catalogs={catalogs}
            currentlySelectedCatalogID={
              hasACatalog ? parentCatalog.get('id') : EMPTY_CATALOG.get('id')
            }
            defaultCatalog={EMPTY_CATALOG}
            handleCatalogChange={this.onCatalogChange}
          />
          <AlgorithmPicker
            handleAlgorithmChange={this.onAlgorithmChange}
            recommender={recommender}
          />
          {this.renderEventPickersSections()}
          {this.renderFilteringRulesSection()}
        </LoadingOverlay>
      </Sheet>
    );
  }
}

export default RecommenderDialog;

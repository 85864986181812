import React from 'react';
import PropTypes from 'prop-types';
import ui from 'core/ui';
import tr from 'optly/translate';
import Immutable, { toImmutable } from 'optly/immutable';
import { useFeature } from '@optimizely/react-sdk';

import * as ExperimentationGroupConstants from 'optly/modules/entity/experimentation_group/constants';
import * as ExperimentationGroupEnums from 'optly/modules/entity/experimentation_group/enums';

import FilterList from 'react_components/filter_list';

import {
  actions as ConfigureGroupComponentModuleActions,
  constants as ConfigureGroupComponentModuleConstants,
  fns as ConfigureGroupComponentModuleFns,
  getters as ConfigureGroupComponentModuleGetters,
} from '../component_module';

const ExperimentPicker = props => {
  const [isUserFriendlyNamesEnabled] = useFeature('user_friendly_names');
  const handleAddEntity = (event, entity) => {
    const layerKind =
      ExperimentationGroupEnums.EXPERIMENTATION_GROUP_TYPES.LAYER;
    const layerExperimentKind =
      ExperimentationGroupEnums.EXPERIMENTATION_GROUP_TYPES.LAYER_EXPERIMENT;

    const updatedEntities = props.group.get('entities').push(
      toImmutable({
        // Read only attributes
        name: entity.get('name'),
        status: entity.get('status'),
        ...(isUserFriendlyNamesEnabled && { api_name: entity.get('key') }),
        // Required attributes for new group entities
        id: entity.get('id'),
        kind: props.isCustomProject ? layerExperimentKind : layerKind,
        weight: ExperimentationGroupConstants.ZERO_TRAFFIC_ALLOCATION,
      }),
    );

    ConfigureGroupComponentModuleActions.setGroup(
      props.group.set('entities', updatedEntities),
    );

    // Set the warning flag if we have added an experiment that is running in
    // any environment to the group
    const shouldSetWarningFlag = ConfigureGroupComponentModuleFns.shouldFlagForWarningOnTrafficChange(
      entity,
      props.isCustomProject,
    );
    if (shouldSetWarningFlag) {
      ConfigureGroupComponentModuleActions.flagError(
        ConfigureGroupComponentModuleConstants.ErrorFlag
          .FULL_STACK_RUNNING_EXPERIMENT_UPDATE,
        true,
      );
    }
  };

  const { canEditGroup, availableEntities } = props;

  const unselectedBlocks = toImmutable([
    {
      key: 'recently_created',
      label: tr('Recently Created Experiments'),
      items: availableEntities,
    },
  ]);

  let inputPlaceholder = tr('Browse for Experiments');

  if (!availableEntities.size) {
    inputPlaceholder = tr('There are no experiments available to be added.');
  }

  if (!canEditGroup) {
    inputPlaceholder = tr(
      'You do not have the necessary permissions to edit this group.',
    );
  }

  return (
    <li className="lego-form-field__item">
      <FilterList
        key="groupsEntityPicker"
        isDisabled={!canEditGroup || !availableEntities.size}
        entityName="groups-experiment-picker"
        itemOnClick={handleAddEntity}
        inputPlaceholder={inputPlaceholder}
        lists={unselectedBlocks}
        showNameAndKey={isUserFriendlyNamesEnabled}
      />
    </li>
  );
};

ExperimentPicker.propTypes = {
  availableEntities: PropTypes.instanceOf(Immutable.List).isRequired,
  canEditGroup: PropTypes.bool.isRequired,
  group: PropTypes.instanceOf(Immutable.Map).isRequired,
  isCustomProject: PropTypes.bool.isRequired,
};

export default ui.connectGetters(ExperimentPicker, {
  availableEntities: ConfigureGroupComponentModuleGetters.availableEntities,
});

/**
 * Third Party Condition Select Field Component
 * Responsible for rendering the configuration UI for a third-party select condition
 */
import _ from 'lodash';

import cloneDeep from 'optly/clone_deep';
import ui from 'core/ui';
import formatFns from 'optly/utils/display_format_fns';

import baseComponent from './base';
import template from './select_with_detail.html';

export default _.extend({}, baseComponent, {
  template,

  data: {
    conditionValues: null,
    selectedConditionIndex: null,
    conditionValuesWithDescription: null,
    hasEmptyValueError: false,
  },

  methods: {
    setSegmentCountAndDescription() {
      if (this.conditionValues) {
        this.conditionValues.forEach(condition => {
          if (condition.child_options) {
            let count;
            let isConditionsEmpty;
            condition.child_options.forEach(child => {
              if (child.value === 'count') {
                count = child.text;
              }
              if (child.value === 'is_conditions_empty') {
                isConditionsEmpty = child.text === 'true';
              }
              if (
                child.value === condition.value &&
                child.text !== condition.text
              ) {
                // eslint-disable-next-line no-param-reassign
                condition.subtext = child.text;
              }
            });

            if (count === null) {
              // eslint-disable-next-line no-param-reassign
              condition.text = `${condition.text} | Size estimate unavailable`;
            }

            if (typeof count === 'number') {
              // eslint-disable-next-line no-param-reassign
              condition.text =
                count > 100
                  ? `${
                      condition.text
                    } | ≈ ${formatFns.formatBigNumberForDisplay(
                      count,
                      1,
                    )} users/month`
                  : `${condition.text} | ${count}${
                      isConditionsEmpty ? '' : '+'
                    }`;
            }
          }
        });
      }
    },
    restoreSelectedOptions() {
      const conditionId = this.condition.value.toString();
      this.selectedConditionIndex = _.findIndex(
        this.conditionValues,
        condition => condition.value.toString() === conditionId,
      );
    },

    setDefaultValue() {
      if (this.condition.value) {
        this.restoreSelectedOptions();
      } else {
        this.selectedConditionIndex = -1;
        this.updateConditionValue();
      }
    },

    updateConditionValue() {
      if (this.selectedConditionIndex !== -1) {
        const selectedCondition = this.conditionValues[
          this.selectedConditionIndex
        ];
        this.condition.value = selectedCondition.value.toString();
      } else {
        this.condition.value = '';
      }
    },
    validateCondition() {
      const val = this.condition.value;
      this.hasEmptyValueError = val === '';
      return !this.hasEmptyValueError;
    },
  },

  created() {
    this.conditionValues = [];
  },

  ready() {
    baseComponent.ready.call(this);

    // Initializes the condition values with those inherited from base.js
    this.conditionValues = cloneDeep(this.config.values);

    this.setDefaultValue();

    // Notify user if conditions weren't loaded
    if (!this.conditionValues || !this.conditionValues.length) {
      ui.showNotification({
        message: tr(
          'Optimizely is still syncing with your account. Please try again in a few minutes.',
        ),
      });
    } else {
      this.setSegmentCountAndDescription();
    }
    const validate = this.validateCondition.bind(this);
    this.$on('validateConditions', () => {
      this.$dispatch('validateConditionsResponse', {
        valid: validate(),
        // no need to supply a message here since the validation has UI
      });
    });
  },
});

export const entity = 'integrations';

/**
 * Entity Definition for integration
 */
export default {
  entity,

  /**
   * Optional definitions
  // fieldTypes are used for client side sorting / filtering
  fieldTypes: {
    categories: fieldTypes.ARRAY,
    channels: fieldTypes.ARRAY,
  },
   */
};

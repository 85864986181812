import RestApi from 'optly/modules/rest_api';

import RequestUtil from 'optly/utils/request';

import definition from './entity_definition';
import enums from './enums';
import fns from './fns';

const baseEntityActions = RestApi.createEntityActions(definition);

/**
 * Duplicates a plugin
 * @param {Plugin} plugin
 * @return {Deferred}
 */
function duplicate(plugin) {
  const options = {
    url: `/api/v1/projects/${plugin.project_id}/plugins/${plugin.plugin_id}/duplicate`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(plugin),
  };
  return RequestUtil.makeOptlyFetchRequest(options);
}

export const {
  save,
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deletePlugin = baseEntityActions.delete;

export { deletePlugin as delete };

export function fetchActiveEventPlugins(projectId) {
  return this.fetchAll({
    project_id: projectId,
    archived: false,
    plugin_type: enums.plugin_type.EVENT,
  });
}

export function onPluginCreate(plugin, currentProjectId, snippetGlobalName) {
  const pluginToSave = fns.fillDefaults(
    plugin,
    currentProjectId,
    snippetGlobalName,
  );
  return this.save(pluginToSave);
}

export function onPluginCopy(pluginToSave) {
  return duplicate(pluginToSave);
}

export default {
  ...baseEntityActions,
  fetchActiveEventPlugins,
  onPluginCreate,
  onPluginCopy,
};

import { hideDialog } from 'core/ui/methods/dialogs';

import htmlTemplate from './confirm.html';

/**
 * Component to show a confirm dialog
 * Intended to be used with services/confirm
 *
 * @author Tyler Brandt (tyler@optimizely.com)
 */
const exported = {
  template: htmlTemplate,

  data: {
    isWarning: false,
    title: tr('Please confirm your action'),
    message: tr('Are you sure?'),
    confirmText: tr('Confirm'),
    cancelText: tr('Cancel'),
    __hasAccepted: false,
    doSanitizeHTML: true,
    trackName: 'confirm-submit-button', // referenced as [[trackName]] because custom delimiters are used: https://github.com/optimizely/optimizely/blob/d7c5ff76587bcc9e92fcadf13bb1b3e72c7d83a8/src/www/frontend/src/js/core/ui/methods/initialize_vue.js#L109
    publishWarning: false,
  },

  beforeDestroy() {
    if (!this.__hasAccepted && this.onReject) {
      this.onReject();
    }
  },

  methods: {
    accept(event) {
      event.preventDefault();
      this.__hasAccepted = true;
      hideDialog();
      if (this.onResolve) {
        this.onResolve();
      }
    },
  },
};

export default exported;

export const { template, data, beforeDestroy, methods } = exported;

import PropTypes from 'prop-types';
import React from 'react';
import htmlSanitizer from 'sanitizer';

import { feature } from '@optimizely/js-sdk-lab/src/decorators';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import { ButtonIcon, Table, ProgressDots } from 'optimizely-oui';

import ui from 'core/ui';

import Immutable, { toJS } from 'optly/immutable';
import { strLimiter } from 'optly/utils/str';
import Router from 'core/router';
import urls from 'optly/services/url_helper_legacy';

import ListDropdown from 'react_components/list_dropdown';

import EventModuleActions from 'optly/modules/entity/event/actions';
import EventModuleEnums from 'optly/modules/entity/event/enums';
import P13NUIActions from 'bundles/p13n/modules/ui/actions';
import View from 'optly/modules/entity/view';
import EventsExperimentUsageDialog from 'bundles/p13n/components/dialogs/events_experiment_usage';

const EXPERIMENTS_USAGE_INDEX = 1;
@feature('usage_inspector_events')
class PageEventTableRow extends React.Component {
  static propTypes = {
    canArchiveUserEvent: PropTypes.bool.isRequired,
    canArchiveView: PropTypes.bool.isRequired,
    currentProjectId: PropTypes.number.isRequired,
    configureView: PropTypes.func.isRequired,
    event: PropTypes.instanceOf(Immutable.Map).isRequired,
    layers: PropTypes.instanceOf(Immutable.Map).isRequired,
    pluginEventTypeToName: PropTypes.instanceOf(Immutable.Map).isRequired,
    view: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  isPageViewEventType = () => {
    const { event } = this.props;
    return event.get('event_type') === EventModuleEnums.eventTypes.PAGE_VIEW;
  };

  humanReadableEventType = () => {
    const { event, pluginEventTypeToName } = this.props;
    const eventType = event.get('event_type');
    const typeToNameMap = pluginEventTypeToName;
    const humanReadable = View.humanReadable.EVENT_TYPES[eventType];
    if (humanReadable) {
      return humanReadable;
    }
    return typeToNameMap[eventType];
  };

  archiveView = () => {
    const { view } = this.props;
    const viewName = view.get('name');

    P13NUIActions.confirmArchiveEntity({
      archiveAction: View.actions.archive.bind(View.actions),
      entityInstance: toJS(view),
      entityName: 'Page',
      instanceName: viewName,
    }).then(() => {
      ui.showNotification({
        message: tr('The page <b>{0}</b> has been archived.', htmlSanitizer.escape(viewName)),
      });
    });
  };

  archiveEvent = () => {
    const { event } = this.props;
    if (!this.isPageViewEventType() && event) {
      P13NUIActions.confirmArchiveEvent(event.toJS()).then(() => {
        ui.showNotification({
          message: tr('The event <b>{0}</b> has been archived.', htmlSanitizer.escape(event.name)),
        });
      });
    }
  };

  handleArchiveEventClick = () => {
    if (this.isPageViewEventType()) {
      this.archiveView();
    } else {
      this.archiveEvent();
    }
  };

  editEvent = () => {
    const { view, event, currentProjectId } = this.props;
    if (!this.isPageViewEventType()) {
      Router.go(
        urls.eventsEdit(currentProjectId, event.get('id'), view.get('id')),
      );
    }
  };

  handleEditEventClick = () => {
    const { configureView, view } = this.props;
    if (this.isPageViewEventType()) {
      configureView(view.get('id'));
    } else {
      this.editEvent();
    }
  };

  unarchiveEvent = () => {
    const { event, view } = this.props;
    if (!this.isPageViewEventType()) {
      if (view.archived) {
        ui.confirm({
          title: tr('Unarchive Event and Page'),
          message: tr(
            'To unarchive the event <b>{0}</b>, the page <b>{1}</b> must also be unarchived. Would you like to unarchive this event and page?',
            htmlSanitizer.escape(event.get('name')),
            htmlSanitizer.escape(view.get('name')),
          ),
          confirmText: tr('Unarchive Event and Page'),
        }).then(() => {
          const deferred = [];
          deferred.push(EventModuleActions.unarchive(event.toJS()));
          deferred.push(View.actions.unarchive(view.toJS()));
          Promise.all(deferred).then(() => {
            ui.showNotification({
              message: tr(
                'The event <b>{0}</b> and the page <b>{1}</b> have been unarchived.',
                htmlSanitizer.escape(event.get('name')),
                htmlSanitizer.escape(view.get('name')),
              ),
            });
          });
        });
      } else {
        EventModuleActions.unarchive(event.toJS()).then(() => {
          ui.showNotification({
            message: tr(
              'The event <b>{0}</b> has been unarchived.',
              htmlSanitizer.escape(event.get('name')),
            ),
          });
        });
      }
    }
  };

  unarchiveView = () => {
    const { view } = this.props;
    if (this.isPageViewEventType()) {
      View.actions.unarchive(view.toJS()).then(() => {
        ui.showNotification({
          message: tr(
            'The page <b>{0}</b> has been unarchived.',
            htmlSanitizer.escape(view.get('name')),
          ),
        });
      });
    }
  };

  handleUnarchiveEventClick = () => {
    if (this.isPageViewEventType()) {
      this.unarchiveView();
    } else {
      this.unarchiveEvent();
    }
  };

  shouldShowEditButton = () => {
    // Should only allow editing if the entity is not archived
    const { event, view } = this.props;
    const isViewArchived = view.get('archived');
    const isEventArchived = event.get('archived');
    if (this.isPageViewEventType()) {
      return !isViewArchived;
    }
    return !isEventArchived;
  };

  shouldShowArchiveButton = () => {
    const { event, view, canArchiveView, canArchiveUserEvent } = this.props;
    const isViewArchived = view.get('archived');
    const isEventArchived = event.get('archived');
    if (this.isPageViewEventType()) {
      return canArchiveView && !isViewArchived;
    }
    return canArchiveUserEvent && !isEventArchived;
  };

  shouldShowUnarchiveButton = () => {
    const { event, view, canArchiveView, canArchiveUserEvent } = this.props;
    const isViewArchived = view.get('archived');
    const isEventArchived = event.get('archived');
    if (this.isPageViewEventType()) {
      return canArchiveView && isViewArchived;
    }
    return canArchiveUserEvent && isEventArchived;
  };

  onExperimentUsageView = () => {
    const { event } = this.props;
    ui.showReactDialog(
      EventsExperimentUsageDialog,
      {
        props: {
          selectedEventId: event.get('id'),
        },
      },
      {
        isOuiDialog: true,
        fullScreen: false,
        dismissOnBack: true,
      },
    );
  };

  render() {
    const { event, view } = this.props;

    let eventName;
    let eventAPIName = event.get('api_name', null);
    let eventID = event.get('id', null);

    if (this.isPageViewEventType()) {
      eventAPIName = eventAPIName || view.get('api_name', null);
      eventID = eventID || view.get('id', null);
    }

    if (!this.shouldShowEditButton()) {
      // Disable editing if event is archived
      eventName = (
        <span
          className="link--disabled"
          data-test-section="page-event-edit-button">
          {event.get('name')}
        </span>
      );
    } else {
      eventName = (
        <a
          tabIndex={eventID}
          className="cursor--pointer"
          onClick={this.handleEditEventClick}
          data-test-section="page-event-edit-button">
          {event.get('name')}
        </a>
      );
    }

    const dropdownItems = [
      {
        text: tr('Settings'),
        testSection: 'events-dashboard-page-settings-button',
        onClick: this.handleEditEventClick,
        isVisible: this.shouldShowEditButton(),
      },
      {
        text: tr('Archive'),
        testSection: 'events-dashboard-page-archive-button',
        onClick: this.handleArchiveEventClick,
        isVisible: this.shouldShowArchiveButton(),
      },
      {
        text: tr('Unarchive'),
        testSection: 'events-dashboard-page-unarchive-button',
        onClick: this.handleUnarchiveEventClick,
        isVisible: this.shouldShowUnarchiveButton(),
      },
    ];

    if (this.usage_inspector_events) {
      dropdownItems.splice(EXPERIMENTS_USAGE_INDEX, 0, {
        text: tr('View Experiment Usage'),
        testSection: 'events-dashboard-page-experiments-button',
        onClick: this.onExperimentUsageView,
      });
    }

    return (
      <Table.TR testSection="page-event-table-row">
        <Table.TD>
          <div>
            <span data-test-section="page-event-name">{eventName}</span>
            <div
              className="muted micro"
              data-test-section="page-event-description">
              {strLimiter(event.get('description'), 300)}
            </div>
          </div>
        </Table.TD>
        <Table.TD>
          <span
            className="word-break--all"
            data-test-section="page-event-api-name">
            {eventAPIName || <span className="style--italic muted">None</span>}
          </span>
        </Table.TD>
        <OptimizelyFeature feature="usage_inspector_events">
          {isEnabled =>
            isEnabled && (
              <Table.TD isNumerical={true}>
                <span className="nowrap">
                  <span
                    className="muted"
                    data-test-section="page-event-experiment-count">
                    {event.get('experiment_count', 0)}
                  </span>
                </span>
              </Table.TD>
            )
          }
        </OptimizelyFeature>
        <Table.TD>
          <span className="nowrap" data-test-section="page-event-id">
            {eventID || <span className="style--italic muted">None</span>}
          </span>
        </Table.TD>
        <Table.TD>
          <span className="nowrap" data-test-section="page-event-type">
            {this.humanReadableEventType()}
          </span>
        </Table.TD>
        <Table.TD isNumerical={true}>
          <ListDropdown
            minWidth="200px"
            activator={
              <ButtonIcon
                iconFill="default"
                iconName="ellipsis-solid"
                size="small"
                style="plain"
                testSection="custom-event-dropdown-button"
              />
            }
            items={dropdownItems}
          />
        </Table.TD>
      </Table.TR>
    );
  }
}

export default PageEventTableRow;

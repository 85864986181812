/**
 * Enumerable field values for targeting_conditions
 */
export const audienceVersions = {
  OPTIMIZELY_X: 'v2',
  OPTIMIZELY_CLASSIC: 'v1',
};

export default {
  audienceVersions,
};

import { enums as LayerEnums } from 'optly/modules/entity/layer';
import { humanReadable as PublishStatusHumanReadable } from 'bundles/p13n/modules/publish_status';

export const STATUS_ACTIONS = {
  START: 'start',
  PAUSE: 'pause',
  ARCHIVE: 'archive',
  UNARCHIVE: 'unarchive',
  CONCLUDE: 'conclude',
};

const archivedHumanReadable = 'Unarchive';

const startAction = {
  id: STATUS_ACTIONS.START,
  label: PublishStatusHumanReadable.statusText.RUN,
};

const pauseAction = {
  id: STATUS_ACTIONS.PAUSE,
  label: PublishStatusHumanReadable.statusText.PAUSE,
};

const archiveAction = {
  id: STATUS_ACTIONS.ARCHIVE,
  label: PublishStatusHumanReadable.statusText.ARCHIVE,
};

const unarchiveAction = {
  id: STATUS_ACTIONS.UNARCHIVE,
  label: archivedHumanReadable,
};

const concludeAction = {
  id: STATUS_ACTIONS.CONCLUDE,
  label: PublishStatusHumanReadable.statusText.CONCLUDE,
};

export const statusActionsMap = {
  [LayerEnums.status.DRAFT]: {
    label: PublishStatusHumanReadable.statusText.NOT_STARTED,
    actions: [archiveAction],
  },
  [LayerEnums.status.PAUSED]: {
    label: PublishStatusHumanReadable.statusText.PAUSED,
    actions: [startAction, concludeAction, archiveAction],
  },
  [LayerEnums.status.RUNNING]: {
    label: PublishStatusHumanReadable.statusText.RUNNING,
    actions: [pauseAction, concludeAction, archiveAction],
  },
  [LayerEnums.status.ARCHIVED]: {
    label: PublishStatusHumanReadable.statusText.ARCHIVED,
    actions: [unarchiveAction],
  },
  [LayerEnums.status.CONCLUDED]: {
    label: PublishStatusHumanReadable.statusText.CONCLUDED,
    actions: [archiveAction],
  },
};

export default {
  STATUS_ACTIONS,
  statusActionsMap,
};

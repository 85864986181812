import _ from 'lodash';

/**
 * calculate cumulative size in bytes of key, data passed in
 * @param {String} key
 * @param {String} data
 */
export function calculateItemSizeInBytes(key, data) {
  if (_.isString(key) && _.isString(data)) {
    return (key.length + data.length) * 2;
  }
  return 0;
}
/**
 * Given a meta store entries Map, compute the list of meta store keys arranged in ascending order of dateModified values
 * @param {Object} metaStoreEntries
 * @returns {Array} sorted list of keys based on order of dateModified values
 */
export function computeLruKeyListFromMetaStoreEntries(metaStoreEntries) {
  // compile the lruKeyList from the metaStore entries
  const entityKeyModifiedMap = _.values(
    _.transform(metaStoreEntries, (res, entryInfo, entryKey) => {
      // entryInfo = { size: xxx, dateModified: xxx }
      res[entryKey] = { key: entryKey, dateModified: entryInfo.dateModified };
    }),
  );
  // get key list sorted by dateModified (ascending order)
  return _.map(_.sortBy(entityKeyModifiedMap, 'dateModified'), 'key');
}

export default {
  calculateItemSizeInBytes,
  computeLruKeyListFromMetaStoreEntries,
};

/**
 * Entity Definition for layer
 */
import fieldTypes from 'optly/modules/rest_api/field_types';

export default {
  entity: 'layers',

  parent: {
    entity: 'projects',
    key: 'project_id',
  },

  fieldTypes: {
    id: fieldTypes.NUMBER,
    project_id: fieldTypes.NUMBER,
    policy: fieldTypes.STRING,
    name: fieldTypes.STRING,
    description: fieldTypes.STRING,
    status: fieldTypes.STRING,
    last_modified: fieldTypes.DATE,
    created: fieldTypes.DATE,
  },
};

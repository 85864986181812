/**
 * Filter to format a date string into a human readable date.
 * Defaults to relative date formatting, like:
 * Yesterday, December 13, 2015
 *
 * Or, you can pass in a moment.js format, like 'LL', which would produce:
 * December 13, 2015
 *
 * @author Asa Schachar (asa@optimizely.com)
 */
import tr from 'optly/translate';

export default (dateString, dateStringFormat) => {
  // Use a relative date string format.
  if (!dateStringFormat) {
    const date = tr.date(dateString);
    const dateStr = tr.date(date).format('dddd, LL');
    const now = tr.date();

    if (date.isSame(now, 'day')) {
      return tr('Today, {0}', dateStr);
    }
    if (date.isSame(now.subtract(1, 'd'), 'day')) {
      return tr('Yesterday, {0}', dateStr);
    }
    return dateStr;
  }

  // Use the passed-in format.
  return tr.date(dateString).format(dateStringFormat);
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AudienceSearchPicker from 'bundles/p13n/components/audience_search_picker';
import ui from 'core/ui';
import { actions as P13NDashboardActions } from 'bundles/p13n/modules/dashboard';
import { actions as P13NUIActions } from 'bundles/p13n/modules/ui'; // eslint-disable-line import/no-cycle

class FlagsAudienceSearchPicker extends Component {
  static propTypes = {
    addAudience: PropTypes.func.isRequired,
    handleAddAudience: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
    selectedEntityIds: PropTypes.array.isRequired,
  };

  /**
   * For creating or viewing Audiences
   *
   * @param {Object} config
   * @param {Number} config.id
   *  If provided, the Audience for this ID will be fetched
   * @param {String} config.name
   *  If provided when options.id is falsy, the Audience builder will be loaded with this value as the name
   */
  handleCreateOrEditAudience = ({ id = null, name = '' }) => {
    const { handleAddAudience } = this.props;

    ui.loadingStart('main');
    P13NDashboardActions.fetchOrCreateAudience(id, name).then(audience => {
      const createEditDeferred = $.Deferred();
      if (!id) {
        createEditDeferred.then(changedAudience => {
          handleAddAudience(changedAudience.id, changedAudience.name);
        });
      }
      P13NUIActions.showAudienceEditorDialog(
        audience,
        createEditDeferred,
      ).finally(() => {
        ui.loadingStop('main');
      });
    });
  };

  render() {
    const { addAudience, projectId, selectedEntityIds } = this.props;
    return (
      <AudienceSearchPicker
        addAudience={addAudience}
        projectId={projectId}
        selectedEntityIds={selectedEntityIds}
        openEditAudienceDialog={audience => {
          this.handleCreateOrEditAudience({
            id: audience.id,
          });
        }}
        openCreateAudienceDialog={audienceName => {
          this.handleCreateOrEditAudience({
            name: audienceName,
          });
        }}
      />
    );
  }
}

export default FlagsAudienceSearchPicker;

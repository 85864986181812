/**
 * Popover directive
 * https://link.optimizely.com/lego#popover-39
 *
 * This is a directive, meaning it is attached via:
 * `<div v-popover>`
 *
 * The popover content _must_ be a sibling of the activator.
 *
 * Options can be passed via data attributes. Just append the option name to
 * `data-`, as in `data-container=""`.
 *
 * Scroll below to `DEFAULT_OPTIONS` to see the default values of each
 * attribute and descriptions of what each attribute does.
 *
 * Example Markup:
 *
 * <button v-popover
 *   data-behavior="show"
 *   data-container="#main-container"
 *   data-content-selector="#my-popover"
 *   data-dir="left-bottom"
 *   data-name="my-first-popover"
 *   data-scroll-listeners="#js-device-container">
 *     Toggle popover
 * </button>
 * <ul id="my-popover" class="lego-pop--over">
 *   <li class="lego-pop--over__content">
 *     <div class="lego-pop--over__title">Title</div>
 *     <p>Ipsa officiis bad-news minus earum a aperiam! Aperiam reiciendis vitae nihil libero et, hic!</p>
 *     <button data-popover-hide>I hide the popover when clicked</button>
 *   </li>
 * </ul>
 *
 * @author Daniel O'Connor (daniel@optimizely.com)
 */

import $ from 'jquery';

import _ from 'lodash';
import events from 'optly/services/events';
import poptipUtil from 'optly/utils/poptip';

const EVENT_PREFIX = 'popover:';

const ARROW_CLASS_TEMPLATE = 'lego-pop--over--arrow-';
const POPOVER_HIDE = '[data-popover-hide]';
const POPOVER_SHOWN_CLASS = 'lego-pop--over--shown';

// Available options and default values.
const DEFAULT_OPTIONS = {
  // The default popover behavior that occurs when clicking
  // on the activator. Options are: show | toggle
  behavior: 'toggle',
  // Append the popover to a specific element. Expects a jQuery selector
  // such as `#container`. If setting a custom value, be sure to set
  // `position: relative` on value of `container`. This is useful to set when
  // the popover is in a scrollable area or there are `z-index` issues.
  container: 'body',
  // A selector that corresponds to the popover content element.
  contentSelector: '.lego-pop--over',
  // How to position the popover. Possible values include: top-left |
  // top-center | top-right | right-top | right-center | right-bottom |
  // bottom-right | bottom-center | bottom-left | left-bottom |
  // left-center | left-top
  dir: 'top-center',
  // Give the popover a name. This is used for tracking open/close
  // events in analytics.
  name: null,
  // z-index to make sure popovers work on top of dialogs.
  zIndex: 3002,
  // Determine where to listen for scroll events to reposition the
  // popover. Expects a jQuery selector such as `#container`.
  scrollListeners: false,
};

const exported = {
  /**
   * Attach or remove scroll listener to user defined elements and
   * resize listener to `window`. The popover will reposition when
   * the events fire.
   */
  _attachOrRemovePositionListeners(shouldAttach) {
    const scrollSelector = this.config.scrollListeners;
    // Limit the number of times the events fire.
    const resizeThrottle = _.throttle(this._positionPopover.bind(this), 30);

    if (shouldAttach) {
      if (scrollSelector) {
        $(scrollSelector).on('scroll', resizeThrottle);
      }
      $(window).on('resize', resizeThrottle);
    } else {
      if (scrollSelector) {
        $(scrollSelector).off('scroll', resizeThrottle);
      }
      $(window).off('resize', resizeThrottle);
    }
  },

  /**
   * Set the initial CSS for the popover.
   */
  _createPopover() {
    this.$popoverContent.css({
      display: 'none',
      position: 'absolute',
      'z-index': this.config.zIndex,
    });
  },

  /**
   * Hide the popover and return it to the original
   * location.
   */
  _hide() {
    if (this.isOpen === true) {
      this._attachOrRemovePositionListeners(false);

      this.$popoverContent.css({
        display: 'none',
        left: 'auto',
        top: 'auto',
      });

      if (this.$popoverContainer) {
        this.$popoverContent.appendTo(this.$popoverActivator);
      }
      this.$el.removeClass(POPOVER_SHOWN_CLASS);
      this.isOpen = false;

      // Track the hide event
      if (this.config.name && events.getPageName()) {
        events.track(events.getPageName(), 'popover-hide', this.config.name);
      }

      // Emit a popover hidden event with the selector of the associated popover
      this.vm.$emit(`${EVENT_PREFIX}hidden`, this.config.contentSelector);
    }
  },

  /**
   * Hide the popover if a click was outside the popover.
   */
  _isClickOutside(event) {
    const clickedInActivator =
      this.$el.is(event.target) || this.$el.has(event.target).length > 0;
    const clickedInContent =
      this.$popoverContent.is(event.target) ||
      this.$popoverContent.has(event.target).length > 0;
    if (this.isOpen && !clickedInActivator && !clickedInContent) {
      this._hide();
    }
  },

  /**
   * Determine the left/top coordinates and move the popover.
   */
  _positionPopover() {
    const dir = this.config.dir;
    if (dir) {
      const dirClass = ARROW_CLASS_TEMPLATE + poptipUtil.getArrowDirection(dir);
      this.$popoverContent.addClass(dirClass);
    }
    const offset = poptipUtil.getPoptipOffset(
      this.$popoverActivator,
      this.$popoverContent,
      this.$popoverContainer,
      this.config.dir,
    );
    this.$popoverContent.css({
      left: offset.left,
      top: offset.top,
    });
  },

  /**
   * Display the popover in the correct location.
   */
  _show() {
    // Only run this if the popover is currently closed.
    if (this.isOpen === false) {
      // Run config set up again to make sure all values are up to date.
      this.config = this._setUpConfig();

      this.$popoverContent.css({
        display: 'block',
        opacity: '1',
      });

      this.$popoverContent.appendTo(this.$popoverContainer);

      this._attachOrRemovePositionListeners(true);
      this.$el.addClass(POPOVER_SHOWN_CLASS);
      this.isOpen = true;

      this._positionPopover();
      // Track the hide event
      if (this.config.name && events.getPageName()) {
        events.track(events.getPageName(), 'popover-show', this.config.name);
      }

      // Emit a popover shown event
      this.vm.$emit(`${EVENT_PREFIX}shown`, this.config.contentSelector);
    }
  },

  /**
   * Open or close the popover.
   */
  _toggle() {
    if (!this.isOpen) {
      this._show();
    } else {
      this._hide();
    }
  },

  /**
   * Set up config.
   */
  _setUpConfig() {
    // Default values are undefined since `getAttribute` returns an
    // empty string when not available.
    const config = _.defaults(
      {
        behavior: this.el.getAttribute('data-behavior') || undefined,
        container: this.el.getAttribute('data-container') || undefined,
        contentSelector:
          this.el.getAttribute('data-content-selector') || undefined,
        dir: this.el.getAttribute('data-dir') || undefined,
        name: this.el.getAttribute('data-name') || undefined,
        zIndex: this.el.getAttribute('data-z-index') || undefined,
        scrollListeners:
          this.el.getAttribute('data-scroll-listeners') || undefined,
      },
      DEFAULT_OPTIONS,
    );

    // Popover will be attached to the nearest instance of
    // `this.config.container`.
    this.$popoverContainer = this.$el.closest(config.container);

    // Merge the configuration values with defaults.
    return config;
  },

  /**
   * Set up the popover directive.
   */
  bind() {
    this.$el = $(this.el);
    this.$popoverActivator = this.$el;
    this.isOpen = false;
    this.config = this._setUpConfig();

    // Find the popover contents.
    this.$popoverContent = this.$el.siblings(this.config.contentSelector);

    // Add the proper classes to the popover.
    this._createPopover();

    // Popover content event listeners. This is used to close
    // the popover from within `this.$popoverContent`. The
    // `this.hideWithThis` variable is needed because you can't
    // remove an event listener when passing in a function with
    // `bind` to jQuery's `on function`.
    this.hideWithThis = this._hide.bind(this);
    this.$popoverContent.on('click', POPOVER_HIDE, this.hideWithThis);

    switch (this.config.behavior) {
      case 'show':
        this.$popoverActivator.on('click', this._show.bind(this));
        break;
      case 'toggle':
      default:
        // Default to toggle.
        this.$popoverActivator.on('click', this._toggle.bind(this));
    }

    $('body').on('click', this._isClickOutside.bind(this));
    // Needed because clicks in the editor are not propagated and caught
    // by the first listener.
    this.vm.$on(
      `${EVENT_PREFIX}isClickOutside`,
      this._isClickOutside.bind(this),
    );
    this.vm.$on(`${EVENT_PREFIX}hide`, this._hide.bind(this));
  },

  unbind() {
    this._hide();

    this.$popoverContent.off('click', POPOVER_HIDE, this.hideWithThis);
    $('body').off('click', this._isClickOutside.bind(this));
  },
};

export default exported;

export const {
  _attachOrRemovePositionListeners,
  _createPopover,
  _hide,
  _isClickOutside,
  _positionPopover,
  _show,
  _toggle,
  _setUpConfig,
  bind,
  unbind,
} = exported;

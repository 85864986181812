import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import Immutable, { toImmutable } from 'optly/immutable';
import ExperimentationGroupConstants from 'optly/modules/entity/experimentation_group/constants';
import { ExperimentStatusType } from 'optly/utils/enums';
import IntegrationEnums from 'optly/modules/entity/integration/enums';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerExperimentFns from 'optly/modules/entity/layer_experiment/fns';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
import ProjectFns from 'optly/modules/entity/project/fns';

/**
 * Module specific pure functions
 */
export function filterByProjectId(entityMap, projectId) {
  return entityMap
    .filter(entity => projectId && entity.get('project_id') === projectId)
    .toList();
}

/**
 * Returns filtered personalization integrations that account is entitled to for
 * @param {Immutable.List} accountPermissions
 * @param {Immutable.List} allIntegrations
 * @returns {Immutable.List}
 */
export function filterPersonalizationIntegrations(
  accountPermissions,
  allIntegrations,
) {
  return allIntegrations.filter(integration => {
    const isPersonalizationIntegration =
      integration
        .get('products')
        .indexOf(IntegrationEnums.products.PERSONALIZATION) !== -1;

    // Filter out beta integrations that user doesn't have access to
    const hasPermission = integration.get('beta')
      ? PermissionsModuleFns.canUseIntegration(
          accountPermissions,
          integration.get('permissionRequired'),
        )
      : true;

    return isPersonalizationIntegration && hasPermission;
  });
}

/**
 * Returns all classic experiments using the given integration in the current project.
 * @param {Immutable.List} experiments
 * @param {Immutable.Map} integration
 * @returns {Immutable.List}
 */
export const getClassicExperimentsUsingIntegration = (
  experiments,
  integration,
) =>
  experiments
    .filter(
      exp =>
        exp.get('enabled_integration_ids') &&
        exp.get('enabled_integration_ids').contains(integration.get('id')),
    )
    .toList()
    .map(exp =>
      toImmutable({
        id: Number(exp.get('id')),
        description: exp.get('description'),
      }),
    );

/**
 * Returns all X layers using the given integration in the current project
 * @param {Immutable.List} activeLayers
 * @param {Immutable.Map} integration
 * @returns {Immutable.List}
 */
export const getLayersUsingIntegration = (activeLayers, integration) => {
  let activeLayersUsingIntegration = toImmutable({});
  activeLayers.forEach(layer => {
    if (layer.get('integration_settings')) {
      layer.get('integration_settings').forEach(setting => {
        if (
          setting.get('integration_id') === integration.get('id') &&
          setting.get('enabled')
        ) {
          activeLayersUsingIntegration = activeLayersUsingIntegration.set(
            layer.get('id').toString(),
            layer.get('name'),
          );
        }
      });
    }
  });
  return activeLayersUsingIntegration
    .map((item, key) => toImmutable({ id: Number(key), name: item }))
    .toList();
};

/**
 * Returns the relative URL for the integration's logo file.
 * @param {Immutable.Map} integration
 * @returns {String}
 */
export const getLogoSrc = integration => {
  let logoFileName = '_placeholder.png';
  if (!!integration.get('id') && !!integration.get('logo_file_name')) {
    logoFileName = integration.get('logo_file_name');
  }
  // TODO: evaluate if there is a good alternate to this dynamic path require
  return require(`/static/img/integrations/${logoFileName}`); // eslint-disable-line
};

/**
 * Returns an Immutable list of the current project's layer experiments with a policy of SINGLE_EXPERIMENT
 * or MULTIVARIATE. Ensures the variables are set on their variation and also sets the current group ID or default ID
 * if the experiment is not in a group.
 *
 * NOTE: This getter allows for easier consumption of the Full Stack Experiment,
 * but is not a fully accurate representation of the layer experiment model.
 *
 * TODO(OASIS-2588) Make this an actual wrapper, and add the real layer_experiment to it alongside the computed data
 */
export const wrapFullStackLayerExperiments = (
  currentProjectLayerExperiments,
  entityToGroupMap,
) => {
  const filteredExperiments = currentProjectLayerExperiments.filter(
    experiment =>
      [
        LayerEnums.policy.SINGLE_EXPERIMENT,
        LayerEnums.policy.MULTIVARIATE,
      ].includes(experiment?.get('layer_policy')),
  );
  return filteredExperiments.map(experiment => {
    // to avoid reassigning param
    let thisExp = experiment;
    const experimentId = thisExp.get('id').toString();

    // The variation's variable values are added as a top level property on the experiment. (limitation on the API)
    // We must extract it and set it on the variations themselves.
    const variablesValues = thisExp.get('variable_values');
    if (variablesValues && variablesValues.size) {
      let variations = thisExp.get('variations');
      variations = variations.map(variation => {
        const variationVariableValues = variablesValues.get(
          variation.get('variation_id').toString(),
        );
        return variation.set('variable_values', variationVariableValues);
      });
      thisExp = thisExp.set('variations', variations);
    }

    // populate group_id field on experiments
    const groupId = entityToGroupMap.has(experimentId)
      ? entityToGroupMap.get(experimentId).get('id')
      : ExperimentationGroupConstants.NONE_GROUP_ID;
    return thisExp.set('group_id', groupId);
  });
};

/**
 * An Audience is considered available if all of the following are true:
 * 1. Audience is not archived OR an archived Audience is already added to the LayerExperiment
 * 2. Audience has been touched by user (i.e. not just created by automated migration)
 *
 * @param audienceConditions {Immutable.List}
 * @returns {Function}
 *  Returns a function that requires currentProjectAudiences and returns an Immutable List
 */
export const getAvailableAudiences = audienceConditions => {
  // Provided audienceConditions, this will return an Array of used Audience IDs
  const selectedAudienceIds = LayerExperimentFns.deriveAudienceIdsFromAudienceConditions(
    audienceConditions,
  );
  return currentProjectAudiences =>
    currentProjectAudiences.filter(
      audience =>
        (!audience.get('archived') ||
          (selectedAudienceIds || []).includes(audience.get('id'))) &&
        audience.get('user_touched'),
    );
};

/**
 * @name getEnabledAndSortedIntegrationsForProjectType
 * @description used in CurrentProjectGetters.integrations, primary filter, sort, and mapping function for all integration types
 *
 * @param {Immutable.Map} currentProject
 * @param {Immutable.Map} integrationEntities
 * @param {Immutable.Map} projectIntegrations
 * @param {Immutable.List} projectExperiments
 * @param {Immutable.List} projectLayers
 * @returns {Immutable.List}
 *  Sorted list of integrations that are enabled and have a matching project type (or delivery mode) via the integration's "channel" array
 */
export function getEnabledAndSortedIntegrationsForProjectType(
  currentProject,
  integrationEntities,
  projectIntegrations,
  projectExperiments,
  projectLayers,
) {
  if (!currentProject) {
    return toImmutable([]);
  }

  const isFlagsProject = ProjectFns.isFlagsProject(currentProject);
  const isFullStackProject = ProjectFns.isFullStackProject(currentProject);
  const enabledAndSortedIntegrationsForProjectType = integrationEntities
    .filter(integration => integration.get('enabled'))
    .filter(integration =>
      ProjectFns.filterIntegrationByProject(currentProject, integration),
    )
    .filter(integration => {
      switch (integration.get('id')) {
        case 'jira':
          return !isFlagsProject; // Jira is not supported in Flags project yet
        case 'experiment_collaboration':
          return isFlagsProject || !isFullStackProject; // Experiment Collaboration is not supported in Full Stack (Legacy) project yet
        case 'google_analytics_4_audience_targeting':
          return isFeatureEnabled('ga4_audience_targeting');
        default:
          return true;
      }
    })
    .toList()
    .sortBy(integration => integration.get('master_label').toLowerCase());

  // create a map to facilitate lookup of projectintegrations by integration ID
  const integrationToProjectIntegrationMap = {};
  projectIntegrations
    .filter(
      projectIntegration =>
        projectIntegration.get('project_id') === currentProject.get('id'),
    )
    .forEach(projectIntegration => {
      integrationToProjectIntegrationMap[
        projectIntegration.get('integration_id')
      ] = projectIntegration;
    });

  return enabledAndSortedIntegrationsForProjectType.map(integration => {
    const projectIntegration =
      integrationToProjectIntegrationMap[integration.get('id')];
    const onOffableAtExperimentLevel = integration.getIn([
      'settings_metadata',
      'onOffableAtExperimentLevel',
    ]);
    const onOffableAtLayerLevel = integration.getIn([
      'settings_metadata',
      'onOffableAtLayerLevel',
    ]);
    const onOffableAtProjectLevel = integration.getIn([
      'settings_metadata',
      'onOffableAtProjectLevel',
    ]);
    const integrationCategories = integration.get('categories');
    const hasAnalyticsOrHeatmapCategory =
      integrationCategories.contains('Analytics') ||
      integrationCategories.contains('Heatmap');

    // compute usage condition for project level integrations such as KISSmetrics
    const isProjectLevelIntegration =
      !onOffableAtExperimentLevel &&
      !onOffableAtLayerLevel &&
      onOffableAtProjectLevel &&
      hasAnalyticsOrHeatmapCategory;

    const usageCount = {
      experiments: 0,
      layers: 0,
    };

    const unarchivedExperiments = projectExperiments.filter(
      experiment => experiment.get('status') !== ExperimentStatusType.ARCHIVED,
    );
    const unarchivedLayers = projectLayers.filter(
      layer => !layer.get('archived'),
    );

    // count all `active` CLASSIC experiments of the current project that have this integration enabled
    // count all `active` X layers of the current project that have this integration enabled
    const unarchivedExperimentsUsingIntegration = getClassicExperimentsUsingIntegration(
      unarchivedExperiments,
      integration,
    );
    const unarchivedLayersUsingIntegration = getLayersUsingIntegration(
      unarchivedLayers,
      integration,
    );

    // If it's a project level integration, then the total active experiments or layers trumps all for usage count
    const experimentCountIfProjectLevel =
      integration.get('products') &&
      integration.get('products').contains(IntegrationEnums.products.AB_TESTING)
        ? unarchivedExperiments.size
        : 0;
    const layerCountIfProjectLevel =
      integration.get('products') &&
      integration
        .get('products')
        .contains(IntegrationEnums.products.PERSONALIZATION)
        ? unarchivedLayers.size
        : 0;
    usageCount.experiments = isProjectLevelIntegration
      ? experimentCountIfProjectLevel
      : unarchivedExperimentsUsingIntegration.size;
    usageCount.layers = isProjectLevelIntegration
      ? layerCountIfProjectLevel
      : unarchivedLayersUsingIntegration.size;

    return toImmutable({
      addonType: integration.get('addon_type'),
      beta: integration.get('beta'),
      developer: integration.get('developer'),
      developerWebsite: integration.get('developer_website'),
      labelAsBeta: !!integration.get('label_as_beta'),
      usesCanvas: !!integration.get('canvas_config'),
      categories: integration.get('categories'),
      products: integration.get('products'),
      categoriesLabels: integration.get('categories_labels'),
      experimentLevelData: {
        fields: integration
          .getIn(['settings_metadata', 'fields'], [])
          .filter(fieldConfig => {
            const saveLocations = fieldConfig.get('saveLocations');
            return saveLocations && saveLocations.indexOf('experiment') !== -1;
          }),
        generalHelp: integration.getIn([
          'settings_metadata',
          'generalHelp',
          'experiment',
        ]),
        onOffable: integration.getIn([
          'settings_metadata',
          'onOffableAtExperimentLevel',
        ]),
        settingsHelp: integration.getIn([
          'settings_metadata',
          'settingsHelp',
          'experiment',
        ]),
      },
      layerLevelData: {
        fields: integration
          .getIn(['settings_metadata', 'fields'], [])
          .filter(fieldConfig => {
            const saveLocations = fieldConfig.get('saveLocations');
            return saveLocations && saveLocations.indexOf('layer') !== -1;
          }),
        generalHelp: integration.getIn([
          'settings_metadata',
          'generalHelp',
          'layer',
        ]),
        onOffable: integration.getIn([
          'settings_metadata',
          'onOffableAtLayerLevel',
        ]),
        settingsHelp: integration.getIn([
          'settings_metadata',
          'settingsHelp',
          'layer',
        ]),
        dynamicLayerSettingsOptions: projectIntegration
          ? projectIntegration.get('dynamic_layer_settings_options')
          : null,
      },
      id: integration.get('id'),
      isProjectLevelIntegration,
      logoSrc: getLogoSrc(integration),
      masterLabel: integration.get('master_label'),
      partnerDirectoryUrl: integration.get('partner_dir_url'),
      permissionRequired: integration.get('permission_required'),
      projectLevelData: {
        conflicts: projectIntegration
          ? projectIntegration.get('conflicts')
          : {},
        description: integration.get('description'),
        dynamicSettingsOptions: projectIntegration
          ? projectIntegration.get('dynamic_settings_options')
          : null,
        enabled: projectIntegration ? projectIntegration.get('enabled') : false,
        fields: integration
          .getIn(['settings_metadata', 'fields'], Immutable.List())
          .filter(fieldConfig => {
            const saveLocations = fieldConfig.get('saveLocations');
            return saveLocations && saveLocations.indexOf('project') !== -1;
          }),
        generalHelp: integration.getIn([
          'settings_metadata',
          'generalHelp',
          'project',
        ]),
        oauthTokenManagementUrl: integration.getIn([
          'settings_metadata',
          'oauthTokenManagementUrl',
        ]),
        onOffable: integration.getIn([
          'settings_metadata',
          'onOffableAtProjectLevel',
        ]),
        requiredFieldsFilled: integration.get('requiredFieldsFilled'),
        settings: projectIntegration
          ? projectIntegration.get('settings')
          : null,
        settingsHelp: integration.getIn([
          'settings_metadata',
          'settingsHelp',
          'project',
        ]),
        settingsMetadata: projectIntegration
          ? projectIntegration.get('settings_metadata')
          : null,
        settingsValidationErrorRaised: integration.get(
          'settingsValidationErrorRaised',
        ),
        settingsValidationErrorMessage: integration.get(
          'settingsValidationErrorMessage',
        ),
        settingsText: integration.getIn(['settings_metadata', 'settingsText']),
        settingsLink: integration.getIn(['settings_metadata', 'settingsLink']),
        signInButtonImage: integration.getIn([
          'settings_metadata',
          'sign_in_button_image',
        ]),
        usageInClassic: unarchivedExperimentsUsingIntegration,
        usageInX: unarchivedLayersUsingIntegration,
        usageCount,
        usesOAuth: !!integration.getIn([
          'settings_metadata',
          'oauthAuthorizationUrl',
        ]),
      },
      subLabel: integration.get('sub_label'),
    });
  });
}

/**
 * @name getProjectTypeFriendly
 * @description returns a friendly representation of a project's type: One of ['web', 'edge', 'fullstack_legacy', 'fullstack_flags', 'other']
 *
 * @param {Immutable.Map} currentProject
 * @returns {String}
 *
 */
export const getProjectTypeFriendly = currentProject => {
  const isEdgeProject = ProjectFns.isWebEdgeProject(currentProject);
  const isWebProject = ProjectFns.isWebProject(currentProject);
  const isFullStackProject = ProjectFns.isFullStackProject(currentProject);
  const isFlagsProject = ProjectFns.isFlagsProject(currentProject);

  if (isWebProject && isEdgeProject) {
    return 'edge';
  }
  if (isWebProject && !isEdgeProject) {
    return 'web';
  }
  if (isFullStackProject && isFlagsProject) {
    return 'fullstack_flags';
  }
  if (isFullStackProject && !isFlagsProject) {
    return 'fullstack_legacy';
  }
  return 'other';
};

export default {
  filterByProjectId,
  filterPersonalizationIntegrations,
  getClassicExperimentsUsingIntegration,
  getEnabledAndSortedIntegrationsForProjectType,
  getLayersUsingIntegration,
  getLogoSrc,
  wrapFullStackLayerExperiments,
  getAvailableAudiences,
  getProjectTypeFriendly,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'optimizely-oui';

import userFriendlyNameToKey from 'optly/utils/userFriendlyNameToKey';
import { fieldPropType } from 'react_components/form';
import { constants as FeatureConstants } from 'optly/modules/entity/feature_flag';

/**
 * VariableKeyCell
 * @description Renders a table-cell that contains a variable-key input for the features variables table
 * @kind component
 * @example
 *  <VariableKeyCell
 *     variableField={form.repeatedField('variables').field('0')}
 *     readOnly={false}
 *  />
 */
const VariableKeyCell = ({
  isDisabled,
  variableField,
  readOnly,
  showLabel,
  testSection,
}) => {
  const apiNameField = variableField.field('api_name');

  const updateApiName = e => {
    const updatedVal = userFriendlyNameToKey(e.target.value);
    apiNameField.setValue(updatedVal);
  };

  return (
    <div>
      {showLabel && <h6 className="muted push-half--bottom">Variable Key</h6>}
      {!readOnly ? (
        <Input
          type="text"
          isDisabled={isDisabled}
          isRequired={true}
          displayError={apiNameField.getErrors().hasError}
          note={apiNameField.getErrors().details.isRequired}
          value={apiNameField.getValue()}
          onChange={updateApiName}
          placeholder="Enter a unique key"
          maxLength={FeatureConstants.MAX_API_NAME_LENGTH}
          testSection={`${testSection}-input`}
        />
      ) : (
        <div className="flex">
          <span
            className="truncate"
            data-test-section={`${testSection}-read-only`}>
            {apiNameField.getValue()}
          </span>
        </div>
      )}
    </div>
  );
};

VariableKeyCell.defaultProps = {
  isDisabled: false,
  readOnly: false,
  showLabel: false,
  testSection: 'variables-key-cell',
};

VariableKeyCell.propTypes = {
  isDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  showLabel: PropTypes.bool,
  testSection: PropTypes.string,
  variableField: fieldPropType.isRequired,
};

export default VariableKeyCell;

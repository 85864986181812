import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Container, Col, Input, Row } from 'optimizely-oui';

import regexUtils from 'optly/utils/regex';

import EmptyFeatureFlagsSVG from '/static/img/p13n/empty-feature-flags.svg';

const validationError =
  'Please enter a valid key. Valid keys contain alphanumeric characters, hyphens, and underscores, and are limited to 64 characters.';

const FeaturesEmptyState = ({
  archivedItemsCtaIfAvailableComponent,
  canManageFeatureFlags,
  isLoading,
  onCreateNewFeature,
}) => {
  const [apiName, setApiName] = useState('');
  const [hasValidationError, setValidationError] = useState(false);
  const handleCallback = useCallback(onCreateNewFeature, [onCreateNewFeature]);

  const handleApiNameChange = e => {
    const text = e.target.value;
    const invalidApiName = !regexUtils.apiName.test(text);

    setApiName(text);
    setValidationError(invalidApiName);
  };

  const handleCreate = () => {
    if (apiName === '') {
      setValidationError(true);
      return;
    }
    handleCallback(apiName);
  };

  return (
    <div className="flex--dead-center hard--top">
      <Container pushRowsTop={false} paddedContent="around" fluid={true}>
        <Row>
          <Col isReadingColumn={true}>
            <Row>
              <Col small={4}>
                <img alt="cell phone" src={EmptyFeatureFlagsSVG} />
              </Col>
              <Col small={8}>
                <Row removeGutters={false} paddedContent="sides">
                  <div className="push-double--bottom">
                    <h2>Create your first feature flag</h2>
                    <p>
                      Wrap a feature, like a chatbot or payment option, in a
                      feature flag to control its availability with an on/off
                      switch.
                    </p>
                    <div>
                      <Input
                        displayError={hasValidationError}
                        isDisabled={isLoading}
                        label="Give your feature a name:"
                        note={hasValidationError ? validationError : ''}
                        onChange={handleApiNameChange}
                        onKeyDown={e =>
                          // Submit on return key
                          e.keyCode === 13 ? handleCreate() : null
                        }
                        placeholder=""
                        testSection="feature-empty-state-input"
                        type="text"
                        value={apiName}
                      />
                    </div>
                  </div>
                  <Button
                    isDisabled={canManageFeatureFlags && hasValidationError}
                    isLoading={isLoading}
                    loadingText="Creating Feature"
                    onClick={handleCreate}
                    testSection="feature-empty-state-submit"
                    style="highlight"
                    width="default">
                    Create New Feature
                  </Button>
                  {archivedItemsCtaIfAvailableComponent && (
                    <div className="push--left">
                      {archivedItemsCtaIfAvailableComponent}
                    </div>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

FeaturesEmptyState.propTypes = {
  archivedItemsCtaIfAvailableComponent: PropTypes.node,
  canManageFeatureFlags: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCreateNewFeature: PropTypes.func.isRequired,
};

FeaturesEmptyState.defaultProps = {
  archivedItemsCtaIfAvailableComponent: null,
};

FeaturesEmptyState.componentId = 'FeaturesEmptyState';

export default FeaturesEmptyState;

import flux from 'core/flux';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';

import constants from './constants';

/**
 * ==================================================================================================================
 * <START FEATURE VARIABLE VALUES>
 * ==================================================================================================================
 */

// Global upgrade link
export const getUpgradeLink = () => {
  const isFreeRolloutsPlan = flux.evaluate(
    AdminAccountGetters.isFreeRolloutsPlan,
  );
  return isFreeRolloutsPlan
    ? constants.UpgradeVariables.PP2020_UPGRADE_LINK
    : constants.UpgradeVariables.UPGRADE_LINK;
};

export const getGlobalNavUpgradeButtonText = () =>
  constants.UpgradeVariables.GLOBAL_NAV_BUTTON_TEXT;

export const getGlobalNavUpgradeHeadingText = () =>
  constants.UpgradeVariables.GLOBAL_NAV_SUBHEADING_TEXT;

export const getUpgradeDialogButtonText = () =>
  constants.UpgradeVariables.UPSELL_DIALOG_BUTTON_TEXT;
/**
 * ==================================================================================================================
 * <END FEATURE VARIABLE VALUES>
 * ==================================================================================================================
 */

/**
 * Gets the upgrade dialog copy.
 * @param {String} featureLimitType - is it change history? targeted rollouts rollout rules?
 * @param {Number|String} featureLimit - the feature limit. Sometimes required to be rendered inline in a message.
 * @param {Boolean} isFreeRolloutsPlan - whether the user is on a plan whose id is "free_rollouts"
 * @returns {Object} with properties buttonLink, buttonText, message, header
 */
export const getUpgradeDialogCopy = (
  featureLimitType,
  featureLimit = null,
  isFreeRolloutsPlan,
) => {
  let message;
  const header = isFreeRolloutsPlan
    ? constants.UpgradeDialogHeaders.UPGRADE_TO_ESSENTIALS
    : constants.UpgradeDialogHeaders.GENERIC_UPGRADE;
  const buttonLink = getUpgradeLink(isFreeRolloutsPlan);
  const buttonText = getUpgradeDialogButtonText();
  if (featureLimitType === constants.FeatureLimitTypes.CHANGE_HISTORY_DAYS) {
    message = constants.UpgradeDialogMessages(featureLimit)[
      constants.FeatureLimitTypes.CHANGE_HISTORY_DAYS
    ];
  }
  if (
    featureLimitType === constants.FeatureLimitTypes.RUNNING_EXPERIMENT_LIMIT
  ) {
    message = constants.UpgradeDialogMessages(featureLimit)[
      constants.FeatureLimitTypes.RUNNING_EXPERIMENT_LIMIT
    ];
  }
  if (featureLimitType === constants.FeatureLimitTypes.NUMBER_OF_SEATS_LIMIT) {
    message = constants.UpgradeDialogMessages(featureLimit)[
      constants.FeatureLimitTypes.NUMBER_OF_SEATS_LIMIT
    ];
  }
  return {
    buttonLink,
    buttonText,
    message,
    header,
  };
};

export default {
  getUpgradeDialogCopy,
  getUpgradeLink,
  getGlobalNavUpgradeButtonText,
  getGlobalNavUpgradeHeadingText,
};

const flux = require('core/flux');

const actionTypes = require('./action_types');

/**
 * Shows/hides the custom code panel in the editor
 * @param {boolean} shown Whether or not the panel should be shown.
 * @param {string} tab describes type of custom code panel (css or javascript).
 */
exports.showCustomCodePanel = function(shown) {
  flux.dispatch(actionTypes.CUSTOM_CODE_PANEL_VISIBILITY_SET, {
    shown,
  });
};

/**
 * Selects visible panel in custom code (css/js)
 * @param {boolean} shown Whether or not the panel should be shown.
 * @param {string} tab describes type of custom code panel (css or javascript).
 */
exports.setCustomCodePanelTab = function(tab) {
  flux.dispatch(actionTypes.CUSTOM_CODE_PANEL_TAB_SET, {
    tab,
  });
};

/**
 * Sets the dirty state for a custom code tab.
 * @param {LayerExperiment.enums.CustomCodeTypes} tab The tab that is dirty.
 * @param {Boolean} isDirty The state to set for the tab.
 */
exports.setCustomCodeTabDirty = function(tab, isDirty) {
  flux.dispatch(actionTypes.CUSTOM_CODE_TAB_DIRTY_SET, {
    tab,
    isDirty,
  });
};

/**
 * Resets the dirty states to clean for the custom code tabs.
 */
exports.resetCustomCodeTabsDirty = function() {
  flux.dispatch(actionTypes.CUSTOM_CODE_TABS_DIRTY_RESET);
};

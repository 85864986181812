import PropTypes from 'prop-types';
import React from 'react';
import { OverlayWrapper, Popover, Icon } from 'optimizely-oui';

const Fieldset = props => (
  <fieldset data-test-section={props.testSection}>
    {props.title && (
      <div className="gamma push--bottom">
        {props.title}
        {props.isOptional && (
          <span className="lego-label__optional">(Optional)</span>
        )}
        {props.helpIcon && (
          <OverlayWrapper
            behavior="click"
            horizontalAttachment="left"
            horizontalTargetAttachment="right"
            overlay={
              <Popover title={props.popoverTitle}>
                <p>{props.popoverText}</p>
              </Popover>
            }
            shouldHideOnClick={true}
            verticalAttachment="middle"
            verticalTargetAttachment="middle">
            <div className="push-half--left">
              <Icon
                name="circle-question"
                size="small"
                className="oui-icon cursor--pointer push-half--left"
              />
            </div>
          </OverlayWrapper>
        )}
      </div>
    )}

    {props.description && (
      <div className="push--bottom">{props.description}</div>
    )}

    {props.children}
  </fieldset>
);

Fieldset.propTypes = {
  title: PropTypes.string,
  helpIcon: PropTypes.bool,
  popoverTitle: PropTypes.string,
  popoverText: PropTypes.string,
  isOptional: PropTypes.bool,
  description: PropTypes.node,
  children: PropTypes.node.isRequired,
  testSection: PropTypes.string,
};

export default Fieldset;

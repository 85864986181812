import _ from 'lodash';

import AccountGetters from 'optly/modules/entity/account/getters';

import { toImmutable } from 'optly/immutable';
import OAuthClientGetters from 'optly/modules/entity/oauth_client/getters';
import OAuthBearerTokenGetters from 'optly/modules/entity/oauth_bearer_token/getters';
import OAuthAuthorizedClientGetters from 'optly/modules/entity/oauth_authorized_client/getters';
import ProjectEnums from 'optly/modules/entity/project/enums';
import BillingInfoEnums from 'optly/modules/entity/billing_info/enums';
import ProjectFns from 'optly/modules/entity/project/fns';
import ProjectGetters from 'optly/modules/entity/project/getters';
import { generateImmutableObjectSortFn } from 'optly/utils/sort';

import constants from './constants';

/**
 * Gets the account id
 * @return {number}
 */
const id = ['accountInfo', 'accountId'];

/**
 * Checks whether account has a plan that includes SSO in its features
 * @return {boolean}
 */
const allowsSSO = ['accountInfo', 'allowsSso'];

/**
 * Gets the account email
 * @return {number}
 */
const email = ['accountInfo', 'email'];

/**
 * Gets the account unique_user_id
 * @return {number}
 */
const uniqueUserId = ['accountInfo', 'uniqueUserId'];

/**
 * Returns boolean whether the user is signed in
 * @return {boolean}
 */
const isSignedIn = ['accountInfo', 'isSignedIn'];

/**
 * Returns boolean whether the user is an admin (in AppEngine)
 * @return {boolean}
 */
const isAdmin = ['accountInfo', 'isAdmin'];

/**
 * Is the account frozen (over its limit)
 * @return {boolean}
 */
const isFrozen = ['accountInfo', 'frozen'];

/**
 * Is the account freezable
 * @return {boolean}
 */
const isFreezable = ['accountInfo', 'isFreezable'];

/**
 * Returns boolean whether the user is an admin
 * @return {boolean}
 */
const isUserAdmin = ['accountInfo', 'isUserAdmin'];

/**
 * Returns an array of user accounts (for multiple accounts feature)
 */
const userAccounts = ['accountInfo', 'userAccounts'];

/**
 * Returns an array of product IDs)
 */
const productIds = ['accountInfo', 'productIds'];

/**
 * Sort user accounts alphabetically by account-name
 * @returns {Immutable.List} sorted list of user accounts
 */
const sortedUserAccounts = [
  userAccounts,
  accounts => {
    // Return null `accounts` values, b/c some components might
    // expect `accounts` to be null
    if (!accounts) {
      return accounts;
    }
    return accounts.sort(
      generateImmutableObjectSortFn({
        field: 'account_name',
        type: 'string',
        dir: 'asc',
      }),
    );
  },
];

/**
 * Returns a complete copy of all account info.
 *
 * Be careful when using this method as it unnecessarily clones the whole thing
 * for dereferencing
 * @return {object}
 */
const accountInfo = ['accountInfo'];

/**
 * Get the maximum number of projects allowed for this plan level
 * @return {number}
 */
const maxProjects = ['accountInfo', 'maxProjects'];

/**
 * Get current user locale
 * @return {string}
 */
const userLocale = ['accountInfo', 'userLocale'];

/**
 * Get current user profile image
 * @return {string}
 */
const userProfileImage = ['accountInfo', 'userProfileImage'];

/**
 * Gets the backend API token for a account
 */
const backendApiToken = ['accountInfo', 'backendApiToken'];

/**
 * Returns a list of permissions consisting of account and user permissions for the current account / user.
 * Used for permission checks in Account Dashboard where account-level context info is guaranteed to exist.
 *
 * @return {array}
 */
const accountPermissions = [
  ['accountInfo', 'permissions'],
  permissions => {
    try {
      return toImmutable(
        _.union(permissions.accountPermissions, permissions.userPermissions),
      ).toList();
    } catch (e) {
      return toImmutable([]);
    }
  },
];

/**
 * Returns a dict of entitlement feature configs, based on how they're configured in the Optimizely Entitlements project
 */
const accountFeatureConfigs = ['accountInfo', 'featureConfigs'];

/**
 * Get the current user account
 */
const currentAccount = [
  userAccounts,
  function(accounts) {
    if (!accounts) {
      return null;
    }
    return accounts.filter(account => account.get('current_account')).first();
  },
];

/**
 * Get the role name of the current account
 */
const currentRole = [
  currentAccount,
  function(account) {
    if (!account) {
      return null;
    }
    return account.get('role');
  },
];

/**
 * Gets the admin_account from the REST API Account Store.
 */
const account = [
  id,
  AccountGetters.entityCache,
  function(accountId, accountStore) {
    const allAccounts = accountStore.filter(
      account => account.get('id') === accountId,
    );
    if (allAccounts.size > 1) {
      console.warn('Multiple admin accounts present.'); // eslint-disable-line no-console
    } else if (allAccounts.size === 0) {
      console.warn('No admin account present.'); // eslint-disable-line no-console
    }
    return allAccounts.first();
  },
];

/**
 * Returns boolean whether the user is using account-based origins
 * @return {boolean}
 */
const useAccountOrigins = [
  account,
  account => account.get('use_account_origins'),
];

/**
 * Checks for account's IDP ID
 * @return {string}
 */
const accountIDP = [
  account,
  function(account) {
    if (!account) {
      return null;
    }
    return account.get('idp_id');
  },
];

/**
 * Checks if account requires 2-step verification
 * @return {string}
 */
const require2StepVerification = [
  account,
  function(account) {
    if (!account) {
      return null;
    }
    return account.get('require_two_factor_auth');
  },
];

const ip_anonymization_default = [
  account,
  function(account) {
    if (!account) {
      return null;
    }
    return account.get('ip_anonymization_default');
  },
];

const ip_anonymization_locked = [
  account,
  function(account) {
    if (!account) {
      return null;
    }
    return account.get('ip_anonymization_locked');
  },
];

/**
 * Checks whether or not PCI is enabled on the account level.
 * @return {Boolean}
 */
const isPciEnabled = [
  account,
  account => {
    if (!account) {
      return null;
    }
    return account.get('pci_enabled');
  },
];

/**
 * Checks if the current account has Optimizely Classic in Read Only Mode or not
 * @return {number}
 */
const isWebClassicReadOnly = [
  account,
  function(account) {
    if (!account) {
      return null;
    }

    // TODO(zach)[WEB-2984]: replace 'is_classic_read_only' with 'is_web_classic_read_only' once classic sunset is complete
    return (
      !!account.get('is_classic_read_only') ||
      !!account.get('is_web_classic_read_only')
    );
  },
];

/**
 * Checks is the current account is SSO enabled or not
 * @return {number}
 */
const isSsoEnabled = [
  account,
  function(account) {
    if (!account) {
      return null;
    }
    return account.get('is_sso_enabled');
  },
];

/**
 * Checks is the current account is SSO enabled and if the user is an admin or not
 * @return {number}
 */
const isAdminOnSsoAccount = [
  isSsoEnabled,
  ['accountInfo', 'isUserAdmin'],
  (ssoEnabled, userAdmin) => ssoEnabled && userAdmin,
];

/**
 * Get the plan ID from window.optlyConfig.account_info
 */
const planId = ['accountInfo', 'planId'];

/**
 * Get the Turnstile Instance ID from window.optlyConfig.account_info
 */
const turnstileInstanceId = ['accountInfo', 'turnstileInstanceId'];

/**
 * Get the user's account level Admin Center Role ID from window.optlyConfig.account_info
 */
const accountAdminCenterRoleId = ['accountInfo', 'accountAdminCenterRoleId'];

/**
 * Gets the account's user management boolean from window.optlyConfig.account_info
 */
const enableOptiIdUserManagement = [
  'accountInfo',
  'enableOptiIdUserManagement',
];

const isInTrial = [
  account,
  function(account) {
    if (!account) {
      return false;
    }
    return account.get('in_trial', false);
  },
];

/**
 * Returns true if the user is on a free Rollouts plan
 */
const isFreeRolloutsPlan = [
  planId,
  planId => planId === BillingInfoEnums.planIds.FREE_ROLLOUTS,
];

/**
 * Gets all projects for your account
 */
const projects = [
  id,
  ProjectGetters.entityCache,
  (accountId, projects) =>
    projects
      .filter(project => project.get('account_id') === accountId)
      .toList(),
];

const activeProjectsAsMap = [
  id,
  ProjectGetters.entityCache,
  (accountId, projects) =>
    projects
      .filter(
        project =>
          project.get('account_id') === accountId &&
          project.get('project_status') === ProjectEnums.project_status.ACTIVE,
      )
      .map(project =>
        project.set(
          'primarySDKLanguage',
          ProjectFns.getPrimarySDKLanguage(project),
        ),
      )
      .sortBy(project => {
        const projectName = project.get('project_name') || '';
        return projectName.toLocaleLowerCase();
      }),
];

/**
 * Get all projects which are active.
 */
const activeProjects = [activeProjectsAsMap, projects => projects.toList()];

/**
 * Get all active web projects
 */
const activeWebProjects = [
  activeProjects,
  activeProjectsList =>
    activeProjectsList.filter(project =>
      project.get('project_platforms').includes('web'),
    ),
];

/**
 * Get all active web projects except edge projects
 */
const activeWebProjectsMinusEdge = [
  activeWebProjects,
  activeWebProjectsList =>
    activeWebProjectsList.filter(
      project =>
        !project.get('delivery_mode') ||
        project.get('delivery_mode') !== ProjectEnums.delivery_modes.EDGE,
    ),
];

/**
 * Get all active projects based on Optimizely version
 */
const activeProjectsByVersion = function(version) {
  version = version || ProjectEnums.manifestVersions.V1;
  return [
    activeProjects,
    function(activeProjectsList) {
      if (version === ProjectEnums.manifestVersions.V1) {
        return activeProjectsList.filter(project => {
          const projectPlatforms = project.get('project_platforms');
          return (
            projectPlatforms.includes(ProjectEnums.project_platforms.WEB) ||
            projectPlatforms.includes(ProjectEnums.project_platforms.IOS) ||
            projectPlatforms.includes(ProjectEnums.project_platforms.ANDROID) ||
            (projectPlatforms.includes(ProjectEnums.project_platforms.CUSTOM) &&
              project.get('manifest_version') !==
                ProjectEnums.manifestVersions.V2)
          );
        });
      }
      if (version === ProjectEnums.manifestVersions.V2) {
        return activeProjectsList
          .filter(project => {
            const projectPlatforms = project.get('project_platforms');
            return (
              projectPlatforms.includes(ProjectEnums.project_platforms.WEB) ||
              (projectPlatforms.includes(
                ProjectEnums.project_platforms.CUSTOM,
              ) &&
                project.get('manifest_version') ===
                  ProjectEnums.manifestVersions.V2)
            );
          })
          .map(project =>
            project.set(
              'primarySDKLanguage',
              ProjectFns.getPrimarySDKLanguage(project),
            ),
          );
      }
    },
  ];
};

const atProjectLimit = [
  activeProjects,
  maxProjects,
  (activeProjectsList, max) => activeProjectsList.size >= max,
];

// OAuth bearer tokens are usable if they have not been revoked and either have not expired or have a refresh token.
const usableOAuthBearerTokens = [
  id,
  email,
  OAuthBearerTokenGetters.entityCache,
  (accountId, userId, oauthBearerTokens) =>
    oauthBearerTokens
      .filter(
        oauthBearerToken =>
          oauthBearerToken.get('account_id') === accountId &&
          oauthBearerToken.get('user_id') === userId &&
          !oauthBearerToken.get('revoked_by') &&
          (oauthBearerToken.get('is_active') ||
            oauthBearerToken.get('has_refresh_token')),
      )
      .toList(),
];

const authorizedOAuthClients = [
  usableOAuthBearerTokens,
  OAuthAuthorizedClientGetters.clientIdToClientMap,
  function(usableBearerTokens, clientIdToClientMap) {
    const clientIdToTokenMap = {};
    // Map client_id to token keeping the token with the latest creation time
    usableBearerTokens.forEach(usableBearerToken => {
      const clientId = usableBearerToken.get('client_id');
      if (clientIdToTokenMap[clientId]) {
        if (usableBearerToken.get('created') > clientIdToTokenMap[clientId]) {
          clientIdToTokenMap[clientId] = usableBearerToken;
        }
      } else {
        clientIdToTokenMap[clientId] = usableBearerToken;
      }
    });

    return toImmutable(
      _.values(clientIdToTokenMap).map(token => ({
        clientId: token.get('client_id'),
        masterLabel: clientIdToClientMap.getIn([
          Number(token.get('client_id')),
          'master_label',
        ]),
        authorizationDate: token.get('created'),
      })),
    );
  },
];

/**
 * Gets all oauth clients for your account
 */
const oauthClients = [
  id,
  OAuthClientGetters.entityCache,
  (accountId, oauthClients) =>
    oauthClients
      .filter(oauthClient => oauthClient.get('account_id') === accountId)
      .toList(),
];

/**
 * Defines the type of user (guest, enterprise, self serve or self served who reached the project limit) based on signed in, plan id and project limit
 */
const userType = [
  isSignedIn,
  planId,
  atProjectLimit,
  function(isSignedIn, planId, atProjectLimit) {
    if (isSignedIn === false) {
      return constants.USER_TYPE.guest;
    }
    if (
      planId &&
      (planId.indexOf(constants.ENTERPRISE_SUBSTRING) >= 0 ||
        planId.substr(0, 1) === constants.ENTERPRISE_C_SUBSTRING)
    ) {
      /** Checking for the string enterprise in the plan or if it starts with 'c'.
       * I know it sounds weird but apparently this is the way even the BE is doing it
       */
      return constants.USER_TYPE.enterprise;
    }
    if (atProjectLimit === false) {
      return constants.USER_TYPE.selfServe;
    }
    return constants.USER_TYPE.saturatedSelfServe;
  },
];

const accountName = [
  account,
  account => {
    if (!account) {
      return '';
    }
    return account.get('account_name');
  },
];

const companyName = [
  account,
  account => {
    if (!account) {
      return '';
    }
    return account.get('company_name');
  },
];

const hasPP2020RolloutsPlusSubscription = [
  productIds,
  productIds => {
    return productIds && productIds.includes(constants.PP2020_FS_PLUS_ROLLOUT);
  },
];

/**
 * Getters for account
 */
export {
  id,
  email,
  uniqueUserId,
  isSignedIn,
  isAdmin,
  isFrozen,
  isFreezable,
  userAccounts,
  sortedUserAccounts,
  accountInfo,
  maxProjects,
  userLocale,
  userProfileImage,
  backendApiToken,
  accountPermissions,
  accountFeatureConfigs,
  currentAccount,
  currentRole,
  projects,
  activeProjectsAsMap,
  activeProjects,
  activeWebProjects,
  activeWebProjectsMinusEdge,
  activeProjectsByVersion,
  atProjectLimit,
  usableOAuthBearerTokens,
  authorizedOAuthClients,
  oauthClients,
  allowsSSO,
  account,
  require2StepVerification,
  ip_anonymization_default,
  ip_anonymization_locked,
  planId,
  isInTrial,
  isFreeRolloutsPlan,
  accountIDP,
  isAdminOnSsoAccount,
  isWebClassicReadOnly,
  isPciEnabled,
  isSsoEnabled,
  isUserAdmin,
  useAccountOrigins,
  userType,
  accountName,
  companyName,
  hasPP2020RolloutsPlusSubscription,
  turnstileInstanceId,
  accountAdminCenterRoleId,
  enableOptiIdUserManagement,
};

export default {
  id,
  email,
  uniqueUserId,
  isSignedIn,
  isAdmin,
  isFrozen,
  isFreezable,
  userAccounts,
  sortedUserAccounts,
  accountInfo,
  maxProjects,
  userLocale,
  userProfileImage,
  backendApiToken,
  accountPermissions,
  accountFeatureConfigs,
  currentAccount,
  currentRole,
  projects,
  activeProjects,
  activeProjectsAsMap,
  activeWebProjects,
  activeWebProjectsMinusEdge,
  activeProjectsByVersion,
  atProjectLimit,
  usableOAuthBearerTokens,
  authorizedOAuthClients,
  oauthClients,
  allowsSSO,
  account,
  require2StepVerification,
  ip_anonymization_default,
  ip_anonymization_locked,
  planId,
  isInTrial,
  isFreeRolloutsPlan,
  accountIDP,
  isAdminOnSsoAccount,
  isWebClassicReadOnly,
  isPciEnabled,
  isSsoEnabled,
  isUserAdmin,
  useAccountOrigins,
  userType,
  accountName,
  companyName,
  hasPP2020RolloutsPlusSubscription,
  turnstileInstanceId,
  accountAdminCenterRoleId,
  enableOptiIdUserManagement,
};

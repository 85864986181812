import { toImmutable } from 'optly/immutable';
import RestApi from 'optly/modules/rest_api';
import { getFeatureVariable } from '@optimizely/js-sdk-lab/src/actions';
import { enums as ChampagneEnums } from 'optly/modules/optimizely_champagne';

import definition from './entity_definition';
import fns from './fns';

const baseEntityActions = RestApi.createEntityActions(definition);

export const {
  fetch,
  fetchAll,
  fetchAllPages,
  fetchPage,
  flush,
} = baseEntityActions;

const deleteFeatureFlag = baseEntityActions.delete;
export { deleteFeatureFlag as delete };

let actions;

/**
 * Saves a feature flag
 * @param {featureFlag} featureFlag
 * @return {Deferred}
 */
export function save(featureFlag) {
  let feature = featureFlag;
  // TODO(APPX-34) Move to entity_definition.serialize for "audience_conditions" when all FeatureFlag Audiences builders can use rich JSON
  feature = fns.cleanAudiencesJson(feature);
  return baseEntityActions.save(feature);
}

/**
 * Unarchives a feature flag.
 * @param {string} featureFlagId
 * @return {Deferred}
 */
export function unarchive(featureFlagId) {
  return actions.save({
    id: featureFlagId,
    archived: false,
  });
}

/**
 * Fetch all active feature flags via fetchAllPages.
 * @param {number} projectId
 * @return {Deferred}
 */
export function fetchAllByArchivedState(projectId, archivedState = false) {
  const fetchFilters = {
    project_id: projectId,
    archived: archivedState,
    $order: 'last_modified:desc',
    $limit: getPageSize(),
  };

  return actions.fetchAllPages(fetchFilters, {
    skipEvaluatingCachedData: true,
  });
}

export function getPageSize() {
  const {
    FEATURE_KEY,
    VARIABLES,
  } = ChampagneEnums.FEATURES.fetch_all_paginated;
  return (
    getFeatureVariable(FEATURE_KEY, VARIABLES.page_size) ||
    RestApi.constants.DEFAULT_PAGE_SIZE
  );
}

/**
 * Fetch feature flag via api_name. Used for validation in the creation dialog
 * @param {number} projectId
 * @param {string} apiName
 * @return {Deferred}
 */
export function fetchByApiName(projectId, apiName) {
  const fetchFilters = {
    project_id: projectId,
    api_name: apiName,
  };

  return actions.fetchAll(fetchFilters, {
    force: true,
    skipEvaluatingCachedData: true,
  });
}

export function getToggleFlagForEnvironment(environment) {
  const env = toImmutable(environment);

  return featureFlag => {
    const flag = toImmutable(featureFlag);

    const isFlagOn = fns.getIsFlagOnForEnvironment(env);
    const rolloutRule = isFlagOn(flag)
      ? { status: 'paused', percentage_included: 0 }
      : { status: 'running', percentage_included: 10000 };

    const nextFeatureConfig = fns.updateRolloutForEnvironment(
      flag,
      env,
      rolloutRule,
    );

    return actions.save(nextFeatureConfig);
  };
}

export default actions = {
  ...baseEntityActions,
  fetchAllByArchivedState,
  fetchByApiName,
  getPageSize,
  save,
  getToggleFlagForEnvironment,
  unarchive,
};

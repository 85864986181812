import React from 'react';
import { Button, DialogNew, Link } from 'optimizely-oui';

import UrlHelper from 'optly/services/url_helper';

const PageTriggersDialog = ({ closeDialog, currentProjectId }) => {
  const projectSettingsPageUrl = UrlHelper.projectSettingsImplementation(
    currentProjectId,
  );

  return (
    <DialogNew
      footerButtonList={[
        <Button
          style="highlight"
          key="close"
          onClick={closeDialog}
          testSection="dynamic-websites-support-dialog-close">
          Close
        </Button>,
      ]}
      hasCloseButton={true}
      hasOverlay={true}
      onClose={closeDialog}
      testSection="dynamic-websites-support-dialog"
      title="Dynamic Websites Support">
      <p>
        You have selected an option under “Triggers” which requires that this
        project have <strong>support for dynamic websites</strong> enabled. A
        project admin can enable this setting on your project’s{' '}
        <Link href={projectSettingsPageUrl}>Settings page</Link>. Your changes
        have been saved, but this experiment will not work properly until this
        setting is enabled.
      </p>
    </DialogNew>
  );
};

export default PageTriggersDialog;

import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';
import enums from '../enums';

/**
 * uiVersionStore
 * Responsible for the identifying what part of the app the user is in
 */
export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * activeVersion: UI version of the app that the user is in
   * deployedVersionId: currently deployed version of master, as obtained from /_app endpoint
   *
   */
  getInitialState() {
    return toImmutable({
      activeVersion: enums.versions.UNKNOWN,
      deployedVersionId: null,
    });
  },

  initialize() {
    this.on(actionTypes.SET_ACTIVE_UI_VERSION, setActiveVersion);
    this.on(actionTypes.SET_DEPLOYED_VERSION_ID, setDeployedVersionId);
  },
});

/**
 * Set the current VERSION into the store
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {string} payload.version
 */
function setActiveVersion(state, payload) {
  return state.set('activeVersion', payload.version);
}

function setDeployedVersionId(state, payload) {
  return state.set('deployedVersionId', payload.deployedVersionId);
}

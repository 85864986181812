/*
 * All of this file is copied from the v-model directive in Vue 0.10.6 except where noted with
 * "MODIFIED" tag
 */

// MODIFIED -- changed path to utils
import Vue from 'vue';

const utils = Vue.require('utils');
const { filter } = [];

/**
 *  Returns an array of values from a multiple select
 */
function getMultipleSelectOptions(select) {
  return filter
    .call(select.options, option => option.selected)
    .map(option => option.value || option.text);
}

/**
 *  Two-way binding for form input elements
 */
const exported = {
  bind() {
    const self = this;
    const { el } = self;
    const { type } = el;
    const tag = el.tagName;

    self.lock = false;
    self.ownerVM = self.binding.compiler.vm;

    // determine what event to listen to
    self.event =
      self.compiler.options.lazy ||
      tag === 'SELECT' ||
      type === 'checkbox' ||
      type === 'radio'
        ? 'change'
        : 'input';

    // determine the attribute to change when updating
    self.attr =
      type === 'checkbox'
        ? 'checked'
        : tag === 'INPUT' || tag === 'SELECT' || tag === 'TEXTAREA'
        ? 'value'
        : 'innerHTML';

    // select[multiple] support
    if (tag === 'SELECT' && el.hasAttribute('multiple')) {
      this.multi = true;
    }

    let compositionLock = false;
    self.cLock = function() {
      compositionLock = true;
    };
    self.cUnlock = function() {
      compositionLock = false;
    };
    el.addEventListener('compositionstart', this.cLock);
    el.addEventListener('compositionend', this.cUnlock);

    // attach listener
    self.set = self.filters
      ? function() {
          if (compositionLock) return;
          // if this directive has filters
          // we need to let the vm.$set trigger
          // update() so filters are applied.
          // therefore we have to record cursor position
          // so that after vm.$set changes the input
          // value we can put the cursor back at where it is
          let cursorPos;
          try {
            cursorPos = el.selectionStart;
          } catch (e) {} // eslint-disable-line no-empty

          self._set();

          // since updates are async
          // we need to reset cursor position async too
          utils.nextTick(() => {
            if (cursorPos !== undefined) {
              el.setSelectionRange(cursorPos, cursorPos);
            }
          });
        }
      : function() {
          if (compositionLock) return;
          // no filters, don't let it trigger update()
          self.lock = true;

          self._set();

          utils.nextTick(() => {
            self.lock = false;
          });
        };
    el.addEventListener(self.event, self.set);

    // MODIFIED removed some IE9 compatibility stuff
  },

  _set() {
    // MODIFIED set value on flux link rather than on VM directly
    this.ownerVM.$fluxLink[this.key.split('.')[1]] = this.multi
      ? getMultipleSelectOptions(this.el)
      : this.el[this.attr];
  },

  update(value, init) {
    /* jshint eqeqeq: false */
    // sync back inline value if initial data is undefined
    if (init && value === undefined) {
      return this._set();
    }
    if (this.lock) return;
    const { el } = this;
    if (el.tagName === 'SELECT') {
      // select dropdown
      el.selectedIndex = -1;
      if (this.multi && Array.isArray(value)) {
        value.forEach(this.updateSelect, this);
      } else {
        this.updateSelect(value);
      }
    } else if (el.type === 'radio') {
      // radio button
      el.checked = value === el.value;
    } else if (el.type === 'checkbox') {
      // checkbox
      el.checked = !!value;
    } else {
      el[this.attr] = utils.guard(value);
    }
  },

  updateSelect(value) {
    /* jshint eqeqeq: false */
    // setting <select>'s value in IE9 doesn't work
    // we have to manually loop through the options
    const { options } = this.el;
    let i = options.length;
    while (i--) {
      if (options[i].value === value) {
        options[i].selected = true;
        break;
      }
    }
  },

  unbind() {
    const { el } = this;
    el.removeEventListener(this.event, this.set);
    el.removeEventListener('compositionstart', this.cLock);
    el.removeEventListener('compositionend', this.cUnlock);
  },
};

export default exported;

export const { bind, _set, update, updateSelect, unbind } = exported;

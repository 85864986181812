/* eslint-disable import/no-cycle */
import { OptimizelyFeature } from '@optimizely/react-sdk';
import React from 'react';
import PropTypes from 'prop-types';
import { Poptip, Icon } from 'optimizely-oui';

import Immutable, { toImmutable } from 'optly/immutable';
import { connect } from 'core/ui/decorators';
import { formPropType } from 'react_components/form';
import Router from 'core/router';
import UrlHelper from 'optly/services/url_helper';

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { getters as EnvironmentGetters } from 'optly/modules/entity/environment';
import LayerActions from 'optly/modules/entity/layer/actions';
import { actions as OasisExperimentManagerActions } from 'bundles/p13n/sections/oasis_experiment_manager/section_module';

import {
  constants as SectionModuleConstants,
  fns as SectionModuleFns,
  getters as SectionModuleGetters,
} from 'bundles/p13n/sections/manager_fullstack/section_module';

import {
  Sidebar,
  SidebarHeader,
  SidebarNavListBody,
  SidebarNavList,
  SidebarNavListItem,
  SidebarDetailsList,
} from 'react_components/sidebar';

import EnvironmentStatusControls from 'bundles/p13n/components/environments/environment_status_controls';

const metricsPoptip = SectionModuleConstants.EMPTY_METRICS_POPOVER_CONTENT_AB;

@connect({
  activeTab: SectionModuleGetters.activeTab,
  currentProject: CurrentProjectGetters.project,
  experiment: SectionModuleGetters.currentlyEditingExperimentWrapper,
  feature: SectionModuleGetters.currentlyEditingExperimentFeature,
  hasLayerMetrics: CurrentLayerGetters.hasLayerMetrics,
  isFlagsProject: CurrentProjectGetters.isFlagsProject,
  isFullStackProject: CurrentProjectGetters.isFullStackProject,
  environmentStatusesByExperimentId:
    EnvironmentGetters.environmentStatusesByExperimentId,
})
class OasisEditExperimentSidebar extends React.Component {
  static componentId = 'oasis-edit-experiment-sidebar';

  static propTypes = {
    activeTab: PropTypes.oneOf(Object.keys(SectionModuleConstants.Tabs))
      .isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    environmentStatusesByExperimentId: PropTypes.instanceOf(Immutable.Map),
    experiment: PropTypes.instanceOf(Immutable.Map).isRequired,
    feature: PropTypes.instanceOf(Immutable.Map),
    form: formPropType,
    hasLayerMetrics: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    isFlagsProject: PropTypes.bool.isRequired,
    isFullStackProject: PropTypes.bool.isRequired,
  };

  state = {
    savingEnvironments: false,
  };

  navigateBack = () => {
    const { currentProject } = this.props;

    Router.go(UrlHelper.xFullStackHome(currentProject.get('id')));
  };

  onEditMetrics = () => {
    const { currentProject, experiment } = this.props;

    Router.go(
      UrlHelper.fullStackManagerMetrics(
        currentProject.get('id'),
        experiment.get('id'),
      ),
    );
  };

  renderAddMetricsNotification() {
    const { hasLayerMetrics } = this.props;

    if (!hasLayerMetrics) {
      return (
        <div className="flex micro vertical-align--center">
          <Icon
            className="lego-icon color--brand push-half--right"
            name="circle-exclamation"
            size="small"
          />
          <span data-test-section="oasis-edit-experiment-message">
            Please
            <a
              data-test-section="edit-metrics-link"
              onClick={this.onEditMetrics}>
              <span> add a metric </span>
            </a>
            to start this experiment
          </span>
        </div>
      );
    }

    return null;
  }

  getExperimentLabel() {
    const { experiment } = this.props;
    const experimentType = SectionModuleFns.getExperimentType(experiment);
    return SectionModuleConstants.ExperimentTypeToLabel[experimentType];
  }

  onStatusChange = (environmentId, environmentKey, environmentStatus) => {
    const { experiment } = this.props;
    this.setState({
      savingEnvironments: true,
    });

    OasisExperimentManagerActions.updateExperimentStatusWithEnvironment(
      experiment,
      environmentId,
      environmentKey,
      environmentStatus,
    )
      .then(() => {
        LayerActions.fetch(experiment.get('layer_id'));
      })
      .always(() => {
        this.setState({
          savingEnvironments: false,
        });
      });
  };

  renderHeaderDetails() {
    const {
      environmentStatusesByExperimentId,
      experiment,
      hasLayerMetrics,
    } = this.props;
    const { savingEnvironments } = this.state;
    const environmentStatuses = environmentStatusesByExperimentId.get(
      experiment.get('id'),
      toImmutable([]),
    );
    return (
      <>
        <SidebarDetailsList
          items={[
            {
              label: this.getExperimentLabel(),
              value: '',
            },
          ]}
        />

        <EnvironmentStatusControls
          className="push--top"
          hasNoLayerMetrics={!hasLayerMetrics}
          isLoading={savingEnvironments}
          onStatusChange={this.onStatusChange}
          statuses={environmentStatuses}
          testSection="fs-manager-environment-status-control"
        />

        {this.renderAddMetricsNotification()}
      </>
    );
  }

  render() {
    const tabs = SectionModuleConstants.Tabs;

    const {
      activeTab,
      currentProject,
      experiment,
      hasLayerMetrics,
      isFlagsProject,
      isFullStackProject,
    } = this.props;

    const experimentId = experiment.get('id');
    const projectId = currentProject.get('id');
    return (
      <Sidebar testSection="manager-fullstack-sidebar">
        <OptimizelyFeature feature="user_friendly_names">
          {isEnabled =>
            isEnabled ? (
              <SidebarHeader
                scrollHeader={false}
                projectName={currentProject.get('project_name')}
                backLinkText="Experiments"
                backLinkOnClick={this.navigateBack}
                showBackLink={!isFlagsProject}
                title={experiment.get('name')}
                isFullHeight={false}
                isFullStackProject={false}>
                <div
                  className="zeta monospace micro muted force-break"
                  data-test-section="header-subtitle"
                  style={{ marginTop: '-1px' }}>
                  {experiment.get('key')}
                </div>
                {this.renderHeaderDetails()}
              </SidebarHeader>
            ) : (
              <SidebarHeader
                scrollHeader={false}
                projectName={currentProject.get('project_name')}
                backLinkText="Experiments"
                backLinkOnClick={this.navigateBack}
                showBackLink={!isFlagsProject}
                title={experiment.get('name')}
                isFullHeight={false}
                isFullStackProject={isFullStackProject}>
                {this.renderHeaderDetails()}
              </SidebarHeader>
            )
          }
        </OptimizelyFeature>

        <SidebarNavListBody>
          <SidebarNavList>
            <SidebarNavListItem
              isActive={activeTab === tabs.VARIATIONS}
              href={UrlHelper.fullStackManagerVariations(
                projectId,
                experimentId,
              )}
              testSection="fullstack-sidebar-variations-tab">
              <div className="flex flex-align--center">Variations</div>
            </SidebarNavListItem>

            <SidebarNavListItem
              isActive={activeTab === tabs.AUDIENCES}
              href={UrlHelper.fullStackManagerAudiences(
                projectId,
                experimentId,
              )}
              testSection="fullstack-sidebar-audiences-tab">
              <div className="flex flex-align--center">Audiences</div>
            </SidebarNavListItem>

            <SidebarNavListItem
              isActive={activeTab === tabs.METRICS}
              href={UrlHelper.fullStackManagerMetrics(projectId, experimentId)}
              testSection="fullstack-sidebar-metrics-tab">
              <div className="flex flex-align--center">
                Metrics
                {!hasLayerMetrics && (
                  <div className="anchor--right flex--none soft--left">
                    <Poptip content={metricsPoptip}>
                      <Icon
                        className="lego-icon color--brand vertical-align--middle cursor--pointer push-half--left flex--none"
                        name="circle-exclamation"
                        size="small"
                      />
                    </Poptip>
                  </div>
                )}
              </div>
            </SidebarNavListItem>

            <SidebarNavListItem
              isActive={activeTab === tabs.TRAFFIC_ALLOCATION}
              href={UrlHelper.fullStackManagerTrafficAllocation(
                projectId,
                experimentId,
              )}
              testSection="fullstack-sidebar-traffic-tab">
              <div className="flex flex-align--center">Traffic Allocation</div>
            </SidebarNavListItem>

            <SidebarNavListItem
              isActive={activeTab === tabs.WHITELIST}
              href={UrlHelper.fullStackManagerWhitelist(
                projectId,
                experimentId,
              )}
              testSection="fullstack-sidebar-whitelist-tab">
              <div className="flex flex-align--center">Whitelist</div>
            </SidebarNavListItem>

            <SidebarNavListItem
              isActive={activeTab === tabs.API_NAMES}
              href={UrlHelper.fullStackManagerApiNames(projectId, experimentId)}
              testSection="fullstack-sidebar-api-tab">
              <div className="flex flex-align--center">API Names</div>
            </SidebarNavListItem>

            <SidebarNavListItem
              isActive={activeTab === tabs.HISTORY}
              href={UrlHelper.fullStackManagerHistory(projectId, experimentId)}
              testSection="fullstack-sidebar-history-tab">
              <div className="flex flex-align--center">History</div>
            </SidebarNavListItem>

            <SidebarNavListItem
              isActive={activeTab === tabs.SETTINGS}
              href={UrlHelper.fullStackManagerSettings(projectId, experimentId)}
              testSection="fullstack-sidebar-settings-tab">
              <div className="flex flex-align--center">Settings</div>
            </SidebarNavListItem>
          </SidebarNavList>
        </SidebarNavListBody>
      </Sidebar>
    );
  }
}

export default OasisEditExperimentSidebar;

/**
 * Geotargeting Autocomplete
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const $ = require('jquery');
// load typeahead jquery plugin
require('jquery-typeahead').default();
const geotargetingService = require('optly/services/geotargeting');
const UrlUtil = require('optly/utils/url');

module.exports = {
  methods: {
    clear() {
      $(this.$el).typeahead('setQuery', '');
    },
    /**
     * Transforms results into a list and caches
     * the results in the geotargeting service
     *
     * @param {Object} response
     * @return {Array}
     */
    _autocompleteFilter(response) {
      if (!response.succeeded) {
        return [];
      }

      return response.labels.map(label => ({
        value: label,
        location: response.labelsToLocations[label],
      }));
    },
  },

  beforeDestroy() {
    $(this.$el).typeahead('destroy');
  },

  ready() {
    const typeaheadOptions = {
      name: 'Locations',
      limit: 32,
      remote: {
        url: UrlUtil.optimizelyHRDUrl('/api/locations.json?term=%QUERY'),
        filter: this._autocompleteFilter,
      },
    };

    $(this.$el)
      .typeahead(typeaheadOptions)
      .on('typeahead:selected', (event, datum) => {
        const toCache = {};
        toCache[datum.location] = datum.value;

        geotargetingService.cacheLocationsToLabels(toCache);

        this.$dispatch('geotargetingLocationSelected', {
          location: datum.location,
          label: datum.value,
        });
      });

    this.$on('geotargetingAutocompleteClear', this.clear.bind(this));
  },
};

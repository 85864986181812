/**
 * @description Given a click event, the fn will not be called if any of the following items are pressed
 * - event.button: https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button (middle mouse button - Windows / Mac)
 * - event.ctrlKey: https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/ctrlKey (control key - Windows)
 * - event.metaKey: https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/metaKey (command key - Mac)
 * - event.shiftKey: https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/shiftKey (shift key - Windows / Mac)
 *
 * @param {Function} fn
 */
export function shouldPreventDefaultAndCallFn(fn) {
  return event => {
    const { button, ctrlKey, metaKey, shiftKey } = event;
    const MIDDLE_BUTTON = 1;
    if (button === MIDDLE_BUTTON || ctrlKey || shiftKey || metaKey) {
      return;
    }
    // preventDefault is a method that must be invoked on an event object to know which default to prevent
    // When using a destructured preventDefault(),it just accesses the value and does not automatically bind any functions
    // See more at https://stackoverflow.com/questions/49616305/destructing-ev-preventdefault
    event.preventDefault();
    fn(event);
  };
}

export default {
  shouldPreventDefaultAndCallFn,
};

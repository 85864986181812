const $ = require('jquery');
const jsSDKLabActions = require('@optimizely/js-sdk-lab/src/actions').default;

const { default: Immutable, toImmutable } = require('optly/immutable');
const flux = require('core/flux');

const Concurrency = require('bundles/p13n/modules/concurrency_legacy').default;

const Layer = require('optly/modules/entity/layer');
const LayerExperiment = require('optly/modules/entity/layer_experiment');
const LiveCommitTagActions = require('optly/modules/entity/live_commit_tag/actions')
  .default;
const MetricConstants = require('optly/modules/entity/metric/constants');
const VerifierActions = require('optly/modules/verifier').actions; // Use index reference to initialize flux store
const OptimizelyChampagne = require('optly/modules/optimizely_champagne')
  .default;
const ViewActions = require('optly/modules/entity/view').actions;
const EventModuleActions = require('optly/modules/entity/event').actions;
const CurrentProjectGetters = require('optly/modules/current_project').getters;

const getters = require('./getters');
const actionTypes = require('./action_types');

const PermissionsModuleGetters = require('optly/modules/permissions').getters;

exports.setCurrentLayerId = id => {
  flux.dispatch(actionTypes.P13N_SET_CURRENT_LAYER_ID, {
    id,
  });
};

/**
 * Fetch all views and events associated with metrics used in the current layer
 * @return {Promise} resolves when entities have been fetched
 */
exports.fetchAllLayerMetrics = () => {
  const currentLayer = flux.evaluate(getters.layer);

  if (!currentLayer) {
    return;
  }

  const currentLayerMetrics = currentLayer.get('metrics');

  // Fetch all views for page view metrics
  const metricViewDefs = currentLayerMetrics
    .filter(
      metric =>
        metric.get('event_type') === MetricConstants.event_type.PAGEVIEW,
    )
    .map(metric => ViewActions.fetch(metric.get('event_id')))
    .toJS();

  let metricEventDefs = [];
  const isEventsBatchFetchEnabled = jsSDKLabActions.isFeatureEnabled(
    'events_batch_fetch',
  );

  if (!isEventsBatchFetchEnabled) {
    // Fetch all events for non-page view metrics
    metricEventDefs = currentLayerMetrics
      .filter(
        metric =>
          metric.get('event_id') &&
          metric.get('event_type') !== MetricConstants.event_type.PAGEVIEW,
      )
      .map(metric => EventModuleActions.fetch(metric.get('event_id')))
      .toJS();
  }

  return Promise.all([...metricViewDefs, ...metricEventDefs]);
};

/**
 * Revalidate all layer metrics if necessary
 * @return {Promise} resolves when entities have been fetched or with undefined if the project does not have cross-project-metrics permissions
 */
exports.revalidateCrossProjectMetrics = () => {
  const canUseCrossProjectMetrics = flux.evaluate(
    PermissionsModuleGetters.canUseCrossProjectMetrics,
  );
  if (canUseCrossProjectMetrics) {
    return exports.fetchAllLayerMetrics();
  }
  return Promise.resolve();
};

/**
 * Fetch all available and selected cross project metrics.
 * @return {Promise} resolves when cross project metrics have been fetched
 */
exports.fetchAllCrossProjectMetrics = () => {
  let viewsDef;
  let eventsDef;
  const additions_events_filters = {};

  if (jsSDKLabActions.isFeatureEnabled('exclude_archived_events_for_metrics')) {
    additions_events_filters.archived = false;
  }

  const currentLayer = flux.evaluate(getters.layer);
  if (!currentLayer) {
    // in the case where there is no current layer set, we cannot check
    // what views / events are in the current layer to fetch manually in the
    // case where the current user is a Viewer only
    viewsDef = ViewActions.fetchAllForAccount();
    eventsDef = EventModuleActions.fetchAllForAccount();
  } else {
    const currentLayerMetrics = currentLayer.get('metrics');

    const metricViewIds = currentLayerMetrics
      .filter(
        metric =>
          metric.get('event_type') === MetricConstants.event_type.PAGEVIEW,
      )
      .map(metric => metric.get('event_id'))
      .toJS();

    const metricEventIds = currentLayerMetrics
      .filter(
        metric =>
          metric.get('event_id') &&
          metric.get('event_type') !== MetricConstants.event_type.PAGEVIEW,
      )
      .map(metric => metric.get('event_id'))
      .toJS();

    // fetch all views / events for the account, in the case where the users
    // doesnt have permission for particular events (because of being a collab
    // only on specific projets) then do individual fetches
    viewsDef = ViewActions.fetchAllForAccount().then(views => {
      const viewIds = Immutable.Set(views.map(view => view.id));
      const allViewsToFetch = metricViewIds
        .filter(id => !viewIds.has(id))
        .map(id => ViewActions.fetch(id));

      if (allViewsToFetch.length === 0) {
        return $.Deferred().resolve();
      }
      return $.when(...allViewsToFetch);
    });

    eventsDef = EventModuleActions.fetchAllForAccount(
      additions_events_filters,
    ).then(events => {
      const eventIds = Immutable.Set(events.map(event => event.id));
      const allEventsToFetch = metricEventIds
        .filter(id => !eventIds.has(id))
        .map(id => EventModuleActions.fetch(id));
      if (allEventsToFetch.length === 0) {
        return $.Deferred().resolve();
      }

      return $.when(...allEventsToFetch);
    });
  }

  return Promise.all([
    new Promise((resolve, reject) => {
      viewsDef.then(resolve).fail(reject);
    }),
    new Promise((resolve, reject) => {
      eventsDef.then(resolve).fail(reject);
    }),
  ]);
};

/**
 * Start the campaign by activating the live tag
 * Note: For single experiment and multivariate campaigns, also sync the experiment status to active
 * @param {Layer} layer
 * @return {Deferred}
 */
exports.startCampaign = function(layer) {
  // Sync the experiment's paused status for single experiment campaigns
  const policiesToUpdate = [
    Layer.enums.policy.SINGLE_EXPERIMENT,
    Layer.enums.policy.MULTIVARIATE,
  ];
  return new Promise(resolve => {
    if (!policiesToUpdate.includes(layer.policy)) {
      return resolve();
    }
    // Ensure layer experiments are loaded before attempting to save
    LayerExperiment.actions
      .fetchAll({
        project_id: layer.project_id,
        layer_id: layer.id,
      })
      .then(() => {
        const campaignExperiments = flux.evaluateToJS(
          getters.campaignExperimentsByLayerId(layer.id),
        );
        const experiment = campaignExperiments && campaignExperiments[0];
        if (!experiment) {
          return resolve();
        }
        LayerExperiment.actions
          .save({
            id: experiment.id,
            status: LayerExperiment.enums.status.ACTIVE,
          })
          .then(resolve);
      });
  })
    .then(() =>
      LiveCommitTagActions.activateTag({
        layer_id: layer.id,
      }),
    )
    .then(updatedLiveTag => {
      // Purposefully don't pipe the result of the verifier as it takes
      // too long for the snippet to update. We only want to wait for the publish to happen
      VerifierActions.verify({
        projectId: layer.project_id,
        revisionToVerify: updatedLiveTag.project_code_revision,
      }).catch(() => {}); // don't error on rejection
    })
    .then(() => {
      if (layer.policy === Layer.enums.policy.SINGLE_EXPERIMENT) {
        OptimizelyChampagne.actions.track(
          OptimizelyChampagne.enums.TestUrlTargetingSetup.Events
            .EXPERIMENT_STARTED,
        );
        const timeToFirstPublish = Date.now() - Date.parse(layer.created);
        OptimizelyChampagne.actions.track(
          OptimizelyChampagne.enums.TestUrlTargetingSetup.Events
            .TIME_TO_PUBLISH,
          {
            eventTags: {
              value: timeToFirstPublish,
            },
          },
        );
        if (layer.metrics && layer.metrics.length) {
          OptimizelyChampagne.actions.track(
            OptimizelyChampagne.enums.TestUrlTargetingSetup.Events
              .COUNT_METRICS_ON_PUBLISH,
            {
              eventTags: {
                value: layer.metrics.length,
              },
            },
          );
        }
      }
    });
};

/**
 * Pause the campaign by activating the live tag
 * Note: For single experiment and multivariate campaigns, also sync the experiment status to active
 * @param {Layer} layer
 * @return {Deferred}
 */
exports.pauseCampaign = function(layer) {
  // Sync the experiment's paused status for single experiment and multivariate campaigns
  const policiesToUpdate = [
    Layer.enums.policy.SINGLE_EXPERIMENT,
    Layer.enums.policy.MULTIVARIATE,
  ];
  return new Promise((resolve, reject) => {
    if (policiesToUpdate.includes(layer.policy)) {
      // Ensure layer experiments are loaded before attempting to save
      LayerExperiment.actions
        .fetchAll({
          project_id: layer.project_id,
          layer_id: layer.id,
        })
        .then(() => {
          const campaignExperiments = flux.evaluateToJS(
            getters.campaignExperimentsByLayerId(layer.id),
          );
          const experiment = campaignExperiments && campaignExperiments[0];

          return LayerExperiment.actions.save({
            id: experiment.id,
            status: LayerExperiment.enums.status.PAUSED,
          });
        })
        .then(resolve);
    } else {
      resolve();
    }
  }).then(() => {
    const deactivatedTag = LiveCommitTagActions.deactivateTag({
      layer_id: layer.id,
    });

    // Purposefully don't pipe the result of the verifier as it takes
    // too long for the snippet to update
    deactivatedTag.then(updatedLiveTag => {
      VerifierActions.verify({
        projectId: layer.project_id,
        revisionToVerify: updatedLiveTag.project_code_revision,
      }).catch(() => {}); // don't error on rejection
    });

    return deactivatedTag;
  });
};

exports.setCurrentLayerShareToken = shareToken => {
  flux.dispatch(actionTypes.P13N_SET_CURRENT_LAYER_SHARE_TOKEN, {
    share_token: shareToken,
  });
};

/**
 * Subscribes to the current AB layer experiment
 */
exports.subscribeToCurrentABExperiment = () => {
  const experiment = flux.evaluate(getters.currentLayerABExperiment);
  if (experiment) {
    Concurrency.actions.subscribeToEntity(
      LayerExperiment.entityDef.entity,
      experiment.get('id'),
    );
    // If the user loses their internet connection and comes back, make sure
    // we invoke this method again to resubscribe them.
    $(window).one('online', exports.subscribeToCurrentABExperiment);
  }
};

/**
 * Unsubscribes from the current AB layer experiment
 */
exports.unsubscribeFromCurrentABExperiment = () => {
  const experiment = flux.evaluate(getters.currentLayerABExperiment);
  if (experiment) {
    Concurrency.actions.unsubscribeFromEntity(
      LayerExperiment.entityDef.entity,
      experiment.get('id'),
    );
    // If the user loses their internet connection and comes back, make sure
    // we invoke this method again to resubscribe them.
    $(window).off('online', exports.subscribeToCurrentABExperiment);
  }
};

/**
 * Add a new metric to the layer with the event id provided and save layer
 * @param {Number} eventId
 *
 * @returns {Deferred}
 */
exports.addEventToCurrentLayerMetricsWithMetricsBuilder = eventId => {
  const layer = flux.evaluate(getters.layer);
  let layerMetrics = layer.get('metrics');

  // Build a default metric for now, we can expand the track clicks UI to allow you to control this by instantiating
  // the metric editor.
  const constructedMetric = toImmutable({
    aggregator: MetricConstants.aggregator.UNIQUE,
    display_title: null,
    display_unit: null,
    event_id: eventId,
    event_type: MetricConstants.event_type.CLICK,
    field: null,
    scope: Layer.fns.isPersonalizationLayer(layer)
      ? MetricConstants.scope.SESSION
      : MetricConstants.scope.VISITOR,
    winning_direction: MetricConstants.winning_direction.INCREASING,
  });

  layerMetrics = layerMetrics.push(constructedMetric);

  return exports.saveCurrentLayerMetricsWithMetricsBuilder(layerMetrics);
};

/**
 * Remove metric that has provided event id from layer and save layer
 * @param {Number} eventId
 *
 * @returns {Deferred}
 */
exports.removeEventFromCurrentLayerMetrics = eventId => {
  const layer = flux.evaluate(getters.layer);
  const layerMetrics = layer.get('metrics');

  // Remove all metrics that use the event_id associated with this event
  const updatedLayerMetrics = layerMetrics.filter(
    metric => metric.get('event_id') !== eventId,
  );

  return exports.saveCurrentLayerMetricsWithMetricsBuilder(updatedLayerMetrics);
};

/**
 * Just happily overwrite the metrics property of the layer
 * @param {Immutable.List} metrics
 *
 * @returns {Deferred}
 */
exports.saveCurrentLayerMetricsWithMetricsBuilder = metrics => {
  const currentLayerId = flux.evaluate(getters.id);

  return Layer.actions.save({
    id: currentLayerId,
    metrics: metrics.toJS(),
  });
};

/*
 * Modules defined by electron and used by the webview inside the editor
 * (aka used in inner)
 *
 * These modules are required and provided on the window.optlyDesktop
 * variable by the inner script of the optimizely desktop application
 *
 * https://github.com/optimizely/optimizely-desktop/blob/master/app/p13n/inner.js
 */
export default {
  ipcDesktop: window.optlyDesktop && window.optlyDesktop.ipcDesktop,
};

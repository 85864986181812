/**
 * Entity Definition for Live Variable Usages
 */

import FieldTypes from 'optly/modules/rest_api/field_types';

export const entity = 'live_variable_usages';

export const parent = {
  entity: 'projects',
  key: 'project_id',
};

export const fieldTypes = {
  id: FieldTypes.NUMBER,
  project_id: FieldTypes.NUMBER,
  created: FieldTypes.DATE,
  experiment_id: FieldTypes.NUMBER,
  experiment_scope: FieldTypes.STRING,
  group_id: FieldTypes.NUMBER,
  live_variable_id: FieldTypes.NUMBER,
  usage_instances: FieldTypes.ARRAY,
  last_modified: FieldTypes.DATE,
};

export default {
  entity,
  parent,
  fieldTypes,
};

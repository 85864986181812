import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';
import DcpDatasourceAttributeGetters from 'optly/modules/entity/dcp_datasource_attribute/getters';
import { toImmutable } from 'optly/immutable';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

export const datasourceWithNestedAttributes = [
  baseEntityGetters.entityCache,
  DcpDatasourceAttributeGetters.entityCache,
  (datasources, attributes) =>
    toImmutable({}).withMutations(datasourceWithNestedAttributes => {
      datasources.forEach(datasource => {
        datasourceWithNestedAttributes.set(datasource.get('id'), datasource);
      });

      attributes.forEach(attribute => {
        const datasourceId = attribute.get('dcp_datasource_id');
        if (datasourceId) {
          const datasource = datasourceWithNestedAttributes.get(datasourceId);
          if (datasource) {
            if (!datasource.attributes) {
              datasource.attributes = [];
            }
            datasource.attributes.push(attribute);
          }
        }
      });
      return datasourceWithNestedAttributes;
    }),
];

export default {
  ...baseEntityGetters,
  datasourceWithNestedAttributes,
};

import flux from 'core/flux';

import actionTypes from './action_types';

export const clearLayerHoldback = () => {
  flux.dispatch(actionTypes.P13N_SET_LAYER_HOLDBACK, {
    holdback: null,
  });
};

export const setLayerHoldback = holdback => {
  flux.dispatch(actionTypes.P13N_SET_LAYER_HOLDBACK, {
    holdback,
  });
};

export const setLayerHoldbackInvalid = invalid => {
  flux.dispatch(actionTypes.P13N_SET_LAYER_HOLDBACK_INVALID, {
    invalid,
  });
};

export default {
  clearLayerHoldback,
  setLayerHoldback,
  setLayerHoldbackInvalid,
};

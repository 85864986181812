import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { redBase } from '@optimizely/design-tokens/dist/json/colors.json';

import { Button, Icon, Pill } from 'optimizely-oui';

import Immutable from 'optly/immutable';
import sanitizeHtml from 'optly/utils/sanitize_html';

import {
  Dropdown,
  DropdownContents,
  DropdownListItem,
  DropdownBlockLink,
} from 'react_components/dropdown';
import FilterList from 'react_components/filter_list';

class SelectedEntity extends React.Component {
  static propTypes = {
    description: PropTypes.string,
    /**
     * Used to identify the item in the parent's data that this component is
     * displaying
     */
    index: PropTypes.number.isRequired,
    /**
     * Boolean that represents whether or not the selected entity should be required or not.
     * If it is not required, it is dismissible. If it is required, it is not dismissible.
     */
    isEntityRequired: PropTypes.bool,
    /** Makes the component full width of container */
    isFullWidth: PropTypes.bool,
    /** Text to display inside the token */
    name: PropTypes.string.isRequired,
    /** Click handler for x icon in the top right of the token */
    onUnselect: PropTypes.func.isRequired,
    /**
     * Click handler for  right side secondary button, which is shown when both
     * secondaryText and secondaryOnClick props are provided.
     */
    secondaryOnClick: PropTypes.func,
    /**
     * Text shown on the right side secondary button, which is shown when both
     * secondaryText and secondaryOnClick props are provided.
     */
    secondaryText: PropTypes.string,
    /**
     * Array of actions to be shown in a dropdown when the secondary button is clicked
     */
    secondaryActions: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ),
    /**
     * A function that returns a string of description text to be shown on the right of a selected entity.
     * The selected entity's ID is passed into the function.
     */
    selectedEntitySecondaryText: PropTypes.func,
    /** Entire selected entity config */
    selectedEntity: PropTypes.object,
    /** Identifier used to create data-test-section attributes for testing */
    testSection: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    /** Content of the warning message */
    warningContent: PropTypes.string,
  };

  static defaultProps = {
    isEntityRequired: false,
  };

  onUnselect = clickEvt => {
    const { index, onUnselect } = this.props;
    onUnselect(clickEvt, index);
  };

  secondaryOnClick = clickEvt => {
    const { index, secondaryOnClick } = this.props;
    secondaryOnClick(clickEvt, index);
  };

  render() {
    const {
      description,
      index,
      isEntityRequired,
      isFullWidth,
      name,
      secondaryActions,
      secondaryOnClick,
      secondaryText,
      selectedEntity,
      selectedEntitySecondaryText,
      testSection,
      warningContent,
    } = this.props;
    const testSectionIdentifier = `${testSection}-entity-selector-selected-${index}`;
    let optionalSecondaryButton = '';
    if (secondaryOnClick && secondaryText) {
      optionalSecondaryButton = (
        <span className="draggable-token__meta soft--sides">
          <Button
            testSection={`${testSectionIdentifier}-secondary`}
            size="narrow"
            style="outline"
            onClick={this.secondaryOnClick}>
            {secondaryText}
          </Button>
        </span>
      );
    }

    if (secondaryActions && secondaryText) {
      optionalSecondaryButton = (
        <Dropdown
          activator={
            <Button
              testSection={`${testSectionIdentifier}-secondary`}
              size="small"
              style="outline">
              <div className="flex flex-align--center">
                <span className="flex flex--1">{secondaryText}</span>
                <span className="push--left oui-arrow-inline--down" />
              </div>
            </Button>
          }>
          <DropdownContents direction="left" minWidth="200px">
            {secondaryActions.map((secondaryAction, mapIndex) => {
              const onSelectedSecondaryClick = () => {
                secondaryAction.onClick(selectedEntity);
              };
              return (
                <DropdownListItem hideOnClick={true} key={mapIndex}>
                  <DropdownBlockLink
                    isLink={true}
                    onClick={onSelectedSecondaryClick}
                    testSection={secondaryAction.testSection}
                    trackId={secondaryAction.trackId}>
                    {secondaryAction.text}
                  </DropdownBlockLink>
                </DropdownListItem>
              );
            })}
          </DropdownContents>
        </Dropdown>
      );
    }

    return (
      <li>
        <div className="flex flex-align--center">
          <div className="flex--1">
            <Pill
              backgroundColor={warningContent ? 'tertiary' : 'secondary'}
              description={description}
              showWell={false}
              isDismissible={!isEntityRequired}
              isFullWidth={isFullWidth}
              name={name}
              onDismiss={this.onUnselect}
              testSection={testSectionIdentifier}
            />
            {selectedEntitySecondaryText && (
              <span
                className="micro muted soft-half--left"
                data-test-section={`selected-entity-secondary-text-${selectedEntity.get(
                  'id',
                )}`}>
                {selectedEntitySecondaryText(selectedEntity.get('id'))}
              </span>
            )}
          </div>
          {optionalSecondaryButton}
        </div>
        {warningContent && (
          <div
            className="flex flex-align--center push-double--left push-half--bottom"
            data-test-section={`${testSectionIdentifier}-warning`}>
            <Icon color={redBase} size="small" name="circle-exclamation" />
            {/* Inner text of the warningContent string should only content texts, not input from users, and it's already
            being sanitized using the sanitizeHtml utility function. This is to allow BOLD (<b>) or ITALIC (<i>) HTML format. */}
            <span
              className="push-half lego-form-note--bad-news"
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(warningContent) }}
              data-test-section={`${testSectionIdentifier}-warning-text`}
            />
          </div>
        )}
      </li>
    );
  }
}

export default class EntitySelector extends React.Component {
  displayName = 'EntitySelector';

  static propTypes = {
    isDisabled: PropTypes.bool,
    /**
     * The type of entity being displayed by the selector. Only required if we
     * need to be able to select the list items using a generic test section.
     */
    entityName: PropTypes.string,
    /** Token name when there are no selected items yet */
    emptyStateTokenName: PropTypes.string,
    /** Placeholder text for the filter input in the unselected area */
    inputPlaceholder: PropTypes.string,
    /** Makes the component full width of container */
    isFullWidth: PropTypes.bool,
    /**
     * Click handler for unselected entities in the lower area. Will be passed
     * the click event, and the item data (an Immutable Map)
     */
    onEntitySelected: PropTypes.func.isRequired,
    /**
     * Click handler for the x icon in selected entity tokens. Will be passed
     * the click event, and the item data (an Immutable Map)
     */
    onEntityUnselected: PropTypes.func.isRequired,
    /**
     * Click handler for the secondary button on the right side of selected
     * entities in the top area. The secondary button is shown when both
     * selectedSecondaryText and onSelectedSecondaryClick are provided.
     */
    onSelectedSecondaryClick: PropTypes.func,
    /**
     * Array of actions to be shown on selected entities for the secondary button
     */
    selectedSecondaryActions: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ),
    /**
     * Click handler for the secondary button on the right side of unselected
     * entities in the lower area. The secondary button is shown when both
     * unselectedSecondaryText and onUnselectedSecondaryClick are provided.
     */
    onUnselectedSecondaryClick: PropTypes.func,
    /** Click handler for the primary action row in the unselected area */
    primaryActionOnClick: PropTypes.func,
    /** Text for the primary action row in the unselected area */
    primaryActionText: PropTypes.string,
    /**
     * An Immutable List of Maps, describing the selected items in the top area.
     * The Maps have these properties:
     *   id: Used as a React element key for the item
     *   name: Displayed in the main area of the token
     *
     * And an optional property:
     *   __isEntitySelectedRequired: Used for required selected items.
     */
    selectedItems: PropTypes.instanceOf(Immutable.List).isRequired,
    /**
     * Text for the secondary button on the right side of selected entities in
     * the top area. The secondary button is shown when both
     * selectedSecondaryText and onSelectedSecondaryClick are provided.
     */
    selectedSecondaryText: PropTypes.string,
    selectedEntitySecondaryText: PropTypes.func,
    /** Identifier used to create data-test-section attributes for testing */
    testSection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * An Immutable List of Maps, describing the unselected items in the bottom
     * area.
     * Each Map represents a sub-section of the filter list with a header
     * and a number of filterable items.
     * The Maps have these properties:
     *  key:
     *    Used as React element key for the sub-section's element
     *  items:
     *    Immutable List of Maps with 'name', 'description', and 'id' properties,
     *    used to render the main and secondary content area in the item rows
     *  url:
     *    If provided, used for the right-side URL in the sub-section category header
     *  label:
     *    If provided, used for the main content in the sub-section category header
     */
    unselectedItems: PropTypes.instanceOf(Immutable.List).isRequired,
    /**
     * Text for the secondary button on the right side of unselected entities in
     * the lower area. The secondary button is shown when both
     * unselectedSecondaryText and onUnselectedSecondaryClick are provided.
     */
    unselectedSecondaryText: PropTypes.string,
  };

  static defaultProps = {
    isDisabled: false,
    inputPlaceholder: '',
    testSection: '',
  };

  onUnselect = (clickEvt, index, sortedSelectedItems) => {
    const { onEntityUnselected } = this.props;
    onEntityUnselected(clickEvt, sortedSelectedItems.get(index));
  };

  onSelectedSecondaryClick = (clickEvt, index, sortedSelectedItems) => {
    const { onSelectedSecondaryClick } = this.props;
    onSelectedSecondaryClick(clickEvt, sortedSelectedItems.get(index));
  };

  render() {
    const {
      isDisabled,
      emptyStateTokenName,
      entityName,
      inputPlaceholder,
      isFullWidth,
      onEntitySelected,
      onUnselectedSecondaryClick,
      primaryActionOnClick,
      primaryActionText,
      selectedSecondaryActions,
      selectedSecondaryText,
      selectedEntitySecondaryText,
      selectedItems,
      testSection,
      unselectedItems,
      unselectedSecondaryText,
    } = this.props;

    let selectedEls = null;

    if (selectedItems.isEmpty() && emptyStateTokenName) {
      selectedEls = (
        <li className="hard--sides">
          <div className="flex">
            <Pill name={emptyStateTokenName} style="secondary" />
          </div>
        </li>
      );
    } else {
      const sortedSelectedItems = selectedItems.sortBy(
        selectedEntity => !selectedEntity.get('__isEntitySelectedRequired'),
      );

      selectedEls = sortedSelectedItems.map((selectedEntity, index) => (
        <SelectedEntity
          description={selectedEntity.get('description')}
          key={selectedEntity.get('id')}
          testSection={testSection}
          index={index}
          isEntityRequired={selectedEntity.get('__isEntitySelectedRequired')}
          isFullWidth={isFullWidth}
          name={selectedEntity.get('name')}
          selectedEntity={selectedEntity}
          onUnselect={_.partialRight(this.onUnselect, sortedSelectedItems)}
          secondaryText={selectedSecondaryText}
          secondaryOnClick={_.partialRight(
            this.onSelectedSecondaryClick,
            sortedSelectedItems,
          )}
          secondaryActions={selectedSecondaryActions}
          selectedEntitySecondaryText={selectedEntitySecondaryText}
          warningContent={selectedEntity.get('warningContent')}
        />
      ));
    }

    return (
      <div data-ui-component={true}>
        <ul className="lego-block-list push-double--bottom">{selectedEls}</ul>
        <FilterList
          isDisabled={isDisabled}
          entityName={entityName}
          itemOnClick={onEntitySelected}
          itemSecondaryOnClick={onUnselectedSecondaryClick}
          itemSecondaryText={unselectedSecondaryText}
          primaryActionText={primaryActionText}
          primaryActionOnClick={primaryActionOnClick}
          inputPlaceholder={inputPlaceholder}
          lists={unselectedItems}
        />
      </div>
    );
  }
}

const _ = require('lodash');

const baseComponent = require('./base');
const constants = require('./constants');

const ui = require('core/ui').default;

const template = require('./select_ad_group.html');

/**
 * Third Party Condition Select Ad Group Field Component
 * Responsible for rendering the configuration UI for a third-party
 * ad group condition
 */
module.exports = _.extend({}, baseComponent, {
  template,

  data: {
    adGroupValues: null,
    campaignValues: null,
    selectedAdGroupIndex: null,
    selectedCampaignIndex: null,
  },

  methods: {
    /**
     * Gets the selected ad group
     * Set this.selectedCampaignIndex and this.selectedAdGroupIndex
     * before calling this method
     */
    getSelectedAdGroup() {
      const selectedCampaign = this.getSelectedCampaign();
      return selectedCampaign && this.selectedAdGroupIndex !== -1
        ? selectedCampaign.child_options[this.selectedAdGroupIndex]
        : null;
    },

    /**
     * Gets the selected campaign
     * Set this.selectedCampaignIndex before calling this method
     */
    getSelectedCampaign() {
      return this.selectedCampaignIndex !== -1
        ? this.config.values[this.selectedCampaignIndex]
        : null;
    },

    /**
     * Handles an ad group change
     */
    onSelectedAdGroupChange() {
      this.updateConditionValue();
    },

    /**
     * Handles a campaign change
     */
    onSelectedCampaignChange() {
      this.updateAdGroupOptions();
      this.updateConditionValue();
    },

    /**
     * Restores initially selected options.
     * Expects the form: [campaignValue, adGroupValue]
     */
    restoreSelectedOptions() {
      this.selectedCampaignIndex = this.selectedAdGroupIndex = -1;

      const split = this.condition.value
        .toString()
        .split(constants.VALUE_DELIMITER);

      // Check for campaign ID and ad group ID.
      // User may have input something invalid in 'code mode'.
      if (split.length === 2) {
        const campaignId = split[0];
        const adGroupId = split[1];

        this.selectedCampaignIndex = _.findIndex(
          this.config.values,
          campaign => campaign.value.toString() === campaignId,
        );

        const selectedCampaign = this.getSelectedCampaign();
        if (selectedCampaign) {
          this.updateAdGroupOptions();
          this.selectedAdGroupIndex = _.findIndex(
            selectedCampaign.child_options,
            option => option.value.toString() === adGroupId,
          );
        }
      }
    },

    /**
     * Set values for campaign dropdown
     */
    setCampaignValues() {
      this.campaignValues = this.config.values;
    },

    /**
     * Set default / initial values for the component
     */
    setDefaultValue() {
      if (this.condition.value) {
        this.restoreSelectedOptions();
      } else {
        this.selectedCampaignIndex = this.selectedAdGroupIndex = -1;
        this.updateAdGroupOptions();
        this.updateConditionValue();
      }
    },

    /**
     * Updates the Ad Group Options
     * Selects the first option by default
     */
    updateAdGroupOptions() {
      this.selectedAdGroupIndex = -1;

      const selectedCampaign = this.getSelectedCampaign();
      this.adGroupValues = selectedCampaign
        ? selectedCampaign.child_options
        : [];
    },

    /**
     * Updates the condition value used by the component / code mode
     */
    updateConditionValue() {
      const selectedCampaign = this.getSelectedCampaign();
      const selectedAdGroup = this.getSelectedAdGroup();

      if (selectedCampaign && selectedAdGroup) {
        const campaignId = selectedCampaign.value.toString();
        const adGroupId = selectedAdGroup.value.toString();

        this.condition.value = [campaignId, adGroupId].join(
          constants.VALUE_DELIMITER,
        );
      } else {
        this.condition.value = '';
      }
    },
  },

  created() {
    this.adGroupValues = [];
    this.campaignValues = [];
  },

  ready() {
    baseComponent.ready.call(this);
    this.setCampaignValues();
    this.setDefaultValue();

    // Notify user if conditions weren't loaded
    if (!this.config.values || !this.config.values.length) {
      ui.showNotification({
        message: tr(
          'Optimizely is unable to access any data from your AdWords account. Please connect an account that contains ad campaigns/groups.',
        ),
      });
    }
  },
});

/*
 * This module is used to bootstrap our application entry point bundles.  The app config is initialized with the
 * configuration data populated on the page by the backend and the webpack public path is set from this config
 * so that dynamic bundles are loaded from the correct URL.  This module's code needs to execute immediately
 * on module load to ensure the webpack public path is set before any dynamic bundle loads are triggered.
 * As such, the import/require of this module should be the very first module loaded in your entry point main.js
 *
 * See: https://webpack.js.org/guides/public-path/
 */
import config from 'atomic-config';

// Load backend-provided config on the page into the config module
if (window.optlyConfig) {
  config.set(window.optlyConfig);
}

const envPublicPath = config.get('env.WEBPACK_PUBLIC_PATH');

if (__DEV__) {
  console.log('[BUNDLE BOOTSTRAP] Setting runtime bundle public path to ', envPublicPath); // eslint-disable-line
}

if (envPublicPath) {
  __webpack_public_path__ = envPublicPath; // eslint-disable-line
}

import { Link, Label } from 'optimizely-oui';
import PropTypes from 'prop-types';
import React from 'react';

import { CHANGE_PASSWORD_LINK, SINGLE_SIGNON_ENABLED } from './constants';

/**
 * Simple component for rendering Password field with links.
 * @param {Object} props - Properties passed to component
 * @returns {ReactElement}
 */
function PasswordField({ isSsoEnabled, onChangeLinkClick, visible }) {
  // Return field and error
  return (
    visible && (
      <div data-ui-component={true}>
        <Label testSection="profile-password-label">Password</Label>
        <div className="push--top">
          {!isSsoEnabled && (
            <Link
              testSection="change-password-link"
              onClick={onChangeLinkClick}>
              {CHANGE_PASSWORD_LINK}
            </Link>
          )}
          {isSsoEnabled && (
            <p data-test-section="sso-password-enabled" className="muted">
              {SINGLE_SIGNON_ENABLED}
            </p>
          )}
        </div>
      </div>
    )
  );
}

PasswordField.propTypes = {
  /** Sets whether the user is signed in via single sign on */
  isSsoEnabled: PropTypes.bool,
  /** Sets event handler for 'Change Password' link clicks */
  onChangeLinkClick: PropTypes.func,
  /** Sets whether the field is visible in the current form instance */
  visible: PropTypes.bool,
};

PasswordField.defaultProps = {
  isSsoEnabled: false,
  onChangeLinkClick: () => {},
  visible: false,
};

export default PasswordField;

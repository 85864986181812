import PropTypes from 'prop-types';
import React from 'react';
import Immutable, { toImmutable } from 'optly/immutable';
import { fns as viewFns, enums as viewEnums } from 'optly/modules/entity/view';

import SingleUrl from 'bundles/p13n/components/targeting/url_config/single_url';
import APIName from 'bundles/p13n/components/targeting/url_config/api_name';

import ComponentModuleConstants from '../../component_module/constants';

class UrlTargetingCreation extends React.Component {
  /*
   * Trigger props.onChange with updated edit_url and conditions
   * @param {Array} includeConditions - can only be of length 1
   */
  onChangeConditions = includeConditions => {
    // Get editor url by taking the first value of include conditions (only possible value that the user can input in this component)
    const editorUrl = includeConditions.getIn([0, 'value']);
    // Parent component expects conditions as a single string representing both include and exclude conditions
    const conditions = viewFns.serializeConditions(
      includeConditions,
      Immutable.List(),
    );
    const updatedConfig = this.props.config.merge({
      edit_url: editorUrl,
      conditions,
    });
    this.props.onChange(updatedConfig);
  };

  /*
   * @param {Object} event
   */
  updateAPIName = event => {
    const updatedConfig = this.props.config.merge({
      api_name: event.target.value,
    });
    this.props.onChange(updatedConfig);
  };

  render() {
    const { config, conditionsError, apiNameError, urlError } = this.props;
    const { includeConditions } = viewFns.deserializeConditions(
      config.get('conditions'),
    );
    return (
      <div data-test-section="url-targeting-creation">
        <SingleUrl
          includeUrlConditions={toImmutable(includeConditions)}
          onChange={this.onChangeConditions}
          showMatchType={false}
          error={urlError || conditionsError}
        />
        {config.get('activation_type') === viewEnums.activationModes.MANUAL && (
          <APIName
            error={apiNameError}
            apiName={config.get('api_name')}
            onChange={this.updateAPIName}
          />
        )}
      </div>
    );
  }
}

UrlTargetingCreation.propTypes = {
  config: PropTypes.instanceOf(Immutable.Map),
  onChange: PropTypes.func.isRequired,
  conditionsError: PropTypes.string,
  apiNameError: PropTypes.string,
  urlError: PropTypes.string,
};

UrlTargetingCreation.defaultProps = {
  config: ComponentModuleConstants.DEFAULT_URL_TARGETING_CONFIG,
};

export default UrlTargetingCreation;

/**
 * Filter for formatting numbers with commas
 */
const tr = require('optly/translate');

module.exports = function(val, fractionSize) {
  if (typeof fractionSize === 'string') {
    fractionSize = parseInt(fractionSize, 10);
  }
  return tr.number(val, fractionSize);
};

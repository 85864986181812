import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';

import Immutable from 'optly/immutable';
import { connect } from 'core/ui/decorators';
import { deepEqual } from 'core/utils/react';
import P13NDashboardActions from 'bundles/p13n/modules/dashboard/actions';
import P13NUIActions from 'bundles/p13n/modules/ui/actions';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import AudienceSearchPicker from 'bundles/p13n/components/audience_search_picker';

import ExperienceOrderManager from './subcomponents/experience_order_manager';
import {
  actions as ComponentModuleActions,
  getters as ComponentModuleGetters,
} from './component_module';

@connect({
  orderedAudienceIds: ComponentModuleGetters.orderedAudienceIds,
  projectId: CurrentProjectGetters.id,
})
class ExperienceManager extends React.Component {
  static propTypes = {
    orderedAudienceIds: PropTypes.instanceOf(Immutable.List).isRequired,
    projectId: PropTypes.number.isRequired,
  };

  shouldComponentUpdate = deepEqual();

  componentWillUnmount() {
    ComponentModuleActions.clearOrderedAudienceIds();
  }

  createAudience = audienceName => {
    const audienceDeferred = $.Deferred();
    audienceDeferred.then(_audience => {
      ComponentModuleActions.addAudienceId(_audience.id);
    });

    P13NDashboardActions.fetchOrCreateAudience(null, audienceName).then(
      audience => {
        P13NUIActions.showAudienceEditorDialog(audience, audienceDeferred);
      },
    );
  };

  render() {
    const { orderedAudienceIds, projectId } = this.props;

    return (
      <div>
        <h3>Experiences</h3>
        <p className="push-double--bottom">
          Choose which audiences to personalize for. Each audience will see a
          separate experience. To create experiences with more complex
          conditions or variations, you can skip this step and add them later.
        </p>
        <AudienceSearchPicker
          addAudience={audience => {
            ComponentModuleActions.addAudienceId(audience.id);
          }}
          openCreateAudienceDialog={audienceName =>
            this.createAudience(audienceName)
          }
          openEditAudienceDialog={audience =>
            ComponentModuleActions.editAudience(audience.id)
          }
          placeholder="Search for Audiences"
          projectId={projectId}
          selectedEntityIds={orderedAudienceIds}
        />
        <ExperienceOrderManager />
      </div>
    );
  }
}

export default ExperienceManager;

import campaignListEmptySVG from '/static/img/p13n/campaign-list-empty-state.svg';
import testingEmptySVG from '/static/img/p13n/testing-empty-state.svg';
import experimentsEmptyStateSVG from '/static/img/p13n/custom-project-experiments-empty-state.svg';
import attributeDashboardEmptyStateSVG from '/static/img/p13n/attribute-dashboard-empty-state.svg';


/**
 * Module specific enums
 */
export default {
  V2_PLATFORM_TEXT__AB_AND_P13N: tr('Optimizely X'),
  V2_PLATFORM_TEXT__P13N_ONLY: tr('Personalization'),
  V2_PLATFORM_TEXT__AB_ONLY: tr('Optimizely X'),

  V1_PLATFORM_TEXT__AB_AND_P13N: tr('Optimizely Classic'),
  V1_PLATFORM_TEXT__P13N_ONLY: tr('Optimizely Testing'),
  V1_PLATFORM_TEXT__AB_ONLY: tr('Optimizely Classic'),

  V2_FIRST_RUN_TITLE__AB_AND_P13N: tr('Get Started with Web Experimentation'),
  V2_FIRST_RUN_SUBTITLE__AB_AND_P13N: tr(
    'Take your web experience to the next level.',
  ),
  V2_FIRST_RUN_IMAGE__AB_AND_P13N: campaignListEmptySVG,

  V2_FIRST_RUN_TITLE__P13N_ONLY: tr('Get Started with Web Experimentation'),
  V2_FIRST_RUN_SUBTITLE__P13N_ONLY: tr(
    'Take your web experience to the next level.',
  ),
  V2_FIRST_RUN_IMAGE__P13N_ONLY: campaignListEmptySVG,

  V2_FIRST_RUN_TITLE__AB_ONLY: tr(
    'Get started with Optimizely X Web Experimentation',
  ),
  V2_FIRST_RUN_SUBTITLE__AB_ONLY: tr(
    'The easiest and most powerful way to run experiments across your website.',
  ),
  V2_FIRST_RUN_IMAGE__AB_ONLY: testingEmptySVG,

  V2_FIRST_RUN_TITLE__EDGE: tr('Get started with Optimizely Performance Edge'),
  V2_FIRST_RUN_SUBTITLE__EDGE: tr(
    'The fastest way to run experiments on your website.',
  ),
  V2_FIRST_RUN_IMAGE__EDGE: testingEmptySVG,

  BUNDLED_SNIPPET_IDENTIFIER__P13N_ONLY: 'p13n',
  BUNDLED_SNIPPET_LABEL__P13N_ONLY: tr(
    'Use Personalization and Optimizely Testing',
  ),
  BUNDLED_SNIPPET_DESCRIPTION__P13N_ONLY: tr(
    'Allows you to run campaigns in Personalization while still using \
                                              Optimizely Testing, but <strong> increases Optimizely snippet \
                                              size</strong>.',
  ),

  BUNDLED_SNIPPET_IDENTIFIER__AB_AND_P13N: 'ab_and_p13n',
  BUNDLED_SNIPPET_LABEL__AB_AND_P13N: tr(
    'Use both Optimizely X and Optimizely Classic',
  ),
  BUNDLED_SNIPPET_DESCRIPTION__AB_AND_P13N: tr(
    'Allows you to run campaigns in both versions of Optimizely, but \
                                                <strong>increases Optimizely snippet size</strong>.',
  ),

  STANDALONE_SNIPPET_IDENTIFIER__P13N_ONLY: 'p13n',
  STANDALONE_SNIPPET_LABEL__P13N_ONLY: tr('Use Personalization only'),
  STANDALONE_SNIPPET_DESCRIPTION__P13N_ONLY: tr(
    'Runs only the Personalization snippet. Experiments created in \
                                                 Optimizely Testing will <strong>not be served and results will not \
                                                 be collected</strong>.',
  ),

  STANDALONE_SNIPPET_IDENTIFIER__AB_AND_P13N: 'ab_and_p13n',
  STANDALONE_SNIPPET_LABEL__AB_AND_P13N: tr('Use only Optimizely X'),
  STANDALONE_SNIPPET_DESCRIPTION__AB_AND_P13N: tr(
    'Runs only Optimizely X snippet. Experiments created in Classic \
                                                   Optimizely will <strong>not be served and results will not be \
                                                   collected</strong>.',
  ),
  V2_FIRST_RUN_TITLE__EXPERIMENTS: tr('Get started with Experiments'),
  V2_FIRST_RUN_SUBTITLE__EXPERIMENTS: tr(
    'Take your experimentation to the next level.',
  ),
  V2_FIRST_RUN_IMAGE__EXPERIMENTS: experimentsEmptyStateSVG,
  V2_FIRST_RUN_TITLE__P13N_CAMPAIGNS: tr(
    'Get started with Personalization Campaigns',
  ),
  V2_FIRST_RUN_SUBTITLE__P13N_CAMPAIGNS: tr(
    'Take your personalization to the next level.',
  ),
  V2_FIRST_RUN_IMAGE__P13N_CAMPAIGNS: attributeDashboardEmptyStateSVG,
};

import PropTypes from 'prop-types';
import React from 'react';

import { Button, Label, Table } from 'optimizely-oui';

import { toImmutable } from 'optly/immutable';
import { fieldPropType } from 'react_components/form';
import { constants as FeatureConstants } from 'optly/modules/entity/feature_flag';

import VariableTableRow from './subcomponents/variable_table_row';

const createAddVariableHandler = variablesField => () => {
  variablesField.pushChild(
    toImmutable({
      api_name: '',
      type: FeatureConstants.FEATURE_VARIABLE_TYPES.string,
      default_value: '',
    }),
  );
};

const FeatureVariables = ({ isDisabled, variablesField }) => {
  const showTable = variablesField.getValue().size > 0;
  return (
    <div className="lego-grid" data-test-section="feature-variables">
      <div className="soft-double--left width--1-1 push-quad--top">
        <h3>
          <Label>
            <span>Feature Configuration</span>
          </Label>
        </h3>
        <div className="push-double--bottom">
          <p>
            Parameterize your feature with variables you want to optimize. Set
            the values of these variables as part of a test.
          </p>
        </div>
        <form>
          {showTable && (
            <Table>
              <Table.THead>
                <Table.TR>
                  <Table.TH>
                    Variable Key
                    <span className="oui-label--required" />
                  </Table.TH>
                  <Table.TH>Type</Table.TH>
                  <Table.TH>Default Value</Table.TH>
                  <Table.TH />
                </Table.TR>
              </Table.THead>
              <Table.TBody>
                {variablesField.mapChildren((variableField, index) => {
                  if (variableField.getValue().get('archived')) {
                    return;
                  }
                  return (
                    <VariableTableRow
                      key={index}
                      index={index}
                      isDisabled={isDisabled}
                      variableField={variableField}
                    />
                  );
                })}
              </Table.TBody>
            </Table>
          )}
          <div className="push-double--top">
            <Button
              isDisabled={isDisabled}
              onClick={createAddVariableHandler(variablesField)}
              testSection="feature-dialog-add-variable"
              width="full">
              Add Variable
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

FeatureVariables.propTypes = {
  isDisabled: PropTypes.bool,
  variablesField: fieldPropType.isRequired,
};

FeatureVariables.defaultProps = {
  isDisabled: false,
};

export default FeatureVariables;

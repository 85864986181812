/**
 * @author Derek Hammond (derek@optimizely.com)
 */
export const directToExternalSupportPortal = [
  'helpCenter',
  'directToExternalSupportPortal',
];
export const showSchedulePhoneCall = ['helpCenter', 'showSchedulePhoneCall'];

export default {
  directToExternalSupportPortal,
  showSchedulePhoneCall,
};

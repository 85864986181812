import PropTypes from 'prop-types';
import React from 'react';

import { Input } from 'optimizely-oui';

import { connect } from 'core/ui/decorators';

import Immutable from 'optly/immutable';

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import {
  actions as HoldbackSelectorModuleActions,
  getters as HoldbackSelectorModuleGetters,
} from 'bundles/p13n/modules/holdback_selector';

const MAX_HOLDBACK = 99.99;
const MIN_HOLDBACK = 0;

@connect({
  currentLayer: CurrentLayerGetters.layer,
  formattedSelectedHoldback: HoldbackSelectorModuleGetters.formattedHoldback,
})
class HoldbackSelector extends React.Component {
  static propTypes = {
    currentLayer: PropTypes.instanceOf(Immutable.Map).isRequired,
    formattedSelectedHoldback: PropTypes.number.isRequired,
  };

  state = {
    error: null,
    holdback: this.props.formattedSelectedHoldback,
  };

  constructor(props) {
    super(props);

    const { currentLayer } = props;

    if (currentLayer && currentLayer.get('holdback')) {
      HoldbackSelectorModuleActions.setLayerHoldback(
        currentLayer.get('holdback'),
      );
    }

    this.validateHoldback();
    this.initialHoldback = props.formattedSelectedHoldback;
  }

  componentWillUnmount() {
    HoldbackSelectorModuleActions.clearLayerHoldback();
  }

  // If an external action changes the holdback value we want to make sure we respect it.
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.formattedSelectedHoldback !== this.state.holdback) {
      this.setState(
        () => ({
          holdback: nextProps.formattedSelectedHoldback,
        }),
        this.validateHoldback,
      );
    }
  }

  showWarning = () => {
    const { holdback } = this.state;

    return Number(holdback) === 0;
  };

  updateHoldback = event => {
    const newHoldback = Number(event.target.value);

    this.setState(
      () => ({
        holdback: newHoldback,
      }),
      () => {
        HoldbackSelectorModuleActions.setLayerHoldback(
          parseInt(newHoldback * 100, 10),
        );
        this.validateHoldback();
      },
    );
  };

  validateHoldback = () => {
    const { holdback } = this.state;
    const currentHoldback = Number(holdback);

    if (currentHoldback >= MAX_HOLDBACK || currentHoldback < MIN_HOLDBACK) {
      this.setState(
        () => ({
          error: `The campaign holdback must be a value between ${MIN_HOLDBACK} and ${MAX_HOLDBACK}.`,
        }),
        () => {
          HoldbackSelectorModuleActions.setLayerHoldbackInvalid(true);
        },
      );
    } else {
      this.setState(
        () => ({
          error: null,
        }),
        () => {
          HoldbackSelectorModuleActions.setLayerHoldbackInvalid(false);
        },
      );
    }
  };

  render() {
    const { error, holdback } = this.state;

    return (
      <fieldset data-test-section="layer-holdback-selector">
        <ol className="lego-form-fields">
          <li className="lego-form-field__item">
            <h3>Holdback Size</h3>
            <p>
              Choose the percentage of visitors who should see the original,
              unpersonalized experience. We recommend a 5% control to measure
              your campaign&apos;s impact.
            </p>
            {this.showWarning() && (
              <div
                className="lego-attention lego-attention--warning text--center push-double--bottom"
                data-test-section="selected-holdback-warning">
                This holdback is too small to measure results. We recommend a 5%
                holdback.
              </div>
            )}
            {error && (
              <div
                className="lego-attention lego-attention--bad-news text--center push-double--bottom"
                data-test-section="selected-holdback-error">
                {error}
              </div>
            )}
            <div className="flex flex--row flex-align--center">
              <div className="width--75 push--right">
                <Input
                  max={MAX_HOLDBACK}
                  min={MIN_HOLDBACK}
                  onChange={this.updateHoldback}
                  testSection="holdback-selector-input"
                  textAlign="right"
                  type="number"
                  value={holdback}
                  defaultValue={holdback}
                />
              </div>
              <div>%</div>
            </div>
          </li>
        </ol>
      </fieldset>
    );
  }
}

export default HoldbackSelector;

import flux from 'core/flux';

import BundleSplitHelper from 'optly/utils/bundle_split_helper';
import CurrentProject from 'optly/modules/current_project';

import RoutingFns from '../../routing_fns';

let LiveVariablesSection;

/*
 * Routing function that ensures the Oasis dynamic bundle has been fetched
 */
const EnsureOasisBundle = (ctx, next) => {
  BundleSplitHelper.getOasisBundleModules()
    .then(modules => {
      ({ LiveVariablesSection } = modules);
    })
    .then(next);
};

/*
 * Routes for the Live Variables section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/projects/:proj_id/live_variables',
    handle: [
      [
        EnsureOasisBundle,
        RoutingFns.loadingMainStart,
        RoutingFns.parseProjectId,
      ],
      (ctx, next) =>
        LiveVariablesSection.pages.variablesDashboard.routingSetup(ctx, next),
      ctx => {
        const isCustomProject = flux.evaluate(
          CurrentProject.getters.isCustomProject,
        );
        const isOTTProject = flux.evaluate(CurrentProject.getters.isOTTProject);
        const isXMobileProject = flux.evaluate(
          CurrentProject.getters.isCustomProject,
        );

        // redirect away unless we're using a mobile or OTT project
        if (isXMobileProject || isOTTProject) {
          RoutingFns.renderMainRegion(
            LiveVariablesSection.pages.variablesDashboard.component,
          );
        } else if (isCustomProject) {
          RoutingFns.redirectToCustomProjectHome(ctx);
        } else {
          RoutingFns.redirectToCurrentProjectLayers(ctx);
        }
      },
    ],
  },
];

export default routes;

const FIREBASE_PROJECT_ID = 'optimizely-b353b';

/**
 * Configuration for our firebase app used to support concurrent editing.
 * See the firebase console for more info:
 *   https://console.firebase.google.com/u/0/project/optimizely-b353b/settings/general/
 *
 * @type {{
 *   apiKey: string,
 *   authDomain: string,
 *   databaseURL: string,
 *   projectId: string,
 *   storageBucket: string,
 *   messagingSenderId: string
 * }}
 */
const firebaseConfig = {
  apiKey: 'AIzaSyAUXoF2-FMfTOuszf7fJMHSrKtpYTIpzkg',
  authDomain: `${FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: `${FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: '174340690338',
};

export default {
  firebaseConfig,
};

import $ from 'jquery';
/**
 * value directive that provides a 1-way data binding
 *
 * @author Cheston Lee
 */
export default function(val) {
  // cursor positioning logic inspired by v-model:
  // https://github.com/yyx990803/vue/blob/0.10.6/src/directives/model.js#L73-L83

  // try to get the cursor position from the element
  let cursorPos;
  const $el = $(this.el);
  try {
    cursorPos = this.el.selectionStart;
  } catch (e) {} // eslint-disable-line
  // set the value
  if ($el.is(':checkbox')) {
    this.el.checked = val;
  } else if ($el.is(':radio')) {
    const checked = this.el.value === val;
    $el.prop('checked', checked);
  } else {
    this.el.value = val;
  }
  // reset the cursor position
  if (cursorPos !== undefined) {
    try {
      this.el.setSelectionRange(cursorPos, cursorPos);
    } catch (e) {
      // handle
    }
  }
}

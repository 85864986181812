import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'dropzone';
import config from 'atomic-config';

import { Spinner, Label, Icon } from 'optimizely-oui';

import SupportEnums from 'optly/modules/support/enums';
import AjaxUtil from 'optly/utils/ajax';
import handleAjaxError from 'optly/utils/handle_ajax_error';

const csrfTokenValue = config.get('csrf', '');

export default class Attachments extends React.Component {
  static propTypes = {
    addUploadToken: PropTypes.func.isRequired,
    removeUploadToken: PropTypes.func.isRequired,
    uploadStart: PropTypes.func.isRequired,
    uploadFinished: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadingCount: 0,
      attachmentsNotWorkingError: false,
    };
  }

  incrementUploadingCount() {
    this.setState(prevState => ({
      uploadingCount: prevState.uploadingCount + 1,
    }));
  }

  decrementUploadingCount() {
    this.setState(prevState => ({
      uploadingCount: prevState.uploadingCount - 1,
    }));
  }

  componentDidMount() {
    this.loadDropzone();
  }

  loadDropzone = () => {
    // eslint disable below required due to design of Dropzone module
    // eslint-disable-next-line no-new
    new Dropzone('[data-dropzone="dropzone"]', {
      method: 'POST',
      url: '/support/submit/upload',
      previewTemplate: $('[data-dropzone="template"]').html(),
      maxFilesize: SupportEnums.DROPZONE.MAX_FILE_SIZE_ALLOWED,
      paramName: 'attachments',
      clickable: ['[data-dropzone="dropzone"]', '[data-dropzone="add-file"]'],
      headers: {
        'X-csrf-token': csrfTokenValue,
      },
      sending: () => {
        const { uploadStart } = this.props;
        uploadStart();
        this.incrementUploadingCount();
      },
      success: (file, response) => {
        const { addUploadToken, uploadFinished } = this.props;
        addUploadToken(response.upload.token);
        file.previewElement.setAttribute('upload_token', response.upload.token);
        uploadFinished();
        this.decrementUploadingCount();
      },
      error: (file, errorMessage, xhr) => {
        /**
         * Show FILE_TOO_BIG_ERROR if file is greater than MAX_FILE_SIZE_ALLOWED
         * otherwise, display message that attachment uploads are currently unavailable
         */
        const { uploadFinished } = this.props;
        const fileSizeError =
          typeof errorMessage === 'string' &&
          errorMessage.indexOf(
            SupportEnums.DROPZONE.MAX_FILE_SIZE_ERROR_SUBSTRING,
          ) !== -1;
        const fileTooLargeHttpStatusCode =
          xhr &&
          xhr.status === SupportEnums.DROPZONE.FILE_TOO_LARGE_HTTP_STATUS_CODE;
        if (fileSizeError || fileTooLargeHttpStatusCode) {
          const uploadElement = file.previewElement;
          $(uploadElement)
            .find('span.dz-error-message')
            .text(SupportEnums.DROPZONE.FILE_TOO_BIG_ERROR);
          $(uploadElement).css('color', 'red');
        } else {
          this.setState({ attachmentsNotWorkingError: true });
        }
        uploadFinished();
        this.decrementUploadingCount();
      },
      removedfile: file => {
        const { removeUploadToken } = this.props;
        const uploadElement = file.previewElement;
        uploadElement.parentNode.removeChild(uploadElement);
        const uploadToken = uploadElement.getAttribute('upload_token');
        removeUploadToken(uploadToken);
        AjaxUtil.makeV1AjaxRequest({
          url: `/support/submit/upload/delete?upload_id=${uploadToken}`,
          type: 'DELETE',
        }).fail(handleAjaxError(() => {}));
      },
      canceled: () => {
        const { uploadFinished } = this.props;
        uploadFinished();
        this.decrementUploadingCount();
      },
    });
  };

  render() {
    const { uploadingCount, attachmentsNotWorkingError } = this.state;
    return (
      <div className="push-double--top">
        {!attachmentsNotWorkingError && (
          <React.Fragment>
            <Label>Attachments</Label>
            <div
              data-dropzone="dropzone"
              className="border-dashed--all text--center soft">
              <span
                data-dropzone="add-file"
                className="flex flex-align--center flex-justified--center">
                {uploadingCount > 0 && <Spinner size="small" />}
                {uploadingCount === 0 && <Icon name="link" size="medium" />}
                <a className="push-half--sides">Add</a>
                <span className="muted weight--light">or drop files here</span>
              </span>
              <div
                data-dropzone="template"
                className="display--none dz-preview dz-file-preview">
                <div className="dz-details push-top oui-form-note">
                  <a className="dz-remove" data-dz-remove={true}>
                    [x]
                  </a>
                  <span className="dz-filename">
                    <span data-dz-name={true} />
                  </span>
                  <span>
                    (<span className="dz-size" data-dz-size={true} />)
                  </span>
                  <span className="dz-error-message">
                    <span data-dz-errormessage={true} />
                  </span>
                </div>
              </div>
            </div>
            <div className="oui-form-note">
              Include screenshots, recordings or other files to help our team
              better understand your request.
            </div>
          </React.Fragment>
        )}
        {attachmentsNotWorkingError && (
          <div className="oui-form-note">
            Attachment uploads are temporarily unavailable. Please reply back to
            the confirmation email with the attachment(s) or provide a download
            link in the issue description. We apologize for the inconvenience.
          </div>
        )}
      </div>
    );
  }
}

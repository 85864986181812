import fieldTypes from 'optly/modules/rest_api/field_types';

export default {
  entity: 'custom_audience_integrations',

  parent: {
    entity: 'projects',
    key: 'project_id',
  },

  fieldTypes: {
    id: fieldTypes.NUMBER,
    project_id: fieldTypes.NUMBER,
    name: fieldTypes.STRING,
    description: fieldTypes.STRING,
    plugin_type: fieldTypes.STRING,
    api_name: fieldTypes.STRING,
    is_enabled_in_client: fieldTypes.BOOLEAN,
    archived: fieldTypes.BOOLEAN,
    get_attribute_definitions: fieldTypes.STRING,
    get_attribute_values: fieldTypes.STRING,
    modified_secrets: fieldTypes.ARRAY,
    integration_secrets: fieldTypes.ARRAY,
  },
};

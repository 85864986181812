import React from 'react';
import { Feedback, Link } from 'optimizely-oui';

const CreateNewVariationFeedback = ({
  open,
  type,
  message,
  linkText,
  linkUrl,
}) => {
  return (
    <Feedback
      open={open}
      type={type}
      onClose={() => {}}
      data-test-section="create-new-variation-feedback">
      {message} {linkUrl && <Link href={linkUrl}>{linkText}</Link>}
    </Feedback>
  );
};
export default CreateNewVariationFeedback;

import _ from 'lodash';
import flux from 'core/flux';
import config from 'atomic-config';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerActions from 'optly/modules/entity/layer/actions';
import RestApiActionTypes from 'optly/modules/rest_api/action_types';
import { actions as LayerExperimentActions } from 'optly/modules/entity/layer_experiment';
import {
  entityDef as ProjectEntityDef,
  actions as ProjectActions,
} from 'optly/modules/entity/project';

import { ProjectStatusType } from 'optly/utils/enums';
import requester from 'optly/modules/rest_api/api/requester';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { getFeatureVariableString } from '@optimizely/js-sdk-lab/src/actions';

import getters from './getters';
import actionTypes from './action_types';

/**
 * Change the current project context
 */
export function setCurrentProjectId(projectId) {
  flux.dispatch(actionTypes.CHANGE_CURRENT_PROJECT_ID, {
    id: projectId,
  });
}

/**
 * Loads the project into the entity cache as well as sets the currentProjectId
 */
export function setCurrentProject(project) {
  flux.dispatch(RestApiActionTypes.API_INJECT_ENTITY, {
    entity: ProjectEntityDef.entity,
    data: project,
  });

  setCurrentProjectId(project.id);
}

/**
 * Fetches the current project from the server.  This is not the most accurate way
 * to do things because it just tries to find the first project that you have permission
 * to see that is ACTIVE
 *
 * This method should be used only if the current project context is not already known
 * and needs to be inferred
 *
 * @return {Deferred}
 */
export function fetchCurrentProject() {
  return ProjectActions.fetchAll().then(projects => {
    const currentProject = _.find(projects, {
      project_status: ProjectStatusType.ACTIVE,
    });

    return currentProject;
  });
}

/**
 * Fetches all Layer Experiments with a policy of SINGLE_EXPERIMENT or MULTIVARIATE for the current project,
 * not layer experiments that are targeting rules inside a rollout
 *
 * @param {object|undefined} filters
 * @param {Object|undefined} options An object with force and
 * skipEvaluatingCachedData boolean properties, or a boolean. Those properties
 * are used for the value of the force and skipEvaluatingCachedData options.
 *
 * When the force option is true, a model fetch is always performed and the cache is ignored.
 * When the skipEvaluatingCachedData option is true, the returned deferred
 * will be resolved with null  (instead of the requested data) for cached
 * requests.
 * @return {Deferred}
 */
export function fetchLayerExperimentsWithSingleAndMvtPolicies(
  filters = {},
  options = {},
) {
  const projectId = flux.evaluateToJS(getters.id);
  const mergedFilters = Object.assign(filters, {
    project_id: projectId,
    layer_policy: [
      LayerEnums.policy.MULTIVARIATE,
      LayerEnums.policy.SINGLE_EXPERIMENT,
    ],
  });
  return LayerExperimentActions.fetchAll(mergedFilters, options);
}

/**
 * Fetches all Layers for the current project
 *
 * @param {object|undefined} filters
 * @param {Object|undefined} options An object with force and
 * skipEvaluatingCachedData boolean properties, or a boolean. Those properties
 * are used for the value of the force and skipEvaluatingCachedData options.
 *
 * When the force option is true, a model fetch is always performed and the cache is ignored.
 * When the skipEvaluatingCachedData option is true, the returned deferred
 * will be resolved with null  (instead of the requested data) for cached
 * requests.
 * @return {Deferred}
 */
export function fetchCurrentProjectLayers(filters = {}, options = {}) {
  const projectId = flux.evaluateToJS(getters.id);
  const mergedFilters = Object.assign(filters, {
    project_id: projectId,
  });
  return LayerActions.fetchAll(mergedFilters, options);
}

/**
 * Sets the last viewed project id in cookie
 * @param {Number} id
 */
export function setLastViewedProjectId(id) {
  const accountInfo = flux.evaluateToJS(AdminAccountGetters.accountInfo);

  if (config.get('is_impersonating', false)) {
    return;
  }

  return requester()
    .one('users', encodeURIComponent(accountInfo.email))
    .all('preferences')
    .put({ last_viewed_project_id: id });
}

/**
 * Get help copy or link value based on OoO feature variable value for current project type
 * @param {String} variable
 * @param {String|Undefined} [defaultVal] - can be undefined, resulting in a falsy return value if variable is not found
 *
 * @returns {String|Undefined} undefined will only be return if variable does not exist and defaultVal is missing or undefined
 */
export function getHelpCopy(variable, defaultVal) {
  return getFeatureVariableString('help_copy', variable) || defaultVal;
}

export default {
  fetchCurrentProject,
  fetchLayerExperimentsWithSingleAndMvtPolicies,
  fetchCurrentProjectLayers,
  setCurrentProject,
  setCurrentProjectId,
  setLastViewedProjectId,
  getHelpCopy,
};

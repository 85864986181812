import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import constants from '../constants';

import actionTypes from '../action_types';

const initialState = toImmutable({
  currentlyEditingGroup: {},
  errorFlags: {
    [constants.ErrorFlag.FULL_STACK_RUNNING_EXPERIMENT_UPDATE]: false,
  },
});

export default Nuclear.Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.FLAG_ERROR, flagError);
    this.on(actionTypes.RESET_ERROR_FLAGS, resetErrorFlags);
    this.on(actionTypes.SET_GROUP, setGroup);
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Boolean} payload.error
 */
function flagError(state, { type, error }) {
  return state.setIn(['errorFlags', type], error);
}

/**
 * @param {Immutable.Map} state
 */
function resetErrorFlags(state) {
  return state.set('errorFlags', initialState.get('errorFlags'));
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Immutable.Map} payload.group
 */
function setGroup(state, { group }) {
  return state.set('currentlyEditingGroup', group);
}

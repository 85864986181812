import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { Button, Input, Icon } from 'optimizely-oui';

import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';

import Immutable, { toImmutable } from 'optly/immutable';

import filter from 'optly/utils/filter';
import truncate from 'optly/filters/truncate';

const BlockListCategory = ({ children, testSection, url }) => {
  let categoryLink;
  if (url) {
    categoryLink = (
      <a
        href={url}
        className="truncate max-width--300"
        title={url}
        data-test-section={`block-list-category-link-${testSection}`}>
        {url}
      </a>
    );
  }
  return (
    <div
      className="drop-filter-list__category soft--sides soft-half--ends border--ends"
      data-test-section={`block-list-category-${testSection}`}>
      <span className="flex--1">{children}</span>
      {categoryLink}
    </div>
  );
};

BlockListCategory.propTypes = {
  /** Content to show in the main area */
  children: PropTypes.node,
  /** Identifier used to create data-test-section attributes for testing */
  testSection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** URL used to show a link on the right side (not shown if URL omitted) */
  url: PropTypes.string,
};

BlockListCategory.defaultProps = {
  testSection: '',
};

class BlockListItem extends React.Component {
  static propTypes = {
    /** Optional category to display to the right of the primary action */
    category: PropTypes.string,
    /** Content to show in the main area */
    children: PropTypes.node.isRequired,
    /**
     * The type of entity being displayed in the list. Only required if we
     * need to be able to select the list items using a generic test section.
     */
    entityName: PropTypes.string,
    /** If true, bold styling applied to anchor elements */
    isBold: PropTypes.bool,
    /** Secondary content to show below the children content */
    itemDescription: PropTypes.string,
    /**
     * Identifies item in parent data associated with this component. Not
     * provided for primary action
     */
    itemIndex: PropTypes.number,
    /** Click event handler for the main area */
    itemOnClick: PropTypes.func,
    /**
     * Click event handler for secondary button on the right side, which is
     * shown when both itemSecondaryOnClick and itemSecondaryText props are
     * provided
     */
    itemSecondaryOnClick: PropTypes.func,
    /**
     * Text for secondary button on the right side, which is shown when both
     * itemSecondaryOnClick and itemSecondaryText props are provided
     */
    itemSecondaryText: PropTypes.string,
    /**
     * Identifies item in parent data associated with this component. Not
     * provided for primary action
     */
    listIndex: PropTypes.number,
    /** Identifier used to create data-test-section attributes for testing */
    testSection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    isBold: false,
    testSection: '',
  };

  itemOnClick = clickEvt => {
    const { itemIndex, itemOnClick, listIndex } = this.props;
    itemOnClick(clickEvt, listIndex, itemIndex);
  };

  itemSecondaryOnClick = clickEvt => {
    clickEvt.stopPropagation();
    const { itemIndex, itemSecondaryOnClick, listIndex } = this.props;
    itemSecondaryOnClick(clickEvt, listIndex, itemIndex);
  };

  render() {
    const {
      category,
      children,
      itemDescription,
      entityName,
      isBold,
      itemSecondaryOnClick,
      itemSecondaryText,
      testSection,
    } = this.props;
    let secondaryAction;
    const anchorClasses = classNames(
      'flex-align--center',
      'lego-dropdown__block-link',
      'flex',
      'soft--sides',
      'soft--half-ends',
      'flush',
      'flex-align--center',
      {
        'weight--bold': isBold,
      },
    );

    if (itemSecondaryOnClick && itemSecondaryText) {
      secondaryAction = (
        <Button
          testSection={`block-list-item-secondary-action-${testSection}`}
          onClick={this.itemSecondaryOnClick}
          size="tiny"
          style="outline">
          {itemSecondaryText}
        </Button>
      );
    } else if (category) {
      secondaryAction = (
        <div className="muted micro width--1-3 force-break" title={category}>
          {truncate(category, 60)}
        </div>
      );
    }

    return (
      <a
        data-test-section={`block-list-item-${testSection}`}
        className={anchorClasses}
        onClick={this.itemOnClick}>
        <div data-test-section={`unselected-${entityName}-add-button`}>
          <Icon
            className="lego-icon push--right vertical-align--text-top"
            color={brandBlueDark}
            name="plus"
            size="small"
          />
        </div>
        <div className="flex--1 soft--right">
          {children}

          <div className="micro muted">{itemDescription}</div>
        </div>
        {secondaryAction}
      </a>
    );
  }
}

const BLOCK_LIST_PROP_TYPES = {
  /**
   * An Immutable List of Maps, describing the items in the filter list.
   * Each Map represents a sub-section of the filter list with a header
   * and a number of filterable items.
   * The Maps have these properties:
   *  key:
   *    Used as React element key for the list's element
   *  items:
   *    Immutable List of Maps with 'name', 'description', and 'id' properties,
   *    used to render the main and secondary content area in the item rows
   *  url:
   *    If provided, used for the right-side URL in the list category header
   *  label:
   *    If provided, used for the main content in the list category header
   */
  lists: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.List),
    PropTypes.instanceOf(Immutable.Map),
  ]).isRequired,
  /**
   * The type of entity being displayed in the list. Only required if we
   * need to be able to select the list items using a generic test section.
   */
  entityName: PropTypes.string,
  /**
   * Click event handler for the primary action row, which is shown when both
   * primaryActionOnClick and primaryActionText props are provided
   */
  primaryActionOnClick: PropTypes.func,
  /**
   * Text in the primary action row, which is shown when both
   * primaryActionOnClick and primaryActionText props are provided
   */
  primaryActionText: PropTypes.string,
  /**
   * Click event handler for list items. Will be passed the click event, and
   * the corresponding item object
   */
  itemOnClick: PropTypes.func,
  /**
   * Click event handler for item secondary buttons, which are shown when
   * both itemSecondaryOnClick and itemSecondaryText props are provided. Will be
   * passed the click event and the corresponding item object.
   */
  itemSecondaryOnClick: PropTypes.func,
  /**
   * Text for item secondary buttons, which are shown when both
   * itemSecondaryOnClick and itemSecondaryText props are provided
   */
  itemSecondaryText: PropTypes.string,
  /**
   * Controls whether borders appear on all sides of the blocklist or just the bottom,
   * particularly helpful if a blocklist is nested.
   */
  bottomBorderOnly: PropTypes.bool,
  /**
   * Removes the maxHeight property from a blocklist. Great for when a blocklist is nested
   * inside another component that has a max height, prevents multiple scroll bars.
   */
  noMaxHeight: PropTypes.bool,
  /**
   * Identifies the items passed as a list of lists which should be treated as subcategories
   */
  isSubcategory: PropTypes.bool,
  /**
   * Determines whether or not the they key should be shown in addition to the name
   */
  showNameAndKey: PropTypes.bool,
  /**
   * Allows a list to avoid being filtered, useful for cases where an option always has to be present
   */
  skipFilter: PropTypes.bool,
};

const BLOCK_LIST_SUBCATEGORY_PROP_TYPES = {
  /**
   * The individual list which can be collapsed
   */
  list: PropTypes.instanceOf(Immutable.Map),
  /**
   * Click event handler for the primary action row, which is shown when both
   * primaryActionOnClick and primaryActionText props are provided
   */
  primaryActionOnClick: PropTypes.func,
  /**
   * Text in the primary action row, which is shown when both
   * primaryActionOnClick and primaryActionText props are provided
   */
  primaryActionText: PropTypes.string,
  /**
   * Click event handler for list items. Will be passed the click event, and
   * the corresponding item object
   */
  itemOnClick: PropTypes.func,
  /**
   * Click event handler for item secondary buttons, which are shown when
   * both itemSecondaryOnClick and itemSecondaryText props are provided. Will be
   * passed the click event and the corresponding item object.
   */
  itemSecondaryOnClick: PropTypes.func,
  /**
   * Text for item secondary buttons, which are shown when both
   * itemSecondaryOnClick and itemSecondaryText props are provided
   */
  itemSecondaryText: PropTypes.string,
  /**
   * Controls whether borders appear on all sides of the blocklist or just the bottom,
   * particularly helpful if a blocklist is nested.
   */
  bottomBorderOnly: PropTypes.bool,
  /**
   * Removes the maxHeight property from a blocklist. Great for when a blocklist is nested
   * inside another component that has a max height, prevents multiple scroll bars.
   */
  noMaxHeight: PropTypes.bool,
  /**
   * Determines whether or not the subcategory is collapsible within the blocklist
   */
  isCollapsible: PropTypes.bool,
  /**
   * Determines whether or not the they key should be shown in addition to the name
   */
  showNameAndKey: PropTypes.bool,
};

class BlockList extends React.Component {
  static propTypes = BLOCK_LIST_PROP_TYPES;

  static defaultProps = {
    bottomBorderOnly: false,
    isSubcategory: false,
    showNameAndKey: false,
  };

  itemOnClick = (clickEvt, listIndex, itemIndex) => {
    const { itemOnClick, lists } = this.props;
    if (itemOnClick) {
      itemOnClick(clickEvt, lists.getIn([listIndex, 'items', itemIndex]));
    }
  };

  itemSecondaryOnClick = (clickEvt, listIndex, itemIndex) => {
    const { itemSecondaryOnClick, lists } = this.props;
    if (itemSecondaryOnClick) {
      itemSecondaryOnClick(
        clickEvt,
        lists.getIn([listIndex, 'items', itemIndex]),
      );
    }
  };

  render() {
    const {
      bottomBorderOnly,
      entityName,
      itemOnClick,
      itemSecondaryOnClick,
      itemSecondaryText,
      lists,
      noMaxHeight,
      primaryActionOnClick,
      primaryActionText,
      showNameAndKey,
    } = this.props;

    let primaryAction;

    if (primaryActionOnClick && primaryActionText) {
      primaryAction = (
        <BlockListItem
          testSection="primary-action"
          itemOnClick={primaryActionOnClick}
          isBold={true}>
          {primaryActionText}
        </BlockListItem>
      );
    }

    const style = {};

    if (noMaxHeight) {
      style.maxHeight = 'none';
    }

    return (
      <div
        className={classNames({
          'drop-filter-list': true,
          'border--all': !bottomBorderOnly,
          'border--bottom': bottomBorderOnly,
        })}
        style={style}>
        {primaryAction}

        {lists.map((list, listIndex) => (
          <div
            data-test-section={`block-list-block-${list.get('key')}`}
            key={list.get('key')}>
            {list.get('label') && (
              <BlockListCategory url={list.get('url')} testSection={listIndex}>
                {list.get('label')}
              </BlockListCategory>
            )}
            {list.get('items').map((item, itemIndex) => {
              if (item.get('isSubcategory')) {
                return (
                  <BlockListSubcategory
                    list={item}
                    key={item.get('key')}
                    primaryActionOnClick={primaryActionOnClick}
                    primaryActionText={primaryActionText}
                    isCollapsible={true}
                    itemOnClick={itemOnClick}
                    itemSecondaryOnClick={itemSecondaryOnClick}
                    itemSecondaryText={itemSecondaryText}
                    showNameAndKey={showNameAndKey}
                  />
                );
              }

              return (
                <BlockListItem
                  category={item.get('category')}
                  testSection={item.get('id')}
                  entityName={entityName}
                  key={item.get('id')}
                  listIndex={listIndex}
                  isBold={item.get('isBold')}
                  itemIndex={itemIndex}
                  itemDescription={item.get('description')}
                  itemOnClick={this.itemOnClick}
                  itemSecondaryOnClick={this.itemSecondaryOnClick}
                  itemSecondaryText={itemSecondaryText}>
                  {item.get('name')}
                  {showNameAndKey ? (
                    <div
                      className="zeta monospace muted micro word-break--all"
                      data-test-section={`unselected-${entityName}-key-${item.get(
                        'key',
                      )}`}
                      style={{ marginTop: '-1px' }}>
                      {item.get('key')}
                    </div>
                  ) : null}
                </BlockListItem>
              );
            })}
          </div>
        ))}
      </div>
    );
  }
}

class BlockListSubcategory extends React.Component {
  static propTypes = BLOCK_LIST_SUBCATEGORY_PROP_TYPES;

  state = {
    isExpanded: false,
  };

  toggleDisclose = clickEvent => {
    // Need to prevent default here because otherwise it will close some dialogs.
    clickEvent.preventDefault();
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  render() {
    const {
      isCollapsible,
      itemOnClick,
      itemSecondaryOnClick,
      itemSecondaryText,
      list,
      primaryActionOnClick,
      primaryActionText,
      showNameAndKey,
    } = this.props;

    const { isExpanded } = this.state;

    const classes = {};

    if (isCollapsible) {
      classes['is-active'] = isExpanded;
      classes['lego-disclose'] = true;
    }

    const noLabelList = list.set('label', null);
    const lists = toImmutable([noLabelList]);

    const content = (
      <BlockList
        lists={lists}
        primaryActionOnClick={primaryActionOnClick}
        primaryActionText={primaryActionText}
        itemOnClick={itemOnClick}
        itemSecondaryOnClick={itemSecondaryOnClick}
        itemSecondaryText={itemSecondaryText}
        bottomBorderOnly={true}
        noMaxHeight={true}
        showNameAndKey={showNameAndKey}
      />
    );

    return (
      <div>
        <div className={classNames(classes)}>
          {isCollapsible && (
            <div>
              <a
                href="#"
                className="lego-disclose__link link--dark push--left push--top"
                onClick={this.toggleDisclose}>
                <span className="lego-disclose__symbol push--right">
                  <svg height="14" width="14">
                    <use
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref="#chevron-right"
                    />
                  </svg>
                </span>
                {list.get('label')}
              </a>
              <div className="lego-disclose__content">{content}</div>
            </div>
          )}
          {!isCollapsible && (
            <div>
              <div className="push--left push--top">{list.get('label')}</div>
              {content}
            </div>
          )}
        </div>
      </div>
    );
  }
}

class FilterList extends React.Component {
  static propTypes = _.extend({}, BLOCK_LIST_PROP_TYPES, {
    /**
     * Placeholder for the filter input
     */
    inputPlaceholder: PropTypes.string,

    isDisabled: PropTypes.bool,

    showNameAndKey: PropTypes.bool,

    testSection: PropTypes.string,
  });

  static defaultProps = {
    isDisabled: false,
    showNameAndKey: false,
  };

  state = {
    query: '',
  };

  setQuery = event => {
    this.setState({
      query: event.target.value,
    });
  };

  primaryActionOnClick = clickEvt => {
    const { primaryActionOnClick } = this.props;
    primaryActionOnClick(clickEvt);
  };

  render() {
    const {
      inputPlaceholder,
      isDisabled,
      itemOnClick,
      itemSecondaryOnClick,
      itemSecondaryText,
      lists,
      entityName,
      primaryActionText,
      showNameAndKey,
      testSection,
    } = this.props;

    const { query } = this.state;

    const filteredLists = lists.map(list => {
      if (list.get('skipFilter')) {
        return list;
      }

      return list.update('items', items =>
        items
          .map(item => {
            if (item.get('isSubcategory')) {
              return item.update('items', nestedItems =>
                nestedItems.filter(
                  nestedItem =>
                    filter.isFilterTermInItem(
                      query,
                      `${nestedItem.get('name')} ${nestedItem.get(
                        'description',
                        '',
                      )} ${nestedItem.get('category', '')}`,
                    ) || nestedItem.get('skipFilter'),
                ),
              );
            }
            return item;
          })
          .filter(item => {
            if (!item.get('isSubcategory')) {
              return (
                filter.isFilterTermInItem(
                  query,
                  `${item.get('name')} ${item.get(
                    'description',
                    '',
                  )} ${item.get('category', '')}`,
                ) || item.get('skipFilter')
              );
            }
            return item.get('items').size;
          }),
      );
    });
    return (
      <div data-ui-component={true} data-test-section={testSection}>
        <Input
          testSection="block-list-input"
          type="search"
          isFilter={true}
          isDisabled={isDisabled}
          placeholder={inputPlaceholder}
          onInput={this.setQuery}
        />
        {!isDisabled && (
          <BlockList
            entityName={entityName}
            lists={filteredLists}
            primaryActionOnClick={this.primaryActionOnClick}
            primaryActionText={primaryActionText}
            itemOnClick={itemOnClick}
            itemSecondaryOnClick={itemSecondaryOnClick}
            itemSecondaryText={itemSecondaryText}
            showNameAndKey={showNameAndKey}
          />
        )}
      </div>
    );
  }
}

export default FilterList;

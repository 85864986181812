import fieldTypes from 'optly/modules/rest_api/field_types';

/**
 * Entity Definition for experiment
 */
export default {
  entity: 'experiments',

  parent: {
    entity: 'projects',
    key: 'project_id',
  },

  field: {
    id: null,
    status: null,
    audience_ids: null,
    enabled_integration_ids: null,
    variation_ids: null,
    earliest: null,
  },

  // define array field types to allow _.contains filtering
  fieldTypes: {
    status: fieldTypes.STRING,
    audience_ids: fieldTypes.ARRAY,
    enabled_integration_ids: fieldTypes.ARRAY,
    variation_ids: fieldTypes.ARRAY,
    earliest: fieldTypes.DATE,
  },

  serialize(experiment) {
    if (Array.isArray(experiment.display_goal_order_lst)) {
      experiment.display_goal_order_lst = experiment.display_goal_order_lst.map(
        String,
      );
    }
    return experiment;
  },

  deserialize(experiment) {
    if (Array.isArray(experiment.display_goal_order_lst)) {
      experiment.display_goal_order_lst = experiment.display_goal_order_lst.map(
        goalId => parseInt(goalId, 10),
      );
    }
    return experiment;
  },
};

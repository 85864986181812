import { toImmutable } from 'optly/immutable';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import { getters as LayerExperimentGetters } from 'optly/modules/entity/layer_experiment';
import { getters as FeatureGetters } from 'optly/modules/entity/feature_flag';
import PermissionsGetters from 'optly/modules/permissions/getters';
import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';
import fns from './fns';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

/*
 * Provides result of getEnvironmentStatusesByExperimentId function based on
 * whether the current project can use environments, the layer experiment entity
 * cache, the environments entity cache, and the current project
 */
export const environmentStatusesByExperimentId = [
  CurrentProjectGetters.environmentsAuthorizedAndSupported,
  LayerExperimentGetters.entityCache,
  entityCache,
  CurrentProjectGetters.project,
  fns.getEnvironmentStatusesByExperimentId,
];

/*
 * Provides result of getEnvironmentStatusesByFeatureId function based on
 * whether the current project can use environments, the layer experiment entity
 * cache, the environments entity cache, and the current project
 */
export const environmentStatusesByFeatureId = [
  CurrentProjectGetters.environmentsAuthorizedAndSupported,
  FeatureGetters.entityCache,
  entityCache,
  CurrentProjectGetters.project,
  PermissionsGetters.canCurrentProjectUseTargetedRollouts,
  fns.getEnvironmentStatusesByFeatureId,
];

export const unarchivedEnvironmentsSortedByPriority = [
  CurrentProjectGetters.id,
  entityCache,
  (currentProjectId, environments) => {
    if (currentProjectId === null) {
      return toImmutable([]);
    }
    return environments
      .filter(
        env =>
          env.get('project_id') === currentProjectId && !env.get('archived'),
      )
      .toList()
      .sortBy(env => env.get('priority'));
  },
];

export const byKey = [
  entityCache,
  envs =>
    envs.reduce(
      (byKeyMap, env) => byKeyMap.set(env.get('key'), env),
      toImmutable({}),
    ),
];

export default {
  ...baseEntityGetters,
  byKey,
  environmentStatusesByExperimentId,
  environmentStatusesByFeatureId,
  unarchivedEnvironmentsSortedByPriority,
};

import { toImmutable } from 'optly/immutable';
import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

export const clientIdToClientMap = [
  baseEntityGetters.entityCache,
  authorizedClients =>
    toImmutable({}).withMutations(clientIdToClientMap => {
      authorizedClients.forEach(authorizedClient => {
        clientIdToClientMap.set(authorizedClient.get('id'), authorizedClient);
      });
    }),
];

export default {
  ...baseEntityGetters,
  clientIdToClientMap,
};

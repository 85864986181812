import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import { withTrack } from '@optimizely/segment-js/dist/decorators';

import LoadingOverlay from 'react_components/loading_overlay';

import { connect } from 'core/ui/decorators';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import Immutable from 'optly/immutable';
import NavConstants from 'optly/services/navigation';

import DataLayerTopbar from 'bundles/p13n/components/data_layer_topbar';

/**
 * Load this Event Inspector component on-demand as it comes from another SCS.
 *
 * @type {React.LazyExoticComponent<function(*=): *>}
 */
const EventInspector = React.lazy(() =>
  System.import('EVENT_INSPECTOR').then(EVENT_INSPECTOR =>
    EVENT_INSPECTOR.loadRootComponent(),
  ),
);

@withTrack
@connect({
  experimentsAndVariationsData: [
    CurrentProjectGetters.currentProjectActiveEvents,
    CurrentProjectGetters.layerExperiments,
    (events, layerExperiments) => {
      /**
       * Need to extract key/name for layer experiment and api_name/name for variations and events.
       * Example: { event_id : "event_name" }
       *          { layer_experiment_id : "layer_experiment_name" }
       *          { variation_id : "variation_name" }
       */
      let dataMap = events.map(
        // Events: Use "api_name" for FullStack and "name" for Web.
        event => ({
          id: event.get('id'),
          key: event.get('api_name') || event.get('name'),
          type: 'event',
        }),
      );

      layerExperiments.forEach(layerExperiment => {
        // Layer Experiments: Use "key" for FullStack and "name" for Web.
        dataMap = dataMap.set(layerExperiment.get('id'), {
          id: layerExperiment.get('id'),
          key: layerExperiment.get('key') || layerExperiment.get('name'),
          type: 'experiment',
        });

        layerExperiment.get('variations').forEach(variation => {
          // Variations: Use "api_name" for FullStack and "name" for Web.
          dataMap = dataMap.set(variation.get('variation_id'), {
            id: variation.get('variation_id'),
            key: variation.get('api_name') || variation.get('name'),
            type: 'variation',
          });
        });
      });
      return dataMap;
    },
  ],
  projectId: CurrentProjectGetters.id,
})
class EventInspectorDashboard extends React.Component {
  static componentId = 'EventInspectorDashboard';

  static displayName = 'EventInspectorDashboard';

  static propTypes = {
    experimentsAndVariationsData: PropTypes.instanceOf(Immutable.Map)
      .isRequired,
    projectId: PropTypes.number.isRequired,
    track: PropTypes.func,
  };

  static defaultProps = {
    track: () => {},
  };

  render() {
    const { experimentsAndVariationsData, projectId, track } = this.props;

    return (
      <div
        className="flex flex--1 flex--column"
        data-test-section="event-inspector-main-content">
        <DataLayerTopbar activeTab={NavConstants.DataLayerTabs.INSPECTOR_TAB} />
        <div
          className="stage__item__content--column position--relative"
          data-test-section="event-inspector">
          <Suspense
            fallback={
              <LoadingOverlay
                isLoading={true}
                data-test-section="loading-event-inspector"
              />
            }>
            <EventInspector
              experimentsAndVariationsData={experimentsAndVariationsData}
              projectId={projectId}
              track={track} // to use SegmentTracking in the SCS.
            />
          </Suspense>
        </div>
      </div>
    );
  }
}

export default EventInspectorDashboard;

import _ from 'lodash';

/**
 * Entity Definition for plugin
 *
 * @type {Plugin}
 * @property {Number} id (GAE ID)
 * @property {Number} project_id
 * @property {Number} is_enabled_in_client
 * @property {String} plugin_id id for the particular plugin within a project
 * @property {String} name
 * @property {String} description
 * @property {String} pluginType the type of the plugin, e.g. Plugin or panel
 * @property {Array} form_schema holds the schema for the form inputs available in the editor
 * @property {Object} options object of all options for the plugin
 * @property {String} edit_page_url url for the page that should be loaded in the plugin builder
 * @property {String} created
 * @property {String} last_modified
 */

export const entity = 'plugins';

export const parent = {
  entity: 'projects',
  key: 'project_id',
};

export const deserialize = data => {
  // TODO(Matt): This is necessary until we run a data migration to transfer
  // 'default', a string property, into 'default_values', a JSON property
  data.form_schema = _.map(data.form_schema, field => {
    if (field.default_value === undefined || field.default_value === null) {
      field.default_value = field.default;
    }
    delete field.default;
    return field;
  });
  return data;
};

export default {
  entity,
  parent,
  deserialize,
};

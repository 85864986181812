/**
 * LEGO disabled directive
 * Shortcut to add the disabled attribute and the `lego-button--disabled` class
 *
 * @author Andrew Delikat (andrew@optimizely.com)
 */
import $ from 'jquery';

export default function(disabled) {
  const $el = $(this.el);

  $el.attr('disabled', disabled).toggleClass('lego-button--disabled', disabled);
}

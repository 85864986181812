import ui from 'core/ui';
import React from 'react';
import { Button, DialogNew } from 'optimizely-oui';

const WarnLastMetricDialog = () => (
  <DialogNew
    onClose={ui.hideDialog}
    footerButtonList={[
      <Button key="close" style="plain" onClick={ui.hideDialog}>
        Close
      </Button>,
    ]}
    title="Metric Required">
    <p>
      Your campaign must have at least one metric. Add an additional metric
      before disabling click tracking on this element.
    </p>
  </DialogNew>
);

export default WarnLastMetricDialog;

/**
 * Interface to expose information & functionality to the app.optimizely.com Optimizely snippet
 * It would be inappropriate to rely on this API for internal usage.
 *
 * NOTICE: this API should not change.  It is relied upon by running Optimizely experiments and
 * simply grepping for it's usage in our codebase is not enough to gaurantee that a function is unused.
 *
 * @author Jordan Garcia
 */

/**
 * TODO(derek@optimizely): Deprecate this for use in Optimizely X in favor of optly/optly_app_api
 * - Currently, the preferred optly/optly_app_api/utilities module is only added to Optimizely X
 * - Before this file is completely dedprecated, we need to migrate all usage to third party js
 *   on Optimizely X (or add it to the Classic bundle) as well as notify several Optimizely on Optimizely
 */
import eventsService from 'optly/services/events';
import flux from 'core/flux';
import ui from 'core/ui';

import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { actions as SupportActions } from 'optly/modules/support';
import EmptyCTADialog from 'optly/components/dialogs/empty_cta';

/**
 * If this function is being called in Optimizely X, console.warn and provide a usage example for the updated method
 * @param {String} updatedMethodUsageExample - example: 'optlyApp.getUtilities().track.trackMarketo(mktoFunctionName, leadInfo, token)'
 */
const warnAndSuggestAlternative = function(updatedMethodUsageExample) {
  console.warn('WARNING - The optlyTesting global will soon be deprecated in favor of the optlyApp API');  //eslint-disable-line
  console.warn(`WARNING - Please migrate usage of this function to ${updatedMethodUsageExample}`);  //eslint-disable-line
  console.warn('WARNING - Learn more about the updated optlyApp API at go/optlyAppAPI');  //eslint-disable-line
};

/**
 * Gets information about the current user
 * @return {Object}
 */
export function getUser() {
  warnAndSuggestAlternative('optlyApp.getUtilities().userInfo.getUser()');
  return {
    email: flux.evaluate(AdminAccountGetters.email),
    accountId: flux.evaluate(AdminAccountGetters.id),
    projectId: flux.evaluate(CurrentProjectGetters.id),
    planId: flux.evaluate(AdminAccountGetters.planId),
    userLocale: flux.evaluate(AdminAccountGetters.userLocale),
  };
}

/**
 * @param {Object} opts
 * @param {String} opts.template
 * @param {Function?} opts.ready
 */
export function showDialog(opts) {
  warnAndSuggestAlternative('optlyApp.getUtilities().ui.showDialog(options)');
  if (!ui.getRootVM()) {
    console.warn('[optlyTesting] cannot show dialog until page has loaded'); // eslint-disable-line
  }
  if (!opts.template) {
    console.warn('show dialog must be supplied a `template` property'); // eslint-disable-line
  }

  const componentConfig = {
    template: opts.template,
  };

  if (opts.ready) {
    componentConfig.ready = opts.ready;
  }

  ui.showDialog({
    component: componentConfig,
  });
}

/**
 * @param {Object} opts
 * @param {String} opts.body
 * @param {String?} opts.title
 * @param {String?} opts.submitText
 * @param {String?} opts.cancelText
 * @param {Function} opts.onSubmit
 * @param {Function?} opts.onCancel
 * @param {String?} opts.size 'large' or 'small' defaults to 'normal'
 */
export function showCTADialog(opts) {
  warnAndSuggestAlternative(
    'optlyApp.getUtilities().ui.showCTADialog(options)',
  );
  ui.showReactDialog(
    EmptyCTADialog,
    {
      props: {
        title: opts.title,
        body: opts.body,
        onSubmit: opts.onSubmit,
        onCancel: opts.onCancel,
      },
    },
    {
      noEscape: !!opts.noEscape,
    },
  );
}

/**
 * Track the event in Marketo
 *
 * @param {String} mktoFunctionName
 * @param {Object} leadInfo
 * @param {String} token
 */
export function trackMarketo(mktoFunctionName, leadInfo, token) {
  // Note: This warning will occur twice due to the use of getUser
  warnAndSuggestAlternative(
    'optlyApp.getUtilities().track.trackMarketo(mktoFunctionName, leadInfo, token)',
  );
  const info = Object.assign(
    getUser(),
    {
      url: window.location.pathname,
    },
    leadInfo,
  );
  eventsService.trackMarketoLead(mktoFunctionName, info, token);
}

/**
 * Checks if the user has permissions for a certain feature
 * @param {String} feature
 * @return {Boolean}
 */
export function hasPermission(feature) {
  warnAndSuggestAlternative(
    'optlyApp.getUtilities().userInfo.hasPermission(feature)',
  );
  return flux.evaluate([
    AdminAccountGetters.accountPermissions,
    CurrentProjectGetters.permissions,
    function(accountPerms, projectPerms) {
      // extra safegaurds around permission checking
      try {
        return accountPerms.contains(feature) || projectPerms.contains(feature);
      } catch (e) {
        return false;
      }
    },
  ]);
}

/**
 * Shows the support_dialog component.
 * @param {Object} data
 */
export function showSupportDialog(version) {
  warnAndSuggestAlternative(
    'optlyApp.getUtilities().ui.showSupportDialog(version)',
  );
  SupportActions.showSupportDialog(version === 'v2');
}

export default {
  getUser,
  showDialog,
  showCTADialog,
  trackMarketo,
  hasPermission,
  showSupportDialog,
};

/**
 * @author Tyler Brandt (tyler@optimizely.com)
 */
const Ratio = require('optly/utils/ratio');
// how many total points there are
const TOTAL_POINTS = 10000;
// what we multiply percent by to get points
const MULTIPLIER = TOTAL_POINTS / 100;
// log base 10 of multiplier is max precision, make sure it's an int
const MAX_PRECISION = Math.floor(Math.log(MULTIPLIER) / Math.log(10));

module.exports = {
  MAX_PRECISION,
  MULTIPLIER,
  TOTAL_POINTS,
  /**
   * .xxxx -> xxxx points
   * @param {number} ratio
   * @returns {number}
   */
  toPoints(ratio) {
    return Math.round(ratio * TOTAL_POINTS);
  },
  /**
   * Return points as a Ratio out of TOTAL_POINTS
   * @param {number} points
   * @returns {Ratio}
   */
  toRatio(points) {
    return new Ratio(points, TOTAL_POINTS);
  },
  /**
   * xxxx points -> .xxxx
   * @param points
   * @returns {number}
   */
  toFloat(points) {
    return points / TOTAL_POINTS;
  },
  /**
   * Returns weight from percentage
   * @param {number|string} percentage
   * @returns {number}
   */
  getWeightFromPercentage(percentage) {
    percentage = Number(percentage);
    return Number((percentage * MULTIPLIER).toFixed());
  },
};

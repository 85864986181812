/**
 * Entity Definition for variation
 */
export const entity = 'variations';

export const parent = {
  entity: 'experiments',
  key: 'experiment_id',
};

export default {
  entity,
  parent,
};

import _ from 'lodash';
import Immutable from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { Input, Label, Textarea, Table } from 'optimizely-oui';

import ComponentModuleFns from 'bundles/p13n/sections/oasis_experiment_manager/components/experiment_dialog/component_module/fns';

import FeatureSelector from './feature_selector';

const ExperimentDetails = props => {
  const handleInputChange = (event, property) => {
    const inputValue = event.target.value;
    props.updateExperimentProperty(property, inputValue);
  };

  const handleFeatureUnselected = () => {
    props.updateExperimentProperty('feature_flag_id', null);
  };

  const renderErrorBlock = () => {
    const experimentKey = props.experiment.get('key');
    if (
      !!experimentKey &&
      !ComponentModuleFns.ensureExperimentKeyValid(experimentKey)
    ) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="feature-experiment-key-invalid-error">
          Please enter a valid key. Valid keys contain alphanumeric characters,
          hyphens, and underscores, and are limited to 64 characters.
        </div>
      );
    }

    if (props.showUniqueKeyError) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="feature-experiment-key-blank-error">
          Key is already in use by another experiment in this project. Please
          choose a unique key.
        </div>
      );
    }
  };

  return (
    <div>
      <form>
        <fieldset>
          <ol className="lego-form-fields">
            <li className="push-quad--bottom lego-form-field__item">
              <Table tableLayoutAlgorithm="fixed">
                <Table.TBody>
                  <Table.TR>
                    <Table.TD>
                      <FeatureSelector
                        experiment={props.experiment}
                        handleFeatureSelected={props.handleFeatureSelected}
                        handleFeatureUnselected={handleFeatureUnselected}
                        showFeatureValidationError={
                          props.showFeatureValidationError
                        }
                      />
                    </Table.TD>
                    <Table.TD>
                      <h3>Experiment Key</h3>
                      <Input
                        type="text"
                        testSection="feature-experiment-key"
                        placeholder="experiment_key"
                        value={props.experiment.get('key')}
                        onChange={_.partialRight(handleInputChange, 'key')}
                      />
                      {renderErrorBlock()}
                    </Table.TD>
                  </Table.TR>
                </Table.TBody>
              </Table>
            </li>
            <li className="lego-form-fields__item">
              <h3>
                <Label isOptional={true}>
                  <span>Description</span>
                </Label>
              </h3>
              <p>
                What is your experiment? State your hypothesis and notes here.
              </p>
              <Textarea
                testSection="feature-experiment-description"
                defaultValue={props.experiment.get('description')}
                onChange={_.partialRight(handleInputChange, 'description')}
              />
            </li>
          </ol>
        </fieldset>
      </form>
    </div>
  );
};

ExperimentDetails.propTypes = {
  experiment: PropTypes.instanceOf(Immutable.Map).isRequired,
  handleFeatureSelected: PropTypes.func,
  handleFeatureUnselected: PropTypes.func,
  showFeatureValidationError: PropTypes.bool,
  showUniqueKeyError: PropTypes.bool,
  updateExperimentProperty: PropTypes.func.isRequired,
};

export default ExperimentDetails;

import { isUndefined } from 'lodash';
import classNames from 'classnames';

import PropTypes from 'prop-types';

import React from 'react';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import tr from 'optly/translate';
import flux from 'core/flux';

import EditorGetters from 'bundles/p13n/modules/editor/getters';
import {
  actions as EditorIframeActions,
  enums as EditorIframeEnums,
} from 'bundles/p13n/modules/editor_iframe';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import HighlighterEnums from 'optly/modules/highlighter/enums';

import InnerInput from './input';

export default class SelectorInput extends React.Component {
  static propTypes = {
    /**
     * Object with selector and dataOptlyId used for highlighting changes
     */
    currentSelectorHighlightOptions: PropTypes.object,
    /**
     * Integer of present elements matching the selector.
     * No default prop is present as 'undefined' is meant to hide the elementCount div
     */
    elementCount: PropTypes.number, // eslint-disable-line react/require-default-props
    /**
     * Vue iframe component for the selector input to interact with
     */
    iframe: PropTypes.object.isRequired,
    /**
     * Boolean if selector should be disabled or not
     */
    isDisabled: PropTypes.bool,
    /**
     * Boolean if component should start in search mode
     */
    mountInSearchMode: PropTypes.bool,
    /**
     * Called with one argument, newValue, should update the stored
     * value of the selector
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Functionality to occur when the user clicks the search btn
     * or enters into the text input
     */
    onEditStart: PropTypes.func,
    /**
     * Functionality to occur when the user is done editing
     * This function should be able to be executed consecutively without error (e.g. in applyNewSelector, then componentWillUnmount)
     * Beware of including references like this.iframe.id since this could execute after the active iframe has been unset
     */
    onEditStop: PropTypes.func,
    /**
     * Placeholder value for input
     * Beware of including references like this.iframe.id since this could execute after the active iframe has been unset
     */
    placeholder: PropTypes.string,
    /**
     * Optional identifier for a specific Selector Input component type
     * No default prop is present as 'undefined' should be passed to this.props.onChange if this is not provided
     */
    selectorInputType: PropTypes.string, // eslint-disable-line react/require-default-props
    /**
     * The stored value of the selector
     */
    value: PropTypes.string,
  };

  static defaultProps = {
    currentSelectorHighlightOptions: {},
    isDisabled: false,
    onEditStart: () => {},
    onEditStop: () => {},
    placeholder: '',
    mountInSearchMode: false,
    value: '',
  };

  constructor(props) {
    super(props);

    const { elementCount, value } = this.props;

    this.state = {
      elementCount,
      isSearching: false,
      isEditingText: false,
      workingSelector: value,
      previousSelector: value,
    };
  }

  componentDidMount() {
    const { mountInSearchMode } = this.props;

    this.onIframeHover = payload => {
      this.setState({
        workingSelector: payload.selector,
      });
    };

    this.onIframeClick = payload => {
      this.applyNewSelector(payload.selector);
      this.removeIframeListeners();
    };

    if (mountInSearchMode) {
      this.onSearchStart();
    }
  }

  componentDidUpdate(prevProps) {
    const { elementCount, value } = this.props;

    let updatedState = {};

    // Changes to the value prop should override & reset the local state
    if (value !== prevProps.value) {
      updatedState = Object.assign(updatedState, {
        isSearching: false,
        isEditingText: false,
        workingSelector: value,
        previousSelector: prevProps.value,
      });
    }

    // Changes to the elementCount prop should update the local state
    if (elementCount !== prevProps.elementCount) {
      updatedState = Object.assign(updatedState, {
        elementCount,
      });
    }

    // Set component state only if the updatedState object has key/values, thus preventing property/layout thrashing
    if (Object.keys(updatedState).length) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState(updatedState);
    }
  }

  componentWillUnmount() {
    const { onEditStop } = this.props;
    this.removeIframeListeners();
    onEditStop();
  }

  highlightSelectorInIframe = (selector, dataOptlyId, highlightType) => {
    // Return early if iframe is not set
    const { iframe } = this.props;

    if (!iframe) {
      return;
    }

    EditorIframeActions.unhighlightAllElements(iframe.id);
    EditorIframeActions.highlightElement({
      dataOptlyId,
      selector,
      id: iframe.id,
      type: highlightType,
    });
  };

  addIframeListeners = () => {
    // Return early if iframe is not set
    const { iframe } = this.props;

    if (!iframe) {
      return;
    }

    iframe.$on(EditorIframeEnums.IFrameMessageTypes.HOVER, this.onIframeHover);
    iframe.$on(EditorIframeEnums.IFrameMessageTypes.CLICK, this.onIframeClick);
  };

  removeIframeListeners = () => {
    // Return early if iframe is not set
    const { iframe } = this.props;

    if (!iframe) {
      return;
    }

    iframe.$off(EditorIframeEnums.IFrameMessageTypes.HOVER, this.onIframeHover);
    iframe.$off(EditorIframeEnums.IFrameMessageTypes.CLICK, this.onIframeClick);
  };

  applyNewSelector = newSelectorValue => {
    const { onChange, onEditStop, selectorInputType } = this.props;

    onChange(newSelectorValue, selectorInputType);
    this.setState(
      {
        isSearching: false,
        isEditingText: false,
      },
      () => {
        // Wait for next render to highlight the selector with the updated this.props.currentSelectorHighlightOptions
        const {
          selector,
          dataOptlyId,
        } = this.props.currentSelectorHighlightOptions;
        this.highlightSelectorInIframe(
          // Use selector or dataOptlyId from currentSelectorHighlightOptions if they are not undefined
          !isUndefined(selector) ? selector : newSelectorValue,
          !isUndefined(dataOptlyId) ? dataOptlyId : null,
          HighlighterEnums.IFrameHighlightTypes.SELECTED,
        );
      },
    );

    onEditStop();
  };

  revertToPreviousSelector = () => {
    const { previousSelector } = this.state;
    const { currentSelectorHighlightOptions, onEditStop } = this.props;
    const { selector, dataOptlyId } = currentSelectorHighlightOptions;

    this.setState(prevState => ({
      isSearching: false,
      isEditingText: false,
      workingSelector: prevState.previousSelector,
    }));

    this.highlightSelectorInIframe(
      // Use selector or dataOptlyId from currentSelectorHighlightOptions if they are not undefined
      !isUndefined(selector) ? selector : previousSelector,
      !isUndefined(dataOptlyId) ? dataOptlyId : null,
      HighlighterEnums.IFrameHighlightTypes.SELECTED,
    );

    onEditStop();
  };

  onTextEditStart = () => {
    const { onEditStart } = this.props;

    onEditStart();

    this.setState(prevState => ({
      isEditingText: true,
      previousSelector: prevState.workingSelector,
    }));
  };

  onTextEdit = newValue => {
    this.setState({
      workingSelector: newValue,
    });

    this.highlightSelectorInIframe(
      newValue,
      null,
      HighlighterEnums.IFrameHighlightTypes.HOVERED,
    );
  };

  onTextEditRevert = () => {
    this.revertToPreviousSelector();
  };

  onTextEditConfirm = newValue => {
    this.applyNewSelector(newValue);
  };

  onSearchStart = () => {
    const { onEditStart } = this.props;

    onEditStart();

    this.setState(prevState => ({
      isSearching: true,
      previousSelector: prevState.workingSelector,
    }));

    this.addIframeListeners();
  };

  onSearchCancel = () => {
    this.revertToPreviousSelector();
    this.removeIframeListeners();
  };

  render() {
    const { iframe, isDisabled, placeholder, value } = this.props;
    const {
      elementCount,
      isEditingText,
      isSearching,
      workingSelector,
    } = this.state;

    const computedValue =
      isEditingText || isSearching ? workingSelector || '' : value || '';

    let elementCountMessage;
    const {
      IFrameLoadStatuses: { FAILED: failedStatus },
    } = EditorIframeEnums;

    if (
      flux.evaluate(EditorGetters.activeFrame)?.get('loadStatus') ===
      failedStatus
    ) {
      elementCountMessage = 'No elements found, visual editor failed to load.';
    } else {
      elementCountMessage = tr.pluralize(
        '1 element matches this selector',
        '{0} elements match this selector',
        elementCount,
      );
    }

    let useTextareaMode = false;

    if (isFeatureEnabled('dynamic_selector_feature')) {
      const project = flux.evaluate(CurrentProjectGetters.project);

      useTextareaMode = project.getIn([
        'client_build_settings',
        'dynamic_selector_support',
      ]);
    }

    // In the case no iframe is active, pass null as the activeFrameID
    return (
      <div>
        <InnerInput
          activeFrameId={iframe ? iframe.id : null}
          allDisabled={isDisabled}
          applyNewSelector={this.applyNewSelector}
          onSearchCancel={this.onSearchCancel}
          onSearchStart={this.onSearchStart}
          onTextEdit={this.onTextEdit}
          onTextEditConfirm={this.onTextEditConfirm}
          onTextEditStart={this.onTextEditStart}
          onTextEditRevert={this.onTextEditRevert}
          placeholder={placeholder}
          handleRevert={this.revertToPreviousSelector}
          inputDisabled={isSearching}
          isEditingText={isEditingText}
          isSearching={isSearching}
          value={computedValue}
          workingSelector={workingSelector}
          textareaMode={useTextareaMode}
        />
        {!isSearching && !isUndefined(elementCount) && (
          <div
            className={classNames('lego-form-note', {
              'lego-form-note--bad-news': !elementCount,
            })}
            data-test-section="element-count">
            {elementCountMessage}
          </div>
        )}
      </div>
    );
  }
}

/**
 * Entity Definition for Audience Layers
 *
 * /audiences/{audience_id}/experiments/
 */

export const entity = 'audience_layers';

export const isRelationshipEntity = true;

export const parents = [
  {
    entity: 'projects',
    key: 'project_id',
  },
  {
    entity: 'audiences',
    key: 'audience_id',
  },
  {
    entity: 'experiments',
  },
];

export default {
  entity,
  isRelationshipEntity: true,
  parents,
};

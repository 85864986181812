import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'optly/immutable';
import { Input, PillsInput, ProgressDots } from 'optimizely-oui';
import { withTrack } from '@optimizely/segment-js/dist/decorators';

import PredictedIntentConditionConstants from '../constants';
import EstimatedReachHistogram from './estimated_reach_histogram';
import EmptyState from './empty_state';

/**
 * Custom prop validator function to explicitly allow null or false.
 * @param {Object} props
 * @param {string} propName
 * @param {React.Component Instance} componentName
 * @return {Error|undefined}
 * @constructor
 */
function PropTypesNullOrFalse(props, propName, componentName) {
  const propValue = props[propName];
  if (propValue === null || propValue === false) return;
  return new Error(
    `Invalid prop \`${propName}\` supplied to` +
    ` \`${componentName}\`. Validation failed.`,
  );
}

@withTrack
export default class PredictedIntentCondition extends React.Component {
  static propTypes = {
    /*
     * The interestGroup prop will always have one of the following 3 values:
     *   null - keywords were just updated and we're searching for an InterestGroup
     *   false - We searched and found an InterestGroup for the current keywords
     *   InterestGroup - We searched and found and InterestGroup for the current keywords
     * @type {Object<InterestGroup>|false|null}
     */
    interestGroup: PropTypes.oneOfType([
      PropTypes.instanceOf(Immutable.Map),
      PropTypesNullOrFalse,
    ]),
    keywords: PropTypes.array.isRequired,
    onKeywordsChange: PropTypes.func.isRequired,
    onPercentVisitorsChange: PropTypes.func.isRequired,
    percentVisitors: PropTypes.number.isRequired,
    track: PropTypes.func,
  };

  static defaultProps = {
    interestGroup: undefined,
    track: () => {},
  };

  constructor(props) {
    super(props);

    const {
      track,
    } = props;

    track('Adaptive Audience Condition Added');
  }

  onKeywordsChange = (updatedKeywords) => {
    const {
      onKeywordsChange,
      track,
    } = this.props;

    onKeywordsChange(updatedKeywords);

    track('Adaptive Audience Keywords Changed', { keywords: updatedKeywords.map(keyword => keyword.name) });
  };

  onPercentVisitorsChange = (event) => {
    const { onPercentVisitorsChange } = this.props;
    onPercentVisitorsChange(event.target.value);
  };

  /**
   * Coerce the users input to a 0-100 number with single decimal point precision.
   * @param event
   */
  ensurePercentageFormat = (event) => {
    const { onPercentVisitorsChange } = this.props;
    const percentageRoundedToTenths = Number(event.target.value).toFixed(1);
    // The <Input> doesn't allow negative numbers, so just make sure it's no more than 100.
    onPercentVisitorsChange(Math.min(Number(percentageRoundedToTenths), 100));
  };

  renderPlaceHolder() {
    const { interestGroup } = this.props;
    const { keywords } = this.props;
    if (interestGroup && !interestGroup.get('available')) {
      return <EmptyState.Processing />;
    }
    if (interestGroup === false) {
      return keywords.length > 0 ? (
        <EmptyState.Missing />
      ) : null;
    }
    if (interestGroup === null) {
      return (
        <div className="flex--dead-center height--150">
          <ProgressDots />
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      interestGroup,
      keywords,
      percentVisitors,
      onPercentVisitorsChange,
    } = this.props;

    const shouldShowTagsCounter = keywords.length / PredictedIntentConditionConstants.TAGS_LIMIT >= PredictedIntentConditionConstants.TAGS_LIMIT_VISIBLE_THRESHOLD;

    return (
      <div className="flex flex--column flex-align--left">
        <div className="flex flex--row push-double--bottom flex-align--center">
          <span>Top&nbsp;</span>
          <div className="display--inline-block width--75 soft-half--right">
            <Input
              type="number"
              min={ 0 }
              max={ 100 }
              step="any"
              onBlur={ this.ensurePercentageFormat }
              value={ String(percentVisitors) }
              onChange={ this.onPercentVisitorsChange }
              testSection="percent-visitors-input"
            />
          </div>
          <div className="display--inline-block push--right">
            % of visitors interested in
          </div>
          <div className="flex--1">
            <PillsInput
              extraAddKeys={ PredictedIntentConditionConstants.KEYWORD_INPUT_SEPARATORS }
              maxTags={ PredictedIntentConditionConstants.TAGS_LIMIT }
              onChange={ this.onKeywordsChange }
              placeholder="keywords"
              pills={ keywords }
            />
          </div>
          { shouldShowTagsCounter && (
            <div className="push--left oui-form-note">
              { keywords.length } / { PredictedIntentConditionConstants.TAGS_LIMIT }
            </div>
          )}
        </div>
        {interestGroup && interestGroup.get('available') && (
          <EstimatedReachHistogram
            percentVisitors={ Number(percentVisitors) }
            scoreDistribution={ interestGroup.get('score_distribution') }
            onPercentVisitorsChange={ onPercentVisitorsChange }
          />
        )}
        {this.renderPlaceHolder()}
      </div>
    );
  }
}

import PropTypes from 'prop-types';
import React from 'react';

import { Input, SelectDropdown, Textarea } from 'optimizely-oui';

import { FEATURE_VARIABLE_TYPES } from 'optly/modules/entity/feature_flag/constants';

// components
import { fieldPropType } from 'react_components/form';

const VariableValueInput = ({
  field,
  type,
  disableVariable,
  testSectionPrefix,
}) => {
  const updateValueFromSelect = value => {
    field.setValue(value);
  };

  const updateValueFromInput = inputEvent => {
    field.setValue(inputEvent.target.value);
  };

  const variableValue = field.getValue();
  testSectionPrefix = testSectionPrefix ? `${testSectionPrefix}-` : '';
  switch (type) {
    case FEATURE_VARIABLE_TYPES.boolean:
      return (
        <SelectDropdown
          isDisabled={disableVariable}
          onChange={updateValueFromSelect}
          testSection={`${testSectionPrefix}variable-value-boolean`}
          value={variableValue}
          fullWidth={true}
          minDropdownWidth={200}
          items={[
            {
              label: 'True',
              value: 'true',
            },
            {
              label: 'False',
              value: 'false',
            },
          ]}
        />
      );
    case FEATURE_VARIABLE_TYPES.double:
    case FEATURE_VARIABLE_TYPES.integer:
      return (
        <Input
          isDisabled={disableVariable}
          onChange={updateValueFromInput}
          type="number"
          testSection={
            type === FEATURE_VARIABLE_TYPES.double
              ? `${testSectionPrefix}variable-value-double`
              : `${testSectionPrefix}variable-value-integer`
          }
          value={variableValue}
        />
      );
    case FEATURE_VARIABLE_TYPES.json:
      return (
        <Textarea
          isDisabled={disableVariable}
          numRows={5}
          onChange={updateValueFromInput}
          testSection={`${testSectionPrefix}variable-value-json`}
          type="json"
          value={
            !field.isDirty() && variableValue
              ? JSON.stringify(JSON.parse(variableValue), undefined, 2)
              : variableValue
          }
        />
      );
    case FEATURE_VARIABLE_TYPES.string:
    default:
      return (
        <Textarea
          isDisabled={disableVariable}
          numRows={1}
          onChange={updateValueFromInput}
          type="text"
          testSection={`${testSectionPrefix}variable-value-string`}
          value={variableValue}
        />
      );
  }
};

VariableValueInput.propTypes = {
  disableVariable: PropTypes.bool,
  field: fieldPropType.isRequired,
  testSectionPrefix: PropTypes.string,
  type: PropTypes.oneOf([
    FEATURE_VARIABLE_TYPES.boolean,
    FEATURE_VARIABLE_TYPES.double,
    FEATURE_VARIABLE_TYPES.integer,
    FEATURE_VARIABLE_TYPES.string,
    FEATURE_VARIABLE_TYPES.json,
  ]).isRequired,
};

VariableValueInput.defaultProps = {
  disableVariable: false,
  testSectionPrefix: '',
};

export default VariableValueInput;

import React from 'react';
import PropTypes from 'prop-types';
import ui from 'core/ui';
import { sanitizeHtml } from 'optly/filters';
import { ButtonRow, Button, Dialog } from 'optimizely-oui';

const { Wrapper, Title, Footer } = Dialog;

export default class MultiConfirm extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        testSection: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
        style: PropTypes.string,
      }),
    ).isRequired,
  };

  onButtonClick = action => {
    ui.hideDialog();
    action();
  };

  render() {
    const { title, message, buttons } = this.props;
    return (
      <Wrapper>
        <Title>{title}</Title>
        {/* dangerouslySetInnerHTML is required because the message can contain bold segments
             and i am unable to find any other easy alternative. the html is already being sanitized
             so i don't see much risk here */}
        <div
          className="push-quad--bottom"
          data-test-section="multi-confirm-dialog-message"
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }}
        />
        <Footer>
          <ButtonRow
            rightGroup={[
              <Button
                key="cancel"
                style="plain"
                testSection="multi-confirm-dialog-cancel"
                onClick={ui.hideDialog}>
                Cancel
              </Button>,
              ...buttons.map(button => (
                <Button
                  key={button.testSection}
                  testSection={button.testSection}
                  style={button.style}
                  onClick={() => this.onButtonClick(button.action)}>
                  {button.message}
                </Button>
              )),
            ]}
          />
        </Footer>
      </Wrapper>
    );
  }
}

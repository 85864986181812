import { generateObjectSortFn, ASC } from 'optly/utils/sort';
import { toImmutable } from 'optly/immutable';
import { fns as LayerFns } from 'optly/modules/entity/layer';

const covertAudiences = (audiences, experimentCounts) => {
  return audiences
    .filter(audience => !audience.get('archived'))
    .toJS()
    .sort(generateObjectSortFn([{ field: 'name', type: 'string', dir: ASC }]))
    .map(audience => {
      return {
        label: audience.name,
        value: audience.id,
        description: `Used in ${audience.experiment_count} experiment${
          audience.experiment_count > 1 ? 's' : ''
        }`,
      };
    });
};

const filterLayers = (layers, layerIds) => {
  return layers
    ? layers.filter(
        layer =>
          !layer.get('archived') &&
          !LayerFns.hasLayerConcluded(layer) &&
          layerIds &&
          layerIds.includes(layer.get('id')),
      )
    : toImmutable([]);
};

export default {
  covertAudiences,
  filterLayers,
};

import flux from 'core/flux';

import CampaignManagerTrafficAllocationStores from 'bundles/p13n/components/campaign_manager/traffic_allocation/component_module/stores';
import ConfigureGroupDialogStores from 'bundles/p13n/components/dialogs/configure_group/component_module/stores';
import ExperienceManagerStores from 'bundles/p13n/components/experience_manager/component_module/stores';
import LayerNameHoldbackDetailStores from 'bundles/p13n/components/layer_name_holdback_detail/component_module/stores';
import MetricsPickerStores from 'bundles/p13n/components/metrics/metrics_manager_react/subcomponents/metrics_picker_react/component_module/stores';
import ScheduleExperienceStores from 'bundles/p13n/components/schedule_experience/component_module/stores';

export const stores = {
  ...CampaignManagerTrafficAllocationStores,
  ...ConfigureGroupDialogStores,
  ...ExperienceManagerStores,
  ...LayerNameHoldbackDetailStores,
  ...MetricsPickerStores,
  ...ScheduleExperienceStores,
};

export default function intialize() {
  flux.registerStores(stores);
}

import PropTypes from 'prop-types';
import React from 'react';

/**
 * The main area of the card
 */
const Body = props => (
  <div className="flex--1 soft--left">{props.children}</div>
);

Body.propTypes = {
  children: PropTypes.node,
};

export default Body;

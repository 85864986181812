/**
 * Loading overlay
 *
 * Usages:
 * 1. with `isLoading` prop
 *    <LoadingOverlay isLoading={ true }>foo</LoadingOverlay>
 *
 * 2. with flux loadingId, corresponds to `ui.loadingWhen` and `ui.loadingStart` / `ui.loadingStop`
 *    <LoadingOverlay loadingId='edit-experiment-dialog'>foo</LoadingOverlay>
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from '@optimizely/axiom';

import { connect } from 'core/ui/decorators';
import { getters as LoadingGetters } from 'core/modules/loading';

@connect(props => ({
  isIdLoading: props.loadingId
    ? LoadingGetters.isLoading(props.loadingId)
    : [null],
}))
class LoadingOverlay extends React.Component {
  static displayName = 'LoadingOverlay';

  static propTypes = {
    children: PropTypes.node,
    isIdLoading: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingId: PropTypes.string,
    size: PropTypes.oneOf(['small', 'tiny', 'large']),
  };

  static defaultProps = {
    size: 'large',
  };

  getLoadingState = () => {
    if (this.props.loadingId !== undefined) {
      return this.props.isIdLoading;
    }
    return this.props.isLoading;
  };

  UNSAFE_componentWillMount() {
    if (
      this.props.isLoading === undefined &&
      this.props.loadingId === undefined
    ) {
      throw new Error('Must pass either `loadingId` or `isLoading`');
    }
  }

  render() {
    const { size } = this.props;
    const propsToPass = _.omit(this.props, [
      'connectGetters',
      'size',
      'isIdLoading',
      'isLoading',
      'children',
      'loadingId',
    ]);
    if (propsToPass.className) {
      propsToPass.className = `${propsToPass.className} position--relative`;
    }
    const isLoading = this.getLoadingState();
    return (
      <div data-ui-component={true} {...propsToPass}>
        {isLoading && (
          <div
            className="lego-overlay"
            style={{ zIndex: 10 }}
            data-test-section="loading-overlay">
            <Spinner size={size} />
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

export default LoadingOverlay;

const propertyFormatMap = {
  string: {
    csharp: `{ "%s", "value" }`,
    java: `properties.put("%s", "value");`,
    go: `"%s": "value",`,
    swift: `"%s": "value"`,
    php: `"%s" => "value"`,
    javascript: `"%s": "value"`,
    node: `"%s": "value"`,
    ruby: `"%s" => "value"`,
    objectivec: `@"%s": @"value"`,
    flutter: `"%s": "value"`,
    python: `"%s": "value"`,
    html: `"%s": "value"`
  },
  number: {
    csharp: `{ "%s", 42 }`,
    java: `properties.put("%s", 42);`,
    go: `"%s": 42,`,
    swift: `"%s": 42`,
    php: `"%s" => 42`,
    javascript: `"%s": 42`,
    node: `"%s": 42`,
    ruby: `"%s" => 42`,
    objectivec: `@"%s": @42`,
    flutter: `"%s": 42`,
    python: `"%s": 42`,
    html: `"%s": 42`
  },
  boolean: {
    csharp: `{ "%s", true }`,
    java: `properties.put("%s", true);`,
    go: `"%s": true,`,
    swift: `"%s": true`,
    php: `"%s" => true`,
    javascript: `"%s": true`,
    node: `"%s": true`,
    ruby: `"%s" => true`,
    objectivec: `@"%s": @YES`,
    flutter: `"%s": true`,
    python: `"%s": true`,
    html: `"%s": true`
  }
};

/**
 * Generates a property line based on its type and language with hardcoded values.
 * Does not include commas at the end of lines.
 * @param {string} type - The type of the property ('string', 'number', 'boolean').
 * @param {string} key - The property key.
 * @param {string} language - The target SDK language.
 * @returns {string} - A formatted property line with hardcoded value.
 */

export const generatePropertyLine = (type, key, language) => {
  let result;
  if (!type) {
    type = 'string';
  }
  const formatType = propertyFormatMap[type.toLowerCase()];
  result = formatType[language] ? formatType[language].replace('%s', key) : `"${key}": "value"`;
  return result;
};

/**
 * Formats all event properties based on their types and language.
 * Assigns hardcoded default values:
 * - String: "value"
 * - Number: 42
 * - Boolean: true
 * @param {Array} eventProperties - Array of event property objects.
 * @param {string} language - The target SDK language.
 * @returns {string} - The formatted properties string.
 */
export const formatEventProperties = (eventProperties, language) => {
  if (!eventProperties || eventProperties.length === 0) {
    return '';
  }

  const propertyLines = eventProperties.map(prop => 
    generatePropertyLine(prop.type, prop.value, language)
  );

  let formattedProperties = '';

  if (['java', 'go'].includes(language)) {
    formattedProperties = propertyLines.join('\n');
  } else if (['csharp'].includes(language)) {
    formattedProperties = propertyLines.join(',\n  ');
  } else {
    const indentedLines = propertyLines.map(line => '    ' + line);
    const propertyCount = indentedLines.length;

    const linesWithCommas = indentedLines.map((line, index) => {
      if (index < propertyCount - 1) {
        return line + ','; // Add commas for all but the last property
      } else {
        return line;
      }
    });

    formattedProperties = linesWithCommas.join('\n');
  }

  return formattedProperties;
};

import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Store({
  /**
   * Initial properties of the store state
   * @property {Boolean} isDialogVisible -
   *                     whether the dialog is in the foreground
   *                     (defaults to false)
   * @property {Boolean} isFirstSignin -
   *                     whether the user is sigining in for the first time
   *                     (defaults to false)
   * @property {Boolean} requiredInfoWasSupplied -
   *                     whether the user has provided the required info
   *                     (defaults to true to prevent the dialog from rendering by default)
   */
  getInitialState: () =>
    toImmutable({
      isDialogVisible: false,
      isFirstSignin: false,
      requiredInfoWasSupplied: true,
    }),
  initialize() {
    this.on(actionTypes.SET_IS_FIRST_SIGNIN, setIsFirstSignin);
    this.on(actionTypes.SET_IS_DIALOG_VISIBLE, setIsDialogVisible);
    this.on(actionTypes.CHECK_PROFILE_DETAILS, performInitialCompletionCheck);
  },
});

/**
 * Set whether the user is signing in for the first time.
 * @param {Object} payload - action data from the caller
 * @param {Boolean} payload.isFirstSignin -
 *                  whether the user is signing in for the first time
 */
function setIsFirstSignin(state, { isFirstSignin }) {
  return state.set('isFirstSignin', isFirstSignin);
}

/**
 * Set whether the dialog is visible.
 * @param {Object} payload - action data from the caller
 * @param {Boolean} payload.visible - whether the dialog is visible or not
 */
function setIsDialogVisible(state, { visible }) {
  return state.set('isDialogVisible', visible);
}

/**
 * Set whether the user data has been checked for completeness.
 * @param {Object} payload - action data from the caller
 * @param {Boolean} payload.requiredInfoWasSupplied -
 *                  whether the user has filled out the form
 */
function performInitialCompletionCheck(state, { requiredInfoWasSupplied }) {
  return state.set('requiredInfoWasSupplied', requiredInfoWasSupplied);
}

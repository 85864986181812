/**
 * v-resize is a Vue directive that will allow for horizontal or vertical resizing
 *
 * NOTE: Keep in mind this has been used and manually tested for horizontal resizing but not for vertical
 * // TODO Remove above note when implemented successfully as a directive for vertical resizing
 *
 * To use in a Vue component:
 * - First, ensure that the element this directive will be added to can be resized via inline styles
 * - On bind, the resizer will be inserted after the element utilizing the directive.
 *   If the value of the v-resize directive is truthy, the resizer will shown. When the value is falsy, it will be hidden.
 *   Passing a flux value is a great way to toggle resizability, for example
 * - (Optional) Add either "horizontal" or "vertical" to the data-resizer-type attribute to set the orientation (e.g. data-resizer-type-"vertical")
 *   If the attribute is not provided, the resizer orientation will be "horizontal"
 * - (Optional) Add the inline style property to modify (e.g. data-style-property="max-width")
 *   If the attribute is not provided, the "width" or "height" style properties, depending on orientation, will be used
 * - (Optional) Add a minimum size in pixels using the data-min-size attribute (e.g. data-min-size="300")
 *   If the attribute is not provided, there will be no default minimum size
 * - (Optional) Add a maximum size in pixels using the data-max-size attribute (e.g. data-max-size="300")
 *   If the attribute is not provided, there will be no default maximum size
 * - (Optional) Subscribe to directive resizing events by adding the following method to the VM that is using v-resize
 *   Use this to let other parts of the app know that a resize is happening or is done (e.g. for code mirror refreshes)
 *   VM Event Subscription Example:
 *     this.$on('containerResized', isResizing => {
 *       this.contentIsResizing = isResizing;
 *       this.$broadcast('sidebarResizeEvent');
 *     });
 *
 * Usage Example:
 * <div class="two-col__nav"
 *      v-resize="sidebarHandleIsVisible"
 *      data-resizer-type="horizontal"
 *      data-style-property="max-width"
 *      data-min-size="300"
 *      data-max-size="800">
 *   <ul v-region="p13n-editor-sidebar" region-map="regionMap"></ul>
 * </div>
 * <div class="two-col__content editor__column">
 *   <div v-style="pointer-events: contentIsResizing ? 'none' : ''"
 *        v-component="editor">
 *   </div>
 * </div>
 *
 * @author Derek Hammond (derek@optimizely.com)
 */
import _ from 'lodash';

import $ from 'jquery';

const DURING_RESIZE_THROTTLE = 50;
const DOUBLE_CLICK_THROTTLE = 400;
const EMPTY_STRING = '';
const HORIZONTAL_KEY = 'horizontal';
const HORIZONTAL_RESIZE_HANDLE =
  '<div class="resizer resizer--horizontal"><div class="resizer__icon"></div></div>';
const HORIZONTAL_STYLE_DEFAULT = 'width';
const RESIZING_AUTO_UNSET_DELAY = 50;
const RESIZE_EVENT = 'containerResized';
const VERTICAL_KEY = 'vertical';
const VERTICAL_RESIZE_HANDLE =
  '<div class="resizer resizer--vertical"><div class="resizer__icon"></div></div>';
const VERTICAL_STYLE_DEFAULT = 'height';

const exported = {
  consecutiveClickCount: 0,
  isExpanded: false,
  isResizing: false,

  setIsResizing(isResizing, autoUnset = false) {
    this.isResizing = isResizing;
    this.vm.$emit(RESIZE_EVENT, this.isResizing);
    if (autoUnset) {
      setTimeout(() => {
        this.setIsResizing(false);
      }, RESIZING_AUTO_UNSET_DELAY);
    }
  },

  onResizerMouseDown(evt) {
    evt.preventDefault(); // preventDefault is to prevent text from being selected while drag-resizing
    this.setIsResizing(true);
    this.consecutiveClickCount += 1;
    if (this.consecutiveClickCount > 1) {
      this.onDoubleClick();
    }
    setTimeout(() => {
      this.consecutiveClickCount = 0;
    }, DOUBLE_CLICK_THROTTLE);
  },

  onDoubleClick() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      const maxSize = this.maxSize ? `${this.maxSize}px` : '100%';
      this.$el.css(this.styleProperty, maxSize);
    } else {
      this.$el.css(this.styleProperty, EMPTY_STRING);
    }
    // Set and auto unset a resize event
    this.setIsResizing(true, true);
  },

  onDocumentMouseMove(evt) {
    if (!this.isResizing) {
      return;
    }
    this.throttledDuringResize(evt);
  },

  onDocumentMouseUp() {
    if (!this.isResizing) {
      return;
    }
    this.setIsResizing(false);
  },

  duringResize(evt) {
    // Let the VM know content is resizing
    this.vm.$emit(RESIZE_EVENT, this.isResizing);

    let newSizeInPixels;
    if (this.orientation === HORIZONTAL_KEY) {
      newSizeInPixels = evt.clientX - this.$el.offset().left;
    } else {
      newSizeInPixels = evt.clientY - this.$el.offset().top;
    }

    const minSize = parseInt(this.minSize, 10);
    const maxSize = parseInt(this.maxSize, 10);

    if (newSizeInPixels < minSize || newSizeInPixels > maxSize) {
      return;
    }

    this.newSize = `${newSizeInPixels}px`;
    this.$el.css(this.styleProperty, this.newSize);
  },

  bind(value) {
    // Set up attribute preferences if provided
    this.minSize = this.el.getAttribute('data-min-size');
    this.maxSize = this.el.getAttribute('data-max-size');
    this.orientation =
      this.el.getAttribute('data-resizer-type') === HORIZONTAL_KEY
        ? HORIZONTAL_KEY
        : VERTICAL_KEY;
    this.styleProperty =
      this.orientation === HORIZONTAL_KEY
        ? HORIZONTAL_STYLE_DEFAULT
        : VERTICAL_STYLE_DEFAULT;
    this.styleProperty =
      this.el.getAttribute('data-style-property') || this.styleProperty;

    // Initialize the parent VM's contentIsResizing property
    this.setIsResizing(false);

    let resizeHandleHtml = VERTICAL_RESIZE_HANDLE;
    if (this.orientation === VERTICAL_KEY) {
      resizeHandleHtml = HORIZONTAL_RESIZE_HANDLE;
    }

    // Insert resizer html directly after the element utilizing the v-resize directive
    this.$el = $(this.el);
    this.el.insertAdjacentHTML('afterend', resizeHandleHtml);
    this.$resizeHandle = $(this.el.nextSibling);

    this._boundMouseDown = this.onResizerMouseDown.bind(this);
    this._boundMouseMove = this.onDocumentMouseMove.bind(this);
    this._boundMouseup = this.onDocumentMouseUp.bind(this);
    this.$resizeHandle.on('mousedown', this._boundMouseDown);
    $(document)
      .on('mouseup', this._boundMouseup)
      .on('mousemove', this._boundMouseMove);

    // Create a throttled instance of the this.duringResize method, so it's not called too often
    this.throttledDuringResize = _.throttle(
      this.duringResize.bind(this),
      DURING_RESIZE_THROTTLE,
    );
  },

  update(value) {
    // Use v-resize directive value too reset, display or hide resizer
    if (value) {
      this.$resizeHandle.removeClass('display--none');
    } else {
      this.$resizeHandle.addClass('display--none');
      this.$el.css(this.styleProperty, EMPTY_STRING);
    }
  },

  unbind() {
    // Remove event bindings and handle
    this.$resizeHandle.off('mousedown', this._boundMouseDown);
    $(document)
      .off('mouseup', this._boundMouseup)
      .off('mousemove', this._boundMouseMove);
    this.$resizeHandle.remove();
  },
};

export default exported;

export const {
  consecutiveClickCount,
  isExpanded,
  isResizing,
  setIsResizing,
  onResizerMouseDown,
  onDoubleClick,
  onDocumentMouseMove,
  onDocumentMouseUp,
  duringResize,
  bind,
  update,
  unbind,
} = exported;

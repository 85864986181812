import ui from 'core/ui';
import { fns as LayerExperimentFns } from 'optly/modules/entity/layer_experiment';
import { actions as ExperimentSectionActions } from 'optly/modules/entity/experiment_section';

/**
 * If newName is a valid name for a new variation in the current section,
 * save it and show a loading spinner while saving. Otherwise show a
 * notification with the validation error message.
 * @param {Immutable.Map} section
 * @param {String} newName
 * @return {Deferred} The save deferred, or a rejected deferred if the name is
 * invalid
 */
function saveNewVariationInMVTSection(section, newName) {
  const validation = LayerExperimentFns.validateVariationName(
    section.toJS(),
    newName,
  );

  if (!validation.valid) {
    ui.showNotification({
      message: validation.message,
      type: 'error',
    });
    return $.Deferred().reject({
      message: validation.message,
      type: 'name error',
    });
  }

  const saveDef = ExperimentSectionActions.createNewVariationWithRedistributedTraffic(
    newName,
    section.toJS(),
  );
  ui.loadingWhen('variations-panel', saveDef);
  return saveDef;
}

export default saveNewVariationInMVTSection;

import React from 'react';
import PropTypes from 'prop-types';

import { yellowBase } from '@optimizely/design-tokens/dist/json/colors.json';

import { withTrack } from '@optimizely/segment-js/dist/decorators';

import { Input, OverlayWrapper, Popover, Table, Icon } from 'optimizely-oui';

import Immutable from 'optly/immutable';

import SelectDropdown from 'react_components/select_dropdown';

import formatFns from 'optly/utils/display_format_fns';

@withTrack
class EventDetailsSection extends React.Component {
  static propTypes = {
    /**
     * a Number indicates the current warning threshold for missing tags
     */
    currentWarningThreshold: PropTypes.string.isRequired,
    /**
     * Function handler for when the currently selected event changed
     */
    onPageEventChange: PropTypes.func.isRequired,
    /**
     * Function handler for when the currently warning threshold changed
     */
    onWarningThresholdChange: PropTypes.func.isRequired,
    /**
     * an Immutable Map containing display names for each event in the given catalog
     */
    pageEventsStats: PropTypes.instanceOf(Immutable.Map).isRequired,
    /**
     * an Immutable Map containing detailed stats for the currently selected event in the given catalog
     */
    selectedPageEventStats: PropTypes.instanceOf(Immutable.Map).isRequired,
    /**
     * Function handler for Segment Tracking
     */
    track: PropTypes.func,
  };

  static defaultProps = {
    track: () => {},
  };

  /**
   * Coerce the users input to a 0-100 number with single decimal point precision.
   * @param event
   */
  ensurePercentageFormat = event => {
    const { onWarningThresholdChange, track } = this.props;
    let inputValue = event.target.value;
    // If input value includes the scientific constant 'e', coerce it to 0.
    if (inputValue.includes('e')) {
      inputValue = 0;
    }
    const percentageRoundedToTenths = Number(inputValue).toFixed(1);
    // Ensure the number is between 0 and 100. If it's negative, round up to 0, if it's greater than 100, round down to 100.
    const newThreshold = Math.min(
      Math.max(Number(percentageRoundedToTenths), 0),
      100,
    );
    onWarningThresholdChange(newThreshold);

    track('Recommendations Catalog Profiler Warning Threshold Applied', {
      value: newThreshold,
    });
  };

  /**
   * Given the currently selectedPageEventStats, render a table containing detailed stats about the given catalog event.
   */
  renderSelectedPageEventStats = () => {
    const { selectedPageEventStats } = this.props;

    const totalPageEvents = Number(selectedPageEventStats.get('num_events'));
    const totalUniqueItems = Number(selectedPageEventStats.get('num_items'));
    const totalPageEventsDisplay = formatFns.formatBigNumberForDisplay(
      totalPageEvents,
      1,
    );
    const totalUniqueItemsDisplay = formatFns.formatBigNumberForDisplay(
      totalUniqueItems,
      1,
    );

    return (
      <React.Fragment>
        <div className="push--ends" data-test-section="events-counts-summary">
          This event has been initiated <b>{totalPageEventsDisplay}</b> times on{' '}
          <b>{totalUniqueItemsDisplay}</b> unique items.
        </div>
        <Table>
          <Table.THead>
            <Table.TR>
              <Table.TH colSpan={2}>Event Tags</Table.TH>
              <Table.TH>Page Events</Table.TH>
              <Table.TH>Unique Items</Table.TH>
            </Table.TR>
          </Table.THead>
          <Table.TBody>
            {this.renderCatalogTagsStats(
              selectedPageEventStats.get('per_tag_stats'),
              totalPageEvents,
              totalUniqueItems,
            )}
          </Table.TBody>
        </Table>
      </React.Fragment>
    );
  };

  /**
   * Renders all table rows containing detailed stats about all tags in a given catalog event.
   *
   * @param {Immutable.Map} tagsStats   an array of 2 containing the eventTagName (0) and an object of {events and items}
   *                                    which has details about the given tag.
   * @param {Number} totalPageEvents    total number of events happened on the current page.
   * @param {Number} totalUniqueItems   total number of unique items in the current catalog.
   */
  renderCatalogTagsStats = (tagsStats, totalPageEvents, totalUniqueItems) =>
    tagsStats
      .mapEntries(([eventTagName, eventTagStats]) => {
        const { currentWarningThreshold } = this.props;

        // Percentage is between 0 - 10000 which corresponds to 0 - 100.00%.
        const pageEventsPercentage = eventTagStats.get('events');
        const uniqueItemsPercentage = eventTagStats.get('items');

        const getStatsDisplay = (percentage, totalCount, testSection) => {
          const displayPercentage = Math.round(percentage / 10) / 10;
          const actualCountDisplay = formatFns.formatBigNumberForDisplay(
            (percentage * totalCount) / 10000,
            1,
          );
          const showWarning =
            displayPercentage < Math.min(Number(currentWarningThreshold), 100);

          return (
            <div className="flex flex-align--center">
              <div data-test-section={`${eventTagName}-${testSection}-number`}>
                {displayPercentage}%{' '}
                <span className="muted">({actualCountDisplay})</span>
              </div>
              {showWarning && (
                <div
                  className="push-half--left push-half--top"
                  data-test-section={`${eventTagName}-${testSection}-warning`}>
                  <Icon
                    color={yellowBase}
                    size="small"
                    name="circle-exclamation"
                  />
                </div>
              )}
            </div>
          );
        };

        const statsRow = (
          <Table.TR
            key={eventTagName}
            borderStyle="ends"
            backgroundColor="faint">
            <Table.TD colSpan={2}>
              <span className="word-break--word">{eventTagName}</span>
            </Table.TD>
            <Table.TD>
              {getStatsDisplay(
                pageEventsPercentage,
                totalPageEvents,
                'page-events',
              )}
            </Table.TD>
            <Table.TD>
              {getStatsDisplay(
                uniqueItemsPercentage,
                totalUniqueItems,
                'unique-items',
              )}
            </Table.TD>
          </Table.TR>
        );

        return [eventTagName, statsRow];
      })
      .toList()
      .toJS();

  render() {
    const {
      currentWarningThreshold,
      onPageEventChange,
      onWarningThresholdChange,
      pageEventsStats,
      selectedPageEventStats,
    } = this.props;

    const eventsList = [];

    pageEventsStats.forEach(eventStats => {
      eventsList.push({
        label: eventStats.get('display_name'),
        value: eventStats,
      });
    });

    return (
      <div className="push-quad--bottom">
        <h3 className="push--bottom">Catalog Events & Tags</h3>
        <div
          className="push-double--bottom"
          data-test-section="catalog-events-tags-description">
          Search this catalog's events to see which tags are being sent with the
          event.
        </div>
        <div className="flex flex-align--center">
          <SelectDropdown
            isFilterable={true}
            items={eventsList}
            onChange={onPageEventChange}
            testSection="events-tags-dropdown"
            value={selectedPageEventStats}
            width={350}
          />
          <div className="flex flex-align--center flex-justified--end flex--1">
            <span className="push-half--bottom">Warning Threshold</span>
            <OverlayWrapper
              behavior="hover"
              horizontalAttachment="center"
              verticalAttachment="bottom"
              overlay={
                <Popover testSection="warning-threshold-description">
                  To detect when tags are missing from your events, set the
                  desired minimum percentage threshold to highlight warning
                  below.
                </Popover>
              }>
              <div className="push-half--left">
                <Icon name="circle-question" size="small" />
              </div>
            </OverlayWrapper>
            <div className="flex flex-align--center push-half--bottom push-double--left width--75">
              <Input
                max={100}
                min={0}
                onBlur={this.ensurePercentageFormat}
                onChange={event => onWarningThresholdChange(event.target.value)}
                testSection="warning-threshold-input"
                textAlign="right"
                type="number"
                value={currentWarningThreshold}
              />
              <span className="push-half--left">%</span>
            </div>
          </div>
        </div>
        {this.renderSelectedPageEventStats()}
      </div>
    );
  }
}

export default EventDetailsSection;

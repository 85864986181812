const Immutable = require('optly/immutable').default;

const React = require('react');
const PropTypes = require('prop-types');

// components
const { Button, Table } = require('optimizely-oui');

const { SortableTableHeader } = require('react_components/sortable_table');
const EmptyState = require('bundles/p13n/components/entity_dashboard/empty_state')
  .default;
const EntityTable = require('bundles/p13n/components/entity_dashboard/entity_table')
  .default;

const TableFilterControls = require('bundles/p13n/components/entity_dashboard/table_filter_controls')
  .default;

// modules
const CurrentProjectGetters = require('optly/modules/current_project/getters');
const FilterableTableEnums = require('optly/modules/filterable_table/enums');
const FilterableTableFns = require('optly/modules/filterable_table/fns');
const SectionModule = require('bundles/p13n/sections/features_old/section_module');
const ui = require('core/ui').default;
const tr = require('optly/translate');

const FeatureTableRow = require('./components/feature_table_row').default;

const EmptyFeatureFlagImage = require('/static/img/p13n/empty-feature-flags.svg');
const { isFeatureEnabled } = require('@optimizely/js-sdk-lab/src/actions');

const { TABLE_ID } = SectionModule.enums;

class FeaturesDashboardComponent extends React.Component {
  static propTypes = {
    canManageFeatureFlags: PropTypes.bool.isRequired,
    currentProjectRunningExperiments: PropTypes.instanceOf(Immutable.List)
      .isRequired,
    isEmptyState: PropTypes.bool.isRequired,
    features: PropTypes.instanceOf(Immutable.List).isRequired,
  };

  archiveFeatureFlag = feature =>
    SectionModule.actions.archiveFeatureFlag(feature);

  createFeature = () => SectionModule.actions.showFeatureDialog();

  editFeature = feature => SectionModule.actions.showFeatureDialog(feature);

  filterItemByString = (item, search) =>
    FilterableTableFns.matchesFields(item, ['api_name'], search);

  filterItemByStatus = (item, status) =>
    FilterableTableFns.matchesArchivedStatus(item, status);

  unarchiveFeatureFlag = feature =>
    SectionModule.actions.unarchiveFeatureFlag(feature);

  renderEmptyState = () => (
    <EmptyState
      createButton={{
        onClick: this.createFeature,
        isDisabled: !this.props.canManageFeatureFlags,
        label: <span>Create New Feature Flag&hellip;</span>,
        testSection: 'create-feature-button',
      }}
      headline={tr('Create feature flags')}
      description={tr(
        "Create flags and variables for parts of your application you'd like to toggle, roll out, or experiment on.",
      )}
      imagePath={EmptyFeatureFlagImage}
    />
  );

  renderTableHeader = () => (
    <Table.TR>
      <SortableTableHeader field="api_name" type="string">
        Feature Flag
      </SortableTableHeader>
      <SortableTableHeader field="created" type="date">
        Created
      </SortableTableHeader>
      <SortableTableHeader field="last_modified" type="date">
        Modified
      </SortableTableHeader>
      <Table.TH />
    </Table.TR>
  );

  renderTableRow = (feature, index) => (
    <FeatureTableRow
      archive={this.archiveFeatureFlag}
      canManageFeatureFlags={this.props.canManageFeatureFlags}
      currentProjectRunningExperiments={
        this.props.currentProjectRunningExperiments
      }
      editFeature={this.editFeature}
      feature={feature}
      rowIndex={index}
      unarchive={this.unarchiveFeatureFlag}
    />
  );

  render() {
    if (this.props.isEmptyState) {
      return this.renderEmptyState();
    }

    return (
      <div className="flex flex--1 flex--column">
        <div className="flex flex--none push-quad--sides push-double--ends">
          <TableFilterControls
            tableId={TABLE_ID}
            statusOptions={[
              { label: 'Active', value: FilterableTableEnums.status.ACTIVE },
              {
                label: 'Archived',
                value: FilterableTableEnums.status.ARCHIVED,
              },
            ]}
          />
          {!isFeatureEnabled("disable_creating_full_stack_entities") && (
              <Button
                isDisabled={!this.props.canManageFeatureFlags}
                style="highlight"
                onClick={this.createFeature}
                testSection="create-feature-button">
                Create New Feature Flag&hellip;
              </Button>
            )
          }
        </div>
        <EntityTable
          tableId={TABLE_ID}
          data={this.props.features}
          entityPlural="feature flags"
          filterItemByString={this.filterItemByString}
          filterItemByStatus={this.filterItemByStatus}
          renderTableRow={this.renderTableRow}
          renderTableHeader={this.renderTableHeader}
          defaultSortBy={{ field: 'api_name', type: 'string' }}
          defaultFilters={{ status: FilterableTableEnums.status.ACTIVE }}
        />
      </div>
    );
  }
}

module.exports = ui.connectGetters(FeaturesDashboardComponent, {
  currentProject: CurrentProjectGetters.project,
  currentProjectRunningExperiments:
    SectionModule.getters.currentProjectRunningExperiments,
  isEmptyState: [
    SectionModule.getters.currentProjectFeatures,
    features => features.size === 0,
  ],
  features: SectionModule.getters.currentProjectFeatures,
});

const flux = require('core/flux');

const actionTypes = require('./action_types');

exports.setCurrentEventId = function(id) {
  flux.dispatch(actionTypes.P13N_SET_CURRENT_EVENT_ID, {
    id,
  });
};

exports.resetCurrentEventId = function() {
  exports.setCurrentEventId(null);
};

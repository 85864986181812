import flux from 'core/flux';

import { actions as LayerActions } from 'optly/modules/entity/layer';
import {
  actions as ProjectActions,
  enums as ProjectEnums,
  getters as ProjectGetters,
} from 'optly/modules/entity/project';

const fetchProjectsAndLayers = () => {
  return new Promise((resolve, reject) => {
    ProjectActions.fetchAll({
      project_status: ProjectEnums.project_status.ACTIVE,
    }).then(() => {
      const promises = [];
      const projects = flux.evaluate(ProjectGetters.entityCache);
      projects.forEach(project => {
        promises.push(
          LayerActions.fetchAllByStatus({
            projectId: project.get('id'),
            byPage: false,
            archived: false,
          }),
        );
      });
      Promise.all(promises).then(() => {
        resolve();
      }, reject);
    }, reject);
  });
};

export default {
  fetchProjectsAndLayers,
};

module.exports = {
  statusText: {
    NOT_STARTED: tr('Not Started'),
    STARTING: tr('Starting...'),
    RUNNING: tr('Running'),
    PAUSING: tr('Pausing...'),
    PAUSED: tr('Paused'),
    ARCHIVED: tr('Archived'),
    CONCLUDED: tr('Concluded'),
    RUN: tr('Run'),
    PAUSE: tr('Pause'),
    ARCHIVE: tr('Archive'),
    CONCLUDE: tr('Conclude'),
  },
};

import EventEnums from 'optly/modules/entity/event/enums';

/**
 * Find an event with the corresponding selector and view id from a list of events
 * @param {Immutable.List} events
 * @param {String} trackClicksChangeSelector
 * @param {String} trackClicksChangeViewId
 *
 * @returns {Immutable.List}
 */
const findMatchingEvent = (
  events,
  trackClicksChangeSelector,
  trackClicksChangeViewId,
) =>
  events.find(event => {
    const filterType = event.getIn(['event_filter', 'filter_type']);
    if (filterType !== EventEnums.filterTypes.TARGET_SELECTOR) {
      return false;
    }
    return (
      event.getIn(['event_filter', 'selector']) === trackClicksChangeSelector &&
      event.get('view_id') === trackClicksChangeViewId
    );
  });

export default {
  findMatchingEvent,
};

/**
 * ported from optly.match
 */
const $ = require('jquery');

const matcher = {};

/**
 * @param {string} url
 * @param {string} string
 * @param {string} type
 * @return {boolean}
 */
matcher.matchUrl = function(url, string, type) {
  url = matcher.stripVarFromQuery(url);

  switch (type) {
    case 'exact':
      url = matcher.stripUrl(url);
      return url === matcher.stripUrl(string);
    case 'regex':
      try {
        return Boolean(url.match(string));
      } catch (error) {
        return false;
      }
    case 'simple':
      url = matcher.simplifyUrl(url);
      string = matcher.simplifyUrl(string);
      return url === string;
    case 'substring':
      url = matcher.stripUrl(url, true);
      string = matcher.stripUrl(string, true);
      return url.indexOf(string) !== -1;
    default:
      return false;
  }
};

/**
 * @param {string} url
 * @return {string}
 */
matcher.removeParameters = function(url) {
  let index = url.indexOf('?');

  if (index !== -1) {
    url = url.substring(0, index);
  }

  index = url.indexOf('#');

  if (index !== -1) {
    url = url.substring(0, index);
  }

  return url;
};

/**
 * Get simple URL.
 */
matcher.simplifyUrl = function(url) {
  return matcher.stripUrl(matcher.removeParameters(url));
};

/**
 * Remove protocol, edit/preview proxy URL and extraneous trailing
 * characters. Also remove 'www.' unless specified not to.
 *
 * @param {string} url
 * @param {boolean=} excludeOptionalPrefixesToStrip
 * @return {string}
 */
matcher.stripUrl = function(url, excludeOptionalPrefixesToStrip) {
  url = url.replace('/?', '?');
  url = url.toLowerCase().replace(/[/&?]+$/, '');

  // Create a copy of the required prefixes
  let prefixesToStrip = matcher.REQUIRED_PREFIXES_TO_STRIP.slice(0);
  if (!excludeOptionalPrefixesToStrip) {
    prefixesToStrip = prefixesToStrip.concat(
      matcher.OPTIONAL_PREFIXES_TO_STRIP,
    );
  }

  const length = prefixesToStrip.length;
  for (let i = 0; i < length; i++) {
    const prefixToStrip = prefixesToStrip[i];

    const prefixRegEx = new RegExp(`^${prefixToStrip}`);
    url = url.replace(prefixRegEx, '');
  }

  return url;
};

/**
 * Strip out any optimizely_... query parameters from a url.
 * @private
 * @param {string} url
 * @return {string}
 */
matcher.stripVarFromQuery = function(url) {
  const urlParts = url.split('?');
  if (urlParts[1]) {
    const urlQueryParts = urlParts[1].split('&');
    const newQueryParts = [];
    $.each(urlQueryParts, function() {
      if (this.indexOf(matcher.QUERY_PREFIX) !== 0) {
        newQueryParts.push(this);
      }
    });
    urlParts[1] = newQueryParts.join('&');
    return urlParts.join('?');
  }
  return url;
};

/**
 * Regexes to match against URL (always)
 *
 * @private
 */
matcher.REQUIRED_PREFIXES_TO_STRIP = [
  'https?://.*?.?optimizelyedit.(com|test)/',
  'https?://.*.?optimizelypreview.(com|test)/',
  'https?://(edit|preview)(-hrd|-devel)?.optimizely.(com|test)/',
  'https?://.*?.?optimizelyedit(-hrd)?.appspot.com/',
  'https?://',
];

/**
 * Regexes to match against URL if excludeOptionalPrefixesToStrip not set
 *
 * @private
 */
matcher.OPTIONAL_PREFIXES_TO_STRIP = ['www.'];

matcher.QUERY_PREFIX = 'optimizely_';

module.exports = matcher;

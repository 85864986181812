import React from 'react';

import flux from 'core/flux';
import parseQueryParams from 'optly/utils/parse_query_params';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import NavConstants from 'optly/services/navigation';
import BundleSplitHelper from 'optly/utils/bundle_split_helper';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';

import RoutingFns from 'bundles/p13n/routing_fns';

let OasisExperimentManagerSection;
let OasisExperimentManagerModule;

/*
 * Routing function that ensures the Oasis dynamic bundle has been fetched
 */
const EnsureOasisBundle = (ctx, next) => {
  BundleSplitHelper.getOasisBundleModules()
    .then(modules => {
      ({
        OasisExperimentManagerSection,
        OasisExperimentManagerModule,
      } = modules);
    })
    .then(next);
};

const shouldHandleCustomProjects = () =>
  Promise.resolve(flux.evaluate(CurrentProjectGetters.isCustomProject));

/*
 * Routes for the Oasis Exp Manager section in nuclear-router format
 */
const routes = [
  /**
   * Root path for Custom Projects in Optimizely X
   * THIS PATH IS ONLY FOR OASIS AT THE MOMENT - 7/14/16
   */
  {
    match: '/v2/projects/:proj_id/experiments',
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.OASIS,
          NavConstants.OasisTabs.EXPERIMENTS,
        ),
        RoutingFns.parseProjectId,
        EnsureOasisBundle,
      ],
      (ctx, next) => {
        RoutingFns.parseQueryFilters(
          OasisExperimentManagerModule.constants.tableIds.EXPERIMENTS,
          LayerExperimentEnums.status.ACTIVE,
          Object.values(LayerExperimentEnums.status),
        )(ctx, next);
      },
      (ctx, next) =>
        OasisExperimentManagerSection.pages.experimentsDashboard.routingSetup(
          ctx,
          next,
        ),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Experiments',
        }),
      ctx => {
        let experimentId;
        const params = parseQueryParams(ctx.canonicalPath);
        if (
          params.experiment_id &&
          !Number.isNaN(Number(params.experiment_id))
        ) {
          experimentId = Number(params.experiment_id);
        }

        // @NOTE: this renders the Oasis experiments manager component (for custom projects)
        // if rendering other project types please fork the logic here
        RoutingFns.renderMainRegion(
          <OasisExperimentManagerSection.pages.experimentsDashboard.component
            experimentId={experimentId}
          />,
        );
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/groups',
    shouldHandle: shouldHandleCustomProjects,
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.OASIS,
          NavConstants.OasisTabs.GROUPS,
        ),
        RoutingFns.parseProjectId,
        EnsureOasisBundle,
      ],
      (ctx, next) =>
        OasisExperimentManagerSection.pages.groupsDashboard.routingSetup(
          ctx,
          next,
        ),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Groups',
        }),
      () =>
        RoutingFns.renderMainRegion(
          <OasisExperimentManagerSection.pages.groupsDashboard.component />,
        ),
    ],
  },
];

export default routes;

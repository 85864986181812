import React from 'react';

import PropTypes from 'prop-types';

import { Button, ButtonRow, Label } from 'optimizely-oui';

import ui from 'core/ui';
import Immutable from 'optly/immutable';

import { connect } from 'core/ui/decorators';

import Router from 'core/router';
import urls from 'optly/services/url_helper_legacy';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { getters as DataLayerGetters } from 'bundles/p13n/modules/data_layer';

const NAVIGATION_TIMEOUT = 500;

@connect({
  currentProjectId: CurrentProjectGetters.id,
  activeViews: DataLayerGetters.activeViews,
})
class CreateClickEvent extends React.Component {
  static propTypes = {
    activeViews: PropTypes.instanceOf(Immutable.List).isRequired,
    currentProjectId: PropTypes.number.isRequired,
  };

  state = {
    errors: {},
    selectedViewId: null,
  };

  renderViews() {
    const { activeViews } = this.props;
    return activeViews.map(view => (
      <option key={view} value={view.get('id')}>
        {view.get('name')}
      </option>
    ));
  }

  save = () => {
    const { currentProjectId } = this.props;
    const { selectedViewId } = this.state;
    this.setState({ errors: {} }, function() {
      if (!selectedViewId) {
        return this.setState({
          errors: { pageSelection: tr('Please choose a page') },
        });
      }
      // Should take us to the page click event creation
      Router.go(urls.createClickEvent(currentProjectId, selectedViewId));
      window.setTimeout(() => {
        ui.hideDialog();
      }, NAVIGATION_TIMEOUT);
    });
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div data-test-section="create-click-event-component">
          <div className="beta push-quad--bottom">New Click Event</div>
          <Label isRequired={true}>Page</Label>
          <p className="muted milli">
            Choose the page where you want to add this click event
          </p>
          <select
            className="oui-select"
            onChange={event => {
              this.setState({ selectedViewId: event.target.value });
            }}
            data-test-section="create-click-event-page-select">
            <option selected={true} disabled={true}>
              Select page...
            </option>
            {this.renderViews()}
          </select>
        </div>
        {errors && (
          <div
            className="oui-form-note lego-form-note--bad-news"
            data-test-section="error-page-selection">
            {errors.pageSelection}
          </div>
        )}
        <div className="flex flex-align--center push-double--top">
          <div className="oui-sheet__required-indicator cursor--default color--red">
            <span>* Required field</span>
          </div>
          <div className="flex--1">
            <ButtonRow
              rightGroup={[
                <Button
                  style="plain"
                  testSection="create-click-event-cancel"
                  key="cancel"
                  onClick={ui.hideDialog}>
                  Cancel
                </Button>,
                <Button
                  style="highlight"
                  testSection="create-click-event-save-button"
                  key="create"
                  onClick={this.save}>
                  Create Event
                </Button>,
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CreateClickEvent;

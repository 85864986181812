import keyMirror from 'optly/utils/key_mirror';

export const modes = keyMirror({
  CREATE: null,
  EDIT: null,
  DUPLICATE: null,
});

export const types = keyMirror({
  P13N_EXP: null,
});

export default {
  modes,
  types,
};

import { toImmutable } from 'optly/immutable';

import AudienceFns from 'optly/modules/entity/audience/fns';
import AudienceGetters from 'optly/modules/entity/audience/getters';
import CurrentLayerFns from 'bundles/p13n/modules/current_layer/fns';
import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import LayerConstants from 'optly/modules/entity/layer/constants';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerExperiment from 'optly/modules/entity/layer_experiment';
import LayerSettingsGetters from 'bundles/p13n/modules/layer_settings/getters';
import LiveVariableGetters from 'optly/modules/entity/live_variable/getters';
import LiveVariableUsageFns from 'optly/modules/entity/live_variable_usage/fns';
import MetricsManagerGetters from 'bundles/p13n/modules/metrics_manager/getters';
import OptimizelyChampagneActions from 'optly/modules/optimizely_champagne/actions';
import OptimizelyChampagneEnums from 'optly/modules/optimizely_champagne/enums';
import P13NDashboardGetters from 'bundles/p13n/modules/dashboard/getters';
import ViewGetters from 'optly/modules/entity/view/getters';
import { isContextualMultiArmedBanditPolicy } from 'bundles/p13n/components/traffic_allocation/traffic_allocation_policy/fns';

// FOR OUTLIER FILTERING WHITELIST
import AdminAccountGetters from 'optly/modules/admin_account/getters';
import PermissionsModuleFns from 'optly/modules/permissions/fns';
import PermissionsModuleGetters from 'optly/modules/permissions/getters';

import fns from './fns';
import { ExploitationRate } from './constants';

('bundles/p13n/components/traffic_allocation/traffic_allocation_policy/constants.js');
// END OUTLIER FILTERING WHITELIST

const STORE_NAME = 'currently_editing_experiment';

export const selectedAudienceIds = [STORE_NAME, 'selectedAudienceIds'];

export const name = [
  [STORE_NAME, 'name'],
  LayerSettingsGetters.name,
  (editingExperimentName, layerSettingsName) =>
    editingExperimentName || layerSettingsName || null,
];

export const description = [
  [STORE_NAME, 'description'],
  LayerSettingsGetters.description,
  (editingExperimentDescription, layerSettingsDescription) =>
    editingExperimentDescription || layerSettingsDescription || null,
];

export const currentExperimentId = [STORE_NAME, 'currentExperimentId'];

export const selectedViewIds = [STORE_NAME, 'selectedViewIds'];

export const urlTargetingConfig = [STORE_NAME, 'urlTargetingConfig'];

export const targetingType = function(policy) {
  return [
    [STORE_NAME, 'targetingType'],
    PermissionsModuleGetters.canUseUrlTargeting,
    function(targetType, canUseUrlTargeting) {
      if (!targetType) {
        let variation;

        if (policy !== LayerEnums.policy.MULTIVARIATE) {
          variation = OptimizelyChampagneActions.activate(
            OptimizelyChampagneEnums.TestUrlTargetingSetup.EXPERIMENT_KEY,
          );
        }

        if (!variation) {
          return CurrentLayerFns.getDefaultTargetingType(canUseUrlTargeting);
        }

        // If the user is bucketed into a variation, we are working around
        // the whitelist so we'll roll our own targeting type here
        const shouldRenderWithUrlTargeting =
          variation ===
          OptimizelyChampagneEnums.TestUrlTargetingSetup.Variations
            .SIMPLIFIED_CREATION_FLOW;
        if (shouldRenderWithUrlTargeting) {
          return LayerConstants.TargetingTypes.URL;
        }
        return LayerConstants.TargetingTypes.SAVED_PAGES;
      }

      return targetType;
    },
  ];
};

export const selectedViews = [
  selectedViewIds,
  ViewGetters.entityCache,
  (viewIds, views) => viewIds.map(id => views.get(id)),
];

export const availableViews = [
  CurrentProjectGetters.views,
  views => views.filter(view => !view.get('archived')),
];

export const selectedAudiences = [
  selectedAudienceIds,
  AudienceGetters.entityCache,
  (audienceIds, audiences) => audienceIds.map(id => audiences.get(id)),
];

export const availableAudiences = [
  CurrentProjectGetters.audiences,
  audiences =>
    audiences.filter(
      audience => !audience.get('archived') && audience.get('user_touched'),
    ),
];

export const audienceMatchType = [STORE_NAME, 'audienceMatchType'];

export const audienceConditions = [STORE_NAME, 'audienceConditions'];

// TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
export const audienceConditionsJson = [STORE_NAME, 'audienceConditionsJson'];

/**
 * Gets the traffic allocation policy for current layer experiment stored inside the entity cache
 */
export const currentLayerExperimentTrafficAllocationPolicy = [
  currentExperimentId,
  LayerExperiment.getters.entityCache,
  (experimentId, experimentEntityCache) => {
    const experimentInEntityCache = experimentEntityCache.get(experimentId);
    if (
      !experimentInEntityCache ||
      !experimentInEntityCache.get('allocation_policy')
    ) {
      return 'manual';
    }
    return experimentInEntityCache.get('allocation_policy');
  },
];

export const weightDistributions = [STORE_NAME, 'weightDistributions'];

export const layerHoldback = [STORE_NAME, 'layerHoldback'];

/**
 * Gets the sections. Relevant if it's an MVT.
 */
export const sections = [STORE_NAME, 'sections'];

/**
 * Gets the combination count based on the variations inside the sections
 */
export const combinationCount = [
  sections,
  experimentSections => fns.getCombinationCount(experimentSections),
];

/**
 * Return boolean denoting whether experiment is live or not.
 * Live means the experiment is either Running or Paused
 * @type {Boolean}
 */
export const isLive = [
  currentExperimentId,
  LayerExperiment.getters.entityCache,
  (experimentId, experimentEntityCache) => {
    const experiment = experimentEntityCache.get(experimentId);
    if (!experiment) {
      return false;
    }
    return fns.isExperimentLive(experiment);
  },
];

/**
 * Return boolean denoting whether experiment is Running.
 * @type {Boolean}
 */
export const isRunning = [
  currentExperimentId,
  LayerExperiment.getters.entityCache,
  (experimentId, experimentEntityCache) => {
    const experiment = experimentEntityCache.get(experimentId);
    if (!experiment) {
      return false;
    }
    return fns.isExperimentRunning(experiment);
  },
];

/**
 * Return boolean denoting whether experiment is Paused.
 * @type {Boolean}
 */
export const isPaused = [
  currentExperimentId,
  LayerExperiment.getters.entityCache,
  (experimentId, experimentEntityCache) => {
    const experiment = experimentEntityCache.get(experimentId);
    if (!experiment) {
      return false;
    }
    return fns.isExperimentPaused(experiment);
  },
];

/**
 * Variations getter for web consumers who don't need / care about live variables (e.g. Web)
 * We strip the variables here to avoid causing trouble for variable naïve consumers
 */
export const variations = [
  [STORE_NAME, 'variations'],
  layerHoldback,
  (experimentVariations, holdback) => {
    if (!variations) {
      return null;
    }
    experimentVariations = fns.addTrafficDistributionInfoToVariations(
      experimentVariations,
      holdback,
    );
    return fns.stripVariableValuesFromVariations(experimentVariations);
  },
];

/**
 * Variations getter for consumers who want live variables (Oasis)
 */
export const variationsWithVariables = [
  [STORE_NAME, 'variations'],
  layerHoldback,
  fns.addTrafficDistributionInfoToVariations,
];

export const bucketingStrategy = [STORE_NAME, 'bucketingStrategy'];

/**
 * Gets the traffic allocation policy for current layer experiment
 */
export const currentlySelectedTrafficAllocationPolicy = [
  STORE_NAME,
  'currentlySelectedTrafficAllocationPolicy',
];

/**
 * Gets the distribution Goal for current layer experiment
 */
export const currentlySelectedDistributionGoal = [
  STORE_NAME,
  'currentlySelectedDistributionGoal',
];

/**
 * Gets the is automated distribution goal for current layer experiment
 */
export const isAutomaticDistributionGoal = [
  STORE_NAME,
  'isAutomaticDistributionGoal',
];

/**
 * Gets the exploitation rate for current layer experiment
 */
export const exploitationRate = [STORE_NAME, 'exploitationRate'];

/**
 * Returns validity of traffic allocation policy and distribution rate values
 */
export const currentlySelectedTrafficAllocationPolicyAndDistributionRatesAreValid = [
  currentlySelectedTrafficAllocationPolicy,
  exploitationRate,
  isAutomaticDistributionGoal,
  (
    selectedTrafficAllocationPolicy,
    exploitationRateValue,
    isAutomaticDistributionGoalValue,
  ) => {
    if (selectedTrafficAllocationPolicy !== 'contextual-multi-arm-bandit') {
      return true;
    }

    if (isAutomaticDistributionGoalValue) {
      return true;
    }

    return (
      !isAutomaticDistributionGoalValue &&
      exploitationRateValue <= ExploitationRate.MAX &&
      exploitationRateValue >= ExploitationRate.MIN
    );
  },
];
/**
 * Returns validity of traffic allocation policy and number of variations
 */
export const currentlySelectedTrafficAllocationPolicyAndNumberOfVariationsAreValid = [
  currentlySelectedTrafficAllocationPolicy,
  variations,
  CurrentLayerGetters.isPersonalizationLayer,
  (allocationPolicy, experimentVariations, isP13N) => {
    // In p13n layer, the holdback isn't counted as a variation
    const numVariationsTotal = isP13N
      ? experimentVariations.size + 1
      : experimentVariations.size;
    return LayerExperiment.fns.allocationPolicyAndNumberOfVariationsAreValid(
      allocationPolicy,
      numVariationsTotal,
    );
  },
];

/**
 * Returns validity of traffic allocation policy and potential absence of primary metric
 * This validator is separate from currentlySelectedTrafficAllocationPolicyAndSelectedPrimaryMetricAreValid in order to
 * produce different error msgs to the user.
 */
export const currentlySelectedTrafficAllocationPolicyAndAbsenceOfPrimaryMetricValid = [
  CurrentLayerGetters.layer,
  currentlySelectedTrafficAllocationPolicy,
  CurrentLayerGetters.layerPrimaryMetricFromList,
  MetricsManagerGetters.primaryWorkingMetric,
  (
    layer,
    allocationPolicy,
    savedPrimaryMetric,
    currentlySelectedPrimaryMetric,
  ) => {
    // layer may not exist yet because in creation dialog
    const primaryMetric = layer
      ? savedPrimaryMetric
      : currentlySelectedPrimaryMetric;
    return LayerExperiment.fns.allocationPolicyAndAbsenceOfPrimaryMetricAreValid(
      allocationPolicy,
      primaryMetric,
    );
  },
];

/**
 * Returns validity of traffic allocation policy and selected primary metric
 */
export const currentlySelectedTrafficAllocationPolicyAndSelectedPrimaryMetricAreValid = [
  currentlySelectedTrafficAllocationPolicy,
  MetricsManagerGetters.primaryWorkingMetric,
  LayerExperiment.fns.allocationPolicyAndSelectedPrimaryMetricAreValid,
];

/**
 * Returns validity of traffic allocation policy configurations
 */
export const currentlySelectedTrafficAllocationPolicyConfigurationsAreValid = [
  currentlySelectedTrafficAllocationPolicyAndNumberOfVariationsAreValid,
  currentlySelectedTrafficAllocationPolicyAndAbsenceOfPrimaryMetricValid,
  currentlySelectedTrafficAllocationPolicyAndSelectedPrimaryMetricAreValid,
  currentlySelectedTrafficAllocationPolicyAndDistributionRatesAreValid,
  (
    numVariationsIsValid,
    absenceOfPrimaryMetricIsValid,
    primaryMetricIsValid,
    distributionRateIsValid,
  ) =>
    numVariationsIsValid &&
    absenceOfPrimaryMetricIsValid &&
    primaryMetricIsValid &&
    distributionRateIsValid,
];

export const userAttributes = [STORE_NAME, 'userAttributes'];

/**
 * This getter gives you the data for the LayerExperiment.actions.save method to consume.
 * This is used when creating AB tests, and creating experiences in the "Create Experience"
 * dialog in p13n.
 * TODO: Remove toJS() from these getters and other getters in this module.
 * JIRA: https://optimizely.atlassian.net/browse/APP-1222
 */
export const experimentToSave = [
  currentExperimentId,
  name,
  selectedAudienceIds,
  selectedViewIds,
  variations,
  bucketingStrategy,
  CurrentLayerGetters.id,
  CurrentProjectGetters.id,
  // TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
  audienceConditions,
  audienceConditionsJson,
  currentlySelectedTrafficAllocationPolicy,
  currentlySelectedDistributionGoal,
  layerHoldback,
  exploitationRate,
  isAutomaticDistributionGoal,
  PermissionsModuleGetters.canUseContextualMultiArmedBandits,
  userAttributes,
  (
    experimentId,
    experimentName,
    audienceIds,
    viewIds,
    experimentVariations,
    strategy,
    layerId,
    projectId,
    audConditions,
    audConditionsJson,
    trafficAllocationPolicy,
    distributionGoal,
    holdback,
    exploitationRateValue,
    isAutomaticDistributionGoalValue,
    canUseContextualMultiArmedBandits,
    attributes,
  ) => {
    const updatedVariations = fns.updateVariationsForSave(
      viewIds,
      toImmutable(experimentVariations),
    );

    const experimentData = {
      id: experimentId || null,
      name: experimentName,
      layer_id: layerId,
      project_id: projectId,
      variations: updatedVariations.toJS(),
      bucketing_strategy: strategy,
      audience_ids: audienceIds.toJS(),
      audience_conditions: audConditions,
      audience_conditions_json: audConditionsJson,
      allocation_policy: trafficAllocationPolicy,
      layer_holdback: holdback,
      cmab_distribution_exploitation_rate: exploitationRateValue || null,
      is_cmab_automatic_distribution: isAutomaticDistributionGoalValue || false,
    };

    if (
      canUseContextualMultiArmedBandits &&
      isContextualMultiArmedBanditPolicy(trafficAllocationPolicy) &&
      attributes &&
      attributes.length > 0
    ) {
      experimentData.cmab_attributes = attributes;
    }

    return experimentData;
  },
];

export const errors = [STORE_NAME, 'errors'];

export const urlTargetingConditionsError = [
  errors,
  allErrors => allErrors.get('urlTargetingConditions'),
];

export const urlTargetingApiNameError = [
  errors,
  allErrors => allErrors.get('urlTargetingApiName'),
];

export const urlTargetingUrlError = [
  errors,
  allErrors => allErrors.get('urlTargetingUrl'),
];

export const savedPagesError = [errors, allErrors => allErrors.get('views')];

export const layerDataToSave = function(policy) {
  return [
    AdminAccountGetters.accountPermissions,
    currentExperimentId,
    name,
    description,
    CurrentProjectGetters.id,
    CurrentLayerGetters.id,
    selectedViewIds,
    MetricsManagerGetters.outlierFilter,
    urlTargetingConfig,
    targetingType(policy),
    (
      accountPermissions,
      experimentId,
      layerName,
      layerDescription,
      currentProjectId,
      layerId,
      viewIds,
      outlierFilter,
      urlTargetConfig,
      targetType,
    ) => {
      const layerData = {
        holdback: 0,
        policy,
        name: layerName,
        description: layerDescription,
        project_id: currentProjectId,
        id: layerId || null,
        metrics: [],
        view_ids: viewIds.toJS(),
      };

      if (
        !PermissionsModuleFns.canUseOutlierFiltering(
          accountPermissions,
          toImmutable(layerData),
        )
      ) {
        // If the account does not have permission to use outlier filtering, always set to false
        layerData.outlier_filter = {
          enabled: false,
        };
      } else if (!layerData.id) {
        // Always set to true for a brand new experiment that has the feature enabled, no need to check the metrics manager state
        layerData.outlier_filter = {
          enabled: true,
        };
      } else {
        layerData.outlier_filter = outlierFilter.toJS();
      }

      if (targetType === LayerConstants.TargetingTypes.URL) {
        layerData.url_targeting = urlTargetConfig.toJS();
        delete layerData.view_ids;
      }

      return layerData;
    },
  ];
};

export const selectedAudiencesInReadableEnglish = [
  audienceConditions,
  P13NDashboardGetters.activeAudiences,
  AudienceFns.getPlaceHolderNameFromAudiences,
];

export const selectedVariableIds = [STORE_NAME, 'selectedVariableIds'];

export const selectedVariables = [
  selectedVariableIds,
  LiveVariableGetters.entityCache,
  (variableIds, variables) => variableIds.map(id => variables.get(id)),
];

export const variableUsagesToSave = [
  variationsWithVariables,
  selectedVariables,
  currentExperimentId,
  CurrentProjectGetters.id,
  (experimentVariations, variables, experimentId, projectId) =>
    variables.map(variable => {
      const variableId = variable.get('id');
      const usageInstances = experimentVariations.map(variation => {
        const valueToSet =
          variation.getIn(['variable_values', variableId]) ||
          variable.get('default_value');
        return {
          variation_id: variation.get('api_name'),
          value: String(valueToSet),
        };
      });

      return LiveVariableUsageFns.createLiveVariableUsageEntity({
        experiment_id: experimentId,
        live_variable_id: variableId,
        project_id: projectId,
        usage_instances: usageInstances.toJS(),
      });
    }),
];

/**
 * The experiment sections to save for the multivariate layer from the New Multivariate Test dialog.
 */
export const experimentSectionsToCreateForMVT = [
  selectedViewIds,
  sections,
  CurrentProjectGetters.id,
  (viewIds, experimentSections, currentProjectId) => {
    if (!experimentSections) {
      return null;
    }
    return experimentSections.map(section => {
      const experimentVariations = section.get('variations');
      const updatedVariations = fns.updateVariationsForSave(
        viewIds,
        toImmutable(experimentVariations),
      );
      return {
        name: section.get('name'),
        variations: updatedVariations.toJS(),
        project_id: currentProjectId,
      };
    });
  },
];

/**
 * The layer experiment to save for the multivariate layer from the New Multivariate Test dialog.
 * NB: By default, we set the multivariate_traffic_policy to full factorial.
 */
export const layerExperimentToCreateForMVT = [
  name,
  selectedAudienceIds,
  CurrentProjectGetters.id,
  (experimentName, audienceIds, projectId) => ({
    name: experimentName,
    project_id: projectId,
    multivariate_traffic_policy:
      LayerExperiment.enums.multivariateTrafficPolicies.FULL_FACTORIAL,
  }),
];

export default {
  selectedAudienceIds,
  name,
  description,
  currentExperimentId,
  selectedViewIds,
  urlTargetingConfig,
  targetingType,
  selectedViews,
  availableViews,
  selectedAudiences,
  availableAudiences,
  audienceMatchType,
  // TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
  audienceConditions,
  audienceConditionsJson,
  currentLayerExperimentTrafficAllocationPolicy,
  currentlySelectedTrafficAllocationPolicy,
  currentlySelectedDistributionGoal,
  currentlySelectedTrafficAllocationPolicyAndNumberOfVariationsAreValid,
  currentlySelectedTrafficAllocationPolicyAndAbsenceOfPrimaryMetricValid,
  currentlySelectedTrafficAllocationPolicyAndSelectedPrimaryMetricAreValid,
  currentlySelectedTrafficAllocationPolicyConfigurationsAreValid,
  currentlySelectedTrafficAllocationPolicyAndDistributionRatesAreValid,
  isAutomaticDistributionGoal,
  exploitationRate,
  weightDistributions,
  layerHoldback,
  sections,
  combinationCount,
  isLive,
  isRunning,
  isPaused,
  variations,
  variationsWithVariables,
  bucketingStrategy,
  experimentToSave,
  errors,
  urlTargetingConditionsError,
  urlTargetingApiNameError,
  urlTargetingUrlError,
  savedPagesError,
  layerDataToSave,
  selectedAudiencesInReadableEnglish,
  selectedVariableIds,
  selectedVariables,
  variableUsagesToSave,
  experimentSectionsToCreateForMVT,
  layerExperimentToCreateForMVT,
  userAttributes,
};

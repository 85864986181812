import schema from 'js-schema';

import enums from './enums';

const fieldTypeValues = Object.values(enums.fieldType);
const plugTypeValues = Object.values(enums.plugin_type);

const FieldSchema = schema({
  name: String,
  label: String,
  field_type: fieldTypeValues,
  default_value: [String, Array, Number], // multi-select field default values are arrays
});

export const Plugin = schema({
  id: Number,
  project_id: Number,
  plugin_type: plugTypeValues,
  is_enabled_in_client: Boolean,
  name: String,
  description: [null, String],
  '?edit_page_url': [null, String],
  form_schema: Array.of(FieldSchema),
  options: Object,
  '?compiled_js': [null, String],
});

export default {
  Plugin,
};

export const searchEntityTypes = {
  AUDIENCE: 'audience',
  EXPERIMENT: 'experiment',
  CAMPAIGN: 'campaign',
  PAGE: 'page',
};
export const searchSortOrders = {
  ASC: 'asc',
  DESC: 'desc',
};
export const searchSortableKeys = {
  ID: 'id',
  NAME: 'name',
  STATUS: 'status',
  CREATED: 'created',
  LAST_MODIFIED: 'last_modified',
  EXPERIMENT_TYPE: 'experiment_type',
  EXPERIMENT_COUNT: 'experiment_count',
};
export const searchDefaultSortOrder = {
  [searchSortableKeys.STATUS]: searchSortOrders.DESC,
};
export const searchFilterableStatuses = {
  RUNNING: 'running',
  PAUSED: 'paused',
  ARCHIVED: 'archived',
  NOT_STARTED: 'not_started',
  CAMPAIGN_PAUSED: 'campaign_paused',
  CONCLUDED: 'concluded',
};
export const searchFilterableLayerTypes = {
  ALL: 'all',
  AB: 'a/b',
  MULTIARMED_BANDIT: 'multiarmed_bandit',
  MULTIVARIATE: 'multivariate',
  PERSONALIZATION: 'personalization',
};
export const searchExpandFields = {
  EXPERIMENT_TYPE: 'experiment_type',
  FEATURE_NAME: 'feature_name',
  FEATURE_KEY: 'feature_key',
};
export const MAX_PAGE_SIZE = 25;
export const ENTITY_UPDATE_REINDEX_DELAY_MS = 2000;
export const EXPERIMENT_SUMMARIES_API_ENDPOINT = 'experiment_summaries';
export const SEARCH_API_ENDPOINT = 'search';
export const LOADING_ID = 'loading-search';

export default {
  searchEntityTypes,
  searchSortOrders,
  searchSortableKeys,
  searchDefaultSortOrder,
  searchFilterableStatuses,
  searchFilterableLayerTypes,
  searchExpandFields,
  MAX_PAGE_SIZE,
  ENTITY_UPDATE_REINDEX_DELAY_MS,
  EXPERIMENT_SUMMARIES_API_ENDPOINT,
  SEARCH_API_ENDPOINT,
  LOADING_ID,
};

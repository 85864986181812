import flux from 'core/flux';
import { toImmutable } from 'optly/immutable';

import { actions as P13NDashboardActions } from 'bundles/p13n/modules/dashboard';
import { actions as P13NUIActions } from 'bundles/p13n/modules/ui';

import actionTypes from './action_types';

export const addAudienceId = audienceId => {
  flux.dispatch(actionTypes.EXPERIENCE_MANAGER_ADD_AUDIENCE_ID, {
    audienceId,
  });
};

export const clearOrderedAudienceIds = () => {
  flux.dispatch(actionTypes.EXPERIENCE_MANAGER_SET_ORDERED_AUDIENCE_IDS, {
    orderedAudienceIds: toImmutable([]),
  });
};

export const editAudience = audienceId => {
  P13NDashboardActions.fetchOrCreateAudience(audienceId).then(
    P13NUIActions.showAudienceEditorDialog,
  );
};

export const removeAudienceId = audienceId => {
  flux.dispatch(actionTypes.EXPERIENCE_MANAGER_REMOVE_AUDIENCE_ID, {
    audienceId,
  });
};

export const setOrderedAudienceIds = orderedAudienceIds => {
  flux.dispatch(actionTypes.EXPERIENCE_MANAGER_SET_ORDERED_AUDIENCE_IDS, {
    orderedAudienceIds,
  });
};

export default {
  addAudienceId,
  clearOrderedAudienceIds,
  editAudience,
  removeAudienceId,
  setOrderedAudienceIds,
};

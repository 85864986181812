import { toImmutable } from 'optly/immutable';

export const NONE_GROUP = toImmutable({
  name: 'None',
  id: 0,
});

export const NONE_GROUP_ID = 0;

export const MAX_TRAFFIC_ALLOCATION = 10000;
export const TRAFFIC_ALLOCATION_DENOMINATOR = 100;
export const ZERO_TRAFFIC_ALLOCATION = 0;

export default {
  NONE_GROUP,
  NONE_GROUP_ID,
  MAX_TRAFFIC_ALLOCATION,
  TRAFFIC_ALLOCATION_DENOMINATOR,
  ZERO_TRAFFIC_ALLOCATION,
};

import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import truncate from 'optly/filters/truncate';

import { actions as EditorIframeActions } from 'bundles/p13n/modules/editor_iframe';
import HighlighterEnums from 'optly/modules/highlighter/enums';

const ALLOWED_CHARACTER_COUNT = 32;

/**
 *
 * @param {Event} event
 * @param {String} activeFrameId
 * @param {String} selector
 * @param {Boolean} removeHighlight - defaults to false
 */
const updateIframeHighlighter = (
  event,
  activeFrameId,
  selector,
  removeHighlight = false,
) => {
  const highlightOptions = {
    id: activeFrameId,
    selector,
    type: HighlighterEnums.IFrameHighlightTypes.HOVERED,
  };
  if (removeHighlight) {
    EditorIframeActions.unhighlightElement(highlightOptions);
  } else {
    EditorIframeActions.highlightElement(highlightOptions);
  }
};

const SelectorLink = (props, context) => (
  <li
    className={classNames({
      hard: true,
    })}
    onMouseEnter={_.partialRight(
      updateIframeHighlighter,
      props.activeFrameId,
      props.selector,
    )}
    onMouseLeave={_.partialRight(
      updateIframeHighlighter,
      props.activeFrameId,
      props.selector,
      true,
    )}
    onClick={_.partialRight(props.applyNewSelector, props.selector)}>
    <a
      className={classNames({
        flex: true,
        muted: props.currentlyWorkingSelector,
        'soft--sides': true,
        'soft-half--ends': true,
      })}
      title={props.selector}>
      {truncate(props.selector, ALLOWED_CHARACTER_COUNT)}
    </a>
  </li>
);

SelectorLink.propTypes = {
  /**
   * The Vue iframe component ID to interact with
   */
  activeFrameId: PropTypes.string.isRequired,
  /**
   * Function to apply new selector state to the component consuming selector input
   */
  applyNewSelector: PropTypes.func.isRequired,
  /**
   * Boolean describing whether the selector passed is the currently working selector
   */
  currentlyWorkingSelector: PropTypes.bool.isRequired,
  /**
   * String value of the currently working or related selector
   */
  selector: PropTypes.string.isRequired,
};

export default SelectorLink;

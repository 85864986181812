const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const enums = require('../enums');

const actionTypes = require('../action_types');

const DIRTY_SETTINGS_INITIAL_STATE = {};
DIRTY_SETTINGS_INITIAL_STATE[enums.tabs.SETTINGS] = false;

/**
 * Responsible for the following state management:
 * tracking the state of the campaign manager
 */
module.exports = Nuclear.Store({
  getInitialState() {
    const initialState = {
      creatingNewVariation: false,
      dirtySettingsTabs: DIRTY_SETTINGS_INITIAL_STATE,
      selectedSettingsTab: enums.settingsTabs.CAMPAIGN_SETTINGS,
      selectedTab: null,
      // ID of variation currently being renamed, or null if no variation is
      // being renamed
      currentlyRenamingVariationId: null,
    };

    return toImmutable(initialState);
  },

  initialize() {
    this.on(actionTypes.P13N_CAMPAIGN_SELECT_TAB, setTab);
    this.on(actionTypes.P13N_CAMPAIGN_SET_SETTINGS_TAB, setSettingsTab);
    this.on(actionTypes.P13N_CAMPAIGN_SET_SETTINGS_DIRTY, setSettingsDirty);
    this.on(actionTypes.P13N_CAMPAIGN_RESET_SETTINGS_DIRTY, resetSettingsDirty);
    this.on(
      actionTypes.P13N_SET_CREATING_VARIATION_STATUS,
      setCreatingVariationStatus,
    );
    this.on(actionTypes.P13N_START_CREATING_VARIATION, startCreatingVariation);
    this.on(actionTypes.P13N_STOP_CREATING_VARIATION, stopCreatingVariation);
    this.on(actionTypes.P13N_START_RENAMING_VARIATION, startRenamingVariation);
    this.on(actionTypes.P13N_STOP_RENAMING_VARIATION, stopRenamingVariation);
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {CampaignManager.enums.tabs} payload.tab
 */
function setTab(state, payload) {
  return state.set('selectedTab', payload.tab);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {CampaignManager.enums.settingsTabs} payload.tab
 */
function setSettingsTab(state, payload) {
  return state.set('selectedSettingsTab', payload.tab);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {string} payload.tab
 * @param {Boolean} payload.isDirty
 */
function setSettingsDirty(state, payload) {
  return state.setIn(['dirtySettingsTabs', payload.tab], payload.isDirty);
}

/**
 * @param {Immutable.Map} state
 */
function resetSettingsDirty(state) {
  return state.set(
    'dirtySettingsTabs',
    toImmutable(DIRTY_SETTINGS_INITIAL_STATE),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {boolean} payload.status
 */
function setCreatingVariationStatus(state, payload) {
  return state.set('creatingNewVariation', payload.status);
}

function startCreatingVariation(state) {
  return state.merge({
    currentlyRenamingVariationId: null,
    creatingNewVariation: true,
  });
}

function stopCreatingVariation(state) {
  return state.set('creatingNewVariation', false);
}

function startRenamingVariation(state, payload) {
  return state.merge({
    currentlyRenamingVariationId: payload.variationId,
    creatingNewVariation: false,
  });
}

function stopRenamingVariation(state) {
  return state.set('currentlyRenamingVariationId', null);
}

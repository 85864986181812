/**
 * Entity Definition for tag
 */
export const entity = 'tags';

export const parent = {
  entity: 'views',
  key: 'view_id',
};

export default {
  entity,
  parent,
};

const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const actionTypes = require('../action_types');

const LayerExperimentEnums = require('optly/modules/entity/layer_experiment/enums')
  .default;

const DIRTY_CUSTOM_CODE_INITIAL_STATE = {};
DIRTY_CUSTOM_CODE_INITIAL_STATE[
  LayerExperimentEnums.CustomCodeTypes.CSS
] = false;
DIRTY_CUSTOM_CODE_INITIAL_STATE[
  LayerExperimentEnums.CustomCodeTypes.JAVASCRIPT
] = false;

/**
 * Custom Code Store
 */
module.exports = Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   */
  getInitialState() {
    return toImmutable({
      tabType: undefined,
      customCodePanelShown: false,
      dirtyCustomCodeTabs: DIRTY_CUSTOM_CODE_INITIAL_STATE,
    });
  },

  initialize() {
    this.on(
      actionTypes.CUSTOM_CODE_PANEL_VISIBILITY_SET,
      setCustomCodePanelVisibility,
    );
    this.on(actionTypes.CUSTOM_CODE_PANEL_TAB_SET, setCustomCodePanelTab);
    this.on(actionTypes.CUSTOM_CODE_TAB_DIRTY_SET, setCustomCodeTabDirty);
    this.on(actionTypes.CUSTOM_CODE_TABS_DIRTY_RESET, resetCustomCodeTabsDirty);
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Boolean} payload.shown
 */
function setCustomCodePanelVisibility(state, payload) {
  return state.set('customCodePanelShown', !!payload.shown);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Boolean} payload.tab
 */
function setCustomCodePanelTab(state, payload) {
  return state.set('tabType', payload.tab);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {string} payload.tab
 * @param {Boolean} payload.isDirty
 */
function setCustomCodeTabDirty(state, payload) {
  return state.setIn(['dirtyCustomCodeTabs', payload.tab], payload.isDirty);
}

/**
 * @param {Immutable.Map} state
 */
function resetCustomCodeTabsDirty(state) {
  return state.set(
    'dirtyCustomCodeTabs',
    toImmutable(DIRTY_CUSTOM_CODE_INITIAL_STATE),
  );
}

const { toImmutable } = require('optly/immutable');
const ui = require('core/ui').default;

const CurrentProjectFns = require('optly/modules/current_project/fns');
const CurrentProjectGetters = require('optly/modules/current_project/getters');
const LiveVariableGetters = require('optly/modules/entity/live_variable/getters');

const ProjectFns = require('optly/modules/entity/project/fns');

const LiveVariableDialogReactComponent = require('./main_content');
const template = require('./template.html');

module.exports = {
  componentId: 'live-variable-dialog',

  replace: true,

  template,

  ready() {
    const immutableVariable = toImmutable(this.$options.variable);
    ui.renderReactComponent(this, {
      component: LiveVariableDialogReactComponent,
      el: this.$$.reactContainer,
      dataBindings: {
        currentProjectVariables: [
          LiveVariableGetters.entityCache,
          CurrentProjectGetters.id,
          // TODO(delikat): clean this up
          (variables, id) => CurrentProjectFns.filterByProjectId(variables, id),
        ],
      },
      props: {
        // TODO(delikat): add proper permisison check here
        canCreateVariable: true,
        language: this.$options.language,
        syntaxHighlightingLanguage: ProjectFns.getSyntaxHighlightingLanguage(
          this.$options.language,
        ),
        variable: immutableVariable,
      },
    });
  },
};

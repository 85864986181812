export default {
  PROJECT_ROLES: [
    'Viewer',
    'Editor',
    'Restricted Editor',
    'Project Owner',
    'Publisher',
    'Restricted Publisher',
  ], // All ProjectToUserRoles
  ACCOUNT_ROLES_USER: ['User'], // for when we don't want to expose the non assignable Project Creator
  ACCOUNT_ROLES_ADMIN: ['Administrator'], // Admin AccountToUserRoles
  ACCOUNT_ROLES_NON_ADMIN: ['Project Creator', 'User'], // Non-Admin AccountToUserRoles
  TOTAL_REPORTING_SLOTS: 10,
  TOTAL_REPORTING_SLOTS_CUSTOM_PROJECT: 25,
  LAST_VIEWED_PROJECT_COOKIE_ID: 'optimizely_last_viewed_project_id',
  LIMITED_COOKIE_AGE: 7, // unit is days
};

/**
 * Reusable Dialog Frame Component
 *
 * Represents the outer frame of a dialog window
 * To be used in conjunction with the dialog-manager component
 */
import $ from 'jquery';

import htmlTemplate from './dialog_frame.html';

const exported = {
  template: htmlTemplate,
  replace: true,

  data: {
    zIndex: 0,
    /**
     * @var {Boolean} dont allow escaping via closer button
     */
    noEscape: false,
    noPadding: false,

    fullHeight: false,
    fullScreen: false,

    /**
     * @type {Boolean} pushes the dialog to the left
     */
    shouldApplyNegativeLeftMargin: false,
  },

  methods: {
    /**
     * Centers the dialog vertically
     */
    _centerDialog() {
      const $frame = $(this.$el);
      const dialogHeight = $frame.height();
      const windowHeight = $(window).height();

      const top = windowHeight / 2 - dialogHeight / 2;

      $frame.css('top', top);
    },
  },

  ready() {
    this.$on('centerDialog', this._centerDialog.bind(this));
  },
};

export default exported;

export const { template, replace, data, methods, ready } = exported;

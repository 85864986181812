/**
 * Get plugin layer counts of a project
 * @param {Immutable.Map} plugin counts cache
 * @param {Number} project id
 * @returns {Immutable.Map} Map of all plugin counts related to single project
 */
export function getLayerCountsofProject(entityMap, projectId) {
  return entityMap.filter(
    entity => entity && entity.get('project_id') === projectId,
  );
}

/**
 * Get no of layers that plugin is used in
 * @param {Immutable.Map} plugin
 * @param {Immutable.List} List of layer count of all plugins
 * @returns {Number} No of layers given plugin is used
 */
export function getLayerCountofPlugin(plugin, pluginLayerCounts) {
  const layerCount = pluginLayerCounts.filter(
    count => count.get('id') === plugin.get('plugin_id'),
  );
  return layerCount.size ? layerCount.first().get('experiment_count') : 0;
}

export default {
  getLayerCountofPlugin,
  getLayerCountsofProject,
};

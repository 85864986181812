const $ = require('jquery');

/**
 * Get resource size. Will only work on CORS enabled endpoints
 *
 * @param {string} url
 * @returns {Promise}
 */
exports.getResourceSize = function(url) {
  const deferred = $.Deferred();
  const xhr = new XMLHttpRequest();

  xhr.onload = function() {
    const length = xhr.getResponseHeader('Content-Length');
    if (length) {
      deferred.resolve(Number(length));
    } else {
      deferred.reject();
    }
  };

  xhr.onerror = deferred.reject;
  xhr.open('GET', url, true);
  xhr.send();
  return deferred;
};

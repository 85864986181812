import flux from 'core/flux';

import actionTypes from './action_types';
import { actions as MetricsManagerModuleActions } from 'bundles/p13n/modules/metrics_manager';
import { constants } from 'optly/modules/entity/project';

export const pushMetricWrapperToTop = (
  metricWrapper,
  metricWrappers,
  canEditPrimaryMetric,
) => {
  let newMetricWrappers = metricWrappers.filterNot(
    wrapper => wrapper.get('alias') === metricWrapper.get('alias'),
  );

  // Reinserting metric in the 2nd position if the 1st (primary) metric is locked
  if (!canEditPrimaryMetric) {
    newMetricWrappers = newMetricWrappers.splice(1, 0, metricWrapper);
  } else {
    // Otherwise metric is reinserted at top
    newMetricWrappers = newMetricWrappers.unshift(metricWrapper);
  }

  MetricsManagerModuleActions.updateWorkingMetricWrappers(newMetricWrappers);
};

export const pushMetricWrapperToBottom = (metricWrapper, metricWrappers) => {
  const newMetricWrappers = metricWrappers.filterNot(
    wrapper => wrapper.get('alias') === metricWrapper.get('alias'),
  );

  MetricsManagerModuleActions.updateWorkingMetricWrappers(
    newMetricWrappers.push(metricWrapper),
  );
};

export const setUseCurrentProject = shouldUse => {
  flux.dispatch(actionTypes.METRICS_PICKER_USE_CURRENT_PROJECT, shouldUse);
};

export const reset = () => {
  flux.dispatch(actionTypes.METRICS_PICKER_USE_CURRENT_PROJECT, true);
};

export default {
  pushMetricWrapperToTop,
  pushMetricWrapperToBottom,
  setUseCurrentProject,
  reset,
};

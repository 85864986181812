/**
 * A header of a sortable list. The header must be wrapped in an element with data-sortable-table-id on it.
 *
 * This is a subcomponent of data-table
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const $ = require('jquery');
const flux = require('core/flux');

const sort = require('optly/utils/sort');

const actions = require('./actions');
const getters = require('./getters');

module.exports = {
  replace: true,

  paramAttributes: ['field', 'type'],

  data: {
    // initialize to null since we cant read store value until the dom is ready
    // since it relies on the parent table and the computed will get run immediately,
    // thus not picking up the dependency.
    dir: null,
  },

  ready() {
    this.tableAttr = $(this.$el)
      .parents('[data-sortable-table-id]')
      .attr('data-sortable-table-id');

    if (!this.type) {
      // default to string
      this.type = 'string';
    }

    flux.bindVueValues(this, {
      dir: getters.fieldSortDir(this.tableAttr, this.field),
    });
  },

  computed: {
    isSortedAsc() {
      return this.dir === sort.ASC;
    },

    isSortedDesc() {
      return this.dir === sort.DESC;
    },
  },

  methods: {
    sortField() {
      actions.toggleField({
        tableId: this.tableAttr,
        field: this.field,
        type: this.type,
      });
    },
  },
};

/**
 * Entity Definition for ExperimentSections
 */

export default {
  entity: 'experiment_sections',

  parent: {
    entity: 'layer_experiments',
    key: 'layer_experiment_id',
  },
};

/**
 * Vue globals that are always available wherever the sandbox is used
 */
import _ from 'lodash';
import Vue from 'vue';

import filter_prop from 'optly/filters/filter_prop';
import format_date from 'optly/filters/format_date';
import format_datetime from 'optly/filters/format_datetime';
import format_description from 'optly/filters/format_description';
import format_file_size from 'optly/filters/format_file_size';
import format_currency from 'optly/filters/format_currency';
import format_time from 'optly/filters/format_time';
import format_phone_number from 'optly/filters/format_phone_number';
import fulldate from 'optly/filters/fulldate';
import join from 'optly/filters/join';
import list_to_readable_string from 'optly/filters/list_to_readable_string';
import format_number from 'optly/filters/format_number';
import number_to_letter from 'optly/filters/number_to_letter';
import percentage from 'optly/filters/percentage';
import relative_date from 'optly/filters/relative_date';
import truncate from 'optly/filters/truncate';
import plural from 'optly/filters/plural';
import results2_unescape from 'optly/filters/results2_unescape';
import sanitize_html from 'optly/filters/sanitize_html';
import slice from 'optly/filters/slice';
import shortdate from 'optly/filters/shortdate';
import wrap_tag from 'optly/filters/wrap_tag';
import translate from 'optly/filters/translate';

import accordion from 'core/ui/directives/accordion';
import back from 'core/ui/directives/back';
import desktop_same_window from 'core/ui/directives/desktop_same_window';
import disabled from 'core/ui/directives/disabled';
import dropdown from 'core/ui/directives/dropdown';
import el from 'core/ui/directives/el';
import flux_link from 'core/ui/directives/flux_link';
import lego_disabled from 'core/ui/directives/lego_disabled';
import loading from 'core/ui/directives/loading';
import entity_loading from 'core/ui/directives/entity_loading';
import loading_hide from 'core/ui/directives/loading_hide';
import loading_show from 'core/ui/directives/loading_show';
import loading_vm_prop from 'core/ui/directives/loading_vm_prop';
import poptip from 'core/ui/directives/poptip';
import popover from 'core/ui/directives/popover';
import push_state from 'core/ui/directives/push_state';
import resize from 'core/ui/directives/resize';
import scroll_add_classes from 'core/ui/directives/scroll_add_classes';
import spinner from 'core/ui/directives/spinner';
import tabs from 'core/ui/directives/tabs';
import value from 'core/ui/directives/value';

const directives = {
  // TODO remove these from optly/directives.js
  accordion,
  back,
  'desktop-same-window': desktop_same_window,
  disabled,
  dropdown,
  el,
  'flux-link': flux_link,
  'lego-disabled': lego_disabled,
  loading,
  'loading-entity': entity_loading,
  'loading-hide': loading_hide,
  'loading-show': loading_show,
  'loading-vm-prop': loading_vm_prop,
  poptip,
  popover,
  'push-state': push_state,
  resize,
  'scroll-classes': scroll_add_classes,
  spinner,
  tabs,
  value,
};

const filters = {
  'filter-prop': filter_prop,
  'format-date': format_date,
  'format-datetime': format_datetime,
  'format-description': format_description,
  'format-file-size': format_file_size,
  'format-currency': format_currency,
  'format-time': format_time,
  'format-phone-number': format_phone_number,
  fulldate,
  join,
  'list-to-readable-string': list_to_readable_string,
  'format-number': format_number,
  'number-to-letter': number_to_letter,
  percentage,
  'relative-date': relative_date,
  truncate,
  plural,
  'results2-unescape': results2_unescape,
  sanitizeHTML: sanitize_html,
  slice,
  shortdate,
  'wrap-tag': wrap_tag,
  translate,
};

/**
 * Initialize global Vue options, directives, & filters.
 */
export default function initializeVue() {
  Vue.config({
    // use `[[ prop ]]` style delimiters since django uses {{
    delimiters: ['[', ']'],
  });

  // register Vue globals that are part of the UI framework
  _.each(directives, (directive, id) => {
    Vue.directive(id, directive);
  });
  _.each(filters, (filter, id) => {
    Vue.filter(id, filter);
  });
}

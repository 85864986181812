import flux from 'core/flux';

import actions from './actions';
import enums from './enums';
import fns from './fns';
import getters from './getters';

const isOptimizelyX = () =>
  flux.evaluate(getters.activeVersion) === enums.versions.OPTIMIZELY_X;

const isClassic = () =>
  flux.evaluate(getters.activeVersion) === enums.versions.CLASSIC;

export { actions, enums, fns, getters, isOptimizelyX, isClassic };

export default {
  actions,
  enums,
  fns,
  getters,
  isOptimizelyX,
  isClassic,
};

import React from 'react';
import PropTypes from 'prop-types';

import { Poptip, Icon } from 'optimizely-oui';
import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';

import Immutable from 'optly/immutable';
import { fns as LayerExperimentFns } from 'optly/modules/entity/layer_experiment';

const getFormattedDate = date => tr.date.utc(date).format('MMM D, LT');

export default class SchedulePoptip extends React.Component {
  static propTypes = {
    experiment: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  getStartScheduleString = experiment => {
    let hasStartSchedule = false;
    let startScheuldeString = '';
    if (experiment) {
      hasStartSchedule = LayerExperimentFns.determineIfStartScheduled(
        experiment,
      );
      if (hasStartSchedule) {
        const experimentTimezone = LayerExperimentFns.getTimeZoneString(
          experiment,
        );
        const startTime = experiment.get('schedule').get('start_time');
        startScheuldeString = `Scheduled to start on ${getFormattedDate(
          startTime,
        )} ${experimentTimezone}.`;
      }
    }
    return startScheuldeString;
  };

  getStopScheduleString = experiment => {
    let hasStopSchedule = false;
    let stopScheuldeString = '';
    if (experiment) {
      hasStopSchedule = LayerExperimentFns.determineIfStopScheduled(experiment);
      if (hasStopSchedule) {
        const experimentTimezone = LayerExperimentFns.getTimeZoneString(
          experiment,
        );
        const stopTime = experiment.get('schedule').get('stop_time');
        stopScheuldeString = `Scheduled to stop on ${getFormattedDate(
          stopTime,
        )} ${experimentTimezone}.`;
      }
    }
    return stopScheuldeString;
  };

  render() {
    const { experiment } = this.props;
    const startScheduleString = this.getStartScheduleString(experiment);
    const stopScheduleString = this.getStopScheduleString(experiment);
    const scheduleString = `${startScheduleString} ${stopScheduleString}`.trim();

    return (
      <span>
        {scheduleString && (
          <Poptip
            testSection="schedule-poptip"
            content={scheduleString}
            position="bottom"
            isInline={true}>
            <Icon color={brandBlueDark} name="clock-thin" size="small" />
          </Poptip>
        )}
      </span>
    );
  }
}

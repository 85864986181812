import PropTypes from 'prop-types';
import React from 'react';

import { EnvironmentStatus as ENVIRONMENT_STATUS } from 'optly/modules/entity/layer_experiment/enums';
import { EXPERIMENT_ENVIRONMENT_STATUS as HR_EXPERIMENT_ENVIRONMENT_STATUS } from 'optly/modules/entity/layer_experiment/human_readable';

/**
 * Returns the human readable status text for the argument experiment
 * @param {String} status - One of ENVIRONMENT_STATUS
 * @param {Boolean} isLaunched
 * @return {String}
 */
function readableStatus(status, isLaunched) {
  // Support legacy layer_experiment.is_launched property
  // TODO: remove once is_launched is no longer supported - OASIS-2464
  if (isLaunched) {
    return tr('Launched');
  }
  return HR_EXPERIMENT_ENVIRONMENT_STATUS[status];
}

/**
 * Returns the status class name for the argument status, or undefined if there
 * is none
 * @param {String} status - One of ENVIRONMENT_STATUS
 * @return {String | undefined}
 */
function statusClassName(status, isLaunched) {
  if (isLaunched) {
    return 'color--brand';
  }

  switch (status) {
    case ENVIRONMENT_STATUS.ARCHIVED:
      return 'muted';
    case ENVIRONMENT_STATUS.NOT_STARTED:
      return 'muted';
    case ENVIRONMENT_STATUS.PAUSED:
      return '';
    case ENVIRONMENT_STATUS.RUNNING:
      return 'color--good-news';
    default:
      break;
  }
}

function SimpleEnvironmentStatus({ status, isLaunched }) {
  return (
    <div className={statusClassName(status, isLaunched)}>
      {readableStatus(status, isLaunched)}
    </div>
  );
}

SimpleEnvironmentStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(ENVIRONMENT_STATUS)).isRequired,
  isLaunched: PropTypes.bool.isRequired,
};

export default SimpleEnvironmentStatus;

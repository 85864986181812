import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonRow } from 'optimizely-oui';

const Footer = props => {
  const { onSave, isNewPage, onClose, isSaveDisabled } = props;

  return (
    <div className="flex flex-align--center border--top soft push-quad--top">
      <div className="oui-sheet__required-indicator cursor--default color--red">
        <span>* Required field</span>
      </div>
      <div className="flex--1">
        <ButtonRow
          rightGroup={[
            <Button
              style="plain"
              key="cancel"
              onClick={onClose}
              testSection="configure-view-smart-cancel">
              Cancel
            </Button>,
            <Button
              style="highlight"
              key="save"
              isDisabled={isSaveDisabled}
              onClick={onSave}
              testSection="configure-view-smart-save">
              {isNewPage ? 'Create' : 'Save'} Page
            </Button>,
          ]}
        />
      </div>
    </div>
  );
};

Footer.propTypes = {
  viewId: PropTypes.number,
  isNewPage: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Footer;

import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import { Button, Dropdown, Label, SelectDropdown, Icon } from 'optimizely-oui';

import { ZIndex } from 'optly/utils/enums';
import { enums as ViewEnums } from 'optly/modules/entity/view';
import ConditionGroup from 'optly/models/condition_group';

import PageTriggersDialog from 'bundles/p13n/components/dialogs/page_triggers';

import constants from './constants';
import fns from './fns';
import TriggerSettingsCard from './card';

const pageTriggersDialogOptions = ['url_changed', 'dom_changed'];

const TriggerSettings = props => {
  const {
    activationCode,
    canUseViewActivationModeManual,
    canUseViewActivationModePolling,
    canUseViewActivationModeCallback,
    canUseViewActivationModeUrlChanged,
    canUseViewActivationModeDomChanged,
    conditionGroup,
    conditionGroupDidMutate,
    currentProjectId,
    onUpdate,
    isWebEdgeProject,
    isSupportForDynamicWebsitesEnabled,
    getHelpLinkProps,
    snippetGlobalName,
  } = props;

  let { activationType } = props;

  const [isOpenPageTriggersDialog, setIsOpenPageTriggersDialog] = useState(
    false,
  );
  const initialActivationType = activationType;
  const initialActivationCode = cloneDeep(activationCode);

  const togglePageTriggersDialog = () => {
    setIsOpenPageTriggersDialog(!isOpenPageTriggersDialog);
  };

  const onChangeActivationType = newType => {
    let newActivationCode = constants.DEFAULT_CODE[newType];

    if (pageTriggersDialogOptions.includes(newType)) {
      togglePageTriggersDialog();
    }

    if (initialActivationType === newType) {
      newActivationCode = initialActivationCode;
    }

    // Note: we need to set the activation code value first to ensure that code mirror will render the code correctly
    onUpdate('activation_code', newActivationCode, () => {
      onUpdate('activation_type', newType);
    });
  };

  const onChangeRootConditionType = newConditionType => {
    conditionGroup.type = newConditionType;
    conditionGroupDidMutate();
  };

  activationType = activationType || ViewEnums.activationModes.IMMEDIATE;
  let availableActivationModes =
    constants.ORDERED_DEFAULT_ACTIVATION_TYPE_OPTIONS;
  if (isWebEdgeProject) {
    availableActivationModes = fns.getAvailableActivationModesForEdgeProject(
      canUseViewActivationModeManual,
      canUseViewActivationModeUrlChanged,
      canUseViewActivationModeDomChanged,
    );
    availableActivationModes = fns.addHelpTextToActivationModesForEdgeProject(
      availableActivationModes,
      getHelpLinkProps,
    );
  }

  const getTriggersButtonText = () =>
    constants.DEFAULT_ACTIVATION_TYPE_OPTIONS[activationType].label;

  const isTriggersItemSelected = currentActivationMode =>
    currentActivationMode.value === activationType;

  const isPageTriggersDialogVisible = () =>
    !isSupportForDynamicWebsitesEnabled && isOpenPageTriggersDialog;

  // TODO(WEB-3059) Use OUI Dropdown for these to fix selected item display
  return (
    <>
      {isPageTriggersDialogVisible() && (
        <PageTriggersDialog
          closeDialog={togglePageTriggersDialog}
          currentProjectId={currentProjectId}
        />
      )}
      <div data-test-section="configure-view-smart-trigger-settings">
        {availableActivationModes.length > 1 && <Label>Triggers</Label>}
        <div className="flex flex-align--center">
          {availableActivationModes.length > 1 && (
            <Dropdown
              activator={
                <Button
                  style="outline"
                  testSection="activation-type"
                  className="flex flex-align--center flex-justified--between width--300">
                  <span>{getTriggersButtonText()}</span>
                  <Icon name="angle-down" size="small" />
                </Button>
              }>
              <Dropdown.Contents minWidth={300} direction="right">
                {availableActivationModes &&
                  availableActivationModes.map(activationMode => (
                    <Dropdown.ListItem
                      key={activationMode.value}
                      className={classNames({
                        'is-selected': isTriggersItemSelected(activationMode),
                      })}>
                      <Dropdown.BlockLink
                        onClick={onChangeActivationType}
                        value={activationMode.value}
                        testSection={`dropdown-block-link-${activationMode.value}`}>
                        <Dropdown.BlockLinkText
                          text={activationMode.label}
                          isItemSelected={isTriggersItemSelected(
                            activationMode,
                          )}
                        />
                        <Dropdown.BlockLinkSecondaryText
                          secondaryText={activationMode.description}
                        />
                        {activationMode.linkText && (
                          <div
                            className="flex flex-align--center"
                            data-test-section="activation-type-help-text">
                            <Icon name="circle-exclamation" size="small" />
                            <span
                              className="push-half--left micro color--base muted"
                              style={{ marginTop: `${1}px` }}>
                              {activationMode.linkText}
                            </span>
                          </div>
                        )}
                      </Dropdown.BlockLink>
                    </Dropdown.ListItem>
                  ))}
              </Dropdown.Contents>
            </Dropdown>
          )}
          {availableActivationModes.length === 1 && (
            <span>{availableActivationModes[0].label}</span>
          )}
          <span className="push--sides">check if</span>
          <SelectDropdown
            items={constants.ROOT_TYPE_OPTIONS}
            value={conditionGroup.type}
            onChange={onChangeRootConditionType}
            width="100px"
            minDropdownWidth="300px"
            zIndex={ZIndex.ACTIVATION_MODE_DROPDOWN}
            testSection="root-type"
          />
          {conditionGroup.type === ViewEnums.conditionGroupTypes.OR ? (
            <span className="push--sides">condition is valid.</span>
          ) : (
            <span className="push--sides">conditions are valid.</span>
          )}
        </div>
        <TriggerSettingsCard {...props} />
      </div>
    </>
  );
};

TriggerSettings.propTypes = {
  activationCode: PropTypes.string,
  activationType: PropTypes.string,
  canUseViewActivationModeCallback: PropTypes.bool.isRequired,
  canUseViewActivationModeDomChanged: PropTypes.bool.isRequired,
  canUseViewActivationModeManual: PropTypes.bool.isRequired,
  canUseViewActivationModePolling: PropTypes.bool.isRequired,
  canUseViewActivationModeUrlChanged: PropTypes.bool.isRequired,
  conditionGroup: PropTypes.instanceOf(ConditionGroup).isRequired,
  conditionGroupDidMutate: PropTypes.func.isRequired,
  currentProjectId: PropTypes.number.isRequired,
  error: PropTypes.string,
  getHelpLinkProps: PropTypes.func,
  isSupportForDynamicWebsitesEnabled: PropTypes.bool,
  isWebEdgeProject: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  snippetGlobalName: PropTypes.string.isRequired,
};

TriggerSettings.defaultProps = {
  activationCode: '',
  activationType: '',
  isWebEdgeProject: false,
  isSupportForDynamicWebsitesEnabled: false,
  getHelpLinkProps: () => {},
};

export default TriggerSettings;

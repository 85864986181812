/**
 * Entity Definition for projectIntegration
 */

export const entity = 'projectintegrations';

export const isRelationshipEntity = true;

export const parents = [
  {
    entity: 'projects',
    key: 'project_id',
  },
  {
    entity: 'integrations',
    key: 'integration_id',
  },
];

export default {
  entity,
  isRelationshipEntity,
  parents,
};

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Immutable from 'optly/immutable';
import flux from 'core/flux';

import { connect } from 'core/ui/decorators';
import { getters as FilterableTableGetters } from 'optly/modules/filterable_table';
import { getters as LoadingGetters } from 'core/modules/loading';
import { actions as PageableActions } from 'optly/modules/pageable';
import { getters as SortableTableGetters } from 'optly/modules/sortable_table';

import {
  TableComponent as SortableTableComponent,
  sortable,
} from 'react_components/sortable_table';
import filterable from 'react_components/filterable';
import pageable from 'react_components/pageable';
import LoadingOverlay from 'react_components/loading_overlay';

import EmptyResults from '../empty_results';

const getTableWrapperClasses = props =>
  classNames({
    flex: true,
    'flex--1': true,
    'flex--column': true,
    'soft-double--sides': !props.disableMargin,
    'soft-double--bottom': true,
    'overflow-y--auto': !props.disableScrolling,
  });

/**
 * Given a SortableTable component definition, return an EntityTable component
 *
 * @param {React.Component} - SortableTable
 * @return {React.Component<EntityTable>}
 */
const getEntityTable = SortableTable =>
  class EntityTable extends React.Component {
    static propTypes = {
      tableId: PropTypes.string.isRequired,
      data: PropTypes.instanceOf(Immutable.List).isRequired,
      renderTableHeader: PropTypes.func.isRequired,
      renderTableRow: PropTypes.func.isRequired,
      // required for the empty filters message
      entityPlural: PropTypes.string.isRequired,
      // provided by filterable
      resetFilters: PropTypes.func.isRequired,
      filters: PropTypes.object.isRequired,

      disableScrolling: PropTypes.bool,
      disableMargin: PropTypes.bool,

      defaultSortBy: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

      testSection: PropTypes.string,

      // boolean, if true shows loading indicator
      isLoading: PropTypes.bool,
      shouldAddHover: PropTypes.bool,

      pageSize: PropTypes.number,
      style: PropTypes.string,
      density: PropTypes.string,
    };

    static defaultProps = {
      defaultSortBy: [],
      isLoading: false,
      style: 'rule-no-bottom-border',
      density: 'loose',
    };

    constructor(props) {
      super(props);
      // When the filters or sort order change, we must reset the pagination state.
      // While not ideal, this is the most concise place to hook into these events,
      // as there are multiple components which can invoke changing filters and sort order.
      this.__unobserveQueue = [
        flux.observe(
          FilterableTableGetters.filters(props.tableId),
          this.resetPagination,
        ),
        flux.observe(
          SortableTableGetters.sortData(props.tableId),
          this.resetPagination,
        ),
      ];
    }

    componentWillUnmount() {
      while (this.__unobserveQueue.length) {
        this.__unobserveQueue.shift()();
      }
    }

    resetFilters = () => {
      this.props.resetFilters();
    };

    resetPagination = () => {
      const { tableId } = this.props;
      // This timeout isn't ideal, but it's necessary to prevent a flux dispatch while one is in progress.
      setTimeout(() => PageableActions.resetCurrentPage(tableId));
    };

    render() {
      const {
        data,
        tableId,
        isLoading,
        disableScrolling,
        disableMargin,
      } = this.props;
      const isEmpty = data.size === 0;

      return (
        <LoadingOverlay
          isLoading={isLoading}
          className={getTableWrapperClasses(this.props)}>
          {isEmpty && !isLoading && (
            <EmptyResults
              resetFilters={this.resetFilters}
              entityPlural={this.props.entityPlural}
              testSection="entity-table-clear-filters-button"
            />
          )}
          {!isEmpty && (
            <SortableTable
              tableId={tableId}
              data={this.props.data}
              pageSize={this.props.pageSize}
              renderTableHeader={this.props.renderTableHeader}
              renderTableRow={this.props.renderTableRow}
              defaultSortBy={this.props.defaultSortBy}
              style={this.props.style}
              density={this.props.density}
              tableLayoutAlgorithm="auto"
              limit={this.props.filters.limit}
              testSection={this.props.testSection}
              shouldAddHover={this.props.shouldAddHover}
              disableMargin={disableMargin}
              disableScrolling={disableScrolling}
            />
          )}
        </LoadingOverlay>
      );
    }
  };

/**
 * For a given tableId, create a pageable SortableTable using the pageable HOC
 * and wrapping it in markup to properly render the pagination controls.
 * @param {string} tableId
 * @return {Function<React Component>}
 */
const getPageableEntityTable = tableId =>
  pageable(tableId)(
    @connect({
      isLoading: LoadingGetters.isLoading(tableId),
    })
    class PageableWrapper extends React.Component {
      render() {
        const { props } = this;
        const { isLoading, renderControls, totalPages } = props;
        return (
          <React.Fragment>
            <SortableTableComponent {...props} />
            {(totalPages > 1 || isLoading) && (
              <div className="flex flex-justified--center soft-double--top">
                {renderControls(isLoading)}
              </div>
            )}
          </React.Fragment>
        );
      }
    },
  );

/**
 * The default EntityTable, filterable & sortable.
 */
export const EntityTable = filterable(
  getEntityTable(sortable(SortableTableComponent)),
);

/**
 * An Entity table which is filterable, sortable, and pageable.
 * Note that the order of HOC implementation here is important.
 * Consumers should have their data processed in this order:
 *   1. filter - remove unwanted data
 *   2. sort   - order the data as desired
 *   3. page   - chunk up the data into pages for more performant browsing
 *
 *   This generates a component structure like:
 *
 *   <Filterable data=<data>>
 *     <Sortable data=<filtered data>>
 *       <Pageable data=<sorted data>>
 *         <Component data=<paged data> />
 *       </Pageable>
 *     </Sortable>
 *   </Filterable>
 *
 * @param {string} tableId
 * @return {React.Component}
 */
export const pageableEntityTable = tableId =>
  filterable(getEntityTable(sortable(getPageableEntityTable(tableId))));

export default EntityTable;

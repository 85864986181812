import htmlTemplate from './custom_dialog.html';
/**
 * Custom Dialog
 *
 * @author Baraa Hamodi (baraa@optimizely.com)
 */
const exported = {
  template: htmlTemplate,

  data: {
    dataTestSection: null,
    message: null,
    title: null,
    showSupportLink: null,
    buttonText: null,
    doSanitizeHTML: true,
    _onClose: null,
  },

  afterDestroy() {
    if (this._onClose) {
      this._onClose.resolve();
    }
  },

  ready() {
    this.dataTestSection = this.dataTestSection;
    this.message = this.message;
    this.title = this.title;
    this.showSupportLink = this.showSupportLink;
    this.buttonText = this.buttonText;
  },
};

export default exported;

export const { template, data, afterDestroy, ready } = exported;

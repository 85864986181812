import SentryActions from 'optly/modules/sentry/actions';

import { errorTypes, BASE_ERROR_FINGERPRINT } from './constants';

export const createCsrfMismatchError = () => {
  const errorType = errorTypes.csrfMismatch;
  const err = new Error(errorType.MESSAGE);
  err.fingerprint = errorType.FINGERPRINT;
  return err;
};

export const createTimeoutError = () => {
  const errorType = errorTypes.timeout;
  const err = new Error(errorType.MESSAGE);
  err.fingerprint = errorType.FINGERPRINT;
  return err;
};

export const isTimeoutError = err =>
  err.fingerprint === errorTypes.timeout.FINGERPRINT;

export const createRetryExhaustionError = maxRetries => {
  const errorType = errorTypes.retryExhaustion;
  const additionalDevMessage = __DEV__ ? errorType.DEV_MESSSAGE : '';
  const err = new Error(`${errorType.MESSAGE}${additionalDevMessage}`);
  err.fingerprint = errorType.FINGERPRINT;
  err.tags = {
    maxRetries,
  };
  return err;
};

export const createIncompatibleEnvironmentError = environment => {
  const errorType = errorTypes.incompatibleEnvironment;
  const err = new Error(errorType.MESSAGE);
  err.fingerprint = errorType.FINGERPRINT;
  err.tags = {
    environment,
  };
  return err;
};

export const createExpiredSessionError = () => {
  const errorType = errorTypes.expiredSession;
  const err = new Error(errorType.MESSAGE);
  err.fingerprint = errorType.FINGERPRINT;
  return err;
};

export const isExpiredSessionError = err =>
  err.fingerprint === errorTypes.expiredSession.FINGERPRINT;

export const createIframeFailureError = iframeMessage => {
  const errorType = errorTypes.iframeFailure;
  const err = new Error(errorType.MESSAGE);
  err.fingerprint = errorType.FINGERPRINT;
  err.extras = {
    iframeMessage,
  };
  return err;
};

export const createErrorForFailedHttpResponse = (
  response,
  tokenUsed,
  lastRefreshTime,
  lastRefreshSucceeded,
) => {
  const errorKey =
    {
      500: 'serviceException',
      404: 'notFound',
      403: 'forbidden',
      401: 'unauthorized',
    }[response.status] || 'generalHttpFailure';
  const errorType = errorTypes[errorKey];
  const err = new Error(errorType.MESSAGE);
  err.fingerprint = errorType.FINGERPRINT;
  err.tags = {
    statusText: response.statusText,
    type: response.type,
    doesTokenExist: !!tokenUsed.token,
    isTokenExpired: new Date(tokenUsed.expires) <= new Date(),
    lastRefreshSucceeded,
  };
  err.extras = {
    lastRefreshTime,
    tokenExpriationDate: tokenUsed.expires,
    timeToTokenExpiration: new Date(tokenUsed.expires).getTime() - Date.now(),
    url: response.url,
  };
  return err;
};

export const capturePublicApiError = err => {
  SentryActions.withStandardScope(scope => {
    scope.setFingerprint([
      BASE_ERROR_FINGERPRINT,
      err.fingerprint || err.message,
    ]);

    if (err.tags) {
      Object.keys(err.tags).forEach(tagKey =>
        scope.setTag(tagKey, err.tags[tagKey]),
      );
    }

    if (err.extras) {
      Object.keys(err.extras).forEach(extraKey =>
        scope.setExtra(extraKey, err.extras[extraKey]),
      );
    }

    SentryActions.captureException(err);
  });
};

export default {
  createCsrfMismatchError,
  createTimeoutError,
  isTimeoutError,
  createRetryExhaustionError,
  createIncompatibleEnvironmentError,
  createExpiredSessionError,
  isExpiredSessionError,
  createIframeFailureError,
  createErrorForFailedHttpResponse,
  capturePublicApiError,
};

/**
 * Dialog Manager component
 *
 * Responsible for listening for show/hide dialog events
 * and controlling the ordering/layering of dialogs shown on the page
 *
 * Usage:
 * Showing a dialog
 * ==================================================================================================
 * There are three types of dialogs that can be shown at the same time.
 * 1. Form Dialogs (most dialogs are these)
 * 2. Confirm Dialogs (presenting the user with a message and then submit/cancel buttons)
 * 3. Error Dialogs (These are purely informational, ex: on uncaught ajax error)
 *
 * Each type of dialog has it's own `showEvent` that, when triggered, causes the dialog-manager
 * to show that type of dialog.
 *
 * ```js
 * ui.showDialog({
 *   component: 'dialogs/create-experiment',
 *   data: {
 *     projectId: 4001
 *   }
 * }
 * ```
 * Will show a form dialog and instantiate a `dialogs/create-experiment` component
 * as the dialog contents, and will pass the projectId to the components $data
 *
 *
 * Hiding Dialogs:
 * ==================================================================================================
 * Triggering the `hideDialog` event will cause the top most shown dialog to be hidden
 * and the overlay to be repositioned behind the new top most dialog (or hidden if no
 * dialogs are shown)
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
import _ from 'lodash';
import $ from 'jquery';
import Vue from 'vue';

import { actions as JSSDKLabActions } from '@optimizely/js-sdk-lab';

// TODO (FEI-3382) - Core should not import from /optly
import events from 'optly/utils/events';
import historyUtils from 'optly/utils/history';
import locationHelper from 'optly/location';
import LocalStorageWrapper from 'optly/utils/local_storage_wrapper';

import Deferred from 'core/async/deferred';
import getComponent from 'core/ui/methods/get_component';
import { hideDialog } from 'core/ui/methods/dialogs';
import { showNotification } from 'core/ui/methods/notifications';
import validateVueOptions from 'core/ui/methods/validate_vue_options';

import constants from '../constants';

import htmlTemplate from './dialog_manager.html';

const ESCAPE_KEY = 27;
const MODAL_HASH = '#modal';
const POPSTATE_EVENT = 'popstate.modal';
const SHOWN_CLASS = 'dialog-shown';
const STARTING_Z_INDEX = 3000;

const DialogFrame = Vue.extend(require('./dialog_frame'));

const hideDialogDirective = {
  bind() {
    $(this.el).on('click.hide-dialog-directive', event => {
      this.vm.$dispatch('hideDialog');
    });
  },

  unbind() {
    if (this.__unbindTransitionEnd) {
      this.__unbindTransitionEnd();
    }

    $(this.el).off('click.hide-dialog-directive');
  },
};

const exported = {
  replace: true,
  template: htmlTemplate,

  data: {
    hasLightOverlay: false,
  },

  directives: {
    'hide-dialog': hideDialogDirective,
  },

  computed: {
    /**
     * Return true if overlay color is light and overlay is showing.
     *
     * @return {Boolean}
     */
    shouldShowWithLightOverlay() {
      return this.showOverlay && this.hasLightOverlay;
    },

    /**
     * Return true if any child dialog has fullScreen set to true.
     *
     * @param {Boolean}
     */
    hasFullscreenDialog() {
      return (
        _.some(this.dialogFrameVMs, 'fullScreen') ||
        _.some(this.dialogFrameVMs, 'fullHeight')
      );
    },

    /**
     * Return true if the dialog manager should have the modal--full class so that we
     * can apply the transition class
     *
     * @return {Boolean}
     */
    shouldHaveModalFullClass() {
      return (
        this.hasFullscreenDialog ||
        // if there aren't any dialogs, set it to full screen so that any new dialogs that are full screen
        // can use the transition via the is-active class, which will be activated by setting this.isFullscreenActive to true
        // if the next dialog to show is not full screen then the conditions before this would make this prop return false
        this.dialogFrameVMs.length === 0
      );
    },

    /**
     * Get the shown dialog with the highest zIndex
     * @return {Vue}
     */
    topShownDialog() {
      return _.last(_.sortBy(this.dialogFrameVMs, 'zIndex'));
    },
    /**
     * Returns true if dialog's overlay should be shown
     *
     * @return {Boolean}
     */
    shouldShowOverlay() {
      if (this.isOuiDialog === undefined) {
        return this.showOverlay;
      }
      return !this.isOuiDialog && this.showOverlay;
    },
  },

  methods: {
    isDialogShown(componentId) {
      const found = _.find(this.dialogFrameVMs, {
        dialogComponentId: componentId,
      });
      return !!found;
    },
    /**
     * Creates a dialog frame vm and shows the dialog
     * @param {Object} options
     * @param {string|object} options.component can be a string referencing a globally registered component or a component config object
     * @param {object?} options.data
     * @param {boolean} options.noEscape
     * @param {boolean} options.dismissOnBack True to dismiss the dialog when the user clicks the browser back button
     * @param {boolean} options.shouldApplyNegativeLeftMargin True to push the dialog over to the left
     * @param {boolean} options.shouldTrack True to keep track of when the dialog was last opened
     */
    showDialog(config) {
      if (!config.component) {
        throw new Error('showDialog: must supply config.component');
      }
      const zIndex = this.__getNextZIndex();
      const dialogFrameVM = new DialogFrame({
        parent: this,
        data: {
          shouldApplyNegativeLeftMargin: !!config.shouldApplyNegativeLeftMargin,
          zIndex,
          noEscape: !!config.noEscape,
          noPadding: !!config.noPadding,
          fullHeight: !!config.fullHeight,
          fullScreen: !!config.fullScreen,
          isOuiDialog: !!config.isOuiDialog,
        },
        onHideDialog: config.onHideDialog,
      });

      dialogFrameVM.$appendTo(this.$el);

      const componentOptions = config.options || {};
      validateVueOptions(componentOptions);

      try {
        const Component = _.isString(config.component)
          ? getComponent(config.component)
          : Vue.extend(config.component);

        const componentConfig = _.extend({}, componentOptions, {
          parent: dialogFrameVM,
          data: config.data || {},
        });
        const dialogInstance = new Component(componentConfig);

        dialogInstance.$appendTo(dialogFrameVM.$$.frame);
        // store the componentId on the dialogFrameVM.$data so isDialogShown works
        dialogFrameVM.dialogComponentId = dialogInstance.$options.componentId;

        if (config.fullScreen) {
          // this adds the is-active class, which triggers the slide down animation for the modal
          this.isFullscreenActive = true;
        }

        // opt-in option for dismissing the dialog when the user clicks on the browser back button
        // the reason it is opt-in right now is because it could potentially break current usage of
        // the dialog if there are a[href=#] inside the dialog.
        this.dismissOnBack = !!config.dismissOnBack;

        // if in full screen we want to push state into the history stack so
        // that when the user hits the browser back button we can dismiss the modal
        if (
          !!config.fullScreen &&
          !!config.dismissOnBack &&
          !this.hasFullscreenDialog
        ) {
          const historyHashArgs = ['', document.title, MODAL_HASH];
          // If a history back handler has been provided, push our hash identifier onto
          // the history stack so the back handler can remove it.
          // Otherwise, just replace it (see corresponding logic in hideDialog)
          if (this.$options.handleHistoryBack) {
            historyUtils.pushState(...historyHashArgs);
          } else {
            historyUtils.replaceState(...historyHashArgs);
          }

          // popstate is fired when the user clicks on the browser back button
          // TODO - Will this cause issues when multiple dialogs exist?
          $(window).on(POPSTATE_EVENT, ({ originalEvent }) => {
            // Ignore SingleSpa's extraneous popstate events
            // More info here: https://github.com/single-spa/single-spa/pull/532
            if (originalEvent && originalEvent.singleSpa) {
              return;
            }
            hideDialog();
          });
        }

        this.dialogFrameVMs.push(dialogFrameVM);
        this._setIsOuiDialog(config.isOuiDialog);
        this._updateOverlay();

        if (config && config.shouldTrack) {
          let dialogHistory = JSON.parse(
            LocalStorageWrapper.getItem(constants.DIALOG_LOCAL_STORAGE_KEY),
          );
          let componentId =
            dialogInstance &&
            dialogInstance.$options &&
            dialogInstance.$options.componentId;
          if (!componentId) {
            componentId =
              dialogInstance &&
              dialogInstance.$options &&
              dialogInstance.$options.component &&
              dialogInstance.$options.component.componentId;
          }

          if (!componentId) {
            throw new Error('Must have componentId if shouldTrack is enabled.');
          }

          if (dialogHistory) {
            dialogHistory[componentId] = Date.now().toString(); // Store as date in case we want to expire these later
          } else {
            dialogHistory = {
              [componentId]: Date.now().toString(),
            };
          }

          if (componentId) {
            LocalStorageWrapper.setItem(
              constants.DIALOG_LOCAL_STORAGE_KEY,
              JSON.stringify(dialogHistory),
            );
          }
        }
        return dialogInstance;
      } catch (e) {
        if (__DEV__) {
          throw e;
        } else {
          // catch an errors that might occur when trying to instantiate
          // a component within a dialog
          dialogFrameVM.$destroy();
          showNotification({
            type: 'error',
            message: 'An error has occurred',
          });
        }
      }
    },

    _executeOnHide(dialog) {
      if (dialog.$options.onHideDialog) {
        dialog.$options.onHideDialog();
      }
    },

    /**
     * Hides the top dialog shown
     */
    hideDialog() {
      const topDialog = this.topShownDialog;
      // No dialog safety check.
      if (!topDialog) {
        return Promise.resolve();
      }
      const ind = this.dialogFrameVMs.indexOf(topDialog);
      this.dialogFrameVMs.splice(ind, 1);

      const dialogDestroyed = new Deferred();
      const executeDialogDestroy = () => {
        topDialog.$destroy();
        this._updateOverlay();
        const result = this._executeOnHide(topDialog);
        return Promise.resolve()
          .then(() => result)
          .then(r => {
            console.log(
              '[DIALOG_MANAGER][hideDialog][executeDialogDestroy] dialog destroyed',
            );
            dialogDestroyed.resolve(r);
          });
      };

      // If there are other active dialogs OR the last one isn't fullscreen, destroy it immediately.
      if (this.dialogFrameVMs.length || !this.isFullscreenActive) {
        return executeDialogDestroy();
      }

      // This was the last dialog, AND it was fullscreen.
      this.isFullscreenActive = false;

      // clean up the event listener as it might've not fired
      $(window).off(POPSTATE_EVENT);

      // Return a promise that resolves only once we've handled historyBack
      // AND the dialog destruction has been completed.
      return Promise.resolve()
        .then(() => {
          // Only handle history back if there is a hash and we haven't been told not to.
          if (!this.dismissOnBack || locationHelper.getHash() !== MODAL_HASH) {
            return;
          }
          console.log(
            '[DIALOG_MANAGER][hideDialog] invoking handleHistoryBack',
          );
          // If a history back handler has been provided, invoke it.
          // Otherwise, just remove the hash we appended (see corresponding logic in showDialog)
          if (this.$options.handleHistoryBack) {
            return this.$options.handleHistoryBack();
          }
          historyUtils.replaceState(
            '',
            document.title,
            window.location.pathname + window.location.search,
          );
        })
        .then(() => {
          console.log(
            '[DIALOG_MANAGER][hideDialog][destroyWithTransition] invoking executeDialogDestroy',
          );
          // If we are in fullscreen mode, then we expect to see a transition when the dialog closes.
          // Therefore, we wait for the transition to end before we destroy the dialog
          this.__unbindTransitionEnd = events.transitionEndOnce(
            this.$el,
            executeDialogDestroy,
          );
          return dialogDestroyed;
        });
    },

    /**
     * Hides the top dialog shown
     * This is the legacy method that is being used to rollout the refactored
     * hideDialog method above (see flag )
     */
    hideDialogLegacy() {
      let hideComplete = Promise.resolve();
      const topDialog = this.topShownDialog;
      if (topDialog) {
        const ind = this.dialogFrameVMs.indexOf(topDialog);
        this.dialogFrameVMs.splice(ind, 1);

        // if we are in fullscreen mode, then we expect to see a transition when the dialog closes,
        // therefore we wait for the transition to end before we destroy the dialog
        if (!this.dialogFrameVMs.length && this.isFullscreenActive) {
          // in case transitionend is not supported
          const timeout = setTimeout(() => {
            topDialog.$destroy();
            this._executeOnHide(topDialog);
            this._updateOverlay();
          }, 500);
          this.__unbindTransitionEnd = events.transitionEndOnce(
            this.$el,
            () => {
              topDialog.$destroy();
              this._executeOnHide(topDialog);
              this._updateOverlay();
              window.clearTimeout(timeout);
            },
          );

          if (!this.hasFullscreenDialog) {
            // if we exited the modal without clicking the browser back button then we gotta navigate back to the
            // previous state
            if (this.dismissOnBack && locationHelper.getHash() === MODAL_HASH) {
              // If a history back handler has been provided, invoke it.
              // Otherwise, just remove the hash we appended (see corresponding logic in showDialog)
              if (this.$options.handleHistoryBack) {
                hideComplete = Promise.resolve().then(() =>
                  this.$options.handleHistoryBack(),
                );
              } else {
                historyUtils.replaceState(
                  '',
                  document.title,
                  window.location.pathname + window.location.search,
                );
              }
            }
            // clean up the event listener as it might've not fired
            $(window).off(POPSTATE_EVENT);
          }

          this.isFullscreenActive = false;
        } else {
          topDialog.$destroy();
          this._executeOnHide(topDialog);
          this._updateOverlay();
        }
      }
      return hideComplete;
    },

    /**
     * Event handler for document level keyup
     *
     * @param {Event} event
     */
    handleKeyup(event) {
      // Ignore when active element is input/textarea, since user is typing
      const activeElement = this._getDocumentActiveElement();
      if (activeElement) {
        const activeElementTagName = activeElement.tagName.toLowerCase();
        if (
          activeElementTagName === 'textarea' ||
          activeElementTagName === 'input' ||
          activeElementTagName === 'select'
        ) {
          return;
        }
      }

      if (event.keyCode === ESCAPE_KEY) {
        // only hide the dialog on escape key is the noEscape option is false
        if (this.topShownDialog && !this.topShownDialog.noEscape) {
          this.hideDialog();
        }
      }
    },

    /**
     * Return true if the dialog passed in as an argument is full screen or full height.
     * @param  {Dialog}  dialog
     * @return {Boolean} whether or not the dialog is full screen
     */
    isDialogFullScreen(dialog) {
      return dialog.fullScreen || dialog.fullHeight;
    },
    /**
     * Stubbable method to get the document's active element
     * @return {DOMElement}
     */
    _getDocumentActiveElement() {
      return document.activeElement;
    },
    /**
     * Updates the overlay to be the zIndex directly below the top dialog
     * @private
     */
    _updateOverlay() {
      if (this.topShownDialog) {
        // Activate overlay color option (can be either light or dark) at the same time as showing the overlay
        // so that a flicker of a dark overlay does not show when using a light overlay with a transition effect
        this.hasLightOverlay = this.isDialogFullScreen(this.topShownDialog);
        this.showOverlay = !_.some(this.dialogFrameVMs, 'isOuiDialog') === true;
        this.overlayZIndex = this.topShownDialog.zIndex - 1;
      } else {
        this.hasLightOverlay = false;
        this.showOverlay = false;
      }
      $('html').toggleClass(SHOWN_CLASS, !!this.topShownDialog);
    },
    /**
     * Updates the isOuiDialog property of this dialog manager
     * @private
     */
    _setIsOuiDialog(isOuiDialog) {
      this.isOuiDialog = isOuiDialog;
    },
    __getNextZIndex() {
      if (!this.topShownDialog) {
        return STARTING_Z_INDEX;
      }
      return this.topShownDialog.zIndex + 2;
    },
  },

  created() {
    this.showOverlay = false;
    this.overlayZIndex = STARTING_Z_INDEX - 1;
    /**
     * Array of all child dialog-frame components
     * @var {Array.<Vue>}
     */
    this.dialogFrameVMs = [];

    // This is a kill switch for the refactored hideDialog method above.
    if (JSSDKLabActions.isFeatureEnabled('fei-hide-dialog-legacy') === true) {
      this.hideDialog = this.hideDialogLegacy;
    }
    this.$on('hideDialog', this.hideDialog.bind(this));
    this.$on('showDialog', this.showDialog.bind(this));
  },

  ready() {
    $(window.document).on(
      'keyup.dialog-manager-component',
      this.handleKeyup.bind(this),
    );
  },

  afterDestroy() {
    // cleanup event listeners
    $(window.document).off('keyup.dialog-manager-component');
    $('html').removeClass(SHOWN_CLASS);
  },
};

export default exported;

export const {
  replace,
  template,
  data,
  directives,
  computed,
  methods,
  created,
  ready,
  afterDestroy,
} = exported;

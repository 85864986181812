import Immutable, { toImmutable } from 'optly/immutable';
import PropTypes from 'prop-types';
import React from 'react';
import regexUtils from 'optly/utils/regex';
import ui from 'core/ui';

// components
import { Button, ButtonRow } from 'optimizely-oui';

import {
  enums as LiveVariableEnums,
  fns as LiveVariableFns,
} from 'optly/modules/entity/live_variable';

import FeatureDetails from './components/feature_details';
import FeatureVariables from './components/variables';
import FeatureCode from './components/code_block';

// modules

class FeatureDialogComponent extends React.Component {
  static propTypes = {
    currentProjectFeatures: PropTypes.instanceOf(Immutable.List).isRequired,
    feature: PropTypes.instanceOf(Immutable.Map).isRequired,
    language: PropTypes.string.isRequired,
    save: PropTypes.func.isRequired,
  };

  state = {
    isEditing: !!this.props.feature.get('id'),
    isSaving: false,
    feature: this.props.feature,
    hasVariableValueBeenClicked: false,
  };

  addVariable = () => {
    const newVariable = toImmutable({
      api_name: '',
      type: LiveVariableEnums.LIVE_VARIABLE_TYPES.string,
      default_value: '',
    });

    const updatedVariables = this.state.feature
      .get('variables')
      .push(newVariable);

    this.updateFeatureProperty('variables', updatedVariables);

    // variable string error handling
    this.handleVariableValueClick(false);
  };

  canSave = () => {
    // make sure there is only ever 1 save in progress
    if (this.state.isSaving) {
      return false;
    }

    if (this.state.feature.get('variables')) {
      const invalidVariable = this.state.feature
        .get('variables')
        .some(
          variable =>
            !this.isVariableUnique(variable) ||
            !this.isVariableKeyValid(variable) ||
            !this.isVariableValueValid(variable),
        );

      if (invalidVariable) {
        return false;
      }
    }

    if (
      !this.isFeatureKeyUnique(this.state.feature) ||
      !this.isFeatureKeyValid(this.state.feature)
    ) {
      return false;
    }

    return true;
  };

  isFeatureKeyUnique = editingFeature =>
    !this.props.currentProjectFeatures.some(
      feature =>
        feature.get('api_name') === editingFeature.get('api_name') &&
        feature.get('id') !== editingFeature.get('id'),
    );

  isFeatureKeyValid = feature =>
    regexUtils.apiName.test(feature.get('api_name'));

  isVariableUnique = variable =>
    this.state.feature
      .get('variables')
      .count(v => v.get('api_name') === variable.get('api_name')) === 1;

  isVariableKeyValid = variable =>
    regexUtils.variableName.test(variable.get('api_name'));

  isVariableValueValid = variable =>
    LiveVariableFns.isVariableValueInputValid(
      variable.get('default_value'),
      variable.get('type'),
    );

  handleVariableValueClick = bool => {
    this.setState({
      hasVariableValueBeenClicked: bool,
    });
  };

  removeVariable = index => {
    let updatedVariables;
    const featureVariables = this.state.feature.get('variables');
    let updatedVariable = featureVariables.get(index);

    if (updatedVariable.get('id')) {
      // if variable exists we just set it to archived
      updatedVariable = updatedVariable.set('archived', true);
      updatedVariables = this.state.feature
        .get('variables')
        .set(index, updatedVariable);
    } else {
      // if variable hasn't been created yet, then remove it from the list
      updatedVariables = this.state.feature.get('variables').remove(index);
    }

    this.updateFeatureProperty('variables', updatedVariables);
  };

  save = () => {
    this.setState({
      isSaving: true,
    });

    const savePromise = this.props
      .save(this.state.feature)
      .done(() => {
        ui.hideDialog();
      })
      .always(() => {
        this.setState({
          isSaving: false,
        });
      });

    ui.loadingWhen('save-oasis-feature', savePromise);
  };

  updateFeatureProperty = (property, value) => {
    this.setState(prevState => ({
      feature: prevState.feature.set(property, value),
    }));
  };

  updateVariable = (index, variable) => {
    // if we are switching variable types, then assign the default variable based on the type
    const foundVariable = this.state.feature.get('variables').get(index);
    const newVariableType = variable.get('type');
    if (
      newVariableType !== foundVariable.get('type') &&
      newVariableType !== LiveVariableEnums.LIVE_VARIABLE_TYPES.string
    ) {
      // we only need default values for non-string variables
      variable = variable.set(
        'default_value',
        LiveVariableFns.getDefaultValueForType(newVariableType),
      );
    }

    const updatedVariables = this.state.feature
      .get('variables')
      .set(index, variable);

    this.updateFeatureProperty('variables', updatedVariables);
  };

  render() {
    return (
      <div data-test-section="feature-dialog">
        <FeatureDetails
          feature={this.state.feature}
          isEditing={this.state.isEditing}
          isFeatureKeyUnique={this.isFeatureKeyUnique}
          isFeatureKeyValid={this.isFeatureKeyValid}
          updateFeatureProperty={this.updateFeatureProperty}
        />
        <FeatureVariables
          addVariable={this.addVariable}
          isVariableKeyValid={this.isVariableKeyValid}
          isVariableUnique={this.isVariableUnique}
          isVariableValueValid={this.isVariableValueValid}
          removeVariableAtIndex={this.removeVariable}
          updateVariable={this.updateVariable}
          variables={this.state.feature.get('variables')}
          hasVariableValueBeenClicked={this.state.hasVariableValueBeenClicked}
          handleVariableValueClick={this.handleVariableValueClick}
        />
        <FeatureCode
          feature={this.state.feature}
          language={this.props.language}
        />
        <div className="lego-form__footer">
          <ButtonRow
            rightGroup={[
              <Button
                key="btn-cancel"
                style="plain"
                testSection="feature-dialog-cancel"
                onClick={ui.hideDialog}>
                Cancel
              </Button>,
              <Button
                key="btn-save"
                style="highlight"
                testSection="feature-dialog-save"
                isDisabled={!this.canSave()}
                onClick={this.save}>
                {this.state.isEditing ? 'Save' : 'Create Feature Flag'}
              </Button>,
            ]}
          />
        </div>
      </div>
    );
  }
}

export default FeatureDialogComponent;

import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';
import constants from '../constants';

const NEW_CATALOG = {
  id: null,
  name: '',
  description: '',
  project_specifics: [],
  id_tag_name: null,
  run_parameters: {
    max_validation_rate: constants.DEFAULT_MAX_VALIDATION_RATE,
  },
  url_tag_name: null,
};

const DEFAULT_INITIAL_STATE = {
  // Maps to Catalog
  catalog: NEW_CATALOG,

  // recommenders
  recommenders: [],

  currentlyEditingEvent: {},

  currentlyEditingRecommender: {},

  selectedEventPageId: null,

  currentlyEditingCatalog: NEW_CATALOG,
};

export default Nuclear.Store({
  getInitialState() {
    return toImmutable(DEFAULT_INITIAL_STATE);
  },

  initialize() {
    this.on(actionTypes.RECOMMENDATIONS_INIT, initialize);
    this.on(
      actionTypes.RECOMMENDATIONS_INIT_CURRENTLY_EDITING_CATALOG,
      initializeCurrentlyEditingCatalog,
    );
    this.on(actionTypes.RECOMMENDATIONS_SET_CATALOG, setCatalog);
    this.on(
      actionTypes.RECOMMENDATIONS_SET_CURRENTLY_EDITING_CATALOG,
      setCurrentlyEditingCatalog,
    );
    this.on(
      actionTypes.RECOMMENDATIONS_RESET_CURRENTLY_EDTING_CATALOG,
      resetService,
    );
    this.on(actionTypes.RECOMMENDATIONS_UPDATE_PROPERTY, updateProperty);
    this.on(actionTypes.RECOMMENDATIONS_UPDATE_EVENT, updateEvent);
    this.on(actionTypes.RECOMMENDATIONS_REMOVE_EVENT, removeEvent);

    // currently editing event
    this.on(
      actionTypes.RECOMMENDATIONS_SET_EDITING_EVENT,
      setCurrentlyEditingEvent,
    );
    this.on(
      actionTypes.RECOMMENDATIONS_ADD_TAG_TO_CURRENTLY_EDITING_EVENT,
      addTag,
    );
    this.on(
      actionTypes.RECOMMENDATIONS_REMOVE_TAG_FROM_CURRENTLY_EDITING_EVENT,
      removeTag,
    );
    this.on(actionTypes.RECOMMENDATIONS_UPDATE_FOR_CATALOG, updateForCatalog);
    this.on(actionTypes.RECOMMENDATIONS_SELECT_EVENT_PAGE, selectEventPage);
    this.on(
      actionTypes.RECOMMENDATIONS_SET_EDITING_RECOMMENDER,
      setEditingRecommender,
    );
    this.on(
      actionTypes.RECOMMENDATIONS_UPDATE_EDITING_RECOMMENDER_PROPERTY,
      updateEditingRecommenderProperty,
    );
    this.on(
      actionTypes.RECOMMENDATIONS_UPDATE_EDITING_RECOMMENDER_SPECIFICS_PROPERTY,
      updateEditingRecommenderSpecificsProperty,
    );
  },
});

function initialize(state, { projectId }) {
  return toImmutable(DEFAULT_INITIAL_STATE).updateIn(
    ['catalog', 'project_specifics'],
    projectSpecifics =>
      projectSpecifics.push(
        toImmutable({
          project_id: projectId,
          events: [],
        }),
      ),
  );
}

function initializeCurrentlyEditingCatalog(state, { projectId }) {
  return toImmutable(DEFAULT_INITIAL_STATE).updateIn(
    ['currentlyEditingCatalog', 'project_specifics'],
    projectSpecifics =>
      projectSpecifics.push(
        toImmutable({
          project_id: projectId,
          events: [],
        }),
      ),
  );
}

function setCatalog(state, { catalog }) {
  return state.set('catalog', toImmutable(catalog));
}

function setCurrentlyEditingCatalog(state, { catalog }) {
  return state.set('currentlyEditingCatalog', toImmutable(catalog));
}

function resetService(state) {
  return state.set('currentlyEditingCatalog', state.get('catalog'));
}

function updateProperty(state, { property, value }) {
  return state.setIn(['currentlyEditingCatalog', property], value);
}

function setCurrentlyEditingEvent(state, { event }) {
  return state.set('currentlyEditingEvent', event);
}

function addTag(state, { tag }) {
  return state.updateIn(['currentlyEditingEvent', 'tags'], tags =>
    tags.push(tag.get('id')),
  );
}

function removeTag(state, { tag }) {
  return state.updateIn(['currentlyEditingEvent', 'tags'], tags =>
    tags.filter(id => id !== tag.get('id')),
  );
}

function updateForCatalog(state, { value }) {
  return state.setIn(['currentlyEditingEvent', 'for_catalog'], value);
}

function selectEventPage(state, { viewId }) {
  return state.set('selectedEventPageId', viewId);
}

function updateEvent(state, { event }) {
  // TODO maybe this should live in a fns
  const formattedEvent = toImmutable({
    id: event.get('id'),
    kind: event.get('kind'),
    tags: event.get('tags'),
    for_catalog: event.get('for_catalog'),
  });
  return state.updateIn(
    ['currentlyEditingCatalog', 'project_specifics', 0, 'events'],
    events => {
      const ind = events.findIndex(
        e =>
          e.get('id') === event.get('id') &&
          e.get('kind') === event.get('kind'),
      );

      if (ind > -1) {
        return events.set(ind, formattedEvent);
      }

      return events.push(formattedEvent);
    },
  );
}

function removeEvent(state, { event }) {
  return state.updateIn(
    ['currentlyEditingCatalog', 'project_specifics', 0, 'events'],
    events =>
      events.filter(
        e =>
          e.get('id') !== event.get('id') ||
          e.get('kind') !== event.get('kind'),
      ),
  );
}

function setEditingRecommender(state, { recommender }) {
  return state.set('currentlyEditingRecommender', toImmutable(recommender));
}

function updateEditingRecommenderProperty(state, { property, value }) {
  return state.setIn(
    ['currentlyEditingRecommender', property],
    toImmutable(value),
  );
}

function updateEditingRecommenderSpecificsProperty(state, { property, value }) {
  return state.updateIn(
    ['currentlyEditingRecommender', 'specifics'],
    specifics => specifics.set(property, toImmutable(value)),
  );
}

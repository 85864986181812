import htmlSanitizer from 'sanitizer';

import React from 'react';
import PropTypes from 'prop-types';

// components
import { ButtonIcon, Table } from 'optimizely-oui';

import ListDropdown from 'react_components/list_dropdown';
import Immutable, { toJS } from 'optly/immutable';

// modules
// eslint-disable-next-line import/no-cycle
import GroupDetailDialog from 'bundles/p13n/components/dialogs/configure_group';
import { actions as GroupModuleActions } from 'optly/modules/entity/experimentation_group';
import shortdate from 'optly/filters/shortdate';
import ui from 'core/ui';

class GroupTableRow extends React.Component {
  constructor(props) {
    super(props);

    this.edit = this.edit.bind(this);
    this.handleArchiveClick = this.handleArchiveClick.bind(this);
    this.archive = this.archive.bind(this);
    this.unarchive = this.unarchive.bind(this);
    this.renderActionsDropdown = this.renderActionsDropdown.bind(this);
  }

  edit() {
    const data = {
      group: toJS(this.props.group),
    };
    GroupDetailDialog.show(GroupModuleActions.save, data);
  }

  handleArchiveClick() {
    const groupContainsEntities = this.props.group.get('entities').size > 0;
    const canGroupBeArchived =
      this.props.canArchiveGroup && !groupContainsEntities;
    if (canGroupBeArchived) {
      this.archive();
    }
  }

  archive() {
    GroupModuleActions.archive(this.props.group).then(() => {
      ui.showNotification({
        message: tr(
          'The group <b>{0}</b> has been archived.',
          htmlSanitizer.escape(this.props.group.get('name')),
        ),
      });
    });
  }

  unarchive() {
    GroupModuleActions.unarchive(this.props.group).then(() => {
      ui.showNotification({
        message: tr(
          'The group <b>{0}</b> has been unarchived.',
          htmlSanitizer.escape(this.props.group.get('name')),
        ),
      });
    });
  }

  renderActionsDropdown() {
    if (!this.props.canArchiveGroup) {
      return; // don't render the actions dropdown if the user does not have canArchiveGroup permissions.
    }
    const { group } = this.props;

    let archiveLink;
    if (group.get('entities').size > 0) {
      archiveLink = [
        {
          isLink: false,
          text: 'Archive',
          secondaryText: 'Only empty exclusion groups can be archived.',
          isWarning: true,
          minWidth: 150,
          onClick: () => {},
          testSection: `group-archive-button-disabled-${group.get('id')}`,
        },
      ];
    } else {
      archiveLink = [
        {
          text: 'Archive',
          onClick: this.handleArchiveClick,
          testSection: `group-archive-button-${group.get('id')}`,
        },
      ];
    }

    const dropdownItems = group.get('archived')
      ? [
          {
            text: 'Unarchive',
            onClick: this.unarchive,
            testSection: `group-unarchive-button-${group.get('id')}`,
          },
        ]
      : archiveLink;

    return (
      <ListDropdown
        key={group.get('id')}
        activator={
          <ButtonIcon
            iconFill="default"
            iconName="ellipsis-solid"
            size="small"
            style="plain"
            testSection="group-table-row-controls-toggle"
          />
        }
        items={dropdownItems}
        testSection="group-table-row-actions-dropdown"
      />
    );
  }

  render() {
    return (
      <Table.TR key={this.props.group.get('id')}>
        <Table.TD testSection="group-table-row">
          <a
            className="cursor--pointer"
            data-test-section="group-edit-button"
            onClick={this.edit}>
            <span data-test-section="group-table-row-name">
              {this.props.group.get('name')}
            </span>
          </a>
          <div className="muted micro">
            {this.props.group.get('description')}
          </div>
        </Table.TD>
        <Table.TD testSection="group-entities">
          {this.props.group.get('entities').size}
        </Table.TD>
        <Table.TD testSection="group-available-traffic">
          {`${tr.number(this.props.group.get('available_traffic') / 100)}%`}
        </Table.TD>
        <Table.TD testSection="group-created-date">
          <span className="nowrap">
            {shortdate(this.props.group.get('created'))}
          </span>
        </Table.TD>
        <Table.TD testSection="group-modified-date">
          <span className="nowrap">
            {shortdate(this.props.group.get('last_modified'))}
          </span>
        </Table.TD>
        <Table.TD isNumerical={true}>{this.renderActionsDropdown()}</Table.TD>
      </Table.TR>
    );
  }
}

GroupTableRow.propTypes = {
  canArchiveGroup: PropTypes.bool.isRequired,
  group: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default GroupTableRow;

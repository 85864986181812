/**
 * NOTE(nate): event tracking in this module does not send data to marketo, per marketing team
 */
import _ from 'lodash';

import $ from 'jquery';

import RestApi from 'optly/modules/rest_api';

// TODO refactor services into modules
import events from 'optly/services/events';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

export default {
  ...baseEntityActions,
  fetchAll: baseEntityActions.fetchAll,
  /**
   * Archives an audience
   * @param {Audience} audience
   * @return {Deferred}
   */
  archive(audience) {
    events.track('dashboard2', 'archive-audience');
    return this.save({
      id: audience.id,
      archived: true,
    });
  },

  /**
   * Unarchives an audience
   * @param {Audience} audience
   * @return {Deferred}
   */
  unarchive(audience) {
    events.track('dashboard2', 'unarchive-audience');
    return this.save({
      id: audience.id,
      archived: false,
    });
  },

  /**
   * Fetch all audiences in a list
   */
  fetchAudiencesById(audienceIds) {
    const deferred = $.Deferred();

    const experimentAudienceDeferreds = _.map(audienceIds, audienceId =>
      baseEntityActions.fetch(audienceId),
    );

    $.when(...experimentAudienceDeferreds).then(function() {
      const audiences = [].slice.call(arguments); // eslint-disable-line prefer-rest-params
      deferred.resolve(audiences);
    }, deferred.reject);

    return deferred;
  },

  /**
   * Fetches all audiences where user_touched == true
   *
   * @param {Integer} projectId
   * @return {Deferred}
   */
  fetchSavedAudiences(projectId) {
    return this.fetchAll({
      project_id: projectId,
      user_touched: true,
    });
  },
};

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

export const pageTypes = {
  SINGLE_URL: 'single_url',
  URL_SET: 'url_set',
  GLOBAL: 'global',
};

export const activationModes = {
  IMMEDIATE: 'immediate',
  MANUAL: 'manual',
  POLLING: 'polling',
  CALLBACK: 'callback',
  URL_CHANGED: 'url_changed',
  DOM_CHANGED: 'dom_changed',
};

export const conditionGroupTypes = {
  OR: 'or',
  AND: 'and',
};

export const conditionMatchTypes = {
  URL: 'url',
  ELEMENT_PRESENT: 'element_present',
  CUSTOM_CODE: 'custom_code',
};

const additionalText = `*
 * Editor now supports ES6 compliant code. Note that adding ES6
 * specific code to an experiment will break for users running
 * ES5-only browsers as code entered is not transpiled down to ES5.`;

export const defaultConditionConfigs = {
  [conditionMatchTypes.URL]: {
    value: '',
    match_type: 'simple',
    type: 'url',
  },
  [conditionMatchTypes.ELEMENT_PRESENT]: {
    value: '',
    type: 'element_present',
  },
  [conditionMatchTypes.CUSTOM_CODE]: {
    value: `/**
 * Sample JavaScript Condition
 * This function is called after the page is triggered.
 * It should return true when the page is ready to activate.
 ${additionalText}
 */

function jsCondition() {
  return globalConfig.pageType === "PDP";
}`,
    type: 'custom_code',
  },
};

export default {
  activationModes,
  conditionGroupTypes,
  conditionMatchTypes,
  defaultConditionConfigs,
  pageTypes,
};

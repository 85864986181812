import React from 'react';

import NavConstants from 'optly/services/navigation';

import { withTopbarAndProjectChangeHistory } from 'bundles/p13n/components/change_history';

import RoutingFns from '../../routing_fns';
import Topbar from './components/topbar';

/*
 * Routes for Change History section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/projects/:proj_id/history',
    metadata: { name: 'History' },
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.HISTORY,
        ),
        RoutingFns.parseProjectId,
      ],
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'History',
        }),
      () => {
        // Define within route since the componentId needs to change for renderMainRegion to remount the component
        const ProjectChangeHistory = withTopbarAndProjectChangeHistory(Topbar);
        RoutingFns.renderMainRegion(<ProjectChangeHistory />);
      },
    ],
  },
];

export default routes;

import _ from 'lodash';

import PropTypes from 'prop-types';

import React from 'react';

import { Button, ButtonRow, Input, Table } from 'optimizely-oui';

import Immutable from 'optly/immutable';

import ui from 'core/ui';

// modules
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';

const VariationRow = props => {
  const isPaused =
    props.variation.get('status') ===
    LayerExperimentEnums.VariationStatus.PAUSED;
  const variationPercentage = props.variation.get('weight') / 100;

  const handleInputChange = (inputEvent, property) => {
    const inputValue = inputEvent.target.value;
    const updatedVariation = props.variation.set(property, inputValue);
    props.updateVariation(props.rowIndex, updatedVariation);
  };

  const updateVariationPercentage = inputEvent => {
    const inputValue = inputEvent.target.value;
    const updatedVariation = props.variation.set(
      'weight',
      Math.round(inputValue * 100),
    );
    props.updateVariation(props.rowIndex, updatedVariation);
  };

  const deleteVariation = () => {
    props.onDeleteVariation(props.rowIndex);
  };

  const pauseVariation = () => {
    ui.confirm({
      title: tr('Pause variation'),
      message: tr(
        'Pausing this variation means that users who have previously seen it will be re-allocated between the remaining unpaused variations.',
      ),
      isWarning: true,
    }).then(() => {
      props.pauseVariation(props.rowIndex, props.variation);
    });
  };

  const resumeVariation = () => {
    props.resumeVariation(props.rowIndex, props.variation);
  };

  const renderErrorBlock = () => {
    if (!!props.variation.get('api_name') && props.showInvalidKeyError) {
      return (
        <div
          className="lego-form-note lego-form-note--bad-news"
          data-test-section="variation-key-invalid-error">
          Please enter a valid key. Valid keys contain alphanumeric characters,
          hyphens, and underscores, and are limited to 64 characters.
        </div>
      );
    }
  };

  const renderActionButtons = () => {
    let button;
    if (props.canDelete) {
      button = (
        <Button
          onClick={deleteVariation}
          style="plain"
          testSection={`delete-experiment-variation-${props.rowIndex}`}>
          Delete
        </Button>
      );
    } else if (isPaused) {
      button = (
        <Button
          onClick={resumeVariation}
          style="plain"
          testSection={`resume-experiment-variation-${props.rowIndex}`}>
          Resume
        </Button>
      );
    } else {
      button = (
        <Button
          onClick={pauseVariation}
          style="plain"
          testSection={`pause-experiment-variation-${props.rowIndex}`}>
          Pause
        </Button>
      );
    }

    return <ButtonRow rightGroup={[button]} />;
  };

  return (
    <Table.TR className={`experiment-variation-row-${props.rowIndex}`}>
      <Table.TD>
        <Input
          name="variation_key"
          onChange={_.partialRight(handleInputChange, 'api_name')}
          testSection={`experiment-variation-key-${props.rowIndex}`}
          type="text"
          value={props.variation.get('api_name')}
        />
        {renderErrorBlock()}
      </Table.TD>
      <Table.TD>
        {!props.hideDescription && (
          <Input
            type="text"
            name="variation_description"
            onChange={_.partialRight(handleInputChange, 'description')}
            placeholder="(optional)"
            testSection={`experiment-variation-desc-${props.rowIndex}`}
            value={props.variation.get('description')}
          />
        )}
      </Table.TD>
      <Table.TD>
        <div className="flex">
          <Input
            isDisabled={isPaused}
            min={0}
            max={100}
            name="variation_percentage"
            onChange={updateVariationPercentage}
            testSection={`experiment-variation-percentage-${props.rowIndex}`}
            type="number"
            value={variationPercentage.toString()}
          />
          <span className="flex--1 push-half--left push--right push-half--top">
            %
          </span>
        </div>
      </Table.TD>
      <Table.TD>{!props.hideActionButtons && renderActionButtons()}</Table.TD>
    </Table.TR>
  );
};

VariationRow.propTypes = {
  canDelete: PropTypes.bool.isRequired,
  onDeleteVariation: PropTypes.func.isRequired,
  pauseVariation: PropTypes.func.isRequired,
  resumeVariation: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
  showInvalidKeyError: PropTypes.bool,
  showUniqueKeyError: PropTypes.bool,
  updateVariation: PropTypes.func.isRequired,
  variation: PropTypes.instanceOf(Immutable.Map),
  hideDescription: PropTypes.bool,
  hideActionButtons: PropTypes.bool,
};

VariationRow.defaultProps = {
  hideDescription: false,
  hideActionButtons: false,
};

export default VariationRow;

import { some } from 'lodash';

import flux from 'core/flux';
import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { fns as ExperimentSectionFns } from 'optly/modules/entity/experiment_section';
import * as LayerConstants from 'optly/modules/entity/layer/constants';
import * as LayerEnums from 'optly/modules/entity/layer/enums';
import { fns as LayerExperimentFns } from 'optly/modules/entity/layer_experiment';
import ViewEnums from 'optly/modules/entity/view/enums';

import Immutable from 'optly/immutable';

import actionTypes from './action_types';
import fns from './fns';
import getters from './getters';
import constants from './constants';

/**
 * Initializes the store for when the create experiment component is created
 * @param {Object} config
 */
export function init(config) {
  const currentLayer = flux.evaluate(CurrentLayerGetters.layer);
  let layerHoldback = 0;
  if (currentLayer) {
    layerHoldback = currentLayer.get('holdback');
  }
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_INIT, {
    experiment: config.experiment,
    mode: config.mode,
    layerHoldback,
    type: config.type,
    variableUsages: config.variableUsages,
    isMultivariateTest: config.isMultivariateTest,
  });

  // Initialize currentlySelectedTrafficAllocationPolicy after initializing other parts of store since experimentId
  // needed for currentLayerExperimentTrafficAllocationPolicy getter to properly function
  const trafficAllocationPolicyInEntityCache = flux.evaluate(
    getters.currentLayerExperimentTrafficAllocationPolicy,
  );
  updateCurrentlySelectedTrafficAllocationPolicy(
    trafficAllocationPolicyInEntityCache,
  );
}

/**
 * Tears down the experiment component if it exists in the store
 */
export function tearDown() {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_TEARDOWN);
}

/**
 * Updates the name of the component experiment with the given name.
 * @param {String} name
 */
export function updateName(name) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_UPDATE_NAME, name);
}

/**
 * Updates the description of the component experiment with the given description.
 * @param {String} description
 */
export function updateDescription(description) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_UPDATE_DESCRIPTION,
    description,
  );
}

/**
 * Sets the errors to be displayed in the UI
 * @param {Object} errors
 */
export function setErrors(errors) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_SET_ERRORS, errors);
}

/**
 * Sets the list of selected view ids
 * @param {Array} viewIds
 */
export function setSelectedViewIds(viewIds) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_SET_VIEW_IDS, {
    viewIds,
  });
  setErrors({
    views: '',
  });
}

/**
 * Sets the url targeting config
 * @param {Object} urlTargetingConfig
 */
export function setUrlTargetingConfig(urlTargetingConfig) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_SET_URL_TARGETING_CONFIG, {
    urlTargetingConfig,
  });
  setSelectedViewIds(Immutable.Set());
  setErrors({
    urlTargetingConditions: '',
    urlTargetingApiName: '',
    urlTargetingUrl: '',
  });
}

export function setTargetingType(targetingType) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_SET_TARGETING_TYPE, {
    targetingType,
  });
}

/**
 * Adds the given audience ID to the list of selected audience IDs.
 * @param {number} audienceId
 */
export function addAudience(audienceId) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_ADD_AUDIENCE, {
    audienceId,
  });
}

/**
 * Removes the given audience ID from the list of selected audience IDs
 * @param {number} audienceId
 */
export function removeAudience(audienceId) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_REMOVE_AUDIENCE, {
    audienceId,
  });
}

/**
 * Updates the order of audience Ids
 * @param {List} audienceIds
 */
export function updateAudienceIds(audienceIds) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_UPDATE_AUDIENCE_IDS,
    audienceIds,
  );
}

/**
 * Updates the layer level holdback
 * @param {Number} layerHoldback
 */
export function updateLayerHoldback(layerHoldback) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_UPDATE_LAYER_HOLDBACK,
    layerHoldback,
  );
}

/**
 * Updates the traffic allocation policy
 * @param {string} policy
 */
export function updateCurrentlySelectedTrafficAllocationPolicy(policy) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_UPDATE_CURRENTLY_SELECTED_TRAFFIC_ALLOCATION_POLICY,
    policy,
  );
}

/**
 * Updates the selected distribution goal
 * @param {string} distributionGoal
 */
export function updateCurrentlySelectedDistributionGoal(distributionGoal) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_UPDATE_CURRENTLY_SELECTED_DISTRIBUTION_GOAL,
    distributionGoal,
  );
}

/**
 * Updates is automatic distribution goal
 * @param {boolean} isAutomaticDistributionGoal
 */
export function updateIsAutomaticDistributionGoal(isAutomaticDistributionGoal) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_UPDATE_IS_AUTOMATIC_DISTRIBUTION_GOAL,
    isAutomaticDistributionGoal,
  );
}

/**
 * Updates the exploitation rate
 * @param {number} amount
 */
export function updateExploitationRate(amount) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_UPDATE_EXPLOITATION_RATE,
    amount,
  );
}

/**
 * Updates the variation
 * @param {Object} variation
 */
export function updateVariation(variation) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_UPDATE_VARIATION, {
    variation,
  });
}

/**
 * Adds the given variation to the list of variations
 */
export function addVariation() {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_ADD_VARIATION);
}

/**
 * Given the variation ID, removes the variation from the list of variations
 * @param {number} variation
 */
export function removeVariation(variation) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_REMOVE_VARIATION, {
    variation,
  });
}

/**
 * Pause the given variation
 * @param {Object} variation
 */
export function pauseVariation(variation) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_PAUSE_VARIATION, {
    variation,
  });
}

/**
 * Resume the given paused variation
 * @param {Object} variation
 */
export function resumeVariation(variation) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_RESUME_VARIATION, {
    variation,
  });
}

/**
 * Reset to uniform traffic distribution
 */
export function resetToUniformTraffic() {
  const variations = flux.evaluate(getters.variations); // ensures clean copy of variations with no undefined properties eventually making its way into the flux store
  const equalWeight = Math.round(10000 / variations.size);
  const updatedVariationsWeights = variations.map(variation =>
    variation.set('weight', equalWeight),
  );
  const updatedVariationsWeightsWithRounding = LayerExperimentFns.redistributeWithNewVariationTrafficImmutable(
    updatedVariationsWeights,
  );
  const updatedVariationsPercentagesAndTotalTraffic = fns.addTrafficDistributionInfoToVariations(
    updatedVariationsWeightsWithRounding,
    flux.evaluate(getters.layerHoldback),
  );
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_RESET_TO_UNIFORM_TRAFFIC,
    updatedVariationsPercentagesAndTotalTraffic,
  );
}

/**
 * Set the bucketing strategy for the experiment.
 * @param {String} strategy
 */
export function updateBucketingStrategy(strategy) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_UPDATE_BUCKETING_STRATEGY,
    strategy,
  );
}

/**
 * Updates the audience match type for the experiment.
 * @param {String} audienceMatchType
 */
export function updateAudienceMatchType(audienceMatchType) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_UPDATE_AUDIENCE_MATCH_TYPE,
    audienceMatchType,
  );
}

/**
 * Updates the audience conditions for the experiment.
 * @param {String} audienceConditions
 */
export function updateAudienceConditions(audienceConditions) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_UPDATE_AUDIENCE_CONDITIONS,
    audienceConditions,
  );
}

/**
 * Updates the audience conditions for the experiment.
 * @param {String} audienceConditionsJson
 */
export function updateAudienceConditionsJson(audienceConditionsJson) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_UPDATE_AUDIENCE_CONDITIONS_JSON,
    audienceConditionsJson,
  );
}

/**
 * Removes variation_ids from the experiment.
 */
export function removeIdsFromVariationsForDuplicateExperiments() {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_REMOVE_IDS_FROM_VARIATIONS_FOR_DUPLICATE_EXPERIMENTS,
  );
}

/**
 * Sets the currentExperimentIdToNull
 */
export function setCurrentExperimentIdToNull() {
  flux.dispatch(actionTypes.CREATE_EXP_SET_CURRENT_EXPERIMENT_ID_TO_NULL);
}

/**
 * Appends a given live variable ID to the list of selected variable IDs
 * @param {number} variableId
 */
export function addVariable(variableId) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_ADD_VARIABLE, {
    variableId,
  });
}

/**
 * Removes a given live variable ID from the list of selected variable IDs
 * @param {number} variableId
 */
export function removeVariable(variableId) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_REMOVE_VARIABLE, {
    variableId,
  });
}

/**
 * Updates value for a given variation GUID / variable ID pair
 * @param {number} variableId
 */
export function updateVariableValue(variationGuid, variableId, value) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_UPDATE_VARIABLE_VALUE, {
    variationGuid,
    variableId,
    value,
  });
}

/**
 * Adds a section to the currently editing experiment's list of sections
 */
export function addSection() {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_ADD_SECTION);
}

/**
 * Removes a section based on the given section guid
 * @param {String} sectionGuid
 */
export function removeSection(sectionGuid) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_REMOVE_SECTION, {
    sectionGuid,
  });
}

/**
 * Adds a variation to the section with the given guid
 * @param {String} sectionGuid
 */
export function addVariationToSection(sectionGuid) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_ADD_VARIATION_TO_SECTION, {
    sectionGuid,
  });
}

/**
 * Removes the variation with the given variation guid from the section with
 * the given section guid
 * @param {String} sectionGuid
 * @param {String} variationGuid
 */
export function removeVariationFromSection(sectionGuid, variationGuid) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_REMOVE_VARIATION_FROM_SECTION,
    {
      sectionGuid,
      variationGuid,
    },
  );
}

/**
 * Updates a variation in the section with the given section guid
 * @param {String} sectionGuid
 * @param {Object} variation
 */
export function updateVariationInSection(sectionGuid, variation) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_UPDATE_VARIATION_IN_SECTION, {
    sectionGuid,
    variation,
  });
}

/**
 * Updates the section name for the section with the given section guid
 * @param {String} sectionGuid
 * @param {String} sectionName
 */
export function updateSectionName(sectionGuid, sectionName) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_UPDATE_SECTION_NAME, {
    sectionGuid,
    sectionName,
  });
}

/**
 * Validates the form data for the Create Experiment / Multivariate Test dialog
 * at the time of saving. If there are errors, it will set the errors in the store.
 * @param {String} policy
 */
export function validateFormDataOnSave(policy) {
  let errors = {
    name: '',
    views: '',
    urlTargetingConditions: '',
    urlTargetingApiName: '',
    urlTargetingUrl: '',
    variations: '',
  };
  const isMultivariateTest = policy === LayerEnums.policy.MULTIVARIATE;
  const isABTest = policy === LayerEnums.policy.SINGLE_EXPERIMENT;

  const name = flux.evaluate(getters.name);
  if (!name || name.trim().length === 0) {
    errors.name = constants.ErrorMessages.NAME_MISSING;
  }

  const targetingType = flux.evaluate(getters.targetingType(policy));
  const selectedViewIds = flux.evaluate(getters.selectedViewIds);
  const urlTargetingConfig = flux.evaluate(getters.urlTargetingConfig);

  if (
    (!targetingType ||
      targetingType === LayerConstants.TargetingTypes.SAVED_PAGES) &&
    selectedViewIds.size === 0
  ) {
    errors.views = constants.ErrorMessages.VIEW_MISSING;
  }
  if (targetingType && targetingType === LayerConstants.TargetingTypes.URL) {
    const editUrl = urlTargetingConfig.get('edit_url');
    if (!editUrl) {
      errors.urlTargetingConditions =
        constants.ErrorMessages.URL_TARGETING_MISSING;
    }

    if (
      urlTargetingConfig.get('activation_type') ===
        ViewEnums.activationModes.MANUAL &&
      !urlTargetingConfig.get('api_name')
    ) {
      errors.urlTargetingApiName =
        constants.ErrorMessages.URL_TARGETING_API_NAME_MISSING;
    }
  }

  if (isABTest) {
    /**
     * AB Test-specific Validations.
     */
    const experimentToSave = flux.evaluate(getters.experimentToSave);
    const anyVariationsMissingName = some(
      experimentToSave.variations,
      variation => variation.weight && variation.name === '',
    );

    if (anyVariationsMissingName) {
      errors.variations = constants.ErrorMessages.VARIATION_NAMES_MISSING;
    }
  } else if (isMultivariateTest) {
    /**
     * Multivariate Test-specific Validations.
     */
    const experimentSectionsToCreateForMVT = flux.evaluateToJS(
      getters.experimentSectionsToCreateForMVT,
    );
    const combinationCount = flux.evaluate(getters.combinationCount);
    const mvtErrors = ExperimentSectionFns.validateSections(
      experimentSectionsToCreateForMVT,
      combinationCount,
    );
    errors = Object.assign(errors, mvtErrors);
  }

  setErrors(errors);
  return errors;
}

/**
 * Update an individual property on the variation with the given GUID. If no
 * such variation exists, this has no effect.
 * @param {String} propertyName
 * @param {String} propertyValue
 * @param {String} variationGUID
 */
export function updateVariationProperty(
  propertyName,
  propertyValue,
  variationGUID,
) {
  flux.dispatch(actionTypes.CREATE_EXP_COMPONENT_UPDATE_VARIATION_PROPERTY, {
    propertyName,
    propertyValue,
    variationGUID,
  });
}

/**
 * Set the user attributes for the experiment.
 * @param {Array} userAttributes
 */
export function updateUserAttributes(userAttributes) {
  flux.dispatch(
    actionTypes.CREATE_EXP_COMPONENT_UPDATE_USER_ATTRIBUTES,
    userAttributes,
  );
}

export default {
  init,
  tearDown,
  updateName,
  updateDescription,
  setErrors,
  setSelectedViewIds,
  setUrlTargetingConfig,
  setTargetingType,
  addAudience,
  removeAudience,
  updateAudienceIds,
  updateLayerHoldback,
  updateCurrentlySelectedTrafficAllocationPolicy,
  updateCurrentlySelectedDistributionGoal,
  updateVariation,
  addVariation,
  removeVariation,
  pauseVariation,
  resetToUniformTraffic,
  resumeVariation,
  updateBucketingStrategy,
  updateAudienceMatchType,
  updateAudienceConditions,
  updateAudienceConditionsJson,
  removeIdsFromVariationsForDuplicateExperiments,
  setCurrentExperimentIdToNull,
  addVariable,
  removeVariable,
  updateVariableValue,
  addSection,
  removeSection,
  addVariationToSection,
  removeVariationFromSection,
  updateVariationInSection,
  updateSectionName,
  validateFormDataOnSave,
  updateVariationProperty,
  updateExploitationRate,
  updateIsAutomaticDistributionGoal,
  updateUserAttributes,
};

/**
 * Recommender Stats Entity Module
 */
import actions from './actions';
import entityDef from './entity_definition';
import getters from './getters';

// name exports
export { actions, entityDef, getters };

// default exports
export default {
  actions,
  entityDef,
  getters,
};

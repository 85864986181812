/**
 * Relative Date Filter
 *
 * Using this filter will take the value passed in
 * and then replace that with a string expressing the time between that value's date and now.
 * The value passed in should be the number of milliseconds since 1970, gotten by doing Date.getTime();
 *
 */
const tr = require('optly/translate');
/**
 * datetime should be an number, resulting from calling getTime on a Date object
 * @param {Date|string|Number} either a Date object, or a string or number which can be passed to new Date()
 */
module.exports = function(datetime) {
  const date = tr.date(datetime);
  if (!date.isValid()) {
    throw new Error('Invalid date');
  }
  if (!date.isAfter(tr.date().subtract(6, 'd'))) {
    return date.format('LL');
  }
  return date.fromNow();
};

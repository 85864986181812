/**
 * A data table Table Header <li>
 * EXAMPLE:
 * <div data-sortable-table-id="[[ sortableAttr ]]">
 *   <ul>
 *     <li><span v-component="sortable-li" field="name">Name</span></li>
 *   </ul>
 *   <ul>
 *     <li v-repeat="thingToBeSorted: things">...</li>
 *   </ul>
 * </div>
 */
const vueHelpers = require('optly/utils/vue_helpers');

module.exports = vueHelpers.extendComponent(
  require('./sortable_table_header_component.js'),
  {
    template: require('./sortable_li_component.html'),
  },
);

import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

export const {
  save,
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deleteLiveVariable = baseEntityActions.delete;

export { deleteLiveVariable as delete };

/**
 * Archives a variable. We use delete here since the mixin also cleans up all attached usages on delete
 * @param {Object} variable
 * @return {Deferred}
 */
export function archive(variable) {
  return baseEntityActions.delete({
    id: variable.id,
  });
}

/**
 * Unarchives a variable
 * @param {Object} variable
 * @return {Deferred}
 */
export function unarchive(variable) {
  return baseEntityActions.save({
    id: variable.id,
    archived: false,
  });
}

export default {
  ...baseEntityActions,
  archive,
  unarchive,
};

import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      filter: null,
    });
  },

  initialize() {
    this.on(
      actionTypes.P13N_SET_CURRENT_JOB_STATUS_BY_FILTER,
      setCurrentJobStatusByFilter,
    );
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Object} payload.filter
 */
function setCurrentJobStatusByFilter(state, payload) {
  return state.set('filter', payload.filter);
}

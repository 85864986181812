import { KEYWORD_INPUT_SEPARATORS } from './constants';

const INVALID_KEYWORD_REGEX = /[^a-z-]/gi;

/**
 * Helper method to clean a keyword.
 * Lowercase and replace invalid characters with an empty string.
 * @param {string} keyword
 * @return {string}
 */
export const cleanKeyword = keyword =>
  keyword
    .trim()
    .toLowerCase()
    .replace(INVALID_KEYWORD_REGEX, '');

/**
 * Clean all keywords in the list and remove any empty strings.
 * @param {Array<KeywordWrapper>} keywordList
 * @return {Array<KeywordWrapper>}
 */
export const cleanKeywordWrapperList = keywordList =>
  keywordList
    .map(keyword =>
      Object.assign(keyword, { name: cleanKeyword(keyword.name) }),
    )
    .filter(keyword => !!keyword.name);

/**
 * Helper method to convert a list of keyword wrappers into an
 * underscore joined string.
 *
 * E.g.:
 *   INPUT:
 *   [
 *     {name: "money", status: "valid"},
 *     {name: "savings", status: "valid"}
 *   ]
 *
 *   OUTPUT:
 *   "money_savings"
 *
 * @param {Object<>} keywordWrapperList
 * @return {String}
 */
export const serializeKeywords = keywordWrapperList =>
  keywordWrapperList
    .map(k => k.name)
    .sort()
    .join('_');

/**
 * Helper method to construct a list of KeywordWrappers with a status of null
 * from an underscore concatenated string of keywords.
 * Also updates keywords to enforce formatting rules.
 *
 * E.g.:
 *   INPUT:
 *     "money_savings"
 *
 *   OUTPUT:
 *     [
 *       {name: "money", status: null},
 *       {name: "savings", status: null}
 *     ]
 *
 * @param {String}
 * @return {Array.<KeywordWrapper>}
 */
export const deserializeKeywords = keywordString => {
  const SEPARATOR_PLACEHOLDER = '\n';

  return (
    KEYWORD_INPUT_SEPARATORS

      /* Split keywordString on all of our KEYWORD_INPUT_SEPARATORS */
      .reduce(
        (acc, addKey) => acc.split(addKey).join(SEPARATOR_PLACEHOLDER),
        keywordString,
      )

      /* Do a final split using our placeholder. */
      .split(SEPARATOR_PLACEHOLDER)

      /* Cleanup each keyword */
      .map(cleanKeyword)

      /* Remove any empty or duplicate values */
      .filter((value, index, self) => !!value && self.indexOf(value) === index)

      /* Format them as KeywordWrapper objects */
      .map(k => ({ name: k }))
  );
};

export default {
  cleanKeyword,

  cleanKeywordWrapperList,

  deserializeKeywords,

  serializeKeywords,
};

import ApiLocalCacheStore from './api_local_cache_store';
import ApiRequestCacheStore from './api_request_cache_store';
import ApiRequestInProgressStore from './api_request_in_progress_store';
import ApiStubsStore from './api_stubs_store';
import EntityCacheStore from './entity_cache_store';

export default {
  apiLocalCache: ApiLocalCacheStore,
  apiRequestCache: ApiRequestCacheStore,
  apiRequestInProgress: ApiRequestInProgressStore,
  apiStubs: ApiStubsStore,
  entityCache: EntityCacheStore,
};

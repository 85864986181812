module.exports = {
  currentState: [
    ['browserHistory', 'historyStack'],
    function(historyStack) {
      return historyStack.last();
    },
  ],
  previousState: [
    ['browserHistory', 'historyStack'],
    function(historyStack) {
      if (historyStack.size > 1) {
        // we are going back 2 because the immutable list is 0-index based and we want to look at the
        // entry penultimate entry
        return historyStack.get(historyStack.size - 2);
      }
      return historyStack.last();
    },
  ],
};

import $ from 'jquery';
import _ from 'lodash';

import config from 'atomic-config';

import sprintf from 'sprintf';

import flux from 'core/flux';

import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import {
  getters as LayerGetters,
  fns as LayerFns,
} from 'optly/modules/entity/layer';
import { getters as LayerExperimentGetters } from 'optly/modules/entity/layer_experiment';
import { getters as ProjectGetters } from 'optly/modules/entity/project';

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';

import fns from './fns';
import getters from './getters';
import constants, { EDP_URLS } from './constants';
import actionTypes from './action_types';

const MIN_RETRY_DELAY = 10000;
const MAX_RETRIES = 3;

// Need this to reference exported functions in other exported functions so
// that the former can be stubbed in tests
let defaultExport; // eslint-disable-line import/no-mutable-exports

/**
 * @param {Object} request base $.ajax() request settings to extend
 * @param {Object} request.data JSON body to send with request
 * @return {Object} updated request settings for making POST request to Results API via $.ajax()
 */
const formatRequest = ({ requestType, ...request }) => {
  const headers = _.extend(
    {},
    {
      Accept: constants.ACCEPT_HEADER,
      'Content-Type': 'application/json; charset=utf-8',
      'X-Account-Id': flux.evaluate(AdminAccountGetters.id),
      'X-Opti-Group': 'results-ui',
      'X-App-Trace-Id': flux.evaluate(getters.traceId),
    },
    request.headers,
  );

  const formattedRequest = _.extend({}, request, {
    type: requestType,
    headers,
    traditional: true,
  });

  if (requestType === 'POST') {
    formattedRequest.data = JSON.stringify(request.data);
  }

  return formattedRequest;
};

export const addXHR = xhr => {
  flux.dispatch(actionTypes.RESULTS_API_ADD_XHR, {
    xhr,
  });
};

/**
 * Function which actually makes all the results requests. Takes an endpoint and a payload and makes the
 * request. If the request succeeds, it notifies the application, if it fails it retries a specific number
 * of times.
 * @param {String} resourceKey
 * @param {Object} data
 * @param {String} url
 * @param {Object} context
 * @param {Object} headers
 * @returns {Deferred}
 */
const makeGenericRequest = (
  resourceKey,
  data,
  url,
  context,
  headers,
  requestType = 'POST',
) => {
  /* eslint-disable no-param-reassign */
  const startTime = new Date().getTime();
  const requestContext = context || {
    deferred: $.Deferred(),
    attempt: 0,
    firstAttemptStartTime: startTime,
  };

  const currentProject = flux.evaluate(CurrentProjectGetters.project);

  // TODO - Pass in user selected time offset instead of false (METRIC-861)
  // Format timing based on user selected time offset

  if (requestType === 'POST') {
    data = fns.formatDates(data, false);
  }

  let confidenceLevel =
    currentProject.get('experiment_confidence_threshold') /
    constants.CONFIDENCE_LEVEL_DIVISOR;

  if (confidenceLevel >= 1) {
    confidenceLevel = constants.MAX_CONFIDENCE_LEVEL; // Approximate 1 without going to 1.
  }
  // Currently we do not have multi platform projects, so there
  // is only ever one value in the project_platforms map
  data = _.extend({}, data, {
    platform: currentProject.get('project_platforms').get(0),
    ipFilter: currentProject.get('ip_filter'),
    confidenceLevel,
  });

  const sessionDbApiSource = config.get('env.SESSION_DB_API_SOURCE', null);
  if (sessionDbApiSource) {
    data = _.extend(
      {},
      {
        api_source: sessionDbApiSource,
      },
      data,
    );
  }

  data = requestType === 'POST' ? data : null;

  const xhr = $.ajax(formatRequest({ data, url, headers, requestType })); // eslint-disable-line fetch/no-jquery

  xhr.then(
    response => {
      flux.dispatch(actionTypes.RESULTS_API_REQUEST_SUCCESS, {
        resourceKey,
        query: data,
        response,
      });
      requestContext.deferred.resolve(response);
    },
    response => {
      // Don't retry if we are over the max number of retries or if the XHR has been aborted.
      if (requestContext.attempt >= MAX_RETRIES || response.status === 0) {
        requestContext.deferred.reject();
        return;
      }
      // We really only want to delay if the response errors immediately, otherwise just retry as soon as it fails
      // Figure out if we can properly identify a 504 here and handle that separately.
      const currentTime = new Date().getTime();
      const retryDelay = Math.max(
        0,
        MIN_RETRY_DELAY - (currentTime - startTime),
      );
      const nextContext = _.extend({}, requestContext, {
        attempt: requestContext.attempt + 1,
      });
      setTimeout(
        _.partial(
          makeGenericRequest,
          resourceKey,
          data,
          url,
          nextContext,
          _,
          requestType,
        ),
        retryDelay,
      );
    },
  );

  addXHR(xhr);

  return requestContext.deferred;
  /* eslint-enable no-param-reassign */
};

/**
 * @param {Object} value - the value to resolve with.
 * @return {Object} a Promise object that is resolved with value on next tick.
 */
const resolvedPromise = value => {
  const deferred = $.Deferred();
  setTimeout(() => {
    deferred.resolve(value);
  }, 0);
  return deferred.promise();
};

export const clearXHRs = () => {
  flux.dispatch(actionTypes.RESULTS_API_CLEAR_XHRS);
};

/**
 * @param {Object} data
 * @return {Object}
 */
export const formatLayerData = data => {
  /* eslint-disable no-param-reassign */
  if (data.experimentIds) {
    data.experimentTokens = data.experimentIds.map(experimentId =>
      flux.evaluateToJS(
        LayerExperimentGetters.resultsAPITokenById(experimentId),
      ),
    );
  }

  const requestData = _.extend(
    {
      token: flux.evaluateToJS(CurrentProjectGetters.projectResultsAPIToken),
      layerToken: flux.evaluateToJS(CurrentLayerGetters.resultsAPIToken),
    },
    data,
  );

  return requestData;
  /* eslint-enable no-param-reassign */
};

export const generateNewTraceId = () => {
  flux.dispatch(actionTypes.RESULTS_API_SET_TRACE_ID, {
    traceId: fns.generateTraceId(),
  });
};

/**
 * Root endpoint for all /experiments requests.
 * @param {String} requestType
 * @param {Object} data
 */
export const makeExperimentRequest = (requestType, data) => {
  let { experimentId } = data;
  if (!experimentId) {
    experimentId = flux.evaluateToJS(
      CurrentLayerGetters.currentLayerABExperiment,
    ).id;
  }

  const env = config.get('env.ENVIRONMENT');
  const urlBase = env === 'production' ? constants.BASE_URL : EDP_URLS.UAT;
  const url = sprintf(
    '%s/experiments/%s/%s',
    urlBase,
    experimentId,
    requestType,
  );

  let requestData = _.extend(
    {
      token: flux.evaluateToJS(
        LayerExperimentGetters.resultsAPITokenById(experimentId),
      ),
    },
    data,
  );

  delete requestData.experimentId;

  const currentLayer = flux.evaluateToJS(CurrentLayerGetters.layer);

  if (currentLayer) {
    const isABLayer = LayerFns.isABTestLayer(currentLayer);
    requestData = _.extend(
      {
        campaignMode: isABLayer ? 'ab' : 'p13n',
      },
      requestData,
    );
  }

  requestData = fns.appendAdditionalQueryParams(requestData);

  return makeGenericRequest(`experiment/${requestType}`, requestData, url);
};

export const makeSectionRequest = (requestType, data) => {
  let { experimentId } = data;
  const { sectionId } = data;

  if (!experimentId) {
    experimentId = flux.evaluateToJS(
      CurrentLayerGetters.currentLayerABExperiment,
    ).id;
  }
  const env = config.get('env.ENVIRONMENT');
  const urlBase = env === 'production' ? constants.BASE_URL : EDP_URLS.UAT;

  const url = `${urlBase}/experiments/${experimentId}/sections/${sectionId}/${requestType}`;

  let requestData = {
    token: flux.evaluateToJS(
      LayerExperimentGetters.resultsAPITokenById(experimentId),
    ),
    ...data,
  };

  requestData = fns.appendAdditionalQueryParams(requestData);

  // Remove unnecessary properties that should not be sent as query params
  delete requestData.experimentId;
  delete requestData.sectionId;

  return makeGenericRequest(`sections/${requestType}`, requestData, url);
};

/**
 * Root endpoint for all /layers requests.
 * @param {String} requestType
 * @param {Object} data
 * @returns {Deferred}
 */
export const makeLayerRequest = (requestType, data) => {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const layerId = flux.evaluate(CurrentLayerGetters.id);
  const env = config.get('env.ENVIRONMENT');
  const urlBase = env === 'production' ? constants.BASE_URL : EDP_URLS.UAT;

  const url = sprintf(
    '%s/projects/%s/layers/%s/%s',
    urlBase,
    projectId,
    layerId,
    requestType,
  );

  let requestData = formatLayerData(data);

  requestData = fns.appendAdditionalQueryParams(requestData);

  return makeGenericRequest(`layer/${requestType}`, requestData, url);
};

export const makeSsrmRequest = data => {
  const { experimentId } = data;
  const token = flux.evaluateToJS(
    LayerExperimentGetters.resultsAPITokenById(experimentId),
  );

  const env = config.get('env.ENVIRONMENT');
  const baseUrl = env === 'production' ? EDP_URLS.PROD : EDP_URLS.UAT;
  const url = sprintf('%s/ssrm/%s', baseUrl, experimentId);

  const headers = {
    'X-Opti-Group': 'ssrm-results-ui',
    Authorization: `bearer ${token}`,
  };

  return makeGenericRequest('ssrm', null, url, undefined, headers, 'GET');
};

export const makeFXSsrmRequest = data => {
  const { projectId, experimentId, flagKey, ruleKey, envKey } = data;
  const token = flux.evaluateToJS(
    LayerExperimentGetters.resultsAPITokenById(experimentId),
  );

  const env = config.get('env.ENVIRONMENT');

  const baseUrl = env === 'production' ? EDP_URLS.PROD : EDP_URLS.UAT;

  const url = sprintf(
    '%s/ssrm/fx/%s?projectId=%s&flagKey=%s&environmentKey=%s&ruleKey=%s',
    baseUrl,
    experimentId,
    projectId,
    flagKey,
    envKey,
    ruleKey,
  );

  const headers = {
    'X-Opti-Group': 'ssrm-results-ui',
    Authorization: `bearer ${token}`,
  };

  return makeGenericRequest('fx-ssrm', null, url, undefined, headers, 'GET');
};

/**
 * @param {String} requestType
 * @param {Function} formatter - Optional function mapped over the raw response
 * for mutation. Should be reserved for aiding migrating API responses.
 */
const makeLayerRequestFunc = (requestType, formatter) => {
  /* eslint-disable no-param-reassign */
  formatter = formatter || _.identity;
  return query =>
    defaultExport.makeLayerRequest(requestType, query).then(formatter);
  /* eslint-enable no-param-reassign */
};

const makeSsrmRequestFunc = formatter => {
  formatter = formatter || _.identity;
  return query => defaultExport.makeSsrmRequest(query).then(formatter);
};

const makeFXSsrmRequestFunc = formatter => {
  formatter = formatter || _.identity;
  return query => defaultExport.makeFXSsrmRequest(query).then(formatter);
};

/**
 * @param {String} requestType
 * @param {Function} formatter - Optional function mapped over the raw response
 * for mutation. Should be reserved for aiding migrating API responses.
 */
const makeExperimentRequestFunc = (requestType, formatter) => {
  /* eslint-disable no-param-reassign */
  formatter = formatter || _.identity;
  return query =>
    defaultExport.makeExperimentRequest(requestType, query).then(formatter);
  /* eslint-enable no-param-reassign */
};

const makeSectionRequestFunc = requestType => query =>
  defaultExport.makeSectionRequest(requestType, query);

export const makeLayerReachRequest = makeLayerRequestFunc('reach');

export const makeLayerResultsRequest = makeLayerRequestFunc('results');

export const makeLayerTimeseriesRequest = makeLayerRequestFunc('timeseries');

export const makeLayerExperimentsRequest = makeLayerRequestFunc('experiments');

export const makeSSRMRequest = makeSsrmRequestFunc();

export const makeSSRMRequestForFX = makeFXSsrmRequestFunc();

export const makeExperimentResultsRequest = makeExperimentRequestFunc(
  'results',
);

export const makeExperimentTimeseriesRequest = makeExperimentRequestFunc(
  'timeseries',
);

export const makeSectionResultsRequest = makeSectionRequestFunc('results');

export const makeSectionTimeseriesRequest = makeSectionRequestFunc(
  'timeseries',
);

/**
 * Makes request for the current layer's segment values
 * @param {boolean} allProjectSegments Changes the value of the byName parameter depending on whether the user
 * wants all project segments or current project segments only. Default is false
 */
export const makeLayerSegmentValueRequest = allProjectSegments => {
  const layer = flux.evaluate(CurrentLayerGetters.layer);
  const layerExperimentIds = flux.evaluateToJS(
    CurrentLayerGetters.allExperimentIdsPointingToLayer,
  );

  if (!layer) {
    // Bail out with an empty response if we are missing the layer.
    // Segment values request is considered non-essential to the page, so we don't throw an error.
    return resolvedPromise({});
  }

  const project = flux.evaluate(ProjectGetters.byId(layer.get('project_id')));
  const campaignTimerange = flux.evaluateToJS(getters.campaignTimerange);

  const data = formatLayerData({
    begin: layer.get('earliest'),
    end: new Date(campaignTimerange.end).toISOString(),
    campaignStart: layer.get('earliest'),
    experimentIds: layerExperimentIds,
    byName: allProjectSegments,
    ipFilter: project.get('ip_filter') || '',
  });

  const env = config.get('env.ENVIRONMENT');
  const urlBase =
    env === 'production'
      ? `${constants.BASE_URL}/segments`
      : `${EDP_URLS.UAT}/segments`;

  const url = sprintf('%s/%s', urlBase, layer.get('id'));

  const xhr = $.ajax(formatRequest({ url, data, requestType: 'POST' })); // eslint-disable-line fetch/no-jquery

  addXHR(xhr);

  return xhr;
};

/**
 * @param experimentId The experimentId to request segment values for. If falsey, the segment values for current layer are requested.
 * @param {boolean} allProjectSegments Changes the value of the byName parameter depending on whether the user
 * wants all project segments or current project segments only. Default is true, otherwise depends on the all_projects_segment_switch feature flag
 */
export const makeSegmentValueRequest = (experimentId, allProjectSegments) => {
  if (!experimentId) {
    return makeLayerSegmentValueRequest(allProjectSegments);
  }

  const experiment = flux.evaluate(LayerExperimentGetters.byId(experimentId));
  const layer = flux.evaluate(LayerGetters.byId(experiment.get('layer_id')));
  const project = flux.evaluate(ProjectGetters.byId(layer.get('project_id')));

  const env = config.get('env.ENVIRONMENT');
  const urlBase =
    env === 'production'
      ? `${constants.BASE_URL}/segments`
      : `${EDP_URLS.UAT}/segments`;
  const url = sprintf('%s/%s', urlBase, experimentId);

  const layerExperimentToken = flux.evaluateToJS(
    LayerExperimentGetters.resultsAPITokenById(experimentId),
  );
  const campaignTimerange = flux.evaluateToJS(getters.campaignTimerange);

  const data = {
    begin: layer.get('earliest'),
    end: new Date(campaignTimerange.end).toISOString(),
    ipFilter: project.get('ip_filter') || '',
    byName: allProjectSegments,
    token: layerExperimentToken,
  };
  /* eslint-disable fetch/no-jquery */
  const xhr = $.ajax(formatRequest({ data, url, requestType: 'POST' }));
  /* eslint-enable fetch/no-jquery */
  addXHR(xhr);

  return xhr;
};

export const makeSegmentCategoriesRequest = () => {
  const projectId = flux.evaluate(CurrentProjectGetters.id);
  const url = sprintf(constants.SEGMENT_CATEGORY_API_ROUTE, projectId);

  const headers = {
    'X-App-Trace-Id': flux.evaluate(getters.traceId),
    token: flux.evaluateToJS(CurrentProjectGetters.projectToken),
  };
  /* eslint-disable fetch/no-jquery */
  const xhr = $.ajax({
    url,
    headers,
    type: 'GET',
    traditional: true,
  });
  /* eslint-enable fetch/no-jquery */
  addXHR(xhr);

  return xhr;
};

export const setDateNow = date => {
  flux.dispatch(actionTypes.RESULTS_API_SET_DATE_NOW, {
    dateNow: date,
  });
};

defaultExport = {
  addXHR,
  clearXHRs,
  formatLayerData,
  generateNewTraceId,
  makeExperimentRequest,
  makeLayerRequest,
  makeLayerReachRequest,
  makeLayerResultsRequest,
  makeLayerTimeseriesRequest,
  makeLayerExperimentsRequest,
  makeExperimentResultsRequest,
  makeExperimentTimeseriesRequest,
  makeLayerSegmentValueRequest,
  makeSectionRequest,
  makeSectionResultsRequest,
  makeSectionTimeseriesRequest,
  makeSegmentValueRequest,
  makeSegmentCategoriesRequest,
  makeSSRMRequest,
  makeSSRMRequestForFX,
  setDateNow,
  makeSsrmRequest,
  makeFXSsrmRequest,
};

export default defaultExport;

/**
 * A bunch of utility functions used to format numbers for display in the product.
 */

const ONE_THOUSAND = 1000;
const ONE_MILLION = ONE_THOUSAND ** 2;
const ONE_BILLION = ONE_THOUSAND ** 3;
const NO_OUTPUT = '--';
const REVENUE_DIVISION_ADJUSTMENT_AMOUNT = 100;

/**
 * @param {Number} number
 * @param {Number} decimals
 * @return {Number}
 */
export const round = (number, decimals = 2) => {
  if (!Number.isFinite(number)) {
    return null;
  }
  return Math.round(number * 10 ** decimals) / 10 ** decimals;
};

/**
 * Format a number with no decimal points if decimals are 0
 * @param {Number} number
 * @return {String}
 */
export const formatWholeNumber = number => {
  if (!Number.isFinite(number)) {
    return NO_OUTPUT;
  }

  return tr.number(number, 0);
};

/**
 * Format a raw number for display with a desired number of decimal points (default 3)
 * except for whole numbers which are formatted with no decimal points
 * @param {Number} number
 * @param {Number} precision
 * @return {String}
 */
export const formatFloatAsFloatOrWholeForDisplay = (number, precision = 3) => {
  if (!Number.isFinite(number)) {
    return NO_OUTPUT;
  }
  if (Math.floor(number) === number) {
    return tr.number(number, 0);
  }

  return tr.number(number, precision);
};

/**
 * Format a raw number for display with a desired number of decimal points (default 3)
 * @param {Number} number
 * @param {Number} precision
 * @return {String}
 */
export const formatFloatForDisplay = (number, precision = 3) => {
  if (!Number.isFinite(number)) {
    return NO_OUTPUT;
  }

  return tr.number(number, precision);
};

/**
 * Format a raw percentage (base 1) for display as increase
 * @param {Number} number
 * @return {String}
 */
export const formatIncrease = number => {
  if (!Number.isFinite(number)) {
    return NO_OUTPUT;
  }
  return (number > 0 ? '+' : '') + tr.number(number, 2, true);
};

/**
 * Format a raw percentage (base 1)
 * @param {Number} number
 * @return {Number}
 */
export const formatPercent = number => round(number * 100, 2);

/**
 * Format a raw percentage (base 1) for display as lift
 * @param {Number} number
 * @param {Boolean} percentFormatted
 * @return {String}
 */
export const formatLift = (number, percentFormatted) => {
  let formattedNumber = number;

  if (!Number.isFinite(formattedNumber)) {
    return NO_OUTPUT;
  }
  if (!percentFormatted) {
    formattedNumber = formatPercent(formattedNumber);
  }

  return `${(formattedNumber > 0 ? '+' : '') +
    (formattedNumber >= 1000
      ? formatWholeNumber(formattedNumber)
      : formattedNumber)}%`;
};

export const formatPercentLift = number => formatLift(number, false);

/**
 * Format a raw number for display with a desired number of decimal points (default 2)
 * @param {Number} number
 * @param {Number} precision
 * @return {String}
 */
export const formatNumberForDisplay = (number, precision = 2) => {
  if (!Number.isFinite(number)) {
    return NO_OUTPUT;
  }

  return tr.number(number, precision);
};

/**
 * Format a raw number for display in thousand(K), million(M), or billion(B) unit with a desired number of decimal points (default 2).
 *
 * @param {Number} number
 * @param {Number} precision
 * @returns {String}
 */
export const formatBigNumberForDisplay = (number, precision = 2) => {
  if (!Number.isFinite(number)) {
    return NO_OUTPUT;
  }

  let displayNumber = Math.abs(number);

  if (displayNumber < ONE_THOUSAND) {
    displayNumber = formatFloatAsFloatOrWholeForDisplay(
      displayNumber,
      precision,
    );
  } else if (displayNumber < ONE_MILLION) {
    displayNumber = `${formatFloatAsFloatOrWholeForDisplay(
      displayNumber / ONE_THOUSAND,
      precision,
    )}K`;
  } else if (displayNumber < ONE_BILLION) {
    displayNumber = `${formatFloatAsFloatOrWholeForDisplay(
      displayNumber / ONE_MILLION,
      precision,
    )}M`;
  } else {
    displayNumber = `${formatFloatAsFloatOrWholeForDisplay(
      displayNumber / ONE_BILLION,
      precision,
    )}B`;
  }

  if (number < 0) {
    displayNumber = `-${displayNumber}`;
  }

  return displayNumber;
};

export const formatPercentForDisplay = number => {
  if (!Number.isFinite(number)) {
    return NO_OUTPUT;
  }
  return `${tr.number(number * 100, 2)}%`;
};

/**
 * Format a raw revenue
 * @param {Number} number
 * @return {Number|String}
 */
export const formatRevenue = number => {
  if (!Number.isFinite(number)) {
    return NO_OUTPUT;
  }
  return number / REVENUE_DIVISION_ADJUSTMENT_AMOUNT;
};

/**
 * @param {Number} number
 * @param {Boolean} alreadyDivided
 * @param {Object} currency
 * @param {Boolean} preserveDecimals
 */
export const formatPriceForDisplay = (
  number,
  alreadyDivided,
  currency,
  preserveDecimals,
) => {
  if (!Number.isFinite(number)) {
    return NO_OUTPUT;
  }
  let formattedNumber = number;
  if (!alreadyDivided) {
    formattedNumber = formatRevenue(number);
  }
  formattedNumber = Math.abs(formattedNumber);

  // Some currencies do not have fractional values, but rates typically have non-whole-number values
  let displayPrice = preserveDecimals
    ? currency.symbol + Math.round(formattedNumber * 100) / 100
    : formattedNumber.toLocaleString(currency.locale, {
        style: 'currency',
        currency: currency.code,
      });

  // Instead of trying to coerce the correct location just remove the US from the price
  // display for all Dollar based currencies so they appear as just $100.00 instead of US$100.00 (Chrome) or USD100.00 (Safari)
  if (currency.code === 'USD') {
    if (displayPrice.indexOf('USD') === 0) {
      displayPrice = displayPrice.replace('USD', '$');
    }

    if (displayPrice.indexOf('US$') === 0) {
      displayPrice = displayPrice.replace('US$', '$');
    }
  }

  if (number < 0) {
    return `-${displayPrice}`;
  }

  return displayPrice;
};

export const formatPriceLift = (
  number,
  alreadyDivided,
  currency,
  preserveDecimals,
) => {
  const price = formatPriceForDisplay(
    number,
    alreadyDivided,
    currency,
    preserveDecimals,
  );
  return `${number >= 0 ? '+' : ''}${price}`;
};

/**
 * Format a raw percentage (base 1) for display as statistical significance
 * @param {Number} number
 * @return {String}
 */
export const formatStatSig = number => {
  if (!Number.isFinite(number)) {
    return NO_OUTPUT;
  }
  if (number >= 0.991) {
    return '>99%';
  }
  if (number <= 0.009) {
    return '<1%';
  }
  return `${tr.number(number * 100, 0)}%`;
};

/**
 * Display a number as a percentage point (the difference bettween two percentages)
 * Ex: 10.3% - 4.5% = 5.8 pp
 * @param {Number} number
 * @param {Boolean} alreadyMultiplied - When passing a percentage determines whether or not it has already been converted
 *                                      to a display format (i.e. .13 -> 13)
 * @returns {String}
 */
export const formatPercentagePoint = (number, alreadyMultiplied = false) => {
  if (!Number.isFinite(number)) {
    return NO_OUTPUT;
  }

  return `${number >= 0 ? '+' : ''}${tr.number(
    number * (alreadyMultiplied ? 1 : 100),
    2,
  )}pp`;
};

export default {
  formatFloatAsFloatOrWholeForDisplay,
  formatFloatForDisplay,
  formatIncrease,
  formatLift,
  formatNumberForDisplay,
  formatBigNumberForDisplay,
  formatPercent,
  formatPercentagePoint,
  formatPercentForDisplay,
  formatPercentLift,
  formatPriceForDisplay,
  formatPriceLift,
  formatRevenue,
  formatStatSig,
  formatWholeNumber,
  round,
};

import schema from 'js-schema';

import { Variation } from 'optly/modules/entity/variation/types';

import * as enums from './enums';

export const WidgetChange = schema({
  id: String,
  name: String,
  type: enums.ChangeTypes.WIDGET,
  dependencies: Array,
  widget_id: String,
  config: Object,
});

export const RedirectChange = schema({
  id: String,
  type: enums.ChangeTypes.REDIRECT,
  dest: String,
  preserveParameters: Boolean,
  allowAdditionalRedirect: Boolean,
});

/**
 * @typedef {Object} LayerExperiment
 * @property {Number} id
 * @property {Number} project_id
 * @property {Boolean} archived
 * @property {null|String} description
 * @property {String} name
 * @property {Number} layer_id
 * @property {Date} created
 * @property {Array<Variation>} variations
 */

/**
 * Schema for LayerExperiment
 * TODO: This should be an exhaustive list of all the properties that are on a layer experiment
 * returned from the mixin. See src/www/mixins/optimizely/layer_experiment.py
 * JIRA: https://optimizely.atlassian.net/browse/FE-456
 */
export const LayerExperiment = schema({
  id: Number,
  project_id: Number,
  archived: Boolean,
  description: [null, String],
  name: String,
  layer_id: Number,
  created: Date,
  variations: Array.of(Variation),
});

import $ from 'jquery';
import _ from 'lodash';
import flux from 'core/flux';

import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';
import enums from './enums';
import getters from './getters';

let ExperimentationGroupActions;

const baseEntityActions = RestApi.createEntityActions(definition);

/**
 * Add the given entity to the given group
 * @param {Object} entity
 * @param {Object} group
 * @return {Promise}
 */
export function addEntityToGroup(entity, group) {
  const groupObject = {
    ...group,
  };
  if (!groupObject.entities) {
    groupObject.entities = [];
  }
  groupObject.entities.push(entity);
  return ExperimentationGroupActions.save(groupObject);
}

/**
 * Archives the group
 * @param {Immutable.Map} group
 * @return {Deferred}
 */
export function archive(group) {
  return ExperimentationGroupActions.save({
    id: group.get('id'),
    archived: true,
  });
}

/**
 * Create a new experimentation group
 * @param  {Object} group
 * @return {Promise}
 */
export function createExperimentationGroup(group) {
  const groupData = {
    ...group,
    policy: enums.POLICY_TYPES.RANDOM,
  };

  return ExperimentationGroupActions.save(groupData);
}

/**
 * Unarchives the group
 * @param {Immutable.Map} group
 * @return {Deferred}
 */
export function unarchive(group) {
  return ExperimentationGroupActions.save({
    id: group.get('id'),
    archived: false,
  });
}

/**
 * Updates the group associated with a given entity, removing it from its previous group if necessary
 * @param {Number} entityId
 * @param {Number} entityWeight
 * @param {String} entityKind
 * @param {Number} groupId
 * @return {Promise}
 */
export function updateExperimentGroup(
  entityId,
  entityWeight,
  entityKind,
  groupId,
) {
  const dissociateOldGroup = oldGroup => {
    _.remove(oldGroup.entities, { id: entityId });
    return ExperimentationGroupActions.save(oldGroup);
  };

  const associateNewGroup = newGroupId => {
    const newGroup = flux.evaluateToJS(getters.byId(newGroupId));
    const entityToAdd = {
      id: entityId,
      kind: entityKind,
      weight: entityWeight,
    };
    return addEntityToGroup(entityToAdd, newGroup);
  };

  const entityToGroupMap = flux.evaluateToJS(getters.entityToGroupMap);
  if (entityToGroupMap[entityId]) {
    const oldGroupId = entityToGroupMap[entityId].id;
    const oldGroup = flux.evaluateToJS(getters.byId(oldGroupId));

    // if we're not changing groups, just update the traffic allocation
    if (oldGroupId === groupId) {
      _.chain(oldGroup.entities)
        .find({ id: entityId })
        .extend({ weight: entityWeight })
        .value();
      return ExperimentationGroupActions.save(oldGroup);
    }

    // remove experiment from old group if it has one
    return dissociateOldGroup(oldGroup).then(() => {
      if (groupId) {
        return associateNewGroup(groupId);
      }
    });
  }

  // associate experiment with new group (if applicable)
  if (groupId) {
    return associateNewGroup(groupId);
  }

  return $.Deferred().resolve();
}

export default ExperimentationGroupActions = {
  ...baseEntityActions,
  addEntityToGroup,
  archive,
  createExperimentationGroup,
  unarchive,
  updateExperimentGroup,
};

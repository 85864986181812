/**
 * IMPORTANT!
 *
 * SELECT COMPONENT WILL BE DEPRECATED!
 * USE THE SELECT_DROPDOWN COMPONENT INSTEAD.
 *
 *
 *
 * A Select component, with accompanying Option component
 *
 * Select comes in two varieties. An instance of Select is exactly one of
 * the following:
 *
 *   Controlled, if it's given a 'value' prop.
 *   This component is pure.
 *
 *   Uncontrolled, if it's given a 'defaultValue' prop.
 *   This component is stateful.
 *
 * For more info, see https://facebook.github.io/react/docs/forms.html#why-select-value
 */
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Icon } from 'optimizely-oui';
import classNames from 'classnames';

import dropdownService from 'optly/services/dropdown';

import Option from './option';

const toggleDropdown = event => dropdownService.toggle(event.currentTarget);

class Select extends React.Component {
  static propTypes = {
    /**
     * Should be one or more Select.Option instances
     */
    children: PropTypes.node,

    /**
     * For uncontrolled Selects. The initial state value.
     */
    defaultValue: PropTypes.any,

    /**
     * If true, the activator spans the width of its container
     */
    isActivatorFullWidth: PropTypes.bool,

    /**
     * The select is greyed out if it is disabled
     */
    isDisabled: PropTypes.bool,

    /**
     * The minimum width of the dropdown list; any valid CSS width value
     */
    minDropdownWidth: PropTypes.string,

    /**
     * Called with two arguments, (newValue, oldValue), whenever a child Option
     * is clicked
     */
    onChange: PropTypes.func,

    testSection: PropTypes.string,

    /**
     * For controlled Selects. The value of the current Select
     */
    value: PropTypes.any,
  };

  isControlled = () => this.props.value !== undefined;

  getValue = () => (this.isControlled() ? this.props.value : this.state.value);

  handleChange = newValue => {
    const oldValue = this.getValue();

    if (!this.isControlled()) {
      this.setState({
        value: newValue,
      });
    }

    if (this.props.onChange) {
      this.props.onChange(newValue, oldValue);
    }
  };

  getLabelFromChildWithValue = value => {
    let label;
    React.Children.forEach(this.props.children, child => {
      if (child.props.value === value) {
        label = child.props.label;
      }
    });

    return label;
  };

  state = this.isControlled() ? {} : { value: this.props.defaultValue };

  render() {
    // Link child Options to `this` via callback
    const childOptions = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        parentCallback: this.handleChange,
      }),
    );

    const label = this.isControlled()
      ? this.getLabelFromChildWithValue(this.props.value)
      : this.getLabelFromChildWithValue(this.state.value);

    const classes = classNames({
      'lego-dropdown-group': true,
      'width--1-1': this.props.isActivatorFullWidth,
    });

    const buttonWidth = this.props.isActivatorFullWidth ? 'full' : null;

    return (
      <div
        data-ui-component={true}
        className={classes}
        onClick={this.props.isDisabled ? () => null : toggleDropdown}>
        <Button
          isDisabled={this.props.isDisabled}
          width={buttonWidth}
          testSection={this.props.testSection}>
          <div className="flex flex-align--center">
            <span className="flex--1 text--left">{label}</span>{' '}
            <Icon className="push--left" name="angle-down" size="small" />
          </div>
        </Button>
        <ul
          className="lego-dropdown"
          style={{ minWidth: this.props.minDropdownWidth }}>
          {childOptions}
        </ul>
      </div>
    );
  }
}

Select.Option = Option;

export default Select;

import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Nuclear.Store({
  getInitialState() {
    return toImmutable({});
  },

  initialize() {
    this.on(actionTypes.PAGEABLE_SAVE_CURRENT_PAGE, saveCurrentPage);
    this.on(actionTypes.PAGEABLE_RESET_CURRENT_PAGE, resetCurrentPage);
  },
});

function saveCurrentPage(state, { id, currentPage }) {
  return state.set(
    id,
    toImmutable({
      currentPage,
    }),
  );
}

function resetCurrentPage(state, { id }) {
  return state.delete(id);
}

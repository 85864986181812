/**
 * Entity Definition for schedule
 */

export const entity = 'schedules';

export const parent = {
  entity: 'experiments',
  key: 'experiment_id',
};

export default {
  entity,
  parent,
  /**
   * Optional definitions
  // fieldTypes are used for client side sorting / filtering
  fieldTypes: {
    id: fieldTypes.NUMBER,
    project_id: fieldTypes.NUMBER,
    name: fieldTypes.STRING,
    description: fieldTypes.STRING,
    last_modified: fieldTypes.STRING,
    conditions: fieldTypes.ARRAY,
    segmentation: fieldTypes.BOOLEAN,
  },
   */
};

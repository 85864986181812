import _ from 'lodash';

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';
import AdminAccountGetters from 'optly/modules/admin_account/getters';
import TargetingConditionEnums from 'optly/modules/entity/targeting_condition/enums';
import TargetingConditionGetters from 'optly/modules/entity/targeting_condition/getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export default {
  ...baseEntityGetters,
  defaultDimensions: [
    AdminAccountGetters.accountPermissions,
    TargetingConditionGetters.entityCache,
    (accountPermissions, dimensions) =>
      dimensions
        .filter(
          dimension =>
            dimension.get('compatible_audiences') &&
            dimension
              .get('compatible_audiences')
              .includes(TargetingConditionEnums.audienceVersions.OPTIMIZELY_X),
        )
        .map(dimension => {
          // permission_required empty state is {}, else it's a string.
          const hasPermission =
            typeof dimension.get('permission_required') === 'string'
              ? accountPermissions.contains(
                  dimension.get('permission_required'),
                )
              : true;
          // deprecated_by empty state is {}, else it's a string.
          const deprecated =
            typeof dimension.get('deprecated_by') === 'string'
              ? accountPermissions.contains(dimension.get('deprecated_by'))
              : false;
          return dimension
            .set('has_permission', hasPermission)
            .set('deprecated', deprecated);
        }),
  ],
};

/**
 * Entity Definition for projectActivity
 */
import FieldTypes from 'optly/modules/rest_api/field_types';

export const entity = 'activity';

export const parent = {
  entity: 'projects',
  key: 'project_id',
};

/**
 * Optional definitions
 // fieldTypes are used for client side sorting / filtering
 */
export const fieldTypes = {
  id: FieldTypes.NUMBER,
  account_id: FieldTypes.NUMBER,
  category: FieldTypes.STRING,
  created: FieldTypes.DATE,
  email: FieldTypes.STRING,
  event: FieldTypes.STRING,
  experiment_id: FieldTypes.NUMBER,
  gae_admin_email: FieldTypes.STRING,
  project_id: FieldTypes.NUMBER,
};

export function serialize(data) {
  if (data.experiment_id) {
    data.experiment_id = data.experiment_id.toString();
  }
  if (data.project_id) {
    data.project_id = data.project_id.toString();
  }
  return data;
}

export function deserialize(data) {
  if (data.experiment_id) {
    data.experiment_id = Number(data.experiment_id);
  }
  if (data.project_id) {
    data.project_id = Number(data.project_id);
  }
  return data;
}

export default {
  entity,
  parent,
  fieldTypes,
  serialize,
  deserialize,
};

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import { toImmutable } from 'optly/immutable';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import { enums as EventModuleEnums } from 'optly/modules/entity/event';
import FilterableTable from 'optly/modules/filterable_table';
import {
  getters as LayerGetters,
  fns as LayerFns,
} from 'optly/modules/entity/layer';
import PluginModuleGetters from 'optly/modules/entity/plugin/getters';
import PluginModuleEnums from 'optly/modules/entity/plugin/enums';
import PredefinedCategoriesGetters from 'bundles/p13n/modules/predefined_categories/getters';
import PredefinedCategoriesFns from 'bundles/p13n/modules/predefined_categories/fns';
import SortableTableGetters from 'optly/modules/sortable_table/getters';
import TagGetters from 'optly/modules/entity/tag/getters';
import MetricGetters from 'optly/modules/entity/metric_template/getters';

const EVENTS_TABLE_ID = FilterableTable.enums.tableIds.P13N_DATA_LAYER_EVENTS;

/*
 * Denormalized events with view information
 *
 */
export const customEvents = [
  CurrentProjectGetters.events,
  PredefinedCategoriesGetters.getUngroupedList('user_events'),
  (events, userEventsCategories) =>
    events
      .filter(
        event => event.get('event_type') === EventModuleEnums.eventTypes.CUSTOM,
      )
      .map(event =>
        event.set(
          'readable_category',
          PredefinedCategoriesFns.resolveReadableName(
            userEventsCategories.toJS(),
            event.get('category'),
          ),
        ),
      ),
];

export const views = [
  CurrentProjectGetters.views,
  CurrentProjectGetters.events,
  TagGetters.entityCache,
  LayerGetters.entityCache,
  (views, events, tags, layers) =>
    views
      .map(view => {
        const viewId = view.get('id');
        const eventCount =
          events.count(
            event =>
              event.get('view_id') === viewId &&
              !event.get('archived') &&
              !event.get('variation_specific'),
          ) +
          views.count(
            internal =>
              internal.get('id') === viewId && !internal.get('single_use'),
          );
        const tagCount = tags.count(
          tag => tag.get('view_id') === viewId && !tag.get('archived'),
        );
        view = view.set('eventCount', eventCount);
        view = view.set('tagCount', tagCount);

        const experimentUsage = layers.filter(
          layer =>
            layer.get('view_ids').includes(viewId) &&
            !layer.get('archived') &&
            !LayerFns.hasLayerConcluded(layer),
        );
        view = view.set('experiments', experimentUsage.size);

        return view;
      })
      .filter(view => !view.get('single_use'))
      .toList(),
];

export const activeViews = [
  views,
  views =>
    views
      .filter(view => !view.get('archived'))
      .sortBy(item => item.get('name')),
];

export const metrics = [MetricGetters.entityCache, metrics => metrics.toList()];

/**
 * Return true if there are any page events for the given table id after applying the string filter
 *
 * @param {string} tableId
 * @return {boolean}
 */
export function hasFilteredPageEvents(tableId) {
  return [
    FilterableTable.getters.stringFilter(tableId),
    CurrentProjectGetters.views,
    CurrentProjectGetters.events,
    function(stringFilter, views, events) {
      const filteredViews = FilterableTable.fns.filterFieldsByString(
        views,
        stringFilter,
        ['name'],
      );
      const filteredEvents = FilterableTable.fns
        .filterFieldsByString(events, stringFilter, ['name'])
        .filter(filteredEvent => filteredEvent.get('event_type') !== 'custom');

      // for pageview events we add some additional display text to it, so we want to check if the
      // filter term includes those words
      let hasPageViewMatch = false;
      if (stringFilter) {
        const pageViewName = tr('Visited page');
        hasPageViewMatch =
          pageViewName.toLowerCase().indexOf(stringFilter.toLowerCase()) !== -1;
      }

      return (
        hasPageViewMatch || filteredViews.size > 0 || filteredEvents.size > 0
      );
    },
  ];
}

export function filteredAndSortedCustomEvents(tableId) {
  return [
    customEvents,
    FilterableTable.getters.fieldFilter(tableId, 'status'),
    FilterableTable.getters.stringFilter(tableId),
    SortableTableGetters.sortFn(tableId),
    (events, statusFilter, filter, sortFn) => {
      events = FilterableTable.fns.filterByArchivedStatus(events, statusFilter);
      events = FilterableTable.fns.filterFieldsByString(events, filter, [
        'name',
      ]);
      if (sortFn) {
        events = events.sort(sortFn);
      }
      return events;
    },
  ];
}

/**
 * Parsed and formated custom, click and pageview events for the events dashboard
 * This getter grabs the pages and the page events associated with the user.
 * It then filters, sorts, and formats them in a digestible way for the events dashboard.
 */
export const pageEventsForDashboard = [
  CurrentProjectGetters.views,
  CurrentProjectGetters.events,
  FilterableTable.getters.fieldFilter(EVENTS_TABLE_ID, 'status'),
  FilterableTable.getters.stringFilter(EVENTS_TABLE_ID),
  SortableTableGetters.sortFn(EVENTS_TABLE_ID),
  PredefinedCategoriesGetters.getUngroupedList('user_events'),
  (views, events, statusFilter, stringFilter, sortFn, userEventsCategories) => {
    // Hide pages with single_use=True, these are pages used only for url targeting on
    // a specific campaign
    views = views.filter(view => !view.get('single_use'));

    const filteredViews = FilterableTable.fns.filterByArchivedStatus(
      views,
      statusFilter,
    );
    const filteredEvents = FilterableTable.fns.filterByArchivedStatus(
      events,
      statusFilter,
    );

    // Places all page events in a map with the associated view as the key
    const viewEventsMap = toImmutable({}).withMutations(m => {
      // each filtered view needs to have a pageview event
      filteredViews.forEach(view => {
        const defaultPageViewEvent = toImmutable({
          name: `Visited ${view.get('name')} page`,
          readable_category: view.get('category'),
          event_type: EventModuleEnums.eventTypes.PAGE_VIEW,
          api_name: '',
          id: null,
        });
        m.set(view.get('id'), toImmutable([defaultPageViewEvent]));
      });

      filteredEvents.forEach(event => {
        const viewId = event.get('view_id');
        if (viewId && !event.get('variation_specific')) {
          // Our current event dashboard filters out variation specific events
          if (!m.has(viewId)) {
            m.set(viewId, toImmutable([]));
          }
          m.update(viewId, lst =>
            lst.push(
              event.set(
                'readable_category',
                PredefinedCategoriesFns.resolveReadableName(
                  userEventsCategories.toJS(),
                  event.get('category'),
                ),
              ),
            ),
          );
        }
      });
    });

    // This filter grabs all views that either have click or pageview events that match the filtered status
    views = views.filter(view => viewEventsMap.has(view.get('id')));

    return toImmutable([]).withMutations(lst => {
      views.forEach(view => {
        let filteredEventsForView = viewEventsMap.get(view.get('id'));
        filteredEventsForView = FilterableTable.fns.filterFieldsByString(
          filteredEventsForView,
          stringFilter,
          ['name'],
        );
        if (filteredEventsForView.size) {
          lst = lst.push(
            toImmutable({
              view,
              events: filteredEventsForView,
            }),
          );
        }
      });
    });
  },
];

export const datasources = CurrentProjectGetters.dcpDatasources;

export function tagsForView(viewId) {
  return [
    TagGetters.entityCache,
    tags => tags.filter(tag => tag.get('view_id') === viewId),
  ];
}

export const pluginEventTypeToName = [
  PluginModuleGetters.entityCache,
  CurrentProjectGetters.id,
  (plugins, projectId) =>
    toImmutable({}).withMutations(map => {
      plugins
        .toList()
        .filter(
          plugin =>
            plugin.get('plugin_type') === PluginModuleEnums.plugin_type.EVENT &&
            plugin.get('project_id') === projectId,
        )
        .forEach(plugin => {
          map.set(plugin.get('plugin_id'), plugin.get('name'));
        });
    }),
];

export default {
  activeViews,
  customEvents,
  datasources,
  filteredAndSortedCustomEvents,
  hasFilteredPageEvents,
  pageEventsForDashboard,
  pluginEventTypeToName,
  tagsForView,
  views,
  metrics,
};

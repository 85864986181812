import React from 'react';
import { Link } from 'optimizely-oui';

/**
 * @name reactStringReplace
 * @description Replace a substring with a react component
 * @param {string} str - input string
 * @param {regex} pattern - regex pattern, this is the pattern you want to replace
 * @param {function} fn - (match: string) => React.component - function that returns a component
 * @returns {array}
 */
export const reactStringReplace = (str, pattern, fn) => {
  const matches = str.matchAll(pattern);
  const results = [str];
  let piv = 0;
  Array.from(matches, match => {
    results.pop();
    const start = match.index;
    const end = match.index + match[0].length;
    const left = str.slice(piv, start);
    const right = str.slice(end, str.length);
    piv = end;

    results.push(left);
    results.push(fn(match[0]));
    results.push(right);
  });

  return results;
};

/**
 * @name injectLinks
 * @description replaces a "link pattern" with a react component Link
 * @example
 *    injectLinks("hello {{x}}world{{/x}}", { x: '/example' })
 *      // => ["hello ", <Link href="/example">world</Link>]
 * @param {string} str - input string
 * @param {object} linksMap - map of link "names" to actual link urls
 * @returns {array}
 */
export const injectLinks = (str, linksMap) =>
  reactStringReplace(str, /{{\w+}}[^({{|}})]*{{\/\w+}}/g, match => {
    // extract link key
    const linkKey = ((match.match(/{{\w+}}/) || [])[0] || '').replace(
      /{{|}}/g,
      '',
    );
    // extract inner text
    const innerText = (match.split(/{{\w+}}|{{\/\w+}}/) || []).join('');
    return (
      <Link href={linksMap[linkKey] || '#'} newWindow={true}>
        {innerText}
      </Link>
    );
  });

const targetedRollouts = {
  /**
   * @name createJoyrideConfig
   * @description generates a joyride config
   * @param {string} stepVariableGroups - a string list of variable page keys (ex. "tour_guide1,tour_guide2,tour_guide3")
   * @param {object} tourGuideVariables - all tour-guide config variables
   * @returns {object} react-joyride config
   */
  createJoyrideConfig: (stepVariableGroups, tourGuideVariables) => ({
    steps: stepVariableGroups.split(',').map(variableKey => ({
      title: tourGuideVariables[`${variableKey}_title`],
      content: (
        <p>
          {injectLinks(
            tourGuideVariables[`${variableKey}_content`],
            tourGuideVariables,
          )}
        </p>
      ),
      placement:
        tourGuideVariables[`${variableKey}_target`] === 'body'
          ? 'center'
          : 'bottom',
      target: tourGuideVariables[`${variableKey}_target`],
      disableBeacon: true,
      spotlightPadding: 0,
    })),
  }),

  /**
   * @name getFeaturesListPageTourGuide
   * @description Creates a joyride-config for the feature-lists page tour
   * @param {object} tourGuideVariables - all tour-guide config variables
   * @returns {object} react-joyride config
   */
  getFeaturesListPageTourGuide: tourGuideVariables =>
    targetedRollouts.createJoyrideConfig(
      tourGuideVariables.list_page_keys,
      tourGuideVariables,
    ),

  /**
   * @name getRulesPageTourGuide
   * @description Creates a joyride-config for the rules page tour
   * @param {object} tourGuideVariables - all tour-guide config variables
   * @returns {object} react-joyride config
   */
  getRulesPageTourGuide: tourGuideVariables =>
    targetedRollouts.createJoyrideConfig(
      tourGuideVariables.rules_page_keys,
      tourGuideVariables,
    ),
};

export default {
  reactStringReplace,
  injectLinks,
  targetedRollouts,
};

/**
 * Directive for simple disclosures
 *
 * @author Tom Genoni
 */
const $ = require('jquery');

const ACTIVE_DISCLOSE_CLASS = 'is-active';

function discloseActivate(el, target) {
  const $target = $(target);

  const contentPane = $target.parent('.lego-disclose');

  if (contentPane.hasClass(ACTIVE_DISCLOSE_CLASS)) {
    contentPane.removeClass(ACTIVE_DISCLOSE_CLASS);
  } else {
    contentPane.addClass(ACTIVE_DISCLOSE_CLASS);
  }
}

module.exports = {
  data: {
    show: true,
  },
  bind() {
    const $el = $(this.el);

    $el.find('> a').on('click', e => {
      e.preventDefault();
      discloseActivate(this.el, e.target);
    });
  },
};

export type Audience = {
  archived: boolean;
  created: string;
  description: string;
  // eslint-disable-next-line camelcase
  experiment_count: number;
  id: number;
  // eslint-disable-next-line camelcase
  last_modified: string;
  name: string;
  // eslint-disable-next-line camelcase
  project_id: number;
  type: 'audience';
};

export type SearchPickerRenderProps = {
  availableEntities: Audience[];
  isLoading: boolean;
  renderInput: () => void;
  searchQuery: string;
  currentFauxFocusIndex: number;
  resultsText: {
    summary: string;
  };
};

export enum ConditionGroup {
  OR = 'or',
  AND = 'and',
}

export type AudienceCondition =
  | ConditionGroup.OR
  | ConditionGroup.AND
  | { audienceId: number };

// null, is meant for the state when there are no audience conditions.
export type AudienceConditionCombination = AudienceCondition[] | null;

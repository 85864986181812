/**
 * Condition service
 *
 * Functionality revolving around condition data
 *
 * Condition data structure:
 *   {
 *    name: <string>,
 *    value: <string|number>,
 *    match_type: <value>
 *   }
 *
 * ConditionGroup data structure
 *   [
 *    <'or'|'and'>,
 *    <Condition|ConditionGroup>, // recursive
 *    <Condition|ConditionGroup>,
 *    <Condition|ConditionGroup>
 *   ]
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const isPlainObject = require('is-plain-object');
const _ = require('lodash');

const ConditionGroup = require('optly/models/condition_group');
const targetingConditions = require('optly/services/targeting_conditions');

/**
 * Validates whether a condition is too complex to be
 * displayed in the UI
 *
 * Must conform to the structure
 * [
 *  'and',
 *  [
 *    'or',
 *    [
 *      'not',
 *      [
 *        'or',
 *        {
 *          match_type: ...
 *          value: ...
 *          name: ...
 *        }
 *      ]
 *    ]
 *  ]
 * ]
 *
 * @param {ConditionGroup}
 * @return {Boolean}
 */
function isUIDisplayableCondition(conditionGroup) {
  if (!conditionGroup.IS_GROUP) {
    // root level conditionGroup must be a ConditionGroup
    return false;
  }
  if (conditionGroup.negate) {
    // cannot negate root level condition
    return false;
  }
  if (conditionGroup.type === 'or') {
    // root ConditionGroup must be 'and' type
    return false;
  }
  if (conditionGroup.conditions.length === 0) {
    // conditionGroup is uninitialized
    return true;
  }
  if (conditionGroupDepth(conditionGroup) !== 3) {
    return false;
  }

  // all children of the root condition group must be condition groups of type 'or'
  // and not negated
  const validSecondLevelGroups = conditionGroup.conditions.every(
    group => group.IS_GROUP && !group.negate && group.type === 'or',
  );
  if (!validSecondLevelGroups) {
    return false;
  }

  // all grandchildren nodes must be condition groups of the type or
  let validThirdLevelGroups = true;
  conditionGroup.conditions.forEach(child => {
    child.conditions.forEach(grandchild => {
      if (
        // must be a group
        !grandchild.IS_GROUP ||
        // must be logical type 'or'
        grandchild.type !== 'or' ||
        // all grandchild.conditions must be the same type
        !getConditionType(grandchild)
      ) {
        validThirdLevelGroups = false;
      }
    });
  });
  if (!validThirdLevelGroups) {
    return false;
  }

  return true;
}

/**
 * Attempts to transform a ConditionGroup that is not UI-displayable
 * into a logical equivalent group that IS UI-displayable
 *
 * Note: Does not modify the inputted condition group
 *
 * Returns null if the condition group cannot be transformed into a UI-displayable group
 *
 * @param {ConditionGroup} conditionGroup
 * @return {ConditionGroup|null}
 */
function transformToUIValidConditionGroup(serializedConditions) {
  let conditionGroup = new ConditionGroup();
  try {
    conditionGroup.load(serializedConditions);
  } catch (e) {
    // invalid serializedConditions cannot even load into ConditionGroup object
    return null;
  }

  if (isUIDisplayableCondition(conditionGroup)) {
    return conditionGroup;
  }

  // load the serialized data into a new conditionGroup
  const serializedData = conditionGroup.serialize();
  conditionGroup = new ConditionGroup();
  conditionGroup.load(serializedData);

  // if ['or', {cond1}, {cond2}, {cond3}] and all are the same type
  // This is a special case where the output should be ['and', ['or', ['or', {cond1}, {cond2}, {cond3}]]]
  conditionGroup = handleSingleProperLeafConditionGroup(conditionGroup);

  // attempt to recognize this pattern and immediately transform into ['and', ['or', ['or', {cond1}, {cond2}, {cond3}]]]
  if (isUIDisplayableCondition(conditionGroup)) {
    return conditionGroup;
  }

  // if ['not', ['or', {cond1}, {cond2}, {cond3}]]
  // This is a special case where the output should be
  // ['and', ['or', ['not', ['or', {cond1}]], ['not', ['or', {cond2}]], ['not', ['or', {cond3}]]]]
  conditionGroup = handleNegateOrConditionGroup(conditionGroup);

  // attempt to traverse improper condition groups and convert condition literals
  // Traverse all leaf condition groups (any group with a condition literal as a child)
  // wrap the condition literals in an 'or' condition group => ['or', cond]
  traverseLeafGroups(conditionGroup, (group, depth) => {
    if (depth < 3) {
      // wrap any condition literals of group in ['or', cond]
      wrapConditionsInGroup(group, 'or');
    }
  });
  // By this point there should be no more improper leaf ConditionGroups
  if (isUIDisplayableCondition(conditionGroup)) {
    return conditionGroup;
  }

  // top level condition group must be 'and' for UI
  if (conditionGroup.type === 'or') {
    conditionGroup = wrapGroup(conditionGroup, 'and');
  }

  if (conditionGroupDepth(conditionGroup) > 3) {
    // cannot transform if depth > 3
    return null;
  }

  // try to normalize the depth to 3 by padding every singular ['or', cond] with ['or', ['or', cond]]
  let succeeded = true;
  try {
    traverseProperLeafGroups(conditionGroup, (group, depth) => {
      if (depth === 2) {
        const ind = conditionGroup.conditions.indexOf(group);
        if (ind === -1) {
          throw new Error(
            `Could find index of ${group.serialize()} in ${conditionGroup.serialize()}`,
          );
        }
        const newGroup = new ConditionGroup('or');
        newGroup.conditions = [group];

        conditionGroup.conditions.splice(ind, 1, newGroup);
      }
    });
  } catch (e) {
    succeeded = false;
  }

  if (!succeeded) {
    // there was an error trying to transform into a 3-level deep condition group
    return null;
  }

  if (isUIDisplayableCondition(conditionGroup)) {
    return conditionGroup;
  }

  return null;
}

/**
 * Takes a condition object literal
 * {
 *   type: 'visitor',
 *   name: '',
 *   value: ''
 * }
 * and removes name and value properties, since name is not a valid field
 * and value isn't a valid value
 *
 * @param {ConditionGroup} conditionGroup
 */
function cleanInvalidConditionFields(conditionGroup) {
  traverseProperLeafGroups(conditionGroup, group => {
    group.conditions.forEach(condition => {
      _.each(condition, (value, field) => {
        // ignore the type property on condition literals
        if (field === 'type') {
          return;
        }
        if (
          !targetingConditions.isValidFieldValue(condition.type, field, value)
        ) {
          delete condition[field];
        }
      });
    });
  });
}

/**
 * Derives the conditionType from conditionGroup.conditions
 * All conditions must be condition literals and of the same type or
 * null is returned
 *
 * @param {ConditionGroup} group
 * @return {String|null}
 */
function getConditionType(group) {
  if (group.conditions.length === 0) {
    return null;
  }
  const hasAllConditions = group.conditions.every(
    condition => !condition.IS_GROUP,
  );
  if (!hasAllConditions) {
    return null;
  }
  // group.conditions[0] must be a condition literal
  const type = group.conditions[0].type;

  const hasSameType = group.conditions.every(
    condition => condition.type === type,
  );

  if (!hasSameType) {
    return null;
  }

  return type;
}

/**
 * if ['or', {cond1}, {cond2}, {cond3}] and all are the same type
 * This is a special case where the output should be ['and', ['or', ['or', {cond1}, {cond2}, {cond3}]]]
 *
 * @private
 * @param {ConditionGroup} group
 * @return {ConditionGroup}
 */
function handleSingleProperLeafConditionGroup(group) {
  if (group.type !== 'or') {
    // only handle the or case
    return group;
  }

  if (!isProperLeafConditionGroup(group)) {
    // group must only have condition literals as children
    return group;
  }

  const type = group.conditions[0].type;
  const allSameType = group.conditions.every(cond => cond.type === type);

  if (!allSameType) {
    // all conditions must be the same type
    return group;
  }

  // at this point we can do a shortcut transform to ['and', ['or', ['or', conds...]]]
  const rootGroup = new ConditionGroup('and');
  rootGroup.conditions = [wrapGroup(group, 'or')];
  return rootGroup;
}

/**
 * if ['not', ['or', {cond1}, {cond2}, {cond3}]] and all are the same type
 * This is a special case where the output should be
 * ['and', ['or', ['not', ['or', {cond1}]], ['not', ['or', {cond2}]], ['not', ['or', {cond3}]]]]
 *
 * Ex:
 * [
 *   'not',
 *   [
 *     'or',
 *     { cond1 },
 *     { cond2 }
 *   ]
 * ]
 * INTO
 * [
 *   'and',
 *   [
 *     'or',
 *     [
 *       'not',
 *       [
 *         'or',
 *         { cond1 }
 *       ]
 *     ],
 *     'or',
 *     [
 *       'not',
 *       [
 *         'or',
 *         { cond2 }
 *       ]
 *     ]
 *   ]
 * ]
 * @private
 * @param {ConditionGroup} group
 * @return {ConditionGroup}
 */
function handleNegateOrConditionGroup(group) {
  if (group.type !== 'or' || !group.negate) {
    // only handle the negate or case
    return group;
  }

  const rootGroup = new ConditionGroup('and');

  group.conditions.forEach(condition => {
    const newGroup = new ConditionGroup('or');
    newGroup.negate = true;
    newGroup.conditions = [condition];
    rootGroup.conditions.push(wrapGroup(newGroup, 'or'));
  });

  return rootGroup;
}

/**
 * Transforms:
 * [
 *  'and',
 *  {
 *    type: 'type1',
 *    value: 'value1'
 *  },
 *  {
 *    type: 'type2',
 *    value: 'value2'
 *  },
 *  {
 *    type: 'type1',
 *    value: 'value3'
 *  },
 * ]
 * into
 * [
 *  'and',
 *  [
 *    'or',
 *    {
 *      type: 'type1',
 *      value: 'value1'
 *    }
 *  ],
 *  [
 *    'or',
 *    {
 *      type: 'type2',
 *      value: 'value2'
 *    }
 *  ]
 *  [
 *    'or',
 *    {
 *      type: 'type1',
 *      value: 'value3'
 *    }
 *  ]
 * ]
 *
 * Wraps every condition literal in a ConditionGroup of type 'or'
 * Does not combine like types
 * @private
 * @param {ConditionGroup} group
 * @param {String} type
 */
function wrapConditionsInGroup(group, type) {
  // if the groups conditions are mutated
  let mutated = false;
  // create the childConditionMap
  group.conditions.forEach((cond, ind) => {
    // replace a raw condition literal with ['or', cond]
    if (!cond.IS_GROUP) {
      mutated = true;
      const groupToInsert = new ConditionGroup(type);
      groupToInsert.conditions = [cond];
      groupToInsert.negate = group.negate;
      group.conditions.splice(ind, 1, groupToInsert);
    }
  });

  if (mutated) {
    // Since the negation is moved to the leaf ConditionGroup
    group.negate = false;
  }
}

/**
 * Wraps a single condition group in another condition group of a certain type
 * ['or', cond1, cond2] => ['and', ['or', cond1, cond2]]
 *
 * @private
 * @param {ConditionGroup} group
 * @param {String} type
 * @return {ConditionGroup}
 */
function wrapGroup(group, type) {
  const wrapper = new ConditionGroup(type);
  wrapper.conditions = [group];
  return wrapper;
}

/**
 * Does a traversal of all proper leaf conditionGroups and executes fn(group, depth)
 *
 * @private
 * @param {ConditionGroup} conditionGroup
 * @param {Function} fn
 */
function traverseProperLeafGroups(conditionGroup, fn, depth) {
  if (!depth) {
    depth = 1;
  } else {
    depth += 1;
  }
  if (isProperLeafConditionGroup(conditionGroup)) {
    fn(conditionGroup, depth);
  } else {
    conditionGroup.conditions.forEach(condition => {
      if (condition.IS_GROUP) {
        traverseProperLeafGroups(condition, fn, depth);
      }
    });
  }
}

/**
 * Does a traversal of all leaf conditionGroups and executes fn(group, depth)
 *
 * @private
 * @param {ConditionGroup} conditionGroup
 * @param {Function} fn
 */
function traverseLeafGroups(conditionGroup, fn, depth) {
  if (!depth) {
    depth = 1;
  } else {
    depth += 1;
  }
  if (
    isImproperLeafConditionGroup(conditionGroup) ||
    isProperLeafConditionGroup(conditionGroup)
  ) {
    fn(conditionGroup, depth);
  } else {
    conditionGroup.conditions.forEach(condition => {
      if (condition.IS_GROUP) {
        traverseLeafGroups(condition, fn, depth);
      }
    });
  }
}

/**
 * Checks if a conditionGroup.conditions are all condition literals
 *
 * @private
 * @param {ConditionGroup} conditionGroup
 * @return {Boolean}
 */
function isProperLeafConditionGroup(conditionGroup) {
  return (
    conditionGroup.conditions.length > 0 &&
    conditionGroup.conditions.every(condition => !condition.IS_GROUP)
  );
}

/**
 * An improper leaf group is a ConditionGroup that has at least one condition literal
 * as a child condition
 *
 * @private
 * @param {ConditionGroup} conditionGroup
 * @return {Boolean}
 */
function isImproperLeafConditionGroup(conditionGroup) {
  if (isProperLeafConditionGroup(conditionGroup)) {
    return false;
  }
  const someConditions = conditionGroup.conditions.some(
    condition => !condition.IS_GROUP,
  );
  const someConditionGroups = conditionGroup.conditions.some(
    condition => condition.IS_GROUP,
  );
  return someConditions && someConditionGroups;
}

/**
 * Gets the depth of the condition group
 * The amount of nesting between the root conditionGroup and the lowest
 * condition literal
 *
 * Assumes that the depth consistent amongst all children nodes
 *
 * @private
 * @param {ConditionGroup} conditionGroup
 * @param {Integer=} depth
 * @return {Integer}
 */
function conditionGroupDepth(conditionGroup, depth) {
  if (!depth) {
    depth = 1;
  } else {
    depth += 1;
  }
  if (
    conditionGroup.conditions.length === 0 ||
    isPlainObject(conditionGroup.conditions[0])
  ) {
    return depth;
  }
  return conditionGroupDepth(conditionGroup.conditions[0], depth);
}

/**
 * Returns whether the conditions array has any condition literals
 *
 * @param {Array} conditions
 * @return {Boolean}
 */
function isEmpty(conditions) {
  let ret = true;

  const conditionGroup = new ConditionGroup();
  conditionGroup.load(conditions);

  traverseProperLeafGroups(conditionGroup, () => {
    ret = false;
  });

  return ret;
}

module.exports = {
  getConditionType,
  cleanInvalidConditionFields,
  transformToUIValidConditionGroup,
  traverseProperLeafGroups,
  isUIDisplayableCondition,
  isEmpty,
};

const flux = require('core/flux');

const UrlHelperV2 = require('optly/services/url_helper').default;

const CurrentProject = require('optly/modules/current_project');
const Project = require('optly/modules/entity/project');
const SectionModuleEnums = require('bundles/p13n/sections/features_old/section_module/enums');

const template = require('./template.html');

module.exports = {
  componentId: 'features-topbar',

  replace: true,

  template,

  data: {
    tabs: SectionModuleEnums.NAV_TABS,
  },

  computed: {
    featuresUrl() {
      if (this.currentProject) {
        return UrlHelperV2.featuresOldHome(this.currentProject.id);
      }
    },

    projectName() {
      this.currentProject; // eslint-disable-line
      if (this.currentProject) {
        return Project.fns.formatCustomProjectName(
          this.currentProject,
          this.primarySDKLanguage,
        );
      }
    },
  },

  created() {
    flux.bindVueValues(this, {
      currentProject: CurrentProject.getters.project,
      primarySDKLanguage: CurrentProject.getters.primarySDKLanguage,
    });
  },
};

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);
export const { byId, entityCache } = baseEntityGetters;

export function resultsAPITokenById(id) {
  return [
    byId(id),
    layerExperiment => {
      if (layerExperiment) {
        return layerExperiment.get('layer_experiment_results_api_token');
      }
    },
  ];
}

export default {
  ...baseEntityGetters,
  resultsAPITokenById,
};

import { each, isArray } from 'lodash';
/**
 * Takes the data structure of a regionMap and expands all explicit mappings between componentIds
 *
 * Example:
 * INPUT: regionMap = {
 *   layerCreation: {
 *     1: 'p13n-layers-home',
 *     2: ['p13n-results', 'p13n-editor'],
 *   },
 *   pages: {
 *     1: 'p13n-data-home',
 *     2: ['p13n-create-page', 'p13n-events-home', 'p13n-custom-events'],
 *     3: 'p13n-click-events',
 *   },
 * }
 *
 * OUTPUT: {
 *   'p13n-layers-home': {
 *     'p13n-results': 1,
 *     'p13n-editor': 1,
 *   },
 *   'p13n-results': {
 *     'p13n-editor': 0,
 *     'p13n-layers-home': -1,
 *   },
 *   'p13n-editor': {
 *     'p13n-results': 0,
 *     'p13n-layers-home': -1,
 *   },
 *   'p13n-data-home': {
 *     'p13n-create-page': 1,
 *     'p13n-events-home': 1,
 *     'p13n-custom-events': 1,
 *     'p13n-click-events': 2,
 *   },
 *   'p13n-create-page': {
 *     'p13n-data-home': -1,
 *     'p13n-events-home': 0,
 *     'p13n-custom-events': 0,
 *     'p13n-click-events': 1,
 *   },
 *   'p13n-events-home': {
 *     'p13n-data-home': -1,
 *     'p13n-create-page': 0,
 *     'p13n-custom-events': 0,
 *     'p13n-click-events': 1,
 *   },
 *   'p13n-custom-events': {
 *     'p13n-data-home': -1,
 *     'p13n-create-page': 0,
 *     'p13n-events-home': 0,
 *     'p13n-click-events': 1,
 *   },
 *   'p13n-custom-events': {
 *     'p13n-data-home': 0,
 *     'p13n-create-page': -1,
 *     'p13n-events-home': -1,
 *     'p13n-custom-events': -1,
 *   },
 * },
 *
 * @param {Object} regionMap
 * @return {Object}
 */
export const graphifyRegionMap = function(regionMap) {
  const output = {};

  // determine if it is a flat map without flow namespaces
  const allNumericKeys = Object.keys(regionMap).every(isNumericString);
  const allStringKeys = Object.keys(regionMap).every(
    key => !isNumericString(key),
  );

  if (!allNumericKeys && !allStringKeys) {
    throw new Error(
      'Region level must be a numeric string or namespaced group',
    );
  }

  if (allNumericKeys) {
    createExplicitComponentMapping(regionMap, output);
  } else {
    each(regionMap, componentMap => {
      createExplicitComponentMapping(componentMap, output);
    });
  }

  return output;
};

/**
 * INPUT: {
 *   1: 'p13n-layers-home',
 *   2: ['p13n-results', 'p13n-editor'],
 * },
 * OUTPUT: {
 *   'p13n-layers-home': {
 *     'p13n-results': 1,
 *     'p13n-editor': 1,
 *   },
 *   'p13n-results': {
 *     'p13n-editor': 0,
 *     'p13n-layers-home': -1,
 *   },
 *   'p13n-editor': {
 *     'p13n-results': 0,
 *     'p13n-layers-home': -1,
 *   },
 * }
 *
 * @param {Object} componentMap
 * @param {Object} output
 */
function createExplicitComponentMapping(componentMap, output) {
  const flattenedComponentMap = flattenComponentLevels(componentMap);

  each(flattenedComponentMap, (level, componentId) => {
    if (!output[componentId]) {
      output[componentId] = {};
    }

    each(flattenedComponentMap, (otherLevel, otherComponentId) => {
      if (componentId === otherComponentId) {
        return;
      }
      const existingDiff = output[componentId][otherComponentId];
      const diff = otherLevel - level;

      if (existingDiff && existingDiff !== diff) {
        throw new Error('Component region relationship cannot be redefined');
      }

      output[componentId][otherComponentId] = diff;
    });
  });
}

/**
 * transform:
 * layerCreation = {
 *   1: 'p13n-layers-home',
 *   2: ['p13n-results', 'p13n-editor'],
 * }
 * to
 * {
 *   'p13n-layers-home': 1,
 *   'p13n-results': 2,
 *   'p13n-editor': 2,
 * }
 * @param {Object} componentLevels
 * @return {Object}
 */
function flattenComponentLevels(componentLevels) {
  const output = {};

  each(componentLevels, (componentIds, level) => {
    if (!isNumericString(level)) {
      throw new Error('Region level must be a numeric string');
    }

    if (!isArray(componentIds)) {
      // coerce to array for case of `1: 'p13n-data-home`
      componentIds = [componentIds];
    }

    componentIds.forEach(id => {
      if (output[id]) {
        throw new Error('Component regoin relationship cannot be redefined');
      }
      output[id] = Number(level);
    });
  });
  return output;
}

/**
 * @param {String} str
 * @return {Boolean}
 */
function isNumericString(str) {
  return !isNaN(parseFloat(str)) && isFinite(str);
}

/**
 * @param {Object} component
 */
export const isVueComponent = function(component) {
  return !!(component.$options || component.template);
};

const exported = {
  graphifyRegionMap,
  isVueComponent,
};

export default exported;

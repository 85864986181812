/**
 * Directive that extends the `v-loading` directive and allows
 * the specification of a single property on the vm.
 *
 * Usage:
 *
 * <div v-loading-entity="experiment.id" entity="experiments">
 *
 * Where `experiment.id` is a ViewModel property and thus dynamic
 *
 * This integrates with the API layer of flux which will mark an entity
 * as "loading" whenever it is saved by the key `<entityName>.<entityId>`
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
import _ from 'lodash';

import loadingDirective from './loading';

export default _.extend({}, loadingDirective, {
  isLiteral: false,

  setupObservation() {
    // extend base class and no-op
  },

  update(value) {
    this.toggleSpinner(value);
  },
});

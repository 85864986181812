/**
 * Constants for Collaborator
 */
export const InvitationStatus = {
  ACCEPTED: 'Accepted',
  PENDING: 'Pending',
  EXPIRED: 'Expired',
};

export default {
  InvitationStatus,
};

import _ from 'lodash';

import { toImmutable } from 'optly/immutable';

import Layer from 'optly/modules/entity/layer';
import LayerExperiment from 'optly/modules/entity/layer_experiment';

import constants from './constants';
import enums from './enums';

/**
 * Creates an empty group entity object with the supplied data
 */
export const createExperimentationGroupEntity = function(data) {
  const DEFAULTS = {
    archived: false,
    description: '',
    entities: [],
    id: null,
    name: '',
    policy: enums.POLICY_TYPES.RANDOM,
    weight_distributions: [],
  };

  return _.extend({}, DEFAULTS, data);
};

/**
 * Calculates the available traffic in the given group, optionally ignoring a certain ID
 *
 * @param  {Immutable.Map} group
 * @param  {Number} ignoreEntityId entity ID to ignore when summing available traffic
 * @return {Number}
 */
export const calculateAvailableTrafficForGroup = (
  group,
  ignoreEntityId = null,
) => {
  let availableTraffic = constants.MAX_TRAFFIC_ALLOCATION;
  if (group && group.get('id')) {
    const usedTraffic = group.get('entities').reduce((traffic, entity) => {
      if (entity.get('id') === ignoreEntityId) {
        return traffic;
      }
      return traffic + entity.get('weight');
    }, 0);
    availableTraffic = constants.MAX_TRAFFIC_ALLOCATION - usedTraffic;
  }
  return availableTraffic;
};

/**
 * Calculates the remaining traffic
 * @param {Number} totalAvailableTraffic
 * @param {Number} percentageIncluded
 * @returns {Number}
 */
export const calculateRemainingTraffic = (
  totalAvailableTraffic,
  percentageIncluded,
) => totalAvailableTraffic - percentageIncluded;

/**
 * @param {String} enumeratedStatus
 * @param {Boolean} isCustomProject
 * @return {Immutable.Map} status details including actual status, readable status, and relevant oui class
 */
export const getStatusDetails = (enumeratedStatus, isCustomProject) => {
  const statusDetails = {
    actual: enumeratedStatus,
    readable: Layer.humanReadable.STATUS_BY_ACTUAL_STATUS[enumeratedStatus],
    class: '',
  };
  switch (enumeratedStatus) {
    case Layer.enums.entityStatus.NOT_STARTED:
      if (isCustomProject) {
        statusDetails.readable =
          LayerExperiment.humanReadable.STATUS_BY_ACTUAL_STATUS[
            enumeratedStatus
          ];
      }
      break;
    case Layer.enums.entityStatus.RUNNING:
      statusDetails.class = 'color--good-news';
      break;
    case Layer.enums.entityStatus.PAUSED:
      statusDetails.class = 'color--bad-news';
      break;
    case LayerExperiment.enums.ActualStatus.CAMPAIGN_PAUSED:
      statusDetails.readable =
        LayerExperiment.humanReadable.STATUS_BY_ACTUAL_STATUS[enumeratedStatus];
      statusDetails.class = 'color--bad-news';
      break;
    case Layer.enums.entityStatus.ARCHIVED:
      break;
    case Layer.enums.entityStatus.CONCLUDED:
      break;
    default:
      if (__DEV__) {
        console.error(`Unknown layer status: ${enumeratedStatus}`); // eslint-disable-line no-console
      }
      statusDetails.readable = '';
  }
  return toImmutable(statusDetails);
};

/**
 * Returns the percentage of traffic in a group that the entity will get
 * @param {Immutable.Map} group
 * @param {Number} entityId
 * @returns {Number}
 */
export const getPercentageIncludedForEntity = (group, entityId) => {
  let percentageIncluded = constants.MAX_TRAFFIC_ALLOCATION;
  // If it's not in a group, then just return 10000.
  if (group) {
    percentageIncluded = group
      .get('entities')
      .find(entity => entity.get('id') === entityId)
      .get('weight');
  }
  return percentageIncluded;
};

/**
 * Returns the group ID and percentage included for a Single Experiment Layer.
 * @param {Number} selectedGroupId
 * @param {Immutable.Map} groupsEntityCache
 * @param {Immutable.Map} currentLayer
 * @returns {Immutable.Map} currentLayer
 */
export const getCurrentSingleExperimentLayerWithGroupIdAndPercentageIncluded = (
  selectedGroupId,
  groupsEntityCache,
  currentLayer,
) => {
  const layerHoldback = currentLayer.get('holdback');
  // If the Layer is NOT part of a group, then the percentageIncluded is 10000 - holdback.
  let percentageIncluded = constants.MAX_TRAFFIC_ALLOCATION - layerHoldback;
  if (selectedGroupId && selectedGroupId !== constants.NONE_GROUP_ID) {
    // If the Layer IS part of a group, then calculate the percentageIncluded based on the groups.weight_distributions.
    percentageIncluded = getPercentageIncludedForEntity(
      groupsEntityCache.get(selectedGroupId),
      currentLayer.get('id'),
    );
  }
  return currentLayer
    .set('percentage_included', percentageIncluded)
    .set('group_id', selectedGroupId);
};

/**
 * Returns the selected group ID given an entity's ID
 * @param {Immutable.Map} entityToGroupMap
 * @param {Immutable.List} activeGroups
 * @param {Number} currentLayerId
 * @returns {Number} selectedGroupId
 */
export const getSelectedGroupIdForEntity = (
  entityToGroupMap,
  activeGroups,
  currentLayerId,
) => {
  const layerId = currentLayerId.toString();
  let selectedGroupId;
  selectedGroupId = entityToGroupMap.has(layerId)
    ? entityToGroupMap.get(layerId).get('id')
    : constants.NONE_GROUP_ID;
  if (!activeGroups.find(group => group.get('id') === selectedGroupId)) {
    // If the group is not active, then set the selectedGroupId to 0...
    // TODO (jessica.chong). This check can be removed once we prevent people from being able to archive groups
    // that have entities in them:
    selectedGroupId = constants.NONE_GROUP_ID;
  }
  return selectedGroupId;
};

export default {
  createExperimentationGroupEntity,
  calculateAvailableTrafficForGroup,
  calculateRemainingTraffic,
  getStatusDetails,
  getPercentageIncludedForEntity,
  getCurrentSingleExperimentLayerWithGroupIdAndPercentageIncluded,
  getSelectedGroupIdForEntity,
};

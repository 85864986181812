export const entity = 'support_info';

export const parent = {
  entity: 'accounts',
  key: 'account_id',
};

export default {
  entity,
  parent,
};

import _ from 'lodash';
import Vue from 'vue';
import { actions as FilterableTableActions } from 'optly/modules/filterable_table';
import { actions as P13NDashboardActions } from 'bundles/p13n/modules/dashboard';

const FilterableMixin = {
  /** ***********************************************************************************
   * This mixin adds in functionality for filtering a table component by
   * typing into the filter textbox
   *
   * Components using this mixin should define the following properties in their config:
   * {
   *   data: {
   *     tableId: {string}
   *   }
   * }
   *
   * @author Mike Ng (mike.ng@optimizely.com)
   *************************************************************************************
   */
  methods: {
    /**
     * Clear out the applied string filter
     */
    clearStringFilter() {
      this.setStringFilter('');
    },

    /**
     * Handle user input via the filter textbox
     */
    handleStringFilterChange: _.debounce(
      event => {
        const { value } = event.target;
        this.setStringFilter(value);
      },
      __TEST__ ? 0 : 100,
    ),

    /**
     * Sets the filter status
     * @param {string} status
     */
    setStatusFilter(status) {
      FilterableTableActions.setFilter(this.tableId, {
        status,
      });
      P13NDashboardActions.replaceUrl();
    },

    /**
     * Sets the filter string
     * @param {string} value
     */
    setStringFilter(value) {
      // TODO: (sam) If we're gonna add filters to url, implement some version of this. also loading indicators
      FilterableTableActions.setFilter(this.tableId, value);
      Vue.nextTick(() => {
        // wait until next tick to replace the url this allows
        // any filtered audiences to be deselected
        P13NDashboardActions.replaceUrl();
      });
    },
  },
};

export default FilterableMixin;

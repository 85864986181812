export const eventTypes = {
  CLICK: 'click',
  CUSTOM: 'custom',
  PAGE_VIEW: 'pageview',
  REVENUE: 'revenue',
  EVENT: 'event',
};

export const METRIC_LIMIT = 4;

export const METRICS_PICKER_EDIT_METRIC_FINISH =
  'metrics-picker-edit-metric-finish';

export const METRICS_PICKER_EDIT_METRIC_START =
  'metrics-picker-edit-metric-start';

export const VIEW_ACTIVATED_EVENT_TYPE = 'view_activated';

export default {
  eventTypes,
  METRIC_LIMIT,
  METRICS_PICKER_EDIT_METRIC_FINISH,
  METRICS_PICKER_EDIT_METRIC_START,
  VIEW_ACTIVATED_EVENT_TYPE,
};

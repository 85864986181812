import PropTypes from 'prop-types';

import React from 'react';

import connectGetters from 'core/ui/methods/connect_getters';
import Immutable from 'optly/immutable';

import tr from 'optly/translate';
import UrlHelper from 'optly/services/url_helper';

import CurrentProjectGetters from 'optly/modules/current_project/getters';
import NavConstants from 'optly/services/navigation';

import DashboardHeader from 'react_components/dashboard_header';

const FeaturesTopbar = ({
  activeTab,
  currentProject,
  currentProjectNameWithSdkIfApplicable,
}) => (
  <DashboardHeader
    projectName={currentProjectNameWithSdkIfApplicable}
    title="Features"
    tabs={[
      {
        title: tr('Overview'),
        url: UrlHelper.featuresHome(currentProject.get('id')),
        id: NavConstants.FeaturesTabs.FEATURES_DASHBOARD,
        testSection: 'features-dashboard-tab',
      },
    ]}
    activeTab={activeTab}
  />
);

FeaturesTopbar.propTypes = {
  activeTab: PropTypes.string.isRequired,
  currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
  currentProjectNameWithSdkIfApplicable: PropTypes.string.isRequired,
};

export default connectGetters(FeaturesTopbar, {
  currentProject: CurrentProjectGetters.project,
  currentProjectNameWithSdkIfApplicable:
    CurrentProjectGetters.nameWithSdkIfApplicable,
});

import { HelpPopover, Input } from 'optimizely-oui';
import PropTypes from 'prop-types';
import React from 'react';

class APIName extends React.Component {
  renderLabelWithHelp = () => {
    const { canUseViewActivationModeManual } = this.props;

    return (
      <div className="display--inline-block">
        API Name
        <HelpPopover
          horizontalAttachment="left"
          popoverTitle="API Name"
          verticalAttachment="top">
          {canUseViewActivationModeManual && (
            <p>
              Please provide a unique name so you can activate this page with an
              API call.
            </p>
          )}
          <p>
            We recommend creating the name using alphanumeric characters and
            underscores between words.
          </p>
        </HelpPopover>
      </div>
    );
  };

  render() {
    const { error, apiName, onChange } = this.props;

    return (
      <div>
        <Input
          value={apiName}
          label={this.renderLabelWithHelp()}
          isRequired={true}
          onChange={onChange}
          type="text"
          testSection="api-name"
          width="200"
          displayError={!!error}
        />
        {error && (
          <div
            className="lego-form-note lego-form-note--bad-news"
            data-test-section="error-api-name">
            {error}
          </div>
        )}
      </div>
    );
  }
}

APIName.propTypes = {
  error: PropTypes.string,
  apiName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  canUseViewActivationModeManual: PropTypes.bool,
};

APIName.defaultProps = {
  canUseViewActivationModeManual: true,
};

export default APIName;

/**
 * Holds the state of the selected primary and secondary metric events for the p13n results picker.
 *
 * @author Aakash Therani (aakash.therani@optimizely.com)
 */

import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      // whether or not to only use current project in a cross project context
      useCurrentProject: true,
    });
  },

  initialize() {
    this.on(
      actionTypes.METRICS_PICKER_USE_CURRENT_PROJECT,
      setUseCurrentProject,
    );
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Boolean} shouldUse current project only
 */
function setUseCurrentProject(state, shouldUse) {
  return state.set('useCurrentProject', shouldUse);
}

/**
 * Entity Definition for custom audience attribute definitions
 */
export default {
  entity: 'attribute_definitions',

  isRelationshipEntity: true,

  parents: [
    {
      entity: 'custom_audience_integrations',
      key: 'integration_id',
    },
    {
      entity: 'attribute_definitions',
      key: 'integration_id',
    },
  ],
};

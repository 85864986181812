/**
 * @author Jon Noronha (jon.noronha@optimizely.com)
 */
const _ = require('lodash');
const sprintf = require('sprintf');

/**
 * Wrap all the elements in a list with an HTML tag.
 * Also accepts "double-quote" to wrap in double quotes instead
 * @param {Array} list
 * @param {String} tag
 * @return {Array}
 */
module.exports = function(list, tag) {
  const out = _.map(list, element => {
    if (element !== null && element !== undefined) {
      if (tag === 'double-quote') {
        return sprintf('"%s"', element);
      }
      return sprintf('<%s>%s</%s>', tag, element, tag);
    }
    return element;
  });
  return out;
};

import keyMirror from 'optly/utils/key_mirror';

export default keyMirror({
  REGISTER_ROOT_VM: null,
  RESET_ROOT_VM: null,
  REGISTER_GLOBAL_COMPONENT: null,

  REGISTER_REGION: null,
  UNREGISTER_REGION: null,
  REGISTER_COMPONENT: null,
  UNREGISTER_COMPONENT: null,
  QUEUE_REGION_COMPONENT: null,
  UNQUEUE_REGION_COMPONENT: null,
});

/**
 * Insert Operator Dropdown
 */

module.exports = {
  DOMInsertionType: {
    AFTER: 'after',
    APPEND: 'append',
    BEFORE: 'before',
    PREPEND: 'prepend',
  },
};

const _ = require('lodash');
const Immutable = require('optly/immutable').default;
const PropTypes = require('prop-types');
const React = require('react');

const VariableTableRow = require('./components/variable_table_row');

// components
const { Button, Label, Table } = require('optimizely-oui');

const FeatureVariables = props => {
  const showTable = props.variables.size > 0;
  return (
    <div>
      <div className="push-quad--bottom">
        <div className="push--bottom">
          <h3>
            <Label isOptional={true}>
              <span>Feature Configuration</span>
            </Label>
          </h3>
        </div>
        <p>
          A list of variables that can be used to test or roll out different
          configurations of your feature.
        </p>
      </div>
      <form>
        <fieldset>
          {showTable && (
            <Table>
              <Table.THead>
                <Table.TR>
                  <Table.TH>Variable Key</Table.TH>
                  <Table.TH>Type</Table.TH>
                  <Table.TH>Default Value</Table.TH>
                  <Table.TH />
                </Table.TR>
              </Table.THead>
              <Table.TBody>
                {props.variables.map((variable, index) => {
                  if (variable.get('archived')) {
                    return;
                  }
                  return (
                    <VariableTableRow
                      key={index}
                      index={index}
                      isVariableKeyValid={props.isVariableKeyValid}
                      isVariableUnique={props.isVariableUnique}
                      isVariableValueValid={props.isVariableValueValid}
                      removeVariableAtIndex={_.partialRight(
                        props.removeVariableAtIndex,
                        index,
                      )}
                      updateVariable={props.updateVariable}
                      variable={variable}
                      handleVariableValueClick={props.handleVariableValueClick}
                      hasVariableValueBeenClicked={
                        props.hasVariableValueBeenClicked
                      }
                    />
                  );
                })}
              </Table.TBody>
            </Table>
          )}
          <Button
            onClick={props.addVariable}
            testSection="feature-dialog-add-variable"
            width="full">
            Add Variable
          </Button>
        </fieldset>
      </form>
    </div>
  );
};

FeatureVariables.propTypes = {
  addVariable: PropTypes.func.isRequired,
  hasVariableValueBeenClicked: PropTypes.bool,
  handleVariableValueClick: PropTypes.func,
  isVariableKeyValid: PropTypes.func.isRequired,
  isVariableUnique: PropTypes.func.isRequired,
  isVariableValueValid: PropTypes.func.isRequired,
  removeVariableAtIndex: PropTypes.func.isRequired,
  updateVariable: PropTypes.func.isRequired,
  variables: PropTypes.instanceOf(Immutable.List).isRequired,
};

module.exports = FeatureVariables;

/**
 * Holds the sort by state of all sortable tables
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */

import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import _ from 'lodash';

import sort from 'optly/utils/sort';

import { actionTypes } from '../action_types';
import fns from '../fns';
import sortFns from '../sort_fns';

const { ASC, DESC } = sort;

const DEFAULT_SORT_MAX = 2;

/**
 * sortableTableStore
 * Responsible for the following state management:
 * TODO: fill in
 */
export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    /**
     * map of tableIds => {
     *   sortMax: <number>,
     *   sortBy: <array<{
     *     field: <string>,
     *     type: <string>, // sort type enum
     *     dir: <sort.ASC|sort.DESC>, // sort dir enum
     *   }>>
     * }
     */
    return toImmutable({});
  },

  initialize() {
    this.on(actionTypes.SORT_TABLE, sortTable);
    this.on(actionTypes.TOGGLE_SORT_TABLE_FIELD, toggleField);
    this.on(actionTypes.RESET_TABLE_SORTING, reset);
  },
});

/**
 * payload.tableId - identifier of the table
 * payload.field - field name of what to sort by
 * payload.type - corresponds to key in fns
 */
function toggleField(state, payload) {
  const { tableId, field, type } = payload;
  const dir = fns.getDefaultSortDir(type);
  const { sortMax } = payload;

  const sortOptions = {
    tableId,
    field,
    type,
    dir,
    sortMax: sortMax || DEFAULT_SORT_MAX,
  };

  if (!state.get(tableId)) {
    return _sort(state, sortOptions);
  }

  // find out if already sorting by this field
  const ind = _.findIndex(state.getIn([tableId, 'sortBy']).toJS(), { field });

  if (ind !== -1) {
    const currentDir = state.getIn([tableId, 'sortBy', ind, 'dir']);

    sortOptions.dir =
      ind === 0
        ? // if at the top switch directions
          currentDir === ASC
          ? DESC
          : ASC
        : // otherwise use the current dir and __sort will move to top
          dir;
  }

  return _sort(state, sortOptions);
}

/**
 * payload.tableId - unique table identifier (ex: 'dashboard.experiments')
 * payload.sortMax - (optional default=2) the maximum number of fields to fallback on sorting
 * payload.sortBy - array of { field, type, dir }
 */
function sortTable(state, payload) {
  payload.sortBy
    .map(entry => {
      const opts = _.extend(
        {
          tableId: payload.tableId,
          sortMax: payload.sortMax,
        },
        entry,
      );
      if (!opts.dir) {
        opts.dir = fns.getDefaultSortDir(opts.type);
      }
      return opts;
    })
    .reverse()
    .forEach(sortOptions => {
      state = _sort(state, sortOptions);
    });

  return state;
}

/**
 * payload.tableId - unique table identifier (ex: 'dashboard.experiments')
 * payload.field - name of field in object
 * payload.type - 'number', 'string', etc see 'utils/sort.js' functions
 * payload.dir - (optional default=sort.ASC) sort.ASC or sort.DESC
 * payload.sortMax - (optional default=2) the maximum number of fields to fallback on sorting
 * @param {Immutable} state
 * @param {object} payload
 * @return {boolean} did emitChange
 */
function _sort(state, payload) {
  const { tableId, field } = payload;
  let { type } = payload;
  const sortMax = payload.sortMax || DEFAULT_SORT_MAX;
  const { dir } = payload;
  let table;
  if (!state.get(tableId)) {
    table = {
      sortMax,
      sortBy: [],
    };
    state = state.set(tableId, toImmutable(table));
  } else {
    // the weird case where the sort max changes after table initialize
    state = state.setIn([tableId, 'sortMax'], sortMax);
  }

  if (!sortFns[type]) {
    console.warn(`No sort function for type: ${type}, using string sort`);
    type = 'string';
  }

  table = state.get(tableId).toJS();
  // see if the field is already being sorted on
  const ind = _.findIndex(table.sortBy, { field });
  if (ind === 0) {
    if (dir === table.sortBy[0].dir) {
      // already sorting by field at top priority in specified directory, no change
      return state;
    }
    table.sortBy[0].dir = dir;
  } else if (ind !== -1) {
    // move field to top of priority
    table.sortBy.unshift(table.sortBy.splice(ind, 1)[0]);
  } else {
    if (table.sortBy.length >= table.sortMax) {
      table.sortBy.pop();
    }
    // add field to beginning of sortBy array
    table.sortBy.unshift({
      field,
      type,
      dir,
    });
  }

  return state.set(tableId, toImmutable(table));
}

/**
 * payload.tableId
 */
function reset(state, payload) {
  return state.delete(payload.tableId);
}

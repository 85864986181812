/**
 * Services layer pure functions for Webhook
 */

/**
 * @TODO(delikat): deprecate
 * Validates that a webhook entity contains required fields.
 * @param {Array} webhooks The webhooks to validate.
 * @returns {Array} Errors during validation.
 */
export function validate(webhooks) {
  const errors = [];

  // validate that each webhook contains a non-empty URL attribute
  const invalidUrl = webhooks.find(webhook => !webhook.get('url'));
  if (invalidUrl) {
    errors.push('Missing URL for one or more entries.');
  }

  return errors;
}

/**
 * Creates an empty webhook entity object extended with the supplied data
 * @param {Object} data
 * @return {Object}
 */
export function createWebhookEntity(data) {
  const DEFAULTS = {
    id: null,
    active: true,
    url: null,
    project_id: null,
  };

  return {
    ...DEFAULTS,
    ...data,
  };
}

export default {
  validate,
  createWebhookEntity,
};

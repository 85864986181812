import { flattenDeep } from 'lodash';

import Immutable, { toImmutable, toJS } from 'optly/immutable';

import {
  deriveAudienceIdsFromAudienceConditions,
  deriveAudienceIdsFromAudienceConditionsPOJO,
} from 'optly/modules/entity/layer_experiment/fns';
import { enums as AudienceEnums } from 'optly/modules/entity/audience';

const {
  ALL_AUDIENCES,
  ANY_AUDIENCES,
  CUSTOM_AUDIENCES,
} = AudienceEnums.audienceMatchTypes;
const { AND, OR, NOT } = AudienceEnums.ConditionGroupTypes;

/**
 * Returns audienceCondition JSON as an Immutable.List if updatedAudienceConditionsString if valid JSON,
 * returns null for invalid JSON and default conditions (e.g ["or"]) for an empty string (when trimmed)
 *
 * @param {String} updatedAudienceConditionsString
 *  JSON string to attempt to parse and prettify
 * @returns {Immutable.List|Null}
 */
export function deriveAudienceConditionsJsonFromString(
  updatedAudienceConditionsString,
) {
  // Empty strings should be treated as "everyone"
  if (
    typeof updatedAudienceConditionsString === 'string' &&
    updatedAudienceConditionsString.trim() === ''
  ) {
    return toImmutable([OR]);
  }

  try {
    const audienceConditionsJson = JSON.parse(updatedAudienceConditionsString);
    return toImmutable(audienceConditionsJson);
  } catch (e) {
    return null;
  }
}

/**
 * Given an audience_conditions Immutable.List, this will return the audienceMatchType
 *
 * @param {Immutable.List} audienceConditions
 * @returns {enums.audienceMatchTypes}
 *  ANY_AUDIENCES, ALL_AUDIENCES, or CUSTOM_AUDIENCES
 */
export function deriveAudienceMatchTypeFromConditions(audienceConditions) {
  if (!audienceConditions || !audienceConditions.size) {
    return ANY_AUDIENCES;
  }

  // Set the audienceMatchType based off the initial operator and remaining items
  const [intialOperator] = audienceConditions.slice(0, 1);
  if (
    [AND, OR].includes(intialOperator) &&
    audienceConditions
      .slice(1)
      .every(conditionLeaf => conditionLeaf instanceof Immutable.Map)
  ) {
    return intialOperator === OR ? ANY_AUDIENCES : ALL_AUDIENCES;
  }

  return CUSTOM_AUDIENCES;
}

/**
 * Given an audience_conditions array, this will return the audienceMatchType
 *
 * @param {Array} audienceConditions
 * @returns {enums.audienceMatchTypes}
 *  ANY_AUDIENCES, ALL_AUDIENCES, or CUSTOM_AUDIENCES
 */
export function deriveAudienceMatchTypeFromConditionsPOJO(audienceConditions) {
  if (!audienceConditions || !audienceConditions.length) {
    return ANY_AUDIENCES;
  }

  // Set the audienceMatchType based off the initial operator and remaining items
  const [intialOperator] = audienceConditions.slice(0, 1);
  if (
    [AND, OR].includes(intialOperator) &&
    audienceConditions
      .slice(1)
      .every(conditionLeaf => !Array.isArray(conditionLeaf))
  ) {
    return intialOperator === OR ? ANY_AUDIENCES : ALL_AUDIENCES;
  }

  return CUSTOM_AUDIENCES;
}

/**
 * If not null, convert the Immutable.List to prettified JSON
 *
 * @param {Immutable.List|Null} immutableListToFormat
 * @returns {String|Null}
 */
export function derivePrettyStringFromImmutableList(immutableListToFormat) {
  if (!immutableListToFormat) {
    return null;
  }
  const jsonToFormat = toJS(immutableListToFormat);
  return JSON.stringify(jsonToFormat, null, 2);
}

/**
 * The Audience Match Type UI options will show if any of the following are true:
 * 1. Is Web Project
 * 2. Is Full Stack Project and LayerExperiment has non-null and non-ANY match type configuration
 * 3. Is Full Stack Project and Full Stack Feature Flag is enabled
 *
 * @param {Immutable.List} audienceConditions
 * @returns {Function}
 *  Returns a function that requires isWebProject, isFullStackProject and will return a Boolean
 */
export function getShouldShowAudienceCombinationOptions(
  isWebProject,
  isFullStackProject,
) {
  return isWebProject || isFullStackProject;
}

/**
 * Finds the name for all audiences in audienceConditions and returns
 * an object of audienceIds mapped to the corresponding audienceName
 *
 * @param {Immutable.List} audienceConditions
 * @param {Immutable.List} audienceEntities
 * @returns {Object}
 */
export function computeSelectedAudienceNamesMap(
  audienceConditions,
  audienceEntities,
) {
  const audienceIds = deriveAudienceIdsFromAudienceConditions(
    audienceConditions,
  );
  const audienceNamesMap = {};
  audienceIds.forEach(audienceId => {
    const audienceName = audienceEntities.find(
      aud => aud.get('id') === audienceId,
    );
    audienceNamesMap[audienceId] =
      (audienceName && audienceName.get('name')) || audienceId;
  });
  return audienceNamesMap;
}

/**
 * Finds the name for all audiences in audienceConditions and returns
 * an object of audienceIds mapped to the corresponding audienceName
 *
 * @param {Array} audienceConditions
 * @param {Array} audienceEntities
 * @returns {Object}
 */
export function computeSelectedAudienceNamesMapPOJO(
  audienceConditions,
  audienceEntities,
) {
  const audienceIds = deriveAudienceIdsFromAudienceConditionsPOJO(
    audienceConditions,
  );
  return audienceIds.reduce((acc, audienceId) => {
    const audienceName = audienceEntities.find(aud => aud.id === audienceId);
    acc[audienceId] = (audienceName && audienceName.name) || audienceId;
    return acc;
  }, {});
}

/**
 * Extracts a list of audience condition operators from a set of
 * audience rules. This function will also extract nested operators.
 *
 * @params {Array} - list of parsed json audience conditions
 * @returns {Array} - list of operator values
 *
 * @example - array with nested conditions
 *   getOperatorsFromAudienceConditions(
 *     ["or",{"audience_id":2},["not",{"audience_id": 444}]]
 *  )
 * // returns ["or", "not"]
 *
 * @example - nested empty conditions
 *   getOperatorsFromAudienceConditions(
 *     ["or",{"audience_id":2},[]]
 *  )
 * // returns ["or", ""]
 *
 */
const getOperatorsFromAudienceConditions = conditions =>
  Array.isArray(conditions)
    ? [
        // Grab the first item in the array (should be the operator value)
        conditions.length < 1 ? '' : conditions[0],
        // Check the rest of the array for nested conditions
        ...conditions.reduce(
          (result, condition) =>
            Array.isArray(condition)
              ? [...result, ...getOperatorsFromAudienceConditions(condition)]
              : result,
          [],
        ),
      ]
    : [];

/**
 * Object of functions for each kind of validation, processed by this.validateAudienceConfig. Each validation will be sent
 * arguments audienceConditionsString, audienceIds, and availableAudiences.
 */
export const validators = {
  /**
   * The Audience will be considered invalid if an error string is returned, or valid if undefined
   *
   * @param {Object} config
   * @param {String} config.audienceConditionsString
   * @returns {String|Undefined}
   */
  audienceConditionsInvalidJson: ({ audienceConditionsString }) => {
    const validationMessage = 'Audience conditions must be valid JSON.';
    try {
      const audienceConditionsJson = JSON.parse(audienceConditionsString);
      if (!audienceConditionsJson) {
        return validationMessage;
      }
    } catch (e) {
      return validationMessage;
    }
  },
  /**
   * The Audience will be considered invalid if an error string is returned, or valid if undefined
   *
   * @param {Object} config
   * @param {String} config.audienceConditionsString
   * @returns {String|Undefined}
   */
  audienceConditionsInvalidLeafKey: ({ audienceConditionsString }) => {
    try {
      const parsedAudienceConditions = JSON.parse(audienceConditionsString);
      const conditionLeaves = flattenDeep(parsedAudienceConditions).filter(
        item => item instanceof Object,
      );
      if (
        conditionLeaves.length &&
        !conditionLeaves.every(item =>
          Object.keys(item).every(itemKey => itemKey === 'audience_id'),
        )
      ) {
        return 'Only the key "audience_id" can be used for Audience combinations.';
      }
    } catch (e) {} // eslint-disable-line no-empty
  },
  /**
   * The Audience will be considered invalid if an error string is returned, or valid if undefined
   *
   * @param {Object} config
   * @param {String} config.audienceConditionsString
   * @returns {String|Undefined}
   */
  audienceConditionsInvalidOperator: ({ audienceConditionsString }) => {
    try {
      const parsedAudienceConditions = JSON.parse(audienceConditionsString);
      const operators = getOperatorsFromAudienceConditions(
        parsedAudienceConditions,
      );
      if (
        !operators.length ||
        operators.some(item => ![OR, AND, NOT].includes(item))
      ) {
        return 'A valid operator ("or", "and", or "not") must be used.';
      }
    } catch (e) {} // eslint-disable-line no-empty
  },
};

export default {
  computeSelectedAudienceNamesMap,
  computeSelectedAudienceNamesMapPOJO,
  deriveAudienceConditionsJsonFromString,
  deriveAudienceMatchTypeFromConditions,
  deriveAudienceMatchTypeFromConditionsPOJO,
  derivePrettyStringFromImmutableList,
  getShouldShowAudienceCombinationOptions,
  validators,
};

/**
 * Entity Definition for event
 */
module.exports = {
  entity: 'dcp_datasource_attributes',

  parent: {
    entity: 'dcp_datasources',
    key: 'dcp_datasource_id',
  },
};

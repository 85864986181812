import shortid from 'shortid';

/**
 * This is a wrapper around the npm package shortid that enables it
 * to return fixed length short IDs.
 *
 * If it's fine to use variable length short IDs, then just import
 * the library directly. The library generates short IDs between 7-14
 * characters in length.
 *
 * By default, the character set includes A to Z, a to z, 0 to 9, - , and _.
 * This is a typical character set used for url safe base64 encoding.
 *
 * Variable short IDs with only letters and numbers does not make sense because
 * in the rare cases a short ID is generated with only -'s' and _'s, an
 * empty string will be returned. Therefore no such wrapper function is included
 * here.
 */

/**
 * Generates a short unique ID of a fixed length.
 *
 * Set lettersAndNumbersOnly to true to remove underscores and dashes from this
 * set. Note that setting this to true slightly increases the likelihood of a
 * collision since multiple values returned by the library can become the
 * same value once -'s and _'s are removed (e.g. ABC_D = ABC-D = ABCD).
 *
 * It is recommended to use a length of at least 7 to reduce the likelihood
 * of a collision to a reasonable state. The library by default returns short
 * IDs between 7-14 which we prefer to follow, although higher lengths wull
 * greatly reduce the likelihood for collision.
 *
 * Given that we're using a character set of 64 (or 62 without _'s and -'s),
 * the chances of collsions is (64 ^ length) which for 7 is approximately 4
 * trillion possible characters.
 *
 * @param  {Number} length
 * @param  {Boolean} lettersAndNumbersOnly
 * @return {String}
 */
export const generateFixedLengthShortId = (
  length = 7,
  lettersAndNumbersOnly = false,
) => {
  let finalId = '';
  let nextPiece;
  while (finalId.length < length) {
    nextPiece = shortid.generate();
    if (lettersAndNumbersOnly) {
      nextPiece = nextPiece.replace(/[_-]/g, '');
    }
    finalId += nextPiece;
  }
  return finalId.slice(0, length);
};

export default {
  generateFixedLengthShortId,
};

/**
 * Join multiple strings into one using the specified delimiter.
 * @param {Array} value an array of strings to concatenate
 * @param {String} delimiter delimiter used to concatenate strings, default to ', '
 * @return {String}
 */
module.exports = function(value, delimiter) {
  delimiter = !(delimiter === undefined || delimiter === null)
    ? delimiter
    : ', ';
  return value.join(delimiter);
};

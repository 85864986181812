/**
 * Sidebar presentational components
 *
 * Example:
 *
 * <Sidebar>
 *   <SidebarHeader
 *      projectName={ currentProject.get('project_name') }
 *      backLinkOnClick={ this.navigateBack }
 *      backLinkText={ this.getBackLinkText() }
 *      showBackLink={ showBackLink }
 *      title={ currentLayer.get('name') }
 *      isFullHeight={ isFullHeight }>
 *      <SidebarDetailsList items={ details } />
 *   </SidebarHeader>
 *   <SidebarNavListBody>
 *     <SidebarNavList label={listName}
 *       testSection='james-list-of-links'>
 *       <SidebarNavListItem href='/james/page1'
 *         testSection='page1-link'>
 *         <span>LINK TO JAMES 1</span>
 *       </SidebarNavListItem>
 *       <SidebarNavListItem href='/james/page2'
 *         isActive={true}
 *         testSection='page2-link'>
 *         <span>LINK TO JAMES 2</span>
 *       </SidebarNavListItem>
 *       <SidebarNavListItem href='/james/page3'
 *         testSection='page3-link'>
 *         <span>LINK TO JAMES 3</span>
 *       </SidebarNavListItem>
 *     </SidebarNavList>
 *   </SidebarNavListBody>
 * </Sidebar>
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NativeListener from 'react-native-listener';
import { Link, Icon, CopyButton, Poptip, Feedback } from '@optimizely/axiom';
import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';

import pushStateHandler from 'optly/utils/push_state';

export class Sidebar extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isResizable: PropTypes.bool,
    testSection: PropTypes.string,
  };

  static defaultProps = {
    isResizable: false,
  };

  render() {
    const { isResizable, testSection, children } = this.props;
    return (
      <div
        data-ui-component={true}
        data-test-section={testSection}
        className={classNames({
          'two-col__nav': !isResizable,
          'stage__item__content--column': true,
        })}>
        {children}
      </div>
    );
  }
}

export class SidebarHeader extends React.Component {
  onCopySuccess = this.onCopySuccess.bind(this);

  onFeedbackClose = this.onFeedbackClose.bind(this);

  state = {
    isFeedbackOpen: false,
  };

  static propTypes = {
    backLinkOnClick: PropTypes.func.isRequired,
    backLinkText: PropTypes.string.isRequired,
    children: PropTypes.node,
    isFullHeight: PropTypes.bool,
    isFullStackProject: PropTypes.bool,
    isResultsPage: PropTypes.bool,
    projectName: PropTypes.string.isRequired,
    scrollHeader: PropTypes.bool,
    showBackLink: PropTypes.bool,
    spaceBelow: PropTypes.bool,
    title: PropTypes.string,
  };

  static defaultProps = {
    isFullHeight: false,
    isFullStackProject: false,
    showBackLink: true,
    spaceBelow: false,
    title: null,
  };

  onCopySuccess() {
    this.setState({ isFeedbackOpen: true });
  }

  onFeedbackClose() {
    this.setState({ isFeedbackOpen: false });
  }

  render() {
    const {
      isFullHeight,
      isFullStackProject,
      isResultsPage,
      projectName,
      showBackLink,
      backLinkText,
      backLinkOnClick,
      title,
      scrollHeader,
      spaceBelow,
    } = this.props;

    const { isFeedbackOpen } = this.state;

    const maxCharCount = 22;
    return (
      <div
        className={classNames({
          sidenav__header: true,
          'sidenav__header--full-height': isFullHeight,
          'overflow-y--auto': scrollHeader,
          'hard--bottom': !spaceBelow,
        })}>
        <div className="micro muted" data-test-section="header-project-name">
          {projectName}
        </div>
        {showBackLink && (
          <div className="push--bottom">
            <NativeListener onClick={backLinkOnClick}>
              <a className="nav-link" data-test-section="header-back-link">
                <Icon
                  className="push-half--right"
                  name="arrow-left"
                  size="small"
                  color={brandBlueDark}
                  fill={brandBlueDark}
                />
                {backLinkText}
              </a>
            </NativeListener>
          </div>
        )}

        {title && (
          <div className="flex flex--row flex-justified--between">
            <h4
              className={classNames({
                sidenav__header__title: true,
                'flush--bottom': true,
                'force-break': true,
                gamma: !isFullStackProject,
                delta: isFullStackProject && title.length <= maxCharCount,
                zeta: isFullStackProject && title.length > maxCharCount,
                monospace: isFullStackProject,
              })}
              title={title}
              data-test-section="header-title">
              {title}
            </h4>
            {isResultsPage && (
              <Poptip
                className="flex-self--end"
                size="small"
                content="Copy experiment name"
                ariaHasPopup={true}
                position="top"
                trigger="mouseenter">
                <CopyButton
                  style="plain"
                  size="small"
                  usesTextLabel={false}
                  textToCopy={title}
                  onCopy={this.onCopySuccess}
                />
              </Poptip>
            )}
            <Feedback
              type="good-news"
              open={isFeedbackOpen}
              onClose={this.onFeedbackClose}>
              Experiment name copied to clipboard
            </Feedback>
          </div>
        )}

        {this.props.children}
      </div>
    );
  }
}

export const SidebarDetailsList = ({ items, spaceBelow }) => {
  const itemList = items.map(item => {
    if (item.isVisible !== undefined && !item.isVisible) {
      return null;
    }

    return [
      <div
        key={`label-${item.label}`}
        className={classNames({
          sidenav__section__title: true,
          'flush--bottom': true,
          'push--top': true,
          'border--top': item.showBorderTop,
        })}>
        <h6
          className={classNames({
            'push--top': item.showBorderTop,
          })}>
          {item.label}
        </h6>
      </div>,
      <span
        title={item.title}
        key={`value-${item.label}`}
        className="sidenav__section__item">
        {item.value}
      </span>,
    ];
  });

  return (
    <div
      className={classNames({
        sidenav__details: true,
        'soft-double--bottom': spaceBelow,
      })}>
      {itemList}
    </div>
  );
};

SidebarDetailsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]).isRequired,
    }),
  ).isRequired,
  spaceBelow: PropTypes.bool,
};

SidebarDetailsList.defaultProps = {
  spaceBelow: false,
};

export const SidebarNavListBody = props => (
  <div className="sidenav__body">{props.children}</div>
);
SidebarNavListBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SidebarNavList = props => (
  <div className="sidenav__section" data-test-section={props.testSection}>
    <div className="sidenav__section__title">
      <h5>{props.label}</h5>
      {props.rightLabel && <h6>{props.rightLabel}</h6>}
      {props.popover}
    </div>
    <ul className="nav-list no-border--top">{props.children}</ul>
  </div>
);

SidebarNavList.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  popover: PropTypes.node,
  rightLabel: PropTypes.string,
  testSection: PropTypes.string,
};

export const SidebarNavListItem = props => {
  const onClickHandler =
    props.onClick || pushStateHandler.bind(null, props.href);
  return (
    <li
      className={classNames(
        Object.assign(
          {
            'nav-list__link': true,
            'is-active': props.isActive,
          },
          props.classNames,
        ),
      )}
      data-test-section={props.testSection}>
      <Link
        onClick={onClickHandler}
        href={props.href}
        testSection={`${props.testSection}-link`}>
        <div className="soft--ends soft-double--sides">{props.children}</div>
      </Link>
    </li>
  );
};

SidebarNavListItem.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.object,
  href: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  testSection: PropTypes.string,
};

export default {
  Sidebar,
  SidebarHeader,
  SidebarNavList,
  SidebarNavListItem,

  SidebarDetailsList,
  SidebarNavListBody,
};

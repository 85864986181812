/**
 * Back directive
 *
 * @author Sam Jackson (sam@optimizely.com)
 */

import $ from 'jquery';

import historyUtil from 'optly/utils/history';

const exported = {
  isLiteral: true,

  bind() {
    $(this.el).on('click', e => {
      e.preventDefault();
      historyUtil.back();
    });
  },
};

export default exported;

export const { isLiteral, bind } = exported;

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';
import PermissionsModuleGetters from 'optly/modules/permissions/getters';
import AdminAccountGetters from 'optly/modules/admin_account/getters';

import definition from './entity_definition';
import fns from './fns';

const baseEntityGetters = createEntityGetters(definition);

export const { entityCache } = baseEntityGetters;

export const projectsWithProjectType = [
  entityCache,
  PermissionsModuleGetters.canAccountUseFullStackExperiments,
  PermissionsModuleGetters.canAccountUseFullStackSDKs,
  AdminAccountGetters.hasPP2020RolloutsPlusSubscription,
  (
    projects,
    canAccountUseFullStackExperiments,
    canAccountUseFullStackSDKs,
    hasPP2020RolloutsPlusSubscription,
  ) => {
    return projects.map(project =>
      fns.mapProjectWithProjectType({
        project,
        canAccountUseFullStackExperiments,
        canAccountUseFullStackSDKs,
        hasPP2020RolloutsPlusSubscription,
      }),
    );
  },
];

export default {
  projectsWithProjectType,
};

/**
 * Geotargeting Conditon Group Component
 * extends `condition-group` component
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const GeotargetingLabel = require('optly/directives/geotargeting_label');

const GeotargetingAutocomplete = require('./geotargeting_autocomplete');

module.exports = {
  replace: true,

  directives: {
    // TODO: test that geotargeting label directive still works
    'geotargeting-label': GeotargetingLabel,
  },

  components: {
    'geotargeting-autocomplete': GeotargetingAutocomplete,
  },

  data: {
    /**
     * @var {ConditionGroup}
     */
    conditionGroup: null,
  },

  computed: {
    /**
     * Use computed negate so 'true' and 'false' can be converted to their boolean values
     */
    negate: {
      $get() {
        return this.conditionGroup.negate ? 'true' : 'false';
      },
      $set(val) {
        this.conditionGroup.negate = val !== 'false';
      },
    },

    /**
     * Returns whether or not the condition group has any conditions
     * @return {Boolean}
     */
    hasNoConditions() {
      return this.conditionGroup.conditions.length === 0;
    },
  },

  methods: {
    /**
     * Helper method to get the current index of a condition
     *
     * Cannot use the $index property of the loop because it does not get
     * updated when conditions are removed
     *
     * @param {ConditionGroup|Condition} condition
     * @return {number}
     */
    conditionIndex(condition) {
      return this.conditionGroup.conditions.indexOf(condition);
    },

    /**
     * Removes condition from the group
     * @param {ConditionGroup|Condition} condition
     */
    removeCondition(condition) {
      this.conditionGroup.removeCondition(condition);
    },

    /**
     * Destroy the codnition group by dispatching an event
     * to parent component
     */
    removeConditionGroup() {
      this.conditionGroup.conditions = [];
      this.$dispatch('conditionRemoved');
    },
  },

  ready() {
    // normal condition groups are instantiated with an empty condition in this.conditionGroup.conditions[0]
    // we dont want that for geotargeting, remove it
    if (
      this.conditionGroup.conditions.length === 1 &&
      !this.conditionGroup.conditions[0].value
    ) {
      this.conditionGroup.conditions = [];
    }

    this.$on('geotargetingLocationSelected', data => {
      this.conditionGroup.conditions.push({
        type: this.conditionType,
        value: data.location,
      });
      // broadcast to geotargeting autocomplete to clear the input
      this.$broadcast('geotargetingAutocompleteClear');
    });
  },
};

import PropTypes from 'prop-types';
import React from 'react';

class CodeSampleWrapper extends React.Component {
  static propTypes = {
    getCodeSample: PropTypes.func.isRequired,
    sdk: PropTypes.string.isRequired,
    showMobileOnly: PropTypes.bool.isRequired,
  };

  generateAppleCodeSamples = () => (
    <div>
      <div className="push--bottom">
        <strong>Objective-C</strong>
        {this.getCodeSampleWithLanguage('objectivec')}
      </div>
      <div>
        <strong>Swift</strong>
        {this.getCodeSampleWithLanguage('swift')}
      </div>
    </div>
  );

  getCodeSampleWithLanguage = language => {
    const { getCodeSample, sdk } = this.props;

    const codeSampleLanguage = language || sdk;
    return getCodeSample(codeSampleLanguage);
  };

  render() {
    const { sdk } = this.props;

    let codeSample;

    switch (sdk) {
      case 'android':
        codeSample = this.getCodeSampleWithLanguage('java');
        break;
      case 'ios':
      case 'tv_os':
        codeSample = this.generateAppleCodeSamples();
        break;
      case 'react':
        codeSample = this.getCodeSampleWithLanguage('jsx');
        break;
      case 'agent':
        codeSample = this.getCodeSampleWithLanguage('html');
        break;
      default:
        codeSample = this.getCodeSampleWithLanguage();
        break;
    }

    return (
      <div className="push--top" data-test-section="code-sample">
        {codeSample}
      </div>
    );
  }
}

export default CodeSampleWrapper;

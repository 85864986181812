/**
 * Module specific pure functions for authentication
 *
 * @author Cheston Lee
 */
import regex from 'optly/utils/regex';
import $ from 'jquery';
import $script from 'scriptjs';
import config from 'atomic-config';

const url = `${config.get('env.HOST_URL')}/static/js/zxcvbn.js`;

/**
 * Ensure we meet FE criteria for signin
 * @param creds {Object} {email: '', password: ''}
 * @return valid {Boolean}
 */
function validateLogin(creds) {
  return (
    regex.email.test(creds.email) && checkComplexPassword(creds.password).passed
  );
}

/**
 * Ensure password passes our security requirements.
 * Rules: 8 characters long lowercase, and either uppercase, numerals or special chars included.
 *
 * @param {String} password
 * @return {Object} i.e. { passed: {Boolean}, message: {String} }
 */
function checkComplexPassword(password) {
  let message = 'message';
  let passed = true;
  const CHAR_LOWERS = /[a-z]/;
  const CHAR_UPPERS = /[A-Z]/;
  const CHAR_NUMBERS = /[0-9]/;
  const CHAR_SPECIAL = /[?=.*!@#$%^&*]/;
  const CHAR_TYPES = [CHAR_LOWERS, CHAR_UPPERS, CHAR_NUMBERS, CHAR_SPECIAL];
  const MIN_PASSWORD_LENGTH = 8;
  const MIN_REQUIREMENTS = 1;

  // The types of special conditions we have to meet
  let counter = 4;
  for (let i = 0; i < CHAR_TYPES.length; i++) {
    if (!CHAR_TYPES[i].test(password)) {
      counter--;
    }
  }

  // CLL: Added length check because we could create accounts with short passwords before.
  if (counter <= MIN_REQUIREMENTS || password.length < MIN_PASSWORD_LENGTH) {
    message = tr(
      'Use at least 8 characters and a mix of upper/lowercase letters, numbers, or symbols.',
    );
    passed = false;
  }

  return {
    passed,
    message,
  };
}

/**
 * Provide password strength score from zxcvbn library
 * @param {String} password
 * @param {Object} userInfo {firstName: '', lastName: '', email: ''}
 * @return {Number} password strength score
 */

function passwordStrength(password, userInfo) {
  userInfo = userInfo || {};
  const result = window.zxcvbn(password, [
    userInfo.firstName,
    userInfo.lastName,
    userInfo.email,
  ]);
  return result.score;
}

/**
 * Provide user with password strength indicator using score returned from passwordStrength
 * @param {String} password
 * @param {Object} userInfo {firstName: '', lastName: '', email: ''}
 * @return {Object} deferred promise object
 */

function checkPasswordStrength(password, userInfo) {
  const deferred = $.Deferred();
  $script([url], 'zxcvbn');
  $script.ready('zxcvbn', () => {
    const score = passwordStrength(password, userInfo);
    deferred.resolve(score);
  });
  return deferred.promise();
}

export { validateLogin, checkComplexPassword, checkPasswordStrength };

export default {
  validateLogin,
  checkComplexPassword,
  checkPasswordStrength,
};

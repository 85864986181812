import React from 'react';

import { HelpPopover } from 'optimizely-oui';

const OutlierFilteringPopover = () => (
  <HelpPopover
    horizontalAttachment="left"
    testSection="outlier-filtering-help-popover-btn"
    verticalAttachment="top">
    <p className="weight--bold">
      Applies only to revenue metrics within the current experiment.
    </p>
    <p>
      <span className="weight--bold display--block">What is an outlier?</span>
      An outlier is a value that is abnormally lower or higher than other values
      in your results.
    </p>
    <p>
      <span className="weight--bold display--block">Why smooth outliers?</span>
      Outliers can severely skew the accuracy of any analysis conducted on a
      data set and can lead to potentially incorrect conclusions. Smoothing
      outliers generally results in more accurate conclusions. We recommend
      turning on this setting for your revenue metric to improve the integrity
      of your results.
    </p>
    <p>
      <span className="weight--bold display--block">
        How does Optimizely treat outliers?
      </span>
      For revenue metrics, Optimizely gives you the option to use outlier
      smoothing. Optimizely's outlier smoothing algorithm first identifies any
      values that exceed the daily exclusion threshold, which are extreme values
      that are 3 standard deviations higher than the observed mean. These
      extreme values are designated as outliers. Next, Optimizely replaces these
      outliers with the metric's harmonic mean. This step in the process is
      known as outlier smoothing. Optimizely recalculates the daily exclusion
      threshold for each day using a moving average of the arithmetic mean and
      standard deviation of your metric over the previous seven (7) days. This
      process repeats for each day of the experiment.
    </p>
    <a
      className="weight--bold"
      target="_blank"
      href="https://support.optimizely.com/hc/en-us/articles/4410289414413-How-Optimizely-Handles-Outliers"
      rel="noopener noreferrer">
      Learn more about outlier smoothing.
    </a>
  </HelpPopover>
);

export default OutlierFilteringPopover;

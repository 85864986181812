import React, { useEffect, useState } from 'react';
import { useFeature } from '@optimizely/react-sdk';

import {
  Button,
  Code,
  Link,
  Sheet,
  Spinner,
  Input,
  Textarea,
} from '@optimizely/axiom';

import regexUtils from 'optly/utils/regex';
import SegmentTracking from 'optly/modules/segment';
import ui from 'core/ui';

import { Form } from 'react_components/form';
import { toImmutable } from 'optly/immutable';

import EventActions from 'optly/modules/entity/event/actions';
import ProjectEnums from 'optly/modules/entity/project/enums';

import CodeSamplePicker from 'bundles/p13n/components/code_sample_picker';
import EventProperties from 'bundles/p13n/components/event_properties';
import {
  cleanEventPropertiesForRequest,
  populateEventPropertiesFromRequest,
} from 'bundles/p13n/components/event_properties/utils';

import { getFormattedEventAPIName } from 'bundles/p13n/sections/oasis_implementation/section_module/fns';

import { getCodeBlock } from '../../component_module/fns';

import {
  Event,
  EventCategoryEnum,
  EventTypeEnum,
} from '../../component_module/types';

export interface CreateOrEditEventSheetProps {
  /** Id of the project the user is using */
  currentProjectId: number;
  /** ID of event to fetch. If not provided, the dialog will be a creation dialog */
  id: number;
  /** Initial value that will be used for the name (via Form HOC) if ID is not provided */
  name?: string;
  /** @access form-hoc - Manages form state and validation */
  form: any;
  /** Function that will be called on cancel */
  onCancel: () => void;
  /** Function that will be called on save (or create) */
  onSave: () => void;
}

const DEFAULT_EVENTS_OVERVIEW_HREF =
  'https://docs.developers.optimizely.com/full-stack/docs/track-events';
const INVALID_KEY_ERROR_MESSAGE =
  'Please enter a valid key. Key must be no longer than 64 characters and consist of only alphanumeric characters, hyphens, underscores, spaces, and periods.';
const UNIQUE_KEY_ERROR_MESSAGE =
  'Key is already in use by another event in this project. Please choose a unique key.';
const FIELD_REQUIRED_ERROR_MESSAGE = 'This field is required.';

const CreateOrEditEventSheet = ({
  currentProjectId,
  form,
  id = NaN,
  onCancel,
  onSave,
}: CreateOrEditEventSheetProps) => {
  // eslint-disable-next-line camelcase
  const { full_stack_events_overview_link } = useFeature('help_copy')[1] || {};

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [eventPropertiesError, setEventPropertiesError] = useState<
    string | null
  >(null);
  const [isValidatingFeatureKey, setIsValidatingFeatureKey] = useState(false);
  const [isKeyFieldTouched, setIsKeyFieldTouched] = useState(false);

  const nameField = form.field('name');
  const nameValue = nameField.getValue();
  const keyField = form.field('api_name');
  const keyValue = keyField.getValue();
  const descriptionField = form.field('description');
  const descriptionValue = descriptionField.getValue();
  const eventPropertiesField = form.field('event_properties');
  const eventProperties = eventPropertiesField.getValue();

  const isNewEvent = !id;

  useEffect(() => {
    if (id) {
      EventActions.fetch(id)
        // eslint-disable-next-line camelcase
        .then(({ api_name, description, name, ...rest }: Event) => {
          const { event_properties } = populateEventPropertiesFromRequest(
            rest,
          ) as Event;
          form.setValue(
            toImmutable({
              _savedKey: api_name, // Not saved, only used for validation
              _savedName: name,
              description: description || '',
              api_name,
              id,
              name: name || '',
              event_properties,
            }),
          );
        })
        .always(() => setIsLoading(false));
      return;
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    form.field('api_name').validators({
      isValid: (value: string) =>
        !value || !regexUtils.eventAPIName.test(value)
          ? INVALID_KEY_ERROR_MESSAGE
          : '',
      isUnique: (value: string) => {
        const isValidEventApiName = regexUtils.eventAPIName.test(value);
        const isEmpty = !value;
        const isExistingEventAndKeyHasNotChanged =
          !isNewEvent && form.field('_savedKey').getValue() === value;
        const skipCheck = isEmpty || isExistingEventAndKeyHasNotChanged;

        // If the name is the same or the valid name check already got to it resolve early
        if (skipCheck || !isValidEventApiName) {
          return Promise.resolve(null);
        }
        setIsValidatingFeatureKey(true);
        const keyValidationFetch = EventActions.fetchByName(
          currentProjectId,
          value,
        )
          .then((result: any) => {
            return result && result.length > 0 ? UNIQUE_KEY_ERROR_MESSAGE : '';
          })
          .always(() => setIsValidatingFeatureKey(false));
        return keyValidationFetch;
      },
    });
    form.field('name').validators({
      isRequired: (value: string) =>
        !value ? FIELD_REQUIRED_ERROR_MESSAGE : '',
      isUnique: (value: string) => {
        const isEmpty = !value;
        const isExistingEventAndNameHasNotChanged =
          !isNewEvent && form.field('_savedName').getValue() === value;
        const skipCheck = isEmpty || isExistingEventAndNameHasNotChanged;

        if (skipCheck) {
          return Promise.resolve(null);
        }

        setIsValidatingFeatureKey(true);
        return EventActions.fetchByName(currentProjectId, value)
          .then((result: any) => {
            return result && result.length > 0
              ? `This name is already in use by another event with ID ${result[0].id}. Please provide a unique value`
              : '';
          })
          .always(() => setIsValidatingFeatureKey(false));
      },
    });
  }, []);

  const getKeyValidationNote = () => {
    return (
      (isValidatingFeatureKey && 'Validating...') ||
      form.field('api_name').getErrors().message
    );
  };

  const getNameValidationNote = () => {
    return form.field('name').getErrors().message;
  };

  const validateEventProperties = () => {
    const eventProperties = form
      .field('event_properties')
      .getValue()
      .toJS();

    return new Promise<void>((resolve, reject) => {
      const hasEmptyProperties = eventProperties.some(
        ({ value, type }: any) => !value || !type,
      );

      if (hasEmptyProperties) {
        reject();
        setEventPropertiesError(
          'Please enter a name and data type for all properties.',
        );
      } else {
        resolve();
        setEventPropertiesError(null);
      }
    });
  };

  const createOrSaveEvent = () => {
    const { event_properties } = cleanEventPropertiesForRequest({
      event_properties: eventProperties.toJS(),
    }) as Event;
    setIsSaving(true);
    Promise.all([form.validate(), validateEventProperties()])
      .then(() => {
        return new Promise((resolve, reject) => {
          if (!__TEST__) {
            SegmentTracking.tracking.trackEvent(
              'Event Created with Event Properties',
              {
                eventName: nameValue,
                apiName: keyValue,
                numberOfProperties: eventProperties.length,
                propertyTypes: eventProperties
                  .toJS()
                  .map((eventProperty: any) => eventProperty.type),
              },
            );
          }
          return EventActions.save({
            api_name: keyValue,
            category: EventCategoryEnum.Other,
            description: descriptionValue,
            event_type: EventTypeEnum.Custom,
            name: nameValue,
            project_id: currentProjectId,
            event_properties,
            ...(!!id && { id }),
          })
            .then((newEvent: Event) => {
              resolve(newEvent);
            })
            .fail(() => reject());
        });
      })
      .then(onSave)
      .then(() => {
        ui.showNotification({
          message: `This event has been ${id ? 'saved' : 'created'}`,
        });
      })
      .catch(() => {
        if (form.isFormValid()) {
          ui.showNotification({
            message: `There was a problem ${
              id ? 'saving' : 'creating'
            } this event`,
            type: 'error',
          });
        } else {
          form.setOptions({ validateOnChange: true });
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleEventNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    nameField.setValue(event.target.value);
    // if creating new event and user has not touched key field
    // auto-fill event key with suggested key value based on event name
    if (isNewEvent && !isKeyFieldTouched) {
      const formattedAPIName = getFormattedEventAPIName(
        false,
        event.target.value,
      ).toLowerCase();
      keyField.setValue(formattedAPIName);
    }
  };

  const updateEventProperties = (updatedProperties: any) => {
    const eventProperties = form.field('event_properties');
    eventProperties.setValue(toImmutable(updatedProperties));
  };

  return (
    <Sheet
      hasRequiredFieldsIndicator={true}
      footerButtonList={[
        <Button
          key="cancel"
          style="plain"
          onClick={onCancel}
          testSection="create-new-event-modal-cancel-button">
          Cancel
        </Button>,
        <Button
          key="submit"
          style="highlight"
          loadingText={`${isNewEvent ? 'Creating' : 'Saving'} Event`}
          onClick={createOrSaveEvent}
          isLoading={isSaving}
          testSection="create-new-event-modal-save-button">
          {isNewEvent ? 'Create' : 'Save'} Event
        </Button>,
      ]}
      onClose={onCancel}
      title={`${isNewEvent ? 'Create' : 'Edit'} Event`}
      subtitle={
        <span>
          Custom tracking events allow you to capture and report on visitor
          actions or events.{' '}
          <Link
            href={
              String(full_stack_events_overview_link) ||
              DEFAULT_EVENTS_OVERVIEW_HREF // eslint-disable-line camelcase
            }
            newWindow={true}>
            Learn more
          </Link>
        </span>
      }>
      {isLoading ? (
        <div className="height--300">
          <Spinner hasOverlay={true} />
        </div>
      ) : (
        <>
          <fieldset>
            <Input
              displayError={form.field('name').getErrors().hasError}
              id="event-name"
              isRequired={true}
              label="Event Name"
              note={getNameValidationNote()}
              onChange={handleEventNameChange}
              placeholder="Enter an Event Name"
              testSection="event-name"
              type="text"
              value={nameValue}
            />
          </fieldset>
          <fieldset>
            <Input
              displayError={form.field('api_name').getErrors().hasError}
              id="event-key"
              isRequired={true}
              label="Event Key"
              note={getKeyValidationNote()}
              onChange={({ target }: { target: { value: string } }) => {
                setIsKeyFieldTouched(true);
                const formattedEventKey: string = getFormattedEventAPIName(
                  true,
                  target.value,
                );
                keyField.setValue(formattedEventKey);
              }}
              placeholder="Enter an Event Key"
              testSection="event-key"
              type="text"
              value={keyValue}
            />
          </fieldset>
          <fieldset>
            <Textarea
              id="event-description"
              label="Description"
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                descriptionField.setValue(target.value)
              }
              placeholder="Describe this event."
              testSection="event-description"
              value={descriptionValue}
            />
          </fieldset>
          <fieldset>
            <EventProperties
              eventProperties={eventProperties.toJS()}
              eventPropertiesError={eventPropertiesError}
              isFx={true}
              updateEventProperties={updateEventProperties}
            />
          </fieldset>
          <fieldset>
            <CodeSamplePicker
              description="Choose your preferred SDK to view sample code. Copy and paste the code to track the event in your application."
              getCodeSample={(codeSampleLanguage: string) => {
                const language =
                  codeSampleLanguage || ProjectEnums.sdkLanguages.PYTHON;
                return (
                  <Code
                    isHighlighted={true}
                    hasCopyButton={true}
                    language={
                      ProjectEnums.sdkSyntaxLanguage[language] || language
                    }
                    type="block"
                    testSection="event-code-sample">
                    {getCodeBlock(language, keyValue)}
                  </Code>
                );
              }}
              showMobileOnly={false}
              title="Event Tracking Code"
            />
          </fieldset>
        </>
      )}
    </Sheet>
  );
};

export default Form({
  mapPropsToFormData: ({ id, name = '' }: { id: number; name: string }) =>
    toImmutable({
      _savedKey: null,
      _savedName: null,
      api_name: '',
      description: '',
      event_properties: [],
      id,
      name,
    }),
  debounceTimeout: __TEST__ ? 0 : 300,
  validateOnChange: false,
})(CreateOrEditEventSheet);

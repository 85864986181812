import fns from './fns';

export const holdback = ['p13n/holdbackSelector', 'holdback'];

export const isLayerHoldbackInvalid = ['p13n/holdbackSelector', 'invalid'];

export const formattedHoldback = [holdback, fns.formatLayerHoldbackForInput];

export default {
  formattedHoldback,
  holdback,
  isLayerHoldbackInvalid,
};

import React from 'react';
import { Button, DialogNew } from 'optimizely-oui';

import ui from 'core/ui';

const FeaturesQuickstartConfirmLeave = () => (
  <DialogNew
    title="Are you sure you want to leave?"
    hasCloseButton={false}
    footerButtonList={[
      <Button
        key={0}
        // Close the confirmation, then the quickstart dialog
        onClick={() => ui.hideDialog().then(ui.hideDialog)}
        style="unstyled"
        testSection="continue-to-dashboard-button">
        Continue to Dashboard
      </Button>,
      <Button
        key={1}
        onClick={ui.hideDialog}
        style="highlight"
        testSection="return-to-quickstart-button">
        Return to Quickstart
      </Button>,
    ]}>
    <p>
      You're leaving without testing the flag you created. Are you sure you want
      to leave without turning it on?
    </p>
  </DialogNew>
);

FeaturesQuickstartConfirmLeave.componentId = 'FeaturesQuickstartConfirmLeave';

export default FeaturesQuickstartConfirmLeave;

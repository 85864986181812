import PropTypes from 'prop-types';
import React from 'react';
import { Button, EmptyDashboard } from 'optimizely-oui';

const EmptyState = ({
  createButton,
  description,
  descriptionMaxWidth,
  headline,
  imagePath,
  shouldHideCreateButton,
}) => {

  return (
    <div className="flex flex--1">
      <EmptyDashboard
        button={
          !shouldHideCreateButton && (
            <Button
              isDisabled={createButton.isDisabled}
              style="highlight"
              onClick={createButton.onClick}
              testSection={createButton.testSection}>
              {createButton.label}
            </Button>
          )
        }
        imagePath={imagePath}
        headline={headline}
        description={description}
        descriptionMaxWidth={descriptionMaxWidth}
      />
    </div>
  );
};

EmptyState.propTypes = {
  // eslint-disable-next-line react/require-default-props
  createButton: PropTypes.shape({
    isDisabled: PropTypes.bool,
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    testSection: PropTypes.string,
  }),
  // eslint-disable-next-line react/require-default-props
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  descriptionMaxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  headline: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  shouldHideCreateButton: PropTypes.bool,
};

export default EmptyState;

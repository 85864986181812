/* eslint-disable import/no-cycle */
import { isNull } from 'lodash';
import { Button, Link } from 'optimizely-oui';
import PropTypes from 'prop-types';
import React from 'react';

import Immutable from 'optly/immutable';

import config from 'atomic-config';

import PhoneNumberValidator from 'optly/utils/phone_number';
import ProfileDetailsGetters from 'optly/modules/profile_details/getters';

import ProfileDetailsForm from 'optly/components/profile_details/profile_details_form';
import ui from 'core/ui';
import {
  actions as UserActions,
  getters as UserGetters,
} from 'optly/modules/entity/user';
import {
  actions as TermsOfServiceActions,
  getters as TermsOfServiceGetters,
} from 'optly/modules/entity/terms_of_service';

import {
  COMMUNICATION_PREFS,
  DESCRIPTION,
  FORM_FIELDS,
  GREETINGS,
  TITLES,
  UNSELECTED,
  SUPPORTED_COUNTRIES,
  TERMS_OF_SERVICE_TEXT,
} from 'optly/modules/profile_details/constants';

import ProfileDetailsDialogHeader from './subcomponents/header';

const DEFAULT_ACCOUNT_SETTINGS = Immutable.Map({
  communication_preference: COMMUNICATION_PREFS.EMPTY.value,
  country: '',
  first_name: '',
  job_department: UNSELECTED,
  job_role: UNSELECTED,
  last_name: '',
  phone_number: '',
});

/**
 * Takes the currentUser getter output and formats it for ProfileDetailsForm.
 * @param {Immutable.Map} currentUser - The currentUser getter output
 * @returns {Immutable.Map} - Profile Details with necessary fields and defaults
 */
const getProfileDetailsForCurrentUser = currentUser => {
  if (!currentUser) {
    return DEFAULT_ACCOUNT_SETTINGS;
  }

  return DEFAULT_ACCOUNT_SETTINGS.merge(
    currentUser.filter(
      (val, key) => FORM_FIELDS.DIALOG.indexOf(key) !== -1 && !isNull(val),
    ),
  );
};

/**
 * Component for displaying the profile details form as a dialog
 */
class ProfileDetailsDialog extends React.Component {
  static propTypes = {
    currentUser: PropTypes.instanceOf(Immutable.Map),
    termsOfService: PropTypes.instanceOf(Immutable.Map),
    isFirstSignin: PropTypes.bool.isRequired,
    /** Passed in function to resolve deferred if needed */
    onResolve: PropTypes.func,
    /** Passed in boolean to add wrapper function if needed for spacing */
    includeWrapper: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    // Bind event handlers to component instance
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);

    // Set the initial state with profile details
    this.state = {
      profileDetails: getProfileDetailsForCurrentUser(props.currentUser),
      errors: {},
      isSubmitting: false,
    };
  }

  UNSAFE_componentWillReceiveProps({ currentUser }) {
    this.setState({
      profileDetails: getProfileDetailsForCurrentUser(currentUser),
    });
  }

  /**
   * Handle changes to form fields.
   * @param {Object} change - Event object
   * @param {Object} change.target - Form field being changed
   * @param {String} change.target.value - Value of form field being changed
   */
  handleChange(change) {
    this.setState(({ profileDetails }) => ({
      profileDetails: profileDetails.merge(change),
    }));
  }

  /**
   * Handles when the user clicks "Submit".
   */
  handleSubmit() {
    // Validate form inputs
    const errors = this.validate();

    // Handle form submission
    if (!Object.values(errors).includes(true)) {
      // Clear validation errors
      this.setState({
        errors: {},
        isSubmitting: true,
      });

      // Save user entity with new properties
      UserActions.save(this.state.profileDetails.toJS())
        // Save terms-of-service data. When a user a clicks save they implicitly accept the terms-of-service.
        // We're saving the Terms-of-service agreement synchronously to prevent duplicate sales records from being
        // created in the backend.
        .then(() =>
          TermsOfServiceActions.save(
            { id: this.props.currentUser.get('id'), has_accepted_tos: true },
            { forcePost: true },
          ),
        )
        .then(() => {
          if (this.props.onResolve) {
            this.props.onResolve();
          }
          ui.hideDialog();
          ui.showNotification({
            message: tr('Your settings have been saved.'),
          });
        })
        .always(() => {
          this.setState({
            isSubmitting: false,
          });
        });
    } else {
      this.setState({ errors });
    }
  }

  /**
   * Validates form data to ensure all fields are valid
   * and filled out (if applicable).
   */
  validate() {
    return {
      jobDepartmentError:
        this.state.profileDetails.get('job_department') === UNSELECTED,
      firstNameError:
        !this.state.profileDetails.get('first_name') ||
        !this.state.profileDetails.get('first_name'),
      lastNameError:
        !this.state.profileDetails.get('last_name') ||
        !this.state.profileDetails.get('last_name'),
      jobRoleError: this.state.profileDetails.get('job_role') === UNSELECTED,
      phoneNumberError:
        !!this.state.profileDetails.get('phone_number') &&
        this.state.profileDetails.get('phone_number.length') > 0 &&
        !PhoneNumberValidator.extractPhoneNumber(
          this.state.profileDetails.get('phone_number'),
        ),
      countryError:
        !this.state.profileDetails.get('country') ||
        this.state.profileDetails.get('country') ===
          SUPPORTED_COUNTRIES.EMPTY.value,
      communicationPreferenceError:
        this.state.profileDetails.get('communication_preference') ===
        COMMUNICATION_PREFS.EMPTY.value,
    };
  }

  /**
   * Primary terms of service text comes in from the server. This forces us to add privacy, terms, and unsubscribe links dynamically.
   */
  addTermsLinks(content) {
    const { termsOfService } = this.props;
    const TOS_TOKEN = 'Terms of Service';
    const PRIVACY_TOKEN = 'Privacy Policy';
    const UNSUBSCRIBE_TOKEN = 'unsubscribe';
    const unsubscribeLink = 'http://pages.optimizely.com/Unsubscribe-Page.html';
    const parts =
      typeof content === 'string'
        ? content.split(/(Terms of Service|Privacy Policy|unsubscribe)/gi)
        : [];
    return parts.map(part => {
      if (part === TOS_TOKEN) {
        return (
          <Link
            href={termsOfService.get('terms_of_service_link')}
            newWindow={true}
            testSection="welcome-terms-of-service-link">
            {TOS_TOKEN}
          </Link>
        );
      }
      if (part === PRIVACY_TOKEN) {
        return (
          <Link
            href={termsOfService.get('privacy_policy_link')}
            newWindow={true}
            testSection="welcome-privacy-policy-link">
            {PRIVACY_TOKEN}
          </Link>
        );
      }
      if (part === UNSUBSCRIBE_TOKEN) {
        return (
          <Link
            href={unsubscribeLink}
            newWindow={true}
            testSection="welcome-unsubscribe-link">
            {UNSUBSCRIBE_TOKEN}
          </Link>
        );
      }
      return part;
    });
  }

  /**
   * Primary terms of service text comes in from the server. This forces us to add privacy, terms, and unsubscribe links dynamically.
   * First we get the text needed for this user (users from certain countries will require addtional text). Then we parse the text with
   * addTermsLinks() to add the linking.
   */
  renderTerms() {
    const { profileDetails } = this.state;
    const { termsOfService } = this.props;
    const ADDITIONAL_TOS_TEXT = TERMS_OF_SERVICE_TEXT[
      profileDetails.get('communication_preference')
    ]
      ? `${
          TERMS_OF_SERVICE_TEXT[profileDetails.get('communication_preference')]
        }`
      : '';
    return (
      <React.Fragment>
        <div>{this.addTermsLinks(termsOfService.get('text'))}</div>
        {ADDITIONAL_TOS_TEXT && (
          <div>{this.addTermsLinks(ADDITIONAL_TOS_TEXT)}</div>
        )}
      </React.Fragment>
    );
  }

  isUserManagedByAdminCenter = () => {
    const accountAdminCenterRoleId = config.get(
      'account_info.account_admin_center_role_id',
    );
    return (
      // eslint-disable-next-line no-eq-null
      accountAdminCenterRoleId != null
    );
  };

  render() {
    const { isSubmitting } = this.state;

    const greeting = GREETINGS.DEFAULT;
    const title = TITLES.DEFAULT;

    const dialogForm = (
      <div>
        <ProfileDetailsForm
          description={DESCRIPTION}
          errors={this.state.errors}
          fields={FORM_FIELDS.DIALOG}
          formData={this.state.profileDetails}
          onChange={this.handleChange}
          title={title}
          isManagedByAdminCenter={this.isUserManagedByAdminCenter()}
        />
        <p
          className="text--left push-double--top soft-double--bottom border--bottom push-double--bottom"
          data-test-section="welcome-terms-of-service-text">
          {this.renderTerms()}
        </p>
        <div className="lego-button-row lego-button-row--right">
          <Button
            isLoading={isSubmitting}
            onClick={this.handleSubmit}
            style="highlight"
            testSection="profile-details-dialog-save">
            {tr('Save')}
          </Button>
        </div>
      </div>
    );

    let dialogContents = null;
    if (this.props.includeWrapper) {
      dialogContents = (
        <div className="max-width--large push-quad--top">{dialogForm}</div>
      );
    } else {
      dialogContents = dialogForm;
    }

    // Render dialog
    return (
      <div className="width--1-1">
        <ProfileDetailsDialogHeader title={greeting} />
        <div className="reading-column">
          <div className="flex flex--1 flex--column flex-align--center">
            {dialogContents}
          </div>
        </div>
      </div>
    );
  }
}

export default ui.connectGetters(ProfileDetailsDialog, {
  currentUser: UserGetters.currentUser,
  isFirstSignin: ProfileDetailsGetters.isFirstSignin,
  termsOfService: TermsOfServiceGetters.first,
});

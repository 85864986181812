const template = require('./template.html');

const ui = require('core/ui').default;

const FeatureDialogReactComponent = require('./main_content').default;

const CurrentProject = require('optly/modules/current_project');

module.exports = {
  componentId: 'feature-dialog',

  replace: true,

  template,

  ready() {
    ui.renderReactComponent(this, {
      component: FeatureDialogReactComponent,
      el: this.$$.reactContainer,
      dataBindings: {
        language: CurrentProject.getters.primarySDKLanguage,
      },
      props: {
        // @TODO(mng): add proper permisison check here
        canCreateFeature: true,
        currentProjectFeatures: this.$options.currentProjectFeatures,
        feature: this.$options.feature,
        save: this.$options.save,
      },
    });
  },
};

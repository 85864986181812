import React from 'react';
import PropTypes from 'prop-types';

import SelectDropdown from 'react_components/select_dropdown';

import Immutable from 'optly/immutable';
import RecommenderConstants from 'optly/modules/entity/recommender/constants';

import { RecommendationsHelpLink } from 'bundles/p13n/components/messaging/recommendations';

import SectionModuleFns from 'bundles/p13n/sections/implementation/section_module/fns';

const AlgorithmPicker = ({ handleAlgorithmChange, recommender }) => (
  <div
    className="push-triple--bottom"
    data-test-section="recommender-algorithm-picker">
    <h4>Algorithm</h4>
    <div
      className="push--bottom"
      data-test-section="algorithm-picker-help-text">
      Choose the algorithm that best suits how you want recommendations
      calculated for your users.&nbsp;
      <RecommendationsHelpLink
        helpLink={SectionModuleFns.getHelpCopy('recommender_algorithm_link')}
        testSection="recommender-dialog-algorithm-picker"
      />
    </div>
    <SelectDropdown
      inputPlaceholder="Select an algorithm..."
      items={RecommenderConstants.AlgorithmSelectorTexts}
      minDropdownWidth={300}
      onChange={handleAlgorithmChange}
      testSection="algorithm-picker-dropdown"
      value={recommender.get('algorithm')}
      width={200}
    />
  </div>
);

AlgorithmPicker.propTypes = {
  handleAlgorithmChange: PropTypes.func.isRequired,
  recommender: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default AlgorithmPicker;

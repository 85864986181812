import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import flux from 'core/flux';
import ui from 'core/ui';
import { loadingWhenFetchAllPages } from 'core/modules/loading/actions';
import ChampagneEnums from 'optly/modules/optimizely_champagne/enums';
import AudienceActions from 'optly/modules/entity/audience/actions';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import FeatureFlagActions from 'optly/modules/entity/feature_flag/actions';
import SectionModuleEnums from 'bundles/p13n/sections/features/section_module/enums';
import LayerEnums from 'optly/modules/entity/layer/enums';
import LayerExperimentActions from 'optly/modules/entity/layer_experiment/actions';
import EnvironmentActions from 'optly/modules/entity/environment/actions';
import RoutingFns from 'bundles/p13n/routing_fns';

import PageComponent from './main_content';

// named exports
export const routingSetup = function(ctx, next) {
  const currentProjectId = flux.evaluate(CurrentProjectGetters.id);
  const byProject = {
    project_id: currentProjectId,
  };
  // This is used in the feature dialog so we can defer and not wait for it
  LayerExperimentActions.fetchAll(
    {
      project_id: byProject.project_id,
      // Only want single experiment layer experiments, not layer experiments that
      // are targeting rules inside a rollout
      layer_policy: LayerEnums.policy.SINGLE_EXPERIMENT,
    },
    { skipEvaluatingCachedData: true },
  );

  const featureFlagsFetchAllPages = FeatureFlagActions.fetchAllByArchivedState(
    currentProjectId,
  );
  loadingWhenFetchAllPages(
    SectionModuleEnums.TABLE_ID,
    featureFlagsFetchAllPages,
  );

  if (
    !isFeatureEnabled(ChampagneEnums.FEATURES.audience_combo_reskin.FEATURE_KEY)
  ) {
    ui.loadingWhen('fetchAudiences', AudienceActions.fetchAll(byProject));
  }

  RoutingFns.fetchJiraLinkedIssuesData('feature');

  Promise.all([
    featureFlagsFetchAllPages.firstPage,
    EnvironmentActions.fetchAll(byProject),
  ]).then(() => {
    next();
  });
};

export const component = PageComponent;

// default export
export default {
  routingSetup,
  component,
};

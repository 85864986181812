/**
 * Holds the state of the selected table items for the dashboard
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */

import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import { actionTypes } from '../action_types';

export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      selectedItems: {
        experiments: [],
        audiences: [],
        activities: [],
        dimensions: [],
        groups: [],
        integrations: [],
        collaborators: [],
        projects: [],
        labs: [],
      },
    });
  },

  initialize() {
    this.on(actionTypes.DASHBOARD_SELECT_TABLE_ITEM, selectItem);
    this.on(actionTypes.DASHBOARD_DESELECT_TABLE_ITEM, deselectItem);
  },
});

/**
 * Switches the main tab state
 * @param {Object} payload
 * @param {Number} payload.itemId
 * @param {String} payload.category
 * @param {Boolean?} payload.multiple
 */
function selectItem(state, payload) {
  const { category } = payload;
  const id = payload.itemId;
  const { multiple } = payload;

  // if there is no list for the category or not multiple then just make
  // the selection a list of the [id]
  if (!state.getIn(['selectedItems', category]) || !multiple) {
    return state.setIn(['selectedItems', category], toImmutable([id]));
  }
  return state.updateIn(['selectedItems', category], list => list.push(id));
}

/**
 * Deselects an item
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.category
 * @param {Number} payload.itemId
 */
function deselectItem(state, payload) {
  const { category } = payload;
  const id = payload.itemId;

  if (!state.getIn(['selectedItems', category])) {
    return state;
  }

  return state.updateIn(['selectedItems', category], list => {
    const ind = list.indexOf(id);
    if (ind > -1) {
      return list.delete(ind);
    }
    return list;
  });
}

import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@optimizely/axiom';

const EmptyMetrics = ({ addMetricHandler }) => {
  return (
    <div className="flex flex--column flex-justified--center flex-align--center border-radius push-double--ends empty-metrics-picker">
      <h4 className="push flush--bottom">No Metrics Found</h4>
      <span>Please add a metric to measure the success of this experiment</span>
      <Button
        style="outline"
        className="push-double--top"
        onClick={addMetricHandler}
        leftIcon="add">
        Add Metric
      </Button>
    </div>
  );
};

EmptyMetrics.propTypes = {
  addMetricHandler: PropTypes.func.isRequired,
};

export default EmptyMetrics;

/* eslint-disable react/jsx-pascal-case */
import React from 'react';

// eslint-disable-next-line import/no-unresolved
import { actions as NavActions } from 'NAVBAR';

import flux from 'core/flux';
import Router from 'core/router';

import Events from 'optly/services/events'; // < - should this be in the dynamic bundle?
import {
  fns as LayerFns,
  getters as LayerGetters,
} from 'optly/modules/entity/layer';
import { getters as LayerExperimentGetters } from 'optly/modules/entity/layer_experiment';
import NavConstants from 'optly/services/navigation';

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';

import RoutingFns from '../../routing_fns';
import { routingFns as PageRoutingFns } from './pages';

const category = 'Results';

/*
 * Routing function that ensures the Results dynamic bundle has been fetched
 * We store the returned dynamic bundle module on the function object itself
 */
function ResultsSection(ctx, next) {
  import(
    /* webpackChunkName: "results-bundle" */
    'bundles/p13n/sections/results' // eslint-disable-line
  )
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(ResultsSection, module);
    })
    .then(next);
}

function setPageTitle({ next, name, params }) {
  next();
  let primaryName = '';
  if (params.item_id) {
    const currentLayer = flux.evaluate(
      LayerGetters.byId(Number(params.item_id)),
    );
    primaryName = !!currentLayer && currentLayer.get('name');
  } else if (params.experiment_id) {
    const currentLayerExperiment = flux.evaluate(
      LayerExperimentGetters.byId(Number(params.experiment_id)),
    );
    primaryName =
      !!currentLayerExperiment && currentLayerExperiment.get('name');
  }

  RoutingFns.setPageTitle({
    category: !primaryName ? category : '',
    name,
    primaryName,
  });
}

/*
 * Routes for the Results section in nuclear-router format
 */
const routes = [
  {
    match: '/v2/projects/:proj_id/results/:item_id',
    metadata: { name: 'Campaign Summary', category },
    handle: [
      [
        ResultsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseShareToken,
        RoutingFns.parseProjectId,
        RoutingFns.fetchLayer,
      ],
      RoutingFns.fetchShareToken,
      PageRoutingFns.setTrackingData,
      (ctx, next) => ResultsSection.pages.p13n_overview.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Campaign Summary',
          params,
        }),
      ctx => {
        const layerId = Number(ctx.params.item_id);
        const layer = flux.evaluateToJS(LayerGetters.byId(layerId));
        const shareToken = flux.evaluate(CurrentLayerGetters.shareToken);
        const params = ctx.queryParams;
        if (shareToken) {
          params.share_token = shareToken;
          NavActions.setNavWidth(NavConstants.NavWidth.HIDDEN);
        }
        if (!LayerFns.isPersonalizationLayer(layer)) {
          RoutingFns.determineResultsUrl(
            ctx.params.proj_id,
            layerId,
            params,
          ).then(url => {
            Router.redirect(url);
          });
        } else {
          Events.trackMarketoEvent('x_results2', 'view', 'results');

          RoutingFns.renderMainRegion(
            <ResultsSection.pages.p13n_overview.component
              previousView={params.previousView}
            />,
          );
        }
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/results/:item_id/audiences/:audience_id',
    handle: [
      [
        ResultsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
        RoutingFns.fetchLayer,
      ],
      ctx => {
        const projectId = Number(ctx.params.proj_id);
        const layerId = Number(ctx.params.item_id);
        // Redirect to either /results or /results/.../experiments
        RoutingFns.determineResultsUrl(projectId, layerId).then(url => {
          Router.redirect(url);
        });
      },
    ],
  },

  {
    match: [
      '/v2/projects/:proj_id/results/:item_id/experiments/:experiment_id',
      '/v2/projects/:proj_id/results/experiments/:experiment_id',
    ],
    metadata: { name: 'Experiment Results', category },
    handle: [
      [
        ResultsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseShareToken,
        RoutingFns.parseProjectId,
        RoutingFns.fetchLayer,
      ],
      RoutingFns.fetchShareToken,
      PageRoutingFns.setTrackingData,
      (ctx, next) => {
        const layer = flux.evaluate(CurrentLayerGetters.layer);
        const isMAB = LayerFns.isMultiArmedBandit(layer);
        ResultsSection.pages.individual.routingSetup(ctx, next, isMAB);
      },
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Experiment Results',
          params,
        }),
      ctx => {
        Events.trackMarketoEvent('x_results2', 'view', 'results');
        const params = ctx.queryParams;
        let component;
        const layer = flux.evaluate(CurrentLayerGetters.layer);
        if (LayerFns.isMultiArmedBandit(layer)) {
          component = (
            <ResultsSection.pages.multiarmed_bandit.component
              previousView={params.previousView}
            />
          );
        } else if (!LayerFns.isPersonalizationLayer(layer)) {
          component = (
            <ResultsSection.pages.abtest.component
              previousView={params.previousView}
            />
          );
        } else {
          component = (
            <ResultsSection.pages.p13n_single_experiment.component
              previousView={params.previousView}
            />
          );
        }

        RoutingFns.renderMainRegion(component);
      },
    ],
  },

  {
    match: [
      '/v2/projects/:proj_id/results/:item_id/experiments/:experiment_id/sections/:section_id',
      '/v2/projects/:proj_id/results/experiments/:experiment_id/sections/:section_id',
    ],
    metadata: { name: 'Section Rollup Results', category },
    handle: [
      [
        ResultsSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseShareToken,
        RoutingFns.parseProjectId,
        RoutingFns.fetchLayer,
      ],
      RoutingFns.fetchShareToken,
      PageRoutingFns.setTrackingData,
      (ctx, next) => ResultsSection.pages.individual.routingSetup(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Section Rollup Results',
          params,
        }),
      ctx => {
        Events.trackMarketoEvent('x_results2', 'view', 'results');
        const params = ctx.queryParams;
        RoutingFns.renderMainRegion(
          <ResultsSection.pages.mvt_rollup.component
            previousView={params.previousView}
          />,
        );
      },
    ],
  },
];

export default routes;

import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export default {
  ...baseEntityGetters,
  byExperimentId(experimentId) {
    return [
      baseEntityGetters.entityCache,
      sectionsMap =>
        sectionsMap
          .toList()
          .filter(
            section =>
              section.get('layer_experiment_id') === experimentId &&
              !section.get('archived'),
          )
          .sortBy(section => section.get('id')),
    ];
  },
};

import Nuclear from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

export default Nuclear.Store({
  getInitialState() {
    return toImmutable({
      now: null,
      xhrs: toImmutable([]),
      traceId: null,
    });
  },

  initialize() {
    this.on(actionTypes.RESULTS_API_ADD_XHR, addXHR);
    this.on(actionTypes.RESULTS_API_CLEAR_XHRS, clearXHRs);
    this.on(actionTypes.RESULTS_API_SET_DATE_NOW, setDateNow);
    this.on(actionTypes.RESULTS_API_SET_TRACE_ID, setTraceId);
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Timestamp} payload.dateNow
 */
function setDateNow(state, payload) {
  return state.set('now', payload.dateNow);
}

function addXHR(state, payload) {
  return state.set('xhrs', state.get('xhrs').push(payload.xhr));
}

function clearXHRs(state) {
  state.get('xhrs').forEach(xhr => {
    if (xhr.readyState !== 4) {
      xhr.abort();
    }
  });

  return state.set('xhrs', toImmutable([]));
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.traceId
 */
function setTraceId(state, payload) {
  return state.set('traceId', payload.traceId);
}

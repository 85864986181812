import config from 'atomic-config';

import Immutable from 'optly/immutable';

import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(definition);

export default Object.assign({}, baseEntityActions, {
  /**
   * Utility method to perform a fetchAll with filter in the form:
   *   GET /api/v1/projects/<project_id>/interest_groups?filter=search_string:money_savings

   * @param searchString
   * @returns {Promise}
   */
  search(projectId, searchString) {
    return this.fetchAll({
      project_id: projectId,
      search_string: searchString,
    }).then(data => Immutable.fromJS(data));
  },

  /**
   * Validate the provided list of keywords with the Control Tower.
   * @param {Array} keywordList
   * @returns {Promise}
   */
  validateKeywords(keywordList) {
    return fetch(
      `${config.get('env.CONTROL_TOWER_HOST')}/v1/interests/words/validate`,
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(keywordList), // body data type must match "Content-Type" header
      },
    ).then(
      response => {
        if (response.ok) {
          return response.json(); // parses response to JSON
        }
        throw new Error(
          'Control Tower returned a non OK response on keyword validation.',
        );
      },
      () => {
        throw new Error('Error contacting Control Tower to validate keywords.');
      },
    );
  },
});

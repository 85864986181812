import { Store } from 'nuclear-js';
import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

const DEFAULT_FILTER = toImmutable({
  string: '',
});

/**
 * filterableTableStore
 */
export default Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({
      defaultFilter: DEFAULT_FILTER,
      defaults: {},
      filters: {},
    });
  },

  initialize() {
    this.on(actionTypes.FILTERABLE_TABLE_RESET_FILTER, resetFilter);
    this.on(actionTypes.FILTERABLE_TABLE_SET_FILTER, setFilter);
    this.on(actionTypes.FILTERABLE_TABLE_SET_FILTER_DEFAULT, setFilterDefault);
  },
});

/**
 * Set the default filter function for a given tableId
 * @private
 */
function setFilterDefault(state, payload) {
  state = state.setIn(
    ['defaults', payload.tableId],
    toImmutable(payload.filter),
  );
  if (!state.getIn(['filters', payload.tableId])) {
    state = state.setIn(
      ['filters', payload.tableId],
      toImmutable(payload.filter),
    );
  }
  return state;
}

/**
 * Reset filter values to defaults for a particular table
 */
function resetFilter(state, payload) {
  const filterDefaults =
    state.getIn(['defaults', payload.tableId]) || DEFAULT_FILTER;
  return state.setIn(['filters', payload.tableId], filterDefaults);
}

/**
 * Sets filters for a table, will leave existing filters in
 * if the payload.filters doesnt overwrite
 */
function setFilter(state, payload) {
  const currentFilters = state.getIn(
    ['filters', payload.tableId],
    toImmutable({}),
  );
  const newFilters = currentFilters.merge(toImmutable(payload.filter));
  return state.setIn(['filters', payload.tableId], newFilters);
}

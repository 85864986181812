import flux from 'core/flux';

import RegionGetters from 'core/ui/region/getters';

/**
 * Retrieves the root Vue VM from the flux store
 * @return {Vue}
 */
export default function getMainRegionVM() {
  return flux.evaluate(RegionGetters.mountedComponent('main'));
}

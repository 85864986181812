/**
 * Entity Definition for links
 */
export default {
  entity: 'links',

  parent: {
    entity: 'projects',
    key: 'project_id',
  },

  deserialize(data) {
    if (data.results) {
      return data.results;
    }
    return data;
  },
};

import React from 'react';

import flux from 'core/flux';
import Router from 'core/router';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import NavConstants from 'optly/services/navigation';
import RoutingFns from 'bundles/p13n/routing_fns';
import EventsDashboard from '../web_events/pages/dashboard';
import FilterableTableEnums from 'optly/modules/filterable_table/enums';
import BundleSplitHelper from 'optly/utils/bundle_split_helper';

import { pages } from '.';

const category = 'Metrics Hub';

function MetricsHubSection(ctx, next) {
  import(/* webpackChunkName: "metrics_hub-bundle" */ 'bundles/p13n/sections/metrics_hub')
    .then(module => {
      Object.assign(MetricsHubSection, module);
      next(); 
    })
    .catch(error => {
      console.error('Error loading MetricsHubSection:', error);
      Router.redirect('/error'); 
    });
}


let OasisImplementationSection;
const EnsureOasisBundle = (ctx, next) => {
  BundleSplitHelper.getOasisBundleModules()
    .then(modules => {
      ({ OasisImplementationSection } = modules);
    })
    .then(next);
};
export const routes = [
  {
    match: '/v2/projects/:proj_id/metrics',
    metadata: { name: 'MetricsHub', category },
    handle: [
      [
        (ctx, next) => {
          MetricsHubSection(ctx, next);
        },
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.METRICS,
          NavConstants.MetricsHubTabs.METRICS_TAB,
        ),
        RoutingFns.parseProjectId,
      ], 
      (_ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Metrics Hub',
          category,
        }),
      () => {
        RoutingFns.renderMainRegion(<pages.metrics.component />);
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/metrics/events/:event_id?/:event_type?',
    metadata: { name: 'Events', category },
    handle: [
      [
        RoutingFns.parseProjectId,
        EnsureOasisBundle,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.METRICS, 
          NavConstants.MetricsHubTabs.EVENTS_TAB,
        ),
      ],
      (ctx, next) => {
        RoutingFns.parseQueryFilters(
          FilterableTableEnums.tableIds.P13N_DATA_LAYER_EVENTS,
        )(ctx, next);
      },
      RoutingFns.fetchEventImplementationData,
      (ctx, next) => {
        OasisImplementationSection.pages.EventsDashboard.routingSetup(ctx, next);
      },
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Events',
          category
        }),
      async (ctx, next) => {
        const eventId = Number(ctx.params.event_id);
        const eventType = ctx.params.event_type;
        const isWebProject = await flux.evaluate(
          CurrentProjectGetters.isWebProject,
        );
        const componentToLoad = isWebProject ? (
          <EventsDashboard eventId={eventId} eventType={eventType} />
        ) : (
          <OasisImplementationSection.pages.EventsDashboard.pageComponent />
        );
        RoutingFns.renderMainRegion(componentToLoad);
      },
    ],
  },

];

export default routes;
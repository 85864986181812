/**
 * Filter for formatting money across countries.
 * Requires BillingInfo to be loaded into `entityCache` before using.
 * Defaults to USD.
 */
const flux = require('core/flux');
const BillingInfoGetters = require('optly/modules/entity/billing_info/getters');
const tr = require('optly/translate');

module.exports = function(amount) {
  const currencySymbolsWithoutTrailingZeros = [
    '\u00A5', // JPY
  ];

  const billingInfo = flux.evaluateToJS(BillingInfoGetters.currentBillingInfo);
  let formattedMoneyWithoutSymbol;
  if (!billingInfo) {
    formattedMoneyWithoutSymbol = tr.currency(amount);
    // tr.currency returns a generic currency symbol that we can replace with the user's correct symbol.
    return formattedMoneyWithoutSymbol.replace('¤', '$');
  }

  const symbol = billingInfo.currency_symbol;
  formattedMoneyWithoutSymbol = tr.currency(
    amount,
    currencySymbolsWithoutTrailingZeros.indexOf(symbol) !== -1,
  );
  return formattedMoneyWithoutSymbol.replace('¤', symbol);
};

/**
 * Third Party Condition Text Field Component
 * Responsible for rendering the configuration UI for a third-party text condition
 */
const _ = require('lodash');

const baseComponent = require('./base');

module.exports = _.extend({}, baseComponent, {
  template:
    '<input type="text" v-model="condition.value" class="lego-text-input" required />',
});

import React from 'react';
import PropTypes from 'prop-types';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import { connect } from 'core/ui/decorators';

import NavConstants from 'optly/services/navigation';
import PermissionsGetters from 'optly/modules/permissions/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import DashboardHeader from 'react_components/dashboard_header';
import UrlHelper from 'optly/services/url_helper';
import { Features } from 'optly/utils/enums';

@connect({
  canUseMutex: PermissionsGetters.canUseMutex,
  canUseExperiments: PermissionsGetters.canUseExperiments,
  canUsePersonalization: PermissionsGetters.canUsePersonalization,
  currentProjectId: CurrentProjectGetters.id,
  currentProjectName: CurrentProjectGetters.name,
})
class LayersTopbar extends React.Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    canUseExperiments: PropTypes.bool.isRequired,
    canUseMutex: PropTypes.bool.isRequired,
    canUsePersonalization: PropTypes.bool.isRequired,
    currentProjectId: PropTypes.number.isRequired,
    currentProjectName: PropTypes.string.isRequired,
  };

  render() {
    const {
      activeTab,
      canUseExperiments,
      canUseMutex,
      canUsePersonalization,
      currentProjectName,
      currentProjectId,
    } = this.props;
    const title =
      isFeatureEnabled(Features.M1_P13N) && canUsePersonalization
        ? 'Optimizations'
        : 'Experiments';
    return (
      <DashboardHeader
        projectName={currentProjectName}
        title={title}
        testSection="layers-header"
        activeTab={activeTab}
        tabs={[
          ...(canUseExperiments
            ? [
                {
                  title: 'Overview',
                  url: UrlHelper.xWebHome(currentProjectId),
                  id: NavConstants.LayersTabs.LAYERS,
                  testSection: 'layers-overview-tab',
                },
              ]
            : []),
          ...(isFeatureEnabled(Features.M1_P13N) && canUsePersonalization
            ? [
                ...(canUseExperiments
                  ? [
                      {
                        title: 'Experiments',
                        url: UrlHelper.experiments(currentProjectId),
                        id: NavConstants.LayersTabs.EXPERIMENTS,
                        testSection: 'layers-experiments-tab',
                      },
                    ]
                  : []),
                {
                  title: 'Personalization Campaigns',
                  url: UrlHelper.personalizations(currentProjectId),
                  id: NavConstants.LayersTabs.PERSONALIZATIONS,
                  testSection: 'layers-personalizations-tab',
                },
              ]
            : []),
          {
            title: 'Exclusion Groups',
            url: UrlHelper.groups(currentProjectId),
            id: NavConstants.LayersTabs.GROUPS,
            testSection: 'groups-tab',
            isVisible: canUseMutex,
          },
        ]}
      />
    );
  }
}

export default LayersTopbar;

const {
  getters: CurrentLayerGetters,
} = require('bundles/p13n/modules/current_layer');
const {
  getters: CurrentProjectGetters,
} = require('optly/modules/current_project');
const LayerFns = require('optly/modules/entity/layer/fns');
const PermissionsModuleFns = require('optly/modules/permissions/fns');
const UrlHelper = require('optly/services/url_helper').default;

exports.canPublishLayer = [
  CurrentProjectGetters.project,
  PermissionsModuleFns.canPublishLayer.bind(PermissionsModuleFns),
];

exports.disablePublishButton = hasUpdatesToPublishGetter => [
  CurrentProjectGetters.isUsingV2Snippet,
  exports.canPublishLayer,
  CurrentLayerGetters.layer,
  hasUpdatesToPublishGetter,
  CurrentLayerGetters.hasLayerMetrics,
  CurrentLayerGetters.allExperimentsPointingToLayer,
  (
    isUsingV2Snippet,
    canPublishLayer,
    currentLayer,
    hasUpdatesToPublish,
    hasLayerMetrics,
    allExperimentsPointingToLayer,
  ) =>
    !isUsingV2Snippet ||
    !canPublishLayer ||
    (currentLayer && currentLayer.get('archived')) ||
    (currentLayer && LayerFns.hasLayerConcluded(currentLayer)) ||
    !hasUpdatesToPublish ||
    !hasLayerMetrics ||
    !allExperimentsPointingToLayer.size,
];

exports.layersHomeUrl = [
  CurrentProjectGetters.id,
  currentProjectId => UrlHelper.xWebHome(currentProjectId),
];

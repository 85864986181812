import flux from 'core/flux';

import AccountDashboardStores from 'bundles/p13n/sections/account_dashboard/section_module/stores';
import CampaignOverviewStores from 'bundles/p13n/sections/campaign_overview/section_module/stores';
import FullStackOnboardingStores from 'bundles/p13n/sections/full_stack_onboarding/section_module/stores';
import LayerDialogReactStores from 'bundles/p13n/sections/layers/pages/layers_dashboard/components/layer_dialog_react/component_module/stores';
import ManagerFullstackStores from 'bundles/p13n/sections/manager_fullstack/section_module/stores';
import CurrentlyEditingPluginStores from 'bundles/p13n/sections/plugin_builder/modules/currently_editing_plugin/stores';
import PluginEditorStores from 'bundles/p13n/sections/plugin_builder/pages/plugin_editor/page_module/stores';
import CollaboratorsStores from 'bundles/p13n/sections/project_settings/pages/collaborators/page_module/stores';
import IntegrationsStores from 'bundles/p13n/sections/project_settings/pages/integrations/page_module/stores';
import ImplementationStores from 'bundles/p13n/sections/implementation/section_module/stores';
import ResultsStores from 'bundles/p13n/sections/results/section_module/stores';
import EditorEventSidebarStores from 'bundles/p13n/sections/views/pages/editor/components/event_sidebar/component_module/stores';
import EditorTagSidebarStores from 'bundles/p13n/sections/views/pages/editor/components/tag_sidebar/component_module/stores';
import EditorStores from 'bundles/p13n/sections/views/pages/editor/page_module/stores';
import SummaryStore from 'bundles/p13n/sections/account_dashboard/pages/mau_dashboard/modules/summary/store';

export const stores = {
  ...AccountDashboardStores,
  ...CampaignOverviewStores,
  ...FullStackOnboardingStores,
  ...LayerDialogReactStores,
  ...ManagerFullstackStores,
  ...CurrentlyEditingPluginStores,
  ...PluginEditorStores,
  ...CollaboratorsStores,
  ...IntegrationsStores,
  ...ImplementationStores,
  ...ResultsStores,
  ...EditorEventSidebarStores,
  ...EditorTagSidebarStores,
  ...EditorStores,
  ...SummaryStore,
};

export default function initialize() {
  flux.registerStores(stores);
}

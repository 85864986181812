import $ from 'jquery';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import flux from 'core/flux';
import ui from 'core/ui';

import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import {
  getters as UserGetters,
  actions as UserActions,
} from 'optly/modules/entity/user';

import { COMMUNICATION_VALUES } from 'optly/modules/profile_details/constants';
import profileDetailsDialog from 'optly/components/profile_details/profile_details_dialog';
import { actions as TermsOfServiceActions } from 'optly/modules/entity/terms_of_service';

import actionTypes from './action_types';
import getters from './getters';

let ProfileDetailsActions;

/**
 * Sets whether the user is signed in for the first time.
 */
export const setIsFirstSignin = (isFirstSignin = true) => {
  flux.dispatch(actionTypes.SET_IS_FIRST_SIGNIN, { isFirstSignin });
};

/**
 * Sets whether the dialog is visible.
 * @param {Boolean} visible - Whether or not the dialog is visible
 */
export const setIsDialogVisible = visible => {
  flux.dispatch(actionTypes.SET_IS_DIALOG_VISIBLE, { visible });
};

/**
 * Performs initial check for Profile Details completion. Executed on load.
 */
export const performInitialCompletionCheck = () => {
  const userEmailAddress = flux.evaluate(AdminAccountGetters.email);
  // If there's an email address and it's not a share_token auth'ed page load, get preferences.
  if (userEmailAddress && window.location.href.indexOf('share_token') === -1) {
    return UserActions.fetch(userEmailAddress).then(profileDetails => {
      const requiredInfoWasSupplied =
        !!profileDetails &&
        !!profileDetails.first_name &&
        profileDetails.first_name.length > 0 &&
        !!profileDetails.last_name &&
        profileDetails.last_name.length > 0 &&
        !!profileDetails.job_role &&
        !!profileDetails.job_department &&
        !!profileDetails.communication_preference &&
        (isFeatureEnabled('require_country_on_sign_in')
          ? !!profileDetails.country &&
            Object.keys(COMMUNICATION_VALUES)
              .map(k => COMMUNICATION_VALUES[k])
              .indexOf(profileDetails.communication_preference) >= 0
          : true);
      flux.dispatch(actionTypes.CHECK_PROFILE_DETAILS, {
        requiredInfoWasSupplied,
      });
      return requiredInfoWasSupplied;
    });
  }
  // We don't have an email so resolve immediately
  // This occurs if the user is signed out
  return $.Deferred().resolve();
};

export const showCollaboratorDetailsDialog = includeWrapper => {
  setIsDialogVisible(true);
  const dialogDef = $.Deferred();
  const dialogConfig = {
    props: {
      onResolve() {
        dialogDef.resolve();
      },
      includeWrapper,
    },
  };
  const dialogOptions = {
    fullScreen: true,
    noEscape: true,
    dismissOnBack: false,
  };

  ui.showReactDialog(profileDetailsDialog, dialogConfig, dialogOptions);

  return dialogDef;
};

export const maybeShowCollaboratorDetailsDialog = () => {
  const emailVerified =
    flux.evaluate(UserGetters.currentUser) &&
    flux.evaluate(UserGetters.currentUser).get('email_verified');

  if (!emailVerified) {
    return;
  }

  ProfileDetailsActions.performInitialCompletionCheck().then(() => {
    // If the required info was not supplied, show ProfileDetailsDialog.
    const requiredInfoWasSupplied = flux.evaluate(
      getters.requiredInfoWasSupplied,
    );
    if (!requiredInfoWasSupplied) {
      // Fetch terms-of-service info for the welcome dialog
      TermsOfServiceActions.fetchAll().then(() => {
        ProfileDetailsActions.showCollaboratorDetailsDialog();
      });
    }
  });
};

export default ProfileDetailsActions = {
  setIsFirstSignin,
  setIsDialogVisible,
  performInitialCompletionCheck,
  showCollaboratorDetailsDialog,
  maybeShowCollaboratorDetailsDialog,
};

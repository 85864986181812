import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'optimizely-oui';

import { fieldPropType } from 'react_components/form';

class TrafficInput extends React.PureComponent {
  static propTypes = {
    entityName: PropTypes.string,
    field: fieldPropType.isRequired,
    isSelectedGroupNone: PropTypes.bool,
  };

  static defaultProps = {
    entityName: 'experiment',
    isSelectedGroupNone: false,
  };

  handleChange = e => {
    const { field } = this.props;
    field.setValue(Math.round(Number(e.target.value) * 100));
  };

  render() {
    const { entityName, isSelectedGroupNone, field } = this.props;

    const percentageIncludedDescription = isSelectedGroupNone
      ? `Fraction of total traffic to include in your ${entityName}:`
      : `Fraction of this group's total visitors who should see this ${entityName}:`;

    const percentageIncluded = Number(field.getValue()) / 100;

    return (
      <li className="lego-form-field__item soft--top">
        <p data-test-section="percentage-included-description">
          {percentageIncludedDescription}
        </p>
        <div className="flex flex-align--center width--75">
          <Input
            min="0"
            max="100"
            type="number"
            onChange={this.handleChange}
            testSection="group-allocation-input"
            value={percentageIncluded}
          />
          <span className="push-half--left">%</span>
        </div>
      </li>
    );
  }
}

export default TrafficInput;

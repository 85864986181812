import createEntityGetters from 'optly/modules/rest_api/create_entity_getters';
import Immutable from 'optly/immutable';

import definition from './entity_definition';

const baseEntityGetters = createEntityGetters(definition);

export const { byId, entityCache } = baseEntityGetters;

export function settingsByIntegrationId(integrationId) {
  return [
    entityCache,
    projectIntegrationSettings =>
      projectIntegrationSettings
        .toList()
        .filter(setting => setting.get('integration_id') === integrationId) ||
      Immutable.List(),
  ];
}

export default {
  ...baseEntityGetters,
  settingsByIntegrationId,
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Radio,
  OverlayWrapper,
  Link,
  Label,
  Sheet,
  Popover,
  Icon,
} from 'optimizely-oui';

import ui from 'core/ui';
import Router from 'core/router';
import { connect } from 'core/ui/decorators';
import { toImmutable } from 'optly/immutable';
import urls from 'optly/services/url_helper_legacy';

import LoadingOverlay from 'react_components/loading_overlay';
import {
  humanReadable as EventModuleHumanReadable,
  enums as EventModuleEnums,
  actions as EventModuleActions,
} from 'optly/modules/entity/event';
import { actions as CurrentEventActions } from 'bundles/p13n/modules/current_event';
import NavConstants from 'optly/services/navigation';
import { actions as ViewActions } from 'optly/modules/entity/view';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { actions as P13NDashboardActions } from 'bundles/p13n/modules/dashboard';

import ConfigureViewSmartComponent from 'bundles/p13n/components/configure_view_smart';
import ConfigureCustomEventComponent from 'bundles/p13n/components/data_layer/configure_custom_event';
import CreateClickEventComponent from 'bundles/p13n/components/data_layer/create_click_event';

@connect({
  currentProjectId: CurrentProjectGetters.id,
})
class CreateEventComponent extends React.Component {
  static propTypes = {
    clickEventsDisabled: PropTypes.bool.isRequired,
    currentProjectId: PropTypes.number.isRequired,
    onSave: PropTypes.func,
  };

  static defaultProps = {
    onSave() {},
  };

  constructor(props) {
    super(props);
    const { clickEventsDisabled } = this.props;
    this.state = {
      selectedEventType: !clickEventsDisabled
        ? EventModuleEnums.eventTypes.CLICK
        : EventModuleEnums.eventTypes.CUSTOM,
    };
  }

  componentDidMount() {
    CurrentEventActions.resetCurrentEventId();
  }

  componentWillUnmount() {
    const { currentProjectId } = this.props;
    const eventViewDeferred = [
      EventModuleActions.fetchAll({ project_id: currentProjectId }, true),
      ViewActions.fetchAll({ project_id: currentProjectId }, true),
    ];
    ui.loadingWhen('createWebEventFromDashboard', eventViewDeferred);
  }

  eventsDashboardUrl() {
    const { currentProjectId } = this.props;
    return urls.dataLayerTab(
      currentProjectId,
      NavConstants.DataLayerTabs.EVENT_TAB,
    );
  }

  getClickLabel() {
    const popover = (
      <Popover>
        <p>
          You can easily create a click event using the editor once you have
          created this campaign. You can also create one by going to{' '}
          <Link href={this.eventsDashboardUrl()} newWindow={true}>
            Events{' '}
            <Icon
              name="arrow-up-right-from-square"
              size="small"
              color="color--base"
            />
          </Link>{' '}
          tab.
        </p>
      </Popover>
    );
    const { clickEventsDisabled } = this.props;
    const eventTypeEnums = EventModuleEnums.eventTypes;
    const humanReadableEventTypes = EventModuleHumanReadable.EVENT_TYPES;

    return (
      <Label>
        {humanReadableEventTypes[eventTypeEnums.CLICK]}
        {clickEventsDisabled && (
          <span className="push-half--left">
            <OverlayWrapper
              horizontalAttachment="left"
              shouldHideOnClick={true}
              overlay={popover}
              verticalAttachment="top">
              <Icon name="circle-exclamation" size="small" />
            </OverlayWrapper>
          </span>
        )}
        <div>
          <span
            className={classNames({
              muted: !clickEventsDisabled,
              faint: clickEventsDisabled,
              milli: true,
            })}>
            Track clicks on elements within a page.
          </span>
        </div>
      </Label>
    );
  }

  getCustomLabel = () => {
    const eventTypeEnums = EventModuleEnums.eventTypes;
    const humanReadableEventTypes = EventModuleHumanReadable.EVENT_TYPES;
    return (
      <Label>
        {humanReadableEventTypes[eventTypeEnums.CUSTOM]}
        <div>
          <span className="milli muted">
            Track other kinds of behavior using custom code.
          </span>
        </div>
      </Label>
    );
  };

  getPageViewLabel = () => {
    const eventTypeEnums = EventModuleEnums.eventTypes;
    const humanReadableEventTypes = EventModuleHumanReadable.EVENT_TYPES;
    return (
      <Label>
        {humanReadableEventTypes[eventTypeEnums.PAGE_VIEW]}
        <div>
          <span className="milli muted">
            Track whether a visitor views a certain page.
          </span>
        </div>
      </Label>
    );
  };

  goToEventsDashboard = () => {
    const { currentProjectId } = this.props;
    Router.go(
      urls.dataLayerTab(currentProjectId, NavConstants.DataLayerTabs.EVENT_TAB),
    );
  };

  render() {
    const { onSave, clickEventsDisabled } = this.props;
    const { selectedEventType } = this.state;
    const eventTypeEnums = EventModuleEnums.eventTypes;
    return (
      <Sheet
        title="Create New Event"
        onClose={ui.hideDialog}
        testSection="create-event-page"
        subtitle={
          <p>
            Custom tracking events allow you to capture and report on visitor
            actions or events.{' '}
            <a href="https://support.optimizely.com/hc/en-us/articles/4410289407885-Metrics-in-Optimizely">
              Learn more.
            </a>
          </p>
        }>
        <LoadingOverlay loadingId="createWebEventFromDashboard">
          <div className="overflow-y--auto flex--1">
            <ul className="oui-input-list">
              <li className={clickEventsDisabled ? 'faint' : ''}>
                <Radio
                  testSection="event-type-click"
                  isDisabled={clickEventsDisabled}
                  checked={
                    selectedEventType === EventModuleEnums.eventTypes.CLICK
                  }
                  name="click"
                  labelWeight="bold"
                  label={this.getClickLabel()}
                  onChange={() =>
                    this.setState({
                      selectedEventType: EventModuleEnums.eventTypes.CLICK,
                    })
                  }
                />
              </li>
              <li>
                <Radio
                  testSection="event-type-custom"
                  name="custom"
                  checked={
                    selectedEventType === EventModuleEnums.eventTypes.CUSTOM
                  }
                  labelWeight="bold"
                  label={this.getCustomLabel()}
                  onChange={() =>
                    this.setState({
                      selectedEventType: EventModuleEnums.eventTypes.CUSTOM,
                    })
                  }
                />
              </li>
              <li>
                <Radio
                  testSection="event-type-page-view"
                  name="page-view"
                  checked={
                    selectedEventType === EventModuleEnums.eventTypes.PAGE_VIEW
                  }
                  labelWeight="bold"
                  label={this.getPageViewLabel()}
                  onChange={() =>
                    this.setState({
                      selectedEventType: EventModuleEnums.eventTypes.PAGE_VIEW,
                    })
                  }
                />
              </li>
            </ul>
            {!clickEventsDisabled &&
              selectedEventType === eventTypeEnums.CLICK && (
                <div className="push-quad--top">
                  <CreateClickEventComponent />
                </div>
              )}
            {selectedEventType === eventTypeEnums.CUSTOM && (
              <div className="push-quad--top">
                <ConfigureCustomEventComponent
                  onSave={
                    !clickEventsDisabled ? this.goToEventsDashboard : onSave
                  }
                  isStandAlone={false}
                  noRedirectOnCancel={clickEventsDisabled}
                />
              </div>
            )}
            {selectedEventType === eventTypeEnums.PAGE_VIEW && (
              <div className="push-quad--top">
                <ConfigureViewSmartComponent
                  onCancel={
                    !clickEventsDisabled ? this.goToEventsDashboard : null
                  }
                  onSave={
                    !clickEventsDisabled ? this.goToEventsDashboard : onSave
                  }
                  isStandAlone={false}
                  viewConfiguration={toImmutable(
                    P13NDashboardActions.createView(),
                  )}
                />
              </div>
            )}
          </div>
        </LoadingOverlay>
      </Sheet>
    );
  }
}

export default CreateEventComponent;

const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const actionTypes = require('../action_types');

const INITIAL_STATE = toImmutable({
  config: {},
  formValues: {},
  currentlyEditingSelector: null,
});

module.exports = Nuclear.Store({
  getInitialState() {
    return INITIAL_STATE;
  },

  initialize() {
    this.on(
      actionTypes.LOAD_CURRENTLY_EDITING_PLUGIN,
      loadCurrentlyEditingPlugin,
    );
    this.on(
      actionTypes.UPDATE_CURRENTLY_EDITING_PLUGIN_CONFIG,
      updateCurrentlyEditingPlugin,
    );
    this.on(
      actionTypes.UNSET_CURRENTLY_EDITING_PLUGIN,
      unsetCurrentlyEditingPlugin,
    );
    this.on(actionTypes.SET_VALUE_CURRENTLY_EDITING_PLUGIN, setValue);
    this.on(
      actionTypes.SET_FORM_VALUES_CURRENTLY_EDITING_PLUGIN,
      setFormValues,
    );
    this.on(
      actionTypes.UPDATE_PLUGIN_DEFAULT_FIELD_VALUE,
      setFieldDefaultValue,
    );
    this.on(
      actionTypes.CURRENTLY_EDITING_PLUGIN_SET_EDITING_SELECTOR,
      setCurrentlyEditingSelector,
    );
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Object} payload.pluginConfig
 */
function loadCurrentlyEditingPlugin(state, payload) {
  return (
    state
      .set('config', toImmutable(payload.pluginConfig))
      // load the default values
      .withMutations(mState => {
        payload.pluginConfig.form_schema.forEach(input => {
          if (input.default_value !== undefined) {
            mState.setIn(['formValues', input.name], input.default_value);
          }
        });
      })
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateCurrentlyEditingPlugin(state, payload) {
  return state.update('config', config => config.merge(payload));
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function unsetCurrentlyEditingPlugin(state, payload) {
  return INITIAL_STATE;
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.name
 * @param {String} payload.value
 */
function setValue(state, payload) {
  return state.setIn(['formValues', payload.name], payload.value);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Object} payload.formValues
 */
function setFormValues(state, payload) {
  return state.setIn(['formValues'], toImmutable(payload.formValues));
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.fieldName
 * @param {String|Array} payload.newDefault Default values are strings, except
 * for multi-select fields, which have array default values
 */
function setFieldDefaultValue(state, payload) {
  return state.updateIn(['config', 'form_schema'], schema =>
    schema.map(field => {
      if (field.get('name') === payload.fieldName) {
        return field.set('default_value', toImmutable(payload.newDefault));
      }
      return field;
    }),
  );
}
/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.selectorFieldName
 */
function setCurrentlyEditingSelector(state, payload) {
  return state.set('currentlyEditingSelector', payload.selectorFieldName);
}

export const DEPARTMENTS = {
  ACCOUNT: tr('Account/Procurement'),
  ANALYTICS: tr('Analytics/Data Science'),
  ECOMMERCE: tr('eCommerce'),
  MARKETING: tr('Marketing'),
  MOBILE_DEVELOPMENT: tr('Mobile Development'),
  PRODUCT_MANAGEMENT: tr('Product Management'),
  WEB_DEVELOPMENT: tr('Web Development'),
  OTHER: tr('Other'),
};

/**
 * Allows bidirectional transformation between data and display value
 */
export const DEPARTMENT_DATA_TO_DISPLAY_MAP = {
  [DEPARTMENTS.ACCOUNT]: tr('Accounting/Procurement'),
};

const SOFT_OPT_IN = 'soft-opt-in';
const EXPLICIT_OPT_IN = 'explicit-opt-in';
const EXPLICIT_OPT_OUT = 'explicit-opt-out';
const IMPLICIT_OPT_IN = 'implicit-opt-in';

export const DESCRIPTION = tr(
  'We use this information to provide you and your colleagues a better Optimizely experience. You can update this information in your profile at any time.',
);

export const FORM_FIELDS = {
  DIALOG: [
    'communication_preference',
    'country',
    'first_name',
    'id',
    'is_first_signin',
    'job_department',
    'job_role',
    'last_name',
    'phone_number',
  ],
  PROFILE_PAGE: [
    'communication_preference',
    'country',
    'first_name',
    'id',
    'is_first_signin',
    'job_department',
    'job_role',
    'last_name',
    'password',
    'phone_number',
  ],
};

export const GREETINGS = {
  DEFAULT: tr('Thanks for using Optimizely.'),
};

export const LABELS = {
  COUNTRY: tr('Country'),
  COMMUNICATION_PREF: tr('Can we send you news and updates?'),
  DEPARTMENT: tr('Department'),
  FIRST_NAME: tr('First Name'),
  LAST_NAME: tr('Last Name'),
  EMAIL_ADDRESS: tr('Email Address'),
  PHONE_NUMBER: tr('Phone Number'),
  ROLE: tr('Role'),
};

export const PLACEHOLDERS = {
  FIRST_NAME: tr('First Name'),
  LAST_NAME: tr('Last Name'),
  PHONE_NUMBER: '555-555-5555',
};

export const ROLES = {
  AGENCY: tr('Agency'),
  PRESIDENT: tr('C-level/President'),
  VICE_PRESIDENT: tr('Vice President'),
  DIRECTOR: tr('Director'),
  MANAGER: tr('Manager'),
  SPECIALIST: tr('Specialist/Associate'),
  COORDINATOR: tr('Coordinator'),
  CONSULTANT: tr('Consultant'),
  DEVELOPER: tr('Developer'),
  OTHER: tr('Other'),
};

export const TITLES = {
  DEFAULT: 'Tell us a little about yourself',
};

export const UNSELECTED = tr('Select the best match...');

export const VALIDATION_ERRORS = {
  COMMUNICATION_PREF: tr('Please select a communications preference'),
  COUNTRY: tr('Please select a country'),
  FIRST_NAME: tr('Please provide your first name'),
  LAST_NAME: tr('Please provide your last name'),
  JOB_DEPARTMENT: tr('Which department do you work in?'),
  JOB_ROLE: tr('Which role most closely matches your own?'),
  PHONE_NUMBER: tr('Please provide a valid phone number'),
};

export const TERMS_OF_SERVICE_TEXT = {
  // additional terms-of-service text that will be prepened to the existing TOS text
  [SOFT_OPT_IN]: tr(
    ' You also agree to receive news, product updates, event announcements and other communications from Optimizely and our subsidiaries. You can unsubscribe anytime.',
  ),
};

export const COMMUNICATION_VALUES = {
  SOFT_OPT_IN,
  EXPLICIT_OPT_IN,
  EXPLICIT_OPT_OUT,
  IMPLICIT_OPT_IN,
};

export const COMMUNICATION_PREFS = {
  EMPTY: {
    displayText: tr('Select marketing communication preference...'),
    value: tr(''),
  },
  EXPLICIT_OPT_IN: {
    displayText: tr(
      'Yes, please send me news and updates from Optimizely and our subsidiaries',
    ),
    value: EXPLICIT_OPT_IN,
  },
  EXPLICIT_OPT_OUT: {
    displayText: tr(
      "No, don't send me news and updates from Optimizely and our subsidiaries",
    ),
    value: EXPLICIT_OPT_OUT,
  },
};

export const HIDDEN_COMMUNICATION_PREFS = {
  SOFT_OPT_IN: {
    displayText: tr('Soft opt-in'),
    value: SOFT_OPT_IN,
  },
  IMPLICIT_OPT_IN: {
    displayText: tr('Implict opt-in'),
    value: IMPLICIT_OPT_IN,
  },
};

export const COUNTRY_COMMUNICATION_PREFS = {
  // soft opt-in
  CA: SOFT_OPT_IN, // Canada
  GB: SOFT_OPT_IN, // United Kingdom
  IE: SOFT_OPT_IN, // Ireland
  SE: SOFT_OPT_IN, // Sweden
  NL: SOFT_OPT_IN, // Netherlands
  FR: SOFT_OPT_IN, // France
  LU: SOFT_OPT_IN, // Luxembourg
  AT: SOFT_OPT_IN, // Austria
  CH: SOFT_OPT_IN, // Switzerland
  BE: SOFT_OPT_IN, // Belgium
  ES: SOFT_OPT_IN, // Spain
  NO: SOFT_OPT_IN, // Norway
  FI: SOFT_OPT_IN, // Finland
  DK: SOFT_OPT_IN, // Denmark

  // explicit opt-in
  DE: EXPLICIT_OPT_IN, // Germany
  AU: EXPLICIT_OPT_IN, // Australia
  DEFAULT: EXPLICIT_OPT_IN, // Rest of world

  // implicit opt-in
  US: IMPLICIT_OPT_IN, // United States
};

export const COUNTRY_COMMUNICATION_DEFAULT_PREFS = {
  // Default values for the country dropdown

  // soft opt-in
  CA: SOFT_OPT_IN, // Canada
  GB: SOFT_OPT_IN, // United Kingdom
  IE: SOFT_OPT_IN, // Ireland
  SE: SOFT_OPT_IN, // Sweden
  NL: SOFT_OPT_IN, // Netherlands
  FR: SOFT_OPT_IN, // France
  LU: SOFT_OPT_IN, // Luxembourg
  AT: SOFT_OPT_IN, // Austria
  CH: SOFT_OPT_IN, // Switzerland
  BE: SOFT_OPT_IN, // Belgium
  ES: SOFT_OPT_IN, // Spain
  NO: SOFT_OPT_IN, // Norway
  FI: SOFT_OPT_IN, // Finland
  DK: SOFT_OPT_IN, // Denmark

  // explicit opt-in
  DE: '', // Germany
  AU: '', // Australia
  DEFAULT: '', // Rest of world

  // implicit opt-in
  US: IMPLICIT_OPT_IN, // United States
};

export const SUPPORTED_COUNTRIES = {
  EMPTY: {
    displayText: tr('Select your country...'),
    value: tr(''),
  },
  AF: {
    displayText: tr('Afghanistan'),
    value: tr('AF'),
  },
  AX: {
    displayText: tr('Aland Islands'),
    value: tr('AX'),
  },
  AL: {
    displayText: tr('Albania'),
    value: tr('AL'),
  },
  DZ: {
    displayText: tr('Algeria'),
    value: tr('DZ'),
  },
  AS: {
    displayText: tr('American Samoa'),
    value: tr('AS'),
  },
  AD: {
    displayText: tr('Andorra'),
    value: tr('AD'),
  },
  AO: {
    displayText: tr('Angola'),
    value: tr('AO'),
  },
  AI: {
    displayText: tr('Anguilla'),
    value: tr('AI'),
  },
  AQ: {
    displayText: tr('Antarctica'),
    value: tr('AQ'),
  },
  AG: {
    displayText: tr('Antigua and Barbuda'),
    value: tr('AG'),
  },
  AR: {
    displayText: tr('Argentina'),
    value: tr('AR'),
  },
  AM: {
    displayText: tr('Armenia'),
    value: tr('AM'),
  },
  AW: {
    displayText: tr('Aruba'),
    value: tr('AW'),
  },
  AU: {
    displayText: tr('Australia'),
    value: tr('AU'),
  },
  AT: {
    displayText: tr('Austria'),
    value: tr('AT'),
  },
  AZ: {
    displayText: tr('Azerbaijan'),
    value: tr('AZ'),
  },
  BS: {
    displayText: tr('Bahamas'),
    value: tr('BS'),
  },
  BH: {
    displayText: tr('Bahrain'),
    value: tr('BH'),
  },
  BD: {
    displayText: tr('Bangladesh'),
    value: tr('BD'),
  },
  BB: {
    displayText: tr('Barbados'),
    value: tr('BB'),
  },
  BY: {
    displayText: tr('Belarus'),
    value: tr('BY'),
  },
  BE: {
    displayText: tr('Belgium'),
    value: tr('BE'),
  },
  BZ: {
    displayText: tr('Belize'),
    value: tr('BZ'),
  },
  BJ: {
    displayText: tr('Benin'),
    value: tr('BJ'),
  },
  BM: {
    displayText: tr('Bermuda'),
    value: tr('BM'),
  },
  BT: {
    displayText: tr('Bhutan'),
    value: tr('BT'),
  },
  BO: {
    displayText: tr('Bonaire, Sint Eustatius and Saba'),
    value: tr('BO'),
  },
  BA: {
    displayText: tr('Bosnia and Herzegovina'),
    value: tr('BA'),
  },
  BW: {
    displayText: tr('Botswana'),
    value: tr('BW'),
  },
  BV: {
    displayText: tr('Bouvet Island'),
    value: tr('BV'),
  },
  BR: {
    displayText: tr('Brazil'),
    value: tr('BR'),
  },
  IO: {
    displayText: tr('British Indian Ocean Territory'),
    value: tr('IO'),
  },
  VG: {
    displayText: tr('British Virgin Islands'),
    value: tr('VG'),
  },
  BN: {
    displayText: tr('Brunei'),
    value: tr('BN'),
  },
  BG: {
    displayText: tr('Bulgaria'),
    value: tr('BG'),
  },
  BF: {
    displayText: tr('Burkina Faso'),
    value: tr('BF'),
  },
  MM: {
    displayText: tr('Burma'),
    value: tr('MM'),
  },
  BI: {
    displayText: tr('Burundi'),
    value: tr('BI'),
  },
  KH: {
    displayText: tr('Cambodia'),
    value: tr('KH'),
  },
  CM: {
    displayText: tr('Cameroon'),
    value: tr('CM'),
  },
  CA: {
    displayText: tr('Canada'),
    value: tr('CA'),
  },
  CV: {
    displayText: tr('Cape Verde'),
    value: tr('CV'),
  },
  KY: {
    displayText: tr('Cayman Islands'),
    value: tr('KY'),
  },
  CF: {
    displayText: tr('Central African Republic'),
    value: tr('CF'),
  },
  TD: {
    displayText: tr('Chad'),
    value: tr('TD'),
  },
  CL: {
    displayText: tr('Chile'),
    value: tr('CL'),
  },
  CN: {
    displayText: tr('China'),
    value: tr('CN'),
  },
  CX: {
    displayText: tr('Christmas Island'),
    value: tr('CX'),
  },
  CC: {
    displayText: tr('Cocos (Keeling) Islands'),
    value: tr('CC'),
  },
  CO: {
    displayText: tr('Colombia'),
    value: tr('CO'),
  },
  KM: {
    displayText: tr('Comoros'),
    value: tr('KM'),
  },
  CG: {
    displayText: tr('Congo'),
    value: tr('CG'),
  },
  CD: {
    displayText: tr('Congo, the Democratic Republic of the'),
    value: tr('CD'),
  },
  CK: {
    displayText: tr('Cook Islands'),
    value: tr('CK'),
  },
  CR: {
    displayText: tr('Costa Rica'),
    value: tr('CR'),
  },
  CI: {
    displayText: tr("Cote d'Ivoire"),
    value: tr('CI'),
  },
  HR: {
    displayText: tr('Croatia'),
    value: tr('HR'),
  },
  CU: {
    displayText: tr('Cuba'),
    value: tr('CU'),
  },
  CW: {
    displayText: tr('Curacao'),
    value: tr('CW'),
  },
  CY: {
    displayText: tr('Cyprus'),
    value: tr('CY'),
  },
  CZ: {
    displayText: tr('Czech Republic'),
    value: tr('CZ'),
  },
  DK: {
    displayText: tr('Denmark'),
    value: tr('DK'),
  },
  DJ: {
    displayText: tr('Djibouti'),
    value: tr('DJ'),
  },
  DM: {
    displayText: tr('Dominica'),
    value: tr('DM'),
  },
  DO: {
    displayText: tr('Dominican Republic'),
    value: tr('DO'),
  },
  EC: {
    displayText: tr('Ecuador'),
    value: tr('EC'),
  },
  EG: {
    displayText: tr('Egypt'),
    value: tr('EG'),
  },
  SV: {
    displayText: tr('El Salvador'),
    value: tr('SV'),
  },
  GQ: {
    displayText: tr('Equatorial Guinea'),
    value: tr('GQ'),
  },
  ER: {
    displayText: tr('Eritrea'),
    value: tr('ER'),
  },
  EE: {
    displayText: tr('Estonia'),
    value: tr('EE'),
  },
  ET: {
    displayText: tr('Ethiopia'),
    value: tr('ET'),
  },
  FK: {
    displayText: tr('Falkland Islands (Islas Malvinas)'),
    value: tr('FK'),
  },
  FO: {
    displayText: tr('Faroe Islands'),
    value: tr('FO'),
  },
  FJ: {
    displayText: tr('Fiji'),
    value: tr('FJ'),
  },
  FI: {
    displayText: tr('Finland'),
    value: tr('FI'),
  },
  FR: {
    displayText: tr('France'),
    value: tr('FR'),
  },
  GF: {
    displayText: tr('French Guiana'),
    value: tr('GF'),
  },
  PF: {
    displayText: tr('French Polynesia'),
    value: tr('PF'),
  },
  TF: {
    displayText: tr('French Southern and Antarctic Lands'),
    value: tr('TF'),
  },
  GA: {
    displayText: tr('Gabon'),
    value: tr('GA'),
  },
  GM: {
    displayText: tr('Gambia'),
    value: tr('GM'),
  },
  GE: {
    displayText: tr('Georgia'),
    value: tr('GE'),
  },
  DE: {
    displayText: tr('Germany'),
    value: tr('DE'),
  },
  GH: {
    displayText: tr('Ghana'),
    value: tr('GH'),
  },
  GI: {
    displayText: tr('Gibraltar'),
    value: tr('GI'),
  },
  GR: {
    displayText: tr('Greece'),
    value: tr('GR'),
  },
  GL: {
    displayText: tr('Greenland'),
    value: tr('GL'),
  },
  GD: {
    displayText: tr('Grenada'),
    value: tr('GD'),
  },
  GP: {
    displayText: tr('Guadeloupe'),
    value: tr('GP'),
  },
  GU: {
    displayText: tr('Guam'),
    value: tr('GU'),
  },
  GT: {
    displayText: tr('Guatemala'),
    value: tr('GT'),
  },
  GG: {
    displayText: tr('Guernsey'),
    value: tr('GG'),
  },
  GN: {
    displayText: tr('Guinea'),
    value: tr('GN'),
  },
  GW: {
    displayText: tr('Guinea-Bissau'),
    value: tr('GW'),
  },
  GY: {
    displayText: tr('Guyana'),
    value: tr('GY'),
  },
  HT: {
    displayText: tr('Haiti'),
    value: tr('HT'),
  },
  HM: {
    displayText: tr('Heard Island and McDonald Islands'),
    value: tr('HM'),
  },
  VA: {
    displayText: tr('Holy See (Vatican City)'),
    value: tr('VA'),
  },
  HN: {
    displayText: tr('Honduras'),
    value: tr('HN'),
  },
  HK: {
    displayText: tr('Hong Kong'),
    value: tr('HK'),
  },
  HU: {
    displayText: tr('Hungary'),
    value: tr('HU'),
  },
  IS: {
    displayText: tr('Iceland'),
    value: tr('IS'),
  },
  IN: {
    displayText: tr('India'),
    value: tr('IN'),
  },
  ID: {
    displayText: tr('Indonesia'),
    value: tr('ID'),
  },
  IR: {
    displayText: tr('Iran'),
    value: tr('IR'),
  },
  IQ: {
    displayText: tr('Iraq'),
    value: tr('IQ'),
  },
  IE: {
    displayText: tr('Ireland'),
    value: tr('IE'),
  },
  IM: {
    displayText: tr('Isle of Man'),
    value: tr('IM'),
  },
  IL: {
    displayText: tr('Israel'),
    value: tr('IL'),
  },
  IT: {
    displayText: tr('Italy'),
    value: tr('IT'),
  },
  JM: {
    displayText: tr('Jamaica'),
    value: tr('JM'),
  },
  JP: {
    displayText: tr('Japan'),
    value: tr('JP'),
  },
  JE: {
    displayText: tr('Jersey'),
    value: tr('JE'),
  },
  JO: {
    displayText: tr('Jordan'),
    value: tr('JO'),
  },
  KZ: {
    displayText: tr('Kazakhstan'),
    value: tr('KZ'),
  },
  KE: {
    displayText: tr('Kenya'),
    value: tr('KE'),
  },
  KI: {
    displayText: tr('Kiribati'),
    value: tr('KI'),
  },
  KP: {
    displayText: tr("Korea, Democratic People's Republic of"),
    value: tr('KP'),
  },
  KR: {
    displayText: tr('Korea, Republic of'),
    value: tr('KR'),
  },
  XK: {
    displayText: tr('Kosovo'),
    value: tr('XK'),
  },
  KW: {
    displayText: tr('Kuwait'),
    value: tr('KW'),
  },
  KG: {
    displayText: tr('Kyrgyzstan'),
    value: tr('KG'),
  },
  LA: {
    displayText: tr('Laos'),
    value: tr('LA'),
  },
  LV: {
    displayText: tr('Latvia'),
    value: tr('LV'),
  },
  LB: {
    displayText: tr('Lebanon'),
    value: tr('LB'),
  },
  LS: {
    displayText: tr('Lesotho'),
    value: tr('LS'),
  },
  LR: {
    displayText: tr('Liberia'),
    value: tr('LR'),
  },
  LY: {
    displayText: tr('Libya'),
    value: tr('LY'),
  },
  LI: {
    displayText: tr('Liechtenstein'),
    value: tr('LI'),
  },
  LT: {
    displayText: tr('Lithuania'),
    value: tr('LT'),
  },
  LU: {
    displayText: tr('Luxembourg'),
    value: tr('LU'),
  },
  MO: {
    displayText: tr('Macau'),
    value: tr('MO'),
  },
  MK: {
    displayText: tr('Macedonia'),
    value: tr('MK'),
  },
  MG: {
    displayText: tr('Madagascar'),
    value: tr('MG'),
  },
  MW: {
    displayText: tr('Malawi'),
    value: tr('MW'),
  },
  MY: {
    displayText: tr('Malaysia'),
    value: tr('MY'),
  },
  MV: {
    displayText: tr('Maldives'),
    value: tr('MV'),
  },
  ML: {
    displayText: tr('Mali'),
    value: tr('ML'),
  },
  MT: {
    displayText: tr('Malta'),
    value: tr('MT'),
  },
  MH: {
    displayText: tr('Marshall Islands'),
    value: tr('MH'),
  },
  MQ: {
    displayText: tr('Martinique'),
    value: tr('MQ'),
  },
  MR: {
    displayText: tr('Mauritania'),
    value: tr('MR'),
  },
  MU: {
    displayText: tr('Mauritius'),
    value: tr('MU'),
  },
  YT: {
    displayText: tr('Mayotte'),
    value: tr('YT'),
  },
  MX: {
    displayText: tr('Mexico'),
    value: tr('MX'),
  },
  FM: {
    displayText: tr('Micronesia'),
    value: tr('FM'),
  },
  MD: {
    displayText: tr('Moldova'),
    value: tr('MD'),
  },
  MC: {
    displayText: tr('Monaco'),
    value: tr('MC'),
  },
  MN: {
    displayText: tr('Mongolia'),
    value: tr('MN'),
  },
  ME: {
    displayText: tr('Montenegro'),
    value: tr('ME'),
  },
  MS: {
    displayText: tr('Montserrat'),
    value: tr('MS'),
  },
  MA: {
    displayText: tr('Morocco'),
    value: tr('MA'),
  },
  MZ: {
    displayText: tr('Mozambique'),
    value: tr('MZ'),
  },
  NA: {
    displayText: tr('Namibia'),
    value: tr('NA'),
  },
  NR: {
    displayText: tr('Nauru'),
    value: tr('NR'),
  },
  NP: {
    displayText: tr('Nepal'),
    value: tr('NP'),
  },
  NL: {
    displayText: tr('Netherlands'),
    value: tr('NL'),
  },
  NC: {
    displayText: tr('New Caledonia'),
    value: tr('NC'),
  },
  NZ: {
    displayText: tr('New Zealand'),
    value: tr('NZ'),
  },
  NI: {
    displayText: tr('Nicaragua'),
    value: tr('NI'),
  },
  NE: {
    displayText: tr('Niger'),
    value: tr('NE'),
  },
  NG: {
    displayText: tr('Nigeria'),
    value: tr('NG'),
  },
  NU: {
    displayText: tr('Niue'),
    value: tr('NU'),
  },
  NF: {
    displayText: tr('Norfolk Island'),
    value: tr('NF'),
  },
  MP: {
    displayText: tr('Northern Mariana Islands'),
    value: tr('MP'),
  },
  NO: {
    displayText: tr('Norway'),
    value: tr('NO'),
  },
  OM: {
    displayText: tr('Oman'),
    value: tr('OM'),
  },
  PK: {
    displayText: tr('Pakistan'),
    value: tr('PK'),
  },
  PW: {
    displayText: tr('Palau'),
    value: tr('PW'),
  },
  PA: {
    displayText: tr('Panama'),
    value: tr('PA'),
  },
  PG: {
    displayText: tr('Papua New Guinea'),
    value: tr('PG'),
  },
  PY: {
    displayText: tr('Paraguay'),
    value: tr('PY'),
  },
  PE: {
    displayText: tr('Peru'),
    value: tr('PE'),
  },
  PH: {
    displayText: tr('Philippines'),
    value: tr('PH'),
  },
  PN: {
    displayText: tr('Pitcairn Islands'),
    value: tr('PN'),
  },
  PL: {
    displayText: tr('Poland'),
    value: tr('PL'),
  },
  PT: {
    displayText: tr('Portugal'),
    value: tr('PT'),
  },
  PR: {
    displayText: tr('Puerto Rico'),
    value: tr('PR'),
  },
  QA: {
    displayText: tr('Qatar'),
    value: tr('QA'),
  },
  RE: {
    displayText: tr('Reunion'),
    value: tr('RE'),
  },
  RO: {
    displayText: tr('Romania'),
    value: tr('RO'),
  },
  RU: {
    displayText: tr('Russian Federation'),
    value: tr('RU'),
  },
  RW: {
    displayText: tr('Rwanda'),
    value: tr('RW'),
  },
  BL: {
    displayText: tr('Saint Barthelemy'),
    value: tr('BL'),
  },
  SH: {
    displayText: tr('Saint Helena'),
    value: tr('SH'),
  },
  KN: {
    displayText: tr('Saint Kitts and Nevis'),
    value: tr('KN'),
  },
  LC: {
    displayText: tr('Saint Lucia'),
    value: tr('LC'),
  },
  MF: {
    displayText: tr('Saint Martin'),
    value: tr('MF'),
  },
  PM: {
    displayText: tr('Saint Pierre and Miquelon'),
    value: tr('PM'),
  },
  VC: {
    displayText: tr('Saint Vincent and the Grenadines'),
    value: tr('VC'),
  },
  WS: {
    displayText: tr('Samoa'),
    value: tr('WS'),
  },
  SM: {
    displayText: tr('San Marino'),
    value: tr('SM'),
  },
  ST: {
    displayText: tr('Sao Tome and Principe'),
    value: tr('ST'),
  },
  SA: {
    displayText: tr('Saudi Arabia'),
    value: tr('SA'),
  },
  SN: {
    displayText: tr('Senegal'),
    value: tr('SN'),
  },
  RS: {
    displayText: tr('Serbia'),
    value: tr('RS'),
  },
  SC: {
    displayText: tr('Seychelles'),
    value: tr('SC'),
  },
  SL: {
    displayText: tr('Sierra Leone'),
    value: tr('SL'),
  },
  SG: {
    displayText: tr('Singapore'),
    value: tr('SG'),
  },
  SX: {
    displayText: tr('Sint Maarten'),
    value: tr('SX'),
  },
  SK: {
    displayText: tr('Slovakia'),
    value: tr('SK'),
  },
  SI: {
    displayText: tr('Slovenia'),
    value: tr('SI'),
  },
  SB: {
    displayText: tr('Solomon Islands'),
    value: tr('SB'),
  },
  SO: {
    displayText: tr('Somalia'),
    value: tr('SO'),
  },
  ZA: {
    displayText: tr('South Africa'),
    value: tr('ZA'),
  },
  GS: {
    displayText: tr('South Georgia and the Islands'),
    value: tr('GS'),
  },
  SS: {
    displayText: tr('South Sudan'),
    value: tr('SS'),
  },
  ES: {
    displayText: tr('Spain'),
    value: tr('ES'),
  },
  LK: {
    displayText: tr('Sri Lanka'),
    value: tr('LK'),
  },
  SD: {
    displayText: tr('Sudan'),
    value: tr('SD'),
  },
  SR: {
    displayText: tr('Suriname'),
    value: tr('SR'),
  },
  SJ: {
    displayText: tr('Svalbard'),
    value: tr('SJ'),
  },
  SZ: {
    displayText: tr('Swaziland'),
    value: tr('SZ'),
  },
  SE: {
    displayText: tr('Sweden'),
    value: tr('SE'),
  },
  CH: {
    displayText: tr('Switzerland'),
    value: tr('CH'),
  },
  SY: {
    displayText: tr('Syria'),
    value: tr('SY'),
  },
  TW: {
    displayText: tr('Taiwan'),
    value: tr('TW'),
  },
  TJ: {
    displayText: tr('Tajikistan'),
    value: tr('TJ'),
  },
  TZ: {
    displayText: tr('Tanzania'),
    value: tr('TZ'),
  },
  TH: {
    displayText: tr('Thailand'),
    value: tr('TH'),
  },
  TL: {
    displayText: tr('Timor-Leste'),
    value: tr('TL'),
  },
  TG: {
    displayText: tr('Togo'),
    value: tr('TG'),
  },
  TK: {
    displayText: tr('Tokelau'),
    value: tr('TK'),
  },
  TO: {
    displayText: tr('Tonga'),
    value: tr('TO'),
  },
  TT: {
    displayText: tr('Trinidad and Tobago'),
    value: tr('TT'),
  },
  TN: {
    displayText: tr('Tunisia'),
    value: tr('TN'),
  },
  TR: {
    displayText: tr('Turkey'),
    value: tr('TR'),
  },
  TM: {
    displayText: tr('Turkmenistan'),
    value: tr('TM'),
  },
  TC: {
    displayText: tr('Turks and Caicos Islands'),
    value: tr('TC'),
  },
  TV: {
    displayText: tr('Tuvalu'),
    value: tr('TV'),
  },
  UG: {
    displayText: tr('Uganda'),
    value: tr('UG'),
  },
  UA: {
    displayText: tr('Ukraine'),
    value: tr('UA'),
  },
  AE: {
    displayText: tr('United Arab Emirates'),
    value: tr('AE'),
  },
  GB: {
    displayText: tr('United Kingdom'),
    value: tr('GB'),
  },
  US: {
    displayText: tr('United States'),
    value: tr('US'),
  },
  UM: {
    displayText: tr('United States Minor Outlying Islands'),
    value: tr('UM'),
  },
  UY: {
    displayText: tr('Uruguay'),
    value: tr('UY'),
  },
  UZ: {
    displayText: tr('Uzbekistan'),
    value: tr('UZ'),
  },
  VU: {
    displayText: tr('Vanuatu'),
    value: tr('VU'),
  },
  VE: {
    displayText: tr('Venezuela'),
    value: tr('VE'),
  },
  VN: {
    displayText: tr('Vietnam'),
    value: tr('VN'),
  },
  VI: {
    displayText: tr('Virgin Islands'),
    value: tr('VI'),
  },
  WF: {
    displayText: tr('Wallis and Futuna'),
    value: tr('WF'),
  },
  PS: {
    displayText: tr('West Bank'),
    value: tr('PS'),
  },
  EH: {
    displayText: tr('Western Sahara'),
    value: tr('EH'),
  },
  YE: {
    displayText: tr('Yemen'),
    value: tr('YE'),
  },
  ZM: {
    displayText: tr('Zambia'),
    value: tr('ZM'),
  },
  ZW: {
    displayText: tr('Zimbabwe'),
    value: tr('ZW'),
  },
};

export const LEGACY_COMMUNICATION_PREFS = [
  'all',
  'occasional',
  'business-critical only',
];

export default {
  DESCRIPTION,
  DEPARTMENTS,
  DEPARTMENT_DATA_TO_DISPLAY_MAP,
  FORM_FIELDS,
  GREETINGS,
  LABELS,
  LEGACY_COMMUNICATION_PREFS,
  PLACEHOLDERS,
  ROLES,
  TITLES,
  UNSELECTED,
  VALIDATION_ERRORS,
  TERMS_OF_SERVICE_TEXT,
  COMMUNICATION_VALUES,
  COMMUNICATION_PREFS,
  HIDDEN_COMMUNICATION_PREFS,
  COUNTRY_COMMUNICATION_PREFS,
  COUNTRY_COMMUNICATION_DEFAULT_PREFS,
  SUPPORTED_COUNTRIES,
};

/**
 * Ported from optly.util.main.generateGuid
 *
 * @author Tyler Brandt (tyler@optimizely.com)
 */
/**
 * This function generates a rfc4122 version 4 compliant guid.
 */
module.exports = function() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .toUpperCase();
};

import { cloneDeep } from 'lodash';

import config from 'atomic-config';

import flux from 'core/flux';
import AuthGetters from 'optly/modules/auth/getters';
import UrlUtil from 'optly/utils/url';

/**
 * Accepts an opts object containing url, and adds options to make a cross site request to an optly HRD url
 * This is needed when making an api request NOT through the requester module
 * @param {Object} opts
 * @param {String} opts.url
 * @param {type} opts.
 * @return {Deferred}
 */
export const makeOptlyFetchRequest = function(opts) {
  if (arguments.length !== 1 || !opts.url) {
    throw new Error(
      'Must supply a single options object containing url, type, etc',
    );
  }

  const updatedOpts = cloneDeep(opts);
  const url =
    opts.url.search('migrate') !== -1 ||
    opts.url.search('migration_status') !== -1
      ? opts.url
      : UrlUtil.optimizelyHRDUrl(opts.url);
  delete updatedOpts.url;
  // send cookies along
  updatedOpts.credentials = 'include';

  // Try retrieving csrf from Auth before falling back to the config. If we loaded the page from an
  // un-authed flow, then config will be empty. handleCSRFResponse will have need to have been called
  // before, and that stores the csrf token in the Auth module.
  updatedOpts.headers = opts.headers || {};
  updatedOpts.headers['X-csrf-token'] =
    flux.evaluate(AuthGetters.csrfToken) || config.get('csrf', '');

  const noResponseParse = Boolean(updatedOpts.noResponseParse);
  delete updatedOpts.noResponseParse;

  return fetch(url, updatedOpts).then(response => {
    // response.ok is true if HTTP status was in 200 range
    if (response.ok) {
      return noResponseParse ? response : response.json();
    }
    return Promise.reject(response); // 2. reject instead of throw
  });
};

export default {
  makeOptlyFetchRequest,
};
